<div class="row mt-5" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
  <div class="col" style="text-align: center;">
     <span>
        <!-- <i class="bi bi-chat-text" style="font-size: 70px;"></i>  -->
        <img src="assets/Empty-SVGs/Messaging/Comments/comments.svg" style="font-size: 70px;">

    </span>        
      <div class="row">
          <div class="col mt-2">
      <h1>No Comments</h1>
          </div>
      </div>
      <div class="col mt-3">
      <p>Keep track of comments and respond to conversation to all <br>in one place</p>


      </div>
  </div>
  <div class="col mt-4" style="display: flex; justify-content: center; align-items: center;">
      <button nz-button nzType="default" style="margin-right: 10px;"><i class="bi bi-box-arrow-up-right me-2"></i>Learn How</button>
  </div>
</div> 
