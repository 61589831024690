<nz-modal nzWidth="90%" [(nzVisible)]="ManageCustomeFileds" [nzTitle]="modalTitle"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '15px', }" [nzStyle]="{ top: '15px' }"
    (nzOnCancel)="closeDialog()">
    <form [formGroup]="roleManagmentForm" >
    <div class="content" *nzModalContent>
        <div class="card-container">
            <nz-tabset nzType="card">
                <nz-tab *ngFor="let tab of tabs" [nzTitle]="' ' + tab">
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <p style="margin: 0;">Internal User Custom Role Setup{{ tab }}</p>
                        <button nz-button nzType="default" (click)="showCopyfromanotherRole()">Copy Form Another Role</button>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-6">
                            <label class="cityDropdown" for="state">Role
                                <span class="text-danger fw-bolder">*</span></label>
                            <input formControlName="role" type="text" class="form-control formField">
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-6">
                            <label class="cityDropdown" for="state">Role Description
                                <span class="text-danger fw-bolder">*</span></label>
                            <input formControlName="roleDescription" type="text" class="form-control formField">
                        </div>
                    </div>



                    <div class="row mt-4 hover-text bg-body-secondary custom-hover">
                        <div class="col-4">
                            <label for=""></label>
                        </div>
                        <div class="col-4">
                            <label for=""></label>
                        </div>
                        <div class="col-1">
                            <label class="cityDropdown" for="checkbox1">View</label>
                        </div>
                        <div class="col-1">
                            <label class="cityDropdown" for="checkbox2">New</label>
                        </div>
                        <div class="col-1">
                            <label class="cityDropdown" for="checkbox3">Edit</label>
                        </div>
                        <div class="col-1">
                            <label class="cityDropdown" for="checkbox4">Delete</label>
                        </div>
                    </div>

                    <div class="row   custom-hover">
                        <div class="col-4"></div>
                        <div class="col-4"></div>
                        <div class="col-1 ">
                            <input formControlName="view" type="checkbox" class="form-check-input ms-2" id="checkbox4">
                        </div>
                        <div class="col-1">
                            <input formControlName="new" type="checkbox" class="form-check-input ms-2" id="checkbox4">
                        </div>
                        <div class="col-1 ">
                            <input formControlName="edit" type="checkbox" class="form-check-input ms-2" id="checkbox4">
                        </div>
                        <div class="col-1">
                            <input formControlName="delete" type="checkbox" class="form-check-input ms-2" id="checkbox4">
                        </div>
                    </div>

                    <div class="row bg-body-secondary custom-hover">
                        <div class="col">
                            <label for="" class="cityDropdown ">Sales</label>
                        </div>
                    </div>

                    <div class="row  custom-hover ">
                        <div class="col-4">
                            <label for="" class="cityDropdown" style="cursor: not-allowed;">Leads</label>
                        </div>

                        <div class="col-4"></div>
                        <div class="col-1">
                            <input formControlName="view" type="checkbox" class="form-check-input ms-2" id="checkbox4">
                        </div>
                        <div class="col-1">
                            <input formControlName="new" type="checkbox" class="form-check-input ms-2" id="checkbox4">
                        </div>
                        <div class="col-1">
                            <input formControlName="Edit" type="checkbox" class="form-check-input ms-2" id="checkbox4">
                        </div>
                        <div class="col-1">
                            <input formControlName="delete" type="checkbox" class="form-check-input ms-2" id="checkbox4">
                        </div>
                    </div>

                    <div class="row custom-hover">
                        <div class="col">
                            <label nz-checkbox [(ngModel)]="checked">Assign Salesperson</label>
                        </div>
                    </div>
                    <div class="row custom-hover" >
                        <div class="col">
                            <label nz-checkbox [(ngModel)]="checked">View other Salesperson Leads & Activities</label>
                        </div>
                    </div>
                    <div class="row custom-hover" >
                        <div class="col">
                            <label nz-checkbox [(ngModel)]="checked">Convert to Job</label>
                        </div>
                    </div>
                    <div class="row custom-hover" >
                        <div class="col">
                            <label nz-checkbox [(ngModel)]="checked">Export to Excel</label>
                        </div>
                    </div>
                </nz-tab>
            </nz-tabset>
        </div>

    </div>
</form>

</nz-modal>