<nz-modal
  [(nzVisible)]="emailSampleVisible"
  [nzFooter]="newleadActivityfooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '600px',
    'overflow-y': 'auto',
    'overflow-x': 'hidden'
  }"
  [nzStyle]="{ top: '37px' }"
  nzWidth="35%"
  [nzTitle]="addActivity"
  (nzOnCancel)="emailSampleCancel()"
>
  <form nz-form [formGroup]="validateForm">
    <ng-template #addActivity>
      <h1 class="p-0 m-0">Email Sample</h1>
    </ng-template>
    <ng-container *nzModalContent>
        <nz-card nzTitle="Email Sample">
                    <div class="row" style="margin-top: -20px;">
                          <nz-form-item>
                           <label class="sub-text1 famly-inn ms-3" for="displayName">E-mail</label>
                            <div class="col-12">
                            <nz-form-control  [nzErrorTip]="errorTpl">
                              <input nz-input formControlName="email" id="email" class="form-control formField" />
                            </nz-form-control>
                            </div>
                          </nz-form-item>
                   </div>
        </nz-card>
    </ng-container>
    <ng-template #newleadActivityfooter>
      <div class="mt-2 align-items-center">
        <button nz-button nzType="default" class="rounded-1" (click)="emailSampleCancel()">Cancel</button>
        <button nz-button nzType="primary" class="rounded-1 me-0" (click)="submitForm()">Send Email</button>
      </div>
    </ng-template>
    <ng-template #errorTpl let-control>
      <ng-container *ngIf="control.errors?.required">Email is required.</ng-container>
      <ng-container *ngIf="control.errors?.email">Please enter a valid email address.</ng-container>
    </ng-template>
  </form>
</nz-modal>
