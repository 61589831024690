import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { format } from '@progress/kendo-angular-intl';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { CustomerInformation } from 'src/Models/CustomerInformation';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';

@Component({
  selector: 'app-new-contact-customer',
  templateUrl: './new-contact-customer.component.html',
  styleUrls: ['./new-contact-customer.component.css']
})
export class NewContactCustomerComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Input() customerId: any;
  preferredCountries: CountryISO[] = [CountryISO.Pakistan];
  modalTitle: string = "Customer Contact";
  isVisible: boolean = true;
  CustomerInformation: FormGroup;
  avatarText: string = '';
  initials: string = '';
  searchCountryField: SearchCountryField[];
  GetDataSubvendor: any[] = [];
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = false;
  PhoneNumberFormat = PhoneNumberFormat;
  additionalEmails: FormArray;
  idVisible = false;
  initialEmail: string | null = null;
  initialLabel: string | null = null;
  loadingSave: boolean = false;
  displayNameInvalid: boolean = false;
  initialFormData: any;
  SavedRecordResponse: any;
  createdDateTime: any = '';
  isRecordSaved: boolean = false;
  confirmModal?: NzModalRef;
  recordSaved: boolean = false;




  constructor(private fb: FormBuilder, private modal: NzModalService, private toastService: NzMessageService, private customerInformationService: CustomerInformationService, private loadingIndicatoreService: LoadingIndicatorService,) {

  }
  ngOnInit(): void {
    if (this.customerId) {
      this.openid(this.customerId);
    }

    console.log('Received data1344:', this.customerId);
    this.CustomerInformation = this.fb.group({
      id: [''],
      globalId: [''],
      createdBy: [0],
      firstName: ['', [Validators.maxLength(35)]],
      lastName: ['', [Validators.maxLength(35)]],
      displayName: ['', [Validators.required, Validators.maxLength(75)]],
      streetAddress: ['', [Validators.maxLength(50)]],
      suburb: ['', [Validators.maxLength(50)]],
      state: ['', [Validators.maxLength(3)]],
      postalCode: ['', [Validators.maxLength(15)]],
      phone: ['', [Validators.maxLength(25)]],
      countryCodeId: [1],
      cellPhone: [''],
      primaryEmail: ['', [Validators.email, Validators.maxLength(110)]],
      label: ['', [Validators.maxLength(50)]],
      status: [false],
      pictureUrl: [''],
      receivedTextMessage: [''],
      isJob: [true],
      isLeadOpportunity: [true],
      contactTypeId: [],
      createCustomerEmailParameterRequests: this.fb.array([]),
    });
    this.additionalEmails = this.CustomerInformation.get('createCustomerEmailParameterRequests') as FormArray;



  }

  closeDialog() {
    this.cancel.emit();
  }

  removeAdditionalEmail(index: number): void {
    this.additionalEmails.removeAt(index);
  }

  addAdditionalEmail(): void {
    const newEmailGroup = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      label: ['']
    });

    this.additionalEmails.push(newEmailGroup);
  }
  extractInitials(fullName: string): string {
    const words = fullName.split(' ');
    return words.map(word => word[0]).join('').toUpperCase();
  }

  updateInitials() {
    const displayName = this.CustomerInformation.get('displayName')?.value || '';
    this.initials = this.extractInitials(displayName);
  }

  LeadOpportunityGetByidData: any;

  openid(customerId: string): void {
    this.getDataById(customerId);
  }

  getDataById(id: string): void {
    this.customerInformationService.getDataId(id).subscribe((data: any) => {
      console.log("Recive data", data);
      this.patchCustomerInformation(data.result); // Patch the fetched data to the form
    });
  }

  patchCustomerInformation(data: any) {
    this.CustomerInformation.patchValue({
      id: data.id,
      globalId: data.globalId,
      createdBy: data.createdBy,
      firstName: data.firstName,
      lastName: data.lastName,
      displayName: data.displayName,
      streetAddress: data.streetAddress,
      suburb: data.suburb,
      state: data.state,
      postalCode: data.postalCode,
      phone: data.phone,
      countryCodeId: data.countryCodeId,
      cellPhone: data.cellPhone,
      primaryEmail: data.primaryEmail,
      label: data.label,
      status: data.status,
      pictureUrl: data.pictureUrl,
      receivedTextMessage: data.receivedTextMessage,
      isJob: data.isJob,
      isLeadOpportunity: data.isLeadOpportunity,
      contactTypeId: data.contactTypeId,
      isActive: data.isActive
      // Add other form controls if needed
    });



    // Patching the additional emails from customerEmailParameters
    const emailFormArray = this.CustomerInformation.get('createCustomerEmailParameterRequests') as FormArray;
    emailFormArray.clear(); // Clear existing form array before patching new values

    if (data.customerEmailParameters && data.customerEmailParameters.length > 0) {
      data.customerEmailParameters.forEach((emailParameter: any) => {
        const emailFormGroup = this.fb.group({
          email: [emailParameter.email, [Validators.required, Validators.email]],
          label: [emailParameter.label],
          // Add more fields from emailParameter if needed
        });
        emailFormArray.push(emailFormGroup); // Push each email form group to the form array
      });
    }
  }




  updateDisplayNameAndAvatar() {
    const firstName = this.CustomerInformation.get('firstName')?.value || '';
    const lastName = this.CustomerInformation.get('lastName')?.value || '';

    if (!firstName && !lastName) {
      this.CustomerInformation.get('displayName')?.setValue('');
      this.avatarText = ''; // Reset avatar text if both fields are empty
    } else {
      const displayName = `${firstName} ${lastName}`;
      this.CustomerInformation.get('displayName')?.setValue(displayName);
      this.avatarText = this.extractInitials(displayName);
    }
  }
  save() {
    this.loadingIndicatoreService.show();
    this.loadingSave = true;

    this.CustomerInformation.get('displayName')?.markAsTouched();
    this.displayNameInvalid =
      this.CustomerInformation.get('displayName')?.invalid || !this.CustomerInformation.get('displayName')?.value;

    if (this.displayNameInvalid) {
      this.loadingSave = false;
      this.loadingIndicatoreService.hide();
      this.toastService.error('Display Name is required');
      return;
    }

    if (this.CustomerInformation.invalid) {
      this.loadingSave = false;
      this.loadingIndicatoreService.hide();
      this.toastService.error('Fill all required fields correctly');
      return;
    }

    const formData = this.CustomerInformation.value;
    const emailArray = this.CustomerInformation.get('createCustomerEmailParameterRequests') as FormArray;
    const email = emailArray.at(0).get('email')?.value;
    const label = emailArray.at(0).get('label')?.value;

    if (formData.globalId !== "") {
      if (JSON.stringify(this.initialFormData) !== JSON.stringify(formData)) {
        formData.isJob = false;
        formData.isLeadOpportunity = false;

        formData.primaryEmail = this.initialEmail || email;

        const emailArray = this.CustomerInformation.get('createCustomerEmailParameterRequests') as FormArray;
        emailArray.push(this.fb.group({
          email: email,
          label: label
        }));

        this.initialEmail = email;
        this.initialLabel = label;

        // Check if the email and label already exist in the form
        const isEmailExists = emailArray.controls.some((control) => control.value.email === email);
        const isLabelExists = emailArray.controls.some((control) => control.value.label === label);

        if (!isEmailExists && !isLabelExists) {
          emailArray.push(this.fb.group({
            email: email,
            label: label
          }));
        }

        // this.update();
      } else {
        setTimeout(() => {
          this.loadingSave = false;
        }, 2000);
        return;
      }
    } else {
      formData.isJob = false;
      formData.isLeadOpportunity = false;

      this.customerInformationService.postData(formData).subscribe((response) => {
        this.SavedRecordResponse = response.result;
        console.log('Saved:', response);
        this.CustomerInformation.controls['globalId'].setValue(response.result.globalId);
         this.CustomerInformation.controls['id'].setValue(response.result.id);
        console.log("this.CustomerInformation!",this.CustomerInformation);
        this.displayNameInvalid = false;

        this.initialEmail = email;
        this.initialLabel = label;

        const isEmailExists = emailArray.controls.some((control) => control.value.email === email);
        const isLabelExists = emailArray.controls.some((control) => control.value.label === label);

        if (!isEmailExists && !isLabelExists) {
          emailArray.push(this.fb.group({
            email: email,
            label: label
          }));
        }

        setTimeout(() => {
          const createdOnDate = new Date(this.SavedRecordResponse.createdOn);
          this.loadingIndicatoreService.hide();
          this.createdDateTime = format(createdOnDate, 'MMM d, yyyy, h:mm a');
          this.toastService.success('Contact Saved');
          this.isRecordSaved = true;
          setTimeout(() => {
            this.loadingSave = false;
          }, 2000);
        }, 2000);
      });
    }
  }

  Delete(): void {
    console.log('DeleteCustomer method called');

    if (this.LeadOpportunityGetByidData && this.LeadOpportunityGetByidData.result && this.LeadOpportunityGetByidData.result.globalId) {
      console.log('Global ID exists:', this.LeadOpportunityGetByidData.result.globalId);

      // Show confirmation modal before deleting
      this.showConfirm();
    } else {
      console.log('Global ID is missing or undefined');
      alert('Global ID is missing or undefined');
    }
  }

  showConfirm(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete this item?',
      nzContent: 'Are you sure you want to delete this item?',
      nzOkDanger: true,
      nzOkText: 'delete',
      nzCancelText: 'No',
      nzOnOk: () => {
        const globalId = this.LeadOpportunityGetByidData.result.globalId;
        this.customerInformationService.deleteData(globalId).subscribe(
          (data: any) => {
            console.log('Deleted', data.result);
            this.toastService.error('Data deleted successfully.');
            this.idVisible = false; // Close the modal
            this.refreshGridData(); // Refresh grid data after deletion
          },
          (error) => {
            console.error('Deletion failed', error);
            this.toastService.error('Failed to delete data.');
          }
        );
      },
      nzOnCancel: () => {
        console.log('Deletion canceled');
      }
    });
  }

  refreshGridData(): void {
    this.customerInformationService.getData().subscribe((res: any) => {
      this.GetDataSubvendor = res.result;
      console.log('Customer info', res.result);
      // Update any necessary variables or UI elements related to the grid here
    });
  }

  saveAndNew() {
    this.save(); // Save the current data

    setTimeout(() => {
      this.CustomerInformation.reset(); // Reset the form after a slight delay
      this.additionalEmails.clear(); // Clear additional email form array

      // Customize as needed to initiate actions for new data input or update
      // For instance, you can set flags or perform any necessary tasks here
      // Example: Navigate to a new entry section or enable fields for new data entry
    }, 3000); // Adjust the delay time as needed to allow for saving data
  }


  saveAndResetForm() {
    this.save(); // Call the save method to perform the save operation

    // Reset the form after a slight delay
    setTimeout(() => {
      this.CustomerInformation.reset(); // Reset the form
      this.additionalEmails.clear(); // Clear the additional email form array
      // Close the form (you can call your close method or set a flag to close the form)
      this.idVisible = false;
    }, 3000); // Adjust the delay time as needed to allow for saving data
  }






  showFields: boolean = true; // Initially show one row
  rows: number = 0; // Initial row count
  maxRows: number = 5; // Maximum rows to show
  showAddButton: boolean = true; // Initially show "Add" button

  addRow() {
    this.rows += 1;
    if (this.rows >= this.maxRows) {
      this.showAddButton = false; // Hide "Add" button when maximum rows are reached
    }
  }

  removeRow(index: number) {
    this.rows -= 1;
    if (this.rows < this.maxRows) {
      this.showAddButton = true; // Show "Add" button if rows are less than the maximum
    }
  }

}
