import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LeadActivityMetaData } from 'src/Models/LeadActivity/leadActivityClass';
import { LeadActivityResponse } from 'src/Models/LeadActivityTemplate/LeadActivityTemplate';
import { ResponseModel, ResponseModelArray, TablePageResponse } from 'src/Models/responseMessage.model';
import { FilterSearchParameter, CreateLookupFilterRequest } from 'src/app/shared/component/Models/LookupModels';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class LeadActivitiesService {

  private base_Url: string = environment.apiUrl + 'LookupFilter/';

  constructor(private http: HttpClient) {}

  // getLeadListViewData = new BehaviorSubject<ResponseModel<TablePageResponse<LeadOpportunityResponse>>>(null);
  // getLeadActivityViewData = new BehaviorSubject<ResponseModel<TablePageResponse<LeadActivityResponse>>>(null);
  // $getInternalUsers = this.getLeadListViewData.asObservable();
  // $getLeadActivity = this.getLeadActivityViewData.asObservable();



  // getLeadOpportunitiesUserData = new BehaviorSubject<ResponseModel<TablePageResponse<LeadActivityResponse>>>(null);
  // $getgetLeadOpportunitiesUserDataUsers = this.getLeadOpportunitiesUserData.asObservable();
  // getLeadUserData = new BehaviorSubject<ResponseModel<TablePageResponse<LeadActivityResponse>>>(null);
  getLeadUserData = new BehaviorSubject<ResponseModel<TablePageResponse<LeadActivityMetaData>>>(null);
  $getLeadUsers = this.getLeadUserData.asObservable();



  // getLeadOpportunities(filterSearchParameter: FilterSearchParameter) {
  //   const apiUrl = `${this.base_Url}GetAll-ByFilter`;
  //   return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
  //     (data) => {
  //       this.getLeadOpportunitiesUserData.next(data);
  //     },
  //     (error) => {
  //       console.error('Error fetching internal users:', error);
  //     }
  //   );
  // }
  getLeadActivities(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getLeadUserData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  // getAppliedFilterDataLeadOpportunities(appliedFilter: CreateLookupFilterRequest[]) {
  //   const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
  //   return this.http.post<any>(apiUrl, appliedFilter).subscribe(
  //     (data) => {
  //       this.getLeadOpportunitiesUserData.next(data);
  //     },
  //     (error) => {
  //       console.error('Error fetching internal users:', error);
  //     }
  //   );
  // }
  getAppliedFilterData(appliedFilter: CreateLookupFilterRequest[]) {
    const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
    return this.http.post<any>(apiUrl, appliedFilter).subscribe(
      (data) => {
        this.getLeadUserData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }

  // tabOneSetSelected = new BehaviorSubject<number>(0);
  // $tabOneSetSelectedId = this.tabOneSetSelected.asObservable();

  // tabOneusertabOneuser = new BehaviorSubject<boolean>(false);
  // $tabOneusertabOneuserId = this.tabOneusertabOneuser.asObservable();

  // tabOneSetSelectedFilterId(filterId: number) {
  //   this.tabOneSetSelected.next(filterId);
  // }
  // tabOneuserAppliedFilter(filter: boolean) {
  //   this.tabOneusertabOneuser.next(filter);
  // }

  tabTwoSetSelected = new BehaviorSubject<number>(0);
  $tabTwoSetSelectedId = this.tabTwoSetSelected.asObservable();

  tabTwousertabOneuser = new BehaviorSubject<boolean>(false);
  $tabTwousertabOneuserId = this.tabTwousertabOneuser.asObservable();

  tabTwoSetSelectedFilterId(filterId: number) {
    this.tabTwoSetSelected.next(filterId);
  }
  tabTwouserAppliedFilter(filter: boolean) {
    this.tabTwousertabOneuser.next(filter);
  }



  getLeadActivityViewGridData(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter)?.subscribe(
      (data) => {
        this.getLeadUserData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }




  ////// Get By Lead-Id All Activities Create Method By Mehmood  07/03/2024  //////////

  private baseUrl: string = environment.apiUrl + 'LeadActivity/';

  getAllActivitiesByLeadId(id: number): Observable<ResponseModel<LeadActivityResponse[]>> {
    return this.http.get<ResponseModel<LeadActivityResponse[]>>(`${this.baseUrl}getAll-ByLeadId?LeadOpportunityId=${id}`);
  }


  getAllCountActivitiesByLeadId(id: number): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}getCount-ByLeadId?LeadOpportunityId=${id}`);
  }
  leadActivityGetData(): Observable<ResponseModelArray<LeadActivityResponse>> {
    return this.http.get<ResponseModelArray<LeadActivityResponse>>(`${this.baseUrl}getAll`);
  }
}
