import { BasicEntity, CompanyParameter, FormName, FormNameResponse, CreateActionRequest, UpdateActionRequest, LookUpStandard, LookUpStandardResponse } from "../All-Filters/financialBillsFilterField";
import { BaseResponse } from "./applicationUserResponse";

export class CreateApplicationUserRequest {
  public globalId?: string;
  public companyParameterId: number;
  public firstName: string;
  public middleName?: string | null;
  public lastName: string;
  public email: string;
  public label?: string | null;
  public phoneNumber?: string | null;
  public countryCodeId?: number | null;
  public cellPhone?: string | null;
  public receiveTextMessages?: string | null;
  public applicationGroupId?: number | null;
  public loginAccess?: boolean | null;
  public archiveUser?: boolean | null;
  public userStatusId?: number | null;
  public userTypeId?: number | null;
  public isSuperAdmin?: boolean | null;
  public activationCode?: string | null;
  public activationCodeExpiration?: Date | null;
  public isActivationCodeUsed?: boolean | null;
  public isSetOutOfOfficeStatus?: boolean | null;
  public messageSignature?: string | null;
  public messageSignatureImageUrl?: string | null;
  public isScheduleNotifyMe?: boolean | null;
  public scheduleNotifyMeWhen?: number | null;
  public isTimeClockRequiredLocation?: boolean | null;
  public costCodeId?: number | null;
  public defaultLaborCost?: number | null;
  public contactInformation?: string | null;
  
  public createApplicationUserNotificationParameterRequests?: CreateApplicationUserNotificationParameterRequest[] | null;
  public createApplicationUserJobAcessParameterRequests?: CreateApplicationUserJobAcessParameterRequest[] | null;
  public createApplicationUserEmailParameterRequests?: CreateApplicationUserEmailParameterRequest[] | null;
  
  public imageFile?: File | null;
  }
  
  export class CreateApplicationUserNotificationParameterRequest {

    public applicationModuleId?: number | null;
    public isAllUserActivity?: boolean | null;
    public isEmail?: number | null;
    public isText?: boolean | null;
    public isPush?: boolean | null;
    public toolTip?: string | null;
    public globalId?: string | null;
  }
  
  export class CreateApplicationUserJobAcessParameterRequest {
    public jobInformationId?: number | null;
    public isAccess?: boolean | null;
    public isNotification?: boolean | null;
  }
  
  export class CreateApplicationUserEmailParameterRequest {
    public email?: string | null;
    public label?: string | null;
    public globalId?: string | null;
  }
  

  export class  Job {
    name: string;
    status: string;
    dateOpened: string;
    DateData: string;
    selected: boolean;
  }

  export class UpdateApplicationUserImageRequest {
    messageSignatureImageUrl: string;
    
    GlobalId: string;
    public ImageFile?: File[];
  }


  export interface ApplicationUserResponse {
    firstName: string;
    middleName: string;
    lastName: string;
    password: string;
    salt: string;
    email: string;
    label: string;
    phoneNumber: string;
    companyParameterId?: number;
    companyParameter?: CompanyParameterResponse;
    countryCodeId?: number;
    countryCode?: CountryCodeResponse;
    cellPhone: string;
    receiveTextMessages: string;
    applicationGroupId?: number;
    applicationGroup?: ApplicationGroupResponse;
    loginAccess?: boolean;
    archiveUser?: boolean;
    userStatusId?: number;
    userStatus?: UserStatusResponse;
    userTypeId?: number;
    userType?: UserTypeResponse;
    isSuperAdmin?: boolean;
    activationCode: string;
    activationCodeExpiration?: Date;
    isActivationCodeUsed?: boolean;
    isSetOutOfOfficeStatus?: boolean;
    messageSignature: string;
    messageSignatureImageUrl: string;
    isScheduleNotifyMe?: boolean;
    scheduleNotifyMeWhen?: number;
    isTimeClockRequiredLocation?: boolean;
    costCodeId?: number;
    costCode?: CostCodeResponse;
    defaultLaborCost?: number;
    contactInformation?: string | null;
    isAdminAccess?: boolean;
    isAutoAccess?: boolean;
  
    applicationUserEmailParameters?: ApplicationUserEmailParameterResponse[] | null;
    applicationUserNotificationParameters?: ApplicationUserNotificationParameterResponse[] | null;
    applicationUserJobAcessParameters?: ApplicationUserJobAcessParameterResponse[] | null;
  }
  
  export interface CompanyParameterResponse {
    // Define properties for CompanyParameterResponse
  }
  
  export interface CountryCodeResponse {
    // Define properties for CountryCodeResponse
  }
  
  export interface ApplicationGroupResponse {
    // Define properties for ApplicationGroupResponse
  }
  
  export interface UserStatusResponse {
    // Define properties for UserStatusResponse
  }
  
  export interface UserTypeResponse {
    // Define properties for UserTypeResponse
  }
  
  export interface CostCodeResponse {
    // Define properties for CostCodeResponse
  }
  
  export interface ApplicationUserEmailParameterResponse {
    // Define properties for ApplicationUserEmailParameterResponse
  }
  
  export interface ApplicationUserNotificationParameterResponse {
    // Define properties for ApplicationUserNotificationParameterResponse
  }
  
  export interface ApplicationUserJobAcessParameterResponse {
    // Define properties for ApplicationUserJobAcessParameterResponse
  }

// ............//

  export class CreateApplicationBulkUserRequest {
    public companyParameterId: number;
    public firstName: string;
    public lastName: string;
    public email: string;
    public applicationGroupId: number | null;
    }



    export class StandardColumnSetup extends BasicEntity {
      companyParameterId?: number;
      companyParameter?: CompanyParameter;
      formNameId?: number;
      formName?: FormName;
      fieldName: string;
      displayColumnName: string;
      tableName: string;
  }
  
  export class StandardColumnSetupResponse extends BaseResponse {
      companyParameterId?: number;
      companyParameter?: CompanyParameterResponse;
      formNameId?: number;
      formName?: FormNameResponse;
      fieldName: string;
      displayColumnName: string;
      tableName: string;
  }
  
  export class CreateStandardColumnSetupRequest extends CreateActionRequest {
      companyParameterId?: number;
      formNameId?: number;
      fieldName: string;
      displayColumnName: string;
      tableName: string;
  }
  
  export class UpdateStandardColumnSetupRequest extends UpdateActionRequest {
      companyParameterId?: number;
      formNameId?: number;
      fieldName: string;
      displayColumnName: string;
      tableName: string;
  }
  
  export class LookupNameSetup extends BasicEntity {
      companyParameterId?: number;
      companyParameter?: CompanyParameter;
      formNameId?: number;
      formName?: FormName;
      lookUpStandardId?: number;
      lookUpStandard?: LookUpStandard;
      code: string;
      name: string;
      description: string;
  }
  
  export class LookupNameSetupResponse extends BaseResponse {
      companyParameterId?: number;
      companyParameter?: CompanyParameterResponse;
      formNameId?: number;
      formName?: FormNameResponse;
      lookUpStandardId?: number;
      lookUpStandard?: LookUpStandardResponse;
      code: string;
      name: string;
      description: string;
  }
  
  export class CreateLookupNameSetupRequest extends CreateActionRequest {
      companyParameterId?: number;
      formNameId?: number;
      lookUpStandardId?: number;
      code: string;
      name: string;
      description: string;
  }
  
  export class SearchParameterForLookupNameSetup {
      code: string;
  }
  
  export class UpdateLookupNameSetupRequest extends UpdateActionRequest {
      companyParameterId?: number;
      formNameId?: number;
      lookUpStandardId?: number;
      code: string;
      name: string;
      description: string;
  }
  