import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { CompanySettingSubVendor } from 'src/Models/CompanySettings/subvendorcompanysettings';
import { SubVendorSetupResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CompanySettingSubVendorService {
  private baseUrl: string = environment.apiUrl + 'SubVendorSetup/';

  constructor(private http: HttpClient) {}

  postsubvendorSetup(userTypeData: CompanySettingSubVendor): Observable<ResponseModelArray<CompanySettingSubVendor>> {
    const apiUrl = `${this.baseUrl}`;
    return this.http.post<ResponseModelArray<CompanySettingSubVendor>>(apiUrl, userTypeData).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  getAllSubVendorData(): Observable<ResponseModelArray<SubVendorSetupResponse>> {
    return this.http.get<ResponseModelArray<SubVendorSetupResponse>>(`${this.baseUrl}getData-ByCompany`);
  }





}
