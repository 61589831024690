import { Component, OnInit, Input } from '@angular/core';
import { AddNewStandardFilterComponent } from '../../Modal/add-new-standard-filter/add-new-standard-filter.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { CreateLookupFilterRequest, FilterSearchParameter, LookUpStandardResponse, LookupFilter, LookupFilterResponse, LookupNameSetupResponse } from '../../Models/LookupModels';
import { FieldDataType } from 'src/app/Core/Enums/data_types';
import { InternalUserPreDefinedCodes } from '../../Models/LookUpStandardFilterCodes';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { ApplicationGroupResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { RoleTypeService } from 'src/Service/Internaluser/role-type.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
@Component({
  selector: 'internal-user-filter-field',
  templateUrl: './internal-user-filter-field.component.html',
  styleUrls: ['./internal-user-filter-field.component.css']
})
export class InternalUserFilterFieldComponent implements OnInit {
  @Input() pageId: number;

  /* In between Start and End these code are different from other filters   */
  /* _______________________________________________________________ START DIFFERENCE __________________________________________________________________ */
  status: NzTreeNodeOptions[] = [];
  roleNodes: NzTreeNodeOptions[] = [];
  preDefinedDataCodes = {
    standardFilter: InternalUserPreDefinedCodes.standardFilter,
    name: InternalUserPreDefinedCodes.username,
    role: InternalUserPreDefinedCodes.role,
    status: InternalUserPreDefinedCodes.status,
  };
  /*__________________________________________________________________ END DIFFERENCE __________________________________________________________________ */

  filters: any[] = [];
  lookUpStandardResponse: LookUpStandardResponse[] = [];
  createLookupFilterRequests: CreateLookupFilterRequest[] = [];
  filterResponseData: LookupFilterResponse[] = [];
  defaultStandardFilter: LookupFilterResponse;
  predefinedValuesMultiSelect: string[] = [];
  isSetAsDefault: boolean;
  filterSearchParameter: FilterSearchParameter;
  selectedFilter: number = -1;
  editSaveFilterFormGroup: FormGroup;
  isFormDirty: boolean = false;
  formFieldValues: any = {};
  constructor(
    private internalUserService: InternalUserService,
    private fb: FormBuilder,
    public toastService: NzMessageService,
    private loadingIndicatorService: LoadingIndicatorService,
    private modal: NzModalService,
    private lookUpFilterService: LookupFilterService,
    private roleService: RoleTypeService,
    private accessLocalStorageService : AccessLocalStorageService
    
  ) { }

  ngOnInit(): void {
    this.initLoad();
    this.initEditSaveFilter();
  }

  initLoad() {
    this.lookUpFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(res => {
      let statusValues = res.result.filter(x => x.code === this.preDefinedDataCodes.status.code);


      this.status = [
        {
          title: 'Select All',
          value: 'select_all',
          key: 'select_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: statusValues.map((type) => ({
            title: type.name,
            value: type.id.toString(),
            key: type.id.toString(),
            isLeaf: true
          }))
        }
      ];
      this.roleService.getData().subscribe(
        (res: ResponseModelArray<ApplicationGroupResponse>) => {
          this.roleNodes = [
            {
              title: 'Select All',
              value: 'select_all',
              key: 'select_all',
              selectable: false,
              isLeaf: false,
              expanded: true,
              children: res.result.map((role) => ({
                title: role.name,
                value: role.id.toString(),
                key: role.id.toString(),
                isLeaf: true
              }))
            }
          ];
        },
        (error) => {
          console.error('Error fetching roles:', error);
        }
      );
    });

    const lookupFilter: LookupFilter = {
      formNameId: this.pageId,
    };
    this.getStandardFilterByFormPageId(this.pageId);
    this.fetchData(lookupFilter);
  }


    //  Status
    onStatusChange(selectedValues: string[]): void {
      const checkAllIndex = selectedValues.indexOf('select_all');
      if (checkAllIndex !== -1) {
        const allValues = this.status[0].children.map(child => child['value']);
        this.formFieldValues['status'] = [...allValues];
      } else {
        const allValues = this.status[0].children.map(child => child['value']);
        this.formFieldValues['status'] = selectedValues.filter(value => allValues.includes(value));
  
      }
    }

    //  Role
    onRoleChange(selectedValues: string[]): void {
      const checkAllIndex = selectedValues.indexOf('select_all');
      if (checkAllIndex !== -1) {
        const allValues = this.roleNodes[0].children.map(child => child['value']);
        this.formFieldValues['role'] = [...allValues];
      } else {
        const allValues = this.roleNodes[0].children.map(child => child['value']);
        this.formFieldValues['role'] = selectedValues.filter(value => allValues.includes(value));
  
      }
    }
  

  fetchData(lookUpStandard: LookupFilter): void {
    this.lookUpFilterService.postData(lookUpStandard).subscribe(
      data => {
        this.lookUpStandardResponse = data.result;
      },
      error => {
        console.error('Error fetching data:', error);
      }
    );
  }

  openStandardFilterComponent(type: string) {
    this.createLookupFilterRequests = [];
    this.createFormFieldsInArray();
    const modalRef = this.modal.create({
      nzContent: AddNewStandardFilterComponent,
      nzFooter: null,
      nzData:
      {
        pageId: this.pageId,
        std: this.filterResponseData,
        field: this.createLookupFilterRequests,
        clickFrom: type
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  getStandardFilterByFormPageId(pageId: number) {
    this.lookUpFilterService.getStandardFilterByFormPageId(pageId);
    this.lookUpFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.filterResponseData = result.result;
        this.initializeForm();
        setTimeout(() => {
          let id = this.accessLocalStorageService.getSelectedFilterId();
          this.setDefaultFieldValues(id);
        }, 100);

      }

    });
  }

  onFilterApply(): void {
    let selectedFilter = this.formFieldValues['standardFilter'];
    let isSelectedFilterStandard = this.filterResponseData.find(x => x.id === selectedFilter && x.isNotDeleted === true);
    if (isSelectedFilterStandard && isSelectedFilterStandard != undefined) {
      this.selectedFilter = 1;
    }
    else {
      this.selectedFilter = 0;
    }
    this.createFormFieldsInArray();
    this.createLookupFilterRequests[0].page = 1;
    this.createLookupFilterRequests[0].pageSize = 10;

    this.internalUserService.getAppliedFilterData(this.createLookupFilterRequests);


  }

  resetFilter(): void {
    Object.keys(this.formFieldValues).forEach(field => {
      if (field !== 'standardFilter') {
        if (Array.isArray(this.formFieldValues[field])) {
          this.formFieldValues[field] = [];
        } else {
          this.formFieldValues[field] = '';
        }
      }
    });
    this.selectedFilter = -1;
  }

  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
    this.isFormDirty = false;
  }

  createFormFieldsInArray() {
    this.createLookupFilterRequests = [];
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const value = this.formFieldValues[field];
      if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 0)) {
        const newRow = {
          globalId: '00000000-0000-0000-0000-000000000000',
          companyParameterId: 0,
          lookUpStandardId: 0,
          code: code,
          meaning: '',
          valueId: 0,
          formNameId: this.pageId,
          dataTypeId: 0,
          createLookupFilterParameterRequests: [null]
        };

        const correspondingResponse = this.lookUpStandardResponse.find(response => response?.code === code);
        if (correspondingResponse) {
          newRow.companyParameterId = correspondingResponse?.companyParameterId;
          newRow.lookUpStandardId = correspondingResponse?.id;
          newRow.dataTypeId = correspondingResponse?.dataTypeId;

          if (
            correspondingResponse.dataType?.code === FieldDataType.SingleLineText ||
            correspondingResponse.dataType?.name === 'Single-Line Text') {
            newRow.meaning = value;
          }
          if (
            correspondingResponse.dataType?.code === FieldDataType.Dropdown ||
            correspondingResponse.dataType?.name === 'Dropdown') {
            newRow.valueId = value;
          }
          if (
            correspondingResponse.dataType?.code === FieldDataType.MultiSelectDropdown ||
            correspondingResponse.dataType?.name === 'Multi-Select Dropdown'
          ) {
            newRow.createLookupFilterParameterRequests = value.map((item, index) => {
              return { valueId: item };
            });
          }
        }
        this.createLookupFilterRequests.push(newRow);
      }
    });
  }

  getDefaultFilterValue() {
   // this.defaultStandardFilter = this.filterResponseData.find(filter => filter.isSetAsDefault === true);
   // return this.defaultStandardFilter ? this.defaultStandardFilter.id : null;
    return this.accessLocalStorageService.getSelectedFilterId();
  }

  setDefaultFieldValues(standardFilterId: number): void {
    this.predefinedValuesMultiSelect = [];
    let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
    let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;

    Object.keys(this.formFieldValues).forEach(field => {
      if (field !== 'standardFilter') {
        if (Array.isArray(this.formFieldValues[field])) {
          this.formFieldValues[field] = [];
        } else {
          this.formFieldValues[field] = '';
        }
      }
    });


    if (lookUpFiltersField.length > 0) {
      Object.keys(this.preDefinedDataCodes).forEach(field => {
        const code = this.preDefinedDataCodes[field].code;
        const fieldData = lookUpFiltersField.find(filter => filter.code === code);
        if (fieldData != undefined) {
          if (
            fieldData.dataType?.code === FieldDataType.SingleLineText) {
            this.formFieldValues[field] = fieldData.meaning;
          }
          if (fieldData.dataType?.code === FieldDataType.Dropdown) {
            this.formFieldValues[field] = fieldData.id;
          }
          if (fieldData.dataType?.code === FieldDataType.MultiSelectDropdown) {
            let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
            this.predefinedValuesMultiSelect = multiSelectValues.map(String);
            this.formFieldValues[field] = this.predefinedValuesMultiSelect;
          }
        }
      });
    } else {
      console.log("lookUpFiltersField is null ", lookUpFiltersField);
    }
  }

  onChangeStandardFilter($event: number): void {
    this.selectedFilter = -1;
    const defaultFIlterId = this.getDefaultFilterValue();
    this.accessLocalStorageService.setSelectedFilterId($event);
    // New Changes By Aamir Ali - 22-Apr2024
    let id = this.accessLocalStorageService.getSelectedFilterId();
    this.setDefaultFieldValues(id);
    this.internalUserService.setSelectedFilterId(id);
    this.filterSearchParameter = {
      CompanyParameterId: 1,
      FormNameId: this.pageId,
      LookUpFilterId: $event,
      page: 1,
      pageSize: 10
    };
    if (defaultFIlterId !== $event) {
      this.internalUserService.userAppliedFilter(true);
    } else {
      this.internalUserService.userAppliedFilter(false);
    }
    this.internalUserService.getAllInternalUsersByFilter(this.filterSearchParameter);

  }

  private initEditSaveFilter(): void {
    this.editSaveFilterFormGroup = this.fb.group({
      globalId: ['00000000-0000-0000-0000-000000000000'],
      companyParameterId: [0],
      code: ['', Validators.required],
      name: ['', Validators.required],
      description: [''],
      meaning: [''],
      formNameId: [0],
      dataTypeId: [],
      isShareThisFilter: [true],
      isSetAsDefault: [false],
      createLookupFilterRequests: [[]],
    });
  }

  editSaveFilterData(): void {
    let selected_Filter_Id = this.formFieldValues['standardFilter'];
    let getSelectedFilterValue = this.filterResponseData.find(filter => filter.id === selected_Filter_Id);
    this.createFormFieldsInArray();
    this.editSaveFilterFormGroup.controls['name'].setValue(getSelectedFilterValue.name);

    if (this.editSaveFilterFormGroup.get('name')?.invalid) {
      this.toastService.error('Name field is required');
      return;
    }
    this.editSaveFilterFormGroup.controls['companyParameterId'].setValue(getSelectedFilterValue.companyParameterId);
    this.editSaveFilterFormGroup.controls['code'].setValue(getSelectedFilterValue.code);
    this.editSaveFilterFormGroup.controls['formNameId'].setValue(getSelectedFilterValue.formNameId);
    this.editSaveFilterFormGroup.controls['dataTypeId'].setValue(getSelectedFilterValue.dataTypeId);
    this.editSaveFilterFormGroup.controls['globalId'].setValue(getSelectedFilterValue.globalId);
    this.editSaveFilterFormGroup.controls['createLookupFilterRequests'].setValue(this.createLookupFilterRequests);
    this.editSaveFilterFormGroup.controls['isSetAsDefault'].setValue(getSelectedFilterValue.isSetAsDefault);
    this.lookUpFilterService.saveStandardFilterData(this.editSaveFilterFormGroup.value)
      .subscribe(
        (res: any) => {
          console.log(res.result);
          setTimeout(() => {
            this.loadingIndicatorService.hide();
            this.toastService.success('Filter saved successfully');
            this.getStandardFilterByFormPageId(this.pageId);
            this.selectedFilter = -1;
          }, 20);
        },
        (error) => {
          console.error('Error:', error);
          this.loadingIndicatorService.hide();
          if (error.status === 500) {
            this.toastService.error('Internal server error. Please try again later.');
          } else {
            this.toastService.error('An error occurred. Please try again.');
          }
        }
      );
    this.selectedFilter = -1;
  }

  /* _____________________________________ START DIFFERENCE _________________________________________________ */
  initializeForm(): void {
    this.formFieldValues = {
      standardFilter: this.getDefaultFilterValue(),
      name: '',
      role: [] = [],
      status: [] = [],
    };
  }
  /* ____________________________________ END DIFFERENCE _____________________________________________________ */

}