<nz-page-header class="p-0">
    <nz-page-header-title class="p-0">
    </nz-page-header-title>
    <nz-page-header-extra>
      <nz-space>
        <button nz-button nzType="default" nz-popover
        [nzPopoverContent]="contentTemplate" nzPopoverPlacement="left"
         class="me-1"><h5>
          <i class="bi bi-gear-fill fs-6"></i>
        <ng-template #contentTemplate>
          <div class="rounded-2">
            <p style="font-size: 13px">
              Schedule Setting
            </p>
          </div>
        </ng-template>
        </h5>
        </button>
        <nz-divider nzType="vertical" class="divder"></nz-divider>
        <nz-switch [(ngModel)]="switchValue1"></nz-switch>
          <span class="ms-1 me-2 spanset">Schedule Online</span>
          <button nz-button nzType="default" class="ms-2 rounded-1" >
              <i class="bi bi-mortarboard-fill"></i>&nbsp;&nbsp;Learn More</button>
          <button nz-button nz-dropdown [nzTrigger]="'click'" [nzDropdownMenu]="menu4" class="ms-2 rounded-1">
              More Action<span nz-icon nzType="down"></span>
            </button>
            <nz-dropdown-menu #menu4="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item >Export to Excel</li>
                <li nz-menu-item >Export to PDF</li>
                Project_Man_WorkDay_Excep
              </ul>
            </nz-dropdown-menu>
          <button nz-button nzType="default" (click)="openFilter(Project_Man_WorkDay_Excep,PAGE_ID)" class="ms-2 rounded-1" >
              <i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter</button>
          <button  nz-button nzType="primary" class="ms-2 rounded-1" 
          >Add Workday Exception</button>
      </nz-space>
    </nz-page-header-extra>
    
  </nz-page-header>

