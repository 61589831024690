import { Component } from '@angular/core';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';

@Component({
  selector: 'app-notification-dashboard',
  templateUrl: './notification-dashboard.component.html',
  styleUrls: ['./notification-dashboard.component.css']
})
export class NotificationDashboardComponent {
  isCollapsed = false;
  selectedJobId: number = 0;

  constructor(private filterService: FilterSideMenuBarService) {}

  PAGE_ID: number = 27;
  Messaging_Notification_History: string = FiltersName.Messaging_Notification_History;


  
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
openFilter(component: string, pageId: number) {
  this.filterService.toggleFilter(component, pageId);
  console.log('component, pageId',component, pageId);
  
}
    // Selected Job Id From Left Side Job List
    getSelectedJobId(item: number){
      this.selectedJobId = item;
      console.log("selectedJobId : ", this.selectedJobId);
    }
}
