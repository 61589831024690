import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { NzSelectSizeType } from 'ng-zorro-antd/select';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { JobInformationResponse } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { CostCode } from '../../../../../Models/CostCode/costCode';
import { LeadOpportunityResponse, LookupNameSetupResponse,} from 'src/Models/LeadTags';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { CostCodeService } from 'src/Service/Internaluser/cost-code.service';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CosCodeModelComponent } from 'src/app/company-settings/cos-code-model/cos-code-model.component';
import {
  ApplicationUserResponse,
  CostCodeCategoryResponse,
} from 'src/Models/InternalUser/applicationUserResponse';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { TimeClockService } from 'src/Service/TimeClock/time-clock.service';
import { ShiftInformationResponse } from 'src/Models/Time-Clock/clock-in';

@Component({
  selector: 'app-time-clock-clock-in',
  templateUrl: './time-clock-clock-in.component.html',
  styleUrls: ['./time-clock-clock-in.component.css'],
})
export class TimeClockClockInComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Input() pageId: number;
  @Input() costCodeResponse: CostCodeCategoryResponse;
  @Input() timeGridRes: ShiftInformationResponse;
  @Input() clockInResponse: ShiftInformationResponse;
  out
  PAGE_ID: number = 11;
  Taghandle = true;
  jobList: LeadOpportunityResponse[] = [];
  costSubCode: CostCode[] = [];
  tagNodes: NzTreeNodeOptions[] = [];
  userNodes: NzTreeNodeOptions[] = [];
  userList: ApplicationUserResponse[] = [];
  selectedLeadId: any;
  filteredJobList: JobInformationResponse[] = [];
  costCode: { label: string; value: string }[] = [];
  TagsForm: FormGroup;
  shiftCardShow : boolean = false ;
  isLoadingEdit: boolean = false;
  public isTagVisible = false;
  isLoadingOne: boolean = false;
  isDeleteTagsLoading: boolean;
  formFieldValues: any = {};
  currentTime: Date;
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  confirmModal: import('ng-zorro-antd/modal').NzModalRef<unknown, any>;
  timeClockForm: any = {};
  listOfOption: Array<{ label: string; value: string }> = [];
  size: NzSelectSizeType = 'default';
  singleValue = 'a10';
  multipleValue = ['a10', 'c12'];
  tagValue = [];
  currentSelectedJob: string = "";

  preDefinedDataCodes = {
    tags: { code: 'PMTCT' },
  };

 
  constructor(
    private modal: NzModalService,
    private fb: FormBuilder,
    private jobsService: JobInformationService,
    private costCodeServices: CostCodeService,
    private LookupFilterServices: LookupFilterService,
    private loadingIndicatoreService: LoadingIndicatorService,
    public localStorageService: AccessLocalStorageService,
    private leadOppService: LeadOpportunitiesService,
    private toastsService: NzMessageService,
    private internalUserservice: InternalUserService,
    private timeClockIn: TimeClockService,
    private toastssService: NzMessageService,
    @Inject(NZ_MODAL_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.jobsService.getData().subscribe((res) => {
      this.jobList = res.result;
    });

    this.initLoad();
    this.iniliazeForm();
    this.internalUserGetAll();
    this.patchClockinInformation();

    this.updateTime(); setInterval(() => {
    this.updateTime();}, 1000);

    const jobId = this.localStorageService.getJobId();
    this.timeClockForm = {
      globalId: '00000000-0000-0000-0000-000000000000',
      exsistingJobInformationId: this.data?.data?.jobInformation?.id,
      switchIntoJobInformationId: jobId, 
      timeClockSetupId: 0,
      jobSetupId: 0,
      jobInformationId: jobId,
      startOn: new Date(Date.now() - new Date().getTimezoneOffset() * 60000),
      endOn: null,
      startTime: new Date(Date.now() - new Date().getTimezoneOffset() * 60000),
      endTime: null,
      costCodeId: 0,
      notes: '',
      isClockIn: true,
      applicationUserIds: [],
      createShiftTagParameterRequests: [],
    };
    
    this.internalUserservice.getData().subscribe((res) => {
      this.userList = res.result;
      const userId = this.localStorageService.getUserLoginId();
      const user = this.userList.find((user) => user.id === userId);
      if (user) {
        this.timeClockForm['applicationUserIds'] = [user.id.toString()]; 
      }
    });
    
    
// ///////  Get Cost Code Data ///////////////
    this.costCodeServices
      .getCategoryData()
      .subscribe((res: ResponseModelArray<CostCode>) => {
        this.costSubCode = res.result.filter(
          (cost) => cost.timeClockLaborCode === true
        );
      });
  }
  updateTime() {
    this.currentTime = new Date();
  }

  initLoad() {
    this.LookupFilterServices.getLookUpValuesByFormNameId(
      this.PAGE_ID
    ).subscribe((res) => {
      let tags = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.tags.code
      );
      this.lookupSetNameResponse = res.result;
      this.tagNodes = tags.map((tags) => ({
        title: tags.name,
        value: tags.id.toString(),
        key: tags.id.toString(),
        isLeaf: true,
      }));
    });
  }

// /////// Get Internal User Data ///////////////
  internalUserGetAll() {
    this.internalUserservice.getData().subscribe((res) => {
      this.userList = res.result;
      this.userNodes = this.userList.map((data) => ({
        title: data.firstName + data.lastName,
        value: data.id.toString(),
        key: data.id.toString(),
        isLeaf: true,
      }));  
    });
  }
 

//  /////// ye method save clockIn k lye hai /////////////
  saveClockIn() {
    let tag = this.timeClockForm.createShiftTagParameterRequests.map((id: string) => ({
      shiftInformationId: null,
      shiftTagSetupId: id
    }));
  
    this.timeClockForm.createShiftTagParameterRequests = tag;
    if(this.data?.data?.applicationUserId > 0){
      const jobId = this.timeClockForm.jobInformationId;
      if(jobId === this.data?.data?.jobInformation?.id){
        return;
      }
     // const selectedJob = this.jobList.find((job) => job.id === jobId);
      if (jobId !== this.data?.data?.jobInformation?.id) {
        this.currentSelectedJob = this.jobList.find(x => x.id === this.timeClockForm.jobInformationId).name;
        this.shiftCardShow = true; 
    }
    }else{
      this.timeClockIn.PostTimeClock(this.timeClockForm).subscribe((response) => {
        this.toastssService.success('New  Successfully!');
        this.timeClockForm = {};
        this.modal.closeAll();
      });
    }
   
  }

// ye method job Switch Shift k lye hai ///////////

shiftClock() {
  if (this.timeClockForm.isClockIn) {

    this.timeClockForm.endTime = new Date();
    this.timeClockForm.existingJobInformationId = this.timeClockForm.jobId;

    this.timeClockForm.switchIntoJobInformationId = this.timeClockForm.jobInformationId;

    if (this.timeClockForm.existingJobInformationId !== this.timeClockForm.switchIntoJobInformationId) {
      console.log('Pehli job alag hai naye job se.');
    } else {
      console.log('Pehli job nayi job ke barabar hai.');
    }
  }

  console.log('Payload baad shiftClock:', this.timeClockForm);
  this.timeClockIn.PostShiftIn(this.timeClockForm).subscribe((response) => {
    this.toastssService.success('New  Successfully!');
    this.timeClockForm = {};
    this.modal.closeAll();
  });
}


  /////// Shift Cancel Model //////////// 
shiftCancel(): void {
  this.shiftCardShow = false;
}
  
  // selectionChoiceModel
  newcostcode(): void {
    const modalRef = this.modal.create({
      nzContent: CosCodeModelComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  // ------  Tags Methods Start ---   ///

  iniliazeForm() {
    this.TagsForm = this.fb.group({
      id: [''],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      name: ['', Validators.required],
      companyParameterId: [1],
      formNameId: this.PAGE_ID,
      lookUpStandardId: [94],
      parentCode: ['PMTCT'],
      code: ['PMTCT'],
      description: [''],
      isFieldValue: [true],
    });
  }

  SaveOrUpdateData() {
    if (this.isLoadingEdit) {
      this.editData();
    } else {
      this.SaveData();
    }
  }

  SaveData() {
    if (this.TagsForm.valid) {
      this.loadingIndicatoreService.show();
      this.isLoadingOne = true;
      setTimeout(() => {
        this.leadOppService.getLookUpNameSetup(this.TagsForm.value).subscribe(
          (response) => {
            this.loadingIndicatoreService.hide();
            const tagName = this.TagsForm.get('name')?.value;
            this.isTagVisible = false;
            this.iniliazeForm();
            this.initLoad();

            this.isLoadingOne = false;
            this.toastsService.success(`${tagName} saved successfully`);
          },
          (error) => {
            this.loadingIndicatoreService.hide();
            if (error.status === 500) {
              this.toastsService.error(
                'Internal Server Error. Please try again later.'
              );
              this.isLoadingOne = false;
            } else if (error.status === 400) {
              this.isLoadingOne = false;
              this.toastsService.error('Bad Request. Please check your input.');
            } else {
              this.toastsService.error(
                'Failed to save division trade. Please try again.'
              );
            }
          }
        );
      }, 1000);
    } else {
      this.TagsForm.markAllAsTouched();
    }
  }

  editData() {
    if (this.TagsForm.valid) {
      this.loadingIndicatoreService.show();
      const tagName = this.TagsForm.get('name')?.value;
      setTimeout(() => {
        this.leadOppService.getLookUpNameSetup(this.TagsForm.value).subscribe(
          (response: any) => {
            this.loadingIndicatoreService.hide();
            this.isTagVisible = false;
            this.iniliazeForm();
            this.initLoad();
            this.isLoadingEdit = false;
            this.toastsService.success(`${tagName} Update successfully`);
          },
          (error) => {
            this.loadingIndicatoreService.hide();
            if (error.status === 500) {
              this.toastsService.error(
                'Internal Server Error. Please try again later.'
              );
            } else if (error.status === 400) {
              this.toastsService.error('Bad Request. Please check your input.');
            } else {
              this.toastsService.error(
                'Failed to save division trade. Please try again.'
              );
            }
          }
        );
      }, 1000); // 2000 milliseconds (2 seconds)
    } else {
    }
  }

  ConfirmDeleteTags(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete Tags?',
      nzContent:
        'This Tags is not applied to any subs/vendors and will be deleted.',
      nzOkDanger: true,
      nzOkText: 'Delete',
      nzCancelText: 'Cancel',
      nzModalType: 'confirm',
      nzOkLoading: this.isDeleteTagsLoading,
      nzAutofocus: 'ok',
      nzCentered: true,
      nzOnOk: () => this.DeleteData(),
    });
  }

  DeleteData(): Promise<boolean> {
    const vendorId = this.TagsForm.get('globalId')?.value;
    const tagName = this.TagsForm.get('name')?.value;
    this.isDeleteTagsLoading = true;
    this.loadingIndicatoreService.show();

    return new Promise<boolean>((resolve, reject) => {
      this.leadOppService.deleteDataLookupNameSetup(vendorId).subscribe(
        (res: any) => {
          this.isDeleteTagsLoading = false;
          setTimeout(() => {
            this.toastsService.success(`${tagName} Deleted successfully`);
            this.isTagVisible = false;
            this.loadingIndicatoreService.hide();
            this.confirmModal.destroy();
            resolve(true);
          }, 1000);
          this.isLoadingEdit = false;
          this.isTagVisible = false;
          this.iniliazeForm();
          this.initLoad();
        },
        (error) => {
          this.isDeleteTagsLoading = false;
          this.isTagVisible = false;
          reject(false);
        }
      );
    });
  }

  showConfirmData(): void {
    if (this.TagsForm.dirty) {
      // The form has unsaved changes, show confirmation modal
      this.confirmModal = this.modal.confirm({
        nzTitle: '<b>Save Changes?</b>',
        nzContent:
          '<p>This Lead Tags has unsaved changes. Do you want to save your changes before closing??</p> ',
        nzOkText: 'Save',
        nzCancelText: "Don't Save",
        nzOnOk: () => {
          // User confirmed to discard changes, save and close modal
          this.SaveData();
        },
        nzOnCancel: () => {
          // User canceled, reset form and close modal
          this.TagsForm.reset();
          this.isTagVisible = false;
        },
        nzCloseIcon: 'false',
        nzStyle: { top: '250px' },
        nzClassName: 'custom-modal-content',
      });
    } else {
      // No unsaved changes, close the modal directly
      this.TagsForm.reset();
      this.isTagVisible = false;
    }
  }

  showTagEdit(selectedValue: LookupNameSetupResponse): void {
    if (selectedValue) {
      const selectedId: number = Number(selectedValue); // Convert the selected ID to number
      // console.log('selectedId', selectedId);
      this.isTagVisible = true;
      this.isLoadingEdit = true;
      const selectedObjectFromLookup = this.lookupSetNameResponse.find(
        (item) => item.id === selectedId
      );
      if (selectedObjectFromLookup) {
        const selectedData = selectedObjectFromLookup;
        if (selectedData) {
          this.TagsForm.controls['name'].setValue(selectedData.name);
          this.TagsForm.controls['globalId'].setValue(selectedData.globalId);
          this.TagsForm.controls['isFieldValue'].setValue(
            selectedData.isFieldValue
          );
          this.TagsForm.controls['companyParameterId'].setValue(
            selectedData.companyParameterId
          );
          this.TagsForm.controls['formNameId'].setValue(
            selectedData.formNameId
          );
          this.TagsForm.controls['lookUpStandardId'].setValue(
            selectedData.lookUpStandardId
          );
          this.TagsForm.controls['code'].setValue(selectedData.code);
          this.TagsForm.controls['description'].setValue(
            selectedData.description
          );
          this.TagsForm.controls['parentCode'].setValue(
            selectedData.parentCode
          );
        }
      }
    }
  }

  showTags(): void {
    this.loadingIndicatoreService.show();

    setTimeout(() => {
      this.loadingIndicatoreService.hide();
      this.isTagVisible = true;
      this.isLoadingEdit = false;
    }, 1000);
  }

  // ------  Tags Methods End ---   ///

  //  Pethch Data Clock In //////////

  patchClockinInformation() {
    if (this.clockInResponse) {
      this.formFieldValues.patchValue({
        globalId: this.clockInResponse.globalId,
        timeClockSetupId: this.clockInResponse.timeClockSetupId,
        jobSetupId: this.clockInResponse.jobSetupId,
        jobInformationId: this.clockInResponse.jobInformationId,
        startTime: this.clockInResponse.startTime,
        endTime: this.clockInResponse.endTime,
        costCodeId: this.clockInResponse.costCodeId,
        notes: this.clockInResponse.notes,
        isClockIn: this.clockInResponse.isClockIn,
        clockBy: this.clockInResponse.clockBy,
        applicationUserIds: this.clockInResponse.applicationUserId,
        shiftTagParameters: this.clockInResponse.shiftTagParameters,

        // Add other form controls if needed
      });
    }
  }
}
