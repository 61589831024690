<div class="card-head border-bottom">
    <div class="row mb-1 p-0 d-flex align-items-center">
      <div class="col">
        <span class="fw-medium fs-6"> Messaging</span>
      </div>

    </div>
  </div>

  <div class="container-fuild mt-3">
    <div class="card-body">
      <div class="row">
      <div class="col-12">
          <div class="row">
              <div class="col-md-6">
                  <div class="row">
                      <div class="col-md-12">
                          <img src="assets/logo-icon-128.png" alt="Profile Image" class="img-fluid rounded-circle" width="55" height="55">
                      </div>
                  </div>
                  <div class="row mt-3"><label class="pira-label" >Sub/Vendor is not linked to a Xero Contact.</label></div>
      
                  <div class="row">
                      <div class="col-12">
                            <nz-radio-group [(ngModel)]="radioValue">
                              <label nz-radio nzValue="A">Link to Xero and create a new Xero Contact</label>
                              <label nz-radio nzValue="Link to Xero and an existing Xero Contact">Link to Xero and an existing Xero Contact</label>
                              <label nz-radio nzValue="Don't link to Xero">Don't link to Xero</label>
                            </nz-radio-group>
                      </div>
                  </div>
                  <!-- Right side with 6 columns (Empty for now, adjust as needed) -->
                  <div class="col-md-6">
                      <!-- Right side content goes here -->
                  </div>
              </div>
          </div>
      </div>
      </div>
      </div>
  </div>