import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SubVendor } from 'src/Models/CompanySettings/Selections/SubVendor';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { ResponseModel, ResponseModelArray, TablePageResponse } from 'src/Models/responseMessage.model';
import { CreateLookupFilterRequest, FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SubVendorService {

  private baseUrl: string = environment.apiUrl + 'SubVendor/';
  private base_Url: string = environment.apiUrl + 'LookupFilter/';

  getSubVendor = new BehaviorSubject<ResponseModel<TablePageResponse<SubVendorResponse>>>(null);
  $getSubVendors = this.getSubVendor.asObservable();

  selectedFilterId = new BehaviorSubject<number>(0);
  $selectedFilterId = this.selectedFilterId.asObservable();

  isUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $isUserAppliedFilter = this.isUserAppliedFilter.asObservable();

  constructor(private http: HttpClient) {}

  postData(data: SubVendor): Observable<SubVendor> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }

  getData(): Observable<ResponseModelArray<SubVendorResponse>> {
    return this.http.get<ResponseModelArray<SubVendorResponse>>(`${this.baseUrl}getAll`);
  }

  getSubVendorByFilter(filterSearchParameter : FilterSearchParameter){
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getSubVendor.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  getAppliedFilterData(appliedFilter: CreateLookupFilterRequest[]) {
    const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
    return this.http.post<any>(apiUrl, appliedFilter).subscribe(
      (data) => {
        this.getSubVendor.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }

  setSelectedFilterId(filterId: number){
    this.selectedFilterId.next(filterId);
  }

  userAppliedFilter(filter: boolean){
    this.isUserAppliedFilter.next(filter);
  }


  



  updateData(data: SubVendor): Observable<SubVendor> {
    return this.http.put<SubVendor>(`${this.baseUrl}`, data);
  }

  deleteData(id: number): Observable<SubVendor> {
    return this.http.delete<SubVendor>(
      `${this.baseUrl}?globalId=${id}`
    );
  }
  getDataId(id: number): Observable<SubVendor> {
    return this.http.get<SubVendor>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }

  getAllSubsByFilter(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getSubVendor.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }

}