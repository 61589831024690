import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { InviteSubsVendorComponent } from '../invite-subs-vendor/invite-subs-vendor.component';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { SubsVendors } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { SubVendersService } from 'src/Service/SubVendorsServices/sub-venders.service';
import { CreateSubVendorCertificateParameterForPdfRequest, CreateSubVendorCertificateParameterRequest, SubVendorCertificateParameterResponse, SubVendorCertificateSetupResponse, SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { WeeklyEmailReminderDaySetupService } from 'src/Service/SubVendorsServices/weekly-email-reminder-day-setup.service';
import { ReminderLimitSetupService } from 'src/Service/SubVendorsServices/reminder-limit-setup.service';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { colorInformation } from 'src/Models/Job-List/Job-Information/color';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { SubVendorCertificateService } from 'src/Service/SubVendorsServices/sub-vendor-certificate.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment.prod';
// import { IsLatLong } from 'class-validator';
@Component({
  selector: 'app-add-sub-vendor',
  templateUrl: './add-sub-vendor.component.html',
  styleUrls: ['./add-sub-vendor.component.css']
})
export class AddSubVendorComponent implements OnInit {
  IMG_BASE: string = environment.IMG_BUCKET_URL;
  @Input() subVendorResponseId: number;
  @ViewChild('phoneInput') phoneInput: NgForm;
  @Output() cancel = new EventEmitter<void>();
  @Input() GridDataResponse: SubVendorResponse;
  @Input() subVButton: boolean;
  formFieldValues: any = {};
  SubVendorCertificate: any = {};
  countryCode: CountryISO;
  dialCode: string;
  CountryISO = CountryISO;
  separateDialCode = true;
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [CountryISO.Australia, CountryISO.Australia];
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  weeklyEmailReminderOptions: colorInformation[] = [];
  subVendorCertificateSetup: any[] = [];
  subVendorCertificateSetupres: SubVendorCertificateParameterResponse[] = [];
  reminderLimitSetup: colorInformation[] = [];
  tagNodes: NzTreeNodeOptions[] = [];
  newSubVendor = [1, 2, 3, 4, 5];
  items: any[] = [];
  fileList: NzUploadFile[] = []
  phoneInputValid: boolean = false;
  ph: boolean = false;
  pageId: number = 12;
  size: NzButtonSize;
  SubvendorModal = true;
  isSaveSubVendorsLoadingEdit: boolean = false;
  jobGroupVisible: boolean = false;
  manageEmailsVisible: boolean = false;
  TagsForm: FormGroup;
  isLoadingEdit: boolean = false;
  isLoadingOne: boolean = false;
  public ModalTitle: string = '';
  confirmModal?: NzModalRef;
  isDeleteTagsLoading: boolean = false;
  @Output() onSaveComplete = new EventEmitter();
  showLoopContent: boolean = false;
  @Input() subVendorResponse: SubVendorResponse;
  subVendorCertificateParameters: SubVendorCertificateParameterResponse;
  isDeleteLoading: boolean = false;
  uploadUrl: string = '';
  preDefinedDataCodes = {Division: SubsVendors.Division,};
  createSubVendor : CreateSubVendorCertificateParameterForPdfRequest[];
  maxItems: number = 6;
  itemss: [] = [];
  index: number = 0;
  selectedFiles: File[] = [];
  selectedLabel: string | undefined;
  validLabels: string[] = [];
  validItems: any[] = [];
  isSaveSubVendor: boolean = false;
  isSaveAndCloseSubVendor: boolean = false;
  isSaveAndNewSubVendor: boolean = false;

  constructor(
    public modal: NzModalService,
    private LookupFilterService: LookupFilterService,
    private SubVendorsService: SubVendersService,
    private cd: ChangeDetectorRef,
    private WeeklyEmailReminderDaySetupService: WeeklyEmailReminderDaySetupService,
    private subVendorCertificateService: SubVendorCertificateService,
    private ReminderLimitSetupService: ReminderLimitSetupService,
    private toastService: NzMessageService,
    private fb: FormBuilder,
    private loadingIndicatoreService: LoadingIndicatorService,
    private leadOppService: LeadOpportunitiesService,
    @Inject(NZ_MODAL_DATA) public activityData: any,
  ){}
  ngOnInit(): void {
    this.subVendorGetById();
    this.leadProFormGroup();
    this.addNewRow();
    this.initLoad();
    this.getColor();
    this.iniliazeForm();
  }
  subVendorGetById(){
    if (this.subVendorResponseId) {
      this.SubVendorsService.subVendorgetDataId(this.subVendorResponseId).subscribe(
        (res) => {
          this.subVendorResponse = res.result;
          if (this.subVendorResponse) {
            this.patchFormValues(this.subVendorResponse);
          }
        },
      )
    }
  }
  patchFormValues(subVendorResponse: SubVendorResponse){
    this.GridDataResponse = subVendorResponse;
    const expirationDate = this.SubVendorCertificate.expirationDate || new Date();
    const datePipe = new DatePipe('en-US');
    const formattedExpirationDate = datePipe.transform(expirationDate, 'yyyy-MM-dd HH:mm:ss');
    this.formFieldValues['globalId'] = this.GridDataResponse?.globalId;
    this.formFieldValues['companyName'] = this.GridDataResponse?.companyName;
    this.formFieldValues['primaryContactFirstLastName'] = this.GridDataResponse?.primaryContactFirstLastName;
    this.formFieldValues['businessPhone'] = this.GridDataResponse?.businessPhone;
    this.formFieldValues['fax'] = this.GridDataResponse?.fax;
    this.formFieldValues['streetAddress'] = this.GridDataResponse?.streetAddress;
    this.formFieldValues['cellPhone']  = subVendorResponse?.cellPhone;
    this.cd.detectChanges();
    this.formFieldValues['countryCode'] = this.GridDataResponse?.countryCode;
    this.formFieldValues['dailCode'] = this.GridDataResponse?.dailCode;
    this.formFieldValues['suburb'] = this.GridDataResponse?.suburb;
    this.formFieldValues['state'] = this.GridDataResponse?.state;
    this.formFieldValues['postalCode'] = this.GridDataResponse?.postalCode;
    this.formFieldValues['viewOwnerInformation'] = this.GridDataResponse?.viewOwnerInformation;
    this.formFieldValues['automaticallyPermitAccessToNewJobs'] = this.GridDataResponse?.automaticallyPermitAccessToNewJobs;
    this.formFieldValues['shareCommentsAndDocumentsWithOwner'] = this.GridDataResponse?.shareCommentsAndDocumentsWithOwner;
    this.formFieldValues['assignRFIsToOtherSubsVendors'] = this.GridDataResponse?.assignRFIsToOtherSubsVendors;
    this.formFieldValues['notifyMeWhen'] = this.GridDataResponse?.notifyMeWhen;
    this.formFieldValues['notifyMeWhenOrMoreScheduleItemsConflict'] = this.GridDataResponse?.notifyMeWhenOrMoreScheduleItemsConflict;
    this.formFieldValues['holdPaymentsToTheSubvVendor'] = this.GridDataResponse?.holdPaymentsToTheSubvVendor;
    this.formFieldValues['holdPaymentsNotes'] = this.GridDataResponse?.holdPaymentsNotes;
    this.formFieldValues['defaultPaymentEmailAddress'] = this.GridDataResponse?.defaultPaymentEmailAddress;
    this.formFieldValues['notes'] = this.GridDataResponse?.notes;
    
    this.formFieldValues.createDivisionTradeParameterRequests = this.GridDataResponse?.divisionTradeParameters.map(param => param.divisionTradeId.toString());
    this.formFieldValues.createSubVendorCertificateParameterRequests = this.GridDataResponse?.divisionTradeParameters.map(param => param.divisionTradeId.toString());

    if (this.GridDataResponse?.subVendorCertificateParameters) {
      this.GridDataResponse?.subVendorCertificateParameters.forEach(param => {
        const jobVenodrs: any = {
          expirationDate: param.expirationDate || '',
          weeklyEmailReminderDays: param.weeklyEmailReminderDays || '',
          weeklyEmailReminderDaySetupId: param.weeklyEmailReminderDaySetupId || '',
          reminderLimitSetupId: param.reminderLimitSetupId || '',
        };
        this.SubVendorCertificate = jobVenodrs;
      });
    }
    let test =  this.GridDataResponse.subVenderManageEmails.map(x => x?.email)
    test = null
    if (this.GridDataResponse?.subVenderManageEmails) {
      this.items = this.GridDataResponse.subVenderManageEmails.map(item => ({
        email: item.email,
        label: item.label || ''
      }));
      this.patchValues();
    } else {
      console.error('No items found in GridDataResponse.subVenderManageEmails');
    }
    if (this.GridDataResponse.subVendorCertificateParameters?.[0]?.pdfFileUrl) {
      this.fileList = [{
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: this.IMG_BASE + this.GridDataResponse.subVendorCertificateParameters[0].pdfFileUrl,
      }];
    } else {
      this.fileList = [];
    }
  }
  leadProFormGroup(): void {
    this.formFieldValues = {
      globalId: '00000000-0000-0000-0000-000000000000',
      companyParameterId: 1,
      companyName: '',
      primaryContactFirstLastName: '',
      businessPhone: '',
      fax: '',
      streetAddress: '',
      countryCode: this.CountryISO.Australia,
      dialCode: '',
      statusSetupId: 0,
      cellPhone: '',
      suburb: '',
      state: '',
      postalCode: '',
      viewOwnerInformation: null,
      automaticallyPermitAccessToNewJobs: null,
      shareCommentsAndDocumentsWithOwner: null,
      assignRFIsToOtherSubsVendors: null,
      notifyMeWhen: false,
      notifyMeWhenOrMoreScheduleItemsConflict: 0,
      holdPaymentsToTheSubvVendor: false,
      holdPaymentsNotes: '',
      defaultPaymentEmailAddress: '',
      notes: '',
      createDivisionTradeParameterRequests: [],
      createSubVenderManageEmailRequests: [],
    };

    this.SubVendorCertificate = {
      SubVendorId: '',
      subVendorCertificateSetupId: 0,
      expirationDate: null,
      weeklyEmailReminderDays: 0,
      weeklyEmailReminderDaySetupId: 0,
      reminderLimitSetupId: 0,
    };
    this.validItems = [];
  }
  downloadFile(filePath: string) {
    this.SubVendorsService.FileDownload(filePath).subscribe(
      (response: Blob) => {
        const url = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = url;
        a.download = filePath.split('/').pop();
        a.click();
        window.URL.revokeObjectURL(url);
      },
      error => {
        console.error('Download failed', error);
      }
    );
  }
  iniliazeForm() {
    this.TagsForm = this.fb.group({
      id: 0,
      globalId: '00000000-0000-0000-0000-000000000000',
      name: ['', Validators.required],
      companyParameterId: 1,
      formNameId: this.pageId,
      lookUpStandardId: 0,
      parentCode: 'SVD',
      code: 'SVD',
      description: '',
      isFieldValue: true,
    });
  }
  patchValues(): void {
    const patchedValues = this.items.map(item => ({
      email: item.email,
      label: item.label.trim() === '' ? item.email : item.label
    }));
    this.validItems = patchedValues;
    this.formFieldValues.createSubVenderManageEmailRequests = patchedValues;
  }
  newSubVendorTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'Additional Information';
      case 2:
        return 'Notifications';
      case 3:
        return 'Job Access';
      case 4:
        return 'Accounting';
      case 5:
        return 'Trade Agreement';
      default:
        return '';
    }
  }
  closeDialog() {
    this.cancel.emit();
  }
  onPhoneInputChange() {
    this.phoneInputValid = !this.phoneInput.invalid;
  }
  isSaveEnabled(): boolean {
    if (this.formFieldValues.state?.length > 3) {
      return false;
    }
    return !!this.formFieldValues.companyName && this.phoneInputValid;
  }
  onFileSelected(event: NzUploadChangeParam, certificate?: any): void {
  const fileList: NzUploadFile[] = event.fileList;
  if (fileList.length > 0) {
    const nzFile: NzUploadFile = fileList[0];
    const file: File = nzFile.originFileObj as File;

    if (certificate) {
      certificate.pdfFileUrl = this.IMG_BASE + file.name;
    } else {
      this.SubVendorCertificate.pdfFileUrl = this.IMG_BASE + file.name;
    }
    this.fileList = [{
      uid: '-1',
      name: file.name,
      status: 'done',
      url: this.IMG_BASE + file.name,
    }];
  }
  }
  openPdf(url: string) {
  window.open(url, '_blank');
  }
  isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  saveFileIdToDatabase(fileId: string) {
  }
  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(
      (res) => {
        this.lookupSetNameResponse = res.result;
        
        let tagValues = res.result.filter(
          (x: { code: string; }) => x.code === this.preDefinedDataCodes.Division.code
        );
        
        this.tagNodes = tagValues.map((status: { name: any; id: { toString: () => any; }; }) => ({
          title: status.name,
          value: status.id.toString(),
          key: status.id.toString(),
          isLeaf: true,
        }));
      }
    );
  }
  findUrlProperty(response: any): any {
    if (response && typeof response === 'object') {
      for (const key in response) {
        if (typeof response[key] === 'string' && response[key].startsWith('http')) {
          return response[key];
        } else if (typeof response[key] === 'object') {
          const urlProperty = this.findUrlProperty(response[key]);
          if (urlProperty) {
            return urlProperty;
          }
        }
      }
    }
    return null;
  }
  getColor() {
    this.WeeklyEmailReminderDaySetupService.getData().subscribe((res: any) => {
      const colorGet = res.result;
      this.weeklyEmailReminderOptions = colorGet;
    })
    this.ReminderLimitSetupService.getData().subscribe((res: any) => {
      const colorGet = res.result;
      this.reminderLimitSetup = colorGet;
    })

   this.subVendorCertificateService.getData().subscribe((res: any) => {
  const certificates: SubVendorCertificateSetupResponse[] = res.result;
  certificates.forEach((certificate, index) => {
    certificate.subVendorCertificateSetupId = index + 1;
  });
  this.subVendorCertificateSetup = certificates;
});

  }
  InviteSubsVendorOpen() {
    const modalRef = this.modal.create({
      nzContent: InviteSubsVendorComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  showDisableVendor(): void {
  }
  SaveOrUpdateData() {
    if (this.isLoadingEdit) {
      this.editData();
    } else {
      this.SaveData();
    }
  }
  SaveData() {
    if (this.TagsForm.valid) {
      this.loadingIndicatoreService.show();
      this.isLoadingOne = true;
      setTimeout(() => {
        this.leadOppService.getLookUpNameSetup(this.TagsForm.value).subscribe(
          response => {
            this.loadingIndicatoreService.hide();
            this.jobGroupVisible = false;
            const tagName = this.TagsForm.get('name')?.value;
            this.initLoad();
            this.iniliazeForm();
            this.isLoadingOne = false;
            this.toastService.success(`${tagName} saved successfully`);
          },
          (error) => {
            this.loadingIndicatoreService.hide();
            if (error.status === 500) {
              this.toastService.error(
                'Internal Server Error. Please try again later.'
              );
              this.isLoadingOne = false;
            } else if (error.status === 400) {
              this.isLoadingOne = false;
              this.toastService.error('Bad Request. Please check your input.');
            } else {
              this.toastService.error(
                'Failed to save division trade. Please try again.'
              );
            }
          }
        );
      }, 1000);
    } else {
      this.TagsForm.markAllAsTouched();
    }
  }
  editData() {
    if (this.TagsForm.valid) {
      this.loadingIndicatoreService.show();
      const tagName = this.TagsForm.get('name')?.value;
      setTimeout(() => {
        this.leadOppService.getLookUpNameSetup(this.TagsForm.value).subscribe(
          (response: any) => {

            this.loadingIndicatoreService.hide();
            this.jobGroupVisible = false;
            this.initLoad();
            this.isLoadingEdit = false;
            this.toastService.success(`${tagName} Update successfully`);
          },
          (error) => {
            this.loadingIndicatoreService.hide();
            if (error.status === 500) {
              this.toastService.error(
                'Internal Server Error. Please try again later.'
              );
            } else if (error.status === 400) {
              this.toastService.error('Bad Request. Please check your input.');
            } else {
              this.toastService.error(
                'Failed to save division trade. Please try again.'
              );
            }
          }
        );
      }, 1000);
    } else {
    }
  }
  ConfirmDeleteTags(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete Tags?',
      nzContent:
        'This Tags is not applied to any subs/vendors and will be deleted.',
      nzOkDanger: true,
      nzOkText: 'Delete',
      nzCancelText: 'Cancel',
      nzModalType: 'confirm',
      nzOkLoading: this.isDeleteTagsLoading,
      nzAutofocus: 'ok',
      nzCentered: true,
      nzOnOk: () => this.DeleteData(),
    });
  }
  DeleteData(): Promise<boolean> {
    const vendorId = this.TagsForm.get('globalId')?.value;
    const tagName = this.TagsForm.get('name')?.value;
    this.isDeleteTagsLoading = true;
    this.loadingIndicatoreService.show();

    return new Promise<boolean>((resolve, reject) => {
      this.leadOppService.deleteDataLookupNameSetup(vendorId).subscribe(
        (res: any) => {
          this.isDeleteTagsLoading = false;
          setTimeout(() => {
            this.toastService.success(`${tagName} Deleted successfully`);
            this.jobGroupVisible = false;
            this.loadingIndicatoreService.hide();
            this.confirmModal.destroy();
            resolve(true);
          }, 1000);
          this.isLoadingEdit = false;
          this.TagsForm.reset();
          this.jobGroupVisible = false;
          this.initLoad();
        },
        (error) => {
          this.isDeleteTagsLoading = false;
          this.jobGroupVisible = false;
          reject(false);
        }
      );
    });
  }

  showTagEdit(selectedValue: LookupNameSetupResponse, fieldType: string): void {
    if (selectedValue) {
      const selectedId: number = Number(selectedValue);
      this.jobGroupVisible = true;
      this.isLoadingEdit = true;
      const selectedObjectFromLookup = this.lookupSetNameResponse.find(item => item.id === selectedId);
      if (selectedObjectFromLookup) {
        const selectedData = selectedObjectFromLookup;
        if (selectedData) {
          this.TagsForm.controls['name'].setValue(selectedData.name);
          this.TagsForm.controls['globalId'].setValue(selectedData.globalId);
          this.TagsForm.controls['isFieldValue'].setValue(selectedData.isFieldValue);
          this.TagsForm.controls['companyParameterId'].setValue(selectedData.companyParameterId);
          this.TagsForm.controls['formNameId'].setValue(selectedData.formNameId);
          this.TagsForm.controls['lookUpStandardId'].setValue(selectedData.lookUpStandardId);
          this.TagsForm.controls['code'].setValue(selectedData.code);
          this.TagsForm.controls['description'].setValue(selectedData.description);
          this.TagsForm.controls['parentCode'].setValue(selectedData.parentCode);
        }
      }
    }
    switch (fieldType) {
      case 'Sources':
        this.ModalTitle = 'Add Lead Source';
        break;
      case 'ProjectType':
        this.ModalTitle = 'Add Project Type';
        break;
      case 'Tags':
        this.ModalTitle = 'Add Lead Tag';
        break;
      default:
        this.ModalTitle = 'Add/Edit Field';
        break;
    }
  }
  showTags() {
    this.jobGroupVisible = true;
  }
  showmanageEmails() {
    this.manageEmailsVisible = true;
  }
  secondModelcancel() {
    this.jobGroupVisible = false;
  }
  manageEmailsModelcancel() {
    this.manageEmailsVisible = false;
  }
  CancelJobScartch() {
    this.cancel.emit();
  }
  doneEmail(): void {
    this.validItems = this.items.filter(item => item.email.trim() !== '' || (item.label && item.label.trim() !== ''));
    if (this.validItems.length > 0) {
      this.formFieldValues.createSubVenderManageEmailRequests = this.validItems;
    } else {
      this.formFieldValues.createSubVenderManageEmailRequests = [];
    }
    this.manageEmailsVisible = false;
  }
  addNewRow(): void {
    if (this.items.length < this.maxItems) {
      this.items.push({ email: '', label: '' });
    }
  }
  deleteRow(index: number): void {
    this.items.splice(index, 1);
    this.formFieldValues.createSubVenderManageEmailRequests = this.items;
  }
  addNewRow1(): void {
    const newRow1: CreateSubVendorCertificateParameterRequest = {
      subVendorId: 0,
      expirationDate: new Date(),
      weeklyEmailReminderDays: 0,
      weeklyEmailReminderDaySetupId: 0,
      reminderLimitSetupId: 0,
      subVendorCertificateSetupId: 0
    };
    this.subVendorCertificateSetup.push(newRow1);
    this.index = this.subVendorCertificateSetup.length - 1;
    this.formFieldValues.createSubVendorCertificateParameterRequests = this.subVendorCertificateSetup;
  }
  isAnyEmailEntered(): boolean {
    const filledEmailsCount = this.items.filter(item => !!item.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(item.email)).length;
    return filledEmailsCount === this.items.length;
  }
  isSave: boolean = false;
  isSaveAndClose: boolean = false;
  isSaveAndNew: boolean = false;
  saveSubVendor(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save'): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
        return reject('Already processing');
      }
  
      if (actionType === 'saveAndNew') {
        this.isSaveAndNew = true;
      } else if (actionType === 'saveAndClose') {
        this.isSaveAndClose = true;
      } else {
        this.isSave = true;
      }
      if (this.formFieldValues.cellPhone && this.formFieldValues.cellPhone.number) {
        const phoneNumber = this.formFieldValues.cellPhone.number;
        const dialCode = this.formFieldValues.cellPhone.dialCode;
        const countryCodeNumber = this.formFieldValues.cellPhone.countryCode;
        this.formFieldValues.cellPhone = phoneNumber;
        this.formFieldValues.dialCode = dialCode;
        this.formFieldValues.countryCode = countryCodeNumber;
      }
    
      let DivisionTrade = this.formFieldValues.createDivisionTradeParameterRequests?.map(
        (id: string) => ({ divisionTradeId: id })
      );
    
      let formDataToSend = {
        ...this.formFieldValues,
        createDivisionTradeParameterRequests: DivisionTrade
      };
      this.SubVendorsService.postData(formDataToSend).subscribe(
        (response) => {
          this.onSaveComplete.emit(response?.result);
          this.subVendorResponse = response?.result;
          this.patchFormValues(this.subVendorResponse); 
          this.toastService.success('Subvendor Saved Successfully!');
          resolve();
        },
        (error) => {
          console.error('Error saving data:', error);
          reject(error);
        }
      ).add(() => {
        this.resetFlags();
      });
    });
  }
  saveSubVendorAndClose(): Promise<void> {
    return this.saveSubVendor('saveAndClose').then(() => {
      this.CancelJobScartch();
      this.cd.detectChanges();
      this.formFieldValues = {}
      this.subVendorResponse = undefined;
    }).catch((error) => {
      console.error('Error during save and close:', error);
    });
  }
  saveSubVendorAndNew() {
    this.saveSubVendor('saveAndNew').then(() => {  
      this.subVendorResponse = undefined;
      this.items = [];
      this.validItems = [];
      this.formFieldValues = {}
    }).catch((error) => {
      console.error('Error during save and new:', error);
    }).finally(() => {
      this.validItems = [];
      this.items = [];
    });
  }
  deleteSubConfirm(): void {
    if (this.isDeleteLoading) {
      return;
    }

    this.modal.confirm({
      nzTitle: `Delete sub/vendor?`,
      nzContent: 'All contact information for this sub/vendor will be deleted from this account and they will be removed from assigned items and jobs.',
      nzOkText: `Delete`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteSubVendor(this.formFieldValues.globalId)
          .then(() => {
            this.SubVendorNewLeadModelCancel();
          })
          .catch(() => {

          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }
  deleteSubVendor(globalId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!globalId) {
        this.toastService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }

      this.SubVendorsService.deleteData(globalId).subscribe(
        (res) => {
          this.isDeleteLoading = false;
          this.toastService.success(`Subvendor deleted successfully!`);
          this.onSaveComplete.emit(res);
          this.closeDialog();
          this.isDeleteLoading = false;
          resolve();
        },
        (error) => {
          this.isDeleteLoading = false;
          this.toastService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      );
    });
  }
  SubVendorNewLeadModelCancel(): void {
    this.cancel.emit();
    this.SubvendorModal = false;
    this.leadOppService.clearSelectedFiles();
  }
  resetFlags() {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
  }
//   .pipe(switchMap(response => {
//     this.formFieldValues.globalId = response.result?.globalId;
//     const subVendorId = response.result?.id;
//     const certificateRequests = this.subVendorCertificateSetup
//       .filter(certificate => certificate.expirationDate !== null)
//       .map(certificate => {
//         const certificateRequest: CreateSubVendorCertificateParameterRequest = {
//           subVendorId,
//           subVendorCertificateSetupId: certificate.id,
//           expirationDate: certificate.expirationDate,
//           weeklyEmailReminderDays: certificate.weeklyEmailReminderDays,
//           weeklyEmailReminderDaySetupId: certificate.weeklyEmailReminderDaySetupId,
//           reminderLimitSetupId: certificate.reminderLimitSetupId,
//         };

//         return this.SubVendorsService.postDataCertificate(subVendorId, certificateRequest).pipe(
//           tap(certificateResponse => {
//           }),
//           catchError(error => {
//             console.error('Certificate API error:', error);
//             return of(null);
//           })
//         );
//       });

//     return forkJoin(certificateRequests).pipe(
//       switchMap(() => from(this.subVendorCertificateSetup)),
//       concatMap(certificate => {
//         if (certificate.pdfFileUrl) {
//           const pdfRequest = new CreateSubVendorCertificateParameterForPdfRequest();
//           pdfRequest.subVendorId = subVendorId;
//           pdfRequest.subVendorCertificateSetupId = certificate.id;
//           pdfRequest.pdfFile = certificate.pdfFileUrl;

//           return this.SubVendorsService.createAttachmentForDocument(pdfRequest).pipe(
//             tap(attachmentResponse => {
//             }),
//             catchError(error => {
//               console.error('PDF Attachment API error:', error);
//               return of(null);
//             })
//           );
//         }
//         return of(null);
//       })
//     );
//   })
// )?
}