import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BillsComponent } from '../bills/bills.component';
import { PurchaseOrderModelComponent } from '../purchase-order-model/purchase-order-model.component';

@Component({
  selector: 'app-purchase-order-empty',
  templateUrl: './purchase-order-empty.component.html',
  styleUrls: ['./purchase-order-empty.component.css']
})
export class PurchaseOrderEmptyComponent {
  constructor(private modal: NzModalService) {}
  PurchaseOpen(): void {
    const modalRef = this.modal.create({
      nzContent: PurchaseOrderModelComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  
  }
}
