import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';

@Component({
  selector: 'app-recent-activity-from-your-team',
  templateUrl: './recent-activity-from-your-team.component.html',
  styleUrls: ['./recent-activity-from-your-team.component.css']
})
export class RECENTACTIVITYFROMYOURTEAMComponent  {

  constructor(private modal: NzModalService, private route: ActivatedRoute, private router: Router , private filterService: FilterSideMenuBarService) { }


  PAGE_ID: number = 34;
  Job_Summary: string = FiltersName.Job_Summary;

openFilter(component: string, pageId: number) {
  this.filterService.toggleFilter(component, pageId);
}
}
