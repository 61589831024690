<div class="row mt-5" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <div class="col" style="text-align: center;">
       
       <span>
        <!-- <i class="bi bi-ui-checks" style="font-size: 55px;"></i> -->
        <img src="assets/Empty-SVGs/Financial/Tenders/tenders.svg">
      </span>   
        <div class="row">
            <div class="col mt-2">
              <h4>Manage Your Bid in One <br>Place</h4>
              
            </div>
        </div>
        <div class="col mt-3">
        <p>Send bids, track responses, and compare bid <br>amount</p>
  
  
        </div>
    </div>
    <div class="col mt-4" style="display: flex; justify-content: center; align-items: center;">
        <button nz-button nzType="default" style="margin-right: 10px;"><i class="bi bi-box-arrow-up-right me-2"></i>Learn How</button>
        <button nz-button nzType="primary" style="margin-right: 10px;">Create a Bid Package</button>
    </div>
  </div>