import { PhaseService } from './../../../../../../Service/CompanyInFormation/ScheDule/Phase/phase.service';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { ApplicationUserResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { CreateAttachmentForDocumentRequest, CreateMessagingRequest, LeadOpportunityResponse, LookupNameSetupResponse } from 'src/Models/LeadTags';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { colorService } from 'src/Service/Job-List/Job-Information/color.service';
import { ScheduleService } from 'src/Service/Project-Management/schedule.service';
import { SubVendersService } from 'src/Service/SubVendorsServices/sub-venders.service';
import { ProjectManegmentScheduleItemDetailsPreDefinedCodes, ProjectManegmentSchedulePreDefinedCodes, ProjectManegmentSiteDiriesPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { ProjectManagementSiteDiariesCreateFilesComponent } from '../../../Site-Diaries/project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { ProjectManagementSiteDiariesUploadFilesComponent } from '../../../Site-Diaries/project-management-site-diaries-upload-files/project-management-site-diaries-upload-files.component';
import { ColorResponse } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { isWeekend, addDays } from 'date-fns';
import { NewRFIsComponent } from 'src/app/messaging/Components/RFIs/new-rfis/new-rfis.component';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { ScheduleItemParameterResponse } from 'src/Models/Project-Management/projectManagement';
import { PhaseSetup } from 'src/Models/CompanySettings/Schedule/PhaseSetup/PhaseSetup';
import { NotifyUsersComponent } from '../../notify-users/notify-users.component';
import { borderRadiusIcon } from '@progress/kendo-svg-icons';
import { RowHeightService } from '@progress/kendo-angular-grid/scrolling/row-height.service';
interface ItemData {
  id: number;
  title: string;
  type: string;
  lag: number;
  start: string;
  end: string;
}
interface ScheduleItem {
  scheduleItemPredecessorParameterId: number;
  scheduleItemPredecessorTypeSetupId: number; // Ensure this property is correctly defined
  logNumber: number;

}
@Component({
  selector: 'app-newschedule-item',
  templateUrl: './newschedule-item.component.html',
  styleUrls: ['./newschedule-item.component.css']
})
export class NewscheduleItemComponent implements OnInit {
  // sufyan working
  jobID: number;
  messagingId:number ;
  messagingIdRow: any;
  formNameID: number = 8;
  @Input() comments: any;  //hold
  // sufyan working
  newScheduleitemModal = false;
  newScheduleItem = [1, 2];
  tabs = [
    { id: 1, title: 'Predecessors & Links', show: true },
    { id: 2, title: 'Phases & Tags', show: true },
    { id: 3, title: 'Viewing', show: true },
    { id: 4, title: 'Notes', show: true },
    { id: 5, title: 'Files', show: true },
    { id: 6, title: 'Shifts', show: false },
    { id: 7, title: 'Messaging', show: false },
    { id: 8, title: 'Confirmations', show: false }
  ];
  viewtabs = [1, 2, 3, 4];
  showCard = false;
  rows: any[] = [{ name: '', type: null, days: '' }];
  value: string[] = ['0-0-0'];
  inputValue: string = '';
  @Output() cancel = new EventEmitter<void>();
  @Output() editDataResponse: ScheduleItemParameterResponse;
  colors: ColorResponse[] = [];
  allScheduleData: ColorResponse[] = [];
  phase: PhaseSetup[] = [];
  combinedNodes: NzTreeNodeOptions[] = [];
  combinedNodesMessaging: NzTreeNodeOptions[] = [];
  assgindata: NzTreeNodeOptions[] = [];
  subVendorData: NzTreeNodeOptions[] = [];
  customerInformationData: NzTreeNodeOptions[] = [];
  internalUserNodes: NzTreeNodeOptions[] = [];
  subVendorsNodes: NzTreeNodeOptions[] = [];
  leadDatas: LeadOpportunityResponse;
  userList: ApplicationUserResponse[] = [];
  vendorList: SubVendorResponse[] = [];
  lookupSetNameResponse: PhaseSetup[] = [];
  lookupSetNameRespons: LookupNameSetupResponse[] = [];
  scheduleItemReminder: LookupNameSetupResponse[] = [];
  scheduleItemPhase: { label: string; value: string }[] = [];
  scheduleItemType: LookupNameSetupResponse[] = [];
  tagNodes: NzTreeNodeOptions[] = [];
  items: ScheduleItem[] = [];
  index: number = 0;
  pageId: number = 8;
  pageid: number = 5;
  value1 = 1;
  sliderValue: number = 0;
  formFieldValues: any = {};
  messagesFormValue: any = {};
  PhaseDetailsFieldValues: any = {};
  ScheduleItemInternalUser: any = {};
  ScheduleItemSubVendorUser: any = {};
  ScheduleItemPredecessor: any = {};
  size: NzButtonSize;
  TagsForm: FormGroup;
  PhaseDetails: boolean = false;
  jobGroupVisible: boolean = false;
  isLoadingEdit: boolean = false;
  isLoadingOne: boolean = false;
  isPhaseDetailsLoadingEdit: boolean = false;
  // isScheduleItemTagLoadingEdit: boolean = false;
  showValidationMessage: boolean = false;
  confirmModal?: NzModalRef;
  isDeleteTagsLoading: boolean = false;
  public ModalTitle: string = '';
  selectedFiles: File[] = [];
  preDefinedDataCodes = {
    Reminder: ProjectManegmentScheduleItemDetailsPreDefinedCodes.Reminder,
    Type: ProjectManegmentScheduleItemDetailsPreDefinedCodes.Type,
    Tags: ProjectManegmentSchedulePreDefinedCodes.Tags,
  };
  selectedNode: any;
  filteredScheduleData: ScheduleItemParameterResponse[] = [];
  responseHold: number = -1; //sufyan set
  responseSchedule: ScheduleItemParameterResponse;

  constructor(

    @Inject(NZ_MODAL_DATA) public leadData: any,
    @Inject(NZ_MODAL_DATA) public data: any,
    private colorService: colorService,
    private PhaseService: PhaseService,
    private LookupFilterService: LookupFilterService,
    public toastsService: NzMessageService,
    private loadingIndicatoreService: LoadingIndicatorService,
    public fb: FormBuilder,
    private leadOppService: LeadOpportunitiesService,
    public toastService: NzMessageService,
    private modal: NzModalService,
    private customerInformationService: CustomerInformationService,
    private SubVendorService: SubVendersService,
    private scheduleService: ScheduleService,
    @Inject(NZ_MODAL_DATA) public JobData: any,
    @Inject(NZ_MODAL_DATA) public setHeadId: any, // add sufyan work
    @Inject(NZ_MODAL_DATA) public setresponse: any, // add sufyan work
    private LeadOpportunitiesService: LeadOpportunitiesService,
    private localStorageService: AccessLocalStorageService,

  ) {
   
   }


  ngOnInit(): void {

    this.messagingId =  this.setresponse?.setresponse?.messagingId;
    this.messagingIdRow =  this.setHeadId?.setHeadId;
    console.log("MESSAGEING ID ROW:", this.messagingIdRow);
    console.log("MESSAGEING ID :", this.messagingId);

    const  storeComments = this.setresponse?.setresponse?.messaging?.messagingParameters.map(param => param.comments)
    this.comments = storeComments?.flat() || [];
    console.log('sufyan console.log',this.comments);

    

    this.jobID = this.localStorageService.getJobId();  // sufyan work 

    this.listOfData = new Array(10).fill(0).map((_, index) => ({
      id: index,
      title: `Demo ${index}`,
      type: 'Schedule Item',
      lag: 100,
      start: `4-04-2024. ${index}`,
      end: `14-05-2024 ${index}`,
    }));
    console.log('recived Data :', this.editDataResponse);
    console.log('Received setHeadId:', this.setHeadId); // sufyan add
    console.log('Received setmesageingHeadId:', this.messagingId); // sufyan add
    const currentDate = new Date();
    this.formFieldValues = {
      globalId: '00000000-0000-0000-0000-000000000000',
      jobSetupId: 1,
      jobInformationId: (this.leadData?.leadData?.id || this.JobData?.JOB_ID || this.editDataResponse?.jobInformationId),
      title: '',
      colorId: 0,
      StartOn: currentDate,
      workDays: 1,
      endOn: currentDate,
      isHourly: false,
      startTime: null,
      endTime: null,
      progressPercentage: 0,
      scheduleItemReminderId: 0,
      isShowOnGantt: true,
      isShowOwnerPhasesOnly: true,
      PhaseSetupId: 0,
      allNotes: "",
      internalNotes: "",
      subNotes: "",
      3: "",
      createScheduleItemAssigneeRequests: [],
      createScheduleItemPredecessorParameterRequests: [
        {
          scheduleItemPredecessorParameterId: 0,
          scheduleItemPredecessorTypeSetupId: 0,
          logNumber: 0
        }
      ],
      createScheduleItemTagParameterRequests: [],
      createScheduleItemSubVendorParameterRequests: [],
    };

    const jobId = this.localStorageService.getJobId();
    this.messagesFormValue = {
      headerId: 0,
      formNameId: 8,
      comments: "",
      isViewOwner: true,
      isViewSubVendor: true,
      JobInformationId: jobId,
      MessageFormTypeId: 10271,
      createMessagingUserNotifyParameterRequests: []
    };

    this.calculateEndDateAndWorkDays(currentDate, currentDate);

    this.PhaseDetailsFieldValues = {
      id: 0,
      globaliId: '',
      name: '',
      displayOrder: 0,
      colorId: 0,
    }
    this.addNewRow();

    this.leadDatas = this.data?.leadDatas;
    this.leadOppService.getSelectedFiles().subscribe(files => {
      this.selectedFiles = files;
    });

    // AGAINST OF JOB BIND DATA INITIAL 

    console.log('leadData', this.JobData.JOB_SELECTED_OBJECT);

    if (this.JobData.JOB_SELECTED_OBJECT) {
      this.formFieldValues['colorId'] = this.JobData.JOB_SELECTED_OBJECT?.colorId ?? 0;
      const selectedScheduleItemParameterIds = this.JobData.JOB_SELECTED_OBJECT?.scheduleItemParameters?.map(item => item) ?? [];
      this.filteredScheduleData = selectedScheduleItemParameterIds;
      this.formFieldValues['createScheduleItemSubVendorParameterRequests'] = this.JobData.JOB_SELECTED_OBJECT?.jobSubVendorUserParameters?.map(vendors => vendors.subVendorId.toString()) ?? [];

    }

    // EDIT MODE OF SCHEDULE DASHBOARD

    if (this.editDataResponse) {
      this.formFieldValues['globalId'] = this.editDataResponse.globalId;
      this.formFieldValues['id'] = this.editDataResponse.id;
      this.formFieldValues['title'] = this.editDataResponse.title;
      this.formFieldValues['colorId'] = this.editDataResponse.colorId;
      this.formFieldValues['StartOn'] = this.editDataResponse.startOn;
      this.formFieldValues['workDays'] = this.editDataResponse.workDays;
      this.formFieldValues['endOn'] = this.editDataResponse.endOn;
      this.formFieldValues['isHourly'] = this.editDataResponse.isHourly;

      // condition for start time and end time if hourly true then fields show start time and End time

      if (this.editDataResponse.isHourly === true) {
        this.enableFields = true;
      }

      this.formFieldValues['startTime'] = this.editDataResponse.startTime;
      this.formFieldValues['endTime'] = this.editDataResponse.endTime;
      this.formFieldValues['progressPercentage'] = this.editDataResponse.progressPercentage;
      this.sliderValue = this.editDataResponse.progressPercentage;
      this.formFieldValues['scheduleItemReminderId'] = this.editDataResponse.scheduleItemReminderId;

      // Extracting applicationUserId and subVendorId from each item in scheduleItemAssignees array
      this.formFieldValues['createScheduleItemAssigneeRequests'] = this.editDataResponse?.scheduleItemAssignees?.map(item => item.applicationUserId.toString());
      this.formFieldValues['createScheduleItemAssigneeRequests'] = this.editDataResponse?.scheduleItemAssignees?.map(item => item.subVendorId.toString());


      // Predecessors tab 

      // this.formFieldValues['createScheduleItemPredecessorParameterRequests'] = this.editDataResponse?.scheduleItemPredecessorParameters?.map(item => item.scheduleItemPredecessorParameterId) ?? [];
      // this.formFieldValues['createScheduleItemPredecessorParameterRequests'] = this.editDataResponse?.scheduleItemPredecessorParameters?.map(item => item.scheduleItemPredecessorTypeSetupId) ?? [];
      // this.formFieldValues['createScheduleItemPredecessorParameterRequests'] = this.editDataResponse?.scheduleItemPredecessorParameters?.map(item => item.logNumber) ?? [];

      this.formFieldValues['createScheduleItemPredecessorParameterRequests'] = this.editDataResponse?.scheduleItemPredecessorParameters?.map(item => ({
        scheduleItemPredecessorParameterId: item.scheduleItemPredecessorParameterId,
        scheduleItemPredecessorTypeSetupId: item.scheduleItemPredecessorTypeSetupId,
        logNumber: item.logNumber
      })) ?? [];
      this.items = this.formFieldValues.createScheduleItemPredecessorParameterRequests;


      // notes tab

      this.formFieldValues['allNotes'] = this.editDataResponse.allNotes;
      this.formFieldValues['internalNotes'] = this.editDataResponse.internalNotes;
      this.formFieldValues['subNotes'] = this.editDataResponse.subNotes;
      this.formFieldValues['ownerNotes'] = this.editDataResponse.ownerNotes;


      //  Schedule Viewing tab

      this.formFieldValues['isShowOnGantt'] = this.editDataResponse?.isShowOnGantt;
      this.formFieldValues['isShowOwnerPhasesOnly'] = this.editDataResponse?.isShowOwnerPhasesOnly;
      this.formFieldValues['createScheduleItemSubVendorParameterRequests'] = this.editDataResponse?.jobSubVendorUserParameters?.map(vendors => vendors.subVendorId.toString()) ?? [];


      //Schedule Item Phase tab 

      this.formFieldValues['PhaseSetupId'] = this.editDataResponse?.phaseSetupId;
      this.formFieldValues['createScheduleItemTagParameterRequests'] = this.editDataResponse?.scheduleItemTagParameters?.map(tag => tag.scheduleItemTagSetupId.toString()) ?? [];



      // main tab 2 response send 
      if (this.editDataResponse) {
        this.responseSchedule = this.editDataResponse;
      }
      // Header link fild Enable
      if (this.editDataResponse.id) {
        this.responseHold = this.editDataResponse.id;
      }
      // after save enabled tabs inside a main tab 1 there are 3 tabs looking after save
      if (this.editDataResponse.id) {
        // this.responseHold = this.editDataResponse.id;
        this.tabs.forEach((tab) => {
          if (tab.id >= 6 && tab.id <= 8) {
            tab.show = true;
          }
        });
      }

    };


    this.getColor();
    this.initLoad();
    this.initLoad2();
    this.iniliazeForm();
    this.ForCombo();
  }

  //  Date Funcnality Methods Start //

  isWeekend = (current: Date): boolean => {
    return isWeekend(current);
  }


  onStartDateChange(startDate: Date): void {
    if (startDate > this.formFieldValues.endOn || startDate < this.formFieldValues.endOn) {
      this.formFieldValues.endOn = new Date(startDate); // Set end date same as start date
    }
    this.calculateEndDateAndWorkDays(startDate, this.formFieldValues.endOn);
  }



  onEndDateChange(endDate: Date): void {
    if (endDate < this.formFieldValues.StartOn) {
      this.formFieldValues.StartOn = new Date(endDate);
    }
    this.calculateEndDateAndWorkDays(this.formFieldValues.StartOn, endDate);
  }

  calculateEndDateAndWorkDays(start: Date, end: Date): void {
    let currentDate = new Date(start);
    let workDays = 0;

    while (currentDate <= end) {
      if (!this.isWeekend(currentDate)) {
        workDays++;
      }
      currentDate = addDays(currentDate, 1);
    }

    this.formFieldValues.endOn = end;
    this.formFieldValues.workDays = workDays;
  }

  onWorkDaysChange(newWorkDays: number): void {
    const startDate = this.formFieldValues.StartOn;
    const newEndDate = addDays(startDate, newWorkDays - 1);
    this.formFieldValues.endOn = newEndDate;
  }


  //  Date Funcnality Methods End //



  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      const assgindata = res.result;
      this.assgindata = assgindata.map((vendor) => ({
        title: vendor.fullName,
        key: vendor.id.toString(),
        isLeaf: true,
        isExpanded: true, // Set isExpanded to true for each node
        expanded: true, // Set expanded to true for each node if required by your tree component
        applicationUserId: vendor.applicationUserId
      }));
      this.combineNodes();
    });

    this.SubVendorService.getData().subscribe((res) => {
      const Subvendor = res.result;
      this.subVendorData = Subvendor.map((vendor) => ({
        title: vendor.companyName,
        key: vendor.id.toString(),
        isLeaf: true,
        isExpanded: true, // Set isExpanded to true for each node
        expanded: true, // Set expanded to true for each node if required by your tree component
        subVendorId: vendor.subVendorId
      }));
      this.combineNodes();
    });

    this.customerInformationService.getData().subscribe((res) => {
      const Subvendor = res.result;
      this.customerInformationData = Subvendor.map((customer) => ({
        title: customer.displayName,
        key: customer.id.toString(),
        isLeaf: true,
        isExpanded: true, // Set isExpanded to true for each node
        expanded: true, // Set expanded to true for each node if required by your tree component
        customerInformationId: customer.customerinformationId
      }));
      this.combineNodes();
    });
  }

  combineNodes(): void {
    if (this.assgindata && this.subVendorData && this.customerInformationData) {
      const internalUsersNode = {
        title: 'internalUser',
        value: '0-0',
        key: '0-0',
        children: this.assgindata,
      };

      const subVendorsNode = {
        title: 'Subs/Vendors',
        value: '0-1',
        key: '0-1',
        children: this.subVendorData,
      };
      const customerInformationNode = {
        title: 'Owners',
        value: '0-2',
        key: '0-2',
        children: this.customerInformationData,
      };

      this.combinedNodes = [internalUsersNode, subVendorsNode];
      this.combinedNodesMessaging = [internalUsersNode, subVendorsNode, customerInformationNode];
    }
  }



  onNodeSelectionChange(selectedNodeKeys: string[]): void {
    if (selectedNodeKeys && selectedNodeKeys.length > 0) {
      const selectedNode = this.combinedNodes.find(node => node.key === selectedNodeKeys[0]);
      const combinedNodesMessaging = this.combinedNodesMessaging.find(node => node.key === selectedNodeKeys[0]);

      if (selectedNode && combinedNodesMessaging) {
        this.selectedNodes.push(selectedNode); // Add selected node data to selectedNodes array
        this.formFieldValues.assigneeUserId = selectedNode['applicationUserId'] || '';
        this.formFieldValues.assigneeSubVendorId = selectedNode['subVendorId'] || '';
        this.messagesFormValue.applicationUserId = combinedNodesMessaging['applicationUserId'] || '';
        this.messagesFormValue.customerInformationId = combinedNodesMessaging['customerInformationId'] || '';
        this.messagesFormValue.subVendorId = combinedNodesMessaging['subVendorId'] || '';
      }
    } else {
      // Clear the values if no node is selected
      this.formFieldValues.assigneeUserId = '';
      this.formFieldValues.assigneeSubVendorId = '';
      this.messagesFormValue.applicationUserId = '';
      this.messagesFormValue.customerInformationId = '';
      this.messagesFormValue.subVendorId = '';
    }
  }


  saveButton() {

    let data = { ...this.formFieldValues }; // Create a copy of formFieldValues to avoid modifying the original object
    let subVendor = data.createScheduleItemSubVendorParameterRequests.map(
      (id: string) => ({ subVendorId: id })
    );

    let scheduleItemTag = data.createScheduleItemTagParameterRequests.map(
      (id: string) => ({ scheduleItemTagSetupId: id })
    );
    data.createScheduleItemTagParameterRequests = scheduleItemTag;
    data.createScheduleItemSubVendorParameterRequests = subVendor;

    let viewerParams = data.createScheduleItemAssigneeRequests;
    let transformedParams = [];
    for (let i = 0; i < viewerParams.length; i += 2) {
      const applicationUserId = viewerParams[i];
      const subVendorId = viewerParams[i + 1];
      if (applicationUserId && subVendorId) {
        transformedParams.push({
          applicationUserId,
          subVendorId,
        });
      }
    }
    data.createScheduleItemAssigneeRequests = transformedParams;
    this.scheduleService.postSchedule(data).subscribe(
      (response) => {
        this.toastService.success('Schedule Saved Successfully!');
        this.responseHold = response.result.id;
        this.messagingId = this.responseHold ? this.responseHold : this.setHeadId;
        this.messagingIdRow = this.responseHold ? this.responseHold : this.setHeadId;
        console.log('Value of mesageingHeadId after assignment:', this.messagingId);


        this.responseSchedule = response.result;
        if (this.responseHold) {
          this.tabs.forEach((tab) => {
            if (tab.id >= 6 && tab.id <= 8) {
              tab.show = true;
            }
          });
        }

        if (this.selectedFiles && this.selectedFiles.length > 0) {
          const filesToUpload = this.selectedFiles.filter(
            (file) => file && file instanceof File
          );
          if (filesToUpload.length > 0) {
            const attachmentRequest: CreateAttachmentForDocumentRequest = {
              headerId: response.result.id,
              companyParameterId: 1,
              formNameId: 8,
              documentFiles: filesToUpload.map((file) => file),
            };
            this.leadOppService
              .CreateAttachmentForDocument(attachmentRequest)
              .subscribe(
                (attachmentResponse) => {
                  console.log(
                    'Attachment created successfully:',
                    attachmentResponse
                  );
                  this.saveFileIdToDatabase(attachmentResponse.fileId);
                },
                (attachmentError) => {
                  console.error('Error creating attachment:', attachmentError);
                }
              );
          } else {
            console.warn('No files to upload.');
          }
        }
      },
      (error) => {
        console.error('Error saving data:', error);
      }
    );
  }

  createMessage() {

    let data = { ...this.messagesFormValue }; // Create a copy of formFieldValues to avoid modifying the original object

    console.log('this.responseHeaderId ', this.responseHold);
    if (!this.responseHold || this.responseHold <= 0) {
      console.error('Main data not saved yet or invalid responseHold.');
      return;
    }

    // Get the selected nodes from messagesFormValue
    const selectedNodes = this.messagesFormValue['createMessagingUserNotifyParameterRequests'] || [];

    // Check if selectedNodes is empty, if so, return
    if (selectedNodes.length === 0) {
      console.error('No selected nodes found.');
      return;
    }



    let viewerParams = data.createMessagingUserNotifyParameterRequests;
    let transformedParams = [];
    for (let i = 0; i < viewerParams.length; i += 2) {
      const applicationUserId = viewerParams[i];
      const subVendorId = viewerParams[i + 1];
      const customerInformationId = viewerParams[i + 1];
      if (applicationUserId && subVendorId && customerInformationId) {
        transformedParams.push({
          applicationUserId,
          subVendorId,
          customerInformationId
        });
      }
    }
    data.createMessagingUserNotifyParameterRequests = transformedParams;

    data.headerId = this.responseHold;
    // Send the messaging request
    this.LeadOpportunitiesService.PostMessage(data).subscribe(
      (messageResponse) => {
        console.log('Message created successfully:', messageResponse);
        this.toastsService.success('Update successfully!');
      },
      (messageError) => {
        console.error('Error creating message:', messageError);
      }
    );
  }





  saveFileIdToDatabase(fileId: string) {
  }


  savePhaseDetails(): void {
    if (this.isPhaseDetailsLoadingEdit) {
      this.editDataPhase();
    } else {
      this.SaveDataPhase();
    }
  }

  SaveDataPhase() {
    this.isPhaseDetailsLoadingEdit = false;
    this.PhaseService.postData(this.PhaseDetailsFieldValues).subscribe(
      (response) => {
        this.getColor();
        this.PhaseDetails = false;
        this.PhaseDetailsFieldValues = {};
      },
      (error) => {
        console.error('Error saving data:', error);
      }
    );
  }

  editDataPhase() {
    this.isPhaseDetailsLoadingEdit = true;
    this.PhaseService.updateData(this.PhaseDetailsFieldValues).subscribe(
      (response) => {
        this.getColor();
        this.PhaseDetails = false;

      },
      (error) => {
        console.error('Error saving data:', error);
      }
    );
  }


  phaseDataDelete() {
    this.isPhaseDetailsLoadingEdit = false;
    this.PhaseService.deleteData(this.PhaseDetailsFieldValues.globalId).subscribe(
      (response) => {
        this.getColor();
        this.PhaseDetails = false;
        this.PhaseDetailsFieldValues = {};
      },
      (error) => {
        console.error('Error saving data:', error);
      }
    );
  }

  addNewRow(): void {
    const newRow = {
      scheduleItemPredecessorParameterId: 0,
      scheduleItemPredecessorTypeSetupId: 0,
      logNumber: 0,
      disabled: false // Add a disabled property and initialize it
    };

    this.items.push(newRow);
    this.formFieldValues.createScheduleItemPredecessorParameterRequests = this.items;

    if (this.scheduleItemType && this.scheduleItemType.length > 0) {
      newRow.scheduleItemPredecessorTypeSetupId = this.scheduleItemType[0].id;
    } else {
      newRow.scheduleItemPredecessorTypeSetupId = 0;
    }
  }

  deleteRow(index: number): void {
    this.items.splice(index, 1);
    this.formFieldValues.createScheduleItemPredecessorParameterRequests = this.items;

  }

  isScheduleDataEmpty(): boolean {
    return !this.filteredScheduleData || this.filteredScheduleData.length === 0;
  }




  initLoad2() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(
      (res) => {
        this.lookupSetNameResponse = res.result;
        // this.lookupSetNameRespons = res.result;
        // -----------Multi Select ---------------

        let tagValues = res.result.filter(
          (x: { code: string; }) => x.code === this.preDefinedDataCodes.Tags.code
        );
        // -----------single Select ---------------
        let scheduleItemReminderValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.Reminder.code
        );

        let scheduleItemTypeValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.Type.code
        );
        // -----------single Select ---------------

        // this.tagNodes = tagValues.map((status: { name: any; id: { toString: () => any; }; }) => ({
        //   title: status.name,
        //   value: status.id.toString(),
        //   key: status.id.toString(),
        //   isLeaf: true,
        // }));
        // -----------single Select ---------------
        this.scheduleItemReminder = scheduleItemReminderValues;
        const reminder = scheduleItemReminderValues.find(
          (item) => item.name === '3 Days Before'
        );

        if (reminder) {
          const reminderId = reminder.id;
          this.formFieldValues.scheduleItemReminderId = reminderId
        } else {
          console.log("Reminder '3 Days Before' not found.");
        }


        this.scheduleItemType = scheduleItemTypeValues;

        const scheduleItemType = scheduleItemTypeValues.find(
          (item) => item.name === 'Finish-to-Start (FS)'
        );

        if (scheduleItemType) {
          this.items.forEach(item => {
            item.scheduleItemPredecessorTypeSetupId = scheduleItemType.id;
          });
        } else {
          console.log("Schedule Item Type 'Finish-to-Start (FS)' not found.");
        }

      }
    );
  }


  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(
      (res) => {
        this.lookupSetNameRespons = res.result;
        // -----------Multi Select ---------------

        let tagValues = res.result.filter(
          (x: { code: string; }) => x.code === this.preDefinedDataCodes.Tags.code
        );
        // -----------single Select ---------------

        this.tagNodes = tagValues.map((status: { name: any; id: { toString: () => any; }; }) => ({
          title: status.name,
          value: status.id.toString(),
          key: status.id.toString(),
          isLeaf: true,
        }));
      }
    );
  }


  UploadFiles(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesUploadFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  CreateNewFile(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  viewAll(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  removeFile(file: File): void {
    const index = this.selectedFiles.indexOf(file);
    if (index !== -1) {
      this.selectedFiles.splice(index, 1);
    }
  }


  showAddNewPhase() {
    this.PhaseDetails = true;
    this.isPhaseDetailsLoadingEdit = false;
  }


  AddNewPhasecancel() {
    this.PhaseDetails = false;
    this.resetForm();
  }
  resetForm() {
    this.PhaseDetailsFieldValues = {};
  }
  EditAddNewPhase(selectedValue: PhaseSetup, fieldType: string): void {
    if (selectedValue) {
      const selectedId: number = Number(selectedValue);
      this.PhaseDetails = true;
      this.isPhaseDetailsLoadingEdit = true;
      // Find the selected value inside the phases array
      const selectedObjectFromLookup = this.phase.find(item => item.id === selectedId);
      if (selectedObjectFromLookup) {
        const selectedData = selectedObjectFromLookup;
        if (selectedData) {
          console.log(selectedObjectFromLookup);

          this.PhaseDetailsFieldValues['globalId'] = (selectedData.globalId);
          this.PhaseDetailsFieldValues['name'] = (selectedData.name);
          this.PhaseDetailsFieldValues['id'] = (selectedData.id);
          this.PhaseDetailsFieldValues['displayOrder'] = (selectedData.displayOrder);
          this.PhaseDetailsFieldValues['colorId'] = (selectedData.colorId);
          console.log(this.PhaseDetailsFieldValues);
        }

      } else {
        console.log('Selected value not found in phases array');
      }
    }
  }
  newScheduleItemTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'Schedule Item Details';
      case 2:
        return 'Related Item(0)';
      default:
        return '';
    }
  }

  innerCardNewScheduleTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'Predecessors & Links';
      case 2:
        return 'Phases & Tags';
      case 3:
        return 'Viewing';
      case 4:
        return 'Notes';
      case 5:
        return 'Files';
      case 6:
        return 'Shifts';
      case 7:
        return 'Messaging';
      case 8:
        return 'Confirmations';
      default:
        return '';
    }
  }

  innerCardTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'All Notes';
      case 2:
        return 'Internal Notes';
      case 3:
        return 'Sub Notes';
      case 4:
        return 'Owner Notes';
      default:
        return '';
    }
  }

  getColor() {
    this.colorService.getData().subscribe((res: any) => {
      const colorGet = res.result;
      this.colors = colorGet;
      const selectedColor = this.colors.find(color => color.name === 'Olive');

      // Set the selected colorId if 'Olive' is found
      if (selectedColor) {
        this.PhaseDetailsFieldValues['colorId'] = selectedColor.id;
      }

    })
    this.PhaseService.getData().subscribe((res: any) => {
      this.PhaseDetailsFieldValues.reses
      const phaseData = res.result;
      this.phase = phaseData;
      if (this.phase && this.phase.length > 0) {
        const unassignedOption = this.phase.find(option => option.name === 'Unassigned');
        if (unassignedOption) {
          this.formFieldValues['PhaseSetupId'] = unassignedOption.id;
        }
      }
    })



  }

  enableFields: boolean = false;
  toggleFields(): void {

    this.enableFields = !this.enableFields;
  }


  selectedIds: any;


  updateSliderValue(newValue: number) {
    this.sliderValue = newValue;
  }

  updateNumberFromSlider(newValue: number) {
    this.formFieldValues.progressPercentage = newValue;
  }

  addScheduleItemTags() {
    this.jobGroupVisible = true;
  }


  secondModelcancel() {
    this.jobGroupVisible = false;
  }


  SaveOrUpdateData() {
    if (this.isLoadingEdit) {
      this.editData();
    } else {
      this.SaveTagData();
    }
  }

  SaveTagData() {
    if (this.TagsForm.valid) {
      this.loadingIndicatoreService.show();
      this.isLoadingOne = true;
      setTimeout(() => {
        this.leadOppService.getLookUpNameSetup(this.TagsForm.value).subscribe(
          response => {
            this.jobGroupVisible = false;
            const tagName = this.TagsForm.get('name')?.value;
            this.initLoad();
            this.iniliazeForm();
            this.isLoadingOne = false;
            this.toastService.success(`${tagName} saved successfully`);
          },
          (error) => {
            if (error.status === 500) {
              this.toastService.error(
                'Internal Server Error. Please try again later.'
              );
              this.isLoadingOne = false;
            } else if (error.status === 400) {
              this.isLoadingOne = false;
              this.toastService.error('Bad Request. Please check your input.');
            } else {
              this.toastService.error(
                'Failed to save trade. Please try again.'
              );
            }
          }
        );
      }, 1000);
    } else {
      this.TagsForm.markAllAsTouched();
    }
  }


  editData() {
    if (this.TagsForm.valid) {
      this.loadingIndicatoreService.show();
      const tagName = this.TagsForm.get('name')?.value;
      setTimeout(() => {
        this.leadOppService.getLookUpNameSetup(this.TagsForm.value).subscribe(
          (response: any) => {

            this.loadingIndicatoreService.hide();
            this.jobGroupVisible = false;
            this.initLoad();
            this.isLoadingEdit = false;
            this.toastService.success(`${tagName} Update successfully`);
          },
          (error) => {
            this.loadingIndicatoreService.hide();
            if (error.status === 500) {
              this.toastService.error(
                'Internal Server Error. Please try again later.'
              );
            } else if (error.status === 400) {
              this.toastService.error('Bad Request. Please check your input.');
            } else {
              this.toastService.error(
                'Failed to save division trade. Please try again.'
              );
            }
          }
        );
      }, 1000); // 2000 milliseconds (2 seconds)
    } else {
    }
  }

  iniliazeForm() {
    this.TagsForm = this.fb.group({
      id: [''],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      name: ['', Validators.required],
      companyParameterId: [1],
      formNameId: this.pageId,
      lookUpStandardId: [10018],
      parentCode: ['PMSST'],
      code: ['PMSST'],
      description: [''],
      isFieldValue: [false],
    });
  }
  ConfirmDeleteTags(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete Tags?',
      nzContent:
        'This Tags is not applied to any subs/vendors and will be deleted.',
      nzOkDanger: true,
      nzOkText: 'Delete',
      nzCancelText: 'Cancel',
      nzModalType: 'confirm',
      nzOkLoading: this.isDeleteTagsLoading,
      nzAutofocus: 'ok',
      nzCentered: true,
      nzOnOk: () => this.DeleteData(),
    });
  }
  DeleteData(): Promise<boolean> {
    const vendorId = this.TagsForm.get('globalId')?.value;
    const tagName = this.TagsForm.get('name')?.value;
    this.isDeleteTagsLoading = true;
    this.loadingIndicatoreService.show();

    return new Promise<boolean>((resolve, reject) => {
      this.leadOppService.deleteDataLookupNameSetup(vendorId).subscribe(
        (res: any) => {
          this.isDeleteTagsLoading = false;
          setTimeout(() => {
            this.toastService.success(`${tagName} Deleted successfully`);
            this.jobGroupVisible = false;
            this.loadingIndicatoreService.hide();
            this.confirmModal.destroy();
            resolve(true);
          }, 1000);
          this.isLoadingEdit = false;
          this.TagsForm.reset();
          this.jobGroupVisible = false;
          this.initLoad();
        },
        (error) => {
          this.isDeleteTagsLoading = false;
          this.jobGroupVisible = false;
          reject(false);
        }
      );
    });
  }
  editScheduleItemTags(selectedValue: LookupNameSetupResponse): void {
    if (selectedValue) {
      const selectedId: number = Number(selectedValue); // Convert the selected ID to number
      console.log('selectedId', selectedId);
      this.jobGroupVisible = true;
      this.isLoadingEdit = true;
      const selectedObjectFromLookup = this.lookupSetNameRespons.find(item => item.id === selectedId);
      if (selectedObjectFromLookup) {
        const selectedData = selectedObjectFromLookup;
        if (selectedData) {
          this.TagsForm.controls['name'].setValue(selectedData.name);
          this.TagsForm.controls['globalId'].setValue(selectedData.globalId);
          this.TagsForm.controls['isFieldValue'].setValue(selectedData.isFieldValue);
          this.TagsForm.controls['companyParameterId'].setValue(selectedData.companyParameterId);
          this.TagsForm.controls['formNameId'].setValue(selectedData.formNameId);
          this.TagsForm.controls['lookUpStandardId'].setValue(selectedData.lookUpStandardId);
          this.TagsForm.controls['code'].setValue(selectedData.code);
          this.TagsForm.controls['description'].setValue(selectedData.description);
          this.TagsForm.controls['parentCode'].setValue(selectedData.parentCode);
        }
      }
    }


  }
  selectedNodes: NzTreeNodeOptions[] = [];


  scheduleVisible = true;
  CancelSchedule() {
    this.cancel.emit();
  }


  rfisOpen(responseHeaderId: number, formNameId: number) {
    const modalRef = this.modal.create({
      nzContent: NewRFIsComponent,
      nzFooter: null,
      nzData: {
        headerId: responseHeaderId,
        formNameId: formNameId,
      }
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }


  copyText() {
    // Logic to copy text to clipboard
    const inputField = document.getElementById('yourInputFieldId') as HTMLInputElement;

    if (inputField) {
      inputField.select();
      document.execCommand('copy');
      this.toastService.success('copied!');
    }
  }



  // Notify User Component Open

  notifyUsersOpen() {
    const modalRef = this.modal.create({
      nzContent: NotifyUsersComponent,
      nzFooter: null,
      nzTitle: null
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  // grid methods


  checked = false;
  indeterminate = false;
  listOfCurrentPageData: readonly ItemData[] = [];
  listOfData: readonly ItemData[] = [];
  setOfCheckedId = new Set<number>();



  onCurrentPageDataChange($event: readonly ItemData[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }


  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }


  showConfirm(): void {
    this.modal.confirm({
      nzTitle: '<h3>Break Selected Links?</h3>',
      nzContent: '<p>Are you sure you want to remove this item as a predecessor to the following items?</p>',
      nzOnOk: () => console.log('OK'),
      nzOnCancel: () => console.log('Cancel'),
      nzOkText: 'Remove',
      nzOkDanger: true,
      nzCentered: true,
    });
  }

}