import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CompanySettingsComponent } from './company-settings.component';
//import { PermissionComponent } from './permission/permission.component';

const routes: Routes = [

  {path: '', component: CompanySettingsComponent},
  //{path: 'permission', component: PermissionComponent},
  // {path: 'Sign-out', component: SignUpComponent},
  // {path: 'Forgot-Passeord', component: ForgotPasswordComponent},
];



  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
export class CompanySettingsRoutingModule { }
