<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form (ngSubmit)="onFilterApply()">
    <div class="row mt-2">
      <div class="col-10 p-0">
        <div class="input-group">
         <nz-select
          [(ngModel)]="formFieldValues['standardFilter']"
          name="standardFilter"
          (ngModelChange)="onChangeStandardFilter($event)"
          nzShowSearch
          [nzAutoFocus]="true"
          nzPlaceHolder="Standard Filter"
          r>
          <nz-option *ngFor="let filter of filterResponseData"
           [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')"
           [nzValue]="filter.id"
           nzCustomContent>
           <div class="option-content">
            <!-- Customize content with icons and text -->
            <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
            <span>{{ filter.name }}</span>
           <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

           </div>
          </nz-option>
         </nz-select>
        </div>
       </div>
      <div class="col-1 mt-1">
        <i class="bi bi-three-dots"

        nz-popover
        [nzPopoverContent]="contentTemplate"
        [nzPopoverPlacement]="'top'"

         (click)="openStandardFilterComponent()"></i>
      </div>
    </div>

    <div class="col p-0 text-end">
      <strong nz-icon nzType="close" nzTheme="outline"></strong>
    </div>

    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Title</label>
        <input  [(ngModel)]="formFieldValues['title']" name="title" type="text" class="form-control formField" />
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Assigned To</label>
        <nz-tree-select
        [nzNodes]="Assigned"
        [(ngModel)]="formFieldValues['assignedTo']" name="assignedTo"
        nzShowSearch
        nzAllowClear
        nzCheckable
        nzCheckAll="false"
        nzPlaceHolder="Select contact type"
      ></nz-tree-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Status</label>
        <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['status']" name="status">
          <nz-option *ngFor="let option of keywordOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Tags</label>
        <nz-tree-select
        [(ngModel)]="formFieldValues['tags']" name="tags"
          [nzNodes]="PhasesNodes"
          nzShowSearch
          nzAllowClear
          nzCheckable
          nzCheckAll="false"
          nzPlaceHolder="Select contact type"
        ></nz-tree-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Phases</label>
        <nz-tree-select  style="width: 100%" [nzNodes]="PhasesNodes" nzShowSearch
        nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Status" name="Phases"  [(ngModel)]="formFieldValues['Phases']"  >
      </nz-tree-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Date</label>
        <nz-select nzShowSearch nzAllowClear   [(ngModel)]="formFieldValues['date']" name="date">
          <nz-option *ngFor="let option of keywordOption2" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
      </div>
    </div>

    <div class="row">
      <div class="col text-end">
        <button nz-button nzType="primary">Clear All</button>
        <button nz-button nzType="default" class="ms-1">apply</button>
      </div>
    </div>


  </form>
</div>




<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>
