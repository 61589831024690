import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';

@Component({
  selector: 'app-custom-field-form',
  templateUrl: './custom-field-form.component.html',
  styleUrls: ['./custom-field-form.component.css']
})
export class CustomFieldFormComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();

  customFieldFormVisible=true;
  isSave = false;
  selectedValue: number = 0;
  visibleCards: boolean[] = [];
  attendeesNodes: NzTreeNodeOptions[] = [];
  confirmModal?: NzModalRef;

  constructor(private modal:NzModalService){}

  ngOnInit() {
    this.resetVisibleCards();
  }
  custoFormFieldCancel(): void {
    this.cancel.emit();
  }
  resetVisibleCards() {
    this.visibleCards = Array(16).fill(false); // Initialize with 16 elements (index 0 to 15)
  }
  onDropdownChange(value: number) {
    this.resetVisibleCards();
    if (value > 0 && value < this.visibleCards.length) {
      this.visibleCards[value] = true;
    }
  }

  showConfirmJobDelete(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete Custom Field?',
      nzContent: 'Are you sure you want to delete this Custom Field? <br><br> This will remove every instance of this custom field including historical data. ',
      nzOkText: 'Delete',
      nzCancelText: "Cancel",
      nzOkDanger: true,
      nzStyle: { top: '250px' },
      nzClosable: false,
      nzIconType:'',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        }).catch(() => console.log('Oops errors!'))
    });
  }

}
