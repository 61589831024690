import { Component } from '@angular/core';

@Component({
  selector: 'app-budget-content',
  templateUrl: './budget-content.component.html',
  styleUrls: ['./budget-content.component.css']
})
export class BudgetContentComponent {

}
