<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form (ngSubmit)="onFilterApply()">
    <div class="row mt-2">

      <div class="col-10 p-0">
        <div class="input-group">
          <nz-select [(ngModel)]="formFieldValues['standardFilter']" name="standardFilter"
            (ngModelChange)="onChangeStandardFilter($event)" nzShowSearch [nzAutoFocus]="true"
            nzPlaceHolder="Standard Filter" r>
            <nz-option *ngFor="let filter of filterResponseData"
              [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')" [nzValue]="filter.id" nzCustomContent>
              <div class="option-content">
                <!-- Customize content with icons and text -->
                <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
                <span>{{ filter.name }}</span>
                <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

              </div>
            </nz-option>
          </nz-select>
        </div>
      </div>

      <div class="col-2">
        <button class="p-0" nz-button nzType="link">
          <i class="bi bi-three-dots"
          nz-popover
          [nzPopoverContent]="contentTemplate"
          [nzPopoverPlacement]="'top'"
           (click)="openStandardFilterComponent('SAVE_AS')"></i>
        </button>
      </div>
    </div>


    <div class="row mt-2" *ngIf="selectedFilter === 1">
      <div class="col">
        <p>Your filter has unsaved changes.</p>
      </div>
    </div>
    <div class="row mt-2" *ngIf="selectedFilter === 0">
      <div class="col">
        <p>Your filter has unsaved changes.</p>
      </div>
    </div>

    <div class="col d-flex mt-3">
      <button class="rounded-2" (click)="openStandardFilterComponent('APPLY_SAVE_AS')" type="button" nz-button
        nzType="default" class="me-2" style="font-size: 12px;" *ngIf="selectedFilter === 1">Save As</button>
      <button class="rounded-2" (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;"
        *ngIf="selectedFilter === 1" type="button">Reset</button>
      <button class="rounded-2" (click)="editSaveFilterData()" nz-button nzType="primary" type="button" class="me-2"
        style="font-size: 12px;" *ngIf="selectedFilter === 0">Save</button>
      <button class="rounded-2" (click)="openStandardFilterComponent('APPLY_SAVE_AS')" nz-button nzType="default"
        class="me-2" style="font-size: 12px;" *ngIf="selectedFilter === 0" type="button">Save As</button>
      <button class="rounded-2" (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;"
        *ngIf="selectedFilter === 0" type="button">Reset</button>
    </div>


    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Title</label>
        <input [(ngModel)]="formFieldValues['title']" name="title" type="text" class="form-control formField" />
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Assigned To </label>
        <!-- assigned to -->
        <nz-tree-select [(ngModel)]="formFieldValues['assignedTo']" name="assignedTo" style="width: 100%"
          [nzNodes]="assignedToNodes" [nzShowSearch]="true" nzAllowClear="false" nzCheckable
          (ngModelChange)="onassignedToChange($event)" nzPlaceHolder="Select assignedTo"
          nzDropdownClassName="dropdownStyle" [nzAllowClear]="false">
        </nz-tree-select>

      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Status</label>
        <nz-select nzShowSearch [(ngModel)]="formFieldValues['status']" name="status">
          <nz-option *ngFor="let option of statusoP" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Tags</label>


        <nz-tree-select [(ngModel)]="formFieldValues['Tags']" name="Tags" style="width: 100%" [nzNodes]="tagsNodes"
          nzShowSearch nzAllowClear="false" nzCheckable (ngModelChange)=" ontagsChange($event)"
          nzPlaceHolder="Select Tags" nzDropdownClassName="dropdownStyle">
        </nz-tree-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Phases</label>

        <nz-tree-select [(ngModel)]="formFieldValues['phases']" name="phases" style="width: 100%"
          [nzNodes]="PhasesNodes" nzShowSearch nzAllowClear="false" nzCheckable (ngModelChange)="onPhaseChange($event)"
          nzPlaceHolder="Select phases" nzDropdownClassName="dropdownStyle">
        </nz-tree-select>
      </div>
    </div>
    <div class="row">
      <div class="mt-1 p-0">
        <label class="sub-text">Date</label>
        <nz-select style="width: 100%" [(ngModel)]="formFieldValues['date']" name="date"
          (ngModelChange)="handleLeadSelection($event)">
          <nz-option *ngFor="let option of DateoP" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
      </div>
    </div>


    <div class="row fixed-bottom p-2">
      <div class="col text-end">
        <button class="rounded-2" nz-button nzType="default" (click)="resetFilter()">Clear All</button>
        <button class="rounded-2" nz-button nzType="primary" class="ms-1" [disabled]="isFormDirty">Apply</button>
      </div>
    </div>

  </form>
</div>

<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>
