<nz-layout>
  <nz-sider class="border" nzCollapsible [nzCollapsedWidth]="10" [(nzCollapsed)]="isCollapsed" [nzWidth]="280"
    [nzTrigger]="null">
    <div class="row">
      <app-side-menu-bar [isAllJobOption]="true" (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"></app-side-menu-bar>
    </div>
  </nz-sider>

  <nz-layout>
    <nz-page-header>
      <nz-page-header-title>
        <nz-breadcrumb class="text-start" nz-page-header-breadcrumb>
          <nz-breadcrumb-item>{{AccessLocalStorageService.getJobName()}}</nz-breadcrumb-item>
        </nz-breadcrumb>
        <h2 class="react">Change Orders</h2>
      </nz-page-header-title>
      <nz-page-header-extra>
        <nz-space>
          <button nz-button nzType="default" class="ms-2 rounded-1">
            Export
          </button>
          <button nz-button nzType="default" class="ms-2 rounded-1"
          (click)="openFilter(Messaging_RFIs, PAGE_ID)">
            <i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter
          </button>
          <button (click)="createRFI('RFIs')" nz-button nzType="primary" class="ms-2">
            RFI
          </button>
        </nz-space>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-content class="custom-content">
      <div class="spinner-wrapper" *ngIf="isLoading">
        <img src="/assets/Loder/new-ajax-loader.gif">
      </div>
      <div class="kendo-grid-container">
        <div class="kendo-grid-scrollable">
          <kendo-grid
          [data]="getrfiUserData"
          [sortable]="true"
          [resizable]="true"
          [reorderable]="true"
          [scrollable]="'scrollable'"
        >
        
          <kendo-grid-column
          *ngFor="let column of displayedColumns"
          [field]="column"
          [title]="column"
          [width]="getColumnWidth(column)"
          [locked]="isColumnLocked(column)"
          [headerStyle]="{
            'background': '#f1f1f1',
            'border-bottom': '1px solid #ddd'
          }"
          [headerClass]="{ 'locked-column': isColumnLocked(column) }">
            <ng-template kendoGridHeaderTemplate>
              <span>{{ column }}</span>
              <span 
                nz-icon 
                nzType="pushpin" 
                nzTheme="outline" 
                (click)="toggleColumnLock(column)" 
                [ngClass]="{ 'locked-icon': isColumnLocked(column), 'unlocked-icon': !isColumnLocked(column) }"
              ></span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <ng-container [ngSwitch]="column">
                <ng-container *ngSwitchCase="'RFI Is New'">
                  <ng-container *ngIf="dataItem?.StatusSetupId === 734">
                    <span class="status-text success">New</span>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'Job'">
                  <span class="text-overflow-auto famly-inn">{{ dataItem?.JobName }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'RFI #'">
                  <span (click)="EditRFIs(dataItem?.Id)" class="text-overflow-auto Click-Btn famly-inn">{{ dataItem?.RFINumber }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'Title'">
                  <span class="text-overflow-auto famly-inn">{{ dataItem?.Title }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'Created By'">
                  <span class="text-overflow-auto famly-inn">{{ dataItem?.CreatedBy }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'Quetion Files'">
                  <span class="text-overflow-auto Click-Btn famly-inn">{{ dataItem?.QuestionFiles }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'Assigned To'">
                  <span class="text-overflow-auto">{{ dataItem?.CreatedBy }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'Status'">
                  <ng-container *ngIf="dataItem?.StatusSetupId === 735">
                    <span class="status-text success">{{ dataItem?.StatusName }}</span>
                  </ng-container>
                  <ng-container *ngIf="dataItem?.StatusSetupId === 734">
                    <span class="status-text pending">{{ dataItem?.StatusName }}</span>
                  </ng-container>
                  <ng-container *ngIf="dataItem?.StatusSetupId === 736">
                    <span class="status-text pending">{{ dataItem?.StatusName }}</span>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'Responses'">
                  <span class="text-overflow-auto">{{ dataItem?.Responses }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'Responses File'">
                  <span class="text-overflow-auto">{{ dataItem?.ResponseFiles }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'Due'">
                  <span class="text-overflow-auto">{{ dataItem?.DueOn }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'Related Items'">
                  <span class="text-overflow-auto">{{ dataItem?.RelatedItemId }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'Created Date'">
                  <span class="text-overflow-auto">{{ dataItem?.CreatedOn }}</span>
                </ng-container>
              </ng-container>
            </ng-template>
          </kendo-grid-column>
          </kendo-grid>
        </div>
      </div>
      <nz-footer class="custom-footer">
        <div class="StickyFooterWrapper react">
          <div class="StickyFooter react" style="left: -13px; right: -8px;padding: 0px 40px 0px 40px;">
            <!-- <div class="Sticky__Component Scrollbar" #customScrollbar style="bottom: 97px; overflow-x: scroll;">
              <div style="height: 1px; width: 3065px;"></div>
              <object tabindex="-1" type="text/html" aria-hidden="true" data="about:blank" style="display: block; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; border: none; padding: 0px; margin: 0px; opacity: 0; z-index: -1000; pointer-events: none;"></object>
            </div> -->
             <div class="ListActionBar bottom StickyLayoutHeader react" style="left: -13px; right: -8px;">
              <div nz-row style="margin-top: 10px;">
                <div nz-col nzSpan="14">
                  <ng-container *ngIf="PAGE_ID">
                    <div nz-row>
                      <div nz-col nzSpan="7">
                        <nz-select class="mt-1" (ngModelChange)="onFilterChange($event)" [(ngModel)]="selectedValueControl" nzShowSearch>
                          <nz-option *ngFor="let standard of getStandardViewFilterData" [nzLabel]="standard.name" [nzValue]="standard.id"></nz-option>
                        </nz-select>
                      </div>
                      <div nz-col nzSpan="1">
                        <a nz-popover [nzPopoverContent]="contentTemplate" nzPopoverTrigger="hover" class="react" nz-button nzType="link" (click)="openStandardViewFilterComponent()">
                          <i style="font-size: 1.43em;" class="bx bx-dots-horizontal-rounded mt-2"></i>
                        </a>
                        <ng-template #contentTemplate>
                          <p>Manage Saved Views</p>
                        </ng-template>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div nz-col nzSpan="10">
                  <div class="d-flex justify-content-end align-items-end">
                    <nz-pagination style="float: right;" [nzPageIndex]="currentPage" [nzTotal]="totalRecords"
                      [nzPageSize]="pageSize" nzShowSizeChanger (nzPageIndexChange)="onPageChange($event)"
                      (nzPageSizeChange)="onPageSizeChange($event)" [nzPageSizeOptions]="[10, 20, 50, 75, 100]">
                    </nz-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nz-footer>
    </nz-content>
  </nz-layout>
</nz-layout>