import { map } from 'rxjs';
import { Component, EventEmitter, OnInit, Output, OnChanges, Input } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzSelectSizeType } from 'ng-zorro-antd/select';
import { CustomFieldFormComponent } from '../custom-field-form/custom-field-form.component';
import { CompanySettingJobEmailSampleComponent } from './company-setting-job-email-sample/company-setting-job-email-sample.component';
import { NzTreeNode, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { JobTypeGroupService } from 'src/Service/Job-List/Job-Information/job-type-group.service';
import { LookupNameSetupResponse } from '../Models/LookupModels';
import { jobSetupDefaultOrderPredifinedcodes } from '../Models/LookUpStandardFilterCodes';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CreateJobSetupRequest, JobSetupResponseGetId } from 'src/Models/Job-List/Job-Information/Job-Comp-setting-Classes';
import { JobTypeService } from './../../../../Service/Job-List/Job-Information/job-type.service';
import { JobTypeGroupSetup } from 'src/Models/CustomerInfromation/CustomerInformation';
import { ResponseModel } from 'src/Models/responseMessage.model';
interface Person {
  key: string;
  name: string;
  age: number;
  address: string;
  icon: string;
}

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Output()  jobsOnCompleted = new EventEmitter();
  // @Input() OnJobSetupResponse: JobSetupResponse[];
  jobsComponentVisible = true;
  conformOpen = false;
  switchConform = false;
  tempSwitchValue = false;
  switchDisabled: boolean = false;
  text: string | undefined;
  checked: boolean = false;
  editorValue: string = '';
  value: string[] = ['0-0-0'];
  JobGroupNodes: NzTreeNodeOptions[]=[];
  defaultCost: LookupNameSetupResponse[] = [];
  listOfOption: Array<{ label: string; value: string }> = [];
  size: NzSelectSizeType = 'default';
  singleValue = 'a10';
  tagValue = ['a10', 'c12', 'tag'];
  isChecked: boolean = false;
  switchValue = true;

  showRow: boolean = false;
  PAGE_ID: number = 40021;
  updateDates: boolean = false;
  customfieldinfo: boolean = false;
  isCheckboxChecked: boolean = false;
  emailmesample: boolean = false;
  isLoadingOne = true;
  isLoadingTwo = false;
  isCheckboxChecked11 = false;
  confirmModal?: NzModalRef;
  updateexistingjobs: boolean = false;
  originalSwitchValue: boolean = false;
  jobSetupFormFieldValues: any = {};
  jobtyparahead: number;
  dataset: JobSetupResponseGetId;
  selectedValues: any;
  test: any;
  selectedValuesweqweqw: any;
  isSave = false;
  isSaveAndClose: boolean = false;
  isSaveAndNew: boolean = false;
  isSubmitted: boolean = false;
  btnTest: boolean = false
  btnTestSecond: boolean = false
  DisabledNextActivity: boolean = false;
  isVisibleInJobs: boolean = true;
  contractTyperadioValue = 'B';
  jobList: JobTypeGroupSetup[] =[];

  constructor(
    private modal: NzModalService,
    private JobTypeService: JobTypeService,
    public toastsService: NzMessageService,
    private jobSetupService: JobTypeGroupService,
    private LookupFilterService: LookupFilterService,
    private message: NzMessageService,) {
    this.editorValue = `
    <p>Xircon Homes is inviting you to view your job! By activating your account, you can begin using Buildertrend for easier communication with Xircon Homes. Through the website or mobile app, you can follow along with the progress of your job and stay up-to-date on all of the details.</p>
    <p>Activation is free, easy and takes less than a minute. Please click 'Accept' to get started.</p>
    <p>To learn more about the experience, watch this video: Home Owner Instructional Video [https://vimeo.com/186487791/439b4fa4aa]</p>
    `
  }
  preDefinedDataCodes = {
    defaultCostSetting: jobSetupDefaultOrderPredifinedcodes.defaultCostSetting,
  };
  ngOnInit(): void {
    this.initLoad();
    this.jobsetupFromGroup();
    this.fetchCompanyJobsetup();
    this.loadJobTypes();
  }
  loadJobTypes(): void {
    this.JobTypeService.getAllDataJobTypesSetup().subscribe(
      (res) => {
        this.jobList = res.result;
        if (Array.isArray(this.jobList)) {
          this.JobGroupNodes = [
            {
              title: 'Check All',
              value: 'check_all',
              key: 'check_all',
              selectable: false,
              isLeaf: false,
              expanded: true,
              children: this.jobList.map((JobGroup) => ({
                title: JobGroup.groupName,
                value: JobGroup.groupName,
                key: JobGroup.groupName,
                isLeaf: false,
                expanded: true,
                children: JobGroup.jobTypes.map((JobType) => ({
                  title: JobType.jobTypeName,
                  value: JobType.jobTypeId.toString(),
                  key: JobType.jobTypeId.toString(),
                  isLeaf: true,
                  disabled: false,
                }))
              }))
            },
          ];
          // let setTest = this.JobGroupNodes.flatMap(c => c.children[0].children.flatMap(ct => ct['value']));
          // let setTest1 = this.JobGroupNodes.flatMap(c => c.children[1].children.flatMap(ct => ct['value']));
          // let selectedValues = [setTest[0], setTest[1], setTest1[0]];
  
          // this.JobGroupNodes[0].children.forEach(group => {
          //   group.children.forEach(jobType => {
          //     if (selectedValues.includes(jobType['value'])) {
          //       jobType.disabled = true;
          //     }
          //   });
          // });
          // this.test = selectedValues;
        }
      },
      (error) => {
        console.error('Error fetching data', error);
      }
    );
  }
  onGroupSelect(selectedValues: string[] | string): void {
    const valuesArray = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
    const selectedNodes = this.JobGroupNodes.flatMap(node => node.children).filter(child => valuesArray.includes(child['value']));
    this.selectedValuesweqweqw = selectedNodes.map(xx => xx.children.map(xx => xx['value']));
    if(valuesArray.includes('check_all')) {
      const allValues = this.JobGroupNodes.flatMap(node => node.children.flatMap(node => node.children.map(xx => xx['value'])));
      this.test =  allValues
      this.jobSetupFormFieldValues.createJobSetupTypeParameterRequests = allValues
    } else {
      this.test = valuesArray;
      if (this.selectedValuesweqweqw.length === 0) {
        this.jobSetupFormFieldValues.createJobSetupTypeParameterRequests = valuesArray;
      } else {
        this.jobSetupFormFieldValues.createJobSetupTypeParameterRequests = this.selectedValuesweqweqw;
      }
    }
    this.contractTyperadioButtons();
  }
  resetFlags() {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
  }
  jobsetupFromGroup() {
    this.jobSetupFormFieldValues = {
      globalId: '00000000-0000-0000-0000-000000000000',
      companyParameterId: 1,
      includeTimeClockLaborInBudgetOnNewJobs: false,
      includeTimeClockLaborInTotalCostsAndPaidColumnsOnBudgetForNewJobs: false,
      hideJobPriceSummaryForOwnersOnNewJobs: false,
      defaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs: false,
      onNewJobsUpdateJobRunningTotalWhenASelectionIsApproved: false,
      disablePOAndBudgetSharingForOwnersOnAllJobs: false,
      allowOwnerChangeOrderRequestsByDefault: false,
      isOpenBook: false,
      isFixedPrice: false,
      costSettingId: 0,
      customizeOwnerEmailInviteText: false,
      emailSample: "",
      createJobSetupTypeParameterRequests: []
    };
  }
  fetchCompanyJobsetup() {
    this.jobSetupService.getAllJobSetupPatch().subscribe(
      (response) => {
        this.dataset = response.result;
        // if(this.dataset.length === 1){
          const firstItem = this.dataset;
          this.test = firstItem?.JobTypeIds? firstItem.JobTypeIds.split(',').map(id => id.trim()): [],   
          this.jobSetupFormFieldValues = {
            globalId: firstItem.GlobalId || '00000000-0000-0000-0000-000000000000',
            companyParameterId: firstItem.CompanyParameterId || 1,
            includeTimeClockLaborInBudgetOnNewJobs: firstItem.IncludeTimeClockLaborInBudgetOnNewJobs || false,
            includeTimeClockLaborInTotalCostsAndPaidColumnsOnBudgetForNewJobs: firstItem.IncludeTimeClockLaborInTotalCostsAndPaidColumnsOnBudgetForNewJobs || false,
            hideJobPriceSummaryForOwnersOnNewJobs: firstItem.HideJobPriceSummaryForOwnersOnNewJobs || false,
            defaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs: firstItem.DefaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs || false,
            onNewJobsUpdateJobRunningTotalWhenASelectionIsApproved: firstItem.OnNewJobsUpdateJobRunningTotalWhenASelectionIsApproved || false,
            disablePOAndBudgetSharingForOwnersOnAllJobs: firstItem.DisablePOAndBudgetSharingForOwnersOnAllJobs || false,
            allowOwnerChangeOrderRequestsByDefault: firstItem.AllowOwnerChangeOrderRequestsByDefault || false,
            costSettingId: firstItem.CostSettingId || 0,
            customizeOwnerEmailInviteText: firstItem.CustomizeOwnerEmailInviteText || '',
            emailSample: firstItem.EmailSample || '',
          };
          if(firstItem.IsFixedPrice ===  true){
            this.contractTyperadioValue = 'A'
          } else {
            this.contractTyperadioValue = 'B'
          }
        }
      // }
      ,
      (error) => {
        console.error('Error:', error);
      }
    );
  }
  customFieldsFormOpen() {
    const modalRef = this.modal.create({
      nzContent: CustomFieldFormComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      modalRef.destroy();
    });
  }
  emailSampleFormOpen() {
    const modalRef = this.modal.create({
      nzContent: CompanySettingJobEmailSampleComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  listOfData: Person[] = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
      icon: 'check'
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park',
      icon: 'check'
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
      icon: 'check'
    }
  ];
  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe(
      (res) => {
        // -----------single Select ---------------
        let defaultCostValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.defaultCostSetting.code
        );
        // -----------single Select --------------
        this.defaultCost = defaultCostValues;
      });
  }
  // showConfirmToggle(event: MouseEvent): void {
  //   event.preventDefault();

  //   if (this.jobSetupFormFieldValues.customizeOwnerEmailInviteText) {
  //     this.originalSwitchValue = this.jobSetupFormFieldValues.customizeOwnerEmailInviteText;

  //     this.confirmModal = this.modal.confirm({
  //       nzTitle: 'Reset to Default Invite?',
  //       nzContent: 'By resetting this invite text, any prior changes will be lost once you hit "Save".<p class="pt-2">Are you sure you want to continue?</p>',
  //       nzOkText: 'Confirm',
  //       nzCancelText: 'Cancel',
  //       nzStyle: { top: '250px' },
  //       nzClosable: false,
  //       nzIconType: '',
  //       nzOnOk: () => 
  //         new Promise((resolve) => {
  //           setTimeout(() => {
  //             this.jobSetupFormFieldValues.customizeOwnerEmailInviteText = false;
  //             this.switchDisabled = true; 
  //             resolve(true);
  //           }, 1000);
  //         }),
  //       nzOnCancel: () => {
  //         this.jobSetupFormFieldValues.customizeOwnerEmailInviteText = this.originalSwitchValue;
  //       }
  //     });
  //   }
  // }
  showConfirmToggle(event: MouseEvent): void {
    event.preventDefault();

    if (this.jobSetupFormFieldValues.customizeOwnerEmailInviteText) {
      this.originalSwitchValue = this.jobSetupFormFieldValues.customizeOwnerEmailInviteText;
      this.modal.confirm({
        nzTitle: 'Reset to Default Invite?',
        nzContent: 'By resetting this invite text, any prior changes will be lost once you hit "Save".<p class="pt-2">Are you sure you want to continue?</p>',
        nzOkText: 'Confirm',
        nzCancelText: 'Cancel',
        nzOnOk: () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              if (Math.random() > 0.5) {
                this.message.success('Job have been successfully updated.');
                resolve(null);
              } else {
                reject();
              }
            }, 1000);
          }),
          nzOnCancel: () => {
            this.jobSetupFormFieldValues.customizeOwnerEmailInviteText = this.originalSwitchValue;
          },
        nzCentered: true,
        nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
        nzCloseIcon: '',
        nzIconType: '',
      });
    }
  }
  onBtSelect(){
    this.btnTest = !this.btnTest;
  }
  onBtSelectSecond(){
    this.btnTestSecond = !this.btnTestSecond;
  }
  CancelJobs() {
    this.isVisibleInJobs = false;
    this.cancel.emit();
  }
  saveJobs(actionType: 'save' = 'save'): Promise<void> {
    let data = this.jobSetupFormFieldValues;
    return new Promise((resolve, reject) => {
        if (this.isSave) {
            return reject('Already processing');
        }

        if (actionType === 'save') {
            this.isSave = true;
        }

        this.DisabledNextActivity = true;
        
        const jobTypeParameterRequests = data.createJobSetupTypeParameterRequests || [];
        const flattenedJobTypeIds = Array.isArray(jobTypeParameterRequests) ? jobTypeParameterRequests.flat() : [];
        data.createJobSetupTypeParameterRequests = flattenedJobTypeIds.map(jobTypeId => ({
            jobTypeId: +jobTypeId 
        }));
        resolve();
        this.jobSetupService.jobsetupCompanySettingPostData(data).subscribe(
            (response) => {
                this.jobsOnCompleted.emit(response.result);
                this.cancel.emit();
                this.DisabledNextActivity = false;
                resolve();
            },
            (error) => {
                console.error('Error saving data:', error);
                this.toastsService.error('An error has occurred. Please try again.');
                this.DisabledNextActivity = false;
                reject(error);
            }
        ).add(() => {
            this.resetFlags();
        });
    });
}

  contractTyperadioButtons(){
    switch (this.contractTyperadioValue) {
      case 'A':
        this.jobSetupFormFieldValues.isOpenBook = false
        this.jobSetupFormFieldValues.isFixedPrice = true;
        break;
      case 'B':
       this.jobSetupFormFieldValues.isFixedPrice = false
        this.jobSetupFormFieldValues.isOpenBook = true;
        break;
    }
  }
}