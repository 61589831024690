<div class="row mt-5" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <div class="col" style="text-align: center;">
       
       <span>
        <img src="assets/Empty-SVGs/Messaging/RFIS/rfis.svg" style="font-size: 70px;">
      </span>   
        <div class="row">
            <div class="col mt-2">
              <h2   >Clarify the unknow  with RFIs</h2>
            </div>
        </div>
        <div class="col mt-3">
        <p>Streamline formal requests for information with Subs/Vendor  <br>project moving.</p>
  
  
        </div>
    </div>
    <div class="col mt-4" style="display: flex; justify-content: center; align-items: center;">
        <button nz-button nzType="default" style="margin-right: 10px;"><i class="bi bi-box-arrow-up-right me-2"></i>Learn How</button>
        <button nz-button nzType="primary" style="margin-right: 10px;" (click)="newRFIsOpen()">Add an RFI</button>
    </div>
  </div> 
  