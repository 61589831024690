import { Component } from '@angular/core';
import { NzSelectSizeType } from 'ng-zorro-antd/select';

@Component({
  selector: 'app-catalog-cost-groups',
  templateUrl: './catalog-cost-groups.component.html',
  styleUrls: ['./catalog-cost-groups.component.css']
})
export class CatalogCostGroupsComponent {
  checked = false;
  checked1 = false;
  selectedValue = null;
  costgroups:boolean = false;
  CostGroups(){
    this.costgroups =  true;
  }
  CoseCostGroups(){
    this.costgroups =  false;
  }
  autoAdjustTextAreaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; // Set the height with a maximum of 200px
  }
  isSectionVisible = false;

  toggleSection() {
    this.isSectionVisible = !this.isSectionVisible;
  }
  costitem:boolean = false;
  add:boolean = false;
  Add(){
    this.add =  true;
  }
  CloseAdd(){
    this.add =  false;
  }
  add3:boolean = false;

  Add2(){
    this.add3 =  true;
  }
  CloseAdd2(){
    this.add3 =  false;
  }
  CostItem(){
    this.costitem =  true;
  }
  CloseCostItem(){
    this.costitem =  false;
  }
  
  activeTabs: string = 'costItem';

  ActiveTab(tabName: string) {
    this.activeTabs = tabName;
}
listOfOption: Array<{ label: string; value: string }> = [];
size: NzSelectSizeType = 'default';
singleValue = 'a10';
multipleValue = [];
tagValue = ['a10', 'c12', 'tag'];

ngOnInit(): void {
  const children: Array<{ label: string; value: string }> = [];
  for (let i = 10; i < 36; i++) {
    children.push({ label: i.toString(36) + i, value: i.toString(36) + i });
  }
  this.listOfOption = children;
}
isCheckboxChecked11 = false;

onCheckboxChange11() {
  this.isCheckboxChecked11 = !this.isCheckboxChecked11;
}
isCheckboxChecked12 = false;

onCheckboxChange12() {
  this.isCheckboxChecked12 = !this.isCheckboxChecked12;
}
}
