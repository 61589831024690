import { Component } from '@angular/core';
import { TermsAndConditionsService } from 'src/Service/Lead-opp/terms-and-conditions.service';

@Component({
  selector: 'app-lead-proposal-preview',
  templateUrl: './lead-proposal-preview.component.html',
  styleUrls: ['./lead-proposal-preview.component.css']
})
export class LeadProposalPreviewComponent {


  termsAndConditionsList: string[];
  termsAndConditionssequenceList: string[];
  termsAndConditionsundersignedList: string[];
  CompanyInfoList: string[];

  constructor(private termsService: TermsAndConditionsService) {
    const conditions = this.termsService.getTermsAndConditions();
    this.termsAndConditionsList = conditions['termsAndConditions'];
    this.termsAndConditionssequenceList = conditions['termsAndConditionssequence'];
    this.termsAndConditionsundersignedList = conditions['termsAndConditionsundersigned'];
    this.CompanyInfoList = conditions['CompanyInfo'];
  }
  


}
