<nz-layout>
  <nz-sider
    class="border"
    nzCollapsible
    [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed"
    [nzWidth]="280"
    [nzTrigger]="null"
  >
    <div class="row">
      <app-side-menu-bar
      [isAllJobOption]="true"
      (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"
      ></app-side-menu-bar>
    </div>
  </nz-sider>

  <nz-layout>
    <nz-content>
      <div class="inner-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="row mt-4 m-0">
                <div class="col-lg-12">
                  <div class="row">
                    <!-- Three Cards -->
                    <div class="col-md-8 mb-4">
                      <div class="card border-0">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-12">
                              <div class="row g-0">
                                <div class="col-6 flex">
                                  <h4 nz-typography *ngIf="accessLocalStorageService.getJobName()">
                                    {{accessLocalStorageService.getJobName()}}
                                  </h4>
                                  <span
                                    class="margin-right-sm mt-0"
                                    style="
                                      width: 24px;
                                      height: 24px;
                                      border-color: transparent;
                                    "
                                    ><span
                                      class="ColorSwatch-Swatch"
                                      style="
                                        background: rgb(67, 81, 48);
                                        width: 16px;
                                        height: 16px;
                                        cursor: default;
                                      "
                                    ></span
                                  ></span>
                                </div>
                                <div class="col-6 text-end">
                                  <div class="WeatherDailyForecast">
                                    <h3 class="margin-right-sm" nz-typography>
                                      Clear
                                    </h3>
                                    <img
                                      style="width: 40px"
                                      class="margin-right-sm"
                                      src="assets\day-partlyCloudyx50 (1).png"
                                      alt="Partly Cloudy"
                                    />
                                    <span
                                      class="pira-label fs-5 margin-right-xs"
                                      >15° C</span
                                    >
                                    <div class="ListViewJobname fs-6">
                                      TRUGANINA VIC 3029
                                    </div>
                                    <button
                                      class="border-0"
                                      nz-button
                                      nzShape="circle"
                                      [nzLoading]="isLoadingOne"
                                    ></button>
                                    <div
                                      class="flex justify-content-center align-items-center"
                                    >
                                      <span
                                        role="img"
                                        aria-label="sync"
                                        class="anticon anticon-sync"
                                      >
                                        <svg
                                          (click)="loadOne()"
                                          viewBox="64 64 896 896"
                                          focusable="false"
                                          data-icon="sync"
                                          width="1em"
                                          height="1em"
                                          fill="currentColor"
                                          aria-hidden="true"
                                        >
                                          <path
                                            d="M168 504.2c1-43.7 10-86.1 26.9-126 17.3-41 42.1-77.7 73.7-109.4S337 212.3 378 195c42.4-17.9 87.4-27 133.9-27s91.5 9.1 133.8 27A341.5 341.5 0 01755 268.8c9.9 9.9 19.2 20.4 27.8 31.4l-60.2 47a8 8 0 003 14.1l175.7 43c5 1.2 9.9-2.6 9.9-7.7l.8-180.9c0-6.7-7.7-10.5-12.9-6.3l-56.4 44.1C765.8 155.1 646.2 92 511.8 92 282.7 92 96.3 275.6 92 503.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8zm756 7.8h-60c-4.4 0-7.9 3.5-8 7.8-1 43.7-10 86.1-26.9 126-17.3 41-42.1 77.8-73.7 109.4A342.45 342.45 0 01512.1 856a342.24 342.24 0 01-243.2-100.8c-9.9-9.9-19.2-20.4-27.8-31.4l60.2-47a8 8 0 00-3-14.1l-175.7-43c-5-1.2-9.9 2.6-9.9 7.7l-.7 181c0 6.7 7.7 10.5 12.9 6.3l56.4-44.1C258.2 868.9 377.8 932 512.2 932c229.2 0 415.5-183.7 419.8-411.8a8 8 0 00-8-8.2z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row g-0">
                                <div class="col-6 flex">
                                  <div class="row">
                                    <div class="col-12">
                                      <strong>0</strong
                                      ><span style="white-space: nowrap"
                                        >internal users are clocked in as of
                                        Invalid date</span
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="row mt-4"
                                    style="margin-left: -313px"
                                  >
                                    <div class="col-12">
                                      <button
                                        [routerLink]="['/TimeClock/Shifts']"
                                        class="text-start"
                                        nz-button
                                        nzType="link"
                                      >
                                        <span>View time sheets</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-6 text-end mt-3">
                                  <h4 nz-typography>
                                    <img
                                      (click)="ViewTime()"
                                      class="me-1"
                                      src="assets/ProjectManagerSitesDiaries/map-marker-default.svg"
                                      alt="Open in Maps"
                                      style="height: 18px; width: 16px"
                                    />LOT 1829 TOPIARY STREET TRUGANINA VIC 3029
                                  </h4>
                                  <div class="row mt-1">
                                    <div class="pira-label margin-right-md">
                                      TRUGANINA
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="pira-label margin-right-md">
                                      VIC 3029
                                    </div>
                                  </div>
                                  <div class="row">
                                    <button
                                      class="text-end"
                                      nz-button
                                      nzType="link"
                                    >
                                      <span>Get directions</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row g-0">
                            <div class="col-4">
                              <span class="sub-text" style="letter-spacing: 2px"
                                >OWNER</span
                              >
                            </div>
                            <div class="col-8">
                              <span class="sub-text" style="letter-spacing: 2px"
                                >PROJECT MANAGERS</span
                              >
                            </div>
                          </div>
                          <div class="row gx-2">
                            <div class="col-4 div-set">
                              <div class="row gx-5 mt-1 align-items-center">
                                <!-- Added align-items-center -->
                                <div class="col-3 mt-2">
                                  <nz-avatar
                                    style="font-size: 30px"
                                    [nzSize]="64"
                                    nzText="YT"
                                  ></nz-avatar>
                                </div>
                                <div class="col-9">
                                  <div class="row">
                                    <div class="col-12 pira-label">
                                      ZH-080 LOT 1829 TOPIARY STREET TRUGANINA
                                      VIC 3029
                                    </div>
                                  </div>
                                  <div class="row mt-1">
                                    <div class="col-12 text-primary">
                                      <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        data-icon="phone"
                                        width="1.25em"
                                        height="1.25em"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          d="M885.6 230.2L779.1 123.8a80.83 80.83 0 00-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L549.8 238.4a80.83 80.83 0 00-23.8 57.3c0 21.7 8.5 42.1 23.8 57.4l83.8 83.8A393.82 393.82 0 01553.1 553 395.34 395.34 0 01437 633.8L353.2 550a80.83 80.83 0 00-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L123.8 664.5a80.89 80.89 0 00-23.8 57.4c0 21.7 8.5 42.1 23.8 57.4l106.3 106.3c24.4 24.5 58.1 38.4 92.7 38.4 7.3 0 14.3-.6 21.2-1.8 134.8-22.2 268.5-93.9 376.4-201.7C828.2 612.8 899.8 479.2 922.3 344c6.8-41.3-6.9-83.8-36.7-113.8z"
                                        ></path>
                                      </svg>
                                      <label class="ms-1">0448289732</label>
                                    </div>
                                  </div>
                                  <div class="row mt-2 mb-2">
                                    <div class="col-12 text-primary">
                                      <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        data-icon="mail"
                                        width="1.25em"
                                        height="1.25em"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-80.8 108.9L531.7 514.4c-7.8 6.1-18.7 6.1-26.5 0L189.6 268.9A7.2 7.2 0 01194 256h648.8a7.2 7.2 0 014.4 12.9z"
                                        ></path>
                                      </svg>
                                      <label class="ms-1">Send a message</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-8">
                              <div class="ProjectManagerCards">
                                <div class="ProjectManagerCardsWrapper">
                                  <div class="CarouselContainer ScrollForward">
                                    <div
                                      class="CardCarousel flex"
                                      [style.marginLeft.px]="carouselPosition"
                                    >
                                      <div class="col-3 div-set me-3">
                                        <div class="row gx-5 mt-1">
                                          <div class="col-3 mt-2">
                                            <nz-avatar
                                              style="font-size: 30px"
                                              [nzSize]="64"
                                              nzText="YT"
                                            ></nz-avatar>
                                          </div>
                                          <div class="col-9">
                                            <div class="row">
                                              <div class="col-12 pira-label">
                                                Deepak Rana
                                              </div>
                                            </div>
                                            <div class="row mt-1">
                                              <div class="col-12 text-primary">
                                                <svg
                                                  viewBox="64 64 896 896"
                                                  focusable="false"
                                                  data-icon="phone"
                                                  width="1.25em"
                                                  height="1.25em"
                                                  fill="currentColor"
                                                  aria-hidden="true"
                                                >
                                                  <path
                                                    d="M885.6 230.2L779.1 123.8a80.83 80.83 0 00-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L549.8 238.4a80.83 80.83 0 00-23.8 57.3c0 21.7 8.5 42.1 23.8 57.4l83.8 83.8A393.82 393.82 0 01553.1 553 395.34 395.34 0 01437 633.8L353.2 550a80.83 80.83 0 00-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L123.8 664.5a80.89 80.89 0 00-23.8 57.4c0 21.7 8.5 42.1 23.8 57.4l106.3 106.3c24.4 24.5 58.1 38.4 92.7 38.4 7.3 0 14.3-.6 21.2-1.8 134.8-22.2 268.5-93.9 376.4-201.7C828.2 612.8 899.8 479.2 922.3 344c6.8-41.3-6.9-83.8-36.7-113.8z"
                                                  ></path>
                                                </svg>
                                                <label class="ms-1"
                                                  >0448289732</label
                                                >
                                              </div>
                                            </div>
                                            <div class="row mt-2 mb-2">
                                              <div class="col-12 text-primary">
                                                <svg
                                                  viewBox="64 64 896 896"
                                                  focusable="false"
                                                  data-icon="mail"
                                                  width="1.25em"
                                                  height="1.25em"
                                                  fill="currentColor"
                                                  aria-hidden="true"
                                                >
                                                  <path
                                                    d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-80.8 108.9L531.7 514.4c-7.8 6.1-18.7 6.1-26.5 0L189.6 268.9A7.2 7.2 0 01194 256h648.8a7.2 7.2 0 014.4 12.9z"
                                                  ></path>
                                                </svg>
                                                <label class="ms-1"
                                                  >Send a message</label
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-3 div-set me-3">
                                        <div class="row gx-5 mt-1">
                                          <div class="col-3 mt-2">
                                            <nz-avatar
                                              style="font-size: 30px"
                                              [nzSize]="64"
                                              nzText="YT"
                                            ></nz-avatar>
                                          </div>
                                          <div class="col-9">
                                            <div class="row">
                                              <div class="col-12 pira-label">
                                                Deepak Rana
                                              </div>
                                            </div>
                                            <div class="row mt-1">
                                              <div class="col-12 text-primary">
                                                <svg
                                                  viewBox="64 64 896 896"
                                                  focusable="false"
                                                  data-icon="phone"
                                                  width="1.25em"
                                                  height="1.25em"
                                                  fill="currentColor"
                                                  aria-hidden="true"
                                                >
                                                  <path
                                                    d="M885.6 230.2L779.1 123.8a80.83 80.83 0 00-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L549.8 238.4a80.83 80.83 0 00-23.8 57.3c0 21.7 8.5 42.1 23.8 57.4l83.8 83.8A393.82 393.82 0 01553.1 553 395.34 395.34 0 01437 633.8L353.2 550a80.83 80.83 0 00-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L123.8 664.5a80.89 80.89 0 00-23.8 57.4c0 21.7 8.5 42.1 23.8 57.4l106.3 106.3c24.4 24.5 58.1 38.4 92.7 38.4 7.3 0 14.3-.6 21.2-1.8 134.8-22.2 268.5-93.9 376.4-201.7C828.2 612.8 899.8 479.2 922.3 344c6.8-41.3-6.9-83.8-36.7-113.8z"
                                                  ></path>
                                                </svg>
                                                <label class="ms-1"
                                                  >0448289732</label
                                                >
                                              </div>
                                            </div>
                                            <div class="row mt-2 mb-2">
                                              <div class="col-12 text-primary">
                                                <svg
                                                  viewBox="64 64 896 896"
                                                  focusable="false"
                                                  data-icon="mail"
                                                  width="1.25em"
                                                  height="1.25em"
                                                  fill="currentColor"
                                                  aria-hidden="true"
                                                >
                                                  <path
                                                    d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-80.8 108.9L531.7 514.4c-7.8 6.1-18.7 6.1-26.5 0L189.6 268.9A7.2 7.2 0 01194 256h648.8a7.2 7.2 0 014.4 12.9z"
                                                  ></path>
                                                </svg>
                                                <label class="ms-1"
                                                  >Send a message</label
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-3 div-set me-3">
                                        <div class="row gx-5 mt-1">
                                          <div class="col-3 mt-2">
                                            <nz-avatar
                                              style="font-size: 30px"
                                              [nzSize]="64"
                                              nzText="YT"
                                            ></nz-avatar>
                                          </div>
                                          <div class="col-9">
                                            <div class="row">
                                              <div class="col-12 pira-label">
                                                Deepak Rana
                                              </div>
                                            </div>
                                            <div class="row mt-1">
                                              <div class="col-12 text-primary">
                                                <svg
                                                  viewBox="64 64 896 896"
                                                  focusable="false"
                                                  data-icon="phone"
                                                  width="1.25em"
                                                  height="1.25em"
                                                  fill="currentColor"
                                                  aria-hidden="true"
                                                >
                                                  <path
                                                    d="M885.6 230.2L779.1 123.8a80.83 80.83 0 00-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L549.8 238.4a80.83 80.83 0 00-23.8 57.3c0 21.7 8.5 42.1 23.8 57.4l83.8 83.8A393.82 393.82 0 01553.1 553 395.34 395.34 0 01437 633.8L353.2 550a80.83 80.83 0 00-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L123.8 664.5a80.89 80.89 0 00-23.8 57.4c0 21.7 8.5 42.1 23.8 57.4l106.3 106.3c24.4 24.5 58.1 38.4 92.7 38.4 7.3 0 14.3-.6 21.2-1.8 134.8-22.2 268.5-93.9 376.4-201.7C828.2 612.8 899.8 479.2 922.3 344c6.8-41.3-6.9-83.8-36.7-113.8z"
                                                  ></path>
                                                </svg>
                                                <label class="ms-1"
                                                  >0448289732</label
                                                >
                                              </div>
                                            </div>
                                            <div class="row mt-2 mb-2">
                                              <div class="col-12 text-primary">
                                                <svg
                                                  viewBox="64 64 896 896"
                                                  focusable="false"
                                                  data-icon="mail"
                                                  width="1.25em"
                                                  height="1.25em"
                                                  fill="currentColor"
                                                  aria-hidden="true"
                                                >
                                                  <path
                                                    d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-80.8 108.9L531.7 514.4c-7.8 6.1-18.7 6.1-26.5 0L189.6 268.9A7.2 7.2 0 01194 256h648.8a7.2 7.2 0 014.4 12.9z"
                                                  ></path>
                                                </svg>
                                                <label class="ms-1"
                                                  >Send a message</label
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-3 div-set me-3">
                                        <div class="row gx-5 mt-1">
                                          <div class="col-3 mt-2">
                                            <nz-avatar
                                              style="font-size: 30px"
                                              [nzSize]="64"
                                              nzText="YT"
                                            ></nz-avatar>
                                          </div>
                                          <div class="col-9">
                                            <div class="row">
                                              <div class="col-12 pira-label">
                                                Deepak Rana
                                              </div>
                                            </div>
                                            <div class="row mt-1">
                                              <div class="col-12 text-primary">
                                                <svg
                                                  viewBox="64 64 896 896"
                                                  focusable="false"
                                                  data-icon="phone"
                                                  width="1.25em"
                                                  height="1.25em"
                                                  fill="currentColor"
                                                  aria-hidden="true"
                                                >
                                                  <path
                                                    d="M885.6 230.2L779.1 123.8a80.83 80.83 0 00-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L549.8 238.4a80.83 80.83 0 00-23.8 57.3c0 21.7 8.5 42.1 23.8 57.4l83.8 83.8A393.82 393.82 0 01553.1 553 395.34 395.34 0 01437 633.8L353.2 550a80.83 80.83 0 00-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L123.8 664.5a80.89 80.89 0 00-23.8 57.4c0 21.7 8.5 42.1 23.8 57.4l106.3 106.3c24.4 24.5 58.1 38.4 92.7 38.4 7.3 0 14.3-.6 21.2-1.8 134.8-22.2 268.5-93.9 376.4-201.7C828.2 612.8 899.8 479.2 922.3 344c6.8-41.3-6.9-83.8-36.7-113.8z"
                                                  ></path>
                                                </svg>
                                                <label class="ms-1"
                                                  >0448289732</label
                                                >
                                              </div>
                                            </div>
                                            <div class="row mt-2 mb-2">
                                              <div class="col-12 text-primary">
                                                <svg
                                                  viewBox="64 64 896 896"
                                                  focusable="false"
                                                  data-icon="mail"
                                                  width="1.25em"
                                                  height="1.25em"
                                                  fill="currentColor"
                                                  aria-hidden="true"
                                                >
                                                  <path
                                                    d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-80.8 108.9L531.7 514.4c-7.8 6.1-18.7 6.1-26.5 0L189.6 268.9A7.2 7.2 0 01194 256h648.8a7.2 7.2 0 014.4 12.9z"
                                                  ></path>
                                                </svg>
                                                <label class="ms-1"
                                                  >Send a message</label
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="BTArrowButton left"
                                        (click)="moveCarousel('left')"
                                      >
                                        <div class="BTArrowButtonIcon"></div>
                                      </div>
                                      <div
                                        class="BTArrowButton right"
                                        (click)="moveCarousel('right')"
                                      >
                                        <div class="BTArrowButtonIcon"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <app-past-due-for-you></app-past-due-for-you>
                      <app-recent-activity-from-your-team></app-recent-activity-from-your-team>
                    </div>
                    <!-- Right Side Column (col-lg-4) -->
                    <div class="col-lg-4">
                      <app-this-weeks-agenda></app-this-weeks-agenda>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
