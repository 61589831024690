import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { WProofreader } from '@webspellchecker/wproofreader-ckeditor5';
import { SelectionService } from 'src/Service/Selection/selection.service';
// import { BlockQuote, Bold, ClassicEditor, Essentials, Font, FontBackgroundColor, FontColor, FontFamily, FontSize, Heading, HorizontalLine, ImageUpload, Indent, IndentBlock, Italic, Link, List, MediaEmbed, Paragraph, Table, Undo, UndoEditing } from 'ckeditor5';
import { Bold, ClassicEditor, Essentials, Font, Heading, Indent, IndentBlock, Italic, Link, List, MediaEmbed, Mention, Paragraph, Table, Undo, FontSize, FontFamily, Style, FontBackgroundColor, FontColor, BlockQuoteCommand, BlockQuote, Image, HorizontalLine, UndoEditing, ImageUpload } from 'ckeditor5';
import { NzButtonGroupSize } from 'ng-zorro-antd/button';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { SelectionChoiceParameterResponse, SelectionChoiceResult } from 'src/Models/Selection/selectionClasses';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { LeadOpportunityAttachmentsUploadFilesComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { NzImageService } from 'ng-zorro-antd/image';
import { AttachmentAnnotationComponent } from 'src/app/lead-opportunities/Components/attachment-annotation/attachment-annotation.component';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/environment';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { CostCodeCategoryResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LeadProposalsPreDefinedCodes, SelectionStatusCode } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { LeadProposalsService } from 'src/Service/LeadProp/lead-proposals.service';
import { CostItemParameterResponse, CostItemResponse, CreateCostItemRequest, CreateCostItemRequestchoice } from 'src/Models/LeadProposal/LeadProposalModels';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { CostItemService } from 'src/Service/Cost_Item/cost-item.service';
import { saveAttachments } from 'src/app/helpers/Attachment_Save';
import { AttachmentParameterResponse, AttachmentResponse, CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { SubVendersService } from 'src/Service/SubVendorsServices/sub-venders.service';
import { TimeClockService } from 'src/Service/TimeClock/time-clock.service';
interface Person {
  key: string;
  name: string;
  age: number;
  address: string;
}
@Component({
  selector: 'app-addchoice-selection-choice',
  templateUrl: './addchoice-selection-choice.component.html',
  styleUrls: ['./addchoice-selection-choice.component.css']
})
export class AddchoiceSelectionChoiceComponent implements OnInit {

  @Output() cancel = new EventEmitter<void>();
  @Output() ChoiceSelectionResponse = new EventEmitter<SelectionChoiceParameterResponse>();
  @Output() onSaveComplete = new EventEmitter<SelectionChoiceParameterResponse>();
  @Output() ChoiceSelectionAttachmentResponse = new EventEmitter<AttachmentResponse>();
  @Output() ChoiceSelectionCostItemResponse = new EventEmitter<CostItemParameterResponse>();
  @ViewChild('carouselContainer', { static: false }) carouselContainer!: ElementRef<HTMLDivElement>;
  @Input() newSeletChoiceId: number;
  @Input() CategorySendChoice: string
  @Input() LocationSendChoice: string
  @Input() SelectTitleSendChoice: string
  @Input() SelectIdAddChoice: number
  @Input() SelectIdByChoice: number

  selectedValue = null;
  inputValue?: string;
  currentIndex = 0;
  isSave: boolean = false;
  isLoading: boolean = false;
  showSiriDiv: boolean = true;
  showPriceDiv: boolean = false;
  selectionChoicevisible = true;
  isSubmitted: boolean = false;
  isSaveAndNew: boolean = false;
  LineItemsBUtton: boolean = false;
  FlateFeeBUtton: boolean = false;
  isInputDisabled: boolean = false;
  SubsVendorsForm: boolean = false;
  isSetPriceLater: boolean = false;
  isSaveAndClose: boolean = false;
  showNavButtons: boolean = false;
  rowAddItemGrid: boolean = false;
  isDeleteLoading: boolean = false;
  DisabledNextActivity: boolean = false;


  items: any[] = [];
  costItemRequest: any = {};
  CostItemFormFieldsValue: any = {};
  costItemGridFormFieldsvalues: any = {};
  choiceSelectionFormFieldValues: any = {};
  selectedFiless: CustomUploadFile[] = [];
  JobGroupNodes: NzTreeNodeOptions[] = [];
  leadOptions: CostCodeCategoryResponse[] = [];
  markupOption: LookupNameSetupResponse[] = [];
  changedFields: Set<string> = new Set<string>();
  validationStates: { [key: string]: NzStatus } = {};
  validationMessages: { [key: string]: string } = {};
  public mySelection: string[] = [];
  touchedFields: { [key: string]: boolean } = {};
  touchedFieldsItem: { [key: string]: boolean } = {};
  validationMessagesItem: { [key: string]: string } = {};
  validationStatesItem: { [key: string]: NzStatus } = {};
  vendersNodes: NzTreeNodeOptions[] = [];
  vendorList: SubVendorResponse[] = [];
  vendorHoldDateByChoice = new Date();
  selectedFilesAttachment: CustomUploadFile[] = [];
  TestAttachments: AttachmentParameterResponse[];

  IMG_BASE: string = environment.IMG_BUCKET_URL;
  SelectionStatusCode = SelectionStatusCode;

  TestAttachment: AttachmentResponse;
  public Editor = ClassicEditor;
  totalOwnerPrice: number = 0;
  totalBuilderCost: number = 0;
  estimatedProfit: number = 0;
  builderCostFeild: any = '0.00';
  ownerPriceFeild: any = '0.00';
  priceInformationradioValue = 'B';
  size: NzButtonGroupSize = 'small';
  currentSelection: string = 'lineItems';
  FileSetName: string;
  titlebySelection: string;
  categoryBySelection: string;
  locationBySelection: string;
  choiceGetbyIdSelectionCategory: string;
  choiceGetbyIdSelectionTitle: string;
  choiceGetbyIdSelectionLocation: string;
  choiceSelectionResID: number;
  choiceSelectionResGlobalId: string;
  choiceSelectionRes: SelectionChoiceParameterResponse;
  attachID: AttachmentResponse;
  choiceResponses: SelectionChoiceParameterResponse;

  constructor(
    private modal: NzModalService,
    private cd: ChangeDetectorRef,
    private changeDetectorRef: ChangeDetectorRef,
    private leadOppService: LeadOpportunitiesService,
    private LeadOpportunitiesService: LeadOpportunitiesService,
    private nzImageService: NzImageService,
    private toastService: NzMessageService,
    private selectionService: SelectionService,
    private LookupFilterService: LookupFilterService,
    private leadProposalsService: LeadProposalsService,
    private _CostItemService: CostItemService,
    private _AttachmentService: AttachmentService,
    public toastsService: NzMessageService,
    private subVendorsService: SubVendersService,
    private TimeClockSer: TimeClockService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {

    this.priceInformationradioButtons()
    this.choiceSelectionFormGroupFields();
    this.onGetCostType();
    this.getLeadData();
    this.fetchActivityResponse(this.choiceSelectionResID, false);
    this.subVendorsGetAll();
    this.selectionService.SelectionGetbyId(this.SelectIdByChoice)?.subscribe((res) => {
      console.log("API response:", res?.result);
      const resData = res?.result;
      const selectTitle = resData?.Title
      this.titlebySelection = selectTitle
      const selectCateg = resData?.CatagorySetupId?.toString();
      this.categoryBySelection = selectCateg
      const selectLocat = resData?.LocationSetupId?.toString();
      this.locationBySelection = selectLocat
    });
    //markup
    this.LookupFilterService.getLookUpValuesByFormNameId(37).subscribe(
      (res) => {
        let markupValues = res.result.filter((x) => x.code === this.preDefinedDataCodes.markup.code);
        this.markupOption = markupValues;
      });
  }

  statussetupColorMap = {
    [SelectionStatusCode.PendingNoChoice.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
    [SelectionStatusCode.ExpiredAvailable.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
    [SelectionStatusCode.UnReleased.code]: { backgroundColor: '#c7d0d9', textColor: '#202227' },
    [SelectionStatusCode.PriceTBD.code]: { backgroundColor: '#c7d0d9', textColor: '#202227' },
    [SelectionStatusCode.SubVendorPrice.code]: { backgroundColor: '#c7d0d9', textColor: '#202227' },
    [SelectionStatusCode.ExpiredNoChoices.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
  };
  preDefinedDataCodes = {
    costType: LeadProposalsPreDefinedCodes.CostType,
    markup: LeadProposalsPreDefinedCodes.Markup,
  };

  subVendorsGetAll() {
    this.subVendorsService.getData().subscribe(res => {
      this.vendorList = res.result.filter(data => data.automaticallyPermitAccessToNewJobs === true);
      this.vendersNodes = this.vendorList.map((data) => ({
        title: data.companyName,
        value: data.id.toString(),
        key: data.id.toString(),
        isLeaf: true,
      }));
    })
  }

  openWebsite() {
    let url = this.choiceSelectionFormFieldValues.productLink;
    if (url) {
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'http://' + url;
      }
      console.log('Opening URL:', url); // For debugging purposes
      window.open(url, '_blank');
    } else {
      this.toastService.error('Please enter a URL!');
    }
  }

  priceInformationradioButtons() {
    switch (this.priceInformationradioValue) {
      case 'A':
        this.LineItemsBUtton = false
        this.FlateFeeBUtton = true
        break;
      case 'B':
        this.FlateFeeBUtton = false
        this.LineItemsBUtton = true
        break;
      case 'C':
        this.FlateFeeBUtton = false
        this.LineItemsBUtton = false
        break;
    }
  }

  choiceSelectionFormGroupFields() {
    this.choiceSelectionFormFieldValues = {
      globalId: "00000000-0000-0000-0000-000000000000",
      selectionInformationId: this.SelectIdAddChoice,
      title: "",
      productLink: "",
      isInCludeInBudget: true,
      subVendorId: 0,
      installerId: 789,
      isShowLineItemToOwner: true,
      description: "",
      attachmentId: 0,
      costItemId: 0,
      statusId: 1819,
      imageUrl: "Sufyan Image URL",
      isApprove: true,
      isDecline: false,
      isRequestFromSubVendor: false,
      isSetPriceLater: false,
    };
  }

  async addChoiceSaveMechanism(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save'): Promise<void> {
    try {
      if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
        throw new Error('Already processing');
      }

      this.isSave = actionType === 'save';
      this.isSaveAndClose = actionType === 'saveAndClose';
      this.isSaveAndNew = actionType === 'saveAndNew';

      this.DisabledNextActivity = true;
      this.isSubmitted = true;
      this.checkErrors();

      if (this.rowAddItemGrid === true) {
        this.checkErrorsItem();
      }

      // Check for validation errors
      if (Object.values(this.validationStates).includes('error')) {
        setTimeout(() => {
          const errorElements = document.querySelectorAll('.error-message');
          if (errorElements.length > 0) {
            const lastErrorElement = errorElements[errorElements.length - 1];
            lastErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 100);

        this.resetFlags();
        this.DisabledNextActivity = false;
        throw new Error('Validation errors present');
      }

      // Process editor content
      let editorContent = this.choiceSelectionFormFieldValues.description;
      editorContent = editorContent.replace(/<br\s*\/?>/gi, '\n').replace(/&nbsp;/g, ' ');
      this.choiceSelectionFormFieldValues.description = editorContent.replace(/<[^>]+>/g, '');

      // First API call: post choice selection
      const res = await this.selectionService.PostChoice(this.choiceSelectionFormFieldValues).toPromise();
      this.choiceSelectionRes = res.result;
      this.toastService.success('Selection Choice Saved');

      // Update status based on conditions
      this.choiceSelectionFormFieldValues.statusId = 381;
      if (this.isSetPriceLater === true) {
        this.choiceSelectionFormFieldValues.statusId = 384;
        console.log("Price TBD Status Mark");
      }
      if (this.choiceSelectionRes?.IsRequestFromSubVendor === true) {
        this.choiceSelectionFormFieldValues.statusId = 386;
        console.log("Sub / vendor Needed");
      }

      this.vendorHoldDateByChoice = this.choiceSelectionRes?.RequestFromSubVendorOn;
      this.cdr.detectChanges();
      this.choiceSelectionResID = res.result.Id;
      this.choiceSelectionResGlobalId = res.result.GlobalId;

      // Prepare cost item data for the second API call
      const costTypeInformation = this.CostItemFormFieldsValue.costTypeItemParameters
        ? this.CostItemFormFieldsValue.costTypeItemParameters.map((id: string) => ({ costTypeId: id }))
        : [];

      const costItemRequest: CostItemResponse = {
        globalId: this.CostItemFormFieldsValue.globalId,
        headerId: res.result?.Id,
        companyParameterId: 1,
        formNameId: 50023,
        isFlatFee: this.FlateFeeBUtton,
        isLineItems: this.LineItemsBUtton,
        builderCost: this.builderCostFeild,
        ownerPrice: this.ownerPriceFeild,
        isSetPriceLater: this.isSetPriceLater,
        costItemParameters: this.items,
      };

      // Second API call: post cost item
      const costItemResponse = await this._CostItemService.postCostItem(costItemRequest).toPromise();
      console.log('Cost Item Response:', costItemResponse);

      this.isSubmitted = false;

      // Save attachments if any
      if (this.selectedFilesAttachment?.length > 0) {
        try {
          const attachmentResponseId = await saveAttachments(this.selectedFilesAttachment, this._AttachmentService, this.choiceSelectionResID, 50023);
          if (attachmentResponseId > 0) {
            this.fetchActivityResponse(this.choiceSelectionResID, false);
          }
        } catch (error) {
          console.error('Error saving attachments:', error);
          throw error;
        }
      } else {
        this.fetchActivityResponse(this.choiceSelectionResID, false);
        this.choiceSelectionFormFieldValues.attachmentId = 0;
      }

      // Add card and fetch activity response
      const newCard = {
        id: this.choiceSelectionResID,
        globalId: this.choiceSelectionResGlobalId,
        title: this.choiceSelectionFormFieldValues?.title,
        description: this.choiceSelectionFormFieldValues.description,
        ownerPrice: this.ownerPriceFeild,
        attachmentId: this.attachID,
      };
      this.selectionService.addCard(newCard);
      this.TimeClockSer.fetchActivityResponse(this.SelectIdAddChoice);

      await this.triggerFetchActivityAPI();
      this.selectionService.updateChoiceSelectionData(res.result);

      // Emit the saved choice selection response
      this.ChoiceSelectionResponse.emit(this.choiceSelectionRes);
      this.DisabledNextActivity = false;
      this.selectionChoicevisible = true;
      this.isSave = false;

    } catch (error) {
      console.error('Error saving data:', error);
      this.toastService.error(error instanceof Error ? error.message : 'An error has occurred. Please try again.');
      this.DisabledNextActivity = false;
      this.resetFlags();
      this.isSubmitted = false;
    }

  }

  private async triggerFetchActivityAPI(): Promise<void> {
    try {
      console.log('Triggering fetchActivityResponseChoice API...');
      await this.fetchActivityResponseChoice(this.SelectIdAddChoice); // Actual API call
      console.log('fetchActivityResponseChoice API successfully called');
    } catch (error) {
      console.error('Error in fetchActivityResponseChoice:', error);
    }
  }

  fetchActivityResponseChoice(id: number): void {
    this.selectionService.getChoiceBy(this.SelectIdAddChoice)?.subscribe((res) => {
      console.log('this.SelectIdAddChoicethis.SelectIdAddChoice', res?.result);
    });
  }

  onFilesUploaded(files: CustomUploadFile[]): void {
    this.selectedFilesAttachment = files
  }

  private fetchActivityResponse(_Id: number, quetionEdit: boolean): void {
    this.selectionService.getChoiceSelectionBy(this.newSeletChoiceId)?.subscribe((res) => {
      this.choiceGetbyIdSelectionTitle = this.titlebySelection
      console.log('choiceGetbyIdSelectionTitle', this.choiceGetbyIdSelectionTitle);
      this.choiceGetbyIdSelectionCategory = this.categoryBySelection
      this.choiceGetbyIdSelectionLocation = this.locationBySelection
      if (res && res.result) {
        console.log("API response:", res);
        this.choiceResponses = res.result as SelectionChoiceParameterResponse;
        this.NewpacthChoice(this.choiceResponses);
        // this.fetchAttachments();
        this.choiceSelectionFormFieldValues.patchValue(this.choiceSelectionFormFieldValues.value);
      } else {
        console.error('Invalid response from API:', res);
      }
    });
  }

  ConfirmDelete(): void {
    if (this.isDeleteLoading) {
      return;
    }
    this.modal.confirm({
      nzTitle: `Delete Selection?`,
      nzContent: 'Are you sure you want to permanently delete this Selection?',
      nzOkText: `Delete Selection `,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteChoice(this.choiceResponses?.GlobalId)
          .then(() => {
            this.selectionChoiceModelCancel();
          })
          .catch(() => {

          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }

  deleteChoice(globalId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!globalId) {
        this.toastsService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }
      // Ensure you are passing the correct GlobalId
      this.selectionService.deleteSelectionChoice(globalId).subscribe(
        (res) => {
          this.isDeleteLoading = false;
          this.toastsService.success(`User Deleted Choice!`);
          this.onSaveComplete.emit(this.choiceResponses);
          this.cdr.detectChanges();
          this.selectionChoiceModelCancel();
          resolve();  // Resolve the promise after success
        },
        (error) => {
          this.isDeleteLoading = false;
          this.toastsService.error('An error occurred while deleting the record. Please try again.');
          reject();  // Reject the promise on error
        }
      );
    });
  }

  saveAndCloseAddChoice(): Promise<void> {
    return this.addChoiceSaveMechanism('saveAndClose').then(() => {
      this.resetForm();
      this.selectionChoiceModelCancel();
    }).catch((error) => {
      console.error('Error during save and close:', error);
    });
  }

  saveAndNewAddChoice() {
    this.addChoiceSaveMechanism('saveAndNew').then(() => {
      this.resetForm();
    }).catch((error) => {
      console.error('Error during save and new:', error);
    }).finally(() => {
    });
  }

  resetFlags() {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
    this.cd.detectChanges();
  }

  resetForm(): void {
    this.choiceSelectionFormFieldValues = {}
  }

  NewpacthChoice(choiceResponses: SelectionChoiceParameterResponse) {
    if (!choiceResponses) {
      console.error('No selection response data available for patching.');
      return;
    }
    const DataSet = choiceResponses;
    this.choiceSelectionFormFieldValues = {
      ...this.choiceSelectionFormFieldValues,
      selectionInformationId: DataSet?.SelectionInformationId,
      globalId: DataSet?.GlobalId || '00000000-0000-0000-0000-000000000000',
      title: DataSet?.Title,
      productLink: DataSet?.ProductLink,
      isInCludeInBudget: DataSet?.IsInCludeInBudget,
      subVendorId: DataSet?.SubVendorId,
      installerId: DataSet?.InstallerId,
      isShowLineItemToOwner: DataSet?.IsShowLineItemToOwner,
      description: DataSet?.Description,
      attachmentId: DataSet?.AttachmentId,
      costItemId: DataSet?.CostItemId,
      isFlatFee: DataSet?.IsFlatFee,
      isLineItem: DataSet?.IsLineItem,
      builderCost: DataSet?.BuilderCost,
      ownerPrice: DataSet?.OwnerPrice,
      isSetPriceLater: DataSet?.IsSetPriceLater,
      statusId: DataSet?.StatusId,
      imageUrl: DataSet?.ImageUrl,
      isApprove: DataSet?.IsApprove,
      isDecline: DataSet?.IsDecline
    };
    this.fetchAttachments(DataSet?.AttachmentId);
  }

  saveFileIdToDatabase(fileId: string) {
  }

  fetchAttachments(AttachmentId: number) {
    if (AttachmentId) {
      this._AttachmentService.getLeadByAttachmentId(AttachmentId).subscribe(
        (res) => {
          this.TestAttachment = res.result
          this.TestAttachments = res.result?.attachmentParameters
          const setFilePath = this.IMG_BASE + res.result?.attachmentParameters?.map(x => x.filePath)
          this.FileSetName = setFilePath
          console.log('Sufiyan Show Responce okay', this.FileSetName);
        },
      );
    } else {
      this.TestAttachment = undefined;
    }
  }

  public config = {
    height: '400px',
    toolbar: {
      items: [
        'undo', 'redo', '|',
        'heading', '|', 'bold', 'italic', '|',
        'link', 'insertTable', 'mediaEmbed', '|',
        'bulletedList', 'numberedList', 'indent', 'outdent', '|',
        'fontSize', 'fontFamily', 'wproofreader', 'fontBackgroundColor', 'fontColor', 'blockQuote',
        'image', 'horizontalLine', 'undoEditing'
      ],
    },
    plugins: [
      Bold,
      Essentials,
      Heading,
      Indent,
      IndentBlock,
      Italic,
      Link,
      List,
      MediaEmbed,
      Paragraph,
      Table,
      Undo,
      Font,
      FontSize,
      FontFamily,
      WProofreader,
      FontBackgroundColor,
      FontColor,
      BlockQuote,
      Image,
      HorizontalLine,
      UndoEditing,
      ImageUpload,
    ],
    fontSize: {
      options: [
        9, 11, 13, 'default', 17, 19, 21, 25, 28, 32, 36, 40, 44, 48, 52, 56, 60
      ]
    },
    fontFamily: {
      options: [
        'default', 'Arial, Helvetica, sans-serif', 'Courier New, Courier, monospace',
        'Georgia, serif', 'Times New Roman, Times, serif', 'Verdana, Geneva, sans-serif'
      ]
    },
    wproofreader: {
      lang: 'en_US',
      serviceId: 'YRY4vsPN7HIGo9kl',
      srcUrl: 'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js'
    }
  };

  selectionChoiceModelCancel(): void {
    this.cancel.emit();
  }

  onVendorSelection(selectedVendorId: any): void {
    if (selectedVendorId) {
      // Vendor selected, show the appropriate modal
      this.showVendorSelectedModal();
    } else {
      this.showVandorsConfirm();
    }
  }

  handleVendorAction(): void {
    if (this.choiceSelectionFormFieldValues.subVendorId) {
      // No vendor selected, show the initial modal
      this.showVendorSelectedModal();
    } else {
      this.showVandorsConfirm();
    }
  }

  showVandorsConfirm(): void {
    this.modal.info({
      nzTitle: '',
      nzContent: '<p>Please assign a Sub/Vendor to this Choice before requesting a price.</p>',
      nzOnOk: () => console.log('Info OK'),
      nzClassName: 'ant-modal-confirm-custom',
      nzBodyStyle: { 'border-radius': '30px', 'height': 'auto', 'padding-top': '15px' },
      nzStyle: { top: '200px' },
      nzCloseIcon: '',
      nzIconType: '',
    });
  }

  // Show modal when a vendor is selected
  showVendorSelectedModal(): void {
    this.modal.confirm({
      nzTitle: 'Switch to Request From Sub/Vendor',
      nzContent: 'All line items will be removed permanently upon Save.',
      nzOkText: 'Request From Sub/Vendors',
      nzOnOk: () => {
        console.log('Vendor selected confirmed');
        this.choiceSelectionFormFieldValues.isRequestFromSubVendor = true; // Disable the input field
      },
      nzCancelText: 'Cancel',
      nzOnCancel: () => console.log('Cancel'),
      nzClassName: 'ant-modal-confirm-custom ,ant-modal-body',
      nzBodyStyle: { 'border-radius': '30px', 'height': 'auto', 'padding-top': '15px' },
      nzStyle: { top: '200px' },
      nzCloseIcon: '',
      nzIconType: '',
    });
  }

  // Function to show the delete confirmation modal
  showDeleteConfirm(): void {
    this.modal.confirm({
      nzTitle: 'Switch to Flat Fee?',
      nzContent: 'All line items will be removed permanently upon Save.',
      nzOkText: 'Use Flat Fee',
      nzOkType: 'primary',
      nzOnOk: () => this.handleFlatFeeSelection(),
      nzCancelText: 'Cancel',
      nzOnCancel: () => console.log('Cancel'),
      nzClassName: 'ant-modal-confirm-custom',
      nzBodyStyle: { 'border-radius': '30px', 'height': 'auto', 'padding-top': '15px' },
      nzStyle: { top: '250px' },
      nzCloseIcon: '',
      nzIconType: '',
    });
  }

  resetVendorSelectedModal(): void {
    this.modal.confirm({
      nzTitle: '',
      nzContent: 'Are you sure you want to reset the Builder Price request?',
      nzOkText: 'Reset',
      nzOnOk: () => {
        console.log('OKay reset');
        // Trigger the API call
        this.choiceSelectionFormFieldValues.isRequestFromSubVendor = false;
        this.selectionService.PostChoice(this.choiceSelectionFormFieldValues).subscribe(res => {
          this.choiceSelectionRes = res.result;
          this.cdr.detectChanges();
          this.priceInformationradioValue = 'A';
          if (this.choiceSelectionFormFieldValues.isRequestFromSubVendor === false) {
            this.choiceResponses.IsRequestFromSubVendor = false;
          }
        });
      },
      nzCancelText: 'Cancel',
      nzOnCancel: () => console.log('Cancel'),
      nzClassName: 'ant-modal-confirm ,ant-modal-body',
      nzBodyStyle: { 'border-radius': '30px', 'height': 'auto', 'padding-top': '15px' },
      nzStyle: { top: '200px' },
      nzCloseIcon: '',
      nzIconType: '',
    });
  }

  handleFlatFeeSelection(): void {
    this.showSiriDiv = false;
    this.showPriceDiv = true;
    this.currentSelection = 'flatFee';
  }
  handleLineItemsSelection(): void {
    this.showSiriDiv = true;
    this.showPriceDiv = false;
    this.currentSelection = 'lineItems';
  }
  uploadFiles(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.UploadFile.subscribe((data: CustomUploadFile[]) => {
      data.filter(file => this.isImageFile(file));
      this.selectedFiless = [...data, ...this.selectedFiless];
      this.onResize();
    });
  }

  isImageFile(file: CustomUploadFile): boolean {
    const fileName = file.name.toLowerCase();
    const fileType = file.type;

    return (
      fileType === 'image/png' ||
      fileType === 'image/jpeg' ||
      fileName.endsWith('.png') ||
      fileName.endsWith('.jpeg')
    );
  }

  onResize() {
    this.updateNavButtonsVisibility();
  }

  private updateNavButtonsVisibility() {
    requestAnimationFrame(() => {
      const container = this.carouselContainer.nativeElement;
      if (this.selectedFiless.length > 0) {
        this.showNavButtons = container.scrollWidth > container.clientWidth;
      } else {
        this.showNavButtons = false;
      }
      this.cd.detectChanges();
    });
  }

  viewAll(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.selectedFilessResponse = this.selectedFiless;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.removeFile.subscribe((file: CustomUploadFile) => {
      this.removeFile(file);
    });
    modalRef.componentInstance.Test.subscribe((data: CustomUploadFile[]) => {
      this.selectedFiless = [...data, ...this.selectedFiless];
      this.onResize();
    });
  }

  removeFile(file: CustomUploadFile): void {
    this.selectedFiless = this.selectedFiless.filter(item => item.uid !== file.uid);
  }

  previous() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.scrollToCurrentIndex();
    }
  }

  next() {
    const container = this.carouselContainer.nativeElement;
    const maxIndex = this.selectedFiless.length - Math.floor(container.clientWidth / 130);
    if (this.currentIndex < maxIndex) {
      this.currentIndex++;
      this.scrollToCurrentIndex();
    }
  }

  scrollToCurrentIndex() {
    const container = this.carouselContainer.nativeElement;
    const scrollAmount = this.currentIndex * 130; // 120px (width) + 10px (margin)
    container.scrollTo({ left: scrollAmount, behavior: 'smooth' });
  }

  getFileIcon(file: CustomUploadFile): string | null {
    const fileType = file.type;
    if (fileType.includes('pdf')) {
      return 'assets/Attachment/documentPDF.svg';
    } else if (fileType.includes('excel') || fileType.includes('spreadsheet')) {
      return 'assets/Attachment/documentXLS.svg';
    } else if (fileType.includes('word')) {
      return 'assets/Attachment/documentDOC.svg';
    } else if (fileType.includes('zip')) {
      return 'assets/Attachment/documentZIP.svg';
    } else if (fileType.includes('text') || fileType.includes('json')) {
      return 'assets/Attachment/documentGeneric.svg';
    } else if (fileType.includes('ppt') || fileType.includes('presentation')) {
      return 'assets/Attachment/documentPPT.svg';
    } else if (fileType.includes('video')) {
      return null;
    } else if (fileType.includes('image')) {
      return null;
    } else {
      return null;
    }
  }

  onClick(): void {
    let filteredImages = this.selectedFiless.filter(f =>
      f.name.includes('png') || f.name.includes('jpeg')
    );
    const images = filteredImages.map(file => ({
      src: file.thumbUrl,
      alt: file.name
    }));
    this.nzImageService.preview(images, {
      nzZoom: 1.0,
      nzRotate: 0
    });
  }

  playVideo(file: CustomUploadFile): void {
    const videoElement: HTMLVideoElement = this.carouselContainer.nativeElement.querySelector(`#video-${file.id}`);
    if (videoElement) {
      videoElement.style.display = 'block'; // Show the video player
      videoElement.play();
    }
  }

  uploadFilesUpdate(fileq: CustomUploadFile): void {
    const modalRef = this.modal.create({
      nzContent: AttachmentAnnotationComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.DrawerImge = fileq;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.imageDataRes.subscribe((data: any) => {
      const newImageUrl = data;
      let test1234 = this.selectedFiless.forEach(activity => {
        activity.thumbUrl = newImageUrl;
      });
      console.log(test1234);
    });
  }
  // grid working
 
  listOfData: Person[] = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park'
    },
  ];

  getLeadData() {
    this.leadProposalsService.getAllLeadData().subscribe(data => {
      this.leadOptions = data.result;
    });
  }

  onGetCostType() {
    this.LookupFilterService.getLookUpValuesByFormNameId(37).subscribe(
      (res) => {
        let JobGroupValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.costType.code
        );
        let markupValues = res.result.filter((x) => x.code === this.preDefinedDataCodes.markup.code);
        this.markupOption = markupValues;
        // this.items.map(mk => mk.markup) = this.markupOption[0].id
        // console.log(this.items.markup,this.items.markup);
        this.JobGroupNodes = [
          {
            title: 'Check All',
            value: 'check_all',
            key: 'check_all',
            selectable: false,
            isLeaf: false,
            expanded: true,
            children: JobGroupValues.map((JobGroup) => ({
              title: JobGroup.name,
              key: JobGroup.id.toString(),
              value: JobGroup.id.toString(),
              isLeaf: true,
            }))
          }
        ];
      });
  }

  deleteRow(index: number): void {
    this.modal.confirm({
      nzTitle: `Delete Line Item?`,
      nzContent: `You have unsaved changes, do you want to save your changes before closing?`,
      nzOkText: `Delete`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        return this.deleteItem(index)
      },
      nzCentered: true,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }

  deleteItem(index: number) {
    this.items.splice(index, 1);
  }

  addNewRow(): void {
    this.rowAddItemGrid = true;
    this.CostItemFormFieldsValue = {
      globalId: '00000000-0000-0000-0000-000000000000',
      title: '',
      description: '',
      internalNotes: '',
      quantity: 0,
      unit: 0,
      unitCost: 0,
      builderCost: 0,
      markupValue: 0,
      markupId: 0,
      ownerPrice: 0,
      margin: 0,
      profit: 0,
      costCodeId: 0,
      costTypeId: 0,
      markAsId: 0,
      costTypeItemParameters: []
    };
    console.log('this.CostItemFormFieldsValueewqeqwe', this.CostItemFormFieldsValue);
    this.items.push(this.CostItemFormFieldsValue);
  }

  updateBuilderCost(item: any): void {
    const unitCost = parseFloat(item.unitCost) || 0;
    const quantity = item.quantity || 1;
    const markupValue = parseFloat(item.markupValue) || 0;

    item.builderCost = unitCost * quantity;

    if (item.markupId === 40417) {
      item.ownerPrice = item.builderCost + (item.builderCost * (markupValue / 100));
    } else if (item.markupId === 40418 || item.markupId === 40419) {
      item.ownerPrice = item.builderCost + markupValue;
    } else {
      item.ownerPrice = item.builderCost;
    }

    item.margin = item.ownerPrice > 0
      ? ((item.ownerPrice - item.builderCost) / item.ownerPrice) * 100
      : 0;

    item.margin = parseFloat(item.margin.toFixed(2));

    item.profit = item.ownerPrice - item.builderCost;
    if (item.profit < 0) {
      item.profit = 0;
    }

    this.updateTotals();
  }
  updateTotals(): void {
    this.totalOwnerPrice = this.items.reduce((sum, item) => sum + item.ownerPrice, 0);
    this.totalBuilderCost = this.items.reduce((sum, item) => sum + item.builderCost, 0);
    this.estimatedProfit = this.items.reduce((sum, item) => sum + item.profit, 0);
  }

  readonly validationRules = {
    title: {
      required: 'Required',
      maxLength: { limit: 50, message: 'Title' }
    },
    isLineItems: {
      message: 'Line Items field must have at least 1 items',
    },
  };

  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      title: 'Title',
      isLineItems: 'Line Items',
      isFlatFee: '',
    };
    return labels[field] || field;
  }

  getErrorTip(field: string): string {
    const rules = this.validationRules[field];
    const input = this.choiceSelectionFormFieldValues[field];
    const inputValue = typeof input === 'string' ? input.trim() : '';

    if (rules.required && !input) {
      return 'Required';
    }

    if (rules.maxLength && inputValue.length > rules.maxLength.limit) {
      const excessLength = inputValue.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }
    if (field === 'isLineItems') {
      return rules?.message;
    }

    return '';
  }

  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }

  checkErrors() {
    this.validationStates = {};
    this.validationMessages = {};

    for (let field in this.validationRules) {
      if (this.validationRules.hasOwnProperty(field)) {
        if (this.touchedFields[field] || this.isSubmitted) {
          const errorTip = this.getErrorTip(field);
          if (errorTip) {
            this.validationStates[field] = 'error';
            this.validationMessages[field] = errorTip;
          } else {
            this.validationStates[field] = null;
            this.validationMessages[field] = '';
          }
        }
      }
    }
    this.onErrorNull();
  }

  onErrorNull() {
    if (this.priceInformationradioValue === 'A' || this.priceInformationradioValue === 'C' || this.rowAddItemGrid === true) {
      delete this.validationStates['isLineItems'];
      delete this.validationMessages['isLineItems'];
    }
  }

  onFieldChange(field: string, value: any) {
    if (typeof value === 'string') {
      this.choiceSelectionFormFieldValues[field] = value.trim();
    } else {
      this.choiceSelectionFormFieldValues[field] = value;
    }
    this.touchedFields[field] = true;
    this.checkErrors();
    if (this.rowAddItemGrid === true) {
      this.checkErrorsItem();
    }
  }

  readonly validationRulesItem = {
    title: {
      maxLength: { limit: 10, message: 'Title' }
    },
    costCodeId: {
      required: 'Required',
      maxLength: { message: 'Cost Code' }
    },
  };

  getFieldLabelItem(field: string): string {
    const labels: { [key: string]: string } = {
      costCodeId: 'Cost Code',
      title: 'Title',
    };
    return labels[field] || field;
  }

  getErrorTipItem(field: string, index: number): string {
    const rules = this.validationRulesItem[field];
    const input = this.items[index][field];
    const inputValue = typeof input === 'string' ? input.trim() : '';

    if (rules.required && !input) {
      return 'Required';
    }

    if (rules.maxLength && inputValue.length > rules.maxLength.limit) {
      const excessLength = inputValue.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }

    return '';
  }

  get errorFieldsItem(): { label: string; message: string }[] {
    return Object.keys(this.validationStatesItem)
      .filter(field => this.validationStatesItem[field] === 'error')
      .map(field => ({
        label: '',
        message: this.validationMessagesItem[field]
      }));
  }

  checkErrorsItem() {
    this.validationStatesItem = {};
    this.validationMessagesItem = {};

    this.items.forEach((item, index) => {
      for (let field in this.validationRulesItem) {
        if (this.validationRulesItem.hasOwnProperty(field)) {
          if (this.touchedFieldsItem[field + '_' + index] || this.isSubmitted) {
            const errorTip = this.getErrorTipItem(field, index);
            if (errorTip) {
              this.validationStatesItem[field + '_' + index] = 'error';
              this.validationMessagesItem[field + '_' + index] = `Cost Code ${index + 1}: ${errorTip}`;
            }
          }
        }
      }
    });
  }

  onFieldChangeItem(field: string, value: any, index: number) {
    if (typeof value === 'string') {
      this.items[index][field] = value.trim();
    } else {
      this.items[index][field] = value;
    }

    this.touchedFieldsItem[field + '_' + index] = true;
    this.checkErrorsItem();
  }
}
