<div *ngIf="filterResponseData && filterResponseData.length > 0">
    <form (ngSubmit)="onFilterApply()">
      <div class="row mt-2">
        <div class="col-10 p-0">
          <div class="input-group">
           <nz-select
            [(ngModel)]="formFieldValues['standardFilter']"
            name="standardFilter"
            (ngModelChange)="onChangeStandardFilter($event)"
            nzShowSearch
            [nzAutoFocus]="true"
            nzPlaceHolder="Standard Filter"
            r>
            <nz-option *ngFor="let filter of filterResponseData"
             [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')"
             [nzValue]="filter.id"
             nzCustomContent>
             <div class="option-content">
              <!-- Customize content with icons and text -->
              <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
              <span>{{ filter.name }}</span>
             <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

             </div>
            </nz-option>
           </nz-select>
          </div>
         </div>
        <div class="col-1 mt-1">
          <i class="bi bi-three-dots"
           nz-popover
          [nzPopoverContent]="contentTemplate"
          [nzPopoverPlacement]="'top'"
          (click)="openStandardFilterComponent()"></i>
        </div>
      </div>





      <!-- <div class="row  d-flex justify-content-between align-items-center">
        <div class="col-10 p-0 ">
          <div class="input-group">
            <nz-select >
              <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
              <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
            </nz-select>
          </div>
        </div>
        <div class="col-2 text-end p-0">
          <button nz-button class="border-0">
            <span nz-popover class="text-primary text-dark fw-bold fs-5" nz-icon nzType="ellipsis"
              nzTheme="outline"></span>
          </button>
        </div>
      </div> -->

      <div class="row famly-inn">
        <div class="col-12">
          <div class="row">
            <div class="col p-0">
              <label class="sub-text">Title</label>
              <input   [(ngModel)]="formFieldValues['title']" name="title" type="text" class="form-control formField">
            </div>
          </div>
          <div class="row">
            <div class="col mt-1 p-0">
              <label class="sub-text">Selection Status</label>
      <!-- selecation status -->
            <nz-tree-select
             [(ngModel)]="formFieldValues['selectionStatus']"
             name="selectionStatus"
             style="width: 100%"
             [nzNodes]="SelectionStatusNodes"
             nzShowSearch
             nzAllowClear="false"
             nzCheckable
             (ngModelChange)="onselectionStatusChange($event)"
             nzPlaceHolder="Select selectionStatus"
             nzDropdownClassName="dropdownStyle"
            >
            </nz-tree-select>
            </div>
          </div>
          <div class="row">
            <div class="col mt-1 p-0">
              <label class="sub-text">Subs/Vendors</label>
              <nz-tree-select
              [(ngModel)]="formFieldValues['subsvendors']"
              name="subsvendors"
              style="width: 100%"
              [nzNodes]="SubvendorNode"
              nzShowSearch
              nzAllowClear="false"
              nzCheckable
              (ngModelChange)="OnVendorChange($event)"
              nzPlaceHolder="Select Subs-vendors"
              nzDropdownClassName="dropdownStyle"
            >
            </nz-tree-select>
            </div>
          </div>
          <div class="row">
            <div class="col mt-1 p-0">
              <label class="sub-text">Locations</label>

            <!-- locations -->
            <nz-tree-select
             [(ngModel)]="formFieldValues['locations']"
             name="locations"
             style="width: 100%"
             [nzNodes]="LocationsNodes"
             nzShowSearch
             nzAllowClear="false"
             nzCheckable
             (ngModelChange)="onlocationsChange($event)"
             nzPlaceHolder="Select locations"
             nzDropdownClassName="dropdownStyle"
            >
            </nz-tree-select>
            </div>
          </div>
          <div class="row">
            <div class="col mt-1 p-0">
              <label class="sub-text">Categories</label>

            <!-- categories -->
            <nz-tree-select
             [(ngModel)]="formFieldValues['categories']"
             name="categories"
             style="width: 100%"
             [nzNodes]="CategoriesNodes"
             nzShowSearch
             nzAllowClear="false"
             nzCheckable
             (ngModelChange)="oncategoriesChange($event)"
             nzPlaceHolder="Select categories"
             nzDropdownClassName="dropdownStyle"
            >
            </nz-tree-select>
            </div>
          </div>
          <div class="row">
            <div class="col mt-1 p-0">
              <label class="sub-text">Deadline</label>
              <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['deadline']" name="deadline">
                <nz-option *ngFor="let option of deadlineOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
              </nz-select>
            </div>
          </div>
          <div class="row">
            <label class="sub-text p-0">
              Show Unavailable Choices
            </label>
            <div class="col-12 p-0 mt-1">
              <label  [(ngModel)]="formFieldValues['showUnavailableChoices']" name="showUnavailableChoices" nz-checkbox></label>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 p-0 text-end ">
          <button nz-button nzType="primary">Clear All</button>
          <button class="ms-1" nz-button nzType="default">Apply </button>
        </div>
      </div>
    </form>
  </div>




<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>
