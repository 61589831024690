<nz-modal nzWidth="55%" [(nzVisible)]="unvisible" [nzTitle]="UploadFiles1" [nzFooter]="UploadFiles2" (nzOnCancel)="close()" [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '0', 'max-height': '70vh', 'overflow-y': 'auto'}" [nzStyle]="{ top: '58px' }">
    <ng-template #UploadFiles1>
        <div class="row">
            <h2 class="p-0 m-0 react">Photo Draw</h2>
        </div>
    </ng-template>
    <div class="content" *nzModalContent>
        <div class="card border-0">
            <div class="card-head mt-2 ms-2">
                <div nz-row>
                    <div nz-col nzSpan="3">
                        <button nz-button nzType="primary" class="blueButton" (click)="onSave()">Save</button>
                    </div>
                    <div nz-col nzSpan="3">
                        <div class="clear-all-container" (click)="onDraw()">
                            <span (click)="onDraw()" class='bx bxs-pencil'></span>
                            <span (click)="onDraw()" class="react">Draw</span>
                        </div>
                    </div>
                    <div nz-col nzSpan="4">
                        <div class="clear-all-container">
                            <span nz-popover nzPopoverPlacement="bottomLeft" [nzPopoverContent]="eventTemplate1" nzPopoverTrigger="click" (click)="onHighlight()" class='bx bx-highlight'></span>
                            <span nz-popover nzPopoverPlacement="bottomLeft" [nzPopoverContent]="eventTemplate1" nzPopoverTrigger="click" (click)="onHighlight()" class="react">Highlight</span>
                        </div>
                    </div>
                    <div nz-col nzSpan="3">
                        <div class="clear-all-container">
                            <span (click)="undo()" class='bx bx-undo'></span>
                            <span (click)="undo()" class="react">Undo</span>
                        </div>
                    </div>
                    <div nz-col nzSpan="3">
                        <div class="clear-all-container">
                            <span disabled (click)="redo()" class='bx bx-redo'></span>
                            <span disabled (click)="redo()" class="react">Redo</span>
                        </div>
                    </div>
                    <div nz-col nzSpan="4">
                        <div class="clear-all-container" (click)="clearAll()">
                            <span class='bx bxs-x-circle'></span>
                            <span class="react">Clear all</span>
                        </div>
                    </div>
                </div>
                <ng-template #eventTemplate1>
                <div style="width: 450px; height: 130px; padding: 5px 0px 10px 0px">
                    <div nz-row class="nav-top-icon" style="padding: 0px 0px 15px 25px">
                        <div nz-col nzSpan="4" (click)="selectBrushSize(2)" [ngClass]="{'active-size': brushSize === 2}">
                            <span style="border-width: 3px" class="ant-divider-vertical"></span>
                            <span class="text-size">2px</span>
                        </div>
                        <div nz-col nzSpan="5" (click)="selectBrushSize(4)" [ngClass]="{'active-size': brushSize === 4}">
                            <span style="border-width: 4px" class="ant-divider-vertical"></span>
                            <span class="text-size">4px</span>
                        </div>
                        <div nz-col nzSpan="5" (click)="selectBrushSize(6)" [ngClass]="{'active-size': brushSize === 6}">
                            <span style="border-width: 5px" class="ant-divider-vertical"></span>
                            <span class="text-size">6px</span>
                        </div>
                        <div nz-col nzSpan="5" (click)="selectBrushSize(10)" [ngClass]="{'active-size': brushSize === 10}">
                            <span style="border-width: 7px" class="ant-divider-vertical"></span>
                            <span class="text-size">10px</span>
                        </div>
                        <div nz-col nzSpan="5" (click)="selectBrushSize(16)" [ngClass]="{'active-size': brushSize === 16}">
                            <span style="border-width: 8px" class="ant-divider-vertical"></span>
                            <span class="text-size">16px</span>
                        </div>
                    </div>
                    <span class="divider-subtoolbar"></span>
                    <div nz-row class="nav-top-icon" style="padding: 0px 0px 15px 25px">
                        <div nz-col nzSpan="4" (click)="selectColor('rgb(255, 0, 0)')" [ngClass]="{'active-color': drawColor === 'rgb(255, 0, 0)'}">
                            <div class="Draw-container">
                                <span style="font-size: 2em;color: rgb(255, 0, 0);" class='bx bxs-circle'></span>
                                <span class="react">Red</span>
                            </div>
                        </div>
                        <div nz-col nzSpan="5" (click)="selectColor('#578FFF')" [ngClass]="{'active-color': drawColor === '#578FFF'}">
                            <div class="Draw-container">
                                <span style="font-size: 2em;color: #578FFF;" class='bx bxs-circle'></span>
                                <span class="react">Blue</span>
                            </div>
                        </div>
                        <div nz-col nzSpan="5" (click)="selectColor('#70cc70')" [ngClass]="{'active-color': drawColor === '#70cc70'}">
                            <div class="Draw-container">
                                <span style="font-size: 2em;color: #70cc70;" class='bx bxs-circle'></span>
                                <span class="react">Green</span>
                            </div>
                        </div>
                        <div nz-col nzSpan="5" (click)="selectColor('#000000')" [ngClass]="{'active-color': drawColor === '#000000'}">
                            <div class="Draw-container">
                                <span style="font-size: 2em;color: #000000;" class='bx bxs-circle'></span>
                                <span class="react">Black</span>
                            </div>
                        </div>
                        <div nz-col nzSpan="5" (click)="selectColor('#FFC300')" [ngClass]="{'active-color': drawColor === '#FFC300'}">
                            <div class="Draw-container">
                                <span style="font-size: 2em;color: #FFC300;" class='bx bxs-circle'></span>
                                <span class="react">Yellow</span>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-template>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12" style="display: flex;align-items: center;justify-content: center;">
                        <div *ngIf="DrawerImge" class="image-container">
                            <img #image [src]="DrawerImge.thumbUrl" />
                            <canvas #canvas class="drawing-canvas"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #UploadFiles2></ng-template>
</nz-modal>
