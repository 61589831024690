import { Component } from '@angular/core';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';


@Component({
  selector: 'filter-side-menu-bar',
  templateUrl: './filter-side-menu-bar.component.html',
  styleUrls: ['./filter-side-menu-bar.component.css']
})
export class FilterSideMenuBarComponent {

  PAGE_ID: number = 0;
  INTERNAL_USER: string = FiltersName.INTERNAL_USER;
  SUB_VENDOR: string = FiltersName.SUB_VENDOR;
  LEAD_OPPR_ACT_TEMPL: string = FiltersName.LEAD_OPPR_ACT_TEMPL;
  LEAD_OPPR_ACT_VIEW: string = FiltersName.LEAD_OPPR_ACT_VIEW;
  LEAD_OPPR_LEAD_PROP: string = FiltersName.LEAD_OPPR_LEAD_PROP;
  LEAD_OPPR_LIST_VIEW: string = FiltersName.LEAD_OPPR_LIST_VIEW;
  LEAD_OPPR_MAP: string = FiltersName.LEAD_OPPR_MAP;
  LEAD_OPPR_PROP_TEMP: string = FiltersName.LEAD_OPPR_PROP_TEMP;
  PROJECT_MANG_SELEC_CARD_V: string = FiltersName.PROJECT_MANG_SELEC_CARD_V;
  PROJECT_MANG_SELEC_LIST: string = FiltersName.PROJECT_MANG_SELEC_LIST;
  PROJECT_MANG_SELEC_HIERARCHY: string = FiltersName.PROJECT_MANG_SELEC_HIERARCHY;
  PROJECT_MANG_SELEC_ALLOWA_ASSIGN: string = FiltersName.PROJECT_MANG_SELEC_ALLOWA_ASSIGN;
  PROJECT_MANG_SELEC_ALLOWA_UNASSIGN: string = FiltersName.PROJECT_MANG_SELEC_ALLOWA_UNASSIGN;
  PROJECT_MANG_WARRANTY: string = FiltersName.PROJECT_MANG_WARRANTY;
  PROJECT_MANG_TIMECLOCK: string = FiltersName.PROJECT_MANG_TIMECLOCK;
  PROJECT_M_ANG_SITEDIAR_DAILY_L: string = FiltersName.PROJECT_MANG_SITE_DIAR_DAILY_L;
  PROJECT_MANG_VARIATION: string = FiltersName.PROJECT_MANG_VARIATION;
  PROJECT_MANG_SCHED_BASELINE: string = FiltersName.PROJECT_MANG_SCHED_BASELINE;
  PROJECT_MANG_SCHEDULE: string = FiltersName.PROJECT_MANG_SCHEDULE;
  CUSTOMER_CONTACT: string = FiltersName.CUSTOMER_CONTACT;
  COMMENT_V_BY_CONVERSATION: string = FiltersName.COMMENT_V_BY_CONVERSATION;
  MESSAGES: string = FiltersName.MESSAGES;
  JOBS_JOBS_LIST: string = FiltersName.JOBS_JOBS_LIST;
  Financial_Tenders: string = FiltersName.Financial_Tenders;
  Financial_Bills: string = FiltersName.Financial_Bills;
  Financial_PurchaseOrder: string = FiltersName.Purchase_Order;
  Cost_Inbox: string = FiltersName.Cost_Inbox;
  Owner_Invoice: string = FiltersName.Owner_Invoice;
  LEAD_OPPR_ACT_CAL: string = FiltersName.LEAD_OPPR_ACT_CAL;
  // Messaging_Servey: string = FiltersName.Messaging_Servey;
  Surveys_Individual: string = FiltersName.Surveys_Individual;
  PROJECT_MANG_Todos: string = FiltersName.PROJECT_MANG_Todos;
  Project_Man_WorkDay_Excep: string = FiltersName.Project_Man_WorkDay_Excep;
  Job_Summary: string = FiltersName.Job_Summary;
  Messaging_RFIs: string = FiltersName.Messaging_RFIs;
  Messaging_Notification_History: string = FiltersName.Messaging_Notification_History;

  drawerTitle = 'Filter';
  filterVisible: boolean = false;
  filterComponent: string;
  activeFilter: string = '';
  pageId: number = 0;
  constructor(private filterService: FilterSideMenuBarService) { }

  ngOnInit() {
    this.filterService.getFilterVisibility().subscribe((visible) => {
      this.filterVisible = visible;
    });

    this.filterService.getActiveFilter().subscribe((filter) => {
      this.activeFilter = filter;
    });

    this.filterService.getPageId().subscribe((id) => {
      this.pageId = id;
    });
  }

  close() {
    this.filterService.toggleFilter(this.filterComponent, this.pageId);
  }

}
