import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-purchase-order-model',
  templateUrl: './purchase-order-model.component.html',
  styleUrls: ['./purchase-order-model.component.css']
})
export class PurchaseOrderModelComponent  {

  constructor(private fb: FormBuilder,){}
  checked: any;
  purchaseModel: FormGroup;
  
  autoAdjustTextAreaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '54'; 
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; 
  }
  initializeForm(): void {
    this.purchaseModel = this.fb.group({
      purchaseOrder: [null],
      title: [null],
      assignedTo: [null],
      materialsOnly: [null],
      scheduleItem: [null],
      complenationDate: [null],
      markAsVariance: [null],
      internalNotes: [null],
      
  // Grid Baqi Ha 
    });
  }
  
      date = null;
    onChange(result: Date): void {
      console.log('onChange: ', result);
    }

    @Output() cancel = new EventEmitter<void>();
  PurchaseOrderVisible=true;

  PurchaseCancel(): void {
  this.cancel.emit();
}

}

function getISOWeek(result: Date): any {
  throw new Error('Function not implemented.');
}

  
