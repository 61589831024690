import { LookupFilterResponse } from 'src/app/shared/component/Models/LookupModels';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { finalize } from 'rxjs';
import { CustomFieldService } from 'src/Service/CompanyInFormation/Selections/custom-field.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';

@Component({
  selector: 'app-add-new-standard-filter',
  templateUrl: './add-new-standard-filter.component.html',
  styleUrls: ['./add-new-standard-filter.component.css']
})
export class AddNewStandardFilterComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  index: number;
  @Input() field: any;
  isLoading: boolean = false;
  confirmModal?: NzModalRef;
  filterValueDropdown: any;
  storedResponse: any;
  isVisible12 = true;
  isAddFilterVisible = false;
  isEditFormVisible: boolean = false;
  formSubmitted: boolean;
  transformedData: NzTreeNodeOptions[] = [];
  selectedStatus: any;
  SelectedTrades: string[] = [];
  DivsionTradeLists: any[] = [];
  isDuplicateSaveButtonVisible: boolean = false;
  changesApplied: boolean = false;
  filters: any[] = [];
  filterFormGroup: FormGroup;
  lookupFilterResponse: LookupFilterResponse[] = [];
  constructor(public toastService: NzMessageService,
    private loadingIndicatoreService: LoadingIndicatorService,
    private standardFilterService: CustomFieldService,
    private modal: NzModalService,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,

    private LookupFilterService: LookupFilterService,
    @Inject(NZ_MODAL_DATA) public createLookupFilterRequests: any
  ) {

  }

  ngOnInit(): void {
    if (this.createLookupFilterRequests.clickFrom === "APPLY_SAVE_AS") {
      this.isAddFilterVisible = true;
    } else {
      this.isAddFilterVisible = false;
    }
    this.lookupFilterResponse = this.createLookupFilterRequests.std;
    this.initFilterForm();

  }

  private initFilterForm(): void {
    this.filterFormGroup = this.fb.group({
      globalId: ['00000000-0000-0000-0000-000000000000'],
      companyParameterId: [1],
      code: ['', Validators.required],
      name: ['', Validators.required],
      description: [''],
      meaning: [''],
      formNameId: [0],
      dataTypeId: [],
      isShareThisFilter: [true],
      isSetAsDefault: [false],
      createLookupFilterRequests: [[]],
    });
  }

  closeDialog() {
    this.cancel.emit();
  }
  toggleAddFilter() {
    this.isAddFilterVisible = !this.isAddFilterVisible;
    this.isEditFormVisible = false;
    // Patch the form to include the copyName field
    this.filterFormGroup.patchValue({
      // name: '',
      // isSetAsDefault: false,
      // isShareThisFilter: true,
    });

  }
  closeAddFilter() {
    this.isAddFilterVisible = false;
    this.isEditFormVisible = false;

  }


  filterGetData() {
    this.standardFilterService.getData().subscribe((res: any) => {
      this.filters = res.result;

      if (this.filters && this.filters.length > 0) {
        const defaultFilter = this.filters.find(filter => filter.isSetAsDefault);
        if (!this.filterValueDropdown) {
          this.filterValueDropdown = defaultFilter || this.filters[0];
          setTimeout(() => {
            this.onDropdownChangeFilter(this.filterValueDropdown);
          }, 0);
        }
      }
    });
  }

  onDropdownChangeFilter(selectedValueFilter: any): void {

    if (selectedValueFilter) {
      const data = selectedValueFilter
      this.storedResponse = data;
      if (data) {
        const result = data;
        this.filterFormGroup.patchValue({
          id: result.id || '',
          globalId: result.globalId || '',
          name: result.name || '',
          isShareThisFilter: result.isShareThisFilter,
          isSetAsDefault: result.isSetAsDefault,
          columnName: result.columnName || '',
          keyWord: result.keyWord || '',
          statusSetupId: result.statusSetupId || '',
        });
        this.filterFormGroup.get('createSubVendorFilterDivisionParameterRequests').valueChanges.subscribe((selectedTrades: string[]) => {
          const updateSubVendorFilterDivisionParameterRequestsArray = this.filterFormGroup.get('updateSubVendorFilterDivisionParameterRequests') as FormArray;
          // Clear existing controls in the FormArray
          while (updateSubVendorFilterDivisionParameterRequestsArray.length !== 0) {
            updateSubVendorFilterDivisionParameterRequestsArray.removeAt(0);
          }
          // Add new controls based on the selectedTrades
          selectedTrades.forEach(divisionTradeId => {
            const formGroup = this.fb.group({
              globalId: '00000000-0000-0000-0000-000000000000', // Add other properties as needed
              divisionTradeId: divisionTradeId,
            });
            updateSubVendorFilterDivisionParameterRequestsArray.push(formGroup);
          });
        });
        this.SelectedTrades = data.subVendorFilterDivisionParameters
          .map((item: any) => item.divisionTrade?.id?.toString())
          .filter((id: string | null) => id !== null); // Filter out null values

        if (this.DivsionTradeLists && this.DivsionTradeLists.length > 0) {
          this.transformedData = this.DivsionTradeLists.map((item) => {
            const isSelected = this.SelectedTrades.includes(item.id);
            return {
              title: item.name,
              value: item.id.toString(),
              key: item.id.toString(),
              isLeaf: true,
              checked: isSelected,
              selectable: true,
              selected: isSelected,
            };
          });
        }
        // this.applyFilter();
      } else {
        console.error('Invalid or empty data received');
      }
      (error) => {
        console.error('Error fetching data:', error);
      }
    } else {
      console.error('Invalid selected value');
    }
  }




  // saveFilter(): void {
  //   if (this.filterFormGroup.get('name')?.invalid) {
  //     this.toastService.error('Name field is required');
  //     this.formSubmitted = true;
  //     return;
  //   }

  //   this.loadingIndicatoreService.show();
  //   this.filterFormGroup.markAllAsTouched();

  //   const firstItem = this.createLookupFilterRequests.field[0];

  //   this.filterFormGroup.controls['companyParameterId'].setValue(firstItem.companyParameterId);
  //   this.filterFormGroup.controls['code'].setValue(firstItem.code);
  //   this.filterFormGroup.controls['formNameId'].setValue(firstItem.formNameId);
  //   this.filterFormGroup.controls['dataTypeId'].setValue(firstItem.dataTypeId);
  //   this.filterFormGroup.controls['globalId'].setValue(this.field);
  //   this.filterFormGroup.controls['createLookupFilterRequests'].setValue(this.createLookupFilterRequests.field);

  //   if (this.field) {
  //     this.updateFilter();
  //   } else {
  //     this.LookupFilterService.saveStandardFilterData(this.filterFormGroup.value).subscribe(
  //       (res: any) => {
  //         console.log(res.result);
  //         setTimeout(() => {
  //           this.loadingIndicatoreService.hide();
  //           this.toastService.success('Filter saved successfully');
  //           this.lookupFilterResponse.push(res.result);
  //           this.filterGetData();
  //           this.refreshUI();
  //           this.isDuplicateSaveButtonVisible = false;
  //           this.isEditFormVisible = false;
  //           this.changesApplied = false;
  //           this.isAddFilterVisible = false;
  //           this.filterFormGroup.reset();
  //           this.formSubmitted = false;
  //           this.LookupFilterService.getStandardFilterByFormPageId(this.createLookupFilterRequests.pageId);
  //         }, 2000);
  //       },
  //       (error) => {
  //         console.error('Error:', error);
  //         this.loadingIndicatoreService.hide();
  //         if (error.status === 500) {
  //           this.toastService.error('Internal server error. Please try again later.');
  //         } else {
  //           this.toastService.error('An error occurred. Please try again.');
  //         }
  //       }
  //     );
  //   }
  // }

  saveFilter(): void { 
    if (this.filterFormGroup.get('name')?.invalid) {
      this.toastService.error('Name field is required');
      this.formSubmitted = true;
      return;
    }
  
    this.loadingIndicatoreService.show();
    this.filterFormGroup.markAllAsTouched();
  
    const firstItem = this.createLookupFilterRequests.field[0];
  
    this.filterFormGroup.controls['companyParameterId'].setValue(firstItem.companyParameterId);
    this.filterFormGroup.controls['code'].setValue(firstItem.code);
    this.filterFormGroup.controls['formNameId'].setValue(firstItem.formNameId);
    this.filterFormGroup.controls['dataTypeId'].setValue(firstItem.dataTypeId);
    this.filterFormGroup.controls['globalId'].setValue(this.field);
    this.filterFormGroup.controls['createLookupFilterRequests'].setValue(this.createLookupFilterRequests.field);
  
    if (this.field) {
      this.updateFilter();
    } else {
      this.LookupFilterService.saveStandardFilterData(this.filterFormGroup.value).subscribe(
        (res: any) => {
          console.log(res.result);
          setTimeout(() => {
            this.loadingIndicatoreService.hide();
            this.toastService.success('Filter saved successfully');
            this.lookupFilterResponse.push(res.result);
  
            // Set the new filter as default only if isShareThisFilter is false
            if (!this.filterFormGroup.value.isShareThisFilter) {
              res.result.isSetAsDefault = true;
  
              // Update other filters to not be default
              this.lookupFilterResponse.forEach(filter => {
                if (filter !== res.result) {
                  filter.isSetAsDefault = false;
                }
              });
            }
  
            this.refreshUI(); // Refresh the UI to reflect the updated default filter
            this.isDuplicateSaveButtonVisible = false;
            this.isEditFormVisible = false;
            this.changesApplied = false;
            this.isAddFilterVisible = false;
            this.filterFormGroup.reset();
            this.formSubmitted = false;
            this.LookupFilterService.getStandardFilterByFormPageId(this.createLookupFilterRequests.pageId);
          }, 2000);
        },
        (error) => {
          console.error('Error:', error);
          this.loadingIndicatoreService.hide();
          if (error.status === 500) {
            this.toastService.error('Internal server error. Please try again later.');
          } else {
            this.toastService.error('An error occurred. Please try again.');
          }
        }
      );
    }
  }
  








  updateFilter(): void {
    const formData = { ...this.filterFormGroup.value };

    formData.isShareThisFilter = formData.isShareThisFilter === 'true';
    formData.isSetAsDefault = formData.isSetAsDefault === 'true';

    formData.statusSetupId = formData.statusSetupId === "" ? 0 : formData.statusSetupId;
    this.loadingIndicatoreService.show();
    this.LookupFilterService.saveStandardFilterData(formData)
      .pipe(
        finalize(() => this.loadingIndicatoreService.hide())
      )
      .subscribe(
        (res: any) => {
          console.log('Filter Update successfully', res.result);
          this.toastService.success('Filter Update successfully');

          // Update the existing filter data in local data store
          const updatedIndex = this.lookupFilterResponse.findIndex(filter => filter.globalId === formData.globalId);
          if (updatedIndex !== -1) {
            this.lookupFilterResponse[updatedIndex] = res.result;
          }

          // Other logic to reflect changes in UI
          this.closeEditForm();
          this.isDuplicateSaveButtonVisible = false;
          this.isEditFormVisible = false;
          this.formSubmitted = false;
          this.changesApplied = false;

          this.LookupFilterService.getStandardFilterByFormPageId(this.createLookupFilterRequests.pageId);
        },
        (error) => {
          console.error('Error:', error);
          // Handle errors
          if (error.status === 500) {
            this.toastService.error('Internal server error. Please try again later.');
          } else {
            this.toastService.error('An error occurred. Please try again.');
          }
        }
      );
  }


  showConfirmation(action: string, index: number): void {
    let title = action === 'edit' ? 'Edit Filter Confirmation' : 'Delete Filter Confirmation';
    let content = action === 'edit' ? 'Are you sure you want to edit this filter?' : 'Are you sure you want to delete this filter?';

    this.confirmModal = this.modal.confirm({
      nzTitle: title,
      nzOkDanger: action === 'delete',
      nzOkText: action === 'edit' ? 'Edit' : 'Delete',
      nzCancelText: 'Cancel',
      nzContent: content,
      nzIconType: action === 'edit' ? 'edit' : 'delete',
      nzCloseIcon: 'false',
      nzCentered: true,
      nzOnOk: () => {
        if (action === 'edit') {
          this.editFilter(index);
        } else if (action === 'delete') {
          const globalIdToDelete = this.lookupFilterResponse[index]?.globalId;
          if (globalIdToDelete) {
            this.onDelete(globalIdToDelete);
          } else {
            console.error('Global ID is undefined or null.');
          }
        }
      },
    });
  }

  duplicateFilter(index: number): void {
    const result = this.lookupFilterResponse[index];
    if (result && result.globalId) {
      this.filterFormGroup.controls['name'].setValue(result.name + ' Copy Of '); // Append '(Copy)' to the name
      this.field = result.globalId;
      this.isAddFilterVisible = true;
      this.isEditFormVisible = true;
    }
  }


  editFilter(index: number): void {
    const result = this.lookupFilterResponse[index];
    if (result && result.globalId) {
      // Set form control values
      this.filterFormGroup.patchValue({
        name: result.name,
        isShareThisFilter: result.isShareThisFilter.toString(),
        isSetAsDefault: result.isSetAsDefault.toString()
      });
      this.field = result.globalId;
      this.isAddFilterVisible = true;
      this.isEditFormVisible = true;
  
      // // Log the set values
      // console.log('Name:', this.filterFormGroup.controls['name'].value);
      // console.log('isShareThisFilter:', this.filterFormGroup.controls['isShareThisFilter'].value);
      // console.log('isSetAsDefault:', this.filterFormGroup.controls['isSetAsDefault'].value);
      // console.log('Field:', this.field);
      // console.log('isAddFilterVisible:', this.isAddFilterVisible);
      // console.log('isEditFormVisible:', this.isEditFormVisible);
    }
  }
  
  

 

  onDelete(globalIdToDelete: string): void {
    this.loadingIndicatoreService.show();
    if (!globalIdToDelete) {
      return;
    }
    this.LookupFilterService.deleteData(globalIdToDelete).subscribe(
      (response: any) => {
        this.lookupFilterResponse = this.lookupFilterResponse.filter(filter => filter.globalId !== globalIdToDelete);

        setTimeout(() => {
          this.toastService.success('Filter deleted successfully!');
          const index = this.lookupFilterResponse.findIndex(x => x.globalId === globalIdToDelete);
          if (index !== -1) {
            this.lookupFilterResponse.splice(index, 1);
          }
          this.LookupFilterService.getStandardFilterByFormPageId(this.createLookupFilterRequests.pageId);
          this.loadingIndicatoreService.hide();
        }, 1000);
      },
      (error: any) => {
        console.error('Error deleting filter:', error);
      }
    );
  }


  setAsDefaultView(filter: LookupFilterResponse): void {
    this.isLoading = true;

    let setAsDefaultPayload = {
      globalId: filter.globalId,
      companyParameterId: filter.companyParameterId,
      isSetAsDefault: true,
      formNameId: filter.formNameId
    };

    this.LookupFilterService.updateData(setAsDefaultPayload).subscribe(
      (updateRes: LookupFilterResponse) => {
        this.isLoading = false;
        this.lookupFilterResponse.forEach(data => {
          if (data.id === filter.id) {
            data.isSetAsDefault = true;
          } else {
            data.isSetAsDefault = false;
          }
        });
        this.LookupFilterService.getStandardFilterByFormPageId(this.createLookupFilterRequests.pageId);
        this.refreshUI();
      },
      (updateError) => {
        console.error('Update Error:', updateError);
        this.isLoading = false;
      }
    );
  }

  refreshUI(): void {
    // If you're using Angular, you might want to trigger change detection
    // to ensure the UI reflects the updated data
    this.changeDetectorRef.detectChanges();
}


  closeEditForm(): void {
    this.isAddFilterVisible = false;
    this.isEditFormVisible = false;
    this.isDuplicateSaveButtonVisible = true;
  }



}


