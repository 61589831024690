import { Component } from '@angular/core';

@Component({
  selector: 'owner-invoice-payment-empty',
  templateUrl: './owner-invoice-payment-empty.component.html',
  styleUrls: ['./owner-invoice-payment-empty.component.css']
})
export class OwnerInvoicePaymentEmptyComponent {

}
