<nz-modal
  nzWidth="60%"
  [(nzVisible)]="uploadfiles"
  [nzTitle]="UploadFiles1"
  [nzFooter]="lpcateFooter"
  [nzBodyStyle]="{ background: '#f1f4fa', padding: '14px', 'max-height': '78vh', 'overflow': 'hidden'}"
  [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="mapclose()"
>
<ng-template #UploadFiles1>
  <div class="row p-0 m-0">
    <h2 class="p-0 m-0" nz-typography>Location</h2>
  </div>
</ng-template>
  <div class="content content-set-background" *nzModalContent>
    <div class="ng-container">
      <!-- card -->
      <iframe
      src="https://maps.google.com/maps?q=university%20of%20san%20francisco&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
      frameborder="0" scrolling="no" style="width: 100%; height: 1100px;"></iframe>
      <!-- card -->
    </div>
  </div>
  <ng-template #lpcateFooter>
    
  </ng-template>
</nz-modal>

