<nz-page-header>
    <nz-page-header-title>
        <h1 style="font-size: 30px;">Reports</h1>
    </nz-page-header-title>
</nz-page-header>
<div class="container-fuild mb-4 m-3">
    <div class="row">
        <h1 class="mt-5" style="color: #626262;">Financial</h1>
    </div>
    <div class="card-container">
        <div class="row mt-2">
            <div class="col-3" >
                <div class="card" style="width: 18rem; height: 280px;">
                    <div class="star-icon">
                        <i class="fas fa-star"></i>
                    </div>
                    <div class="star-icon"></div>
                    <img src="/assets/Financial1.svg" alt="Chart 1"> 
                    <div class="card-title text-start">Estimated Cost vs. Actual Cost</div>
                    <div class="card-text text-start">A comparison of the total estimated costs coming from your estimate with the actual costs paid from POS and Bills.</div>
                </div>
            </div>
            <div class="col-3" >
                <div class="card" style="width: 18rem; height: 280px;">
                    <div class="star-icon">
                        <i class="fas fa-star"></i>
                    </div>
                    <img src="/assets/Financial2.svg" alt="Chart 1"> 
                    <div class="card-title text-start" >Receivables by Job</div>
                    <div class="card-text text-start">A comparison of the the total amount you have invoiced with the amount paid against those invoices.</div>
                </div>
            </div>
            <div class="col-3" >
                <div class="card" style="width: 18rem; height: 280px;">
                    <div class="star-icon">
                        <i class="fas fa-star"></i>
                    </div>
                    <img src="/assets/Financial3.svg" alt="Chart 1"> 
                    <div class="card-title text-start" >Work In Progress - Cash</div>
                    <div class="card-text text-start">Check the financial health of jobs on a cash basis. Identify current cost and billing statuses based upon when cash is received or when payments are made, as well as ensure jobs are on track.</div>
                </div>
            </div>
            <div class="col-3" >
                <div class="card" style="width: 18rem; height: 280px;">
                    <div class="star-icon">
                        <i class="fas fa-star"></i>
                    </div>
                    <img src="/assets/Financial4.svg" alt="Chart 1"> 
                    <div class="card-title text-start" >Work in Progress - Accrual</div>
                    <div class="card-text text-start"> Check the financial health of jobs on an accrual basis. Identify current cost and billing statuses based upon when revenue and expenses are incurred, as well as ensure jobs are on track.</div>
                </div>
            </div>
        </div>  
    </div>
</div>