<div class="row famly-inn">
    <div class="col-12">
        <div class="card border-0 mt-0">
          <div class="card-head border-bottom">
            <div class="row py-2">
              <div class="col-6">
                <h5 nz-typography>Checklist Items</h5>
              </div>
              <div class="col-6 text-end">
                <button (click)="addNewRow()" nz-button nzType="default" class="rounded-1">Add Checklist</button>
              </div>
            </div>
          </div>
          <div class="card-body align-content-center">
            <div class="row gx-2">
              <div class="col-12">
                <div class="row gx-2" *ngFor="let email of emails" class="row">
                  <div class="col-1 image">
                    <img src="assets/drag.png">
                    <!-- <label class="ms-1 mt-1" nz-checkbox></label> -->
                    <div class="round">
                      <input type="checkbox" checked id="checkbox" />
                      <label for="checkbox"></label>
                    </div>
                  </div>
                  <div class="col-10">
                    <input type="text" class="formField">
                  </div>
                  <div class="col-1">
                  <button nzTrigger="click" nz-dropdown  [nzDropdownMenu]="menu4"   nzPlacement="bottomLeft" nz-button nzType="text" class="same-but"><i class="fa-solid fa-ellipsis-vertical me-1"></i></button>
                  <nz-dropdown-menu #menu4="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item><i class="fa fa-light fa-users me-2"></i>Add Assignees</li>
                        <li nz-menu-item><i class="fa-solid fa-paperclip me-2"></i>Add Attachments</li>
                        <li (click)="deleteRow(email.id)" nz-menu-item><i class="fa-regular fa-trash-can me-2"></i>Delete Item</li>
                      </ul>
                  </nz-dropdown-menu>
                  </div>
                </div>
                <div class="row"  *ngIf="emails.length > 0">
                    <div class="col-4">
                      <button (click)="addNewRow()" nz-button nzType="link" class="text-primary fs-6"><i class="bx bxs-plus-circle text-primary me-2 fs-5"></i><span class="fs-6">Add Checklist Item</span></button>
                    </div>
                </div>
               </div>
            </div>
            <div class="row" *ngIf="emails.length === 0; isCheckboxChecked11">
              <div class="col-12">
                <div class="row">
                  <div class="col-12 d-flex align-content-center justify-content-center">
                    <i class="fa-regular fa-circle-check"></i>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 d-flex align-content-center justify-content-center">
                    <h2 nz-typography>Give your To-Do's more power</h2>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12 d-flex align-content-center justify-content-center">
                    <label class="para-label">Add checklist items to assign specific and detailed tasks from your</label>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12 d-flex align-content-center justify-content-center">
                    <label class="para-label">To-Do.</label>
                  </div>
                </div>
                <div class="row mt-3 d-flex align-content-center justify-content-center">
                  <div class="col-2">
                    <button (click)="addNewRow()" nz-button class="ms-2 button-Daily">Add Checklist</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>




