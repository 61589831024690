import { Component } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { SalesComponent } from 'src/app/shared/component/sales/sales.component';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { NewProposalComponent } from '../../new-proposal/new-proposal.component';

@Component({
  selector: 'app-lead-opportunities-proposal-templates-header',
  templateUrl: './lead-opportunities-proposal-templates-header.component.html',
  styleUrls: ['./lead-opportunities-proposal-templates-header.component.css']
})
export class LeadOpportunitiesProposalTemplatesHeaderComponent {
  LEAD_OPPR_PROP_TEMP: string = FiltersName.LEAD_OPPR_PROP_TEMP;
  PAGE_ID = 39;
  constructor(private modal: NzModalService, 
    private msg: NzMessageService,
    private filterService: FilterSideMenuBarService) { }
    //LeadSettings
    LeadSettings(): void {
      const modalRef = this.modal.create({
        nzContent: SalesComponent,
        nzFooter: null, 
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy(); 
      });
    }
    showNewTemplateProposal() {
      const modalRef = this.modal.create({
        nzContent: NewProposalComponent,
        nzFooter: null,
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
  
      });
    }
    
    isVisible10 = false;
  
    leadProposal() {
      this.isVisible10 = true;
    }
    isVisible = false;

  openFilter(component: string , pageId: number) {
    this.filterService.toggleFilter(component, pageId);
  }
  openModal(): void {
    this.isVisible = true;
  }
}
