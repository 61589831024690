import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LeadOpportunitiesRoutingModule } from './lead-opportunities-routing.module';
import { LeadOpportunitiesDashBoardComponent } from './Components/lead-opportunities-dash-board/lead-opportunities-dash-board.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgZorroModule } from 'src/shared/ng-zorro/ng-zorro.module';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { AddContactFromComponent } from './Components/add-contact-from/add-contact-from.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { SharedModule } from 'src/shared/shared.module';
import { NewProposalComponent } from './Components/new-proposal/new-proposal.component';
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { IconsModule } from '@progress/kendo-angular-icons';
import { LeadOpportunitiesSettingComponent } from './Components/Modal/lead-opportunities-setting/lead-opportunities-setting.component';
import { LeadOpportunitiesLearnMoreComponent } from './Components/Modal/lead-opportunities-learn-more/lead-opportunities-learn-more.component';
import { LeadOpportunitiesExportComponent } from './Components/Modal/lead-opportunities-export/lead-opportunities-export.component';
import { LeadOpportunitiesImportLeadOpportunitiesComponent } from './Components/Modal/lead-opportunities-import-lead-opportunities/lead-opportunities-import-lead-opportunities.component';
import { LeadOpportunitiesAddContactComponent } from './Components/Modal/lead-opportunities-add-contact/lead-opportunities-add-contact.component';
import { LeadOpportunitiesNewLeadOpportunitiesComponent } from './Components/Modal/lead-opportunities-new-lead-opportunities/lead-opportunities-new-lead-opportunities.component';
import { LeadProposalPreviewComponent } from './Components/lead-proposal-preview/lead-proposal-preview.component';
import { FormatNoDataComponent } from './Components/format-no-data/format-no-data.component';
import { FileUploadComponent } from './Components/file-upload/file-upload.component';
import { AddContactInstallationInstructionComponent } from './Components/Modal/add-contact-installation-instruction/add-contact-installation-instruction.component';
import { LeadOpportunitiesLeadProposalsHeaderComponent } from './Components/Lead-opportunities-header/lead-opportunities-lead-proposals-header/lead-opportunities-lead-proposals-header.component';
import { AddContactLearnMoreComponent } from './Components/Modal/add-contact-learn-more/add-contact-learn-more.component';
import { LeadOpportunitiesListViewHeaderComponent } from './Components/Lead-opportunities-header/lead-opportunities-list-view-header/lead-opportunities-list-view-header.component';
import { EstimatedCostComponent } from './Components/estimated-cost/estimated-cost.component';
import { NewProposalsCostCodesComponent } from './Components/new-proposals-cost-codes/new-proposals-cost-codes.component';
import { LeadOpportunitiesActivityTemplatesHeaderComponent } from './Components/Lead-opportunities-header/lead-opportunities-activity-templates-header/lead-opportunities-activity-templates-header.component';
import { LeadOpportunitiesActivityViewHeaderComponent } from './Components/Lead-opportunities-header/lead-opportunities-activity-view-header/lead-opportunities-activity-view-header.component';
import { LeadOpportunitiesActivityCalendarHeaderComponent } from './Components/Lead-opportunities-header/lead-opportunities-activity-calendar-header/lead-opportunities-activity-calendar-header.component';
import { LeadOpportunitiesWorkSheetAddformCatalogComponent } from './Components/Catalog-Modal/lead-opportunities-work-sheet-addform-catalog/lead-opportunities-work-sheet-addform-catalog.component';
import { CatalogCatalogTab1Component } from './Components/Catalog-Modal/catalog-catalog-tab-1/catalog-catalog-tab-1.component';
import { CatalogCostGroupTab2Component } from './Components/Catalog-Modal/catalog-cost-group-tab-2/catalog-cost-group-tab-2.component';
import { CatalogNewCostItemComponent } from './Components/Catalog-Modal/catalog-new-cost-item/catalog-new-cost-item.component';
import { CatalogNewCostGroupComponent } from './Components/Catalog-Modal/catalog-new-cost-group/catalog-new-cost-group.component';
import { LeadOpportunitiesProposalTemplatesHeaderComponent } from './Components/Lead-opportunities-header/lead-opportunities-proposal-templates-header/lead-opportunities-proposal-templates-header.component';
import { LeadOpportunityMapHeaderComponent } from './Components/Lead-opportunities-header/lead-opportunity-map-header/lead-opportunity-map-header.component';
import { LeadOpportunityMapGraphComponent } from './Components/lead-Opportunity-map/lead-opportunity-map-graph/lead-opportunity-map-graph.component';
import { LeadOpportunityMap6LeadComponent } from './Components/lead-Opportunity-map/lead-opportunity-map-6-lead/lead-opportunity-map-6-lead.component';
import { ChooseFormEXistingContactsComponent } from './choose-form-existing-contacts/choose-form-existing-contacts.component';
import { ScheduleNewActivityvityComponent } from './Components/Modal/schedule-new-activityvity/schedule-new-activityvity.component';
import { AddLeadActivityTypeComponent } from './Components/Modal/add-lead-activity-type/add-lead-activity-type.component';
import { ContactListComponent } from './Components/contact-list/contact-list.component';
import { LeadActivityFormComponent } from './Components/lead-activity-form/lead-activity-form.component';
import { LeadOpportunitySelectDialogBoxComponent } from './Components/lead-opportunity-select-dialog-box/lead-opportunity-select-dialog-box.component';
import { LeadOpportunityAttachmentsUploadFilesComponent } from './Components/Modal/lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from './Components/Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { LeadToJobComponent } from './Components/Modal/lead-to-job/lead-to-job.component';
import { LeadOpportunityImportTemplateComponent } from './Components/Modal/lead-opportunity-import-template/lead-opportunity-import-template.component';
import { CopyToActivityTemplateComponent } from './Components/Modal/copy-to-activity-template/copy-to-activity-template.component';
import { ImportProposalsComponent } from './Components/Modal/import-proposals/import-proposals.component';
import { CopyToProposalsTemplateComponent } from './Components/Modal/copy-to-proposals-template/copy-to-proposals-template.component';
import { LeadProposalColumnsComponent } from './Components/lead-proposal-columns/lead-proposal-columns.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NewLeadProposalTemplateComponent } from './Components/Lead-Template/new-lead-proposal-template/new-lead-proposal-template.component';
import { ActivityFullCalendarComponent } from './Components/activity-full-calendar/activity-full-calendar.component';
import { GoogleMapComponent } from './Components/lead-Opportunity-map/google-map/google-map.component';
import { LeademailWizardComponent } from './Components/leademail-wizard/leademail-wizard.component';
import { LeadActivityAssignDisplayColorComponent } from './Components/Modal/lead-activity-assign-display-color/lead-activity-assign-display-color.component';
import { LeadActivityAssignUserComponent } from './Components/Modal/lead-activity-assign-user/lead-activity-assign-user.component';
import { LeadActivityDeleteComponent } from './Components/Modal/lead-activity-delete/lead-activity-delete.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { AttachmentAnnotationComponent } from './Components/attachment-annotation/attachment-annotation.component';
import { PhotoDrawComponent } from './Components/photo-draw/photo-draw.component';
import { PercentageMarkupComponent } from './Components/new-proposal/percentage-markup/percentage-markup.component';
@NgModule({
  declarations: [
    LeadOpportunitiesDashBoardComponent,
    AddContactFromComponent,
    NewProposalComponent,
    LeadOpportunitiesSettingComponent,
    LeadOpportunitiesLearnMoreComponent,
    LeadOpportunitiesExportComponent,
    LeadOpportunitiesImportLeadOpportunitiesComponent,
    LeadOpportunitiesAddContactComponent,
    LeadOpportunitiesNewLeadOpportunitiesComponent,
    LeadProposalPreviewComponent,
    FormatNoDataComponent,
    FileUploadComponent,
    AddContactInstallationInstructionComponent,
    AddContactLearnMoreComponent,
    LeadOpportunitiesLeadProposalsHeaderComponent,
    LeadOpportunitiesListViewHeaderComponent,
    EstimatedCostComponent,
    NewProposalsCostCodesComponent,
    LeadOpportunitiesActivityTemplatesHeaderComponent,
    LeadOpportunitiesActivityViewHeaderComponent,
    LeadOpportunitiesActivityCalendarHeaderComponent,
    LeadOpportunitiesWorkSheetAddformCatalogComponent,
    CatalogCatalogTab1Component,
    CatalogCostGroupTab2Component,
    CatalogNewCostItemComponent,
    CatalogNewCostGroupComponent,
    LeadOpportunitiesProposalTemplatesHeaderComponent,
    LeadOpportunityMapHeaderComponent,
    LeadOpportunityMapGraphComponent,
    LeadOpportunityMap6LeadComponent,
    ChooseFormEXistingContactsComponent,
    ScheduleNewActivityvityComponent,
    AddLeadActivityTypeComponent,
    ContactListComponent,
    LeadActivityFormComponent,
    LeadOpportunitySelectDialogBoxComponent,
    LeadToJobComponent,
    LeadOpportunityAttachmentsUploadFilesComponent,
    LeadOpportunityAttachmentsUploadFilesViewAllComponent,
    LeadOpportunityImportTemplateComponent,
    CopyToActivityTemplateComponent,
    ImportProposalsComponent,
    CopyToProposalsTemplateComponent,
    LeadProposalColumnsComponent,
    NewLeadProposalTemplateComponent,
    GoogleMapComponent,
    ActivityFullCalendarComponent,
    AttachmentAnnotationComponent,
    PhotoDrawComponent,
    LeademailWizardComponent,
    LeadActivityAssignDisplayColorComponent,
    LeadActivityAssignUserComponent,
    LeadActivityDeleteComponent,
    PercentageMarkupComponent,
  ],
  imports: [
    FullCalendarModule,
    CommonModule,
    LeadOpportunitiesRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgZorroModule,
    NzTabsModule,
    DragDropModule,
    SharedModule,
    NzTimePickerModule,
    IndicatorsModule,
    IconsModule,
    NzPopoverModule,
    NzAvatarModule
  ],
  exports: [
    AddContactFromComponent,
    LeadOpportunitiesNewLeadOpportunitiesComponent,
  ],
  providers: [DatePipe],
})
export class LeadOpportunitiesModule { }
