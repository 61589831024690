<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form (ngSubmit)="onFilterApply()">
    <div class="row mt-2">
      <div class="col-10 p-0">
        <div class="input-group">
         <nz-select
          [(ngModel)]="formFieldValues['standardFilter']"
          name="standardFilter"
          (ngModelChange)="onChangeStandardFilter($event)"
          nzShowSearch
          [nzAutoFocus]="true"
          nzPlaceHolder="Standard Filter"
          r>
          <nz-option *ngFor="let filter of filterResponseData"
           [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')"
           [nzValue]="filter.id"
           nzCustomContent>
           <div class="option-content">
            <!-- Customize content with icons and text -->
            <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
            <span>{{ filter.name }}</span>
           <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

           </div>
          </nz-option>
         </nz-select>
        </div>
       </div>
      <div class="col-1 mt-1">
        <i class="bi bi-three-dots"

        nz-popover
        [nzPopoverContent]="contentTemplate"
        [nzPopoverPlacement]="'top'"

         (click)="openStandardFilterComponent()"></i>
      </div>
    </div>
    <div class="col p-0 text-end">
      <strong nz-icon nzType="close" nzTheme="outline"></strong>
    </div>
    <div class="row famly-inn">
      <div class="col-12">
        <div class="row">
          <div class="col p-0">
            <label class="sub-text">Title</label>
            <input [(ngModel)]="formFieldValues['title']" name="title" type="text" class="form-control formField">
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Selection Status</label>
         <!-- selecation status -->
              <nz-tree-select
             [(ngModel)]="formFieldValues['selectionStatus']"
             name="selectionStatus"
             style="width: 100%"
             [nzNodes]="SelectionStatusNodes"
             nzShowSearch
             nzAllowClear="false"
             nzCheckable
             (ngModelChange)="onselectionStatusChange($event)"
             nzPlaceHolder="Select selection Status"
             nzDropdownClassName="dropdownStyle"
            >
            </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Allowance Status</label>

   <!-- allowance status  -->
<nz-tree-select
             [(ngModel)]="formFieldValues['AllowanceStatus']"
             name="AllowanceStatus"
             style="width: 100%"
             [nzNodes]="AllowanceStatusNodes"
             nzShowSearch
             nzAllowClear="false"
             nzCheckable
             (ngModelChange)="onAllowanceStatusChange($event)"
             nzPlaceHolder="Select Allowance Status"
             nzDropdownClassName="dropdownStyle"
            >
            </nz-tree-select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 p-0 text-end ">
        <button nz-button nzType="primary">Clear All</button>
        <button class="ms-1" nz-button nzType="default">Apply </button>
      </div>
    </div>

  </form>
</div>


<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>
