import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { JobTypeService } from 'src/Service/Job-List/Job-Information/job-type.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { JobScratchPreDefinedCodes } from '../../Models/LookUpStandardFilterCodes';

@Component({
  selector: 'app-add-edit-job-group',
  templateUrl: './add-edit-job-group.component.html',
  styleUrls: ['./add-edit-job-group.component.css']
})
export class AddEditJobGroupComponent implements OnInit{
  @Output() cancel = new EventEmitter<void>();
  @ViewChild('titleInput') titleInput: ElementRef<HTMLInputElement>;
  @Input() selectedValue: number;
  jobGroupForm: FormGroup;
  pageId: number = 30002;
  isLoadingOne: boolean = false;
  isLoadingEdit: boolean = false;
  onJobGroupEdit: boolean = false;
  confirmModal?: NzModalRef;
  isDeleteTagsLoading: any;
  jobGroupVisible: boolean = true;
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  JobGroupNodes: NzTreeNodeOptions[] = [];
  jobGroupResponse: LookupNameSetupResponse;
  DisabledNextActivity: boolean = false;
  isSave = false;
  @Output()  jobGroupOnCompleted = new EventEmitter();
  PAGE_ID: number = 30002;
  onCheckNameSame: string[] = [];
  isJobGroupLoaded = false;
  preDefinedDataCodes = {
    JobGroup: JobScratchPreDefinedCodes.JobGroup,
  };
  constructor(
    private jobTypeService: JobTypeService,
    private LookupFilterService: LookupFilterService,
    private LeadOpportunitiesService: LeadOpportunitiesService,
    private fb: FormBuilder,
    public toastService: NzMessageService,
    private modal: NzModalService,
  ){}
  ngAfterViewInit(): void {
    this.focusTitleInput();
  }
  private focusTitleInput(): void {
    setTimeout(() => {
      if (this.titleInput) {
        this.titleInput.nativeElement.focus();
      }
    }, 1000);
  }
  ngOnInit(): void {
    this.initLoad();
    this.iniliazeForm();
    // this.onGetJobGroup();
    if(this.selectedValue === 0){
      this.onJobGroupEdit = false
    } else {
      this.onJobGroupEdit = true
    }
  }
  iniliazeForm() {
    this.jobGroupForm = this.fb.group({
      globalId: ['00000000-0000-0000-0000-000000000000'],
      name: ['', [Validators.required, Validators.maxLength(25)]],
      companyParameterId: 1,
      formNameId: 30002,
      isFieldValue: false,
      lookUpStandardId: 204,
      parentCode: "JSCG",
      code: "JSCG",
      description:'',
    });
  }
  initLoad(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(
        (res) => {
          this.lookupSetNameResponse = res.result;
          this.showTagEdit();
          resolve();
        },
        (error) => {
          console.error('Error loading lookup values:', error);
          reject(error);
        }
      );
    });
  }

  showTagEdit(): void {
    const selectedObjectFromLookup = this.lookupSetNameResponse.find(item => item.id === this.selectedValue);
    if (selectedObjectFromLookup) {
      this.jobGroupForm.patchValue({
        name: selectedObjectFromLookup.name,
        globalId: selectedObjectFromLookup.globalId,
        id: selectedObjectFromLookup.id
      });
    } else {
      console.error('No matching data found for ID:', this.selectedValue);
    }
  }

  saveJob(actionType: 'save' = 'save'): Promise<void> {
    let DataSet = this.jobGroupForm.value;
  
    if (this.jobGroupForm.invalid) {
      this.jobGroupForm.markAllAsTouched();
      return Promise.reject('Form is invalid');
    }
  
    return new Promise((resolve, reject) => {
      if (this.isSave) {
        return reject('Already processing');
      }
  
      if (actionType === 'save') {
        this.isSave = true;
      }
      this.DisabledNextActivity = true;

      let name = this.lookupSetNameResponse.map(nn => nn?.name);
      if (name.includes(this.jobGroupForm.value.name)) {
        this.toastService.error("This Job Group exists.");
        this.DisabledNextActivity = false;
        this.isSave = false;
        reject('Duplicate job group');
        return;
      }

      this.LeadOpportunitiesService.getLookUpNameSetup(DataSet).subscribe(
        (response) => {
          this.jobGroupResponse = response.result;
          this.jobGroupOnCompleted.emit(this.jobGroupResponse);
          const tagName = this.jobGroupForm.get('name')?.value;
          this.toastService.success(`${tagName} saved successfully`);
          this.cancel.emit();
          this.jobGroupVisible = false;
          resolve();
        },
        (error) => {
          console.error('Error saving data:', error);
          this.toastService.error('An error has occurred. Please try again.');
          this.DisabledNextActivity = false;
          reject(error);
        }
      ).add(() => {
        this.resetFlags();
      });
    });
  }
  resetFlags() {
    this.isSave = false;
  }

  // onGetJobGroup() {
  //   if (this.isJobGroupLoaded) {
  //     return;
  //   }
    
  //   this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(
  //     (res) => {
  //       this.onCheckNameSame = res?.result?.map(nn => nn?.name);
  //       this.isJobGroupLoaded = true;
  //     },
  //     (error) => {
  //       console.error('Error fetching job group names:', error);
  //     }
  //   );
  // }
  
 isDeleteLoading: boolean = false;
  deleteJobConfirm(): void {
    if (this.isDeleteLoading) {
      return;
    }
  
    this.modal.confirm({
      nzTitle: `Delete job group?`,
      nzContent: `This job group is not currently being used and will be deleted.`,
      nzOkText: `Delete`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteSubVendor(this.selectedValue)
          .then(() => {
            this.SubVendorNewLeadModelCancel();
          })
          .catch(() => {
  
          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }
  deleteSubVendor(id: number): Promise<void> {
    console.log('this.jobGroupResponse.globalId',this.jobGroupForm.value.globalId);
    
    return new Promise((resolve, reject) => {
      if (!id) {
        this.toastService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }
      resolve();
      
      this.LeadOpportunitiesService.deleteDataLookupNameSetup(this.jobGroupForm.value.globalId).subscribe(
        (res) => {
          this.jobGroupOnCompleted.emit(res?.result);
          this.CancelJobJobGroup();
          this.isDeleteLoading = false;
        },
        (error) => {
          this.isDeleteLoading = false;
          this.toastService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      );
    });
  }
  SubVendorNewLeadModelCancel(): void {
    this.CancelJobJobGroup();
    this.jobGroupVisible = false;
    this.LeadOpportunitiesService.clearSelectedFiles();
  }
  isVisibleInJobGroup: boolean = true;
  CancelJobJobGroup() {
   this.isVisibleInJobGroup = false;
    this.cancel.emit();
  }
  onInputChange(event: any) {
    const value = event.trim();
    const control = this.jobGroupForm.get('name');
    
    control?.setValue(value, { emitEvent: false });
    if (value.length > 0) {
      control?.markAsTouched();
    } else {
      control?.markAsUntouched();
    }
  }
}
