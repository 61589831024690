import { Component, EventEmitter, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NewRFIsComponent } from '../new-rfis/new-rfis.component';

@Component({
  selector: 'app-rfi-empty',
  templateUrl: './rfi-empty.component.html',
  styleUrls: ['./rfi-empty.component.css']
})
export class RfiEmptyComponent {
  @Output() cancel = new EventEmitter<void>();

  constructor(private modal: NzModalService) {}

  RFIsEmptyVisible=true;

RFIsEmptyCancel(): void {
  this.cancel.emit();
}


newRFIsOpen(): void {
  const modalRef = this.modal.create({
    nzContent: NewRFIsComponent,
    nzFooter: null, 
  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy(); 
  });

}
}
