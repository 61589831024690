<div class="row mt-5" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <div class="col" style="text-align: center;">
       <span><i class="bi bi-receipt" style="font-size: 70px;"></i> </span>        
        <div class="row">
            <div class="col mt-2">
        <h1>No Receipts</h1>
            </div>
        </div>
        <div class="col mt-3 text-secondary">
        <p>Track and manage your receipts in one place</p>
        </div>
        <div class="col mt-4" style="display: flex; justify-content: center; align-items: center;">
            <button nz-button nzType="primary" style="margin-right: 10px;" (click)="receiptUploadModelOpen()">Upload a Receipts</button>
        </div>
    </div>
  
  </div>