import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ApplicationGroupParameterResponse, ApplicationGroupResponse } from 'src/Models/InternalUser/applicationUserResponse';

@Component({
  selector: 'permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.css']
})
export class PermissionComponent implements OnChanges {
  @Input() roleId: number;
  @Input() selectedRole: ApplicationGroupResponse;

  applicationGroupParameters: ApplicationGroupParameterResponse[] = [];
  sortedApplicationGroupParameters: ApplicationGroupParameterResponse[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['roleId'] || changes['selectedRole']) {
      if (this.selectedRole && this.selectedRole.applicationGroupParameters) {
        this.applicationGroupParameters = this.selectedRole.applicationGroupParameters;

        this.sortedApplicationGroupParameters = [...this.applicationGroupParameters].sort((a, b) => {
          return a.applicationGroupId - b.applicationGroupId;
        });

        console.log("sortedArray:", this.sortedApplicationGroupParameters);
      }
    }
  }
}
