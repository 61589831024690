<nz-card [nzTitle]="'Lead Opportunities'" nzType="card" class="mt-3" nzBorderless="true">
  <ng-template #leadOpp>
    <div class="row p-0 m-0">
      <h6 class="p-0 m-0">Lead Opportunities</h6>
    </div>
  </ng-template>
  <div nz-row>
    <nz-table
      nzSize="small"
      #smallTable
      #filterTable
       [nzData]="customerResponseData?.leadOpportunities"
      nzTableLayout="fixed"
      nzShowPagination="false"
    >
    <thead>
      <tr>
        <th nzWidth="100px">Title</th>
        <th nzWidth="150px">IsActive</th>
        <th nzWidth="200px">CreatedOn</th>
        <th nzWidth="200px">StreetAddress</th>
        <th nzWidth="200px">Suburb</th>
        <th nzWidth="200px">State</th>
        <th nzWidth="200px">PostalCode</th>
        <!-- Add more headers as needed -->
      </tr>
    </thead>
      <tbody>
        <tr *ngFor="let data of filterTable.data">
          <td (click)="leadOpportunityNewLeadModalOpen(data)">
            <a  class="hover blue-text">{{ data.title }}</a>
          </td>
          <td>{{ data.isActive }}</td>
          <td>{{ data.createdOn }}</td>
          <td>{{ data.streetAddress}}</td>
          <td>{{ data.suburb}}</td>
          <td>{{ data.state}}</td>
          <td>{{ data.postalCode}}</td>
          <!-- <td>{{ data }}</td>
          <td>{{ data.projectedSalesDate }}</td> -->
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-card>





