<nz-modal
    nzWidth="50%"
    [(nzVisible)]="Taghandle"
    [nzTitle]="Tag1"
    [nzFooter]="Tag2"
    (nzOnCancel)="TagClose()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '70vh', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
  <ng-template #Tag1>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0" nz-typography>Cost Code Editor</h2>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <div class="row famly-inn gx-3">
      <div class="col-12">
          <div class="card border-0 mt-0">
              <div class="card-head border-bottom">
                  <div class="row py-2">
                    <div class="col-12">
                      <h5 class="ms-3" nz-typography>Adding a Cost Code</h5>
                    </div>
                  </div>
              </div>
              <div class="card-body">
                <div class="row">
                    <div class="col-6">
                      <label class="sub-text">Title <span class="text-danger fw-bolder">*</span></label>
                      <input [disabled]="true" class="formField" type="text">
                    </div>
                    <div class="col-6">
                      <label class="sub-text">Cost Code Category <span class="text-danger fw-bolder">*</span></label>
                      <nz-select nzDisabled nzShowSearch nzAllowClear>
                        <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                        <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                        <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                      </nz-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                      <label class="sub-text">Sub Code Of</label>
                      <nz-select nzDisabled nzShowSearch nzAllowClear>
                        <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                        <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                        <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                      </nz-select>
                    </div>
                </div>
                <div class="row border-bottom pb-4 mt-3">
                  <div class="col-6">
                    <label nzDisabled nz-checkbox>Time Clock Labor Code</label>
                      <i
                      class="bi bi-info-circle-fill text-secondary"
                      nz-popover
                      [nzPopoverContent]="JobTypets"
                      nzPopoverPlacement="top"></i>
                      <ng-template #JobTypets>
                        <div class="rounded-2">
                          <p style="width: 210px;font-size: 13px;">
                            Checking this box will make this code available on the Time Clock.
                          </p>
                        </div>
                      </ng-template>
                  </div>
                </div>
                <div class="row mt-3"><div class="col-12"><label class="sub-text">Details</label></div></div>
                <div class="row border-bottom pb-4">
                  <div class="col-12">
                    <textarea  [disabled]="true" style="border-radius: 5px;" rows="2" class="formField" nz-input></textarea>
                  </div>
                </div> 
                <div class="row mt-3">
                  <div class="col-12">
                    <label nzDisabled nz-checkbox>Active</label>
                  </div>
                </div>
              </div>
          </div>
      </div>
    </div>
</div>
  <ng-template #Tag2>
    <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
  </ng-template>
</nz-modal>





