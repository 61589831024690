import { Component, EventEmitter, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProjectManagementSiteDiariesDocumentDOCComponent } from '../project-management-site-diaries-document-doc/project-management-site-diaries-document-doc.component';
import { ProjectManagementSiteDiariesDocumentXLSComponent } from '../project-management-site-diaries-document-xls/project-management-site-diaries-document-xls.component';

@Component({
  selector: 'app-project-management-site-diaries-create-files',
  templateUrl: './project-management-site-diaries-create-files.component.html',
  styleUrls: ['./project-management-site-diaries-create-files.component.css']
})
export class ProjectManagementSiteDiariesCreateFilesComponent {
  @Output() cancel = new EventEmitter<void>();


  uploadfiles=true;
  UploadFiles(): void {
  this.cancel.emit();
  }
  constructor(private modal: NzModalService) {}

  documentXLS(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesDocumentXLSComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }
  documentDOC(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesDocumentDOCComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }
}


