import { Component, EventEmitter, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MessagingManageEmailsComponent } from 'src/app/messaging/Components/messaging/messaging-manage-emails/messaging-manage-emails.component';

@Component({
  selector: 'app-invite-subs-vendor',
  templateUrl: './invite-subs-vendor.component.html',
  styleUrls: ['./invite-subs-vendor.component.css']
})
export class InviteSubsVendorComponent {
  @Output() cancel = new EventEmitter<void>();
  constructor(private modal: NzModalService) {}


  InviteSubsVendor=true
editorValue: string;
  InviteSubsVendorClose(): void {
    this.cancel.emit();

  }
  SubsVendorManageEmail(): void {
    const modalRef = this.modal.create({
      nzContent: MessagingManageEmailsComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });

  }
 
}
