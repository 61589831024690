import { Component, EventEmitter, Output } from '@angular/core';
import { JobCreateFromTemplateComponent } from '../job-create-from-template/job-create-from-template.component';
import { JobCreateFromScratchComponent } from '../job-create-from-scratch/job-create-from-scratch.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-job-list-empty',
  templateUrl: './job-list-empty.component.html',
  styleUrls: ['./job-list-empty.component.css']
})
export class JobListEmptyComponent {
  @Output() cancel = new EventEmitter<void>();

  ChooseHowYouWouldLike = false;

  constructor(private modal: NzModalService, private route: ActivatedRoute, private router: Router) { }


  OpenChooseHowYouWouldLiketoStart(): void {
    this.ChooseHowYouWouldLike = true;
  }
  CloseChooseHowYouWouldLiketoStart(): void {
    this.ChooseHowYouWouldLike = false;
  }





  OpenJobTEmplate(): void {
    const modalRef = this.modal.create({
      nzContent: JobCreateFromTemplateComponent,
      nzFooter: null,

    });
    this.ChooseHowYouWouldLike = false;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  }
  JobCreateScratch(): void {
    const modalRef = this.modal.create({
      nzContent: JobCreateFromScratchComponent,
      nzFooter: null,

    });
    this.ChooseHowYouWouldLike = false;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  }
}
