import { Component } from '@angular/core';

@Component({
  selector: 'app-rolemanagement',
  templateUrl: './rolemanagement.component.html',
  styleUrls: ['./rolemanagement.component.css']
})
export class RolemanagementComponent {
  selectedValue = null;
  checked7 = false;
  checked8 = false;
  newsurveys: boolean = false;
  selectedOption: string = 'None';
  selectedOption3: string = 'None';
  selectedOption5: string = '1';
  isOpen: boolean = false;
  isOpen3: boolean = false;
  isOpen5: boolean = false;
  options5: string[] = ['1', '2', '3', '4', '5', '6' , '7'];
  options: string[] = ['None', '1 Day', '2 Day', '3 Day', '7 Day', '14 Day', '21 Day' , '30 Day'];
  options3: string[] = ['None', '1 Day', '2 Day', '3 Day', '4 Day', '5 Day', '6 Day' , '7 Day'];




  selectOption5(option: string) {
    this.selectedOption = option;
    this.isOpen5 = false;
  }
  toggleDropdown5() {
    this.isOpen5 = !this.isOpen5;
  }

  newsurvey() {
    this.newsurveys = true;
}
customfieldinfo:boolean = false;
CustomFieldInfo(){
  this.customfieldinfo = true;
}
handleCancel2(): void {
  this.customfieldinfo = false;
}


}
