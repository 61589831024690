import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccessLocalStorageService {

  private readonly JOB_ID_KEY = 'SET_JOB_ID';
  private readonly JOB_NAME_KEY = 'SET_JOB_NAME';
  private readonly JOB_COLOR_KEY = 'SET_JOB_COLOR';

  private readonly LOGIN_USER_ID = 'LOGIN_USER_ID';
  private readonly login_NAME_KEY = 'LOGIN_NAME_ID';

  private readonly Selected_Filter_Id = 'Selected_Filter_Id';

  constructor() { }

  // Method to set the job ID and name in local storage
  setJobId(id: number): void {
    localStorage.setItem(this.JOB_ID_KEY, JSON.stringify(id));
  }

  setJobName(name: string): void {
    localStorage.setItem(this.JOB_NAME_KEY, name);
  }

  // Method to get the job ID and name from local storage
  getJobId(): number | null {

    const idString = localStorage.getItem(this.JOB_ID_KEY);
    return idString ? JSON.parse(idString) : null;

  }

  // Method to set the job color ID in local storage
  setJobColorId(colorId: number): void {  // Corrected method to set job color
    localStorage.setItem(this.JOB_COLOR_KEY, JSON.stringify(colorId));
  }

  // Method to get the job color ID from local storage
  getJobColorId(): number | null {
    const colorIdString = localStorage.getItem(this.JOB_COLOR_KEY);
    return colorIdString ? JSON.parse(colorIdString) : null;
  }

  getJobName(): string | null {
    return localStorage.getItem(this.JOB_NAME_KEY);
  }
  getUserLoginIdName(): string | null {
    return localStorage.getItem(this.login_NAME_KEY);
  }

  setUserLoginId(id: number): void {
    localStorage.setItem(this.LOGIN_USER_ID, JSON.stringify(id));
  }
  getUserLoginId(): number | null {
    const idString = localStorage.getItem(this.LOGIN_USER_ID);
    return idString ? JSON.parse(idString) : null;
  }

  setSelectedFilterId(id: number): void {
    localStorage.setItem(this.Selected_Filter_Id, JSON.stringify(id));
  }
  getSelectedFilterId(): number | null {
    const idString = localStorage.getItem(this.Selected_Filter_Id);
    return idString ? JSON.parse(idString) : null;
  }
}
