import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterSideMenuBarService {

  private filterVisibility = new BehaviorSubject<boolean>(false);
  private activeFilter = new BehaviorSubject<string>(''); // Track active filter
  private pageId = new BehaviorSubject<number>(0);

  getFilterVisibility(): Observable<boolean> {
    return this.filterVisibility.asObservable();
  }

  getActiveFilter(): Observable<string> {
    return this.activeFilter.asObservable();
  }

  getPageId(): Observable<number> {
    return this.pageId.asObservable();
  }

  toggleFilter(filter: string,pageId: number) {
    this.pageId.next(pageId);
    this.activeFilter.next(filter);
    this.filterVisibility.next(!this.filterVisibility.value);
  }
  
}