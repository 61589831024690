
  <nz-modal
    nzWidth="50%"
    [(nzVisible)]="isProposalCostCode"
    nzClosable="true"
    (nzOnCancel)="closebiilss()"
    [nzBodyStyle]="{
      background: '#f1f4fa',
      padding: '14px',
      'max-height': '600px',
      'overflow-y': 'auto'
    }"
    [nzStyle]="{ top: '15px' }"
    [nzFooter]="modalFooter"
    [nzTitle]="cost"
  >
    <ng-template #cost >
      <h3>Add Cost Code</h3>
    </ng-template>

    <ng-container *nzModalContent>
      <nz-card nzTitle="Add Cost Code">
        <div class="row">
          <p>
            Please Select Which cost codes you would like to add to the proposal
          </p>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="col-12">
              <label class="sub-text"> Cost Codes</label>
              <span class="text-danger">*</span>
              <nz-select
                nzAllowClear
                nzPlaceHolder="Select OS"
                nzMode="multiple"
                [nzCustomTemplate]="multipleTemplate"
              >
                <nz-option nzLabel="Luck" nzValue="Luck"></nz-option>
                <nz-option nzLabel="Joem" nzValue="Joem"></nz-option>
                <nz-option nzLabel="Hor" nzValue="Hor"></nz-option>
                <nz-option nzLabel="Windows" nzValue="windows"></nz-option>
                <nz-option nzLabel="Apple" nzValue="apple"></nz-option>
                <nz-option nzLabel="Android" nzValue="android"></nz-option>
              </nz-select>

              <ng-template #multipleTemplate let-selected>
                <div class="ant-select-selection-item-content">
                  <span nz-icon [nzType]="selected.nzValue"></span>
                  {{ selected.nzLabel }}
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </nz-card>
    </ng-container>
  </nz-modal>

  <ng-template #modalFooter>
    <button nz-button nzType="primary" class="rounded-1">Add</button>
  </ng-template>


