


<nz-page-header class="p-0">
    <nz-page-header-extra>
      <nz-space>

        <nz-switch class="mt-2" *nzSpaceItem  [(ngModel)]="switchValue1" ></nz-switch>
        <label  *nzSpaceItem class="mt-2" (click)="hideFeildmethod()">Cluster</label>
        
        <button *nzSpaceItem  nz-button nzType="default" (click)="mapCardModelOpen()" >
          Map All</button>
          <!-- (click)="MapCard()" -->
        <button *nzSpaceItem  nz-button nzType="default" (click)="openFilter(LEAD_OPPR_LIST_VIEW,PAGE_ID)" >
            <!-- (click)="mapOpenfilter()" -->
          <i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter (0)</button>
        <button *nzSpaceItem nz-button nzType="primary" (click)="newScheduleItemModalOpen()" >New Schedule Item</button>
      </nz-space>
    </nz-page-header-extra>
    
  </nz-page-header>


  