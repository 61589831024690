import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-time-clock-cost-code-editor',
  templateUrl: './time-clock-cost-code-editor.component.html',
  styleUrls: ['./time-clock-cost-code-editor.component.css']
})
export class TimeClockCostCodeEditorComponent {
  @Output() cancel = new EventEmitter<void>();
    // selectionModel
    Taghandle= true;
    TagClose(): void {
    this.cancel.emit();
    }
}
