<!-- drawer -->
<nz-drawer [nzClosable]="false" [nzVisible]="Taghandle" nzPlacement="right" [nzTitle]="customtt" [nzFooter]="footerTpl"
  nzCloseIconPlacement="right" nzWidth="70%" (nzOnClose)="mapClosefilter()" [nzBodyStyle]="{'background': '#f1f4fa',}">
  <ng-template #customtt>
    <div class="row m-0 p-0 ">
      <div class="col m-0 p-0">

        <nz-breadcrumb *ngIf="JOB_NAME?.JOB_NAME" nz-page-header-breadcrumb>
          <nz-breadcrumb-item>{{ JOB_NAME?.JOB_NAME}}</nz-breadcrumb-item>
        </nz-breadcrumb>
        <nz-breadcrumb *ngIf="leadData?.leadData?.name" nz-page-header-breadcrumb>
          <nz-breadcrumb-item>{{ leadData?.leadData?.name }}</nz-breadcrumb-item>
        </nz-breadcrumb>
        <h1 class="p-0 m-0 famly-inn fs-3">To-Do</h1>
      </div>

      <div class="col p-0 text-end me-2">
        <strong nz-icon nzType="close" (click)="mapClosefilter()" nzTheme="outline"></strong>
      </div>

    </div>
  </ng-template>
  <!-- Your HTML file -->

  <ng-container *nzDrawerContent>
<div class="top-of-modal mb-2"></div>
    <nz-alert
    class="mb-3 error-message"
    *ngIf="isSubmitted && errorFields.length > 0"
    nzType="error"
    nzShowIcon
    [nzMessage]="message"
    [nzDescription]="errorDescriptionTemplate"
  ></nz-alert>

  <ng-template #message>
    <p class="mt-1">Please correct the following fields:</p>
  </ng-template>

  <ng-template #errorDescriptionTemplate>
    <ul>
      <li class="sub-text" *ngFor="let error of errorFields">
        <span class="d-flex align-items-center">
          <p>{{ error.label }}:</p>
          <p>{{ error.message }}</p>
        </span>
      </li>
    </ul>
  </ng-template>
    <!-- <nz-alert nzType="error" nzMessage="Please correct the following fields:"
      nzDescription="This is an error message about copywriting." nzShowIcon class="mb-2"></nz-alert> -->
    <nz-alert *ngIf="showAlert" nzType="error" nzMessage="Please correct the following fields:"
      nzDescription="This is an error message about copywriting." nzShowIcon class="mb-2"></nz-alert>
    <div class="card-container" style="background-color: #f1f4fa;">
      <nz-tabset nzType="card">
        <nz-tab *ngFor="let tab of tabs" [nzTitle]="innerCardTabs(tab)" style="background-color: #f1f4fa;">
          <ng-container *ngIf="tab === 1">
            <!-- <app-project-management-site-diaries-to-do-information></app-project-management-site-diaries-to-do-information> -->

            <!-- stat -->
            <div class="row famly-inn">
              <div class="col-12">
                <div class="card border-0 mt-0">
                  <div class="card-head border-bottom">
                    <h5 nz-typography class="py-2 ms-3 famly-inn">To-Do Information</h5>
                  </div>
                  <form>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <!-- <label nz-checkbox
                          [(ngModel)]="newTodosFormFiedls.createToDoParameterRequest['isCompleted']"
                          name="isCompleted"
                          [disabled]="disableAddChecklistButton && disableAddChecklistButton2">
                          Complete
                       </label> -->
                      <span class="round">
                        <input
                                [disabled]="disableAddChecklistButton && disableAddChecklistButton2"
                               type="checkbox"
                               [id]="'checkbox-'" />
                        <label nz-popover
                                [disabled]=" disableAddChecklistButton2"
                               [for]="'checkbox-'"
                               [(ngModel)]="newTodosFormFiedls.createToDoParameterRequest['isCompleted']"
                               name="isCompleted">
                        </label>
                        <span class="ms-4 mt-2"
                        nz-popover
                        [nzPopoverContent]="DisableButtonPop"
                        [nzPopoverTrigger]="!disableAddChecklistButton2 ? null : 'hover'"
                        nzPopoverPlacement="top">
                      Complete
                  </span>

                        <ng-template #DisableButtonPop>
                            <div class="rounded-2">
                                <p style="width: 210px; font-size: 13px;">
                                    Company settings require you to complete all checklist items before completing a To-Do.
                                </p>
                            </div>
                        </ng-template>
                    </span>

                      </div>
                    </div>
                      <div class="row mt-3">
                        <div class="col-12">
                          <nz-alert *ngIf="showAlert2" nzType="info"
                            nzMessage="There are incomplete checklist items in this To-Do." nzShowIcon></nz-alert>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="row">
                            <div class="col-12">
                              <label class="sub-text">Title<span class="text-danger fw-bolder">*</span> </label>
                              <input
                               [(ngModel)]="newTodosFormFiedls.createToDoParameterRequest['title']"
                                name="title"
                                required
                                (ngModelChange)="onFieldChange('title', $event)"
                                [nzStatus]="validationStates['title']"
                                autocomplete="off"
                                nz-input

                                />
                                <div
                                *ngIf="validationStates['title'] === 'error'"
                                class="text-danger"
                              >
                                {{ validationMessages["title"] }}
                              </div>


                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <label class="sub-text">Notes</label>
                              <textarea class="formField"
                                [(ngModel)]="newTodosFormFiedls.createToDoParameterRequest['notes']"
                                 name="notes"
                                 required
                                 (ngModelChange)="onFieldChange('notes', $event)"
                                 [nzStatus]="validationStates['notes']"
                                 autocomplete="off"
                                 nz-input
                                 [nzAutosize]="{ minRows: 4, maxRows: 8 }"
                                 >
                              </textarea>
                              <div
                              *ngIf="validationStates['notes'] === 'error'"
                              class="text-danger"
                            >
                              {{ validationMessages["notes"] }}
                            </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12" *ngIf="showPriorityField">
                              <label class="sub-text">Priority</label>
                              <nz-select [(ngModel)]="newTodosFormFiedls.createToDoParameterRequest['prioritySetupId']"
                                name="prioritySetupId" style="width: 100%" nzShowSearch [lang]="true" nzAutoFocus="true"
                                nzPlaceHolder="Unassigned">
                                <nz-option *ngFor="let option of priorityOption" [nzLabel]="option.name"
                                  [nzValue]="option.id"></nz-option>
                              </nz-select>
                            </div>
                          </div>
                          <div class="row" *ngIf="showTagsField">
                            <div class="row">
                              <div class="col-9">
                                <label class="sub-text">Tags</label>
                                <nz-tree-select style="width: 100%"
                                  [(ngModel)]="newTodosFormFiedls.createToDoParameterRequest['createToDoInformationTagParameterRequests']"
                                  name="createToDoInformationTagParameterRequests" [nzNodes]="tagsNodes" nzShowSearch
                                  nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Status">
                                </nz-tree-select>
                              </div>
                              <div class="col-sm-3 mt-4 p-0">
                                <button nz-button nzType="link" (click)="showTags()">Add</button>
                                <button [disabled]="!newTodosFormFiedls.createToDoInformationTagParameterRequests"
                                  class="btn btn-sm text-primary border-0 me-1"
                                  (click)="showTagEdit(newTodosFormFiedls.createToDoInformationTagParameterRequests)">
                                  Edit
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="row" *ngIf="showReminderField">
                            <div class="col-12">
                                <label class="sub-text">Reminder</label>
                                <nz-select [(ngModel)]="newTodosFormFiedls.createToDoParameterRequest['reminderSetupId']"
                                  name="reminderSetupId" style="width: 100%" nzShowSearch [lang]="true" nzAutoFocus="true"
                                  nzPlaceHolder="Unassigned"
                                  >
                                  <nz-option *ngFor="let option of reminderOption" [nzLabel]="option.name"
                                  [nzValue]="option.id"></nz-option>
                              </nz-select>                                 
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-2 me-4" *ngIf="!showAttachments">
                              <button nz-button nzType="text" class="same-but">
                                <i class="fa-solid fa-paperclip me-1"></i><span
                                  (click)="toggleAttachments()">Attach</span>
                              </button>
                            </div>
                            <div class="col-2 me-4" *ngIf="!showPriorityField">
                              <button nz-button nzType="text" class="same-but" (click)="togglePriorityField()">
                                <i class="bx bx-error-circle bx-flip-vertical me-1"></i><span>Priority</span>
                              </button>

                            </div>
                            <div class="col-2 me-3" *ngIf="!showTagsField">
                              <button nz-button nzType="text" class="same-but" (click)="toggleTagsField()">
                                <i class="bx bx-purchase-tag-alt me-1"></i><span>Tags</span>
                              </button>
                            </div>
                            <!-- A** -->
                            <div class="col-3" *ngIf="!showReminderField">
                              <!-- <button nz-button nzType="text" class="same-but" (click)="toggleReminderField()">
                                <i class="fa-regular fa-bell me-1"></i><span class="fs-6">Reminder</span>
                              </button> -->
                              <button 
                              nz-button 
                              nzType="text" 
                              class="same-but" 
                              (click)="toggleReminderField()" 
                              [disabled]="!newTodosFormFiedls.createToDoParameterRequest['dueDate']">
                              <i class="fa-regular fa-bell me-1"></i><span class="fs-6">Reminder</span>
                            </button>                              
                            </div>
                            <!-- A** End -->
                          </div>

                        </div>
                        <div class="col-6">
                          <div class="row">
                            <div class="col-12">
                              <label class="sub-text">Assigned to</label>
                              <!-- <nz-tree-select [nzNodes]="combinedNodes"
                                [(ngModel)]="newTodosFormFiedls.createToDoParameterRequest['createToDoParameterAssignOwnersRequests']"
                                name="createToDoParameterAssignOwnersRequests" nzAllowClear="false" nzCheckable
                                nzCheckAll="true" (ngModelChange)="onNodeSelectionChange($event)">
                              </nz-tree-select> -->
                              <nz-tree-select
                              style="width: 100%"
                              [(ngModel)]="newTodosFormFiedls.createToDoParameterRequest['createToDoParameterAssignOwnersRequests']"
                              name="createToDoParameterAssignOwnersRequests"
                              [nzNodes]="combinedNodes"
                              [nzShowSearch]="true"
                              nzCheckable
                              nzPlaceHolder="Select CreatedBy"
                              nzDropdownClassName="dropdownStyle"
                              (ngModelChange)="onCombineChange($event)">
                              </nz-tree-select>
                              <label nz-checkbox><span class="pira-label">Notify new assigned users</span></label>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-12 mt-3">
                              <label class="sub-text">Deadline </label><br>
                              <nz-button-group>
                                <button nz-button
                                  [(ngModel)]="newTodosFormFiedls.createToDoParameterRequest['isChooseDate']"
                                  name="isChooseDate" nzType="default" (click)="toggleDateFields()">Choose Date</button>
                                <button nz-button nzType="default"
                                  [(ngModel)]="newTodosFormFiedls.createToDoParameterRequest['isLinkedScheduleItem']"
                                  name="isLinkedScheduleItem" (click)="toggleScheduleItemFields()">Link to add</button>
                              </nz-button-group>
                            </div>
                          </div>
                          <div class="row  mt-4" *ngIf="showDateFields">
                            <div class="col-4">
                              <label class="sub-text">Deadline</label>
                              <nz-date-picker nzPlaceHolder=" " nzSuffixIcon="false"
                                [(ngModel)]="newTodosFormFiedls.createToDoParameterRequest['dueDate']"
                                name="dueDate"></nz-date-picker>
                            </div>

                            <div class="col-4">
                              <label class="sub-text">Time</label><br>
                              <nz-time-picker [(ngModel)]="newTodosFormFiedls.createToDoParameterRequest['time']"
                                name="time" nzFormat="HH:mm" nzPlaceHolder=" "></nz-time-picker>
                            </div>
                          </div>

                          <div class="row  gx-0" *ngIf="showScheduleItemFields">
                            <div class="col-9 mt-4">
                              <label class="sub-text">Schedule Item <span class="text-danger">*</span> </label>
                              <nz-select
                                [(ngModel)]="newTodosFormFiedls.createToDoParameterRequest['scheduleItemParameterId']"
                                name="scheduleItemParameterId" style="width: 100%" nzShowSearch [lang]="true"
                                nzAutoFocus="true" nzPlaceHolder="Unassigned">
                                <nz-option *ngFor="let option of scheduleItemOption" [nzLabel]="option.title"
                                  [nzValue]="option.id">
                                </nz-option>
                              </nz-select>

                            </div>
                            <div class="col-3 mt-5 p-0">
                              <button nz-button nzType="link" (click)="openSchedule()">Add</button>
                              <button nz-button nzType="link" class="ps-0">Edit</button>
                            </div>

                          </div>
                          <div class="row  mt-3" *ngIf="showScheduleItemFields">
                            <div class="col-2">
                              <label class=" fw-bold">Due</label>
                              <input [(ngModel)]="newTodosFormFiedls.createToDoParameterRequest['dueNumberDays']"
                                name="dueNumberDays" type="text" class="form-control formField" placeholder="0">
                            </div>
                            <div class="col-7" style="margin-top: 27px;">
                              <label class=" fw-medium me-3" style="margin-left: -10px;">Days</label>
                              <nz-radio-group [(ngModel)]="radioValue" nzName="radiogroup">
                                <label [(ngModel)]="newTodosFormFiedls.createToDoParameterRequest['before']"
                                  name="before" nz-radio nzValue="A">Before</label>
                                <label [(ngModel)]="newTodosFormFiedls.createToDoParameterRequest['after']" name="after"
                                  nz-radio nzValue="B" style="margin-left: -5px;">After</label>
                              </nz-radio-group>
                            </div>
                          </div>
                          <div class="row  mt-3 mb-4" *ngIf="showScheduleItemFields">
                            <div class="col-2">
                              <label class="sub-text mb-1">DeadLine</label>
                              <p class="fw-bold">--</p>
                            </div>
                            <div class="col-3">
                              <label class="sub-text">Time</label><br>
                              <nz-time-picker [(ngModel)]="newTodosFormFiedls.createToDoParameterRequest['time']"
                                name="time" nzFormat="HH:mm" nzPlaceHolder=" "></nz-time-picker>
                            </div>
                          </div>

                        </div>

                        <div class="card border-0 mt-3">
                          <div class="card-head border-bottom" *ngIf="showAttachments">
                            <h3 class="p-0 m-0 pt-2 pb-2 ms-3 react" nz-typography>Attachments</h3>
                          </div>
                          <div class="card-body bt-file-viewer-card">
                            <div class="row">
                              <div class="col-12">
                                <button nz-button class="button-default react" *ngIf="showAttachments" (click)="uploadFiles()"><span>Add</span></button>
                                <button nz-button class="button-default react ms-2" *ngIf="showAttachments" (click)="CreateNewFile()"><span>Create New
                                    File</span></button>
                                <button *ngIf="selectedFiless?.length > 0" nz-button class="button-default react ms-2"
                                  (click)="viewAll()"><span>View All ({{ selectedFiless?.length }})</span></button>
                              </div>
                            </div>
                            <i *ngIf="showNavButtons" class="bx bx-chevron-left carousel-nav prev" (click)="previous()"></i>
                            <i *ngIf="showNavButtons" class="bx bx-chevron-right carousel-nav next" (click)="next()"></i>
                            <div class="carousel-container" #carouselContainer>
                              <div class="carousel-view" *ngFor="let fileq of selectedFiless">
                                <div class="bt-file-viewer">
                                  <img class="img-1" *ngIf="getFileIcon(fileq)" [src]="getFileIcon(fileq)" />
                                  <img (click)="onClick()" class="img-2" *ngIf="!getFileIcon(fileq) && !fileq.type.includes('video')"
                                    [src]="fileq.thumbUrl" />
                                  <ng-container *ngIf="fileq.type.includes('video')">
                                    <div class="video-container">
                                      <i nz-icon nzType="play-circle" nzTheme="outline" class="video-play-icon"
                                        (click)="playVideo(fileq)"></i>
                                      <video #videoPlayer id="video-{{ fileq.id }}" [src]="fileq.thumbUrl"
                                        (click)="playVideo(fileq)"></video>
                                    </div>
                                  </ng-container>
                                  <div nz-row class="action-row">
                                    <div nz-col nzSpan="24">
                                      <label class="BTFileUpload-SecondaryText p-0 m-0 ms-2">{{ fileq.name }}</label>
                                    </div>
                                    <div nz-col nzSpan="5">
                                      <i style="margin: 2px 0px 0px 6px;cursor: pointer;font-size: 21px;color: #626262;"
                                        nzTrigger="click" nz-dropdown [nzDropdownMenu]="menu4" class="bx bxs-chevron-down"></i>
                                      <nz-dropdown-menu #menu4="nzDropdownMenu">
                                        <ul nz-menu style="width: 170px;">
                                          <li nz-menu-item (click)="removeFile(fileq)">
                                            <div class="dropdown-container">
                                              <i style="cursor: pointer;font-size: 20px;color: #626262;" class='bx bx-trash'></i>
                                              <span class="react">Delete</span>
                                            </div>
                                          </li>
                                          <li nz-menu-item (click)="uploadFilesUpdate(fileq)" *ngIf="isImageFile(fileq)">
                                            <div class="dropdown-container">
                                              <i style="cursor: pointer;font-size: 20px;color: #626262;" class='bx bxs-pencil'></i>
                                              <span class="react">Edit Online</span>
                                            </div>
                                          </li>
                                        </ul>
                                      </nz-dropdown-menu>
                                    </div>
                                    <div nz-col nzSpan="4" *ngIf="isImageFile(fileq)">
                                      <i (click)="uploadFilesUpdate(fileq)" style="cursor: pointer;font-size: 26px;color: #626262;"
                                        class='bx bxs-pencil'></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <!-- A** -->
                        <!-- <div class="card border-0 mt-3">
                          <div class="card-head border-bottom" *ngIf="showAttachments">
                            <h3 class="p-0 m-0 pt-2 pb-2 ms-3 react" nz-typography>Attachments</h3>
                          </div>
                          <div class="card-body bt-file-viewer-card">
                            <div class="row">
                              <div class="col-12">
                                <button nz-button class="button-default react" *ngIf="showAttachments" (click)="uploadFiles()"><span>Add</span></button>
                                <button nz-button class="button-default react ms-2" *ngIf="showAttachments" (click)="CreateNewFile()"><span>Create New
                                    File</span></button>
                                <button *ngIf="selectedFiless?.length > 0" nz-button class="button-default react ms-2"
                                  (click)="viewAll()"><span>View All ({{ selectedFiless?.length }})</span></button>
                              </div>
                            </div>
                            <i *ngIf="showNavButtons" class="bx bx-chevron-left carousel-nav prev" (click)="previous()"></i>
                            <i *ngIf="showNavButtons" class="bx bx-chevron-right carousel-nav next" (click)="next()"></i>
                            <div class="carousel-container" #carouselContainer>
                              <div class="carousel-view" *ngFor="let fileq of selectedFiless">
                                <div class="bt-file-viewer">
                                  <img class="img-1" *ngIf="getFileIcon(fileq)" [src]="getFileIcon(fileq)" />
                                  <img (click)="onClick()" class="img-2" *ngIf="!getFileIcon(fileq) && !fileq.type.includes('video')"
                                    [src]="fileq.thumbUrl" />
                                  <ng-container *ngIf="fileq.type.includes('video')">
                                    <div class="video-container">
                                      <i nz-icon nzType="play-circle" nzTheme="outline" class="video-play-icon"
                                        (click)="playVideo(fileq)"></i>
                                      <video #videoPlayer id="video-{{ fileq.id }}" [src]="fileq.thumbUrl"
                                        (click)="playVideo(fileq)"></video>
                                    </div>
                                  </ng-container>
                                  <div nz-row class="action-row">
                                    <div nz-col nzSpan="24">
                                      <label class="BTFileUpload-SecondaryText p-0 m-0 ms-2">{{ fileq.name }}</label>
                                    </div>
                                    <div nz-col nzSpan="5">
                                      <i style="margin: 2px 0px 0px 6px;cursor: pointer;font-size: 21px;color: #626262;"
                                        nzTrigger="click" nz-dropdown [nzDropdownMenu]="menu4" class="bx bxs-chevron-down"></i>
                                      <nz-dropdown-menu #menu4="nzDropdownMenu">
                                        <ul nz-menu style="width: 170px;">
                                          <li nz-menu-item (click)="removeFile(fileq)">
                                            <div class="dropdown-container">
                                              <i style="cursor: pointer;font-size: 20px;color: #626262;" class='bx bx-trash'></i>
                                              <span class="react">Delete</span>
                                            </div>
                                          </li>
                                          <li nz-menu-item (click)="uploadFilesUpdate(fileq)" *ngIf="isImageFile(fileq)">
                                            <div class="dropdown-container">
                                              <i style="cursor: pointer;font-size: 20px;color: #626262;" class='bx bxs-pencil'></i>
                                              <span class="react">Edit Online</span>
                                            </div>
                                          </li>
                                        </ul>
                                      </nz-dropdown-menu>
                                    </div>
                                    <div nz-col nzSpan="4" *ngIf="isImageFile(fileq)">
                                      <i (click)="uploadFilesUpdate(fileq)" style="cursor: pointer;font-size: 26px;color: #626262;"
                                        class='bx bxs-pencil'></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> -->




                        
                         <!-- A** End -->

                      </div>
                      <ng-container *ngIf="holdHeaderId">
                        <messaging-common
                          [Job_ID]="jobID"
                          [Form_Name_ID]="formNameID"
                          [Message_header_ID]="messagingId"
                          [Message_headerSet_ID]="messagingIdRow"
                           >
                        </messaging-common>
                      </ng-container>

                    </div>

                  </form>
                </div>
              </div>
            </div>

            <!-- end -->

          </ng-container>
          <ng-container *ngIf="tab === 2">
            <!-- <app-project-management-site-diaries-checklist></app-project-management-site-diaries-checklist> -->

            <div class="row famly-inn">
              <div class="col-12">
                <div class="card border-0 mt-0">
                  <div class="card-head border-bottom">
                    <div class="row py-2">
                      <div class="col-6">
                        <h5 nz-typography class="ms-3 famly-inn">Checklist Items</h5>
                      </div>
                      <div class="col-6 text-end">
                        <button
                        (click)="completeAll()"
                        nz-button
                        nzType="default"
                        class="rounded-1 me-2"
                        [hidden]="allChecked || toDoCheckListItems.length === 0">
                        Complete All
                    </button>
                      <button nz-button nzType="default" class="rounded-1 me-2" (click)="toggleChecklist()">
                        {{ addingChecklist ? 'Add Checklist' : 'Remove Checklist' }}
                        <span *ngIf="!addingChecklist" (click)="ConfirmDelete()"></span>
                     </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body align-content-center">
                    <div class="row gx-2 ">
                      <div class="col-12">
                        <div class="row gx-1" *ngFor="let item of toDoCheckListItems ; let i=index">
                          <div class="col-1 image">
                            <img src="assets/drag.png">
                            <!-- <label class="ms-1 mt-1" nz-checkbox></label> -->
                            <!-- <div class="round">
                              <input  [(ngModel)]="item.isComplete" name="isComplete_{{i}}" type="checkbox"
                                checked id="checkbox" />
                              </div> -->
                              <span class="round">
                                <input type="checkbox" [id]="'checkbox-' + i" [checked]="item.isComplete" (change)="toggleCheckbox(item)" />
                                <label [for]="'checkbox-' + i" (change)="checkAllChecked()"></label>
                            </span>
                              <!-- <label class="ms-1 mt-1" (change)="checkAllChecked()" nz-checkbox [(ngModel)]="item.isComplete" name="isComplete_{{i}}" ></label> -->
                          </div>
                          <!-- <div class="col-10">
                            <div class="row"> -->
                          <!-- your-component.component.html -->

                          <div class="col-10 mb-2" *ngIf="!showDropdown">
                            <input [(ngModel)]="item.name" name="name_{{i}}" type="text" class="formField">
                          </div>

                          <div class="col-7 mb-2" *ngIf="showDropdown">
                            <input [(ngModel)]="item.name" name="name_{{i}}" type="text" class="formField">
                          </div>

                          <div class="col-3 mb-2" *ngIf="showDropdown">
                            <nz-select [(ngModel)]="selectedAssignee" name="assignee" nzShowSearch [lang]="true"
                              nzAutoFocus="true" nzPlaceHolder="--None--" (ngModelChange)="onAssigneeChange($event)">
                              <optgroup label="Option Group 1">
                                <nz-option disabled nzLabel="Application User" [nzValue]="''"></nz-option>
                                <nz-option *ngFor="let option of assignedUserGroup1" [nzLabel]="option.label"
                                  [nzValue]="{ assigneeUserId: option.value, assigneeSubVendorId: null}">
                                </nz-option>
                              </optgroup>
                              <optgroup label="Option Group 2">
                                <nz-option disabled nzLabel="Sub/Venders" [nzValue]="''"></nz-option>
                                <nz-option *ngFor="let option of assignedUserGroup2" [nzLabel]="option.label"
                                  [nzValue]="{ assigneeUserId: null, assigneeSubVendorId: option.value}">
                                </nz-option>
                              </optgroup>
                            </nz-select>
                          </div>

                          <div class="col-1">
                            <button nzTrigger="click" nz-dropdown [nzDropdownMenu]="menu4" nzPlacement="bottomLeft"
                              nz-button nzType="text" class="same-but"><i
                                class="fa-solid fa-ellipsis-vertical me-1"></i></button>
                            <nz-dropdown-menu #menu4="nzDropdownMenu">
                              <ul nz-menu>
                                <li nz-menu-item (click)="toggleDropdown()">
                                  <i class="fa fa-light fa-users me-2"></i>
                                  <span *ngIf="!showDropdown">Add Assignees</span>
                                  <span *ngIf="showDropdown">Remove Assignees</span>
                                </li>
                                <li nz-menu-item (click)="showAttachmentbuttons()">
                                  <i class="fa-solid fa-paperclip me-2"></i>
                                  <span *ngIf="showAddButton">Add Attachments</span>
                                  <span *ngIf="!showAddButton">Remove Attachments</span>
                                </li>
                                <li (click)="deleteRow(index)" nz-menu-item><i
                                    class="fa-regular fa-trash-can me-2"></i>Delete Item</li>
                              </ul>
                            </nz-dropdown-menu>
                          </div>



                          <!-- attachment -->
                          <!-- <div class="row" *ngIf="!showAddButton">
                            <div class="col-1"></div>
                            <div class="col-11">
                              <div class="row p-0 rounded-1">
                                <h4 class="fw-bold famly-inn mb-3">Attachments</h4>
                                <div class="d-flex align-items-center">

                                </div>
                                <div class="row">
                                  <div class="col-5">
                                    <button (click)="UploadFiles()" nz-button nzType="default"
                                      class="rounded-1 me-2">Add</button>
                                    <button (click)="CreateNewFile()" nz-button nzType="default"
                                      class="rounded-1 me-2">Create New
                                      File</button>
                                    <button (click)="viewAll()" *ngIf="selectedFiles.length > 0" nz-button
                                      nzType="default" class="rounded-1 me-2">View All ({{ selectedFiles.length
                                      }})</button>
                                  </div>
                                </div>
                                <div class="row mt-3">
                                  <div class="col-1 filUpload me-3" *ngFor="let file of selectedFiles">
                                    <div class="row gx-0 mt-2 p-0 m-0">
                                      <div class="col-12 mt-2">
                                        <span class="m-0 p-0d d-flex align-items-center justify-content-center"
                                          style="border-radius: 6px; object-fit: cover;" nz-icon nzType="file-text"
                                          nzTheme="outline"></span>
                                      </div>
                                    </div>
                                    <div class="row p-0 m-0">
                                      <div class="col-12">
                                        <label class="ListViewJobname">{{file.name}}</label>
                                      </div>
                                    </div>
                                    <div class="row p-0 m-0">
                                      <div class="col-12">
                                        <i nzTrigger="click" nzType="down" nz-dropdown [nzDropdownMenu]="menu4"
                                          class="fa-solid fa-chevron-down fw-bolder fs-6"></i>
                                        <nz-dropdown-menu #menu4="nzDropdownMenu">
                                          <ul nz-menu>
                                            <li nz-menu-item><i class="fa-solid fa-pen me-2"></i>Edit Online</li>
                                            <li (click)="removeFile(file)" nz-menu-item><i
                                                class="fa-regular fa-trash-can me-2"></i>Delete</li>
                                          </ul>
                                        </nz-dropdown-menu>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> -->
                          <!-- attachment -->


                        </div>

                      </div>
                    </div>
                    <div class="row" *ngIf="toDoCheckListItems.length > 0">
                      <div class="col-4">
                        <button (click)="addNewRow()" nz-button nzType="link" class="text-primary fs-6">
                            <i class="bx bxs-plus-circle text-primary me-2 fs-5"></i>
                            <span class="fs-6">Add Checklist Item</span>
                        </button>
                    </div>

                    </div>
                    <div class="row" *ngIf="toDoCheckListItems.length === 0; isCheckboxChecked11">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12 d-flex align-content-center justify-content-center">
                            <i class="fa-regular fa-circle-check"></i>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 d-flex align-content-center justify-content-center">
                            <h3 nz-typography class="famly-inn">Give your To-Do's more power</h3>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-12 d-flex align-content-center justify-content-center">
                            <label class="para-label">Add checklist items to assign specific and detailed tasks from
                              your</label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 d-flex align-content-center justify-content-center">
                            <label class="para-label">To-Do.</label>
                          </div>
                        </div>
                        <div class="row mt-2 mb-3 d-flex align-content-center justify-content-center">
                          <div class="col-2">
                            <button (click)="addNewRow()" nz-button class="ms-2 button-Daily2">Add Checklist</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </ng-container>
          <ng-container *ngIf="tab === 3">
            <!-- <app-project-management-site-diaries-related-items></app-project-management-site-diaries-related-items> -->

            <div class="row famly-inn gx-3">
              <div class="col-12">
                  <div class="card border-0 mt-0">
                    <div class="card-head border-bottom">
                          <h5 nz-typography class=" ms-3 py-2 famly-inn">Related  Items</h5>
                      </div>
                    <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <label class="famly-inn fw-medium fs-6">Schedule Items</label>
                        </div>
                        <div class="row">
                          <div class="ListViewJobname mt-2 fs-6">Schedule items available after save.</div>
                        </div>
                        <div class="row">
                          <label class="famly-inn fw-medium fs-6">Daily Logs</label>
                        </div>
                        <div class="row">
                          <div class="ListViewJobname mt-2 fs-6">Daily Logs available after save.</div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-12">
                            <button nz-button nzType="default" class="rounded-1 me-0">Save and Create To-Do</button>
                      </div>
                      </div>
                    </div>
                    </div>
                  </div>
              </div>
              </div>
          </div>
          </ng-container>
        </nz-tab>
      </nz-tabset>





    </div>
  </ng-container>



  <ng-template #footerTpl>
    <!-- <button *ngIf="responseToDo" nz-button class="button-Daily" class="button-Daily"

          (click)="createMessage()">  Update
        </button> -->
    <div style="float: right; padding: 12px;">

      <button nz-button style="margin-right: 8px; border-radius: 7px;" nz-dropdown [nzDropdownMenu]="menu2"
        nzTrigger="click">
        <i class="bi bi-three-dots"></i>
      </button>

      <nz-button-group >
        <button nz-button class="button-Daily" (click)="saveButton()"  > Save & Close
        </button>

        <button nz-button class="button-Daily" nz-dropdown [nzDropdownMenu]="menu1" nzTrigger="click">
          <span nz-icon nzType="down"></span>
        </button>
      </nz-button-group>
      <nz-dropdown-menu #menu1="nzDropdownMenu">
        <ul nz-menu >
          <li *ngIf="responseToDo" nz-menu-item (click)="createMessage()" class="fw-bold">Save Message</li>
          <li nz-menu-item class="fw-bold">Save & New</li>
          <li nz-menu-item (click)="saveButton()" class="fw-bold"> Save</li>
        </ul>
      </nz-dropdown-menu>
      <nz-dropdown-menu #menu2="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item>Print</li>
          <!-- <li disabled nz-menu-item>Delete</li> -->
          <li nz-menu-item *ngIf="showDeleteButton" (click)="deleteToDoConfirm()">Delete</li> <!-- Bind the click event here -->
          <li disabled nz-menu-item>Copy</li>
        </ul>
      </nz-dropdown-menu>

    </div>



  </ng-template>
</nz-drawer>
<!-- drawer -->


<nz-modal nzWidth="35%" [(nzVisible)]="isTagVisible" [nzTitle]="CategoryTitle" [nzFooter]="divsionTradeFooter"
  [nzBodyStyle]="{ background: '#f1f4fa', padding: '14px' }" [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="showConfirmData()">
  <ng-template #CategoryTitle>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0">Add Selection Category</h1>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <form [formGroup]="tagsField">
      <nz-card [nzBorderless]="false" nzTitle="Add Selection Category">
        <div class="row">
          <div class="col">
            <label class="sub-text">Title<span class="text-danger fw-bolder">*</span></label>
            <input id="inputField" formControlName="name" type="text" class="form-control formField" [ngClass]="{
                error:
                  tagsField.get('name')?.invalid &&
                  tagsField.get('name')?.touched
              }" />
            <div *ngIf="
                tagsField.get('name').hasError('required') &&
                tagsField.get('name').touched
              " class="text-danger p-error">
              Required
            </div>
          </div>
        </div>
      </nz-card>
    </form>
  </div>
  <ng-template #divsionTradeFooter>
    <div class="mt-2 align-items-center">
      <button *ngIf="isLoadingEdit" nz-button nzType="default" class="rounded-1 me-0" (click)="ConfirmDeleteTags()">
        Delete
      </button>

      <button nz-button nzType="primary" class="rounded-1 me-0" (click)="SaveOrUpdateData()">
        {{ isLoadingEdit ? "Update" : "Save" }}
      </button>



    </div>
  </ng-template>
</nz-modal>
