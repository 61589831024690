<nz-layout class="p-0 m-0 famly-inn">
    <nz-header class="p-0 m-0 Unstuck" style="position: sticky; top: 0; z-index: 1;">
        <div class="row ms-3 mt-1">
            <h1 class="p-0 m-0 mt-3 fw-bold fs-3">Time Clock</h1>
        </div>
    </nz-header>
    <nz-content>
            <div class="card border-0 mt-4">
                <div class="card-head border-bottom">
                    <div class="row mb-0 p-2 d-flex align-items-center">
                        <div class="col">
                            <span class="fw-bold fs-5 ms-2">General Defaults</span>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="row">
                                    <label class="sub-text me-2">Week Start</label>
                                </div>
                                <div class="row">
                                    <div class="col-2">
                                        <nz-select nzShowSearch [(ngModel)]="companytimeClockForm.weekStartId"
                                            name="weekStartId">
                                            <nz-option *ngFor="let option of WeekStart" [nzLabel]="option.name"
                                                [nzValue]="option.id"></nz-option>
                                        </nz-select>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="row">
                                    <label class="sub-text me-2">Hourly Rate Tracking</label>
                                </div>
                                <div class="row">
                                    <div class="col-2">
                                        <nz-select nzShowSearch [(ngModel)]="companytimeClockForm.hourlyRateTrackingId"
                                            name="hourlyRateTrackingId">
                                            <nz-option *ngFor="let option of hourlyRateTracking" [nzLabel]="option.name"
                                                [nzValue]="option.id"></nz-option>
                                        </nz-select>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class=" col-9 d-flex align-items-center">
                                    <label class="ms-1" nz-checkbox
                                        [(ngModel)]="companytimeClockForm['useFormattingOTimeClockTotals']"
                                        name="useFormattingOTimeClockTotals">Use HH:MM formatting on time
                                        clock totals.</label>
                                    <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover
                                        [nzPopoverContent]="enable" nzPopoverPlacement="top"></i>
                                    <ng-template #enable>
                                        <div class="rounded-2">
                                            <p style="height: 75px; width: 210px; font-size: 13px;">
                                                The HH:MM format will display time clock totals as 25:45. Otherwise
                                                totals
                                                will display in decimal format 25.75 (hrs).
                                            </p>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class=" col-9 d-flex align-items-center">
                                    <label class="ms-1" nz-checkbox
                                        [(ngModel)]="companytimeClockForm['whenAJobIsDeletedPromptToDeleteCorrespondingTimeCardItems']"
                                        name="whenAJobIsDeletedPromptToDeleteCorrespondingTimeCardItems">When a job is
                                        deleted, prompt to delete corresponding time card items.
                                    </label>
                                    <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover
                                        [nzPopoverContent]="any" nzPopoverPlacement="top"></i>
                                    <ng-template #any>
                                        <div class="rounded-2">
                                            <p style="height: 52px; width: 210px; font-size: 13px;">
                                                When unchecked, any recorded shifts on deleted job will be transferred
                                                to
                                                General.
                                            </p>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class=" col-9 d-flex align-items-center">
                                    <label class="ms-1" nz-checkbox
                                        [(ngModel)]="companytimeClockForm['sendOneTimeNotificationToInternalUsersThatHaveBeenClockedInForOverHours']"
                                        name="sendOneTimeNotificationToInternalUsersThatHaveBeenClockedInForOverHours">Send
                                        one time notification to internal users that have been clocked in for over 12
                                        hours.
                                    </label>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-9 d-flex align-items-center">
                                    <label class="ms-1 mb-1" nz-checkbox> </label>
                                    <label for="To1" class="pira-label ms-2 me-2">Automatically end breaks after
                                        <nz-input-number style="width: 100px; border-radius: 4px;"
                                            [(ngModel)]="companytimeClockForm['automaticallyEndBreaksAfterMinutesNo']"
                                            name="automaticallyEndBreaksAfterMinutesNo" [nzMin]="1" [nzMax]=""
                                            [nzStep]="1"></nz-input-number>
                                        <label class="pira-label ms-2">minutes.</label></label>
                                    <i class="bi bi-info-circle-fill text-secondary ms-1 mb-2" nz-popover
                                        [nzPopoverContent]="minutes" nzPopoverPlacement="top"></i>
                                    <ng-template #minutes>
                                        <div class="rounded-2">
                                            <p style="height: 180px; width: 210px; font-size: 13px;">
                                                When checked, time clock breaks will automatically end. User will choose
                                                to
                                                take a break and the break will end after the set break length expires
                                                and
                                                their work time will resume. This option will allow users to quickly
                                                indicate when they are taking an unpaid break without having to manually
                                                end
                                                the break.
                                            </p>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class=" col-9 d-flex align-items-center">
                                    <label class="ms-1" nz-checkbox [(ngModel)]="companytimeClockForm['showGeneralJob']"
                                        name="showGeneralJob">Show General Job.
                                    </label>
                                    <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover
                                        [nzPopoverContent]="shifs" nzPopoverPlacement="top"></i>
                                    <ng-template #shifs>
                                        <div class="rounded-2">
                                            <p style="height: 55px; width: 210px; font-size: 13px;">
                                                Any shifts in the General Job will remain and can be accessible by
                                                viewing
                                                all jobs list.
                                            </p>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card border-0 mt-3">
                <div class="card-head border-bottom">
                    <div class="row mb-0 p-2 d-flex align-items-center">
                        <div class="col">
                            <span class="fw-bold fs-5 ms-2">Overtime Defaults</span>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <nz-alert *ngIf="showAlert" nzType="warning"
                                    nzMessage="Changes made to Overtime Defaults will affect existing overtime totals if existing shifts are edited."
                                    nzShowIcon></nz-alert>
                            </div>
                            <div class="row mt-3">
                                <div class=" col-9 d-flex align-items-center">
                                            <span class="ms-2">
                                        <input [(ngModel)]="companytimeClockForm['showOvertime']" name="showOvertime" (click)="toggleFields()"
                                        class="form-check-input " type="checkbox" value="" aria-label="...">
                                        Show double overtime.
                                        </span>
                                </div>
                            </div>
                            <div class="row ms-5" *ngIf="!isCheckboxChecked11">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="row mt-3">
                                                <div class=" col-9 d-flex align-items-center">
                                                    <label *ngIf="companytimeClockForm['showOvertime']" class="ms-2" (change)="onCheckboxChange13()" nz-checkbox
                                                    [(ngModel)]="companytimeClockForm['employeesAccrueDailyOvertime']"
                                                    name="employeesAccrueDailyOvertime"> Employees accrue daily
                                                    overtime.</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row ms-3" *ngIf="isCheckboxChecked13">
                                        <div class="row">
                                            <div class="col-4">
                                                <label class="sub-text me-1">After # of Hours <span
                                                        class="fw-bolder text-danger">*</span> </label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-1">
                                                <nz-input-number style="width: 100px; border-radius: 4px;" [nzMin]="1"
                                                    [nzMax]="" [nzStep]="1"></nz-input-number>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="row mt-3">
                                                <div class=" col-9 d-flex align-items-center">
                                                    <label *ngIf="companytimeClockForm['showOvertime']" (change)="onCheckboxChange14()" class="ms-2" nz-checkbox
                                                    [(ngModel)]="companytimeClockForm['employeesAccrueWeeklyOvertime']"
                                                    name="employeesAccrueWeeklyOvertime">Employees accrue weekly
                                                    overtime.</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row ms-3" *ngIf="isCheckboxChecked14">
                                        <div class="row">
                                            <div class="col-4">
                                                <label class="sub-text me-1">After # of Hours <span
                                                        class="fw-bolder text-danger">*</span> </label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-1">
                                                <nz-input-number style="width: 100px; border-radius: 4px;" [nzMin]="1"
                                                    [nzMax]="" [nzStep]="1"></nz-input-number>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="row">
                                            <div class="col-4">
                                                <label class="sub-text me-1">Overtime Days</label>
                                                <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover
                                                    [nzPopoverContent]="text" nzPopoverPlacement="top"></i>
                                                <ng-template #text>
                                                    <div class="rounded-2">
                                                        <p style="height: 30px; width: 210px; font-size: 13px;">
                                                            Hours are always overtime on these days.
                                                        </p>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-3">
                                                <nz-tree-select name="activityStatus"
                                                    [nzDropdownStyle]="{ 'max-height': '250px', 'overflow-y': 'auto' }"
                                                    [nzNodes]="overTimeNodes" nzShowSearch nzCheckable></nz-tree-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="row mt-3">
                                        <div class=" col-9 d-flex align-items-center">
                                            <label class="ms-2" (click)="onCheckboxChange12()" nz-checkbox
                                                [(ngModel)]="companytimeClockForm['showDoubleOvertime']"
                                                name="showDoubleOvertime">Show double overtime.</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ms-5" *ngIf="isCheckboxChecked12">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="row mt-3">
                                                <div class=" col-9 d-flex align-items-center">
                                                    <label class="ms-2" (change)="onCheckboxChange15()"
                                                        nz-checkbox>Employees accrue daily double overtime</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row ms-3" *ngIf="isCheckboxChecked15">
                                        <div class="row">
                                            <div class="col-4">
                                                <label class="sub-text me-1">After # of Hours <span
                                                        class="fw-bolder text-danger">*</span> </label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-1">
                                                <nz-input-number style="width: 100px; border-radius: 4px;" [nzMin]="1"
                                                    [nzMax]="" [nzStep]="1"></nz-input-number>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="row mt-3">
                                                <div class=" col-9 d-flex align-items-center">
                                                    <label class="ms-2" (change)="onCheckboxChange16()"
                                                        nz-checkbox>Employees accrue weekly double overtime</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row ms-3" *ngIf="isCheckboxChecked16">
                                        <div class="row">
                                            <div class="col-4">
                                                <label class="sub-text me-1">After # of Hours <span
                                                        class="fw-bolder text-danger">*</span> </label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-1">
                                                <nz-input-number style="width: 100px; border-radius: 4px;" [nzMin]="1"
                                                    [nzMax]="" [nzStep]="1"></nz-input-number>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="row">
                                            <div class="col-4">
                                                <label class="sub-text me-1">Double Overtime Days</label>
                                                <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover
                                                    [nzPopoverContent]="Hours" nzPopoverPlacement="top"></i>
                                                <ng-template #Hours>
                                                    <div class="rounded-2">
                                                        <p style="height: 30px; width: 210px; font-size: 13px;">
                                                            Hours are always double overtime on these days.
                                                        </p>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-3">
                                                <nz-tree-select name="activityStatus"
                                                    [nzDropdownStyle]="{ 'max-height': '250px', 'overflow-y': 'auto' }"
                                                    [nzNodes]="doubleOvertimeDays" nzShowSearch
                                                    nzCheckable></nz-tree-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card border-0 mt-3">
                <div class="card-head border-bottom">
                    <div class="row mb-0 p-2 d-flex align-items-center">
                        <div class="col">
                            <span class="fw-bold fs-6 ms-2">Geofencing</span>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <span class="sub-text me-2 fw-bold" style="font-size: 12px;">Set a geofence radius and
                                receive
                                notifications when a user clocks in or out of a job beyond the geofence. See Internal
                                User
                                details to turn this on for each user.</span>

                            <div class="row mt-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="sub-text me-2">Select Radius</label>
                                        <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover
                                            [nzPopoverContent]="will" nzPopoverPlacement="top"></i>
                                        <ng-template #will>
                                            <div class="rounded-2">
                                                <p style="height: 30px; width: 210px; font-size: 13px;">
                                                    Changes made will only apply to any new Time Clock shifts
                                                </p>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="row gx-0 ms-2">
                                    <div class="col-2">
                                          
                                            <nz-select *ngIf="isdropDownChange" nzShowSearch [(ngModel)]="companytimeClockForm.radiusId" name="radiusId">
                                                <nz-option *ngFor="let option of selectRadiusk" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                                              </nz-select>
                                              
                                              <nz-select *ngIf="!isdropDownChange" nzShowSearch [(ngModel)]="companytimeClockForm.radiusId" name="radiusId">
                                                <nz-option *ngFor="let option of selectRadiusm" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                                              </nz-select>
                                    </div>
                                    <div class="col-1">
                                        <nz-select nzShowSearch [(ngModel)]="companytimeClockForm['radiusUnitId']" name="radiusUnitId" (ngModelChange)="dropDownChange()">
                                            <nz-option *ngFor="let option of optionKilometers" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                                          </nz-select>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <label class="ms-2" nz-checkbox
                                    [(ngModel)]="companytimeClockForm['defaultTimeClockGeofencingToOnForAllNewJobs']"
                                    name="defaultTimeClockGeofencingToOnForAllNewJobs"> Default Time Clock Geofencing to
                                    ON for all new jobs</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      
    </nz-content>
    <nz-footer class="p-2 Unstuck">
        <button nz-button nzType="primary" class="rounded-1" (click)="companyTimeClockSave()">Save</button>
    </nz-footer>
</nz-layout>