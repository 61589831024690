import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { JobInformationResponse } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';

@Component({
  selector: 'app-to-dos-import-data-from-template',
  templateUrl: './to-dos-import-data-from-template.component.html',
  styleUrls: ['./to-dos-import-data-from-template.component.css']
})
export class ToDosImportDataFromTemplateComponent implements OnInit{

  constructor(  
    public localStorage: AccessLocalStorageService,
    @Inject(NZ_MODAL_DATA) public data: any,
    @Inject(NZ_MODAL_DATA) public JobData: any,
){}
  ngOnInit(): void {
    this.localStorage.getJobId();
    this.localStorage.getJobName();
  }
  @Output() cancel = new EventEmitter<void>();
    // selectionModel
    Taghandle: boolean =true;
    
    TagClose(): void {
    this.cancel.emit();
    }

    isCheckboxChecked11 = false;

    onCheckboxChange11() {
      this.isCheckboxChecked11 = !this.isCheckboxChecked11;
  }
    isCheckboxChecked12 = false;

    onCheckboxChange12() {
      this.isCheckboxChecked12 = !this.isCheckboxChecked12;
  }
    isCheckboxChecked13= false;

    onCheckboxChange13() {
      this.isCheckboxChecked13 = !this.isCheckboxChecked13;
  }
    isCheckboxChecked14 = false;

    onCheckboxChange14() {
      this.isCheckboxChecked14 = !this.isCheckboxChecked14;
  }
    isCheckboxChecked15 = false;

    onCheckboxChange15() {
      this.isCheckboxChecked15 = !this.isCheckboxChecked15;
  }
    isCheckboxChecked16 = false;

    onCheckboxChange16() {
      this.isCheckboxChecked16 = !this.isCheckboxChecked16;
  }
    isCheckboxChecked17 = false;

    onCheckboxChange17() {
      this.isCheckboxChecked17 = !this.isCheckboxChecked17;
  }
    isCheckboxChecked18 = false;

    onCheckboxChange18() {
      this.isCheckboxChecked18 = !this.isCheckboxChecked18;
  }
    isCheckboxChecked19 = false;

    onCheckboxChange19() {
      this.isCheckboxChecked19 = !this.isCheckboxChecked19;
  }
  }
