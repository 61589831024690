import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { LeadActivityResponse } from 'src/Models/LeadActivityTemplate/LeadActivityTemplate';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';

@Component({
  selector: 'app-lead-opportunity-import-template',
  templateUrl: './lead-opportunity-import-template.component.html',
  styleUrls: ['./lead-opportunity-import-template.component.css']
})
export class LeadOpportunityImportTemplateComponent {
  @Output() cancel = new EventEmitter<void>();
  @Output() importTemplate = new EventEmitter<LeadActivityResponse[] | LeadActivityResponse>();
  @Output() activityImported = new EventEmitter();


  isActivityTemVisible = true;
  formFieldValues: any = {};
  assignedUser: { label: string; value: string }[] = [];
  AllUser: { label: string; value: string }[] = [];


  constructor(private LeadOpportunitiesService: LeadOpportunitiesService,
    private customerInformationService: CustomerInformationService,
    public toastService: NzMessageService,
    @Inject(NZ_MODAL_DATA) public isLeadTemplate: any,
  ) {

  }

  ngOnInit(): void {
    this.ForCombo()
    this.LeadOpportunitiesService.getDataLeadTemplte().subscribe((res) => {
      const assignedUser = res.result;
      this.assignedUser = assignedUser.map((AUser) => ({
        label: AUser.name,
        value: AUser.id.toString(),
      }));
    })
  
    
    this.formFieldValues = {
      leadOpportunityId: this.isLeadTemplate.isLeadTemplate.id,
      leadActivityTemplateId: '',
      activityOn: new Date (),
      startTime: new Date(),
      assignAllTo: '',
    };
  }
  

  saveImportButton() {
    let data = this.formFieldValues;
    data.leadActivityTemplateId = data.leadActivityTemplateId.toString();

    console.log('FORM FIELD VALUES ', data);
    this.LeadOpportunitiesService.PostimportTemplate(data).subscribe(
      (response) => {
        console.log('Data saved successfully:', response);
        data.id = response.result.id;
        this.isActivityTemVisible = false
        this.toastService.success('New Lead Saved Successfully!');
        this.importTemplate.emit(response.result); // Emit the array directly
        this.cancel.emit();
      },
      (error) => {
        console.error('Error saving lead:', error);
      }
    );
  }

  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      const AllUser = res.result;
      this.AllUser = AllUser.map((AUser) => ({
          label: AUser.fullName,
          value: AUser.id.toString(),
      }));
  
      // Set initial value to the first item in the AllUser array
      if (this.AllUser.length > 0) {
          this.formFieldValues['assignAllTo'] = this.AllUser[0].value;
      }
  });
}
  



handleActivityTemCancel(): void {
  this.cancel.emit();
}


completeImport(activity: LeadActivityResponse) {
  this.activityImported.emit(activity);
}

}

