<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form [formGroup]="financialownerinvoiceFilterForm" (ngSubmit)="onSubmit()">
    <div class="row mt-2">
      <div class="col-10 p-0">
        <div class="input-group">
          <nz-select formControlName="standardFilter" name="standardFilter"
            (ngModelChange)="onChangeStandardFilter($event)" nzShowSearch [nzAutoFocus]="true"
            nzPlaceHolder="Standard Filter" r>
            <nz-option *ngFor="let filter of filterResponseData"
              [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')" [nzValue]="filter.id" nzCustomContent>
              <div class="option-content">
                <!-- Customize content with icons and text -->
                <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
                <span>{{ filter.name }}</span>
                <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

              </div>
            </nz-option>
          </nz-select>
        </div>
      </div>
      <div class="col-1 mt-1">
        <i
        nz-popover
        [nzPopoverContent]="contentTemplate"
        [nzPopoverPlacement]="'top'"
        class="bi bi-three-dots" (click)="openStandardFilterComponent()"></i>
      </div>
    </div>


    <!-- <div class="row  d-flex justify-content-between align-items-center">
            <div class="col-10 p-0 ">
                <div class="input-group">
                    <nz-select nzPlaceHolder="Standard Filter">
                        <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
                        <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
                    </nz-select>
                </div>
            </div>
            <div class="col-2 text-end p-0">
                <button nz-button class="border-0">
                    <span nz-popover class="text-primary text-dark fw-bold fs-5" nz-icon nzType="ellipsis"
                        nzTheme="outline"></span>
                </button>
            </div>
        </div> -->
    <div class="row famly-inn">
      <div class="col-12">


        <div class="row">
          <div class="col p-0 mt-0">
            <label class="sub-text">Keyword Search</label>
            <input formControlName="keywordSearch" type="text" class="form-control formField">
          </div>
        </div>


        <div class="row">
          <div class="col p-0 mt-2">
            <label class="sub-text">Status</label>
            <nz-tree-select formControlName="status" [nzNodes]="StatusNodes" nzShowSearch nzAllowClear="false"
              nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
          </div>
        </div>



        <div class="row">
          <div class="col p-0 mt-2">
            <label class="sub-text">Paid Date </label>
            <nz-select nzShowSearch nzAllowClear formControlName="PaidDate">
              <nz-option *ngFor="let option of PaidDate" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
            </nz-select>
          </div>
        </div>



        <div class="row">
          <div class="col p-0 mt-2">
            <label class="sub-text">DeadLine Date </label>
            <nz-select nzShowSearch nzAllowClear formControlName="DeadlineDate">
              <nz-option *ngFor="let option of DeadlineDate" [nzLabel]="option.label"
                [nzValue]="option.value"></nz-option>
            </nz-select>
          </div>
        </div>




      </div>
    </div>
  </form>
</div>



<ng-template #contentTemplate>
  <div>
  <p>Manage Saved Views</p>
  </div>
  </ng-template>