import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { CreateOwnerInvoicesSetupRequest, OwnerInvoicesSetupResponse, OwnerView } from 'src/Models/CompanySettings/Schedule/PhaseSetup/OwnerView';
import { LeadOpportunityResponse } from 'src/Models/LeadTags';
import { ResponseModel, ResponseModelArray } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class OwnerViewService {

  private baseUrl: string = environment.apiUrl + 'OwnerView/';
  private baseUrl2: string = environment.apiUrl + 'OwnerInvoicesSetup';
  private base_url_get_own_ino: string = environment.apiUrl + 'OwnerInvoicesSetup/getData-ByCompany';
  



  constructor(private http: HttpClient) {}

  postData(data: OwnerView): Observable<OwnerView> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }

  getData(): Observable<OwnerView[]> {
    return this.http.get<OwnerView[]>(`${this.baseUrl}getAll`);
  }

  updateData(data: OwnerView): Observable<OwnerView> {
    return this.http.put<OwnerView>(`${this.baseUrl}`, data);
  }

  deleteData(id: number): Observable<OwnerView> {
    return this.http.delete<OwnerView>(
      `${this.baseUrl}?globalId=${id}`
    );
  }
  getDataId(id: number): Observable<OwnerView> {
    return this.http.get<OwnerView>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }
  postCompanyOwner(OwnerInvoicesFormData: CreateOwnerInvoicesSetupRequest): Observable<ResponseModel<OwnerInvoicesSetupResponse>> {
    const baseUrl2 = `${this.baseUrl2}`;
    return this.http.post<ResponseModel<OwnerInvoicesSetupResponse>>(baseUrl2, OwnerInvoicesFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  getAllOwnerInvoice(): Observable<ResponseModelArray<OwnerInvoicesSetupResponse>> {
    return this.http.get<ResponseModelArray<OwnerInvoicesSetupResponse>>(`${this.base_url_get_own_ino}`);
  }
}