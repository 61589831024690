<nz-modal nzWidth="40%" [(nzVisible)]="CostInformation" [nzFooter]="footer" [nzTitle]="surveys1" (nzOnCancel)="close()" [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px','height': '70vh', 'max-height': '70vh', 'overflow-y': 'auto'}" [nzStyle]="{ top: '25px' }">
    <ng-template #surveys1>
      <div class="row p-0 m-0">
        <h1 class="p-0 m-0 famly-inn">Lead Proposal Columns</h1>
      </div>
    </ng-template>
    <div class="content" *nzModalContent>
      <div class="card border-0">
        <div class="card-head border-bottom">
            <div class="row mb-0 p-2 d-flex align-formFieldValues.createCostItemParameterRequests[i]s-center">
                <span class="fw-medium fs-6 ms-2">Visibility and Reordering</span>
            </div>
        </div>
        <div class="card-body">
            <div class="conatiner-fluid mt-3" cdkDropList (cdkDropListDropped)="drop($event)">
                <div *ngFor="let data of listOfData">
                    <div class="row mb-2 p-2">
                        <div class="col-6 p-2 grage gap-2 d-flex" cdkDragHandle>
                          <div class="image me-1">
                            <img cdkDrag src="assets/drag.png">
                          </div>
                          <div class="CheckBox me-2">
                            <label for="check" nz-checkbox>{{data.name}}</label>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <ng-template #footer>
      <div class="mt-2 mb-2 align-items-center">
          <button nzType="primary" nz-button >Apply</button>
      </div>
    </ng-template>
  </nz-modal>

