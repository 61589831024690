<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form [formGroup]="financialownerinvoicepaymentcreditmemosFilterForm" (ngSubmit)="onSubmit()">
    <div class="row mt-2">
      <div class="col-10 p-0">
        <div class="input-group">
          <nz-select formControlName="standardFilter" (ngModelChange)="onChangeStandardFilter($event)" nzShowSearch
            [lang]="true" nzAutoFocus="true" nzPlaceHolder="Standard Filter">
            <nz-option *ngFor="let filter of filterResponseData" [nzLabel]="filter.name"
              [nzValue]="filter.id"></nz-option>
          </nz-select>
        </div>
      </div>
      <div class="col-1 mt-1">
        <i class="bi bi-three-dots" (click)="openStandardFilterComponent()"></i>
      </div>
    </div>

    <div class="row  d-flex justify-content-between align-items-center">
      <div class="col-10 p-0 ">
        <div class="input-group">
          <nz-select>
            <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
            <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
          </nz-select>
        </div>
      </div>
      <div class="col-2 text-end p-0">
        <button nz-button class="border-0">
          <span nz-popover class="text-primary text-dark fw-bold fs-5" nz-icon nzType="ellipsis"
            nzTheme="outline"></span>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Keyword Search </label>
        <input formControlName="keywordSearch" class="formField form-control" nz-input placeholder="Basic usage" type="number" />
      </div>
    </div>

    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Created Date</label>
        <nz-select nzShowSearch nzAllowClear formControlName="CreatedDate" >
          <nz-option *ngFor="let option of CreatedDate" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
      </div>
    </div>


    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Status</label>
        <nz-tree-select  formControlName="status"  [nzNodes]="StatusNodes" nzShowSearch
                nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
      </div>
    </div>


    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Applied Date</label>
        <nz-select nzShowSearch nzAllowClear formControlName="AppliedDate" >
          <nz-option *ngFor="let option of AppliedDate" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
      </div>
    </div>
  </form>
</div>