import { Component, EventEmitter, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-lead-opportunities-import-lead-opportunities',
  templateUrl: './lead-opportunities-import-lead-opportunities.component.html',
  styleUrls: ['./lead-opportunities-import-lead-opportunities.component.css']
})
export class LeadOpportunitiesImportLeadOpportunitiesComponent {
  constructor(private modal: NzModalService,private msg: NzMessageService) { }
  @Output() cancel = new EventEmitter<void>();
  
  current = 0;
  isImportVisible = false;
  
  LeadOpportunityImportLead=true;
  LeadOpportunityImportLeadModelCancel(): void {
  this.cancel.emit();
  }

  handleImportCancel(): void {
    this.isImportVisible = false;
  }
  handleChange(info: NzUploadChangeParam): void {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      this.msg.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`${info.file.name} file upload failed.`);
    }
  }
}
