<nz-layout>
  <nz-sider
    class="border"
    nzCollapsible
    [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed"
    [nzWidth]="280"
    [nzTrigger]="null"
  >
    <div class="row">
      <app-side-menu-bar
      [isAllJobOption]="true"
      (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"
      ></app-side-menu-bar>
    </div>
  </nz-sider>
  <nz-layout>
    <nz-page-header>
      <nz-page-header-title>
        <nz-breadcrumb class="text-start" nz-page-header-breadcrumb>
          <nz-breadcrumb-item  >{{localStorage.getJobName()}}</nz-breadcrumb-item
          >
        </nz-breadcrumb>
        <h2 class="react p-0 m-0">Warranties</h2>
      </nz-page-header-title>
      <nz-page-header-extra>
        <nz-space>
          <!-- <button  nz-button nzType="default" class="ms-2 rounded-1" (click)="exportToExcel()" >
            Export
          </button> -->
          <button
            (click)="openFilter(PROJECT_MANG_WARRANTY, PAGE_ID)"
            nz-button
            nzType="default"
            style="padding: 5px 10px 30px 15px;"
            class="ms-2 rounded-2"
          >
            <span style="font-size: 16px;"><i class="bi bi-three-dots"></i></span> 
          </button>
          <button
            (click)="openFilter(PROJECT_MANG_WARRANTY, PAGE_ID)"
            nz-button
            nzType="default"
            style="padding: 5px 10px 30px 15px;"
            class="ms-2 rounded-2"
          >
            <span>
              <i class="bi bi-funnel"></i>
            </span>
            <span style="font-size: 16px;">Filter</span> 
          </button>
          <button
            (click)="Claim('Warranties')"
            nz-button
            nzType="primary"
            style="padding: 0px 10px 35px 12px;"
            class="ms-2 button-Daily rounded-2"
          >
          <span> <i style="font-size: 22px;" class="bi bi-plus me-2"></i> </span>
          <span style="font-size: 16px;">Claim</span> 
          </button>
        </nz-space>
      </nz-page-header-extra>
    </nz-page-header>

    <nz-content class="custom-content">
      <div class="container-fuild mb-4 m-3">
        <kendo-grid (columnResize)="saveColumnSizes($event)" [data]="GetProjectWarrantyDatagriddata" [pageSize]="5"
        [sortable]="true" [resizable]="true" [reorderable]="true">
        <kendo-grid-column *ngFor="let column of displayedColumns; let i = index" [title]="column" [field]="column"
          [minResizableWidth]="150" [maxResizableWidth]="800" [width]="getColumnWidthSize[i]"
          [sticky]="isColumnSticky[i]" [headerClass]="'pinned-header'">
          <ng-template kendoGridHeaderTemplate let-columnIndex="columnIndex">
            <div class="d-flex align-items-center">
              <span>{{ column }}</span>
              <button (click)="toggleColumnSticky(columnIndex)" class="btn btn-link">
                <i class="bi" [ngClass]="{
                              'bi-pin-fill': isColumnSticky[columnIndex],
                              'bi-pin': !isColumnSticky[columnIndex]
                            }"></i>
              </button>
            </div>
          </ng-template>
          <ng-template kendoGridColumnMenuTemplate let-columnIndex="columnIndex">
            <button (click)="toggleColumnSticky(columnIndex)" class="btn btn-link">
              <i class="bi" [ngClass]="{
                            'bi-pin-fill': isColumnSticky[columnIndex],
                            'bi-pin': !isColumnSticky[columnIndex]
                          }"></i>
            </button>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <ng-container *ngIf="column === 'Title'">
              <a class="text-decoration-none  blue-text" (click)="FormEdit(dataItem.Id)">{{ dataItem?.Title }} </a>
            </ng-container>
            <ng-container *ngIf="column === 'Claim #'">{{ dataItem?.idNumber }}</ng-container>

            <!-- <ng-container *ngIf="column === 'RFIs'">{{ dataItem?.rfi?.rfiInformations.length }}</ng-container> -->
            <ng-container *ngIf="column === 'RFIs'">{{ dataItem?.TotalRfi}}</ng-container>

            <ng-container *ngIf="column === 'Job'">
              {{ dataItem?.JobName }}
          </ng-container>

            <ng-container *ngIf="column === 'Comments'">{{ dataItem?.TotalMessaging }}</ng-container>

            <!-- <ng-container *ngIf="column === 'Email'">
              <span>{{ dataItem.email }} </span>
              <span *ngFor="let email of dataItem.applicationUserEmailParameters; let last = last">
                <span *ngIf="!last && isExpanded(rowIndex)">{{ email?.email }}, </span>
                <span *ngIf="last && isExpanded(rowIndex)">{{ email?.email }}</span>
                <span style="cursor: pointer;"
                  *ngIf="last && !isExpanded(rowIndex) && dataItem.applicationUserEmailParameters.length > 0"
                  (click)="toggleAdditionalEmails(rowIndex)">...</span>
              </span>
            </ng-container> -->
            <ng-container *ngIf="column === 'Added'">
              <a class="text-decoration-none">{{ dataItem?.AddedCost }}</a>
            </ng-container>
            <ng-container *ngIf="column === 'Follow up'">
              {{ dataItem?.followUpOn}}
            </ng-container>





            <ng-container *ngIf="column === 'Files'">
              <ng-container *ngIf="dataItem?.TotalAttachment > 0">
                <a nz-button nzType="link" class="p-0 m-0" nz-dropdown [nzDropdownMenu]="menu4" nzTrigger="click">
                  <svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path>
                  </svg>
                  <span>{{ dataItem?.TotalAttachment }}</span>
                  <span nz-icon nzType="down"></span>
                </a>

                <nz-dropdown-menu #menu4="nzDropdownMenu">
                  <ul nz-menu>
                    <li *ngFor="let fileName of dataItem?.FileNames.split(','); let i = index" nz-menu-item>
                      {{ fileName?.trim() }} <!-- Trimming in case of extra spaces -->
                    </li>
                  </ul>
                </nz-dropdown-menu>
              </ng-container>
            </ng-container>




            <ng-container *ngIf="column === 'Priority'">
              <span class="status-text badge" [ngClass]="{ 'low': dataItem?.PriorityName === 'Low', 'medium': dataItem?.PriorityName === 'Medium', 'high': dataItem?.PriorityName === 'High','emergency': dataItem?.PriorityName === 'Emergency' }">{{ dataItem?.PriorityName }}</span>
            </ng-container>

          <ng-container *ngIf="column === 'Category'">
            <a class="text-decoration-none"> {{ dataItem?.CategoryName }}</a>
          </ng-container>

            <ng-container *ngIf="column === 'Description'">

              <a class="text-decoration-none">{{ dataItem?.ProblemDescription }}</a>
            </ng-container>
            <ng-container *ngIf="column === 'Added Cost'">
                {{ dataItem?.AddedCost }}

            </ng-container>
            <ng-container *ngIf="column === 'Follow up '">
                {{ dataItem?.CreatedOn | date : "MMM d, yyyy" }}

            </ng-container>

            <ng-container *ngIf="column === 'Notes'">
              <!-- Replace 'phoneNumber' with the appropriate property containing creator information -->
              <a class="text-decoration-none">{{ dataItem?.Notes }}</a>
            </ng-container>
            <ng-container *ngIf="column === 'Service Coordinator'">
              {{ dataItem?.ServiceCoordinator }}
            </ng-container>

            <ng-container *ngIf="column === 'Class'">
              {{ dataItem?.Class }}
          </ng-container>

            <ng-container *ngIf="column === 'Owner Last Viewed'">
              <!-- Replace 'phoneNumber' with the appropriate property containing owner last viewed information -->
              <a class="text-decoration-none">{{ dataItem?.ownerLastViewed }}</a>
            </ng-container>
            <ng-container *ngIf="column === 'PO Builder Variance'">
              <!-- Replace 'phoneNumber' with the appropriate property containing PO builder variance -->
              <a class="text-decoration-none">{{ dataItem?.poBuilderVariance }}</a>
            </ng-container>
            <ng-container *ngIf="column === 'PO Customer Variance'">
              <!-- Replace 'phoneNumber' with the appropriate property containing PO customer variance -->
              <a class="text-decoration-none">{{ dataItem?.poCustomerVariance }}</a>
            </ng-container>
            <ng-container *ngIf="column === 'Related Bid Packages'">
              <!-- Replace 'phoneNumber' with the appropriate property containing related bid packages -->
              <a class="text-decoration-none">{{ dataItem?.relatedBidPackages }}</a>
            </ng-container>
            <ng-container *ngIf="column === 'Related Invoices'">
              <!-- Replace 'phoneNumber' with the appropriate property containing related invoices -->
              <a class="text-decoration-none">{{ dataItem?.relatedInvoices }}</a>
            </ng-container>
            <ng-container *ngIf="column === 'Sub/Vendors'">
              <!-- Replace 'phoneNumber' with the appropriate property containing sub/vendors information -->
              <a class="text-decoration-none">{{ dataItem?.subVendors }}</a>
            </ng-container>
            <ng-container *ngIf="column === 'Total Markup'">
              <!-- Replace 'phoneNumber' with the appropriate property containing total markup -->
              <a class="text-decoration-none">{{ dataItem?.totalMarkup }}</a>
            </ng-container>


          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>





<nz-footer class="custom-footer">
  <div class="container-fluid ">
    <div class="row d-flex justify-content-between align-items-center  ">
      <div class="row mb-0 mt-2">
        <div class="col">
          <nz-pagination style="float: right;" [nzPageIndex]="currentPage" [nzTotal]="totalRecords"
            [nzPageSize]="pageSize" nzShowSizeChanger (nzPageIndexChange)="onPageChange($event)"
            (nzPageSizeChange)="onPageSizeChange($event)" [nzPageSizeOptions]="[10, 20, 50, 75, 100]"></nz-pagination>

          <ng-container *ngIf="PAGE_ID">
            <div class="row d-flex justify-content-start mb-1 ">
              <!-- <div class="col-md-3">
                <nz-select (ngModelChange)="onFilterChange($event)" [(ngModel)]="selectedValueControl" nzShowSearch
                  nzAutoFocus="true">
                  <nz-option *ngFor="let standard of getStandardViewFilterData" [nzLabel]="standard.name"
                    [nzValue]="standard.id"></nz-option>
                </nz-select>

              </div> -->
              <div class="col-md-3">
                <div class="input-group">
                  <nz-select
                  [(ngModel)]="selectedValueControl"
                    name="standardFilter"
                    (ngModelChange)="onFilterChange($event)"
                    nzShowSearch
                    [nzAutoFocus]="true"
                    nzPlaceHolder="Standard Filter"
                    >
                    <nz-option  *ngFor="let standard of getStandardViewFilterData"
                      [nzLabel]="standard.name + (standard.isSetAsDefault ? ' ' : '')"
                      [nzValue]="standard.id"
                      nzCustomContent>
                      <div class="option-content">
                        <!-- Customize content with icons and text -->
                        <span *ngIf="standard.icon" nz-icon [nzType]="standard.icon"></span>
                        <span>{{ standard.name }}</span>
                      <span  *ngIf="standard.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

                      </div>
                    </nz-option>
                  </nz-select>
                </div>
              </div>

              <div class="col-md-1">
                <button nz-button class="border-0"

           nz-popover
           [nzPopoverContent]="contentTemplate"
           [nzPopoverPlacement]="'top'"

                 (click)="openStandardViewFilterComponent()">
                  <span nz-popover class="text-primary text-dark fw-bold fs-5" nz-icon nzType="ellipsis"
                    nzTheme="outline"></span>
                </button>
              </div>
              <div class="col-md-2">
                <div class="mt-2" *ngIf="selectedFilter === 1">
                  <p>Your filter has unsaved changes.</p>
                </div>
                <div class="mt-2" *ngIf="selectedFilter === 0">
                  <p>Your filter has unsaved changes.</p>
                </div>
              </div>

              <div class="col-md-3">
                <button (click)="openStandardViewFilterComponent('saveAs')" type="button" nz-button nzType="default"
                  class="me-2" style="font-size: 12px;" *ngIf="selectedFilter === 1">Save As</button>

                <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;"
                  *ngIf="selectedFilter === 1" type="button">Reset</button>

                <button (click)="saveEditStandardView()" nz-button nzType="primary" type="button" class="me-2"
                  style="font-size: 12px;" *ngIf="selectedFilter === 0">Save</button>

                <button (click)="openStandardViewFilterComponent('saveAs')" nz-button nzType="default" class="me-2"
                  style="font-size: 12px;" *ngIf="selectedFilter === 0" type="button">Save As</button>

                <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;"
                  *ngIf="selectedFilter === 0" type="button">Reset</button>

              </div>
            </div>
          </ng-container>

          <div class="col d-flex mt-3">


          </div>

        </div>
      </div>
    </div>
  </div>

</nz-footer>


<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>
