<nz-modal nzWidth="40%" [(nzVisible)]="ImportProposalsVisible" [nzTitle]="modalTitle" [nzFooter]="LeadActivityFooter"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '10px', 'max-height': '400px', 'overflow-y': 'auto' }"
    [nzStyle]="{ top: '13px' }" (nzOnCancel)="ActivityTemCancel()">

    <ng-template #modalTitle>
            <h1 >Import Proposals into 1 Lead?</h1>
    </ng-template>

    <div *nzModalContent>
        <div class="card border-0 mt-0 p-2">
            <div class="card-body">
                <div class="row mb-3">
                    <div class="col-12 p-0 m-0">
                        <label class="sub-text">Proposal Template
                            <span class="fw-bolder text-danger ms-1">*</span>
                        </label>
                        <input type="text" class="form-control formField" [(ngModel)]="formFieldValues['name']" name="name"
                            required #name="ngModel">

                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12 p-0 m-0">
                        <label class="sub-text" for="streetAddress">Salespeople</label>
                        <nz-tree-select style="width: 100%" [(ngModel)]="formFieldValues['applicationUserId']"
                            name="applicationUserId" nzShowSearch nzAllowClear="false" nzCheckable nzCheckAll="true"
                            nzPlaceHolder="Select Status">
                        </nz-tree-select>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #LeadActivityFooter>
        <div class="mt-2 align-items-center">
            <button nz-button nzType="default" (click)="ActivityTemCancel()">Cancel</button>
            <button nz-button nzType="primary" [disabled]="!formFieldValues.name">Import</button>
        </div>
    </ng-template>
</nz-modal>