import { Component } from '@angular/core';

@Component({
  selector: 'owner-invoice-credit-memos-empty',
  templateUrl: './owner-invoice-credit-memos-empty.component.html',
  styleUrls: ['./owner-invoice-credit-memos-empty.component.css']
})
export class OwnerInvoiceCreditMemosEmptyComponent {

}
