<form [formGroup]="filterFormGroup">
  <nz-modal nzWidth="60%" [nzVisible]="isVisible12" [nzTitle]="printModalTitle" [nzFooter]="printmodalFooter"
    [nzBodyStyle]="{
      background: '#f1f4fa',
      padding: '14px',
      'max-height': '540px',
      'overflow-y': 'auto'
    }" [nzStyle]="{ top: '15px' }" (nzOnCancel)="closeDialog()">
    <ng-template #printModalTitle>
      <div class="row p-0 m-0">
        <h1 class="p-0 m-0 fw-medium">Manage Save Filters</h1>
      </div>
    </ng-template>
    <div class="content content-set-background" *nzModalContent>
      <nz-card nzTitle="Manage Save Filters">
        <div nz-row>
          <div nz-col nzSpan="24">
            <p>
              Manage Your Save Filters and Select your default Filters for the
              current page
            </p>
          </div>
        </div>
        <nz-card nzTitle="Your Saved Filters" nzBorderless="true" style="margin-left: -20px"
          [nzExtra]="Addfilteremplate">
          <!-- card -->
          <!-- <nz-page-header *ngIf="!isAddFilterVisible && filters.length === 1" style="
              border: 0.1px solid #dad7d7;
              margin-right: -22px;
              margin-left: -3px;
            ">
            <nz-page-header-content>
              <div nz-row>
                <div nz-col nzSpan="3" style="margin-top: -10px; margin-left: -15px">
                  <svg fill="#000000" width="100px" height="100px" viewBox="-1.6 -1.6 35.20 35.20" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" stroke="#000000" stroke-width="0.064"
                    transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC"
                      stroke-width="0.576">
                      <path
                        d="M17.5 0h-11c-1.104 0-2 0.895-2 2v28c0 1.105 0.896 2 2 2h19c1.105 0 2-0.895 2-2v-20zM25.5 10.829v0.171h-9v-9h0.172zM6.5 30v-28h8v11h11v17h-19z">
                      </path>
                    </g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M17.5 0h-11c-1.104 0-2 0.895-2 2v28c0 1.105 0.896 2 2 2h19c1.105 0 2-0.895 2-2v-20zM25.5 10.829v0.171h-9v-9h0.172zM6.5 30v-28h8v11h11v17h-19z">
                      </path>
                    </g>
                  </svg>
                </div>
                <div nz-col nzSpan="21">
                  <span>
                    <h4 class="fw-bold mb-0">You Have No Saved Filters</h4>
                  </span>
                  <br />
                  <span>
                    <p>
                      Save Filters let you Configure what information is Shown
                      onn this page.<a class="text-primary fw-bold" (click)="toggleAddFilter()">Add a new saved
                        filter</a>
                    </p>
                  </span>
                </div>
              </div>
            </nz-page-header-content>
          </nz-page-header> -->
          <!-- card -->
        </nz-card>

        <!-- ADD FORM  -->
        <ng-template #Addfilteremplate>
          <button *ngIf="!isAddFilterVisible" nz-button nzType="default" class="text-primary border-0"
            (click)="toggleAddFilter()">
            <i class="bi bi-plus-circle-fill" style="font-size: 18px"></i>&nbsp;&nbsp;Add New Saved filter
          </button>
        </ng-template>

        <div *ngIf="isAddFilterVisible">
          <nz-page-header class="headset">
            <nz-page-header-title><span class="fw-medium">Add New Saved Filters</span></nz-page-header-title>
            <nz-page-header-extra>
              <nz-space>
                <i class="bi bi-x-lg text-dark" style="cursor: pointer; font-size: 22px" (click)="closeAddFilter()"></i>
              </nz-space>
            </nz-page-header-extra>
            <nz-page-header-content>
              <div nz-row>
                <div nz-col nzSpan="24">
                  <label class="mb-1 fw-medium">Filter Name</label>
                  <input type="text" formControlName="name" class="form-control formField" [ngClass]="{
                      error:
                        (filterFormGroup.get('name')?.invalid &&
                          (filterFormGroup.get('name')?.touched ||
                            formSubmitted)) ||
                        (filterFormGroup.get('name')?.value?.length ||
                          0) > 25
                    }" />
                  <div *ngIf="
                      filterFormGroup.get('name')?.hasError('required') &&
                      (filterFormGroup.get('name')?.touched ||
                        formSubmitted)
                    " class="text-danger p-error">
                    Required
                  </div>
                  <div *ngIf="
                      filterFormGroup.get('name')?.value &&
                      filterFormGroup.get('name')?.value.length > 25 &&
                      (filterFormGroup.get('name')?.touched ||
                        formSubmitted)
                    " class="text-danger p-error">
                    {{
                    filterFormGroup.get("name")?.value.length - 25
                    }}
                    characters over.
                  </div>
                  <div *ngIf="
                      filterFormGroup.get('name')?.hasError(
                        'uniqueName'
                      ) &&
                      (filterFormGroup.get('name')?.touched ||
                        formSubmitted)
                    " class="text-danger p-error">
                    View name must be unique
                  </div>
                </div>
              </div>

              <div nz-row class="mt-4">
                <div nz-col nzSpan="24">
                  <label formControlName="isShareThisFilter" nz-checkbox>Share this Filters</label>
                </div>
              </div>
              <div nz-row>
                <div nz-col nzSpan="24" class="mt-2 mb-2">
                  <label formControlName="isSetAsDefault" nz-checkbox>Set as default</label>
                </div>
              </div>
              <div nz-row class="text-end">
                <div nz-col nzSpan="24" class="mt-2 mb-2">
                  <nz-space>
                    <button nz-button nzType="primary" *ngIf="!isEditFormVisible" (click)="saveFilter()">
                      Add
                    </button>
                    <button nz-button nzType="default" *ngIf="isEditFormVisible"
                      (click)="onDelete(this.field)">Delete</button>


                    <button nz-button nzType="primary" *ngIf="isEditFormVisible" (click)="saveFilter()">
                      Save
                    </button>
                  </nz-space>
                </div>
              </div>
            </nz-page-header-content>
          </nz-page-header>
        </div>


        <nz-page-header class="headset-border mt-2 p-0" *ngFor="let filter of lookupFilterResponse let i =index">
          <nz-page-header-title>
            <div class="ms-3" nz-row>
              <div nz-col nzSpan="18">
                <ng-container *ngIf="filter.isSetAsDefault">
                  <nz-tag nzColor="#99daf2" class="rounded-5">
                    <span style="color: #353b99; font-size: 10px" class="fw-bold">DEFAULT FILTER</span>
                  </nz-tag>
                </ng-container>
                <div nz-row class="mb-0" style="margin-top: -6px">
                  <span style="font-size: 13px">{{ filter.name}}</span>
                </div>
                <div nz-row class="mb-0">
                  <span style="
                      opacity: 70%;
                      font-weight: normal;
                      font-size: 10px;
                      margin-top: -5px;
                    ">
                    {{ filter.isShareThisFilter ? "Shared" : (filter.isNotDeleted ? "" : "Private") }}

                  </span>
                </div>
              </div>
            </div>
          </nz-page-header-title>


          <!--extra-->
          <nz-page-header-extra class="justify-content-center align-content-center">
            <div nz-row>
              <nz-space class="p-3">
                <button *ngIf="i !== 0" (click)="showConfirmation('edit', i)" nz-button
                  class="text-primary border-0 me-2 align-items-center">
                  <i class="fa-solid fa-pen" style="font-size: 18px; color: #0d6efd;"></i>
                  <span nz-popover [nzPopoverContent]="editTemplate" nzPopoverPlacement="top">
                    <ng-template #editTemplate>
                      <div class="rounded-2">
                        <p style="height: auto; width: auto">Edit</p>
                      </div>
                    </ng-template>
                  </span>
                </button>

                <button *ngIf="i !== 0" (click)="showConfirmation('delete', i)" nz-button
                  class="text-primary border-0 me-2 align-items-center">
                  <i class="fa-solid fa-trash" style="font-size: 18px; color: #0d6efd;"></i>
                  <span nz-popover [nzPopoverContent]="deleteTemplate" nzPopoverPlacement="top">
                    <ng-template #deleteTemplate>
                      <div class="rounded-2">
                        <p style="height: auto; width: auto">Delete</p>
                      </div>
                    </ng-template>
                  </span>
                </button>

                <button nz-button nz-dropdown class="text-primary border-0 me-2 align-items-center"
                  [nzPlacement]="'bottomRight'" [nzTrigger]="'click'" [nzDropdownMenu]="menu2">
                  <span nz-icon nzType="ellipsis" style="font-size: 22px" s nzTheme="outline" class="text-primary ms-2"
                    nz-popover [nzPopoverContent]="moreTemplate" nzPopoverPlacement="top">
                    <ng-template #moreTemplate>
                      <div class="rounded-2">
                        <p style="height: auto; width: auto">More Action</p>
                      </div>
                    </ng-template>
                  </span>
                </button>
                <nz-dropdown-menu #menu2="nzDropdownMenu">
                  <ul nz-menu>
                    <li nz-menu-item *ngIf="!filter.isSetAsDefault" (click)="setAsDefaultView(filter)">
                      Set as Default View
                    </li>


                    <li nz-menu-item *ngIf="!isDuplicateSaveButtonVisible" (click)="duplicateFilter(i)">
                      <!-- (click)="EditFilter(filter.id)" -->
                      Duplicate Save View
                    </li>
                  </ul>
                </nz-dropdown-menu>
              </nz-space>
            </div>
          </nz-page-header-extra>
        </nz-page-header>

        <div nz-row class="mt-3 mb-1">
          <div nz-col nzSpan="24">
            <span class="fw-bold">Shared with you</span>
          </div>
        </div>

        <div nz-row class="mt-1 mb-1">
          <div nz-col nzSpan="24">
            <span class="fw-medium">These Filter have Created by other internal users on your
              account</span>
          </div>
        </div>




      </nz-card>
    </div>
    <ng-template #printmodalFooter> </ng-template>
  </nz-modal>
</form>