import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataUpdateServiceService {

  private dataUpdatedSubject = new BehaviorSubject<boolean>(false);
  private updateDropdownSource = new Subject<void>();

  // Observable that the grid component will subscribe to
  dataUpdated$: Observable<boolean> = this.dataUpdatedSubject.asObservable();
  updateDropdown$ = this.updateDropdownSource.asObservable();
  
  // Method to notify the grid component about data changes
  notifyDataUpdate(): void {
    this.dataUpdatedSubject.next(true);
  }

  triggerDropdownUpdate() {
    this.updateDropdownSource.next();
  }
}
