import { LeadOpportunitiesWorkSheetAddformCatalogComponent } from './../Catalog-Modal/lead-opportunities-work-sheet-addform-catalog/lead-opportunities-work-sheet-addform-catalog.component';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { EstimatedCostComponent } from '../estimated-cost/estimated-cost.component';
import { NewProposalsCostCodesComponent } from '../new-proposals-cost-codes/new-proposals-cost-codes.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CostItemParameterResponse, CreateCostItemParameterRequest, LeadProposalResponse, UpdateStatusPayload } from 'src/Models/LeadProposal/LeadProposalModels';
import { LeadProposalsPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LeadProposalsService } from 'src/Service/LeadProp/lead-proposals.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { AttachmentResponse, CreateAttachmentForDocumentRequest, LeadOpportunityResponse, LookupNameSetupResponse } from 'src/Models/LeadTags';
import { LeadProposalsFService } from 'src/Service/Sales-lead-proposals-F/lead-proposals-f.service';
import { LeadActivityTypeService } from 'src/Service/Lead-Activity-Type/lead-activity-type.service';
import { ProjectManagementSiteDiariesCreateFilesComponent } from 'src/app/project-management/components/Site-Diaries/project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from '../Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { LeadOpportunityAttachmentsUploadFilesComponent } from '../Modal/lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { Subscription } from 'rxjs';
import { LeadProposalColumnsComponent } from '../lead-proposal-columns/lead-proposal-columns.component';
import { CostCodeCategoryResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { HttpResponse } from '@angular/common/http';
import { ProposalStatusEnum, StatusSetupProposal } from 'src/app/Core/Enums/proposalstatus';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { SignaturePadComponent } from 'src/app/project-management/signature-pad/signature-pad.component';
import { SignatureSetupResponse } from 'src/Models/SignaturePad/SignaturePad';
// import { BlockQuote, Bold,  ClassicEditor,  EditorConfig,  Essentials, Font, FontBackgroundColor, FontColor, FontFamily, FontSize, Heading, HorizontalLine, Image, ImageUpload, Indent, Italic, Link, List, MediaEmbed, Paragraph, Table, Undo, UndoEditing } from 'ckeditor5';
import { BlockQuote, Bold, ClassicEditor, EditorConfig, Essentials, Font, FontBackgroundColor, FontColor, FontFamily, FontSize, Heading, HorizontalLine, Image, ImageUpload, Indent, Italic, Link, List, MediaEmbed, Paragraph, Table, Undo, UndoEditing } from 'ckeditor5';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzImageService } from 'ng-zorro-antd/image';
import { AttachmentAnnotationComponent } from '../attachment-annotation/attachment-annotation.component';
import { PhotoDrawComponent } from '../photo-draw/photo-draw.component';
import { environment } from 'src/environments/environment.prod';
import { FooterService } from 'src/Service/Footer/footer.service';
import { AuditDetailsResponse, FilterFooterAudit } from 'src/Models/Footer/footer.model';
import { PercentageMarkupComponent } from './percentage-markup/percentage-markup.component';
import { SalesSettings } from 'src/Models/LeasSalesPeople';
interface ItemData {
  id: number;
  name: string;
  age: string;
  address: string;
  disabled: any
}
interface Email {
  id: number;
  email: string;
  title: string;
  description: string;
  label: string;
}

interface Person {
  key: string;
  name: string;
  age: number;
  address: string;
}


interface CustomUploadFile extends NzUploadFile {
  loading?: boolean;
  error?: boolean;
  addedBy: number
  createdOn: Date;
  modifiedOn: Date;
  id: number;
  globalId: string;
}
@Component({
  selector: 'new-proposal',
  templateUrl: './new-proposal.component.html',
  styleUrls: ['./new-proposal.component.css']
})
export class NewProposalComponent implements OnInit, OnDestroy {
  @Output() leadProposalDeleted = new EventEmitter<string>();
  ProposalStatusEnum = ProposalStatusEnum;

  @Input() getProposalTemplatesDataId: number;
  @Output() leadProposalSaveResponse = new EventEmitter<LeadProposalResponse>();


  // these variables coming from lead Opportunity start 

  @Output() leadOpportunityProposalSaveResponse: LeadOpportunityResponse;
  @Output() leadProposalsResponse: LeadProposalResponse[] = [];
  @Output() saveProposalInLeadOpportunity: boolean = false;

  // these variables coming from lead Opportunity End 


  // this variable for Attachment Start //

  IMG_BASE: string = environment.IMG_BUCKET_URL;
  attachments: AttachmentResponse[] = [];
  testFile: AttachmentResponse[] = [];


  // this variable Attachment End   //





  @Output() leadProposalGridOnCompleted = new EventEmitter<void>();
  getProposalTemplatesData: LeadProposalResponse;
  footerResponse: AuditDetailsResponse; // Add the Footer Dynamic
  getStatusProposal: StatusSetupProposal;
  statusUpdateForDecline: StatusSetupProposal;
  NewProposalResponse: LeadProposalResponse[] = []
  assignedUser: { label: string; value: string }[] = [];
  selectedFiles: File[] = [];
  selectedDataSet: CreateCostItemParameterRequest[] = [];
  isContentExceedsTwoLines: boolean = false;
  @Output() cancel = new EventEmitter<void>();
  leadOptions: CostCodeCategoryResponse[] = [];
  public leadOpportunityRes: LeadOpportunityResponse;
  tableFormFieldValues: any[] = [];
  tableCostTypeFormFieldValues: any = {};
  markupOption: LookupNameSetupResponse[] = [];
  proposalOption: LookupNameSetupResponse[] = [];
  markAsOption: LookupNameSetupResponse[] = [];
  tabs = [1, 2, 3, 4, 5, 6, 7, 8];
  editorValue: string = '';
  closingText: string = '';

  test: number = 0;
  public pageId: 37;
  formFieldValues: any = {};
  initialFormFieldValues: any = {};

  selectedGroup: string = 'Group'; // Default selected group



  items: any[] = [];
  index: number = 0;
  LeadProposalPreviewComponent: boolean = true;
  showValidationMessage: boolean = false;
  leadproposal: FormGroup;
  selectedValue = null;
  isFromLeadProposal: boolean = false;
  leadOpportunitiesCost: CostItemParameterResponse[];
  isVisible10 = false;
  isVisibleLead = true;
  isFieldsVisible = false;
  isSectionVisible = false;
  lalalala: ItemData[] = [];
  tbodyList: any[] = [];
  i = 0;
  flag: string = '';
  costCodeId: any;
  preDefinedDataCodes = {
    costType: LeadProposalsPreDefinedCodes.CostType,
    markup: LeadProposalsPreDefinedCodes.Markup,
    markAs: LeadProposalsPreDefinedCodes.MarkAs,
  };
  isLoading: boolean = false;
  isDeleteLoading: boolean = false;
  isUnRelease: boolean = false;

  // **
  isCustomSelected: boolean = false;
  selectedItems: any[] = [];
  // **
  signatureId: number;
  signatureResponse: SignatureSetupResponse;
  signatureResponseDecline: SignatureSetupResponse;
  showAddGroupButton: boolean;
  dataset: SalesSettings;

  inputValue: '';
  constructor(private modal: NzModalService, private fb: FormBuilder,
    private leadProposalsService: LeadProposalsService,
    private LookupFilterService: LookupFilterService,
    private customerInformationService: CustomerInformationService,
    public leadProposalsFService: LeadProposalsFService,
    private toastService: NzMessageService,
    private leadActivitiesService: LeadActivityTypeService,
    private leadOppService: LeadOpportunitiesService,
    private changeDetectorRef: ChangeDetectorRef,
    private nzImageService: NzImageService,
    private FooterService: FooterService,


  ) {



  }
  ngOnInit(): void { 
    this.leadProposalGetById();
    this.getLeadData();
    localStorage.setItem('isFromLeadProposal', 'true');
    this.ForCombo();

    this.initLoad();
    this.getFilesProposal();
    this.leadProFormGroup();
    this.leadOppService.getDataByCompany().subscribe(
      (response) => {
        this.dataset = response.result;
        console.log('data set', this.dataset);

        if (this.dataset) {
          this.formFieldValues.introductoryText = this.dataset.DefaultIntroductoryText;
          this.formFieldValues.closingText = this.dataset.DefaultClosingText;

        }
      },
      (error) => {
        console.error('Error fetching data', error);
      }
    );
    // this.resetFormGroup();
    this.selectedOption('None'); // Initialize with "None" selected


    // In Lead Opportunity, send a flag to directly save the proposal associated with the Lead Opportunity.
    if (this.saveProposalInLeadOpportunity === true) { 
      this.saveLeadProposal(null);
    }
    else {
      this.saveProposalInLeadOpportunity === false;

    }
    document.addEventListener('click', this.handleOutsideClick.bind(this));
    this.updateHeaderCheckboxState();


  }

  leadProFormGroup(): void {
    this.items = [];
    this.formFieldValues = {
      id: 0,
      globalId: '00000000-0000-0000-0000-000000000000',
      leadOpportunityId: 0,
      title: '', // Title will be set later
      approvalDeadlineOn: null,
      internalNotes: '',
      IsTemplate: false,
      salesPersonId: 0,
      StatusSetupCode: '',
      introductoryText: '',
      signatureSetupId: 0,
      closingText: '',
      createCostItemRequest: {
        companyParameterId: 1,
        formNameId: 37,
        createCostItemParameterRequests: [],
      },
    };

    // Determine the base title
    let baseTitle = 'Proposal For ' + this.leadOpportunityProposalSaveResponse?.title;

    // Initialize title with the base title
    this.formFieldValues.title = baseTitle;

    // Check if leadProposalsResponse has any data
    if (this.leadProposalsResponse && this.leadProposalsResponse.length > 0) {
      let counter = 1;
      let titleExists = this.leadProposalsResponse.some(
        proposal => proposal.title === this.formFieldValues.title
      );

      // Increment counter until a unique title is found
      while (titleExists) {
        this.formFieldValues.title = `${baseTitle} (${counter})`;
        counter++;
        titleExists = this.leadProposalsResponse.some(
          proposal => proposal.title === this.formFieldValues.title
        );
      }
    }

    if (this.leadOpportunityProposalSaveResponse && this.leadOpportunityProposalSaveResponse?.id) {
      this.formFieldValues.leadOpportunityId = this.leadOpportunityProposalSaveResponse?.id;
    }

  }

  getFilesProposal() {
    this.leadOppService.getSelectedFiles().subscribe(files => {
      this.selectedFiles = files;
    });
    this.setInitialFormFieldValues();
  }

  leadProposalGetById() {
    if (this.getProposalTemplatesDataId) {
      this.isLoading = true;
      this.leadProposalsFService.getleadProposalsId(this.getProposalTemplatesDataId).subscribe(
        (res) => {
          this.getProposalTemplatesData = res.result;

          if (this.getProposalTemplatesData) {
            this.patchFormValues(this.getProposalTemplatesData);
            this.fetchAttachments(this.getProposalTemplatesData.attachmentId); // Fetch attachments only if proposal data is fetched successfully
          }
          this.isLoading = false;
        },
        (error) => {
          console.error('Error fetching lead proposal data', error);
          this.isLoading = false;
        }
      );
      this.storeInitialFormFieldValues();
    }
  }

  patchFormValues(LeadOpportunitiesNewProposalResponse: LeadProposalResponse) {
    let DataSet = LeadOpportunitiesNewProposalResponse;
    const param = DataSet?.costItem?.costItemParameters;

    this.formFieldValues['id'] = LeadOpportunitiesNewProposalResponse.id;
    this.formFieldValues['globalId'] = LeadOpportunitiesNewProposalResponse.globalId;
    this.formFieldValues['title'] = LeadOpportunitiesNewProposalResponse.title;
    this.formFieldValues['approvalDeadlineOn'] = DataSet.approvalDeadlineOn;
    this.formFieldValues['salesPersonId'] = DataSet.salesPersonId.toString();
    this.formFieldValues['internalNotes'] = DataSet.internalNotes;
    this.formFieldValues['introductoryText'] = DataSet.introductoryText;
    this.editorValue = DataSet.introductoryText;
    this.closingText = DataSet.closingText;
    this.formFieldValues['closingText'] = DataSet.closingText;
    this.formFieldValues['signatureSetupId'] = LeadOpportunitiesNewProposalResponse.signatureSetupId;
    this.formFieldValues['leadOpportunityId'] = LeadOpportunitiesNewProposalResponse.leadOpportunityId;
    this.getStatusProposal = LeadOpportunitiesNewProposalResponse.statusSetup;
    this.signatureResponse = LeadOpportunitiesNewProposalResponse?.signatureSetup;

    // Cost Item Data
    if (param) {
      this.formFieldValues.createCostItemRequest = {
        companyParameterId: 1,
        formNameId: 37,
      };
      // Populate this.items with cost item data
      this.isRowCreated = true;
      this.items = param.map((costItem) => ({
        id: costItem.id || null,
        title: costItem.title || '',
        description: costItem.description || '',
        quantity: costItem.quantity || 0,
        unit: costItem.unit || '',
        unitCost: costItem.unitCost || 0,
        costTypeId: costItem.costTypeId || null,
        builderCost: costItem.builderCost || 0,
        markupValue: costItem.markupValue || 0,
        markupId: costItem.markupId || 0,
        ownerPrice: costItem.ownerPrice || 0,
        margin: costItem.margin || 0,
        profit: costItem.profit || 0,
        markAsId: costItem.markAsId,
        costCodeId: costItem.costCodeId || null,
        showValidationMessage: false,
        isEditable: false
      }));

      // Synchronize form field values
      this.formFieldValues.createCostItemRequest.createCostItemParameterRequests = this.items;

      // Update totals after setting items
      this.updateTotals();

    } else {
      // If no params, initialize items as empty
      this.items = [];
      this.updateTotals(); // Ensure totals are reset when no params are provided
    }
    this.createAndModifiedByData(LeadOpportunitiesNewProposalResponse.id); // patch time Calling this Footer
    // Call the method to initialize form field values
    this.setInitialFormFieldValues();

    // Log the items for debugging purposes
  }




  fetchAttachments(attachmentId: number) {
    if (attachmentId) {
      this.leadOppService.getLeadByAttachmentId(attachmentId).subscribe(
        (res) => {
          const attachmentParameters = res.result?.attachmentParameters;
          if (attachmentParameters) {
            this.selectedFilses = attachmentParameters.map(att => ({
              uid: att.id?.toString() || '',
              name: att.name || '',
              status: 'done',
              url: this.IMG_BASE + att.filePath,
              thumbUrl: this.IMG_BASE + att.filePath,
              type: this.getMimeType(att.extension),
              originFileObj: new File([new Blob()], att.name || '', {
                type: this.getMimeType(att.extension),
                lastModified: new Date(att.modifiedOn).getTime()
              }),
              loading: false,
              error: false,
              addedBy: att.createdBy || 0,
              createdOn: new Date(att.createdOn),
              modifiedOn: new Date(att.modifiedOn),
              id: att.id || 0,
              globalId: att.globalId || ''
            }));
          }
        },
        (error) => {
          console.error('Error fetching attachments:', error); // Log error for debugging
        }
      );
    } else {
      this.attachments = [];
    }
  }

  getMimeType(extension: string): string {
    switch (extension) {
      case '.pdf':
        return 'application/pdf';
      case '.doc':
      case '.docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case '.json':
        return 'application/json';
      case '.txt':
        return 'text/plain';
      case '.xls':
      case '.xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case '.ppt':
      case '.pptx':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'mp4':
      case 'avi':
        return 'video/mp4';
      default:
        return 'application/octet-stream';
    }
  }

  setInitialFormFieldValues(): void {
    this.initialFormFieldValues = JSON.parse(JSON.stringify(this.formFieldValues));
  }


  // Save Save and Close and Save & New 

  isSave: boolean = false;
  isSaveAndClose: boolean = false;
  isSaveAndRelease: boolean = false;
  isSaveAndApproval: boolean = false;
  isDecline: boolean = false;
  isDeclineProposal: boolean = false; // this flag is only check decline time status not update
  private isDataEmitted = false; // Flag to check if data has been emitted
  isSubmitted: boolean = false;
  DisabledNextActivity: boolean = false;


  async saveLeadProposal(statusCode: ProposalStatusEnum, callback?: () => void): Promise<void> {
    if (this.isSave || this.isSaveAndClose || this.isSaveAndRelease || this.isSaveAndApproval || this.isDecline) {
      return Promise.reject('Already processing');
    }

    // Set flags based on the callback provided
    if (callback) {
      this.isSaveAndClose = callback.name === 'saveAndCloseProposal';
      this.isSaveAndRelease = callback.name === 'saveAndReleasedProposal';
      this.isSaveAndApproval = callback.name === 'saveAndApproveProposal';
      this.isDecline = callback.name === 'declineProposal';
    } else {
      this.isSave = true;
    }

    this.DisabledNextActivity = true; // Disable next activity
    this.isSubmitted = true;
    this.checkErrors(true); // Validate all fields before saving

    // Handle validation errors
    if (Object.values(this.validationStates).includes('error')) {
      setTimeout(() => {
        const errorElements = document.querySelectorAll('.top-of-modal');
        if (errorElements.length > 0) {
          const lastErrorElement = errorElements[errorElements.length - 1];
          lastErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100);
      this.resetFlags();
      this.DisabledNextActivity = false; // Re-enable next activity
      return; // Exit early if there are validation errors
    }

    // Assign signature ID if available
    if (this.signatureResponse) {
      this.formFieldValues.signatureSetupId = this.signatureResponse.id;
    } else if (this.signatureResponseDecline) {
      this.formFieldValues.signatureSetupId = this.signatureResponseDecline.id;
    } else {
      this.formFieldValues.signatureSetupId = 0;
    }

    // Set status code
    this.formFieldValues.StatusSetupCode = statusCode || this.getStatusProposal?.code || 'LOLPPSTSU';




    try {
      // Save the lead proposal
      const postResponse = await new Promise<any>((resolve, reject) => {
        this.leadProposalsService.postLeadProposal(this.formFieldValues).subscribe({
          next: (response) => {
            this.getProposalTemplatesData = response.result;
            this.getStatusProposal = this.isDecline ? undefined : response.result.statusSetup;
            this.statusUpdateForDecline = this.isDecline ? response.result.statusSetup : undefined;
            this.formFieldValues.globalId = response.result.globalId;
            resolve(response);
          },
          error: (error) => reject(error)
        });
      });

      // If files are selected, save them using the headerId from the response
      if (this.selectedFilses?.length > 0) {
        const filesToUpload = this.selectedFilses.filter(i => i.originFileObj);
        const documentFiles = filesToUpload.map(f => f.originFileObj);
        const attachmentRequest: CreateAttachmentForDocumentRequest = {
          headerId: postResponse.result.id, // Use the headerId from the response
          companyParameterId: 1,
          formNameId: 37,
          documentFiles: documentFiles
        };

        await new Promise<void>((resolve, reject) => {
          this.leadOppService.CreateAttachmentForDocument(attachmentRequest).subscribe(
            (attachmentResponse) => {
              this.saveFileIdToDatabase(attachmentResponse.fileId);
              resolve();
            },
            (attachmentError) => {
              console.error('Error creating attachment:', attachmentError);
              reject(attachmentError);
            }
          );
        });
      }

      if (postResponse) {
        this.createAndModifiedByData(postResponse.result.id);
      }

      this.toastService.success('Saved Successfully!');
      this.leadActivitiesService.setLeadActivityTypeResponse(postResponse);
      this.storeInitialFormFieldValues();

      if (callback) {
        callback();
        this.leadProposalSaveResponse.emit(postResponse.result);
      }

    } catch (error) {
      console.error('Error saving data:', error);
      this.toastService.error('Error saving lead data.');
      throw error;
    } finally {
      this.resetFlags();
      this.isSubmitted = false;
      this.DisabledNextActivity = false; // Re-enable next activity
      this.isDeclineProposal = false;
    }
  }



  saveAndCloseProposal(): Promise<void> {
    return new Promise((resolve, reject) => {
      const statusCode: any = this.getStatusProposal?.code;
      this.saveLeadProposal(statusCode, () => {
        this.resetForm(); // Reset the form
        this.isVisibleLead = false; // Close the modal or hide the view
        this.cancel.emit(); // Emit cancel event if needed
        resolve(); // Resolve the promise
      }).catch((error) => {
        reject(error); // Reject the promise with the error
      });
    });
  }


  saveAndReleasedProposal(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.saveLeadProposal(ProposalStatusEnum.Released, () => {
        resolve(); // Resolve the promise
      }).catch((error) => {
        reject(error); // Reject the promise with the error
      });
    });
  }


  ReleaseConfirmCard(): void {
    const customerInformationId = this.leadOpportunityProposalSaveResponse?.customerInformationId;
    const inGridcustomerInformationId = this.getProposalTemplatesData?.leadOpportunity?.customerInformationId;
    if (!customerInformationId && !inGridcustomerInformationId) {
      this.modal.confirm({
        nzTitle: `There is no customer contact assigned to this lead.`,
        nzContent: 'Please set up a customer contact on this lead opportunity if you wish to send this proposal.',
        nzOkText: `Ok`,
        nzCancelText: null,
        nzOnOk: () => {
        },
        nzCentered: true,
        nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15px', 'padding': '20px' },
        nzCloseIcon: '',
        nzIconType: ''
      });
    } else {
      this.saveAndReleasedProposal()
        .then(() => {
        })
        .catch((error) => {
        });
    }
  }
  // Dropdown

  importFromPlanSwift(): void {
    // logic for importing from PlanSwift
    console.log('Import from PlanSwift clicked');
  }

  downloadPlugin(): void {
    // logic for downloading the plugin
    console.log('Download integration plugin clicked');
  }


  onMoveItems(): void {
    console.log('Adjust MoveItem Markup clicked');
    // Your logic for adjusting percentage markup
  }

  // Handle Delete Checked click
  // onDeleteChecked(): void {
  //     this.confirmModal = this.modal.confirm({
  //       nzTitle: 'Delete Item?',
  //       nzContent: 'Are you sure you want to delete the selected Lead Proposal item?',
  //       nzOkDanger: true,
  //       nzOkText: 'Delete',
  //       nzCancelText: 'Cancel',
  //       nzModalType: 'confirm',
  //       nzAutofocus: 'ok',
  //       nzCentered: true,
  //       nzOnOk: () => this.deleteRow(this.index)
  //     });
  //   }


  onDeleteChecked(): void {
    if (!this.isAnyRowChecked()) {
      // Agar koi item select nahi hai to warning show karo
      console.warn('Koi item select nahi kiya gaya.');
      return;
    }

    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete Items?',
      nzContent: 'Are you sure you want to delete the selected Lead Proposal item?',
      nzOkDanger: true,
      nzOkText: 'Delete',
      nzCancelText: 'Cancel',
      nzModalType: 'confirm',
      nzAutofocus: 'ok',
      nzCentered: true,
      nzOnOk: () => this.deleteSelectedItems() // Ye function multiple selected items delete karega
    });
  }

  // Delete all selected items
  deleteSelectedItems(): void {
    // Filter out all items that are not checked
    this.items = this.items.filter(item => !item.checked);

    // Agar backend API ko bhi hit karna hai to yahan uska logic add karo
  }



  // **


  saveAndApproveProposal(): void {
    const modalRef = this.modal.create({
      nzContent: SignaturePadComponent,
      nzFooter: null
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.signatureSaved.subscribe(async (data) => {
      this.signatureResponse = data;
      try {
        await new Promise<void>((resolve, reject) => {
          this.saveLeadProposal(ProposalStatusEnum.Approved, resolve).catch(reject);
        });
      } catch (error) {
        this.toastService.error('Error saving lead proposal.');
        console.error('Error:', error);
      } finally {
        modalRef.destroy();
      }
    });
  }


  /// after approved visibility of buttons and hidden buttons
  approveAllowButtons(): boolean {
    return this.getStatusProposal?.code !== this.ProposalStatusEnum.Approved;
  }
  /// after Decline visibility of buttons and hidden buttons

  declineAllowButtons(): boolean {
    return this.getStatusProposal?.code !== this.ProposalStatusEnum.Declined && this.statusUpdateForDecline?.code !== this.ProposalStatusEnum.Declined;
  }

  ReleaseAllowButtons(): boolean {
    return this.getStatusProposal?.code !== this.ProposalStatusEnum.Released;
  }


  declineProposal() {
    const modalRef = this.modal.create({
      nzContent: SignaturePadComponent,
      nzFooter: null
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.signatureSaved.subscribe(async (data) => {
      this.signatureResponseDecline = data;
      this.isDeclineProposal = true; // Set the decline proposal flag

      try {
        await new Promise<void>((resolve, reject) => {
          this.saveLeadProposal(ProposalStatusEnum.Declined, resolve).catch(reject);
        });
      } catch (error) {
        this.toastService.error('Error declining lead proposal.');
        console.error('Error:', error);
      } finally {
        modalRef.destroy();
      }
    });
  }


  private emitData(data: LeadProposalResponse): void {
    if (!this.isDataEmitted && data) {
      this.leadProposalSaveResponse.emit(data);
      this.isDataEmitted = true;
    }
  }


  resetFlags() {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndRelease = false;
    this.isSaveAndApproval = false;
    this.isDecline = false;
  }


  // UnRelease Card And Method


  UnReleasedProposal(UnReleaseCode: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const payload: UpdateStatusPayload = {
        globalId: this.getProposalTemplatesData.globalId,
        statusSetupCode: UnReleaseCode
      };

      this.leadProposalsService.updateUnReleaseStatus(payload).subscribe({
        next: (res) => {
          this.toastService.success('Un Release Proposal!');
          this.getStatusProposal = res.result;

          resolve(); // Resolve the promise
        },
        error: (error) => {
          this.toastService.error('Error updating proposal status.');
          reject(error); // Reject the promise with the error
        },
        complete: () => {
          this.isUnRelease = false; // Reset the unrelease flag
        }
      });
    });
  }

  UnReleaseConfirmCard(): void {
    if (this.isUnRelease) {
      return;
    }

    this.modal.confirm({
      nzTitle: `Unrelease Lead Proposal?`,
      nzContent: 'This will disable the link sent to the Contact Email and will reset the status of this proposal.',
      nzOkText: `Unrelease`,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isUnRelease = true;
        return this.UnReleasedProposal(ProposalStatusEnum.Unreleased)
          .then(() => {
            // Additional actions if needed
          })
          .catch((error) => {
            console.error('Error during unrelease operation:', error);
          });
      },
      nzCentered: true,
      nzOkLoading: this.isUnRelease,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15px' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }


  resetFormFields: any = {};
  getFormattedDateTime(): string {
    const date = new Date();

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    return date.toLocaleString('en-US', options);
  }

  resetFormGroup(): void {
    this.resetFormFields = {
      saveApprovedProposalAsAPdf: true,
      pdfTitle: `Approved Proposal ${this.getFormattedDateTime()}`,
      resetAllProposalsForThisLead: true
    };
  }





  isResetVisible = false;
  showConfirmResetModal(): void {
    this.isResetVisible = true;
  }

  handleCancel(): void {
    this.isResetVisible = false;
  }


  isTitleSet = false; // Flag to track if title is set

  resetProposal(): void {
    // Check if pdfTitle is empty or consists of only spaces
    if (!this.resetFormFields.pdfTitle) {
      this.resetFormFields.pdfTitle = `Approved Proposal ${this.getFormattedDateTime()}`;
      this.isTitleSet = true; // Set flag indicating the title is now set
      return; // Exit without calling the API
    }

    // Proceed with the proposal reset logic only if the title is already set
    if (this.resetFormFields.pdfTitle) {
      const payload: UpdateStatusPayload = {
        globalId: this.getProposalTemplatesData.globalId,
        statusSetupCode: ProposalStatusEnum.Reset
      };

      this.leadProposalsService.updateUnReleaseStatus(payload).subscribe({
        next: (res) => {
          this.toastService.success('Suuccessfuly Reset');
          this.getStatusProposal = res.result;
          this.isResetVisible = false; // Close the modal after success
          this.isTitleSet = false; // Reset the flag for future use
        },
        error: (error) => {
          this.toastService.error('Error updating proposal status.');
        }
      });
    }
  }




  deleteLeadProposal(globalId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!globalId) {
        this.toastService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }

      this.leadProposalsService.deleteProposal(globalId).subscribe({
        next: (res: HttpResponse<void>) => {
          this.isDeleteLoading = false;
          // if (res.status === 200) { // Check for HTTP status code 200
          this.toastService.success(`${this.getProposalTemplatesData.title} Deleted!`);
          this.leadActivitiesService.setLeadActivityTypeResponse(res);
          this.leadProposalSaveResponse.emit();
          resolve();
          // } else {
          this.toastService.error('Failed to delete the record. Please try again.');
          reject();
          // }
        },
        error: (error) => {
          this.isDeleteLoading = false;
          this.toastService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      });
    });
  }

  deleteLeadConfirm(): void {
    if (this.isDeleteLoading) {
      return;
    }

    this.modal.confirm({
      nzTitle: `Delete Lead Proposal?`,
      nzContent: 'Are you sure you want to permanently delete this lead proposal and all line items on it?',
      nzOkText: `Delete`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteLeadProposal(this.formFieldValues.globalId)
          .then(() => {
            this.handleCancelLeadProposal();
          })
          .catch(() => {

          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }


  resetForm(): void {
    this.setInitialFormFieldValues();
    this.formFieldValues = {
      id: 0,
      globalId: '',
      leadOpportunityId: 0,
      title: '',
      approvalDeadlineOn: '',
      internalNotes: '',
      IsTemplate: true,
      salesPersonId: 0,
      introductoryText: '',
      closingText: '',
      createCostItemRequest: {
        companyParameterId: 0,
        formNameId: 0,
        createCostItemParameterRequests: [],
      },
    };
    this.items = [];
    this.leadOppService.clearSelectedFiles();
  }



  getLeadData() {
    this.leadProposalsService.getAllLeadData().subscribe(data => {
      this.leadOptions = data.result;
    });
    this.setInitialFormFieldValues();
  }



  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(37).subscribe(res => {
      let proposalStatus = res.result.filter(x => x.code === this.preDefinedDataCodes.costType.code);
      let markupValues = res.result.filter((x) => x.code === this.preDefinedDataCodes.markup.code);
      let markAsOptionValues = res.result.filter(x => x.code === this.preDefinedDataCodes.markAs.code);
      this.proposalOption = proposalStatus;
      this.markupOption = markupValues;

      this.markAsOption = markAsOptionValues;

      if (this.getProposalTemplatesDataId) {
      } else {
        const leadmarkup = this.markupOption.find((item) => item.description === '%');
        const leadleadmarkupId = leadmarkup?.id;
      }
    });
    this.setInitialFormFieldValues();

  }
  leadProposal() {
    this.isVisible10 = true;
  }
  handleCancelLeadProposal(): void {
    this.cancel.emit();
    this.leadOppService.clearSelectedFiles();
    this.isVisibleLead = false;
    this.emitData(this.getProposalTemplatesData);
  }

  openLeadProposaltModal(): void {
    this.isVisibleLead = true;
  }
  cancelLeadProposaltModal(): void {
    this.cancel.emit();
  }
  handleLeadSelection(value: any): void {
    this.isVisibleLead = false;
    if (value === 'jack' || value === 'lucy' || value === 'tom') {
      this.isVisible10 = true;
    } else {
    }
  }
  showMoreFeild() {
    this.isFieldsVisible = !this.isFieldsVisible;
  }
  leadproposalTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'Worksheet';
      case 2:
        return 'Format';
      case 3:
        return 'Preview';
      default:
        return '';
    }
  }
  panels = [
    {
      active: false,
      disabled: false
    },
  ];
  showDocUpload() {
    const modalRef = this.modal.create({
      nzContent: FileUploadComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }


  // A**
  selectedOption(group: string): void {
    if (group === 'None') {
      this.selectedGroup = null; // Reset to just "Group" when None is selected
    } else {
      this.selectedGroup = group; // Show the selected group when any other option is selected
    }

    // Optionally manage the "Add Group" button for Custom
    if (group === 'Custom') {
      this.showAddGroupButton = true;
    } else {
      this.showAddGroupButton = false; // Hide for other selections
    }
  }

  // A** End


  // Cost Code Grid Add Row This Form Modal and Edit
  showEstimatedCost() {
    const modalRef = this.modal.create({
      nzContent: EstimatedCostComponent,
      nzFooter: null,
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.onSaveEstimatedCostComplete.subscribe((data: any) => {
      this.processReceivedCostData(data);
    });
  }

  processReceivedCostData(data: any): void {
    const existingItemIndex = this.items.findIndex(item => item.id === data.id); // Check if the item already exists

    if (existingItemIndex !== -1) {
      this.items[existingItemIndex] = { ...this.items[existingItemIndex], ...data };
    } else {
      this.items.push({ ...data, id: this.generateNewItemId(), isEditable: false });
    }

    this.updateTotals();
    this.changeDetectorRef.detectChanges();
    this.formFieldValues.createCostItemRequest.createCostItemParameterRequests = this.items;
  }

  generateNewItemId(): number {
    return this.items.length > 0 ? Math.max(...this.items.map(item => item.id)) + 1 : 1;
  }



  leadProposalItemEdit(costItemParameterDataSet: CostItemParameterResponse) {
    const modalRef = this.modal.create({
      nzContent: EstimatedCostComponent,
      nzFooter: null
    });
    modalRef.componentInstance.getProposalItemData = costItemParameterDataSet;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.onSaveEstimatedCostComplete.subscribe((data: any) => {
      this.processReceivedCostData(data);
    });
  }



  private subscription: Subscription;
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  showProposalCostCode() {
    const modalRef = this.modal.create({
      nzContent: NewProposalsCostCodesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();

    });
  }
  showCatalogModel(flag: String) {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesWorkSheetAddformCatalogComponent,
      nzFooter: null,
      nzData: {
        CompanySetting: flag
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  toggleSection() {
    this.isSectionVisible = !this.isSectionVisible;
  }
  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      const assignedUser = res.result;
      this.assignedUser = assignedUser.map((AUser) => ({
        label: AUser.fullName,
        value: AUser.id.toString(),
      }));
    });
    this.setInitialFormFieldValues();

  }



  listOfData: Person[] = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park'
    },
  ];


  editColumns() {
    const modalRef = this.modal.create({
      nzContent: LeadProposalColumnsComponent,
      nzFooter: null
    });
    // modalRef.componentInstance.getProposalItemData = costItemParameterDataSet;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }


  saveFileIdToDatabase(fileId: string) { }

  // Formatter function to format the number to "1.000"
  formatNumber = (value: number | string): string => {
    if (value === null || value === undefined) return '';
    // Ensure the value is a number
    const numValue = typeof value === 'string' ? parseFloat(value) : value;
    return numValue.toFixed(3); // Format number with 3 decimal places
  };

  // Parser function to parse the formatted string back to a number
  parseNumber = (value: string): string => {
    if (!value) return '';
    // Convert the string to a number and then back to a string to remove commas or other non-numeric characters
    return parseFloat(value.replace(/,/g, '')).toString();
  };


  listOfCurrentPageData: readonly ItemData[] = [];
  isCheckboxSelected = false;
  setOfCheckedId = new Set<number>();
  checked = false;
  // indeterminate = false;
  expandSet = new Set<number>();

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  // onAllChecked(isChecked: boolean): void {
  //   this.isCheckboxSelected = isChecked;
  //   this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, isChecked));
  //   this.refreshCheckedStatus();
  // }

  onCurrentPageDataChange($event: readonly any[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  cancelSelection(): void {
    this.setOfCheckedId.clear();
    this.isCheckboxSelected = false;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData.filter(({ disabled }) => !disabled);
    this.checked = listOfEnabledData.every(({ id }) => this.setOfCheckedId.has(id));
    this.indeterminate = listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  // onItemChecked(id: number, checked: boolean): void {
  //   this.updateCheckedSet(id, checked);
  //   this.isCheckboxSelected = this.setOfCheckedId.size > 0;
  //   this.refreshCheckedStatus();
  // }


  public Editor = ClassicEditor;
  public config: EditorConfig = {
    toolbar: {
      items: [
        'undo', 'redo', '|',
        'heading', '|', 'bold', 'italic', '|',
        'link', 'insertTable', 'mediaEmbed', '|',
        'bulletedList', 'numberedList', 'indent', 'outdent', '|',
        'fontSize', 'fontFamily', 'fontBackgroundColor', 'fontColor', 'blockQuote',
        'image', 'horizontalLine', 'undoEditing'
      ]
    },
    plugins: [
      Bold,
      Essentials,
      Heading,
      Indent,
      Italic,
      Link,
      List,
      MediaEmbed,
      Paragraph,
      Table,
      Undo,
      Font,
      FontSize,
      FontFamily,
      FontBackgroundColor,
      FontColor,
      BlockQuote,
      Image,
      HorizontalLine,
      UndoEditing,
      ImageUpload
    ],
    fontSize: {
      options: [
        9, 11, 13, 'default', 17, 19, 21, 25, 28, 32, 36, 40, 44, 48, 52, 56, 60
      ]
    },
    fontFamily: {
      options: [
        'default', 'Arial, Helvetica, sans-serif', 'Courier New, Courier, monospace',
        'Georgia, serif', 'Times New Roman, Times, serif', 'Verdana, Geneva, sans-serif'
      ]
    }
  };

  //  Custom Error 

  // validationStates: { [key: string]: NzStatus } = {};
  validationMessages: { [key: string]: string } = {};
  changedFields: Set<string> = new Set<string>();

  formatDate(date: Date): string {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  }

  readonly validationRules = {
    title: {
      required: 'Required',
      maxLength: { limit: 100, message: 'Title exceeds the limit.' }
    },
    approvalDeadlineOn: {
      dateNotInPast: 'Date must be on or after ' + this.formatDate(new Date()),
    },
    internalNotes: {
      maxLength: { limit: 512, message: 'Internal Notes exceeds the limit.' }
    }
  };

  // Function to get the label for a field
  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      title: 'Title',
      approvalDeadlineOn: 'Approval Deadline',
      internalNotes: 'Internal Notes',
    };
    return labels[field] || field;
  }

  checkErrors(validateAllFields = false) {
    this.validationStates = {};
    this.validationMessages = {};

    // Determine which fields to validate
    const fieldsToValidate = validateAllFields ? Object.keys(this.validationRules) : Array.from(this.changedFields);

    for (const field of fieldsToValidate) {
      if (this.validationRules.hasOwnProperty(field)) {
        const errorTip = this.getErrorTip(field);
        if (errorTip) {
          this.validationStates[field] = 'error';
          this.validationMessages[field] = errorTip;
        } else {
          this.validationStates[field] = null;
          this.validationMessages[field] = '';
        }
      }
    }
  }

  validateAllFields() {
    this.checkErrors(true);
  }

  validateChangedFields(field: string) {
    this.changedFields.add(field); // Add field to the changed fields set
    this.checkErrors(); // Validate changed fields
  }

  getErrorTip(field: string): string {
    const rules = this.validationRules[field];
    const input = this.formFieldValues[field] || '';

    if (rules.required && !input.trim()) {
      return `Required.`;
    }

    if (rules.maxLength && input.length > rules.maxLength.limit) {
      const excessLength = input.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }

    // Validate the date for approvalDeadlineOn
    if (field === 'approvalDeadlineOn' && input) {
      const selectedDate = new Date(input);
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set today's time to midnight for comparison
      if (selectedDate < today) {
        return rules.dateNotInPast;
      }
    }

    return ''; // No error
  }

  // Get an array of fields with errors
  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }

  // Function to handle changes in field values
  onFieldChange(field: string, value: any) {
    if (field === 'approvalDeadlineOn') {
      this.formFieldValues[field] = value instanceof Date ? value.toISOString() : value;
    } else {
      this.formFieldValues[field] = value;
    }
    this.changedFields.add(field);
    this.checkErrors();
  }



  // discard changes 

  confirmModal?: NzModalRef;


  async storeInitialFormFieldValues(): Promise<void> {
    await new Promise(resolve => setTimeout(() => {
      this.initialFormFieldValues = JSON.parse(JSON.stringify(this.formFieldValues));
      resolve(true);
    }, 0));
  }

  isFormDirty(): boolean {
    return JSON.stringify(this.formFieldValues) !== JSON.stringify(this.initialFormFieldValues);
  }

  showConfirmCardLeadProposal(): void {
    const dontSaveText = 'Dont Save'; // Constant to store the 'Don't Save' button text

    if (this.isFormDirty()) {
      this.confirmModal = this.modal.confirm({
        nzTitle: '<b>Save Changes?</b>',
        nzContent: '<p>You have unsaved changes, do you want to save your changes before closing?</p>',
        nzOkText: 'Save',
        nzOkType: 'primary',
        nzCancelText: dontSaveText,
        nzOkLoading: this.isSaveAndClose,
        nzClosable: true, // Modal can be closed with the close button
        nzOnOk: () => {
          this.resetFlags();
          this.isSaveAndClose = true;
          this.confirmModal.destroy();
          return this.saveAndCloseProposal()
            .then(() => {
              this.isSaveAndClose = false;
              this.saveAndCloseProposal();
            })
            .catch((error) => {
              this.isSaveAndClose = false;
              console.error('Error saving Customer:', error);
              // Optionally show an error message to the user
            });
        },
        nzOnCancel: () => {
          const clickedButton = (event.target as HTMLElement).textContent?.trim();
          if (clickedButton === dontSaveText) {
            // Close both the confirm card and the modal
            this.confirmModal.destroy();
            this.resetForm();
            this.handleCancelLeadProposal();
          }
          else {
            this.confirmModal.destroy();
          }
        },

        nzStyle: { top: '250px' },
        nzClassName: 'custom-modal-content',
      });
    } else {
      this.resetForm();
      this.handleCancelLeadProposal();
    }
  }



  // _________________________Muhammad Mehmood Start Date 08/29/2024__________________________

  testingHide: CustomUploadFile
  selectedFilses: CustomUploadFile[] = [];
  @ViewChild('carouselContainer', { static: false }) carouselContainer!: ElementRef<HTMLDivElement>;
  showNavButtons = false;
  currentIndex = 0;


  uploadFiles(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.UploadFile.subscribe((data: CustomUploadFile[]) => {
      data.filter(file => this.isImageFile(file));
      this.selectedFilses = [...data, ...this.selectedFilses];
      this.onResize();
    });
  }
  isImageFile(file: CustomUploadFile): boolean {
    const fileName = file.name.toLowerCase();
    const fileType = file.type;

    return (
      fileType === 'image/png' ||
      fileType === 'image/jpeg' ||
      fileName.endsWith('.png') ||
      fileName.endsWith('.jpeg')
    );
  }
  // this.testingHide = this.selectedFiless.find(f => f.name.includes('png') || f.name.includes('jpeg'));
  // console.log('testingHide',this.testingHide);

  onResize() {
    this.updateNavButtonsVisibility();
  }

  private updateNavButtonsVisibility() {
    requestAnimationFrame(() => {
      const container = this.carouselContainer.nativeElement;
      if (this.selectedFilses.length > 0) {
        this.showNavButtons = container.scrollWidth > container.clientWidth;
      } else {
        this.showNavButtons = false;
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  previous() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.scrollToCurrentIndex();
    }
  }

  next() {
    const container = this.carouselContainer.nativeElement;
    const maxIndex = this.selectedFilses.length - Math.floor(container.clientWidth / 130);
    if (this.currentIndex < maxIndex) {
      this.currentIndex++;
      this.scrollToCurrentIndex();
    }
  }

  scrollToCurrentIndex() {
    const container = this.carouselContainer.nativeElement;
    const scrollAmount = this.currentIndex * 130; // 120px (width) + 10px (margin)
    container.scrollTo({ left: scrollAmount, behavior: 'smooth' });
  }

  getFileIcon(file: CustomUploadFile): string | null {
    const fileType = file.type;
    if (fileType.includes('pdf')) {
      return 'assets/Attachment/documentPDF.svg';
    } else if (fileType.includes('excel') || fileType.includes('spreadsheet')) {
      return 'assets/Attachment/documentXLS.svg';
    } else if (fileType.includes('word')) {
      return 'assets/Attachment/documentDOC.svg';
    } else if (fileType.includes('zip')) {
      return 'assets/Attachment/documentZIP.svg';
    } else if (fileType.includes('text') || fileType.includes('json')) {
      return 'assets/Attachment/documentGeneric.svg';
    } else if (fileType.includes('ppt') || fileType.includes('presentation')) {
      return 'assets/Attachment/documentPPT.svg';
    } else if (fileType.includes('video')) {
      return null;
    } else if (fileType.includes('image')) {
      return null;
    } else {
      return null;
    }
  }
  onClick(): void {
    let filteredImages = this.selectedFilses.filter(f =>
      f.name.includes('png') || f.name.includes('jpeg')
    );
    const images = filteredImages.map(file => ({
      src: file.thumbUrl,
      alt: file.name
    }));
    this.nzImageService.preview(images, {
      nzZoom: 1.0,
      nzRotate: 0
    });
  }



  playVideo(file: CustomUploadFile): void {
    const videoElement: HTMLVideoElement = this.carouselContainer.nativeElement.querySelector(`#video-${file.id}`);
    if (videoElement) {
      videoElement.style.display = 'block'; // Show the video player
      videoElement.play();
    }
  }
  CreateNewFile(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  viewAll(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.selectedFilessResponse = this.selectedFilses;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.removeFile.subscribe((file: CustomUploadFile) => {
      this.removeFile(file);
    });
    modalRef.componentInstance.Test.subscribe((data: CustomUploadFile[]) => {
      this.selectedFilses = [...data, ...this.selectedFilses];
      this.onResize();
    });
  }
  imageData: string;
  uploadFilesUpdate(fileq: CustomUploadFile): void {
    const modalRef = this.modal.create({
      nzContent: AttachmentAnnotationComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.DrawerImge = fileq;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.imageDataRes.subscribe((data: any) => {
      const newImageUrl = data;
      let test1234 = this.selectedFilses.forEach(activity => {
        activity.thumbUrl = newImageUrl;
      });
    });
  }
  AnnotationFilesUpdate(): void {
    const modalRef = this.modal.create({
      nzContent: PhotoDrawComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  removeFile(file: CustomUploadFile): void {
    this.selectedFilses = this.selectedFilses.filter(item => item.uid !== file.uid);
  }

  // _________________________Muhammad Mehmood End Date 08/29/2024_____________________________


  // Cost Grid methods start
  isRowCreated: boolean = false;
  allChecked: boolean = false;
  indeterminate = false;
  validationMessageShown: boolean = false;
  isTableLocked: boolean = true;
  collapsedRowIndex: number | null = null;
  activeRowIndex: number | null = null;
  editId: number | null = null;
  editingTitle: string = '';
  showDollarFormatter = false;
  activeRow: number | null = null;
  hasError: boolean = false;
  showReadOnly: boolean = true;
  isSelectOpen: boolean = false;
  validationStates: { [key: string]: NzStatus } = {};

  startEdit(id: number): void {
    this.editId = id;
    const item = this.items.find(d => d.id === id);

    if (item) {
      item.isEditable = true;
    }
  }

  stopEdit(id: number): void {
    if (this.editId !== null && !this.isSelectOpen) { // Ensure the select dropdown state is considered
      const item = this.items.find(d => d.id === this.editId);

      if (item) {
        if (item.isNew) {
          this.validateNewItem(item); // Validate new item
        } else {
          if (this.isMissingRequiredFields(item)) {
            console.log('Validation failed for item', id);
            item.showValidationMessage = true;
            item.backgroundColor = '#ffeeea'; // Apply error color if validation fails
            return; // Prevent editing from stopping if validation fails
          }
          this.updateCostCodeTitle(this.editId); // Update title or other fields
        }
        item.isEditable = true; // Stop editing when clicking outside
      }
    }
    this.editId = null; // Reset editId to null
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = (event.target as HTMLElement).closest('.table-cell-td-color');
    if (!clickedInside && this.editId !== null) {
      const item = this.items.find(d => d.id === this.editId);
      if (item && item.isEditable) {
        this.stopEdit(this.editId);
      }
    }
  }
  onDropdownOpenChange(isOpen: boolean, id: number): void {
    if (!isOpen) {
      const item = this.items.find(d => d.id === id);
      if (item && !item.costCodeId) {
        item.showValidationMessage = true;
        return;
      }
      this.stopEdit(id);
    }
  }

  isInputDisabled: boolean = false;

  onSelectOpenChange(open: boolean): void {
    this.isSelectOpen = open;
  }

  item = {
    markupId: 40417,
    markupValue: '',
    isEditable: false
  };

  onMarkupChange(selectedValue: number, item: any): void {
    item.markupId = selectedValue;
    item.markupValue = 0;
    this.updateBuilderCost(item);
  }


  formatMarkupValue(value: string, markupId: string): string {
    if (!value) return value;

    switch (markupId) {
      case '%':
        return `${value}%`;
      case '$':
      case '$/Unit':
        return `$${value}`;
      case '--':
        return value;
      default:
        return value;
    }
  }




  validateCostCode(item: any): void {
    const nzSelectElement = document.querySelector(`nz-select[name="costCodeId_${this.i}"] .ant-select-selector`);
    if (!item.costCodeId) {
      item.showValidationMessage = true;
      if (nzSelectElement) {
        (nzSelectElement as HTMLElement).style.borderColor = '#e6f6ff';
      }
    } else {
      item.showValidationMessage = false;
      if (nzSelectElement) {
        (nzSelectElement as HTMLElement).style.borderColor = ''; // Reset to default
      }
    }
    this.changeDetectorRef.detectChanges();
  }
  isMissingRequiredFields(item): string {
    // Define the logic to check for missing required fields
    if (!item.costCodeId) {
      return '#efe6ff'; // Return color if required fields are missing
    }
    return ''; // Return empty string if no missing fields
  }
  validateNewItem(item: any): void {
    item.showValidationMessage = !item.costCodeId;
    if (!item.showValidationMessage) {
      item.isNew = false;
    }
  }
  updateCostCodeTitle(id: number): void {
    const item = this.items.find(d => d.id === id);
    if (item && item.costCode) {
      item.costCode.title = this.editingTitle;
    }
  }
  highlightRow(index: number): void {
    this.activeRow = index;
  }

  handleOutsideClick(event: MouseEvent): void {
    const clickedInsideTable = (event.target as HTMLElement).closest('tr');
    const clickedInsideSelect = (event.target as HTMLElement).closest('.ant-select-dropdown');
    if (!clickedInsideTable && !clickedInsideSelect && !this.isSelectOpen && !this.isInputDisabled) {
      const hasMissingRequiredFields = this.items.some(item => this.isMissingRequiredFields(item));
      this.items.forEach(item => {
        const rowElement = document.querySelector(`tr[data-id="${item.id}"]`);
        if (hasMissingRequiredFields) {
          if (this.isMissingRequiredFields(item)) {
            // Apply error class and remove other classes
            rowElement?.classList.add('error-background');
            rowElement?.classList.remove('default-color', 'clicked-color');
            item.showValidationMessage = true; // Show validation message
          } else {
            // Ensure rows without missing fields are reset
            rowElement?.classList.remove('error-background');
            rowElement?.classList.add('default-color');
            item.showValidationMessage = false;
          }
        } else {
          console.log('No missing fields, hiding editable rows');
          // If a required field is filled, maintain the default color
          if (item.isEditable) {
            rowElement?.classList.remove('error-background', 'clicked-color');
            rowElement?.classList.add('default-color');
          } else {
            // Remove error and clicked classes, apply default color
            rowElement?.classList.remove('error-background', 'clicked-color');
            rowElement?.classList.add('default-color');
          }
          item.isEditable = false;
          item.showValidationMessage = false;
        }
      });
      this.editId = null;
      this.activeRow = null;
    }
  }



  totalOwnerPrice: number = 0;
  totalBuilderCost: number = 0;
  estimatedProfit: number = 0;

  // addNewRow(): void {
  //   // Check if any rows are incomplete (missing costCodeId)
  //   const incompleteRows = this.items.some(item => !item.costCodeId);
  
  //   // Always proceed to add a new row, but mark incomplete rows for validation
  //   if (incompleteRows) {
  //     this.items.forEach(item => {
  //       if (!item.costCodeId) {
  //         item.showValidationMessage = true; // Highlight incomplete rows
  //       }
  //     });
  //   }
  
  //   // Generate a new ID for the new row
  //   const newId = this.items.length > 0 ? Math.max(...this.items.map(item => item.id)) + 1 : 1;
  
  //   // Create a new empty row
  //   const newItem = {
  //     id: newId,
  //     title: '',
  //     description: '',
  //     quantity: 1,
  //     unit: 0,
  //     unitCost: 0,
  //     costTypeId: null,
  //     builderCost: 0,
  //     markupValue: 0,
  //     markupId: 40417,
  //     ownerPrice: 0,
  //     margin: 0,
  //     profit: 0,
  //     markAsId: 0,
  //     markupChange: '',
  //     costCodeId: null,
  //     showValidationMessage: false,
  //     isEditable: true
  //   };
  
  //   // Add the new row to the items array
  //   this.items.unshift(newItem);
  
  //   // Update totals to reflect the new row addition
  //   this.updateTotals();
  
  //   // Detect changes in the view
  //   this.changeDetectorRef.detectChanges();
  
  //   // Call the save method to process any completed rows (optional)
  //   this.saveCompletedRows();

    
  // }


  addNewRow(): void {
    // Check if any rows are incomplete (missing costCodeId)
    const incompleteRows = this.items.some(item => !item.costCodeId);

    // Always proceed to add a new row, but mark incomplete rows for validation
    if (incompleteRows) {
      this.items.forEach(item => {
        if (!item.costCodeId) {
          item.showValidationMessage = true; // Highlight incomplete rows
        }
      });
    }

    // Generate a new ID for the new row
    const newId = this.items.length > 0 ? Math.max(...this.items.map(item => item.id)) + 1 : 1;
  
    // Create a new empty row with the latest markupValue
    const newItem = {
      id: newId,
      title: '',
      description: '',
      quantity: 1,
      unit: 0,
      unitCost: 0,
      costTypeId: null,
      builderCost: 0,
      markupValue: '', 
      markupId: 40417,
      ownerPrice: 0,
      margin: 0,
      profit: 0,
      markAsId: 0,
      markupChange: '',
      costCodeId: null,
      showValidationMessage: false,
      isEditable: true
    };

    // Add the new row to the items array
    this.items.unshift(newItem);

    // Update totals to reflect the new row addition
    this.updateTotals();

    // Detect changes in the view
    this.changeDetectorRef.detectChanges();

    // Call the save method to process any completed rows (optional)
    this.saveCompletedRows();
  }
  


  // onAdjustMarkup(): void {
   

  //   const modalRef = this.modal.create({
  //     nzContent: PercentageMarkupComponent,
  //     nzTitle: null, 
  //     nzFooter: null, 
  //   });


  //   modalRef.componentInstance.cancel.subscribe(() => {
  //     modalRef.destroy();
  //   });
  // }


  onAdjustMarkup(): void {
    const modalRef = this.modal.create({
      nzContent: PercentageMarkupComponent,
      nzTitle: null,
      nzFooter: null,
    });
  
    // Listen for the percentage value when the user applies it
    modalRef.componentInstance.apply.subscribe((percentage: number) => {
      console.log('Applied percentage:', percentage);
      
      // Bind the percentage value to all items in the grid
      this.items.forEach(item => {
        item.markupValue = percentage; // Update each item's markupValue
      });
  
      // Optional: Update totals or any other calculations
      this.updateTotals();
  
      // Close the modal after applying the value
      modalRef.destroy();
    });
  
    // Handle cancel
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  
  
  updateBuilderCost(item: any): void {
    const unitCost = parseFloat(item.unitCost) || 0;
    const quantity = item.quantity || 1;
    const markupValue = parseFloat(item.markupValue) || 0;

    // Calculate builder cost
    item.builderCost = unitCost * quantity;

    // Calculate owner price based on markupId
    if (item.markupId === 40417) {
      // Percentage markup
      item.ownerPrice = item.builderCost + (item.builderCost * (markupValue / 100));
    } else if (item.markupId === 40418 || item.markupId === 40419) {
      // Dollar markup
      item.ownerPrice = item.builderCost + markupValue;
    } else {
      item.ownerPrice = item.builderCost;
    }

    // Calculate margin and ensure it is a number
    item.margin = item.ownerPrice > 0
      ? ((item.ownerPrice - item.builderCost) / item.ownerPrice) * 100
      : 0;

    // Ensure margin is a number
    item.margin = parseFloat(item.margin.toFixed(2));

    // Calculate profit
    item.profit = item.ownerPrice - item.builderCost;
    if (item.profit < 0) {
      item.profit = 0;
    }

    // Call updateTotals if needed
    this.updateTotals();
  }


  updateTotals(): void {
    this.totalOwnerPrice = this.items.reduce((sum, item) => sum + item.ownerPrice, 0);
    this.totalBuilderCost = this.items.reduce((sum, item) => sum + item.builderCost, 0);
    this.estimatedProfit = this.items.reduce((sum, item) => sum + item.profit, 0);
  }
  isNumber(value: any): boolean {
    return typeof value === 'number' && !isNaN(value);
  }

  saveCompletedRows(): void {
    const completedRows = this.items.filter(item => !!item.costCodeId);

    if (completedRows.length === 0) {
      return; // Exit if there are no completed rows
    }

    const payload = {
      createCostItemRequest: {
        companyParameterId: 1,
        formNameId: 37,
        createCostItemParameterRequests: completedRows.map(item => ({
          id: item.id,
          title: item.title,
          description: item.description,
          quantity: item.quantity,
          unit: item.unit | 0,
          unitCost: item.unitCost,
          costTypeId: item.costTypeId,
          builderCost: item.builderCost,
          markupValue: item.markupValue,
          markupId: item.markupId,
          ownerPrice: item.ownerPrice,
          margin: item.margin,
          profit: item.profit,
          markAsId: item.markAsId,
          costCodeId: item.costCodeId,
          showValidationMessage: item.showValidationMessage,
          isEditable: item.isEditable
        }))
      }
    };

    this.formFieldValues = [payload];

  }



  trackById(index: number, item: any): number {
    return item.id;
  }
  collapseRow(item: any): void {
    item.isCollapsed = !item.isCollapsed;
  }
  onCostCodeBlur(item: any): void {
    if (item.costCodeId) {
      this.isTableLocked = true; // Lock the table when the cost code field is filled
    }
  }
  isAnyRowChecked(): boolean {
    return this.items.some(item => item.checked);
  }
  // Handle the state of the header checkbox
  onAllChecked(checked: boolean): void {
    this.items.forEach(item => item.checked = checked);
    this.updateHeaderCheckboxState();
  }
  // Handle the state of each item checkbox
  onItemChecked(checked: boolean, index: number): void {
    this.items[index].checked = checked;
    this.updateHeaderCheckboxState();
  }
  // Update the header checkbox state based on the row checkboxes
  updateHeaderCheckboxState(): void {
    const allSelected = this.items.length > 0 && this.items.every(item => item.checked);
    const noneSelected = this.items.every(item => !item.checked);
    this.allChecked = allSelected;
    this.indeterminate = !allSelected && !noneSelected;
  }
  deleteRow(index: number): void {
    if (this.items.length > 1 || (this.items.length === 1 && index === 0)) {
      try {
        const deletedRow = this.items[index];
        const deletedRowId = deletedRow.id;
        console.log(`Deleting row with ID: ${deletedRowId}`);
        this.items.splice(index, 1);
        this.updateHeaderCheckboxState();
        // Show success toast message
        this.toastService.success(`Item deleted`);
        if (this.items.length === 0) {
          this.isRowCreated = false;
          this.allChecked = false;
        }
        if (this.collapsedRowIndex === index) {
          this.collapsedRowIndex = null;
        } else if (this.collapsedRowIndex > index) {
          this.collapsedRowIndex--;
        }
      } catch (error) {
        console.error('Error deleting row:', error);
        // Show error toast message
        this.toastService.error('Failed to delete item. Please try again.');
      }
    } else {
      console.log('Cannot delete the only remaining row.');
      // Show error toast message
      this.toastService.error('Cannot delete the only remaining row.');
    }
  }
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const tableElement = document.querySelector('nz-table');
    if (tableElement && !tableElement.contains(event.target as Node)) {
      const allFieldsFilled = this.items.every(item => item.costCodeId);
      if (allFieldsFilled) {
        this.highlightIncompleteRows();
      } else {
        this.lockTable();
      }
    }
  }
  lockTable(): void {
    this.isTableLocked = true;
    // Disable inputs or use a read-only class
    this.items.forEach(item => {
      item.readOnly = true; // Or add a CSS class to manage the lock state
    });
  }
  highlightIncompleteRows(): void {
    // Change the background color of the ng-container for items that are not filled
    this.items.forEach(item => {
      if (!item.costCodeId) {
        item.backgroundColor = '#ffeeea'; // Apply the background color
      }
    });
  }
  //  confirmModal: import("ng-zorro-antd/modal").NzModalRef<unknown, any>;
  ConfirmDelete(index: number): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete Item?',
      nzContent: 'Are you sure you want to delete the selected Lead Proposal item?',
      nzOkDanger: true,
      nzOkText: 'Delete',
      nzCancelText: 'Cancel',
      nzModalType: 'confirm',
      nzAutofocus: 'ok',
      nzCentered: true,
      nzOnOk: () => this.deleteRow(index)
    });
  }
  getSelectedOptionName(selectedId: number): string | null {
    const selectedOption = this.proposalOption.find(option => option.id === selectedId);
    return selectedOption ? selectedOption.name : null;
  }
  getSelectedOptionNames(id: number): string {
    console.log('Fetching name for ID:', id);  // Debug log
    const option = this.proposalOption.find(opt => opt.id === id);
    console.log('Found option:', option);      // Debug log
    return option ? option.name : '--';
  }
  // Cost Grid methods end

  createAndModifiedByData(id: number) {
    const payload: FilterFooterAudit = {
      id: id,
      formCode: 'LOLP'
    }
    this.FooterService.createdAndModifyUser(payload).subscribe(res => {
      this.footerResponse = res.result;

    })
  }

}