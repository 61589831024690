<nz-page-header>
    <nz-page-header-title>
      <h1 style="font-size: 30px;">Reports</h1>
    </nz-page-header-title>
  </nz-page-header>
  <div class="container-fuild mb-4 m-3">
    <div class="row">
      <h1 class="mt-5" style="color: #626262;">Sales</h1>
  </div>
  <div class="card-container">
    <div class="row mt-2">
        <div class="col-4" >
            <div class="card" style="width: 18rem; height: 250px;">
                <div class="star-icon">
                    <i class="fas fa-star"></i>
                </div>
                <img src="/assets/saless1.svg" alt="Chart 1"> 
                <div class="card-title text-start">Lead Activities by Salesperson</div>
                <div class="card-text text-start">A summary of lead activities grouped by each salesperson in your company.</div>
            </div>
        </div>
        <div class="col-4" >
            <div class="card" style="width: 18rem; height: 250px; ">
                <div class="star-icon">
                    <i class="fas fa-star"></i>
                </div>
                <img src="/assets/saless2.svg" alt="Chart 1"> 
                <div class="card-title text-start" >Lead Count by Salesperson</div>
                <div class="card-text text-start">Track the number of leads for each status grouped by assigned salesperson.</div>
            </div>
        </div>
        <div class="col-4" >
            <div class="card" style="width: 18rem; height: 250px;">
                <div class="star-icon">
                    <i class="fas fa-star"></i>
                </div>
                <img src="/assets/saless3.svg" alt="Chart 1"> 
                <div class="card-title text-start" >Lead Status by Source</div>
                <div class="card-text text-start">An overview of the status of each lead coming from your lead sources.</div>
            </div>
        </div>
    </div>  
</div>  
    </div>
