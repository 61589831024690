import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ResponseModelArray } from 'src/Models/responseMessage.model';

@Injectable({
  providedIn: 'root'
})
export class LeadActivitiesDeleteService {

  private base_Url: string = environment.apiUrl + 'LeadActivity/Delete-RangeLeadActivity';

  constructor(private http: HttpClient) {}

  leadActivitiesDelete(globalIds: string[]): Observable<ResponseModelArray<any>> {
    const url = this.base_Url;

    return this.http.post<ResponseModelArray<any>>(url, globalIds, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

}