import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, tap, throwError } from 'rxjs';
import { CreateSubVendorCertificateParameterForPdfRequest, CreateSubVendorCertificateParameterRequest, SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';

import {   CreateSubVendorRequest } from 'src/Models/SubVendor/createSubvendorRequest.model';
import { UpdateSubVendorRequests } from 'src/Models/SubVendor/updateSubvendorRequest.model';
import { ApiResponseModel } from 'src/Models/apiResponse.model';
import { ResponseModel, ResponseModelArray } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SubVendersService {

 private baseUrl: string = environment.apiUrl + 'SubVendor/';


  constructor(private http: HttpClient) {}






  getData(): Observable<ResponseModelArray<SubVendorResponse>> {
    return this.http.get<ResponseModelArray<SubVendorResponse>>(`${this.baseUrl}getAll`);
  }


  getDataColumns(): Observable<ApiResponseModel> {
    return this.http.get<ApiResponseModel>(`${this.baseUrl}getAll-Columns`);
  }


  // postDataCertificate(subVendorId: number, internalUserFormData: CreateSubVendorCertificateParameterRequest): Observable<ResponseModel<SubVendorResponse>> {
  //   const apiUrl = `${this.baseUrls}create-certificate-entry`;
  //   return this.http.post<any>(apiUrl, { subVendorId, ...internalUserFormData }).pipe(
  //     catchError((error: any) => {
  //       console.error('Error:', error);
  //       return throwError(error);
  //     })
  //   );
  // }

  postDataCertificate(subVendorId: number, internalUserFormData: CreateSubVendorCertificateParameterRequest): Observable<ResponseModel<SubVendorResponse>> {
    if (!internalUserFormData.expirationDate) {
      console.log('Skipping API call for certificate with null expirationDate');
      return of(null); // Skip the API call if expirationDate is null
    }

    const apiUrl = `${this.baseUrls}create-certificate-entry`;
    return this.http.post<any>(apiUrl, { subVendorId, ...internalUserFormData }).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }



    private baseUrls: string = environment.apiUrl + 'SubVendor/';


    createAttachmentForDocument(pdfRequest: CreateSubVendorCertificateParameterForPdfRequest): Observable<any> {
      const formData: FormData = new FormData();
      formData.append('SubVendorId', pdfRequest.subVendorId.toString());
      formData.append('SubVendorCertificateSetupId', pdfRequest.subVendorCertificateSetupId.toString());
      if (pdfRequest.pdfFile instanceof Blob) {
          formData.append('pdfFile', pdfRequest.pdfFile, pdfRequest.pdfFile.name);
      } else {
          const blob = new Blob([pdfRequest.pdfFile], { type: 'application/pdf' });
          formData.append('pdfFile', blob, 'file.pdf');
      }
      const apiUrl = `${this.baseUrls}create-certificate-pdf`;
      return this.http.post<any>(apiUrl, formData).pipe(
          tap(() => {
              console.log('PDF file uploaded successfully');
          }),
          catchError(error => {
              console.error('PDF Upload error:', error);
              return throwError(error);
          })
      );
  }






  getDataByParameter(
    columnName: string,
    searchParameter: string,
    contactTypeIds: number[] = [],
    divisionTradeIds: number[] = []
  ): Observable<ApiResponseModel> {
    const data = {
      columnName: columnName,
      searchParameter: searchParameter,
      statusSetupId: contactTypeIds,
      divisionTradeIds: divisionTradeIds
    };

    return this.http.post<ApiResponseModel>(`${this.baseUrl}getAll-byParameter`, data)
      .pipe(
        catchError((error: any) => {
          console.log('Error:', error);
          return throwError(error);
        })
      );
  }


  updateData(data: UpdateSubVendorRequests): Observable<ApiResponseModel> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }


  postData(data: CreateSubVendorRequest): Observable<ResponseModel<SubVendorResponse>> {
    const apiUrl = `${this.baseUrl}`;
    return this.http.post<any>(apiUrl, data).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  subVendorgetDataId(id: number): Observable<ResponseModel<SubVendorResponse>> {
    return this.http.get<ResponseModel<SubVendorResponse>>(`${this.baseUrl}getBy-Id?id=${id}`);
  }


  deleteData(globalId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}?globalId=${globalId}`);
  }

  getDataId(id: string): Observable<ApiResponseModel> {
    return this.http.get<ApiResponseModel>(`${this.baseUrl}getBy-Id?id=${id}`);
  }

  deleteDataByBookingId(bookingId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}?bookingId=${bookingId}`);
  }






  FileDownload(objectName: string): Observable<Blob> {
    const url = `${this.baseUrl}?objectName=${encodeURIComponent(objectName)}`;
    return this.http.get(url, { responseType: 'blob' }).pipe(
      tap(() => {
        console.log('File download initiated');
      }),
      catchError(error => {
        console.error('File download error:', error);
        return throwError(error);
      })
    );
  }



}
