import { EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ChangeDetectorRef,  } from '@angular/core';
import { JobsSummaryMapComponent } from '../jobs-summary-map/jobs-summary-map.component';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { Subscription } from 'rxjs';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { FilterSearchParameter, LookupFilterResponse } from 'src/app/shared/component/Models/LookupModels';
import { JobInformationResponse } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';

@Component({
  selector: 'app-job-summary-db',
  templateUrl: './job-summary-db.component.html',
  styleUrls: ['./job-summary-db.component.css']
})
export class JobSummaryDbComponent implements OnInit, OnDestroy{
  @Output() cancel = new EventEmitter<void>();
  private subscriptions: Subscription[] = [];
  private subscription: Subscription;
  private summaryfilterSubscription : Subscription;
  private displayColumnSubscription: Subscription;
  isCollapsed = false;
  isClicked: boolean = false;
  composenewmessage: boolean = false;
  displayJobName: string;
  selectedJobId: number = 0;
  @Input() JobInformationResponse: JobInformationResponse; 
  carouselPosition: number = 0;
  cardWidth: number = 240; // Adjust this based on your card width
  numberOfCards: number = 3; // Adjust this based on your number of cards
  containerWidth: number = 720; // Adjust this based on the width of your container
  managers: any[] = []; // Replace 'any' with the actual type of your manager objects
  isLoadingOne = false;
  isLoadingTwo = false;
  selectionFrom: string = 'DB' ;
  defaultFilterIdSelected : number = 0;
  //  Grid Properties
  PAGE_ID: number = -1;
  displayedColumns: string[] = [];
  selectedFilter: number = -1;
  isFilterApplied: boolean = false;
  lookupFilterResponse: LookupFilterResponse[] = [];
  defaultFilterId_selected: number = 0
  currentPage = 1;
  pageSize = 50;
  filterSearchParameter: FilterSearchParameter;
  displayedData: JobInformationResponse[] = [];


  constructor(private modal: NzModalService,
    public accessLocalStorageService: AccessLocalStorageService,
    private jobService: JobInformationService,
    private standardViewService: StandardViewForAllService,
    private LookupFilterService: LookupFilterService,
    private changeDetectorRef: ChangeDetectorRef,
    private internalUserService: InternalUserService,
    ) {
      if (this.summaryfilterSubscription) {
        this.summaryfilterSubscription.unsubscribe();
      }
      if (this.subscription) {
          this.subscription.unsubscribe();
      }
      // if (this.InternalstandardViewFilterSubscription) {
      //   this.InternalstandardViewFilterSubscription.unsubscribe();
      // }
      console.clear();
    this.accessLocalStorageService.setUserLoginId(1);
    this.displayJobName = this.accessLocalStorageService.getJobName();
 

    // this.displayedColumns = [];
  }
  ngOnInit(): void {
    this.internalUserService.setSelectedFilterId(0); // New Changes By Aamir Ali - 31-May-2024
    if(this.summaryfilterSubscription){
      this.summaryfilterSubscription.unsubscribe()
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
  }

    this.PAGE_ID = 3;

    this.selectedJobId  = this.accessLocalStorageService.getJobId();

    this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom); // New Changes By Aamir Ali - 22-Apr2024

    this.displayedColumns = [];
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    // Add subscriptions to the array
    this.subscriptions.push(
      this.standardViewService.$displaySelectedColApply.subscribe(colName => {
        if (colName.length > 0) {
          this.displayedColumns = colName;
          this.changeDetectorRef.detectChanges();
        }
      })
    );

    this.subscriptions.push(
      this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
        this.selectedFilter = standardType != -1 ? standardType : standardType;
      })
    );
 
    // this.jobService.$isUserAppliedFilter.subscribe(filter => {
    //   if (filter) {
    //     this.isFilterApplied = filter;
    //   } else {
    //     this.isFilterApplied = filter;
    //   }
    // });
    // // Add subscriptions to the array
    // this.subscriptions.push(
    //   this.standardViewService.$displaySelectedColApply.subscribe(colName => {
    //     if (colName.length > 0) {
    //       this.displayedColumns = colName;
    //       this.changeDetectorRef.detectChanges();
    //     }
    //   })
    // );

    // this.subscriptions.push(
    //   this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
    //     this.selectedFilter = standardType != -1 ? standardType : standardType;
    //   })
    // );
 
    this.jobService.$isUserAppliedFilter.subscribe(filter => {
      if (filter) {
        this.isFilterApplied = filter;
      } else {
        this.isFilterApplied = filter;
      }
    });

  }
    getStandardFilterByFormPageId(pageId:number, type: string) {
      this.LookupFilterService.getStandardFilterByFormPageId(pageId);
      this.summaryfilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(result =>{
      if(result){
        this.lookupFilterResponse = result.result;
       let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
       this.jobService.setSelectedFilterId(defaultFilterId);
       let id = -1;
       if(type === this.selectionFrom){
        this.defaultFilterIdSelected = defaultFilterId;
        this.accessLocalStorageService.setSelectedFilterId(defaultFilterId); 
        id = this.accessLocalStorageService.getSelectedFilterId();
       }else{
        id = this.accessLocalStorageService.getSelectedFilterId();
       }
       if(id !== -1){
        this.customerGridTableData(1, this.PAGE_ID , id ,this.currentPage,this.pageSize);
       }
  
      }
      });
    }
  customerGridTableData(companyId: number, formNameId: number, lookUpFilterId: number, page: number, pageSize: number) {
    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId: lookUpFilterId,
      page: page,
      pageSize: pageSize,
      jobInformationId: this.selectedJobId // update by Mehmood for Data filter Againts of Update Job ID
    };

    // this.jobService.getAllCustomerinformationByFilter(this.filterSearchParameter);
    // this.filterSubscription = this.jobService.$getJobSummary.subscribe(result => {
    //   if (result) {
    //     this.displayedData = result?.result?.rows;
    //     console.log("JOB SUMMARY");
        
    //   }
    // });
  }

  toggleClicked() {
    this.isClicked = !this.isClicked;
  }

  ComposeNewMessage() {
    this.composenewmessage = true;
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }


  moveCarousel(direction: string): void {
    const maxPosition = -((this.numberOfCards - 1) * this.cardWidth);
    const minPosition = 0;

    if (direction === 'left' && this.carouselPosition > maxPosition) {
      this.carouselPosition -= this.cardWidth;
    } else if (direction === 'right' && this.carouselPosition < minPosition) {
      this.carouselPosition += this.cardWidth;
    }
  }

  loadOne(): void {
    this.isLoadingOne = true;
    setTimeout(() => {
      this.isLoadingOne = false;
    }, 1000);
  }

  ViewTime(): void {
    const modalRef = this.modal.create({
      nzContent: JobsSummaryMapComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  getSelectedJobId(item: number) {
    this.selectedJobId = item;
  }

  ngOnDestroy(): void {
    this.LookupFilterService.getStandardFilter.next(null); // New Changes By Aamir Ali - 31-May-2024
    localStorage.removeItem('Selected_Filter_Id'); // New Changes By Aamir Ali - 31-May-2024
    if(this.summaryfilterSubscription){
      this.summaryfilterSubscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
  }
  }

}