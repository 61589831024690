<nz-modal
nzWidth="80%"
[(nzVisible)]="newSurveyshandle"
[nzTitle]="modalTitle1"
[nzFooter]="modalFooter1"
(nzOnCancel)="newSurveysModelCancel()"
[nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '80vh', 'overflow-y': 'auto',}"
[nzStyle]="{ top: '15px' }">
  <ng-template #modalTitle1>
    <span class="text-secondary">ZH-047 Lot 186 SINCLAIRS RD DEANSIDE - DISPLAY</span>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0 fw-medium">   Surveys
      </h1>
    </div>
  </ng-template>
  <form [formGroup]="newSurvey">
  <div class="content" *nzModalContent>
    <div class="row famly-inn gx-3">
        <div class="col-12">
                  <div class="row">
                    <div class="col mt-1">
                      <nz-card style="width:95%px;" [nzBordered]="false" nzTitle="Email Invitation" >
                       <div class="row">
                        <div class="col">
                        <label class="sub-text">Email body</label>
                       </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <textarea formControlName="emailBody" rows="4" nz-input [(ngModel)]="inputValue"></textarea>
                        </div>
                      </div>
                      </nz-card>
                    </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col">

                        <nz-card style="width:95%px;" [nzBordered]="false" nzTitle="Survey Information" [nzExtra]="extraTemplate" >
                          <div class="row">
                           <div class="col mt-0">
                           <label class="sub-text">Survey Name</label>
                          </div>
                         </div>
                         <div class="row">
                           <div class="col mt-2">
                            <label for="">Client Survey</label>
                           </div>
                         </div>
                         <div class="row">
                          <div class="col mt-2">
                           <label class="sub-text">Owner Name</label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col mt-2">
                            <p>MSA INVESTMENT HOLDING GROUP PTY LTD ATF MSA INVESTMENT TRUST</p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col mt-2">
                            <label class="sub-text">Release Date</label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <nz-button-group>
                              <button nz-button>Choose Date</button>
                              <button nz-button>Link to scheddule Item</button>
                            </nz-button-group>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-2">
                            <label class="sub-text">Release Date<span style="color: red;">*</span></label>
                          </div>
                          <div class="col-2">
                            <label class="sub-text">Time</label>
                          </div>
                        </div>
                        <div class="row">
                      <div class="col-2"> 
                        <nz-date-picker formControlName="RreleaseDate" [(ngModel)]="date" (ngModelChange)="onChange($event)"></nz-date-picker>
                      </div>
                      <div class=" size col-1">
                         <nz-time-picker formControlName="time" [(ngModel)]="time" nzFormat="HH:mm"></nz-time-picker> 
                      </div>
                        </div>
                        <ng-template #extraTemplate>
                          <label class="sub-text">Unreleased</label>
                        </ng-template>
                         </nz-card>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col mt-2">
                        <nz-card style="width:95%px;" [nzBordered]="false" nzTitle="Client Survey (Preview)" >
                       <div class="row  mt-3">
                            <div class="col-3">
                              <div style="width: 120px; height: 120px; background-size: contain; background-repeat: no-repeat; background-position: center center; background-image: url('https://buildertrend.net/api/files/brandingLogo?id=6f803e39-4059-4938-9c3b-a3cc70699a52&amp;imageType=0&amp;rowVersion=22251233594');">
                              </div>
                            </div>
                            <div class="col-3">
                              <label class="sub-text">Zircon Homes T/A Xircon <br> Home</label> <br>
                              <p>20/47-51 Little Boundary Road</p> <br>
                              <p>Laverton North VIC 3026</p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col mt-2">
                              <p>Please take a moment to write a  detailed review bleow</p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              To enter the draw to win a $1000 gift card you will also need to post your review on Google, Product Review & Facebook We will send you <br>
                              the links to do this once your review has been submitted on Buildertrend.<br>
                              The winner will be announced at the end of this financial year
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              We thanks you for your time and hope you have a great day.
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <label class="sub-text">Please write a detailed review on your overall experience buliding with us.</label>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <label class="sub-text">Responce</label>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col mt-2">
                              <textarea rows="4" nz-input  [disabled]="true"></textarea>
                            </div>
                          </div>
                          <hr>
                          <div class="row">
                            <div class="col">
                              <label class="sub-text">Please rate the overall quality and value of your finished project</label>
                            </div>
                          </div>
                        </nz-card>
                      </div>
                      </div>
                </div>
            </div>
        </div>
        <ng-template #modalFooter1>
          <!-- <div class="mt-2 align-items-center"> -->
            <button nz-button nzType="default">Print</button>
            <button nz-button nzType="primary">Send</button>
            <button nz-button nzType="primary">Send & Close</button>
          <!-- </div> -->
        </ng-template>
      </form>

</nz-modal>