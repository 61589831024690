<div>
    <div class="row p-0 m-0">
        <h1 class="p-0 m-0 fw-bold">Variance Code</h1>
    </div>
</div>
<div class="card border-0 famly-inn">
    <div class="card-head border-bottom">
        <div class="row mb-0 p-2 d-flex align-items-center">
            <div class="col">
                <span class="fw-medium fs-6 ms-2">Variance Code Information </span>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row gx-2">
            <div class="col-6">
                <label for="text" class="sub-text">Title <span class="text-danger">*</span></label>
                <input type="text" [(ngModel)]="formFieldValues['title']" name="title" class="form-control formField">

            </div>

            <div class="col-6">
                <label for="text" class="sub-text">Variance Code Category<span class="text-danger">*</span></label>
                <nz-select nzShowSearch [(ngModel)]="formFieldValues['varianceCodeId']" name="varianceCodeId">
                    <nz-option [nzLabel]="'Non'" [nzValue]="0"></nz-option>
                    <nz-option *ngFor="let option of varianceCategory" [nzLabel]="option.title"
                        [nzValue]="option.id"></nz-option>
                </nz-select>
            </div>

        </div>
        <div class="row">
            <div class="col-12">
                <label for="text" class="sub-text">Sub Code Of</label>
                <nz-select nzShowSearch [(ngModel)]="formFieldValues['subVarianceCodeId']" name="subVarianceCodeId"
                    (ngModelChange)="subCodeChanged()">
                    <nz-option [nzLabel]="'-- No Parent --'" [nzValue]="0"></nz-option>
                    <nz-option *ngFor="let list of listOfOptions" [nzLabel]="list.title" [nzValue]="list.id">
                    </nz-option>
                </nz-select>
            </div>
        </div>
        <div class="row mt-2 border-bottom pb-4">
        </div>
        <div class="row mt-3 border-bottom pb-5">
            <div class="col-12">
                <label for="text" class="sub-text">Details</label>
                <textarea nz-input [nzAutosize]="{ minRows: 3, maxRows: 8 }" [(ngModel)]="formFieldValues['detail']"
                    name="detail" class="form-control formField"></textarea>

            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <label nz-checkbox [(ngModel)]="formFieldValues['isCustomerVariance']" name="isCustomerVariance"
                    class="pira-label">Customer
                    Variance</label>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12">
                <label nz-checkbox [(ngModel)]="formFieldValues['isStatus']" name="isStatus"
                    class="pira-label">isActive</label>
            </div>
        </div>

    </div>
</div>
<div>
</div>

<button nz-button *ngIf="varianceCodeResponse">
    Delete
</button>
<button nz-button [disabled]="
        !this.formFieldValues['title'] && this.formFieldValues['varianceCodeId']" (click)=" saveAndCloseButton()">
    Save & Close
</button>
<button nz-button nz-dropdown [nzTrigger]="'click'" [disabled]="
        !this.formFieldValues['title'] && this.formFieldValues['varianceCodeId']
      " [nzDropdownMenu]="menu1">
    <span nz-icon nzType="ellipsis"></span>
</button>

<nz-dropdown-menu #menu1="nzDropdownMenu">
    <ul nz-menu>
        <li nz-menu-item (click)="saveButton()">Save</li>
        <li nz-menu-item (click)="saveAndNew()">Save & New</li>
    </ul>
</nz-dropdown-menu>