import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScheduleSetupPhaseParameter, ScheduleSetupPhaseParameterResponse } from 'src/Models/CompanySettings/Schedule/PhaseSetup/Company-Settings-Schedule';
import { PhaseSetup } from 'src/Models/CompanySettings/Schedule/PhaseSetup/PhaseSetup';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class PhaseService {

  private baseUrl: string = environment.apiUrl + 'ScheduleSetup/create-PhaseSetup';
  private BASE_Url: string = environment.apiUrl + 'ScheduleSetupPhaseParameter';


  constructor(private http: HttpClient) {}

  postData(data: ScheduleSetupPhaseParameter): Observable<ResponseModelArray<ScheduleSetupPhaseParameterResponse>> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }

  getData():  Observable<ResponseModelArray<ScheduleSetupPhaseParameterResponse>> {
    return this.http.get<ResponseModelArray<ScheduleSetupPhaseParameterResponse>>(`${this.baseUrl}getAll`);
  }

  updateData(data: ScheduleSetupPhaseParameter): Observable<ScheduleSetupPhaseParameterResponse> {
    return this.http.put<ScheduleSetupPhaseParameterResponse>(`${this.baseUrl}`, data);
  }

  deleteData(globalId: string): Observable<ScheduleSetupPhaseParameterResponse> {
    return this.http.delete<ScheduleSetupPhaseParameterResponse>(
      `${this.BASE_Url}?globalId=${globalId}`
    );
  }
  getDataId(id: number): Observable<ScheduleSetupPhaseParameterResponse> {
    return this.http.get<ScheduleSetupPhaseParameterResponse>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }
}