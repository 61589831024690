import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { ScheduleItemParameterResponse, ToDoResponse } from 'src/Models/Project-Management/projectManagement';
import { AllowanceInformationResponse, CreateAllowanceInformationRequest } from 'src/Models/Selection/AllowanceClasses';
import {  CreateSelectionChoiceParameterRequest, CreateSelectionInformationRequest, SelectionChoiceParameterResponse, SelectionInformationResponse } from 'src/Models/Selection/selectionClasses';
import { ResponseModel, ResponseModelArray, TablePageResponse } from 'src/Models/responseMessage.model';
import { CreateLookupFilterRequest, FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SelectionService {

  private URL_SDUL_ITEM_BASE: string = environment.apiUrl + 'ScheduleItemParameter/';
  private URL_POST_SELECT: string = environment.apiUrl + 'SelectionInformation/';
  private URL_ALLOW_INFORM_GET_BASE: string = environment.apiUrl + 'AllowanceInformation/GetAll/';
  private select_Get_all_URL: string = environment.apiUrl + 'SelectionInformation/GetAll';
  private base_Url: string = environment.apiUrl + 'LookupFilter/';
  private selectionInformationUrl: string = environment.apiUrl + 'SelectionInformation/';
  private AllowanceInformation_Url: string = environment.apiUrl + 'AllowanceInformation/';
  private baseUrl: string = environment.apiUrl + 'JobInformation/';


  constructor(private http: HttpClient) {}


   // ------------------getdataScheduleItemParameter/GetAll by sufyan------------------------

  getAllDataScheduleItem(): Observable<ResponseModelArray<ScheduleItemParameterResponse>> {
    return this.http.get<ResponseModelArray<ScheduleItemParameterResponse>>(`${this.URL_SDUL_ITEM_BASE}GetAll `);
  }
  // ------------------getdataScheduleItemParameter/GetAll by sufyan------------------------

  getAllDataAllowanceInformation(): Observable<ResponseModelArray<AllowanceInformationResponse>> {
    return this.http.get<ResponseModelArray<AllowanceInformationResponse>>(`${this.URL_ALLOW_INFORM_GET_BASE}`);
  }

  PostDataSelectionForm(createSelection:CreateSelectionInformationRequest): Observable<ResponseModel<SelectionInformationResponse>> {
    const apiUrl = `${this.URL_POST_SELECT}`;
    return this.http.post<ResponseModel<SelectionInformationResponse>>(apiUrl, createSelection).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }


postSelectionInformation(createLead: CreateSelectionInformationRequest): Observable<ResponseModel<SelectionInformationResponse>> {
    const apiUrl = `${this.selectionInformationUrl}`;
    return this.http.post<ResponseModel<SelectionInformationResponse>>(apiUrl, createLead).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
postAllowanceInformation(createLead: CreateAllowanceInformationRequest): Observable<ResponseModel<AllowanceInformationResponse>> {
    const apiUrl = `${this.AllowanceInformation_Url}`;
    return this.http.post<ResponseModel<AllowanceInformationResponse>>(apiUrl, createLead).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }


  

  getDataAllSelectionInfo(): Observable<ResponseModelArray<SelectionInformationResponse>> {
    return this.http.get<ResponseModelArray<SelectionInformationResponse>>(`${this.select_Get_all_URL}`);
  }
  getDataId(id: number): Observable<ResponseModelArray<SelectionInformationResponse>> {
    const url = `${this.baseUrl}getSelectionsBy-JobInformationId?jobInformationId=${id}`;
    console.log('Request URL:', url); // Log the URL
    return this.http.get<any>(url);
  }

  PostChoice(createSelection:CreateSelectionChoiceParameterRequest): Observable<ResponseModel<SelectionChoiceParameterResponse>> {
    const apiUrl = `${this.URL_POST_SELECT}create-ChoiceParameter`;
    return this.http.post<ResponseModel<SelectionChoiceParameterResponse>>(apiUrl, createSelection).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  getChoice(): Observable<ResponseModelArray<SelectionChoiceParameterResponse>> {
    return this.http.get<ResponseModelArray<SelectionChoiceParameterResponse>>(`${this.select_Get_all_URL}`);
  }
  getSelectionData = new BehaviorSubject<ResponseModel<TablePageResponse<SelectionInformationResponse>>>(null);
  $getSelectionInformation = this.getSelectionData.asObservable();

  cradViewselectedFilterId = new BehaviorSubject<number>(0);
  $cradViewselectedFilterId = this.cradViewselectedFilterId.asObservable();

  cradViewisUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $cradviewisUserAppliedFilter = this.cradViewisUserAppliedFilter.asObservable();


  getAllselectUsersByFilter(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getSelectionData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  getAppliedFilterData(appliedFilter: CreateLookupFilterRequest[]) {
    const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
    return this.http.post<any>(apiUrl, appliedFilter).subscribe(
      (data) => {
        this.getSelectionData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  CradViewedsetSelectedFilterId(filterId: number) {
    this.cradViewselectedFilterId.next(filterId);
  }

  CradVieweduserAppliedFilter(filter: boolean) {
    this.cradViewisUserAppliedFilter.next(filter);
  }
}
