import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { ChangeDetectorRef, Component, OnDestroy, EventEmitter, OnInit, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { DialogBoxSelectJob, JobInfoComponentInformation } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { SelectionService } from 'src/Service/Selection/selection.service';
import { StandardColumnSetupResponse, StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';
import { FilterSearchParameter, LookupFilterResponse } from 'src/app/shared/component/Models/LookupModels';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { AddNewGridStandardViewComponent } from 'src/app/shared/component/Modal/add-new-grid-standard-view/add-new-grid-standard-view.component';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ColumnsResizePinProperties } from 'src/Models/InternalUser/internalUserColumnsPinResize';
import { Subscription } from 'rxjs';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { CostCodeService } from 'src/Service/Internaluser/cost-code.service';
import { AddchoiceSelectionChoiceComponent } from 'src/app/project-management/components/Selection-Common/addchoice-selection-choice/addchoice-selection-choice.component';
import { ImportSelectionComponent } from 'src/app/project-management/components/Selection-Common/import-selection/import-selection.component';
import { NewSelectionComponent } from 'src/app/project-management/components/hierarchy/Add Chooice List/new-selection/new-selection.component';
import { NewRFIsComponent } from '../new-rfis/new-rfis.component';
import { ProjectManagementSelectDialogBoxComponent } from 'src/app/project-management/components/project-management-select-dialog-box/project-management-select-dialog-box.component';
import { RFIInformationResponse } from 'src/Models/_RFIs/RFIs_Infor';
import { RfisService } from 'src/Service/rfis.service';
import { BehaviorsubjectService } from 'src/Service/behaviorsubject/behaviorsubject.service';

@Component({
  selector: 'app-rfis-dashboard',
  templateUrl: './rfis-dashboard.component.html',
  styleUrls: ['./rfis-dashboard.component.css']
})
export class RfisDashboardComponent  implements OnInit  , OnDestroy {
  private subscriptions: Subscription[] = [];
  private displayColumnSubscription: Subscription;
  private RFFisstandardViewFilterSubscription : Subscription
  private RFIssubscription: Subscription;
  private RFIsFilterSubscription: Subscription;
  @Output() onSaveComplete  = new EventEmitter();
  jobInfoOptions: DialogBoxSelectJob[] = [];
  getrfiUserData: RFIInformationResponse [] = [];
  PAGE_ID: number = -1;
  demoValue = 0;
  isCollapsed = false;
  jobId: number;
  formFieldValues: any = {};
  selectedFilter: number = -1;
  lookupFilterResponse: LookupFilterResponse[] = [];
  selectionFrom: string = 'DB';
  companyNameInvalid: boolean = false;
  showErrorCards: boolean = false;
  isFilterApplied: boolean = false;
  public mySelection: string[] = [];
  selectedJobId: number = 0;
  statusClass: string = '';

  constructor(private modal: NzModalService, private filterService: FilterSideMenuBarService, public AccessLocalStorageService: AccessLocalStorageService,
    private jobInfoService: JobInformationService,
    private selectionService: SelectionService,
    public localStorage: AccessLocalStorageService,
    private internalUserService: InternalUserService,
    private standardViewService: StandardViewForAllService,
    public toastService: NzMessageService,
    private changeDetectorRef: ChangeDetectorRef,
    private LookupFilterService: LookupFilterService,
    private accessLocalStorageService: AccessLocalStorageService,
    private CostCodeService: CostCodeService,
    private rfisService: RfisService,
    private _BehaviorsubjectService: BehaviorsubjectService,
  ) {
    if (this.RFIsFilterSubscription) {
      this.RFIsFilterSubscription.unsubscribe();
    }
    if (this.RFIssubscription) {
        this.RFIssubscription.unsubscribe();
    }
    if (this.RFFisstandardViewFilterSubscription) {
      this.RFFisstandardViewFilterSubscription.unsubscribe();
    }
    console.clear();
  }

  ngOnInit(): void {
    this.internalUserService.setSelectedFilterId(0);
    if (this.RFIsFilterSubscription) {
      this.RFIsFilterSubscription.unsubscribe();
    }
    if (this.RFIssubscription) {
        this.RFIssubscription.unsubscribe();
    }
    if (this.RFFisstandardViewFilterSubscription) {
      this.RFFisstandardViewFilterSubscription.unsubscribe();
    }

    this.PAGE_ID = 26
    this.selectedJobId = this.accessLocalStorageService.getJobId();

    this.displayedColumns = [];
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    // Add subscriptions to the array
    this.subscriptions.push(
      this.standardViewService.$displaySelectedColApply.subscribe(colName => {
        if (colName.length > 0) {
          this.displayedColumns = colName;
          this.changeDetectorRef.detectChanges();
        }
      })
    );

    this.subscriptions.push(
      this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
        this.selectedFilter = standardType != -1 ? standardType : standardType;
      })
    );

    // this.displayedColumns = [];
    // this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
    //   if (colName.length > 0) {
    //     this.displayedColumns = colName;
    //     this.changeDetectorRef.detectChanges();
    //   }
    // });

    // this.displayColumnSubscription = this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
    //   if (standardType != -1) {
    //     this.selectedFilter = standardType;
    //   } else {
    //     this.selectedFilter = standardType;
    //   }

    // });

    this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom); // New Changes By Aamir Ali - 22-Apr2024
    this.getStandardViewFilterByFormPageId(this.PAGE_ID);

    this.CostCodeGetData();
    this.showErrorCards = false;
    this.companyNameInvalid = false;

    this.rfisService.$isUserAppliedFilter.subscribe(filter => {
      if (filter) {
        this.isFilterApplied = filter;
      } else {
        this.isFilterApplied = filter;
      }
    });
  }

  getInstallerNameOrSelect(parameter: any, index: number): string {
    return parameter?.subVendor?.companyName ? parameter.subVendor.companyName : `Select(${index})`;
  }
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  selectionModelOpen(select: string): void {
    const jobId = this.localStorage.getJobId();
    this.jobInfoService.getDataId(jobId).subscribe(res => {
      const modalRef = this.modal.create({
        nzStyle: {
          top: '22px',
        },
        nzData: {
          JOB_ID: jobId,
          JOB_NAME: this.localStorage.getJobName(),
          JOB_SELECTED_OBJECT: res.result
        },
        nzContent: NewRFIsComponent,
        nzFooter: null
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
    });
  }
  getLeadData() {
    this.jobInfoService.getAllLeadData().subscribe(Data => {
      this.jobInfoOptions = Data.result;
    });
  }
  importSelectionModelOpen(): void {
    const modalRef = this.modal.create({
      nzContent: ImportSelectionComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  }
  selectionChoiceModelOpen() {
    const modalRef = this.modal.create({
      nzContent: AddchoiceSelectionChoiceComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  }
  //  newformselection=false
  openchooice() {
    const modalRef = this.modal.create({
      nzContent: NewSelectionComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  }
  Messaging_RFIs: string = FiltersName.Messaging_RFIs;
  openFilter(component: string, pageId: number) {
    this.selectionFrom = ''; // New Changes By Aamir Ali - 22-Apr2024
    this.isColumnSticky= new Array(this.displayedColumns.length).fill(false);
    this.filterService.toggleFilter(component, pageId);
    this.rfisService.userAppliedFilter(false);
  }
  // _________________________Abdul_Rehman Start___________________

  // _________________________Abdul_Rehman End_____________________
  displayedColumns: string[] = [];
  columnSizes: { columnName: string, widthSize: number }[] = [];
  isColumnSticky: boolean[] = new Array(this.displayedColumns.length).fill(false);
  getColumnWidthSize: number[] = [];
  // selectedFilter: number = -1;
  selected_View_Columns: string[] = [];
  getStandardViewFilterData: StandardViewResponse[] = [];
  filterSearchParameter: FilterSearchParameter;
  totalRecords = 100;
  currentPage = 1;
  pageSize = 10;
  selectedValueControl: any;
  isLoading: boolean = false;
  defaultFilterIdSelected: number = 0;
  selectedColNameSizePin: ColumnsResizePinProperties[] = [];
  getStandardViewColumnFilterData: StandardColumnSetupResponse[] = [];
  fixedSelectedColumnNames: string[] = [];
  CoseCode: { id: number, title: string, detail: string }[] = [];

  toggleColumnSticky(index: number) {
    this.isColumnSticky[index] = !this.isColumnSticky[index];
    if (this.isColumnSticky[index]) {
      for (let i = 0; i < index; i++) {
        this.isColumnSticky[i] = true;
      }
    }
  }
  saveColumnSizes(event: any) {
    event.forEach(col => {
      const columnName = col.column.field;
      const widthSize = col.newWidth;
      const existingIndex = this.columnSizes.findIndex(item => item.columnName === columnName);

      if (existingIndex !== -1) {
        this.columnSizes[existingIndex].widthSize = widthSize;
      } else {
        this.columnSizes.push({ columnName, widthSize });
      }
    });

  }
  onFilterChange(id: number) {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);

    this.selectedFilter = -1;
    this.displayedColumns = [];
    this.selected_View_Columns = [];
    let selectedView = this.getStandardViewFilterData.find(view => view.id === id).standardViewParameters;
    selectedView.forEach(viewItem => {
      if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
        this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
        this.displayedColumns = this.selected_View_Columns;

        const columnIndex = this.displayedColumns.indexOf(viewItem.standardColumnSetup.displayColumnName);
        if (columnIndex !== -1) {
          this.isColumnSticky[columnIndex] = viewItem.isPin;
          this.getColumnWidthSize[columnIndex] = viewItem.resizeColumnWidth;
        }
      }
    });
  }
  RFIsUserGridTableData(companyId: number, formNameId: number , lookUpFilterId: number, page: number, pageSize: number ){
    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId:lookUpFilterId,
      page: page,
      pageSize: pageSize,
      jobInformationId: this.selectedJobId
    };

    this.rfisService.getrefi(this.filterSearchParameter);
    this.RFIssubscription = this.rfisService.$getRFISUsers.subscribe(result => {
      if (result) {
        this.totalRecords = result?.result?.rowCount;
        this.getrfiUserData = result?.result?.rows;
      }
    });
  }
  showCollapsePanel: boolean = false;
  saveChoice(selectionId: number) {
     this.formFieldValues.selectionInformationId = selectionId;

    this.selectionService.PostChoice(this.formFieldValues).subscribe(res => {
      const id = this.accessLocalStorageService.getSelectedFilterId();

      this.RFIsUserGridTableData(1, this.PAGE_ID, id, this.currentPage, this.pageSize);
      this.formFieldValues = {};
      this.showCollapsePanel = true;
    });
  }
  openStandardViewFilterComponent(isSaveAs?: string) {
    let isInputVisible: boolean = false;
    if (isSaveAs === 'saveAs') {
      isInputVisible = true;
    } else {
      isInputVisible = false;
    }
    this.selectedColNameSizePin = [];
    for (let i = 0; i < this.displayedColumns.length; i++) {
      const item = this.displayedColumns[i];
      const isPinValue = this.isColumnSticky[i];
      let widthSize = 0;
      if (this.columnSizes.length > 0) {
        for (let i = 0; i < this.columnSizes.length; i++) {
          const columnName = this.columnSizes[i].columnName;
          const columnNameSize = this.columnSizes[i].widthSize;
          if (columnName === item) {
            widthSize = columnNameSize;
            break;
          }
        }
      }

      if (widthSize === 0) {
        widthSize = 180;
      }

      this.selectedColNameSizePin.push({
        displayColumnName: item,
        resizeColumnWidth: widthSize, //  "200px", // Set default width here
        isPin: isPinValue == true ? isPinValue : false
      });
    }
    const modalRef = this.modal.create({
      nzContent: AddNewGridStandardViewComponent,
      nzData:
      {
        pageId: this.PAGE_ID,
        column: this.getStandardViewColumnFilterData,
        displayCol: this.displayedColumns,
        viewList: this.getStandardViewFilterData,
        selectedColNameSizePin: this.selectedColNameSizePin,
        selectedStandardView: this.selectedValueControl,
        fixedCol: this.fixedSelectedColumnNames,
        isSaveAs: isInputVisible
      },

    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
  }
  saveEditStandardView() {
    this.standardViewService.$createStandardViewRequest.subscribe(formData => {
      if (formData && formData !== null) {
        this.standardViewService.postData(formData)
          .subscribe(
            (res: ResponseModelArray<StandardViewResponse>) => {
              setTimeout(() => {
                this.toastService.success('Standard View Updated Successfully');
                this.standardViewService.getStandardViewFilterByFormPageId(this.PAGE_ID);
                this.selectedFilter = -1;
              }, 10);
            },
            (error) => {
              console.error('Error:', error);
            }
          );
      }
    });
  }
  onPageSizeChange(size: number): void {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
    this.pageSize = size;
    let filterId: number = 0;
    this.internalUserService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.RFIsUserGridTableData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize);
    }, 100);
  }
  onPageChange(page: number): void {
    this.currentPage = page;
    let filterId: number = 0;
    this.internalUserService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.RFIsUserGridTableData(1, this.PAGE_ID, filterId, page, this.pageSize);
    }, 100);
  }
  getStandardFilterByFormPageId(pageId: number, type: string) {// New Changes By Aamir Ali - 22-Apr2024

    if (this.RFIsFilterSubscription) {
      this.RFIsFilterSubscription.unsubscribe();
  }
  if (this.RFIssubscription) {
      this.RFIssubscription.unsubscribe();
  }
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.RFIsFilterSubscription =  this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.lookupFilterResponse = result.result;
        let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true)?.id;
        this.rfisService.setSelectedFilterId(defaultFilterId);
        let id = -1;
        if (type === this.selectionFrom) {
          this.defaultFilterIdSelected = defaultFilterId;
          this.accessLocalStorageService.setSelectedFilterId(defaultFilterId);

          // New Changes By Aamir Ali - 22-Apr2024
          id = this.accessLocalStorageService?.getSelectedFilterId();
        } else {
          id = this.accessLocalStorageService.getSelectedFilterId();
        }

        if (id !== -1) {
          this.RFIsUserGridTableData(1, this.PAGE_ID, id, this.currentPage, this.pageSize);
        }

      }
    });
  }
  getStandardViewFilterByFormPageId(pageId: number) {
    if (this.RFFisstandardViewFilterSubscription) {
      this.RFFisstandardViewFilterSubscription.unsubscribe();
    }
    this.displayedColumns = [];
    this.fixedSelectedColumnNames = [];
    this.standardViewService.getStandardViewFilterByFormPageId(pageId);
    this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
    // this.standardViewService.$getStandardViewFilter.subscribe(result => {
    this.RFFisstandardViewFilterSubscription = this.standardViewService.$getStandardViewFilter.subscribe(result => {
      if (result) {
        this.displayedColumns = [];
        this.selected_View_Columns = [];
        this.getStandardViewFilterData = result.result;
        let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).standardViewParameters;
        this.selectedValueControl = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).id;
        defaultView.forEach(viewItem => {
          if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
            this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
          }
        });
        this.displayedColumns = this.selected_View_Columns;
      }
    });

    this.standardViewService.$getStandardViewColumn.subscribe(result => {
      if (result) {
        this.getStandardViewColumnFilterData = result.result;
        let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);
        fixedCol.forEach(x => {
          this.fixedSelectedColumnNames.push(x.displayColumnName);
        });

      }
    });
  }
  CostCodeGetData() {
    this.CostCodeService.getData().subscribe(
      (res: any) => {
        this.CoseCode = res.result.map((costCode: any) => ({
          id: costCode.id,
          title: costCode.title,
          detail: costCode.detail
        }));
      },
      (error) => {
        console.error('Error fetching cost codes:', error);
      }
    );
  }
  VendorsAllUsers = false;
  InstallersAllUsers = false;

  toggleVendors(): void {
    this.VendorsAllUsers = !this.VendorsAllUsers;
  }
  toggleInstallers(): void {
    this.InstallersAllUsers = !this.InstallersAllUsers;
  }
  createRFI(select: string): void {
    this.isLoading = true
    const jobId = this.localStorage.getJobId();
    if (jobId) {
      const modalRef = this.modal.create({
        nzContent: NewRFIsComponent,
        nzFooter: null
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.componentInstance.initializationComplete.subscribe((res) => {
        this.isLoading = res
      });
    } else {
      const modalRef = this.modal.create({
        nzWidth: '32%',
        nzStyle: { top: '36%' },
        nzContent: ProjectManagementSelectDialogBoxComponent,
        nzFooter: null
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
    }
    this.onAfterReponse()
  }
  getSelectedJobId(item: number) {
    this.selectedJobId = item;
    this.getrfiUserData = null;
    this.getrfiUserData = null;
    this.getStandardFilterByFormPageId(this.PAGE_ID,this.selectionFrom);
  }
  ngOnDestroy() {
    this.LookupFilterService.getStandardFilter.next(null); // New Changes By Aamir Ali - 31-May-2024
    localStorage.removeItem('Selected_Filter_Id');
    if (this.RFIsFilterSubscription) {
      this.RFIsFilterSubscription.unsubscribe();
    }
    if (this.RFIssubscription) {
        this.RFIssubscription.unsubscribe();
    }
    if (this.RFFisstandardViewFilterSubscription) {
      this.RFFisstandardViewFilterSubscription.unsubscribe();
    }
    console.clear()
  }
  getColumnWidth(column: string): number {
    switch (column) {
      case 'RFI Is New':
        return 100;
      case 'Job':
        return 200;
      case 'RFI #':
        return 200;
      case 'Title':
        return 250;
      case 'Created By':
        return 180;
      case 'Quetion Files':
        return 150;
      case 'Assigned To':
        return 180;
      case 'Status':
        return 200;
      case 'Responses':
        return 150;
      case 'Responses File':
        return 150;
      case 'Due':
        return 150;
      case 'Related Items':
        return 200;
      case 'Created Date':
        return 200;
        default:
          return 100;
    }
  }
  columnLockState: { [key: string]: boolean } = {};
  isColumnLocked(column: string): boolean {
    return this.columnLockState[column];
  }
  toggleColumnLock(column: string) {
    this.columnLockState[column] = !this.columnLockState[column];
  }
  EditRFIs(Id: number): void {
    this.isLoading = true
    const modalRef = this.modal.create({
      nzContent: NewRFIsComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.RFIsResponseId = Id;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.initializationComplete.subscribe((res) => {
      this.isLoading = res
    });
    this.onAfterReponse();
  }
  onAfterReponse(){
    this.RFIssubscription = this._BehaviorsubjectService.leadActivityTypeResponse$.subscribe(
      (response) => {
        if (response) {
          this.RFIsUserGridTableData(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPage, this.pageSize);
        }
    });
  }
  setStatusInfo(Status_Id: number) {
    
    switch (Status_Id) {
      case 735:
        this.statusClass = 'success';
        break;
      case 734:
        this.statusClass = 'pending';
        break;
      case 736:
        this.statusClass = 'pending';
        break;
      default:
        this.statusClass = '';
        break;
    }
  }
}
