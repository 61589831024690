<nz-modal
nzWidth="50%"
[(nzVisible)]="costCode"
[nzTitle]="newnostcategory1"
[nzFooter]="newnostcategory2"
(nzOnCancel)="CostCodeCancel()"
[nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '500px', 'overflow-y': 'auto'}"
[nzStyle]="{ top: '18px' }">
<ng-template #newnostcategory1> <div class="row p-0 m-0">
    <h1 class="p-0 m-0">Cost Category</h1></div>
</ng-template>
<div  class="cantent" *nzModalContent>
  <div class="card border-0 famly-inn">
    <div class="card-head border-bottom">
      <div class="row mb-0 p-2 d-flex align-items-center">
        <div class="col">
          <span class="fw-medium fs-6 ms-2">Adding a Cost Catageroy</span>
        </div>
      </div>
    </div>
    <div class="card-body">
          <div class="row gx-2">
              <div class="col-6">
                <label for="text" class="sub-text">Title <span class="text-danger" >*</span></label>
                <input [(ngModel)]="formFieldValues['title']" name="title"  type="text"  class="form-control formField">
              </div>
              <div class="col-6">
                <label for="text" class="sub-text">Category</label>
                <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['costCodeId']" name="costCodeId">
                  <nz-option  [nzLabel]="'Non'" [nzValue]="0"></nz-option>
                  <nz-option *ngFor="let option of costCategory" [nzLabel]="option.title" [nzValue]="option.id"></nz-option>
                </nz-select>
              </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <label for="text" class="sub-text">Details</label>
                <textarea [(ngModel)]="formFieldValues['detail']" name="detail" class="form-control formField"  style="height: 50px; resize: none;"
                (input)="autoAdjustTextAreaHeight($event)"
            ></textarea>
            </div>
        </div>                        
    </div> 
  </div>
</div>
<ng-template #newnostcategory2>
    <button nz-button nzType="primary" class="rounded-1 me-0">Save & New</button>
    <button nz-button nzType="primary" class="rounded-1 me-0" (click)="saveButton()">Save</button>
</ng-template>
</nz-modal>