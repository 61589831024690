import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent {
  isActive: boolean = false;

  constructor(
    private router: Router
  ){}
  toggleContent() {
  }

  navigateToSalesDetails(): void {
    this.router.navigate(['/reports/salesDetailReports']);
  }
  
  navigateToProjectManagmentDetails(): void {
    this.router.navigate(['/reports/ProjectManagmentReports']);
  }
  
  navigateToFinancialDetails(): void {
    this.router.navigate(['/reports/FinancialReports']);
  }
  
  
}
