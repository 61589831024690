import { ScheduleComponent } from './schedule/schedule.component';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModalService } from 'src/Service/CompanyInFormation/modal.service';
import { ModalBillsPosBudgetService } from 'src/Service/CompanyInFormation/Selections/modal-bills-pos-budget.service';
import { SelectionsSScheduleSetupModalService } from 'src/Service/CompanyInFormation/Selections/selections-sschedule-setup-modal.service';
import { SelectionsService } from 'src/Service/CompanyInFormation/Selections/selections.service';
import { ToDosComponent } from './to-dos/to-dos.component';
import { JobsComponent } from '../shared/component/jobs/jobs.component';
import { SalesComponent } from '../shared/component/sales/sales.component';
import { CostCodeComponent } from './cost-code/cost-code.component';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { LeadOpportunitiesWorkSheetAddformCatalogComponent } from '../lead-opportunities/Components/Catalog-Modal/lead-opportunities-work-sheet-addform-catalog/lead-opportunities-work-sheet-addform-catalog.component';
import { RFIsComponent } from './rfis/rfis.component';
import { WarrantyComponent } from './warranty/warranty.component';
import { DailyLogsComponent } from './daily-logs/daily-logs.component';
import { TimeClockComponent } from './time-clock/time-clock.component';
import { CommentMessagesComponent } from './comment-messages/comment-messages.component';
import { BidsComponent } from './bids/bids.component';
import { EstimatesComponent } from './estimates/estimates.component';
import { OwnerInvoicesComponent } from './owner-invoices/owner-invoices.component';
import { CompanySettingsSubsVendorsComponent } from './company-settings-subs-vendors/company-settings-subs-vendors.component';
import { OwnerInvoicesSetupResponse } from 'src/Models/CompanySettings/Schedule/PhaseSetup/OwnerView';
import { JobTypeGroupService } from 'src/Service/Job-List/Job-Information/job-type-group.service';
import { SelectiondetupComponent } from './selectiondetup/selectiondetup.component';
import { ChangeOrdersComponent } from './change-orders/change-orders.component';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.css'],
})
export class CompanySettingsComponent implements OnInit {
  @Input() dataInvo: OwnerInvoicesSetupResponse;

  checked: boolean = false;
  dataNew: any;
  gridStyle = {
    width: '100%',
    height: '100%',
  };
  isUpdateForm: boolean;
  constructor(
    public msg: NzMessageService,
    private modalService: ModalService,
    private modal: NzModalService,
    private accessLocalStorageService: AccessLocalStorageService
  ) {
    this.accessLocalStorageService.setUserLoginId(1);
  }

  ngOnInit(): void {}
  companylogo: boolean = false;
  companyinformation: boolean = false;
  subscriptions: boolean = false;
  jobs: boolean = false;
  sales: boolean = false;
  dailylogs: boolean = false;
  schedule: boolean = false;
  todos: boolean = false;
  warranty: boolean = false;
  files: boolean = false;
  commentsmessages: boolean = false;
  surveys: boolean = false;
  rFIs: boolean = false;
  costcodes: boolean = false;
  catalog: boolean = false;
  bids: boolean = false;
  estimates: boolean = false;
  billspOsbudget: boolean = false;
  ownerinvoices: boolean = false;
  subsvendors: boolean = false;
  customercontacts: boolean = false;
  rolemanagement: boolean = false;
  pipedrive: boolean = false;
  hubSpot: boolean = false;
  gusto: boolean = false;
  accountingsetup: boolean = false;

  jobsComponentsOpen() {
    const modalRef = this.modal.create({
      nzContent: JobsComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  Sales(): void {
    const modalRef = this.modal.create({
      nzContent: SalesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  ToDods(): void {
    const modalRef = this.modal.create({
      nzContent: ToDosComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  dailyLogMethod(): void {
    const modalRef = this.modal.create({
      nzContent: DailyLogsComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  costCode(): void {
    const modalRef = this.modal.create({
      nzContent: CostCodeComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  RFIs() {
    const modalRef = this.modal.create({
      nzContent: RFIsComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  timeClock(): void {
    const modalRef = this.modal.create({
      nzWidth: '95%',
      nzBodyStyle: {
        background: '#f1f4fa',
        padding: '0',
        margin: '0',
        'max-height': '80vh',
      },
      nzStyle: {
        top: '22px',
      },
      nzContent: TimeClockComponent,
      nzFooter: null,
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  Estimates() {
    this.estimates = true;

    const modalRef = this.modal.create({
      nzContent: EstimatesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  SubsVendors() {
    const modalRef = this.modal.create({
      nzContent: CompanySettingsSubsVendorsComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  Schedule() {
    const modalRef = this.modal.create({
      nzContent: ScheduleComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  selectionsSetup() {
    const modalRef = this.modal.create({
      nzWidth: '90%',
      nzBodyStyle: {
        background: '#f1f4fa',
        padding: '0',
        margin: '0',
        'max-height': '80vh',
      },
      nzStyle: {
        top: '22px',
      },

      nzContent: SelectiondetupComponent,
      nzFooter: null,
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  openWarrantySetup() {
    const modalRef = this.modal.create({
      nzContent: WarrantyComponent,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  openCommentMessageSetup() {
    const modalRef = this.modal.create({
      nzContent: CommentMessagesComponent,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  openBidsSetup() {
    const modalRef = this.modal.create({
      nzContent: BidsComponent,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  Catalog(flag: String) {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesWorkSheetAddformCatalogComponent,
      nzFooter: null,
      nzData: {
        CompanySetting: flag,
      },
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    console.log(flag);
  }

  ToDos() {
    this.todos = true;
  }
  closeToDos() {
    this.todos = false;
  }
  ChangeOrders() {
    const modalRef = this.modal.create({
      nzContent: ChangeOrdersComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  Warranty() {
    this.warranty = true;
  }
  closeWarranty() {
    this.warranty = false;
  }
  Files() {
    this.files = true;
  }
  closeFiles() {
    this.files = false;
  }
  CommentsMessages() {
    this.commentsmessages = true;
  }
  closeCommentsMessages() {
    this.commentsmessages = false;
  }
  // CloseRFIs() {
  //   this.rFIs = false;
  // }
  OwnerInvoices(dataInvo:OwnerInvoicesSetupResponse){
    const modalRef = this.modal.create({
     
      nzContent: OwnerInvoicesComponent,
    
      nzFooter: null,
    });
    modalRef.componentInstance.newVariable = dataInvo
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }



  closeCatalog() {
    this.catalog = false;
  }
  Bids() {
    this.bids = true;
  }
  closeEstimates() {
    this.estimates = false;
  }
  BillsPOsBudget() {
    this.billspOsbudget = true;
  }
  closeBillsPOsBudget() {
    this.billspOsbudget = false;
  }

  closeOwnerInvoices() {
    this.ownerinvoices = false;
  }
  closeBids() {
    this.bids = false;
  }
  Surveys() {
    this.surveys = true;
  }
  closeSubsVendors() {
    this.subsvendors = false;
  }
  CustomerContacts() {
    this.customercontacts = true;
  }
  closeCustomerContacts() {
    this.customercontacts = false;
  }
  RoleManagement() {
    this.rolemanagement = true;
  }
  closeRoleManagement() {
    this.rolemanagement = false;
  }
  Pipedrive() {
    this.pipedrive = true;
  }
  closePipedrive() {
    this.pipedrive = false;
  }
  HubSpot() {
    this.hubSpot = true;
  }
  closeHubSpot() {
    this.hubSpot = false;
  }
  Gusto() {
    this.gusto = true;
  }
  closeGusto() {
    this.gusto = false;
  }
  AccountingSetup() {
    this.accountingsetup = true;
  }
  closeAccountingSetup() {
    this.accountingsetup = false;
  }
  CompanyInformation() {
    this.modalService.triggerShowModal();
  }

  closeSurveys() {
    this.surveys = false;
  }

  CloseRFIs() {
    this.rFIs = false;
  }
  CompanyLogo() {
    this.companylogo = true;
  }
  closeCompanyLogo() {
    this.companylogo = false;
  }

  Subscriptions() {
    this.subscriptions = true;
  }
  closeSubscriptions() {
    this.subscriptions = false;
  }
}
