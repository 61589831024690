<div *ngIf="filterResponseData && filterResponseData.length > 0">
    <form  (ngSubmit)="onFilterApply()">
      <div class="row mt-2">
        <div class="col-10 p-0">
          <div class="input-group">
           <nz-select
            [(ngModel)]="formFieldValues['standardFilter']"
            name="standardFilter"
            (ngModelChange)="onChangeStandardFilter($event)"
            nzShowSearch
            [nzAutoFocus]="true"
            nzPlaceHolder="Standard Filter"
            r>
            <nz-option *ngFor="let filter of filterResponseData"
             [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')"
             [nzValue]="filter.id"
             nzCustomContent>
             <div class="option-content">
              <!-- Customize content with icons and text -->
              <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
              <span>{{ filter.name }}</span>
             <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

             </div>
            </nz-option>
           </nz-select>
          </div>
         </div>
        <div class="col-1 mt-1">
          <i class="bi bi-three-dots"
          nz-popover
          [nzPopoverContent]="contentTemplate"
          [nzPopoverPlacement]="'top'"
           (click)="openStandardFilterComponent()"></i>
        </div>
      </div>
      <div class="col p-0 text-end">
        <strong nz-icon nzType="close" nzTheme="outline"></strong>
      </div>
        <div class="row famly-inn">
          <div class="col-12">
            <div class="row">
              <div class="col mt-1 p-0">
                <label class="sub-text">Categories</label>
                <i class="bi bi-info-circle-fill text-secondary ms-2" nz-popover [nzPopoverContent]="contentTemplate4"
              nzPopoverPlacement="right"></i>
            <ng-template #contentTemplate4>
              <div class="rounded-2">
                <p style="height: auto; width: 180px;">
                  Only Filters the Top Level Selections
                </p>
              </div>
            </ng-template>
                <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['categories']" name="categories">
                  <nz-option *ngFor="let option of categoryOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                </nz-select>
              </div>
            </div>
            <div class="row">
              <div class="col mt-1 p-0">
                <label class="sub-text">Locations</label>
                <i class="bi bi-info-circle-fill text-secondary ms-2" nz-popover [nzPopoverContent]="contentTemplate4"
              nzPopoverPlacement="right"></i>
            <ng-template #contentTemplate4>
              <div class="rounded-2">
                <p style="height: auto; width: 180px;">
                  Only Filters the Top Level Selections
                </p>
              </div>
            </ng-template>
                <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['locations']" name="locations">
                  <nz-option *ngFor="let option of locationOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                </nz-select>
              </div>
            </div>
            <div class="row">
              <label class="sub-text p-0">
                Show All Selections <i class="bi bi-info-circle-fill text-secondary ms-2" nz-popover [nzPopoverContent]="contentTemplate5"
                nzPopoverPlacement="right"></i>
              <ng-template #contentTemplate5>
                <div class="rounded-2">
                  <p style="height: auto; width: 180px;">
                    Unchecking filter will change the view to only display Dependent Selections
                  </p>
                </div>
              </ng-template>
              </label>
              <div class="col-12 p-0 mt-1">
                <label [(ngModel)]="formFieldValues['showSelection']" name="showSelection" nz-checkbox></label>
              </div>
            </div>
            <div class="row">
              <label class="sub-text p-0">
                Show Unavailable Choices
              </label>
              <div class="col-12 p-0 mt-1">
                <label [(ngModel)]="formFieldValues['showChooice']" name="showChooice" nz-checkbox></label>
              </div>
            </div>
          </div>
        </div>
        <!-- </ng-container>
     <ng-template #footerTpl> -->
        <div class="row">
          <div class="col-12 p-0 text-end ">
            <button nz-button nzType="primary">Clear All</button>
            <button class="ms-1" nz-button nzType="default">Apply </button>
          </div>
        </div>

    </form>
  </div>




<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>
