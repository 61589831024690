import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JobSummaryDbComponent } from './Jobs-Summary/job-summary-db/job-summary-db.component';
import { JobCreateFromScratchComponent } from './Job-List/job-create-from-scratch/job-create-from-scratch.component';
import { JobsDashboardComponent } from './Job-List/jobs-dashboard/jobs-dashboard.component';
import { JobPriceSummaryComponent } from './Jobs-Job-Price-Summary/job-price-summary/job-price-summary.component';
import { ProjectManagementTimeClockDbComponent } from '../project-management/components/TimeClock/project-management-time-clock-db/project-management-time-clock-db.component';


const routes: Routes = [
  { path: '', redirectTo: 'Summary', pathMatch: 'full' },
  { path: 'Summary', component:JobSummaryDbComponent},
  { path: 'Job-Info', component:JobCreateFromScratchComponent},
  { path: 'JobPriceSummary', component:JobPriceSummaryComponent},
  { path: 'Job-List', component:JobsDashboardComponent},
  { path: 'Job-Map', component:JobsDashboardComponent},
  { path: 'New-Job-From-Scratch', component:JobCreateFromScratchComponent},
  // { path: 'TimeClock/Shifts', component:ProjectManagementTimeClockDbComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JobsManagementRoutingModule { }
