<div class="card border-0 mt-3 mb-5">
    <div class="card-body">
      <div class="row">
        <div class="col-md-4" style="border-right: 1px solid #ccc">
          <div class="row g-0">
             <div class="col-12"><span class="sub-text" style="letter-spacing: 2px;">PAST DUE FOR YOU</span></div>
          </div>
          <div class="row g-0 mt-3">
             <div class="col-11"><a nz-button nzType="link">Sub Certificates</a></div>
             <div class="col-1"> <nz-badge
                nzStandalone
                [nzCount]="1"
                [nzStyle]="{ backgroundColor: '#fdddd5', color: 'red',}"
              >
              </nz-badge></div>
          </div>
        </div>
        <div class="col-md-4" style="border-right: 1px solid #ccc">
          <div class="row g-0">
             <div class="col-12"><span class="sub-text" style="letter-spacing: 2px;">DUE TODAY</span></div>
          </div>
          <div class="row g-0 mt-3">
             <div class="col-12 text-center">
                <span role="img" size="78" data-testid="EmptyStateIcon" class="anticon EmptyStateIcon" style="font-size: 78px">
                <svg width="1em" height="1em" viewBox="0 0 33 33" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class>
                  <path d="M4.49927 27.5C3.44491 27.5 2.5811 26.6841 2.50475 25.6493L2.49927 25.5V14.5C2.49927 13.4456 3.31515 12.5818 4.35001 12.5055L4.49927 12.5H9.88027L14.6048 3.05279C14.7742 2.714 15.1205 2.5 15.4993 2.5C16.8253 2.5 18.0971 3.02678 19.0348 3.96447C19.91 4.83964 20.4272 6.00583 20.4923 7.23559L20.4993 7.5L20.499 9.5H27.2337C28.028 9.5 28.787 9.81484 29.347 10.3707L29.4827 10.5146C30.0084 11.1101 30.2747 11.8874 30.2286 12.6751L30.2105 12.8721L28.7105 24.8721C28.5304 26.3133 27.347 27.4093 25.9141 27.4946L25.7337 27.5H4.49927ZM16.087 4.558L11.4993 13.735V25.5H25.7337C26.1992 25.5 26.5975 25.18 26.7052 24.7372L26.726 24.624L28.226 12.624C28.2616 12.3394 28.1732 12.0532 27.9834 11.8382C27.8207 11.6539 27.5968 11.5371 27.3556 11.5074L27.2337 11.5H19.4993C18.9864 11.5 18.5638 11.114 18.506 10.6166L18.4993 10.5V7.5C18.4993 6.70435 18.1832 5.94129 17.6206 5.37868C17.2455 5.00361 16.7814 4.73811 16.2778 4.60278L16.087 4.558ZM9.499 14.5H4.49927V25.5H9.499V14.5Z" fill="#e8e8e9"></path>
                </svg>
              </span>
             </div>
          </div>
          <div class="row g-0 mt-3 mb-3">
            <div class="col-12 text-center"><span class="pira-label">You have nothing due today</span></div>
         </div>
        </div>
        <div class="col-md-4">
          <div class="row g-0 mt-3">
            <div class="col-11"><a nz-button nzType="link">Pass Day For You </a></div>
            <div class="col-1"> <nz-badge
               nzStandalone
               [nzCount]="1"
               [nzStyle]="{ backgroundColor: '#fdddd5', color: 'red',}"
             >
             </nz-badge></div>
         </div>
          <div class="row g-0 mt-3">
             <div class="col-12 text-center">
                <span role="img" size="78" data-testid="EmptyStateIcon" class="anticon EmptyStateIcon" style="font-size: 78px">
                <svg width="1em" height="1em" viewBox="0 0 33 33" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class>
                  <path d="M4.49927 27.5C3.44491 27.5 2.5811 26.6841 2.50475 25.6493L2.49927 25.5V14.5C2.49927 13.4456 3.31515 12.5818 4.35001 12.5055L4.49927 12.5H9.88027L14.6048 3.05279C14.7742 2.714 15.1205 2.5 15.4993 2.5C16.8253 2.5 18.0971 3.02678 19.0348 3.96447C19.91 4.83964 20.4272 6.00583 20.4923 7.23559L20.4993 7.5L20.499 9.5H27.2337C28.028 9.5 28.787 9.81484 29.347 10.3707L29.4827 10.5146C30.0084 11.1101 30.2747 11.8874 30.2286 12.6751L30.2105 12.8721L28.7105 24.8721C28.5304 26.3133 27.347 27.4093 25.9141 27.4946L25.7337 27.5H4.49927ZM16.087 4.558L11.4993 13.735V25.5H25.7337C26.1992 25.5 26.5975 25.18 26.7052 24.7372L26.726 24.624L28.226 12.624C28.2616 12.3394 28.1732 12.0532 27.9834 11.8382C27.8207 11.6539 27.5968 11.5371 27.3556 11.5074L27.2337 11.5H19.4993C18.9864 11.5 18.5638 11.114 18.506 10.6166L18.4993 10.5V7.5C18.4993 6.70435 18.1832 5.94129 17.6206 5.37868C17.2455 5.00361 16.7814 4.73811 16.2778 4.60278L16.087 4.558ZM9.499 14.5H4.49927V25.5H9.499V14.5Z" fill="#e8e8e9"></path>
                </svg>
              </span>
             </div>
          </div>
          <div class="row g-0 mt-3 mb-3">
            <div class="col-12 text-center"><span class="pira-label">Nothing requires your attention at the moment.</span></div>
         </div>
        </div>
      </div>

    </div>
  </div>