<div>
    <p>Video File Path: {{ data.videoUrl }}</p>
    <p>Video File Extension: {{ getExtension(data.videoUrl) }}</p>
    <video width="100%" height="450" controls>
      <source [src]="data.videoUrl" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div>
  



 
    <div *ngIf="data.imageUrl">
      <img [src]="data.imageUrl" alt="Image file" width="100%" />
    </div>