<nz-modal
    nzWidth="35%"
    [(nzVisible)]="Taghandle"
    [nzTitle]="Tag1"
    [nzFooter]="Tag2"
    (nzOnCancel)="TagClose()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '38vh', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
    <ng-template #warrantyModalTitle #Tag1>
      <div class="row p-0 m-0">
        <h1 class="p-0 m-0">Add Warranty Category</h1>
      </div>
    </ng-template>
  
    <div class="content content-set-background" *nzModalContent>
      <form [formGroup]="jobGroupForm">
        <div class="ng-container">
          <!-- card -->
  
          <nz-card nzTitle="Add Warranty Category">
            <div class="row">
              <div class="col">
                <label class="sub-text"
                  >Title<span class="text-danger fw-bolder">*</span></label
                >
                <input
                  id="inputField"
                  formControlName="name"
                  type="text"
                  class="form-control formField"
                 
                  
                />
                <div
                >
                  Required
                </div>
              </div>
            </div>
          </nz-card>
          <!-- card -->
        </div>
      </form>
    </div>
  <ng-template #Tag2>
    <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
  </ng-template>
</nz-modal>





