<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form [formGroup]="financialestimateFilterForm" (ngSubmit)="onSubmit()">
    <div class="row mt-2">
      <div class="col-10 p-0">
        <div class="input-group">
          <nz-select formControlName="standardFilter" (ngModelChange)="onChangeStandardFilter($event)" nzShowSearch
            [lang]="true" nzAutoFocus="true" nzPlaceHolder="Standard Filter">
            <nz-option *ngFor="let filter of filterResponseData" [nzLabel]="filter.name"
              [nzValue]="filter.id"></nz-option>
          </nz-select>
        </div>
      </div>
      <div class="col-1 mt-1">
        <i class="bi bi-three-dots" (click)="openStandardFilterComponent()"></i>
      </div>
    </div>


    <div class="col">
      <label class="sub-text">Cost Code</label>
      <nz-tree-select  formControlName="costCode"  [nzNodes]="nodes" nzShowSearch
      nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
    </div>


    <div class="row">
      <div class="col">
        <label class="sub-text">Cost Type </label>
        <nz-tree-select  formControlName="costType"  [nzNodes]="nodes" nzShowSearch
                nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>  
      </div>
    </div>
  </form>
</div>