import { LeadProposalsService } from './../../Service/LeadProp/lead-proposals.service';
import { CostItemParameterResponse } from 'src/Models/LeadProposal/LeadProposalModels';
import { LeadOpportunitiesLeadProposalsHeaderComponent } from './../lead-opportunities/Components/Lead-opportunities-header/lead-opportunities-lead-proposals-header/lead-opportunities-lead-proposals-header.component';
import { leadOpportunities } from 'src/Models/CustomerInformation';
import { CompanySettingsModule } from './company-settings.module';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { costItemParameter, CostItem, LeadProposalResponse } from './../../Models/LeadProposal/LeadProposalModels';
import { ScheduleComponent } from './schedule/schedule.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { SelectionSetup } from 'src/Models/CompanySettings/Selections/SelectionSetup';
import { ModalService } from 'src/Service/CompanyInFormation/modal.service';
import { ScheduleModalService } from 'src/Service/CompanyInFormation/ScheDule/schedule-modal.service';
import { ModalBillsPosBudgetService } from 'src/Service/CompanyInFormation/Selections/modal-bills-pos-budget.service';
import { RequireSignatureOnSelectionSetupService } from 'src/Service/CompanyInFormation/Selections/require-signature-on-selection-setup.service';
import { SelectionBuilderViewService } from 'src/Service/CompanyInFormation/Selections/selection-builder-view.service';
import { SelectionOwnerViewService } from 'src/Service/CompanyInFormation/Selections/selection-owner-view.service';
import { SelectionsSScheduleSetupModalService } from 'src/Service/CompanyInFormation/Selections/selections-sschedule-setup-modal.service';
import { SelectionsService } from 'src/Service/CompanyInFormation/Selections/selections.service';
import { ToDosComponent } from './to-dos/to-dos.component';
import { CatalogComponent } from './catalog/catalog.component';
import { JobsComponent } from '../shared/component/jobs/jobs.component';
import { SalesComponent } from '../shared/component/sales/sales.component';
import { CostCodeComponent } from './cost-code/cost-code.component';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { RfiEmptyComponent } from '../messaging/Components/RFIs/rfi-empty/rfi-empty.component';
import { LeadOpportunitiesWorkSheetAddformCatalogComponent } from '../lead-opportunities/Components/Catalog-Modal/lead-opportunities-work-sheet-addform-catalog/lead-opportunities-work-sheet-addform-catalog.component';
import { ChangeOrdersComponent } from './change-orders/change-orders.component';
import { RFIsComponent } from './rfis/rfis.component';
import { WarrantyComponent } from './warranty/warranty.component';
import { DailyLogsComponent } from './daily-logs/daily-logs.component';
import { TimeClockComponent } from './time-clock/time-clock.component';
import { CommentMessagesComponent } from './comment-messages/comment-messages.component';
import { BidsComponent } from './bids/bids.component';
import { EstimatesComponent } from './estimates/estimates.component';

import { OwnerInvoicesComponent } from './owner-invoices/owner-invoices.component';
import { CompanySettingsSubsVendorsComponent } from './company-settings-subs-vendors/company-settings-subs-vendors.component';
import { OwnerInvoicesSetupResponse } from 'src/Models/CompanySettings/Schedule/PhaseSetup/OwnerView';
import { JobTypeGroupService } from 'src/Service/Job-List/Job-Information/job-type-group.service';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.css']
})
export class CompanySettingsComponent implements OnInit {
  @Input() dataInvo : OwnerInvoicesSetupResponse

  checked: boolean = false;

  gridStyle = {
    width: '100%',
    height: '100%',
  };
  selections: boolean;
  isUpdateForm: boolean;
  constructor(
    public msg: NzMessageService,
     private modalService: ModalService, 
    private modalSelections: SelectionsSScheduleSetupModalService,
    private modalSelectionsGetById: ModalBillsPosBudgetService,
    private Selectionsservice: SelectionsService,
    private fb: FormBuilder,
    private modal: NzModalService,
    private accessLocalStorageService: AccessLocalStorageService,
    private jobSetupService: JobTypeGroupService,
  ) {
    // Login User Id in local storage Session
    this.accessLocalStorageService.setUserLoginId(1);
    // this.getSelectionSetup();
    // this.getSelectionBuilder();
    // this.getSelectionOwner();
    // this.getRequireSignatureOnSelectionSetup();
  }

  ngOnInit(): void {
    this.initForm();
    this.initFormPut();
  }
  selectionSetupForm: FormGroup;
  PutselectionSetupForm: FormGroup;



  companylogo: boolean = false;
  companyinformation: boolean = false;
  subscriptions: boolean = false;
  jobs: boolean = false;
  sales: boolean = false;
  dailylogs: boolean = false;
  schedule: boolean = false;
  todos: boolean = false;
  changeorders: boolean = false;
  warranty: boolean = false;
  files: boolean = false;
  commentsmessages: boolean = false;
  surveys: boolean = false;
  rFIs: boolean = false;
  costcodes: boolean = false;
  catalog: boolean = false;
  bids: boolean = false;
  estimates: boolean = false;
  billspOsbudget: boolean = false;
  ownerinvoices: boolean = false;
  subsvendors: boolean = false;
  customercontacts: boolean = false;
  rolemanagement: boolean = false;
  pipedrive: boolean = false;
  hubSpot: boolean = false;
  gusto: boolean = false;
  accountingsetup: boolean = false;

  /////////////////////
  CompanyLogo() {
    this.companylogo = true;
  }
  closeCompanyLogo() {
    this.companylogo = false;
  }

  Subscriptions() {
    this.subscriptions = true;
  }
  closeSubscriptions() {
    this.subscriptions = false;
  }

  jobsComponentsOpen() {
    const modalRef = this.modal.create({
      nzContent: JobsComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  Sales(): void {
    const modalRef = this.modal.create({
      nzContent: SalesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  ToDods(): void {
    const modalRef = this.modal.create({
      nzContent: ToDosComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  dailyLogMethod(): void {
    const modalRef = this.modal.create({
      nzContent: DailyLogsComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  costCode(): void {
    const modalRef = this.modal.create({
      nzContent: CostCodeComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }


  RFIs(): void {
    const modalRef = this.modal.create({
      nzWidth: '95%',
      nzBodyStyle: {
        background: '#f1f4fa',
        padding: '0',
        margin: '0',
        'max-height': '80vh'
      },
      nzStyle: {
        top: '22px',
      },

      nzContent: RFIsComponent,
      nzFooter: null,
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  timeClock(): void {
    const modalRef = this.modal.create({
      nzWidth: '95%',
      nzBodyStyle: {
        background: '#f1f4fa',
        padding: '0',
        margin: '0',
        'max-height': '80vh'
      },
      nzStyle: {
        top: '22px',
      },
      nzContent: TimeClockComponent,
      nzFooter: null,
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  // DailyLogs(){
  //   this.dailylogs = true;
  // }
  // closeDailyLogs(){
  //   this.dailylogs = false;
  // }
  ToDos() {
    this.todos = true;
  }
  closeToDos() {
    this.todos = false;
  }
  ChangeOrders() {
    this.changeorders = true;
  }
  closeChangeOrders() {
    this.changeorders = false;
  }
  Warranty() {
    this.warranty = true;
  }
  closeWarranty() {
    this.warranty = false;
  }
  Files() {
    this.files = true;
  }
  closeFiles() {
    this.files = false;
  }
  CommentsMessages() {
    this.commentsmessages = true;
  }
  closeCommentsMessages() {
    this.commentsmessages = false;
  }
  Surveys() {
    this.surveys = true;
  }
  closeSurveys() {
    this.surveys = false;
  }

  CloseRFIs() {
    this.rFIs = false;
  }
  Catalog(flag: String) {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesWorkSheetAddformCatalogComponent,
      nzFooter: null,
      nzData: {
        CompanySetting: flag

      },

    });
    // modalRef.componentInstance.LeadProposals = flag;

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();

    });
    console.log(flag);
  }
  dataNew : any ;
  OwnerInvoices(dataInvo:OwnerInvoicesSetupResponse){
    const modalRef = this.modal.create({
     
      nzContent: OwnerInvoicesComponent,
    
      nzFooter: null,
    });
    modalRef.componentInstance.newVariable = dataInvo
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }



  closeCatalog() {
    this.catalog = false;
  }
  Bids() {
    this.bids = true;
  }
  closeBids() {
    this.bids = false;
  }
  Estimates() {
    this.estimates = true;

    const modalRef = this.modal.create({
      nzContent: EstimatesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });  }

  closeEstimates() {
    this.estimates = false;
  }
  BillsPOsBudget() {
    this.billspOsbudget = true;
  }
  closeBillsPOsBudget() {
    this.billspOsbudget = false;
  }
  // OwnerInvoices() {
  //   this.ownerinvoices = true;
  // }
  closeOwnerInvoices() {
    this.ownerinvoices = false;
  }
  SubsVendors() {
    
    const modalRef = this.modal.create({
      nzContent: CompanySettingsSubsVendorsComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  }
  closeSubsVendors() {
    this.subsvendors = false;
  }
  CustomerContacts() {
    this.customercontacts = true;
  }
  closeCustomerContacts() {
    this.customercontacts = false;
  }
  RoleManagement() {
    this.rolemanagement = true;
  }
  closeRoleManagement() {
    this.rolemanagement = false;
  }
  Pipedrive() {
    this.pipedrive = true;
  }
  closePipedrive() {
    this.pipedrive = false;
  }
  HubSpot() {
    this.hubSpot = true;
  }
  closeHubSpot() {
    this.hubSpot = false;
  }
  Gusto() {
    this.gusto = true;
  }
  closeGusto() {
    this.gusto = false;
  }
  AccountingSetup() {
    this.accountingsetup = true;
  }
  closeAccountingSetup() {
    this.accountingsetup = false;
  }



  // <-------ModalOpen------->
  CompanyInformation() {
    this.modalService.triggerShowModal();
  }


  Schedule() {
    const modalRef = this.modal.create({
      nzContent: ScheduleComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  Selections() {
    this.modalSelections.triggerSelections();
  }
  SelectionsGetById() {
    this.modalSelectionsGetById.triggerSelectionsGetById();
  }

  private initForm(): void {
    this.selectionSetupForm = this.fb.group({
      globalId: [''],
      companyNameId: [1],
      selectionBuilderViewId: [''],
      selectionOwnerViewId: [''],
      defaultToExpandCategoryLocationView: [false],
      alertUserNumber: [0],
      allowancesOnNewJob: [false],
      showAllowancesToSubsVendors: [false],
      showVendorContactInfoToOwner: [false],
      showLineItemsToOwner: [false],
      defaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs: [false],
      onNewJobsUpdateJobRunningTotalWhenASelectionIsApproved: [false],
      requireSignatureOnSelectionSetupId: [''],
      selectionsApprovalDisclaimer: [1],
      approvedChoicesOnly: [false],
      pricing: [false],
      subsVendors: [false],
      addedBy: [false],
      installers: [false],
      choiceDescription: [false],
      selectionDescription: [false],
      lineItems: [false],
      discussions: [false],
      customFields: [false],
      updateSelectionCustomFieldParameterRequests:
        this.fb.group({
          globalId: ['3fa85f64-5717-4562-b3fc-2c963f66afa6'],
          customFieldId: [1]
        }),
    });
  }
  private initFormPut(): void {
    // this.PutselectionSetupForm = this.fb.group({
    //   globalId: [''],
    //   companyParameterId: [1],
    //   selectionBuilderViewId: [0],
    //   selectionOwnerViewId: [0],
    //   defaultToExpandCategoryLocationView: [false],
    //   alertUserNumber: [0],
    //   allowancesOnNewJob: [false],
    //   showAllowancesToSubsVendors: [false],
    //   showVendorContactInfoToOwner: [false],
    //   showLineItemsToOwner: [false],
    //   defaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs: [false],
    //   onNewJobsUpdateJobRunningTotalWhenASelectionIsApproved: [false],
    //   requireSignatureOnSelectionSetupId: [0],
    //   selectionsApprovalDisclaimer: [''],
    //   approvedChoicesOnly: [false],
    //   pricing: [false],
    //   subsVendors: [false],
    //   addedBy: [false],
    //   installers: [false],
    //   choiceDescription: [false],
    //   selectionDescription: [false],
    //   lineItems: [false],
    //   discussions: [false],
    //   customFields: [false],
    //   updateSelectionCustomFieldParameterRequests: this.fb.array([]),
    // });
  }
  selectionsSetup() {
    this.selections = true;
  }
  closeselectionsSetup() {
    this.selections = false;
  }
  getid: any;
  openform(id: number): void {
    this.selections = true;

    this.getDataById(id);
  }

  getDataById(id: number): void {

    this.Selectionsservice.getDataId(id).subscribe((data: any) => {
      // console.log('getDataById',data.result);

      // Log the retrieved data to the console
      this.isUpdateForm = !!data;
      this.patchFormValues(data.result); // Assuming the response structure matches the form structure
      this.getid = data;
    });
  }
  patchFormValues(data: any) {
    this.selectionSetupForm.patchValue({
      globalId: data.globalId,
      companyNameId: data.companyNameId,
      selectionBuilderViewId: data.selectionBuilderViewId,
      selectionOwnerViewId: data.selectionOwnerViewId,
      defaultToExpandCategoryLocationView: data.defaultToExpandCategoryLocationView,
      alertUserNumber: data.alertUserNumber,
      showAllowancesToSubsVendors: data.showAllowancesToSubsVendors,
    });
  }
  UpdatePhase() {
    const formData = this.selectionSetupForm.value;
    this.Selectionsservice.updateData(formData).subscribe(
      (response) => {
        console.log('User updated successfully:', response);
        // console.log('from data update time :', formData);
        alert('User updated successfully');
        // this.getPhase();
        this.closeselectionsSetup();
        this.selectionSetupForm.reset();
      },
      (error) => {
        console.error('Error updating user:', error);
      }
    );
  }
  PhaseSet: SelectionSetup[] = [];

  // getSelectionSetup() {
  //   this.Selectionsservice.getData().subscribe(
  //     (data: any) => {
  //       // console.log('Data received:', data);
  //       this.PhaseSet = data.result;
  //     },
  //     (error) => {
  //       console.error('Error fetching data:', error);
  //     }
  //   );
  // }
  // <------SelectionBuilder------->
  SelectionBuilder: { id: number, name: string }[] = [];
  // getSelectionBuilder() {
  //   this.SelectionBuilderViewservice.getData().subscribe(
  //     (res: any) => {
  //       // console.log('SelectionBuilder type data', res);
  //       this.SelectionBuilder = res.result.SelectionBuilder || [];
  //       this.SelectionBuilder = res.result.map((SelectionBuilder: any) => ({ id: SelectionBuilder.id, name: SelectionBuilder.name }));
  //     },
  //     (error) => {
  //       console.error('Error fetching SelectionBuilder:', error);
  //     }
  //   );
  // }
  // <------SelectionOwner------->
  SelectionOwner: { id: number, name: string }[] = [];
  // getSelectionOwner() {
  //   this.SelectionOwnerViewservice.getData().subscribe(
  //     (res: any) => {
  //       // console.log('SelectionOwner type data', res);

  //       // Assuming res.result.Builder contains the array of Builder from the API response
  //       this.SelectionOwner = res.result.SelectionOwner || [];

  //       // Map both id and name properties
  //       this.SelectionOwner = res.result.map((SelectionOwner: any) => ({ id: SelectionOwner.id, name: SelectionOwner.name }));

  //       // Set the selectedValue to '--Choose Builder--' initially
  //     },
  //     (error) => {
  //       console.error('Error fetching SelectionOwner:', error);
  //     }
  //   );
  // }
  // <------RequireSignatureOnSelectionSetup------->
  RequireSignatureOnSelectionSetup: { id: number, name: string }[] = [];
  // getRequireSignatureOnSelectionSetup() {
  //   this.RequireSignatureOnSelectionSetupservice.getData().subscribe(
  //     (res: any) => {
  //       // console.log('RequireSignatureOnSelectionSetup type data', res);

  //       // Assuming res.result.Builder contains the array of Builder from the API response
  //       this.RequireSignatureOnSelectionSetup = res.result.RequireSignatureOnSelectionSetup || [];

  //       // Map both id and name properties
  //       this.RequireSignatureOnSelectionSetup = res.result.map((RequireSignatureOnSelectionSetup: any) => ({ id: RequireSignatureOnSelectionSetup.id, name: RequireSignatureOnSelectionSetup.name }));

  //       // Set the selectedValue to '--Choose Builder--' initially
  //     },
  //     (error) => {
  //       console.error('Error fetching RequireSignatureOnSelectionSetup:', error);
  //     }
  //   );
  // }

  openWarrantySetup() {
    const modalRef = this.modal.create({
      nzContent: WarrantyComponent,

    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  openCommentMessageSetup() {
    const modalRef = this.modal.create({
      nzContent: CommentMessagesComponent,

    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  openBidsSetup() {
    const modalRef = this.modal.create({
      nzContent: BidsComponent,

    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
}
