import { Component } from '@angular/core';
interface ParentItemData {
  key: number;
  name: string;
  platform: string;
  version: string;
  upgradeNum: number | string;
  creator: string;
  createdAt: string;
  expand: boolean;
}

interface ChildrenItemData {
  key: number;
  name: string;
  date: string;
  upgradeNum: string;
}
@Component({
  selector: 'app-bills-pos-budget',
  templateUrl: './bills-pos-budget.component.html',
  styleUrls: ['./bills-pos-budget.component.css']
})
export class BillsPOsBudgetComponent {
  listOfParentData: ParentItemData[] = [];
  listOfChildrenData: ChildrenItemData[] = [];
  selectedValue = null;
  checked = false;
  checked1 = false;
  checked2 = false;
  checked3 = false;
  checked4 = false;
  checked5 = false;
  checked6 = false;
  checked7 = false;
  checked8 = false;
  checked9 = false;
  checked10 = false;
  demoValue = 3;


  autoAdjustTextAreaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; // Set the height with a maximum of 200px
  }

  isInputVisible: boolean = false;

  toggleVisibility() {
    this.isInputVisible = true;
  }

  customfieldinfo:boolean = false;
  CustomFieldInfo(){
    this.customfieldinfo = true;
  }
  handleCancel2(): void {
    this.customfieldinfo = false;
  }
  purchaseorderscustomfield:boolean = false;
  PurchaseOrdersCustomField(){
    this.purchaseorderscustomfield = true;
  }
  ClosePurchaseOrdersCustomField(): void {
    this.purchaseorderscustomfield = false;
  }
  billscustomfield:boolean = false;
  BillsCustomField(){
    this.billscustomfield = true;
  }
  CloseBillsCustomField(): void {
    this.billscustomfield = false;
  }
  lienwaiverform:boolean = false;
  LienWaiverForm(){
    this.lienwaiverform = true;
  }
  CloseLienWaiverForm(): void {
    this.lienwaiverform = false;
  }

  ngOnInit(): void {
    for (let i = 0; i < 3; ++i) {
      this.listOfParentData.push({
        key: i,
        name: 'Screem',
        platform: 'iOS',
        version: '10.3.4.5654',
        upgradeNum: 500,
        creator: 'Jack',
        createdAt: '2014-12-24 23:12:00',
        expand: false
      });
    }
    for (let i = 0; i < 3; ++i) {
      this.listOfChildrenData.push({
        key: i,
        date: '2014-12-24 23:12:00',
        name: 'This is production name',
        upgradeNum: 'Upgraded: 56'
      });
    }
  }
}