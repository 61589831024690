<nz-modal
    nzWidth="90%"
    [(nzVisible)]="companyinformation"
    [nzTitle]="modalTitle"
    [nzFooter]="modalFooter"
    (nzOnCancel)="closeCompanyInformation()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '68vh', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
    <ng-template #modalTitle> <div class="row p-0 m-0">
        <h1 class="p-0 m-0 fw-medium">Company Information</h1></div>
    </ng-template>
    <div  class="cantent" *nzModalContent>
        <div class="row famly-inn gx-3">
            <div class="col-12">
                <div class="card border-0">
                    <div class="card-head border-bottom">
                        <div class="row mb-0 p-2 d-flex align-items-center">
                          <div class="col">
                            <span class="fw-medium fs-6 ms-2">Company Information</span>
                          </div>
                
                        </div>
                      </div>
                    <div class="card-body">
                <form [formGroup]="CompanyformationFormFormGroup">
                  <div class="row">
                    <div class="col-6">
                        <div class="row">
                            <span class="fw-medium fs-6 ms-0">Business Address</span>
                        </div>

                        <div class="row gx-2  mt-3">
                            <div class="col-md-6">
                                <label class="sub-text">
                                    Company Name <span class="text-danger fw-bolder">*</span>
                                </label>
                                <input
                                    formControlName="name"
                                    type="text"
                                    class="form-control formField">
                            </div>
                            <div class="col-md-6" style="margin-top: 25px;">
                                <div class="row mt-2">
                                    <div class=" col-9 d-flex align-items-center">
                                    <label formControlName="includeOnPrintOuts" nz-checkbox [(ngModel)]="checked1">Include on Printouts</label>
                                    </div>
                                
                                </div>
                            </div>  
                        </div>
                        <div class="row mt-2">
                            <div class=" col-9 d-flex align-items-center">
                                <label formControlName="includeAllAddressInformationPrintOut" nz-checkbox  [(ngModel)]="checked">Include all Address information on Printout</label>
                            </div>
                        
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="sub-text"> Street Address </label>
                                <input
                                    formControlName="streetAddress"
                                    type="text"
                                    class="form-control formField">
                            </div>
                        </div>
                        <div class="row gx-2">
                            <div class="col-4">
                                <div class="col-md-12">
                                    <label class="sub-text">Suburb</label>
                                    <input
                                        formControlName="suburb"
                                        type="text"
                                        class="form-control formField">
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="col-md-12">
                                    <label class="sub-text">State</label>
                                    <input
                                        formControlName="state"
                                        type="text"
                                        class="form-control formField">
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="col-md-12">
                                    <label class="sub-text">Postal Code</label>
                                    <input
                                    formControlName="postalCode"
                                    type="text"
                                    class="form-control formField">
                                </div>
                            </div>
                            
                        </div>
                        <div class="row mt-2">
                            <span class="fw-medium fs-6 ms-0">Printout Information</span>
                        </div>
                        <div class="row mt-2">
                        <label class="pira-label">These fields will be included on your header</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="sub-text">Field 1</label>
                                <input
                                    formControlName="field1"
                                    type="text"
                                    class="form-control formField">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="sub-text">Field 2</label>
                                <input
                                    formControlName="field2"
                                    type="text"
                                    class="form-control formField">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="sub-text">Field 3</label>
                                <input
                                    formControlName="field3"
                                    type="text"
                                    class="form-control formField">
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <span class="fw-medium fs-6 ms-0">Contact Information</span>
                        </div>
                    <div class="row  mt-3">
                        <div class="col-12">
                            <label class="sub-text">Website</label>
                              <div class="input-group">
                                <span class="input-group-text d-flex justify-content-center align-items-center shadow-none" style="width: 5px; height: 30px;">$</span>
                                <input type="text" class="radius-work p-inputtext-sm form-control  shadow-none" formControlName="webSiteUrl"  style="height: 30px; border-top-left-radius: 0; border-bottom-left-radius: 0;">
                            </div>
                            
                        </div>
                    </div>
                    <div class="row gx-2 mt-2">
                        <div class="col-md-6">
                            <label class="sub-text">Phone</label>
                            <input
                                formControlName="phoneNumber"
                                type="fax"
                                class="form-control formField">
                        </div>
                        <div class="col-md-6">
                            <label class="sub-text">Fax </label>
                            <input
                                formControlName="fax"
                                type="text"
                                class="form-control formField">
                        </div>
                    </div>
                  </div>
                    </div>
                </form>
                </div>
                <span class="mt-5"></span>
                </div>
                <div class="card border-0 mt-3">
                    <div class="card-head border-bottom">
                        <div class="row mb-0 p-2 d-flex align-items-center">
                          <div class="col">
                            <span class="fw-medium fs-6 ms-2">Additional Information</span>
                          </div>
                
                        </div>
                      </div>
                    <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                   <div class="row">
                    <span class="sub-text">Time Zone</span>
                    <span class="pira-label">Canberra, Melbourne, Sydney [GMT+10]</span>
                    <span class="sub-text">Country</span>
                    <span class="pira-label">AU</span>
                   </div>
                    </div>
                    </div>
                </div>
                </div>
        </div>
        </div>
    </div>
    <ng-template #modalFooter>
        <div class="mt-2 align-items-center">
          <button (click)="SaveComInfor()" nz-button nzType="primary" class="rounded-1 me-0" >Save</button>
        </div>
      </ng-template>
</nz-modal>