import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzSelectSizeType } from 'ng-zorro-antd/select';

@Component({
  selector: 'app-project-management-site-diaries-to-do-information',
  templateUrl: './project-management-site-diaries-to-do-information.component.html',
  styleUrls: ['./project-management-site-diaries-to-do-information.component.css']
})
export class ProjectManagementSiteDiariesToDoInformationComponent {

  TOdoInformation:FormGroup;
  constructor(private fb:FormBuilder){}
  autoAdjustTextAreaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; // Set the height with a maximum of 200px
  }
  listOfOption: Array<{ label: string; value: string }> = [];
  size: NzSelectSizeType = 'default';
  singleValue = 'a10';
  multipleValue = ['a10', 'c12'];
  tagValue = ['a10', 'c12', 'tag'];

  ngOnInit(): void {
    const children: Array<{ label: string; value: string }> = [];
    for (let i = 10; i < 36; i++) {
      children.push({ label: i.toString(36) + i, value: i.toString(36) + i });
    }
    this.listOfOption = children;
  }
  initializeForm(): void {
    this.TOdoInformation = this.fb.group({
      complete: [null],
      title: [null],
      notes: [null],
     assignedTo: [null],
     dueDate: [null],
     time: [null],
     tags: [null],
     due: [null],
     before: [null],
     after: [null],
      
      
  // Grid Baqi Ha 
    });
  }

  isRowVisible = true;
  isRowVisible1 = false;

  showRow() {
    this.isRowVisible = true;
    this.isRowVisible1 = false;
  }

  showRow1() {
    this.isRowVisible1 = true;
    this.isRowVisible = false;
  }
}



