import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-warranty-new-claim-edit-warranty-category',
  templateUrl: './warranty-new-claim-edit-warranty-category.component.html',
  styleUrls: ['./warranty-new-claim-edit-warranty-category.component.css']
})
export class WarrantyNewClaimEditWarrantyCategoryComponent {
  @Output() cancel = new EventEmitter<void>();

    // selectionModel
    Taghandle= true;
    TagClose(): void {
    this.cancel.emit();
    }
}
