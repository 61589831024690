
<nz-layout>
  <nz-sider
    class="border"
    nzCollapsible
    [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed"
    [nzWidth]="280"
    [nzTrigger]="null"
  >
    <div class="row">
      <app-side-menu-bar
      [isAllJobOption]="true"
      (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"
      ></app-side-menu-bar>
    </div>
  </nz-sider>
<nz-layout>
  <nz-page-header>
    <nz-page-header-title>
      <nz-breadcrumb class="text-start" nz-page-header-breadcrumb>
        <nz-breadcrumb-item
          >ZH-211 Lot 1909 Cosgrove Street Tarneit VIC 3029</nz-breadcrumb-item
        >
      </nz-breadcrumb>
      <h1>Surveys</h1>
    </nz-page-header-title>
    <nz-page-header-extra>
      <nz-space>
        <button *nzSpaceItem nz-button>Import Surveys</button>
        <button
          *nzSpaceItem
          nz-button
          (click)="openFilter(Surveys_Individual, PAGE_ID)"
        >
          Filter
        </button>
        <button *nzSpaceItem nz-button>Add Surveys to website</button>
        <!-- <button *nzSpaceItem nz-button nzType="primary">New Surveys</button> -->
        <button
          *nzSpaceItem
          nz-button
          nzType="primary"
          (click)="openSelectaSurveyForm()"
        >
          New Surveys
        </button>
      </nz-space>
    </nz-page-header-extra>
  </nz-page-header>
  <nz-content class="custom-content">
    <div class="container-fuild mb-4 m-3">
      <nz-tabset nzType="card">
        <nz-tab
          *ngFor="let tab of tabs"
          [nzTitle]="innerCardTabs(tab)"
          style="background-color: #f1f4fa"
        >
          <ng-container *ngIf="tab === 1">
            <app-surveys-empty-content></app-surveys-empty-content>
          </ng-container>
          <ng-container *ngIf="tab === 2">
            <app-surveys-empty-content></app-surveys-empty-content>
          </ng-container>
          <ng-container *ngIf="tab === 3">
            <app-surveys-empty-content></app-surveys-empty-content>
          </ng-container>
        </nz-tab>
      </nz-tabset>
    </div>
  </nz-content>
</nz-layout>
</nz-layout>
