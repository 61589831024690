import { Component } from '@angular/core';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';

@Component({
  selector: 'app-inner-tab-phases-tags',
  templateUrl: './inner-tab-phases-tags.component.html',
  styleUrls: ['./inner-tab-phases-tags.component.css']
})
export class InnerTabPhasesTagsComponent {
  value: string[] = ['0-0-0'];
  selectedValue=null;
  scheduleInnerModal2= false;
  scheduleInnerModal1= false;

  scheduleInnercardModal1(): void {
    this.scheduleInnerModal1 = true;
  }

  scheduleInnercardModal2(): void {
    this.scheduleInnerModal2 = true;
  }

  nodes: NzTreeNodeOptions[] = [
    {
      title: 'Check All',
      value: '0-all',
      key: '0-all',
      isLeaf: true,
      checked: true
    },
    {
      title: 'Node1',
      value: '0-0',
      key: '0-0',
      isLeaf: true
    },
    {
      title: 'Node2',
      value: '0-1',
      key: '0-1',
    },
    {
      title: 'Node3',
      value: '0-2',
      key: '0-2',
      isLeaf: true
    }
  ];

}
