<nz-modal nzWidth="72%" [(nzVisible)]="newCostGroup" [nzTitle]="Cost12" (nzOnCancel)="newCostGroupCancel()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '70vh', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '37px' }" [nzFooter]="modalFooter">
    <ng-template #Cost12>
        <div class="row p-0 m-0">
            <h3 class="p-0 m-0 fw-medium">Cost Item</h3>
        </div>
    </ng-template>
    <div class="cantent" *nzModalContent>
        <form [formGroup]="NewCostGroup">

        <div class="row">
            <div class="col-12">
                 <nz-card [nzTitle]="CostGroupEditor">
                    <ng-template #CostGroupEditor>
                        <strong class="mt-1">Cost Group Editor</strong>
                    </ng-template>
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6">
                                    <label class="sub-text">
                                        Title <span class="text-danger">*</span>
                                    </label>
                                    <input formControlName="title" type="text" class="form-control formField">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 mt-1">
                                    <label class="sub-text">Description</label>
                                    <textarea formControlName="description"  class="formField" rows="2" nz-input [(ngModel)]="inputValue"></textarea>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <span style="font-weight: 500;" class="fs-6">Attachments</span>
                            </div>
                            <div class="row">
                                <div class="col mt-3">
                                    <button nz-button nzType="default">Add</button>
                                    <button nz-button nzType="default" class="ms-1">Create New Doc</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-card>

                <!-- Sencond Card -->
                    <nz-collapse   [nzBordered]="false" class="mt-2">
                        <nz-collapse-panel 
                          #p
                          [ngStyle]="{'background': '#ffffff','border-radius': '4px',
                          'margin-bottom': '10px',
                          border: '0px'}"
                          [nzExpandedIcon]="expandedIcon"
                          [nzHeader]="tileetwe"
                          [nzExtra]="extraTemplate"
                        >
                        <div class="row mt-0" #p>
                            <nz-card nzBorderless="true">
                                <div class="row">
                                    <div class="col-12">
                                         <nz-empty nzNotFoundImage="simple"></nz-empty>
                                    </div>
                                </div>
                              </nz-card>
                           
                        </div>
                          <ng-template #tileetwe >
                            <div class="row">
                                <strong class="mt-1"><h3>Custom Fields (0)</h3></strong>
                            </div>
                           
                          </ng-template>
                          <ng-template #expandedIcon let-active>
                            {{ active }}
                            <span nz-icon nzType="caret-right" class="ant-collapse-arrow" [nzRotate]="p.nzActive ? 90 : -0"></span>
                          </ng-template>
                          <ng-template #extraTemplate>
                            <div class="col-6 text-end"><button nz-button nzType="primary" (click)="addButtonshowModel()">Add</button></div>
                          </ng-template>
                        </nz-collapse-panel>
                      </nz-collapse>


            </div>
        </div>
        </form>
    </div>




    <ng-template #modalFooter>

        <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>

    </ng-template>
</nz-modal>