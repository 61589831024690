import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment.prod";
import { RfiInformationAnswerApprovedGetById, RFIInformationAnswerGetById, RfiInformationAnswerResponse, RFIInformationGetById, RfiInformationPostById, RFIResponse } from "src/Models/_RFIs/RFIs_Infor";
import { ResponseModel, ResponseModelArray } from "src/Models/responseMessage.model";
import { RFIsSetupResponse } from "src/Models/RFIs.model";

@Injectable({
  providedIn: 'root'
})
export class RFIsInforServiceService {
  private RFIs_Answer_URL: string = environment.apiUrl + 'RfiInformationAnswer/';
  private RFIs_URL: string = environment.apiUrl + 'RFI/';
  private RFIs_URL_Get_RFIs: string = environment.apiUrl + 'RFI/getRFIInformation-byRFIId?RFIId';
  private RFIs_URL_SETUP: string = environment.apiUrl + 'RFIsSetup/';
  private RFIs_Setup_Fetch: string = environment.apiUrl + 'RFIsSetup/getData-ByCompany/';

  constructor(private http: HttpClient) {}

  PostRfis(createLead: RFIResponse): Observable<ResponseModel<RFIResponse>> {
    const apiUrl = `${this.RFIs_URL}`;
    return this.http.post<ResponseModel<RFIResponse>>(apiUrl, createLead).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  PostRfisSetup(rfiData: RFIResponse): Observable<ResponseModel<RFIResponse>> {
    const apiUrl = `${this.RFIs_URL_SETUP}`;
    return this.http.post<ResponseModel<RFIResponse>>(apiUrl, rfiData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  RFIsgetDataId(id: number): Observable<ResponseModel<RFIInformationGetById>> {
    return this.http.get<ResponseModel<RFIInformationGetById>>(`${this.RFIs_URL}getBy-Id?id=${id}`);
  }
  RFIsgetRFIInformation(id: number): Observable<ResponseModelArray<RfiInformationPostById>> {
    const apiUrl = `${this.RFIs_URL_Get_RFIs}=${id}`;
    return this.http.post<ResponseModelArray<RfiInformationPostById>>(apiUrl, id).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }  
  PostRfisNetwork(createLead: RfiInformationAnswerResponse): Observable<ResponseModel<RfiInformationAnswerResponse>> {
    const apiUrl = `${this.RFIs_Answer_URL}`;
    return this.http.post<ResponseModel<RfiInformationAnswerResponse>>(apiUrl, createLead).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }  
  RFIsInfoAnswergetDataId(id: number): Observable<ResponseModelArray<RFIInformationAnswerGetById>> {
    return this.http.get<ResponseModelArray<RFIInformationAnswerGetById>>(`${this.RFIs_Answer_URL}getBy-RFIAnswerId?Id=${id}`);
  }
  deleteData(_global_Id: string): Observable<RfiInformationAnswerResponse> {
    return this.http.delete<RfiInformationAnswerResponse>(
      `${this.RFIs_Answer_URL}?globalId=${_global_Id}`
    );
  }

  getCompanyRFIsSetup(): Observable<ResponseModel<RFIsSetupResponse>> {
    return this.http.get<ResponseModel<RFIsSetupResponse>>(this.RFIs_Setup_Fetch);
  }

  private baseUrl: string = environment.apiUrl + 'RfiInformationAnswer/update-RfiAnswer';
  RFIsInfoAnswerApproved(globalId: string, data: any = {}): Observable<ResponseModelArray<RfiInformationAnswerApprovedGetById>> {
    return this.http.put<ResponseModelArray<RfiInformationAnswerApprovedGetById>>(`${this.baseUrl}?globalId=${globalId}`, data);
  }
}
