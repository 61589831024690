import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ScheduleSetupPhaseParameter, ScheduleSetupPhaseParameterResponse } from 'src/Models/CompanySettings/Schedule/PhaseSetup/Company-Settings-Schedule';
import { PhaseSetup } from 'src/Models/CompanySettings/Schedule/PhaseSetup/PhaseSetup';
import { ColorResponse } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { PhaseService } from 'src/Service/CompanyInFormation/ScheDule/Phase/phase.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { colorService } from 'src/Service/Job-List/Job-Information/color.service';
import { ScheduleService } from 'src/Service/Project-Management/schedule.service';
import { CompanySettingsSchedulePreDefineCodes, ProjectManegmentScheduleItemDetailsPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {

  @Output() cancel = new EventEmitter<void>();

  isLargeScreen = true; // Initially assuming a large screen
  colors: ColorResponse[] = [];
  PhaseDetails: boolean = false;
  PhaseDetailsFieldValues: any = {};
  isPhaseDetailsLoadingEdit: boolean = false;
  phase: ScheduleSetupPhaseParameter[] = [];
  PhaseSet: ScheduleSetupPhaseParameterResponse[] = [];
  updateModalVisible = false;
  schedule: boolean = true;
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  pageId: number = 30010;
  formNameId: number = 8;
  preDefinedDataCodes = {
    defaultBuilderView: CompanySettingsSchedulePreDefineCodes.defaultBuilderView,
    defaultOwnerView: CompanySettingsSchedulePreDefineCodes.defaultOwnerView,
    defaultScheduleItemReminders: ProjectManegmentScheduleItemDetailsPreDefinedCodes.Reminder,
  };

  builder: LookupNameSetupResponse[] = [];
  owner: LookupNameSetupResponse[] = [];
  scheduleItemReminder: LookupNameSetupResponse[] = [];
  scheduleFormsValues: any = {};
  addphase: boolean = false;
  headerId: number = -1;
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1600) {
      this.isLargeScreen = true; // Consider this as an extra-large screen
    } else if (screenWidth >= 1200) {
      this.isLargeScreen = true; // Consider this as a large screen
    } else if (screenWidth >= 768 && screenWidth < 1200) {
      this.isLargeScreen = false; // Consider this as a medium screen, adjust as needed
    } else {
      this.isLargeScreen = false; // Consider this as a small screen, adjust as needed
    }
  }

  constructor(
    private LookupFilterService: LookupFilterService,
    private CompanyScheduleService: ScheduleService,
    private toasterService: NzMessageService,
    private PhaseService: PhaseService,
    private colorService: colorService,
  ) {

  }
  ngOnInit(): void {
    this.scheduleFormsValues = {
      companyParameterId: 1,
      builderViewId: 0,
      ownerViewId: 0,
      isPhases: false,
      isScheduleItem: false,
      scheduleItemReminderId: 0,
      sendConfirmationRequestWithScheduleItemReminders: false,
      defaultNotifyLinkedSubsVendor: false,
      showTimesForHourlyItemsOnCalendarMonthView: false,
      showJobNameOnCalendarMonthWeekDayViewsForAllListedJobs: false,
      automaticallyMarkItemsComplete: false,
      newScheduleItemsDefaultToShowSubsVendors: false,
      includeHeaderOnSchedulePdfExports: false
    }
    this.initLoad();
    this.getColor();
    // this.getAllPhase();

    this.PhaseDetailsFieldValues = {
      scheduleSetupId: 0,
      id: 0,
      globaliId: '',
      name: '',
      displayOrder: 0,
      colorId: 0,
    }

  }


  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(
      (res) => {
        this.lookupSetNameResponse = res.result;
        // -----------Multi Select ---------------

        let defaultBuilderViewValue = res.result.filter(
          (x: { code: string; }) => x.code === this.preDefinedDataCodes.defaultBuilderView.code
        );
        let defaultOwnerViewValue = res.result.filter(
          (x: { code: string; }) => x.code === this.preDefinedDataCodes.defaultOwnerView.code
        );
        // -----------single Select ---------------
        this.builder = defaultBuilderViewValue;
        this.owner = defaultOwnerViewValue;

      }
    );
    this.LookupFilterService.getLookUpValuesByFormNameId(this.formNameId).subscribe(
      (res) => {
        this.lookupSetNameResponse = res.result;
        // -----------Multi Select ---------------

        let defaultScheduleItemReminders = res.result.filter(
          (x: { code: string; }) => x.code === this.preDefinedDataCodes.defaultScheduleItemReminders.code
        );
        // -----------single Select ---------------
        this.scheduleItemReminder = defaultScheduleItemReminders;

      }
    );
  }


  saveScheduleCompanySetting() {
    console.log('Form Values', this.scheduleFormsValues);

    this.CompanyScheduleService.postScheduleCompanySettings(this.scheduleFormsValues).subscribe(
      (res) => {
        // Show success message
        this.headerId = res.result.id;
        this.toasterService.success('Schedule settings saved successfully!');
        // this.cancel.emit();
      },
      (error) => {
        // Show error message
        this.toasterService.error('Failed to save schedule settings. Please try again.');
        console.error('Error:', error);
      }
    );
  }



  closeSchedule() {
    this.schedule = false;
    this.cancel.emit();
  }




  AddNewPhasecancel() {
    this.PhaseDetails = false;
    this.resetForm();
  }

  showAddNewPhase(id: number) {
    this.PhaseDetails = true;
    this.isPhaseDetailsLoadingEdit = false;
    this.PhaseDetailsFieldValues.scheduleSetupId = id;
    console.log(this.PhaseDetailsFieldValues);

  }

  resetForm() {
    this.PhaseDetailsFieldValues = {};
  }
  EditAddNewPhase(selectedValue: ScheduleSetupPhaseParameterResponse,): void {
    console.log();
    
    if (selectedValue) {
      this.PhaseDetails = true;
      this.isPhaseDetailsLoadingEdit = true;

      this.PhaseDetailsFieldValues['globalId'] = (selectedValue.globalId);
      this.PhaseDetailsFieldValues['name'] = (selectedValue.name);
      this.PhaseDetailsFieldValues['id'] = (selectedValue.id);
      this.PhaseDetailsFieldValues['displayOrder'] = (selectedValue.displayOrder);
      this.PhaseDetailsFieldValues['colorId'] = (selectedValue.colorId);
      this.PhaseDetailsFieldValues['scheduleSetupId'] = (selectedValue.scheduleSetupId);
      console.log(this.PhaseDetailsFieldValues);
      // }

    } else {
      console.log('Selected value not found in phases array');
    }
  }


  phaseDataDelete() {
    this.isPhaseDetailsLoadingEdit = false;
    this.PhaseService.deleteData(this.PhaseDetailsFieldValues.globalId).subscribe(
      (response) => {
        this.PhaseDetails = false;
        // this.PhaseSet response.
        this.PhaseDetailsFieldValues = {};
      },
      (error) => {
        console.error('Error saving data:', error);
      }
    );
  }


  getColor() {
    this.colorService.getData().subscribe((res: any) => {
      const colorGet = res.result;
      this.colors = colorGet;
      const selectedColor = this.colors.find(color => color.name === 'Olive');

      // Set the selected colorId if 'Olive' is found
      if (selectedColor) {
        this.PhaseDetailsFieldValues['colorId'] = selectedColor.id;
      }

    })

  }

  savePhaseDetails(): void {
    if (this.isPhaseDetailsLoadingEdit) {
      this.editDataPhase();
    } else {
      this.SaveDataPhase();
    }
  }


  SaveDataPhase() {
    this.isPhaseDetailsLoadingEdit = false;
    this.PhaseService.postData(this.PhaseDetailsFieldValues).subscribe(
      (response) => {
        // Ensure response.result is always an array
        const phaseData = Array.isArray(response.result) ? response.result : [response.result];
        this.PhaseSet = phaseData;
        this.PhaseDetails = false;
        this.PhaseDetailsFieldValues = {};
      },
      (error) => {
        console.error('Error saving data:', error);
      }
    );
  }
  


  editDataPhase() {
    this.isPhaseDetailsLoadingEdit = true;
    this.PhaseService.updateData(this.PhaseDetailsFieldValues).subscribe(
      (response) => {
        this.getColor();
        // this.getAllPhase();
        this.PhaseDetails = false;
      },
      (error) => {
        console.error('Error saving data:', error);
      }
    );
  }


  // getAllPhase(){
  //   this.PhaseService.getData().subscribe(res => {
  //      this.PhaseSet = res.result
  //      console.log('phases',this.PhaseSet);
  //   })
  // }

}
