import { ContactListComponent } from './../../contact-list/contact-list.component';
import { CustomFieldFormComponent } from './../../../../shared/component/custom-field-form/custom-field-form.component';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { NewContactComponent } from 'src/app/customer-infromation/Components/new-contact/new-contact.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LeadOpportunityListViewPreDefinedCodes, CustomerStatusCode } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { AttachmentResponse, CreateAttachmentForDocumentRequest, LeadOpportunityResponse } from 'src/Models/LeadTags';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { CustomerInformationResponse } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import { NewProposalComponent } from '../../new-proposal/new-proposal.component';
import { LeadOpportunityAttachmentsUploadFilesComponent } from '../lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { ProjectManagementSiteDiariesCreateFilesComponent } from 'src/app/project-management/components/Site-Diaries/project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from '../lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { LeadToJobComponent } from '../lead-to-job/lead-to-job.component';
import { LeadOpportunityImportTemplateComponent } from '../lead-opportunity-import-template/lead-opportunity-import-template.component';
import { CopyToActivityTemplateComponent } from '../copy-to-activity-template/copy-to-activity-template.component';
import { ImportProposalsComponent } from '../import-proposals/import-proposals.component';
import { CopyToProposalsTemplateComponent } from '../copy-to-proposals-template/copy-to-proposals-template.component';
import { LeadProposalResponse } from 'src/Models/LeadProposal/LeadProposalModels';
import { LeadActivitiesService } from 'src/Service/Sales-Lead-Activities/lead-activities.service';
import { LeadProposalsService } from 'src/Service/LeadProp/lead-proposals.service';
import { LeadActivityFormComponent } from '../../lead-activity-form/lead-activity-form.component';
import { environment } from 'src/environments/environment.prod';
import { LeadActivityResponse } from 'src/Models/LeadActivityTemplate/LeadActivityTemplate';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { GoogleMapComponent } from '../../lead-Opportunity-map/google-map/google-map.component';
import { AttachmentAnnotationComponent } from '../../attachment-annotation/attachment-annotation.component';
import { NzImageService } from 'ng-zorro-antd/image';
import { PhotoDrawComponent } from '../../photo-draw/photo-draw.component';
import { Autoformat, BlockQuote, Bold, ClassicEditor, EditorConfig, Essentials, Font, FontBackgroundColor, FontColor, FontFamily, FontSize, Heading, HorizontalLine, Image, ImageUpload, Indent, Italic, Link, List, ListProperties, MediaEmbed, Paragraph, Table, Undo, UndoEditing } from 'ckeditor5';
import { LeadActivityAssignDisplayColorComponent } from '../lead-activity-assign-display-color/lead-activity-assign-display-color.component';
import { LeadActivityAssignUserComponent } from '../lead-activity-assign-user/lead-activity-assign-user.component';
import { LeadActivityDeleteComponent } from '../lead-activity-delete/lead-activity-delete.component';
import { FooterService } from 'src/Service/Footer/footer.service';
import { AuditDetailsResponse, FilterFooterAudit } from 'src/Models/Footer/footer.model';
// import { ContactListComponent } from '../../contact-list/contact-list.component';
interface ItemData {
  id: number;
  name: string;
  age: number;
  address: string;
}
interface ModalTitle {
  title?: string;
  deleteTitle?: string; // Optional property for cases where deleteTitle is not needed
}
interface CustomUploadFile extends NzUploadFile {
  loading?: boolean;
  error?: boolean;
  addedBy: number
  createdOn: Date;
  modifiedOn: Date;
  id: number;
  globalId: string;
}
interface Person {
  key: string;
  name: string;
  age: number;
  address: string;
}
@Component({
  selector: 'app-lead-opportunities-new-lead-opportunities',
  templateUrl: './lead-opportunities-new-lead-opportunities.component.html',
  styleUrls: ['./lead-opportunities-new-lead-opportunities.component.css'],

})
export class LeadOpportunitiesNewLeadOpportunitiesComponent implements OnInit, AfterViewInit {
  IMG_BASE: string = environment.IMG_BUCKET_URL;
  selectedFiless: CustomUploadFile[] = [];
  @ViewChild('carouselContainer', { static: false }) carouselContainer!: ElementRef<HTMLDivElement>;
  @HostListener('window:resize', ['$event'])
  @ViewChild('titleInput') titleInput: ElementRef<HTMLInputElement>;
  // IMG_BASE: string = environment.IMG_BUCKET_URL;

  @Output() cancel = new EventEmitter<void>();
  @Output() leadOpportunityOnCompleted = new EventEmitter();
  public selectedCustomer: CustomerInformationResponse | null = null;
  @Input() leadOpportunityResponseId: number;
  @Input() leadProposalId: number;

  @Input() flagDialogBoxForProposalTabActive: number = 0;
  @Input() leadActivityResponseId: number;
  @Input() TestingforResponse: any;
  currentIndex = 0;
  showNavButtons = false;
  leadOpportunityResponse: LeadOpportunityResponse;
  leadActivitiesResponse: LeadActivityResponse[] = [];
  leadProposalsResponse: LeadProposalResponse[] = [];
  validationErrors: string[] = [];
  // @Input() leadOpportunityResponse: LeadOpportunityResponse;
  @Output() CustomerResponse: CustomerInformationResponse;
  JobMenuButton: boolean = false;

  date = null;
  imageUploaded = false;
  // value: LookupNameSetupResponse[];
  // source: LookupNameSetupResponse[];
  // projectTypeFiled: LookupNameSetupResponse[];
  onSaveComplete: any;
  data: CustomerInformationResponse;
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  salespersonMulti: NzTreeNodeOptions[] = [];
  // public ModalTitle : string = '';
  public ModalTitle: ModalTitle; // Initialize with the correct type
  inputValue: string = 'my site';
  inputValues: string;
  searchText = '';
  sliderValue: number = 0;
  // fileList: NzUploadFile[] = [];

  // sliderValue: number = 0;
  conformCardShow = false;
  proposalConformCardShow = false;
  pageId: number = 36;
  TagsForm: FormGroup;
  Sources: FormGroup;
  projectTypes: FormGroup;
  public isTagVisible = false;
  isLoadingEdit: boolean = false;
  isLoadingOne: boolean = false;
  confirmModal?: NzModalRef;
  isDeleteTagsLoading: boolean = false;
  editorValue: string = '';
  expandSet = new Set<number>();
  checked = false;
  pageIndex: number;
  checked18: boolean;
  indeterminate = false;
  listOfCurrentPageData: readonly ItemData[] = [];
  setOfCheckedId = new Set<number>();
  // --------------------sami Brach Name Sami/Features-Activity-Work-------------------------
  formFieldValues: any = {};
  tagNodes: NzTreeNodeOptions[] = [];
  sourceNodes: NzTreeNodeOptions[] = [];
  status: { label: string; value: string }[] = [];
  projectType: { label: string; value: string }[] = [];
  // --------------------------------sami Brach Name Sami/Features-Activity-Work-------------------------
  opportunityTitleValid: boolean = false;
  isChooseVisible = false;
  filterDrawerVisible = false;
  isVisibleAddModal = false;
  isOpen5: boolean = false;
  mainTabs = [9, 10, 11];
  // ----------   Lead Activities Table Start----------- //
  listOfActivity: any[];
  listOfProposal: any[];
  // ----------   Lead Activities Table End ----------- //
  newOpportunityModalNotClose: boolean = true;
  // Created By Mehmood
  leadOpportunitySaveResponse: LeadOpportunityResponse;
  initialFormFieldValues: any = {};
  preDefinedDataCodes = {
    status: LeadOpportunityListViewPreDefinedCodes.Status,
    tags: LeadOpportunityListViewPreDefinedCodes.Tags,
    source: LeadOpportunityListViewPreDefinedCodes.Source,
    projectType: LeadOpportunityListViewPreDefinedCodes.ProjectType,
  };
  isLoading: boolean = false;
  modalErrorMessages: string[];
  // selectedFiles: File[] = [];
  attachments: AttachmentResponse[] = [];
  isSave = false;
  isDeleteLoading: boolean = false;
  leadActivityCount: number;
  leadProposalCount: number;
  isCheckboxSelected = false;
  selectedStatus: { label: string; value: string; };
  showReclassify: boolean = false;
  activeTab: number;
  footerResponse: AuditDetailsResponse;
  @Output() gridPatchedValue: boolean = false;


  constructor(
    private nzImageService: NzImageService,
    private FooterService: FooterService,
    private modal: NzModalService,
    private LookupFilterService: LookupFilterService,
    private customerInformationService: CustomerInformationService,
    private fb: FormBuilder,
    private loadingIndicatoreService: LoadingIndicatorService,
    public toastService: NzMessageService,
    private leadOppService: LeadOpportunitiesService,
    private leadActivitiesService: LeadActivitiesService,
    private leadProposalsService: LeadProposalsService,
    private cd: ChangeDetectorRef,
    @Inject(NZ_MODAL_DATA) public leadData: any,
    @Inject(NZ_MODAL_DATA) public lead: any,
    @Inject(NZ_MODAL_DATA) public datas: any,
  ) {


  }
  // ---------------------Tag Methods---------------------------
  //  sami Working ------------------------------ ////////////////////


  ngOnInit(): void {


    this.initializeFormFields();
    this.initLoad().then(() => {
      if (this.leadOpportunityResponseId > 0) {
        this.fetchLeadData(this.leadOpportunityResponseId);
      }
    });


    this.updateSliderValue(this.formFieldValues.confidence || 0);
    this.leadOppService.setSelectedJobId(10);
    this.leadOppService.getSelectedJobId(); // This line might be redundant if not used
    // this.leadOppService.getSelectedFiles().subscribe(files => {
    // });

    // Load lookup values and initialize form
    this.ForCombo();
    this.iniliazeForm();
  }

  /// auto focus title field
  ngAfterViewInit(): void {
    this.focusTitleInput();
  }

  private focusTitleInput(): void {
    setTimeout(() => {
      if (this.titleInput) {
        this.titleInput.nativeElement.focus();
      }
    }, 1000);  // Reduced the delay to 0, the view should already be initialized
  }
  initializeFormFields(): void {
    this.formFieldValues = {
      id: '',
      globalId: '00000000-0000-0000-0000-000000000000',
      customerInformationId: 0,
      title: '',
      streetAddress: '',
      suburb: '',
      state: '',
      postalCode: '',
      confidence: 0,
      projectedSalesDate: null,
      soldOn: null,
      estimatedRevenueFrom: '0.00',
      estimatedRevenueTo: '0.00',
      projectTypeSetupId: 0,
      notes: '',
      statusSetupId: 0,
      asNewLead: false,
      createLeadOpportunitySalesUserParameterRequests: [],
      createLeadOpportunitySourceParameterRequests: [],
      createLeadOpportunityTagsParameterRequests: []
    };


  }



  setInitialFormFieldValues(): void {
    this.initialFormFieldValues = JSON.parse(JSON.stringify(this.formFieldValues));
  }



  async fetchLeadData(leadOppId: number): Promise<void> {
    // this.leadOpportunityResponseId = this.TestingforResponse?.leadOpportunity;


    if (this.leadOpportunityResponseId) {

      this.isLoading = true;
      try {
        // Fetch lead data
        const leadResponse = await this.leadOppService.getLeadById(this.leadOpportunityResponseId).toPromise();
        this.leadOpportunityResponse = leadResponse.result;
        if (this.leadOpportunityResponse) {
          this.patchFormValues(this.leadOpportunityResponse);

          // Fetch activities and their count
          try {
            const activitiesResponse = await this.leadActivitiesService.getAllActivitiesByLeadId(this.leadOpportunityResponseId).toPromise();
            this.leadActivitiesResponse = activitiesResponse.result;
            if (this.leadActivitiesResponse) {
              if (this.leadActivityResponseId) {
                this.OnClickActivity(this.leadActivityResponseId);
              }
            }
            this.leadActivitiesService.getAllCountActivitiesByLeadId(this.leadOpportunityResponseId).subscribe(
              (response) => {
                this.leadActivityCount = response;
              },
              (error) => {
                console.error('Error fetching activity count:', error);
              }
            );

          } catch (error) {
            console.error('Error fetching lead activities:', error);
          }

          // Fetch proposals
          try {
            const proposalsResponse = await this.leadProposalsService.getAllProposalByLeadId(this.leadOpportunityResponseId).toPromise();
            this.leadProposalsResponse = proposalsResponse.result;

            this.leadProposalsService.getAllCountProposalsByLeadId(this.leadOpportunityResponseId).subscribe(
              (response) => {
                this.leadProposalCount = response;
              },
              (error) => {
                console.error('Error fetching activity count:', error);
              }
            );



          } catch (error) {
            console.error('Error fetching lead proposals:', error);
          }

          // Handle attachments
          this.fetchAttachments();
          this.createAndModifiedByData(this.leadOpportunityResponseId);

          if (this.flagDialogBoxForProposalTabActive > 0) {
            this.activeTab = this.flagDialogBoxForProposalTabActive;
            this.proposalConformCardShow = false;
            this.openConfirmDialogActivity(false, true, 'PROPOSAL');
          }

        }
      } catch (error) {
        console.error('Error fetching lead opportunity:', error);
      } finally {
        this.isLoading = false;
        this.storeInitialFormFieldValues();
        this.checkTitle(); // Call checkTitle to update the validation state
        this.cd.detectChanges();
      }
    }
  }


  testFile: AttachmentResponse[] = []
  fetchAttachments() {
    if (this.leadOpportunityResponse?.attachmentId) {
      this.leadOppService.getLeadByAttachmentId(this.leadOpportunityResponse.attachmentId).subscribe(
        (res) => {
          const attachmentParameters = res.result?.attachmentParameters;
          this.selectedFiless = attachmentParameters.map(att => ({
            uid: att.id?.toString() || '',
            name: att.name || '',
            status: 'done',
            url: this.IMG_BASE + att.filePath,
            thumbUrl: this.IMG_BASE + att.filePath,
            type: this.getMimeType(att.extension),
            originFileObj: new File([new Blob()], att.name || '', {
              type: this.getMimeType(att.extension),
              lastModified: new Date(att.modifiedOn).getTime()
            }),
            loading: false,
            error: false,
            addedBy: att.createdBy || 0,
            createdOn: new Date(att.createdOn),
            modifiedOn: new Date(att.modifiedOn),
            id: att.id || 0,
            globalId: att.globalId || ''
          }));
        },
        () => {
        }
      );
    } else {
      this.attachments = [];
    }
  }
  // } else if (fileType.includes('text')|| fileType.includes('json')) {
  getMimeType(extension: string): string {
    switch (extension) {
      case '.pdf':
        return 'application/pdf';
      case '.doc':
      case '.docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case '.json':
        return 'application/json';
      case '.txt':
        return 'text/plain';
      case '.xls':
      case '.xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case '.ppt':
      case '.pptx':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'mp4':
      case 'avi':
        return 'video/mp4';
      default:
        return 'application/octet-stream';
    }
  }


  // fetchAttachments() {
  //   if (this.leadOpportunityResponse?.attachmentId) {
  //     this.leadOppService.getLeadByAttachmentId(this.leadOpportunityResponse.attachmentId).subscribe(
  //       (res) => {
  //         this.selectedFiless = res.result?.attachmentParameters
  //         const attachmentParameters = res.result?.attachmentParameters || [];
  //         this.attachments = attachmentParameters.map(att => ({
  //           attachmentId: att.attachmentId,
  //           name: att.name,
  //           extension: att.extension,
  //           size: att.size,
  //           filePath: this.IMG_BASE + att.filePath,
  //           createdBy: att.createdBy,
  //           createdOn: att.createdOn,
  //           modifiedBy: att.modifiedBy,
  //           modifiedOn: att.modifiedOn,
  //           isActive: att.isActive,
  //           id: att.id,
  //           globalId: null
  //         }));
  //         Update attachment in leadOpportunityResponse if needed
  //         this.leadOpportunityResponse.attachment = {
  //           attachmentParameters,
  //           createdBy: this.leadOpportunityResponse.attachment?.createdBy,
  //           createdOn: this.leadOpportunityResponse.attachment?.createdOn,
  //           modifiedBy: this.leadOpportunityResponse.attachment?.modifiedBy,
  //           modifiedOn: this.leadOpportunityResponse.attachment?.modifiedOn,
  //           isActive: this.leadOpportunityResponse.attachment?.isActive,
  //           id: this.leadOpportunityResponse.attachment?.id,
  //           globalId: null,
  //         };
  //       },
  //       (error) => {
  //       }
  //     );
  //   } else {
  //     this.attachments = [];
  //   }
  // }

  patchFormValues(leadOpportunityResponse: any) {
    this.selectedCustomer = leadOpportunityResponse.customerInformation;
    const {
      id,
      globalId,
      confidence,
      customerInformationId,
      title,
      streetAddress,
      suburb,
      state,
      postalCode,
      projectedSalesDate,
      estimatedRevenueFrom,
      estimatedRevenueTo,
      notes,
      statusSetupId,
      projectTypeSetupId,
      leadOpportunitySalesUserParameters,
      leadOpportunitySourceParameters,
      leadOpportunityTagsParameters,
      soldOn } = leadOpportunityResponse;

    this.formFieldValues = {
      id: id,
      globalId: globalId,
      sliderValue: confidence || 0, // Ensure this is a number
      customerInformationId: customerInformationId,
      title: title,
      streetAddress: streetAddress,
      suburb: suburb,
      state: state,
      postalCode: postalCode,
      confidence: confidence, // Ensure this is a number
      projectedSalesDate: projectedSalesDate,
      estimatedRevenueFrom: estimatedRevenueFrom,
      estimatedRevenueTo: estimatedRevenueTo,
      projectTypeSetupId: projectTypeSetupId.toString(),
      statusSetupId: statusSetupId.toString(),
      notes: notes,
      soldOn: soldOn,
      applicationUserId: leadOpportunitySalesUserParameters
        ?.filter(item => item !== null)
        .map(item => item?.applicationUserId.toString()),
      tagSetupId: leadOpportunityTagsParameters
        ?.filter(item => item !== null)
        .map(item => item?.tagSetupId.toString()),
      sourceSetupId: leadOpportunitySourceParameters
        ?.filter(item => item !== null)
        .map(item => item?.sourceSetupId.toString())
    };

    // Ensure status list is loaded
    Promise.resolve().then(() => {
      if (statusSetupId) {
        const selectedStatus = this.status.find(status => status.value === statusSetupId.toString());

        if (selectedStatus) {
          this.isSold = selectedStatus.label === 'Sold';
          this.formFieldValues.soldOn = this.isSold ? soldOn : null;
          this.showReclassify = this.isSold;

          if (selectedStatus.label === 'Lost') {
            this.soldDateReadAble = soldOn;

          } else {
            this.soldDateReadAble = false;

          }
        } else {
          this.isSold = false;
          this.showReclassify = false;
          this.soldDateReadAble = false;
        }
      } else {
        this.isSold = false;
        this.showReclassify = false;
        this.soldDateReadAble = false;
      }

      this.cd.detectChanges(); // Update view with changes
      this.checkTitle(); // Update validation state
      this.setInitialFormFieldValues(); // Initialize form field values
    });


  }




  // --------------------------------Tag Methods OFfffff--------------------------------------------------------------------------
  LeadOpportunityNewLeadmodal = true;

  LeadOpportunityNewLeadModelCancel(): void {
    this.cancel.emit();
    this.LeadOpportunityNewLeadmodal = false;
    this.leadOppService.clearSelectedFiles();
  }

  handleCancelAddModal(): void {
    this.isVisibleAddModal = false;
  }

  handleOkAddModal(): void {
    this.isVisibleAddModal = false;
  }

  openchooseExistingModal(): void {
    this.isChooseVisible = true;
  }
  cancelchooseExistingModal(): void {
    this.isChooseVisible = false;
  }

  openFilterDrawer(): void {
    this.filterDrawerVisible = true;
  }

  openAddsModal(): void {
    this.isVisibleAddModal = true;
  }

  public showOvertimeContent: boolean = true;

  toggleDropdown5() {
    this.isOpen5 = !this.isOpen5;
  }
  toggleShowdoubleOvertime() {
    this.showOvertimeContent = !this.showOvertimeContent;
  }


  getTab(tab: number): string {
    switch (tab) {
      case 9:
        return 'General';
      case 10:
        return this.leadActivityCount && this.leadActivityCount > 0
          ? `Activities (${this.leadActivityCount})`
          : 'Activities';
      case 11:
        return this.leadProposalCount && this.leadProposalCount > 0
          ? `Proposals (${this.leadProposalCount})`
          : 'Proposals';
      default:
        return '';
    }
  }

  // updateSliderValue(value: number): void {
  //   this.sliderValue = value;
  // }


  showConfirm(): void {
    this.modal.confirm({
      nzTitle: 'Do you want to schedule a new activity?',
      nzContent: 'This action will schedule a new activity.',
      nzOnOk: () => {
        // You can add your logic here for when the user clicks OK
      },
      nzWrapClassName: 'custom-modal-style', // Apply a custom class to the modal
    });
  }
  statuscode: CustomerStatusCode
  showContactlModal(flag: boolean) {
    this.leadOppService.setAgainRowHide(true); // Pass true to keep it false

    const modalRef: NzModalRef = this.modal.create({
      nzContent: NewContactComponent,
      nzFooter: null,
      nzData: {
        statusdata: CustomerStatusCode.INACTIVE,
        flag
      }
    });
    modalRef.componentInstance.saveAndNewButtonHide = flag;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.selectedCustomer.subscribe((data: CustomerInformationResponse) => {
      this.selectedCustomer = data;
      this.handleSelectedCustomerChange();
    });
  }

  easementCustomFieldFormModalOpen() {
    const modalRef = this.modal.create({
      nzContent: CustomFieldFormComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      // this.getNewProposalData = null;
      // this.getInternalUsersData();
    });
  }
  cornerLotCustomFieldFormModalOpen() {
    const modalRef = this.modal.create({
      nzContent: CustomFieldFormComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      // this.getNewProposalData = null;
      // this.getInternalUsersData();
    });
  }

  openConfirmDialogActivity(markComplete: boolean, isProposal: boolean, type: string): void {
    if (this.leadOpportunityResponse) {
      if (type === 'ACTIVITY') {
        const modalRef = this.modal.create({
          nzContent: LeadActivityFormComponent,
          nzData: {
            leadData: this.leadOpportunityResponse,
            isMark: markComplete,
          },
          nzFooter: null,
        });
        modalRef.componentInstance.LeadOpportunityResponse = this.leadOpportunityResponse;
        modalRef.componentInstance.cancel.subscribe(() => {
          modalRef.destroy();
        });

        modalRef.componentInstance.leadActivityOnSavedCompleted.subscribe(async () => {
          try {
            this.isLoading = true;
            const activitiesResponse = await this.leadActivitiesService.getAllActivitiesByLeadId(this.formFieldValues.id).toPromise();
            this.leadActivitiesResponse = activitiesResponse.result;

            this.leadActivitiesService.getAllCountActivitiesByLeadId(this.formFieldValues.id).subscribe(
              (response) => {
                this.leadActivityCount = response;
                this.fetchAttachments();

              },

              (error) => {
                console.error('Error fetching activity count:', error);
              }

            );
          } catch (error) {
            console.error('Error fetching activities:', error);
          }
          this.isLoading = false;
        });

        modalRef.componentInstance.leadActivityDeleted.subscribe(async () => {
          try {
            this.isLoading = true;

            const activitiesResponse = await this.leadActivitiesService.getAllActivitiesByLeadId(this.formFieldValues.id).toPromise();
            this.leadActivitiesResponse = activitiesResponse.result;

            this.leadActivitiesService.getAllCountActivitiesByLeadId(this.formFieldValues.id).subscribe(
              (response) => {
                this.leadActivityCount = response;
                this.fetchAttachments();

              },
              (error) => {
                console.error('Error fetching activity count:', error);
              }
            );
          } catch (error) {
            console.error('Error fetching activities:', error);
          }
          this.isLoading = false;

        });


      } else if (type === 'PROPOSAL') { 
        const modalRef = this.modal.create({
          nzContent: NewProposalComponent,
          nzFooter: null,
        });
        modalRef.componentInstance.cancel.subscribe(() => {
          modalRef.destroy();
        });


        if (this.gridPatchedValue === true) {
          modalRef.componentInstance.leadOpportunityProposalSaveResponse = this.leadOpportunityResponse;
          modalRef.componentInstance.getProposalTemplatesDataId = this.leadProposalId;
          modalRef.componentInstance.saveProposalInLeadOpportunity = false;
        } else {
          modalRef.componentInstance.leadOpportunityProposalSaveResponse = this.leadOpportunityResponse;
          modalRef.componentInstance.leadProposalsResponse = this.leadProposalsResponse;
          modalRef.componentInstance.saveProposalInLeadOpportunity = true;
        }

        modalRef.componentInstance.leadProposalSaveResponse.subscribe(async (updatedData: LeadProposalResponse) => {
          try {
            this.isLoading = true;

            const proposalResponse = await this.leadProposalsService.getAllProposalByLeadId(this.formFieldValues.id).toPromise();
            this.leadProposalsResponse = proposalResponse.result;

            this.leadProposalsService.getAllCountProposalsByLeadId(this.formFieldValues.id).subscribe(
              (response) => {
                this.leadProposalCount = response;
              },

              (error) => {
                console.error('Error fetching Proposal count:', error);
              }
            );
          } catch (error) {
            console.error('Error fetching Proposals:', error);
          }
          this.isLoading = false;

          modalRef.componentInstance.leadProposalDeleted.subscribe(async () => {
            try {
              this.isLoading = true;

              const proposalResponse = await this.leadProposalsService.getAllProposalByLeadId(this.formFieldValues.id).toPromise();
              this.leadProposalsResponse = proposalResponse.result;

              this.leadProposalsService.getAllCountProposalsByLeadId(this.formFieldValues.id).subscribe(
                (response) => {
                  this.leadProposalCount = response;
                },
                (error) => {
                  console.error('Error fetching Proposal count:', error);
                }
              );
            } catch (error) {
              console.error('Error fetching Proposals:', error);
            }
            this.isLoading = false;

          });


        });

      }
    } else {
      if (!this.leadOpportunityResponse) {
        if (type === 'ACTIVITY') {
          this.conformCardShow = true;
        }
        else {
          this.proposalConformCardShow = true;
        }
      }
    }
  }




  conformhandleCancel(): void {
    this.conformCardShow = false;
    this.proposalConformCardShow = false;
  }




  initLoad(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(
        (res) => {
          this.lookupSetNameResponse = res.result;

          // -----------Multi Select ---------------
          let tagValues = res.result.filter(
            (x) => x.code === this.preDefinedDataCodes.tags.code
          );
          let sourceValues = res.result.filter(
            (x) => x.code === this.preDefinedDataCodes.source.code
          );

          // -----------single Select ---------------
          let statusValues = res.result.filter(
            (x) => x.code === this.preDefinedDataCodes.status.code
          );
          let projectTypeValues = res.result.filter(
            (x) => x.code === this.preDefinedDataCodes.projectType.code
          );

          this.tagNodes = [
            {
              title: 'Check All',
              value: 'check_all',
              key: 'check_all',
              selectable: false,
              isLeaf: false,
              expanded: true,
              children: tagValues.map((status) => ({
                title: status.name,
                value: status.id.toString(),
                key: status.id.toString(),
                isLeaf: true
              }))
            }
          ];

          this.sourceNodes = [
            {
              title: 'Check All',
              value: 'check_all',
              key: 'check_all',
              selectable: false,
              isLeaf: false,
              expanded: true,
              children: sourceValues.map((source) => ({
                title: source.name,
                value: source.id.toString(),
                key: source.id.toString(),
                isLeaf: true
              }))
            }
          ];

          this.status = statusValues.map((LOP) => ({
            label: LOP.description,
            value: LOP.id.toString(),
          }));

          if (this.status.length > 0) {
            this.formFieldValues.statusSetupId = this.status[0].value;
          }

          this.projectType = projectTypeValues.map((LOP) => ({
            label: LOP.name,
            value: LOP.id.toString(),
          }));

          // Set initial values after dropdowns are loaded
          this.setInitialFormFieldValues();
          resolve();
        },
        (error) => {
          console.error('Error loading lookup values:', error);
          reject(error);
        }
      );
    });
  }


  onTagChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('check_all');
    if (checkAllIndex !== -1) {
      const allValues = this.tagNodes[0].children.map(child => child['value']);
      this.formFieldValues['tagSetupId'] = [...allValues];
    } else {
      const allValues = this.tagNodes[0].children.map(child => child['value']);
      this.formFieldValues['tagSetupId'] = selectedValues.filter(value => allValues.includes(value));
    }

  }


  onSourceChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('check_all');
    if (checkAllIndex !== -1) {
      const allValues = this.sourceNodes[0].children.map(child => child['value']);
      this.formFieldValues['sourceSetupId'] = [...allValues];
    } else {
      const allValues = this.sourceNodes[0].children.map(child => child['value']);
      this.formFieldValues['sourceSetupId'] = selectedValues.filter(value => allValues.includes(value));

    }
  }

  // Status Field Options sold selected Condition

  isSold = false;
  onStatusChange(selectedStatusId: string): void {
    this.selectedStatus = this.status.find(status => status.value === selectedStatusId);
    this.isSold = this.selectedStatus ? this.selectedStatus.label === 'Sold' : false;
    if (this.isSold) {
      this.formFieldValues.soldOn = new Date();
      this.soldDateReadAble = false;
    } else {
      this.formFieldValues.soldOn = null;
      this.showReclassify = false;

    }
    // Check if soldDateReadAble was true before making any changes
    if (this.leadOpportunityResponse.soldOn) {
      // Check if the selected status is "Lost"
      const lostStatus = this.status.find(option => option.label === 'Lost');
      if (this.selectedStatus && this.selectedStatus.value === lostStatus.value) {
        this.soldDateReadAble = true;
        this.formFieldValues.soldOn = this.leadOpportunityResponse?.soldOn;
      } else {
        this.soldDateReadAble = false;
      }
    }

  }

  soldDateReadAble: boolean = false;
  classifyAsLostOptionWorking() {
    const lostStatus = this.status.find(option => option.label === 'Lost');
    if (lostStatus) {
      this.formFieldValues.statusSetupId = lostStatus.value;
      this.isSold = false;
      this.showReclassify = false;
      this.soldDateReadAble = true;
    }
    this.saveButton('save').then(() => {
    }).catch(error => {
      console.error('Error during save:', error);
    });
  }



  reOpenAsNewLead() {
    const currentStatus = this.status.find(option => option.value === this.formFieldValues.statusSetupId);
    if (currentStatus && currentStatus.label === 'Sold') {
      this.isSold = false;
      this.showReclassify = false;
      this.soldDateReadAble = false;
      this.formFieldValues.asNewLead = true; // Set to true
      this.initLoad();
      this.cd.detectChanges();
    }

    this.saveButton('save', true).then(() => {
      // asNewLead is already reset to false in saveButton method
    }).catch(error => {
      console.error('Error during save:', error);
    });
  }

  reOpenAndUndoSale() {
    const currentStatus = this.status.find(option => option.value === this.formFieldValues.statusSetupId);
    if (currentStatus && currentStatus.label === 'Sold') {
      this.isSold = false;
      this.showReclassify = false;
      this.soldDateReadAble = false;

      // Set status to "Open"
      const openStatus = this.status.find(option => option.label === 'Open');
      if (openStatus) {
        this.formFieldValues.statusSetupId = openStatus.value;
      }

      this.initLoad();
      this.cd.detectChanges();
    }

    this.saveButton('save').then(() => {
      // Additional logic after save if needed
    }).catch(error => {
      console.error('Error during save:', error);
    });
  }





  // ............  Patched Value Edit Mode Lead Opp.................  //

  updateSliderValue(newValue: number) {
    this.sliderValue = newValue;
  }

  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      const salesPer = res.result;

      this.salespersonMulti = [
        {
          title: 'Check All',
          value: 'check_all',
          key: 'check_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: salesPer.map((user) => ({
            title: user.fullName,
            fullName: user.fullName,
            firstName: this.getFirstName(user.fullName),
            lastName: this.getLastName(user.fullName),
            value: user.id.toString(),
            key: user.id.toString(),
            isLeaf: true,
          }))
        }
      ];

    });
  }


  getFirstName(fullName: string): string {
    const nameParts = fullName.trim().split(' ');
    return nameParts[0] || '';
  }

  getLastName(fullName: string): string {
    const nameParts = fullName.trim().split(' ');
    return nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';
  }

  getInitials(user: any): string {
    if (!user) {
      return '';
    }

    const firstNameInitial = user.firstName ? user.firstName.charAt(0).toUpperCase() : '';
    const lastNameInitial = user.lastName ? user.lastName.charAt(0).toUpperCase() : '';

    return `${firstNameInitial}${lastNameInitial}`;
  }


  onUserChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('check_all');
    if (checkAllIndex !== -1) {
      const allValues = this.salespersonMulti[0].children.map(child => child['value']);
      this.formFieldValues['applicationUserId'] = [...allValues];
    } else {
      const allValues = this.salespersonMulti[0].children.map(child => child['value']);
      this.formFieldValues['applicationUserId'] = selectedValues.filter(value => allValues.includes(value));
    }

  }


  openStandardFilterComponent() {
    const modalRef = this.modal.create({
      nzContent: ContactListComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  handleSelectedCustomerChange() {
    if (this.selectedCustomer && typeof this.selectedCustomer.id !== 'undefined') {
      this.formFieldValues.selectedCustomer = this.selectedCustomer;
      this.formFieldValues.customerInformationId = this.selectedCustomer.id.toString();
    } else {
      this.formFieldValues.selectedCustomer = null;
      this.formFieldValues.customerInformationId = null;
    }
  }

  chooseExistingContactCustomerOpen() {
    const modalRef = this.modal.create({
      nzContent: ContactListComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.selectedCustomer.subscribe((data) => {
      this.selectedCustomer = data;
      this.handleSelectedCustomerChange();
    });

  }

  ///////// Saim Start work // 3/13/2024 // Saim-contact-list-new-work //////

  copyFromContact() {
    this.formFieldValues.streetAddress = this.selectedCustomer.streetAddress
    this.formFieldValues.suburb = this.selectedCustomer.suburb
    this.formFieldValues.state = this.selectedCustomer.state
    this.formFieldValues.postalCode = this.selectedCustomer.postalCode
  }

  ///////// Saim End work // 3/13/2024 // Saim-contact-list-new-work //////


  onRemoveClick() {
    this.selectedCustomer = null;
    this.handleSelectedCustomerChange();
  }

  // Noor Working Add SelectedCustomerId In Save Method//



  // ------------------------------------------- sami & noor work ------------------------------//////

  //  Add By MEHMOOD
  isSaveAndClose: boolean = false;
  isSaveAndNew: boolean = false;
  isSubmitted: boolean = false;
  DisabledNextActivity: boolean = false;
  handleFileInput(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const customFile: CustomUploadFile = {
        uid: file.name,
        name: file.name,
        size: file.size,
        type: file.type,
        thumbUrl: URL.createObjectURL(file),
        loading: false,
        addedBy: 1,
        createdOn: new Date(),
        modifiedOn: new Date(),
        id: i + 1,
        globalId: this.generateGlobalId(),
        originFileObj: file // yeh line ensure karti hai ke `originFileObj` property add ho
      };
      this.selectedFiless.push(customFile);
    }
    const filesToUpload = this.selectedFiless.filter(f => f.originFileObj);
  }
  // Function to generate a global ID (for demonstration purposes)
  generateGlobalId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  testing: any = {};

  saveButton(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save', setAsNewLead: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
        return reject('Already processing');
      }

      // Set appropriate flags based on the actionType
      if (actionType === 'saveAndNew') {
        this.isSaveAndNew = true;
      } else if (actionType === 'saveAndClose') {
        this.isSaveAndClose = true;
      } else {
        this.isSave = true;
      }

      this.DisabledNextActivity = true; // Disable next activity

      if (setAsNewLead) {
        this.formFieldValues.asNewLead = true;
      }

      this.isSubmitted = true;
      this.checkErrors(true); // Validate all fields before saving

      // Handle validation errors
      if (Object.values(this.validationStates).includes('error')) {
        this.activeTab = 9; // Assuming you have an `activeTab` property to control the active tab
        setTimeout(() => {
          const errorElements = document.querySelectorAll('.top-of-modal');
          if (errorElements.length > 0) {
            const lastErrorElement = errorElements[errorElements.length - 1];
            lastErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
          this.activeTab = null;
        }, 100);

        this.resetFlags();
        this.DisabledNextActivity = false; // Re-enable next activity

        if (setAsNewLead) {
          this.formFieldValues.asNewLead = false;
        }

        return reject('Validation errors present');
      }

      // Prepare data for saving
      let data = this.formFieldValues;
      let salesUsers = this.formFieldValues.applicationUserId?.length > 0
        ? this.formFieldValues.applicationUserId.map((id: string) => ({ applicationUserId: id }))
        : [];
      let sources = this.formFieldValues.sourceSetupId?.length > 0
        ? this.formFieldValues.sourceSetupId.map((id: string) => ({ sourceSetupId: id }))
        : [];
      let tags = this.formFieldValues.tagSetupId?.length > 0
        ? this.formFieldValues.tagSetupId.map((id: string) => ({ tagSetupId: id }))
        : [];

      this.formFieldValues.createLeadOpportunitySalesUserParameterRequests = salesUsers;
      this.formFieldValues.createLeadOpportunitySourceParameterRequests = sources;
      this.formFieldValues.createLeadOpportunityTagsParameterRequests = tags;
      this.handleSelectedCustomerChange();

      this.leadOppService.postLeadData(data).subscribe(
        (response) => {
          this.leadOpportunitySaveResponse = response.result;
          this.leadOpportunityResponse = response.result;
          this.formFieldValues.globalId = response.result.globalId;
          this.formFieldValues.id = response.result.id;
          this.isSubmitted = false;

          // Check status after save
          const savedStatus = this.status.find(status => status.value === this.formFieldValues.statusSetupId);
          this.showReclassify = savedStatus?.label === 'Sold';

          // this.fetchAttachments();
          if (this.selectedFiless?.length > 0) {
            const filesToUpload = this.selectedFiless.filter(i => i.originFileObj);
            const documentFiles = filesToUpload.map(f => f.originFileObj);
            if (this.selectedFiless.length > 0) {
              const attachmentRequest: CreateAttachmentForDocumentRequest = {
                headerId: response.result.id,
                companyParameterId: 1,
                formNameId: 36,
                documentFiles: documentFiles
              };
              this.leadOppService.CreateAttachmentForDocument(attachmentRequest).subscribe(
                (attachmentResponse) => {
                  this.saveFileIdToDatabase(attachmentResponse.fileId);
                },
                (attachmentError) => {
                  console.error('Error creating attachment:', attachmentError);
                }
              );
            } else {
              console.warn('No files to upload.');
            }
          }


          if (response) {
            this.createAndModifiedByData(response.result.id);
          }

          this.toastService.success('Saved Successfully');
          this.leadOpportunityOnCompleted.emit();
          this.DisabledNextActivity = false;


          this.storeInitialFormFieldValues();

          resolve();
        },
        (error) => {
          console.error('Error saving data:', error);
          this.toastService.error('An error has occurred. Please try again.');
          this.DisabledNextActivity = false; // Re-enable next activity
          reject(error);
        }
      ).add(() => {
        this.resetFlags();
        this.conformCardShow = false;
        this.proposalConformCardShow = false;
        this.modalErrorMessages = []; // Reset modal error messages specifically
        this.isSubmitted = false;
        if (setAsNewLead) {
          this.formFieldValues.asNewLead = false;
        }
      });
    });
  }




  saveAndCloseLead(): Promise<void> {
    return this.saveButton('saveAndClose').then(() => {
      this.resetForm();
      this.LeadOpportunityNewLeadModelCancel();
    }).catch((error) => {
      console.error('Error during save and close:', error);
    });
  }

  saveAndNewLead() {
    this.saveButton('saveAndNew').then(() => {
      this.resetForm();
    }).catch((error) => {
      console.error('Error during save and new:', error);
    }).finally(() => {
    });
  }

  resetFlags() {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
  }


  resetForm(): void {
    this.initializeFormFields();
    this.setInitialFormFieldValues();
    this.validationStates = {};
    this.validationMessages = {};
    setTimeout(() => {
      const errorElements = document.querySelectorAll('.ant-input-status-error');
      errorElements.forEach((element: HTMLElement) => {
        element.classList.remove('ant-input-status-error');
      });
    }, 0);

    // Clear other related states
    this.leadOpportunitySaveResponse = null;
    this.leadOpportunityResponse = null;
    this.leadOppService.clearSelectedFiles();
    this.leadActivitiesResponse = null;
    this.leadProposalsResponse = null;
    this.leadActivityCount = null;
    this.leadProposalCount = null;
    this.selectedCustomer = null;
    this.modalErrorMessages = [];
    this.isSubmitted = false;
  }


  createAndModifiedByData(id: number) {
    const payload: FilterFooterAudit = {
      id: id,
      formCode: 'LOLV'
    }
    this.FooterService.createdAndModifyUser(payload).subscribe(res => {
      this.footerResponse = res.result;

    })
  }


  async storeInitialFormFieldValues(): Promise<void> {
    await new Promise(resolve => setTimeout(() => {
      this.initialFormFieldValues = JSON.parse(JSON.stringify(this.formFieldValues));
      resolve(true);
    }, 0));
  }

  isFormDirty(): boolean {
    return JSON.stringify(this.formFieldValues) !== JSON.stringify(this.initialFormFieldValues);
  }

  showConfirmCardLead(): void {
    const dontSaveText = 'Dont Save'; // Constant to store the 'Don't Save' button text

    if (this.isFormDirty()) {
      this.confirmModal = this.modal.confirm({
        nzTitle: '<b>Save Changes?</b>',
        nzContent: '<p>This Lead Opportunity has unsaved changes. Do you want to save your changes before closing?</p>',
        nzOkText: 'Save',
        nzOkType: 'primary',
        nzCancelText: dontSaveText,
        nzOkLoading: this.isSaveAndClose,
        nzClosable: true, // Modal can be closed with the close button
        nzOnOk: () => {
          this.resetFlags();
          this.isSaveAndClose = true;
          this.confirmModal.destroy();
          return this.saveAndCloseLead()
            .then(() => {
              this.isSaveAndClose = false;
              this.saveAndCloseLead();
            })
            .catch((error) => {
              this.isSaveAndClose = false;
              console.error('Error saving lead:', error);
              // Optionally show an error message to the user
            });
        },
        nzOnCancel: () => {
          const clickedButton = (event.target as HTMLElement).textContent?.trim();
          if (clickedButton === dontSaveText) {
            // Close both the confirm card and the modal
            this.confirmModal.destroy();
            this.resetForm();
            this.LeadOpportunityNewLeadModelCancel();
          }
          else {
            this.confirmModal.destroy();
          }
        },

        nzStyle: { top: '250px' },
        nzClassName: 'custom-modal-content',
      });
    } else {
      this.resetForm();
      this.LeadOpportunityNewLeadModelCancel();
    }
  }





  deleteOpportunity(globalId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!globalId) {
        this.toastService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }

      this.leadOppService.deleteLeadOpportunity(globalId).subscribe(
        () => {
          this.isDeleteLoading = false;
          // if (res.result) {
          this.toastService.success(`${this.leadOpportunityResponse.title} Deleted!`);
          this.leadOpportunityOnCompleted.emit();
          resolve();
          // } else {
          // this.toastService.error('Failed to delete the record. Please try again.');
          reject();
          // }
        },
        () => {
          this.isDeleteLoading = false;
          this.toastService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      );
    });
  }

  deleteLeadConfirm(): void {
    if (this.isDeleteLoading) {
      return;
    }

    this.modal.confirm({
      nzTitle: `Delete Lead Opportunity?`,
      nzContent: 'Are you sure you want to permanently delete this lead and all items on it?',
      nzOkText: `Delete Lead Opportunity`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteOpportunity(this.formFieldValues.globalId)
          .then(() => {
            this.LeadOpportunityNewLeadModelCancel();
          })
          .catch(() => {

          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }



  saveFileIdToDatabase(fileId: string) {
  }

  // --------------------------------------------------sami Brach Name Sami/Features-Activity-Work-------------------------
  checkTitle() {
    this.opportunityTitleValid = this.formFieldValues['title']?.trim() !== '';
    // this.checkErrors();
  }


  showNewCustomerStandard() {
    const modalRef = this.modal.create({
      nzContent: NewContactComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.onSaveComplete.subscribe(() => {
      this.customerGridTableData(1, this.PAGE_ID, this.defaultFilterId_selected, this.currentPage, this.pageSize);
    });
  }
  customerGridTableData(arg0: number, PAGE_ID: any, defaultFilterId_selected: any, currentPage: any, pageSize: any) {
    throw new Error('Method not implemented.');
  }
  PAGE_ID(arg0: number, PAGE_ID: any, defaultFilterId_selected: any, currentPage: any, pageSize: any) {
    throw new Error('Method not implemented.');
  }
  defaultFilterId_selected(arg0: number, PAGE_ID: any, defaultFilterId_selected: any, currentPage: any, pageSize: any) {
    throw new Error('Method not implemented.');
  }
  currentPage(arg0: number, PAGE_ID: any, defaultFilterId_selected: any, currentPage: any, pageSize: any) {
    throw new Error('Method not implemented.');
  }
  pageSize(arg0: number, PAGE_ID: any, defaultFilterId_selected: any, currentPage: any, pageSize: any) {
    throw new Error('Method not implemented.');
  }

  // --------------------------Saim Work Start----3/16/2024---Saim-new-proposal-data-bind----------------
  leadOpportunityEditCustomer(Id: number, flag: boolean): void {
    const modalRef = this.modal.create({
      nzContent: NewContactComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.CustomerResponseId = Id;
    modalRef.componentInstance.saveAndNewButtonHide = flag;
    // modalRef.componentInstance.CustomerResponse = data;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.selectedCustomer.subscribe((data: CustomerInformationResponse) => {
      this.selectedCustomer = data;

    });

  }
  // ------------------------Saim Work End----3/16/2024---Saim-new-proposal-data-binding--work--------------
  // sufyan work

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }

  }


  selectedData: LeadActivityResponse[] = [];
  selectedGlobalIds: string[] = [];



  onAllChecked(isChecked: boolean, data: LeadActivityResponse[]): void {
    this.isCheckboxSelected = isChecked;

    if (isChecked) {
      // Select all items
      this.selectedData = [...data];
      this.selectedGlobalIds = data.map(item => item.globalId);
      this.setOfCheckedId = new Set(data.map(item => item.id));
    } else {
      // Deselect all items
      this.selectedData = [];
      this.selectedGlobalIds = [];
      this.setOfCheckedId.clear();
    }

    this.refreshCheckedStatus();

    console.log('LeadActivityResponse:', this.selectedData);
    console.log('Selected Global IDs:', this.selectedGlobalIds);
  }


  onItemChecked(id: number, checked: boolean, data: LeadActivityResponse): void {
    this.updateCheckedSet(id, checked);
    this.isCheckboxSelected = this.setOfCheckedId.size > 0;

    if (checked) {
      // Add the checked item to selectedData and selectedGlobalIds
      this.selectedData.push(data);
      this.selectedGlobalIds.push(data.globalId);
    } else {
      // Remove the unchecked item from selectedData and selectedGlobalIds
      this.selectedData = this.selectedData.filter(item => item.id !== id);
      this.selectedGlobalIds = this.selectedGlobalIds.filter(globalId => globalId !== data.globalId);
    }

    this.refreshCheckedStatus();

    console.log('Selected Lead Activities:', this.selectedData);
    console.log('Selected Global IDs:', this.selectedGlobalIds);
  }



  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }



  onCurrentPageDataChange($event: readonly any[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }
  cancelSelection(): void {
    this.setOfCheckedId.clear();
    this.isCheckboxSelected = false;
    this.refreshCheckedStatus();
  }

  addressSortFn: NzTableSortFn = (a: { address: string }, b: { address: string }, sortOrder: NzTableSortOrder) => {
    // Implement your custom sorting logic here
    if (a.address < b.address) {
      return sortOrder === 'ascend' ? -1 : 1;
    } else if (a.address > b.address) {
      return sortOrder === 'ascend' ? 1 : -1;
    } else {
      return 0;
    }
  };


  OnClickProposal(Id: number) {
    const modalRef = this.modal.create({
      nzContent: NewProposalComponent,
      nzFooter: null
    });
    modalRef.componentInstance.getProposalTemplatesDataId = Id;
    // modalRef.componentInstance.getProposalTemplatesData = data;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.leadProposalSaveResponse.subscribe(async (updatedData: LeadProposalResponse) => {
      try {
        this.isLoading = true;

        const existingActivity = this.leadProposalsResponse.find(a => a.id === updatedData.id);
        if (existingActivity) {
          Object.assign(existingActivity, updatedData);
        } else {
          this.leadProposalsResponse.push(updatedData);
        }
        const activitiesResponse = await this.leadProposalsService.getAllProposalByLeadId(this.formFieldValues.id).toPromise();
        this.leadProposalsResponse = activitiesResponse.result;

        this.leadProposalsService.getAllCountProposalsByLeadId(this.formFieldValues.id).subscribe(
          (response) => {
            this.leadProposalCount = response;
          },
          (error) => {
            console.error('Error fetching activity count:', error);
          }
        );
      } catch (error) {
        console.error('Error updating activities:', error);
      }
      this.isLoading = false;

    });

    modalRef.componentInstance.leadProposalSaveResponse.subscribe(async () => {
      try {
        this.isLoading = true;

        const activitiesResponse = await this.leadProposalsService.getAllProposalByLeadId(this.formFieldValues.id).toPromise();
        this.leadProposalsResponse = activitiesResponse.result;

        this.leadProposalsService.getAllCountProposalsByLeadId(this.formFieldValues.id).subscribe(
          (response) => {
            this.leadProposalCount = response;
          },
          (error) => {
            console.error('Error fetching Proposal count:', error);
          }
        );
      } catch (error) {
        console.error('Error updating Proposals after deletion:', error);
      }
    });


  }

  async OnClickActivity(Id: number) {
    const modalRef = this.modal.create({
      nzContent: LeadActivityFormComponent,
      nzData: {
        leadData: this.leadOpportunityResponse
      },
      nzFooter: null,
    });

    modalRef.componentInstance.activityResponseId = Id;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.leadActivityOnSavedCompleted.subscribe(async (updatedData: LeadActivityResponse) => {
      try {
        this.isLoading = true;


        // Log the status code
        const existingActivity = this.leadActivitiesResponse.find(a => a.id === updatedData.id);
        if (existingActivity) {
          Object.assign(existingActivity, updatedData);
        } else {
          this.leadActivitiesResponse.push(updatedData);
        }
        const activitiesResponse = await this.leadActivitiesService.getAllActivitiesByLeadId(this.formFieldValues.id).toPromise();
        this.leadActivitiesResponse = activitiesResponse.result;

        this.leadActivitiesService.getAllCountActivitiesByLeadId(this.formFieldValues.id).subscribe(
          (response) => {
            this.leadActivityCount = response;
          },
          (error) => {
            console.error('Error fetching activity count:', error);
          }
        );
      } catch (error) {
        console.error('Error updating activities:', error);
      }
      this.isLoading = false;
    });

    modalRef.componentInstance.leadActivityDeleted.subscribe(async () => {
      try {
        this.isLoading = true;
        const activitiesResponse = await this.leadActivitiesService.getAllActivitiesByLeadId(this.formFieldValues.id).toPromise();
        this.leadActivitiesResponse = activitiesResponse.result;

        this.leadActivitiesService.getAllCountActivitiesByLeadId(this.formFieldValues.id).subscribe(
          (response) => {
            this.leadActivityCount = response;
          },
          (error) => {
            console.error('Error fetching activity count:', error);
          }
        );
      } catch (error) {
        console.error('Error updating activities after deletion:', error);
      }
      this.isLoading = false;

    });



  }





  //-----------  Grid Data Bind End ------  ///


  //.............. Edit Modal with Lead To Job Form Open Start  (created by): Mehmood (Date): 11/03/2024 (Branch Name:) Mehmood-Lead-Opportunities-Edit  ............//

  leadToJob(select: string): void {
    const modalRef = this.modal.create({
      nzContent: LeadToJobComponent,
      nzData: {
        type: select,
        formData: this.leadOpportunityResponse,
      },
      nzFooter: null,
    });

    // Pass the this.leadOpportunityResponse to the modal component
    modalRef.componentInstance.leadOpportunityResponse = this.leadOpportunityResponse;

    // Subscribe to the cancel event
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  //.............. Edit Modal with Lead To Job Form End  Mehmood 11/03/2024   ............//


  // _________________________Abdul Rehman  Start__________________________
  testingHide: CustomUploadFile
  uploadFiles(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.UploadFile.subscribe((data: CustomUploadFile[]) => {
      data.filter(file => this.isImageFile(file));
      this.selectedFiless = [...data, ...this.selectedFiless];
      this.onResize();
    });
  }
  isImageFile(file: CustomUploadFile): boolean {
    const fileName = file.name.toLowerCase();
    const fileType = file.type;

    return (
      fileType === 'image/png' ||
      fileType === 'image/jpeg' ||
      fileName.endsWith('.png') ||
      fileName.endsWith('.jpeg')
    );
  }
  // this.testingHide = this.selectedFiless.find(f => f.name.includes('png') || f.name.includes('jpeg'));
  // console.log('testingHide',this.testingHide);

  onResize() {
    this.updateNavButtonsVisibility();
  }

  private updateNavButtonsVisibility() {
    requestAnimationFrame(() => {
      const container = this.carouselContainer.nativeElement;
      if (this.selectedFiless.length > 0) {
        this.showNavButtons = container.scrollWidth > container.clientWidth;
      } else {
        this.showNavButtons = false;
      }
      this.cd.detectChanges();
    });
  }

  previous() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.scrollToCurrentIndex();
    }
  }

  next() {
    const container = this.carouselContainer.nativeElement;
    const maxIndex = this.selectedFiless.length - Math.floor(container.clientWidth / 130);
    if (this.currentIndex < maxIndex) {
      this.currentIndex++;
      this.scrollToCurrentIndex();
    }
  }

  scrollToCurrentIndex() {
    const container = this.carouselContainer.nativeElement;
    const scrollAmount = this.currentIndex * 130; // 120px (width) + 10px (margin)
    container.scrollTo({ left: scrollAmount, behavior: 'smooth' });
  }

  getFileIcon(file: CustomUploadFile): string | null {
    const fileType = file.type;
    if (fileType.includes('pdf')) {
      return 'assets/Attachment/documentPDF.svg';
    } else if (fileType.includes('excel') || fileType.includes('spreadsheet')) {
      return 'assets/Attachment/documentXLS.svg';
    } else if (fileType.includes('word')) {
      return 'assets/Attachment/documentDOC.svg';
    } else if (fileType.includes('zip')) {
      return 'assets/Attachment/documentZIP.svg';
    } else if (fileType.includes('text') || fileType.includes('json')) {
      return 'assets/Attachment/documentGeneric.svg';
    } else if (fileType.includes('ppt') || fileType.includes('presentation')) {
      return 'assets/Attachment/documentPPT.svg';
    } else if (fileType.includes('video')) {
      return null;
    } else if (fileType.includes('image')) {
      return null;
    } else {
      return null;
    }
  }
  onClick(): void {
    let filteredImages = this.selectedFiless.filter(f =>
      f.name.includes('png') || f.name.includes('jpeg')
    );
    const images = filteredImages.map(file => ({
      src: file.thumbUrl,
      alt: file.name
    }));
    this.nzImageService.preview(images, {
      nzZoom: 1.0,
      nzRotate: 0
    });
  }
  // let filteredImages = this.selectedFiless.filter(f => f.type.includes('image'));
  // const images = filteredImages.map(file => ({
  //   src: file.thumbUrl,
  //   alt: file.name
  // }));
  // this.openPreview(images, index);
  // onClick(fileq: CustomUploadFile): void {
  //   let filteredImages = this.selectedFiless.filter(f => f.type.includes('image'));

  //   const imageUrls: NzImage[] = filteredImages.map(file => ({
  //     src: file.thumbUrl,
  //     alt: file.name
  //   }));

  //   const initialIndex = imageUrls.findIndex(image => image.src === fileq.thumbUrl);
  //   this.nzImageService.preview(imageUrls, {
  //     nzZoom: 1.0,
  //     nzRotate: 0,
  //     nzInitialIndex: initialIndex,
  //   });
  //   console.log('after call services:', imageUrls);
  // }

  // onClick(fileq: CustomUploadFile): void {
  //   const images1 = [{
  //     src: fileq.thumbUrl,
  //     alt: fileq.name
  //   }];
  //   const images = this.selectedFiless.map(file => ({
  //     src: file.thumbUrl,
  //     alt: file.name
  //   }));
  //     this.nzImageService.preview(images1,  {
  //       nzZoom: 1.0,
  //       nzRotate: 0
  //     });
  //     this.nzImageService.preview(,  {
  //       nzZoom: 1.0,
  //       nzRotate: 0
  //     });
  //   }




  playVideo(file: CustomUploadFile): void {
    const videoElement: HTMLVideoElement = this.carouselContainer.nativeElement.querySelector(`#video-${file.id}`);
    if (videoElement) {
      videoElement.style.display = 'block'; // Show the video player
      videoElement.play();
    }
  }
  CreateNewFile(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  viewAll(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.selectedFilessResponse = this.selectedFiless;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.removeFile.subscribe((file: CustomUploadFile) => {
      this.removeFile(file);
    });
    modalRef.componentInstance.Test.subscribe((data: CustomUploadFile[]) => {
      this.selectedFiless = [...data, ...this.selectedFiless];
      this.onResize();
    });
  }
  imageData: string;
  uploadFilesUpdate(fileq: CustomUploadFile): void {
    const modalRef = this.modal.create({
      nzContent: AttachmentAnnotationComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.DrawerImge = fileq;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.imageDataRes.subscribe((data: any) => {
      const newImageUrl = data;
      let test1234 = this.selectedFiless.forEach(activity => {
        activity.thumbUrl = newImageUrl;
      });
      console.log(test1234);
    });
  }
  AnnotationFilesUpdate(): void {
    const modalRef = this.modal.create({
      nzContent: PhotoDrawComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  removeFile(file: CustomUploadFile): void {
    this.selectedFiless = this.selectedFiless.filter(item => item.uid !== file.uid);
  }
  tabs = [1, 2, 3, 4, 5];
  innerCardTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'To-Do Information';
      case 2:
        return 'Checklist';
      case 3:
        return 'Related Items';
      default:
        return '';
    }
  }
  listOFData: Person[] = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park'
    },
  ];
  // _________________________Abdul Rehman  End_____________________________



  // ............Noor work in Lead Template........//
  leadActivities: any[] = [];
  handleDataSaved(data: any) {
    // Update the table data with the newly saved data
    this.leadActivities.push(data);
  }


  ImportLeadTemplate() {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityImportTemplateComponent,
      nzFooter: null,
      nzData: {
        isLeadTemplate: this.leadOpportunityResponse
      }
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.importTemplate.subscribe((data: LeadActivityResponse | LeadActivityResponse[]) => {
      // Initialize leadActivitiesResponse if it's null
      if (!this.leadActivitiesResponse) {
        this.leadActivitiesResponse = [];
      }

      // Check if data is an array or a single object
      if (Array.isArray(data)) {
        this.leadActivitiesResponse = [...this.leadActivitiesResponse, ...data];
      } else {
        this.leadActivitiesResponse = [...this.leadActivitiesResponse, data];
      }

    });
  }



  CopyToLeadTemplate() {
    const modalRef = this.modal.create({
      nzContent: CopyToActivityTemplateComponent,
      nzFooter: null,
      nzData: {
        isLeadTemplate: this.leadOpportunityResponse
      }
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  ImportProposals() {
    const modalRef = this.modal.create({
      nzContent: ImportProposalsComponent,
      nzFooter: null,
      nzData: {
        isLeadTemplate: this.leadOpportunityResponse

      }
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  }

  // New Lead Activity Method
  NewLeadActivity(newOpportunityModalNotCloseFlag: boolean) {
    this.newOpportunityModalNotClose = newOpportunityModalNotCloseFlag;

    // Call saveButton with 'save' actionType and handle the promise
    this.saveButton('save').then(() => {
      const modalRef = this.modal.create({
        nzContent: LeadActivityFormComponent,
        nzFooter: null,
        nzData: {
          leadData: this.formFieldValues,
        }
      });

      modalRef.componentInstance.activityResponseId = this.leadOpportunityResponse?.id;
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });

      modalRef.componentInstance.leadActivityOnSavedCompleted.subscribe((data: LeadActivityResponse | LeadActivityResponse[]) => {
        if (!this.leadActivitiesResponse) {
          this.leadActivitiesResponse = [];
        }

        if (Array.isArray(data)) {
          this.leadActivitiesResponse = [...this.leadActivitiesResponse, ...data];
        } else {
          this.leadActivitiesResponse = [...this.leadActivitiesResponse, data];
        }
      });

      this.conformCardShow = false;
    }).catch((error) => {
      console.error('Error saving lead before opening activity modal:', error);
    });

    this.conformCardShow = false;
  }

  // New Proposal Card Method
  NewProposalCard(newOpportunityModalNotCloseFlag: boolean) {
    this.newOpportunityModalNotClose = newOpportunityModalNotCloseFlag;

    // Call saveButton with 'save' actionType and handle the promise
    this.saveButton('save').then(() => {
      if (this.leadOpportunitySaveResponse) {
        setTimeout(() => {
          const modalRef = this.modal.create({
            nzContent: NewProposalComponent,
            nzFooter: null,
            nzData: this.leadOpportunitySaveResponse
          });
          modalRef.componentInstance.cancel.subscribe(() => {
            modalRef.destroy();
          });
        }, 1000);
        this.proposalConformCardShow = false;
      }
    }).catch((error) => {
      console.error('Error saving lead before opening proposal modal:', error);
    });

    this.proposalConformCardShow = false;
  }





  IsCopyProposalsTemplate(proposalId: number) {
    const modalRef = this.modal.create({
      nzContent: CopyToProposalsTemplateComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.proposalId = proposalId
    modalRef.componentInstance.leadId = this.leadOpportunityResponse.id
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });



  }


  IsCustomer(Id: number, flag: boolean) {
    const modalRef = this.modal.create({
      nzContent: NewContactComponent,
      nzFooter: null,
    });

    modalRef.componentInstance.CustomerResponseId = Id;
    modalRef.componentInstance.saveAndNewButtonHide = flag;
    // modalRef.componentInstance.CustomerResponse = this.leadOpportunityResponse.customerInformation;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }


  //handle the disabled option dropdown not perform the action created by mehmood
  handleCopyToLeadTemplate() {
    if (this.leadActivitiesResponse && this.leadActivitiesResponse?.length > 0) {
      this.CopyToLeadTemplate();
    } else {
      this.toastService.warning('No lead activities available to copy.');
    }
  }


  // current date then show Today
  isToday(date: string | Date): boolean {
    const today = new Date();
    const inputDate = new Date(date);
    return inputDate.getDate() === today.getDate() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getFullYear() === today.getFullYear();
  }


  validationStates: { [key: string]: NzStatus } = {};
  validationMessages: { [key: string]: string } = {};
  changedFields: Set<string> = new Set<string>();

  readonly validationRules = {
    title: {
      required: 'Required',
      maxLength: { limit: 50, message: 'Opportunity Title exceeds the limit.' }
    },
    streetAddress: {
      maxLength: { limit: 50, message: 'Street Address exceeds the limit.' }
    },
    suburb: {
      maxLength: { limit: 50, message: 'Suburb exceeds the limit.' }
    },
    state: {
      maxLength: { limit: 3, message: 'State exceeds the limit.' }
    },
    postalCode: {
      maxLength: { limit: 15, message: 'Postal Code exceeds the limit.' }
    }
  };

  // Function to get the label for a field
  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      title: 'Opportunity Title',
      streetAddress: 'Street Address',
      suburb: 'Suburb',
      state: 'State',
      postalCode: 'Postal Code'
    };
    return labels[field] || field;
  }


  checkErrors(validateAllFields = false) {
    this.validationStates = {};
    this.validationMessages = {};

    // Determine which fields to validate
    const fieldsToValidate = validateAllFields ? Object.keys(this.validationRules) : Array.from(this.changedFields);

    for (const field of fieldsToValidate) {
      if (this.validationRules.hasOwnProperty(field)) {
        const errorTip = this.getErrorTip(field);
        if (errorTip) {
          this.validationStates[field] = 'error';
          this.validationMessages[field] = errorTip;
        } else {
          this.validationStates[field] = null;
          this.validationMessages[field] = '';
        }
      }
    }
  }

  validateAllFields() {
    this.checkErrors(true);
  }

  validateChangedFields(field: string) {
    this.changedFields.add(field); // Add field to the changed fields set
    this.checkErrors(); // Validate changed fields
  }




  getErrorTip(field: string): string {
    const rules = this.validationRules[field];
    const input = this.formFieldValues[field] || '';

    if (rules.required && !input.trim()) {
      return `Required.`;
    }

    if (rules.maxLength && input.length > rules.maxLength.limit) {
      const excessLength = input.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }

    return ''; // No error
  }


  // Get an array of fields with errors
  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }

  // Function to handle changes in field values
  onFieldChange(field: string, value: string) {
    this.formFieldValues[field] = value;
    this.changedFields.add(field);
    this.checkErrors();
  }





  ///////////////////     Setup Forms Methods  Start   ////////////////////////////

  iniliazeForm() {
    this.TagsForm = this.fb.group({
      id: [''],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      name: ['', [Validators.required, Validators.maxLength(50)]],
      companyParameterId: [1],
      formNameId: this.pageId,
      lookUpStandardId: [],
      parentCode: [''],
      code: [''],
      description: [''],
      isFieldValue: [true],
    });
  }

  get exceededCharacters(): number {
    const nameControl = this.TagsForm.get('name');
    if (nameControl && nameControl.errors && nameControl.errors['maxlength']) {
      const currentLength = nameControl.value.length;
      const maxLength = nameControl.errors['maxlength'].requiredLength;
      return currentLength - maxLength;
    }
    return 0;
  }

  SaveOrUpdateData() {
    // Mark all controls as touched to ensure validation errors are shown
    Object.values(this.TagsForm.controls).forEach(control => {
      if (control instanceof FormControl) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });

    if (this.TagsForm.valid) {
      if (this.isLoadingEdit) {
        this.editData();
      } else {
        this.SaveData();
      }
    }
  }



  SaveData(): void {
    if (this.TagsForm.valid) {
      this.loadingIndicatoreService.show();
      this.isLoadingOne = true;
      setTimeout(() => {
        this.leadOppService.getLookUpNameSetup(this.TagsForm.value).subscribe(
          (response) => {
            if (response.result && 'id' in response.result && 'code' in response.result) {
              const id = response.result.id.toString();
              const code = response.result.code;

              if (code === 'LOLVPT') {
                this.formFieldValues.projectTypeSetupId = id;
              } else if (code === 'LOLVT') {
                this.formFieldValues.tagSetupId = [id];
              } else if (code === 'LOLVS') {
                this.formFieldValues.sourceSetupId = [id];
              } else {
                this.toastService.error('Response code does not match expected values.');
              }
            } else {
              this.toastService.error('Response does not contain a valid id or code.');
            }

            this.initLoad();
            this.loadingIndicatoreService.hide();
            this.isTagVisible = false;
            this.iniliazeForm();  // Ensure this method properly resets or initializes the form
            this.isLoadingOne = false;
            // this.toastService.success(`${tagName} saved successfully`);
          },
          (error) => {
            this.loadingIndicatoreService.hide();
            this.isLoadingOne = false;

            if (error.status === 500) {
              this.toastService.error('Internal Server Error. Please try again later.');
            } else if (error.status === 400) {
              this.toastService.error('Bad Request. Please check your input.');
            } else {
              this.toastService.error('Failed to save division trade. Please try again.');
            }
          }
        );
      }, 1000);
    } else {
      this.TagsForm.markAllAsTouched();
    }
  }

  editData() {
    if (this.TagsForm.valid) {
      this.loadingIndicatoreService.show();
      setTimeout(() => {
        this.leadOppService.getLookUpNameSetup(this.TagsForm.value).subscribe(
          () => {
            this.loadingIndicatoreService.hide();
            this.isTagVisible = false;
            this.initLoad();
            this.isLoadingEdit = false;
            this.iniliazeForm();
            // this.toastService.success(`${tagName} Update successfully`);
          },
          (error) => {
            this.loadingIndicatoreService.hide();
            if (error.status === 500) {
              this.toastService.error('Internal Server Error. Please try again later.');
            } else if (error.status === 400) {
              this.toastService.error('Bad Request. Please check your input.');
            } else {
              this.toastService.error('Failed to save division trade. Please try again.');
            }
          }
        );
      }, 1000); // 2000 milliseconds (2 seconds)
    }
  }

  ConfirmDeleteTags(deleteTitle: string): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: `Delete ${deleteTitle}?`,
      nzContent:
        `This ${deleteTitle} is not applied to any subs/vendors and will be deleted.`,
      nzOkDanger: true,
      nzOkText: 'Delete',
      nzCancelText: 'Cancel',
      nzModalType: 'confirm',
      nzOkLoading: this.isDeleteTagsLoading,
      nzAutofocus: 'ok',
      nzCentered: true,
      nzOnOk: () => this.DeleteData(),
    });
  }

  DeleteData(): Promise<boolean> {
    const vendorId = this.TagsForm.get('globalId')?.value;
    this.isDeleteTagsLoading = true;
    this.loadingIndicatoreService.show();

    return new Promise<boolean>((resolve, reject) => {
      this.leadOppService.deleteDataLookupNameSetup(vendorId).subscribe(
        () => {
          this.isDeleteTagsLoading = false;
          setTimeout(() => {
            // this.toastService.success(`${tagName} Deleted successfully`);

            // Reset fields based on flags
            if (this.resetProjectType === true) {
              this.formFieldValues.projectTypeSetupId = 0;
            }
            if (this.resetSource === true) {
              this.formFieldValues.sourceSetupId = [];
            }
            if (this.resetTags === true) {
              this.formFieldValues.tagSetupId = [];
            }

            // Reset the form
            // this.TagsForm.reset();
            this.iniliazeForm();
            // Hide the modal and reset flags
            this.isTagVisible = false;
            this.loadingIndicatoreService.hide();
            this.confirmModal.destroy();

            // Optionally reinitialize data if necessary
            this.initLoad();

            resolve(true);
          }, 1000);
        },
        () => {
          this.isDeleteTagsLoading = false;
          this.isTagVisible = false;
          reject(false);
        }
      );
    });
  }



  showConfirmData(): void {
    if (this.TagsForm.dirty) {
      // The form has unsaved changes, show confirmation modal
      this.confirmModal = this.modal.confirm({
        nzTitle: '<b>Save Changes?</b>',
        nzContent:
          '<p>This Lead Tags has unsaved changes. Do you want to save your changes before closing??</p> ',
        nzOkText: 'Save',
        nzCancelText: "Don't Save",
        nzOnOk: () => {
          // User confirmed to discard changes, save and close modal
          this.SaveData();
        },
        nzOnCancel: () => {
          // User canceled, reset form and close modal
          this.TagsForm.reset();
          this.isTagVisible = false;
        },
        nzCloseIcon: 'false',
        nzStyle: { top: '250px' },
        nzClassName: 'custom-modal-content',
      });
    } else {
      // No unsaved changes, close the modal directly
      this.TagsForm.reset();
      this.isTagVisible = false;
    }
  }

  resetProjectType: boolean = false;
  resetSource: boolean = false;
  resetTags: boolean = false;

  showTags(fieldType: string, pCode: string, code: string, stndId: number): void {
    this.loadingIndicatoreService.show();

    setTimeout(() => {
      this.loadingIndicatoreService.hide();
      this.isTagVisible = true;
      this.isLoadingEdit = false;
    }, 1000);

    // Update the dynamic title based on the field type
    switch (fieldType) {
      case 'Sources':
        this.ModalTitle = { title: 'Add Lead Source', deleteTitle: 'Lead Source' };
        this.TagsForm.controls['code'].setValue(code);
        this.TagsForm.controls['parentCode'].setValue(pCode);
        this.TagsForm.controls['lookUpStandardId'].setValue(stndId);

        break;
      case 'ProjectType':
        this.ModalTitle = { title: 'Add Project Type', deleteTitle: 'Project Type' };
        this.TagsForm.controls['code'].setValue(code);
        this.TagsForm.controls['parentCode'].setValue(pCode);
        this.TagsForm.controls['lookUpStandardId'].setValue(stndId);

        break;
      case 'Tags':
        this.ModalTitle = { title: 'Add Lead Tag', deleteTitle: 'Lead Tag' };
        this.TagsForm.controls['code'].setValue(code);
        this.TagsForm.controls['parentCode'].setValue(pCode);
        this.TagsForm.controls['lookUpStandardId'].setValue(stndId);

        break;
      default:
        this.ModalTitle = { title: 'Add/Edit Field', deleteTitle: 'Add/Edit Field' };

        break;
    }

  }


  showTagEdit(selectedValue: LookupNameSetupResponse, fieldType: string): void {
    if (selectedValue) {
      const selectedId: number = Number(selectedValue); // Convert the selected ID to number
      this.isTagVisible = true;
      this.isLoadingEdit = true;
      const selectedObjectFromLookup = this.lookupSetNameResponse.find(item => item.id === selectedId);
      if (selectedObjectFromLookup) {
        const selectedData = selectedObjectFromLookup;
        if (selectedData) {
          this.TagsForm.controls['name'].setValue(selectedData.name);
          this.TagsForm.controls['globalId'].setValue(selectedData.globalId);
          this.TagsForm.controls['isFieldValue'].setValue(selectedData.isFieldValue);
          this.TagsForm.controls['companyParameterId'].setValue(selectedData.companyParameterId);
          this.TagsForm.controls['formNameId'].setValue(selectedData.formNameId);
          this.TagsForm.controls['lookUpStandardId'].setValue(selectedData.lookUpStandardId);
          this.TagsForm.controls['code'].setValue(selectedData.code);
          this.TagsForm.controls['description'].setValue(selectedData.description);
          this.TagsForm.controls['parentCode'].setValue(selectedData.parentCode);
        }
      }
    }

    switch (fieldType) {
      case 'Sources':
        this.ModalTitle = { title: 'Edit Lead Source', deleteTitle: 'Lead Source' };
        this.resetSource = true;
        this.resetTags = false;
        this.resetProjectType = false;
        break;
      case 'ProjectType':
        this.ModalTitle = { title: 'Edit Project Type', deleteTitle: 'Project Type' };
        this.resetProjectType = true;
        this.resetSource = false;
        this.resetTags = false;

        break;
      case 'Tags':
        this.ModalTitle = { title: 'Edit Lead Tag', deleteTitle: 'Lead Tag' };
        this.resetTags = true;
        this.resetProjectType = false;
        this.resetSource = false;
        break;
      default:
        this.ModalTitle = { title: 'Add/Edit Field', deleteTitle: 'Add/Edit Field' };

        break;
    }
  }

  CancelTags(): void {
    this.isTagVisible = false;
  }
  //    Setup Forms Methods  End




  getStatusClass(description: string): string {
    switch (description) {
      case 'Pending':
        return 'status-text pending';
      case 'Unreleased':
        return 'status-text unreleased';
      case 'Approved':
        return 'status-text approved';
      case 'Declined':
        return 'status-text declined';
      case 'Declined Not Released':
        return 'status-text declined-not-released';
      case 'Expired':
        return 'status-text expired';
      case 'Released':
        return 'status-text released';
      default:
        return '';
    }
  }

  // generateMapUrl(query: string) {
  //   const url = `https://maps.google.com/maps?q=${encodeURIComponent(query)}&t=&z=13&ie=UTF8&iwloc=&output=embed`;
  //   window.open(url, '_blank');
  // }
  generateMapUrl(query: string) {
    const modalRef = this.modal.create({
      nzContent: GoogleMapComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.searchQuery = query;
  }

  @ViewChild('dropdown') dropdownTemplate!: TemplateRef<any>




  @ViewChild('leadActivityExtraCard', { static: true }) leadActivityExtraCard!: TemplateRef<any>;
  @ViewChild('selectedTemplate', { static: true }) selectedTemplate!: TemplateRef<any>;

  // Determine which template to use
  getCardExtraTemplate(): TemplateRef<any> {
    return this.setOfCheckedId.size > 0 ? this.selectedTemplate : this.leadActivityExtraCard;
  }

  capitalizeWords(text: string): string {
    return text.replace(/\b\w/g, char => char.toUpperCase());
  }

  public Editor = ClassicEditor;
  public config: EditorConfig = {
    toolbar: {
      items: [
        'undo', 'redo', '|',
        'heading', '|', 'bold', 'italic', '|',
        'link', 'insertTable', 'mediaEmbed', '|',
        'bulletedList', 'numberedList', 'indent', 'outdent', '|',
        'fontSize', 'fontFamily', 'fontBackgroundColor', 'fontColor', 'blockQuote',
        'image', 'horizontalLine', 'undoEditing', 'ListProperties', 'Autoformat'
      ]
    },
    plugins: [
      Bold,
      Essentials,
      Heading,
      Indent,
      Italic,
      Link,
      List,
      ListProperties,
      MediaEmbed,
      Paragraph,
      Autoformat,
      Table,
      Undo,
      Font,
      FontSize,
      FontFamily,
      FontBackgroundColor,
      FontColor,
      BlockQuote,
      Image,
      HorizontalLine,
      UndoEditing,
      ImageUpload
    ],
    fontSize: {
      options: [
        9, 11, 13, 'default', 17, 19, 21, 25, 28, 32, 36, 40, 44, 48, 52, 56, 60
      ]
    },
    fontFamily: {
      options: [
        'default', 'Arial, Helvetica, sans-serif', 'Courier New, Courier, monospace',
        'Georgia, serif', 'Times New Roman, Times, serif', 'Verdana, Geneva, sans-serif'
      ]
    }
  };



  // Assign Display Color Open Modal

  assignDisplayColor() {
    const modalRef: NzModalRef = this.modal.create({
      nzContent: LeadActivityAssignDisplayColorComponent,
      nzFooter: null,
      nzTitle: null,
      nzCentered: true,
      nzWidth: '30%'
    });
    modalRef.componentInstance.selectedGlobalIds = this.selectedGlobalIds;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.leadActivityColorAssign.subscribe(async () => {
      try {
        this.isLoading = true;
        const activitiesResponse = await this.leadActivitiesService.getAllActivitiesByLeadId(this.formFieldValues.id).toPromise();
        this.leadActivitiesResponse = activitiesResponse.result;

        this.leadActivitiesService.getAllCountActivitiesByLeadId(this.formFieldValues.id).subscribe(
          (response) => {
            this.leadActivityCount = response;
          },
          (error) => {
            console.error('Error fetching activity count:', error);
          }
        );
      } catch (error) {
        console.error('Error updating activities after deletion:', error);
      }
      this.isLoading = false;
      this.checked = null;
      this.isCheckboxSelected = false;
      this.setOfCheckedId.clear();
      this.selectedGlobalIds = [];
      this.selectedData = [];
    });
  }

  // Assign User

  leadActivityAssignUser() {
    const modalRef: NzModalRef = this.modal.create({
      nzContent: LeadActivityAssignUserComponent,
      nzFooter: null,
      nzTitle: null,
      nzCentered: true,
      nzWidth: '30%'
    });
    modalRef.componentInstance.selectedGlobalIds = this.selectedGlobalIds;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.leadActivityAssigned.subscribe(async () => {
      try {
        this.isLoading = true;
        const activitiesResponse = await this.leadActivitiesService.getAllActivitiesByLeadId(this.formFieldValues.id).toPromise();
        this.leadActivitiesResponse = activitiesResponse.result;


        this.leadActivitiesService.getAllCountActivitiesByLeadId(this.formFieldValues.id).subscribe(
          (response) => {
            this.leadActivityCount = response;
          },
          (error) => {
            console.error('Error fetching activity count:', error);
          }
        );
      } catch (error) {
        console.error('Error updating activities after deletion:', error);
      }
      this.isLoading = false;
      this.checked = null;
      this.isCheckboxSelected = false;
      this.setOfCheckedId.clear();
      this.selectedGlobalIds = [];
      this.selectedData = [];
    });


  }



  // Lead Activities Delete

  leadActivityDelete() {
    const modalRef: NzModalRef = this.modal.create({
      nzContent: LeadActivityDeleteComponent,
      nzFooter: null,
      nzTitle: null,
      nzCentered: true,
      nzWidth: '30%'
    });
    modalRef.componentInstance.selectedGlobalIds = this.selectedGlobalIds;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.leadActivityDeleted.subscribe(async () => {
      try {
        this.isLoading = true;
        const activitiesResponse = await this.leadActivitiesService.getAllActivitiesByLeadId(this.formFieldValues.id).toPromise();
        this.leadActivitiesResponse = activitiesResponse.result;

        this.leadActivitiesService.getAllCountActivitiesByLeadId(this.formFieldValues.id).subscribe(
          (response) => {
            this.leadActivityCount = response;
          },
          (error) => {
            console.error('Error fetching activity count:', error);
          }
        );
      } catch (error) {
        console.error('Error updating activities after deletion:', error);
      }
      this.isLoading = false;
      this.checked = null;
      this.isCheckboxSelected = false;
      this.setOfCheckedId.clear();
      this.selectedGlobalIds = [];
      this.selectedData = [];
    });

  }

}
