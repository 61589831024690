import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CreateLookupFilterRequest, LookUpStandardResponse, LookupFilter, LookupFilterResponse } from '../../Models/LookupModels';
import { RoleTypeService } from 'src/Service/Internaluser/role-type.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { AddNewStandardFilterComponent } from '../../Modal/add-new-standard-filter/add-new-standard-filter.component';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FieldDataType } from 'src/app/Core/Enums/data_types';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { BudgetPridefinedCodes as BudgetPredefinedCodes } from '../../Models/LookUpStandardFilterCodes';
import { LookupNameSetupResponse } from 'src/Models/All-Filters/financialBillsFilterField';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { InterUserService } from 'src/Service/inter-user.service';

@Component({
  selector: 'app-financial-buget-filter-field',
  templateUrl: './financial-buget-filter-field.component.html',
  styleUrls: ['./financial-buget-filter-field.component.css']
})
export class FinancialBugetFilterFieldComponent implements OnInit {
  @Input() pageId: number;
  financialbugetFilterForm: FormGroup;
  createLookupFilterRequests: CreateLookupFilterRequest[] = [];
  filterResponseData: LookupFilterResponse[] = [];
  lookUpStandardResponse: LookUpStandardResponse[] = [];
  defaultStandardFilter: LookupFilterResponse;
  predefinedValuesMultiSelect: string[] = [];
 

  preDefinedDataCodes = {
    standardFilter: BudgetPredefinedCodes.standardFilter,
    status: BudgetPredefinedCodes.ApprovalStatus,
    name: BudgetPredefinedCodes.standardFilter,
  };

  nodes: NzTreeNodeOptions[] = [
    {
      title: 'Check All',
      value: '0-all',
      key: '0-all',
      isLeaf: true,
      checked: false
    },
    {
      title: 'Node1',
      value: '0-0',
      key: '0-0',
      isLeaf: true
    },
    {
      title: 'Node2',
      value: '0-1',
      key: '0-1',
      isLeaf: true
    },
    {
      title: 'Node3',
      value: '0-2',
      key: '0-2',
      isLeaf: true
    },
    {
      title: 'Node4',
      value: '0-3',
      key: '0-3',
      isLeaf: true
    },
    {
      title: 'Node5',
      value: '0-4',
      key: '0-4',
      isLeaf: true
    },
    {
      title: 'Node6',
      value: '0-5',
      key: '0-5',
      isLeaf: true
    },];
    InterUserService: any;
  constructor(
    private roleService: RoleTypeService,
    private fb: FormBuilder,
    public toastService: NzMessageService,
    private cdr: ChangeDetectorRef,
    private loadingIndicatoreService: LoadingIndicatorService,
    private modal: NzModalService,
    private LookupFilterService: LookupFilterService,
    private internalUserService: InterUserService,

  ) { }
  ngOnInit(): void {
    this.initLoad();
    this.showContactTypes();
  }
  initLoad() {
    const lookupFilter: LookupFilter = {
      formNameId: this.pageId,
    };
    this.getStandardFilterByFormPageId(this.pageId);
    this.fetchData(lookupFilter);
  }
  fetchData(lookUpStandard: LookupFilter): void {
    this.LookupFilterService.postData(lookUpStandard).subscribe(
      data => {
        this.lookUpStandardResponse = data.result;
      },
      error => {
        console.error('Error fetching data:', error);
      }
    );
  }
  openStandardFilterComponent() {
    this.createFormFieldsInArray();
    const modalRef = this.modal.create({
      nzContent: AddNewStandardFilterComponent,
      nzData: 
      { 
        pageId: this.pageId,
        std : this.filterResponseData ,
        field: this.createLookupFilterRequests
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  getStandardFilterByFormPageId(pageId: number) {
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.LookupFilterService.$getStandardFilter.subscribe(result =>{
      if(result){
        this.filterResponseData = result.result;
        console.log("RESULT STANDARD FILTER : ", this.filterResponseData);
        this.initializeForm();
        this.setDefaultFieldValues(this.defaultStandardFilter.id);
      }
    });
  }
  initializeForm(): void {
    this.financialbugetFilterForm = this.fb.group({
      standardFilter: new FormControl(this.getDefaultFilterValue()),
      search: [null],
      approvalStatus: [null],
      costCatagory: [null],
    });
  }
  onSubmit(): void {
    this.createFormFieldsInArray();
  }
  resetFilter(): void {
    const formControlKeys = Object.keys(this.financialbugetFilterForm.controls).slice(1);
    formControlKeys.forEach(field => {
      this.financialbugetFilterForm.get(field).reset();
    });
  }
  createFormFieldsInArray() {
    this.createLookupFilterRequests = [];
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const value = this.financialbugetFilterForm.get(field).value;
      if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 1 && value[0] === null)) {
        const newRow = {
          companyParameterId: 0,
          lookUpStandardId: 0,
          code: code,
          meaning: '',
          valueId: 0,
          formNameId: this.pageId,
          dataTypeId: 0,
          createLookupFilterParameterRequests: [null]
        };
        const correspondingResponse = this.lookUpStandardResponse.find(response => response.code === code);
        if (correspondingResponse) {
          newRow.companyParameterId = correspondingResponse.companyParameterId;
          newRow.lookUpStandardId = correspondingResponse.id;
          newRow.dataTypeId = correspondingResponse.dataTypeId;
          if (
            correspondingResponse.dataType.code === FieldDataType.SingleLineText ||
            correspondingResponse.dataType.name === 'Single-Line Text') {
            newRow.meaning = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.Dropdown ||
            correspondingResponse.dataType.name === 'Dropdown') {
            newRow.valueId = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.MultiSelectDropdown ||
            correspondingResponse.dataType.name === 'Multi-Select Dropdown'
          ) {
            newRow.createLookupFilterParameterRequests = value.map((item, index) => {
              return { valueId: item };
            });
          }
          // Assign other properties similarly based on your data structure
        }
        this.createLookupFilterRequests.push(newRow);
      }
    });
  }
  getDefaultFilterValue() {
    this.defaultStandardFilter = this.filterResponseData.find(filter => filter.isSetAsDefault === true);
    return this.defaultStandardFilter ? this.defaultStandardFilter.id : null;
  }
  setDefaultFieldValues(standardFilterId: number): void {
    this.predefinedValuesMultiSelect = [];
    let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
    let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;
    // Get all form control keys except the first one
    const formControlKeys = Object.keys(this.financialbugetFilterForm.controls).slice(1);
    // Reset all form controls except the first one
    formControlKeys.forEach(field => {
      this.financialbugetFilterForm.get(field).reset(); // Reset the form control
    });
    if(lookUpFiltersField.length > 0){
      Object.keys(this.preDefinedDataCodes).forEach(field => {
        const code = this.preDefinedDataCodes[field].code;
        const fieldData = lookUpFiltersField.find(filter => filter.code === code);
        if (fieldData != undefined) {
          if (
            fieldData.dataType.code === FieldDataType.SingleLineText) {
            this.financialbugetFilterForm.get(field).setValue(fieldData.meaning);
          }
          if (fieldData.dataType.code === FieldDataType.Dropdown) {
            this.financialbugetFilterForm.get(field).setValue(fieldData.id);
          }
          if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown) {
            let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
            this.predefinedValuesMultiSelect = multiSelectValues.map(String);
            this.financialbugetFilterForm.get(field).setValue(this.predefinedValuesMultiSelect);
          }
        }
      });
    }
    
  }
  onChangeStandardFilter($event: number): void {
    this.setDefaultFieldValues($event);
  }



  
  ApprovalStatusNodes : NzTreeNodeOptions[]=[];
    roles: { id: string, name: string }[] = [];
    
    showContactTypes() {
      const formNameId = 16;
      this.internalUserService.showCode(formNameId).subscribe(
        (res: ResponseModelArray<LookupNameSetupResponse>) => {
          const approvData = res.result.filter(item => item.code === BudgetPredefinedCodes.ApprovalStatus.code);

          this.ApprovalStatusNodes = approvData.map(ApprovalStatus => ({
            title: ApprovalStatus.description,
            value: ApprovalStatus.id,
             key: ApprovalStatus.id.toString(),
             isLeaf: true

          }));

         
        },
        
        (error) => {
          console.error('Error fetching contact types:', error);
        }
      );
  }
}



