import { BaseResponse } from "src/Models/All-Filters/financialBillsFilterField";

export enum ProposalStatusEnum {
    Unreleased = 'LOLPPSTSU',
    Pending = 'LOLPPSTSP',
    Approved = 'LOLPPSTSA',
    Declined = 'LOLPPSTSD',
    DeclinedNotReleased = 'LOLPPSTSND',
    Expired = 'LOLPPSTSE',
    Released = 'LOLPPSTSR',
    Reset = 'LOLPPSTSRS',

  }
  

  export class StatusSetupProposal extends BaseResponse{
    companyParameterId: number;
    companyParameter: any; // You can replace 'any' with the appropriate type if known
    formNameId: number;
    formName: any; // You can replace 'any' with the appropriate type if known
    lookUpStandardId: number;
    lookUpStandard: any; // You can replace 'any' with the appropriate type if known
    parentCode: string;
    code: string;
    name: string;
    description: string;
    isFieldValue: boolean;
    // createdBy: number;
    // createdOn: Date;
    // modifiedBy: number;
    // modifiedOn: Date;
    // isActive: boolean;
    // id: number;
    // globalId: string;
  }  