import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-surveys-empty-content',
  templateUrl: './surveys-empty-content.component.html',
  styleUrls: ['./surveys-empty-content.component.css']
})
export class SurveysEmptyContentComponent {

  @Output() cancel = new EventEmitter<void>();
  surveyEmptyModelCancel(): void {
  this.cancel.emit();

  }
  // ForwardingEmail=false;
  // open(): void{
  //   this.ForwardingEmail=true;
  // }
  // close(): void{
  //   this.ForwardingEmail=false;
  // }

}
