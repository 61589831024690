
<nz-card [nzBordered]="false" nzTitle="Format Proposal">
    <div
      class="container-fluid  CardSetting text-center"
    >
      <div class="row mb-2">
        <div class="col">
          <h1>No Item Proposal</h1>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <p>
            Organize and formate proposal item here to share with
            your lead.Start by adding items on the worksheet tab.
          </p>
        </div>
      </div>
    </div>
  </nz-card>