<nz-layout class="p-0 m-0 famly-inn">
  <nz-header class="p-0 m-0" style="background-color: #fff">
    <div class="row ms-3 mt-1">
      <h1 class="p-0 m-0 mt-3">Clock Out</h1>
    </div>
  </nz-header>
  <nz-content>
    <div class="content p-3">
      <div class="row famly-inn gx-3">
        <div class="col-12">
          <div class="card border-0">
            <div class="card-head border-bottom">
              <div class="row py-2">
                <h3 nz-typography><span class="ms-3">Current Shift</span></h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row text-center">
                <div class="col-4">
                  <label class="sub-text">Current Time</label>
                  <h3>{{ currentTime | date: 'hh:mm' }}
                  </h3>
                </div>
                <div class="col-4">
                  <label class="sub-text fw-bold"> Hours Worked</label>
                  <h3>{{ calculateHoursWorked(holdResponse?.Data?.startTime, currentTime) }}</h3>
                </div>
                <div class="col-4">
                  <label class="sub-text"> Breaks Taken</label>
                  <h3>0:00</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <label class="sub-text">Job <span class="text-danger fw-bold">*</span></label>
                  <nz-select nzPlaceHolder=" " [(ngModel)]="timeClockForm['jobInformationId']" name="jobInformationId" nzDisabled class="rounded-end-3" nz-icon="false">
                    <nz-option class="p-0 m-0" *ngFor="let option of jobList" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                </nz-select>
                
                
                </div>
              </div>
              <div nz-col nzSpan="">
                <label class="sub-text">User <span class="text-danger">*</span></label>
                <nz-tree-select [nzDropdownStyle]="{ 'max-height': '250px', 'overflow-y': 'auto' }"
                [(ngModel)]="timeClockForm.applicationUserIds" name="applicationUserIds"
                 [nzNodes]="userNodes" [nzShowSearch]="true" [nzAllowClear]="false" [nzCheckable]="true"
                 nzCheckAll="true" nzPlaceHolder="Select Division" nzDisabled>
               </nz-tree-select>



              </div>
              <ng-template #Dailyfw2>
                <div class="rounded-2" style="width: 350px; max-height: 300px">
                  <div class="row">
                    <div class="col-2">
                      <nz-avatar class="mt-1" style="
                          width: 64px;
                          height: 64px;
                          line-height: 64px;
                          font-size: 28px;
                          background-color: rgb(255, 185, 168);
                          color: rgb(139, 20, 57);
                        " nzText="MY"></nz-avatar>
                    </div>
                    <div class="col-10">
                      <label class="sub-text fs-6 ms-1">Muhammad Yousuf</label><br />
                      <i class="bx bx-envelope ms-1"></i><label
                        class="text-primary ms-1">yousuf.tutalhotmail.com</label>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12">
                      <button nz-button nzType="text" class="same-but">
                        <span nz-icon nzType="message" nzTheme="outline"></span>
                      </button>
                      <button nz-button nzType="text" class="same-but">
                        <span nz-icon nzType="user" nzTheme="outline"></span>
                      </button>
                      <button nz-button nzType="text" disabled class="same-but1">
                        <span nz-icon nzType="phone" nzTheme="outline"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-template>

              <!-- Cost Code -->

              <div class="row">
                <label class="sub-text">Cost Code</label>
                <div class="col-10">
                  <nz-select nzShowSearch nzAutoFocus="true" nzPlaceHolder="Buildertrend Flat Rate"
                    [(ngModel)]="timeClockForm['costCodeId']" name="costCodeId">
                    <nz-option [nzLabel]="'Buildertrend Flat Rate'" [nzValue]="0"></nz-option>
                    <nz-option class="p-0 m-0" *ngFor="let option of costSubCode" [nzLabel]="option.title"
                      [nzValue]="option.id"></nz-option>
                  </nz-select>
                </div>
              </div>

              <!-- Cost Code -->

              <div class="row mt-2 gx-5">
                <div class="col-1">
                  <button (click)="newcostcode()" nz-button type="button" class="none-buttom">
                    <span>Add</span>
                  </button>
                </div>
                <div class="col-1">
                  <button (click)="newcostcode()" nz-button type="button" class="none-buttom">
                    <span>Edit</span>
                  </button>
                </div>  
              </div>
              <div nz-row>
                <div nz-col nzSpan="18">
                  <label class="sub-text">Tags</label>
                  <nz-tree-select name="activityStatus" 
                  [nzDropdownStyle]="{ 'max-height': '250px', 'overflow-y': 'auto' }"
                  [(ngModel)]=" timeClockForm['createShiftTagParameterRequests']
                  " name="createShiftTagParameterRequests" [nzNodes]="tagNodes" nzShowSearch nzAllowClear="false"
                    nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
                </div>
                <div nz-col nzSpan="6" style="margin-top: 30px; padding: 0px">
                  <button class="btn btn-sm text-primary" (click)="showTags()">
                    Add
                  </button>

                  <button [disabled]="!timeClockForm.createShiftTagParameterRequests || timeClockForm.createShiftTagParameterRequests.length !== 1"
                  class="btn btn-sm text-primary border-0 me-1" (click)="showTagEdit(timeClockForm.createShiftTagParameterRequests)">
                  Edit
                </button>

                </div>
              </div>

              <div class="row">
                <div class="col-12"><label class="sub-text">Notes</label></div>
              </div>
              <div class="row">
                <div class="col-12">
                  <textarea style="border-radius: 5px" rows="2" class="formField" [(ngModel)]="timeClockForm['notes']"
                    name="notes" nz-input></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-content>
  <nz-footer class="p-2 Unstuck">
    <button nz-button nzType="primary" class="rounded-1 me-0" (click)="saveClockOut()">Clock Out</button>
  </nz-footer>
</nz-layout>

<!-- Tags Modal Start -->

<nz-modal nzWidth="35%" [(nzVisible)]="isTagVisiblee" [nzTitle]="divsionTradeTitle1" [nzFooter]="divsionTradeFooter1"
  [nzBodyStyle]="{ background: '#f1f4fa', padding: '14px' }" [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="showConfirmCancel()">
  <ng-template #divsionTradeTitle1>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0">Add Time Clock Tag</h1>
    </div>
  </ng-template>
  <div class="content" *nzModalContent2>
    <form [formGroup]="TagForm">
      <nz-card [nzBorderless]="false" nzTitle="Add Time Clock Tag">
        <div class="row">
          <div class="col">
            <label class="sub-text">Title<span class="text-danger fw-bolder">*</span></label>
            <input id="inputField" formControlName="name" type="text" class="form-control formField" [ngClass]="{
                error:
                  TagForm.get('name')?.invalid &&
                  TagForm.get('name')?.touched
              }" />
            <div *ngIf="
                TagForm.get('name').hasError('required') &&
                TagForm.get('name').touched
              " class="text-danger p-error">
              Required
            </div>
          </div>
        </div>
      </nz-card>
    </form>
  </div>
  <ng-template #divsionTradeFooter1>
    <div class="mt-2 align-items-center">
      <button *ngIf="isLoadingEdit" nz-button nzType="default" class="rounded-1 me-0" (click)="ConfirmDeleteTags()">
        Delete
      </button>

      <button nz-button nzType="primary" class="rounded-1 me-0" (click)="SaveOrUpdateData()">
        {{ isLoadingEdit ? "Update" : "Save" }}
      </button>



    </div>
  </ng-template>
</nz-modal>