import { CostItemResponse, CreateCostItemParameterRequest, CreateCostItemRequest, UpdateStatusPayload } from './../../Models/LeadProposal/LeadProposalModels';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { ApplicationUserForComboResponse } from 'src/Models/InternalUser/InternalUserJob';
import { CostCodeCategoryResponse, CostCodeInfo, CostCodeResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { CostItemParameterResponse, CreateLeadProposalRequest, LeadProposalResponse } from 'src/Models/LeadProposal/LeadProposalModels';
import { ResponseModel, ResponseModelArray } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadProposalsService {
  private baseURLGet: string = environment.apiUrl + 'CostCode/';
  private base__Url: string = environment.apiUrl + 'ApplicationUser/';
  private leadProposalBaseUrl: string = environment.apiUrl + 'LeadProposal/';
  private CostItembaseurls: string = environment.apiUrl + 'CostItem/';
  private CostItemLeadProposalsbaseurls: string = environment.apiUrl + 'CostItem/';



  constructor(private http: HttpClient) {}
  getAllLeadData(): Observable<ResponseModelArray<CostCodeCategoryResponse>> {
    return this.http.get<ResponseModelArray<CostCodeCategoryResponse>>(`${this.baseURLGet}getAll`);
  }

  getDataforcombo(): Observable<ResponseModelArray<ApplicationUserForComboResponse>> {
    return this.http.get<ResponseModelArray<ApplicationUserForComboResponse>>(`${this.base__Url}GetAll-ActiveUsers`);
  }
  postLeadProposal(LeadProposal: CreateLeadProposalRequest): Observable<ResponseModel<LeadProposalResponse>> {
    const apiUrl = `${this.leadProposalBaseUrl}`;
    return this.http.post<any>(apiUrl, LeadProposal).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }


  postCostItem(CostItem: CreateCostItemRequest): Observable<ResponseModel<CostItemResponse>> {
    const apiUrl = `${this.CostItembaseurls}`;
    return this.http.post<any>(apiUrl, CostItem).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }


    ////// Get By Lead-Id All Proposal Create Method By Mehmood  07/03/2024  //////////

    private baseUrl: string = environment.apiUrl + 'LeadProposal/';

    getAllProposalByLeadId(id: number): Observable<ResponseModel<LeadProposalResponse[]>> {
      return this.http.get<ResponseModel<LeadProposalResponse[]>>(`${this.baseUrl}getAll-ByLeadId?LeadOpportunityId=${id}`);
    }




    deleteProposal(globalId: string): Observable<HttpResponse<void>> {
      const url = `${this.leadProposalBaseUrl}?globalId=${globalId}`;
      return this.http.delete<void>(url, { observe: 'response' }).pipe(
        catchError(this.handleError)
      );
    }

    private handleError(error: HttpErrorResponse): Observable<never> {
      console.error('An error occurred:', error.message);
      return throwError(() => new Error('An error occurred while deleting the proposal.'));
    }



    updateUnReleaseStatus(payload: UpdateStatusPayload): Observable<ResponseModel<any>> {
      const apiUrl = `${this.leadProposalBaseUrl}Update-LeadProposal-Status`;
      return this.http.post<ResponseModel<any>>(apiUrl, payload).pipe(
        catchError((error: any) => {
          console.error('Error:', error);
          return throwError(error);
        })
      );
    }


    getAllCountProposalsByLeadId(id: number): Observable<number> {
      return this.http.get<number>(`${this.baseUrl}getCount-ByLeadId?LeadOpportunityId=${id}`);
    }


}
