<nz-modal [(nzVisible)]="isVisible" nzTitle="Percentage Markup" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()"  [nzFooter]="footerTemplate">>
    <ng-container  class="content" *nzModalContent>
        <nz-card nzTitle="Edit Markup">
            <p>Any items set to a percentage markup will be updated to reflect the markup below. Items linked to Square
                Takeoff or with a different markup applied will not be updated.</p>
    <div class="row">
                <label class="sub-text">Percentage</label>
            </div>
    <div class="row">
                <nz-input-group nzAddOnAfter="%">
                    <input type="text" nz-input class="small-input"  [(ngModel)]="inputValue" (ngModelChange)="logInputValue()" appDollarFormatter/>
                  </nz-input-group>  
        </div>              
        </nz-card>
    </ng-container>
</nz-modal>
<ng-template #footerTemplate>
    <button nz-button (click)="handleCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="handleApply()">Apply</button>
  </ng-template>