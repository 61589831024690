import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { ApiResponseModel } from 'src/Models/apiResponse.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CreateStandardViewRequestService {


  private baseUrl: string = environment.apiUrl + 'StandardView/';


  constructor(private http: HttpClient) {}


  postData(data: any): Observable<ApiResponseModel> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }
  

  getData(): Observable<ApiResponseModel[]> {
    return this.http.get<ApiResponseModel[]>(`${this.baseUrl}getAll`);
  }

  updateData(data: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}`, data);
  }


  deleteData(globalId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}?globalId=${globalId}`);
  }

  getsDataById(id: string): Observable<ApiResponseModel> {
    return this.http.get<ApiResponseModel>(`${this.baseUrl}getBy-Id?id=${id}`);
  }
}
