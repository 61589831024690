<nz-layout>
  <nz-sider
    class="border"
    nzCollapsible
    [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed"
    [nzWidth]="280"
    [nzTrigger]="null"
  >
    <div class="row">
      <app-side-menu-bar
      [isAllJobOption]="true"
      (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"
      ></app-side-menu-bar>
    </div>
  </nz-sider>
  <nz-layout>
  <nz-page-header>
      <nz-page-header-title>
        <nz-breadcrumb class="text-start" nz-page-header-breadcrumb>
          <nz-breadcrumb-item>Yousuf Test F3</nz-breadcrumb-item>
        </nz-breadcrumb>
        <h1>
          Purchase Order
        </h1>

      </nz-page-header-title>


    </nz-page-header>
  <nz-content class="custom-content">
      <div class="container-fuild mb-4 m-3">
        <nz-tabset nzType="card" [nzSelectedIndex]="activeTab">
          <nz-tab *ngFor="let tab of tabs" [nzTitle]="innerCardTabs(tab)" style="background-color: #f1f4fa;">
            <ng-container *ngIf="tab === 1">
                <nz-page-header class="p-0">
                    <nz-page-header-extra>
                      <nz-space>
                        <button *nzSpaceItem nz-button nzType="default">Imports Purchased Orders</button>
                        <button *nzSpaceItem  nz-button nzType="default"  (click)="openFilter(Purchase_Order,PAGE_ID)" class="rounded-1 me-2"><span><i class="bi bi-funnel fs-6 me-1"></i></span>Filter</button>
                        <button *nzSpaceItem nz-button nzType="primary"> New <i class="bi bi-chevron-down ms-1"></i></button>
                      </nz-space>
                    </nz-page-header-extra>
                  </nz-page-header>

                <app-purchase-order-empty></app-purchase-order-empty>
            </ng-container>
            <ng-container *ngIf="tab === 2">
                <nz-page-header class="p-0 ">
                    <nz-page-header-extra>
                      <nz-space>
                        <button *nzSpaceItem nz-button nzType="default">Imports Bills </button>
                        <button *nzSpaceItem nz-button nzType="default" (click)="openFilter(Purchase_Order,PAGE_ID)"  ><span nz-icon nzType="filter" nzTheme="outline"></span>Filter</button>
                        <button *nzSpaceItem nz-button nzType="primary" (click)="BillsOpen()">New Bill</button>
                      </nz-space>
                    </nz-page-header-extra>
                  </nz-page-header>
             <app-purchase-order-bills></app-purchase-order-bills>
            </ng-container>
          </nz-tab>
        </nz-tabset>


<!-- Purchase Order Footer -->

<nz-footer class="custom-footer">
  <div class="container-fluid ">
    <div class="row d-flex justify-content-between align-items-center  ">
      <div class="row mb-0 mt-2">
        <div class="col">
          <nz-pagination style="float: right;" [nzPageIndex]="currentPage" [nzTotal]="totalRecords"
          [nzPageSize]="pageSize" nzShowSizeChanger (nzPageIndexChange)="onPageChange($event)"
          (nzPageSizeChange)="onPageSizeChange($event)" [nzPageSizeOptions]="[10, 20, 50, 75, 100]"></nz-pagination>

          <ng-container  *ngIf="PAGE_ID">
            <div class="row d-flex justify-content-start mb-1 ">
              <div class="col-md-3">
                <nz-select (ngModelChange)="onFilterChange($event)" [(ngModel)]="selectedValueControl" nzShowSearch nzAutoFocus="true">
                  <nz-select
                  [(ngModel)]="selectedValueControl"
                   name="standardFilter"
                   (ngModelChange)="onFilterChange($event)"
                   nzShowSearch
                   >
                   <nz-option  *ngFor="let standard of getStandardViewFilterData"
                     [nzLabel]="standard.name + (standard.isSetAsDefault ? ' ' : '')"
                     [nzValue]="standard.id"
                     nzCustomContent>
                     <div class="option-content">
                       <!-- Customize content with icons and text -->
                       <span *ngIf="standard.icon" nz-icon [nzType]="standard.icon"></span>
                       <span>{{ standard.name }}</span>
                     <span  *ngIf="standard.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

                     </div>
                   </nz-option>
                 </nz-select>
                </nz-select>

              </div>
              <div class="col-md-1">
                <button
                nz-button
                nz-popover
                nzPopoverTitle="Manage Saved View"
                nzPopoverPlacement="top"
                nz-button class="border-0" (click)="openStandardViewFilterComponent()">
                  <span nz-popover class="text-primary text-dark fw-bold fs-5" nz-icon nzType="ellipsis"
                    nzTheme="outline"></span>
                </button>
              </div>
              <div class="col-md-2">
                <div class="mt-2" *ngIf="selectedFilter === 1">
                  <p>Your filter has unsaved changes.</p>
                </div>
                <div class="mt-2" *ngIf="selectedFilter === 0">
                  <p>Your filter has unsaved changes.</p>
                </div>
              </div>

              <div class="col-md-3">
                <button (click)="openStandardViewFilterComponent('saveAs')" type="button" nz-button nzType="default" class="me-2" style="font-size: 12px;"
                *ngIf="selectedFilter === 1">Save As</button>

                <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;"
                *ngIf="selectedFilter === 1" type="button">Reset</button>

                <button (click)="saveEditStandardView()"  nz-button nzType="primary" type="button" class="me-2" style="font-size: 12px;"
                *ngIf="selectedFilter === 0">Save</button>

                <button (click)="openStandardViewFilterComponent('saveAs')" nz-button nzType="default" class="me-2" style="font-size: 12px;"
                *ngIf="selectedFilter === 0" type="button">Save As</button>

                <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;"
                *ngIf="selectedFilter === 0" type="button">Reset</button>

              </div>
            </div>
          </ng-container>

          <div class="col d-flex mt-3">


          </div>

        </div>
      </div>
    </div>
  </div>

</nz-footer>


<!-- Purchase Order Footer -->



      </div>
      </nz-content>

    </nz-layout>
    </nz-layout>
