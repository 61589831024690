


<!-- card 1 -->
 <nz-card nzBorderless="true" nzTitle="Cost Group" [nzExtra]="extraTemplate">
    <!-- table inner card -->
    <nz-table #rowSelectionTable nzShowSizeChanger [nzData]="listOfData" 
         (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
        #middleTable nzSize="small" class="wh-100" nzFrontPagination="false">
        <thead>
            <tr class="fw-bold">
                <th nzLeft nzWidth="50px" [(nzChecked)]="checked"
                    [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)"></th>



            <th *ngFor="let column of displayedColumns">{{ column }}</th>


            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let data of rowSelectionTable.data">
                <td nzWidth="50px" nzLeft [nzChecked]="setOfCheckedId.has(data.id)"
                    (nzCheckedChange)="onItemChecked(data.id, $event)"></td>

                <td nzWidth="150px"  *ngIf="displayedColumns.includes('Cost Group')">{{ data.name}}</td>
                <td nzWidth="120px" *ngIf="displayedColumns.includes('Cost Items')">{{ data.age }}</td>
                <td nzWidth="200px" *ngIf="displayedColumns.includes('Description')">{{ data.name }}</td>
                <td nzWidth="120px" *ngIf="displayedColumns.includes('builder Cost')">{{ data.address }}</td>
                <td nzWidth="120px"  *ngIf="displayedColumns.includes('Markup')">{{ data.name }}</td>
                <td nzWidth="120px" *ngIf="displayedColumns.includes('Owner Price')">{{ data.age }}</td>
                <td nzWidth="120px" *ngIf="displayedColumns.includes('Attachments')">{{ data.age }}</td>
                
            </tr>
        </tbody>
       
    </nz-table>
    <!-- table inner card -->
</nz-card>

<div class="row">
    <div class="col-7">
        <label>this is here every time </label>
    </div>
</div>

  
<ng-template style="margin-left: 15px;" #extraTemplate>
    <nz-space>
        <button nz-button nzType="default" class="ms-2" >
            <!-- (click)="open()" -->
            <i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter</button>
        <button nz-button nzType="default" class="ms-2" (click)="newCostGroupOpenMethod()">New Cost Group</button>
        <!-- (click)="openCostItem()" -->
    </nz-space>
</ng-template>
<!-- card 1 -->