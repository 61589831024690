<nz-drawer
  [nzClosable]="true"
  [nzVisible]="filterVisible"
  nzPlacement="right"
  [nzBodyStyle]="{'overflow-x': 'hidden'}"
  [nzTitle]="drawerTitle"
  [nzExtra]="extra"
  [nzWidth]="290"
  (nzOnClose)="close()"
  nzCloseIcon="false"
>
<ng-template #extra>
  <button button nz-button nzType="text" class="same-but" (click)="close()"><span class="fs-6 mt-2" nz-icon nzType="close" nzTheme="outline"></span></button>
</ng-template>
  <ng-container *nzDrawerContent>
    <ng-container [ngSwitch]="activeFilter">
      <internal-user-filter-field [pageId]="pageId" *ngSwitchCase="INTERNAL_USER"></internal-user-filter-field>
      <app-financial-tenders-filter-field [pageId]="pageId" *ngSwitchCase="Financial_Tenders"></app-financial-tenders-filter-field>
      <app-financial-bills-filter-field [pageId]="pageId" *ngSwitchCase="Financial_Bills"></app-financial-bills-filter-field>
      <sub-vender-filter-field *ngSwitchCase="SUB_VENDOR" [pageId]="pageId"></sub-vender-filter-field>
      <customer-contact-filter-field [pageId]="pageId" *ngSwitchCase="CUSTOMER_CONTACT"></customer-contact-filter-field>
      <app-messaging-notification-history-filter-filed [pageId]="pageId" *ngSwitchCase="Messaging_Notification_History"></app-messaging-notification-history-filter-filed>
      <app-lead-opportunities-activity-templates-filter-field [pageId]="pageId" *ngSwitchCase="LEAD_OPPR_ACT_TEMPL"></app-lead-opportunities-activity-templates-filter-field>
      <app-comments-viewby-conversation-filter-field  [pageId]="pageId" *ngSwitchCase="COMMENT_V_BY_CONVERSATION"></app-comments-viewby-conversation-filter-field>
      <!-- <app-jobs-list-filter-field *ngSwitchCase="'customer'"></app-jobs-list-filter-field> -->
      <!-- <app-jobs-map-filter-field *ngSwitchCase="'customer'"></app-jobs-map-filter-field> -->
      <app-lead-opportunities-activity-view-filter-field [pageId]="pageId"  *ngSwitchCase="LEAD_OPPR_ACT_VIEW"></app-lead-opportunities-activity-view-filter-field>
      <app-lead-opportunities-lead-proposals-filter-field [pageId]="pageId"  *ngSwitchCase="LEAD_OPPR_LEAD_PROP"></app-lead-opportunities-lead-proposals-filter-field>
      <app-lead-opportunities-list-view-filter-field [pageId]="pageId"  *ngSwitchCase="LEAD_OPPR_LIST_VIEW"></app-lead-opportunities-list-view-filter-field>
      <app-lead-opportunities-map-filter-field [pageId]="pageId"  *ngSwitchCase="LEAD_OPPR_MAP"></app-lead-opportunities-map-filter-field>
      <lead-opportunities-proposal-templates-filter-field [pageId]="pageId" *ngSwitchCase="LEAD_OPPR_PROP_TEMP"></lead-opportunities-proposal-templates-filter-field>
      <app-project-management-selection-card-view-filter-field [pageId]="pageId" *ngSwitchCase="PROJECT_MANG_SELEC_CARD_V"></app-project-management-selection-card-view-filter-field>
      <app-project-management-selection-list-filter-field [pageId]="pageId" *ngSwitchCase="PROJECT_MANG_SELEC_LIST"></app-project-management-selection-list-filter-field>
      <app-project-management-site-diaries-daily-log-filter-field [pageId]="pageId" *ngSwitchCase="PROJECT_M_ANG_SITEDIAR_DAILY_L"></app-project-management-site-diaries-daily-log-filter-field>
      <app-project-management-variation-filter-field [pageId]="pageId"  *ngSwitchCase="PROJECT_MANG_VARIATION"></app-project-management-variation-filter-field>
      <app-project-mangament-schedule-baseline-filter-field [pageId]="pageId" *ngSwitchCase="PROJECT_MANG_SCHED_BASELINE"></app-project-mangament-schedule-baseline-filter-field>
      <app-project-mangament-schedule-filter-field [pageId]="pageId" *ngSwitchCase="PROJECT_MANG_SCHEDULE"></app-project-mangament-schedule-filter-field>
      <messaging-messages-filter-field [pageId]="pageId" *ngSwitchCase="MESSAGES"></messaging-messages-filter-field>
      <app-lead-opportunity-activity-calander-filter-field [pageId]="pageId" *ngSwitchCase="LEAD_OPPR_ACT_CAL"></app-lead-opportunity-activity-calander-filter-field>
      <app-jobs-list-filter-field [pageId]="pageId" *ngSwitchCase="JOBS_JOBS_LIST"></app-jobs-list-filter-field>
      <app-job-summary-filter-field [pageId]="pageId" *ngSwitchCase="Job_Summary"></app-job-summary-filter-field>
      <app-messaging-surveys-individual-filter-field [pageId]="pageId" *ngSwitchCase="Surveys_Individual"></app-messaging-surveys-individual-filter-field>
      <app-project-manegment-to-dos-filter-field [pageId]="pageId" *ngSwitchCase="PROJECT_MANG_Todos"></app-project-manegment-to-dos-filter-field>
      <app-project-mangament-schedule-workday-exception-filter-field  [pageId]="pageId" *ngSwitchCase="Project_Man_WorkDay_Excep"></app-project-mangament-schedule-workday-exception-filter-field>
      <app-project-management-warranty-filter-field [pageId]="pageId" *ngSwitchCase="PROJECT_MANG_WARRANTY"></app-project-management-warranty-filter-field>
      <app-project-management-time-clock-filter-field [pageId]="pageId" *ngSwitchCase="PROJECT_MANG_TIMECLOCK"></app-project-management-time-clock-filter-field>
      <app-project-management-selection-hirechy-filter-field [pageId]="pageId" *ngSwitchCase="PROJECT_MANG_SELEC_HIERARCHY"></app-project-management-selection-hirechy-filter-field>
      <app-project-management-allowance-assinged-filter-field [pageId]="pageId" *ngSwitchCase="PROJECT_MANG_SELEC_ALLOWA_ASSIGN"></app-project-management-allowance-assinged-filter-field>
      <app-project-management-allowance-unassinged-filter-field [pageId]="pageId" *ngSwitchCase="PROJECT_MANG_SELEC_ALLOWA_UNASSIGN"> </app-project-management-allowance-unassinged-filter-field>
      <app-financial-bills-filter-field [pageId]="pageId" *ngSwitchCase="MESSAGES"></app-financial-bills-filter-field>
      <app-financial-purchase-order-filter-field [pageId]="pageId" *ngSwitchCase="Financial_PurchaseOrder">  </app-financial-purchase-order-filter-field>
      <app-financial-owner-invoice-filter-field *ngSwitchCase="Owner_Invoice"></app-financial-owner-invoice-filter-field>
      <app-financial-owner-invoice-payment-filter-field *ngSwitchCase="Owner_Invoice"></app-financial-owner-invoice-payment-filter-field>
      <app-financial-owner-invoice-payment-credit-memos-filter-field [pageId]="pageId" *ngSwitchCase="Owner_Invoice"></app-financial-owner-invoice-payment-credit-memos-filter-field>
      <app-financial-cost-inbox-filter-field [pageId]="pageId" *ngSwitchCase="Cost_Inbox"></app-financial-cost-inbox-filter-field>
      <app-messaging-rfis-filter-field [pageId]="pageId" *ngSwitchCase="Messaging_RFIs"></app-messaging-rfis-filter-field>
      <!-- Add other cases as needed -->
    </ng-container>
  </ng-container>
</nz-drawer>
