<nz-modal
    nzWidth="96%"
    [(nzVisible)]="dailylog"
    [nzTitle]="dailylog1"
    [nzFooter]="dailylogs2"
    (nzOnCancel)="closeToDos()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '72vh', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
    <ng-template #dailylog1> 
        <div class="row p-0 m-0">
          <h2 class="p-0 m-0" nz-typography>Daily Logs</h2>
       </div>
    </ng-template>
    <div  class="cantent" *nzModalContent>


<div class="row famly-inn gx-3">
    <div class="col-12">
        <div class="card border-0 mt-0">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Lead Activity Calendar Settings</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="row mt-1">
                        <div class="col-9">
                          <label [(ngModel)]="formFieldValues['isStampLocation']" name="isStampLocation" nz-checkbox nz-checkbox [(ngModel)]="checked2">
                          <!-- <label    [(ngModel)]="formFieldValues['isStampLocation']" name="isStampLocation" for="exampleCheck2" class="pira-label  me-2">Stamp Location</label> -->
                          <label  for="exampleCheck2" class="pira-label  me-2">Stamp Location</label>
                        </label>
                            <i class="bi bi-info-circle-fill text-secondary me-2" nz-popover [nzPopoverContent]="Summary" nzPopoverPlacement="top"></i>
                            <ng-template #Summary>
                              <div class="rounded-2">
                                <p style="height: 30px; width: 210px; font-size: 13px;">
                                    New Daily Logs will include a GPS pin for the location they were created at.
                                </p>
                              </div>
                            </ng-template>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-md-12">
                            <label class="sub-text me-2">Default Daily Log Notes</label>
                            <textarea [(ngModel)]="formFieldValues['notes']" name="notes"
                                class="form-control formField"
                                style="height: 54px; resize: none;"
                                (input)="autoAdjustTextAreaHeight($event)"
                            ></textarea>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Weather</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="row mt-1">
                        <div class="col-9">
                          <!-- <label class="checkbox bounce">
                            <input type="checkbox" id="exampleCheck2">
                            <svg viewBox="0 0 21 21" fill="#fff">
                              <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                            </svg> 
                          </label> -->
                          <label  [(ngModel)]="formFieldValues['isIncludeWeatherConditionsDefault']" name="isIncludeWeatherConditionsDefault" nz-checkbox [(ngModel)]="checked">
                          <label  for="exampleCheck2" class="pira-label  me-2">Include Weather Conditions (Default)</label>
                        </label>
                        </div>
                      </div>
                    <div class="row mt-3">
                        <div class="col-9">
                          <!-- <label class="checkbox bounce">
                            <input type="checkbox" id="exampleCheck2">
                            <svg viewBox="0 0 21 21" fill="#fff">
                              <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                            </svg>
                          </label> -->
                          <label [(ngModel)]="formFieldValues['isIncludeWeatherConditionNotesDefault']" name="isIncludeWeatherConditionNotesDefault" nz-checkbox [(ngModel)]="checked1">
                          <label  for="exampleCheck2" class="pira-label  me-2">
                            Include Weather Condition Notes (Default)</label></label>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Default Daily Log Share Settings
                            <i class="bi bi-info-circle-fill text-secondary me-2" nz-popover [nzPopoverContent]="Daily" nzPopoverPlacement="top"></i>
                            <ng-template #Daily>
                              <div class="rounded-2">
                                <p style="height: 130px; width: 210px; font-size: 13px;">
                                    Must default to share with at least 1 type of user. These presets will be applied as the default setting for all new Daily Logs. Depending on user permissions, these settings can be adjusted manually for each Daily Log.
                                </p>
                              </div>
                            </ng-template>

                        </span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-4">
                        <div class="row">
                            <div class="col-12">
                                <div class="ant-row ant-row-space-between ant-row-middle BTRow-xs ItemRow" style="row-gap: 0px;">
                                    <div class="ant-col text-center ant-col-xs-6 ant-col-xs-offset-12 ant-col-md-4 ant-col-md-offset-16">
                                        <span class="ant-typography"><strong>Share</strong></span>
                                    </div>
                                    <div class="ant-col text-center ant-col-xs-6 ant-col-md-4">
                                        <span class="ant-typography"><strong>Notify</strong></span>
                                    </div>
                                </div>
                            <div class="ant-row ant-row-space-between ant-row-middle BTRow-xs ItemRow mt-3" style="margin-left: -4px; background-color: #f1f4fa; padding: 7px; margin-right: -4px; row-gap: 0px;">
                                <div class="ant-col ant-col-xs-12 ant-col-md-16" style="padding-left: 4px; padding-right: 4px;">
                                <img src="assets/dail.png" alt="" width="15" class="Icon me-1">Internal Users
                                </div>
                                    <div class="ant-col text-center ant-col-xs-6 ant-col-md-4" style="padding-left: 4px; padding-right: 4px;">
                                      <label nz-checkbox nzDisabled [ngModel]="true" ></label>
                                    </div>
                                    <div class="ant-col text-center ant-col-xs-6 ant-col-md-4" style="padding-left: 4px; padding-right: 4px;">
                                      <label nz-checkbox [(ngModel)]="formFieldValues.createDefaultDailyLogShareParameterRequests['']" 
                                      name="isNotify" 
                                      (ngModelChange)="onNotifyCheckboxChange($event)">
                                      </label>
                                  </div>
                                  
                            </div>

                            <div *ngFor="let row of formFieldValues.createDefaultDailyLogShareParameterRequests; let i = index" class="ant-row ant-row-space-between ant-row-middle BTRow-xs ItemRow mt-0" style="margin-left: -4px; background-color: #fff; padding: 7px; margin-right: -4px; row-gap: 0px;">
                              <div class="ant-col ant-col-xs-12 ant-col-md-16" style="padding-left: 4px; padding-right: 4px;">
                                  <img src="assets/daily.log.haimer.png" alt="" width="15" class="Icon me-1">Subs/Vendors
                              </div>
                              <div class="ant-col text-center ant-col-xs-6 ant-col-md-4" style="padding-left: 4px; padding-right: 4px;">
                                  <label nz-checkbox [(ngModel)]="row.isShare" name="isShare{{i}}"></label>
                              </div>
                              <div class="ant-col text-center ant-col-xs-6 ant-col-md-4" style="padding-left: 4px; padding-right: 4px;">
                                  <label nz-checkbox [(ngModel)]="row.isNotify" name="isNotify{{i}}" (change)="onIsNotifyChange($event, row)"></label>
                              </div>
                          </div>
                          
                          
                          <div class="ant-row ant-row-space-between ant-row-middle BTRow-xs ItemRow mt-0" style="margin-left: -4px; background-color: #f1f4fa; padding: 7px; margin-right: -4px; row-gap: 0px;">
                            <div class="ant-col ant-col-xs-12 ant-col-md-16" style="padding-left: 4px; padding-right: 4px;">
                                <img src="assets/daily.log.house.png" alt="" width="15" class="Icon me-1">Owners
                            </div>
                            <div class="ant-col text-center ant-col-xs-6 ant-col-md-4" style="padding-left: 4px; padding-right: 4px;">
                                <label nz-checkbox [(ngModel)]="formFieldValues.createDefaultDailyLogShareParameterRequests['isShare']" name="isShare"></label>
                            </div>
                            <div class="ant-col text-center ant-col-xs-6 ant-col-md-4" style="padding-left: 4px; padding-right: 4px;">
                                <label nz-checkbox [(ngModel)]="formFieldValues.createDefaultDailyLogShareParameterRequests['isNotify']" name="isNotify" (ngModelChange)="updateShareCheckbox($event)"></label>
                            </div>
                        </div>
                        
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card border-0 mt-3">
          <div class="card-head border-bottom">
              <div class="row mb-0 p-2 d-flex align-items-center">
                <div class="col">
                  <span class="fw-medium fs-6 ms-2">Daily Logs Custom Fields</span>
                </div>
                <div class="col text-end">
                  <button  (click)="CustomFieldInfo()"   data-testid="saveDraft" aria-disabled="false" type="button" class="me-2 ant-btn13 ant-btn-default">
                      <span>Add New Field</span>
                  </button>
                </div>    
              </div>
            </div>
          <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-12 d-flex alighn-item-center justify-content-center">
                  <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g class="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" class="ant-empty-img-simple-path"></path></g></g></svg>
                </div>
              </div>
              <div class="row">
                <div class="col-12 d-flex alighn-item-center justify-content-center">
              <span class="ant-empty-normal">No Data</span>
                </div>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
</div>
</div>
</nz-modal> 
<ng-template #dailylogs2>
  <button (click)="saveDialyLog()" nz-button nzType="primary" class="rounded-1 me-0">Save</button>
</ng-template>



<nz-modal
nzWidth="40%"
[(nzVisible)]="customfieldinfo"
[nzTitle]="modalTitle1"
[nzFooter]="modalFooter1"
(nzOnCancel)="handleCancel2()"
[nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '440px', 'overflow-y': 'auto',}"
[nzStyle]="{ top: '15px' }">
  <ng-template #modalTitle1>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0 fw-medium">Daily Logs Custom Field
      </h1>
    </div>
  </ng-template>

  <div class="content" *nzModalContent>
      <div class="card border-0">
          <div class="card-head border-bottom">
              <div class="row mb-1 p-2 d-flex align-items-center">
                <div class="col">
                  <span class="fw-medium fs-6 ms-2"> Custom Field Info</span>
                </div>
      
              </div>
            </div>
                    <div class="card-body">
                      <div class="row">
                          <div class="col">
                          <label class="sub-text">
                            Label
                              <span class="text-danger fw-bolder">*</span>
                          </label>
                          <input
                          formControlName="name"
                          type="text"
                          class="form-control formField">
                      </div>
                      </div>
                      <div class="row">
                          <div class="col">
                          <label class="sub-text">
                              Data Type
                              <span class="text-danger fw-bolder">*</span>
                          </label>
                          <nz-select nzShowSearch formControlName="jobInformationTypeParameterId">
                              <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                              <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                              <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                            </nz-select>
                            </div>
                      </div>
                      <div class="row">
                          <div class="col">
                          <label class="sub-text">
                              Tool Tip Text
                          </label>
                          <textarea
                          nz-input [nzAutosize]="{ minRows: 2, maxRows: 6 }"
                          formControlName="lotInformation"
                          class="form-control formField"
                          style="height: 54px; resize: none;"
                        ></textarea>
                              </div>
                      </div>
                      <div class="row mt-2">
                          <div class=" col-9 d-flex align-items-center">
                      <label class="checkbox bounce">
                          <input type="checkbox" id="exampleCheck8">
                      <svg viewBox="0 0 21 21" fill="#fff">
                          <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                      </svg>
                          </label>
                      <label for="exampleCheck8" class="pira-label ms-2 me-2">Required?
                      </label>
                          </div>
                      </div>
                      <div class="row mt-2">
                          <div class=" col-9 d-flex align-items-center">
                      <label class="checkbox bounce">
                          <input type="checkbox" id="exampleCheck9">
                      <svg viewBox="0 0 21 21" fill="#fff">
                          <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                      </svg>
                          </label>
                      <label for="exampleCheck9" class="pira-label ms-2 me-2">
                          Include In My Filters
                      </label>
                          </div>
                      </div>
                      <div class="row mt-3" style="margin-bottom: 30px;">
                          <div class="col-12">
                              <label class="sub-text">Display Order 
                                  <span class="text-danger fw-bolder">*</span>
                              </label>
                              <nz-input-number
                              [nzMin]="1"
                              [nzMax]="10"
                              [nzStep]="1"
                              type="number" 
                            ></nz-input-number>
                          </div>
                      </div>
                      <div
                      class="ant-divider ant-divider-horizontal mt-3"
                      role="separator"
                  ></div>
                      <div class="row mt-4">
                          <div class=" col-9 d-flex align-items-center">
                      <label class="checkbox bounce">
                          <input type="checkbox" id="exampleCheck10">
                      <svg viewBox="0 0 21 21" fill="#fff">
                          <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                      </svg>
                          </label>
                      <label for="exampleCheck10" class="pira-label ms-2 me-2">Show Owners?
                      </label>
                          </div>
                      </div>
                      <div
                      class="ant-divider ant-divider-horizontal mt-3"
                      role="separator"
                  ></div>
                      <div class="row mt-4">
                          <div class=" col-9 d-flex align-items-center">
                      <label class="checkbox bounce">
                          <input type="checkbox" id="exampleCheck10">
                      <svg viewBox="0 0 21 21" fill="#fff">
                          <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                      </svg>
                          </label>
                      <label for="exampleCheck10" class="pira-label ms-2 me-2">Allow permitted sub/vendor to view??
                      </label>
                          </div>
                      </div>
                    </div>
          </div>
</div>
  <ng-template #modalFooter1>
      <div class="mt-2 align-items-center">
          <button   data-testid="saveDraft" aria-disabled="false" type="button" class="me-2 ant-btn13 ant-btn-default" (click)="saveDialyLog()">
              Save
          </button>
      </div>

    </ng-template>
</nz-modal>
