import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { ScheduleItemParameter, ScheduleItemParameterResponse } from 'src/Models/Project-Management/projectManagement';
import { ResponseModel, ResponseModelArray, TablePageResponse } from 'src/Models/responseMessage.model';
import { CreateLookupFilterRequest, FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';
import { ScheduleSetup, ScheduleSetupResponse } from 'src/Models/CompanySettings/Schedule/PhaseSetup/Company-Settings-Schedule';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  private PROJECT_MANAGMENT_URL: string = environment.apiUrl + 'ScheduleItemParameter/';
  private Url_LookUpFilter: string = environment.apiUrl + 'LookupFilter/';
  private Company_Settings_Schedule_URL: string = environment.apiUrl + 'ScheduleSetup/';


  getScheduleData = new BehaviorSubject<ResponseModel<TablePageResponse<ScheduleItemParameterResponse>>>(null);
  $getScheduleItemParameters = this.getScheduleData.asObservable();




  constructor(private http: HttpClient) {}

  postSchedule(jobInformationFormData: ScheduleItemParameter): Observable<ResponseModel<ScheduleItemParameterResponse>> {
    const apiUrl = `${this.PROJECT_MANAGMENT_URL}`;
    return this.http.post<any>(apiUrl, jobInformationFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }


  getData(): Observable<ResponseModelArray<ScheduleItemParameterResponse>> { 
    return this.http.get<ResponseModelArray<ScheduleItemParameterResponse>>(`${this.PROJECT_MANAGMENT_URL}getAll`);
  }


  getAllScheduleDataListViewyFilter(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.Url_LookUpFilter}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getScheduleData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }


  // Company Setting Methods

  postScheduleCompanySettings(jScheduleSetupFormData: ScheduleSetup): Observable<ResponseModel<ScheduleSetupResponse>> {
    const apiUrl = `${this.Company_Settings_Schedule_URL}`;
    return this.http.post<any>(apiUrl, jScheduleSetupFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
    
}


getDataByCompany(): Observable<ResponseModelArray<ScheduleSetupResponse>> { 
  return this.http.get<ResponseModelArray<ScheduleSetupResponse>>(`${this.PROJECT_MANAGMENT_URL}`);
}
getAllInternalUsersByFilter(filterSearchParameter: FilterSearchParameter) {
  const apiUrl = `${this.Url_LookUpFilter}GetAll-ByFilter`;
  return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
    (data) => {
      this.getScheduleData.next(data);
    },
    (error) => {
      console.error('Error fetching internal users:', error);
    }
  );
}
getAppliedFilterData(appliedFilter: CreateLookupFilterRequest[]) {
  const apiUrl = `${this.Url_LookUpFilter}GetAll-ByLookupFilter`;
  return this.http.post<any>(apiUrl, appliedFilter).subscribe(
    (data) => {
      this.getScheduleData.next(data);
    },
    (error) => {
      console.error('Error fetching internal users:', error);
    }
  );
}
}