<nz-modal
  nzWidth="54%"
  [(nzVisible)]="leadActivtyVisibility"
  [nzTitle]="LeadActivityTitle"
  [nzFooter]="LeadActivityFooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    height: '72vh',
    'max-height': '72vh',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '35px' }"
  (nzOnCancel)="leadActivityFromCancel()"
>
  <ng-template #LeadActivityTitle>
      <h5 class="p-0 m-0">{{ dataSetForLeadOp }}</h5>
    <!-- Edit Mode By Grid Start -->
    <!-- <div class="row" *ngIf="getDisplayTitle() === 'grid'">
      <h5 class="p-0 m-0">{{ activityResponse?.leadOpportunity?.title }}</h5>
    </div> -->
    <!-- Edit Mode By Grid End -->

    <!-- Edit Mode By Lead Opp Start -->
    <!-- <div class="row" *ngIf="getDisplayTitle() === 'Lead Opp'">
      <h5 class="p-0 m-0">{{ holdResponse?.leadData?.title }}</h5>
    </div> -->
    <!-- Edit Mode By Lead Opp End -->

    <!-- Edit Mode By Dialog box Start -->
    <!-- <div class="row" *ngIf="getDisplayTitle() === 'Dialog box'">
      <h5 class="p-0 m-0">{{ LeadOpportunityResponse?.title }}</h5>
    </div> -->
    <!-- Edit Mode By Dialog box End -->

    <div class="row" *ngIf="nameS">
      <h5 class="ListViewJobname p-0 m-0">{{ nameS }}</h5>
    </div>
    <ng-template #showDataSetForLeadOp>
      <div class="row">
        <h5 class="ListViewJobname p-0 m-0">{{ data?.data?.name }}</h5>

      </div>
    </ng-template>
    <div class="row">
      <h1 class="p-0 m-0 famly-inn">Lead Activity</h1>
    </div>
  </ng-template>
  <div class="content famly-inn" *nzModalContent>
    <div class="spinner-wrapper" *ngIf="isLoading">
      <nz-spin nzSimple [nzSpinning]="true" [nzSize]="'large'"></nz-spin>
    </div>
    <div class="card-container">
      <nz-alert
        *ngIf="isAttachmentQueued && !ismark"
        class="mb-2 mt-2"
        nzType="info"
        nzMessage="Queued For Send"
        nzShowIcon
      ></nz-alert>

      <nz-alert
        *ngIf="ismark"
        class="mb-2 mt-2"
        nzType="info"
        [nzMessage]="alertMessage"
        nzShowIcon
      ></nz-alert>

      <div *ngIf="alertMessageVisible && !ismark">
        <nz-alert
          class="mb-2 mt-2"
          nzType="info"
          nzMessage="Waiting To Receive Your Message..."
          nzShowIcon
        ></nz-alert>
      </div>

      <nz-tabset nzType="card" class="">
        <nz-tab *ngFor="let tab of tabs" [nzTitle]="leaAdctivityTabs(tab)">
          <ng-container *ngIf="tab === 1">
            <div class="card border-0 mt-0">
              <div class="card-head border-bottom">
                <div class="row">
                  <div class="col-6">
                    <h2 class="ms-3" nz-typography>Activity</h2>
                  </div>
                  <div class="col-6 text-end">
                    <ng-container>
                      <label
                        nz-checkbox
                        class="mt-1"
                        [(ngModel)]="formFieldValues.markComplete"
                        name="markComplete"
                        (ngModelChange)="toggleFields()"
                      >
                        <span>Mark Complete</span>
                      </label>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ng-container *ngIf="isMarkVisibility">
                      <h2 nz-typography>Schedule Follow-Up</h2>
                      <div class="row">
                        <div class="col-3">
                            <label class="sub-text famly-inn"
                            >Follow-Up Date</label
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <nz-date-picker
                          nzPlaceHolder=" "
                          nzSuffixIcon="false"
                          (ngModelChange)="onDateChange($event)"
                          [(ngModel)]="formFieldValues['followUpDate']"
                          name="followUpDate"
                        ></nz-date-picker>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <label class="sub-text famly-inn"
                            >Follow-Up Time</label
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <nz-time-picker
                          [(ngModel)]="formFieldValues['followUpTime']"
                          name="followUpTime"
                          nzFormat="HH:mm"
                          nzPlaceHolder=" "
                          [nzDisabled]="!formFieldValues['followUpDate']"
                        ></nz-time-picker>
                        </div>
                      </div>
                    </ng-container>
                    <div class="row mt-2">
                      <div class="col-12">
                        <span
                          class="color-circle"
                          [style.backgroundColor]="selectedColor"
                        ></span>
                      </div>
                    </div>
                    <div class="row iconset">
                      <div class="col-12">
                        <i
                          style="font-size: 30px; left: 38px"
                          [ngClass]="selectedDescription"
                        ></i>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <!-- Label and Description -->
                        <div class="row">
                          <div class="col-10">
                            <label
                              class="sub-text famly-inn"
                              for="activityTypeId"
                            >
                              Activity Type
                              <span class="text-danger">*</span>
                            </label>
                          </div>
                          <div class="col-2"></div>
                        </div>

                        <!-- Activity Type Display and Buttons -->
                        <div class="row d-flex align-items-center">
                          <div class="col-10 mr-0 pr-0">
                            <!-- Display Activity Type as text or dropdown based on activityResponse.MarkComplete -->
                            <ng-container
                              *ngIf="
                                !activityResponses?.MarkComplete;
                                else nonEditableFields
                              "
                            >
                              <nz-select
                                [(ngModel)]="formFieldValues['activityTypeId']"
                                name="activityTypeId"
                                [style.width.%]="100"
                                (ngModelChange)="changeActivityType($event)"
                              >
                                <nz-option
                                  *ngFor="let option of leadActivityType"
                                  [nzLabel]="option.name"
                                  [nzValue]="option.id"
                                ></nz-option>
                              </nz-select>
                            </ng-container>
                            <ng-template #nonEditableFields>
                              <div>
                                {{
                                  getActivityTypeLabel(
                                    formFieldValues["activityTypeId"]
                                  ) || "--None--"
                                }}
                              </div>
                            </ng-template>
                          </div>

                          <ng-container *ngIf="!activityResponses?.MarkComplete">
                            <div class="col-2 d-flex">
                              <!-- Buttons to Add and Edit Activity Types -->
                              <button
                                nz-button
                                nzType="text"
                                (click)="addNewLeadActivityModal()"
                              >
                                <a>Add</a>
                              </button>
                              <button
                                nz-button
                                nzType="text"
                                (click)="editNewLeadActivityModal()"
                              >
                                <a>Edit</a>
                              </button>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text famly-inn">Color</label>
                        <div class="input-group">
                          <nz-select
                            nzShowSearch
                            [nzCustomTemplate]="defaultTemplate"
                            [(ngModel)]="formFieldValues['colorId']"
                            name="colorId"
                            [style.width.%]="100"
                            (ngModelChange)="updateSelectedColor($event)"
                            [disabled]="test1234"
                          >
                            <nz-option
                              nzCustomContent
                              *ngFor="let option of colors"
                              [nzLabel]="option.name"
                              [nzValue]="option.id"
                            >
                              <span
                                class="color-circle-drop"
                                [style.backgroundColor]="option.colorCollection"
                              ></span>
                              <ng-container>
                                <span class="color-name">{{
                                  option.name
                                }}</span>
                              </ng-container>
                            </nz-option>
                          </nz-select>
                          <ng-template #defaultTemplate let-selected>
                            <div class="color-item d-flex align-items-center">
                              <span
                                class="color-circle-drop"
                                [style.backgroundColor]="selectedColor"
                              ></span>
                              <span class="color-name">{{
                                selected.nzLabel
                              }}</span>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text"
                          >Activity Date<span class="text-danger text-bolder"
                            >*</span
                          ></label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-3">
                        <ng-container
                          *ngIf="
                          activityResponses?.MarkComplete;
                            else editableDatePicker
                          "
                        >
                          <!-- Display read-only date when MarkComplete is true -->
                          <div class="read-only-date">
                            <span>{{
                              formFieldValues["activityOn"]
                                | date : "MMM d, yyyy"
                            }}</span>
                          </div>
                        </ng-container>
                        <ng-template #editableDatePicker>
                          <!-- Display editable date picker when MarkComplete is false -->
                          <nz-date-picker
                            nzFormat="MMM d, yyyy"
                            nzPlaceHolder=" "
                            nzSuffixIcon="false"
                            [(ngModel)]="formFieldValues['activityOn']"
                            name="activityOn"
                            required
                          ></nz-date-picker>
                        </ng-template>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-3">
                        <label class="sub-text"
                          >Start Time<span class="text-danger text-bolder"
                            >*</span
                          ></label
                        >
                      </div>
                      <div class="col-3">
                        <label class="sub-text">End Time</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-3">
                        <ng-container *ngIf="activityResponses?.MarkComplete">
                          <!-- Display read-only time when MarkComplete is true -->
                          <div class="read-only-time">
                            <span>{{
                              formFieldValues["startTime"] | date : "h:mm a"
                            }}</span>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!activityResponses?.MarkComplete">
                          <!-- Display editable time picker when MarkComplete is false -->
                          <nz-time-picker
                            [(ngModel)]="formFieldValues['startTime']"
                            name="startTime"
                            nzFormat="h:mm a"
                            [nzUse12Hours]="true"
                            nzPlaceHolder=" "
                            required
                          ></nz-time-picker>

                        </ng-container>
                      </div>

                      <div class="col-3">
                        <ng-container *ngIf="activityResponses?.MarkComplete">
                          <!-- Display read-only time when MarkComplete is true -->
                          <div class="read-only-time">
                            <span>{{
                              formFieldValues["endTime"] | date : "h:mm a"
                            }}</span>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!activityResponses?.MarkComplete">
                          <!-- Display editable time picker when MarkComplete is false -->
                          <nz-time-picker
                            [(ngModel)]="formFieldValues['endTime']"
                            name="endTime"
                            nzFormat="h:mm a"
                            [nzUse12Hours]="true"
                            nzPlaceHolder=" "
                            required
                          ></nz-time-picker>
                        </ng-container>
                      </div>
                    </div>

                    <!-- <div class="row">
                      <div class="col-12">
                        <label class="sub-text"
                          >Reminder<i
                            class="bi bi-info-circle-fill text-secondary ms-1"
                            nz-popover
                            [nzPopoverContent]="RemindercontentTemplate"
                            nzPopoverPlacement="left"
                          ></i>
                          <ng-template #RemindercontentTemplate>
                            <div class="">
                              <p style="height: auto; width: 180px">
                                The Reminder, when selected, will alert you
                                prior to your activity. Alerts will be sent via
                                email or text.
                              </p>
                            </div>
                          </ng-template>
                        </label>
                        <nz-select
                          nzShowSearch
                          [lang]="true"
                          [(ngModel)]="formFieldValues['reminderId']"
                          name="reminderId"
                          nzAutoFocus="true"
                          nzPlaceHolder="--None--"
                        >
                          <nz-option
                            *ngFor="let option of reminder"
                            [nzLabel]="option.name"
                            [nzValue]="option.id"
                          ></nz-option>
                        </nz-select>
                      </div>
                    </div> -->

                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text">
                          Reminder
                          <i
                            class="bi bi-info-circle-fill text-secondary ms-1"
                            nz-popover
                            [nzPopoverContent]="RemindercontentTemplate"
                            nzPopoverPlacement="left"
                          ></i>
                          <ng-template #RemindercontentTemplate>
                            <div class="">
                              <p class="popo ms-3 mt-1">
                                The Reminder, when selected, will alert you
                                prior to your activity. Alerts will be sent via
                                email or text.
                              </p>
                            </div>
                          </ng-template>
                        </label>
                        <ng-container
                          *ngIf="
                          activityResponses?.MarkComplete;
                            else editableReminder
                          "
                        >
                          <!-- Display reminder as a tag when MarkComplete is true -->
                          <div class="ant-space-item">
                            <span class="ant-tag">
                              {{
                                getReminderLabel(
                                  formFieldValues["reminderId"]
                                ) || "--None--"
                              }}
                            </span>
                          </div>
                        </ng-container>
                        <ng-template #editableReminder>
                          <!-- Display nz-select when MarkComplete is false -->
                          <nz-select
                            nzShowSearch
                            [lang]="true"
                            [(ngModel)]="formFieldValues['reminderId']"
                            name="reminderId"
                            nzAutoFocus="true"
                            nzPlaceHolder="--None--"
                          >
                            <nz-option
                              *ngFor="let option of reminder"
                              [nzLabel]="option.name"
                              [nzValue]="option.id"
                            ></nz-option>
                          </nz-select>
                        </ng-template>
                      </div>
                    </div>

                    <!-- <div class="row">
                      <div class="col-12">
                        <label class="sub-text famly-inn"
                          >Assigned User
                          <span class="text-danger">*</span></label
                        >
                        <nz-select
                          nzShowSearch
                          [lang]="true"
                          [(ngModel)]="formFieldValues['assignedUserId']"
                          name="assignedUserId"
                          nzAutoFocus="true"
                          nzPlaceHolder="--None--"
                          required
                        >
                          <nz-option
                            *ngFor="let option of assignedUser"
                            [nzLabel]="option.fullName"
                            [nzValue]="option.id"
                          ></nz-option>
                        </nz-select>
                      </div>
                    </div> -->

                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text famly-inn">Assigned User <span class="text-danger">*</span></label>
                        <div class="input-group">
                          <nz-select
                          nzShowSearch
                          nz-popover [nzPopoverTitle]="null" [nzPopoverContent]="customContent"
                          [nzCustomTemplate]="initialsavtar"
                          [(ngModel)]="formFieldValues['assignedUserId']"
                          name="assignedUserId"
                          [style.width.%]="100"
                          (ngModelChange)="onUserChanges($event)"
                          nzPlaceHolder="Select a user"
                        >
                        <ng-container *ngIf="assignedUser && assignedUser.length > 0">
                          <!-- Group Label for Internal User -->
                          <p class="group-label">Internal User</p>

                          <nz-option
                            *ngFor="let option of assignedUser"
                            [nzLabel]="option.fullName"
                            [nzValue]="option.id"
                            nzCustomContent
                          >
                            <span class="initials-circle">
                              {{ getInitias(option) }}
                            </span>
                            <span class="user-name">{{ option.fullName }}</span>
                          </nz-option>

                        </ng-container>
                        </nz-select>

                        <ng-template #initialsavtar let-selected>
                          <div class="color-item d-flex align-items-center">
                            <span class="initials-circle">
                              <img
                                *ngIf="selected.nzValue?.avatarUrl; else initialsTemplate"
                                [src]="selected.nzValue.avatarUrl"
                                alt="{{ selected.nzLabel }}"
                                class="avatar-img"
                              />
                              <ng-template #initialsTemplate>
                                {{ getInitias(selected.nzLabel) }}
                              </ng-template>
                            </span>
                            <span class="color-name">{{ selected.nzLabel }}</span>
                          </div>

                        </ng-template>
                        </div>
                      </div>
                    </div>

                    <ng-template #customContent>
                      <div *ngIf="selectedUser" class="custom-popover-content">
                        <div
                        nz-row
                        style="
                          width: 400px;
                          padding: 10px;
                          padding-bottom: 0px;
                          height: 80px;
                          border-radius: 0px;
                        "
                      >
                        <nz-space>
                          <div *nzSpaceItem nz-col nzSpan="4">
                            <nz-avatar
                              [nzSize]="64"
                              style="
                                background-color: #ffb9a8;
                                font-size: 24px;
                                font-weight: medium;
                                color: #8b1439;
                                top: 3px;
                              "
                              [nzText]="selectedUser?.Initials"
                            ></nz-avatar>
                          </div>
                          <div *nzSpaceItem nz-col nzSpan="20" style="left: 10px; top: 5px">
                            <div class="row">
                              <div class="col">
                                <h2 class="popoverheadSet">
                                  {{ selectedUser?.fullName }}
                                </h2>
                                <div class="row">
                                  <div class="col d-flex">
                                    <i class="bi bi-envelope me-2"></i>
                                    <p>
                                      <a class="text-primary">
                                        {{ selectedUser?.Email }}
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </nz-space>
                      </div>
                      </div>
                      <div class="row ps-3 pb-3">
                        <div class="col">
                          <button
                            class="p-auto m-auto"
                            nz-button
                            nzType="text"
                            nzSize="small"
                            nz-popover
                            [nzPopoverContent]="ChatTemplate"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1.5em"
                              height="1.5em"
                              viewBox="0 0 32 32"
                              fill="currentColor"
                              aria-hidden="true"
                              focusable="false"
                            >
                              <path
                                fill="currentColor"
                                fill-rule="evenodd"
                                d="M5.64 5.5a10 10 0 0 1 15.34 3.98 10 10 0 0 1 7.73 14.44l-.1.2.77 2.72c.16.56.03 1.16-.34 1.6l-.1.12c-.42.4-1 .58-1.57.48l-.15-.04-2.71-.77-.2.1a10 10 0 0 1-7.62.6l-.32-.12a10 10 0 0 1-5.78-5.47 9.95 9.95 0 0 1-3.3-1l-.22-.1-2.7.76a1.8 1.8 0 0 1-1.47-.23l-.14-.1-.12-.1c-.4-.43-.58-1-.48-1.58l.04-.15.77-2.7-.06-.13A10 10 0 0 1 5.18 5.88l.23-.2.22-.17h.01V5.5Zm15.97 6.1v.05a9.98 9.98 0 0 1-8.8 11.7 8 8 0 0 0 11.05 2.93 1 1 0 0 1 .65-.13l.14.03 2.67.76-.76-2.67a1 1 0 0 1 .04-.66l.06-.13a8 8 0 0 0-5.05-11.87ZM6.88 7.1a8 8 0 1 1 1.03 13.3l-.23-.14-.12-.06-.12-.04a1.01 1.01 0 0 0-.53 0l-2.67.77.76-2.67.03-.14a1 1 0 0 0-.13-.65 8 8 0 0 1 1.97-10.4l.01.03Z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </button>
                          <ng-template #ChatTemplate>
                            <p style="height: auto; padding: 5px">Send Message</p>
                          </ng-template>
                  
                          <button
                            class="p-auto m-auto"
                            nz-button
                            nzType="text"
                            nzSize="small"
                            nz-popover
                            [nzPopoverContent]="UserTemplate"
                          >
                            <svg
                              width="1.5em"
                              height="1.5em"
                              viewBox="0 0 32 32"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                              focusable="false"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M25 12C25 7.02944 20.9705 3 16 3C11.0294 3 6.99999 7.02944 6.99999 12C6.99999 15.2833 8.75811 18.1559 11.3834 19.7276C7.90068 20.8541 4.89273 23.2358 3.00773 26.4986C2.73145 26.9768 2.89515 27.5884 3.37336 27.8647C3.85157 28.141 4.46321 27.9773 4.73949 27.4991C7.06305 23.4772 11.3553 21 16.0001 21L16.3725 21.0053C20.8734 21.1338 24.9993 23.5851 27.2606 27.4993C27.5369 27.9775 28.1485 28.1412 28.6267 27.865C29.1049 27.5887 29.2687 26.9771 28.9924 26.4988C27.1073 23.2358 24.0991 20.8539 20.6157 19.7279C23.2419 18.1559 25 15.2833 25 12ZM16.029 18.9999L15.9711 19L15.7593 18.9959C12.0048 18.8691 8.99999 15.7855 8.99999 12C8.99999 8.13401 12.134 5 16 5C19.866 5 23 8.13401 23 12C23 15.8563 19.8817 18.9843 16.029 18.9999Z"
                              ></path>
                            </svg>
                          </button>
                          <ng-template #UserTemplate>
                            <p style="height: auto; padding: 5px">Manage Internal User</p>
                          </ng-template>
                  
                          <button
                            class="p-auto m-auto"
                            nz-button
                            nzType="text"
                            nzSize="small"
                            nz-popover
                            [nzPopoverContent]="CallTemplate"
                          >
                            <svg
                              viewBox="64 64 896 896"
                              focusable="false"
                              data-icon="phone"
                              width="1.5em"
                              height="1.5em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                d="M877.1 238.7L770.6 132.3c-13-13-30.4-20.3-48.8-20.3s-35.8 7.2-48.8 20.3L558.3 246.8c-13 13-20.3 30.5-20.3 48.9 0 18.5 7.2 35.8 20.3 48.9l89.6 89.7a405.46 405.46 0 01-86.4 127.3c-36.7 36.9-79.6 66-127.2 86.6l-89.6-89.7c-13-13-30.4-20.3-48.8-20.3a68.2 68.2 0 00-48.8 20.3L132.3 673c-13 13-20.3 30.5-20.3 48.9 0 18.5 7.2 35.8 20.3 48.9l106.4 106.4c22.2 22.2 52.8 34.9 84.2 34.9 6.5 0 12.8-.5 19.2-1.6 132.4-21.8 263.8-92.3 369.9-198.3C818 606 888.4 474.6 910.4 342.1c6.3-37.6-6.3-76.3-33.3-103.4zm-37.6 91.5c-19.5 117.9-82.9 235.5-178.4 331s-213 158.9-330.9 178.4c-14.8 2.5-30-2.5-40.8-13.2L184.9 721.9 295.7 611l119.8 120 .9.9 21.6-8a481.29 481.29 0 00285.7-285.8l8-21.6-120.8-120.7 110.8-110.9 104.5 104.5c10.8 10.8 15.8 26 13.3 40.8z"
                              ></path>
                            </svg>
                          </button>
                          <ng-template #CallTemplate>
                            <p style="height: auto; padding: 5px">Call:{{ selectedUser?.CellPhone}}</p>
                          </ng-template>
                        </div>
                      </div>
                    </ng-template>

                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text" for="streetAddress"
                          >Attendees
                          <i
                          class="bi bi-info-circle-fill text-secondary ms-1"
                          nz-popover
                            [nzPopoverContent]="AttendeescontentTemplate"
                          nzPopoverPlacement="top"
                           ></i>
                        <ng-template #AttendeescontentTemplate>
                          <div class="popover-content">
                            <h5>Attendees allows additional users to have access to this activity.</h5>
                          </div>
                        </ng-template>

                          </label
                        >
                        <ng-template #avatarTemplate let-node>
                          <ng-container
                            *ngIf="node.origin.value !== 'check_all'"
                          >
                            <nz-avatar
                              nzSize="small"
                              [nzIcon]="
                                !node.origin.firstName && !node.origin.lastName
                                  ? 'user'
                                  : ''
                              "
                              [nzText]="
                                node.origin.firstName || node.origin.lastName
                                  ? getInitia(node.origin)
                                  : ''
                              "
                            >
                            </nz-avatar>
                          </ng-container>
                          <span class="ms-1">{{ node?.title }}</span>
                        </ng-template>
                        <ng-container
                          *ngIf="
                            !activityResponses?.MarkComplete;
                            else readOnlyContent
                          "
                        >
                        <nz-tree-select
                        [(ngModel)]="formFieldValues['createLeadActivityUserParameterRequests']"
                        [nzNodes]="attendeesNodes"
                        nzCheckable
                        nzCheckAll="true"
                        nzPlaceHolder="Select Sales people"
                        [nzAllowClear]="false"
                        [nzShowSearch]="true"
                        (ngModelChange)="onUserChange($event)"
                        nzCustomContent
                        [nzTreeTemplate]="avatarTemplate"
                      >
                        <!-- Template for rendering selected option with avatar -->
                        <ng-template #defaultTemplate let-selected>
                          <div class="d-flex align-items-center">
                            <nz-avatar
                              nzSize="small"
                              [nzText]="getInitia(selected.nzValue)"
                            ></nz-avatar>
                            <span class="ms-1">{{ selected.nzLabel }}</span>
                          </div>
                        </ng-template>
                      </nz-tree-select>

                          <ng-template #defaultTemplate let-selected>
                            <div class="color-item d-flex align-items-center">
                              <nz-avatar
                                nzSize="small"
                                [nzText]="getInitia(selected.nzValue)"
                              ></nz-avatar>
                              <span class="ms-1">{{ selected.nzLabel }}</span>
                            </div>
                          </ng-template>



                        </ng-container>
                        <ng-template #readOnlyContent>
                          <div class="d-flex flex-column align-items-start">
                            <!-- Avatar with initials displayed for each selected attendee -->
                            <div *ngFor="let user of getSelectedAttendeesList(); let i = index" class="attendee-row">
                              <nz-avatar
                                [nzText]="getI(user?.fullName)"
                                class="attendee-avatar"
                                style="color: rgb(139, 20, 57); background-color: rgb(255, 185, 168);"
                                nz-popover
                                [nzPopoverContent]="popoverContentTemplate"
                                (mouseenter)="setCurrentAttendee(user)"
                              >
                              </nz-avatar>
                              <span class="attendee-name">{{ user?.fullName }}</span>
                            </div>
                          </div>
                        </ng-template>
                        <!-- Define the popover content template -->
                        <ng-template #popoverContentTemplate>
                          <div nz-row style="width: 100%; padding: 10px; height: auto; border-radius: 8px;">
                            <nz-space nzDirection="horizontal" nzAlign="start" nzSize="middle" style="width: 100%;">
                              <div style="flex: 1; display: flex; flex-direction: column;">
                                <!-- Display the current hovered attendee only -->
                                <div *ngIf="currentAttendee" class="attendee-row" style="display: flex; align-items: center; margin-bottom: 8px;">
                                  <nz-avatar
                                    [nzText]="getI(currentAttendee?.fullName)"
                                    class="attendee-avatar"
                                    style="
                                    background-color: #ffb9a8;
                                    font-size: 12px;
                                    font-weight: medium;
                                    color: #8b1439;
                                  "
                                  ></nz-avatar>
                                  <span class="attendee-name" style="margin-left: 8px;">
                                    {{ currentAttendee?.fullName }}
                                  </span>
                                </div>
                              </div>
                              <div style="flex: 2; padding-left: 20px;">
                                <!-- Only show the email for the current attendee -->
                                <div *ngIf="currentAttendee?.fullName">
                                  <p>{{ currentAttendee?.fullName }}</p>
                                </div>
                                <div style="display: flex; align-items: center; margin-top: 1px;">
                                  <i class="bi bi-envelope me-2"></i>
                                  <a
                                    class="text-primary"
                                    [href]="'mailto:' + activityResponses?.CustomerPrimaryEmail"
                                    style="color: #007bff;"
                                  >
                                    {{ activityResponses?.CustomerPrimaryEmail }}
                                  </a>
                                </div>
                              </div>
                            </nz-space>
                          </div>

                          <!-- Actions at the bottom of the popover -->
                          <div style="display: flex;  ">
                            <button nz-button nzType="text" nzSize="small" nz-popover [nzPopoverContent]="ChatTemplate">
                              <i class="bi bi-chat"></i>
                            </button>
                            <ng-template #ChatTemplate>
                              <p>Send Message</p>
                            </ng-template>

                            <button nz-button nzType="text" nzSize="small" nz-popover [nzPopoverContent]="UserTemplate">
                              <i class="bi bi-person"></i>
                            </button>
                            <ng-template #UserTemplate>
                              <p >Manage Internal User</p>
                            </ng-template>

                            <button nz-button nzType="text" nzSize="small" nz-popover [nzPopoverContent]="CallTemplate">
                              <i class="bi bi-telephone"></i>
                            </button>
                            <ng-template #CallTemplate>
                              <p >Call</p>
                            </ng-template>
                          </div>
                        </ng-template>
                      </div>
                    </div>


                    <ng-container *ngIf="activityResponse; else formEnabled">
                      <div class="row" [class.disabled-container]="activityResponse?.markComplete">
                        <div class="col-2">
                          <label class="sub-text famly-inn">Initiated By</label>
                          <nz-radio-group [(ngModel)]="formFieldValues['initiatedBy']" name="initiatedBy" [nzDisabled]="activityResponse?.markComplete">
                            <div class="mt-2">
                              <label nz-radio nzValue="A" [(ngModel)]="formFieldValues['salesperson']" name="salesperson">Salesperson</label>
                            </div>
                            <div class="mt-2">
                              <label nz-radio nzValue="B" [(ngModel)]="formFieldValues['lead']" name="lead">Lead</label>
                            </div>
                            <div class="mt-2">
                              <label nz-radio nzValue="isSelected" [(ngModel)]="formFieldValues['other']" name="other">Others</label>
                            </div>
                          </nz-radio-group>
                          
                        </div>
                      </div>
                    </ng-container>


                    <ng-template #formEnabled>
                      <div class="row">
                        <div class="col-2">
                          <label class="sub-text famly-inn">Initiated By</label>
                          <nz-radio-group [(ngModel)]="formFieldValues['initiatedBy']" name="initiatedBy">
                            <div class="mt-2">
                              <label nz-radio nzValue="A" [(ngModel)]="formFieldValues['salesperson']" name="salesperson">Salesperson</label>
                            </div>
                            <div class="mt-2">
                              <label nz-radio nzValue="B" [(ngModel)]="formFieldValues['lead']" name="lead">Lead</label>
                            </div>
                            <div class="mt-2">
                              <label nz-radio nzValue="isSelected" [(ngModel)]="formFieldValues['other']" name="other">Others</label>
                            </div>
                          </nz-radio-group>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-0 mt-0">
              <div class="card-head border-bottom pb-1">
                <div class="row">
                  <div class="col-6">
                    <h2 class="ms-3" nz-typography>Location</h2>
                  </div>
                  <div *ngIf="showContactForm || showContactLead" class="col-6 text-end">
                    <button
                      nz-button
                      nzType="primary"
                      class="button-Primary"
                      nz-dropdown
                      [nzDropdownMenu]="menu"
                      nzTrigger="click"
                    >
                      <span>Copy Address</span>
                      <span nz-icon nzType="down"></span>
                    </button>

                    <nz-dropdown-menu #menu="nzDropdownMenu">
                      <ul nz-menu>
                        <li *ngIf="activityResponses && !showAdvancedOptions && showContactForm" nz-menu-item (click)="patchContactafterpacthtime()">
                          Contact Formed
                        </li>
                        <li *ngIf="activityResponses && !showAdvancedOptions && showContactLead" nz-menu-item (click)="patchLeadafterpacthtime()">
                          Contact Leads
                        </li>

                        <!-- Show only if activityResponse exists -->
                        <li *ngIf="LeadOpportunityResponse && !showAdvancedOptions && showContactForm" nz-menu-item (click)="patchContact()">
                          Contact Form
                        </li>
                        <li *ngIf="LeadOpportunityResponse && !showAdvancedOptions && showContactLead" nz-menu-item (click)="patchLead()">
                          Contact Lead
                        </li>
                      </ul>
                    </nz-dropdown-menu>

                  </div>


                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <label class="sub-text">Street Address</label>
                    <input
                      [(ngModel)]="formFieldValues.address"
                      (ngModelChange)="checkCharacterLimits()"
                      name="address"
                      type="text"
                      #stateInput="ngModel"
                      class="formField col-11"
                      [ngClass]="{
                        'border-danger': formFieldValues['address']?.length > 50
                      }"
                      required
                      pattern="^.{50}$"
                    />
                    <i
                      class="fa-solid fa-location-dot col-1"
                      *ngIf="formFieldValues['address']?.trim() != ''"
                      (click)="
                        generateMapUrl(formFieldValues['address']?.trim())
                      "
                    ></i>
                    <div
                      *ngIf="
                        stateInput.invalid &&
                        (stateInput.dirty || stateInput.touched)
                      "
                      class="text-danger"
                    >

                    </div>
                    <div
                      *ngIf="formFieldValues['address']?.length > 50"
                      class="text-danger"
                    >
                      {{ formFieldValues["address"].length - 50 }} characters
                      over.
                    </div>
                  </div>
                </div>
                <div class="row mt-2 gx-0 gx-2">
                  <div class="col-4">
                    <label class="sub-text">Suburb</label>
                    <input
                      [(ngModel)]="formFieldValues['suburb']"
                      (ngModelChange)="checkCharacterLimits()"

                      name="suburb"
                      type="text"
                      class="formField form-control"
                      #stateInput="ngModel"
                      [ngClass]="{
                        'border-danger': formFieldValues['suburb']?.length > 50
                      }"
                      required
                      pattern="^.{50}$"
                    />
                    <div
                      *ngIf="
                        stateInput.invalid &&
                        (stateInput.dirty || stateInput.touched)
                      "
                      class="text-danger"
                    >

                    </div>
                    <div
                      *ngIf="formFieldValues['suburb']?.length > 50"
                      class="text-danger"
                    >
                      {{ formFieldValues["suburb"].length - 50 }} characters
                      over.
                    </div>
                  </div>
                  <div class="col-4">
                    <label class="sub-text">State</label>
                    <input
                      [(ngModel)]="formFieldValues['state']"
                      (ngModelChange)="checkCharacterLimits()"

                      name="state"
                      type="text"
                      class="formField form-control"
                      #stateInput="ngModel"
                      [ngClass]="{
                        'border-danger': formFieldValues['state']?.length > 3
                      }"
                      required
                      pattern="^.{3}$"
                    />
                    <div
                      *ngIf="
                        stateInput.invalid &&
                        (stateInput.dirty || stateInput.touched)
                      "
                      class="text-danger"
                    >

                    </div>
                    <div
                      *ngIf="formFieldValues['state']?.length > 3"
                      class="text-danger"
                    >
                      {{ formFieldValues["state"].length - 3 }} characters over.
                    </div>
                  </div>
                  <div class="col-4">
                    <label class="sub-text">Postal Code</label>
                    <input
                      [(ngModel)]="formFieldValues['postalCode']"
                      name="postalCode"
                      (ngModelChange)="checkCharacterLimits()"
                      type="text"
                      class="formField form-control"
                      #stateInput="ngModel"
                      [ngClass]="{
                        'border-danger':
                          formFieldValues['postalCode']?.length > 15
                      }"
                      required
                      pattern="^.{15}$"
                    />
                    <div
                      *ngIf="
                        stateInput.invalid &&
                        (stateInput.dirty || stateInput.touched)
                      "
                      class="text-danger"
                    >

                    </div>
                    <div
                      *ngIf="formFieldValues['postalCode']?.length > 15"
                      class="text-danger"
                    >
                      {{ formFieldValues["postalCode"].length - 15 }} characters
                      over.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <nz-card nzBorderless="true" [nzTitle]="Details">
              <ng-template #Details>
                <h2 class="famly-inn">Details</h2>
              </ng-template>
              <div class="row">
                <div class="col-12">
                  <label class="sub-text">Description</label>
                  <textarea
                    class=""
                    [(ngModel)]="formFieldValues['description']"
                    name="description"
                    rows="2"
                    nz-input
                  ></textarea>
                </div>
              </div>

              <ng-container *ngIf="activityResponses">
                <div class="row mt-2">
                  <div class="col-12">
                    <label class="sub-text">Lead Contact Email</label>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <p>
                      {{
                        activityResponses?.CustomerPrimaryEmail
                      }}
                    </p>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <label class="sub-text">Lead Contact Phone</label>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <div class="col-12 ms-2">
                      <a href="">{{
                        activityResponses?.CustomerContactNumber
                      }}</a>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <label class="sub-text">Lead Contact Cell</label>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <div class="col-12">
                      <a href="">{{
                        activityResponses?.CustomerContactCell
                      }}</a>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="customerInformation">
                <div class="row mt-2">
                  <div class="col-12">
                    <label class="sub-text">Lead Contact Email</label>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <p>{{ customerInformation?.primaryEmail }}</p>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <label class="sub-text">Lead Contact Phone</label>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <a href="">{{
                      customerInformation?.phone
                    }}</a>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <label class="sub-text">Lead Contact Cell</label>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <a href="">{{
                      customerInformation?.cellPhone
                    }}</a>
                  </div>
                </div>
              </ng-container>


            </nz-card>
          </ng-container>
          <ng-container *ngIf="tab === 2">
            <nz-card
              [nzTitle]="'Email'"
              nzBorderless="true"
              [nzExtra]="EmailTemplate"
            >
              <ng-template #Email>
                <h2 class="famly-inn">Email</h2>
              </ng-template>

              <div class="row">
                <div class="col-6">
                  <label class="famly-inn"
                    >Send an email with this lead Activity.</label
                  >
                </div>
              </div>

              <!-- <div class="row mt-3" *ngIf="!showActivityResponse && !showEmailContent">
              <div class="col-12">
                <button
                *ngIf="showAttachBuildertrendMessageButton"
                  nz-button
                  class=""
                  nzType="default"
                  (click)="
                    customerInformationId > 0
                      ? toggleActivityResponse()
                      : showConfirmationCard()
                  "
                >
                  Attach Buildertrend Message
                </button>
                <button
                  *ngIf="!showActivityResponse"
                  class="ms-3 "
                  nz-button
                  nzType="default"
                  (click)="
                    customerInformationId > 0
                      ? toggleDisplay()
                      : showConfirmationCard()

                  "
                >
                  Attach External Email
                </button>
              </div>
            </div> -->
              <div
                class="row mt-3"
                *ngIf="!showActivityResponse && !showEmailContent"
              >
                <div class="col-12">
                  <button
                    *ngIf="showAttachBuildertrendMessageButton"
                    nz-button
                    class=""
                    nzType="default"
                    (click)="handleAttachBuildertrendMessageClick()"
                  >
                    Attach Buildertrend Message
                  </button>
                  <button
                  *ngIf="!showActivityResponse"
                  class="ms-3"
                  nz-button
                  nzType="default"
                  [disabled]="isButtonDisabled"
                  (click)="handleAttachExternalEmailClick()"
                  nz-popover
                  [nzPopoverContent]="contentTemplates"
                  nzPopoverPlacement="top"
                  [nzPopoverTrigger]="isButtonDisabled ? 'hover' : 'click'"
                >
                  Attach External Email
                </button>

                <!-- ng-template for popover content -->
                <ng-template #contentTemplates>
                  <div class="popover-content" style="width:200px;">
                    <h5>You must save the Lead Activity before  composing an external email</h5>
                  </div>
                </ng-template>




                </div>
              </div>

              <div *ngIf="showActivityResponse" class="ng-container">
                <div class="row mt-1">
                  <div class="col-3">
                    <h2 class="famly-inn fw-medium">Compose Message</h2>
                  </div>
                  <div class="col-7"></div>
                  <div class="col-2 p-0">
                    <span class="mail-merge-info">
                      Mail Merge
                      <i
                        class="bi bi-info-circle-fill text-secondary"
                        nz-popover
                        [nzPopoverContent]="contentTemplate"
                        nzPopoverPlacement="left"
                      ></i>
                      <ng-template #contentTemplate>
                        <div class="popover-content">
                          <h4>Mail Merge Template</h4>
                          <p>
                            Update a lead email with placeholder text. Use the
                            fields below as placeholders, and we will populate
                            them before the email is sent:
                          </p>
                          <ul>
                            <li>
                              <strong>[[FirstName]] [[LastName]]</strong>: The
                              Customer Contact's First and Last Name for that
                              specific lead is populated.
                            </li>
                            <li>
                              <strong>[[DisplayName]]</strong>: The Customer
                              Contact's Display Name for that specific lead is
                              populated.
                            </li>
                            <li>
                              <strong>[[Signature]]</strong>: The signature text
                              and/or image is populated for the Lead's assigned
                              Salesperson. The signature text and image can be
                              set up for each Salesperson in User Preferences.
                            </li>
                          </ul>
                          <p>
                            <strong>Example:</strong> You want to set up an
                            email drip campaign that can be used for any of your
                            leads. To avoid personalizing each lead email
                            greeting or signature, set up a lead activity
                            template with placeholder fields. For instance,
                            "Dear [[DisplayName]]" would populate the Lead's
                            name into the email message before sending, and show
                            as "Dear John Smith".
                          </p>
                        </div>
                      </ng-template>
                    </span>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <label class="sub-text">Subject</label>
                    <ng-container
                      *ngIf="
                      activityResponses?.MarkComplete;
                        else editableSubject
                      "
                    >
                      <!-- Display subject as plain text when MarkComplete is true -->
                      <div class="form-control-plaintext">
                        {{ formFieldValues["subject"] || "--None--" }}
                      </div>
                    </ng-container>
                    <ng-template #editableSubject>
                      <!-- Editable subject input field -->
                      <input
                        [(ngModel)]="formFieldValues['subject']"
                        (ngModelChange)="checkCharacterLimits()"
                        name="subject"
                        type="text"
                        class="formField form-control"
                        #stateInput="ngModel"
                        [ngClass]="{
                          'border-danger':
                            formFieldValues['subject']?.length > 50
                        }"
                        required
                        pattern="^.{50}$"
                      />
                      <div
                        *ngIf="
                          stateInput.invalid &&
                          (stateInput.dirty || stateInput.touched)
                        "
                        class="text-danger"
                      >
                        <div *ngIf="stateInput.errors?.['required']">
                          Subject is required.
                        </div>
                      </div>
                      <div
                        *ngIf="formFieldValues['subject']?.length > 50"
                        class="text-danger"
                      >
                        {{ formFieldValues["subject"].length - 50 }} characters
                        over.
                      </div>
                    </ng-template>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12">
                    <label class="sub-text">Body</label>
                    <div class="editor-container">
                      <!-- Display CKEditor when MarkComplete is false -->
                      <ng-container
                        *ngIf="
                          !activityResponses?.MarkComplete;
                          else readOnlyContent
                        "
                      >
                        <ckeditor
                          [(ngModel)]="formFieldValues['body']"
                          name="notes"
                          [editor]="Editor"
                          [config]="config"
                          class="size"
                          id="editor"
                        ></ckeditor>
                      </ng-container>

                      <!-- Display a <p> tag when MarkComplete is true -->
                      <ng-template #readOnlyContent>
                        <p>{{ activityResponses?.Body }}</p>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div class="card border-0 mt-3">
                  <div class="card-head border-bottom">
                    <h3 class="p-0 m-0 pt-2 pb-2 ms-3 react" nz-typography>
                      Attachments
                    </h3>
                  </div>
                  <div class="card-body bt-file-viewer-card">
                    <div class="row">
                      <div class="col-12">
                        <button
                          nz-button
                          class="button-default react"
                          (click)="uploadFiles()"
                        >
                          <span>Add</span>
                        </button>
                        <button
                          nz-button
                          class="button-default react ms-2"
                          (click)="CreateNewFile()"
                        >
                          <span>Create New File</span>
                        </button>
                        <button
                          *ngIf="selectedFiless?.length > 0"
                          nz-button
                          class="button-default react ms-2"
                          (click)="viewAll()"
                        >
                          <span>View All ({{ selectedFiless?.length }})</span>
                        </button>
                      </div>
                    </div>
                    <i
                      *ngIf="showNavButtons"
                      class="bx bx-chevron-left carousel-nav prev"
                      (click)="previous()"
                    ></i>
                    <i
                      *ngIf="showNavButtons"
                      class="bx bx-chevron-right carousel-nav next"
                      (click)="next()"
                    ></i>
                    <div class="carousel-container" #carouselContainer>
                      <div
                        class="carousel-view"
                        *ngFor="let fileq of selectedFiless"
                      >
                        <div class="bt-file-viewer">
                          <img
                            class="img-1"
                            *ngIf="getFileIcon(fileq)"
                            [src]="getFileIcon(fileq)"
                          />
                          <img
                            (click)="onClick()"
                            class="img-2"
                            *ngIf="
                              !getFileIcon(fileq) &&
                              !fileq.type.includes('video')
                            "
                            [src]="fileq.thumbUrl"
                          />
                          <ng-container *ngIf="fileq.type.includes('video')">
                            <div class="video-container">
                              <i
                                nz-icon
                                nzType="play-circle"
                                nzTheme="outline"
                                class="video-play-icon"
                                (click)="playVideo(fileq)"
                              ></i>
                              <video
                                #videoPlayer
                                id="video-{{ fileq.id }}"
                                [src]="fileq.thumbUrl"
                                (click)="playVideo(fileq)"
                              ></video>
                            </div>
                          </ng-container>
                          <div nz-row class="action-row">
                            <div nz-col nzSpan="24">
                              <label
                                class="BTFileUpload-SecondaryText p-0 m-0 ms-2"
                                >{{ fileq.name }}</label
                              >
                            </div>
                            <div nz-col nzSpan="5">
                              <i
                                style="
                                  margin: 2px 0px 0px 6px;
                                  cursor: pointer;
                                  font-size: 21px;
                                  color: #626262;
                                "
                                nzTrigger="click"
                                nz-dropdown
                                [nzDropdownMenu]="menu4"
                                class="bx bxs-chevron-down"
                              ></i>
                              <nz-dropdown-menu #menu4="nzDropdownMenu">
                                <ul nz-menu style="width: 170px">
                                  <li nz-menu-item (click)="removeFile(fileq)">
                                    <div class="dropdown-container">
                                      <i
                                        style="
                                          cursor: pointer;
                                          font-size: 20px;
                                          color: #626262;
                                        "
                                        class="bx bx-trash"
                                      ></i>
                                      <span class="react">Delete</span>
                                    </div>
                                  </li>
                                  <li
                                    nz-menu-item
                                    (click)="uploadFilesUpdate(fileq)"
                                    *ngIf="isImageFile(fileq)"
                                  >
                                    <div class="dropdown-container">
                                      <i
                                        style="
                                          cursor: pointer;
                                          font-size: 20px;
                                          color: #626262;
                                        "
                                        class="bx bxs-pencil"
                                      ></i>
                                      <span class="react">Edit Online</span>
                                    </div>
                                  </li>
                                </ul>
                              </nz-dropdown-menu>
                            </div>
                            <div nz-col nzSpan="4" *ngIf="isImageFile(fileq)">
                              <i
                                (click)="uploadFilesUpdate(fileq)"
                                style="
                                  cursor: pointer;
                                  font-size: 26px;
                                  color: #626262;
                                "
                                class="bx bxs-pencil"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="showEmailContent" class="ng-container">
                <div class="row mt-1">
                  <div class="col-3">
                    <h2 class="famly-inn fw-medium">Compose Message</h2>
                  </div>
                  <div class="col-7"></div>
                  <div class="col-2 p-0">
                    <span class="mail-merge-info">
                      Mail Merge
                      <i
                        class="bi bi-info-circle-fill text-secondary"
                        nz-popover
                        [nzPopoverContent]="contentTemplate"
                        nzPopoverPlacement="left"
                      ></i>
                      <ng-template #contentTemplate>
                        <div class="popover-content">
                          <h4>Mail Merge Template</h4>
                          <p>
                            Update a lead email with placeholder text. Use the
                            fields below as placeholders, and we will populate
                            them before the email is sent:
                          </p>
                          <ul>
                            <li>
                              <strong>[[FirstName]] [[LastName]]</strong>:<br />
                              <br />
                              The Customer Contact's First and Last Name for
                              that specific lead is populated.
                            </li>
                            <li>
                              <strong>[[DisplayName]]</strong>:<br />
                              <br />
                              The Customer Contact's Display Name for that
                              specific lead is populated.
                            </li>
                            <li>
                              <strong>[[Signature]]</strong>:<br />
                              <br />
                              The signature text and/or image is populated for
                              the Lead's assigned Salesperson. The signature
                              text and image can be set up for each Salesperson
                              in User Preferences.
                            </li>
                          </ul>
                          <p>
                            <strong>Example:</strong> <br />
                            You want to set up an email drip campaign that can
                            be used for any of your leads. To avoid
                            personalizing each lead email greeting or signature,
                            set up a lead activity template with placeholder
                            fields. For instance, "Dear [[DisplayName]]" would
                            populate the Lead's name into the email message
                            before sending, and show as "Dear John Smith".
                          </p>
                        </div>
                      </ng-template>
                    </span>
                  </div>
                </div>
                <div class="mt-2">
                  <p>
                    To send another email, you can click "Compose New Message"
                    or copy/paste the below email address.
                  </p>
                </div>
                <!-- Option to compose a new message -->
                <div class="mt-2">
                  <button nz-button nzType="default" (click)="openMailClient()">
                    Compose New Message
                  </button>
                </div>

                <!-- Input field for the email address -->
                <div class="mt-2">
                  <label for="" style="font-weight: bolder; color: black"
                    >Email Address</label
                  >
                  <nz-input-group
                    nzSearch
                    nzSize="large"
                    [nzAddOnAfter]="suffixButton"
                  >
                    <input
                      class="formField form-control email-link"
                      type="text"
                      nz-input
                      [(ngModel)]="inputValue"
                      placeholder="Enter email address"
                      [readonly]="isDisabled"
                      (click)="openMailClient()"
                    />
                  </nz-input-group>
                  <ng-template #suffixButton>
                    <button
                      class="formField form-control email-link"
                      (click)="copyToClipboard()"
                    >
                      Copy
                    </button>
                  </ng-template>
                </div>
              </div>
            </nz-card>

            <ng-template #EmailTemplate>
              <button
                *ngIf="showActivityResponse"
                class=""
                nz-button
                nzType="default"
                (click)="cancelEmailFields()"
              >
                Cancel
              </button>

              <button
                *ngIf="showEmailContent"
                class=""
                nz-button
                nzType="default"
                (click)="cancelEmailFields()"
              >
                Cancel
              </button>
            </ng-template>
          </ng-container>

          <!-- Confirmation Modal -->
          <nz-modal
            *ngIf="isConfirmationVisible"
            [nzTitle]="'Invalid Email'"
            [nzCentered]="true"
            [nzVisible]="isConfirmationVisible"
            (nzOnCancel)="handleCancel()"
            (nzOnOk)="handleOk()"
          >
            <p>
              The lead you have selected does not have a valid email address.
            </p>
          </nz-modal>
        </nz-tab>
      </nz-tabset>
    </div>
  </div>
  <ng-template #LeadActivityFooter>
          <!-- <div class="created-by d-flex align-items-center">

            <div *ngIf=" activityResponse"  class="avatar-initials d-flex align-items-center">
              <div class="me-2">Created by</div>
              <nz-avatar

                [nzText]="getAvatarText(activityResponse?.createdByUser)"
                style="
                  color: rgb(139, 20, 57);
                  background-color: rgb(255, 185, 168);
                "
              ></nz-avatar>
              <div  style="font-weight: bolder;">
                {{ activityResponse?.createdByUser }}
              </div>
              <div  class="me-2">
                {{ activityResponse?.createdOn | date : "MMM d, yyyy, h:mm a" }}
              </div>
            </div>
          </div> -->
        <div class="row" >

          <div class="col text-start  justify-content-start align-items-start" *ngIf="activityResponse">
            <span *ngIf="footerResponse">
              <app-footer-label [footerData]="footerResponse"></app-footer-label>
            </span>

        </div>

          <div class="col text-end">
            <nz-space>
              <ng-container *nzSpaceItem>
        <button
          *ngIf="activityResponses !== undefined"
          nz-button
          nzType="default"
          class="me-2"
          (click)="deleteLeadConfirm()"
          [nzLoading]="isDeleteLoading"
          [disabled]="isButtonDisableds"
        >
          Delete
        </button>
        <button
          nz-button
          nzType="primary"
          class="me-2"
          [nzLoading]="isSaveAndNew"
          (click)="saveAndNewActivity()"
          [disabled]="isButtonDisableds"
        >
          Save & New
        </button>
        <button
          nz-button
          nzType="primary"
          class="me-2"
          [nzLoading]="isSave"
          (click)="saveLeadActivity()"
          [disabled]="isButtonDisableds"
        >
          Save
        </button>
        <button
          nz-button
          nzType="primary"
          class="me-2"
          [nzLoading]="isSaveAndClose"
          (click)="saveAndCloseActivity()"
          [disabled]="isButtonDisableds"
        >
          Save & Close
        </button>
              </ng-container>
            </nz-space>

      </div>
    </div>

    <!-- Example template to display customerInformationId -->
  </ng-template>
</nz-modal>
