
<div class="row mt-5" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <div class="col" style="text-align: center;">
           <span>
            <!-- <i class="bi bi-cart4" style="font-size: 60px;"></i> -->
            <img src="assets/Empty-SVGs/Financial/Purchase Orders/purchaseOrder.svg" >

         </span>      
        <div class="row">
            <div class="col mt-2">
        <h3>Track and plain job cots with purchase</h3>
        <h3 class="p-0">Orders</h3>
            </div>
        </div>
        <div class="col mt-3">
        <p>Keep your subs involved with online signature and easily<br>approvals,stattus and invoice of for every PO.</p>
  
  
        </div>
    </div>
    <div class="col mt-4" style="display: flex; justify-content: center; align-items: center;">
        <button nz-button nzType="default" style="margin-right: 10px;"><i class="bi bi-box-arrow-up-right me-2"></i>Learn How</button>
        <button nz-button nzType="primary" style="margin-right: 10px;" (click)="PurchaseOpen()">Add a Purchase Order</button>
    </div>
  
  </div> 
  
