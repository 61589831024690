import { Component } from '@angular/core';

@Component({
  selector: 'app-schedule-lgantt-empty',
  templateUrl: './schedule-lgantt-empty.component.html',
  styleUrls: ['./schedule-lgantt-empty.component.css']
})
export class ScheduleLGanttEmptyComponent {

}
