
<div class="content" *ngIf="!isAssign">
  <div class="row">
    <h1 nz-typography>
      Delete {{ selectedGlobalIds?.length }}
      {{ selectedGlobalIds?.length === 1 ? "Activity" : "Activities" }}?
    </h1>
  </div>
 
  <form nz-form [formGroup]="validateForm" >
      <nz-form-item>
        <nz-form-label [nzSpan]="24" class="sub-text  p-0" style="margin-left: -3px;" nzFor="colorId" nzRequired>Assigned User</nz-form-label>
        <nz-form-control [nzSpan]="24" nzErrorTip="Required">
            <nz-select
            nzShowSearch
            [nzCustomTemplate]="defaultTemplate"
            id="assignedUserId"
            formControlName="assignedUserId"
            [style.width.%]="100">
            <nz-option
              nzCustomContent
              *ngFor="let option of getAssigner"
              [nzLabel]="option.fullName"
              [nzValue]="option.id">
              <nz-avatar nzSize="small" [nzText]="getInitials(option.fullName)"></nz-avatar>
              <ng-container>
                <span class="color-name">{{ option.fullName }}</span>
              </ng-container>
            </nz-option>
          </nz-select>
          
          <ng-template #defaultTemplate let-selected>
            <div class="color-item d-flex align-items-center">
              <nz-avatar nzSize="small" [nzText]="getInitials(selected.nzLabel)"></nz-avatar>
              <span class="color-name">{{ selected.nzLabel }}</span>
            </div>
          </ng-template>
          
        </nz-form-control>
      </nz-form-item>
     
      <div nz-row class="justify-content-end mt-3">
          <div nz-col >
                   <nz-space>
                      <button (click)="Cancel()" *nzSpaceItem nz-button nzType="default">Cancel</button>
                      <button (click)="submitForm()" *nzSpaceItem nz-button nzType="primary">
                        Reassign {{ selectedGlobalIds?.length }} {{ selectedGlobalIds?.length === 1 ? "Activity" : "Activities" }}
                      </button>
                   </nz-space>
          </div>
      </div>
    </form>

</div>

<div *ngIf="isAssign" class="result">
  <nz-result
    nzStatus="success"
    nzTitle="Reassign {{ selectedGlobalIds?.length === 1 ? 'Activity..' : 'Activities...' }}"
  >
  </nz-result>
</div>
