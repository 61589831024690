import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BooleanInput } from 'ng-zorro-antd/core/types';
import { NzSelectSizeType } from 'ng-zorro-antd/select';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { CompanySettingsEstimatePreDefineCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { EstimateService } from '../services/estimate.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
@Component({
  selector: 'app-estimates',
  templateUrl: './estimates.component.html',
  styleUrls: ['./estimates.component.css'],

})
export class EstimatesComponent implements OnInit {
  estimates: boolean = true
  @Output() cancel = new EventEmitter<void>();
  formfieldvalue: any ={};
  selectedValue = null;
  checked = false;
  text: string | undefined;
  listOfOption: Array<{ label: string; value: string }> = [];
  size: NzSelectSizeType = 'default';
  multipleValue = [''];
  tagValue = ['a10', 'c12', 'tag'];
  PAGE_ID: number = 30017;
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  defaultGroupSetting : LookupNameSetupResponse[] = [];
  header: LookupNameSetupResponse[] = [];
  content: LookupNameSetupResponse[] = [];
  showContact: LookupNameSetupResponse[]=[];

  // estimates: boolean;


  preDefinedDataCodes = {
    defaultGrouping: CompanySettingsEstimatePreDefineCodes.CSEGPW,
    header: CompanySettingsEstimatePreDefineCodes.CSEH,
    content: CompanySettingsEstimatePreDefineCodes.CSEC,
    showContactName: CompanySettingsEstimatePreDefineCodes.CSESCN,
  };


    constructor (private LookupFilterServicee: LookupFilterService,
      private estmateser:EstimateService,
      private toastssService: NzMessageService,
      private modal: NzModalService,
    ){}

ngOnInit(): void {
  this.initLoad();
  this.formfieldvalue ={

    companyParameterId: 1,
    estimateGroupId: '',
    headerSetupId: '',
    contentSetupId: '',
    showContactNamePhoneSetupId: '',
    showAddressSetupId: '',
    showCompanyInformationSetupId: '',
    defaultIntroductoryText: '',
    defaultTitle: '',
    defaultClosingText: '',
    estimateDisclaimer: ''

  }

}


initLoad() {
  this.LookupFilterServicee.getLookUpValuesByFormNameId(
    this.PAGE_ID
  ).subscribe((res) => {
    // -----------single Select ---------------

    let defaultGrouping = res.result.filter(
      (x) => x.code === this.preDefinedDataCodes.defaultGrouping.code
    );
    let header = res.result.filter(
      (x) => x.code === this.preDefinedDataCodes.header.code
    );
    let content = res.result.filter(
      (x) => x.code === this.preDefinedDataCodes.content.code
    );
    let showContactName = res.result.filter(
      (x) => x.code === this.preDefinedDataCodes.showContactName.code
    );


    // -----------single Select -------------
    this.defaultGroupSetting = defaultGrouping;

    this.header = header;

    this.content = content;

    this.showContact = showContactName;

    this.lookupSetNameResponse = res.result;
  });
}
closeEstimates() {
  this.estimates = true;
}


// checkbox //

  listOfData = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park'
    }
  ];

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.listOfData, event.previousIndex, event.currentIndex);
  }
  autoAdjustTextAreaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; // Set the height with a maximum of 200px
  }

  isInputVisible: boolean = false;

  toggleVisibility() {
    this.isInputVisible = true;
  }


// // save method //

saveData() {
console.log('testing data ', this.formfieldvalue);
this.estmateser
.postEstimate(this.formfieldvalue)
.subscribe((response) => {
  this.toastssService.success('New  Successfully!');
  this.formfieldvalue = {};
  this.modal.closeAll();
});
}
}
