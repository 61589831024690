import { Component, ElementRef, ViewChild, AfterViewInit, EventEmitter, Output, ChangeDetectorRef, OnInit } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';

interface CustomUploadFile extends NzUploadFile {
  loading?: boolean;
  error?: boolean;
  addedBy: number;
  createdOn: Date;
  modifiedOn: Date;
  id: number;
  globalId: string;
}

@Component({
  selector: 'app-attachment-annotation',
  templateUrl: './attachment-annotation.component.html',
  styleUrls: ['./attachment-annotation.component.css']
})
export class AttachmentAnnotationComponent implements OnInit, AfterViewInit {
  @ViewChild('canvas') canvasRef: ElementRef<HTMLCanvasElement>;
  @ViewChild('image') imageRef: ElementRef<HTMLImageElement>;
  @Output() cancel = new EventEmitter<void>();
  @Output() imageDataRes = new EventEmitter<any>();
  @Output() DrawerImge: CustomUploadFile;

  unvisible = true;
  drawing = false;
  highlightMode = false;
  context: CanvasRenderingContext2D;
  drawColor = 'rgb(255, 0, 0)';
  highlightColor = 'rgba(255, 127, 127, 0.5)';
  brushSize = 2;
  isDrawing = false;

  history: string[] = [];
  redoStack: string[] = [];

  constructor(private cdn: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.onDraw();
  }

  ngAfterViewInit(): void {
    const canvas = this.canvasRef.nativeElement;
    this.context = canvas.getContext('2d');
    const image = this.imageRef.nativeElement;

    image.crossOrigin = 'anonymous';
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      this.context.drawImage(image, 0, 0);
    };
  }
  close(): void {
    this.cancel.emit();
    this.unvisible = false;
  }
  onDraw(): void {
    this.drawing = !this.drawing;
    if (this.drawing) {
      this.startDrawing();
      this.cdn.detectChanges();
    } else {
      this.stopDrawing();
    }
  }
  onHighlight(): void {
    this.highlightMode = !this.highlightMode;
    this.context.strokeStyle = this.highlightMode ? this.highlightColor : this.drawColor;
    if (this.highlightMode) {
      this.startHighlighting();
    } else {
      this.stopHighlighting();
    }
  }
  selectBrushSize(size: number): void {
    this.brushSize = size;
    this.context.lineWidth = size;
    this.startHighlighting();
    this.cdn.detectChanges();
  }
  selectColor(color: string): void {
    this.drawColor = color;
    this.highlightColor = color;
    this.context.strokeStyle = this.highlightMode ? this.highlightColor : this.drawColor;
    this.cdn.detectChanges();
  }
  startDrawing(): void {
    if (this.canvasRef && this.canvasRef.nativeElement) {
      this.saveState();
      const canvas = this.canvasRef.nativeElement;
      canvas.addEventListener('mousedown', this.onMouseDown.bind(this));
      canvas.addEventListener('mousemove', this.onMouseMove.bind(this));
      canvas.addEventListener('mouseup', this.onMouseUp.bind(this));
      this.context.lineWidth = this.brushSize;
      this.context.strokeStyle = this.drawColor;
      this.cdn.detectChanges();
    }
  }
  stopDrawing(): void {
    if (this.canvasRef && this.canvasRef.nativeElement) {
      const canvas = this.canvasRef.nativeElement;
      canvas.removeEventListener('mousedown', this.onMouseDown.bind(this));
      canvas.removeEventListener('mousemove', this.onMouseMove.bind(this));
      canvas.removeEventListener('mouseup', this.onMouseUp.bind(this));
      this.context.strokeStyle = this.drawColor;
    }
  }
  startHighlighting(): void {
    this.saveState();
    const canvas = this.canvasRef.nativeElement;
    canvas.addEventListener('mousedown', this.onMouseDownHighlight.bind(this));
    canvas.addEventListener('mousemove', this.onMouseMoveHighlight.bind(this));
    canvas.addEventListener('mouseup', this.onMouseUpHighlight.bind(this));
  }
  stopHighlighting(): void {
    const canvas = this.canvasRef.nativeElement;
    canvas.removeEventListener('mousedown', this.onMouseDownHighlight.bind(this));
    canvas.removeEventListener('mousemove', this.onMouseMoveHighlight.bind(this));
    canvas.removeEventListener('mouseup', this.onMouseUpHighlight.bind(this));
  }
  onMouseDown(event: MouseEvent): void {
    if (this.drawing) {
      this.context.beginPath();
      this.context.moveTo(event.offsetX, event.offsetY);
      this.isDrawing = true;
    }
  }
  onMouseMove(event: MouseEvent): void {
    if (this.drawing && this.isDrawing) {
      this.context.lineTo(event.offsetX, event.offsetY);
      this.context.stroke();
    }
  }
  onMouseUp(event: MouseEvent): void {
    if (this.drawing && this.isDrawing) {
      this.context.lineTo(event.offsetX, event.offsetY);
      this.context.stroke();
      this.isDrawing = false;
    }
  }
  onMouseDownHighlight(event: MouseEvent): void {
    if (this.highlightMode) {
      this.isDrawing = true;
      this.context.beginPath();
      this.context.moveTo(event.offsetX, event.offsetY);
      this.context.strokeStyle = this.highlightColor;
      this.context.lineWidth = this.brushSize;
    }
  }
  onMouseMoveHighlight(event: MouseEvent): void {
    if (this.highlightMode && this.isDrawing) {
      this.context.lineTo(event.offsetX, event.offsetY);
      this.context.stroke();
    }
  }
  onMouseUpHighlight(event: MouseEvent): void {
    if (this.highlightMode && this.isDrawing) {
      this.context.lineTo(event.offsetX, event.offsetY);
      this.context.stroke();
      this.isDrawing = false;
    }
  }
  saveState(): void {
    if (this.canvasRef && this.canvasRef.nativeElement) {
      const canvas = this.canvasRef.nativeElement;
      const state = canvas.toDataURL('image/png');
      this.history.push(state);
      this.redoStack = [];
    }
  }
  undo(): void {
    if (this.history.length > 0) {
      const lastState = this.history.pop();
      this.redoStack.push(this.canvasRef.nativeElement.toDataURL('image/png'));
      const canvas = this.canvasRef.nativeElement;
      const context = this.context;
      const image = new Image();
      image.src = lastState;
      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0);
      };
    }
  }
  redo(): void {
    if (this.redoStack.length > 0) {
      const redoState = this.redoStack.pop();
      this.saveState();
      const canvas = this.canvasRef.nativeElement;
      const context = this.context;
      const image = new Image();
      image.src = redoState;
      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0);
      };
    }
  }
  clearCanvas(): void {
    this.saveState();
    const canvas = this.canvasRef.nativeElement;
    const context = this.context;
    context.clearRect(0, 0, canvas.width, canvas.height);
    const image = this.imageRef.nativeElement;
    context.drawImage(image, 0, 0);
  }
  clearAll(): void {
    this.clearCanvas();
  }
  onSave(): void {
    const canvas = this.canvasRef?.nativeElement;
    const imageData = canvas.toDataURL('image/png');
    this.imageDataRes.emit(imageData);
  }
}
