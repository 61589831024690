<nz-modal nzWidth="95%" [(nzVisible)]="Taghandle" [nzTitle]="NewDaily1" [nzFooter]="NewDaily2" (nzOnCancel)="TagClose()"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '68vh', 'overflow-y': 'auto'}"
  [nzStyle]="{ top: '18px' }">
  <ng-template #NewDaily1>
    <div class="row p-0 m-0">
      <nz-breadcrumb nz-page-header-breadcrumb *ngIf="JOBDATA?.JOB_NAME">
        <nz-breadcrumb-item>{{JOBDATA?.JOB_NAME}}</nz-breadcrumb-item> 
      </nz-breadcrumb>
      <nz-breadcrumb nz-page-header-breadcrumb *ngIf="leadData?.leadData?.name">
        <nz-breadcrumb-item>{{leadData?.leadData?.name}}</nz-breadcrumb-item> 
      </nz-breadcrumb>

      <h2 class="p-0 m-0" nz-typography>Warranty</h2>
      
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <div class="row famly-inn gx-3">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-head border-bottom">
            <div class="row py-2">
              <h3 nz-typography><span class="ms-3">Information</span></h3>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <div class="row">
                  <div class="col-12">
                    <label class="sub-text">Claim #<i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover
                        [nzPopoverContent]="Daily" nzPopoverPlacement="right"></i>
                      <ng-template #Daily>
                        <div class="rounded-2">
                          <p style="width: 210px; font-size: 13px;">
                            Change Order numbers are auto assigned based on your previous numbers used per job.<br>
                            <span class="row mt-3">
                              <div class="col-12">Recently Added:</div>
                            </span>
                            <span class="row mt-3">
                              <div class="col-12">0001 - YOUSUF TEST F3</div>
                            </span>
                          </p>
                        </div>
                      </ng-template>
                    </label>
                  </div>
                </div>
                <div>
                  <nz-input-group class="rounded-1" nzAddOnBefore="ZH-25JURA-">
                    <input type="text" nz-input [(ngModel)]="warrantyFieldValues['claimNumber']" name="claimNumber" />
                  </nz-input-group>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="sub-text">Title <span class="text-danger fw-bold">*</span></label>
                    <input [(ngModel)]="warrantyFieldValues['title']" name="title" type="text"
                      class="form-control formField" />
                  </div>
                </div>


                <!-- Saim starting Work || 4/15/2024 || Saim_Raza_Warranty_Form -->
                <div nz-row>
                  <div nz-col nzSpan="18">
                    <label class="sub-text">Category</label>
                    <nz-select nzShowSearch [(ngModel)]="warrantyFieldValues['warrantyCategorySetupId']"
                      name="warrantyCategorySetupId">
                      <nz-option *ngFor="let option of categoryOption" [nzLabel]="option.label"
                        [nzValue]="option.value"></nz-option>
                    </nz-select>
                  </div>
                  <div nz-col nzSpan="6" style="margin-top: 30px; padding: 0px">
                    <button class="btn btn-sm text-primary" (click)="showTags()">
                      Add
                    </button>
                    <button [disabled]="!warrantyFieldValues['warrantyCategorySetupId']"
                      class="btn btn-sm text-primary border-0 me-1" 
                      (click)="showTagEdit(warrantyFieldValues.warrantyCategorySetupId)">
                      Edit
                    </button>

                  </div>
                </div>
                <!-- Saim end Work || 4/15/2024 || Saim_Raza_Warranty_Form -->

                <div class="row">
                  <div class="col-7">
                    <label class="sub-text">Priority</label>
                    <nz-select nzShowSearch nzAllowClear [(ngModel)]="warrantyFieldValues['prioritySetupId']"
                      name="prioritySetupId">
                      <nz-option *ngFor="let option of priorityOption" [nzLabel]="option.label"
                        [nzValue]="option.value"></nz-option>
                    </nz-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12"><label class="sub-text">Problem Description</label></div>
            </div>
            <div class="row">
              <div class="col-12">
                <textarea style="border-radius: 5px;" [(ngModel)]="warrantyFieldValues['problemDescription']"
                  name="problemDescription" rows="2" class="formField" nz-input></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-12"><label class="sub-text">Internal
                  Comments</label></div>

            </div>
            <div class="row">
              <div class="col-12">
                <textarea style="border-radius: 5px;" [(ngModel)]="warrantyFieldValues['internalComments']"
                  name="internalComments" rows="2" class="formField" nz-input></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-0 mt-3">
          <div class="card-head border-bottom">
            <div class="row py-2">
              <h3 nz-typography><span class="ms-3">Assigned Information</span></h3>
            </div>
          </div>
          <div class="card-body">
            <div class="row gx-5">
              <div class="col-3">
                <div class="row">
                  <div class="col-12">
                    <label class="sub-text">Service Coordinator<i class="bi bi-info-circle-fill text-secondary ms-1"
                        nz-popover [nzPopoverContent]="Daiyly" nzPopoverPlacement="right"></i>
                      <ng-template #Daiyly>
                        <div class="rounded-2">
                          <p style="width: 210px; font-size: 13px;">
                            This Service Coordinator box allows you to limit<br> communications on this warranty request
                            to one employee.
                          </p>
                        </div>
                      </ng-template>
                    </label>
                    <nz-select [(ngModel)]="warrantyFieldValues['coordinatorUserId']" name="coordinatorUserId"
                      style="width: 100%" nzShowSearch>
                      <nz-option *ngFor="let option of serviceCoordinator" [nzLabel]="option.label"
                        [nzValue]="option.value"></nz-option></nz-select>


                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label class="sub-text">Classification <span class="text-danger fw-bold">*</span><i
                        class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="swarranty"
                        nzPopoverPlacement="top"></i>
                      <ng-template #swarranty>
                        <div class="rounded-2">
                          <p style="width: 210px; font-size: 13px;">

                            Classifications designated 'closed' will close the claim regardless of its service appointment status.
                            </p>
                        </div>
                      </ng-template>
                    </label>
                    <nz-select nzShowSearch nzAllowClear [(ngModel)]="warrantyFieldValues['classificationSetupId']"
                      name="classificationSetupId">
                      <nz-option *ngFor="let option of classificationOption" [nzLabel]="option.name"
                        [nzValue]="option.id"></nz-option>
                    </nz-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label class="sub-text">Added Cost</label>
                    <!-- <currency-dollar-field></currency-dollar-field> -->
                    <nz-input-number [(ngModel)]="warrantyFieldValues['addedCost']" name="addedCost" type="numbers"
                      nz-input class="w-100" nzPlaceHolder=""></nz-input-number>
                  </div>
                </div>
              </div>
              <div class="col-2"></div>
              <div class="col-3">
                <div class="row mt-4">
                  <div class="col-12">
                    <label [(ngModel)]="warrantyFieldValues['isShowOwner']" name="isShowOwner" nz-checkbox>Show
                      Owner?</label>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <label class="sub-text">Orig. Item/User
                      <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="Da1iyly"
                        nzPopoverPlacement="right"></i>
                      <ng-template #Da1iyly>
                        <div class="rounded-2">
                          <p style="width: 210px; font-size: 13px;">
                            Orig. Item/User does not schedule the user for this claim. This dropdown represents the
                            original user who performed the work now under claim.
                          </p>
                        </div>
                      </ng-template>
                    </label>
                    <nz-select [(ngModel)]="warrantyFieldValues['scheduleItemParameterId']"
                      name="scheduleItemParameterId" style="width: 100%" nzShowSearch [lang]="true" nzAutoFocus="true"
                      nzPlaceHolder="Unassigned" [(ngModel)]="selectedValue">
                      <nz-option *ngFor="let option of scheduleItemOption" [nzLabel]="option.title"
                        [nzValue]="option.id"></nz-option>
                    </nz-select>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <label class="sub-text">Follow-Up Date</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <nz-date-picker [(ngModel)]="warrantyFieldValues['followUpOn']" [nzFormat]="'MMM d,YYYY'"
                      name="followUpOn" [nzPlaceHolder]="' '" nzSuffixIcon="false"></nz-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Saim works on warranty form || 4/16/2024 || Saim_Raza_ -->
        <nz-card [nzTitle]="title" [nzExtra]="extraTemplate">
          <ng-template #title>
            <h4 class="small-title with-border"><span class="ms-3">Service Appointments</span></h4>
          </ng-template>
          <ng-template #extraTemplate>
            <i class="bi bi-info-circle-fill text-secondary me-2" nz-popover [nzPopoverContent]="Daiyly"
              nzPopoverPlacement="top" *ngIf="isFormSaved"></i>
            <ng-template #Daiyly>
              <div class="rounded-2">
                <p style="width: 210px; font-size: 13px;">
                  The <span class="fw-bold">Schedule Service </span> button allows you to schedule a date/time for a
                  subs/vendors to perform a service at the job. <br><br>

                  The <span class="fw-bold">Log Completed Service</span> button allows you to enter information about a
                  service that has already been completed. </p>
              </div>
            </ng-template>

            <nz-space *ngIf="isFormSaved" class="mb-5">

              <button *nzSpaceItem nz-button nzType="default" class="rounded-1"
                (click)="OpenLogCompletedService(false)">Log Completed Service</button>

              <button *nzSpaceItem nz-button nzType="primary" class="ms-2 rounded-1"
                (click)="OpenLogCompletedService(true)">Schedule Service Appointment</button>
            </nz-space>
          </ng-template>
          
            <nz-card class="border" *ngIf="isFormSaved">
            
              <nz-table #smallTable nzSize="small" [nzData]="warrantyServiceData" >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Scheduled for</th>
                    <th>Appointment Status</th>
                    <th>Feedback</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody *ngIf="warrantyServiceData">
                  <tr   *ngFor="let data of warrantyServiceData">
                    <td>{{ data?.assignToUser?.firstName }}</td>
                    <td>
                      {{ data?.createdOn | date: 'MMM d, yyyy' }} , 
                      {{ data?.startTime | date: 'h:mm a' }}  
                      {{ data?.endTime | date: 'h:mm a' }}
                    </td>
                    <td>
                      <i class="bi bi-house-door"></i>
                      <span class="fw-bold text-secondary"> Never Accepted</span>
                      <p class="fw-bold">
                        <p>
                          <i class="bi bi-people"></i> 
                          <span class="fw-bold" style="color: #52A300"> Internally Accepted</span> -
                          {{ data?.serviceOn | date: 'MMM d, yyyy'   }} ,{{ data?.serviceOn | date: 'h:mm a' }}

                        </p>
                      </td>
                    <td>
                      <span nz-icon [nzType]="'check-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'" style="font-size: 20px;"></span> <p>{{data?.createdOn | date: 'MMM d, yyyy'}} {{ data?.completionTime | date: 'h:mm a' }}</p>
                    </td>
                    <td>
                      <button nz-button nzType="default"   (click)="openEditMode(responseData)">
                        <span nz-icon nzType="edit" nzTheme="fill"></span>
                        Edit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
              
                
            </nz-card>
          <p *ngIf="!isFormSaved">Please save this warranty claim above before scheduling a service appointment.
          </p>
        </nz-card>



        <nz-card nzBorderless="true" class="mt-2 rounded-1" [(ngModel)]="warrantyFieldValues['attachmentId']"
          name="attachmentId" [nzTitle]="Attachments">
          <ng-template #Attachments>
            <h4>Attachments</h4>
          </ng-template>
          <div class="d-flex align-items-center mt-2">
   
          </div>
          <div class="row">
            <div class="col-5">
              <button (click)="UploadFiles()" nz-button nzType="default" class="rounded-1 me-2">Add</button>
              <button (click)="CreateNew()" nz-button nzType="default" class="rounded-1 me-2">Create New
                File</button>
              <button (click)="viewAll()" *ngIf="selectedFiles.length > 0" nz-button nzType="default"
                class="rounded-1 me-2">View All ({{ selectedFiles.length }})</button>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-1 filUpload me-3" *ngFor="let file of selectedFiles">
              <div class="row gx-0 mt-2 p-0 m-0">
                <div class="col-12 mt-2">
                  <span class="m-0 p-0d d-flex align-items-center justify-content-center"
                    style="border-radius: 6px; object-fit: cover;" nz-icon nzType="file-text" nzTheme="outline"></span>
                </div>
              </div>
              <div class="row p-0 m-0">
                <div class="col-12">
                  <label class="ListViewJobname">{{file.name}}</label>
                </div>
              </div>
              <div class="row p-0 m-0">
                <div class="col-12">
                  <i nzTrigger="click" nzType="down" nz-dropdown [nzDropdownMenu]="menu4"
                    class="fa-solid fa-chevron-down fw-bolder"></i>
                  <nz-dropdown-menu #menu4="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item><i class="fa-solid fa-pen me-2"></i>Edit Online</li>
                      <li (click)="removeFile(file)" nz-menu-item><i class="fa-regular fa-trash-can me-2"></i>Delete
                      </li>
                    </ul>
                  </nz-dropdown-menu>
                </div>
              </div>
            </div>
          </div>
        </nz-card>


        <ng-template #extraTemplate>
        </ng-template>

             <!-- new -->
             <ng-container *ngIf="responseHeaderId === -1">
              <messaging-common
              [Job_ID]="jobID"
              [Form_Name_ID]="formNameID"
              [Message_header_ID]="messagingId"
              [Message_headerSet_ID]="messagingIdRow"
              >
              </messaging-common>
            </ng-container>
            <!-- new -->

        <div class="card border-0 mt-3">

         
          <nz-card nzTitle="" *ngIf="isFormSaved">
            <div class="row">
              <div class="col-11">
                <h5 nz-typography>rFI'S</h5>

              </div>
              <div class="col-1">
                <button nz-button nzType="default" (click)="openrFISForm(HeaderId,10002)" >Create RFI</button>
              </div>

            </div>

          </nz-card>
        </div>
      </div>
    </div>
  </div>
  <ng-template #NewDaily2>
    <!-- <button nz-button nzType="primary" class="rounded-1 me-0">Save & New</button> -->

    <!-- Saim Start work || 4/16/2024 || Saim_Raza_Warranty_Form -->
    <button *ngIf="responseHeaderId === -1" nz-button nzType="primary" class="rounded-1 me-0"
      [disabled]="!warrantyFieldValues['title'] ||!warrantyFieldValues['classificationSetupId']"
      (click)="saveWarrantyForm()">Save</button>

    <button *ngIf="responseHeaderId > 0 " nz-button nzType="primary" class="rounded-1 me-0"
      [disabled]="!warrantyFieldValues['title'] ||!warrantyFieldValues['classificationSetupId']"
      (click)="createMessage()">Update</button>


  </ng-template>
</nz-modal>




<nz-modal nzWidth="35%" [(nzVisible)]="isTagVisible" [nzTitle]="divsionTradeTitle" [nzFooter]="divsionTradeFooter"
  [nzBodyStyle]="{ background: '#f1f4fa', padding: '14px' }" [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="showConfirmData()">
  <ng-template #divsionTradeTitle>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0">Add Warranty Category</h1>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <form [formGroup]="TagsForm">
      <nz-card [nzBorderless]="false" nzTitle="Add Warranty Category">
        <div class="row">
          <div class="col">
            <label class="sub-text">Title<span class="text-danger fw-bolder">*</span></label>
            <input id="inputField" formControlName="name" type="text" class="form-control formField" [ngClass]="{
                error:
                  TagsForm.get('name')?.invalid &&
                  TagsForm.get('name')?.touched
              }" />
            <div *ngIf="
                TagsForm.get('name').hasError('required') &&
                TagsForm.get('name').touched
              " class="text-danger p-error">
              Required
            </div>
          </div>
        </div>
      </nz-card>
    </form>
  </div>
  <ng-template #divsionTradeFooter>
    <div class="mt-2 align-items-center">
      <button *ngIf="isLoadingEdit" nz-button nzType="default" class="rounded-1 me-0" (click)="ConfirmDeleteTags()">
        Delete
      </button>

      <button nz-button nzType="primary" class="rounded-1 me-0" (click)="SaveOrUpdateData()">
        {{ isLoadingEdit ? "Update" : "Save" }}
      </button>



    </div>
  </ng-template>
</nz-modal>