


<nz-modal
  nzWidth="95%"
  [(nzVisible)]="LeadOpportunityImportLead"
  [nzTitle]="wizradModalTitle"
  [nzFooter]="wizardModalfooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '75vh',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="LeadOpportunityImportLeadModelCancel()"
>
  <ng-template #wizradModalTitle>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0 fw-bold">Import Wizard</h2>
    </div>
  </ng-template>

  <div class="content content-set-background" *nzModalContent>
    <nz-card style="padding: 0; padding-top: 0" [nzSize]="'small'">
        <nz-steps [nzCurrent]="current">
            <nz-step nzTitle="Choose File"></nz-step>
            <nz-step nzTitle="Map Columns"></nz-step>
            <nz-step nzTitle="Confirm Import"></nz-step>
        </nz-steps>
    </nz-card>
    
 
    
    <nz-card class="mt-4" [nzTitle]="ChooseFile">
        <ng-template #ChooseFile>
            <h4 class="font-set  famly-inn">Choose File</h4>
        </ng-template>
        <div class="row">
          <div class="col-12">
            <p style="font-size: 16px">
              To begin the import process, select a spreadsheet with your data and
              click the next button to proceed.
              <a href="path/to/excel/template.xlsx" class="text-primary" download>
                Download Excel Template
              </a>
            </p>
          </div>
        </div>
  
        <div class="row mb-0 mt-5">            
            <div class="col-lg-8 offset-lg-2">
               <div class="col text-center" style="margin-bottom: 33px;">
                <span nz-icon nzType="file" nzTheme="outline" class="large-icon "></span>
               </div>
                <span class="text-center "><h3 class="fw-bold famly-inn mb-4">Drop files here to upload, or browse for files.</h3></span>
            <div class="col mt-4 text-center" >
                <button nz-button nzType="primary" class="me-2 " >Browse Computer</button>
                <button nz-button nzType="default">Browse Buildertrend</button>
            </div>
        </div>
        </div>
  
       
      </nz-card>
  </div>
  <ng-template #wizardModalfooter>
    <div class="mt-2 d-flex justify-content-between">
      <div>
        <a
          nz-button
          nzType="text"
          style="border: 2px solid #f1f4f1"
          class="rounded-1"
          disabled
          >Previous</a
        >
      </div>

      <div>
        <button nz-button nzType="primary" class="rounded-1 me-0">Next</button>
      </div>
    </div>
  </ng-template>
</nz-modal>



