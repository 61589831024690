import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-project-management-site-diaries-setting',
  templateUrl: './project-management-site-diaries-setting.component.html',
  styleUrls: ['./project-management-site-diaries-setting.component.css']
})
export class ProjectManagementSiteDiariesSettingComponent {
  @Output() cancel = new EventEmitter<void>();
  constructor(private fb:FormBuilder){}

    // selectionModel
    selectionModelhandle=true;
    selectionModelCancel(): void {
    this.cancel.emit();
    }



   
// Form Group
siteDiariesSetting: FormGroup;


initializeForm(): void {
  this.siteDiariesSetting = this.fb.group({
    stampLocation: [null],
    dailyLog: [null],
    includeWeather: [null],
    includeNotes: [null],
    // Share                 // Notify
    internalShare:[null],  internalNotify:[null],
    subsShare:[null],      subsNotify:[null],
    ownerShare:[null],     ownerNotify:[null],
   
   
  });
}





    autoAdjustTextAreaHeight(event: Event): void {
      const textarea = event.target as HTMLTextAreaElement;
      textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
      textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; // Set the height with a maximum of 200px
    }
  
    customfieldinfo:boolean = false;
    CustomFieldInfo(){
      this.customfieldinfo = true;
    }
    handleCancel2(): void {
      this.customfieldinfo = false;
    }

   

}
