<nz-modal nzWidth="97%" [(nzVisible)]="Taghandle" [nzTitle]="NewDaily1" [nzFooter]="NewDaily2" (nzOnCancel)="TagClose()"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '68vh', 'overflow-y': 'auto'}"
  [nzStyle]="{ top: '18px' }">
  <ng-template #NewDaily1>
    <div class="row p-0 m-0">
      <nz-breadcrumb nz-page-header-breadcrumb>
        <nz-breadcrumb-item>{{ leadData?.name }}</nz-breadcrumb-item>
        <nz-breadcrumb-item>{{JOBDATA?.JOB_NAME}}</nz-breadcrumb-item>
      </nz-breadcrumb>

      <h2 class="p-0 m-0">Change Order</h2>

    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <div class="row famly-inn gx-3">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="card border-0 mt-0">
              <div class="card-head border-bottom">
                <div class="row py-2">
                  <h3 nz-typography><span class="ms-3">Change Order Information</span></h3>
                </div>

              </div>
              <div class="card-body">
                <div class="row gx-4">
                  <div class="col-4">
                    <label class="sub-text">Title <span class="text-danger fw-bolder ms-1">*</span> </label>
                    <input [(ngModel)]="formFieldValues.title" name="title" type="text" class="form-control formField" />
                  </div>
                  <div class="col-2">
                    <label class="sub-text">ID#
                      <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="Daily"
                        nzPopoverPlacement="right"></i>
                      <ng-template #Daily>
                        <div class="rounded-2">
                          <p style="width: 210px; font-size: 13px;">
                            Change Order numbers are auto assigned based on your previous numbers used per job.<br>
                            <span class="row mt-3">
                              <div class="col-12">Recently Added:</div>
                            </span>
                            <span class="row mt-3">
                              <div class="col-12">0001 - YOUSUF TEST F3</div>
                            </span>
                          </p>
                        </div>
                      </ng-template>
                    </label>
                    <input [(ngModel)]="formFieldValues.rfiFullNumber" name="rfiFullNumber" type="text" class="formField">
                  </div>
                  <div class="col-4">
                    <div class="row">
                      <div class="col-5">
                        <label class="sub-text">Approval Deadline
                          <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="Daily1"
                            nzPopoverPlacement="top"></i>
                          <ng-template #Daily1>
                            <div class="rounded-2">
                              <p style="width: 210px; font-size: 13px; line-height: 25px;">
                                The <strong> Approval Deadline</strong> is the date an owner must make an approval or
                                declination of the Change Order by.
                              </p>
                            </div>
                          </ng-template>
                        </label>
                        <nz-date-picker [(ngModel)]="formFieldValues.approvalDeadlineOn" name="approvalDeadlineOn"
                          [nzPlaceHolder]="' '" nzSuffixIcon="false"></nz-date-picker>
                      </div>
                      <div class="col-5">
                        <label class="sub-text">Approval Deadline</label>
                        <div class="row">
                          <label>Never</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-0 mt-3">
              <div class="card-head border-bottom">
                <div class="row py-2">
                  <h3 nz-typography><span class="ms-3">Price Information</span></h3>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-9">
                    <nz-radio-group>
                      <label  [(ngModel)]="formFieldValues['isFlatFree']" name="isFlatFree" nz-radio-button nzValue="large" class="Choose" (click)="confirmSwitchToFlatFee()">Flat Fee</label>
                      <label  [(ngModel)]="formFieldValues['isLineItems']" name="isLineItems" (click)="showRow()" nz-radio-button nzValue="default" class="link">Line Items</label>
                    </nz-radio-group>
                    <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="Daily2"
                      nzPopoverPlacement="right"></i>
                    <ng-template #Daily2>
                      <div class="rounded-2">
                        <div style="width: 210px; font-size: 13px; line-height: 25px;">
                          Selecting <strong> Flat Fee</strong>will allow you to enter one amount for the entire change
                          order.
                          <div class="row mt-2">
                            <div class="col-12">
                              Selecting <strong>Line Items</strong> will allow you to enter multiple line items broken out
                              by cost codes.
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>

                  </div>
                  <div class="col-3 text-end ">
                    <button (click)="Catalog('CHANGE_ORDER')" nz-button nzType="default" class="rounded-1 me-2">Add From Catalog</button>
                  </div>
                </div>

                <div class="row mt-3" *ngIf="isRowVisible1">
                  <div class="col-12">
                    <div class="row gx-3">
                      <div class="col-3">
                        <label class="sub-text">Builder Cost</label>
                        <div class="input-group mb-3">
                          <span class="input-group-text d-flex justify-content-center align-items-center shadow-none"
                            style="width: 5px; height: 30px;">$</span>
                          <input [(ngModel)]="formFieldValues.builderCost" name="builderCost" pInputText type="text"
                            class="radius-work p-inputtext-sm form-control  shadow-none"
                            style="height: 30px; border-top-left-radius: 0; border-bottom-left-radius: 0;">
                        </div>
                      </div>
                      <div class="col-3">
                        <label class="sub-text">Owner Price</label>
                        <div class="input-group mb-3">
                          <span class="input-group-text d-flex justify-content-center align-items-center shadow-none"
                            style="width: 5px; height: 30px;">$</span>
                          <input [(ngModel)]="formFieldValues.ownerPrice" name="ownerPrice" pInputText type="text"
                            class="radius-work p-inputtext-sm form-control  shadow-none"
                            style="height: 30px; border-top-left-radius: 0; border-bottom-left-radius: 0;">
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3" *ngIf="isRowVisible">
                  <div class="col-12">
                    <label   [(ngModel)]="formFieldValues['isShowLineItemsToOwner']" name="isShowLineItemsToOwner" nz-checkbox>Show Line Items To Owner</label>
                    <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="Daily2"
                      nzPopoverPlacement="right"></i>
                    <ng-template #Daily2>
                      <div class="rounded-2">
                        <p style="width: 210px; font-size: 13px; line-height: 25px;">
                          Owner will not be permitted to see builder cost or markup. Description, Quantity, Unit Price and
                          Price will be shown for each line item.
                        </p>
                      </div>
                    </ng-template>
                    <nz-table [nzFooter]="customfoter" nzFooter="Footer" [nzBordered]="false" nzSize="small" #editRowTable
                      nzBordered nzShowPagination="false" [nzScroll]="{ x: '1625px'}">
                      <thead style="background-color: #f1f4fa;border-bottom: #ccc;">
                        <tr>
                          <!-- <th nzLeft nzWidth="6%" class="sub-text1"><label nz-checkbox></label></th> -->
                          <th nzLeft nzWidth="7%" class="sub-text1"><span
                              style="align-items: center; position: sticky;"><label nz-checkbox></label></span></th>
                          <th nzLeft nzWidth="55%" class="sub-text1">Items</th>
                          <th nzWidth="55%" class="sub-text1">Description</th>
                          <th nzWidth="20%" class="sub-text1 text-end">Quantity</th>
                          <th nzWidth="17%" class="sub-text1 text-start">Unit</th>
                          <th nzWidth="25%" class="sub-text1 text-end">Unit Cost</th>
                          <th nzWidth="30%" class="sub-text1 text-start">Cost Type</th>
                          <th nzWidth="23%" class="sub-text1 text-end">Builder Cost</th>
                          <th nzWidth="26%" class="sub-text1">Markup</th>
                          <th nzWidth="25%" class="sub-text1  text-end">Owner Price</th>
                          <th nzWidth="17%" class="sub-text1  text-end">Margin</th>
                          <th nzWidth="17%" class="sub-text1  text-end">Profit</th>
                          <th nzRight nzWidth="18%" class="sub-text1"></th>
                        </tr>
                      </thead>
                      <tr class="border-top hoverable">
                        <td class="border" [colSpan]="9">
                          <button (click)="addNewRow()" nz-button nzType="link" class="text-primary fs-6">
                            <span class="text-primary me-2 fs-5" nz-icon nzType="plus-circle" nzTheme="fill"></span>
                            <span class="fs-6">Item</span>
                          </button>
                          <!-- Validation message for adding new row -->
                        </td>
                      </tr>
                      <tbody *ngFor="let item of items; let i=index">
                        <tr class="ms-2 bard" style="background-color: #e6f6ff;border: solid 2px #ccc">
                          <td nzLeft style="background-color: #e6f6ff;">
                            <label nz-checkbox></label>
                          </td>
                          <td nzLeft style="width: 40%; background-color: #e6f6ff;">
                            <label class="sub-text">Title</label>
                            <input [(ngModel)]="item.title" name="title_{{i}}" type="text" class="formField" required>

                          </td>
                          <td style="width: 17%;">
                            <label for="" class="sub-text">Description</label>
                            <textarea [(ngModel)]="item.description" name="description_{{i}}" style="border-radius: 5px;"
                              rows="2" class="ant-input1 formField" nz-input></textarea>
                          </td>
                          <td>
                            <label for="" class="sub-text">Quantity</label>
                            <nz-input-number [(ngModel)]="item.quantity" name="quantity_{{i}}"
                              style="width: 100%;border-radius: 4px;"></nz-input-number>
                          </td>
                          <td>
                            <label for="" class="sub-text">Unit</label>
                            <input [(ngModel)]="item.unit" name="unit_{{i}}" type="text" nz-input class=" formField" />
                          </td>
                          <td>
                            <label for="" class="sub-text">Unit Cost</label>
                            <nz-input-group nzAddOnBefore="$" style="width: 100%;">
                              <input [(ngModel)]="item.unitCost" name="unitCost_{{i}}" type="text" nz-input
                                class=" formField" />

                            </nz-input-group>
                          </td>
                          <td style="width: 17%;">
                            <label for="" class="sub-text">Cost Type</label>
                            <nz-tree-select [(ngModel)]="item.createCostTypeItemParameterRequests" name="costTypeId_{{i}}" style="width: 100%"
                                [nzNodes]="proposalStatusNodes" nzShowSearch nzAllowClear="false" nzCheckable
                                nzCheckAll="true" nzPlaceHolder="Select Division">
                            </nz-tree-select>
                        </td>

                          <td>
                            <div class="row">
                              <label class="sub-text text-end">Builder Cost</label>
                            </div>
                            <div class="row">
                              <span class="fw-bold mb-2 text-end">{{ item.builderCost }}</span>
                            </div>
                          </td>
                          <td>
                            <div class="row">
                              <label class="sub-text">Markup</label>
                            </div>
                            <div class="row gx-0">
                              <div class="col-8">
                                <input [(ngModel)]="item.markupValue" name="markupValue_{{i}}" type="text" nz-input
                                  class="formField" (ngModelChange)="calculateMarginAndProfit()" />
                              </div>
                              <div class="col-4">
                                <nz-select [(ngModel)]="item.markupId" name="markupId_{{i}}" class="custom-select"
                                  nzShowSearch>
                                  <nz-option nzLabel="%" nzValue="%"></nz-option>
                                  <nz-option nzLabel="$" nzValue="$"></nz-option>
                                  <nz-option nzLabel="$/Unit" nzValue="$/Unit"></nz-option>
                                  <nz-option nzLabel="--" nzValue="--"></nz-option>
                                </nz-select>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row">
                              <label class="sub-text">Owner Price</label>
                            </div>
                            <div class="row">
                              <!-- <span class="fw-bold mb-2 text-end">{{ tableFormFieldValues['ownerPrice'] }}</span> -->
                              <span class="ant-input-wrapper ant-input-group"><span
                                  class="ant-input-group-addon">$</span><input class="ant-input ant-input-disabled"
                                  disabled="" data-testid="lineItems[0].ownerPrice" id="lineItems[0].ownerPrice" type="text"
                                  value="0.00"></span>
                              <!--
                                      <nz-input-group nzDisabled nzAddOnBefore="$" style="width: 100%;">
                                        <input [(ngModel)]="tableFormFieldValues['ownerPrice']" name="ownerPrice" type="text" nz-input class="formField" (ngModelChange)="calculateBuilderCost()" />
                                    </nz-input-group> -->
                            </div>
                          </td>
                          <td>
                            <div class="row">
                              <label class="sub-text text-end">Margin</label>
                            </div>
                            <div class="row">
                              <span class="fw-bold mb-2 text-end" [(ngModel)]="item.margin" name="margin_{{i}}">{{
                                item.margin}}%</span>
                            </div>
                          </td>
                          <td>
                            <div class="row">
                              <label class="sub-text text-end">Profit</label>
                            </div>
                            <div class="row">
                              <!-- <span class="fw-bold mb-2 text-end" [(ngModel)]="items[i]['profit']" name="profit">{{ items[i]['profit'] }}</span> -->
                              <span class="fw-bold mb-2 text-end" [(ngModel)]="item.profit" name="profit{{i}}">{{
                                item.profit}}</span>

                            </div>
                          </td>
                          <td nzRight style="background-color: #e6f6ff;">
                            <span (click)="deleteRow(i)" nz-icon nzType="delete"
                              class="text-primary text-center fs-4 k-cursor-pointer" nzTheme="fill"></span>
                          </td>
                        </tr>
                        <tr class="ms-2 bard" style="background-color: #e6f6ff;border: solid 2px #ccc">
                          <td nzLeft style="width: 40%;background-color: #e6f6ff;">

                          </td>
                          <td nzLeft style="width: 40%; background-color: #e6f6ff;">
                            <label class="sub-text">Cost Code <span class="fw-bolder text-danger">*</span> </label>
                            <nz-select nzShowSearch nzAutoFocus="true" nzPlaceHolder="Select Lead"
                              [(ngModel)]="item.costCodeId" name="costCodeId_{{i}}">
                              <nz-option *ngFor="let option of leadOptions" [nzLabel]="option.title"
                                [nzValue]="option.id"></nz-option>
                            </nz-select>
                            <div *ngIf="showValidationMessage && !items[i]?.title" class="text-danger">Required</div>
                          </td>


                          <td style="width: 17%;">
                          </td>
                          <td>
                          </td>
                          <td>
                          </td>
                          <td>
                          </td>
                          <td style="width: 17%;">
                          </td>
                          <td>
                          </td>
                          <td>
                          </td>
                          <td>
                          </td>
                          <td>
                          </td>
                          <td>
                          </td>
                          <td nzRight style="background-color: #e6f6ff;">
                          </td>
                        </tr>
                      </tbody>
                      <ng-template #customfoter>
                        <tfoot>
                          <tr>
                            <td class="sub-text fs-6">Total</td>
                          </tr>
                        </tfoot>
                      </ng-template>
                    </nz-table>
                    <div class="row mt-3 mb-3">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12 text-end">
                            <h4 nz-typography>Owner Price: $0.00</h4>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-12 text-end"><label class="k-text-underline text-primary" nzPopoverTrigger="click"
                              nz-popover [nzPopoverContent]="Daily21" nzPopoverPlacement="left">See full price
                              breakdown</label></div>
                        </div>
                        <ng-template #Daily21>
                          <div class="rounded-2">
                            <div class="row p-2" style="width: 210px; font-size: 13px; line-height: 25px;">
                              <div class="col-12">
                                <div class="row hoverJob p-1">
                                  <div class="col-9">
                                    <div class="ListViewJobname text-center">Total Builder Cost</div>
                                  </div>
                                  <div class="col-3">
                                    <div class="ListViewJobname">$0.00</div>
                                  </div>
                                </div>
                                <div class="row hoverJob p-1">
                                  <div class="col-9">
                                    <div class="ListViewJobname text-center">Total Markup</div>
                                  </div>
                                  <div class="col-3">
                                    <div class="ListViewJobname">$0.00</div>
                                  </div>
                                </div>
                                <div class="row hoverJob">
                                  <div class="col-9">
                                    <div class="sub-text text-center">Owner Price</div>
                                  </div>
                                  <div class="col-3">
                                    <div class="sub-text">$0.00</div>
                                  </div>
                                </div>
                                <div class="row hoverJob p-1">
                                  <div class="col-9">
                                    <div class="ListViewJobname text-center">Total Margin %</div>
                                  </div>
                                  <div class="col-3">
                                    <div class="ListViewJobname">0.00%</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                    </div>

                  </div>
                </div>



              </div>
            </div>
          </div>
        </div>
        <div class="row mt-0">
          <div class="col-7">
            <div class="card border-0 mt-3">
              <div class="card-head border-bottom">
                <div class="row py-2">
                  <h3 nz-typography><span class="ms-3">Attachments</span></h3>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <button (click)="UploadFiles()" nz-button nzType="default" class="rounded-1 me-2">Add</button>
                    <button (click)="CreateNewFile()" nz-button nzType="default" class="rounded-1 me-2">Create New
                      File</button>
                    <button (click)="viewAll()" *ngIf="selectedFiles.length > 0" nz-button nzType="default"
                      class="rounded-1 me-2">View All ({{ selectedFiles.length }})</button>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-1 filUpload me-3" *ngFor="let file of selectedFiles">
                    <div class="row gx-0 mt-2 p-0 m-0">
                      <div class="col-12 mt-2">
                        <span class="m-0 p-0d d-flex align-items-center justify-content-center"
                          style="border-radius: 6px; object-fit: cover;" nz-icon nzType="file-text"
                          nzTheme="outline"></span>
                      </div>
                    </div>
                    <div class="row p-0 m-0">
                      <div class="col-12">
                        <label class="ListViewJobname">{{file.name}}</label>
                      </div>
                    </div>
                    <div class="row p-0 m-0">
                      <div class="col-12">
                        <i nzTrigger="click" nzType="down" nz-dropdown [nzDropdownMenu]="menu4"
                          class="fa-solid fa-chevron-down fw-bolder"></i>
                        <nz-dropdown-menu #menu4="nzDropdownMenu">
                          <ul nz-menu>
                            <li nz-menu-item><i class="fa-solid fa-pen me-2"></i>Edit Online</li>
                            <li (click)="removeFile(file)" nz-menu-item><i class="fa-regular fa-trash-can me-2"></i>Delete
                            </li>
                          </ul>
                        </nz-dropdown-menu>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-0 mt-3">
              <div class="card-head border-bottom">
                <div class="row py-2">
                  <h3 nz-typography><span class="ms-3">Notes</span></h3>
                </div>
              </div>
              <div class="card-body">
                <nz-tabset nzType="card">
                  <nz-tab *ngFor="let tab of tabs" [nzTitle]="innerCardTabs(tab)" style="background-color: #f1f4fa;">
                    <ng-container *ngIf="tab === 1">
                      <div class="row p-2">
                        <div class="col-12">
                          <label class="sub-text">Notes</label>
                          <textarea [(ngModel)]="formFieldValues.internalNotes" name="internalNotes"
                            style="border-radius: 5px;" class="formField" nz-input></textarea>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="tab === 2">
                      <div class="row p-2">
                        <div class="col-12">
                          <label class="sub-text">Notes</label>
                          <textarea [(ngModel)]="formFieldValues.subVendorNotes" name="subVendorNotes"
                            style="border-radius: 5px;" class="formField" nz-input></textarea>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="tab === 3">
                      <div class="row p-2">
                        <div class="col-12">
                          <label class="sub-text">Notes</label>
                          <textarea [(ngModel)]="formFieldValues.ownerNotes" name="ownerNotes"
                            style="border-radius: 5px;" class="formField" nz-input></textarea>
                        </div>
                      </div>
                    </ng-container>
                  </nz-tab>
                </nz-tabset>
              </div>
            </div>
            <div class="card border-0 mt-3">
              <div class="card-head border-bottom">
                <div class="row py-2">
                  <h3 nz-typography><span class="ms-3">Change Order Description</span></h3>
                </div>
              </div>
              <!-- <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <textarea [(ngModel)]="formFieldValues.changeOrderDescription" name="changeOrderDescription" rows="5" style="border-radius: 5px;" class="formField" nz-input></textarea>
                  </div>
                </div>
              </div>
            </div> -->

   <ckeditor
   [(ngModel)]="formFieldValues['changeOrderDescription']"
   name="changeOrderDescription"
   [editor]="Editor"
   [config]="config"
   class="size"
   id="editor">
 </ckeditor>

            <!-- new -->
            <ng-container *ngIf="mainSaveResponseId ">
              <messaging-common
              [Job_ID]="jobID"
              [Form_Name_ID]="formNameID"
              [Message_header_ID]="messagingId"
              [Message_headerSet_ID]="messagingIdRow"
              >
              </messaging-common>
            </ng-container>
            <!-- new -->


            <div class="card border-0 mt-0" *ngIf="showMessagingSection">
              <div class="card-head border-bottom">
                <div class="row py-2">
                  <div class="col-6">
                    <h3 nz-typography><span class="ms-3">RFIs</span></h3>
                  </div>
                  <div class="col-6 text-end">
                    <button (click)="Rfis(mainSaveResponseId,7)" nz-button nzType="default" class="rounded-1 me-2">Create RFI</button>
                  </div>
                </div>
              </div>
              <div class="card-body">

              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="card border-0 mt-3" *ngIf="showMessagingSection">
              <div class="card-head border-bottom">
                <div class="row py-2">
                    <h3 nz-typography><span class="ms-3">Approval Status</span></h3>
                </div>

              </div>
              <div class="card-body">
                <nz-card  nzTitle="Signature" class="signature-card">
                  <img *ngIf="signatureData" [src]="signatureData" alt="Signature" class="signature-image">
                </nz-card>
                <div class="row gx-1">
                  <div class="col-12">
                    <label for="" class="sub-text">Comments</label>
                    <textarea [(ngModel)]="formFieldValues.changeOrderDescription" name="changeOrderDescription"
                      rows="5" style="border-radius: 5px;" class="formField" nz-input></textarea>
                  </div>
                </div>
                <div class="row gx-1">
                  <div class="col-12">

                    <button class="btn btn-primary" (click)="Signature()">Approve</button>
                    <button class="btn btn-danger">Desline</button>
                    <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="is"
                      nzPopoverPlacement="top"></i>
                    <ng-template #is>
                      <div class="rounded-2">
                        <p style="width: 210px; font-size: 13px;">
                          Manually Approve or Decline this Change Order for the Owner. Signature options are
                          available in builder setup. </p>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>



            <div class="card border-0 mt-3">
              <div class="card-head border-bottom">
                <div class="row py-2">
                    <h3 nz-typography><span class="ms-3">Subs/Vendors
                    <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="is" nzPopoverPlacement="top"></i>
                    <ng-template #is>
                      <div class="rounded-2">
                        <p style="width: 210px; font-size: 13px;">
                          When this Change Order is approved, any selected subs/vendors will be permitted to view and receive alerts
                        </p>
                      </div>
                    </ng-template>
                    </span></h3>
                </div>
              </div>
              <div class="card-body">
                <div class="row gx-1">
                  <div class="col-12">
                    <nz-tree-select style="width: 100%"
                      [(ngModel)]="formFieldValues['createChangeOrderSubVendorRequests']"
                      name="createChangeOrderSubVendorRequests" [nzNodes]="salespersonMulti" nzShowSearch
                      nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Status">
                    </nz-tree-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-0 mt-3">
              <div class="card-head border-bottom">
                <div class="row py-2">
                    <h3 nz-typography><span class="ms-3">Related Items</span></h3>
                </div>
              </div>
              <div class="card-body">
                <h3 nz-typography><span class="ms-3">Subs/Vendors</span></h3>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>



</nz-modal>
<ng-template #NewDaily2>
  <button nz-button nzType="primary" class="rounded-1 me-0"  (click)="save()">Save</button>
  <button nz-button nzType="primary" class="rounded-1 me-0"  (click)="Signature()">Signature</button>

  <button nz-button nzType="primary" class="rounded-1 me-0" *ngIf="mainSaveResponseId" (click)="createMessage()">Message</button>
</ng-template>
