<nz-modal [(nzVisible)]="isVisibleInChangeOrder" [nzTitle]="InChangeOrder" [nzFooter]="modalFooterInChangeOrder" [nzBodyStyle]="{
  background: '#f1f4fa',
  padding: '14px',
  maxHeight: '69vh',
  'overflow-y': 'auto',
  'overflow-x': 'hidden',
}" [nzStyle]="{ top: '25px'}" [nzWidth]="'96%'" (nzOnCancel)="CancelChangeOrder()">
  <ng-template #InChangeOrder>
    <div class="row">
      <div class="ModalHeaderJobName famly-inn"><div>{{ onSelectedJobRes?.name }}</div></div>
      <h2 class="p-0 m-0 famly-inn ant-typography-Modal">Change Order</h2>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <nz-alert class="rounded-3 mb-3 mt-2 error-message" *ngIf="isSubmitted && errorFieldsItem.length > 0 || errorFields.length > 0" nzType="error" nzShowIcon [nzMessage]="message" [nzDescription]="errorDescriptionTemplate"></nz-alert>
    <ng-template #message>
      <p class="mt-1">Please correct the following fields:</p>
    </ng-template>
    <ng-template #errorDescriptionTemplate>
      <ul *ngFor="let error of errorFields" class="m-0">
        <li class="famly-inn p-0 m-0 ">
          <span class="ms-0">{{ error.label }}:</span>
          <span class="ms-1">{{ error.message }}</span>
        </li>
      </ul>
      <ul class="m-0" *ngIf="isSubmitted && errorFieldsItem.length > 0">
        <li class="famly-inn p-0 m-0">
          <span class="ms-0">Line Items :</span>
        </li>
        <ul class="m-0" *ngFor="let error of errorFieldsItem">
          <li class="famly-inn p-0 m-0">
            <span class="ms-0">{{ error.label }}</span>
            <span class="ms-1">{{ error.message }}</span>
          </li>
        </ul>
      </ul>
    </ng-template>

    <div class="card-container">
      <div class="card border-0">
        <div class="card-head border-bottom pr-0 mr-0">
          <div nz-row>
            <div nz-col nzSpan="12" class="d-flex align-items-center">
              <h2 class="famly-inn ant-typography-crad-Header">Change Order Information</h2>
            </div>
            <div nz-col nzSpan="12" class="d-flex justify-content-end align-items-center">
              <ng-container *ngIf="status">
                <div class="status-text status-tag {{statusClass}}">
                  <span>{{ statusText }}</span>
                  <ng-container *ngIf="currentDate === null; else approvalDeadlineOn">
                    <span class="ms-1">{{ ChangeOrderInformationResponse?.approvalDeadlineOn| date: 'MMM d, yyyy'}}</span>
                  </ng-container>
                  <ng-template #approvalDeadlineOn>
                    <span class="ms-1">{{ currentDate | date: 'MMM d, yyyy'}}</span>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div nz-row [nzGutter]="24">
            <div nz-col nzSpan="8">
              <label class="sub-text">Title<span class="text-danger fw-bolder ms-1">*</span></label>
              <nz-form-item>
                <nz-form-control>
                  <input  #titleInput nz-input [(ngModel)]="ChangeOrderFormFieldsValue.title" name="title"
                    required (ngModelChange)="onFieldChange('title', $event)"
                    [nzStatus]="validationStates['title']" class="formField" />
                </nz-form-control>
                <div *ngIf="validationStates['title'] === 'error'" class="text-danger">
                  {{ validationMessages['title'] }}
                </div>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="5">
              <label class="sub-text">ID#
                <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="onIDTooltipContent" nzPopoverPlacement="right"></i>
                <ng-template #onIDTooltipContent>
                <div style="border-radius: 10px; width: 210px; font-size: 13px; line-height: 25px;">
                  <div nz-row>
                    <div nz-col nzSpan="24">
                      <div class="famly-inn">
                        Change Order numbers are auto assigned based on your previous numbers used per job.
                      </div>
                    </div>
                  </div>
                  <div nz-row class="pt-2">
                    <div nz-col nzSpan="24">
                      <div class="famly-inn">
                        Recently Added:
                      </div>
                    </div>
                  </div>
                  <div nz-row class="pt-2">
                    <div nz-col nzSpan="24">
                      <div class="famly-inn">
                        0001 - 
                        <ng-container *ngIf="onSelectedJobRes?.JobPrefix?.length > 0">
                          <span>{{ onSelectedJobRes?.JobPrefix }}</span>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
                </ng-template>
              </label>
              <nz-form-item>
                <nz-form-control>
                  <div class="input-group">
                    <nz-input-group [nzAddOnBefore]="test">
                      <input 
                        placeholder="(Auto Assign)"
                        nz-input 
                        [(ngModel)]="ChangeOrderFormFieldsValue.autoAssignNumber"
                        name="autoAssignNumber"
                        [nzStatus]="validationStates['autoAssignNumber']"
                        class="formField" 
                      />
                    </nz-input-group>
                    <ng-template #test>
                      <ng-container *ngIf="_ChangeOrderSetupResponse?.changeOrderPrefix?.length > 0">
                        <span>{{ _ChangeOrderSetupResponse?.changeOrderPrefix }}</span> -
                      </ng-container>
                      <ng-container *ngIf="onSelectedJobRes?.JobPrefix?.length > 0">
                        <span>{{ onSelectedJobRes?.JobPrefix }}</span>
                      </ng-container>
                    </ng-template>
                    
                  </div>
                </nz-form-control>
                <div *ngIf="validationStates['autoAssignNumber'] === 'error'" class="text-danger">
                  {{ validationMessages['autoAssignNumber'] }}
                </div>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="4">
              <div nz-row [nzGutter]="5">
                <div nz-col nzSpan="24">
                  <label class="sub-text">Approval Deadline
                    <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="onApprovalDeadlineTooltipContent" nzPopoverPlacement="top"></i>
                    <ng-template #onApprovalDeadlineTooltipContent>
                    <div style="border-radius: 10px; width: 210px; font-size: 13px; line-height: 25px;">
                      <div nz-row>
                        <div nz-col nzSpan="24">
                          <div class="famly-inn">
                            The <strong>Approval Deadline</strong> is the date an owner must make an approval or declination of the Change Order by.
                          </div>
                        </div>
                      </div>
                    </div>
                    </ng-template>
                  </label>
                </div>
                <div nz-col nzSpan="24">
                  <nz-date-picker
                  [(ngModel)]="ChangeOrderFormFieldsValue.approvalDeadlineOn"
                  name="approvalDeadlineOn"
                  nzSuffixIcon="false"
                    [nzFormat]="'MMM d, YYYY'"
                    nzPlaceHolder=" "
                    autocomplete="off"
                ></nz-date-picker>
                </div>
              </div>
            </div>
            <div nz-col nzSpan="3" style="left: -30px;">
              <div nz-row>
                <div nz-col nzSpan="24">
                  <label class="sub-text">Owner Last Viewed</label>
                </div>
                <div nz-col nzSpan="24">
                  <span class="pt-3">Never</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
      <div class="card border-0 mt-3">
        <div class="card-head border-bottom">
          <div class="row">
            <h2 class="famly-inn ant-typography-crad-Header">Price Information</h2>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <nz-radio-group [(ngModel)]="priceInformationradioValue" (ngModelChange)="onRadioChange($event)">
                <label nz-radio-button nzValue="A">Flat Fee</label>
                <label nz-radio-button nzValue="B">Line Item</label>
              </nz-radio-group>
              <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="onLineItemTooltipContent"
                nzPopoverPlacement="right"></i>
              <ng-template #onLineItemTooltipContent>
                <div style="border-radius: 10px; width: 210px; font-size: 13px; line-height: 25px;">
                  <div nz-row>
                    <div nz-col nzSpan="24">
                      <div class="famly-inn">
                        Selecting <strong> Flat Fee</strong>will allow you to enter one amount for the entire change order.
                      </div>
                    </div>
                  </div>
                  <div nz-row class="pt-2">
                    <div nz-col nzSpan="24">
                      <div class="famly-inn">
                        Selecting <strong class="me-2">Line Items</strong>will allow you to enter multiple line items broken out by cost codes.
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <button nz-button nzType="default">Add Form Catalog</button>
            </div>
          </div>
          <div nz-row [nzGutter]="5" class="pt-3" *ngIf="isFlatFeeConfirmed === true">
            <div nz-col nzSpan="6">
              <nz-input-group nzAddOnBefore="$">
                <input
                [(ngModel)]="builderCostFeild"
                name="builderCostFeild"
                nz-input
                appDollarFormatter
                class="form-control formField" 
              />
              </nz-input-group>
            </div>
            <div nz-col nzSpan="6">
              <nz-input-group nzAddOnBefore="$">
                <input
                [(ngModel)]="ownerPriceFeild"
                name="ownerPriceFeild"
                nz-input
                appDollarFormatter
                class="form-control formField" 
              />
              </nz-input-group>
            </div>
          </div>
          <div nz-row class="pt-3" *ngIf="isFlatFeeConfirmed === false">
            <div nz-col nzSpan="24">
              <label nz-checkbox [(ngModel)]="ChangeOrderFormFieldsValue.updateActualDatesBasedOnSchedule">
                Show Line Items To Owner
              </label>
              <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="onLineItemsToOwnerooltipContent" nzPopoverPlacement="right"></i>
              <ng-template #onLineItemsToOwnerooltipContent>
              <div style="border-radius: 10px; width: 210px; font-size: 13px; line-height: 25px;">
                <div nz-row>
                  <div nz-col nzSpan="24">
                    <div class="famly-inn">
                      Owner will not be permitted to see builder cost or markup. Description, Quantity, Unit Price and Price will be shown for each line item.
                    </div>
                  </div>
                </div>
              </div>
              </ng-template>
            </div>
          </div>
          <div nz-row class="pt-3" *ngIf="isFlatFeeConfirmed === false">
            <div nz-col nzSpan="24">
              <nz-table [nzFooter]="customFooter" nzFooter="Footer" [nzBordered]="false" nzSize="small" #editRowTable nzShowPagination="false" [nzScroll]="{ x: '1625px' }" [nzData]="listOfData">
                <thead>
                  <tr class="custom-table-row-th">
                    <th nzLeft nzWidth="9%">
                    </th>
                    <th nzLeft nzWidth="60%" class="sub-text1">Items</th>
                    <th nzWidth="35%" class="sub-text1">Cost Type</th>
                    <th nzWidth="25%" class="sub-text1 text-end">Unit Cost</th>
                    <th nzWidth="24%" class="sub-text1 text-end">Quantity</th>
                    <th nzWidth="20%" class="sub-text1 text-start">Unit</th>
                    <th nzWidth="23%" class="sub-text1 text-end">Builder Cost</th>
                    <th nzWidth="30%" class="sub-text1">Markup</th>
                    <th nzWidth="25%" class="sub-text1 text-end">Owner Price</th>
                    <th nzWidth="25%" class="sub-text1 text-end">Margin</th>
                    <th nzRight nzWidth="18%" class="sub-text1"></th>
                  </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of items; let i = index">
                        <tr class="actionRow first-tr">
                          <td nzLeft nzWidth="9%">
                          </td>
                          <td nzLeft nzWidth="60%">
                            <div class="row">
                              <div class="col-12">
                                <label class="sub-text">Title</label>
                                <nz-form-item>
                                  <nz-form-control>
                                    <input  #titleInput nz-input 
                                      [(ngModel)]="items[i].title"
                                      name="title_{{ i }}"
                                      required 
                                      (ngModelChange)="onFieldChangeItem('title', $event, i)"
                                      [nzStatus]="validationStatesItem['title_' + i]" class="formField" />
                                  </nz-form-control>
                                  <div *ngIf="validationStatesItem['title_' + i] === 'error'" class="text-danger">
                                    {{ validationMessagesItem['title_' + i] }}
                                  </div>
                                </nz-form-item>
                              </div>
                            </div>
                          </td>
                          <td nzWidth="35%">
                              <div class="row">
                                  <div class="col-12">
                                    <label class="sub-text">Cost Type</label>
                                    <nz-tree-select
                                    [(ngModel)]="item.costTypeItemParameters" name="costTypeItemParameters_{{ i }}"
                                    style="width: 100%"
                                    [nzNodes]="JobGroupNodes"
                                    [nzShowSearch]="true"
                                    nzAllowClear="false"
                                    nzCheckable
                                    nzDropdownClassName="dropdownStyle"
                                    [nzAllowClear]="false">
                                    </nz-tree-select>
                                  </div>
                              </div>
                          </td>
                          <td nzWidth="29%">
                              <div class="row">
                                  <div class="col-12">
                                      <label class="sub-text">Unit Cost</label>
                                      <div class="input-group">
                                        <nz-input-group nzAddOnBefore="$">
                                          <input
                                          nz-input
                                          [(ngModel)]="item.unitCost" name="unitCost_{{ i }}"
                                          appDollarFormatter
                                          #stateInput="ngModel"
                                          class="form-control formField" 
                                        />
                                        </nz-input-group>
                                      </div>
                                  </div>
                              </div>
                          </td>
                          <td nzWidth="24%">
                              <div class="row">
                                  <div class="col-12">
                                      <label class="sub-text">Quantity</label>
                                      <nz-input-number [(ngModel)]="item.quantity" name="quantity_{{ i }}"></nz-input-number>
                                  </div>
                              </div>
                          </td>
                          <td nzWidth="20%">
                              <div class="row">
                                  <div class="col-12">
                                      <label class="sub-text">Unit</label>
                                      <input [(ngModel)]="item.unit" name="unit_{{ i }}" type="text"nz-input class="formField"/>
                                  </div>
                              </div>
                          </td>
                          <td nzWidth="23%">
                              <div class="row">
                                  <div class="col-12">
                                      <label class="sub-text">Builder Cost</label>
                                      <label class="sub-text pt-2" [(ngModel)]="item.builderCost" name="builderCost_{{ i }}">$0.00</label>
                                  </div>
                              </div>
                          </td>
                          <td nzWidth="30%">
                              <div class="row gx-0">
                                  <div class="col-10">
                                      <label class="sub-text">Markup</label>
                                  </div>
                                  <div class="col-2">
                                  </div>
                              </div>
                              <div class="row gx-0">
                                  <div class="col-7">
                                    <input
                                    nz-input
                                    class="formField"
                                    appDollarFormatter
                                    [(ngModel)]="item.markupValue"
                                    name="markupValue"
                                    (ngModelChange)="updateBuilderCost(item)"
                                  />
                                  </div>
                                  <div class="col-5">
                                    <nz-select nzShowSearch nzAutoFocus="true" [(ngModel)]="item.markupId" name="markupId_{{ i }}">
                                      <nz-option *ngFor="let option of markupOption" [nzLabel]="option.description" [nzValue]="option.id"></nz-option>
                                    </nz-select>
                                  </div>
                              </div>
                          </td>
                          <td nzWidth="25%">
                              <div class="row">
                                  <div class="col-12">
                                      <label class="sub-text">Owner Price</label>
                                  </div>
                              </div>
                          </td>
                          <td nzWidth="25%">
                              <div class="row">
                                  <div class="col-12">
                                      <label class="sub-text">Margin</label>
                                  </div>
                              </div>
                          </td>
                          <td nzRight nzWidth="18%">
                            <span
                            (click)="deleteRow(i)"
                            nz-icon
                            nzType="delete"
                            class="text-primary text-center fs-4 k-cursor-pointer"
                            nzTheme="fill"
                          ></span>
                          </td>
                        </tr>
                        <tr class="actionRow">
                          <td nzLeft nzWidth="9%">
                          </td>
                          <td nzLeft nzWidth="60%">
                              <div class="row">
                                  <div class="col-12">
                                    <label class="sub-text">Cost Code <span class="ms-1 fw-bold text-danger">*</span></label>
                                    <nz-form-item>
                                      <nz-form-control [nzErrorTip]="validationMessagesItem['costCodeId_' + i]" [nzValidateStatus]="validationStatesItem['costCodeId_' + i]">
                                        <nz-select
                                        (ngModelChange)="onFieldChangeItem('costCodeId', $event, i)"
                                          nzShowSearch
                                          [(ngModel)]="items[i].costCodeId"
                                          name="costCodeId">
                                          <nz-option *ngFor="let option of leadOptions" [nzLabel]="option.title" [nzValue]="option.id"></nz-option>
                                        </nz-select>
                                      </nz-form-control>
                                    </nz-form-item>




                                    <!-- <nz-form-item>
                                      <nz-form-control [nzErrorTip]="validationMessages['costTypeId']" [nzValidateStatus]="validationStates['costTypeId']">
                                        <nz-select
                                        (ngModelChange)="onFieldChangeItem('costTypeId', $event)"
                                          nzShowSearch
                                          [(ngModel)]="item.costTypeId"
                                          id="costTypeId"
                                          name="costTypeId"
                                        >
                                        <nz-option *ngFor="let option of leadOptions" [nzLabel]="option.title" [nzValue]="option.id"></nz-option>
                                        </nz-select>
                                      </nz-form-control>
                                    </nz-form-item> -->
<!-- 



                                    <nz-form-item>
                                      <nz-form-control [nzErrorTip]="validationMessages['costTypeId' + i]" [nzValidateStatus]="validationStates['costTypeId' + i]">
                                          <nz-select
                                              required (ngModelChange)="onFieldChangeItem('costTypeId', $event)"
                                              nzShowSearch
                                              [(ngModel)]="item.costTypeId"
                                              name="costTypeId"
                                          >
                                              <nz-option *ngFor="let option of leadOptions" [nzLabel]="option.title" [nzValue]="option.id"></nz-option>
                                          </nz-select>
                                      </nz-form-control>
                                  </nz-form-item> -->
                                  </div>
                              </div>
                          </td>
                          <td nzWidth="55%"></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td nzRight></td>
                        </tr>
                        <tr class="actionRow">
                          <td nzLeft nzWidth="9%"></td>
                          <td nzLeft nzWidth="60%">
                              <div nz-row style="margin-top: -14px;margin-left: -13px;">
                                  <div nz-col nzSpan="3">
                                      <a nz-button nzType="link">Add</a>
                                  </div>
                                  <div nz-col nzSpan="3">
                                      <a nz-button nzType="link">Edit</a>
                                  </div>
                              </div>
                          </td>
                          <td nzWidth="55%"></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td nzRight></td>
                        </tr>
                        <tr class="actionRow">
                          <td [colSpan]="1">
                          </td>
                          <td [colSpan]="5">
                              <label class="sub-text">Description</label>
                              <textarea [(ngModel)]="item.description" name="description_{{ i }}" nz-input [nzAutosize]="{ minRows: 2, maxRows: 10 }" class="formField" style="resize: none;border: none;"></textarea>
                          </td>
                          <td [colSpan]="5">
                              <label class="sub-text">Internal Notes</label>
                              <textarea [(ngModel)]="item.internalnoter_" name="internalnoter_{{ i }}" nz-input [nzAutosize]="{ minRows: 2, maxRows: 10 }" class="formField" style="resize: none;border: none;"></textarea>
                          </td>
                        </tr>
                    </ng-container>
                    <tr class="custom-table-row">
                      <td  class="border" [colSpan]="13">
                        <a (click)="addNewRow()">
                          <span class="me-2 fs-5" nz-icon nzType="plus-circle" nzTheme="fill"></span>
                          <span class="fs-6">Item</span>
                        </a>
                      </td>
                    </tr>
                </tbody>
                  <ng-template #customFooter>
                    <tfoot>
                    </tfoot>
                  </ng-template>
              </nz-table>
            </div>
          </div>
        </div>
      </div>  
      <div class="row">
        <div class="col-7">
          <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
              <div class="row">
                <h2 class="famlyPrice Information-inn ant-typography-crad-Header">Attachments</h2>
              </div>
            </div>
            <div class="card-body bt-file-viewer-card">
              <div class="row">
                <div class="col-12">
                  <app-create-attachments-common [TestAttachment__]="TestAttachment" (filesUploaded)="onFilesUploaded($event)"></app-create-attachments-common>
                </div>
              </div>
            </div>
          </div>  
          <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
              <div class="row">
                <h2 class="famly-inn ant-typography-crad-Header">Notes</h2>
              </div>
            </div>
            <div class="card-body">
              <nz-tabset nzType="card">
                <nz-tab *ngFor="let tab of tabs" [nzTitle]="innerCardTabs(tab)" style="background-color: #f1f4fa;">
                  <ng-container *ngIf="tab === 1">
                    <div class="row p-2">
                      <div class="col-12">
                        <label class="sub-text">Notes</label>
                        <textarea 
                          [(ngModel)]="ChangeOrderFormFieldsValue.internalNotes" name="internalNotes"
                           nz-input [nzAutosize]="{ minRows: 2, maxRows: 12 }"
                          class="formField" style="resize: none;"></textarea>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="tab === 2">
                    <div class="row p-2">
                      <div class="col-12">
                        <label class="sub-text">Notes</label>
                        <textarea 
                        [(ngModel)]="ChangeOrderFormFieldsValue.subVendorNotes" name="subVendorNotes"
                         nz-input [nzAutosize]="{ minRows: 2, maxRows: 12 }"
                        class="formField" style="resize: none;"></textarea>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="tab === 3">
                    <div class="row p-2">
                      <div class="col-12">
                        <label class="sub-text">Notes</label>
                        <textarea 
                        [(ngModel)]="ChangeOrderFormFieldsValue.ownerNotes" name="ownerNotes"
                         nz-input [nzAutosize]="{ minRows: 2, maxRows: 12 }"
                        class="formField" style="resize: none;"></textarea>
                      </div>
                    </div>
                  </ng-container>
                </nz-tab>
              </nz-tabset>
            </div>
          </div>  
          <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
              <div class="row">
                <h2 class="famly-inn ant-typography-crad-Header">Change Order Description</h2>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <ckeditor
                    [(ngModel)]="ChangeOrderFormFieldsValue.changeOrderDescription"
                    name="changeOrderDescription"
                    [editor]="Editor"
                    [config]="config"
                    class="size RFIsEditor"
                    id="changeOrdereditor">
                  </ckeditor>
                </div>
              </div>
            </div>
          </div>  
          <ng-container *ngIf="GetByIdRes?.MessagingId > 0; else MessagingComponent">
            <div class="card border-0 mt-3">
              <div class="card-head border-bottom">
                <div class="row">
                  <h2 class="famly-inn ant-typography-crad-Header">Messaging</h2>
                </div>
              </div>
              <div class="card-body">
                  <div nz-row>
                    <div nz-col nzSpan="24">
                      <h2 class="famly-inn ant-typography-crad-Header">Messaging available after save</h2>
                    </div>
                  </div>
              </div>
            </div>  
          </ng-container>
          <ng-template #MessagingComponent>
            <messaging-common 
            [Job_ID]="JobInfoId"
            [Messaging_ID]="Messaging_Id"
            [header_ID]="ChangeOrderInfoId"
            [Form_Name_ID]="formNameId"
            >
            </messaging-common>
            <ng-container *ngIf="GetByIdRes?.RFIId > 0; else Change_Order_Info">
              <app-create-rfis-common
              [Job_ID]="JobInfoId"
              [header_ID]="ChangeOrderInfoId"
              [Change_Order_Info]="GetByIdRes"
              >
              </app-create-rfis-common>
            </ng-container>
            <ng-template #Change_Order_Info>
              <app-create-rfis-common
              [Job_ID]="JobInfoId"
              [header_ID]="ChangeOrderInfoId"
              [Change_Order_Info]="GetByIdRes"
              >
              </app-create-rfis-common>
            </ng-template>
          </ng-template>
        </div>
        <div class="col-5">
          <div class="card border-0 mt-3" *ngIf="onGetByIdRes !== undefined">
            <div class="card-head border-bottom">
              <div class="row">
                <h2 class="famly-inn ant-typography-crad-Header">Approval Status</h2>
              </div>
            </div>
            <div class="card-body pt-0 mt-0">
              <div nz-row>
                <div nz-col nzSpan="24">
                  <div class="signatureImage" *ngIf="SignatureRes?.imageSource?.length > 0">
                    <img [src]="SignatureRes?.imageSource" alt="Signature" class="signature-img"/>
                  </div>
                </div>
              </div>
              <label class="sub-text">Comments</label>
              <textarea 
              [(ngModel)]="ChangeOrderFormFieldsValue.comments"
                    name="comments"
               nz-input [nzAutosize]="{ minRows: 2, maxRows: 12 }"
              class="formField" style="resize: none;"></textarea>
              <div nz-row class="pt-3" *ngIf="SignatureRes === undefined && !Approved && !Declined">
                <div nz-col nzSpan="24">
                  <button (click)="onApproveChangeOrder('saveAndAndApprove')" nz-button nzType="primary">Approve</button>
                  <button (click)="onApproveChangeOrder('saveAndAndDeclined')" class="ms-1" nz-button nzType="primary" nzDanger>Decline</button>
                  <span class="text-secondary ms-1" nz-icon nzType="info-circle" nzTheme="fill" nz-popover [nzPopoverContent]="ApprovalStatustipContent" nzPopoverPlacement="top"></span>
                  
                  <ng-template #ApprovalStatustipContent>
                    <div style="border-radius: 10px; width: 210px; font-size: 13px; line-height: 25px;">
                      <div nz-row>
                        <div nz-col nzSpan="24">
                          <div class="famly-inn">
                            Manually Approve or Decline this Change Order for the Owner. Signature options are available in builder setup.
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div> 
          <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
              <div class="row">
                <h2 class="famly-inn ant-typography-crad-Header">Subs/Vendors
                  <span class="text-secondary ms-1" nz-icon nzType="info-circle" nzTheme="fill" nz-popover [nzPopoverContent]="SubsVendorstipContent" nzPopoverPlacement="top"></span>
                  <ng-template #SubsVendorstipContent>
                  <div style="border-radius: 10px; width: 210px; font-size: 13px; line-height: 25px;padding: 10px;">
                    <div nz-row>
                      <div nz-col nzSpan="24">
                        <div class="famly-inn">
                          When this Change Order is approved, any selected subs/vendors will be permitted to view and receive alerts
                        </div>
                      </div>
                    </div>
                  </div>
                  </ng-template>
                </h2>
              </div>
            </div>
            <div class="card-body">
              <nz-tree-select
              style="width: 100%"
              [nzShowSearch]="true"
              [nzAllowClear]="true"
              nzCheckable>
            </nz-tree-select>
            </div>
          </div>  
          <div class="card border-0 mt-3" *ngIf="ChangeOrderInformationResponse !== undefined">
            <div class="card-head border-bottom">
              <div class="row">
                <h2 class="famly-inn ant-typography-crad-Header">Related Items</h2>
              </div>
            </div>
            <div class="card-body">
              <span class="famly-inn">No related items.</span>
            </div>
          </div>  
        </div>
      </div>
    </div>
  </div>
  <ng-template #modalFooterInChangeOrder>
    <div nz-row class="p-1 justify-content-end">
      <div nz-col nzSpan="24">
        <nz-space>
          <ng-container *nzSpaceItem>
            <button 
            *ngIf="onGetByIdRes !== undefined"
            nz-button 
            nzType="default" 
            class="align-items-center"
            [disabled]="DisabledNextActivity"
            [class.disabled]="DisabledNextActivity">
            <span>See Owner Preview</span>
          </button>
          </ng-container>
          
          <ng-container *nzSpaceItem>
            <button 
            *ngIf="onGetByIdRes !== undefined"
            nz-button 
            nzType="default" 
            class="align-items-center"
            [disabled]="DisabledNextActivity"
            [class.disabled]="DisabledNextActivity"
            nz-dropdown 
            nzTrigger="click" 
            [nzDropdownMenu]="more" 
            nzPlacement="topLeft">
            <span>More</span>
            <i style="font-size: 14px;" nz-icon nzType="down"></i>
          </button>
          </ng-container>

          <ng-container *nzSpaceItem>
            <button 
            *ngIf="onGetByIdRes !== undefined"
            nz-button 
            nzType="primary" 
            class="align-items-center"
            [disabled]="DisabledNextActivity"
            [class.disabled]="DisabledNextActivity"
            nz-dropdown 
            nzTrigger="click" 
            [nzDropdownMenu]="menu" 
            nzPlacement="topLeft">
            <span>New</span>
            <i style="font-size: 14px;" nz-icon nzType="down"></i>
          </button>
          </ng-container>

          <ng-container *nzSpaceItem>
            <button
              *ngIf="(status !== 361) && (status === 362 || status === 363 || status === 364 || status === 365 || onGetByIdRes?.Decline === true || onGetByIdRes?.Approved === true)"
              nz-button
              nzType="default"
              class="align-items-center"
              [nzLoading]="isSaveAndUnrelease"
              (click)="UnreleaseChangeOrderConfirm()"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
              Unrelease
            </button>
          </ng-container>
          
          <ng-container *nzSpaceItem>
            <button
              *ngIf="(status !== 361) && (status === 364 || status === 365 || this.ChangeOrderFormFieldsValue.signatureSetupId.length >= 0 || ChangeOrderInformationResponse?.decline === true || ChangeOrderInformationResponse?.approved === true)"
              nz-button
              nzType="default"
              class="align-items-center"
              [nzLoading]="isSaveAndAndResetToPending"
              (click)="ResetChangeOrderConfirm()"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
              Reset to Pending
            </button>
          </ng-container>

          <ng-container *nzSpaceItem>
            <button
              *ngIf="(status !== 362 && status !== 363 && status !== 364 && status !== 365 && ChangeOrderInformationResponse?.decline !== true && ChangeOrderInformationResponse?.approved !== true) || status === 361"
              nz-button
              nzType="primary"
              class="align-items-center"
              [nzLoading]="isSaveAndRelease"
              (click)="saveChangeOrderAndRelease()"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
              Save & Release
            </button>
          </ng-container>

          <ng-container *nzSpaceItem>
            <button
              nz-button
              nzType="primary"
              class="align-items-center"
              [nzLoading]="isSave"
              (click)="saveChangeOrder()"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
              >
              Save
            </button>
          </ng-container>

          <ng-container *nzSpaceItem>
            <button
              nz-button
              nzType="primary"
              class="align-items-center"
              [nzLoading]="isSaveAndClose"
              (click)="saveChangeOrderAndClose()"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
              >
              Save & Close
            </button>
          </ng-container>
        </nz-space>
      </div>
    </div>
    <nz-dropdown-menu #more="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item>Print</li>
        <li (click)="deleteChangeOrderConfirm()" nz-menu-item>Delete</li>
      </ul>
    </nz-dropdown-menu>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item>Bid Package</li>
        <li nz-menu-item>Schedule Item</li>
        <li nz-menu-item>Purchase Order</li>
      </ul>
    </nz-dropdown-menu>
  </ng-template>
</nz-modal>