import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ApplicationUserForComboResponse } from 'src/Models/InternalUser/InternalUserJob';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { LeadActivitiesAssignerService } from 'src/Service/Sales-Lead-Activities/lead-activity-feature-services/lead-activities-assigner.service';

@Component({
  selector: 'app-lead-activity-assign-user',
  templateUrl: './lead-activity-assign-user.component.html',
  styleUrls: ['./lead-activity-assign-user.component.css']
})
export class LeadActivityAssignUserComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Output() leadActivityAssigned = new EventEmitter<void>();
  @Input() selectedGlobalIds: string[];

  validateForm!: FormGroup;
  getAssigner: ApplicationUserForComboResponse[] = [];
  isAssign = false;
  constructor(private fb: FormBuilder,
    private customerInformationService: CustomerInformationService,
    private leadActivitiesAssignerService: LeadActivitiesAssignerService,
    private toasterService: NzMessageService,
  ) {}

  ngOnInit(): void {
    this.formGroup();
    this.getAssignerDropdown();
  }

  formGroup(): void {
    this.validateForm = this.fb.group({
      assignedUserId: [null, Validators.required] // Removed globalId from here
    });
  }

  getAssignerDropdown() {
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      this.getAssigner = res.result;
    });
  }

  getInitials(fullName: string): string {
    if (!fullName) return '';
    const nameParts = fullName.trim().split(' ');
    const firstInitial = nameParts[0]?.charAt(0).toUpperCase() || '';
    const lastInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1]?.charAt(0).toUpperCase() : '';
    return `${firstInitial}${lastInitial}`;
  }

  submitForm(): void {
    if (this.validateForm.valid) {
      this.isAssign = true; 
      const assignedUserId = this.validateForm.value.assignedUserId;
      const assignments = this.selectedGlobalIds.map(globalId => ({
        globalId: globalId,
        assignedUserId: assignedUserId
      }));
      console.log('check :',assignments);
      

      this.leadActivitiesAssignerService.leadActivitiesReAssign(assignments).subscribe(res => {
        this.toasterService.success('Activities Reassigned Successfully');
        this.isAssign = false; 
        this.cancel.emit();
        this.leadActivityAssigned.emit(); // Emit event after successful reassignment
      });
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
           this.isAssign = false; 
        }
      });
    }
  }

  Cancel(): void {
    this.cancel.emit();
  }
}
