import { LeadOpportunitiesNewLeadOpportunitiesComponent } from './../../Modal/lead-opportunities-new-lead-opportunities/lead-opportunities-new-lead-opportunities.component';
import { LeadOpportunitiesExportComponent } from './../../Modal/lead-opportunities-export/lead-opportunities-export.component';
import { LeadOpportunitiesLearnMoreComponent } from './../../Modal/lead-opportunities-learn-more/lead-opportunities-learn-more.component';
import { LeadOpportunitiesSettingComponent } from './../../Modal/lead-opportunities-setting/lead-opportunities-setting.component';
import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LeadOpportunitiesImportLeadOpportunitiesComponent } from '../../Modal/lead-opportunities-import-lead-opportunities/lead-opportunities-import-lead-opportunities.component';
import { LeadOpportunitiesAddContactComponent } from '../../Modal/lead-opportunities-add-contact/lead-opportunities-add-contact.component';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { SalesComponent } from 'src/app/shared/component/sales/sales.component';

interface DataItem {
  name: string;
  chinese: number;
  math: number;
  english: number;
}

@Component({
  selector: 'lead-opportunities-list-view-header',
  templateUrl: './lead-opportunities-list-view-header.component.html',
  styleUrls: ['./lead-opportunities-list-view-header.component.css']
})
export class LeadOpportunitiesListViewHeaderComponent {
  PAGE_ID: number = 36;
  LEAD_OPPR_LIST_VIEW: string = FiltersName.LEAD_OPPR_LIST_VIEW;

  constructor(private modal: NzModalService, 
    private msg: NzMessageService,
    private filterService: FilterSideMenuBarService) { }


   //leadOpportunitySetting
   leadOpportunitySettingModalOpen(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesSettingComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }

  //leadOpportunityLearnMore
  leadOpportunityLearnMoreModalOpen(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesLearnMoreComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }

  //leadOpportunityExport
  leadOpportunityExportModalOpen(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesExportComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }

  //leadOpportunityNewLeadOpportunity
  leadOpportunityNewLeadModalOpen(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesNewLeadOpportunitiesComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }

   //leadOpportunityNewLeadOpportunity
   leadOpportunityImportLeadModalOpen(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesImportLeadOpportunitiesComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }

  
  //leadOpportunityAddContactModal
  leadOpportunityAddContactModalOpen(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesAddContactComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }
  //LeadSettings
  LeadSettings(): void {
    const modalRef = this.modal.create({
      nzContent: SalesComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }

  filteredData: DataItem[] = []; 
  searchText = '';

 

  openFilter(component: string, pageId: number) {
    this.filterService.toggleFilter(component, pageId);
  }
}
