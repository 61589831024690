import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LeadProposalResponse } from 'src/Models/LeadProposal/LeadProposalModels';
import { LeadOpportunityMetaData, LeadOpportunityResponse } from 'src/Models/LeadTags';
import { ResponseModel, TablePageResponse } from 'src/Models/responseMessage.model';
import { FilterSearchParameter, CreateLookupFilterRequest } from 'src/app/shared/component/Models/LookupModels';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ListViewFService {
  private base_Url: string = environment.apiUrl + 'LookupFilter/';

  constructor(private http: HttpClient) {}

  getListUserData = new BehaviorSubject<ResponseModel<TablePageResponse<LeadOpportunityMetaData>>>(null);
  $getViewUsers = this.getListUserData.asObservable();

  ListViewSelectedFilterId = new BehaviorSubject<number>(0);
  $ListSelectedFilterId = this.ListViewSelectedFilterId.asObservable();

  ListViewIsUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $ListIsUserAppliedFilter = this.ListViewIsUserAppliedFilter.asObservable();
  
  getListView(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        // console.log('After Apply Filter :',data);
        
        this.getListUserData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  getAppliedFilterDataListView(appliedFilter: CreateLookupFilterRequest[]) {
    const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
    return this.http.post<any>(apiUrl, appliedFilter).subscribe(
      (data) => {
        console.log('After Apply Filter :',data);
        this.getListUserData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  ListViewSetSelectedFilterId(filterId: number) {
    this.ListViewSelectedFilterId.next(filterId);
  }
  ListViewUserAppliedFilter(filter: boolean) {
    this.ListViewIsUserAppliedFilter.next(filter);
  }


  private dataUpdatedSource = new Subject<void>();
  dataUpdated$ = this.dataUpdatedSource.asObservable();

  triggerDataUpdate() {
    this.dataUpdatedSource.next();
  }
}