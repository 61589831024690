<nz-modal nzWidth="35%"  [(nzVisible)]="isActivityTemVisible" [nzTitle]="modalTitle" [nzFooter]="LeadActivityFooter"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '10px', 'max-height': '400px', 'overflow-y': 'auto' }"
    [nzStyle]="{ top: '15px' }" (nzOnCancel)="handleActivityTemCancel()">

    <ng-template #modalTitle>
        <div class="row p-0 m-0">
            <h1 class="p-0 m-0">
                Import Activities into 1 Lead?
            </h1>
        </div>
    </ng-template>

    <div *nzModalContent>
        <div class="card border-0 mt-0 p-3">
            <div class="card-body ">
                <div class="row mb-2">
                    <div class="col-12 p-0 m-0">
                        <label class="sub-text">Template <span class="fw-bolder text-danger ms-1">*</span> </label>
                        <nz-select nzShowSearch [lang]="true" [(ngModel)]="formFieldValues['leadActivityTemplateId']"
                            name="leadActivityTemplateId" nzAutoFocus="true" nzPlaceHolder="--None--" required #leadActivityTemplateId="ngModel">
                            <nz-option *ngFor="let option of assignedUser" [nzLabel]="option.label"
                                [nzValue]="option.value"></nz-option>
                        </nz-select>
                        <div *ngIf="leadActivityTemplateId.invalid && (leadActivityTemplateId.dirty || leadActivityTemplateId.touched)" class="text-danger">
                            <div *ngIf="leadActivityTemplateId.errors['required']">Template Name is required.</div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2 p-0">
                    <div class="col-6 p-0">
                      <div class="form-group">
                        <label class="sub-text d-block">
                          Start Date <span class="fw-bolder text-danger ">*</span>
                          <i nz-popover [nzPopoverContent]="startDate" nzPopoverPlacement="top" class="bx bxs-info-circle text-secondary"></i>
                        </label>
                        <ng-template #startDate>
                          <div class="rounded-2">
                            <p style="width: 195px; font-size: 13px;">
                              Set the new date for the earliest activity on the import.
                            </p>
                          </div>
                        </ng-template>
                        <nz-date-picker
                          class="d-block"
                          nzPlaceHolder=" "
                          nzSuffixIcon="false"
                          [(ngModel)]="formFieldValues['activityOn']"
                          name="activityOn"
                          required
                        ></nz-date-picker>
                      </div>
                    </div>
                    <div class="col-6 p-0">
                      <div class="form-group">
                        <label class="sub-text d-block">
                          Start Time
                          <i nz-popover [nzPopoverContent]="startTime" nzPopoverPlacement="top" class="bx bxs-info-circle text-secondary"></i>
                        </label>
                        <ng-template #startTime>
                          <div class="rounded-2">
                            <p style="width: 195px; font-size: 13px;">
                              By default, the new time will be copied from the earliest activity on the source template.
                            </p>
                          </div>
                        </ng-template>
                        <nz-time-picker
                          class="d-block"
                          [(ngModel)]="formFieldValues['startTime']"
                          name="startTime"
                          nzFormat="HH:mm"
                          nzPlaceHolder=" "
                          required
                        ></nz-time-picker>
                      </div>
                    </div>
                  </div>
                  
                  
                  
                <div class="row mb-2">
                    <div class="col-12 p-0 m-0">
                        <label class="sub-text">Assign All Activities To<span
                                class="fw-bolder text-danger ms-1">*</span> </label>
                        <nz-select nzShowSearch [lang]="true" [(ngModel)]="formFieldValues['assignAllTo']"
                            name="assignAllTo" nzAutoFocus="true" nzPlaceHolder="--None--" required>
                            <nz-option *ngFor="let option of AllUser" [nzLabel]="option.label"
                                [nzValue]="option.value"></nz-option>
                        </nz-select>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #LeadActivityFooter>
        <div class="mt-2 align-items-center">
            <button nz-button nzType="primary">Cancel</button>
            <button nz-button nzType="primary" (click)="saveImportButton()">Import</button>
        </div>
    </ng-template>
</nz-modal>
