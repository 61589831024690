import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SelectionDashbordComponent } from './components/selection-dashbord/selection-dashbord.component';
import { ScheduleDashboradComponent } from './components/schedules-Components/schedule-dashborad/schedule-dashborad.component';
import { ProjectManagementSiteDiariesComponent } from './components/Site-Diaries/project-management-site-diaries/project-management-site-diaries.component';
import { ProjectManagementToDosDbComponent } from './components/To-Do\'s/project-management-to-dos-db/project-management-to-dos-db.component';
import { ProjectManagementVariationsDbComponent } from './components/Variations/project-management-variations-db/project-management-variations-db.component';
import { ProjectManagementWarrantyDbComponent } from './components/Warranty/project-management-warranty-db/project-management-warranty-db.component';
import { ProjectManagementTimeClockDbComponent } from './components/TimeClock/project-management-time-clock-db/project-management-time-clock-db.component';

const routes: Routes = [
  { path: '', redirectTo: 'Schedule', pathMatch: 'full' },
  { path: 'Schedule', component: ScheduleDashboradComponent },
  { path: 'SiteDiaries', component: ProjectManagementSiteDiariesComponent }, 
  { path: 'ToDos', component: ProjectManagementToDosDbComponent },
  { path: 'Variations', component: ProjectManagementVariationsDbComponent },
  { path: 'Selections', component: SelectionDashbordComponent },
  { path: 'Warranty', component: ProjectManagementWarrantyDbComponent },
  { path: 'TimeClock', component: ProjectManagementTimeClockDbComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectManagementRoutingModule { }
