<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form (ngSubmit)="onFilterApply()">
    <div class="row mt-2">
      <div class="col-10 p-0">
        <div class="input-group">
         <nz-select
          [(ngModel)]="formFieldValues['standardFilter']"
          name="standardFilter"
          (ngModelChange)="onChangeStandardFilter($event)"
          nzShowSearch
          [nzAutoFocus]="true"
          nzPlaceHolder="Standard Filter"
          >
          <nz-option *ngFor="let filter of filterResponseData"
           [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')"
           [nzValue]="filter.id"
           nzCustomContent>
           <div class="option-content">
            <!-- Customize content with icons and text -->
            <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
            <span>{{ filter.name }}</span>
           <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

           </div>
          </nz-option>
         </nz-select>
        </div>
       </div>

      <!-- [nzLabel]="filter.name + (filter.isSetAsDefault ? ' DEFAULT' : '')"  -->
      <div class="col-1 mt-1">
        
        <i 
        nz-popover
        [nzPopoverContent]="contentTemplate"
        [nzPopoverPlacement]="'top'"
        class="bi bi-three-dots" 
        (click)="openStandardFilterComponent('SAVE_AS')"></i>
      </div>
    </div>
    <div class="row mt-2" *ngIf="selectedFilter === 1">
      <div class="col">
        <p>Your filter has unsaved changes.</p>
      </div>
    </div>
    <div class="row mt-2" *ngIf="selectedFilter === 0">
      <div class="col">
        <p>Your filter has unsaved changes.</p>
      </div>
    </div>

    <div class="col d-flex mt-3">
      <button (click)="openStandardFilterComponent('APPLY_SAVE_AS')" type="button" nz-button nzType="default"
        class="me-2" style="font-size: 12px;" *ngIf="selectedFilter === 1">Save As</button>
      <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;" *ngIf="selectedFilter === 1"
        type="button">Reset</button>
      <button (click)="editSaveFilterData()" nz-button nzType="primary" type="button" class="me-2"
        style="font-size: 12px;" *ngIf="selectedFilter === 0">Save</button>
      <button (click)="openStandardFilterComponent('APPLY_SAVE_AS')" nz-button nzType="default" class="me-2"
        style="font-size: 12px;" *ngIf="selectedFilter === 0" type="button">Save As</button>
      <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;" *ngIf="selectedFilter === 0"
        type="button">Reset</button>
    </div>

    <div class="row famly-inn">
      <div class="col-12">
        <div class="row">
          <div class="col-12 p-0">
            <label class="sub-text">Keywords</label>
            <i
            class="bi bi-info-circle-fill text-secondary ms-2"
            nz-popover
            [nzPopoverContent]="keywordTemplate"
            nzPopoverPlacement="right"
          ></i>
          <ng-template #keywordTemplate>
            <div class="rounded-2">
              <p style="height: auto; width: 180px">
                Keyword Search allows you to search Leads by keyword for any
                of the fields included in the dropdown.
              </p>
            </div>
          </ng-template>
            <input [(ngModel)]="formFieldValues['keywords']" name="keywords" type="text" class="form-control formField">
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-0 mt-1">
            <label class="sub-text">Project Managers</label>
            <div class="input-group">

              <nz-tree-select
              [(ngModel)]="formFieldValues['projectManagers']"
              name="projectManagers"
              style="width: 100%"
              [nzNodes]="projectManagersNode"
              nzShowSearch
              nzAllowClear="false"
              nzCheckable
              (ngModelChange)="onProjectManagerChange($event)"
              nzPlaceHolder="Select Project Managers"
              nzDropdownClassName="dropdownStyle"
             >
             </nz-tree-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Status</label>
            <nz-tree-select
            [(ngModel)]="formFieldValues['Status']"
            name="Status"
            style="width: 100%"
            [nzNodes]="statusNode"
            nzShowSearch
            nzAllowClear="false"
            nzCheckable
            (ngModelChange)="onstatusChange($event)"
            nzPlaceHolder="Select  Status"
            nzDropdownClassName="dropdownStyle"
           >
           </nz-tree-select>

          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Created Date</label>
            <nz-select nzShowSearch [lang]="true" nzAutoFocus="true" [(ngModel)]="formFieldValues['createdDate']"
              name="createdDate">
              <nz-option *ngFor="let filter of createdDateSelected" [nzLabel]="filter.label" [nzValue]="filter.value">
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Created By</label>
            <nz-select nzShowSearch [lang]="true" nzAutoFocus="true" [(ngModel)]="formFieldValues['createdBy']"
              name="createdBy">
              <nz-option *ngFor="let filter of internal" [nzLabel]="filter.fullName" [nzValue]="filter.id"> </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-0 mt-1">
            <label class="sub-text">Job Type</label>
            <div class="input-group">



              <nz-tree-select
              [(ngModel)]="formFieldValues['jobType']"
              name="jobType"
              style="width: 100%"
              [nzNodes]="jobTypeNode"
              nzShowSearch
              nzAllowClear="false"
              nzCheckable
              (ngModelChange)="onjobTypeChange($event)"
              nzPlaceHolder="Select job Type "
              nzDropdownClassName="dropdownStyle"
             >
             </nz-tree-select>




            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-0 mt-1">
            <label class="sub-text">Owners</label>
            <div class="input-group">


              <nz-tree-select
              [(ngModel)]="formFieldValues['owners']"
              name="owners"
              style="width: 100%"
              [nzNodes]="ownerNode"
              nzShowSearch
              nzAllowClear="false"
              nzCheckable
              (ngModelChange)="onOwnerChange($event)"
              nzPlaceHolder="Select owners "
              nzDropdownClassName="dropdownStyle"
             >
             </nz-tree-select>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Map Status</label>
            <nz-select nzShowSearch [lang]="true" nzAutoFocus="true" [(ngModel)]="formFieldValues['mapStatus']"
              name="mapStatus">
              <nz-option *ngFor="let filter of mapStatusSelected" [nzLabel]="filter.label" [nzValue]="filter.value">
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Actual Start</label>
            <nz-select nzShowSearch [lang]="true" nzAutoFocus="true" [(ngModel)]="formFieldValues['actualStart']"
              name="actualStart">
              <nz-option *ngFor="let filter of actualStartSelected" [nzLabel]="filter.label" [nzValue]="filter.value">
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Actual Completion</label>
            <nz-select nzShowSearch [lang]="true" nzAutoFocus="true" [(ngModel)]="formFieldValues['actualCompletion']"
              name="actualCompletion">
              <nz-option *ngFor="let filter of actualCompletionSelected" [nzLabel]="filter.label"
                [nzValue]="filter.value"> </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-0 mt-1">
            <label class="sub-text">Groups</label>
            <div class="input-group">


              <nz-tree-select
              [(ngModel)]="formFieldValues['groups']"
              name="groups"
              style="width: 100%"
              [nzNodes]="groupsNode"
              nzShowSearch
              nzAllowClear="false"
              nzCheckable
              (ngModelChange)="ongroupChange($event)"
              nzPlaceHolder="Select groups "
              nzDropdownClassName="dropdownStyle"
             >
             </nz-tree-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Plans - Lodged</label>
            <nz-select nzShowSearch [lang]="true" nzAutoFocus="true" [(ngModel)]="formFieldValues['plansLodged']"
              name="plansLodged">
              <nz-option *ngFor="let filter of plansLodgedSelected" [nzLabel]="filter.label" [nzValue]="filter.value">
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Plans Approved - Issued</label>
            <nz-select nzShowSearch [lang]="true" nzAutoFocus="true"
              [(ngModel)]="formFieldValues['plansApprovedIssued']" name="plansApprovedIssued">
              <nz-option *ngFor="let filter of plansApprovedIssuedSelected" [nzLabel]="filter.label"
                [nzValue]="filter.value"> </nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </div>


    <div class="row mt-2">
      <div class="col-12 p-0 text-end">
        <button nz-button nzType="default" type="button" (click)="reInitReset()">Clear All</button>
        <button class="ms-1" nz-button nzType="primary" type="submit" [disabled]="isFormDirty">Apply</button>
      </div>
    </div>

  </form>
</div>


<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>