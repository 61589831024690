import { ModalService } from './../../../Service/CompanyInFormation/modal.service';
import { AuthService } from './../../../app/auth/auth.service';
import { Component, ElementRef, EventEmitter, HostListener, NgZone, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModuleResponse } from 'src/Models/treeTab.model';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
// import { ApplicationTreeMenu } from 'src/Models/treeTab.model';
import { TreeMenu } from 'src/app/Core/Enums/TreeMenu';
import { JobCreateFromScratchComponent } from 'src/app/jobs-management/Job-List/job-create-from-scratch/job-create-from-scratch.component';
import { JobCreateFromTemplateComponent } from 'src/app/jobs-management/Job-List/job-create-from-template/job-create-from-template.component';
import { JobPriceSummaryComponent } from 'src/app/jobs-management/Jobs-Job-Price-Summary/job-price-summary/job-price-summary.component';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit{

  @Output() pageIdSelected = new EventEmitter<number>();
  isDropdownOpen: boolean = false;
  tabs: ModuleResponse[];
  mainTabs: ModuleResponse[];
  usersTab: ModuleResponse;
  account: ModuleResponse;
  education: ModuleResponse;
  frequentlyAdded: ModuleResponse;
  isExpand: boolean = false;
  
  constructor(private elementRef: ElementRef, private authService: AuthService,private Model:ModalService,
    private router: Router,
    private modal: NzModalService,
    private zone: NgZone) { }

  ngOnInit() {
    document.addEventListener('click', this.closeDropdownOnClickOutside.bind(this));
    this.getTabs();
  }

  getTabs() {
    this.tabs = this.authService.getTabs();
    
    let mainMenuTabsEnum: string[] = [TreeMenu.Sales, TreeMenu.Jobs, TreeMenu.Project_Management, TreeMenu.Messaging, TreeMenu.Files, TreeMenu.Financial];
    this.mainTabs = this.tabs.filter(x => mainMenuTabsEnum.includes(x.name));
    this.usersTab = this.tabs.find(x => x.name == TreeMenu.Groups);
    this.education = this.tabs.find(x => x.name == TreeMenu.Education);
    this.account = this.tabs.find(x => x.name == TreeMenu.Account);
    this.frequentlyAdded = this.tabs.find(x => x.name == TreeMenu.Frequently_Added);
  }



  toggleDropdown() {
    this.zone.runOutsideAngular(() => {
       console.log('Dropdown toggled');
       this.isDropdownOpen = !this.isDropdownOpen;
       console.log('isDropdownOpen:', this.isDropdownOpen);
    });
 }
  navbarToggle() {
    console.log('Navbar toggled');
    this.isExpand = !this.isExpand;
    console.log('isExpand:', this.isExpand);
  }
  

  closeDropdownOnClickOutside(event: Event) {
    // Check if the click occurred outside of the dropdown element
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  ngOnDestroy() {
    // Remove the event listener when the component is destroyed
    document.removeEventListener('click', this.closeDropdownOnClickOutside);
  }

  logout() {
    this.authService.logout();
  }

  navigate(tab: string, subMenu: string) {
    subMenu = subMenu.replaceAll(' ', '-');
    console.log('Url', tab + '/' + subMenu)
    let url = '/' + tab + '/' + subMenu;
    this.router.navigateByUrl(url);
    this.navbarToggle();
  }

  
  jobsCreateScratchOpen() {

    const modalRef = this.modal.create({
      nzContent: JobCreateFromScratchComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  
  JobCreateFromTemplateOpen() {
    const modalRef = this.modal.create({
      nzContent: JobCreateFromTemplateComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  JobInfo() {
    let Id = 40137
    const modalRef = this.modal.create({
      nzContent: JobCreateFromScratchComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.JobResponseId = Id;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  jobpricesummary() {
    
    const modalRef = this.modal.create({
      nzContent: JobPriceSummaryComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      console.log("CALL");
      
      modalRef.destroy();
    });
  }
  

  // handleMenuItemClick(subMenu: any, event: Event) {
     
  //   switch (subMenu.name) {
  //     // case SubMenuName.NewJobFromScratch:
  //     //   this.jobsCreateScratchOpen(event);
  //     //   break;
  //     // case SubMenuName.NewJobFromTemplate:
  //     //   this.JobCreateFromTemplateOpen(event);
  //     //   break;
  //     case SubMenuName.JobInfoJob:
  //       this.JobInfo(event);
  //       break;
  //     case SubMenuName.JobPriceSummary:
  //       this.jobpricesummary(event);
  //       break;
  //   }
  // }

	inputValue?: string;
	options: string[] = [];
	onInput(event: Event): void {
		const value = (event.target as HTMLInputElement).value;
		this.options = value ? [value, value + value, value + value + value] : [];
	}

	inputWidth = '270px';
	// toggleInputWidth(): void {
	// 	this.inputWidth = this.inputWidth === '270px' ? '330px' : '270px';
	// }

	visible = false;

	openHeaderSideBar(): void {
		this.visible = true;
	}

	close(): void {
		this.visible = false;
	}


	showAllButtons: boolean = true;

	// You may also want to define a property to store the screen size
	screenSize: number = window.innerWidth;
  
	// Listen for window resize events to update the screenSize property
	@HostListener('window:resize', ['$event'])
	onResize(event: Event): void {
	  this.screenSize = (event.target as Window).innerWidth;
	}

  optionList: any[] = [];
  initialOptionsToShow = 5;
  isLoading = false;


  // loadMore(): void {
  //   this.isLoading = true;
  //   // Simulating API call or data fetching
  //   const startIndex = this.optionList.length;
  //   const endIndex = startIndex + this.initialOptionsToShow;
  //   const additionalOptions = this.frequentlyAdded.applicationModules.slice(startIndex, endIndex);
  //   setTimeout(() => { // Simulating async operation
  //     this.isLoading = false;
  //     this.optionList = [...this.optionList, ...additionalOptions];
  //   }, 1000); // Simulating delay for loading
  // }

  // By AAMIR ALI

  getModuleControlPermission(applicationModuleDevId: number, modulePermissionId: number){
    this.authService.getModuleUserPermission(applicationModuleDevId,modulePermissionId).subscribe(res => {

    });
  }
}


