<!-- -->
<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form (ngSubmit)="onFilterApply()">
    <div class="row mt-2">
      <div class="col-10 p-0">
        <div class="input-group">
          <nz-select [(ngModel)]="formFieldValues['standardFilter']" name="standardFilter"
            (ngModelChange)="onChangeStandardFilter($event)" nzShowSearch [nzAutoFocus]="true"
            nzPlaceHolder="Standard Filter" r>
            <nz-option *ngFor="let filter of filterResponseData"
              [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')" [nzValue]="filter.id" nzCustomContent>
              <div class="option-content">
                <!-- Customize content with icons and text -->
                <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
                <span>{{ filter.name }}</span>
                <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

              </div>
            </nz-option>
          </nz-select>
        </div>
      </div>
      <!-- [nzLabel]="filter.name + (filter.isSetAsDefault ? ' DEFAULT' : '')"  -->
      <div class="col-1 mt-1">
        <i class="bi bi-three-dots"
         nz-popover
        [nzPopoverContent]="contentTemplate"
        [nzPopoverPlacement]="'top'"

         (click)="openStandardFilterComponent('SAVE_AS')"></i>
      </div>
    </div>
    <div class="row mt-2" *ngIf="selectedFilter === 1">
      <div class="col">
        <p>Your filter has unsaved changes.</p>
      </div>
    </div>
    <div class="row mt-2" *ngIf="selectedFilter === 0">
      <div class="col">
        <p>Your filter has unsaved changes.</p>
      </div>
    </div>

    <div class="col d-flex mt-3">
      <button (click)="openStandardFilterComponent('APPLY_SAVE_AS')" type="button" nz-button nzType="default"
        class="me-2" style="font-size: 12px;" *ngIf="selectedFilter === 1">Save As</button>
      <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;" *ngIf="selectedFilter === 1"
        type="button">Reset</button>
      <button (click)="editSaveFilterData()" nz-button nzType="primary" type="button" class="me-2"
        style="font-size: 12px;" *ngIf="selectedFilter === 0">Save</button>
      <button (click)="openStandardFilterComponent('APPLY_SAVE_AS')" nz-button nzType="default" class="me-2"
        style="font-size: 12px;" *ngIf="selectedFilter === 0" type="button">Save As</button>
      <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;" *ngIf="selectedFilter === 0"
        type="button">Reset</button>
    </div>
    <div class="row famly-inn">
      <div class="col-12">
        <div class="row">
          <div class="col p-0">
            <label class="sub-text">Keyword</label>
            <i
            class="bi bi-info-circle-fill text-secondary ms-2"
            nz-popover
            [nzPopoverContent]="keywordTemplate"
            nzPopoverPlacement="right"
          ></i>
          <ng-template #keywordTemplate>
            <div class="rounded-2">
              <p style="height: auto; width: 180px">
                Keyword Search allows you to search Leads by keyword for any
                of the fields included in the dropdown.
              </p>
            </div>
          </ng-template>
            <input [(ngModel)]="formFieldValues['keyword']" name="keyword" type="text" class="form-control formField">
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Proposal Status</label>

            <nz-tree-select [(ngModel)]="formFieldValues['proposalStatus']" name="proposalStatus" style="width: 100%"
              [nzNodes]="proposalStatusNodes" nzShowSearch nzAllowClear="false" nzCheckable
              (ngModelChange)="onPorposalstatusChange($event)" nzPlaceHolder="Select proposal Status"
              nzDropdownClassName="dropdownStyle">
            </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Opportunity Status</label>

            <nz-tree-select [(ngModel)]="formFieldValues['opportunityStatus']" name="opportunityStatus"
              style="width: 100%" [nzNodes]="opportunityStatusNodes" nzShowSearch nzAllowClear="false" nzCheckable
              (ngModelChange)="onopportunityStatusChange($event)" nzPlaceHolder="Select opportunity Status"
              nzDropdownClassName="dropdownStyle">
            </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col p-0">
            <label class="sub-text">Proposal Amount</label>
            <input [(ngModel)]="formFieldValues['proposalAmount']" name="proposalAmount" type="text"
              class="form-control formField">
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Last Updated</label>
            <nz-select nzShowSearch [lang]="true" nzAutoFocus="true" [(ngModel)]="formFieldValues['lastUpdated']"
              name="lastUpdated">
              <nz-option *ngFor="let filter of last" [nzLabel]="filter.label" [nzValue]="filter.value"> </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Salesperson</label>
            <nz-tree-select [(ngModel)]="formFieldValues['salesperson']" name="salesperson" style="width: 100%"
              [nzNodes]="salespersonNodes" nzShowSearch nzAllowClear="false" nzCheckable
              (ngModelChange)="onopportunityStatusChange($event)" nzPlaceHolder="Select sales person"
              nzDropdownClassName="dropdownStyle">
            </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Source</label>
            <nz-tree-select [(ngModel)]="formFieldValues['source']" name="source" style="width: 100%"
              [nzNodes]="sourceNodes" nzShowSearch nzAllowClear="false" nzCheckable
              (ngModelChange)="onsourceChange($event)" nzPlaceHolder="Select source"
              nzDropdownClassName="dropdownStyle">
            </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Job Type</label>
            <nz-tree-select [(ngModel)]="formFieldValues['jobType']" name="jobType" style="width: 100%"
              [nzNodes]="jobTypeNodes" nzShowSearch nzAllowClear="false" nzCheckable
              (ngModelChange)="onJobtypeChange($event)" nzPlaceHolder="Select job Type"
              nzDropdownClassName="dropdownStyle">
            </nz-tree-select>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 p-0 text-end">
        <button nz-button nzType="default" type="button" (click)="resetFilter()">Clear All</button>
        <button class="ms-1" nz-button nzType="primary" type="submit" [disabled]="isFormDirty">Apply</button>
      </div>
    </div>

  </form>
</div>

<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>
