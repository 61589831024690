import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'new-proposals-cost-codes',
  templateUrl: './new-proposals-cost-codes.component.html',
  styleUrls: ['./new-proposals-cost-codes.component.css']
})
export class NewProposalsCostCodesComponent {

  @Output() cancel = new EventEmitter<void>();


  isProposalCostCode:boolean = true;
  showModal(): void {
    this.isProposalCostCode = true;
  }


  closebiilss(){
   this.cancel.emit();
  }



}


