import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { PhaseSetup } from 'src/Models/CompanySettings/Schedule/PhaseSetup/PhaseSetup';
import { ColorResponse } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { PhaseService } from 'src/Service/CompanyInFormation/ScheDule/Phase/phase.service';
import { colorService } from 'src/Service/Job-List/Job-Information/color.service';

@Component({
  selector: 'app-inner-tab-phases-phase-setup',
  templateUrl: './inner-tab-phases-phase-setup.component.html',
  styleUrls: ['./inner-tab-phases-phase-setup.component.css']
})
export class InnerTabPhasesPhaseSetupComponent implements OnInit, AfterViewInit {
  @Output() cancel = new EventEmitter<void>();
  @ViewChild('nameInput') nameInputElement?: ElementRef;
  phaseForm: FormGroup;
  phaseNameErrorMessage: string = '';
  colors: ColorResponse[] = [];
  confirmModal?: NzModalRef;
  isFormDirty = false;
  isSaving = false;
  isSaveAndNew = false;
  selectedColor: string = '';
  selectedColorName: string = '';
  @Input() editPhase: PhaseSetup; // Receive the phase ID from the parent component
  @Output() phaseSaved = new EventEmitter<void>();
  @Output() phaseSavedSelected = new EventEmitter<any>();  
  @Output() phaseSavedAndNew =new EventEmitter<any>();  
  deleteLoading = false;

  constructor(
    private fb: FormBuilder,
    private colorService: colorService,
    private phaseService: PhaseService,
    private messageService: NzMessageService,
    private cdr: ChangeDetectorRef,
    private modal: NzModalService // Inject NzModalService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.initLoad();  

    if (this.editPhase) {
      this.phaseForm.patchValue({
        id: this.editPhase.Id,
        globalId: this.editPhase.GlobalId,
        name: this.editPhase.Name,
        colorId: this.editPhase.ColorId,
        displayOrder: this.editPhase.DisplayOrder
      });
      this.colorService.getData().subscribe((res: any) => {
        this.colors = res.result;
        this.updateSelectedColor(this.editPhase.ColorId); 
      });
    }
  }



  initializeForm(): void {
    this.phaseForm = this.fb.group({
      globalId: ["00000000-0000-0000-0000-000000000000"],
      name: ['', [Validators.required, Validators.maxLength(25)]],
      colorId: [0],
      scheduleSetupId: [0],
      displayOrder: [0]
    });

    this.phaseForm.get('name')?.valueChanges.subscribe(value => {
      this.setPhaseNameErrorMessage(value);
    });
  }

  ngAfterViewInit(): void {
    this.focusNameField();
    this.cdr.detectChanges();
  }

  focusNameField(): void {
    setTimeout(() => {
      if (this.nameInputElement) {
        this.nameInputElement.nativeElement.focus();
      }
    }, 300);
  }

  setPhaseNameErrorMessage(value: string): void {
    const maxLength = 25;
    const currentLength = value ? value.length : 0;
    if (currentLength > maxLength) {
      const overCount = currentLength - maxLength;
      this.phaseNameErrorMessage = `${overCount} character${overCount > 1 ? 's' : ''} over`;
    } else {
      this.phaseNameErrorMessage = '';
    }
  }

  initLoad(): void {
    this.colorService.getData().subscribe((res: any) => {
      const colorGet = res.result;
      this.colors = colorGet;
    });
  }

  updateSelectedColor(colorId: number): void {
    const selectedColor = this.colors.find(color => color.id === colorId);
    this.selectedColor = selectedColor ? selectedColor.colorCollection : '';
    this.selectedColorName = selectedColor ? selectedColor.name : '';

  }




 


  onSave(actionType: 'save' | 'saveAndNew' = 'save'): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isSaving || this.isSaveAndNew) {
        return reject('Already processing');
      }
  
      if (this.phaseForm.valid) {
        // Set the saving flags based on the action type
        if (actionType === 'saveAndNew') {
          this.isSaveAndNew = true;
        } else {
          this.isSaving = true;
        }
  
        // Perform the save operation
        this.phaseService.postData(this.phaseForm.value).subscribe(
          res => {
            this.messageService.success('Saved Successfully');
            this.isFormDirty = false;
            if (actionType === 'saveAndNew') {
              this.isSaveAndNew = false; 
              this.phaseSavedAndNew.emit(res.result);
              this.phaseSavedSelected.emit(res.result); 
            } else {
              this.phaseSaved.emit(); 
              this.isSaving = false; 
              this.phaseSavedSelected.emit(res.result); 
            }
            resolve();
          },
          err => {
            this.messageService.error('Failed to save data');
            // Reset the saving flags on error
            if (actionType === 'saveAndNew') {
              this.isSaveAndNew = false; 
            } else {
              this.isSaving = false; 
            }
            reject(err);
          }
        );
      } else {
        // Mark all controls as dirty if the form is invalid
        Object.values(this.phaseForm.controls).forEach(control => {
          if (control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
        });
        reject('Form is invalid');
      }
    });
  }
  
  
  

  onSaveAndNew(): void {
    this.onSave('saveAndNew')
      .then(() => {
        this.phaseForm.reset();
        this.initializeForm();  
      })
      .catch((error) => {
        console.error('Error during save and new:', error);
      });
  }
  




  onCancel(): void {
    this.cancel.emit(); 
  }

  showUnsavedChangesModal(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Unsaved changes',
      nzContent: 'You have unsaved changes, do you want to save your changes before closing?',
      nzOkText: 'Save',
      nzOkType: 'primary',
      nzCancelText: 'Don\'t Save',
      nzOnOk: () => {
        this.onSave();
      },
      nzOnCancel: () => {
        console.log("Not saving, closing modal");
        this.isFormDirty = false;

        this.cancel.emit();
        this.confirmModal?.destroy();
      }
    });
  }

 

  showConfirmDelete(): void {
    this.modal.confirm({
      nzTitle: 'Delete phase?',
      nzContent: 'Any schedule items using this phase will be reset to unassigned. Are you sure you want to continue?',
      nzOkText: 'Delete',
      nzCentered: true,
      nzOkDanger: true,
      nzOkLoading: this.deleteLoading, 
      nzOnOk: () => {
        this.deleteLoading = true; 
        return this.onDelete().then(() => {
          this.deleteLoading = false; 
        }).catch(() => {
          this.deleteLoading = false; 
        });
      },
      nzCancelText: 'Cancel',
      nzOnCancel: () => console.log('Delete action canceled')
    });
  }
  
  onDelete(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.editPhase && this.editPhase.GlobalId) {
        this.phaseService.deletePhase(this.editPhase.GlobalId).subscribe(
          () => {
            this.messageService.success('Deleted successfully');
            this.cancel.emit(); 
            this.phaseSaved.emit();
            resolve(); 
          },
          err => {
            this.messageService.error('Failed to delete phase');
            reject(err); 
          }
        );
      } else {
        reject('No phase selected to delete');
      }
    });
  }






}
