import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { NewscheduleItemComponent } from 'src/app/project-management/components/schedules-Components/schedule-common/newschedule-item/newschedule-item.component';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { ProjectManagementSelectDialogBoxComponent } from '../../project-management-select-dialog-box/project-management-select-dialog-box.component';
import { JobInfoComponentInformation } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { Subscription } from 'rxjs';
import { FilterSearchParameter, LookupFilterResponse } from 'src/app/shared/component/Models/LookupModels';
import { JobInformationResponse } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { StandardColumnSetupResponse, StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { ScheduleService } from 'src/Service/Project-Management/schedule.service';
import { ScheduleItemParameterResponse } from 'src/Models/Project-Management/projectManagement';
import { CommentMessagesComponent } from 'src/app/company-settings/comment-messages/comment-messages.component';
import { ProjectManagementSiteDiariesCommentComponent } from '../../Site-Diaries/project-management-site-diaries-comment/project-management-site-diaries-comment.component';
import { NewRFIsComponent } from 'src/app/messaging/Components/RFIs/new-rfis/new-rfis.component';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';


interface ItemData {
  id: number;
  name: string;
  age: number;
  address: string;
  icon: string;
  number: number;
  icon2: string;
}
interface Job {
  label: string;
  DisplayOrder: number;
  Color: string;
}
interface DataItem {
  name: string;
  age: number;
  address: string;
  id: number;
}

interface ColumnItem {
  name: string;
  id: number;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<DataItem> | null;
  sortDirections: NzTableSortOrder[];
  icon: string;

}

@Component({
  selector: 'app-schedule-dashborad',
  templateUrl: './schedule-dashborad.component.html',
  styleUrls: ['./schedule-dashborad.component.css']
})
export class ScheduleDashboradComponent implements OnInit  , OnDestroy {
  private Schesubscriptions: Subscription[] = [];
  private Schedulesubscription: Subscription; 
  private scheduleFilterSubscription: Subscription;
  private displayColumnSubscriptions: Subscription;
  jobInfoOptions: JobInfoComponentInformation[] = [];
  scheduleListViewData: ScheduleItemParameterResponse[] = [];   
  PROJECT_MANG_SCHEDULE: string = FiltersName.PROJECT_MANG_SCHEDULE;
  selectedValue = null;
  selectiontabsdashbord = [1, 2, 3];
  switchValue1 = false;
  switchValue2 = false;
  setOfCheckedId = new Set<number>();
  listOfCurrentPageData: readonly ItemData[] = [];
  indeterminate = false;
  listOfData: readonly ItemData[] = [];
  checked = true;
  checked5 = false;
  isCollapsed = false;
  selectedJobId: number = 0;
  @Input() JobInformationResponse: JobInformationResponse;


  //  Grid Properties
  PAGE_ID: number = -1;
  displayedColumns: string[] = [];
  displayColumnSubscription: Subscription;
  selectedFilter: number = -1;
  isFilterApplied: boolean = false;
  lookupFilterResponse: LookupFilterResponse[] = [];
  defaultFilterId_selected: number = 0
  currentPage = 1;
  pageSize = 50;
  totalRecords = 100;
  isLoading: boolean = false;
  defaultFilterIdSelected: number = 0;
  filterSearchParameter: FilterSearchParameter;
  displayedData: ScheduleItemParameterResponse[] = [];
  fixedSelectedColumnNames: string[] = [];
  selected_View_Columns: string[] = [];
  getStandardViewColumnFilterData: StandardColumnSetupResponse[] = [];
  getStandardViewFilterData: StandardViewResponse[] = [];
  selectedValueControl: any;
  selectionFrom: string = 'DB' ; // New Changes By Aamir Ali - 22-Apr2024
  constructor(private modal: NzModalService, private filterService: FilterSideMenuBarService,
    private jobInfoService: JobInformationService,
    public accessLocalStorageService: AccessLocalStorageService,
    private jobService: JobInformationService,
    private LookupFilterService: LookupFilterService,
    private scheduleService: ScheduleService,
    private internalUserService: InternalUserService,
    private standardViewService: StandardViewForAllService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { 
    if (this.scheduleFilterSubscription) {
      this.scheduleFilterSubscription.unsubscribe();
    }
    if (this.Schedulesubscription) {
        this.Schedulesubscription.unsubscribe();
    }
    // if (this.SchedulestandardViewFilterSubscription) {
    //   this.SchedulestandardViewFilterSubscription.unsubscribe();
    // }
    console.clear();
  }

  ngOnInit(): void {
    this.internalUserService.setSelectedFilterId(0); // New Changes By Aamir Ali - 31-May-2024
    if (this.scheduleFilterSubscription) {
      this.scheduleFilterSubscription.unsubscribe();
    }
    if (this.Schedulesubscription) {
        this.Schedulesubscription.unsubscribe();
    }
  
    this.PAGE_ID = 8;

    this.displayedColumns = [];
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    // Add subscriptions to the array
    this.Schesubscriptions.push(
      this.standardViewService.$displaySelectedColApply.subscribe(colName => {
        if (colName.length > 0) {
          this.displayedColumns = colName;
          this.changeDetectorRef.detectChanges();
        }
      })
    );

    this.Schesubscriptions.push( 
      this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
        this.selectedFilter = standardType != -1 ? standardType : standardType;
      })
    );
 
    this.selectedJobId = this.accessLocalStorageService.getJobId();
    this.accessLocalStorageService.getJobId();
    this.accessLocalStorageService.getJobName();
    this.listOfData = new Array(10).fill(0).map((_, index) => ({
      id: index + 1, // Assuming IDs start from 1
      name: `Edward ${index}`,
      age: 32,
      address: `London ${index}`,
      icon: 'eye-fill',
      icon2: 'edit-fill',
      number: index + 1,
    }));
   // this.getStandardFilterByFormPageId(this.PAGE_ID);
    this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom); // New Changes By Aamir Ali - 22-Apr2024
    
  }
  tabs = [1, 2, 3];
  selectionTabTitle(tab: number): string {
    switch (tab) {
      case 1:
        return 'Calender';
      case 2:
        return 'List';
      case 3:
        return 'Gantt';
      default:
        return '';
    }
  }


  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }


// here add api also get company data default morning mai add karni hai

  newSchedulemodalOpen(select: string): void {
    const jobId = this.accessLocalStorageService.getJobId();

    if (typeof jobId !== 'number') {
      // If job ID is not a number, open ProjectManagementSelectDialogBoxComponent
      const modalRef = this.modal.create({
        nzWidth: '32%',
        nzStyle: {
          top: '36%',
        },
        nzContent: ProjectManagementSelectDialogBoxComponent,
        nzData: {
          type: select,
         // leads: this.jobInfoOptions
        },
        nzFooter: null
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
    } else {
      // If job ID is a number, make API call to get job data
      this.jobInfoService.getDataId(jobId).subscribe(res => {
        const modalRef = this.modal.create({
          nzStyle: {
            top: '22px',
          },
          nzData: {
            JOB_ID: jobId,
            JOB_NAME: this.accessLocalStorageService.getJobName(),
            JOB_SELECTED_OBJECT: res.result
          },
          nzContent: NewscheduleItemComponent,
          nzFooter: null
        });
        modalRef.componentInstance.cancel.subscribe(() => {
          modalRef.destroy();
        });
      });
    }
  }




  ScheduleTabTitle(tab: number): string {
    switch (tab) {
      case 1:
        return 'Schedule ';
      case 2:
        return 'Baseline';
      case 3:
        return 'Workday Exceptions';
      default:
        return '';

    }
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange($event: readonly ItemData[]): void {
    this.listOfCurrentPageData = $event;
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'Phase',
      id: 1,
      icon: '',
      sortOrder: 'descend',
      sortFn: (a: DataItem, b: DataItem) => a.id - b.id,
      sortDirections: [null],

    },
    
    {
      name: 'Title',
      id: 2,
      icon: '',
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: DataItem, b: DataItem) => a.address.localeCompare(b.address),
    },
    {
      name: 'Complete',
      id: 3,
      icon: '',
      sortOrder: 'descend',
      sortFn: (a: DataItem, b: DataItem) => a.age - b.age,
      sortDirections: [null],
    },
    {
      name: 'Duration',
      id: 3,
      icon: '',
      sortOrder: 'descend',
      sortFn: (a: DataItem, b: DataItem) => a.age - b.age,
      sortDirections: [null],
    },
    {
      name: 'Start',
      id: 6,
      icon: '',
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: DataItem, b: DataItem) => a.address.localeCompare(b.address),
    },
    {
      name: 'End',
      id: 7,
      icon: '',
      sortOrder: null,
      sortDirections: ['ascend', 'desoend', null],
      sortFn: (a: DataItem, b: DataItem) => a.address.localeCompare(b.address),
    },
    {
      name: 'Assigness',
      id: 8,
      icon: '',
      sortOrder: 'descend',
      sortFn: (a: DataItem, b: DataItem) => a.age - b.age,
      sortDirections: [null],
    },
    {
      name: 'Accepted',
      id: 9,
      icon: '',
      sortOrder: 'descend',
      sortFn: (a: DataItem, b: DataItem) => a.age - b.age,
      sortDirections: [null],
    },
    {
      name: 'Pending',
      id: 10, icon: '',
      sortOrder: 'descend',
      sortFn: (a: DataItem, b: DataItem) => a.age - b.age,
      sortDirections: [null],
    },
    {
      name: 'Declined',
      id: 11,
      icon: '',
      sortOrder: 'descend',
      sortFn: (a: DataItem, b: DataItem) => a.age - b.age,
      sortDirections: [null],
    },
    {
      name: 'Comments',
      id: 12,
      icon: '',

      sortOrder: 'descend',
      sortFn: (a: DataItem, b: DataItem) => a.age - b.age,
      sortDirections: [null],
    },
    {
      name: 'RFIs',
      id: 13,
      icon: '',
      sortOrder: 'descend',
      sortFn: (a: DataItem, b: DataItem) => a.age - b.age,
      sortDirections: [null],
    },
    {
      name: '',
      id: 14,
      icon: 'home-fill',
      sortOrder: 'descend',
      sortFn: (a: DataItem, b: DataItem) => a.age - b.age,
      sortDirections: [null],
    },
    // {
    //   name: 'Predecessors',
    //   id: 15,
    //   icon: '',
    //   sortOrder: 'descend',
    //   sortFn: (a: DataItem, b: DataItem) => a.age - b.age,
    //   sortDirections: [null],
    // },
    // {
    //   name: 'Actions',
    //   id: 16,
    //   icon: '',
    //   sortOrder: 'descend',
    //   sortFn: (a: DataItem, b: DataItem) => a.age - b.age,
    //   sortDirections: [null],
    // },
  ];
  isColumnSticky: boolean[] = new Array(this.displayedColumns.length).fill(false);
  openFilter(component: string, pageId: number) {
    this.selectionFrom = ''; // New Changes By Aamir Ali - 22-Apr2024
    this.isColumnSticky= new Array(this.displayedColumns.length).fill(false);
    this.filterService.toggleFilter(component, pageId);
    this.internalUserService.userAppliedFilter(false);
  }

  // Grid Methods With out Standard View 

  // getStandardFilterByFormPageId(pageId: number) {
  //   if (this.scheduleFilterSubscription) {
  //     this.scheduleFilterSubscription.unsubscribe();
  // }
  // if (this.Schedulesubscription) {
  //     this.Schedulesubscription.unsubscribe();
  // }
  //   this.LookupFilterService.getStandardFilterByFormPageId(pageId);
  //   this.scheduleFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(result => {
  //     if (result) {
  //       this.lookupFilterResponse = result.result;

  //       let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
  //       this.jobService.setSelectedFilterId(defaultFilterId);
  //       this.defaultFilterId_selected = defaultFilterId;
  //       console.log("this.selectedJobId ", this.selectedJobId);

  //       this.customerGridTableData(1, this.PAGE_ID, defaultFilterId, this.currentPage, this.pageSize);
  //     }
  //   });
  //   console.log("Standard filters retrieved successfully for job ID: ", this.selectedJobId);
  // }

  getStandardFilterByFormPageId(pageId:number, type: string){
    
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.scheduleFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(result =>{
    if(result){
      this.lookupFilterResponse = result.result;
     let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
     this.internalUserService.setSelectedFilterId(defaultFilterId);
     let id = -1;
     if(type === this.selectionFrom){
      this.defaultFilterIdSelected = defaultFilterId;
      this.accessLocalStorageService.setSelectedFilterId(defaultFilterId); 

      // New Changes By Aamir Ali - 22-Apr2024
      id = this.accessLocalStorageService.getSelectedFilterId();
     }else{
      id = this.accessLocalStorageService.getSelectedFilterId();
     }

     if(id !== -1){
      this.customerGridTableData(1, pageId , id ,this.currentPage,this.pageSize);
     }

    }
    });
  }

  customerGridTableData(companyId: number, formNameId: number, lookUpFilterId: number, page: number, pageSize: number) {
    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId: lookUpFilterId,
      page: page,
      pageSize: pageSize,
      jobInformationId: this.selectedJobId // update by Mehmood for Data filter Againts of Update Job ID
    };


    this.scheduleService.getAllScheduleDataListViewyFilter(this.filterSearchParameter);
    this.Schedulesubscription = this.scheduleService.$getScheduleItemParameters.subscribe(result => {
      if (result) {
        this.totalRecords = result?.result?.rowCount;
        this.scheduleListViewData = result?.result?.rows;
        console.log('console.log(this.scheduleListViewData',this.scheduleListViewData);
        
      }
    });
  }

  // Selected Job Id From Left Side Job List
  getSelectedJobId(item: number) {
    this.selectedJobId = item;
    this.scheduleListViewData = null;
    this.getStandardFilterByFormPageId(this.PAGE_ID,this.selectionFrom);  // FILTER JOB WISE THIS METHOD ATTECHED HERE 
  }


  // Edit Schedule Model Open Method

  editSchedule(rowData: ScheduleItemParameterResponse): void {
    const modalRef = this.modal.create({
      nzContent: NewscheduleItemComponent,
      nzData:{
        setHeadId:rowData.id,
        setresponse:rowData,
      },
      nzFooter: null,
    });
    console.log('rowData',rowData);
    modalRef.componentInstance.editDataResponse = rowData;
    modalRef.componentInstance.JobData = {};
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    console.log('rowDatarowDatarowData',rowData);
    
  }
  // Edit Comments Model Open Method

  editComments(rowData: ScheduleItemParameterResponse): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesCommentComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.editDataResponse = rowData;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  // Edit RFI Model Open Method

  editRfi(): void {
    const modalRef = this.modal.create({
      nzContent: NewRFIsComponent,
      nzFooter: null,
    });
    
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  ngOnDestroy() {
    this.LookupFilterService.getStandardFilter.next(null); // New Changes By Aamir Ali - 31-May-2024
    localStorage.removeItem('Selected_Filter_Id'); // New Changes By Aamir Ali - 31-May-2024
    if (this.scheduleFilterSubscription) {
      this.scheduleFilterSubscription.unsubscribe();
    }
    if (this.Schedulesubscription) {
        this.Schedulesubscription.unsubscribe();
    }
    console.clear();
  }
}
