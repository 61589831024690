import { SelectionChoiceParameterResponse, SelectionChoiceResult, SelectionInformationApprove, SelectionInformationResponse, SelectionResponse } from 'src/Models/Selection/selectionClasses';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTreeNode, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { LookupNameSetupResponse, ScheduleItemParameterResponse } from 'src/Models/LeadTags';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { SelectionService } from 'src/Service/Selection/selection.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { LeadStatusCode, ProjectManagementSelectionCardViewwPreDefinedCodes, SelectionStatusCode } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { ProjectManagementSiteDiariesUploadFilesComponent } from '../../Site-Diaries/project-management-site-diaries-upload-files/project-management-site-diaries-upload-files.component';
import { ProjectManagementSiteDiariesCreateFilesComponent } from '../../Site-Diaries/project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { SubVendersService } from 'src/Service/SubVendorsServices/sub-venders.service';
import { NzButtonGroupSize, NzButtonType } from 'ng-zorro-antd/button';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { AllowanceInformationResponse } from 'src/Models/Selection/AllowanceClasses';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { ApplicationUserForComboResponse } from 'src/Models/InternalUser/InternalUserJob';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { NewAllowanceComponent } from '../../allowance/new-allowance/new-allowance.component';
import { AddchoiceSelectionChoiceComponent } from '../addchoice-selection-choice/addchoice-selection-choice.component';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { environment } from 'src/environments/environment.prod';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { LeadOpportunityAttachmentsUploadFilesComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { AttachmentAnnotationComponent } from 'src/app/lead-opportunities/Components/attachment-annotation/attachment-annotation.component';
import { NzImageService } from 'ng-zorro-antd/image';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { Editor, EditorConfig } from 'src/app/helpers/ckeditor-config';
import { AttachmentParameterResponse, AttachmentResponse, CreateAttachmentForDocumentRequest, CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { StatusSetup } from '../../../../../Models/CustomerInfromation/CustomerInformation';

import { AuditDetailsResponse, FilterFooterAudit } from 'src/Models/Footer/footer.model';
import { FooterService } from 'src/Service/Footer/footer.service';
import { saveAttachments } from 'src/app/helpers/Attachment_Save';
import { TimeClockService } from 'src/Service/TimeClock/time-clock.service';


interface Person {
  key: string;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.css']
})
export class SelectionComponent implements OnInit {
  dataSetForselection: string;
  isReleased: boolean = false;
  statusCode: { code: string; description: string; id?: number } | null = null;
  IMG_BASE: string = environment.IMG_BUCKET_URL;
  public Editor = Editor;
  public config = EditorConfig;
  @Output() cancel = new EventEmitter<void>();
  @Output() onSaveComplete = new EventEmitter<SelectionResponse>();
  listOfData: Person[] = []
  CNChoicesradioValue = 'A'; // Initial value set to 'A'
  SelectionResponse: SelectionResponse;
  // sufyan working addSelectionResponse
  @Input() newSelectionId: number;
  selectionResponses: SelectionResponse;
  testingHide: CustomUploadFile;
  selectedFiless: CustomUploadFile[] = [];
  isButtonDisableds = false;
  isRowVisible: boolean = false;
  validationStates: { [key: string]: NzStatus } = {};
  validationMessages: { [key: string]: string } = {};
  changedFields: Set<string> = new Set<string>();
  isSubmitted: boolean = false;
  jobID: number;
  copiedText: string = '';
  isCopied: boolean = false;
  formNameId: number = 30;
  messagingId: number; //soofe work
  messagingIdRow: any; //soofe work
  selectionReponse: number = -1;
  @Input() comments: any;
  // sufyan working add
  isSaveAndClose: boolean = false;
  issaveAndReleaseve: boolean = false;
  public ModalTitle: ModalTitle;
  resetProjectType: boolean = false;
  resetSource: boolean = false;
  resetTags: boolean = false;
  isSaveAndNew: boolean = false;
  isSave: boolean = false;
  fileName: string;
  selectedValue = null;
  addedCost: number = 0;
  visible = false
  radioValue = 'A';
  demoValue = 0.00;
  date = null;
  time: Date | null = null;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);
  value: string[] = ['0-0-0'];
  showDateFields: boolean = true;
  showScheduleItemFields: boolean = false;
  selectionModelhandle = true;
  selectioTabs = [1, 2, 3, 4, 5];
  listOfOption: Array<{ label: string; value: string }> = [];
  categoryOption: LookupNameSetupResponse[] = [];
  locationOption: LookupNameSetupResponse[] = [];
  scheduleItemOption: ScheduleItemParameterResponse[] = [];
  shareAllowanceOption: AllowanceInformationResponse[] = [];
  listOfTagOptions = [];
  customNotFoundContent = 'Adding choice will be available after saving';
  showSelector = true
  ShowSelector2 = false;
  newSelectionFormFiedls: any = {};
  pageId: number = 30;
  selectionResponce: SelectionInformationResponse;
  inputValue?: string;
  editorValue: string = '';
  radioValuess = 'A';
  radioValues = 'A';
  vendersNodes: NzTreeNodeOptions[] = [];
  vendorList: SubVendorResponse[] = [];
  selectedUsers: ApplicationUserForComboResponse[] = [];
  nzNodes: NzTreeNodeOptions[] | NzTreeNode[];
  subVendorsNodes: NzTreeNodeOptions[] = [];
  installarNodes: NzTreeNodeOptions[] = [];
  titleTouched: boolean = false;
  showTitleError: boolean = false;
  showSharedAllowanceError: boolean = false;
  sharedAllowanceTouched: boolean = false;
  sharedAllowanceId: string | null = null;
  selectedFiles: File[] = [];
  SelectInfoResponse: SelectionInformationResponse;
  size: NzButtonGroupSize = 'small';
  responceHoldingTitle: string;
  categoryNameSet: string;
  locationNameSet: string;
  private ChoiceSelectiondataSubscription: Subscription;
  responseDataChoice: SelectionChoiceParameterResponse[];
  footerResponse: AuditDetailsResponse;




  /////
    CategoryField: FormGroup;
  isDeleteTagsLoading: boolean;
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  isLoadingOne: boolean = false;
  public isTagVisible = false;
  public Approve = false;
  isLoadingEdit: boolean = false;
  PAGE_ID: 30;
  confirmModal: import("ng-zorro-antd/modal").NzModalRef<unknown, any>;
  isButtonEnabled: boolean = false;
  JobInfoId: number;
  ChangeOrderInfoId: number;
  preDefinedDataCodes = {
    Categories: ProjectManagementSelectionCardViewwPreDefinedCodes.Categories,
    Locations: ProjectManagementSelectionCardViewwPreDefinedCodes.Locations,
  };
  newChoiceFormFiedls: any = {};
  newChoiceFormFiedlsAttachment: any = {};
  cards: CardChoice[] = [];
  cardd: any[] = [];
  holdPath: string;
  holdName: any[];
  shareSingleradioValue = 'A';
  chooseAddLinkradioValue = 'A';
  // isCardVisible: boolean = false;





  constructor(
    private toastService: NzMessageService,
    private cdn: ChangeDetectorRef,
    private nzImageService: NzImageService,
    private LookupFilterService: LookupFilterService,
    private modal: NzModalService,
    private selectionService: SelectionService,
    private leadOpportunitiesService: LeadOpportunitiesService,
    private _AttachmentService: AttachmentService,
    public toastsService: NzMessageService,
    private FooterService: FooterService,
    private changeDetectorRef: ChangeDetectorRef,
    private subVendorsService: SubVendersService,
    private internalUserService: InternalUserService,
    private loadingIndicatoreService: LoadingIndicatorService,
    private _TimeClockSer: TimeClockService,
    private fb: FormBuilder,
    @Inject(NZ_MODAL_DATA) public JobData: any,
    @Inject(NZ_MODAL_DATA) public leadData: any, public localStorage: AccessLocalStorageService,
    @Inject(NZ_MODAL_DATA) public SelectionInformation: any,
    @Inject(NZ_MODAL_DATA) public SelectionInformation2: any,
  ) {

  }

  ngOnInit(): void {
    this.iniliazeFormApproveForm();
    // this.fetchActivityResponseChoice(this.newSelectionId);
    if (this.newSelectionId) {
      this.fetchActivityResponseChoice(this.newSelectionId)
    }
    this.selectionService.getCards().subscribe((cards) => {
      this.cards = cards;
      this.onSaveComplete.emit();
      console.log('cards:', this.cards);
      // if (this.cards && this.cards.length > 0) {
      //   this.newSelectionFormFiedls.statusSetupId = 437; // Set status to Pending: Available
      //   console.log('Status set to 387: Pending: Available');
      // } else {
      //   this.newSelectionFormFiedls.statusSetupId = 433; // Set status to Pending: No Choices
      //   console.log('Status set to 383: Pending: No Choices');
      // }
      // console.log('newSelectionFormFiedls:', this.newSelectionFormFiedls);
      this._TimeClockSer.selectionData$.subscribe(res => {
        if (res) {
          // Ensure it's always an array (whether res is a single object or an array)
          this.selectionData = Array.isArray(res) ? res : [res];
          if (this.selectionData && this.selectionData.length > 0) {
            this.selectedChoice = res[0].Id

            this.newSelectionFormFiedls.statusSetupId = 437; // Set status to Pending: Available
            console.log('Status set to 387: Pending: Available');
          } else {
            this.newSelectionFormFiedls.statusSetupId = 433; // Set status to Pending: No Choices
            console.log('Status set to 383: Pending: No Choices');
          }
        } else {
          this.selectionData = [];
        }
        console.log("Received new Choice in Selection data All:", this.selectionData);
      });

      const attachmentObj = cards?.map(x => x.attachmentId.result.attachmentParameters[0])
      const letSetAtt = attachmentObj?.map(x => x.filePath);
      const nameHold = attachmentObj?.map(x => x.name);
      const url = this.IMG_BASE + letSetAtt
      console.log('url', url);
      console.log('nameHold', nameHold);
      this.holdPath = url
      this.holdName = nameHold
    });
    this.selectionService.getCardsChoice().subscribe((cardsss) => {
      this.cardd = cardsss;
    });
    this.ChoiceSelectiondataSubscription = this.selectionService.getChoiceSelectionDatas().subscribe(data => {
      this.responseDataChoice = data;
      // console.log('Type of responseDataChoice:',this.responseDataChoice);
    });

    this.Newpacth(this.selectionResponses);
    this.fetchActivityResponse();
    if (this.newSelectionId) {
      this._TimeClockSer.selectionData$.subscribe(res => {
        if (res) {
          // Ensure it's always an array (whether res is a single object or an array)
          this.selectionDatas = Array.isArray(res) ? res : [res];
        } else {
          this.selectionDatas = [];
        }
        console.log("Received new Choice in Selection data All:", this.selectionDatas);
      });
    }
    this.messagingId = this.SelectionInformation?.SelectionInformation?.messagingId;
    this.messagingIdRow = this.SelectionInformation2?.SelectionInformation2;
    const storeComments = this.SelectionInformation?.SelectionInformation?.messaging?.messagingParameters.map(param => param.comments)
    this.comments = storeComments?.flat() || [];
    const jobId = this.localStorage.getJobId();
    this.getDropDownMethod();
    this.getScheduleItem();
    this.getAllowanceInformation();
    this.subVendorsGetAll();
    this.getForINstallar();
    this.iniliazeForma();
    this.shareSingleradioButtons();
    this.chooseAddLinkradioButtons();
    this.jobID = this.localStorage.getJobId();
    this.leadOpportunitiesService.getSelectedFiles().subscribe(files => {
      this.selectedFiles = files;
    });
    const children: Array<{ label: string; value: string }> = [];
    for (let i = 10; i < 36; i++) {
      children.push({ label: i.toString(36) + i, value: i.toString(36) + i });
    }
    this.listOfOption = children;
    this.newSelectionFormFiedls = {
      globalId: '00000000-0000-0000-0000-000000000000',
      selectionSetupId: 0,
      jobInformationId: jobId,
      title: '',
      isSingle: true,
      isShared: true,
      sharedAllowanceId: 0,
      isRequireOwnerToMakeSelection: false,
      catagorySetupId: '',
      locationSetupId: '',
      singleAllowanceNumber: '',
      isChooseDate: true,
      isLinkedToScheduleItem: 0,
      scheduleItemParameterId: '',
      dueNumberDays: 0,
      isBefore: true,
      isAfter: false,
      dedlineOn: new Date(),
      time: null,
      attachmentId: 0,
      messagingId: 0,
      isApproveChoicesOnly: true,
      isAddEditAndApproveChoices: false,
      publicInstructions: "",
      internalNotes: '',
      isAllowMultipleSelectedChoices: true,
      isAuto: true,
      isManual: true,
      isApprove: true,
      isDecline: true,
      statusSetupId: '',
      createSelectionSubVenderParameterRequests: [],
      createSelectionInstallerParameterRequests: [],
    };
    this.newSelectionFormFiedls['title'] = this.SelectionInformation?.SelectionInformation?.title;
    this.newSelectionFormFiedls['statusSetupId'] = this.SelectionInformation?.SelectionInformation?.statusSetupId;  // Set default status if not provided
    this.selectionReponse = this.SelectionInformation2?.SelectionInformation?.id

  }

  ngOnDestroy() {
    if (this.ChoiceSelectiondataSubscription) {
      this.ChoiceSelectiondataSubscription.unsubscribe();
    }
  }

  getDropDownMethod() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(res => {
      this.lookupSetNameResponse = res.result;

      this.changeDetectorRef.detectChanges();
      let categoryOptionValues = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.Categories.code
      );
      this.changeDetectorRef.detectChanges();
      this.categoryOption = categoryOptionValues;
      this.changeDetectorRef.detectChanges();

      let locationOptionValues = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.Locations.code
      );
      this.locationOption = locationOptionValues;
      this.changeDetectorRef.detectChanges();
    })
  }

  getScheduleItem() {
    this.leadOpportunitiesService.getAllDataScheduleItem().subscribe(res => {

      this.scheduleItemOption = res.result
      // // Check if there are options available and set the default value
      if (this.scheduleItemOption.length > 0) {
        // Assuming you want to select the first option by default
        this.newSelectionFormFiedls['scheduleItemParameterId'] = this.scheduleItemOption[0].id;
      }
    });
  }

  getAllowanceInformation() {
    this.selectionService.getAllDataAllowanceInformation().subscribe(res => {
      this.shareAllowanceOption = res.result;
    })
  }



  fileList: NzUploadFile[] = [
    {
      uid: '-1',
      name: 'claimNoPhoto.gif',
      status: 'done',
      url: 'assets/claimNoPhoto.gif',
      download: false,
    },
  ];

  getButtonType(): NzButtonType {
    if (!this.newSelectionFormFiedls.title || (this.ShowSelector2 && (!this.newSelectionFormFiedls.sharedAllowanceId)) || (this.showScheduleItemFields && (!this.newSelectionFormFiedls.scheduleItemParameterId))) {
      return null;
    } else {
      return 'primary';
    }
  }

  subVendorsGetAll() {
    this.subVendorsService.getData().subscribe(res => {
      this.vendorList = res.result.filter(data => data.automaticallyPermitAccessToNewJobs === true);
      this.vendersNodes = this.vendorList.map((data) => ({
        title: data.companyName,
        value: data.id.toString(),
        key: data.id.toString(),
        isLeaf: true,
      }));
    })
  }
  getForINstallar() {
    // this.internalUserService.getData().subscribe(result => {
    //   this.selectedUsers = result?.result.filter(data => data.isGrantExsistJobOrTemplate === true)
    //   this.installarNodes = this.selectedUsers.map((data) => ({
    //     title: data.fullName,
    //     value: data.id.toString(),
    //     key: data.id.toString(),
    //     isLeaf: true,
    //   }));
    // });
  }

  selectionCancel() {
    this.visible = false;
  }
  showSelection() {
    this.visible = true;
  }


  convertToRupees(value: number): string {
    // Your conversion logic here
    // For example, if 1 dollar = 75 rupees
    const conversionRate = 75;
    const result = (value * conversionRate).toFixed(2); // Convert to string with two decimal places
    return result;
  }

  updateConvertedValue(newValue: string): void {
    // Update the addedCost property as the user types, with a debounce of 300 milliseconds
    const inputParts = newValue.split('.');
    const integerValue = inputParts[1];
    const decimalValue = inputParts.length > 1 ? inputParts[1] : '';

    // Check if the decimal part has been manually modified by the user
    const isDecimalModifiedByUser = newValue.includes('.') && decimalValue.length > 0;

    // If the decimal part is not modified by the user, update the addedCost
    if (!isDecimalModifiedByUser) {
      this.addedCost = integerValue !== '' ? parseFloat(integerValue) / 75 : 0; // Parse the input for the integer part
    }

  }

  toggleDateFields() {
    this.showDateFields = !this.showDateFields;
    this.showScheduleItemFields = false; // Ensure the other set is hidden
  }

  toggleScheduleItemFields() {
    this.showScheduleItemFields = !this.showScheduleItemFields;
    this.showDateFields = false; // Ensure the other set is hidden
  }


  selectionModelCancel(): void {
    this.cancel.emit();
  }

  open(): void {
    this.visible = true;
  }

  selectioninnercardTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'Chooice';
      case 2:
        return 'Description';
      case 3:
        return 'Lock/Unlock';
      case 4:
        return 'Files & Discussions';
      case 5:
        return 'Participation';

      default:
        return '';
    }
  }
  autoAdjustTextAreaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; // Set the height with a maximum of 200px
  }

  Single() {
    this.showSelector = true
    this.ShowSelector2 = false
  }

  Shared() {
    this.showSelector = false
    this.ShowSelector2 = true
  }





  // uploadFiles(): void {
  //   const modalRef = this.modal.create({
  //     nzContent: ProjectManagementSiteDiariesUploadFilesComponent,
  //     nzFooter: null,
  //   });
  //   modalRef.componentInstance.cancel.subscribe(() => {
  //     modalRef.destroy();
  //   });
  //   this.isRowVisible = true;
  // }
  createNew(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    this.isRowVisible = true;
  }
  onTitleFocus() {
    this.titleTouched = true;
  }
  onTitleBlur() {
    if (!this.newSelectionFormFiedls.title && this.titleTouched) {
      this.showTitleError = true;
    } else {
      this.showTitleError = false;
    }
  }
  onSharedAllowanceBlur(): void {
    // Check if the shared allowance ID is empty or not selected
    if (!this.sharedAllowanceId) {
      // Set the error flag to true to show the error message
      this.showSharedAllowanceError = true;
      console.log('showSharedAllowanceError set to true');
    } else {
      // If the value is selected, set error flag to false
      this.showSharedAllowanceError = false;
      console.log('showSharedAllowanceError set to false');
    }
  }

  onSharedAllowanceFocus(): void {
    console.log('onSharedAllowanceFocus called');
    this.showSharedAllowanceError = false;
    console.log('showSharedAllowanceError reset to false');
  }


  // viewAll(): void {
  //   const modalRef = this.modal.create({
  //     nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
  //     nzFooter: null,
  //   });
  //   modalRef.componentInstance.cancel.subscribe(() => {
  //     modalRef.destroy();
  //   });
  // }
  removeFile(file: CustomUploadFile): void {
    const fileToRemove = this.selectedFiless.find(item => item.uid === file.uid);
    if (fileToRemove) {
      this.selectedFiless = this.selectedFiless.filter(item => item.uid !== file.uid);
      this.leadOpportunitiesService.deleteLeadByAttachmentId(fileToRemove.globalId).subscribe(
        (res) => {
          console.log("show all delete", res.result);
          this.toastService.success('File deleted successfully!');
        },

      )
    }
  }

  // SaveData(): void {
  //   if (this.CategoryField.valid) {
  //     this.loadingIndicatoreService.show();
  //     this.isLoadingOne = true;
  //     setTimeout(() => {
  //       this.leadOpportunitiesService.getLookUpNameSetup(this.CategoryField.value).subscribe(
  //         (response) => {
  //           if (response.result && 'id' in response.result && 'code' in response.result) {
  //             const id = response.result.id.toString();
  //             const code = response.result.code;

  //             if (code === 'PMSCVL') {
  //               this.newSelectionFormFiedls.projectTypeSetupId = id;
  //             } else if (code === 'PMSCVC') {
  //               this.newSelectionFormFiedls.sourceSetupId = [id];
  //             } else {
  //               this.toastsService.error('Response code does not match expected values.');

  //               this.newSelectionFormFiedls.locationSetupId = id; // Example usage
  //               this.newSelectionFormFiedls.catagorySetupId = id; // Example usage
  //             }
  //           } else {
  //             this.toastsService.error('Response does not contain a valid id or code.');
  //           }

  //           this.getDropDownMethod();
  //           this.loadingIndicatoreService.hide();
  //           this.isTagVisible = false;
  //           this.iniliazeForma();  // Ensure this method properly resets or initializes the form
  //           this.isLoadingOne = false;
  //           // this.toastsService.success(`${tagName} saved successfully`);
  //         },
  //         (error) => {
  //           this.loadingIndicatoreService.hide();
  //           this.isLoadingOne = false;

  //           if (error.status === 500) {
  //             this.toastsService.error('Internal Server Error. Please try again later.');
  //           } else if (error.status === 400) {
  //             this.toastsService.error('Bad Request. Please check your input.');
  //           } else {
  //             this.toastsService.error('Failed to save division trade. Please try again.');
  //           }
  //         }
  //       );
  //     }, 1000);
  //   } else {
  //     this.CategoryField.markAllAsTouched();
  //   }
  // }


  SaveData(): void {
    if (this.CategoryField.valid) {
      this.loadingIndicatoreService.show();
      this.isLoadingOne = true;

      setTimeout(() => {
        this.leadOpportunitiesService.getLookUpNameSetup(this.CategoryField.value).subscribe(
          (response) => {
            if (response.result && 'id' in response.result && 'code' in response.result) {
              const id = response.result.id.toString();
              const code = response.result.code;

              // Check if globalId exists
              if (response.result.globalId) {
                // Perform the necessary frontend updates if globalId exists
                console.log('Global ID exists:', response.result.globalId);
                // Example: Update form field
                this.newSelectionFormFiedls.globalId = response.result.globalId;
              } else {
                console.log('Global ID does not exist.');
                // Handle the case where globalId does not exist
              }

              if (code === 'PMSCVL') {
                this.newSelectionFormFiedls.projectTypeSetupId = id;
                console.log('Set projectTypeSetupId:', id);
              } else if (code === 'PMSCVC') {
                this.newSelectionFormFiedls.sourceSetupId = [id];
                console.log('Set sourceSetupId:', [id]);
              } else {
                this.newSelectionFormFiedls.locationSetupId = id;
                this.newSelectionFormFiedls.catagorySetupId = id;
                console.log('Set locationSetupId and catagorySetupId:', id);
              }

              // Fetch the latest dropdown values and update the options
              this.getDropDownMethod();

              // Update the form field with the new location ID
              this.newSelectionFormFiedls['locationSetupId'] = id;
              console.log('Updated locationSetupId in form fields:', this.newSelectionFormFiedls['locationSetupId']);

              // Trigger change detection
              this.changeDetectorRef.detectChanges();

              // Log current form field values to check selection
              console.log('Current form fields:', this.newSelectionFormFiedls);

            } else {
              this.toastsService.error('Response does not contain a valid id or code.');
            }

            this.loadingIndicatoreService.hide();
            this.isTagVisible = false;
            this.iniliazeForma();  // Ensure this method resets or initializes the form properly
            this.isLoadingOne = false;

          },
          (error) => {
            this.loadingIndicatoreService.hide();
            this.isLoadingOne = false;

            if (error.status === 500) {
              this.toastsService.error('Internal Server Error. Please try again later.');
            } else if (error.status === 400) {
              this.toastsService.error('Bad Request. Please check your input.');
            } else {
              this.toastsService.error('Failed to save division trade. Please try again.');
            }
          }
        );
      }, 1000);
    } else {
      this.CategoryField.markAllAsTouched();
    }
  }



  editData() {
    if (this.CategoryField.valid) {
      this.loadingIndicatoreService.show();
      const tagName = this.CategoryField.get('name')?.value;
      setTimeout(() => {
        this.leadOpportunitiesService.getLookUpNameSetup(this.CategoryField.value).subscribe(
          (response: any) => {
            this.loadingIndicatoreService.hide();
            this.isTagVisible = false;
            if (response.result.globalId) {
              console.log('Global ID exists:', response.result.globalId);
              // Example: Update form field or perform other frontend tasks
              this.newSelectionFormFiedls.globalId = response.result.globalId;
            } else {
              console.log('Global ID does not exist.');
            }

            // this.iniliazeForm();
            // this.iniliazeForm2();
            this.getDropDownMethod();
            this.iniliazeForma();

            this.isLoadingEdit = false;
            this.toastsService.success(`${tagName} Update successfully`);
          },
          (error) => {
            this.loadingIndicatoreService.hide();
            if (error.status === 500) {
              this.toastsService.error(
                'Internal Server Error. Please try again later.'
              );
            } else if (error.status === 400) {
              this.toastsService.error('Bad Request. Please check your input.');
            } else {
              this.toastsService.error(
                'Failed to save division trade. Please try again.'
              );
            }
          }
        );
      }, 1000); // 2000 milliseconds (2 seconds)
    } else {
    }
  }



  ConfirmDeleteTags(deleteTitle: string): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: `Delete ${deleteTitle}?`,
      nzContent:
        `This ${deleteTitle} is not applied to any subs/vendors and will be deleted.`,
      nzOkDanger: true,
      nzOkText: 'Delete',
      nzCancelText: 'Cancel',
      nzModalType: 'confirm',
      nzOkLoading: this.isDeleteTagsLoading,
      nzAutofocus: 'ok',
      nzCentered: true,
      nzOnOk: () => this.DeleteData(),
    });
  }
  SaveOrUpdateData() {
    if (this.isLoadingEdit) {
      this.editData();
    } else {
      this.SaveData();
    }
  }

  DeleteData(): Promise<boolean> {
    const vendorId = this.CategoryField.get('globalId')?.value;
    const tagName = this.CategoryField.get('name')?.value;
    this.isDeleteTagsLoading = true;
    this.loadingIndicatoreService.show();

    return new Promise<boolean>((resolve, reject) => {
      this.leadOpportunitiesService.deleteDataLookupNameSetup(vendorId).subscribe(
        (res: any) => {
          this.isDeleteTagsLoading = false;
          setTimeout(() => {
            this.toastsService.success(`${tagName} Deleted successfully`);
            this.isTagVisible = false;
            this.loadingIndicatoreService.hide();
            this.confirmModal.destroy();
            resolve(true);
          }, 1000);
          this.isLoadingEdit = false;
          this.isTagVisible = false;
          this.iniliazeForma();
          // this.iniliazeForm();
          // this.iniliazeForm2();

          this.getDropDownMethod();
          this.newSelectionFormFiedls['catagorySetupId'] = '';
          this.newSelectionFormFiedls['locationSetupId'] = '';
        },
        (error) => {
          this.isDeleteTagsLoading = false;
          this.isTagVisible = false;
          reject(false);
        }
      );
    });
  }

  showConfirmData(): void {
    if (this.CategoryField.dirty) {
      // The form has unsaved changes, show confirmation modal
      this.confirmModal = this.modal.confirm({
        nzTitle: '<b>Save Changes?</b>',
        nzContent:
          '<p>This Lead Tags has unsaved changes. Do you want to save your changes before closing??</p> ',
        nzOkText: 'Save',
        nzCancelText: "Don't Save",
        nzOnOk: () => {
          // User confirmed to discard changes, save and close modal
          this.SaveData();
        },
        nzOnCancel: () => {
          // User canceled, reset form and close modal
          this.CategoryField.reset();
          this.isTagVisible = false;
        },
        nzCloseIcon: 'false',
        nzStyle: { top: '250px' },
        nzClassName: 'custom-modal-content',
      });
    } else {
      // No unsaved changes, close the modal directly
      this.CategoryField.reset();
      this.isTagVisible = false;
    }
  }

  iniliazeForma() {
    this.CategoryField = this.fb.group({
      id: [''],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      name: ['', [Validators.required, Validators.maxLength(50)]],
      companyParameterId: [1],
      formNameId: this.pageId,
      lookUpStandardId: [],
      parentCode: [''],
      code: [''],
      description: [''],
      isFieldValue: [true],
    });
  }


  Editaloownce(): void {
    const modalRef = this.modal.create({
      nzContent: NewAllowanceComponent,
      nzFooter: null,
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.allowanceSaved.subscribe((savedData: any) => {
      console.log('Received saved allowance data:', savedData);
      this.handleSavedAllowanceData(savedData);
      modalRef.destroy();
    });
  }

  handleSavedAllowanceData(savedData: any): void {
    console.log('Handling saved data:', savedData);

    // Push the newly saved data into the shareAllowanceOption array
    this.shareAllowanceOption.push(savedData);

    // Trigger Angular change detection to update the view
    this.changeDetectorRef.detectChanges();
  }


  copyText() {
    const textToCopy = document.getElementById('textToCopy')?.textContent;
    navigator.clipboard.writeText(textToCopy).then(() => {
      this.toastsService.success('Copied');
      this.copiedText = textToCopy;  // Set the copied text to display in the input field
      this.isCopied = true;  // Show the input fields

      setTimeout(() => {
        this.isCopied = false;
      }, 2000);  // 2000 milliseconds = 2 seconds

      console.log('Text copied to clipboard:', textToCopy);
    }).catch(err => {
      console.error('Could not copy text:', err);
    });
  }

  copyInputText() {
    navigator.clipboard.writeText(this.copiedText).then(() => {
      this.toastsService.success('Input text copied');
      console.log('Input text copied to clipboard:', this.copiedText);
    }).catch(err => {
      console.error('Could not copy input text:', err);
    });
  }


  checkCharacterLimits(): void {
    const { title, suburb, state, postalCode, subject } = this.newSelectionFormFiedls;
    this.isButtonDisableds = false;
    if (title?.length > 50 || suburb?.length > 50 || state?.length > 3 || postalCode?.length > 15 || subject?.length > 50) {
      this.isButtonDisableds = true;
    }
  }
  isDeleteLoading: boolean = false;
  public mySelection: string[] = [];



  ConfirmDelete(): void {
    if (this.isDeleteLoading) {
      return;
    }
    const selectedCount = this.mySelection.length;
    this.modal.confirm({
      nzTitle: `Delete 1 Selection Choice?  ${selectedCount > 1 ? 's' : ''}`,
      nzContent: 'Are you sure you want to delete this selection choice?',
      nzOkText: `Delete ${selectedCount > 1 ? 's' : ''}`,
      nzOkDanger: true,
      // nzOnOk: () => this.(),
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteChoice('63470620-0F4B-4217-8DFF-5B84FDC3AB97')
          .then(() => {
            this.selectionCancel();
          })
          .catch(() => {

          });
      },
      nzCentered: false,
      nzBodyStyle: { 'border-radius': '30px', 'height': 'auto', 'padding-top': '15', },
      nzStyle: {
        top: '250px',
      },
      nzClassName: 'ant-modal-confirm-custom',
      nzCloseIcon: '',
      nzIconType: '',
    });
  }



  deleteChoice(globalId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!globalId) {
        this.toastsService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }

      // Ensure you are passing the correct GlobalId
      this.selectionService.deleteSelectionChoice(globalId).subscribe(
        (res) => {
          this.isDeleteLoading = false;
          this.toastsService.success(`User Deleted Choice!`);
          // this.onSaveComplete.emit(this.choiceResponses);
          this.changeDetectorRef.detectChanges();
          resolve();  // Resolve the promise after success
        },
        (error) => {
          this.isDeleteLoading = false;
          this.toastsService.error('An error occurred while deleting the record. Please try again.');
          reject();  // Reject the promise on error
        }
      );
    });
  }
  //

  showDeleteConfirm(): void {
    this.modal.confirm({
      nzTitle: 'Disable Manual Sort Order',
      nzContent: 'Are you sure you want to disable manual sort order? Doing so will reset your sort order.',
      nzOkText: 'Disable',
      nzOkType: 'primary',
      nzOnOk: () => console.log('OK'),
      nzCancelText: 'Cancel',
      nzOnCancel: () => console.log('Cancel'),
      nzClassName: 'ant-modal-confirm-custom',
      nzBodyStyle: { 'border-radius': '30px', 'height': 'auto', 'padding-top': '15', },
      nzStyle: {
        top: '250px',
      },
      nzCloseIcon: '',
      nzIconType: '',
    });
  }
  openSelectionChoice(data) {
    this.loadingIndicatoreService.show();
    setTimeout(() => {
      this.loadingIndicatoreService.hide();
      this.isLoadingEdit = false;
      const modalRef = this.modal.create({
        nzContent: AddchoiceSelectionChoiceComponent,
        nzFooter: null,
      });
      if (modalRef.componentInstance) {
        modalRef.componentInstance.CategorySendChoice = this.categoryNameSet;
        modalRef.componentInstance.LocationSendChoice = this.locationNameSet;
        modalRef.componentInstance.SelectTitleSendChoice = this.responceHoldingTitle;
        modalRef.componentInstance.SelectIdAddChoice = this.selectionReponse;
        modalRef.componentInstance.SelectIdByChoice = this.newSelectionId;
      }
      modalRef.afterClose.subscribe(res => {
        console.log('close response', res);
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      // modalRef.componentInstance.ChoiceSelectionResponse.subscribe((res) => {
      //   console.log('res.image', res);
      //   this.newChoiceFormFiedls.title = res.title;
      //   this.newChoiceFormFiedls.description = res.description;
      //   modalRef.destroy();
      // });
      modalRef.componentInstance.ChoiceSelectionAttachmentResponse.subscribe((res: AttachmentResponse) => {
        console.log('RES ATTACHMENT', res);
        if (!res) {
          console.error('Attachment response is null or undefined');
        } else if (typeof res === 'object' && 'id' in res) {
          console.log('RES ATTACHMENT.id', res.id); // Access the id directly from res
        } else {
          console.error('Response is not of expected type or ID is missing.');
        }
        modalRef.destroy();
      });

      modalRef.componentInstance.ChoiceSelectionCostItemResponse.subscribe((res) => {
        console.log('ChoiceSelectionCostItemResponse', res);
        this.newChoiceFormFiedls.ownerPrice = res.ownerPrice;
        modalRef.destroy();
      });
    }, 500);
  }




  // Noor Working//

  isConfirmUnreleaseVisible: boolean = false;



  onUnrelease(): void {
    // Show confirmation modal when the user clicks "Unrelease"
    this.modal.confirm({
      nzTitle: 'Unrelease Selection?',
      nzContent: 'Are you sure you want to reset this selection to UnReleased?',
      nzOkText: 'Unrelease',
      nzOkType: 'primary',
      nzOnOk: () => this.confirmUnrelease(),  // Call confirmUnrelease on confirmation
      nzCancelText: 'Cancel',
      nzOnCancel: () => this.onCancelUnrelease(),  // Hide modal on cancel
      nzClassName: 'ant-modal-confirm-custom', // Custom class for styling
      nzBodyStyle: { 'border-radius': '30px', 'padding': '15px' },
      nzStyle: { top: '250px' },
      nzCloseIcon: '',  // Remove close icon
      nzIconType: '',   // Remove icon type
    });
  }

  onCancelUnrelease(): void {
    // Hide confirmation modal when the user cancels
    this.isConfirmUnreleaseVisible = false;
    this.changeDetectorRef.detectChanges();
  }

  confirmUnrelease(): void {
  console.log('Setting status to Unreleased');
  this.newSelectionFormFiedls.statusSetupId = SelectionStatusCode.UnReleased.statusid;
  this.statusCode = {
    code: SelectionStatusCode.UnReleased.code,
    description: SelectionStatusCode.UnReleased.description,
    id: SelectionStatusCode.UnReleased.statusid
  };
  this.isReleased = false;  // Update state to indicate it is unreleased
  this.isConfirmUnreleaseVisible = false;

  console.log('Current statusCode:', this.statusCode);
  this.changeDetectorRef.detectChanges();

  // Save the new selection status
  this.saveSelection('save')
    .then(() => {
    })
    .catch((error) => {
      console.error('Error during unrelease:', error);
    });
   }


SelectionStatusCode = SelectionStatusCode;
statussetupColorMap = {
  [SelectionStatusCode.PendingNoChoice.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
  [SelectionStatusCode.PendingAvailable.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
  [SelectionStatusCode.ExpiredAvailable.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
  [SelectionStatusCode.ExpiredNoChoices.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' }, // corrected from ##fbe9b1
  [SelectionStatusCode.UnReleased.code]: { backgroundColor: '#c7d0d9', textColor: '#202227' },
  [SelectionStatusCode.PendingOverdue.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
  [SelectionStatusCode.Selected.code]: { backgroundColor: '#c4fae2', textColor: '#005c35' } // corrected from ##c4fae2
};

  status: number;
isSaveAndUnrelease: boolean = false;

saveSelection(
  actionType: 'save' | 'saveAndClose' | 'saveAndNew' | 'saveAndRelease' | 'saveAndUnrelease' = 'save'
): Promise<void> {
  return new Promise((resolve, reject) => {
    // Prevent concurrent actions
    if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
      return reject('Already processing');
    }

    switch (actionType) {
      case 'saveAndNew':
        this.isSaveAndNew = true;
        break;
      case 'saveAndClose':
        this.isSaveAndClose = true;
        break;
      case 'saveAndRelease':
        this.issaveAndReleaseve = true;
        break;
      case 'saveAndUnrelease':
      case 'save':
        this.isSaveAndUnrelease = true;
        this.newSelectionFormFiedls.statusSetupId = 431; // Set status as Unreleased
        break;
    }

    this.isSubmitted = true;
    this.checkErrors(true); // Validate form fields

    // Handle validation errors
    if (Object.values(this.validationStates).includes('error')) {
      setTimeout(() => {
        const errorElements = document.querySelectorAll('.top-of-modal');
        if (errorElements.length > 0) {
          const lastErrorElement = errorElements[errorElements.length - 1];
          lastErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100);

      this.resetFlags();
      console.log('Validation errors:', this.errorFields);
      return reject('Validation errors present');
    }

    // const currentDate = new Date();
    // const deadlineDate = this.newSelectionFormFiedls.dedlineOn ? new Date(this.newSelectionFormFiedls.dedlineOn) : null;

    // if (!deadlineDate) {
    //   this.newSelectionFormFiedls.statusSetupId = 431; // Unreleased status
    // } else {
    //   // New condition to set status to 441 if selectionResponses.StatusName is present
    //   if (this.selectionResponses?.StatusName) {
    //     console.log("Setting status to 441: Specific Condition Met");
    //     this.newSelectionFormFiedls.statusSetupId = 441;
    //   } else {
    //     // If deadline is selected and one of the action buttons (save, saveAndNew, saveAndClose) is clicked, still set status to Unreleased
    //     if (actionType === 'save' || actionType === 'saveAndNew' || actionType === 'saveAndClose') {
    //       this.newSelectionFormFiedls.statusSetupId = 431; // Unreleased status
    //     } else if (this.newSelectionFormFiedls.isRequireOwnerToMakeSelection && deadlineDate < currentDate) {
    //       // If required and past deadline: Overdue
    //       this.newSelectionFormFiedls.statusSetupId = 438; // Overdue
    //     } else {
    //       if (this.cards && this.cards.length > 0) {
    //         // Cards exist
    //         if (deadlineDate >= currentDate) {
    //           this.newSelectionFormFiedls.statusSetupId = 437; // Pending: Available
    //         } else {
    //           this.newSelectionFormFiedls.statusSetupId = 440; // Expired: Available
    //         }
    //       } else {
    //         // No cards exist
    //         if (deadlineDate >= currentDate) {
    //           this.newSelectionFormFiedls.statusSetupId = 433; // Pending: No Choices
    //         } else {
    //           this.newSelectionFormFiedls.statusSetupId = 440; // Expired: No Choices
    //         }
    //       }
    //     }
    //   }
    // }

    const currentDate = new Date();
    const deadlineDate = this.newSelectionFormFiedls.dedlineOn ? new Date(this.newSelectionFormFiedls.dedlineOn) : null;

    // Set initial status
    if (!deadlineDate) {
      this.newSelectionFormFiedls.statusSetupId = 431; // Unreleased status
    } else if (this.selectionResponses?.StatusSetupId == 441) {
      // Check for selectionResponses.StatusName
      console.log("Setting status to 441: Specific Condition Met");
      this.newSelectionFormFiedls.statusSetupId = 441;
    } else {
      // Check action type
      if (actionType === 'save' || actionType === 'saveAndNew' || actionType === 'saveAndClose') {
        this.newSelectionFormFiedls.statusSetupId = 431; // Unreleased status
      } else if (this.newSelectionFormFiedls.isRequireOwnerToMakeSelection && deadlineDate < currentDate) {
        // If required and past deadline: Overdue
        this.newSelectionFormFiedls.statusSetupId = 438; // Overdue
      } else {
        // Check cards existence
        if (this.selectionDatas && this.selectionDatas.length > 0) {
          // Cards exist
          this.newSelectionFormFiedls.statusSetupId = (deadlineDate >= currentDate) ? 437 : 440; // Pending or Expired
        } else {
          // No cards exist
          this.newSelectionFormFiedls.statusSetupId = (deadlineDate >= currentDate) ? 433 : 440; // Pending or Expired
        }
      }
    }


    const SubsVendors = this.newSelectionFormFiedls?.createSelectionSubVenderParameterRequests?.map(
      (id: string) => ({ workDayId: id })
    ) || [];

    const formDataToSend = {
      ...this.newSelectionFormFiedls,
      createLeadActivityUserParameterRequests: SubsVendors,
    };

    this.selectionService.PostDataSelectionForm(formDataToSend).subscribe(
      (response) => {

        this.processSelectionResponse(response);
        this.responceHoldingTitle = response.result.Title;

        const selectedCategory = this.categoryOption.find(option => option.id === this.newSelectionFormFiedls.catagorySetupId);
        if (selectedCategory) {
          this.categoryNameSet = selectedCategory.name;
          console.log('Category Name:', this.categoryNameSet);
        }

        const selectedLocation = this.locationOption.find(option => option.id === this.newSelectionFormFiedls.locationSetupId);
        if (selectedLocation) {
          this.locationNameSet = selectedLocation.name;
          console.log('Location Name:', this.locationNameSet);
        }

        this.newSelectionFormFiedls.statusSetupId = response.result?.StatusSetupId || this.newSelectionFormFiedls.statusSetupId;
        this.statusCode = this.getStatusCode(this.newSelectionFormFiedls.statusSetupId);
        console.log('Final Status:', this.statusCode);

        if (this.selectedFilesAttachment?.length > 0) {
          saveAttachments(this.selectedFilesAttachment, this._AttachmentService, response.result.Id, 30)
            .then((attachmentResponseId) => {
              this.toastsService.success('New Selection Saved Successfully!');
              this.onSaveComplete.emit(this.selectionResponses);
              this.selectionResponses = response.result;
              this.resetFlags(); // Reset flags after save
              resolve();

              // Create and update metadata
              if (response) {
                this.createAndModifiedByData(response.result.Id);
              }
            })
            .catch((error) => {
              console.error('Error uploading attachments:', error);
              this.resetFlags();
              reject(error);
            });
        } else {
          this.toastsService.success('New Selection Saved Successfully!');
          this.onSaveComplete.emit(this.selectionResponses);
          this.selectionResponses = response.result;
          this.resetFlags(); // Reset flags after save
          resolve();

          // Create and update metadata
          if (response) {
            this.createAndModifiedByData(response.result.Id);
          }
        }
      },
      (error) => {
        console.error('Error saving data:', error);
        this.resetFlags();
        reject(error);
      }
    );
  });
}


  saveAndRelease(): Promise<void> {
    return new Promise((resolve, reject) => {
      const currentDate = new Date();
      const deadlineDate = new Date(this.newSelectionFormFiedls.dedlineOn);
      const selectedTime = new Date(this.newSelectionFormFiedls.dedlineOn);
      let timeValue = this.newSelectionFormFiedls.time;
      if (timeValue && typeof timeValue === 'string') {
        timeValue = new Date(timeValue);
      }
      const hours = timeValue instanceof Date ? timeValue.getHours() : 0;
      const minutes = timeValue instanceof Date ? timeValue.getMinutes() : 0;
      selectedTime.setHours(hours);
      selectedTime.setMinutes(minutes);
      if (deadlineDate <= currentDate && selectedTime.getTime() <= currentDate.getTime()) {
        if (this.newSelectionFormFiedls.statusSetupId === SelectionStatusCode.PendingAvailable.statusid) {
          this.newSelectionFormFiedls.statusSetupId = SelectionStatusCode.ExpiredAvailable.statusid;
        } else {
          this.newSelectionFormFiedls.statusSetupId = SelectionStatusCode.ExpiredNoChoices.statusid;
        }
      } else {
        this.newSelectionFormFiedls.statusSetupId = SelectionStatusCode.PendingNoChoice.statusid;
      }

      this.statusCode = this.getStatusCode(this.newSelectionFormFiedls.statusSetupId);

      this.saveSelection('saveAndRelease')
        .then(() => {
          this.isReleased = true;
          this.changeDetectorRef.detectChanges();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  processSelectionResponse(response: any) {
    this.selectionReponse = response.result?.Id;
    this.newSelectionFormFiedls.globalId = response.result.GlobalId;
    this.newSelectionFormFiedls.statusSetupId = response.result.StatusName;
    this.messagingId = this.selectionReponse ? this.selectionReponse : this.SelectionInformation2;
    this.messagingIdRow = this.selectionReponse ? this.selectionReponse : this.SelectionInformation2;
    this.JobInfoId = response.result?.JobInformationId;
    this.ChangeOrderInfoId = this.selectionReponse;
  }


  private getStatusCode(status: string) {
    switch (status) {
      case "Released":
        return SelectionStatusCode.UnReleased;
      case "UnReleased":
        return SelectionStatusCode.UnReleased;

      case "PendingNoChoice: No Choices":
        return SelectionStatusCode.PendingNoChoice;
      case "PendingAvailable: No Choices":
        return SelectionStatusCode.PendingAvailable;
      case "ExpiredAvailable: No Choices":
        return SelectionStatusCode.ExpiredAvailable;
      case "ExpiredNoChoices: No Choices":
        return SelectionStatusCode.ExpiredNoChoices;
      default:
        return null; // Handle other statuses as needed
    }
  }



  saveFileIdToDatabase(fileId: string) {
  }

  saveAndCloseSelection(): Promise<void> {
    return this.saveSelection('saveAndClose')
      .then(() => {
        this.resetForm(); // Reset form after save and close
        this.SelectionFromCancel(); // Trigger close functionality
        this.resetFlags(); // Reset flags after success
      })
      .catch((error) => {
        console.error('Error during save and close:', error);
        this.resetFlags(); // Reset flags in case of error
      });
  }







  saveAndNewSelection(): Promise<void> {
    return this.saveSelection('saveAndNew')
      .then(() => {
        this.selectionReponse = undefined; // Clear activity response for a new entry
        this.resetForm(); // Reset form for new entry
        this.resetFlags(); // Reset flags after success
      })
      .catch((error) => {
        console.error('Error during save and new:', error);
      });
  }


  SelectionFromCancel(): void {
    this.selectionModelhandle = false; // Set this to false to close the modal
    this.cancel.emit(); // Emit the cancel event
  }

  resetFlags() {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
    this.issaveAndReleaseve = false; // Added to reset the new flag
    this.isSaveAndUnrelease = false; // Added to reset the new flag
  }

  resetForm(): void {
    this.newSelectionFormFiedls = {
      ...this.newSelectionFormFiedls, // Spread to retain existing fields
      globalId: '00000000-0000-0000-0000-000000000000',
      selectionSetupId: 0,
      title: '',
      isSingle: true,
      isShared: true,
       statusSetupId : 0,
      sharedAllowanceId: 0,
      isRequireOwnerToMakeSelection: false,
      catagorySetupId: 0,
      locationSetupId: 0,
      singleAllowanceNumber: '',
      isChooseDate: true,
      isLinkedToScheduleItem: 0,
      scheduleItemParameterId: 0,
      dueNumberDays: 0,
      isBefore: true,
      isAfter: false,
      dedlineOn: null,
      time: null,
      attachmentId: 0,
      messagingId: 0,
      isApproveChoicesOnly: true,
      isAddEditAndApproveChoices: false,
      publicInstructions: '',
      internalNotes: '',
      isAllowMultipleSelectedChoices: true,
      isAuto: true,
      isManual: true,
      isApprove: true,
      isDecline: true,

      createSelectionSubVenderParameterRequests: [], // Reset this if necessary
      createSelectionInstallerParameterRequests: [], // Reset this if necessary
      // Keep jobInformationId and any other fields intact
      jobInformationId: this.newSelectionFormFiedls.jobInformationId
    };
  }


  initialFormFieldValues: any = {};
  setInitialFormFieldValues(): void {
    this.initialFormFieldValues = JSON.parse(JSON.stringify(this.newSelectionFormFiedls));
  }



  showTags(fieldType: string, pCode: string, code: string, stndId: number): void {
    this.getDropDownMethod();
    this.loadingIndicatoreService.show();
    setTimeout(() => {
      this.loadingIndicatoreService.hide();
      this.isTagVisible = true;
      this.isLoadingEdit = false;
    }, 1000);

    // Update the dynamic title based on the field type
    switch (fieldType) {
      case 'Sources':
        this.ModalTitle = { title: 'Add Lead Source', deleteTitle: 'Lead Source' };
        this.CategoryField.controls['code'].setValue(code);
        this.CategoryField.controls['parentCode'].setValue(pCode);
        this.CategoryField.controls['lookUpStandardId'].setValue(stndId);

        break;
      case 'ProjectType':
        this.ModalTitle = { title: 'Add Project Type', deleteTitle: 'Project Type' };
        this.CategoryField.controls['code'].setValue(code);
        this.CategoryField.controls['parentCode'].setValue(pCode);
        this.CategoryField.controls['lookUpStandardId'].setValue(stndId);

        break;

      default:
        this.ModalTitle = { title: 'Add/Edit Field', deleteTitle: 'Add/Edit Field' };

        break;
    }

  }



  showTagEdit(selectedValue: LookupNameSetupResponse, fieldType: string): void {
    if (selectedValue) {
      const selectedId: number = Number(selectedValue); // Convert the selected ID to number
      this.isTagVisible = true;
      this.isLoadingEdit = true;
      const selectedObjectFromLookup = this.lookupSetNameResponse.find(item => item.id === selectedId);
      if (selectedObjectFromLookup) {
        const selectedData = selectedObjectFromLookup;
        if (selectedData) {
          this.CategoryField.controls['name'].setValue(selectedData.name);
          this.CategoryField.controls['globalId'].setValue(selectedData.globalId);
          this.CategoryField.controls['isFieldValue'].setValue(selectedData.isFieldValue);
          this.CategoryField.controls['companyParameterId'].setValue(selectedData.companyParameterId);
          this.CategoryField.controls['formNameId'].setValue(selectedData.formNameId);
          this.CategoryField.controls['lookUpStandardId'].setValue(selectedData.lookUpStandardId);
          this.CategoryField.controls['code'].setValue(selectedData.code);
          this.CategoryField.controls['description'].setValue(selectedData.description);
          this.CategoryField.controls['parentCode'].setValue(selectedData.parentCode);
        }
      }
    }

    switch (fieldType) {
      case 'Sources':
        this.ModalTitle = { title: 'Edit Lead Source', deleteTitle: 'Lead Source' };
        this.resetSource = true;
        this.resetTags = false;
        this.resetProjectType = false;
        break;
      case 'ProjectType':
        this.ModalTitle = { title: 'Edit Project Type', deleteTitle: 'Project Type' };
        this.resetProjectType = true;
        this.resetSource = false;
        this.resetTags = false;
        break;
      default:
        this.ModalTitle = { title: 'Add/Edit Field', deleteTitle: 'Add/Edit Field' };
        break;
    }
  }




  get exceededCharacters(): number {
    const nameControl = this.CategoryField.get('name');
    if (nameControl && nameControl.errors && nameControl.errors['maxlength']) {
      const currentLength = nameControl.value.length;
      const maxLength = nameControl.errors['maxlength'].requiredLength;
      return currentLength - maxLength;
    }
    return 0;
  }


  limitToThreeDigits(): void {
    let inputValue = this.newSelectionFormFiedls['dueNumberDays'];
    if (inputValue && inputValue.length > 3) {
      this.newSelectionFormFiedls['dueNumberDays'] = inputValue.slice(0, 3);
    }
  }





  Newpacth(selectionResponses: SelectionResponse) {
    this.dataSetForselection = selectionResponses?.JobName;
    if (!selectionResponses) {
      console.error('No selection response data available for patching.');
      return;
    }
    const DataSet = selectionResponses;
    this.newSelectionFormFiedls = {
      ...this.newSelectionFormFiedls,
      title: DataSet?.Title,
      globalId: DataSet?.GlobalId || '00000000-0000-0000-0000-000000000000',
      selectionSetupId: DataSet?.SelectionSetupId,
      jobInformationId: DataSet?.JobInformationId,
      // jobInformationId: DataSet?.JobInformationId || this.newSelectionFormFiedls.get('jobInformationId')?.value,
      isSingle: DataSet?.IsSingle ?? true,
      isShared: DataSet?.IsShared ?? true,
      sharedAllowanceId: DataSet?.SharedAllowanceId,
      isRequireOwnerToMakeSelection: DataSet?.IsRequireOwnerToMakeSelection ?? false,
      locationSetupId: DataSet?.LocationSetupId,
      catagorySetupId: DataSet?.CatagorySetupId,
      singleAllowanceNumber: DataSet?.SingleAllowanceNumber,
      isChooseDate: DataSet?.IsChooseDate ?? true,
      isLinkedToScheduleItem: DataSet?.IsLinkedToScheduleItem,
      scheduleItemParameterId: DataSet?.ScheduleItemParameterId,
      dueNumberDays: DataSet?.DueNumberDays,
      statusSetupId: DataSet.StatusName,

      isBefore: DataSet?.IsBefore ?? true,
      isAfter: DataSet?.IsAfter ?? false,
      dedlineOn: DataSet?.DedlineOn || null,
      time: DataSet?.Time || null,
      attachmentId: DataSet?.AttachmentId,
      messagingId: DataSet?.MessagingId,
      isApproveChoicesOnly: DataSet?.IsApproveChoicesOnly ?? true,
      isAddEditAndApproveChoices: DataSet?.IsAddEditAndApproveChoices ?? false,
      publicInstructions: DataSet?.PublicInstructions,
      internalNotes: DataSet?.InternalNotes,
      isAllowMultipleSelectedChoices: DataSet?.IsAllowMultipleSelectedChoices ?? true,
      isAuto: DataSet?.IsAuto ?? true,
      isManual: DataSet?.IsManual ?? true,
      isApprove: DataSet?.IsApprove ?? true,
      isDecline: DataSet?.IsDecline ?? true,
    };

    this.fetchAttachments(DataSet?.AttachmentId);
    console.log("show all patch time",DataSet.StatusName);

  }


  fetchAttachments(AttachmentId: number) {
    if (AttachmentId) {
      this._AttachmentService.getLeadByAttachmentId(AttachmentId).subscribe(
        (res) => {
          this.TestAttachment = res.result
        },
      );
    } else {
      this.TestAttachment = undefined;
    }
  }




  // private fetchActivityResponse(): void {
  //   this.selectionService.SelectionGetbyId(this.newSelectionId)?.subscribe((res) => {
  //     if (res && res.result) {
  //       console.log("API response:", res);
  //       this.selectionResponses = res.result as SelectionResponse; // Use type assertion if needed
  //       this.Newpacth(this.selectionResponses);
  //       this.fetchAttachments();

  //       this.newSelectionFormFiedls.patchValue(this.newSelectionFormFiedls?.value);
  //     } else {
  //       console.error('Invalid response from API:', res);
  //     }
  //   });
  // }


  private fetchActivityResponse(): void {
    this.selectionService.SelectionGetbyId(this.newSelectionId)?.subscribe((res) => {
      if (res && res.result) {
        console.log("API response:", res);
        this.selectionResponses = res.result as SelectionResponse;

        // Ensure StatusName is available in the response
        if (this.selectionResponses?.StatusName) {
          console.log('Status Name:', this.selectionResponses?.StatusName);
        }

        this.Newpacth(this.selectionResponses);
        this.fetchAttachments(this.selectionResponses.AttachmentId);
        this.newSelectionFormFiedls.patchValue(this.newSelectionFormFiedls?.value);
      } else {
        console.error('Invalid response from API:', res);
      }
    });
  }


  // newdata: SelectionChoiceResult;
  Approvalcomments: string;
  selectedChoice: number;
 selectionData: SelectionChoiceResult[];
 selectionDatas: SelectionChoiceResult[] = [];
  choiceSelectionRes: SelectionChoiceParameterResponse;

  fileDetails: { footImage: string }[] = [];
  firstFilePath: string;
  // fetchActivityResponseChoice(id: number): void {
  //   this.selectionService.getChoiceBy(this.newSelectionId)?.subscribe((res) => {debugger
  //     console.log('response', res.result);
  //     this.newdata = res.result.Id;
  //     console.log('responsenewdata',res.result.Id);


  //     this.selectedChoice = res.result.Id;

  //     if (Array.isArray(res.result)) {
  //       this.selectionData = res.result;
  //     } else if (res.result) {
  //       this.selectionData = [res.result];
  //     } else {
  //       this.selectionData = [];
  //     }
  //     this.selectionData.forEach(item => {
  //       const filePath = item.FilePath;
  //       const parts = filePath.split('-');

  //       const firstFilePath = parts[1];
  //       this.firstFilePath = firstFilePath
  //       const footImage = parts[2];
  //       this.fileDetails.push({ footImage });
  //     });
  //   });
  //   console.log("show all card data for",this.selectedChoice);

  // }


  fetchActivityResponseChoice(id: number): void {
    this.selectionService.getChoiceBy(this.newSelectionId)?.subscribe((res) => {
      console.log('response', res.result);

      if (Array.isArray(res.result) && res.result.length > 0) {
        this.selectedChoice = res.result[0].Id;
        this.Approvalcomments = res.result[0].ApprovalNotes // Get the first item from the array
        console.log('this.selectedChoice', this.selectedChoice);
      } else if (res.result && res.result.Id) {
        this.selectedChoice = res.result.Id;
      }

      if (Array.isArray(res.result)) {
        this.selectionData = res.result;
      } else if (res.result) {
        this.selectionData = [res.result];
      } else {
        this.selectionData = [];
      }

      // Process file details for each item in selectionData
      this.fileDetails = [];
      this.selectionData.forEach(item => {
        const filePath = item.FilePath || '';
        const parts = filePath.split('-');

        const firstFilePath = parts.length > 1 ? parts[1] : '';
        this.firstFilePath = firstFilePath;

        const footImage = parts.length > 2 ? parts[2] : '';
        this.fileDetails.push({ footImage });
      });

      // Log inside the subscription to ensure selectedChoice is available
      console.log("show all card data for", this.selectedChoice);
    });
  }



  FormEdit(Id: number): void {
    const modalRef = this.modal.create({
      nzContent: AddchoiceSelectionChoiceComponent,
      nzFooter: null
    });
    modalRef.componentInstance.newSeletChoiceId = Id;
    modalRef.componentInstance.SelectIdByChoice = this.newSelectionId;
    modalRef.componentInstance.onSaveComplete.emit()
    modalRef.componentInstance.ChoiceSelectionResponse.subscribe(res => {
      if (this.newSelectionId) {
        this.fetchActivityResponseChoice(this.newSelectionId)
      }
    })
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  }




  // CNChoicesradioValue = 'A'; // Initial value set to 'A'

  CNChoicesradioButtons(value: string) {
    this.CNChoicesradioValue = value;
  }

  deleteLeadConfirm(): void {
    if (this.isDeleteLoading) {
      return;
    }
    this.modal.confirm({
      nzTitle: `Delete Selection?`,
      nzContent: 'Are you sure you want to permanently delete this Selection?',
      nzOkText: `Delete Selection `,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteActivity(this.newSelectionFormFiedls.globalId)
          .then(() => {
            this.LeadActivityNewLeadModelCancel();
          })
          .catch(() => {

          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }


  deleteActivity(globalId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!globalId) {
        this.toastsService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }

      this.selectionService.deleteSelectionData(globalId).subscribe(
        (res) => {
          this.isDeleteLoading = false;
          this.toastsService.success(`Deleted User!`);
          this.onSaveComplete.emit(this.selectionResponses);
          this.leadActivityFromCancel();
          resolve();
          reject();
        },
        (error) => {
          this.isDeleteLoading = false;
          this.toastsService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      );
    });
  }

  leadActivityFromCancel(): void {
    this.cancel.emit();
    this.selectionModelhandle = false;
    this.leadOpportunitiesService.clearSelectedFiles();
  }

  LeadActivityNewLeadModelCancel(): void {
    this.cancel.emit();
    this.selectionModelhandle = false;
    this.leadOpportunitiesService.clearSelectedFiles();
  }

  validateNumberLength(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    if (input.value.length >= 14 && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }

  // sufiyan Error Show
  readonly validationRules = {
    title: {
      required: 'Required',
      maxLength: { limit: 50, message: 'Template Name exceeds the limit.' }
    }
  };
  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      title: ' Title',
    };
    return labels[field] || field;
  }
  checkErrors(validateAllFields = false) {
    this.validationStates = {};
    this.validationMessages = {};
    const fieldsToValidate = validateAllFields ? Object.keys(this.validationRules) : Array.from(this.changedFields);
    for (const field of fieldsToValidate) {
      if (this.validationRules.hasOwnProperty(field)) {
        const errorTip = this.getErrorTip(field);
        if (errorTip) {
          this.validationStates[field] = 'error';
          this.validationMessages[field] = errorTip;
        } else {
          this.validationStates[field] = null;
          this.validationMessages[field] = '';
        }
      }
    }
  }
  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }
  getErrorTip(field: string): string {
    const rules = this.validationRules[field];
    const input = this.newSelectionFormFiedls[field] || '';
    if (rules.required && !input.trim()) {
      return `Required.`;
    }
    if (rules.maxLength && input.length > rules.maxLength.limit) {
      const excessLength = input.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }
    return '';
  }
  validateChangedFields(field: string) {
    this.changedFields.add(field); // Add field to the changed fields set
    this.checkErrors(); // Validate changed fields
  }
  onFieldChange(field: string, value: string) {
    this.newSelectionFormFiedls[field] = value;
    this.changedFields.add(field);
    this.checkErrors();
  }
  createAndModifiedByData(id: number) {
    const payload: FilterFooterAudit = {
      id: id,
      formCode: 'PMSCV',
    }
    this.FooterService.createdAndModifyUser(payload).subscribe(res => {
      this.footerResponse = res.result;
    })
  }
  // sufiyan Error Show

  shareSingleradioButtons() {
    switch (this.shareSingleradioValue) {
      case 'A':
        this.showSelector = true
        this.ShowSelector2 = false
        break;
      case 'B':
        this.showSelector = false
        this.ShowSelector2 = true
        break;
    }
  }
  chooseAddLinkradioButtons() {
    switch (this.chooseAddLinkradioValue) {
      case 'A':
        this.showDateFields = true;
        this.showScheduleItemFields = false;
        break;
      case 'B':
        this.showScheduleItemFields = true;
        this.showDateFields = false;
        break;
    }
  }

  OpenAproveStatus(){
    this.Approve = true;

  }
  OpenAproveStatuscancel(){
    this.Approve = false;
    // this.cancel.emit()

  }

  ApproveForm: FormGroup;

  iniliazeFormApproveForm() {
    this.ApproveForm = this.fb.group({
      id: [''],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      ApprovalComments: ['', [Validators.required, Validators.maxLength(50)]],

    });

  }
  selectionInformationStatus: string = '';
  selectionChoiceParameterStatus: string = '';


  ApproveStatus() {
    const selectionData: SelectionInformationApprove = {
      selectionInformationId: this.selectionResponses?.Id,
      // selectionChoiceParameterId: this.cards[0]?.id,  // card data pick id of card then assgin this this data
      selectionChoiceParameterId: this.selectedChoice,  // user cmoing respose then asssgin this repsose in api
      approvalNotes: this.ApproveForm.value.ApprovalComments,
      statusId: 441
    };
    this.selectionService.PostApprove(selectionData).subscribe({
      next: (response) => {
        this.toastService.success('Approval successful');
        console.log('Approval successful:', response);
        this.selectionInformationStatus = response.result.selectionInformationStatus;
        this.selectionChoiceParameterStatus = response.result.selectionChoiceParameterStatus;

      },
      error: (error) => {
        console.error('Approval failed:', error);
      }
    });
  }


  TestAttachment: AttachmentResponse;
  onFilesUploaded(files: CustomUploadFile[]): void {
    this.selectedFilesAttachment = files
  }
  selectedFilesAttachment: CustomUploadFile[] = [];
}

interface ModalTitle {
  title?: string;
  deleteTitle?: string; // Optional property for cases where deleteTitle is not needed
}
interface CardChoice {
  id: number;
  title?: string;
  description?: string;
  ownerPrice?: string;
  attachmentId?: AttachmentResponse[]
  attachmentParameters?: AttachmentParameterResponse[];
}
