<nz-modal
nzWidth="35%"
[(nzVisible)]="customFieldFormVisible"
[nzTitle]="modalTitle1"
[nzFooter]="modalFooter1"
(nzOnCancel)="custoFormFieldCancel()"
[nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '77vh', 'overflow-y': 'auto',}"
[nzStyle]="{ top: '37px' }">
  <ng-template #modalTitle1>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0 famly-inn fs-3 fw-bold">
          Jobs (Job Info) Custom Field 
      </h2>
    </div>
  </ng-template>

  <div class="content" *nzModalContent>

    <nz-card nzBorderless="true" [nzTitle]=" CustomFieldInfo">
        <ng-template #CustomFieldInfo>
            <h4 class="fw-medium famly-inn">Custom Field Info</h4>
        </ng-template>
        <div class="row">
            <div class="col-12">
                    <label class="sub-text1 famly-inn" for="displayName">Label <span class="text-danger">*</span></label>
                    <input nz-input type="text" class="form-control formField">
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label class="sub-text1 famly-inn" for="displayName">Data Type <span class="text-danger">*</span></label>
                <nz-select [(ngModel)]="selectedValue" (ngModelChange)="onDropdownChange($event)" style="width: 100%;">
                    <nz-option nzValue="0" nzLabel="--Please Select--"></nz-option>
                    <nz-option nzValue="1" nzLabel="Single-Line Text"></nz-option>
                    <nz-option nzValue="2" nzLabel="Multi-Line Text with Expandable Textbox"></nz-option>
                    <nz-option nzValue="3" nzLabel="CheckBox"></nz-option>
                    <nz-option nzValue="4" nzLabel="Whole Number"></nz-option>
                    <nz-option nzValue="5" nzLabel="List of Users - Single Select"></nz-option>
                    <nz-option nzValue="6" nzLabel="List of Subs/Vendors - Single Select"></nz-option>
                    <nz-option nzValue="7" nzLabel="Date"></nz-option>
                    <nz-option nzValue="8" nzLabel="Currency"></nz-option>
                    <nz-option nzValue="9" nzLabel="File"></nz-option>
                    <nz-option nzValue="10" nzLabel="Dropdown"></nz-option>
                    <nz-option nzValue="11" nzLabel="Multi-Select Dropdown"></nz-option>
                    <nz-option nzValue="12" nzLabel="Link"></nz-option>
                    <nz-option nzValue="13" nzLabel="List of Users - Multi Select"></nz-option>
                    <nz-option nzValue="14" nzLabel="List of Subs/Vendors - Multi Select"></nz-option>
                  </nz-select>                  
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                <label class="sub-text1 famly-inn" for="displayName">Tool Tip Text</label>
                <textarea rows="2" nz-input ></textarea>                   
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12">
                <label nz-checkbox class="famly-inn fs-6" >Required?</label>                  
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12">
                <label nz-checkbox class="famly-inn fs-6">Include In My Filters</label>                  
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-12">
                <label class="sub-text mb-1" for="displayName">Display Order <span class="text-danger">*</span></label>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-12">
                <nz-input-number  [nzMin]="1" [nzMax]="10" [nzStep]="1"></nz-input-number>
            </div>
        </div>
        <!-- <nz-divider></nz-divider> -->
        <div class="row mt-3">
            <div class="col-12">
                <label nz-checkbox class="famly-inn fs-6">Allow permitted sub/vendor to view?</label>                  
            </div>
        </div>
      </nz-card>
      <nz-card *ngIf="visibleCards[1]" class="mt-3" nzTitle="Single-Line Text">
        <div class="row">
                    <div class="col-12">
                        <label class="sub-text mb-1" for="displayName">Default Value</label>
                        <i class="bi bi-info-circle-fill text-secondary ms-2" nz-popover [nzPopoverContent]="contentTemplate" nzPopoverPlacement="left"></i>
                        <ng-template #contentTemplate>
                        <div class="container-fuild" style="height: auto; width: 200px;">
                            With a fixed width, Single-Line Text is perfect for shorter data entry.
                        </div>
                        </ng-template>
                        <input nz-input type="text" class="form-control formField">
                    </div>
                </div>
        </nz-card>
        <nz-card *ngIf="visibleCards[2]" class="mt-3" nzTitle="Multi-Line Text with Expandable Textbox">
            <div class="row">
                       <div class="col-12">
                        <label class="sub-text mb-1" for="displayName">Default Value</label> 
                        <textarea class="rounded-1" rows="2" nz-input></textarea>
                       </div>
                   </div>
        </nz-card>
        <nz-card *ngIf="visibleCards[3]" class="mt-3" nzTitle="CheckBox">
            <div class="row">
                       <div class="col-12">
                        <label nz-checkbox>Checked by default</label>  
                       </div>
                   </div>
        </nz-card>
        <nz-card *ngIf="visibleCards[4]" class="mt-3" nzTitle="Whole Number">
 
            <div class="row mb-4">
                <div class="col-12">
                    <label class="sub-text mb-1" for="displayName">Default Value</label>
                    <nz-input-number  [nzMin]="1" [nzMax]="10" [nzStep]="1"></nz-input-number>
                </div>
            </div>
        </nz-card>
        <nz-card *ngIf="visibleCards[5]" class="mt-3" nzTitle="List of Users - Single Select">
            <div class="row mb-4">
                <div class="col-12">
                    <label class="sub-text mb-1" for="displayName">Default Value</label>
                    <nz-select nzShowSearch [lang]="true" nzAutoFocus="true" nzPlaceHolder="--None--" required>
                        <nz-option  ></nz-option>
                      </nz-select>
                </div>
            </div>
        </nz-card>
        <nz-card *ngIf="visibleCards[6]" class="mt-3" nzTitle="List of Subs/Vendors - Single Select">
            <div class="row mb-4">
                <div class="col-12">
                    <label class="sub-text mb-1" for="displayName">Default Value</label>
                    <nz-select nzShowSearch [lang]="true" nzAutoFocus="true" nzPlaceHolder="--None--" required>
                        <nz-option  ></nz-option>
                      </nz-select>
                </div>
            </div>
        </nz-card>
        
        <nz-card *ngIf="visibleCards[7]" class="mt-3" nzTitle="Date">
            <div class="row mb-4">
                <label class="sub-text mb-1" for="displayName">Default Value</label>
                <div class="col-12">
                    <nz-date-picker  nzFormat="MMM d, yyyy" nzPlaceHolder=" " nzSuffixIcon="false" required></nz-date-picker>
                </div>
            </div>
        </nz-card>
        <nz-card *ngIf="visibleCards[8]" class="mt-3" nzTitle="Currency">
            <div class="row mb-4">
                <div class="col-12">
                    <label class="sub-text mb-1" for="displayName">Default Value</label>
                    <div class="input-group">
                        <nz-input-group nzAddOnBefore="$">
                          <input
                            type="text"
                            nz-input
                            currencyMask
                            [options]="{
                              align: 'left',
                              prefix: '',
                              thousands: ',',
                              decimal: '.',
                              precision: 2
                            }"
                            class="form-control formField"
                          />
                        </nz-input-group>
                      </div>
                </div>
            </div>
        </nz-card>
        <nz-card *ngIf="visibleCards[9]" class="mt-3" nzTitle="File">
            <div class="row mb-4">
                <div class="col-12">
                    <label class="sub-text mb-1" for="displayName">Allowable Files</label>
                    <nz-tree-select [nzVirtualHeight]="'250px'"  style="width: 100%" [nzNodes]="attendeesNodes" nzShowSearch nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Status"></nz-tree-select>
                </div>
            </div>
        </nz-card>
        <nz-card *ngIf="visibleCards[10]" class="mt-3" nzTitle="Dropdown List">
            <div class="row mb-4">
                <div class="col-12">
                    <label class="sub-text mb-1" for="displayName">Default Value</label>
                    <nz-select nzShowSearch [lang]="true" nzAutoFocus="true" nzPlaceHolder="--None--" required>
                        <nz-option  ></nz-option>
                      </nz-select>
                </div>
            </div>
            <div class="row mb-4">
                <label class="sub-text mb-1" for="displayName">Default Value</label>
                <div class="col-10">
                    <input nz-input type="text" class="form-control formField">
                </div>
                <div class="col-2">
                    <button nz-button nzType="primary" >Add</button>
                </div>
            </div>
        </nz-card>
        <nz-card *ngIf="visibleCards[11]" class="mt-3" nzTitle="Multi-Select Dropdown">
            <div class="row mb-4">
                <label class="sub-text mb-1" for="displayName">Default Value</label>
                <div class="col-10">
                    <input nz-input type="text" class="form-control formField">
                </div>
                <div class="col-2">
                    <button nz-button nzType="primary" >Add</button>
                </div>
            </div>
        </nz-card>
        <nz-card *ngIf="visibleCards[12]" class="mt-3" nzTitle="Link">
            <div class="row mb-4">
                <label class="sub-text mb-1" for="displayName">Default Link</label>
                <div class="col-12">
                    <input nz-input type="text" class="form-control formField">
                </div>
            </div>
        </nz-card>
    
</div>
  <ng-template #modalFooter1>
    <button nz-button nzType="default" (click)="showConfirmJobDelete()">Delete</button>
    <button nz-button nzType="primary">Save</button>
    </ng-template>
</nz-modal>