
  <nz-content class="custom-content mt-0">
      <div class="row mt-5" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
        <div class="col" style="text-align: center;">

          <!-- <i class="bi bi-receipt-cutoff" style="font-size: 60px;"></i> -->
          <img src="assets/Empty-SVGs/Financial/Bills/bills.svg" style="font-size: 70px;">


            <div class="row">
                <div class="col mt-2">
                  <h3>Never Lose a Payment With Bills  </h3>
                  
                </div>
            </div>
            <div class="col mt-3">
            <p>Track expenses on the go and apply them againts Purchase Order to <br>compare estimated versus actual costs.</p>
      
      
            </div>
        </div>
        <div class="col mt-4" style="display: flex; justify-content: center; align-items: center;">
            <button nz-button nzType="default" style="margin-right: 10px;"><i class="bi bi-box-arrow-up-right me-2"></i>Learn How</button>
            <button nz-button nzType="primary" style="margin-right: 10px;" (click)="newBillsOpen()">Add a Bill</button>
        </div>
      </div>
    </nz-content>
