<nz-layout>
  <nz-sider
    class="border"
    nzCollapsible
    [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed"
    [nzWidth]="280"
    [nzTrigger]="null">
    <div class="row">
      <app-side-menu-bar
        (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"></app-side-menu-bar>
    </div>
  </nz-sider>

  <nz-layout>
    <nz-content>
      <div class="inner-content">
        <nz-page-header>
          <nz-page-header-title>
            <nz-breadcrumb>
              <nz-breadcrumb-item>{{
                accessLocalStorageService.getJobName()
                }}</nz-breadcrumb-item>
            </nz-breadcrumb>
            <h1>Schedule</h1>
          </nz-page-header-title>
        </nz-page-header>

        <nz-content class="custom-content">
          <div class="container-fuild mb-4 m-3">
            <nz-tabset nzType="card">
              <ng-container *ngFor="let tab of dashboardMainTabs">
                <nz-tab
                *ngIf="tab.show"
                [nzTitle]="tab.title">
                <ng-container *ngIf="tab.id === 1">
                  <!-- inside tabs Start -->

                  <nz-tabset
                    nzType="card"
                    [nzTabBarExtraContent]="extraTemplate">
                    <ng-container *ngFor="let tab of tabs">
                      <nz-tab
                        *ngIf="tab.show"
                        [nzTitle]="tab.title">
                        <ng-container *ngIf="tab.id === 1">
                          <app-schedule-list-empty></app-schedule-list-empty>
                        </ng-container>

                        <ng-container *ngIf="tab.id === 2">
                          <div
                            *ngIf="scheduleListViewData?.length === 0 || null">
                            <app-schedule-list-empty></app-schedule-list-empty>
                          </div>

                          <div
                            *ngIf="scheduleListViewData?.length > 0">
                            <nz-table
                              #nzTable
                              [nzData]="scheduleListViewData"
                              [nzSize]="'small'"
                              nzTableLayout="fixed"
                              [nzFrontPagination]="false"
                              [nzShowPagination]="false"
                              #headerTable
                              [nzScroll]="{ x: '1100px', y: '300px' }">
                              <thead>
                                <tr>
                                  <th nzWidth="50px">
                                    <label
                                    nz-checkbox
                                    [(ngModel)]="allChecked2"
                                    [nzIndeterminate]="indeterminate"
                                    (ngModelChange)="onAllChecked($event)">
                                    </label>
                                  </th>
                                  <th nzWidth="60px"></th>
                                  <th *ngFor="let column of listOfColumns"
                                    [nzSortOrder]="column.sortOrder"
                                    [nzSortFn]="column.sortFn"
                                    [nzSortDirections]="column.sortDirections"
                                    [nzWidth]="
                                      column.name === 'Pending' || column.name === 'Declined' ||
                                      column.name === 'Comments' || column.name === 'RFIs' ? '100px' :
                                      column.name === 'Title' || column.name === 'Assignees' ||
                                      column.name === 'Phase' ? '200px' : '150px'">
                                    {{ column.name }}
                                    <i *ngIf="column.icon" nz-icon
                                      [nzType]="column.icon"
                                      class="filter-icon"></i>
                                  </th>
                                </tr>
                              </thead>

                              <tbody *ngFor="let data of scheduleListViewData">
                                <tr>
                                  <td nzWidth="50px">
                                    <label nz-checkbox
                                    nz-checkbox [(ngModel)]="data.checked" (ngModelChange)="onItemChecked()">
                                    </label>
                                  </td>
                                  <td nzWidth="60px">
                                    <ng-container
                                      *ngIf="data?.AllNotes || data?.OwnerNotes || data?.InternalNotes || data?.SubNotes; else noSubject">
                                      <td
                                        nzWidth="60px"
                                        [nzExpand]="expandSet.has(data.Id)"
                                        (nzExpandChange)="onExpandChange(data.Id, $event)">
                                      </td>
                                    </ng-container>

                                    <ng-template #noSubject>
                                      <td nzWidth="60px"></td>
                                    </ng-template>
                                  </td>
                                  <td nzEllipsis>{{ data.PhaseSetupName }}</td>
                                  <td nzEllipsis
                                    (click)="newScheduleEdit(data.Id)"
                                    style="display: flex; align-items: center;">
                                    <div class="color-circle me-2"
                                      [style.backgroundColor]="data?.ColorCollection"></div>
                                    <a>{{ data.Title }}</a>
                                  </td>
                                  <td>
                                    <label nz-checkbox
                                      class="custom-checkbox d-flex align-items-center"
                                      [(ngModel)]="data.isComplete"
                                      [ngModel]="data.ProgressPercentage === 100"
                                      (ngModelChange)="circleCheckBox(data.GlobalId, $event)">
                                      <span class="mx-2">{{
                                        data.ProgressPercentage }}%</span>
                                    </label>
                                  </td>
                                  <td nzWidth="250px">{{ data.WorkDays }} {{
                                    data.WorkDays === 1 ? 'day' : 'days' }}</td>
                                  <td nzEllipsis nzWidth="250px">{{ data.StartOn
                                    | date: 'MMM d, yyyy' }}</td>
                                  <td nzEllipsis nzWidth="250px">{{ data.EndOn |
                                    date: 'MMM d, yyyy' }}</td>
                                  <td nzEllipsis nzWidth="220px">{{
                                    data.AssigneeNames }}</td>
                                  <td nzWidth="50px">{{ data.Accepted }}</td>
                                  <td nzWidth="50px">{{ data.Pending }}</td>
                                  <td nzWidth="50px">{{ data.Declined }}</td>
                                  <td class="text-primary cursorTab"
                                    nzWidth="50px">{{ data.TotalMessage }}</td>
                                  <td class="text-primary cursorTab"
                                    nzWidth="50px" (click)="editRfi()">{{
                                    data.TotalRfi }}</td>
                                  <td nzWidth="50px">
                                    <span class="bi bi-eye-fill"></span>
                                  </td>
                                </tr>

                                <!-- Expanded content for notes -->
                                <tr *ngIf="expandSet.has(data.Id)">
                                  <td [attr.colspan]="14">
                                    <div *ngIf="data.AllNotes"><strong>All
                                        Notes:</strong><p>{{ data.AllNotes
                                        }}</p></div>
                                    <div
                                      *ngIf="data.InternalNotes"><strong>Internal
                                        Notes:</strong><p>{{ data.InternalNotes
                                        }}</p></div>
                                    <div *ngIf="data.SubNotes"><strong>Sub
                                        Notes:</strong><p>{{ data.SubNotes
                                        }}</p></div>
                                    <div *ngIf="data.OwnerNotes"><strong>Owner
                                        Notes:</strong><p>{{ data.OwnerNotes
                                        }}</p></div>
                                  </td>
                                </tr>
                              </tbody>
                            </nz-table>

                          </div>

                        </ng-container>

                        <ng-container *ngIf="tab.id === 3">
                          <app-schedule-lgantt-empty></app-schedule-lgantt-empty>
                        </ng-container>
                      </nz-tab>
                    </ng-container>
                  </nz-tabset>

                  <!-- inside tabs End -->
                  <ng-template #extraTemplate>
                    <div class="ng-container">
                      <nz-page-header class="p-0">
                        <nz-page-header-title class="p-0">
                          <!-- <nz-button-group class="p-0 m-0">
                  <button nz-button nzType="default">Calander</button>
                  <button nz-button nzType="default">List</button>
                  <button nz-button nzType="default">Gantt</button>
                </nz-button-group> -->
                        </nz-page-header-title>
                        <nz-page-header-extra>
                          <nz-space>
                            <button
                              nz-button
                              nzType="default"
                              nz-popover
                              [nzPopoverContent]="contentTemplate"
                              nzPopoverPlacement="left"
                              class="me-1 ">
                              <h5>
                                <!-- <i class="bi bi-gear-fill fs-6"></i> -->
                                <!-- <span nz-icon nzType="setting" nzTheme="fill" class="fs-5 d-flex align-items-center mt-1"></span>
                                  -->
                                  <i   nz-button
                                  nzType="default" class=" rounded-1"><span nz-icon nzType="setting" nzTheme="outline" class="fs-5"></span></i>
                                <ng-template #contentTemplate>
                                  <p style="font-size: 13px">
                                    Schedule Setting
                                  </p>
                                </ng-template>
                              </h5>
                            </button>
                            <div
                              class="ant-divider ant-divider-vertical button-vertical-divider"
                              role="separator"></div>
                            <nz-switch
                              [(ngModel)]="switchValue1"></nz-switch>
                            <span class="ms-1 me-2 spanset">Phases</span>
                            <nz-switch
                              [(ngModel)]="switchValue2"></nz-switch>
                            <span class="ms-1 spanset">Schedule
                              Online</span>
                            <button
                              nz-button
                              nzType="default"
                              class="ms-2 ">
                              <i
                                class="bi bi-mortarboard-fill"></i>&nbsp;&nbsp;Learn
                              More
                            </button>
                            <button
                              nzType="default"
                              nz-button
                              nz-dropdown
                              [nzTrigger]="'click'"
                              [nzDropdownMenu]="menu4"
                              class="ms-2 ">
                              More Action<span nz-icon
                                nzType="down"></span>
                            </button>
                            <nz-dropdown-menu #menu4="nzDropdownMenu">
                              <ul nz-menu>
                                <li nz-menu-item>Import Schedule</li>
                                <li nz-menu-item>Track Conflicts</li>
                                <li nz-menu-item>Notify Assigned
                                  Users</li>
                                <li nz-menu-item>Delete All Items</li>
                                <li nz-menu-item>Export to Excel</li>
                                <li nz-menu-item>SomeThingExpot to
                                  PDF</li>
                              </ul>
                            </nz-dropdown-menu>
                            <button
                              nz-button
                              nzType="default"
                              (click)="
                                openFilter(PROJECT_MANG_SCHEDULE,PAGE_ID)
                              "
                              class="ms-2 ">
                              <i
                                class="bi bi-funnel"></i>&nbsp;&nbsp;Filter
                            </button>
                            <button
                              nz-button
                              nzType="primary"
                              class="ms-2 "
                              (click)="newScheduleModalOpen('Schedule')">
                              New Schedule Item
                            </button>
                          </nz-space>
                        </nz-page-header-extra>
                      </nz-page-header>
                    </div>
                  </ng-template>
                </ng-container>

                <!-- main tabs    -->

                <ng-container *ngIf="tab.id === 2">
                  <app-baseline></app-baseline>
                </ng-container>
                <ng-container *ngIf="tab.id === 3">
                  <workday-execptions></workday-execptions>
                </ng-container>
              </nz-tab>
              </ng-container>
            </nz-tabset>
          </div>

          <!-- ////////////////////////////// -->
        </nz-content>

        <!-- ///// content ///// -->

        <nz-footer class="custom-footer"
          *ngIf="scheduleListViewData?.length > 0">
            <div class="row mb-0 mt-2 p-2">
              <div class="col-12 me-2">
                <!-- sufyan Work -->
                <nz-pagination
                  class="hide-buttons"
                  style="float: right;
                  margin-right: 63px;"
                  [nzPageIndex]="currentPage"
                  [nzTotal]="totalRecords"
                  [nzPageSize]="pageSize"
                  [nzShowSizeChanger]="true"
                  [nzPageSizeOptions]="pageSizeOptions"
                  (nzPageIndexChange)="onPageChangeSchedule($event)"
                  (nzPageSizeChange)="onPageSizeChangeSchedule($event)"
                  [nzShowTotal]="totalTemplate"
                  [nzSimple]="false"></nz-pagination>
                <ng-template #totalTemplate let-total="total"
                  let-range="range">
                  {{ range[0] }}-{{ range[1] }} of {{ total }} items
                </ng-template>
                <nz-pagination
                  *ngIf="!isAllDataShownSchedule()"
                  style="float: right"
                  [nzPageIndex]="currentPage"
                  [nzTotal]="totalRecords"
                  [nzPageSize]="pageSize"
                  [nzShowSizeChanger]="true"
                  [nzPageSizeOptions]="pageSizeOptions"
                  (nzPageIndexChange)="onPageChangeSchedule($event)"
                  (nzPageSizeChange)="onPageSizeChangeSchedule($event)"
                  nzSimple></nz-pagination>

              </div>
            </div>
        </nz-footer>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
