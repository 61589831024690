import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RoleTypeService } from 'src/Service/Internaluser/role-type.service';
import { FieldDataType } from 'src/app/Core/Enums/data_types';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { AddNewStandardFilterComponent } from '../../Modal/add-new-standard-filter/add-new-standard-filter.component';
import { CreateLookupFilterRequest, LookupFilterResponse, LookUpStandardResponse, LookupFilter } from '../../Models/LookupModels';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { TenderPriDefinedCodes } from '../../Models/LookUpStandardFilterCodes';
import { LookupNameSetupResponse } from 'src/Models/All-Filters/financialBillsFilterField';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { InterUserService } from 'src/Service/inter-user.service';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';


@Component({
  selector: 'app-financial-tenders-filter-field',
  templateUrl: './financial-tenders-filter-field.component.html',
  styleUrls: ['./financial-tenders-filter-field.component.css']
})
export class FinancialTendersFilterFieldComponent implements OnInit {
  selectedValue: any;
  listOfOption: Array<{ label: string; value: string }> = [];
  listOfTagOptions = [];
  @Input() pageId: number;
  financialtendersFilterForm: FormGroup;

  createLookupFilterRequests: CreateLookupFilterRequest[] = [];
  filterResponseData: LookupFilterResponse[] = [];
  lookUpStandardResponse: LookUpStandardResponse[] = [];
  defaultStandardFilter: LookupFilterResponse;
  predefinedValuesMultiSelect: string[] = [];




  preDefinedDataCodes = {
    standardFilter: TenderPriDefinedCodes.standardFilter,
    BidPackageStatus : TenderPriDefinedCodes.BidPackageStatus,
    BidStatus : TenderPriDefinedCodes.BidStatus,
    DeadlineDate : TenderPriDefinedCodes.DeadlineDate,
    BudgetedStatus : TenderPriDefinedCodes.BudgetedStatus,
    HasRelatedPO : TenderPriDefinedCodes.HasRelatedPO,

  };

  constructor(
    private roleService: RoleTypeService,
    private fb: FormBuilder,
    public toastService: NzMessageService,
    private SubVendorService:SubVendorService ,
    private cdr: ChangeDetectorRef,
    private loadingIndicatoreService: LoadingIndicatorService,
    private modal: NzModalService,
    private LookupFilterService: LookupFilterService,
    private internalUserService: InterUserService,

  ) { }
  ngOnInit(): void {
    this.initLoad();
    this.showContactTypes();
    this.ShowAddDate();
    this.ForUbvendor()
  }
  initLoad() {
    const lookupFilter: LookupFilter = {
      formNameId: this.pageId,
    };
    this.getStandardFilterByFormPageId(this.pageId);
    this.fetchData(lookupFilter);
  }
  fetchData(lookUpStandard: LookupFilter): void {
    this.LookupFilterService.postData(lookUpStandard).subscribe(
      data => {
        this.lookUpStandardResponse = data.result;
      },
      error => {
        console.error('Error fetching data:', error);
      }
    );
  }
  nodes: NzTreeNodeOptions[] = [
    {
      title: 'Select  all',
      value: '0-all',
      key: '0-all',
      isLeaf: true,
      checked: false
    },
    {
      title: 'Node1',
      value: '0-0',
      key: '0-0',
      isLeaf: true
    },
    {
      title: 'Node2',
      value: '0-1',
      key: '0-1',
      isLeaf: true
    },
    {
      title: 'Node3',
      value: '0-2',
      key: '0-2',
      isLeaf: true
    },
    {
      title: 'Node4',
      value: '0-3',
      key: '0-3',
      isLeaf: true
    },
    {
      title: 'Node5',
      value: '0-4',
      key: '0-4',
      isLeaf: true
    },
    {
      title: 'Node6',
      value: '0-5',
      key: '0-5',
      isLeaf: true
    },];

  openStandardFilterComponent() {
    this.createFormFieldsInArray();
    const modalRef = this.modal.create({
      nzContent: AddNewStandardFilterComponent,
      nzData:
      {
        pageId: this.pageId,
        std : this.filterResponseData ,
        field: this.createLookupFilterRequests
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  getStandardFilterByFormPageId(pageId: number) {
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.LookupFilterService.$getStandardFilter.subscribe(result =>{
      if(result){
        this.filterResponseData = result.result;
        console.log("RESULT STANDARD FILTER : ", this.filterResponseData);
        this.initializeForm();
        this.setDefaultFieldValues(this.defaultStandardFilter.id);
      }
    });
  }
  initializeForm(): void {
    this.financialtendersFilterForm = this.fb.group({
      standardFilter: new FormControl(this.getDefaultFilterValue()),
      search: [null],
      bidPackageole: [[null]],
      bidStatus: [[null]],
      subsvendors: [[null]],
      deadlineDate: [null],
      budgetedStatus: [[null]],
      hasRelated: [[null]],
    });
  }
  onSubmit(): void {
    this.createFormFieldsInArray();
  }
  resetFilter(): void {
    const formControlKeys = Object.keys(this.financialtendersFilterForm.controls).slice(1);
    formControlKeys.forEach(field => {
      this.financialtendersFilterForm.get(field).reset();
    });
  }
  createFormFieldsInArray() {
    this.createLookupFilterRequests = [];
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const value = this.financialtendersFilterForm.get(field).value;
      if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 1 && value[0] === null)) {
        const newRow = {
          companyParameterId: 0,
          lookUpStandardId: 0,
          code: code,
          meaning: '',
          valueId: 0,
          formNameId: this.pageId,
          dataTypeId: 0,
          createLookupFilterParameterRequests: [null]
        };
        const correspondingResponse = this.lookUpStandardResponse.find(response => response.code === code);
        if (correspondingResponse) {
          newRow.companyParameterId = correspondingResponse.companyParameterId;
          newRow.lookUpStandardId = correspondingResponse.id;
          newRow.dataTypeId = correspondingResponse.dataTypeId;
          if (
            correspondingResponse.dataType.code === FieldDataType.SingleLineText ||
            correspondingResponse.dataType.name === 'Single-Line Text') {
            newRow.meaning = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.Dropdown ||
            correspondingResponse.dataType.name === 'Dropdown') {
            newRow.valueId = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.MultiSelectDropdown ||
            correspondingResponse.dataType.name === 'Multi-Select Dropdown'
          ) {
            newRow.createLookupFilterParameterRequests = value.map((item, index) => {
              return { valueId: item };
            });
          }
          // Assign other properties similarly based on your data structure
        }
        this.createLookupFilterRequests.push(newRow);
      }
    });
  }
  getDefaultFilterValue() {
    this.defaultStandardFilter = this.filterResponseData.find(filter => filter.isSetAsDefault === true);
    return this.defaultStandardFilter ? this.defaultStandardFilter.id : null;
  }
  setDefaultFieldValues(standardFilterId: number): void {
    this.predefinedValuesMultiSelect = [];
    let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
    let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;
    // Get all form control keys except the first one
    const formControlKeys = Object.keys(this.financialtendersFilterForm.controls).slice(1);
    // Reset all form controls except the first one
    formControlKeys.forEach(field => {
      this.financialtendersFilterForm.get(field).reset(); // Reset the form control
    });
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const fieldData = lookUpFiltersField.find(filter => filter.code === code);
      if (fieldData != undefined) {
        if (
          fieldData.dataType.code === FieldDataType.SingleLineText) {
          this.financialtendersFilterForm.get(field).setValue(fieldData.meaning);
        }
        if (fieldData.dataType.code === FieldDataType.Dropdown) {
          this.financialtendersFilterForm.get(field).setValue(fieldData.id);
        }
        if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown) {
          let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
          this.predefinedValuesMultiSelect = multiSelectValues.map(String);
          this.financialtendersFilterForm.get(field).setValue(this.predefinedValuesMultiSelect);
        }
      }
    });
  }
  onChangeStandardFilter($event: number): void {
    this.setDefaultFieldValues($event);
  }



  BidPackageStatusNodes : NzTreeNodeOptions[]=[];
  BidStatusNodes : NzTreeNodeOptions[]=[];
  SubvendorAssginTo: NzTreeNodeOptions[] = [];
  BudgetedStatusNodes : NzTreeNodeOptions[]=[];
  HasRelatedPONodes : NzTreeNodeOptions[]=[];
    roles: { id: string, name: string }[] = [];

    showContactTypes() {
      const formNameId = 16;
      this.internalUserService.showCode(formNameId).subscribe(
        (res: ResponseModelArray<LookupNameSetupResponse>) => {
          const contactTypeData = res.result.filter(item => item.code === TenderPriDefinedCodes.BidPackageStatus.code);
          const BidStatus = res.result.filter(item => item.code === TenderPriDefinedCodes.BidStatus.code);
          const BidPackageStatus = res.result.filter(item => item.code === TenderPriDefinedCodes.BidPackageStatus.code);
          const budgetData = res.result.filter(item => item.code === TenderPriDefinedCodes.BudgetedStatus.code);
          const hasRelatedData = res.result.filter(item => item.code === TenderPriDefinedCodes.HasRelatedPO.code);

      // Bid Package Status
          this.BidPackageStatusNodes = [
            {
              title: 'Select  all',
              value: 'select_all',
              key: 'select_all',
              selectable: false,
              isLeaf: false,
              expanded: true,
              children: BidPackageStatus.map((Packagestatus) => ({
                title: Packagestatus.name,
                value: Packagestatus.id.toString(),
                key: Packagestatus.id.toString(),
                isLeaf: true
              }))
            }
          ];

          // Bid Status
          this.BidStatusNodes = [
            {
              title: 'Select  all',
              value: 'select_all',
              key: 'select_all',
              selectable: false,
              isLeaf: false,
              expanded: true,
              children: BidStatus.map((bidstatus) => ({
                title: bidstatus.name,
                value: bidstatus.id.toString(),
                key: bidstatus.id.toString(),
                isLeaf: true
              }))
            }
          ];
          // BudgetedStatus
          this.BudgetedStatusNodes = [
            {
              title: 'Select  all',
              value: 'select_all',
              key: 'select_all',
              selectable: false,
              isLeaf: false,
              expanded: true,
              children: budgetData.map((budget) => ({
                title: budget.name,
                value: budget.id.toString(),
                key: budget.id.toString(),
                isLeaf: true
              }))
            }
          ];

  //  Has Related PO -----------------
          this.HasRelatedPONodes = [
            {
              title: 'Select  all',
              value: 'select_all',
              key: 'select_all',
              selectable: false,
              isLeaf: false,
              expanded: true,
              children: hasRelatedData.map((HasRelatedPO) => ({
                title: HasRelatedPO.name,
                value: HasRelatedPO.id.toString(),
                key: HasRelatedPO.id.toString(),
                isLeaf: true
              }))
            }
          ];


        },

        (error) => {
          console.error('Error fetching contact types:', error);
        }
      );
  }

  ForUbvendor() {
    this.SubVendorService.getData().subscribe(res => {
      const SubvendorAssgin = res.result;
      this.SubvendorAssginTo = [
        {
         title: 'Select  all',
         value: 'select_all',
         key: 'select_all',
         selectable: false,
         isLeaf: false,
         expanded: true,
         children: SubvendorAssgin.map((LOP) => ({
          title: LOP.companyName,
          value: LOP.id.toString(),
          key: LOP.id.toString(),
          isLeaf: true
         }))
        }
       ];
    });

  }



  OnbidStatusChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.BidPackageStatusNodes[0].children.map(child => child['value']);
      this.financialtendersFilterForm['bidPackageole'] = [...allValues];
    } else {
      const allValues = this.BidPackageStatusNodes[0].children.map(child => child['value']);
      this.financialtendersFilterForm['bidPackageole'] = selectedValues.filter(value => allValues.includes(value));
    }
  }
  OnbidStatusNotesChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.BidStatusNodes[0].children.map(child => child['value']);
      this.financialtendersFilterForm['bidStatus'] = [...allValues];
    } else {
      const allValues = this.BidStatusNodes[0].children.map(child => child['value']);
      this.financialtendersFilterForm['bidStatus'] = selectedValues.filter(value => allValues.includes(value));
    }
  }
  OnbudgeStatusChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.BudgetedStatusNodes[0].children.map(child => child['value']);
      this.financialtendersFilterForm['budgetedStatus'] = [...allValues];
    } else {
      const allValues = this.BudgetedStatusNodes[0].children.map(child => child['value']);
      this.financialtendersFilterForm['budgetedStatus'] = selectedValues.filter(value => allValues.includes(value));
    }
  }
  OnRelatedPoChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.HasRelatedPONodes[0].children.map(child => child['value']);
      this.financialtendersFilterForm['hasRelated'] = [...allValues];
    } else {
      const allValues = this.HasRelatedPONodes[0].children.map(child => child['value']);
      this.financialtendersFilterForm['hasRelated'] = selectedValues.filter(value => allValues.includes(value));
    }
  }

  OnVendorChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.SubvendorAssginTo[0].children.map(child => child['value']);
      this.financialtendersFilterForm['subsvendors'] = [...allValues];
    } else {
      const allValues = this.SubvendorAssginTo[0].children.map(child => child['value']);
      this.financialtendersFilterForm['subsvendors'] = selectedValues.filter(value => allValues.includes(value));
    }
  }





  keywordOptions: { label: string, value: number }[] = [];
  LookupNameSetupResponse: LookupNameSetupResponse[] = [];

  ShowAddDate() {
    const formNameId = 16;
    this.internalUserService.showCode(formNameId).subscribe(
      (res: ResponseModelArray<LookupNameSetupResponse>) => {
        const DeadlineDate = res.result.filter(item => item.code === TenderPriDefinedCodes.DeadlineDate.code);

        this.keywordOptions = DeadlineDate.map(DeadlineDate => ({
          label: DeadlineDate.description,
          value: DeadlineDate.id
        }));
      },

      (error) => {
        console.error('Error fetching keyword options:', error);
      }
    );
  }
}
















