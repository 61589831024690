<nz-modal nzWidth="35%" [(nzVisible)]="Copyfromanotherrolevisible" [nzTitle]="modalTitle"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '15px', }" [nzStyle]="{ top: '15px' }"
    (nzOnCancel)="closeDialog()">
    <div class="content" *nzModalContent>


        <nz-card [nzSize]="'small'" nzTitle="Copy Permissions">
            <p>Please select a role to copy permissions from.</p>
          
            <div class="row mt-2">
                <div class="col">
                    <label class="cityDropdown" for="state">Roles
                        <span class="text-danger fw-bolder">*</span></label>
                    <input type="text" class="form-control formField">
                </div>
            </div>
          </nz-card>



        </div>
        </nz-modal>