import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-copy-form-another-role',
  templateUrl: './copy-form-another-role.component.html',
  styleUrls: ['./copy-form-another-role.component.css']
})
export class CopyFormAnotherRoleComponent {
  @Output() cancel = new EventEmitter<void>();


  Copyfromanotherrolevisible: boolean = true; // Set to false initially
  modalTitle: string = "Copy Permissions from Another Role";
  checked = true;

  closeDialog() {
    this.cancel.emit();
  }


}
