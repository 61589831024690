<nz-layout>
  <nz-sider
    class="border"
    nzCollapsible
    [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed"
    [nzWidth]="280"
    [nzTrigger]="null"
  >
    <div class="row">
      <app-side-menu-bar
        [isAllJobOption]="false"
        (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"
      ></app-side-menu-bar>
    </div>
  </nz-sider>
  <nz-layout>
    <nz-page-header>
      <nz-page-header-title>
        <nz-breadcrumb nz-page-header-breadcrumb *ngIf="selectedJobId !== null">
          <nz-breadcrumb-item>{{AccessLocalStorageService.getJobName()}}</nz-breadcrumb-item>
        </nz-breadcrumb>
        <h1 class="react">Selection</h1>
      </nz-page-header-title>
    </nz-page-header>

    <nz-content class="custom-content">
      <div class="container-fuild mb-4 m-3" *ngIf="selectedJobId !== null">
        <nz-tabset nzType="card">
          <nz-tab
            *ngFor="let tab of selectiontabsdashbord"
            [nzTitle]="selectionTabTitle(tab)"
          >
            <ng-container *ngIf="tab === 1">
              <nz-card nzBorderless="true" [nzTitle]="SelectionTitle" [nzExtra]="extraTemplate">
              <ng-template #SelectionTitle>
                <div class="row">
                  <div class="col-2 fs-6 me-3">
                    <label nz-checkbox [(ngModel)]="isSelectAll" (ngModelChange)="onSelectAllChange()">Select All</label>
                  </div>
                  <div *ngIf="!isSelectAll" class="col-1 me-2">
                    <label>Sort</label>
                  </div>
                  <div *ngIf="!isSelectAll" class="col-5 ms-1" style="margin-left: -20px;">
                    <nz-input-group nzCompact>
                      <nz-select [(ngModel)]="selectedOption" style="width: 50%">
                        <nz-option *ngFor="let option of deadlineOptions" [nzLabel]="option?.name" [nzValue]="option?.id"></nz-option>
                      </nz-select>
                      <button nz-button nzType="default">
                        <i class="bi bi-arrow-down-up text-primary"></i>
                      </button>
                    </nz-input-group>
                  </div>
                </div>
              </ng-template>

<!-- All Content -->

<div *ngIf="selectionResponse && selectionResponse.length">
  <nz-card class="div-set p-0 m-0" *ngFor="let data of selectionResponse">
    <div style="background-color: #f7f9fc">
    <nz-page-header>
      <nz-page-header-title>
      <a  nz-button nzType="link" class="p-0 m-0" (click)="FormEdit(data?.Id)">{{ data?.Title }}</a>
        <!-- <label nz-checkbox class="text-primary">{{ data?.title }}</label> -->
      </nz-page-header-title>
      <nz-page-header-subtitle>{{ data?.CategoryName}} | {{ data?.LocationName}}</nz-page-header-subtitle>
      <nz-page-header-content style="margin-top: -20px">
        <div class="row">
          <div class="col-12"><label class="sub-text">Price : </label>$0.00</div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="sub-text">Remianing From Allowances : </label>{{ data?.SingleAllowanceNumber }} of {{ data?.SingleAllowanceNumber }} | <a  nz-button nzType="link" class="p-0 m-0">{{ data?.Title }}</a>
          </div>
        </div>
        <div class="row">
          <div class="col-10">
            <label class="sub-text">Installers:</label>
            <!-- <ng-container *ngIf="data.selectionInstallerParameters?.length === 0">
             0 seleted
          </ng-container> -->
            <!-- <ng-container *ngIf="data.selectionInstallerParameters?.length > 1">
               {{ data.selectionInstallerParameters.length }} seleted
            </ng-container>
            <ng-container *ngIf="data.selectionInstallerParameters?.length === 1">
              {{ data.selectionInstallerParameters[0]?.subVendor?.companyName }} | {{ data?.isAddEditAndApproveChoices }}
            </ng-container> -->
          </div>
          <div class="col-2 text-end fw-bold fs-8">
            <nz-tag style="background-color: #c7d0d9;border-radius: 20px;">UNRELEASED</nz-tag>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <label class="sub-text">Subs / Vendors:</label>
            <!-- <ng-container *ngIf="data.selectionSubVenderParameters?.length === 0">
             0 seleted  |  |
             <ng-container>
                 <ng-container *ngIf="data?.isApproveChoicesOnly">
                   Viewing / Price Requests Only
                 </ng-container>
                 <ng-container *ngIf="data?.isAddEditAndApproveChoices">
                   Add / Edit Choices
                 </ng-container>
             </ng-container>
          </ng-container> -->
            <!-- <ng-container *ngIf="data.selectionSubVenderParameters?.length > 1">
               {{ data.selectionSubVenderParameters.length }} seleted |
              <ng-container>
                  <ng-container *ngIf="data?.isApproveChoicesOnly">
                    Viewing / Price Requests Only
                  </ng-container>
                  <ng-container *ngIf="data?.isAddEditAndApproveChoices">
                    Add / Edit Choices
                  </ng-container>
              </ng-container>
            </ng-container> -->
            <!-- <ng-container *ngIf="data.selectionSubVenderParameters?.length === 1">
              {{ data.select[0]?.subVendor?.companyName }} | {{ data?.IsAddEditAndApproveChoices }}
            </ng-container> -->
          </div>
          <div class="col-4 text-end fw-medium fs-8">
            <!-- DeadLine: N/A -->
            {{ data?.DedlineOn | date : "MMM d, yyyy, h:mm a" }}
          </div>
        </div>
        <div class="row" *ngIf="data?.PublicInstructions && data.PublicInstructions?.length > 0">
          <div class="col-12 p-0 m-0">
            <nz-collapse [nzBordered]="false">
              <nz-collapse-panel #p [ngStyle]="{'background': '#f7f9fc','border-radius': '4px',border: '0px'}" nzTitle="Custom Column"
                [nzExpandedIcon]="expandedIcon" [nzHeader]="description">
                <div class="row mt-0" #p>
                  <div class="row mt-1">
                    <div class="col-12">
                       {{ data?.PublicInstructions }}
                    </div>
                  </div>
                </div>
                <ng-template #description>
                  <span class="LColaPP mt-1" style="background-color: #f7f9fc;">
                    <h4 class="fw-medium famly-inn">Description</h4>
                  </span>
                </ng-template>
                <ng-template #expandedIcon let-active>
                  {{ active }}
                  <span nz-icon nzType="caret-right" class="ant-collapse-arrow"
                    [nzRotate]="p.nzActive ? 90 : -0"></span>
                </ng-template>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
        </div>
      </nz-page-header-content>



      <nz-page-header-extra class="text-end">
        <nz-space>
          <button
            [nzTrigger]="'click'"
            nz-button
            class="text-primary border-0 me-2 mt-2"
            style="background-color: #f7f9fc"
            nz-dropdown

          >
            <i class="fa-solid fa-link text-primary me-"></i>
            <span class="text-primary">Attachments</span>
            <span nz-icon nzType="down"></span>
          </button>

          <nz-dropdown-menu #menu4="nzDropdownMenu">
            <ul nz-menu>
              <!-- <ng-container *ngFor="let selection of selectionResponse">
                <ng-container *ngIf="selection?.attachment && selection?.attachment?.attachmentParameters">
                  <ng-container *ngFor="let attachmentParameter of selection?.attachment?.attachmentParameters">
                    <li nz-menu-item>
                      <span>{{ attachmentParameter?.name }}</span>
                    </li>
                  </ng-container>
                </ng-container>
              </ng-container> -->
            </ul>
          </nz-dropdown-menu>

          <button *nzSpaceItem nz-button nzType="link" class="text-primary fs-6">
            <i class="bi bi-chat-dots-fill text-primary me-2 fs-4"></i>
            Add Comment
          </button>
        </nz-space>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-page-header-content>
      <!-- <nz-descriptions nzSize="small" [nzColumn]="3">
            <nz-descriptions-item nzTitle="Created" [nzSpan]="1">Lili Qu</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Association" [nzSpan]="1"><a>421421</a></nz-descriptions-item>
            <nz-descriptions-item nzTitle="Creation Time" [nzSpan]="1">2017-01-10</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Effective Time" [nzSpan]="1">2017-10-10</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Remarks" [nzSpan]="2">
              Gonghu Road, Xihu District, Hangzhou, Zhejiang, China
            </nz-descriptions-item>
          </nz-descriptions> -->
    </nz-page-header-content>
    </div>
    <br />
    <nz-page-header class="p-0 m-0">
    <nz-page-header-content class="p-0 m-0">
      <nz-collapse [nzBordered]="false">
        <nz-collapse-panel
        #p
        [ngStyle]="{'background': '#ffffff','border-radius': '4px','margin-bottom': '10px','margin-top': '15px',border: '0px'}"
        nzTitle="Custom Column"
        [nzExpandedIcon]="expandedIcon"
        [nzActive]="showCollapsePanel"
       >
          <div class="row mt-0" #p>
            <div *ngIf="!isCollapsed">
              <!-- <nz-card class="nz-card-set" *ngFor="let ChoiceParam of data.selectionChoiceParameters let i=index">
                <div nz-row>
                  <div nz-col nzSpan="9">
                    <div class="row">
                      <div class="col-3">
                        <img
                          src="https://i.ibb.co/F8NmNPd/Capture.png"
                          alt=""
                          width="auto"
                          height="65px"
                        />
                      </div>
                      <div class="col-5">
                        <p class="text-primary"> {{ ChoiceParam.title }}</p>
                        <p>Created by Isabella Lussi</p>
                        <p> {{ ChoiceParam.ownerPrice }}</p>
                      </div>
                      <div class="col-4 mt-3">
                        <nz-tag
                          style="
                            background-color: #c7d0d9;
                            border-radius: 20px;
                          "
                          >UNRELEASED</nz-tag
                        >
                      </div>
                    </div>
                  </div>
                  <div nz-col nzSpan="6"></div>
                  <div nz-col nzSpan="9">
                    <div class="row">
                      <div class="col-12">
                        <button
                          [nzTrigger]="'click'"
                          nz-button
                          class="text-primary border-0"
                          nz-dropdown
                          [nzDropdownMenu]="menu4"
                        >
                          <i
                            class="fa-solid fa-link text-primary me-"
                          ></i
                          ><span class="text-primary">1</span>
                          <span nz-icon nzType="down"></span>
                        </button>
                        <nz-dropdown-menu #menu4="nzDropdownMenu">
                          <ul nz-menu>
                            <li nz-menu-item>
                              menu4 1st menu item
                            </li>
                            <li nz-menu-item>
                              menu4 2nd menu item
                            </li>
                            <li nz-menu-item>
                              menu4 3rd menu item
                            </li>
                          </ul>
                        </nz-dropdown-menu>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <nz-space>
                          <button
                            nz-button
                            nzType="link"
                            class="text-primary fs-8"
                          >
                            0 Department Selections
                          </button>
                          <button
                            nz-button
                            nzType="default"
                            class="me-1"
                          >
                            <span
                              nz-icon
                              nzType="check"
                              nzTheme="outline"
                              class="text-success"
                            ></span
                            >Approve
                          </button>
                          <button nz-button nzType="default">
                            <span
                              nz-icon
                              nzType="close"
                              nzTheme="outline"
                              class="text-danger"
                            ></span
                            >Decline
                          </button>
                        </nz-space>
                      </div>
                    </div>
                  </div>
                </div>
              </nz-card> -->
              <nz-card class="nz-card-set mt-2">
                <div nz-row>
                  <div nz-col nzSpan="16">
                    <div class="row">
                      <div class="col-4">
                        <label> Choice Title</label>
                        <input
                          type="text"
                          class="formField form-Control"
                          [(ngModel)]="formFieldValues.title" name="title"
                        />
                      </div>
                      <div class="col-3">
                        <label> Choice Title</label>
                        <nz-input-group nzAddOnAfter="$">
                          <nz-input-number
                          [(ngModel)]="formFieldValues.ownerPrice" name="ownerPrice"
                            [nzStep]="1"
                          ></nz-input-number>
                        </nz-input-group>
                      </div>
                    </div>
                  </div>
                  <div nz-col nzSpan="8" class="text-end">
                    <button  (click)="saveChoice(data.Id)" nz-button class="ms-2 ant-btn-primary">
                      Add Choice
                    </button>
                  </div>
                </div>
              </nz-card>
            </div>
          </div>
          <!-- <ng-template #tileetwe>
            <span class="LColaPP mt-1 " style="background-color: #ffffff;">
              <h4 class="fw-medium famly-inn">All Choices ({{ data.selectionChoiceParameters?.length }})</h4>
            </span>
          </ng-template> -->
          <ng-template #expandedIcon let-active active="true">
            {{ active }}
            <span nz-icon nzType="caret-right" class="ant-collapse-arrow"
              [nzRotate]="p.nzActive ? 90 : -0"></span>
          </ng-template>
        </nz-collapse-panel>
      </nz-collapse>
      <ng-template #extraTemplate>
        <nz-space>
          <button
            nz-button
            class="ant-btn-primary"
            nz-dropdown
            [nzDropdownMenu]="menu3"
          >
            Add Choices
            <span nz-icon nzType="down"></span>
          </button>
          <nz-dropdown-menu #menu3="nzDropdownMenu">
            <ul nz-menu>
              <li
                nz-menu-item
                (click)="selectionChoiceModelOpen()"
              >
                New
              </li>
              <li nz-menu-item (click)="openchooice()">
                From Other Selection
              </li>
            </ul>
          </nz-dropdown-menu>
        </nz-space>
      </ng-template>
    </nz-page-header-content>
    </nz-page-header>
  </nz-card>
</div>
<div class="row mt-5 d-flex justify-content-center align-content-center" *ngIf="selectionResponse?.length === 0">
  <div class="col" style="text-align: center;">
    <span role="img" size="78" data-testid="EmptyStateIcon" class="anticon EmptyStateIcon" style="font-size: 78px;"><svg width="1em" height="1em" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class=""><path fill-rule="evenodd" clip-rule="evenodd" d="M6.62239 4.3108C6.80548 3.27246 7.75896 2.56345 8.79135 2.66796L8.9393 2.68848L15.833 3.90402C16.8713 4.08711 17.5803 5.04059 17.4758 6.07298L17.4553 6.22093L16.0695 14.0738L23.5635 11.3463C24.552 10.9865 25.6401 11.4548 26.0678 12.3968L26.1269 12.5417L28.521 19.1195C28.6889 19.5806 28.6809 20.0858 28.5013 20.5395L28.5 26C28.5 27.0543 27.6841 27.9181 26.6492 27.9945L26.5 28H8.99996C8.71364 27.9999 8.43279 27.9781 8.15035 27.934C5.18673 27.4697 3.16007 24.6434 3.56499 21.6748L3.59898 21.4574L6.62239 4.3108ZM26.4995 21.9838L15.4635 25.9998L26.5 26L26.4995 21.9838ZM5.56859 21.8047L8.592 4.6581L15.4857 5.87363L12.4468 23.1078L12.4089 23.2934C11.9975 25.0679 10.2803 26.2426 8.45954 25.958C6.54197 25.6576 5.22375 23.7604 5.56859 21.8047ZM24.2475 13.2257L15.6685 16.3478L14.4164 23.4551C14.3626 23.7603 14.2845 24.0552 14.1846 24.3382L26.6417 19.8036L24.2475 13.2257ZM10.5 22.5C10.5 21.6716 9.82842 21 8.99999 21C8.17156 21 7.49999 21.6716 7.49999 22.5C7.49999 23.3284 8.17156 24 8.99999 24C9.82842 24 10.5 23.3284 10.5 22.5Z"></path></svg></span>
    <div class="row">
      <div class="col mt-2">
        <h2>Manage project selections</h2>
      </div>
    </div>
    <div class="col mt-3">
      <p>Improve the selection process, and get decisions from
      <br> customers sooner.</p>
    </div>
    <div class="col mt-4"
      style="display: flex; justify-content: center; align-items: center;">
      <nz-space>
        <button *nzSpaceItem nz-button nzType="default"><i
            class="bi bi-box-arrow-up-right me-2"></i>Learn How</button>
        <button *nzSpaceItem nz-button nzType="primary">Add a Selection</button>
      </nz-space>
    </div>
  </div>
</div>

<!-- All Content -->

              </nz-card>
              <ng-template #extraTemplate>
                <nz-space *ngIf="!isSelectAll" >
                  <button
                  nz-button
                  *nzSpaceItem
                  nzType="default"
                  class=" rounded-1"
                  (click)="toggleCollapse()"
                >
                  {{
                    isCollapsed
                      ? "Expand All Choices "
                      : "Collapse All Choices"
                  }}
                </button>
                <button
                  nz-button
                  *nzSpaceItem
                  nzType="default"
                  class=" rounded-1"
                  (click)="toggleCollapse()"
                >
                  {{
                    isCollapsed ? "Expand Everything" : "Collapse Everything"
                  }}
                </button>

                <button *nzSpaceItem nz-button nz-dropdown    class=" rounded-1" [nzDropdownMenu]="menu4">
                  Import
                  <span nz-icon nzType="down"></span>
                </button>

                <nz-dropdown-menu #menu4="nzDropdownMenu">
                  <ul nz-menu >
                    <li nz-menu-item (click)="importSelectionModelOpen()">Template</li>
                  </ul>
                </nz-dropdown-menu>

                <button
                  nz-button
                  *nzSpaceItem
                  nzType="default"
                  class=" rounded-1"
                  (click)="openFilter(PROJECT_MANG_SELEC_CARD_V, PAGE_ID)"
                >
                  <i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter
                </button>
                <button
                  nz-button
                  *nzSpaceItem
                  class=" ant-btn-primary"
                  (click)="selectionModelOpen('Selection')"
                >
                  New Selection
                </button>
                </nz-space>

                <nz-space *ngIf="isSelectAll">

                  <th class="d-flex align-items-center sub-text fs-6 p-0 m-0">
                    1
                    <button nz-button *nzSpaceItem nzType="default">Print</button>
                    <div class="ms-2 me-2">selected</div>
                  </th>
                  <a  class="ms-2 me-2 d-flex align-items-center"
                  >Cancle</a>
                  <button nz-button *nzSpaceItem nzType="default">Delete</button>
                  <button nz-button *nzSpaceItem nzType="default">Copy</button>
                  <button nz-button *nzSpaceItem nzType="default">Unrelease</button>
                  <button nz-button *nzSpaceItem nzType="default">Default Button</button>
                  <button nz-button *nzSpaceItem nzType="default">Release</button>
                  <button nz-button *nzSpaceItem nzType="default">Add to Existing POs</button>
                  <button nz-button *nzSpaceItem disabled nzType="default">Add to Owner Invoice</button>
                </nz-space>

              </ng-template>



            </ng-container>

            <ng-container *ngIf="tab === 2">
              <div nz-row>
                <div nz-col nzSpan="12">
                  <div class="row">
                    <div class="col-2 fs-6 mt-1">
                      <nz-page-header-title>
                        <button [nzTrigger]="'click'" nz-button nz-dropdown [nzDropdownMenu]="group" class="me-2 rounded-1" >
                          Group
                          <span nz-icon nzType="down"></span>
                        </button>
                        <nz-dropdown-menu #group="nzDropdownMenu">
                          <ul nz-menu>
                            <li nz-menu-item>None</li>
                            <li nz-menu-item>Category</li>
                            <li nz-menu-item>Location</li>
                          </ul>
                        </nz-dropdown-menu>
                      </nz-page-header-title>
                    </div>
                  </div>
                </div>
                <div nz-col nzSpan="12" class="text-end">
                  <button
                    nz-button
                    nzType="default"
                    class="ms-2 rounded-1"
                    (click)="toggleCollapse()"
                  >
                    {{
                      isCollapsed
                        ? "Expand All Choices "
                        : "Collapse All Choices"
                    }}
                  </button>
                  <button
                    nz-button
                    nzType="default"
                    class="ms-2 rounded-1"
                    (click)="toggleCollapse()"
                  >
                    {{
                      isCollapsed ? "Expand Everything" : "Collapse Everything"
                    }}
                  </button>
                  <button
                    nz-button
                    nzType="default"
                    class="ms-2 rounded-1"
                    (click)="importSelectionModelOpen()"
                  >
                    Import Selections
                  </button>
                  <button
                    nz-button
                    nzType="default"
                    class="ms-2 rounded-1"
                    (click)="openFilter(PROJECT_MANG_SELEC_CARD_V, PAGE_ID)"
                  >
                    <i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter
                  </button>
                  <button
                    nz-button
                    class="ms-2 ant-btn-primary"
                    (click)="selectionModelOpen('Selection')"
                  >
                    New Selection
                  </button>
                </div>
              </div>
              <kendo-grid [kendoGridBinding]="getSelectUserData" [sortable]="true"   (detailExpand)="onDetailExpand($event)"
              (detailCollapse)="onDetailCollapse($event)" >


              <kendo-grid-checkbox-column [width]="45" [headerClass]="{ 'text-center': true }" [class]="{ 'text-center': true }" [resizable]="false" [columnMenu]="false" [showSelectAll]="true"></kendo-grid-checkbox-column>
                <kendo-grid-column *ngFor="let column of displayedColumns; let i = index" [title]="column" [field]="column" [minResizableWidth]="150" [maxResizableWidth]="800"[width]="getColumnWidthSize[i]"[sticky]="isColumnSticky[i]" [headerClass]="'pinned-header'">
                  <ng-template kendoGridHeaderTemplate let-columnIndex="columnIndex">
                    <div class="d-flex align-items-center">
                      <span>{{ column }}</span>
                      <button (click)="toggleColumnSticky(columnIndex)" class="btn btn-link"><i class="bi" [ngClass]="{'bi-pin-fill': isColumnSticky[columnIndex],'bi-pin': !isColumnSticky[columnIndex]}"></i></button>
                    </div>
                  </ng-template>




                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <ng-container *ngIf="column === 'Title'" style="width: 50%" (resize)="false">
                    <a  nz-button nzType="link" class="p-0 m-0 ms-2" (click)="FormEdit(dataItem?.Id)">{{dataItem?.Title}}</a>
                  </ng-container>


                  <ng-container *ngIf="column === 'Catagory'">
                    <div class="ellipsis">{{dataItem?.CategoryName}}</div>
                  </ng-container>

                <!-- <div class="d-flex align-items-center">
                <span *ngIf="dataItem.TotalChoice > 0"
                      (click)="dataItem.isExpanded = !dataItem.isExpanded; fetchActivityResponse(dataItem.Id)"
                      style="cursor: pointer;">
                    <span nz-icon nzType="caret-right" nzTheme="outline"
                          [ngClass]="{'rotated-icon': dataItem.isExpanded}">
                    </span>
                </span>
                {{ dataItem[column] }}
            </div> -->





                  <ng-container *ngIf="column === 'Location'">
                    <div class="ellipsis">{{dataItem?.LocationName}}</div>
                  </ng-container>
                  <ng-container *ngIf="column === 'Comments'">
                    <div class="ellipsis">{{dataItem?.TotalMessaging}}</div>
                  </ng-container>



                  <ng-container *ngIf="column === 'Status'">
                    <nz-tag
                    [nzColor]="statussetupColorMap?.[dataItem?.StatusName]?.backgroundColor"
                    [ngStyle]="{
                      color:
                      statussetupColorMap[dataItem?.StatusName]?.textColor
                    }"
                    style="
                      font-weight: 700;
                      line-height: 1;
                      border-radius: 12px;
                      line-height: 16px;
                      padding: 0.2em 0.6em;
                    "
                  >
                {{dataItem?.StatusName}}
                </nz-tag>
                  </ng-container>
                  <ng-container *ngIf="column === 'Remianing from Allowances'">
                    <div class="ellipsis">{{dataItem?.SingleAllowanceNumber}}</div>
                  </ng-container>
                  <ng-container *ngIf="column === 'Deadline'">
                    <div class="ellipsis">{{dataItem?.DedlineOn | date: 'MMM d, yyyy'}}</div>
                  </ng-container>
                  <ng-container *ngIf="column === 'Price'">
                    <div class="ellipsis">{{dataItem?.DedlineOn | date: 'MMM d, yyyy'}}</div>
                  </ng-container>
                  <ng-container *ngIf="column === 'File'">
                    <ng-container *ngIf="dataItem?.TotalAttachments > 0">
                      <a nz-button nzType="link" class="p-0 m-0" nz-dropdown [nzDropdownMenu]="menu4" nzTrigger="click">
                        <svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                          <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path>
                        </svg>
                        <span>{{ dataItem?.TotalAttachments }}</span>
                        <span nz-icon nzType="down"></span>
                      </a>

                      <nz-dropdown-menu #menu4="nzDropdownMenu">
                        <ul nz-menu>
                          <li *ngFor="let fileName of dataItem?.FileNames.split(','); let i = index" nz-menu-item>
                            {{ fileName?.trim() }} <!-- Trimming in case of extra spaces -->
                          </li>
                        </ul>
                      </nz-dropdown-menu>
                    </ng-container>


                  </ng-container>


                  <ng-container *ngIf="column === 'Subs/Vendors'">
                    <ng-container *ngFor="let userParam of dataItem?.selectionSubVenderParameters; let i = index">
                      <div class="" *ngIf="i === 0 || VendorsAllUsers">
                        <span>
                          {{ userParam?.subVendor?.companyName }}
                        </span>
                      </div>
                      <div class="mt-2" *ngIf="!VendorsAllUsers && i > 1">
                        <span nz-button nzType="text" (click)="toggleVendors()">
                          <a class="text-primary text-start fw-bold">Show More</a>
                        </span>
                      </div>
                    </ng-container>
                    <div class="mt-2" *ngIf="dataItem?.selectionSubVenderParameters && dataItem?.selectionSubVenderParameters?.length > 1 && VendorsAllUsers">
                      <span nz-button nzType="text" (click)="toggleVendors()">
                        <a class="text-primary text-start fw-bold">Show Less</a>
                      </span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="column === 'Installers'">
                    <ng-container *ngFor="let userParam of dataItem?.selectionSubVenderParameters; let i = index">
                      <div class="" *ngIf="i === 0 || InstallersAllUsers">
                        <span>
                          {{ userParam?.subVendor?.companyName }}
                        </span>
                      </div>
                      <div class="mt-2" *ngIf="!InstallersAllUsers && i > 1">
                        <span nz-button nzType="text" (click)="toggleInstallers()">
                          <a class="text-primary text-start fw-bold">Show More</a>
                        </span>
                      </div>
                    </ng-container>
                  <div class="mt-2" *ngIf="dataItem?.selectionSubVenderParameters && dataItem?.selectionSubVenderParameters?.length > 1 && InstallersAllUsers">
                    <span nz-button nzType="text" (click)="toggleInstallers()">
                      <a class="text-primary text-start fw-bold">Show Less</a>
                    </span>
                  </div>
                  </ng-container>
                </ng-template>

                </kendo-grid-column>
                <ng-template kendoGridDetailTemplate let-dataItem>
                  <div *ngIf="dataItem?.TotalChoice > 0" class="card ms-5" >
                    <div class="card-left">
                      <div class="avatar">
                        <img
                          [src]="dataItem?.expandedData?.FilePath || dataItem?.FilePath"

                          class="img-fluid"
                        />
                      </div>
                      <div class="card-content">
                        <h4>
                          {{ dataItem?.expandedData?.Title || dataItem?.Title }}
                          <span class="selected-label">SELECTED</span>
                        </h4>
                        <p>Created by {{ dataItem?.expandedData?.CreatedBy || dataItem?.CreatedBy }}</p>
                        <p>(Budgeted) ${{ dataItem?.expandedData?.OwnerPrice || 'N/A' }}</p>
                      </div>
                    </div>
                    <div>
                      <span class="dependent-link">
                        {{ dataItem?.expandedData?.TotalDependentSelection || 0 }} Dependent Selections
                      </span>
                    </div>
                  </div>
                </ng-template>

                <ng-template kendoGridDetailExpandIconTemplate let-dataItem>
                  <!-- Conditionally display the expand icon -->
                  <ng-container *ngIf="dataItem?.TotalChoice > 0">
                    <span class="expand-icon" (click)="dataItem.expanded = !dataItem.expanded">
                      <i class="fas" [ngClass]="dataItem.expanded ? 'fa-minus' : 'fa-plus'"></i>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="dataItem?.TotalChoice <= 0">
                    <span class="no-expand-icon">N/A</span>
                  </ng-container>
                </ng-template>

                <ng-template kendoGridDetailTemplate let-dataItem>
                  <section *ngIf="dataItem?.selectionChoiceParameters?.length > 0">
                    <nz-card class="nz-card-set" *ngFor="let ChoiceParam of dataItem.selectionChoiceParameters">
                      <div nz-row>
                        <div nz-col nzSpan="9">
                          <div class="row">
                            <div class="col-3">
                              <img
                                src="https://i.ibb.co/F8NmNPd/Capture.png"
                                alt=""
                                width="auto"
                                height="65px"
                              />
                            </div>
                            <div class="col-5">
                              <p class="text-primary"> {{ ChoiceParam.title }}</p>
                              <p>Created by Isabella Lussi</p>
                              <p> {{ ChoiceParam.ownerPrice }}</p>
                            </div>
                            <div class="col-4 mt-3">
                              <nz-tag
                                style="
                                  background-color: #c7d0d9;
                                  border-radius: 20px;
                                "
                                >UNRELEASED</nz-tag
                              >
                            </div>
                          </div>
                        </div>
                        <div nz-col nzSpan="6"></div>
                        <div nz-col nzSpan="9">
                          <div class="row">
                            <div class="col-12">
                              <button
                                [nzTrigger]="'click'"
                                nz-button
                                class="text-primary border-0"
                                nz-dropdown
                                [nzDropdownMenu]="menu4"
                              >
                                <i
                                  class="fa-solid fa-link text-primary me-"
                                ></i
                                ><span class="text-primary">1</span>
                                <span nz-icon nzType="down"></span>
                              </button>
                              <nz-dropdown-menu #menu4="nzDropdownMenu">
                                <ul nz-menu>
                                  <li nz-menu-item>
                                    menu4 1st menu item
                                  </li>
                                  <li nz-menu-item>
                                    menu4 2nd menu item
                                  </li>
                                  <li nz-menu-item>
                                    menu4 3rd menu item
                                  </li>
                                </ul>
                              </nz-dropdown-menu>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <nz-space>
                                <button
                                  nz-button
                                  nzType="link"
                                  class="text-primary fs-8"
                                >
                                  0 Department Selections
                                </button>
                                <button
                                  nz-button
                                  nzType="default"
                                  class="me-1"
                                >
                                  <span
                                    nz-icon
                                    nzType="check"
                                    nzTheme="outline"
                                    class="text-success"
                                  ></span
                                  >Approve
                                </button>
                                <button nz-button nzType="default">
                                  <span
                                    nz-icon
                                    nzType="close"
                                    nzTheme="outline"
                                    class="text-danger"
                                  ></span
                                  >Decline
                                </button>
                              </nz-space>
                            </div>
                          </div>
                        </div>
                      </div>
                    </nz-card>
                  </section>
                </ng-template>

              </kendo-grid>
              <nz-footer class="custom-footer">
                <div class="container-fluid ">
                  <div class="row d-flex justify-content-between align-items-center  ">
                    <div class="row mb-0 mt-2">
                      <div class="col">
                        <nz-pagination style="float: right;" [nzPageIndex]="currentPage" [nzTotal]="totalRecords"
                        [nzPageSize]="pageSize" nzShowSizeChanger (nzPageIndexChange)="onPageChange($event)"
                        (nzPageSizeChange)="onPageSizeChange($event)" [nzPageSizeOptions]="[10, 20, 50, 75, 100]"></nz-pagination>

                        <ng-container  *ngIf="PAGE_ID">
                          <div class="row d-flex justify-content-start mb-1 ">
                            <div class="col-md-3">

                              <nz-select
                              [(ngModel)]="selectedValueControl"
                               name="standardFilter"
                               (ngModelChange)="onFilterChange($event)"
                               nzShowSearch
                               >
                               <nz-option  *ngFor="let standard of getStandardViewFilterData"
                                 [nzLabel]="standard.name + (standard.isSetAsDefault ? ' ' : '')"
                                 [nzValue]="standard.id"
                                 nzCustomContent>
                                 <div class="option-content">
                                   <!-- Customize content with icons and text -->
                                   <span *ngIf="standard.icon" nz-icon [nzType]="standard.icon"></span>
                                   <span>{{ standard.name }}</span>
                                 <span  *ngIf="standard.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

                                 </div>
                               </nz-option>
                             </nz-select>





                            </div>
                            <div class="col-md-1">
                              <button nz-button class="border-0"
                              nz-popover
                              [nzPopoverContent]="contentTemplate"
                              [nzPopoverPlacement]="'top'"

                               (click)="openStandardViewFilterComponent()">
                                <span nz-popover class="text-primary text-dark fw-bold fs-5" nz-icon nzType="ellipsis"
                                  nzTheme="outline"></span>
                              </button>
                            </div>
                            <div class="col-md-2">
                              <div class="mt-2" *ngIf="selectedFilter === 1">
                                <p>Your filter has unsaved changes.</p>
                              </div>
                              <div class="mt-2" *ngIf="selectedFilter === 0">
                                <p>Your filter has unsaved changes.</p>
                              </div>
                            </div>

                            <div class="col-md-3">
                              <button (click)="openStandardViewFilterComponent('saveAs')" type="button" nz-button nzType="default" class="me-2" style="font-size: 12px;"
                              *ngIf="selectedFilter === 1">Save As</button>

                              <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;"
                              *ngIf="selectedFilter === 1" type="button">Reset</button>

                              <button (click)="saveEditStandardView()"  nz-button nzType="primary" type="button" class="me-2" style="font-size: 12px;"
                              *ngIf="selectedFilter === 0">Save</button>

                              <button (click)="openStandardViewFilterComponent('saveAs')" nz-button nzType="default" class="me-2" style="font-size: 12px;"
                              *ngIf="selectedFilter === 0" type="button">Save As</button>

                              <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;"
                              *ngIf="selectedFilter === 0" type="button">Reset</button>

                            </div>
                          </div>
                        </ng-container>

                        <div class="col d-flex mt-3">


                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </nz-footer>
            </ng-container>

            <ng-container *ngIf="tab === 3">
              <app-hierarchy></app-hierarchy>
            </ng-container>

            <ng-container *ngIf="tab === 4">
              <app-allowance></app-allowance>
            </ng-container>
          </nz-tab>
        </nz-tabset>
      </div>
      <div  *ngIf="selectedJobId  === null" class="flex justify-content-center" style="background: rgb(255, 255, 255); margin-top: 13%;">
        <h1 class="font-family d-flex align-items-center justify-content-center">No Job Selected</h1>
      </div>


    </nz-content>
    <!-- ///// content ///// -->
  </nz-layout>
</nz-layout>



<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>
  </div>
</ng-template>
