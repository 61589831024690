import { CdkDragDrop, moveItemInArray,transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-lead-proposal-columns',
  templateUrl: './lead-proposal-columns.component.html',
  styleUrls: ['./lead-proposal-columns.component.css']
})
export class LeadProposalColumnsComponent {
  @Output() cancel = new EventEmitter<void>();
  CostInformation: boolean = true;
  listOfData = [
    {
      id: '1',
      name: 'Quantity',
    },
    {
      id: '2',
      name: 'Description',
    },
    {
      id: '3',
      name: 'Unit',
    },
    {
      id: '4',
      name: 'Unit Cost',
    },
    {
      id: '5',
      name: 'Cost Type',
    },
    {
      id: '6',
      name: 'Builder Cost',
    },
    {
      id: '7',
      name: 'Markup',
    },
    {
      id: '8',
      name: 'Owner',
    },
    {
      id: '8',
      name: 'Margin',
    },
    {
      id: '8',
      name: 'Profit',
    },
  ];
  Moves = ['Quantity', 'Description', 'Unit', 'Unit Cost','Cost Type', 'Builder Cost','Markup', 'Owner', 'Margin','Profit'];
  inputFields: boolean;
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer !== event.container) {
      // When an item is dropped into a different container
      const movie = this.listOfData[event.previousIndex];

      this.inputFields = true;
      console.log(this.listOfData);
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      // Remove the item from the original section after dropping
      this.listOfData = this.listOfData.filter((_, index) => index !== event.previousIndex);
    } else {
      moveItemInArray(this.listOfData, event.previousIndex, event.currentIndex);
    }
  }
  open(): void {
    this.CostInformation = true
  }
  close(): void {
    this.cancel.emit();
  }
}
