import { Component, EventEmitter, Output } from '@angular/core';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';

@Component({
  selector: 'app-hierarchy-new-selection',
  templateUrl: './hierarchy-new-selection.component.html',
  styleUrls: ['./hierarchy-new-selection.component.css']
})
export class HierarchyNewSelectionComponent {
  @Output() cancel = new EventEmitter<void>();
  selectedValue = null;
  addedCost: number = 0;
  visible=false
  radioValue = 'A';
  demoValue = 0.00;
  date = null;
  time: Date | null = null;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);
  value: string[] = ['0-0-0'];

  selectionCancel(){
    this.visible=false;
  }
  showSelection(){
    this.visible=true;
  }

  
  convertToRupees(value: number): string {
    // Your conversion logic here
    // For example, if 1 dollar = 75 rupees
    const conversionRate = 75;
    const result = (value * conversionRate).toFixed(2); // Convert to string with two decimal places
    return result;
  }

  updateConvertedValue(newValue: string): void {
    // Update the addedCost property as the user types, with a debounce of 300 milliseconds
    const inputParts = newValue.split('.');
    const integerValue = inputParts[1];
    const decimalValue = inputParts.length > 1 ? inputParts[1] : '';
    
    // Check if the decimal part has been manually modified by the user
    const isDecimalModifiedByUser = newValue.includes('.') && decimalValue.length > 0;
  
    // If the decimal part is not modified by the user, update the addedCost
    if (!isDecimalModifiedByUser) {
      this.addedCost = integerValue !== '' ? parseFloat(integerValue) / 75 : 0; // Parse the input for the integer part
    }

}

showDateFields: boolean = true;
  showScheduleItemFields: boolean = false;

  toggleDateFields() {
    this.showDateFields = !this.showDateFields;
    this.showScheduleItemFields = false; // Ensure the other set is hidden
  }

  toggleScheduleItemFields() {
    this.showScheduleItemFields = !this.showScheduleItemFields;
    this.showDateFields = false; // Ensure the other set is hidden
  }


  nodes: NzTreeNodeOptions[] = [
    {
      title: 'Check All',
      value: '0-all',
      key: '0-all',
      isLeaf: true,
      checked: true
    },
    {
      title: 'Node1',
      value: '0-0',
      key: '0-0',
      isLeaf: true
    },
    {
      title: 'Node2',
      value: '0-1',
      key: '0-1',
    },
    {
      title: 'Node3',
      value: '0-2',
      key: '0-2',
      isLeaf: true
    },
    {
      title: 'Node4',
      value: '0-3',
      key: '0-3',
      isLeaf: true
    },
    {
      title: 'Node5',
      value: '0-4',
      key: '0-4',
      isLeaf: true
    },
    {
      title: 'Node6',
      value: '0-5',
      key: '0-5',
      isLeaf: true
    },
  ];
}
