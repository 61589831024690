<div class="content" *ngIf="!isAssignColor">
  <div class="row">
    <h1 nz-typography>
      Update Color for {{ selectedGlobalIds?.length }}
      {{ selectedGlobalIds?.length === 1 ? "Activity" : "Activities" }}?
    </h1>
  </div>

  <form nz-form [formGroup]="validateForm">
    <nz-form-item>
      <nz-form-label
        [nzSpan]="24"
        class="sub-text p-0"
        style="margin-left: -3px"
        nzFor="colorId"
        nzRequired
        >Display Color</nz-form-label
      >
      <nz-form-control [nzSpan]="24" nzErrorTip="Required">
        <nz-select
          nzShowSearch
          [nzCustomTemplate]="defaultTemplate"
          id="colorId"
          formControlName="colorId"
          [style.width.%]="100"
          (ngModelChange)="updateSelectedColor($event)"
        >
          <nz-option
            nzCustomContent
            *ngFor="let option of colors"
            [nzLabel]="option.name"
            [nzValue]="option.id"
          >
            <span
              class="color-circle-drop d-flex"
              [style.backgroundColor]="option.colorCollection"
            ></span>
            <ng-container>
              <span class="color-name">{{ option.name }}</span>
            </ng-container>
          </nz-option>
        </nz-select>
        <ng-template #defaultTemplate let-selected>
          <div class="color-item d-flex align-items-center">
            <span
              class="color-circle-drop"
              [style.backgroundColor]="selectedColor"
            ></span>
            <span class="color-name">{{ selected.nzLabel }}</span>
          </div>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <div nz-row class="justify-content-end mt-5">
      <div nz-col>
        <nz-space>
          <button (click)="Cancel()" *nzSpaceItem nz-button nzType="default">
            Cancel
          </button>
          <button
            (click)="submitForm()"
            *nzSpaceItem
            nz-button
            nzType="primary"
          >
            Update {{ selectedGlobalIds?.length }}
            {{ selectedGlobalIds?.length === 1 ? "Activity" : "Activities" }}?
          </button>
        </nz-space>
      </div>
    </div>
  </form>
</div>

<div *ngIf="isAssignColor" class="result">
  <nz-result
    nzStatus="success"
    nzTitle="Updated {{
      selectedGlobalIds?.length === 1 ? 'Activity..' : 'Activities...'
    }}"
  >
  </nz-result>
</div>
