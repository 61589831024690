import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-company-setting-job-email-sample',
  templateUrl: './company-setting-job-email-sample.component.html',
  styleUrls: ['./company-setting-job-email-sample.component.css']
})
export class CompanySettingJobEmailSampleComponent implements OnInit{

  @Output() cancel = new EventEmitter<void>();
  emailSampleVisible = true;

  constructor(private fb: NonNullableFormBuilder) {
    this.validateForm = this.fb.group({
      email: ['shabbir@gmail.com', [Validators.email, Validators.required]],
    });
  }
  validateForm: FormGroup<{
    email: FormControl<string>;
  }>;

  ngOnInit(): void {
  }

  emailSampleCancel()
  {
    this.cancel.emit();
    this.emailSampleVisible = false;
  }
  submitForm(): void {
    if (this.validateForm.valid) {
      console.log('submit', this.validateForm.value);
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  
}
