<nz-modal
  nzWidth="70%"
  [(nzVisible)]="leadActivtyVisibility"
  [nzTitle]="LeadActivityTitle"
  [nzFooter]="LeadActivityFooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    height: '75vh',
    'max-height': '75vh'
  }"
  [nzStyle]="{ top: '37px' }"
  (nzOnCancel)="GoogleMapFromCancel()"
>
<ng-template #LeadActivityTitle>
    <!-- Edit Mode By Grid Start -->
    <div class="row">
      <h2 class="p-0 m-0">Location</h2>
    </div>
    </ng-template>
<ng-container *nzModalContent>

            <iframe [src]="mapUrl" frameborder="0" scrolling="no"  style="width: 100%; height: 100%; border:0;" allowfullscreen="" loading="lazy"></iframe>
            <div *ngIf="isFindingLocation" class="blinking-location-icon">
              <img src="assets/location-svg/location.svg" alt="Finding location">
            </div>
          
</ng-container>

  <!-- Edit Mode By Dialog box End -->


  <ng-template #LeadActivityFooter>
    <div class="mt-2 align-items-center">

      <button
        nz-button
        nzType="primary"
        class="rounded-1 me-0"
        (click)="GoogleMapFromCancel()"
      >
        Cancel
      </button>
    </div>
  </ng-template>
</nz-modal>