import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RoleTypeService } from 'src/Service/Internaluser/role-type.service';
import { FieldDataType } from 'src/app/Core/Enums/data_types';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { AddNewStandardFilterComponent } from '../../Modal/add-new-standard-filter/add-new-standard-filter.component';
import { CreateLookupFilterRequest, LookupFilterResponse, LookUpStandardResponse, LookupFilter, FilterSearchParameter } from '../../Models/LookupModels';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { MessagingsCommentsService } from 'src/Service/Messagings-Comments-Services/messagings-comments.service';

@Component({
  selector: 'app-messaging-notification-history-filter-filed',
  templateUrl: './messaging-notification-history-filter-filed.component.html',
  styleUrls: ['./messaging-notification-history-filter-filed.component.css']
})
export class MessagingNotificationHistoryFilterFiledComponent implements OnInit {
  selectedValue: any;
  date = null;
  listOfOption: Array<{ label: string; value: string }> = [];
  listOfTagOptions = [];
  @Input() pageId: number;
  messagingnotificationhistoryFilterForm: FormGroup;
  createLookupFilterRequests: CreateLookupFilterRequest[] = [];
  filterResponseData: LookupFilterResponse[] = [];
  lookUpStandardResponse: LookUpStandardResponse[] = [];
  defaultStandardFilter: LookupFilterResponse;
  predefinedValuesMultiSelect: string[] = [];
  formFieldValues: any = {};
  filterSearchParameter: FilterSearchParameter;
  selectedFilter: number = -1;
  preDefinedDataCodes = {
    standardFilter: { code: "IUSTD" },
    status: { code: "IUSTS" },
    name: { code: "IUNAM" },
    role: { code: "IUROL" }
  };
  selectedJobId: number = 0;
  constructor(
    private roleService: RoleTypeService,
    private fb: FormBuilder,
    public toastService: NzMessageService,
    private cdr: ChangeDetectorRef,
    private loadingIndicatoreService: LoadingIndicatorService,
    private modal: NzModalService,
    private LookupFilterService: LookupFilterService,
    private internalUserService: InternalUserService,
    private messagingNotifiyService: MessagingsCommentsService,
    private accessLocalStorageService: AccessLocalStorageService,
  ) { }
  ngOnInit(): void {
    this.initLoad();
    this.initializeForm();
    const children: Array<{ label: string; value: string }> = [];
    for (let i = 10; i < 36; i++) {
      children.push({ label: i.toString(36) + i, value: i.toString(36) + i });
    }
    this.listOfOption = children;
  }

  nodes: NzTreeNodeOptions[] = [
    {
      title: 'Check All',
      value: '0-all',
      key: '0-all',
      isLeaf: true,
      checked: false
    },
    {
      title: 'Node1',
      value: '0-0',
      key: '0-0',
      isLeaf: true
    },
    {
      title: 'Node2',
      value: '0-1',
      key: '0-1',
      isLeaf: true
    },
    {
      title: 'Node3',
      value: '0-2',
      key: '0-2',
      isLeaf: true
    },
    {
      title: 'Node4',
      value: '0-3',
      key: '0-3',
      isLeaf: true
    },
    {
      title: 'Node5',
      value: '0-4',
      key: '0-4',
      isLeaf: true
    },
    {
      title: 'Node6',
      value: '0-5',
      key: '0-5',
      isLeaf: true
    },];






  initLoad() {
    const lookupFilter: LookupFilter = {
      formNameId: this.pageId,
    };
    this.getStandardFilterByFormPageId(this.pageId);
    this.fetchData(lookupFilter);
  }
  fetchData(lookUpStandard: LookupFilter): void {
    this.LookupFilterService.postData(lookUpStandard).subscribe(
      data => {
        this.lookUpStandardResponse = data.result;
      },
      error => {
        console.error('Error fetching data:', error);
      }
    );
  }
  openStandardFilterComponent() {
    this.createFormFieldsInArray();
    const modalRef = this.modal.create({
      nzContent: AddNewStandardFilterComponent,
      nzData: 
      { 
        pageId: this.pageId,
        std : this.filterResponseData ,
        field: this.createLookupFilterRequests
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  getStandardFilterByFormPageId(pageId: number) {
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.LookupFilterService.$getStandardFilter.subscribe(result =>{
      if(result){
        this.filterResponseData = result.result;
        console.log("RESULT STANDARD FILTER : ", this.filterResponseData);
        this.initializeForm();
        this.setDefaultFieldValues(this.defaultStandardFilter.id);
      }
    });
  }
  // initializeForm(): void {
  //   this.messagingnotificationhistoryFilterForm = this.fb.group({
  //     standardFilter: new FormControl(this.getDefaultFilterValue()),
  //     sentTo: [null],
  //     dateSent: [null],
  //     none: [null],
  //     type: [[null]]

  //   });
  // }
  onSubmit(): void {
    this.createFormFieldsInArray();
  }
  resetFilter(): void {
    const formControlKeys = Object.keys(this.messagingnotificationhistoryFilterForm.controls).slice(1);
    formControlKeys.forEach(field => {
      this.messagingnotificationhistoryFilterForm.get(field).reset();
    });
  }
  createFormFieldsInArray() {
    this.createLookupFilterRequests = [];
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const value = this.messagingnotificationhistoryFilterForm.get(field).value;
      if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 1 && value[0] === null)) {
        const newRow = {
          companyParameterId: 0,
          lookUpStandardId: 0,
          code: code,
          meaning: '',
          valueId: 0,
          formNameId: this.pageId,
          dataTypeId: 0,
          createLookupFilterParameterRequests: [null]
        };
        const correspondingResponse = this.lookUpStandardResponse.find(response => response.code === code);
        if (correspondingResponse) {
          newRow.companyParameterId = correspondingResponse.companyParameterId;
          newRow.lookUpStandardId = correspondingResponse.id;
          newRow.dataTypeId = correspondingResponse.dataTypeId;
          if (
            correspondingResponse.dataType.code === FieldDataType.SingleLineText ||
            correspondingResponse.dataType.name === 'Single-Line Text') {
            newRow.meaning = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.Dropdown ||
            correspondingResponse.dataType.name === 'Dropdown') {
            newRow.valueId = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.MultiSelectDropdown ||
            correspondingResponse.dataType.name === 'Multi-Select Dropdown'
          ) {
            newRow.createLookupFilterParameterRequests = value.map((item, index) => {
              return { valueId: item };
            });
          }
          // Assign other properties similarly based on your data structure
        }
        this.createLookupFilterRequests.push(newRow);
      }
    });
  }
  getDefaultFilterValue() {
    this.defaultStandardFilter = this.filterResponseData.find(filter => filter.isSetAsDefault === true);
    return this.defaultStandardFilter ? this.defaultStandardFilter.id : null;
  }
  setDefaultFieldValues(standardFilterId: number): void {
    this.predefinedValuesMultiSelect = [];
    let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
    let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;
    // Get all form control keys except the first one
    const formControlKeys = Object.keys(this.messagingnotificationhistoryFilterForm.controls).slice(1);
    // Reset all form controls except the first one
    formControlKeys.forEach(field => {
      this.messagingnotificationhistoryFilterForm.get(field).reset(); // Reset the form control
    });
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const fieldData = lookUpFiltersField.find(filter => filter.code === code);
      if (fieldData != undefined) {
        if (
          fieldData.dataType.code === FieldDataType.SingleLineText) {
          this.messagingnotificationhistoryFilterForm.get(field).setValue(fieldData.meaning);
        }
        if (fieldData.dataType.code === FieldDataType.Dropdown) {
          this.messagingnotificationhistoryFilterForm.get(field).setValue(fieldData.id);
        }
        if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown) {
          let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
          this.predefinedValuesMultiSelect = multiSelectValues.map(String);
          this.messagingnotificationhistoryFilterForm.get(field).setValue(this.predefinedValuesMultiSelect);
        }
      }
    });
  }
  onChangeStandardFilter($event: number): void {
    this.setDefaultFieldValues($event);
  }

  onChange(result: Date): void {
    console.log('onChange: ', result);
  }



  onFilterApply(): void {
    let selectedFilter = this.formFieldValues['standardFilter'];
    let isSelectedFilterStandard = this.filterResponseData.find(x => x.id === selectedFilter && x.isNotDeleted === true);
    if (isSelectedFilterStandard && isSelectedFilterStandard != undefined) {
      this.selectedFilter = 1;
    }
    else {
      this.selectedFilter = 0;
    }
    this.createFormFieldsInArray();
    this.createLookupFilterRequests[0].page = 1;
    this.createLookupFilterRequests[0].pageSize = 10;

    this.internalUserService.getAppliedFilterData(this.createLookupFilterRequests);


  }

  
  /* _____________________________________ START DIFFERENCE _________________________________________________ */
  initializeForm(): void {
    this.formFieldValues = {
      standardFilter: this.getDefaultFilterValue(),
      sentTo: '',
      dateSent: '',
      type: [] = [],
    };
  }
  /* _____________________________________ End DIFFERENCE _________________________________________________ */
}





















