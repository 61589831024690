<nz-modal [(nzVisible)]="Taghandle" [nzTitle]="NewDaily1" [nzFooter]="CWarranty" (nzOnCancel)="TagClose()"
[nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    maxHeight: '72vh',
    'overflow-y': 'auto',
    'overflow-x': 'hidden',
  }" 
  [nzStyle]="{ top: '25px'}"
  [nzWidth]="'84%'">
    <ng-template #NewDaily1>
        <div class="row p-0 m-0">
          <h2 class="WarrantyServiceTitleSet famly-inn p-0 m-0">
          Warranty Service
          </h2>
        </div>
    </ng-template>
  <div class="content" *nzModalContent>
  <div class="card border-0" style="border-radius: 7px 7px 0px 0px;">
        <div class="card-head border-bottom pr-0 mr-0">
          <div nz-row>
            <div nz-col nzSpan="12" class="p-2 d-flex align-items-center">
              <h3 class="Card-Title-Set famly-inn ps-2">Appointment Scheduling</h3>
            </div>
            <div nz-col nzSpan="12" class="d-flex justify-content-end align-items-center">

            </div>
          </div>
        </div>
        <div class="card-body">
          <div nz-row>
            <div nz-col nzSpan="24">
              <label class="para-lable-selection famly-inn">Assigned to<span class="text-danger">*</span></label>
            </div>
        </div>
        <div nz-row class="Test mb-2">
            <div nz-col nzSpan="10">
              <nz-select
                [nzCustomTemplate]="defaultTemplate"
                nzShowSearch
                [(ngModel)]="test"
                [compareWith]="compareFn"
                (ngModelChange)="onAssigneeChange($event)">
                <nz-option nzValue="me" nzLabel="Me"></nz-option>
                <ng-container *ngFor="let group of selectedUsers">
                  <nz-option-group [nzLabel]="group.groupName">
                    <ng-container *ngFor="let user of group.users">
                      <nz-option
                        [nzValue]="{ id: user.id, groupName: group.groupName }"
                        [nzLabel]="user.name"
                      >
                        <span class="color-name">{{ user.name }}</span>
                      </nz-option>
                    </ng-container>
                  </nz-option-group>
                </ng-container>
              </nz-select>
              <ng-template #defaultTemplate let-selected>
                <div class="color-item d-flex align-items-center">
                  <nz-avatar 
                    [nzText]="initials"
                    style="color:#f56a00; background-color:#fde3cf;">
                  </nz-avatar>
                  <span class="color-name famly-inn">{{ selected.nzLabel }}</span>
                </div>
              </ng-template>
            </div>
          </div>
          <div nz-row >
            <div nz-col nzSpan="24">
              <label class="para-lable-selection famly-inn">Service on<span class="text-danger">*</span></label>
            </div>
        </div>
        <div nz-row class="mb-1">
            <div nz-col nzSpan="4">
                <nz-date-picker nzPlaceHolder=" " [(ngModel)]="appointmentData['serviceOn']" name="serviceOn"
                    nzSuffixIcon="false" (ngModelChange)="updateCheckboxState($event)"></nz-date-picker>
            </div>
        </div>
        <div nz-row >
          <div nz-col nzSpan="24">
            <label class="para-lable-selection famly-inn">Start Time </label>
          </div>
      </div>
        <div nz-row class="mb-1">
            <div nz-col nzSpan="4">
              <nz-select 
               style="width: 137px;"
               [(ngModel)]="appointmentData['startTime']" name="startTime">
                 <nz-option
                     *ngFor="let time of timeOptions"
                     [nzLabel]="time"
                     [nzValue]="time">
                 </nz-option>
             </nz-select>
                 
            </div>
        </div>
        <div nz-row >
          <div nz-col nzSpan="24">
            <label class="para-lable-selection famly-inn">End Time</label>
          </div>
      </div>
        <div nz-row>
            <div nz-col nzSpan="4">
              <nz-select 
               style="width: 137px;"
               [(ngModel)]="appointmentData['endTime']" name="endTime">
                 <nz-option
                     *ngFor="let time of timeOptions"
                     [nzLabel]="time"
                     [nzValue]="time">
                 </nz-option>
             </nz-select>
            </div>
        </div>
        </div>
  </div>  
  <div class="card mb-3 border-0" style="border-radius: 0px 0px 7px 7px;">
        <div class="card-body" style="border-top: solid #f1f4fa 2px;">
          <div nz-row >
            <div nz-col nzSpan="24">
              <label class="para-lable-selection famly-inn">Internal Appointment Notes</label>
            </div>
          </div>
          <div nz-row>
            <div nz-col nzSpan="24">
              <textarea 
              [(ngModel)]="appointmentData['internalNotes']"
              name="internalNotes"
              nz-input [nzAutosize]="{ minRows: 2, maxRows: 12 }"
              class="formField" style="resize: none;"
              [ngClass]="{'border-danger': appointmentData['internalNotes']?.length > 512}"
              required>
              </textarea>
              <!-- Dynamic over character message -->
              <div *ngIf="appointmentData['internalNotes']?.length > 512" class="text-danger mt-1">
                You have exceeded the character limit by {{ appointmentData['internalNotes'].length - 512 }} characters!
              </div>
              <!-- Remaining characters message -->
              <div *ngIf="appointmentData['internalNotes']?.length >= 412 && appointmentData['internalNotes']?.length <= 512" class="text-info mt-1">
                {{ 512 - appointmentData['internalNotes'].length }} characters remaining.
              </div>
            </div>
          </div>
        </div>
  </div>  
  <div class="card mb-3 border-0">
        <div class="card-head border-bottom pr-0 mr-0">
          <div nz-row>
            <div nz-col nzSpan="12" class="p-2 d-flex align-items-center">
              <h3 class="Card-Title-Set famly-inn ps-2">Attachments</h3>
            </div>
            <div nz-col nzSpan="12" class="d-flex justify-content-end align-items-center">
            </div>
          </div>
        </div>
        <div class="card-body">
          <div nz-row>
            <div nz-col nzSpan="24">
              <app-create-attachments-common [TestAttachment__]="TestAttachment" (filesUploaded)="onFilesUploaded($event)"></app-create-attachments-common>
            </div>
          </div>
        </div>
  </div>  
  <div class="card mb-3 border-0">
        <div class="card-head border-bottom pr-0 mr-0">
          <div nz-row>
            <div nz-col nzSpan="12" class="p-2 d-flex align-items-center">
              <h3 class="Card-Title-Set famly-inn ps-2">Appointment Status</h3>
            </div>
            <div nz-col nzSpan="12" class="d-flex justify-content-end align-items-center">
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="warrantyResponse || responseHeaderId > 0 ||!isformSave ">
          <div nz-row *ngIf="!savedResponseData">
            <div nz-col nzSpan="24">
              <label nz-checkbox [(ngModel)]="checked2" *ngIf=" !isformSaved ">
                <span class="selectionCheckBoxSelection famly-inn">
                  Submit this appointment to OWNER for acceptance?
                </span>
                <i class="bi bi-info-circle-fill text-secondary ms-1" 
                nz-popover
                [nzPopoverContent]="Daiyly" 
                nzPopoverPlacement="top">
                </i>
                <ng-template #Daiyly>
                    <div class="iconPopoverSetted">
                        <span>
                          Checking this box will notify the
                          Owner of this appointment time. 
                          They will be prompted to accept 
                          or decline it in PersistentSolutions.
                        </span>
                    </div>
                </ng-template>
            </label>
            </div>
          </div>
          <div nz-row [nzGutter]="16" *ngIf="savedResponseData">
            <div nz-col nzSpan="6" *ngIf="WarrantyResponse">
              <p class="para-lable-selection">Owner</p>
            </div>
            <div nz-col nzSpan="6">
              <td>
                <i class="bi bi-house-door"></i>
                <span class="fw-bold text-secondary"> Never Accepted </span>
                <p class="fw-bold">
              </td>
            </div>
          </div>
          <div nz-row [nzGutter]="16" *ngIf="savedResponseData">
            <div nz-col nzSpan="6">
              <p class="para-lable-selection">Builder</p>
            </div>
            <div nz-col nzSpan="6">
              <p>
                <i class="bi bi-people"></i>
                <span class="fw-bold" style="color: #52A300" [(ngModel)]="appointmentData['isBuilderAccepted']"
                name="isBuilderAccepted"> Internally Accepted</span> -
                {{ savedResponseData?.serviceOn | date: 'MMM d, yyyy'   }} ,{{ savedResponseData?.serviceOn | date: 'h:mm a' }}
              </p>
            </div>
          </div>
        </div>
  </div>  
  <div class="card mb-3 border-0">
        <div class="card-head border-bottom pr-0 mr-0">
          <div nz-row>
            <div nz-col nzSpan="12" class="p-2 d-flex align-items-center">
              <h3 class="Card-Title-Set famly-inn ps-2">Final Work Approval</h3>
            </div>
            <div nz-col nzSpan="12" class="d-flex justify-content-end align-items-center">
            </div>
          </div>
        </div>
        <div class="card-body">
          <div nz-row>
            <div nz-col nzSpan="24">
              <label nz-checkbox [(ngModel)]="checked" >
                <span class="selectionCheckBoxSelection famly-inn">
                  Builder Override
                </span>
              </label>
            </div>
          </div>
          <div nz-row >
            <div nz-col nzSpan="24">
              <label class="para-lable-selection famly-inn">
                Owner Feedback 
                <i class="bi bi-info-circle-fill text-secondary" 
                   nz-popover
                   [nzPopoverContent]="contentTemplate"  
                   nzPopoverPlacement="top">
                </i>
                <ng-template #contentTemplate>
                  <div class="iconPopoverSetted">
                    <span>
                      Feedback will be available after the appointment date
                    </span>
                </div>
                </ng-template>
              </label>
            </div>
          </div>
          <div nz-row class="mt-1 mb-1" *ngIf="!checked">
            <div nz-col nzSpan="24">
              <span class="selectionCheckBoxSelection famly-inn">Not Submitted</span>
            </div>
          </div>
          <div nz-row *ngIf="checked">
            <div nz-col nzSpan="5">
            <nz-select
              [(ngModel)]="appointmentData['ownerFeedbackId']"
              name="ownerFeedbackId"
              (ngModelChange)="onFeedbackChange()"
              [ngClass]="{'border-danger': showError}">
              <nz-option
                *ngFor="let option of ownerFeedbackOption"
                [nzLabel]="option.name"
                [nzValue]="option.id">
              </nz-option>
            </nz-select>
            <div *ngIf="showError" class="text-danger">Owner Feedback is required.</div>
            </div>
          </div>
          
          <div nz-row [nzGutter]="6" *ngIf="(showCompletionFields || showMeetwarrantyFields) && !hideForNeedsReworkFields && !hideReadyForNextPhaseFields">
            <div nz-col nzSpan="3">
                <label class="para-lable-selection">Completion Date </label>
                <nz-date-picker
                  [disabled]="isFormSaved3 || !checked"
                  [(ngModel)]="appointmentData['completionOn']" name="completionOn" nzPlaceHolder=" "
                  nzSuffixIcon="false"></nz-date-picker>
            </div>
            <div nz-col nzSpan="3">
              <label class="para-lable-selection">Time </label>
              <nz-select 
               style="width: 137px;"
               [disabled]="isFormSaved3 || !checked"
               [(ngModel)]="appointmentData['completionTime']" name="completionTime">
                 <nz-option
                     *ngFor="let time of timeOptions"
                     [nzLabel]="time"
                     [nzValue]="time">
                 </nz-option>
             </nz-select>
            </div>
        </div>
        <div nz-row class="mt-1">
            <div nz-col nzSpan="24">
                <label class="para-lable-selection">Appointment Notes</label>
            </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <textarea 
              [(ngModel)]="appointmentData['appointmentNotes']"
              name="appointmentNotes"
              [disabled]="isFormSaved3 || !checked"
              nz-input [nzAutosize]="{ minRows: 2, maxRows: 12 }"
              class="formField" style="resize: none;"
              [ngClass]="{'border-danger': appointmentData['internalNotes']?.length > 512}"
              required>
            </textarea>
            <div *ngIf="appointmentData['appointmentNotes']?.length > 512" class="text-danger mt-1">
              You have exceeded the character limit by {{ appointmentData['appointmentNotes'].length - 512 }} characters!
            </div>
            <div *ngIf="appointmentData['appointmentNotes']?.length >= 412 && appointmentData['appointmentNotes']?.length <= 512" class="text-info mt-1">
              {{ 512 - appointmentData['appointmentNotes'].length }} characters remaining.
            </div>
          </div>
        </div>
      <div nz-row>
          <div nz-col nzSpan="12">
              <label class="para-lable-selection">Feedback Left on</label>
          </div>
      </div>
      <div nz-row>
          <div nz-col nzSpan="12">
              <span *ngIf="!isfeedbackData || !savedResponseData">N/A</span>
              <span *ngIf="isfeedbackData && savedResponseData">
                {{ savedResponseData.serviceOn | date: 'MMM d, yyyy' }}, {{ savedResponseData.serviceOn | date: 'h:mm a' }}
              </span>
            </div>
      </div>
    </div>
  </div>      
  </div>
<ng-template #CWarranty>
        <div nz-row [nzGutter]="16">
            <div nz-col [nzSpan]="8">
              <div class="text-start  justify-content-start align-items-start" *ngIf="warrantiesResponse">
                <span *ngIf="footerResponse">
                <app-footer-label [footerData]="footerResponse"></app-footer-label>
                </span>
              </div>
            </div>
            <div nz-col [nzSpan]="16">
              <nz-space>
                <button
                  nz-button
                  nzType="default"
                  *nzSpaceItem
                  (click)="ConfirmDelete()"
                  >
                  Delete
                </button>
                <button
                  nz-button
                  nzType="primary"
                  *nzSpaceItem
                  [nzLoading]="isSave"
                  (click)="savewarrantyserviceform()">
                  Save
                </button>
                <button
                  nz-button
                  nzType="primary"
                  *nzSpaceItem
                  [nzLoading]="isSaveAndClose"
                  (click)="saveAndClosewarrantyserviceform()">
                  Save & Close
                </button>
               </nz-space>
            </div>
          </div>
</ng-template>
</nz-modal>
