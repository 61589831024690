<nz-modal
nzWidth="35%"
[(nzVisible)]="customfieldinfo"
[nzTitle]="modalTitle1"
[nzFooter]="modalFooter1"
(nzOnCancel)="handleCancel2()"
[nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '75vh', 'overflow-y': 'auto',}"
[nzStyle]="{ top: '15px' }">
  <ng-template #modalTitle1>
    <div class="row p-0 m-0">
        <h1 class="p-0 famly-inn" >To-Do's Custom Field</h1>
     </div>
  </ng-template>
  <div class="content" *nzModalContent>
      <div class="card border-0 p-0">
              <div class="card-head border-bottom">
                <div class="row py-2">
                    <h3 nz-typography><span class="ms-3">Custom Field Info</span></h3>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                          <div class="col">
                          <label class="sub-text">
                            Label
                              <span class="text-danger fw-bolder">*</span>
                          </label>
                          <input
                          formControlName="name"
                          type="text"
                          class="form-control formField">
                      </div>
                </div>
                <div class="row">
                    <div class="col">
                    <label class="sub-text">
                        Data Type
                        <span class="text-danger fw-bolder">*</span>
                    </label>
                    <nz-select   nzShowSearch formControlName="jobInformationTypeParameterId">
                        <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                        <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                        <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                      </nz-select>
                      </div>
                </div>
                <div class="row">
                    <div class="col">
                    <label class="sub-text">Tool Tip Text</label>
                    <textarea rows="2" nz-input ></textarea>
                    </div>
                </div>
                <div class="row mt-3">
                          <div class=" col-9 d-flex align-items-center">
                      <label nz-checkbox>Required?</label>
                          </div>
                </div>
                <div class="row mt-3">
                          <div class=" col-9 d-flex align-items-center">
                      <label nz-checkbox> Include In My Filters</label>
                          </div>
                </div>
                <div class="row mt-3" style="margin-bottom: 30px;">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-12">
                                <label class="sub-text">Display Order <span class="text-danger fw-bolder">*</span></label>
                                <nz-input-number class="custom-select" [nzMin]="1" [nzMax]="10" [nzStep]="1" type="number"></nz-input-number>
                              </div>
                            </div>
                          </div>
                </div>
                <nz-divider></nz-divider>
                <div class="row mt-4 mb-3">
                  <div class=" col-12 ">
                      <label nz-checkbox>Show Owners?</label>
                  </div>
                </div>
                <nz-divider ></nz-divider>
                <div class="row mt-3 mb-3">
                  <div class=" col-12 ">
                      <label nz-checkbox>Allow permitted sub/vendor to view?</label>
                  </div>
                </div>



              </div>
          </div>
</div>
  <ng-template #modalFooter1>
      <div class="mt-2 align-items-center">

          <button nz-button  class="button-Daily  rounded-1 me-2">Save</button>
      </div>

    </ng-template>
</nz-modal>