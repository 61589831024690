import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportsComponent } from './components/reports/reports.component';
import { SalesreportDetailComponent } from './components/reports/salesreport-detail/salesreport-detail.component';
import { ProjectManagmentDetailComponent } from './components/reports/project-managment-detail/project-managment-detail.component';
import { FinancialReportsDetailComponent } from './components/reports/financial-reports-detail/financial-reports-detail.component';

const routes: Routes = [
  { path: '', redirectTo: 'reports', pathMatch: 'full' },
  { path: 'reports', component: ReportsComponent },
  { path: 'salesDetailReports', component: SalesreportDetailComponent },
  { path: 'ProjectManagmentReports', component: ProjectManagmentDetailComponent },
  { path: 'FinancialReports', component: FinancialReportsDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
