<button
class="d-flex justify-content-center align-items-center"
nz-button
nzType="text"
class="text-primary border-0"
>

<span
  class="fs-5"
  nz-icon
  nzType="plus-circle"
  nzTheme="fill"
></span>
<span>{{ buttonText }}</span>
</button>