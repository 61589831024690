import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'src/environments/environment.prod';
import { ResponseModel, TablePageResponse } from 'src/Models/responseMessage.model';
import { CreateLookupFilterRequest, FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';
import { MessagingResponse } from 'src/Models/ChangeOrder/ChangeOrder';
import { MessagingNotificationHistoryFilterFiledComponent } from 'src/app/shared/component/filter/messaging-notification-history-filter-filed/messaging-notification-history-filter-filed.component';


@Injectable({
  providedIn: 'root'
})
export class MessagingsCommentsService {

  private base_Url: string = environment.apiUrl + 'LookupFilter/';
  getCommentsData = new BehaviorSubject<ResponseModel<TablePageResponse<MessagingResponse>>>(null);
  $getComments = this.getCommentsData.asObservable();

  // getUserData = new BehaviorSubject<ResponseModel<TablePageResponse<>>>(null);
  // $getInternalUsers = this.getUserData.asObservable();

  selectedFilterId = new BehaviorSubject<number>(0);
  isUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $isUserAppliedFilter = this.isUserAppliedFilter.asObservable();

  constructor(private http: HttpClient) { }

  getCOM(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter)?.subscribe(
      (data) => {
        this.getCommentsData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  getAppliedFilterData(appliedFilter: CreateLookupFilterRequest[]) {
    const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
    return this.http.post<any>(apiUrl, appliedFilter).subscribe(
      (data) => {
        this.getCommentsData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  setSelectedFilterId(filterId: number) {
    this.selectedFilterId.next(filterId);
  }
  userAppliedFilter(filter: boolean) {
    this.isUserAppliedFilter.next(filter);
  }

  // getAllSiteDiariesByFilter(filterSearchParameter: FilterSearchParameter) {
  //   const apiUrl = `${this.base_Url}GetAll-ByFilter`;
  //   return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
  //     (data) => {
  //       this.getSiteDiariesData.next(data);
  //     },
  //     (error) => {
  //       console.error('Error fetching internal users:', error);
  //     }
  //   );
  // }
  
}