import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { ApiResponseModel } from 'src/Models/apiResponse.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class WeeklyEmailReminderDaySetupService {


  private baseUrl: string = environment.apiUrl + 'WeeklyEmailReminderDaySetup/';


  constructor(private http: HttpClient) {}

  postData(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl, data).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  

  getData(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}getAll`);
  }


  getDataColumns(): Observable<ApiResponseModel> {
    return this.http.get<ApiResponseModel>(`${this.baseUrl}getAll-Columns`);
  }
  getDataByParameter(columnName: string, searchParameter: string, contactTypeIds: number[]): Observable<ApiResponseModel> {
    const data = {
      columnName: columnName,
      searchParameter: searchParameter,
      contactTypeIds: contactTypeIds
    };

    return this.http.post<ApiResponseModel>(`${this.baseUrl}getAll-byParameter`, data)
      .pipe(
        catchError((error: any) => {
          console.error('Error:', error);
          return throwError(error);
        })
      );
  }

  updateData(data: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}`, data);
  }


  deleteData(globalId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}?globalId=${globalId}`);
  }

  getDataId(id: string): Observable<ApiResponseModel> {
    return this.http.get<ApiResponseModel>(`${this.baseUrl}getBy-Id?id=${id}`);
  }

  deleteDataByBookingId(bookingId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}?bookingId=${bookingId}`);
  }
}
