<nz-layout>
  <nz-sider class="border" nzCollapsible [nzCollapsedWidth]="10" [(nzCollapsed)]="isCollapsed" [nzWidth]="280"
    [nzTrigger]="null">
    <div class="row">
      <app-side-menu-bar [isAllJobOption]="true" (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"></app-side-menu-bar>
    </div>
  </nz-sider>

  <nz-layout>
    <nz-page-header>
      <nz-page-header-title>
        <nz-breadcrumb class="text-start" nz-page-header-breadcrumb>
          <nz-breadcrumb-item>{{localStorageService.getJobName()}}</nz-breadcrumb-item>

        </nz-breadcrumb>
        <h1>Change Order</h1>
      </nz-page-header-title>
      <nz-page-header-extra>
        <nz-space>
          <button nz-button nzType="default" class="ms-2 rounded-1">
            Export
          </button>
          <button nz-button nzType="default" class="ms-2 rounded-1"
            (click)="openFilter(PROJECT_MANG_VARIATION, PAGE_ID)">
            <i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter
          </button>
          <button (click)="selectionModelOpen('ChangeOrder')" nz-button nzType="primary" class="ms-2 button-Daily">
            New Change Order
          </button>
        </nz-space>
      </nz-page-header-extra>
    </nz-page-header>

    <nz-content class="custom-content">
      <div class="container-fuild mb-4 m-3"></div>


      <ng-container *ngIf="GetProjectVariationgriddata?.length > 0">
        <kendo-grid (columnResize)="saveColumnSizes($event)" [data]="GetProjectVariationgriddata" [pageSize]="5"
          [sortable]="true" [resizable]="true" [reorderable]="true">
          <kendo-grid-column *ngFor="let column of displayedColumns; let i = index" [title]="column" [field]="column"
            [minResizableWidth]="150" [maxResizableWidth]="800" [width]="getColumnWidthSize[i]"
            [sticky]="isColumnSticky[i]" [headerClass]="'pinned-header'">
            <ng-template kendoGridHeaderTemplate let-columnIndex="columnIndex">
              <div class="d-flex align-items-center">
                <span>{{ column }}</span>
                <button (click)="toggleColumnSticky(columnIndex)" class="btn btn-link">
                  <i class="bi" [ngClass]="{
                                'bi-pin-fill': isColumnSticky[columnIndex],
                                'bi-pin': !isColumnSticky[columnIndex]
                              }"></i>
                </button>
              </div>
            </ng-template>
            <ng-template kendoGridColumnMenuTemplate let-columnIndex="columnIndex">
              <button (click)="toggleColumnSticky(columnIndex)" class="btn btn-link">
                <i class="bi" [ngClass]="{
                              'bi-pin-fill': isColumnSticky[columnIndex],
                              'bi-pin': !isColumnSticky[columnIndex]
                            }"></i>
              </button>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <ng-container *ngIf="column === 'CO ID#'">
                <a class="text-decoration-none  blue-text" (click)="EditInternalUser(dataItem)">{{ dataItem?.title }} </a>
              </ng-container>
              <ng-container *ngIf="column === 'Title'">{{ dataItem?.rfiFullNumber }}</ng-container>

              <ng-container *ngIf="column === 'RFIs'">{{ dataItem?.rfi?.rfiInformations.length }}</ng-container>
              <ng-container *ngIf="column === 'Comments'">

              </ng-container>
              <ng-container *ngIf="column === 'Owner'">{{ dataItem.isAutoAccess }}</ng-container>

              <ng-container *ngIf="column === 'Email'">
                <span>{{ dataItem.email }} </span>
                <span *ngFor="let email of dataItem.applicationUserEmailParameters; let last = last">
                  <span *ngIf="!last && isExpanded(rowIndex)">{{ email?.email }}, </span>
                  <span *ngIf="last && isExpanded(rowIndex)">{{ email?.email }}</span>
                  <span style="cursor: pointer;"
                    *ngIf="last && !isExpanded(rowIndex) && dataItem.applicationUserEmailParameters.length > 0"
                    (click)="toggleAdditionalEmails(rowIndex)">...</span>
                </span>
              </ng-container>
              <ng-container *ngIf="column === 'Added'">
                <a class="text-decoration-none">{{ dataItem?.createdOn }}</a>
              </ng-container>

              <ng-container *ngIf="column === 'Files'">
                <ng-container *ngFor="let attachmentParam of dataItem?.attachment?.attachmentParameters; let i = index">
                  <button nz-button nz-dropdown class="border-0" [nzDropdownMenu]="menu4">
                    <svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                      <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path>
                    </svg>
                    <span nz-icon nzType="down"></span>
                  </button>
                  <nz-dropdown-menu #menu4="nzDropdownMenu">
                    <ul nz-menu>
                      <!-- Include SVG icon, attachmentParam name, and the number of attachments in the dropdown menu -->
                      <li nz-menu-item>
                        <svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                          <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path>
                        </svg>
                        {{ attachmentParam?.name }}
                        <span *ngIf="i === dataItem?.attachment?.attachmentParameters.length - 1">{{ dataItem?.attachment?.attachmentParameters.length }}</span>
                      </li>
                    </ul>
                  </nz-dropdown-menu>
                </ng-container>
              </ng-container>











              <ng-container *ngIf="column === 'Builder Cost'">
                <a class="text-decoration-none">{{ dataItem?.builderCost }}</a>
              </ng-container>
              <ng-container *ngIf="column === 'Owner Price'">
                <a class="text-decoration-none">{{ dataItem?.ownerPrice }}</a>
              </ng-container>
              <ng-container *ngIf="column === 'Approval Status'">
                <!-- Replace 'phoneNumber' with the appropriate property containing approval status -->
                <ng-container *ngIf="dataItem.approvalStatus; else pendingInvite">
                  <nz-tag class="active" [nzColor]="'#c4fae2'">APPROVE</nz-tag>
                </ng-container>
                <ng-template #pendingInvite>
                  <nz-tag class="inactive" [nzColor]="'#fbe9b1'">DECLINED</nz-tag>
                </ng-template>
                <a class="text-decoration-none">{{ dataItem?.approvalStatus }}</a>
              </ng-container>
              <ng-container *ngIf="column === 'Approval Date'">
                <!-- Replace 'phoneNumber' with the appropriate property containing approval date -->
                <a class="text-decoration-none">{{ dataItem?.approvalDate }}</a>
              </ng-container>
              <ng-container *ngIf="column === 'Related POs'">
                <a class="text-decoration-none  blue-text">
                  <span  style="background-color: blue; color: white; "  nz-icon nzType="plus-circle" nzTheme="outline"></span>
                  {{ dataItem?.relatedPOs }}
                </a>
                <a class="text-decoration-none  blue-text" >add Purchase Order</a>
              </ng-container>

              <ng-container *ngIf="column === 'Creator'">
                <!-- Replace 'phoneNumber' with the appropriate property containing creator information -->
                <a class="text-decoration-none">{{ dataItem?.creator }}</a>
              </ng-container>
              <ng-container *ngIf="column === 'Deadline'">
                <a class="text-decoration-none">{{ dataItem?.approvalDeadlineOn }}</a>
              </ng-container>
              <ng-container *ngIf="column === 'Owner Last Viewed'">
                <!-- Replace 'phoneNumber' with the appropriate property containing owner last viewed information -->
                <a class="text-decoration-none">{{ dataItem?.ownerLastViewed }}</a>
              </ng-container>
              <ng-container *ngIf="column === 'PO Builder Variance'">
                <!-- Replace 'phoneNumber' with the appropriate property containing PO builder variance -->
                <a class="text-decoration-none">{{ dataItem?.poBuilderVariance }}</a>
              </ng-container>
              <ng-container *ngIf="column === 'PO Customer Variance'">
                <!-- Replace 'phoneNumber' with the appropriate property containing PO customer variance -->
                <a class="text-decoration-none">{{ dataItem?.poCustomerVariance }}</a>
              </ng-container>
              <ng-container *ngIf="column === 'Related Bid Packages'">
                <!-- Replace 'phoneNumber' with the appropriate property containing related bid packages -->
                <a class="text-decoration-none">{{ dataItem?.relatedBidPackages }}</a>
              </ng-container>
              <ng-container *ngIf="column === 'Related Invoices'">
                <!-- Replace 'phoneNumber' with the appropriate property containing related invoices -->
                <a class="text-decoration-none">{{ dataItem?.relatedInvoices }}</a>
              </ng-container>
              <ng-container *ngIf="column === 'Sub/Vendors'">
                <!-- Replace 'phoneNumber' with the appropriate property containing sub/vendors information -->
                <a class="text-decoration-none">{{ dataItem?.subVendors }}</a>
              </ng-container>
              <ng-container *ngIf="column === 'Total Markup'">
                <!-- Replace 'phoneNumber' with the appropriate property containing total markup -->
                <a class="text-decoration-none">{{ dataItem?.totalMarkup }}</a>
              </ng-container>


            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </ng-container>

      <ng-container *ngIf="GetProjectVariationgriddata?.length === 0">
        <div class="row mt-5 mb-0"
          style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
          <div class="col" style="text-align: center;">
            <span >
              <svg width="30em" height="5em" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true" focusable="false" class="">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M21.91 3.004L22 3L22.0752 3.00279L22.2007 3.02024L22.3121 3.04974L22.4232 3.09367L22.5207 3.146L22.6254 3.21969L22.7071 3.29289L26.7071 7.29289C27.0976 7.68342 27.0976 8.31658 26.7071 8.70711C26.3466 9.06759 25.7794 9.09532 25.3871 8.7903L25.2929 8.70711L23 6.415V10.0984C23 12.1687 21.9333 14.0864 20.1893 15.1805L19.9768 15.3078L11.0154 20.4286C9.83474 21.1033 9.08228 22.3284 9.00635 23.676L9 23.9016V28C9 28.5523 8.55228 29 8 29C7.48716 29 7.06449 28.614 7.00673 28.1166L7 28V23.9016C7 21.8313 8.06668 19.9136 9.81066 18.8195L10.0232 18.6922L18.9846 13.5714C20.1653 12.8967 20.9177 11.6716 20.9937 10.324L21 10.0984V6.415L18.7071 8.70711C18.3466 9.06759 17.7794 9.09532 17.3871 8.7903L17.2929 8.70711C16.9324 8.34662 16.9047 7.77939 17.2097 7.3871L17.2929 7.29289L21.2929 3.29289C21.3283 3.2575 21.3657 3.22531 21.4047 3.19633L21.5159 3.12467L21.6287 3.07123L21.734 3.03585L21.8515 3.01102L21.91 3.004ZM22 19C22.5128 19 22.9355 19.386 22.9933 19.8834L23 20V28C23 28.5523 22.5523 29 22 29C21.4872 29 21.0645 28.614 21.0067 28.1166L21 28V20C21 19.4477 21.4477 19 22 19ZM8.99327 4.88338C8.93551 4.38604 8.51284 4 8 4C7.44772 4 7 4.44772 7 5V15L7.00673 15.1166C7.06449 15.614 7.48716 16 8 16C8.55228 16 9 15.5523 9 15V5L8.99327 4.88338Z">
                </path>
              </svg>

            </span>
            <div class="row">
              <div class="col mt-2">
                <h1>Take control of the unexpected with</h1>

                <h1 class="p-0">Change Orders</h1>
              </div>
            </div>
            <div class="col mt-3">
              <p>Manage time, cost, and other project changes more efficiently with Change Orders.</p>
            </div>
          </div>
          <div class="col mt-4" style="display: flex; justify-content: center; align-items: center">
            <button nz-button nzType="default" style="margin-right: 10px">
              <i class="bi bi-box-arrow-up-right me-2"></i>Learn How
            </button>
            <div>
              <button nz-button nzType="primary" class="ms-2 button-Daily">
                Add a Change Order
              </button>
            </div>
          </div>

        </div>
      </ng-container>

    </nz-content>


  </nz-layout>




</nz-layout>



<nz-footer class="custom-footer">
  <div class="container-fluid ">
    <div class="row d-flex justify-content-between align-items-center  ">
      <div class="row mb-0 mt-2">
        <div class="col">
          <nz-pagination style="float: right;" [nzPageIndex]="currentPage" [nzTotal]="totalRecords"
            [nzPageSize]="pageSize" nzShowSizeChanger (nzPageIndexChange)="onPageChange($event)"
            (nzPageSizeChange)="onPageSizeChange($event)" [nzPageSizeOptions]="[10, 20, 50, 75, 100]"></nz-pagination>

          <ng-container *ngIf="PAGE_ID">
            <div class="row d-flex justify-content-start mb-1 ">
              <!-- <div class="col-md-3">
                <nz-select (ngModelChange)="onFilterChange($event)" [(ngModel)]="selectedValueControl" nzShowSearch
                  nzAutoFocus="true">
                  <nz-option *ngFor="let standard of getStandardViewFilterData" [nzLabel]="standard.name"
                    [nzValue]="standard.id"></nz-option>
                </nz-select>

              </div> -->

              <div class="col-md-3">
                <div class="input-group">
                  <nz-select
                  [(ngModel)]="selectedValueControl"
                    name="standardFilter"
                    (ngModelChange)="onFilterChange($event)"
                    nzShowSearch
                    [nzAutoFocus]="true"
                    nzPlaceHolder="Standard Filter"
                    >
                    <nz-option  *ngFor="let standard of getStandardViewFilterData"
                      [nzLabel]="standard.name + (standard.isSetAsDefault ? ' ' : '')"
                      [nzValue]="standard.id"
                      nzCustomContent>
                      <div class="option-content">
                        <!-- Customize content with icons and text -->
                        <span *ngIf="standard.icon" nz-icon [nzType]="standard.icon"></span>
                        <span>{{ standard.name }}</span>
                      <span  *ngIf="standard.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

                      </div>
                    </nz-option>
                  </nz-select>
                </div>
              </div>

              <div class="col-md-1">
                <button nz-button class="border-0"
                nz-popover
           [nzPopoverContent]="contentTemplate"
           [nzPopoverPlacement]="'top'"

                 (click)="openStandardViewFilterComponent()">

                  <span nz-popover class="text-primary text-dark fw-bold fs-5" nz-icon nzType="ellipsis"
                    nzTheme="outline"></span>
                </button>
              </div>
              <div class="col-md-2">
                <div class="mt-2" *ngIf="selectedFilter === 1">
                  <p>Your filter has unsaved changes.</p>
                </div>
                <div class="mt-2" *ngIf="selectedFilter === 0">
                  <p>Your filter has unsaved changes.</p>
                </div>
              </div>

              <div class="col-md-3">
                <button (click)="openStandardViewFilterComponent('saveAs')" type="button" nz-button nzType="default"
                  class="me-2" style="font-size: 12px;" *ngIf="selectedFilter === 1">Save As</button>

                <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;"
                  *ngIf="selectedFilter === 1" type="button">Reset</button>

                <button (click)="saveEditStandardView()" nz-button nzType="primary" type="button" class="me-2"
                  style="font-size: 12px;" *ngIf="selectedFilter === 0">Save</button>

                <button (click)="openStandardViewFilterComponent('saveAs')" nz-button nzType="default" class="me-2"
                  style="font-size: 12px;" *ngIf="selectedFilter === 0" type="button">Save As</button>

                <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;"
                  *ngIf="selectedFilter === 0" type="button">Reset</button>

              </div>
            </div>
          </ng-container>

          <div class="col d-flex mt-3">


          </div>

        </div>
      </div>
    </div>
  </div>

</nz-footer>

<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>
