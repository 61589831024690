import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzFormatBeforeDropEvent, NzFormatEmitEvent } from 'ng-zorro-antd/tree/public-api';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CostCodeCostCategoryComponent } from '../cost-code-cost-category/cost-code-cost-category.component';
import { CosCodeModelComponent } from '../cos-code-model/cos-code-model.component';
import { VarianceCategoryComponent } from '../VarianceCategory/variance-category/variance-category.component';
import { VarianceCodeComponent } from '../variance-code/variance-code/variance-code.component';
@Component({
  selector: 'app-cost-code',
  templateUrl: './cost-code.component.html',
  styleUrls: ['./cost-code.component.css']
})
export class CostCodeComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  checked = true;
  isCollapsed = false;
  checkeds = true;
  checkeds1 = true;
  catalog = true;
  selectedValue = null;
  categorycost: boolean = false;


  constructor(private modal: NzModalService) { }

  ngOnInit(): void { }


  CostCodeCancel(): void {
    this.cancel.emit();
  }

  nzEvent($event: NzFormatEmitEvent) {
    throw new Error('Method not implemented.');
  }
  title = 'Client';
  costcodes: boolean = false;
  checkedKeys = [];

  log(): void {
    console.log('click dropdown button');
  }


  beforeDrop(arg: NzFormatBeforeDropEvent): Observable<boolean> {
    // if insert node into another node, wait 1s
    if (arg.pos === 0) {
      return of(true).pipe(delay(1000));
    } else {
      return of(false);
    }
  }

  onCheck(event: any): void {
    console.log('Checked keys:', event.checkedKeys);
    this.checkedKeys = event.checkedKeys;
  }

  autoAdjustTextAreaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
    textarea.style.height = `${Math.min(textarea.scrollHeight, 190)}px`; // Set the height with a maximum of 200px
  }
  category() {
    this.categorycost = true;
  }
  categoryclose() {
    this.categorycost = false;
  }
  switchValue = false;
  isSectionVisible = true;

  toggleSection() {
    this.isSectionVisible = !this.isSectionVisible;
  }
  isSectionVisible1 = true;

  toggleSection1() {
    this.isSectionVisible1 = !this.isSectionVisible1;
  }

  newvariancecategory: boolean = false;

  NewVariancecategory() {
    this.newvariancecategory = true;
  }
  CloseNewVariancecategory() {
    this.newvariancecategory = false;
  }
  newnostcode: boolean = false;

  NewCostCode() {
    this.newnostcode = true;
  }
  CloseNewCostCode() {
    this.newnostcode = false;
  }
  newvariancecode: boolean = false;

    NewVarianceCode(){
      this.newvariancecode = true;
    }
    CloseNewVarianceCode(){
      this.newvariancecode = false;
    }
    NewCostcategory() {
      const modalRef = this.modal.create({
        nzContent: CostCodeCostCategoryComponent,
        nzFooter: null,
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
        
      });
    }
    NewCostCodes() {
      const modalRef = this.modal.create({
        nzContent: CosCodeModelComponent,
        nzFooter: null,
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
    }
    Variancecategory() {
      const modalRef = this.modal.create({
        nzContent: VarianceCategoryComponent,
        nzFooter: null,
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
    }
    VarianceCode() {
      const modalRef = this.modal.create({
        nzContent: VarianceCodeComponent,
        nzFooter: null,
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
    }
}
