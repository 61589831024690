<nz-layout>
  <nz-page-header>
    <nz-page-header-title>
      <h1>Subs/Vendors</h1>
    </nz-page-header-title>
    <nz-page-header-extra>
      <nz-space>
        <button *nzSpaceItem nz-button class="rounded-1 me-0" (click)="exportToExcel()"
        [nzLoading]="isLoadingOne">Export</button>
        <button
          (click)="openFilter(SUB_VENDOR, PAGE_ID)"
          *nzSpaceItem
          nz-button
          class="rounded-1 me-0"
        >
          <i class="bi bi-funnel pe-1"></i>Filter
        </button>
        <button
          *nzSpaceItem
          nz-button
          class="rounded-1 me-0"
          nzType="primary"
          (click)="showSubVendor()"
        >
          New Sub/Vendor
        </button>
      </nz-space>
    </nz-page-header-extra>
  </nz-page-header>

  <nz-content class="custom-content">
    <div class="container-fluid mb-5">
      <kendo-grid
        (columnResize)="saveColumnSizes($event)"
        [data]="displayedData"
        [pageSize]="5"
        [sortable]="true"
        [resizable]="true"
        [reorderable]="true"
      >
        <kendo-grid-column
          *ngFor="let column of displayedColumns; let i = index"
          [title]="column"
          [field]="column"
          [minResizableWidth]="150"
          [maxResizableWidth]="800"
          [width]="getColumnWidthSize[i]"
          [sticky]="isColumnSticky[i]"
          [headerClass]="'pinned-header'"
        >
          <ng-template kendoGridHeaderTemplate let-columnIndex="columnIndex">
            <div class="d-flex align-items-center">
              <span>{{ column }}</span>
              <button
                (click)="toggleColumnSticky(columnIndex)"
                class="btn btn-link"
              >
                <i
                  class="bi"
                  [ngClass]="{
                    'bi-pin-fill': isColumnSticky[columnIndex],
                    'bi-pin': !isColumnSticky[columnIndex]
                  }"
                ></i>
              </button>
            </div>
          </ng-template>
          <ng-template
            kendoGridColumnMenuTemplate
            let-columnIndex="columnIndex"
          >
            <button
              (click)="toggleColumnSticky(columnIndex)"
              class="btn btn-link"
            >
              <i
                class="bi"
                [ngClass]="{
                  'bi-pin-fill': isColumnSticky[columnIndex],
                  'bi-pin': !isColumnSticky[columnIndex]
                }"
              ></i>
            </button>
          </ng-template>
          <ng-template
            kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex"
          >
            <ng-container *ngIf="column === 'Company Name'">
              <a class="text-decoration-none text-primary"
              (click)="OnClickVendors(dataItem)"
                >{{ dataItem.companyName }}</a
              >
            </ng-container>
            <ng-container *ngIf="column === 'Sub/Vendor Divisions'">
              <a class="text-decoration-none text-primary"
                >{{ dataItem?.divisionTradeParameters?.divisionTrade?.name }}</a
              >
            </ng-container>
            <ng-container *ngIf="column === 'Phone'">
              <a class="text-decoration-none text-primary"
                >{{ dataItem.phone }}</a
              >
            </ng-container>
           
            <ng-container *ngIf="column === 'Primary Contact'">{{
              dataItem.primaryContactFirstLastName
            }}</ng-container>
            <ng-container *ngIf="column === 'Sub/Vendor Divisions'">{{
              dataItem?.divisionTradeParameters?.name
            }}</ng-container>
            
            <ng-container *ngIf="column === 'Fax'">{{
              dataItem.fax
            }}</ng-container>
            <ng-container *ngIf="column === 'Activation'">
              <ng-container *ngIf="dataItem.Activation; else pendingInvite">
                <nz-tag class="active" [nzColor]="'#c4fae2'">Active</nz-tag>
              </ng-container>
              <ng-template #pendingInvite>
                <nz-tag class="inactive" [nzColor]="'#fbe9b1'"
                  >INVITE PENDING</nz-tag
                >
              </ng-template>
            </ng-container>
            <ng-container *ngIf="column === 'Auto Access'">{{
              dataItem.isAutoAccess
            }}</ng-container>
          

<!-- 
            <ng-container *ngIf="column === 'Email'">
              <span>{{ dataItem.email }} </span>
              <ng-container *ngIf="dataItem.subVenderManageEmails?.length">
                <span *ngFor="let email of dataItem.subVenderManageEmails; let last = last">
                  <span *ngIf="isExpanded(rowIndex) || last">
                    {{ email?.email }}
                    <span *ngIf="!last && isExpanded(rowIndex)">, </span>
                  </span>
                </span>
                <span
                  *ngIf="!isExpanded(rowIndex) && dataItem.subVenderManageEmails.length > 0"
                  (click)="toggleAdditionalEmails(rowIndex)"
                  class="cursor-pointer"
                >
                  ...
                </span>
              </ng-container>
            </ng-container> -->

            <ng-container *ngIf="column === 'Email'">
              <div>
                <div>{{ dataItem.email }} </div>
                <ng-container *ngIf="dataItem.subVenderManageEmails?.length">
                  <ng-container *ngFor="let email of dataItem.subVenderManageEmails.slice(0, isExpanded(rowIndex) ? dataItem.subVenderManageEmails.length : 1)">
                    <div>{{ email?.email }}</div>
                  </ng-container>
                  <button
                    *ngIf="dataItem.subVenderManageEmails.length > 1"
                    (click)="toggleAdditionalEmails(rowIndex)"
                    nz-button
                    nzType="default"
                    [ngStyle]="{'border': 'none', 'padding': '0', 'background': 'none', 'color': '#004fd6'}"
                  >
                    {{ isExpanded(rowIndex) ? '...' : '...' }}
                  </button>
                </ng-container>
              </div>
            </ng-container>
            



      
            

            <ng-container *ngIf="column === 'Worker`s Comp Exp.'">{{
              dataItem.subVendorCertificateParameters?.subVendorCertificateSetup?.createdOn
            }}</ng-container>
            <ng-container *ngIf="column === 'Cell'"
              ><a class="text-decoration-none">{{
                dataItem.cellPhone
              }}</a></ng-container
            >
            <ng-container *ngIf="column === 'Phone'"
              ><a class="text-decoration-none">{{
                dataItem.businessPhone
              }}</a></ng-container
            >
            <ng-container *ngIf="column === 'Street Address'"
              >{{
                dataItem.streetAddress
              }}</ng-container
            >
            <ng-container *ngIf="column === 'Suburb'"
              >{{
                dataItem.suburb
              }}</ng-container
            >
            <ng-container *ngIf="column === 'State'"
              >{{
                dataItem.state
              }}</ng-container
            >
            <ng-container *ngIf="column === 'Postal Code'"
              >{{
                dataItem.postalCode
              }}</ng-container
            >
            <ng-container *ngIf="column === 'Hold Payment'"
              >{{
                dataItem.holdPaymentsToTheSubvVendor
              }}</ng-container
            >
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </nz-content>

  <nz-footer class="custom-footer" *ngIf="displayedData?.length > 0">
    <div class="container-fluid">
      <div class="row d-flex justify-content-between align-items-center">
        <div class="row mb-0 mt-2">
          <div class="col">
              <!-- sufyan Work -->
              <nz-pagination
              class="hide-buttons"
              style="float: right"
              [nzPageIndex]="currentPage"
              [nzTotal]="totalRecords"
              [nzPageSize]="pageSize"
              [nzShowSizeChanger]="true"
              [nzPageSizeOptions]="pageSizeOptions"
              (nzPageIndexChange)="onPageChange($event)"
              (nzPageSizeChange)="onPageSizeChange($event)"
              [nzShowTotal]="totalTemplate"
              [nzSimple]="false"
            ></nz-pagination>
            <ng-template #totalTemplate let-total="total" let-range="range">
              {{ range[0] }}-{{ range[1] }} of {{ total }} items
            </ng-template>
            <nz-pagination
              *ngIf="!isAllDataShownSubvendor()"
              style="float: right"
              [nzPageIndex]="currentPage"
              [nzTotal]="totalRecords"
              [nzPageSize]="pageSize"
              [nzShowSizeChanger]="true"
              [nzPageSizeOptions]="pageSizeOptions"
              (nzPageIndexChange)="onPageChange($event)"
              (nzPageSizeChange)="onPageSizeChange($event)"
              nzSimple
            ></nz-pagination>
              <!-- sufyan Work -->

            <ng-container *ngIf="PAGE_ID">
              <div class="row d-flex justify-content-start mb-1">
                <!-- <div class="col-md-3">
                  <nz-select
                    (ngModelChange)="onFilterChange($event)"
                    [(ngModel)]="selectedValueControl"
                    nzShowSearch
                    nzAutoFocus="true"
                  >
                    <nz-option
                      *ngFor="let standard of getStandardViewFilterData"
                      [nzLabel]="standard.name"
                      [nzValue]="standard.id"
                    ></nz-option>
                  </nz-select>
                </div> -->
                <div class="col-md-3">
                  <div class="input-group">
                    <nz-select
                    [(ngModel)]="selectedValueControl"
                      name="standardFilter"
                      (ngModelChange)="onFilterChange($event)"
                      nzShowSearch
                      [nzAutoFocus]="true"
                      nzPlaceHolder="Standard Filter"
                      nzAllowClear>
                      <nz-option  *ngFor="let standard of getStandardViewFilterData"
                        [nzLabel]="standard.name + (standard.isSetAsDefault ? ' ' : '')"
                        [nzValue]="standard.id"
                        nzCustomContent>
                        <div class="option-content">
                          <!-- Customize content with icons and text -->
                          <span *ngIf="standard.icon" nz-icon [nzType]="standard.icon"></span>
                          <span>{{ standard.name }}</span>
                        <span  *ngIf="standard.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

                        </div>
                      </nz-option>
                    </nz-select>
                  </div>
                </div>

                <div class="col-md-1">
                  <button
                    nz-button
                    class="border-0"
                    (click)="openStandardViewFilterComponent()"
                  >
                    <span
                      nz-popover
                      class="text-primary text-dark fw-bold fs-5"
                      nz-icon
                      nzType="ellipsis"
                      nzTheme="outline"
                    ></span>
                  </button>
                </div>
                <div class="col-md-2">
                  <div class="mt-2" *ngIf="selectedFilter === 1">
                    <p>Your filter has unsaved changes.</p>
                  </div>
                  <div class="mt-2" *ngIf="selectedFilter === 0">
                    <p>Your filter has unsaved changes.</p>
                  </div>
                </div>

                <div class="col-md-3">
                  <button
                    (click)="openStandardViewFilterComponent('saveAs')"
                    type="button"
                    nz-button
                    nzType="default"
                    class="me-2"
                    style="font-size: 12px"
                    *ngIf="selectedFilter === 1"
                  >
                    Save As
                  </button>

                  <button
                    (click)="reInitReset()"
                    nz-button
                    nzType="default"
                    style="font-size: 12px"
                    *ngIf="selectedFilter === 1"
                    type="button"
                  >
                    Reset
                  </button>

                  <button
                    (click)="saveEditStandardView()"
                    nz-button
                    nzType="primary"
                    type="button"
                    class="me-2"
                    style="font-size: 12px"
                    *ngIf="selectedFilter === 0"
                  >
                    Save
                  </button>

                  <button
                    (click)="openStandardViewFilterComponent('saveAs')"
                    nz-button
                    nzType="default"
                    class="me-2"
                    style="font-size: 12px"
                    *ngIf="selectedFilter === 0"
                    type="button"
                  >
                    Save As
                  </button>

                  <button
                    (click)="reInitReset()"
                    nz-button
                    nzType="default"
                    style="font-size: 12px"
                    *ngIf="selectedFilter === 0"
                    type="button"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </ng-container>

            <div class="col d-flex mt-3"></div>
          </div>
        </div>
      </div>
    </div>
  </nz-footer>
</nz-layout>

<app-loading-indicator></app-loading-indicator>
