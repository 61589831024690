import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { CommunicationService } from 'src/shared/Shared-Services/communication.service';
import { DeleteConfirmService } from 'src/shared/Shared-Services/delete-confirm.service';
import { ToastService } from 'src/shared/Shared-Services/toast.service';
type Position =
  | 'center'
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'topleft'
  | 'topright'
  | 'bottomleft'
  | 'bottomright';
interface EmailReminder {
  name: string;
  code: string;
}
interface ReminderLimit {
  name: string;
  code: string;
}

interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

interface Job {
  name: string;
  status: string;
  dateOpened: string;
  DateData: string;
  selected: boolean;
}
@Component({
  selector: 'app-subs-vendors-job-access',
  templateUrl: './subs-vendors-job-access.component.html',
  styleUrls: ['./subs-vendors-job-access.component.css']
})
export class SubsVendorsJobAccessComponent implements OnInit {

  mainCheckboxIndeterminate: boolean = false;
  jobs: Job[] = [
    { name: 'ZH-210 Lot 1915 Liston Avenue, Tarniet VIC 3029', status: 'Open', dateOpened: '02. Design, 03. Consent', DateData: 'Nov 23, 2023',  selected: false },
    { name: 'ZH-210 Lot 1915 Liston Avenue, Tarniet VIC 3029', status: 'Open', dateOpened: '1. Contract, 02. Design, 03. Consent', DateData: 'Oct 23, 2023',  selected: false },
    { name: 'ZH-210 Lot 1917 Liston Avenue, Tarniet VIC 3029', status: 'close', dateOpened: '1. Contract,', DateData: 'Oct 23, 2023',  selected: false },
    { name: 'ZH-210 Lot 1917 Liston Avenue, Tarniet VIC 3029', status: 'close', dateOpened: '02. Design, 02. Design, 03. Consent', DateData: 'Nov 23, 2023',  selected: false },
  ];
  text = "Zircon Homes T/A Xircon Homes is asking that you participate in Buildertrend, a project management software that is absolutely free to you. By activating your free account, you can follow along with the progress of the job, get notified about action items, and communicate with us easier. It is user friendly and can be used on your mobile device as you go .Activation is free, easy, and takes less than a minute. Please click ACTIVATE to get started. This activation link will expire after 30 days.To learn more about the experience, consider viewing these: Sub/Vendor Overview Video Sub/Vendor Mobile App Overview";



  mainCheckbox: boolean = true;
  sortColumn: keyof Job = 'name'; 
  sortDirection: 'asc' | 'desc' = 'asc'; // Default sorting direction


  ngOnInit(): void {

    
  }

  selectAll(): void {
    this.mainCheckbox = !this.mainCheckbox; // Invert the state

    this.jobs.forEach((job) => (job.selected = this.mainCheckbox));
  }

  onCheckboxChanges(event: any, job: Job): void {
    job.selected = event;

    // Check the main checkbox only if all checkboxes are selected
    this.mainCheckbox = this.jobs.every((j) => j.selected);

    // Uncheck the main checkbox if any one checkbox is unchecked
    if (!job.selected) {
      this.mainCheckbox = false;
    }
  }




  sortTable(column: keyof Job): void {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }

    this.jobs.sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];

      if (valueA < valueB) {
        return this.sortDirection === 'asc' ? -1 : 1;
      } else if (valueA > valueB) {
        return this.sortDirection === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  getSortIconColor(column: keyof Job): string {
    return this.sortColumn === column ? 'blue' : 'gray';
  }

}