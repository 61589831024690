import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions, } from 'ng-zorro-antd/tree';
import { FieldDataType } from 'src/app/Core/Enums/data_types';
import { AddNewStandardFilterComponent } from '../../Modal/add-new-standard-filter/add-new-standard-filter.component';
import { CreateLookupFilterRequest, LookupFilterResponse, LookUpStandardResponse, LookupFilter, FilterSearchParameter } from '../../Models/LookupModels';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LeadOpportunityListViewPreDefinedCodes, LeadOpportunityListViewPreDefinedParentCodes } from '../../Models/LookUpStandardFilterCodes';
import { LookupNameSetupResponse } from 'src/Models/All-Filters/financialBillsFilterField';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { ListViewFService } from 'src/Service/Sales-List-View-F/list-view-f.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { NzTSType } from 'ng-zorro-antd/core/types';

@Component({
  selector: 'app-lead-opportunities-list-view-filter-field',
  templateUrl: './lead-opportunities-list-view-filter-field.component.html',
  styleUrls: ['./lead-opportunities-list-view-filter-field.component.css']
})
export class LeadOpportunitiesListViewFilterFieldComponent implements OnInit {
  @Input() pageId: number;
  salespersonNodes: NzTreeNodeOptions[] = [];
  statusNodes: NzTreeNodeOptions[] = [];
  tagsNodes: NzTreeNodeOptions[] = [];
  sourceNodes: NzTreeNodeOptions[] = [];
  projectTypeNodes: NzTreeNodeOptions[] = [];
  proposalStatusNodes: NzTreeNodeOptions[] = [];
  ageofLead: { label: string, value: string }[] = [];
  soldDate: { label: string, value: string }[] = [];
  mostRecentClick: { label: string, value: string }[] = [];
  ofLinksClicked: { label: string, value: string }[] = [];
  doyouhaveLand: { label: string, value: string }[] = [];
  keywordOptions: LookupNameSetupResponse[] = [];
  colName: any;
  filters: any[] = [];
  lookUpStandardResponse: LookUpStandardResponse[] = [];
  createLookupFilterRequests: CreateLookupFilterRequest[] = [];
  filterResponseData: LookupFilterResponse[] = [];
  defaultStandardFilter: LookupFilterResponse;
  predefinedValuesMultiSelect: string[] = [];
  isSetAsDefault: boolean;
  filterSearchParameter: FilterSearchParameter;
  selectedFilter: number = -1;
  editSaveFilterFormGroup: FormGroup;
  isFormDirty: boolean = false;
  formFieldValues: any = {};
  scheduleItemReminder: LookupNameSetupResponse[] = [];
  lookupSetNameResponse: LookupNameSetupResponse[] = [];

  /* In between Start and End these code are different from other filters   */
  /* _______________________________________________________________ START DIFFERENCE __________________________________________________________________ */
  preDefinedDataCodes = {
    standardFilter: LeadOpportunityListViewPreDefinedCodes.StandardFilter,
    keyword: LeadOpportunityListViewPreDefinedCodes.Keyword,
    salesperson: LeadOpportunityListViewPreDefinedCodes.Salesperson,
    status: LeadOpportunityListViewPreDefinedCodes.Status,
    ageofLead: LeadOpportunityListViewPreDefinedCodes.AgeofLead,
    tags: LeadOpportunityListViewPreDefinedCodes.Tags,
    source: LeadOpportunityListViewPreDefinedCodes.Source,
    projectType: LeadOpportunityListViewPreDefinedCodes.ProjectType,
    proposalStatus: LeadOpportunityListViewPreDefinedCodes.ProposalStatus,
    soldDate: LeadOpportunityListViewPreDefinedCodes.SoldDate,
    mostRecentClick: LeadOpportunityListViewPreDefinedCodes.MostRecentClick,
    ofLinksClicked: LeadOpportunityListViewPreDefinedCodes.OfLinksClicked,
    doyouhaveLand: LeadOpportunityListViewPreDefinedCodes.DoyouhaveLand,
  };
contentTemplate: NzTSType;
  /*__________________________________________________________________ END DIFFERENCE __________________________________________________________________ */

  constructor(
    private fb: FormBuilder,
    public toastService: NzMessageService,
    private loadingIndicatorService: LoadingIndicatorService,
    private modal: NzModalService,
    private lookUpFilterService: LookupFilterService,
    private customerInformationService: CustomerInformationService,
    private accessLocalStorageService: AccessLocalStorageService,
    private listViewFService: ListViewFService,
    private cdr: ChangeDetectorRef
  ) { }
  selectedJobId: number = -1;
  ngOnInit(): void {
    this.selectedJobId = this.accessLocalStorageService.getJobId();
    this.initLoad();
    this.initEditSaveFilter();
  }
  initLoad() {
    this.lookUpFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(res => {
      let statusMulT = res.result.filter(x => x.code === this.preDefinedDataCodes.status.code);
      let ageofLeadSEL = res.result.filter(x => x.code === this.preDefinedDataCodes.ageofLead.code);
      let tagsMulT = res.result.filter(x => x.code === this.preDefinedDataCodes.tags.code);
      let sourceMulT = res.result.filter(x => x.code === this.preDefinedDataCodes.source.code);
      let projectTypeMulT = res.result.filter(x => x.code === this.preDefinedDataCodes.projectType.code);
      let proposalStatusMulT = res.result.filter(x => x.code === this.preDefinedDataCodes.proposalStatus.code);
      let soldDateSEL = res.result.filter(x => x.code === this.preDefinedDataCodes.soldDate.code);
      let mostRecentClickSEL = res.result.filter(x => x.code === this.preDefinedDataCodes.mostRecentClick.code);
      let ofLinksClickedSEL = res.result.filter(x => x.code === this.preDefinedDataCodes.ofLinksClicked.code);
      let doyouhaveLandSEL = res.result.filter(x => x.code === this.preDefinedDataCodes.doyouhaveLand.code);
      let columnName = res.result.filter(x => x.parentCode.trim() === LeadOpportunityListViewPreDefinedParentCodes.PARENT_CODE);


      // modified by mehmood all user call

      // Sales Persons
      this.customerInformationService.getDataforcombo().subscribe((res) => {
        this.salespersonNodes = [
          {
            title: 'Select All',
            value: 'select_all',
            key: 'select_all',
            selectable: false,
            isLeaf: false,
            expanded: true,
            children: res.result.map((salesperson) => ({
              title: salesperson?.fullName,
              value: salesperson.id.toString(),
              key: salesperson.id.toString(),
              isLeaf: true
            }))
          }
        ];
      });

      // Status
      this.statusNodes = [
        {
          title: 'Select All',
          value: 'select_all',
          key: 'select_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: statusMulT.map((status) => ({
            title: status.name,
            value: status.id.toString(),
            key: status.id.toString(),
            isLeaf: true
          }))
        }
      ];

      this.tagsNodes = [
        {
          title: 'Select All',
          value: 'select_all',
          key: 'select_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: tagsMulT.map((tags) => ({
            title: tags.name,
            value: tags.id.toString(),
            key: tags.id.toString(),
            isLeaf: true
          }))
        }
      ];

      this.sourceNodes = [
        {
          title: 'Select All',
          value: 'select_all',
          key: 'select_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: sourceMulT.map((source) => ({
            title: source.name,
            value: source.id.toString(),
            key: source.id.toString(),
            isLeaf: true
          }))
        }
      ];

      this.projectTypeNodes = [
        {
          title: 'Select All',
          value: 'select_all',
          key: 'select_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: projectTypeMulT.map((projectType) => ({
            title: projectType.name,
            value: projectType.id.toString(),
            key: projectType.id.toString(),
            isLeaf: true
          }))
        }
      ];

      this.proposalStatusNodes = [
        {
          title: 'Select All',
          value: 'select_all',
          key: 'select_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: proposalStatusMulT.map((proposalStatus) => ({
            title: proposalStatus.name,
            value: proposalStatus.id.toString(),
            key: proposalStatus.id.toString(),
            isLeaf: true
          }))
        }
      ];





      this.ageofLead = ageofLeadSEL.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));


      this.soldDate = soldDateSEL.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));
      this.mostRecentClick = mostRecentClickSEL.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));
      this.ofLinksClicked = ofLinksClickedSEL.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));
      this.doyouhaveLand = doyouhaveLandSEL.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));
      this.keywordOptions = columnName.map(res => ({
        name: res.description,
        id: res.code.toString(),
      }));
    });
    const lookupFilter: LookupFilter = {
      formNameId: this.pageId,
    };
    this.getStandardFilterByFormPageId(this.pageId);
    this.fetchData(lookupFilter);
  }
  onParentCodeChange(codeVal: string): void {
    console.log('Selected parent code:', codeVal);
    let createCode = { code: codeVal };
    this.preDefinedDataCodes.keyword = createCode;
    console.log('After change preDefinedDataCodes :', this.preDefinedDataCodes);
  }
  fetchData(lookUpStandard: LookupFilter): void {
    this.lookUpFilterService.postData(lookUpStandard).subscribe(
      data => {
        this.lookUpStandardResponse = data.result;
      },
      error => {
        console.error('Error fetching data:', error);
      }
    );
  }

  ///  Sales Person
  onSalesPersonChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.salespersonNodes[0].children.map(child => child['value']);
      this.formFieldValues['salesperson'] = [...allValues];
    } else {
      const allValues = this.salespersonNodes[0].children.map(child => child['value']);
      this.formFieldValues['salesperson'] = selectedValues.filter(value => allValues.includes(value));
    }

  }

  // status
  onStatusChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.statusNodes[0].children.map(child => child['value']);
      this.formFieldValues['status'] = [...allValues];
    } else {
      const allValues = this.statusNodes[0].children.map(child => child['value']);
      this.formFieldValues['status'] = selectedValues.filter(value => allValues.includes(value));
    }

  }
  // tags
  onTagChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.tagsNodes[0].children.map(child => child['value']);
      this.formFieldValues['tags'] = [...allValues];
    } else {
      const allValues = this.tagsNodes[0].children.map(child => child['value']);
      this.formFieldValues['tags'] = selectedValues.filter(value => allValues.includes(value));
    }
  }

  // source
  onSourceChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.sourceNodes[0].children.map(child => child['value']);
      this.formFieldValues['source'] = [...allValues];
    } else {
      const allValues = this.sourceNodes[0].children.map(child => child['value']);
      this.formFieldValues['source'] = selectedValues.filter(value => allValues.includes(value));

    }
  }

  // Project Type
  onProjectTypeChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.projectTypeNodes[0].children.map(child => child['value']);
      this.formFieldValues['projectType'] = [...allValues];
    } else {
      const allValues = this.projectTypeNodes[0].children.map(child => child['value']);
      this.formFieldValues['projectType'] = selectedValues.filter(value => allValues.includes(value));

    }
  }
  // Proposal Status
  onProposalStatusChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.proposalStatusNodes[0].children.map(child => child['value']);
      this.formFieldValues['proposalStatus'] = [...allValues];
    } else {
      const allValues = this.proposalStatusNodes[0].children.map(child => child['value']);
      this.formFieldValues['proposalStatus'] = selectedValues.filter(value => allValues.includes(value));

    }
  }

  openStandardFilterComponent(type: string) {
    this.createLookupFilterRequests = [];
    this.createFormFieldsInArray();
    const modalRef = this.modal.create({
      nzContent: AddNewStandardFilterComponent,
      nzFooter: null,
      nzData:
      {
        pageId: this.pageId,
        std: this.filterResponseData,
        field: this.createLookupFilterRequests,
        clickFrom: type
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  getStandardFilterByFormPageId(pageId: number) {
    this.lookUpFilterService.getStandardFilterByFormPageId(pageId);
    this.lookUpFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.filterResponseData = result.result;
        this.initializeForm();
        setTimeout(() => {
          let id = this.accessLocalStorageService.getSelectedFilterId();
          this.setDefaultFieldValues(id);
        }, 100);

      }

    });
  }

  onFilterApply(): void {
    let selectedFilter = this.formFieldValues['standardFilter'];
    let isSelectedFilterStandard = this.filterResponseData.find(x => x.id === selectedFilter && x.isNotDeleted === true);
    if (isSelectedFilterStandard && isSelectedFilterStandard != undefined) {
      this.selectedFilter = 1;
    }
    else {
      this.selectedFilter = 0;
    }
    this.createFormFieldsInArray();
    this.createLookupFilterRequests[0].page = 1;
    this.createLookupFilterRequests[0].pageSize = 10;

    this.listViewFService.getAppliedFilterDataListView(this.createLookupFilterRequests);


  }

  resetFilter(): void {
    Object.keys(this.formFieldValues).forEach(field => {
      if (field !== 'standardFilter') {
        if (Array.isArray(this.formFieldValues[field])) {
          this.formFieldValues[field] = [];
        } else {
          this.formFieldValues[field] = '';
        }
      }
    });
    this.selectedFilter = -1;
  }

  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
    this.isFormDirty = false;
  }

  createFormFieldsInArray() {
    this.createLookupFilterRequests = [];
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const value = this.formFieldValues[field];
      if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 0)) {
        const newRow = {
          globalId: '00000000-0000-0000-0000-000000000000',
          companyParameterId: 0,
          lookUpStandardId: 0,
          code: code,
          meaning: '',
          valueId: 0,
          formNameId: this.pageId,
          dataTypeId: 0,
          jobInformationId: null,
          createLookupFilterParameterRequests: [null]
        };

        const correspondingResponse = this.lookUpStandardResponse.find(response => response.code === code);
        if (correspondingResponse) {
          newRow.companyParameterId = correspondingResponse.companyParameterId;
          newRow.lookUpStandardId = correspondingResponse.id;
          newRow.dataTypeId = correspondingResponse.dataTypeId;

          if (
            correspondingResponse.dataType?.code === FieldDataType.SingleLineText ||
            correspondingResponse.dataType?.name === 'Single-Line Text') {
            newRow.meaning = value;
          }
          if (
            correspondingResponse.dataType?.code === FieldDataType.Dropdown ||
            correspondingResponse.dataType?.name === 'Dropdown') {
            newRow.valueId = value;
          }
          if (
            correspondingResponse.dataType?.code === FieldDataType.MultiSelectDropdown ||
            correspondingResponse.dataType?.name === 'Multi-Select Dropdown'
          ) {
            newRow.createLookupFilterParameterRequests = value.map((item, index) => {
              return { valueId: item };
            });
          }
        }
        this.createLookupFilterRequests.push(newRow);
      }
    });
  }

  getDefaultFilterValue() {
    // this.defaultStandardFilter = this.filterResponseData.find(filter => filter.isSetAsDefault === true);
    // return this.defaultStandardFilter ? this.defaultStandardFilter.id : null;
    return this.accessLocalStorageService.getSelectedFilterId();
  }

  setDefaultFieldValues(standardFilterId: number): void {
    this.predefinedValuesMultiSelect = [];
    let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
    let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;

    Object.keys(this.formFieldValues).forEach(field => {
      if (field !== 'standardFilter') {
        if (Array.isArray(this.formFieldValues[field])) {
          this.formFieldValues[field] = [];
        } else {
          this.formFieldValues[field] = '';
        }
      }
    });


    if (lookUpFiltersField.length > 0) {
      Object.keys(this.preDefinedDataCodes).forEach(field => {
        const code = this.preDefinedDataCodes[field].code;
        const fieldData = lookUpFiltersField.find(filter => filter.code === code);
        if (fieldData != undefined) {
          if (
            fieldData.dataType.code === FieldDataType.SingleLineText) {
            this.formFieldValues[field] = fieldData.meaning;
            this.colName = code;
          }
          if (fieldData.dataType.code === FieldDataType.Dropdown) {
            this.formFieldValues[field] = fieldData.valueId.toString();
          }
          if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown) {
            let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
            this.predefinedValuesMultiSelect = multiSelectValues.map(String);
            this.formFieldValues[field] = this.predefinedValuesMultiSelect;
          }
        }
      });
    } else {
      console.log("lookUpFiltersField is null ", lookUpFiltersField);
    }
  }

  onChangeStandardFilter($event: number): void {
    this.selectedFilter = -1;
    const defaultFIlterId = this.getDefaultFilterValue();
    this.accessLocalStorageService.setSelectedFilterId($event);
    // New Changes By Aamir Ali - 22-Apr2024
    let id = this.accessLocalStorageService.getSelectedFilterId();
    this.setDefaultFieldValues(id);
    this.listViewFService.ListViewSetSelectedFilterId(id);
    this.filterSearchParameter = {
      CompanyParameterId: 1,
      FormNameId: this.pageId,
      LookUpFilterId: $event,
      page: 1,
      pageSize: 10,
      jobInformationId: null,
    };
    if (defaultFIlterId !== $event) {
      this.listViewFService.ListViewUserAppliedFilter(true);
    } else {
      this.listViewFService.ListViewUserAppliedFilter(false);
    }
    this.listViewFService.getListView(this.filterSearchParameter);

  }

  private initEditSaveFilter(): void {
    this.editSaveFilterFormGroup = this.fb.group({
      globalId: ['00000000-0000-0000-0000-000000000000'],
      companyParameterId: [0],
      code: ['', Validators.required],
      name: ['', Validators.required],
      description: [''],
      meaning: [''],
      formNameId: [0],
      dataTypeId: [],
      isShareThisFilter: [true],
      isSetAsDefault: [false],
      createLookupFilterRequests: [[]],
    });
  }

  editSaveFilterData(): void {
    let selected_Filter_Id = this.formFieldValues['standardFilter'];
    let getSelectedFilterValue = this.filterResponseData.find(filter => filter.id === selected_Filter_Id);
    this.createFormFieldsInArray();
    this.editSaveFilterFormGroup.controls['name'].setValue(getSelectedFilterValue.name);

    if (this.editSaveFilterFormGroup.get('name')?.invalid) {
      this.toastService.error('Name field is required');
      return;
    }
    this.editSaveFilterFormGroup.controls['companyParameterId'].setValue(getSelectedFilterValue.companyParameterId);
    this.editSaveFilterFormGroup.controls['code'].setValue(getSelectedFilterValue.code);
    this.editSaveFilterFormGroup.controls['formNameId'].setValue(getSelectedFilterValue.formNameId);
    this.editSaveFilterFormGroup.controls['dataTypeId'].setValue(getSelectedFilterValue.dataTypeId);
    this.editSaveFilterFormGroup.controls['globalId'].setValue(getSelectedFilterValue.globalId);
    this.editSaveFilterFormGroup.controls['createLookupFilterRequests'].setValue(this.createLookupFilterRequests);
    this.editSaveFilterFormGroup.controls['isSetAsDefault'].setValue(getSelectedFilterValue.isSetAsDefault);
    this.lookUpFilterService.saveStandardFilterData(this.editSaveFilterFormGroup.value)
      .subscribe(
        (res: any) => {
          console.log(res.result);
          setTimeout(() => {
            this.loadingIndicatorService.hide();
            this.toastService.success('Filter saved successfully');
            this.getStandardFilterByFormPageId(this.pageId);
            this.selectedFilter = -1;
          }, 20);
        },
        (error) => {
          console.error('Error:', error);
          this.loadingIndicatorService.hide();
          if (error.status === 500) {
            this.toastService.error('Internal server error. Please try again later.');
          } else {
            this.toastService.error('An error occurred. Please try again.');
          }
        }
      );
    this.selectedFilter = -1;
  }

  /* _____________________________________ START DIFFERENCE _________________________________________________ */
  initializeForm(): void {
    this.formFieldValues = {
      standardFilter: this.getDefaultFilterValue(),
      keyword: '',
      salesperson: [] = [],
      status: [] = [],
      ageofLead: '',
      tags: [] = [],
      source: [] = [],
      projectType: [] = [],
      proposalStatus: [] = [],
      soldDate: '',
      mostRecentClick: '',
      ofLinksClicked: '',
      doyouhaveLand: '',
    };
  }
  /* ____________________________________ END DIFFERENCE _____________________________________________________ */

}
