import { Exchange } from '../InternalUser/applicationUserResponse';

export class CreateActionRequest {}
export class UpdateActionRequest {}

export class BasicEntity {
  id: number;
  isActive: boolean;
  isDeleted: boolean;
  createdOn: Date;
  createdBy: number;
  modifiedOn?: Date;
  modifiedBy?: number;
}
export class LookupNameSetup {
  companyParameterId?: number | null;
  companyParameter?: CompanyParameter | null;
  formNameId?: number | null;
  formName?: null;
  lookUpStandardId?: number | null;
  lookUpStandard?: LookUpStandard | null;
  code?: string;
  name?: string;
  description?: string;
}

export class FormName {
  name: string | null;
  tableName: string | null;
}

export class LookUpStandard {
  companyParameterId?: number;
  companyParameter?: CompanyParameter;
  code: string;
  formNameId?: number;
  formName?: FormName;
  description: string;
  meaning: string;
  dataTypeId?: number;
  dataType?: DataType;
  tableName: string;
}

export class DataType {
  code: string;
  name: string;
}

export class CompanyParameter extends BasicEntity {
  companyNameId?: number | null;
  companyName?: CompanyName | null;
}

export class CompanyName {
  name: string;
}

/////respose/////////

export interface LookupNameSetupResponse extends BaseResponse {
  fullName: any;
  companyParameterId?: number;
  companyParameter?: CompanyParameterResponse;
  formNameId?: number;
  formName?: FormNameResponse;
  lookUpStandardId?: number;
  lookUpStandard?: LookUpStandardResponse;
  code: string;
  name: string;
  description: string;
}

export interface CompanyParameterResponse extends BaseResponse {
  companyNameId?: number;
  companyName?: CompanyNameResponse;
}

export interface CompanyNameResponse extends BaseResponse {
  name: string;
}

export interface FormNameResponse extends BaseResponse {
  name?: string;
  tableName?: string;
}

export interface LookUpStandardResponse extends BaseResponse {
  companyParameterId?: number;
  companyParameter?: CompanyParameterResponse;
  code: string;
  formNameId?: number;
  formName?: FormNameResponse;
  description: string;
  meaning: string;
  dataTypeId?: number;
  dataType?: DataTypeResponse;
  tableName: string;
}

export interface DataTypeResponse extends BaseResponse {
  code: string;
  name: string;
}

export interface CreateLookupNameSetupRequest extends BaseResponse {
  companyParameterId?: number;
  formNameId?: number;
  lookUpStandardId?: number;
  code: string;
  name: string;
  description: string;
}

export interface SearchParameterForLookupNameSetup {
  code: string;
}

export interface UpdateLookupNameSetupRequest extends BaseResponse {
  companyParameterId?: number;
  formNameId?: number;
  lookUpStandardId?: number;
  code: string;
  name: string;
  description: string;
}

export abstract class BaseResponse extends Exchange {
  createdBy: number;
  createdOn: Date;
  modifiedBy: number | null;
  modifiedOn: Date | null;
  isActive: boolean;
}
