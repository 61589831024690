import { Component } from '@angular/core';
import { DocumentEmptyComponent } from '../../Documents/document-empty/document-empty.component';
import { DocumentCreateFolderComponent } from 'src/app/shared/component/document-create-folder/document-create-folder.component';
import { FileInformationResponse } from 'src/Models/FilesModules/FilesDocument';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FileDecumentService } from 'src/Service/FileDocumnet/file-decument.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { AttachmentfileUploadComponent } from '../../attachmentfile-upload/attachmentfile-upload.component';

@Component({
  selector: 'app-videos-dashboard',
  templateUrl: './videos-dashboard.component.html',
  styleUrls: ['./videos-dashboard.component.css']
})
export class VideosDashboardComponent {
responseData: FileInformationResponse;
  isCollapsed = false;
  cards = [];

  selectedJobId: number = 0;
  previewUrl: string | ArrayBuffer | null = null;
  showH1: boolean = false;
  showH5: boolean = false;
  checked = false;
  loading = false;
  indeterminate = false;
  listOfData: readonly Data[] = [];
  listOfCurrentPageData: readonly Data[] = [];
  response: FileInformationResponse [] =[];
  setOfCheckedId = new Set<number>();
  showActivity: boolean = true;
previewExtension: string = '';


  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
getSelectedJobId(item: number){
  this.selectedJobId = item;
  if(this.selectedJobId){
    this.getAllFilesByJob(this.selectedJobId)
  }
  console.log("selectedJobId : ", this.selectedJobId);
}


getAllFilesByJob(Jobinformationid: number){
  this.FileDecumentService.getDatabyJob(Jobinformationid).subscribe((response) =>{
    this.response = response.result;

  })

}

  constructor(private modal: NzModalService,
    public localStorage: AccessLocalStorageService,
    private FileDecumentService: FileDecumentService) {}

 



 
  ngOnInit(): void {
    this.selectedJobId = this.localStorage.getJobId();
    if(this.selectedJobId){
      this.getAllFilesByJob(this.selectedJobId)
    }
    this.localStorage.getJobName();
    this.FileDecumentService.currentSavedId.subscribe(savedId => {
      console.log('Saved ID in DocumentsDashboardComponent:', savedId);
    });
    this.FileDecumentService.getData().subscribe(
      (response) => {
        console.log("Received FileDecumentService data:", response.result);
        this.response = response.result;
      },
      (error) => {
        console.error('Error:', error);
      }
    );
    this.listOfData = new Array(100).fill(0).map((_, index) => ({
      id: index,
      name: `Edward King ${index}`,
      age: 32,
      address: `London, Park Lane no. ${index}`,
      disabled: index % 2 === 0
    }));
  }



  showH1OnClick(): void {
    this.showH1 = true;
    this.showH5 = false;
  }

  showH5OnClick(): void {
    this.showH5 = true;
    this.showH1 = false;
  }
  CreateNewfolder(data: FileInformationResponse): void {
    const modalRef = this.modal.create({
      nzContent: DocumentCreateFolderComponent,
      nzFooter: null,
      nzData: {
        showFields: true,
        isDocument: false,
        isPhoto: false,
        isVedio: true
      }
    });
    modalRef.componentInstance.savedId = data;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    
  }
  

  CreateDocumentEmptyComponent(): void {
    const modalRef = this.modal.create({
      nzContent: DocumentEmptyComponent,
      nzFooter: null,
      nzData:{
        responseData: this.response
      }
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }


  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onCurrentPageDataChange(listOfCurrentPageData: readonly Data[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData.filter(({ disabled }) => !disabled);
    this.checked = listOfEnabledData.every(({ id }) => this.setOfCheckedId.has(id));
    this.indeterminate = listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.listOfCurrentPageData
      .filter(({ disabled }) => !disabled)
      .forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  sendRequest(): void {
    this.loading = true;
    const requestData = this.listOfData.filter(data => this.setOfCheckedId.has(data.id));
    console.log(requestData);
    setTimeout(() => {
      this.setOfCheckedId.clear();
      this.refreshCheckedStatus();
      this.loading = false;
    }, 1000);
  }


  addCard() {
    this.cards.push({});
  }
  isPdfFile(extension: string): boolean {
    return extension.toLowerCase() === '.pdf';
  }
  
  isExcelFile(extension: string): boolean {
    return extension.toLowerCase() === '.xls' || extension.toLowerCase() === '.xlsx';
  }
  
  isWordFile(extension: string): boolean {
    return extension.toLowerCase() === '.doc' || extension.toLowerCase() === '.docx';
  }
  isImageFile(extension: string): boolean {
    return extension.toLowerCase() === '.png' || extension.toLowerCase() === '.jpg' || extension.toLowerCase() === '.jpeg' || extension.toLowerCase() === '.gif';
  }
  isTxtFile(extension: string): boolean {
    return extension.toLowerCase() === '.txt';
  }
  isVideoFile(extension: string): boolean {
    return extension.toLowerCase() === '.mp4' || extension.toLowerCase() === '.mov' || extension.toLowerCase() === '.avi' || extension.toLowerCase() === '.mkv';
}
  
  getIconType(extension: string): string {
    if (this.isPdfFile(extension)) {
      return 'fa-file-pdf';
    } else if (this.isExcelFile(extension)) {
      return 'fa-file-excel';
    } else if (this.isWordFile(extension)) {
      return 'fa-file-word';
    } else if (this.isImageFile(extension)) {
      return 'fa-file-image';
    } else if (this.isTxtFile(extension)) {
      return 'fa-file-alt';
    } else {
      return 'fa-file';
    }
  }


openVideo(attachmentParam: any): void {
  if (this.isVideoFile(attachmentParam.extension)) {
    const videoUrl = `../assets/File/Uplaod/Documents/${attachmentParam.name}${attachmentParam.extension}`;
    
    const modalRef = this.modal.create({
      nzTitle: 'Video Player',
      nzContent: AttachmentfileUploadComponent,
      nzData: { videoUrl: videoUrl },
      nzFooter: null,
      nzWidth: '80%'
    });
  }
}

openImagePreview(attachmentParam: any): void {
  this.previewUrl = this.getImageUrl(attachmentParam);
}
previewFile(file: File): void {
  const reader = new FileReader();
  reader.onload = (e) => {
    this.previewUrl = e.target?.result;
  };
  reader.readAsDataURL(file);
}
isImageFiles(fileType: string): boolean {
  return fileType.startsWith('image/');
}


openFile(attachmentParam: any): void {
  const fileUrl = this.getFileUrl(attachmentParam);
  console.log('Opening file:', fileUrl); 
  if (fileUrl) {
    window.open(fileUrl, '_blank');
  } else {
    console.error('File URL is invalid');
  }
}

getFileUrl(attachmentParam: any): string | null {
  const basePath = '../assets/File/Uplaod/Documents';
  const fileName = attachmentParam.name;
  if (fileName) {
    return `${basePath}/${fileName}`;
  }
  return null;
}

getImageUrl(attachmentParam: any): string {
  const baseUrl = 'assets/File/Uplaod/Documents/'; 
  const imageName = attachmentParam.name + attachmentParam.extension; 
  return baseUrl + imageName;
}

  
}

export interface Data {
  id: number;
  name: string;
  age: number;
  address: string;
  disabled: boolean;
}

