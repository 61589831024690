import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { VarianceCode } from 'src/Models/VarianceCode/varianceCode.Model';
import { VarianceCodeService } from 'src/Service/VarianceCode/variance-code.service';

@Component({
  selector: 'app-variance-category',
  templateUrl: './variance-category.component.html',
  styleUrls: ['./variance-category.component.css']
})
export class VarianceCategoryComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  selectedValue = null;
  VarianceCategory = true;
  formFieldValues: any = {};
  varianceCategory: VarianceCode[] = [];


  constructor(private varianceCategorySer: VarianceCodeService, private toastssService: NzMessageService) {
  }

  ngOnInit(): void {

    this.varianceCategorySer.getCategoryData().subscribe((res: ResponseModelArray<VarianceCode>) => {
      if (res) {
        this.varianceCategory = res.result;
      }
    });


    this.formFieldValues = {
      globalI: '00000000-0000-0000-0000-000000000000',
      companyParameterId: 1,
      title: '',
      varianceCodeId: 0,
      detail: '',
      isStatus: false,
      isCategory: true,
      isSubCodeOf: false,
      subCostCodeId: 0
    };

  }


  VarianceCategoryCancel(): void {
    this.cancel.emit();
  }

  autoAdjustTextAreaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
    textarea.style.height = `${Math.min(textarea.scrollHeight, 190)}px`; // Set the height with a maximum of 200px
  }

  saveButton(): void {
    if (this.formFieldValues.varianceCodeId > 0) {
      this.formFieldValues.isCategory = false;
      this.formFieldValues.isSubCodeOf = true;
    }

    this.varianceCategorySer.postVarianceCategory(this.formFieldValues).subscribe((response) => {
      this.toastssService.success('New  Successfully!');
      this.cancel.emit();
    });
  }
}



