import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';
import { RoleParameter } from 'src/Models/RoleParameters.model';
import { ApiResponseModel } from 'src/Models/apiResponse.model';
import { ResponseModel } from 'src/Models/responseMessage.model';
import { ModuleResponse } from 'src/Models/treeTab.model';
// import { ApplicationTreeMenu } from 'src/Models/treeTab.model';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { environment } from 'src/environments/environment.prod';
import { RolePermissionForControlAccessResponse } from 'src/shared/modals/header';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  //private baseUrl = "https://dev-bt-api.persistentsolutions.co/api/";
  private baseUrl: string = environment.apiUrl ;

  public currentUser: Observable<string>;
  public activeTabs: Observable<string>;


  private isAuthenticated = false;

  constructor(private httpClient: HttpClient,
     private router: Router,
     private accessLocalStorage: AccessLocalStorageService
    ) { }

  //login process
  login(username: string, password: string) {
    let model = {
      email: username,
      password: password
    }
    this.httpClient.post<any>(this.baseUrl+'auth/', model).subscribe((res) => {
      if (res.isValid) {
        this.isAuthenticated = true;
        console.log('auth response ', res)
        localStorage.setItem('accessToken', res.accessToken);
        this.getApplicationModules();
        // this.setRoleParameter();
        // this.setTabs(this.tabs);
      }
    });
  }

  // Log the user out
  logout(): void {
    this.isAuthenticated = false;
    this.clearLocalStorageSession();
    this.router.navigateByUrl('/Authentication/Sign-In');
  }

  // Check if the user is authenticated
  isAuthenticatedUser(): boolean {
    return this.isAuthenticated;
  }

  // Get the authentication token from local storage
  getAuthToken(): string | null {
    return localStorage.getItem('accessToken');
  }

  setTabs(data: ModuleResponse[]) {
    const tabsJSON = JSON.stringify(data);
    localStorage.setItem('tabs', tabsJSON);
  }

  getTabs(): ModuleResponse[] {
    return JSON.parse(localStorage.getItem('tabs'));
  }


  clearLocalStorageSession() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('tabs');
    localStorage.removeItem('roleParameters');
    localStorage.removeItem('JOB_ID');
    localStorage.removeItem('isFromLeadProposal');
    localStorage.removeItem('LOGIN_USER_ID');
    this.clearAllLocalStorage();
  }

  clearAllLocalStorage(): void {
    localStorage.clear();
  }

  //------------------ Application Module ------------------  

  getApplicationModules(){
    this.httpClient.get<ApiResponseModel>(this.baseUrl + 'Role/getAll-Menu' ).subscribe((res) => {
      this.setTabs(res.result);
      this.router.navigateByUrl('/Jobs');
    })
  }





  //------------------ Role Parameters ------------------  

  roleParameters: RoleParameter[] = [
    {
      roleId: 1,
      role: null,
      treeMenuId: 1,
      treeMenu: {
        name: "Sales",
        treeMenuId: 0,
        path: null,
        isAllUserActivity: null,
        isEmailNotification: null,
        isTextNotification: null,
        isPushNotification: null,
        toolTip: null,
        treeMenus: null,
        createdBy: 1,
        createdOn: "2023-11-04T01:08:20.61",
        modifiedBy: null,
        modifiedOn: null,
        isActive: true,
        id: 1,
        globalId: "a1974a3d-cdab-4d07-841b-0588196035c9"
      },
      view: true,
      add: true,
      edit: true,
      delete: true,
      toolTip: "test",
      createdBy: 0,
      createdOn: "2023-11-04T03:13:42.6095447",
      modifiedBy: null,
      modifiedOn: null,
      isActive: true,
      id: 1,
      globalId: "4c06ed09-86c5-45eb-bcb6-136c0f948efa"
    }
  ]

  setRoleParameter() {
    const permissionsJSON = JSON.stringify(this.roleParameters);
    localStorage.setItem('roleParameters', permissionsJSON);
  }

// getModuleUserPermission(applicationModuleDevId: number, modulePermissionId: number){

//   let model = {
//     applicationModuleDevId: applicationModuleDevId,
//     modulePermissionId: modulePermissionId
//   }
//   return this.httpClient.post<any>(this.baseUrl+'Role/getControlBy-ModulePage', model).subscribe((res) => {
//     if (res) {
//       console.log("RESULT : ", res);
      
//     }
//   });

// }

getModuleUserPermission(applicationModuleDevId: number, modulePermissionId: number): Observable<ResponseModel<RolePermissionForControlAccessResponse>> {
  let model = {
    applicationModuleDevId: applicationModuleDevId,
    modulePermissionId: modulePermissionId
  }
  return this.httpClient.post<any>(this.baseUrl+'Role/getControlBy-ModulePage', model).pipe(
    catchError((error: any) => {
      console.error('Error:', error);
      return throwError(error);
    })
  );
}

}
