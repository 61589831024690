<nz-modal
nzWidth="95%"
[(nzVisible)]="selectionModelhandle"
[nzTitle]="SelectionModelTitle"
[nzFooter]="SelectionModelFooter"
[nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '75vh', 'overflow-y': 'auto' }"
[nzStyle]="{ top: '15px' }"
(nzOnCancel)="selectionModelCancel()">
  <!-- <ng-template #SelectionModelTitle>
   <nz-breadcrumb nz-page-header-breadcrumb>
     <nz-breadcrumb-item>{{name.name}}</nz-breadcrumb-item>
   </nz-breadcrumb>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0 fw-bold">
      Selection
      </h2>
    </div>
  </ng-template> -->
  <ng-template #SelectionModelTitle>
    <div class="row" *ngIf="leadData?.leadData?.name">
      <h5 class="ListViewJobname p-0 m-0">{{ leadData?.leadData?.name }}</h5>
  </div>
  <div class="row" *ngIf="JobData.JOB_NAME">
    <h5 class="ListViewJobname p-0 m-0">{{ JobData.JOB_NAME }}</h5>
  </div>
  <div class="row">
    <h1 class="p-0 m-0 famly-inn">Selection</h1>
  </div>
</ng-template>

  <div class="content content-set-background " *nzModalContent>
    <div class="ng-container ">

<nz-card nzBorderless="true" nzTitle="Selection">
  <div class="row">
    <div class="col-6">
      <div class="col-12">
        <div class="row">
          <div class="col-10">
              <label class="sub-text">Title <span class="text-danger">*</span></label>
              <input [(ngModel)]="newSelectionFormFiedls['title']" name="title" type="text" class="form-control formField"
                     (focus)="onTitleFocus()" (blur)="onTitleBlur()"  (touch)="onTitleFocus()"
                     [ngClass]="{'is-invalid': showTitleError}" />
              <!-- Show error message conditionally -->
              <div *ngIf="showTitleError" class="text-danger">
                  Required
              </div>
          </div>
      </div>
        <div class="row">
          <div class="col-12 mt-3">
            <label class="pira-label" [(ngModel)]="newSelectionFormFiedls['isRequireOwnerToMakeSelection']" name="isRequireOwnerToMakeSelection" nz-checkbox>Requare owner to make a selection</label>
          </div>
        </div>
        <div class="row gx-0">
          <div class="col-8 mt-4">
            <label class="sub-text">Category </label>
            <nz-select [(ngModel)]="newSelectionFormFiedls['catagorySetupId']" name="catagorySetupId" style="width: 100%" nzShowSearch [lang]="true" nzAutoFocus="true" nzPlaceHolder="Unassigned"
              >
              <nz-option *ngFor="let option of categoryOption" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
            </nz-select>
          </div>
          <div class="col-3 mt-5 p-0">
            <button nz-button nzType="link" (click)="showTags()">Add</button>
            <button [disabled]="!newSelectionFormFiedls['catagorySetupId']"
               class="btn btn-sm text-primary border-0 me-1" (click)="showTagEdit(newSelectionFormFiedls.catagorySetupId)">
               Edit
            </button>
          </div>
        </div>
        <div class="row gx-0" >
          <div class="col-8 mt-4">
            <label class="sub-text">Location </label>
            <nz-select style="width: 100%" [(ngModel)]="newSelectionFormFiedls['locationSetupId']" name="locationSetupId" nzShowSearch [lang]="true" nzAutoFocus="true" nzPlaceHolder="Unassigned"
              >
              <nz-option *ngFor="let option of locationOption" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
            </nz-select>
          </div>
          <div class="col-3 mt-5 p-0">
            <button nz-button nzType="link"  (click)="showTags()">Add</button>
            <button [disabled]="!newSelectionFormFiedls['locationSetupId']"
               class="btn btn-sm text-primary border-0 me-1" (click)="showTagEdit(newSelectionFormFiedls.locationSetupId)">
               Edit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-12 ">
          <button nz-button  [(ngModel)]="newSelectionFormFiedls['isSingle']" name="isSingle" (click)="Single()">Single</button>
          <button nz-button [(ngModel)]="newSelectionFormFiedls['isShared']" name="isShared" (click)="Shared()">Shared</button>
        </div>
      </div>
      <div class="row" *ngIf="showSelector" >
        <div class="col-10  mt-3">
          <label class="sub-text">Single Allowance</label>
          <nz-input-group nzAddOnBefore="$">
            <input [(ngModel)]="newSelectionFormFiedls['singleAllowanceNumber']" name="singleAllowanceNumber" nz-input placeholder="0.00" type="number" [ngModel]="convertToRupees(addedCost)"
              (ngModelChange)="updateConvertedValue($event)" />
          </nz-input-group>
        </div>
      </div>
      <div class="row gx-0" *ngIf="ShowSelector2">
        <div class="col-8 mt-4">
          <label class="sub-text">Shared Allowance <span class="text-danger">*</span></label>
          <nz-select [(ngModel)]="newSelectionFormFiedls.sharedAllowanceId"
                     (blur)="onSharedAllowanceBlur()"
                     (focus)="onSharedAllowanceFocus()"
                     [ngClass]="{'is-invalid': showSharedAllowanceError}"
                     style="width: 100%;">
              <nz-option *ngFor="let option of shareAllowanceOption" [nzLabel]="option.title" [nzValue]="option.id"></nz-option>
              <div *ngIf="showSharedAllowanceError" class="text-danger">
                Required
            </div>
            </nz-select>

      </div>
        <div class="col-3 mt-5 p-0">
          <button nz-button nzType="link">Add</button>
          <button nz-button nzType="link" class="ps-0">Edit</button>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mt-3">
          <label class="sub-text">Deadline </label><br>
          <nz-button-group>
            <button nz-button  [(ngModel)]="newSelectionFormFiedls['isChooseDate']" name="isChooseDate" nzType="default" (click)="toggleDateFields()">Choose Date</button>
            <button nz-button nzType="default" [(ngModel)]="newSelectionFormFiedls['isLinkedToScheduleItem']" name="isLinkedToScheduleItem" (click)="toggleScheduleItemFields()">Link to add</button>
          </nz-button-group>
        </div>
      </div>
      <div class="row  mt-4" *ngIf="showDateFields">
        <div class="col-3">
          <label class="sub-text">Deadline</label>
          <nz-date-picker nzPlaceHolder=" " nzSuffixIcon="false" [(ngModel)]="newSelectionFormFiedls['dedlineOn']"  name="dedlineOn"></nz-date-picker>
        </div>

        <div class="col-3">
          <label class="sub-text">Time</label><br>
          <nz-time-picker [(ngModel)]="newSelectionFormFiedls['time']" name="time"   nzFormat="HH:mm" nzPlaceHolder=" "></nz-time-picker>
        </div>
      </div>

      <div class="row  gx-0" *ngIf="showScheduleItemFields">
        <div class="col-9 mt-4">
          <label class="sub-text">Schedule Item <span class="text-danger">*</span> </label>
          <nz-select [(ngModel)]="newSelectionFormFiedls['scheduleItemParameterId']" name="scheduleItemParameterId" style="width: 100%" nzShowSearch [lang]="true" nzAutoFocus="true" nzPlaceHolder="Unassigned"
            [(ngModel)]="selectedValue">
            <nz-option *ngFor="let option of scheduleItemOption" [nzLabel]="option.title" [nzValue]="option.id"></nz-option>
          </nz-select>
        </div>
        <div class="col-3 mt-5 p-0">
          <button nz-button nzType="link">Add</button>
          <button nz-button nzType="link" class="ps-0">Edit</button>
        </div>

      </div>
      <div class="row  mt-3" *ngIf="showScheduleItemFields">
        <div class="col-2">
          <label class=" fw-bold">Due</label>
          <input  [(ngModel)]="newSelectionFormFiedls['dueNumberDays']" name="dueNumberDays" type="text" class="form-control formField" placeholder="0">
        </div>
        <div class="col-5" style="margin-top: 27px;">
          <label class=" fw-medium me-3" style="margin-left: -10px;">Days</label>
          <nz-radio-group [(ngModel)]="radioValue" nzName="radiogroup">
            <label [(ngModel)]="newSelectionFormFiedls['isBefore']" name="isBefore"  nz-radio nzValue="A">Before</label>
            <label [(ngModel)]="newSelectionFormFiedls['isAfter']" name="isAfter"  nz-radio nzValue="B" style="margin-left: -5px;">After</label>
          </nz-radio-group>
        </div>
      </div>
      <div class="row  mt-3 mb-4" *ngIf="showScheduleItemFields">
        <div class="col-2">
          <label class="sub-text mb-1">DeadLine</label>
          <p class="fw-bold">--</p>
        </div>
        <div class="col-3">
          <label class="sub-text">Time</label><br>
          <nz-time-picker [(ngModel)]="newSelectionFormFiedls['time']" name="time"   nzFormat="HH:mm" nzPlaceHolder=" "></nz-time-picker>
        </div>
      </div>
    </div>
  </div>
</nz-card>



<div class="card-container mt-2">
  <nz-tabset nzType="card">
    <nz-tab *ngFor="let tab of selectioTabs" [nzTitle]="selectioninnercardTabs(tab)">

      <ng-container *ngIf="tab === 1">
        <!-- <app-selection-choice></app-selection-choice> -->
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col text-center mt-2">
                <nz-empty [nzNotFoundContent]="CustomContent"></nz-empty>
                <ng-template #CustomContent>
                  <div class="row">
                    <div class="col-text-center">
                      <span class="pira-label">Adding choice will be avaibleafter saving </span>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="tab === 2">
        <!-- <app-selection-description ></app-selection-description> -->
        <div class="row">
          <div class="col-12">
            <label class="para-lable">Public Instructions</label>
            <!-- <div class="editor-container">
              <kendo-editor [value]="editorValue" [(ngModel)]="newSelectionFormFiedls['publicInstructions']" name="publicInstructions"
                [style]="{'flex-direction': 'column-reverse'}"></kendo-editor>
            </div> -->


            <ckeditor
            [(ngModel)]="editorValue['body']"
            name="notes"
            [editor]="Editor"
            [config]="config"
            class="size"
            id="editor">
          </ckeditor>


          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="para-lable">Internal Notes</label>
            <!-- <kendo-editor [value]="editorValue" [(ngModel)]="newSelectionFormFiedls['internalNotes']" name="internalNotes"
                [style]="{'flex-direction': 'column-reverse'}"></kendo-editor> -->

                <ckeditor
                [(ngModel)]="editorValue['body']"
                name="notes"
                [editor]="Editor"
                [config]="config"
                class="size"
                id="editor">
              </ckeditor>
          </div>
        </div>

      </ng-container>
      <ng-container *ngIf="tab === 3">
      <!-- <app-selection-lock-and-unlock></app-selection-lock-and-unlock> -->
      <nz-card [nzTitle]="locktitlemanage"  nzBordered="false">
        <ng-template #locktitlemanage>
            <h5>Lock or Unlock This Selection</h5>
        </ng-template>
        <div class="row">
          <div class="col">
         <span class="pira-label"> Approving any of the following chooice will unlock this Selection for approval</span>
        </div>
        </div>
        <div class="row">
          <div class="col mt-3 ">
           <button nz-button class="ant-btn-primary rounded-2" >Add/Edit Chooice</button>
          </div>
        </div>
        </nz-card>
      </ng-container>
      <ng-container *ngIf="tab === 4">
        <!-- <app-selection-files-discussion></app-selection-files-discussion> -->

        <nz-card nzBorderless="true" [nzTitle]="filestitle">
          <ng-template #filestitle>
            <h5  class="pira-label">Attachments</h5>
          </ng-template>
            <div class="row ">
              <div class="col-12 p-0 ">
                <button class="me-2 ms-2" nz-button (click)="uploadFiles()">Add</button>
                <button nz-button (click)="createNew()">Create New Doc</button>
                <button (click)="viewAll()" *ngIf="selectedFiles.length > 0" nz-button nzType="default"
                  class="rounded-1 me-2">View All ({{ selectedFiles.length }})</button>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-1 filUpload me-3" *ngFor="let file of selectedFiles">
                <div class="row gx-0 mt-2 p-0 m-0">
                  <div class="col-12 mt-2">
                    <span class="m-0 p-0d d-flex align-items-center justify-content-center"
                      style="border-radius: 6px; object-fit: cover;" nz-icon nzType="file-text"
                      nzTheme="outline"></span>
                  </div>
                </div>
                <div class="row p-0 m-0">
                  <div class="col-12">
                    <label class="ListViewJobname">{{file.name}}</label>
                  </div>
                </div>
                <div class="row p-0 m-0">
                  <div class="col-12">
                    <i nzTrigger="click" nzType="down" nz-dropdown [nzDropdownMenu]="menu4"
                      class="fa-solid fa-chevron-down fw-bolder"></i>
                    <nz-dropdown-menu #menu4="nzDropdownMenu">
                      <ul nz-menu>
                        <li nz-menu-item><i class="fa-solid fa-pen me-2"></i>Edit Online</li>
                        <li (click)="removeFile(file)" nz-menu-item><i
                            class="fa-regular fa-trash-can me-2"></i>Delete</li>
                      </ul>
                    </nz-dropdown-menu>
                  </div>
                </div>
              </div>
            </div>
          </nz-card>

          <nz-card nzBorderless="true" [nzTitle]="messagingtitle"  *ngIf="selectionReponse === -1">
            <ng-template #messagingtitle>
              <h5 class="pira-label">Messaging</h5>
            </ng-template>
            <div class="row">
              <div class="col">
                <h5 class="pira-label">Message available after save</h5>
              </div>
            </div>
          </nz-card>

          <!-- sufyan working add -->
           <!-- new -->
           <ng-container *ngIf="selectionReponse">
            <messaging-common
            [Job_ID]="jobID"
            [Form_Name_ID]="formNameID"
            [Message_header_ID]="messagingId"
            [Message_headerSet_ID]="messagingIdRow"
            >
            </messaging-common>
          </ng-container>
          <!-- new -->
          <!-- sufyan working add -->

      </ng-container>
      <ng-container *ngIf="tab === 5">
        <!-- <app-selection-participation ></app-selection-participation> -->

        <div class="row">
          <div class="col-12">
            <nz-card nzBorderless="true" [nzTitle]=" Ownertitle">
              <ng-template #Ownertitle>
                <h5>Owner</h5>
              </ng-template>
              <div class="row">
                <div class="col">
                  <span>Miteshkumar Manubhai Sathavara and Priyanka Miteshkumar Sathavara</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-2">
                  <label class="sub-text">Allowed to</label>
                </div>
                <nz-radio-group [(ngModel)]="radioValues">
                  <label [(ngModel)]="newSelectionFormFiedls['isApproveChoicesOnly']" name="isApproveChoicesOnly"  nz-radio nzValue="A" class="mt-2 ms-3">Approve Choices Only</label><br>
                  <label [(ngModel)]="newSelectionFormFiedls['isAddEditAndApproveChoices']" name="isAddEditAndApproveChoices" nz-radio nzValue="B" class="mt-2 ms-3">Add/Edit & Approve Choices</label>
                </nz-radio-group>
              </div>
            </nz-card>
            <nz-card [nzTitle]="Venderstitle" nzBordered="false">
              <ng-template #Venderstitle>
                <h5>Venders</h5>
              </ng-template>
              <div class="row">
                <div class="col-5 ">
                  <label class="sub-text">
                    Venders <i class="bi bi-info-circle-fill text-secondary  ms-1"></i>
                  </label>
                  <nz-tree-select  style="width: 100%" [(ngModel)]="newSelectionFormFiedls['createSelectionSubVenderParameterRequests']" name="createSelectionSubVenderParameterRequests"
                  [nzNodes]="vendersNodes" nzShowSearch nzAllowClear="false" nzCheckable nzCheckAll="true"
                  nzPlaceHolder="Select Status">
                </nz-tree-select>
                </div>
              </div>
              <div class="row ">
                <div class="col-12 mt-2">
                  <label class="sub-text">Allowed to</label>
                </div>
                <nz-radio-group [(ngModel)]="radioValuess">
                  <label [(ngModel)]="newSelectionFormFiedls['isApproveChoicesOnly']" name="isApproveChoicesOnly"  nz-radio nzValue="A" class="mt-2 ms-3">Approve Choices Only</label><br>
                  <label [(ngModel)]="newSelectionFormFiedls['isAddEditAndApproveChoices']" name="isAddEditAndApproveChoices" nz-radio nzValue="B" class="mt-2 ms-3">Add/Edit & Approve Choices</label>
                </nz-radio-group>
              </div>
              <!-- <div class="row mt-1">
                <label nz-radio nzValue="A">View Pricing /Request Only</label>
              </div>
              <div class="row mt-1">
                <label nz-radio nzValue="B">Add/Edit Choices</label>
              </div> -->
            </nz-card>
            <nz-card [nzTitle]="Installerstitle" nzBordered="false">
              <ng-template #Installerstitle>
                <h5>Installers</h5>
              </ng-template>
              <div class="row">
                <div class="col">
                  <label class="sub-text">
                    Installers <i class="bi bi-info-circle-fill text-secondary  ms-1"></i>
                  </label>

                  <div class="row">
                    <div class="col-5 mt-2">
                      <nz-tree-select  style="width: 100%" [(ngModel)]="newSelectionFormFiedls['createSelectionInstallerParameterRequests']" name="createSelectionInstallerParameterRequests"
                        [nzNodes]="installarNodes" nzShowSearch nzAllowClear="false" nzCheckable nzCheckAll="true"
                        nzPlaceHolder="Select Status">
                      </nz-tree-select>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col mt-1">
                <label class="sub-text">Allowed to</label>
              </div>
              <div class="col mt-2">
                <label>View Only</label>
              </div>
            </nz-card>

          </div>
        </div>
      </ng-container>



    </nz-tab>
  </nz-tabset>
</div>

</div>
</div>
<ng-template #SelectionModelFooter>
  <button
  [nzType]="getButtonType()"
  nz-button
  [disabled]="!newSelectionFormFiedls.title"
  (click)="saveSelectionForm()">
    Save & Close
</button>
</ng-template>
</nz-modal>




<!-- new modal open -->

<nz-modal nzWidth="35%" [(nzVisible)]="isTagVisible" [nzTitle]="CategoryTitle" [nzFooter]="divsionTradeFooter"
  [nzBodyStyle]="{ background: '#f1f4fa', padding: '14px' }" [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="showConfirmData()">
  <ng-template #CategoryTitle>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0">Add Selection Category</h1>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <form [formGroup]="CategoryField">
      <nz-card [nzBorderless]="false" nzTitle="Add Selection Category">
        <div class="row">
          <div class="col">
            <label class="sub-text">Title<span class="text-danger fw-bolder">*</span></label>
            <input id="inputField" formControlName="name" type="text" class="form-control formField" [ngClass]="{
                error:
                  CategoryField.get('name')?.invalid &&
                  CategoryField.get('name')?.touched
              }" />
            <div *ngIf="
                CategoryField.get('name').hasError('required') &&
                CategoryField.get('name').touched
              " class="text-danger p-error">
              Required
            </div>
          </div>
        </div>
      </nz-card>
    </form>
  </div>
  <ng-template #divsionTradeFooter>
    <div class="mt-2 align-items-center">
      <button *ngIf="isLoadingEdit" nz-button nzType="default" class="rounded-1 me-0" (click)="ConfirmDeleteTags()">
        Delete
      </button>

      <button nz-button nzType="primary" class="rounded-1 me-0" (click)="SaveOrUpdateData()">
        {{ isLoadingEdit ? "Update" : "Save" }}
      </button>



    </div>
  </ng-template>
</nz-modal>

<!-- new modal open -->
