import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-jobs-summary-map',
  templateUrl: './jobs-summary-map.component.html',
  styleUrls: ['./jobs-summary-map.component.css']
})
export class JobsSummaryMapComponent {
  @Output() cancel = new EventEmitter<void>();

  uploadfiles=true;
  mapclose(): void {
  this.cancel.emit();
  }

}
