<nz-modal
  nzWidth="60%"
  [(nzVisible)]="selectionModelhandle"
  [nzTitle]="companylogo1"
  [nzFooter]="companylogo2"
  (nzOnCancel)="selectionModelCancel()"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '78vh',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '18px' }"
>
  <ng-template #companylogo1>
    <div class="p-0 m-0">
      <h1 class="p-0 m-0">Conversation</h1>
    </div>
  </ng-template>

  <div class="content" *nzModalContent>
        <nz-card [nzTitle]="heading" class="card border-0 rounded-2">
          <ng-template #heading>
              <h2 class="p-0 m-0">
                Conversation Information
              </h2>
          </ng-template>

            <div class="details" *ngIf="editDataResponse">
              <div class="row">
                <label class="sub-text mb-1">Job</label>
                <div class="ListViewJobname mb-1">{{editDataResponse?.jobInformation?.name}}</div>
              </div>
              <div class="row">
                <label class="sub-text mb-1">Type</label>
                <div class="ListViewJobname mb-1">{{editDataResponse?.messaging?.messageFormType}}</div>
              </div>
            
              <div class="row">
                <label class="sub-text mb-1">Title</label>
                <div class="ListViewJobname mb-1">{{editDataResponse?.title}}</div>
              </div>
            </div>
           
            <div class="row">
              <div class="col-12">
                <label class="sub-text">Comments</label>
                <i
                  class="fa-solid fa-eye ms-2"
                  nz-popover
                  [nzPopoverContent]="Daily"
                  nzPopoverPlacement="right"
                ></i>
                <ng-template #Daily>
                  <div
                    class="rounded-2 p-0 m-0"
                    style="width: 210px; font-size: 13px"
                  >
                    <div class="card border-0 mt-0 m-0 p-0">
                      <div class="card-head border-bottom m-0 p-0">
                        <div class="row py-2">
                          <h5 nz-typography>
                            <span class="ms-3">Who Can View?</span>
                          </h5>
                        </div>
                      </div>
                      <div class="card-body">
                        <p>
                          16 users can currently view all or some of this
                          conversation (details on the comment).
                        </p>
                      </div>
                    </div>
                    <div class="card border-0 mt-0">
                      <div class="card-head m-0 p-0">
                        <div class="row py-2">
                          <h5 nz-typography>
                            <span class="ms-3">Internal Users</span>
                          </h5>
                        </div>
                      </div>
                      <div class="card-body">
                        <p>
                          Abbas Hussain, Accounts Assistant, Construction Admin,
                          Construction Manager, ddd-1 fffffff, Imran Ahmed,
                          Lucas King, Mesum Hussain, Muhammad Umer, Muhammad
                          Yousuf, Pre-Construction Admin, Sajad Hassani, sds
                          dsd, Syed Ahsan Kazmi, Testingoor testingNoor1, Wasi
                          Shah
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>

            <div class="row mt-2 mb-3">
              <div class="col-12">
                <textarea
                  class="form-control formField"
                  [nzAutosize]="{ minRows: 4, maxRows: 8 }"
                  nz-input
                ></textarea>
              </div>
            </div>

            <div class="card-body">
              <div class="row mt-3">
                <div class="col-12">
                  <nz-collapse
                    [nzBordered]="false"
                    class="conection border-0 rounded-2"
                  >
                    <nz-collapse-panel
                      class="border-0 rounded-2"
                      #p
                      [nzExpandedIcon]="expandedIcon"
                      [nzHeader]="tileetwe"
                    >
                      <div class="row m-0 p-1" #p>
                        <div class="col">
                          <label class="sub-text h5">Show</label>
                        </div>
                        <div class="col-md-12">
                          <label
                            [(ngModel)]="messagesFormValue.isViewOwner"
                             name="isViewOwner"
                            nz-checkbox
                            name="isCopyActivityAddresses"
                            >Owner</label
                          >
                        </div>

                        <div class="col-md-12 mb-3">
                          <label
                          [(ngModel)]="messagesFormValue.isViewSubVendor"
                           name="isViewSubVendor"
                            nz-checkbox
                            name="isCopyActivityAddresses"
                            >Subs/Vendors</label
                          >
                        </div>
                        <div class="col align-items-center">
                          <label class="sub-text h5"
                            >Notify
                            <span
                              nz-popover
                              [nzPopoverContent]="CollapseMessage"
                              nzPopoverPlacement="left"
                              nz-icon
                              nzType="info-circle-fill"
                            ></span
                          ></label>
                        </div>
                        <div class="col-md-12 mb-1">
                          <div class="input-group">
                            <nz-tree-select
                            [nzNodes]="combinedNodesMessaging"
                              [(ngModel)]="
                                messagesFormValue[
                                  'createMessagingUserNotifyParameterRequests'
                                ]
                              "
                              name="createMessagingUserNotifyParameterRequests"
                              style="width: 100%"
                              nzShowSearch
                              nzAllowClear="false"
                              nzCheckable
                              nzCheckAll="true"
                              (ngModelChange)="onNodeSelectionChange($event)"
                              [nzVirtualHeight]="'200px'"
                           
                              
                            >
                            </nz-tree-select>
                          </div>
                        </div>
                      </div>
                      <ng-template #CollapseMessage>
                        <div class="rounded-2">
                          <p style="width: 230px; font-size: 13px">
                            Owners will appear in this list if they have
                            access to the entity this comment is
                            associated to.
                          <br >
                          <br>
                          <span>
                            A Sub/Vendor will appear on this list if they
                            have access to the entity this comment is
                            associated to and if they have viewing access
                            to the job.
                          </span>
                          <br>
                          <br>
                          <span>
                            An Internal User will appear in the list if
                            they have access to the entity the comment is
                            associated with, if they have access to the
                            job, and if they have their notifications
                            turned on for new comments on the entity.
                            </span>
                          </p>
                        </div>
                      </ng-template>

                      <ng-template #tileetwe>
                        <h5
                          nz-typography
                          class="p-0 w-100 h-100 m-0 mt-0 mb-0 border-0"
                        >
                          <span class="LColaPP ms-3"
                            >Viewer & Notification Settings</span
                          >
                        </h5>
                      </ng-template>
                      <ng-template #expandedIcon let-active>
                        {{ active }}
                        <span
                          nz-icon
                          nzType="caret-right"
                          class="ant-collapse-arrow"
                          [nzRotate]="p.nzActive ? 90 : -0"
                        ></span>
                      </ng-template>
                    </nz-collapse-panel>
                  </nz-collapse>
                  <nz-divider></nz-divider>
                </div>
              </div>
            </div>
           
            <div class="row mt-3">
              <div class="col-2"></div>
              <div class="col-10">
                <div
                  class="card border-0 mt-0"
                  id="hoverRow"
                  style="background: #e6f6ff"
                >
                  <div class="card-head">
                    <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-10">
                            <div class="ListViewJobname p-3">
                              Oct 18, 2023, 3:39 AM
                            </div>
                          </div>
                          <div class="col-2 p-3 text-end" id="hoverIcons">
                            <i
                              nz-popover
                              [nzPopoverContent]="Summary"
                              nzPopoverPlacement="top"
                              class="fs-5 text-primary fa-solid fa-trash me-3"
                            ></i>
                            <ng-template #Summary>
                              <div class="rounded-2">
                                <p style="font-size: 13px">Delete comment</p>
                              </div>
                            </ng-template>
                            <i
                              nz-popover
                              [nzPopoverContent]="Summary1"
                              nzPopoverPlacement="top"
                              class="fs-5 text-primary fa-solid fa-eye me-2"
                            ></i>
                            <ng-template #Summary1>
                              <div class="rounded-2">
                                <p style="font-size: 13px">
                                  Edit viewing permissions
                                </p>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-12">
                        <div
                          class="ms-5 text-primary"
                          style="text-decoration: underline"
                        >
                          text
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body"></div>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-10">
                <div
                  class="card border-0 mt-0"
                  id="hoverRow"
                  style="background: #e6f6ff"
                >
                  <div class="card-head">
                    <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-10">
                            <div class="ListViewJobname p-3">
                              Oct 18, 2023, 3:39 AM
                            </div>
                          </div>
                          <div class="col-2 p-3 text-end" id="hoverIcons">
                            <i
                              nz-popover
                              [nzPopoverContent]="Summary"
                              nzPopoverPlacement="top"
                              class="fs-5 text-primary fa-solid fa-trash me-3"
                            ></i>
                            <ng-template #Summary>
                              <div class="rounded-2">
                                <p style="font-size: 13px">Delete comment</p>
                              </div>
                            </ng-template>
                            <i
                              nz-popover
                              [nzPopoverContent]="Summary1"
                              nzPopoverPlacement="top"
                              class="fs-5 text-primary fa-solid fa-eye me-2"
                            ></i>
                            <ng-template #Summary1>
                              <div class="rounded-2">
                                <p style="font-size: 13px">
                                  Edit viewing permissions
                                </p>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-12">
                        <div class="ms-5">Again watching</div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body"></div>
                </div>
              </div>
            </div>
        </nz-card>
  </div>
  <ng-template #companylogo2> </ng-template>
</nz-modal>
