import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { LookUpStandardResponse, LookupFilter, LookupFilterResponse, UpdateFilterPayload, UpdateLookupSetupForSetAsDefaultRequest } from 'src/app/shared/component/Models/LookupModels';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class LookupFilterService {
  private baseUrl: string = environment.apiUrl;
  getStandardFilter = new BehaviorSubject<ResponseModelArray<LookupFilterResponse>>(null);
  $getStandardFilter = this.getStandardFilter.asObservable();

  constructor(private http: HttpClient) {}
  postData(lookUpStandard: LookupFilter): Observable<ResponseModelArray<LookUpStandardResponse>> {
    const apiUrl = `${this.baseUrl}LookUpStandard/getAll-ByFormNameId?FormNameId=${lookUpStandard.formNameId}`;
    return this.http.post<any>(apiUrl, lookUpStandard).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  saveStandardFilterData(data: any): Observable<any>{
    const apiUrl = `${this.baseUrl}LookupFilter`;
    return this.http.post<any>(apiUrl, data).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
      );
    }


    private base_Url: string = environment.apiUrl + 'LookupFilter/';
    deleteData(globalId: string): Observable<any> {
      return this.http.delete<any>(`${this.base_Url}?globalId=${globalId}`).pipe(
        catchError((error: any) => {
          console.error('Error:', error);
          return throwError(error);
        })
      );
    }

  getStandardFilterByFormPageId(formNameId: number){
    const apiUrl = `${this.baseUrl}LookupFilter/getAll-ByFormNameId?FormNameId=${formNameId}`;

    return this.http.post<ResponseModelArray<LookupFilterResponse>>(apiUrl, formNameId).subscribe(
      (data) => {
        this.getStandardFilter.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }


  // getLookUpValuesByFormNameId(pageId: number): Observable<any> {
  //   const apiUrl = `${this.baseUrl}LookupNameSetup/getAll-byFormNameId?formNameId=${pageId}`;
  //   return this.http.post<any>(apiUrl, null).pipe(
  //     catchError((error: any) => {
  //       console.error('Error:', error);
  //       return throwError(error);
  //     })
  //   );
  // }

  getLookUpValuesByFormNameId(pageId: number): Observable<any> {
    const apiUrl = `${this.baseUrl}LookupNameSetup/getAll-byFormNameId?formNameId=${pageId}`;
    return this.http.post<any>(apiUrl, null).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  getDataId(id: any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}LookupFilter/getBy-Id?id=${id}`);
  }

  updateData(data: UpdateLookupSetupForSetAsDefaultRequest): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    const options = { headers: new HttpHeaders(headers) };
    return this.http.put<any>(`${this.baseUrl}LookupFilter/IsSetAsDefault`, data, options);
  }



  // getStandardFiltersByFormPageId(formNameId: number){
  //   const apiUrl = `${this.baseUrl}LookupFilter/getAll-ByFormNameId?FormNameId=${formNameId}`;
  //   return this.http.post<ResponseModelArray<LookupFilterResponse>>(apiUrl, formNameId).subscribe(
  //     (data) => {
  //       this.getStandardFilter.next(data);
  //     },
  //     (error) => {
  //       console.error('Error fetching internal users:', error);
  //     }
  //   );
  // }


}
