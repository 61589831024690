<div class="card-head pt-2">
    <h5 class="fw-medium">Predecessors</h5>
</div>
<hr>
<div  *ngFor="let row of rows;let i = index" class="row">
  <div class="col-6">
      <label class="cityDropdown">Name</label>
      <div class="input-group">
        <nz-select  nzShowSearch [lang]="true" nzAutoFocus="true" nzPlaceHolder="3 Days Before" [(ngModel)]="selectedValue">
            <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
            <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
            <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
        </nz-select>
    </div>
     
  </div>
  <div class="col-3  ">
      <label for="" class="cityDropdown">Type</label>
      <nz-select nzShowSearch [lang]="true" nzAutoFocus="true"  nzPlaceHolder="Select a person" [(ngModel)]="selectedValue">
          <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
          <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
          <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
      </nz-select>
  </div>
  <div class="col-2 mt-4" style="padding-left: 0;">
      <div class="input-group mb-3">
             <nz-input-group  nzAddOnAfter="Days">
              <nz-input-number [(ngModel)]="demoValue" nzPlaceHolder="Recipient" ></nz-input-number>
            </nz-input-group>
      </div>
  </div>
  <div class="col-1 mt-4" (click)="removeRow(i)" style="padding-left: 0;">
    <h4><i class="bi bi-trash3" ></i></h4>

  </div>
</div>
<div class="row mt-4">
<div class="col-md-4">
    <button class="btn text-primary" style="cursor: pointer;padding: 5px;" (click)="addRow()"
        [hidden]="rows.length >= 5"><i class="bi bi-plus-circle-fill"></i>Add
        Predecessor</button>
</div>
</div>