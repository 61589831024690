<div class="row famly-inn gx-3">
    <div class="col-12">
        <div class="card border-0 mt-0">
          <div class="card-head border-bottom">
              <div class="row mb-0 p-2 d-flex align-items-center">
                <div class="col">
                  <span class="fw-medium fs-6 ms-2">Cost Items</span>
                </div>
                <div class="col- text-end">
                  <div nz-row class="text-end">
                    <div nz-col [nzSpan]="18" [nzPush]="6">
                        <nz-space>
                            <button nz-button nzType="default" class="rounded-1 ms-0">Expoprt</button>
                            <button (click)="ImportWizard()"  nz-button nzType="default" class="rounded-1 ms-2">Import Cost Items</button>
                            <button nz-button nzType="default" class="rounded-1 ms-2"><i class="bi bi-funnel me-1"></i>  Filter</button>
                            <button (click)="CostItem()"   nz-button nzType="primary" class="rounded-1 ms-2">New Cost Item</button>
                        </nz-space>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div class="card-body">
          <div class="row">
            <div class="col-12">
             
            </div>
          </div>
          </div>
        </div>
    </div>
</div>

<!-- Catalog -->
<nz-modal
    nzWidth="65%"
    [(nzVisible)]="costitem"
    [nzTitle]="costitem1"
    [nzFooter]="costitem2"
    (nzOnCancel)="CloseCostItem()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '400px', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
    <ng-template #costitem1> <div class="row p-0 m-0">
        <h1 class="p-0 m-0 fw-medium">Cost Group Editor</h1></div>
    </ng-template>
    <div  class="cantent" *nzModalContent>
      <div class="row famly-inn gx-3">
        <div class="col-12">
            <div class="card border-0 mt-0">
              <div class="card-head border-bottom">
                  <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                      <span class="fw-medium fs-6 ms-2">New Cost Group</span>
                    </div>
                  </div>
                </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text">Cost Code <span class="fw-bolder text-danger">*</span></label>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a person" [(ngModel)]="selectedValue">
                          <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                          <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                          <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                        </nz-select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text">Title <span class="fw-bolder text-danger">*</span></label>
                      <input type="text" class="form-control formField">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text">Description</label>
                        <textarea
                        class="form-control formField"
                        style="height: 55px; resize: none;"
                        (input)="autoAdjustTextAreaHeight($event)"
                    ></textarea>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text">Internal Notes</label>
                        <textarea
                        class="form-control formField"
                        style="height: 55px; resize: none;"
                        (input)="autoAdjustTextAreaHeight($event)"
                    ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text">Cost Code <span class="fw-bolder text-danger">*</span></label>
                        <nz-select [(ngModel)]="multipleValue" [nzSize]="size" nzMode="multiple" nzPlaceHolder="Please select">
                          <nz-option *ngFor="let option of listOfOption" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                        </nz-select>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
            </div>
            <div class="card border-0 mt-3">
              <div class="card-head border-bottom">
                  <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                      <span class="fw-medium fs-6 ms-2">Cost Information</span>
                    </div>
                  </div>
                </div>
              <div class="card-body">
                <div class="row">
              <div class="col-12">
                <div class="row mt-0 mb-0">
                  <div class=" col-12">
                      <label (change)="onCheckboxChange11()" nz-checkbox [(ngModel)]="checked"><span class="pira-label me-1">Include Quantity</span>
                        <i
                        class="bi bi-info-circle-fill text-secondary"
                        nz-popover
                        [nzPopoverContent]="JobTypes"
                        nzPopoverPlacement="top"></i>
                        <ng-template #JobTypes>
                          <div class="rounded-2">
                            <p style="height: 100px; width: 210px;font-size: 13px;">
                              Includes Quantity as a part of the Cost Item. Quantity will be synced to your Catalog and cannot be edited when the Cost Item is used in the program without breaking the link to your Catalog.
                            </p>
                          </div>
                        </ng-template>
                      </label>
                  </div>
              </div>
                <div class="row mt-3 mb-0">
                  <div class=" col-12">
                      <label (change)="onCheckboxChange12()" nz-checkbox [(ngModel)]="checked1"><span class="pira-label me-1">Include Owner Price</span>
                        <i
                        class="bi bi-info-circle-fill text-secondary"
                        nz-popover
                        [nzPopoverContent]="Price"
                        nzPopoverPlacement="top"></i>
                        <ng-template #Price>
                          <div class="rounded-2">
                            <p style="height: 100px; width: 210px;font-size: 13px;">
                              Includes markup and owner price as a part of the Cost Item. Markup and owner price will be synced to your Catalog and cannot be edited when the Cost Item is used in the program without breaking the link to your Catalog.
                            </p>
                          </div>
                        </ng-template>
                      </label>
                  </div>
              </div>
                <div class="row mt-0 mb-0">
                  <div class=" col-3">
                    <label class="sub-text">Unit Cost</label>
                    <div class="input-group mb-3">
                      <span class="input-group-text d-flex justify-content-center align-items-center shadow-none" style="width: 5px; height: 30px;">$</span>
                      <input type="text" class="radius-work p-inputtext-sm form-control  shadow-none" formControlName="contractPrice"  style="height: 30px; border-top-left-radius: 0; border-bottom-left-radius: 0;">
                  </div>
                  </div>
                  <div class=" col-3">
                    <label class="sub-text">Unit</label>
                    <input type="text" class="form-control formField">
                  </div>
                  <div class=" col-2"  *ngIf="isCheckboxChecked11">
                    <label class="sub-text">Quantity</label><nz-input-number [nzMin]="1"[nzMax]="10"[nzStep]="1"type="number" ></nz-input-number>
                  </div>
                  <div class=" col-2">
                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text">Builder Cost</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-8 text-end">
                        <label class="sub-text">$0.00</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row gx-0"   *ngIf="isCheckboxChecked12">
                  <div class=" col-4">
                    <label class="sub-text">Markup</label>
                    <input type="text" class="form-control formField">
                  </div>
                  <div class=" col-1">
                    <div class="row">
                      <div class="col-3" style="margin-top: 30px;">
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a person" style="width: 50px" [(ngModel)]="selectedValue">
                          <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                          <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                          <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                        </nz-select>
                      </div>
                    </div>
                  </div>
                  <div class=" col-2">
                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text">Owner Price</label>
                      </div>
                    </div>
                    <div class="row">
                      <div  class="col-7 text-end">
                        <label class="sub-text">$0.00</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
                </div>
              </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <ng-template #costitem2>
        <button nz-button nzType="primary" class="rounded-1 me-0">Save & New</button>
        <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
    </ng-template>
</nz-modal>
<!-- Catalog -->
<nz-modal
    nzWidth="95%"
    [(nzVisible)]="importwizard"
    [nzTitle]="importwizard1"
    [nzFooter]="importwizard2"
    (nzOnCancel)="CloseImportWizard()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '400px', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
    <ng-template #importwizard1> <div class="row p-0 m-0">
        <h1 class="p-0 m-0 fw-medium">Import Wizard</h1></div>
    </ng-template>
    <div  class="cantent" *nzModalContent>
      <div class="row famly-inn gx-3">
        <div class="col-12">
            <div class="card border-0 mt-0">
              <div class="card-body">
                <div class="row">
                <div class="col-12">
                  <nz-steps [nzCurrent]="current" [nzStartIndex]="3" nzSize="small">
                    <nz-step nzTitle="Choose File"></nz-step>
                    <nz-step nzTitle="Map Columns"></nz-step>
                    <nz-step nzTitle="Confirm Import"></nz-step>
                  </nz-steps>
                </div>
                </div>
              </div>
            </div>
            <div class="card border-0 mt-3">
              <div class="card-head border-bottom">
                  <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                      <span class="fw-medium fs-6 ms-2">Choose File</span>
                    </div>
                  </div>
                </div>
              <div class="card-body">
                <div class="row">
                <div class="col-12">
                  <label class="pira-label">To begin the import process, select a spreadsheet with your data and click the next button to proceed.</label>
                </div>
              </div>
                <div class="row mt-3">
                <div class="col-2">
                  <button nz-button nzType="default" class="rounded-1">
                    <svg style="color: #202227;" viewBox="64 64 896 896" focusable="false" data-icon="upload" width="1.45em" height="1.45em" fill="currentColor" aria-hidden="true"><path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg>
                    <span class="ms-1">Browse from Computer</span></button>
                </div>
                <div class="col-3 ms-3">
                  <button nz-button nzType="default" class="rounded-1">
                    <svg style="color: #202227;" viewBox="64 64 896 896" focusable="false" data-icon="upload" width="1.45em" height="1.45em" fill="currentColor" aria-hidden="true"><path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg>
                    <span class="ms-1">Browse Buildertrend</span></button>
                </div>
              </div>
                </div>
              </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <ng-template #importwizard2>
        <button nz-button nzType="default" class="rounded-1 me-0 text-start">Previous</button>
        <button nz-button nzType="primary" class="rounded-1 me-0">Nex</button>
    </ng-template>
</nz-modal>
