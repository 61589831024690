import { CatalogNewCostItemComponent } from './../catalog-new-cost-item/catalog-new-cost-item.component';
import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
interface ItemData {
  id: number;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'catalog-catalog-tab-1',
  templateUrl: './catalog-catalog-tab-1.component.html',
  styleUrls: ['./catalog-catalog-tab-1.component.css']
})
export class CatalogCatalogTab1Component {


  @Input() flagForword : string


  proposalType: "leadProposal" | "variation";
  cancelSelection() {
    throw new Error('Method not implemented.');
  }
  checkbox: boolean;
  showChangeOrderButton: boolean = true;
  showChangeLeadProposalButton: boolean = false;
  mySelection: any;


  constructor(private modal: NzModalService, private route: ActivatedRoute,
    @Inject(NZ_MODAL_DATA) public leadProp: any,
  ) { }

  listOfData: readonly ItemData[] = [];
  checked = false;
  isCheckClicked: boolean = false;
  isButton: boolean = true;
  isButtons: boolean = false;
  setOfChecked: boolean = false;


  submitted: boolean = false;

  indeterminate = false;
  listOfCurrentPageData: readonly ItemData[] = [];
  setOfCheckedId = new Set<number>();

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
      this.setOfChecked = true;
    } else {
      this.setOfCheckedId.delete(id);
      this.setOfChecked = false;

    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange($event: readonly ItemData[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  ngOnInit(): void {


    this.listOfData = new Array(8).fill(0).map((_, index) => ({
      id: index,
      name: `Edward King`,
      age: 32,
      address: `London`
    }));


  }


  newCostItemOpenMethod(flag: string) {

    const modalRef = this.modal.create({

      nzContent: CatalogNewCostItemComponent,
      nzFooter: null,
      nzData: {
        NewProposalComponent: flag,

      },
      nzMaskClosable: false, // optional - to prevent closing on outside click
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }



  tableHeaders: string[] = [
    'Item',
    'Cost Type',
    'Description',
    'Unit Cost Unit',
    'Quantity Builder Cost',
    'Markup%',
    'Address',
    'Age',
    'Address'

  ];
  displayedColumns: string[] = this.tableHeaders.slice();

  // addToChange(isOrder: boolean): void {
  //   if (isOrder) {
  //     // Handle adding to Change Order logic
  //     console.log('Adding to Change Order');
  //   } else {
  //     // Handle adding to Change Lead Proposal logic
  //     console.log('Adding to Change Lead Proposal');
  //   }
  // }




  addToChange(value: boolean) {
    // Your addToChange logic here
    console.log('Adding to change with value:', value);
  }

  // checkBoxToggle() {
  //   this.isButton = true;
  //   if(this.isCheckClicked){
  //     this.isButton = false;
  //   }

  //   this.isCheckClicked = !this.isCheckClicked;
  //   // Agar checkbox unchecked hai, to submitted ko false karen
  //   if (this.isCheckClicked) {
  //     this.submitted = false;
  //   }
  // }


  checkBoxToggle() {
    this.isCheckClicked = !this.isCheckClicked;


    if (this.isCheckClicked) {
      this.isButton = false;
      this.isButtons = true;
    }
    else {
      this.isButton = false;
      this.isButtons = false
    }
  }
  getSelectedCount(): number {
    return this.mySelection.length;
  }








}
