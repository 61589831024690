<div class="row">
  <div class="col-12">
    <div class="row ">
      <div class="col-12 text-end">
        <nz-space>
          <button nz-button nzType="default" class="me-2 rounded-1">Print All</button>
          <button   (click)="openFilter(PROJECT_MANG_SELEC_ALLOWA_ASSIGN,PAGE_ID)" nz-button nzType="default" class="me-2 rounded-1"><i
              class="bi bi-funnel me-1 mt-3 "></i>Filter</button>
          <button nz-button nzType="primary" class="me-2 rounded-1">New Selection</button>
          <button nz-button nzType="primary" class="me-2 rounded-1" (click)="openNewAllowance()">New Allowance</button>
        </nz-space>
      </div>
    </div>

    <div class="row">
      <div class="col mt-3">
        <nz-card style="width:99%;">
          <div class="row">
            <div class="col">
              <h6>Grand Total </h6>
            </div>
            <div class="col text-end">
              <span class="me-3">Difference:$0.00</span>
              <span class="me-3">Price:$0.00</span>
              <span class="me-3">Allowance:$0.00</span>
            </div>
          </div>
        </nz-card>
      </div>
    </div>
    <div class="row">
      <div class="col text-center" style=" margin-top: 3rem;">
        <i class="bi bi-send-plus" style="font-size: 90px;"></i>
      </div>
      <div class="row text-center ">
        <h4>No Result</h4>
      </div>
      <div class="row text-center mt-1">
        <p>Adjust or clear your filters to find the Allowance you're</p>
      </div>
      <div class="row text-center mt-1 ">
        <p>Looking for</p>
      </div>
    </div>
  </div>
</div>




