import { LeadOpportunityMap6LeadComponent } from './../../lead-Opportunity-map/lead-opportunity-map-6-lead/lead-opportunity-map-6-lead.component';
import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LeadOpportunitiesNewLeadOpportunitiesComponent } from '../../Modal/lead-opportunities-new-lead-opportunities/lead-opportunities-new-lead-opportunities.component';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';

@Component({
  selector: 'lead-opportunity-map-header',
  templateUrl: './lead-opportunity-map-header.component.html',
  styleUrls: ['./lead-opportunity-map-header.component.css']
})
export class LeadOpportunityMapHeaderComponent {
  constructor(private modal: NzModalService,    private filterService: FilterSideMenuBarService,) { }
  switchValue1 = false;

   newScheduleItemModalOpen(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesNewLeadOpportunitiesComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }
  mapCardModelOpen() {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityMap6LeadComponent,
      nzFooter: null,

    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();

    });
  }
  openFilter(component: string, pageId: number) {
    this.filterService.toggleFilter(component, pageId);
  }

  hideField=false;

   hideFeildmethod(){
    this.hideField= !this.hideField;
  }

  // PAGE_ID: number = 5;
  // LEAD_OPPR_MAP: string = FiltersName.LEAD_OPPR_MAP;
  LEAD_OPPR_LIST_VIEW: string = FiltersName.LEAD_OPPR_LIST_VIEW;
   PAGE_ID: number = 36;

}
