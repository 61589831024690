import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { NzMessageService } from 'ng-zorro-antd/message';
import { WarrantyService } from 'src/Service/Project-Management/warranty/warranty.service';
import { WarrantySetupResponse } from 'src/app/completed-warranty-service/warrantyService';



@Component({
  selector: 'app-warranty',
  templateUrl: './warranty.component.html',
  styleUrls: ['./warranty.component.css']
})
export class WarrantyComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  text: string | undefined;
  selectedValue = null;
  demoValue = 3;
  customfieldinfo: boolean = false;
  warrantyVisible: boolean = true;
  counter: { label: string; value: number }[] = [];
  warrantyFormFields: WarrantySetupResponse ;
  WarrantyInformationResponse: any;
  WarrantyInformationRequest: WarrantySetupResponse;
  CreateWarrantyInformationRequest: null;
  constructor(private warrantyService: WarrantyService, private toastService: NzMessageService) { }

  ngOnInit(): void {
    for (let i = 1; i <= 30; i++) {
      this.counter.push({ label: i.toString(), value: i });
    }
    this.warrantyFormFields = {
      companyParameterId: 1,
      sendOwnerFeedbackReminderUpToDaysNo: 0,
      allowSubsVendorsAndOwnersToPrintWarrantyClaims: false
    }
    this.getWarrantySetupArray();
    
  }

  getWarrantySetup(): void {
    this.warrantyService.companyWarrantyget()
      .subscribe(res => {


        this.WarrantyInformationRequest = res.result[0];

        console.log("DATA = ", this.WarrantyInformationRequest);

      });
  }

  getWarrantySetupArray(): void {
    this.warrantyService.getCompanyWarranty()
      .subscribe(res => {
        let warranty= res.result;
        this.warrantyFormFields["sendOwnerFeedbackReminderUpToDaysNo"] = warranty.sendOwnerFeedbackReminderUpToDaysNo;
        this.warrantyFormFields["allowSubsVendorsAndOwnersToPrintWarrantyClaims"] = warranty.allowSubsVendorsAndOwnersToPrintWarrantyClaims;
      });
  }

  CustomFieldInfo() {
    this.customfieldinfo = true;
  }
  handleCancel2(): void {
    this.customfieldinfo = false;
  }
  warrantyCancel(): void {
    this.cancel.emit();
  }


  saveButton(): void {
    // console.log(this.warrantyFormFields)
    this.warrantyService.companyWarrantyPost(this.warrantyFormFields).subscribe((response) => {
      this.toastService.success('Warranty Setting Information Updated successfully!');
      this.cancel.emit();
    });
  }
}