import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { SalesComponent } from 'src/app/shared/component/sales/sales.component';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { LeadActivityFormComponent } from '../../lead-activity-form/lead-activity-form.component';
import {  LeadActivityTemplateResponse, LeadActivityTemplateResponses } from 'src/Models/LeadActivityTemplate/LeadActivityTemplate';
import { ApplicationUserForComboResponse } from 'src/Models/InternalUser/InternalUserJob';
import { LeadActivityTemplatesService } from 'src/Service/Sales-Lead-Activity-Templates/lead-activity-templates.service';
import { FormGroup } from '@angular/forms';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { LeadOpportunityImportTemplateComponent } from '../../Modal/lead-opportunity-import-template/lead-opportunity-import-template.component';
import { LeadActivityTypeService } from 'src/Service/Lead-Activity-Type/lead-activity-type.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { ClassicEditor, EditorConfig, Bold, Essentials, Heading, Indent, Italic, Link, List, ListProperties, MediaEmbed, Paragraph, Autoformat, Table, Undo, Font, FontSize, FontFamily, FontBackgroundColor, FontColor, BlockQuote, HorizontalLine, UndoEditing, ImageUpload, Image } from 'ckeditor5';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { ApplicationUserResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { AuditDetailsResponse, FilterFooterAudit } from 'src/Models/Footer/footer.model';
import { FooterService } from 'src/Service/Footer/footer.service';
import { LeadActivitiesService } from 'src/Service/Sales-Lead-Activities/lead-activities.service';
import { ThisReceiver } from '@angular/compiler';
import { LeadActivityResponse } from 'src/Models/LeadActivity/leadActivityClass';

interface ItemData {
  id: number;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'lead-opportunities-activity-templates-header',
  templateUrl: './lead-opportunities-activity-templates-header.component.html',
  styleUrls: ['./lead-opportunities-activity-templates-header.component.css']
})
export class LeadOpportunitiesActivityTemplatesHeaderComponent implements OnInit {
  GirdBindTemplateActivity: LeadActivityResponse[];
  @Output() cancel = new EventEmitter<void>();
  LeadOpportunitiesActivityTemplatesResponse: LeadActivityTemplateResponse;
  LeadOpportunitiesActivityTemplatesResponses: LeadActivityTemplateResponses;
  footerResponse: AuditDetailsResponse;
  @Input() LeadOpportunitiesActivityTemplatesResponseId: number;
  leadActivitiesResponse: LeadActivityResponse[] = [];
  @Output() leadActivityTemplateOnCompleted = new EventEmitter<void>();
  responseActivityTemplate: LeadActivityTemplateResponse[] = [];
  GridResponse: LeadActivityResponse[];
  ApplicationNodes: NzTreeNodeOptions[] = [];
  PAGE_ID: number = 38;
  isLoadingEdit: boolean = false;
  formFieldValues: any = {};
  assignedUser: ApplicationUserForComboResponse[] = [];
  isActivityTemVisible = true;
  isVisible = false;
  validateForm: FormGroup;
  isSubmitted: boolean = false;
  LEAD_OPPR_ACT_TEMPL: string = FiltersName.LEAD_OPPR_ACT_TEMPL;
  isLoading: boolean = false;
  checked = false;
  indeterminate = false;
  listOfCurrentPageData: readonly ItemData[] = [];
  listOfData: readonly any[] = [];
  setOfCheckedId = new Set<number>();
  isSaveAndNew: boolean = false;
  isSave: boolean = false;
  selectedValue = null;
  value: string[] = ['0-0-0'];
  inputValue: string = '';
  showCustomFields1 = false;
  leadActivity: LeadActivityResponse;
  public mySelection: string[] = [];
  isDeleteLoading: boolean = false;
  leadActivityCount: number;
  expandSet = new Set<number>();
  headIdTemp: number;
  conformCardShow = false;
  proposalconformCardShow = false;
  validationStates: { [key: string]: NzStatus } = {};
  validationMessages: { [key: string]: string } = {};
  changedFields: Set<string> = new Set<string>();
  DisabledNextActivity: boolean = false;
  initialFormFieldValues: any = {};
  modalErrorMessages: string[];
  isSaveAndClose: boolean = false;
  setIdS: number;
  confirmModal?: NzModalRef;
  
  

  constructor(
    private modal: NzModalService,
    private customerInformationService: CustomerInformationService,
    private LeadOpportunities: LeadOpportunitiesService,
    public toastService: NzMessageService,
    private FooterService:FooterService,

    public leadActivityTemplatesService: LeadActivityTemplatesService,
    private loadingIndicatoreService: LoadingIndicatorService,
    private cd: ChangeDetectorRef,
    private filterService: FilterSideMenuBarService,
    private leadActivitiesServices: LeadActivitiesService,
    private loadingIndicatorService: LoadingIndicatorService,
    private leadActivitiesService: LeadActivityTypeService,
    public accessLocalStorageService: AccessLocalStorageService,
    @Inject(NZ_MODAL_DATA) public helpSandData: any,

  ) {
      
  }
  ngOnInit(): void {
    console.log('TemplatesResponse By UpdateId',this.LeadOpportunitiesActivityTemplatesResponseId);
    this.leadActivityTemplatesService.getLeadTemplateById(this.LeadOpportunitiesActivityTemplatesResponseId).subscribe(res =>{
      this.LeadOpportunitiesActivityTemplatesResponses = res.result;
      this.patchedForm(this.LeadOpportunitiesActivityTemplatesResponses);

      console.log("@@@UpdateId@@@@",this.LeadOpportunitiesActivityTemplatesResponses);
      
      
    });
    
        
    this.getInitials(this.LeadOpportunitiesActivityTemplatesResponse);
    this.getDataByIdTemplate();
    this.leadActivityFormGroup();
    this.salesPersons();
    this.assignedUserSelecte();
  }
  getNameEffect(fullName: string): boolean {
    if (!fullName) return false;
    return fullName.charAt(0).toUpperCase() === 'S' || fullName === 'Sami Khan';
  }
  getInitials(user: any): string {
    if (!user) {
      return '';
    }
    const firstNameInitial = user.firstName ? user.firstName.charAt(0).toUpperCase() : '';
    const lastNameInitial = user.lastName ? user.lastName.charAt(0).toUpperCase() : '';
    return `${firstNameInitial}${lastNameInitial}`;
  }

getAvatarText(user: ApplicationUserResponse): string {
  if (!user || !user.fullName) {
    return '';
  }
  const nameParts = user.fullName.split(' ');
  const initials = nameParts.map(part => part.charAt(0)).join('');
  return initials;
}
  salesPersons() {
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      this.assignedUser = res.result;
    });
  }
  async getDataByIdTemplate() {
    if (this.LeadOpportunitiesActivityTemplatesResponseId) {
      this.loadingIndicatoreService.show();
      try {
        const templateResponse = await this.leadActivityTemplatesService.getLeadTemplateById(this.LeadOpportunitiesActivityTemplatesResponseId).toPromise();
        
        this.LeadOpportunitiesActivityTemplatesResponses = templateResponse.result;
        console.log('ActivityTemplates getby temp Id',this.LeadOpportunitiesActivityTemplatesResponses);
        this.setIdS = this.LeadOpportunitiesActivityTemplatesResponses.Id
        console.log('this.setIdS',this.setIdS);
        const templateIdResponse = await this.leadActivityTemplatesService.getTemplateId(this.LeadOpportunitiesActivityTemplatesResponseId).toPromise();
        if (templateIdResponse && Array.isArray(templateIdResponse.result) && templateIdResponse.result.length > 0) {
          this.GirdBindTemplateActivity = templateIdResponse.result;
          console.log('show Color Grids',this.GirdBindTemplateActivity);
        } else {
          console.log('No data available');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.loadingIndicatoreService.hide();
      }
    }
  }
  // assignedUserSelecte() {
  //   const userId = this.accessLocalStorageService.getUserLoginId();
  //   if (userId) {
  //     this.formFieldValues['applicationUserId'] = userId;
  //   }
  // }
  assignedUserSelecte() {
    const userId = this.accessLocalStorageService.getUserLoginId();
    if (userId) {
      this.formFieldValues['applicationUserId'] = userId;
    }
  
    // Store the initial form values after setting them
    this.storeInitialFormFieldValues();
  }
  leadActivityFormGroup(): void {
    this.formFieldValues = {
      globalId: '00000000-0000-0000-0000-000000000000',
      name: '',
      applicationUserId: 0,
      description: '',
      createLeadActivityRequest: {
        globalId: '00000000-0000-0000-0000-000000000000',
        leadOpportunityId: 0,
        leadActivityTemplateId: this.headIdTemp,
        activityTypeId: 0,
        colorId: 0,
        activityOn: new Date(),
        startTime: new Date(),
        endTime: new Date(),
        reminderId: 0,
        salesperson: false,
        lead: false,
        other: false,
        address: '',
        suburb: '',
        state: '',
        postalCode: '',
        followUpDate: new Date(),
        followUpTime: new Date(),
        description: '',
        markComplete: false,
        assignedUserId: 0,
        subject: '',
        body: '',
        createLeadActivityUserParameterRequests: []
      },
    };
  }
  patchedForm(responseActivityResponse: LeadActivityTemplateResponses): void {
      this.GridResponse = this.GirdBindTemplateActivity ;
    console.log('this.GridResponse',this.GridResponse);
    this.formFieldValues['globalId'] = responseActivityResponse?.GlobalId;
    this.formFieldValues['name'] = responseActivityResponse?.Name;
    this.formFieldValues['applicationUserId'] = responseActivityResponse?.ApplicationUserId;
    this.formFieldValues['description'] = responseActivityResponse?.Description;
    this.createAndModifiedByData(this.LeadOpportunitiesActivityTemplatesResponseId);

  }
  async storeInitialFormFieldValues(): Promise<void> {
    await new Promise(resolve => setTimeout(() => {
      this.initialFormFieldValues = JSON.parse(JSON.stringify(this.formFieldValues));
      resolve(true);
    }, 0));
  }



  saveActivityTemplate(actionType: 'save' | 'saveAndNew' | 'saveAndClose' = 'save', setAsNewLead: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
        return reject('Already processing');
      }
      if (actionType === 'saveAndNew') {
        this.isSaveAndNew = true;
      } else if (actionType === 'saveAndClose') {
        this.isSaveAndClose = true;
      } else {
        this.isSave = true;
      }

      if (setAsNewLead) {
        this.formFieldValues.asNewLead = true;
      }

      this.DisabledNextActivity = true; // Disable next activity
      this.isSubmitted = true;
      this.checkErrors(true); // Validate all fields before saving
      if (Object.values(this.validationStates).includes('error')) {
        setTimeout(() => {
          const errorElements = document.querySelectorAll('.top-of-modal');
          if (errorElements.length > 0) {
            const lastErrorElement = errorElements[errorElements.length - 1];
            lastErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 100);
        this.resetFlags();
        this.DisabledNextActivity = false; // Re-enable next activity
        console.log('Validation errors:', this.errorFields); // Debug log for validation errors
        return reject('Validation errors present');

      }

    
      this.LeadOpportunities.PostLeadTemplate(this.formFieldValues).subscribe(
        (response) => {
          this.formFieldValues.globalId = response.result.globalId;
          this.LeadOpportunitiesActivityTemplatesResponse = response.result;
          this.headIdTemp = response.result.id;
          console.log('this.headIdTemp',this.headIdTemp);
          this.isSubmitted = false;
          this.toastService.success('Saved Successfully');
          this.DisabledNextActivity = false;
          this.leadActivityTemplateOnCompleted.emit();
          this.storeInitialFormFieldValues();
          this.resetFlags();
          resolve();
          if (response) {
            this.createAndModifiedByData(response.result.id);
             } 
        },
        (error) => {
          console.error('Error saving data:', error);
          this.toastService.error('An error has occurred. Please try again.');
          this.DisabledNextActivity = false; // Re-enable next activity
          reject(error);
        }
        
      ).add(() => {
        this.resetFlags();
        this.conformCardShow = false;
        this.proposalconformCardShow = false;
        this.modalErrorMessages = []; // Reset modal error messages specifically
        this.isSubmitted = false;
      });
    });
    
  }
  saveAndCloseLeadAcitivitytemplate(): Promise<void> {
    return this.saveActivityTemplate('saveAndClose').then(() => {
      this.resetForm();
      this.handleActivityTemCancel();
    }).catch((error) => {
      console.error('Error during save and close:', error);
    });
  }
  saveAndNewLeadAcitivitytemplate() {
    this.saveActivityTemplate('saveAndNew').then(() => {
      this.resetForm();
    }).catch((error) => {
      console.error('Error during save and new:', error);
    }).finally(() => {
    });
  }


  async saveAndNewLeadActivityTemplate(): Promise<void> {
    try {
      await this.saveActivityTemplate('saveAndNew');
      this.resetForm();
      this.LeadOpportunitiesActivityTemplatesResponse = undefined;
      this.salesPersons();
      this.assignedUserSelecte();
    } catch (error) {
      console.error('Error occurred while saving and creating new template:', error);
    }
  }
  savecreateScheduleActivity() {
    this.saveActivityTemplate();
    this.conformCardShow = false;
  }
  resetFlags() {
    this.isSave = false;
    this.isSaveAndNew = false;
  }
  resetForm() {
    this.leadActivityFormGroup()
    this.validationStates = {};
    this.validationMessages = {};
    setTimeout(() => {
      const errorElements = document.querySelectorAll('.ant-input-status-error');
      errorElements.forEach((element: HTMLElement) => {
        element.classList.remove('ant-input-status-error');
      });
    }, 0);
    this.LeadOpportunitiesActivityTemplatesResponse = null;
    this.modalErrorMessages = [];
    this.isSubmitted = false;
  }
  updateInternalUser() {
    let data = this.formFieldValues;
    this.LeadOpportunities.PostLeadTemplate(data).subscribe(response => {
      setTimeout(() => {
        this.loadingIndicatorService.hide();
        this.toastService.success('New User Saved Successfully!');
        this.LeadOpportunitiesActivityTemplatesResponse = response.result;
        this.patchedForm(data);
        this.leadActivityTemplateOnCompleted.emit();
      }, 2000);
    }, (error) => {
      this.loadingIndicatorService.hide();
      console.error('Error saving internal user', error);
      this.toastService.error('Error saving user. Please try again.');
    });
  }
  openModalWithData(data: LeadActivityResponse): void {
    console.log('Opening modal with data:', data);
    const modalRef = this.modal.create({
      nzContent: LeadActivityFormComponent,
      nzFooter: null,
      nzData: {
        data: data
      }
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  openModal(): void {
    this.isVisible = true;
  }
  opencActivityTemModal(): void {
    this.isActivityTemVisible = true;
  }
  showActivityTemModal(): void {
    this.isActivityTemVisible = true;
  }
  handleActivityTemCancel(): void {
    this.cancel.emit();
    this.isActivityTemVisible = false;
  }
  isFormDirty(): boolean {
    return JSON.stringify(this.formFieldValues) !== JSON.stringify(this.initialFormFieldValues);
  }
  showConfirmCardLeadActivityTemplate(): void {
    const dontSaveText = 'Dont Save';
    
    if (this.isFormDirty()) {
      this.confirmModal = this.modal.confirm({
        nzTitle: '<b>Unsaved changes</b>',
        nzContent: 'You have unsaved changes, do you want to save your changes before closing?',
        nzOkText: 'Save',
        nzOkType: 'primary',
        nzIconType: null,
        nzCancelText: dontSaveText,
        nzOkLoading: this.isSaveAndClose,
        nzClosable: true,
        nzOnOk: () => {
          this.resetFlags();
          this.isSaveAndClose = true;
          this.confirmModal.destroy();
          return this.saveAndCloseLeadAcitivitytemplate()
            .then(() => {
              this.isSaveAndClose = false;
              this.saveAndCloseLeadAcitivitytemplate();
            })
            .catch((error) => {
              this.isSaveAndClose = false;
              console.error('Error saving lead:', error);
            });
        },
        nzOnCancel: () => {
          const clickedButton = (event.target as HTMLElement).textContent?.trim();
          if (clickedButton === dontSaveText) {
            this.confirmModal.destroy();
            this.resetForm();
            this.handleActivityTemCancel();
          } else {
            this.confirmModal.destroy();
          }
        },
        nzStyle: {
          top: '250px',
        },
        nzClassName: 'ant-modal-confirm-custom', // Custom class
      });
    } else {
      this.resetForm();
      this.handleActivityTemCancel();
    }
  }
  openFilter(component: string, pageId: number) {
    this.filterService.toggleFilter(component, pageId);
  }
  toggleCustomFields1() {
    this.showCustomFields1 = !this.showCustomFields1;
  }
  LeadSettings(): void {
    const modalRef = this.modal.create({
      nzContent: SalesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }
  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }
  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }
  onCurrentPageDataChange($event: readonly ItemData[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }
  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }
  NewLeadTemplate(): void {
    const modalRef = this.modal.create({
      nzContent: LeadActivityFormComponent,
      nzData: {
        leadData: this.LeadOpportunitiesActivityTemplatesResponse,
      },
      nzFooter: null
    });
    modalRef.componentInstance.NewLeadTemplate = this.LeadOpportunitiesActivityTemplatesResponse
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.leadActivityOnSavedCompleted.subscribe((data: LeadActivityResponse) => {
      if (!this.GridResponse) {
        this.GridResponse = [];
      }
      this.GridResponse?.push(data);
      if (!this.responseActivityTemplate) {
        this.responseActivityTemplate = [];
      }
      this.responseActivityTemplate?.push(this.LeadOpportunitiesActivityTemplatesResponse);
    });
  }
  OnClickActivity(activityData: LeadActivityResponse): void {
    const modalRef = this.modal.create({
      nzContent: LeadActivityFormComponent,
      nzData: {
        leadData: activityData,
      },
      nzFooter: null,
    });
    modalRef.componentInstance.activityResponseId = activityData.Id
    modalRef.componentInstance.NewLeadTemplate = this.LeadOpportunitiesActivityTemplatesResponse
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.leadActivityOnSavedCompleted.subscribe((updatedData: any) => {
      const existingActivity = this.GridResponse.find(a => a.Id === updatedData.id);

      if (existingActivity) {
        Object.assign(existingActivity, updatedData);
      } else {
        this.GridResponse.push(updatedData);
      }
      this.getDataByIdTemplate();
    });
  }
  ConfirmDelete(globalId: string): void {
    if (this.isDeleteLoading) {
      return;
    }
    const selectedCount = this.mySelection.length;
    this.modal.confirm({
      nzTitle: `Delete Lead Activity Template?  ${selectedCount > 1 ? 's' : ''}`,
      nzContent: 'Are you sure you want to permanently delete this Lead Activity Template?',
      nzOkText: `Delete Comment ${selectedCount > 1 ? 's' : ''}`,
      nzOkDanger: true,
      // nzOnOk: () => this.(),
      nzCancelText: 'Cancel',
      nzOnOk: () => this.userDelete(globalId),
      nzCentered: false,
      // nzWidth: '18%',
      nzBodyStyle: { 'border-radius': '30px', 'height': 'auto', 'padding-top': '15', },
      nzStyle: {
        top: '250px',
      },
      nzClassName: 'ant-modal-confirm-custom',
      nzCloseIcon: '',
      nzIconType: '',
    });
  }
  userDelete(globalId: string) {
    if (this.isDeleteLoading) {
      return;
    }
    this.isDeleteLoading = true;
    this.LeadOpportunities.deleteDataLookupNameSetup(globalId).subscribe(
      response => {
        setTimeout(() => {
          this.leadActivitiesService.setLeadActivityTypeResponse(response.result);
          this.leadActivityTemplateOnCompleted.emit(response.result);
          this.toastService.success('User deleted successfully!');
          this.handleActivityTemCancel();
          this.loadingIndicatorService.hide();
          this.isDeleteLoading = false;
        }, 2000);
      },
      error => {
        console.error('Error deleting user:', error);
        this.isDeleteLoading = false;
      }
    );
  }
  importactivity() {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityImportTemplateComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.activityImported.subscribe((newActivity: LeadActivityResponse) => {
      this.GridResponse.push(newActivity);
      modalRef.destroy();
    });
  }
  public Editor = ClassicEditor;
  public config: EditorConfig = {
    toolbar: {
      items: [
        'undo', 'redo', '|',
        'heading', '|', 'bold', 'italic', '|',
        'bulletedList', 'numberedList', 'indent', 'outdent', '|',
        'fontSize', 'fontFamily', 'fontBackgroundColor', 'fontColor', 'blockQuote',
        'image', 'horizontalLine', 'undoEditing', 'ListProperties', 'Autoformat'
      ]
    },
    plugins: [
      Bold,
      Essentials,
      Heading,
      Indent,
      Italic,
      Link,
      List,
      ListProperties,
      MediaEmbed,
      Paragraph,
      Autoformat,
      Table,
      Undo,
      Font,
      FontSize,
      FontFamily,
      FontBackgroundColor,
      FontColor,
      BlockQuote,
      Image,
      HorizontalLine,
      UndoEditing,
      ImageUpload
    ],
    fontSize: {
      options: [
        9, 11, 13, 'default', 17, 19, 21, 25, 28, 32, 36, 40, 44, 48, 52, 56, 60
      ]
    },
    fontFamily: {
      options: [
        'default', 'Arial, Helvetica, sans-serif', 'Courier New, Courier, monospace',
        'Georgia, serif', 'Times New Roman, Times, serif', 'Verdana, Geneva, sans-serif'
      ]
    }
  };
  conformhandleCancel(): void {
    this.conformCardShow = false;
  }
  async openConfirmDialogActivity(markComplete: boolean, type: string): Promise<void> {
    if (this.LeadOpportunitiesActivityTemplatesResponses ) {
      if (type === 'ACTIVITY') {
        const modalRef = this.modal.create({
          nzContent: LeadActivityFormComponent,
          nzData: {
            leadData: this.LeadOpportunitiesActivityTemplatesResponses,
            isMark: markComplete,
            headerIdValue: this.LeadOpportunitiesActivityTemplatesResponses.Id,
          },
          nzFooter: null,
        });
        modalRef.componentInstance.NewLeadTemplates = this.LeadOpportunitiesActivityTemplatesResponses;
        modalRef.componentInstance.cancel.subscribe(() => {
          modalRef.destroy(); 
        });
        modalRef.componentInstance.leadActivityOnSavedCompleted.subscribe(async () => { 
          try {
            let templateIdResponse;
        
            if (this.headIdTemp) {
              templateIdResponse = await this.leadActivityTemplatesService
                .getTemplateId(this.headIdTemp)
                .toPromise();
            } else if (this.LeadOpportunitiesActivityTemplatesResponseId) {
              templateIdResponse = await this.leadActivityTemplatesService
                .getTemplateId(this.LeadOpportunitiesActivityTemplatesResponseId)
                .toPromise();
            }
            if (templateIdResponse && Array.isArray(templateIdResponse.result)) {
              this.GirdBindTemplateActivity = templateIdResponse.result; 
              console.log('Updated Grid Activities:', this.GirdBindTemplateActivity);
            } else {
              console.log('No data available');
            }
          } catch (error) {
            console.error('Error fetching template activity:', error); 
          } finally {
            modalRef.destroy();
          }
        });
      }
    } else {
      if (this.LeadOpportunitiesActivityTemplatesResponses && type === 'ACTIVITY') {
        this.conformCardShow = true; 
      }
    }
  }
  // private extractNecessaryProperties(data: LeadActivityResponse[]): any[] {
  //   return data.map(item => ({
  //     globalId: item.globalId || '',
  //     leadOpportunityId: item.leadOpportunityId ?? 0,
  //     leadActivityTemplateId: item.leadActivityTemplateId ?? 0,
  //     activityTypeId: item.activityTypeId ?? 0,
  //     colorId: item.colorId ?? 0,
  //     assignedUserId: item.assignedUserId ?? 0,
  //     startTime: item.startTime || new Date(),
  //     activityOn: item.activityOn || new Date(),
  //     endTime: item.endTime || new Date(),
  //     description: item.description || '',
  //     markComplete: item.markComplete ?? false,
  //     reminderId: item.reminderId ?? 0,
  //     salesperson: item.salesperson ?? false,
  //     lead: item.lead ?? false,
  //     other: item.other ?? false,
  //     address: item.address || '',
  //     suburb: item.suburb || '',
  //     state: item.state || '',
  //     postalCode: item.postalCode || '',
  //     followUpDate: item.followUpDate || new Date(),
  //     followUpTime: item.followUpTime || new Date(),
  //     subject: item.subject || '',
  //     body: item.body || '',
  //     createLeadActivityUserParameterRequests: [item.leadActivityUserParameters || []]
  //   }));
  // }
  readonly validationRules = {
    name: {
      required: 'Required',
      maxLength: { limit: 50, message: 'Template Name exceeds the limit.' }
    }
  };
  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      name: 'Template Name ',
    };
    return labels[field] || field;
  }
  checkErrors(validateAllFields = false) {
    this.validationStates = {};
    this.validationMessages = {};
    const fieldsToValidate = validateAllFields ? Object.keys(this.validationRules) : Array.from(this.changedFields);
    for (const field of fieldsToValidate) {
      if (this.validationRules.hasOwnProperty(field)) {
        const errorTip = this.getErrorTip(field);
        if (errorTip) {
          this.validationStates[field] = 'error';
          this.validationMessages[field] = errorTip;
        } else {
          this.validationStates[field] = null;
          this.validationMessages[field] = '';
        }
      }
    }
  }
  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }
  getErrorTip(field: string): string {
    const rules = this.validationRules[field];
    const input = this.formFieldValues[field] || '';
    if (rules.required && !input.trim()) {
      return `Required.`;
    }
    if (rules.maxLength && input.length > rules.maxLength.limit) {
      const excessLength = input.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }
    return '';
  }
  validateChangedFields(field: string) {
    this.changedFields.add(field); // Add field to the changed fields set
    this.checkErrors(); // Validate changed fields
  }
  onFieldChange(field: string, value: string) {
    this.formFieldValues[field] = value;
    this.changedFields.add(field);
    this.checkErrors();
  }


  createAndModifiedByData(id: number) {
    const payload: FilterFooterAudit = {
      id: id,
      formCode: 'LOAT',
    }
    this.FooterService.createdAndModifyUser(payload).subscribe(res => {
      this.footerResponse = res.result;
    })
  }
  attendeesNodes: NzTreeNodeOptions[] = [];
  onUserChanges(selectedValues: string[]): void {
    const allValues = this.attendeesNodes[0].children.map(child => child['value']);

    // Check if "check all" is selected
    if (selectedValues.includes('check_all')) {
      this.formFieldValues['applicationUserId'] = [...allValues];
    } else {
      // Only keep selected values that are part of allValues
      this.formFieldValues['applicationUserId'] = selectedValues.filter(value => allValues.includes(value));
    }
  }

  getInitias(user: ApplicationUserForComboResponse | string): string {
    // Check if input is a string or ApplicationUserForComboResponse object
    const fullName = typeof user === 'string' ? user : user?.fullName;
  
    if (!fullName) {
      return '';
    }
  
    const nameParts = fullName.split(' ');
    const firstNameInitial = nameParts[0].charAt(0).toUpperCase();
    const lastNameInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1].charAt(0).toUpperCase() : '';
    
    return `${firstNameInitial}${lastNameInitial}`;
  }


}
