import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { ProjectManegmentSiteDiriesPreDefinedCodes, SubVendorPreDefinedCodes, SubVendorPreDefinedParentCodes } from '../../Models/LookUpStandardFilterCodes';
import { CreateLookupFilterRequest, FilterSearchParameter, LookUpStandardResponse, LookupFilter, LookupFilterResponse } from '../../Models/LookupModels';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { AddNewStandardFilterComponent } from '../../Modal/add-new-standard-filter/add-new-standard-filter.component';
import { FieldDataType } from 'src/app/Core/Enums/data_types';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { ApplicationUserForComboResponse } from 'src/Models/InternalUser/InternalUserJob';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { SiteDiariesService } from 'src/Service/Project-Management/site-diaries.service';

@Component({
  selector: 'app-project-management-site-diaries-daily-log-filter-field',
  templateUrl: './project-management-site-diaries-daily-log-filter-field.component.html',
  styleUrls: ['./project-management-site-diaries-daily-log-filter-field.component.css']
})
export class ProjectManagementSiteDiariesDailyLogFilterFieldComponent implements OnInit {
  @Input() pageId: number;
  TagsNodes: NzTreeNodeOptions[] = [];
  lookUpStandardResponse: LookUpStandardResponse[] = [];
  filterResponseData: LookupFilterResponse[] = [];
  formFieldValues: any = {};
  defaultStandardFilter: LookupFilterResponse;
  predefinedValuesMultiSelect: string[] = [];
  editSaveFilterFormGroup: FormGroup;
  createLookupFilterRequests: CreateLookupFilterRequest[] = [];
  selectedFilter: number = -1;
  isFormDirty: boolean = false;
  filterSearchParameter: FilterSearchParameter;
  colName: string;

  selectedJobId: number = 0;



  divisionTypeNodes: NzTreeNodeOptions[] = [];
  keywordOptions: { label: string, value: string }[] = [];
  DateNodes:  NzTreeNodeOptions[] = [];
  assgindata: NzTreeNodeOptions[] = [];
  subVendorData: NzTreeNodeOptions[] = [];
  combinedNodes: NzTreeNodeOptions[] = [];
  combinedNodesMessaging: NzTreeNodeOptions[] = [];
  /* _____________________________________ START DIFFERENCE _________________________________________________ */

  preDefinedDataCodes = {
    standardFilter: ProjectManegmentSiteDiriesPreDefinedCodes.standardFilter,
    keyword: ProjectManegmentSiteDiriesPreDefinedCodes.Keyword,
    date: ProjectManegmentSiteDiriesPreDefinedCodes.Date,
    tags: ProjectManegmentSiteDiriesPreDefinedCodes.Tags,
    createdBy: ProjectManegmentSiteDiriesPreDefinedCodes.CreatedBy
  };
  /* _____________________________________ End DIFFERENCE _________________________________________________ */


  constructor(
    private fb: FormBuilder,
    public toastService: NzMessageService,
    private loadingIndicatoreService: LoadingIndicatorService,
    private modal: NzModalService,
    private LookupFilterService: LookupFilterService,
    private SubVendorService: SubVendorService,
    private accessLocalStorageService: AccessLocalStorageService,
    private customerInformationService: CustomerInformationService,
    private SiteDiariesService: SiteDiariesService,

    private internalUserService: InternalUserService) {console.log('pageId',this.pageId);
     }

  ngOnInit(): void {
    this.selectedJobId = this.accessLocalStorageService.getJobId();

    this.initLoad();
    this.initEditSaveFilter();
    this.initializeForm();
    console.log('preDefinedDataCodes', this.preDefinedDataCodes)
  }



  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(res => {
      let statusValues = res.result.filter(x => x.code === this.preDefinedDataCodes.date.code);
      let TagsData = res.result.filter(x => x.code === this.preDefinedDataCodes.tags.code);

      // tags//
      this.TagsNodes = [
        {
          title: 'Select All',
          value: 'select_all',
          key: 'select_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: TagsData.map((status) => ({
            title: status.name,
            value: status.id.toString(),
            key: status.id.toString(),
            isLeaf: true
          }))
        }
      ];


      this.DateNodes = [
        {
          title: 'Select All',
          value: 'select_all',
          key: 'select_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: statusValues.map((status) => ({
            title: status.name,
            value: status.id.toString(),
            key: status.id.toString(),
            isLeaf: true
          }))
        }
      ];
      this.customerInformationService.getDataforcombo().subscribe(
        (response: ResponseModelArray<ApplicationUserForComboResponse>) => {
          this.SubVendorService.getData().subscribe(
            (res: ResponseModelArray<SubVendorResponse>) => {
              this.combinedNodes = [
                {
                  title: 'Select All',
                  value: 'select_all',
                  key: 'select_all',
                  selectable: false,
                  isLeaf: false,
                  expanded: true,
                  children: [
                    {
                      title: 'Internal Users',
                      value: 'internal_users',
                      key: 'internal_users',
                      selectable: false,
                      isLeaf: false,
                      expanded: true,
                      children: response.result.map((status) => ({
                        title: status.fullName,
                        value: status.id.toString(),
                        key: status.id.toString(),
                        isLeaf: true
                      }))
                    },
                    {
                      title: 'Sub Vendors',
                      value: 'sub_vendors',
                      key: 'sub_vendors',
                      selectable: false,
                      isLeaf: false,
                      expanded: true,
                      children: res.result.map((status) => ({
                        title: status.companyName,
                        value: status.id.toString(),
                        key: status.id.toString(),
                        isLeaf: true
                      }))
                    }
                  ]
                }
              ];
            },
            (error) => {
              console.error('Error fetching sub-vendor data:', error);
            }
          );
        },
        (error) => {
          console.error('Error fetching customer data:', error);
        }
      );

    })
      const lookupFilter: LookupFilter = {
      formNameId: this.pageId,
    };
    this.getStandardFilterByFormPageId(this.pageId);
    this.fetchData(lookupFilter);
  }

  // combineNodes(): void {
  //   if (this.assgindata && this.subVendorData) {
  //     const internalUsersNode = {
  //       title: 'internalUser',
  //       value: '0-0',
  //       key: '0-0',
  //       children: this.assgindata,
  //     };
  //     const subVendorsNode = {
  //       title: 'Subs/Vendors',
  //       value: '0-1',
  //       key: '0-1',
  //       children: this.subVendorData,
  //     };
  //     this.combinedNodes = [internalUsersNode, subVendorsNode];
  //     this.combinedNodesMessaging = [internalUsersNode, subVendorsNode];
  //   }
  // }
  fetchData(lookUpStandard: LookupFilter): void {
    this.LookupFilterService.postData(lookUpStandard).subscribe(
      data => {
        this.lookUpStandardResponse = data.result;
      },
      error => {
        console.error('Error fetching data:', error);
      }
    );
  }
  // tags//
  ontagsChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.TagsNodes[0].children.map(child => child['value']);
      this.formFieldValues['tags'] = [...allValues];
    } else {
      const allValues = this.TagsNodes[0].children.map(child => child['value']);
      this.formFieldValues['tags'] = selectedValues.filter(value => allValues.includes(value));
    }
  }

  onCombineChange(selectedValues: string[]): void {
    const internalUsersValues = this.combinedNodes[0].children[0].children.map(child => child['value']);
    const subVendorsValues = this.combinedNodes[0].children[1].children.map(child => child['value']);
    const allChildValues = [...internalUsersValues, ...subVendorsValues];
    if (selectedValues.includes('select_all')) {
      selectedValues = allChildValues;
    } else {
      if (selectedValues.includes('internal_users')) {
        selectedValues = selectedValues.concat(internalUsersValues.filter(value => !selectedValues.includes(value)));
      }
      if (selectedValues.includes('sub_vendors')) {
        selectedValues = selectedValues.concat(subVendorsValues.filter(value => !selectedValues.includes(value)));
      }
      selectedValues = selectedValues.filter(value => allChildValues.includes(value));
    }
    this.formFieldValues['createdBy'] = Array.from(new Set(selectedValues));
    console.log(this.formFieldValues['createdBy']);
  }

  onParentCodeChange(codeVal: string): void {
    console.log('Selected parent code:', codeVal);
    let createCode = { code: codeVal };
    this.preDefinedDataCodes.keyword = createCode;
    console.log('After change preDefinedDataCodes :', this.preDefinedDataCodes);
  }

  openStandardFilterComponent() {
    this.createLookupFilterRequests = [];
    this.createFormFieldsInArray();
    const modalRef = this.modal.create({
      nzContent: AddNewStandardFilterComponent,
      nzFooter: null,
      nzData:
      {
        pageId: this.pageId,
        std: this.filterResponseData,
        field: this.createLookupFilterRequests
      },
    });
    console.log(this.createLookupFilterRequests);
    console.log(this.filterResponseData);
    console.log(this.pageId);

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  getStandardFilterByFormPageId(pageId: number) {
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.filterResponseData = result.result;
        this.initializeForm();
        setTimeout(() => {
          let id = this.accessLocalStorageService.getSelectedFilterId();
          this.setDefaultFieldValues(id);
        }, 100);

      }
    });
  }

// date

onDateChange(selectedValues: string[]): void {
  const checkAllIndex = selectedValues.indexOf('select_all');
  if (checkAllIndex !== -1) {
    const allValues = this.DateNodes[0].children.map(child => child['value']);
    this.formFieldValues['date'] = [...allValues];
  } else {
    const allValues = this.DateNodes[0].children.map(child => child['value']);
    this.formFieldValues['date'] = selectedValues.filter(value => allValues.includes(value));
  }
}


  onFilterApply(): void {
    let selectedFilter = this.formFieldValues['standardFilter'];
    let isSelectedFilterStandard = this.filterResponseData.find(x => x.id === selectedFilter && x.isNotDeleted === true);
    if (isSelectedFilterStandard && isSelectedFilterStandard != undefined) {
      this.selectedFilter = 1;
    }
    else {
      this.selectedFilter = 0;
    }
    this.createFormFieldsInArray();
    this.createLookupFilterRequests[0].page = 1;
    this.createLookupFilterRequests[0].pageSize = 10;

    this.internalUserService.getAppliedFilterData(this.createLookupFilterRequests);


  }

  resetFilter(): void {
    Object.keys(this.formFieldValues).forEach(field => {
      if (field !== 'standardFilter') {
        if (Array.isArray(this.formFieldValues[field])) {
          this.formFieldValues[field] = [];
        } else {
          this.formFieldValues[field] = '';
        }
      }
    });
  }

  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
    this.isFormDirty = false;
  }


  createFormFieldsInArray() {
    this.createLookupFilterRequests = [];
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const value = this.formFieldValues[field];
      if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 0)) {
        const newRow = {
          globalId: '00000000-0000-0000-0000-000000000000',
          companyParameterId: 0,
          lookUpStandardId: 0,
          code: code,
          meaning: '',
          valueId: 0,
          formNameId: this.pageId,
          dataTypeId: 0,
          jobInformationId: this.selectedJobId,
          createLookupFilterParameterRequests: [null]
        };

        const correspondingResponse = this.lookUpStandardResponse.find(response => response.code === code);
        if (correspondingResponse) {
          newRow.companyParameterId = correspondingResponse.companyParameterId;
          newRow.lookUpStandardId = correspondingResponse.id;
          newRow.dataTypeId = correspondingResponse.dataTypeId;

          if (
            correspondingResponse.dataType.code === FieldDataType.SingleLineText ||
            correspondingResponse.dataType.name === 'Single-Line Text') {
            newRow.meaning = value;
            //   this.colName = code;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.Dropdown ||
            correspondingResponse.dataType.name === 'Dropdown') {
            newRow.valueId = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.MultiSelectDropdown ||
            correspondingResponse.dataType.name === 'Multi-Select Dropdown'
          ) {
            newRow.createLookupFilterParameterRequests = value.map((item, index) => {
              return { valueId: item };
            });
          }
        }
        this.createLookupFilterRequests.push(newRow);
      }
    });
  }

  getDefaultFilterValue() {
    // this.defaultStandardFilter = this.filterResponseData.find(filter => filter.isSetAsDefault === true);
    // return this.defaultStandardFilter ? this.defaultStandardFilter.id : null;
    return this.accessLocalStorageService.getSelectedFilterId();
  }

  setDefaultFieldValues(standardFilterId: number): void {
    this.predefinedValuesMultiSelect = [];
    let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
    let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;

    this.keywordOptions.forEach(val => {
      lookUpFiltersField.forEach(cod => {
        if (val.value === cod.code) {
          let createCode = { code: cod.code };
          this.preDefinedDataCodes.keyword = createCode;

        }
      });
    });


    Object.keys(this.formFieldValues).forEach(field => {
      if (field !== 'standardFilter') {
        if (Array.isArray(this.formFieldValues[field])) {
          this.formFieldValues[field] = [];
        } else {
          this.formFieldValues[field] = '';
        }
      }
    });


    if (lookUpFiltersField.length > 0) {
      Object.keys(this.preDefinedDataCodes).forEach(field => {
        const code = this.preDefinedDataCodes[field].code;
        const fieldData = lookUpFiltersField.find(filter => filter.code === code);
        if (fieldData != undefined) {
          if (
            fieldData.dataType.code === FieldDataType.SingleLineText) {
            this.formFieldValues[field] = fieldData.meaning;
            this.colName = code;
          }
          if (fieldData.dataType.code === FieldDataType.Dropdown) {
            this.formFieldValues[field] = fieldData.valueId.toString();
          }
          if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown) {
            let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
            this.predefinedValuesMultiSelect = multiSelectValues.map(String);
            this.formFieldValues[field] = this.predefinedValuesMultiSelect;
          }
        }
      });
    } else {
      console.log("lookUpFiltersField is null ", lookUpFiltersField);
    }
  }

  onChangeStandardFilter($event: number): void {
    this.selectedFilter = -1;
    const defaultFIlterId = this.getDefaultFilterValue();
    this.accessLocalStorageService.setSelectedFilterId($event);
    // New Changes By Aamir Ali - 22-Apr2024
    let id = this.accessLocalStorageService.getSelectedFilterId();
    this.setDefaultFieldValues(id);
    this.SiteDiariesService.setSelectedFilterId(id);
    this.filterSearchParameter = {
      CompanyParameterId: 1,
      FormNameId: this.pageId,
      LookUpFilterId: $event,
      page: 1,
      pageSize: 10,
      jobInformationId: this.selectedJobId
    };

    if (defaultFIlterId !== $event) {
      this.SiteDiariesService.userAppliedFilter(true);
    } else {
      this.SiteDiariesService.userAppliedFilter(false);
    }
    this.SiteDiariesService.getAllSiteDiariesByFilter(this.filterSearchParameter);

  }




  private initEditSaveFilter(): void {
    this.editSaveFilterFormGroup = this.fb.group({
      globalId: ['00000000-0000-0000-0000-000000000000'],
      companyParameterId: [0],
      code: ['', Validators.required],
      name: ['', Validators.required],
      description: [''],
      meaning: [''],
      formNameId: [0],
      dataTypeId: [],
      isShareThisFilter: [true],
      isSetAsDefault: [false],
      createLookupFilterRequests: [[]],
    });
  }

  editSaveFilterData(): void {
    let selected_Filter_Id = this.formFieldValues['standardFilter'];
    let getSelectedFilterValue = this.filterResponseData.find(filter => filter.id === selected_Filter_Id);
    this.createFormFieldsInArray();
    this.editSaveFilterFormGroup.controls['name'].setValue(getSelectedFilterValue.name);

    if (this.editSaveFilterFormGroup.get('name')?.invalid) {
      this.toastService.error('Name field is required');
      return;
    }
    this.editSaveFilterFormGroup.controls['companyParameterId'].setValue(getSelectedFilterValue.companyParameterId);
    this.editSaveFilterFormGroup.controls['code'].setValue(getSelectedFilterValue.code);
    this.editSaveFilterFormGroup.controls['formNameId'].setValue(getSelectedFilterValue.formNameId);
    this.editSaveFilterFormGroup.controls['dataTypeId'].setValue(getSelectedFilterValue.dataTypeId);
    this.editSaveFilterFormGroup.controls['globalId'].setValue(getSelectedFilterValue.globalId);
    this.editSaveFilterFormGroup.controls['createLookupFilterRequests'].setValue(this.createLookupFilterRequests);
    this.editSaveFilterFormGroup.controls['isSetAsDefault'].setValue(getSelectedFilterValue.isSetAsDefault);
    this.LookupFilterService.saveStandardFilterData(this.editSaveFilterFormGroup.value)
      .subscribe(
        (res: any) => {
          console.log(res.result);
          setTimeout(() => {
            this.loadingIndicatoreService.hide();
            this.toastService.success('Filter saved successfully');
            this.getStandardFilterByFormPageId(this.pageId);
          }, 2000);
        },
        (error) => {
          console.error('Error:', error);
          this.loadingIndicatoreService.hide();
          if (error.status === 500) {
            this.toastService.error('Internal server error. Please try again later.');
          } else {
            this.toastService.error('An error occurred. Please try again.');
          }
        }
      );
  }

  /* _____________________________________ START DIFFERENCE _________________________________________________ */
  initializeForm(): void {
    this.formFieldValues = {
      standardFilter: this.getDefaultFilterValue(),
      keyword: '',
      date: '',
      tags: [] = [],
      createdBy: [] = [],
    };

  }
  /* _____________________________________ End DIFFERENCE _________________________________________________ */

}
