import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerContactsDashboardComponent } from './Components/customer-contacts-dashboard/customer-contacts-dashboard.component';

const routes: Routes = [


  { path: '', redirectTo: 'Customer-Info-dashboard', pathMatch: 'full' },
  { path: 'Customer-Info-dashboard', component: CustomerContactsDashboardComponent },
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerInfromationRoutingModule { }
