import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MessagingDashboardComponent } from './Components/messaging/messaging-dashboard/messaging-dashboard.component';
import { SurveysDashboardComponent } from './Components/surveys/surveys-dashboard/surveys-dashboard.component';
import { CommentsDashboardComponent } from './Components/Comments/comments-dashboard/comments-dashboard.component';
import { NotificationDashboardComponent } from './Components/Notification-History/notification-dashboard/notification-dashboard.component';
import { RfisDashboardComponent } from './Components/RFIs/rfis-dashboard/rfis-dashboard.component';

const routes: Routes = [
  { path: '', redirectTo: 'Messages', pathMatch: 'full' },
  { path: 'Messages', component: MessagingDashboardComponent},
  { path: 'Surveys', component: SurveysDashboardComponent},
  { path: 'Comments', component: CommentsDashboardComponent },
  { path: 'Notification-History', component: NotificationDashboardComponent },
  { path: 'RFIs', component: RfisDashboardComponent},

  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MessagingRoutingModule { }
