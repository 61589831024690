<div class="row mb-3 mt-4">
    <div class="col-12  mb-3">
        <div class="card shadow">
            <div class="demo card-header py-3 ">Import Cost Codes</div>
            <div class="card-body">
                <div
                    class="demo row py-4"
                    style="background-color: #f7f9fc; margin-left: 2%;  margin-right: 2%; border-radius: 2%;"
                >
                    <div
                        class="col-6"
                        style="border-right: 1px solid #ccc;"
                    >
                    <div class="flex flex-column align-items-center gap-3">
                      <input type="checkbox" [(ngModel)]="checkboxes[0].isSelected" (change)="xeroCheckboxChanged()">
                      <span class="ms-4">{{ checkboxes[0].label }}</span>
                    </div>
                    </div>
                    <div class="col-6">
                        <div class="flex flex-column align-items-center gap-3">
                          <span class="ms-4">Buildertrend Cost Codes</span>
                        </div>
                    </div>
                </div>
                
                <div
                    class="row py-2"
                    *ngFor="let checkbox of checkboxes.slice(1); let i = index"
                    style="border-bottom: 1px solid #ccc; margin-left: 2%;  margin-right: 2%; border-radius: 2%;"
                >
                    <div class="col-6 mt-3">
                      <div class="flex flex-column align-items-center gap-3">
                        <input type="checkbox" [(ngModel)]="checkboxes[i + 1].isSelected" (change)="individualCheckboxChanged()">
                        <span class="ms-4">{{ checkbox.label }}</span>
                      </div>
          
                    </div>
                    <div class="col-6 mt-2">
                        <!-- Second Row -->
                        <div class="ant-row ant-form-item ant-form-item-with-help BTFormItem">
                            <div
                                style="width: 355px; height: 42px;"
                                class="dropdown mt-1 mb-1"
                            >
                                <label for="dropdown">Default Builder View</label>

                                <input
                                    type="text"
                                    class="petter"
                                    [value]="selectedOption3"
                                    (click)="toggleDropdown3()"
                                >
                                <i
                                    class="dropdown-icon fa fa-chevron-down"
                                    [ngClass]="{ 'open': isOpen3 }"
                                    (click)="toggleDropdown3()"
                                >
                                </i>
                                <div
                                    class="option"
                                    *ngIf="isOpen3"
                                    [@dropdownAnimation]="isOpen3 ? 'open' : 'closed'"
                                >
                                    <div
                                        *ngFor="let option of options3"
                                        (click)="selectOption3(option)"
                                        [ngClass]="{
                      'special-hover': option === selectedOption3,
                      'normal-hover': true
                    }"
                                    >
                                        {{ option }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
