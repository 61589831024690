<nz-modal nzWidth="95%" [(nzVisible)]="  PurchaseOrderVisible
" [nzTitle]="surveys1" [nzFooter]="surveys2" (nzOnCancel)="PurchaseCancel()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '400px', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
    <ng-template #surveys1>
        <div class="row p-0 m-0">
            <h2 class="p-0 m-0 fw-bold">Purchase Order </h2>
        </div>
    </ng-template>
    <form [formGroup]="purchaseModel" >

    <div class="cantent" *nzModalContent>   
        <!-- card -->
        <div class="row">
            <div class="col-9">
                <nz-card nzTitle="General Information">
                    <!-- content -->
                    <div class="row">
                        <div class="col-lg-4">
                            <label class="sub-text">
                                purchase Order #<span nz-icon nzType="info-circle" class="text-secondary ms-2 mb-"
                                    nzTheme="fill" nz-popover [nzPopoverContent]="contentTemplate"
                                    nzPopoverPlacement="right"></span>
                                <ng-template #contentTemplate>
                                    <div class="rounded-2">
                                        <p style="height: auto; width: 180px; ">
                                            Claim numbers are auto assigned based on your previous numbers used per job.

                                        </p>
                                    </div>
                                </ng-template>
                            </label>
                            <input  formControlName="purchaseOrder" type="text" class="form-control formField" placeholder="(Auto Design)" nz-input />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 mt-3">
                            <label class="sub-text" mt-3>
                                Title
                            </label>
                            <input formControlName="title" type="text" class="form-control formField" placeholder="" nz-input />
                        </div>
                    </div>
                    <div class="row mb-2 d-flex mt-4   align-items-center align-content-center">
                        <label class="sub-text">Assigned to</label>
                        <div class="col-md-4 col-sm-6 ">
                            <nz-select formControlName="assignedTo" ngModel="Unassigned">
                                <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
                                <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
                                <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
                            </nz-select>
                        </div>
                        <div class="col-md-4 col-sm-3 ">
                            <label nz-checkbox [(ngModel)]="checked" formControlName="materialsOnly">Materials Only</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 mt-2">
                            <label class="sub-text">
                                Scheduled Completion
                            </label>
                            <nz-button-group>
                                <button nz-button nzType="default">
                                    Choose Date
                                </button>
                                <button nz-button nzType="default">
                                    Link to Schedule Item
                                </button>
                            </nz-button-group>
                        </div>
                    </div>
                    <div class="row mt-2 ">
                        <div class="col-4">
                            <label class="sub-text">Complenation Date</label><br>
                            <nz-date-picker  formControlName="complenationDate" [(ngModel)]="date" (ngModelChange)="onChange($event)"></nz-date-picker>
                        </div>
                    </div>
                    <div class="row mb-2 d-flex mt-4 align-items-center align-content-center">
                        <label class="sub-text">Schedule Item</label>
                        <div class="col-md-4 col-sm-6">
                            <nz-select formControlName="scheduleItem">
                                <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
                                <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
                                <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
                            </nz-select>
                        </div>
                        <div class="col-3" style="text-align: left;">
                            <nz-space>
                                <button aria-disabled="false" type="button"
                                    class="ant-btn ant-btn-link BTButton isolated AutoSizing " style="height: 29px;">
                                    <span class="ms-2">Add</span>
                                </button>
                                <button aria-disabled="false" type="button"
                                    class="ant-btn ant-btn-link BTButton isolated AutoSizing p-0">
                                    <span style="opacity: 60%;">Edit</span>
                                </button>
                            </nz-space> 
                        </div>
                    </div>
                   
                    <div class="row">
                        <div class="col-lg-4 mt-3">
                            <label class="sub-text" mt-3>
                                Scope Of Work<span nz-icon nzType="info-circle" class="text-secondary ms-2"
                                    nzTheme="fill" nz-popover [nzPopoverContent]="contentTemplate"
                                    nzPopoverPlacement="right"></span>
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mt-4">
                            <label class="sub-text">
                                IMPORTANT NOTICE
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <label class="sub-text">
                                Attechments
                            </label><br>
                            <nz-button-group>
                                <button nz-button nzType="default">
                                    Add
                                </button>
                                &nbsp;
                                <button nz-button nzType="default">
                                    Create New Doc
                                </button>
                            </nz-button-group>
                        </div>
                    </div>
                    <div class="col-lg-4 mt-3">
                        <label class="sub-text">
                            Variance
                        </label>
                    </div>
                    <div class="row">
                        <div class="col mt-3">
                            <div class="col-md-4 col-sm-3 ">
                                <label nz-checkbox formControlName="markAsVariance">Mark as Variance PO</label>
                            </div>
                        </div>
                    </div>
                    <div class="col mt-3">
                        <label class="sub-text">
                            Cost
                        </label>
                    </div>
                    <div class="row">
                        <div class="col mt 2">
                            <div class="text-end">
                                <button nz-button nzType="default" class="rounded-1 me-0">Add Form Catalog</button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mt-3">
                            <nz-card nzTitle="Bills/Lien Waivers">
                                <p>please Save the purchase Order before viewing Bills </p>
                            </nz-card>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mt-3">
                            <nz-card nzTitle="Messageing">
                                <p>Messageing available after save </p>
                            </nz-card>
                        </div>
                    </div>
                </nz-card>
            </div>
            <div class="col">
                <nz-card nzTitle="Related Bids">
                    No bids available for import
                </nz-card>
                <div class="row mt-3">
                    <div class="col ">
                        <nz-card nzTitle="Internal Only">
                            <div class="row">
                                <div class="col">
                                    <label for="text" class="sub-text">Internal Notes</label>
                                    <textarea formControlName="internalNotes" class="form-control formField" style="height: 50px; resize: none;"
                                        (input)="autoAdjustTextAreaHeight($event)"></textarea>

                                </div>
                            </div>
                        </nz-card>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
    <ng-template #surveys2>
        <button nz-button nzType="primary" class="rounded-1 me-0">Save&New</button>
        <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>

    </ng-template>
</form>
</nz-modal>