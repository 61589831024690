<div class="row">
  <h1 nz-typography>Select a Lead</h1>
</div>
<form>
  <div class="content">
    <div class="row mt-4 mb-4 ms-2 me-2">
      <div class="col-12">
        <label class="fw-bold">Please Select a Lead</label>
          <div class="input-group">
            <nz-select
              nzShowSearch
              nzAutoFocus="true"
              nzPlaceHolder="Select Lead"
              [(ngModel)]="selectedLeadId"
              name="selectedLeadId"
              (ngModelChange)="onLeadSelect($event)"
            >
              <nz-option
                *ngFor="let option of leadOptions"
                [nzLabel]="option.title"
                [nzValue]="option.id"
              ></nz-option>
            </nz-select>
          </div>
      </div>
    </div>
  </div>
</form>
