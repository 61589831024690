import { Component } from '@angular/core';
import { FormScratchTemplateComponent } from '../../Job-List/form-scratch-template/form-scratch-template.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-this-weeks-agenda',
  templateUrl: './this-weeks-agenda.component.html',
  styleUrls: ['./this-weeks-agenda.component.css']
})
export class ThisWeeksAGENDAComponent {

  constructor(private modal:NzModalService){}

  templateModalOpen(){
    const modalRef = this.modal.create({
      nzContent: FormScratchTemplateComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

}
