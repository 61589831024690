<nz-layout>
  <nz-sider
    class="border fixed"
    nzCollapsible
    [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed"
    [nzWidth]="280"
    [nzTrigger]="null"
  >
    <div class="row">
      <app-side-menu-bar
        [isAllJobOption]="true"
        (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"
      ></app-side-menu-bar>
    </div>
  </nz-sider>
  <nz-layout>
    <nz-page-header class="p-4">
      <nz-breadcrumb class="text-start mb-0" nz-page-header-breadcrumb>
        <nz-breadcrumb-item>{{ localStorage.getJobName() }}</nz-breadcrumb-item>
      </nz-breadcrumb>
      <nz-page-header-title class="mt-0">
        <h1>To-Dos's</h1>
      </nz-page-header-title>
      <ng-template #topExtra>
        <nz-page-header-extra>
          <nz-space>
            <button
              (click)="ToDodsSetting()"
              nz-popover
              [nzPopoverContent]="Summary"
              nzPopoverPlacement="left"
              nz-button
              nzType="default"
              class="ms-2 rounded-1"
            >
              <span nz-icon nzType="setting" nzTheme="outline"></span>
            </button>
            <ng-template #Summary>
              <div class="rounded-2">
                <p style="font-size: 13px">Daily Log Settings</p>
              </div>
            </ng-template>
            <nz-divider nzType="vertical" class="divder"></nz-divider>
            <button nz-button nzType="default" class="ms-2 rounded-1">
              <i class="fas fa-graduation-cap"></i>&nbsp;&nbsp;Learn More
            </button>
            <button nz-button nzType="default" class="ms-2 rounded-1">
              Export
            </button>

            <button
              (click)="ImportModelOpen()"
              nz-button
              nzType="default"
              class="ms-2 rounded-1"
            >
              Import To-Do's
            </button>
            <button
              nz-button
              nzType="default"
              (click)="openFilter(PROJECT_MANG_Todos, PAGE_ID)"
              class="ms-2 rounded-1"
            >
              <i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter (1)
            </button>
            <button
              (click)="toDosModalOpen('To-Dos')"
              nz-button
              nzType="primary"
              class="ms-2 fs-6 button-Daily"
            >
              <i class="bi bi-plus me-1"></i>
              To-Do
            </button>
          </nz-space>
        </nz-page-header-extra>
      </ng-template>

      <nz-page-header-extra *ngIf="mySelection?.length > 0; else topExtra">
        <nz-space>
          <button nz-button nzType="default" class="ms-2 rounded-1">
            Print
          </button>
          <button nz-button nzType="default" class="ms-2 rounded-1">
            Mark Complete
          </button>
          <button nz-button nzType="default" class="ms-2 rounded-1">
            Assign To
          </button>
          <button (click)="copyToDos()" nz-button nzType="default" class="ms-2 rounded-1">
            Copy
          </button>
          <button (click)="showDeleteConfirm()" nz-button nzType="default" class="ms-2 rounded-1">
            Delete
          </button>
        </nz-space>
      </nz-page-header-extra>
    </nz-page-header>

    <nz-content  class="custom-content ">
      <div class="container h-100" *ngIf="displayedData?.length === 0 || null">
        <div class="row">
          <app-todos-empty-content></app-todos-empty-content>
        </div>
      </div>
      <div *ngIf="displayedData?.length > 0" class="container-fluid">
        <div class="row">
            <!-- #gridRef -->
            <!-- [kendoGridBinding]="displayedData" -->
            <!-- <ng-template kendoGridToolbarTemplate>
              <button kendoGridExcelCommand type="button">
                Export Columns
              </button>
              <kendo-grid-excel fileName="To-Do`s.xlsx"> </kendo-grid-excel>
            </ng-template> -->
            <kendo-grid [selectable]="{ mode: 'multiple', checkboxOnly: true }"
            (columnResize)="saveColumnSizes($event)"
            [data]="displayedData"
            kendoGridSelectBy="id"
            [pageSize]="5"
            [resizable]="true"
            [reorderable]="true"
            width="100%"
            [sortable]="true"
            [style.maxHeight.px]="600"
            [(selectedKeys)]="mySelection"
            (selectionChange)="selectionChange(mySelection)"
            >

            <kendo-grid-checkbox-column
              [width]="55"
              [headerClass]="{ 'text-center': true }"
              [class]="{ 'text-center': true }"
              [resizable]="false"
              [columnMenu]="false"
              [showSelectAll]="true"
            ></kendo-grid-checkbox-column>

            <kendo-grid-column
              [width]="400"
              *ngFor="let column of displayedColumns; let i = index"
              [title]="column"
              [field]="column"
              [minResizableWidth]="200"
              [maxResizableWidth]="800"
              [width]="getColumnWidthSize[i]"
              [sticky]="isColumnSticky[i]"
              [headerClass]="'pinned-header'"
              class="pinned-header"
              [sortable]="true"
            >
              <ng-template
                kendoGridHeaderTemplate
                let-columnIndex="columnIndex"
              >
                <div class="d-flex align-items-center">
                  <span class="fw-bold">{{ column }}</span>
                  <button
                    (click)="toggleColumnSticky(columnIndex)"
                    class="btn btn-link"
                  >
                    <i
                      class="bi"
                      [ngClass]="{
                        'bi-pin-fill': isColumnSticky[columnIndex],
                        'bi-pin': !isColumnSticky[columnIndex]
                      }"
                    ></i>
                  </button>
                </div>
              </ng-template>
              <ng-template
                kendoGridColumnMenuTemplate
                let-columnIndex="columnIndex"
              >
                <button
                  (click)="toggleColumnSticky(columnIndex)"
                  class="btn btn-link"
                >
                  <i
                    class="bi"
                    [ngClass]="{
                      'bi-pin-fill': isColumnSticky[columnIndex],
                      'bi-pin': !isColumnSticky[columnIndex]
                    }"
                  ></i>
                </button>
              </ng-template>
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <ng-container *ngIf="column === 'Title'">
                  <div class="ellipsis">
                    <a class="text-primary" (click)="editTodo(dataItem)">{{
                      dataItem?.toDoParameters[0]?.title
                    }}</a>
                  </div>
                </ng-container>

                <ng-container *ngIf="column === 'Complete'">
                  <div class="ellipsis">
                    <!-- <a class="text-primary">{{ dataItem?.toDoParameters?.title }}</a> -->
                    <div class="round me-3">
                      <input
                        type="checkbox"
                        [id]="'checkbox-' + dataItem.id"
                        [checked]="
                          dataItem?.toDoParameters[0]?.isCompleted === true
                        "
                        (change)="
                          logIsCompleted(dataItem?.toDoParameters[0]?.globalId)
                        "
                      />

                      <label [for]="'checkbox-' + dataItem.id"></label>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="column === 'Due'">
                  <div class="ellipsis">
                    <a class="text-danger">{{
                      dataItem?.toDoParameters[0]?.dueDate
                        | date : "MMM d, yyyy"
                    }}</a>
                  </div>
                </ng-container>
                <ng-container *ngIf="column === 'Asssigned To'">
                  <div class="ellipsis">
                    <a class="">{{ getAssignedToName(dataItem) }}</a>
                  </div>
                  <div class="ellipsis">
                    <a class="">{{ getAssignedSubVendorToName(dataItem) }}</a>
                  </div>
                  <div class="ellipsis">
                    <a class="">{{ getAssignedOwnerToName(dataItem) }}</a>
                  </div>
                </ng-container>

                <ng-container *ngIf="column === 'CheckList'">
                  <ng-container
                    *ngFor="let userParam of dataItem?.toDoParameters"
                  >
                    <div class="ellipsis text-center">
                      <span
                        *ngIf="
                          userParam?.toDoInformationCheckListItems[0]?.name
                        "
                      >
                        <i class="bi bi-list-check fs-4 fw-dark"></i>
                      </span>
                    </div>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="column === 'Job'">
                  <div class="ellipsis">
                    <span>{{ dataItem?.jobInformation?.name }}</span>
                  </div>
                </ng-container>
                <ng-container *ngIf="column === 'Comments'">
                  <div class="ellipsis text-center">
                    <a class="text-primary" (click)="editComments(dataItem)"
                      >0</a
                    >
                  </div>
                </ng-container>
                <ng-container *ngIf="column === 'Priority'">
                  <div class="ellipsis text-center">
                    <span [ngClass]="{'high': dataItem?.toDoParameters[0]?.prioritySetup?.name === 'HIGH', 'highest': dataItem?.toDoParameters[0]?.prioritySetup?.name === 'HIGHEST','low': dataItem?.toDoParameters[0]?.prioritySetup?.name === 'LOW'}">
                     {{ dataItem?.toDoParameters[0]?.prioritySetup?.name }}
                    </span>
                  </div>
                </ng-container>

                <ng-container *ngIf="column === 'Realted Items'">
                  <div class="ellipsis">
                    <span class="RelatedItemTag">
                      <button
                        nz-button
                        nzType="text"
                        nzTrigger="click"
                        nz-dropdown
                        [nzDropdownMenu]="menu4"
                      >
                        Related Item
                        <span nz-icon nzType="down"></span>
                      </button>
                      <nz-dropdown-menu #menu4="nzDropdownMenu">
                        <ul nz-menu>
                          <li nz-menu-item>menu4 1st menu item</li>
                        </ul>
                      </nz-dropdown-menu>
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngIf="column === 'Files'">
                  <div class="ellipsis">
                    <a >{{
                      dataItem?.toDoParameters[0]?.title
                    }}</a>
                  </div>
                </ng-container>
                <ng-container *ngIf="column === 'Created By'">
                  <div class="ellipsis">
                    <a >{{
                      dataItem?.toDoParameters[0]?.title
                    }}</a>
                  </div>
                </ng-container>
                <ng-container *ngIf="column === 'Tags'">
                  <div class="ellipsis" *ngFor="let tagParameter of dataItem?.toDoParameters[0]?.toDoInformationTagParameters">
                    <nz-tag class="rounded-1 fw-medium me-1 mb-1">{{ tagParameter?.tagSetup?.name }}</nz-tag>
                  </div>
                </ng-container>


                <ng-container *ngIf="column === 'RFIs'">
                  <div class="ellipsis">
                    <a class="text-primary">{{
                      dataItem?.toDoParameters?.title
                    }}</a>
                  </div>
                </ng-container>
                <ng-container *ngIf="column === 'Completed Date'">
                  <div class="ellipsis">
                    <a class="text-primary">{{
                      dataItem?.toDoParameters?.title
                    }}</a>
                  </div>
                </ng-container>
                <ng-container *ngIf="column === 'Notes'">
                  <div class="ellipsis">
                    <a class="text-primary">{{
                      dataItem?.toDoParameters?.notes
                    }}</a>
                  </div>
                </ng-container>
                <ng-container *ngIf="column === 'Reminder'">
                  <div class="ellipsis">
                    <a class="text-primary">{{
                      dataItem?.toDoParameters?.title
                    }}</a>
                  </div>
                </ng-container>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </div>
    </nz-content>

    <nz-footer class="custom-footer">
      <div class="container-fluid">
        <div class="row">
          <div class="row mb-0 mt-2">
            <div class="col">
              <nz-pagination
                style="float: right"
                [nzPageIndex]="currentPage"
                [nzTotal]="totalRecords"
                [nzPageSize]="pageSize"
                nzShowSizeChanger
                (nzPageIndexChange)="onPageChange($event)"
                (nzPageSizeChange)="onPageSizeChange($event)"
                [nzPageSizeOptions]="[10, 20, 50, 75, 100]"
              ></nz-pagination>
              <ng-container *ngIf="PAGE_ID">
                <div class="row d-flex justify-content-start mb-1">
                  <div class="col-md-3">
                    <div class="input-group">
                      <nz-select
                      [(ngModel)]="selectedValueControl"
                        name="standardFilter"
                        (ngModelChange)="onFilterChange($event)"
                        nzShowSearch
                        >
                        <nz-option  *ngFor="let standard of getStandardViewFilterData"
                          [nzLabel]="standard.name + (standard.isSetAsDefault ? ' ' : '')"
                          [nzValue]="standard.id"
                          nzCustomContent>
                          <div class="option-content">
                            <!-- Customize content with icons and text -->
                            <span *ngIf="standard.icon" nz-icon [nzType]="standard.icon"></span>
                            <span>{{ standard.name }}</span>
                          <span  *ngIf="standard.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

                          </div>
                        </nz-option>
                      </nz-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <button
                    nz-button
                    nz-popover
                    class="text-primary text-dark fw-bold fs-5 border-0"
                    nz-popover
                    [nzPopoverContent]="contentTemplate"
                    nzPopoverPlacement="top"
                    nzTheme="outline"
                    (click)="openStandardViewFilterComponent()"
                  >
                    <i nz-icon nzType="ellipsis" nzTheme="outline"></i>

                      <!-- <span
                        nz-popover
                        class="text-primary text-dark fw-bold fs-5"
                        nz-icon
                        nzType="ellipsis"
                        nzTheme="outline"
                      ></span> -->
                    </button>
                  </div>
                  <div class="col-md-2">
                    <div class="mt-2" *ngIf="selectedFilter === 1">
                      <p>Your filter has unsaved changes.</p>
                    </div>
                    <div class="mt-2" *ngIf="selectedFilter === 0">
                      <p>Your filter has unsaved changes.</p>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <button
                      (click)="openStandardViewFilterComponent('saveAs')"
                      type="button"
                      nz-button
                      nzType="default"
                      class="me-2"
                      style="font-size: 12px"
                      *ngIf="selectedFilter === 1"
                    >
                      Save As
                    </button>

                    <button
                      (click)="reInitReset()"
                      nz-button
                      nzType="default"
                      style="font-size: 12px"
                      *ngIf="selectedFilter === 1"
                      type="button"
                    >
                      Reset
                    </button>

                    <button
                      (click)="saveEditStandardView()"
                      nz-button
                      nzType="primary"
                      type="button"
                      class="me-2"
                      style="font-size: 12px"
                      *ngIf="selectedFilter === 0"
                    >
                      Save
                    </button>

                    <button
                      (click)="openStandardViewFilterComponent('saveAs')"
                      nz-button
                      nzType="default"
                      class="me-2"
                      style="font-size: 12px"
                      *ngIf="selectedFilter === 0"
                      type="button"
                    >
                      Save As
                    </button>

                    <button
                      (click)="reInitReset()"
                      nz-button
                      nzType="default"
                      style="font-size: 12px"
                      *ngIf="selectedFilter === 0"
                      type="button"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </nz-footer>
  </nz-layout>
</nz-layout>


<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>
