import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzSelectSizeType } from 'ng-zorro-antd/select';
import { TimeClockNewCostCodeComponent } from '../time-clock-new-cost-code/time-clock-new-cost-code.component';
import { TimeClockCostCodeEditorComponent } from '../time-clock-cost-code-editor/time-clock-cost-code-editor.component';
import { TimeClockAddTimeClockTagComponent } from '../time-clock-add-time-clock-tag/time-clock-add-time-clock-tag.component';
import { TimeClockEditTimeClockTagComponent } from '../time-clock-edit-time-clock-tag/time-clock-edit-time-clock-tag.component';
import {
  LeadOpportunityResponse,
  LookupNameSetupResponse,
} from 'src/Models/LeadTags';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { CostCodeService } from 'src/Service/Internaluser/cost-code.service';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { CostCode } from 'src/Models/CostCode/costCode';
import { ApplicationUserResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { TimeClockService } from 'src/Service/TimeClock/time-clock.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { ShiftInformationResponse } from 'src/Models/Time-Clock/clock-in';

@Component({
  selector: 'app-time-clock-shift',
  templateUrl: './time-clock-shift.component.html',
  styleUrls: ['./time-clock-shift.component.css'],
})
export class TimeClockShiftComponent implements OnInit {
  timeClockShift: any = {};
  constructor(
    private modal: NzModalService,
    private jobsService: JobInformationService,
    private LookupFilterServices: LookupFilterService,
    private fb: FormBuilder,
    private costCodesServices: CostCodeService,
    private internalUserservice: InternalUserService,
    private timeClockIn: TimeClockService,
    private toastssService: NzMessageService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  @Output() cancel = new EventEmitter<void>();
  @Input() shiftGridResponse : ShiftInformationResponse;
  Taghandle = true;
  @Input() pageId: number;
  selectedLeadId: any;
  PAGE_ID: number = 11;
  jobList: LeadOpportunityResponse[] = [];
  ShiftType: LookupNameSetupResponse[] = [];
  costSubCode: CostCode[] = [];
  userNodes: NzTreeNodeOptions[] = [];
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  public localStorageService: AccessLocalStorageService
  tagNodes: NzTreeNodeOptions[] = [];
  userList: ApplicationUserResponse[] = [];
  timeClockForm: any = {};
  breaks: any[] = [];
  // shiftTotal: string = '00 : 00';
  

  showBreakContent: boolean = false;
  TagClose(): void {
    this.cancel.emit();
  }
  listOfOption: Array<{ label: string; value: string }> = [];
  size: NzSelectSizeType = 'default';

  tagValue = [];
  preDefinedDataCodes = {
    tags: { code: 'PMTCT' },
    shiftType: { code: 'PMTCST' },
  };

  ngOnInit(): void {
    this.initLoad();
    this.jobsService.getData().subscribe((res) => {
      this.jobList = res.result;
      const jobId = this.localStorageService.getJobId();
      const selectedJob = this.jobList.find((job) => job.id === jobId);
      if (selectedJob) {
        this.timeClockForm['jobInformationId'] = selectedJob.id;
      }
    });


    const children: Array<{ label: string; value: string }> = [];
    for (let i = 10; i < 36; i++) {
      children.push({ label: i.toString(36) + i, value: i.toString(36) + i });
    }
    this.listOfOption = children;

    Object.keys(this.shiftGridResponse).forEach(key => {
      if (key !== 'applicationUserIds' && key !== 'shiftTagParameters') {
        this.timeClockForm[key] = this.shiftGridResponse[key];
      }
    });
    console.log('Grid Data By Sami', this.shiftGridResponse);

    this.timeClockForm['globalId'] = this.shiftGridResponse ?.globalId;
    this.timeClockForm['timeClockSetupId'] = this.shiftGridResponse?.timeClockSetupId;
    this.timeClockForm['jobInformationId'] = this.shiftGridResponse?.jobInformationId;
    this.timeClockForm['jobSetupId'] = this.shiftGridResponse?.jobSetupId;
    this.timeClockForm['builderCost'] = this.shiftGridResponse ?.builderCost;
    this.timeClockForm['startTime'] = this.shiftGridResponse ?.startTime;
    this.timeClockForm['endTime'] = this.shiftGridResponse ?.endTime;
    this.timeClockForm['endOn'] = this.shiftGridResponse ?.endOn;
    this.timeClockForm['startOn'] = this.shiftGridResponse?.startOn;
    this.timeClockForm['costCodeId'] = this.shiftGridResponse?.costCodeId;
    this.timeClockForm['notes'] = this.shiftGridResponse?.notes;
    this.timeClockForm['applicationUserIds'] = this.shiftGridResponse.applicationUserId.toString();
    this.timeClockForm['createShiftTagParameterRequests'] = this.shiftGridResponse.shiftTagParameters.map(param => param.shiftTagSetupId.toString());
  }
  initLoad() {
    this.internalUserGetAll();
      this.timeClockForm = {
      globalId: '00000000-0000-0000-0000-000000000000',
      timeClockSetupId: 0,
      jobSetupId: 0,
      jobInformationId: 0,
      startOn: new Date(),
      endOn: new Date(),
      startTime: null,
      endTime: null,
      timeClockTagSetupId: 0,
      shiftTypeSetupId: 0,
      shiftHours: 0,
      shiftMinutes: 0,
      overTimeHours: 0,
      overTimeMinutes: 0,
      costCodeId: 0,
      notes: '',
      rate: 0,
      hours: 0,
      builderCost: 0,
      applicationUserIds: [],
      createShiftTagParameterRequests: [],
     createShiftInformationBreakParameterRequests: [
        {
            shiftInformationId: null,
            breakStartOn: new Date(),
            breakStartTime: new Date()  ,
            breakEndOn: new Date() ,
            breakEndTime: new Date() 
     },
    ]};

    this.LookupFilterServices.getLookUpValuesByFormNameId(
      this.PAGE_ID
    ).subscribe((res) => {
      let tags = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.tags.code
      );
       this.ShiftType = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.shiftType.code
      );
      this.lookupSetNameResponse = res.result;
      this.tagNodes = tags.map((tags) => ({
        title: tags.name,
        value: tags.id.toString(),
        key: tags.id.toString(),
        isLeaf: true,
      }));

     
    });

    this.costCodesServices
      .getCategoryData()
      .subscribe((res: ResponseModelArray<CostCode>) => {
        this.costSubCode = res.result.filter(
          (cost) => cost.timeClockLaborCode === true
        );
      });
  }

  calculateHoursWorked(startTime: Date, endTime: Date): string {
    if (startTime && endTime) {
      const start = new Date(startTime);
      const end = new Date(endTime);
      const diff = Math.abs(end.getTime() - start.getTime());
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
       // return hours.toString().padStart(2, '0') + ' : ' + minutes.toString().padStart(2, '0');
      return `${hours} hours ${minutes} minutes`;
    }
    return '00 Hours : 00 Minutes';
  }

  get shiftTotal(): string {
    return this.calculateHoursWorked(this.timeClockForm.startTime, this.timeClockForm.endTime);
  }

  internalUserGetAll() {
    this.internalUserservice.getData().subscribe((res) => {
      this.userList = res.result;
      this.userNodes = this.userList.map((data) => ({
        title: data.firstName + data.lastName,
        value: data.id.toString(),
        key: data.id.toString(),
        isLeaf: true,
      }));
    });
  }
  tabs = [1, 2, 3, 4, 5];
  innerCardTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'General';
      case 2:
        return 'Detailed Hours';
      case 3:
        return 'Breaks';
      case 4:
        return 'Cost';
      default:
        return 'Map';
    }
  }

  // selectionChoiceModel
  newcostcode(): void {
    const modalRef = this.modal.create({
      nzContent: TimeClockNewCostCodeComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  // selectionChoiceModel
  costcodeeditor(): void {
    const modalRef = this.modal.create({
      nzContent: TimeClockCostCodeEditorComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  // selectionChoiceModel
  addtime(): void {
    const modalRef = this.modal.create({
      nzContent: TimeClockAddTimeClockTagComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  // selectionChoiceModel
  edittime(): void {
    const modalRef = this.modal.create({
      nzContent: TimeClockEditTimeClockTagComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  showParameters = false;
  addModuleParameter() {
    this.timeClockForm.createShiftInformationBreakParameterRequests.push({
      breakStartTime: new Date(),
      breakStartOn: new Date(),
      breakEndTime: new Date(),
      breakEndOn: new Date()
    });
    this.showParameters = true; 
  }
  removeModuleParameter(index: number) {
    this.timeClockForm.createShiftInformationBreakParameterRequests.splice(index, 1);
    if (this.timeClockForm.createShiftInformationBreakParameterRequests.length === 0) {
      this.showParameters = false;
    }
  }

  saveShift() {
    console.log('save Data Shift', this.timeClockForm);
 
    let tag = this.timeClockForm.createShiftTagParameterRequests.map(
      (id: string) => ({ createShiftTagParameterRequests: id })
    );
    let Break = this.timeClockForm.createShiftInformationBreakParameterRequests.map(
      (id: string) => ({ createShiftInformationBreakParameterRequests: id })
    );

    this.timeClockForm.createShiftTagParameterRequests = tag;
    this.timeClockForm.createShiftInformationBreakParameterRequests = Break;

    this.timeClockIn.PostTimeClock(this.timeClockForm).subscribe((response) => {
      this.toastssService.success('New  Successfully!');
      this.timeClockForm = {};
      this.modal.closeAll();
      });
  }
  
}
