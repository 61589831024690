<section class="ant-layout">
  <div class="ListSection react">
    <div class="GridContainer-Header StickyLayoutHeader" style="height: 72px; top: 48px;">
      <div nz-row>
        <div nz-col nzSpan="12" class="ListViewHeaderCol">
          <h2 class="react" style="padding: 0px 0px 0px 41px;">Internal Users</h2>
        </div>
        <div nz-col nzSpan="12" class="ListViewHeaderCol">
          <div class="d-flex justify-content-end align-items-end" style="padding: 0px 41px 0px 0px;">
            <button nz-button nzType="default" class="react me-2" (click)="exportToExcel()"><span>Export</span></button>
            <button nz-button nzType="default" class="react me-2" (click)="openFilter(INTERNAL_USER, PAGE_ID)"><span class="bi bi-funnel ml-0 pl-0 mr-0 pr-0"></span><span class="ml-0 pl-0 mr-0 pr-0 ms-2">Filter</span></button>
            <button nz-button nzType="primary" class="react me-2" (click)="showNewBulkInternalUser()"><span>Invite Multiple User</span></button>
            <button nz-button nzType="primary" (click)="showNewInternalUser()"><span>New Internal User</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="GridContainer" style="position: sticky; padding: 0px 14px 0px 14px; background-color: #fff;">
    <loader-icon *ngIf="isLoading" class="loader-icon"></loader-icon>
    <kendo-grid
    (columnResize)="onColumnResize($event)"
    [data]="getInternalUserData"
    [pageSize]="5"
    [sortable]="true"
    [resizable]="true"
    [reorderable]="true"
    (sortChange)="sortChange($event)">
      <kendo-grid-column *ngFor="let column of displayedColumns; let i = index"
        [title]="column"
        [field]="column"
        [sortable]="true"
        [minResizableWidth]="150"
        [maxResizableWidth]="800"
        [width]="getColumnWidthSize[i]"
        [sticky]="isColumnSticky[i]"
        [headerClass]="'pinned-header'">
        <ng-template kendoGridHeaderTemplate let-column>
          <div class="k-link p-0 m-0" style="width: 100%" (click)="onSort(column.title)">
            <table>
              <thead>
                <tr>
                  <th>{{ column.title }}</th>
                  <th>
                    <div class="test">
                      <i *ngIf="isActiveColumn(column.title)"
                         class='bx bxs-up-arrow'
                         [ngStyle]="{color: '#004fd6'}"></i>
                      <i *ngIf="!isActiveColumn(column.title)"
                         class='bx bxs-up-arrow hover-show'></i>
                      <i *ngIf="!isActiveColumn(column.title)"
                         class='bx bxs-down-arrow hover-show'></i>
                    </div>
                  </th>
                  <!-- <th>
                    <span class="pined ms-5"
                          nz-icon
                          nzType="pushpin"
                          nzTheme="fill"
                          (click)="onPinClick(column.title)">
                    </span>
                  </th> -->
                </tr>
              </thead>
            </table>
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <ng-container *ngIf="column === 'Name' || column === 'id'">
            <a (click)="EditInternalUser(dataItem)" nz-button nzType="link"><span class="underdecor">{{ dataItem?.firstName }} {{ dataItem?.lastName }}</span></a>
          </ng-container>
          <ng-container *ngIf="column === 'Role'">{{ dataItem?.applicationGroup?.name }}</ng-container>
          <ng-container *ngIf="column === 'Admin Access'">{{ dataItem?.isAdminAccess }}</ng-container>
          <ng-container *ngIf="column === 'Login'">
            <ng-container *ngIf="dataItem?.loginAccess === true">
              <nz-tag class="active" [nzColor]="'#c4fae2'">Active</nz-tag>
            </ng-container>
            <ng-container *ngIf="dataItem?.loginAccess === false">
              <nz-tag class="inactive" [nzColor]="'#fbe9b1'">INVITE PENDING</nz-tag>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="column === 'Auto Access'">{{ dataItem?.isAutoAccess }}</ng-container>
          <ng-container *ngIf="column === 'Email'">
            <span>{{ dataItem?.email }} </span>
            <span *ngFor="let email of dataItem?.applicationUserEmailParameters; let last = last">
              <span *ngIf="!last && isExpanded(rowIndex)">{{ email?.email }}, </span>
              <span *ngIf="last && isExpanded(rowIndex)">{{ email?.email }}</span>
              <span style="cursor: pointer;" *ngIf="last && !isExpanded(rowIndex) && dataItem?.applicationUserEmailParameters?.length > 0" (click)="toggleAdditionalEmails(rowIndex)">...</span>
            </span>
          </ng-container>
          <ng-container *ngIf="column === 'Phone'">
            <a nz-button nzType="link" class="text-decoration-none">{{ dataItem?.phoneNumber }}</a>
          </ng-container>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
  <div class="StickyFooterWrapper react">
    <div class="StickyFooter react" style="left: -13px; right: -8px;">
      <div class="ListActionBar bottom StickyLayoutHeader react" style="left: -13px; right: -8px;">
        <div nz-row>
          <div nz-col nzSpan="14">
            <ng-container  *ngIf="PAGE_ID">
              <div nz-row>
                <div nz-col nzSpan="7">
                  <nz-select class="mt-1"  (ngModelChange)="onFilterChange($event)" [(ngModel)]="selectedValueControl" nzShowSearch nzAutoFocus="true">
                    <nz-option *ngFor="let standard of getStandardViewFilterData" [nzLabel]="standard.name"
                      [nzValue]="standard.id"></nz-option>
                  </nz-select>
                </div>
                <div nz-col nzSpan="1">
                  <!-- <a nz-popover [nzPopoverContent]="contentTemplate" nzPopoverTrigger="hover" class="react" (click)="openStandardViewFilterComponent()"><i style="font-size: 1.43em;" class="clickTrigred fa-solid fa-ellipsis mt-2 ms-2"></i></a> -->
                  <a nz-popover [nzPopoverContent]="contentTemplate" nzPopoverTrigger="hover" class="react" nz-button nzType="link" (click)="openStandardViewFilterComponent()"><i style="font-size: 1.43em;" class="bx bx-dots-horizontal-rounded mt-2"></i></a>
                  <ng-template #contentTemplate>
                    <p>Manage Saved Views</p>
                </ng-template>
                </div>
                <div nz-col nzSpan="6">
                  <div class="mt-2" *ngIf="selectedFilter === 1">
                    <p class="UnsavedChanges">Your filter has unsaved changes.</p>
                  </div>
                  <div class="mt-2" *ngIf="selectedFilter === 0">
                    <p class="UnsavedChanges">Your filter has unsaved changes.</p>
                  </div>
                </div>
               <!-- Ensure that saveColumewrewrewrnSizes is called on the correct event -->
               <div nz-col nzSpan="10">
                <button *ngIf="selectedFilter === 0 " nz-button nzType="primary" class="react mt-1 me-2" (click)="saveEditStandardView()"><span>Save</span></button>
                <button *ngIf="selectedFilter === 0" nz-button nzType="default" class="react mt-1 me-2" (click)="reInitReset()"><span>Reset</span></button>
                <button *ngIf="selectedFilter === 1 " nz-button nzType="default" class="react mt-1 me-2" (click)="reInitReset()"><span>Reset</span></button>
                <button *ngIf="selectedFilter === 0 " nz-button nzType="default" class="react mt-1" (click)="openStandardViewFilterComponent('saveAs')"><span>Save As</span></button>
              </div>


              </div>
            </ng-container>
          </div>
          <div nz-col nzSpan="10">
            <div class="d-flex justify-content-end align-items-end">
              <nz-pagination style="float: right;" [nzPageIndex]="currentPage" [nzTotal]="totalRecords"
              [nzPageSize]="pageSize" nzShowSizeChanger (nzPageIndexChange)="onPageChange($event)"
              (nzPageSizeChange)="onPageSizeChange($event)" [nzPageSizeOptions]="[10, 20, 50, 75, 100]"></nz-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
