import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { CreateIsCompletedRequest, CreateToDoInformationCheckListItemRequest, CreateToDoRequest, CreateToDoSetupRequest, ToDoInformationCheckListItemResponse, ToDoResponse, ToDoSetupResponse } from 'src/Models/Project-Management/projectManagement';
import { CopyTodoRequest, ToDoParameter } from 'src/Models/Warranty/warranty';
import { ResponseModel, TablePageResponse, ResponseModelArray } from 'src/Models/responseMessage.model';
import { CreateLookupFilterRequest, FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ToDoInformationService {

  private PROJECT_MANAGMENT_TO_DO_URL: string = environment.apiUrl + 'ToDo/';
  private PRO_MANAG_TO_DO_CheckList_URL: string = environment.apiUrl + 'ToDo/create-ToDoCheckList/';
  private base_Url: string = environment.apiUrl + 'LookupFilter/';

  gettoDosData = new BehaviorSubject<ResponseModel<TablePageResponse<ToDoResponse>>>(null);
  $getToDoInformation = this.gettoDosData.asObservable();

  isUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $isUserAppliedFilter = this.isUserAppliedFilter.asObservable();

  selectedFilterId = new BehaviorSubject<number>(0);
  $selectedFilterId = this.selectedFilterId.asObservable();  private Comp_Set_TO_DO_Setup_URL: string = environment.apiUrl + 'ToDoSetup/';
  private Comp_Set_TO_DO_Get_Setup_URL: string = environment.apiUrl + 'ToDoSetup/';


  constructor(private http: HttpClient) {}

  postDataToDoModel(ToDosFormData: CreateToDoRequest): Observable<ResponseModel<ToDoResponse>> {
    const apiUrl = `${this.PROJECT_MANAGMENT_TO_DO_URL}`;
    return this.http.post<any>(apiUrl, ToDosFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  
  postDataToDoToDoCheckList(ToDoCheckList: CreateToDoInformationCheckListItemRequest): Observable<ResponseModel<ToDoInformationCheckListItemResponse>> {
    const apiUrl = `${this.PRO_MANAG_TO_DO_CheckList_URL}`;
    return this.http.post<any>(apiUrl, ToDoCheckList).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error); 
      })
    );
  }

  postDataToDoToDoSetupCompSet(ToDoSetupCompSet: CreateToDoSetupRequest): Observable<ResponseModel<ToDoSetupResponse>> {
    const apiUrl = `${this.Comp_Set_TO_DO_Setup_URL}`;
    return this.http.post<any>(apiUrl, ToDoSetupCompSet).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error); 
      })
    );
  }

    getData(): Observable<ResponseModelArray<ToDoSetupResponse>> {
      return this.http.get<ResponseModelArray<ToDoSetupResponse>>(`${this.Comp_Set_TO_DO_Get_Setup_URL}getData-ByCompany`);
    }


  setSelectedFilterId(filterId: number){
    this.selectedFilterId.next(filterId);
  }


  getAlltodoUsersByFilter(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.gettoDosData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  getAppliedFilterData(appliedFilter: CreateLookupFilterRequest[]) {
    const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
    return this.http.post<any>(apiUrl, appliedFilter).subscribe(
      (data) => {
        this.gettoDosData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }

  updateIsCompleted(internalUserFormData: CreateIsCompletedRequest): Observable<ResponseModel<ToDoParameter>> {
    const apiUrl = `${this.PROJECT_MANAGMENT_TO_DO_URL}IsCompleted`;
    return this.http.put<ResponseModel<ToDoParameter>>(apiUrl, internalUserFormData);
  }

  deleteRowTodoRange(globalIds: string[]): Observable<ResponseModel<ToDoParameter>> {
    const apiUrl = `${this.PROJECT_MANAGMENT_TO_DO_URL}Delete-TodoParameters`;
    return this.http.delete<ResponseModel<ToDoParameter>>(apiUrl, { body: globalIds });
  }
  
  
  postCopyToDos(CopyTodoRequest: CopyTodoRequest): Observable<ResponseModel<ToDoParameter>> {
    const apiUrl = `${this.PROJECT_MANAGMENT_TO_DO_URL}CopyTodoFromTodo`;
    return this.http.put<any>(apiUrl, CopyTodoRequest).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error); 
      })
    );
  }


  deleteToDo(globalId: string): Observable<ResponseModel<any>> {
    return this.http.delete<ResponseModel<any>>(`${this.PROJECT_MANAGMENT_TO_DO_URL}ToDoParameter?globalId=${globalId}`);
  }
  

}
 