import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, throwError } from 'rxjs';
import { AddLeadActivityTypeResponse, CreateAddLeadActivityTypeRequest, LeadActivityMetaData, UpdateAddLeadActivityTypeResponse } from 'src/Models/LeadActivity/leadActivityClass';
import { ApiResponseModel } from 'src/Models/apiResponse.model';
import { ResponseModel, ResponseModelArray } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class LeadActivityTypeService {
  private base_Urls: string = environment.apiUrl + 'LeadActivityType/';

  constructor(private http: HttpClient) {}
  
  postAddLeadActivityType(LeadActivityTypeFormData: CreateAddLeadActivityTypeRequest): Observable<ResponseModel<CreateAddLeadActivityTypeRequest>> {
    const apiUrl = `${this.base_Urls}`;
    return this.http.post<any>(apiUrl, LeadActivityTypeFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  deleteData(globalId: string): Observable<CreateAddLeadActivityTypeRequest> {
    return this.http.delete<any>(`${this.base_Urls}?globalId=${globalId}`);
  }
  getAddLeadActivityType():Observable<ResponseModelArray<CreateAddLeadActivityTypeRequest>>{
    return this.http.get<ResponseModelArray<CreateAddLeadActivityTypeRequest>>(`${this.base_Urls}getAll`);
  }
  EditAddLeadActivityType(body: CreateAddLeadActivityTypeRequest): Observable<ResponseModelArray<CreateAddLeadActivityTypeRequest>> {
    return this.http.put<ResponseModelArray<CreateAddLeadActivityTypeRequest>>(`${this.base_Urls}Post`, body);
  }
  
  private newArgumentSource = new BehaviorSubject<boolean>(false);
  newArgument$ = this.newArgumentSource.asObservable();

  private leadActivityTypeResponseSource = new Subject<any>();
  leadActivityTypeResponse$ = this.leadActivityTypeResponseSource.asObservable();

  setNewArgument(value: boolean) {
    this.newArgumentSource.next(value);
  }

  resetNewArgument() {
    this.newArgumentSource.next(false);
  }
  setLeadActivityTypeResponse(response: any) {
    this.leadActivityTypeResponseSource.next(response);
  }
  // private onAfterResponseSource = new Subject<void>();
  // onAfterResponse$ = this.onAfterResponseSource.asObservable();

  // triggerOnAfterResponse() {
  //   this.onAfterResponseSource.next();
  // }
  private activityResponseIdSource = new BehaviorSubject<number>(null);
  currentActivityResponseId = this.activityResponseIdSource.asObservable();
  
  changeActivityResponseId(id: number) {
    this.activityResponseIdSource.next(id);
  }
}
