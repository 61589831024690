import { Component, Input, OnInit } from '@angular/core';
import { AuditDetailsResponse } from 'src/Models/Footer/footer.model';
import { LeadActivityTemplateResponse } from 'src/Models/LeadActivityTemplate/LeadActivityTemplate';
import { FooterService } from 'src/Service/Footer/footer.service';

@Component({
  selector: 'app-footer-label',
  templateUrl: './footer-label.component.html',
  styleUrls: ['./footer-label.component.css']
})
export class FooterLabelComponent implements OnInit {
  @Input() footerData: AuditDetailsResponse | null = null;
  constructor(
  ) { }


  ngOnInit(): void {
  

  }




}
