<nz-table #sortTable #fixedTable [nzData]="listOfData" [nzSize]="'default'" [nzShowPagination]="false"
nzTableLayout="fixed">

<thead>
  <tr>
    <th nzWidth="150px" class="text-center">
      <span >Access</span>
      <div >
        <label nz-checkbox ></label>
      </div>
    </th>
    <th nzWidth="150px" class="text-center">
      <span >Notifications</span>
      <div >
        <label nz-checkbox ></label>
      </div>
    </th>
    <th *ngFor="let column of listOfColumn" [nzSortFn]="column.compare" [nzSortPriority]="column.priority" colspan="{{ column.title === 'Job Name' ? 2 : 1 }}">
      {{ column.title }}
    </th>
  </tr>
</thead>
<tbody>
  <tr *ngFor="let data of sortTable.data">
    <td  class="text-center">
      <div>
        <label nz-checkbox ></label>
      </div>
    </td>
    <td  class="text-center"> 
      <div>
        <label nz-checkbox ></label>
      </div>
    </td>
    <td  colspan="2" >{{ data.name }}</td>
    <td >{{ data.chinese }}</td>
    <td >{{ data.math }}</td>
    <td >{{ data.english }}</td>
  </tr>
</tbody>
</nz-table>











