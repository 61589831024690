
import { filter } from 'rxjs/operators';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { FilterSearchParameter, LookupFilterResponse, LookupNameSetupResponse } from 'src/app/shared/component/Models/LookupModels';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { LeadOpportunitiesSettingComponent } from '../Modal/lead-opportunities-setting/lead-opportunities-setting.component';
import { LeadOpportunitiesLearnMoreComponent } from '../Modal/lead-opportunities-learn-more/lead-opportunities-learn-more.component';
import { LeadOpportunitiesExportComponent } from '../Modal/lead-opportunities-export/lead-opportunities-export.component';
import { LeadOpportunitiesNewLeadOpportunitiesComponent } from '../Modal/lead-opportunities-new-lead-opportunities/lead-opportunities-new-lead-opportunities.component';
import { LeadOpportunitiesImportLeadOpportunitiesComponent } from '../Modal/lead-opportunities-import-lead-opportunities/lead-opportunities-import-lead-opportunities.component';
import { LeadOpportunitiesAddContactComponent } from '../Modal/lead-opportunities-add-contact/lead-opportunities-add-contact.component';
import { SalesComponent } from 'src/app/shared/component/sales/sales.component';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';
import { ColumnsResizePinProperties } from 'src/Models/InternalUser/internalUserColumnsPinResize';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { Subscription } from 'rxjs';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LeadActivityTemplateResponseMetaData, LeadOpportunityMetaData, LeadOpportunityResponse, LeadProposalsMetaDataResponse } from 'src/Models/LeadTags';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { LeadOpportunitySelectDialogBoxComponent } from '../lead-opportunity-select-dialog-box/lead-opportunity-select-dialog-box.component';
import { LeadOpportunitiesActivityTemplatesHeaderComponent } from '../Lead-opportunities-header/lead-opportunities-activity-templates-header/lead-opportunities-activity-templates-header.component';
import { NewProposalComponent } from '../new-proposal/new-proposal.component';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { ListViewFService } from 'src/Service/Sales-List-View-F/list-view-f.service';
import { LeadProposalsFService } from 'src/Service/Sales-lead-proposals-F/lead-proposals-f.service';
import { LeadActivityTemplatesService } from 'src/Service/Sales-Lead-Activity-Templates/lead-activity-templates.service';
import { LeadProposalResponse } from 'src/Models/LeadProposal/LeadProposalModels';
import { ShiftInformationResponse } from 'src/Models/Time-Clock/clock-in';
import { NewContactComponent } from 'src/app/customer-infromation/Components/new-contact/new-contact.component';
import { LeadProposalsService } from 'src/Service/LeadProp/lead-proposals.service';
import { LeadActivityTypeService } from 'src/Service/Lead-Activity-Type/lead-activity-type.service';
import * as XLSX from 'xlsx';
import { ViewContainerRef, SimpleChanges } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { CalendarOptions } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { LeadActivitiesService } from 'src/Service/Sales-Lead-Activities/lead-activities.service';
import { Status } from 'src/app/Core/Enums/status';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { LeadActivityFormComponent } from '../lead-activity-form/lead-activity-form.component';
import { NewLeadProposalTemplateComponent } from '../Lead-Template/new-lead-proposal-template/new-lead-proposal-template.component';
import { LeadActivityResponse, LeadActivityResponses, LeadActivityTemplateResponse, LeadActivityTemplateResponses } from 'src/Models/LeadActivityTemplate/LeadActivityTemplate';
import { LeadActivityMetaData } from 'src/Models/LeadActivity/leadActivityClass';
import { CompanySettingssalesPreDefineCodes, LeadStatusCode } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { CustomerInformationResponse } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { LeademailWizardComponent } from '../leademail-wizard/leademail-wizard.component';
import { LeadActivityDeleteComponent } from '../Modal/lead-activity-delete/lead-activity-delete.component';
import { LeadActivityAssignUserComponent } from '../Modal/lead-activity-assign-user/lead-activity-assign-user.component';
import { LeadActivityAssignDisplayColorComponent } from '../Modal/lead-activity-assign-display-color/lead-activity-assign-display-color.component';
import { LeadmapService } from 'src/Service/Leadmap/leadmap.service';
import { AddNewGridStandardViewComponent } from 'src/app/shared/component/Modal/add-new-grid-standard-view/add-new-grid-standard-view.component';
import {  SalesSettings } from 'src/Models/LeasSalesPeople';
interface ItemData {
  id: number;
  name: string;
  age: number;
  address: string;
  icon: string;
  number: number;
  icon2: string;
}

@Component({
  selector: 'app-lead-opportunities-dash-board',
  templateUrl: './lead-opportunities-dash-board.component.html',
  styleUrls: ['./lead-opportunities-dash-board.component.css'],
})
export class LeadOpportunitiesDashBoardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() leadOpportunityResponse: LeadActivityResponse;
  private subscriptions: Subscription[] = [];
  private salesListViewSubscription: Subscription;
  private salesListViewFilterSubscription: Subscription;
  private resetSubscription: Subscription;
  private salesActivityViewSubscription: Subscription;
  private salesActivityTemplatesSubscription: Subscription;
  private displayActivityColumnSubscription: Subscription;
  private salesActivityViewFilterSubscription: Subscription;


  selectionFrom: string = 'DB';
  LEAD_OPPR_ACT_TEMPL: string = FiltersName.LEAD_OPPR_ACT_TEMPL;
  LEAD_OPPR_ACT_VIEW: string = FiltersName.LEAD_OPPR_ACT_VIEW;
  LEAD_OPPR_LEAD_PROP: string = FiltersName.LEAD_OPPR_LEAD_PROP;
  LEAD_OPPR_LIST_VIEW: string = FiltersName.LEAD_OPPR_LIST_VIEW;
  LEAD_OPPR_MAP: string = FiltersName.LEAD_OPPR_MAP;
  LEAD_OPPR_PROP_TEMP: string = FiltersName.LEAD_OPPR_PROP_TEMP;
  //LEAD_OPPR_LIST_VIEW: string = FiltersName.LEAD_OPPR_LIST_VIEW;
  Selected_Filter_Type: string = this.LEAD_OPPR_LIST_VIEW;
  PAGE_ID: number = -1; // Default page ID
  visible = false;
  tagPlaceHolder: TemplateRef<{ $implicit: any[] }> | null | undefined;
  checked1: any;
  lucy: any;
  demoValue = 3;
  listOfOption: string[] = [];
  listOfSelectedValue = ['a10', 'c12'];
  isVisible8 = false;
  isVisibleLead = false;
  selectedValue1: Date | null = null;
  @ViewChild('googleMapFrame') googleMapFrame: any;
  isBlinking: boolean = false;
  tabs = [1, 2, 3, 4, 5, 6, 7];
  activeTab: number = 0;
  tabss = [9, 10, 11];
  isModalVisible = false;
  isVisibleAddModal = false;
  isVisible = false;
  isContactVisible = false;
  isImportVisible = false;
  isActivityTemVisible = false;
  date = null;
  inputValue: string = 'my site';
  value: string[] = ['0-0-0'];
  filterDrawerVisible = false;
  selectedValue = null;
  showCustomFields = false;
  showCustomFields1 = false;
  showDetails: boolean = false;
  NewArgument: boolean = false;
  showleadopportunitiesDialog: boolean = false;
  listOfData: ItemData[] = [];
  getNewProposalData: any[] = [];
  columnSizes: { columnName: string, widthSize: number }[] = [];
  isChooseVisible = false;
  displayColumnSubscription: Subscription;
  // currentPage = 1;
  currentPageListView: number = 1;
  currentPageMap: number = 1;
  pageSizeListView: number = 10;
  currentPageActivityView: number = 1;
  pageSizeActivityView: number = 100;
  currentPageactivityTemplates: number = 1;
  LeadMapPage: number = 1;
  pageSizeMap: number = 10;
  pageSizeActivityTemplates: number = 10;
  currentPageLeadProposals: number = 1;
  pageSizeLeadProposals: number = 10;
  currentPageProposalTemplates: number = 1;
  pageSizeProposalTemplates: number = 10;
  totalRecords = 100;
  expandedRows: number[] = [];
  isFilterApplied: boolean = false;
  switchValue = false;
  isformed = false;
  isLoading: boolean = false;
  defaultFilterIdSelected: number = 0;
  filterSearchParameter: FilterSearchParameter;
  LeadListViewData: LeadOpportunityMetaData[] = [];
  LeadActivityViewData: LeadActivityResponse[] = [];
  getActivityTemplatesData: LeadActivityTemplateResponse[] = [];
  displayedColumns: string[] = [];
  isColumnSticky: boolean[] = new Array(this.displayedColumns.length).fill(false);
  selectedFilterData: any = null;
  selected_View_Columns: string[] = [];
  selectedFilter: number = -1;
  getStandardViewFilterData: StandardViewResponse[] = [];
  selectedValueControl: any;
  selectedColNameSizePin: ColumnsResizePinProperties[] = [];
  getStandardViewColumnFilterData: any[] = [];
  fixedSelectedColumnNames: string[] = [];
  getColumnWidthSize: number[] = [];
  JobMenuButton: boolean = false;
  lookupFilterResponse: LookupFilterResponse[] = [];
  leadOptions: LeadOpportunityResponse[] = [];
  leadOptionsNew: LeadOpportunityResponse;
  displayedData: any;
  // getrfiUserData: LeadActivityResponse [] = [];
  getrfiUserDatas: LeadActivityMetaData[] = [];
  getLeadMapDataResponse: LeadOpportunityMetaData[] = [];
  ActivityId: number;
  getLeadProposalData: LeadProposalsMetaDataResponse[] = [];
  getLeadProposalisTemplateData: LeadProposalsMetaDataResponse[] = [];
  getProposalTemplatesData: LeadActivityTemplateResponseMetaData[] = [];
  percent = 0;
  isOpen5: boolean = false;
  public showOvertimeContent: boolean = true;
  private commentFilterSubscription: Subscription;
  current = 0;
  index = 'First-content';
  sliderValue: number = 0;
  customFieldsCount: number = 19;
  listOfCurrentPageData: readonly ItemData[] = [];
  isVisible9 = false;
  isVisible10 = false;
  isFieldsVisible = false;
  indeterminate = false;
  checked00 = false;
  radioValue = 'A';
  checked = true;
  setOfCheckedId = new Set<number>();
  selectedLead: LeadOpportunityResponse;
  defaultCalendarView: LookupNameSetupResponse[] = [];
  selectedLeadId: any;
  holdResponse: ShiftInformationResponse[];
  activeDate: string;
  isIconOnly: boolean = false;
  pageId: number;
  isLoadingEdit: boolean = false;
  Status = Status;
  HeaderId: number;
  private pageIdSubscription: Subscription;
  statusColorMap = {
    [Status.OPEN]: { backgroundColor: '#c7d0d9', textColor: '#202227' },
    [Status.IN_PROGRESS]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
    [Status.ON_HOLD]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
    [Status.PENDING]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
    [Status.LOST]: { backgroundColor: '#fdddd5', textColor: '#8b1439' },
    [Status.SOLD]: { backgroundColor: '#c4fae2', textColor: '#005c35' },
    [Status.NO_OPPORTUNITY]: { backgroundColor: '#f7e7e7', textColor: '#8b1439' }  // Lighter than LOST
  };


  salesData: any;
  constructor(
    private modal: NzModalService,
    private filterService: FilterSideMenuBarService,
    private route: ActivatedRoute, private router: Router,
    private standardViewService: StandardViewForAllService,
    public toastService: NzMessageService,
    private LookupFilterService: LookupFilterService,
    private changeDetectorRef: ChangeDetectorRef,
    private listViewFService: ListViewFService,
    private cdr: ChangeDetectorRef,
    public accessLocalStorageService: AccessLocalStorageService,
    public leadActivitiesService: LeadActivitiesService,
    public leadOpportunitiesService: LeadOpportunitiesService,
    private leadSer: LeadOpportunitiesService,
    public leadProposalsFService: LeadProposalsFService,
    public leadProposalsService: LeadProposalsService,

    public leadActivityTemplatesService: LeadActivityTemplatesService,
    private leadAceeetivitiesService: LeadActivityTypeService,
    private loadingIndicatoreService: LoadingIndicatorService,
    private viewContainerRef: ViewContainerRef,
    private ngZone: NgZone,
    private leadmapService: LeadmapService,
    private leadSetvices: LeadmapService
  ) {

    this.activeDate = new Date().toISOString().split('T')[0];
    this.displayedColumns = [];

  }
  currentPageId = 0;
  selectedCalendarId: number | null = null;
  dataset: SalesSettings;
  ngOnInit(): void {

    this.listViewFService.dataUpdated$.subscribe(() => {
      this.leadMapListGrid(1, 40022, this.defaultFilterIdSelected, this.LeadMapPage, this.pageSizeMap);
    });


    this.leadSer.getDataByCompany().subscribe(
      (response) => {
        this.dataset = response.result;
        if (this.dataset && this.dataset.ActivityCalendarSettingId) {
          this.selectedCalendarId = this.dataset.ActivityCalendarSettingId;
        }
      },
      (error) => {
        console.error('Error fetching data', error);
      }
    );

    this.selectedFilter = -1;
    // Subscribe to page ID changes
    this.pageIdSubscription = this.filterService.getPageId().subscribe((id) => {
      this.pageId = id;
      this.currentPageId = id;

    });
    this.subscriptions.push(this.pageIdSubscription);

    // Subscribe to URL changes
    this.route.url.subscribe(urlSegments => {
      if (urlSegments.length > 0) {
        const path = urlSegments[0].path;
        this.handlePathChange(path);
      } else {
        console.warn('No URL segments found');
      }
      this.updatePageId();
    });
    // this.isFirstTabActive();
    this.subscriptions.push(
      this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
        this.selectedFilter = standardType != -1 ? standardType : standardType;
      })
    );
    this.displayedColumns = [];
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    // Add subscriptions to the array
    this.subscriptions.push(
      this.standardViewService.$displaySelectedColApply.subscribe(colName => {
        if (colName.length > 0) {
          this.displayedColumns = colName;
          this.changeDetectorRef.detectChanges();
        }
      })
    );


  }



  handlePathChange(path: string): void {
    // Unsubscribe from previous page's API calls if any
    this.cleanupPageResources();

    switch (path) {
      case 'Lead-Opportunities':
        this.activeTab = 0;
        this.Selected_Filter_Type = FiltersName.LEAD_OPPR_LIST_VIEW;
        this.PAGE_ID = 36;
        this.listViewTabAction(this.PAGE_ID);
        break;
      case 'Lead-Activities':
        this.activeTab = 1;
        this.Selected_Filter_Type = FiltersName.LEAD_OPPR_ACT_VIEW;
        this.PAGE_ID = 4;
        this.activityViewTabAction(this.PAGE_ID);
        break;
      case 'Lead-Activity-Calender':
        this.activeTab = 2;
        //this.PAGE_ID = 38;
        this.PAGE_ID = 4;
        this.activityCalendarTabAction(this.PAGE_ID);
        break;
      case 'Lead-Proposals':
        this.initLoad();
        this.activeTab = 4;
        this.Selected_Filter_Type = FiltersName.LEAD_OPPR_LEAD_PROP;
        this.PAGE_ID = 37;
        this.leadProposalsTabAction(this.PAGE_ID);

        break;
      case 'Lead-Map':
        this.activeTab = 7;
        this.Selected_Filter_Type = FiltersName.LEAD_OPPR_MAP;
        this.PAGE_ID = 20003;
        break;
      default:
        console.warn('No matching path found');
    }

  }

  cleanupPageResources(): void {
    // Logic to cleanup any resources related to the previous page
    if (this.salesListViewSubscription) {
      this.salesListViewSubscription.unsubscribe();
    }
    if (this.salesListViewFilterSubscription) {
      this.salesListViewFilterSubscription.unsubscribe();
    }
    this.LookupFilterService.getStandardFilter.next(null);
    this.changeDetectorRef.detectChanges();
  }

  updatePageId(): void {
    this.PAGE_ID = this.filterPageMap[this.Selected_Filter_Type];
  }

  openFilter(component: string, pageId: number) {
    this.selectionFrom = ''; // New Changes By Aamir Ali - 22-Apr2024
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
    this.filterService.toggleFilter(component, pageId);
  }


  filterPageMap: { [key: string]: number } = {
    [FiltersName.LEAD_OPPR_ACT_TEMPL]: 38,
    [FiltersName.LEAD_OPPR_ACT_VIEW]: 4,
    [FiltersName.LEAD_OPPR_LEAD_PROP]: 37,
    [FiltersName.LEAD_OPPR_LIST_VIEW]: 36,
    [FiltersName.LEAD_OPPR_PROP_TEMP]: 39,
    [FiltersName.LEAD_OPPR_MAP]: 20003
  };

// //







  ngAfterViewInit() {
    console.log(this.selectedFilter);
    // Defer the change to the next tick
    setTimeout(() => {
      this.isIconOnly = true;
      this.triggerResize();
      this.cdr.detectChanges();
    }, 0);
  }
  // <------------------Tab event------START--------------->
  listViewTabAction(pageId: number): void {
    this.LookupFilterService.getStandardFilter.next(null);
    this.displayedColumns = [];
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    this.getlistStandardFilterByFormPageId(pageId, this.selectionFrom);
    this.getlistStandardViewFilterByFormPageId(pageId);
    this.salesListViewSubscription = this.listViewFService.$ListIsUserAppliedFilter.subscribe(filter => {
      if (filter) {
        this.isFilterApplied = filter;
      } else {
        this.isFilterApplied = filter;
      }
    });
  }
  async activityCalendarTabAction(pageId: number) {
    try {
      await this.activityViewTabAction(pageId);
    } catch (error) {
      console.error('Error saving lead activity:', error);
    } finally {
      this.triggerResize();
    }
  }
  activityViewTabAction(pageId: number): void {
    this.LookupFilterService.getStandardFilter.next(null);
    this.displayedColumns = [];
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    this.activityViewgetStandardFilterByFormPageId(pageId, this.selectionFrom);
    this.activityViewgetStandardViewFilterByFormPageId(pageId);
    this.salesListViewSubscription = this.leadActivitiesService.$tabTwousertabOneuserId.subscribe(filter => {
      if (filter) {
        this.isFilterApplied = filter;
      } else {
        this.isFilterApplied = filter;
      }
    });
  }
  activityTemplatesTabAction(pageId: number): void {
    this.LookupFilterService.getStandardFilter.next(null); // New Changes By Aamir Ali - 31-May-2024
    this.displayedColumns = [];
    this.displayActivityColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    this.activityTemplatesgetStandardFilterByFormPageId(pageId, this.selectionFrom);
    this.activityTemplatesStandardViewFilterByFormPageId(pageId);
    this.salesListViewSubscription = this.leadActivityTemplatesService.$LeadIsUserAppliedFilter.subscribe(filter => {
      if (filter) {
        this.isFilterApplied = filter;
      } else {
        this.isFilterApplied = filter;
      }
    });
  }
  leadProposalsTabAction(pageId: number): void {
    this.cleanupSubscriptions();
    this.LookupFilterService.getStandardFilter.next(null); // New Changes By Aamir Ali - 31-May-2024
    this.displayedColumns = [];
    this.displayActivityColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    this.leadProposalsgetStandardFilterByFormPageId(pageId, this.selectionFrom);
    this.leadProposalsStandardViewFilterByFormPageId(pageId);
    this.salesListViewSubscription = this.leadProposalsFService.$ProposalIsUserAppliedFilter.subscribe(filter => {
      if (filter) {
        this.isFilterApplied = filter;
      } else {
        this.isFilterApplied = filter;
      }
    });
  }

  cleanupSubscriptions(): void {
    if (this.salesListViewSubscription) {
      this.salesListViewSubscription.unsubscribe();
    }
    if (this.displayActivityColumnSubscription) {
      this.displayActivityColumnSubscription.unsubscribe();
    }
    if (this.salesActivityViewFilterSubscription) {
      this.salesActivityViewFilterSubscription.unsubscribe();
    }
  }
  proposalTemplatesTabAction(pageId: number): void {
    this.LookupFilterService.getStandardFilter.next(null); // New Changes By Aamir Ali - 31-May-2024
    this.displayedColumns = [];
    this.displayActivityColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    this.proposalTemplatesgetStandardFilterByFormPageId(pageId, this.selectionFrom);
    this.proposalTemplatesStandardViewFilterByFormPageId(pageId);
    this.salesListViewSubscription = this.leadProposalsFService.$ProposalIsUserAppliedFilter.subscribe(filter => {
      if (filter) {
        this.isFilterApplied = filter;
      } else {
        this.isFilterApplied = filter;
      }
    });
  }

  leadMapTabAction(pageId: number): void {
    this.LookupFilterService.getStandardFilter.next(null);
    this.displayedColumns = [];
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    this.leadMapStandardFilterByFormPageId(pageId, this.selectionFrom);
    this.salesListViewSubscription = this.listViewFService.$ListIsUserAppliedFilter.subscribe(filter => {
      if (filter) {
        this.isFilterApplied = filter;
      } else {
        this.isFilterApplied = filter;
      }
    });
  }

  // <------------------Tab event------START--------------->

  getTabTitle(tab: number): string {
    switch (tab) {
      case 1:
        return 'List View';
      case 2:
        return 'Activity View';
      case 3:
        return 'Activity Calendar';
      case 4:
        return 'Activity Templates';
      case 5:
        return 'Lead Proposals';
      case 6:
        return 'Proposal Templates';
      case 7:
        return 'Map';
      default:
        return '';
    }
  }
  public tab: number = 0;
  getTabTitles(tab: number): string {
    switch (tab) {
      case 0:
        return 'Lead Opportunities';
      case 1:
        return 'Lead Activities';
      case 2:
        return 'Lead Opportunities';
      case 3:
        return 'Lead Activity Templates';
      case 4:
        return 'Lead Proposals';
      case 5:
        return 'Lead Proposal Templates';
      case 6:
        return 'Lead Opportunities';
      case 7:
        return 'Lead Opportunities';
      default:
        return '';
    }
  }


  public selectTab(index: number): void {
    if (this.tab !== index) {
      this.tab = index; // Switch to the selected tab
      this.new(); // Clear selection when switching tabs
    }
  }

  // <------------------GRID------START--------------->

  capitalizeFirstLetter(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  capitalizeFields(data: any): any {
    if (Array.isArray(data)) {
      return data.map(item => this.capitalizeFields(item));
    } else if (data !== null && typeof data === 'object') {
      const capitalizedData: any = {};
      Object.keys(data).forEach(key => {
        const capitalizedKey = this.capitalizeFirstLetter(key);
        capitalizedData[capitalizedKey] = this.capitalizeFields(data[key]);
      });
      return capitalizedData;
    }
    return data;
  }

  LeadListViewGridTableData(companyId: number, formNameId: number, lookUpFilterId: number, page: number, pageSizeListView: number) {
    this.isLoading = true;
    this.LeadListViewData = [];
    this.LookupFilterService.getStandardFilter.next(null);
    if (formNameId == 40022) {
      pageSizeListView = 10;
    }
    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId: lookUpFilterId,
      page: page,
      pageSize: pageSizeListView,
      jobInformationId: null
    };
    if (this.salesListViewSubscription) {
      this.salesListViewSubscription?.unsubscribe(); // Unsubscribe if there's an existing subscription
    }

    this.listViewFService.getListView(this.filterSearchParameter);

    this.salesListViewSubscription = this.listViewFService.$getViewUsers.subscribe(result => {
      if (result) {
        this.totalRecords = result?.result?.rowCount;
        this.LeadListViewData = result?.result?.rows;
        this.isLoading = false;
      }
    }, error => {
      this.isLoading = false;
      console.error('Error fetching data:', error);
    });
  }

  activityViewGrid(companyId: number, formNameId: number, lookUpFilterId: number, page: number, pageSizeActivityView: number): void {
    this.isLoading = true;
    this.getrfiUserDatas = [];
    this.LookupFilterService.getStandardFilter.next(null);

    if (lookUpFilterId === 50324 && formNameId) {
      pageSizeActivityView = 10; // Ensure correct comparison with `===`
    }

    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId: lookUpFilterId,
      page: page,
      pageSize: pageSizeActivityView,
      jobInformationId: null
    };

    if (this.salesListViewSubscription) {
      this.salesListViewSubscription.unsubscribe();
    }

    this.leadActivitiesService.getLeadActivityViewGridData(this.filterSearchParameter);
    this.salesListViewSubscription = this.leadActivitiesService.$getLeadUsers.subscribe(result => {
      if (result) {
        this.totalRecords = result?.result?.rowCount;
        this.getrfiUserDatas = result?.result?.rows;
        this.loadActivities(this.getrfiUserDatas);
        console.log("show all data", this.getrfiUserDatas);
      }
      this.isLoading = false; // Set loading to false after data is fetched
    }, error => {
      this.isLoading = false; // Ensure loading is also set to false on error
      console.error('Error fetching activity data:', error);
    });
  }

  activityTemplatesGrid(companyId: number, formNameId: number, lookUpFilterId: number, page: number, pageSizeActivityTemplates: number) {
    this.isLoading = true;
    this.getProposalTemplatesData = [];

    this.LookupFilterService.getStandardFilter.next(null);
    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId: lookUpFilterId,
      page: page,
      pageSize: pageSizeActivityTemplates,
      jobInformationId: null
    };

    if (this.salesListViewSubscription) {
      this.salesListViewSubscription?.unsubscribe();
    }

    this.leadActivityTemplatesService.getLeadActivity(this.filterSearchParameter);
    this.salesListViewSubscription = this.leadActivityTemplatesService.$getsctivityUsers.subscribe(result => {
      if (result) {
        this.totalRecords = result?.result?.rowCount;
        this.getProposalTemplatesData = result?.result?.rows;
        console.log("show grid id",this.getProposalTemplatesData);
        
        this.isLoading = false;
      }
    }, error => {
      this.isLoading = false;
      console.error('Error fetching activity templates data:', error);
    });
  }

  leadProposalsGrid(companyId: number, formNameId: number, lookUpFilterId: number, page: number, pageSizeLeadProposals: number) {
    this.isLoading = true;
    this.getLeadProposalData = [];
    this.LookupFilterService.getStandardFilter.next(null);
    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId: lookUpFilterId,
      page: page,
      pageSize: pageSizeLeadProposals,
      jobInformationId: null
    };

    if (this.salesListViewSubscription) {
      this.salesListViewSubscription?.unsubscribe();
    }

    this.leadProposalsFService.getLeadProposal(this.filterSearchParameter);
    this.salesListViewSubscription = this.leadProposalsFService.$getActivityUsers.subscribe(result => {
      if (result) {
        this.totalRecords = result?.result?.rowCount;
        this.getLeadProposalData = result?.result?.rows;
        this.isLoading = false;
      }
    }, error => {
      this.isLoading = false;
      console.error('Error fetching lead proposals data:', error);
    });
  }


  proposalTemplatesGrid(companyId: number, formNameId: number, lookUpFilterId: number, page: number, pageSizeProposalTemplates: number): void {
    this.isLoading = true;
    this.getLeadProposalisTemplateData = [];

    // Trigger standard filter reset
    this.LookupFilterService.getStandardFilter.next(null);

    // Prepare search parameters
    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId: lookUpFilterId,
      page: page,
      pageSize: pageSizeProposalTemplates,
      jobInformationId: null
    };

    // Unsubscribe from previous subscription if exists
    if (this.salesListViewSubscription) {
      this.salesListViewSubscription.unsubscribe();
    }

    // Fetch the proposal templates
    this.leadProposalsFService.getLeadProposal(this.filterSearchParameter);

    // Subscribe to the data service
    this.salesListViewSubscription = this.leadProposalsFService.$getActivityUsers.subscribe(result => {
      if (result) {
        this.totalRecords = result?.result?.rowCount;
        this.getLeadProposalisTemplateData = result?.result?.rows;
        console.log('template proposal griddata,', this.getLeadProposalisTemplateData);
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      console.error('Error fetching proposal templates data:', error);
    });
  }

  leadMapListGrid(companyId: number, formNameId: number, lookUpFilterId: number, page: number, pageSizeMap: number) {
    this.isLoading = true;
    this.getrfiUserDatas = [];
    this.LookupFilterService.getStandardFilter.next(null);

    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId: lookUpFilterId,
      page: page,
      pageSize: pageSizeMap,
      jobInformationId: null
    };


    this.leadmapService.getMapData(this.filterSearchParameter);
    this.salesListViewSubscription = this.leadmapService.$getLeadMap.subscribe(result => {
      if (result) {
        this.totalRecords = result?.result?.rowCount;
        this.getLeadMapDataResponse = result?.result?.rows;
        this.isLoading = false;
      }
    }, error => {
      this.isLoading = false;
      console.error('Error fetching activity data:', error);
    });
  }
  // <------------------List View------START----------------->
  getlistStandardFilterByFormPageId(pageId: number, type: string) {
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.salesListViewFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.lookupFilterResponse = result.result;
        let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
        this.listViewFService.ListViewSetSelectedFilterId(defaultFilterId);
        let id = -1;
        if (type === this.selectionFrom) {
          this.defaultFilterIdSelected = defaultFilterId;
          this.accessLocalStorageService.setSelectedFilterId(defaultFilterId);
          id = this.accessLocalStorageService.getSelectedFilterId();
        } else {
          id = this.accessLocalStorageService.getSelectedFilterId();
        }
        if (id !== -1) {
          this.LeadListViewGridTableData(1, this.PAGE_ID, id, this.currentPageListView, this.pageSizeListView);
        }

      }
    });
  }
  // getlistStandardViewFilterByFormPageId(pageId: number) {
  //   if (this.PAGE_ID === 36) {
  //     this.displayedColumns = [];
  //     this.fixedSelectedColumnNames = [];
  //     this.standardViewService.getStandardViewFilterByFormPageId(pageId);
  //     this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
  //     this.standardViewService.$getStandardViewFilter.subscribe(result => {
  //       if (result) {
  //         this.displayedColumns = [];
  //         this.selected_View_Columns = [];
  //         this.getStandardViewFilterData = result.result;
  //         let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).standardViewParameters;
  //         this.selectedValueControl = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).id;
  //         defaultView.forEach(viewItem => {
  //           if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
  //             this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
  //           }
  //         });
  //         this.displayedColumns = this.selected_View_Columns;
  //       }
  //     });

  //     this.standardViewService.$getStandardViewColumn.subscribe(result => {
  //       if (result) {
  //         this.getStandardViewColumnFilterData = result.result;
  //         let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);
  //         fixedCol.forEach(x => {
  //           this.fixedSelectedColumnNames.push(x.displayColumnName);
  //         });

  //       }
  //     });
  //   }

  // }



  getlistStandardViewFilterByFormPageId(pageId: number) {
    if (this.PAGE_ID === 36) {
      this.displayedColumns = [];
      this.fixedSelectedColumnNames = [];
      this.standardViewService.getStandardViewFilterByFormPageId(pageId);
      this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);

      this.standardViewService.$getStandardViewFilter.subscribe(result => {
        if (result && result.result) {
          this.displayedColumns = [];
          this.selected_View_Columns = [];
          this.getStandardViewFilterData = result.result;

          let defaultView = this.getStandardViewFilterData?.find(view => view.isSetAsDefault === true)?.standardViewParameters;

          this.selectedValueControl = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true)?.id;

          if (Array.isArray(defaultView)) {
            defaultView.forEach(viewItem => {
              if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
                this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
              }
            });
          }

          this.displayedColumns = this.selected_View_Columns;
        }
      });


      this.standardViewService.$getStandardViewColumn.subscribe(result => {
        if (result) {
          this.getStandardViewColumnFilterData = result.result;
          let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);

          if (fixedCol && Array.isArray(fixedCol)) {
            fixedCol.forEach(x => {
              this.fixedSelectedColumnNames.push(x.displayColumnName);
            });
          }
        }
      });
    }
  }


  saveColumnSizes(event: any) {
    event.forEach(col => {
      const columnName = col.column.field;
      const widthSize = col.newWidth;
      const existingIndex = this.columnSizes.findIndex(item => item.columnName === columnName);

      if (existingIndex !== -1) {
        this.columnSizes[existingIndex].widthSize = widthSize;
      } else {
        this.columnSizes.push({ columnName, widthSize });
      }
    });
  }
  onPageSizeChangeListView(size: number): void {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
    this.pageSizeListView = size;
    let filterId: number = 0;
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
    }
    this.resetSubscription = this.listViewFService.$ListSelectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
      this.defaultFilterIdSelected = filterId;
      this.LeadListViewGridTableData(1, this.PAGE_ID, filterId, this.currentPageListView, this.pageSizeListView);
      this.isLoading = true;
    });
  }
  onPageChangeListView(page: number): void {
    this.currentPageListView = page;
    let filterId: number = 0;
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
    }
    this.resetSubscription = this.leadSetvices?.$ListSelectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.defaultFilterIdSelected = filterId;
    this.LeadListViewGridTableData(1, this.PAGE_ID, filterId, page, this.pageSizeListView);
    this.isLoading = true;
  }
  onPageChangeMap(page: number): void {
    this.currentPageMap = page;
    let filterId: number = 0;
    this.resetSubscription = this.leadmapService.$ListSelectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.defaultFilterIdSelected = filterId;
    this.leadMapListGrid(1, this.PAGE_ID, filterId, page, this.pageSizeMap);
    this.isLoading = true;
  }

  onPageSizeChangeMap(size: number): void {

    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
    this.pageSizeMap = size;
    let filterId: number = 0;

    this.resetSubscription = this.leadmapService.$ListSelectedFilterId.subscribe({
      next: (v) => {
        if (v !== null && v !== undefined) {
          filterId = v;
        } else {
          console.warn('Filter ID is null or undefined, using default value:', filterId);
        }
      },
      error: (error) => {
        console.error('Error while fetching filter ID:', error);
      }
    });

    this.defaultFilterIdSelected = filterId;
    this.leadMapListGrid(1, this.PAGE_ID, filterId, this.currentPageMap, this.pageSizeMap);
  }

  // <------------------List View------END------------------->

  // <------------------Activity View------START----------------->
  activityViewgetStandardFilterByFormPageId(pageId: number, type: string) {
    this.LookupFilterService.getStandardFilter.next(null);
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.salesListViewFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.lookupFilterResponse = result.result;
        let defaultFilterId = result?.result.find(filter => filter?.isSetAsDefault === true)?.id;
        this.leadActivitiesService.tabTwoSetSelectedFilterId(defaultFilterId);
        let id = -1;
        if (type === this.selectionFrom) {
          this.defaultFilterIdSelected = defaultFilterId;
          this.accessLocalStorageService.setSelectedFilterId(defaultFilterId);
          id = this.accessLocalStorageService.getSelectedFilterId();
        } else {
          id = this.accessLocalStorageService.getSelectedFilterId();
        }
        if (id !== -1) {
          this.activityViewGrid(1, pageId, id, this.currentPageActivityView, this.pageSizeActivityView);
        }
      }
    });
  }
  // activityViewgetStandardViewFilterByFormPageId(pageId: number) {
  //   if (this.salesActivityViewSubscription) {
  //     this.salesActivityViewSubscription.unsubscribe();
  //   }
  //   if (this.displayActivityColumnSubscription) {
  //     this.displayActivityColumnSubscription.unsubscribe();
  //   }
  //   if (this.salesActivityViewFilterSubscription) {
  //     this.salesActivityViewFilterSubscription.unsubscribe();
  //   }
  //   if (this.PAGE_ID === 4) {
  //     this.displayedColumns = [];
  //     this.fixedSelectedColumnNames = [];
  //     this.standardViewService.getStandardViewFilterByFormPageId(pageId);
  //     this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
  //     this.standardViewService.$getStandardViewFilter.subscribe(result => {
  //       if (result) {
  //         this.displayedColumns = [];
  //         this.selected_View_Columns = [];
  //         this.getStandardViewFilterData = result.result;
  //         let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).standardViewParameters;
  //         this.selectedValueControl = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).id;
  //         defaultView.forEach(viewItem => {
  //           if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
  //             this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
  //           }
  //         });
  //         this.displayedColumns = this.selected_View_Columns;
  //       }
  //     });

  //     this.standardViewService.$getStandardViewColumn.subscribe(result => {
  //       if (result) {
  //         this.getStandardViewColumnFilterData = result.result;
  //         let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);
  //         fixedCol.forEach(x => {
  //           this.fixedSelectedColumnNames.push(x.displayColumnName);
  //         });

  //       }
  //     });
  //   }
  // }



  activityViewgetStandardViewFilterByFormPageId(pageId: number) {
    if (this.salesActivityViewSubscription) {
      this.salesActivityViewSubscription.unsubscribe();
    }
    if (this.displayActivityColumnSubscription) {
      this.displayActivityColumnSubscription.unsubscribe();
    }
    if (this.salesActivityViewFilterSubscription) {
      this.salesActivityViewFilterSubscription.unsubscribe();
    }

    if (this.PAGE_ID === 4) {
      this.displayedColumns = [];
      this.fixedSelectedColumnNames = [];
      this.standardViewService.getStandardViewFilterByFormPageId(pageId);
      this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);

      this.standardViewService.$getStandardViewFilter.subscribe(result => {
        if (result && result.result) {
          this.displayedColumns = [];
          this.selected_View_Columns = [];
          this.getStandardViewFilterData = result.result;
          let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true)?.standardViewParameters;

          this.selectedValueControl = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true)?.id;

          if (Array.isArray(defaultView)) {
            defaultView.forEach(viewItem => {
              if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
                this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
              }
            });
          } else {
            console.warn('defaultView is not an array or is null');
          }

          this.displayedColumns = this.selected_View_Columns;
        }
      });

      this.standardViewService.$getStandardViewColumn.subscribe(result => {
        if (result && result.result) {
          this.getStandardViewColumnFilterData = result.result;
          let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);

          if (Array.isArray(fixedCol)) {
            fixedCol.forEach(x => {
              this.fixedSelectedColumnNames.push(x.displayColumnName);
            });
          } else {
            console.warn('fixedCol is not an array or is null');
          }
        }
      });
    }
  }


  activityViewSaveColumnSizes(event: any) {
    event.forEach(col => {
      const columnName = col.column.field;
      const widthSize = col.newWidth;
      const existingIndex = this.columnSizes.findIndex(item => item.columnName === columnName);

      if (existingIndex !== -1) {
        this.columnSizes[existingIndex].widthSize = widthSize;
      } else {
        this.columnSizes.push({ columnName, widthSize });
      }
    });

  }
  // onPageSizeChangeActivityViews(size: number): void {
  //   this.changeDetectorRef.detectChanges();
  //   this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
  //   this.pageSizeActivityView = size;
  //   let filterId: number = 0;
  //   this.salesListViewSubscription = this.leadActivitiesService.$tabTwoSetSelectedId.subscribe(v => {
  //     if (v) {
  //       filterId = v;
  //     }
  //   });
  //   this.isLoading = true;
  //   setTimeout(() => {
  //     this.defaultFilterIdSelected = filterId;
  //     this.activityViewGrid(1, this.PAGE_ID, filterId, this.currentPageActivityView, this.pageSizeActivityView);
  //     this.isLoading = false;
  //   }, 100);
  // }
  onPageSizeChangeActivityView(size: number): void {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
    this.pageSizeListView = size;
    let filterId: number = 0;
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
    }
    this.salesListViewSubscription = this.leadActivitiesService.$tabTwoSetSelectedId.subscribe(v => {
      if (v) {
        filterId = v;
      }
      this.defaultFilterIdSelected = filterId;
      this.activityViewGrid(1, this.PAGE_ID, filterId, this.currentPageActivityView, this.pageSizeActivityView);
      this.isLoading = true;
    });
  }
  // onPageChangeActivityViews(page: number): void {
  //   this.currentPageActivityView = page;
  //   let filterId: number = 0;
  //   this.leadActivitiesService.$tabTwoSetSelectedId.subscribe(v => {
  //     if (v) {
  //       filterId = v;
  //     }
  //   });
  //   this.isLoading = true;
  //   setTimeout(() => {
  //     this.defaultFilterIdSelected = filterId;
  //     this.activityViewGrid(1, this.PAGE_ID, filterId, page, this.pageSizeActivityView);
  //     this.isLoading = false;
  //   }, 100);
  // }
  onPageChangeActivityView(page: number): void {
    this.currentPageListView = page;
    let filterId: number = 0;

    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
    }
    this.salesListViewSubscription = this.leadActivitiesService.$tabTwoSetSelectedId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.defaultFilterIdSelected = filterId;
    this.activityViewGrid(1, this.PAGE_ID, filterId, page, this.pageSizeActivityView);
    this.isLoading = true;
  }



  // <------------------Activity View------END------------------->
  // <------------------Activity Templates------START----------------->
  activityTemplatesgetStandardFilterByFormPageId(pageId: number, type: string) {
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.salesListViewFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.lookupFilterResponse = result.result;
        let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
        this.leadActivityTemplatesService.LeadActivitysetSelectedFilterId(defaultFilterId);
        let id = -1;
        if (type === this.selectionFrom) {
          this.defaultFilterIdSelected = defaultFilterId;
          this.accessLocalStorageService.setSelectedFilterId(defaultFilterId);
          id = this.accessLocalStorageService.getSelectedFilterId();
        } else {
          id = this.accessLocalStorageService.getSelectedFilterId();
        }

        if (id !== -1) {
          this.activityTemplatesGrid(1, pageId, id, this.currentPageactivityTemplates, this.pageSizeActivityTemplates);
        }
      }
    });
  }
  activityTemplatesStandardViewFilterByFormPageId(pageId: number) {
    if (this.salesActivityViewSubscription) {
      this.salesActivityViewSubscription.unsubscribe();
    }
    if (this.displayActivityColumnSubscription) {
      this.displayActivityColumnSubscription.unsubscribe();
    }
    if (this.salesActivityViewFilterSubscription) {
      this.salesActivityViewFilterSubscription.unsubscribe();
    }
    if (this.PAGE_ID === 38) {
      this.displayedColumns = [];
      this.fixedSelectedColumnNames = [];
      this.standardViewService.getStandardViewFilterByFormPageId(pageId);
      this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
      this.standardViewService.$getStandardViewFilter.subscribe(result => {
        if (result) {
          this.displayedColumns = [];
          this.selected_View_Columns = [];
          this.getStandardViewFilterData = result.result;
          let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).standardViewParameters;
          this.selectedValueControl = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).id;
          defaultView.forEach(viewItem => {
            if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
              this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
            }
          });
          this.displayedColumns = this.selected_View_Columns;
        }
      });

      this.standardViewService.$getStandardViewColumn.subscribe(result => {
        if (result) {
          this.getStandardViewColumnFilterData = result.result;
          let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);
          fixedCol.forEach(x => {
            this.fixedSelectedColumnNames.push(x.displayColumnName);
          });

        }
      });
    }
  }
  activityTemplatesSaveColumnSizes(event: any) {
    event.forEach(col => {
      const columnName = col.column.field;
      const widthSize = col.newWidth;
      const existingIndex = this.columnSizes.findIndex(item => item.columnName === columnName);

      if (existingIndex !== -1) {
        this.columnSizes[existingIndex].widthSize = widthSize;
      } else {
        this.columnSizes.push({ columnName, widthSize });
      }
    });

  }
  onPageSizeChangeActivityTemplates(size: number): void {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
    this.pageSizeActivityTemplates = size;
    let filterId: number = 0;
    this.resetSubscription = this.leadActivityTemplatesService.$LeadSelectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.defaultFilterIdSelected = filterId;
    this.activityTemplatesGrid(1, this.PAGE_ID, filterId, this.currentPageactivityTemplates, this.pageSizeActivityTemplates);
    this.isLoading = true;
  }
  onPageChangeActivityTemplates(page: number): void {
    this.currentPageactivityTemplates = page;
    let filterId: number = 0;
    this.resetSubscription = this.leadActivityTemplatesService.$LeadSelectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.defaultFilterIdSelected = filterId;
    this.activityTemplatesGrid(1, this.PAGE_ID, filterId, page, this.pageSizeActivityTemplates);
    this.isLoading = true;
  }
  // <------------------Activity Templates------END------------------->

  // <------------------Activity Templates------START----------------->
  leadProposalsgetStandardFilterByFormPageId(pageId: number, type: string) {
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.salesListViewFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.lookupFilterResponse = result.result;
        let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
        this.leadProposalsFService.LeadProposalSetSelectedFilterId(defaultFilterId);
        let id = -1;
        if (type === this.selectionFrom) {
          this.defaultFilterIdSelected = defaultFilterId;
          this.accessLocalStorageService.setSelectedFilterId(defaultFilterId);
          id = this.accessLocalStorageService.getSelectedFilterId();
        } else {
          id = this.accessLocalStorageService.getSelectedFilterId();
        }

        if (id !== -1) {
          this.leadProposalsGrid(1, pageId, id, this.currentPageactivityTemplates, this.pageSizeLeadProposals);
        }
      }
    });
  }
  leadProposalsStandardViewFilterByFormPageId(pageId: number) {

    if (this.PAGE_ID === 37) {
      this.displayedColumns = [];
      this.fixedSelectedColumnNames = [];
      this.standardViewService.getStandardViewFilterByFormPageId(pageId);
      this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
      this.standardViewService.$getStandardViewFilter.subscribe(result => {
        if (result) {
          this.displayedColumns = [];
          this.selected_View_Columns = [];
          this.getStandardViewFilterData = result.result;
          let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).standardViewParameters;
          this.selectedValueControl = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).id;
          defaultView.forEach(viewItem => {
            if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
              this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
            }
          });
          this.displayedColumns = this.selected_View_Columns;
        }
      });

      this.standardViewService.$getStandardViewColumn.subscribe(result => {
        if (result) {
          this.getStandardViewColumnFilterData = result.result;
          let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);
          fixedCol.forEach(x => {
            this.fixedSelectedColumnNames.push(x.displayColumnName);
          });

        }
      });
    }
  }


  leadProposalsSaveColumnSizes(event: any) {
    event.forEach(col => {
      const columnName = col.column.field;
      const widthSize = col.newWidth;
      const existingIndex = this.columnSizes.findIndex(item => item.columnName === columnName);

      if (existingIndex !== -1) {
        this.columnSizes[existingIndex].widthSize = widthSize;
      } else {
        this.columnSizes.push({ columnName, widthSize });
      }
    });

  }
  onPageSizeChangeLeadProposals(size: number): void {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
    this.pageSizeLeadProposals = size;
    let filterId: number = 0;
    this.resetSubscription = this.leadProposalsFService.$ProposalSelectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.defaultFilterIdSelected = filterId;
    this.leadProposalsGrid(1, this.PAGE_ID, filterId, this.currentPageLeadProposals, this.pageSizeLeadProposals);
    this.isLoading = true;
  }
  onPageChangeLeadProposals(page: number): void {
    this.currentPageLeadProposals = page;
    let filterId: number = 0;
    this.resetSubscription = this.leadProposalsFService.$ProposalSelectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.defaultFilterIdSelected = filterId;
    this.leadProposalsGrid(1, this.PAGE_ID, filterId, page, this.pageSizeLeadProposals);
    this.isLoading = true;
  }
  // <------------------Activity Templates------END------------------->

  // <------------------Activity Templates------START----------------->
  proposalTemplatesgetStandardFilterByFormPageId(pageId: number, type: string) {
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.salesListViewFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.lookupFilterResponse = result.result;
        let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
        this.leadProposalsFService.LeadProposalSetSelectedFilterId(defaultFilterId);
        let id = -1;
        if (type === this.selectionFrom) {
          this.defaultFilterIdSelected = defaultFilterId;
          this.accessLocalStorageService.setSelectedFilterId(defaultFilterId);
          id = this.accessLocalStorageService.getSelectedFilterId();
        } else {
          id = this.accessLocalStorageService.getSelectedFilterId();
        }

        if (id !== -1) {
          this.proposalTemplatesGrid(1, pageId, id, this.currentPageProposalTemplates, this.pageSizeProposalTemplates);
        }
      }
    });
  }
  proposalTemplatesStandardViewFilterByFormPageId(pageId: number) {
    if (this.salesActivityViewSubscription) {
      this.salesActivityViewSubscription.unsubscribe();
    }
    if (this.displayActivityColumnSubscription) {
      this.displayActivityColumnSubscription.unsubscribe();
    }
    if (this.salesActivityViewFilterSubscription) {
      this.salesActivityViewFilterSubscription.unsubscribe();
    }
    if (this.PAGE_ID === 39) {
      this.displayedColumns = [];
      this.fixedSelectedColumnNames = [];
      this.standardViewService.getStandardViewFilterByFormPageId(pageId);
      this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
      this.standardViewService.$getStandardViewFilter.subscribe(result => {
        if (result) {
          this.displayedColumns = [];
          this.selected_View_Columns = [];
          this.getStandardViewFilterData = result.result;
          let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).standardViewParameters;
          this.selectedValueControl = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).id;
          defaultView.forEach(viewItem => {
            if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
              this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
            }
          });
          this.displayedColumns = this.selected_View_Columns;
        }
      });

      this.standardViewService.$getStandardViewColumn.subscribe(result => {
        if (result) {
          this.getStandardViewColumnFilterData = result.result;
          let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);
          fixedCol.forEach(x => {
            this.fixedSelectedColumnNames.push(x.displayColumnName);
          });

        }
      });
    }
  }
  proposalTemplatesSaveColumnSizes(event: any) {
    event.forEach(col => {
      const columnName = col.column.field;
      const widthSize = col.newWidth;
      const existingIndex = this.columnSizes.findIndex(item => item.columnName === columnName);

      if (existingIndex !== -1) {
        this.columnSizes[existingIndex].widthSize = widthSize;
      } else {
        this.columnSizes.push({ columnName, widthSize });
      }
    });

  }
  onPageSizeChangeProposalTemplates(size: number): void {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
    this.pageSizeProposalTemplates = size;
    let filterId: number = 0;
    this.resetSubscription = this.leadProposalsFService.$ProposalSelectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.defaultFilterIdSelected = filterId;
    this.proposalTemplatesGrid(1, this.PAGE_ID, filterId, this.currentPageProposalTemplates, this.pageSizeProposalTemplates);
    this.isLoading = true;
  }
  onPageChangeProposalTemplates(page: number): void {
    this.currentPageProposalTemplates = page;
    let filterId: number = 0;
    this.resetSubscription = this.leadProposalsFService.$ProposalSelectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.defaultFilterIdSelected = filterId;
    this.proposalTemplatesGrid(1, this.PAGE_ID, filterId, page, this.pageSizeProposalTemplates);
    this.isLoading = true;
  }
  // <------------------Activity Templates------END------------------->

  // <------------  Lead MAP START ------------> //
  leadMapStandardFilterByFormPageId(pageId: number, type: string) {
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.salesListViewFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.lookupFilterResponse = result.result;
        let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
        this.leadmapService.LeadMapSetSelectedFilterId(defaultFilterId);
        let id = -1;
        if (type === this.selectionFrom) {
          this.defaultFilterIdSelected = defaultFilterId;
          this.accessLocalStorageService.setSelectedFilterId(defaultFilterId);
          id = this.accessLocalStorageService.getSelectedFilterId();
        } else {
          id = this.accessLocalStorageService.getSelectedFilterId();
        }

        if (id !== -1) {
          this.leadMapListGrid(1, pageId, id, this.LeadMapPage, this.pageSizeMap);
        }
      }
    });
  }
  // <------------  Lead MAP END------------> //

  handleTabChange(tabIndex: number) {
    this.listViewFService.getListUserData.next(null);
    this.activeTab = tabIndex;
    switch (tabIndex) {
      case 0:
        this.unsubscribePreviousSubscriptions();
        console.clear();
        this.Selected_Filter_Type = this.LEAD_OPPR_LIST_VIEW;
        this.PAGE_ID = 36;
        this.listViewTabAction(this.PAGE_ID);
        break;
      case 1:
        // this.unsubscribePreviousSubscriptions();
        // console.clear();
        this.Selected_Filter_Type = this.LEAD_OPPR_ACT_VIEW;
        this.PAGE_ID = 4;
        this.activityViewTabAction(this.PAGE_ID);
        break;
      case 2:
        this.unsubscribePreviousSubscriptions();
        console.clear();
        this.PAGE_ID = 20002;
        this.activityCalendarTabAction(this.PAGE_ID);
        break;
      case 3:
        this.unsubscribePreviousSubscriptions();
        console.clear();
        this.Selected_Filter_Type = this.LEAD_OPPR_ACT_TEMPL;
        this.PAGE_ID = 38;
        this.activityTemplatesTabAction(this.PAGE_ID);
        break;
      case 4:
        this.unsubscribePreviousSubscriptions();
        // console.clear();
        this.Selected_Filter_Type = this.LEAD_OPPR_LEAD_PROP;
        this.PAGE_ID = 37;
        this.leadProposalsTabAction(this.PAGE_ID);
        break;
      case 5:
        this.unsubscribePreviousSubscriptions();
        console.clear();
        this.Selected_Filter_Type = this.LEAD_OPPR_PROP_TEMP;
        this.PAGE_ID = 39;
        this.proposalTemplatesTabAction(this.PAGE_ID);
        break;
      case 6:
        this.unsubscribePreviousSubscriptions();
        console.clear();
        this.Selected_Filter_Type = this.LEAD_OPPR_MAP;
        this.PAGE_ID = 40022;
        this.leadMapTabAction(this.PAGE_ID);
        break;
      default:
        console.clear();
        break;
    }
    this.clearSelections();
    this.listViewFService.getListUserData.next(null);
    this.cdr.detectChanges();
  }

  unsubscribePreviousSubscriptions() {
    if (this.salesListViewSubscription) {
      this.salesListViewSubscription.unsubscribe();
    }
    if (this.salesListViewFilterSubscription) {
      this.salesListViewFilterSubscription.unsubscribe();
    }
  }



  closeDialog(): void {
    this.showleadopportunitiesDialog = false;
  }
  toggleDetails() {
    this.showDetails = !this.showDetails;
  }
  toggleCustomFields() {
    this.showCustomFields = !this.showCustomFields;
  }
  openModal(): void {
    this.isVisible = true;
  }
  showModal(): void {
    this.isVisible = true;
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  opencImportModal(): void {
    this.isImportVisible = true;
  }
  showImportModal(): void {
    this.isImportVisible = true;
  }
  handleImportCancel(): void {
    this.isImportVisible = false;
  }
  opencActivityTemModal(): void {
    this.isActivityTemVisible = true;
  }
  showActivityTemModal(): void {
    this.isActivityTemVisible = true;
  }
  handleActivityTemCancel(): void {
    this.isActivityTemVisible = false;
  }
  openchooseExistingModal(): void {
    this.isChooseVisible = true;
  }
  cancelchooseExistingModal(): void {
    this.isChooseVisible = false;
  }
  handleChooseCancel(): void {
    this.isChooseVisible = true;
  }
  showcontacModal(): void {
    this.isContactVisible = true;
  }
  handleContactCancel(): void {
    this.isContactVisible = false;
  }
  handleSaveAndClose(): void {
    this.isVisible = false;
  }
  showConfirm(): void {
    this.modal.confirm({
      nzTitle: 'Do you want to schedule a new activity?',
      nzContent: 'This action will schedule a new activity.',
      nzOnOk: () => {
      },
      nzWrapClassName: 'custom-modal-style',
    });
  }
  getTab(tab: number): string {
    switch (tab) {
      case 9:
        return 'Gernal';
      case 10:
        return 'Activities';
      case 11:
        return 'Proposals';
      default:
        return '';
    }
  }
  openFilterDrawer(): void {
    this.filterDrawerVisible = true;
  }
  closeFilterDrawer(): void {
    this.filterDrawerVisible = false;
  }
  increase(): void {
    this.percent = this.percent + 10;
    if (this.percent > 100) {
      this.percent = 100;
    }
  }
  decline(): void {
    this.percent = this.percent - 10;
    if (this.percent < 0) {
      this.percent = 0;
    }
  }
  openAddsModal(): void {
    this.isVisibleAddModal = true;
  }
  handleOkAddModal(): void {
    this.isVisibleAddModal = false;
  }
  handleCancelAddModal(): void {
    this.isVisibleAddModal = false;
  }
  toggleDropdown5() {
    this.isOpen5 = !this.isOpen5;
  }
  toggleShowdoubleOvertime() {
    this.showOvertimeContent = !this.showOvertimeContent;
  }
  pre(): void {
    this.current -= 1;
    this.changeContent();
  }
  next(): void {
    this.current += 1;
    this.changeContent();
  }
  changeContent(): void {
    switch (this.current) {
      case 0: {
        this.index = 'First-content';
        break;
      }
      case 1: {
        this.index = 'Second-content';
        break;
      }
      case 2: {
        this.index = 'third-content';
        break;
      }
      default: {
        this.index = 'error';
      }
    }
  }
  handleChange(info: NzUploadChangeParam): void {
    if (info.file.status !== 'uploading') {
    }
    if (info.file.status === 'done') {
      this.toastService.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.toastService.error(`${info.file.name} file upload failed.`);
    }
  }
  updateSliderValue(value: number): void {
    this.sliderValue = value;
  }
  handleCancel9(): void {
    this.isVisible9 = false;
  }
  showMoreFeild() {
    this.isFieldsVisible = !this.isFieldsVisible;
  }
  leadProposal() {
    this.isVisible10 = true;
  } s
  handleCancel10(): void {
    this.isVisible10 = false;
  }
  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every((item) =>
      this.setOfCheckedId.has(item.id)
    );
    this.indeterminate =
      this.listOfCurrentPageData.some((item) =>
        this.setOfCheckedId.has(item.id)
      ) && !this.checked;
  }
  leadproposalTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'WorkSheet';
      case 2:
        return 'Format';
      case 3:
        return 'Preview';
      default:
        return '';
    }
  }
  onCurrentPageDataChange($event: readonly ItemData[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }
  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach((item) =>
      this.updateCheckedSet(item.id, value)
    );
    this.refreshCheckedStatus();
  }
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }
  open(): void {
    this.visible = true;
  }
  close(): void {
    this.visible = false;
  }
  leadActivity1() {
    this.isVisible8 = true;
  }
  openLeadProposaltModal(): void {
    this.isVisibleLead = true;
  }
  cancelLeadProposaltModal(): void {
    this.isVisibleLead = false;
  }
  handleLeadSelection(value: string): void {
    this.isVisibleLead = false; // Close the first modal
    if (value === 'jack' || value === 'lucy' || value === 'tom') {
      this.isVisible10 = true;
    } else {
    }
  }
  handleCancel19(): void {
    this.isVisible8 = false;
  }
  selectChange(select: Date): void {
  }
  showMuseumLocation() {
    const museumCoords = { lat: 40.779437, lng: -73.963244 };
    if (this.googleMapFrame) {
      const mapSrc = `https://maps.google.com/maps?q=${museumCoords.lat},${museumCoords.lng}&z=15&output=embed`;
      this.googleMapFrame.nativeElement.src = mapSrc;
    }
  }
  // <----------modals open Methods---------->
  leadOpportunitySettingModalOpen(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesSettingComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  leadOpportunityLearnMoreModalOpen(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesLearnMoreComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  leadOpportunityExportModalOpen(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesExportComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  leadOpportunityNewLeadModalOpen(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesNewLeadOpportunitiesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.leadOpportunityOnCompleted.subscribe(() => {
      this.LeadListViewGridTableData(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageListView, this.pageSizeListView);
    });
  }
  leadOpportunityLogCompleted(markComplete___True: boolean, isProposal: boolean, type: string): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitySelectDialogBoxComponent,
      nzData: {
        type: type,
        isMark: markComplete___True,
      },
      nzFooter: null
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  leadOpportunityAddContactModalOpen(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesAddContactComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  LeadSettings(): void {
    const modalRef = this.modal.create({
      nzContent: SalesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }


  toggleColumnSticky(index: number) {
    this.isColumnSticky[index] = !this.isColumnSticky[index];
    if (this.isColumnSticky[index]) {
      for (let i = 0; i < index; i++) {
        this.isColumnSticky[i] = true;
      }
    }
  }
  isExpanded(rowIndex: number): boolean {
    return this.expandedRows.includes(rowIndex);
  }
  toggleAdditionalEmails(rowIndex: number): void {
    const index = this.expandedRows.indexOf(rowIndex);
    if (index > -1) {
      this.expandedRows.splice(index, 1);
    } else {
      this.expandedRows.push(rowIndex);
    }
  }
  // <----------Edit Modal Open Start---------->
  leadOpportunityFormEdit(Id: number): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesNewLeadOpportunitiesComponent,
      nzFooter: null
    });
    modalRef.componentInstance.leadOpportunityResponseId = Id;
    modalRef.componentInstance.JobMenuButton = true;

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      this.selectedLead = null;
    });
    modalRef.componentInstance.leadOpportunityOnCompleted.subscribe(() => {
      this.LeadListViewGridTableData(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageListView, this.pageSizeListView);
      // this.leadProposalsGrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageListView, this.pageSizeListView);
      this.activityViewGrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageListView, this.pageSizeListView);
    });
  };
  //.............. Edit Modal Open End  Mehmood 11/03/2024   ............//
  // onFilterChange(id: number) {
  //   this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);

  //   this.selectedFilter = -1;
  //   this.displayedColumns = [];
  //   this.selected_View_Columns = [];
  //   let selectedView = this.getStandardViewFilterData.find(view => view.id === id).standardViewParameters;
  //   selectedView?.forEach(viewItem => {
  //     if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
  //       this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
  //       this.displayedColumns = this.selected_View_Columns;

  //       const columnIndex = this.displayedColumns.indexOf(viewItem.standardColumnSetup.displayColumnName);
  //       if (columnIndex !== -1) {
  //         this.isColumnSticky[columnIndex] = viewItem.isPin;
  //         this.getColumnWidthSize[columnIndex] = viewItem.resizeColumnWidth;
  //       }
  //     }
  //   });
  // }
  onFilterChange(id: number) {
    this.isColumnSticky= new Array(this.displayedColumns.length).fill(false);

    this.selectedFilter = -1;
    this.displayedColumns = [];
    this.selected_View_Columns = [];
     let selectedView = this.getStandardViewFilterData.find(view => view.id === id).standardViewParameters;
     selectedView.forEach(viewItem => {
       if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
         this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
         this.displayedColumns = this.selected_View_Columns;

          const columnIndex = this.displayedColumns.indexOf(viewItem.standardColumnSetup.displayColumnName);
          if (columnIndex !== -1) {
            this.isColumnSticky[columnIndex] = viewItem.isPin;
            this.getColumnWidthSize[columnIndex] = viewItem.resizeColumnWidth;
          }
       }
     });
   }

  // openStandardViewFilterComponent(isSaveAs?: string) { 
  //   let isInputVisible: boolean = false;
  //   if (isSaveAs === 'saveAs') {
  //     isInputVisible = true;
  //   } else {
  //     isInputVisible = false;
  //   }
  //   this.selectedColNameSizePin = [];
  //   for (let i = 0; i < this.displayedColumns.length; i++) {
  //     const item = this.displayedColumns[i];
  //     const isPinValue = this.isColumnSticky[i];
  //     let widthSize = 0;
  //     if (this.columnSizes.length > 0) {
  //       for (let i = 0; i < this.columnSizes.length; i++) {
  //         const columnName = this.columnSizes[i].columnName;
  //         const columnNameSize = this.columnSizes[i].widthSize;
  //         if (columnName === item) {
  //           widthSize = columnNameSize;
  //           break;
  //         }
  //       }
  //     }
  //     if (widthSize === 0) {
  //       widthSize = 180;
  //     }
  //     this.selectedColNameSizePin.push({
  //       displayColumnName: item,
  //       resizeColumnWidth: widthSize,
  //       isPin: isPinValue == true ? isPinValue : false
  //     });
  //   }
  //   const modalRef = this.modal.create({
  //     nzContent: AddNewGridStandardViewComponent,
  //     nzData:
  //     {
  //       pageId: this.PAGE_ID,
  //       column: this.getStandardViewColumnFilterData,
  //       displayCol: this.displayedColumns,
  //       viewList: this.getStandardViewFilterData,
  //       selectedColNameSizePin: this.selectedColNameSizePin,
  //       selectedStandardView: this.selectedValueControl,
  //       fixedCol: this.fixedSelectedColumnNames,
  //       isSaveAs: isInputVisible
  //     },

  //   });
  //   modalRef.componentInstance.cancel.subscribe(() => {
  //     modalRef.destroy();
  //   });
  // }

  openStandardViewFilterComponent(isSaveAs?: string) {
    console.log(isSaveAs);
    let isInputVisible: boolean = false;
    if (isSaveAs === 'saveAs') {
      isInputVisible = true;
    } else {
      isInputVisible = false;
    }
    this.selectedColNameSizePin = [];
    for (let i = 0; i < this.displayedColumns.length; i++) {
      const item = this.displayedColumns[i];
      const isPinValue = this.isColumnSticky[i];
      let widthSize = 0;
      if (this.columnSizes.length > 0) {
        for (let i = 0; i < this.columnSizes.length; i++) {
          const columnName = this.columnSizes[i].columnName;
          const columnNameSize = this.columnSizes[i].widthSize;
          if (columnName === item) {
            widthSize = columnNameSize;
            break;
          }
        }
      }

      if (widthSize === 0) {
        widthSize = 180;
      }

      this.selectedColNameSizePin.push({
        displayColumnName: item,
        resizeColumnWidth: widthSize,
        isPin: isPinValue == true ? isPinValue : false
      });
    }
    const modalRef = this.modal.create({
      nzContent: AddNewGridStandardViewComponent,
      nzData:
      {
        pageId: this.PAGE_ID,
        column: this.getStandardViewColumnFilterData,
        displayCol: this.displayedColumns,
        viewList: this.getStandardViewFilterData,
        selectedColNameSizePin: this.selectedColNameSizePin,
        selectedStandardView: this.selectedValueControl,
        fixedCol: this.fixedSelectedColumnNames,
        isSaveAs: isInputVisible,
      },
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }


  // openStandardViewFilterComponent(isSaveAs?: string) {
  //   let isInputVisible: boolean = false;
  //   if (isSaveAs === 'saveAs') {
  //     isInputVisible = true;
  //   } else {
  //     isInputVisible = false;
  //   }
  //   this.selectedColNameSizePin = [];
  //   for (let i = 0; i < this.displayedColumns.length; i++) {
  //     const item = this.displayedColumns[i];
  //     const isPinValue = this.isColumnSticky[i];
  //     let widthSize = 0;
  //     if (this.columnSizes.length > 0) {
  //       for (let i = 0; i < this.columnSizes.length; i++) {
  //         const columnName = this.columnSizes[i].columnName;
  //         const columnNameSize = this.columnSizes[i].widthSize;
  //         if (columnName === item) {
  //           widthSize = columnNameSize;
  //           break;
  //         }
  //       }
  //     }

  //     if (widthSize === 0) {
  //       widthSize = 180;
  //     }

  //     this.selectedColNameSizePin.push({
  //       displayColumnName: item,
  //       resizeColumnWidth: widthSize, //  "200px", // Set default width here
  //       isPin: isPinValue == true ? isPinValue : false
  //     });
  //   }
  //   const modalRef = this.modal.create({
  //     nzContent: AddNewGridStandardViewComponent,
  //     nzData:
  //     {
  //       pageId: this.PAGE_ID,
  //       column: this.getStandardViewColumnFilterData,
  //       displayCol: this.displayedColumns,
  //       viewList: this.getStandardViewFilterData,
  //       selectedColNameSizePin: this.selectedColNameSizePin,
  //       selectedStandardView: this.selectedValueControl,
  //       fixedCol: this.fixedSelectedColumnNames,
  //       isSaveAs: isInputVisible
  //     },

  //   });
  //   modalRef.componentInstance.cancel.subscribe(() => {
  //     modalRef.destroy();
  //   });
  // }


  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
  }
  // saveEditStandardView() {
  //   this.standardViewService.$createStandardViewRequest.subscribe(formData => {
  //     if (formData && formData !== null) {
  //       this.standardViewService.postData(formData)
  //         .subscribe(
  //           (res: ResponseModelArray<StandardViewResponse>) => {
  //             setTimeout(() => {
  //               this.isLoading = true;
  //               this.toastService.success('Standard View Updated Successfully');
  //               this.standardViewService.getStandardViewFilterByFormPageId(this.PAGE_ID);
  //               this.selectedFilter = -1;
  //             }, 10);
  //           },
  //           (error) => {
  //             console.error('Error:', error);
  //             this.isLoading = false;
  //           }
  //         );
  //     }
  //   });
  // }
  saveEditStandardView() {
    this.standardViewService.$createStandardViewRequest.subscribe(formData => {
      if (formData && formData !== null) {
        this.standardViewService.postData(formData)
          .subscribe(
            (res: ResponseModelArray<StandardViewResponse>) => {
              setTimeout(() => {
                // this.isLoading = true;
                this.toastService.success('Standard View Updated Successfully');
                this.standardViewService.getStandardViewFilterByFormPageId(this.PAGE_ID);
                this.selectedFilter = -1;
              }, 10);
            },
            (error) => {
              console.error('Error:', error);
              this.isLoading = false;
            }
          );
      }
    });
  }
  leadActivityDialogBoxOpen(select: string): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitySelectDialogBoxComponent,
      nzData: {
        type: select,
        // leads: this.leadOptions,
      },
      nzWidth: '30%',
      nzTitle: null,
      nzFooter: null,
      nzCentered: true
    });

    modalRef.componentInstance.leadActivityOnSavedCompleted.subscribe(() => {
      this.activityViewGrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageActivityView, this.pageSizeActivityView);
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });



    this.leadAceeetivitiesService.leadActivityTypeResponse$.subscribe(
      (response) => {
        if (response) {
          this.loadActivities(response);
          this.activityViewGrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageActivityView, this.pageSizeActivityView);
        } else {
          console.error("No activity response ID found");
        }
      },
      (error) => {
        console.error("Error fetching lead activity type response:", error);
      }
    );
  }

  NewProposalTemplate(): void {
    const modalRef = this.modal.create({
      nzContent: NewLeadProposalTemplateComponent,
      nzFooter: null
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.leadProposalSaveResponse.subscribe(() => {
      this.proposalTemplatesGrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageLeadProposals, this.pageSizeLeadProposals);
    });

    this.leadAceeetivitiesService.leadActivityTypeResponse$.subscribe(
      (response) => {
        if (response.result?.id) {
          this.activityTemplatesGrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageLeadProposals, this.pageSizeLeadProposals);
        } else {
          console.error("No activity response ID found");
        }
      },
      (error) => {
        console.error("Error fetching lead activity type response:", error);
      }
    );
  }
  // getLeadData() {
  //   this.leadActivitiesservice.getAllLeadData().subscribe(Data => {
  //     this.leadOptions = Data.result;
  //   });
  // }
  isFirstTabActive(): boolean {
    return this.activeTab === 0;
  }
  isSecondTabActive(): boolean {
    return this.activeTab === 1;
  }
  isTwoTabActive(): boolean {
    return this.activeTab === 2;
  }
  isThreeTabActive(): boolean {
    return this.activeTab === 3;
  }
  isFourthTabActive(): boolean {
    return this.activeTab === 4;
  }
  isFifthTabActive(): boolean {
    return this.activeTab === 5;
  }

  NewLeadTemplate(): void {
    // Show loading indicator
    this.loadingIndicatoreService.show();
  
    // Simulate a delay before hiding the loading indicator
    setTimeout(() => {
      this.loadingIndicatoreService.hide();
      this.isLoadingEdit = false;
  
      // After loading is complete, open the modal
      const modalRef = this.modal.create({
        nzContent: LeadOpportunitiesActivityTemplatesHeaderComponent,
        nzData: {}, // You can pass data to the modal here if needed
        nzFooter: null
      });
  
      // Handle modal close/cancel event
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
  
      // Handle the modal's completion event
      modalRef.componentInstance.leadActivityTemplateOnCompleted.subscribe(() => {
        this.activityTemplatesGrid(
          1, 
          this.PAGE_ID, 
          this.defaultFilterIdSelected, 
          this.currentPageListView, 
          this.pageSizeListView
        );
      });
    }, 500);
  
    // Handle response from leadActivityTypeResponse$
    this.leadAceeetivitiesService.leadActivityTypeResponse$.subscribe(
      (response) => {
        if (response.result?.id) {
          this.activityTemplatesGrid(
            1, 
            this.PAGE_ID, 
            this.defaultFilterIdSelected, 
            this.currentPageLeadProposals, 
            this.pageSizeLeadProposals
          );
        } else {
          console.error("No activity response ID found");
        }
      },
      (error) => {
        console.error("Error fetching lead activity type response:", error);
      }
    );
  }
  
  isFirstImport(): boolean {
    return this.activeTab === 0;
  }
  isSecondImport(): boolean {
    return this.activeTab === 1;
  }
  isTwoImport(): boolean {
    return this.activeTab === 2;
  }
  isThreeImport(): boolean {
    return this.activeTab === 3;
  }
  isFourthImport(): boolean {
    return this.activeTab === 4;
  }
  isFifthImport(): boolean {
    return this.activeTab === 5;
  }
  isSixthImport(): boolean {
    return this.activeTab === 6;
  }
  isFirstLogCompletedActivity(): boolean {
    return this.activeTab === 0;
  }
  isSecondLogCompletedActivity(): boolean {
    return this.activeTab === 1;
  }
  isThreeLogCompletedActivity(): boolean {
    return this.activeTab === 3;
  }
  isFourthLogCompletedActivity(): boolean {
    return this.activeTab === 4;
  }
  isFifthLogCompletedActivity(): boolean {
    return this.activeTab === 5;
  }
  isSixthLogCompletedActivity(): boolean {
    return this.activeTab === 6;
  }
  isFirstAddContactToFromWebsite(): boolean {
    return this.activeTab === 0;
  }
  isSecondAddContactToFromWebsite(): boolean {
    return this.activeTab === 1;
  }
  isTwoAddContactToFromWebsite(): boolean {
    return this.activeTab === 2;
  }
  isThreeAddContactToFromWebsite(): boolean {
    return this.activeTab === 3;
  }
  isFourthAddContactToFromWebsite(): boolean {
    return this.activeTab === 4;
  }
  isFifthAddContactToFromWebsite(): boolean {
    return this.activeTab === 5;
  }
  isSixthAddContactToFromWebsite(): boolean {
    return this.activeTab === 6;
  }
  isTwoAddFilter(): boolean {
    return this.activeTab === 2;
  }
  isThreeAddFilter(): boolean {
    return this.activeTab === 3;
  }
  isTwoAddExport(): boolean {
    return this.activeTab === 2;
  }
  isSixthAddExport(): boolean {
    return this.activeTab === 6;
  }
  isTwoAddLearnMore(): boolean {
    return this.activeTab === 2;
  }
  isThreeLearnMore(): boolean {
    return this.activeTab === 3;
  }
  isFourthLearnMore(): boolean {
    return this.activeTab === 4;
  }
  isFifthLearnMore(): boolean {
    return this.activeTab === 5;
  }
  isSixthLearnMore(): boolean {
    return this.activeTab === 6;
  }
  isTwoAddLeadSetting(): boolean {
    return this.activeTab === 2;
  }
  isSixthAddLeadSetting(): boolean {
    return this.activeTab === 6;
  }
  isSecondAddLeadSetting(): boolean {
    return this.activeTab === 1;
  }
  isFourthAddLeadSetting(): boolean {
    return this.activeTab === 4;
  }
  isFifthAddLeadSetting(): boolean {
    return this.activeTab === 5;
  }
  isThreeAddLeadSetting(): boolean {
    return this.activeTab === 3;
  }
  isFirstAddLeadSetting(): boolean {
    return this.activeTab === 0;
  }
  isFirstMapAll(): boolean {
    return this.activeTab === 0;
  }
  isSecondMapAll(): boolean {
    return this.activeTab === 1;
  }
  isTwoMapAll(): Boolean {
    return this.activeTab === 2;
  }
  isThreeMapAll(): boolean {
    return this.activeTab === 3;
  }
  isFourthMapAll(): boolean {
    return this.activeTab === 4;
  }
  isFifthMapAll(): boolean {
    return this.activeTab === 5;
  }
  isFirstSwicth(): boolean {
    return this.activeTab === 0;
  }
  isSecondSwicth(): boolean {
    return this.activeTab === 1;
  }
  isTwoSwicth(): Boolean {
    return this.activeTab === 2;
  }
  isThreeSwicth(): boolean {
    return this.activeTab === 3;
  }
  isFourthSwicth(): boolean {
    return this.activeTab === 4;
  }
  isFifthSwicth(): boolean {
    return this.activeTab === 5;
  }
  isFirstAddLeadActivitySettings2(): boolean {
    return this.activeTab === 0;
  }
  isTwoAddLeadActivitSettings2(): boolean {
    return this.activeTab === 2;
  }
  isSixthAddLeadActivitSettings2(): boolean {
    return this.activeTab === 6;
  }
  isSecondAddLeadActivitSettings2(): boolean {
    return this.activeTab === 1;
  }
  isFourthAddLeadActivitSettings2(): boolean {
    return this.activeTab === 4;
  }
  isFifthAddLeadActivitSettings2(): boolean {
    return this.activeTab === 5;
  }
  isThreeAddLeadActivitSettings2(): boolean {
    return this.activeTab === 3;
  }
  isTwodivider(): boolean {
    return this.activeTab === 2;
  }
  // saim End work || 4/27/2024 || Saim_Assign_Work
  OpenImportModel(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesImportLeadOpportunitiesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  // openLeadActivity(data: LeadActivityResponse): void {
  //   const modalRef = this.modal.create({
  //     nzContent: LeadActivityFormComponent,
  //     nzFooter: null,
  //   });
  //   modalRef.componentInstance.leadActivityResponce = data;
  //   modalRef.componentInstance.cancel.subscribe(() => {
  //     modalRef.destroy();
  //   });
  // }
  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }
  // ngOnDestroy() {
  //   if (this.pageIdSubscription) {
  //     this.pageIdSubscription.unsubscribe();
  //   }
  //   this.LookupFilterService.getStandardFilter.next(null); // New Changes By Aamir Ali - 31-May-2024
  //   localStorage.removeItem('Selected_Filter_Id'); // New Changes By Aamir Ali - 31-May-2024
  //   this.salesListViewSubscription.unsubscribe();
  //   this.salesListViewFilterSubscription.unsubscribe();
  //   if (this.salesListViewSubscription) {
  //     this.salesListViewSubscription.unsubscribe(); // Ensure to unsubscribe when component is destroyed
  //   }
  // }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.cleanupPageResources();
    localStorage.removeItem('Selected_Filter_Id');
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
    }
    this.selectedFilter = -1;
  }



  //Modified by Mehmood Id also Add
  listViewGridPlusIconLeadOpportunitiesNewLeadOpportunities(data: LeadOpportunityResponse) {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesNewLeadOpportunitiesComponent,
      nzFooter: null,
    });
    //Modified by Mehmood Id also Add
    modalRef.componentInstance.leadOpportunityResponseId = data.id;
    modalRef.componentInstance.leadOpportunityResponse = data;
    modalRef.componentInstance.JobMenuButton = true;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    // if (data.leadActivities && data.leadActivities.length > 0) {
    //   setTimeout(() => {
    //     this.listViewGridPlusIconLeadActivityForm(data);
    //   }, 1000);
    // }
    modalRef.componentInstance.leadOpportunityOnCompleted.subscribe(() => {
      this.LeadListViewGridTableData(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageListView, this.pageSizeListView);
    });
  }

  listViewGridPlusIconLeadActivityForm(leadSelectedData: LeadOpportunityResponse) {
    const modalRef = this.modal.create({
      nzCentered: true,
      nzContent: LeadActivityFormComponent,
      nzData: {
        leadData: leadSelectedData,
        Data: this.holdResponse,
      },
      nzFooter: null,
    });
    modalRef.componentInstance.dataSet = leadSelectedData;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  // Modified By Mehmood
  EditCustomer(Id: number): void {
    const modalRef = this.modal.create({
      nzContent: NewContactComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.CustomerResponseId = Id;
    // modalRef.componentInstance.CustomerResponse = data;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.onSaveComplete.subscribe(() => {
      this.LeadListViewGridTableData(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageListView, this.pageSizeListView);
      this.leadProposalsGrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageListView, this.pageSizeListView);

    });

  }
  OnClickActivity(Id: number): void {
    const modalRef = this.modal.create({
      nzContent: LeadActivityFormComponent,
      // nzData: {
      //   leadData: activityData,
      // },
      nzFooter: null,
    });
    modalRef.componentInstance.activityResponseId = Id;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.leadActivityOnSavedCompleted.subscribe(() => {
      this.activityViewGrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageActivityView, this.pageSizeActivityView);
    });

    this.leadAceeetivitiesService.leadActivityTypeResponse$.subscribe(
      (response) => {
        if (response?.result?.id) {
          this.activityViewGrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageActivityView, this.pageSizeActivityView);
        } else {
          console.error("No activity response ID found");
        }
      },
      (error) => {
        console.error("Error fetching lead activity type response:", error);
      }
    );
  }
  triggered() {
    this.leadAceeetivitiesService.setNewArgument(true);
  }
  // OnClickActivity(activityData: LeadOpportunityResponse) {
  //   const modalRef = this.modal.create({
  //     nzContent: LeadActivityFormComponent,
  //     nzData: {
  //       activityData: activityData,
  //       leadData: this.leadOpportunityResponse
  //     },
  //     nzFooter: null,
  //   });
  //   modalRef.componentInstance.cancel.subscribe(() => {
  //     modalRef.destroy();
  //   });
  // }


  leadActivityTemplatesFormEdit(Id: number) {
    console.log("show all activity Noor",Id);

    this.loadingIndicatoreService.show();
      setTimeout(() => {
        this.loadingIndicatoreService.show();
        this.isLoadingEdit = false;
        const modalRef = this.modal.create({
          nzContent: LeadOpportunitiesActivityTemplatesHeaderComponent,
          nzData: {
          },
          nzFooter: null,
        });
        modalRef.componentInstance.LeadOpportunitiesActivityTemplatesResponseId = Id;
        modalRef.componentInstance.cancel.subscribe(() => {
          modalRef.destroy();
        });
        this.leadAceeetivitiesService.leadActivityTypeResponse$.subscribe(
          (response) => {
            if (response) {
              this.activityTemplatesGrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageactivityTemplates, this.pageSizeActivityTemplates);
            } else {
              console.error("No activity response ID found");
            }
          },
          (error) => {
            console.error("Error fetching lead activity type response:", error);
          }
        );
        modalRef.componentInstance.leadActivityTemplateOnCompleted.subscribe((data: any) => {
          this.activityTemplatesGrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageactivityTemplates, this.pageSizeActivityTemplates);
        });
      }, 500);
      this.loadingIndicatoreService.hide();
  }

  // leadActivityTemplatesFormEdit(Id: number) {
  //   console.log("show all activity Noor", Id);
  
  //   this.loadingIndicatoreService.show(); // Show the loading indicator at the start
  
  //   // Subscribe to the response
  //   this.leadAceeetivitiesService.leadActivityTypeResponse$.subscribe(
  //     (response) => {
  //       if (response) {
  //         // Call the grid method after getting the response
  //         this.activityTemplatesGrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageactivityTemplates, this.pageSizeActivityTemplates);
  
  //         // Now that response is received, open the modal
  //         const modalRef = this.modal.create({
  //           nzContent: LeadOpportunitiesActivityTemplatesHeaderComponent,
  //           nzData: {
  //             // You can pass response data here if needed
  //           },
  //           nzFooter: null,
  //         });
  
  //         // Pass the ID to the modal instance
  //         modalRef.componentInstance.LeadOpportunitiesActivityTemplatesResponseId = Id;
  
  //         // Handle modal cancellation
  //         modalRef.componentInstance.cancel.subscribe(() => {
  //           modalRef.destroy();
  //         });
  
  //         // Handle completion event
  //         modalRef.componentInstance.leadActivityTemplateOnCompleted.subscribe((data: any) => {
  //           this.activityTemplatesGrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageactivityTemplates, this.pageSizeActivityTemplates);
  //         });
  
  //         // Hide loading indicator after modal is created
  //         this.isLoadingEdit = false;
  //         this.loadingIndicatoreService.hide();
  //       } else {
  //         console.error("No activity response ID found");
  //         this.loadingIndicatoreService.hide(); // Hide loader if no response
  //       }
  //     },
  //     (error) => {
  //       console.error("Error fetching lead activity type response:", error);
  //       this.loadingIndicatoreService.hide(); // Hide loader in case of error
  //     }
  //   );
  // }

  leadProposalFormEdit(Id: number,leadOppId: number): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesNewLeadOpportunitiesComponent,
      nzFooter: null
    });
    modalRef.componentInstance.leadOpportunityResponseId = leadOppId;
    modalRef.componentInstance.leadProposalId = Id;
    modalRef.componentInstance.flagDialogBoxForProposalTabActive = 11;
    modalRef.componentInstance.gridPatchedValue = true;


    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  };


  // modalRef.componentInstance.leadProposalSaveResponse.subscribe((data: any) => {
  //   this.leadProposalsGrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageactivityTemplates, this.pageSizeActivityTemplates);
  // });

  leadProposalTemplatesFormEdit(Id: number): void {
    const modalRef = this.modal.create({
      nzContent: NewLeadProposalTemplateComponent,
      nzData: {
        leadData: this.getLeadProposalisTemplateData
      },
      nzFooter: null
    });
    modalRef.componentInstance.getProposalTemplatesDataId = Id;

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      this.selectedLead = null;
    });
    modalRef.componentInstance.leadProposalSaveResponse.subscribe(() => {
      this.proposalTemplatesGrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageLeadProposals, this.pageSizeLeadProposals);
    });
  }
  public listView: string[] = [];
  selectedKeys: number[] = [];
  public activityView: string[] = [];
  public activityTemplates: string[] = [];
  public proposalTemplates: string[] = [];
  globalIds: string[] = [];
  ids: number[] = [];
  public mySelection: number[] = [];
  private selectedIdsSet: Set<number> = new Set();
  public selectedCount: number = 0; // Add this property to keep track of the selected count

  // public selectionChange(event: any): void {
  //   console.log('SelectionChange event:', event);

  //   if (event && event.selectedRows) {
  //     // Update the selected IDs set
  //     event.selectedRows.forEach((row: any) => {
  //       const id = row.dataItem?.Id;
  //       if (id !== null && id !== undefined) {
  //         this.selectedIdsSet.add(id);
  //       }
  //     });

  //     // Remove IDs that were deselected
  //     event.deselectedRows.forEach((row: any) => {
  //       const id = row.dataItem?.Id;
  //       if (id !== null && id !== undefined) {
  //         this.selectedIdsSet.delete(id);
  //       }
  //     });

  //     // Update the selected count
  //     this.selectedCount = this.selectedIdsSet.size;

  //     console.log('Selected IDs:', Array.from(this.selectedIdsSet));
  //     console.log('Selected count:', this.selectedCount);
  //   } else {
  //     console.error('No rows selected or event structure is different.');
  //     this.clearSelection();
  //     this.selectedCount = 0;
  //   }
  // }

  // public clearSelection(): void {
  //   this.selectedIdsSet.clear(); // Clear the Set
  //   this.selectedCount = 0; // Reset the selected count

  // }


  public selectionChange(event: any): void {
    if (event) {
      console.log('Selection Change Event:', event);

      // Process selected rows
      if (Array.isArray(event.selectedRows)) {
        event.selectedRows.forEach((row: any) => {
          const id = row.dataItem?.Id; // Use correct property name
          if (id != null) {
            this.selectedIdsSet.add(id);
            console.log(`Row selected: ID=${id}`);
          }
        });
      } else {
        console.warn('Expected selectedRows to be an array.');
      }

      // Process deselected rows
      if (Array.isArray(event.deselectedRows)) {
        event.deselectedRows.forEach((row: any) => {
          const id = row.dataItem?.Id; // Use correct property name
          if (id != null) {
            this.selectedIdsSet.delete(id);
            console.log(`Row deselected: ID=${id}`);
          }
        });
      } else {
        console.warn('Expected deselectedRows to be an array.');
      }

      // Update ids, globalIds, and listView based on the selected IDs
      this.ids = Array.from(this.selectedIdsSet);
      console.log('Updated IDs:', this.ids);

      this.globalIds = this.ids.map(id => {
        const selectedRow = this.getrfiUserDatas.find(row => row.Id === id); // Ensure id property matches
        return selectedRow?.GlobalId || ''; // Provide default empty string if not found
      });
      console.log('Updated Global IDs:', this.globalIds);

      this.activityView = this.ids.map(id => {
        const item = this.getrfiUserDatas.find(row => row.Id === id); // Ensure id property matches
        return item ? item.Body || `Item ${id}` : `Item ${id}`; // Default if title is not present
      });
      console.log('Updated List View:', this.listView);

      // Update the selected count based on ids length
      this.selectedCount = this.ids.length;
      console.log('Selected Count:', this.selectedCount);
    } else {
      console.error('SelectionChange event is invalid or undefined.');
      this.new();
    }
  }



  public clearSelections(): void {
    this.selectedIdsSet.clear(); // Clear the Set
    this.ids = [];
    this.globalIds = [];
    this.selectedCount = 0; // Reset selected count as well
  }
  leadActivityCount: number;
  selectedGlobalIds: string[] = [];
  leadActivitiesResponse: LeadActivityResponse[] = [];






  public leadActivityDelete(): void {
    const modalRef: NzModalRef = this.modal.create({
      nzContent: LeadActivityDeleteComponent,
      nzFooter: null,
      nzTitle: null,
      nzCentered: true,
      nzWidth: '30%'
    });

    modalRef.componentInstance.selectedGlobalIds = this.globalIds;
    modalRef.componentInstance.cancel.subscribe(() => {

      this.leadActivitiesService.getLeadActivityViewGridData(this.filterSearchParameter);
      this.salesListViewSubscription = this.leadActivitiesService.$getLeadUsers.subscribe(result => {
        if (result) {
          this.totalRecords = result?.result?.rowCount;
          this.getrfiUserDatas = result?.result?.rows;
          this.loadActivities(this.getrfiUserDatas);
          console.log("show all data", this.getrfiUserDatas);

          this.isLoading = false;
        }
      }, error => {
        this.isLoading = false;
        console.error('Error fetching activity data:', error);
      });

      this.new();
      modalRef.destroy();
    });


  }









  assignDisplayColor() {
    const modalRef: NzModalRef = this.modal.create({
      nzContent: LeadActivityAssignDisplayColorComponent,
      nzFooter: null,
      nzTitle: null,
      nzCentered: true,
      nzWidth: '30%'
    });
    modalRef.componentInstance.selectedGlobalIds = this.globalIds;
    modalRef.componentInstance.cancel.subscribe(() => {
      this.leadActivitiesService.getLeadActivityViewGridData(this.filterSearchParameter);
      this.salesListViewSubscription = this.leadActivitiesService.$getLeadUsers.subscribe(result => {
        if (result) {
          this.totalRecords = result?.result?.rowCount;
          this.getrfiUserDatas = result?.result?.rows;
          this.loadActivities(this.getrfiUserDatas);
          console.log("show all data", this.getrfiUserDatas);

          this.isLoading = false;
        }
      }, error => {
        this.isLoading = false;
        console.error('Error fetching activity data:', error);
      });

      this.new();
      modalRef.destroy();
    });


  }
  leadActivityAssignUser() {
    const modalRef: NzModalRef = this.modal.create({
      nzContent: LeadActivityAssignUserComponent,
      nzFooter: null,
      nzTitle: null,
      nzCentered: true,
      nzWidth: '30%'
    });
    modalRef.componentInstance.selectedGlobalIds = this.globalIds;
    modalRef.componentInstance.cancel.subscribe(() => {
      this.leadActivitiesService.getLeadActivityViewGridData(this.filterSearchParameter);
      this.salesListViewSubscription = this.leadActivitiesService.$getLeadUsers.subscribe(result => {
        if (result) {
          this.totalRecords = result?.result?.rowCount;
          this.getrfiUserDatas = result?.result?.rows;
          this.loadActivities(this.getrfiUserDatas);
          console.log("show all data", this.getrfiUserDatas);

          this.isLoading = false;
        }
      }, error => {
        this.isLoading = false;
        console.error('Error fetching activity data:', error);
      });
      this.new();
      modalRef.destroy();
    });


  }




  // new() {
  //   this.activityView = [];
  //   this.listView = [];
  //   this.activityTemplates = [];
  //   this.proposalTemplates = [];

  // }


  public new(): void {
    this.activityView = [];
    this.listView = [];
    this.activityTemplates = [];
    this.proposalTemplates = [];

    // Clear selected IDs and global IDs
    this.selectedIdsSet.clear();
    this.ids = [];
    this.globalIds = [];

    this.selectedCount = 0;

  }

  // clearSelection(): void {
  //   this.listView = [];
  //   this.activityView = [];
  //   this.activityTemplates = [];
  //   this.proposalTemplates = [];
  //   this.ids = [];
  // }

  onSelectionChange(event: any): void {
    console.log('Selection change:', event);
  }



  hidePercentage(): string {
    return ''; // Return empty string to hide percentage
  }


  getAge(createdOn: Date): string {
    if (!createdOn) return '';

    const currentDate = new Date();
    const createdDate = new Date(createdOn);
    const diffTime = Math.abs(currentDate.getTime() - createdDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return `${diffDays} ${diffDays > 1 ? 'days' : 'day'}`;
  }

  expandedAttendees: Set<number> = new Set<number>();  // Assuming Id is a number

  toggleAttendees(dataItemId: number): void {
    console.log('Toggling Attendees for ID:', dataItemId);
    if (this.expandedAttendees.has(dataItemId)) {
      this.expandedAttendees.delete(dataItemId);
    } else {
      this.expandedAttendees.add(dataItemId);
    }
    console.log('Current Expanded Attendees Set:', this.expandedAttendees);
  }


  isAttendeesExpanded(dataItemId: number): boolean {
    return this.expandedAttendees.has(dataItemId);
  }


  exportToExcel(): void {
    const currentDate = new Date().toLocaleDateString();
    const headings = ['Status', 'Type', 'Opportunity Title ', 'Assigned User', ' Contact Date ', 'Attendees', ' Activity Notes', 'Cell Phone', 'Suburb', ' Email Address', 'Email', 'Last Contacted', 'Phone', 'State', 'Street Address', 'Postal Code'];
    const dataToExport = [];
    dataToExport.push(['Exported on ' + currentDate]);
    dataToExport.push([]);
    dataToExport.push(headings);

    this.getrfiUserDatas.forEach(row => {
      const rowData = [];
      headings.forEach(column => {
        switch (column) {
          case 'Status':
            rowData.push(`${row?.ActivityStatus} `);
            break;
          case 'Type':
            rowData.push(row?.ActivityTypeId);
            break;
          case 'Opportunity Title':
            rowData.push(row?.LeadOpportunityTitle);
            break;
          case 'Assigned User':
            rowData.push(row?.AssignedUserName);
            break;

          // case 'Attendees':
          //   rowData.push(row.attendee);
          //   break;
          case 'Activity Notes':
            rowData.push(row.Subject || '');
            break;
          case 'Cell Phone':
            rowData.push(row?.CustomerCellPhone || '');
            break;
          case 'Suburb':
            rowData.push(row?.Suburb || '');
            break;
          case 'Email Address':
            rowData.push(row?.CustomerEmailAddress || '');
            break;
          // case 'Last Contacted':
          //   rowData.push(row.LastContacted || '');
          //   break;
          case 'Phone':
            rowData.push(row.CustomerPhoneNumber || '');
            break;
          case 'State':
            rowData.push(row.State || '');
            break;
          // case 'Street Address':
          //   rowData.push(row. || '');
          //   break;
          case 'Postal Code':
            rowData.push(row.PostalCode || '');
            break;
          default:
            rowData.push('');
            break;
        }
      });
      dataToExport.push(rowData);
    });

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataToExport);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'Users.xlsx');
  }


  exportToExcelLeadProposal(): void {
    const currentDate = new Date().toLocaleDateString();
    const headings = ['Proposal Title', 'Opportunity Title', 'Customer Contact', 'Salesperson', 'Owner Price', 'Proposal Status', 'Opportunity Status', 'Updated', 'Last Viewed', 'Project Type', 'Takeoff Status'];
    const dataToExport = [];
    dataToExport.push(['Exported on ' + currentDate]);
    dataToExport.push([]);
    dataToExport.push(headings);

    this.getLeadProposalData.forEach(row => {
      const rowData = [];
      headings.forEach(column => {
        switch (column) {
          case 'Proposal Title':
            rowData.push(`${row.LeadProposalsTitle}`);
            break;
          case 'Opportunity Title':
            rowData.push(row.LeadOpportunityTitle || '');
            break;
          case 'Customer Contact':
            rowData.push(row.CustomerInformationDisplayName || '');
            break;
          case 'Salesperson':
            rowData.push(row.LeadOpportunitySalesPersonFullName || '');
            break;
          case 'Owner Price':
            rowData.push(row.CostItemOwnerPrice || '');
            break;
          case 'Proposal Status':
            rowData.push(row.ProposalStatus || '');
            break;
          case 'Opportunity Status':
            rowData.push(row.ProjectTypeName || '');
            break;
          default:
            rowData.push('');
            break;
        }
      });
      dataToExport.push(rowData);
    });

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataToExport);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'Users.xlsx');
  }
  @Input() Testing: boolean;
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  @ViewChild('eventTemplate', { read: TemplateRef }) eventTemplate!: TemplateRef<any>;
  @ViewChild('eventTemplate1', { read: TemplateRef }) eventTemplate1!: TemplateRef<any>;
  activities: LeadActivityResponse[] = [];
  TryeventTemplate: LeadActivityResponse;
  TryeventTemplates: LeadActivityResponses;
  hideDetails: boolean = false;
  num: any = {};
  testing: any = {};
  Testingfor: string
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    timeZone: 'UTC',
    dayMaxEvents: false,
    headerToolbar: {
      left: '',
      center: '',
      right: ''
    },
    events: [],
    eventContent: this.renderEventContent.bind(this),
    dayCellDidMount: this.renderDayCell.bind(this)
  };
  renderDayCell(arg: any) {
    const date = new Date(arg.date).toISOString().split('T')[0];

    if (date === this.activeDate) {
      const dayCellElement = arg.el as HTMLTableCellElement;
      const dayTopElement = dayCellElement.querySelector('.fc-daygrid-day-top');

      if (dayTopElement) {
        (dayTopElement as HTMLElement).style.background = '#6097b5';
        (dayTopElement as HTMLElement).style.padding = '0';
        (dayTopElement as HTMLElement).style.color = '#fff';
        (dayTopElement as HTMLElement).style.textAlign = 'right';

        dayCellElement.style.height = '7px';
        dayCellElement.style.verticalAlign = 'top';

        (dayTopElement as HTMLElement).addEventListener('click', () => {
          this.leadActivityDialogBoxOpen('ACTIVITY');
        });
      }
    }
  }
  loadActivities(activityResponse: LeadActivityMetaData[]): void {
    this.testing = activityResponse?.map(activity => ({
      title: activity?.LeadActivityTypeName,
      leadOpportunityId: activity?.LeadOpportunityId,
      id: activity?.Id,
      date: activity?.ActivityOn,
      backgroundColor: activity?.LeadActivityColorCollection,
      name: activity?.AssignedUserName,

      extendedProps: {
        opportunityTitle: activity?.LeadOpportunityTitle,
        displayName: activity?.CustomerDisplayName,

      },
    }));
    this.cdr.detectChanges();
    this.againterst();
    if (this.calendarComponent) {
      const calendarApi = this.calendarComponent.getApi();
      calendarApi.removeAllEvents();
      calendarApi.addEventSource(this.testing);
      this.triggerResize();
    }
  }
  getByIdActivityClandar(event: number) {
    this.leadOpportunitiesService.ActivityGetbyId(event).subscribe(
      (response) => {
        this.TryeventTemplates = response?.result;
        this.isCardVisible = true;
      });
    this.isEndTimeNull();
  }
  isEndTimeNull(): boolean {
    return this.TryeventTemplate?.endTime === null;
  }
  againterst() {
    this.calendarOptions = {
      ...this.calendarOptions,
      events: this.testing
    };
  }
  updateCalendarOptions(): void {
    if (this.calendarComponent) {
      this.calendarComponent.getApi().setOption('events', this.calendarOptions.events);
    }

  }
  onViewChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedView = selectElement.value;

    this.hideDetails = selectedView !== 'dayGridMonth';

    if (this.calendarComponent) {
      this.calendarComponent.getApi().changeView(selectedView);
      this.updateCalendarOptions();
      this.triggerResize(); // Trigger resize after view change
    }
  }

  // onViewChange(event: Event): void {
  //   const selectElement = event.target as HTMLSelectElement;
  //   const selectedView = selectElement.value;

  //   this.hideDetails = selectedView !== 'dayGridMonth';

  //   if (this.calendarComponent) {
  //     this.calendarComponent.getApi().changeView(selectedView);
  //     this.updateCalendarOptions();
  //     this.triggerResize(); // Trigger resize after view change
  //   }
  //   this.initLoad();
  // }

  // updateCalendarOptions(): void {
  //   if (this.calendarComponent) {
  //     this.calendarComponent.getApi().setOption('events', this.calendarOptions.events);
  //   }
  //   this.initLoad();
  // }


  isCardVisible: boolean = true;

  closeButtonAction(): void {
    if (this.isCardVisible) {
      // Action when the card is visible
      console.log('Card is currently visible. Hiding it now.');
      this.isCardVisible = false;
    } else {
      // Action when the card is already hidden
      console.log('Card is already hidden. No action needed.');
      this.isCardVisible = true;

    }
  }

  renderEventContent(arg: any): any {
    const viewType = this.calendarComponent.getApi().view.type;
    const event = arg.event;

    const view = this.viewContainerRef.createEmbeddedView(this.eventTemplate, { event, hideDetails: this.hideDetails });
    const div = document.createElement('div');
    div.appendChild(view.rootNodes[0]);

    return { domNodes: [div] };
  }
  triggerResize(): void {
    if (this.calendarComponent) {
      this.calendarComponent.getApi().updateSize();
    }
  }


  CalandarleadOpportunityFormEdit(event: LeadActivityResponse): void {
    this.num = {
      leadOpportunity: event.leadOpportunityId,
      leadAcit: event.id,
    }
    if (this.num?.leadAcit) {
      const modalRef = this.modal.create({
        nzContent: LeadOpportunitiesNewLeadOpportunitiesComponent,
        nzFooter: null
      });
      modalRef.componentInstance.leadOpportunityResponseId = this.num?.leadOpportunity
      modalRef.componentInstance.leadActivityResponseId = this.num?.leadAcit
      modalRef.componentInstance.JobMenuButton = true;
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
        this.selectedLead = null;
      });
      this.leadAceeetivitiesService.leadActivityTypeResponse$.subscribe(
        (response) => {
          if (response) {
            this.activityViewGrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageActivityView, this.pageSizeActivityView);
          } else {
            console.error("No activity response ID found");
          }
        },
        (error) => {
          console.error("Error fetching lead activity type response:", error);
        }
      );
    } else {
      console.error(this.num);
    }
  };
  MarkComletedActivity() {
    this.TryeventTemplate.markComplete = true;
    this.leadOpportunitiesService.ActivityPut(this.TryeventTemplate).subscribe(
      (response) => {
      });
  }
  LeadStatusCode = LeadStatusCode;
  statussetupColorMap = {
    [LeadStatusCode.Completed.code]: { backgroundColor: '#c4fae2', textColor: '#005c35' },
    [LeadStatusCode.Pending.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
    [LeadStatusCode.PastDue.code]: { backgroundColor: '#fdddd5', textColor: ' #8b1439' },

  };
  typestatussetupColorMap = {
    [LeadStatusCode.Queued.code]: { backgroundColor: '#c7d0d9', textColor: '#202227' },
    [LeadStatusCode.AwaitingMessage.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },


  };
  handleLeadActivitySaved(event: { response: LeadActivityResponse; statusCode: { code: string; description: string; color?: string } }) {
    console.log('Lead Activity Saved:', event.response);
    console.log('Status Code:', event.statusCode);
  }




  pageSizeOptions = [10, 20, 50, 75, 100, 250];
  isAllDataShownLeadProposals(): boolean {
    return this.totalRecords <= this.pageSizeLeadProposals;
  }
  isAllDataShownLeadMap(): boolean {
    return this.totalRecords <= this.pageSizeMap;
  }
  isAllDataShownListView(): boolean {
    return this.totalRecords <= this.pageSizeListView;
  }
  isAllDataShownActivityView(): boolean {
    return this.totalRecords <= this.pageSizeActivityView;
  }
  isAllDataShownActivityTemplate(): boolean {
    return this.totalRecords <= this.pageSizeActivityTemplates;
  }
  isAllDataShownProposalTemplate(): boolean {
    return this.totalRecords <= this.pageSizeProposalTemplates;
  }
  activityData: LeadActivityResponse
  LeadEmailWizard(Id: number): void {
    const modalRef = this.modal.create({
      nzContent: LeademailWizardComponent,

      nzFooter: null
    });
    modalRef.componentInstance.activityResponseId = Id;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.leadActivityOnSavedCompleted.subscribe(() => {
      this.activityViewGrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageActivityView, this.pageSizeActivityView);
    });

  }
  preDefinedDataCodes = {
    defaultLeadActivityCalendarView: CompanySettingssalesPreDefineCodes.DefaultLeadActivityCalendarView,
  };

  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(30018).subscribe(
      (res) => {
        this.lookupSetNameResponse = res.result;
        console.log(res.result);
        
        let lastValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.defaultLeadActivityCalendarView.code
        );

        this.defaultCalendarView = lastValues;
        this.cdr.detectChanges();
        this.updateCalendarOptions();
      }
    );
  }




}

