import { ChangeOrderComponent } from './../Variations/change-order/change-order.component';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { LeadOpportunityResponse } from 'src/Models/LeadTags';
import { LeadProposalsService } from 'src/Service/LeadProp/lead-proposals.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { LeadActivityFormComponent } from 'src/app/lead-opportunities/Components/lead-activity-form/lead-activity-form.component';
import { NewProposalComponent } from 'src/app/lead-opportunities/Components/new-proposal/new-proposal.component';
import { NewscheduleItemComponent } from '../schedules-Components/schedule-common/newschedule-item/newschedule-item.component';
import { ProjectManagementSiteDiariesNewDailyLogComponent } from '../Site-Diaries/project-management-site-diaries-new-daily-log/project-management-site-diaries-new-daily-log.component';
import { ProjectManagementSiteDiariesRelatedToDoStComponent } from '../Site-Diaries/project-management-site-diaries-related-to-do-st/project-management-site-diaries-related-to-do-st.component';
import { WarrantyNewClaimComponent } from '../Warranty/warranty-new-claim/warranty-new-claim.component';
import { SelectionComponent } from '../Selection-Common/selection/selection.component';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { JobInfoComponentInformation } from 'src/Models/Job-List/Job-Information/Jobinformation';

@Component({
  selector: 'app-project-management-select-dialog-box',
  templateUrl: './project-management-select-dialog-box.component.html',
  styleUrls: ['./project-management-select-dialog-box.component.css']
})
export class ProjectManagementSelectDialogBoxComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  leadOptions: LeadOpportunityResponse[] = [];
  selectedLead: LeadOpportunityResponse;
  selectedLeadId: any; 
  jobInfoOptions: JobInfoComponentInformation[] = [];

  constructor(
    private jobInfoService: JobInformationService,
    private modal: NzModalService,
    public AccessLocalStorageService: AccessLocalStorageService,
    @Inject(NZ_MODAL_DATA) public data: any
    
  
  ){

  }

  ngOnInit(){
    this.getLeadData();
  }

  getLeadData() {
    this.jobInfoService.getAllLeadData().subscribe(Data => {
      this.jobInfoOptions = Data.result;
    });

  }


  onLeadSelect($event: any): void {
    let selectedLead = this.leadOptions.find(x => x.id === $event);
    console.log('show data', selectedLead);
     this.selectedLead = selectedLead;
     this.openLeadActivityFormModal(selectedLead);
     this.cancel.emit();
  }

openLeadActivityFormModal(leadSelectedData: LeadOpportunityResponse) {
  if (this.data.type === 'Schedule') {
    const modalRef = this.modal.create({
      nzWidth: '67%',
      nzBodyStyle: {'background': '#f1f4fa', 'padding': '0','margin': '0', 'overflow-x': 'hidden',},
      nzStyle: {
        top: '22px',
      },
      nzContent: NewscheduleItemComponent,
      nzData: {
        leadData: leadSelectedData,
        dropDownData:this.selectedLead
      },
      nzFooter: null
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      this.selectedLead = null;
    });
  } else if (this.data.type === 'SiteDiaries') {
    const modalRef = this.modal.create({
      nzWidth: '97%',
      nzBodyStyle: {'background': '#f1f4fa', 'padding': '0','margin': '0', 'overflow-x': 'hidden',},
      nzStyle: {
        top: '22px',
      },
      nzContent: ProjectManagementSiteDiariesNewDailyLogComponent,
      nzData: {
        leadData: leadSelectedData
      },
      nzFooter: null
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      this.selectedLead = null;
    });
  } else if (this.data.type === 'To-Dos') {
    const modalRef = this.modal.create({
      nzWidth: '97%',
      nzBodyStyle: {'background': '#f1f4fa', 'padding': '0','margin': '0', 'overflow-x': 'hidden',},
      nzStyle: {
        top: '22px',
      },
      nzContent: ProjectManagementSiteDiariesRelatedToDoStComponent,
      nzData: {
        leadData: leadSelectedData
      },
      nzFooter: null
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      this.selectedLead = null;
    });
  } else if (this.data.type === 'ChangeOrder') {
    const modalRef = this.modal.create({
      nzWidth: '97%',
      nzBodyStyle: {'background': '#f1f4fa', 'padding': '0','margin': '0', 'overflow-x': 'hidden',},
      nzStyle: {
        top: '22px',
      },
      nzContent: ChangeOrderComponent,
      nzData: {
        leadData: leadSelectedData
      },
      nzFooter: null
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      this.selectedLead = null;
    });
  } else if (this.data.type === 'Selection') {
    const modalRef = this.modal.create({
      nzWidth: '97%',
      nzBodyStyle: {'background': '#f1f4fa', 'padding': '0','margin': '0', 'overflow-x': 'hidden',},
      nzStyle: {
        top: '22px',
      },
      nzContent: SelectionComponent,
      nzData: {
        leadData: leadSelectedData
      },
      nzFooter: null
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      this.selectedLead = null;
    });
  } else if (this.data.type === 'Warranties') {
    const modalRef = this.modal.create({
      nzWidth: '95%',
      nzBodyStyle: {'background': '#f1f4fa', 'padding': '0','margin': '0', 'overflow-x': 'hidden',},
      nzStyle: {
        top: '25px',
      },
      nzContent: WarrantyNewClaimComponent,
      nzData: {
         leadData:  leadSelectedData
       
      },
      nzFooter: null
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      this.selectedLead = null;
    });
  } 
  else {
    console.error('Unknown type:', this.data.type);
  }
}



}
