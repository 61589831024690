import { Component } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { NewProposalComponent } from '../../new-proposal/new-proposal.component';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { SalesComponent } from 'src/app/shared/component/sales/sales.component';

@Component({
  selector: 'lead-opportunities-lead-proposals-header',
  templateUrl: './lead-opportunities-lead-proposals-header.component.html',
  styleUrls: ['./lead-opportunities-lead-proposals-header.component.css']
})
export class LeadOpportunitiesLeadProposalsHeaderComponent {
  PAGE_ID: number = 37;
  // LEAD_OPPR_ACT_TEMPL: string = FiltersName.LEAD_OPPR_ACT_TEMPL;
  // LEAD_OPPR_ACT_VIEW: string = FiltersName.LEAD_OPPR_ACT_VIEW;
  LEAD_OPPR_LEAD_PROP: string = FiltersName.LEAD_OPPR_LEAD_PROP;
  // LEAD_OPPR_LIST_VIEW: string = FiltersName.LEAD_OPPR_LIST_VIEW;
  // LEAD_OPPR_MAP: string = FiltersName.LEAD_OPPR_MAP;
  // LEAD_OPPR_PROP_TEMP: string = FiltersName.LEAD_OPPR_PROP_TEMP;

  constructor(private modal: NzModalService, 
    private msg: NzMessageService,
    private filterService: FilterSideMenuBarService) { }



    openFilter(component: string, pageId: number) {
      this.filterService.toggleFilter(component, pageId);
    }

  showNewProposal() {
    const modalRef = this.modal.create({
      nzContent: NewProposalComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();

    });
  }
  //LeadSettings
  LeadSettings(): void {
    const modalRef = this.modal.create({
      nzContent: SalesComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }
  
}
