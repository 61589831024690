<nz-modal
    nzWidth="70%"
    [(nzVisible)]="uploadfiles"
    [nzTitle]="UploadFiles1"
    [nzFooter]="UploadFiles2"
    (nzOnCancel)="UploadFilesClose()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '70vh', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }"
>
    <ng-template #UploadFiles1>
        <div class="row">
          <h2 class="p-0 m-0 react">Lead Attachments</h2>
        </div>
    </ng-template>
    <div class="content" *nzModalContent>
      <div class="card border-0 mt-0">
        <div class="card-head">
          <div class="row">
            <div class="col-1 pt-2 ms-3">
              <button nz-dropdown [nzDropdownMenu]="menu4"  nzPlacement="bottomLeft" nzTrigger="click" nz-button class="button-default react"><span>Discard</span><span nz-icon nzType="caret-down" nzTheme="fill"></span></button>
              <nz-dropdown-menu #menu4="nzDropdownMenu">
                <ul nz-menu>
                    <li (click)="uploadFiles()" nz-menu-item><span class="me-2" nz-icon nzType="folder-open" nzTheme="fill"></span>Photo / Doc / Video</li>
                    <li (click)="CreateNewFile()" nz-menu-item><span class="me-2" nz-icon nzType="plus-circle" nzTheme="fill"></span>Create New Doc</li>
                </ul>   
              </nz-dropdown-menu>
            </div>
          </div>
          <div class="row pt-1 ms-3 me-1">
            <div class="col-6 p-0 m-0">
              <h3 class="p-0 m-0 mt-2 react" nz-typography>All Attachments File</h3>
            </div>
            <div class="col-6 text-end">
              <button style="border-radius: 6px 0px 0px 6px;" (click)="grid()" nz-button class="button-default react"><span><i class="bx bxs-grid me-2"></i></span><span>Grid</span></button>
              <button style="border-radius: 0px 6px 6px 0px;" (click)="list()" nz-button class="button-default react"><span><i class="fa-solid fa-list me-2"></i>  </span><span>List</span></button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div *ngIf="isGridView">
                <div class="carousel-view" *ngFor="let fileq of responeData">
                  <div nz-row>
                    <div nz-col nzSpan="17"></div>
                    <div nz-col nzSpan="6" style="padding: 3px 3px 0px 1px;">
                      <div class="button-icon">
                        <i (click)="onRemoveFile(fileq)"
                          class="bx bx-trash pt-1"></i>
                      </div>
                    </div>
                  </div>
                  <div class="bt-file-viewer">
                    <img class="img-1" *ngIf="getFileIcon(fileq)" [src]="getFileIcon(fileq)" />
                    <img class="img-2" *ngIf="!getFileIcon(fileq) && !fileq.type.includes('video')" [src]="fileq.thumbUrl" />
                    <ng-container *ngIf="fileq.type.includes('video')">
                      <div class="video-container">
                        <i nz-icon nzType="play-circle" nzTheme="outline" class="video-play-icon"></i>
                        <video #videoPlayer id="video-{{ fileq.id }}" [src]="fileq.thumbUrl"></video>
                      </div>
                    </ng-container>
                    <div nz-row class="action-row">
                      <div nz-col nzSpan="24">
                        <label class="BTFileUpload-SecondaryText p-0 m-0 ms-2 text-start">{{ fileq.name }}</label>
                      </div>
                      <div nz-col nzSpan="5">
                        <i style="margin: 2px 0px 0px 6px;cursor: pointer;font-size: 21px;color: #626262;" nzTrigger="click" nz-dropdown [nzDropdownMenu]="menu4" class="bx bxs-chevron-down"></i>
                        <nz-dropdown-menu #menu4="nzDropdownMenu">
                          <ul nz-menu style="width: 170px;">
                            <li nz-menu-item (click)="onRemoveFile(fileq)">
                              <div class="dropdown-container">
                                <i style="cursor: pointer;font-size: 20px;color: #626262;" class='bx bx-trash'></i>
                                <span class="react">Delete</span>
                              </div>
                            </li>
                            <li nz-menu-item (click)="uploadFilesUpdate(fileq)" *ngIf="isImageFile(fileq)" >
                              <div class="dropdown-container">
                                <i style="cursor: pointer;font-size: 20px;color: #626262;" class='bx bxs-pencil'></i>
                                <span class="react">Edit Online</span>
                              </div>
                            </li>
                          </ul>
                        </nz-dropdown-menu>
                      </div>
                      <div nz-col nzSpan="4" *ngIf="isImageFile(fileq)" >
                        <i (click)="uploadFilesUpdate(fileq)" style="cursor: pointer;font-size: 26px;color: #626262;" class='bx bxs-pencil'></i>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
          <div *ngIf="isListView">
            <div class="card-body bt-file-viewer">
              <nz-table #basicTable [nzData]="responeData" [nzBordered]="false" nzSize="small" nzShowPagination="false">
                <thead style="background-color: #f1f4fa;border-bottom: #ccc;">
                  <tr>
                    <th></th>
                    <th class="react" style="font-weight: 700;font-size: 15px;">Title</th>
                    <th class="react" style="font-weight: 700;font-size: 15px;">Added By</th>
                    <th class="react" style="font-weight: 700;font-size: 15px;">Last Modified</th>
                    <th class="react" style="font-weight: 700;font-size: 15px;">Ext.</th>
                    <th class="react" style="font-weight: 700;font-size: 15px;">Size</th>
                    <th class="react" style="font-weight: 700;font-size: 15px;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of responeData">
                    <td>
                      <img class="list-img-1" *ngIf="getFileIcon(item)" [src]="getFileIcon(item)" />
                      <img class="list-img-2" *ngIf="!getFileIcon(item) && !item.type.includes('video')" [src]="item.thumbUrl" />
                      <ng-container *ngIf="item.type.includes('video')">
                        <div class="list-video-container">
                          <i nz-icon nzType="play-circle" nzTheme="outline" class="list-video-play-icon"></i>
                          <video #videoPlayer id="video-{{ item.id }}" [src]="item.thumbUrl"></video>
                        </div>
                      </ng-container>
                    </td>
                    <td>
                      <label style="width: 130px;" class="BTFileUpload-SecondaryText react p-0 m-0 text-start">{{ item.name }}</label>
                    </td>
                    <td>
                      <ng-container *ngIf="item?.addedBy === 1; else nonename">
                        <label class="pira-label">
                          <nz-tag [nzColor]="'#fdddd5'">umartutal</nz-tag>
                        </label>
                      </ng-container>
                      <ng-template #nonename>
                        <label class="pira-label">
                          <nz-tag [nzColor]="'#fdddd5'">Null</nz-tag>
                        </label>
                      </ng-template>
                    </td>
                    
                    <td>
                      {{ item?.createdOn | date: 'MMM d, yyyy,h:mm a'}}
                    </td>
                    <td>
                      <label nz-popover [nzPopoverContent]="CellPhoneTemplate" style="width: 100px;" class="BTFileUpload-SecondaryText react p-0 m-0">{{ item.type }}</label>
                      <ng-template #CellPhoneTemplate>
                        <p class="react" style="padding: 20px;white-space: nowrap;">
                         {{ item.type }}
                        </p>
                      </ng-template>
                    </td>
                    <td>
                      <label class="BTFileUpload-SecondaryText react p-0 m-0">{{ item.size / 1024 | number: '1.0-2' }} KB</label>
                    </td>
                    <td style="width: 130px;">
                      <div nz-row>
                        <div nz-col nzSpan="4">
                          <i style="margin: 2px 0px 0px 6px;cursor: pointer;font-size: 21px;color: #626262;" nzTrigger="click" nz-dropdown [nzDropdownMenu]="menu4" class="bx bxs-chevron-down"></i>
                          <nz-dropdown-menu #menu4="nzDropdownMenu">
                            <ul nz-menu style="width: 130px;">
                              <li nz-menu-item (click)="onRemoveFile(item)">
                                <div class="dropdown-container">
                                  <i style="cursor: pointer;font-size: 20px;color: #626262;" class='bx bx-trash'></i>
                                  <span class="react">Delete</span>
                                </div>
                              </li>
                              <li nz-menu-item (click)="uploadFilesUpdate(item)" *ngIf="isImageFile(item)" >
                                <div class="dropdown-container">
                                  <i style="cursor: pointer;font-size: 20px;color: #626262;" class='bx bxs-pencil'></i>
                                  <span class="react">Edit Online</span>
                                </div>
                              </li>
                            </ul>
                          </nz-dropdown-menu>
                        </div>
                        <div nz-col nzSpan="4" *ngIf="isImageFile(item)" >
                          <i (click)="uploadFilesUpdate(item)" style="cursor: pointer;font-size: 26px;color: #626262;" class='bx bxs-pencil'></i>
                        </div>
                        <div nz-col nzSpan="11"></div>
                        <div nz-col nzSpan="5">
                          <i (click)="onRemoveFile(item)" style="margin: 2px 5px 0px 6px;cursor: pointer;font-size: 21px;color: #626262;" class="bx bx-trash"></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #UploadFiles2>
    </ng-template>
</nz-modal>