import { Component } from '@angular/core';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';

@Component({
  selector: 'app-owner-invoice-dashboard',
  templateUrl: './owner-invoice-dashboard.component.html',
  styleUrls: ['./owner-invoice-dashboard.component.css']
})
export class OwnerInvoiceDashboardComponent {
  isCollapsed = false;
  selectedJobId: number = 0;




constructor(private filterService: FilterSideMenuBarService){}
toggleCollapse() {
  this.isCollapsed = !this.isCollapsed;
}
  tabs = [1, 2, 3];
  innerCardTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'Invoices';
      case 2:
        return 'Payments';
      case 3:
        return 'Credit Memos';
      default:
        return '';
    }
}

PAGE_ID: number = 20;
Owner_Invoice: string = FiltersName.Owner_Invoice;

  openFilter(component: string, pageId: number) {
    this.filterService.toggleFilter(component, pageId);
  }
      // Selected Job Id From Left Side Job List
getSelectedJobId(item: number){
  this.selectedJobId = item;
  console.log("selectedJobId : ", this.selectedJobId);
}
}
