import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { JobListGridResponse } from 'src/Models/Job-Scratch/JobFromScratch';
import { ResponseModel, TablePageResponse } from 'src/Models/responseMessage.model';
import { CreateLookupFilterRequest, FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class JobListService {

  private base_Url: string = environment.apiUrl + 'LookupFilter/';

  constructor(private http: HttpClient) {}


  getJobListUserData = new BehaviorSubject<ResponseModel<TablePageResponse<JobListGridResponse>>>(null);
  $getListUsers = this.getJobListUserData.asObservable();

  // ListisselectedFilterId = new BehaviorSubject<number>(0);
  // ListisUserAppliedFilter = new BehaviorSubject<boolean>(false);
  // $ListisUserAppliedFilter = this.ListisUserAppliedFilter.asObservable();


  ListisselectedFilterId = new BehaviorSubject<number>(0);
  $ListisselectedFilterId = this.ListisselectedFilterId.asObservable();

  ListisUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $ListisUserAppliedFilter = this.ListisUserAppliedFilter.asObservable();
  getJobList(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getJobListUserData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  getAppliedFilterData(appliedFilter: CreateLookupFilterRequest[]) {
    const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
    return this.http.post<any>(apiUrl, appliedFilter).subscribe(
      (data) => {
        this.getJobListUserData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  jobListSetSelectedFilterId(filterId: number) {
    this.ListisselectedFilterId.next(filterId);
  }
  JobListUserAppliedFilter(filter: boolean) {
    this.ListisUserAppliedFilter.next(filter);
  }
}