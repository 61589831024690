import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';
import { environment } from 'src/environments/environment.prod';
import { LeadActivityResponse } from 'src/Models/LeadActivity/leadActivityClass';
import { LeadOpportunityMetaData } from 'src/Models/LeadTags';
import { ResponseModel, TablePageResponse } from 'src/Models/responseMessage.model';

@Injectable({
  providedIn: 'root'
})
export class LeadmapService {
  private base_Url: string = environment.apiUrl + 'LookupFilter/';

  constructor(private http: HttpClient) {}
  getLeadMap = new BehaviorSubject<ResponseModel<TablePageResponse<LeadOpportunityMetaData>>>(null);  
  $getLeadMap = this.getLeadMap.asObservable();
  ListViewSelectedFilterId = new BehaviorSubject<number>(0);
  $ListSelectedFilterId = this.ListViewSelectedFilterId.asObservable();
  ListViewIsUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $ListIsUserAppliedFilter = this.ListViewIsUserAppliedFilter.asObservable();

  getMapData(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getLeadMap.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }


  LeadMapSetSelectedFilterId(filterId: number) {
    this.ListViewSelectedFilterId.next(filterId);
  }
  LeadMapUserAppliedFilter(filter: boolean) {
    this.ListViewIsUserAppliedFilter.next(filter);
  }



}
