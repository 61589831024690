<nz-layout>
  <nz-sider
    class="border"
    nzCollapsible
    [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed"
    [nzWidth]="280"
    [nzTrigger]="null"
  >
    <div class="row">
      <app-side-menu-bar
      [isAllJobOption]="true"
      (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"
      ></app-side-menu-bar>
    </div>
  </nz-sider>

  <nz-layout>
    <nz-page-header>
      <nz-page-header-title>
        <nz-breadcrumb nz-page-header-breadcrumb>
          <nz-breadcrumb-item>{{localStorage?.getJobName()}}</nz-breadcrumb-item>
        </nz-breadcrumb>
        <h1>Comments</h1>
      </nz-page-header-title>
      <nz-page-header-extra>
        <nz-space>
          <button
            nz-button
            nzType="default"
            class="rounded-1 me-2"
            (click)="openFilter(COMMENT_V_BY_CONVERSATION, PAGE_ID)"
          >
            <i class="bi bi-funnel fs-6 me-1"></i>Filter
          </button>
        </nz-space>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-content class="custom-content">
      <div class="container-fuild mb-4 m-3">
        <nz-tabset nzType="card">
          <nz-tab
            *ngFor="let tab of selectiontabsdashbord"
            [nzTitle]="selectionTabTitle(tab)"
            style="background-color: #f1f4fa"
          >
            <ng-container *ngIf="tab === 1">

              <div class="mt-3" *ngFor="let data of groupedMessages">
                <nz-collapse [nzBordered]="false">
                  <nz-collapse-panel #p [ngStyle]="{
                      background: '#fafafa',
                      'border-radius': '4px',
                      border: '0px'
                    }"
                    [nzActive]="showCollapsePanel"
                    [nzExpandedIcon]="expandedIcon"
                    [nzHeader]="groupTitle"
                  >
                  <ng-template #groupTitle>
                    <span class="font-family mt-1">{{ data?.date | date: 'MMM d, yyyy'}}</span>
                  </ng-template>

                  <div class="row mt-3" #p *ngFor="let message of data?.messages;">
                    <div [ngClass]="{'Side': userId === message?.createdBy }">
                        <div class="card" style="width: 80%; height: 100%;">
                          <div class="card-body text-start">
                            <div class="row">
                              <div class="col-12">
                                <div class="font-family fw-norma">{{ data?.headerData?.formName?.name }}</div>
                                <div nz-col nzSpan="12"><p class="font-family text-end">{{localStorage?.getJobName()}}</p></div>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div nz-col nzSpan="24">
                                <a>Todo</a>
                                <!-- <p class="text-start mt-2 fs-6">Todo</p> -->
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div nz-col nzSpan="1" *ngIf="userId !== message?.createdBy">
                                <nz-avatar class="text-dark" style="background-color: #efcf61" nzText="DN" [nzSize]="40"></nz-avatar>
                              </div>
                              <div nz-col nzSpan="21">
                                <div class="card border-0" [ngClass]="{'seconBackGround': userId !== message?.createdBy }">
                                  <div class="card-body" id="hoverRow" [ngClass]="{'BackGround': userId === message?.createdBy }">
                                    <div class="row">
                                      <div class="col-10 d-flex">
                                        <strong class="font-family me-2" *ngIf="userId !== message?.createdBy">{{ message?.createdBy }}</strong>
                                        <p class="ListViewJobname me-2">{{ message?.createdOn | date: 'MMM d, yyyy'}}</p>
                                        <p class="ListViewJobname">{{ message?.createdOn | date: 'h:mm a' }}</p>
                                      </div>
                                      <div class="col-2 text-end"  id="hoverIcons" *ngIf="userId === message?.createdBy">
                                        <a  (click)="ConfirmDelete(message?.globalId)" nz-popover [nzPopoverContent]="Summary" nzPopoverPlacement="top" class="fs-5 fa-solid fa-trash me-3"></a>
                                        <ng-template #Summary><div class="rounded-2"><p style="font-size: 13px">Delete comment</p></div></ng-template>
                                        <a nz-popover [nzPopoverContent]="Summary1" nzPopoverPlacement="top" class="fs-5 fa-solid fa-eye  me-3"></a>
                                        <ng-template #Summary1><div class="rounded-2"><p style="font-size: 13px">Edit viewing permissions</p></div></ng-template>
                                      </div>
                                    </div>  
                                    <div class="row">
                                      <div class="col-12">
                                        <div class="font-family fw-norma ms-3 mt-3 mb-2">{{ message?.comments }}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div nz-col nzSpan="2"></div>
                            </div>
                            <div class="row mt-1">
                              <div class="col-4">
                                <a nz-button nzType="link" class="p-0 m-0 ms-2">Open Conversation</a>
                              </div>
                              <div class="col-8 mt-2 font-family ">
                                <p>{{ (data?.messages?.length || -0) }} other comments</p>
                              </div>
                            </div>  
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-template #expandedIcon let-active>
                      {{ active }}
                      <span nz-icon nzType="caret-right" class="ant-collapse-arrow" [nzRotate]="p.nzActive ? 90 : -0"></span>
                    </ng-template>
                  </nz-collapse-panel>
                </nz-collapse>
              </div>
            </ng-container>
          </nz-tab>
        </nz-tabset>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>