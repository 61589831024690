<nz-modal nzWidth="60%" [(nzVisible)]="JobCreateNewScratchModal"
    [nzTitle]="JobCreateNewScratchModalTitle"
    [nzFooter]="jobCreateNewScratchModalfooter"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '70vh', 'overflow-y': 'auto' }"
    [nzStyle]="{ top: '15px' }" (nzOnCancel)="CancelJobScartch()">
    <ng-template #JobCreateNewScratchModalTitle>
        <div class="row p-0 m-0">
            <h2 class="p-0 m-0 fs-3 ">
                Job Price Summary
            </h2>
        </div>
    </ng-template>

<div class="content content-set-background famly-inn " *nzModalContent>
    <div class="row famly-inn gx-3">
        <div class="col-12">
            <div class="card border-0 mt-0">
                <div class="card-head border-bottom">
                    <div class="row py-2">
                      <div class="col-12">
                        <h3 class="ms-3" nz-typography>Job Price Summary</h3>
                      </div>
                    </div>
                </div>
                <div class="card-body p-4 pt-4">
                      <table class="table">
                        <tbody>
                          <tr class="ant-table-row">
                            <td class="pt-3 pb-3">Contract Price Subtotal</td>
                            <td class="pt-0 pb-3" style="text-align: right;">
                                <span class="pira-label">
                                    $272,000.00
                                </span>
                            </td>
                          </tr>
                          <tr class="ant-table-row">
                            <td class="pt-3 pb-3 frg">Approved Selection Difference Subtotal</td>
                            <td class="pt-3 pb-3 frg" style="text-align: right;">
                                <span class="pira-label">
                                    $0.00
                                </span>
                            </td>
                          </tr>
                          <tr class="ant-table-row">
                            <td class="pt-3 pb-3">Approved Change Order Subtotal</td>
                            <td class="pt-3 pb-3" style="text-align: right;">
                                <span class="pira-label">
                                  $0.00
                                </span>
                            </td>
                          </tr>
                          <tr  class="ant-table-row">
                            <td class="pt-3 pb-3 frg">Job Running Total</td>
                            <td class="pt-3 pb-3 frg" style="text-align: right;">
                                <span class="pira-label">
                                  $272,000.00
                                </span>
                            </td>
                          </tr>
                          <tr  class="ant-table-row">
                            <td class="pt-3 pb-3">Less Payments Received</td>
                            <td class="pt-3 pb-3" style="text-align: right;">
                                <span class="pira-label">
                                  $2,000.00
                                </span>
                            </td>
                          </tr>
                          <tr class="ant-table-row">
                            <th class="pt-3 pb-3">Remaining Balance</th>
                            <th class="pt-3 pb-3" style="text-align: right;">
                                <span class="pira-label">
                                    $272,000.00
                                </span>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                      
                </div>
            </div>
            <div class="card border-0 mt-3">
              <div class="card-head">
                <div class="row">
                  <div class="col-12">
                  <nz-collapse  [nzBordered]="true">
                    <nz-collapse-panel
                      #p
                      [ngStyle]="{'background': '#fff','border-radius': '0px',
                      border: '1px solid #fafafa'}"
                      [nzExpandedIcon]="expandedIcon"
                      [nzHeader]="tileetwe"
                    >
                        <div class="row" #p>
                        <div class="col-12">
                          <nz-table [nzShowPagination]="false" [nzBordered]="false" #middleTable nzSize="middle"  [nzData]="listOfData">
                            <thead *ngFor="let data of listOfData">
                              <tr>
                                <th class="pt-3 pb-3"><strong>Title</strong></th>
                                <th class="pt-3 pb-3"><strong>Date</strong> </th>
                                <th class="pt-3 pb-3" style="text-align: right;"><strong>Amount</strong></th>
                              </tr>
                            </thead>
                            <tbody *ngFor="let data of listOfData">
                                <tr>
                                  <td class="pt-3 pb-3"> {{ data.namee }}</td>
                                  <td class="pt-3 pb-3"> {{ data.addresse }}</td>
                                  <td class="pt-3 pb-3" style="text-align: right;"> {{ data.agee }}</td>
                                </tr>
                                <tr class="border-top">
                                  <td class="pt-3 pb-3 fs-6"><strong class="sub-text">{{ data.name }}</strong></td>
                                  <td class="pt-3 pb-3"><strong></strong></td>
                                  <td  style="text-align: right;" class="pt-3 pb-3">
                                    <strong class="sub-text fs-6">
                                      {{ data.age }}
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>

                          </nz-table>

          
                        </div>
                        </div>
                        <ng-template style="background-color: #ccc;" #tileetwe>
                          <h3 nz-typography><span class=" ms-3">Payments Received</span></h3>
                        </ng-template>
                      <ng-template #expandedIcon let-active>
                        {{ active }}
                        <span nz-icon nzType="caret-right" class="ant-collapse-arrow" [nzRotate]="p.nzActive ? 90 : -0"></span>
                      </ng-template>
                    </nz-collapse-panel>
                  </nz-collapse>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
    <ng-template #jobCreateNewScratchModalfooter>
        <nz-space>
            <button *nzSpaceItem nz-button class="rounded-2">Print</button>
        </nz-space>

    </ng-template>
</nz-modal>