// import { Component, Input } from '@angular/core';
// import { NzModalService } from 'ng-zorro-antd/modal';
// import { ImportSelectionComponent } from '../Selection-Common/import-selection/import-selection.component';
// import { FiltersName } from 'src/app/Core/Enums/filtersName';
// import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
// import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
// import { AddSubVendorComponent } from 'src/app/subs-vendors-information/Components/add-sub-vendor/add-sub-vendor.component';
// import { FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';
// import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
import { ChangeDetectorRef, Component,ViewEncapsulation ,OnDestroy, OnInit, ViewChildren, QueryList } from '@angular/core';
import { FormArray, NonNullableFormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { CostCodeService } from 'src/Service/Internaluser/cost-code.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { ApplicationUserResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { ResponseModel, ResponseModelArray, TablePageResponse } from 'src/Models/responseMessage.model';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { AddNewGridStandardViewComponent } from 'src/app/shared/component/Modal/add-new-grid-standard-view/add-new-grid-standard-view.component';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { FilterSearchParameter, LookupFilterResponse } from 'src/app/shared/component/Models/LookupModels';
import { StandardColumnSetupResponse, StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';
import { Subscription } from 'rxjs';
import { ColumnsResizePinProperties } from 'src/Models/InternalUser/internalUserColumnsPinResize';
import { ExcelExportService } from 'src/Service/excel-export.service';
import * as XLSX from 'xlsx';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { AddBulkInternalUserComponent } from 'src/app/internal-user/component/add-bulk-internal-user/add-bulk-internal-user.component';
import { AddNewInternalUserComponent } from 'src/app/internal-user/component/add-new-internal-user/add-new-internal-user.component';
//import { Component } from '@angular/core';
interface Job {
  name: string;
  status: string;
  dateOpened: string;
  DateData: string;
  selected: boolean;
}
interface ItemData {
  id: number;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.css']
})
export class ListsComponent {
isLoading : boolean = false;
displayColumnSubscription: Subscription;
PAGE_ID: number = 30;
separateDialCode = true;
SearchCountryField = SearchCountryField;
CountryISO = CountryISO;
PhoneNumberFormat = PhoneNumberFormat;
preferredCountries: CountryISO[] = [CountryISO.Pakistan];
isVisible = false;
isConfirmLoading = false;
transformedData: NzTreeNodeOptions[] = [];
userInvited: boolean = false;
companyNameInvalid: boolean = false;
confirmModal?: NzModalRef;
filtersData: any[] = [];
showCard = true;
tabs = [1, 2, 3, 4, 5, 6, 7];
checked00 = false;
indeterminate = false;
listOfCurrentPageData: readonly ApplicationUserResponse[] = [];
listOfData: readonly ApplicationUserResponse[] = [];
setOfCheckedId = new Set<number>();
isLoadingOne = false;
showErrorCards: boolean = false;
getInternalUserDataResponseModelArray: ResponseModel<TablePageResponse<ApplicationUserResponse>>;
getInternalUserData: ApplicationUserResponse[] = [];
total = 1;
loading = true;
demoValue = 3;
isDisabled = true;
CoseCode: { id: number, title: string, detail: string }[] = [];
selectedImageUrl: string | undefined;
showChooseImageButton: boolean = true;
mainCheckbox: boolean = true;
sortColumn: keyof Job = 'name';
sortDirection: 'asc' | 'desc' = 'asc';
text = "Zircon Homes T/A Xircon Homes is asking that you participate in Buildertrend, a project management software that is absolutely free to you. By activating your free account, you can follow along with the progress of the job, get notified about action items, and communicate with us easier. It is user friendly and can be used on your mobile device as you go .Activation is free, easy, and takes less than a minute. Please click ACTIVATE to get started. This activation link will expire after 30 days.To learn more about the experience, consider viewing these: Sub/Vendor Overview Video Sub/Vendor Mobile App Overview";
additionalEmails: FormArray;
selectedValuefilter: any = null;
selectedFilterData: any = null;
StandardData: any[] = [];
selectedValueControl: any;

currentPage = 1;
pageSize = 10;
totalRecords = 100;
INTERNAL_USER: string = FiltersName.INTERNAL_USER;
displayedColumns: string[] = [];
filterSearchParameter : FilterSearchParameter;
lookupFilterResponse : LookupFilterResponse[] = [];
getStandardViewFilterData : StandardViewResponse[] = [];
getStandardViewColumnFilterData : StandardColumnSetupResponse[] = [];
fixedSelectedColumnNames: string[] = [];
selected_View_Columns : string[] = [];
selectedColNameSizePin : ColumnsResizePinProperties[]=[];
isFilterApplied: boolean = false;
selectedFilter: number = -1;
defaultFilterIdSelected : number = 0;

// public menuSettings: ColumnMenuSettings = {
//   lock: true,
//   stick: true,
//   setColumnPosition: { expanded: true },
//   autoSizeColumn: true,
//   autoSizeAllColumns: true,
// };
expandedRows: number[] = [];
isColumnSticky: boolean[] = new Array(this.displayedColumns.length).fill(false);

columnSizes: { columnName: string, widthSize: number }[] = [];
//columnSizes2: { columnName: string, widthSize: number }[] = [];
selectionFrom: string = 'DB' ; // New Changes By Aamir Ali - 22-Apr2024
constructor(private fb: NonNullableFormBuilder,
  private CostCodeService: CostCodeService,
  public toastService: NzMessageService,
  private modal: NzModalService,
  private internalUserService: InternalUserService,
  private standardViewService: StandardViewForAllService,
  private filterService: FilterSideMenuBarService,
  private LookupFilterService: LookupFilterService,
  private changeDetectorRef: ChangeDetectorRef,
  private excelService: ExcelExportService,
  private accessLocalStorageService : AccessLocalStorageService
 ) {
  this.displayedColumns = [];
}

ngOnInit(): void {
  this.displayedColumns = [];
  this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
    if(colName.length > 0){
      this.displayedColumns = colName;
      this.changeDetectorRef.detectChanges();
    }
  });

  this.displayColumnSubscription = this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
    if(standardType != -1){
      this.selectedFilter = standardType;
    }else{
      this.selectedFilter = standardType;
    }

  });

  this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom); // New Changes By Aamir Ali - 22-Apr2024
  this.getStandardViewFilterByFormPageId(this.PAGE_ID);

  this.CostCodeGetData();
  this.showErrorCards = false;
  this.companyNameInvalid = false;

  this.internalUserService.$isUserAppliedFilter.subscribe(filter =>{
    if(filter){
      this.isFilterApplied = filter;
    }else{
      this.isFilterApplied = filter;
    }
  });
}
getColumnWidthSize: number[] = [];
onFilterChange(id: number) {
  this.isColumnSticky= new Array(this.displayedColumns.length).fill(false);

  this.selectedFilter = -1;
  this.displayedColumns = [];
  this.selected_View_Columns = [];
   let selectedView = this.getStandardViewFilterData.find(view => view.id === id).standardViewParameters;
   selectedView.forEach(viewItem => {
     if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
       this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
       this.displayedColumns = this.selected_View_Columns;

        const columnIndex = this.displayedColumns.indexOf(viewItem.standardColumnSetup.displayColumnName);
        if (columnIndex !== -1) {
          this.isColumnSticky[columnIndex] = viewItem.isPin;
          this.getColumnWidthSize[columnIndex] = viewItem.resizeColumnWidth;
        }
     }
   });
 }

openStandardViewFilterComponent(isSaveAs?: string) {
  let isInputVisible : boolean = false;
  if(isSaveAs === 'saveAs'){
    isInputVisible = true;
  }else{
    isInputVisible = false;
  }
  this.selectedColNameSizePin = [];
  for (let i = 0; i < this.displayedColumns.length; i++) {
    const item = this.displayedColumns[i];
    const isPinValue = this.isColumnSticky[i];
    let widthSize = 0;
    if (this.columnSizes.length > 0) {
      for (let i = 0; i < this.columnSizes.length; i++) {
        const columnName = this.columnSizes[i].columnName;
        const columnNameSize = this.columnSizes[i].widthSize;
        if (columnName === item) {
          widthSize = columnNameSize;
          break;
        }
      }
    }

    if (widthSize === 0) {
      widthSize = 180;
    }

    this.selectedColNameSizePin.push({
      displayColumnName: item,
      resizeColumnWidth: widthSize, //  "200px", // Set default width here
      isPin: isPinValue == true ? isPinValue : false
    });
  }
  const modalRef = this.modal.create({
    nzContent: AddNewGridStandardViewComponent,
    nzData:
    {
      pageId: this.PAGE_ID,
      column: this.getStandardViewColumnFilterData,
      displayCol :  this.displayedColumns,
      viewList : this.getStandardViewFilterData,
      selectedColNameSizePin : this.selectedColNameSizePin,
      selectedStandardView : this.selectedValueControl,
      fixedCol : this.fixedSelectedColumnNames,
      isSaveAs: isInputVisible
    },

  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
}

getStandardViewFilterByFormPageId(pageId:number){
  this.displayedColumns  = [];
  this.fixedSelectedColumnNames = [];
  this.standardViewService.getStandardViewFilterByFormPageId(pageId);
  this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
  this.standardViewService.$getStandardViewFilter.subscribe(result =>{
    if(result){
      this.displayedColumns  = [];
      this.selected_View_Columns = [];
      this.getStandardViewFilterData = result.result;
      let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).standardViewParameters;
      this.selectedValueControl = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).id;
      defaultView.forEach(viewItem => {
        if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
          this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
        }
      });
      this.displayedColumns = this.selected_View_Columns;
    }
  });

  this.standardViewService.$getStandardViewColumn.subscribe(result =>{
    if(result){
      this.getStandardViewColumnFilterData = result.result;
      let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);
      fixedCol.forEach(x => {
        this.fixedSelectedColumnNames.push(x.displayColumnName);
      });

    }
  });
}

internalUserGridTableData(companyId: number, formNameId: number , lookUpFilterId: number, page: number, pageSize: number ){
  this.filterSearchParameter = {
    CompanyParameterId: companyId,
    FormNameId: formNameId,
    LookUpFilterId:lookUpFilterId,
    page: page,
    pageSize: pageSize
  };

  this.internalUserService.getAllInternalUsersByFilter(this.filterSearchParameter);
  this.internalUserService.$getInternalUsers.subscribe(result => {
    if (result) {
      this.totalRecords = result?.result?.rowCount;
      this.getInternalUserData = result?.result?.rows;
    }
  });
}

getStandardFilterByFormPageId(pageId:number, type: string){// New Changes By Aamir Ali - 22-Apr2024
  this.LookupFilterService.getStandardFilterByFormPageId(pageId);
  this.LookupFilterService.$getStandardFilter.subscribe(result =>{
  if(result){
    this.lookupFilterResponse = result.result;
   let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
   this.internalUserService.setSelectedFilterId(defaultFilterId);
   let id = -1;
   if(type === this.selectionFrom){
    this.defaultFilterIdSelected = defaultFilterId;
    this.accessLocalStorageService.setSelectedFilterId(defaultFilterId);

    // New Changes By Aamir Ali - 22-Apr2024
    id = this.accessLocalStorageService.getSelectedFilterId();
   }else{
    id = this.accessLocalStorageService.getSelectedFilterId();
   }

   if(id !== -1){
    this.internalUserGridTableData(1, this.PAGE_ID , id ,this.currentPage,this.pageSize);
   }

  }
  });
}

openFilter(component: string, pageId: number) {
  this.selectionFrom = ''; // New Changes By Aamir Ali - 22-Apr2024
  this.isColumnSticky= new Array(this.displayedColumns.length).fill(false);
  this.filterService.toggleFilter(component, pageId);
  this.internalUserService.userAppliedFilter(false);
}

showNewBulkInternalUser() {
  const modalRef = this.modal.create({
    nzContent: AddBulkInternalUserComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });

  modalRef.componentInstance.onBulkSaveComplete.subscribe(() => {
    this.internalUserGridTableData(1, this.PAGE_ID , this.defaultFilterIdSelected,this.currentPage,this.pageSize);
  });
}

showNewInternalUser() {
  const modalRef = this.modal.create({
    nzContent: AddNewInternalUserComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
  modalRef.componentInstance.internalUserOnCompleted.subscribe(() =>{
    this.internalUserGridTableData(1, this.PAGE_ID , this.defaultFilterIdSelected,this.currentPage,this.pageSize);
  });
}

EditInternalUser(data: ApplicationUserResponse): void {
  const modalRef = this.modal.create({
    nzContent: AddNewInternalUserComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.internalUserResponse = data;
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
  modalRef.componentInstance.internalUserOnCompleted.subscribe(() => {
    this.internalUserGridTableData(1, this.PAGE_ID , this.defaultFilterIdSelected,this.currentPage,this.pageSize);
  });
}

getTabTitle(tab: number): string {
  switch (tab) {
    case 1:
      return 'Overview';
    case 2:
      return 'Permissions';
    case 3:
      return 'Notifications';
    case 4:
      return 'Messaging';
    case 5:
      return 'Job Access (0)';
    case 6:
      return 'Security & Login';
    case 7:
      return 'Other';
    default:
      return '';
  }
}


CostCodeGetData() {
  this.CostCodeService.getData().subscribe(
    (res: any) => {
      this.CoseCode = res.result.map((costCode: any) => ({
        id: costCode.id,
        title: costCode.title,
        detail: costCode.detail
      }));
    },
    (error) => {
      console.error('Error fetching cost codes:', error);
    }
  );
}

fileList: NzUploadFile[] = [
  {
    uid: '-1',
    name: 'xxx.png',
    status: 'done',
    response: '{"status": "success"}',
  }
];


handleChange(info: NzUploadChangeParam): void {
  let fileList = [...info.fileList];
  fileList = fileList.slice(-1);

  fileList = fileList.map(file => {
    if (file.response) {

      file.url = file.response.url;
      this.selectedImageUrl = file.response.url;

      const imgElement = document.getElementById('tempImage') as HTMLImageElement;
      if (imgElement) {
        imgElement.src = this.selectedImageUrl;
      }

      this.showChooseImageButton = false;
    }
    if (info.file.status === 'done') {
      this.toastService.info(`${info.file.name} Click Save to Upload File`);
    } else if (info.file.status === 'error') {
      console.error(`File upload failed: ${info.file.name}`, info.file);
      this.toastService.error(`${info.file.name} file upload failed.`);
    }
    return file;
  });

  this.fileList = fileList;
}

beforeUpload = (file: NzUploadFile): boolean => {
  const isImage = file.type.startsWith('image/');
  if (!isImage) {
    this.toastService.error('You can only upload image files!');
  }
  return isImage;
}

mainCheckboxIndeterminate: boolean = false;

jobs: Job[] = [
  { name: 'ZH-210 Lot 1915 Liston Avenue, Tarniet VIC 3029', status: 'Open', dateOpened: '02. Design, 03. Consent', DateData: 'Nov 23, 2023', selected: false },
  { name: 'ZH-210 Lot 1915 Liston Avenue, Tarniet VIC 3029', status: 'Open', dateOpened: '1. Contract, 02. Design, 03. Consent', DateData: 'Oct 23, 2023', selected: false },
  { name: 'ZH-210 Lot 1917 Liston Avenue, Tarniet VIC 3029', status: 'close', dateOpened: '1. Contract,', DateData: 'Oct 23, 2023', selected: false },
  { name: 'ZH-210 Lot 1917 Liston Avenue, Tarniet VIC 3029', status: 'close', dateOpened: '02. Design, 02. Design, 03. Consent', DateData: 'Nov 23, 2023', selected: false },
];

onCheckboxChanges(event: any, job: Job): void {
  job.selected = event;
  this.mainCheckbox = this.jobs.every((j) => j.selected);
  if (!job.selected) {
    this.mainCheckbox = false;
  }
}

sortTable(column: keyof Job): void {
  if (this.sortColumn === column) {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
  } else {
    this.sortColumn = column;
    this.sortDirection = 'asc';
  }
  this.jobs.sort((a, b) => {
    const valueA = a[column];
    const valueB = b[column];

    if (valueA < valueB) {
      return this.sortDirection === 'asc' ? -1 : 1;
    } else if (valueA > valueB) {
      return this.sortDirection === 'asc' ? 1 : -1;
    } else {
      return 0;
    }
  });
}

getSortIconColor(column: keyof Job): string {
  return this.sortColumn === column ? 'blue' : 'gray';
}

addAdditionalEmail(): void {
  const newEmailGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    label: ['']
  });

  this.additionalEmails.push(newEmailGroup);
}

removeAdditionalEmail(index: number): void {
  this.additionalEmails.removeAt(index);
}

onPageChange(page: number): void {
  this.currentPage = page;
  let filterId : number = 0;
  this.internalUserService.$selectedFilterId.subscribe(v =>{
    if(v){
      filterId = v;
    }
  });
  this.isLoading = true;
  setTimeout(() => {
    this.defaultFilterIdSelected = filterId;
 this.internalUserGridTableData(1, this.PAGE_ID ,filterId,page,this.pageSize);
  this.isLoading = false;

  }, 100);
}

onPageSizeChange(size: number): void {
  this.isColumnSticky= new Array(this.displayedColumns.length).fill(false);
  this.pageSize = size;
  let filterId : number = 0;
  this.internalUserService.$selectedFilterId.subscribe(v =>{
    if(v){
      filterId = v;
    }
  });
  this.isLoading = true;
  setTimeout(() => {
    this.defaultFilterIdSelected = filterId;
    this.internalUserGridTableData(1, this.PAGE_ID ,filterId,this.currentPage,this.pageSize);
    this.isLoading = false;

  }, 100);
}

getDataForSelectStandard(): void {
  this.standardViewService.getData().subscribe((res: any) => {

    this.StandardData = res.result.filter(standardData => standardData.formNameId === 1);
    if (this.StandardData && this.StandardData.length > 0) {
      const defaultItem = this.StandardData.find(standardData => standardData.isSetAsDefault);
      this.selectedValueControl = defaultItem ? defaultItem.id : this.StandardData[0].id;

    }
  });
}

changePreferredCountries() {
  this.preferredCountries = [CountryISO.Australia, CountryISO.Canada];
}

ngOnDestroy(): void {
  this.displayedColumns = [];
  if (this.displayColumnSubscription) {
    this.displayColumnSubscription.unsubscribe();
  }
}

reInitReset(){
  this.ngOnInit();
  this.selectedFilter = -1;
}

saveEditStandardView(){
  this.standardViewService.$createStandardViewRequest.subscribe(formData =>{
    if(formData && formData !== null){
      this.standardViewService.postData(formData)
      .subscribe(
        (res: ResponseModelArray<StandardViewResponse>) => {
          setTimeout(() => {
            this.isLoading = true;
            this.toastService.success('Standard View Updated Successfully');
            this.standardViewService.getStandardViewFilterByFormPageId(this.PAGE_ID);
            this.selectedFilter = -1;
          }, 10);
        },
        (error) => {
          console.error('Error:', error);
          this.isLoading = false;
        }
        );
    }
  });
}

exportToExcel(): void {
  const currentDate = new Date().toLocaleDateString();
  const headings = ['Name', 'Role', 'Admin Access', 'Login', 'Auto Access', 'Email', 'Phone'];
  const dataToExport = [];
  dataToExport.push(['Exported on ' + currentDate]);
  dataToExport.push([]);
  dataToExport.push(headings);

  this.getInternalUserData.forEach(row => {
    const rowData = [];
    headings.forEach(column => {
      switch (column) {
        case 'Name':
          rowData.push(`${row.firstName} ${row.lastName}`);
          break;
        case 'Role':
          rowData.push(row.applicationGroup?.name || '');
          break;
        case 'Admin Access':
          rowData.push(row.isAdminAccess ? 'TRUE' : 'FALSE');
          break;
        case 'Login':
          rowData.push(row.loginAccess ? 'Active' : 'INVITE PENDING');
          break;
        case 'Auto Access':
          rowData.push(row.isAutoAccess ? 'TRUE' : 'FALSE');
          break;
        case 'Email':
          rowData.push(row.email || '');
          break;
        case 'Phone':
          rowData.push(row.phoneNumber || '');
          break;
        default:
          rowData.push('');
          break;
      }
    });
    dataToExport.push(rowData);
  });

  const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataToExport);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, 'Users.xlsx');
}


isExpanded(rowIndex: number): boolean {
  return this.expandedRows.includes(rowIndex);
}

toggleAdditionalEmails(rowIndex: number): void {
  const index = this.expandedRows.indexOf(rowIndex);
  if (index > -1) {
    this.expandedRows.splice(index, 1);
  } else {
    this.expandedRows.push(rowIndex);
  }
}

toggleColumnSticky(index: number) {
  this.isColumnSticky[index] = !this.isColumnSticky[index];
  if (this.isColumnSticky[index]) {
      for (let i = 0; i < index; i++) {
          this.isColumnSticky[i] = true;
      }
  }
}


saveColumnSizes(event: any) {
  event.forEach(col => {
    const columnName = col.column.field;
    const widthSize = col.newWidth;
    const existingIndex = this.columnSizes.findIndex(item => item.columnName === columnName);

    if (existingIndex !== -1) {
      this.columnSizes[existingIndex].widthSize = widthSize;
    } else {
      this.columnSizes.push({ columnName, widthSize });
    }
  });

}


}
