  <!-- <nz-modal
      nzWidth="75%"
      [(nzVisible)]="selectionModelhandle"
      [nzTitle]="companylogo1"
      [nzFooter]="companylogo2"
      (nzOnCancel)="selectionModelCancel()"
      [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '68vh', 'overflow-y': 'auto'}"
      [nzStyle]="{ top: '18px' }"
> -->
    <div>
      <div class="row p-0 m-0">
        <h1 nz-typography>Daily Logs</h1>
      </div>
    </div>
    <form [formGroup]="siteDiariesSetting">

    <div class="content">

      <div class="row famly-inn gx-3">
        <div class="col-12">
            <div class="card border-0 mt-0">
                    <div class="card-head border-bottom">
                      <div class="row py-2">
                          <h5 nz-typography><span class="ms-3"></span>Daily Log Setup</h5>
                      </div>
                  </div>

                <div class="card-body">

                    <div class="row">
                      <div class="col-12">
                        <div class="row">

                          <div class=" col-9 d-flex align-items-center">
                              <label formControlName="stampLocation" nz-checkbox><span class="pira-label">Stamp Location</span></label>
                              <i class="bi bi-info-circle-fill text-secondary me-2" nz-popover nzPopoverPlacement="top"></i>
                              <ng-template #Summary>
                                <div class="rounded-2">
                                  <p style="height: 50px; width: 210px; font-size: 13px;">
                                      New Daily Logs will include a GPS pin for the location they were created at.
                                  </p>
                                </div>
                              </ng-template>
                          </div>
                      </div>
                      
                          <div class="row mt-3">
                            <div class="col-md-12">
                                <label class="sub-text me-2"> Daily Log Notes Default</label>
                                <textarea
                                formControlName="dailyLog"
                                    class="form-control formField"
                                    style="height: 54px; resize: none;"
                                    (input)="autoAdjustTextAreaHeight($event)"
                                ></textarea>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="card border-0 mt-3">
              <div class="card-head border-bottom">
                <div class="row py-2">
                    <h5 nz-typography><span class="ms-3">Weather</span></h5>
                </div>
            </div>
                <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <div class="row mt-1">
                            <div class="col-9">
                              <label formControlName="includeWeather" nz-checkbox><span class="pira-label">Include Weather Conditions (Default)</span></label>
                            </div>
                          </div>
                        <div class="row mt-3">
                            <div class="col-9">
                              <label formControlName="includeNotes"  nz-checkbox><span class="pira-label">Include Weather Conditions Notes  (Default)</span></label>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="card border-0 mt-3">
              <div class="card-head border-bottom">
                <div class="row py-2">
                  <div class="col">
                    <span class="fw-medium ms-2"><strong>Notify Users</strong>
                        <i class="bi bi-info-circle-fill text-secondary ms-2" nz-popover nzPopoverPlacement="top"></i>
                        <ng-template #Daily>
                          <div class="rounded-2">
                            <p style="height: 130px; width: 210px; font-size: 13px;">
                              Users who have notifications disabled will not show
                            </p>
                          </div>
                        </ng-template>

                    </span>
                </div>
              </div>
              </div>
              <div class="card-body">
                <div class="row" style="padding: 0px 0px 0px 0px;">
                  <div class="col-4">
                    <div class="row">
                      <div class="col-6"></div>
                      <div class="col-2">
                        <span>Share</span>
                      </div>
                      <div class="col-2">Notify</div>
                    </div>
                    <div class="row mt-2" style="background-color: #f1f4fa;">
                      <div class="col-6 pt-1 pb-1">
                  <img class="ViewableByIcon" src="assets/ProjectManagerSitesDiaries/userIcon.png" alt="userIcon">
                        <span> Internal Users </span>
                      </div>
                      <div class="col-2 pt-1">
                        <label nzDisabled formControlName="internalShare" nz-checkbox [nzChecked]="true"></label>
                      </div>
                      <div class="col-2 pt-1">
                        <label formControlName="internalNotify" nz-checkbox></label>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-6 pt-1 pb-1">
                  <img class="ViewableByIcon" src="assets/ProjectManagerSitesDiaries/subIcon.png" alt="userIcon">
                        <span> Subs/Vendors </span>
                      </div>
                      <div class="col-2 pt-1">
                        <label formControlName="subsShare" nz-checkbox></label>
                      </div>
                      <div class="col-2 pt-1">
                        <label formControlName="subsNotify" nz-checkbox></label>
                      </div>
                    </div>
                    <div class="row mt-2" style="background-color: #f1f4fa">
                      <div class="col-6 pt-1 pb-1">
                  <img class="ViewableByIcon" src="assets/ProjectManagerSitesDiaries/ownerIcon.png" alt="userIcon">
                        <span> Owner </span>
                      </div>
                      <div class="col-2 pt-1">
                        <label formControlName="ownerShare"  nz-checkbox></label>
                      </div>
                      <div class="col-2 pt-1">
                        <label  formControlName="ownerNotify"  nz-checkbox></label>
                      </div>

                    </div>
                  </div>
                </div>

                </div>
          </div>



            <div class="card border-0 mt-3">
              <div class="card-head border-bottom">
                  <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                      <span class="fw-medium fs-6 ms-2">Daily Logs Custom Fields</span>
                    </div>
                    <div class="col text-end">
                <button nz-button  class="ms-2  ant-btn-primary">Add New Field</button>
                    </div>    
                  </div>
                </div>
              <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12 d-flex alighn-item-center justify-content-center">
                      <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g class="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" class="ant-empty-img-simple-path"></path></g></g></svg>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 d-flex alighn-item-center justify-content-center">
                  <span class="ant-empty-normal">No Data</span>
                    </div>
                  </div>
                </div>
              </div>
              </div>
          </div>  
        </div>
    </div>
    </div>
    <div>
      <button nz-button nzType="primary" class="rounded-1 me-0">Save & New</button>
      <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
      <button nz-button nzType="primary" class="rounded-1 me-0">Save & Close</button>
    </div>
  </form>

    <!-- </nz-modal> -->


    