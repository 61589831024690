import { LookupNameSetup } from 'src/Models/LeadTags';
import { CostItemService } from './../Catalog.service';
import { ModalService } from './../../../../../Service/CompanyInFormation/modal.service';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { CostCodeService } from 'src/Service/Internaluser/cost-code.service';
import { CostCode } from 'src/Models/CostCode/costCode';
import { ProjectManegmentCostItemPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { NzTreeNode, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

// import { LookupNameSetup } from 'src/Models/All-Filters/financialBillsFilterField';
@Component({
  selector: 'app-catalog-new-cost-item',
  templateUrl: './catalog-new-cost-item.component.html',
  styleUrls: ['./catalog-new-cost-item.component.css']
})
export class CatalogNewCostItemComponent {
  //  Saim Start Work on Cost Item work || Saim-New-Work || 5/14/2024 

  selectedFiles: boolean;
  leadOppService: any;
  CodeTypeNodes: LookupNameSetup[] = [];
  


  preDefinedDataCodes = {
    CostType: ProjectManegmentCostItemPreDefinedCodes.CostType,

  };


  @Output() cancel = new EventEmitter<void>();
  // catalogNewCostItemForm:FormGroup;
  inputValue?: string;
  isCheckClicked=   true;
  isCheckClicked2=   true;
  IncludeQuantityField = false;

  submitted: boolean = false;
  itemFormFields: any = {};
  costItemFieldValues: any = {};
  costTypeField: any= {};
  formFieldValues: any= {};
  SubFormField: any= {};
  selectedValue = null;
  costSubCodes: CostCode[] = [];
  listOfOptions: CostCode[];
  isCheckedInitially: boolean = false;
  isOption4Selected = false;

  responseHeaderId: number = 0;
  pageId = 30008;
  newCostItem = true;
  codeType: NzTreeNodeOptions[]=[];
  NewProposalComponent: boolean = true;
  IncludeOwnerField = false;
  selectedOption: string = ''; // Variable to hold the selected option value
  field1Value: string = '';
  field2Value: string = '';
  field3Value: string = '';
  // proposalType: string;

  constructor(
    private Modal: ModalService,
    private CostItemService: CostItemService,
    private costCodeService: CostCodeService,
    private LookupFilterService: LookupFilterService,
    public toastsService: NzMessageService,
    @Inject(NZ_MODAL_DATA) public flag: any,
  ) {
   

   }

  ngOnInit(): void {
    this.initLoad();
    this.getCostCode();
    this.leadProFormGroup();

  }

  
  leadProFormGroup(): void {
    this.SubFormField = {
      categoryId: 1,
      costCodeId: 0,
      title: '',
      description: '',
      quantity: 0,
      unit: 0,
      unitCost: 0,
      builderCost: 1,
      markupValue: 0,
      markupId:  0,
      ownerPrice: 0,
      margin: 0,
      profit: 0,
      internalNotes: '',
      createCostTypeItemParameterRequests: []
  } 
    this.formFieldValues = {
      headerId: 1,
      companyParameterId: 1,
      formNameId: 30008,
      createCostItemParameterRequests: [this.SubFormField]
  }


}

  getCostCode() {
    this.costCodeService.getCategoryData()
      .subscribe(
        (res: ResponseModelArray<CostCode>) => {
          this.costSubCodes = res.result;
          console.log('List of cost codes:', this.costSubCodes);
        },
        (error) => {
          console.error('Error fetching cost codes:', error);
          // Handle error (e.g., display error message)
        }
      );
    console.log("costSubCodes ", this.costSubCodes);

  }


  newCostItemCancel(): void {
    this.cancel.emit();
  }





// Initialize to false if you want the input field hidden initially

  IncludeQuantityFeildShow() {
    this.IncludeQuantityField = !this.IncludeQuantityField;
    

    // Toggle the value
  }
  IncludeQuantityFeildShow2() {
    // This function is triggered when the checkbox is clicked
    if (this.isCheckClicked) {
      this.IncludeQuantityField = true; // Show the quantity field
    } else {
      this.IncludeQuantityField = false; // Hide the quantity field
    }
  }
  saveCatalog() {

    let data = { ...this.formFieldValues };
    let markupValueAsString = this.SubFormField.markupValue;
    let markupValueAsNumber = Number(markupValueAsString);
    console.log(markupValueAsNumber); 
    let updatedRequests = data.createCostItemParameterRequests.map((item) => {
      return {
        ...item,
        createCostTypeItemParameterRequests: item.createCostTypeItemParameterRequests.map((id: string) => ({
          costTypeId: id
        }))
      };
    });
  
    data.createCostItemParameterRequests = updatedRequests;
  
    this.CostItemService.postCostItem(data).subscribe(
      (response) => {
        this.toastsService.success('Cost Item  Saved Successfully!');
        if (response) {
        }
      },
    );
  }





  
 
  
  get totalBuilderCost(): number {
    return  this.SubFormField.unitCost * this.SubFormField.quantity;
  }




  saveFileIdToDatabase(fileId: string): void {
  }

  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(
      res => {
        console.log("show all data", res.result);

        let codeTypeData = res.result.filter(x => x.code === this.preDefinedDataCodes.CostType.code);



        this.codeType = codeTypeData.map(Groups => ({
          title: Groups.name,
          value: Groups.id.toString(),
          key: Groups.id.toString(),
          isLeaf: true
        }));





      });


  }

 
  onItemChecked(): void {
    this.updateQuantityField();
    this.IncludeOwnerFeildShow();
  }

  updateQuantityField() {
    this.IncludeQuantityField = this.isCheckClicked;
  }


  IncludeOwnerFeildShow() {
    this.IncludeOwnerField = this.isCheckClicked2;

  }

  handleOptionSelect(event: any) {
    this.isOption4Selected = event === '4';
  }
    //  Saim End Work on Cost Item work || Saim-New-Work || 5/14/2024 

}

