<div class="table-responsive">
  <nz-table #basicTable class="table">
    <thead>
      <tr>
        <th colspan="4" class="fw-bold text-start"></th>
        <th nzWidth="100px"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let module of sortedApplicationGroupParameters">
        <tr>
          <td class="fw-bold bg-body-tertiary" colspan="12">{{ module.applicationModule?.name }}</td>
        </tr>
        <ng-container *ngIf="module.applicationModule?.applicationModules && module.applicationModule.applicationModules.length > 0">
          <tr *ngFor="let nestedModule of module.applicationModule.applicationModules">
            <td class="" colspan="4">
              {{ nestedModule.name }}
              <nz-tag [nzColor]="'#ceedff'" class="custom-tag">
                {{ nestedModule.name }}
                <a target="_blank"></a>
              </nz-tag>
            </td>
            <td class="fw- text-end">
              View, Add, Edit, Delete
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </nz-table>
</div>
