<div *ngIf="filterResponseData && filterResponseData.length > 0">
    <form  (ngSubmit)="onFilterApply()">
      <div class="row mt-2">
        <!-- <div class="col-10 p-0">
          <div class="input-group">
              <nz-select [(ngModel)]="formFieldValues['standardFilter']" name="standardFilter"  (ngModelChange)="onChangeStandardFilter($event)" nzShowSearch [lang]="true" nzAutoFocus="true"
                  nzPlaceHolder="Standard Filter">
                  <nz-option *ngFor="let filter of filterResponseData"
                      [nzLabel]="filter.name"
                      [nzValue]="filter.id">
                  </nz-option>
              </nz-select>
          </div>
      </div> -->


      <div class="col-10 p-0">
        <div class="input-group">
         <nz-select
          [(ngModel)]="formFieldValues['standardFilter']"
          name="standardFilter"
          (ngModelChange)="onChangeStandardFilter($event)"
          nzShowSearch
          [nzAutoFocus]="true"
          nzPlaceHolder="Standard Filter"
          r>
          <nz-option *ngFor="let filter of filterResponseData"
           [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')"
           [nzValue]="filter.id"
           nzCustomContent>
           <div class="option-content">
            <!-- Customize content with icons and text -->
            <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
            <span>{{ filter.name }}</span>
           <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

           </div>
          </nz-option>
         </nz-select>
        </div>
       </div>



       <!-- [nzLabel]="filter.name + (filter.isSetAsDefault ? ' DEFAULT' : '')"  -->
        <div class="col-1 mt-1">
          <i class="bi bi-three-dots"
           nz-popover
          [nzPopoverContent]="contentTemplate"
          [nzPopoverPlacement]="'top'"

           (click)="openStandardFilterComponent('SAVE_AS')"></i>
        </div>
      </div>
      <div class="row mt-2" *ngIf="selectedFilter === 1">
        <div class="col">
          <p>Your filter has unsaved changes.</p>
        </div>
      </div>
      <div class="row mt-2" *ngIf="selectedFilter === 0">
        <div class="col">
          <p>Your filter has unsaved changes.</p>
        </div>
      </div>

      <div class="col d-flex mt-3">
        <button (click)="openStandardFilterComponent('APPLY_SAVE_AS')" type="button" nz-button nzType="default" class="me-2" style="font-size: 12px;"
        *ngIf="selectedFilter === 1">Save As</button>
        <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;" *ngIf="selectedFilter === 1" type="button">Reset</button>
        <button (click)="editSaveFilterData()" nz-button nzType="primary" type="button" class="me-2" style="font-size: 12px;" *ngIf="selectedFilter === 0">Save</button>
        <button (click)="openStandardFilterComponent('APPLY_SAVE_AS')" nz-button nzType="default" class="me-2"
        style="font-size: 12px;" *ngIf="selectedFilter === 0" type="button">Save As</button>
        <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;" *ngIf="selectedFilter === 0" type="button">Reset</button>
      </div>

      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">Search</label>
          <input  [(ngModel)]="formFieldValues['Search']" name="Search" type="text" class="form-control formField" />
        </div>
      </div>
      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">Status</label>

        <nz-tree-select
             [(ngModel)]="formFieldValues['status']"
             name="status"
             style="width: 100%"
             [nzNodes]="StatusNodes"
             nzShowSearch
             nzAllowClear="false"
             nzCheckable
             (ngModelChange)="onstatusChange($event)"
             nzPlaceHolder="Select status"
             nzDropdownClassName="dropdownStyle"
            >
            </nz-tree-select>
        </div>
      </div>
      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">Classifications</label>


        <nz-tree-select
             [(ngModel)]="formFieldValues['classifications']"
             name="tagSetupId"
             style="width: 100%"
             [nzNodes]="classificationNodes"
             nzShowSearch
             nzAllowClear="false"
             nzCheckable
             (ngModelChange)="onclassificationsChange($event)"
             nzPlaceHolder="Select classifications"
             nzDropdownClassName="dropdownStyle"
            >
            </nz-tree-select>
        </div>
      </div>
      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">Category</label>
          <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['category']" name="category">
            <nz-option *ngFor="let option of categoryOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>
        </div>
      </div>
      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">Priority</label>
          <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['priority']" name="priority">
            <nz-option *ngFor="let option of priorityOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>
        </div>
      </div>
      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">Coordinator</label>
          <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['coordinator']" name="coordinator">
            <nz-option *ngFor="let option of coordinatorOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>
        </div>
      </div>
      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">Assigned To</label>

        <nz-tree-select
        [(ngModel)]="formFieldValues['assignedTo']"
        name="assignedTo"
        style="width: 100%"
        [nzNodes]="AssignDatesNodes"
        nzShowSearch
        nzAllowClear="false"
        nzCheckable
        (ngModelChange)="onAssignedTosChange($event)"
        nzPlaceHolder="Select assignedTo"
        nzDropdownClassName="dropdownStyle"
       >
       </nz-tree-select>

        </div>
      </div>
      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">Original Sub/Vendor</label>
          <nz-tree-select
          [(ngModel)]="formFieldValues['originalVendor']"
          name="originalVendor"
          style="width: 100%"
          [nzNodes]="SubvendorAssginTo"
          nzShowSearch
          nzAllowClear="false"
          nzCheckable
          (ngModelChange)="onSubsVendorChange($event)"
          nzPlaceHolder="Select Original Vendor"
          nzDropdownClassName="dropdownStyle"
         >
         </nz-tree-select>
        </div>
      </div>
      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">Scheduled Date</label>
          <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['scheduledDate']" name="scheduledDate">
            <nz-option *ngFor="let option of ScheduledDateOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>
        </div>
      </div>
      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">Added Date</label>
          <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['addedDate']" name="addedDate">
            <nz-option *ngFor="let option of AddedDateOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>
        </div>
      </div>
      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">Added By</label>
          <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['addedBy']" name="addedBy">
            <nz-option *ngFor="let option of AddedByOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>
        </div>
      </div>




      <div class="col text-end">
 <button nz-button nzType="default" type="button" (click)="resetFilter()">Clear All</button>
        <button class="ms-1" nz-button nzType="primary" type="submit" [disabled]="isFormDirty" >Apply</button>
      </div>
    </form>
  </div>



  <ng-template #contentTemplate>
    <div>
      <p>Manage Saved Views</p>

    </div>
  </ng-template>
