import { MessagingParameter } from './../../../../../Models/ChangeOrder/ChangeOrder';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs';
import { ApplicationUser } from 'src/Models/CustomerInfromation/CustomerInformation';
import { ToDoParameter, ToDoParameterResponse } from 'src/Models/Warranty/warranty';
import { ToDo, Messaging } from './../../../../../Models/Warranty/warranty';
import { jobInformations } from './../../../../../Models/CustomerInformation';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { LeadOpportunityAttachmentsUploadFilesComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { ProjectManagementSiteDiariesCreateFilesComponent } from '../project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { CreateAttachmentForDocumentRequest, LookupNameSetupResponse, ScheduleItemParameterResponse } from 'src/Models/LeadTags';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { ProjectManegmentScheduleItemDetailsPreDefinedCodes, ProjectManegmentToDosPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { ToDoInformationService } from 'src/Service/Project-Management/to-do-information.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { CustomerInformationResponse, Customerres } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { CreateToDoRequest, MessagingResponse, ToDoInformationCheckListItemResponse, ToDoResponse, ToDoSetupResponse } from 'src/Models/Project-Management/projectManagement';
import { NewRFIsComponent } from 'src/app/messaging/Components/RFIs/new-rfis/new-rfis.component';
import { ChangeOrderInformationResponse } from 'src/Models/ChangeOrder/ChangeOrder';
import { ThisReceiver } from '@angular/compiler';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { ApplicationUserForComboResponse } from 'src/Models/InternalUser/InternalUserJob';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { NewscheduleItemComponent } from '../../schedules-Components/schedule-common/newschedule-item/newschedule-item.component';
import { NzImageService } from 'ng-zorro-antd/image';
import { AttachmentAnnotationComponent } from 'src/app/lead-opportunities/Components/attachment-annotation/attachment-annotation.component';

interface Email {
  id: number;
  email: string;
  label: string;
}


// A**
interface CustomUploadFile extends NzUploadFile {
  loading?: boolean;
  error?: boolean;
  addedBy: number
  createdOn: Date;
  modifiedOn: Date;
  id: number;
  globalId: string;
}


@Component({
  selector: 'app-project-management-site-diaries-related-to-do-st',
  templateUrl: './project-management-site-diaries-related-to-do-st.component.html',
  styleUrls: ['./project-management-site-diaries-related-to-do-st.component.css']
})
export class ProjectManagementSiteDiariesRelatedToDoStComponent implements OnInit {

  @Output() cancel = new EventEmitter<void>();
  @Input() comments: any;
  jobID: number;
  formNameID: number = 30006;
  messagingId:number ;
  messagingIdRow: any;

  index: number = 0;
  pageID: number = 8;
  pageId: number = 6;
  PAGE_id: number = 6;
  inputValue?: string;
  holdHeaderId: number;
  responseHold: number;
  checkListId: number;
  visible = false;
  isRowVisible = true;
  isRowVisible1 = false;
  radioValue = 'A';
  counter: number = 0;
  emails: Email[] = [];
  tagsField: FormGroup;
  formGroup: FormGroup;
  TOdoInformation: FormGroup;
  tabs = [1, 2, 3, 4, 5];
  selectedFiles: File[] = [];
  toDoCheckListItems: any[] = [];
  messagesFormValue: any = {};
  newTodosFormFiedls: any = {};
  checkListFormValue: any = {};
  responseToDo: ToDoResponse;
  @Input() responseToDopara: ToDoResponse;
  @Output() helpline: (ChangeOrderInformationResponse & any[]) | MessagingResponse[];
  isDeleteTagsLoading: boolean;
  addButtonClass: string = '';
  showAlert: boolean = false;
  showAlert2: boolean = false;
  showAfterSaveToDo = false;
  Taghandle: boolean = true;
  allChecked: boolean = false;
  enableCheckbox: boolean = true;
  titleTouched: boolean = false;
  public isTagVisible = false;
  showAttachmentsButton = false;
  showAddButton: boolean = true;
  showDropdown: boolean = false;
  isLoadingOne: boolean = false;
  isLoadingEdit: boolean = false;
  showTitleError: boolean = false;
  addingChecklist: boolean = true;
  isChecklistAdded: boolean = false;
  showTagsField: boolean = false;
  showDateFields: boolean = true;
  showRemoveButton: boolean = false;
  showAttachments: boolean = false;
  showPriorityField: boolean = false;
  showReminderField: boolean = false;
  isAssigneesAdded: boolean = false;
  isAddButtonDisabled: boolean = false;
  isCheckboxChecked11: boolean = false;
  showCompleteAllButton: boolean =false;
  showRemoveButtonAssign: boolean = false;
  showScheduleItemFields: boolean = false;
  disableAddChecklistButton2: boolean  = false;

  showDeleteButton = false;


  disableAddChecklistButton: boolean ;
  selectedNodes: any[] = [];
  assignedUserGroup1: any[] = [];
  assignedUserGroup2: any[] = [];
  assignedUserGroup3: any[] = [];
  rowDisable: ToDoSetupResponse[];
  rowDisableNot: ToDoSetupResponse[];
  getToDoGet: ToDoSetupResponse [] = [];
  tagsNodes: NzTreeNodeOptions[] = [];
  assgindata: NzTreeNodeOptions[] = [];
  customerData: NzTreeNodeOptions[] = [];
  subVendorData: NzTreeNodeOptions[] = [];
  combinedNodes: NzTreeNodeOptions[] = [];
  priorityOption: LookupNameSetupResponse[] = [];
  reminderOption: LookupNameSetupResponse[] = [];
  customerInformationData: NzTreeNodeOptions[] = [];
  combinedNodesMessaging: NzTreeNodeOptions[] = [];
  enableChildComponent: boolean = false;
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  scheduleItemOption: ScheduleItemParameterResponse[] = [];
  listOfOption: Array<{ label: string; value: string }> = [];

    isDeleteLoading: boolean = false;
  
  selectedFiless: CustomUploadFile[] = [];
  showNavButtons = false;
  currentIndex = 0;


  confirmModal: import("ng-zorro-antd/modal").NzModalRef<unknown, any>;
  requireAllChecklistItemsToBeCompleteInOrderToCompleteAtoDo : boolean;
  @Output() rowData: ToDoParameterResponse[]=[];
  @Output() rowDatas: ToDoParameterResponse[]=[];
  @Input() constVariblee: MessagingResponse[] | (ChangeOrderInformationResponse & any[]);
  @Input() constVarible: MessagingResponse;

  @ViewChild('carouselContainer', { static: false }) carouselContainer!: ElementRef<HTMLDivElement>;


  preDefinedDataCodes = {
    Priority: ProjectManegmentToDosPreDefinedCodes.Priority,
    Tags: ProjectManegmentToDosPreDefinedCodes.Tags,
    Reminder: ProjectManegmentScheduleItemDetailsPreDefinedCodes.Reminder,
  };
isSubmitted: any;
errorDescriptionTemplate: string|TemplateRef<void>;

  constructor(
    @Inject(NZ_MODAL_DATA) public leadData: any,
    @Inject(NZ_MODAL_DATA) public JOB_NAME: any,
    @Inject(NZ_MODAL_DATA) public rowToDoData: any,
    @Inject(NZ_MODAL_DATA) public rowToDoData2: any,
    private modal: NzModalService,
    public localStorage: AccessLocalStorageService,
    private leadOppService: LeadOpportunitiesService,
    private fb: FormBuilder,
    private LookupFilterService: LookupFilterService,
    public toastsService: NzMessageService,
    private loadingIndicatoreService: LoadingIndicatorService,
    private todoService: ToDoInformationService,
    private customerInformationService: CustomerInformationService,
    private SubVendorService: SubVendorService,

    private cd: ChangeDetectorRef,
    private nzImageService: NzImageService,

  ) {

   }

  toggleCheckbox(item: any) {
    item.isComplete = !item.isComplete;
    this.checkAllChecked();
  }

  ngOnInit(): void {
    // console.log("toDoParameters :", this.rowToDoData.rowToDoData2.toDoParameters[0]);
    // this.mesageingHeadId = this.rowToDoData?.rowToDoData2

    this.messagingId = this.rowToDoData?.rowToDoData2?.toDoParameters[0]?.messagingId;
    this.messagingIdRow = this.rowToDoData?.rowToDoData2?.toDoParameters[0]?.id;
    console.log("MESSAGEING ID ROW:", this.messagingIdRow);
    console.log("MESSAGEING ID :", this.messagingId);

    let stoee = this.rowToDoData?.rowToDoData2?.toDoParameters.map(vari => vari.messaging?.messagingParameters.map(param => param.comments));
     this.comments = stoee?.flat() || [];




     this.rowData = this.rowToDoData?.rowToDoData2;
     this.rowDatas = this.rowToDoData?.rowToDoData2?.toDoParameters.map(print => print.messagingId);




    this.FiltersubVendorsGetAll(),

    this.localStorage.getJobId();
    this.jobID = this.localStorage.getJobId();
    this.localStorage.getJobName();
    this.getAllDropdownToDosModal();
    this.tagFieldMethod();
    this.iniliazeForm();
    this.loadAssignedUserGroup1();
    this.loadAssignedUserGroup2();
    this.ForCombo(),
    this.getScheduleItem(),

      this.leadOppService.getSelectedFiles().subscribe(files => {
        this.selectedFiles = files;
      });

      this.todoService.getData().subscribe(res => {
        this.getToDoGet = res.result;
        const flag =  this.getToDoGet.filter(x => x.requireAllChecklistItemsToBeCompleteInOrderToCompleteAtoDo === this.requireAllChecklistItemsToBeCompleteInOrderToCompleteAtoDo);
       // console.log('this.getToDoGet', flag);

      });

      this.formGroup = this.fb.group({
        dueDate: ['', Validators.required]
      });

    // newTodos Value form

    const jobId = this.localStorage.getJobId();
    this.newTodosFormFiedls = {

      headerId: 0,
      formNameId: 6,
      jobInformationId: jobId,
      companyParameterId: 1,
      createToDoParameterRequest:
      {
        globalId: '00000000-0000-0000-0000-000000000000',
        jobInformationId: jobId,
        isCompleted: false,
        completedBy: 1,
        title: "",
        notes: "",
        isChooseDate: true,
        isLinkedScheduleItem: false,
        scheduleItemParameterId: 0,
        dueNumberDays: null,
        before: false,
        after: false,
        dueDate: null,
        time: null,
        prioritySetupId: 0,
        reminderSetupId: 0,
        attachmentId: null,
        createToDoParameterAssignOwnersRequests: [
          {
            applicationUserId: 0,
            customerInformationId: 0,
            subVendorId: 0,
          }
        ],
        createToDoInformationTagParameterRequests: [],
      }
    }

    // newTodos Value form
    // messages Value form


    // messages Value form
    // check list Value form

    this.checkListFormValue = {
      toDoInformationId: this.responseToDo,
      serialNumber: 1,
      name: "",
      isComplete: false,
      applicationUserId: 0,
      subVendorId: 1
    }
    // check list Value form


    this.newTodosFormFiedls.createToDoParameterRequest['title'] = this.rowData?.map(item => item.title);
    this.newTodosFormFiedls.createToDoParameterRequest['notes'] =this.rowData?.map(s => s.notes);

    this.holdHeaderId = this.rowToDoData?.SELECTED_ROW_DATA?.id;

  }


  FiltersubVendorsGetAll() {
    this.todoService.getData().subscribe(result => {


        let falseTime = result?.result.filter(data => !data.requireAllChecklistItemsToBeCompleteInOrderToCompleteAtoDo === false);


        this.rowDisableNot= falseTime

        if (falseTime.length < 0) {
            this.disableAddChecklistButton = falseTime.some(item => item.requireAllChecklistItemsToBeCompleteInOrderToCompleteAtoDo);
        } else {
            this.disableAddChecklistButton = false;
        }

        let trueTime = result?.result.filter(data => !data.requireAllChecklistItemsToBeCompleteInOrderToCompleteAtoDo === true);

        this.rowDisable = trueTime
        if (trueTime.length > 0) {
            this.disableAddChecklistButton = trueTime.some(item => item.requireAllChecklistItemsToBeCompleteInOrderToCompleteAtoDo);
        } else {
            this.disableAddChecklistButton = true;
        }
    });
}
resetFlags() {
  this.isSave = false;
  this.isSaveAndClose = false;
  this.isSaveAndNew = false;
}
isSaveAndClose: boolean = false;
isSaveAndNew: boolean = false;
isSave: boolean = false;

  saveButton(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save', setAsNewLead: boolean = false): Promise<void> {
  return new Promise((resolve, reject) => {
    if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
      return reject('Already processing');
    }

    // Set appropriate flags based on the actionType
    if (actionType === 'saveAndNew') {
      this.isSaveAndNew = true;
    } else if (actionType === 'saveAndClose') {
      this.isSaveAndClose = true;
    } else {
      this.isSave = true;
    }
    this.isSubmitted = true;
    this.checkErrors(true); // Validate all fields before saving

    // Handle validation errors
    if (Object.values(this.validationStates).includes('error')) {
      setTimeout(() => {
        const errorElements = document.querySelectorAll('.top-of-modal');
        if (errorElements.length > 0) {
          const lastErrorElement = errorElements[errorElements.length - 1];
          lastErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100);

      this.resetFlags();
      return reject('Validation errors present');
    }

    let TagParameter = this.newTodosFormFiedls.createToDoParameterRequest.createToDoInformationTagParameterRequests.map(
      (id: string) =>
        ({ tagSetupId: id })
    );
    this.newTodosFormFiedls.createToDoParameterRequest.createToDoInformationTagParameterRequests = TagParameter;

    let data = { ...this.newTodosFormFiedls };
    const newjob = this.localStorage.getJobId();
    data.headerId = 0;
    data.formNameId = 0;
    data.companyParameterId = 1;
    data.jobInformationId = newjob;

    let viewerParams = this.newTodosFormFiedls.createToDoParameterRequest.createToDoParameterAssignOwnersRequests;
    let transformedParams = [];

    viewerParams.forEach((userId, index) => {
      transformedParams.push({
        applicationUserId: userId,
        customerInformationId: 0,
        subVendorId: viewerParams[index + 1] || 0
      });
    });

    data.createToDoParameterRequest.createToDoParameterAssignOwnersRequests = transformedParams;

    // data.createToDoParameterRequest.createToDoInformationCheckListItemRequests = this.items;
    data.createToDoParameterRequest = {
      jobId: this.newTodosFormFiedls.jobId || null,
      jobInformationId: this.newTodosFormFiedls.jobInformationId || null,
      isCompleted: this.newTodosFormFiedls.isCompleted || false,
      completedBy: this.newTodosFormFiedls.completedBy || 0,
      title: this.newTodosFormFiedls.title || '',
      notes: this.newTodosFormFiedls.notes || '',
      isChooseDate: this.newTodosFormFiedls.isChooseDate != null ? this.newTodosFormFiedls.isChooseDate : true,
      isLinkedScheduleItem: this.newTodosFormFiedls.isLinkedScheduleItem || false,
      scheduleItemParameterId: this.newTodosFormFiedls.scheduleItemParameterId || 0,
      dueNumberDays: this.newTodosFormFiedls.dueNumberDays || null,
      before: this.newTodosFormFiedls.before || true,
      after: this.newTodosFormFiedls.after || false,
      dueDate: this.newTodosFormFiedls.dueDate || new Date(),
      time: this.newTodosFormFiedls.time || new Date(),
      prioritySetupId: this.newTodosFormFiedls.prioritySetupId || null,
      reminderSetupId: this.newTodosFormFiedls.reminderSetupId || null,
      createToDoParameterAssignOwnersRequests: transformedParams
    }

    this.todoService.postDataToDoModel(this.newTodosFormFiedls).subscribe(
      (response) => {


        this.showDeleteButton = true;


        this.responseToDo = response?.result;
        // this.holdHeaderId = this.responseToDo?.toDoParameters?.id;



        this.holdHeaderId = Array.isArray(this.responseToDo?.toDoParameters) && this.responseToDo?.toDoParameters.length > 0
  ? this.responseToDo.toDoParameters[0]?.id
  : null;


        const toDoParameterId = this.responseToDo?.toDoParameters[0]?.id;
         this.messagingId = toDoParameterId ? toDoParameterId : this.rowToDoData;
         this.messagingIdRow =  toDoParameterId ? toDoParameterId : this.rowToDoData;
         console.log(" toDoParameterId ? toDoParameterId : this.rowToDoData;",this.messagingIdRow);

       //  console.log('this.mesageingHeadId ',this.mesageingHeadId );
         // new work sufyan

         this.toastsService.success('New ToDo Saved Successfully!');

        this.checkListSaveMethod(this.selectedFiles);

        if (this.selectedFiles && this.selectedFiles.length > 0) {
          const filesToUpload = this.selectedFiles.filter(file => file && file instanceof File);
          if (filesToUpload.length > 0) {
            const attachmentRequest: CreateAttachmentForDocumentRequest = {
              headerId: response.result.id,
              companyParameterId: 1,
              formNameId: 30006,
              documentFiles: filesToUpload.map(file => file)

            };
            console.log('selectedFiles:', this.selectedFiles);
            console.log('headerId:', response);


            this.leadOppService.CreateAttachmentForDocument(attachmentRequest).subscribe(
              (attachmentResponse) => {
                console.log('Attachment created successfully:', attachmentResponse);
              },
              (attachmentError) => {
                console.error('Error creating attachment:', attachmentError);
              }
            );
            this.showAfterSaveToDo = true;
            this.toastsService.success('Selection Form Successfully!');

            // this.Taghandle = false;
            // this.cancel.emit();
          } else {
            console.warn('No files to upload.');
          }
        }
      }),
      (error) => {

        console.error('Error saving data:', error);
      }
  })}

  checkListSaveMethod(selectedFiles: File[]) {
    this.toDoCheckListItems.forEach(checkList => {
      if (!this.holdHeaderId || this.holdHeaderId <= 0) {
        console.error('Main data not saved yet or invalid responseHold.');
        return;
      }
      const postData = {
        ...checkList,
        toDoInformationId: this.holdHeaderId
      };

      this.todoService.postDataToDoToDoCheckList(postData).subscribe(response => {
        const checkListResponse = response.result;
        this.checkListId = checkListResponse.id;
        console.log('checkListId', this.checkListId);

        if (this.checkListId > 0) {
          if (selectedFiles && selectedFiles.length > 0) {
            selectedFiles.forEach(selectedFile => {
              const attachmentRequest: CreateAttachmentForDocumentRequest = {
                headerId: this.checkListId,
                companyParameterId: 1,
                formNameId: 30007,
                documentFiles: [selectedFile],
              };

              this.leadOppService.CreateAttachmentForDocument(attachmentRequest).subscribe(
                (attachmentResponse) => {
                  console.log('Attachment created successfully:', attachmentResponse);
                  this.toastsService.success('PDF Attachment Saved Successfully!');
                },
                error => {
                  console.error('PDF Attachment API error:', error);
                }
              );
            });
          } else {
            console.log('No selected files found for this checklist item.');
          }
        }
      });
    });
  }

  // A**
  // deleteToDoConfirm(): void {
  //       if (this.isDeleteLoading) {
  //         return;
  //       }
    
  //       this.modal.confirm({
  //         nzTitle: `Delete Lead Proposal?`,
  //         nzContent: 'Are you sure you want to permanently delete this lead proposal and all line items on it?',
  //         nzOkText: `Delete Lead Proposal`,
  //         nzOkDanger: true,
  //         nzCancelText: 'Cancel',
  //         nzOnOk: () => {
  //           this.isDeleteLoading = true;
  //           return this.deleteLeadProposal(this.newTodosFormFiedls.globalId)
  //             .then(() => {
  //               this.handleCancelLeadProposalTemplate();
  //             })
  //             .catch(() => {
    
  //             });
  //         },
  //         nzCentered: true,
  //         nzOkLoading: this.isDeleteLoading,
  //         nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
  //         nzCloseIcon: '',
  //         nzIconType: ''
  //       });
  //     }


    // deleteToDoConfirm(): void {
    //   // Display a simple confirmation modal
    //   this.modal.confirm({
    //     nzTitle: `Delete ToDo?`,
    //     nzContent: 'Are you sure you want to permanently delete this ToDo?',
    //     nzOkText: 'Delete',
    //     nzCancelText: 'Cancel',
    //     nzOkDanger: true, // Red color for delete confirmation
    //     nzOnOk: () => {
    //       // Handle the delete action here (e.g., calling a service or function)
    //       this.toastsService.success('ToDo deleted successfully!');
    //       // Optionally call your delete logic:
    //       // return this.deleteLeadProposal(this.newTodosFormFiedls.globalId);
    //     },
    //     nzCentered: true // Center the modal
    //   });
    // }


    // deleteToDoConfirm(): void {
    //   // Display a simple confirmation modal
    //   this.modal.confirm({
    //     nzTitle: `Delete ToDo?`,
    //     nzContent: 'Are you sure you want to permanently delete this ToDo?',
    //     nzOkText: 'Delete',
    //     nzCancelText: 'Cancel',
    //     nzOkDanger: true, // Red color for delete confirmation
    //     nzOnOk: () => {
    //       // Call the delete API using the global ID(s)
    //       const globalIds = [this.holdHeaderId];
    //       const stringGlobalIds = globalIds.map(id => id.toString()); // Converts to string[]

    //       // Assuming you are deleting by the headerId (or you can adjust it)
    //       this.todoService.deleteToDo(stringGlobalIds).subscribe(
    //         (response) => {
    //           // Show success message on successful deletion
    //           this.toastsService.success('ToDo deleted successfully!');
    //           this.showDeleteButton = false; // Hide delete button after deletion
    //         },
    //         (error) => {
    //           console.error('Error deleting ToDo:', error);
    //         }
    //       );
    //     },
    //     nzCentered: true // Center the modal
    //   });
    // }
    

    deleteToDoConfirm(): void { 
      // Display a simple confirmation modal
      this.modal.confirm({
        nzTitle: `Delete ToDo?`,
        nzContent: 'Are you sure you want to permanently delete this ToDo?',
        nzOkText: 'Delete',
        nzCancelText: 'Cancel',
        nzOkDanger: true, // Red color for delete confirmation
        nzOnOk: () => {
          // Call the delete API using the global ID
          const globalId = this.holdHeaderId.toString(); // Convert the ID to a string if necessary
    
          this.todoService.deleteToDo(globalId).subscribe(
            (response) => {
              // Show success message on successful deletion
              this.toastsService.success('ToDo deleted successfully!');
              this.showDeleteButton = false; // Hide delete button after deletion
            },
            (error) => {
              console.error('Error deleting ToDo:', error);
            }
          );
        },
        nzCentered: true // Center the modal
      });
    }
  



// Attachments
onResize() {
  this.updateNavButtonsVisibility();
}



playVideo(file: CustomUploadFile): void {
  const videoElement: HTMLVideoElement = this.carouselContainer.nativeElement.querySelector(`#video-${file.id}`);
  if (videoElement) {
    videoElement.style.display = 'block'; // Show the video player
    videoElement.play();
  }
}



private updateNavButtonsVisibility() {
  requestAnimationFrame(() => {
    const container = this.carouselContainer.nativeElement;
    if (this.selectedFiless.length > 0) {
      this.showNavButtons = container.scrollWidth > container.clientWidth;
    } else {
      this.showNavButtons = false;
    }
    this.cd.detectChanges();
  });
}

uploadFiles(): void {
  const modalRef = this.modal.create({
    nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
  modalRef.componentInstance.UploadFile.subscribe((data: CustomUploadFile[]) => {
    data.filter(file => this.isImageFile(file));
    this.selectedFiless = [...data, ...this.selectedFiless];
    this.onResize();
  });
}

isImageFile(file: CustomUploadFile): boolean {
  const fileName = file.name.toLowerCase();
  const fileType = file.type;

  return (
    fileType === 'image/png' ||
    fileType === 'image/jpeg' ||
    fileName.endsWith('.png') ||
    fileName.endsWith('.jpeg')
  );
}

scrollToCurrentIndex() {
  const container = this.carouselContainer.nativeElement;
  const scrollAmount = this.currentIndex * 130; // 120px (width) + 10px (margin)
  container.scrollTo({ left: scrollAmount, behavior: 'smooth' });
}

previous() {
  if (this.currentIndex > 0) {
    this.currentIndex--;
    this.scrollToCurrentIndex();
  }
}


next() {
  const container = this.carouselContainer.nativeElement;
  const maxIndex = this.selectedFiless.length - Math.floor(container.clientWidth / 130);
  if (this.currentIndex < maxIndex) {
    this.currentIndex++;
    this.scrollToCurrentIndex();
  }
}


onClick(): void {
  let filteredImages = this.selectedFiless.filter(f =>
    f.name.includes('png') || f.name.includes('jpeg')
  );
    const images = filteredImages.map(file => ({
      src: file.thumbUrl,
      alt: file.name
    }));
    this.nzImageService.preview(images,  {
      nzZoom: 1.0,
      nzRotate: 0
    });
}


getFileIcon(file: CustomUploadFile): string | null {
  const fileType = file.type;
  if (fileType.includes('pdf')) {
    return 'assets/Attachment/documentPDF.svg';
  } else if (fileType.includes('excel') || fileType.includes('spreadsheet')) {
    return 'assets/Attachment/documentXLS.svg';
  } else if (fileType.includes('word')) {
    return 'assets/Attachment/documentDOC.svg';
  } else if (fileType.includes('zip')) {
    return 'assets/Attachment/documentZIP.svg';
  } else if (fileType.includes('text')|| fileType.includes('json')) {
    return 'assets/Attachment/documentGeneric.svg';
  } else if (fileType.includes('ppt') || fileType.includes('presentation')) {
    return 'assets/Attachment/documentPPT.svg';
  } else if (fileType.includes('video')) {
    return null;
  } else if (fileType.includes('image')) {
    return null;
  } else {
    return null;
  }
}


CreateNewFile(): void {
  const modalRef = this.modal.create({
    nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
}
viewAll(): void {
  const modalRef = this.modal.create({
    nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.selectedFilessResponse = this.selectedFiless;
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
  modalRef.componentInstance.removeFile.subscribe((file: CustomUploadFile) => {
    this.removeFile(file);
  });
  modalRef.componentInstance.Test.subscribe((data: CustomUploadFile[]) => {
    this.selectedFiless = [...data, ...this.selectedFiless];
    this.onResize();
  });
}

removeFile(file: CustomUploadFile): void {
  this.selectedFiless = this.selectedFiless.filter(item => item.uid !== file.uid);
}

imageData: string;

uploadFilesUpdate(fileq: CustomUploadFile): void {
  const modalRef = this.modal.create({
    nzContent: AttachmentAnnotationComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.DrawerImge = fileq;
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
  modalRef.componentInstance.imageDataRes.subscribe((data: any) => {
    const newImageUrl = data;
    let test1234 = this.selectedFiless.forEach(activity => {
      activity.thumbUrl = newImageUrl;
    });
    console.log(test1234);
  });
}





  // A** End

  onTitleFocus() {
    this.titleTouched = true;
  }

  onTitleBlur() {
    if (!this.newTodosFormFiedls.title && this.titleTouched) {
      this.showTitleError = true;
    } else {
      this.showTitleError = false;
    }
  }

  getAllDropdownToDosModal() {

    this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(res => {
      this.lookupSetNameResponse = res.result;
      let PriorityOptionValues = res.result.filter((x) => x.code === this.preDefinedDataCodes.Priority.code);
      this.priorityOption = PriorityOptionValues;

      this.LookupFilterService.getLookUpValuesByFormNameId(this.pageID).subscribe(res => {
        this.lookupSetNameResponse = res.result;
        let reminderOptionValues = res.result.filter((x) => x.code === this.preDefinedDataCodes.Reminder.code);
        this.reminderOption = reminderOptionValues;
      })
    })
  }

  tagFieldMethod() {

    this.LookupFilterService.getLookUpValuesByFormNameId(this.PAGE_id).subscribe(responce => {
      this.lookupSetNameResponse = responce.result;

      let tagOptions = responce.result.filter(
        (x) => x.code === this.preDefinedDataCodes.Tags.code
      );
      this.tagsNodes = tagOptions.map((data) => ({
        title: data.name,
        value: data.id.toString(),
        key: data.id.toString(),
        isLeaf: true,
      }));
    })
  }

  showTags(): void {

      this.loadingIndicatoreService.show();
      setTimeout(() => {
        this.loadingIndicatoreService.hide();
        this.isTagVisible = true;
        this.isLoadingEdit = false;
      }, 1000);

  }

  showTagEdit(selectedValue: LookupNameSetupResponse): void {
    if (selectedValue) {
      const selectedId: number = Number(selectedValue);
      this.isTagVisible = true;
      this.isLoadingEdit = true;
      const selectedObjectFromLookup = this.lookupSetNameResponse.find(
        (item) => item.id === selectedId

      );
      if (selectedObjectFromLookup) {
        const selectedData = selectedObjectFromLookup;
        if (selectedData) {
          this.tagsField.controls['name'].setValue(selectedData.name);
          this.tagsField.controls['globalId'].setValue(selectedData.globalId);
          this.tagsField.controls['isFieldValue'].setValue(
            selectedData.isFieldValue
          );
          this.tagsField.controls['companyParameterId'].setValue(
            selectedData.companyParameterId
          );
          this.tagsField.controls['formNameId'].setValue(
            selectedData.formNameId
          );
          this.tagsField.controls['lookUpStandardId'].setValue(
            selectedData.lookUpStandardId
          );
          this.tagsField.controls['code'].setValue(selectedData.code);
          this.tagsField.controls['description'].setValue(
            selectedData.description
          );
          this.tagsField.controls['parentCode'].setValue(
            selectedData.parentCode
          );
        }
      }
    }
  }

  showConfirmData(): void {

    if (this.tagsField.dirty) {
      this.confirmModal = this.modal.confirm({
        nzTitle: '<b>Save Changes?</b>',
        nzContent:
          '<p>This Lead Tags has unsaved changes. Do you want to save your changes before closing??</p> ',
        nzOkText: 'Save',
        nzCancelText: "Don't Save",
        nzOnOk: () => {
          this.SaveData();
        },
        nzOnCancel: () => {
          this.tagsField.reset();
          this.isTagVisible = false;
        },
        nzCloseIcon: 'false',
        nzStyle: { top: '250px' },
        nzClassName: 'custom-modal-content',
      });
    } else {
      this.tagsField.reset();
      this.isTagVisible = false;
    }

  }

  SaveData() {
    if (this.tagsField.valid) {
      this.loadingIndicatoreService.show();
      this.isLoadingOne = true;
      setTimeout(() => {
        this.leadOppService.getLookUpNameSetup(this.tagsField.value).subscribe(
          (response) => {
            this.loadingIndicatoreService.hide();
            const category = this.tagsField.get('name')?.value;
            this.isTagVisible = false;
            this.iniliazeForm();
            this.getAllDropdownToDosModal();

            this.isLoadingOne = false;
            this.toastsService.success(`${category} saved successfully`);
          },
          (error) => {
            this.loadingIndicatoreService.hide();
            if (error.status === 500) {
              this.toastsService.error(
                'Internal Server Error. Please try again later.'
              );
              this.isLoadingOne = false;
            } else if (error.status === 400) {
              this.isLoadingOne = false;
              this.toastsService.error('Bad Request. Please check your input.');
            } else {
              this.toastsService.error(
                'Failed to save division trade. Please try again.'
              );
            }
          }
        );
      }, 1000);
    } else {
      this.tagsField.markAllAsTouched();
    }
  }

  ConfirmDeleteTags(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete Tags?',
      nzContent:
        'This Tags is not applied to any subs/vendors and will be deleted.',
      nzOkDanger: true,
      nzOkText: 'Delete',
      nzCancelText: 'Cancel',
      nzModalType: 'confirm',
      nzOkLoading: this.isDeleteTagsLoading,
      nzAutofocus: 'ok',
      nzCentered: true,
      nzOnOk: () => this.DeleteData(),
    });
  }
  DeleteData(): Promise<boolean> {
    const vendorId = this.tagsField.get('globalId')?.value;
    const tagName = this.tagsField.get('name')?.value;
    this.isDeleteTagsLoading = true;
    this.loadingIndicatoreService.show();

    return new Promise<boolean>((resolve, reject) => {
      this.leadOppService.deleteDataLookupNameSetup(vendorId).subscribe(
        (res: any) => {
          this.isDeleteTagsLoading = false;
          setTimeout(() => {
            this.toastsService.success(`${tagName} Deleted successfully`);
            this.isTagVisible = false;
            this.loadingIndicatoreService.hide();
            this.confirmModal.destroy();
            resolve(true);
          }, 1000);
          this.isLoadingEdit = false;
          this.isTagVisible = false;
          this.iniliazeForm();
          this.getAllDropdownToDosModal();
          this.newTodosFormFiedls['catagorySetupId'] = '';
          this.newTodosFormFiedls['locationSetupId'] = '';
        },
        (error) => {
          this.isDeleteTagsLoading = false;
          this.isTagVisible = false;
          reject(false);
        }
      );
    });
  }

  editData() {
    if (this.tagsField.valid) {
      this.loadingIndicatoreService.show();
      const tagName = this.tagsField.get('name')?.value;
      setTimeout(() => {
        this.leadOppService.getLookUpNameSetup(this.tagsField.value).subscribe(
          (response: any) => {
            this.loadingIndicatoreService.hide();
            this.isTagVisible = false;
            this.iniliazeForm();
            this.getAllDropdownToDosModal();
            this.isLoadingEdit = false;
            this.toastsService.success(`${tagName} Update successfully`);
          },
          (error) => {
            this.loadingIndicatoreService.hide();
            if (error.status === 500) {
              this.toastsService.error(
                'Internal Server Error. Please try again later.'
              );
            } else if (error.status === 400) {
              this.toastsService.error('Bad Request. Please check your input.');
            } else {
              this.toastsService.error(
                'Failed to save division trade. Please try again.'
              );
            }
          }
        );
      }, 1000);
    } else {
    }
  }

  SaveOrUpdateData() {
    if (this.isLoadingEdit) {
      this.editData();
    } else {
      this.SaveData();
    }
  }

  iniliazeForm() {
    this.tagsField = this.fb.group({
      id: [''],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      name: ['', Validators.required],
      companyParameterId: [1],
      formNameId: 6,
      lookUpStandardId: [57],
      parentCode: ['PMTDT'],
      code: ['PMTDT'],
      description: [''],
      isFieldValue: [true],
    });
  }

  toggleDateFields() {
    this.showDateFields = !this.showDateFields;
    this.showScheduleItemFields = false;
  }

  toggleScheduleItemFields() {
    this.showScheduleItemFields = !this.showScheduleItemFields;
    this.showDateFields = false;
  }

  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe(
      (response: ResponseModelArray<ApplicationUserForComboResponse>) => {
        this.SubVendorService.getData().subscribe(
          (res: ResponseModelArray<SubVendorResponse>) => {
            this.customerInformationService.customercombo().subscribe(
              (responses: ResponseModelArray<Customerres>) => {
            this.combinedNodes = [
              {
                title: 'Select All',
                value: 'select_all',
                key: 'select_all',
                selectable: false,
                isLeaf: false,
                expanded: true,
                children: [
                  {
                    title: 'Internal Users',
                    value: 'internal_users',
                    key: 'internal_users',
                    selectable: false,
                    isLeaf: false,
                    expanded: true,
                    children: response.result.map((status) => ({
                      title: status.fullName,
                      value: status.id.toString(),
                      key: status.id.toString(),
                      isLeaf: true
                    }))
                  },
                  {
                    title: 'Sub Vendors',
                    value: 'sub_vendors',
                    key: 'sub_vendors',
                    selectable: false,
                    isLeaf: false,
                    expanded: true,
                    children: res.result.map((status) => ({
                      title: status.companyName,
                      value: status.id.toString(),
                      key: status.id.toString(),
                      isLeaf: true
                    }))
                  },
                  {
                    title: 'Customer Info',
                    value: 'Customer_info',
                    key: 'Customer_info',
                    selectable: false,
                    isLeaf: false,
                    expanded: true,
                    children: responses.result.map((status) => ({
                      title: status.displayName,
                      value: status.id.toString(),
                      key: status.id.toString(),
                      isLeaf: true
                    }))
                  }
                ]
              }
            ];
          },
          (error) => {
            console.error('Error fetching sub-vendor data:', error);
          }
        );
      },
      (error) => {
        console.error('Error fetching customer data:', error);
      }
    );
  })
}

  combineNodes(): void {
    if (this.assgindata && this.subVendorData && this.customerInformationData) {
      const internalUsersNode = {
        title: 'internalUser',
        value: '0-0',
        key: '0-0',
        children: this.assgindata,
      };

      const subVendorsNode = {
        title: 'Subs/Vendors',
        value: '0-1',
        key: '0-1',
        children: this.subVendorData,
      };
      const customerInformationNode = {
        title: 'Owners',
        value: '0-2',
        key: '0-2',
        children: this.customerInformationData,
      };

      this.combinedNodes = [internalUsersNode, subVendorsNode];
      this.combinedNodesMessaging = [internalUsersNode, subVendorsNode, customerInformationNode];
    }
  }

  // onNodeSelectionChange(selectedNodeKeys: string[]): void {
  //   if (selectedNodeKeys && selectedNodeKeys.length > 0) {
  //     const selectedNode = this.combinedNodes.find(node => node.key === selectedNodeKeys[0]);
  //     const combinedNodesMessaging = this.combinedNodesMessaging.find(node => node.key === selectedNodeKeys[0]);

  //     if (selectedNode && combinedNodesMessaging) {
  //       this.selectedNodes.push(selectedNode);
  //       this.newTodosFormFiedls.assigneeUserId = selectedNode['applicationUserId'] || '';
  //       this.newTodosFormFiedls.assigneeSubVendorId = selectedNode['subVendorId'] || '';
  //       this.messagesFormValue.applicationUserId = combinedNodesMessaging['applicationUserId'] || '';
  //       this.messagesFormValue.customerInformationId = combinedNodesMessaging['customerInformationId'] || '';
  //       this.messagesFormValue.subVendorId = combinedNodesMessaging['subVendorId'] || '';
  //     }
  //   } else {
  //     this.newTodosFormFiedls.assigneeUserId = '';
  //     this.newTodosFormFiedls.assigneeSubVendorId = '';
  //     this.messagesFormValue.applicationUserId = '';
  //     this.messagesFormValue.customerInformationId = '';
  //     this.messagesFormValue.subVendorId = '';
  //   }
  // }

  getScheduleItem() {
    this.leadOppService.getAllDataScheduleItem().subscribe(res => {
      this.scheduleItemOption = res.result
    })
  }

  loadAssignedUserGroup1() {
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      this.assignedUserGroup1 = res.result.map((AUser) => ({
        label: AUser.fullName,
        value: AUser.id.toString(),
      }));
    });
  }

  loadAssignedUserGroup2() {
    this.SubVendorService.getData().subscribe((res) => {
      this.assignedUserGroup2 = res.result.map((vendor) => ({
        label: vendor.companyName,
        value: vendor.id.toString(),
      }));
    });
  }

  optionGroups = [
    { label: 'Option Group 1', options: this.assignedUserGroup1 },
    { label: 'Option Group 2', options: this.assignedUserGroup2 },
  ];


  onAssigneeChange(assigneeing: { applicationUserId: string | null, subVendorId: string | null }) {
    this.newTodosFormFiedls['applicationUserId'] = assigneeing.applicationUserId;
    this.newTodosFormFiedls['subVendorId'] = assigneeing.subVendorId;
  }

  selectedAssignee: { applicationUserId: string | null, subVendorId: string | null } = { applicationUserId: null, subVendorId: null };

  addNewRow() {
    this.counter++;
    const newRow = {
        toDoInformationId: this.holdHeaderId || 0,
        serialNumber: this.toDoCheckListItems.length + 1,
        name: "",
        isComplete: false,
        applicationUserId: 0,
        subVendorId: 0,
        attachmentId: 0,
    };
    this.showCompleteAllButton = true;
    this.toDoCheckListItems.push({ ...newRow });
    this.toDoCheckListItems[this.toDoCheckListItems.length - 1].attachmentId = 0;
    this.addingChecklist = false;


    if (this.rowDisableNot) {
       this.disableAddChecklistButton2 = false ;
    } else {
        this.disableAddChecklistButton = true;
    }
    if (this.rowDisable) {
      this.disableAddChecklistButton2 = true;
    } else {
        this.disableAddChecklistButton2 = false;
    }


}



completeAll() {
  for (let item of this.toDoCheckListItems) {
      item.isComplete = true;
  }

  this.showCompleteAllButton = false;
  this.allChecked = true;
  this.enableCheckbox = true;

  this.disableAddChecklistButton = this.toDoCheckListItems.some(item => !item.isComplete);
}

checkAllChecked() {
  this.allChecked = this.toDoCheckListItems.every(item => item.isComplete);
  this.showCompleteAllButton = true;

  this.disableAddChecklistButton2 = this.toDoCheckListItems.some(item => !item.isComplete);
}

  removeChecklist() {
    this.toDoCheckListItems = [];
    this.showCompleteAllButton = false;
    this.addingChecklist = true;

    this.disableAddChecklistButton = false;
  }

  deleteRow(index: number) {

    this.toDoCheckListItems.splice(index, 1);
    this.newTodosFormFiedls.createToDoInformationCheckListItemRequests = this.toDoCheckListItems;

    if (this.toDoCheckListItems.length === 0) {
      this.showCompleteAllButton = false;
      this.addingChecklist = true;
    }
  }

  toggleChecklist() {

    if (this.addingChecklist) {
        this.addNewRow();
    } else {
        this.ConfirmDelete();
     }

  }

  ConfirmDelete(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Remove Checklist?',
      nzContent: 'All checklist items will be removed. The to-do must also be saved in order for this change to be made permanent.',
      nzOkDanger: true,
      nzOkText: 'Remove',
      nzCancelText: 'Cancel',
      nzModalType: 'confirm',
      nzAutofocus: 'ok',
      nzCentered: true,
      nzOnOk: () => this.removeChecklist()
    });
  }

  updateButtonState() {
    this.isAddButtonDisabled = false;
    this.addButtonClass = '';
  }

  showErrorMessage() {
    this.showAlert = true;
  }

  showErrorMessage2() {
    this.showAlert2 = true;
  }

  showAttachmentbuttons() {
    this.showRemoveButton = !this.showRemoveButton;
    this.showAddButton = !this.showAddButton;
  }

  // showAttachmentbuttonCancel() {
  //   this.showAttachmentsButton = false;
  // }

  toggleDropdown() {
    this.showRemoveButtonAssign = !this.showRemoveButtonAssign;
    this.showDropdown = !this.showDropdown;
  }

  fileList: NzUploadFile[] = [
    {
      uid: '-1',
      name: 'claimNoPhoto.gif',
      status: 'done',
      url: 'assets/claimNoPhoto.gif',
      download: false,
    },
  ];


  createMessage() {
    this.messagesFormValue = {
      headerId: this.messagingId,
      formNameId: 30006,
      comments: "",
      isViewOwner: true,
      isViewSubVendor: true,
      JobInformationId: this.localStorage.getJobId(),
      MessageFormTypeId: 10273,
      createMessagingUserNotifyParameterRequests: []
    };
    console.log('console.log newTodosFormFiedls 3', this.messagesFormValue);


    let data = { ...this.messagesFormValue };

    console.log('this.responseHeaderId ', this.responseHold);
    if (!this.holdHeaderId || this.holdHeaderId <= 0) {
      console.error('Main data not saved yet or invalid responseHold.');
      return;
    }

    const selectedNodes = this.messagesFormValue['createMessagingUserNotifyParameterRequests'] || [];
    if (selectedNodes.length === 0) {
      console.error('No selected nodes found.');
      return;
    }

    let viewerParams = data.createMessagingUserNotifyParameterRequests;
    let transformedParams = [];
    for (let i = 0; i < viewerParams.length; i += 2) {
      const applicationUserId = viewerParams[i];
      const subVendorId = viewerParams[i + 1];
      const customerInformationId = viewerParams[i + 1];
      if (applicationUserId && subVendorId && customerInformationId) {
        transformedParams.push({
          applicationUserId,
          subVendorId,
          customerInformationId
        });
      }
    }
    data.createMessagingUserNotifyParameterRequests = transformedParams;
    data.headerId = this.holdHeaderId;

    this.leadOppService.PostMessage(data).subscribe(
      (messageResponse) => {
        console.log('Message created successfully:', messageResponse);
        this.toastsService.success('Update successfully!');
      },
      (messageError) => {
        console.error('Error creating message:', messageError);
      }
    );
  }

  rfisOpenModal(responseHeaderId: number, formNameId: number) {
    const modalRef = this.modal.create({
      nzContent: NewRFIsComponent,
      nzFooter: null,
      nzData: {
        headerId: responseHeaderId,
        formNameId: formNameId,
      }
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  innerCardTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'To-Do Information';
      case 2:
        return 'Checklist';
      case 3:
        return 'Related Items';
      default:
        return '';
    }
  }

  TagClose(): void {
    this.cancel.emit();
  }

  mapClosefilter(): void {
    this.cancel.emit();
  }

  showRow() {
    this.isRowVisible = true;
    this.isRowVisible1 = false;
  }

  showRow1() {
    this.isRowVisible1 = true;
    this.isRowVisible = false;
  }

  UploadFiles(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  // CreateNewFile(): void {
  //   const modalRef = this.modal.create({
  //     nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
  //     nzFooter: null,
  //   });
  //   modalRef.componentInstance.cancel.subscribe(() => {
  //     modalRef.destroy();
  //   });
  // }

  // viewAll(): void {
  //   const modalRef = this.modal.create({
  //     nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
  //     nzFooter: null,
  //   });
  //   modalRef.componentInstance.cancel.subscribe(() => {
  //     modalRef.destroy();
  //   });
  // }

  // removeFile(file: File): void {
  //   const index = this.selectedFiles.indexOf(file);
  //   if (index !== -1) {
  //     this.selectedFiles.splice(index, 1);
  //   }
  // }

  toggleAttachments() {
    this.showAttachments = !this.showAttachments;
  }

  togglePriorityField() {
    this.showPriorityField = !this.showPriorityField;
  }

  toggleTagsField() {
    this.showTagsField = !this.showTagsField;
  }

  toggleReminderField() {
    this.showReminderField = !this.showReminderField;
  }
  // azam add error methods start
  validationStates: { [key: string]: NzStatus } = {};
  validationMessages: { [key: string]: string } = {};
  changedFields: Set<string> = new Set<string>();

  readonly validationRules = {
    title: {
      required: 'Title is Required',
      maxLength: { limit: 75, message: ' Title exceeds the limit.' }
    },
    notes: {
      required: 'Notes is Required',
      maxLength: { limit: 2000, message: 'Notes exceeds the limit.' }
    }

  };

  // Function to get the label for a field
  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      title: 'Title',
      notes: 'Notes',
      // deadline:'deadline',
      // time:'time'

    };
    return labels[field] || field;
  }


  checkErrors(validateAllFields = false) {
    this.validationStates = {};
    this.validationMessages = {};

    // Determine which fields to validate
    const fieldsToValidate = validateAllFields ? Object.keys(this.validationRules) : Array.from(this.changedFields);

    for (const field of fieldsToValidate) {
      if (this.validationRules.hasOwnProperty(field)) {
        const errorTip = this.getErrorTip(field);
        if (errorTip) {
          this.validationStates[field] = 'error';
          this.validationMessages[field] = errorTip;
        } else {
          this.validationStates[field] = null;
          this.validationMessages[field] = '';
        }
      }
    }
  }

  validateAllFields() {
    this.checkErrors(true);
  }

  validateChangedFields(field: string) {
    this.changedFields.add(field); // Add field to the changed fields set
    this.checkErrors(); // Validate changed fields
  }




  getErrorTip(field: string): string {
    const rules = this.validationRules[field];
    const input = this.newTodosFormFiedls[field] || '';

    if (rules.required && !input.trim()) {
      return `Required.`;
    }

    if (rules.maxLength && input.length > rules.maxLength.limit) {
      const excessLength = input.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }

    return ''; // No error
  }


  // Get an array of fields with errors
  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }

  // Function to handle changes in field values
  onFieldChange(field: string, value: string) {
    this.newTodosFormFiedls[field] = value;
    this.changedFields.add(field);
    this.checkErrors();
  }
// azam add error methods End


onCombineChange(selectedValues: string[]): void {
  const internalUsersValues = this.combinedNodes[0].children[0].children.map(child => child['value']);
  const subVendorsValues = this.combinedNodes[0].children[1].children.map(child => child['value']);
  const customerValues = this.combinedNodes[0].children[2].children.map(child => child['value']);
  const allChildValues = [...internalUsersValues, ...subVendorsValues, ...customerValues];
  if (selectedValues.includes('select_all')) {
    selectedValues = allChildValues;
  } else {
    if (selectedValues.includes('internal_users')) {
      selectedValues = selectedValues.concat(internalUsersValues.filter(value => !selectedValues.includes(value)));
    }
    if (selectedValues.includes('sub_vendors')) {
      selectedValues = selectedValues.concat(subVendorsValues.filter(value => !selectedValues.includes(value)));
    }
    if (selectedValues.includes('Customer_info')) {
      selectedValues = selectedValues.concat(customerValues.filter(value => !selectedValues.includes(value)));
    }
    selectedValues = selectedValues.filter(value => allChildValues.includes(value));
  }
  this.newTodosFormFiedls.createToDoParameterRequest.createToDoParameterAssignOwnersRequests = Array.from(new Set(selectedValues));
  console.log(this.newTodosFormFiedls.createToDoParameterRequest.createToDoParameterAssignOwnersRequests);
}



openSchedule(): void {
  const modalRef = this.modal.create({
    nzWidth: '47%',
    nzBodyStyle: {
      background: '#f1f4fa',
      padding: '0',
      margin: '0',
      'overflow-x': 'hidden',
    },
    nzStyle: {
      top: '22px',
    },

    nzContent: NewscheduleItemComponent,
    nzFooter: null,

  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
}
}

