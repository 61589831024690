<nz-modal
    nzWidth="70%"
    [(nzVisible)]="docunmentCreatevisible"
    [nzTitle]="Cost12"
    [nzFooter]="surveys2"
    (nzOnCancel)="createFolderModelCancel()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '500px', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
    <ng-template #Cost12> 
      <div class="row p-0 m-0">
        <h2 class="p-0 m-0 fw-bold">Folder Info </h2>
    </div>
    </ng-template>
    <div  class="cantent" *nzModalContent>

       <nz-card nzTitle="Folder Information " class="mb-3" >
             <div class="row mb-2">
              <div class="col-12">
                <label  class="sub-text">Title</label><span class="text-danger">*</span>
                  <input type="text"  [(ngModel)]="formFieldValues['title']" name="title"  class="form-control formField ">
              </div>
             </div>
             <div class="row"  *ngIf="showFields">
              <div class="col-12">
                <label    [(ngModel)]="formFieldValues['folderName']" name="folderName"  class="sub-text">Parent Folder</label>
                <nz-select [(ngModel)]="formFieldValues['folderName']" name="folderName" style="width: 100%"
                nzShowSearch>
                <nz-option *ngFor="let option of serviceCoordinator" [nzLabel]="option.title"
                    [nzValue]="option.title"></nz-option></nz-select>
              </div>
             </div>
             
       </nz-card> 

       <nz-card nzTitle="Viewing Permissions " >
             <div class="row mb-3">
              <label   [(ngModel)]="formFieldValues['isAllowSubVendorViewFolder']" name="isAllowSubVendorViewFolder"   class="sub-text">Subs/Vendor </label>
              <div class="col-12 mt-2 ">
                <label nz-checkbox  >Allow Subs/Vendor to View this folder</label>
              </div>
             </div>
             <div class="row mb-2">
              <label    [(ngModel)]="formFieldValues['isAllowOwnerViewFolder']" name="isAllowOwnerViewFolder"  class="sub-text">Owners </label>
              <div class="col-12 mt-2 ">
                <label nz-checkbox  >Allow Owner to View this folder</label>
              </div>
             </div>
          
       </nz-card> 
    </div>
    <ng-template #surveys2>
      <nz-space>
        <button   *nzSpaceItem  nz-button nzType="primary" (click)="save()">Save</button>
        <button *nzSpaceItem nz-button nzType="primary">Save & Go To</button>
      </nz-space>

    </ng-template>
</nz-modal>