import { Component, EventEmitter, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-messaging-forword-to-email',
  templateUrl: './messaging-forword-to-email.component.html',
  styleUrls: ['./messaging-forword-to-email.component.css']
})
export class MessagingForwordToEmailComponent {
  constructor(private message: NzMessageService) {}
  @Output() cancel = new EventEmitter<void>();
  ForwardingEmail=true;
  current = 0;


  // listOfOption: Array<{ label: string; value: string }> = [];
  // listOfTagOptions = [];

  inputValue='gp6xnpn2w6pkwnwbhgnnc5mr1dgpwpar_ix_ee5d6949@messages.buildertrend.net'

  copyText() {
    // Logic to copy text to clipboard
    const inputField = document.getElementById('yourInputFieldId') as HTMLInputElement;

    if (inputField) {
      inputField.select();
      document.execCommand('copy');
      this.message.success('copied!');
    }
  }

  forwordToEmailModelCancel(): void {
    this.cancel.emit();
  }
  



  }
