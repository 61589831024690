import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NZ_ICONS, NzIconModule, NzIconService } from 'ng-zorro-antd/icon';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import {  NzTreeViewModule } from 'ng-zorro-antd/tree-view';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { IconsModule } from "@progress/kendo-angular-icons";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import {DragDropModule} from '@angular/cdk/drag-drop';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzImageModule } from 'ng-zorro-antd/image';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from "@progress/kendo-angular-grid";
import { NzListModule } from 'ng-zorro-antd/list';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzResultModule } from 'ng-zorro-antd/result';
import { UserOutline, LockOutline } from '@ant-design/icons-angular/icons';
@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule,
    NzPopoverModule,
    NzSelectModule,
    NzButtonModule,
    NzPageHeaderModule,
    NzSpaceModule,
    NzDescriptionsModule,
    NzLayoutModule,
    NzIconModule,
    NzModalModule,
    NzCardModule,
    NzGridModule,
    NzTreeSelectModule,
    NzPaginationModule,
    NzPopoverModule,
    NzSelectModule,
    NzPageHeaderModule,
    NzLayoutModule,
    NzTableModule,
    NzTagModule,
    DragDropModule,
    NzBackTopModule,
    NzTabsModule,
    NzFormModule,
    NzDatePickerModule,
    NzInputModule,
    NzMessageModule,
    NzCheckboxModule,
    NzStatisticModule,
    NzEmptyModule,
    NzUploadModule,
    NzSwitchModule,
    NzInputNumberModule,
    NzDividerModule,
    NzAvatarModule,
    NzBadgeModule,
    NzTagModule,
    NzToolTipModule,
    NzMessageModule,
    NzPaginationModule,
    NzDrawerModule,
    NzFormModule,
    NzTreeViewModule,
    NzDropDownModule,
    NzAlertModule,
    NzCollapseModule,
    NzStepsModule,
    NzSliderModule,
    NzTreeModule,
    NzBreadCrumbModule,
    NzRadioModule,
    NzCalendarModule,
    NzTimePickerModule,
    NzAutocompleteModule,
    LayoutModule,
    IconsModule,
    IndicatorsModule,
    NzCarouselModule,
    GridModule,
    NzListModule,
    ScrollingModule,
    NzTypographyModule,
    NzMenuModule,
    NzProgressModule,
    NzSpinModule,
    NzImageModule,
    NzResultModule
  ],
  exports:[
    NzPopoverModule,
    NzSelectModule,
    NzButtonModule,
    NzPageHeaderModule,
    NzDescriptionsModule,
    NzLayoutModule,
    NzStatisticModule,
    NzIconModule,
    NzModalModule,
    NzCardModule,
    NzGridModule,
    NzEmptyModule,
    NzTreeSelectModule,
    NzPaginationModule,
    NzPopoverModule,
    NzSelectModule,
    NzButtonModule,
    NzPageHeaderModule,
    NzBadgeModule,
    NzSpaceModule,
    NzLayoutModule,
    NzTableModule,
    NzTagModule,
    NzModalModule,
    NzTabsModule,
    NzFormModule,
    NzDatePickerModule,
    NzInputModule,
    NzMessageModule,
    NzCardModule,
    NzCheckboxModule,
    NzUploadModule,
    NzSwitchModule,
    NzInputNumberModule,
    NzDividerModule,
    NzAvatarModule,
    NzTagModule,
    NzToolTipModule,
    NzMessageModule,
    NzPaginationModule,
    NzDrawerModule,
    NzFormModule,
    NzRadioModule,
    NzTreeViewModule,
    NzTreeSelectModule ,
    NzDropDownModule,
    NzAlertModule,
    NzCollapseModule,
    NzStepsModule,
    NzSliderModule,
    NzTreeModule,
    NzBreadCrumbModule,
    NzCalendarModule,
    NzTimePickerModule,
    NzAutocompleteModule,
    LayoutModule,
    IconsModule,
    IndicatorsModule,
    DragDropModule,
    NzCarouselModule,
    GridModule,
    NzListModule,
    ScrollingModule,
    NzTypographyModule,
    NzMenuModule,
    NzProgressModule,
    NzSpinModule,
    NzImageModule,
    NzResultModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    NzIconService,
    {
      provide: NZ_ICONS,
      useValue: [UserOutline, LockOutline]
    }
  ]

})
export class NgZorroModule { 
  constructor(iconService: NzIconService) {
    iconService.addIcon(UserOutline, LockOutline);
  }
}
