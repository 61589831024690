import { Component } from '@angular/core';

@Component({
  selector: 'app-buildertrend-marketplace',
  templateUrl: './buildertrend-marketplace.component.html',
  styleUrls: ['./buildertrend-marketplace.component.css']
})
export class BuildertrendMarketplaceComponent {

}
