import { Component, Input, OnInit } from '@angular/core';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { CreateLookupFilterRequest, FilterSearchParameter, LookUpStandardResponse, LookupFilter, LookupFilterResponse } from '../../Models/LookupModels';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { AddNewStandardFilterComponent } from '../../Modal/add-new-standard-filter/add-new-standard-filter.component';
import { FieldDataType } from 'src/app/Core/Enums/data_types';
import { CustomerInformationPreDefinedParentCodes, CustomerinformationPreDefinedCodes } from '../../Models/LookUpStandardFilterCodes';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { ApplicationModuleResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
@Component({
  selector: 'customer-contact-filter-field',
  templateUrl: './customer-contact-filter-field.component.html',
  styleUrls: ['./customer-contact-filter-field.component.css']
})
export class CustomerContactFilterFieldComponent implements OnInit {
  @Input() pageId: number;
  colName: string;
  customerContactFilterForm: FormGroup;
  createLookupFilterRequests: CreateLookupFilterRequest[] = [];
  filterResponseData: LookupFilterResponse[] = [];
  lookUpStandardResponse: LookUpStandardResponse[] = [];
  defaultStandardFilter: LookupFilterResponse;
  predefinedValuesMultiSelect: string[] = [];
  selectedFilter: number = -1;
  roleNodes: NzTreeNodeOptions[] = [];
  keywordOptions: { label: string, value: string }[] = [];
  selectedContactType: string | null = null;
  formFieldValues: any = {};
  isFormDirty: boolean = false;
  editSaveFilterFormGroup: FormGroup;
  filterSearchParameter: FilterSearchParameter;
  contactTypeNodes: NzTreeNodeOptions[] = [];
  roles: { id: string, name: string }[] = [];
  /* _____________________________________ START DIFFERENCE _________________________________________________ */

  preDefinedDataCodes = {
    standardFilter: CustomerinformationPreDefinedCodes.standardFilter,
    keyword: CustomerinformationPreDefinedCodes.keyword,
    contactType: CustomerinformationPreDefinedCodes.contactType,
  };
  /* _____________________________________ End DIFFERENCE _________________________________________________ */

  constructor(
    private fb: FormBuilder,
    public toastService: NzMessageService,
    private loadingIndicatoreService: LoadingIndicatorService,
    private modal: NzModalService,
    private LookupFilterService: LookupFilterService,
    private customerInformationService: CustomerInformationService,
    private accessLocalStorageService: AccessLocalStorageService,
    private internalUserService: InternalUserService) { }
  ngOnInit(): void {
    this.initLoad();
    this.initEditSaveFilter();
    console.log('preDefinedDataCodes :', this.preDefinedDataCodes);
  }

  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(res => {
      let statusValues = res.result.filter(x => x.code === this.preDefinedDataCodes.contactType.code);
      let columnName = res.result.filter(x => x.parentCode.trim() === CustomerInformationPreDefinedParentCodes.PARENT_CODE);
      this.keywordOptions = columnName.map(res => ({
        label: res.description,
        value: res.code.toString(),
      }));


      this.contactTypeNodes = [
        {
          title: 'Select All',
          value: 'select_all',
          key: 'select_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: statusValues.map((type) => ({
            title: type.name,
            value: type.id.toString(),
            key: type.id.toString(),
            isLeaf: true
          }))
        }
      ];


    });
    const lookupFilter: LookupFilter = {
      formNameId: this.pageId,
    };
    this.getStandardFilterByFormPageId(this.pageId);
    this.fetchData(lookupFilter);
  }

  // Proposal Status
  onContactTypeChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.contactTypeNodes[0].children.map(child => child['value']);
      this.formFieldValues['contactType'] = [...allValues];
    } else {
      const allValues = this.contactTypeNodes[0].children.map(child => child['value']);
      this.formFieldValues['contactType'] = selectedValues.filter(value => allValues.includes(value));

    }
  }

  fetchData(lookUpStandard: LookupFilter): void {
    this.LookupFilterService.postData(lookUpStandard).subscribe(
      data => {
        this.lookUpStandardResponse = data.result;
      },
      error => {
        console.error('Error fetching data:', error);
      }
    );
  }

  onParentCodeChange(codeVal: string): void {
    console.log('Selected parent code:', codeVal);
    let createCode = { code: codeVal };
    this.preDefinedDataCodes.keyword = createCode;
    console.log('After change preDefinedDataCodes :', this.preDefinedDataCodes);
  }

  openStandardFilterComponent() {
    this.createLookupFilterRequests = [];
    this.createFormFieldsInArray();
    const modalRef = this.modal.create({
      nzContent: AddNewStandardFilterComponent,
      nzFooter: null,
      nzData:
      {
        pageId: this.pageId,
        std: this.filterResponseData,
        field: this.createLookupFilterRequests
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  getStandardFilterByFormPageId(pageId: number) {
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.filterResponseData = result.result;
        this.initializeForm();
        setTimeout(() => {
          let id = this.accessLocalStorageService.getSelectedFilterId();
          this.setDefaultFieldValues(id);
        }, 100);
      }
    });
  }


  onFilterApply(): void {
    let selectedFilter = this.formFieldValues['standardFilter'];
    let isSelectedFilterStandard = this.filterResponseData.find(x => x.id === selectedFilter && x.isNotDeleted === true);
    if (isSelectedFilterStandard && isSelectedFilterStandard != undefined) {
      this.selectedFilter = 1;
    }
    else {
      this.selectedFilter = 0;
    }
    this.createFormFieldsInArray();
    this.createLookupFilterRequests[0].page = 1;
    this.createLookupFilterRequests[0].pageSize = 10;

    this.customerInformationService.getAppliedFilterData(this.createLookupFilterRequests);


  }



  resetFilter(): void {
    Object.keys(this.formFieldValues).forEach(field => {
      if (field !== 'standardFilter') {
        if (Array.isArray(this.formFieldValues[field])) {
          this.formFieldValues[field] = [];
        } else {
          this.formFieldValues[field] = '';
        }
      }
    });
  }

  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
    this.isFormDirty = false;
  }


  createFormFieldsInArray() {
    this.createLookupFilterRequests = [];
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const value = this.formFieldValues[field];
      if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 0)) {
        const newRow = {
          globalId: '00000000-0000-0000-0000-000000000000',
          companyParameterId: 0,
          lookUpStandardId: 0,
          code: code,
          meaning: '',
          valueId: 0,
          formNameId: this.pageId,
          dataTypeId: 0,
          createLookupFilterParameterRequests: [null]
        };

        const correspondingResponse = this.lookUpStandardResponse.find(response => response.code === code);
        if (correspondingResponse) {
          newRow.companyParameterId = correspondingResponse.companyParameterId;
          newRow.lookUpStandardId = correspondingResponse.id;
          newRow.dataTypeId = correspondingResponse.dataTypeId;

          if (
            correspondingResponse.dataType.code === FieldDataType.SingleLineText ||
            correspondingResponse.dataType.name === 'Single-Line Text') {
            newRow.meaning = value;
            //   this.colName = code;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.Dropdown ||
            correspondingResponse.dataType.name === 'Dropdown') {
            newRow.valueId = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.MultiSelectDropdown ||
            correspondingResponse.dataType.name === 'Multi-Select Dropdown'
          ) {
            newRow.createLookupFilterParameterRequests = value.map((item) => {
              return { valueId: item };
            });
          }
        }
        this.createLookupFilterRequests.push(newRow);
      }
    });
  }

  getDefaultFilterValue() {
    // this.defaultStandardFilter = this.filterResponseData.find(filter => filter.isSetAsDefault === true);
    // return this.defaultStandardFilter ? this.defaultStandardFilter.id : null;
    return this.accessLocalStorageService.getSelectedFilterId();
  }

  setDefaultFieldValues(standardFilterId: number): void {
    this.predefinedValuesMultiSelect = [];
    let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
    let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;


    // ....for dropdownoption fileds//
    this.keywordOptions.forEach(val => {
      lookUpFiltersField.forEach(cod => {
        if (val.value === cod.code) {
          let createCode = { code: cod.code };
          this.preDefinedDataCodes.keyword = createCode;

        }
      });
    });

    // ...//


    Object.keys(this.formFieldValues).forEach(field => {
      if (field !== 'standardFilter') {
        if (Array.isArray(this.formFieldValues[field])) {
          this.formFieldValues[field] = [];
        } else {
          this.formFieldValues[field] = '';
        }
      }
    });


    if (lookUpFiltersField.length > 0) {
      Object.keys(this.preDefinedDataCodes).forEach(field => {
        const code = this.preDefinedDataCodes[field].code;
        const fieldData = lookUpFiltersField.find(filter => filter.code === code);
        if (fieldData != undefined) {
          if (
            fieldData.dataType.code === FieldDataType.SingleLineText) {
            this.formFieldValues[field] = fieldData.meaning;
            // /...
            this.colName = code;
          }
          if (fieldData.dataType.code === FieldDataType.Dropdown) {
            this.formFieldValues[field] = fieldData.id;
          }
          if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown) {
            let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
            this.predefinedValuesMultiSelect = multiSelectValues.map(String);
            this.formFieldValues[field] = this.predefinedValuesMultiSelect;
          }
        }
      });
    } else {
      console.log("lookUpFiltersField is null ", lookUpFiltersField);
    }
  }

  onChangeStandardFilter($event: number): void {
    this.selectedFilter = -1;
    const defaultFIlterId = this.getDefaultFilterValue();
    this.accessLocalStorageService.setSelectedFilterId($event);
    let id = this.accessLocalStorageService.getSelectedFilterId();
    this.setDefaultFieldValues(id);
    this.customerInformationService.setSelectedFilterId(id);
    this.filterSearchParameter = {
      CompanyParameterId: 1,
      FormNameId: this.pageId,
      LookUpFilterId: $event,
      page: 1,
      pageSize: 10
    };

    console.log("Filter Dara", this.filterSearchParameter);

    if (defaultFIlterId !== $event) {
      this.customerInformationService.userAppliedFilter(true);
    } else {
      this.customerInformationService.userAppliedFilter(false);
    }
    this.customerInformationService.getAllCustomerinformationByFilter(this.filterSearchParameter);

  }

  private initEditSaveFilter(): void {
    this.editSaveFilterFormGroup = this.fb.group({
      globalId: ['00000000-0000-0000-0000-000000000000'],
      companyParameterId: [0],
      code: ['', Validators.required],
      name: ['', Validators.required],
      description: [''],
      meaning: [''],
      formNameId: [0],
      dataTypeId: [],
      isShareThisFilter: [true],
      isSetAsDefault: [false],
      createLookupFilterRequests: [[]],
    });
  }

  editSaveFilterData(): void {
    let selected_Filter_Id = this.formFieldValues['standardFilter'];
    let getSelectedFilterValue = this.filterResponseData.find(filter => filter.id === selected_Filter_Id);
    this.createFormFieldsInArray();
    this.editSaveFilterFormGroup.controls['name'].setValue(getSelectedFilterValue.name);

    if (this.editSaveFilterFormGroup.get('name')?.invalid) {
      this.toastService.error('Name field is required');
      return;
    }
    this.editSaveFilterFormGroup.controls['companyParameterId'].setValue(getSelectedFilterValue.companyParameterId);
    this.editSaveFilterFormGroup.controls['code'].setValue(getSelectedFilterValue.code);
    this.editSaveFilterFormGroup.controls['formNameId'].setValue(getSelectedFilterValue.formNameId);
    this.editSaveFilterFormGroup.controls['dataTypeId'].setValue(getSelectedFilterValue.dataTypeId);
    this.editSaveFilterFormGroup.controls['globalId'].setValue(getSelectedFilterValue.globalId);
    this.editSaveFilterFormGroup.controls['createLookupFilterRequests'].setValue(this.createLookupFilterRequests);
    this.editSaveFilterFormGroup.controls['isSetAsDefault'].setValue(getSelectedFilterValue.isSetAsDefault);
    this.LookupFilterService.saveStandardFilterData(this.editSaveFilterFormGroup.value)
      .subscribe(
        (res: any) => {
          console.log(res.result);
          setTimeout(() => {
            this.loadingIndicatoreService.hide();
            this.toastService.success('Filter saved successfully');
            this.getStandardFilterByFormPageId(this.pageId);
          }, 2000);
        },
        (error) => {
          console.error('Error:', error);
          this.loadingIndicatoreService.hide();
          if (error.status === 500) {
            this.toastService.error('Internal server error. Please try again later.');
          } else {
            this.toastService.error('An error occurred. Please try again.');
          }
        }
      );
  }

  /* _____________________________________ START DIFFERENCE _________________________________________________ */
  initializeForm(): void {
    this.formFieldValues = {
      standardFilter: this.getDefaultFilterValue(),
      keyword: '',
      contactType: [] = [], // Ensure contactType FormControl is initialized
    };
  }
  /* _____________________________________ End DIFFERENCE _________________________________________________ */






  //   showContactTypes() {
  //   const formNameId = 2;
  //   this.internalUserService.showCode(formNameId).subscribe(
  //     (res: ResponseModelArray<ApplicationUserResponse>) => {

  //       const contactTypeData = res.result.filter(item => item.code === CustomerinformationPreDefinedCodes.contactType.code);

  //       this.contactTypeNodes = contactTypeData.map(contactType => ({
  //         title: contactType.description,
  //         value: contactType.id,
  //         key: contactType.id.toString(),
  //         isLeaf: true
  //       }));

  //     },
  //     (error) => {
  //       console.error('Error fetching contact types:', error);
  //     }
  //   );
  // }
}











