export enum TreeMenu {
    Sales = 'Sales',
    Jobs = 'Jobs',
    Project_Management = 'Project Management',
    Files = 'Files',
    Messaging = 'Messaging',
    Financial = 'Financial',
    Frequently_Added = 'Plus Pages',
    Groups = 'User Setting',
    Education = 'Education',
    Account = 'Account'
}


export enum SubMenuName {
    NewJobFromScratch = 'New Job From Scratch',
    NewJobFromTemplate = 'New Job From Template',
    JobInfoJob = 'Job Info',
    JobPriceSummary = 'Job Price Summary'
  }
  