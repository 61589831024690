<div
  class="row mt-5"
  style="
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  "
>
  <div class="col" style="text-align: center">
    <span>
      <img src="assets/Empty-SVGs/Financial/Owner-invoice/invoice.svg" >
    </span>
    <div class="row">
      <div class="col mt-2">
        <h2>Effortlessly Manage Invoicing</h2>
      </div>
    </div>
    <div class="col mt-3 text-secondary">
      <p>
        Create and send invoices to your clients quickly and efficiently.
        <br />for more reliable revenue planning.
      </p>
    </div>
    <div
      class="col mt-4"
      style="display: flex; justify-content: center; align-items: center"
    >
    <nz-space>
      <button
      class="rounded-1"
       *nzSpaceItem
        nz-button
        nzType="default"
      >
      <i class="bi bi-box-arrow-up-right me-2"></i>  Learn How
      </button>

      <button *nzSpaceItem nz-button nzType="primary" class="rounded-1">
        Ad on Ivoice
      </button>
    </nz-space>

    </div>

  </div>
</div>
