import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalBillsPosBudgetService {

  constructor() { }

  private showModalSourceGetById = new Subject<void>();
  showModal$ = this.showModalSourceGetById.asObservable();

  // Expose a public method to trigger the next on the subject
  triggerSelectionsGetById() {
    this.showModalSourceGetById.next();
  }
}
