import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NzTreeNodeOptions } from 'ng-zorro-antd/core/tree/public-api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { RoleTypeService } from 'src/Service/Internaluser/role-type.service';
import { FieldDataType } from 'src/app/Core/Enums/data_types';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { AddNewStandardFilterComponent } from '../../Modal/add-new-standard-filter/add-new-standard-filter.component';
import { CreateLookupFilterRequest, LookupFilterResponse, LookUpStandardResponse, LookupFilter, LookupNameSetupResponse } from '../../Models/LookupModels';
import { LeadActivityActivityCalanderPreDefinedCodes } from '../../Models/LookUpStandardFilterCodes';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { CustomerInformationService } from 'src/Service/customer-information.service';

@Component({
  selector: 'app-lead-opportunity-activity-calander-filter-field',
  templateUrl: './lead-opportunity-activity-calander-filter-field.component.html',
  styleUrls: ['./lead-opportunity-activity-calander-filter-field.component.css']
})
export class LeadOpportunityActivityCalanderFilterFieldComponent {
  
  selectedValue: any;
  formFieldValues: any = {};
  listOfOption: Array<{ label: string; value: string }> = [];
  listOfTagOptions = [];
  @Input() pageId: number;
  leadopportunitiesActivityCalanderFilterForm: FormGroup;
  createLookupFilterRequests: CreateLookupFilterRequest[] = [];
  filterResponseData: LookupFilterResponse[] = [];
  selectedFilter: number = -1;
  lookUpStandardResponse: LookUpStandardResponse[] = [];
  defaultStandardFilter: LookupFilterResponse;
  predefinedValuesMultiSelect: string[] = [];
  preDefinedDataCodes = {
    activityStatus: LeadActivityActivityCalanderPreDefinedCodes.activityStatus,
    LeadStatus: LeadActivityActivityCalanderPreDefinedCodes.LeadStatus,
    activityType: LeadActivityActivityCalanderPreDefinedCodes.activityType,
  };
  constructor(
    private roleService: RoleTypeService,
    private fb: FormBuilder,
    public toastService: NzMessageService,
    private cdr: ChangeDetectorRef,
    private loadingIndicatoreService: LoadingIndicatorService,
    private modal: NzModalService,
    private LookupFilterService: LookupFilterService,
    private customerInformationService: CustomerInformationService,

    // private internalUserService: InternalUserService,
  ) { }
  ngOnInit(): void {
    this.initLoad();
    this.ForCombo();
    // this.showContactTypes()
    const children: Array<{ label: string; value: string }> = [];
    for (let i = 10; i < 36; i++) {
      children.push({ label: i.toString(36) + i, value: i.toString(36) + i });
      this.listOfOption = children;
    }
  }
  nodes: NzTreeNodeOptions[] = [
    {
      title: 'Check All',
      value: '0-all',
      key: '0-all',
      isLeaf: true,
      checked: false
    },
    {
      title: 'Node1',
      value: '0-0',
      key: '0-0',
      isLeaf: true
    },
    {
      title: 'Node2',
      value: '0-1',
      key: '0-1',
      isLeaf: true
    },
    {
      title: 'Node3',
      value: '0-2',
      key: '0-2',
      isLeaf: true
    },
    {
      title: 'Node4',
      value: '0-3',
      key: '0-3',
      isLeaf: true
    },
    {
      title: 'Node5',
      value: '0-4',
      key: '0-4',
      isLeaf: true
    },
    {
      title: 'Node6',
      value: '0-5',
      key: '0-5',
      isLeaf: true
    },];


    Filter: boolean = false
    closeFilter() {
      this.Filter = false
    }
  




 
  fetchData(lookUpStandard: LookupFilter): void {
    this.LookupFilterService.postData(lookUpStandard).subscribe(
      data => {
        this.lookUpStandardResponse = data.result;
      },
      error => {
        console.error('Error fetching data:', error);
      }
    );
  }

  onFilterApply(): void {
    let selectedFilter = this.formFieldValues['standardFilter'];
    let isSelectedFilterStandard = this.filterResponseData.find(x => x.id === selectedFilter && x.isNotDeleted === true);
    if(isSelectedFilterStandard && isSelectedFilterStandard != undefined ){
      this.selectedFilter = 1;
    }
     else{
      this.selectedFilter = 0;
    }
    this.createFormFieldsInArray();

  }
  openStandardFilterComponent() {
    this.createFormFieldsInArray();
    const modalRef = this.modal.create({
      nzContent: AddNewStandardFilterComponent,
      nzData: 
      { 
        pageId: this.pageId,
        std : this.filterResponseData ,
        field: this.createLookupFilterRequests
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  getStandardFilterByFormPageId(pageId: number) {
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.LookupFilterService.$getStandardFilter.subscribe(result =>{
      if(result){
        this.filterResponseData = result.result;
        console.log("RESULT STANDARD FILTER : ", this.filterResponseData);
        this.initializeForm();
        this.setDefaultFieldValues(this.defaultStandardFilter.id);
      }
    });
  }
  // initializeForms(): void {
  //   this.leadopportunitiesActivityCalanderFilterForm = this.fb.group({
  //     standardFilter: new FormControl(this.getDefaultFilterValue()),
  //     keyWord: [null],
  //     activityStatus: [[null]],
  //     leadStatus: [[null]],
  //     activityType: [[null]],
  //     assignedUser: [[null]],
  //     includeAttendees: [null]
  //   });
  // }

  
  initializeForm(): void {
    this.formFieldValues = {
      standardFilter: this.getDefaultFilterValue(),
      keyWord: '',
      activityStatus: [] = [],
      leadStatus: [] = [],
      activityType: [] = [],
      assignedUser: [] = [],
      includeAttendees: false,
    };
  }




  onSubmit(): void {
    this.createFormFieldsInArray();
  }
  resetFilter(): void {
    const formControlKeys = Object.keys(this.leadopportunitiesActivityCalanderFilterForm.controls).slice(1);
    formControlKeys.forEach(field => {
      this.leadopportunitiesActivityCalanderFilterForm.get(field).reset();
    });
  }
  createFormFieldsInArray() {
    this.createLookupFilterRequests = [];
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const value = this.leadopportunitiesActivityCalanderFilterForm.get(field).value;
      if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 1 && value[0] === null)) {
        const newRow = {
          companyParameterId: 0,
          lookUpStandardId: 0,
          code: code,
          meaning: '',
          valueId: 0,
          formNameId: this.pageId,
          dataTypeId: 0,
          createLookupFilterParameterRequests: [null]
        };
        const correspondingResponse = this.lookUpStandardResponse.find(response => response.code === code);
        if (correspondingResponse) {
          newRow.companyParameterId = correspondingResponse.companyParameterId;
          newRow.lookUpStandardId = correspondingResponse.id;
          newRow.dataTypeId = correspondingResponse.dataTypeId;
          if (
            correspondingResponse.dataType.code === FieldDataType.SingleLineText ||
            correspondingResponse.dataType.name === 'Single-Line Text') {
            newRow.meaning = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.Dropdown ||
            correspondingResponse.dataType.name === 'Dropdown') {
            newRow.valueId = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.MultiSelectDropdown ||
            correspondingResponse.dataType.name === 'Multi-Select Dropdown'
          ) {
            newRow.createLookupFilterParameterRequests = value.map((item, index) => {
              return { valueId: item };
            });
          }
          // Assign other properties similarly based on your data structure
        }
        this.createLookupFilterRequests.push(newRow);
      }
    });
  }
  getDefaultFilterValue() {
    this.defaultStandardFilter = this.filterResponseData.find(filter => filter.isSetAsDefault === true);
    return this.defaultStandardFilter ? this.defaultStandardFilter.id : null;
  }
  setDefaultFieldValues(standardFilterId: number): void {
    this.predefinedValuesMultiSelect = [];
    let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
    let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;
    // Get all form control keys except the first one
    const formControlKeys = Object.keys(this.leadopportunitiesActivityCalanderFilterForm.controls).slice(1);
    // Reset all form controls except the first one
    formControlKeys.forEach(field => {
      this.leadopportunitiesActivityCalanderFilterForm.get(field).reset(); // Reset the form control
    });
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const fieldData = lookUpFiltersField.find(filter => filter.code === code);
      if (fieldData != undefined) {
        if (
          fieldData.dataType.code === FieldDataType.SingleLineText) {
          this.leadopportunitiesActivityCalanderFilterForm.get(field).setValue(fieldData.meaning);
        }
        if (fieldData.dataType.code === FieldDataType.Dropdown) {
          this.leadopportunitiesActivityCalanderFilterForm.get(field).setValue(fieldData.id);
        }
        if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown) {
          let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
          this.predefinedValuesMultiSelect = multiSelectValues.map(String);
          this.leadopportunitiesActivityCalanderFilterForm.get(field).setValue(this.predefinedValuesMultiSelect);
        }
      }
    });
  }
  onChangeStandardFilter($event: number): void {
    this.setDefaultFieldValues($event);
  }

  activityStatusNodes : NzTreeNodeOptions[]=[];
  leadStatusNodes : NzTreeNodeOptions[]=[];
  activityTypeNodes : NzTreeNodeOptions[]=[];
  roles: { id: string, name: string }[] = [];



  
  // showContactTypes() {
  //   const formNameId = 20002;
  //   this.internalUserService.showCode(formNameId).subscribe(
  //     (res: ResponseModelArray<LookupNameSetupResponse>) => {
  //       // Filter out only the contact type data
  //       const activityStatusData = res.result.filter(item => item.code === LeadActivityActivityCalanderPreDefinedCodes.activityStatus.code);
  //       const leadStatusData = res.result.filter(item => item.code === LeadActivityActivityCalanderPreDefinedCodes.LeadStatus.code);
  //       const activityTypeData = res.result.filter(item => item.code === LeadActivityActivityCalanderPreDefinedCodes.activityType.code);
  
  //       this.activityStatusNodes = activityStatusData.map(activityStatus => ({
  //         title: activityStatus.description, 
  //         key: activityStatus.code,
  //         isLeaf: true 
  //       }));
  //       this.leadStatusNodes = leadStatusData.map(leadStatus => ({
  //         title: leadStatus.description, 
  //         key: leadStatus.code,
  //         isLeaf: true 
  //       }));
  //       this.activityTypeNodes = activityTypeData.map(activityType => ({
  //         title: activityType.description, 
  //         key: activityType.code,
  //         isLeaf: true 
  //       }));
        
  //     },
  //     (error) => {
  //       console.error('Error fetching contact types:', error);
  //     }
  //   );
  // }

  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(res =>{
      let activityStatusValues = res.result.filter(x => x.code === this.preDefinedDataCodes.activityStatus.code);
      let leadStatusValues = res.result.filter(x => x.code === this.preDefinedDataCodes.LeadStatus.code);
      let activityTypeValues = res.result.filter(x => x.code === this.preDefinedDataCodes.activityType.code);



      this.activityStatusNodes = activityStatusValues.map(activityStatus => ({
        title: activityStatus.name,
        value: activityStatus.id.toString(),
        key: activityStatus.id.toString(),
        isLeaf: true
      }));
      this.leadStatusNodes = leadStatusValues.map(leadStatus => ({
        title: leadStatus.name,
        value: leadStatus.id.toString(),
        key: leadStatus.id.toString(),
        isLeaf: true
      }));
      this.activityTypeNodes = activityTypeValues.map(activityType => ({
        title: activityType.name,
        value: activityType.id.toString(),
        key: activityType.id.toString(),
        isLeaf: true
      }));
      
    });
    const lookupFilter: LookupFilter = {
      formNameId: this.pageId,
    };
    this.getStandardFilterByFormPageId(this.pageId);
    this.fetchData(lookupFilter);
  }

  assignedNodes: NzTreeNodeOptions[]=[];
  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe(res => {
      const assignedUser = res.result; 
      
      this.assignedNodes = assignedUser.map(AU => ({
        title: AU.fullName,
        key: AU.id.toString(),
        isLeaf: true,
      }));
    });

  }


}
