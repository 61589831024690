import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
export class GlobalIdColorAssignment {
  globalId: string;
  colorId: number;
  
}

@Injectable({
  providedIn: 'root'
})
export class LeadActivitiesColorAssignService {

  private base_Url: string = environment.apiUrl + 'LeadActivity/Update-AssignDisplayColorAsync';

  constructor(private http: HttpClient) {}

  leadActivitiesColorReAssign(colorAssignerData: GlobalIdColorAssignment[]): Observable<ResponseModelArray<any>> {
    const url = this.base_Url;

    return this.http.post<ResponseModelArray<any>>(url, colorAssignerData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
}
