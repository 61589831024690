import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ModalButtonOptions, NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { OwnerInvoicesSetupResponse } from 'src/Models/CompanySettings/Schedule/PhaseSetup/OwnerView';
import { OwnerViewService } from 'src/Service/CompanyInFormation/ScheDule/owner-view.service';

@Component({
  selector: 'app-owner-invoices',
  templateUrl: './owner-invoices.component.html',
  styleUrls: ['./owner-invoices.component.css']
})
export class OwnerInvoicesComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  ownerInvoiOpen= true;
  @Input() newVariable:OwnerInvoicesSetupResponse;
  formFieldsValue: any = {}
  selectedValue = null;
  editorValue: string = '';
  checked14 = false;
  checked4 = false;
  checked9 = false;
  checked8 = false;
  checked7 = false;
  checked1 = false;
  checked5 = false;
  checked24 = false;
  checked34 = false;
  checked44 = false;
  dataset: any;
ownerInvoicefooter: string|TemplateRef<{}>|ModalButtonOptions<any>[];
notifyOwnersAfterOwnerInvoiceDeadlinesHavePassed: any;

  
  constructor(  private modal: NzModalService,
    private ownerser: OwnerViewService,
    private toastser: NzMessageService,
    @Inject(NZ_MODAL_DATA) public patchOnwer: any

  )
  {
    console.log('patchOnwer',this.newVariable);
    
  }

  ngOnInit(): void {

    this.fetchOwnerInvoiceData();

    this.formFieldsValue = {
      companyParameterId: 1,
      ownerInvoicePrefix: "",
      notifyInternalUsersOfUpcomingOwnerInvoiceDeadlines: false,
      daysBeforeInternalUsers: 0,
      notifyOwnersOfUpcomingOwnerInvoiceDeadlines: false,
      daysBeforeOwnerUpcomming: 0,
      daysBeforeOwnerInvoice: 0,
      notifyOwnersAfterOwnerInvoiceDeadlinesHavePassed: false,
      description: ""
    }
    
  }

  closeOwnerInvoices(){
    this.cancel.emit();
  }

  SaveButton(){
    console.log(this.formFieldsValue);
    this.ownerser.postCompanyOwner(this.formFieldsValue).subscribe((response) =>{
      this.newVariable = response.result;
      this.toastser.success('Owner Save Success');
      this.cancel.emit();
      this.ownerInvoiOpen =  false;
      // this.formFieldsValue = []
    })
  }

  fetchOwnerInvoiceData() {
    this.ownerser.getAllOwnerInvoice().subscribe(
      (response) => {
        this.dataset = response.result;
        let setDataSalse =  this.dataset
        this.formFieldsValue.ownerInvoicePrefix = setDataSalse.ownerInvoicePrefix || '',
        this.formFieldsValue.description = setDataSalse.description || '',
        this.formFieldsValue.notifyInternalUsersOfUpcomingOwnerInvoiceDeadlines = setDataSalse.notifyInternalUsersOfUpcomingOwnerInvoiceDeadlines,
        this.formFieldsValue.notifyOwnersOfUpcomingOwnerInvoiceDeadlines =  setDataSalse.notifyOwnersOfUpcomingOwnerInvoiceDeadlines || '',
        this.formFieldsValue.notifyOwnersAfterOwnerInvoiceDeadlinesHavePassed =  setDataSalse.notifyOwnersAfterOwnerInvoiceDeadlinesHavePassed;
        this.formFieldsValue.daysBeforeInternalUsers = setDataSalse.daysBeforeInternalUsers || '',
        this.formFieldsValue.daysBeforeOwnerUpcomming = setDataSalse.daysBeforeOwnerUpcomming || '',
        this.formFieldsValue.daysBeforeOwnerInvoice = setDataSalse.daysBeforeOwnerInvoice || ''
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }

  onCheckboxChange14() {
    this.checked14 = !this.checked14;
  }

  onCheckboxChange24(){
    this.checked24 = !this.checked24;
  }

  onCheckboxChange34(){
    this.checked34 = !this.checked34;
  }

  onCheckboxChange44(){
    this.checked44 = !this.checked44;
  }

  autoAdjustTextAreaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; // Set the height with a maximum of 200px
  }
  isInputVisible: boolean = false;

  toggleVisibility() {
    this.isInputVisible = true;
  }
  customfieldinfo:boolean = false;
  CustomFieldInfo(){
    this.customfieldinfo = true;
  }
  handleCancel2(): void {
    this.customfieldinfo = false;
  }
  
 
}
