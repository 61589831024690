<nz-modal
  nzWidth="55%"
  [(nzVisible)]="selectionModelhandle"
  [nzTitle]="istoptionlTitle"
  [nzFooter]="istoptionFooter"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '540px', 'overflow-y': 'auto' }"
  [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="importSelectionCancelEvent()">
    <ng-template #istoptionlTitle>
      <div class="row p-0 m-0">
        <h4 class="">
        Import Data Form Template 
        </h4>
      </div>
    </ng-template>

    <div class="content" *nzModalContent>

      <nz-card [nzBorderless]="false"  [nzTitle]="ImportOptions" >
        <ng-template #ImportOptions>
          <h4>Import Options</h4>
        </ng-template>
        <div class="row">
          <div class="col-12">
            <h3>Source</h3>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <label class="para-lable fs-10">Source Template</label>
            <div class="input-group">
              <nz-select class="rounded-1" nzShowSearch [lang]="true" nzAutoFocus="true" 
                nzPlaceHolder="   -- Choose a Template --   " [(ngModel)]="selectedValue">
                  <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                  <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                  <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
              </nz-select>
          </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <h3 class="fw-bold" style="font-size: 18px;">Items to Copy</h3>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <h6 class="para-lable fs-10">Project Management</h6>
          </div>
        </div>
        <div  class="row mt-2">
          <div   class="col-12">
             <label (click)="dateShow()" nz-checkbox  >Schedule</label>
             <div *ngIf="importDataShowDate" class="row ms-4 mt-3 mb-2">
              <div class="col-3">
            <label class="para-lable fs-10">New Start Date</label>
            <nz-date-picker class="rounded-1" [(ngModel)]="date" (ngModelChange)="onChange($event)"></nz-date-picker>
              </div> 
             </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
             <label nz-checkbox >To-Do's</label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
             <label (click)="showSelectFeild()" nz-checkbox >Selections</label>
             <div *ngIf="importShowSelectFeild" class="row ms-3 mt-2">
              <div class="col-3">
                <label class="para-lable fs-10">New DeadLine</label>
                <input type="text" class="formField form-control">
              </div>
             </div>
          <div *ngIf="importShowSelectFeild" class="row ms-3 mt-3">
            <div class="col-12">
             <label  nz-checkbox >Reset approved choices to pending</label>
            </div>
          </div>
          <div *ngIf="importShowSelectFeild" class="row ms-5 mt-3">
            <div class="col-12">
             <label nz-checkbox >Apply any pending line item updates</label>
            </div>
          </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
             <label nz-checkbox >Specifications</label>
          </div>
        </div>
        <hr>
        <div class="row mt-4 mb-2">
          <div class="col-12">
            <h6 class="para-lable fs-10">Files</h6>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <label (click)="showDocumentFeild()" nz-checkbox >Document Folders</label>
            <div *ngIf="importShowDocumentFeild" class="row ms-3 mt-3">
              <div class="col-12">
               <label  nz-checkbox >Include Files</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label nz-checkbox >Photo Folders</label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label (click)="showVideoFeild()" nz-checkbox >Video Folders</label>
            <div  *ngIf="importShowVideoFeild" class="row ms-3 mt-3">
              <div class="col-12">
               <label nz-checkbox >Include Files</label>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row mt-4 mb-1">
          <div class="col-12">
            <h6 class="para-lable fs-10">Messaging</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label nz-checkbox >Surveys</label>
          </div>
        </div>
        <hr>
        <div class="row mt-4 mb-2">
          <div class="col-12">
            <h6 class="para-lable fs-10">Financial</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label nz-checkbox >Onwer Invoices</label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label nz-checkbox >Bid Packages</label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label (click)="showEstimateFeild()" nz-checkbox >Estimates</label>
            <div *ngIf="importShowEstimateFeild" class="row mt-3 ms-3">
              <div class="col-12">
                <div><label nz-checkbox >Remove existing estimates items</label></div>
                <div class="ms-4"><p>Any existing item tied to bids, Selection, or Allowances will be not removed.</p></div>
              </div>
            </div>
            <div *ngIf="importShowEstimateFeild" class="row mt-2 ms-3">
              <div class="col-12">
                <div><label nz-checkbox >Keep Introductory and Closing Tags in Jobs proposal Details.
                   Text from this template will  be added to the existing text.</label></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label (click)="showPurchaseFeild()" nz-checkbox >Purchase Orders</label>
            <div *ngIf="importShowPurchaseFeild" class="row ms-3 mt-3">
              <div class="col-12">
               <label nz-checkbox >Link Bills</label>
              </div>
            </div>
            <div *ngIf="importShowPurchaseFeild" class="row ms-3 mt-4">
              <div class="col-12">
               <label nz-checkbox >Include PO attachments</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label nz-checkbox >Bills</label>
          </div>
        </div>
      </nz-card>
 
    </div>

    <ng-template #istoptionFooter>
      <div class="mt-2 align-items-center">
        <button nz-button nzType="primary" class="rounded-1 me-0"
          >Import</button>
      </div>
    </ng-template>

</nz-modal>