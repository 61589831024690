import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { UpdatePayload } from 'src/Models/CustomerInformation';
import { VarianceCode, VarianceCodeCategoryResponse } from 'src/Models/VarianceCode/varianceCode.Model';
import { ApiResponseModel } from 'src/Models/apiResponse.model';
import { ResponseModel, ResponseModelArray } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class VarianceCodeService {
  private varianceCode: string = environment.apiUrl + 'VarianceCode/getAll-Category';
  private varianceSubCode = environment.apiUrl + 'VarianceCode/getAll-SubCodeOf'
  private varianceCategoryUrl: string = environment.apiUrl + 'VarianceCode/Create-VarianceCostCode';


  constructor(private http: HttpClient) { }

  postData(data: any): Observable<any> {
    return this.http.post<any>(this.varianceSubCode, data).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  getData(): Observable<ApiResponseModel[]> {
    return this.http.get<ApiResponseModel[]>(`${this.varianceSubCode}getAll`);
  }

  updateData(data: UpdatePayload): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    const options = { headers: new HttpHeaders(headers) };
    return this.http.put<any>(`${this.varianceSubCode}`, data, options);
  }

  deleteData(globalId: string): Observable<any> {
    return this.http.delete<any>(`${this.varianceSubCode}?globalId=${globalId}`).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  getDataId(id: any): Observable<any> {
    return this.http.get<any>(`${this.varianceSubCode}getBy-Id?id=${id}`);
  }

  getDataGlobalId(globalId: string): Observable<any> {
    return this.http.get<any>(`${this.varianceSubCode}getBy-globalId=${globalId}`);
  }

  getSubCodeData(): Observable<ResponseModelArray<VarianceCode>> {
    return this.http.get<ResponseModelArray<VarianceCode>>(`${this.varianceSubCode}`);
  }

  getCategoryData(): Observable<ResponseModelArray<VarianceCode>> {
    return this.http.get<ResponseModelArray<VarianceCode>>(`${this.varianceCode}`);
  }

  postVarianceCategory(varianceCategoryFormData: VarianceCodeCategoryResponse): Observable<ResponseModel<VarianceCodeCategoryResponse>> {
    const apiUrl = `${this.varianceCategoryUrl}`;
    return this.http.post<any>(apiUrl, varianceCategoryFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
}
