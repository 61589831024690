<nz-layout>
  <nz-sider class="border" nzCollapsible [nzCollapsedWidth]="10" [(nzCollapsed)]="isCollapsed" [nzWidth]="280"
    [nzTrigger]="null">
    <div class="row">
      <app-side-menu-bar [isAllJobOption]="true" (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"></app-side-menu-bar>
    </div>
  </nz-sider>


  <nz-layout>
  <nz-page-header>
    <nz-page-header-title>
      <nz-breadcrumb>
        <nz-breadcrumb-item>{{localStorage.getJobName()}}</nz-breadcrumb-item></nz-breadcrumb>
      <h1>
        Site Diaries
      </h1>
    </nz-page-header-title>
    <nz-page-header-extra>
      <div nz-col nzSpan="12" class="text-end">
        <button nz-popover [nzPopoverContent]="Summary" nzPopoverPlacement="left" (click)="DailyLogSetting()" nz-button
          nzType="default" class="ms-2 rounded-1"><span nz-icon nzType="setting" nzTheme="outline"></span></button>
        <ng-template #Summary>
          <div class="rounded-2">
            <p style="font-size: 13px;">
              Daily Log Settings
            </p>
          </div>
        </ng-template>
        <nz-divider nzType="vertical" class="divder"></nz-divider>
        <button nz-button nzType="default" class="ms-2 rounded-1">
          <i class="fas fa-graduation-cap"></i>&nbsp;&nbsp;Learn More</button>
        <button nz-button nzType="default" class="ms-2 rounded-1">Print</button>
        <button nz-button nzType="default" class="ms-2 rounded-1"
          (click)="openFilter(PROJECT_MANG_SITE_DIAR_DAILY_L, PAGE_ID)">
          <i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter</button>
        <button (click)="newDailyLogModelOpen('SiteDiaries')" nz-button nzType="primary" class="ms-2 button-Daily">New
          Daily Log</button>

      </div>

    </nz-page-header-extra>
  </nz-page-header>
  <!-- <div class="container-fuild mb-4 m-3">
    <div class="ant-row div-set" style="row-gap: 0px;" *ngIf="selectionResponse && selectionResponse.length">
        <div class="ant-col ant-col-xs-24 ant-col-md-18" *ngFor="let data of selectionResponse">
          
            <h2 nz-typography class="text-sites">{{ data?.date }}</h2>
            <h2 nz-typography class="text-sites-name">{{ data?.title }}</h2>
             <div class="ListViewJobname mt-2">{{ data?.notes }}</div>
        </div>
        <div class="ant-col text-right ant-col-xs-24 ant-col-md-6 text-end">
             <div class="ListViewJobname mt-2  text-end"><img nz-popover [nzPopoverContent]="Summary" nzPopoverPlacement="top" class="me-2" src="assets/ProjectManagerSitesDiaries/map-marker-default.svg" alt="Open in Maps" style="height: 18px; width: 16px;">Created by Muhammad Yousuf</div>
             <ng-template #Summary>
              <div class="rounded-2">
                <p style="font-size: 13px;">
                  Click to view details
                </p>
              </div>
            </ng-template>
             <div class="ViewableBy">
                <span class="padding-right-xs"><span style="font-size: 12px; color: rgb(78, 85, 95);">Viewable by</span></span>
                <img class="ViewableByIcon" src="assets/ProjectManagerSitesDiaries/ownerIconDisabled.png" alt="ownerIconDisabled">
                <img class="ViewableByIcon" src="assets/ProjectManagerSitesDiaries/subIconDisabled.png" alt="subIconDisabled">
                <img class="ViewableByIcon" src="assets/ProjectManagerSitesDiaries/userIcon.png" alt="userIcon">
            </div>
        </div>
        <div class="ant-col ant-col-xs-24 ant-col-md-18 mt-2">
            <button (click)="CommentModelOpen()" nz-button nzType="link" class="text-primary fs-6"><i
                class="bi bi-chat-dots-fill text-primary me-2 fs-5"></i><span class="fs-6">Add Comment</span></button>
                <button [nzTrigger]="'click'" nz-button class="text-primary border-0 me-2 ms-3 mt-2" nz-dropdown [nzDropdownMenu]="menu4">
                    <span class="text-primary">1 Related To-Do</span>
                    <span nz-icon nzType="down"></span>
              </button>
                  <nz-dropdown-menu #menu4="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item>menu4 1st menu item</li>
                      <li nz-menu-item>menu4 2nd menu item</li>
                      <li nz-menu-item>menu4 3rd menu item</li>
                    </ul>
                  </nz-dropdown-menu>
        </div>
        <div class="ant-col text-right ant-col-xs-24 ant-col-md-6">
        </div>
    </div>




    </div> -->

    <nz-content class="custom-content">
      <ng-container *ngIf="selectionResponse?.length > 0">
    <div class="container-fuild  m-3">
      <div class="ant-row div-set" style="row-gap: 0px;" *ngIf="selectionResponse && selectionResponse.length">
        <div class="ant-col ant-col-xs-24 ant-col-md-18">
          <div class="card mt-2" *ngFor="let data of selectionResponse">
            <h2 nz-typography class="text-sites mt-2 ms-4"(click)="EditSiteD(data)">{{ data?.date | date: 'MMM d, yyyy' }}</h2>

            <h2 nz-typography class="text-sites-name ms-4">{{ data?.title }}</h2>
            <div class="ListViewJobname mt-2 ms-4">{{ data?.notes }}</div>
            <div class="row ms-2">
              <div class="col-5">
                <div class="">Attachments</div>
                <button (click)="viewAll()" *ngIf="data?.attachment?.attachmentParameters?.length > 0" nz-button
                  nzType="default" class="rounded-1 me-2">View All ({{ data?.attachment?.attachmentParameters?.length
                  }})</button>
                <button (click)="viewAll()" *ngIf="data?.attachment?.attachmentParameters?.length > 0" nz-button
                  nzType="default" class="rounded-1 me-2">Download All ({{
                  data?.attachment?.attachmentParameters?.length }})</button>
              </div>
            </div>

            <!-- <div class="row mt-2 ms-2">
              <div class="col-6">
                <div class="card-columns">
                  <div class="card"
                    *ngFor="let attachmentParam of data?.attachment?.attachmentParameters; let i = index">
                    <div class="card-body">
                      <div nz-row class="file-icon"
                        [ngClass]="{'pdf': isPdfFile(attachmentParam.extension), 'excel': isExcelFile(attachmentParam.extension), 'word': isWordFile(attachmentParam.extension), 'image': isImageFile(attachmentParam.extension)}">
                        <i class="fas" [ngClass]="getIconType(attachmentParam.extension)"></i>
                        <span>{{ attachmentParam.name }}</span>
                      </div>

                      
                    </div>
                  </div>
                </div>
              </div>
            </div> -->


            
            <div class="row ms-2">
              <div class="col-6">
                <div class="card-columns">
                  <div class="card" *ngFor="let attachmentParam of data?.attachment?.attachmentParameters; let i = index">
                    <div class="card-body">
                      <div nz-row class="file-icon">
                        <!-- Set the image based on file type -->
                        <img *ngIf="isPdfFile(attachmentParam.extension)" src="assets/file-type-pdf2.252x256.png" alt="PDF file" class="file-image" />
                        <img *ngIf="isExcelFile(attachmentParam.extension)" src="assets/file-type-excel.256x239.png" alt="Excel file" class="file-image" />
                        <img *ngIf="isWordFile(attachmentParam.extension)" src="assets/file-type-word.256x239.png" alt="Word file" class="file-image" />
                        <img *ngIf="isTxtFile(attachmentParam.extension)" src="assets/file-type-txt-text-textedit.214x256.png" alt="Text file" class="file-image" />
                        <img *ngIf="isImageFile(attachmentParam.extension)" src="assets/image.512x432.png" alt="Image file" class="file-image" />
                        <span class="font-size">{{ attachmentParam.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            
            

            


            <!--  -->


            <div class="row me-3">
              <div class="col-12 text-end ">
                <div class="">
                  <div class="mt-0">{{data?.jobInformation?.name}}</div>
                  <div class="ListViewJobname mt-2 text-end"><img nz-popover [nzPopoverContent]="Summary"
                      nzPopoverPlacement="top" class="me-2"
                      src="assets/ProjectManagerSitesDiaries/map-marker-default.svg" alt="Open in Maps"
                      style="height: 18px; width: 16px;">Created by Muhammad Yousuf</div>
                  <ng-template #Summary>
                    <div class="rounded-2">
                      <p style="font-size: 13px;">
                        Click to view details
                      </p>
                    </div>
                  </ng-template>
                  <div class="ViewableBy">
                    <span class="padding-right-xs"><span style="font-size: 12px; color: rgb(78, 85, 95);">Viewable
                        by</span></span>
                    <img class="ViewableByIcon" src="assets/ProjectManagerSitesDiaries/ownerIconDisabled.png"
                      alt="ownerIconDisabled">
                    <img class="ViewableByIcon" src="assets/ProjectManagerSitesDiaries/subIconDisabled.png"
                      alt="subIconDisabled">
                    <img class="ViewableByIcon" src="assets/ProjectManagerSitesDiaries/userIcon.png" alt="userIcon">
                  </div>
                </div>
              </div>
            </div>


            

            <div *ngIf="data?.dailyLogTagParameters?.length" class="tags-container text-end mt-1">
              <span *ngFor="let tagParam of data?.dailyLogTagParameters" class="ant-tag DailyLogTag mt-1">
                {{ tagParam?.dailyLogTagSetup?.name }}
              </span>
            </div>
            


            <div class="ant-col ant-col-xs-24 ant-col-md-18 mt-2">
              <button (click)="CommentModelOpen()" nz-button nzType="link" class="text-primary fs-6"><i
                  class="bi bi-chat-dots-fill text-primary me-2 fs-5"></i><span class="fs-6">Add Comment</span></button>
              <button [nzTrigger]="'click'" nz-button class="text-primary border-0 me-2 ms-3 mt-2" nz-dropdown
                [nzDropdownMenu]="menu4">
                <span class="text-primary">1 Related To-Do</span>
                <span nz-icon nzType="down"></span>
              </button>
              <nz-dropdown-menu #menu4="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item>menu4 1st menu item</li>
                  <li nz-menu-item>menu4 2nd menu item</li>
                  <li nz-menu-item>menu4 3rd menu item</li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
    </ng-container>


    <ng-container *ngIf="selectionResponse?.length === 0">
      <div class="row mt-5 mb-0"
        style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
        <div class="col" style="text-align: center;">
          <span class="size">
            <svg width="5em" height="6em" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class=""><path d="M5.26238 5H26.7374C27.1253 5.00012 27.5052 5.11307 27.8301 5.32508C28.1549 5.53709 28.4112 5.83901 28.5675 6.19407C28.7238 6.54911 28.7735 6.94197 28.7105 7.32476C28.6476 7.70682 28.4752 8.0624 28.2142 8.34834L28.2127 8.35L26.8468 9.85309C26.4754 10.2618 25.843 10.2921 25.4342 9.92064C25.0255 9.54922 24.9952 8.91678 25.3667 8.50805L26.7348 7.00248L26.7371 7H5.2627L5.26496 7.00248L13.4736 16.0358C13.8151 16.4042 14.0033 16.889 13.9999 17.3914V27.1273L17.9999 24.4648C17.9985 23.9157 18.4407 23.4676 18.9907 23.4625C19.543 23.4575 19.9948 23.9011 19.9998 24.4533C20.0029 24.7878 19.9215 25.1176 19.7632 25.4123C19.606 25.7049 19.378 25.9534 19.1 26.1351L15.104 28.795L15.0999 28.7976C14.7998 28.9953 14.452 29.1084 14.0931 29.1252C13.7342 29.142 13.3773 29.0617 13.0601 28.8929C12.7429 28.724 12.4771 28.4729 12.2905 28.1658C12.1039 27.8586 12.0036 27.5069 11.9999 27.1476L11.9998 27.1375L11.9999 17.3879L11.9972 17.3851L3.78706 8.35L3.78572 8.34853C3.52461 8.06255 3.35213 7.7069 3.28924 7.32476C3.22625 6.94197 3.27594 6.54912 3.43227 6.19407C3.5886 5.83901 3.84481 5.53709 4.1697 5.32508C4.49458 5.11307 4.87444 5.00012 5.26238 5Z" fill="#0A0B0D"></path><path d="M25.2071 12.7929C25.5976 13.1834 25.5976 13.8166 25.2071 14.2071L22.9142 16.5L25.2071 18.7929C25.5976 19.1834 25.5976 19.8166 25.2071 20.2071C24.8166 20.5976 24.1834 20.5976 23.7929 20.2071L21.5 17.9142L19.2071 20.2071C18.8166 20.5976 18.1834 20.5976 17.7929 20.2071C17.4024 19.8166 17.4024 19.1834 17.7929 18.7929L20.0858 16.5L17.7929 14.2071C17.4024 13.8166 17.4024 13.1834 17.7929 12.7929C18.1834 12.4024 18.8166 12.4024 19.2071 12.7929L21.5 15.0858L23.7929 12.7929C24.1834 12.4024 24.8166 12.4024 25.2071 12.7929Z" fill="#0A0B0D"></path></svg>

          </span>
          <div class="row">
            <div class="col mt-2">
              <h1>No results</h1>

            </div>
          </div>
          <div class="col mt-3">
            <p>Adjust or clear your filters to find the daily logs you're looking for.</p>

          </div>
        </div>
     

      </div>
    </ng-container>

</nz-content>
  </nz-layout>


</nz-layout>