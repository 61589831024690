<nz-layout>
  <nz-sider
    class="border"
    nzCollapsible
    [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed"
    [nzWidth]="280"
    [nzTrigger]="null"
  >
    <div class="row">
      <app-side-menu-bar
      [isAllJobOption]="true"
      (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"
      ></app-side-menu-bar>
    </div>
  </nz-sider>
  <nz-layout>
      <nz-page-header>
        <nz-page-header-title>
          <nz-breadcrumb class="text-start" nz-page-header-breadcrumb>
            <nz-breadcrumb-item>Yousuf Test F2</nz-breadcrumb-item>
          </nz-breadcrumb>
          <h1>
            Budget
          </h1>
        </nz-page-header-title>
      </nz-page-header>
      <nz-content class="custom-content">
      <app-budget-content></app-budget-content>
      </nz-content>
  
    </nz-layout>
    </nz-layout>