<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form (ngSubmit)="onFilterApply()">
    <div class="row mt-2">


      <div class="col-10 p-0">
        <div class="input-group">
          <nz-select [(ngModel)]="formFieldValues['standardFilter']" name="standardFilter"
            (ngModelChange)="onChangeStandardFilter($event)" nzShowSearch [nzAutoFocus]="true"
            nzPlaceHolder="Standard Filter" r>
            <nz-option *ngFor="let filter of filterResponseData"
              [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')" [nzValue]="filter.id" nzCustomContent>
              <div class="option-content">
                <!-- Customize content with icons and text -->
                <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
                <span>{{ filter.name }}</span>
                <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

              </div>
            </nz-option>
          </nz-select>
        </div>
      </div>

      <div class="col-1 mt-1">
        <i class="bi bi-three-dots"
        nz-popover
        [nzPopoverContent]="contentTemplate"
        [nzPopoverPlacement]="'top'"
         (click)="openStandardFilterComponent('SAVE_AS')"></i>
      </div>
    </div>



    <div class="col p-0 text-end">
      <strong nz-icon nzType="close" nzTheme="outline"></strong>
    </div>



    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Search</label>
        <input [(ngModel)]="formFieldValues['search']" name="search" type="text" class="form-control formField" />
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Approval Status</label>

        <!-- aproval status -->
        <nz-tree-select [(ngModel)]="formFieldValues['approvalStatus']" name="approvalStatus" style="width: 100%"
          [nzNodes]="ApprovalstatusNodes" nzShowSearch nzAllowClear="false" nzCheckable
          (ngModelChange)="onapprovalStatusChange($event)" nzPlaceHolder="Select approvalStatus"
          nzDropdownClassName="dropdownStyle">
        </nz-tree-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Subs</label>
        <nz-tree-select [nzNodes]="subsNodes" [(ngModel)]="formFieldValues['subs']" name="subs" nzShowSearch
          nzAllowClear nzCheckable nzCheckAll="false" nzPlaceHolder="Select Subs"
          nzDropdownClassName="dropdownStyle"></nz-tree-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Created By</label>
        <nz-tree-select [nzNodes]="CreatedBy" [(ngModel)]="formFieldValues['createdBy']" name="createdBy" nzShowSearch
          nzAllowClear nzCheckable nzCheckAll="false" nzPlaceHolder="Select contact type"
          nzDropdownClassName="dropdownStyle"></nz-tree-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Added Date</label>
        <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['AddedDate']" name="AddedDate">
          <nz-option *ngFor="let option of AddedDate" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Approval Date</label>
        <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['approvalDate']" name="approvalDate">
          <nz-option *ngFor="let option of ApprovalDate" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Test1</label>
        <input formControlName="test1" type="text" class="form-control formField" />
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Test2</label>
        <input formControlName="test2" type="text" class="form-control formField" />
      </div>
    </div>


    <div class="row">
      <div class="col text-end">
        <button nz-button nzType="primary">Clear All</button>
        <button nz-button nzType="default" class="ms-1">apply</button>
      </div>
    </div>

  </form>
</div>
<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>
