<nz-modal nzWidth="95%" [(nzVisible)]="catalog" [nzTitle]="catalog1" [nzFooter]="catalog2"
  (nzOnCancel)="CostCodeCancel()"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '68vh', 'overflow-y': 'auto'}"
  [nzStyle]="{ top: '18px' }">
  <ng-template #catalog1>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0 ">Cost Codes </h1>
    </div>
  </ng-template>
  <div class="cantent" *nzModalContent>
    <div class="row famly-inn gx-3">
      <div class="col-12">
        <div class="card node --max-old-space-size=4096 ./node_modules/@angular/cli/bin/ng serve --openorder-0 mt-0">
          <div class="card-body">
            <div class="row mt-0 text-end">
              <div class="col">
                <nz-switch [(ngModel)]="switchValue"></nz-switch>
                <span class="me-3 "> Show Inactive Code</span>

                <button class="rounded-1" nz-button nz-dropdown [nzDropdownMenu]="menu5" nzTrigger="click"
                  nzPlacement="bottomLeft">
                  Cost Code Action
                  <span nz-icon nzType="down"></span>

                  <nz-dropdown-menu #menu5="nzDropdownMenu">
                    <ul nz-menu>
                      <li (click)="category()" nz-menu-item>Cost Category</li>
                      <li nz-menu-item>Null</li>
                      <li nz-menu-item>Null</li>
                    </ul>
                  </nz-dropdown-menu>
                </button>
                <button class="ms-2" nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu4"
                  nzPlacement="bottomLeft" nzTrigger="click">
                  New
                  <span nz-icon nzType="down"></span>
                </button>
                <nz-dropdown-menu #menu4="nzDropdownMenu">
                  <ul nz-menu>
                    <li (click)="NewCostcategory()" nz-menu-item> <img src="assets/iconFolderMini.gif" class="me-2 ms-0"
                        alt=""> Cost Category</li>
                    <li (click)="NewCostCodes()" nz-menu-item> <img src="assets/iconFolderMini.gif" class="me-2 ms-0"
                        alt=""> Cost Code</li>
                    <li (click)="Variancecategory()" nz-menu-item> <img src="assets/iconFolderMini.gif"
                        class="me-2 ms-0" alt=""> Variances Category</li>
                    <li (click)="VarianceCode()" nz-menu-item> <img src="assets/iconFolderMini.gif" class="me-2 ms-0"
                        alt=""> Variances Code</li>
                  </ul>
                </nz-dropdown-menu>



              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="card border-0 mt-3">
              <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                  <div class="col">
                    <span class="fw-medium fs-6 ms-2">Costs</span>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row mb-0 p-2 d-flex align-items-center">
                  <div class="col-12">
                    <div class="col d-flex align-items-center">
                      <i (click)="toggleSection()" [ngClass]="{'rotate-icon': isSectionVisible}"
                        class="fa-solid fa-caret-right icon-container"></i>
                      <span class="fw-medium fs-6 ms-3 TextWrap">01 - PRELIMINARY WORKS</span>
                    </div>
                  </div>
                </div>
                <!-- <div class="row"  *ngIf="isSectionVisible">
                                <div class="col" style="margin-left: 65px;">
                                        <a  href="/"><span class="TextWrap">01.00 - Architectural Plans</span></a>
                                </div>
                              </div> -->
                <!-- <div class="row"  *ngIf="isSectionVisible">
                                <div class="col" style="margin-left: 65px;">
                                        <a  href="/"><span class="TextWrap">01.00 - Architectural Plans</span></a>
                                </div>
                              </div>
                              <div class="row"  *ngIf="isSectionVisible">
                                <div class="col" style="margin-left: 65px;">
                                        <a  href="/"><span class="TextWrap">01.00 - Architectural Plans</span></a>
                                </div>
                              </div>
                              <div class="row"  *ngIf="isSectionVisible">
                                <div class="col" style="margin-left: 65px;">
                                        <a  href="/"><span class="TextWrap">01.00 - Architectural Plans</span></a>
                                </div>
                              </div>
                              <div class="row"  *ngIf="isSectionVisible">
                                <div class="col" style="margin-left: 65px;">
                                        <a  href="/"><span class="TextWrap">01.00 - Architectural Plans</span></a>
                                </div>
                              </div> -->
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card border-0 mt-3">
              <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                  <div class="col">
                    <span class="fw-medium fs-6 ms-2">Variances</span>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row mb-0 p-2 d-flex align-items-center">
                  <div class="col-12">
                    <div class="col d-flex align-items-center">
                      <i (click)="toggleSection1()" [ngClass]="{'rotate-icon': isSectionVisible}"
                        class="fa-solid fa-caret-right icon-container"></i>
                      <span class="fw-medium fs-6 ms-3 TextWrap">0 - 9 Plans</span>
                    </div>
                  </div>
                </div>
                <!-- <div class="row mt-2"  *ngIf="isSectionVisible1">
                                <div class="col" style="margin-left: 65px;">
                                        <a  href="/"><span class="TextWrap">01 - Dimensioning Error </span></a>
                                </div>
                              </div> -->
                <!-- <div class="row mt-2"  *ngIf="isSectionVisible1">
                                <div class="col" style="margin-left: 65px;">
                                        <a  href="/"><span class="TextWrap">01 - Dimensioning Error </span></a>
                                </div>
                              </div>
                              <div class="row mt-2"  *ngIf="isSectionVisible1">
                                <div class="col" style="margin-left: 65px;">
                                        <a  href="/"><span class="TextWrap">01 - Dimensioning Error </span></a>
                                </div>
                              </div>
                              <div class="row mt-2"  *ngIf="isSectionVisible1">
                                <div class="col" style="margin-left: 65px;">
                                        <a  href="/"><span class="TextWrap">01 - Dimensioning Error </span></a>
                                </div>
                              </div>
                              <div class="row mt-2"  *ngIf="isSectionVisible1">
                                <div class="col" style="margin-left: 65px;">
                                        <a  href="/"><span class="TextWrap">01 - Dimensioning Error </span></a>
                                </div>
                              </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <ng-template #catalog2>
    <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
  </ng-template>
</nz-modal>