import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { JobCreateFromTemplateComponent } from '../job-create-from-template/job-create-from-template.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActivatedRoute } from '@angular/router';
import { JobCreateFromScratchComponent } from '../job-create-from-scratch/job-create-from-scratch.component';
import { JobsComponent } from 'src/app/shared/component/jobs/jobs.component';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { Subscription } from 'rxjs';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { FilterSearchParameter, LookupFilterResponse } from 'src/app/shared/component/Models/LookupModels';
import { StandardColumnSetupResponse, StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';
import { AddNewGridStandardViewComponent } from 'src/app/shared/component/Modal/add-new-grid-standard-view/add-new-grid-standard-view.component';
import { ColumnsResizePinProperties } from 'src/Models/InternalUser/internalUserColumnsPinResize';
import { NzMessageService } from 'ng-zorro-antd/message';
import { JobInformationResponse } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { JobListService } from 'src/Service/Job_List/job-list.service';
import { NewContactComponent } from 'src/app/customer-infromation/Components/new-contact/new-contact.component';
import { LeadActivityTypeService } from 'src/Service/Lead-Activity-Type/lead-activity-type.service';
import { SortDescriptor } from '@progress/kendo-data-query';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { JobInformationByIdResponse, JobListGridResponse } from 'src/Models/Job-Scratch/JobFromScratch';

@Component({
  selector: 'app-jobs-dashboard',
  templateUrl: './jobs-dashboard.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./jobs-dashboard.component.css']
})
export class JobsDashboardComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private subscription: Subscription;
  private jobDashFilterSubscription: Subscription;
  private jobDashstandardViewFilterSubscription : Subscription;
  ChooseHowYouWouldLike = false;
  showAllUsers = false;
  PAGE_ID: number = -1;
  JOBS_JOBS_LIST: string = FiltersName.JOBS_JOBS_LIST;
  activeTab: number = 0;
  tabs = [1, 2];
  switchValue = false;
  isDisabled = true;
  public mySelection: string[] = [];
  displayedColumns: string[] = [];
  selectedFilter: number = -1;
  isFilterApplied: boolean = false;
  lookupFilterResponse: LookupFilterResponse[] = [];
  defaultFilterId_selected: number = 0
  filterSearchParameter: FilterSearchParameter;
  currentPage = 1;
  pageSize = 50;
  totalRecords = 100;
  // displayedData: JobInformationResponse[] = [];
    displayedData: JobListGridResponse[] = [];
  fixedSelectedColumnNames: string[] = [];
  selected_View_Columns: string[] = [];
  getStandardViewColumnFilterData: StandardColumnSetupResponse[] = [];
  getStandardViewFilterData: StandardViewResponse[] = [];
  selectedValueControl: any;
  expandedRows: number[] = [];
  isColumnSticky: boolean[] = new Array(this.displayedColumns.length).fill(false);
  columnSizes: { columnName: string, widthSize: number }[] = [];
  getColumnWidthSize: number[] = [];
  isLoading: boolean = false;
  isLoading1: boolean = false;
  defaultFilterIdSelected: number = 0;
  selectedColNameSizePin: ColumnsResizePinProperties[] = [];

  selectionFrom: string = 'DB' ;
  constructor(private modal: NzModalService,
    private sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    private standardViewService: StandardViewForAllService,
    private LookupFilterService: LookupFilterService,
    private filterService: FilterSideMenuBarService,
    private leadAceeetivitiesService: LeadActivityTypeService,
    private route: ActivatedRoute,
    private toastService: NzMessageService,
    private JobListService: JobListService,
    private accessLocalStorageService : AccessLocalStorageService
  ) {
    this.searchQuery = '';
    if (this.jobDashFilterSubscription) {
      this.jobDashFilterSubscription.unsubscribe();
    }
    if (this.subscription) {
        this.subscription.unsubscribe();
    }
    if (this.jobDashstandardViewFilterSubscription) {
      this.jobDashstandardViewFilterSubscription.unsubscribe();
    }
    console.clear();
    this.displayedColumns.forEach(column => {
      this.columnLockState[column] = false;
    });
  }
  ngOnInit(): void {
    this.mapUrl = this.generateMapUrl(this.searchQuery);
      this.unsubscribeAll();
      this.JobListService.jobListSetSelectedFilterId(0);
      if (this.jobDashFilterSubscription) {
        this.jobDashFilterSubscription.unsubscribe();
      }
      if (this.subscription) {
          this.subscription.unsubscribe();
      }
      if (this.jobDashstandardViewFilterSubscription) {
        this.jobDashstandardViewFilterSubscription.unsubscribe();
      }
    this.PAGE_ID = 3;
    this.displayedColumns = [];
    
    // Add subscriptions to the array
    this.subscriptions.push(
      this.standardViewService.$displaySelectedColApply.subscribe(colName => {
        if (colName.length > 0) {
          this.displayedColumns = colName;
          this.changeDetectorRef.detectChanges();
        }
      })
    );

    this.subscriptions.push(
      this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
        this.selectedFilter = standardType != -1 ? standardType : standardType;
      })
    );

    this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom);
    this.getStandardViewFilterByFormPageId(this.PAGE_ID);

      this.route.url.subscribe(urlSegments => {
      const path = urlSegments[0].path;
      switch (path) {
        case 'Job-List':
          this.activeTab = 0;
          break;
        case 'Job-Map':
          this.activeTab = 1;
          break;
      }
    });
    this.leadAceeetivitiesService.leadActivityTypeResponse$.subscribe(() => {
      this.customerGridTableData(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageActivityView, this.pageSizeActivityView);
    });    
  }
  private unsubscribeAll(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }



  openStandardViewFilterComponent(isSaveAs?: string) {
    let isInputVisible: boolean = false;
    if (isSaveAs === 'saveAs') {
      isInputVisible = true;
    } else {
      isInputVisible = false;
    }
    this.selectedColNameSizePin = [];
    for (let i = 0; i < this.displayedColumns.length; i++) {
      const item = this.displayedColumns[i];
      const isPinValue = this.isColumnSticky[i];
      let widthSize = 0;
      if (this.columnSizes.length > 0) {
        for (let i = 0; i < this.columnSizes.length; i++) {
          const columnName = this.columnSizes[i].columnName;
          const columnNameSize = this.columnSizes[i].widthSize;
          if (columnName === item) {
            widthSize = columnNameSize;
            break;
          }
        }
      }

      if (widthSize === 0) {
        widthSize = 180;
      }

      this.selectedColNameSizePin.push({
        displayColumnName: item,
        resizeColumnWidth: widthSize, //  "200px", // Set default width here
        isPin: isPinValue == true ? isPinValue : false
      });
    }
    const modalRef = this.modal.create({
      nzContent: AddNewGridStandardViewComponent,
      nzData:
      {
        pageId: this.PAGE_ID,
        column: this.getStandardViewColumnFilterData,
        displayCol: this.displayedColumns,
        viewList: this.getStandardViewFilterData,
        selectedColNameSizePin: this.selectedColNameSizePin,
        selectedStandardView: this.selectedValueControl,
        fixedCol: this.fixedSelectedColumnNames,
        isSaveAs: isInputVisible
      },

    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  // Grid methods Start //

  // getStandardFilterByFormPageId(pageId:number, type: string){ 
  //   // New Changes By Aamir Ali - 22-Apr2024
  //   if (this.jobDashFilterSubscription) {
  //     this.jobDashFilterSubscription.unsubscribe();
  // }
  // if (this.subscription) {
  //     this.subscription.unsubscribe();
  // }

  //   this.LookupFilterService.getStandardFilterByFormPageId(pageId);
  //   this.jobDashFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(result =>{
  //   if(result){
  //     this.lookupFilterResponse = result.result;
  //    let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
  //    this.internalUserService.setSelectedFilterId(defaultFilterId);
  //    let id = -1;
  //    if(type === this.selectionFrom){
  //     this.defaultFilterIdSelected = defaultFilterId;
  //     this.accessLocalStorageService.setSelectedFilterId(defaultFilterId); 

  //     // New Changes By Aamir Ali - 22-Apr2024
  //     id = this.accessLocalStorageService.getSelectedFilterId();
  //    }else{
  //     id = this.accessLocalStorageService.getSelectedFilterId();
  //    }

  //    if(id !== -1){
  //     this.customerGridTableData(1, this.PAGE_ID , id ,this.currentPage,this.pageSize);
  //    }

  //   }
  //   });
  // }
  getStandardFilterByFormPageId(pageId: number, type: string): void {
    // New Changes By Aamir Ali - 22-Apr-2024
    this.unsubscribeFrom(this.jobDashFilterSubscription);
    this.unsubscribeFrom(this.subscription);

    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.jobDashFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(result =>{
    if(result){
      this.lookupFilterResponse = result.result;
     let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
     this.JobListService.jobListSetSelectedFilterId(defaultFilterId);
     let id = -1;
     if(type === this.selectionFrom){
      this.defaultFilterIdSelected = defaultFilterId;
      this.accessLocalStorageService.setSelectedFilterId(defaultFilterId); 

      // New Changes By Aamir Ali - 22-Apr2024
      id = this.accessLocalStorageService.getSelectedFilterId();
     }else{
      id = this.accessLocalStorageService.getSelectedFilterId();
     }

     if(id !== -1){
      this.customerGridTableData(1, this.PAGE_ID , id ,this.currentPage,this.pageSize);
     }

    }
    });

    this.subscriptions.push(this.jobDashFilterSubscription);
  }

  private unsubscribeFrom(subscription: Subscription): void {
    if (subscription) {
      subscription.unsubscribe();
    }
  }
  customerGridTableData(companyId: number, formNameId: number, lookUpFilterId: number, page: number, pageSize: number) {
    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId: lookUpFilterId,
      page: page,
      pageSize: pageSize,
      jobInformationId: null
    };

    this.JobListService.getJobList(this.filterSearchParameter);
    this.jobDashFilterSubscription = this.JobListService.$getListUsers.subscribe(result => {
      if (result) {
        this.totalRecords = result?.result?.rowCount;
        this.displayedData = result?.result?.rows;
      }
    });
  }
  showMoreMap: Map<string, boolean> = new Map();  
  formatAttendeeNames(attendeeNames: string, showFull: boolean): string {
    if (!attendeeNames) return '';
    const namesArray = attendeeNames.split(',').map(name => name.trim());
    const filteredNames = namesArray.filter(name => name.length > 0);
    if (showFull) {
      return filteredNames.join('<br>');
    } else {
      return filteredNames.slice(0, 2).join('<br>') + (filteredNames.length > 2 ? '<ng-template #showMoreButton></ng-template>' : '');
    }
  }
  hasMoreThanTwoAttendees(attendeeNames: string): boolean {
    if (!attendeeNames) return false;
    const namesArray = attendeeNames.split(',').map(name => name.trim());
    return namesArray.length > 2;
  }
  toggleShowMore(dataItem: JobListGridResponse): void {
    const key = dataItem.AttendeeNames;
    this.showMoreMap.set(key, !this.showMoreMap.get(key));
  }
  showMoreButtonText(dataItem: JobListGridResponse): string {
    return this.shouldShowMore(dataItem) ? 'Show Less' : 'Show More';
  }
  shouldShowMore(dataItem: JobListGridResponse): boolean {
    const key = dataItem.AttendeeNames;
    return this.showMoreMap.get(key) ?? false;
  }
  // getStandardViewFilterByFormPageId(pageId: number) {
  //   this.displayedColumns = [];
  //   this.fixedSelectedColumnNames = [];
  //   this.standardViewService.getStandardViewFilterByFormPageId(pageId);
  //   this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
  //   this.standardViewService.$getStandardViewFilter.subscribe(result => {
  //     if (result) {
  //       this.displayedColumns = [];
  //       this.selected_View_Columns = [];
  //       this.getStandardViewFilterData = result.result;
  //       let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).standardViewParameters;
  //       this.selectedValueControl = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).id;
  //       defaultView.forEach(viewItem => {
  //         if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
  //           this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
  //         }
  //       });
  //       this.displayedColumns = this.selected_View_Columns;
  //     }
  //   });

  //   this.standardViewService.$getStandardViewColumn.subscribe(result => {
  //     if (result) {
  //       this.getStandardViewColumnFilterData = result.result;
  //       let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);
  //       fixedCol.forEach(x => {
  //         this.fixedSelectedColumnNames.push(x.displayColumnName);
  //       });

  //     }
  //   });

  // }
  getStandardViewFilterByFormPageId(pageId:number){
    if (this.jobDashstandardViewFilterSubscription) {
      this.jobDashstandardViewFilterSubscription.unsubscribe();
    }
    this.displayedColumns  = [];
    this.fixedSelectedColumnNames = [];
    this.standardViewService.getStandardViewFilterByFormPageId(pageId);
    this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
    // this.standardViewService.$getStandardViewFilter.subscribe(result =>{
    this.jobDashstandardViewFilterSubscription= this.standardViewService.$getStandardViewFilter.subscribe(result => {
      if(result){
        this.displayedColumns  = [];
        this.selected_View_Columns = [];
        this.getStandardViewFilterData = result.result;
        let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).standardViewParameters;
        this.selectedValueControl = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).id;
        defaultView.forEach(viewItem => {
          if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
            this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
          }
        });
        this.displayedColumns = this.selected_View_Columns;
      }
    });

    this.standardViewService.$getStandardViewColumn.subscribe(result =>{
      if(result){
        this.getStandardViewColumnFilterData = result.result;
        let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);
        fixedCol.forEach(x => {
          this.fixedSelectedColumnNames.push(x.displayColumnName);
        });
        
      }
    });
  }




  toggleColumnSticky(index: number) {
    this.isColumnSticky[index] = !this.isColumnSticky[index];
    if (this.isColumnSticky[index]) {
      for (let i = 0; i < index; i++) {
        this.isColumnSticky[i] = true;
      }
    }
  }


  saveColumnSizes(event: any) {
    event.forEach(col => {
      const columnName = col.column.field;
      const widthSize = col.newWidth;
      const existingIndex = this.columnSizes.findIndex(item => item.columnName === columnName);

      if (existingIndex !== -1) {
        this.columnSizes[existingIndex].widthSize = widthSize;
      } else {
        this.columnSizes.push({ columnName, widthSize });
      }
    });

  }


  onPageChange(page: number): void {
    this.currentPage = page;
    let filterId: number = 0;
    this.JobListService.$ListisselectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.customerGridTableData(1, this.PAGE_ID, filterId, page, this.pageSize);
      this.isLoading = false;

    }, 100);
  }

  onPageSizeChange(size: number): void {
    this.pageSize = size;
    let filterId: number = 0;
    this.JobListService.$ListisselectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.customerGridTableData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize);
      this.isLoading = false;

    }, 100);
  }


  reInitReset(){
    this.ngOnInit();
    this.selectedFilter = -1;
  }


  saveEditStandardView() {
    this.standardViewService.$createStandardViewRequest.subscribe(formData => {
      if (formData && formData !== null) {
        this.standardViewService.postData(formData)
          .subscribe(
            () => {
              setTimeout(() => {
                // this.loadingIndicateService.hide();
                this.isLoading = true;
                this.toastService.success('Standard View Updated Successfully');
                this.standardViewService.getStandardViewFilterByFormPageId(this.PAGE_ID);
                this.selectedFilter = -1;
              }, 2000);
            },
            (error) => {
              console.error('Error:', error);
              //this.loadingIndicateService.hide();
              this.isLoading = false;
            }
          );
      }
    });
  }


  onFilterChange(id: number) {

    this.selectedFilter = -1;
    this.displayedColumns = [];
    this.selected_View_Columns = [];
    let selectedView = this.getStandardViewFilterData.find(view => view.id === id).standardViewParameters;
    selectedView.forEach(viewItem => {
      if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
        this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
        this.displayedColumns = this.selected_View_Columns;
      }
    });
  }

  // Grid methods End //
  openFilter(component: string, pageId: number) {
    this.selectionFrom = ''; // New Changes By Aamir Ali - 22-Apr2024
    this.isColumnSticky= new Array(this.displayedColumns.length).fill(false);
    this.filterService.toggleFilter(component, pageId);
    this.JobListService.JobListUserAppliedFilter(false);
  }
  jobsListSettingOpen() {
    const modalRef = this.modal.create({
      nzContent: JobsComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();

    });
  }

  // openFilter(component: string, pageId: number) {
  //   this.filterService.toggleFilter(component, pageId);
  // }


  innerCardTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'List';
      case 2:
        return 'Map';
      default:
        return '';
    }
  }

  currentPageActivityView: number = 1;
  pageSizeActivityView: number = 10;
  OpenChooseHowYouWouldLiketoStart(): void {
    this.ChooseHowYouWouldLike = true;
  }
  CloseChooseHowYouWouldLiketoStart(): void {
    this.ChooseHowYouWouldLike = false
  }


  OpenJobTEmplate(): void {
    const modalRef = this.modal.create({
      nzContent: JobCreateFromTemplateComponent,
      nzFooter: null,

    });
    this.ChooseHowYouWouldLike = false;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  }
  // JobCreateScratch(): void {
  //   this.isLoading1 = true;

  //   const modalPromise = new Promise<void>((resolve, reject) => {
  //     const modalRef = this.modal.create({
  //       nzContent: JobCreateFromScratchComponent,
  //       nzFooter: null,
  //     });

  //     this.ChooseHowYouWouldLike = false;

  //     modalRef.componentInstance.cancel.subscribe((data: JobInformationByIdResponse) => {
  //       modalRef.destroy();
  //       resolve();
  //     });

  //     modalRef.afterClose.subscribe(() => {
  //       resolve();
  //     });
  //   });

  //   modalPromise
  //     .then(() => {
  //       modalRef.componentInstance.Loder.subscribe(() =>{
  //           this.isLoading1 = false;
  //       });
  //     })
  //     .catch(() => {
  //       modalRef.componentInstance.Loder.subscribe(() =>{
  //         this.isLoading1 = false;
  //     });
  //     });
  // }
  
  JobCreateScratch(): void {
    const modalRef = this.modal.create({
      nzContent: JobCreateFromScratchComponent,
      nzFooter: null,
    });
    this.ChooseHowYouWouldLike = false;
    modalRef.componentInstance.cancel.subscribe((data: JobInformationByIdResponse) =>{
      modalRef.destroy();
    });
  }

  toggleShowAllUsers(): void {
    this.showAllUsers = !this.showAllUsers;
  }


  EditJobScratch(Id: number): void {
    const modalRef = this.modal.create({
      nzContent: JobCreateFromScratchComponent,
      nzFooter: null,

    });
    modalRef.componentInstance.JobResponseId = Id;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  ngOnDestroy() {
    this.LookupFilterService.getStandardFilter.next(null); // New Changes By Aamir Ali - 31-May-2024
    localStorage.removeItem('Selected_Filter_Id'); // New Changes By Aamir Ali - 31-May-2024
    if (this.jobDashFilterSubscription) {
      this.jobDashFilterSubscription.unsubscribe();
    }
    if (this.subscription) {
        this.subscription.unsubscribe();
    }
    if (this.jobDashstandardViewFilterSubscription) {
      this.jobDashstandardViewFilterSubscription.unsubscribe();  
    }
    console.clear();
    this.unsubscribeAll();
  }

  // =========Abdul rehman======
  public sort: SortDescriptor[] = [];
  switchValue1 = false;
  hideField = false;
  mapUrl: SafeResourceUrl;
  searchQuery: string = '';
  @Input() data: JobInformationResponse[] = [];
  
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.displayedData = this.sortData();
  }

  onSort(column: any): void {
    this.activeColumn = column;
    const existingSort = this.sort.find(s => s.field === column.field);
    if (existingSort) {
      existingSort.dir = existingSort.dir === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort = [{ field: column.field, dir: 'asc' }];
    }
    this.displayedData = this.sortData();
  }
  sortData(): any[] {
    return this.displayedData.sort((a, b) => {
      for (const descriptor of this.sort) {
        const fieldA = a[descriptor.field];
        const fieldB = b[descriptor.field];
        const compareResult = (fieldA > fieldB ? 1 : -1) * (descriptor.dir === 'asc' ? 1 : -1);
        if (compareResult !== 0) {
          return compareResult;
        }
      }
      return 0;
    });
  }
  activeColumn: any;
  isActiveColumn(column: any): boolean {
    return this.activeColumn === column;
  }
  allSelected: boolean = false;

  onCheckboxChange() {
    const selectedCount = this.displayedData.filter(item => item.selected).length;
    this.allSelected = selectedCount === this.displayedData.length;
  }
  onHeaderCheckboxChange(checked: boolean) {
    this.allSelected = checked;
    this.displayedData.forEach(item => item.selected = checked);
  }
  generateMapUrl(query: string): SafeResourceUrl {
    const url = `https://maps.google.com/maps?q=${encodeURIComponent(query)}&t=&z=13&ie=UTF8&iwloc=&output=embed`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  updateMapUrl(): void {
    this.mapUrl = this.generateMapUrl(this.searchQuery);
  }
  MapChange(e :string){
    this.searchQuery=e;
    this.mapUrl = this.generateMapUrl(this.searchQuery);
  }
  getColumnWidth(column: string): number {
    switch (column) {
      case 'Job Color':
        return 100;
      case 'Job Name':
        return 300;
      case 'Street Address':
        return 220;
      case 'State':
        return 100;
      case 'Postal Code':
        return 100;
      case 'Suburb':
        return 150;
      case 'Project Manager':
        return 200;
      case 'Owners':
        return 250;
      case 'Schedule Status':
        return 120;
      case 'Cost / Sqtf':
        return 100;
      default:
        return 100;
    }
  }
  columnLockState: { [key: string]: boolean } = {};
  toggleColumnLock(column: string) {
    this.columnLockState[column] = !this.columnLockState[column];
  }
  isColumnLocked(column: string): boolean {
    return this.columnLockState[column];
  }
  getOwnersArray(displayNames: string | undefined): Array<{ id: string, name: string }> {
    if (!displayNames) {
      return [];
    }

    return displayNames.split(',').map(item => {
      const parts = item.split('-');
      if (parts.length !== 2) {
        return { id: '', name: '' };
      }
      const [id, name] = parts;
      return { id: id.trim(), name: name.trim() };
    });
  }
  EditCustomer(id: any): void {
    const modalRef = this.modal.create({
      nzContent: NewContactComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.CustomerResponseId = id;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.onSaveComplete.subscribe(() => {
      this.customerGridTableData(1, this.PAGE_ID, this.defaultFilterId_selected, this.currentPage, this.pageSize);
    });
  }
  // =========Abdul rehman======
}
