<nz-modal
    nzWidth="35%"
    [(nzVisible)]="Taghandle"
    [nzTitle]="Tag1"
    [nzFooter]="Tag2"
    (nzOnCancel)="TagClose()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '38vh', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
  <ng-template #Tag1>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0" nz-typography>Add Time Clock Tag</h2>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <div class="row famly-inn gx-3">
      <div class="col-12">
          <div class="card border-0 mt-0">
              <div class="card-head border-bottom">
                  <div class="row py-2">
                    <div class="col-12">
                      <h5 class="ms-3" nz-typography>Add Time Clock Tag</h5>
                    </div>
                  </div>
              </div>
              <div class="card-body">
                <div class="row">
                    <div class="col-12">
                      <label class="sub-text">Title <span class="text-danger fw-bolder">*</span></label>
                      <input type="text" class="formField">
                    </div>
                </div>
              </div>
          </div>
      </div>
    </div>
</div>
  <ng-template #Tag2>
    <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
  </ng-template>
</nz-modal>





