import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { LookupNameSetupResponse } from 'src/Models/All-Filters/financialBillsFilterField';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment.prod';
interface RandomUser {
  gender: string;
  email: string;
  name: {
    title: string;
    first: string;
    last: string;
  };
}
@Injectable({
  providedIn: 'root'
})
export class InterUserService {

  randomUserUrl = 'https://api.randomuser.me/';

  getUsers(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>
  ): Observable<{ results: RandomUser[] }> {
    let params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('results', `${pageSize}`)
      .append('sortField', `${sortField}`)
      .append('sortOrder', `${sortOrder}`);
    filters.forEach(filter => {
      filter.value.forEach(value => {
        params = params.append(filter.key, value);
      });
    });
    return this.http
      .get<{ results: RandomUser[] }>(`${this.randomUserUrl}`, { params })
      .pipe(catchError(() => of({ results: [] })));
  }

  constructor(private http: HttpClient) {}

  private baseUrl1: string = environment.apiUrl;

  showCode(formNameId: number): Observable<ResponseModelArray<LookupNameSetupResponse>> {
      const url = `${this.baseUrl1}LookupNameSetup/getAll-byFormNameId?formNameId=${formNameId}`;
      return this.http.post<ResponseModelArray<LookupNameSetupResponse>>(url, { formNameId });
    }
}