import { Component, EventEmitter, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { v4 as uuidv4 } from 'uuid'; // Import the UUID function
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';

interface CustomUploadFile extends NzUploadFile {
  loading?: boolean;
  error?: boolean;
  addedBy: number
  createdOn: Date;
  modifiedOn: Date;
  id: number;
  globalId: string;
  ftb?: File;
}

@Component({
  selector: 'app-lead-opportunity-attachments-upload-files',
  templateUrl: './lead-opportunity-attachments-upload-files.component.html',
  styleUrls: ['./lead-opportunity-attachments-upload-files.component.css']
})
export class LeadOpportunityAttachmentsUploadFilesComponent {
  @Output() cancel = new EventEmitter<void>();
  @Output() UploadFile = new EventEmitter<CustomUploadFile[]>();

  uploadfiles = true;
  fileList: CustomUploadFile[] = [];
  private fileIdCounter: number = 1;
  constructor(public accessLocalStorageService: AccessLocalStorageService,private toastService: NzMessageService) {}

  UploadFiles(): void {
    this.cancel.emit();
  }

  chooseFile(): void {
    const input = document.getElementById('files') as HTMLInputElement;
    if (input) {
      input.click();
    }
  }
  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    let idd = this.accessLocalStorageService.getUserLoginId();
    const supportedFileTypes = [
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'text/plain',
      'application/x-rar-compressed',
      'application/zip',
      'application/x-zip-compressed',
      'video/mp4',
      'video/avi',
      'video/mpeg',
      'video/quicktime',
      'video/x-msvideo',
      'audio/mpeg',
      'audio/ogg',
      'audio/wav',
      'application/json'
    ];

    const unsupportedFiles: File[] = [];

    Array.from(files).forEach(file => {
      if (!supportedFileTypes.includes(file.type)) {
        unsupportedFiles.push(file);
      } else {
        const customFile: CustomUploadFile = {
          uid: file.name,
          name: file.name,
          size: file.size,
          type: file.type,
          thumbUrl: URL.createObjectURL(file),
          loading: true,
          url: URL.createObjectURL(file),
          addedBy: idd,
          createdOn: new Date(),
          modifiedOn: new Date(),
          id: this.fileIdCounter++,
          globalId: uuidv4(),
          originFileObj: file
        };

        this.fileList.push(customFile);

        // Simulate loading time based on file size
        this.simulateUpload(file);
      }
    });

    if (unsupportedFiles.length > 0) {
      this.toastService.error('The file type selected is not supported for this field.');
    }
  }

  // onFileSelected(event: any): void {
  //   const files: FileList = event.target.files;
  //   let idd = this.accessLocalStorageService.getUserLoginId();
  //   const supportedFileTypes = [
  //     'image/jpeg',
  //     'image/png',
  //     'application/pdf',
  //     'application/msword',
  //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  //     'application/vnd.ms-excel',
  //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //     'application/vnd.ms-powerpoint',
  //     'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  //     'text/plain',
  //     'application/x-rar-compressed',
  //     'application/zip',
  //     'application/x-zip-compressed',
  //     'video/mp4',
  //     'video/avi',
  //     'video/mpeg',
  //     'video/quicktime',
  //     'video/x-msvideo',
  //     'audio/mpeg',
  //     'audio/ogg',
  //     'audio/wav',
  //     'application/json'
  //   ];



  //   const unsupportedFiles: File[] = [];

  //   Array.from(files).forEach(file => {
  //     if (!supportedFileTypes.includes(file.type)) {
  //       unsupportedFiles.push(file);
  //     } else {
  //       this.fileList.push({
  //         uid: file.name,
  //         name: file.name,
  //         size: file.size,
  //         type: file.type,
  //         thumbUrl: URL.createObjectURL(file),
  //         loading: true,
  //         addedBy: idd,
  //         createdOn:  new Date(),
  //         modifiedOn: new Date(),
  //         id: this.fileIdCounter++, // Assign and increment the file ID counter
  //         globalId: uuidv4() // Generate a unique global ID
  //       });

  //       // Simulate loading time based on file size
  //       this.simulateUpload(file);
  //     }
  //   });

  //   if (unsupportedFiles.length > 0) {
  //     this.toastService.error('The file type selected is not supported for this field.');
  //   }
  // }

  simulateUpload(file: File): void {
    const fileItem = this.fileList.find(item => item.name === file.name);
    if (fileItem) {
      const fileSizeMB = file.size / (1024 * 1024); // MB
      const simulatedDuration = fileSizeMB * 1000; // Adjust as needed

      setTimeout(() => {
        fileItem.loading = false;
      }, simulatedDuration);
    }
  }

  removeFile(file: CustomUploadFile): void {
    this.fileList = this.fileList.filter(item => item.uid !== file.uid);
  }

  clearAll(): void {
    this.fileList = [];
  }

  uploadFilesTransferLeadOpp(): void {
    this.UploadFile.emit(this.fileList);
    this.cancel.emit();
  }
  getFileIcon(file: CustomUploadFile): { type: string, color: string, iconUrl?: string } | null {
    const fileType = file.type;
    if (fileType.includes('image')) {
      return null;
    } else if (fileType.includes('pdf')) {
      return { type: 'file-pdf', color: 'rgb(236, 32, 49)' };
    } else if (fileType.includes('word')) {
      return { type: 'file-word', color: 'rgb(36, 88, 154)' };
    } else if (fileType.includes('excel') || fileType.includes('spreadsheet')) {
      return { type: 'file-excel', color: 'rgb(56, 127, 70)' };
    } else if (fileType.includes('ppt') || fileType.includes('presentation')) {
      return { type: 'file-ppt', color: 'rgb(223, 131, 38)' };
    } else if (fileType.includes('x-rar-compressed') || fileType.includes('zip')) {
      return { type: 'file-zip', color: 'rgb(33, 150, 243)' };
    } else if (fileType.includes('video')) {
      return { type: 'video-camera', color: 'rgb(198, 198, 197)' };
    } else {
      return { type: 'file-text', color: 'rgb(198, 198, 197)' };
    }
  }
  images = [
    { src: 'assets/Attachment/videoThumbnail.jpg', thumb: 'assets/Attachment/videoThumbnail.jpg', videoSrc: 'assets/Attachment/CmsClient - Google Chrome 2024-04-23 16-15-37.mp4' }
  ];
  open(index: number): void {
    const video = this.images[index].videoSrc;
    const thumb = this.images[index].thumb;  // Use thumbnail for video

    const videoLightbox = {
      src: video,
      type: 'video',
      caption: 'Video Player',
      thumb: thumb  // Add thumb property
    };
  }

  close(): void {
  }
}
