<nz-modal [(nzVisible)]="isVisible" [nzTitle]="modalTitle" [nzFooter]="modalFooter" [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    maxHeight: '75vh',
    overflowY: 'auto'
  }" [nzStyle]="{ top: '15px'}" [nzWidth]="'90%'" (nzOnCancel)="closeDialog()">
  <ng-template #modalTitle>
    <div class="row">
      <h2 class="p-0 m-0 famly-inn" *ngIf="internalHeader?.length > 0">{{ internalHeader }}</h2>
      <h2 class="p-0 m-0 famly-inn" *ngIf="!internalHeader || internalHeader.length === 0">Add Internal User</h2>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <div class="spinner-wrapper" *ngIf="isLoading">
      <nz-spin nzSimple [nzSpinning]="true" [nzSize]="'large'"></nz-spin>
    </div>
    <nz-alert class="rounded-3 mb-3 mt-2 error-message" *ngIf="isSubmitted && errorFields.length > 0" nzType="error"
      nzShowIcon [nzMessage]="message" [nzDescription]="errorDescriptionTemplate"></nz-alert>
    <ng-template #message>
      <p class="mt-1">Please correct the following fields:</p>
    </ng-template>
    <ng-template #errorDescriptionTemplate>
      <ul>
        <li class="sub-text p-0 m-0 " *ngFor="let error of errorFields">
          <span class="d-flex align-items-center">
            <p>{{ error.label }}:</p>
            <p> {{ error.message }}</p>
          </span>
        </li>
      </ul>
    </ng-template>

    <div class="card border-0 mt-0" *ngIf="internalUserResponse">
      <div class="card-head border-bottom">
        <h3 class="ms-3 famly-inn pt-2 pb-2">User Information</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-1 pr-0 mr-0">
            <nz-avatar class="justify-content-md-center" nzText="AH" nzSrc="assets/user/user.jpg" [nzSize]="65"
              style="color: #8b1439;background-color: #ffb9a8;font-size: 25px;"></nz-avatar>
          </div>
          <div class="col-11 pl-0 ml-0">
            <h2 class="ant-typography famly-inn">{{ internalUserResponse?.fullName }}</h2>
            <div class="row">
              <div class="col-12">
                <span nz-icon nzType="mail" nzTheme="outline"></span>
                <a class="ms-2">{{internalUserResponse.label}} {{ internalUserResponse.email }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-2">
            <button nz-button class="button-default famly-inn pt-0 mt-0 border-0 p-0 m-0" nz-popover
              [nzPopoverContent]="ChatTemplate"><i class="bi bi-chat-dots"></i></button>
            <ng-template #ChatTemplate>
              <p style="height: 20px">Send Message</p>
            </ng-template>
            <button nz-button class="button-default famly-inn pt-0 mt-0 border-0 p-0 m-0" nz-popover
              [nzPopoverContent]="telephoneTemplate">
              <i class='bx bx-phone bx-rotate-270'></i></button>
            <ng-template #telephoneTemplate>
              <p style="height: 20px">Call {{ internalUserResponse.phoneNumber }}</p>
            </ng-template>
            <button *ngIf="internalUserResponse.cellPhone" nz-button
              class=" p-0 m-0 button-default famly-inn pt-0 mt-0 border-0" nz-popover
              [nzPopoverContent]="CellPhoneTemplate"><i class='bx bx-mobile-alt'></i></button>
            <ng-template #CellPhoneTemplate>
              <p style="height: 20px">Call Cell Phone {{ internalUserResponse.cellPhone }}</p>
            </ng-template>
          </div>
        </div>
        <div class="row mt-1">
          <span><nz-tag [nzColor]="'blue'" class="rounded-5 fw-bold">MFA ENABLE</nz-tag>
            Multi Factor authentication is turned
            <span class="fw-bold">on</span> for this user
            <span class="text-primary">Learn More</span>
          </span>
        </div>
      </div>
    </div>
    <div class="card-container">
      <nz-tabset nzType="card" class="p-0 m-0">
        <nz-tab *ngFor="let tab of tabs" [nzTitle]="getTabTitle(tab)">
          <ng-container *ngIf="tab === 1">
            <div class="card border-0 mt-0">
              <div class="card-head border-bottom">
                <div class="row pt-2 pb-2">
                  <div class="col-6">
                    <h3 class="ms-3 famly-inn">Overview</h3>
                  </div>
                  <div class="col-6 d-flex justify-content-end align-items-end">
                    <span class="famly-inn pb-2" *ngIf="userInvited">User has not been invited</span>
                    <span class="famly-inn pb-2" *ngIf="!userInvited">Invite will be sent on save.</span>
                    <button nz-button class="button-default famly-inn m-0 p-0 ms-2 me-2" (click)="toggleInviteStatus()">
                      <span>{{ userInvited ? "Invite User" : "Cancel Invite" }}</span></button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-4">
                        <label class="sub-text">First Name<span class="text-danger fw-bolder ms-1">*</span></label>
                        <nz-form-item>
                          <nz-form-control>
                            <input nz-input [(ngModel)]="internalUserFormFieldsValue.firstName" name="firstName"
                              required (ngModelChange)="onFieldChange('firstName', $event)"
                              [nzStatus]="validationStates['firstName']" class="formField" />
                          </nz-form-control>
                          <div *ngIf="validationStates['firstName'] === 'error'" class="text-danger">
                            {{ validationMessages['firstName'] }}
                          </div>
                        </nz-form-item>
                      </div>
                      <div class="col-4">
                        <label class="sub-text">Last Name<span class="text-danger fw-bolder ms-1">*</span></label>
                        <nz-form-item>
                          <nz-form-control>
                            <input nz-input [(ngModel)]="internalUserFormFieldsValue.lastName" name="lastName" required
                              (ngModelChange)="onFieldChange('lastName', $event)"
                              [nzStatus]="validationStates['lastName']" class="formField" />
                          </nz-form-control>
                          <div *ngIf="validationStates['lastName'] === 'error'" class="text-danger">
                            {{ validationMessages['lastName'] }}
                          </div>
                        </nz-form-item>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <label class="sub-text">Primary Email<span *ngIf="userInvited === false"
                            class="text-danger fw-bolder ms-1">*</span></label>
                        <nz-form-item>
                          <nz-form-control>
                            <input nz-input [(ngModel)]="internalUserFormFieldsValue.email" name="email" required
                              (ngModelChange)="onFieldChange('email', $event)" [nzStatus]="validationStates['email']"
                              class="formField" type="email" />
                          </nz-form-control>
                          <div *ngIf="validationStates['email'] === 'error'" class="text-danger">
                            {{ validationMessages['email'] }}
                          </div>
                        </nz-form-item>
                      </div>
                      <div class="col-4">
                        <label class="sub-text">Label</label>
                        <nz-form-item>
                          <nz-form-control>
                            <input nz-input [(ngModel)]="internalUserFormFieldsValue.label" name="label"
                              (ngModelChange)="onFieldChange('label', $event)" [nzStatus]="validationStates['label']"
                              class="formField" type="text" />
                          </nz-form-control>
                          <div *ngIf="validationStates['label'] === 'error'" class="text-danger">
                            {{ validationMessages['label'] }}
                          </div>
                        </nz-form-item>
                      </div>
                    </div>
                    <ng-container *ngFor="let item of items; let i = index">
                      <div class="row">
                        <div class="col-4">
                          <label class="sub-text">Additional Email<span class="text-danger fw-bolder">*</span></label>
                        </div>
                        <div class="col-4">
                          <label class="sub-text">Label</label>
                        </div>
                        <div class="col-1"></div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <nz-form-item>
                            <nz-form-control>
                              <input nz-input [(ngModel)]="item.email" name="email_{{i}}" required
                                (ngModelChange)="onFieldChange('email_' + i, $event)"
                                [nzStatus]="validationStatestest123['email_' + i]" class="formField" type="email" />
                            </nz-form-control>
                            <div *ngIf="validationStatestest123['email_' + i] === 'error'" class="text-danger">
                              Required
                            </div>
                          </nz-form-item>
                        </div>
                        <div class="col-4">
                          <nz-form-item>
                            <nz-form-control>
                              <input nz-input [(ngModel)]="item.label" name="label_{{i}}"
                                (ngModelChange)="onFieldChange('label_' + i, $event)"
                                [nzStatus]="validationStates['label_' + i]" class="formField" type="text" />
                            </nz-form-control>
                            <div *ngIf="validationStates['label_' + i] === 'error'" class="text-danger">
                              {{ validationMessages['label_' + i] }}
                            </div>
                          </nz-form-item>
                        </div>
                        <div class="col-1">
                          <a (click)="deleteRow(i)"><span style="font-size: 26px;" nz-icon nzType="delete"
                              nzTheme="fill"></span></a>
                        </div>
                      </div>
                    </ng-container>
                    <div *ngIf="items.length < maxItems && isEmailValid()">
                      <div class="col-4">
                        <a (click)="addAdditionalEmail()" class="fs-5" nz-icon nzType="plus-circle" nzTheme="fill"></a>
                        <a (click)="addAdditionalEmail()" class="ms-2" style="bottom: 100;">Add Additional Email</a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-5">
                        <label class="sub-text">Phone Number</label>
                        <nz-form-item>
                          <nz-form-control [nzErrorTip]="getErrorTip('phoneNumber')">
                            <input nz-input type="number" [(ngModel)]="internalUserFormFieldsValue.phoneNumber"
                              name="phoneNumber" #stateInput="ngModel"
                              (ngModelChange)="onFieldChange('phoneNumber', $event)" class="form-control formField"
                              [nzStatus]="
                                  internalUserFormFieldsValue.phoneNumber?.length > 25 ? 'error' : null
                                " />
                          </nz-form-control>
                          <div *ngIf="internalUserFormFieldsValue.phoneNumber?.length > 25" class="text-danger">
                            {{ internalUserFormFieldsValue.phoneNumber?.length - 25 }} characters over.
                          </div>
                        </nz-form-item>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-5">
                        <label class="sub-text">Cell Phone</label>
                        <div class="wrapper">
                          <div class="wrapper">
                            <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                              [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                              [selectFirstCountry]="true" [selectedCountryISO]="internalUserFormFieldsValue.countryCode"
                              [phoneValidation]="true" [separateDialCode]="separateDialCode"
                              [numberFormat]="PhoneNumberFormat.National"
                              [(ngModel)]="internalUserFormFieldsValue.cellPhone" name="cellPhone" #phoneInput="ngModel"
                              (ngModelChange)="onPhoneInputChange()">
                            </ngx-intl-tel-input>
                            <div *ngIf="phoneInput.invalid && (phoneInput.dirty || phoneInput.touched)"
                              class="text-danger">Invalid phone number</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <label class="sub-text"> Receive Text Messages
                          <i class="bi bi-info-circle-fill text-secondary" nz-popover
                            [nzPopoverContent]="contentTemplate" nzPopoverPlacement="right"></i>
                          <ng-template #contentTemplate>
                            <div class="rounded-2">
                              <p style="height: 60px; width: 180px">
                                To receive text messages please review your
                                <a href="#">notification preferences</a>.
                              </p>
                            </div>
                          </ng-template>
                        </label>
                        <button nz-button class="button-default famly-inn m-0 p-0 me-2"><span
                            class="bi bi-phone mr-0 pr-0"></span><span class="ml-0 pl-0 ms-2 me-2">Verify Phone
                            Number</span></button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-5">
                        <label class="sub-text">Role<span class="text-danger fw-bolder ms-1">*</span></label>
                      </div>
                      <div class="col-3">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-5">
                        <nz-form-item>
                          <nz-form-control>
                            <nz-select [(ngModel)]="internalUserFormFieldsValue['applicationGroupId']"
                              name="applicationGroupId" required
                              (ngModelChange)="onFieldChange('applicationGroupId', $event)"
                              [nzStatus]="validationStates['applicationGroupId']" [style.width.%]="100">
                              <nz-option *ngFor="let option of roles" [nzLabel]="option.name"
                                [nzValue]="option.id"></nz-option>
                            </nz-select>
                          </nz-form-control>
                          <div *ngIf="validationStates['applicationGroupId'] === 'error'" class="text-danger">
                            {{ validationMessages['applicationGroupId'] }}
                          </div>
                        </nz-form-item>
                      </div>
                      <div class="col-3">
                        <a style="text-decoration: underline; font-size: 18px;">Manage Roles</a>
                      </div>
                    </div>
                    <div class="ng-container rounded-2 role-card" *ngIf="selectedRole">
                      <nz-card style="width: 60%">
                        <div nz-row>
                          <div nz-col nzSpan="18">
                            <div class="row">
                              <div class="col">
                                <h5>{{ selectedRole.name }}</h5>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col">
                                <p>{{ selectedRole.description }}</p>
                              </div>
                            </div>
                          </div>
                          <div nz-col nzSpan="6">
                            <div class="row">
                              <div class="col text-end text-primary mb-2">
                                <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </nz-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="tab === 2">
            <div class="card border-0 mt-0">
              <div class="card-head border-bottom">
                <div class="row pt-2 pb-2">
                  <h3 class="ms-3 famly-inn">Permissions</h3>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-5">
                        <label class="sub-text" for="companyName">Role<span
                            class="text-danger fw-bolder">*</span></label>
                      </div>
                      <div class="col-3">
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-5">
                        <nz-form-item>
                          <nz-form-control>
                            <nz-select [(ngModel)]="internalUserFormFieldsValue['applicationGroupId']"
                              name="applicationGroupId" required
                              (ngModelChange)="onFieldChange('applicationGroupId', $event)"
                              [nzStatus]="validationStates['applicationGroupId']" [style.width.%]="100">
                              <nz-option *ngFor="let option of roles" [nzLabel]="option.name"
                                [nzValue]="option.id"></nz-option>
                            </nz-select>
                          </nz-form-control>
                          <div *ngIf="validationStates['applicationGroupId'] === 'error'" class="text-danger">
                            {{ validationMessages['applicationGroupId'] }}
                          </div>
                        </nz-form-item>
                      </div>
                      <div class="col-3">
                        <a style="text-decoration: underline; font-size: 18px;">Manage Roles</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ng-container *ngIf="roleId">
              <permission [roleId]="roleId" [selectedRole]="selectedRole"></permission>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="tab === 3">
            <div class="card border-0 mt-0">
              <div class="card-head border-bottom">
                <div class="row pt-2 pb-2">
                  <h3 class="ms-3 famly-inn">Notifications</h3>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12 mb-3">
                    <p class=" famly-inn">
                      Browser notifications are turned on. To stop receiving
                      browser notifications, go to your browser settings and
                      choose to block notifications from Buildertrend.net.
                    </p>
                  </div>
                </div>
                <notification></notification>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="tab === 4">
            <div class="card border-0 mt-0">
              <div class="card-head border-bottom">
                <div class="row pt-2 pb-2">
                  <h3 class="ms-3 famly-inn">Messaging</h3>
                </div>
              </div>
              <div class="card-body">
                <div class="row mb-xxl-3">
                  <div class="col">
                    <div class="fw-medium fs-6">Message Preferences</div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col d-flex align-items-center">
                    <label nz-checkbox [(ngModel)]="internalUserFormFieldsValue['isSetOutOfOfficeStatus']"
                      name="isSetOutOfOfficeStatus">Set Out of Office Status
                    </label>
                    <span nz-icon nzType="info-circle" nz-popover [nzPopoverContent]="infoContent"
                      nzTheme="outline"></span>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-6">
                    <div class="fw-medium dropdownField mb-2">
                      Message Preferences
                    </div>
                    <div class="row">
                      <div class="editor-container">
                        <kendo-editor [(ngModel)]="internalUserFormFieldsValue['messageSignature']"
                          name="messageSignature" [value]="editorValue"
                          [style]="{ 'flex-direction': 'column-reverse' }"></kendo-editor>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-6">
                    <div class="fw-medium dropdownField mb-2">
                      Message Signature Image
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="signatureImage">
                          <img *ngIf="uploadedImageUrl; else defaultImage" [src]="uploadedImageUrl" alt="Signature">
                          <ng-template #defaultImage>
                            <img src="assets/claimNoPhoto.gif" alt="Signature">
                          </ng-template>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-3">
                        <nz-upload
                          [(nzFileList)]="fileList"
                          [nzShowButton]="fileList.length < 2"
                          (nzChange)="handleChange($event)"
                          [nzRemove]="handleRemove"
                          [nzBeforeUpload]="beforeUpload"
                          showPreviewIcon="false"
                          showDownloadIcon="false"
                          nzShowUploadList="false">
                          <button nz-button *ngIf="!uploadedImageUrl" class="button-default famly-inn m-0 p-0 me-2">
                            <span nz-icon nzType="upload" class="mr-0 pr-0"></span>
                            <span class="ml-0 pl-0 ms-2 me-2">Choose File</span>
                          </button>
                          <button nz-button *ngIf="uploadedImageUrl" class="button-default famly-inn m-0 p-0 me-2">
                            <span nz-icon nzType="upload" class="mr-0 pr-0"></span>
                            <span class="ml-0 pl-0 ms-2 me-2">Replace File</span>
                          </button>
                        </nz-upload>
                      </div>
                      <div class="col-1 ms-4">
                        <a *ngIf="uploadedImageUrl" (click)="deleteImage()">
                          <span style="font-size: 26px;margin-top: 5px;" nz-icon nzType="delete" nzTheme="fill"></span>
                        </a>
                      </div>
                    </div>
                    <div class="row mt-1">
                      <div class="col-12">
                        <div nz-row class="file-name-container" *ngIf="fileList.length > 0">
                          <div nz-col nzSpan="1">
                            <i class="ms-1" nz-icon nzType="paper-clip" nzTheme="outline"></i>
                          </div>
                          <div nz-col nzSpan="22">
                            <a class="famly-inn pl-0 ml-0"><span (click)="downloadImage()">{{ fileList[0]?.name }}</span></a>
                          </div>
                          <div nz-col nzSpan="1">
                            <span (click)="deleteImage()" nz-icon nzType="delete" nzTheme="outline"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #infoContent>
              <div class="rounded-2">
                <p class="small" style="height: auto; width: 230px">
                  Turning on Out of Office does not prevent others from
                  sending you messages.
                </p>
              </div>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="tab === 5">
            <div class="card border-0 mt-0">
              <div class="card-head border-bottom">
                <div class="row pt-2 pb-2">
                  <div class="col-6">
                    <h3 class="ms-3 famly-inn">Job Access</h3>
                  </div>
                  <div class="col-6 d-flex justify-content-end align-items-end">
                    <button nz-button class="button-default famly-inn ms-2 me-2" (click)="openFilter()"><span
                        class="bi bi-funnel ml-0 pl-0 mr-0 pr-0"></span><span class="ml-0 pl-0 mr-0 pr-0 ms-2">Filter</span></button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row mt-2 ms-1">
                  <div class="col">
                    <label for="Existing Jobs" class="sub-text">Existing Jobs:</label>
                    <div class="row ms-0">
                      <label nz-checkbox [(ngModel)]="checked">
                        Grant access to all existing jobs and templates
                        <i class="mb-1" nz-tooltip nzTooltipTitle="Prompt text" [nzTooltipPlacement]="'topLeft'"
                          nz-button nz-icon nzType="info-circle" style="color: #666d7c"></i>
                      </label>
                    </div>
                  </div>
                  <div class="col">
                    <label for="New Jobs" class="sub-text">New Jobs:</label>
                    <div class="row ms-0">
                      <label nz-checkbox [(ngModel)]="checked">Grant automatic access to new jobs and
                        templates</label>
                    </div>
                  </div>
                </div>
                <nz-drawer nzWidth="19%" [nzClosable]="false" [nzVisible]="Filter" nzPlacement="right"
                  [nzTitle]="CustomFilterTitle" (nzOnClose)="closeFilter()" [nzFooter]="CustomFilterFooter">
                  <ng-template #CustomFilterTitle>
                    <div class="row">Filter</div>
                  </ng-template>
                  <ng-container *nzDrawerContent>
                    <div class="row">
                      <div class="col mt-1 p-0">
                        <label class="sub-text">Job Name</label>
                        <input type="text" class="form-control formField" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col mt-1 p-0">
                        <label class="sub-text">Status </label>
                        <nz-select ngModel="lucy">
                          <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
                          <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
                        </nz-select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col mt-1 p-0">
                        <label class="sub-text">Groups</label>
                        <nz-select nzMode="tags" nzPlaceHolder="Tag Mode">
                          <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
                          <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
                        </nz-select>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #CustomFilterFooter>
                    <div class="row">
                      <div class="col text-end">
                        <button nz-button nzType="primary">Clear All</button>
                        <button nz-button nzType="default" class="ms-1">
                          Filter
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </nz-drawer>
                <div class="mt-4">
                  <job-access></job-access>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="tab === 6">
            <div class="card border-0 mt-0">
              <div class="card-head border-bottom">
                <div class="row pt-2 pb-2">
                  <h3 class="ms-3 famly-inn">Security & Login</h3>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="row mb-3">
                      <div class="col">
                        <label class="sub-text fw-medium fs-6 mb-1" for="displayName">Login Access</label>
                        <nz-tag nz-popover [nzPopoverContent]="ActiveTemplate" class="active">ACTIVE <i
                            class="bi bi-info-circle-fill ms-1"></i></nz-tag>
                        <ng-template #ActiveTemplate>
                          <p>Active since Jun 21, 2023</p>
                        </ng-template>
                        <nz-tag class="inactive">INACTIVE</nz-tag>
                        <nz-tag class="invitepending">INVITE PENDING</nz-tag>
                        <nz-tag class="archived">ARCHIVED</nz-tag>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col d-flex align-items-center">
                        <span>
                          <nz-switch [(ngModel)]="internalUserFormFieldsValue['loginAccess']" name="loginAccess"
                            id="Toggle"></nz-switch>
                          <label class="ms-2" for="Toggle">Active</label>
                        </span>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-12">
                        <label class="sub-text fw-medium fs-6 mb-2" for="displayName">Username</label>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <span class="mb-4">bt1irrat&#64;ionalidiom.com</span>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-12">
                        <label class="sub-text fw-medium fs-6 mb-2" for="displayName">Password</label>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-12">
                        <button nz-button nzType="default">
                          Rest Password
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text fw-medium fs-6 mb-2" for="displayName">Archive</label>
                      </div>
                      <div class="col-12">
                        <button nz-button nzType="default">Archive User</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="tab === 7">
            <div class="card border-0 mt-0">
              <div class="card-head border-bottom">
                <div class="row pt-2 pb-2">
                  <h3 class="ms-3 famly-inn">Other</h3>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="row mb-3">
                      <div class="col">
                        <span class="fw-medium">Schedule</span>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col d-flex align-items-center">
                        <div class="d-flex align-items-center">
                          <label nz-checkbox [(ngModel)]="internalUserFormFieldsValue['isScheduleNotifyMe']"
                            name="isScheduleNotifyMe">Notify me when</label>
                          <!-- [nzDisabled]="
                            internalUserForm.get('scheduleNotifyMeWhen')
                              ?.disabled -->
                          <nz-input-number [(ngModel)]="internalUserFormFieldsValue['scheduleNotifyMeWhen']"
                            name="scheduleNotifyMeWhen"></nz-input-number>
                          <span class="ms-2">
                            or more schedule items conflict (minimum 2).
                          </span>
                        </div>
                        s
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col">
                        <span class="fw-medium">External Calendar Integrations</span>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col">
                        <a href="">Learn how to Create a feed for your iCal, google
                          Calendar, or Outlook</a>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col">
                        <span class="fw-medium">Schedule Items</span>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-4">
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="---All Schedule Item Selected---"
                          [(ngModel)]="selectedValue">
                          <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                          <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                          <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                        </nz-select>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col">
                        <button nz-button nzType="default">Create Feed</button>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col">
                        <span class="fw-medium">Lead Activity</span>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-4">
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="---All Activity Item Selected---"
                          [(ngModel)]="selectedValue">
                          <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                          <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                          <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                        </nz-select>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col">
                        <button nz-button nzType="default">Create Feed</button>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col">
                        <span class="fw-medium">To-Do's</span>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-4">
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="---All ToDo's Selected---"
                          [(ngModel)]="selectedValue">
                          <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                          <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                          <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                        </nz-select>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col">
                        <button nz-button nzType="default">Create Feed</button>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col">
                        <span class="fw-medium">Time Clock</span>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col">
                        <label nz-checkbox [(ngModel)]="internalUserFormFieldsValue['isTimeClockRequiredLocation']"
                          name="isTimeClockRequiredLocation">
                          <p>
                            Require location on mobile and track when clocks in
                            or out beyond the geofence.
                          </p>
                        </label>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-4">
                        <div class="form-group mb-2">
                          <label class="sub-text" for="companyName">Default Labor Cost Code
                          </label>
                          <nz-select nzPlaceHolder="--Choose Cost Code--"
                            [(ngModel)]="internalUserFormFieldsValue['costCodeId']" name="costCodeId" nzShowSearch
                            nzInput>
                            <nz-option *ngFor="let costCode of CoseCode" [nzLabel]="
                                costCode.title + ' - ' + costCode.detail
                              " [nzValue]="costCode.id"></nz-option>
                          </nz-select>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center mb-3">
                      <div class="col-4">
                        <div class="form-group">
                          <label class="sub-text" for="companyName">Default Labor Cost</label>
                          <input nz-input class="formField"
                            [(ngModel)]="internalUserFormFieldsValue['defaultLaborCost']" name="defaultLaborCost"
                            type="number" />
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col">
                        <span class="fw-medium">Contact</span>
                      </div>
                    </div>

                    <div class="row align-items-center">
                      <div class="col-7">
                        <div class="form-group mb-2">
                          <label class="sub-text" for="companyName">Contact Information
                          </label>
                          <textarea [(ngModel)]="internalUserFormFieldsValue['contactInformation']"
                            name="contactInformation" nz-input [nzAutosize]="{ minRows: 2, maxRows: 9 }"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </nz-tab>
      </nz-tabset>
    </div>
  </div>
  <ng-template #modalFooter>
    <div class="d-flex justify-content-end align-items-end">
      <button *ngIf="internalUserResponse !== undefined" nz-button class="button-default famly-inn m-0 p-0" [nzLoading]="isDeleteLoading" (click)="deleteInternalUser()"><span>Delete</span></button>
      <button nz-button class="button-primary famly-inn m-0 p-0 ms-2" [nzLoading]="isSaveAndNew"
        (click)="saveInternalUserAndNew()"><span>Save & New</span></button>
      <button nz-button class="button-primary famly-inn m-0 p-0 ms-2" [nzLoading]="isSave"
        (click)="saveInternalUser()"><span>Save</span></button>
      <button nz-button class="button-primary famly-inn m-0 p-0 ms-2" [nzLoading]="isSaveAndClose"
        (click)="saveInternalUserAndClose()"><span>Save & Close</span></button>
    </div>
  </ng-template>
</nz-modal>