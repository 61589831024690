import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilesRoutingModule } from './files-routing.module';
import { SharedModule } from 'src/shared/shared.module';
import { DocumentsDashboardComponent } from './Documents/documents-dashboard/documents-dashboard.component';
import { DocumentEmptyComponent } from './Documents/document-empty/document-empty.component';
import { PhotoDashboardComponent } from './photos/photo-dashboard/photo-dashboard.component';
import { PhotoEmptyComponent } from './photos/photo-empty/photo-empty.component';
import { VideosDashboardComponent } from './videos/videos-dashboard/videos-dashboard.component';
import { VideosContentComponent } from './videos/videos-content/videos-content.component';
import { AttachmentfileUploadComponent } from './attachmentfile-upload/attachmentfile-upload.component';

@NgModule({
  declarations: [
    DocumentsDashboardComponent,
    DocumentEmptyComponent,
    PhotoDashboardComponent,
    PhotoEmptyComponent,
    VideosDashboardComponent,
    VideosContentComponent,
    AttachmentfileUploadComponent
  ],
  imports: [
    CommonModule,
    FilesRoutingModule,
    SharedModule,
  ]
})
export class FilesModule { }
