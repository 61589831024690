<nz-modal nzWidth="50%" 
[nzFooter]="footer"
 [(nzVisible)]="manageEmailModal" 
 [nzTitle]="surveys1"
  [nzContent]="modalContent" 
  (nzOnCancel)="manageEmailsclose()"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '55vh', 'overflow-y': 'auto'}"
  [nzStyle]="{ top: '30px' }">

  <ng-template #surveys1>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0 fw-bold">Manage Emails </h2>
    </div>
  </ng-template>
  <form [formGroup]="manageEmail" >


  <ng-template #modalContent>
    <nz-card nzBorderless="true" [nzTitle]="ManageEmail">
        <ng-template #ManageEmail>
            <h4 class="fw-medium famly-inn">Manage Emails</h4>
        </ng-template>
            <div class="row">
              <!-- Use `emails.controls` to iterate over the FormArray controls -->
              <div class="col-7">
                <label class="sub-text">Email<span class="text-danger fw-bolder ms-1">*</span></label>
                <input class="form-control formField"  formControlName="email" />
              </div>
              <div class="col-4">
                <div class="input-container">
                  <label class="sub-text">Label</label>
                  <div class="input-wrapper">
                    <input   class="form-control formField" formControlName="label" />
                  </div>
                </div>
              </div>
              <div class="col-1">
                <i  nz-icon nzType="delete" nzTheme="fill" style="font-size: 20px;" class="trash-icon"></i>
              </div>
            </div>
    </nz-card>  
    <div class="row">
        <div class="col">
            <nz-card>
                <div class="row">
                  <div class="col">
                    <button class="btn text-primary ms-1" style="cursor: pointer;padding: 5px;"><i class="bi bi-plus-circle-fill fs-5 me-2"></i>Add Email</button>
                  </div>
                </div>
            </nz-card>
            
            
        </div>
    </div>
  </ng-template>
  <ng-template #footer >
    <button nz-button nzType="default" >Cancel</button>
    <button nz-button nzType="primary" >Done </button>
  </ng-template>
</form>

</nz-modal>