import { FileDecumentService } from 'src/Service/FileDocumnet/file-decument.service';
import { AccessLocalStorageService } from './../../../../Service/access-local-storage.service';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FileInformationResponse } from 'src/Models/FilesModules/FilesDocument';
import { ApplicationUserForComboResponse } from 'src/Models/InternalUser/InternalUserJob';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-document-create-folder',
  templateUrl: './document-create-folder.component.html',
  styleUrls: ['./document-create-folder.component.css']
})
export class DocumentCreateFolderComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Input() savedId: FileInformationResponse;
  @Output() saveSuccess = new EventEmitter<string>();
  serviceCoordinator: FileInformationResponse[] = [];
  @Input() showFields: boolean = true;
  selectedJobId: number;
  formFieldValues: any = {};
  docunmentCreatevisible = true;
  isDocument: boolean;
  isPhoto: boolean;
  isVedio: boolean;
  id: number;

  constructor(
    private localStorage: AccessLocalStorageService,
    @Inject(NZ_MODAL_DATA) public modalData: any,
    private FileDecumentService: FileDecumentService
  ) {
    this.showFields = modalData.showFields;
    this.isDocument = modalData.isDocument;
    this.isPhoto = modalData.isPhoto;
    this.isVedio = modalData.isVedio;
  }

  ngOnInit(): void {
    this.selectedJobId = this.localStorage.getJobId();
    if (this.selectedJobId) {
      this.getAllFilesByJob(this.selectedJobId);
      console.log("Show file data on behalf of job id", this.selectedJobId);
    } else {
      console.error('No Job ID found');
    }

    this.formFieldValues = {
      globalId: "00000000-0000-0000-0000-000000000000",
      jobInformationId: this.selectedJobId,
      fileInformationId: 0,
      title: "",
      isGlobal: true,
      folderName: "",
      isFolder: false,
      isDocument: this.isDocument,
      isPhoto: this.isPhoto,
      isVedio: this.isVedio,
      sourceUrl: "",
      isViewSubVendor: true,
      isViewOwner: true,
      isMoveToTrash: false,
      isAllowSubVendorViewFolder: true,
      isAllowSubVendorUpload: true,
      isAllowOwnerViewFolder: true,
      isAllowOwnerUpload: false,
      createFileInternalUserRequests: [],
      createFileSubVendorRequests: []
    };


  }

  createFolderModelCancel(): void {
    this.cancel.emit();
  }

  save(): void {
    this.FileDecumentService.PostFileDocument(this.formFieldValues).subscribe(
      (res) => {
        const savedId = res?.result?.id;
        this.docunmentCreatevisible = false;
        console.log('Saved ID:', savedId);
        if (savedId) {
          this.FileDecumentService.changeSavedId(savedId.toString());
        } else {
          console.warn('No ID found in the response.');
        }
        console.log('Response:', res);
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }
  // ForCombo() {
  //   this.FileDecumentService.getData().subscribe((res) => {
  //     const serviceCoordinator = res.result;
  //     this.serviceCoordinator = serviceCoordinator;

  //   });
  // }



  getAllFilesByJob(JobInformationId: number): void {
    this.FileDecumentService.getDatabyJob(JobInformationId).subscribe(
      (response) => {
        this.serviceCoordinator = response.result;
      },
      (error) => {
        console.error('Error fetching job data', error);
      }
    );
  }

}
