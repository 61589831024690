<nz-modal nzWidth="75%" [(nzVisible)]="LeadOpportunityAddcontact" [nzTitle]="Addcontact"
    [nzFooter]="LeadOpportunityAddcontactFooter"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '0px', 'max-height': '76vh', 'overflow-y': 'auto','border':'0px' }"
    [nzStyle]="{ top: '37px' }" (nzOnCancel)="LeadOpportunityAddcontactCancel()">


    <ng-template #Addcontact>
        <h2>Lead Contact Form</h2>
    </ng-template>


    <div class="content" *nzModalContent>
        <div class="container-fluid">
            <div class="row p-0">
                <div class="col-4 p-0">
                    <div class="long-div">
                        <div class="long-div-inner">
                            <nz-page-header class="headset p-2">
                                <!--title-->
                                <nz-page-header-title class="ms-3">Fields</nz-page-header-title>
                                <!--extra-->
                                <nz-page-header-extra>
                                    <nz-space>
                                        <button *nzSpaceItem nz-button nzType="default" class="rounded-1" (click)="addCustomFieldsOpen()">Add</button>
                                    </nz-space>
                                </nz-page-header-extra>
                            </nz-page-header>
                            <nz-collapse [nzBordered]="false">
                                <nz-collapse-panel #p1 [ngStyle]="{'background': '#f1f4fa','border-radius': '4px',
                                                      'margin-bottom': '0px',
                                                      border: '0px'}" nzTitle="Custom Column"
                                    [nzExpandedIcon]="expandedIcon1st" [nzHeader]="title1st">

                                    <div class="row mt-0" #p1>
                                        <div nz-row [nzGutter]="24">
                                            <div nz-col class="gutter-row " [nzSpan]="24">
                                                <div class=" mb-2 rounded-2" style="width: 100%;">
                                                    <div class="example-list  rounded-2"
                                                    cdkDropList #todoList="cdkDropList" [cdkDropListData]="fix"
                                                    [cdkDropListConnectedTo]="[doneList]"
                                                    (cdkDropListDropped)="drop($event)"
                                                    >
                                                        <div class="row rounded-3 mb-1  example-box"
                                                        *ngFor="let item of fix; let i = index"
                                                        cdkDrag
                                                        >
                                                            <div class="col-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000"
                                                                    width="30px" height="30px"
                                                                    viewBox="-64 -64 384.00 384.00" id="Flat"
                                                                    stroke="#000000" stroke-width="25.6"
                                                                    transform="rotate(0)">
                                                                    <g id="SVGRepo_bgCarrier" stroke-width="0"
                                                                        transform="translate(56.31999999999999,56.31999999999999), scale(0.56)" />
                                                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round"
                                                                        stroke-linejoin="round" stroke="#CCCCCC"
                                                                        stroke-width="0.512" />
                                                                    <g id="SVGRepo_iconCarrier">
                                                                        <path
                                                                            d="M100,60.0001a8,8,0,1,1-8-8A8.00008,8.00008,0,0,1,100,60.0001Zm64,8a8,8,0,1,0-8-8A8.00008,8.00008,0,0,0,164,68.0001Zm-72,52a8,8,0,1,0,8,8A7.99977,7.99977,0,0,0,92,120.0001Zm72,0a8,8,0,1,0,8,8A7.99977,7.99977,0,0,0,164,120.0001Zm-72,68a8,8,0,1,0,8,8A7.99977,7.99977,0,0,0,92,188.0001Zm72,0a8,8,0,1,0,8,8A7.99977,7.99977,0,0,0,164,188.0001Z" />
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                            <div class="col-10"><label class="fw-bold mt-1 "
                                                                    for="displayName">{{ item.name }}</label></div>
                                                        </div>
                                                    </div>
                                                </div>                                           
                                            </div>
                                        </div>
                                        <ng-template #title1st>
                                            <span class="LColaPP mt-1" style="background-color: #f1f4fa;">Lead
                                                Fields</span>
                                        </ng-template>
                                        <ng-template #expandedIcon1st let-active>
                                            {{ active }}
                                            <span nz-icon nzType="caret-right" class="ant-collapse-arrow"
                                                [nzRotate]="p1.nzActive ? 90 : -0"></span>
                                        </ng-template>
                                    </div>
                                </nz-collapse-panel>
                            </nz-collapse>
                            <!-- //////// -->
                            <nz-collapse [nzBordered]="false" >
                                <nz-collapse-panel #p2 [ngStyle]="{'background': '#f1f4fa','border-radius': '4px',
                                                      'margin-bottom': '0px',
                                                      border: '0px'}" nzTitle="Custom Column"
                                    [nzExpandedIcon]="expandedIcon2nd" [nzHeader]="title2st">
                                    <div class="row mt-0" #p2>
                                        <div nz-row [nzGutter]="24">
                                            <div nz-col class="gutter-row " [nzSpan]="24">
                                                <!-- <div class="example-container">
                                                    <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="fix"
                                                    [cdkDropListConnectedTo]="[doneList]"
                                                    class="example-list  rounded-2"
                                                    (cdkDropListDropped)="drop($event)">
                                                    <div class="example-box" *ngFor="let item of fix; let i = index"
                                                        cdkDrag>
                                                        {{ item.name }}
                                                        <br>
                                                    </div>  
                                                    </div>             
                                                </div> -->

                                                <div class=" mb-2" style="width: 100%;">
                                                    <div class="example-list  rounded-2"
                                                    cdkDropList #todoList="cdkDropList" [cdkDropListData]="customColumns"
                                                    [cdkDropListConnectedTo]="[doneList]"
                                                    (cdkDropListDropped)="drop($event)"
                                                    >
                                                        <div class="row rounded-3 mb-1  example-box"
                                                        *ngFor="let item of customColumns; let i = index"
                                                        cdkDrag
                                                        >
                                                            <div class="col-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000"
                                                                    width="30px" height="30px"
                                                                    viewBox="-64 -64 384.00 384.00" id="Flat"
                                                                    stroke="#000000" stroke-width="25.6"
                                                                    transform="rotate(0)">
                                                                    <g id="SVGRepo_bgCarrier" stroke-width="0"
                                                                        transform="translate(56.31999999999999,56.31999999999999), scale(0.56)" />
                                                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round"
                                                                        stroke-linejoin="round" stroke="#CCCCCC"
                                                                        stroke-width="0.512" />
                                                                    <g id="SVGRepo_iconCarrier">
                                                                        <path
                                                                            d="M100,60.0001a8,8,0,1,1-8-8A8.00008,8.00008,0,0,1,100,60.0001Zm64,8a8,8,0,1,0-8-8A8.00008,8.00008,0,0,0,164,68.0001Zm-72,52a8,8,0,1,0,8,8A7.99977,7.99977,0,0,0,92,120.0001Zm72,0a8,8,0,1,0,8,8A7.99977,7.99977,0,0,0,164,120.0001Zm-72,68a8,8,0,1,0,8,8A7.99977,7.99977,0,0,0,92,188.0001Zm72,0a8,8,0,1,0,8,8A7.99977,7.99977,0,0,0,164,188.0001Z" />
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                            <div class="col-10"><label class="fw-bold mt-1 "
                                                                    for="displayName">{{ item.name }}</label><i class="bi bi-pencil text-primary ms-2"></i></div>
                                                        </div>
                                                    </div>
                                                </div>                                               
                                            </div>
                                        </div>

                                        <ng-template #title2st>
                                            <span class="LColaPP mt-1" style="background-color: #f1f4fa;">Lead Custom Fields</span>
                                        </ng-template>
                                        <ng-template #expandedIcon2nd let-active>
                                            {{ active }}
                                            <span nz-icon nzType="caret-right" class="ant-collapse-arrow"
                                                [nzRotate]="p2.nzActive ? 90 : -0"></span>
                                        </ng-template>
                                    </div>
                                </nz-collapse-panel>
                            </nz-collapse>
                            <!-- ///////// -->


                        </div>

                    </div>


                    <nz-modal nzWidth="30%" [(nzVisible)]="isVisible" [nzTitle]="CustomField"
                        [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '13px', 'max-height': '70vh', 'overflow-y': 'auto' }"
                        [nzStyle]="{ top: '15px' }" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
                        <ng-template #CustomField>
                            <h4>Custom Field</h4>
                        </ng-template>
                        <ng-container *nzModalContent>
                            <nz-card [nzTitle]="CostomFeildInfo">
                                <ng-template #CostomFeildInfo>
                                    <h5>Costom Feild Info</h5>
                                </ng-template>
                                <div class="row">
                                    <div class="col">
                                        <label class="sub-text">
                                            Label
                                            <span class="text-danger fw-bolder">*</span>
                                        </label>
                                        <input type="text" class="form-control formField">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label class="sub-text">
                                            Data Type
                                            <span class="text-danger fw-bolder">*</span>
                                        </label>
                                        <nz-select nzShowSearch nzPlaceHolder="--Please Select--">
                                            <nz-option>lich</nz-option>
                                            <nz-option>jack</nz-option>
                                            <nz-option>jack</nz-option>
                                            <nz-option>lich</nz-option>

                                        </nz-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label class="sub-text">
                                            Tool Tip Text
                                        </label>
                                        <textarea nz-input [nzAutosize]="{ minRows: 2, maxRows: 6 }"
                                            class="form-control formField"
                                            style="height: 54px; resize: none;"></textarea>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class=" col-9 d-flex align-items-center">
                                        <label nz-checkbox>Required?</label>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class=" col-9 d-flex align-items-center">
                                        <label nz-checkbox>Include In My Filters</label>
                                    </div>
                                </div>
                                <div class="row mt-3" style="margin-bottom: 30px;">
                                    <div class="col-12">
                                        <label class="sub-text">Display Order
                                            <span class="text-danger fw-bolder">*</span>
                                        </label><br>
                                        <nz-input-number [nzMin]="1" [nzMax]="10" [nzStep]="1"
                                            type="number"></nz-input-number>
                                    </div>
                                </div>

                            </nz-card>
                            <ng-template #extraTemplate>
                            </ng-template>
                        </ng-container>
                    </nz-modal>

                    <!-- <ng-template pTemplate="header">
                                    <h5 class="text-xl fw-bold font-bold">Custom Field
                                    </h5>
                                </ng-template> -->

                </div>

                <div class="col-8 p-0" style="background-color: #f1f4fa; color: black; border-color: black;">
                    <div class="long-div">
                        <div class="long-div-inner">
                            <div nz-row class="p-3">
                                <div nz-col nzSpan="24">
                                    <div nz-row>
                                        <div nz-col nzSpan="24" class="text-end">
                                            <nz-space>
                                                <button nz-button nzType="default" class="me-3 rounded-1" (click)="installationInstructionOpen()">Installation Instructions</button>
                                                <button nz-button nzType="default" class="me-3 rounded-1" (click)="LearnMoreOpen()">Learn More</button>
                                            </nz-space>
                                        </div>
                                    </div>
                                    <div nz-row class="mt-5">
                                        <div nz-col nzSpan="24">
                                            <label class="sub-text fs-6 fw-medium mb-1" for="displayName">Form Fields
                                                <span class="text-danger p-error fw-bolder">*</span>
                                                
                                            </label>
                                        </div>
                                    </div>
                                    <div nz-row>
                                        <div nz-col nzSpan="24" class="mt-2">
                                            <p class="mb-3" style="font-size: 16px">Drag fields from the left into the area below</p>
                                        </div>
                                    </div>
                                    <div class="card mt-2">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="row">
                                                    <div class="col-1 mt-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#000000"
                                                            width="35px" height="35px"
                                                            viewBox="-64 -64 384.00 384.00" id="Flat"
                                                            stroke="#000000" stroke-width="25.6"
                                                            transform="rotate(0)">
                                                            <g id="SVGRepo_bgCarrier" stroke-width="0"
                                                                transform="translate(56.31999999999999,56.31999999999999), scale(0.56)" />
                                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round"
                                                                stroke-linejoin="round" stroke="#CCCCCC"
                                                                stroke-width="0.512" />
                                                            <g id="SVGRepo_iconCarrier">
                                                                <path
                                                                d="M100,60.0001a8,8,0,1,1-8-8A8.00008,8.00008,0,0,1,100,60.0001Zm64,8a8,8,0,1,0-8-8A8.00008,8.00008,0,0,0,164,68.0001Zm-72,52a8,8,0,1,0,8,8A7.99977,7.99977,0,0,0,92,120.0001Zm72,0a8,8,0,1,0,8,8A7.99977,7.99977,0,0,0,164,120.0001Zm-72,68a8,8,0,1,0,8,8A7.99977,7.99977,0,0,0,92,188.0001Zm72,0a8,8,0,1,0,8,8A7.99977,7.99977,0,0,0,164,188.0001Z" />
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div class="col-11 p-0">
                                                        <div class="row"><div class="col-4"><label class="sub-text fs-6 fw-medium mb-1" for="displayName">Lable</label></div></div>
                                                        <div class="row mt-1"><div class="col-4"><input type="text" class="formField form-control" placeholder="First Name"></div></div>
                                                        <div class="row mt-2">
                                                            <div class="col-4">
                                                                <label class="pira-label" nz-checkbox  nzDisabled [(ngModel)]="isCheckedButton">Required</label>
                                                            </div>
                                                            <div class="col-8 text-end">
                                                                <i class="bi bi-trash fs-5 text-primary"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                              

                        </div>
                    </div>
                </div>

            </div>
        </div>
        <ng-template #extraTemplate>
            <a>More</a>
        </ng-template>

    </div>

</nz-modal>

<ng-template #LeadOpportunityAddcontactFooter>
    <div class="mt-2 align-items-center">
        <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
        <button nz-button nzType="primary" class="rounded-1 me-0">Save & Preview</button>
    </div>
</ng-template>

