<nz-modal
  nzWidth="95%"
  [(nzVisible)]="selectionChoicevisible"
  [nzTitle]="selectionChoiceModelTitle"
  [nzFooter]="selectionChoiceModelFooter"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '85vh', 'overflow-y': 'auto' }"
  [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="selectionChoiceModelCancel()"
  >
    <ng-template #selectionChoiceModelTitle >
      <div class="row p-0 m-0">
        <h2 class="p-0 m-0 fw-bold">
          Selection Choice
        </h2>
      </div>
    </ng-template>

    <div class="content content-set-background " *nzModalContent >
      <div class="ng-container ">

<nz-card nzBorderless="true" [nzTitle]="Choice">
  <ng-template #Choice>
    <h4>Choice Details</h4>
  </ng-template>

  <div nz-row>
    <div nz-col nzSpan="8">
      <div class="row  div-set">
        <div class="col-12 CardPicSet ">
          <img class="rounded-img" src="https://i.ibb.co/F8NmNPd/Capture.png" alt="" width="100%" height="430px">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <label class="para-lable">Attachment</label>
          <button class="ms-2 " nz-button>Add</button>
          <button class="ms-1 " nz-button>Create New Doc</button>
        </div>
      </div>
    </div>
    <div nz-col nzSpan="16">
      <div class="row">
        <div class="col-6 mt-2">
          <label class="para-lable p-1">Selectin Title :</label>
          <span class="p-1"> Feature Tiles</span>
        </div>
      </div>
      <div class="row">
        <div class="col-6 mt-2">
          <label class="para-lable p-1">Category :</label>
          <span class="p-1"> 10-interiorFinishes</span>
        </div>
      </div>
      <div class="row">
        <div class="col-6 mt-2">
          <label class="para-lable p-1">Location :</label>
          <span class="p-1">Master Bath</span>
        </div>
      </div>
      <div class="row">
        <div class="col-6 mt-3">
          <label class="para-lable">Choice Title <span class="text-danger">*</span></label>
          <input type="text" class="form-control formField" />
        </div>
      </div>
      <div class="row">
        <div class="col-6 mt-3  ">
          <label class="para-lable">
            Product Link
            <i class="bi bi-info-circle-fill mb-2 text-secondary" nz-popover [nzPopoverContent]="contentTemplate"
              nzPopoverPlacement="right"></i>

            <ng-template #contentTemplate>
              <div class="rounded-2">
                <p style="height: auto; width: 180px">
                  Claim numbers are auto assigned based on your previous numbers
                  used per job.
                </p>
              </div>
            </ng-template>
          </label>
          <div class="input-group mb-1">
            <input type="text" class="form-control shadow-none" style="width: 70%; height: 30px;" aria-label="Username"
              aria-describedby="basic-addon1">
            <span class="input-group-text" id="basic-addon1" style="height: 30px; cursor: pointer;">View <i
                class="bi bi-box-arrow-in-down-right ms-1"></i></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 mt-1">

          <div class="card rounded-0 border-0">
            <div class="row">
              <div class="col-6 text-center mt-4 " style="border-right: solid 1px #ccc;">
                <span class="fw-bold fs-7"> Builder Cost </span>
                <div class="chooice">$0.00 </div>

              </div>
              <div class="col-6 text-center   mt-4  ">
                <span class="fw-bold fs-7">Owner Price</span>
                <div class="chooice">$0.00 </div>
              </div>
            </div>

          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-6 mt-3">
          <label nz-checkbox class="para-lable1">Include in Budget</label>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <nz-divider></nz-divider>
        </div>
      </div>


      <div class="row">
        <div class="col-6">
          <label class="fw-bold">Vendor</label>
          <p class="para-lable1 mt-2">None Assigned to Parent Selection</p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-6">
          <label class="para-lable">Installer </label>
          <div class="input-group mt-1">
            <nz-select style="width: 100%" nzShowSearch [lang]="true" nzAutoFocus="true"
              nzPlaceHolder="All Installers On Selection" [(ngModel)]="selectedValue">
              <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
              <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
              <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</nz-card>

<nz-card nzBorderless="true" class="mt-3" [nzTitle]="PriceDetails">
  <ng-template #PriceDetails>
    <h4>Price Details</h4>
  </ng-template>
  <nz-button-group>
    <button nz-button nzType="default">
      Flat Fee
    </button>
    <button nz-button nzType="default">
      Line Items
    </button>
    <button nz-button nzType="default">
      Request From Sub/Vendor
    </button>
        <i class="bi bi-info-circle-fill mt-1 ms-1 text-secondary"
        nz-popover [nzPopoverContent]="contentTemplate"
        nzPopoverPlacement="right"></i>
        <ng-template #contentTemplate>
          <div class="rounded-2">
            <p style="height: auto; width: 180px">
              Claim numbers are auto assigned based on your previous numbers
              used per job.
            </p>
          </div>
        </ng-template>
  </nz-button-group>
    <div class="row mt-1">
      <div class="col-6 mt-3">
        <label nz-checkbox class="para-lable1">Show Line Items To Owner</label>
        <i class="bi bi-info-circle-fill mt-1  text-secondary"
        nz-popover [nzPopoverContent]="contentTemplate"
        nzPopoverPlacement="right"></i>
        <ng-template #contentTemplate>
          <div class="rounded-2">
            <p style="height: auto; width: 180px">
              Claim numbers are auto assigned based on your previous numbers
              used per job.
            </p>
          </div>
        </ng-template>
      </div>
    </div>

</nz-card>

<nz-card nzBorderless="true" class="mt-3" [nzTitle]="ProductDescription">
  <ng-template #ProductDescription>
    <h4>Product Description</h4>
  </ng-template>
 <div class="row">
  <div class="col-12">
    <label class="para-lable ">Description</label>
    <textarea class="formField" rows="4" nz-input [(ngModel)]="inputValue"></textarea>
  </div>
 </div>
</nz-card>

</div>
</div>
<ng-template #selectionChoiceModelFooter>
  
</ng-template>
</nz-modal>