
<nz-drawer
  [nzWidth]="'900px'"
  [nzVisible]="visible"
  nzPlacement="right"
  [nzTitle]="nzTitle"
  [nzExtra]="extra"
  (nzOnClose)="close()"
  nzClosable="false"
  [nzFooter]="TodoSfooter"
  [nzBodyStyle]="{
    'background-color': '#f1f4fa', 
    'padding': '10px'
  }"
>

<ng-template #nzTitle>
    <h1 nz-typography>To-Do</h1>
</ng-template>

<ng-template #extra>
    <button nz-button nzType="text" (click)="close()">
      <span nz-icon nzType="close" nzTheme="outline"></span> <!-- Close icon -->
    </button>
  </ng-template>


<form nz-form *nzDrawerContent>
    <div class="content">
        <div class="card-container">
            <nz-tabset class="mt-3" nzType="card">
                <ng-container *ngFor="let tab of tabs">
                    <nz-tab *ngIf="tab.show" [nzTitle]="tab.title">

                        <!-- TAB 1 TODO  INFORMATION START -->
                        <ng-container *ngIf="tab.id === 1">
                            <nz-card nzTitle="To-Do Information" nzBorderless [formGroup]="todoForm"> <!-- Bind formGroup here -->
                                <div nz-row class="mb-2">
                                    <div nz-col [nzSpan]="24">
                                        <span class="round">
                                            <input class="mb-3" type="checkbox" [id]="'checkbox-'" formControlName="isCompleted" />
                                            <label class="mb-3" nz-popover [for]="'checkbox-'"></label>
                                            <span class="ms-4 mt-4">Complete</span>
                                        </span>
                                    </div>
                                </div>
                        
                                <div nz-row>
                                    <!-- Left Side -->
                                    <div nz-col class="gutter-row" [nzSpan]="12">
                                        <div nz-col [nzSpan]="24">
                                            <label class="sub-text">Title</label><span class="text-danger">*</span>
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <input nz-input formControlName="title" />
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                        
                                        <div nz-col [nzSpan]="24" class="mb-2">
                                            <label class="sub-text">Notes</label>
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <textarea nz-input [nzAutosize]="{ minRows: 2, maxRows: 6 }" formControlName="notes"></textarea>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                        
                                        <!-- Priority Field -->
                                        <div nz-col [nzSpan]="24" *ngIf="showPriorityField">
                                            <label class="sub-text">Priority</label>
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <nz-select style="width: 100%" nzShowSearch formControlName="prioritySetupId">
                                                        <nz-option *ngFor="let option of priorityOption" [nzLabel]="option.description" [nzValue]="option.id"></nz-option>
                                                    </nz-select>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                        
                                        <!-- Tags Field -->
                                        <div nz-col [nzSpan]="24" *ngIf="showTagsField">
                                            <label class="sub-text">Tags</label>
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <nz-tree-select style="width: 100%" nzCheckable nzCheckAll="true" nzAllowClear="false" [nzNodes]="tagsNodes" nzShowSearch formControlName="createToDoInformationTagParameterRequests">
                                                    </nz-tree-select>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                        
                                        <!-- Reminder Field -->
                                        <div nz-col [nzSpan]="24" *ngIf="showReminderField">
                                            <label class="sub-text">Reminder</label>
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <nz-select style="width: 100%" nzShowSearch formControlName="reminderSetupId">
                                                        <nz-option *ngFor="let option of reminderOption" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                                                    </nz-select>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                        
                                        <!-- Additional Buttons (Attach, Priority, Tags, Reminder) -->
                                        <div nz-col [nzSpan]="24">
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <nz-space>
                                                        <button nz-button nzType="text" (click)="toggleAttachment()" *ngIf="!showAttachment" class="d-flex align-items-center">
                                                            <span nz-icon nzType="link" nzTheme="outline"></span> Attach
                                                        </button>
                                                        <button nz-button nzType="text" *ngIf="!showPriorityField" (click)="togglePriorityField()" class="d-flex align-items-center">
                                                            <span nz-icon nzType="info-circle" nzTheme="outline"></span> Priority
                                                        </button>
                                                        <button nz-button nzType="text" *ngIf="!showTagsField" (click)="toggleTagsField()" class="d-flex align-items-center">
                                                            <span nz-icon nzType="tag" nzTheme="outline"></span> Tags
                                                        </button>
                                                        <button nz-button nzType="text" *ngIf="!showReminderField" nz-popover [nzPopoverContent]="'Select a due date to add a reminder'" (click)="toggleReminderField()" class="d-flex align-items-center">
                                                            <span nz-icon nzType="bell" nzTheme="outline"></span> Reminder
                                                        </button>
                                                    </nz-space>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>

                                      </div>
                                      <!-- Attachment Field -->
                                    
                        
                                    <!-- Right Side -->
                                    <div nz-col class="gutter-row" [nzSpan]="12">
                                        <div nz-col [nzSpan]="24">
                                            <label class="sub-text">Assigned to</label>
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <nz-tree-select style="width: 100%" [nzNodes]="combinedNodes" [nzShowSearch]="true" nzAllowClear="false" nzCheckable formControlName="createToDoParameterAssignOwnersRequests">
                                                    </nz-tree-select>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                        
                                        <div nz-col [nzSpan]="24">
                                            <label class="sub-text">Due Date</label><br>
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <nz-button-group class="full-width-group">
                                                        <button nz-button name="isChooseDate" nzType="default" (click)="toggleDateFields()" formControlName="isChooseDate">
                                                            Choose Date
                                                        </button>
                                                        <button nz-button nzType="default" name="isLinkedScheduleItem" (click)="toggleScheduleItemFields()" formControlName="isLinkedScheduleItem">
                                                            Link to add
                                                        </button>
                                                    </nz-button-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                        
                                        <!-- Schedule Work Section -->
                                        <div nz-row *ngIf="!isLinkToAdd" nzAlign="middle">
                                            <div nz-col [nzSpan]="16" class="me-2">
                                                <label class="sub-text">Schedule Item <span class="text-danger">*</span></label>
                                                <nz-form-item>
                                                    <nz-form-control>
                                                        <nz-select nzShowSearch nzAutoFocus="true" formControlName="scheduleItemParameterId" id="scheduleItemParameterId">
                                                          <nz-option
                        *ngFor="let option of scheduleItemOption"
                        [nzLabel]="option.Title"
                        [nzValue]="option.Id"
                      ></nz-option>
                                                        </nz-select>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div nz-col [nzSpan]="4" class="mt-4">
                                                <nz-space class="mt-2" nzAlign="center">
                                                    <button nz-button nzType="link" class="add-button">Add</button>
                                                    <button nz-button nzType="link" class="edit-button">Edit</button>
                                                </nz-space>
                                            </div>
                                        </div>
                        
                                        <div nz-col [nzSpan]="24" *ngIf="!isLinkToAdd">
                                            <label class="sub-text">Due</label>
                                            <div nz-row nzAlign="middle">
                                                <div nz-col [nzSpan]="8">
                                                    <nz-form-item>
                                                        <nz-form-control>
                                                            <nz-input-number [nzMin]="0" formControlName="dueNumberDays"></nz-input-number>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col [nzSpan]="14">
                                                    <nz-form-item>
                                                        <nz-form-control>
                                                            <nz-radio-group formControlName="before">
                                                                <label class="me-1">Days</label>
                                                                <label nz-radio nzValue="B">Before</label>
                                                                <label nz-radio nzValue="A">After</label>
                                                            </nz-radio-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                            </div>
                                        </div>
                        
                                        <div nz-col [nzSpan]="24" class="d-flex align-items-center p-0">
                                            <div nz-col [nzSpan]="10" class="gutter-row">
                                                <label class="sub-text">Due Date</label>
                                                <nz-form-item>
                                                    <nz-form-control *ngIf="isLinkToAdd">
                                                        <nz-date-picker [nzFormat]="'MMM d, YYYY'" nzSuffixIcon="false" nzAllowClear [nzPlaceHolder]="' '" formControlName="dueDate"></nz-date-picker>
                                                    </nz-form-control>
                                                    <strong class="d-flex align-items-center" *ngIf="!isLinkToAdd"> -- </strong>
                                                </nz-form-item>
                                            </div>
                        
                                            <div nz-col [nzSpan]="10" class="gutter-row">
                                                <label class="sub-text">Time</label>
                                                <nz-select nzShowSearch nzDefaultActiveFirstOption="false" formControlName="time">
                                                    <nz-option *ngFor="let time oftimeOptions" [nzLabel]="time"
                                                    [nzValue]="time"></nz-option>
                                                </nz-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nz-card>
                            <div nz-col [nzSpan]="24" *ngIf="showAttachment">
                              <nz-card nzTitle="Attachments" nzBorderless>
                                <app-create-attachments-common
                                      [TestAttachment__]="TestAttachment"
                                      (filesUploaded)="onFilesUploaded($event)"
                                      ></app-create-attachments-common>
                                      

                              </nz-card>
                          </div>
                        </ng-container>
                        
                         <!-- TAB 1 TODO INFORMATION END -->

                        <!-- TAB 2 CHECKLIST START -->
                        <ng-container *ngIf="tab.id === 2">
                            <nz-card nzTitle="Checklist Items" nzBorderless [nzExtra]="checkList">
                              <ng-template #checkList>
                                <nz-space>
                                  <ng-container *ngIf="!isChecklistAdded">
                                    <button nz-button nzType="default" *nzSpaceItem (click)="addChecklist()">
                                      Add Checklist
                                    </button>
                                  </ng-container>
                          
                                  <ng-container *ngIf="isChecklistAdded">
                                    <button nz-button nzType="default" id="checkbox" *nzSpaceItem (click)="completeAll()">
                                      Complete All
                                    </button>
                                    <button nz-button nzType="default" *nzSpaceItem >
                                      Remove Checklist
                                    </button>
                                  </ng-container>
                                </nz-space>
                              </ng-template>
                          
                              <div *ngIf="checklistItems.length === 0" nz-row [nzGutter]="16" class="justify-content-center container">
                                <nz-card class="center-card text-center p-2" nzBordered="false">
                                  <div>
                                    <i nz-icon nzType="check-circle" nzSize="large" nzTheme="outline" class="large-icon fs-8"></i>
                                  </div>
                                  <h2 class="mb-3" nz-typography>Give your To-Do's more power</h2>
                                  <p class="mb-1">Add checklist items to assign specific and detailed tasks from your To-Do.</p>
                                  <button class="mt-3" nz-button nzType="primary" (click)="addChecklist()">Add Checklist</button>
                                </nz-card>
                              </div>
                          
                              <!-- <div nz-row [nzGutter]="16">
                                <div nz-col [nzSpan]="1">
                                  <span class="col-adjust" nz-icon nzType="holder" nzTheme="outline"></span>
                                </div>
                          
                                <div nz-col nzSpan="1">
                                  <nz-form-item>
                                    <nz-form-control>
                                      <label nz-checkbox class="custom-checkbox d-flex align-items-center" [formControlName="i" formControlName="item.get('isChecked')"></label>
                                    </nz-form-control>
                                  </nz-form-item>
                                </div>
                          
                                <div nz-col [nzSpan]="item.get('isAssigneeAdded').value ? 16 : 21">
                                  <nz-form-item>
                                    <nz-form-control>
                                      <input nz-input placeholder="Enter text here" [formControlName]="i" formControlName="item.get('inputValue')"/>
                                    </nz-form-control>
                                  </nz-form-item>
                                </div>
                          
                                <div nz-col *ngIf="item.get('isAssigneeAdded').value" [nzSpan]="5">
                                  <nz-form-item>
                                    <nz-form-control>
                                      <nz-select [formControlName]="i" [formControlName]="item.get('assignee')" nzPlaceHolder="Select a person">
                                        <nz-option nzValue="me" nzLabel="Me"></nz-option>
                                        <ng-container *ngFor="let group of selectedUsers">
                                          <nz-option-group [nzLabel]="group.groupName">
                                            <ng-container *ngFor="let user of group.users">
                                              <nz-option [nzValue]="user.id" [nzLabel]="user.name"></nz-option>
                                            </ng-container>
                                          </nz-option-group>
                                        </ng-container>
                                      </nz-select>
                                    </nz-form-control>
                                  </nz-form-item>
                                </div>
                          
                                <div nz-col [nzSpan]="1">
                                  <button class="mt-1" nz-button nzType="default" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
                                    <span nz-icon nzType="more" nzTheme="outline"></span>
                                  </button>
                          
                                  <nz-dropdown-menu #menu="nzDropdownMenu">
                                    <ul nz-menu>
                                      <li nz-menu-item (click)="toggleAssignee(item)">
                                        <span class="p-1" nz-space nz-icon nzType="usergroup-add"></span>
                                        <span>{{ item.get('isAssigneeAdded').value ? 'Remove Assignees' : 'Add Assignees' }}</span>
                                      </li>
                                      <li nz-menu-item (click)="toggleAttachment(item)">
                                        <span nz-icon nzType="paper-clip"></span>
                                        <span>{{ item.get('isAttachmentAdded').value ? 'Remove Attachments' : 'Add Attachments' }}</span>
                                      </li>
                                      <li nz-menu-item (click)="deleteItem(i)" class="delete-item">
                                        <span nz-icon nzType="delete" class="icon"></span>
                                        <span class="text">Delete Item</span>
                                      </li>
                                    </ul>
                                  </nz-dropdown-menu>
                                </div>
                          
                                <div nz-row *ngIf="item.get('isAttachmentAdded').value" [nzGutter]="30" class="ms-5">
                                  <div nz-col [nzSpan]="1"></div>
                                  <div nz-col [nzSpan]="1"></div>
                                  <div nz-col [nzSpan]="21">
                                    <div class="row p-2">
                                      <div class="col-12">
                                        <label class="sub-text mb-2">Add Attachments</label>
                                        <app-create-attachments-common [TestAttachment__]="item.get('attachment').value"></app-create-attachments-common>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                           -->
                              <div nz-row *ngIf="isChecklistAdded" class="p-1">
                                <div nz-col>
                                  <button nz-button nzType="text" (click)="addChecklist()">
                                    <a class="fs-5" nz-icon nzType="plus-circle" nzTheme="fill"></a>
                                    <a class="ms-3" style="bottom: 100;">Add Checklist Item</a>
                                  </button>
                                </div>
                              </div>
                            </nz-card>
                          </ng-container>
                          
                          <!-- TAB 2 CHECKLIST END -->

                            <!-- TAB 3 RELATED ITEMS START -->
                        <ng-container *ngIf="tab.id === 3">
                            <nz-card nzTitle="Related Items" nzBorderless>
                                <h4 nz-typography class="mb-3">Schedule Items</h4>
                                <p nz-typography>Schedule items available
                                    after save.</p> <br>
                                    <h4 nz-typography class="mb-3">Daily Logs</h4>
                                <p nz-typography  class="mb-3">Daily Logs available after
                                    save.</p>
                                    <button nz-button nzType="default">
                                        Save And Create Daily Log
                                    </button>

                            </nz-card>
                        </ng-container>
                            <!-- TAB 3 RELATED ITEMS END -->
                    </nz-tab>
                </ng-container>
            </nz-tabset>
        </div>
    </div>



</form>

<!-- TODO FOOTER START -->
<ng-template #TodoSfooter>
   <div nz-row [nzAlign]="'middle'">
    <div nz-col [nzSpan]="12">
        <span>
            <app-footer-label [footerData]="footerResponse"></app-footer-label>
        </span>
    </div>
    <div nz-col [nzSpan]="12" class="text-end">
        <nz-space>
            <button *nzSpaceItem nz-button nzType="default">Delete</button>
            <button *nzSpaceItem nz-button nzType="primary">Save &
                New</button>
            <button *nzSpaceItem nz-button nzType="primary">Save</button>
            <button *nzSpaceItem nz-button nzType="primary">Save &
                Close</button>
        </nz-space>
    </div>
   </div>
</ng-template>
<!-- TODO FOOTER END -->

