import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';

import { CreateJobInformationRequest, JobInformationByIdResponse, JobInformationNameResponse, JobInformationOwnersByIdResponse, JobInformationResponse } from 'src/Models/Job-Scratch/JobFromScratch';
import { ResponseModel, ResponseModelArray, TablePageResponse } from 'src/Models/responseMessage.model';
import { ApiResponseModel } from 'src/Models/apiResponse.model';
import { environment } from 'src/environments/environment.prod';
import { JobInfoComponentInformation, JobTemplateResponse } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { JobType } from 'src/Models/CustomerInfromation/CustomerInformation';
import { LeadOpportunityResponse } from 'src/Models/LeadTags';
import { FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';

@Injectable({
  providedIn: 'root'
})
export class JobInformationService {


  private baseUrl: string = environment.apiUrl + 'JobInformation/';
  private JobInformation: string = environment.apiUrl + 'JobInformationTypeParameter/GetAll/';
  private base_url: string = environment.apiUrl + 'WorkDay/';
  private JOB_TEM_URL: string = environment.apiUrl + 'JobTemplate/';
  private base_Url: string = environment.apiUrl + 'LookupFilter/';


  getJobData = new BehaviorSubject<ResponseModel<TablePageResponse<JobInfoComponentInformation>>>(null);
  $getJobSummary = this.getJobData.asObservable();



  isUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $isUserAppliedFilter = this.isUserAppliedFilter.asObservable();

  selectedFilterId = new BehaviorSubject<number>(0);
  $selectedFilterId = this.selectedFilterId.asObservable();

  constructor(private http: HttpClient) {}

  postData(data: JobInfoComponentInformation): Observable<JobInfoComponentInformation> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }

  getAllLeadData(): Observable<ResponseModelArray<JobInfoComponentInformation>> {
    return this.http.get<ResponseModelArray<JobInfoComponentInformation>>(`${this.baseUrl}getAll-Name`);
  }
  getData(): Observable<ResponseModelArray<JobInformationResponse>> { 
    return this.http.get<ResponseModelArray<JobInformationResponse>>(`${this.baseUrl}getAll-Name`);
  }


  getNameIdAll(): Observable<ResponseModelArray<JobInformationNameResponse>> { 
    return this.http.get<ResponseModelArray<JobInformationNameResponse>>(`${this.baseUrl}getAll-Name`);
  }



  // ___________Abdul Rehman Start______________
  // getData(): Observable<JobInfoComponentInformation[]> {
  //   return this.http.get<JobInfoComponentInformation[]>(`${this.baseUrl}getAll`);
  // }
  // ___________Abdul Rehman End______________


  // getData(): Observable<JobInfoComponentInformation[]> {
  //   return this.http.get<JobInfoComponentInformation[]>(`${this.baseUrl}getAll`);
  // }
  // getDataWorkDay(): Observable<JobInfoComponentInformation[]> {
  //   return this.http.get<JobInfoComponentInformation[]>(`${this.base_url}GetAll`);
  // }

  getDataWorkDay(): Observable<ResponseModelArray<JobTemplateResponse>> {
    return this.http.get<ResponseModelArray<JobTemplateResponse>>(`${this.base_url}GetAll`);
  }

  updateData(data: JobInfoComponentInformation): Observable<JobInfoComponentInformation> {
    return this.http.put<JobInfoComponentInformation>(`${this.baseUrl}`, data);  
  }

  deleteData(id: number): Observable<JobInfoComponentInformation> {
    return this.http.delete<JobInfoComponentInformation>(
      `${this.baseUrl}?globalId=${id}`
    );
  }
  // getDataId(id: string): Observable<ApiResponseModel> {
  //   return this.http.get<ApiResponseModel>(
  //     `${this.baseUrl}getBy-Id?id=${id}`
  //   );
  // }


  getDataId(id: number): Observable<ResponseModel<JobInformationResponse>> {
    return this.http.get<any>(`${this.baseUrl}getBy-Id?id=${id}`);
  }

  PostJobScratch(jobInformationFormData: JobInformationResponse){
    const apiUrl = `${this.baseUrl}`;
    return this.http.post<any>(apiUrl, jobInformationFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  getJobTypeData():Observable<ResponseModelArray<JobType>>{
    return this.http.get<ResponseModelArray<JobType>>(`${this.JobInformation}`);
  }


  PostJobTemplate(jobInformationFormData: CreateJobInformationRequest): Observable<ResponseModel<JobInformationResponse>> {
    const apiUrl = `${this.JOB_TEM_URL}`;
    return this.http.post<any>(apiUrl, jobInformationFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  PostJobTemplateForm(jobInformationFormData: CreateJobInformationRequest): Observable<ResponseModel<JobInformationResponse>> {
    const apiUrl = `${this.baseUrl}`;
    return this.http.post<any>(apiUrl, jobInformationFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  getDataAllJobTemplate(): Observable<ResponseModelArray<JobInformationResponse>> {
    return this.http.get<ResponseModelArray<JobInformationResponse>>(`${this.JOB_TEM_URL}GetAll`);
  }



  setSelectedFilterId(filterId: number){
    this.selectedFilterId.next(filterId);
  }


  getAllCustomerinformationByFilter(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getJobData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }

  getJobDataa(jobId: number): Observable<JobInformationResponse> {
    return this.http.get<JobInformationResponse>(`${this.baseUrl}getBy-Id?id=${jobId}`).pipe(
      catchError((error: any) => {
        console.error('Error fetching job data:', error);
        return throwError(error);
      })
    );
  }
  jobgetDataId(id: number): Observable<ResponseModel<JobInformationByIdResponse>> {
    return this.http.get<ResponseModel<JobInformationByIdResponse>>(`${this.baseUrl}getBy-Id?id=${id}`);
  }
  JobOwnersgetDataId(id: number): Observable<ResponseModelArray<JobInformationOwnersByIdResponse>> {
    return this.http.get<ResponseModelArray<JobInformationOwnersByIdResponse>>(`${this.baseUrl}getJobOwnersBy-Id?id=${id}`);
  }
  

  // subVendorgetDataId(id: number): Observable<ResponseModel<SubVendorResponse>> {
  //   return this.http.get<ResponseModel<SubVendorResponse>>(`${this.baseUrl}getBy-Id?id=${id}`);
  // }
}