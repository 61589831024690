import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-project-management-site-diaries-document-xls',
  templateUrl: './project-management-site-diaries-document-xls.component.html',
  styleUrls: ['./project-management-site-diaries-document-xls.component.css']
})
export class ProjectManagementSiteDiariesDocumentXLSComponent {
  @Output() cancel = new EventEmitter<void>();
  uploadfiles=true;
  UploadFiles(): void {
  this.cancel.emit();
  }

  
}
