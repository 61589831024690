<div class="row famly-inn gx-3">
    <div class="col-12">
        <div class="card border-0 mt-0">
          <div class="card-head border-bottom">
                <h5 nz-typography class="py-2">Related  Items</h5>
            </div>
          <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <label class="sub-text fs-6">Schedule Items</label>
              </div>
              <div class="row">
                <div class="ListViewJobname mt-2 fs-6">Schedule items available after save.</div>
              </div>
              <div class="row">
                <label class="sub-text fs-6">Daily Logs</label>
              </div>
              <div class="row">
                <div class="ListViewJobname mt-2 fs-6">Daily Logs available after save.</div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <button nz-button nzType="default" class="rounded-1 me-0">Save and Create To-Do</button>
            </div> 
            </div>
          </div>
          </div>
        </div>
    </div>
    </div>
</div>


