import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzSelectSizeType } from 'ng-zorro-antd/select';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { TimeClockSetupResponse } from 'src/Models/Time-Clock/clock-in';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { TimeClockService } from 'src/Service/TimeClock/time-clock.service';
import { CompanySettingsTimeClockPreDefineCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';

@Component({
  selector: 'app-time-clock',
  templateUrl: './time-clock.component.html',
  styleUrls: ['./time-clock.component.css'],
})
export class TimeClockComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  WeekStart: LookupNameSetupResponse[] = [];
  selectRadiusk: LookupNameSetupResponse[] = [];
  selectRadiusm: LookupNameSetupResponse[] = [];
  timeClockSetupResponse : TimeClockSetupResponse []= []
  hourlyRateTracking: LookupNameSetupResponse[] = [];
  optionKilometers: LookupNameSetupResponse[] = [];
  overTimeNodes: NzTreeNodeOptions[] = [];
  doubleOvertimeDays: NzTreeNodeOptions[] = [];
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  selectRadiusOptions:LookupNameSetupResponse[] = [];
  companytimeClockForm: any = {};
  dataset: any;
  PAGE_ID: number = 30016;
  selectedValue = null;
  listOfOption: Array<{ label: string; value: string }> = [];
  size: NzSelectSizeType = 'default';
  isCheckboxChecked12 = false;
  isCheckboxChecked15 = false;
  isCheckboxChecked13 = false;  
  showAlert: boolean = false;
  isCheckboxChecked11: boolean = false;
  isCheckboxChecked16: boolean = false;
  isCheckboxChecked14: boolean = false;
  isdropDownChange = false;


  preDefinedDataCodes = {
    weakStart: CompanySettingsTimeClockPreDefineCodes.CSTCWS,
    hourlyRateTracking: CompanySettingsTimeClockPreDefineCodes.CSTCHRT,
    selectRadius: CompanySettingsTimeClockPreDefineCodes.CSTCSRK,
    selectRadiusm: CompanySettingsTimeClockPreDefineCodes.CSTCSRM,
    kilometers: CompanySettingsTimeClockPreDefineCodes.CSTCK,
  };

  constructor(
    private LookupFilterServicee: LookupFilterService,
    private companytimeSer: TimeClockService,
    private toastssService: NzMessageService,
    private modal: NzModalService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initLoad();
    this.fetchCompanyClockData();

    this.companytimeClockForm = {
      globalId: '00000000-0000-0000-0000-000000000000',
      companyParameterId: 1,
      weekStartId: '',
      hourlyRateTrackingId: '',
      useFormattingOTimeClockTotals: false,
      whenAJobIsDeletedPromptToDeleteCorrespondingTimeCardItems: false,
      sendOneTimeNotificationToInternalUsersThatHaveBeenClockedInForOverHours:
        false,
      automaticallyEndBreaksAfterMinutesNo: '',
      showGeneralJob: false,
      showOvertime: false,
      employeesAccrueDailyOvertime: false,
      employeesAccrueWeeklyOvertime: false,
      showDoubleOvertime: false,
      radiusId: '',
      radiusUnitId: '',
      defaultTimeClockGeofencingToOnForAllNewJobs: false,
    };
  }

  initLoad() {
    this.LookupFilterServicee.getLookUpValuesByFormNameId(
      this.PAGE_ID
    ).subscribe((res) => {
      // -----------single Select ---------------

      let weakStart = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.weakStart.code
      );
      let hourlyRateTracking = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.hourlyRateTracking.code
      );
      let selectRadiusk = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.selectRadius.code
      );
      let selectRadiusM = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.selectRadiusm.code
      );
      let kilometers = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.kilometers.code
      );

      // -----------single Select -------------
      this.WeekStart = weakStart;

      this.hourlyRateTracking = hourlyRateTracking;

      this.selectRadiusk = selectRadiusk;

      this.selectRadiusm = selectRadiusM;

      this.optionKilometers = kilometers;

      this.lookupSetNameResponse = res.result;
      this.overTimeNodes = weakStart.map((tags) => ({
        title: tags.name,
        value: tags.id.toString(),
        key: tags.id.toString(),
        isLeaf: true,
      }));
      this.lookupSetNameResponse = res.result;
      this.doubleOvertimeDays = weakStart.map((tags) => ({
        title: tags.name,
        value: tags.id.toString(),
        key: tags.id.toString(),
        isLeaf: true,
      }));
    });
  }

  fetchCompanyClockData() {
    this.companytimeSer.getAllCompanyClock().subscribe(
      (response) => {
        this.dataset = response.result;
        console.log("this.dataset",this.dataset);
        
        if (this.dataset.length > 0) {
          const firstItem = this.dataset[0];
          this.companytimeClockForm = {
            companyParameterId: firstItem.companyParameterId || 1,
            weekStartId: firstItem.weekStartId || '',
            hourlyRateTrackingId: firstItem.hourlyRateTrackingId || '',
            useFormattingOTimeClockTotals:
              firstItem.useFormattingOTimeClockTotals || false,
            whenAJobIsDeletedPromptToDeleteCorrespondingTimeCardItems:
              firstItem.whenAJobIsDeletedPromptToDeleteCorrespondingTimeCardItems ||
              false,
            sendOneTimeNotificationToInternalUsersThatHaveBeenClockedInForOverHours:
              firstItem.sendOneTimeNotificationToInternalUsersThatHaveBeenClockedInForOverHours ||
              false,
            automaticallyEndBreaksAfterMinutesNo:
              firstItem.automaticallyEndBreaksAfterMinutesNo || '',
            showGeneralJob: firstItem.showGeneralJob || false,
            showOvertime: firstItem.showOvertime || false,
            employeesAccrueDailyOvertime:
              firstItem.employeesAccrueDailyOvertime || false,
            employeesAccrueWeeklyOvertime:
            firstItem.employeesAccrueWeeklyOvertime || false,
            showDoubleOvertime: firstItem.showDoubleOvertime || false,
            radiusId: firstItem.radiusId  || '',
            radiusUnitId: firstItem.radiusUnitId && firstItem.radiusUnitId|| '',
            defaultTimeClockGeofencingToOnForAllNewJobs:
              firstItem.defaultTimeClockGeofencingToOnForAllNewJobs || false,
          };
        }
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }
  companyTimeClockSave() {

    this.companytimeSer.postCompanyTimeClock(this.companytimeClockForm) .subscribe((response) => {
        this.toastssService.success('New  Successfully!');
        this.companytimeClockForm = {};
        this.modal.closeAll();
      });
  }
 
  onCheckboxChange12() {
    this.isCheckboxChecked12 = !this.isCheckboxChecked12;
  }

  onCheckboxChange13() {
    this.companytimeClockForm['employeesAccrueDailyOvertime'] = !this.companytimeClockForm['employeesAccrueDailyOvertime'];
  }

  onCheckboxChange14() {
    this.companytimeClockForm['employeesAccrueWeeklyOvertime'] = !this.companytimeClockForm['employeesAccrueWeeklyOvertime'];
  }

  onCheckboxChange15() {
    this.isCheckboxChecked15 = !this.isCheckboxChecked15;
  }

  onCheckboxChange16() {
    this.isCheckboxChecked16 = !this.isCheckboxChecked16;
  }

  toggleFields(): void {
    this.showAlert = true;
    this.companytimeClockForm['showOvertime'] = !this.companytimeClockForm['showOvertime'];

    if (!this.companytimeClockForm['showOvertime']) {
      // Set other fields to false or any specific logic you want
      this.companytimeClockForm['employeesAccrueDailyOvertime'] = false;
      this.companytimeClockForm['employeesAccrueWeeklyOvertime'] = false;
    }
  
}

dropDownChange(){
  this.isdropDownChange = !this.isdropDownChange;
}
}