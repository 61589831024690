<!-- modal form modal  -->
<nz-modal nzWidth="66%" [(nzVisible)]="InviteSubsVendor" [nzTitle]="modalTitle" [nzFooter]="modalFooter"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '75vh', 'overflow-y': 'auto' }"
  [nzStyle]="{ top: '15px' }" (nzOnCancel)="InviteSubsVendorClose()">
  <ng-template #modalTitle>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0 fw-medium">
        Sub/Vendor Invitation
      </h2>
    </div>
  </ng-template>

  <div class="content" *nzModalContent>
    <nz-card nzTitle="Invitation" nzBorderless>

      <div nz-row>
        <div nz-col nzSpan="16">
           <button (click)="SubsVendorManageEmail()" nz-button nzType="link">Add Email</button>

          <kendo-editor [value]="editorValue" [style]="{'flex-direction': 'column-reverse'}"></kendo-editor>
        </div>
      </div>
      <div nz-row class="mt-3">
        <div nz-col nzSpan="24">
          <nz-collapse [nzBordered]="false">
            <nz-collapse-panel #p [nzExpandedIcon]="expandedIcon" [nzHeader]="tileetwe">
              <div class="work mt-0" #p>
                <div class="InvitationCompose" style="display: table; align-content: center;">
                  <div style="display: table-row;">
                    <div style="display: table-cell; text-align: right;"><img class="builderLogo" alt="logo"
                        src="https://buildertrend.net/api/files/brandingLogo?id=6f803e39-4059-4938-9c3b-a3cc70699a52&amp;imageType=0&amp;rowVersion=23763203472">
                    </div>
                  </div>
                  <div class="innerEmailContainer" style="display: table;">
                    <div style="display: table-row;">
                      <div class="emailSubject" style="display: table-cell;"><span>Zircon Homes T/A Xircon Homes
                          Requests Your Participation in Buildertrend</span></div>
                    </div>
                    <div style="display: table-row;">
                      <div class="fullWidth"
                        style="display: table-cell; text-align: left; font-family: Arial; color: rgb(98, 98, 98); font-weight: bold; font-size: 13px; padding-top: 10px; padding-left: 20px; padding-right: 20px;">
                        <span>Zircon Homes T/A Xircon Homes</span></div>
                    </div>
                    <div style="display: table-row;">
                      <div class="fullWidth"
                        style="display: table-cell; text-align: left; font-family: Arial; color: rgb(98, 98, 98); font-size: 13px; padding-top: 10px; padding-left: 20px; padding-right: 20px;">
                        <span style="font-weight: bold;">To:&nbsp;</span><span> </span></div>
                    </div>
                    <div class="fullWidth" style="display: table-row;">
                      <div class="fullWidth" style="display: table-cell; text-align: left; padding: 20px 20px 10px;">
                        <div class="fullWidth"
                          style="display: table; border: 1px dotted rgb(221, 221, 221); border-radius: 10px; background-color: rgb(242, 250, 255); border-collapse: separate; border-spacing: 15px;">
                          <div style="display: table-row;">
                            <div style="display: table-cell;">
                              <div class="fullWidth" style="display: table;">
                                <div style="display: table-row;">
                                  <div
                                    style="display: table-cell; font-family: Arial; color: rgb(98, 98, 98); font-size: 12px;">
                                    <div  style="word-break: break-word;">
                                      <div>Zircon Homes T/A Xircon Homes is asking that you participate in Buildertrend,
                                        a project management software that is absolutely free to you. By activating your
                                        free account, you can follow along with the progress of the job, get notified
                                        about action items, and communicate with us easier. It is user friendly and can
                                        be used on your mobile device as you go.<br>
                                        <br>
                                        Activation is free, easy, and takes less than a minute. Please click ACTIVATE to
                                        get started. This activation link will expire after 30 days.<br>
                                        <br>
                                        To learn more about the experience, consider viewing these: <a
                                        >Sub/Vendor Overview Video</a> <a
                                         >Sub/Vendor
                                          Mobile App Overview</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="display: table-row;">
                      <div style="display: table-cell; padding-top: 10px;"></div>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template #tileetwe>
                <span class="LColaPP mt-1">Viewer & Notification Settings</span>
              </ng-template>
              <ng-template #expandedIcon let-active>
                {{ active }}
                <span nz-icon nzType="caret-right" class="ant-collapse-arrow" [nzRotate]="p.nzActive ? 90 : -0"></span>
              </ng-template>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>


    </nz-card>

  </div>

  <ng-template #modalFooter>
    <div class="mt-2 align-items-center">
      <button nz-button nzType="default" class="rounded-1 me-0">Cancel</button>
      <button nz-button nzType="primary" class="rounded-1 me-0">Sand On Save</button>

    </div>
  </ng-template>
</nz-modal>

<app-loading-indicator></app-loading-indicator>