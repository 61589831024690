<div class="row famly-inn">
    <div class="col-12">
        <div class="card border-0 mt-0">
          <div class="card-head border-bottom">
                <h5 nz-typography class="py-2">To-Do Information</h5>
            </div>
            <form [formGroup]="TOdoInformation">
          <div class="card-body">
            <div class="row">
        <div class="col-12">
          <label nz-checkbox formControlName="complete">Complete</label> 
        </div>
            </div>
            <div class="row mt-3">
        <div class="col-12">
          <nz-alert nzType="info" nzMessage="There are incomplete checklist items in this To-Do." nzShowIcon></nz-alert>
        </div>
            </div>
            <div class="row">
                <div class="col-6">
            <div class="row">
                <div class="col-12">
                    <label class="sub-text">Title<span class="text-danger fw-bolder">*</span> </label>
                    <input formControlName="title" type="text" class="formField">
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                <label class="sub-text">Notes</label>
                <textarea formControlName="notes" class="form-control formField" style="height: 54px; resize: none;" (input)="autoAdjustTextAreaHeight($event)"></textarea>
                </div>
            </div>
            <div class="row mt-3">
              <div class="col-2 me-4">
                <button nz-button nzType="text" class="same-but"><i class="fa-solid fa-paperclip me-1"></i><span>Attach</span></button>
              </div>
              <div class="col-2 me-4">
                <button nz-button nzType="text" class="same-but"><i class="bx bx-error-circle bx-flip-vertical me-1"></i><span>Priority</span></button>
              </div>
              <div class="col-2 me-3">
                <button nz-button nzType="text" class="same-but"><i class="bx bx-purchase-tag-alt me-1"></i><span>Tags</span></button>
              </div>
              <div class="col-3">
                <button nz-button nzType="text" class="same-but"><i class="fa-regular fa-bell me-1"></i><span class="fs-6">Reminder</span></button>
              </div>
            </div>

                </div>
                <div class="col-6">
            <div class="row">
                <div class="col-12">
                    <label class="sub-text">Assigned to</label>
                    <nz-select formControlName="assignedTo"  [nzSize]="size">
                        <nz-option *ngFor="let option of listOfOption" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                      </nz-select>
              <label nz-checkbox><span class="pira-label">Notify new assigned users</span></label>
                </div>
            </div>
            <div class="row">
                <label class="sub-text">Notes</label>
            </div>
            <div class="row">
                <div class="col-11">
                <nz-radio-group>
                    <label (click)="showRow()" nz-radio-button nzValue="large" class="Choose">Choose Date</label>
                    <label (click)="showRow1()" nz-radio-button nzValue="default" class="link">Link to Schedule Item</label>
                  </nz-radio-group>
                </div>
            </div>
            <div class="row gx-2" *ngIf="isRowVisible">
              <div class="col-4">
                  <label class="sub-text">Due Date</label>
                  <nz-date-picker formControlName="dueDate"></nz-date-picker>
              </div>
              <div class="col-4">
                  <label class="sub-text">Time</label>
                  <nz-time-picker formControlName="time"></nz-time-picker>
              </div>
              <div class="col-4">

              </div>
          </div>
          <div *ngIf="isRowVisible1">
            <div class="row gx-1">
                <div class="col-10">
                    <label class="sub-text">Tags</label>
                    <nz-select formControlName="tags" [nzSize]="size">
                      <nz-option *ngFor="let option of listOfOption" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                    </nz-select>
                </div>
                <div class="col-1">
                    <button nz-button type="button" class="none-buttom">
                        <span>Add</span>
                      </button>
                </div>
                <div class="col-1">
                    <button nz-button type="button" class="none-buttom">
                        <span>Edit</span>
                      </button>
                </div>
            </div> 
            <div class="row gx-2">
              <div class="col-4">
                  <label class="sub-text">Due</label>
              </div>
              <div class="col-7">
              </div>
              <div class="col-1">
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-4">
                  <nz-input-number formControlName="due"  [nzMin]="1" [nzMax]="10" [nzStep]="1"></nz-input-number>
              </div>
              <div class="col-7 mt-1">
                  <nz-radio-group>
                    <label class="pira-label me-2">Days</label>
                    <label nz-radio nzValue="B" formControlName="before">Before</label>
                    <label nz-radio nzValue="C" formControlName="after">After</label>
                  </nz-radio-group>
              </div>
              <div class="col-1">

              </div>
            </div>
            <div class="row gx-2">
            <div class="col-4">
                <label class="sub-text">Due Date</label>
                <label class="pira-label">Oct 23, 2023</label>
            </div>
            <div class="col-4">
                <label class="sub-text">Time</label>
                <nz-time-picker></nz-time-picker>
            </div>
            <div class="col-4">

            </div>
           </div>
          </div>

                </div>
                <div class="col-12">
                    <div class="row py-2 border-bottom pd-3">
                        <h5 nz-typography><span class="ms-3"></span>Attachments</h5>
                    </div>
                      <div class="row gx-1">
                        <div class="col-12">
                          <div class="row">
                          </div>
                          <button nz-button nzType="default" class="rounded-1 me-2">Add</button>
                          <button nz-button nzType="default" class="rounded-1 me-0">Create New Doc</button>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            
                          </div>
                        </div>
                    </div> 
                </div>
            </div>
          </div>
            </form>
        </div>
    </div>
  </div>  


  
