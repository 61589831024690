<!-- <nz-page-header class="site-page-header p-0">
  <nz-page-header-extra>
    <nz-space>
      <button nz-button nzType="default" (click)="salesCommonComponentOpen()" nz-popover
        [nzPopoverContent]="contentTemplate" nzPopoverPlacement="left">
        <i class="bi bi-gear"></i>
        <ng-template #contentTemplate>
          <div class="rounded-2">
            <p>Lead Activity Setting</p>
          </div>
        </ng-template>
      </button>


      <nz-divider nzType="vertical" class="divder"></nz-divider>
      <button *nzSpaceItem nz-button nzType="default" class=" rounded-1"><i
          class="fas fa-graduation-cap"></i>&nbsp;&nbsp;Learn More</button>
      <button class="rounded-1" *nzSpaceItem nz-button nzType="default">Export</button>
      <button class="rounded-1" *nzSpaceItem nz-button nzType="default" (click)="openSelectLead()">Log Completed
        Activity</button>
      <button class="rounded-1" *nzSpaceItem nz-button nzType="default"
        (click)="openFilter(LEAD_OPPR_ACT_VIEW,PAGE_ID)"><i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter</button>
      <button class="rounded-1" *nzSpaceItem nz-button nzType="primary" (click)="openSelectLead()">Schedule New
        Activity</button>
    </nz-space>
  </nz-page-header-extra>
</nz-page-header> -->

<!--Mehmood Grid Start ------  Branch Name ==> Mehmood_Grid_Filter_standard_View_From_Lead_opp_tab_2  -->


<!--Mehmood Grid End ------  Branch Name ==> Mehmood_Grid_Filter_standard_View_From_Lead_opp_tab_2  -->

<nz-modal
  nzWidth="30%"
  [(nzVisible)]="isSelectLead"
  [nzTitle]="LeadTitle"
  [nzBodyStyle]="{
    background: '#FFFFFF',
    padding: '14px',
    'max-height': '540px',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '250px' }"
  (nzOnCancel)="closeSelectLead()"
  [nzFooter]="null"
>
  <ng-template #LeadTitle>
    <div class="row p-0 m-0">
      <h4 class="p-0 m-0 fw-bold">Select a Lead</h4>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <div class="row mt-4 mb-4 ms-2 me-2">
      <div class="col-12">
        <label class="cityDropdown fw-bold">Please Select a Lead</label>
        <div class="input-group">
          <div class="input-group">
            <nz-select
              nzShowSearch
              nzAutoFocus="true"
              nzPlaceHolder="Select Lead"
            >
              <nz-option
                *ngFor="let option of leadOptions"
                [nzLabel]="option.label"
                [nzValue]="option.value"
                (click)="addNewLeadActivityModal()"
              ></nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</nz-modal>

<nz-modal
  nzWidth="50%"
  [(nzVisible)]="isVisible7"
  [nzTitle]="LeadActivityTitle"
  [nzFooter]="LeadActivityFooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    height: '70vh',
    'max-height': '70vh',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '37px' }"
  (nzOnCancel)="handleCancel8()"
>
  <ng-template #LeadActivityTitle>
    <nz-breadcrumb nz-page-header-breadcrumb>
      <nz-breadcrumb-item
        >ZH-106 Lot 1713 (45) Zachary Street Burnside 3023</nz-breadcrumb-item
      >
    </nz-breadcrumb>
    <div class="row p-0 m-0">
      <h4 class="famly-inn p-0 m-0 fw-bold">Lead Activity</h4>
    </div>
  </ng-template>

  <!-- sufyan Working from Control name remove and add FormFieldValue -->

  <form>
    <div class="content" *nzModalContent>
      <div class="card-container">
        <nz-tabset nzType="card">
          <nz-tab *ngFor="let tab of tabs" [nzTitle]="leaAdctivityTabs(tab)">
            <ng-container *ngIf="tab === 1">
              <ng-template #extraTemplate>
                <label
                  nz-checkbox
                  [(ngModel)]="formFieldValues['markComplete']"
                  name="markComplete"
                  (click)="toggleFields()"
                  >Mark Complete</label
                >
              </ng-template>
              <!-- body work -->
              <nz-card
                [nzTitle]="Activity"
                nzBorderless="true"
                [nzExtra]="extraTemplate"
              >
                <ng-template #Activity>
                  <h6 class="famly-inn">Activity</h6>
                </ng-template>
                <h6 *ngIf="checked18" class="famly-inn fw-medium">
                  Schedule Follow-Up
                </h6>
                <div class="row" *ngIf="checked18">
                  <div class="col-3">
                    <label class="sub-text famly-inn">Follow-Up Date</label>
                    <nz-date-picker
                      nzPlaceHolder=" "
                      nzSuffixIcon="false"
                      [(ngModel)]="formFieldValues['followupDate']"
                      name="followupDate"
                    ></nz-date-picker>
                    <!--  -->
                  </div>
                </div>

                <div class="row mb-2" *ngIf="checked18">
                  <div class="col-3">
                    <label class="sub-text famly-inn">Follow-Up Time</label>
                    <nz-time-picker
                      [(ngModel)]="formFieldValues['followupTime']"
                      name="followupTime"
                      nzFormat="HH:mm"
                      nzPlaceHolder=" "
                    ></nz-time-picker>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <nz-avatar
                      nzSize="large"
                      [nzSize]="50"
                      style="background-color: rgb(136, 170, 199)"
                      nzIcon="phone"
                    ></nz-avatar>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div nz-row>
                      <div nz-col nzSpan="20">
                        <label class="sub-text" for="projectedSalesDate"
                          >Activity Type
                          <span class="text-danger">*</span></label
                        >
                        <nz-select
                          [(ngModel)]="formFieldValues['activityTypeId']"
                          name="activityTypeId"
                          [style.width.%]="100"
                        >
                          <nz-option
                            *ngFor="let option of activityType"
                            [nzLabel]="option.label"
                            [nzValue]="option.value"
                          ></nz-option>
                        </nz-select>
                      </div>
                      <div
                        nz-col
                        nzSpan="4"
                        style="margin-top: 30px; padding: 0px"
                        (click)="addNewLeadActivityModal()"
                      >
                        <button class="btn btn-sm text-primary">Add</button>
                        <button class="btn btn-sm text-primary border-0 me-1">
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12">
                    <label class="sub-text fw-bold">Color</label>
                    <div class="input-group">
                      <nz-select
                        [(ngModel)]="formFieldValues['colorId']"
                        name="colorId"
                        [style.width.%]="100"
                      >
                        <nz-option
                          *ngFor="let option of colors"
                          [nzLabel]="option.label"
                          [nzValue]="option.value"
                        >
                          <span
                            [style.background-color]="option.value"
                            style="
                              display: inline-block;
                              width: 20px;
                              height: 20px;
                            "
                          ></span>
                          {{ option.label }}
                        </nz-option>
                      </nz-select>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-3">
                    <label class="sub-text fw-bold">Activity Date</label>
                    <nz-date-picker
                      nzPlaceHolder=" "
                      nzSuffixIcon="false"
                      [(ngModel)]="formFieldValues['activityOn']"
                      name="activityOn"
                    ></nz-date-picker>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-3">
                    <label class="sub-text fw-bold">Start Time</label>
                    <nz-time-picker
                      [(ngModel)]="formFieldValues['startTime']"
                      name="startTime"
                      nzFormat="HH:mm"
                      nzPlaceHolder=" "
                    ></nz-time-picker>
                  </div>

                  <div class="col-2"></div>
                  <div class="col-3">
                    <label class="sub-text fw-bold">End Time</label>
                    <nz-time-picker
                      [(ngModel)]="formFieldValues['endTime']"
                      name="endTime"
                      nzFormat="HH:mm"
                      nzPlaceHolder=" "
                    ></nz-time-picker>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <label class="sub-text fw-bold"
                      >Reminder

                      <i
                        class="bi bi-info-circle-fill text-secondary"
                        nz-popover
                        [nzPopoverContent]="RemindercontentTemplate"
                        nzPopoverPlacement="left"
                      ></i>
                      <ng-template #RemindercontentTemplate>
                        <div class="rounded-2">
                          <p style="height: auto; width: 180px">
                            The Reminder, when selected, will alert you prior to
                            your activity. Alerts will be sent via email or
                            text.
                          </p>
                        </div>
                      </ng-template>
                    </label>
                    <div class="input-group">
                      <nz-select
                        nzShowSearch
                        [lang]="true"
                        [(ngModel)]="formFieldValues['reminderId']"
                        name="reminderId"
                        nzAutoFocus="true"
                        nzPlaceHolder="--None--"
                      >
                        <nz-option
                          *ngFor="let option of reminder"
                          [nzLabel]="option.label"
                          [nzValue]="option.value"
                        ></nz-option>
                      </nz-select>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <label class="sub-text fw-bold"
                      >Assigned User <span class="text-danger">*</span>
                    </label>
                    <div class="input-group">
                      <nz-tree-select
                        [(ngModel)]="formFieldValues['applicationUserId']"
                        name="applicationUserId"
                        style="width: 100%"
                        [nzNodes]="assignedUserNodes"
                        nzShowSearch
                        nzAllowClear="false"
                        nzCheckable
                        nzCheckAll="true"
                        nzPlaceHolder="Select Status"
                      >
                      </nz-tree-select>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <label class="sub-text fw-bold"
                      >Attendees
                      <i
                        class="bi bi-info-circle-fill text-secondary"
                        nz-popover
                        [nzPopoverContent]="AttendeescontentTemplate"
                        nzPopoverPlacement="top"
                      ></i>
                      <ng-template #AttendeescontentTemplate>
                        <div class="rounded-2">
                          <p style="height: auto; width: 250px">
                            Attendees allows additional users to have access to
                            this activity.
                          </p>
                        </div>
                      </ng-template>
                    </label>
                    <div class="input-group">
                      <nz-tree-select
                        [(ngModel)]="formFieldValues['attendees']"
                        name="attendees"
                        style="width: 100%"
                        [nzNodes]="attendeesNodes"
                        nzShowSearch
                        nzAllowClear="false"
                        nzCheckable
                        nzCheckAll="true"
                        nzPlaceHolder="Select Status"
                      >
                      </nz-tree-select>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-2">
                    <label class="sub-text fw-bold">Initiated By </label>
                    <nz-radio-group
                      [(ngModel)]="formFieldValues['initiatedBy']"
                      name="initiatedBy"
                    >
                      <div class="mt-2">
                        <label
                          [(ngModel)]="formFieldValues['salesperson']"
                          name="salesperson"
                          nz-radio
                          nzValue="A"
                          >Salesperson</label
                        >
                      </div>
                      <div class="mt-2">
                        <label
                          [(ngModel)]="formFieldValues['lead']"
                          name="lead"
                          nz-radio
                          nzValue="B"
                          >Lead</label
                        >
                      </div>
                      <div class="mt-2">
                        <label
                          [(ngModel)]="formFieldValues['other']"
                          name="other"
                          nz-radio
                          nzValue="C"
                          >Others</label
                        >
                      </div>
                    </nz-radio-group>
                  </div>
                </div>
              </nz-card>

              <!-- saecond card -->
              <nz-card
                [nzTitle]="Location"
                nzBorderless="true"
                [nzExtra]="LocationTemplate"
              >
                <ng-template #Location>
                  <h6 class="famly-inn">Location</h6>
                </ng-template>
                <div class="row">
                  <div class="col-12">
                    <label class="sub-text fw-bold">Street Address</label>
                    <input
                      [(ngModel)]="formFieldValues['address']"
                      name="address"
                      type="text"
                      class="formField form-control"
                    />
                  </div>
                </div>
                <div class="row mt-2 gx-0 gx-2">
                  <div class="col-4">
                    <label class="sub-text fw-bold">Suburb</label>
                    <input
                      [(ngModel)]="formFieldValues['suburb']"
                      name="suburb"
                      type="text"
                      class="formField form-control"
                    />
                  </div>
                  <div class="col-4">
                    <label class="sub-text fw-bold">State</label>
                    <input
                      [(ngModel)]="formFieldValues['state']"
                      name="state"
                      type="text"
                      class="formField form-control"
                    />
                  </div>
                  <div class="col-4">
                    <label class="sub-text fw-bold">Postal Code</label>
                    <input
                      [(ngModel)]="formFieldValues['postalCode']"
                      name="postalCode"
                      type="text"
                      class="formField form-control"
                    />
                  </div>
                </div>
              </nz-card>
              <ng-template #LocationTemplate>
                <button
                  nz-button
                  nzType="primary"
                  nz-dropdown
                  [nzDropdownMenu]="menu4"
                  nzTrigger="click"
                >
                  Copy Address
                  <span nz-icon nzType="down"></span>
                </button>
                <nz-dropdown-menu #menu4="nzDropdownMenu">
                  <ul nz-menu>
                    <li nz-menu-item>Contact Form</li>
                    <li nz-menu-item>Contact Lead</li>
                  </ul>
                </nz-dropdown-menu>
              </ng-template>
              <!-- saecond card -->
              <!-- third card -->
              <nz-card nzBorderless="true" [nzTitle]="Details">
                <ng-template #Details>
                  <h6 class="famly-inn">Details</h6>
                </ng-template>
                <div class="row">
                  <div class="col-12">
                    <label class="sub-text fw-bold">Description</label>
                    <textarea
                      class="rounded-1"
                      [(ngModel)]="formFieldValues['description']"
                      name="description"
                      rows="2"
                      nz-input
                    ></textarea>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <label class="sub-text fw-bold">Lead Contact Email</label>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <p>dilpal_singh-hotmail.com</p>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <label class="sub-text fw-bold">Lead Contact Cell</label>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12"><a href="">0431 724 111</a></div>
                </div>
              </nz-card>
              <!-- third card -->
              <!-- body work -->
            </ng-container>
            <ng-container *ngIf="tab === 2">
              <nz-card
                [nzTitle]="Email"
                nzBorderless="true"
                [nzExtra]="EmailTemplate"
              >
                <ng-template #Email>
                  <h6 class="famly-inn">Email</h6>
                </ng-template>
                <div class="row" *ngIf="!showEmailFields">
                  <div class="col-6">
                    <label class="famly-inn"
                      >Send an email with this lead Activity.</label
                    >
                  </div>
                </div>
                <div class="row mt-3" *ngIf="!showEmailFields">
                  <div class="col-12">
                    <button
                      nz-button
                      class="rounded-1"
                      nzType="default"
                      (click)="toggleEmailFields()"
                    >
                      Attach Buildertrend Message
                    </button>
                    <button
                      class="ms-3 rounded-1"
                      nz-button
                      nzType="default"
                      disabled
                      nz-popover
                      [nzPopoverContent]="buttonEmialTemplate"
                      nzPopoverPlacement="top"
                    >
                      <ng-template #buttonEmialTemplate>
                        <div class="rounded-2">
                          <p
                            class="famly-inn"
                            style="height: auto; width: 200px"
                          >
                            You must save the Lead Activity before composing an
                            external email
                          </p>
                        </div>
                      </ng-template>
                      Attach External Email
                    </button>
                  </div>
                </div>
                <div class="ng-container" *ngIf="showEmailFields">
                  <div class="row mt-1">
                    <div class="col-3">
                      <h6 class="famly-inn fw-medium">Compose Message</h6>
                    </div>
                    <div class="col-7"></div>
                    <div class="col-2 p-0">
                      <span class="famly-inn"
                        >Mail Merge
                        <i
                          class="bi bi-info-circle-fill text-secondary"
                          nz-popover
                          [nzPopoverContent]="contentTemplate"
                          nzPopoverPlacement="left"
                        ></i>
                        <ng-template #contentTemplate>
                          <div
                            class="container-fuild"
                            style="height: auto; width: 450px"
                          >
                            <div class="row mb-3">
                              <div class="col-12">
                                <label class="famly-inn fw-bold"
                                  >Mail Merge</label
                                >
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <p class="famly-inn">
                                  Template or update a lead email with
                                  placeholder text.
                                </p>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-12">
                                <p class="famly-inn">
                                  Use the fields below as placeholders and we
                                  will populate the field before it is actually
                                  sent:
                                </p>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-12">
                                <label class="famly-inn fw-bold"
                                  >[[FirstName]] [[LastName]]</label
                                >
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-12">
                                <p class="famly-inn">
                                  The Customer Contact's First and Last Name for
                                  that specific lead is populated.
                                </p>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-12">
                                <label class="famly-inn fw-bold"
                                  >[[DisplayName]]</label
                                >
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-12">
                                <p class="famly-inn">
                                  The Customer Contact's Display Name for that
                                  specific lead is populated.
                                </p>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-12">
                                <label class="famly-inn fw-bold"
                                  >[[Signature]]</label
                                >
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-12">
                                <p class="famly-inn">
                                  The signature text and/or image is populated
                                  for the Lead's assigned Salesperson. The
                                  signature text and image can be set up for
                                  each Salesperson in User Preferences.
                                </p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <p class="famly-inn">Example:</p>
                              </div>
                            </div>
                            <div class="row mb-1">
                              <div class="col-12">
                                <p class="famly-inn">
                                  You want to setup an email drip campaign that
                                  can be used for any of your leads. To avoid
                                  having to personalize each lead email greeting
                                  or signature, you can set up a lead activity
                                  template with the placeholder fields so that
                                  we can merge in your selected greeting. "Dear
                                  [[DisplayName]]" would bring your Lead's name
                                  into the email message before being sent, and
                                  show to them as "Dear John Smith"
                                </p>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </span>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <label class="sub-text">Subject</label>
                      <input
                        [(ngModel)]="formFieldValues['subject']"
                        name="subject"
                        type="text"
                        class="formField form-control"
                      />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <label class="sub-text">Body</label>
                      <textarea
                        [(ngModel)]="formFieldValues['body']"
                        name="body"
                        rows="4"
                        nz-input
                      ></textarea>
                    </div>
                  </div>

                  <!--  -->

                  <div class="row mt-3">
                    <div class="col-12">
                      <label class="sub-text">Attachments</label>
                      <div class="mt-1">
                        <button nz-button nzType="default">Add</button>
                        <button class="ms-2" nz-button nzType="default">
                          Creat New Doc
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </nz-card>
              <ng-template #EmailTemplate>
                <button
                  *ngIf="showEmailFields"
                  class="rounded-1"
                  nz-button
                  nzType="default"
                  (click)="cancelEmailFields()"
                >
                  Cancel
                </button>
              </ng-template>
            </ng-container>
          </nz-tab>
        </nz-tabset>
      </div>
    </div>

    <ng-template #LeadActivityFooter>
      <div class="mt-2 align-items-center">
        <button nz-button nzType="primary" class="rounded-1 me-0">
          Save & New
        </button>
        <button
          nz-button
          nzType="primary"
          class="rounded-1 me-0"
          (click)="saveButton()"
        >
          Save
        </button>
        <button nz-button nzType="primary" class="rounded-1 me-0">
          Save & Close
        </button>
      </div>
    </ng-template>
  </form>

  <!-- sufyan Working from Control name remove and add FormFieldValue -->
</nz-modal>

<!-- 
<nz-modal nzWidth="30%" [(nzVisible)]="isVisible6" [nzTitle]="LeadTitle"
    [nzBodyStyle]="{'background': '#FFFFFF', 'padding': '14px', 'max-height': '540px', 'overflow-y': 'auto' }"
    [nzStyle]="{ top: '250px' }" (nzOnCancel)="handleCancel()" [nzFooter]="null">
    <ng-template #LeadTitle>
        <div class="row p-0 m-0">
            <h4 class="p-0 m-0 fw-bold">
                Select a Lead
            </h4>
        </div>
    </ng-template>
    <div class="content" *nzModalContent>
        <div class="row mt-4 mb-4 ms-2 me-2">
            <div class="col-12">
                <label class="sub-text fw-bold">Please Select a Lead</label>
                <div class="input-group">
                    <nz-select (click)="leadActivity()" nzShowSearch [lang]="true" nzAutoFocus="true"
                        nzPlaceHolder="" [(ngModel)]="selectedValue">
                        <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                        <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                        <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                    </nz-select>
                </div>
            </div>
        </div>
    </div>
</nz-modal>



<nz-modal nzWidth="50%" [(nzVisible)]="isVisible8" [nzTitle]="LeadActivityTitle"
    [nzFooter]="LeadActivityFooter"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '430px', 'overflow-y': 'auto' }"
    [nzStyle]="{ top: '15px' }" (nzOnCancel)="handleCancel8()">
    <ng-template #LeadActivityTitle>
        <nz-breadcrumb nz-page-header-breadcrumb>
            <nz-breadcrumb-item>ZH-106 Lot 1713 (45) Zachary Street Burnside 3023</nz-breadcrumb-item>
        </nz-breadcrumb>
        <div class="row p-0 m-0"> 
            <h3 class="p-0 m-0 fw-bold">
                Lead Activity
            </h3>
        </div>
    </ng-template>

    <div class="content " *nzModalContent>
        <div class="card-container">
            <nz-tabset nzType="card">
                <nz-tab *ngFor="let tab of tabs" [nzTitle]="leaAdctivityTabs(tab)">
                    <ng-container *ngIf="tab === 1">
                        <ng-template #extraTemplate>
                            <label nz-checkbox [(ngModel)]="checked18" (ngModelChange)="toggleFields()">Mark Complete</label>
                          </ng-template>
                        
                        <nz-card nzTitle="Activity" nzBorderless="true" [nzExtra]="extraTemplate">
                            <label >Schedule Follow-Up</label>
                            <div class="row" *ngIf="checked18">
                                <div class="col-3">
                                  <label class="cityDropdown fw-bold">Follow-Up Date</label>
                                  <nz-date-picker ></nz-date-picker>
                                </div>
                              </div>
                              
                              <div class="row mb-2" *ngIf="checked18">
                                <div class="col-3">
                                  <label class="cityDropdown fw-bold">Follow-Up Time</label>
                                  <nz-time-picker [(ngModel)]="time" nzFormat="HH:mm"></nz-time-picker>
                                </div>
                              </div>

                            <div class="row">
                                <div class="col-12">
                                    <nz-avatar [nzSize]="65" style="vertical-align: middle;"
                                        nzIcon="phone"></nz-avatar>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-9">
                                    <label class="cityDropdown fw-bold">Activity Type</label>
                                    <div class="input-group">
                                        <nz-select nzShowSearch [lang]="true" nzAutoFocus="true"
                                            nzPlaceHolder="Standard Filter" [(ngModel)]="selectedValue">
                                            <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                                            <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                                            <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                                        </nz-select>
                                    </div>
                                </div>
                                <div class="col-3 mt-4">
                                    <button aria-disabled="false" type="button"
                                        class="ant-btn ant-btn-link BTButton isolated AutoSizing"
                                        style="height: 29px; margin-top: -12px;">
                                        <span>Add</span>
                                    </button>&nbsp;&nbsp;
                                    <button aria-disabled="false" type="button"
                                        class="ant-btn ant-btn-link BTButton isolated AutoSizing ">
                                        <span style="opacity: 60%; margin-bottom: 40px;">
                                            Edit
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12">
                                    <label class="cityDropdown fw-bold">Color</label>
                                    <div class="input-group">
                                        <nz-tree-select style="width: 100%" 
                                             nzShowSearch nzAllowClear="false"
                                            nzCheckable nzCheckAll="true"
                                            nzPlaceHolder="Select Division"
                                            formControlName="createDivisionTradeParameterRequests"></nz-tree-select>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-3">
                                    <label class="cityDropdown fw-bold">Activity Date</label>
                                    <nz-date-picker
                                        ></nz-date-picker>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-3">
                                    <label class="cityDropdown fw-bold">Start Time</label>
                                    <nz-date-picker
                                        ></nz-date-picker>
                                </div>

                                <div class="col-2"></div>
                                <div class="col-3">
                                    <label class="cityDropdown fw-bold">Activity Date</label>
                                    <nz-date-picker
                                        ></nz-date-picker>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12">
                                    <label class="cityDropdown fw-bold">Reminder

                                        <i class="bi bi-info-circle-fill text-secondary" nz-popover
                                            [nzPopoverContent]="contentTemplate"
                                            nzPopoverPlacement="right"></i>
                                        <ng-template #contentTemplate>
                                            <div class="rounded-2">
                                                <p style="height: auto; width: 180px; ">
                                                    Selecting a default color for a particular phase
                                                    determines how that phase will display on the
                                                    schedule.
                                                </p>
                                            </div>
                                        </ng-template>
                                    </label>
                                    <div class="input-group">
                                        <nz-select nzShowSearch [lang]="true" nzAutoFocus="true"
                                            nzPlaceHolder="--None--" [(ngModel)]="selectedValue">
                                            <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                                            <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                                            <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                                        </nz-select>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12">
                                    <label class="cityDropdown fw-bold">Assigned User </label>
                                    <div class="input-group">
                                        <nz-tree-select style="width: 100%" 
                                             nzShowSearch nzAllowClear="false"
                                            nzCheckable nzCheckAll="true" nzPlaceHolder=""
                                            formControlName="createDivisionTradeParameterRequests"></nz-tree-select>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12">
                                    <label class="cityDropdown fw-bold">Attendees
                                        <i class="bi bi-info-circle-fill text-secondary" nz-popover
                                            [nzPopoverContent]="contentTemplate"
                                            nzPopoverPlacement="right"></i>
                                        <ng-template #contentTemplate>
                                            <div class="rounded-2">
                                                <p style="height: auto; width: 180px; ">
                                                    Selecting a default color for a particular phase
                                                    determines how that phase will display on the
                                                    schedule.
                                                </p>
                                            </div>
                                        </ng-template>
                                    </label>
                                    <div class="input-group">
                                        <nz-tree-select style="width: 100%" 
                                             nzShowSearch nzAllowClear="false"
                                            nzCheckable nzCheckAll="true" nzPlaceHolder=""
                                            formControlName="createDivisionTradeParameterRequests"></nz-tree-select>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-2">
                                    <label class="cityDropdown fw-bold">Initiated By </label>
                                    <nz-radio-group >
                                        <div class="mt-2"><label nz-radio
                                                nzValue="A">Salesperson</label></div>
                                        <div class="mt-2"><label nz-radio nzValue="B">Lead</label></div>
                                        <div class="mt-2"><label nz-radio nzValue="C">Others</label>
                                        </div>
                                    </nz-radio-group>
                                </div>
                            </div>
                        </nz-card>
                        <ng-template #extraTemplate>
                            <label nz-checkbox [(ngModel)]="checked18"> Mark Complete</label>
                        </ng-template>
                        
                        <nz-card nzTitle="Location" nzBorderless="true" [nzExtra]="LocationTemplate">
                            <div class="row">
                                <div class="col-12">
                                    <label class="cityDropdown fw-bold">Street Address</label>
                                    <input type="text" class="formField form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-4">
                                    <label class="cityDropdown fw-bold">Suburb</label>
                                    <input type="text" class="formField form-control">
                                </div>
                                <div class="col-4">
                                    <label class="cityDropdown fw-bold">State</label>
                                    <input type="text" class="formField form-control">
                                </div>
                                <div class="col-4">
                                    <label class="cityDropdown fw-bold">Postal Code</label>
                                    <input type="text" class="formField form-control">
                                </div>
                            </div>
                        </nz-card>
                        <ng-template #LocationTemplate>
                            <button nz-button nzType="primary" nz-dropdown [nzDropdownMenu]="menu4">
                                Copy Address
                                <span nz-icon nzType="down"></span>
                            </button>
                            <nz-dropdown-menu #menu4="nzDropdownMenu">
                                <ul nz-menu>
                                    <li nz-menu-item>SomeThingSomething</li>
                                </ul>
                            </nz-dropdown-menu>
                        </ng-template>
                       
                        <nz-card nzBorderless="true" nzTitle="Details">
                            <div class="row">
                                <div class="col-12">
                                    <label class="cityDropdown fw-bold">Description</label>
                                    <textarea rows="4" nz-input ></textarea>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12">
                                    <label class="cityDropdown fw-bold">Lead Contact Email</label>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12">
                                    <p>dilpal_singh-hotmail.com</p>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12"> <label class="cityDropdown fw-bold">Lead Contact
                                        Cell</label></div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12"><a href="">0431 724 111</a></div>
                            </div>
                        </nz-card>
                       
                        
                    </ng-container>
                    <ng-container *ngIf="tab === 2">
                        <nz-card nzTitle="Email" nzBorderless="true" [nzExtra]="EmailTemplate">
                            <div class="row">
                                <div class="col-6">
                                    <label class=" fw-bold">Send an email with this lead
                                        Activity.</label>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12">
                                    <button nz-button nzType="default"
                                        (click)="toggleEmailFields()">Attach Buildertrend
                                        Message</button>
                                    <button class="ms-3" nz-button nzType="default" disabled nz-popover
                                        [nzPopoverContent]="buttonEmialTemplate"
                                        nzPopoverPlacement="top">
                                        <ng-template #buttonEmialTemplate>
                                            <div class="rounded-2">
                                                <p style="height: auto; width: 180px; ">
                                                    You must save the Lead Activity before composing an
                                                    external email.
                                                </p>
                                            </div>
                                        </ng-template>
                                        Attach External Email</button>
                                </div>
                            </div>
                            <div class="ng-container" *ngIf="showEmailFields">
                                <div class="row mt-3">
                                    <div class="col-3">
                                        <p class=" fw-medium">Compose Message</p>
                                    </div>
                                    <div class="col-7"></div>
                                    <div class="col-2 p-0">
                                        <p class=" fw-medium">Mail Merge


                                        </p>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <label class="fw-bold">Subject</label>
                                        <input type="text" class="formField form-control">
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <label class="fw-bold">Body</label>
                                        <textarea rows="6" nz-input ></textarea>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <label class="fw-bold">Attachments</label>
                                        <div class="mt-1">
                                            <button nz-button nzType="default">Add</button>
                                            <button class="ms-2" nz-button nzType="default">Creat New
                                                Doc</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nz-card>
                        <ng-template #EmailTemplate>
                            <div class="row mt-3" *ngIf="showEmailFields">
                                <div class="col-12">
                                    <button nz-button nzType="default"
                                        (click)="cancelEmailFields()">Cancel</button>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </nz-tab>
            </nz-tabset>
        </div>
    </div>

    <ng-template #LeadActivityFooter>
        <div class="mt-2 align-items-center">
            <button nz-button nzType="primary" class="rounded-1 me-0">Save & New</button>
            <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
            <button nz-button nzType="primary" class="rounded-1 me-0">Save &
                Close</button>
        </div>
    </ng-template>
</nz-modal>
 -->

<!-- Mehmood Grid Html Start ==> Mehmood_Grid_Filter_standard_View_From_Lead_opp_tab_2   -->

<!-- Mehmood Grid Html End ==> Mehmood_Grid_Filter_standard_View_From_Lead_opp_tab_2   -->
