import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { CustomerInformationResponse } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { LeadProposalResponse } from 'src/Models/LeadProposal/LeadProposalModels';
import { LeadOpportunityResponse, LookupNameSetupResponse } from 'src/Models/LeadTags';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { JobTypeService } from 'src/Service/Job-List/Job-Information/job-type.service';
import { JobCreateFromScratchComponent } from 'src/app/jobs-management/Job-List/job-create-from-scratch/job-create-from-scratch.component';
import { JobCreateFromTemplateComponent } from 'src/app/jobs-management/Job-List/job-create-from-template/job-create-from-template.component';
import { JobScratchPreDefinedCodes, LeadOpportunityListViewPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';

@Component({
  selector: 'app-lead-to-job',
  templateUrl: './lead-to-job.component.html',
  styleUrls: ['./lead-to-job.component.css']
})
export class LeadToJobComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Inject(NZ_MODAL_DATA) public nzData: any
  @Input() leadOpportunityResponse: LeadOpportunityResponse;
  public selectedCustomer: CustomerInformationResponse | null = null;
  public leadOpportunityDetail: LeadOpportunityResponse | null = null;
  public leadOpportunitypyProposal: LeadProposalResponse[] = [];
  handleCancelLeadToJobModal = true;
  checkboxChecked: boolean = true;
  checked = true;
  leadToJobForm: any = {};
  jobTypeList: { value: string, label: string }[] = [];
  ContactType: LookupNameSetupResponse[] = [];
  leadProposalOptions: LeadProposalResponse[] = [];
  pageId: number = 30002;
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  preDefinedDataCodes = {
    contractType: JobScratchPreDefinedCodes.ContactType,
  };
  constructor(private jobTypeService: JobTypeService,
    private LookupFilterService: LookupFilterService,
    private modal: NzModalService,
    @Inject(NZ_MODAL_DATA) public data: any

  ) {
   }

  ngOnInit(): void {

    this.initLoad();
    this.selectedCustomer = this.leadOpportunityResponse?.customerInformation;
    this.leadOpportunityDetail = this.leadOpportunityResponse;
    this.leadOpportunitypyProposal = this.leadOpportunityResponse?.leadProposals;

    this.leadToJobForm = {
      title: this.leadOpportunityDetail?.title || '',
      jobTypeId: 0,
      contractTypeId: 0,
      proposalId: 0,
      updateStatusToSold: true,
      soldDate: new Date(),
      selectedOption: 'Create new Contact in Xero',
      copyLeadFilesToDocsSection: true,
      copyJobAddress: true,
      useLeadCustomerContact: true,
      showOwner: false,
      showSubs: false,
      copyProposalLineItemsToEstimate: true,
      copyProposalFilesToDocsSection: true,
      copyProposalIntroductoryandClosingTextToEstimate: true,
      leadOpportunityAddressParameters: {
        streetAddress: this.leadOpportunityDetail?.streetAddress || '',
        suburb: this.leadOpportunityDetail?.suburb || '',
        state: this.leadOpportunityDetail?.state || '',
        postalCode: this.leadOpportunityDetail?.postalCode || ''
      },
      customerInformationParameters: {
        firstName: this.selectedCustomer?.firstName || '',
        lastName: this.selectedCustomer?.lastName || '',
        displayName: this.selectedCustomer?.displayName || '',
        streetAddress: this.selectedCustomer?.streetAddress || '',
        suburb: this.selectedCustomer?.suburb || '',
        state: this.selectedCustomer?.state || '',
        postalCode: this.selectedCustomer?.postalCode || '',
        cellPhone: this.selectedCustomer?.cellPhone || '',
        phone: this.selectedCustomer?.phone || '',
        primaryEmail: this.selectedCustomer?.primaryEmail || '',
      },
    };

  }


  initLoad() {
    this.jobTypeService.getAllDataJobType().subscribe((res) => {
      if (Array.isArray(res.result)) {
        this.jobTypeList = res.result.map((LOP: any) => ({
          label: LOP.jobType.name,
          value: LOP.jobType.id.toString(),
        }));
      }
    });

    this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(
      (res) => {
        this.lookupSetNameResponse = res.result;

        let contactTypeValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.contractType.code
        );

        this.ContactType = contactTypeValues;

      })

  }


  scratchModalOpen(): void {
    if (this.data.type === 'SCRATCH') {
      const modalRef = this.modal.create({
        nzContent: JobCreateFromScratchComponent,
        nzFooter: null,
      });
      modalRef.componentInstance.leadToJobFormData   = this.leadToJobForm;
      this.handleCancelLeadToJobModal = false;
      modalRef.componentInstance.leadOpportunityResponse = this.leadOpportunityResponse;
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
        this.cancel.emit();
      });
    } else {
      const modalRef = this.modal.create({
        nzContent: JobCreateFromTemplateComponent,
        nzFooter: null,
      });
      modalRef.componentInstance.leadToJobFormData = this.leadToJobForm;
      this.handleCancelLeadToJobModal = false;
      modalRef.componentInstance.leadOpportunityResponse = this.leadOpportunityResponse;
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
        this.cancel.emit();
      });
    }

  }



  getAvatarText(): string {
    if (this.selectedCustomer && this.selectedCustomer.firstName && this.selectedCustomer.lastName) {
      const firstNameInitial = this.selectedCustomer.firstName.charAt(0);
      const lastNameInitial = this.selectedCustomer.lastName.charAt(0);
      return firstNameInitial.toUpperCase() + lastNameInitial.toUpperCase();
    } else {
      return '';
    }

  }


  isDataAvailable(selectedCustomer: any): boolean {
    return !!selectedCustomer;
  }



  handleCheckboxChange() {
    this.checkboxChecked = !this.checkboxChecked;
  }


  handleCancelLeadToJob(): void {
    this.cancel.emit();
    this.handleCancelLeadToJobModal = false;
  }


}
