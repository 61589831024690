import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-add-contact-installation-instruction',
  templateUrl: './add-contact-installation-instruction.component.html',
  styleUrls: ['./add-contact-installation-instruction.component.css']
})
export class AddContactInstallationInstructionComponent {
  @Output() cancel = new EventEmitter<void>();
  installationInstuctionAddForm = true;
  installationInstuctionclose(): void {
    this.cancel.emit();
  }


  
}
