import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { CreateRFIsSetupRequest, RFIsSetupResponse } from 'src/Models/RFIs.model';
import { RFIInformationResponse } from 'src/Models/ChangeOrder/ChangeOrder';
import { ApiResponseModel } from 'src/Models/apiResponse.model';
import { ResponseModel, ResponseModelArray, TablePageResponse } from 'src/Models/responseMessage.model';
import { rfiReminder } from 'src/Models/rfisReminder,model';
import { CreateLookupFilterRequest, FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class RfisService {


  private baseUrl: string = environment.apiUrl + 'RfiReminder/';
  private baseurl1:string = environment.apiUrl + 'RFIsSetup/';
  private base_Url: string = environment.apiUrl + 'LookupFilter/';

  constructor(private http: HttpClient) {}

  postData(data:rfiReminder): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }
  getRFISUserData = new BehaviorSubject<ResponseModel<TablePageResponse<RFIInformationResponse>>>(null);
  $getRFISUsers = this.getRFISUserData.asObservable();

  selectedFilterId = new BehaviorSubject<number>(0);
  isUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $isUserAppliedFilter = this.isUserAppliedFilter.asObservable();

  getrefi(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getRFISUserData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  getAppliedFilterData(appliedFilter: CreateLookupFilterRequest[]) {
    const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
    return this.http.post<any>(apiUrl, appliedFilter).subscribe(
      (data) => {
        this.getRFISUserData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  setSelectedFilterId(filterId: number) {
    this.selectedFilterId.next(filterId);
  }
  userAppliedFilter(filter: boolean) {
    this.isUserAppliedFilter.next(filter);
  }
  
  getData(): Observable<rfiReminder[]> {
    return this.http.get<rfiReminder[]>(`${this.baseUrl}getAll`);
  }


  getDataColumns(): Observable<ApiResponseModel> {
    return this.http.get<ApiResponseModel>(`${this.baseUrl}getAll-Columns`);
  }
  getDataByParameter(columnName: string, searchParameter: string, contactTypeIds: number[]): Observable<ApiResponseModel> {
    const data = {
      columnName: columnName,
      searchParameter: searchParameter,
      contactTypeIds: contactTypeIds
    };

    return this.http.post<ApiResponseModel>(`${this.baseUrl}getAll-byParameter`, data)
      .pipe(
        catchError((error: any) => {
          console.error('Error:', error);
          return throwError(error);
        })
      );
  }

  updateData(data: rfiReminder): Observable<rfiReminder> {
    return this.http.put<rfiReminder>(`${this.baseUrl}`, data);
  }


  deleteData(globalId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}?globalId=${globalId}`);
  }

    // getDataId(id: string): Observable<any> {
    //   return this.http.get<any>(`${this.baseUrl}getBy-Id?id=${id}`);
    // }
  getDataId(id: string): Observable<ApiResponseModel> {
    return this.http.get<ApiResponseModel>(`${this.baseUrl}getBy-Id?id=${id}`);
  }

  deleteDataByBookingId(bookingId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}?bookingId=${bookingId}`);
  }
// // // // // // // Sami Add Post Api  // // // /// 

postRFIs(rfisData: CreateRFIsSetupRequest): Observable<ResponseModel<RFIsSetupResponse>> {
  const apiUrl = `${this.baseurl1}`;
  return this.http.post<ResponseModel<RFIsSetupResponse>>(apiUrl, rfisData).pipe(
    catchError((error: any) => {
      console.error('Error:', error);
      return throwError(error);
    })
  );
}


getAllCompanyRFis(): Observable<ResponseModelArray<RFIsSetupResponse>> {
  return this.http.get<ResponseModelArray<RFIsSetupResponse>>(`${this.baseurl1}getData-ByCompany`);
}
}
