import { Injectable } from '@angular/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { SelectiondetupComponent } from 'src/app/company-settings/selectiondetup/selectiondetup.component';
// import { SelectionComponent } from '../../app/company-settings/selection/selection.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {


  constructor(private modalService: NzModalService) { }

  private showModalSource = new Subject<void>();
  showModal$ = this.showModalSource.asObservable();

  // Expose a public method to trigger the next on the subject
  triggerShowModal() {
    this.showModalSource.next();
  }

  // Updated create method to accept additional options
  create(options?: { nzTitle?: string, nzContent?: typeof SelectiondetupComponent }): NzModalRef {
    const modalRef = this.modalService.create({
      nzTitle: options?.nzTitle || 'Default Modal Title',
      nzContent: SelectiondetupComponent ,// Default to null if not provided
      nzFooter: null,
    });

    return modalRef;
  }
}
