<nz-modal nzWidth="60%" [(nzVisible)]="composeNewMessaging" [nzTitle]="ProductModalTitle" [nzFooter]="ProductModalfooter"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '15px', 'max-height': '70vh', 'overflow-y': 'auto' , 'margin': '0px'}"
  [nzStyle]="{ top: '30px' }" (nzOnCancel)="composeNewMessageModelCancel()">
  <ng-template #ProductModalTitle>
        <nz-breadcrumb class="text-start fs-6" nz-page-header-breadcrumb>
        <nz-breadcrumb-item>{{AccessLocalStorageService.getJobName()}}</nz-breadcrumb-item>
        </nz-breadcrumb>
      <div class="row p-0 m-0 ">
        <h1 class="p-0 famly-inn"> Company New Message</h1>
      </div>
  </ng-template>
  
  <div class="content content-set-background " *nzModalContent>
    <div class="ng-container ">

        <nz-card  [nzTitle]="ComposeaMessage" class="rounded-2">
            <ng-template #ComposeaMessage>
                <h4 class="fw-medium famly-inn">Compose a Message</h4>
            </ng-template>
              <div class="row">
                  <span class="sub-text">To <span class="text-danger fw-bolder ms-1">*</span></span>
              </div>
              <div class="row">
                <div class="col-12 flex">
                  <nz-select nzMode="multiple">
                    <nz-option *ngFor="let item of listOfOption" [nzLabel]="item" [nzValue]="item"></nz-option>
                  </nz-select>
                </div>
              </div>
              <!-- <div class="row mt-2">
                <div class="col-md-4">
                    <button class="btn text-primary ms-3" style="cursor: pointer;padding: 5px;" (click)="manageEmailsModelOpen()"><i class="bi bi-plus-circle-fill fs-5 me-2"></i>Add Email</button>
                    <button class="btn text-primary ms-3" style="cursor: pointer;padding: 5px;" (click)="manageEmailsModelOpen()"><i class="bi bi-plus-circle-fill fs-5 me-2"></i>Add Email</button>
              </div>
              </div> -->
              <div class="row mt-2">
                <div class="col-md-4">
                  <button nz-button nzType="link" class="same-but"><i class="bi bi-plus-circle-fill me-2"></i><span class="same-but">Add Email</span></button>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col">
                  <span class="sub-text">CC </span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 flex">
                    <nz-select
                    [nzMaxTagCount]="3"
                    nzMode="multiple"
                  >
                    <nz-option *ngFor="let item of listOfOption" [nzLabel]="item" [nzValue]="item"></nz-option>
                  </nz-select>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                    <button class="btn text-primary ms-3" style="cursor: pointer;padding: 5px;" (click)="manageEmailsModelOpen()"><i class="bi bi-plus-circle-fill fs-5 me-2"></i>Add Email</button>
              </div>
              </div>

              <div class="row mt-2">
                <div class="col">
                  <span class="sub-text">BCC</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 flex">
                    <nz-select
                    [nzMaxTagCount]="3"
                    nzMode="multiple"
                  >
                    <nz-option *ngFor="let item of listOfOption" [nzLabel]="item" [nzValue]="item"></nz-option>
                  </nz-select>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                    <button class="btn text-primary ms-3"  style="cursor: pointer;padding: 5px;" (click)="manageEmailsModelOpen()"><i class="bi bi-plus-circle-fill fs-5 me-2"></i>Add Email</button>
              </div>
              </div>

              <div class="row mt-2">
                <div class="col-12">
                  <span class="sub-text">Subject</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 flex">
                  <input type="text" class="form-control formField" />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <span class="sub-text">Body</span>

                  <ckeditor
                  [(ngModel)]="editorValue['body']"
                  name="notes"
                  [editor]="Editor"
                  [config]="config"
                  class="size"
                  id="editor">
                </ckeditor>
                </div>
              </div>
              <!-- <div class="row mt-2">
                <div class="col-12 flex">
                    <div class="editor-container">
                        <kendo-editor [value]="editorValue"
                          [style]="{'flex-direction': 'column-reverse'}"></kendo-editor>
                      </div>
                </div>
              </div> -->



              <div class="row mt-2">
                <div class="col-12">
                  <span class="sub-text">Attachments</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 flex">
                  <div class="mt-2">
                    <button nz-button nzType="default"
                      style="margin-right: 4px; white-space: nowrap;">Add</button>

                    <!-- <span (click)="Add()">Add</span> -->

                    <button nz-button nzType="default" style="white-space: nowrap;">Create new doc</button>
                    <!-- <span (click)="Doc()">Create New Doc</span> -->
                  </div>
                </div>
              </div>
              
        </nz-card>


      
    </div>
  </div>

  <ng-template #ProductModalfooter>
    <div class="mt-2 align-items-center">
      <button nz-button nzType="default" class="rounded-1 me-0">Save as Draft</button>
      <button nz-button nzType="primary" class="rounded-1 me-0">Send & Close</button>
    </div>
  </ng-template>

</nz-modal>