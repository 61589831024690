import { Component } from '@angular/core';

@Component({
  selector: 'app-inner-tabs-files',
  templateUrl: './inner-tabs-files.component.html',
  styleUrls: ['./inner-tabs-files.component.css']
})
export class InnerTabsFilesComponent {

}
