import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { LeadActivityFormComponent } from './../lead-activity-form/lead-activity-form.component';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { LeadActivityResponse } from 'src/Models/LeadActivity/leadActivityClass';
import { LeadOpportunityResponse } from 'src/Models/LeadTags';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';

@Component({
    selector: 'lead_selection_dialog',
    templateUrl: './dialog-box.component.html',
    styleUrls: ['./dialog-box.component.css']
})
export class LeadOpportunitySelectionDialog implements OnInit {
    @Output() cancel = new EventEmitter<void>();
    leadOptions: LeadOpportunityResponse[] = [];
    selectedLead: LeadOpportunityResponse;
    selectedLeadId: any;
    constructor(private leadserv: LeadOpportunitiesService,
    private modal: NzModalService,
    @Inject(NZ_MODAL_DATA) public data: any
    ) { }

    ngOnInit(): void {
     //   this.getLeadData();
     this.leadOptions = this.data.leads;
        console.log("LeadsData  : ", this.data.leads);

    }

    dialogBoxCancel(): void {
        this.cancel.emit();
    }

    openLeadActivityFormModal(leadSelectedData :LeadOpportunityResponse ) {
        const modalRef = this.modal.create({
            nzContent: LeadActivityFormComponent,
            nzData:{
                leadData: leadSelectedData
            } ,
            nzFooter: null
        });

        modalRef.componentInstance.cancel.subscribe(() => {
            modalRef.destroy();
            this.selectedLead = null;
        });
    }

    onLeadSelect($event: any): void {
        let selectedLead = this.leadOptions.find(x => x.id === $event);
        console.log('show data', selectedLead);
         this.selectedLead = selectedLead;
         this.openLeadActivityFormModal(selectedLead);
    }

    onSubmit(){

    }

}
