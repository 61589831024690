import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { NewProposalComponent } from '../../new-proposal/new-proposal.component';

@Component({
  selector: 'app-copy-to-proposals-template',
  templateUrl: './copy-to-proposals-template.component.html',
  styleUrls: ['./copy-to-proposals-template.component.css']
})
export class CopyToProposalsTemplateComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Input() proposalId: number;
  @Input() leadId: number;
  formFieldValues: any = {};
  IsCopyProposalsTemplate = true;
  isCopyActivityAddresses = false;


  constructor(
    private LeadOpportunitiesService: LeadOpportunitiesService,
    public toastService: NzMessageService,
    private modal: NzModalService,

      ) {}

  ngOnInit(): void {
    this.formFieldValues = {
      name: '',
      leadOpportunityId: this.leadId,
      leadProposalId: this.proposalId,
    };
  }



  save() {
    let data = this.formFieldValues;
    this.LeadOpportunitiesService.PostCopyToProposalTemplate(data).subscribe(
      (response) => {
        data.id = response.result.id;
        this.toastService.success('New Proposal Saved Successfully!');
        this.IsCopyProposalsTemplate = false;
        this.cancel.emit();
        if(this.isCopyActivityAddresses === true){
            const modalRef = this.modal.create({
              nzContent: NewProposalComponent,
              nzFooter: null,
            });
            modalRef.componentInstance.getProposalTemplatesDataId = response.result.id;
            modalRef.componentInstance.cancel.subscribe(() => {
              modalRef.destroy();
            });
        }else{
          return;
        }
      },
      (error) => {
        console.error('Error saving lead:', error);
      }
    );
  }



  handleActivityTemCancel(): void {
    this.IsCopyProposalsTemplate = false;
    this.cancel.emit();
  }

  opencActivityTemModal(): void {
    this.IsCopyProposalsTemplate = true;
  }
  

}
