<div *ngIf="filterResponseData && filterResponseData.length > 0">
    <form (ngSubmit)="onFilterApply()">
      <div class="row mt-2">
        <div class="col-10 p-0">
          <div class="input-group">
            <nz-select [(ngModel)]="formFieldValues['standardFilter']" name="standardFilter" (ngModelChange)="onChangeStandardFilter($event)" nzShowSearch
              [lang]="true" nzAutoFocus="true" nzPlaceHolder="Standard Filter">
              <nz-option *ngFor="let filter of filterResponseData" [nzLabel]="filter.name"
                [nzValue]="filter.id"></nz-option>
            </nz-select>
          </div>
        </div>
        <div class="col-1 mt-1">
          <i class="bi bi-three-dots" (click)="openStandardFilterComponent()"></i>
        </div>
      </div>
  
      <div class="col p-0 me-2 text-end">
        <strong nz-icon nzType="close" class="CloseIconCursor" (click)="closeFilter()" nzTheme="outline"></strong>
      </div>
  
  
  
  
      <div class="row mb-2">
        <div class="col p-0">
          <label class="sub-text">Keyword</label>
          <input  name="keyWord"  [(ngModel)]="formFieldValues['keyWord']"  type="text"  class="form-control formField" />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col p-0">
          <label class="sub-text">Activity Status</label>
          <nz-tree-select  name="activityStatus"  [(ngModel)]="formFieldValues['activityStatus']"   [nzNodes]="activityStatusNodes" nzShowSearch nzAllowClear="false" nzCheckable
            nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col p-0">
          <label class="sub-text">Lead Status</label>
          <nz-tree-select  name="leadStatus"  [(ngModel)]="formFieldValues['leadStatus']"   [nzNodes]="leadStatusNodes" nzShowSearch nzAllowClear="false" nzCheckable
            nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col p-0">
          <label class="sub-text">Activity Type</label>
          <nz-tree-select  name="activityType"  [(ngModel)]="formFieldValues['activityType']"   [nzNodes]="activityTypeNodes" nzShowSearch nzAllowClear="false" nzCheckable
            nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col p-0">
          <label class="sub-text">Assigned User</label>
          <nz-tree-select  name="assignedUser"  [(ngModel)]="formFieldValues['assignedUser']"  [nzNodes]="assignedNodes" nzShowSearch nzAllowClear="false" nzCheckable
            nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col p-0">
          <label nz-checkbox name="includeAttendees"  [(ngModel)]="formFieldValues['includeAttendees']" >Include Attendees in Assigned User Filter</label>
        </div>
      </div>
  
  
  
  
      <div class="row">
        <div class="col text-end">
          <nz-space>
            <button nz-button nzType="default">Clear All</button>
            <button nz-button nzType="primary">Apply Filter</button>
          </nz-space>
        </div>
      </div>
    </form>
  </div>
 