




<nz-modal
  nzWidth="97%"
  [(nzVisible)]="isVisibleLead"
  [nzTitle]="LeadproposalTitle"

  [nzFooter]="LeadproposalFooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '71vh',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '38px' }"
  (nzOnCancel)="showConfirmCardLeadProposal()"
>
  <ng-template #LeadproposalTitle>

    <div class="row" *ngIf="leadOpportunityProposalSaveResponse">
      <p nz-typography class="p-0 m-0">
        {{ leadOpportunityProposalSaveResponse?.title }}
      </p>
    </div>
    <div>
      <div class="row">
        <h1 nz-typography class="p-0 m-0">Lead Proposal</h1>
      </div>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <div class="spinner-wrapper" *ngIf="isLoading">
      <nz-spin nzSimple [nzSpinning]="true" [nzSize]="'large'"></nz-spin>
    </div>

    <nz-alert
      class="mb-3 error-message"
      *ngIf="isSubmitted && errorFields.length > 0"
      nzType="error"
      nzShowIcon
      [nzMessage]="message"
      [nzDescription]="errorDescriptionTemplate"
    ></nz-alert>

    <ng-template #message>
      <p class="mt-1">Please correct the following fields:</p>
    </ng-template>

    <ng-template #errorDescriptionTemplate>
      <ul>
        <li class="sub-text" *ngFor="let error of errorFields">
          <span class="d-flex align-items-center">
            <p>{{ error.label }}:</p>
            <p>{{ error.message }}</p>
          </span>
        </li>
      </ul>
    </ng-template>
    <nz-card
      class="mb-3"
      nzTitle="Lead proposal Details"
      [nzExtra]="leadProposalExtra"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-8">
            <label class="sub-text">Title</label>
          </div>
          <div class="col-2">
            <span >
              <label class="sub-text">Approval Deadline</label>
            </span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-8">
            <input
              #titleInput
              nz-input
              [(ngModel)]="formFieldValues.title"
              name="title"
              required
              (ngModelChange)="onFieldChange('title', $event)"
              [nzStatus]="validationStates['title']"
              class="formField"
            />
            <div
              *ngIf="validationStates['title'] === 'error'"
              class="text-danger"
            >
              {{ validationMessages["title"] }}
            </div>
          </div>
          <div class="col-2">
            <nz-date-picker
              *ngIf="
                getStatusProposal?.code === ProposalStatusEnum.Unreleased ||
                getStatusProposal == null
              "
              [(ngModel)]="formFieldValues.approvalDeadlineOn"
              name="approvalDeadlineOn"
              [nzPlaceHolder]="' '"
              nzSuffixIcon="false"
              [nzFormat]="'MMM d, YYYY'"
              (ngModelChange)="onFieldChange('approvalDeadlineOn', $event)"
              [nzStatus]="validationStates['approvalDeadlineOn']"
            ></nz-date-picker>
            <div
              *ngIf="validationStates['approvalDeadlineOn'] === 'error'"
              class="text-danger"
            >
              {{ validationMessages["approvalDeadlineOn"] }}
            </div>
            <p
              class="mt-1"
              *ngIf="
                getStatusProposal?.code === ProposalStatusEnum.Released ||
                statusUpdateForDecline?.code === ProposalStatusEnum.Declined
              "
            >
              {{
                formFieldValues.approvalDeadlineOn
                  ? (formFieldValues.approvalDeadlineOn | date : "MMM d, YYYY")
                  : "--"
              }}
            </p>
          </div>
        </div>
        <div class="row">
          <div
            [ngClass]="{
              'col-12': !signatureResponse && !signatureResponseDecline,
              'col-8': signatureResponse || signatureResponseDecline
            }"
            class="signature-container"
          >
            <nz-collapse>
              <nz-collapse-panel
                #p
                [ngStyle]="{ background: '#f1f4fa', 'margin-bottom': '24px' }"
                [nzExpandedIcon]="expandedIcon"
                [nzHeader]="tileetwe"
              >
                <div class="row mt-0" #p>
                  <div class="col-12">
                    <div class="row mt-2">
                      <div class="col-10">
                        <label class="sub-text">Internal Notes</label>
                        <textarea
                          [(ngModel)]="formFieldValues.internalNotes"
                          name="internalNotes"
                          nz-input
                          [nzAutosize]="{ minRows: 2, maxRows: 10 }"
                          class="formField"
                          (ngModelChange)="
                            onFieldChange('internalNotes', $event)
                          "
                          [nzStatus]="validationStates['internalNotes']"
                        ></textarea>
                        <div
                          *ngIf="
                            validationStates['internalNotes'] === 'error'
                          "
                          class="text-danger"
                        >
                          {{ validationMessages["internalNotes"] }}
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-10">
                        <label class="sub-text">Introductory Text</label>
                        <div class="row">
                          <ckeditor
                            [(ngModel)]="formFieldValues['introductoryText']"
                            name="introductoryText"
                            [editor]="Editor"
                            [config]="config"
                            class="size"
                            id="editor"
                          >
                          </ckeditor>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-10">
                        <label class="sub-text">Closing Text</label>
                        <div class="row">
                          <!-- <div class="editor-container">
                            <div class="parent-container">
                              <kendo-editor
                                [(ngModel)]="formFieldValues.closingText"
                                name="closingText"
                                [value]="closingText"
                                [style]="{ 'flex-direction': 'column-reverse' }"
                                style="height: 800px"
                              ></kendo-editor>
                            </div> -->
                          <ckeditor
                            [(ngModel)]="formFieldValues['closingText']"
                            name="closingText"
                            [editor]="Editor"
                            [config]="config"
                            class="size"
                            id="editor"
                          >
                          </ckeditor>
                        </div>
                      </div>
                    </div>
                  </div>

                 <!-- Document Card For Lead Proposal Start  Date 08/29/2024-->
                 <div class="card border-0 mt-3">
                  <div class="card-head border-bottom">
                    <h3 class="p-0 m-0 pt-2 pb-2 ms-3 react" nz-typography>Attachments</h3>
                  </div>
                  <div class="card-body bt-file-viewer-card">
                    <div class="row">
                      <div class="col-12">
                        <button nz-button class="button-default react" (click)="uploadFiles()"><span>Add</span></button>
                        <button nz-button class="button-default react ms-2" (click)="CreateNewFile()"><span>Create New File</span></button>
                        <button *ngIf="selectedFilses?.length > 0" nz-button class="button-default react ms-2" (click)="viewAll()"><span>View All ({{ selectedFilses?.length }})</span></button>
                      </div>
                    </div>
                    <i *ngIf="showNavButtons" class="bx bx-chevron-left carousel-nav prev" (click)="previous()"></i>
                    <i *ngIf="showNavButtons" class="bx bx-chevron-right carousel-nav next" (click)="next()"></i>
                    <div class="carousel-container" #carouselContainer>
                      <div class="carousel-view" *ngFor="let fileq of selectedFilses">
                        <div class="bt-file-viewer">
                          <img class="img-1" *ngIf="getFileIcon(fileq)" [src]="getFileIcon(fileq)" />
                          <img (click)="onClick()" class="img-2" *ngIf="!getFileIcon(fileq) && !fileq.type.includes('video')" [src]="fileq.thumbUrl" />
                          <ng-container *ngIf="fileq.type.includes('video')">
                            <div class="video-container">
                              <i nz-icon nzType="play-circle" nzTheme="outline" class="video-play-icon" (click)="playVideo(fileq)"></i>
                              <video #videoPlayer id="video-{{ fileq.id }}" [src]="fileq.thumbUrl" (click)="playVideo(fileq)"></video>
                            </div>
                          </ng-container>
                          <div nz-row class="action-row">
                            <div nz-col nzSpan="24">
                              <label class="BTFileUpload-SecondaryText p-0 m-0 ms-2">{{ fileq.name }}</label>
                            </div>
                            <div nz-col nzSpan="5">
                              <i style="margin: 2px 0px 0px 6px;cursor: pointer;font-size: 21px;color: #626262;" nzTrigger="click" nz-dropdown [nzDropdownMenu]="menu4" class="bx bxs-chevron-down"></i>
                              <nz-dropdown-menu #menu4="nzDropdownMenu">
                                <ul nz-menu style="width: 170px;">
                                  <li nz-menu-item (click)="removeFile(fileq)">
                                    <div class="dropdown-container">
                                      <i style="cursor: pointer;font-size: 20px;color: #626262;" class='bx bx-trash'></i>
                                      <span class="react">Delete</span>
                                    </div>
                                  </li>
                                  <li nz-menu-item (click)="uploadFilesUpdate(fileq)" *ngIf="isImageFile(fileq)" >
                                    <div class="dropdown-container">
                                      <i style="cursor: pointer;font-size: 20px;color: #626262;" class='bx bxs-pencil'></i>
                                      <span class="react">Edit Online</span>
                                    </div>
                                  </li>
                                </ul>
                              </nz-dropdown-menu>
                            </div>
                            <div nz-col nzSpan="4" *ngIf="isImageFile(fileq)" >
                              <i (click)="uploadFilesUpdate(fileq)" style="cursor: pointer;font-size: 26px;color: #626262;" class='bx bxs-pencil'></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <!-- Document Card For Lead Proposal End -->




                </div>
                <ng-template #tileetwe>
                  <strong class="ms-3 fS-5">More Details</strong>
                </ng-template>
                <ng-template #expandedIcon let-active>
                  {{ active }}
                  <span
                    nz-icon
                    nzType="caret-right"
                    class="ant-collapse-arrow"
                    [nzRotate]="p.nzActive ? 90 : -0"
                  ></span>
                </ng-template>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
          <div
            class="col-4"
            *ngIf="signatureResponse || signatureResponseDecline"
          >
            <div class="signatureImage" *ngIf="signatureResponse">
              <img
                [src]="signatureResponse?.imageSource"
                alt="Signature"
                class="signature-img"
              />
            </div>
            <div
              class="signatureImage"
              *ngIf="getStatusProposal?.code === ProposalStatusEnum.Released"
            >
              <img
                [src]="signatureResponseDecline?.imageSource"
                alt="Signature"
                class="signature-img"
              />
            </div>
          </div>
        </div>
      </div>
    </nz-card>
    <ng-template #leadProposalExtra >

      <!-- Display statusUpdateForDecline if present -->
      <div
        *ngIf="statusUpdateForDecline; else statusProposalTemplate"
        [ngClass]="{
          declined: statusUpdateForDecline?.description === 'Declined'
        }"
        class="status-text d-flex justify-content-center align-items-center"
      >
        <strong class="fs-6">{{ statusUpdateForDecline?.description }}</strong>
      </div>

      <!-- Template for getStatusProposal -->
      <ng-template #statusProposalTemplate>
        <!-- Conditionally show this paragraph based on the status -->
        <p class="me-2" *ngIf="getStatusProposal?.description !== 'Unreleased'">
          <!-- Display appropriate text based on the status -->
          <span *ngIf="getStatusProposal?.description === 'Released'">
            Released by {{ footerResponse?.fullName }} on {{ footerResponse?.createdOnFormatted }}
          </span>
          <span *ngIf="getStatusProposal?.description === 'Declined'">
            Declined by {{ footerResponse?.fullName }} on {{ footerResponse?.createdOnFormatted }}
          </span>
          <span *ngIf="getStatusProposal?.description === 'Approved'">
            Approved by {{ footerResponse?.fullName }} on {{ footerResponse?.createdOnFormatted }}
          </span>
          <span *ngIf="getStatusProposal?.description === 'Pending'">
            Pending approval since {{ footerResponse?.createdOnFormatted }}
          </span>
          <span *ngIf="getStatusProposal?.description === 'Expired'">
            Expired on {{ footerResponse?.createdOnFormatted }}
          </span>
        </p>

        <!-- Status text with dynamic classes based on the description -->
        <div
          *ngIf="getStatusProposal"
          [ngClass]="{
            pending: getStatusProposal?.description === 'Pending',
            unreleased: getStatusProposal?.description === 'Unreleased',
            approved: getStatusProposal?.description === 'Approved',
            declined: getStatusProposal?.description === 'Declined',
            'declined-not-released': getStatusProposal?.description === 'Declined Not Released',
            expired: getStatusProposal?.description === 'Expired',
            released: getStatusProposal?.description === 'Released'
          }"
          class="status-text d-flex justify-content-center align-items-center"
        >
          <strong class="fs-6">{{ getStatusProposal?.description }}</strong>
        </div>
      </ng-template>

    </ng-template>



    <!-- tabs -->
    <div class="card-container">
      <nz-tabset nzType="card">
        <nz-tab *ngFor="let tab of tabs" [nzTitle]="leadproposalTabs(tab)">
          <ng-container *ngIf="tab === 1">
            <nz-card
              nzBorderless="true"
              nzTitle="Proposal Worksheet"
              [nzExtra]="leadProposalTabsTemplate"
            >
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-2">
                    <small class="ListViewJobname">Total Owner Price</small>
                  </div>
                  <div class="col-2">
                    <small class="ListViewJobname">Total Builder Cost</small>
                  </div>
                  <div class="col-2">
                    <small class="ListViewJobname">Estimated Profit</small>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2">
                    <strong class="" style="letter-spacing: 1px">
                      {{ totalOwnerPrice | currency:'USD' }}
                    </strong>
                  </div>
                  <div class="col-2">
                    <strong class="" style="letter-spacing: 1px">
                      {{ totalBuilderCost | currency:'USD' }}
                    </strong>
                  </div>
                  <div class="col-2">
                    <strong class="" style="letter-spacing: 1px">
                      {{ estimatedProfit | currency:'USD' }}
                    </strong>
                  </div>
                </div>
              </div>
            </div>

              <div class="row" style="background-color: rgb(241, 244, 250)">
                <div class="col-12">
                  <div class="row pt-2 pb-2 gx-0">
                    <div class="col-2">
                      <!-- <button
                        class=""
                        nzTrigger="click"
                        nz-button
                        nz-dropdown
                        [disabled]="!isAnyRowChecked()"
                      >
                        Checked Option
                        <span nz-icon nzType="down"></span>
                      </button> -->


                      <!-- Button to trigger dropdown -->
<button nz-button nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click" [disabled]="!isAnyRowChecked()">
  Checked Option
  <span nz-icon nzType="down"></span>
</button>

<!-- Dropdown menu -->
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item (click)="onAdjustMarkup()">
      Adjust Percentage Markup
    </li>
    <li nz-menu-item (click)="onDeleteChecked()">
      Delete Checked
    </li>
    <li *ngIf="showAddGroupButton && selectedGroup === 'Custom'" nz-menu-item (click)="onMoveItems()">
      Move Items
    </li>
  </ul>
</nz-dropdown-menu>



                    </div>
                    <div class="col-2">
                      <button
                        [ngClass]="{
                          disabled:
                            !approveAllowButtons() ||
                            !declineAllowButtons() ||
                            !ReleaseAllowButtons()
                        }"
                        class="ms-2"
                        nzTrigger="click"
                        nz-button
                        nz-dropdown
                        [nzDropdownMenu]="menu4"
                        [disabled]="DisabledNextActivity"
                        [class.disabled]="DisabledNextActivity"
                      >
                      {{ selectedGroup ? 'Group: ' + selectedGroup : 'Group' }}
                        <span nz-icon nzType="down"></span>
                      </button>
                      <nz-dropdown-menu #menu4="nzDropdownMenu">
                        <ul nz-menu>
                          <li nz-menu-item (click)="selectedOption('None')">None</li>
                          <li nz-menu-item  (click)="selectedOption('Cost Category')">Cost Category</li>
                          <li nz-menu-item (click)="selectedOption('Custom')">Custom</li>
                        </ul>
                      </nz-dropdown-menu>
                      </div>
                    <div class="col-2">
              <button *ngIf="showAddGroupButton" nz-button nzType="default">Add Group</button>
                    </div>
     <div class="col ms-auto text-end">                      <a
                        nz-popover
                        [nzPopoverContent]="Summary"
                        nzPopoverPlacement="top"
                        class="fs-5 me-3"
                        nz-icon
                        nzType="download"
                        nzTheme="outline"
                      ></a>
                      <ng-template #Summary>
                        <div class="">
                          <p style="font-size: 13px">Export to Excel</p>
                        </div>
                      </ng-template>
                      <a
                        (click)="editColumns()"
                        nz-popover
                        [nzPopoverContent]="Summary1"
                        nzPopoverPlacement="top"
                        nz-icon
                        nzType="setting"
                        nzTheme="fill"
                        class="fs-5 me-2"
                      ></a>
                      <ng-template #Summary1>
                        <div class="">
                          <p style="font-size: 13px">Edit Columns</p>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row border"
                [ngClass]="{
                  disabled:
                    !approveAllowButtons() ||
                    !declineAllowButtons() ||
                    !ReleaseAllowButtons()
                }"
              >
                <div
                  class="col-12 p-0 m-0"
                  [ngClass]="{
                    disabled:
                      !approveAllowButtons() ||
                      !declineAllowButtons() ||
                      !ReleaseAllowButtons()
                  }"
                >
                <nz-table
                [nzFooter]="customFooter"
                nzFooter="Footer"
                [nzBordered]="false"
                nzSize="small"
                #editRowTable
                nzShowPagination="false"
                [nzScroll]="{ x: '1625px' }"
                [nzData]="listOfData"
              >
                <thead
                  style="
                    background-color: #f1f4fa;
                    border-bottom: 1px solid #ccc;
                  "
                >
                  <tr>
                    <th nzLeft nzWidth="9%">
                      <span style="align-items: center; position: sticky">
                        <label
                        nz-checkbox
                        [(ngModel)]="allChecked"
                        (ngModelChange)="onAllChecked($event)"
                        [nzIndeterminate]="indeterminate"
                        [nzDisabled]="!isRowCreated"
                      ></label>
                      </span>
                    </th>
                    <th nzLeft nzWidth="55%" class="sub-text1">Items</th>
                    <th nzWidth="55%" class="sub-text1">Description</th>
                    <th nzWidth="20%" class="sub-text1 text-end">Quantity</th>
                    <th nzWidth="17%" class="sub-text1 text-start">Unit</th>
                    <th nzWidth="25%" class="sub-text1 text-end">Unit Cost</th>
                    <th nzWidth="30%" class="sub-text1 text-start">Cost Type</th>
                    <th nzWidth="23%" class="sub-text1 text-end">Builder Cost</th>
                    <th nzWidth="26%" class="sub-text1">Markup</th>
                    <th nzWidth="25%" class="sub-text1 text-end">Owner Price</th>
                    <th nzWidth="25%" class="sub-text1 text-end">Margin</th>
                    <th nzWidth="17%" class="sub-text1 text-end">Profit</th>
                    <th nzRight nzWidth="18%" class="sub-text1"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="custom-table-row">
                    <td  class="border" [colSpan]="13">
                      <a (click)="addNewRow()">
                        <span class="me-2 fs-5" nz-icon nzType="plus-circle" nzTheme="fill"></span>
                        <span class="fs-6">Item</span>
                      </a>
                    </td>
                  </tr>


                  <ng-container
                   *ngFor="let item of items; let i = index trackBy: trackById">
                    <tr
                      [ngClass]="{
                        'default-color': !item.isEditable && !isMissingRequiredFields(item),
                        'clicked-color': item.isEditable,
                        'error-background': isMissingRequiredFields(item)
                      }"
                      class="editable-row"
                      (click)="highlightRow(i)"
                      [attr.data-id]="item.id"
                    >
                    <td nzLeft nzWidth="9%" class="table-cell-td table-cell-td-color">
                      <label nz-checkbox [(ngModel)]="item.checked" (ngModelChange)="onItemChecked($event, index)"></label>
                    </td>

                  <td nzLeft nzWidth="55%" class="table-cell-td table-cell-td-color editable-row">
                    <div  class=""  [hidden]="item.isEditable">
                      <div style="color: #004bcc;" (click)="leadProposalItemEdit(item)">{{ item?.title || "--"}} <br> {{item.costCodeId || "Sami" }}</div>
                      <!-- <div (click)="leadProposalItemEdit(item)">{{ item?.costCode?.title || "--" }}</div> -->
                    </div>
                    <label  [hidden]="!item.isEditable" nzWidth="55%" class="sub-textInnerLable mt-4">Title</label>
                    <div [hidden]="!item.isEditable">
                      <input
                      type="text"
                      nz-input
                      [(ngModel)]="item.title"
                      name="title_{{ i }}"
                      (blur)="stopEdit(item.id)" />
                    </div>
                  </td>
                        <td nzWidth="55%" class="table-cell-td table-cell-td-color">
                           <div
                              class="editable-cell"
                              [hidden]="item.isEditable"
                              (click)="startEdit(item.id)"
                            >
                              {{ item?.description || "--"}}
                            </div>
                            <label  [hidden]="!item.isEditable" nzWidth="55%" class="sub-textInnerLable mt-4">Description</label>
                            <textarea
                            [hidden]="!item.isEditable"
                            (blur)="stopEdit(item.id)"
                            type="text"
                            nz-input
                            [(ngModel)]="item.description"
                            name="description_{{ i }}"
                            ></textarea>
                       </td>
                      <!-- Quantity -->
                      <td nzWidth="20%" class="table-cell-td table-cell-td-color">
                        <div
                          class="text-end editable-cell"
                          [hidden]="item.isEditable"
                          (click)="startEdit(item.id)"
                        >
                          {{ item?.quantity || "--" }}
                        </div>
                        <label [hidden]="!item.isEditable" class="sub-textInnerLable text-end">Quantity</label>
                        <nz-input-number
                        [hidden]="!item.isEditable"
                        [(ngModel)]="item.quantity"
                        (ngModelChange)="onQuantityChange(item)"
                        (blur)="stopEdit(item.id)"
                        name="quantity_{{ i }}"
                        [nzMin]="0"
                        [nzStep]="1"
                        [nzFormatter]="quantityFormatter"
                        [nzParser]="quantityParser"
                      ></nz-input-number>




                      </td>
                      <td class="table-cell-td table-cell-td-color">
                        <div
                          class="text-end editable-cell"
                          [hidden]="item.isEditable"
                          (click)="startEdit(item.id)"
                        >
                          {{ item?.unit || "--" }}
                        </div>
                        <label [hidden]="!item.isEditable" for="" class="sub-text">Unit</label>
                        <input
                        [hidden]="!item.isEditable"
                          (blur)="stopEdit(item.id)"
                          [(ngModel)]="item.unit"
                          name="unit_{{ i }}"
                          type="text"
                          maxlength="8"
                          nz-input
                          class="formField"
                        />
                      </td>
                      <td  class="table-cell-td table-cell-td-color">
                        <div
                          class="editable-cell text-end"
                          [hidden]="item.isEditable"
                          (click)="startEdit(item.id)"
                        >
                          {{ item?.unitCost || "--"}}
                        </div>
                    <label [hidden]="!item.isEditable" nzWidth="25%" class="sub-textInnerLable text-end">Unit Cost</label>

                        <!-- <input
                          [(ngModel)]="item.unitCost"
                        name="unitCost_{{ i }}"
                        *ngIf="showDollarFormatter"
                        class="formField p-1"
                        [hidden]="!item.isEditable"
                        (blur)="stopEdit(item.id)"
                        (ngModelChange)="updateBuilderCost(item)"
                        nz-input
                         /> -->

                    <!-- Input field with $ symbol -->
                    <!-- <nz-input-group nzAddOnBefore="$">
                      <nz-input-number
                        [(ngModel)]="item.unitCost"
                        name="unitCost_{{ i }}"
                        class="formField"
                        [hidden]="!item.isEditable"
                        (blur)="stopEdit(item.id)"
                        (ngModelChange)="onQuantityChange( item)"
                        nz-input
                        [nzFormatter]="quantityFormatter"
                        [nzParser]="quantityParser"
                      ></nz-input-number>
                    </nz-input-group>
                     -->
                     <nz-input-group nzAddOnBefore="$">
                      <input
                        nz-input
                        appDollarFormatter
                        [(ngModel)]="
                          formFieldValues['estimatedRevenueFrom']
                        "
                        name="estimatedRevenueFrom"
                      />
                    </nz-input-group>

                      </td>
                      <td class="table-cell-td table-cell-td-color">
                        <!-- Display the selected label -->
                        <div
                          class="text-start editable-cell"
                          [hidden]="item.isEditable"
                          (click)="startEdit(item.id)"
                        >
                          {{ getSelectedOptionName(item.costTypeId) || "--" }}
                        </div>

                        <!-- Label and Select for editing -->
                        <label
                          [hidden]="!item.isEditable"
                          nzWidth="30%"
                          class="sub-textInnerLable text-start"
                        >
                          Cost Type
                        </label>
                        <nz-select
                          [hidden]="!item.isEditable"
                          style="width: 100%"
                          [(ngModel)]="item.costTypeId"
                          name="costTypeId_{{ i }}"
                          nzPlaceHolder="None"
                          (nzOpenChange)="onSelectOpenChange($event)"
                        >
                          <nz-option
                            *ngFor="let option of proposalOption"
                            [nzLabel]="option.name"
                            [nzValue]="option.id"
                          ></nz-option>
                        </nz-select>
                      </td>
                      <td class="table-cell-td table-cell-td-color">
                        <div
                          class="text-start editable-cell"
                          [hidden]="item.isEditable"
                          (click)="startEdit(item.id)"
                        >
                        {{ item.builderCost.toFixed(2) || "--"}}
                        </div>
                        <label [hidden]="!item.isEditable" nzWidth="23%" class="sub-textInnerLable text-end">Builder Cost</label>
                        <strong
                        [hidden]="!item.isEditable"
                          (blur)="stopEdit(item.id)"
                          [(ngModel)]="item.builderCost"
                          name="builderCost_{{ i }}"
                        >{{item.builderCost | currency}}</strong>
                      </td>
                      <td class="table-cell-td table-cell-td-color">
                        <!-- Editable Area -->
                        <div
                        class="text-start editable-cell"
                        [hidden]="item.isEditable"
                        (click)="startEdit(item.id)"
                      >
                   {{ formatMarkupValue(formFieldValues.markupValue, item.markupId) || '--' }}
                      </div>

                        <!-- Non-Editable Label -->
                        <label [hidden]="!item.isEditable" nzWidth="30%" class="sub-textInnerLable text-start">
                          Markup
                        </label>
                        <div [hidden]="!item.isEditable">
                         <div class="row gx-0">
                           <div class="col-8">
                             <nz-input-group nzAddOnBefore="$" *ngIf="item.markupId === 40418 || item.markupId === 40419 ">
                               <input
                                 nz-input
                                 class="formField"
                                 appDollarFormatter
                                 [(ngModel)]="item.markupValue"
                                 name="markupValue"
                                 (ngModelChange)="updateBuilderCost(item)"
                               />
                             </nz-input-group>
                             <nz-input-group *ngIf="item.markupId === 40417">
                              <input
                                nz-input
                                class="p-1"
                                appDollarFormatter
                                [(ngModel)]="item.markupValue"
                                name="markupValue"
                                (ngModelChange)="updateBuilderCost(item)"
                                maxlength="16"
                              />
                            </nz-input-group>


                             <!-- <nz-input-group  *ngIf="item.markupId === 40417">
                               <input
                                 nz-input
                                 class="p-1"
                                 appPercentage
                                 [(ngModel)]="item.markupValue"
                                   appDollarFormatter
                                 name="markupValue"
                                 maxlength="16"
                                 (ngModelChange)="updateBuilderCost(item)"
                               />
                             </nz-input-group> -->
                             <nz-input-group nzAddOnBefore="--" *ngIf="item.markupId === 40420">
                               <input
                                 nz-input
                                 class="formField"
                                 [(ngModel)]="item.markupValue"

                                 name="markupValue"
                                 [disabled]="true"

                               />
                             </nz-input-group>
                           </div>
                           <div class="col-4">
                             <nz-select
                               style="width: 100%;"
                               [(ngModel)]="item.markupId"
                               name="markupId"
                               (ngModelChange)="onMarkupChange($event, item)"
                             >
                               <nz-option *ngFor="let item of markupOption" [nzLabel]="item.description" [nzValue]="item.id"></nz-option>
                             </nz-select>
                           </div>
                         </div>
                        </div>
                      </td>
                      <td  class="table-cell-td table-cell-td-color">
                        <div
                          class="fw-bold text-end"
                          [hidden]="item.isEditable"
                          (click)="startEdit(item.id)"
                        >
                        ${{ item.ownerPrice || "--"}}
                        </div>


                    <label [hidden]="!item.isEditable" nzWidth="25%" class="sub-textInnerLable text-end">Owner price</label>
                        <input
                        [(ngModel)]="item.ownerPrice"
                        name="ownerPrice_{{ i }}"
                        *ngIf="showDollarFormatter"
                        class="formField p-1"
                        disabled
                        [hidden]="!item.isEditable"
                        (blur)="stopEdit(item.id)"
                        nz-input
                         />
                    <!-- Input field with $ symbol -->
                    <nz-input-group  nzPrefix="$">
                      <input
                      [(ngModel)]="item.ownerPrice"
                      name="ownerPrice_{{ i }}"
                          disabled
                          [hidden]="!item.isEditable"
                          (blur)="stopEdit(item.id)"
                          appDollarFormatter
                          nz-input
                      />
                    </nz-input-group>
                      </td>
                      <td class="table-cell-td table-cell-td-color">
                        <div
                        class="editable-cell text-end"
                        [hidden]="item.isEditable"
                        (click)="startEdit(item.id)"
                      >
                        {{ isNumber(item.margin) ? item.margin.toFixed(2) : '--' }}%
                      </div>
                        <label [hidden]="!item.isEditable" nzWidth="35%" class="sub-textInnerLable text-end">Margin</label>
                        <nz-input-group nzAddOnBefore="%"  >
                         <input
                         nz-input
                         class="p-1"
                         appPercentage
                         [hidden]="!item.isEditable"
                         (blur)="stopEdit(item.id)"
                         [(ngModel)]="item.margin"
                         name="margin_{{i}}"
                       />
                    </nz-input-group>

                      </td>



                      <td>
                        <div class="row">
                          <label class="sub-text text-end">Profit</label>
                        </div>
                        <div class="row">
                          <span
                            class="fw-bold mb-2 text-end"
                            [(ngModel)]="item.profit"
                            name="profit{{ i }}"
                            >${{ item.profit.toFixed(2) || "--"}}</span
                          >
                        </div>
                      </td>
                      <td nzRight>
                        <span
                          (click)="ConfirmDelete(i)"
                          nz-icon
                          nzType="delete"
                          class="text-primary text-center fs-4 k-cursor-pointer"
                          nzTheme="fill"
                        ></span>
                      </td>
                    </tr>
                    <tr
                    *ngIf="activeRow === i || isMissingRequiredFields(item)"
                    [ngClass]="{
                      'default-color': !item.isEditable && !isMissingRequiredFields(item),
                      'clicked-color': item.isEditable, 'error-background': isMissingRequiredFields(item)
                    }"
                    class="expanded-row"
                    [attr.data-id]="item.id"
                    (click)="highlightRow(i)"
                  >

                          <td nzLeft style="width: 40%"></td>
                          <!-- <td nzLeft style="width: 40%"> -->
                            <td nzLeft class="table-cell-td table-cell-td-color">
                              <!-- Display the selected label -->
                              <div
                                class="text-start editable-cell"
                                *ngIf="!item.isEditable"
                                (click)="startEdit(item.id)"
                              >
                                {{ item.costCodeId || "--" }}
                              </div>

                              <!-- Label and Select for editing -->
                              <label [hidden]="!item.isEditable" class="sub-text">
                                Cost Code
                                <span class="fw-bolder text-danger">*</span>
                              </label>
                              <nz-select
                              [hidden]="!item.isEditable"
                              nzShowSearch
                              [nzStatus]="item.showValidationMessage && !item.costCodeId ? 'error' : ''"
                              nzAutoFocus="true"
                              nzPlaceHolder="Select Lead"
                              [(ngModel)]="item.costCodeId"
                              name="costCodeId_{{ i }}"
                              (nzOpenChange)="onSelectOpenChange($event)"
                            >
                              <nz-option
                                *ngFor="let option of leadOptions"
                                [nzLabel]="option.title"
                                [nzValue]="option.id"
                              ></nz-option>
                            </nz-select>

                            <!-- Validation message -->
                            <div *ngIf="item.showValidationMessage && !item.costCodeId" class="text-danger">
                              Required
                            </div>
                            </td>

                          <td style="width: 17%">
                            <label for="" class="sub-text">Mark As</label>
                            <nz-select
                              style="width: 100%"
                              nzPlaceHolder="None"
                              [(ngModel)]="item.markAsId"
                              name="markAsId_{{ i }}"
                            >
                              <nz-option
                                *ngFor="let option of markAsOption"
                                [nzLabel]="option.name"
                                [nzValue]="option.id"
                              ></nz-option>
                            </nz-select>
                          </td>
                          <td style="width: 17%"></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style="width: 17%"></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td nzRight></td>
                        </tr>
                      </ng-container>

                      <ng-container *ngIf="leadOpportunitiesCost != null">
                        <ng-container *ngFor="let data of leadOpportunitiesCost; let i = index">
                          <tr [ngClass]="{'default-color': activeRow !== i, 'clicked-color': activeRow === i}" class="editable-row editable-cell" (click)="highlightRow(i)">
                        <td
                        nzLeft
                        nzWidth="9%"
                        class="table-cell-td table-cell-td-color"
                        [ngClass]="{'row-highlight': activeRow === i}">
                        <span style="align-items: center; position: sticky">
                          <label nz-checkbox></label>
                        </span>
                      </td>
                      <td
                        nzLeft
                        nzWidth="55%"
                        class="table-cell-td table-cell-td-color editable-row"
                        [ngClass]="{'row-highlight': activeRow === i}"
                        (click)="leadProposalItemEdit(data)"
                      >
                        <a>
                          <div class="flex editable-row">{{ data?.title }}</div>
                          <div class="ant-space-item editable-row">{{ data?.costCode?.title }}</div>
                        </a>
                      </td>
                          <td nzWidth="55%" class="table-cell-td table-cell-td-color">
                            <div
                              class="editable-cell"
                              [hidden]="editId === data.id"
                              (click)="startEdit(data.id)"
                            >
                              {{ data?.description }}
                            </div>
                            <label [hidden]="editId !== data.id" nzWidth="55%" class="sub-textInnerLable mt-4">Description</label>
                            <textarea
                            [hidden]="editId !== data.id"
                            (blur)="stopEdit(data.id)"
                            type="text"
                            nz-input
                            [(ngModel)]="data.description"
                            name="description_{{ i }}"
                            ></textarea>
                          </td>
                          <td nzWidth="20%" class="table-cell-td table-cell-td-color">
                            <div
                              class="text-end editable-cell"
                              [hidden]="editId === data.id"
                              (click)="startEdit(data.id)"
                            >
                              {{ data?.quantity }}
                            </div>
                            <label [hidden]="editId !== data.id" nzWidth="20%" class="sub-textInnerLable text-end">Quantity</label>
                            <nz-input-number
                            [hidden]="editId !== data.id"
                              (blur)="stopEdit(data.id)"
                              type="number"
                              nz-input
                              [(ngModel)]="data.quantity"
                              (ngModelChange)="updateBuilderCost(item)"
                              name="quantity_{{ i }}"
                            >
                            </nz-input-number>
                          </td>
                          <td nzWidth="17%" class="table-cell-td table-cell-td-color">
                            <div
                              class="text-start editable-cell"
                              [hidden]="editId === data.id"
                              (click)="startEdit(data.id)"
                            >
                              {{ data?.unit }}
                            </div>
                            <label [hidden]="editId !== data.id" nzWidth="17%" class="sub-textInnerLable text-start">Unit</label>
                            <input
                              [hidden]="editId !== data.id"
                              (blur)="stopEdit(data.id)"
                              (ngModelChange)="updateBuilderCost(item)"
                              type="text"
                              nz-input
                              [(ngModel)]="data.unit"
                              name="unit_{{ i }}"
                            />
                          </td>
                          <td nzWidth="25%" class="table-cell-td table-cell-td-color">
                            <div
                              class="text-end editable-cell"
                              [hidden]="editId === data.id"
                              (click)="startEdit(data.id)"
                            >
                              {{ data?.unitCost }}
                            </div>
                            <label [hidden]="editId !== data.id" nzWidth="25%" class="sub-textInnerLable text-end">Unit Cost</label>
                            <input
                            *ngIf="!showDollarFormatter"
                            class="formField"
                            [hidden]="editId !== data.id"
                            (blur)="stopEdit(data.id)"
                            (ngModelChange)="updateBuilderCost(item)"
                            nz-input
                            [(ngModel)]="data.unitCost"
                             />
                        <!-- Input field with $ symbol -->
                            <input
                                class="formField"
                                [hidden]="editId !== data.id"
                                (blur)="stopEdit(data.id)"
                                appDollarFormatter
                                nz-input
                                [(ngModel)]="data.unitCost"
                                name="unitCost_{{ i }}"
                                />
                          </td>
                          <td nzWidth="25%" class="table-cell-td table-cell-td-color">
                            <div
                              class="text-start editable-cell"
                              [hidden]="editId === data.id"
                              (click)="startEdit(data.id)"
                            >
                              {{ data?.costCode?.title }}
                            </div>
                            <label [hidden]="editId !== data.id" nzWidth="30%" class="sub-textInnerLable text-start">Cost Type</label>
                            <nz-select
                            [hidden]="editId !== data.id"
                            (blur)="stopEdit(data.id)"
                            style="width: 100%"
                            [(ngModel)]="data.costTypeId"
                            name="costTypeId_{{ i }}"
                            nzPlaceHolder="None"
                          >
                            <nz-option
                              *ngFor="let option of proposalOption"
                              [nzLabel]="option.name"
                              [nzValue]="option.id"
                            ></nz-option>
                          </nz-select>
                          </td>
                          <td nzWidth="23%" class="table-cell-td table-cell-td-color">
                            <div
                              class="text-start editable-cell"
                              [hidden]="editId === data.id"
                              (click)="startEdit(data.id)"
                            >
                              {{ data?.builderCost }}
                            </div>
                            <label [hidden]="editId !== data.id" nzWidth="23%" class="sub-textInnerLable text-end">Builder Cost</label>
                            <strong
                            [hidden]="!item.isEditable"
                              (blur)="startEdit(data.id)"
                              [(ngModel)]="data.builderCost"
                              name="builderCost_{{ i }}"
                            >{{data.builderCost | currency}}</strong>
                          </td>
                          <td nzWidth="26%" class="table-cell-td table-cell-td-color">
                            <div
                              class="text-end editable-cell"
                              [hidden]="editId === data.id"
                              (click)="startEdit(data.id)"
                            >
                              {{ data?.markupValue }}
                            </div>
                            <label [hidden]="editId !== data.id" nzWidth="26%" class="sub-textInnerLable">Markup</label>
                            <input
                              [hidden]="editId !== data.id"
                              (blur)="stopEdit(data.id)"
                              nz-input
                              appDollarFormatter
                              [(ngModel)]="data.markupValue"
                              name="markupValue_{{ i }}"
                            />
                          </td>
                          <td nzWidth="25%" class="table-cell-td table-cell-td-color">
                            <div
                              class="editable-cell text-end"
                              [hidden]="editId === data.id"
                              (click)="startEdit(data.id)"
                            >
                              {{ data?.ownerPrice }}
                            </div>
                            <label [hidden]="editId !== data.id" nzWidth="25%" class="sub-textInnerLable text-end">Owner Price</label>
                             <nz-input-group  nzAddOnBefore="$">
                            <input
                                class="formField"
                                [hidden]="editId !== data.id"
                                (blur)="stopEdit(data.id)"
                                appDollarFormatter
                                nz-input
                                 [(ngModel)]="data.ownerPrice"
                                 name="ownerPrice_{{ i }}"
                            />
                        </nz-input-group>
                          </td>
                          <td nzWidth="17%" class="table-cell-td table-cell-td-color">
                            <div
                              class="editable-cell"
                              [hidden]="editId === data.id"
                              (click)="startEdit(data.id)"
                            >
                              {{ data?.margin }}
                            </div>
                            <label [hidden]="editId !== data.id" nzWidth="17%" class="sub-textInnerLable text-end">Margin</label>
                            <input
                              [hidden]="editId !== data.id"
                              (blur)="stopEdit(data.id)"
                              appDollarFormatter
                              nz-input
                              [(ngModel)]="data.margin"
                             name="margin_{{ i }}"
                            />
                          </td>
                          <td nzWidth="17%" class="table-cell-td table-cell-td-color">
                            <label [hidden]="editId !== data.id" nzWidth="17%" class="sub-textInnerLable text-end">Profit</label>
                            <div class="text-end">{{ data?.profit }}</div>
                          </td>
                          <td nzRight>
                            <span
                              (click)="deleteRow(i)"
                              nz-icon
                              nzType="delete"
                              class="text-primary text-center fs-4 k-cursor-pointer"
                              nzTheme="fill"
                            ></span>
                          </td>
                        </tr>
                        <tr
                        [ngClass]="{
                            'default-color': activeRow !== i,
                            'clicked-color': activeRow === i
                          }"
                        *ngIf="activeRow === i"
                        >
                          <td nzLeft></td>
                          <td nzLeft></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td nzWidth="25%" class="table-cell-td table-cell-td-color">

                          <label [hidden]="editId !== data.id" nzWidth="55%" class="sub-textInnerLable">Mark As</label>
                          <nz-select
                          [hidden]="editId !== data.id"
                          (blur)="stopEdit(data.id)"
                          style="width: 100%"
                          [lang]="true"
                          nzPlaceHolder="None"
                          [(ngModel)]="data.markAsId"
                          name="markAsId_{{ i }}"
                        >
                          <nz-option
                            *ngFor="let option of markAsOption"
                            [nzLabel]="option.name"
                            [nzValue]="option.id"
                          ></nz-option>
                        </nz-select>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td nzRight></td>
                        </tr>
                      </ng-container>
                      </ng-container>





                    </tbody>
                    <ng-template #customFooter>
                      <tfoot>
                        <tr
                          class="ms-2 custom-table-row-tr"
                          style="border: solid 2px #ccc"
                        >
                          <td nzLeft style="width: 9%">
                            <span style="align-items: center; position: sticky"
                              ><label nz-checkbox></label
                            ></span>
                          </td>
                          <td nzLeft style="width: 13%" class="sub-text1"></td>
                          <td style="width: 18%" class="sub-text1"></td>
                          <td style="width: 6%" class="sub-text1 text-end"></td>
                          <td
                            style="width: 6%"
                            class="sub-text1 text-start"
                          ></td>
                          <td style="width: 8%" class="sub-text1 text-end"></td>
                          <td
                            style="width: 5%"
                            class="sub-text1 text-start"
                          ></td>
                          <td style="width: 6%" class="sub-text1 text-end">
                            {{ totalBuilderCost | currency }}
                          </td>
                          <td style="width: 7%" class="sub-text1"></td>
                          <td style="width: 7%" class="sub-text1 text-end">
                            {{ totalOwnerPrice | currency }}
                          </td>
                          <td style="width: 7%" class="sub-text1 text-end"></td>
                          <td style="width: 7%" class="sub-text1 text-end">
                            {{ estimatedProfit | currency }}
                          </td>
                          <td nzRight style="width: 15%" class="sub-text1"></td>
                        </tr>
                      </tfoot>
                    </ng-template>
                  </nz-table>

                  <ng-template #customFooter>
                    <tfoot>
                      <tr
                        class="ms-2 custom-table-row-tr"
                        style="border: solid 2px #ccc"
                      >
                        <td nzLeft style="width: 9%">
                          <span style="align-items: center; position: sticky"
                            ><label nz-checkbox></label
                          ></span>
                        </td>
                        <td nzLeft style="width: 13%" class="sub-text1"></td>
                        <td style="width: 18%" class="sub-text1"></td>
                        <td style="width: 6%" class="sub-text1 text-end"></td>
                        <td style="width: 6%" class="sub-text1 text-start"></td>
                        <td style="width: 8%" class="sub-text1 text-end"></td>
                        <td style="width: 5%" class="sub-text1 text-start"></td>
                        <td style="width: 6%" class="sub-text1 text-end">
                          {{ totalBuilderCost | currency }}
                        </td>
                        <td style="width: 7%" class="sub-text1"></td>
                        <td style="width: 7%" class="sub-text1 text-end">
                          {{ totalOwnerPrice | currency }}
                        </td>
                        <td style="width: 7%" class="sub-text1 text-end"></td>
                        <td style="width: 7%" class="sub-text1 text-end">
                          {{ estimatedProfit | currency }}
                        </td>
                        <td nzRight style="width: 15%" class="sub-text1"></td>
                      </tr>
                    </tfoot>
                  </ng-template>
                </div>
              </div>
              <div class="row mt-3 mb-3">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12 text-end">
                      <h4 nz-typography>Owner Price: $0.00</h4>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12 text-end">
                      <label
                        class="k-text-underline text-primary"
                        nzPopoverTrigger="click"
                        nz-popover
                        [nzPopoverContent]="Daily21"
                        nzPopoverPlacement="left"
                        >See full price breakdown</label
                      >
                    </div>
                  </div>
                  <ng-template #Daily21>
                    <div class="">
                      <div
                        class="row p-2"
                        style="width: 210px; font-size: 13px; line-height: 25px"
                      >
                        <div class="col-12">
                          <div class="row hoverJob p-1">
                            <div class="col-9">
                              <div class="ListViewJobname text-center">
                                Total Builder Cost
                              </div>
                            </div>
                            <div class="col-3">
                              <div class="ListViewJobname">$0.00</div>
                            </div>
                          </div>
                          <div class="row hoverJob p-1">
                            <div class="col-9">
                              <div class="ListViewJobname text-center">
                                Total Markup
                              </div>
                            </div>
                            <div class="col-3">
                              <div class="ListViewJobname">$0.00</div>
                            </div>
                          </div>
                          <div class="row hoverJob">
                            <div class="col-9">
                              <div class="sub-text text-center">
                                Owner Price
                              </div>
                            </div>
                            <div class="col-3">
                              <div class="sub-text">$0.00</div>
                            </div>
                          </div>
                          <div class="row hoverJob p-1">
                            <div class="col-9">
                              <div class="ListViewJobname text-center">
                                Total Margin %
                              </div>
                            </div>
                            <div class="col-3">
                              <div class="ListViewJobname">0.00%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </nz-card>
            <ng-template #leadProposalTabsTemplate>
              <nz-space>
                <button
                  *nzSpaceItem
                  nz-button
                  nz-dropdown
                  [nzDropdownMenu]="ImportForm"
                  class=""
                  nzTrigger="click"
                  [disabled]="DisabledNextActivity"
                  [class.disabled]="DisabledNextActivity"
                >
                  Import
                  <span nz-icon nzType="down"></span>
                </button>
                <!-- <nz-dropdown-menu #ImportForm="nzDropdownMenu">
                  <ul nz-menu>
                    <li nz-menu-item>Excel</li>
                    <li nz-menu-item>Proposal Template</li>
                    <li nz-menu-item>Softplan</li>
                    <li nz-menu-item>PrebuiltML</li>
                    <li nz-menu-item>Chief Architect</li>
                    <li nz-menu-item>STACK</li>
                    <li nz-menu-item>Square Takeoff</li>
                    <li nz-menu-item>Clear Estimates</li>
                    <li nz-menu-item>Plan Swift</li>
                    <li nz-menu-item>CadSoft</li>
                    <li nz-menu-item>Cubit</li>
                    <li nz-menu-item>eTakeoff</li>
                    <li nz-menu-item>ProEst</li>
                    <li nz-menu-item>RenoWalk</li>
                    <li nz-menu-item>PrioSoft</li>
                  </ul>
                </nz-dropdown-menu> -->
                <!-- Dropdown menu -->
<nz-dropdown-menu #ImportForm ="nzDropdownMenu">
  <ul nz-menu class="scrollable-dropdown">
    <li nz-menu-item>
      <svg viewBox="64 64 896 896" focusable="false" data-icon="file-excel" width="1em" height="1em" fill="greenColor" aria-hidden="true"><path d="M854.6 288.7c6 6 9.4 14.1 9.4 22.6V928c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32h424.7c8.5 0 16.7 3.4 22.7 9.4l215.2 215.3zM790.2 326L602 137.8V326h188.2zM575.34 477.84l-61.22 102.3L452.3 477.8a12 12 0 00-10.27-5.79h-38.44a12 12 0 00-6.4 1.85 12 12 0 00-3.75 16.56l82.34 130.42-83.45 132.78a12 12 0 00-1.84 6.39 12 12 0 0012 12h34.46a12 12 0 0010.21-5.7l62.7-101.47 62.3 101.45a12 12 0 0010.23 5.72h37.48a12 12 0 006.48-1.9 12 12 0 003.62-16.58l-83.83-130.55 85.3-132.47a12 12 0 001.9-6.5 12 12 0 00-12-12h-35.7a12 12 0 00-10.29 5.84z"></path></svg>      <span>Excel</span>
    </li>
    <li nz-menu-item>
      <img src="assets/DropdownImages/Proposal Template.png" alt="">
      <span>Proposal Template</span>
    </li>
    <li nz-menu-item>
      <img alt="" src="assets/DropdownImages/softPlan.png">
      <span>Softplan</span>
    </li>
    <li nz-menu-item>
      <img alt="" src="assets/DropdownImages/prebuiltml.png">
      <span>PrebuiltML</span>
    </li>
    <li nz-menu-item>
      <img alt="" src="assets/DropdownImages/chiefArchitect.png">
      <span>Chief Architect</span>
    </li>
    <li nz-menu-item>
      <img alt="" src="assets/DropdownImages/stack.png">
      <span>STACK</span>
    </li>
    <li nz-menu-item>
      <img alt="" src="assets/DropdownImages/squareTakeoff.png">
      <span>Square Takeoff</span>
    </li>
    <li nz-menu-item>
      <img alt="" src="assets/DropdownImages/CEicon.png">
      <span>Clear Estimates</span>
    </li>
    <li nz-submenu>
    <span nzTitle>
      <img alt="PlanSwift" src="assets/DropdownImages/planswift.png" class="menu-icon"/>
      <span>PlanSwift</span>
      <i nz-icon nzType="right" nzTheme="outline"></i>
      <ul nz-menu>
        <li nz-menu-item (click)="importFromPlanSwift()">Import from PlanSwift</li>
        <li nz-menu-item (click)="downloadPlugin()">Download integration plugin</li>
      </ul>
    </span>
    </li>
    <li nz-menu-item>
      <img alt="" src="assets/DropdownImages/cadsoft.png">
      <span>CadSoft</span>
    </li>
    <li nz-menu-item>
      <img alt="" src="assets/DropdownImages/Cubit.png">
      <span>Cubit</span>
    </li>
    <li nz-menu-item>
      <img alt="" src="assets/DropdownImages/eTakeoff.png">
      <span>eTakeoff</span>
    </li>
    <li nz-menu-item>
      <img alt="" src="assets/DropdownImages/ProEst.png">
      <span>ProEst</span>
    </li>
    <li nz-menu-item>
      <img alt="" src="assets/DropdownImages/RenoWalk.png">
      <span>RenoWalk</span>
    </li>
    <li nz-menu-item>
      <img alt="" src="assets/DropdownImages/PrioSoft.png">
      <span>PrioSoft</span>
    </li>
  </ul>
</nz-dropdown-menu>
                <button
                  *nzSpaceItem
                  nz-button
                  nz-dropdown
                  [nzDropdownMenu]="AddForm"
                  class=""
                  nzTrigger="click"
                  [disabled]="DisabledNextActivity"
                  [class.disabled]="DisabledNextActivity"
                >
                  Add Form
                  <span nz-icon nzType="down"></span>
                </button>
                <nz-dropdown-menu #AddForm="nzDropdownMenu">
                  <ul nz-menu>
                    <li nz-menu-item (click)="showCatalogModel('NEW_PROPOSAL')">
                      Catalog
                    </li>
                    <li nz-menu-item (click)="showProposalCostCode()">
                      Cost Codes
                    </li>
                  </ul>
                </nz-dropdown-menu>
                <button
                  *nzSpaceItem
                  nz-button
                  nzType="primary"
                  [disabled]="DisabledNextActivity"
                  [class.disabled]="DisabledNextActivity"
                  (click)="showEstimatedCost()"
                  >
                  Add New Cost
                </button>
              </nz-space>
            </ng-template>
          </ng-container>

          <ng-container *ngIf="tab === 2">
            <nz-card nzBorderless="true" nzTitle="Format Proposal">
              <div class="row mb-2">
                <div class="col-9">
                  <div class="form-group">
                    <div
                      class="row d-flex justify-content-center align-items-center"
                    >
                      <label class="sub-text align-items-center">
                        What to Display to Lead
                        <span
                          class="text-secondary p-0 m-0 bi bi-info-circle-fill"
                          nz-popover
                          nzPopoverTitle=""
                          [nzPopoverContent]="contentTemplate"
                        ></span>
                      </label>
                    </div>

                    <ng-template #contentTemplate>
                      <div>
                        <p style="width: 230px">
                          Including more than 6 display columns on this proposal
                          may cause text to overflow and Item Description to
                          display within the Items column. Please preview the
                          proposal before sending.
                        </p>
                      </div>
                    </ng-template>

                    <nz-tree-select
                      [nzMultiple]="true"
                      [nzCheckStrictly]="true"
                      nzShowSearch="true"
                      [nzNodes]=""
                      nzShowSearch
                      nzMultiple
                      nzCheckStrictly
                      nzStatus
                      nzAllowClear="false"
                      nzDropdownMatchSelectWidth="true"
                      nzCheckable
                      nzCheckAll="true"
                      nzPlaceHolder="Select Division"
                      nzHideUnMatched="true"
                    ></nz-tree-select>
                  </div>
                </div>
              </div>

              <div class="row p-2">
                <div class="col text-end">
                  <h4>
                    <strong> Total Price: $381,360.00 </strong>
                  </h4>
                </div>
              </div>
            </nz-card>

            <app-format-no-data></app-format-no-data>
          </ng-container>

          <ng-container *ngIf="tab === 3">
            <app-lead-proposal-preview></app-lead-proposal-preview>
          </ng-container>
        </nz-tab>
      </nz-tabset>
    </div>
    <!-- tabs -->
     <!-- Second Phase Payment Card Start -->

    <div class="card border-0 mb-3 mt-3">
      <div class="card-head border-bottom">
        <div class="row py-2">
          <div class="col-12">
            <h3 class="ms-3" nz-typography>Online Payment Details</h3>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div>
              <span>Payment Status: </span> <span>No Payment Requested</span>
            </div>
          </div>
        </div>
      </div>
    </div>

<!-- Second Phase Payment Card End -->
  </div>
  <ng-template #LeadproposalFooter>
    <div class="container-fluid p-0 PSCustomFooter mt-2">
      <div class="row">
          <div class="col-4 text-start  justify-content-start align-items-start">
            <span *ngIf="footerResponse">
              <app-footer-label [footerData]="footerResponse"></app-footer-label>
            </span>
        </div>
        <div class="col-md-8 text-end">
          <nz-space>
            <ng-container *nzSpaceItem>
              <button
                class=""
                nz-button
                nzType="default"
                *ngIf="
                  approveAllowButtons() &&
                  declineAllowButtons() &&
                  getProposalTemplatesData
                "
                (click)="deleteLeadConfirm()"
                [disabled]="DisabledNextActivity"
                 [class.disabled]="DisabledNextActivity"
              >
                Delete
              </button>
            </ng-container>
            <ng-container *nzSpaceItem>
              <button
                class=""
                nz-button
                nzType="default"
                *ngIf="getProposalTemplatesData"
                [disabled]="DisabledNextActivity"
          [class.disabled]="DisabledNextActivity"
              >
                Print
              </button>
            </ng-container>
            <ng-container *nzSpaceItem>
              <button
                class=""
                nz-button
                nzType="default"
                *ngIf="
                  getStatusProposal?.code === ProposalStatusEnum.Approved ||
                  statusUpdateForDecline?.code === ProposalStatusEnum.Declined
                "
                [disabled]="DisabledNextActivity"
                [class.disabled]="DisabledNextActivity"
                (click)="showConfirmResetModal()"
              >
                Reset
              </button>
            </ng-container>
            <ng-container *nzSpaceItem>
              <button
                *ngIf="
                  approveAllowButtons() &&
                  declineAllowButtons() &&
                  getProposalTemplatesData &&
                  getStatusProposal?.code === ProposalStatusEnum.Released
                "
                class=""
                (click)="UnReleaseConfirmCard()"
                nz-button
                nzType="default"
                [disabled]="DisabledNextActivity"
                [class.disabled]="DisabledNextActivity"
              >
                Unrelease
              </button>
            </ng-container>

            <ng-container *nzSpaceItem>
              <button
                class=""
                *ngIf="
                  approveAllowButtons() &&
                  declineAllowButtons() &&
                  getStatusProposal?.code === ProposalStatusEnum.Released
                "
                nz-button
                nzType="default"
                [disabled]="DisabledNextActivity"
          [class.disabled]="DisabledNextActivity"
              >
                Resend to Owner
              </button>
            </ng-container>
            <ng-container *nzSpaceItem>
              <button
                class=""
                *ngIf="
                  approveAllowButtons() &&
                  getProposalTemplatesData &&
                  declineAllowButtons()
                "
                nz-button
                nzType="default"
                (click)="declineProposal()"
                [nzLoading]="isDecline"
                [disabled]="DisabledNextActivity"
          [class.disabled]="DisabledNextActivity"
              >
                Decline
              </button>
            </ng-container>
            <ng-container *nzSpaceItem>
              <button
                class=""
                *ngIf="
                  approveAllowButtons() &&
                  getProposalTemplatesData &&
                  declineAllowButtons()
                "
                nz-button
                nzType="primary"
                (click)="saveAndApproveProposal()"
                [nzLoading]="isSaveAndApproval"
                [disabled]="DisabledNextActivity"
                [class.disabled]="DisabledNextActivity"
              >
                Save & Approve
              </button>
            </ng-container>
            <ng-container *nzSpaceItem>
              <button
                *ngIf="
                  approveAllowButtons() &&
                  getStatusProposal?.code === ProposalStatusEnum.Unreleased &&
                  declineAllowButtons()
                "
                class=""
                nz-button
                nzType="primary"
                (click)="ReleaseConfirmCard()"
                [nzLoading]="isSaveAndRelease"
                [disabled]="DisabledNextActivity"
               [class.disabled]="DisabledNextActivity"
              >
                Save & Release
              </button>
            </ng-container>
            <button
              class=""
              (click)="saveLeadProposal(null)"
              *nzSpaceItem
              nz-button
              nzType="primary"
              [nzLoading]="isSave"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
              Save
            </button>
            <button
              class=""
              (click)="saveAndCloseProposal()"
              *nzSpaceItem
              nz-button
              nzType="primary"
              [nzLoading]="isSaveAndClose"
              [disabled]="DisabledNextActivity"
             [class.disabled]="DisabledNextActivity"
            >
              Save & Close
            </button>
          </nz-space>
        </div>
        <!-- <div class="col-md-4 text-start">
            <span>Updated by <nz-avatar nzSize="small" nzText="MM" style="color:#f56a00; background-color:#fde3cf;"></nz-avatar>  <span class="ms-1"><strong>Muhammad Ahmed</strong></span>  on Oct 31, 2023, 7:27 AM</span>
        </div> -->
      </div>
    </div>
  </ng-template>
</nz-modal>





<nz-modal [(nzVisible)]="isResetVisible"
[nzFooter]="resetLeadproposalFooter"
nzTitle="Reset Lead Proposal Status"
[nzBodyStyle]="{
  background: '#f1f4fa',
  padding: '14px',
  'max-height': '71vh',
  'overflow-y': 'auto'
}"
[nzStyle]="{ top: '35px' }"
(nzOnCancel)="handleCancel()">
  <ng-container *nzModalContent>
    <nz-card nzTitle="Reset Proposal">
      <div nz-row>
        <div nz-col nzSpan="24"  class="mb-3">
          <p>This will reset the proposal status to Released.</p>
        </div>
      </div>
      <div nz-row  class="mb-3">
        <div nz-col nzSpan="24">
          <label nz-checkbox [(ngModel)]="resetFormFields.saveApprovedProposalAsAPdf">
            Save approved proposal as a PDF
          </label>
        </div>
      </div>

      <!-- Conditionally show this row only if the checkbox is true -->
      <div nz-row class="mb-3" *ngIf="resetFormFields.saveApprovedProposalAsAPdf">
        <div nz-col nzSpan="24">
          <label class="sub-text">Title</label>
          <input
            nz-input
            name="title"
            [(ngModel)]="resetFormFields.pdfTitle"
            required
            [placeholder]="'Approved Proposal' + getFormattedDateTime()"
          />
        </div>
      </div>

      <div nz-row>
        <div nz-col nzSpan="24">
          <label nz-checkbox [(ngModel)]="resetFormFields.resetAllProposalsForThisLead">
            Reset all proposals for this lead
          </label>
        </div>
      </div>
    </nz-card>
  </ng-container>

  <ng-template #resetLeadproposalFooter>
    <nz-space>
      <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>
      <button nz-button nzType="primary" (click)="resetProposal()">Reset</button>
    </nz-space>
  </ng-template>
</nz-modal>
