import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RoleTypeService } from 'src/Service/Internaluser/role-type.service';
import { FieldDataType } from 'src/app/Core/Enums/data_types';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { AddNewStandardFilterComponent } from '../../Modal/add-new-standard-filter/add-new-standard-filter.component';
import { CreateLookupFilterRequest, LookupFilterResponse, LookUpStandardResponse, LookupFilter, FilterSearchParameter } from '../../Models/LookupModels';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { SiteDiariesService } from 'src/Service/Project-Management/site-diaries.service';
import { MessagingSurveyIndividualCode } from '../../Models/LookUpStandardFilterCodes';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';

@Component({
  selector: 'app-messaging-surveys-individual-filter-field',
  templateUrl: './messaging-surveys-individual-filter-field.component.html',
  styleUrls: ['./messaging-surveys-individual-filter-field.component.css']
})
export class MessagingSurveysIndividualFilterFieldComponent implements OnInit {
  selectedValue: any;
  listOfOption: Array<{ label: string; value: string }> = [];
  listOfTagOptions = [];
  @Input() pageId: number;
  messagingsurveysindividualFilterForm: FormGroup;
  createLookupFilterRequests: CreateLookupFilterRequest[] = [];
  filterResponseData: LookupFilterResponse[] = [];
  formFieldValues: any = {};
  surveyTitleNodes: NzTreeNodeOptions[] = [];
  lookUpStandardResponse: LookUpStandardResponse[] = [];
  defaultStandardFilter: LookupFilterResponse;
  predefinedValuesMultiSelect: string[] = [];
  preDefinedDataCodes = {
    standardFilter: MessagingSurveyIndividualCode.standardFilter,
    releasedDate: MessagingSurveyIndividualCode.releasedDate,
    surveysTitle: MessagingSurveyIndividualCode.surveyTitle,

  };
  selectedFilter: number = -1;
  selectedJobId: number = 0;
  filterSearchParameter: FilterSearchParameter;
  relesedDate: { label: string, value: string }[] = [];

  constructor(
    private roleService: RoleTypeService,
    private SiteDiariesService: SiteDiariesService,
    private fb: FormBuilder,
    public toastService: NzMessageService,
    private lookUpFilterService: LookupFilterService,
    private cdr: ChangeDetectorRef,
    private loadingIndicatoreService: LoadingIndicatorService,
    private modal: NzModalService,
    private LookupFilterService: LookupFilterService,
    private internalUserService: InternalUserService,
    private accessLocalStorageService: AccessLocalStorageService,


  ) {

  }
  ngOnInit(): void {
    this.initLoad();
    const children: Array<{ label: string; value: string }> = [];
    for (let i = 10; i < 36; i++) {
      children.push({ label: i.toString(36) + i, value: i.toString(36) + i });
    }
    this.listOfOption = children;
  }
  // initLoad() {
  //   const lookupFilter: LookupFilter = {
  //     formNameId: this.pageId,
  //   };
  //   this.getStandardFilterByFormPageId(this.pageId);
  //   this.fetchData(lookupFilter);
  // }

  initLoad() {
    this.lookUpFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(res => {
      let activityStatusMul = res.result.filter(x => x.code === this.preDefinedDataCodes.surveysTitle.code); //multi select
      let activityDateSel = res.result.filter(x => x.code === this.preDefinedDataCodes.releasedDate.code); // single Select

      this.surveyTitleNodes = [
        {
          title: 'Select All',
          value: 'select_all',
          key: 'select_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: activityStatusMul.map((status) => ({
            title: status.name,
            value: status.id.toString(),
            key: status.id.toString(),
            isLeaf: true
          }))
        }
      ];
      this.relesedDate = activityDateSel.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));

    });
    const lookupFilter: LookupFilter = {
      formNameId: this.pageId,
    };
    this.getStandardFilterByFormPageId(this.pageId);
    this.fetchData(lookupFilter);
  }

  fetchData(lookUpStandard: LookupFilter): void {
    this.LookupFilterService.postData(lookUpStandard).subscribe(
      data => {
        this.lookUpStandardResponse = data.result;
      },
      error => {
        console.error('Error fetching data:', error);
      }
    );
  }
  openStandardFilterComponent() {
    this.createFormFieldsInArray();
    const modalRef = this.modal.create({
      nzContent: AddNewStandardFilterComponent,
      nzData:
      {
        pageId: this.pageId,
        std: this.filterResponseData,
        field: this.createLookupFilterRequests
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  getStandardFilterByFormPageId(pageId: number) {
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.filterResponseData = result.result;
        console.log("RESULT STANDARD FILTER : ", this.filterResponseData);
        this.initializeForm();
        this.setDefaultFieldValues(this.defaultStandardFilter.id);
      }
    });
  }
  onSubmit(): void {
    this.createFormFieldsInArray();
  }
  resetFilter(): void {
    const formControlKeys = Object.keys(this.messagingsurveysindividualFilterForm.controls).slice(1);
    formControlKeys.forEach(field => {
      this.messagingsurveysindividualFilterForm.get(field).reset();
    });
  }
  createFormFieldsInArray() {
    this.createLookupFilterRequests = [];
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const value = this.messagingsurveysindividualFilterForm.get(field).value;
      if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 1 && value[0] === null)) {
        const newRow = {

          companyParameterId: 0,
          lookUpStandardId: 0,
          code: code,
          meaning: '',
          valueId: 0,
          formNameId: this.pageId,
          dataTypeId: 0,
          createLookupFilterParameterRequests: [null]
        };
        const correspondingResponse = this.lookUpStandardResponse.find(response => response.code === code);
        if (correspondingResponse) {
          newRow.companyParameterId = correspondingResponse.companyParameterId;
          newRow.lookUpStandardId = correspondingResponse.id;
          newRow.dataTypeId = correspondingResponse.dataTypeId;
          if (
            correspondingResponse.dataType.code === FieldDataType.SingleLineText ||
            correspondingResponse.dataType.name === 'Single-Line Text') {
            newRow.meaning = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.Dropdown ||
            correspondingResponse.dataType.name === 'Dropdown') {
            newRow.valueId = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.MultiSelectDropdown ||
            correspondingResponse.dataType.name === 'Multi-Select Dropdown'
          ) {
            newRow.createLookupFilterParameterRequests = value.map((item, index) => {
              return { valueId: item };
            });
          }
          // Assign other properties similarly based on your data structure
        }
        this.createLookupFilterRequests.push(newRow);
      }
    });
  }
  getDefaultFilterValue() {
    this.defaultStandardFilter = this.filterResponseData.find(filter => filter.isSetAsDefault === true);
    return this.defaultStandardFilter ? this.defaultStandardFilter.id : null;
  }
  setDefaultFieldValues(standardFilterId: number): void {
    this.predefinedValuesMultiSelect = [];
    let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
    let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;
    // Get all form control keys except the first one
    const formControlKeys = Object.keys(this.messagingsurveysindividualFilterForm.controls).slice(1);
    // Reset all form controls except the first one
    formControlKeys.forEach(field => {
      this.messagingsurveysindividualFilterForm.get(field).reset(); // Reset the form control
    });
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const fieldData = lookUpFiltersField.find(filter => filter.code === code);
      if (fieldData != undefined) {
        if (
          fieldData.dataType.code === FieldDataType.SingleLineText) {
          this.messagingsurveysindividualFilterForm.get(field).setValue(fieldData.meaning);
        }
        if (fieldData.dataType.code === FieldDataType.Dropdown) {
          this.messagingsurveysindividualFilterForm.get(field).setValue(fieldData.id);
        }
        if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown) {
          let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
          this.predefinedValuesMultiSelect = multiSelectValues.map(String);
          this.messagingsurveysindividualFilterForm.get(field).setValue(this.predefinedValuesMultiSelect);
        }
      }
    });
  }
  // onChangeStandardFilter($event: number): void {
  //   this.setDefaultFieldValues($event);
  // }
  onChangeStandardFilter($event: number): void {
    this.selectedFilter = -1;
    const defaultFIlterId = this.getDefaultFilterValue();
    this.accessLocalStorageService.setSelectedFilterId($event);
    // New Changes By Aamir Ali - 22-Apr2024
    let id = this.accessLocalStorageService.getSelectedFilterId();
    this.setDefaultFieldValues(id);
    this.SiteDiariesService.setSelectedFilterId(id);
    this.filterSearchParameter = {
      CompanyParameterId: 1,
      FormNameId: this.pageId,
      LookUpFilterId: $event,
      page: 1,
      pageSize: 10,
      jobInformationId: this.selectedJobId
    };

    if (defaultFIlterId !== $event) {
      this.SiteDiariesService.userAppliedFilter(true);
    } else {
      this.SiteDiariesService.userAppliedFilter(false);
    }
    this.SiteDiariesService.getAllSiteDiariesByFilter(this.filterSearchParameter);

  }
  onFilterApply(): void {
    let selectedFilter = this.formFieldValues['standardFilter'];
    let isSelectedFilterStandard = this.filterResponseData.find(x => x.id === selectedFilter && x.isNotDeleted === true);
    if (isSelectedFilterStandard && isSelectedFilterStandard != undefined) {
      this.selectedFilter = 1;
    }
    else {
      this.selectedFilter = 0;
    }
    this.createFormFieldsInArray();
    this.createLookupFilterRequests[0].page = 1;
    this.createLookupFilterRequests[0].pageSize = 10;

    this.internalUserService.getAppliedFilterData(this.createLookupFilterRequests);
  }
  onserveyTitleChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.surveyTitleNodes[0].children.map(child => child['value']);
      this.formFieldValues['serveyTitle'] = [...allValues];
    } else {
      const allValues = this.surveyTitleNodes[0].children.map(child => child['value']);
      this.formFieldValues['serveyTitle'] = selectedValues.filter(value => allValues.includes(value));
    }
  }
  initializeForm(): void {
    this.formFieldValues = {
      standardFilter: this.getDefaultFilterValue(),
      releaseDate: '',
      serveyTitle: ''
    };
  }
}




















