<div class="row famly-inn gx-3">
    <div class="col-3">
        <div class="card border-0" style="height: 895px;">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                  <div class="col">
                    <span class="fw-medium fs-6 ms-2">Brand Settings</span>
                  </div>
        
                </div>
              </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                    <span class="fw-medium fs-6 ms-0">Upload Your Logo & Color</span>
                    <div class="row mt-3 border-bottom pb-5 padding-bottom-sm">
                        <span class="LitelText">
                            Brand your Buildertrend account with your company's logo and a complementary color scheme.
                        </span>
                    </div>
                    <div class="row  famly-inn">
                      <div class="col-12 sub-text">
                        Account Color <span class="fw-bolder text-danger ms-1">*</span>
                      </div>
                    </div>
                    <div class="row gx-0">
                        <div class="col-2">
                            <div class="color-box"></div>
                        </div>
                        <div class="col-7">
                          <button data-testid="saveDraft" aria-disabled="false" type="button" class=" ant-btn2 ant-btn-default">
                            <span class="pira-label ms-1" >Select Color</span>
                            <i class="bx bx-caret-down ms-1"></i>
                        </button>
                        </div>
                        <div class="col-2"></div>
                    </div>
                    <div class="row  famly-inn mt-3">
                      <div class="col-12 sub-text">Website Logo</div>
                    </div>
                    <div class="row gx-0">
                        <div class="col-9">
                          <button data-testid="saveDraft" aria-disabled="false" type="button" class=" ant-btn2 ant-btn-default">
                            <svg style="color: #202227;" viewBox="64 64 896 896" focusable="false" data-icon="upload" width="1.55em" height="1.55em" fill="currentColor" aria-hidden="true"><path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg>
                            <span class="pira-label ms-2" >Choose File</span>
                        </button>
                        </div>
                    </div>
                    <div class="row  famly-inn mt-3">
                      <div class="col-12 sub-text">Mobile Logo</div>
                    </div>
                    <div class="row gx-0">
                        <div class="col-9">
                          <button data-testid="saveDraft" aria-disabled="false" type="button" class=" ant-btn2 ant-btn-default">
                            <svg style="color: #202227;" viewBox="64 64 896 896" focusable="false" data-icon="upload" width="1.55em" height="1.55em" fill="currentColor" aria-hidden="true"><path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg>
                            <span class="pira-label ms-2" >Choose File</span>
                        </button>
                        </div>
                    </div>
                    <div class="row  famly-inn mt-3">
                      <div class="col-12 sub-text">Mini Logo</div>
                    </div>
                    <div class="row gx-0">
                        <div class="col-9">
                          <button data-testid="saveDraft" aria-disabled="false" type="button" class=" ant-btn2 ant-btn-default">
                            <svg style="color: #202227;" viewBox="64 64 896 896" focusable="false" data-icon="upload" width="1.55em" height="1.55em" fill="currentColor" aria-hidden="true"><path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg>
                            <span class="pira-label ms-2" >Choose File</span>
                        </button>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
        <span class="mt-5"></span>
    </div>
    <div class="col-6">
        <div class="card border-0" style="height: 523px; flex: 1 1 auto;">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                  <div class="col">
                    <span class="fw-medium fs-6 ms-2">Website</span>
                  </div>
        
                </div>
              </div>
            <div class="card-body">
              <div class="ant-row BTRow-xs" style="margin-left: -8px; margin-right: -8px; background: rgb(86, 86, 87); padding: 0px 16px 4px; row-gap: 0px;">
                <!-- <ngx-skeleton-loader
                count="1"
                [theme]="{
                  'width.px': 167,
                  'height.px': 18,
                  'margin-top':'17px',
                  'background-color': '#bebebe33',
                }"
              ></ngx-skeleton-loader> -->
            
              <!-- <ngx-skeleton-loader
                count="1"
                [theme]="{
                  'width.px': 167,
                  'height.px': 18,
                  'margin-top':'17px',
                  'margin-right':'10px',
                  'margin-left':'10px',
                  'background-color':  '#bebebe33',
                }"
              ></ngx-skeleton-loader> -->
            
              <!-- <ngx-skeleton-loader
                count="1"
                [theme]="{
                  'width.px': 167,
                  'height.px': 18,
                  'margin-top':'17px',
                  'background-color':  '#bebebe33',
                }"
              ></ngx-skeleton-loader> -->
              </div>
              <div class="row">
                <div class="col-4">
                    <div
                        class="ant-col ant-col-xs-8"
                        style="padding-left: 8px; padding-right: 8px;">
                        <div
                            style="background-image: url(&quot;https://buildertrend.net/api/files/brandingLogo?id=6f803e39-4059-4938-9c3b-a3cc70699a52&amp;imageType=0&amp;rowVersion=22251233594&quot;); margin: 16px auto auto; width: 120px; height: 120px; background-size: contain; background-repeat: no-repeat; background-position: center center;"></div>
                    </div>
                </div>
                <div class="col-8">
                    <div
                        class="mt-2"
                        style="padding: 10px;">
                        <!-- <ngx-skeleton-loader
                            <!-- count="3"
                            [theme]="{ 
                        'width.px': 330,
                            'height.px': 18,
                            'background-color': '#f3f6f4'
                          }"
                            style="margin: 10px 0;"></ngx-skeleton-loader> --> -->
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="card border-0 mt-3"  style="width: 874px; height: 355px;">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                  <div class="col">
                    <span class="fw-medium fs-6 ms-2">Documents</span>
                  </div>
        
                </div>
              </div>
              <div class="card-body mt-3">
                <div class="row">
                  <div class="col">
                    <div style="background-image: url(&quot;https://buildertrend.net/api/files/brandingLogo?id=6f803e39-4059-4938-9c3b-a3cc70699a52&amp;imageType=0&amp;rowVersion=22251233594&quot;); width: 120px; height: 120px; background-size: contain; background-repeat: no-repeat; background-position: center center;"></div>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-12">
                    <div class="item">
                      <ngx-skeleton-loader count="1" [theme]="{'width.px': 372, height: '25px',  background: '#f3f6f4'}"></ngx-skeleton-loader>
                    </div>
                    <div class="item">
                      <ngx-skeleton-loader count="1" [theme]="{'width.px': 850, height: '25px',  background: '#f3f6f4'}"></ngx-skeleton-loader>
                    </div>
                    <div class="item">
                      <ngx-skeleton-loader count="1" [theme]="{'width.px': 850, height: '25px',  background: '#f3f6f4'}"></ngx-skeleton-loader>
                    </div>
                    <div class="item">
                      <ngx-skeleton-loader count="1" [theme]="{'width.px': 572, height: '25px',  background: '#f3f6f4'}"></ngx-skeleton-loader>
                    </div>
                  </div>
                </div> -->
            </div>
        </div>
 
    </div>
    <div class="col-3">
        <div class="card border-0"  style="height: 523px; flex: 1 1 auto;">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                  <div class="col">
                    <span class="fw-medium fs-6 ms-2">Mobile App</span>
                  </div>
        
                </div>
              </div>
            <div class="card-body">
              <div
              style="background-color: #f7f9fc; margin-left: -8px; margin-right: -8px; row-gap: 16px;" class="item h-100 w-100 mt-2 p-2">
                              <!-- <ngx-skeleton-loader
                                  count="7"
                                  [theme]="{'width.px': 65, height: '68px', 'margin-left': '5px', 'margin-right': '5px', background: '#c7d0d9'}"
                                  ></ngx-skeleton-loader> -->
              
                          </div>
            </div>
        </div>
    </div>
</div>
   