import { Data } from './../../../files/videos/videos-dashboard/videos-dashboard.component';

import { leadOpportunities } from './../../../../Models/CustomerInformation';
import { AddLeadActivityTypeResponse, CreateAddLeadActivityTypeRequest, LeadActivityResponse } from './../../../../Models/LeadActivity/leadActivityClass';
import { ModalService } from 'src/Service/CompanyInFormation/modal.service';
import { ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { CdkDragDrop, moveItemInArray,transferArrayItem } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { NzSelectSizeType } from 'ng-zorro-antd/select';
import { CompanySettingssalesPreDefineCodes } from '../Models/LookUpStandardFilterCodes';
import { LookupNameSetupResponse } from '../Models/LookupModels';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AddLeadActivityTypeComponent } from 'src/app/lead-opportunities/Components/Modal/add-lead-activity-type/add-lead-activity-type.component';
import {  SaleSetupResponse, SalesSettings } from 'src/Models/LeasSalesPeople';
import { LeadOpportunity } from 'src/Models/CustomerInfromation/CustomerInformation';
import { SalesLeadOpportunityCustomFieldComponent } from 'src/app/company-settings/sales-lead-opportunity-custom-field/sales-lead-opportunity-custom-field.component';
import { FormGroup } from '@angular/forms';
import { LeadActivitiesService } from 'src/Service/Sales-Lead-Activities/lead-activities.service';
import { LeadActivityTypeService } from 'src/Service/Lead-Activity-Type/lead-activity-type.service';
import { ClassicEditor, EditorConfig, Bold, Essentials, Heading, Indent, Italic, Link, List, MediaEmbed, Paragraph, Table, Undo, Font, FontSize, FontFamily, FontBackgroundColor, FontColor, BlockQuote, HorizontalLine, UndoEditing, ImageUpload,Image } from 'ckeditor5';
import { SaleSetup } from '../../../../Models/LeasSalesPeople';
interface City {
  name: string,
  code: string
}
@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Output() onSaveComplete  = new EventEmitter();
  @Output() formDataEvent = new EventEmitter<any>();
  @Output() onDeleteComplete = new EventEmitter<void>();

@Output() onUpdateComplete  = new EventEmitter();
  customFieldForm: FormGroup;
  @Output() cancel1 = new EventEmitter<void>();
  @Input() dataInvo : AddLeadActivityTypeResponse
  formfieldvalues : any= {}
  isLoadingOne = false;
  editorValue: string = '';
  closingText: string = '';

  // formfieldvalues: { [key: string]: any } = {};
  editorValueChange: string = "I confirm that my action here represents my electronic signature and is binding..";
  isLoadingTwo = false;
  listOfOption: Array<{ label: string; value: string }> = [];
  size: NzSelectSizeType = 'default';
  singleValue = 'a10';
  multipleValue = [''];
  tagValue = ['a10', 'c12', 'tag'];
  selectedValue = null;
  text: string | undefined;
  selectedOS = null;
  inputFields: boolean;
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  last: { label: string, value: string }[] = [];
  pageId: number = 30018;
  defaultCalendarView: LookupNameSetupResponse[] = [];
  defaultGroup: LookupNameSetupResponse[] = [];
  Header:LookupNameSetupResponse[] = [];
  Content:LookupNameSetupResponse[] = [];
  ShowContactName:LookupNameSetupResponse[] = [];
  LeadActivityType:CreateAddLeadActivityTypeRequest[]
  SaleResponse: SaleSetupResponse

  preDefinedDataCodes = {
    defaultLeadActivityCalendarView: CompanySettingssalesPreDefineCodes.DefaultLeadActivityCalendarView,
    defaultGroup: CompanySettingssalesPreDefineCodes.DefaultGrouping,
    Header: CompanySettingssalesPreDefineCodes.Header,
    Content: CompanySettingssalesPreDefineCodes.Content,
    ShowContactName : CompanySettingssalesPreDefineCodes.ShowContactName,
    ShowCoDefaultWhattoDisplayntactName : CompanySettingssalesPreDefineCodes.ShowCoDefaultWhattoDisplayntactName,

  };


  constructor(
    private leadActivitiesService: LeadActivityTypeService,
    private LookupFilterService: LookupFilterService,
    private leadser: LeadOpportunitiesService,
    private toastService: NzMessageService,
    private tostser: NzMessageService,
    private modal: NzModalService,


  ) {

    this.editorValue = `
    <p>Hi</p>
    <p>Thank you for providing Xircon homes an opportunity to quote your home.</p>
    <p><strong>Syed Ahsan Kazmi</strong><br/>Director Sales Operations</p>
    <img src="assets/initialtext/initialImage.png" alt="Xircon Homes" />
    <img src="assets/initialtext/initialSecond.png" alt="HIA Winner 2022" />
    <p>Xircon Homes accepts no liability for the content of this email, or for the consequences of any actions taken on the basis of the information provided, unless that information is subsequently confirmed in writing. This e-mail and/or attachments are confidential and may also be privileged. If you are not the named recipient, please notify the sender immediately and do not disclose the contents to other persons or use it for any purpose or store or copy the information in any medium. Any views expressed in this message are those of the individual sender, except where the sender specifically states them to be the views of Xircon Homes.</p>
  `;
    this.closingText = ` <p>Terms And Conditions:</p>
    <ol>
      <li>It is the owner's responsibility to notify Xircon Homes in writing of the exact location and depth in relation to the house of any in-ground pool, spa, pond or similar structure requiring excavation in proximity of the home which is proposed to be constructed after the home is completed.</li>
      <li>The owner is responsible to provide certified engineering details and computations for any such work in order that the builder can be satisfied that such work will not affect the location of pipes and services or the structural design of the footing system for the home. Once said details have been provided if any additional cost is to be incurred by the builder then the builder reserves the right to charge the owner accordingly by way of variation.</li>
      <li>It is the owner’s responsibility to provide a temporary void cover over any pool that is existing on site or that will be installed prior to or during construction. This temporary void cover must meet work safe requirements and be to the satisfaction of the builder.</li>
      <li>Boundary pegs must be present at the commencement of construction works. If the pegs are not readily identifiable, the Builder may charge the owner(s) the cost of a re-establishment survey by way of future variation. A home cannot be set out off boundary fences as they may be incorrectly installed.</li>
      <li>The owner(s) are responsible for any fencing notices relating to the property. If and when required, the owner(s) will notify the adjoining neighbour(s) of any fencing which may have to be temporarily removed during construction prior to the commencement of construction.</li>
      <li>Where power or water connection is not available to the site during construction, the owner(s) will ensure that a satisfactory alternative source is made available to the builder. If this is not provided, the builder reserves the right to make the necessary arrangements and to charge the owner(s) with any associated costs for materials, hire, labour and delays to the construction time by way of future variation.</li>
      <li>The owner(s) are advised that the location of the water tapping point is not the responsibility of the Builder. In some instances, the tapping may be located in the area of the proposed driveway. The owner(s) accept responsibility for arranging for the tapping to be relocated if required, at the discretion of the Builder, and will need to notify the applicable water authority or a licensed plumber. The Builder may agree to arrange for the tapping to be moved with the resulting costs to be charged to the owner(s) by way of future variation.</li>
      <li>The owner(s) are to provide a clean building site, clear of all obstructions, above and below ground, including but not limited to long grass and free of all old or unused services prior to the builder commencing any works on site. The Builder reserves the right to arrange and charge the owner(s) by way of future variation for the removal of any rubbish placed on the site which is not related to the construction of the home or which is illegally dumped during the construction of the home. The Builder agrees to notify the owner(s) of any planned removal before proceeding (where possible and practical); however, as such rubbish can pose a risk to safety on site or restrict reasonable access to the site, such removal may commence without approval of the owner(s).</li>
      <li>It is the owner(s) responsibility to ensure that the crossover is located as is indicated on the site plan. The owner(s) are responsible for all costs and applications relating to crossover relocations if required, including obtaining any necessary permits and permissions from relevant authorities.</li>
      <li>The owner(s) acknowledge that as per the recommendations of the Engineer, no fixtures or structures are to be attached after handover to any external cladding including but not limited to brickwork, hebel, timber linings, fibre cement linings, composite material linings. Any fixtures or structures attached to external surfaces require the approval of a Structural Engineer. The Builder does not offer any engineering services for any alterations or additions after handover.</li>
      <li>Any natural materials, for example but not limited to concrete, timber and stone products, require regular maintenance. The Builder provides no warranty or insurance on natural material products that have not been maintained by the owner(s) in accordance with the product and/or material guidelines.</li>
      <li>Long-term product availability is dependent upon many conditions, some of which are outside of the Builder's control. When a product is discontinued or unavailable, the owner(s) will be required to reselect, with any necessary price adjustment to suit the reselection to be forwarded by way of future variation. It is the commitment of the Builder that when the discontinuation of a product occurs, it is done with absolute minimum disruption and impact to the owner(s).</li>
      <li>It is recommended by the Builder that the owner(s) visit the supplier's displays to view selections as the Builder's showroom may not be a full indication of the finished products. Products that are manufactured from natural materials may experience variances.</li>
      <li>STCs (small scale technology certificates) are credits associated with the installation of solar hot water systems or solar PV systems. The owner(s) acknowledge that the value of these STC credits is reflected in the base price. The owner(s) assign exclusively to the Builder the rights to create STCs pursuant to the installation of these products in the home. The owner(s) agree not to assign, sell or transfer or otherwise interfere with the Builder's rights to create STCs.</li>
      <li>The owner(s) acknowledge that due to product constraints stone bench tops require a join line when the bench top length exceeds 3 metres and the Builder and its suppliers make all reasonable attempts to place this join line in the most appropriate location for each design, should the owner(s) wish to be notified of the location of a join line prior to installation a request shall be made in writing to the Builder.</li>
      <li>The owner(s) acknowledge that the Builder recommends that the fridge space be a minimum width of 100mm wider than the width of the owner(s) selected fridge and the owner(s) are solely responsible for ensuring the fridge space width is adequate prior to commencement of construction.</li>
      <li>The owner(s) acknowledge that where there is an access door internally between the garage and the house and where such access requires the installation of steps and/or a landing that they are aware of the reduced car parking space available inside the garage.</li>
      <li>The owner(s) acknowledge that the extent of excavation and retaining walls as shown on the site plan are approximate only and that where there is sufficient room or altered conditions prevail on site any soil remaining from excavation works will be spread on site and retaining walls may be relocated.</li>
      <li>The Owner(s) acknowledge that a 12-month Future Price Guarantee will start from the day the initial deposit is paid to secure the base price of the house, inclusions and any promotions. Site start occurs within 12 months from the date of the initial deposit; if it does not occur additional costs will be applied via contract variation. Extra costs will not be applied if delays are caused by Xircon Homes. For site start after 12 months agreed damages of $2000 for single story and $3000 for double story will apply. If the site does not commence within 15 months, Xircon Homes reserves the right to withdraw the pricing, inclusions, or any promotions.</li>
      <li>While government grants are available for some home buyers, the Builder makes no representation and gives no warranty that the Owner is eligible for or will receive funding from any third-party grant provider including but not limited to Commonwealth and State governments. The Owner acknowledges that the Builder is not responsible for the performance of these external parties and their purchase is independent of these organizations.</li>
      <li>The Builder guarantees that the standard home on any orientation will achieve a six-star energy efficiency rating or equivalent NCC Verification method V2.6.2.2. The Builder reserves the right to modify the design to achieve the required rating. Changes may include, but are not limited to, double glazing, window sizes, and insulation type. Any such changes will be noted in a Post Contract Variation.</li>
      <li>KNOCK DOWN REBUILD
        <ol>
          <li>The Builder does not offer a “design & construct” service but offers minor flexibility to alter its home designs to address Owner requirements, site-specific issues, and planning requirements. The Builder offers a limited range of specification options as displayed in its selection studio.</li>
          <li>The Owner is responsible to provide all service connection points within the property boundaries prior to the Builder applying for a building permit.</li>
          <li>Prior to preparation of the New Home Contract, the Builder will arrange for a plumbing & drainage contractor to inspect the Building Site and determine requirements for sewer & storm-water connection. Such provisions will be incorporated into a Post Contract Variation.</li>
          <li>Demolition, disconnection & abolishment of existing services and associated permits, if applicable, are the responsibility of the Owner; however, the Builder will assist by providing details of trusted demolition contractors.</li>
          <li>The Builder will arrange for its Building Surveyor and/or town planner to assess the proposed Building Works for compliance with relevant regulations.</li>
          <li>The Owner should make arrangements for demolition when notified by the Builder, after the receipt of town planning or dispensation approvals where necessary.</li>
          <li>Following completion of demolition works by the Owner and completion of final soil report & feature survey, the Builder will apply for a Building Permit.</li>
          <li>The sequence of events prior to commencement of construction is as follows:
            <ol>
              <li>Owner signs Preliminary Works Contract (PWC) and pays PWC Fee</li>
              <li>Preliminary survey / plumbing inspection / soil test</li>
              <li>Owner signs New Home Contract, pays balance of 5% deposit and provides evidence of funds</li>
              <li>Owner completes colour / specification / tile / electrical selections and signs addenda</li>
              <li>Owner makes arrangements for new services</li>
              <li>Builder obtains planning approval / local authority consent if applicable</li>
              <li>Owner completes abolishment of services and demolition if applicable</li>
              <li>Builder completes soil report / final survey / site costs / engineering / variations</li>
              <li>Building Surveyor issues Building Permit</li>
              <li>Builder prepares orders for labour & materials</li>
            </ol>
          </li>
        </ol>
      </li>
      <li>QUOTATION ACCEPTANCE
        <ol>
          <li>I/We, the undersigned, understand the following upon signing this quotation:
            <ol>
              <li>I/We understand that on our behalf, Xircon Homes will order an initial soil test, feature survey, and property information.</li>
              <li>I/We understand initial deposit monies paid are non-refundable.</li>
              <li>I/We agree to provide the following to Xircon Homes as soon as available: section 32, land contract, developer guidelines (if applicable), and plan of subdivision.</li>
              <li>I/We understand the finalised design sketches of my/our new home design are to be submitted along with our quotation. Any further changes to my/our new home design are to be completed during the tender appointment.</li>
              <li>I/We will provide Xircon Homes with current finance pre-approval.</li>
              <li>I/We agree to attend an introductory appointment to Xircon’s X Studio Colour Showroom conducted on a Saturday at Xircon’s head office in Laverton North.</li>
              <li>I/We acknowledge that the results of the preliminary assessment on my/our new home design conducted by the Design Review Panel have been considered and discussed. If my/our home design has not been made compliant, this will be discussed further at the Tender appointment.</li>
              <li>I/We agree to attend a full day colour and electrical selection appointment at Xircon’s X Studio Colour Showroom and a full day tender appointment, both conducted on a weekday at Xircon’s head office in Laverton North.</li>
              <li>I/We understand that any provisional sums and/or allowances listed within this quotation are not fixed prices and they may vary.</li>
              <li>I/We understand that the energy rating assessment is completed upon finalisation of contract drawings. Any additional costs to maintain a 6-star energy rating that result from variations will be charged in future.</li>
              <li>I/We understand that an application of the home design will be submitted for developer approval (if required) upon finalisation of contract drawings. If additional requirements are necessary to comply, I/We understand these will be presented and the additional costs associated will be charged in the future.</li>
              <li>I/We understand that town planning submissions (if required) is my/our responsibility. Any alterations and costs associated with alterations required for approval will be charged in future. If town planning is required, I/We understand this will be discussed at the tender appointment.</li>
              <li>I/We understand this quotation has been prepared without information from local council and other relevant authorities. I/We understand that once this information is made available, Xircon Homes will assess all of the requirements and any additional costs associated with complying with Council or local authority requirements will be charged in future.</li>
              <li>I/We acknowledge that the information within this quotation and all relevant information via electronic or material format provided by Xircon Homes is and remains confidential and is the exclusive property of Xircon Homes. I/We will undertake and use all reasonable endeavours to keep the information secure against unauthorized loss, use, or disclosure.</li>
              <li>If applicable, an application of the home design will be submitted for developer approval. Should there be any additional requirements necessary to comply with the developer approval, the owner(s) will be advised and charged for these additional requirements by way of future variation.</li>
              <li>The owner(s) acknowledge that should there be a Bushfire Attack Level relevant and applicable, any and all subsequent costs for reports, recommendations, and any additional works will be charged to the owner(s) by way of future variation.</li>
            </ol>
          </li>
        </ol>
      </li>
      <li>Electronic Funds Transfer
        <p>Funds can be deposited directly to:</p>
        <ul>
          <li>Account Name: Zircon Homes</li>
          <li>Bank: ANZ BANK</li>
          <li>BSB No: 013145</li>
          <li>Account No: 416434996</li>
          <li>Write your name as a reference</li>
        </ul>
      </li>
    </ol>
    `;
  }

  ngOnInit(): void {
    this.GetAddNewActivityTypes();
    this.fetchSalesData();
    this.initLoad()
   this.formfieldvalues = {
    id: 0,
    globalId: '00000000-0000-0000-0000-000000000000',
      companyParameterId: 1,
      activityCalendarSettingId: '',
      proposalWorksheetSettingId: '',
      headerSetupId: '',
      contentSetupId: '',
      showContactNamePhoneSetupId:'',
      showAddressSetupId: '',
      showCompanyInformationId:'',
      DefaultWhattoDisplayId:[],
      defaultIntroductoryText: this.editorValue,
      defaultTitle:  '',
      defaultClosingText: this.closingText,
      defaultReleaseText:  '',
      proposalDisclaimer:  '',
  }

  }

  GridResponse: CreateAddLeadActivityTypeRequest[] = [];

  GetAddNewActivityTypes() {
    this.leadActivitiesService.getAddLeadActivityType().subscribe(
      (res) => {
        this.GridResponse = res?.result;
        this.onSaveComplete.emit( );


      },
    );
  }
  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(
      (res) => {
        this.lookupSetNameResponse = res.result;

        // -----------single Select ---------------

        let lastValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.defaultLeadActivityCalendarView.code
        );
        let defaultGrouping = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.defaultGroup.code
        );
        let Header= res.result.filter(
          (x) => x.code === this.preDefinedDataCodes. Header.code
        );
        let Content= res.result.filter(
          (x) => x.code === this.preDefinedDataCodes. Content.code
        );
        let ShowContactName= res.result.filter(

          (x) => x.code === this.preDefinedDataCodes. ShowContactName.code
        );
        let DefaultWhattoDisplay = res.result.filter(

          (x) => x.code === this.preDefinedDataCodes. ShowCoDefaultWhattoDisplayntactName.code
        );

        // -----------single Select ---------------

        this.defaultCalendarView = lastValues;

        this.defaultGroup = defaultGrouping;

        this.Header = Header;

        this.Content = Content;


        this.ShowContactName= ShowContactName;

        this.lookupSetNameResponse = res.result;
        this.DefaultWhattoDisplaynodes = DefaultWhattoDisplay.map((tags) => ({
          title: tags.name,
          value: tags.id.toString(),
          key: tags.id.toString(),
          isLeaf: true,
        }));

        this.DefaultWhattoDisplaynodes = [
          {
            title: 'Check All',
            value: 'check_all',
            key: 'check_all',
            selectable: false,
            isLeaf: false,
            expanded: true,
            children: DefaultWhattoDisplay.map((tags) => ({
              title: tags.name,
              value: tags.id.toString(),
              key: tags.id.toString(),
              isLeaf: true,
            }))
          }
        ];



        // this.lookupSetNameResponse = res.result;
        // this.doubleOvertimeDays = weakStart.map((tags) => ({
        //   title: tags.name,
        //   value: tags.id.toString(),
        //   key: tags.id.toString(),
        //   isLeaf: true,
        // }));
      });

      }




      OnWhatDisplayChange(selectedValues: string[]): void {
        const checkAllIndex = selectedValues.indexOf('check_all');
        if (checkAllIndex !== -1) {
          const allValues = this.DefaultWhattoDisplaynodes[0].children.map(child => child['value']);
          this.formfieldvalues['DefaultWhattoDisplayId'] = [...allValues];
        } else {
          const allValues = this.DefaultWhattoDisplaynodes[0].children.map(child => child['value']);
          this.formfieldvalues['DefaultWhattoDisplayId'] = selectedValues.filter(value => allValues.includes(value));
        }
      }



  listOfData = [
    {
      id: '1',
      name: 'Quantity',
    },
    {
      id: '2',
      name: 'Description',
    },
    {
      id: '3',
      name: 'Unit',
    },
    {
      id: '4',
      name: 'Unit Cost',
    },
    {
      id: '5',
      name: 'Cost Type',
    },
    {
      id: '6',
      name: 'Builder Cost',
    },
    {
      id: '7',
      name: 'Markup',
    },
    {
      id: '8',
      name: 'Owner Price',
    },
    {
      id: '8',
      name: 'Margin',
    },
    {
      id: '8',
      name: 'Profit',
    },
    {
      id: '8',
      name: 'Internal Notes',
    },
  ];
  DefaultWhattoDisplaynodes: NzTreeNodeOptions[] = [
    {
      title: 'Check All',
      key: '0-0',
      children: []
    },
    {
      title: 'All Files',
      key: '0-1',
      isLeaf: true
    },
    {
      title: 'Excel Document (.xls, .xlsx)',
      key: '0-2',
      isLeaf: true
    },
    {
      title: 'Image (.jpg, .jpeg, .gif, .png, .tif, .tiff)',
      key: '0-3',
      isLeaf: true
    },
    {
      title: 'PDF (.pdf)',
      key: '0-4',
      isLeaf: true
    },
    {
      title: 'Word Document (.doc, .docx)',
      key: '0-5',
      isLeaf: true
    }
  ];





  public Editor = ClassicEditor;
  public config: EditorConfig = {
    toolbar: {
      items: [
        'undo', 'redo', '|',
        'heading', '|', 'bold', 'italic', '|',
        'link', 'insertTable', 'mediaEmbed', '|',
        'bulletedList', 'numberedList', 'indent', 'outdent', '|',
        'fontSize', 'fontFamily', 'fontBackgroundColor', 'fontColor', 'blockQuote',
        'image', 'horizontalLine', 'undoEditing'
      ]
    },
    plugins: [
      Bold,
      Essentials,
      Heading,
      Indent,
      Italic,
      Link,
      List,
      MediaEmbed,
      Paragraph,
      Table,
      Undo,
      Font,
      FontSize,
      FontFamily,
      FontBackgroundColor,
      FontColor,
      BlockQuote,
      Image,
      HorizontalLine,
      UndoEditing,
      ImageUpload
    ],
    fontSize: {
      options: [
        9, 11, 13, 'default', 17, 19, 21, 25, 28, 32, 36, 40, 44, 48, 52, 56, 60
      ]
    },
    fontFamily: {
      options: [
        'default', 'Arial, Helvetica, sans-serif', 'Courier New, Courier, monospace',
        'Georgia, serif', 'Times New Roman, Times, serif', 'Verdana, Geneva, sans-serif'
      ]
    }
  };



  Moves = ['Quantity', 'Description', 'Unit', 'Unit Cost','Cost Type', 'Builder Cost','Markup', 'Owner', 'Margin','Profit'];

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer !== event.container) {
      // When an item is dropped into a different container
      const movie = this.listOfData[event.previousIndex];

      this.inputFields = true;
      console.log(this.listOfData);
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      // Remove the item from the original section after dropping
      this.listOfData = this.listOfData.filter((_, index) => index !== event.previousIndex);
    } else {
      moveItemInArray(this.listOfData, event.previousIndex, event.currentIndex);
    }
  }




  loadOne(): void {
    this.isLoadingOne = true;
    setTimeout(() => {
      this.isLoadingOne = false;
    }, 5000);
  }
  loadTwo(): void {
    this.isLoadingTwo = true;
    setTimeout(() => {
      this.isLoadingTwo = false;
    }, 5000);
  }
  addleadactivitytype:boolean = false;
  AddLeadActivityType(){
    this.addleadactivitytype = true;
  }
  closeAddLeadActivityType(): void {
    this.addleadactivitytype = false;
  }
  customfieldinfo:boolean = false;
  CustomFieldInfo(){
    this.customfieldinfo = true;
  }
  handleCancel2(): void {
    this.customfieldinfo = false;
  }
  Taghandle= true;
  closeSales(): void {
  this.cancel.emit();
  }

  ProposlDisclamar: boolean = false;
  change: boolean = true;

  Change() {
    this.change = false;
    this.ProposlDisclamar = true;
  }


  AddNewField(){
      const modalRef = this.modal.create({
        nzContent:SalesLeadOpportunityCustomFieldComponent,
        nzFooter: null,
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
  }
   // Variable to track whether the image is active or not
   isActive = false;

   // Function to toggle the 'active' class
   toggleBorder() {
     this.isActive = !this.isActive;
   }

   saveData(){
    // console.log('azam dataa', this.formfieldvalues);
    this.leadser.Postsale(this.formfieldvalues).subscribe((response) =>{
      this.formfieldvalues = response.result.globalId;
      console.log("show all data globalid,",this.formfieldvalues);

      this.tostser.success('save sales successfully')
      this.formfieldvalues = {}

      this.modal.closeAll()
    })
    console.log('Sales Post Values', this.formfieldvalues );
   }

// Fetch Data Sales

dataset: SalesSettings;




fetchSalesData() {
  this.leadser.getDataByCompany().subscribe(
    (response) => {
      this.dataset = response.result;
      // if(this.dataset.length === 1){
        const setDataSalse = this.dataset;
        this.formfieldvalues = {
          globalId: setDataSalse.GlobalId || '',
          id: setDataSalse.Id,
          companyParameterId: setDataSalse.CompanyParameterId || 1,
          activityCalendarSettingId: setDataSalse.ActivityCalendarSettingId || '',
          proposalWorksheetSettingId: setDataSalse.ProposalWorksheetSettingId || '',
          headerSetupId: setDataSalse.HeaderSetupId || '',
          contentSetupId: setDataSalse.ContentSetupId || '',
          showCompanyInformationId: setDataSalse.ShowCompanyInformationId || '',
          showContactNamePhoneSetupId: setDataSalse.ShowContactNamePhoneSetupId || '',
          showAddressSetupId: setDataSalse.ShowAddressSetupId || '',
          defaultTitle: setDataSalse.DefaultTitle || '',
          defaultReleaseText: setDataSalse.DefaultReleaseText || '',
          defaultIntroductoryText: setDataSalse.DefaultIntroductoryText || '',
          proposalDisclaimer: setDataSalse.ProposalDisclaimer || '',
          defaultClosingText: setDataSalse.DefaultClosingText || '',
        }

        this.closingText = setDataSalse.DefaultClosingText;
        this.editorValue = setDataSalse.DefaultIntroductoryText;
    })
  }


// handleSaveComplete(newActivityType: any) {
//   this.GridResponse.push(newActivityType); // Add new activity type to the grid
// }



addNewActivityType() {
  const modalRef = this.modal.create({
    nzContent: AddLeadActivityTypeComponent,
    nzFooter: null,
  });

  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });

  modalRef.componentInstance.onSaveComplete.subscribe((newActivityType: any) => {
    this.GetAddNewActivityTypes();
    modalRef.destroy();
  });
}
selectedKeys: string[] = [];
isAllChecked: boolean = false;






EditLeadActivityType(activity:AddLeadActivityTypeResponse) {
  const modalRef = this.modal.create({
    nzContent: AddLeadActivityTypeComponent,
    nzFooter: null,
  });

  modalRef.componentInstance.addLeadActivityTypeResponse = activity;

  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();

  });

  modalRef.componentInstance.onUpdateComplete.subscribe((newActivityType: any) => {
    this.GetAddNewActivityTypes();
    modalRef.destroy();

  });
  modalRef.componentInstance.onDeleteComplete.subscribe((newActivityType: any) => {
    this.onDeleteComplete.emit();
    this.GetAddNewActivityTypes();
    modalRef.destroy();
  });
}

}
