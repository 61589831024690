<nz-modal nzWidth="70%" [(nzVisible)]="isVisible" [nzTitle]="modalTitle" [nzFooter]="modalFooter" [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '540px','overflow-y': 'auto'}" [nzStyle]="{ top: '15px' }" (nzOnCancel)="closeDialog()">
    <form [formGroup]="CustomerInformation">
        <div class="content" *nzModalContent>
            <ng-container *ngIf="CustomerInformation.value">
                <nz-card nzTitle="Contact Information" class="card-size">
                    <div nz-row>
                        <div nz-col nzSpan="3">
                            <div class="">

                                <nz-avatar class="custom-avatar" nzSrc="assets/user/user.jpg" [nzSize]="65" style="
                                  color: #88f8d3;
                                  background-color: #9ab3d3;
                                  font-size: 25px;
                                "></nz-avatar>
                            </div>

                            <nz-tag class="custom-tag">
                                {{ CustomerInformation.value.status ? 'active' : 'inactive' }}
                                <span style="font-weight: bold;">{{ CustomerInformation.value.firstName | slice:0:1
                                    }}</span>
                                <span style="font-weight: bold;">{{ CustomerInformation.value.lastName | slice:0:1
                                    }}</span>
                            </nz-tag>

                        </div>
                        <div nz-col nzSpan="21">
                            <div class="row">
                                <div class="col-12 ">
                                    <div class="form-group">
                                        <p>{{ CustomerInformation.value.firstName }}
                                            {{CustomerInformation.value.lastName}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 ">
                                    <div class="form-group">
                                        <p>{{ CustomerInformation.value.displayName }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 ">
                                    <div class="form-group">
                                        <p>{{ CustomerInformation.value.streetAddress }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 ">
                                    <div class="form-group">
                                        <p>{{ CustomerInformation.value.suburb }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <span style="color: rgb(118, 118, 240);">Cell:</span>
                                        <span>{{ CustomerInformation.value.phone }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <span style="color: rgb(118, 118, 240);">Primary Email:</span>
                                        <span>{{ CustomerInformation.value.primaryEmail }}</span>
                                    </div>
                                </div>
                            </div>



                            <div class="col-2 mt-0">
                                <button (click)="addRow()" *ngIf="showAddButton" type="button"
                                    class="ant-btn align-items-center ant-btn-link BTButton  AutoSizing">
                                    <span nz-icon nzType="plus-circle" nzTheme="fill"></span>
                                    <span>Add Additional Email</span>
                                </button>



                            </div>

                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row" *ngIf="showFields">
                                            <div class="col-12" *ngFor="let _ of [].constructor(rows); let i = index">
                                                <div class="row mb-3">
                                                    <div class="col-4">
                                                        <label class="AdditionalEmail rounded-1"> Additional Email <span
                                                                style="color: red;">*</span></label>
                                                        <input nz-input placeholder="Basic usage" class="rounded-1">
                                                    </div>
                                                    <div class="col-4 rounded-1">
                                                        <label class="AdditionalEmail rounded-1">Label</label>
                                                        <input nz-input placeholder="Basic usage" class="rounded-1">
                                                    </div>
                                                    <div class="col-1 mt-1">
                                                        <button class="trash-icon" nz-button nzType="text"
                                                            nzSize="small" (click)="removeRow(i)">
                                                            <i class="fa fa-trash  animated" id="trash-icon"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <ng-template #contentTemplate>
                        <div class="rounded-2">
                            <p style="height: auto; width: 220px;">
                                Display Name is shown anywhere the name of this contact is displayed on lead
                                opportunities and jobs.
                            </p>
                        </div>
                    </ng-template>
                </nz-card>
            </ng-container>




            <nz-card class="mt-2" nzTitle="Contact Information">
                <div nz-row>
                    <div nz-col nzSpan="3">
                        <div class="me-2">
                            <nz-avatar [nzSize]="64" *ngIf="avatarText; else defaultAvatar"
                                [nzText]="avatarText"></nz-avatar>
                            <ng-template #defaultAvatar>
                                <nz-avatar [nzSize]="64" nzIcon="user"></nz-avatar>
                            </ng-template>
                        </div>

                        <div class="mt-2">
                            <nz-tag class="custom-tag" [nzColor]="'#b9d7f4'">Inactive</nz-tag>
                        </div>
                    </div>

                    <div nz-col nzSpan="21">
                        <div class="row">
                            <div class="col-6 mt-2">
                                <div class="form-group">
                                    <label class="cityDropdown" for="firstName">First Name</label>
                                    <input type="text" autocomplete="off" formControlName="firstName"
                                        class="form-control formField" autocomplete="off" id="firstName"
                                        name="firstName" [attr.autofocus]="false"
                                        [ngClass]="{'error': CustomerInformation.get('firstName')?.invalid}"
                                        (input)="updateDisplayNameAndAvatar()">

                                    <div *ngIf="CustomerInformation.get('firstName')?.value?.length > 35"
                                        class="text-danger p-error">
                                        {{ CustomerInformation.get('firstName')?.value?.length - 35 }} character over.
                                    </div>


                                </div>
                            </div>

                            <div class="col-6 mt-2">
                                <div class="form-group">
                                    <label class="cityDropdown" for="lastName">Last Name</label>
                                    <input type="text" autocomplete="off" formControlName="lastName"
                                        class="form-control formField" autocomplete="off" id="lastName" name="lastName"
                                        [attr.autofocus]="false"
                                        [ngClass]="{'error': CustomerInformation.get('lastName')?.invalid}"
                                        (input)="updateDisplayNameAndAvatar()">


                                    <div *ngIf="CustomerInformation.get('lastName')?.value?.length > 35"
                                        class="text-danger p-error">
                                        {{ CustomerInformation.get('lastName')?.value?.length - 35 }} character over.
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-12 mt-2">
                                <div class="form-group">
                                    <label class="cityDropdown" for="displayName">Display Name
                                        <span class="text-danger p-error fw-bolder">*</span>
                                    </label>
                                    <input type="text" formControlName="displayName" class="form-control formField"
                                        id="displayName" name="displayName" [ngClass]="{
                                      'error': CustomerInformation.get('displayName')?.invalid && CustomerInformation.get('displayName')?.touched
                                  }" (input)="updateInitials()">

                                    <div *ngIf="CustomerInformation.get('displayName')?.hasError('required') && CustomerInformation.get('displayName')?.touched"
                                        class="text-danger p-error">Required</div>

                                    <div *ngIf="CustomerInformation.get('displayName')?.value?.length > 35"
                                        class="text-danger p-error">
                                        {{ CustomerInformation.get('displayName')?.value?.length - 35 }} character over.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-template #contentTemplate>
                            <div class="rounded-2">
                                <p style="height: auto; width: 220px;">
                                    Display Name is shown anywhere the name of this contact is displayed on lead
                                    opportunities and jobs.
                                </p>
                            </div>
                        </ng-template>
                        <div class="row">
                            <div class="col-12 mt-2">
                                <div class="form-group">
                                    <label for="" class="cityDropdown">Street Address</label>
                                    <input type="text" autocomplete="off" formControlName="streetAddress"
                                        class="form-control formField" autocomplete="off" id="streetAddress"
                                        name="streetAddress"
                                        [ngClass]="{'error': CustomerInformation.get('streetAddress')?.invalid}">

                                    <div *ngIf="CustomerInformation.get('streetAddress')?.value?.length > 50"
                                        class="text-danger p-error">
                                        {{ CustomerInformation.get('streetAddress')?.value?.length - 50 }} character
                                        over.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4 mt-2 ">
                                <div class="form-group">
                                    <label class="cityDropdown" for="suburb">Suburb</label>
                                    <input type="text" autocomplete="off" formControlName="suburb"
                                        class="form-control formField" autocomplete="off" id="suburb" name="suburb"
                                        [ngClass]="{'error': CustomerInformation.get('suburb')?.invalid}">

                                    <div *ngIf="CustomerInformation.get('suburb')?.value?.length > 50"
                                        class="text-danger p-error">
                                        {{ CustomerInformation.get('suburb')?.value?.length - 50 }} character over.
                                    </div>
                                </div>
                            </div>
                            <div class="col-4  mt-2 gx-2">
                                <div class="form-group">
                                    <label class="cityDropdown" for="state">State</label>
                                    <input type="text" autocomplete="off" formControlName="state"
                                        class="form-control formField" autocomplete="off" id="state" name="state"
                                        [ngClass]="{'error': CustomerInformation.get('state')?.invalid}">
                                    <div *ngIf="CustomerInformation.get('state')?.value?.length > 3"
                                        class="text-danger p-error">
                                        {{ CustomerInformation.get('state')?.value?.length - 3 }} character over.
                                    </div>
                                </div>
                            </div>
                            <div class="col-4  mt-2 gx-2">
                                <div class="form-group">
                                    <label class="cityDropdown" for="postalCode">Postal Code</label>
                                    <input type="text" autocomplete="off" formControlName="postalCode"
                                        class="form-control formField" autocomplete="off" id="postalCode"
                                        name="postalCode"
                                        [ngClass]="{'error': CustomerInformation.get('postalCode')?.invalid}">
                                    <div *ngIf="CustomerInformation.get('postalCode')?.value?.length > 15"
                                        class="text-danger p-error">
                                        {{ CustomerInformation.get('postalCode')?.value?.length - 15 }} character over.
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-5 mt-2">
                                <div class="form-group">
                                    <label class="cityDropdown" for="phone">Phone</label>
                                    <input type="text" autocomplete="off" formControlName="phone"
                                        class="form-control formField" autocomplete="off" id="phone" name="phone"
                                        [ngClass]="{'error': CustomerInformation.get('phone')?.invalid}">
                                    <div *ngIf="CustomerInformation.get('phone')?.value?.length > 25"
                                        class="text-danger p-error">
                                        {{ CustomerInformation.get('phone')?.value?.length - 25 }} character over.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-5 mt-2">
                                <div class="form-group">
                                    <label class="cityDropdown" for="phone">Cell
                                        Phone</label>
                                    <form [formGroup]="CustomerInformation">
                                        <div class="wrapper">
                                            <ngx-intl-tel-input [cssClass]="'custom'"
                                                [preferredCountries]="preferredCountries"
                                                [enableAutoCountrySelect]="false" [enablePlaceholder]="true"
                                                [searchCountryFlag]="true" [searchCountryField]="[
                               SearchCountryField.Iso2,
                              SearchCountryField.Name   ]" [selectFirstCountry]="false"
                                                [selectedCountryISO]="CountryISO.Australia" [maxLength]="15"
                                                [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                                [numberFormat]="PhoneNumberFormat.National" [searchCountryFlag]="true"
                                                name="cellPhone" formControlName="cellPhone" id="phone">
                                            </ngx-intl-tel-input>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-3 mt-2">
                                <div class="form-group">
                                    <label class="cityDropdown" for="phone">Receive Text Messages</label>
                                    <i class="bi bi-info-circle-fill text-secondary ms-2" nz-popover
                                        [nzPopoverContent]="contentTemplate" nzPopoverPlacement="right"></i>
                                    <ng-template #contentTemplate>
                                        <div class="rounded-2">
                                            <p style="height: auto; width: 180px;">
                                                To receive text messages
                                                please review your
                                                notification preferences.
                                            </p>
                                        </div>
                                    </ng-template>
                                    <a class="btn btn-sm btn-outline-secondary d-flex align-items-center"
                                        style="height: 28px;">
                                        <span role="img" class="anticon align-items-center">
                                            <svg width="2em" height="1.5em" viewBox="0 0 32 32" fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"
                                                class>
                                                <path
                                                    d="M22 2H10C9.20435 2 8.44129 2.31607 7.87868 2.87868C7.31607 3.44129 7 4.20435 7 5V27C7 27.7956 7.31607 28.5587 7.87868 29.1213C8.44129 29.6839 9.20435 30 10 30H22C22.7956 30 23.5587 29.6839 24.1213 29.1213C24.6839 28.5587 25 27.7956 25 27V5C25 4.20435 24.6839 3.44129 24.1213 2.87868C23.5587 2.31607 22.7956 2 22 2ZM9 8H23V24H9V8ZM10 4H22C22.2652 4 22.5196 4.10536 22.7071 4.29289C22.8946 4.48043 23 4.73478 23 5V6H9V5C9 4.73478 9.10536 4.48043 9.29289 4.29289C9.48043 4.10536 9.73478 4 10 4ZM22 28H10C9.73478 28 9.48043 27.8946 9.29289 27.7071C9.10536 27.5196 9 27.2652 9 27V26H23V27C23 27.2652 22.8946 27.5196 22.7071 27.7071C22.5196 27.8946 22.2652 28 22 28Z">
                                                </path>
                                            </svg>
                                        </span>
                                        <span class="align-items-center" style="font-size: 0.7rem;">Verify Phone
                                            Number</span>
                                    </a>
                                </div>
                            </div>
                        </div>


                        <div class="row mt-2">
                            <div class="col-6">
                                <div class="form-group mb-2">
                                    <label class="cityDropdown" for="state">Primary Email <span
                                            class="text-danger fw-bolder">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control formField" primaryEmail
                                        autocomplete="off" id="primaryEmail" formControlName="" name="primaryEmail"
                                        [ngClass]="{'error': CustomerInformation.get('primaryEmail')?.invalid &&  CustomerInformation.get('primaryEmail')?.touched  }">

                                    <div *ngIf="CustomerInformation.get('primaryEmail')?.hasError('email') && CustomerInformation.get('primaryEmail')?.touched"
                                        class="text-danger p-error">Primary Email must be a
                                        valid Primary Email
                                    </div>
                                    <div *ngIf="CustomerInformation.get('primaryEmail')?.value?.length > 110"
                                        class="text-danger p-error">
                                        {{ CustomerInformation.get('primaryEmail')?.value?.length - 110 }} character
                                        over.
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group mb-2">
                                    <label class="cityDropdown" for="label">
                                        Label</label>
                                    <input type="text" autocomplete="off" class="form-control formField"
                                        autocomplete="off" id="label" name="label" formControlName="label"
                                        [ngClass]="{'error': CustomerInformation.get('label')?.invalid &&  CustomerInformation.get('label')?.touched  }">
                                    <div *ngIf="CustomerInformation.get('label')?.value?.length > 50"
                                        class="text-danger p-error">
                                        {{ CustomerInformation.get('label')?.value?.length - 50 }} character over.
                                    </div>

                                </div>
                            </div>
                        </div>



                        <div formArrayName="createCustomerEmailParameterRequests">
                            <div class="row mt-2 mb-2 align-items-center d-flex justify-content-center"
                                *ngFor="let emailControl of additionalEmails.controls; let i = index"
                                [formGroupName]="i">
                                <div class="col-6">
                                    <div class="form-group mb-2">
                                        <label class="cityDropdown" for="email{{ i }}">Primary Email <span
                                                class="text-danger fw-bolder">*</span></label>
                                        <input type="text" autocomplete="off" class="form-control formField"
                                            id="email{{ i }}" formControlName="email"
                                            [ngClass]="{'error': emailControl.get('email')?.invalid && emailControl.get('email')?.touched }">
                                        <div *ngIf="emailControl.get('email')?.hasError('required') && emailControl.get('email')?.touched"
                                            class="text-danger p-error">Required
                                        </div>
                                        <div *ngIf="emailControl.get('email')?.hasError('email') && emailControl.get('email')?.touched"
                                            class="text-danger p-error">Primary Email must be a valid email
                                        </div>

                                        <div *ngIf="emailControl.get('email')?.value?.length > 110"
                                            class="text-danger p-error">
                                            {{ emailControl.get('email')?.value?.length - 110 }} character over.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <div class="form-group mb-2">
                                            <label class="cityDropdown" for="label{{ i }}">Label</label>
                                            <input type="text" autocomplete="off" class="form-control formField"
                                                id="label{{ i }}" formControlName="label"
                                                [ngClass]="{'error': emailControl.get('label')?.invalid && emailControl.get('label')?.touched }">

                                            <div *ngIf="emailControl.get('label')?.value?.length > 50"
                                                class="text-danger p-error">
                                                {{ emailControl.get('label')?.value?.length - 50 }} character over.
                                            </div>


                                            <div *ngIf="CustomerInformation.get('label')?.value?.length > 50"
                                                class="text-danger p-error">
                                                {{ CustomerInformation.get('label')?.value?.length - 50 }} character
                                                over.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-1 align-self-center mt-1">
                                    <div class="trash-icon" (click)="removeAdditionalEmail(i)">
                                        <i class="fa fa-trash  animated" id="trash-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2 mb-2" *ngIf="CustomerInformation.get('primaryEmail')?.valid && 
                       !CustomerInformation.get('primaryEmail')?.errors && 
                       additionalEmails.controls.length < 6">
                            <div class="col-2">
                                <button (click)="addAdditionalEmail()" type="button"
                                    class="ant-btn align-items-center ant-btn-link BTButton border AutoSizing">
                                    <span nz-icon nzType="plus-circle" nzTheme="fill"></span>
                                    <span>Add Additional Email</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-card>

            <div class="row mt-2">
                <div class="col">
                    <nz-card nzTitle="Lead Opportunities" [nzExtra]="extraTemplate">
                        <p>No Lead Opportunities Found</p>
                    </nz-card>
                    <ng-template #extraTemplate>

                    </ng-template>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col">
                    <nz-card nzTitle="Jobs " [nzExtra]="extraTemplate">
                        <p>No Jobs Found</p>
                    </nz-card>
                    <ng-template #extraTemplate>

                    </ng-template>
                </div>
            </div>
        </div>
    </form>


    <ng-template #modalFooter>
        <div class="mt-2 align-items-center">
            <button nz-button nzType="default" class="rounded-1 me-0">Print</button>
            <button nz-button nzType="default" class="rounded-1 me-0" (click)="Delete()">Delete</button>
            <!-- <button nz-button nzType="primary" class="rounded-1 me-0" (click)="saveAndNew()">Save & New</button> -->
            <button nz-button nzType="primary" class="rounded-1 me-0" (click)="save()">Save</button>
            <button nz-button nzType="primary" class="rounded-1 me-0" (click)="saveAndResetForm()">Save & Close</button>
        </div>
    </ng-template>
</nz-modal>