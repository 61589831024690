<nz-layout>
  <nz-sider class="border" nzCollapsible [nzCollapsedWidth]="10" [(nzCollapsed)]="isCollapsed" [nzWidth]="280"
    [nzTrigger]="null">
    <div class="row">
      <app-side-menu-bar [isAllJobOption]="true" (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"></app-side-menu-bar>
    </div>
  </nz-sider>
  <nz-layout>
    <nz-page-header>
      <nz-page-header-title>
        <nz-breadcrumb nz-page-header-breadcrumb>
          <nz-breadcrumb-item>{{
            accessLocalStorageService.getJobName()
            }}</nz-breadcrumb-item>
        </nz-breadcrumb>
        <h1>Time Clock</h1>
        <h3 style="color: #737987;" *ngIf="mySelection.length > 0">{{ mySelection.length }} selected</h3>
      </nz-page-header-title>
      <nz-page-header-extra *ngIf="mySelection?.length === 0">
        <nz-space>
          <button nz-button nzType="default" class="ms-2 rounded-1">
            Export
          </button>
          <button class="ms-2 rounded-1" nzType="default" nz-button nz-dropdown [nzDropdownMenu]="menu4"
            nzPlacement="bottomLeft" nzTrigger="click">
            Action
            <span nz-icon nzType="down"></span>
          </button>
          <nz-dropdown-menu #menu4="nzDropdownMenu">
            <ul nz-menu>
              <li (click)="ClockInUsers()" nz-menu-item>Clock In Users</li>
              <li (click)="RecordShift()" nz-menu-item>Record Shift</li>
            </ul>
          </nz-dropdown-menu>
          <button (click)="openFilter(PROJECT_MANG_TIMECLOCK, PAGE_ID)" nz-button nzType="default"
            class="ms-2 rounded-1">
            <i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter
          </button>
          <div *ngIf="showClockInOutButton">
            <button (click)="Clock()" nz-button nzType="primary" class="ms-2 button-Daily">
              Clock In
            </button>
          </div>
          <div *ngIf="!showClockInOutButton">
            <button (click)="ClockOut()" nz-button nzType="primary" class="ms-2 button-Daily">
              Clock Out
            </button>
          </div>
        </nz-space>
      </nz-page-header-extra>
      <nz-page-header-extra *ngIf="mySelection?.length > 0">
        <div class="button-container">
          <button class="rounded-1 me-3" nz-button nzType="default" (click)="approvalShift()">Approval Shifts</button>
        </div>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-content class="custom-content">
      <div class="container-fuild mb-4 m-3">
        <div class="row p-2">
          <div *ngIf="holdResponse" class="col-md-6 col-sm-12 mb-3">
            <div class="card" style="background-color: #fafdff; border-color: blue;">
              <div class="card-body">
                <p class="card-title fw-bold">YOUR CURRENT SHIFT</p>
                <div class="row">
                  <div class="col-4">
                    <div class="card mt-0" style="background: #ceedff;">
                      <div class="card-header" style="height: 90px;">
                        <label class="para-label mt-2">{{inTimeJobName}}</label>
                        <div *ngIf="holdResponse">
                          <h2 class="fw-bolder">{{timeDifference}}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <label style="color: #d0b6a8;">Shift Break Total</label><br>
                    <label style="color: #9d9ba8;" class="fw-bold mb-2">00:00</label><br>
                    <label style="color: #9d9ba8;" class="fw-bold">00.00 HR</label>
                  </div>
                  <div class="col-4 text-end mt-4">
                    <label class="text-primary" style="cursor: pointer;">Start Break</label>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12">
                      <label class="para-label">Clocked in: {{formattedDate}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card" style="width: 32rem; height: 190px;">
              <div class="card-body">
                <h4 class="card-title ms-1">YOUR TIME CLOCK TOTALS</h4>
                <div class="row p-1 ms-lg-1">
                  <div class="col-3 p-1 bg-body-secondary">
                    <span>Daily Total Time</span>
                    <span><strong>00:01</strong></span>
                    <span>00:02 HR</span>
                  </div>
                  <div class="col-3 p-1 bg-body-secondary ms-4">
                    <span>Daily Break Total</span>
                    <span><strong>00:01</strong></span>
                    <span>00:02 HR</span>
                  </div>
                  <div class="col-3 p-1 bg-body-secondary ms-4">
                    <span>Weekly Total</span>
                    <span><strong>00:01</strong></span>
                    <span>00:02 HR</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- second card ka First Card -->
        </div>
      </div>
      <ng-container *ngIf="GetProjecTimeClockgriddata?.length === 0">
        <div class="row mt-5"
          style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
          <div class="col" style="text-align: center;">
            <span>
              <img src="assets/Empty-SVGs/Financial/Purchase Orders/purchaseOrder.svg">
            </span>
            <div class="row">
              <div class="col mt-2">
                <h1>Simplify timekeeping with</h1>

                <h1 class="p-0">Time Clock</h1>
              </div>
            </div>
            <div class="col mt-3">
              <p>Clock in to track hours and breaks on the Job</p>
            </div>
          </div>
          <div class="col mt-4" style="display: flex; justify-content: center; align-items: center">
            <button nz-button nzType="default" style="margin-right: 10px">
              <i class="bi bi-box-arrow-up-right me-2"></i>Learn How
            </button>
            <div>
              <button (click)="Clock()" nz-button nzType="primary" class="ms-2 button-Daily">
                Clock In
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <!--------------------------------------Sami add Grid  -------------------------->
      <ng-container *ngIf="GetProjecTimeClockgriddata?.length > 0">
        <kendo-grid
          [ngStyle]="{ 'overflow-y': 'auto' }"
          (columnResize)="saveColumnSizes($event)"
          [data]="GetProjecTimeClockgriddata"
          [pageSize]="10"
          [sortable]="true"
          [resizable]="true"
          [reorderable]="true"
          kendoGridSelectBy="id"
          [(selectedKeys)]="mySelection"
          (selectionChange)="selectionChange(mySelection)"
          style="width: 100%; min-width: 800px;">

          <!-- Checkbox column and button container -->
          <kendo-grid-checkbox-column
            [width]="45"
            [headerClass]="{ 'text-center': true }"
            [class]="{ 'text-center': true }"
            [resizable]="false"
            [columnMenu]="false"
            [showSelectAll]="true">
          </kendo-grid-checkbox-column>

          <kendo-grid-column
            *ngFor="let column of displayedColumns; let i = index"
            [title]="column"
            [field]="column"
            [minResizableWidth]="150"

            [sticky]="isColumnSticky[i]"
            [headerClass]="'pinned-header'">

            <ng-template kendoGridHeaderTemplate let-columnIndex="columnIndex">
              <div class="d-flex align-items-center">
                <span>{{ column }}</span>
                <button (click)="toggleColumnSticky(columnIndex)" class="btn btn-link">
                  <i class="bi" [ngClass]="{
                    'bi-pin-fill': isColumnSticky[columnIndex],
                    'bi-pin': !isColumnSticky[columnIndex]
                  }"></i>
                </button>
              </div>
            </ng-template>

            <ng-template kendoGridColumnMenuTemplate let-columnIndex="columnIndex">
              <button (click)="toggleColumnSticky(columnIndex)" class="btn btn-link">
                <i class="bi" [ngClass]="{
                  'bi-pin-fill': isColumnSticky[columnIndex],
                  'bi-pin': !isColumnSticky[columnIndex]
                }"></i>
              </button>
            </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <ng-container *ngIf="column === 'CO ID#'">
                <!-- Add your content here -->
              </ng-container>

              <ng-container *ngIf="column === 'Job #'">
                {{ dataItem?.jobInformation?.name }}
              </ng-container>

              <ng-container *ngIf="column === 'Notes'">
                {{ dataItem?.notes }}
              </ng-container>

              <ng-container *ngIf="column === 'TimeIn'">
                {{ dataItem?.startTime | date: 'HH:mm:ss' }}
              </ng-container>

              <ng-container *ngIf="column === 'Tags'">
                <span *ngFor="let tagParameter of dataItem?.shiftTagParameters">
                  <nz-tag class="rounded-1 fw-medium me-1 mb-1">{{ tagParameter.shiftTagSetup?.name }}</nz-tag>
                </span>
              </ng-container>

              <ng-container *ngIf="column === 'TimeOut'">
                <ng-container *ngIf="dataItem.endTime; else clockOutButton">
                  {{ dataItem.endTime | date: 'HH:mm:ss' }}
                </ng-container>
                <ng-template #clockOutButton>
                  <ng-container *ngIf="shouldShowClockOutButton(dataItem)">
                    <button (click)="ClockOut()" nz-button nzType="primary" class="ms-2 button-Daily">
                      Clock Out
                    </button>
                  </ng-container>
                </ng-template>
              </ng-container>

              <ng-container *ngIf="column === 'Date'">
                <a class="text-decoration-none blue-text" (click)="EditInternalUser(dataItem)">
                  {{ dataItem?.startOn | date: 'MMM d, yyyy' }}
                </a>
              </ng-container>

              <ng-container *ngIf="column === 'CreatedBy'">
                {{ dataItem?.createdBy }}
              </ng-container>

              <ng-container *ngIf="column === 'Name'">
                {{ dataItem?.applicationUser?.firstName }}
              </ng-container>

              <ng-container *ngIf="column === 'CostCodes'">
                <nz-tag class="rounded-1 fw-medium me-1 mb-1">{{ dataItem?.costCode?.title }}</nz-tag>
              </ng-container>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </ng-container>


    </nz-content>
  </nz-layout>
</nz-layout>
<nz-footer class="custom-footer">
  <div class="container-fluid ">
    <div class="row d-flex justify-content-between align-items-center  ">
      <div class="row mb-0 mt-2">
        <div class="col">
          <nz-pagination style="float: right;" [nzPageIndex]="currentPage" [nzTotal]="totalRecords"
            [nzPageSize]="pageSize" nzShowSizeChanger (nzPageIndexChange)="onPageChange($event)"
            (nzPageSizeChange)="onPageSizeChange($event)" [nzPageSizeOptions]="[10, 20, 50, 75, 100]"></nz-pagination>

          <ng-container *ngIf="PAGE_ID">
            <div class="row d-flex justify-content-start mb-1 ">
              <!-- <div class="col-md-3">
                <nz-select (ngModelChange)="onFilterChange($event)" [(ngModel)]="selectedValueControl" nzShowSearch
                  nzAutoFocus="true">
                  <nz-option *ngFor="let standard of getStandardViewFilterData" [nzLabel]="standard.name"
                    [nzValue]="standard.id"></nz-option>
                </nz-select>
              </div> -->


              <div class="col-md-3">
                <div class="input-group">
                  <nz-select
                  [(ngModel)]="selectedValueControl"
                    name="standardFilter"
                    (ngModelChange)="onFilterChange($event)"
                    nzShowSearch
                    [nzAutoFocus]="true"
                    nzPlaceHolder="Standard Filter"
                    >
                    <nz-option  *ngFor="let standard of getStandardViewFilterData"
                      [nzLabel]="standard.name + (standard.isSetAsDefault ? ' ' : '')"
                      [nzValue]="standard.id"
                      nzCustomContent>
                      <div class="option-content">
                        <!-- Customize content with icons and text -->
                        <span *ngIf="standard.icon" nz-icon [nzType]="standard.icon"></span>
                        <span>{{ standard.name }}</span>
                      <span  *ngIf="standard.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

                      </div>
                    </nz-option>
                  </nz-select>
                </div>
              </div>


              <div class="col-md-1">
                <button nz-button class="border-0"
                nz-popover
           [nzPopoverContent]="contentTemplate"
           [nzPopoverPlacement]="'top'"
                 (click)="openStandardViewFilterComponent()">
                  <span nz-popover class="text-primary text-dark fw-bold fs-5" nz-icon nzType="ellipsis"
                    nzTheme="outline"></span>
                </button>
              </div>
              <div class="col-md-2">
                <div class="mt-2" *ngIf="selectedFilter === 1">
                  <p>Your filter has unsaved changes.</p>
                </div>
                <div class="mt-2" *ngIf="selectedFilter === 0">
                  <p>Your filter has unsaved changes.</p>
                </div>
              </div>

              <div class="col-md-3">
                <button (click)="openStandardViewFilterComponent('saveAs')" type="button" nz-button nzType="default"
                  class="me-2" style="font-size: 12px;" *ngIf="selectedFilter === 1">Save As</button>

                <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;"
                  *ngIf="selectedFilter === 1" type="button">Reset</button>

                <button (click)="saveEditStandardView()" nz-button nzType="primary" type="button" class="me-2"
                  style="font-size: 12px;" *ngIf="selectedFilter === 0">Save</button>

                <button (click)="openStandardViewFilterComponent('saveAs')" nz-button nzType="default" class="me-2"
                  style="font-size: 12px;" *ngIf="selectedFilter === 0" type="button">Save As</button>

                <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;"
                  *ngIf="selectedFilter === 0" type="button">Reset</button>

              </div>
            </div>
          </ng-container>

          <div class="col d-flex mt-3">


          </div>

        </div>
      </div>
    </div>
  </div>

</nz-footer>

<!-- New Content Sami -->

<!-- New Confirm Model  -->
<nz-modal nzWidth="23%" [(nzVisible)]="shiftconformCardShow" [nzTitle]="null" [nzContent]="conformContentSecondModal"
  [nzFooter]="null" [nzClosable]="false" (nzOnCancel)="conformhandleCancel()"
  [nzBodyStyle]="{'background': '#ffffff','padding': '35px','max-height': '470px','overflow-y': 'auto'}"
  [nzStyle]="{ top: '280px' }">
  <ng-template #conformContentSecondModal>
    <div class="row mb-4" style="margin-top: -15px;">
      <div class="col-12">
      </div>
    </div>
    <h2 class="mb-2 famly-inn" style="margin-top: -20px;">Approve {{ mySelection.length }} Shift?</h2>
    <div class="row mb-3">
      <div class="col-12 mt-3">
        <p class="fw-medium famly-inn">
          Are you sure you want to approve?
        </p>
      </div>
    </div>
    <div class="row" style=" margin-bottom:-10px">
      <div class="col-12 text-end">
        <nz-space>
          <button *nzSpaceItem nz-button class="rounded-1" nzType="default"
            (click)="conformhandleCancel()">Cancel</button>
          <button *nzSpaceItem nz-button class="rounded-1 me-2" nzType="primary">Continue</button>
        </nz-space>
      </div>
    </div>
  </ng-template>
</nz-modal>

<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>
