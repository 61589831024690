import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationEnd,
} from "@angular/router";
import { filter } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  previousUrl: string;
  constructor(private authService: AuthService, private router: Router) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
      });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.verifyActivation(route, state);

  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.verifyActivation(route, state);
  }



  verifyActivation(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isAuthenticated: boolean = false;
    if (this.authService.isAuthenticatedUser && this.authService.getTabs()?.length > 0) {
      isAuthenticated = true;
    }

    if (!isAuthenticated) {
      this.router.navigate(['/Authentication/Sign-In']);
      return false;
    }
    return isAuthenticated;
  }

}