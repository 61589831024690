import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommentsMessageSetupResponse, CreateCommentsMessageSetupRequest } from 'src/Models/comments & message/commentMessage.Model';
import { ResponseModel, ResponseModelArray } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CommentMessageService {

  constructor(private http: HttpClient) { }

  private baseUrl: string = environment.apiUrl + 'CommentsMessageSetup/getData-ByCompany';
  companyCommentMessageget(): Observable<ResponseModelArray<CommentsMessageSetupResponse>> {
    return this.http.get<ResponseModelArray<CommentsMessageSetupResponse>>(this.baseUrl);
  }
  getCompanyCommentMessage(): Observable<ResponseModel<CommentsMessageSetupResponse>> {
    return this.http.get<ResponseModel<CommentsMessageSetupResponse>>(this.baseUrl);
  }

  private _baseUrl: string = environment.apiUrl + 'CommentsMessageSetup';

  companyCommentMessagepost(CommentMessageFormData: CreateCommentsMessageSetupRequest): Observable<ResponseModelArray<CommentsMessageSetupResponse>> {
    const apiUrl = `${this._baseUrl}`;
    return this.http.post<any>(this._baseUrl, CommentMessageFormData);
  }

}
