import { Component, EventEmitter, Input, Output, NgZone, inject, Inject } from '@angular/core';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-lead-opportunities-work-sheet-addform-catalog',
  templateUrl: './lead-opportunities-work-sheet-addform-catalog.component.html',
  styleUrls: ['./lead-opportunities-work-sheet-addform-catalog.component.css']
})
export class LeadOpportunitiesWorkSheetAddformCatalogComponent {
  // @Input() nzData: number = 1; 
  @Output() cancel = new EventEmitter<void>();
  visibleCatalogModel = true;
  isButton: boolean = false ;
  tabs = [1, 2];
  value: string[] = ['0-0-0'];
  selectedValue = null;
  drawervisible = false;
  buttonLabel: string;
  flagForword: string;
constructor(
  @Inject(NZ_MODAL_DATA) public flag: any,
) {

}
  ngOnInit(): void{
    console.log('flag chek',this.flag?.CompanySetting);
    this.flagForword = this.flag?.CompanySetting;
  }
  CatalogModelhandle() {
    this.cancel.emit();
  }



  catalogTabTitle(tab: number): string {
    switch (tab) {
      case 1:
        return 'Catalog';
      case 2:
        return 'Cost Groups';
      default:
        return '';
    }
  }
onChange(){
  this.isButton = true;

  console.log(this.flag?.ChangeOrder);
  
}

  
}
