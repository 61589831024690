<app-header></app-header>
<nz-layout>
  <nz-page-header>
    <nz-page-header-title>
      <h1>Customer Contacts</h1>
      <!-- Display total and selected checkboxes -->
      <!-- <p>Total checkboxes: {{ getTotalCheckboxes() }}</p>
<p>Selected checkboxes: {{ getSelectedCheckboxes() }}</p> -->

    </nz-page-header-title>
    <nz-page-header-extra>
      <nz-space *ngIf="!checkboxChecked">
        <button *nzSpaceItem nz-button nzType="default" class="me-0" (click)="exportToExcel()"
          [nzLoading]="isLoadingOne">Export</button>
        <button *nzSpaceItem nz-button nzType="default" class="me-0" (click)="openFilter(CUSTOMER_CONTACT, PAGE_ID)">
          <i class="bi bi-funnel pe-1"></i>
          Filter <span *ngIf="isFilterApplied"> (1) </span>
        </button>
        <button *nzSpaceItem nz-button nzType="primary" class="me-0" nzType="primary" (click)="showNewCustomerStandard()">New
          Contact</button>
      </nz-space>

      <nz-space *ngIf="checkboxChecked">
        <p> {{ getSelectedCheckboxes() }} </p>
        <button *nzSpaceItem nz-button nzType="default" class="me-0" (click)="deleteSelectedItems()">Delete</button>
        <button *nzSpaceItem nz-button nzType="default" class="me-0" (click)="toggleCheckbox()">Cancel</button>
        <button *nzSpaceItem nz-button nzType="default" class="me-0">
          <i class="bi bi-funnel pe-1"></i>
          Merge Contacts
        </button>
      </nz-space>


    </nz-page-header-extra>
  </nz-page-header>
  <nz-content class="custom-content">
    <div class="container-fuild mb-4 m-3">


      <kendo-grid
      (columnResize)="saveColumnSizes($event)"
      [data]="displayedData"
      [pageSize]="5"
      [sortable]="true"
      [resizable]="true"
      [reorderable]="true"
    >
      <kendo-grid-column *ngFor="let column of displayedColumns; let i = index"
                         [title]="column"
                         [field]="column"
                         [minResizableWidth]="150"
                         [maxResizableWidth]="800"
                         [width]="getColumnWidthSize[i]"
                         [sticky]="isColumnSticky[i]"
                         [headerClass]="'pinned-header'"
                         >
                         <ng-template kendoGridHeaderTemplate let-columnIndex="columnIndex">
                          <div class="d-flex align-items-center" >
                            <span>{{ column }}</span>
                            <button (click)="toggleColumnSticky(columnIndex)" class="btn btn-link">
                              <i class="bi" [ngClass]="{
                                'bi-pin-fill': isColumnSticky[columnIndex],
                                'bi-pin': !isColumnSticky[columnIndex]
                              }"></i>
                            </button>
                          </div>
                        </ng-template>
                        <ng-template kendoGridColumnMenuTemplate let-columnIndex="columnIndex">
                          <button (click)="toggleColumnSticky(columnIndex)" class="btn btn-link">
                            <i class="bi" [ngClass]="{
                              'bi-pin-fill': isColumnSticky[columnIndex],
                              'bi-pin': !isColumnSticky[columnIndex]
                            }"></i>
                          </button>
                        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex">
          <!-- <ng-container *ngIf="column === 'Name'">
            <a class="text-decoration-none" (click)="EditCustomer(dataItem)">{{ dataItem.firstName }} {{ dataItem.lastName }}</a>
          </ng-container> -->
          <ng-container *ngIf="column === 'DisplayName'">
            <a class="text-decoration-none blue-text" (click)="EditCustomer(dataItem)">{{ dataItem.displayName }}</a>
          </ng-container>

          <ng-container *ngIf="column === 'StreetAddress'">{{ dataItem.streetAddress }}</ng-container>
          <ng-container *ngIf="column === 'Suburb'">{{ dataItem.suburb}}</ng-container>
          <ng-container *ngIf="column === 'State'">{{ dataItem.state  }}</ng-container>
          <ng-container *ngIf="column === 'PostalCode'">{{ dataItem.postalCode }}</ng-container>
          <ng-container *ngIf="column === 'Phone'">{{ dataItem.phone }}</ng-container>
          <ng-container *ngIf="column === 'CellPhone'">{{ dataItem.cellPhone  }}</ng-container>
          <ng-container *ngIf="column === 'PrimaryEmail'">
            <span>{{ dataItem.primaryEmail }} </span>
            <span *ngFor="let email of dataItem.customerEmailParameters; let last = last">
              <span *ngIf="!last && isExpanded(rowIndex)">{{ email?.email }}, </span>
              <span *ngIf="last && isExpanded(rowIndex)">{{ email?.email }}</span>
              <span style="cursor: pointer;" *ngIf="last && !isExpanded(rowIndex) && dataItem.customerEmailParameters.length > 0" (click)="toggleAdditionalEmails(rowIndex)">...</span>
            </span>
          </ng-container>
          <!-- <ng-container *ngIf="column === 'Status'">
            <ng-container *ngIf="dataItem.loginAccess; else pendingInvite">
              <nz-tag class="active" [nzColor]="'#c4fae2'">Active</nz-tag>
            </ng-container>
            <ng-template #pendingInvite>
              <nz-tag class="inactive" [nzColor]="'#fbe9b1'">Inactive</nz-tag>
            </ng-template>
          </ng-container> -->



          <ng-container *ngIf="column === 'Activation Status'">
            <nz-tag
            nz-popover
            [nzPopoverContent]="Content"
            class="d-flex align-items-center mt-1"
            [ngClass]="getStatusClass(dataItem?.statusSetup?.code)"
            style="
                line-height: 1;
                border-radius: 10px;
                border: none;
                padding: inherit;
                height: 20px;
                width: initial;
                font-weight: 700;
                cursor: pointer;
            "
            (mouseenter)="setHovered(dataItem, true)"
            (mouseleave)="setHovered(dataItem, false)"
          >
            {{
              dataItem?.statusSetup?.description
            }}  
            <span class="ms-2" nz-icon nzType="info-circle" [nzTheme]="dataItem.isHovered ? 'fill' : 'outline'"></span>
          </nz-tag>
          
          </ng-container>

          <ng-template #Content>
            <p style="width: 220px; height: auto;">
             <span>
               {{ dataItem?.statusSetup?.name }}
               <ng-container *ngIf="dataItem?.statusSetup?.code === StatusCode.ACTIVE.code || StatusCode.PENDING.code">
                  {{ dataItem?.createdOn | date : 'MMM dd,YYYY' }}
               </ng-container>
             </span>
             
            </p>
         </ng-template>


        </ng-template>
      </kendo-grid-column>
    </kendo-grid>


      <nz-footer class="custom-footer" *ngIf="displayedData?.length > 0">
        <div class="container-fluid ">
          <div class="row d-flex justify-content-between align-items-center  ">
            <div class="row mb-0 mt-2">
              <div class="col">
                  <!-- sufyan Work -->
           <nz-pagination
           class="hide-buttons"
           style="float: right"
           [nzPageIndex]="current"
           [nzTotal]="totalRecords"
           [nzPageSize]="pageSize"
           [nzShowSizeChanger]="true"
           [nzPageSizeOptions]="pageSizeOptions"
           (nzPageIndexChange)="onPageChange($event)"
           (nzPageSizeChange)="onPageSizeChange($event)"
           [nzShowTotal]="totalTemplate"
           [nzSimple]="false"
         ></nz-pagination>
         <ng-template #totalTemplate let-total="total" let-range="range">
           {{ range[0] }}-{{ range[1] }} of {{ total }} items
         </ng-template>
         <nz-pagination
           *ngIf="!isAllDataShownCustomerContact()"
           style="float: right"
           [nzPageIndex]="current"
           [nzTotal]="totalRecords"
           [nzPageSize]="pageSize"
           [nzShowSizeChanger]="true"
           [nzPageSizeOptions]="pageSizeOptions"
           (nzPageIndexChange)="onPageChange($event)"
           (nzPageSizeChange)="onPageSizeChange($event)"
           nzSimple
         ></nz-pagination>
           <!-- sufyan Work -->



                <ng-container *ngIf="PAGE_ID">
                  <div class="row d-flex justify-content-start mb-1 ">
                    <!-- <div class="col-md-3">
                      <nz-select (ngModelChange)="onFilterChange($event)" [(ngModel)]="selectedValueControl"
                        nzShowSearch nzAutoFocus="true">
                        <nz-option *ngFor="let standard of getStandardViewFilterData" [nzLabel]="standard.name"
                          [nzValue]="standard.id"></nz-option>
                      </nz-select>

                    </div> -->
                    <div class="col-md-3">
                      <div class="input-group">
                        <nz-select
                        [(ngModel)]="selectedValueControl"
                          name="standardFilter"
                          (ngModelChange)="onFilterChange($event)"
                          nzShowSearch
                          [nzAutoFocus]="true"
                          nzPlaceHolder="Standard Filter"
                          >
                          <nz-option  *ngFor="let standard of getStandardViewFilterData"
                            [nzLabel]="standard.name + (standard.isSetAsDefault ? ' ' : '')"
                            [nzValue]="standard.id"
                            nzCustomContent>
                            <div class="option-content">
                              <span *ngIf="standard.icon" nz-icon [nzType]="standard.icon"></span>
                              <span>{{ standard.name }}</span>
                            <span  *ngIf="standard.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

                            </div>
                          </nz-option>
                        </nz-select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <button nz-button class="border-0" (click)="openStandardViewFilterComponent()">
                        <span nz-popover class="text-primary text-dark fw-bold fs-5" nz-icon nzType="ellipsis"
                          nzTheme="outline"></span>
                      </button>
                    </div>
                    <div class="col-md-2">
                      <div class="mt-2" *ngIf="selectedFilter === 1">
                        <p>Your filter has unsaved changes.</p>
                      </div>
                      <div class="mt-2" *ngIf="selectedFilter === 0">
                        <p>Your filter has unsaved changes.</p>
                      </div>
                    </div>
                     <div class="col-md-3">
                      <button (click)="openStandardViewFilterComponent('saveAs')" type="button" nz-button
                        nzType="default" class="me-2" style="font-size: 12px;" *ngIf="selectedFilter === 1">Save
                        As</button>

                      <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;"
                        *ngIf="selectedFilter === 1" type="button">Reset</button>

                      <button (click)="saveEditStandardView()" nz-button nzType="primary" type="button" class="me-2"
                        style="font-size: 12px;" *ngIf="selectedFilter === 0">Save</button>

                      <button (click)="openStandardViewFilterComponent('saveAs')" nz-button nzType="default"
                        class="me-2" style="font-size: 12px;" *ngIf="selectedFilter === 0" type="button">Save
                        As</button>

                      <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;"
                        *ngIf="selectedFilter === 0" type="button">Reset</button>

                    </div> 
                    

                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </nz-footer>
    </div>

  </nz-content>
</nz-layout>



<ng-template #modalFooter>
  <div class="mt-2 align-items-center">
    <button nz-button nzType="default" class="me-0">Print</button>
    <!-- <button nz-button nzType="default" class="me-0" (click)="Delete()">Delete</button> -->
    <!-- <button nz-button nzType="primary" class="me-0" (click)="saveAndNew()">Save & New</button> -->
    <!-- <button nz-button nzType="primary" class="me-0" (click)="save()">Save</button> -->
    <!-- <button nz-button nzType="primary" class="me-0" (click)="saveAndResetForm()">Save & Close</button> -->
  </div>
</ng-template>



<app-customer-standard-view></app-customer-standard-view>
<app-loading-indicator></app-loading-indicator>
