<nz-layout>
  <nz-sider
    class="border"
    nzCollapsible
    [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed"
    [nzWidth]="280"
    [nzTrigger]="null"
  >
    <div class="row">
      <app-side-menu-bar
        [isAllJobOption]="true"
        (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"
      ></app-side-menu-bar>
    </div>
  </nz-sider>
  <nz-layout>
    <nz-page-header>
      <nz-page-header-title>
        <nz-breadcrumb class="text-start" nz-page-header-breadcrumb>
          <nz-breadcrumb-item>{{localStorage.getJobName()}}</nz-breadcrumb-item>

          <nz-breadcrumb-item *ngFor="let item of response">
          
            {{ item.title }}
          </nz-breadcrumb-item>
        </nz-breadcrumb>
      </nz-page-header-title>
    
    </nz-page-header>
    <nz-content class="custom-content">
      <div class="text-end">
        <nz-page-header-extra>
          <div nz-row class="button-container">
            <div id="fileViewToggle" class="btn-group btn-group-toggle" data-toggle="buttons">
              <label class="btn btn-outline-danger active" data-testid="fileViewToggle-Tile" (click)="showH1OnClick()">
                <input type="radio" name="options" id="option1" autocomplete="off" checked>
                <svg width="20" height="20" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class="icon">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H6Zm1 8V7h6v6H7ZM18 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-8Zm1 8V7h6v6h-6ZM5 18a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-8Zm2 1v6h6v-6H7ZM18 17a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1h-8Zm1 8v-6h6v6h-6Z"></path>
                </svg>
              </label>
              <label class="btn btn-outline-primary" data-testid="fileViewToggle-Table" (click)="showH5OnClick()">
                <input type="radio" name="options" id="option2" autocomplete="off">
                <svg width="20" height="20" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class="icon">
                  <path d="M4 8c0-.6.4-1 1-1h22a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1ZM4 16c0-.6.4-1 1-1h22a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1ZM5 23a1 1 0 1 0 0 2h22a1 1 0 1 0 0-2H5Z"></path>
                </svg>
              </label>
            </div>

            
            
            
            <nz-switch [(ngModel)]="showActivity" nzCheckedChildren="Activity" nzUnCheckedChildren="Activity"></nz-switch>

            <nz-button-group>
              <button nz-button nz-dropdown nzPlacement="bottomRight">
                <span nz-icon nzType="ellipsis"></span>
              </button>
            </nz-button-group>
            <button nz-button nzType="primary" class="ms-2 rounded-1" (click)="CreateNewfolder(responseData)">
              Create Folder
            </button>
          </div>
        </nz-page-header-extra>


        <div class="row" *ngIf="response && response.length > 0">

         

          <div class="col-7 ">
            <div *ngIf="showH1">
              <div class="card sizing ms-4   gx-5" style="float: left;"  *ngFor="let item of response">
                <div class="text-start ms-1">
                    <input  type="checkbox" class="checkbox">
                    <div class="icon home-icon"></div>
                </div>
                <div class="text-right" style="display: flex; justify-content: flex-end; ">
                  <span class="ant-tag Tag BTPopover PopoverSpacing">
                    <span role="img" size="16" data-icon-name="BTIcon" class="anticon" style="font-size: 16px;">
                      <svg width="1em" height="1em" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class="">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C16.7709 3 19.9387 3.67145 21.5646 5.15211L21.7125 5.2933L25.7076 9.29337C25.8636 9.44962 25.9627 9.65215 25.9913 9.86867L26 10V10.999L27 11C27.1768 11 27.3488 11.0468 27.4994 11.1336L27.608 11.2061L27.7071 11.2929L29.7071 13.2929C30.0676 13.6534 30.0953 14.2206 29.7903 14.6129L29.7071 14.7071L24.7071 19.7071C24.3466 20.0676 23.7794 20.0953 23.3871 19.7903L23.2929 19.7071L21.2929 17.7071C21.1366 17.5508 21.0374 17.3481 21.0087 17.1314L21 17V15.999L20.425 16L9.22378 27.6981L9.101 27.842L8.97068 27.9791C8.31953 28.628 7.43794 29 6.5 29C4.567 29 3 27.433 3 25.5C3 24.629 3.32081 23.8067 3.88604 23.1724L4.0324 23.0179L4.13356 22.9211L4.23955 22.8311L16.0584 11.5177C16.5159 7.96275 15.2161 6.27148 11.917 5.99655C11.4385 5.95668 11.062 5.58444 11.0069 5.11834L11 5V4C11 3.44772 11.4477 3 12 3ZM16.984 13.399L5.5591 24.3317C5.51846 24.3645 5.47953 24.3994 5.44247 24.4362C5.16043 24.7166 5 25.0943 5 25.5C5 26.3284 5.67157 27 6.5 27C6.85283 27 7.18453 26.8787 7.44951 26.6614L7.58215 26.5387L7.72348 26.3782L18.6 15.015L16.984 13.399ZM16.015 5.33818L15.816 5.194L15.979 5.21339L16.3324 5.26025C18.2972 5.53858 19.6243 6.03272 20.2975 6.7067L24 10.414V12L24.0067 12.1166L24.0264 12.2293C24.1301 12.6711 24.5266 13 25 13L26.584 12.999L27.585 14L24 17.585L23 16.585V15L22.9933 14.8834L22.9736 14.7707C22.8699 14.3289 22.4734 14 22 14L20.414 13.999L18.056 11.641L18.0954 11.2718C18.3323 8.57026 17.6213 6.56013 16.015 5.33818Z"></path>
                      </svg>
                    </span>
                  </span>
                  <span class="ant-tag Tag BTPopover PopoverSpacing">
                    <span role="img" size="16" data-icon-name="BTIcon" class="anticon" style="font-size: 16px;">
                      <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="1em" height="1em" aria-hidden="true" focusable="false" class="">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.65 3.87a2 2 0 0 1 2.7 0l10 9.09.01.01a2.04 2.04 0 0 1 .64 1.45V26h2a1 1 0 1 1 0 2H2a1 1 0 1 1 0-2h2V14.42a2.04 2.04 0 0 1 .64-1.45l.01-.01 10-9.09ZM14 26h4v-6h-4v6Zm6 0v-6a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v6H6V14.45a.04 0 0 1 0-.02l10-9.08 10 9.08V26h-6Z"></path>
                      </svg>
                    </span>
                  </span>
                </div>
                <div class="card-body">
                  <svg width="2em" height="2em" viewBox="0 0 32 32" fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class="">
                  <path
                    d="M16 10L12.2875 6.28751C12.1955 6.19591 12.0862 6.12338 11.9661 6.07404C11.846 6.02471 11.7174 5.99955 11.5875 6.00001H5C4.7348 6.00001 4.4804 6.10536 4.2929 6.2929C4.1054 6.48044 4 6.73479 4 7.00001V25.075C4 25.1965 4.0239 25.3168 4.0704 25.429C4.1169 25.5412 4.185 25.6432 4.2709 25.7291C4.3568 25.815 4.4588 25.8831 4.571 25.9296C4.6832 25.9761 4.8035 26 4.925 26H27.1125C27.3479 26 27.5736 25.9065 27.7401 25.7401C27.9065 25.5736 28 25.3479 28 25.1125V11C28 10.7348 27.8946 10.4804 27.7071 10.2929C27.5196 10.1054 27.2652 10 27 10H16Z"
                    fill="#FBE9B1"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.3461 5.14907C12.1043 5.04975 11.8454 4.99911 11.584 5.00001H5C4.46955 5.00001 3.96076 5.21076 3.58572 5.58588C3.21073 5.96095 3 6.46963 3 7.00001V25.075C3 25.3279 3.04978 25.5783 3.14659 25.8119C3.24335 26.0453 3.38508 26.2575 3.56379 26.4362C3.74251 26.6149 3.95468 26.7567 4.18814 26.8534C4.42173 26.9502 4.67213 27 4.925 27H27.1125C27.613 27 28.093 26.8012 28.447 26.4474C28.8012 26.093 29 25.613 29 25.1125V11C29 10.4696 28.7892 9.96084 28.4142 9.58579C28.0392 9.21075 27.5304 9 27 9H16.4142L12.9946 5.58042C12.8078 5.39445 12.5879 5.2484 12.3461 5.14907ZM15.9939 11C15.998 11 16.002 11 16.0061 11H27V25H5V11H15.9939ZM13.5854 9L11.585 7.00001H5V9H13.5854Z"
                    fill="#B78200"></path>
                </svg>
                </div>
                <div class="card-footer">
                  <img src="assets/ellipsis-v-solid.205x1024.png"  class="file-image" style="max-width: 30px; max-height: 14px;" />
                    <div class="menu-icon">{{ item.title }}</div>
                </div>
            </div>
            </div>

          

      
            <div *ngIf="showH5">
              <div class="send-request">
                <button
                  nz-button
                  nzType="primary"
                  [disabled]="setOfCheckedId.size === 0"
                  [nzLoading]="loading"
                  (click)="sendRequest()"
                >
                  Send Request
                </button>
                <span>Selected {{ setOfCheckedId.size }} items</span>
              </div>
              <nz-card style="width:960px;">
                <nz-table
                #rowSelectionTable
                nzShowPagination
                nzShowSizeChanger
                [nzData]="response"
              >
                <thead>
                  <tr>
                    <th
                      [nzChecked]="checked"
                      [nzIndeterminate]="indeterminate"
                      nzLabel="Select all"
                      (nzCheckedChange)="onAllChecked($event)"
                    ></th>
                    <th>Name</th>
                    <th>Viewable By</th>
                    <th>Modified</th>
                    <th>Added</th>
                    <th>Contents</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of rowSelectionTable.data">
                    <td
                      [nzChecked]="setOfCheckedId.has(data.id)"
                    
                      (nzCheckedChange)="onItemChecked(data.id, $event)"
                    ></td>
                    <td>
                      <img src="assets/opened-folder.256x194.png" alt="PDF file" class="file-image" style="max-width: 30px; max-height: 40px; text-align: center; margin-top: 0px;" (click)="CreateDocumentEmptyComponent()" />
                      {{ data.title }}
                    </td>
                    <td>
                      <div class="text-right" style="display: flex; justify-content: flex-end; gap: 10px;">
                        <span class="ant-tag Tag BTPopover PopoverSpacing">
                          <span role="img" size="16" data-icon-name="BTIcon" class="anticon" style="font-size: 16px;">
                            <svg width="1em" height="1em" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class="">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C16.7709 3 19.9387 3.67145 21.5646 5.15211L21.7125 5.2933L25.7076 9.29337C25.8636 9.44962 25.9627 9.65215 25.9913 9.86867L26 10V10.999L27 11C27.1768 11 27.3488 11.0468 27.4994 11.1336L27.608 11.2061L27.7071 11.2929L29.7071 13.2929C30.0676 13.6534 30.0953 14.2206 29.7903 14.6129L29.7071 14.7071L24.7071 19.7071C24.3466 20.0676 23.7794 20.0953 23.3871 19.7903L23.2929 19.7071L21.2929 17.7071C21.1366 17.5508 21.0374 17.3481 21.0087 17.1314L21 17V15.999L20.425 16L9.22378 27.6981L9.101 27.842L8.97068 27.9791C8.31953 28.628 7.43794 29 6.5 29C4.567 29 3 27.433 3 25.5C3 24.629 3.32081 23.8067 3.88604 23.1724L4.0324 23.0179L4.13356 22.9211L4.23955 22.8311L16.0584 11.5177C16.5159 7.96275 15.2161 6.27148 11.917 5.99655C11.4385 5.95668 11.062 5.58444 11.0069 5.11834L11 5V4C11 3.44772 11.4477 3 12 3ZM16.984 13.399L5.5591 24.3317C5.51846 24.3645 5.47953 24.3994 5.44247 24.4362C5.16043 24.7166 5 25.0943 5 25.5C5 26.3284 5.67157 27 6.5 27C6.85283 27 7.18453 26.8787 7.44951 26.6614L7.58215 26.5387L7.72348 26.3782L18.6 15.015L16.984 13.399ZM16.015 5.33818L15.816 5.194L15.979 5.21339L16.3324 5.26025C18.2972 5.53858 19.6243 6.03272 20.2975 6.7067L24 10.414V12L24.0067 12.1166L24.0264 12.2293C24.1301 12.6711 24.5266 13 25 13L26.584 12.999L27.585 14L24 17.585L23 16.585V15L22.9933 14.8834L22.9736 14.7707C22.8699 14.3289 22.4734 14 22 14L20.414 13.999L18.056 11.641L18.0954 11.2718C18.3323 8.57026 17.6213 6.56013 16.015 5.33818Z"></path>
                            </svg>
                          </span>
                        </span>
                        <span class="ant-tag Tag BTPopover PopoverSpacing">
                          <span role="img" size="16" data-icon-name="BTIcon" class="anticon" style="font-size: 16px;">
                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="1em" height="1em" aria-hidden="true" focusable="false" class="">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.65 3.87a2 2 0 0 1 2.7 0l10 9.09.01.01a2.04 2.04 0 0 1 .64 1.45V26h2a1 1 0 1 1 0 2H2a1 1 0 1 1 0-2h2V14.42a2.04 2.04 0 0 1 .64-1.45l.01-.01 10-9.09ZM14 26h4v-6h-4v6Zm6 0v-6a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v6H6V14.45a.04 0 0 1 0-.02l10-9.08 10 9.08V26h-6Z"></path>
                            </svg>
                          </span>
                        </span>
                      </div>
                    </td>
                    <td>{{ data.createdOn }}</td>
                    <td>{{ data.createdOn }}</td>
                    <td>
                      <span nz-popover nzType="primary"  nzPopoverContent="Files">
                        <img src="assets/file-text-o.385x512.png" alt="PDF file" class="file-image" style="max-width: 25px; max-height: 30px; text-align: center; margin-top: 0px;" (click)="CreateDocumentEmptyComponent()" />
                      </span>
                    </td>
                    <td>
                      <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
                        <img src="assets/ellipsis-v-solid.205x1024.png" alt="PDF file" class="file-image" style="max-width: 25px; max-height: 10px; text-align: center; margin-top: 1px;" />
                      </a>
                      <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul nz-menu>
                          <li nz-menu-item>
                            <span nz-icon nzType="setting" nzTheme="outline"></span>
                   Folder Info
                          </li>
                          <li nz-menu-item>
                            <span nz-icon nzType="edit" nzTheme="outline"></span>
                      Rename
                          </li>
                          <li nz-menu-item>
                            <span nz-icon nzType="download" nzTheme="outline"></span>
                           Download
                          </li>
                          <li nz-menu-item>
                            <span nz-icon nzType="delete" nzTheme="outline"></span>
                      Delete
                          </li>
                        </ul>
                      </nz-dropdown-menu>
                    </td>
                    
                   
                  </tr>
                </tbody>
              </nz-table>
              </nz-card>
             
            </div>
          </div>

          <div class="col-2 mt-0 mb-5 ">


            <nz-card nzHoverable style="width:180px; height: 170px;" [nzCover]="coverTemplate"  >
              <nz-card-meta nzTitle="">
              
              </nz-card-meta>
            </nz-card>
            <ng-template #coverTemplate>
             <div *ngIf="previewUrl" >
            <img *ngIf="isImageFile(previewExtension)" [src]="previewUrl" alt="Image Preview" style="width:100%; height:auto;">
          </div>
            </ng-template>
          </div>
          <div class="col-3" *ngIf="showActivity">
            <nz-card>
                <div class="row text-end">
                    <h1>Activity</h1>
                    <div class="row" *ngFor="let item of response">
                        <div class="row">
                            <div class="col">
                                <span>{{item?.jobInformation?.name}} || {{item?.jobInformation?.createdOn}}</span>
                                <div>
                                    <img src="assets/opened-folder.256x194.png" alt="PDF file" class="file-image" style="max-width: 30px; max-height: 40px; text-align: center; margin-top: 0px;" />
                                    <h4>{{item?.folderName}}</h4>
                                </div>
                                <div class="row ms-2 mt-2 text-end">
                                  <div class="col-12 ms-3 d-flex justify-content-center">
                                    <div class="card-columns">
                                      <div class="card small-card" *ngFor="let attachmentParam of item?.attachment?.attachmentParameters; let i = index">
                                        <div nz-row class="file-icon">
                                          <img *ngIf="isPdfFile(attachmentParam.extension)"  src="assets/file-type-pdf2.252x256.png" alt="PDF file" class="file-image small-image" />
                                          <img *ngIf="isExcelFile(attachmentParam.extension)"  src="assets/file-type-excel.256x239.png" alt="Excel file" class="file-image small-image" />
                                          <img *ngIf="isWordFile(attachmentParam.extension)"  src="assets/file-type-word.256x239.png" alt="Word file" class="file-image small-image" />
                                          <img *ngIf="isTxtFile(attachmentParam.extension)" src="assets/file-type-txt-text-textedit.214x256.png" alt="Text file" class="file-image small-image" />
                                          <img *ngIf="isImageFile(attachmentParam.extension)" [src]="getImageUrl(attachmentParam)" alt="Image file" class="file-image small-image" (click)="openImagePreview(attachmentParam)" />
                                          <img (click)="openVideo(attachmentParam)" *ngIf="isVideoFile(attachmentParam.extension)" src="assets/video.512x436.png" alt="Video file" class="file-image small-image" />
                                          <span class="font-size">{{ attachmentParam.name }}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              
                               
                                
                                <!-- File Preview Section -->
                              
                                
                                

                              
                            </div>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        </div>


        
        <div class="row"   *ngIf="!response || response.length === 0">
          <div class="row mt-5" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
            <div class="col" style="text-align: center;">
               <span>
                <!-- <i  class="bi bi-card-image" ></i> -->
                <img src="assets/Empty-SVGs/Files/Photos/Photos.svg" style="font-size: 70px;">
               </span>        
                <div class="row">
                    <div class="col mt-2">
                <h2>No Folder</h2>
                    </div>
                </div>
                <div class="col mt-3">
                <p>Create a folder to start adding files</p>
                </div>
            </div>
            <div class="col mt-4" style="display: flex; justify-content: center; align-items: center;">
              <nz-space>
                <a   *nzSpaceItem nz-dropdown nz-button nzTrigger="click"  [nzDropdownMenu]="menu" >
                  <span
                  nz-popover
                  class="text-primary text-dark fw-bold fs-5"
                  nz-icon
                  nzType="ellipsis"
                  nzTheme="outline"
                ></span>
              </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  
                  <ul nz-menu>
                    <li nz-menu-item><i class="bi bi-copy me-2 mt-2"></i> Import Folders</li>
                
                  </ul>
                </nz-dropdown-menu>
                <button  *nzSpaceItem  nz-button  nzType="default" ><i class="bi bi-box-arrow-up-right me-2"></i>Learn How</button>
              </nz-space>
            </div>
          </div> 
          <div class="row mt-2" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
            <div class="col" style="text-align: center;">
              <h4>
                Get organized quickly with recommended folders
              </h4>
              <p>
                Opt into our recommended file structure to quickly set up necessary folders and start adding documents.
              </p>
              <label nz-checkbox [(ngModel)]="checked">Select All</label>
            </div>
          </div>
          <div class="row mt-2" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
          <div class="col-6" style="display: flex; flex-direction: column; text-align: center; align-items: center; justify-content: center;">


            
            <nz-card style="width:400px; height: 70px; border: 1px solid lightgrey; margin-top: 4px;">
              <div class="d-flex justify-content-between align-items-center" style="height: 100%;">
                <label nz-checkbox [(ngModel)]="checked">
                  <svg width="2em" height="2em" viewBox="0 0 32 32" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class="">
                    <path
                      d="M16 10L12.2875 6.28751C12.1955 6.19591 12.0862 6.12338 11.9661 6.07404C11.846 6.02471 11.7174 5.99955 11.5875 6.00001H5C4.7348 6.00001 4.4804 6.10536 4.2929 6.2929C4.1054 6.48044 4 6.73479 4 7.00001V25.075C4 25.1965 4.0239 25.3168 4.0704 25.429C4.1169 25.5412 4.185 25.6432 4.2709 25.7291C4.3568 25.815 4.4588 25.8831 4.571 25.9296C4.6832 25.9761 4.8035 26 4.925 26H27.1125C27.3479 26 27.5736 25.9065 27.7401 25.7401C27.9065 25.5736 28 25.3479 28 25.1125V11C28 10.7348 27.8946 10.4804 27.7071 10.2929C27.5196 10.1054 27.2652 10 27 10H16Z"
                      fill="#FBE9B1"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12.3461 5.14907C12.1043 5.04975 11.8454 4.99911 11.584 5.00001H5C4.46955 5.00001 3.96076 5.21076 3.58572 5.58588C3.21073 5.96095 3 6.46963 3 7.00001V25.075C3 25.3279 3.04978 25.5783 3.14659 25.8119C3.24335 26.0453 3.38508 26.2575 3.56379 26.4362C3.74251 26.6149 3.95468 26.7567 4.18814 26.8534C4.42173 26.9502 4.67213 27 4.925 27H27.1125C27.613 27 28.093 26.8012 28.447 26.4474C28.8012 26.093 29 25.613 29 25.1125V11C29 10.4696 28.7892 9.96084 28.4142 9.58579C28.0392 9.21075 27.5304 9 27 9H16.4142L12.9946 5.58042C12.8078 5.39445 12.5879 5.2484 12.3461 5.14907ZM15.9939 11C15.998 11 16.002 11 16.0061 11H27V25H5V11H15.9939ZM13.5854 9L11.585 7.00001H5V9H13.5854Z"
                      fill="#B78200"></path>
                  </svg>
                  Checkbox
                </label>
                <p class="permissionCard mb-0">Subs/Vendors</p>
              </div>
            </nz-card>
            <nz-card style="width:400px; height: 70px; border: 1px solid lightgrey; margin-top: 4px;">
              <div class="d-flex justify-content-between align-items-center" style="height: 100%;">
                <label nz-checkbox [(ngModel)]="checked">
                  <svg width="2em" height="2em" viewBox="0 0 32 32" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class="">
                    <path
                      d="M16 10L12.2875 6.28751C12.1955 6.19591 12.0862 6.12338 11.9661 6.07404C11.846 6.02471 11.7174 5.99955 11.5875 6.00001H5C4.7348 6.00001 4.4804 6.10536 4.2929 6.2929C4.1054 6.48044 4 6.73479 4 7.00001V25.075C4 25.1965 4.0239 25.3168 4.0704 25.429C4.1169 25.5412 4.185 25.6432 4.2709 25.7291C4.3568 25.815 4.4588 25.8831 4.571 25.9296C4.6832 25.9761 4.8035 26 4.925 26H27.1125C27.3479 26 27.5736 25.9065 27.7401 25.7401C27.9065 25.5736 28 25.3479 28 25.1125V11C28 10.7348 27.8946 10.4804 27.7071 10.2929C27.5196 10.1054 27.2652 10 27 10H16Z"
                      fill="#FBE9B1"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12.3461 5.14907C12.1043 5.04975 11.8454 4.99911 11.584 5.00001H5C4.46955 5.00001 3.96076 5.21076 3.58572 5.58588C3.21073 5.96095 3 6.46963 3 7.00001V25.075C3 25.3279 3.04978 25.5783 3.14659 25.8119C3.24335 26.0453 3.38508 26.2575 3.56379 26.4362C3.74251 26.6149 3.95468 26.7567 4.18814 26.8534C4.42173 26.9502 4.67213 27 4.925 27H27.1125C27.613 27 28.093 26.8012 28.447 26.4474C28.8012 26.093 29 25.613 29 25.1125V11C29 10.4696 28.7892 9.96084 28.4142 9.58579C28.0392 9.21075 27.5304 9 27 9H16.4142L12.9946 5.58042C12.8078 5.39445 12.5879 5.2484 12.3461 5.14907ZM15.9939 11C15.998 11 16.002 11 16.0061 11H27V25H5V11H15.9939ZM13.5854 9L11.585 7.00001H5V9H13.5854Z"
                      fill="#B78200"></path>
                  </svg>
                  Checkbox
                </label>
                <p class="permissionCard mb-0">Subs/Vendors</p>
              </div>
            </nz-card>
            <nz-card style="width:400px; height: 70px; border: 1px solid lightgrey; margin-top: 4px;">
              <div class="d-flex justify-content-between align-items-center" style="height: 100%;">
                <label nz-checkbox [(ngModel)]="checked">
                  <svg width="2em" height="2em" viewBox="0 0 32 32" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class="">
                    <path
                      d="M16 10L12.2875 6.28751C12.1955 6.19591 12.0862 6.12338 11.9661 6.07404C11.846 6.02471 11.7174 5.99955 11.5875 6.00001H5C4.7348 6.00001 4.4804 6.10536 4.2929 6.2929C4.1054 6.48044 4 6.73479 4 7.00001V25.075C4 25.1965 4.0239 25.3168 4.0704 25.429C4.1169 25.5412 4.185 25.6432 4.2709 25.7291C4.3568 25.815 4.4588 25.8831 4.571 25.9296C4.6832 25.9761 4.8035 26 4.925 26H27.1125C27.3479 26 27.5736 25.9065 27.7401 25.7401C27.9065 25.5736 28 25.3479 28 25.1125V11C28 10.7348 27.8946 10.4804 27.7071 10.2929C27.5196 10.1054 27.2652 10 27 10H16Z"
                      fill="#FBE9B1"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12.3461 5.14907C12.1043 5.04975 11.8454 4.99911 11.584 5.00001H5C4.46955 5.00001 3.96076 5.21076 3.58572 5.58588C3.21073 5.96095 3 6.46963 3 7.00001V25.075C3 25.3279 3.04978 25.5783 3.14659 25.8119C3.24335 26.0453 3.38508 26.2575 3.56379 26.4362C3.74251 26.6149 3.95468 26.7567 4.18814 26.8534C4.42173 26.9502 4.67213 27 4.925 27H27.1125C27.613 27 28.093 26.8012 28.447 26.4474C28.8012 26.093 29 25.613 29 25.1125V11C29 10.4696 28.7892 9.96084 28.4142 9.58579C28.0392 9.21075 27.5304 9 27 9H16.4142L12.9946 5.58042C12.8078 5.39445 12.5879 5.2484 12.3461 5.14907ZM15.9939 11C15.998 11 16.002 11 16.0061 11H27V25H5V11H15.9939ZM13.5854 9L11.585 7.00001H5V9H13.5854Z"
                      fill="#B78200"></path>
                  </svg>
                  Checkbox
                </label>
                <p class="permissionCard mb-0">Subs/Vendors</p>
              </div>
            </nz-card>
            <div class="row">
              <div class="col text-center mt-2 me-5 ">
                <button class="text-center" nz-button nzType="primary" >Add Button</button>
              </div>
            </div>
          </div>
          </div>
        </div>


     
     

   
        
     

      </div>

      <ng-template #actionEllipsis>
        <div style="display: flex; flex-direction: column; align-items: center; border: 1px solid lightskyblue;">
          <img src="assets/ellipsis-v-solid.205x1024.png" alt="PDF file" class="file-image" style="text-align: center; margin-top: 1px;" />
        </div>
      </ng-template>
    </nz-content>
  </nz-layout>
</nz-layout>
