import { CostCode } from './../../../../../Models/CostCode/costCode';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RoleTypeService } from 'src/Service/Internaluser/role-type.service';
import { FieldDataType } from 'src/app/Core/Enums/data_types';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { AddNewStandardFilterComponent } from '../../Modal/add-new-standard-filter/add-new-standard-filter.component';
import { CreateLookupFilterRequest, LookupFilterResponse, LookUpStandardResponse, LookupFilter } from '../../Models/LookupModels';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { PurchaseOrderPridefinedCodes, TenderPriDefinedCodes } from '../../Models/LookUpStandardFilterCodes';
import { LookupNameSetupResponse } from 'src/Models/All-Filters/financialBillsFilterField';
import { InterUserService } from 'src/Service/inter-user.service';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { LeadActivitiesService } from 'src/Service/Sales-Lead-Activities/lead-activities.service';

@Component({
  selector: 'app-financial-purchase-order-filter-field',
  templateUrl: './financial-purchase-order-filter-field.component.html',
  styleUrls: ['./financial-purchase-order-filter-field.component.css']
})
export class FinancialPurchaseOrderFilterFieldComponent implements OnInit {

  selectedValue: any;
  listOfOption: Array<{ label: string; value: string }> = [];
  listOfTagOptions = [];
  @Input() pageId: number;
  financialpurchaseorderFilterForm: FormGroup;
  createLookupFilterRequests: CreateLookupFilterRequest[] = [];
  filterResponseData: LookupFilterResponse[] = [];
  lookUpStandardResponse: LookUpStandardResponse[] = [];
  defaultStandardFilter: LookupFilterResponse;
  predefinedValuesMultiSelect: string[] = [];


  preDefinedDataCodes = {
    standardFilter: PurchaseOrderPridefinedCodes.standardFilter,
    WorkStatus: PurchaseOrderPridefinedCodes.WorkStatus,
    WorkCompleteDate: PurchaseOrderPridefinedCodes.WorkCompleteDate,
    PaymentStatus: PurchaseOrderPridefinedCodes.PaymentStatus,
    POStatus: PurchaseOrderPridefinedCodes.POStatus,
    MaterialsLabor: PurchaseOrderPridefinedCodes.MaterialsLabor,
    VarianceCode: PurchaseOrderPridefinedCodes.VarianceCode,
    CreatedDate: PurchaseOrderPridefinedCodes.CreatedDate,
    EstCompletionDate: PurchaseOrderPridefinedCodes.EstCompletionDate,
    Costcode: PurchaseOrderPridefinedCodes.Costcode,
  };



  formFieldValues: any = {};
  selectedFilter: number= -1;

  constructor(
    private roleService: RoleTypeService,
    private fb: FormBuilder,
    public toastService: NzMessageService,
    private cdr: ChangeDetectorRef,
    private loadingIndicatoreService: LoadingIndicatorService,
    private modal: NzModalService,
    private LookupFilterService: LookupFilterService,
    private internalUserService: InterUserService,
    private leadActivitiesService: LeadActivitiesService,
  ) { }
  ngOnInit(): void {


// this.onWorkStatusChange(this.selectedValue )th
this.ShowAddDate();
    this.initLoad();



  }
  initLoad() {






    const lookupFilter: LookupFilter = {
      formNameId: this.pageId,
    };
    this.getStandardFilterByFormPageId(this.pageId);
    this.fetchData(lookupFilter);
  }
  fetchData(lookUpStandard: LookupFilter): void {
    this.LookupFilterService.postData(lookUpStandard).subscribe(
      data => {
        this.lookUpStandardResponse = data.result;
      },
      error => {
        console.error('Error fetching data:', error);
      }
    );



  }


  ShowAddDate() {



    const formNameId = 15;
    this.internalUserService.showCode(formNameId).subscribe(
      (res: ResponseModelArray<LookupNameSetupResponse>) => {
        const workdata = res.result.filter(item => item.code === PurchaseOrderPridefinedCodes.WorkStatus.code);
        const WorkCompleteDate = res.result.filter(item => item.code === PurchaseOrderPridefinedCodes.WorkCompleteDate.code);
        const POData = res.result.filter(item => item.code === PurchaseOrderPridefinedCodes.POType.code);
        const POStatus = res.result.filter(item => item.code === PurchaseOrderPridefinedCodes.POType.code);
        const materialData = res.result.filter(item => item.code === PurchaseOrderPridefinedCodes.POStatus.code);
        const createData = res.result.filter(item => item.code === PurchaseOrderPridefinedCodes.CreatedDate.code);
        const estcompData = res.result.filter(item => item.code === PurchaseOrderPridefinedCodes.EstCompletionDate.code);
         const statusData = res.result.filter(item => item.code === PurchaseOrderPridefinedCodes.PaymentStatus.code);
        const contactTypeData = res.result.filter(item => item.code === PurchaseOrderPridefinedCodes.CreatedDate.code);
        const Variance = res.result.filter(item => item.code === PurchaseOrderPridefinedCodes.VarianceCode.code);
        const Costcode = res.result.filter(item => item.code === PurchaseOrderPridefinedCodes.Costcode.code);











        this.workStatusNodes = [
          {
            title: 'Select All',
            value: 'select_all',
            key: 'select_all',
            selectable: false,
            isLeaf: false,
            expanded: true,
            children:workdata.map((work) => ({
              title: work.name,
              value: work.id.toString(),
              key: work.id.toString(),
              isLeaf: true
            }))
          }
        ];

 this.PaymentStatusNodes = [
          {
            title: 'Select All',
            value: 'select_all',
            key: 'select_all',
            selectable: false,
            isLeaf: false,
            expanded: true,
            children: statusData.map((paymentStatus) => ({
              title: paymentStatus.name,
              value: paymentStatus.id.toString(),
              key: paymentStatus.id.toString(),
              isLeaf: true
            }))
          }
        ];



        this.POStatusNodes = [
          {
            title: 'Select All',
            value: 'select_all',
            key: 'select_all',
            selectable: false,
            isLeaf: false,
            expanded: true,
            children: POStatus.map((pOStatus) => ({
              title: pOStatus.name,
              value: pOStatus.id.toString(),
              key: pOStatus.id.toString(),
              isLeaf: true
            }))
          }
        ];
        this.PerformbyNodes = [
          {
            title: 'Select All',
            value: 'select_all',
            key: 'select_all',
            selectable: false,
            isLeaf: false,
            expanded: true,
            children: res.result.map((status) => ({
              title: status.name,
              value: status.id.toString(),
              key: status.id.toString(),
              isLeaf: true
            }))
          }
        ];







        this.CostCodeNodes = [
          {
            title: 'Select All',
            value: 'select_all',
            key: 'select_all',
            selectable: false,
            isLeaf: false,
            expanded: true,
            children: Costcode.map((code) => ({
              title: code.name,
              value: code.id.toString(),
              key: code.id.toString(),
              isLeaf: true
            }))
          }
        ];
        this.VarianceNodes = [
          {
            title: 'Select All',
            value: 'select_all',
            key: 'select_all',
            selectable: false,
            isLeaf: false,
            expanded: true,
            children: Variance.map((varii) => ({
              title: varii.name,
              value: varii.id.toString(),
              key: varii.id.toString(),
              isLeaf: true
            }))
          }
        ];



        this.WorkCompleteDate = WorkCompleteDate.map(WorkCompleteDate => ({
          label: WorkCompleteDate.description,
          value: WorkCompleteDate.id
        }));

        this.POType = POData.map(POType => ({
          label: POType.description,
          value: POType.id
        }));


        this.MaterialsLabor = materialData.map(MaterialsLabor => ({
          label: MaterialsLabor.description,
          value: MaterialsLabor.id
        }));


        this.CreatedDate = createData.map(CreatedDate => ({
          label: CreatedDate.description,
          value: CreatedDate.id
        }));

        this.EstCompletionDate = estcompData.map(EstCompletionDate => ({
          label: EstCompletionDate.description,
          value: EstCompletionDate.id
        }));
      },

      (error) => {
        console.error('Error fetching keyword options:', error);
      }
    );

  }




  openStandardFilterComponent() {
    this.createFormFieldsInArray();
    const modalRef = this.modal.create({
      nzContent: AddNewStandardFilterComponent,
      nzData:
      {
        pageId: this.pageId,
        std: this.filterResponseData,
        field: this.createLookupFilterRequests
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  getStandardFilterByFormPageId(pageId: number) {
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.filterResponseData = result.result;
        console.log("RESULT STANDARD FILTER : ", this.filterResponseData);
        this.initializeForm();
        this.setDefaultFieldValues(this.defaultStandardFilter.id);
      }
    });
  }
  // initializeForm(): void {
  //   this.financialpurchaseorderFilterForm = this.fb.group({
  //     standardFilter: new FormControl(this.getDefaultFilterValue()),
  //     title: [null],
  //     workStatus: [[null]],
  //     workComplete: [null],
  //     paymentStatus: [[null]],
  //     pOStatus: [[null]],
  //     performedBy: [[null]],
  //     pOType: [null],
  //     materialsLabor: [null],
  //     costCode: [[null]],
  //     varianceCode: [[null]],
  //     createdDate: [[null]],
  //     estCompletion: [null],
  //   });
  // }
  onSubmit(): void {
    this.createFormFieldsInArray();
  }
  resetFilter(): void {
    const formControlKeys = Object.keys(this.financialpurchaseorderFilterForm.controls).slice(1);
    formControlKeys.forEach(field => {
      this.financialpurchaseorderFilterForm.get(field).reset();
    });
  }
  createFormFieldsInArray() {
    this.createLookupFilterRequests = [];
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const value = this.financialpurchaseorderFilterForm.get(field).value;
      if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 1 && value[0] === null)) {
        const newRow = {
          companyParameterId: 0,
          lookUpStandardId: 0,
          code: code,
          meaning: '',
          valueId: 0,
          formNameId: this.pageId,
          dataTypeId: 0,
          createLookupFilterParameterRequests: [null]
        };
        const correspondingResponse = this.lookUpStandardResponse.find(response => response.code === code);
        if (correspondingResponse) {
          newRow.companyParameterId = correspondingResponse.companyParameterId;
          newRow.lookUpStandardId = correspondingResponse.id;
          newRow.dataTypeId = correspondingResponse.dataTypeId;
          if (
            correspondingResponse.dataType.code === FieldDataType.SingleLineText ||
            correspondingResponse.dataType.name === 'Single-Line Text') {
            newRow.meaning = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.Dropdown ||
            correspondingResponse.dataType.name === 'Dropdown') {
            newRow.valueId = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.MultiSelectDropdown ||
            correspondingResponse.dataType.name === 'Multi-Select Dropdown'
          ) {
            newRow.createLookupFilterParameterRequests = value.map((item, index) => {
              return { valueId: item };
            });
          }
          // Assign other properties similarly based on your data structure
        }
        this.createLookupFilterRequests.push(newRow);
      }
    });
  }
  getDefaultFilterValue() {
    this.defaultStandardFilter = this.filterResponseData.find(filter => filter.isSetAsDefault === true);
    return this.defaultStandardFilter ? this.defaultStandardFilter.id : null;
  }
  setDefaultFieldValues(standardFilterId: number): void {
    this.predefinedValuesMultiSelect = [];
    let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
    let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;
    // Get all form control keys except the first one
    const formControlKeys = Object.keys(this.financialpurchaseorderFilterForm.controls).slice(1);
    // Reset all form controls except the first one
    formControlKeys.forEach(field => {
      this.financialpurchaseorderFilterForm.get(field).reset(); // Reset the form control
    });
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const fieldData = lookUpFiltersField.find(filter => filter.code === code);
      if (fieldData != undefined) {
        if (
          fieldData.dataType.code === FieldDataType.SingleLineText) {
          this.financialpurchaseorderFilterForm.get(field).setValue(fieldData.meaning);
        }
        if (fieldData.dataType.code === FieldDataType.Dropdown) {
          this.financialpurchaseorderFilterForm.get(field).setValue(fieldData.id);
        }
        if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown) {
          let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
          this.predefinedValuesMultiSelect = multiSelectValues.map(String);
          this.financialpurchaseorderFilterForm.get(field).setValue(this.predefinedValuesMultiSelect);
        }
      }
    });
  }
  onChangeStandardFilter($event: number): void {
    this.setDefaultFieldValues($event);
  }

  selectedDateType: string | null = null; // Initialized to null or a default value
  dateFormat: string = 'yyyy-MM-dd';


  handleLeadSelection(value: any): void {
    this.selectedDateType = value;

  }


  workStatusNodes: NzTreeNodeOptions[] = [];
  PaymentStatusNodes: NzTreeNodeOptions[] = [];
  PerformbyNodes: NzTreeNodeOptions[] = [];
  POStatusNodes: NzTreeNodeOptions[] = [];
  CostCodeNodes: NzTreeNodeOptions[] = [];
  VarianceNodes: NzTreeNodeOptions[] = [];
  roles: { id: string, name: string }[] = [];

  // showContactTypes() {
  //   const formNameId = 15;
  //   this.internalUserService.showCode(formNameId).subscribe(
  //     (res: ResponseModelArray<LookupNameSetupResponse>) => {
  //       const contactTypeData = res.result.filter(item => item.code === PurchaseOrderPridefinedCodes.WorkStatus.code);
  //       const statusData = res.result.filter(item => item.code === PurchaseOrderPridefinedCodes.PaymentStatus.code);
  //       const budgetData = res.result.filter(item => item.code === PurchaseOrderPridefinedCodes.POStatus.code);

  //       this.workStatusNodes = contactTypeData.map(workStatus => ({
  //         title: workStatus.description,
  //         value: workStatus.id,
  //         key: workStatus.id.toString(),
  //         isLeaf: true

  //       }));









  //     },

  //     (error) => {
  //       console.error('Error fetching contact types:', error);
  //     }
  //   );
  // }



  WorkCompleteDate: { label: string, value: number }[] = [];
  POType: { label: string, value: number }[] = [];
  MaterialsLabor: { label: string, value: number }[] = [];
  CreatedDate: { label: string, value: number }[] = [];
  EstCompletionDate: { label: string, value: number }[] = [];




  LookupNameSetupResponse: LookupNameSetupResponse[] = [];


  onWorkStatusChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.workStatusNodes[0].children.map(child => child['value']);
      this.formFieldValues['workStatus'] = [...allValues];
    } else {
      const allValues = this.workStatusNodes[0].children.map(child => child['value']);
      this.formFieldValues['workStatus'] = selectedValues.filter(value => allValues.includes(value));
    }
  }



  onpaymentStatusChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.PaymentStatusNodes[0].children.map(child => child['value']);
      this.formFieldValues['paymentStatus'] = [...allValues];
    } else {
      const allValues = this.PaymentStatusNodes[0].children.map(child => child['value']);
      this.formFieldValues['paymentStatus'] = selectedValues.filter(value => allValues.includes(value));
    }
  }

  onpOStatusChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.POStatusNodes[0].children.map(child => child['value']);
      this.formFieldValues['pOStatus'] = [...allValues];
    } else {
      const allValues = this.POStatusNodes[0].children.map(child => child['value']);
      this.formFieldValues['pOStatus'] = selectedValues.filter(value => allValues.includes(value));
    }
  }

  onperformedByChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.PerformbyNodes[0].children.map(child => child['value']);
      this.formFieldValues['performedBy'] = [...allValues];
    } else {
      const allValues = this.PerformbyNodes[0].children.map(child => child['value']);
      this.formFieldValues['performedBy'] = selectedValues.filter(value => allValues.includes(value));
    }
  }
  onvarianceCodeChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.VarianceNodes[0].children.map(child => child['value']);
      this.formFieldValues['varianceCode'] = [...allValues];
    } else {
      const allValues = this.VarianceNodes[0].children.map(child => child['value']);
      this.formFieldValues['varianceCode'] = selectedValues.filter(value => allValues.includes(value));
    }
  }
  oncostCodeChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.CostCodeNodes[0].children.map(child => child['value']);
      this.formFieldValues['costCode'] = [...allValues];
    } else {
      const allValues = this.CostCodeNodes[0].children.map(child => child['value']);
      this.formFieldValues['costCode'] = selectedValues.filter(value => allValues.includes(value));
    }
  }

  onFilterApply(): void {
    let selectedFilter = this.formFieldValues['standardFilter'];
    let isSelectedFilterStandard = this.filterResponseData.find(x => x.id === selectedFilter && x.isNotDeleted === true);
    if (isSelectedFilterStandard && isSelectedFilterStandard != undefined) {
      this.selectedFilter = 1;
    }
    else {
      this.selectedFilter = 0;
    }
    this.createFormFieldsInArray();
    this.createLookupFilterRequests[0].page = 1;
    this.createLookupFilterRequests[0].pageSize = 10;

    this.leadActivitiesService.getAppliedFilterData(this.createLookupFilterRequests);


  }

  initializeForm(): void {
    this.formFieldValues = {
      standardFilter: this.getDefaultFilterValue(),
      title: '',
      workStatus:[] = [],
      workComplete: '',
      paymentStatus: [] = [],
      pOStatus: [] = [],
      performedBy: [] = [],
      pOType: '',
      materialsLabor: '',
      costCode: [] = [],
      varianceCode: [] = [],
      createdDate: '',
      estCompletion: '',
    };
  }

}


















