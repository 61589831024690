import {Component, EventEmitter, Output} from '@angular/core';
@Component({
  selector: 'app-photo-draw',
  templateUrl: './photo-draw.component.html',
  styleUrls: ['./photo-draw.component.css']
})
export class PhotoDrawComponent {
  @Output() cancel = new EventEmitter<void>();
  unvisible = true;
  close(): void {
    this.cancel.emit();
    this.unvisible = false;
  }
}

