import { CostItem, CostItemParameterResponse, CreateCostItemParameterRequest, costItemParameter } from './../../../../Models/LeadProposal/LeadProposalModels';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { ResponseModel } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment.prod';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root'
})
export class CostItemService {
    private PROJECT_MANAGMENT_URL: string = environment.apiUrl + 'CostItem/';
    public toastsService: NzMessageService

  constructor(private http: HttpClient) { }

  postCostItem(jobInformationFormData: CreateCostItemParameterRequest): Observable<ResponseModel<CostItemParameterResponse>> {
    const apiUrl = `${this.PROJECT_MANAGMENT_URL}`;
    return this.http.post<any>(apiUrl, jobInformationFormData).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

 
  
}
