import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ProjectManegmentSiteDiriesPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { ChangeOrderGetById } from 'src/Models/ChangeOrder/ChangeOrder';
import { JobInformationResponse } from 'src/Models/Job-Scratch/JobFromScratch';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { ProjectManagementSiteDiariesAddTagComponent } from '../project-management-site-diaries-add-tag/project-management-site-diaries-add-tag.component';
import { AttachmentResponse } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { AuditDetailsResponse, FilterFooterAudit } from 'src/Models/Footer/footer.model';
import { FooterService } from 'src/Service/Footer/footer.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { saveAttachments } from 'src/app/helpers/Attachment_Save';
import { SiteDiariesService } from 'src/Service/Project-Management/site-diaries.service';
import { DailyLogInformationResponse, DailyLogResult } from 'src/Models/Project-Management/projectManagement';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';



interface CustomUploadFile extends NzUploadFile {
  loading?: boolean;
  error?: boolean;
  addedBy: number
  createdOn: Date;
  modifiedOn: Date;
  id: number;
  globalId: string;
}
@Component({
  selector: 'app-project-management-site-diaries-new-daily-log',
  templateUrl: './project-management-site-diaries-new-daily-log.component.html',
  styleUrls: ['./project-management-site-diaries-new-daily-log.component.css']
})


export class ProjectManagementSiteDiariesNewDailyLogComponent implements OnInit {
  DailyLogForm: FormGroup;
  dailyLogVisible = true;
  @Output() cancel = new EventEmitter<void>();
  tagNodes: NzTreeNodeOptions[] = [];
  jobList: JobInformationResponse[] = [];
  pageId: number = 5;
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  TestAttachment: AttachmentResponse;
  selectedFilesAttachment: CustomUploadFile[] = [];
  tagLoaded: any;
  tagResponse: any;
  isSingleTagSelected: boolean = false;
  isDeleteLoading: boolean = false;
  // Selected Job and Name site dairies Dashboard
  selectedJobId: number;
  selectedJobName: string;
  footerResponse: AuditDetailsResponse;
  dailyLogResponse: DailyLogResult;
  // Selected Job and Name site dairies Dashboard
  confirmModal?: NzModalRef;
  preDefinedDataCodes = {
    tags: ProjectManegmentSiteDiriesPreDefinedCodes.Tags,
  };

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      weekday: 'short', // Mon
      year: 'numeric',   // 2024
      month: 'short',    // Oct
      day: 'numeric',    // 7
      hour: 'numeric',   // 5
      minute: 'numeric', // 18
      hour12: true       // PM format
    });
  }


  constructor(private fb: FormBuilder,
    private http: HttpClient,
    private LookupFilterService: LookupFilterService,
    private jobsService: JobInformationService,
    private _AttachmentService: AttachmentService,
    private modal: NzModalService,
    private _footerService: FooterService,
    private _toastService: NzMessageService,
    private _siteDiariesDailyLogService: SiteDiariesService,
    private _loadingIndicatorService: LoadingIndicatorService,

  ) { }



  ngOnInit(): void {
    this._loadingIndicatorService.show();
    this.InitializeForm();
    Promise.all([this.initLoad(), this.setupFormValueChangeHandler()])
      .then(() => {
        this._loadingIndicatorService.hide();
      })
      .catch((error) => {
        this._loadingIndicatorService.hide();
      });

    this.DailyLogForm.statusChanges.subscribe(() => {
      if (this.hasAttemptedSubmit) {
        this.errorMessages = this.getFormValidationErrors();
      }
    });
  }

  InitializeForm() {
    this.DailyLogForm = this.fb.group({
      id: [0],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      jobSetupId: [0],
      jobInformationId: [this.selectedJobId],
      date: [new Date(), Validators.required],
      title: ['', [Validators.maxLength(50)]],
      notes: ['', Validators.maxLength(4000)],
      isIncludeWeatherConditions: [false],
      isIncludeWeatherNotes: [false],
      weatherNotes: ['', Validators.maxLength(512)],
      weatherId: [0],
      weatherName: '',
      startDegreeCentigrade: [null],
      endDegreeCentigrade: [null],
      wind: [''],
      humidity: [''],
      totalPrecipitation: [''],
      weatherOn: [null],
      isPermissionInternalUser: [false],
      isPermissionSubVendor: [false],
      isPermissionOwner: [false],
      isPermissionPrivate: [false],
      createDailyLogTagParameterRequests: [null],
      createDailyLogNotifyUserRequest: this.fb.array([]),
    });
  }

  initLoad(): Promise<void> {
    return new Promise((resolve, reject) => {
      // Fetch lookup values
      this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(
        (res) => {
          this.lookupSetNameResponse = res.result;

          // -----------Multi Select --------------
          this.tagResponse = res.result.filter(
            (x) => x.code === this.preDefinedDataCodes.tags.code
          );

          // -----------Single Select --------------
          this.tagNodes = [
            {
              title: 'Select All',
              value: 'select_all',
              key: 'select_all',
              selectable: false,
              isLeaf: false,
              expanded: true,
              children: this.tagResponse.map((tag) => ({
                title: tag.name,
                value: tag.id.toString(),
                key: tag.id.toString(),
                isLeaf: true,
              })),
            },
          ];

          resolve();
        },
        (error) => {
          console.error('Error loading lookup values:', error);
          reject(error);
        }
      );

      // Fetch job data
      this.jobsService.getAllLeadData().subscribe(
        (res: any) => {
          this.jobList = res.result;

          resolve();
        },
        (error) => {
          console.error('Error loading job data:', error);
          reject(error);
        }
      );
    });
  }







  // Aisha _____ Attachment Work
  fetchAttachments(ChangeOrderInfoResponse: ChangeOrderGetById) {
    if (ChangeOrderInfoResponse?.AttachmentId) {
      this._AttachmentService.getLeadByAttachmentId(ChangeOrderInfoResponse?.AttachmentId).subscribe(
        (res) => {
          this.TestAttachment = res.result
        },
      );
    } else {
      this.TestAttachment = undefined;
    }
  }

  onFilesUploaded(files: CustomUploadFile[]): void {
    this.selectedFilesAttachment = files
  }


  checkGetWeather() {
    // Access the reactive form control using `this.DailyLogForm.get()`
    if (this.DailyLogForm.get('isIncludeWeatherConditions')?.value === true) {
      this.getWeather(); // Call the method that fetches the weather
    }
  }

  onCheckboxChange2() {
    // Instead of managing the state manually, rely on the form control value
    const isChecked = this.DailyLogForm.get('isIncludeWeatherNotes')?.value;
    this.onCheckboxChange2 = isChecked;
  }



  weatherData: any; // To store the full response
  wind: number | undefined;
  precipitation: number | undefined; humidity: number = 0; // To store humidity
  conditionText: string = '';
  isLoadingWeather = false;
  getWeather(): void {
    const city = 'Karachi'; // Example city, you can replace it with dynamic input
    const apiKey = '47c32bf7d8msh56a6e680e754763p155991jsn80a529846e81';
    const apiUrl = `https://weatherapi-com.p.rapidapi.com/current.json?q=${city}`;

    const headers = new HttpHeaders({
      'x-rapidapi-key': apiKey,
      'x-rapidapi-host': 'weatherapi-com.p.rapidapi.com'
    });

    this.isLoadingWeather = true;

    this.http.get(apiUrl, { headers }).subscribe(
      (data) => {
        this.weatherData = data;
        this.isLoadingWeather = false; // Set loading state to false on error
        // Extract weather data
        const wind = this.weatherData?.current?.wind_kph;
        const precipitation = this.weatherData?.current?.precip_mm;
        const humidity = this.weatherData?.current?.humidity;
        const conditionText = this.weatherData?.current?.condition.text;
        const weatherOn = this.weatherData?.current?.last_updated;
        const startDegreeCentigrade = this.weatherData?.current?.temp_c;
        const endDegreeCentigrade = this.weatherData?.current?.feelslike_c;
        const weatherName = this.weatherData?.current?.condition?.text;

        // Patch values to the form

        const weatherOnDate = new Date(weatherOn.replace(" ", "T") + ":00");
        this.DailyLogForm.patchValue({


          wind: wind.toString(),
          humidity: humidity.toString(),
          totalPrecipitation: precipitation.toString(),
          weatherOn: weatherOnDate,  // Save as Date object
          startDegreeCentigrade: startDegreeCentigrade.toString(),
          endDegreeCentigrade: endDegreeCentigrade.toString(),
          weatherName: weatherName.toString(),
          conditionText: conditionText.toString(),

        });
      },
      (error) => {
        this.isLoadingWeather = false; // Set loading state to false on error
        console.error('Error fetching weather data', error);
      }
    );
  }








  createAndModifiedByData(id: number) {
    const payload: FilterFooterAudit = {
      id: id,
      formCode: 'PMSD'
    }
    this._footerService.createdAndModifyUser(payload).subscribe(res => {
      this.footerResponse = res.result;
    })
  }




  // aisha tags work



  onTagChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('check_all');

    if (checkAllIndex !== -1) {
      const allValues = this.tagNodes[0].children.map(child => child['value']);
      this.DailyLogForm.get('createDailyLogTagParameterRequests')?.setValue(allValues);
      this.isSingleTagSelected = false; // Reset the single tag selection flag
    } else {
      const allValues = this.tagNodes[0].children.map(child => child['value']);
      const filteredValues = selectedValues.filter(value => allValues.includes(value));
      this.DailyLogForm.get('createDailyLogTagParameterRequests')?.setValue(filteredValues);
      this.isSingleTagSelected = filteredValues.length === 1; // Set to true if only one tag is selected
    }
    this.DailyLogForm.get('createDailyLogTagParameterRequests')?.markAsTouched();
    this.DailyLogForm.get('createDailyLogTagParameterRequests')?.updateValueAndValidity();
  }


  newDailyLogTagOpen(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesAddTagComponent,
      nzFooter: null,
      nzTitle: '<h1 nz-typography>Add Daily Log Tag</h1>',
      nzWidth: '35%',
      nzBodyStyle: {
        'background-color': '#f1f4fa',
        padding: '20px',
      },
      nzStyle: { top: '10px' },
    });

    modalRef.componentInstance.cancelTag.subscribe(() => {
      modalRef.close();
    });

    modalRef.componentInstance.tagSavedSelected.subscribe((newTag: LookupNameSetupResponse) => {
      this.initLoad().then(() => {
        const newTagNode = {
          title: newTag.name,
          key: newTag.id.toString(),
          value: newTag.id.toString(),
          isLeaf: true,
        };

        // Step 3: Add the new tag node to the tagNodes array
        if (this.tagNodes.length > 0 && this.tagNodes[0].children) {
          this.tagNodes[0].children.push(newTagNode);
        }

        // Step 4: Update the form control value
        const updatedValues = [newTag.id.toString()];
        this.DailyLogForm.get('createDailyLogTagParameterRequests')?.patchValue(updatedValues);
        this.DailyLogForm.get('createDailyLogTagParameterRequests')?.markAsTouched();
        this.DailyLogForm.get('createDailyLogTagParameterRequests')?.updateValueAndValidity();
        modalRef.destroy();
      }).catch((err) => {
        console.error('Error during initLoad:', err);
        // Handle the error, if necessary
      });
    });
  }

  newDailyLogTagEdit(): void {
    const selectedTagIds = this.DailyLogForm.get('createDailyLogTagParameterRequests')?.value;
    if (selectedTagIds && Array.isArray(selectedTagIds) && selectedTagIds.length === 1) {
      const selectedTagId = selectedTagIds[0].toString();
      const selectedTag = this.tagResponse.find((res) => res.id.toString() === selectedTagId);
      if (selectedTag) {
        // Create a modal to edit the selected tag
        const modalRef = this.modal.create({
          nzContent: ProjectManagementSiteDiariesAddTagComponent,
          nzFooter: null,
          nzTitle: '<h1 nz-typography>Edit Daily Log Tag</h1>',
          nzWidth: '35%',
          nzBodyStyle: {
            'background-color': '#f1f4fa',
            padding: '20px',
          },
          nzStyle: { top: '10px' },
        });

        // Pass the selected tag to the modal component
        modalRef.componentInstance.selectedTag = selectedTag;

        modalRef.componentInstance.cancelTag.subscribe(() => {
          modalRef.destroy();

        });

        modalRef.componentInstance.tagSavedSelected.subscribe((newTag: LookupNameSetupResponse) => {
          // Step 1: Call initLoad() and wait for it to complete
          this.initLoad().then(() => {
            // Step 2: Create the new tag node based on the saved tag
            const newTagNode = {
              title: newTag.name,
              key: newTag.id.toString(),
              value: newTag.id.toString(),
              isLeaf: true,
            };

            // Step 3: Add the new tag node to the tagNodes array
            if (this.tagNodes.length > 0 && this.tagNodes[0].children) {
              this.tagNodes[0].children.push(newTagNode);
            }

            // Step 4: Update the form control value
            const updatedValues = [newTag.id.toString()];
            this.DailyLogForm.get('createDailyLogTagParameterRequests')?.patchValue(updatedValues);
            this.DailyLogForm.get('createDailyLogTagParameterRequests')?.markAsTouched();
            this.DailyLogForm.get('createDailyLogTagParameterRequests')?.updateValueAndValidity();

            // Step 5: Close the modal only after initLoad is completed
            modalRef.destroy();
          }).catch((err) => {
            console.error('Error during initLoad:', err);
            // Handle the error, if necessary
          });
        });



        // Subscribe to the tagSaved event when a tag is deleted
        modalRef.componentInstance.tagSaved.subscribe(() => {
          this.initLoad().then(() => {
            this.DailyLogForm.patchValue({ createDailyLogTagParameterRequests: null });
            modalRef.destroy();
          }).catch((err) => {
            console.error('Error during initLoad:', err);
          });
        });

      } else {
        console.error('Tag not found! Ensure the selected ID exists in tagResponse.');
      }
    } else {
      console.error('No valid tag selected for editing! Ensure only one tag is selected.');
    }

  }


  isSingleItemSelected(): boolean {
    const value = this.DailyLogForm.get('createDailyLogTagParameterRequests')?.value;
    return value && Array.isArray(value) && value.length === 1; // Adjusted to check if it's an array with one element
  }





  // Save , save and New and Save Close
  isSaving: boolean = false;
  isSaveAndNew: boolean = false;
  isSaveAndClose: boolean = false;
  DisabledNextActivity: boolean = false;
  weatherNotesErrorMessage: string = '';
  dailyLogId: number;
  Messaging_Id: number;
  formNameId: number = 5;
  JobInfoId: number;
  titleErrorMessage: string = '';
  allNotesErrorMessage: string = '';
  hasAttemptedSubmit = false;
  errorMessages: string[] = [];



  setupFormValueChangeHandler() {
    // Track changes for the notes field
    this.DailyLogForm.get('notes')?.valueChanges.subscribe(value => {
      this.setFieldErrorMessage('notes', value, 4000);  // Max 4000 characters, show error after 3000
    });

    this.DailyLogForm.get('weatherNotes')?.valueChanges.subscribe(value => {
      this.setFieldErrorMessage('weatherNotes', value, 512);  // Max 4000 characters, show error after 3000
    });

    // Track changes for the title field
    this.DailyLogForm.get('title')?.valueChanges.subscribe(value => {
      this.setFieldErrorMessage('title', value, 50);  // Max 50 characters for title
    });
  }

  // Set field error message dynamically based on input length
  setFieldErrorMessage(fieldName: string, value: string, maxLength: number): void {
    const currentLength = value ? value.length : 0;
    const overCount = currentLength - maxLength;

    // If characters exceed max length, show error message
    const message = overCount > 0 ? `${overCount} character${overCount > 1 ? 's' : ''} over` : '';

    // Set specific error messages for each field
    switch (fieldName) {
      case 'title':
        this.titleErrorMessage = message;
        break;
      case 'notes':
        this.allNotesErrorMessage = message;
        break;
      case 'weatherNotes':
        this.weatherNotesErrorMessage = message;
        break;
    }
  }

  // Collect form validation errors for display
  getFormValidationErrors(): string[] {
    const errorMessages: string[] = [];

    Object.keys(this.DailyLogForm.controls).forEach(key => {
      const control = this.DailyLogForm.get(key);
      const controlErrors = control?.errors;
      const fieldLabel = this.getFieldLabel(key);

      if (controlErrors) {
        if (controlErrors['required']) {
          errorMessages.push(`${fieldLabel}: Required`);
        }
        if (controlErrors['maxlength']) {
          const maxLength = controlErrors['maxlength'].requiredLength;
          const actualLength = controlErrors['maxlength'].actualLength;
          const overCount = actualLength - maxLength;
          errorMessages.push(`${fieldLabel}: ${overCount} character${overCount > 1 ? 's' : ''} over the limit`);
        }
      }
    });

    return errorMessages;
  }

  // Helper method to map field names to user-friendly labels
  getFieldLabel(fieldName: string): string {
    const fieldLabels: { [key: string]: string } = {
      title: 'Title',
      notes: 'Notes',
      date: 'Date',
      weatherNotes: 'Weather Notes',

      // add more fields as needed
    };
    return fieldLabels[fieldName] || fieldName;
  }
  // Method to handle form submission and display error messages

  saveButton(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save'): Promise<void> {
    return new Promise((resolve, reject) => {
      // Prevent double submission
      if (this.isSaving || this.isSaveAndClose || this.isSaveAndNew) {
        return reject('Already processing');
      }

      this.hasAttemptedSubmit = true;
      this.errorMessages = [];

      // Validate the form
      if (this.DailyLogForm.invalid) {
        Object.values(this.DailyLogForm.controls).forEach(control => {
          control.markAsDirty();
          control.updateValueAndValidity();
        });

        this.errorMessages = this.getFormValidationErrors(); // Collect error messages
        this.scrollToTop();  // Scroll to top on error
        return reject('Form is invalid'); // Reject promise on invalid form
      }

      // Disable next activity and set appropriate save flag
      this.DisabledNextActivity = true;

      if (actionType === 'saveAndNew') {
        this.isSaveAndNew = true;
      } else if (actionType === 'saveAndClose') {
        this.isSaveAndClose = true;
      } else {
        this.isSaving = true;
      }

      // Construct the tag and assignee parameter requests
      const selectedTags = this.DailyLogForm.get('createDailyLogTagParameterRequests')?.value || [];
      const tagRequests = Array.isArray(selectedTags) ? selectedTags.map(tagId => ({ dailyLogTagSetupId: tagId })) : [];



      // Construct the schedule data payload
      const dailyLogData = {
        ...this.DailyLogForm.value,
        createDailyLogTagParameterRequests: tagRequests,
      };


      // Call the service to save data
      this._siteDiariesDailyLogService.postDailyLog(dailyLogData).subscribe({
        next: (response) => {
          // On success
          this.isSaving = false;
          this.DisabledNextActivity = false;
          this.dailyLogResponse = response.result;
          this.Messaging_Id = response.result?.MessagingId;
          this.JobInfoId = response.result?.JobInformationId;
          this.dailyLogId = response.result?.Id;
          this.DailyLogForm.patchValue({ globalId: response.result.GlobalId });

          // Save attachments if any
          if (this.selectedFilesAttachment?.length > 0) {
            saveAttachments(this.selectedFilesAttachment, this._AttachmentService, response.result.Id, this.pageId);
          }

          // Handle post-save actions
          if (response) {
            this.createAndModifiedByData(response.result.Id);
          }

          // Show success message and reset flags
          this._toastService.success('Saved Successfully');
          this.resetFlags(); // Reset flags after success
          resolve(); // Resolve promise
        },
        error: (error) => {
          // On error
          this.isSaving = false;
          this.DisabledNextActivity = false;
          console.error('Error saving daily log:', error);
          reject('Error saving daily log'); // Reject promise on error
        }
      });
    });
  }


  saveAndNewDailyLog() {
    this.saveButton('saveAndNew')
      .then(() => {
        this.resetForm();
        this.dailyLogResponse = null;
        this.TestAttachment = null;
        this.selectedFilesAttachment = null;
      })
      .catch((error) => {
        console.error('Error during save and new:', error);
      });
  }

  saveAndCloseDailyLog() {
    return this.saveButton('saveAndClose')
      .then(() => {
        this.resetForm();
        // this.scheduleResponse = null;
        this.onCancel();
      })
      .catch((error) => {
        console.error('Error during save and close:', error);
      });
  }


  createDailyLogAndOpenTodo() {
    this.saveButton('save')
      .then(() => {
        this._toastService.success('success');
      })
      .catch((error) => {
        console.error('Error during save and new:', error);
      });
  }


  resetFlags() {
    this.isSaving = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
  }


  resetForm(): void {
    this.DailyLogForm.reset();
    this.DailyLogForm.reset({
      id: 0,
      globalId: '00000000-0000-0000-0000-000000000000',
      jobSetupId: 0,
      jobInformationId: this.selectedJobId,
      date: new Date(),
      title: '',
      notes: '',
      isIncludeWeatherConditions: false,
      isIncludeWeatherNotes: false,
      weatherNotes: '',
      weatherId: 0,
      weatherName: '',
      startDegreeCentigrade: null,
      endDegreeCentigrade: null,
      wind: '',
      humidity: '',
      totalPrecipitation: '',
      weatherOn: null,
      isPermissionInternalUser: false,
      isPermissionSubVendor: false,
      isPermissionOwner: false,
      isPermissionPrivate: false,
      createDailyLogTagParameterRequests: null,
      createDailyLogNotifyUserRequest: [] // Resetting to an empty array
    });

    // Optionally, you may want to clear the form control errors
    this.errorMessages = [];

    // If you have any specific value change handlers, you may want to reset or re-apply them
    this.setupFormValueChangeHandler();
    // Refresh form validation state
    this.refreshErrorStates();

    // Reset the attachments
    this.resetAttachments();


  }


  resetAttachments(): void {
    this.selectedFilesAttachment = [];
    this.TestAttachment = null;
  }


  refreshErrorStates(): void {
    // Clear error messages for specific fields
    this.titleErrorMessage = '';
    this.allNotesErrorMessage = '';
    this.hasAttemptedSubmit = false;

    // Clear global error messages
    this.errorMessages = [];

    // Manually trigger validation for each control
    Object.values(this.DailyLogForm.controls).forEach(control => {
      control.markAsPristine(); // Set control to pristine state
      control.markAsUntouched(); // Set control to untouched state
      control.updateValueAndValidity(); // Trigger re-validation
    });
  }

  // Method to scroll to the top of the form
  scrollToTop() {
    const formElement = document.getElementById('dailyLogForm'); // Replace with your form's ID
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }




  /* Delete Daily Log */

  deleteDailyLog() {
    const globalId = this.DailyLogForm.get('globalId').value;
    this.isDeleteLoading = true; // Start loading

    this._siteDiariesDailyLogService.deleteDailyLog(globalId).subscribe(
      res => {
        this._toastService.success('Deleted Successfully');
        this.isDeleteLoading = false; // End loading
        // You may want to perform additional actions after deletion, e.g., refresh the list
      },
      err => {
        this._toastService.error('Deletion failed'); // Handle error appropriately
        this.isDeleteLoading = false; // End loading
      }
    );
  }

  showConfirm(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete Daily Log?',
      nzContent: 'Are you sure you want to permanently delete this Daily Log?',
      nzOkDanger: true,
      nzOkText: 'Delete',
      nzOkLoading: this.isDeleteLoading, // Step 2: Set loading flag here
      nzCentered: true,
      nzAutofocus: 'cancel',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          this.isDeleteLoading = true; // Show loading spinner
          this.deleteDailyLog(); // Call deleteSchedule here

          setTimeout(() => {
            this.isDeleteLoading = false; // Hide loading spinner after delay
            this.onCancel();
            resolve(); // Resolve the promise to close the modal
          }, 1000); // Delay in milliseconds (1 second)
        }),
      nzOnCancel: () => console.log('Cancel'),
    });
  }


  onLeadSelect(selectedLeadId: any): void {
    const selectedLead = this.jobList.find(lead => lead.id === selectedLeadId);
    // this.JobData = { JobData: selectedLead };
  }

  onCancel(): void {
    this.cancel.emit();
  }



}



