<nz-modal nzWidth="90%" [(nzVisible)]="bills" [nzTitle]="Cost12" [nzFooter]="surveys2" (nzOnCancel)="closebiils()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '70vh', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
    <ng-template #Cost12>
        <div class="row p-0 m-0">
            <h1 class="p-0 m-0 fw-medium">Bills</h1>
        </div>
    </ng-template>
    <div class="cantent" *nzModalContent>




        <div class="card-container">
            <nz-tabset nzType="card">
                <nz-tab *ngFor="let tab of tabs" [nzTitle]="innerCardTabs(tab)">
                    <ng-container *ngIf="tab === 1">


                        <div class="row famly-inn gx-3" style="margin-top: -20px;">
                            <div class="col-12">
                                <ng-template #extraTemplate>
                                    <span
                                        style="background-color: #c7d0d9; font-size:14px; font-weight: 800; padding: 1.2rem; margin-right: -19px; "
                                        nz-button nzType="default">Open</span>
                                </ng-template>
                                <nz-card nzBorderless="true" nzTitle="Bills" [nzExtra]="extraTemplate">
                                    <div class="row">
                                        <div class="col-8">
                                            <div class="row">
                                                <div class="col-lg-9">
                                                    <label class="sub-text">
                                                        Bills #<i class="bi bi-info-circle-fill text-secondary"
                                                            nz-popover [nzPopoverContent]="contentTemplate4"
                                                            nzPopoverPlacement="right"></i>
                                                        <ng-template #contentTemplate4>
                                                            <div class="rounded-2">
                                                                <p style="height: auto; width: 180px;">
                                                                    This Service Coordinator box allows you to limit
                                                                    communications on this warranty
                                                                    request to one employee
                                                                </p>
                                                            </div>
                                                        </ng-template>

                                                    </label>
                                                    <nz-input-group nzAddOnBefore="ZH-208LIS">
                                                        <input type="text" class="form-control formField"
                                                            placeholder="(Auto-Resign)" nz-input />
                                                    </nz-input-group>
                                                </div>
                                            </div>
                                            <div class="row ">
                                                <div class="col-lg-9 mt-2">
                                                    <label class="sub-text">
                                                        Title
                                                    </label>
                                                    <input formControlName="" type="text"
                                                        class="form-control formField">
                                                </div>

                                            </div>
                                            <div class="row ">
                                                <div class="col-lg-2 mt-2">
                                                    <label class="sub-text">
                                                        Date Paid
                                                    </label>
                                                    <nz-date-picker class="form-control formField" [(ngModel)]="date"
                                                        (ngModelChange)="onChange($event)"></nz-date-picker>
                                                </div>

                                            </div>
                                            <div class="row gx-2 ">
                                                <div class="col-lg-5 mt-2">
                                                    <label class="sub-text">
                                                        Pay To
                                                    </label>
                                                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a person"
                                                        [(ngModel)]="selectedValue">
                                                        <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                                                        <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                                                        <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                                                    </nz-select>
                                                </div>
                                                <div class="col-lg-3 mt-2">
                                                    <label class="sub-text">
                                                        Name
                                                    </label>
                                                    <input formControlName="" type="text" class="form-control formField"
                                                        placeholder="Builder Cosr" nz-input>
                                                </div>

                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-lg-8">
                                                    <label for="text" class="sub-text">Description</label>
                                                    <textarea class="form-control formField"
                                                        style="height: 50px; resize: none;"
                                                        (input)="autoAdjustTextAreaHeight($event)"></textarea>
                                                </div>
                                            </div>
                                            <div class="row ">
                                                <div class="col-lg-2 mt-2">
                                                    <label class="sub-text">
                                                        Invoice Date
                                                    </label>
                                                    <nz-date-picker class="form-control formField" [(ngModel)]="date"
                                                        (ngModelChange)="onChange($event)"></nz-date-picker>
                                                </div>

                                            </div>
                                            <div class="row ">
                                                <div class="col-3 mt-2">
                                                    <label class="sub-text">
                                                        Invoice Date
                                                    </label>
                                                    <nz-button-group class="mt-1">
                                                        <button nz-button nzBlock nzType="default" (click)="show1()">
                                                            <span nzType=""></span>
                                                            Choose Date
                                                        </button>
                                                        <button nz-button nzType="default" (click)="show2()">
                                                            Link To Schedule item
                                                            <span nzType="default"></span>
                                                        </button>
                                                    </nz-button-group>
                                                </div>


                                            </div>
                                            <div class="row gx-1" *ngIf="field2">
                                                <div class="col-lg-1 mt-1">
                                                    <label class="sub-text">
                                                        Due
                                                    </label>
                                                    <nz-input-number class="form-control formField"
                                                        [(ngModel)]="demoValue" [nzMin]="1" [nzMax]="10"
                                                        [nzStep]="1"></nz-input-number>
                                                </div>
                                                <div class="col-lg-3" style="margin-top: 38px;">
                                                    <span class="sub-text ">Days</span>
                                                    <label class="ms-1" nz-radio ngModel>Before</label>
                                                    <label nz-radio ngModel>After</label>
                                                </div>
                                            </div>
                                            <div class="row gx-0" *ngIf="field2">
                                                <div class="col-lg-5 mt-2">
                                                    <label class="sub-text">
                                                        Schedule Item <span class="text-danger">*</span>
                                                    </label>
                                                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a person"
                                                        [(ngModel)]="selectedValue">
                                                        <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                                                        <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                                                        <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                                                    </nz-select>
                                                </div>
                                                <div class="col-3 mt-4">
                                                    <button aria-disabled="false" type="button"
                                                        class="ant-btn ant-btn-link BTButton isolated AutoSizing"
                                                        style="height: 29px; margin-top: 12px;">
                                                        <span>Add</span>
                                                    </button>&nbsp;&nbsp;
                                                    <button aria-disabled="false" type="button"
                                                        class="ant-btn ant-btn-link BTButton isolated AutoSizing ">
                                                        <span
                                                            style="opacity: 60%; margin-bottom: 40px; margin-left: -40px;">
                                                            Edit
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="row gx-2" *ngIf="field1">
                                                <div class="col-lg-2 mt-2" *ngIf="field3">
                                                    <label class="sub-text">
                                                        Due Date
                                                    </label>
                                                    <nz-date-picker class="form-control formField" [(ngModel)]="date"
                                                        (ngModelChange)="onChange($event)"></nz-date-picker>


                                                </div>
                                                <div class="col-lg-2 mt-2">
                                                    <label class="sub-text">
                                                        Time
                                                    </label>
                                                    <nz-time-picker class="form-control formField" [(ngModel)]="time"
                                                        [nzDefaultOpenValue]="defaultOpenValue"></nz-time-picker>
                                                </div>


                                            </div>

                                        </div>
                                        <div class="col">
                                            <span class="sub-text">Xero Status</span>
                                            <p>Not Billed</p>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col mt-5 ms-1">
                                            <span class="toggle-icon" nz-icon nzType="caret-right" nzTheme="fill"
                                                [ngClass]="{'rotate-icon': isPaymentHistoryVisible}"
                                                (click)="togglePaymentHistory()"></span>
                                            <span class="fw-bold fs-sm"> Payment History</span>
                                            <div class="card border-0 mt-0" *ngIf="isPaymentHistoryVisible">
                                                <div class="card-head border-bottom mt-2"></div>
                                                <div class="card-body">
                                                    <span class="ms-2">Payment applied to this billed is listed
                                                        here</span>
                                                </div>

                                                <span class="fw-bold fs-sm ms-2">Variance</span>
                                                <div class="card-head border-bottom mt-2"></div>

                                                <label class="mt-4 ms-2" nz-checkbox [(ngModel)]="checked">Mark us
                                                    to</label>
                                            </div>

                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col mt-4">
                                            <span class="fw-bold fs-sm ms-2">Cost</span>
                                            <div class="card-head border-bottom mt-2"></div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col text-end mt-2">
                                            <button nz-button nzType="default">Add Form Catalog</button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col mt-2">
                                            <button nz-button (click)="addRow()" nzType="primary">Add</button>
                                            <nz-table #editRowTable [nzSize]="'small'" [nzData]="listOfData"
                                                style="background-color: #cccccc;" [nzShowPagination]="false"
                                                [nzFooter]="customFooter ? customFooter : null">
                                                <thead>
                                                    <tr>
                                                        <th class="fw-bold" nzWidth="35%">Items</th>
                                                        <th class="fw-bold" nzWidth="200px">Cost Type</th>
                                                        <th class="fw-bold" nzWidth="15%">Unit Cost</th>
                                                        <th class="fw-bold" nzWidth="8%">Quantity</th>
                                                        <th class="fw-bold" nzWidth="10%">Unit</th>
                                                        <th class="fw-bold" nzWidth="12%">Builder Cost</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody style="background: #e6f6ff;">
                                                    <tr *ngFor="let data of editRowTable.data" class="editable-row ">
                                                        <td>
                                                            <!-- <div class="editable-cell" [hidden]="editId === data.id" (click)="startEdit(data.id)">
                                {{ data.name }}
                              </div> -->
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <label class="sub-text"> Title</label>
                                                                    <input type="text" class="form-control formField">
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-10">
                                                                    <label class="sub-text">
                                                                        Schedule Item <span class="text-danger">*</span>
                                                                    </label>
                                                                    <nz-select style="width: 100%;" nzShowSearch
                                                                        nzAllowClear nzPlaceHolder="Select a person"
                                                                        [(ngModel)]="selectedValue">
                                                                        <nz-option nzLabel="Jack"
                                                                            nzValue="jack"></nz-option>
                                                                        <nz-option nzLabel="Lucy"
                                                                            nzValue="lucy"></nz-option>
                                                                        <nz-option nzLabel="Tom"
                                                                            nzValue="tom"></nz-option>
                                                                    </nz-select>
                                                                </div>
                                                            </div>
                                                            <div class="row" style="margin-top: -10px;">
                                                                <div class="col-6">
                                                                    <button aria-disabled="false" type="button"
                                                                        class="ant-btn ant-btn-link BTButton isolated AutoSizing"
                                                                        style="height: 29px; margin-top: 12px;">
                                                                        <span>Add</span>
                                                                    </button>&nbsp;&nbsp;
                                                                    <button aria-disabled="false" type="button"
                                                                        class="ant-btn ant-btn-link BTButton isolated AutoSizing ">
                                                                        <span
                                                                            style="opacity: 60%; margin-bottom: 40px; margin-left: -40px;">
                                                                            Edit
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row" style="margin-top: -78px;">
                                                                <div class="col-12">
                                                                    <label class="sub-text">Cost Type</label>
                                                                    <input  type="text"
                                                                        class="form-control formField">
                                                                </div>
                                                            </div>

                                                        </td>
                                                        <td>
                                                            <div class="row" style="margin-top: -78px;">
                                                                <div class="col-12">
                                                                    <label class="sub-text">Unit Cost</label>
                                                                    <nz-input-group nzAddOnBefore="$">
                                                                        <input type="text"
                                                                            class="form-control formField"
                                                                            placeholder="0.0000" nz-input />
                                                                    </nz-input-group>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row" style="margin-top: -78px;">
                                                                <div class="col-12">
                                                                    <label class="sub-text">Quantity</label>
                                                                    <input type="text" class="form-control formField"
                                                                        placeholder="1.0000">
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row" style="margin-top: -78px;">
                                                                <div class="col-12">
                                                                    <label class="sub-text">Unit</label>
                                                                    <input type="text" class="form-control formField">
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row" style="margin-top: -78px;">
                                                                <div class="col-12 text-end">
                                                                    <label class="sub-text">BuilderCost</label>
                                                                    <p class="text-end fw-bold">$0.00</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row" style="margin-top: -50px;">
                                                                <div class="col-12 text-end">
                                                                    <a (click)="deleteRow(data.id)"><span nz-icon
                                                                            nzType="delete" nzTheme="fill"
                                                                            style="color: #004fd6;font-size: 20px;"></span>
                                                                    </a>
                                                                </div>
                                                            </div>

                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </nz-table>
                                            <ng-template #customFooter>
                                                <div class="row">
                                                    <div class="col text-start">
                                                        <span>Quantity </span>
                                                    </div>
                                                    <div class="col text-end">
                                                        <span>$0.00</span>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </nz-card>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="tab === 2">yuiopoi</ng-container>
                    <ng-container *ngIf="tab === 3">tyuioiuu</ng-container>
                </nz-tab>
            </nz-tabset>
        </div>
    </div>
    <ng-template #surveys2>
        <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
    </ng-template>
</nz-modal>