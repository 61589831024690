<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form (ngSubmit)="onFilterApply()">
    <div class="row mt-2">
      <!-- <div class="col-10 p-0">
        <div class="input-group">
            <nz-select [(ngModel)]="formFieldValues['standardFilter']" name="standardFilter"  (ngModelChange)="onChangeStandardFilter($event)" nzShowSearch [lang]="true" nzAutoFocus="true"
                nzPlaceHolder="Standard Filter">
                <nz-option *ngFor="let filter of filterResponseData"
                    [nzLabel]="filter.name"
                    [nzValue]="filter.id">
                </nz-option>
            </nz-select>
        </div>
    </div> -->
    <div class="col-10 p-0">
          <div class="input-group">
           <nz-select
            [(ngModel)]="formFieldValues['standardFilter']"
            name="standardFilter"
            (ngModelChange)="onChangeStandardFilter($event)"
            nzShowSearch
            [nzAutoFocus]="true"
            nzPlaceHolder="Standard Filter"
            >
            <nz-option *ngFor="let filter of filterResponseData"
             [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')"
             [nzValue]="filter.id"
             nzCustomContent>
             <div class="option-content">
              <!-- Customize content with icons and text -->
              <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
              <span>{{ filter.name }}</span>
             <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

             </div>
            </nz-option>
           </nz-select>
          </div>
         </div>

     <!-- [nzLabel]="filter.name + (filter.isSetAsDefault ? ' DEFAULT' : '')"  -->
      <div class="col-1 mt-1">
        <i class="bi bi-three-dots"
        nz-popover
        [nzPopoverContent]="contentTemplate"
        [nzPopoverPlacement]="'top'"

         (click)="openStandardFilterComponent('SAVE_AS')"></i>
      </div>
    </div>
    <div class="row mt-2" *ngIf="selectedFilter === 1">
      <div class="col">
        <p>Your filter has unsaved changes.</p>
      </div>
    </div>
    <div class="row mt-2" *ngIf="selectedFilter === 0">
      <div class="col">
        <p>Your filter has unsaved changes.</p>
      </div>
    </div>

    <div class="col d-flex mt-3">
      <button (click)="openStandardFilterComponent('APPLY_SAVE_AS')" type="button" nz-button nzType="default" class="me-2" style="font-size: 12px;"
      *ngIf="selectedFilter === 1">Save As</button>
      <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;" *ngIf="selectedFilter === 1" type="button">Reset</button>
      <button (click)="editSaveFilterData()" nz-button nzType="primary" type="button" class="me-2" style="font-size: 12px;" *ngIf="selectedFilter === 0">Save</button>
      <button (click)="openStandardFilterComponent('APPLY_SAVE_AS')" nz-button nzType="default" class="me-2"
      style="font-size: 12px;" *ngIf="selectedFilter === 0" type="button">Save As</button>
      <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;" *ngIf="selectedFilter === 0" type="button">Reset</button>
    </div>

    <div class="row famly-inn">
      <div class="col-12">
        <div class="row">
          <div class="col p-0">
            <label class="sub-text">Title</label>
            <input   [(ngModel)]="formFieldValues['title']" name="title" type="text" class="form-control formField">
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Selection Status</label>

   <!-- selecationstatus -->
          <nz-tree-select
             [(ngModel)]="formFieldValues['selectionStatus']"
             name="selectionStatus"
             style="width: 100%"
             [nzNodes]="SelectionStatusNodes"
             nzShowSearch
             nzAllowClear="false"
             nzCheckable
             (ngModelChange)="onselectionStatusChange($event)"
             nzPlaceHolder="Select selection Status"
             nzDropdownClassName="dropdownStyle"
            >
            </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Subs/Vendors</label>

            <!-- subsvondors -->
            <nz-tree-select
            [(ngModel)]="formFieldValues['subsvendors']"
            name="subsvendors"
            style="width: 100%"
            [nzNodes]="vendersNodes"
            nzShowSearch
            nzAllowClear="false"
            nzCheckable
            (ngModelChange)="OnVendorChange($event)"
            nzPlaceHolder="Select subsvendors"
            nzDropdownClassName="dropdownStyle"
          >
          </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Locations</label>
            <nz-tree-select
            [(ngModel)]="formFieldValues['locations']"
             name="locations"
             style="width: 100%"
             [nzNodes]="LocationsNodes"
             nzShowSearch
             nzAllowClear="false"
             nzCheckable
             (ngModelChange)="onLocationsChange($event)"
             nzPlaceHolder="Select locations"
             nzDropdownClassName="dropdownStyle"
            >
            </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Categories</label>

       <!--  Categories -->
          <nz-tree-select
             [(ngModel)]="formFieldValues['Categories']"
             name="Categories"
             style="width: 100%"
             [nzNodes]="CategoriesNodes"
             nzShowSearch
             nzAllowClear="false"
             nzCheckable
             (ngModelChange)="onCategoriesChange($event)"
             nzPlaceHolder="Select Categories"
             nzDropdownClassName="dropdownStyle"
            >
            </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Deadline</label>
            <nz-select nzShowSearch [lang]="true" nzAutoFocus="true" [(ngModel)]="formFieldValues['deadline']" name="deadline">
              <nz-option *ngFor="let filter of deadlineOptions" [nzLabel]="filter.label" [nzValue]="filter.value"> </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row">
          <label class="sub-text p-0">
            Show Unavailable Choices
          </label>
          <div class="col-12 p-0 mt-1">
            <label  [(ngModel)]="formFieldValues['showUnavailableChoices']" name="showUnavailableChoices" nz-checkbox></label>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12 p-0 text-end">
        <button nz-button nzType="default" type="button" (click)="resetFilter()">Clear All</button>
        <button class="ms-1" nz-button nzType="primary" type="submit" [disabled]="isFormDirty" >Apply</button>
      </div>
    </div>

  </form>
</div>


<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>
