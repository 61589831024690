<nz-modal
    nzWidth="75%"
    [(nzVisible)]="uploadfiles"
    [nzTitle]="UploadFiles1"
    [nzFooter]="UploadFiles2"
    (nzOnCancel)="UploadFiles()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '68vh', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
  <ng-template #UploadFiles1>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0" nz-typography>Upload Files</h2>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <div class="row famly-inn gx-3">
      <div class="col-5">
          <div class="card border-0 mt-0">
              <div class="card-head">
                <div class="row">

                    <div class="col-12">
                      <div class="row">
                          <div class="col-12 d-flex align-content-center justify-content-center">
                              <span
                                  style="font-size: 60px; padding: 30px 0px 20px 0px;"
                                  nz-icon
                                  nzType="file"
                                  nzTheme="outline"
                              ></span>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-12 d-flex align-content-center justify-content-center">
                              <h4 nz-typography>Drop files here to upload, or</h4>
                          </div>
                      </div>
                      <div class="row mt-1">
                          <div class="col-12 d-flex align-content-center justify-content-center">
                              <h4 nz-typography>browse for files.</h4>
                          </div>
                      </div>
                      <div class="row mt-3 gx-2">
                        <div class="col-6 text-end">

                          <button (click)="chooseFile()" nz-button nzType="primary"  class="ms-2 button-Daily">Browse Computer</button>
                           <input type="file" id="files" name="files" (change)="onFileSelected($event)" multiple style="display: none;">

                        </div>
                        <div class="col-6 text-start">
                          <button nz-button nzType="text" class="same-but"><span>Browse Buildertrend</span></button>
                        </div>
                      </div>
<!-- ccccc -->

                      <div class="row pt-2 pb-2" style="row-gap: 0px;" *ngIf="selectedFiles.length > 0">
                        <div class="col-6">
                            <span class="BTFileUpload-AddLeftBodyPadding">{{ selectedFiles.length }} File</span>
                        </div>
                        <div class="col-6 text-end">
                            <a nz-button nzType="link" (click)="clearAll()" >Clear All</a>
                        </div>
                    </div>
                    <div class="row" *ngFor="let file of selectedFiles">
                        <div class="col-12">
                            <div class="row pt-2 pb-2" style="row-gap: 0px;">
                            <div class="col-1">
                            <button *ngIf="!showCheckIcon" nz-button nzLoading nzShape="circle"></button>
                            <span *ngIf="showCheckIcon" style="color: #31ce8d;" class="BTFileUpload-AddLeftBodyPadding " nz-icon nzType="check" nzTheme="outline"></span>
                            </div>
                            <div class="col-2">
                              <span class="d-flex justify-content-center align-content-center" style="font-size: 24px; color: rgb(177, 180, 181);" nz-icon nzType="file-text" nzTheme="fill"></span>
                            </div>
                            <div class="col-5">
                                <div class="text-overflow-auto">
                                  <span class="BTPopover PopoverSpacing">
                                    <span class="ant-upload-list-item-name ng-tns-c314274227-69 ng-star-inserted" title="_position.scss" style=""> {{ file.name }}</span>
                                  </span>
                                </div>
                            </div>
                            <div class="col-2">
                              <span class="BTFileUpload-SecondaryText">17 KB</span>
                            </div>
                            <div class="col-1 text-end p-0 m-0">
                                <a class="p-0 m-0" nz-button nzType="link" (click)="removeFile(file)" >Remove</a>
                            </div>
                            <div class="row" *ngIf="isImageFile(file.type)">
                              <img [src]="previewUrl" alt="Image Preview" class="file-image small-image" />
                            </div>
                            </div>
                        </div>
                    </div>
<!-- ccccc -->


                    </div>
                  </div>
              </div>
              <div class="card-body">
              </div>
          </div>
      </div>
      <div class="col-7">
          <div class="card border-0 mt-0">
            <div class="card-head border-bottom">
              <div class="row py-2">
                  <h5 nz-typography><span class="ms-3"></span>Viewing Permissions</h5>
              </div>
          </div>
              <div class="card-body">
                <div class="row">
                      <div class="col-12">
                        <div class="row">
                            <label class="sub-text">Subs/Vendors</label>
                        </div>
                        <div class="row mt-1">
                      <div class="col-12">
                            <label nz-checkbox>Allow Subs / Vendors to view attached files.</label>
                      </div>
                        </div>
                        <div class="row mt-2">
                            <label class="sub-text">Owner</label>
                        </div>
                        <div class="row mt-1">
                        <div class="col-12">
                            <label nz-checkbox>Allow Owner to view attached files.</label>
                        </div>
                        </div>

                      </div>
                </div>
              </div>
          </div>
      </div>
    </div>
</div>
  <ng-template #UploadFiles2>
    <button nz-button nzType="primary" class="rounded-1 me-0" (click)="saveAttachment()">Save</button>
    <button nz-button nzType="primary" class="rounded-1 me-0" (click)="save()">Save12</button>
  </ng-template>
</nz-modal>


