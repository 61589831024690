import { Component, TemplateRef } from '@angular/core';
import { NzFormatBeforeDropEvent, NzFormatEmitEvent } from 'ng-zorro-antd/tree/public-api';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
@Component({
  selector: 'app-cost-codes-header',
  templateUrl: './cost-codes-header.component.html',
  styleUrls: ['./cost-codes-header.component.css']
})
export class CostCodesHeaderComponent {
    checked = true;
    isCollapsed = false;
    checkeds = true;
  
  nzEvent($event: NzFormatEmitEvent) {
  throw new Error('Method not implemented.');
  }
    title = 'Client';
    costcodes:boolean = false;
    checkedKeys = [];
  
    log(): void {
      console.log('click dropdown button');
    }
  
    CostCodes(){
    this.costcodes = true;
    }
    CloseCostCodes(){
      this.costcodes = false;
    }
  
    beforeDrop(arg: NzFormatBeforeDropEvent): Observable<boolean> {
      // if insert node into another node, wait 1s
      if (arg.pos === 0) {
        return of(true).pipe(delay(1000));
      } else {
        return of(false);
      }
    }
  
    onCheck(event: any): void {
      console.log('Checked keys:', event.checkedKeys);
      this.checkedKeys = event.checkedKeys;
    }
  
  
    cost1:boolean=false;
    costopen(){
     this.cost1 =true;
    }
    costclose(){
      this.cost1 =false;
     }
     selectedValue = null;
  
  
     autoAdjustTextAreaHeight(event: Event): void {
      const textarea = event.target as HTMLTextAreaElement;
      textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
      textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; // Set the height with a maximum of 200px
      }
      categorycost:boolean=false;
      category(){
        this.categorycost=true;
      }
      categoryclose(){
        this.categorycost=false;
      }
      switchValue = false;
}
