<nz-modal nzWidth="40%" [(nzVisible)]="isActivityTemplateCopyVisible" [nzTitle]="modalTitle"
    [nzFooter]="LeadActivityFooter"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '10px', 'max-height': '400px', 'overflow-y': 'auto' }"
    [nzStyle]="{ top: '13px' }" (nzOnCancel)="ActivityTemCancel()">

    <ng-template #modalTitle>
        <div class="row p-0 m-0">
            <h2 class="p-0 m-0 " nz-typography>Copy to Activity Template?</h2>
        </div>
    </ng-template>

    <div *nzModalContent>
        <div class="card border-0 mt-0 p-2">
            <div class="card-body">
                <div class="row mb-3">
                    <div class="col-12 p-0 m-0">
                        <label class="sub-text">Template Name
                            <span class="fw-bolder text-danger ms-1">*</span>
                        </label>
                        <input type="text" class="form-control formField" [(ngModel)]="formFieldValues['name']" name="name"
                             >
                    </div>
                </div>

                <div class="row mb-2">
                    <label nz-checkbox [(ngModel)]="formFieldValues['isCopyActivityAddresses']"
                        name="isCopyActivityAddresses">Copy Activity Addresses</label>
                </div>
                <div class="row mb-2">
                    <label nz-checkbox 
                       >Open Template on Save</label>
                </div>
            </div>
        </div>
    </div>

    <ng-template #LeadActivityFooter>
        <div class="mt-2 align-items-center">
            <button class="rounded-2" nz-button nzType="default" (click)="ActivityTemCancel()">Cancel</button>
            <button class="rounded-2" nz-button nzType="primary" [disabled]="!formFieldValues.name || isSaving" (click)="SaveCopy()">Copy To Activity Template</button>
        </div>
    </ng-template>
</nz-modal>