<div class="row mt-5" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <div class="col" style="text-align: center;">
       <span><i class="bi bi-bell" style="font-size: 70px;"></i> </span>        
        <div class="row">
            <div class="col mt-2">
        <h1>No notifications</h1>
            </div>
        </div>
        <div class="col mt-3 text-secondary">
        <p>Stay up to date with Buildertrend<br>activity</p>
  
  
        </div>
    </div>
  
  </div>