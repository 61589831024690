import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-warranty-new-claim-warranty-category',
  templateUrl: './warranty-new-claim-warranty-category.component.html',
  styleUrls: ['./warranty-new-claim-warranty-category.component.css']
})
export class WarrantyNewClaimWarrantyCategoryComponent {
  @Output() cancel = new EventEmitter<void>();
  jobGroupForm: FormGroup;

    // selectionModel
    Taghandle= true;
    TagClose(): void {
    this.cancel.emit();
    }
}
