import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentsDashboardComponent } from './Documents/documents-dashboard/documents-dashboard.component';
import { PhotoDashboardComponent } from './photos/photo-dashboard/photo-dashboard.component';
import { VideosDashboardComponent } from './videos/videos-dashboard/videos-dashboard.component';

const routes: Routes = [

  { path: 'Documents', component:DocumentsDashboardComponent},
  { path: 'Photos', component:PhotoDashboardComponent},
  { path: 'Videos', component:VideosDashboardComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FilesRoutingModule { }
