<nz-modal nzWidth="95%" [(nzVisible)]="bidsVisible" [nzTitle]="bids1" [nzFooter]="bids2" (nzOnCancel)="bidsCancel()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '68vh', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
    <ng-template #bids1>
        <div class="row p-0 m-0">
            <h1 class="p-0 m-0 fw-medium">Bids </h1>
        </div>
</ng-template>
<div class="cantent" *nzModalContent>
<div class="row famly-inn gx-3">
    <div class="col-12">
        <div class="card border-0 mt-0">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Bids Setup</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="row mt-0">
                            <div class="col-9 d-flex align-items-center">
                                <label for="exampleCheck2" class="pira-label ms-0 me-1">Alert subs/vendors
                                    <nz-input-number [(ngModel)]="bidsFormFields['alertSubsVendorsDayNo']" name="alertSubsVendorsDayNo"
                                        style="width: 100px; border-radius: 4px;" [(ngModel)]="demoValue" [nzMin]="1"
                                        [nzMax]="10" [nzStep]="1"></nz-input-number>
                                    <span class="ms-2">days before bid package deadline</span></label>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <label for="text" class="sub-text">Default bid request notification text</label>
                                <textarea [(ngModel)]="bidsFormFields['defaultBidRequestNotificationText']" name="defaultBidRequestNotificationText"
                                    class="form-control formField" style="height: 100px; resize: none;"
                                    (input)="autoAdjustTextAreaHeight($event)"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<ng-template #bids2>
    <button nz-button nzType="primary" class="rounded-1 me-0" (click)="saveButton()"><span>Save</span></button>
</ng-template>
</nz-modal>