import { Component, EventEmitter, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';



@Component({
  selector: 'app-lead-opportunities-setting',
  templateUrl: './lead-opportunities-setting.component.html',
  styleUrls: ['./lead-opportunities-setting.component.css']
})
export class LeadOpportunitiesSettingComponent {

  constructor(private modal: NzModalService){}
  @Output() cancel = new EventEmitter<void>();


  LeadOpportunitySettingmodal=true;
  LeadOpportunitySettingModelCancel(): void {
  this.cancel.emit();
  }

  


}
