import { Component } from '@angular/core';


@Component({
  selector: 'inbox-details',
  templateUrl: './inbox-details.component.html',
  styleUrls: ['./inbox-details.component.css']
})
export class InboxDetailsComponent {

 
}
