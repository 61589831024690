import { Component } from '@angular/core';

@Component({
  selector: 'app-hub-spot',
  templateUrl: './hub-spot.component.html',
  styleUrls: ['./hub-spot.component.css']
})
export class HubSpotComponent {

}
