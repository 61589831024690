<nz-card 
  nzBorderless="true"
  class="p-0"
  [nzTitle]="RelatedItems"
  [nzExtra]="extraTemplate"
>
  <ng-template #RelatedItems class="ps-0">
    <h5 class="fs-6">Related Items</h5>
  </ng-template>

<!-- After Save Show button -->
    <ng-template #extraTemplate >  
      <nz-page-header-extra *ngIf="responseSchedule">
        <button class="rounded-2" nz-button nzType="default">New To-Do</button>
      </nz-page-header-extra>
    </ng-template>


  <div *ngIf="!responseSchedule">  <!-- After Save Hide Content -->
    <div class="row">
      <div class="col-12">
        <h4 class="fs-6">Related To-Do's</h4>
      </div>
    </div>
    <nz-divider></nz-divider>
    <div class="row mt-1">
      <div class="col ms-1">
        <h4 class="fs-6">To-Do's available after save</h4>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col ms-1">
        <button nz-button nzType="default" class="fw-medium">
          Save & Creat To-Do's
        </button>
      </div>
    </div>
  </div>


  <!-- After Save Show Content -->
  <div class="container  justify-content-center align-items-center text-center" *ngIf="responseSchedule">  
    <div class="row mb-3">
      <div class="col-md-12">
        <span role="img" size="66" class="anticon" style="font-size: 66px"
        ><svg
          width="1em"
          height="1em"
          viewBox="0 0 32 32"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false"
          class=""
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.9933 2.88338C22.9355 2.38604 22.5128 2 22 2C21.4477 2 21 2.44772 21 3V3.999H17V3L16.9933 2.88338C16.9355 2.38604 16.5128 2 16 2C15.4477 2 15 2.44772 15 3V3.999H11V3L10.9933 2.88338C10.9355 2.38604 10.5128 2 10 2C9.44772 2 9 2.44772 9 3V3.999L7 4L6.85074 4.00549C5.81588 4.08183 5 4.94564 5 6V25L5.0049 25.1996C5.10892 27.316 6.8578 29 9 29H23L23.1996 28.9951C25.316 28.8911 27 27.1422 27 25V6L26.9945 5.85074C26.9182 4.81588 26.0544 4 25 4L23 3.999V3L22.9933 2.88338ZM7 6L9 5.999V7L9.00673 7.11662C9.06449 7.61396 9.48716 8 10 8C10.5523 8 11 7.55228 11 7V5.999H15V7L15.0067 7.11662C15.0645 7.61396 15.4872 8 16 8C16.5523 8 17 7.55228 17 7V5.999H21V7L21.0067 7.11662C21.0645 7.61396 21.4872 8 22 8C22.5523 8 23 7.55228 23 7V5.999L25 6V25L24.9945 25.1493C24.9182 26.1841 24.0544 27 23 27H9L8.85074 26.9945C7.81588 26.9182 7 26.0544 7 25V6ZM17.2076 17.7927C17.5678 18.1534 17.5952 18.7207 17.2899 19.1127L17.2066 19.2069L13.7016 22.7069C13.3408 23.0672 12.7733 23.0944 12.3812 22.7888L12.2871 22.7055L10.292 20.7055C9.90198 20.3145 9.90276 19.6813 10.2938 19.2913C10.6547 18.9312 11.222 18.9042 11.6139 19.2097L11.708 19.293L12.996 20.5843L15.7934 17.7917C16.1842 17.4014 16.8174 17.4019 17.2076 17.7927ZM17.2899 13.1127C17.5952 12.7207 17.5678 12.1534 17.2076 11.7927C16.8174 11.4019 16.1842 11.4014 15.7934 11.7917L12.996 14.5843L11.708 13.293L11.6139 13.2097C11.222 12.9042 10.6547 12.9312 10.2938 13.2913C9.90276 13.6813 9.90198 14.3145 10.292 14.7055L12.2871 16.7055L12.3812 16.7888C12.7733 17.0944 13.3408 17.0672 13.7016 16.7069L17.2066 13.2069L17.2899 13.1127Z"
          ></path></svg
      ></span>
    </div>

      </div>
       <div class="row mb-3">
        <div class="col-12">
          <h3>No Related To-Do's</h3>
        </div>
       </div>
      <div class="row mb-3">
        <div class="col-12">
          <p>
            To-do's can help you and your team stay on track, and get work done
            faster.
          </p>
        </div>
      </div>
  </div>
</nz-card>
