import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ReceiptUploadComponent } from '../receipt-upload/receipt-upload.component';
import { MessagingForwordToEmailComponent } from 'src/shared/Components/messaging-forword-to-email/messaging-forword-to-email.component';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { FiltersName } from 'src/app/Core/Enums/filtersName';

@Component({
  selector: 'app-cost-inbox-dashboard',
  templateUrl: './cost-inbox-dashboard.component.html',
  styleUrls: ['./cost-inbox-dashboard.component.css']
})
export class CostInboxDashboardComponent {
  isCollapsed: boolean= false;
  selectedJobId: number = 0;
constructor(private modal: NzModalService,  private filterService: FilterSideMenuBarService) {}
toggleCollapse() {
  this.isCollapsed = !this.isCollapsed;
}
  receiptUploadModelOpen(): void {
    const modalRef = this.modal.create({
      nzContent: ReceiptUploadComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }
  
PAGE_ID: number = 19;
Cost_Inbox: string = FiltersName.Cost_Inbox;

  openFilter(component: string, pageId: number) {
    this.filterService.toggleFilter(component, pageId);
  }
  forwordToEmailModelOpen(): void {
    const modalRef = this.modal.create({
      nzContent: MessagingForwordToEmailComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }

 
// 
showFields: boolean = true; // Initially show one row
rows: number = 1; // Initial row count
maxRows: number = 5; // Maximum rows to show
showAddButton: boolean = true; // Initially show "Add" button

addRow() {
  this.rows += 1;
  if (this.rows >= this.maxRows) {
    this.showAddButton = false; // Hide "Add" button when maximum rows are reached
  }
}

removeRow(index: number) {
  this.rows -= 1;
  if (this.rows < this.maxRows) {
    this.showAddButton = true; // Show "Add" button if rows are less than the maximum
  }
}
// 
  // Selected Job Id From Left Side Job List
  getSelectedJobId(item: number){
    this.selectedJobId = item;
    console.log("selectedJobId : ", this.selectedJobId);
  }

}
