<div class="row famly-inn gx-3">
    <div class="col-12">
        <div class="card border-0 mt-0">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Owner Settings</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row mt-1">
                    <div class=" col-9 d-flex align-items-center">
                        <label nz-checkbox [(ngModel)]="checked"><span class="pira-label">
                            Hide Job Price Summary for owners on new jobs</span></label>
                            <i
                            class="bi bi-info-circle-fill text-secondary"
                            nz-popover
                            [nzPopoverContent]="JobTypes"
                            nzPopoverPlacement="top"></i>
                            <ng-template #JobTypes>
                              <div class="rounded-2">
                                <p style="height: 25px; width: 210px;font-size: 13px;">
                                    This can be modified for individual jobs in Job Details
                                </p>
                              </div>
                            </ng-template>
                    </div>
                </div>
                <div class="row mt-3 mb-3">
                    <div class=" col-9 d-flex align-items-center">
                        <label nz-checkbox [(ngModel)]="checked1"><span class="pira-label">
                            Disable PO and Budget sharing for owners on all jobs</span></label>
                            <i
                            class="bi bi-info-circle-fill text-secondary"
                            nz-popover
                            [nzPopoverContent]="jobs"
                            nzPopoverPlacement="right"></i>
                            <ng-template #jobs>
                              <div class="rounded-2">
                                <p style="height: 45px; width: 210px;font-size: 13px;">
                                    This will prevent all existing and new jobs from sharing the Budget and PO pages with Owners.
                                </p>
                              </div>
                            </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Budget Settings</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row mt-1">
                    <div class=" col-9 d-flex align-items-center">
                        <label nz-checkbox [(ngModel)]="checked2"><span class="pira-label">
                            Include Time Clock Labor in Budget on new jobs</span></label>
                            <i
                            class="bi bi-info-circle-fill text-secondary"
                            nz-popover
                            [nzPopoverContent]="appear"
                            nzPopoverPlacement="top"></i>
                            <ng-template #appear>
                              <div class="rounded-2">
                                <p style="height: 50px; width: 210px;font-size: 13px;">
                                    A Time Clock Labor column will appear on the Budget and include labor costs based on user shifts
                                </p>
                              </div>
                            </ng-template>
                                <button nz-button nzType="default" class="rounded-1 ms-2">Update Existing Jobs</button>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class=" col-9 d-flex align-items-center">
                        <label nz-checkbox [(ngModel)]="checked3"><span class="pira-label">Include Time Clock Labor in Total Costs and Paid Columns on Budget for new jobs</span></label>
                            <i
                            class="bi bi-info-circle-fill text-secondary"
                            nz-popover
                            [nzPopoverContent]="jobs"
                            nzPopoverPlacement="bottom"></i>
                            <ng-template #jobs>
                              <div class="rounded-2">
                                <p style="height: 110px; width: 210px;font-size: 13px;">
                                    Checking this box will include the labor costs from time clock shift in both the total costs and paid columns on the budget for new jobs. This is recommended for users that do not use POs/Bills to track labor costs.
                                </p>
                              </div>
                            </ng-template>
                            <button nz-button nzType="default" class="rounded-1 ms-2">Update Existing Jobs</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Purchase Orders </span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row mt-1">
                    <div class=" col-9 d-flex align-items-center">
                        <label nz-checkbox [(ngModel)]="checked4"><span class="pira-label">Create separate POs for each line item when using the PO creation wizard</span></label>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class=" col-9 d-flex align-items-center">
                        <label class="sub-text me-2">Purchase Order Prefix</label>
                    </div>
                </div>
                <div class="row">
                    <div class=" col-1 d-flex align-items-center">
                        <input
                        type="text"
                        class="form-control formField">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class=" col-9 d-flex align-items-center">
                        <label class="sub-text me-2">Require signature on purchase orders</label>
                    </div>
                </div>
                <div class="row mt-0">
                    <div class=" col-2 d-flex align-items-center">
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a person" [(ngModel)]="selectedValue">
                            <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                            <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                            <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                          </nz-select>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class=" col-9 d-flex align-items-center">
                        <label class="sub-text me-2">Send PO approval reminders up to</label>
                        <i
                        class="bi bi-info-circle-fill text-secondary"
                        nz-popover
                        [nzPopoverContent]="maximum"
                        nzPopoverPlacement="top"></i>
                        <ng-template #maximum>
                          <div class="rounded-2">
                            <p style="height: 45px; width: 210px;font-size: 13px;">
                                Set the maximum number of PO approval reminders to be sent when PO is released.
                            </p>
                          </div>
                        </ng-template>
                    </div>
                </div>
                <div class="row mt-0">
                    <div class=" col-2 d-flex align-items-center">
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a person" [(ngModel)]="selectedValue">
                            <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                            <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                            <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                          </nz-select>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class=" col-9 d-flex align-items-center">
                        <label class="sub-text me-2">Default Scope of Work </label>
                    </div>
                </div>
                <div class="row mt-0 border-bottom pb-5">
                    <div class="col-md-12">
                        <textarea
                            class="form-control formField"
                            style="height: 1020px; resize: none;"
                            (input)="autoAdjustTextAreaHeight($event)"
                        >
                    </textarea>
                    </div>
                </div>
                <div class="row- mt-1">
                    <span class="sub-text">Purchase Order Disclaimer</span>
                </div>
                <div  *ngIf="!isInputVisible" class="row-mt-1">
                    <span class="pira-label mt-1">
                        A signature of Approval or Electronic Acceptance is required before purchase order is effective. This purchase order then becomes part of the existing contract and is binding.
                    </span>
                </div>
                <div class="row mt-2">
                    <span class="ms-0">
                        <button  *ngIf="!isInputVisible"
                (click)="toggleVisibility()" 
                            nz-button
                            type="button"
                            class="me-0 ant-btn1 ant-btn-default"
                        >
                            <span>Change</span>
                        </button>
                    </span>
                </div>
                <div  *ngIf="isInputVisible" class="row mt-1 border-bottom pb-5">
                    <div class="col-md-12">
                        <textarea
                            class="form-control formField"
                            style="height: 120px; resize: none;"
                            (input)="autoAdjustTextAreaHeight($event)"
                        >
                    </textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Display On Printout</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row mt-1">
                    <div class=" col-9 d-flex align-items-center">
                        <label nz-checkbox [(ngModel)]="checked5"><span class="pira-label">Show Paid Amount</span></label>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class=" col-9 d-flex align-items-center">
                        <label nz-checkbox [(ngModel)]="checked6"><span class="pira-label">Show Custom Fields</span></label>
                    </div>
                </div>
            </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                  <div class="col">
                    <span class="fw-medium fs-6 ms-2">Purchase Orders Custom Fields</span>
                  </div>
                  <div class="col text-end">
                        <button  (click)="CustomFieldInfo()"   nz-button nzType="primary" class="rounded-1 me-0">Add New Field</button>
                  </div>    
                </div>
              </div>
            <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12 d-flex alighn-item-center justify-content-center">
                    <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g class="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" class="ant-empty-img-simple-path"></path></g></g></svg>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 d-flex alighn-item-center justify-content-center">
                <span class="ant-empty-normal">No Data</span>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Bill Due Date Reminder</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
               <div class="row">
                <div class="col-12">
                    <div class="row mt-0">
                        <div class="col-9 d-flex align-items-center">
                          <label  for="exampleCheck2" class="pira-label ms-0 me-1">Alert users
                            <nz-input-number  style="width: 100px; border-radius: 4px;" [(ngModel)]="demoValue" [nzMin]="1" [nzMax]="10" [nzStep]="1"></nz-input-number>
                            <span class="ms-2">days before upcoming due date.</span></label>
                            <i
                            class="bi bi-info-circle-fill text-secondary"
                            nz-popover
                            [nzPopoverContent]="days"
                            nzPopoverPlacement="top"></i>
                            <ng-template #days>
                              <div class="rounded-2">
                                <p style="height: 70px; width: 210px;font-size: 13px;">
                                    Set up a one time reminder to be sent out before the Bill due date. A value of 0 will send the reminder on the day of the due date.
                                </p>
                              </div>
                            </ng-template>
                        </div>
                      </div>
                </div>
               </div>
            </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                  <div class="col">
                    <span class="fw-medium fs-6 ms-2">Bills Custom Fields</span>
                  </div>
                  <div class="col text-end">
                        <button  (click)="PurchaseOrdersCustomField()"   nz-button nzType="primary" class="rounded-1 me-0">Add New Field</button>
                  </div>    
                </div>
              </div>
            <div class="card-body">
            <div class="row">
              <div class="col-12">
              <nz-table nzShowPagination="false" #nestedTable [nzData]="listOfParentData" [nzPageSize]="10">
                <thead>
                  <tr>
                    <th></th>
                    <th>Label</th>
                    <th>Data Type</th>
                    <th>Display Order</th>
                    <th>Required</th>
                    <th>Include In Filters</th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                      <td></td>
                      <td>Invoice attachment</td>
                      <td>	File</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                </tbody>
              </nz-table>
            </div>
        </div>
            </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Bills</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <div class="row mt-1">
                        <div class=" col-9 d-flex align-items-center">
                            <label class="sub-text me-2">Bill Prefix</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-1 d-flex align-items-center">
                            <input
                            type="text"
                            class="form-control formField">
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class=" col-9 d-flex align-items-center">
                            <label class="sub-text me-2">Default Cost Code for Bills</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-2 d-flex align-items-center">
                            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a person" [(ngModel)]="selectedValue">
                                <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                                <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                                <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                              </nz-select>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class=" col-9 d-flex align-items-center">
                            <label nz-checkbox [(ngModel)]="checked"><span class="pira-label">Add PO suffix to bill number</span></label>
                                <i
                                class="bi bi-info-circle-fill text-secondary"
                                nz-popover
                                [nzPopoverContent]="bill"
                                nzPopoverPlacement="top"></i>
                                <ng-template #bill>
                                  <div class="rounded-2">
                                    <p style="height: 45px; width: 210px;font-size: 13px;">
                                        When selected, bill numbers will include the related PO prefix and number as a suffix.
                                    </p>
                                  </div>
                                </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Lien Waivers</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row mt-1">
                    <div class=" col-9 d-flex align-items-center">
                        <label nz-checkbox [(ngModel)]="checked"><span class="pira-label">Hides Lien Waivers on all jobs</span></label>
                            <i
                            class="bi bi-info-circle-fill text-secondary"
                            nz-popover
                            [nzPopoverContent]="modified"
                            nzPopoverPlacement="top"></i>
                            <ng-template #modified>
                              <div class="rounded-2">
                                <p style="height: 25px; width: 210px;font-size: 13px;">
                                    This will disable and hide the ability to send all Lien Waivers on Bills.
                                </p>
                              </div>
                            </ng-template>
                    </div>
                </div>
                <div class="row mt-3 mb-3">
                    <div class=" col-9 d-flex align-items-center">
                        <label nz-checkbox [(ngModel)]="checked1"><span class="pira-label">Default additional signature line to ON on printouts</span></label>
                            <i
                            class="bi bi-info-circle-fill text-secondary"
                            nz-popover
                            [nzPopoverContent]="jobs"
                            nzPopoverPlacement="right"></i>
                            <ng-template #jobs>
                              <div class="rounded-2">
                                <p style="height: 60px; width: 210px;font-size: 13px;">
                                    Allows users to default and name an additional signature line on all Lien Waiver printouts. This can be modified on individual printouts.
                                </p>
                              </div>
                            </ng-template>
                            <input
                            style="width: 150px;"
                            type="text"
                            class="form-control formField ms-2">

                    </div>
                </div>
            </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                  <div class="col">
                    <span class="fw-medium fs-6 ms-2">Lien Waiver Forms</span>
                  </div>
                  <div class="col text-end">
                        <button  (click)="LienWaiverForm()"   nz-button nzType="primary" class="rounded-1 me-0">Add New Line Waiver Forms</button>
                  </div>    
                </div>
              </div>
            <div class="card-body">
            <div class="row">
              <div class="col-12">
              <nz-table nzShowPagination="false" #nestedTable [nzData]="listOfParentData" [nzPageSize]="10">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Date Created</th>
                    <th>Default</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                      <td>Invoice Copy</td>
                      <td>	Oct 13, 2021</td>
                      <td><span class="fa fa-check"></span></td>
                      <td><button nz-button nzType="default" class="rounded-1 d-flex align-content-center justify-content-center">Delete</button></td>
                    </tr>
                    <tr>
                      <td>Invoice Copy</td>
                      <td>	Oct 13, 2021</td>
                      <td><span class="fa fa-check"></span></td>
                      <td><button nz-button nzType="default" class="rounded-1 d-flex align-content-center justify-content-center">Delete</button></td>
                    </tr>
                </tbody>
              </nz-table>
            </div>
        </div>
            </div>
        </div>
    </div>
</div>
<!-- <------stat-Modals-------->
<nz-modal
nzWidth="40%"
[(nzVisible)]="customfieldinfo"
[nzTitle]="modalTitle1"
[nzFooter]="modalFooter1"
(nzOnCancel)="handleCancel2()"
[nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '395px', 'overflow-y': 'auto',}"
[nzStyle]="{ top: '15px' }">
  <ng-template #modalTitle1>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0 fw-medium">
        Purchase Orders Custom Field
      </h1>
    </div>
  </ng-template>

  <div class="content" *nzModalContent>
      <div class="card border-0 famly-inn">
          <div class="card-head border-bottom">
              <div class="row mb-1 p-2 d-flex align-items-center">
                <div class="col">
                  <span class="fw-medium fs-6 ms-2"> Custom Field Info</span>
                </div>
      
              </div>
            </div>
                    <div class="card-body">
                      <div class="row">
                          <div class="col">
                          <label class="sub-text">
                            Label
                              <span class="text-danger fw-bolder">*</span>
                          </label>
                          <input
                          formControlName="name"
                          type="text"
                          class="form-control formField">
                      </div>
                      </div>
                      <div class="row">
                          <div class="col">
                          <label class="sub-text">
                              Data Type
                              <span class="text-danger fw-bolder">*</span>
                          </label>
                          <nz-select nzShowSearch formControlName="jobInformationTypeParameterId">
                              <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                              <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                              <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                            </nz-select>
                            </div>
                      </div>
                      <div class="row">
                          <div class="col">
                          <label class="sub-text">
                              Tool Tip Text
                          </label>
                          <textarea
                          nz-input [nzAutosize]="{ minRows: 2, maxRows: 6 }"
                          formControlName="lotInformation"
                          class="form-control formField"
                          style="height: 54px; resize: none;"
                        ></textarea>
                              </div>
                      </div>
                      <div class="row mt-1">
                        <div class=" col-9 d-flex align-items-center">
                            <label nz-checkbox [(ngModel)]="checked7"><span class="pira-label">Required?</span></label>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class=" col-9 d-flex align-items-center">
                            <label nz-checkbox [(ngModel)]="checked8"><span class="pira-label">Include In My Filters</span></label>
                        </div>
                    </div>
                      <div class="row mt-3 border-bottom pb-5">
                          <div class="col-12">
                              <label class="sub-text">Display Order 
                                  <span class="text-danger fw-bolder">*</span>
                              </label>
                              <nz-input-number
                              [nzMin]="1"
                              [nzMax]="10"
                              [nzStep]="1"
                              type="number" 
                            ></nz-input-number>
                          </div>
                      </div>
                      <div class="row mt-3 border-bottom pb-5">
                        <div class=" col-9 d-flex align-items-center">
                            <label nz-checkbox [(ngModel)]="checked9"><span class="pira-label">Show Owners?</span></label>
                        </div>
                    </div>
                      <div class="row mt-3">
                        <div class=" col-9 d-flex align-items-center">
                            <label nz-checkbox [(ngModel)]="checked9"><span class="pira-label">Allow permitted sub/vendor to view?</span></label>
                        </div>
                    </div>
                    </div>
          </div>
</div>
  <ng-template #modalFooter1>
      <div class="mt-2 align-items-center">
        <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
      </div>

    </ng-template>
</nz-modal>
<nz-modal
nzWidth="40%"
[(nzVisible)]="purchaseorderscustomfield"
[nzTitle]="purchaseorderscustomfield1"
[nzFooter]="purchaseorderscustomfield2"
(nzOnCancel)="ClosePurchaseOrdersCustomField()"
[nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '395px', 'overflow-y': 'auto',}"
[nzStyle]="{ top: '15px' }">
  <ng-template #purchaseorderscustomfield1>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0 fw-medium">Bills Custom Field
      </h1>
    </div>
  </ng-template>

  <div class="content" *nzModalContent>
      <div class="card border-0 famly-inn">
          <div class="card-head border-bottom">
              <div class="row mb-1 p-2 d-flex align-items-center">
                <div class="col">
                  <span class="fw-medium fs-6 ms-2">Custom Field Info</span>
                </div>
      
              </div>
            </div>
                    <div class="card-body">
                      <div class="row">
                          <div class="col">
                          <label class="sub-text">
                            Label
                              <span class="text-danger fw-bolder">*</span>
                          </label>
                          <input
                          formControlName="name"
                          type="text"
                          class="form-control formField">
                      </div>
                      </div>
                      <div class="row">
                          <div class="col">
                          <label class="sub-text">
                              Data Type
                              <span class="text-danger fw-bolder">*</span>
                          </label>
                          <nz-select nzShowSearch formControlName="jobInformationTypeParameterId">
                              <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                              <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                              <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                            </nz-select>
                            </div>
                      </div>
                      <div class="row">
                          <div class="col">
                          <label class="sub-text">
                              Tool Tip Text
                          </label>
                          <textarea
                          nz-input [nzAutosize]="{ minRows: 2, maxRows: 6 }"
                          formControlName="lotInformation"
                          class="form-control formField"
                          style="height: 54px; resize: none;"
                        ></textarea>
                              </div>
                      </div>
                      <div class="row mt-1">
                        <div class=" col-9 d-flex align-items-center">
                            <label nz-checkbox [(ngModel)]="checked7"><span class="pira-label">Required?</span></label>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class=" col-9 d-flex align-items-center">
                            <label nz-checkbox [(ngModel)]="checked8"><span class="pira-label">Include In My Filters</span></label>
                        </div>
                    </div>
                      <div class="row mt-3">
                          <div class="col-12">
                              <label class="sub-text">Display Order 
                                  <span class="text-danger fw-bolder">*</span>
                              </label>
                              <nz-input-number
                              [nzMin]="1"
                              [nzMax]="10"
                              [nzStep]="1"
                              type="number" 
                            ></nz-input-number>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
  <ng-template #purchaseorderscustomfield2>
      <div class="mt-2 align-items-center">
        <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
      </div>

    </ng-template>
</nz-modal>
<nz-modal
nzWidth="40%"
[(nzVisible)]="lienwaiverform"
[nzTitle]="lienwaiverform1"
[nzFooter]="lienwaiverform2"
(nzOnCancel)="CloseLienWaiverForm()"
[nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '395px', 'overflow-y': 'auto',}"
[nzStyle]="{ top: '15px' }">
  <ng-template #lienwaiverform1>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0 fw-medium">Lien Waiver Form
      </h1>
    </div>
  </ng-template>

  <div class="content" *nzModalContent>
      <div class="card border-0 famly-inn">
          <div class="card-head border-bottom">
              <div class="row mb-1 p-2 d-flex align-items-center">
                <div class="col">
                  <span class="fw-medium fs-6 ms-2">Lien Waiver Form  </span>
                </div>
      
              </div>
            </div>
            <div class="card-body">
                <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col">
                    <label class="sub-text">
                        Title <span class="text-danger fw-bolder">*</span>
                    </label>
                    <input
                    formControlName="name"
                    type="text"
                    class="form-control formField">
                </div>
                </div>
                <div class="row mt-2">
                    <div class=" col-9 d-flex align-items-center">
                        <label nz-checkbox [(ngModel)]="checked7"><span class="pira-label">Set By Default</span></label>
                    </div>
                </div>
            </div>
                </div>
            </div>
      </div>
      <div class="card border-0 famly-inn mt-3">
          <div class="card-head border-bottom">
              <div class="row mb-1 p-2 d-flex align-items-center">
                <div class="col">
                  <span class="fw-medium fs-6 ms-2">Definition of Terms </span>
                </div>
      
              </div>
            </div>
            <div class="card-body">
                <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col">
                        <label class="sub-text">Subcontractor : </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="sub-text">Job Address (Property): </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="sub-text">Lot Info (Property)</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="sub-text">Effective Date of Lien Waiver :</label><span class="pira-label ms-2">Will display date when signed</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="sub-text">Payment Amount :</label><span class="pira-label ms-2">Will display amount when payment is sent</span>
                    </div>
                </div>
            </div>
                </div>
            </div>
      </div>
      <div class="card border-0 famly-inn mt-3">
          <div class="card-head border-bottom">
              <div class="row mb-1 p-2 d-flex align-items-center">
                <div class="col">
                  <span class="fw-medium fs-6 ms-2">Lien Waiver Description</span>
                </div>
      
              </div>
            </div>
            <div class="card-body">
                <div class="row">
            <div class="col-12">
                <div class="row mt-0 border-bottom pb-5">
                    <div class="col-md-12">
                        <textarea
                            class="form-control formField"
                            style="height: 54px; resize: none;"
                            (input)="autoAdjustTextAreaHeight($event)"
                        >
                    </textarea>
                    </div>
                </div>
            </div>
                </div>
            </div>
      </div>
    </div>
  <ng-template #lienwaiverform2>
      <div class="mt-2 align-items-center">
        <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
      </div>

    </ng-template>
</nz-modal>
