<div class="row mt-5" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <div class="col" style="text-align: center;">
        <span>
            <!-- nz-icon nzType="file-done" nzTheme="outline" style="font-size: 70px;"> -->
            <img src="assets/Empty-SVGs/Messaging/Surveys/surveys.svg" style="font-size: 70px;">

        </span>

        <div class="row">
            <div class="col mt-2">
        <h1>Hear from your customers with <br> Surveys</h1>
            </div>
        </div>
        <div class="col mt-3">
        <p>Effortlessly create and manage customer surveys to quickly gain <br> valuable feedback.</p>


        </div>
    </div>
    <div class="col mt-4" style="display: flex; justify-content: center; align-items: center;">
        <button nz-button nzType="default" style="margin-right: 10px;"><i class="bi bi-box-arrow-up-right me-2"></i>Learn How</button>
        <button nz-button nzType="primary">Add a Survey</button>
    </div>
</div>











<!-- 
 <nz-modal nzWidth="68%"
 [nzFooter]="null" 
 [(nzVisible)]="ForwardingEmail" 
 [nzTitle]="surveys1"
 [nzContent]="modalContent" 
 (nzOnCancel)="close()"
 [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '400px', 'overflow-y': 'auto'}"
 [nzStyle]="{ top: '18px' }">

  <ng-template #surveys1>
    
  </ng-template>

  <ng-Content #modalContent>

    <div class="row mt-5" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
        <div class="col" style="text-align: center;">
            <span nz-icon nzType="file-done" nzTheme="outline" style="font-size: 70px;"></span>
    
            <div class="row">
                <div class="col mt-2">
            <h1>Hear from your customers with <br> Surveys</h1>
                </div>
            </div>
            <div class="col mt-3">
            <p>Effortlessly create and manage customer surveys to quickly gain <br> valuable feedback.</p>
    
    
            </div>
        </div>
        <div class="col mt-4" style="display: flex; justify-content: center; align-items: center;">
            <button nz-button nzType="default" style="margin-right: 10px;"><i class="bi bi-box-arrow-up-right me-2"></i>Learn How</button>
            <button nz-button nzType="primary">Add a Survey</button>
        </div>
    </div>

  </ng-Content>



  </nz-modal>  -->



















  





