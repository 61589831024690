import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { CommentsMessageSetupResponse } from 'src/Models/comments & message/commentMessage.Model';
import { CommentMessageService } from '../services/comments&Message/comment-message.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-comment-messages',
  templateUrl: './comment-messages.component.html',
  styleUrls: ['./comment-messages.component.css']
})
export class CommentMessagesComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();

  checked = false;
  checked1 = false;
  commentsmessages = true;
  commentMessageFields: any = {};
  toastService: any;
  CreateCommentsMessageSetupRequest: any;
  CommentsMessageSetupResponse: any;

  constructor(
    private _commentMessageService: CommentMessageService,
    private _toastService: NzMessageService) { }

  ngOnInit(): void {
    this.commentMessageFields = {
      companyParameterId: 1,
      allowOwnerToDiscussAndCommentWithSubsVendors: false,
      allowOwnersToComposeMessagesWithMoreThanOneRecipient: false
    }
    this.getCommentMessageSetupArray();
  }

  autoAdjustTextAreaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; // Set the height with a maximum of 200px
  }

  getCommentMessageSetupArray(): void {
    this._commentMessageService.getCompanyCommentMessage()
      .subscribe(res => {
        let commentMessage = res.result;
        console.log(commentMessage);
        this.commentMessageFields["allowOwnerToDiscussAndCommentWithSubsVendors"] = commentMessage.allowOwnerToDiscussAndCommentWithSubsVendors;
        this.commentMessageFields["allowOwnersToComposeMessagesWithMoreThanOneRecipient"] = commentMessage.allowOwnersToComposeMessagesWithMoreThanOneRecipient;
      });
  }

  saveButton(): void {
    console.log(this.commentMessageFields);
    this._commentMessageService.companyCommentMessagepost(this.commentMessageFields).subscribe((_response) => {
      this._toastService.success('Comments  & Messages Updated successfully!');
      this.cancel.emit();
    });
  }

  closeCommentsMessages(): void {
    this.cancel.emit();
  }
}



