import { OnInit } from '@angular/core';
import {Component} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { forkJoin, map } from 'rxjs';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';


@Component({
  selector: 'app-project-management-site-diaries-checklist',
  templateUrl: './project-management-site-diaries-checklist.component.html',
  styleUrls: ['./project-management-site-diaries-checklist.component.css']
})
export class ProjectManagementSiteDiariesChecklistComponent implements OnInit  {

  internalRes: any;
  InterUserId: any;
  SubVenId: any;
  subVenRes: SubVendorResponse[] = [];
  selectedUsers: any[] = [];
  checklistForm:FormGroup
  item: FormGroup

constructor(
  private modal: NzModalService,
  private internalUserService: InternalUserService,
  private subVendorService: SubVendorService,

  private fb: FormBuilder

){
}
  
  ngOnInit(): void {
this.InitalizeForm()
  }


InitalizeForm(){
  this.checklistForm= this.fb.group({

    CheckListArray:this.fb.array([]),

  });
}
 
  onGetAllAssigneeUser() {
    const internalUser$ = this.internalUserService.getData().pipe(map(res => res.result));
    const subVendor$ = this.subVendorService.getData().pipe(map(res => res.result));

    forkJoin([internalUser$, subVendor$]).subscribe(
      ([internalRes, subVenRes]) => {
        this.internalRes = internalRes.filter(ii => ii?.isActive === true);
        this.subVenRes = subVenRes.filter(ii => ii?.isActive === true);
        this.selectedUsers = [
          {
            groupName: 'Internal Users',
            users: this.internalRes.map(user => ({
              id: user?.id,
              name: user?.fullName,
            }))
          },
          {
            groupName: 'Sub Vendors',
            users: this.subVenRes.map(vendor => ({
              id: vendor?.id,
              name: vendor?.companyName,
            }))
          }
        ];
      },
      (error) => {
        console.error('Error fetching users or vendors:', error);
      }
    );
  }




// Haseeb CheckList Work Satrt -----------------
createPredecessorRow(): FormGroup {
  return this.fb.group({
    toDoInformationId: [null],
    serialNumber: [false],
    isComplete: [false],
    name: [''],
    applicationUserId: [null],
  });
}

get predecessorRows(): FormArray {
  return this.checklistForm.get('CheckListArray') as FormArray;
}

addPredecessorRow(): void {
  if (this.predecessorRows) {
    const newRow = this.createPredecessorRow();
    console.log('NewRoe',newRow);
    this.predecessorRows.push(newRow);
  }
}


deletePredecessorRow(index: number): void {
  if (this.predecessorRows.length > 0) {
    this.predecessorRows.removeAt(index);
  }
}

// Haseeb CheckList Work End ------------------
isItemClicked = false;
isAssigneeAdded: boolean = false;
isAttachmentAdded: boolean = false;
checklistItems: Array<any> = [];
isChecklistAdded: boolean = false;


addChecklistItem() {
  const newItem = {
    text: '', // Default text for input field
    assignee: null, // Assignee selection
    isChecked: false, // For checkbox state
    isAssigneeAdded: false, // Control assignee field display
  };
  this.checklistItems.push(newItem); // Add new item to the array


  console.log('Check List Row --', this.checklistItems);

}







toggleCheckbox(item: any) {
  item.isChecked = !item.isChecked; // Toggle checkbox state
}


onItemClick() {
  this.isAssigneeAdded = !this.isAssigneeAdded;
}

onAttachClick() {
  this.isAttachmentAdded = !this.isAttachmentAdded;
}

removeAttachments(): void {
  this.isAttachmentAdded = false;
}


toggleAssignee(item: FormGroup): void {
  item.get('isAssigneeAdded')?.setValue(!item.get('isAssigneeAdded')?.value);
}
toggleAttachment(item: FormGroup): void {
  item.get('isAttachmentAdded')?.setValue(!item.get('isAttachmentAdded')?.value);
}

// Method to delete a checklist item
deleteItem(index: number) {
  this.checklistItems.splice(index, 1);
  if (this.checklistItems.length === 0) {
    this.isChecklistAdded = false; // Reset if no items are left
  }
}

// Method to handle file uploads for a specific item
// onFilesUploaded(event, item) {
//   item.attachment = event;
// }

confirmModal?: NzModalRef;

RemoveCheckListConformModal(): void {
  this.confirmModal = this.modal.confirm({
    nzTitle: '<b>Remove CheckList?</b>',
    nzContent: '<p>All checklist items will be removed. The to-do must also be saved in order for this change to be made permanent.</p>',
    nzOkText: 'Remove',
    nzOkDanger: true,
    nzCancelText: 'Cancel',
    nzCloseIcon: null,
    nzIconType: null,
    nzOnOk: () => {
      this.checklistItems = [];
      this.confirmModal.destroy();
    },
    nzOnCancel: () => {
      this.confirmModal.destroy();
    },
    nzStyle: { top: '250px' },
    nzClassName: 'custom-modal-content',
    // Add the custom class here
  });
}





}

