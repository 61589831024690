import { Component } from '@angular/core';

@Component({
  selector: 'app-project-managment-detail',
  templateUrl: './project-managment-detail.component.html',
  styleUrls: ['./project-managment-detail.component.css']
})
export class ProjectManagmentDetailComponent {

}
