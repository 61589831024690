import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FieldDataType } from 'src/app/Core/Enums/data_types';
import { AddNewStandardFilterComponent } from '../../Modal/add-new-standard-filter/add-new-standard-filter.component';
import { CreateLookupFilterRequest, LookupFilterResponse, LookUpStandardResponse, LookupFilter, FilterSearchParameter } from '../../Models/LookupModels';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { ProjectManegmentSchedulePreDefinedCodes } from '../../Models/LookUpStandardFilterCodes';
import { LookupNameSetupResponse } from 'src/Models/All-Filters/financialBillsFilterField';
import { NzTreeNode, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { ApplicationUserForComboResponse } from 'src/Models/InternalUser/InternalUserJob';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
import { ScheduleService } from 'src/Service/Project-Management/schedule.service';
@Component({
  selector: 'app-project-mangament-schedule-filter-field',
  templateUrl: './project-mangament-schedule-filter-field.component.html',
  styleUrls: ['./project-mangament-schedule-filter-field.component.css']
})
export class ProjectMangamentScheduleFilterFieldComponent implements OnInit {
ontagsChange($event: any) {
throw new Error('Method not implemented.');
}
  Filter: boolean = false
  @Input() pageId: number;
  page_Id: number = 8;
  projectmangamentscheduleFilterForm: FormGroup;
  createLookupFilterRequests: CreateLookupFilterRequest[] = [];
  filterResponseData: LookupFilterResponse[] = [];
  lookUpStandardResponse: LookUpStandardResponse[] = [];
  defaultStandardFilter: LookupFilterResponse;
  predefinedValuesMultiSelect: string[] = [];
  formFieldValues: any = {};
  status: LookupNameSetupResponse[] = [];
  dates: LookupNameSetupResponse[] = [];
  LookupNameSetupResponse: LookupNameSetupResponse[] = [];
  PhasesNodes: NzTreeNodeOptions[] = [];
  assignedToNodes: NzTreeNodeOptions[] = [];
  tagsNodes: NzTreeNodeOptions[] = [];
  selectedDateType: string = 'CustomDate'; // Initialized to null or a default value
  dateFormat: string = 'yyyy-MM-dd';

  isFormDirty: boolean = false;
  selectedFilter: number = -1;
  editSaveFilterFormGroup: FormGroup;
  filterSearchParameter: FilterSearchParameter;
  assgindata: NzTreeNodeOptions[] = [];
  subVendorData: NzTreeNodeOptions[] = [];
  combinedNodes: NzTreeNodeOptions[] = [];
  combinedNodesMessaging: NzTreeNodeOptions[] = [];
  statusoP: { label: string, value: string }[] = [];
  DateoP: { label: string, value: string }[] = [];
  preDefinedDataCodes = {
    standardFilter: ProjectManegmentSchedulePreDefinedCodes.standardFilter,
    title: ProjectManegmentSchedulePreDefinedCodes.Title,
    assignedTo: ProjectManegmentSchedulePreDefinedCodes.AssignedTo,
    status: ProjectManegmentSchedulePreDefinedCodes.Status,
    tags: ProjectManegmentSchedulePreDefinedCodes.Tags,
    phases: ProjectManegmentSchedulePreDefinedCodes.Phases,
    date: ProjectManegmentSchedulePreDefinedCodes.Date,
  };
assignedTo: NzTreeNodeOptions[]|NzTreeNode[];

  constructor(
    public toastService: NzMessageService,
    private modal: NzModalService,
    private LookupFilterService: LookupFilterService,
    private customerInformationService: CustomerInformationService,
    private lookUpFilterService: LookupFilterService,
    private loadingIndicatorService: LoadingIndicatorService,
    private accessLocalStorageService: AccessLocalStorageService,
    private fb: FormBuilder,
    private scheduleService: ScheduleService,
    private SubVendorService: SubVendorService,
    private internalUserService: InternalUserService
  ) { }

  selectedJobId: number = -1;
  ngOnInit(): void {
    this.selectedJobId = this.accessLocalStorageService.getJobId();
    this.getStandardFilterByFormPageId(this.page_Id);
    this.initEditSaveFilter();
    this.initLoad();
  }
  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.page_Id).subscribe(res => {
      let status = res.result.filter(x => x.code === this.preDefinedDataCodes.status.code);
      let Tags = res.result.filter(x => x.code === this.preDefinedDataCodes.tags.code);
      let Phases = res.result.filter(x => x.code === this.preDefinedDataCodes.phases.code);
      let Dateop = res.result.filter(x => x.code === this.preDefinedDataCodes.date.code);
      // let StatusData = res.result.filter(x => x.code === this.preDefinedDataCodes.status.code);
      // let DateData = res.result.filter(x => x.code === this.preDefinedDataCodes.Date.code);
      this.customerInformationService.getDataforcombo().subscribe(
        (res: ResponseModelArray<ApplicationUserForComboResponse>) => {
          this.assgindata = res.result.map(vendor => ({
            title: vendor.fullName,
            value: vendor.id.toString(),
            key: vendor.id.toString(),
            isLeaf: true,
            applicationUserId: vendor.applicationUserId
          }));
          this.combineNodes();
        },
        (error) => {
          console.error('Error fetching roles:', error);
        }
      );
      this.customerInformationService.getDataforcombo().subscribe(
        (res: ResponseModelArray<ApplicationUserForComboResponse>) => {

          // assigned to//
          this.customerInformationService.getDataforcombo().subscribe((res) => {
            this.assignedToNodes = [
              {
                title: 'Select All',
                value: 'select_all',
                key: 'select_all',
                selectable: false,
                isLeaf: false,
                expanded: true,
                children: res.result.map((assignedToNodes) => ({
                  title: assignedToNodes?.fullName,
                  value: assignedToNodes.id.toString(),
                  key: assignedToNodes.id.toString(),
                  isLeaf: true
                }))
              }
            ];
          });
          this.combineNodes();
        },
        (error) => {
          console.error('Error fetching roles:', error);
        }
      );
      this.SubVendorService.getData().subscribe(
        (res: ResponseModelArray<SubVendorResponse>) => {
          this.subVendorData = res.result.map(vendor => ({
            title: vendor.companyName,
            value: vendor.id.toString(),
            key: vendor.id.toString(),
            isLeaf: true,
            subVendorId: vendor.subVendorId
          }));
          this.combineNodes();
        },
        (error) => {
          console.error('Error fetching roles:', error);
        }
      );
      this.statusoP = status.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));


   // tags//
      this.tagsNodes = [
        {
         title: 'Select All',
         value: 'select_all',
         key: 'select_all',
         selectable: false,
         isLeaf: false,
         expanded: true,
         children:Tags.map((status) => ({
          title: status.name,
          value: status.id.toString(),
          key: status.id.toString(),
          isLeaf: true
         }))
        }
       ]
          // phases//
        this.PhasesNodes = [
             {
              title: 'Select All',
              value: 'select_all',
              key: 'select_all',
              selectable: false,
              isLeaf: false,
              expanded: true,
              children: Phases.map((status) => ({
               title: status.name,
               value: status.id.toString(),
               key: status.id.toString(),
               isLeaf: true
              }))
             }
            ];
      this.DateoP = Dateop.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));
    });
    const lookupFilter: LookupFilter = {
      formNameId: this.page_Id,
    };
    this.getStandardFilterByFormPageId(this.page_Id);
    this.fetchData(lookupFilter);
  }
  combineNodes(): void {
    if (this.assgindata && this.subVendorData) {
      const internalUsersNode = {
        title: 'internalUser',
        value: '0-0',
        key: '0-0',
        children: this.assgindata,
      };
      const subVendorsNode = {
        title: 'Subs/Vendors',
        value: '0-1',
        key: '0-1',
        children: this.subVendorData,
      };
      this.combinedNodes = [internalUsersNode, subVendorsNode];
      this.combinedNodesMessaging = [internalUsersNode, subVendorsNode];
    }
  }
  fetchData(lookUpStandard: LookupFilter): void {
    this.lookUpFilterService.postData(lookUpStandard).subscribe(
      data => {
        this.lookUpStandardResponse = data.result;
      },
      error => {
        console.error('Error fetching data:', error);
      }
    );
  }
   // phases//
   onPhaseChange(selectedValues: string[]): void {
      const checkAllIndex = selectedValues.indexOf('select_all');
      if (checkAllIndex !== -1) {
       const allValues = this.PhasesNodes[0].children.map(child => child['value']);
       this.formFieldValues['phases'] = [...allValues];
      } else {
       const allValues = this.PhasesNodes[0].children.map(child => child['value']);
       this.formFieldValues['phases'] = selectedValues.filter(value => allValues.includes(value));
      }
     }
        // assigned to//
     onassignedToChange(selectedValues: string[]): void {
      const checkAllIndex = selectedValues.indexOf('select_all');
      if (checkAllIndex !== -1) {
        const allValues = this.assignedToNodes[0].children.map(child => child['value']);
        this.formFieldValues['assignedTo'] = [...allValues];
      } else {
        const allValues = this.assignedToNodes[0].children.map(child => child['value']);
        this.formFieldValues['assignedTo'] = selectedValues.filter(value => allValues.includes(value));
      }

    }
       // tags//
       OntagsChanges(selectedValues: string[]): void {
        const checkAllIndex = selectedValues.indexOf('select_all');
        if (checkAllIndex !== -1) {
          const allValues = this.tagsNodes[0].children.map(child => child['value']);
          this.formFieldValues['Tags'] = [...allValues];
        } else {
          const allValues = this.tagsNodes[0].children.map(child => child['value']);
          this.formFieldValues['Tags'] = selectedValues.filter(value => allValues.includes(value));
        }

      }



  onFilterApply(): void {
    let selectedFilter = this.formFieldValues['standardFilter'];
    let isSelectedFilterStandard = this.filterResponseData.find(x => x.id === selectedFilter && x.isNotDeleted === true);
    if (isSelectedFilterStandard && isSelectedFilterStandard != undefined) {
      this.selectedFilter = 1;
    }
    else {
      this.selectedFilter = 0;
    }
    this.createFormFieldsInArray();
    this.createLookupFilterRequests[0].page = 1;
    this.createLookupFilterRequests[0].pageSize = 10;

    this.scheduleService.getAppliedFilterData(this.createLookupFilterRequests);

  }



  getStandardFilterByFormPageId(pageId: number) {
    this.lookUpFilterService.getStandardFilterByFormPageId(pageId);
    this.lookUpFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.filterResponseData = result.result;
        this.initializeForm();
        setTimeout(() => {
          let id = this.accessLocalStorageService.getSelectedFilterId();
          this.setDefaultFieldValues(id);
        }, 100);

      }

    });
  }


  resetFilter(): void {
    Object.keys(this.formFieldValues).forEach(field => {
      if (field !== 'standardFilter') {
        if (Array.isArray(this.formFieldValues[field])) {
          this.formFieldValues[field] = [];
        } else {
          this.formFieldValues[field] = '';
        }
      }
    });
    this.selectedFilter = -1;
  }


  createFormFieldsInArray() {
    this.createLookupFilterRequests = [];
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const value = this.formFieldValues[field];
      if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 0)) {
        const newRow = {
          globalId: '00000000-0000-0000-0000-000000000000',
          companyParameterId: 0,
          lookUpStandardId: 0,
          code: code,
          meaning: '',
          valueId: 0,
          formNameId: this.page_Id,
          dataTypeId: 0,
          jobInformationId: this.selectedJobId,
          createLookupFilterParameterRequests: [null]
        };

        const correspondingResponse = this.lookUpStandardResponse.find(response => response.code === code);
        if (correspondingResponse) {
          newRow.companyParameterId = correspondingResponse.companyParameterId;
          newRow.lookUpStandardId = correspondingResponse.id;
          newRow.dataTypeId = correspondingResponse.dataTypeId;

          if (
            correspondingResponse.dataType.code === FieldDataType.SingleLineText ||
            correspondingResponse.dataType.name === 'Single-Line Text') {
            newRow.meaning = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.Dropdown ||
            correspondingResponse.dataType.name === 'Dropdown') {
            newRow.valueId = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.MultiSelectDropdown ||
            correspondingResponse.dataType.name === 'Multi-Select Dropdown'
          ) {
            if (Array.isArray(value) && value.length > 0) { // Defensive check
              newRow.createLookupFilterParameterRequests = value.map((item, index) => {
                return { valueId: item };
              });
            }
          }
        }
        this.createLookupFilterRequests.push(newRow);
      }
    });
  }


  setDefaultFieldValues(standardFilterId: number): void {
    this.predefinedValuesMultiSelect = [];
    let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
    let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;

    Object.keys(this.formFieldValues).forEach(field => {
      if (field !== 'standardFilter') {
        if (Array.isArray(this.formFieldValues[field])) {
          this.formFieldValues[field] = [];
        } else {
          this.formFieldValues[field] = '';
        }
      }
    });


    if (lookUpFiltersField.length > 0) {
      Object.keys(this.preDefinedDataCodes).forEach(field => {
        const code = this.preDefinedDataCodes[field].code;
        const fieldData = lookUpFiltersField.find(filter => filter.code === code);
        if (fieldData != undefined) {
          if (
            fieldData.dataType.code === FieldDataType.SingleLineText) {
            this.formFieldValues[field] = fieldData.meaning;
          }
          if (fieldData.dataType.code === FieldDataType.Dropdown) {
            this.formFieldValues[field] = fieldData.valueId.toString();
          }
          if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown) {
            let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
            this.predefinedValuesMultiSelect = multiSelectValues.map(String);
            this.formFieldValues[field] = this.predefinedValuesMultiSelect;
          }
        }
      });
    } else {
      console.log("lookUpFiltersField is null ", lookUpFiltersField);
    }
  }


  onChangeStandardFilter($event: number): void {
    this.selectedFilter = -1;
    const defaultFIlterId = this.getDefaultFilterValue();
    this.accessLocalStorageService.setSelectedFilterId($event);
    // New Changes By Aamir Ali - 22-Apr2024
    let id = this.accessLocalStorageService.getSelectedFilterId();
    this.setDefaultFieldValues(id);
    this.internalUserService.setSelectedFilterId(id);
    this.filterSearchParameter = {
      CompanyParameterId: 1,
      FormNameId: this.page_Id,
      LookUpFilterId: $event,
      page: 1,
      pageSize: 10,
      jobInformationId: this.selectedJobId,
    };
    if (defaultFIlterId !== $event) {
      this.internalUserService.userAppliedFilter(true);
    } else {
      this.internalUserService.userAppliedFilter(false);
    }
    this.scheduleService.getAllInternalUsersByFilter(this.filterSearchParameter);

  }



  closeFilter() {
    this.Filter = false
  }





  handleLeadSelection(value: any): void {
    this.selectedDateType = value;
  }



  openStandardFilterComponent(type: string) {
    this.createLookupFilterRequests = [];
    this.createFormFieldsInArray();
    const modalRef = this.modal.create({
      nzContent: AddNewStandardFilterComponent,
      nzFooter: null,
      nzData:
      {
        pageId: this.page_Id,
        std: this.filterResponseData,
        field: this.createLookupFilterRequests,
        clickFrom: type
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
    this.isFormDirty = false;
  }


  editSaveFilterData(): void {
    let selected_Filter_Id = this.formFieldValues['standardFilter'];
    let getSelectedFilterValue = this.filterResponseData.find(filter => filter.id === selected_Filter_Id);
    this.createFormFieldsInArray();
    this.editSaveFilterFormGroup.controls['name'].setValue(getSelectedFilterValue.name);

    if (this.editSaveFilterFormGroup.get('name')?.invalid) {
      this.toastService.error('Name field is required');
      return;
    }
    this.editSaveFilterFormGroup.controls['companyParameterId'].setValue(getSelectedFilterValue.companyParameterId);
    this.editSaveFilterFormGroup.controls['code'].setValue(getSelectedFilterValue.code);
    this.editSaveFilterFormGroup.controls['formNameId'].setValue(getSelectedFilterValue.formNameId);
    this.editSaveFilterFormGroup.controls['dataTypeId'].setValue(getSelectedFilterValue.dataTypeId);
    this.editSaveFilterFormGroup.controls['globalId'].setValue(getSelectedFilterValue.globalId);
    this.editSaveFilterFormGroup.controls['createLookupFilterRequests'].setValue(this.createLookupFilterRequests);
    this.editSaveFilterFormGroup.controls['isSetAsDefault'].setValue(getSelectedFilterValue.isSetAsDefault);
    this.lookUpFilterService.saveStandardFilterData(this.editSaveFilterFormGroup.value)
      .subscribe(
        (res: any) => {
          console.log(res.result);
          setTimeout(() => {
            this.loadingIndicatorService.hide();
            this.toastService.success('Filter saved successfully');
            this.getStandardFilterByFormPageId(this.page_Id);
            this.selectedFilter = -1;
          }, 20);
        },
        (error) => {
          console.error('Error:', error);
          this.loadingIndicatorService.hide();
          if (error.status === 500) {
            this.toastService.error('Internal server error. Please try again later.');
          } else {
            this.toastService.error('An error occurred. Please try again.');
          }
        }
      );
    this.selectedFilter = -1;
  }

  getDefaultFilterValue() {
    // this.defaultStandardFilter = this.filterResponseData.find(filter => filter.isSetAsDefault === true);
    // return this.defaultStandardFilter ? this.defaultStandardFilter.id : null;
    return this.accessLocalStorageService.getSelectedFilterId();
  }
  // ....................initialize Form Start.......................////

  initializeForm(): void {
    this.formFieldValues = {
      standardFilter: this.getDefaultFilterValue(),
      title: '',
      status: '',
      date: '',
      assignedTo: [] = [],
      tags: [] = [],
      phases: [] = [],
    };
  }
  // ....................initialize Form End.......................////


  private initEditSaveFilter(): void {
    this.editSaveFilterFormGroup = this.fb.group({
      globalId: ['00000000-0000-0000-0000-000000000000'],
      companyParameterId: [0],
      code: ['', Validators.required],
      name: ['', Validators.required],
      description: [''],
      meaning: [''],
      formNameId: [0],
      dataTypeId: [],
      isShareThisFilter: [true],
      isSetAsDefault: [false],
      createLookupFilterRequests: [[]],
    });
  }


}
