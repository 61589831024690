import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RoleTypeService } from 'src/Service/Internaluser/role-type.service';
import { FieldDataType } from 'src/app/Core/Enums/data_types';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { AddNewStandardFilterComponent } from '../../Modal/add-new-standard-filter/add-new-standard-filter.component';
import { CreateLookupFilterRequest, LookupFilterResponse, LookUpStandardResponse, LookupFilter, FilterSearchParameter } from '../../Models/LookupModels';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { ProjectManegmentSiteDiriesPreDefinedCodes, ProjectManegmentVariationPreDefinedCodes } from '../../Models/LookUpStandardFilterCodes';
import { LookupNameSetupResponse } from 'src/Models/All-Filters/financialBillsFilterField';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { NzTreeNode, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';

@Component({
  selector: 'app-project-management-variation-filter-field',
  templateUrl: './project-management-variation-filter-field.component.html',
  styleUrls: ['./project-management-variation-filter-field.component.css']
})
export class ProjectManagementVariationFilterFieldComponent implements OnInit {

  Filter: boolean = false;
  @Input() pageId: number;
  projectmanagementvariationFilterForm: FormGroup;
  createLookupFilterRequests: CreateLookupFilterRequest[] = [];
  filterResponseData: LookupFilterResponse[] = [];
  lookUpStandardResponse: LookUpStandardResponse[] = [];
  defaultStandardFilter: LookupFilterResponse;
  predefinedValuesMultiSelect: string[] = [];
  formFieldValues: any = {};
  ApprovalstatusNodes : NzTreeNodeOptions[]=[];
  AddedDate: { label: string, value: number }[] = [];
  ApprovalDate: { label: string, value: number }[] = [];
  LookupNameSetupResponse: LookupNameSetupResponse[] = [];
  selectedFilter: number = -1;

  preDefinedDataCodes = {
    standardFilter : ProjectManegmentVariationPreDefinedCodes.standardFilter,
    Search : ProjectManegmentVariationPreDefinedCodes.Search,
    ApprovalStatus : ProjectManegmentVariationPreDefinedCodes.ApprovalStatus,
    addedDate : ProjectManegmentVariationPreDefinedCodes.AddedDate,
    approvalDate : ProjectManegmentVariationPreDefinedCodes.ApprovalDate,
  };

  approvalStatus: NzTreeNodeOptions[]|NzTreeNode[];

  constructor(
    private roleService: RoleTypeService,
    private fb: FormBuilder,
    public toastService: NzMessageService,
    private cdr: ChangeDetectorRef,
    private loadingIndicatoreService: LoadingIndicatorService,
    private modal: NzModalService,
    private LookupFilterService: LookupFilterService,
    private subVendorsService: SubVendorService,
    private internalUserService: InternalUserService,
    private customerInformationService: CustomerInformationService,
    private accessLocalStorageService : AccessLocalStorageService

  ) { 
  }
  ngOnInit(): void {
    this.initLoad();
    this.ForUbvendor(),
    this.ForCombo();
  }



  initializeForm(): void {
    this.formFieldValues = {
      standardFilter: this.getDefaultFilterValue(),
      Search: '',
      approvalStatus: [] = [] ,
      subs: [] = [] ,
      createdBy: [] = [],
      addedDate: [] ,
      approvalDate: [],

    };
  }

  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(res => {
      console.log("all data",res);
      let ApprovalStatusData = res.result.filter(x => x.code === this.preDefinedDataCodes.ApprovalStatus.code);
      let AddedDateData = res.result.filter(x => x.code === this.preDefinedDataCodes.addedDate.code);
      let ApprovalDateData = res.result.filter(x => x.code === this.preDefinedDataCodes.approvalDate.code);

    // aproval status//
    
    this.ApprovalstatusNodes = [
      {
       title: 'Select All',
       value: 'select_all',
       key: 'select_all',
       selectable: false,
       isLeaf: false,
       expanded: true,
       children: ApprovalStatusData.map((status) => ({
        title: status.name,
        value: status.id.toString(),
        key: status.id.toString(),
        isLeaf: true
       }))
      }
     ];

      this.AddedDate = AddedDateData.map(res => ({
        label: res.description,
        value: res.id.toString(),

      }));
      console.log('  this.AddedDate',this.AddedDate);

      this.ApprovalDate = ApprovalDateData.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));
    })
    const lookupFilter: LookupFilter = {
      formNameId: this.pageId,
    };
    this.getStandardFilterByFormPageId(this.pageId);
    this.fetchData(lookupFilter);
  }




 onFilterApply(): void {
    let selectedFilter = this.formFieldValues['standardFilter'];
    let isSelectedFilterStandard = this.filterResponseData.find(x => x.id === selectedFilter && x.isNotDeleted === true);
    if (isSelectedFilterStandard && isSelectedFilterStandard != undefined) {
      this.selectedFilter = 1;
    }
    else {
      this.selectedFilter = 0;
    }
    this.createFormFieldsInArray();
    this.createLookupFilterRequests[0].page = 1;
    this.createLookupFilterRequests[0].pageSize = 10;

    this.internalUserService.getAppliedFilterData(this.createLookupFilterRequests);


  }
 // aproval status//
 onapprovalStatusChange(selectedValues: string[]): void {
  const checkAllIndex = selectedValues.indexOf('select_all');
  if (checkAllIndex !== -1) {
    const allValues = this.ApprovalstatusNodes[0].children.map(child => child['value']);
    this.formFieldValues['approvalStatus'] = [...allValues];
  } else {
    const allValues = this.ApprovalstatusNodes[0].children.map(child => child['value']);
    this.formFieldValues['approvalStatus'] = selectedValues.filter(value => allValues.includes(value));
  }
}


  fetchData(lookUpStandard: LookupFilter): void {
    this.LookupFilterService.postData(lookUpStandard).subscribe(
      data => {
        this.lookUpStandardResponse = data.result;
      },
      error => {
        console.error('Error fetching data:', error);
      }
    );
  }


  openStandardFilterComponent(type: string) {
    this.createLookupFilterRequests = [];
    this.createFormFieldsInArray();
    const modalRef = this.modal.create({
      nzContent: AddNewStandardFilterComponent,
      nzFooter: null,
      nzData:
      {
        pageId: this.pageId,
        std: this.filterResponseData,
        field: this.createLookupFilterRequests,
        clickFrom: type
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  getStandardFilterByFormPageId(pageId: number) {
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.filterResponseData = result.result;
        this.initializeForm();
        setTimeout(() => {
          let id = this.accessLocalStorageService.getSelectedFilterId();
          this.setDefaultFieldValues(id);
        }, 100);

      }

    });
  }



  resetFilter(): void {
    const formControlKeys = Object.keys(this.projectmanagementvariationFilterForm.controls).slice(1);
    formControlKeys.forEach(field => {
      this.projectmanagementvariationFilterForm.get(field).reset();
    });
  }
  createFormFieldsInArray() {
    this.createLookupFilterRequests = [];
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const value = this.formFieldValues[field];
      if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 0)) {
        const newRow = {
          globalId: '00000000-0000-0000-0000-000000000000',
          companyParameterId: 0,
          lookUpStandardId: 0,
          code: code,
          meaning: '',
          valueId: 0,
          formNameId: this.pageId,
          dataTypeId: 0,
          createLookupFilterParameterRequests: [null]
        };

        const correspondingResponse = this.lookUpStandardResponse.find(response => response.code === code);
        if (correspondingResponse) {
          newRow.companyParameterId = correspondingResponse.companyParameterId;
          newRow.lookUpStandardId = correspondingResponse.id;
          newRow.dataTypeId = correspondingResponse.dataTypeId;

          if (
            correspondingResponse.dataType.code === FieldDataType.SingleLineText ||
            correspondingResponse.dataType.name === 'Single-Line Text') {
            newRow.meaning = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.Dropdown ||
            correspondingResponse.dataType.name === 'Dropdown') {
            newRow.valueId = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.MultiSelectDropdown ||
            correspondingResponse.dataType.name === 'Multi-Select Dropdown'
          ) {
            newRow.createLookupFilterParameterRequests = value.map((item, index) => {
              return { valueId: item };
            });
          }
        }
        this.createLookupFilterRequests.push(newRow);
      }
    });
  }

  getDefaultFilterValue() {
   // this.defaultStandardFilter = this.filterResponseData.find(filter => filter.isSetAsDefault === true);
   // return this.defaultStandardFilter ? this.defaultStandardFilter.id : null;
    return this.accessLocalStorageService.getSelectedFilterId();
  }



  // setDefaultFieldValues(standardFilterId: number): void {
  //   this.predefinedValuesMultiSelect = [];
  //   let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
  //   let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;
  //   // Get all form control keys except the first one
  //   const formControlKeys = Object.keys(this.projectmanagementvariationFilterForm.controls).slice(1);
  //   // Reset all form controls except the first one
  //   formControlKeys.forEach(field => {
  //     this.projectmanagementvariationFilterForm.get(field).reset(); // Reset the form control
  //   });
  //   Object.keys(this.preDefinedDataCodes).forEach(field => {
  //     const code = this.preDefinedDataCodes[field].code;
  //     const fieldData = lookUpFiltersField.find(filter => filter.code === code);
  //     if (fieldData != undefined) {
  //       if (
  //         fieldData.dataType.code === FieldDataType.SingleLineText) {
  //         this.projectmanagementvariationFilterForm.get(field).setValue(fieldData.meaning);
  //       }
  //       if (fieldData.dataType.code === FieldDataType.Dropdown) {
  //         this.projectmanagementvariationFilterForm.get(field).setValue(fieldData.id);
  //       }
  //       if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown) {
  //         let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
  //         this.predefinedValuesMultiSelect = multiSelectValues.map(String);
  //         this.projectmanagementvariationFilterForm.get(field).setValue(this.predefinedValuesMultiSelect);
  //       }
  //     }
  //   });
  // }



  setDefaultFieldValues(standardFilterId: number): void {
    this.predefinedValuesMultiSelect = [];
    let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
    let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;

    Object.keys(this.formFieldValues).forEach(field => {
      if (field !== 'standardFilter') {
        if (Array.isArray(this.formFieldValues[field])) {
          this.formFieldValues[field] = [];
        } else {
          this.formFieldValues[field] = '';
        }
      }
    });


    if (lookUpFiltersField.length > 0) {
      Object.keys(this.preDefinedDataCodes).forEach(field => {
        const code = this.preDefinedDataCodes[field].code;
        const fieldData = lookUpFiltersField.find(filter => filter.code === code);
        if (fieldData != undefined) {
          if (
            fieldData.dataType.code === FieldDataType.SingleLineText) {
            this.formFieldValues[field] = fieldData.meaning;
          }
          if (fieldData.dataType.code === FieldDataType.Dropdown) {
            this.formFieldValues[field] = fieldData.id;
          }
          if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown) {
            let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
            this.predefinedValuesMultiSelect = multiSelectValues.map(String);
            this.formFieldValues[field] = this.predefinedValuesMultiSelect;
          }
        }
      });
    } else {
      console.log("lookUpFiltersField is null ", lookUpFiltersField);
    }
  }

  filterSearchParameter: FilterSearchParameter;


  onChangeStandardFilter($event: number): void {
    this.selectedFilter = -1;
    const defaultFIlterId = this.getDefaultFilterValue();
    this.accessLocalStorageService.setSelectedFilterId($event);
    // New Changes By Aamir Ali - 22-Apr2024
    let id = this.accessLocalStorageService.getSelectedFilterId();
    this.setDefaultFieldValues(id);
    this.internalUserService.setSelectedFilterId(id);
    this.filterSearchParameter = {
      CompanyParameterId: 1,
      FormNameId: this.pageId,
      LookUpFilterId: $event,
      page: 1,
      pageSize: 10
    };
    if (defaultFIlterId !== $event) {
      this.internalUserService.userAppliedFilter(true);
    } else {
      this.internalUserService.userAppliedFilter(false);
    }
    this.internalUserService.getAllInternalUsersByFilter(this.filterSearchParameter);

  }


  openFilter() {
    this.Filter = true
  }
  closeFilter() {
    this.Filter = false
  }

  CreatedBy: NzTreeNodeOptions[]=[];
  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe(res => {
      const CreatedByData = res.result;
      console.log("dataforcombo",CreatedByData);

     this.CreatedBy = [{
        title: 'Select All',
        value: 'select_all',
        key: 'select_all',
        selectable: false,
        isLeaf: false,
        expanded: true,
        children: CreatedByData.map((LOP) => ({
         title: LOP.fullName,
         value: LOP.id.toString(),
         key: LOP.id.toString(),
         isLeaf: true
        }))
       }]
    });


}
  subsNodes: NzTreeNodeOptions[]=[];

ForUbvendor() {
  this.subVendorsService.getData().subscribe(res => {
    const SubvendorAssgin = res.result;
    this.subsNodes = [
      {
       title: 'Select All',
       value: 'select_all',
       key: 'select_all',
       selectable: false,
       isLeaf: false,
       expanded: true,
       children: SubvendorAssgin.map((LOP) => ({
        title: LOP.companyName,
        value: LOP.id.toString(),
        key: LOP.id.toString(),
        isLeaf: true
       }))
      }
     ];
  });

}
}























