import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { CostCodeCategoryResponse, CostCodeResponse } from 'src/Models/InternalUser/applicationUserResponse';
import {  CostItemParameterResponse, CreateCostItemRequest, CreateCostTypeItemParameterRequest } from 'src/Models/LeadProposal/LeadProposalModels';
import { SelectionInformationResponse } from 'src/Models/Selection/selectionClasses';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LeadProposalsService } from 'src/Service/LeadProp/lead-proposals.service';
import { SelectionService } from 'src/Service/Selection/selection.service';
import { LeadProposalsPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';

interface ItemData {
  id: string;
  name: string;
  age: string;
  address: string;
}


@Component({
  selector: 'app-new-allowance',
  templateUrl: './new-allowance.component.html',
  styleUrls: ['./new-allowance.component.css']
})
export class NewAllowanceComponent implements OnInit{
  @Output() cancel = new EventEmitter<void>();
  confirmModal?: NzModalRef ; // For testing by now
  listOfData: ItemData[] = [];
  selectionOptions:SelectionInformationResponse[] = [];
  proposalStatusNodes: NzTreeNodeOptions[] = [];
  selectionNodes: NzTreeNodeOptions[]=[];
  leadOptions: CostCodeCategoryResponse[] = [];
  i = 0;
  editId: string | null = null;
  demoValue = 0;
  inputValue?: string;
  showValidationMessage: boolean = false;
  items: any[] = [];
  index: number = 0
  allowancevisible = true;
  flatfee = true;
  LineItem = true;
  newAllowanceInfomationForm: any;
  mainSaveResponseId: number | undefined;


  constructor(
     private modal: NzModalService,
     private cdr: ChangeDetectorRef,
     private selectionService:SelectionService,
     public toastsService: NzMessageService,
    private leadproposalsserv: LeadProposalsService,
    private LookupFilterService: LookupFilterService,


    ) {}

    preDefinedDataCodes = {
      LOLPCT: LeadProposalsPreDefinedCodes
    };

  ngOnInit(): void {

    this.getLeadData();
    this.initLoad();


    this.selectionService.getDataAllSelectionInfo().subscribe(res =>{

      this.selectionOptions = res.result;

      this.selectionNodes = this.selectionOptions.map((data) => ({
        title: data.title,
        value: data.id.toString(),
        key: data.id.toString(),
        isLeaf: true,
      }));
    })


    this.newAllowanceInfomationForm = {

      globalId: "00000000-0000-0000-0000-000000000000",
      jobSetupId: 0,
      title: "",
      notes: "",
      isFlatFee: true,
      isLineItems: false,
      createAllowanceSelectionParameterRequests: [
        {
          "selectionInformationId": 0
        }
      ]
    }

  }

  // saveMethodAllowance() {
  //   let data = this.newAllowanceInfomationForm ;

  //   const selectionInformation = data.selectionInformationId.map((id: string) => ({ selectionInformationId: id }));
  //   data.selectionInformationId = selectionInformation;

  //   this.selectionService.postAllowanceInformation(data).subscribe(
  //     (response) => {
  //       console.log('Data saved successfully:', response);
  //       this.toastsService.success('New CostItem Save Successfully!');
  //      let mainSaveResponseId = response.result[0].id;
  //      console.log("header id",mainSaveResponseId);

  //       if (mainSaveResponseId) {
  //         const selectedUsers = this.newAllowanceInfomationForm['createCostItemParameterRequests'] || [];
  //         const costItemRequest: CreateCostItemRequest = {
  //           headerId:  mainSaveResponseId.id,
  //           companyParameterId: 1,
  //           formNameId: 32,
  //           createCostItemParameterRequests: selectedUsers.map(userId => {
  //             const mappedCostTypeItemParameterRequests = userId.createCostTypeItemParameterRequests.map((costType: CreateCostTypeItemParameterRequest) => ({
  //               costTypeId: costType.costTypeId
  //             }));
  //             return {
  //               costItemId:mainSaveResponseId,
  //               categoryId: userId.categoryId,
  //               costCodeId: userId.costCodeId,
  //               title: userId.title,
  //               description: userId.description,
  //               quantity: userId.quantity,
  //               unit: userId.unit,
  //               unitCost: userId.unitCost,
  //               builderCost: userId.builderCost,
  //               markupValue: userId.markupValue,
  //               markupId: userId.markupId,
  //               ownerPrice: userId.ownerPrice,
  //               margin: userId.margin,
  //               profit: userId.profit,
  //               internalNotes: userId.internalNotes,
  //               includeQuantity: userId.includeQuantity,
  //               includeOwnerPrice: userId.includeOwnerPrice,
  //               createCostTypeItemParameterRequests: mappedCostTypeItemParameterRequests
  //             };
  //           })
  //         };

  //         // Call the service method with the request object
  //         this.leadproposalsserv.postCostItem(costItemRequest).subscribe(
  //           (costItemResponse) => {
  //             console.log('Cost item data saved successfully:', costItemResponse);
  //             this.toastsService.success('New Cost Item Saved Successfully!');
  //           },
  //           (costItemError) => {
  //             console.error('Error saving cost item data:', costItemError);
  //             this.toastsService.error('Error saving cost item data.');
  //           }
  //         );
  //       }

  //       this.toastsService.success('New Form Template Successfully!');
  //       this.allowancevisible = false;
  //       this.cancel.emit();
  //     },
  //     (error) => {
  //       console.error('Error saving data:', error);
  //       this.toastsService.error('Error saving data.');
  //     }
  //   );
  //   console.log('Allowance Data', this.newAllowanceInfomationForm);
  // }
  saveMethodAllowance(): void {
    const data = { ...this.newAllowanceInfomationForm }; // Make a copy of formFieldValues

    const selectionInformation = data.selectionInformationId.map((id: string) => ({ selectionInformationId: id }));
     data.selectionInformationId = selectionInformation;

    this.selectionService.postAllowanceInformation(data).subscribe(
      (response) => {
        console.log('Data saved successfully:', response);
        this.toastsService.success('New Allowance Saved Successfully!');
        this.allowancevisible=false;
        this.cancel.emit();
        this.mainSaveResponseId = response.result.id;
        if (this.mainSaveResponseId) {
          const selectedUsers = this.newAllowanceInfomationForm['createCostItemParameterRequests'] || [];
          const costItemRequest: CreateCostItemRequest = {
              headerId: response.result.id,
              companyParameterId: 1,
              formNameId: 32,
              createCostItemParameterRequests: selectedUsers.map(userId => {
                  const mappedCostTypeItemParameterRequests = userId.createCostTypeItemParameterRequests.map((costType: CreateCostTypeItemParameterRequest) => ({
                      costTypeId: costType.costTypeId
                  }));
                  return {
                      costItemId: userId.costItemId,
                      categoryId: userId.categoryId,
                      costCodeId: userId.costCodeId,
                      title: userId.title,
                      description: userId.description,
                      quantity: userId.quantity,
                      unit: userId.unit,
                      unitCost: userId.unitCost,
                      builderCost: userId.builderCost,
                      markupValue: userId.markupValue,
                      markupId: userId.markupId,
                      ownerPrice: userId.ownerPrice,
                      margin: userId.margin,
                      profit: userId.profit,
                      internalNotes: userId.internalNotes,
                      includeQuantity: userId.includeQuantity,
                      includeOwnerPrice: userId.includeOwnerPrice,
                      createCostTypeItemParameterRequests: mappedCostTypeItemParameterRequests
                  };
              })
          };

          // Call the service method with the request object
          this.leadproposalsserv.postCostItem(costItemRequest).subscribe(
              (costItemResponse) => {
                  console.log('Cost item data saved successfully:', costItemResponse);
                  this.toastsService.success('New CostItem Saved Successfully!');
              },
              (costItemError) => {
                  console.error('Error saving cost item data:', costItemError);
                  this.toastsService.error('Error saving cost item data.');
              }
          );
      }



         else {
          console.error('mainSaveResponseId is not available.');
        }


      },
      (error) => {
        console.error('Error saving data:', error);
      }
    );
  }


addNewRow(): void {
  const incompleteRows = this.items.some(item => !item.title || !item.costCodeId);
  if (incompleteRows) {
    this.showValidationMessage = true;
    return;
  }
  this.showValidationMessage = false;

  const newRow = {
    title: "",
    description: "",
    quantity: "",
    unit: "",
    unitCost: "",
    builderCost: 0,
    markupValue: 9942,
    markupId: 0,
    ownerPrice: 0,
    margin: 0,
    profit: 0,
    internalNotes: "IN",
    includeQuantity: true,
    includeOwnerPrice: true,
    createCostTypeItemParameterRequests: [] // <-- This should be createCostItemParameterRequests
  };

  this.items.push(newRow);
  this.index = this.items.length - 1; // Update the index
  this.newAllowanceInfomationForm.createCostItemParameterRequests = this.items; // Update this line
}

deleteRow(index: number) {
this.items.splice(index, 1);
this.newAllowanceInfomationForm.createCostItemParameterRequests = this.items; // Update this line
}

getLeadData() {
  console.log('Lead Pro Data:', this.leadOptions);
  this.leadproposalsserv.getAllLeadData().subscribe(data => {
    this.leadOptions = data.result;
  });
}

initLoad() {
  this.LookupFilterService.getLookUpValuesByFormNameId(37).subscribe(res => {
    let proposalStatus = res.result.filter(x => x.code === this.preDefinedDataCodes.LOLPCT);
    console.log('costtype', proposalStatus);


    this.proposalStatusNodes = proposalStatus.map(proposalStatus => ({
      title: proposalStatus.name,
      value: proposalStatus.id.toString(),
      key: proposalStatus.id.toString(),
      isLeaf: true
    }));
  });
}



  close(): void {
    this.cancel.emit();
  }

  data = [
    {
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park'
    },
    {
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park'
    },
    {
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park'
    }
  ];


  isRowVisible = true;
  isRowVisible1 = false;

  showRow1() {
    this.isRowVisible = false;
    this.isRowVisible1 = true;
  }

  showRow() {
    this.isRowVisible1 = false;
    this.isRowVisible = true;
  }

  confirmSwitchToFlatFeeAllowance(): void {
    this.modal.confirm({
      nzTitle: 'Switch to Flat Fee?',
      nzContent: 'Upon save, all line items will be removed permanently and any invoice previously created from this change order will be unlinked.',
      nzOkText: 'Use Flat Fee',
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.showRow1();
      },
    });
  }
  // showFlatFee(): void {
  //   if (this.flatfee) {
  //     if (this.confirmModal && !this.confirmModal.destroy) {
  //       this.confirmModal.destroy();
  //     }
  //     return;
  //   }
  //     this.flatfee=true
  //   if (!this.confirmModal || (this.confirmModal && this.confirmModal.destroy)) {
  //     this.confirmModal = this.modal.confirm({
  //       nzOkText: 'Use Flat Fee',
  //       nzOkType: 'primary',
  //       nzTitle: 'Switch To Flat Fee?',
  //       nzContent: 'All line items will be removed permanently upon Save.',
  //       nzOnOk: () => {
  //          this.flatfee=true
  //       },
  //       nzOnCancel: () => {
  //         this.LineItem=true
  //         this.flatfee=false
  //       }
  //     });
  //   }

  //   this.LineItem = false;
  // }

  showlineitem(): void {
    this.flatfee = false;
    this.LineItem=true
  }

  // addRow(): void {
  //   this.listOfData = [
  //     ...this.listOfData,
  //     {
  //       id: `${this.i}`,
  //       name: `Edward King ${this.i}`,
  //       age: '32',
  //       address: `London, Park Lane no. ${this.i}`
  //     }
  //   ];
  //   this.i++;
  // }

  // deleteRow(id: string): void {
  //   this.listOfData = this.listOfData.filter(d => d.id !== id);
  // }

  startEdit(id: string): void {
    this.editId = id;
  }

  stopEdit(): void {
    this.editId = null;
  }


  calculateMarginAndProfit(): void {
    // const quantity = parseFloat(this.tableFormFieldValues['quantity']);
    // const unitCost = parseFloat(this.tableFormFieldValues['unitCost']);
    // const markup = parseFloat(this.tableFormFieldValues['markup']);

    // if (!isNaN(quantity) && !isNaN(unitCost) && !isNaN(markup)) {
    //   let margin: number;
    //   let profit: number;

    //   if (markup >= unitCost) {
    //     margin = ((markup - unitCost) / unitCost) * 100;
    //     profit = (markup - unitCost) * quantity;
    //   } else {
    //     margin = 0;
    //     profit = 0;
    //   }

    //   this.tableFormFieldValues['margin'] = margin.toFixed(2);
    //   this.tableFormFieldValues['profit'] = profit.toFixed(2);
    // } else {
    //   this.tableFormFieldValues['margin'] = '0.00';
    //   this.tableFormFieldValues['profit'] = '0.00';
    // }
  }

  item = { markupId: '' };

  onMarkupIdChange(selectedValue: string) {
    this.item.markupId = selectedValue;
    console.log('Selected Value:', this.item.markupId);
  }



}
