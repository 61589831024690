<div class="container-fluid mt-2" *ngIf="data">
  <div nz-row>
    <!-- Left Side Start -->
    <div nz-col nzSpan="6" class="leftSide">
      <div class="MappedTabList" *ngFor="let item of data">
        <div nz-row class="mapRow">
          <div nz-col nzSpan="18">
            <a class="title" (click)="leadOpportunityFormEdit(item.Id)">{{ item.Title }}</a>
            <div class="customer-name-full">{{ item.CustomerInformationName }}</div>

            <!-- If no address or state, show '--' -->
            <ng-container *ngIf="!item.StreetAddress && !item.State; else showDetails">
              <div>--</div>
            </ng-container>
            <ng-template #showDetails>
              <div class="street-address">{{ item.StreetAddress }}</div>
              <div class="street-address">{{ item.Suburb }}</div>
              <div class="state">{{ item.State }}</div>
            </ng-template>
          </div>

          <!-- View Button (only show if address and state are present) -->
          <div nz-col nzSpan="6" *ngIf="item.StreetAddress && item.State">
            <button nz-button nzType="default" (click)="panToLocation(item)">View</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Left Side End -->

    <!-- Right Side Start (Map) -->
    <div nz-col nzSpan="18" class="rightSide">
      <div id="map"></div>
    </div>
    <!-- Right Side End -->
  </div>
</div>


























<!-- <iframe
  [src]="mapUrl"
     frameborder="0" scrolling="no" style="width: 100%; height: 100%;"></iframe>
     <img
     *ngIf="!mapUrl"
     alt=""
     src="https://maps.gstatic.com/mapfiles/transparent.png"
     draggable="false"
     style="width: 32px; height: 32px; user-select: none; border: 0px; padding: 0px; margin: 0px; max-width: none;"> -->



<!-- <div class="col-8">
            <iframe
              src="https://maps.google.com/maps?q=saddar karachi&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
              frameborder="0" scrolling="no" style="width: 100%; height: 100%;"></iframe>
  
          </div> -->
