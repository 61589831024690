import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-addchoice-selection-choice',
  templateUrl: './addchoice-selection-choice.component.html',
  styleUrls: ['./addchoice-selection-choice.component.css']
})
export class AddchoiceSelectionChoiceComponent {
  @Output() cancel = new EventEmitter<void>();

  selectedValue = null;
  inputValue?: string;



  selectionChoicevisible=true;
  
  selectionChoiceModelCancel(): void {
    this.cancel.emit();
  }
 
}
