<!-- card 1 -->
<nz-card nzBorderless="true" nzTitle="Cost items" [nzExtra]="extraTemplate">
    <!-- table inner card -->
    <nz-table #rowSelectionTable nzShowSizeChanger [nzData]="listOfData" [nzScroll]="{ x: '1700px' }"
        (nzCurrentPageDataChange)="onCurrentPageDataChange($event)" #middleTable nzSize="small" class="wh-100"
        nzFrontPagination="false">
        <thead>
            <tr class="fw-bold">
                <th nzLeft nzWidth="50px" [(nzChecked)]="checked" [nzIndeterminate]="indeterminate"
                    (nzCheckedChange)="onAllChecked($event)"></th>
                <th *ngFor="let column of displayedColumns">{{ column }}</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let data of rowSelectionTable.data">
                <td nzWidth="50px" nzLeft [nzChecked]="setOfCheckedId.has(data.id)"
                    (nzCheckedChange)="onItemChecked(data.id, $event)"></td>

                <td nzWidth="150px" *ngIf="displayedColumns.includes('Item')">{{ data.name}}</td>
                <td nzWidth="150px" *ngIf="displayedColumns.includes('Cost Type')">{{ data.name}}</td>
                <td nzWidth="150px" *ngIf="displayedColumns.includes('Description')">{{ data.name}}</td>
                <td nzWidth="150px" *ngIf="displayedColumns.includes('Unit Cost Unit')">{{ data.name}}</td>
                <td nzWidth="150px" *ngIf="displayedColumns.includes('Quantity Builder Cost')">{{ data.name}}</td>
                <td *ngIf="displayedColumns.includes('Markup%')"></td>
                <td *ngIf="displayedColumns.includes('Address')"></td>
                <td *ngIf="displayedColumns.includes('Age')"></td>
                <td *ngIf="displayedColumns.includes('Address')"></td>
            </tr>
        </tbody>

    </nz-table>
    <!-- table inner card -->
</nz-card>
<div class="mt-2 align-items-center">
    <div class="row">
        <div class="col-2">
        </div>
        <div class="col-10 text-end">
            <nz-pagination [nzPageIndex]="3" [nzTotal]="500" nzShowSizeChanger [nzPageSize]="10"></nz-pagination>
        </div>
    </div>
</div>
<ng-template style="margin-left: 15px;" #extraTemplate>
    <nz-space>

        <button *ngIf="flagForword === 'COMPANY_SETTINGS'" nz-button nzType="default" class="ms-2">Export</button>

        <button *ngIf="flagForword === 'COMPANY_SETTINGS'" nz-button nzType="default" class="ms-2">Import</button>

        <button nz-button nzType="default" class="ms-2">
            <!-- (click)="open()" -->
            <i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter</button>
        <button *ngIf="flagForword === 'COMPANY_SETTINGS'" nz-button nzType="primary"  class="ms-2"
            (click)="newCostItemOpenMethod('COMPANY_SETTINGS ')">New Cost Item</button>




        <!-- Button to Add to Change Order -->
        <button *ngIf="flagForword === 'CHANGE_ORDER'" nz-button nzType="primary" class="ms-2">
            Add To Change Order
        </button>

        <!-- Button to Add to Change Lead Proposal -->
        <button *ngIf="flagForword === 'NEW_PROPOSAL'" nz-button nzType="default" class="ms-2">
            Add To Change Lead Proposal
        </button>


        <!-- (click)="opencatalog()" -->
    </nz-space>
</ng-template>
<!-- card 1 -->