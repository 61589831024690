import { DataSource } from '@angular/cdk/collections';
import { WarrantyInformation } from 'src/Models/Warranty/warranty';
import { Warranty, WarrantyServiceResponse } from './../../Models/Warranty/warranty';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from '../lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { LeadOpportunityAttachmentsUploadFilesComponent } from '../lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { ProjectManagementSiteDiariesCreateFilesComponent } from '../project-management/components/Site-Diaries/project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { WarrantyNewClaimEditWarrantyCategoryComponent } from '../project-management/components/Warranty/warranty-new-claim-edit-warranty-category/warranty-new-claim-edit-warranty-category.component';
import { WarrantyNewClaimWarrantyCategoryComponent } from '../project-management/components/Warranty/warranty-new-claim-warranty-category/warranty-new-claim-warranty-category.component';
import { LookupNameSetupResponse, ScheduleItemParameterResponse } from 'src/Models/LeadTags';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { WarrantyService } from 'src/Service/Project-Management/warranty/warranty.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { ProjectManagementWarrantyPreDefinedCodes } from '../shared/component/Models/LookUpStandardFilterCodes';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ApplicationUserResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { ApplicationUserForComboResponse } from 'src/Models/InternalUser/InternalUserJob';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { saveAttachments } from '../helpers/Attachment_Save';
import { AttachmentResponse, } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
import { AuditDetailsResponse } from 'src/Models/Footer/footer.model';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { forkJoin, map } from 'rxjs';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';

@Component({
  selector: 'app-completed-warranty-service',
  templateUrl: './completed-warranty-service.component.html',
  styleUrls: ['./completed-warranty-service.component.css']
})
export class CompletedWarrantyServiceComponent {
  @Output() cancel = new EventEmitter<void>();
  @Output() WarrantyResponse = new EventEmitter<WarrantyServiceResponse>();
  @Output() warrantyResponse = new EventEmitter<boolean>();
  @Input() isEditMode: boolean = false;
  @Input() footerData: AuditDetailsResponse | null = null;
  @Output() UploadFile = new EventEmitter<File>();
  public warrantyServiceData: WarrantyServiceResponse | null = null;
  savedResponseData: WarrantyServiceResponse;
  warrantiesResponse: WarrantyServiceResponse;
  footerResponse: AuditDetailsResponse;
  TestAttachment: AttachmentResponse;
  formFieldValues: any = {};
  timeOptions: string[] = [
    '08:00 am',
    '08:30 am',
    '09:00 am',
    '09:30 am',
    '10:00 am',
    '10:30 am',
    '11:00 am',
    '11:30 am',
    '12:00 am',
    '12:30 am',
    '08:00 pm',
    '08:30 pm',
    '09:00 pm',
    '09:30 pm',
    '10:00 pm',
    '10:30 pm',
    '11:00 pm',
    '11:30 pm',
    '12:00 pm',
    '12:30 pm'
  ];
  isData: any = {};
  appointmentData: any = {};
  selectedUsers: any[] = [];
  selectedFiles: File[] = [];
  isOwnerAccepted: { name: string; };
  isBuilderAccepted: { name: string; };
  warrantiesData: WarrantyServiceResponse[];
  selectedFilesAttachment: CustomUploadFile[] = [];
  scheduleItemOption: ScheduleItemParameterResponse[] = [];
  serviceCoordinator: ApplicationUserForComboResponse[] = [];
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  ownerFeedbackOption: LookupNameSetupResponse[] = [];
  subVenRes: SubVendorResponse[] = [];
  internalRes: ApplicationUserResponse[] = [];
  test: any;
  InterUserId: number
  SubVenId: number
  initials: string = '';
  formName: string = "Completed Warranty Service";
  PAGE_ID: number = 10002;
  isdeleteEditData: true;
  responseHeader: number;
  deleteButtonEnabled: boolean;
  responseHeaderId: number = -1;
  selectedValue = null;
  date = null;
  isFormSaved = true;
  checked = false;
  checked2 = true;
  uploadfiles = true;
  isFormSaved2 = false;
  isformSaved = false;
  isButtonDisableds = false;
  isDeleteLoading: boolean = false;
  isformSave = true;
  isFormSaved3 = false;
  Taghandle = true;
  showEditForm = false;
  showDeleteButton = false;
  showCheckIcon: boolean = false;
  isSaveAndClose: boolean = false;
  isSaveAndNew: boolean = false;
  isSave: boolean = false;
  isopenDelete = this.warrantyEditData
  isfeedbackData: boolean = false;
  isSaveButtonClicked: boolean = false;
  showCompletionFields: boolean = false;
  showMeetwarrantyFields: boolean = false;
  hideForNeedsReworkFields: boolean = false;
  hideReadyForNextPhaseFields: boolean = false;
  isCheckClicked: boolean = true;
  isCheckClicked2: boolean = true;
  submitted: boolean = false;
  dateFlag: boolean = false;
  isSaveCheckClicked: boolean = true;
  showError = false; 
  time: Date | null = null;
  time2: Date | null = null;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);
  defaultOpenValue2 = new Date(0, 0, 0, 0, 0, 0);

  preDefinedDataCodes = {
    OwnerFeedback: ProjectManagementWarrantyPreDefinedCodes.OwnerFeedback,
  };

  constructor(
    private leadOppService: LeadOpportunitiesService,
    private _AttachmentService: AttachmentService,
    private modal: NzModalService,
    private warrantyService: WarrantyService,
    private CustomerInformationService: CustomerInformationService,
    public localStorageService: AccessLocalStorageService,
    private internalUserService: InternalUserService,
    private subVendorService: SubVendorService,
    private LookupFilter: LookupFilterService,
    public toastsService: NzMessageService,
    @Inject(NZ_MODAL_DATA) public warrantyEditData: any

  ) {
    console.log("warrantyEditData", warrantyEditData)
  }

  ngOnInit(): void {
    this.initLoad();
    this.ForCombo();
    this.loadData();
    this.filedsBindRecord();
    this.onGetAllAssigneeUser();

    const currentDate = new Date();

    // Initialize the warrantyFieldValues object with default values
    this.appointmentData = {
      globalId: "00000000-0000-0000-0000-000000000000",
      warrantyInformationId: this.warrantyEditData.headerId,
      assignToUserId: 0,
      completionTime: currentDate, // Current date and time in ISO 8601 format
      completionOn: currentDate, // Current date in "YYYY-MM-DD" format
      serviceOn: currentDate, // Current date in "YYYY-MM-DD" format
      assignToSubVendorId: 0,
      startTime: null,
      endTime: null,
      internalNotes: null,
      attachmentId: 0,
      isAppointmentToOwner: true,
      isBuilderOverride: 0,
      ownerFeedbackId: 0,
      appointmentNotes: null,
      isOwnerAccepted: true,
      ownerAcceptedOn: null,
      isBuilderAccepted: false,
      builderAcceptedOn: null
    };

    setTimeout(() => {
      this.showCheckIcon = true;
    }, 4000); 
    this.leadOppService.getSelectedFiles().subscribe(files => {
      this.selectedFiles = files;
    });
    this.isSaveButtonClicked = this.warrantyEditData.warrantyEditData;
    this.isfeedbackData = this.warrantyEditData.warrantyEditData;
    this.savedResponseData = this.warrantyEditData.warrantyEditData;
    this.appointmentData['globalId'] = this.warrantyEditData.warrantyEditData.globalId;
    this.appointmentData['assignToUserId'] = this.warrantyEditData.warrantyEditData.assignToUserId;
    this.appointmentData['attachmentId'] = this.warrantyEditData.warrantyEditData.attachmentId;
    this.appointmentData['ownerFeedbackId'] = this.warrantyEditData.warrantyEditData.ownerFeedbackId;
    this.appointmentData['startTime'] = this.warrantyEditData.warrantyEditData.startTime;
    this.appointmentData['endTime'] = this.warrantyEditData.warrantyEditData.endTime;
    this.appointmentData['internalNotes'] = this.warrantyEditData.warrantyEditData.internalNotes;
    this.appointmentData['isAppointmentToOwner'] = this.warrantyEditData.warrantyEditData.isAppointmentToOwner;
    this.appointmentData['isBuilderOverride'] = this.warrantyEditData.warrantyEditData.isBuilderOverride;
    this.appointmentData['appointmentNotes'] = this.warrantyEditData.warrantyEditData.appointmentNotes;
    this.appointmentData['isOwnerAccepted'] = this.warrantyEditData.warrantyEditData.isOwnerAccepted;
    this.appointmentData['ownerAcceptedOn'] = this.warrantyEditData.warrantyEditData.ownerAcceptedOn;
    this.appointmentData['isBuilderAccepted'] = this.warrantyEditData.warrantyEditData.isBuilderAccepted;
    this.appointmentData['builderAcceptedOn'] = this.warrantyEditData.warrantyEditData.builderAcceptedOn;
    this.appointmentData['serviceOn'] = this.warrantyEditData.warrantyEditData.serviceOn;
    this.appointmentData['completionOn'] = this.warrantyEditData.warrantyEditData.completionOn;
    this.appointmentData['completionTime'] = this.warrantyEditData.warrantyEditData.completionTime;
    this.appointmentData['warrantyInformationId'] = this.warrantyEditData.warrantyEditData.warrantyInformationId;
    this.appointmentData['assignToSubVendorId'] = this.warrantyEditData.warrantyEditData.assignToSubVendorId;

  }
  selectedOption: number;
  initLoad() {
    this.LookupFilter.getLookUpValuesByFormNameId(10002).subscribe(
      (res) => {
        this.lookupSetNameResponse = res.result;
        this.serviceCoordinator = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.OwnerFeedback.code
        );
        console.log('show all owner data',this.serviceCoordinator);
        if (this.serviceCoordinator.length > 0) {
          this.selectedOption = this.serviceCoordinator[0].id;
  
        }
      },
      (error) => {
        console.error('Error fetching lookup values', error);
  }
  );
  }
  TagClose(): void {
    this.cancel.emit();
  }

  Add(): void {
    const modalRef = this.modal.create({
      nzContent: WarrantyNewClaimWarrantyCategoryComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  Edit(): void {
    const modalRef = this.modal.create({
      nzContent: WarrantyNewClaimEditWarrantyCategoryComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  savewarrantyserviceform(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save'): Promise<void> {
    this.isFormSaved = true;
    this.isSaveButtonClicked = true;

    return new Promise((resolve, reject) => {
      // Check if the form is already being processed
      if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
        return reject('Already processing');
      }

      if (!this.appointmentData['assignToUserId']) {
        this.showError = true; // Show the error message
        return; // Exit the function if there's an error
      }

      // Set the appropriate action flag
      if (actionType === 'saveAndNew') {
        this.isSaveAndNew = true;
      } else if (actionType === 'saveAndClose') {
        this.isSaveAndClose = true;
      } else {
        this.isSave = true;
      }

      // Prepare the data
      let data = this.appointmentData;
      this.loadOwnerAndBuilderData();

      // Call the warranty service to save the data
      this.warrantyService.postWarrantyServiceData(data).subscribe(
        (response) => {

          const result = response.result;
          console.log("resultresult", result.id);

          this.appointmentData.globalId = result?.globalId;
          this.savedResponseData = result;

          // Notify success
          this.toastsService.success('New Warranty Service Saved Successfully!');
          this.isfeedbackData = true;

          // Emit the new warranty data to other components
          this.warrantyService.updateWarrantyData(result);

          // Make another API call with the returned ID
          if (result?.id) { // Ensure the ID exists
            this.warrantyService.WarrantyWarrantyService(this.warrantyEditData.headerId).subscribe(
              (warrantyResponse) => {
                console.log('Warranty data fetched:', warrantyResponse.result);
                // Update the shared service with the response from WarrantyWarrantyService
                this.warrantyService.updateWarrantyData(warrantyResponse.result);
                this.warrantyResponse.emit(true);
                this.WarrantyResponse.emit(response.result);
              },
              (warrantyError) => {
                console.error('Error fetching warranty details:', warrantyError);
              }
            );
          }

          if (this.selectedFilesAttachment?.length > 0) {
            saveAttachments(this.selectedFilesAttachment, this._AttachmentService, response, 30005);
          }

          resolve(); // Resolve the promise on successful save
        },
        (error) => {
          console.error('Error saving data:', error);
          reject(error); // Reject the promise on error
        }
      ).add(() => {
        // Reset loading flags regardless of success or failure
        this.resetFlags();
      });
    });
  }

  onFilesUploaded(files: CustomUploadFile[]): void {
    this.selectedFilesAttachment = files
  }

  saveAndClosewarrantyserviceform(): Promise<void> {
    return this.savewarrantyserviceform('saveAndClose').then(() => {
      this.resetForm();
      this.warrantyserviceformCancel();
    }).catch((error) => {
      console.error('Error during save and close:', error);
    });
  }

  closeForm() {
    this.isSaveAndClose = false;
  }

  resetFlags() {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
  }

  resetForm() {
    this.appointmentData = {};
  }

  warrantyserviceformCancel(): void {
    this.cancel.emit();
    this.Taghandle = false;
  }

  saveFileIdToDatabase(fileId: string): void {
  }

  ForCombo() {
    this.CustomerInformationService.getDataforcombo().subscribe((res) => {
      const serviceCoordinator = res.result;
      this.serviceCoordinator = serviceCoordinator;
    });
  }

  checkBoxToggle() {
    this.isCheckClicked = this.isCheckClicked;
    if (this.isCheckClicked) {
      this.submitted = false;
    }
  }

  filedsBindRecord() {
    this.LookupFilter.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe(
      (res) => {
        this.lookupSetNameResponse = res.result;
        console.log('warranty service data success',this.lookupSetNameResponse);
        let ownerFeedbackValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.OwnerFeedback.code
        );
        this.ownerFeedbackOption = ownerFeedbackValues;

        const serviceCompletedOption = this.ownerFeedbackOption.find(option => option.name === 'Service Completed');
        if (serviceCompletedOption) {
          this.appointmentData['ownerFeedbackId'] = serviceCompletedOption.id;
        }
      }
    );
  }

  logButtonToggle(): void {
    this.appointmentData.isBuilderOverride = !this.appointmentData.isBuilderOverride; // Toggle isBuilderOverride
  }

  updateCheckboxState(selectedDate: Date): void {
    this.appointmentData['serviceOn'] = selectedDate;
    this.isCheckClicked = false;
    this.isCheckClicked2 = false;
  }

  isDateAfterCurrent(): boolean {
    const selectedDate = new Date(this.appointmentData['serviceOn']);
    const currentDate = new Date();
    return selectedDate > currentDate;
  }

  loadOwnerAndBuilderData(): void {
    // Simulated data for owner and builder (replace with actual API calls)
    this.isOwnerAccepted = { name: 'John Doe' }; // Example owner data
    this.isBuilderAccepted = { name: 'Builder Inc.' }; // Example builder data
  }

  loadData() {
    // Example: Fetch initial data and assign to isData
    this.warrantyService.postWarrantyServiceData(this.warrantyEditData).subscribe(
      (response) => {
        this.isData = response; // Assign received data to isData
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  checkCharacterLimits(): void {
    const { internalNotes, appointmentNotes } = this.formFieldValues;
    this.isButtonDisableds = false;
    if (internalNotes?.length > 512 || appointmentNotes?.length > 512) {
      this.isButtonDisableds = true;
    }
  }

  onAssignToUserChange() {
    this.showError = false; // Reset the error when the user selects an option
  }

  ConfirmDelete(): void {
    if (this.isDeleteLoading) {
      return;
    }
    this.modal.confirm({
      nzTitle: `Delete Selection?`,
      nzContent: 'Are you sure you want to permanently delete this Selection?',
      nzOkText: `Delete`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return console.log('Okay Delete WarrantyService');

      },
      nzCentered: false,
      nzBodyStyle: { 'border-radius': '30px', 'height': 'auto', 'padding-top': '15', },
      nzStyle: {
        top: '200px',
      },
      nzClassName: 'ant-modal-confirm-custom',
      nzCloseIcon: '',
      nzIconType: '',
    });
  }

  compareFn = (o1: any, o2: any) => {
    return o1 && o2 ? o1.id === o2.id && o1.groupName === o2.groupName : o1 === o2;
  }
  
  onAssigneeChange(selectedValue: any) {
    if (selectedValue === 'me') {
      const userId = this.localStorageService.getUserLoginId();
      const selectedUser = this.internalRes.find(user => user.id === userId);

      if (selectedUser) {
        this.test = { id: selectedUser.id, groupName: 'Internal Users' };
        this.onShortName(this.test?.id);
      }
    } else {
      this.onShortName(selectedValue.id || selectedValue);
    }
    if (selectedValue.groupName === "Internal Users") {
      this.InterUserId = selectedValue.id;
    } else if (selectedValue.groupName === "Sub Vendors") {
      this.SubVenId = selectedValue.id;
    }
  }

  onShortName(selectedValue: any) {
    if (selectedValue) {
      const userIdAndNames = this.selectedUsers.flatMap(group =>
        group.users.map(user => ({
          id: user.id,
          name: user.name
        }))
      );
      let data = userIdAndNames.find(user => user.id === selectedValue);
      this.initials = this.getInitials(data?.name);
    }
  }

  getInitials(fullName: string | undefined): string {
    if (!fullName) return '';
    const nameParts = fullName.split(' ');
    const firstInitial = nameParts[0]?.charAt(0) || '';
    const lastInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1]?.charAt(0) || '' : '';
    return (firstInitial + lastInitial).toUpperCase();
  }

  onGetAllAssigneeUser() {
    const internalUser$ = this.internalUserService.getData().pipe(map(res => res.result));
    const subVendor$ = this.subVendorService.getData().pipe(map(res => res.result));

    forkJoin([internalUser$, subVendor$]).subscribe(
      ([internalRes, subVenRes]) => {
        this.internalRes = internalRes.filter(ii => ii?.isActive === true);
        this.subVenRes = subVenRes.filter(ii => ii?.isActive === true);

        this.selectedUsers = [
          {
            groupName: 'Internal Users',
            users: this.internalRes.map(user => ({
              id: user?.id,
              name: user?.fullName,
            }))
          },
          {
            groupName: 'Sub Vendors',
            users: this.subVenRes.map(vendor => ({
              id: vendor?.id,
              name: vendor?.companyName,
            }))
          }
        ];

        const userId = this.localStorageService.getUserLoginId();
        const selectedUser = this.internalRes.find(user => user.id === userId);

        if (selectedUser) {
          this.test = { id: selectedUser.id, groupName: 'Internal Users' };
          this.onAssigneeChange(this.test);
        }
      },
      (error) => {
        console.error('Error fetching users or vendors:', error);
      }
    );
  }

  onFeedbackChange() {
    const selectedFeedbackId = this.appointmentData['ownerFeedbackId'];
  
    // Show fields based on feedback ID
    this.showCompletionFields = selectedFeedbackId === 71001;
    this.showMeetwarrantyFields = selectedFeedbackId === 71005;
  
    // Hide for "Needs Re-Work" (id: 71002)
    this.hideForNeedsReworkFields = selectedFeedbackId === 71002;
  
    // Hide for "Ready for Next Phase" (id: 71006)
    this.hideReadyForNextPhaseFields = selectedFeedbackId === 71006;
  
    // Show error if "Unassigned" (id: 71007) is selected
    this.showError = selectedFeedbackId === 71007;
  }

}
interface CustomUploadFile extends NzUploadFile {
  loading?: boolean;
  error?: boolean;
  addedBy: number
  createdOn: Date;
  modifiedOn: Date;
  id: number;
  globalId: string;
}
