<nz-layout>
    <nz-page-header>
        <nz-page-header-title>
            <h1 style="font-size: 30px;">Reports</h1>
        </nz-page-header-title>
    </nz-page-header>
    <nz-content class="custom-content">
        <div class="container-fuild mb-4 m-3">
            <h2 style="color: #626262;">Favorites</h2>
            <p class="mt-2"><i>There are currently no favorite reports. Favorite a report to pin to this section.</i>
            </p>
            <!-- First sales reports -->
            <div class="row">
                <h1 class="mt-5" style="color: #626262;">Sales</h1>
            </div>
            <div class="card-container">
                <div class="row mt-2">
                    <div class="col-4">
                        <div class="card" style="width: 18rem; height: 250px;">
                            <div class="star-icon">
                                <i class="fas fa-star text-secondary"></i>
                            </div>
                            <img src="/assets/saless1.svg" alt="Chart 1">
                            <div class="card-title text-start">Lead Activities by Salesperson</div>
                            <div class="card-text text-start">A summary of lead activities grouped by each salesperson
                                in your company.</div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="card" style="width: 18rem; height: 250px; ">
                            <div class="star-icon">
                                <i class="fas fa-star text-secondary"></i>
                            </div>
                            <img src="/assets/saless2.svg" alt="Chart 1">
                            <div class="card-title text-start">Lead Count by Salesperson</div>
                            <div class="card-text text-start">Track the number of leads for each status grouped by
                                assigned salesperson.</div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="card" style="width: 18rem; height: 250px;">
                            <div class="star-icon">
                                <i class="fas fa-star text-secondary"></i>
                            </div>
                            <img src="/assets/saless3.svg" alt="Chart 1">
                            <div class="card-title text-start">Lead Status by Source</div>
                            <div class="card-text text-start">An overview of the status of each lead coming from your
                                lead sources.</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Collapse -->
            <div class="row mt-3">
                <p class="collapsible">
                    View All Sales Reports <i class="bi bi-caret-right-fill" (click)="navigateToSalesDetails()"></i>
                </p>
                <div class="content" [ngClass]="{'active': isActive}">
                    <p>Here is the content that can be shown or hidden when you click on the "View All Sales Reports"
                        button.</p>
                    <!-- Add your additional content here -->
                </div>
            </div>

            <!-- 2nd Peoject Managment Reports -->
            <div class="row">
                <h2 class="mt-4" style="color: #626262;">Project Management </h2>
            </div>
            <div class="card-container">
                <div class="row mt-2">
                    <div class="col-3">
                        <div class="card" style="width: 18rem; height: 250px;">
                            <div class="star-icon">
                                <i class="fas fa-star text-secondary"></i>
                            </div>
                            <img src="/assets/ProjectManagement1.svg" alt="Chart 1">
                            <div class="card-title text-start">Schedule Percent Complete by Job</div>
                            <div class="card-text text-start">Percent of scheduled project item completion in relation
                                to total duration of the calendar grouped by job.</div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card" style="width: 18rem; height: 250px;">
                            <div class="star-icon">
                                <i class="fas fa-star text-secondary"></i>
                            </div>
                            <img src="/assets/project2.svg" alt="Chart 1">
                            <div class="card-title text-start">Baseline vs Actual Duration By Job</div>
                            <div class="card-text text-start">The difference between the actual duration and the set
                                baseline duration for each job.</div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card" style="width: 18rem; height: 250px;">
                            <div class="star-icon">
                                <i class="fas fa-star text-secondary"></i>
                            </div>
                            <img src="/assets/project3.svg" alt="Chart 1">
                            <div class="card-title text-start">Change Order Profit</div>
                            <div class="card-text text-start">A comparison of the Change Order Profit across jobs using
                                the Change Order Owner Price and the Change Order Builder Cost.</div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card" style="width: 18rem; height: 250px;">
                            <div class="star-icon">
                                <i class="fas fa-star text-secondary"></i>
                            </div>
                            <img src="/assets/project4.svg" alt="Chart 1">
                            <div class="card-title text-start ">Daily Log Count by User </div>
                            <div class="card-text text-start">A count of daily logs created and grouped by added by
                                user.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <p class="collapsible">
                    View All Project Managment Reports <i class="bi bi-caret-right-fill"
                        (click)="navigateToProjectManagmentDetails()"></i>
                </p>
                <div class="content" [ngClass]="{'active': isActive}">
                    <p>Here is the content that can be shown or hidden when you click on the "View All Sales Reports"
                        button.</p>
                    <!-- Add your additional content here -->
                </div>
            </div>

            <!-- 3rd Financial Reports  -->
            <div class="row">
                <h2 class="mt-4" style="color: #626262;">Financial</h2>
            </div>
            <div class="card-container">
                <div class="row mt-2">
                    <div class="col-3">
                        <div class="card" style="width: 18rem; height: 280px;">
                            <div class="star-icon">
                                <i class="fas fa-star text-secondary"></i>
                            </div>
                            <div class="star-icon"></div>
                            <img src="/assets/Financial1.svg" alt="Chart 1">
                            <div class="card-title text-start">Estimated Cost vs. Actual Cost</div>
                            <div class="card-text text-start">A comparison of the total estimated costs coming from your
                                estimate with the actual costs paid from POS and Bills.</div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card" style="width: 18rem; height: 280px;">
                            <div class="star-icon">
                                <i class="fas fa-star text-secondary"></i>
                            </div>
                            <img src="/assets/Financial2.svg" alt="Chart 1">
                            <div class="card-title text-start">Receivables by Job</div>
                            <div class="card-text text-start">A comparison of the the total amount you have invoiced
                                with the amount paid against those invoices.</div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card" style="width: 18rem; height: 280px;">
                            <div class="star-icon">
                                <i class="fas fa-star text-secondary"></i>
                            </div>
                            <img src="/assets/Financial3.svg" alt="Chart 1">
                            <div class="card-title text-start">Work In Progress - Cash</div>
                            <div class="card-text text-start">Check the financial health of jobs on a cash basis.
                                Identify current cost and billing statuses based upon when cash is received or when
                                payments are made, as well as ensure jobs are on track.</div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card" style="width: 18rem; height: 280px;">
                            <div class="star-icon">
                                <i class="fas fa-star text-secondary"></i>
                            </div>
                            <img src="/assets/Financial4.svg" alt="Chart 1">
                            <div class="card-title text-start">Work in Progress - Accrual</div>
                            <div class="card-text text-start"> Check the financial health of jobs on an accrual basis.
                                Identify current cost and billing statuses based upon when revenue and expenses are
                                incurred, as well as ensure jobs are on track.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <p class="collapsible">
                    View All Financial Reports <i class="bi bi-caret-right-fill"
                        (click)="navigateToFinancialDetails()"></i>
                </p>
                <div class="content" [ngClass]="{'active': isActive}">
                    <p>Here is the content that can be shown or hidden when you click on the "View All Sales Reports"
                        button.</p>
                    <!-- Add your additional content here -->
                </div>
            </div>

        </div>
    </nz-content>
</nz-layout>