import { Component } from '@angular/core';

@Component({
  selector: 'currency-dollar-field',
  templateUrl: './currency-dollar-field.component.html',
  styleUrls: ['./currency-dollar-field.component.css']
})
export class CurrencyDollarFieldComponent {

}
