import { Component } from '@angular/core';

@Component({
  selector: 'app-choose-form-existing-contacts',
  templateUrl: './choose-form-existing-contacts.component.html',
  styleUrls: ['./choose-form-existing-contacts.component.css']
})
export class ChooseFormEXistingContactsComponent {

  isVisible = false;

  handleContactCancel(): void {
    this.isVisible = false;
  }

}
