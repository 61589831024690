<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form [formGroup]="messagingmessagesFilterForm" (ngSubmit)="onSubmit()">
    <div class="row mt-2">
      <div class="col-10 p-0">
        <div class="input-group">
          <nz-select formControlName="standardFilter" name="standardFilter"
            (ngModelChange)="onChangeStandardFilter($event)" nzShowSearch [nzAutoFocus]="true"
            nzPlaceHolder="Standard Filter" r>
            <nz-option *ngFor="let filter of filterResponseData"
              [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')" [nzValue]="filter.id" nzCustomContent>
              <div class="option-content">
                <!-- Customize content with icons and text -->
                <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
                <span>{{ filter.name }}</span>
                <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

              </div>
            </nz-option>
          </nz-select>
        </div>
      </div>

      <div class="col-1 mt-1">
        <i
        nz-popover
        [nzPopoverContent]="contentTemplate"
        [nzPopoverPlacement]="'top'"
        class="bi bi-three-dots" (click)="openStandardFilterComponent()"></i>
      </div>
    </div>

    <button nz-button nzType="primary" (click)="open()">Open</button>
    <ng-template #Messages>
      <div class="row mb-3 d-flex justify-content-between align-items-center p-0 ">
        <div class="col p-0">
          <strong>Filter</strong>
        </div>

        <div class="col p-0 me-2 text-end">
          <strong nz-icon nzType="close" class="CloseIconCursor" (click)="close()" nzTheme="outline"></strong>
        </div>

      </div>
      <!-- <div class="row  d-flex justify-content-between align-items-center">
        <div class="col-10 p-0 ">
          <div class="input-group">
            <nz-select>
              <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
              <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
            </nz-select>
          </div>
        </div>
        <div class="col-2 text-end p-0">
          <button nz-button class="border-0">
            <span nz-popover class="text-primary text-dark fw-bold fs-5" nz-icon nzType="ellipsis"
              nzTheme="outline"></span>
          </button>
        </div>
      </div> -->
    </ng-template>

    <nz-drawer [nzClosable]="false" (nzOnClose)="close()" nzWidth="20%" [nzVisible]="" nzPlacement="right"
      [nzVisible]="visible" [nzTitle]="Messages" [nzFooter]="footerTpl">
      <ng-container *nzDrawerContent>
        <div class="row famly-inn">
          <div class="col-12">
            <div class="row">
              <div class="col-12 p-0">
                <label class="sub-text">Keyword</label>
                <input formControlName="keyWord" type="text" class="form-control formField">
              </div>
            </div>
            <div class="row">
              <div class="col-12 p-0">
                <label class="sub-text">From</label>
                <input formControlName="from" type="text" class="form-control formField">
              </div>
            </div>
            <div class="row">
              <div class="col-12 p-0">
                <label class="sub-text">To</label>
                <input formControlName="to" type="text" class="form-control formField">
              </div>
            </div>

            <div class="row">
              <div class="col-12 p-0 mt-1">
                <label class="sub-text">Date</label>
                <nz-select formControlName="date" nzShowSearch nzAllowClear nzPlaceHolder="All Dates">
                  <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                  <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                  <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                </nz-select>
              </div>
            </div>
            <div class="row">
              <div class="col-12 p-0 mt-1">
                <label class="sub-text">Message Status</label>
                <nz-select formControlName="messageStatus" nzShowSearch nzAllowClear nzPlaceHolder="All Dates">
                  <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                  <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                  <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                </nz-select>
              </div>
            </div>
            <div class="row">
              <div class="col-12 p-0 mt-1">
                <label class="sub-text">Has Attachments</label>
              </div>
              <label formControlName="hasAttachments" nz-checkbox></label>
            </div>

          </div>
        </div>
      </ng-container>
      <ng-template #footerTpl>
        <div class="row">
          <div class="col-12 p-0 text-end ">
            <button nz-button nzType="default">Clear All</button>
            <button class="ms-1" nz-button nzType="primary">Apply Filter</button>
          </div>
        </div>
      </ng-template>
    </nz-drawer>


  </form>
</div>



<ng-template #contentTemplate>
  <div>
  <p>Manage Saved Views</p>
  </div>
  </ng-template>