import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, AfterViewInit, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import SignaturePad from 'signature_pad';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { SignatureSetupResponse } from 'src/Models/SignaturePad/SignaturePad';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.css']
})
export class SignaturePadComponent implements OnInit, AfterViewInit {
  @ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  context: CanvasRenderingContext2D;
  @Output() cancel = new EventEmitter<void>();
  @Output() signatureSaved = new EventEmitter<SignatureSetupResponse>();

  Taghandle = true;
  checked = false;
  signatureNeeded!: boolean;
  signaturePad!: SignaturePad;
  signatureImg!: string;
  formFieldValues: any = {};
  @Input() signature: SignatureSetupResponse;
  isCheckboxDisabled: boolean = true;
  isApprove: boolean = true;

  constructor(private LeadOpportunitiesService: LeadOpportunitiesService,
              private toastService: NzMessageService) {}

  ngOnInit(): void {
    this.formFieldValues = {
      globalId: '00000000-0000-0000-0000-000000000000',
      imageSource: '',
      applicationUserId: 1, // Verify that this value is correct
    };
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvas.nativeElement);
    this.signaturePad.penColor = 'black';
    this.signaturePad.backgroundColor = 'white';
    this.signaturePad.minWidth = 2;
    this.signaturePad.maxWidth = 3;
    this.signaturePad.dotSize = 2;

    // Set font properties
    this.context = this.canvas.nativeElement.getContext('2d');
    this.context.font = '12px Arial';
    this.context.textAlign = 'center';
    this.context.fillStyle = 'black';

    const base64Image = '';
    this.drawBase64Image(base64Image);
    this.CheckApplicationId(false);

  }

  drawBase64Image(base64Data: string) {
    const image = new Image();
    image.onload = () => {
      this.context.drawImage(image, 0, 0);
    };
    image.src = base64Data;
  }

  startDrawing(event: Event) {
    this.updateCheckboxState();
  }

  moved(event: Event) {
    this.updateCheckboxState();
  }

  updateCheckboxState() {
    this.isCheckboxDisabled = this.signaturePad.isEmpty();
  }

  onCheckboxClick(): void {
    if (this.isCheckboxDisabled) {
      this.toastService.warning('Please draw your signature first.');
      return;
    } else {
      this.isCheckboxDisabled = false;
    }
  }

  clearPad() {
    this.signaturePad.clear();
    this.isCheckboxDisabled = true;
  }

  TagClose(): void {
    this.cancel.emit();
    this.Taghandle = false;
  }

  CheckApplicationId(approveFlag: boolean): void {
    const applicationUserId = this.formFieldValues.applicationUserId;
    if (applicationUserId) {
      this.LeadOpportunitiesService.getSignatureSetupByUserId(applicationUserId).subscribe(
        (response) => {
          if (response && response.result && response.result.globalId) {
            this.formFieldValues.globalId = response.result.globalId;
            this.formFieldValues.imageSource = response.result.imageSource;
            this.drawBase64Image(this.formFieldValues.imageSource);
            
            if (this.isApprove === approveFlag) {
              this.signatureSaved.emit(response.result);
            }

            if (this.checked === true) {
              this.savePad();
            }
          }
        },
        (error) => {
          console.error('Error checking applicationUserId:', error);
        }
      );
    }
  }

  savePad(): void {
    const base64Data = this.signaturePad.toDataURL();
    this.formFieldValues['imageSource'] = base64Data;
    this.LeadOpportunitiesService.PostSignaturePad(this.formFieldValues).subscribe(
      (response) => {
        const savedId = response.result.id;
        const signatureSetupResponse: SignatureSetupResponse = {
          ...response.result,
          base64Data: base64Data,
          savedId: savedId
        };
        this.signatureSaved.emit(signatureSetupResponse);
        this.toastService.success('Signature created successfully!');
      },
      (error) => {
        console.error('Error saving signature:', error);
      }
    );
  }
}
