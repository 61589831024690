<nz-card nzTitle="Phase Details">
  <form nz-form [formGroup]="phaseForm">
    <div nz-row nzGutter="16">
      <!-- Phase Name Field -->
      <div nz-col [nzSpan]="12">
        <nz-form-item class="form-item-block">
          <nz-form-label [nzRequired]="true" nzFor="name" class="label-block">Phase Name</nz-form-label>
          <nz-form-control [nzErrorTip]="phaseNameErrorMessage || 'Required'" class="control-block">
            <input nz-input id="name" formControlName="name" #nameInput  />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  
      <!-- Phase Field -->
    <div nz-row nzGutter="16">
      <div nz-col [nzSpan]="14">
        <nz-form-item class="form-item-block">
          <nz-form-label nzFor="colorId" class="label-block">Phase Field <i nz-icon nzType="info-circle" [nzTheme]="'fill'"
            class="ms-2"
            nz-popover
            [nzPopoverContent]="PhaseColorTemp"
            nzPopoverPlacement="right"></i>

            <ng-template #PhaseColorTemp>
              <div>
                  <p
                      style="width: 210px; font-size: 13px; padding: auto;">
                      Selecting a default color for a particular phase determines how that phase will display on the schedule
                  </p>
              </div>
          </ng-template>
          </nz-form-label>
          <nz-form-control>
            <nz-select
                nzShowSearch
                [nzCustomTemplate]="defaultTemplate"
                id="colorId"
                formControlName="colorId"
                (ngModelChange)="updateSelectedColor($event)"
                [style.width.%]="100"
                [disabled]="false">
                <nz-option
                    nzCustomContent
                    *ngFor="let option of colors"
                    [nzLabel]="option.name"
                    [nzValue]="option.id">
                    <span
                        class="color-circle-drop"
                        [style.backgroundColor]="option.colorCollection"></span>
                    <ng-container>
                        <span
                            class="color-name">{{
                            option.name
                            }}</span>
                    </ng-container>
                </nz-option>
            </nz-select>
            <ng-template #defaultTemplate
                let-selected>
                <div
                    class="color-item d-flex align-items-center">
                    <span
                        class="color-circle-drop"
                        [style.backgroundColor]="selectedColor"></span>
                    <span class="color-name">{{
                        selected.nzLabel
                        }}</span>
                </div>
            </ng-template>
        </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>


</nz-card>

<!-- Footer Buttons -->
<div class="form-footer" nz-row nzJustify="end">
  <div nz-col>
    <nz-space>
      <button *ngIf="editPhase" nz-button nzType="default" (click)="showConfirmDelete()" >Delete</button>
      <button nz-button nzType="primary" (click)="onSaveAndNew()" [nzLoading]="isSaveAndNew" [disabled]="isSaving">Save & New</button>
      <button nz-button nzType="primary" [nzLoading]="isSaving" [disabled]="isSaving" (click)="onSave()">Save</button>
    </nz-space>
  </div>
</div>
