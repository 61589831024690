<nz-modal
  nzWidth="40%"
  [(nzVisible)]="scheduleVisible"
  [nzTitle]="JobCreateNewScratchModalTitle"
  [nzFooter]="scheduleModalfooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '68vh',
    'overflow-y': 'auto',
  }"
  [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="CancelSchedule()"
>
  <ng-template #JobCreateNewScratchModalTitle>
    <div class="p-0 m-0">
      <div class="row">
        <h1>Notify Users</h1>
      </div>
    </div>
  </ng-template>

  <nz-content *nzModalContent>
    <div class="content">
      <div class="card-container">
        <nz-card 
          [nzTitle]="Notifications"
          class=" border-0 m-0 p-0 rounded-2 mb-3"
        >
          <ng-template #Notifications>
            <div class="row m-0 p-0">
              <h5 class="m-0 p-0" nz-typography>Notifications</h5>
            </div>
          </ng-template>
          <div class="card-body m-0 p-0">
            <div class="row mb-3">
              <div class="col-12">
                <label nz-checkbox>Notify Assignees</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-10 ms-3">
                <nz-tree-select
                  [nzNodes]="combinedNodes"
                  nzAllowClear="false"
                  nzCheckable
                  nzCheckAll="true"
                  [nzVirtualHeight]="'250px'"
                  ></nz-tree-select>
                  <!-- (ngModelChange)="onNodeSelectionChange($event)" -->
              </div>
            </div>
            <div class="row mb-3">
                <label class="sub-text">Notification Details</label>
              <div class="col-12">
              <textarea    [nzAutosize]="{ minRows: 3, maxRows: 6 }"
              formControlName="lotInformation"
              class="form-control formField" nz-input></textarea>
              </div>
            </div>
          </div>
        </nz-card>
        <nz-card 
          [nzTitle]="Confirmations"
          class="border-0 m-0 p-0 rounded-2"
        >
          <ng-template #Confirmations>
            <div class="row m-0 p-0">
              <h5 class=" m-0 p-0" nz-typography>Confirmations</h5>
            </div>
          </ng-template>
          <div class="card-body">
                <div class="row">
                    <div class="col-12">
                      <label nz-checkbox>Request confirmation from all assignees</label>
                    </div>
            </div>
          </div>
        </nz-card>
      </div>
    </div>
  </nz-content>
  <ng-template #scheduleModalfooter>
    <div>
      <button nz-button nzType="default" class="rounded-1">Cancel</button>
      <button nz-button nzType="primary" class="rounded-1">
        Send Notification Now
      </button>
    </div>
  </ng-template>
</nz-modal>
