<nz-modal
  [(nzVisible)]="newleadActivityVisible"
  [nzFooter]="newleadActivityfooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '600px',
    'overflow-y': 'auto',
    'overflow-x': 'hidden',
  }"
  [nzStyle]="{ top: '37px' }"
  nzWidth="35%"
  [nzTitle]="addActivity"
  (nzOnCancel)="newleadActivityCancel10()"

>
  <ng-template #addActivity>
    <h1 nz-typography>Add Lead Activity Type</h1>
  </ng-template>
  <ng-container *nzModalContent>
    <div class="card border-0 mt-0">
      <div class="card-head border-bottom">
          <div class="row">
            <h2 class="ms-3" nz-typography>Add Lead Activity Type</h2>
          </div>
      </div>
      <div class="card-body">
        <div class="row">
          <!-- <div class="col-12">
            <label class="sub-text">Title <span class="text-danger fw-bolder">*</span></label>
            <input [(ngModel)]="formFieldValues['name']" name="name" type="text" class="form-control formField">
          </div> -->
          <div class="col-12">
            <label class="sub-text" for="name">Title</label>
            <input
              type="text"
              [(ngModel)]="formFieldValues['name']"
              name="name"
              (ngModelChange)="checkCharacterLimits()"
              #stateInput="ngModel"
              class="form-control formField"
              [ngClass]="{'border-danger': formFieldValues['name']?.length > 50}"
              required
              pattern="^.{50}$"
            />

            <div *ngIf="formFieldValues['name']?.length > 50" class="text-danger">
              {{ formFieldValues['name'].length - 50 }} characters over.
            </div>
          </div>
        </div>
        <nz-row [nzGutter]="30">
          <nz-col nzSpan="16">
            <p class="fw-bold famly-inn">
              Set up a color and icon for the activity
            </p>
            <p class="fw-bold famly-inn">
              type:
            </p>
          </nz-col>
          <nz-col nzSpan="8">
            <p class="fw-bold famly-inn">
              Preview:
            </p>
          </nz-col>
        </nz-row>
        <nz-row [nzGutter]="30">
          <nz-col nzSpan="16">
            <label class="sub-text fw-bold">Color</label>
              <nz-select nzShowSearch [nzCustomTemplate]="defaultTemplate" [style.width.%]="100" [(ngModel)]="formFieldValues['colorId']" name="colorId" (ngModelChange)="updateSelectedColor($event)">
              <nz-option nzCustomContent *ngFor="let option of colors" [nzLabel]="option.name" [nzValue]="option.id">
                <span class="color-circle-drop" [style.backgroundColor]="option.colorCollection"></span>
                <ng-container>
                    <span class="color-name">{{ option.name }}</span>
                </ng-container>
              </nz-option>
            </nz-select>
            <ng-template #defaultTemplate let-selected>
              <div class="color-item">
                <span *ngIf="addLeadActivityTypeResponse === undefined"  class="color-circle-drop" [style.backgroundColor]="selectedColor"></span>
                <span *ngIf="addLeadActivityTypeResponse !== undefined" class="color-circle-drop" [style.backgroundColor]="selectedColor"></span>
                <span class="color-name">{{ selected.nzLabel }}</span>
              </div>
            </ng-template>
            <label class="sub-text">Icon</label>
              <nz-select nzShowSearch [nzCustomTemplate]="defaultTemplate1" [(ngModel)]="formFieldValues['iconId']" name="iconId" [style.width.%]="100" (ngModelChange)="updateSelectedIcon($event)">
              <nz-option nzCustomContent *ngFor="let option of icons" [nzLabel]="option.name" [nzValue]="option.id">
                <span [ngClass]="option.sourceCollection" style="margin-right: 7px;"></span>
                <ng-container>
                  <span>{{ option.name }}</span>
              </ng-container>
              </nz-option>
            </nz-select>
            <ng-template #defaultTemplate1 let-selected>
                <span *ngIf="addLeadActivityTypeResponse === undefined" [ngClass]="sourceCollectionIcon" style="margin-right: 7px;"></span>
                <span *ngIf="addLeadActivityTypeResponse !== undefined" [ngClass]="sourceCollectionIcon" style="margin-right: 7px;"></span>
                <span>{{ selected.nzLabel }}</span>
            </ng-template>
          </nz-col>
          <nz-col nzSpan="8">
            <div class="row">
              <div class="col-12">
                <span class="color-circle" [style.backgroundColor]="selectedColor">
                  <span
                  style="
                  font-size: 50px;
                  padding-right: 10px;
                  padding-top: 20px;
                  margin-left: 20px;
                  color: #FFFFFF;"
                  [ngClass]="sourceCollectionIcon"
                  >
                  </span>
                  </span>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-12">
                <span [ngClass]="sourceCollectionIcon"></span>
              </div>
            </div> -->
          </nz-col>
        </nz-row>
      </div>
    </div>
  </ng-container>
  <ng-template #newleadActivityfooter>
    <div class="mt-2 align-items-center">
      <button *ngIf="addLeadActivityTypeResponse !== undefined" nz-button nzType="default" class="rounded-1 me-0" (click)="deleteLeadActivityType(addLeadActivityTypeResponse?.globalId)"   [disabled]="isButtonDisableds" [nzLoading]="isDeleteLoading">Delete</button>
      <button *ngIf="addLeadActivityTypeResponse === undefined"  nz-button nzType="primary" class="rounded-1 me-0" (click)="saveLeadActivityType()" [nzLoading]="isSave"   [disabled]="isButtonDisableds">Save</button>
      <button *ngIf="addLeadActivityTypeResponse !== undefined"nz-button nzType="primary" class="rounded-1 me-0" (click)="EditLeadActivityType()" [nzLoading]="isEdit"       [disabled]="isButtonDisableds">Save</button>
    </div>
  </ng-template>
</nz-modal>
