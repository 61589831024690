<nz-modal
  nzWidth="30%"
  [(nzVisible)]="isSelectLead"
  [nzTitle]="LeadTitle"
  [nzBodyStyle]="{
    background: '#FFFFFF',
    padding: '14px',
    'max-height': '540px',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '250px' }"
  (nzOnCancel)="closeSelectLead()"
  [nzFooter]="null"
>
  <ng-template #LeadTitle>
    <div class="row p-0 m-0">
      <h4 class="p-0 m-0 fw-bold">Select a Lead</h4>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <div class="row mt-4 mb-4 ms-2 me-2">
      <div class="col-12">
        <label class="cityDropdown fw-bold">Please Select a Lead</label>
        <div class="input-group">
          <nz-select
            (click)="leadActivity()"
            nzShowSearch
            [lang]="true"
            nzAutoFocus="true"
            nzPlaceHolder=""
           
          >
            <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
            <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
            <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
          </nz-select>
        </div>
      </div>
    </div>
  </div>
</nz-modal>

<nz-modal
  nzWidth="50%"
  [(nzVisible)]="isVisible7"
  [nzTitle]="LeadActivityTitle"
  [nzFooter]="LeadActivityFooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    height: '70vh',
    'max-height': '70vh',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '37px' }"
  (nzOnCancel)="handleCancel8()"
>
  <ng-template #LeadActivityTitle>
    <nz-breadcrumb nz-page-header-breadcrumb>
      <nz-breadcrumb-item
        >ZH-106 Lot 1713 (45) Zachary Street Burnside 3023</nz-breadcrumb-item
      >
    </nz-breadcrumb>
    <div class="row p-0 m-0">
      <h4 class="famly-inn p-0 m-0 fw-bold">Lead Activity</h4>
    </div>
  </ng-template>

  <div class="content" *nzModalContent>    
    <div class="card-container">
      <nz-tabset nzType="card">
        <nz-tab *ngFor="let tab of tabs" [nzTitle]="leaAdctivityTabs(tab)">
          <ng-container *ngIf="tab === 1">
            <ng-template #extraTemplate>
              <label nz-checkbox formControlName="markComplete" (click)="toggleFields()" >Mark Complete</label>
            </ng-template>
            <!-- body work -->
            <nz-card
              [nzTitle]="Activity"
              nzBorderless="true"
              [nzExtra]="extraTemplate"
            >
            <ng-template #Activity>
              <h6 class="famly-inn">Activity</h6>
            </ng-template>
              <h6 *ngIf="checked18"  class="famly-inn fw-medium">Schedule Follow-Up</h6>
              <div class="row" *ngIf="checked18">
                <div class="col-3">
                  <label class="sub-text famly-inn">Follow-Up Date</label>
                  <nz-date-picker nzPlaceHolder=" " nzSuffixIcon="false" formControlName="followupDate"  ></nz-date-picker>
                </div>
              </div>
              <div class="row mb-2" *ngIf="checked18">
                <div class="col-3">
                  <label class="sub-text famly-inn">Follow-Up Time</label>
                  <nz-time-picker [(ngModel)]="time" formControlName="followupTime"   nzFormat="HH:mm" nzPlaceHolder=" "></nz-time-picker>
                </div>
              </div>
              <div class="row gx-1">
                <div class="col-1">
                </div>
                <div class="col-6">
                    <label class="sub-text">Tags</label>
                </div>
                <div class="col-5">
                    <label class="sub-text">Color</label>
                </div>
            </div> 
              <div class="row gx-1">
                <div class="col-1">
                    <nz-avatar nzIcon="phone" style="background-color:  rgb(136, 170, 199);"></nz-avatar>
                </div>
                <div class="col-6">
                    <div class="row gx-0">
                        <div class="col-9">
                            <nz-select ngModel="lucy" [style.width.%]="100">
                                <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
                                <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
                                <nz-option nzValue="disabled" nzLabel="Disabled"></nz-option>
                            </nz-select>
                        </div>
                        <div class="col-3">
                            <button nz-button type="button" class="none-buttom p-0 m-0 ms-1" (click)="addNewLeadActivityModal()">
                                <span>Add</span>
                              </button>
                            <button nz-button type="button" class="none-buttom p-0 m-0 ms-2">
                                <span>Edit</span>
                              </button>
                        </div>
                    </div> 
                </div>
                <div class="col-5">
                    <div class="input-group">
                    <nz-tree-select
                      style="width: 100%"
                      nzShowSearch
                      formControlName="color"
                      nzAllowClear="false"
                      nzCheckable
                      nzCheckAll="true"
                      nzPlaceHolder="Select Division"
                    ></nz-tree-select>
                  </div>
                </div>
              </div> 
              <div class="row mt-2">
                <div class="col-3">
                  <label class="sub-text fw-bold">Activity Date</label>
                  <nz-date-picker nzPlaceHolder=" " nzSuffixIcon="false" formControlName="activityDate"></nz-date-picker>
                </div>
                <div class="col-3">
                  <label class="sub-text fw-bold">Start Time</label>
                  <nz-time-picker
                    [(ngModel)]="time"
                    formControlName="startTime"  
                    nzFormat="HH:mm"
                    nzPlaceHolder=" "
                  ></nz-time-picker>
                </div>
                <div class="col-3">
                  <label class="sub-text fw-bold">Activity Date</label>
                  <nz-time-picker
                    [(ngModel)]="time"
                    formControlName="activityDate"
                    nzFormat="HH:mm"
                    nzPlaceHolder=" "
                  ></nz-time-picker>
                </div>
              </div>

             
              <div class="row mt-2">
                <div class="col-6">
                  <label class="sub-text fw-bold"
                    >Reminder

                    <i
                      class="bi bi-info-circle-fill text-secondary"
                      nz-popover
                      [nzPopoverContent]="RemindercontentTemplate"
                      nzPopoverPlacement="left"
                    ></i>
                    <ng-template #RemindercontentTemplate>
                      <div class="rounded-2">
                        <p style="height: auto; width: 180px">
                          The Reminder, when selected, will alert you prior to your activity. Alerts will be sent via email or text.
                        </p>
                      </div>
                    </ng-template>
                  </label>
                  <div class="input-group">
                    <nz-select
                      nzShowSearch
                      [lang]="true"
                      formControlName="reminder"
                      nzAutoFocus="true"
                      nzPlaceHolder="--None--"
                    >
                      <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                      <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                      <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                    </nz-select>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-4">
                  <label class="sub-text fw-bold">Assigned User <span class="text-danger">*</span> </label>
                  <div class="input-group">
                    <nz-tree-select
                      style="width: 100%"
                      nzShowSearch
                      formControlName="assignedUser"
                      nzAllowClear="false"
                      nzCheckable
                      nzCheckAll="true"
                      nzPlaceHolder=""
                    ></nz-tree-select>
                  </div>
                </div>
                <div class="col-4">
                  <label class="sub-text fw-bold"
                    >Attendees
                    <i
                      class="bi bi-info-circle-fill text-secondary"
                      nz-popover
                      [nzPopoverContent]="AttendeescontentTemplate"
                      nzPopoverPlacement="top"
                    ></i>
                    <ng-template #AttendeescontentTemplate>
                      <div class="rounded-2">
                        <p style="height: auto; width: 250px">
                          Attendees allows additional users to have access to this activity.
                        </p>
                      </div>
                    </ng-template>
                  </label>
                  <div class="input-group">
                    <nz-tree-select
                      style="width: 100%"
                      nzShowSearch
                      nzAllowClear="false"
                      nzCheckable
                      formControlName="attendees"
                      nzCheckAll="true"
                      nzPlaceHolder=""
                    ></nz-tree-select>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-2">
                  <label class="sub-text fw-bold">Initiated By </label>
                  <nz-radio-group formControlname="initiatedBy">
                    <div class="mt-2">
                      <label formControlName="salesPerson" nz-radio nzValue="A">Salesperson</label>
                    </div>
                    <div class="mt-2">
                      <label formControlName="lead" nz-radio nzValue="B">Lead</label>
                    </div>
                    <div class="mt-2">
                      <label formControlName="others" nz-radio nzValue="C">Others</label>
                    </div>
                  </nz-radio-group>
                </div>
              </div>
            </nz-card>

            <!-- saecond card -->
            <nz-card
              [nzTitle]="Location"
              nzBorderless="true"
              [nzExtra]="LocationTemplate">
              <ng-template #Location>
                <h6 class="famly-inn">Location</h6>
              </ng-template>
              <div class="row">
                <div class="col-8">
                  <label class="sub-text fw-bold">Street Address</label>
                  <input formControlName="streetAddress"  type="text" class="formField form-control" />
                </div>
              </div>
              <div class="row">
                <div class="col-8">
                  <label class="sub-text fw-bold">Suburb</label>
                  <input   formControlName="suburb"  type="text" class="formField form-control" />
                </div>
              </div>
              <div class="row mt-2 gx-0 gx-3">
                <div class="col-4">
                  <label class="sub-text fw-bold">State</label>
                  <input formControlName="state" type="text" class="formField form-control" />
                </div>
                <div class="col-4">
                  <label class="sub-text fw-bold">Postal Code</label>
                  <input formControlName="postalCode" type="text" class="formField form-control" />
                </div>
              </div>
            </nz-card>
            <ng-template #LocationTemplate>
              <button
                nz-button
                nzType="primary"
                nz-dropdown
                [nzDropdownMenu]="menu4"
                nzTrigger="click"
              >
                Copy Address
                <span nz-icon nzType="down"></span>
              </button>
              <nz-dropdown-menu #menu4="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item>Contact Form</li>
                  <li nz-menu-item>Contact Lead</li>
                </ul>
              </nz-dropdown-menu>
            </ng-template>
            <!-- saecond card -->
            <!-- third card -->
            <nz-card nzBorderless="true" [nzTitle]="Details">
              <ng-template #Details>
                <h6 class="famly-inn">Details</h6>
              </ng-template>
              <div class="row">
                <div class="col-8">
                  <label class="sub-text fw-bold">Description</label>
                  <textarea class="rounded-1" formControlName="description" rows="2" nz-input></textarea>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <label class="sub-text fw-bold">Lead Contact Email</label>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <p>dilpal_singh-hotmail.com</p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <label class="sub-text fw-bold">Lead Contact Cell</label>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12"><a href="">0431 724 111</a></div>
              </div>
            </nz-card>
            <!-- third card -->
            <!-- body work -->
          </ng-container>
          <ng-container *ngIf="tab === 2">
            <nz-card
              [nzTitle]="Email"
              nzBorderless="true"
              [nzExtra]="EmailTemplate"
            >
            <ng-template #Email>
              <h6 class="famly-inn">Email</h6>
            </ng-template>
              <div class="row" *ngIf="!showEmailFields">
                <div class="col-6">
                  <label class="famly-inn"
                    >Send an email with this lead Activity.</label
                  >
                </div>
              </div>
              <div class="row mt-3" *ngIf="!showEmailFields">
                <div class="col-12">
                  <button
                    nz-button
                    class="rounded-1"
                    nzType="default"
                    (click)="toggleEmailFields()"
                  >
                    Attach Buildertrend Message
                  </button>
                  <button
                    class="ms-3 rounded-1"
                    nz-button
                    nzType="default"
                    disabled
                    nz-popover
                    [nzPopoverContent]="buttonEmialTemplate"
                    nzPopoverPlacement="top"
                  >
                    <ng-template #buttonEmialTemplate>
                      <div class="rounded-2">
                        <p class="famly-inn" style="height: auto; width: 200px">
                          You must save the Lead Activity before composing an external email
                        </p>
                      </div>
                    </ng-template>
                    Attach External Email
                  </button>
                </div>
              </div>
              <div class="ng-container" *ngIf="showEmailFields">
                <div class="row mt-1">
                  <div class="col-3">
                    <h6 class="famly-inn   fw-medium">Compose Message</h6>
                  </div>
                  <div class="col-7"></div>
                  <div class="col-2 p-0">
                    <span class="famly-inn ">Mail Merge
                      <i class="bi bi-info-circle-fill text-secondary" nz-popover
                      [nzPopoverContent]="contentTemplate" nzPopoverPlacement="left"></i>
                  <ng-template #contentTemplate>
                      <div class="container-fuild" style="height: auto; width: 450px; ">
                          <div class="row mb-3">
                            <div class="col-12">
                              <label class="famly-inn fw-bold">Mail Merge</label>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <p class="famly-inn">Template or update a lead email with placeholder text.</p>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-12">
                              <p class="famly-inn">Use the fields below as placeholders and we will populate the field before it is actually sent:</p>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-12">
                              <label class="famly-inn fw-bold">[[FirstName]] [[LastName]]</label>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-12">
                              <p class="famly-inn">The Customer Contact's First and Last Name for that specific lead is populated.</p>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-12">
                              <label class="famly-inn fw-bold">[[DisplayName]]</label>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-12">
                              <p class="famly-inn">The Customer Contact's Display Name for that specific lead is populated.</p>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-12">
                              <label class="famly-inn fw-bold">[[Signature]]</label>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-12">
                              <p class="famly-inn">The signature text and/or image is populated for the Lead's assigned Salesperson.
                                 The signature text and image can be set up for each Salesperson in User Preferences.</p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <p class="famly-inn">Example:</p>
                            </div>
                          </div>
                          <div class="row mb-1">
                            <div class="col-12">
                              <p class="famly-inn">You want to setup an email drip campaign that can be used for any of your leads.
                                 To avoid having to personalize each lead email greeting or signature, you can set up a lead activity template with the placeholder fields so that we can merge in your selected greeting.
                                 "Dear [[DisplayName]]" would bring your Lead's name into the email message before being sent, and show to them as "Dear John Smith"</p>
                            </div>
                          </div>
                      </div>
                  </ng-template>
                    </span>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <label class="sub-text">Subject</label>
                    <input formControlName="subject" type="text" class="formField form-control" />
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <label class="sub-text">Body</label>
                    <textarea formControlName="body" rows="4" nz-input></textarea>
                  </div>
                </div>

<!--  -->

                <div class="row mt-3">
                  <div class="col-12">
                    <label class="sub-text">Attachments</label>
                    <div class="mt-1">
                      <button nz-button nzType="default">Add</button>
                      <button class="ms-2" nz-button nzType="default">
                        Creat New Doc
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </nz-card>
            <ng-template #EmailTemplate>
              
                  <button
                  *ngIf="showEmailFields"
                   class="rounded-1 "
                    nz-button
                    nzType="default"
                    (click)="cancelEmailFields()"
                  >
                    Cancel
                  </button>
            </ng-template>
          </ng-container>
        </nz-tab>
      </nz-tabset>
  </div>
</div>
  <ng-template #LeadActivityFooter>
    <div class="mt-2 align-items-center">
      <button nz-button nzType="primary" class="rounded-1 me-0">
        Save & New
      </button>
      <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
      <button nz-button nzType="primary" class="rounded-1 me-0">
        Save & Close
      </button>
    </div>
  </ng-template>
</nz-modal>