<nz-modal nzWidth="35%" [(nzVisible)]="CostInformation" [nzFooter]="footer" [nzTitle]="surveys1" (nzOnCancel)="close()" [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px','height': '70vh', 'max-height': '70vh', 'overflow-y': 'auto'}" [nzStyle]="{ top: '25px' }">
  <ng-template #surveys1>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0 famly-inn">Cost Code</h1>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <div class="card border-0">
      <div class="card-head border-bottom">
        <div class="row mb-0 p-2 d-flex align-items-center">
          <span class="fw-medium fs-6 ms-2">Adding a Cost Code</span>
        </div>
      </div>
      <div class="card-body">
        <div class="row gx-2">
          <div class="col-6">
            <label for="text" class="sub-text"
              >Title <span class="text-danger">*</span></label
            >
            <input
              type="text"
              [(ngModel)]="formFieldValues['title']"
              name="title"
              class="form-control formField"
            />
          </div>
          <div class="col-6">
            <label for="text" class="sub-text"
              >Cost Code Category<span class="text-danger">*</span></label
            >
            <nz-select
              nzShowSearch
              [(ngModel)]="formFieldValues['costCodeId']"
              name="costCodeId"
            >
              <nz-option [nzLabel]="'Non'" [nzValue]="0"></nz-option>
              <nz-option
                *ngFor="let option of costCategory"
                [nzLabel]="option.title"
                [nzValue]="option.id"
              ></nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label for="text" class="sub-text">Sub Code Of</label>
            <nz-select
              nzShowSearch
              [(ngModel)]="formFieldValues['isSubCodeOf']"
              name="isSubCodeOf"
              (ngModelChange)="subCodeChanged()"
            >
              <nz-option
                [nzLabel]="'-- No Parent --'"
                [nzValue]="0"
              ></nz-option>
              <nz-option
                *ngFor="let option of costSubCode"
                [nzLabel]="option.title"
                [nzValue]="option.id"
              ></nz-option>
            </nz-select>
          </div>
        </div>

        <div class="row mt-3 border-bottom pb-5">
          <div class="col">
            <lable
              nz-checkbox
              class="sub-text"
              [(ngModel)]="formFieldValues['timeClockLaborCode']"
              name="timeClockLaborCode"
              (nzCheckedChange)="toggleFields()"
              
            >
           
            Default Labor Cost
              <i
              class="bi bi-info-circle-fill text-secondary ms-1"
              nz-popover
              [nzPopoverContent]="JobTypes"
              nzPopoverPlacement="top"
            ></i>
            </lable>
            <ng-template #JobTypes>
                <p class=" p-2" style="height: 70px; width: 210px; font-size: 13px">
                  Checking this box will make this code available on the Time
                  Clock.
                </p>
            </ng-template>
          </div>
          <div class="col" *ngIf="checked">
            <label for="text" class="sub-text"> Default Labor Cost </label>
            <i
              class="bi bi-info-circle-fill text-secondary ms-2"
              nz-popover
              [nzPopoverContent]="contact"
              nzPopoverPlacement="top"
            ></i>
            <ng-template #contact>
              <div class="rounded-2">
                <p style="height: 100px; width: 210px; font-size: 13px">
                  This allows you to associate a fixed cost rate to a specific
                  cost code. Hourly Rate Tracking needs to be set to "By Cost
                  Code" within Time Clock Setup.
                </p>
              </div>
            </ng-template>
            <span class="text-danger ms-1">*</span>
            <input
              type="number"
              [(ngModel)]="formFieldValues['defaultLaborCost']"
              name="defaultLaborCost"
              class="form-control formField"
            />
          </div>
        </div>
        <div class="row mt-3 border-bottom pb-5">
          <div class="col-12">
            <label for="text" class="sub-text">Details</label>
            <textarea
            nz-input
            [nzAutosize]="{ minRows: 3, maxRows: 8 }"
              [(ngModel)]="formFieldValues['detail']"
              name="detail"
              class="form-control formField"
            ></textarea>
         
          </div>
        </div>
        <nz-divider></nz-divider>
        <div class="row mt-3"  >
          <div class="col-12">
            <label
              nz-checkbox
              [(ngModel)]="formFieldValues['isSubCodeOf']"
              name="isSubCodeOf"
              class="pira-label"
              >isActive</label
            >
          </div>
        </div>
      </div>
  </div>
</div>
  <ng-template #footer>
    <div class="mt-2 mb-2 align-items-center">
      <nz-button-group>
        <button nzType="primary" dizable nz-button (click)="saveAndCloseButton()">Save & close</button>
        <button nzTrigger="click" nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
          <span nz-icon nzType="down" nzTheme="outline"></span>
        </button>
        <nz-dropdown-menu #menu1="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item>Save</li>
            <li nz-menu-item>Save & New</li>
          </ul>
        </nz-dropdown-menu>
      </nz-button-group>
    </div>
  </ng-template>
</nz-modal>