import { Subscription, map } from 'rxjs';
import { leadOpportunities } from 'src/Models/CustomerInformation';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { SalesComponent } from 'src/app/shared/component/sales/sales.component';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { AddLeadActivityTypeComponent } from '../../Modal/add-lead-activity-type/add-lead-activity-type.component';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { LeadActivityActivityViewPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { colorService } from 'src/Service/Job-List/Job-Information/color.service';
import { colorInformation } from 'src/Models/Job-List/Job-Information/color';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { LeadActivityResponse } from 'src/Models/LeadActivityTemplate/LeadActivityTemplate';

@Component({
  selector: 'lead-opportunities-activity-view-header',
  templateUrl: './lead-opportunities-activity-view-header.component.html',
  styleUrls: ['./lead-opportunities-activity-view-header.component.css']
})
export class LeadOpportunitiesActivityViewHeaderComponent implements OnInit {
  PAGE_ID: number = 4;
  leadActivityViewForm: FormGroup;
  // LEAD_OPPR_ACT_TEMPL: string = FiltersName.LEAD_OPPR_ACT_TEMPL;
  LEAD_OPPR_ACT_VIEW: string = FiltersName.LEAD_OPPR_ACT_VIEW;
  // LEAD_OPPR_LEAD_PROP: string = FiltersName.LEAD_OPPR_LEAD_PROP;
  // LEAD_OPPR_LIST_VIEW: string = FiltersName.LEAD_OPPR_LIST_VIEW;
  // LEAD_OPPR_MAP: string = FiltersName.LEAD_OPPR_MAP;
  // LEAD_OPPR_PROP_TEMP: string = FiltersName.LEAD_OPPR_PROP_TEMP;

  selectedValue = null;
  tabs = [1, 2];
  isVisible8: boolean;
  formFieldValues: any = {};
  // leadOpportunityViewForm: FormGroup;
  leadActivityResponce:LeadActivityResponse;
  assignedUserNodes: NzTreeNodeOptions[] = [];
  attendeesNodes: NzTreeNodeOptions[] = [];
  activityType: { label: string; value: string }[] = [];
  reminder: { label: string; value: string }[] = [];
  colors: { label: string; value: string }[] = [];
  leadOptions: { label: string; value: string }[] = [];
  isSelectLead = false;
  time = new Date();
  checked18 = false;
  isVisible6 = false;
  isVisible7 = false;
  showEmailFields = false;

  ///-------- Grid Properties Start------------ //
  columnSizes: { columnName: string, widthSize: number }[] = [];
  displayedColumns: string[] = [];
  isColumnSticky: boolean[] = new Array(this.displayedColumns.length).fill(false);
  expandedRows: number[] = [];
  ///-------- Grid Properties End------------ //


  ///-------- Columns Properties Start------------ //

  displayColumnSubscription: Subscription;
  selectedFilter: number = -1;

  ///-------- Columns Properties End------------ //


  constructor(private modal: NzModalService,
    private msg: NzMessageService,
    private fb: FormBuilder,
    private colorService:colorService,
    private customerInformationService: CustomerInformationService,
    private filterService: FilterSideMenuBarService,
    private LookupFilterService: LookupFilterService,
    private leadserv:LeadOpportunitiesService,
    private standardViewService: StandardViewForAllService,
    private changeDetectorRef: ChangeDetectorRef,


  ) {
    this.displayedColumns = [];
   }

  preDefinedDataCodes = {
    activitytype: LeadActivityActivityViewPreDefinedCodes,
    reminderFeild: LeadActivityActivityViewPreDefinedCodes,
  };

  openFilter(component: string, pageId: number) {
    this.filterService.toggleFilter(component, pageId);
  }

  ngOnInit(): void {
    this.displayedColumns = [];
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });

    this.displayColumnSubscription = this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
      if (standardType != -1) {
        this.selectedFilter = standardType;
      } else {
        this.selectedFilter = standardType;
      }

    });
   this.ForCombo();
   this.initLoad();
   this.getColor();
   this.getLeadData();
  }

  openSelectLead(): void {
    this.isSelectLead = true;
  }

  closeSelectLead(): void {
    this.isSelectLead = false;
  }


  leadActivity() {
    this.isSelectLead = false;
    this.isVisible7 = true;
  }
  handleCancel8(): void {
    this.isVisible7 = false;
  }


  leaAdctivityTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'General';
      case 2:
        return 'Attach an Email';
      default:
        return '';
    }
  }

  toggleFields(): void {
    this.checked18 = !this.checked18;
  }

  onChange1(result: Date): void {
    console.log('onChange: ', result);
  }


  toggleEmailFields() {
    this.showEmailFields = !this.showEmailFields;
  }
  cancelEmailFields() {
    this.showEmailFields = false;
  }


  leadActivity1() {
    this.isVisible6 = true;
  }

  handleCancel() {
    this.isVisible6 = false;

  }

  handleCancel19(): void {
    this.isVisible8 = false;
  }

  salesCommonComponentOpen() {
    const modalRef = this.modal.create({
      nzContent: SalesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();

    });
  }

  leadOpportunityActivityView(): void {

    this.formFieldValues = {
      globalId: ['00000000-0000-0000-0000-000000000000'],
      markComplete: false,
      followupDate: '',
      followupTime: '',
      activityTypeId: [],
      colorId: [],
      activityOn: '',
      startTime: '',
      endTime: '',
      reminderId: [],
      assignedUser: []=[],
      attendees: []=[],
      salesperson: false,
      lead: false,
      other: false,
      address: '',
      suburb: '',
      state: '',
      postalCode: '',
      description: '',
      subject: '',
      body: '',
      createLeadActivityUserParameterRequests:[]
    };
  }

  //    LeadSettings(): void {
  //     const modalRef = this.modal.create({
  //       nzContent: SalesComponent,
  //       nzFooter: null,
  //     });
  //     modalRef.componentInstance.cancel.subscribe(() => {
  //       modalRef.destroy();
  //     });
  // }

  addNewLeadActivityModal() {
    const modalRef = this.modal.create({
      nzContent: AddLeadActivityTypeComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();

    });
  }

  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe(
      (res) => {

        // -----------single Select ---------------

        let activityTypeValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.activitytype
        );
        let reminderValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.reminderFeild
        );


        // -----------single Select ---------------

        this.activityType = activityTypeValues.map((LOP) => ({
          label: LOP.description,
          value: LOP.id.toString(),
        }));

        this.reminder = reminderValues.map((reminders) => ({
          label: reminders.description,
          value: reminders.id.toString(),
        }));

      }
    );
  }

  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      const assignedUser = res.result;

      const attendees = res.result;

      // console.log('dataforcombo', assignedUser);

      this.assignedUserNodes = assignedUser.map((AUser) => ({
        title: AUser.fullName,
        key: AUser.id.toString(),
        isLeaf: true,
      }));

      this.attendeesNodes = attendees.map((Adees) => ({
        title: Adees.fullName,
        key: Adees.id.toString(),
        isLeaf: true,
      }));
    });
  }

  getColor(){
    this.colorService.getData().subscribe((res:any)=>{
      const colorGet = res.result;
      console.log('color',this.colors);

      this.colors = colorGet.map((COL)=>({
        label: COL.name,
        value: COL.id.toString(),
      }))
      console.log('color',this.colors);
    })

  }

  getLeadData() {
    this.leadserv.getAllLeadData().subscribe((res: any) => {
      const leadData = res.result;
      console.log('Lead Data:', leadData);

      // Check if leadData is an array
      if (Array.isArray(leadData)) {
        this.leadOptions = leadData.map((lead) => ({
          label: lead.title,
          value: lead.id.toString(),
        }));
        console.log('Lead Options:', this.leadOptions);
      } else {
        console.error('Lead Data is not an array:', leadData);
      }
    });
  }



  saveButton(){
    let data = this.formFieldValues;
    console.log('Show FormFieldValues',data);
    this.leadserv.postLeadActivity(data).subscribe((res)=>{
      this.leadActivityResponce=res.result;

    })

  }





  //Grid Start //


  saveColumnSizes(event: any) {
    event.forEach(col => {
      const columnName = col.column.field;
      const widthSize = col.newWidth;
      const existingIndex = this.columnSizes.findIndex(item => item.columnName === columnName);

      if (existingIndex !== -1) {
        this.columnSizes[existingIndex].widthSize = widthSize;
      } else {
        this.columnSizes.push({ columnName, widthSize });
      }
    });

  }

  toggleColumnSticky(index: number) {
    this.isColumnSticky[index] = !this.isColumnSticky[index];
    if (this.isColumnSticky[index]) {
      for (let i = 0; i < index; i++) {
        this.isColumnSticky[i] = true;
      }
    }
  }

  isExpanded(rowIndex: number): boolean {
    return this.expandedRows.includes(rowIndex);
  }


  toggleAdditionalEmails(rowIndex: number): void {
    const index = this.expandedRows.indexOf(rowIndex);
    if (index > -1) {
      this.expandedRows.splice(index, 1);
    } else {
      this.expandedRows.push(rowIndex);
    }
  }


  //Grid End //

}
