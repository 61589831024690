<nz-modal
nzWidth="80%"
[(nzVisible)]="selections"
[nzTitle]="selections1"
[nzFooter]="selections2"
(nzOnCancel)="closeSelections()"
[nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '72vh', 'overflow-y': 'auto',}"
[nzStyle]="{ top: '15px','height': '100%',  }">
<ng-template #selections1> <div class="row p-0 m-0">
    <h1 class="p-0 m-0 fw-medium">Selections</h1></div>
</ng-template>
<div  class="cantent" *nzModalContent>
<form [formGroup]="selectionSetupForm">
    <div class="row famly-inn"  fxLayout="row" fxLayoutAlign="space-between">
        <div class="col-12">
            <div class="card border-0">
                <div class="card-head border-bottom">
                    <div class="row mb-0 p-2 d-flex align-items-center">
                      <div class="col">
                        <span class="fw-medium fs-6 ms-2">Default Viewing & Notification Options</span>
                      </div>
            
                    </div>
                  </div>
                <div class="card-body">
                    <div class="row mt-0">
                        <div class="row">
                            <label class="sub-text me-2">Default Builder view</label>
                        </div>
                        <div class="row">
                            <div class="col-2">
                            <nz-select
                                formControlName="selectionBuilderViewId"
                                nzShowSearch nz-input>
                                <nz-option *ngFor="let role of SelectionBuilder" [nzLabel]="role.name" [nzValue]="role.id"></nz-option>
                            </nz-select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row">
                            <label class="sub-text me-2">Default Owner view</label>
                        </div>
                        <div class="row">
                            <div class="col-2">
                                <nz-select
                                formControlName="selectionOwnerViewId"
                                nzShowSearch nz-input>
                                <nz-option *ngFor="let role of SelectionOwner" [nzLabel]="role.name" [nzValue]="role.id"></nz-option>
                            </nz-select>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class=" col-9 d-flex align-items-center">
                            <label formControlName="defaultToExpandCategoryLocationView" nz-checkbox  [(ngModel)]="checked" >Default to expand Category & Location view</label>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-9 d-flex align-items-center">
                          <label  for="exampleCheck2" class="pira-label ms-0 me-1">
                            Alert users
                            <nz-input-number formControlName="alertUserNumber"  style="width: 100px; border-radius: 4px;" [(ngModel)]="demoValue" [nzMin]="1" [nzMax]="10" [nzStep]="1"></nz-input-number>
                            <span class="ms-2">days before upcoming deadline</span></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card border-0 mt-3">
                <div class="card-head border-bottom">
                    <div class="row mb-0 p-2 d-flex align-items-center">
                        <div class="col">
                            <span class="fw-medium fs-6 ms-2">Allowances</span>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="row mt-2">
                                <div class=" col-9 d-flex align-items-center">
                                    <label formControlName="allowancesOnNewJob" nz-checkbox   [(ngModel)]="checked1" >Allowances on new jobs</label>
                                    <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="setting" nzPopoverPlacement="top"></i>
                                    <ng-template #setting>
                                      <div class="rounded-2">
                                        <p style="height: 70px; width: 210px; font-size: 13px;">
                                            This setting does not apply when creating a job from a template that has allowances enabled, in which case allowances will be included.
                                        </p>
                                      </div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class=" col-9 d-flex align-items-center">
                                    <label formControlName="showAllowancesToSubsVendors" nz-checkbox   [(ngModel)]="checked2" >Show allowances to Subs/Vendors</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card border-0 mt-3">
                <div class="card-head border-bottom">
                    <div class="row mb-0 p-2 d-flex align-items-center">
                        <div class="col">
                            <span class="fw-medium fs-6 ms-2">Owner Options</span>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="row mt-2">
                                <div class=" col-9 d-flex align-items-center">
                                    <label formControlName="showVendorContactInfoToOwner" nz-checkbox  [(ngModel)]="checked3" >Show Vendor contact info to Owner</label>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class=" col-9 d-flex align-items-center">
                                    <label formControlName="showLineItemsToOwner" nz-checkbox  [(ngModel)]="checked4" >Allow Owner Change Order requests by</label>
                                    <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="enable" nzPopoverPlacement="top"></i>
                                    <ng-template #enable>
                                      <div class="rounded-2">
                                        <p style="height: 230px; width: 210px; font-size: 13px;">
                                            This setting will enable the "Show Line Items to Owner" flag on all new Selection Choice by default. This can be individually enabled/disabled on each Selection Choice.
                                            <br>
                                            <br>
                                            Description, Quantity, Unit Price and Price will be shown for each line item, but the Owner will Not be permitted to see builder cost Or markup.
                                        </p>
                                      </div>
                                    </ng-template> 
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class=" col-9 d-flex align-items-center">
                                    <label formControlName="defaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs" nz-checkbox  [(ngModel)]="checked5" >Default To Allow Owner To "Peek" at Locked Selections For All New Jobs</label>
                                    <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="This" nzPopoverPlacement="top"></i>
                                    <ng-template #This>
                                      <div class="rounded-2">
                                        <p style="height: 175px; width: 210px; font-size: 13px;">
                                            This setting will allow the Owner To view "Locked" Selections on new jobs And can be Set from either the Job Settings Or Selections Setup pages. Allowing Owners To view "Locked" Selections can also be set on an individual job basis On the Job Owner page.
                                        </p>
                                      </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card border-0 mt-3">
                <div class="card-head border-bottom">
                    <div class="row mb-0 p-2 d-flex align-items-center">
                      <div class="col">
                        <span class="fw-medium fs-6 ms-2">Default Change Order Description</span>
                      </div>
            
                    </div>
                  </div>
                <div class="card-body">
                    <div class="row mt-2">
                        <div class=" col-9 d-flex align-items-center">
                            <label formControlName="onNewJobsUpdateJobRunningTotalWhenASelectionIsApproved" nz-checkbox  [(ngModel)]="checked6" >On New Jobs Update Job Running Total When a Selection Is Approved</label>
                            <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="ChangeOrder" nzPopoverPlacement="top"></i>
                            <ng-template #ChangeOrder>
                              <div class="rounded-2">
                                <p style="height: 240px; width: 210px; font-size: 13px;">
                                    This setting will be the Default For all Of your New Jobs. If  [(ngModel)]="checked" the Job Running Total will update When either a Selection Or a Change Order Is approved throughout the job.
                                    <br>
                                    <br>
                                    If UN [(ngModel)]="checked" the Job Running Total will ONLY be updated by Approved Change Orders. You will need To create a Change Order To account For any selection allowance overages.
                                    <br>
                                    <br>
                                    This setting can be changed For all New jobs In the Jobs Settings Setup Or Selections Setup.
                                </p>
                              </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="row">
                            <label class="sub-text me-2">Require Signature on Selections</label>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <nz-select
                                formControlName="requireSignatureOnSelectionSetupId"
                                nzShowSearch nz-input>
                                <nz-option *ngFor="let role of RequireSignatureOnSelectionSetup" [nzLabel]="role.name" [nzValue]="role.id"></nz-option>
                            </nz-select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <label class="sub-text me-2">Selections approval disclaimer</label>
                    </div>
                    <div class="row">
                        <label class="pira-label me-2">I confirm that my action here represents my electronic signature and is binding.</label>
                    </div>
                    <div class="row mt-2">
                        <span class="ms-0">
                            <button   *ngIf="!isInputVisible"
                            nz-button
                            (click)="toggleVisibility()" 
                            data-testid="saveDraft"
                            aria-disabled="false"
                            type="button"
                            class=" ant-btn1 ant-btn-default"
                            >
                                <span>Change</span>
                            </button>
                        </span>
                    </div>
                    <div *ngIf="isInputVisible" class="row">
                        <label formControlName="selectionsApprovalDisclaimer" class="sub-text me-2">Selections approval disclaimer</label>
                    </div>
                    <div *ngIf="isInputVisible" class="row mt-1">
                        <div>
                            <p-editor [(ngModel)]="text" [style]="{ height: '120px' }"></p-editor>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card border-0 mt-3">
                <div class="card-head border-bottom">
                    <div class="row mb-0 p-2 d-flex align-items-center">
                        <div class="col">
                            <span class="fw-medium fs-6 ms-2">Display on Printout</span>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                <div class="col-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="row mt-2">
                                <div class=" col-9 d-flex align-items-center">
                                    <label formControlName="approvedChoicesOnly" nz-checkbox  [(ngModel)]="checked7"> pproved Choices Only</label>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class=" col-9 d-flex align-items-center">
                                    <label formControlName="subsVendors" nz-checkbox  [(ngModel)]="checked8"> Subs/Vendors</label>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class=" col-9 d-flex align-items-center">
                                    <label formControlName="installers" nz-checkbox  [(ngModel)]="checked9"> Installers</label>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class=" col-9 d-flex align-items-center">
                                    <label formControlName="selectionDescription" nz-checkbox  [(ngModel)]="checked10"> Selection Description</label>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class=" col-9 d-flex align-items-center">
                                    <label formControlName="discussions" nz-checkbox  [(ngModel)]="checked11"> Discussions</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row mt-2">
                                <div class=" col-9 d-flex align-items-center">
                                    <label formControlName="pricing" nz-checkbox  [(ngModel)]="checked12">Pricing</label>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class=" col-9 d-flex align-items-center">
                                    <label formControlName="addedBy" nz-checkbox  [(ngModel)]="checked13">Added By</label>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class=" col-9 d-flex align-items-center">
                                    <label formControlName="choiceDescription" nz-checkbox  [(ngModel)]="checked14">Choice Description</label>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class=" col-9 d-flex align-items-center">
                                    <label formControlName="lineItems" nz-checkbox  [(ngModel)]="checked15">Line Items</label>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class=" col-9 d-flex align-items-center">
                                    <label formControlName="customFields" nz-checkbox  [(ngModel)]="checked16">Custom Fields</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
            <div class="card border-0 mt-3">
                <div class="card-head border-bottom">
                    <div class="row mb-0 p-2 d-flex align-items-center">
                      <div class="col">
                        <span class="fw-medium fs-6 ms-2">Selections Custom Fields</span>
                      </div>
                      <div class="col text-end">
                        <button nz-button nzType="primary" class="rounded-1 me-0">Add New Field</button>
                      </div>    
                    </div>
                  </div>
                <div class="card-body">
                    <div class="card-body" style="    padding: 16px;">
                        <nz-table  nzFrontPagination="false">
                            <thead>
                                <tr style="background-color: #f1f4fa;">
                                  <th>Lable</th>
                                  <th>Data Type</th>
                                  <th>Display Order</th>
                                  <th>Required</th>
                                  <th>Include In Filters</th>
                                </tr>
                              </thead>
                            <tbody>
                            <!-- <tr *ngFor="let item of  CustomField">
                            <td  (click)="openform(item.id)">{{ item.label }}</td>
                            <td>{{ item.dataTypeId }}</td>
                            <td> <div class="color-box">{{ item.displayOrder }}</div></td>
                            <td> <div class="color-box">{{ item.isRequired }}</div></td>
                            <td> <div class="color-box">{{ item.includeInMyFilters }}</div></td>
                            </tr> -->
                            </tbody>
                          </nz-table>
                </div>
                </div>
            </div>
        </div>
    </div>
</form>
</div>
<ng-template #selections2>
    <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
</ng-template>
</nz-modal>