import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NewRFIsComponent } from 'src/app/messaging/Components/RFIs/new-rfis/new-rfis.component';
import { AddNewBillComponent } from '../add-new-bill/add-new-bill.component';

@Component({
  selector: 'app-purchase-order-bills',
  templateUrl: './purchase-order-bills.component.html',
  styleUrls: ['./purchase-order-bills.component.css']
})
export class PurchaseOrderBillsComponent {
  constructor(private modal: NzModalService) {}

  newBillsOpen(): void {
    const modalRef = this.modal.create({
      nzContent: AddNewBillComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });

  }
  
}
