 <nz-modal
  nzWidth="80%"
  [(nzVisible)]="Taghandle"
  [nzTitle]="sales1"
  [nzFooter]="sales2"
  (nzOnCancel)="closeSales()"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '76vh', 'overflow-y': 'auto'}"
  [nzStyle]="{ top: '18px' }">
  <ng-template #sales1>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0" nz-typography>Sales</h2>
    </div>
  </ng-template>
  <div class="cantent" *nzModalContent>
    <div class="row famly-inn gx-3">
      <div class="col-12">

        <nz-card nzTitle="Lead Activity Calendar Settings"
          class=" border-0">

          <div nz-row class="d-block" >
            <label class="sub-text ">Default Lead Activity Calendar
              View</label>
            <div nz-col nzSpan="6">
                    <nz-select nzShowSearch
                      [(ngModel)]="formfieldvalues['activityCalendarSettingId']"
                      name="activityCalendarSettingId">
                      <nz-option *ngFor="let option of defaultCalendarView"
                        [nzLabel]="option.name"
                        [nzValue]="option.id"></nz-option>
                    </nz-select>
            </div>
          </div>
        </nz-card>

        <div class="card border-0 mt-3">
          <div class="card-head border-bottom">
            <div class="row py-2">
              <h3 nz-typography><span class="ms-3">Proposal Worksheet
                  Settings</span></h3>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="row  border-bottom pb-5">
                  <div class="row">
                    <label class="sub-text me-2">Default Grouping</label>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <nz-select nzShowSearch
                        [(ngModel)]="formfieldvalues['proposalWorksheetSettingId']"
                        name="proposalWorksheetSettingId">
                        <nz-option *ngFor="let option of defaultGroup"
                          [nzLabel]="option.name"
                          [nzValue]="option.id"></nz-option>
                      </nz-select>
                    </div>
                  </div>
                </div>
                <div class="conatiner-fluid mt-3" cdkDropList
                  (cdkDropListDropped)="drop($event)">
                  <div class="row">
                    <div class="col">
                      <label class="sub-text me-2">Default Columns</label>
                    </div>
                  </div>
                  <div *ngFor="let data of listOfData">
                    <div class="row mb-2 p-2">
                      <div class="col-6 p-2 grage gap-2 d-flex" cdkDragHandle>
                        <div class="image me-1">
                          <img cdkDrag src="assets/drag.png">
                        </div>
                        <div class="CheckBox me-2">
                          <label for="check" nz-checkbox>{{data.name}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-0 mt-3">
          <div class="card-head border-bottom">
            <div class="row py-2">
              <h3 nz-typography><span class="ms-3">Proposal Format
                  Settings</span></h3>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="row border-bottom pb-4">
                  <div class="row">
                    <label class="sub-text me-2">Header</label>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <nz-select nzShowSearch
                        [(ngModel)]="formfieldvalues['headerSetupId']"
                        name="headerSetupId">
                        <nz-option *ngFor="let option of Header"
                          [nzLabel]="option.name"
                          [nzValue]="option.id"></nz-option>
                      </nz-select>
                    </div>
                  </div>
                  <div class="row">
                    <label class="sub-text me-2">Content</label>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <nz-select nzShowSearch
                        [(ngModel)]="formfieldvalues['contentSetupId']"
                        name="contentSetupId">
                        <nz-option *ngFor="let option of Content"
                          [nzLabel]="option.name"
                          [nzValue]="option.id"></nz-option>
                      </nz-select>
                    </div>
                  </div>
                  <div class="row">
                    <label class="sub-text me-2">Show Contact Name &
                      Phone</label>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <nz-select nzShowSearch
                        [(ngModel)]="formfieldvalues['showContactNamePhoneSetupId']"
                        name="showContactNamePhoneSetupId">
                        <nz-option *ngFor="let option of ShowContactName "
                          [nzLabel]="option.name"
                          [nzValue]="option.id"></nz-option>
                      </nz-select>
                    </div>
                  </div>
                  <div class="row">
                    <label class="sub-text me-2">
                      Show Address
                      <i
                        class="bi bi-info-circle-fill text-secondary"
                        nz-popover
                        [nzPopoverContent]="Budget"
                        nzPopoverPlacement="right"></i>
                      <ng-template #Budget>
                        <div class="rounded-2">
                          <p style="height:80px; width: 210px;font-size: 13px;">
                            Both the Customer Contact and Opportunity's address
                            will display if the addresses are different from one
                            another.
                          </p>
                        </div>
                      </ng-template>
                    </label>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <nz-select nzShowSearch
                        [(ngModel)]="formfieldvalues['showAddressSetupId']"
                        name="showAddressSetupId">
                        <nz-option *ngFor="let option of ShowContactName "
                          [nzLabel]="option.name"
                          [nzValue]="option.id"></nz-option>
                      </nz-select>
                    </div>
                  </div>
                  <div class="row">
                    <label class="sub-text me-2">Show Company
                      Information</label>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <nz-select nzShowSearch
                        [(ngModel)]="formfieldvalues['showCompanyInformationId']"
                        name="showCompanyInformationId">
                        <nz-option *ngFor="let option of ShowContactName "
                          [nzLabel]="option.name"
                          [nzValue]="option.id"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
                <div class="row border-bottom pb-5">
                  <div class="col-12">
                    <div class="row mt-3 ">
                      <label class="sub-text me-2">Default
                        "What to Display"</label>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <div class="tree-select-container">
                     

                          <nz-tree-select
                            [(ngModel)]="formfieldvalues['DefaultWhattoDisplayId']"
                            name="DefaultWhattoDisplayId"
                            style="width: 100%"
                            [nzNodes]="DefaultWhattoDisplaynodes"
                            nzShowSearch
                            nzAllowClear="false"
                            nzCheckable
                            (ngModelChange)="OnWhatDisplayChange($event)"
                            nzPlaceHolder="Select Tags"
                            nzDropdownClassName="dropdownStyle">
                          </nz-tree-select>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="row border-bottom pb-5">
                  <div class="col-12">
                    <div class="row mt-3 ">
                      <label class="sub-text me-2">Default Introductory
                        Text</label>
                    </div>
                    <div class="row mt-1">
                      <div class="col-md-12 col-lg-12 col-xl-12">
                        <div class="row">
                          <ckeditor
                            [(ngModel)]="formfieldvalues['defaultIntroductoryText']"
                            name="defaultIntroductoryText"
                            [editor]="Editor"
                            [config]="config"
                            class="size"
                            id="editor">
                          </ckeditor>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label class="sub-text">Default Title</label>
                    <input class="custom-select"
                      [(ngModel)]="formfieldvalues['defaultTitle']"
                      name="defaultTitle"
                      type="text"
                      class="form-control formField">
                  </div>
                </div>
                <div class="row border-bottom pb-5">
                  <div class="col-12">
                    <div class="row mt-3 ">
                      <label class="sub-text me-2">Default Closing Text</label>
                    </div>
                    <div class="row mt-1">
                      <div class="col-md-12 col-lg-12 col-xl-12">
                        <div class="row">
                          <ckeditor
                            [(ngModel)]="formfieldvalues['defaultClosingText']"
                            name="defaultClosingText"
                            [editor]="Editor"
                            [config]="config"
                            class="size"
                            id="editor">
                          </ckeditor>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row  border-bottom pb-5">
                  <div class="col-md-12">
                    <label class="sub-text me-2">Default Release Text</label>
                    <textarea class="custom-select"
                      [(ngModel)]="formfieldvalues['defaultReleaseText']"
                      name="defaultReleaseText"
                      nz-input
                      [nzAutosize]="{ minRows: 2, maxRows: 6 }"></textarea>
                  </div>
                </div>

                <div class="row mt-3 mt-2">
                  <span class="sub-text">Proposal Disclaimer</span>
                </div>

                <!-- Show text if available -->
                <div class="row" *ngIf="change">
                  <span class="small text-dark"
                    [innerHTML]="formfieldvalues?.proposalDisclaimer"></span>
                </div>

                <!-- Show CKEditor if ProposlDisclamar is true -->
                <div class="row mt-2" *ngIf="ProposlDisclamar">
                  <div class="editor-container">
                    <ckeditor
                      [(ngModel)]="formfieldvalues['proposalDisclaimer']"
                      name="proposalDisclaimer"
                      [editor]="Editor"
                      [config]="config"
                      class="size"
                      id="editor">
                    </ckeditor>
                  </div>
                </div>

                <!-- Show the Change button only if 'change' is true and content exists -->
                <div class="row mt-2" *ngIf="change">
                  <span class="ms-0">
                    <button
                      nz-button
                      (click)="Change()"
                      data-testid="saveDraft"
                      aria-disabled="false"
                      type="button"
                      class="me-0 ant-btn1 ant-btn-default">
                      <span>Change</span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-0 mt-3">
          <div class="card-head border-bottom">
            <div class="row py-2">
              <div class="col-6">
                <h3 nz-typography><span class="ms-3">Activity Types</span></h3>
              </div>
              <div class="col-6 text-end">
                <button (click)="addNewActivityType()" nz-button
                  nzType="primary" class="rounded-1 me-2">New Activity
                  Type</button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- <ul nz-list nzBordered nzSize="large">
                          <nz-list-header>Header</nz-list-header>
                            <li nz-list-item nzNoFlex>
                              <ul nz-list-item-actions>
                              </ul>
                                 hasdd
                            </li>
                        </ul> -->
            <nz-table
              #rowSelectionTable
              nzShowSizeChanger
              nzShowPagination="false"
              [nzData]="GridResponse"
              nzSize="middle">

              <thead>
                <tr>
                  <th nzWidth="8%">Icon <span  nz-popover [nzPopoverContent]="iconContent" nzPopoverPlacement="right">
                    <span nz-icon nzType="info-circle" nzTheme="fill"></span>
                  </span> </th>
                  <th>Type</th>
                  <ng-template #iconContent>
                    <p style="width: 200px; padding: 10px">
                      The default color and/or icon assigned to the activity type will appear in this column
                    </p>
                  </ng-template>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let activity of GridResponse">
                  <td>
                    <div class="row">
                      <div class="col-md-12">
                        <span class="color-circle"
                          [style.background-color]="activity?.color?.colorCollection"></span>
                      </div>
                    </div>

                    <div class="row iconset">
                      <div class="col-12">
                        <i style="font-size: 14px; left: 35px;"
                          [class]="activity?.icon?.sourceCollection"></i>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a
                      (click)="EditLeadActivityType(activity)">{{activity?.name}}</a>
                  </td>
                </tr>
              </tbody>
            </nz-table>

            <!-- <div class="ant-list ant-list-sm ant-list-split ant-list-bordered">
                          <div class="ant-list-header">
                            <div
                              class="ant-row BTRow-xs BTRow-sm BTRow-md BTRow-lg"
                              style="row-gap: 0px;"
                            >
                              <div class="ant-col-2">
                                Icon
                                <i
                                  class="bi bi-info-circle-fill text-secondary ms-1"
                                  nz-popover
                                  [nzPopoverContent]="Cost"
                                  nzPopoverPlacement="right"
                                ></i>
                                <ng-template #Cost>
                                  <div class="rounded-2">
                                    <p style="height: 70px; width: 210px; font-size: 13px;">
                                      The default color and/or icon assigned to the activity type will appear in this column
                                    </p>
                                  </div>
                                </ng-template>
                              </div>
                              <div class="ant-col ant-col-22">Type</div>
                            </div>
                          </div>
                          <div class="ant-spin-nested-loading">
                            <div class="ant-spin-container">
                              <ul class="ant-list-items">
                                <li class="ant-list-item" *ngFor="let item of leadActivityType">
                                  <div
                                    class="ant-row BTRow-xs BTRow-sm BTRow-md BTRow-lg"
                                    style="width: 100%; row-gap: 0px;"
                                  >
                                    <div class="ant-col ant-col-2">
                                      <span class="lead-activity-icon fa-stack fa-lg">
                                        <i>{{item.iconId}}</i>
                                      </span>
                                    </div>
                                    <div class="ant-col ant-col-22">
                                      <a class="BTLink" href="/">{{ item.name }}</a>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div> -->

          </div>
        </div>
        <div class="card border-0 mt-3">
          <div class="card-head border-bottom">
            <div class="row py-2">
              <div class="col-6">
                <h3 nz-typography><span class="ms-3">Lead Opportunity Custom
                    Fields</span></h3>
              </div>
              <div class="col-6 text-end">
                <button (click)="AddNewField()" nz-button nzType="primary"
                  class="rounded-1 me-2">Add New Field</button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <nz-table nzShowPagination="false" #nestedTable
                  [nzPageSize]="10">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Label</th>
                      <th>Data Type</th>
                      <th>Display Order</th>
                      <th>Required</th>
                      <th>Include In Filters</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </nz-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #sales2>
    <button nz-button nzType="primary" class="rounded-1 me-0"
      (click)="saveData()">Save</button>
  </ng-template>
</nz-modal>
