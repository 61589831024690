<nz-layout>
    <nz-sider
      class="border"
      nzCollapsible
      [nzCollapsedWidth]="10"
      [(nzCollapsed)]="isCollapsed"
      [nzWidth]="280"
      [nzTrigger]="null"
    >
      <div class="row">
        <app-side-menu-bar
        [isAllJobOption]="true"
        (getSelectedJobId)="getSelectedJobId($event)"
          (toggleCollapse)="toggleCollapse()"
        ></app-side-menu-bar>
      </div>
    </nz-sider>
      <nz-layout>
        <nz-page-header  >
            <nz-page-header-title>
                <h1>
                    Estimates
                </h1>
                <nz-breadcrumb nz-page-header-breadcrumb>
                    <nz-breadcrumb-item>Job: Yousuf Test F1</nz-breadcrumb-item>
                    <nz-breadcrumb-item>ESTIMATE</nz-breadcrumb-item>
                </nz-breadcrumb>
            </nz-page-header-title>
            <nz-page-header-extra>
                <nz-row nzType="flex" class="mb-3">
                    <nz-space>
                        <nz-statistic nzTitle="Status" nzValue="Pending"></nz-statistic>
                        <nz-statistic nzTitle="Price" [nzValue]="568.08" nzPrefix="$"
                            style="margin: 0 32px"></nz-statistic>
                        <nz-statistic nzTitle="Balance" [nzValue]="3345.08" nzPrefix="$"></nz-statistic>
                    </nz-space>
                </nz-row>

                <nz-row nzType="flex">
                    <nz-space>
                        <button *nzSpaceItem nz-button><i class="bi bi-box-arrow-up  me-2 mt-1"></i> Export </button>
                        <button *nzSpaceItem nz-button><i class="bi bi-lock  me-2 mt-1"></i> Lock Estimate</button>
                        <button *nzSpaceItem nz-button nzType="primary"><i class="bi bi-file-earmark text-dark me-2 mt-1"></i> Build Proposal</button>
                    </nz-space>
                </nz-row>


            </nz-page-header-extra>

        </nz-page-header>
        <nz-content class="custom-content mt-0">

        </nz-content>
    </nz-layout>
    </nz-layout>
