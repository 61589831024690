import { Component } from '@angular/core';

@Component({
  selector: 'app-schedule-list-empty',
  templateUrl: './schedule-list-empty.component.html',
  styleUrls: ['./schedule-list-empty.component.css']
})
export class ScheduleListEmptyComponent {

}
