<div class="row mb-3 mt-4">
    <div class="col-12  mb-3">
        <div class="card shadow">
            <div class="demo card-header py-3 ">Manage Cost Codes</div>
            <div class="card-body">
                <div
                    class="demo row py-4"
                    style="background-color: #f7f9fc; margin-left: 2%;  margin-right: 2%; border-radius: 2%;"
                >
                    <div
                        class="col-6"
                        style="border-right: 1px solid #ccc;"
                    >
                        <div class="flex flex-column align-items-center gap-3">
                            <span class="ms-4">Buildertrend Cost Codes</span>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="flex flex-column align-items-center gap-3">
                            <span class="ms-4">Xero Products/Services</span>
                        </div>
                    </div>
                </div>

                <div
                    class="row py-2"
                    style="border-bottom: 1px solid #ccc; margin-left: 2%;  margin-right: 2%; border-radius: 2%;"
                >
                    <div class="col-6 mt-3">
                        <div class="flex flex-column align-items-center gap-3">
                            <span class="ms-4">01.00 - Architectural Plans</span>
                        </div>
                    </div>
                    <div class="col-6 mt-3">
                        <div class="flex flex-column align-items-center gap-3">
                            <span class="stylinh">-- Not Linked in Xero --</span>
                            <button
                            (click)="Manage()"
                                class="ms-5 btn btn-button custom-button"
                                type="button"
                                aria-expanded="false"
                            >
                                Edit Link
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    class="row py-2"
                    style="border-bottom: 1px solid #ccc; margin-left: 2%;  margin-right: 2%; border-radius: 2%;"
                >
                    <div class="col-6 mt-3">
                        <div class="flex flex-column align-items-center gap-3">
                            <span class="ms-4">01.05 - Building Permit</span>
                        </div>
                    </div>
                    <div class="col-6 mt-3">
                        <div class="flex flex-column align-items-center gap-3">
                            <span class="stylinh">-- Not Linked in Xero --</span>
                            <button
                            type="button"
                            class="ms-5 btn btn-button custom-button"
                        >
                                Edit Link
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    class="row py-2"
                    style="border-bottom: 1px solid #ccc; margin-left: 2%;  margin-right: 2%; border-radius: 2%;"
                >
                    <div class="col-6 mt-3">
                        <div class="flex flex-column align-items-center gap-3">
                            <span class="ms-4">01.10 - Portable Toilet</span>
                        </div>
                    </div>
                    <div class="col-6 mt-3">
                        <div class="flex flex-column align-items-center gap-3">
                            <span class="stylinh">-- Not Linked in Xero --</span>
                            <button
                            type="button"
                            class="ms-5 btn btn-button custom-button"
                        >
                                Edit Link
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    class="row py-2"
                    style="border-bottom: 1px solid #ccc; margin-left: 2%;  margin-right: 2%; border-radius: 2%;"
                >
                    <div class="col-6 mt-3">
                        <div class="flex flex-column align-items-center gap-3">
                            <span class="ms-4">01.15 - Insurance</span>
                        </div>
                    </div>
                    <div class="col-6 mt-3">
                        <div class="flex flex-column align-items-center gap-3">
                            <span class="stylinh">-- Not Linked in Xero --</span>
                            <button
                            type="button"
                            class="ms-5 btn btn-button custom-button"
                        >
                                Edit Link
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    class="row py-2"
                    style="border-bottom: 1px solid #ccc; margin-left: 2%;  margin-right: 2%; border-radius: 2%;"
                >
                    <div class="col-6 mt-3">
                        <div class="flex flex-column align-items-center gap-3">
                            <span class="ms-4">01.20 - Temporary Utilities</span>
                        </div>
                    </div>
                    <div class="col-6 mt-3">
                        <div class="flex flex-column align-items-center gap-3">
                            <span class="stylinh">-- Not Linked in Xero --</span>
                            <button
                            type="button"
                            class="ms-5 btn btn-button custom-button"
                        >
                                Edit Link
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


    <!-- Edit -->
    <div class=" flex justify-content-center ">
        <nz-modal
        nzWidth="90%"
        [(nzVisible)]="manage"
        [nzTitle]="manage1"
        [nzFooter]="manage2"
        [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '400px', 'overflow-y': 'auto'}"
        [nzStyle]="{ top: '18px' }">
      <ng-template #manage1>
        <div class="row p-0 m-0">
          <h1 class="p-0 m-0 fw-medium">Edit Cost Code Link</h1>
        </div>
      </ng-template>
      <div class="content" *nzModalContent>
        <app-edit-cost-code-link></app-edit-cost-code-link>
      </div>
      <ng-template #manage2>
        <button nz-button nzType="default" class="rounded-1 me-0">Cancel</button>
        <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
      </ng-template>
    </nz-modal>

    </div>
