import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AddSubVendorComponent } from '../add-sub-vendor/add-sub-vendor.component';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import {
  FilterSearchParameter,
  LookupFilterResponse,
} from 'src/app/shared/component/Models/LookupModels';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
import {
  StandardColumnSetupResponse,
  StandardViewResponse,
} from 'src/Models/LookupStandardview/Standardview';
import { ColumnsResizePinProperties } from 'src/Models/InternalUser/internalUserColumnsPinResize';
import { Subscription } from 'rxjs';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { AddNewGridStandardViewComponent } from 'src/app/shared/component/Modal/add-new-grid-standard-view/add-new-grid-standard-view.component';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import * as XLSX from 'xlsx';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';

@Component({
  selector: 'app-subs-vendors-dashboard',
  templateUrl: './subs-vendors-dashboard.component.html',
  styleUrls: ['./subs-vendors-dashboard.component.css'],
})
export class SubsVendorsDashboardComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private subVendorFilterSubscription: Subscription;
  private subVendorSubscription: Subscription;
  private subVendorStandardViewFilterSubscription: Subscription;
  PAGE_ID: number = -1;
  SUB_VENDOR: string = FiltersName.SUB_VENDOR;
  filterSearchParameter: FilterSearchParameter;
  displayedData: SubVendorResponse[] = [];
  getsubvendordata: SubVendorResponse[] = [];
  displayedColumns: string[] = [];
  lookupFilterResponse: LookupFilterResponse[] = [];
  isLoading: boolean = false;
  currentPage = 1;
  pageSize = 10;
  totalRecords = 100;
  pageSizeOptions = [10,20, 50, 75, 100, 250];
  INTERNAL_USER: string = FiltersName.INTERNAL_USER;
  getStandardViewFilterData: StandardViewResponse[] = [];
  getStandardViewColumnFilterData: StandardColumnSetupResponse[] = [];
  fixedSelectedColumnNames: string[] = [];
  selected_View_Columns: string[] = [];
  selectedColNameSizePin: ColumnsResizePinProperties[] = [];
  isFilterApplied: boolean = false;
  selectedFilter: number = -1;
  defaultFilterIdSelected: number = 0;
  displayColumnSubscription: Subscription;
  expandedRows: number[] = [];
  isColumnSticky: boolean[] = new Array(this.displayedColumns.length).fill(
    false
  );
  columnSizes: { columnName: string; widthSize: number }[] = [];
  getColumnWidthSize: number[] = [];
  companyNameInvalid: boolean = false;
  showErrorCards: boolean = false;
  selectedValueControl: any;
  selectionFrom: string = 'DB' ;

  constructor(
    public toastService: NzMessageService,
    private modal: NzModalService,
    private filterService: FilterSideMenuBarService,
    private SubVendorService: SubVendorService,
    private standardViewService: StandardViewForAllService,
    private changeDetectorRef: ChangeDetectorRef,
    private LookupFilterService: LookupFilterService,
    private accessLocalStorageService: AccessLocalStorageService,
    private internalUserService: InternalUserService,


  ) {
    if (this.subVendorFilterSubscription) {
      this.subVendorFilterSubscription.unsubscribe();
    }
    if (this.subVendorSubscription) {
        this.subVendorSubscription.unsubscribe();
    }
    if (this.subVendorStandardViewFilterSubscription) {
      this.subVendorStandardViewFilterSubscription.unsubscribe();
    }
    // console.clear();
    // this.displayedColumns = [];

  }

  ngOnInit(): void {
    this.selectedFilter = -1;
    this.internalUserService.setSelectedFilterId(0);
    if (this.subVendorFilterSubscription) {
      this.subVendorFilterSubscription.unsubscribe();
    }
    if (this.subVendorSubscription) {
        this.subVendorSubscription.unsubscribe();
    }
    if (this.subVendorStandardViewFilterSubscription) {
      this.subVendorStandardViewFilterSubscription.unsubscribe();
    }
    this.PAGE_ID = 12

    this.displayedColumns = [];
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    // Add subscriptions to the array
    this.subscriptions.push(
      this.standardViewService.$displaySelectedColApply.subscribe(colName => {
        if (colName.length > 0) {
          this.displayedColumns = colName;
          this.changeDetectorRef.detectChanges();
        }
      })
    );

    this.subscriptions.push(
      this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
        this.selectedFilter = standardType != -1 ? standardType : standardType;
      })
    );
    // this.displayedColumns = [];
    // this.displayColumnSubscription =
    //   this.standardViewService.$displaySelectedColApply.subscribe((colName) => {
    //     if (colName.length > 0) {
    //       this.displayedColumns = colName;
    //       this.changeDetectorRef.detectChanges();
    //     }
    //   });
    // this.displayColumnSubscription =
    //   this.standardViewService.$selectedStandardFilterType.subscribe(
    //     (standardType) => {
    //       if (standardType != -1) {
    //         this.selectedFilter = standardType;
    //       } else {
    //         this.selectedFilter = standardType;
    //       }
    //     }
    //   );

      this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom); 
    this.getStandardViewFilterByFormPageId(this.PAGE_ID);

    this.showErrorCards = false;
    this.companyNameInvalid = false;

    this.SubVendorService.$isUserAppliedFilter.subscribe((filter) => {
      if (filter) {
        this.isFilterApplied = filter;
      } else {
        this.isFilterApplied = filter;
      }
    });
  }

  // getStandardFilterByFormPageId(pageId: number) {
  //   this.LookupFilterService.getStandardFilterByFormPageId(pageId);
  //   this.LookupFilterService.$getStandardFilter.subscribe((result) => {
  //     if (result) {
  //       this.lookupFilterResponse = result.result;
  //       let defaultFilterId = result.result.find(
  //         (filter) => filter.isSetAsDefault === true
  //       ).id;
  //       this.SubVendorService.setSelectedFilterId(defaultFilterId);
  //       this.defaultFilterIdSelected = defaultFilterId;
  //       this.SubVendorsGridTableData(
  //         1,
  //         this.PAGE_ID,
  //         defaultFilterId,
  //         this.currentPage,
  //         this.pageSize
  //       );
  //     }
  //   });
  // }

  getStandardFilterByFormPageId(pageId:number, type: string){// New Changes By Aamir Ali - 22-Apr2024
    // Unsubscribe previous subscriptions if they exist
    if (this.subVendorFilterSubscription) {
      this.subVendorFilterSubscription.unsubscribe();
  }
  if (this.subVendorSubscription) {
      this.subVendorSubscription.unsubscribe();
  }
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    // this.SubVendorFilterSubscription =  this.LookupFilterService.$getStandardFilter.subscribe(result =>{
    this.subVendorFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(result => {
    if(result){
      this.lookupFilterResponse = result.result;
     let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
     this.SubVendorService.setSelectedFilterId(defaultFilterId);
     let id = -1;
     if(type === this.selectionFrom){
      this.defaultFilterIdSelected = defaultFilterId;
      this.accessLocalStorageService.setSelectedFilterId(defaultFilterId); 

      // New Changes By Aamir Ali - 22-Apr2024
      id = this.accessLocalStorageService.getSelectedFilterId();
     }else{
      id = this.accessLocalStorageService.getSelectedFilterId();
     }

     if(id !== -1){
      this.SubVendorsGridTableData(1, this.PAGE_ID , id ,this.currentPage,this.pageSize);
     }

    }
    });
  }



  getStandardViewFilterByFormPageId(pageId: number) {
    if (this.subVendorStandardViewFilterSubscription) {
      this.subVendorStandardViewFilterSubscription.unsubscribe();
    }
    this.displayedColumns = [];
    this.fixedSelectedColumnNames = [];
    this.standardViewService.getStandardViewFilterByFormPageId(pageId);
    this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
    // this.standardViewService.$getStandardViewFilter.subscribe((result) => {
      this.subVendorStandardViewFilterSubscription = this.standardViewService.$getStandardViewFilter.subscribe(result => {
      if (result) {
        this.displayedColumns = [];
        this.selected_View_Columns = [];
        this.getStandardViewFilterData = result.result;
        let defaultView = this.getStandardViewFilterData.find(
          (view) => view.isSetAsDefault === true
        ).standardViewParameters;
        this.selectedValueControl = this.getStandardViewFilterData.find(
          (view) => view.isSetAsDefault === true
        ).id;
        defaultView.forEach((viewItem) => {
          if (
            viewItem.standardColumnSetup &&
            viewItem.standardColumnSetup.displayColumnName
          ) {
            this.selected_View_Columns.push(
              viewItem.standardColumnSetup.displayColumnName
            );
          }
        });
        this.displayedColumns = this.selected_View_Columns;
      }
    });

    this.standardViewService.$getStandardViewColumn.subscribe((result) => {
      if (result) {
        this.getStandardViewColumnFilterData = result.result;
        let fixedCol = this.getStandardViewColumnFilterData.filter(
          (x) => x.isSelected === true
        );
        fixedCol.forEach((x) => {
          this.fixedSelectedColumnNames.push(x.displayColumnName);
        });
      }
    });
  }



  SubVendorsGridTableData(companyId: number, formNameId: number , lookUpFilterId: number, page: number, pageSize: number ){
    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId:lookUpFilterId,
      page: page,
      pageSize: pageSize,
      jobInformationId: null
    };

    this.SubVendorService.getSubVendorByFilter(this.filterSearchParameter);
    this.subVendorSubscription = this.SubVendorService.$getSubVendors.subscribe((result) => {
      if (result) {
        this.totalRecords = result?.result?.rowCount;
        this.displayedData = result?.result?.rows;
      }
    });

  }
  isExpanded(rowIndex: number): boolean {
    return this.expandedRows.includes(rowIndex);
  }
  
  toggleAdditionalEmails(rowIndex: number): void {
    const index = this.expandedRows.indexOf(rowIndex);
    if (index > -1) {
      this.expandedRows.splice(index, 1);
    } else {
      this.expandedRows.push(rowIndex);
    }
  }
  
  

  toggleColumnSticky(index: number) {
    this.isColumnSticky[index] = !this.isColumnSticky[index];
    if (this.isColumnSticky[index]) {
      for (let i = 0; i < index; i++) {
        this.isColumnSticky[i] = true;
      }
    }
  }

  onFilterChange(id: number) {
    this.isColumnSticky= new Array(this.displayedColumns.length).fill(false);

    this.selectedFilter = -1;
    this.displayedColumns = [];
    this.selected_View_Columns = [];
     let selectedView = this.getStandardViewFilterData.find(view => view.id === id).standardViewParameters; 
     selectedView.forEach(viewItem => {
       if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
         this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
         this.displayedColumns = this.selected_View_Columns;

          const columnIndex = this.displayedColumns.indexOf(viewItem.standardColumnSetup.displayColumnName);
          if (columnIndex !== -1) {
            this.isColumnSticky[columnIndex] = viewItem.isPin;
            this.getColumnWidthSize[columnIndex] = viewItem.resizeColumnWidth;
          }
       }
     });
   }


  showAllEmailsFlag: boolean = false;

  showAllEmails(dataItem: any): void {
    dataItem.showAllEmailsFlag = true;
  }

  getStatusText(statusSetupId: number): string {
    switch (statusSetupId) {
      case 10002:
        return 'Activated Only';
      case 10003:
        return 'Pending Only';
      case 10004:
        return 'Not Activated Only';
      case 10005:
        return 'Insurance Expired';
      case 10006:
        return 'Disabled';
      default:
        return '';
    }
  }

  ngOnDestroy(): void {
    this.LookupFilterService.getStandardFilter.next(null); // New Changes By Aamir Ali - 31-May-2024
    localStorage.removeItem('Selected_Filter_Id'); // New Changes By Aamir Ali - 31-May-2024
    if (this.subVendorFilterSubscription) {
      this.subVendorFilterSubscription.unsubscribe();
    }
    if (this.subVendorSubscription) {
        this.subVendorSubscription.unsubscribe();
    }
    if (this.subVendorStandardViewFilterSubscription) {
      this.subVendorStandardViewFilterSubscription.unsubscribe();
    }
    console.clear();
    this.selectedFilter = -1;

  }
      // this.displayedColumns = [];
    // if (this.displayColumnSubscription) {
    //   this.displayColumnSubscription.unsubscribe();
    // }
    // // Unsubscribe from the observable to avoid memory leaks
    // this.SubVendorsubscription.unsubscribe();
    // this.SubVendorFilterSubscription.unsubscribe();

  //////////   Filter Open  ///////////////



  openFilter(component: string, pageId: number) {
    this.selectionFrom = ''; // New Changes By Aamir Ali - 22-Apr2024
    this.isColumnSticky= new Array(this.displayedColumns.length).fill(false);
    this.filterService.toggleFilter(component, pageId);
    this.SubVendorService.userAppliedFilter(false);
  }
  //////////// Filter Closed   ///////////////

  //////////   Sub vendor Form Open  ///////////////

  showSubVendor() {
    const modalRef = this.modal.create({
      // nzWidth: '50%',
      // nzBodyStyle: {
      //   'background': '#f1f4fa',
      //   'padding': '0',
      //   'margin': '0',
      //   'overflow-x': 'hidden'
      // },
      nzStyle: {
        top: '22px',
        'border-radius': '50px'
      },
      nzContent: AddSubVendorComponent,
      nzFooter: null
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.onSaveComplete.subscribe(() =>{
      this.SubVendorsGridTableData(1, this.PAGE_ID , this.defaultFilterIdSelected,this.currentPage,this.pageSize);
    });
  }

  //////////// Sub vendor Form Closed   ///////////////

  saveColumnSizes(event: any) {
    event.forEach((col) => {
      const columnName = col.column.field;
      const widthSize = col.newWidth;
      const existingIndex = this.columnSizes.findIndex(
        (item) => item.columnName === columnName
      );

      if (existingIndex !== -1) {
        this.columnSizes[existingIndex].widthSize = widthSize;
      } else {
        this.columnSizes.push({ columnName, widthSize });
      }
    });
  }



  ///  footer Methods  /////

  // this.internalUserGridTableData(1, this.PAGE_ID , this.defaultFilterIdSelected,this.currentPage,this.pageSize);

  onPageChange(page: number): void {


    this.currentPage = page;
    let filterId: number = 0;
    this.SubVendorService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.SubVendorsGridTableData(1, this.PAGE_ID, filterId, page, this.pageSize);
      this.isLoading = false;

    }, 100);
  }

  onPageSizeChange(size: number): void {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
    this.pageSize = size;
    let filterId: number = 0;
    this.SubVendorService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.SubVendorsGridTableData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize);
      this.isLoading = false;

    }, 100);
  }

  isAllDataShownSubvendor(): boolean {
    return this.totalRecords <= this.pageSize;
  }


  openStandardViewFilterComponent(isSaveAs?: string) {
    let isInputVisible: boolean = false;
    if (isSaveAs === 'saveAs') {
      isInputVisible = true;
    } else {
      isInputVisible = false;
    }
    this.selectedColNameSizePin = [];
    for (let i = 0; i < this.displayedColumns.length; i++) {
      const item = this.displayedColumns[i];
      const isPinValue = this.isColumnSticky[i];
      let widthSize = 0;
      if (this.columnSizes.length > 0) {
        for (let i = 0; i < this.columnSizes.length; i++) {
          const columnName = this.columnSizes[i].columnName;
          const columnNameSize = this.columnSizes[i].widthSize;
          if (columnName === item) {
            widthSize = columnNameSize;
            break;
          }
        }
      }

      if (widthSize === 0) {
        widthSize = 180;
      }

      this.selectedColNameSizePin.push({
        displayColumnName: item,
        resizeColumnWidth: widthSize, //  "200px", // Set default width here
        isPin: isPinValue == true ? isPinValue : false
      });
    }
    const modalRef = this.modal.create({
      nzContent: AddNewGridStandardViewComponent,
      nzData:
      {
        pageId: this.PAGE_ID,
        column: this.getStandardViewColumnFilterData,
        displayCol: this.displayedColumns,
        viewList: this.getStandardViewFilterData,
        selectedColNameSizePin: this.selectedColNameSizePin,
        selectedStandardView: this.selectedValueControl,
        fixedCol: this.fixedSelectedColumnNames,
        isSaveAs: isInputVisible
      },

    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
  }

  saveEditStandardView() {
    this.standardViewService.$createStandardViewRequest.subscribe(formData => {
      if (formData && formData !== null) {
        this.standardViewService.postData(formData)
          .subscribe(
            (res: ResponseModelArray<StandardViewResponse>) => {
              setTimeout(() => {
                this.isLoading = true;
                this.toastService.success('Standard View Updated Successfully');
                this.standardViewService.getStandardViewFilterByFormPageId(this.PAGE_ID);
                this.selectedFilter = -1;
              }, 10);
            },
            (error) => {
              console.error('Error:', error);
              this.isLoading = false;
            }
          );
      }
    });
  }
@Input() subVendorResponse: SubVendorResponse;

OnClickVendors(subVendorData: SubVendorResponse) {
  const modalRef = this.modal.create({
      nzContent: AddSubVendorComponent,
      nzWidth: '50%',
      nzBodyStyle: {'background': '#f1f4fa', 'padding': '0', 'margin': '0', 'overflow-x': 'hidden', },
      nzStyle: {
          top: '22px',
      },
      nzData: {
          activityData: subVendorData,
      },
      nzFooter: null,
  });
  modalRef.componentInstance.subVendorResponseId = subVendorData.id;
  modalRef.componentInstance.GridDataResponse = subVendorData;
  modalRef.componentInstance.subVendorResponse = subVendorData;
  
  modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
  });
  modalRef.componentInstance.onSaveComplete.subscribe(() => {
    this.SubVendorsGridTableData(1, this.PAGE_ID , this.defaultFilterIdSelected,this.currentPage,this.pageSize);
  });
  console.log("Lead data for lead:", this.subVendorResponse);
}
  // ___________________Abdul Rehman Code End________________





  isLoadingOne = false;


  exportToExcel(): void {
    const currentDate = new Date().toLocaleDateString();
    const headings = ['Company Name', 'Sub/VendorDivisions', 'Activation', 'Primary Contact', 'Trade Agreement Status', 'Liability Exp', 
    'Worker`s Comp Exp','Cell', 'Phone', 'Auto Access', 'Discuss With Owner', 'Email', ' Fax', 'Hold Payment','Postal Code','State','Street Address','Suburb'];
    const dataToExport = [];
    dataToExport.push(['Exported on ' + currentDate]);
    dataToExport.push([]);
    dataToExport.push(headings); 


    
  
    this.displayedData.forEach(row => {
      const rowData = [];
      headings.forEach(column => {
        switch (column) {
          case 'Company Name':
            rowData.push(row.companyName || '');
            break;
          case 'Phone':
            rowData.push(row.cellPhone || '');
            break;
       
          case 'Sub/VendorDivisions':
            rowData.push(row.divisionTradeParameters || '');
            break;
          case 'Primary Contact':
            rowData.push(row.primaryContactFirstLastName || '');
            break;
          case 'Sub/Vendor Divisions':
            rowData.push(row.divisionTradeParameters || '');
            break;
          case 'Email':
            rowData.push(row.subVenderManageEmails || ''); 
            break;
            case 'Activation':
              rowData.push(row.activation ? 'TRUE' : 'FALSE');
              break;
          case 'Auto Access':
            rowData.push(row.automaticallyPermitAccessToNewJobs || ''); 
            break;
          case 'Cell':
            rowData.push(row.cellPhone || ''); 
            break;
          case 'Phone':
            rowData.push(row.cellPhone || ''); 
            break;
          case 'Street Address':
            rowData.push(row.streetAddress || ''); 
            break;
          case 'Fax':
            rowData.push(row.fax || ''); 
            break;
          case 'Suburb':
            rowData.push(row.suburb || ''); 
            break;
          case 'State':
            rowData.push(row.state || ''); 
            break;
          case 'Postal Code':
            rowData.push(row.postalCode || ''); 
            break;
          case 'Hold Payment':
            rowData.push(row.holdPaymentsNotes || ''); 
            break;
          default:
            rowData.push(''); 
            break;
        }
      });
      dataToExport.push(rowData);
    });
  
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataToExport);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'Users.xlsx');
  }
}