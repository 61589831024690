import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LeadActivityResponse } from 'src/Models/LeadActivity/leadActivityClass';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.css']
})
export class GoogleMapComponent {
  leadActivtyVisibility = true;
  activityResponse: LeadActivityResponse;
  mapUrl: SafeResourceUrl;
  
  @Input() searchQuery: string = '';
  @Output() cancel = new EventEmitter<void>();

  isFindingLocation: boolean = false; // Flag to manage blinking state

  constructor(
    private leadOpportunitiesService: LeadOpportunitiesService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.updateMapUrl();
  }

  updateMapUrl(): void {
    if (this.searchQuery && this.searchQuery.trim()) {
      this.mapUrl = this.generateMapUrl(this.searchQuery);
    } else {
      this.isFindingLocation = true; // Start blinking
      this.getCurrentLocation().then(coords => {
        this.isFindingLocation = false; // Stop blinking
        this.mapUrl = this.generateMapUrl(`${coords.latitude},${coords.longitude}`);
      }).catch(() => {
        this.isFindingLocation = false; // Stop blinking
        // Handle the error of getting current location here if needed
        this.mapUrl = this.generateMapUrl('');
      });
    }
  }

  generateMapUrl(query: string): SafeResourceUrl {
    const url = `https://maps.google.com/maps?q=${encodeURIComponent(query)}&t=&z=13&ie=UTF8&iwloc=&output=embed`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getCurrentLocation(): Promise<GeolocationCoordinates> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => resolve(position.coords),
          error => reject(error)
        );
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  }

  GoogleMapFromCancel(): void {
    this.cancel.emit();
    this.leadActivtyVisibility = false;
    this.leadOpportunitiesService.clearSelectedFiles();
  }
}
