import { AuthService } from 'src/app/auth/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, Subject, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isModalOpen = false;
  constructor(private authService: AuthService, private modal: NzModalService,
    private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // return next.handle(request);

    let authRequest = request;
    // Get the authentication token from your service
    const authToken = this.authService.getAuthToken();
    if (authToken) {
      // Clone the request and add the token to the headers
      authRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
        },
      });

    }
    // Pass the modified request to the next handler
    return next.handle(authRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !this.isModalOpen) {
          this.isModalOpen = true; 
          this.warning().subscribe(() => {
            this.authService.logout();
            this.isModalOpen = false; 
          });
        }
        return throwError(error);
      })
    );

  }

  warning(): Observable<void> {
    const loginClicked = new Subject<void>();
    this.modal.error({
      nzTitle: 'Session Expired',
      nzContent: 'Your session has expired. Please log in again to continue.',
      nzOkDanger: true,
      nzOkText: 'Login',
      nzCloseIcon: '',
      nzOnOk: () => {
        loginClicked.next();
        loginClicked.complete();
      },
      nzOnCancel: () => {
        this.isModalOpen = false;
      }
    });
    return loginClicked.asObservable();
  }
}
