import { Component } from '@angular/core';

@Component({
  selector: 'app-gusto',
  templateUrl: './gusto.component.html',
  styleUrls: ['./gusto.component.css']
})
export class GustoComponent {

}
