import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-commment-conversation',
  templateUrl: './commment-conversation.component.html',
  styleUrls: ['./commment-conversation.component.css']
})
export class CommmentConversationComponent {
  @Output() cancel = new EventEmitter<void>();
  commentConversation:FormGroup

  constructor(private fb: FormBuilder,
    ){}
  commentConversationvisible=true;

  commentConversationModelCancel(): void {
    this.cancel.emit();
  }
  initializeForm(): void {
    this.commentConversation = this.fb.group({
      comment: [null],
      subsVendors: [null],
      notify: [null],
    
  // Grid Baqi Ha 
    });
  }

  
  inputValue?: string;
  selectedValue = null;
 
 
  
}


