import { Component } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NewAllowanceComponent } from '../new-allowance/new-allowance.component';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';

@Component({
  selector: 'app-assingedselection',
  templateUrl: './assingedselection.component.html',
  styleUrls: ['./assingedselection.component.css']
})
export class AssingedselectionComponent {
  confirmModal?: NzModalRef ; // For testing by now

  constructor(private modal: NzModalService,private filterService: FilterSideMenuBarService) {}
  openNewAllowance(){
    //console.log("NEW USER");
    const modalRef = this.modal.create({
      // nzTitle: 'Modal Title',
       nzContent: NewAllowanceComponent,
       nzFooter: null, // You can customize the footer if needed
     });
     modalRef.componentInstance.cancel.subscribe(() => {
       // Handle cancel event
       modalRef.destroy(); // Close the modal
     });
 
     // You can subscribe to modalRef.afterOpen, modalRef.afterClose, or other events if needed
   }
   PROJECT_MANG_SELEC_ALLOWA_ASSIGN: string = FiltersName.PROJECT_MANG_SELEC_ALLOWA_ASSIGN;
   PAGE_ID: number =  32;
   openFilter(component: string, pageId: number) {
     this.filterService.toggleFilter(component, pageId);
   }   
 
  }