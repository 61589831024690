import { Component } from '@angular/core';

@Component({
  selector: 'app-dropdowncostcodes',
  templateUrl: './dropdowncostcodes.component.html',
  styleUrls: ['./dropdowncostcodes.component.css']
})
export class DropdowncostcodesComponent {
  checked: boolean = false;

  
  newsurveys: boolean = false;
  selectedOption: string = 'None';
  selectedOption3: string = '-- Create New Cost Code in Buildertrend --';
  selectedOption5: string = '1';
  isOpen: boolean = false;
  isOpen3: boolean = false;
  isOpen5: boolean = false;
  options5: string[] = ['1', '2', '3', '4', '5', '6' , '7'];
  options: string[] = ['None', '1 Day', '2 Day', '3 Day', '7 Day', '14 Day', '21 Day' , '30 Day'];
  options3: string[] = ['Line items', 'Flat Fee'];




  selectOption5(option: string) {
    this.selectedOption5 = option;
    this.isOpen5 = false;
  }
  toggleDropdown5() {
    this.isOpen5 = !this.isOpen5;
  }
  selectOption3(option: string) {
    this.selectedOption3 = option;
    this.isOpen3 = false;
  }
  toggleDropdown3() {
    this.isOpen3 = !this.isOpen3;
  }
  checkboxes = [
    { label: 'Xero Products/Services', isSelected: false },
    { label: '5160 - Re-establishment Survey', isSelected: false },
    { label: '6701 - Heating Roughin', isSelected: false },
    // Add more checkboxes as needed
  ];

  // Function to handle the change of the "Xero Products/Services" checkbox
  xeroCheckboxChanged() {
    const xeroCheckbox = this.checkboxes[0];
    for (const checkbox of this.checkboxes) {
      checkbox.isSelected = xeroCheckbox.isSelected;
    }
  }

  // Function to handle individual checkbox changes
  individualCheckboxChanged() {
    const allSelected = this.checkboxes.slice(1).every((checkbox) => checkbox.isSelected);
    this.checkboxes[0].isSelected = allSelected;
  }
}