<nz-tabset nzType="card" class="mt-2">
    <nz-tab *ngFor="let tab of tabs" [nzTitle]="innerCardTabs(tab)">
    <ng-container *ngIf="tab === 1">
       <app-assingedselection></app-assingedselection>
  </ng-container>

  <ng-container *ngIf="tab === 2">
       <app-unassinged-selection></app-unassinged-selection>
  </ng-container>
  
    </nz-tab>
  </nz-tabset>