import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FileInformationResponse } from 'src/Models/FilesModules/FilesDocument';
import { DocumentCreateFolderComponent } from 'src/app/shared/component/document-create-folder/document-create-folder.component';
import { DocumentEmptyComponent } from '../document-empty/document-empty.component';
import { FileDecumentService } from 'src/Service/FileDocumnet/file-decument.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { AttachmentfileUploadComponent } from '../../attachmentfile-upload/attachmentfile-upload.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-documents-dashboard',
  templateUrl: './documents-dashboard.component.html',
  styleUrls: ['./documents-dashboard.component.css']
})
export class DocumentsDashboardComponent implements OnInit {
  responseData: FileInformationResponse;
  previewUrl: string | null = null;
  isCollapsed = false;
  selectedJobId: number;
  showH1: boolean = false;
  showH5: boolean = false;
  checked = false;
  loading = false;
  indeterminate = false;
  listOfData: readonly Data[] = [];
  listOfCurrentPageData: readonly Data[] = [];
  setOfCheckedId = new Set<number>();
  response: FileInformationResponse[];
  showActivity: boolean = true;
  cards = [];
  item: any; // Replace with your actual data type
  previewExtension: string = '';
  id: number;


  constructor(private modal: NzModalService,
    private cdr: ChangeDetectorRef,
    public localStorage: AccessLocalStorageService,
    private sanitizer: DomSanitizer,
    private FileDecumentService: FileDecumentService) { }

 

  ngOnInit(): void {
    console.log("show file data  on behave of jobid",this.selectedJobId);
    
    this.selectedJobId = this.localStorage.getJobId();
    if (this.selectedJobId) {
      this.getAllFilesByJob(this.selectedJobId);
      console.log("Show file data on behalf of job id", this.selectedJobId);
    } else {
      console.error('No Job ID found');
    }
    this.localStorage.getJobName();

    this.FileDecumentService.currentSavedId.subscribe(savedId => {
      console.log('Saved ID in DocumentsDashboardComponent:', savedId);
    });

   
   
    this.listOfData = new Array(100).fill(0).map((_, index) => ({
      id: index,
      name: `Edward King ${index}`,
      age: 32,
      address: `London, Park Lane no. ${index}`,
      disabled: index % 2 === 0
    }));
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  getSelectedJobId(jobId: number): void {
    this.selectedJobId = jobId;
    if (this.selectedJobId) {
      this.getAllFilesByJob(this.selectedJobId);
      console.log("Updated file data on behalf of new job id", this.selectedJobId);
    } else {
      console.error('No Job ID provided');
    }
  }

  getAllFilesByJob(JobInformationId: number): void {
    this.FileDecumentService.getDatabyJob(JobInformationId).subscribe(
      (response) => {
        this.response = response.result;
      },
      (error) => {
        console.error('Error fetching job data', error);
      }
    );
  }


  showH1OnClick(): void {
    this.showH1 = true;
    this.showH5 = false;
  }

  showH5OnClick(): void {
    this.showH5 = true;
    this.showH1 = false;
  }

  CreateNewfolder(data: FileInformationResponse): void {
    const modalRef = this.modal.create({
      nzContent: DocumentCreateFolderComponent,
      nzFooter: null,
      nzData: {
        showFields: true,
        isDocument: true,
        isPhoto: false,
        isVedio: false
      }
    });

    modalRef.componentInstance.savedId = data;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  CreateDocumentEmptyComponent(): void {
    const modalRef = this.modal.create({
      nzContent: DocumentEmptyComponent,
      nzFooter: null,
      nzData: {
      }
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }


  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onCurrentPageDataChange(listOfCurrentPageData: readonly Data[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData.filter(({ disabled }) => !disabled);
    this.checked = listOfEnabledData.every(({ id }) => this.setOfCheckedId.has(id));
    this.indeterminate = listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.listOfCurrentPageData
      .filter(({ disabled }) => !disabled)
      .forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  sendRequest(): void {
    this.loading = true;
    const requestData = this.listOfData.filter(data => this.setOfCheckedId.has(data.id));
    console.log(requestData);
    setTimeout(() => {
      this.setOfCheckedId.clear();
      this.refreshCheckedStatus();
      this.loading = false;
    }, 1000);
  }


  addCard() {
    this.cards.push({});
  }

  isPdfFile(extension: string): boolean {
    return extension.toLowerCase() === '.pdf';
  }

  isExcelFile(extension: string): boolean {
    return extension.toLowerCase() === '.xls' || extension.toLowerCase() === '.xlsx';
  }

  isWordFile(extension: string): boolean {
    return extension.toLowerCase() === '.doc' || extension.toLowerCase() === '.docx';
  }
  isImageFile(extension: string): boolean {
    return extension.toLowerCase() === '.png' || extension.toLowerCase() === '.jpg' || extension.toLowerCase() === '.jpeg' || extension.toLowerCase() === '.gif';
  }
  isTxtFile(extension: string): boolean {
    return extension.toLowerCase() === '.txt';
  }
  isVideoFile(extension: string): boolean {
    return extension.toLowerCase() === '.mp4' || extension.toLowerCase() === '.mov' || extension.toLowerCase() === '.avi' || extension.toLowerCase() === '.mkv';
  }

  getIconType(extension: string): string {
    if (this.isPdfFile(extension)) {
      return 'fa-file-pdf';
    } else if (this.isExcelFile(extension)) {
      return 'fa-file-excel';
    } else if (this.isWordFile(extension)) {
      return 'fa-file-word';
    } else if (this.isImageFile(extension)) {
      return 'fa-file-image';
    } else if (this.isTxtFile(extension)) {
      return 'fa-file-alt'; // Assuming this icon for text files
    } else {
      return 'fa-file';
    }
  }




 
 


  openVideo(attachmentParam: any): void {
    if (this.isVideoFile(attachmentParam.extension)) {
      const videoUrl = `../assets/File/Uplaod/Documents/${attachmentParam.name}${attachmentParam.extension}`;

      const modalRef = this.modal.create({
        nzTitle: 'Video Player',
        nzContent: AttachmentfileUploadComponent,
        nzData: { videoUrl: videoUrl },
        nzFooter: null,
        nzWidth: '80%'
      });
    }
  }


  getFileUrl(attachmentParam: any): string | null {
    const basePath = '../assets/File/Uplaod/Documents';
    const fileName = attachmentParam.name;
    if (fileName) {
      return `${basePath}/${fileName}`;
    }
    return null;
  }



  getImageUrl(attachmentParam: any): string {
    const baseUrl = 'assets/File/Uplaod/Documents/';
    const imageName = attachmentParam.name + attachmentParam.extension;
    return baseUrl + imageName;
  }





openImagePreview(attachmentParam: any): void {
  this.previewUrl = this.getImageUrl(attachmentParam);
  this.previewExtension = attachmentParam.extension.toLowerCase();
}

}

export interface Data {
  id: number;
  name: string;
  age: number;
  address: string;
  disabled: boolean;
}
