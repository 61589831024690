<div class="ant-modal-content">
    <div class="ant-modal-body">
        <div class="ant-modal-confirm-body-wrapper">
            <div class="ant-modal-confirm-body">
                <strong class="styling">Import Recommended Cost Codes</strong>
                <div class="ant-modal-confirm-content">
                    <div class="BTConfirm-BodyContainer">
                        Are you sure you would like to import Buildertrend's recommended cost code list?
                    </div>
                </div>
            </div>
            <div class="mt-4 ant-modal-confirm-btn">
                <button
                data-testid="cancelPrompt"
                type="button"
                class="btns ant-btn ant-btn-button"
                (click)="closeDialog()"
            >
                <span>Cancel</span>
            </button>
            

                <button
                    data-testid="confirmPrompt"
                    type="button"
                    class="ms-3 ant-btn ant-btn-success"
                >
                    <span>Import Codes</span>
                </button>
            </div>
        </div>
    </div>
</div>
