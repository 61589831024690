import { Component, OnInit, EventEmitter, Output, TemplateRef } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ModalButtonOptions } from 'ng-zorro-antd/modal';
import { ResponseModel, ResponseModelArray } from 'src/Models/responseMessage.model';
import { FormBuilder } from '@angular/forms';
import { VarianceCodeService } from 'src/Service/VarianceCode/variance-code.service';
import { VarianceCode, VarianceCodeCategoryResponse, VarianceCodeResponse } from 'src/Models/VarianceCode/varianceCode.Model';


@Component({
  selector: 'app-variance-code',
  templateUrl: './variance-code.component.html',
  styleUrls: ['./variance-code.component.css']
})
export class VarianceCodeComponent implements OnInit {
  [x: string]: any;
  @Output() cancel = new EventEmitter<void>();
  @Output() varianceCodeResponse: VarianceCodeCategoryResponse;
  @Output() varianceCodeSaved = new EventEmitter<VarianceCodeCategoryResponse>(); // Event emitter for variance code saved response
  formFieldValues: any = {};
  selectedValue = null;
  varianceCategory: VarianceCode[] = [];
  varianceSubCode: VarianceCode[] = [];
  varianceCode = true;
  newvariancecode = true;
  checked: boolean = false;
  TitleValid: boolean = false;
  listOfOptions: VarianceCode[];

  constructor(
    private varianceCodeService: VarianceCodeService,
    private toastssServices: NzMessageService,
  ) { }

  ngOnInit(): void {
    this.loadVarianceCodes();
    this.initializeFormFields();
  }
  initializeFormFields(): void {
    this.formFieldValues = {
      globalId: '00000000-0000-0000-0000-000000000000',
      companyParameterId: 1,
      title: '',
      varianceCodeId: 0,
      subVarianceCodeId: 0,
      detail: '',
      isStatus: false,
      isCategory: false,
      isSubCodeOf: true,
      isCustomerVariance: false
    };
  }


  loadVarianceCodes(): void {
    this.varianceCodeService
      .getSubCodeData()
      .subscribe((res: ResponseModelArray<VarianceCode>) => {
        this.listOfOptions = res.result;
        if (res) {
          this.varianceCategory = res.result.filter(
            (variance) => variance.isCategory === true
          );
          this.varianceSubCode = res.result;
        }
      });
  }

  subCodeChanged(): void {
    const selectedSubCodeId = this.formFieldValues['subVarianceCodeId'];
    const matchingSubCode = this.varianceSubCode.find(variance => variance.id === selectedSubCodeId);
    if (matchingSubCode && matchingSubCode.varianceCodeId) {
      const matchingCategory = this.varianceCategory.find(variance => variance.id === matchingSubCode.varianceCodeId);

      if (matchingCategory) {
        this.formFieldValues['varianceCodeId'] = matchingCategory.id;
      }
    }
  }


  CloseNewVarianceCode(): void {
    this.cancel.emit();
  }
  toggleFields(): void {
    this.checked = !this.checked;
  }

  saveButton(): void {
    let subVarianceCodeId = this.formFieldValues.subVarianceCodeId;
    if (subVarianceCodeId > 0) {
      this.formFieldValues.varianceCodeId = subVarianceCodeId;
    }


    this.varianceCodeService.postVarianceCategory(this.formFieldValues)
      .subscribe((response) => {
        this.varianceCodeResponse = response.result;
        this.varianceCodeSaved.emit(response.result);
        this.toastssServices.success('New  Successfully!');
      });
  }



  // Define a method to handle "Save & Close" action
  saveAndCloseButton(): void {
    this.saveButton(); // Save the data
    this.varianceCodeResponse = null; // Hide the delete button
    this.CloseNewVarianceCode();
  }

  saveAndNew(): void {
    this.saveButton(); // Save the data
    this.varianceCodeResponse = null; // Hide the delete button
    this.toggleFields();
    this.initializeFormFields(); // Reset form fields for new entry
  }
}

