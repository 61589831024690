import { Component } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-sub-vendor-trade-agreement',
  templateUrl: './sub-vendor-trade-agreement.component.html',
  styleUrls: ['./sub-vendor-trade-agreement.component.css']
})
export class SubVendorTradeAgreementComponent {
  fileName: string;


constructor(private msg: NzMessageService) {}
  uploadButtonClicked = false;
  message: string = '';


  handleChange(info: NzUploadChangeParam): void {

    this.fileName=info.file.name;
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      this.msg.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`${info.file.name} file upload failed.`);
    }
    setTimeout(() => {
      this.message = 'Successfully';
      this.uploadButtonClicked = true;
    }, 1000);
  }
  
}
