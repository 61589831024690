import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ColorResponse } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { colorService } from 'src/Service/Job-List/Job-Information/color.service';
import { GlobalIdColorAssignment, LeadActivitiesColorAssignService } from 'src/Service/Sales-Lead-Activities/lead-activity-feature-services/lead-activities-color-assign.service';

@Component({
  selector: 'app-lead-activity-assign-display-color',
  templateUrl: './lead-activity-assign-display-color.component.html',
  styleUrls: ['./lead-activity-assign-display-color.component.css']
})
export class LeadActivityAssignDisplayColorComponent {
  @Output() cancel = new EventEmitter<void>();
  @Output() leadActivityColorAssign = new EventEmitter<void>();
  @Input() selectedGlobalIds: string[];

  validateForm!: FormGroup;
  colors: ColorResponse[] = [];
  selectedColor: string;
  selectedColorName: string = '';
  isAssignColor: boolean = false;

  constructor(
    private fb: FormBuilder,
    private colorService: colorService,
    private leadActivitiesColorAssignService: LeadActivitiesColorAssignService
  ) { }

  ngOnInit(): void {
    this.formGroup();
    this.getColor();
  }

  formGroup(): void {
    this.validateForm = this.fb.group({
      colorId: [null, Validators.required]
    });
  }

  getColor(): void {
    this.colorService.getColorData().subscribe((res) => {
      this.colors = res.result;
    });
  }

  updateSelectedColor(colorId: number): void {
    const selectedColor = this.colors.find(color => color.id === colorId);
    if (selectedColor) {
      this.selectedColor = selectedColor.colorCollection;
      this.selectedColorName = selectedColor.name;
    } else {
      this.selectedColor = '';
      this.selectedColorName = '';
    }
  }

  submitForm(): void {
    if (this.validateForm.valid) {
      this.isAssignColor = true;
      const colorAssignerData: GlobalIdColorAssignment[] = this.selectedGlobalIds.map(globalId => ({
        globalId,
        colorId: this.validateForm.value.colorId
      }));

      this.leadActivitiesColorAssignService.leadActivitiesColorReAssign(colorAssignerData).subscribe(res => {
        this.cancel.emit();
        this.leadActivityColorAssign.emit();
        this.isAssignColor = false;

      });
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
          this.isAssignColor = false;

        }
      });
    }
  }

  Cancel(): void {
    this.cancel.emit();
  }
}
