import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-add-contact-learn-more',
  templateUrl: './add-contact-learn-more.component.html',
  styleUrls: ['./add-contact-learn-more.component.css']
})
export class AddContactLearnMoreComponent {
  @Output() cancel = new EventEmitter<void>();
  
  learnMoreAddForm = true;

  learnMoreclose(): void {
    this.cancel.emit();
  }

}



