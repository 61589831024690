import { Component } from '@angular/core';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';

@Component({
  selector: 'app-inner-tab-viewing',
  templateUrl: './inner-tab-viewing.component.html',
  styleUrls: ['./inner-tab-viewing.component.css']
})
export class InnerTabViewingComponent {
  value: string[] = ['0-0-0'];





  nodes: NzTreeNodeOptions[] = [
    {
      title: 'Check All',
      value: '0-all',
      key: '0-all',
      isLeaf: true,
      checked: true
    },
    {
      title: 'Node1',
      value: '0-0',
      key: '0-0',
      isLeaf: true
    },
    {
      title: 'Node2',
      value: '0-1',
      key: '0-1',
    },
    {
      title: 'Node3',
      value: '0-2',
      key: '0-2',
      isLeaf: true
    }
  ];
}
