import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sales-add-lead-activity-type',
  templateUrl: './sales-add-lead-activity-type.component.html',
  styleUrls: ['./sales-add-lead-activity-type.component.css']
})
export class SalesAddLeadActivityTypeComponent {
  @Output() cancel = new EventEmitter<void>();

  // selectionModel
  customfieldinfo= true;
  closeAddLeadActivityType(): void {
  this.cancel.emit();
  }
}
