import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from 'src/Service/CompanyInFormation/modal.service';

@Component({
  selector: 'app-project-management-site-diaries-document-doc',
  templateUrl: './project-management-site-diaries-document-doc.component.html',
  styleUrls: ['./project-management-site-diaries-document-doc.component.css']
})
export class ProjectManagementSiteDiariesDocumentDOCComponent {
  constructor(private modal:ModalService, private fb:FormBuilder){}
  @Output() cancel = new EventEmitter<void>();

  projectManagementSitediariesDocForm:FormGroup;
  uploadfiles=true;
  UploadFiles(): void {
  this.cancel.emit();
  }

  projectManagementSiteDiariesDoc(): void {
    this.projectManagementSitediariesDocForm = this.fb.group({
      untitledDocument: [null],
      owner: [null],
      subs: [null],
    });
  }



  
}
