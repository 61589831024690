import { ToDo } from './../../../../../Models/Warranty/warranty';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProjectManagementSiteDiariesRelatedToDoStComponent } from '../../Site-Diaries/project-management-site-diaries-related-to-do-st/project-management-site-diaries-related-to-do-st.component';
import { ToDosImportDataFromTemplateComponent } from '../to-dos-import-data-from-template/to-dos-import-data-from-template.component';
import { ToDosComponent } from 'src/app/company-settings/to-dos/to-dos.component';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { ProjectManagementSelectDialogBoxComponent } from '../../project-management-select-dialog-box/project-management-select-dialog-box.component';
import { JobInfoComponentInformation } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { Subscription } from 'rxjs';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { FilterSearchParameter, LookupFilterResponse } from 'src/app/shared/component/Models/LookupModels';
import { StandardColumnSetupResponse, StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';
import { ToDoInformationService } from 'src/Service/Project-Management/to-do-information.service';
import { CreateIsCompletedRequest, ToDoResponse } from 'src/Models/Project-Management/projectManagement';
import { ColumnsResizePinProperties } from 'src/Models/InternalUser/internalUserColumnsPinResize';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AddNewGridStandardViewComponent } from 'src/app/shared/component/Modal/add-new-grid-standard-view/add-new-grid-standard-view.component';
import { ToDoParameterResponse } from 'src/Models/Warranty/warranty';
import { ProjectManagementSiteDiariesCommentComponent } from '../../Site-Diaries/project-management-site-diaries-comment/project-management-site-diaries-comment.component';
import {
  aggregateBy,
  AggregateDescriptor,
  AggregateResult,
  GroupDescriptor,
} from "@progress/kendo-data-query";
import { SVGIcon, fileExcelIcon } from "@progress/kendo-svg-icons";
import { CopyToDosComponent } from '../copy-to-dos/copy-to-dos.component';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
@Component({
  selector: 'app-project-management-to-dos-db',
  templateUrl: './project-management-to-dos-db.component.html',
  styleUrls: ['./project-management-to-dos-db.component.css']
})
export class ProjectManagementToDosDbComponent implements OnInit  , OnDestroy {
  private subscriptions: Subscription[] = [];
  private ToDosubscription: Subscription;
  private ToDoFilterSubscription: Subscription;
  private displayColumnSubscription: Subscription;
  private TodostandardViewFilterSubscription : Subscription
  jobInfoOptions: JobInfoComponentInformation[] = [];
  isCollapsed = false;
  selectedJobId: number = 0;
  PAGE_ID: number = -1;
  PROJECT_MANG_Todos: string = FiltersName.PROJECT_MANG_Todos;
  displayedColumns: string[] = [];
  selectedFilter: number = -1;
  isFilterApplied: boolean = false;
  lookupFilterResponse: LookupFilterResponse[] = [];
  defaultFilterId_selected: number = 0
  filterSearchParameter: FilterSearchParameter;
  currentPage = 1;
  pageSize = 50;
  totalRecords = 100;
  displayedData: ToDoResponse[] = [];
  fixedSelectedColumnNames: string[] = [];
  selected_View_Columns: string[] = [];
  getStandardViewColumnFilterData: StandardColumnSetupResponse[] = [];
  getStandardViewFilterData: StandardViewResponse[] = [];
  selectedValueControl: any;
  public mySelection: string[] = [];

  expandedRows: number[] = [];
  isColumnSticky: boolean[] = new Array(this.displayedColumns.length).fill(false);
  columnSizes: { columnName: string, widthSize: number }[] = [];
  getColumnWidthSize: number[] = [];
  isLoading: boolean = false;
  defaultFilterIdSelected: number = 0;

  selectedColNameSizePin: ColumnsResizePinProperties[] = [];
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  constructor(private modal: NzModalService, private filterService: FilterSideMenuBarService,
    private jobInfoService: JobInformationService,
    public localStorage: AccessLocalStorageService,
    private standardViewService: StandardViewForAllService,
    private changeDetectorRef: ChangeDetectorRef,
    private LookupFilterService: LookupFilterService,
    private toDoService: ToDoInformationService,
    private toastService: NzMessageService,
    private internalUserService: InternalUserService,

  ) {
    if (this.ToDoFilterSubscription) {
      this.ToDoFilterSubscription.unsubscribe();
    }
    if (this.ToDosubscription) {
        this.ToDosubscription.unsubscribe();
    }
    if (this.TodostandardViewFilterSubscription) {
      this.TodostandardViewFilterSubscription.unsubscribe();
    }
    console.clear();
   }


  ngOnInit(): void {
    this.internalUserService.setSelectedFilterId(0);
    if (this.ToDoFilterSubscription) {
      this.ToDoFilterSubscription.unsubscribe();
    }
    if (this.ToDosubscription) {
        this.ToDosubscription.unsubscribe();
    }
    if (this.TodostandardViewFilterSubscription) {
      this.TodostandardViewFilterSubscription.unsubscribe();
    }

    this.PAGE_ID = 6
    this.selectedJobId = this.localStorage.getJobId();
    // this.getLeadData();
    this.localStorage.getJobId();
    this.localStorage.getJobName();

    this.displayedColumns = [];
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    // Add subscriptions to the array
    this.subscriptions.push(
      this.standardViewService.$displaySelectedColApply.subscribe(colName => {
        if (colName.length > 0) {
          this.displayedColumns = colName;
          this.changeDetectorRef.detectChanges();
        }
      })
    );

    this.subscriptions.push(
      this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
        this.selectedFilter = standardType != -1 ? standardType : standardType;
      })
    );
    // this.displayedColumns = [];
    // this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
    //   if (colName.length > 0) {
    //     this.displayedColumns = colName;

    //     this.changeDetectorRef.detectChanges();
    //   }
    // });

    // this.displayColumnSubscription = this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
    //   if (standardType != -1) {
    //     this.selectedFilter = standardType;
    //   } else {
    //     this.selectedFilter = standardType;
    //   }
    // });

    this.getStandardFilterByFormPageId(this.PAGE_ID);
    this.getStandardViewFilterByFormPageId(this.PAGE_ID);
    this.toDoService.$isUserAppliedFilter.subscribe(filter => {
      if (filter) {
        this.isFilterApplied = filter;
      } else {
        this.isFilterApplied = filter;
      }
    });

    console.log('Data', this.mySelection);

    this.clearSelection();
  }


  getStandardFilterByFormPageId(pageId: number) {
    if (this.ToDoFilterSubscription) {
      this.ToDoFilterSubscription.unsubscribe();
  }
  if (this.ToDosubscription) {
      this.ToDosubscription.unsubscribe();
  }
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.ToDoFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.lookupFilterResponse = result.result;

        let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
        this.toDoService.setSelectedFilterId(defaultFilterId);
        this.defaultFilterId_selected = defaultFilterId;
        this.customerGridTableData(1, this.PAGE_ID, defaultFilterId, this.currentPage, this.pageSize, this.selectedJobId);
      }
    });
  }

  customerGridTableData(companyId: number, formNameId: number, lookUpFilterId: number, page: number, pageSize: number, jobInformationId: number) {
    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId: lookUpFilterId,
      page: page,
      pageSize: pageSize,
      jobInformationId: this.selectedJobId
    };

    this.toDoService.getAlltodoUsersByFilter(this.filterSearchParameter);
    this.ToDosubscription =  this.toDoService.$getToDoInformation.subscribe(result => {
      if (result) {
        this.displayedData = result?.result?.rows;

      }
    });
  }

  getStandardViewFilterByFormPageId(pageId: number) {
    if (this.TodostandardViewFilterSubscription) {
      this.TodostandardViewFilterSubscription.unsubscribe();
    }
    this.displayedColumns = [];
    this.fixedSelectedColumnNames = [];
    this.standardViewService.getStandardViewFilterByFormPageId(pageId);
    this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
    // this.standardViewService.$getStandardViewFilter.subscribe(result => {
    this.TodostandardViewFilterSubscription = this.standardViewService.$getStandardViewFilter.subscribe(result => {
      if (result) {
        this.displayedColumns = [];
        this.selected_View_Columns = [];
        this.getStandardViewFilterData = result.result;
        let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).standardViewParameters;
        this.selectedValueControl = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).id;
        defaultView.forEach(viewItem => {
          if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
            this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
          }
        });
        this.displayedColumns = this.selected_View_Columns;
      }
    });

    this.standardViewService.$getStandardViewColumn.subscribe(result => {
      if (result) {
        this.getStandardViewColumnFilterData = result.result;
        let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);
        fixedCol.forEach(x => {
          this.fixedSelectedColumnNames.push(x.displayColumnName);
        });

      }
    });

  }



  toggleColumnSticky(index: number) {
    this.isColumnSticky[index] = !this.isColumnSticky[index];
    if (this.isColumnSticky[index]) {
      for (let i = 0; i < index; i++) {
        this.isColumnSticky[i] = true;
      }
    }
  }


  saveColumnSizes(event: any) {
    event.forEach(col => {
      const columnName = col.column.field;
      const widthSize = col.newWidth;
      const existingIndex = this.columnSizes.findIndex(item => item.columnName === columnName);

      if (existingIndex !== -1) {
        this.columnSizes[existingIndex].widthSize = widthSize;
      } else {
        this.columnSizes.push({ columnName, widthSize });
      }
    });

  }


  onPageChange(page: number): void {
    this.currentPage = page;
    let filterId: number = 0;
    this.toDoService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.customerGridTableData(1, this.PAGE_ID, filterId, page, this.pageSize, this.selectedJobId);
      this.isLoading = false;

    }, 100);
  }

  onPageSizeChange(size: number): void {
    this.pageSize = size;
    let filterId: number = 0;
    this.toDoService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.customerGridTableData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize, this.selectedJobId);
      this.isLoading = false;
    }, 100);
  }


  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
  }


  saveEditStandardView() {
    this.standardViewService.$createStandardViewRequest.subscribe(formData => {
      if (formData && formData !== null) {
        this.standardViewService.postData(formData)
          .subscribe(
            () => {
              setTimeout(() => {
                // this.loadingIndicateService.hide();
                this.isLoading = true;
                this.toastService.success('Standard View Updated Successfully');
                this.standardViewService.getStandardViewFilterByFormPageId(this.PAGE_ID);
                this.selectedFilter = -1;
              }, 2000);
            },
            (error) => {
              console.error('Error:', error);
              //this.loadingIndicateService.hide();
              this.isLoading = false;
            }
          );
      }
    });
  }


  onFilterChange(id: number) {

    this.selectedFilter = -1;
    this.displayedColumns = [];
    this.selected_View_Columns = [];
    let selectedView = this.getStandardViewFilterData.find(view => view.id === id).standardViewParameters;
    selectedView.forEach(viewItem => {
      if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
        this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
        this.displayedColumns = this.selected_View_Columns;
      }
    });
  }



  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }



  // selectionModelOpen(select: string): void {
  //   const modalRef = this.modal.create({
  //     nzContent: ProjectManagementSelectDialogBoxComponent,
  //     nzData: {
  //       type: select,
  //       leads: this.jobInfoOptions

  //     },
  //     nzFooter: null
  //   });
  //   modalRef.componentInstance.cancel.subscribe(() => {
  //     modalRef.destroy();
  //   });

  // }

  toDosModalOpen(select: string): void {
    const jobId = this.localStorage.getJobId();
    if (jobId !== null) {
      const modalRef = this.modal.create({
        nzWidth: '60%',
        nzBodyStyle: { 'background': '#f1f4fa', 'padding': '0', 'margin': '0', 'overflow-x': 'hidden', },
        nzStyle: {
          top: '25px',
        },
        nzContent: ProjectManagementSiteDiariesRelatedToDoStComponent,
        nzData: {
          JOB_ID: jobId,
          JOB_NAME: this.localStorage.getJobName(),


        },
        nzFooter: null
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
    } else {
      const modalRef = this.modal.create({
        nzWidth: '32%',
        nzStyle: {
          top: '36%',
        },
        nzContent: ProjectManagementSelectDialogBoxComponent,
        nzData: {
          type: select,
          leads: this.jobInfoOptions
        },
        nzFooter: null
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
    }
  }


  // getLeadData() {
  //   this.jobInfoService.getAllLeadData().subscribe(Data => {
  //     this.jobInfoOptions = Data.result;
  //   });
  // }

  ImportModelOpen(): void {
    const modalRef = this.modal.create({
      nzContent: ToDosImportDataFromTemplateComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  ToDodsSetting(): void {
    const modalRef = this.modal.create({
      nzContent: ToDosComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  openFilter(component: string, pageId: number) {
    this.filterService.toggleFilter(component, pageId);
  }


  // Selected Job Id From Left Side Job List
  getSelectedJobId(item: number) {
    this.selectedJobId = item;
    this.displayedData = null;
    this.getStandardFilterByFormPageId(this.PAGE_ID);
    console.log("selectedJobId : ", this.selectedJobId);
  }




  // footer methods

  openStandardViewFilterComponent(isSaveAs?: string) {
    let isInputVisible: boolean = false;
    if (isSaveAs === 'saveAs') {
      isInputVisible = true;
    } else {
      isInputVisible = false;
    }
    this.selectedColNameSizePin = [];
    for (let i = 0; i < this.displayedColumns.length; i++) {
      const item = this.displayedColumns[i];
      const isPinValue = this.isColumnSticky[i];
      let widthSize = 0;
      if (this.columnSizes.length > 0) {
        for (let i = 0; i < this.columnSizes.length; i++) {
          const columnName = this.columnSizes[i].columnName;
          const columnNameSize = this.columnSizes[i].widthSize;
          if (columnName === item) {
            widthSize = columnNameSize;
            break;
          }
        }
      }

      if (widthSize === 0) {
        widthSize = 180;
      }

      this.selectedColNameSizePin.push({
        displayColumnName: item,
        resizeColumnWidth: widthSize, //  "200px", // Set default width here
        isPin: isPinValue == true ? isPinValue : false
      });
    }
    const modalRef = this.modal.create({
      nzContent: AddNewGridStandardViewComponent,
      nzData:
      {
        pageId: this.PAGE_ID,
        column: this.getStandardViewColumnFilterData,
        displayCol: this.displayedColumns,
        viewList: this.getStandardViewFilterData,
        selectedColNameSizePin: this.selectedColNameSizePin,
        selectedStandardView: this.selectedValueControl,
        fixedCol: this.fixedSelectedColumnNames,
        isSaveAs: isInputVisible
      },

    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }



  logIsCompleted(globalId: string) {

    const isCompletedPayload: CreateIsCompletedRequest = {
      globalId: globalId,
      completedBy: 1
    };
    this.toDoService.updateIsCompleted(isCompletedPayload).subscribe(res => {
      console.log(res.result);
      if (res.result.isCompleted === true) {
        this.toastService.success('To-Do marked complete');
      } else {
        this.toastService.success('To-Do marked incomplete');
      }

    });

    let filterId: number = 0;
    this.toDoService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.defaultFilterIdSelected = filterId;
    this.customerGridTableData(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPage, this.pageSize, this.selectedJobId);

  }


  // Grid Nested Data Access Using Methods


  getAssignedToName(dataItem: any): string {
    // Check if dataItem exists and has the necessary properties
    if (dataItem?.toDoParameters && dataItem.toDoParameters.length > 0) {
      const firstToDoParameter = dataItem.toDoParameters[0]; // Assuming we are interested in the first toDoParameter
      if (firstToDoParameter?.toDoParameterAssignOwners && firstToDoParameter.toDoParameterAssignOwners.length > 0) {
        const firstAssignOwner = firstToDoParameter.toDoParameterAssignOwners[0]; // Assuming we are interested in the first assign owner
        if (firstAssignOwner?.applicationUser) {
          const user = firstAssignOwner.applicationUser;
          const fullNameParts = [
            user.firstName || '',
            user.middleName || '',
            user.lastName || ''
          ];
          return fullNameParts.filter(part => part.trim() !== '').join(' ');
        }
      }
    }
    return ''; // Return empty string if data structure doesn't match or data is unavailable
  }
  getAssignedSubVendorToName(dataItem: any): string {
    // Check if dataItem exists and has the necessary properties
    if (dataItem?.toDoParameters && dataItem.toDoParameters.length > 0) {
      const firstToDoParameter = dataItem.toDoParameters[0]; // Assuming we are interested in the first toDoParameter
      if (firstToDoParameter?.toDoParameterAssignOwners && firstToDoParameter.toDoParameterAssignOwners.length > 0) {
        const firstAssignOwner = firstToDoParameter.toDoParameterAssignOwners[0]; // Assuming we are interested in the first assign owner
        if (firstAssignOwner?.subVendor) {
          const user = firstAssignOwner.subVendor;
          const fullNameParts = [
            user.companyName || '',

          ];
          return fullNameParts.filter(part => part.trim() !== '').join(' ');
        }
      }
    }
    return ''; // Return empty string if data structure doesn't match or data is unavailable
  }
  getAssignedOwnerToName(dataItem: any): string {
    // Check if dataItem exists and has the necessary properties
    if (dataItem?.toDoParameters && dataItem.toDoParameters.length > 0) {
      const firstToDoParameter = dataItem.toDoParameters[0]; // Assuming we are interested in the first toDoParameter
      if (firstToDoParameter?.toDoParameterAssignOwners && firstToDoParameter.toDoParameterAssignOwners.length > 0) {
        const firstAssignOwner = firstToDoParameter.toDoParameterAssignOwners[0]; // Assuming we are interested in the first assign owner
        if (firstAssignOwner?.customerInformation) {
          const user = firstAssignOwner.customerInformation;
          const fullNameParts = [
            user.displayName || '',

          ];
          return fullNameParts.filter(part => part.trim() !== '').join(' ');
        }
      }
    }
    return ''; // Return empty string if data structure doesn't match or data is unavailable
  }



  // edit Mode open Todos

  editTodo(rowToDoData: ToDoParameterResponse) {
    const modalRef = this.modal.create({
      nzWidth: '60%',
      nzBodyStyle: { 'background': '#f1f4fa', 'padding': '0', 'margin': '0', 'overflow-x': 'hidden', },
      nzStyle: {
        top: '25px',
      },
      nzContent: ProjectManagementSiteDiariesRelatedToDoStComponent,
      nzData: {
        rowToDoData: rowToDoData.id,
        rowToDoData2: rowToDoData
      },
      nzFooter: null
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  editComments(rowData: ToDoParameterResponse): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesCommentComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.editDataResponseByToDos = rowData;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }


  globalIds: string[] = [];
  ids: number[] = [];

  selectionChange(event: any): void {
    if (event && event.length > 0) {
      this.globalIds = [];
      this.ids = [];
      event.forEach((id: number) => {
        const selectedRow = this.displayedData.find(row => row.id === id);
        if (selectedRow) {
          this.globalIds.push(selectedRow.toDoParameters[0]?.globalId); // Add globalId to array
          this.ids.push(selectedRow.toDoParameters[0]?.id); // Add globalId to array
          console.log('Selected row data', selectedRow);
          console.log('Selected globalIds', this.globalIds);
          console.log('Selected ids', this.ids);
        }
      });

    } else {
      console.log('No rows selected.');
      this.clearSelection();
    }
  }


  deleteRowTodo() {
    this.toDoService.deleteRowTodoRange(this.globalIds).subscribe(res => {
      this.toastService.success('To-Do Deleted!');
      this.clearSelection();
      let filterId: number = 0;
      this.toDoService.$selectedFilterId.subscribe(v => {
        if (v) {
          filterId = v;
        }
      });
      this.isLoading = true;
      setTimeout(() => {
        this.defaultFilterIdSelected = filterId;
        this.customerGridTableData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize, this.selectedJobId);
        this.isLoading = false;
      }, 100);
    })
  }


  clearSelection(): void {
    this.mySelection = [];
    this.ids = [];
  }

  showDeleteConfirm(): void {
    const selectedCount = this.mySelection.length;
    this.modal.confirm({
      nzTitle: `<h1>Delete ${selectedCount} To-Do${selectedCount > 1 ? 's' : ''}?</h1>`,
      nzOkText: `Delete ${selectedCount} To-Do${selectedCount > 1 ? 's' : ''}`,
      nzOkDanger: true,
      nzOnOk: () => this.deleteRowTodo(),
      nzCancelText: 'Cancel',
      nzOnCancel: () => this. clearSelection(),
      nzCentered: true,
      nzWidth: '500px',
      nzBodyStyle: {'border-radius': '30px','height': 'auto','padding':'45px'},
      nzCloseIcon: '',
      nzIconType: '',

    });
  }


  // Check box Selected then show button Copy open New Form and Against of Jop Create toDo

  copyToDos(): void {
      const modalRef = this.modal.create({
        nzContent: CopyToDosComponent,
        nzFooter: null,
        nzTitle: null,
        nzCentered:true
      });
      modalRef.componentInstance.ids = this.ids
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.componentInstance.onSaveEmit.subscribe(() => {
        this.clearSelection();
        let filterId: number = 0;
      this.toDoService.$selectedFilterId.subscribe(v => {
        if (v) {
          filterId = v;
        }
      });
      this.isLoading = true;
      setTimeout(() => {
        this.defaultFilterIdSelected = filterId;
        this.customerGridTableData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize, this.selectedJobId);
        this.isLoading = false;
      }, 100);
      });
    }
    ngOnDestroy() {
      this.LookupFilterService.getStandardFilter.next(null); // New Changes By Aamir Ali - 31-May-2024
      localStorage.removeItem('Selected_Filter_Id'); // New Changes By Aamir Ali - 31-May-2024
      if (this.ToDoFilterSubscription) {
        this.ToDoFilterSubscription.unsubscribe();
      }
      if (this.ToDosubscription) {
          this.ToDosubscription.unsubscribe();
      }
      if (this.TodostandardViewFilterSubscription) {
        this.TodostandardViewFilterSubscription.unsubscribe();
      }
      console.clear();
    }
}
