<nz-modal
  nzWidth="63%"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzFooter]="newContactmodalFooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '15px',
    'max-height': '75vh',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="showConfirmCardCustomer()"
>
  <ng-template #modalTitle>
    <div class="row p-0 m-0">
      <h1 nz-typography>Customer Contact</h1>
    </div>
  </ng-template>

  <form nz-form>
    <div class="content" *nzModalContent>
      <div class="spinner-wrapper" *ngIf="isLoading">
        <nz-spin nzSimple [nzSpinning]="true" [nzSize]="'large'"></nz-spin>
      </div>

      <!-- Alert For Status Start -->
       <nz-alert class="mb-3" *ngIf="CustomerResponse?.statusSetup?.code === StatusCode.ACTIVE.code" nzType="info" nzMessage="This user maintains their contact information." nzShowIcon></nz-alert>
       <nz-alert 
       class="mb-3" 
       *ngIf="(CustomerResponse?.statusSetup?.code === StatusCode.PENDING.code || 
               CustomerResponse?.statusSetup?.code === StatusCode.DISABLED.code) ||
              updateStatus?.code === StatusCode.PENDING.code" 
       nzType="info" 
       nzMessage="After activating their account, the user maintains their contact information and will see all jobs they are added to." 
       nzShowIcon>
     </nz-alert>
     
      <!-- Alert For Status End -->


      <!-- Error Card Start-->

      <nz-alert
      class=" mb-3 error-message"
      *ngIf="isSubmitted && (errorFields.length > 0 || errorFieldsInfo.length > 0)"
      nzType="error"
      nzShowIcon
      [nzMessage]="message"
      [nzDescription]="errorDescriptionTemplate"
    ></nz-alert>
    
    <ng-template #message>
      <strong class="mt-1">Please correct the following fields:</strong>
    </ng-template>
    
    <ng-template #errorDescriptionTemplate>
      <ul >
        <ng-container *ngIf="errorFields.length > 0 && errorFieldsInfo.length === 0">
          <li class="sub-text p-0" *ngFor="let error of errorFields">
            <span class="d-flex align-items-center">
              <p class="me-1">{{ error.label }}:</p>
              <p>{{ error.message }}</p>
            </span>
          </li>
        </ng-container>
    
        <ng-container *ngIf="errorFieldsInfo.length > 0 && errorFields.length === 0">
          <li class="sub-text p-0" *ngFor="let error of errorFieldsInfo">
            <span class="d-flex align-items-center">
              <p class="me-1">{{ error.label }}:</p>
              <p>{{ error.message }}</p>
            </span>
          </li>
        </ng-container>
      </ul>
    </ng-template>
    
    

      <!-- Error Card End-->


            <!-- Customer Info Card Start -->

           <nz-card class="mb-3 " [nzTitle]="ContactInformation" *ngIf="isInfoCardVisible()">
              <ng-template #ContactInformation>
                <h3 nz-typography>Contact Information</h3>
              </ng-template>
      
              <div nz-row>
                <div nz-col nzSpan="2" style="top: -15px">
                  <nz-avatar
                    [nzSize]="64"
                    [nzText]="hasInitialsInfoCard() ? getAvatarInitialsInfoCard() : ''"
                    [nzIcon]="!hasInitialsInfoCard() ? 'user' : ''"
                  >
                  </nz-avatar>
                </div>
                <div nz-col nzSpan="22">
                  <div nz-row>
                        <div class="col-12">
                          <p
                            class="mb-1"
                            *ngIf="
                              CustomerResponse?.firstName || CustomerResponse?.lastName
                            "
                          >
                            {{ CustomerResponse?.firstName }}
                            {{ CustomerResponse?.lastName }}
                          </p>
          
                          <p class="mb-1" *ngIf="CustomerResponse?.displayName">
                            {{ CustomerResponse?.displayName }}
                          </p>
                          <p
                            class="mb-1"
                            *ngIf="CustomerResponse?.streetAddress"
                          >
                            {{ CustomerResponse?.streetAddress }}
                          </p>
                          <p class="mb-1" *ngIf="CustomerResponse?.suburb">
                            {{ CustomerResponse?.suburb }}
                          </p>
                          <p
                            class="mb-1"
                            *ngIf="
                              CustomerResponse?.state || CustomerResponse?.postalCode
                            "
                          >
                            {{ CustomerResponse?.state }}
                            {{ CustomerResponse?.postalCode }}
                          </p>
                          <p class="mb-1" *ngIf="CustomerResponse?.phone">
                            Phone:
                            <a class="text-primary"> {{ CustomerResponse?.phone }}</a>
                          </p>
                          <p class="mb-1" *ngIf="CustomerResponse?.cellPhone">
                            Cell:
                            <a class="text-primary"> {{ CustomerResponse?.cellPhone }}</a>
                          </p>
                          <p
                            class="mb-1"
                            *ngIf="CustomerResponse?.primaryEmail"
                          >
                            Primary Email:
                            <i
                              class="bi bi-info-circle-fill me-1"
                              nz-popover
                              [nzPopoverContent]="contentTemplate"
                              nzPopoverPlacement="top"
                            ></i>
                            <ng-container
                              *ngIf="CustomerResponse?.label; else showPrimaryEmail"
                            >
                              {{ CustomerResponse?.label }}
                            </ng-container>
                            <ng-template #showPrimaryEmail>
                              <a
                                class="text-primary"
                                *ngIf="CustomerResponse?.primaryEmail"
                              >
                                {{ CustomerResponse?.primaryEmail }}
                              </a>
                            </ng-template>
                            <ng-template #contentTemplate>
                              <div class="">
                                <p style="height: auto; width: 180px">
                                  This email activates and maintains the account
                                </p>
                              </div>
                            </ng-template>
                          </p>
          
                          <p
                            class="mb-1"
                            *ngIf="
                              CustomerResponse?.customerEmailParameters &&
                              CustomerResponse.customerEmailParameters.length > 0
                            "
                          >
                            Additional Email:
                            <ng-container *ngFor="let emailObj of CustomerResponse.customerEmailParameters; let last = last">
                              <ng-container *ngIf="emailObj">
                                <span>
                                  <a *ngIf="emailObj.label" class="text-primary" [href]="'mailto:' + emailObj.email">{{ emailObj.label }}</a>
                                  <a *ngIf="!emailObj.label" class="text-primary" [href]="'mailto:' + emailObj.email">{{ emailObj.email }}</a>
                                  <ng-container *ngIf="!last">, </ng-container>
                                </span>
                              </ng-container>
                            </ng-container>
                          </p>
      
                                 <!-- additional emails active Customer Start-->
      
                        <ng-container >
                          <div
                            class="mb-2"
                            *ngFor="
                              let item of formFieldValuesEmails.createCustomerEmailParameterRequests;
                              let i = index
                            "
                            nz-row
                            nzAlign="top"
                          >
                            <div nz-col nzSpan="12">
                              <label class="sub-text"
                                >Additional Email
                                <span class="text-danger fw-bolder">*</span></label
                              >
                              <nz-form-control>
                                <input
                                  nz-input
                                  [(ngModel)]="
                                    formFieldValuesEmails.createCustomerEmailParameterRequests[
                                      i
                                    ].email
                                  "
                                  name="email{{ i }}"
                                  (ngModelChange)="onFieldChangeInfo('email' + i, $event)"
                                  class="formField"
                                  [nzStatus]="validationStatesInfo['email' + i]"
                                />
                              </nz-form-control>
                              <div
                                *ngIf="validationStatesInfo['email' + i] === 'error'"
                                class="text-danger"
                              >
                                {{ validationMessagesInfo["email" + i] }}
                              </div>
                            </div>
                            <div
                              nz-col
                              nzSpan="12"
                              style="
                                padding-left: 10px;
                                padding-right: 4px;
                                flex: 1 1 0px;
                              "
                            >
                              <label class="sub-text">Label</label>
                              <nz-form-control>
                                <input
                                  nz-input
                                  [(ngModel)]="
                                    formFieldValuesEmails.createCustomerEmailParameterRequests[
                                      i
                                    ].label
                                  "
                                  name="label{{ i }}"
                                  (ngModelChange)="
                                    onFieldChangeInfo('label' + i, $event)
                                  "
                                  class="formField"
                                  [nzStatus]="validationStatesInfo['label' + i]"
                                />
                              </nz-form-control>
                              <div
                                *ngIf="
                                  validationStatesInfo['label' + i] === 'error'
                                "
                                class="text-danger"
                              >
                                {{ validationMessagesInfo["label" + i] }}
                              </div>
                            </div>
                            <div nz-col class="d-flex align-items-center">
                              <div
                                class="trash-icon ms-2 mt-4"
                                (click)="removeAdditionalEmailInfoCard(i)"
                              >
                                <span
                                  nz-icon
                                  nzType="delete"
                                  nzTheme="fill"
                                  class="m-0 p-0"
                                ></span>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        <!-- additional emails active Customer End-->
          
                        <!-- Add Button Additional Email Start -->
                        <div
                          class="row mb-2 mt-2"
                          *ngIf="
                          formFieldValuesEmails?.createCustomerEmailParameterRequests
                              ?.length < 5 
                          "
                        >
                          <button
                            nz-button
                            nzType="text"
                            class="col-3 d-flex ms-3"
                            (click)="addAdditionalEmailInfoCard()"
                          >
                            <a
                              class="fs-5"
                              nz-icon
                              nzType="plus-circle"
                              nzTheme="fill"
                            ></a>
                            <a class="ms-2" style="bottom: 100">Add Additional Email</a>
                          </button>
                        </div>
                        <!-- Add Button Additional Email End -->
                        </div>
                      </div>
                    </div>
                  </div>
          </nz-card>
                
            <!-- Customer Info Card End -->



      <ng-container *ngIf="isCardVisible()">
        <nz-card class="mb-3 " [nzTitle]="ContactInformation">
          <ng-template #ContactInformation>
            <h3 nz-typography>Contact Information</h3>
          </ng-template>

          <div nz-row>
            <div nz-col nzSpan="2" style="left: -10px; top: -5px">
              <nz-avatar
                [nzSize]="64"
                [nzText]="hasInitials() ? getAvatarInitials() : ''"
                [nzIcon]="!hasInitials() ? 'user' : ''"
              >
              </nz-avatar>
            </div>

            <div nz-col nzSpan="22">
              <div nz-row>
                <div nzSpan="12" nz-col>
                  <label class="sub-text" for="firstName">First Name</label>
                  <nz-form-control>
                    <input
                      #firstNameInput
                      nz-input
                      [(ngModel)]="formFieldValues.firstName"
                      name="firstName"
                      (ngModelChange)="onFieldChange('firstName', $event)"
                      [nzStatus]="validationStates['firstName']"
                      class="formField"
                      autocomplete="off"
                    />
                  </nz-form-control>
                  <span
                    *ngIf="validationStates['firstName'] === 'error'"
                    class="text-danger"
                  >
                    {{ validationMessages["firstName"] }}
                  </span>
                </div>

                <div nzSpan="12" nz-col style="padding-left: 10px">
                  <label class="sub-text" for="lastName">Last Name</label>
                  <nz-form-control>
                    <input
                      nz-input
                      [(ngModel)]="formFieldValues.lastName"
                      name="lastName"
                      (ngModelChange)="onFieldChange('lastName', $event)"
                      [nzStatus]="validationStates['lastName']"
                      class="formField"
                    />
                  </nz-form-control>
                  <span
                    *ngIf="validationStates['lastName'] === 'error'"
                    class="text-danger"
                  >
                    {{ validationMessages["lastName"] }}
                  </span>
                </div>
              </div>

              <div nz-row>
                <div nzSpan="24" nz-col>
                  <label class="sub-text" for="displayName"
                    >Display Name
                    <span class="text-danger p-error fw-bolder">*</span>
                    <i
                      class="bi bi-info-circle-fill ms-1"
                      nz-popover
                      [nzPopoverContent]="DisplayName"
                      nzPopoverPlacement="right"
                    ></i>
                  </label>
                  <nz-form-control>
                    <input
                      nz-input
                      [(ngModel)]="formFieldValues.displayName"
                      name="displayName"
                      (ngModelChange)="onFieldChange('displayName', $event)"
                      [nzStatus]="validationStates['displayName']"
                      class="formField"
                    />
                  </nz-form-control>
                  <div
                    *ngIf="validationStates['displayName'] === 'error'"
                    class="text-danger"
                  >
                    {{ validationMessages["displayName"] }}
                  </div>
                </div>
              </div>

              <ng-template #DisplayName>
                <div class="">
                  <p style="height: auto; width: 250px">
                    Display Name is shown anywhere the name of this contact is
                    displayed on lead opportunities and jobs.
                  </p>
                </div>
              </ng-template>

              <div nz-row>
                <div nzSpan="24" nz-col>
                  <label for="" class="sub-text">Street Address</label>
                  <nz-form-control>
                    <input
                      nz-input
                      [(ngModel)]="formFieldValues.streetAddress"
                      name="streetAddress"
                      (ngModelChange)="onFieldChange('streetAddress', $event)"
                      [nzStatus]="validationStates['streetAddress']"
                      class="formField"
                    />
                  </nz-form-control>
                  <div
                    *ngIf="validationStates['streetAddress'] === 'error'"
                    class="text-danger"
                  >
                    {{ validationMessages["streetAddress"] }}
                  </div>
                </div>
              </div>

              <div nz-row>
                <div nzSpan="8" nz-col>
                  <label class="sub-text" for="suburb">Suburb</label>
                  <nz-form-control>
                    <input
                      nz-input
                      [(ngModel)]="formFieldValues.suburb"
                      name="suburb"
                      (ngModelChange)="onFieldChange('suburb', $event)"
                      [nzStatus]="validationStates['suburb']"
                      class="formField"
                    />
                  </nz-form-control>
                  <div
                    *ngIf="validationStates['suburb'] === 'error'"
                    class="text-danger"
                  >
                    {{ validationMessages["suburb"] }}
                  </div>
                </div>
                <div nzSpan="8" nz-col style="padding-left: 10px">
                  <label class="sub-text" for="state">State</label>
                  <nz-form-control>
                    <input
                      nz-input
                      [(ngModel)]="formFieldValues.state"
                      name="state"
                      (ngModelChange)="onFieldChange('state', $event)"
                      [nzStatus]="validationStates['state']"
                      class="formField"
                    />
                  </nz-form-control>
                  <div
                    *ngIf="validationStates['state'] === 'error'"
                    class="text-danger"
                  >
                    {{ validationMessages["state"] }}
                  </div>
                </div>
                <div nzSpan="8" nz-col style="padding-left: 10px">
                  <label class="sub-text" for="postalCode">Postal Code</label>
                  <nz-form-control>
                    <input
                      nz-input
                      [(ngModel)]="formFieldValues.postalCode"
                      name="postalCode"
                      (ngModelChange)="onFieldChange('postalCode', $event)"
                      [nzStatus]="validationStates['postalCode']"
                      class="formField"
                    />
                  </nz-form-control>
                  <div
                    *ngIf="validationStates['postalCode'] === 'error'"
                    class="text-danger"
                  >
                    {{ validationMessages["postalCode"] }}
                  </div>
                </div>
              </div>

              <div nz-row>
                <div nzSpan="12" nz-col>
                  <label class="sub-text" for="phone">Phone</label>
                  <nz-form-control>
                    <input
                      nz-input
                      [(ngModel)]="formFieldValues.phone"
                      name="phone"
                      (ngModelChange)="onFieldChange('phone', $event)"
                      [nzStatus]="validationStates['phone']"
                      class="formField"
                    />
                  </nz-form-control>
                  <div
                    *ngIf="validationStates['phone'] === 'error'"
                    class="text-danger"
                  >
                    {{ validationMessages["phone"] }}
                  </div>
                </div>
              </div>

              <div nz-row>
                <div nzSpan="12" nz-col>
                  <div class="form-group">
                    <label class="sub-text" for="phone">Cell Phone</label>
                    <div class="wrapper">
                      <ngx-intl-tel-input
                        [cssClass]="'custom'"
                        [(ngModel)]="formFieldValues.cellPhone"
                        name="cellPhone"
                        [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true"
                        [searchCountryFlag]="true"
                        [searchCountryField]="[
                          SearchCountryField.Iso2,
                          SearchCountryField.Name
                        ]"
                        [selectFirstCountry]="true"
                        [selectedCountryISO]="formFieldValues.countryCode"
                        [phoneValidation]="true"
                        [separateDialCode]="separateDialCode"
                        [numberFormat]="PhoneNumberFormat.National"
                        #phoneInput="ngModel"
                      ></ngx-intl-tel-input>
                    </div>
                  </div>
                </div>
                <div nzSpan="12" nz-col style="padding-left: 10px">
                  <label class="sub-text" for="phone"
                    >Receive Text Messages</label
                  >
                  <i
                    class="bi bi-info-circle-fill ms-2"
                    nz-popover
                    [nzPopoverContent]="ReceiveText"
                    nzPopoverPlacement="right"
                  ></i>
                  <ng-template #ReceiveText>
                    <div class="">
                      <p style="height: auto; width: 180px">
                        To receive text messages please review your notification
                        preferences.
                      </p>
                    </div>
                  </ng-template>
                 <button class="d-flex " nz-button nzType="default" >
                    <i class="bi bi-phone text-dark fs-6 me-2"></i>
                    <span class="align-items-center">Verify Phone Number</span>
                  </button>
                </div>
              </div>

              <div nz-row nzAlign="top" *ngIf="isRowPrimaryEmailVisible">
                <div nzSpan="12" nz-col>
                  <label class="sub-text" for="primaryEmail"
                    >Primary Email  
                     <span class="text-danger p-error fw-bolder">*</span><i
                    class="bi bi-info-circle-fill ms-1"
                    nz-popover
                    [nzPopoverContent]="PrimaryEmailText"
                    nzPopoverPlacement="right"
                  ></i>
                 </label
                  >
                  <ng-template #PrimaryEmailText>
                    <div class="">
                      <p style="height: auto; width: 180px">
                        This email activates and maintains the account
                      </p>
                    </div>
                  </ng-template>
                  <nz-form-control>
                    <div class="input-container">
                      <input
                        nz-input
                        [(ngModel)]="formFieldValues.primaryEmail"
                        name="primaryEmail"
                        (ngModelChange)="onFieldChange('primaryEmail', $event)"
                        [nzStatus]="validationStates['primaryEmail']"
                        class="formField"
                      />
                      <div
                        *ngIf="validationStates['primaryEmail'] === 'error'"
                        class="text-danger"
                      >
                        {{ validationMessages["primaryEmail"] }}
                      </div>
                    </div>
                  </nz-form-control>
                </div>
                <div
                  nz-col
                  nzSpan="12"
                  style="padding-left: 10px; padding-right: 4px; flex: 1 1 0px"
                >
                  <label class="sub-text" for="label">Label</label>
                  <nz-form-control>
                    <div class="input-container">
                      <input
                        nz-input
                        [(ngModel)]="formFieldValues.label"
                        name="label"
                        (ngModelChange)="onFieldChange('label', $event)"
                        [nzStatus]="validationStates['label']"
                        class="formField"
                      />
                      <div
                        *ngIf="validationStates['label'] === 'error'"
                        class="text-danger"
                      >
                        {{ validationMessages["label"] }}
                      </div>
                    </div>
                  </nz-form-control>
                </div>
                <div
                  nz-col
                  class="align-items-center d-flex"
                  *ngIf="
                    !(
                      formFieldValues.createCustomerEmailParameterRequests
                        ?.length > 0
                    )
                  "
                >
                  <div class="trash-icon ms-2 mt-4">
                    <span
                      (click)="hideRowPrimaryEmailVisible()"
                      nz-icon
                      nzType="delete"
                      nzTheme="fill"
                      class="mt-2"
                    ></span>
                  </div>
                </div>
              </div>

              <!-- Add Button Primary Email Start -->
              <div
                class="row mb-2"
                *ngIf="!isRowPrimaryEmailVisible"
                (click)="toggleRowPrimaryEmailVisible()"
              >
                <button
                  nz-button
                  nzType="text"
                  class="button-default" 
                  class="col-3 d-flex  ms-3"
                >
                  <a
                    class="fs-5"
                    nz-icon
                    nzType="plus-circle"
                    nzTheme="fill"
                  ></a>
                  <a class="ms-2" style="bottom: 100">Add Primary Email</a>
                </button>
              </div>
              <!-- Add Button Primary Email End -->

              <ng-container *ngIf="formFieldValues.primaryEmail">
                <div
                  class="mb-2"
                  *ngFor="
                    let item of formFieldValues.createCustomerEmailParameterRequests;
                    let i = index
                  "
                  nz-row
                  nzAlign="top"
                >
                  <div nz-col nzSpan="12">
                    <label class="sub-text"
                      >Additional Email
                      <span class="text-danger fw-bolder">*</span></label
                    >
                    <nz-form-control>
                      <input
                        nz-input
                        [(ngModel)]="
                          formFieldValues.createCustomerEmailParameterRequests[
                            i
                          ].email
                        "
                        name="email{{ i }}"
                        [nzStatus]="validationStates['email' + i]"
                        (ngModelChange)="onFieldChange('email', $event, i)"
                        class="formField"
                      />
                      <div
                        *ngIf="validationStates['email' + i] === 'error'"
                        class="text-danger"
                      >
                        {{ validationMessages["email" + i] }}
                      </div>
                    </nz-form-control>
                  </div>
                  <div
                    nz-col
                    nzSpan="12"
                    style="
                      padding-left: 10px;
                      padding-right: 4px;
                      flex: 1 1 0px;
                    "
                  >
                    <label class="sub-text">Label</label>
                    <nz-form-control>
                      <input
                        nz-input
                        [(ngModel)]="
                          formFieldValues.createCustomerEmailParameterRequests[
                            i
                          ].label
                        "
                        name="label{{ i }}"
                        [nzStatus]="validationStates['label' + i]"
                        (ngModelChange)="onFieldChange('label', $event, i)"
                        class="formField"
                      />
                      <div
                        *ngIf="validationStates['label' + i] === 'error'"
                        class="text-danger"
                      >
                        {{ validationMessages["label" + i] }}
                      </div>
                    </nz-form-control>
                  </div>
                  <div nz-col class="d-flex align-items-center">
                    <div
                      class="trash-icon ms-2 mt-4"
                      (click)="removeAdditionalEmail(i)"
                    >
                      <span
                        nz-icon
                        nzType="delete"
                        nzTheme="fill"
                        class="m-0 p-0"
                      ></span>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- Add Button Additional Email Start -->
              <div
                class="row mb-2 mt-2"
                *ngIf="
                  formFieldValues?.createCustomerEmailParameterRequests
                    ?.length < 5 && formFieldValues?.primaryEmail
                "
              >
                <button
                  nz-button
                  nzType="text"
                  class="button-default" 
                  class="col-3  d-flex ms-3"
                  (click)="addAdditionalEmail()"
                >
                  <a
                    class="fs-5"
                    nz-icon
                    nzType="plus-circle"
                    nzTheme="fill"
                  ></a>
                  <a class="ms-2" style="bottom: 100">Add Additional Email</a>
                </button>
              </div>
              <!-- Add Button Additional Email End -->
            </div>
          </div>
        </nz-card>
      </ng-container>

      <nz-card class=" mb-3" [nzTitle]="SecurityCard" [nzExtra]="SecurityCardExtra"> 
        <ng-template #SecurityCard>
              <h3 nz-typography>Security & Login</h3>
        </ng-template>
        <ng-template #SecurityCardExtra>
                  <p>{{ statusMessage }}</p>
        </ng-template>

        <div nz-row>
          <div class="container-fuild">
         
                <div class="row mb-3">
                  <div class="col d-flex align-items-center">
                    <label
                      class="sub-text fw-medium fs-6 me-2"
                      >Login Access  </label
                    >
                    <nz-tag
                    nz-popover
                    [nzPopoverContent]="Content"
                    class="d-flex align-items-center mt-1"
                    [ngClass]="getStatusClass(CustomerResponse?.statusSetup?.code)"
                    style="
                        line-height: 1;
                        border-radius: 10px;
                        border: none;
                        padding: inherit;
                        height: 20px;
                        width: initial;
                        font-weight: 700;
                        cursor: pointer;
                    "
                      (mouseenter)="isHovered = true"
                      (mouseleave)="isHovered = false"
                  >
                    {{
                      getStatusDescription(CustomerResponse?.statusSetup?.code)
                    }}  <span class="ms-2" nz-icon nzType="info-circle" [nzTheme]="isHovered ? 'fill' : 'outline'"></span>
                  </nz-tag>
                  <ng-template #Content>
                     <p style="width: 220px; height: auto;">
                      <span>
                        {{ CustomerResponse?.statusSetup?.name }}
                        <ng-container *ngIf="CustomerResponse?.statusSetup?.code === StatusCode.ACTIVE.code || StatusCode.PENDING.code">
                           {{ CustomerResponse?.createdOn | date : 'MMM dd,YYYY' }}
                        </ng-container>
                      </span>
                      
                     </p>
                  </ng-template>
                  
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <nz-space>
                      <ng-container *ngIf="CustomerResponse?.statusSetup?.code === StatusCode.DISABLED.code">
                        <button
                          *nzSpaceItem
                          nz-button
                          nzType="default"
                          class="button-default" 
                          (click)="enable()"
                        >
                          Enable
                        </button>
                      </ng-container>
                    <ng-container *ngIf="CustomerResponse?.statusSetup?.code === StatusCode.ACTIVE.code">
                          <button
                            *nzSpaceItem
                            nz-button
                            nzType="default"
                          class="button-default" 
                           (click)="disabled()"
                          >
                            Disable
                          </button>
                    </ng-container>
                      <ng-container *ngIf="CustomerResponse?.statusSetup?.code === StatusCode.PENDING.code">
                        <button 
                          *nzSpaceItem
                           nz-button
                          class="button-default" 
                           nzType="default"
                          (click)="reSendInvite()"
                        >
                          Resend Invite
                        </button>
                      </ng-container>
                      <ng-container *ngIf="CustomerResponse?.statusSetup?.code === StatusCode.INACTIVE.code || CustomerResponse?.statusSetup.code === StatusCode.PENDING.code">
                        <button 
                          *nzSpaceItem
                          nz-button
                          nzType="default"
                          class="button-default" 
                          (click)="setStatusToInActive()"
                        >
                          {{ getButtonLabel() }}
                        </button>
                      </ng-container>
                      
                    
                    </nz-space>
                  </div>
                </div>
              </div>
        </div>
      </nz-card>

      <div class="conditionTable mb-3" *ngIf="customerRes">
      <ng-container *ngIf="listOfData; else showFirstCard">
        <nz-card
          class="border-0  custom-card p-0 m-0"
          [nzTitle]="LeadOpportunityCard"
        >
          <div class="activity-table-container">
            <nz-table
              #smallTable
              nzSize="small"
              [nzFrontPagination]="false"
              [nzPaginationType]="'small'"
              [nzShowPagination]="false"
              #sortTable
              [nzData]="listOfData"
              nzTableLayout="fixed"
            >
              <thead>
                <tr>
                  <th
                    *ngFor="let column of listOfColumn"
                    [nzSortFn]="column.compare"
                    [nzSortPriority]="column.priority"
                    [ngClass]="{
                      'title-column': column.title === 'Opportunity Title'
                    }"
                  >
                    {{ column.title }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of sortTable.data">
                  <td [ngClass]="{ 'title-column': true }">
                    <a>
                      {{ data.opportunityTitle }}
                    </a>
                  </td>
                  <td>{{ data.status }}</td>
                  <td>{{ data.createdDate }}</td>
                  <td>{{ data.soldDate }}</td>
                  <td>{{ data.salesperson }}</td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </nz-card>
      </ng-container>
     </div>
      <ng-template #LeadOpportunityCard>
        <h3>Lead Opportunity</h3>
      </ng-template>

      <ng-template #showFirstCard>
        <nz-card class="border-0 " [nzTitle]="LeadOpportunityCard">
          <strong>No Lead Opportunities Found</strong>
        </nz-card>
      </ng-template>



      <div class="conditionTable" *ngIf="customerRes">
        <ng-container *ngIf="listOfDataJob; else jobShowFirstCard">
          <nz-card
            class="border-0  custom-card p-0 m-0"
            [nzTitle]="jobCard"
          >
            <div class="activity-table-container">
              <nz-table
                #smallTable
                nzSize="small"
                [nzFrontPagination]="false"
                [nzPaginationType]="'small'"
                [nzShowPagination]="false"
                #sortTable
                [nzData]="listOfDataJob"
                nzTableLayout="fixed"
              >
                <thead>
                  <tr>
                    <th
                      *ngFor="let column of listOfColumnJob"
                      [nzSortFn]="column.compare"
                      [nzSortPriority]="column.priority"
                      [ngClass]="{
                        'title-column': column.title === 'Job Name'
                      }"
                    >
                      {{ column.title }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of sortTable.data">
                    <td [ngClass]="{ 'title-column': true }">
                      <a>
                        {{ data.jobName }}
                      </a>
                    </td>
                    <td>{{ data.streetAddress }}</td>
                    <td>{{ data.suburb }}</td>
                    <td>{{ data.state }}</td>
                    <td>{{ data.postalCode }}</td>
                    <td>{{ data.projectManager }}</td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </nz-card>
        </ng-container>
       </div>

       <ng-template #jobCard>
        <h3>Jobs</h3>
      </ng-template>

      <ng-template #jobShowFirstCard>
        <nz-card class="border-0 " [nzTitle]="jobCard">
          <strong>No Jobs Found</strong>
        </nz-card>
      </ng-template>





    </div>

    <ng-template #newContactmodalFooter>
      <!-- <div class="mt-2 align-items-center">
      <div class="created-by">
        <div class="avatar-initials">
          <div class="mt-1 me-1">Created by</div>
          <nz-avatar
            class="mt-1"
            [nzText]="CustomerResponse?.createdBy.toString()"
            style="
              color: rgb(139, 20, 57);
              background-color: rgb(255, 185, 168);
            "
          ></nz-avatar>
          <div class="mt-1 me-2">{{ CustomerResponse?.createdBy }}</div>
          <div class="mt-1">
            {{ CustomerResponse?.createdOn | date : "MMM d, yyyy, h:mm a" }}
          </div>
        </div>
      </div>
    </div> -->

      <div class="align-items-center">
        <button
          nz-button
          nzType="default"
          class=" me-0 button-default"
          *ngIf="customerRes"
          [nzLoading]="isDeleteLoading"
          (click)="ConfirmDelete(formFieldValues.globalId)"
          [disabled]="DisabledNextActivity"
          [class.disabled]="DisabledNextActivity"
        >
          Delete
        </button>

        <button
          nz-button
          *ngIf="saveAndNewButtonHide === true"
          nzType="primary"
          class=" me-0 button-primary"
          [nzLoading]="isSaveAndNew"
          (click)="saveCustomerAndNews()"
          [disabled]="DisabledNextActivity"
          [class.disabled]="DisabledNextActivity"
               
        >
          Save & New
        </button>
        <button
          nz-button
          nzType="primary"
          class=" me-0 button-primary"
          [nzLoading]="isSave"
          (click)="saveButton()"
          [disabled]="DisabledNextActivity"
          [class.disabled]="DisabledNextActivity"
        >
          Save
        </button>
        <button
          nz-button
          nzType="primary"
          class=" me-0 button-primary"
          [nzLoading]="isSaveAndClose"
          (click)="saveCustomerAndCloses()"
          [disabled]="DisabledNextActivity"
          [class.disabled]="DisabledNextActivity"
        >
          Save & Close
        </button>
      </div>
    </ng-template>
  </form>
</nz-modal>
