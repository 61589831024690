import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { UpdatePayload } from 'src/Models/CustomerInformation';
import { ApplicationGroupParameterResponse, ApplicationGroupResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { ApiResponseModel } from 'src/Models/apiResponse.model';
import { ResponseModel, ResponseModelArray } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class RoleTypeService {
  private baseUrl: string = environment.apiUrl + 'ApplicationGroup/';


  constructor(private http: HttpClient) {}

  postData(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl, data).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  

  getData(): Observable<ResponseModelArray<ApplicationGroupResponse>> {
    return this.http.get<ResponseModelArray<ApplicationGroupResponse>>(`${this.baseUrl}getAll`);
  }

  updateData(data: UpdatePayload): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    const options = { headers: new HttpHeaders(headers) };
    return this.http.put<any>(`${this.baseUrl}`, data, options);
  }
  

  deleteData(globalId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}?globalId=${globalId}`).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  
  getDataId(id: any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}getBy-Id?id=${id}`);
  }
  getDataGlobalId(globalId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}getBy-globalId=${globalId}`);
  }
  


}
