<div class="row famly-inn gx-3">
  <div class="col-12">
      <div class="card border-0 mt-0">
        <div class="card-head border-bottom">
            <div class="row mb-0 p-2 d-flex align-items-center">
              <div class="col">
                <span class="fw-medium fs-6 ms-2">Cost Groups</span>
              </div>
              <div class="col- text-end">
                <div nz-row class="text-end">
                  <div nz-col [nzSpan]="18" [nzPush]="6">
                      <nz-space>
                          <button nz-button nzType="default" class="rounded-1 ms-0">Expoprt</button>
                          <button nz-button nzType="default" class="rounded-1 ms-2"><i class="bi bi-funnel me-1"></i>  Filter</button>
                          <button (click)="CostGroups()" nz-button nzType="primary" class="rounded-1 ms-2">New Cost Groups</button>
                      </nz-space>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div class="card-body">
        <div class="row">
          <div class="col-12">
           
          </div>
        </div>
        </div>
      </div>
  </div>
</div>

<!-- Catalog -->
<nz-modal
    nzWidth="90%"
    [(nzVisible)]="costgroups"
    [nzTitle]="costgroups1"
    [nzFooter]="costgroups2"
    (nzOnCancel)="CoseCostGroups()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '400px', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
    <ng-template #costgroups1> <div class="row p-0 m-0">
        <h1 class="p-0 m-0 fw-medium">Cost Group Editor</h1></div>
    </ng-template>
    <div  class="cantent" *nzModalContent>
      <div class="row famly-inn gx-3">
        <div class="col-12">
            <div class="card border-0 mt-0">
              <div class="card-head border-bottom">
                  <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                      <span class="fw-medium fs-6 ms-2">New Cost Group</span>
                    </div>
                  </div>
                </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">


                <div class="row">
                  <div class="col-6">
                        <label class="sub-text">Title <span class="fw-bolder text-danger">*</span></label>
                        <input type="text" class="form-control formField">
                </div>
                </div>
                <div class="row mt-2">
                  <div class="col-6">
                        <label class="sub-text">Title</label>
                        <textarea
                        class="form-control formField"
                        style="height: 54px; resize: none;"
                        (input)="autoAdjustTextAreaHeight($event)"
                    ></textarea>
                </div>
                </div>
                <div class="row mt-3">
                  <div class="col-6">
                    <span class="fw-medium fs-6 ms-2">Attachments</span>
                </div>
                </div>
              <div class="row mt-2">
                <div class="col-6">
                  <button (click)="Add2()" nz-button nzType="default" class="rounded-1 ms-0">Add</button>
                  <button nz-button nzType="default" class="rounded-1 ms-2">Create New Doc</button>
                </div>
              </div>
              </div>
            </div>
          </div>
            </div>
            <div class="card border-0 mt-3">
              <div class="card-head border-bottom">
                  <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col-11">
                      <div  class="col d-flex align-items-center">
                        <i (click)="toggleSection()" [ngClass]="{'rotate-icon': isSectionVisible}" class="fa-solid fa-caret-right icon-container"></i>
                              <!-- <i (click)="toggleSection()"  class="bx bxs-down-arrow icon-container"></i> -->
                          <span class="fw-medium fs-6 ms-3">Cost Items (0)</span>
                        </div>
                    </div>
                    <div class="col-1 text-end">
                      <button (click)="Add()" nz-button nzType="primary" class="rounded-1 me-0">Add</button>
                    </div>
                  </div>
                </div>
              <div class="card-body">
                <div class="row"  *ngIf="isSectionVisible">
                    <div class="col-12">
                        <div class="row">
                          <div class="col-12 d-flex alighn-item-center justify-content-center">
                            <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g class="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" class="ant-empty-img-simple-path"></path></g></g></svg>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 d-flex alighn-item-center justify-content-center">
                        <span class="ant-empty-normal">No Data</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
            </div>
    
    
    </div>
    <ng-template #costgroups2>
        <button nz-button nzType="primary" class="rounded-1 me-0">Save & New</button>
        <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
    </ng-template>
</nz-modal>
<!-- Catalog -->
<nz-modal
    nzWidth="90%"
    [(nzVisible)]="add"
    [nzTitle]="add1"
    [nzFooter]="add2"
    (nzOnCancel)="CloseAdd()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '400px', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
    <ng-template #add1> <div class="row p-0 m-0">
        <h1 class="p-0 m-0 fw-medium">Cost Group Editor</h1></div>
    </ng-template>
    <div  class="cantent" *nzModalContent>
      <div class="row">
        <div class="col-12">
            <div class="shadow-none rounded border-0 mt-0">
                <div class="-body">
                    <div class="row">
                        <div class="col">
                            <ul
                                class="nav nav-tabs border-0  rounded-0"
                                style="font-size: 16px; border-radius: none;"
                            >
                                <li class="nav-item border-0  rounded-0">
                                    <a
                                        class="nav-link border-0 rounded-0"
                                        [class.active]="activeTabs === 'costItem'"
                                        (click)="ActiveTab('costItem')"
                                    >
                                    Cost Items
                                    </a>
                                </li>
                            </ul>
                            <div *ngIf="activeTabs === 'costItem'" class="ms-0">
                              <div class="row famly-inn gx-3">
                                <div class="col-12">
                                    <div class="card border-0 mt-0">
                                      <div class="card-head border-bottom">
                                          <div class="row mb-0 p-2 d-flex align-items-center">
                                            <div class="col">
                                              <span class="fw-medium fs-6 ms-2">Catalog</span>
                                            </div>
                                            <div class="col- text-end">
                                              <div nz-row class="text-end">
                                                <div nz-col [nzSpan]="18" [nzPush]="6">
                                                    <nz-space>
                                                        <button nz-button nzType="default" class="rounded-1 ms-2"><i class="bi bi-funnel me-1"></i>  Filter</button>
                                                        <button (click)="CostItem()" nz-button nzType="default" class="rounded-1 ms-2">New Cost Items</button>
                                                    </nz-space>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      <div class="card-body">
                                      <div class="row">
                                        <div class="col-12">
                                        </div>
                                      </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                            </div>
                            </div>
      
                        </div>
            </div>
        </div>
      </div>
      
      
    </div>
    </div>
    <ng-template #add2>
    </ng-template>
</nz-modal>
<!-- Catalog -->
<nz-modal
    nzWidth="45%"
    [(nzVisible)]="add3"
    [nzTitle]="add21"
    [nzFooter]="add22"
    (nzOnCancel)="CloseAdd2()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '400px', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
    <ng-template #add21> <div class="row p-0 m-0">
        <h1 class="p-0 m-0 fw-medium">Upload Filesr</h1></div>
    </ng-template>
    <div  class="cantent" *nzModalContent>
      <app-catalog-upload-files></app-catalog-upload-files>
    </div>
    <ng-template #add22>
      <button nz-button nzType="default" class="rounded-1 ms-2">Discard</button>
      <button nz-button nzType="primary" class="rounded-1 ms-2">Save</button>
    </ng-template>
</nz-modal>

<!-- Catalog -->
<nz-modal
    nzWidth="65%"
    [(nzVisible)]="costitem"
    [nzTitle]="costitem1"
    [nzFooter]="costitem2"
    (nzOnCancel)="CloseCostItem()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '400px', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
    <ng-template #costitem1> <div class="row p-0 m-0">
        <h1 class="p-0 m-0 fw-medium">Cost Group Editor</h1></div>
    </ng-template>
    <div  class="cantent" *nzModalContent>
      <div class="row famly-inn gx-3">
        <div class="col-12">
            <div class="card border-0 mt-0">
              <div class="card-head border-bottom">
                  <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                      <span class="fw-medium fs-6 ms-2">New Cost Group</span>
                    </div>
                  </div>
                </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text">Cost Code <span class="fw-bolder text-danger">*</span></label>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a person" [(ngModel)]="selectedValue">
                          <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                          <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                          <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                        </nz-select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text">Title <span class="fw-bolder text-danger">*</span></label>
                      <input type="text" class="form-control formField">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text">Description</label>
                        <textarea
                        class="form-control formField"
                        style="height: 55px; resize: none;"
                        (input)="autoAdjustTextAreaHeight($event)"
                    ></textarea>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text">Internal Notes</label>
                        <textarea
                        class="form-control formField"
                        style="height: 55px; resize: none;"
                        (input)="autoAdjustTextAreaHeight($event)"
                    ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text">Cost Code <span class="fw-bolder text-danger">*</span></label>
                        <nz-select [(ngModel)]="multipleValue" [nzSize]="size" nzMode="multiple" nzPlaceHolder="Please select">
                          <nz-option *ngFor="let option of listOfOption" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                        </nz-select>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
            </div>
            <div class="card border-0 mt-3">
              <div class="card-head border-bottom">
                  <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                      <span class="fw-medium fs-6 ms-2">Cost Information</span>
                    </div>
                  </div>
                </div>
              <div class="card-body">
                <div class="row">
              <div class="col-12">
                <div class="row mt-0 mb-0">
                  <div class=" col-12">
                      <label (change)="onCheckboxChange11()" nz-checkbox [(ngModel)]="checked"><span class="pira-label me-1">Include Quantity</span>
                        <i
                        class="bi bi-info-circle-fill text-secondary"
                        nz-popover
                        [nzPopoverContent]="JobTypes"
                        nzPopoverPlacement="top"></i>
                        <ng-template #JobTypes>
                          <div class="rounded-2">
                            <p style="height: 100px; width: 210px;font-size: 13px;">
                              Includes Quantity as a part of the Cost Item. Quantity will be synced to your Catalog and cannot be edited when the Cost Item is used in the program without breaking the link to your Catalog.
                            </p>
                          </div>
                        </ng-template>
                      </label>
                  </div>
              </div>
                <div class="row mt-3 mb-0">
                  <div class=" col-12">
                      <label (change)="onCheckboxChange12()" nz-checkbox [(ngModel)]="checked1"><span class="pira-label me-1">Include Owner Price</span>
                        <i
                        class="bi bi-info-circle-fill text-secondary"
                        nz-popover
                        [nzPopoverContent]="Price"
                        nzPopoverPlacement="top"></i>
                        <ng-template #Price>
                          <div class="rounded-2">
                            <p style="height: 100px; width: 210px;font-size: 13px;">
                              Includes markup and owner price as a part of the Cost Item. Markup and owner price will be synced to your Catalog and cannot be edited when the Cost Item is used in the program without breaking the link to your Catalog.
                            </p>
                          </div>
                        </ng-template>
                      </label>
                  </div>
              </div>
                <div class="row mt-0 mb-0">
                  <div class=" col-3">
                    <label class="sub-text">Unit Cost</label>
                    <div class="input-group mb-3">
                      <span class="input-group-text d-flex justify-content-center align-items-center shadow-none" style="width: 5px; height: 30px;">$</span>
                      <input type="text" class="radius-work p-inputtext-sm form-control  shadow-none" formControlName="contractPrice"  style="height: 30px; border-top-left-radius: 0; border-bottom-left-radius: 0;">
                  </div>
                  </div>
                  <div class=" col-3">
                    <label class="sub-text">Unit</label>
                    <input type="text" class="form-control formField">
                  </div>
                  <div class=" col-2"  *ngIf="isCheckboxChecked11">
                    <label class="sub-text">Quantity</label><nz-input-number [nzMin]="1"[nzMax]="10"[nzStep]="1"type="number" ></nz-input-number>
                  </div>
                  <div class=" col-2">
                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text">Builder Cost</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-8 text-end">
                        <label class="sub-text">$0.00</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row gx-0"   *ngIf="isCheckboxChecked12">
                  <div class=" col-4">
                    <label class="sub-text">Markup</label>
                    <input type="text" class="form-control formField">
                  </div>
                  <div class=" col-1">
                    <div class="row">
                      <div class="col-3" style="margin-top: 30px;">
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a person" style="width: 50px" [(ngModel)]="selectedValue">
                          <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                          <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                          <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                        </nz-select>
                      </div>
                    </div>
                  </div>
                  <div class=" col-2">
                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text">Owner Price</label>
                      </div>
                    </div>
                    <div class="row">
                      <div  class="col-7 text-end">
                        <label class="sub-text">$0.00</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
                </div>
              </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <ng-template #costitem2>
        <button nz-button nzType="primary" class="rounded-1 me-0">Save & New</button>
        <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
    </ng-template>
</nz-modal>