import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';

@Component({
  selector: 'workday-execptions',
  templateUrl: './workday-execptions.component.html',
  styleUrls: ['./workday-execptions.component.css']
})
export class WorkdayExecptionsComponent {
  switchValue1 = false;
  constructor(private modal: NzModalService,private filterService: FilterSideMenuBarService) {}

  openFilter(component: string, pageId: number) {
    this.filterService.toggleFilter(component, pageId);
  }
  PAGE_ID: number = 10;

  Project_Man_WorkDay_Excep: string = FiltersName.Project_Man_WorkDay_Excep;


}
