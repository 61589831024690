<nz-modal [(nzVisible)]="Taghandle" [nzTitle]="NewDaily1" [nzFooter]="NewDaily2" (nzOnCancel)="TagClose()"
[nzBodyStyle]="{
  background: '#f1f4fa',
  padding: '14px',
  maxHeight: '69vh',
  'overflow-y': 'auto',
  'overflow-x': 'hidden',
}" 
[nzStyle]="{ top: '25px'}"
[nzWidth]="'92%'"
>
  <ng-template #NewDaily1>
    <div class="row p-0 m-0">
      <nz-breadcrumb nz-page-header-breadcrumb *ngIf="JOBDATA?.JOB_NAME">
        <nz-breadcrumb-item>{{ JOBDATA?.JOB_NAME }}</nz-breadcrumb-item>
      </nz-breadcrumb>
      <nz-breadcrumb nz-page-header-breadcrumb *ngIf="leadData?.leadData?.name">
        <nz-breadcrumb-item>{{ leadData?.leadData?.name }}</nz-breadcrumb-item>
      </nz-breadcrumb>

      <h2 class="p-0 m-0">Warranty</h2>

    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <nz-alert
      class="mb-3 error-message"
      *ngIf="isSubmitted && errorFields.length > 0"
      nzType="error"
      nzShowIcon
      [nzMessage]="message"
      [nzDescription]="errorDescriptionTemplate"
    ></nz-alert>

    <ng-template #message>
      <p class="mt-1">Please correct the following fields:</p>
    </ng-template>

    <ng-template #errorDescriptionTemplate>
      <ul>
        <li class="sub-text" *ngFor="let error of errorFields">
          <span class="d-flex align-items-center">
            <p>{{ error.label }}:</p>
            <p>{{ error.message }}</p>
          </span>
        </li>
      </ul>
    </ng-template>

    <div class="row famly-inn gx-3">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-head border-bottom">
            <div class="row py-2">
              <h3 nz-typography><span class="ms-3">Information</span></h3>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <div class="row">
                  <div class="col-12">
                    <label class="sub-text"
                      >Claim #<i
                        class="bi bi-info-circle-fill text-secondary ms-1"
                        nz-popover
                        [nzPopoverContent]="Daily"
                        nzPopoverPlacement="right"
                      ></i>
                      <ng-template #Daily>
                        <div class="rounded-2">
                          <p style="width: 210px; font-size: 13px">
                            Change Order numbers are auto assigned based on your
                            previous numbers used per job.<br />
                            <span class="row mt-3">
                              <div class="col-12">Recently Added:</div>
                            </span>
                            <span class="row mt-3">
                              <div class="col-12">0001 - YOUSUF TEST F3</div>
                            </span>
                          </p>
                        </div>
                      </ng-template>
                    </label>
                  </div>
                </div>
                <div>
                  <nz-input-group
                    class="rounded-1"
                    [nzAddOnBefore]="warrantyFieldValues['autoAssignNumber']"
                  >
                    <input
                      type="text"
                      nz-input
                      [(ngModel)]="warrantyFieldValues['idNumber']"
                      (ngModelChange)="
                        onFieldChange('title', warrantyFieldValues.title)
                      "
                      name="idNumber"
                    />
                  </nz-input-group>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="sub-text"
                      >Title <span class="text-danger fw-bold">*</span></label
                    >

                    <input
                      [(ngModel)]="warrantyFieldValues['title']"
                      name="title"
                      type="text"
                      (ngModelChange)="
                        onFieldChange('title', warrantyFieldValues['title'])
                      "
                      (input)="
                        onFieldChange('title', warrantyFieldValues['title'])
                      "
                      class="formField form-control"
                      #titleInput="ngModel"
                      [ngClass]="{
                        'border-danger':
                          isFieldEmpty['title'] &&
                          !warrantyFieldValues['title']?.length
                      }"
                      required
                    />

                    <!-- Dynamic over character message -->
                    <div
                      *ngIf="warrantyFieldValues['title']?.length > 50"
                      class="text-danger mt-1"
                    >
                      Max length
                      {{ warrantyFieldValues["title"].length - 50 }} characters!
                    </div>

                    <!-- Show error when title is empty and field is checked -->
                    <div
                      *ngIf="
                        isFieldEmpty['title'] &&
                        !warrantyFieldValues['title']?.length
                      "
                      class="text-danger mt-1"
                    >
                      Title is required!
                    </div>
                  </div>
                </div>

                <div class="row gx-0">
                  <div class="col-8 mt-4">
                    <label class="sub-text">Category </label>
                    <nz-select
                      style="width: 100%"
                      [(ngModel)]="
                        warrantyFieldValues['warrantyCategorySetupId']
                      "
                      name="warrantyCategorySetupId"
                      (ngModelChange)="
                        onFieldChange('title', warrantyFieldValues.title)
                      "
                      nzShowSearch
                      [lang]="true"
                      nzAutoFocus="true"
                      nzPlaceHolder="Unassigned"
                    >
                      <nz-option
                        *ngFor="let option of categoryOption"
                        [nzLabel]="option.name"
                        [nzValue]="option.id"
                      >
                      </nz-option>
                    </nz-select>
                  </div>
                  <div class="col-3 mt-5 p-0">
                    <button
                      nz-button
                      nzType="link"
                      (click)="showTags('Category', 'PMWCG', 'PMWCG', 89)"
                    >
                      Add
                    </button>
                    <button
                      [disabled]="
                        !warrantyFieldValues['warrantyCategorySetupId'] ||
                        warrantyFieldValues['warrantyCategorySetupId'] === 0
                      "
                      class="btn btn-sm text-primary border-0 me-1"
                      (click)="
                        showTagEdit(
                          warrantyFieldValues['warrantyCategorySetupId'],
                          'Category'
                        )
                      "
                    >
                      Edit
                    </button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-7">
                    <label class="sub-text">Priority</label>
                    <nz-select
                      nzShowSearch
                      nzAllowClear
                      [(ngModel)]="warrantyFieldValues['prioritySetupId']"
                      (ngModelChange)="
                        onFieldChange('title', warrantyFieldValues.title)
                      "
                      name="prioritySetupId"
                    >
                      <nz-option
                        *ngFor="let option of priorityOption"
                        [nzLabel]="option.label"
                        [nzValue]="option.value"
                      ></nz-option>
                    </nz-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label class="sub-text">Problem Description</label>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-12">
                <textarea style="border-radius: 5px;" [(ngModel)]="warrantyFieldValues['problemDescription']"
                  name="problemDescription" rows="2" class="formField" nz-input></textarea>
              </div>
            </div> -->

            <div class="row">
              <div class="col-12">
                <textarea
                  [(ngModel)]="warrantyFieldValues['problemDescription']"
                  name="problemDescription"
                  type="text"
                  (ngModelChange)="
                    onFieldChange('title', warrantyFieldValues.title)
                  "
                  class="formField form-control"
                  #stateInput="ngModel"
                  [ngClass]="{
                    'border-danger':
                      warrantyFieldValues['problemDescription']?.length > 4000
                  }"
                  required
                >
                </textarea>

                <div
                  *ngIf="
                    warrantyFieldValues['problemDescription']?.length > 4000
                  "
                  class="text-danger mt-1"
                >
                  You have exceeded the character limit by
                  {{ warrantyFieldValues["problemDescription"].length - 4000 }}
                  characters!
                </div>

                <div
                  *ngIf="
                    warrantyFieldValues['problemDescription']?.length >= 3000 &&
                    warrantyFieldValues['problemDescription']?.length <= 4000
                  "
                  class="text-info mt-1"
                >
                  {{
                    4000 - warrantyFieldValues["problemDescription"].length
                  }}
                  characters remaining.
                </div>
              </div>
            </div>

            <!-- Raza Work -->

            <div class="row">
              <div class="col-12">
                <label class="sub-text">Internal Comments</label>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-12">
                <textarea style="border-radius: 5px;" [(ngModel)]="warrantyFieldValues['internalComments']"
                  name="internalComments" rows="2" class="formField" nz-input></textarea>
              </div>
            </div> -->

            <div class="row">
              <div class="col-12">
                <textarea
                  [(ngModel)]="warrantyFieldValues['internalComments']"
                  name="internalComments"
                  type="text"
                  (ngModelChange)="
                    onFieldChange('title', warrantyFieldValues.title)
                  "
                  class="formField form-control"
                  #stateInput="ngModel"
                  [ngClass]="{
                    'border-danger':
                      warrantyFieldValues['internalComments']?.length > 4000
                  }"
                  required
                >
                </textarea>

                <!-- Dynamic over character message -->
                <div
                  *ngIf="warrantyFieldValues['internalComments']?.length > 4000"
                  class="text-danger mt-1"
                >
                  You have exceeded the character limit by
                  {{ warrantyFieldValues["internalComments"].length - 4000 }}
                  characters!
                </div>

                <!-- Remaining characters message -->
                <div
                  *ngIf="
                    warrantyFieldValues['internalComments']?.length >= 3000 &&
                    warrantyFieldValues['internalComments']?.length <= 4000
                  "
                  class="text-info mt-1"
                >
                  {{
                    4000 - warrantyFieldValues["internalComments"].length
                  }}
                  characters remaining.
                </div>
              </div>
            </div>

            <!-- Raza Work -->
          </div>
        </div>
        <div class="card border-0 mt-3">
          <div class="card-head border-bottom">
            <div class="row py-2">
              <h3 nz-typography>
                <span class="ms-3">Assigned Information</span>
              </h3>
            </div>
          </div>
          <div class="card-body">
            <div class="row gx-5">
              <div class="col-3">
                <div class="row">
                  <div class="col-12">
                    <label class="sub-text"
                      >Service Coordinator<i
                        class="bi bi-info-circle-fill text-secondary ms-1"
                        nz-popover
                        [nzPopoverContent]="Daiyly"
                        nzPopoverPlacement="right"
                      ></i>
                      <ng-template #Daiyly>
                        <div class="rounded-2">
                          <p style="width: 210px; font-size: 13px">
                            This Service Coordinator box allows you to limit<br />
                            communications on this warranty request to one
                            employee.
                          </p>
                        </div>
                      </ng-template>
                    </label>
                    <nz-select
                      [(ngModel)]="warrantyFieldValues['coordinatorUserId']"
                      name="coordinatorUserId"
                      style="width: 100%"
                      nzShowSearch
                    >
                      <nz-option
                        *ngFor="let option of serviceCoordinator"
                        [nzLabel]="option.label"
                        [nzValue]="option.value"
                      ></nz-option>
                    </nz-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="sub-text"
                      >Classification <span class="text-danger fw-bold">*</span>
                      <i
                        class="bi bi-info-circle-fill text-secondary ms-1"
                        nz-popover
                        [nzPopoverContent]="swarranty"
                        nzPopoverPlacement="top"
                      ></i>
                      <ng-template #swarranty>
                        <div class="rounded-2">
                          <p style="width: 210px; font-size: 13px">
                            Classifications designated 'closed' will close the
                            claim regardless of its service appointment status.
                          </p>
                        </div>
                      </ng-template>
                    </label>

                    <nz-select
                      nzShowSearch
                      nzAllowClear
                      [(ngModel)]="warrantyFieldValues['classificationSetupId']"
                      (ngModelChange)="
                      onFieldChanges('classificationSetupId', $event)
                      "
                      name="classificationSetupId"
                      #classificationInput="ngModel"
                      required
                      [ngClass]="{
                        'border-danger':
                          isFieldEmpty['classificationSetupId'] &&
                          !warrantyFieldValues['classificationSetupId']
                      }"
                    >
                      <nz-option
                        *ngFor="let option of classificationOption"
                        [nzLabel]="option.name"
                        [nzValue]="option.id"
                      >
                      </nz-option>
                    </nz-select>

                    <!-- Error message for required field -->
                    <div
                      *ngIf="
                        isFieldEmpty['classificationSetupId'] &&
                        !warrantyFieldValues['classificationSetupId']
                      "
                      class="text-danger mt-1"
                    >
                      Classification is required!
                    </div>
                  </div>
                </div>

                <!-- This fileds show after user select Meets Warranty Standards(closed) option in  -->
                <ng-container *ngIf="showAdditionalFields">
                  <div class="row">
                    <div class="col-5">
                      <label class="sub-text famly-inn">Completion Date</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <nz-date-picker
                        nzPlaceHolder=" "
                        nzSuffixIcon="false"
                        (ngModelChange)="onDateChange($event)"
                        [(ngModel)]="warrantyFieldValues['completionDate']"
                        name="completionDate"
                      ></nz-date-picker>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <label class="sub-text famly-inn">at</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <nz-time-picker
                        [(ngModel)]="warrantyFieldValues['completionTime']"
                        name="completionTime"
                        nzFormat="HH:mm"
                        nzPlaceHolder=" "
                        [nzDisabled]="!warrantyFieldValues['completionDate']"
                      ></nz-time-picker>
                    </div>
                  </div>
                </ng-container>




                <div class="row">
                  <div class="col-12">
                    <label class="sub-text">Added Cost</label>
                      <div class="input-group">
                        <nz-input-group nzAddOnBefore="$">
                          <input
                          nz-input
                          name="addedCost"
                          type="numbers"
                          [(ngModel)]="warrantyFieldValues['addedCost']"
                          appDollarFormatter
                          #stateInput="ngModel"
                          class="form-control formField"
                        />
                        </nz-input-group>
                      </div>
                  </div>
              </div>


              </div>
              <div class="col-2"></div>
              <div class="col-3">
                <div class="row mt-4">
                  <div class="col-12">
                    <label
                      [(ngModel)]="warrantyFieldValues['isShowOwner']"
                      name="isShowOwner"
                      nz-checkbox
                      >Show Owner?</label
                    >
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <label class="sub-text"
                      >Orig. Item/User
                      <i
                        class="bi bi-info-circle-fill text-secondary ms-1"
                        nz-popover
                        [nzPopoverContent]="Da1iyly"
                        nzPopoverPlacement="right"
                      ></i>
                      <ng-template #Da1iyly>
                        <div class="rounded-2">
                          <p style="width: 210px; font-size: 13px">
                            Orig. Item/User does not schedule the user for this
                            claim. This dropdown represents the original user
                            who performed the work now under claim.
                          </p>
                        </div>
                      </ng-template>
                    </label>
                    <nz-select
                      [(ngModel)]="
                        warrantyFieldValues['scheduleItemParameterId']
                      "
                      name="scheduleItemParameterId"
                      style="width: 100%"
                      nzShowSearch
                      [lang]="true"
                      nzAutoFocus="true"
                      nzPlaceHolder="Unassigned"
                      [(ngModel)]="selectedValue"
                    >
                      <nz-option
                        *ngFor="let option of scheduleItemOption"
                        [nzLabel]="option.title"
                        [nzValue]="option.id"
                      ></nz-option>
                    </nz-select>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <label class="sub-text">Follow-Up Date</label>
                  </div>
                </div>
                <div class="row" *ngIf="!showAdditionalFields">
                  <div class="col-12">
                    <nz-date-picker
                      [(ngModel)]="warrantyFieldValues['followUpOn']"
                      [nzFormat]="'MMM d,YYYY'"
                      name="followUpOn"
                      [nzPlaceHolder]="' '"
                      nzSuffixIcon="false"
                    ></nz-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Saim works on warranty form || 4/16/2024 || Saim_Raza_ -->
        <nz-card nzTitle="Service Appointments" class="mt-2" [nzExtra]="extraTemplate">
          <ng-template #title>
            <h4 class="small-title with-border">
              <span class="ms-3">Service Appointments</span>
            </h4>
          </ng-template>
          <ng-template #extraTemplate>
            <i
              class="bi bi-info-circle-fill text-secondary me-2"
              nz-popover
              [nzPopoverContent]="Daiyly"
              nzPopoverPlacement="top"
              *ngIf="isFormSaved"
            ></i>
            <ng-template #Daiyly>
              <div class="rounded-2">
                <p style="width: 210px; font-size: 13px">
                  The <span class="fw-bold">Schedule Service </span> button
                  allows you to schedule a date/time for a subs/vendors to
                  perform a service at the job. <br /><br />

                  The <span class="fw-bold">Log Completed Service</span> button
                  allows you to enter information about a service that has
                  already been completed.
                </p>
              </div>
            </ng-template>

            <nz-space *ngIf="isFormSaved" class="mb-5">

              <button *nzSpaceItem nz-button nzType="default" class="rounded-1"
                (click)="OpenLogCompletedService(false)">Log Completed Service</button>

              <button *nzSpaceItem nz-button nzType="primary" class="ms-2 rounded-1"
                (click)="OpenLogCompletedService(true)">Schedule Service Appointment</button>
            </nz-space>
          </ng-template>

          <nz-card class="border" *ngIf="isFormSaved">
            <nz-table #smallTable nzSize="small" [nzData]="warrantyServiceData">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Scheduled for</th>
                  <th>Appointment Status</th>
                  <th>Feedback</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody *ngIf="warrantyServiceData">
                <tr *ngFor="let data of warrantyServiceData">
                  <td>{{ data?.assignToUser?.firstName }}</td>
                  <td>
                    {{ data?.createdOn | date : "MMM d, yyyy" }} ,
                    {{ data?.startTime | date : "h:mm a" }}
                    {{ data?.endTime | date : "h:mm a" }}
                  </td>
                  <td>
                    <i class="bi bi-house-door"></i>
                    <span class="fw-bold text-secondary"> Never Accepted</span>
                    <p class="fw-bold"></p>
                    <p>
                      <i class="bi bi-people"></i>
                      <span class="fw-bold" style="color: #52a300">
                        Internally Accepted</span
                      >
                      - {{ data?.serviceOn | date : "MMM d, yyyy" }} ,{{
                        data?.serviceOn | date : "h:mm a"
                      }}
                    </p>
                  </td>
                  <td>
                    <span
                      nz-icon
                      [nzType]="'check-circle'"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#52c41a'"
                      style="font-size: 20px"
                    ></span>
                    <p>
                      {{ data?.createdOn | date : "MMM d, yyyy" }}
                      {{ data?.completionTime | date : "h:mm a" }}
                    </p>
                  </td>
                  <td>
                    <button
                      nz-button
                      nzType="default"
                      (click)="openEditMode(responseData)"
                    >
                      <span nz-icon nzType="edit" nzTheme="fill"></span>
                      Edit
                    </button>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </nz-card>
          <p *ngIf="!isFormSaved">
            Please save this warranty claim above before scheduling a service
            appointment.
          </p>
        </nz-card>

        <nz-card nzTitle="Attachments" class="mt-2" [nzBordered]="false">
          <div class="card-body bt-file-viewer-card">
            <div class="row">
              <div class="col-12">
                <app-create-attachments-common
                  [TestAttachment__]="TestAttachment"
                  (filesUploaded)="onFilesUploaded($event)"
                ></app-create-attachments-common>
              </div>
            </div>
          </div>
        </nz-card>

        <nz-card nzTitle="Messaging"  class="mt-2 ">
          <div class="row">
            <div class="col-11">
              <h5 nz-typography>Messaging available after save</h5>
              <messaging-common
                *ngIf="newResponseHeaderId"
                [Job_ID]="jobID"
                [Messaging_ID]="messagingIdRow"
                [header_ID]="newResponseHeaderId"
                [Form_Name_ID]="formNameId"
              ></messaging-common>
            </div>
          </div>
        </nz-card>



        <div class="card border-0 mt-3">
          <nz-card nzTitle="" *ngIf="isFormSaved">
            <div class="row">
              <div class="col-11">
                <h5 nz-typography>rFI'S</h5>
              </div>
              <div class="col-1">
                <button
                  nz-button
                  nzType="default"
                  (click)="openrFISForm(HeaderId, 10002)"
                >
                  Create RFI
                </button>
              </div>
            </div>
          </nz-card>
        </div>
      </div>
    </div>
  </div>

  <ng-template #NewDaily2>
    <div class="row">
      <div
        class="col text-start justify-content-start align-items-start"
        *ngIf="warrantyServiceData"
      >
        <span *ngIf="footerResponse">
          <app-footer-label [footerData]="footerResponse"></app-footer-label>
        </span>
      </div>
    </div>

    <div class="col text-end">
      <nz-space>


          <ng-container *ngIf="WarrantyInformations !== undefined">
            <button
            nz-button
            nzType="default"
            class="me-2"
            (click)="deleteWarantyConfirm()"
          >
            Delete
          </button>
          </ng-container>
          <button
            nz-button
            nzType="primary"
            class="me-2"
            [nzLoading]="isSaveAndNew"
            (click)="saveAndNewwarranty()"
          >
            Save & New
          </button>
          <button
            nz-button
            nzType="primary"
            class="me-2"
            [nzLoading]="isSave"
            (click)="saveWarrantyForm()"
          >
            Save
          </button>
          <button
            nz-button
            nzType="primary"
            class="me-2"
            [nzLoading]="isSaveAndClose"
            (click)="saveAndClosewwarranty()"
          >
            Save & Close
          </button>
      </nz-space>
    </div>
  </ng-template>





</nz-modal>

<nz-modal
  nzWidth="35%"
  [(nzVisible)]="isTagVisible"
  [nzTitle]="divsionTradeTitle"
  [nzFooter]="divsionTradeFooter"
  [nzBodyStyle]="{ background: '#f1f4fa', padding: '14px' }"
  [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="showConfirmData()"
>
  <ng-template #divsionTradeTitle>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0">Add Warranty Category</h1>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <form [formGroup]="TagsForm">
      <nz-card [nzBorderless]="false" nzTitle="Add Warranty Category">
        <div class="row">
          <div class="col">
            <label class="sub-text"
              >Title<span class="text-danger fw-bolder">*</span></label
            >
            <input
              id="inputField"
              formControlName="name"
              type="text"
              class="form-control formField"
              [ngClass]="{
                error:
                  TagsForm.get('name')?.invalid && TagsForm.get('name')?.touched
              }"
            />
            <div
              *ngIf="
                TagsForm.get('name').hasError('required') &&
                TagsForm.get('name').touched
              "
              class="text-danger p-error"
            >
              Required
            </div>
          </div>
        </div>
      </nz-card>
    </form>
  </div>
  <ng-template #divsionTradeFooter>
    <div class="mt-2 align-items-center">
      <button
        *ngIf="isLoadingEdit"
        nz-button
        nzType="default"
        class="rounded-1 me-0"
        (click)="ConfirmDeleteTags()"
      >
        Delete
      </button>

      <button
        nz-button
        nzType="primary"
        class="rounded-1 me-0"
        (click)="SaveOrUpdateData()"
      >
        {{ isLoadingEdit ? "Update" : "Save" }}
      </button>
    </div>
  </ng-template>
</nz-modal>
