import { Component } from '@angular/core';

@Component({
  selector: 'app-manage-cost-codes',
  templateUrl: './manage-cost-codes.component.html',
  styleUrls: ['./manage-cost-codes.component.css']
})
export class ManageCostCodesComponent {
  manage: boolean = false;


  Manage(){
    this.manage = true; 
  }
}
