import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SubsVendorsDashboardComponent } from './Components/subs-vendors-dashboard/subs-vendors-dashboard.component';

const routes: Routes = [

  {path: '', component: SubsVendorsDashboardComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubsVendorsInformationRoutingModule { }



