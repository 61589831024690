import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectionBuilderView } from 'src/Models/CompanySettings/Selections/SelectionBuilderView';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SelectionBuilderViewService {

  private baseUrl: string = environment.apiUrl + 'SelectionBuilderView/';


  constructor(private http: HttpClient) {}

  postData(data: SelectionBuilderView): Observable<SelectionBuilderView> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }

  getData(): Observable<SelectionBuilderView[]> {
    return this.http.get<SelectionBuilderView[]>(`${this.baseUrl}getAll`);
  }

  updateData(data: SelectionBuilderView): Observable<SelectionBuilderView> {
    return this.http.put<SelectionBuilderView>(`${this.baseUrl}`, data);
  }

  deleteData(id: number): Observable<SelectionBuilderView> {
    return this.http.delete<SelectionBuilderView>(
      `${this.baseUrl}?globalId=${id}`
    );
  }
  getDataId(id: number): Observable<SelectionBuilderView> {
    return this.http.get<SelectionBuilderView>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }
}