import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';

@Component({
  selector: 'app-copy-to-activity-template',
  templateUrl: './copy-to-activity-template.component.html',
  styleUrls: ['./copy-to-activity-template.component.css']
})
export class CopyToActivityTemplateComponent {
  @Output() cancel = new EventEmitter<void>();
  isActivityTemplateCopyVisible = true;
  formFieldValues: any = {};
  checked = true;
  isSaving: boolean = false;


  constructor(private LeadOpportunitiesService: LeadOpportunitiesService,
    public toastService: NzMessageService,
    @Inject(NZ_MODAL_DATA) public isLeadTemplate: any,
  ) {

  }

  ngOnInit(): void {
    this.formFieldValues = {
      leadOpportunityId: this.isLeadTemplate.isLeadTemplate.id,
      name: '',
      applicationUserId: 1,
      isCopyActivityAddresses: false,
    };

  }

  
  SaveCopy() {
    if (this.isSaving) return; // Prevent duplicate submissions
    this.isSaving = true; // Set the flag to indicate that the save operation is in progress

    let data = this.formFieldValues;
    this.LeadOpportunitiesService.PostImportCopyTemplate(data).subscribe(
      (response) => {
        console.log('Data saved successfully:', response);
        data.id = response.result.id;
        this.isActivityTemplateCopyVisible = false;
        this.toastService.success('New Copy Saved Successfully!');
        this.cancel.emit();
      },
      (error) => {
        console.error('Error saving lead:', error);
      },
      () => {
        this.isSaving = false; // Reset the flag when the operation is complete
      }
    );
  }


  openCopycActivityTemplateModal(): void {
    this.isActivityTemplateCopyVisible = true;
  }

  ActivityTemCancel(): void {
    this.cancel.emit();
  }

}
