// status.enum.ts
export enum Status {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN PROGRESS',
  ON_HOLD = 'ON HOLD',
  PENDING = 'PENDING',
  LOST = 'LOST',
  SOLD = 'SOLD',
  NO_OPPORTUNITY = 'NO OPPORTUNITY'
}
