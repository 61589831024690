import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/Models/Job-List/Job-Information/ApplicationUser';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ApplicationUserService {

  private baseUrl: string = environment.apiUrl + 'ApplicationUser/';


  constructor(private http: HttpClient) {}

  postData(data: User): Observable<User> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }

  getData(): Observable<User[]> {
    return this.http.get<User[]>(`${this.baseUrl}getAll`);
  }

  updateData(data: User): Observable<User> {
    return this.http.put<User>(`${this.baseUrl}`, data);
  }

  deleteData(id: number): Observable<User> {
    return this.http.delete<User>(
      `${this.baseUrl}?globalId=${id}`
    );
  }
  getDataId(id: number): Observable<User> {
    return this.http.get<User>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }
}