import { Component } from '@angular/core';

@Component({
  selector: 'app-estimate-dashboard',
  templateUrl: './estimate-dashboard.component.html',
  styleUrls: ['./estimate-dashboard.component.css']
})
export class EstimateDashboardComponent {
  isCollapsed = false;
  selectedJobId: number = 0;


  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
      // Selected Job Id From Left Side Job List
getSelectedJobId(item: number){
  this.selectedJobId = item;
  console.log("selectedJobId : ", this.selectedJobId);
}
}
