<div class="header">
  <div nz-row>
    <div nz-col nzSpan="2">
      <span style="color: #fff;" nz-icon nzType="double-left" nzTheme="outline"></span>
    </div>
    <div nz-col nzSpan="20">
      <div class="select-container">
        <select style="color: #000" (change)="onViewChange($event)">
          <option selected="selected" value="dayGridMonth">Month</option>
          <option value="timeGridWeek">Week</option>
          <option value="timeGridDay">Day</option>
        </select>
      </div>
    </div>
    <div nz-col nzSpan="2">
      <span style="color: #fff; margin-top: 8px;" class="d-flex justify-content-end align-items-end" nz-icon nzType="double-right" nzTheme="outline"></span>
    </div>
  </div>
</div>
<div id="calendar">
  <full-calendar #calendar [options]="calendarOptions"></full-calendar>
</div>
<ng-template #eventTemplate let-event="event" let-hideDetails="hideDetails">
  <div #popoverComponent nz-popover [nzPopoverContent]="eventTemplate1" nzPopoverTrigger="click" class="calWeekActivityItem" [style.backgroundColor]="event?.backgroundColor">
    <!-- Your content here -->
  <!-- <div  #popover  nz-popover [nzPopoverContent]="eventTemplate1" nzPopoverTrigger="click" class="calWeekActivityItem" [style.backgroundColor]="event?.backgroundColor"> -->
    <div *ngIf="!hideDetails">
      <div class="calWeekActivityItemHeader text-start">
        {{ event?.start | date: 'h:mm a' }} {{ event?.title }}
      </div>
      <div class="calOpportunityTitle">
        <div class="titleDisplayMonth text-start">
          <div style="width: 170px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ event?.extendedProps?.leadOpportunityTitle }}
          </div>
        </div>
      </div>
      <div class="assignedTo text-start">Umar Farooq</div>
    </div>
    <div *ngIf="hideDetails">
      <div class="calWeekActivityItemHeader text-start">
        {{ event?.title }}
      </div>
    </div>
  </div>
  <ng-template  #eventTemplate1>
    <div class="container" style="width: 400px">
      <div class="row">
        <div class="col-1 p-0 m-0">
          <div [style.backgroundColor]="event?.backgroundColor" style="width:40px; height:100%;">
            <div style="font-size: 16px;">
              <i [ngClass]="event?.extendedProps?.Icon" style="color: white;margin: 50px  0px 0px 8px"></i>
            </div>
          </div>
        </div>
        <div class="col-11 p-0 m-0">
          <div class="content">
            <div class="row">
              <div class="col-12 d-flex justify-content-end align-items-end">
                <span  (click)="leadOpportunityFormEdit(event)" style="font-size: 18px;" nz-icon nzType="edit" nzTheme="outline"></span>
                <!-- <span style="font-size: 18px;" class="ms-2" nz-icon nzType="close" nzTheme="outline"></span> -->
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <span class="calendarHeader-Popover-Text">{{ event?.title }} - {{ event?.extendedProps?.datestartTime }} {{ event?.extendedProps?.dateEndTime }}</span> <br>
                <span class="contactName">{{ event?.extendedProps?.title }}</span> <br>
                <span class="opportunityTitle">{{ event?.extendedProps?.title }}</span> <br>
                <span class="assignedToTooltip">{{ event?.extendedProps?.name }}</span> <br>
              </div>
            </div>
            <div class="row markComplete">
              <i class="fa fa-check" style="color:green;"><span class="complete">Complete</span></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>