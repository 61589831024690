import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProjectManagementSiteDiariesCommentComponent } from '../project-management-site-diaries-comment/project-management-site-diaries-comment.component';
import { ProjectManagementSiteDiariesNewDailyLogComponent } from '../project-management-site-diaries-new-daily-log/project-management-site-diaries-new-daily-log.component';
import { ProjectManagementSiteDiariesSettingComponent } from '../project-management-site-diaries-setting/project-management-site-diaries-setting.component';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { ProjectManagementSelectDialogBoxComponent } from '../../project-management-select-dialog-box/project-management-select-dialog-box.component';
import { JobInfoComponentInformation } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { DailyLogInformationResponse } from 'src/Models/Project-Management/projectManagement';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { FilterSearchParameter, LookupFilterResponse } from 'src/app/shared/component/Models/LookupModels';
import { SiteDiariesService } from 'src/Service/Project-Management/site-diaries.service';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { Subscription } from 'rxjs';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { StandardColumnSetupResponse, StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';

@Component({
  selector: 'app-project-management-site-diaries',
  templateUrl: './project-management-site-diaries.component.html',
  styleUrls: ['./project-management-site-diaries.component.css']
})
export class ProjectManagementSiteDiariesComponent implements OnInit, OnDestroy {
  @Output() cancel = new EventEmitter<void>();

  private subscriptions: Subscription[] = [];
  private SiteDiariessubscription: Subscription;
  private SiteDiariesFilterSubscription: Subscription;
  private SitediariesstandardViewFilterSubscription: Subscription;
  private displayColumnSubscription: Subscription;
  getInternalUserData: DailyLogInformationResponse[] = [];
  selectionResponse: DailyLogInformationResponse[] = [];
  filterSearchParameter: FilterSearchParameter;
  lookupFilterResponse: LookupFilterResponse[] = [];
  defaultFilterIdSelected: number = 0;
  selectionFrom: string = 'DB';
  isLoading: boolean = false;
  totalRecords = 100;
  currentPage = 1;
  pageSize = 10;
  fixedSelectedColumnNames: string[] = [];
  selected_View_Columns: string[] = [];
  getStandardViewFilterData: StandardViewResponse[] = [];
  selectedValueControl: any;
  getStandardViewColumnFilterData: StandardColumnSetupResponse[] = [];
  jobInfoOptions: JobInfoComponentInformation[] = [];
  displayedColumns: string[] = [];
  selectedFilter: number = -1;
  isFilterApplied: boolean = false;
  PAGE_ID: number = -1;
  PROJECT_MANG_SITE_DIAR_DAILY_L: string = FiltersName.PROJECT_MANG_SITE_DIAR_DAILY_L;
  selectiontabsdashbord = [1, 2, 3, 4];
  demoValue = 0;
  isCollapsed = false;
  selectedJobId: number = 0;
  isColumnSticky: boolean[] = new Array(this.displayedColumns.length).fill(false);

  constructor(private modal: NzModalService, private filterService: FilterSideMenuBarService, private leadOppService: LeadOpportunitiesService,
    public localStorage: AccessLocalStorageService,
    private LookupFilterService: LookupFilterService,
    private SiteDiariesService: SiteDiariesService,
    private standardViewService: StandardViewForAllService,
    private changeDetectorRef: ChangeDetectorRef,
    private internalUserService: InternalUserService,

  ) {
    if (this.SiteDiariesFilterSubscription) {
      this.SiteDiariesFilterSubscription.unsubscribe();
    }
    if (this.SiteDiariessubscription) {
      this.SiteDiariessubscription.unsubscribe();
    }
    if (this.SitediariesstandardViewFilterSubscription) {
      this.SitediariesstandardViewFilterSubscription.unsubscribe();
    }
    console.clear();
  }

  ngOnInit(): void {
    this.internalUserService.setSelectedFilterId(0);
    if (this.SiteDiariesFilterSubscription) {
      this.SiteDiariesFilterSubscription.unsubscribe();
    }
    if (this.SiteDiariessubscription) {
      this.SiteDiariessubscription.unsubscribe();
    }
    if (this.SitediariesstandardViewFilterSubscription) {
      this.SitediariesstandardViewFilterSubscription.unsubscribe();
    }

    this.PAGE_ID = 5

    this.displayedColumns = [];
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    // Add subscriptions to the array
    this.subscriptions.push(
      this.standardViewService.$displaySelectedColApply.subscribe(colName => {
        if (colName.length > 0) {
          this.displayedColumns = colName;
          this.changeDetectorRef.detectChanges();
        }
      })
    );

    this.subscriptions.push(
      this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
        this.selectedFilter = standardType != -1 ? standardType : standardType;
      })
    );
    // this.displayedColumns = [];
    // this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
    //   if (colName.length > 0) {
    //     this.displayedColumns = colName;
    //     this.changeDetectorRef.detectChanges();
    //   }
    // });

    // this.displayColumnSubscription = this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
    //   if (standardType != -1) {
    //     this.selectedFilter = standardType;
    //   } else {
    //     this.selectedFilter = standardType;
    //   }

    // });

    this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom); // New Changes By Aamir Ali - 22-Apr2024
    this.getStandardViewFilterByFormPageId(this.PAGE_ID);

    // this.CostCodeGetData();


    this.SiteDiariesService.$isUserAppliedFilter.subscribe(filter => {
      if (filter) {
        this.isFilterApplied = filter;
      } else {
        this.isFilterApplied = filter;
      }
    });

    this.selectedJobId = this.localStorage.getJobId();
    this.localStorage.getJobId();
    this.localStorage.getJobName();
    // this.getLeadData();

  }
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }





  getStandardViewFilterByFormPageId(pageId: number) {
    if (this.SitediariesstandardViewFilterSubscription) {
      this.SitediariesstandardViewFilterSubscription.unsubscribe();
    }
    this.displayedColumns = [];
    this.fixedSelectedColumnNames = [];
    this.standardViewService.getStandardViewFilterByFormPageId(pageId);
    this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
    // this.standardViewService.$getStandardViewFilter.subscribe(result => {
    this.SitediariesstandardViewFilterSubscription = this.standardViewService.$getStandardViewFilter.subscribe(result => {
      if (result) {
        this.displayedColumns = [];
        this.selected_View_Columns = [];
        this.getStandardViewFilterData = result.result;
        let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true)?.standardViewParameters;
        this.selectedValueControl = this.getStandardViewFilterData?.find(view => view?.isSetAsDefault === true)?.id;
        defaultView.forEach(viewItem => {
          if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
            this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
          }
        });
        this.displayedColumns = this.selected_View_Columns;
      }
    });

    this.standardViewService.$getStandardViewColumn.subscribe(result => {
      if (result) {
        this.getStandardViewColumnFilterData = result.result;
        let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);
        fixedCol.forEach(x => {
          this.fixedSelectedColumnNames.push(x.displayColumnName);
        });

      }
    });
  }

  selectionTabTitle(tab: number): string {
    switch (tab) {
      case 1:
        return 'Card View';
      case 2:
        return 'List';
      case 3:
        return 'Hierarchy';
      case 4:
        return 'Allowances';

      default:
        return '';
    }
  }

  panels = [
    {
      active: true,
      disabled: false,
      name: 'All Choices (1)',
      childPannel: [
        {
          active: false,
          disabled: true,
          name: 'This is panel header 1-1'
        }
      ]
    }
  ];






  newDailyLogModelOpen(select: string): void {
    const jobId = this.localStorage.getJobId();
    if (jobId) {
      const modalRef = this.modal.create({
        nzBodyStyle: {
          'padding': '250px',
        },
        nzContent: ProjectManagementSiteDiariesNewDailyLogComponent,
        nzFooter: null,
        nzTitle: null,
      });
      modalRef.componentInstance.selectedJobId = this.localStorage.getJobId();
      modalRef.componentInstance.selectedJobName = this.localStorage.getJobName();

      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
    } else {
      // If job ID does not exist, open ProjectManagementSelectDialogBoxComponent
      const modalRef = this.modal.create({
        nzWidth: '32%',
        nzStyle: {
          top: '36%',
        },
        nzContent: ProjectManagementSelectDialogBoxComponent,
        nzData: {
          type: select,
          leads: this.jobInfoOptions
        },
        nzFooter: null
      });
      modalRef.componentInstance.argument = select
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
    }
  }

  // getLeadData() {
  //   this.jobInfoService.getAllLeadData().subscribe(Data => {
  //     this.jobInfoOptions = Data.result;
  //   });
  // }

  DailyLogSetting(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesSettingComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  }
  CommentModelOpen(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesCommentComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  }


  openFilter(component: string, pageId: number) {
    this.filterService.toggleFilter(component, pageId);
  }

  // selectionChoiceModel

  // Selected Job Id From Left Side Job List


  getSelectedJobId(item: number) {
    this.selectedJobId = item;
    this.selectionResponse = null;
    this.getInternalUserData = null;
    this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom);

    // Assuming selectionResponse is an array of objects with a jobInformationId property
    this.selectionResponse = this.selectionResponse.filter(entry => entry.jobInformationId === this.selectedJobId);

    console.log("selectedJobId : ", this.selectedJobId);
  }



  getStandardFilterByFormPageId(pageId: number, type: string) {// New Changes By Aamir Ali - 22-Apr2024
    if (this.SiteDiariesFilterSubscription) {
      this.SiteDiariesFilterSubscription.unsubscribe();
    }
    if (this.SiteDiariessubscription) {
      this.SiteDiariessubscription.unsubscribe();
    }
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.SiteDiariesFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.lookupFilterResponse = result.result;
        let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
        this.SiteDiariesService.setSelectedFilterId(defaultFilterId);
        let id = -1;
        if (type === this.selectionFrom) {
          this.defaultFilterIdSelected = defaultFilterId;
          this.localStorage.setSelectedFilterId(defaultFilterId);

          // New Changes By Aamir Ali - 22-Apr2024
          id = this.localStorage.getSelectedFilterId();
        } else {
          id = this.localStorage.getSelectedFilterId();
        }

        if (id !== -1) {
          this.SiteDiariesGridTableData(1, this.PAGE_ID, id, this.currentPage, this.pageSize);
        }

      }
    });
  }
  onPageSizeChange(size: number): void {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
    this.pageSize = size;
    let filterId: number = 0;
    this.SiteDiariesService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.SiteDiariesGridTableData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize);
      this.isLoading = false;

    }, 100);
  }
  onPageChange(page: number): void {
    this.currentPage = page;
    let filterId: number = 0;
    this.SiteDiariesService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.SiteDiariesGridTableData(1, this.PAGE_ID, filterId, page, this.pageSize);
      this.isLoading = false;

    }, 100);
  }

  SiteDiariesGridTableData(companyId: number, formNameId: number, lookUpFilterId: number, page: number, pageSize: number) {
    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId: lookUpFilterId,
      page: page,
      pageSize: pageSize,
      jobInformationId: this.selectedJobId
    };

    this.SiteDiariesService.getAllSiteDiariesByFilter(this.filterSearchParameter);
    this.SiteDiariessubscription = this.SiteDiariesService.$getSitedata.subscribe(result => {
      if (result) {
        this.totalRecords = result?.result?.rowCount;
        this.getInternalUserData = result?.result?.rows;
        this.selectionResponse = this.getInternalUserData;
      }
    });
  }

  viewAll(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  // removeFile(file: File): void {
  //   const index = this.selectedFiles.indexOf(file);
  //   if (index !== -1) {
  //     this.selectedFiles.splice(index, 1);
  //   }
  // }



  // Add or update functions for checking file types
  isPdfFile(extension: string): boolean {
    return extension.toLowerCase() === '.pdf';
  }

  isExcelFile(extension: string): boolean {
    return extension.toLowerCase() === '.xls' || extension.toLowerCase() === '.xlsx';
  }

  isWordFile(extension: string): boolean {
    return extension.toLowerCase() === '.doc' || extension.toLowerCase() === '.docx';
  }

  isImageFile(extension: string): boolean {
    return extension.toLowerCase() === '.png' || extension.toLowerCase() === '.jpg' || extension.toLowerCase() === '.jpeg' || extension.toLowerCase() === '.gif';
  }
  isTxtFile(extension: string): boolean {
    return extension.toLowerCase() === '.txt';
  }

  getIconType(extension: string): string {
    if (this.isPdfFile(extension)) {
      return 'fa-file-pdf';
    } else if (this.isExcelFile(extension)) {
      return 'fa-file-excel';
    } else if (this.isWordFile(extension)) {
      return 'fa-file-word';
    } else if (this.isImageFile(extension)) {
      return 'fa-file-image';
    } else if (this.isTxtFile(extension)) {
      return 'fa-file-alt'; // Assuming this icon for text files
    } else {
      return 'fa-file';
    }
  }
  ngOnDestroy() {
    this.LookupFilterService.getStandardFilter.next(null); // New Changes By Aamir Ali - 31-May-2024
    localStorage.removeItem('Selected_Filter_Id'); // New Changes By Aamir Ali - 31-May-2024
    if (this.SiteDiariesFilterSubscription) {
      this.SiteDiariesFilterSubscription.unsubscribe();
    }
    if (this.SiteDiariessubscription) {
      this.SiteDiariessubscription.unsubscribe();
    }
    if (this.SitediariesstandardViewFilterSubscription) {
      this.SitediariesstandardViewFilterSubscription.unsubscribe();
    }
    console.clear();
  }
  EditSiteD(data: DailyLogInformationResponse): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesNewDailyLogComponent,
      nzFooter: null,
    });
    // modalRef.componentInstance.dailyLogResponse = data;
    // modalRef.componentInstance.cancel.subscribe(() => {
    //   modalRef.destroy();
    // });
  }
}


