import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, of, throwError } from 'rxjs';
import { ApiResponseModel } from 'src/Models/apiResponse.model';
import { UpdatePayload, UpdateStandardViewRequest } from 'src/Models/CustomerInformation';
import { environment } from 'src/environments/environment.prod';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { CreateStandardViewRequest, StandardColumnSetupResponse, StandardView, StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';

@Injectable({
  providedIn: 'root'
})
export class StandardViewForAllService {
  private baseUrl: string = environment.apiUrl;
  getStandardViewFilter = new BehaviorSubject<ResponseModelArray<StandardViewResponse>>(null);
  $getStandardViewFilter = this.getStandardViewFilter.asObservable();

  getStandardViewColumn = new BehaviorSubject<ResponseModelArray<StandardColumnSetupResponse>>(null);
  $getStandardViewColumn = this.getStandardViewColumn.asObservable();


  private displaySelectedColApply = new BehaviorSubject<string[]>([]);
  public $displaySelectedColApply = this.displaySelectedColApply.asObservable();

  private selectedStandardFilterType = new BehaviorSubject<number>(-1);
  public $selectedStandardFilterType = this.selectedStandardFilterType.asObservable();

  private createStandardViewRequest = new BehaviorSubject<CreateStandardViewRequest>(null);
  public $createStandardViewRequest = this.createStandardViewRequest.asObservable();





  constructor(private http: HttpClient) {}

  getStandardViewFilterByFormPageId(formNameId: number){
    const apiUrl = `${this.baseUrl}StandardView/getByDefaultStandardViewByForm?FormNameId=${formNameId}`;
    return this.http.post<ResponseModelArray<StandardViewResponse>>(apiUrl, formNameId).subscribe(
      (data) => {
        this.getStandardViewFilter.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }

  getStandardViewFilterColumnsByFormPageId(formNameId: number){
    const apiUrl = `${this.baseUrl}StandardColumnSetup/getAll-StandardColumnByFormNameId?FormNameId=${formNameId}`;
    return this.http.post<ResponseModelArray<StandardColumnSetupResponse>>(apiUrl, formNameId).subscribe(
      (data) => {
        this.getStandardViewColumn.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }

  postData(data: CreateStandardViewRequest): Observable<ResponseModelArray<StandardViewResponse>> {
    return this.http.post<any>(`${this.baseUrl}StandardView`, data).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  getData(): Observable<ApiResponseModel[]> {
    return this.http.get<ApiResponseModel[]>(`${this.baseUrl}StandardView/getAll`);
  }

  updateData(data: UpdatePayload): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    const options = { headers: new HttpHeaders(headers) };
    return this.http.put<any>(`${this.baseUrl}StandardView/`, data, options);
  }

  updateDataView(data: UpdateStandardViewRequest): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    const options = { headers: new HttpHeaders(headers) };
    return this.http.put<any>(`${this.baseUrl}StandardView/IsSetAsDefault`, data, options);
  }



  deleteData(globalId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}StandardView?globalId=${globalId}`).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  getDataId(id: any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}StandardView/getBy-Id?id=${id}`);
  }
  getDataGlobalId(globalId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}StandardView/getBy-globalId=${globalId}`);
  }


  getDataForStandardView(selectedView: any): Observable<ApiResponseModel> {
    // Customize the URL or parameters based on your API
    return this.http.get<ApiResponseModel>(`${this.baseUrl}StandardView/getDataForStandardView?viewId=${selectedView.id}`);
  }

  sendDisplaySelectedColApply(data: string[], selectedFilter: number, formData: CreateStandardViewRequest) {
    console.log('sendDisplaySelectedColApply called');
    console.log('Data:', data);
    console.log('Selected Filter:', selectedFilter);
    console.log('Form Data:', formData);

    this.displaySelectedColApply.next(data);
    this.selectedStandardFilterType.next(selectedFilter);
    this.createStandardViewRequest.next(formData);
  }


  // sendDisplaySelectedColApply(data: string[], selectedFilter: number, formData: CreateStandardViewRequest): Observable<void> {
  //   console.log('sendDisplaySelectedColApply called');
  //   console.log('Data:', data);
  //   console.log('Selected Filter:', selectedFilter);
  //   console.log('Form Data:', formData);

  //   this.displaySelectedColApply.next(data);
  //   this.selectedStandardFilterType.next(selectedFilter);
  //   this.createStandardViewRequest.next(formData);

  //   // Return an observable to enable subscription
  //   return of();
  // }





  private dataSource = new BehaviorSubject<any>(null);
  currentData = this.dataSource.asObservable();

  updateDatforsend(data: any) {
    this.dataSource.next(data);
  }



}
