<div class="card-container mt-2" >
    <nz-tabset nzType="card" >
      <nz-tab  *ngFor="let tab of viewtabs" [nzTitle]="ViewingTabs(tab)">
         <ng-container *ngIf="tab === 1">
        <div class="row">
          <div class="col-12">
            <textarea rows="2"  nz-input [(ngModel)]="inputValue" class="formField"></textarea>
          </div>
        </div>
        </ng-container>
         <ng-container *ngIf="tab === 2">
          <div class="row">
            <div class="col-12">
               <textarea rows="2"  nz-input [(ngModel)]="inputValue" class="formField"></textarea>
            </div>
          </div>
        </ng-container>
         <ng-container *ngIf="tab === 3">
          <div class="row">
            <div class="col-12">
              <textarea rows="2"  nz-input [(ngModel)]="inputValue" class="formField"></textarea>
            </div>
          </div>
        </ng-container>
         <ng-container *ngIf="tab === 4">
        <div class="row">
          <div class="col-12">
            <textarea rows="2"  nz-input [(ngModel)]="inputValue" class="formField"></textarea>
          </div>
        </div>
        </ng-container>
        
      </nz-tab>
    </nz-tabset>
   </div>