<nz-modal nzWidth="40%" [(nzVisible)]="IsCopyProposalsTemplate" [nzTitle]="modalTitle" [nzFooter]="LeadActivityFooter"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '10px', 'max-height': '400px', 'overflow-y': 'auto' }"
    [nzStyle]="{ top: '15px' }" (nzOnCancel)="handleActivityTemCancel()">

    <ng-template #modalTitle>
        <div class="row p-0 m-0">
            <h2>Copy to Proposal Template</h2>
        </div>
    </ng-template>

    <div *nzModalContent>
       
        <div class="card border-0 mt-0 p-2">
            <div class="card-body ">
                <div class="row mb-1">
                    <div class="col-12 p-0 m-0">
                        <label class="sub-text">Proposal Template
                            <span class="fw-bolder text-danger ms-1">*</span>
                        </label>
                        <nz-form-item>
                            <nz-form-control
                              [nzErrorTip]="'Name is required'"
                              [nzValidateStatus]="name.invalid && (name.touched || name.dirty) ? 'error' : null">
                              <input
                                nz-input
                                [(ngModel)]="formFieldValues.name"
                                name="name"
                                #name="ngModel"
                                class="formField"
                                required
                              />
                            </nz-form-control>
                          </nz-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label nz-checkbox [(ngModel)]="isCopyActivityAddresses"
                        name="isCopyActivityAddresses">Copy Activity Addresses</label>
                    </div>
                </div>
            </div>
         
        </div>
    </div>

    <ng-template #LeadActivityFooter>
        <div class="mt-2 align-items-center">
            <button nz-button nzType="default" class="rounded-2" (click)="handleActivityTemCancel()">Cancel</button>
            <button nz-button nzType="primary" class="rounded-2" (click)="save()" [disabled]="!formFieldValues.name">Copy</button>
        </div>
    </ng-template>

   
    
</nz-modal>
