<nz-page-header>
    <nz-page-header-title>
      <h1 style="font-size: 30px;">Reports</h1>
    </nz-page-header-title>
  </nz-page-header>
  <div class="container-fuild mb-4 m-3">
    <div class="row">
      <h1 class="mt-5" style="color: #626262;">Project Management </h1>
  </div>
  <div class="card-container">
    <div class="row mt-2">
        <div class="col-3" >
            <div class="card" style="width: 18rem; height: 250px;">
                <div class="star-icon">
                    <i class="fas fa-star"></i>
                </div>
                <img src="/assets/ProjectManagement1.svg" alt="Chart 1"> 
                <div class="card-title text-start">Schedule Percent Complete by Job</div>
                <div class="card-text text-start">Percent of scheduled project item completion in relation to total duration of the calendar grouped by job.</div>
            </div>
        </div>
        <div class="col-3" >
            <div class="card" style="width: 18rem; height: 250px;">
                <div class="star-icon">
                    <i class="fas fa-star"></i>
                </div>
                <img src="/assets/project2.svg" alt="Chart 1"> 
                <div class="card-title text-start" >Baseline vs Actual Duration By Job</div>
                <div class="card-text text-start">The difference between the actual duration and the set baseline duration for each job.</div>
            </div>
        </div>
        <div class="col-3" >
            <div class="card" style="width: 18rem; height: 250px;">
                <div class="star-icon">
                    <i class="fas fa-star"></i>
                </div>
                <img src="/assets/project3.svg" alt="Chart 1"> 
                <div class="card-title text-start" >Change Order Profit</div>
                <div class="card-text text-start">A comparison of the Change Order Profit across jobs using the Change Order Owner Price and the Change Order Builder Cost.</div>
            </div>
        </div>
        <div class="col-3" >
            <div class="card" style="width: 18rem; height: 250px;">
                <div class="star-icon">
                    <i class="fas fa-star"></i>
                </div>
                <img src="/assets/project4.svg" alt="Chart 1"> 
                <div class="card-title text-start " >Daily Log Count by User </div>
                <div class="card-text text-start">A count of daily logs created and grouped by added by user.</div>
            </div>
        </div>
    </div>  
</div>
    </div>
