import { Component } from '@angular/core';

@Component({
  selector: 'app-salesreport-detail',
  templateUrl: './salesreport-detail.component.html',
  styleUrls: ['./salesreport-detail.component.css']
})
export class SalesreportDetailComponent {

}
