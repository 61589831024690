import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { CreateEstimateSettingRequest, EstimateSettingResponse } from 'src/Models/Estimate/estimate';
import { ResponseModel } from 'src/Models/responseMessage.model';
import { ShiftInformationResponse } from 'src/Models/Time-Clock/clock-in';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EstimateService {

private baseUrl: string = environment.apiUrl + 'EstimateSetting/';

  constructor( private http: HttpClient) { }



  postEstimate(estimateFormData: CreateEstimateSettingRequest): Observable<ResponseModel<EstimateSettingResponse>> {
    const apiUrl = `${this.baseUrl}`;
    return this.http.post<ResponseModel<EstimateSettingResponse>>(apiUrl, estimateFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

}
