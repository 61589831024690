import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { LookupNameSetupResponse } from 'src/Models/All-Filters/financialBillsFilterField';
import { CustomerInformation } from 'src/Models/CustomerInformation';
import { CreateCustomerEmailParameterRequest, CreateCustomerInformationRequest } from 'src/Models/CustomerInfromation/CreateCustomerInformation';
import { CustomerInformationResponse, Customerres, UpdateCustomerRequest } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { ApplicationUser } from 'src/Models/CustomerInfromation/CustomerInformation';
import { ApplicationUserForComboResponse } from 'src/Models/InternalUser/InternalUserJob';
import { ApiResponseModel } from 'src/Models/apiResponse.model';
import { ResponseModel, ResponseModelArray, TablePageResponse, TablePageResponseSingle } from 'src/Models/responseMessage.model';
import { CreateLookupFilterRequest, FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CustomerInformationService {
  CustomerInformationResponse() {
    throw new Error("Method not implemented.");
  }
  constructor(private http: HttpClient) { }


  postData(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }

  getDataColumns(): Observable<ApiResponseModel> {
    return this.http.get<ApiResponseModel>(`${this.baseUrl}getAll-Columns`);
  }
  getDataByParameter(columnName: string, searchParameter: string, contactTypeIds: number[]): Observable<ApiResponseModel> {
    const data = {
      columnName: columnName,
      searchParameter: searchParameter,
      contactTypeIds: contactTypeIds
    };
    return this.http.post<ApiResponseModel>(`${this.baseUrl}getAll-byParameter`, data)
      .pipe(
        catchError((error: any) => {
          console.error('Error:', error);
          return throwError(error);
        })
      );
  }

  updateData(data: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}`, data);
  }
  // getDataId(id: string): Observable<any> {
  //   return this.http.get<any>(`${this.baseUrl}getBy-Id?id=${id}`);
  // }
  getDataId(id: string): Observable<ApiResponseModel> {
    return this.http.get<ApiResponseModel>(`${this.baseUrl}getBy-Id?id=${id}`);
  }

  deleteDataByBookingId(bookingId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}?bookingId=${bookingId}`);
  }

  // ---------------------------------------Customer Service---------------------------------------///

  private baseUrl: string = environment.apiUrl + 'CustomerInformation/';
  private internalUser: string = environment.apiUrl + 'ApplicationUser/';
  private base_Url: string = environment.apiUrl + 'LookupFilter/';

  getCustomerData = new BehaviorSubject<ResponseModel<TablePageResponse<CustomerInformationResponse>>>(null);
  $getcustomerinformation = this.getCustomerData.asObservable();


  isUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $isUserAppliedFilter = this.isUserAppliedFilter.asObservable();


  selectedFilterId = new BehaviorSubject<number>(0);
  $selectedFilterId = this.selectedFilterId.asObservable();


  setSelectedFilterId(filterId: number) {
    this.selectedFilterId.next(filterId);
  }

  userAppliedFilter(filter: boolean) {
    this.isUserAppliedFilter.next(filter);
  }


  deleteData(globalId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}?globalId=${globalId}`);
  }







  getData(): Observable<ResponseModelArray<CustomerInformationResponse>> {
    return this.http.get<ResponseModelArray<CustomerInformationResponse>>(`${this.baseUrl}getAll`);
  }


  postCustomerData(customerInformationFormData: CreateCustomerInformationRequest): Observable<ResponseModel<CustomerInformationResponse>> {
    const apiUrl = `${this.baseUrl}`;
    return this.http.post<any>(apiUrl, customerInformationFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  deleteData1(globalId: string): Observable<ResponseModel<TablePageResponseSingle<CustomerInformationResponse>>> {
    return this.http.delete<any>(`${this.baseUrl}?globalId=${globalId}`).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  Customerupdate(customerInformationFormData: CreateCustomerInformationRequest): Observable<ResponseModel<CustomerInformationResponse>> {
    const headers = { 'Content-Type': 'application/json' };
    const options = { headers: new HttpHeaders(headers) };
    const apiUrl = `${this.baseUrl}`;
    let response = this.http.post<any>(apiUrl, customerInformationFormData, options);
    return response;
  }

  getDataId1(id: number): Observable<ResponseModel<CustomerInformationResponse>> {
    return this.http.get<ResponseModel<CustomerInformationResponse>>(`${this.baseUrl}getBy-Id?id=${id}`);
  }
  getDataGlobalId(globalId: string): Observable<ResponseModel<CustomerInformationResponse>> {
    return this.http.get<ResponseModel<CustomerInformationResponse>>(`${this.baseUrl}getBy-globalId=${globalId}`);
  }

  getAllCustomerinformationByFilter(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getCustomerData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }

  getAllCustomer() {
    this.http.get<ResponseModel<TablePageResponse<CustomerInformationResponse>>>(`${this.baseUrl}getAll`)
      .subscribe(
        (data) => {
          this.getCustomerData.next(data);
        },
        (error) => {
          console.error('Error fetching internal Customer:', error);
        }
      );
  }

  getAppliedFilterData(appliedFilter: CreateLookupFilterRequest[]) {
    const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
    return this.http.post<any>(apiUrl, appliedFilter).subscribe(
      (data) => {
        this.getCustomerData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }

  ShowCode(code: string): Observable<ResponseModelArray<LookupNameSetupResponse>> {
    return this.http.post<ResponseModelArray<LookupNameSetupResponse>>(`${this.baseUrl}getAll-byStandardCode`, { code });
  }

  getDataforcombo(): Observable<ResponseModelArray<ApplicationUserForComboResponse>> {
    return this.http.get<ResponseModelArray<ApplicationUserForComboResponse>>(`${this.internalUser}GetAll-ActiveUsers`);
  }

  customercombo(): Observable<ResponseModelArray<Customerres>> {
    return this.http.get<ResponseModelArray<Customerres>>(`${this.baseUrl}getAll-ForName`);
  }


  getInternalUserData(): Observable<ResponseModelArray<ApplicationUser>> {
    return this.http.get<ResponseModelArray<ApplicationUser>>(`${this.internalUser}getAll`);
  }



  // Create Method Get By ID Customer Information

  getCustomerById(id: number): Observable<ResponseModel<CustomerInformationResponse>> {
    return this.http.get<ResponseModel<CustomerInformationResponse>>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }

  updateEmail(data: UpdateCustomerRequest): Observable<ResponseModel<UpdateCustomerRequest>> {
    return this.http.post<ResponseModel<UpdateCustomerRequest>>(`${this.baseUrl}update-Customer-Status-Email`, data);
  }


}

