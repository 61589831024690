<nz-modal
    nzWidth="36%"
    [(nzVisible)]="customfieldinfo"
    [nzTitle]="modalTitle1"
    [nzFooter]="modalFooter1"
    (nzOnCancel)="closeAddLeadActivityType()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '400px', 'overflow-y': 'auto',}"
    [nzStyle]="{ top: '15px' }"
>
    <ng-template #modalTitle1>
        <div class="row p-0 m-0">
        <h2 class="p-0 m-0" nz-typography>Add Lead Activity Type</h2>
        </div>
    </ng-template>


    <div
        class="content"
        *nzModalContent
    >
        <div class="card border-0">
            <div class="card-head border-bottom">
                <div class="row py-2">
                    <h3 nz-typography><span class="ms-3">Add Lead Activity Type</span></h3>
                </div>
              </div>
            <div class="card-body  famly-inn">
                <div class="row">
                    <div class="col">
                        <label class="sub-text">
                            Title
                            <span class="text-danger fw-bolder">*</span>
                        </label>
                        <input
                            formControlName="name"
                            type="text"
                            class="form-control formField"
                        >
                    </div>
                </div>
                <div class="row gx-4">
                    <div class="col-8">
                        <div class="row">
                            <h4 class="ant-typography">
                                <b>
                                    Set up a color and icon for the activity type:
                                </b>
                            </h4>
                        </div>
                        <div class="row">
                            <label class="sub-text me-2">Color</label>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <nz-select
                                    nzShowSearch
                                    class="custom-select"
                                    nzPlaceHolder="Select a person"
                                >
                                    <nz-option
                                        nzLabel="Jack"
                                        nzValue="jack"
                                    ></nz-option>
                                    <nz-option
                                        nzLabel="Lucy"
                                        nzValue="lucy"
                                    ></nz-option>
                                    <nz-option
                                        nzLabel="Tom"
                                        nzValue="tom"
                                    ></nz-option>
                                </nz-select>
                            </div>
                        </div>
                        <div class="row">
                            <label class="sub-text me-2">Icon</label>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <nz-select
                                nzShowSearch
                                    class="custom-select"
                                    nzPlaceHolder="Select OS"
                                    [nzCustomTemplate]="defaultTemplate"
                                >
                                    <nz-option
                                        nzCustomContent
                                        nzLabel="Windows"
                                        nzValue="windows"
                                    >
                                        <span
                                            nz-icon
                                            nzType="windows"
                                        ></span>
                                        Windows
                                    </nz-option>
                                    <nz-option
                                        nzCustomContent
                                        nzLabel="Mac"
                                        nzValue="mac"
                                    >
                                        <span
                                            nz-icon
                                            nzType="apple"
                                        ></span>
                                        Mac
                                    </nz-option>
                                    <nz-option
                                        nzCustomContent
                                        nzLabel="Android"
                                        nzValue="android"
                                    >
                                        <span
                                            nz-icon
                                            nzType="android"
                                        ></span>
                                        Android
                                    </nz-option>
                                </nz-select>
                                <ng-template
                                    #defaultTemplate
                                    let-selected
                                >
                                    <span
                                        nz-icon
                                        [nzType]="selected.nzValue"
                                    ></span>
                                    {{ selected.nzLabel }}
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="row">
                            <h4 class="ant-typography">
                                <b>Preview:</b>
                            </h4>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <span
                                    class="ant-avatar"
                                    style="width: 130px;height: 130px;line-height: 64px;font-size: 18px; "
                                >
                                    <i
                                        style="font-size: 85px;  text-align: center; margin-top: 30px; margin-left: 5px;"
                                        class="fa fa-user-plus fa-stack-1x"
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #modalFooter1>
        <div class="mt-2 align-items-center">
            <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
        </div>
    </ng-template>  
</nz-modal>
