import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FinancialRoutingModule } from './financial-routing.module';
import { EstimateDashboardComponent } from './Estimate/estimate-dashboard/estimate-dashboard.component';
import { BidDashboardComponent } from './Tenders/bid-dashboard/bid-dashboard.component';
import { SharedModule } from 'src/shared/shared.module';
import { TendorsContentComponent } from './Tenders/tendors-content/tendors-content.component';
import { BudgetDashboardComponent } from './Budget/budget-dashboard/budget-dashboard.component';
import { BudgetContentComponent } from './Budget/budget-dashboard/budget-content/budget-content.component';
import { PurchaseOrderDashboardComponent } from './purchaseOreder/purchase-order-dashboard/purchase-order-dashboard.component';
import { PurchaseOrderEmptyComponent } from './purchaseOreder/purchase-order-empty/purchase-order-empty.component';
import { PurchaseOrderBillsComponent } from './purchaseOreder/purchase-order-bills/purchase-order-bills.component';
import { AddNewBillComponent } from './purchaseOreder/add-new-bill/add-new-bill.component';
import { BillsComponent } from './purchaseOreder/bills/bills.component';
import { PurchaseOrderModelComponent } from './purchaseOreder/purchase-order-model/purchase-order-model.component';
import { CostInboxDashboardComponent } from './Cost-Inbox/cost-inbox-dashboard/cost-inbox-dashboard.component';
import { CostInboxEmptyComponent } from './Cost-Inbox/cost-inbox-empty/cost-inbox-empty.component';
import { ReceiptUploadComponent } from './Cost-Inbox/receipt-upload/receipt-upload.component';
import { OwnerInvoiceDashboardComponent } from './Owner-Invoice/owner-invoice-dashboard/owner-invoice-dashboard.component';
import { OwnerInvoicesEmptyComponent } from './Owner-Invoice/owner-invoices-empty/owner-invoices-empty.component';
import { OwnerInvoicePaymentEmptyComponent } from './Owner-Invoice/owner-invoice-payment-empty/owner-invoice-payment-empty.component';
import { OwnerInvoiceCreditMemosEmptyComponent } from './Owner-Invoice/owner-invoice-credit-memos-empty/owner-invoice-credit-memos-empty.component';

@NgModule({
  declarations: [
    EstimateDashboardComponent,
    BidDashboardComponent,
    TendorsContentComponent,
    BudgetDashboardComponent,
    BudgetContentComponent,
    CostInboxDashboardComponent,
    CostInboxEmptyComponent,
    ReceiptUploadComponent,
    PurchaseOrderDashboardComponent,
    PurchaseOrderEmptyComponent,
    PurchaseOrderBillsComponent,
    AddNewBillComponent,
    BillsComponent,
    PurchaseOrderModelComponent,
    OwnerInvoiceDashboardComponent,
    OwnerInvoicesEmptyComponent,
    OwnerInvoicePaymentEmptyComponent,
    OwnerInvoiceCreditMemosEmptyComponent,
  ],
  imports: [
    CommonModule,
    FinancialRoutingModule,
   SharedModule,
  ]
})
export class FinancialModule { }
