<nz-page-header class="p-0">
    <nz-page-header-extra>
      <nz-space>
        <button (click)="LeadSettings()" nz-popover [nzPopoverContent]="Summary" nzPopoverPlacement="left" nz-button nzType="default" class="ms-2 rounded-1"><span nz-icon nzType="setting" nzTheme="outline"></span></button>
        <ng-template #Summary>
          <div class="rounded-2">
            <p style="font-size: 13px;">
                Lead Settings
            </p>
          </div>
        </ng-template>
        <nz-divider nzType="vertical" class="divder"></nz-divider>
        <button  *nzSpaceItem  nz-button nzType="default" class="ms-2 rounded-1" >
            <i class="fas fa-graduation-cap"></i>&nbsp;&nbsp;Learn More</button>
            <button  class="rounded-1" *nzSpaceItem nz-button nzType="default" 
                >Export</button>
            <button  class="rounded-1" *nzSpaceItem nz-button nzType="default"  (click)="leadOpportunityImportLeadModalOpen()"
                >Import</button>
            <button  class="rounded-1" *nzSpaceItem nz-button nzType="default"  (click)="leadOpportunityAddContactModalOpen()"
                >Add Contact To From Website</button>
            <button  class="rounded-1" *nzSpaceItem nz-button nzType="default"  (click)="openFilter(LEAD_OPPR_LIST_VIEW,PAGE_ID)"
                ><i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter</button>
            <button  class="rounded-1" *nzSpaceItem nz-button nzType="primary"  (click)="leadOpportunityNewLeadModalOpen()" >New Lead
                Opportunity</button>
      </nz-space>
    </nz-page-header-extra>
    
  </nz-page-header>


  