import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sales-lead-opportunity-custom-field',
  templateUrl: './sales-lead-opportunity-custom-field.component.html',
  styleUrls: ['./sales-lead-opportunity-custom-field.component.css']
})
export class SalesLeadOpportunityCustomFieldComponent {
  @Output() cancel = new EventEmitter<void>();

  // selectionModel
  customfieldinfo= true;
  handleCancel2(): void {
  this.cancel.emit();
  }
}
