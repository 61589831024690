import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

	title = 'CmsClient';


	ngOnInit(): void {
	}

	inputValue?: string;
	options: string[] = [];
	onInput(event: Event): void {
		const value = (event.target as HTMLInputElement).value;
		this.options = value ? [value, value + value, value + value + value] : [];
	}

	inputWidth = '270px';
	// toggleInputWidth(): void {
	// 	this.inputWidth = this.inputWidth === '270px' ? '330px' : '270px';
	// }

	visible = false;

	openHeaderSideBar(): void {
		this.visible = true;
	}

	close(): void {
		this.visible = false;
	}


	showAllButtons: boolean = true;

	// You may also want to define a property to store the screen size
	screenSize: number = window.innerWidth;
  
	// Listen for window resize events to update the screenSize property
	@HostListener('window:resize', ['$event'])
	onResize(event: Event): void {
	  this.screenSize = (event.target as Window).innerWidth;
	}

}
