<!-- -->
<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form (ngSubmit)="onFilterApply()">
    <div class="row mt-2">
      <div class="col-10 p-0">
        <div class="input-group">
          <nz-select [(ngModel)]="formFieldValues['standardFilter']" name="standardFilter"
            (ngModelChange)="onChangeStandardFilter($event)" nzShowSearch [nzAutoFocus]="true"
            nzPlaceHolder="Standard Filter" r>
            <nz-option *ngFor="let filter of filterResponseData"
              [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')" [nzValue]="filter.id" nzCustomContent>
              <div class="option-content">
                <!-- Customize content with icons and text -->
                <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
                <span>{{ filter.name }}</span>
                <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

              </div>
            </nz-option>
          </nz-select>
        </div>
      </div>
      <!-- [nzLabel]="filter.name + (filter.isSetAsDefault ? ' DEFAULT' : '')"  -->
      <div class="col-1 mt-1">
        <i 
        nz-popover
        [nzPopoverContent]="contentTemplate"
        [nzPopoverPlacement]="'top'"
        class="bi bi-three-dots" (click)="openStandardFilterComponent('SAVE_AS')"></i>
      </div>
    </div>
    <div class="row mt-2" *ngIf="selectedFilter === 1">
      <div class="col">
        <p>Your filter has unsaved changes.</p>
      </div>
    </div>
    <div class="row mt-2" *ngIf="selectedFilter === 0">
      <div class="col">
        <p>Your filter has unsaved changes.</p>
      </div>
    </div>

    <div class="col d-flex mt-3">
      <button (click)="openStandardFilterComponent('APPLY_SAVE_AS')" type="button" nz-button nzType="default"
        class="me-2" style="font-size: 12px;" *ngIf="selectedFilter === 1">Save As</button>
      <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;" *ngIf="selectedFilter === 1"
        type="button">Reset</button>
      <button (click)="editSaveFilterData()" nz-button nzType="primary" type="button" class="me-2"
        style="font-size: 12px;" *ngIf="selectedFilter === 0">Save</button>
      <button (click)="openStandardFilterComponent('APPLY_SAVE_AS')" nz-button nzType="default" class="me-2"
        style="font-size: 12px;" *ngIf="selectedFilter === 0" type="button">Save As</button>
      <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;" *ngIf="selectedFilter === 0"
        type="button">Reset</button>
    </div>

    <div class="row famly-inn">
      <div class="col-12">
        <div class="row">
          <div class="col p-0">
            <label class="sub-text">Search</label>
            <input [(ngModel)]="formFieldValues['search']" name="search" type="text" class="form-control formField">
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Created By</label>
            <!-- Created By -->
            <nz-tree-select style="width: 100%" [(ngModel)]="formFieldValues['createdBy']" name="createdBy"
              style="width: 100%" [nzNodes]="combinedNodes" [nzShowSearch]="true" nzAllowClear="false" nzCheckable
              (ngModelChange)="onCombineChange($event)" nzPlaceHolder="Select CreatedBy"
              nzDropdownClassName="dropdownStyle" [nzAllowClear]="false">
            </nz-tree-select>

          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Assigned Users</label>
            <!-- Assigned Users -->
            <nz-tree-select style="width: 100%" [(ngModel)]="formFieldValues['assignedUsers']" name="assignedUsers"
              style="width: 100%" [nzNodes]="combinedNodes" [nzShowSearch]="true" nzAllowClear="false" nzCheckable
              (ngModelChange)="onCombineChange($event)" nzPlaceHolder="Select assignedUsers"
              nzDropdownClassName="dropdownStyle" [nzAllowClear]="false">
            </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Status</label>
            <nz-tree-select [(ngModel)]="formFieldValues['status']" name="status" style="width: 100%"
              [nzNodes]="StatusNodes" nzShowSearch nzAllowClear="false" nzCheckable
              (ngModelChange)="OnStatusChange($event)" nzPlaceHolder="Select  Status"
              nzDropdownClassName="dropdownStyle">
            </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Due Date</label>
            <nz-select nzShowSearch [lang]="true" nzAutoFocus="true" [(ngModel)]="formFieldValues['dueDate']"
              name="dueDate">
              <nz-option *ngFor="let filter of DueDate" [nzLabel]="filter.label" [nzValue]="filter.value"> </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Related Types</label>
            <nz-tree-select [(ngModel)]="formFieldValues['relatedTypes']" name="relatedTypes" style="width: 100%"
              [nzNodes]="RelatedTypesNodes" nzShowSearch nzAllowClear="false" nzCheckable
              (ngModelChange)="OnRelatedChange($event)" nzPlaceHolder="Select relatedTypes"
              nzDropdownClassName="dropdownStyle">
            </nz-tree-select>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 p-0 text-end">
        <button nz-button nzType="default" type="button" (click)="resetFilter()">Clear All</button>
        <button class="ms-1" nz-button nzType="primary" type="submit" [disabled]="isFormDirty">Apply</button>
      </div>
    </div>

  </form>
</div>
<ng-template #contentTemplate>
  <div>
  <p>Manage Saved Views</p>
  </div>
  </ng-template>