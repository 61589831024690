import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CostCode } from 'src/Models/CostCode/costCode';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { CostCodeService } from 'src/Service/Internaluser/cost-code.service';

@Component({
  selector: 'app-cost-code-cost-category',
  templateUrl: './cost-code-cost-category.component.html',
  styleUrls: ['./cost-code-cost-category.component.css']
})
export class CostCodeCostCategoryComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  selectedValue = null;
  costCode = true;
  formFieldValues: any = {};
  costCategory: CostCode[] = [];


  constructor(private costCodeSer: CostCodeService, private CostCategory: CostCodeService, private toastssService: NzMessageService) {

  }

  ngOnInit(): void {

    // this.costCodeSer.getCategoryData().subscribe((res: ResponseModelArray<CostCode>) => {
    //   if (res) {
    //     // Map the response to the category data array
    //     this.costCategory = res.result
    //       .filter(costCategory => costCategory.costCodeId === 0) // Filter only category data where costCodeId is 0
    //       .map(costCategory => ({
    //         label: costCategory.title,
    //         value: costCategory.costCodeId.toString(),
    //       }));
    //   }
    // });

    this.costCodeSer.getCategoryData().subscribe((res: ResponseModelArray<CostCode>) => {
      if (res) {
        this.costCategory = res.result.filter(cost => cost.isCategory === true);
      }
    });


    this.formFieldValues = {
      globalI: '00000000-0000-0000-0000-000000000000',
      companyParameterId: 1,
      title: '',
      defaultLaborCost: 0,
      timeClockLaborCode: false,
      costCodeId: 0,
      detail: '',
      isStatus: false,
      isCategory: true,
      isSubCodeOf: false,
      subCostCodeId: 0
    };

  }

  CostCodeCancel(): void {
    this.cancel.emit();
  }

  autoAdjustTextAreaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
    textarea.style.height = `${Math.min(textarea.scrollHeight, 190)}px`; // Set the height with a maximum of 200px
  }

  saveButton(): void {
    if (this.formFieldValues.costCodeId > 0) {
      this.formFieldValues.isCategory = false;
      this.formFieldValues.isSubCodeOf = true;
    }

    this.CostCategory.postCostCategory(this.formFieldValues).subscribe((response) => {
      this.toastssService.success('New  Successfully!');
    });
  }
}
