import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { CreateRfiInformationAnswerRequest } from 'src/Models/ChangeOrder/ChangeOrder';
import { ApplicationUserResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { JobInformationResponse } from 'src/Models/Job-Scratch/JobFromScratch';
import { CreateAttachmentForDocumentRequest, LookupNameSetupResponse, ScheduleItemParameterResponse } from 'src/Models/LeadTags';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { RfisService } from 'src/app/company-settings/services/rfis.service';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { LeadOpportunityAttachmentsUploadFilesComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { ProjectManagementSiteDiariesCreateFilesComponent } from 'src/app/project-management/components/Site-Diaries/project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { companySettingRFIsPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
@Component({
  selector: 'app-new-rfis',
  templateUrl: './new-rfis.component.html',
  styleUrls: ['./new-rfis.component.css']
})
export class NewRFIsComponent implements OnInit {
  @Output() onSaveComplete  = new EventEmitter();
  PAGE_ID: number = 30011;
  isVisible = false;
  isHistoryVisible: boolean = false;
  isEditCardVisible: boolean = false;
  isVisibless = false;
  isSaved: boolean = false;
  showScheduleItemFields = false
  selectedValue = null;
  titleValue: string | null = null;
  newRFIsvisible = true;
  radioValue = 'A';
  time: Date | null = null;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);
  isVisibles = false;
  isOkLoading = false;
  formFieldValues: any = {};
  selectedFiles: File[] = [];
  reminder: LookupNameSetupResponse[] = [];
  assignedUser: { label: string; value: string }[] = [];
  Subvendor: { label: string; value: string }[] = [];
  salespersonMulti: NzTreeNodeOptions[] = [];
  subVendorData: NzTreeNodeOptions[] = [];
  assgindata: NzTreeNodeOptions[] = [];
  scheduleItemOption: ScheduleItemParameterResponse[] = [];
  combinedNodes: NzTreeNodeOptions[] = [];
    vendorList: SubVendorResponse[] = [];
  subVendorsNods: NzTreeNodeOptions[] = [];
  
  selectedItem: string | undefined;
  selectedNodes: any[] = [];
  jobListss: JobInformationResponse [] = [];
  combinedData: { label: string; value: string }[] = [];
  assignedUserGroup1: any[] = [];
  assignedUserGroup2: any[] = [];
  selectedVenodors: SubVendorResponse[] = [];
  selectedAssigneee: ApplicationUserResponse [] = [];
  date = null;
  inputValue1: string = 'my site';
  inputValue?: string;
  rfiInformationId: string; // Declare rfiInformationId property
  selectedUsers: ApplicationUserResponse [] = [];
 
  mainSaveResponseId: number | undefined;
  newRFIs: FormGroup
  @Output() cancel = new EventEmitter<void>();
  constructor(
    @Inject(NZ_MODAL_DATA) public leadData: any,
    private fb: FormBuilder, 
    private modal: NzModalService,
    private customerInformationService: CustomerInformationService,
    private toastService: NzMessageService, private SubVendorService: SubVendorService,
    private LeadOpportunitiesService: LeadOpportunitiesService,
    public localStorage: AccessLocalStorageService,
    private rfisSser : RfisService,
    private LookupFilterService: LookupFilterService,
    private jobsServicee: JobInformationService,
    @Inject(NZ_MODAL_DATA) public JobData: any,
    @Inject(NZ_MODAL_DATA) public name: any,
    private localStorageService: AccessLocalStorageService,
    private internalUserService: InternalUserService,
    @Inject(NZ_MODAL_DATA) public DataRfis: any,
    @Inject(NZ_MODAL_DATA) public nzData: any)
    

    {
    console.log('DataRfis:', this.DataRfis);
  }
  ngOnInit(): void {
  this.rfisubVendors();
 console.log('leadData', this.JobData.SELECTED_JOB_OBJECT);

this.rfisSser.getAllCompanyRFis().subscribe((RFIs) => {
  console.log('RFIs:', this.DataRfis); 
})

    console.log('DataRfis:', this.DataRfis);
    this.LeadOpportunitiesService.getSelectedFiles().subscribe(files => {
      this.selectedFiles = files;
    });
    this.ForCombo(),
      this.getScheduleItem(),
      this.initLoad(),
      this.loadAssignedUserGroup1();
       this.loadAssignedUserGroup2();
       this.localStorage.getJobId();
       this.localStorage.getJobName();
       this.leadProFormGroup();
                     // Sami work 
                  //    this.internalUserService.getData().subscribe((res) => {
                  //     this.selectedUsers = res.result;
                  //     const userId = this.localStorageService.getUserLoginId();
                      
                  //     if (userId) {
                  //         console.log('Found logged-in userId:', userId);
                  //         this.formFieldValues['assigneeUserId'] = [userId.toString()]; // Set the logged-in user ID to selectedAssignee
                  //     }
                  // });
                  this.internalUserService.getData().subscribe((res) => {
                    this.selectedUsers = res.result;
                    const userId = this.localStorageService.getUserLoginId();
                    const user = this.selectedUsers.find((user) => user.id === userId);
                    if (user) {
                        console.log('Mil gaya logged-in userId:', userId);
                        this.selectedAssignee = {
                            assigneeUserId: user.id.toString(),
                            assigneeSubVendorId: null
                        };
                    }
                })
                  
  }

  preDefinedDataCodes = {
    reminderFeild: companySettingRFIsPreDefinedCodes.RFIDFR,
  };
  leadProFormGrorerewup(): void {
    this.formFieldValues = {
      globalId: '00000000-0000-0000-0000-000000000000',
      headerId: this.DataRfis?.DataRfis || this.DataRfis,
      formNameId: 7,
      jobInformationId: null,
      jobSetupId: null,
      createRFIInformationRequest:[

      ],
      
      createRFIViewerParameterRequests: [],
    };
  }

  leadProFormGroup(): void {
    const jobId = this.localStorageService.getJobId();
    this.formFieldValues = {
      globalId: '00000000-0000-0000-0000-000000000000',
      headerId: this.DataRfis?.headerId,
      formNameId: this.DataRfis?.formNameId,
      jobInformationId: jobId,
      jobSetupId: 0,
      assigneeUserId: 0,
      createRFIInformationRequest: [],
      createRFIViewerParameterRequests: [],
     }
    };
    
      
  
  
  // preDefinedDataCodes = {
  //   reminderFeild: LeadActivityActivityViewPreDefinedCodes.LOAVR,
  // };
  toggleScheduleItemFields() {
    this.showScheduleItemFields = !this.showScheduleItemFields;
  }
  toggleScheduleItemFieldss() {
    this.showScheduleItemFields = false;
  }
  newcRFIsCancel(): void {
    this.cancel.emit();
  }
  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      const assgindata = res.result;
      console.log("Show data",assgindata);
      
      this.assgindata = assgindata.map((vendor) => ({
        title: vendor.fullName,
        key: vendor.id.toString(),
        isLeaf: false,
        applicationUserId: vendor.applicationUserId
      }));
      this.combineNodes();
      this.SubVendorService.getData().subscribe((res) => {
        const Subvendor = res.result;
        this.subVendorData = Subvendor.map((vendor) => ({
          title: vendor.companyName,
          key: vendor.id.toString(),
          isLeaf: false,
          subVendorId: vendor.subVendorId
        }));
        this.combineNodes();
      });
    }
    )
  }
  combineNodes(): void {
    if (this.assgindata && this.subVendorData) {
      const internalUsersNode = {
        title: 'internalUser',
        value: '0-0',
        key: '0-0',
        children: this.assgindata
      };
      const subVendorsNode = {
        title: 'Subs/Vendors',
        value: '0-1',
        key: '0-1',
        children: this.subVendorData
      };

      this.combinedNodes = [internalUsersNode, subVendorsNode];
      console.log('Combined Nodes:', this.combinedNodes);
    }
  }
  onNodeSelectionChange(selectedNodeKeys: string[]): void {
    if (selectedNodeKeys && selectedNodeKeys.length > 0) {
      const selectedNode = this.combinedNodes.find(node => node.key === selectedNodeKeys[0]);

      if (selectedNode) {
        this.selectedNodes.push(selectedNode); // Add selected node data to selectedNodes array
        this.formFieldValues.assigneeUserId = selectedNode['applicationUserId'] || '';
        this.formFieldValues.assigneeSubVendorId = selectedNode['subVendorId'] || '';
        
      }
    } else {
      // Clear the values if no node is selected
      this.formFieldValues.assigneeUserId = '';
      this.formFieldValues.assigneeSubVendorId = '';
    }
  }

  loadAssignedUserGroup1() {
   this.customerInformationService.getDataforcombo().subscribe((res) => {
      this.assignedUserGroup1 = res.result.map((AUser) => ({
        label: AUser.fullName,
        value: AUser.id.toString(),
      }));
    });
  }

  loadAssignedUserGroup2() {

    this.SubVendorService.getData().subscribe((res) => {
      this.assignedUserGroup2 = res.result.map((vendor) => ({
        label: vendor.companyName,
        value: vendor.id.toString(),
      }));
    });
  }

  rfisubVendors() {
    this.SubVendorService.getData().subscribe(res => {
      this.vendorList = res.result.filter(data => data.automaticallyPermitAccessToNewJobs === true);
      this.subVendorsNods = this.vendorList.map((data) => ({
        title: data.companyName,
        value: data.id.toString(),
        key: data.id.toString(),
        isLeaf: true,
      }));
    })
  }

  selectedAssignee: { assigneeUserId: string | null, assigneeSubVendorId: string | null } = { assigneeUserId: null, assigneeSubVendorId: null };

  onAssigneeChange(assignee: { assigneeUserId: string | null, assigneeSubVendorId: string | null }) {
    this.formFieldValues['assigneeUserId'] = assignee.assigneeUserId;
    this.formFieldValues['assigneeSubVendorId'] = assignee.assigneeSubVendorId;
  }
  

  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe(
      (res) => {
        console.log("Lookup Values Response:", res);
        let reminderValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.reminderFeild.code
        );

        this.reminder = reminderValues;
       
        // console.log("Reminder Values:", reminderValues);

        // this.reminder = reminderValues;

        // console.log("Reminder Array:", this.reminder);
      }
    );
  }
  UploadFiles(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  CreateNewFile(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  viewAll(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  removeFile(file: File): void {
    const index = this.selectedFiles.indexOf(file);
    if (index !== -1) {
      this.selectedFiles.splice(index, 1);
    }
  }

  
  
  responseHeaderId: number = -1;


  SaveRfis() {
    let data = { ...this.formFieldValues };
    let viewerParams = this.formFieldValues.createRFIViewerParameterRequests;
    let transformedParams = [];
    for (let i = 0; i < viewerParams.length; i += 2) {
      const applicationUserId = viewerParams[i];
      const subVendorId = viewerParams[i + 1];
      if (applicationUserId && subVendorId) {
        transformedParams.push({
          applicationUserId,
          customerInformationId: 0,
          subVendorId,
        }); 
      }
    }
    console.log("Transformed viewerParams:", transformedParams);
    data.createRFIViewerParameterRequests = transformedParams;
    const jobId = this.localStorageService.getJobId();
    data.createRFIInformationRequest = {
      jobInformationId: jobId || 0, 
      jobSetupId: this.formFieldValues.jobSetupId || 0,
      title: this.formFieldValues.title || '', 
      isChooseDate: this.formFieldValues.isChooseDate != null ? this.formFieldValues.isChooseDate : true, 
      isLinkToScheduleItem: this.formFieldValues.isLinkToScheduleItem || false,
      dueOn: this.formFieldValues.dueOn || new Date(), 
      rfiNumber: this.formFieldValues.rfiNumber || 'Your RFI Full Number', 
      autoAssignNumber: this.formFieldValues.autoAssignNumber || null,
      rfiFullNumber: this.formFieldValues.rfiFullNumber || 'Your RFI Full Number',
      isCompleted: this.formFieldValues.isCompleted || false,
      scheduleItemParameterId: this.formFieldValues.scheduleItemParameterId || null,
      dueNumberDays: this.formFieldValues.dueNumberDays || null,
      before: this.formFieldValues.before || false,
      after: this.formFieldValues.after || false,
      reminderSetupId: this.formFieldValues.reminderSetupId || null,
      assigneeUserId: this.formFieldValues.assigneeUserId || null,
      assigneeSubVendorId: this.formFieldValues.assigneeSubVendorId || null,
      assigneeCustomerId: null,
      relatedTypeId: null,
      todoParameterId: null,
      question: this.formFieldValues.question || 'Your RFI Question',
      isApproved: null,
      createRFIViewerParameterRequests: transformedParams,
    };
    
    this.LeadOpportunitiesService.PostRfis(data).subscribe(
      (response) => {
        this.onSaveComplete.emit();
        console.log('Data saved successfully:', response);
        this.toastService.success('New RFIS Saved Successfully!');
        // this.responseHeaderId = response.result.id.toString();
        this.responseHeaderId = response.result.id;
        
        this.isSaved = true; 
        this.isEditCardVisible = false; 
        if (this.selectedFiles && this.selectedFiles.length > 0) {
          const filesToUpload = this.selectedFiles.filter(file => file && file instanceof File);
          if (filesToUpload.length > 0) {
            const attachmentRequest: CreateAttachmentForDocumentRequest = {
              headerId: response.result.id,
              companyParameterId: 1,
              formNameId: 10002,
              documentFiles: filesToUpload.map(file => file)

            };
            console.log("responseHeaderId", this.responseHeaderId);

            console.log('selectedFiles:', this.selectedFiles);
            this.LeadOpportunitiesService.CreateAttachmentForDocument(attachmentRequest).subscribe(
              (attachmentResponse) => {
                console.log('Attachment created successfully:', attachmentResponse);
                this.responseHeaderId = response.result.id;
                // console.log("responseHeaderId",this.responseHeaderId);
                
                this.saveFileIdToDatabase(attachmentResponse.fileId);
              },
              (attachmentError) => {
                console.error('Error creating attachment:', attachmentError);
              }
              
            );
          } else {
            console.warn('No files to upload.');
          }
        }
      },
      (error) => {
        console.error('Error saving data:', error);
      }
    );
  }


 
  saveFileIdToDatabase(fileId: string) {
    // Implement your logic here
  }









  saveAnswer(rfiInformationId: string) {
    const answerData: CreateRfiInformationAnswerRequest = {
      rfiInformationId: parseInt(rfiInformationId, 10), // Convert string to number
      reassignUserId: 1,
      reassignCustomerId: 0,
      reassignSubVendorId: 0,
      response: "Sample response text",
      isApproved: true
    };
  
    this.LeadOpportunitiesService.PostRfisNetwork(answerData).subscribe(
      (response) => {
        console.log('Answer saved successfully:', response);
        this.toastService.success('New Answer Saved Successfully!');

  
        // Extract the headerId from the response
        const responseHeaderId = response.result.id;  
        console.log('responseHeaderId:', this.responseHeaderId);
        
        // Handle success
        if (this.selectedFiles && this.selectedFiles.length > 0) {
          const filesToUpload = this.selectedFiles.filter(file => file && file instanceof File);
          if (filesToUpload.length > 0) {
            const attachmentRequest: CreateAttachmentForDocumentRequest = {
              headerId: responseHeaderId, // Assign the headerId from the response
              companyParameterId: 1,
              formNameId: 30004,
              documentFiles: filesToUpload.map(file => file)
            };
            console.log('selectedFiles:', this.selectedFiles);
            this.LeadOpportunitiesService.CreateAttachmentForDocument(attachmentRequest).subscribe(
              (attachmentResponse) => {
                console.log('Attachment created successfully:', attachmentResponse);
                this.mainSaveResponseId = responseHeaderId; // Assign the headerId to mainSaveResponseId
                this.saveFileIdToDatabase(attachmentResponse.fileId);
              },
              (attachmentError) => {
                console.error('Error creating attachment:', attachmentError);
              }
            );
          } else {
            console.warn('No files to upload.');
          }
        }
      },
      (error) => {
        console.error('Error saving answer:', error);
        // Handle error
      }
    );
  }
  
  
  

  RFI(): void {
    this.isVisible = true;
  }
  commentss(): void {
    this.isVisible = true;
  }
  closebiils() {
    this.isVisible = false
  }
  handleCancel() {
    this.isVisible = false;
  }
  handleOk(): void {
    this.isVisible = false;
  }
  Add(): void {
    this.isVisibles = true;
  }
  commentsss(): void {
    this.isVisible = true;
  }
  closebiilss() {
    this.isVisibles = false
  }
  toggleEditCardVisibility(): void {
    this.isEditCardVisible = !this.isEditCardVisible;
  }
  toggleHistoryVisibility(): void {
    this.isHistoryVisible = !this.isHistoryVisible;
  }
  getScheduleItem() {
    this.LeadOpportunitiesService.getAllDataScheduleItem().subscribe(res => {
      this.scheduleItemOption = res.result;
    });
  }
}