import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ApplicationUserResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { forkJoin, map } from 'rxjs';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { MessagingRFIsPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { Editor, EditorConfig, initializeEditor } from 'src/app/helpers/ckeditor-config';
import { SelectionService } from 'src/Service/Selection/selection.service';
import { ScheduleItemParameterResponse } from 'src/Models/Project-Management/projectManagement';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RelateItemComponent } from '../relate-item/relate-item.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import validateForm from 'src/app/helpers/validationForm';
import { RFIsInforServiceService } from 'src/Service/RFIs_InforService/rfis-infor-service.service';
import { saveAttachments } from 'src/app/helpers/Attachment_Save';
import { AttachmentResponse, CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { NzImageService } from 'ng-zorro-antd/image';
import { AttachmentAnnotationComponent } from 'src/app/lead-opportunities/Components/attachment-annotation/attachment-annotation.component';
import { environment } from 'src/environments/environment.prod';
import { RFIInformationGetById, RFIInformationResponse } from 'src/Models/_RFIs/RFIs_Infor';
import { BehaviorsubjectService } from 'src/Service/behaviorsubject/behaviorsubject.service';
;
@Component({
  selector: 'app-new-rfis',
  templateUrl: './new-rfis.component.html',
  styleUrls: ['./new-rfis.component.css']
})
export class NewRFIsComponent implements OnInit, AfterViewInit {
  @Output() RFIs_Response_Is_Complete = new EventEmitter<RFIInformationResponse>(); 
  initializationComplete = new EventEmitter<boolean>();
  @Input() RFIsResponseId: number;
  @ViewChild('carouselContainer', { static: false }) carouselContainer!: ElementRef<HTMLDivElement>;
  IMG_BASE: string = environment.IMG_BUCKET_URL;
  @Output() cancel = new EventEmitter<void>();
  @Input() Job_ID: number;
  @Input() header_ID : number; 
  @Input() Form_Name_Id : number; 
  @Input() onSeletedJobEndSetJob: string;
  onSetJob: string;
  isSave: boolean = false;
  isVisibleInRFIs: boolean = true;
  showErrorCardFlag: boolean = false;
  isSubmitted: boolean = false;
  formErrors: { field: string, message: string }[] = [];
  RFIsFormFieldsValue: any = {};
  validationStates: { [key: string]: NzStatus } = {};
  validationMessages: { [key: string]: string } = {};
  touchedFields: { [key: string]: boolean } = {};
  selectedUsers: any[] = [];
  internalRes: ApplicationUserResponse[] = [];
  subVenRes: SubVendorResponse[] = [];
  ViewersGroupNodes: NzTreeNodeOptions[] = [];
  reminder: LookupNameSetupResponse[] = [];
  selectionRes: ScheduleItemParameterResponse[] = [];
  isSaveAndClose: boolean = false;
  isSaveAndRelease: boolean = false;
  DisabledNextActivity: boolean = false;
  RFIsInformationResponse: RFIInformationResponse;
  initials: string = '';
  radioValue = 'A';
  // DaysradioValue = 'A';
  public Editor = Editor;
  public config = EditorConfig;
  selectedFilesAttachment: CustomUploadFile[] = [];
  selectedFilesAttachmentTest: CustomUploadFile[] = [];
  test: any;
  InterUserId: number
  SubVenId: number
  GetByRe: boolean = false
  isSaveAndApprove : boolean = false;
  un_Display : boolean = true;
  TestAttachment__: AttachmentResponse;
  TestId: number;
  currentDate: Date = null;
  onTagPastDue: boolean = false;
  onReminderDirective: string = null;
  onReminderTag: string = null;
  scheduleItem: string = null;
  TestCase: string = null;
  onGetByIdRes: RFIInformationGetById;
  dueDate: string;
  dueDateTest: string;
  UnApproved: boolean = false;
  anserResponse: any;
  form_NameId: number;
  preDefinedDataCodes = {
    reminderFeild: MessagingRFIsPreDefinedCodes.Reminder,
    relatedTypeField: MessagingRFIsPreDefinedCodes.RelatedTypes,
  };

  constructor(
    public localStorageService: AccessLocalStorageService,
    private _AttachmentService: AttachmentService,
    public toastsService: NzMessageService,
    private internalUserService: InternalUserService,
    private subVendorService: SubVendorService,
    private LookupFilterService: LookupFilterService,
    private selectionService: SelectionService,
    private modal: NzModalService,
    private _RFIsInforService: RFIsInforServiceService,
    private _BehaviorsubjectService: BehaviorsubjectService,
    private nzImageService: NzImageService,
  ){ }
  ngAfterViewInit(): void {
    initializeEditor(this.Editor, this.config);
  }
  ngOnInit(): void {
    this.createRFIsForm();
    if(this.onSeletedJobEndSetJob?.length === undefined){
      this.onSetJob = this.localStorageService.getJobName();
    } else {
      this.onSetJob = this.onSeletedJobEndSetJob;
    }
    if(this.Form_Name_Id > 0){
      this.form_NameId = this.Form_Name_Id
    } else {
      this.form_NameId = 26
    }
    this.onGetAllAssigneeUser();
    this.initLoad();
    this.radioButtons();
    this.DaysradioButtons();
    if (this.RFIsResponseId > 0) {
      this.RFIsGetById(this.RFIsResponseId, null);
    }
  }
  onGetAllAssigneeUser() {
    const internalUser$ = this.internalUserService.getData().pipe(map(res => res.result));
    const subVendor$ = this.subVendorService.getData().pipe(map(res => res.result));
  
    forkJoin([internalUser$, subVendor$]).subscribe(
      ([internalRes, subVenRes]) => {
        this.internalRes = internalRes.filter(ii => ii?.isActive === true);
        this.subVenRes = subVenRes.filter(ii => ii?.isActive === true);
  
        this.selectedUsers = [
          {
            groupName: 'Internal Users',
            users: this.internalRes.map(user => ({
              id: user?.id,
              name: user?.fullName,
            }))
          },
          {
            groupName: 'Sub Vendors',
            users: this.subVenRes.map(vendor => ({
              id: vendor?.id,
              name: vendor?.companyName,
            }))
          }
        ];
  
        const userId = this.localStorageService.getUserLoginId();
        const selectedUser = this.internalRes.find(user => user.id === userId);
  
        if (selectedUser) {
          this.test = { id: selectedUser.id, groupName: 'Internal Users' };
          this.onAssigneeChange(this.test);
        }
      },
      (error) => {
        console.error('Error fetching users or vendors:', error);
      }
    );
  }
  compareFn = (o1: any, o2: any) => {
    return o1 && o2 ? o1.id === o2.id && o1.groupName === o2.groupName : o1 === o2;
  }
  initLoad() {
    this.selectionService.getAllDataScheduleItem().subscribe(
      (res) => {
        this.selectionRes = res.result;
      });
    this.LookupFilterService.getLookUpValuesByFormNameId(26).subscribe(
      (res) => {
        let reminderValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.reminderFeild.code
        );
        this.reminder = reminderValues;
        this.initializationComplete.emit(false);
      });
  }  
  onAssigneeChange(selectedValue: any) {
    if (selectedValue === 'me') {
      const userId = this.localStorageService.getUserLoginId();
      const selectedUser = this.internalRes.find(user => user.id === userId);

      if (selectedUser) {
        this.test = { id: selectedUser.id, groupName: 'Internal Users' };
        this.onShortName(this.test?.id);
      }
    } else {
      this.onShortName(selectedValue.id || selectedValue);
    }
    if(selectedValue.groupName === "Internal Users"){
      this.InterUserId = selectedValue.id;
    } else if(selectedValue.groupName === "Sub Vendors"){
      this.SubVenId = selectedValue.id;
    }
  }
  onShortName(selectedValue: any){
    if(selectedValue){
      const userIdAndNames = this.selectedUsers.flatMap(group => 
        group.users.map(user => ({
          id: user.id,
          name: user.name
        }))
      );
      let data = userIdAndNames.find(user => user.id === selectedValue);
      this.initials = this.getInitials(data?.name);
    }
  }
  getInitials(fullName: string | undefined): string {
    if (!fullName) return '';
    const nameParts = fullName.split(' ');
    const firstInitial = nameParts[0]?.charAt(0) || '';
    const lastInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1]?.charAt(0) || '' : '';
    return (firstInitial + lastInitial).toUpperCase();
  }
  CancelRFIs() {
    this.isVisibleInRFIs = false;
    this.cancel.emit();
  }
  createRFIsForm() {
    this.RFIsFormFieldsValue = {
      globalId: '00000000-0000-0000-0000-000000000000',
      headerId: this.header_ID,
      formNameId: 0,
      jobInformationId: 0,
      createRFIInformationRequest: {
        globalId: '00000000-0000-0000-0000-000000000000',
        jobInformationId: 0,
        jobSetupId: 0,
        title: '',
        isChooseDate: true,
        isLinkToScheduleItem: false,
        dueOn: null,
        idNumber: '',
        autoAssignNumber: null,
        relatedItemName: '',
        relatedTypeName: '',
        rfiFullNumber: '',
        isCompleted: false,
        scheduleItemParameterId: 0,
        dueNumberDays: null,
        before: true,
        after: false,
        reminderSetupId: 0,
        assigneeUserId: 0,
        assigneeSubVendorId: 0,
        assigneeCustomerId: 0,
        relatedType: 0,
        relatedTypeId: 0,
        relatedItemId: 0,
        question: '',
        attachmentId: 0,
        statusSetupId: 0,
        isApproved: false,
      },
    };
  }
  readonly validationRules = {
    title: {
      maxLength: { limit: 50, message: 'Title' }
    },
    autoAssignNumber: {
      maxLength: { limit: 50, message: 'ID#' }
    },
    dueOn: {
      required: 'Required',
      maxLength: {message: 'Due Date' }
    },
    question: {
      required: 'Required',
      maxLength: {message: 'Question' }
    },
  };
  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      title: 'Title',
      autoAssignNumber: 'ID#',
      dueOn: 'Due Date',
      question: 'Question',
    };
    return labels[field] || field;
  }
  getErrorTip(field: string): string {
   const rules = this.validationRules[field];
   const input = this.RFIsFormFieldsValue.createRFIInformationRequest[field];
   const inputValue = typeof input === 'string' ? input.trim() : '';
 
   if (rules.required && !input) {
     return 'Required';
   }
 
    if (rules.maxLength && inputValue.length > rules.maxLength.limit) {
     const excessLength = inputValue.length - rules.maxLength.limit;
     const unit = excessLength === 1 ? 'character' : 'characters';
     return `${excessLength} ${unit} over.`;
   }
   if (field === 'isLineItems') {
    return rules?.message;
    }

    return '';
  }
  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }
  checkErrors() {
   this.validationStates = {};
   this.validationMessages = {};

   for (let field in this.validationRules) {
     if (this.validationRules.hasOwnProperty(field)) {
       if (this.touchedFields[field] || this.isSubmitted) {
         const errorTip = this.getErrorTip(field);
         if (errorTip) {
           this.validationStates[field] = 'error';
           this.validationMessages[field] = errorTip;
         } else {
           this.validationStates[field] = null;
           this.validationMessages[field] = '';
         }
       }
     }
   }
  }


  onFieldChange(field: string, value: any) {
    if (field === 'dueOn') {
      const formattedDate = value.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
      this.dueDate = formattedDate;
    }
    if (typeof value === 'string') {
      this.RFIsFormFieldsValue.createRFIInformationRequest[field] = value.trim();
    } else {
      this.RFIsFormFieldsValue.createRFIInformationRequest[field] = value;
    }
    this.touchedFields[field] = true;
    this.checkErrors();
  }
  saveRFIs(actionType: 'save' | 'saveAndClose' | 'saveAndRelease' | 'saveAndReopen' | 'saveAndUnApprove' = 'save'): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isSave || this.isSaveAndClose || this.isSaveAndRelease || this.isSaveAndApprove) {
        return reject('Already processing');
      }
      if(this.UnApproved === true && actionType === 'saveAndReopen'){

      } else {
        if(this.RFIsFormFieldsValue?.createRFIInformationRequest?.isCompleted === false && this.onGetByIdRes?.isApproved === true && this.onGetByIdRes?.statusSetupId === 735){
          this.UnApproved = true;
          actionType = 'saveAndUnApprove'
        }
      }
      switch (actionType) {
        case 'saveAndUnApprove':
          if(this.RFIsFormFieldsValue?.createRFIInformationRequest?.isCompleted === false && this.onGetByIdRes?.isApproved === true && this.onGetByIdRes?.statusSetupId === 735){
            this.RFIsFormFieldsValue.createRFIInformationRequest.isCompleted = false;
          } else {
          this.RFIsFormFieldsValue.createRFIInformationRequest.isCompleted = true;
          }
          this.RFIsFormFieldsValue.createRFIInformationRequest.statusSetupId = 735;
          this.RFIsFormFieldsValue.createRFIInformationRequest.isApproved = true;
          if(this.RFIsFormFieldsValue?.createRFIInformationRequest?.isCompleted === false && this.onGetByIdRes?.isApproved === true && this.onGetByIdRes?.statusSetupId === 735 && this.UnApproved === true){
            this.Confirunapprove();
            this.resetFlags();
            this.DisabledNextActivity = false;
            return reject('Validation errors present');
          }
          this.isSave = true;
          break;
        case 'saveAndRelease':
          this.RFIsFormFieldsValue.createRFIInformationRequest.statusSetupId = 734
          this.isSaveAndRelease = true;
          break;
        case 'saveAndClose':
          if(this.RFIsFormFieldsValue?.createRFIInformationRequest?.isCompleted === false && this.onGetByIdRes?.statusSetupId === 735){
            this.ConfirReopen();
            this.resetFlags();
            this.DisabledNextActivity = false;
            return reject('Validation errors present');
          }
          if(this.RFIsFormFieldsValue.createRFIInformationRequest.isCompleted === true){
            this.RFIsFormFieldsValue.createRFIInformationRequest.statusSetupId = 735
          }
          this.isSaveAndClose = true;
          break;
        case 'saveAndReopen':
          this.RFIsFormFieldsValue.createRFIInformationRequest.statusSetupId = 736
          // this.isSave = true;
          this.isReopenLoading = true;
          break;
        case 'save':
          if(this.RFIsFormFieldsValue?.createRFIInformationRequest?.isCompleted === false && this.onGetByIdRes?.statusSetupId === 735){
            this.ConfirReopen();
            this.resetFlags();
            this.DisabledNextActivity = false;
            return reject('Validation errors present');
          }
          if(this.RFIsFormFieldsValue.createRFIInformationRequest.isCompleted === true){
            this.RFIsFormFieldsValue.createRFIInformationRequest.statusSetupId = 735
          }
          break;
        default:
          this.isSave = true;
      }

      this.DisabledNextActivity = true;
      this.isSubmitted = true;
      this.checkErrors();

      if (Object.values(this.validationStates).includes('error')) {
        setTimeout(() => {
          const errorElements = document.querySelectorAll('.error-message');
          if (errorElements.length > 0) {
            const lastErrorElement = errorElements[errorElements.length - 1];
            lastErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 100);

        this.resetFlags();
        this.DisabledNextActivity = false;
        return reject('Validation errors present');
      }

      let Job = this.localStorageService.getJobId();
      this.RFIsFormFieldsValue.jobInformationId = Job;
      this.RFIsFormFieldsValue.createRFIInformationRequest.jobInformationId = Job;
  
      // Assignee logic
      if (this.SubVenId === undefined) {
        this.RFIsFormFieldsValue.createRFIInformationRequest.assigneeSubVendorId = 0;
        this.RFIsFormFieldsValue.createRFIInformationRequest.assigneeUserId = this.InterUserId || this.test?.id;
      } else if (this.InterUserId === undefined) {
        this.RFIsFormFieldsValue.createRFIInformationRequest.assigneeUserId = 0;
        this.RFIsFormFieldsValue.createRFIInformationRequest.assigneeSubVendorId = this.SubVenId;
      }

      this.RFIsFormFieldsValue.formNameId = this.form_NameId;
      this._RFIsInforService.PostRfis(this.RFIsFormFieldsValue).subscribe(
        (res) => {
          this.SubVenId = undefined;
          this.InterUserId = undefined;
          this.RFIsInformationResponse = res.result;
          this.RFIsFormFieldsValue.createRFIInformationRequest.globalId = res.result.globalId;
          this.RFIsFormFieldsValue.createRFIInformationRequest.dueOn = res.result?.rfiInformations?.dueOn;
          this.isSubmitted = false;
          const dueDate = new Date(this.RFIsInformationResponse?.dueOn);

          if(actionType === 'save' || actionType === 'saveAndClose'){
            if(this.RFIsInformationResponse?.isCompleted === true && this.RFIsInformationResponse?.statusSetupId === 735){
              this.onTagPastDue = false;
              this.un_Display = false;
            } else {
              this.TestDATE(dueDate);
            }
          } else {
            if(actionType === 'saveAndRelease'){
              this.TestDATE(dueDate);
            } else if(actionType === 'saveAndReopen'){
                this.TestDATE(dueDate);
                this.un_Display = true;
                this.onQuetionIcon = true
            } else if(actionType === 'saveAndUnApprove'){
                this.TestDATE(dueDate);
                this.un_Display = false;
                this.onTagPastDue = false;
                this.onQuetionIcon = false
            }
          }
          
          if (this.selectedFilesAttachmentTest?.length > 0) {
            saveAttachments(this.selectedFilesAttachmentTest, this._AttachmentService, res.result?.id, 26)
              .then((attachmentResponseId) => {
                if (attachmentResponseId > 0) {
                  this.selectedFilesAttachmentTest = []
                  this.RFIsGetById(this.RFIsInformationResponse?.id, false);
                }
                resolve();
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            this.RFIsGetById(this.RFIsInformationResponse?.id, false);
            resolve();
          }
        this.toastsService.success('Change Order saved successfully');
        this.RFIs_Response_Is_Complete.emit(res.result);
        this._BehaviorsubjectService.setLeadActivityTypeResponse(res.result);
        },
        (error) => {
          this.toastsService.error('An error has occurred. Please try again.');
          reject(error);
        }
      ).add(() => {
        this.resetFlags();
        this.DisabledNextActivity = false;
        this.isSaveAndRelease = false;
        this.isSave = false;
      });
    });
  }
  saveRFIsAndRelease(): Promise<void> {
    return this.saveRFIs('saveAndRelease').then(() => {
    }).catch((error) => {
      console.error('Error during save and close:', error);
    });
  }
  saveRFIsAndClose(): Promise<void> {
    return this.saveRFIs('saveAndClose').then(() => {
      this.resetForm();
      this.CancelRFIs();
    }).catch((error) => {
      console.error('Error during save and close:', error);
    })
  }
  saveRFIsAndReopen(): Promise<void> {
    if(this.RFIsFormFieldsValue?.createRFIInformationRequest?.isCompleted === false && this.onGetByIdRes?.isApproved === true && this.onGetByIdRes?.statusSetupId === 735){
      this.UnApproved = true;
      return this.saveRFIs('saveAndReopen').then(() => {
      }).catch((error) => {
        console.error('Error during save and close:', error);
      })
    } else {
      this.UnApproved = false;
      return this.saveRFIs('saveAndReopen').then(() => {
      }).catch((error) => {
        console.error('Error during save and close:', error);
      })
    }
  }
  onQuetionIcon: boolean = true;
  saveRFIsAndUnApproveReopen(): Promise<void> {
    this.onQuetionIcon = false
    return this.saveRFIs('saveAndUnApprove').then(() => {
    }).catch((error) => {
      console.error('Error during save and close:', error);
    })
  }
  resetFlags() {
    this.isSave = false; 
    this.isSaveAndClose = false;
    this.isSaveAndRelease = false;
    this.isSaveAndApprove = false;
    this.isReopenLoading = false; 
    this.isReopenAndApprove = false; 
  }
  resetForm(): void {
    this.RFIsFormFieldsValue = {}
  }
  TestDATE(date: Date){
    const currentDate = new Date();
    const dueDate = new Date(date);
    if(dueDate < currentDate){
      this.onTagPastDue = true;
    } else {
      this.onTagPastDue = false;
    }
  }
  patchFormValues(RFIsInfoResponse: RFIInformationGetById) {
    const dueDate = new Date(RFIsInfoResponse?.DueOn);
    if(RFIsInfoResponse?.IsCompleted === false){
      this.TestDATE(dueDate);
    }
    if(RFIsInfoResponse?.IsCompleted === true && RFIsInfoResponse?.statusSetupId === 735){
      this.onTagPastDue = false;
      this.un_Display = false;
      this.anserResponse = {
        RFIId: RFIsInfoResponse?.RFIId,
        IsComplete: true,
      };
    } else {
      this.anserResponse = {
        RFIId: RFIsInfoResponse?.RFIId,
        IsComplete: false,
      };
      this.TestDATE(dueDate);
    }
    const selectedUser = this.internalRes.find(user => user.id === RFIsInfoResponse?.AssigneeUserId);

    if (selectedUser) {
      this.test = { id: selectedUser.id, groupName: 'Internal Users' };
      this.onAssigneeChange(this.test);
    }
    this.onGetByIdRes = RFIsInfoResponse
    if(this.onGetByIdRes !== undefined){
      this.initializationComplete.emit(false);
    }
    this.TestId = RFIsInfoResponse?.Id
    this.RFIsFormFieldsValue = {
      createRFIInformationRequest: {
        globalId: RFIsInfoResponse?.GlobalId || '00000000-0000-0000-0000-000000000000',
        jobInformationId: RFIsInfoResponse?.JobInformationId || 0,
        title: RFIsInfoResponse?.Title || '',
        isChooseDate: RFIsInfoResponse?.IsChooseDate || true,
        isLinkToScheduleItem: RFIsInfoResponse?.isLinkToScheduleItem || false,
        dueOn: RFIsInfoResponse?.DueOn || null,
        idNumber: RFIsInfoResponse?.IdNumber || '',
        autoAssignNumber: RFIsInfoResponse?.AutoAssignNumber || null,
        rfiFullNumber: RFIsInfoResponse?.rfiFullNumber || '',
        isCompleted: RFIsInfoResponse?.IsCompleted || false,
        scheduleItemParameterId: RFIsInfoResponse?.scheduleItemParameterId || 0,
        dueNumberDays: RFIsInfoResponse?.dueNumberDays || null,
        before: RFIsInfoResponse?.before || true,
        after: RFIsInfoResponse?.after || false,
        reminderSetupId: RFIsInfoResponse?.ReminderSetupId || 0,
        assigneeSubVendorId: RFIsInfoResponse?.AssigneeUserId || 0,
        assigneeCustomerId: RFIsInfoResponse?.AssigneeUserId || 0,
        relatedTypeId: RFIsInfoResponse?.RelatedTypeId || 0,
        relatedItemId: RFIsInfoResponse?.RelatedItemId || 0,
        question: RFIsInfoResponse?.question || '',
        attachmentId: RFIsInfoResponse?.attachmentId || 0,
        isApproved: RFIsInfoResponse?.isApproved || false,
        relatedTypeName: RFIsInfoResponse?.RelatedTypeName || '',
        relatedItemName: RFIsInfoResponse?.RelatedItemName || '',
      }
    };
    this.fetchAttachments(RFIsInfoResponse?.attachmentId);
  }
  fetchAttachments(AttachmentId: number) {
    if (AttachmentId) {
      this._AttachmentService.getLeadByAttachmentId(AttachmentId).subscribe(
        (res) => {
          this.TestAttachment__ = res.result
          this.processAttachments(this.TestAttachment__);
        },
      );
    } else {
      this.TestAttachment__ = undefined;
    }
  }
  processAttachments(Files: AttachmentResponse): void {
    if (Files?.attachmentParameters && Files.attachmentParameters.length > 0) {
      const attachmentParameters = Files?.attachmentParameters;
      this.selectedFilesAttachment = attachmentParameters.map(att => ({
        uid: att.id?.toString() || '',
        name: att.name || '',
        status: 'done',
        url: this.IMG_BASE + att.filePath,
        thumbUrl: this.IMG_BASE + att.filePath,
        type: this.getMimeType(att.extension),
        originFileObj: new File([new Blob()], att.name || '', {
          type: this.getMimeType(att.extension),
          lastModified: new Date(att.modifiedOn).getTime()
        }),
        loading: false,
        error: false,
        addedBy: att.createdBy || 0,
        createdOn: new Date(att.createdOn),
        modifiedOn: new Date(att.modifiedOn),
        id: att.id || 0,
        globalId: att.globalId || ''
      }));
    } else {
      this.selectedFilesAttachment = [];
      console.log('No attachments available.');
    }
  }
  getMimeType(extension: string): string {
    switch (extension) {
      case '.pdf':
        return 'application/pdf';
      case '.doc':
      case '.docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case '.json':
        return 'application/json';
      case '.txt':
        return 'text/plain';
      case '.xls':
      case '.xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case '.ppt':
      case '.pptx':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'mp4':
      case 'avi':
        return 'video/mp4';
      default:
        return 'application/octet-stream';
    }
  }
  radioButtons(){
    switch (this.radioValue) {
      case 'A':
        this.RFIsFormFieldsValue.createRFIInformationRequest.isLinkToScheduleItem = false
        this.RFIsFormFieldsValue.createRFIInformationRequest.isChooseDate = true;
        break;
      case 'B':
        this.RFIsFormFieldsValue.createRFIInformationRequest.isChooseDate = false
        this.RFIsFormFieldsValue.createRFIInformationRequest.isLinkToScheduleItem = true;
        break;
    }
  }
  onFilesUploaded(files: CustomUploadFile[]): void {
    this.selectedFilesAttachmentTest = files
  }
  createRelateItem(): void {
    const modalRef = this.modal.create({
      nzWidth: '40%',
      nzStyle: { top: '60px' },
      nzContent: RelateItemComponent,
      nzFooter: null
    });
  
    modalRef.componentInstance.cancel.subscribe(res => {
      this.RFIsFormFieldsValue.createRFIInformationRequest.relatedItemId = res?.ItemId;
      this.RFIsFormFieldsValue.createRFIInformationRequest.relatedItemName = res?.ItemName;
      this.RFIsFormFieldsValue.createRFIInformationRequest.relatedTypeId = res?.TypeId;
      this.RFIsFormFieldsValue.createRFIInformationRequest.relatedTypeName = res?.TypeName;
      modalRef.destroy();
    });
  }
  closerelateItemB(){
    this.RFIsFormFieldsValue.createRFIInformationRequest.relatedItemId = 0
    this.RFIsFormFieldsValue.createRFIInformationRequest.relatedItemName = ''
    this.RFIsFormFieldsValue.createRFIInformationRequest.relatedTypeId = 0
    this.RFIsFormFieldsValue.createRFIInformationRequest.relatedTypeName = ''
  }
  RFIsGetById(_Id: number, quetionEdit: boolean){
    this._RFIsInforService.RFIsgetDataId(_Id).subscribe(
      (res) => {
        if(quetionEdit === true){
          this.selectedFilesAttachment = []
          this.GetByRe = false;
        } else if(res.result?.Id >= 0){
          this.GetByRe = true;
        }
        if (res?.result) {
          this.patchFormValues(res.result);
        }
      },
    )
  }
  deleteFile(): void { 
    this.RFIsGetById(this.TestId, null);
  }
  getFileIcon(file: CustomUploadFile): string | null {
    const fileType = file.type;
    if (fileType.includes('pdf')) {
      return 'assets/Attachment/documentPDF.svg';
    } else if (fileType.includes('excel') || fileType.includes('spreadsheet')) {
      return 'assets/Attachment/documentXLS.svg';
    } else if (fileType.includes('word')) {
      return 'assets/Attachment/documentDOC.svg';
    } else if (fileType.includes('zip')) {
      return 'assets/Attachment/documentZIP.svg';
    } else if (fileType.includes('text') || fileType.includes('json')) {
      return 'assets/Attachment/documentGeneric.svg';
    } else if (fileType.includes('ppt') || fileType.includes('presentation')) {
      return 'assets/Attachment/documentPPT.svg';
    } else if (fileType.includes('video')) {
      return null;
    } else if (fileType.includes('image')) {
      return null;
    } else {
      return null;
    }
  }
  onClick(): void {
    let filteredImages = this.selectedFilesAttachment.filter(f =>
      f.name.includes('png') || f.name.includes('jpeg')
    );
    const images = filteredImages.map(file => ({
      src: file.thumbUrl,
      alt: file.name
    }));
    this.nzImageService.preview(images, {
      nzZoom: 1.0,
      nzRotate: 0
    });
  }
  playVideo(file: CustomUploadFile): void {
    const videoElement: HTMLVideoElement = this.carouselContainer.nativeElement.querySelector(`#video-${file.id}`);
    if (videoElement) {
      videoElement.style.display = 'block';
      videoElement.play();
    }
  }
  removeFile(file: CustomUploadFile): void {
    this.selectedFilesAttachment = this.selectedFilesAttachment.filter(item => item.uid !== file.uid);
    // this.filesUploaded.emit(this.QuetionsFiles);
  }
  uploadFilesUpdate(fileq: CustomUploadFile): void {
    const modalRef = this.modal.create({
      nzContent: AttachmentAnnotationComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.DrawerImge = fileq;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.imageDataRes.subscribe((data: any) => {
      const newImageUrl = data;
    });
  }
  isImageFile(file: CustomUploadFile): boolean {
    const fileName = file.name.toLowerCase();
    const fileType = file.type;

    return (
      fileType === 'image/png' ||
      fileType === 'image/jpeg' ||
      fileName.endsWith('.png') ||
      fileName.endsWith('.jpeg')
    );
  }
  // ConfirReopen(){
  //   this.modal.confirm({
  //     nzTitle: 'Are you sure you would like to reopen this RFI?',
  //     nzOkText: 'Ok',
  //     nzCancelText: 'Cancel',
  //     nzOnOk: () => {
  //       // this.ConfirmFlatFee('isFlatFree', value);
  //     },
  //     nzOnCancel: () => {
  //     }
  //   });
  // }
  isReopenLoading: boolean = false;
  isReopenAndApprove: boolean = false;
  ConfirReopen(): void {
    this.modal.confirm({
      nzTitle: `Are you sure you would like to reopen this RFI?`,
      nzOkText: `Ok`,
      nzOkDanger: false,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.saveRFIsAndReopen();
      },
      nzOnCancel: () => {
      },
      nzOkLoading: this.isReopenLoading,
      nzStyle: { 'padding-top': '95px' },
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }
  Confirunapprove(): void {
    this.modal.confirm({
      nzTitle: `Are you sure you would like to reopen this RFI? This will unapprove the approved response.`,
      nzOkText: `Ok`,
      nzOkDanger: false,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.saveRFIsAndReopen();
      },
      nzOnCancel: () => {
      },
      // nzOkLoading: this.isReopenAndApprove,
      nzStyle: { 'padding-top': '95px' },
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }
  DaysradioValue = 'A';
  // dueNumberDays: number = 0;
  ScheduleItem_start_end_On: boolean = false
  onFieldScheduleItem() {
    let scheduleItemRow = this.selectionRes.filter(xx => xx?.id === this.RFIsFormFieldsValue.createRFIInformationRequest.scheduleItemParameterId);
    let start_On = scheduleItemRow[0]?.startOn
    let end_On = scheduleItemRow[0]?.endOn
    
    if(this.DaysradioValue === 'A'){
      if(this.RFIsFormFieldsValue.createRFIInformationRequest.scheduleItemParameterId > 0){
        this.ScheduleItem_start_end_On =  true;
      } else {
        this.ScheduleItem_start_end_On =  false;
      }
      this.RFIsFormFieldsValue.createRFIInformationRequest.dueOn = start_On
    } else {
      if(this.RFIsFormFieldsValue.createRFIInformationRequest.scheduleItemParameterId > 0){
        this.ScheduleItem_start_end_On =  true;
      } else {
        this.ScheduleItem_start_end_On =  false;
      }
      this.RFIsFormFieldsValue.createRFIInformationRequest.dueOn = end_On
    }
    this.updateDueDate();
  }
  formatDate(dateString: Date): string {
    if (!dateString) return '';
    let date = new Date(dateString);
    return date.toISOString().split('T')[0];
  }
  DaysradioButtons() {
    switch (this.DaysradioValue) {
      case 'A':
        this.RFIsFormFieldsValue.createRFIInformationRequest.after = false
        this.RFIsFormFieldsValue.createRFIInformationRequest.before = true;
        break;
      case 'B':
        this.RFIsFormFieldsValue.createRFIInformationRequest.before = false
        this.RFIsFormFieldsValue.createRFIInformationRequest.after = true;
        break;
    }
    this.updateDueDate();
    this.onFieldScheduleItem();
  }
  onDaysChange(value: number) {
    this.RFIsFormFieldsValue.createRFIInformationRequest.dueNumberDays = value;
    this.updateDueDate();
  }
  updateDueDate() {
    let dueOn = this.RFIsFormFieldsValue.createRFIInformationRequest.dueOn;
  
    if (typeof dueOn === 'string') {
      dueOn = new Date(dueOn);
    }
  
    if (dueOn instanceof Date && !isNaN(dueOn.getTime())) {
      let newDate = new Date(dueOn);
  
      if (this.DaysradioValue === 'A') {
        newDate.setDate(newDate.getDate() - this.RFIsFormFieldsValue.createRFIInformationRequest.dueNumberDays);
      } else if (this.DaysradioValue === 'B') {
        newDate.setDate(newDate.getDate() + this.RFIsFormFieldsValue.createRFIInformationRequest.dueNumberDays);
      }
  
      const formattedDate = newDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
  
      this.dueDateTest = formattedDate;
      this.RFIsFormFieldsValue.createRFIInformationRequest.dueOn = newDate;
  
    } else {
      this.dueDateTest = null;
      this.RFIsFormFieldsValue.createRFIInformationRequest.dueOn = null;
    }
  }
  onFieldisComplete(field: string, selectedId: number) {
    switch (field) {
      case 'reminderSetupId':
        const selectedReminder = this.reminder.find(option => option?.id === selectedId);
        this.onReminderDirective= selectedReminder?.name
        this.onReminderTag = selectedReminder?.name
        break;
      case 'scheduleItemParameterId':
        const selectedScheduleItem = this.selectionRes.find(option => option.id === selectedId);
        this.scheduleItem = selectedScheduleItem?.title
        break;
        default:
        this.onFieldScheduleItem();
    }
  }
}
