import { Component, EventEmitter, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';


@Component({
  selector: 'app-lead-opportunities-export',
  templateUrl: './lead-opportunities-export.component.html',
  styleUrls: ['./lead-opportunities-export.component.css']
})
export class LeadOpportunitiesExportComponent {
  constructor(private modal: NzModalService){}
  @Output() cancel = new EventEmitter<void>();
 

  LeadOpportunityLExportmodal=true;
  LeadOpportunityExportModelCancel(): void {
  this.cancel.emit();
  }


}
