<nz-modal
  nzWidth="80%"
  [(nzVisible)]="visible"
  [nzTitle]="selectionModalTitle"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '75vh',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="selectionCancel()"
>
  <ng-template #selectionModalTitle>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0 fw-bold">Selection</h2>
    </div>
  </ng-template>

  <div class="content content-set-background" *nzModalContent>
    <div class="ng-container">
      <nz-card nzBorderless="true" nzTitle="Selection">
        <div class="row">
          <div class="col-6">
            <div class="col-12">
              <div class="row">
                <div class="col-10">
                  <label class="cityDropdown fw-bold">Title <span class="text-danger">*</span></label>
                  <input type="text" class="form-control formField" />
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-3">
                        <label class="cityDropdown" nz-checkbox
                          >Requare owner to make a selection</label
                        >
                </div>
              </div>
              <div class="row gx-0" >
                <div class="col-8 mt-4">
                    <label class="cityDropdown">Category </label>
        <nz-select
          style="width: 100%"
          nzShowSearch
          [lang]="true"
          nzAutoFocus="true"
          nzPlaceHolder="Unassigned"
          [(ngModel)]="selectedValue"
        >
          <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
          <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
          <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
        </nz-select>
       </div>
       <div class="col-3 mt-5 p-0">
          <button nz-button nzType="link" >Add</button>
          <button nz-button nzType="link" class="ps-0">Edit</button>
        </div>
                
              </div>
              <div class="row gx-0" >
                <div class="col-8 mt-4">
                    <label class="cityDropdown">Location </label>
        <nz-select
          style="width: 100%"
          nzShowSearch
          [lang]="true"
          nzAutoFocus="true"
          nzPlaceHolder="Unassigned"
          [(ngModel)]="selectedValue"
        >
          <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
          <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
          <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
        </nz-select>
       </div>
       <div class="col-3 mt-5 p-0">
          <button nz-button nzType="link" >Add</button>
          <button nz-button nzType="link" class="ps-0">Edit</button>
        </div>
                
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
                <div class="col-12 ">
                    <button nz-button>Single</button>
                    <button nz-button>Shared</button>
                </div>
            </div>
            <div class="row">
                <div class="col-10  mt-3">
                    <label class="sub-text cityDropdown">Single Allowance</label>
                    <nz-input-group nzAddOnBefore="$">
                      <input
                        nz-input
                        placeholder="0.00"
                        type="number"
                        [ngModel]="convertToRupees(addedCost)"
                        (ngModelChange)="updateConvertedValue($event)"
                      />
                    </nz-input-group>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mt-3">
                    <label class="cityDropdown fw-bold">Deadline </label><br>
                    <nz-button-group>
                        <button nz-button nzType="default" (click)="toggleDateFields()">Choose Date</button>
                        <button nz-button nzType="default" (click)="toggleScheduleItemFields()">Link to Schedule Item</button>
                      </nz-button-group>
                </div>
            </div>
            <div class="row  mt-4" *ngIf="showDateFields">
                <div class="col-3">
                  <label class="cityDropdown fw-bold">Deadline</label>
                  <input nz-input placeholder="Basic usage" [(ngModel)]="value" type="number" />
                </div>
          
                <div class="col-3">
                  <label class="cityDropdown fw-bold">Time</label><br>
                  <nz-time-picker class="formField" style="width: 150px; font-size: 22px;"  [(ngModel)]="time"
                  ></nz-time-picker>
                </div>
              </div>
              
              <div class="row  gx-0" *ngIf="showScheduleItemFields">
                <div class="col-9 mt-4">
                    <label class="cityDropdown">Category </label>
        <nz-select
          style="width: 100%"
          nzShowSearch
          [lang]="true"
          nzAutoFocus="true"
          nzPlaceHolder="Unassigned"
          [(ngModel)]="selectedValue"
        >
          <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
          <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
          <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
        </nz-select>
       </div>
       <div class="col-3 mt-5 p-0">
          <button nz-button nzType="link" >Add</button>
          <button nz-button nzType="link" class="ps-0">Edit</button>
        </div>
                
              </div>
              <div class="row  mt-3" *ngIf="showScheduleItemFields">
                <div class="col-3">
                  <label class=" fw-bold">Due</label>
                  <input type="text" class="form-control formField" placeholder="0">
                </div>
                <div class="col-5" style="margin-top: 27px;">
                  <label class=" fw-medium me-3" style="margin-left: -10px;">Days</label>
                  <nz-radio-group [(ngModel)]="radioValue" nzName="radiogroup">
                    <label nz-radio nzValue="A">Before</label>
                    <label nz-radio nzValue="B" style="margin-left: -5px;">After</label>
                  </nz-radio-group>
                </div>
              </div>
              <div class="row  mt-3 mb-4" *ngIf="showScheduleItemFields">
                <div class="col-2">
                  <label class=" fw-bold mb-1">DeadLine</label>
                  <p class="fw-bold">--</p>
                </div>
                <div class="col-3">
                  <label class="cityDropdown fw-bold">Time</label><br>
                  <nz-time-picker class="formField" style="width: 150px; font-size: 22px;"  [(ngModel)]="time"
                    ></nz-time-picker>
                </div>
              </div>


           


          </div>
        </div>
      </nz-card>
    </div>
    
  </div>
</nz-modal>