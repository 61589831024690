<nz-modal [(nzVisible)]="isVisibleInJobs" [nzTitle]="modalTitle" [nzFooter]="modalFooter" [nzBodyStyle]="{
  background: '#f1f4fa',
  padding: '14px',
  maxHeight: '70vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  }" [nzStyle]="{ top: '22px'}" [nzWidth]="'95%'" (nzOnCancel)="CancelJobs()">
  <ng-template #modalTitle>
    <div class="row">
      <h2 class="p-0 m-0 famly-inn">Jobs</h2>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <div class="card border-0 mt-0">
      <div class="card-head border-bottom">
        <div class="row pt-2 pb-2">
            <h3 class="ms-3 famly-inn">Defaults on New Jobs</h3>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-8">
                <label class="sub-text famly-inn"> Job Types:
                  <i class="bi bi-info-circle-fill text-secondary" nz-popover [nzPopoverContent]="JobTypes" nzPopoverPlacement="right"></i>
                  <ng-template #JobTypes>
                    <div class="rounded-2 famly-inn fs-6"><p style="height: auto; width: 210px;font-size: 13px;">What types of jobs do you do? Please select all that apply.</p></div>
                  </ng-template>
                </label>
                <nz-tree-select
                [(ngModel)]="test"
                style="width: 100%"
                [nzNodes]="JobGroupNodes"
                nzCheckAll="true"
                nzCheckable
                [nzAllowClear]="false"
                [nzShowSearch]="true"
                (ngModelChange)="onGroupSelect($event)"
                nzDropdownClassName="dropdownStyle"
              >
              </nz-tree-select>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="14">
                <label class="sub-text">Contract Type<span class="text-danger fw-bolder ms-1">*</span><i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="ContractTypeTemplate" nzPopoverPlacement="top"></i></label>
                <ng-template #ContractTypeTemplate>
                  <div class="rounded-2">
                    <p style="height: auto; width: 200px">
                      Buildertrend will create workflows to best fit your financial structure.
                    </p>
                  </div>
                </ng-template>
              </div>
            </div>
            <div nz-row class="pt-2 pb-1">
              <div nz-col nzSpan="24">
                <nz-radio-group [(ngModel)]="contractTyperadioValue" (ngModelChange)="contractTyperadioButtons()">
                  <div class="row">
                    <div class="col-12">
                      <label nz-radio nzValue="A"><strong class="famly-inn">Fixed Price</strong></label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label style="font-size: 14px; color: #202227;margin-left: 24px;" class="famly-inn">You will set the Contract Price for the owner.</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label nz-radio nzValue="B"><strong class="famly-inn">Open Book</strong></label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label style="font-size: 14px; color: #202227;margin-left: 24px;" class="famly-inn">Price will be determined by your actual costs plus markup/margin (includes Cost Plus and Time & Materials contracts).</label>
                    </div>
                  </div>
                </nz-radio-group>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12">
                <label (ngModelChange)="onBtSelect()" nz-checkbox [(ngModel)]="jobSetupFormFieldValues.includeTimeClockLaborInBudgetOnNewJobs" name="includeTimeClockLaborInBudgetOnNewJobs" class="famly-inn fs-6">Include Time Clock Labor in Budget on new jobs
                  <i class="bi bi-info-circle-fill text-secondary" nz-popover [nzPopoverContent]="IncludeTimeClock" nzPopoverPlacement="top"></i>
                  <ng-template #IncludeTimeClock>
                      <div class="rounded-2 famly-inn"><p style="height: auto; width: 210px;font-size: 13px;">A Time Clock Labor column will appear on the Budget and include labor costs based on user shifts</p></div>
                  </ng-template>
                </label>
                <ng-container *ngIf="btnTest === false; else BtnPrimary">
                  <button nz-button nzType="default" class="rounded-1">
                    Update Existing Jobs
                  </button>
                </ng-container>
                <ng-template #BtnPrimary>
                  <button nz-button nzType="primary" class="rounded-1">
                    Update Existing Jobs
                  </button>
                </ng-template>
              </div>
            </div>
            <ng-container *ngIf="btnTest === true;">
              <div class="row">
                <div class="col-12">
                  <label (ngModelChange)="onBtSelectSecond()"  nz-checkbox [(ngModel)]="jobSetupFormFieldValues.includeTimeClockLaborInTotalCostsAndPaidColumnsOnBudgetForNewJobs" name="includeTimeClockLaborInTotalCostsAndPaidColumnsOnBudgetForNewJobs" class="famly-inn fs-6">Include Time Clock Labor in Total Costs and Paid Columns on Budget for new jobs
                    <i class="bi bi-info-circle-fill text-secondary" nz-popover [nzPopoverContent]="IncludeTimeClockTotalCosts" nzPopoverPlacement="top"></i>
                    <ng-template #IncludeTimeClockTotalCosts >
                      <div class="rounded-2 famly-inn"><p style="height: auto; width: 210px;font-size: 13px;">
                        Checking this box will include the labor costs from time clock shift in both the total costs and paid columns on the budget for new jobs. This is recommended for users that do not use POs/Bills to track labor costs.
                      </p></div>
                    </ng-template>
                  </label>
                  <ng-container *ngIf="btnTestSecond === false; else BtnPrimarySecond">
                    <button nz-button nzType="default" class="rounded-1">
                      Update Existing Jobs
                    </button>
                  </ng-container>
                  <ng-template #BtnPrimarySecond>
                    <button nz-button nzType="primary" class="rounded-1">
                      Update Existing Jobs
                    </button>
                  </ng-template>
                </div>
              </div>
            </ng-container>
            <div class="row pt-2">
              <div class="col-12">
                <label nz-checkbox [(ngModel)]="jobSetupFormFieldValues.hideJobPriceSummaryForOwnersOnNewJobs" name="hideJobPriceSummaryForOwnersOnNewJobs" class="famly-inn fs-6">Hide Job Price Summary for owners on new jobs
                  <i
                  class="bi bi-info-circle-fill text-secondary"
                  nz-popover
                  [nzPopoverContent]="IncludeTimeClockSummary"
                  nzPopoverPlacement="top"></i>
                  <ng-template #IncludeTimeClockSummary>
                    <div class="rounded-2 famly-inn">
                      <p style="height: auto; width: 210px;font-size: 13px;">
                        This can be modified for individual jobs in Job Details
                      </p>
                    </div>
                  </ng-template>
                </label>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12">
                <label nz-checkbox [(ngModel)]="jobSetupFormFieldValues.defaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs" name="defaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs" class="famly-inn fs-6">Default To Allow Owner to "Peek" at Locked Selections For All New Jobs
                  <i
                  class="bi bi-info-circle-fill text-secondary"
                  nz-popover
                  [nzPopoverContent]="IncludeTimeClockLocked"
                  nzPopoverPlacement="top"></i>
                  <ng-template #IncludeTimeClockLocked>
                    <div class="rounded-2 famly-inn">
                      <p style="height: auto; width: 210px;font-size: 13px;">
                        This setting will allow the Owner To view "Locked" Selections On New jobs And can be Set from either the Job Setting Or Selections Setup pages. Allowing Owners To view "Locked" Selections can also be Set On an individual job basis On the Job Owner page.
                      </p>
                    </div>
                  </ng-template>
                </label>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12">
                <label nz-checkbox [(ngModel)]="jobSetupFormFieldValues.onNewJobsUpdateJobRunningTotalWhenASelectionIsApproved" name="onNewJobsUpdateJobRunningTotalWhenASelectionIsApproved" class="famly-inn  fs-6">On New Jobs Update Job Running Total When a Selection Is Approved
                  <i
                  class="bi bi-info-circle-fill text-secondary"
                  nz-popover
                  [nzPopoverContent]="IncludeTimeClocks"
                  nzPopoverPlacement="right"></i>
                  <ng-template #IncludeTimeClocks>
                    <div class="rounded-2 famly-inn">
                      <div style="height: auto; width: 210px;font-size: 13px;">
                        <div class="row mb-3">
                          <p>
                            This setting will be the Default For all Of your New Jobs. If CHECKED, the Job Running Total will update When either a Selection Or a Change Order Is approved throughout the job.
                          </p>
                        </div>
                        <div class="row mb-3">
                          <p>
                            If UNCHECKED, the Job Running Total will ONLY be updated by Approved Change Orders. You will need To create a Change Order To account For any selection allowance overages.
                          </p>
                        </div>
                        <div>
                          <p>
                            This setting can be changed For all New jobs In the Jobs Settings Setup Or Selections Setup.
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card border-0 mt-3">
      <div class="card-head border-bottom">
        <div class="row pt-2 pb-2">
            <h3 class="ms-3 famly-inn">Other Settings</h3>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <label nz-checkbox [(ngModel)]="jobSetupFormFieldValues.disablePOAndBudgetSharingForOwnersOnAllJobs" name="disablePOAndBudgetSharingForOwnersOnAllJobs" class="famly-inn fs-6">Disable PO and Budget sharing for owners on all jobs
                  <i
                  class="bi bi-info-circle-fill text-secondary"
                  nz-popover
                  [nzPopoverContent]="Thiswillprevent"
                  nzPopoverPlacement="top"></i>
                  <ng-template #Thiswillprevent>
                    <div class="rounded-2 famly-inn">
                      <p style="height: auto; width: 210px;font-size: 13px;">
                        This will prevent all existing and new jobs from sharing the Budget and PO pages with Owners.
                      </p>
                    </div>
              </ng-template>
                </label>
              </div>
            </div>
            <div class="row pt-1">
              <div class="col-12">
                <label nz-checkbox [(ngModel)]="jobSetupFormFieldValues.allowOwnerChangeOrderRequestsByDefault" name="allowOwnerChangeOrderRequestsByDefault" class="famly-inn fs-6">Allow Owner Change Order requests by default
            
                  <i
                  class="bi bi-info-circle-fill text-secondary"
                  nz-popover
                  [nzPopoverContent]="Thissettingcanbechanged"
                  nzPopoverPlacement="top"></i>
                  <ng-template #Thissettingcanbechanged>
                    <div class="rounded-2 famly-inn">
                      <p style="height: auto; width: 210px;font-size: 13px;">
                        This setting can be changed in the Job Settings Setup or the Change Order Setup.
                      </p>
                    </div>
              </ng-template>
                </label>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-4">
                <label class="fw-bold famly-inn fs-6" >Default Cost Setting
                  <i
                  class="bi bi-info-circle-fill text-secondary"
                  nz-popover
                  [nzPopoverContent]="settingcanbechangedAllowances"
                  nzPopoverPlacement="top"></i>
                  <ng-template #settingcanbechangedAllowances>
                    <div class="rounded-2 famly-inn">
                      <p style="height: auto; width: 210px;font-size: 13px;">
                        This will be the default cost setting for new
                         <span class="sub-text" >Allowances, Bid Packages, Change Orders, Owner Invoices, and Selection Choices.</span>
                      </p>
                    </div>
              </ng-template>
                </label>
                <nz-select nzShowSearch [lang]="true" [(ngModel)]="jobSetupFormFieldValues.costSettingId" name="costSettingId" nzAutoFocus="true" nzPlaceHolder="--None--" required>
                  <nz-option *ngFor="let option of defaultCost" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                </nz-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card border-0 mt-3">
      <div class="card-head border-bottom">
        <div class="row pt-2 pb-2">
            <h3 class="ms-3 famly-inn">Owner Invitation Email</h3>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="row mb-2">
              <div class="col-12">
                <nz-switch 
                  [(ngModel)]="jobSetupFormFieldValues.customizeOwnerEmailInviteText" 
                  name="customizeOwnerEmailInviteText" 
                  (click)="showConfirmToggle($event)">
                </nz-switch>
                <label class="ms-2 famly-inn">Customize owner email invite text</label>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <ng-container *ngIf="jobSetupFormFieldValues.customizeOwnerEmailInviteText; else viewOnly">
                  <kendo-editor [(ngModel)]="editorValue" [style]="{'flex-direction': 'column-reverse'}"></kendo-editor>
                </ng-container>
                <ng-template #viewOnly>
                  <div [innerHTML]="editorValue"></div>
                </ng-template>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <button nz-button nzType="default" (click)="emailSampleFormOpen()">Email Me a Sample</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card border-0 mt-3">
      <div class="card-head border-bottom">
        <div class="row pt-2 pb-2">
          <div class="col-6 d-flex justify-content-start">
            <h3 class="ms-3 famly-inn">Jobs (Job Info) Custom Fields</h3>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button nz-button nzType="primary" (click)="customFieldsFormOpen()">Add New Field</button>
          </div>
          </div>
        </div>
      </div>
      <div class="card-body" style="background: #fff">
        <div class="row">
          <div class="col-12">
             <nz-table nzFrontPagination="false" #basicTable [nzData]="listOfData" style="padding: 10px 20px 0px 20px">
              <thead>
                <tr>
                  <th></th>
                  <th>Label</th>
                  <th>Data Type</th>
                  <th>Display Order</th>
                  <th>Required</th>
                  <th>Include In Filters</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of basicTable.data">
                  <td></td>
                  <td><a href="">{{ data.name }}</a></td>
                  <td>{{ data.address }}</td>
                  <td>{{ data.age }}</td>
                  <td></td>
                  <td><i nz-icon [nzType]="data.icon" class="filter-icon"></i></td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
      </div>
  </div>
  <ng-template #modalFooter>
    <div nz-row class="p-1 justify-content-end">
      <div nz-col nzSpan="24">
        <nz-space>
          <ng-container *nzSpaceItem>
            <button
              nz-button
              nzType="primary"
              class="align-items-center"
              [nzLoading]="isSave"
              (click)="saveJobs()"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
              Save
            </button>
          </ng-container>
        </nz-space>
      </div>
    </div>
  </ng-template>
</nz-modal>














    


