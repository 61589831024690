<div nz-row>
  <div nz-col [nzSpan]="18" [nzPush]="6" class="text-end"><button nz-button nzType="default"
      class="me-2 rounded-1">Print All</button>
    <button   (click)="openFilter(PROJECT_MANG_SELEC_ALLOWA_UNASSIGN,PAGE_ID)" nz-button nzType="default" class="me-2 rounded-1"><i class="bi bi-funnel me-1 mt-3 "></i>Filter</button>
    <button nz-button nzType="primary" class="me-2 rounded-1">New Selection</button>
    <button nz-button nzType="primary" class="me-2 rounded-1" (click)="openAllowance()">New Allowance</button>
  </div>
  <div nz-col [nzSpan]="6" [nzPull]="18"> <label nz-checkbox>Select All</label></div>
</div>

<nz-card style="width:99%; margin-top: 12px;">
  <div class="row">
    <div class="col">
      <label nz-checkbox>
      <span class="text-primary ms-4 fw-bold">Feature Tiles</span></label>
    </div>
    <div class="col text-center fw-bold">
      Construction Admin
    </div>
    <div class="col text-end">
      <span class="fw-bold me-5">Price</span>
      <span class="fw-bold">Deadline</span>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col ms-3">
      <span class="text-primary ms-4 text-secondary ">10-Interiror finishes | Master Bath</span>
      <nz-tag [nzColor]="'cyan'" class="rounded-4 fw-bolder ms-2">Compelete</nz-tag>
    </div>
    <div class="col text-center">
      <span>-</span>
    </div>
    <div class="col text-end">
      <span class="sub-text" style="margin-right: 87px;">$0.00</span>
      <span class="sub-text">-</span>

    </div>
  </div>
</nz-card>


<nz-card style="width:99%; margin-top: 12px;padding: 0px;" [nzTitle]="Tilecustom" >



  <nz-collapse [nzBordered]="false">
    <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active">
      <nz-card style="width:99%; margin-top: 12px;">
        <div class="row">
          <div class="col">
            <label nz-checkbox>
              <span class="text-primary ms-4 fw-bold">Feature Tiles</span></label>
          </div>
          <div class="col text-center fw-bold">
            Construction Admin
          </div>
          <div class="col text-end">
            <span class="fw-bold me-5">Price</span>
            <span class="fw-bold">Deadline</span>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col ms-3">
            <span class="text-primary ms-4 text-secondary ">10-Interiror finishes | Master Bath</span>
            <nz-tag [nzColor]="'cyan'" class="rounded-4 fw-bolder ms-2">Compelete</nz-tag>
          </div>
          <div class="col text-center">
            <span>-</span>
          </div>
          <div class="col text-end">
            <span class="sub-text" style="margin-right: 87px;">$0.00</span>
            <span class="sub-text">-</span>

          </div>
        </div>
      </nz-card>
    </nz-collapse-panel>
  </nz-collapse>
</nz-card>

<ng-template #Tilecustom>
  <div class="row">
    <div class="col">
      <label nz-checkbox>
        <span class="text-primary ms-5 fw-bold">haseeb</span></label>
      <nz-tag [nzColor]="'cyan'" class="rounded-4 fw-bolder ms-2 fs-8 mb-4">Compelete</nz-tag>
    </div>
    <div class="col text-end">
      <span class="para-lable me-2 fs-9">Diffrence</span><span >:0.00$</span>
      <span class="para-lable me-2 fs-9">Price</span><span >:0.00$</span>
      <span class="para-lable me-2 fs-9">Allowance</span><span >:0.00$</span>

    </div>
  </div>
</ng-template>