<nz-modal class="shadow" nzWidth="90%" [(nzVisible)]="dailyLogsVisible" [nzTitle]="modalTitle"
  [nzFooter]="LeadOpportunitySettingmodalFooter"
  [nzBodyStyle]="{background: '#f1f4fa',padding: '13px','max-height': '78vh','overflow-x': 'hidden',}"
  [nzStyle]="{ top: '15px' }" (nzOnCancel)="ClosedailyLogInformation()">
  <ng-template #modalTitle>
    <div class="row" *ngIf="leadData?.leadData?.name">
      <h5 class="ListViewJobname p-0 m-0">{{ leadData?.leadData?.name }}</h5>
    </div>
    <div class="row" *ngIf="JobData?.JOB_NAME">
      <h5 class="ListViewJobname p-0 m-0">{{ JobData?.JOB_NAME }}</h5>
    </div>
    <div class="row">
      <h1 class="p-0 m-0 famly-inn">Daily Log</h1>
    </div>
  </ng-template>
  <div class="content " *nzModalContent>
    <div class="row famly-inn gx-3">
      <div class="col-5">
        <div class="card border-0 mt-0">
          <div class="card-head border-bottom">
            <div class="row">
              <div class="col-6">
                <h2 class="ms-3" nz-typography>Daily Log Information</h2>
              </div>
              <div class="col-6 text-end">
                <img nz-popover [nzPopoverContent]="Summary" nzPopoverPlacement="bottom" (click)="map()"
                  class="ViewableByIcon me-3" src="assets/ProjectManagerSitesDiaries/map-marker-default.svg"
                  alt="Open in Maps" style="height: 18px; width: 16px;">
                <ng-template #Summary>
                  <div class="rounded-2">
                    <p style="font-size: 13px;">
                      Open in Map
                    </p>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <label class="sub-text">Job</label>
                <nz-select name="jobInformationId" [(ngModel)]="formFieldValues.jobInformationId" nzShowSearch
                  nzAutoFocus="true" nzPlaceHolder="Select Lead" (ngModelChange)="onLeadSelect($event)">
                  <nz-option class="p-0 m-0" *ngFor="let option of jobList" [nzLabel]="option.name"
                    [nzValue]="option.id"></nz-option>
                </nz-select>
              </div>
              <div class="col-12">
                <div class="row">
                  <label class="sub-text">Date<span class="fw-bolder text-danger">*</span></label>
                </div>
                <nz-date-picker [(ngModel)]="formFieldValues['date']" name="date"
                  (ngModelChange)="onChange($event)"></nz-date-picker>
              </div>
              <div class="col-12">
                <label class="sub-text">Title</label>
                <input [(ngModel)]="formFieldValues['title']" name="title" type="text" class="formField">
              </div>
            </div>
            <div class="row gx-1">
              <div class="col-10">
                <label class="sub-text">Tags</label>
              </div>
              <div class="col-1"></div>
              <div class="col-1"></div>
            </div>
            <div class="row gx-1">
              <div class="col-10">
                <nz-tree-select style="width: 100%" [nzVirtualHeight]="'250px'"
                  [(ngModel)]="formFieldValues.createDailyLogTagParameterRequests"
                  name="createDailyLogTagParameterRequests" [nzNodes]="tagNodes" nzShowSearch nzAllowClear="false"
                  nzCheckable nzCheckAll="true" nzPlaceHolder="Select Status"></nz-tree-select>
              </div>
              <div class="col-1">
                <button class="btn btn-sm text-primary" (click)="showTags()">Add</button>
              </div>
              <div class="col-1">
                <button
                  [disabled]="!formFieldValues['createDailyLogTagParameterRequests'] || formFieldValues['createDailyLogTagParameterRequests'].length !== 1"
                  class="btn btn-sm text-primary border-0 me-1"
                  (click)="showTagEdit(formFieldValues['createDailyLogTagParameterRequests'],'createDailyLogTagParameterRequests')">Edit</button>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-0 mt-3">
          <div class="card-head border-bottom">
            <div class="row">
              <h2 class="ms-3" nz-typography>Permissions</h2>
            </div>
          </div>
          <div class="card-body">
            <div class="row" style="padding: 0px 10px 0px 10px;">
              <div class="col-12">
                <div class="row">
                  <div class="col-6"></div>
                  <div class="col-2">
                    <span>Share</span>
                  </div>
                  <div class="col-4"></div>
                </div>
                <div class="row justify-content-center align-items-center d-flex mt-2"
                  style="background-color: #f1f4fa;">
                  <div class="col-6 pt-1 pb-1">
                    <img src="assets/ProjectManagerSitesDiaries/userIcon.png" alt="" width="15" class="Icon">
                    <span> Internal Users </span>
                  </div>
                  <div class="col-2 pt-!">
                    <label nz-checkbox [(ngModel)]="formFieldValues['isPermissionInternalUser']"
                      name="isPermissionInternalUser" (change)="onInternal()" [ngModel]="internal"></label>
                  </div>
                  <div class="col-4"></div>
                </div>
                <div class="row justify-content-center align-items-center d-flex mt-2">
                  <div class="col-6 pt-1 pb-1">
                    <img src="assets/ProjectManagerSitesDiaries/subIcon.png" alt="" width="15" class="Icon">
                    <span> Subs/Vendors </span>
                  </div>
                  <div class="col-2 pt-!">
                    <label nz-checkbox [(ngModel)]="formFieldValues['isPermissionSubVendor']"
                      name="isPermissionSubVendor" (change)="onSubsVendors()" [ngModel]="subsVendors"></label>
                  </div>
                  <div class="col-4"></div>
                </div>
                <div class="row justify-content-center align-items-center d-flex mt-2"
                  style="background-color: #f1f4fa">
                  <div class="col-6 pt-1 pb-1">
                    <img src="assets/ProjectManagerSitesDiaries/ownerIcon.png" alt="" width="15" class="Icon">
                    <span> Owner </span>
                  </div>
                  <div class="col-2 pt-!">
                    <label nz-checkbox [(ngModel)]="formFieldValues['isPermissionOwner']" name="isPermissionOwner"
                      (change)="onOwner()" [ngModel]="owner"></label>
                  </div>
                  <div class="col-4"></div>
                </div>
                <div class="row justify-content-center align-items-center d-flex mt-2">
                  <div class="col-6 pt-1 pb-1">
                    <img src="assets/ProjectManagerSitesDiaries/iconDailyLogPrivate.png" alt="" width="15" class="Icon">
                    <span> Private </span>
                  </div>
                  <div class="col-2 pt-!">
                    <label nz-checkbox [(ngModel)]="formFieldValues['isPermissionPrivate']" name="isPermissionPrivate"
                      (change)="onPrivate()" [ngModel]="privateChecked"></label>
                  </div>
                  <div class="col-4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-0 mt-3">
          <div class="card-head border-bottom py-2">
            <div class="row">
              <div class="col">
                <span class="fw-medium ms-2"><strong>Notify Users</strong>
                  <i class="bi bi-info-circle-fill text-secondary ms-2" nz-popover [nzPopoverContent]="Daily"
                    nzPopoverPlacement="top"></i>
                  <ng-template #Daily>
                    <div class="rounded-2">
                      <p style="height: 130px; width: 210px; font-size: 13px;">
                        Users who have notifications disabled will not show
                      </p>
                    </div>
                  </ng-template>

                </span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row gx-1">
              <div class="col-12">
                <nz-tree-select [disabled]="privateChecked" [nzNodes]="combinedNodes" [nzVirtualHeight]="'250px'"
                  [(ngModel)]="formFieldValues.createDailyLogNotifyUserRequest" name="createDailyLogNotifyUserRequest"
                  nzAllowClear="false" nzCheckable nzCheckAll="true" (ngModelChange)="($event)"></nz-tree-select>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-0 mt-3">
          <div class="card-head border-bottom">
            <div class="row">
              <div class="col-6">
                <h2 class="ms-3 mt-2" nz-typography>Related To-Do's</h2>
              </div>
              <div class="col-6 text-end" *ngIf="isRowVisible">
                <button (click)="RelatedModelOpen()" nz-button nzType="default" class="rounded-1 me-2">New
                  To-Do</button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row gx-1" *ngIf="!isRowVisible">
              <div class="col-12">
                <div class="row">
                  <label class="sub-text">To-Do's available after save</label>
                </div>
                <button (click)="RelatedModelOpen()" nz-button nzType="default" class="rounded-1 me-0">Save and Create
                  To-Do</button>
              </div>
            </div>
            <div class="row" *ngIf="isRowVisible">
              <div class="col-12">
                <div class="row">
                  <div class="col-12 d-flex align-content-center justify-content-center">
                    <span role="img" size="66" class="" style="font-size: 66px;"><svg width="1em" height="1em"
                        viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                        focusable="false" class="">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M22.9933 2.88338C22.9355 2.38604 22.5128 2 22 2C21.4477 2 21 2.44772 21 3V3.999H17V3L16.9933 2.88338C16.9355 2.38604 16.5128 2 16 2C15.4477 2 15 2.44772 15 3V3.999H11V3L10.9933 2.88338C10.9355 2.38604 10.5128 2 10 2C9.44772 2 9 2.44772 9 3V3.999L7 4L6.85074 4.00549C5.81588 4.08183 5 4.94564 5 6V25L5.0049 25.1996C5.10892 27.316 6.8578 29 9 29H23L23.1996 28.9951C25.316 28.8911 27 27.1422 27 25V6L26.9945 5.85074C26.9182 4.81588 26.0544 4 25 4L23 3.999V3L22.9933 2.88338ZM7 6L9 5.999V7L9.00673 7.11662C9.06449 7.61396 9.48716 8 10 8C10.5523 8 11 7.55228 11 7V5.999H15V7L15.0067 7.11662C15.0645 7.61396 15.4872 8 16 8C16.5523 8 17 7.55228 17 7V5.999H21V7L21.0067 7.11662C21.0645 7.61396 21.4872 8 22 8C22.5523 8 23 7.55228 23 7V5.999L25 6V25L24.9945 25.1493C24.9182 26.1841 24.0544 27 23 27H9L8.85074 26.9945C7.81588 26.9182 7 26.0544 7 25V6ZM17.2076 17.7927C17.5678 18.1534 17.5952 18.7207 17.2899 19.1127L17.2066 19.2069L13.7016 22.7069C13.3408 23.0672 12.7733 23.0944 12.3812 22.7888L12.2871 22.7055L10.292 20.7055C9.90198 20.3145 9.90276 19.6813 10.2938 19.2913C10.6547 18.9312 11.222 18.9042 11.6139 19.2097L11.708 19.293L12.996 20.5843L15.7934 17.7917C16.1842 17.4014 16.8174 17.4019 17.2076 17.7927ZM17.2899 13.1127C17.5952 12.7207 17.5678 12.1534 17.2076 11.7927C16.8174 11.4019 16.1842 11.4014 15.7934 11.7917L12.996 14.5843L11.708 13.293L11.6139 13.2097C11.222 12.9042 10.6547 12.9312 10.2938 13.2913C9.90276 13.6813 9.90198 14.3145 10.292 14.7055L12.2871 16.7055L12.3812 16.7888C12.7733 17.0944 13.3408 17.0672 13.7016 16.7069L17.2066 13.2069L17.2899 13.1127Z">
                        </path>
                      </svg></span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 d-flex align-content-center justify-content-center">
                    <h2 nz-typography>No Related To-Do's</h2>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12 d-flex align-content-center justify-content-center">
                    <label class="para-label">To-do's can help you and your team stay on track, and get</label>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12 d-flex align-content-center justify-content-center">
                    <label class="para-label">work done faster.</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-7">
        <!-- <div class="card border-0 mt-0">
          <div class="card-head border-bottom"> 
            <div class="row">
              <h2 nz-typography><span class="ms-3"></span>Attachments</h2>
            </div>
          </div>
          <div class="card-body" *ngIf="!attachments || attachments.length === 0">
            <div class="row">
              <div class="col-12">
                <button (click)="UploadFiles()" nz-button nzType="default" class="rounded-1 me-2">Add</button>
                <button (click)="CreateNewFile()" nz-button nzType="default" class="rounded-1 me-2">Create New File</button>
                <button (click)="viewAll()" *ngIf="selectedFiles.length > 0" nz-button nzType="default" class="rounded-1 me-2">View All ({{ selectedFiles.length }})</button>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-1 filUpload me-3" *ngFor="let file of selectedFiles">
                <div class="row gx-0 mt-2 p-0 m-0">
                  <div class="col-12 mt-2">
                    <span class="m-0 p-0d d-flex align-items-center justify-content-center" style="border-radius: 6px; object-fit: cover;" nz-icon nzType="file-text" nzTheme="outline"></span>
                  </div>
                </div>
                <div class="row p-0 m-0">
                  <div class="col-12">
                    <label class="ListViewJobname">{{file.name}}</label>
                  </div>
                </div>
                <div class="row p-0 m-0">
                  <div class="col-12">
                    <i nzTrigger="click" nzType="down" nz-dropdown [nzDropdownMenu]="menu4" class="fa-solid fa-chevron-down fw-bolder"></i>
                    <nz-dropdown-menu #menu4="nzDropdownMenu">
                      <ul nz-menu>
                        <li nz-menu-item><i class="fa-solid fa-pen me-2"></i>Edit Online</li>
                        <li (click)="removeFile(file)" nz-menu-item><i class="fa-regular fa-trash-can me-2"></i>Delete</li>
                      </ul>
                    </nz-dropdown-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" *ngIf="attachments && attachments.length > 0">
            <div class="d-flex align-items-center mt-2">
              <div class="row">
                <div class="col-5">
                  <button (click)="UploadFiles()" nz-button nzType="default" class="rounded-1 me-2">Add</button>
                  <button (click)="CreateNewFile()" nz-button nzType="default" class="rounded-1 me-2">Create New File</button>
                  <button (click)="viewAll()" *ngIf="attachments.length > 0" nz-button nzType="default" class="rounded-1 me-2">
                    View All ({{ attachments.length }})
                  </button>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-3 attachment-column" *ngFor="let attachmentParameter of dailyLogResponse.attachment.attachmentParameters">
                <nz-card class="attachment-card-item"
                [ngClass]="{
                    'pdf-color': attachmentParameter.extension === '.pdf',
                    'excel-color': attachmentParameter.extension === '.xlsx' || attachmentParameter.extension === '.xls',
                    'word-color': attachmentParameter.extension === '.docx' || attachmentParameter.extension === '.doc'
                }">
                  <div class="row gx-0 mt-2 p-0 m-0">
                    <div class="col-12 mt-2">
                      <span class="m-0 p-0 d-flex align-items-center justify-content-center"
                            style="border-radius: 6px; object-fit: cover;" nz-icon nzType="file-text" nzTheme="outline"></span>
                    </div>
                  </div>
                  <div class="row p-0 m-0">
                    <div class="card-body col-12 ms-3 mt-2 me-4 ">
                      {{ attachmentParameter.name }}
                      <i nzTrigger="click" nzType="down" nz-dropdown [nzDropdownMenu]="menu4"
                        class="fa-solid fa-chevron-down fw-bolder"></i>
                      <nz-dropdown-menu #menu4="nzDropdownMenu">
                        <ul nz-menu>
                          <li nz-menu-item><i class="fa-solid fa-pen me-2"></i>Edit Online</li>
                        </ul>
                      </nz-dropdown-menu>
                    </div>
                  </div>
                </nz-card>
              </div>
            </div>
          </div>
        </div> -->

        <!-- A** -->
        <div class="card border-0 mt-3">
          <div class="card-head border-bottom">
            <h3 class="p-0 m-0 pt-2 pb-2 ms-3 react" nz-typography>Attachments</h3>
          </div>
          <div class="card-body bt-file-viewer-card">
            <div class="row">
              <div class="col-12">
                <button nz-button class="button-default react" (click)="uploadFiles()"><span>Add</span></button>
                <button nz-button class="button-default react ms-2" (click)="CreateNewFile()"><span>Create New
                    File</span></button>
                <button *ngIf="selectedFiless?.length > 0" nz-button class="button-default react ms-2"
                  (click)="viewAll()"><span>View All ({{ selectedFiless?.length }})</span></button>
              </div>
            </div>
            <i *ngIf="showNavButtons" class="bx bx-chevron-left carousel-nav prev" (click)="previous()"></i>
            <i *ngIf="showNavButtons" class="bx bx-chevron-right carousel-nav next" (click)="next()"></i>
            <div class="carousel-container" #carouselContainer>
              <div class="carousel-view" *ngFor="let fileq of selectedFiless">
                <div class="bt-file-viewer">
                  <img class="img-1" *ngIf="getFileIcon(fileq)" [src]="getFileIcon(fileq)" />
                  <img (click)="onClick()" class="img-2" *ngIf="!getFileIcon(fileq) && !fileq.type.includes('video')"
                    [src]="fileq.thumbUrl" />
                  <ng-container *ngIf="fileq.type.includes('video')">
                    <div class="video-container">
                      <i nz-icon nzType="play-circle" nzTheme="outline" class="video-play-icon"
                        (click)="playVideo(fileq)"></i>
                      <video #videoPlayer id="video-{{ fileq.id }}" [src]="fileq.thumbUrl"
                        (click)="playVideo(fileq)"></video>
                    </div>
                  </ng-container>
                  <div nz-row class="action-row">
                    <div nz-col nzSpan="24">
                      <label class="BTFileUpload-SecondaryText p-0 m-0 ms-2">{{ fileq.name }}</label>
                    </div>
                    <div nz-col nzSpan="5">
                      <i style="margin: 2px 0px 0px 6px;cursor: pointer;font-size: 21px;color: #626262;"
                        nzTrigger="click" nz-dropdown [nzDropdownMenu]="menu4" class="bx bxs-chevron-down"></i>
                      <nz-dropdown-menu #menu4="nzDropdownMenu">
                        <ul nz-menu style="width: 170px;">
                          <li nz-menu-item (click)="removeFile(fileq)">
                            <div class="dropdown-container">
                              <i style="cursor: pointer;font-size: 20px;color: #626262;" class='bx bx-trash'></i>
                              <span class="react">Delete</span>
                            </div>
                          </li>
                          <li nz-menu-item (click)="uploadFilesUpdate(fileq)" *ngIf="isImageFile(fileq)">
                            <div class="dropdown-container">
                              <i style="cursor: pointer;font-size: 20px;color: #626262;" class='bx bxs-pencil'></i>
                              <span class="react">Edit Online</span>
                            </div>
                          </li>
                        </ul>
                      </nz-dropdown-menu>
                    </div>
                    <div nz-col nzSpan="4" *ngIf="isImageFile(fileq)">
                      <i (click)="uploadFilesUpdate(fileq)" style="cursor: pointer;font-size: 26px;color: #626262;"
                        class='bx bxs-pencil'></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- A** End -->




        <nz-card nzTitle="Notes" class="mb-3 mt-3">
          <div class="card-body">
            <div class="row gx-1">
              <div class="col-12">
                <textarea [(ngModel)]="formFieldValues['notes']" name="notes" style="border-radius: 5px;"
                  class="formField" nz-input></textarea>
              </div>
            </div>
          </div>
        </nz-card>


        
        <nz-card nzTitle="Weather" class="mt-3 mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-9 d-flex align-items-center">
                <!-- <label (change)="checkGetWeather()" nz-checkbox [(ngModel)]="formFieldValues['isIncludeWeatherConditions']" name="isIncludeWeatherConditions">Include Weather Conditions</label>  -->


                <label nz-checkbox [(ngModel)]="formFieldValues['isIncludeWeatherConditions']"
                  (ngModelChange)="checkGetWeather()">
                  Include Weather Conditions
                </label>
              </div>
            </div>

            <!-- A** -->
            <div class="row ms-1" *ngIf="formFieldValues['isIncludeWeatherConditions'] === true && weatherData">
              <div class="col-12">
                <div nz-row>
                  <div nz-col nzSpan="10" class="mt-2">
                    Weather in {{ weatherData?.current?.condition?.text }}
                  </div>
                  <div nz-col nzSpan="12" class="text-end">
                    {{ weatherData?.location?.localtime }}
                  </div>
                </div>
                <div nz-row>
                  <div nz-col nzSpan="10">
                    <div nz-row>
                      <div nz-col>
                        <img class="WeatherImage"
                          [src]="weatherData?.current?.condition?.icon ? 'https:' + weatherData?.current?.condition?.icon : 'assets/ProjectManagerSitesDiaries/rainyx50.png'"
                          alt="{{weatherData?.current?.condition?.text}}"
                          style="height: 50px; margin-right: 16px; width: auto;">
                      </div>
                      <div nz-col>
                        <div nz-row style="row-gap: 0px;">
                          <div nz-col>
                            <strong style="font-size: 1.5em; font-weight: 700;">
                              {{ weatherData?.current?.temp_c }} °C
                            </strong>
                          </div>
                        </div>
                        <div nz-row style="row-gap: 0px;">
                          <div nz-col>
                            <strong style="font-size: 1.5em; font-weight: 700;">
                              {{ weatherData?.current?.feelslike_c }} °C
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div nz-col nzSpan="12">
                    <div nz-row>
                      <div nz-col>
                        Wind Speed: {{ weatherData?.current?.wind_kph }} km/h
                      </div>
                    </div>
                    <div nz-row>
                      <div nz-col>
                        Humidity: {{ weatherData?.current?.humidity }}%
                      </div>
                    </div>
                    <div nz-row>
                      <div nz-col>
                        Total Precipitation: {{ weatherData?.current?.precip_mm }} mm
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <!-- A** -->

            <div class="row mt-3">
              <div class=" col-9 d-flex align-items-center">
                <!-- <label (change)="onCheckboxChange2()"  nz-checkbox [(ngModel)]="formFieldValues['isIncludeWeatherNotes']" name="isIncludeWeatherNotes"> <span class="pira-label">Include Weather Notes</span></label> -->
                <label nz-checkbox [(ngModel)]="formFieldValues['isIncludeWeatherNotes']" name="isIncludeWeatherNotes"
                  (ngModelChange)="onCheckboxChange2()">
                  <span class="pira-label">Include Weather Notes</span>
                </label>
              </div>
            </div>
            <div class="row mt-2" *ngIf="isCheckboxChecked2">
              <div class="col-12">
                <textarea [(ngModel)]="formFieldValues['weatherNotes']" name="weatherNotes" style="border-radius: 5px;"
                  class="formField" nz-input></textarea>
              </div>
            </div>
          </div>
        </nz-card>

        <nz-card nzBorderless="true" [nzTitle]="messagingtitle" *ngIf="mainSaveResponseId === -1">
          <ng-template #messagingtitle>
            <h5 class="pira-label">Messaging</h5>
          </ng-template>
          <div class="row">
            <div class="col">
              <h5 class="pira-label">Message available after save</h5>
            </div>
          </div>
        </nz-card>

        <!-- sufyan working add -->
        <!-- new -->
        <ng-container *ngIf="mainSaveResponseId !== -1">
          <messaging-common [Job_ID]="jobID" [Form_Name_ID]="formNameID" [Message_header_ID]="mesageingHeadId">
          </messaging-common>
        </ng-container>
        <!-- new -->
        <!-- sufyan working add -->


      </div>
    </div>
  </div>
  <ng-template #LeadOpportunitySettingmodalFooter>
    <button button nz-button nzType="text" class="button-Daily"><span>Save & New</span></button>
    <button button nz-button nzType="text" class="button-Daily ms-2" (click)="saveButton()"><span>Save</span></button>
    <button button nz-button nzType="text" class="button-Daily ms-2"><span>Save & Close</span></button>
    <button nz-button nzType="primary" class="rounded-1 me-0" *ngIf="mainSaveResponseId"
      (click)="createMessage()">Message</button>
  </ng-template>
</nz-modal>


<!-- ___________________Abdul rehman Tag____________________ -->
<nz-modal nzWidth="35%" [(nzVisible)]="jobGroupVisible" [nzTitle]="warrantyModalTitle" [nzFooter]="warrantyModalTitle1"
  [nzBodyStyle]="{background: '#f1f4fa',padding: '14px','max-height': '75vh','overflow-x': 'hidden'}"
  [nzStyle]="{ top: '20px' }" (nzOnCancel)="secondModelcancel()">
  <ng-template #warrantyModalTitle>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0">Add Daily Log Tag</h1>
    </div>
  </ng-template>
  <form [formGroup]="TagsForm">
    <div class="content" *nzModalContent>
      <div class="card border-0 mt-0">
        <div class="card-head border-bottom">
          <div class="row py-2">
            <h3 class="ms-3" nz-typography>Add Daily Log Tag</h3>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <label class="sub-text">Title<span class="fw-bolder text-danger">*</span> </label>
              <input id="inputField" formControlName="name" type="text" class="form-control formField" [ngClass]="{
              error:
                TagsForm.get('name')?.invalid &&
                TagsForm.get('name')?.touched
            }" />
              <div *ngIf="
              TagsForm.get('name').hasError('required') &&
              TagsForm.get('name').touched
            " class="text-danger p-error">
                Required
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #warrantyModalTitle1>
      <button *ngIf="isLoadingEdit" nz-button nzType="default" class="rounded-1 me-0" (click)="ConfirmDeleteTags()">
        Delete
      </button>

      <button nz-button nzType="primary" class="rounded-1 me-0" (click)="SaveOrUpdateData()">
        {{ isLoadingEdit ? "Update" : "Save" }}
      </button>
    </ng-template>
  </form>
</nz-modal>
<!-- ___________________Abdul rehman Tag____________________ -->