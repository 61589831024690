import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-job-price-summary',
  templateUrl: './job-price-summary.component.html',
  styleUrls: ['./job-price-summary.component.css']
})
export class JobPriceSummaryComponent {
  @Output() cancel = new EventEmitter<void>();


  JobCreateNewScratchModal = true;
  CancelJobScartch(){
  this.cancel.emit();
  this.JobCreateNewScratchModal = false;

  }
  listOfData = [
    {
      key: '1',
      namee: 'Initial Deposit',
      agee: '$272,000.00',
      addresse: 'Tue, Jun 29, 2021',
      name: 'Payments Received Subtotal',
      age: '$272,000.00',
    },
  ]
  
}
