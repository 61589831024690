import { Component, Input, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ApplicationUserResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { InternalPaginatorService } from 'src/Service/Internaluser/internal-paginator.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { AddNewGridStandardViewComponent } from '../../component/Modal/add-new-grid-standard-view/add-new-grid-standard-view.component';
import { StandardviewColumnSetupService } from 'src/Service/Internaluser/LookupStandardViewService/standardview-column-setup.service';
import { StandardColumnSetupResponse, StandardView, StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dashboard-pages-footer',
  templateUrl: './dashboard-pages-footer.component.html',
  styleUrls: ['./dashboard-pages-footer.component.css']
})
export class DashboardPagesFooterComponent implements OnInit {
  @Input() page_id: number;
  constructor(private loadingIndicateService: LoadingIndicatorService, private fb: FormBuilder, private modal: NzModalService,
    private Standardviewervice: StandardviewColumnSetupService) {

  }
  ngOnInit(): void {
    this.initLoad();
    this.getStandardViewByFormPageId(this.page_id);
  }
  selectedValueControl: any;
  getInternalUserData: ApplicationUserResponse[] = [];
  StandardData: any[] = [];
  internalUserStandardViewForm: FormGroup;
  StandardViewResponseData: StandardViewResponse[] = [];

  onFilterChange(event: Event) {
    const selectedFilter = (event.target as HTMLSelectElement).value;
  }


  initializeForm(): void {
    this.internalUserStandardViewForm = this.fb.group({
      name: [''],
    });
  }

  showNewInternaStandardView() {
    const modalRef = this.modal.create({
      nzContent: AddNewGridStandardViewComponent,
      nzFooter: null,
      nzData: {
        pageId: this.page_id,
        StandardViewResponseData: this.StandardViewResponseData,
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }



  getStandardViewByFormPageId(pageId: number) {
    this.Standardviewervice.getStandardViewByFormPageId(pageId);
    this.Standardviewervice.$getStandardView.subscribe(result => {
      if (result) {
        this.StandardViewResponseData = result.result;
        this.initializeForm();
        // this.setDefaultFieldValues(this.defaultStandardFilter.id);
      }

    });
  }

  fetchData(lookUpStandard: StandardView): void {
    this.Standardviewervice.postData(lookUpStandard).subscribe(
      data => {
        this.StandardViewResponseData = data.result;
      },
      error => {
        console.error('Error fetching data:', error);
      }
    );
  }
  initLoad() {
    const lookupFilter: StandardView = {
      formNameId: this.page_id,
    };
    this.getStandardViewByFormPageId(this.page_id);
    this.fetchData(lookupFilter);
  }
}





