<div class="row mt-5" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <div class="col" style="text-align: center;">
        <img src="assets/Empty-SVGs/Financial/Owner-invoice/creditMemos.svg" >

       
        <div class="row">
            <div class="col mt-2">
        <h2 class="size">No Credit Memos Available.</h2>
            </div>
        </div>
        <!-- <div class="col mt-3 text-secondary">
        <p>Track and manage your receipts in one place</p>
        </div> -->
        <div class="col mt-4" style="display: flex; justify-content: center; align-items: center;">
            <button nz-button nzType="primary" style="margin-right: 10px;" >Add a Credit Memo</button>
        </div>
    </div>
  
  </div>