import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileNameSetup } from 'src/Models/CompanySettings/Selections/FileNameSetup';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class FileNameSetupService {


  private baseUrl: string = environment.apiUrl + 'FileNameSetup/';


  constructor(private http: HttpClient) {}

  postData(data: FileNameSetup): Observable<FileNameSetup> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }

  getData(): Observable<FileNameSetup[]> {
    return this.http.get<FileNameSetup[]>(`${this.baseUrl}getAll`);
  }

  updateData(data: FileNameSetup): Observable<FileNameSetup> {
    return this.http.put<FileNameSetup>(`${this.baseUrl}`, data);
  }

  deleteData(id: number): Observable<FileNameSetup> {
    return this.http.delete<FileNameSetup>(
      `${this.baseUrl}?globalId=${id}`
    );
  }
  getDataId(id: number): Observable<FileNameSetup> {
    return this.http.get<FileNameSetup>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }
}