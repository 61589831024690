import { AccessLocalStorageService } from './../../../../../Service/access-local-storage.service';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { ProjectManagementSelectDialogBoxComponent } from '../../project-management-select-dialog-box/project-management-select-dialog-box.component';
import { JobInfoComponentInformation } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { FilterSearchParameter, LookupFilterResponse } from 'src/app/shared/component/Models/LookupModels';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { StandardColumnSetupResponse, StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';
import { ColumnsResizePinProperties } from 'src/Models/InternalUser/internalUserColumnsPinResize';
import { AddNewGridStandardViewComponent } from 'src/app/shared/component/Modal/add-new-grid-standard-view/add-new-grid-standard-view.component';
import { ChangeOrderInformationResponse, } from 'src/Models/ChangeOrder/ChangeOrder';
import { ChangeOrderComponent } from '../change-order/change-order.component';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { Subscription } from 'rxjs';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { ResponseModel, TablePageResponse } from 'src/Models/responseMessage.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { BehaviorsubjectService } from 'src/Service/behaviorsubject/behaviorsubject.service';

@Component({
  selector: 'app-project-management-variations-db',
  templateUrl: './project-management-variations-db.component.html',
  styleUrls: ['./project-management-variations-db.component.css']
})
export class ProjectManagementVariationsDbComponent implements OnInit  , OnDestroy {
  private ChangeOrdersubscription: Subscription;
  private subscriptions: Subscription[] = [];
  PROJECT_MANG_VARIATION: string = FiltersName.PROJECT_MANG_VARIATION;
  @Output() onSaveComplete = new EventEmitter();
  PAGE_ID: number = -1;
  isLoading : boolean = false;
  GetProjectVariationgriddata: ChangeOrderInformationResponse[] = [];
  jobInfoOptions: JobInfoComponentInformation[] = [];
  lookupFilterResponse: LookupFilterResponse[] = [];
  getInternalUserDataResponseModelArray: ResponseModel<TablePageResponse<ChangeOrderInformationResponse>>;
  getStandardViewFilterData: StandardViewResponse[] = [];
  selected_View_Columns: string[] = [];
  getColumnWidthSize: number[] = [];
  expandedRows: number[] = [];
  columnSizes: { columnName: string, widthSize: number }[] = [];
  getStandardViewColumnFilterData: StandardColumnSetupResponse[] = [];
  selectedColNameSizePin: ColumnsResizePinProperties[] = [];
  displayedColumns: string[] = [];
  isColumnSticky: boolean[] = new Array(this.displayedColumns.length).fill(false);
  fixedSelectedColumnNames: string[] = [];
  selectedValueControl: any;
  selectedFilter: number = -1;
  isFilterApplied: boolean = false;
  defaultFilterIdSelected: number = 0;
  currentPage = 1;
  pageSize = 10;
  totalRecords = 100;
  isCollapsed = false;
  selectedJobId: number = 0;
  companyNameInvalid: boolean = false;
  expand: boolean = false;
  selection: any [];
  showErrorCards: boolean = false;
  selectionFrom: string = 'DB';
  filterSearchParameter: FilterSearchParameter;

  constructor(private modal: NzModalService, private filterService: FilterSideMenuBarService,
    public localStorageService: AccessLocalStorageService,
    private internalUserService: InternalUserService,
    private standardViewService: StandardViewForAllService,
    private changeDetectorRef: ChangeDetectorRef,
    private accessLocalStorageService: AccessLocalStorageService,
    private LookupFilterService: LookupFilterService,
    public toastService: NzMessageService,
    public leadOpportunities: LeadOpportunitiesService,
    private _BehaviorsubjectService: BehaviorsubjectService,
  ) {
    console.clear();
  }


  ngOnInit(): void {
    this.internalUserService.setSelectedFilterId(0);
    this.PAGE_ID = 7 
    this.selectedJobId = this.localStorageService.getJobId();
    this.localStorageService.getJobId();
    this.localStorageService.getJobName();

    this.displayedColumns = [];
    this.subscriptions.push(
      this.standardViewService.$displaySelectedColApply.subscribe(colName => {
        if (colName.length > 0) {
          this.displayedColumns = colName;
          this.changeDetectorRef.detectChanges();
        }
      })
    );
    this.subscriptions.push(
      this.ChangeOrdersubscription = this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
        this.selectedFilter = standardType != -1 ? standardType : standardType;
      })
    );

    this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom); // New Changes By Aamir Ali - 22-Apr2024
    this.getStandardViewFilterByFormPageId(this.PAGE_ID);

    this.showErrorCards = false;
    this.companyNameInvalid = false;

    this.internalUserService.$isUserAppliedFilter.subscribe(filter => {
      if (filter) {
        this.isFilterApplied = filter;
      } else {
        this.isFilterApplied = filter;
      }
    });
  }
  




  getStandardFilterByFormPageId(pageId: number, type: string) {// New Changes By Aamir Ali - 22-Apr2024
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.ChangeOrdersubscription = this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.lookupFilterResponse = result.result;
        let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
        this.internalUserService.setSelectedFilterId(defaultFilterId);
        let id = -1;
        if (type === this.selectionFrom) {
          this.defaultFilterIdSelected = defaultFilterId;
          this.accessLocalStorageService.setSelectedFilterId(defaultFilterId);

          id = this.accessLocalStorageService.getSelectedFilterId();
        } else {
          id = this.accessLocalStorageService.getSelectedFilterId();
        }

        if (id !== -1) {
          this.Changeordergrid(1, this.PAGE_ID, id, this.currentPage, this.pageSize);
        }

      }
    });
  }


  getStandardViewFilterByFormPageId(pageId: number) {
    this.displayedColumns = [];
    this.fixedSelectedColumnNames = [];
    this.standardViewService.getStandardViewFilterByFormPageId(pageId);
    this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
    this.ChangeOrdersubscription = this.standardViewService.$getStandardViewFilter.subscribe(result => {
      if (result) {
        this.displayedColumns = [];
        this.selected_View_Columns = [];
        this.getStandardViewFilterData = result.result;
        let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).standardViewParameters;
        this.selectedValueControl = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).id;
        defaultView.forEach(viewItem => {
          if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
            this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
          }
        });
        this.displayedColumns = this.selected_View_Columns;
      }
    });

    this.ChangeOrdersubscription = this.standardViewService.$getStandardViewColumn.subscribe(result => {
      if (result) {
        this.getStandardViewColumnFilterData = result.result;
        let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);
        fixedCol.forEach(x => {
          this.fixedSelectedColumnNames.push(x.displayColumnName);
        });

      }
    });
  }


  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  selectionModelOpen(select: string): void {
    const jobId = this.localStorageService.getJobId();
    if (jobId) {
      this.isLoading = true;
      const modalRef = this.modal.create({
        nzWidth: '97%',
        nzBodyStyle: { 'background': '#f1f4fa', 'padding': '0', 'margin': '0','overflow-y': 'hidden', 'overflow-x': 'hidden' },
        nzStyle: { top: '22px' },
        nzContent: ChangeOrderComponent,
        nzFooter: null
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.componentInstance.initializationComplete.subscribe((res) => {
        this.isLoading = res
      });
    } else {
      const modalRef = this.modal.create({
        nzWidth: '32%',
        nzStyle: { top: '36%' },
        nzContent: ProjectManagementSelectDialogBoxComponent,
        nzFooter: null
      });
      modalRef.componentInstance.argument = select;
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
    }
    this.onAfterReponse();
  }

  // getLeadData() {
  //   this.jobInfoService.getAllLeadData().subscribe(Data => {
  //     this.jobInfoOptions = Data.result;
  //   });
  // }

  openFilter(component: string, pageId: number) {
    console.log(component,pageId);
    
    this.filterService.toggleFilter(component, pageId);
  }

  // selectionChoiceModel
  // Selected Job Id From Left Side Job List
  // getSelectedJobId(item: number) {
  //   this.selectedJobId = item;

  //   // this.filterSearchParameter = {
  //   //   CompanyParameterId: 1,
  //   //   FormNameId: 7,
  //   //   LookUpFilterId: 7,
  //   //   page: 1,
  //   //   pageSize: 5,
  //   //   jobInformationId: this.selectedJobId
  //   // };

  //   this.internalUserService.getAllInternalUsersByFilter(this.filterSearchParameter);
  //   console.log("selectedJobId : ", this.selectedJobId);
  // }

  ChangeorderListViewData: ChangeOrderInformationResponse[] = [];
  
  getSelectedJobId(item: number) {
    this.selectedJobId = item;
    this.ChangeorderListViewData = null;
    // this.getStandardFilterByFormPageId(this.PAGE_ID);
    this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom); // New Changes By Aamir Ali - 22-Apr2024

    console.log("selectedJobId : ", this.selectedJobId);
  }

  // .....Noor......

 


  onFilterChange(id: number) {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);

    this.selectedFilter = -1;
    this.displayedColumns = [];
    this.selected_View_Columns = [];
    let selectedView = this.getStandardViewFilterData.find(view => view.id === id).standardViewParameters;
    selectedView.forEach(viewItem => {
      if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
        this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
        this.displayedColumns = this.selected_View_Columns;

        const columnIndex = this.displayedColumns.indexOf(viewItem.standardColumnSetup.displayColumnName);
        if (columnIndex !== -1) {
          this.isColumnSticky[columnIndex] = viewItem.isPin;
          this.getColumnWidthSize[columnIndex] = viewItem.resizeColumnWidth;
        }
      }
    });
  }


  openStandardViewFilterComponent(isSaveAs?: string) {
    let isInputVisible: boolean = false;

    if (isSaveAs === 'saveAs') {
      isInputVisible = true;
    } else {
      isInputVisible = false;
    }
    this.selectedColNameSizePin = [];
    for (let i = 0; i < this.displayedColumns.length; i++) {
      const item = this.displayedColumns[i];
      const isPinValue = this.isColumnSticky[i];
      let widthSize = 0;
      if (this.columnSizes.length > 0) {
        for (let i = 0; i < this.columnSizes.length; i++) {
          const columnName = this.columnSizes[i].columnName;
          const columnNameSize = this.columnSizes[i].widthSize;
          if (columnName === item) {
            widthSize = columnNameSize;
            break;
          }
        }
      }

      if (widthSize === 0) {
        widthSize = 180;
      }

      this.selectedColNameSizePin.push({
        displayColumnName: item,
        resizeColumnWidth: widthSize, //  "200px", // Set default width here
        isPin: isPinValue == true ? isPinValue : false
      });
    }
    const modalRef = this.modal.create({
      nzContent: AddNewGridStandardViewComponent,
      nzData:
      {
        pageId: this.PAGE_ID,
        column: this.getStandardViewColumnFilterData,
        displayCol: this.displayedColumns,
        viewList: this.getStandardViewFilterData,
        selectedColNameSizePin: this.selectedColNameSizePin,
        selectedStandardView: this.selectedValueControl,
        fixedCol: this.fixedSelectedColumnNames,
        isSaveAs: isInputVisible
      },

    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }




  saveColumnSizes(event: any) {
    event.forEach(col => {
      const columnName = col.column.field;
      const widthSize = col.newWidth;
      const existingIndex = this.columnSizes.findIndex(item => item.columnName === columnName);

      if (existingIndex !== -1) {
        this.columnSizes[existingIndex].widthSize = widthSize;
      } else {
        this.columnSizes.push({ columnName, widthSize });
      }
    });

  }


  isExpanded(rowIndex: number): boolean {
    return this.expandedRows.includes(rowIndex);
  }

  toggleAdditionalEmails(rowIndex: number): void {
    const index = this.expandedRows.indexOf(rowIndex);
    if (index > -1) {
      this.expandedRows.splice(index, 1);
    } else {
      this.expandedRows.push(rowIndex);
    }
  }

  toggleColumnSticky(index: number) {
    this.isColumnSticky[index] = !this.isColumnSticky[index];
    if (this.isColumnSticky[index]) {
      for (let i = 0; i < index; i++) {
        this.isColumnSticky[i] = true;
      }
    }
  }

  Changeordergrid(companyId: number, formNameId: number, lookUpFilterId: number, page: number, pageSize: number) {
    this.filterSearchParameter = {
        CompanyParameterId: companyId,
        FormNameId: formNameId,
        LookUpFilterId: lookUpFilterId,
        page: page,
        pageSize: pageSize,
        jobInformationId: this.selectedJobId
    };

    this.leadOpportunities.getChangeOrder(this.filterSearchParameter);

    if (this.ChangeOrdersubscription) {
        this.ChangeOrdersubscription.unsubscribe();
    }

    this.ChangeOrdersubscription = this.leadOpportunities.$getProjectVariation.subscribe(result => {
        if (result) {
            this.totalRecords = result?.result?.rowCount;
            this.GetProjectVariationgriddata = result?.result?.rows;
            this.selection = this.GetProjectVariationgriddata;
        }
    });
}


  EditInternalUser(ChangeOrderInformation: ChangeOrderInformationResponse): void {
    const modalRef = this.modal.create({
      nzContent: ChangeOrderComponent,
      nzFooter: null,
      nzData: {
        ChangeOrderInformation: ChangeOrderInformation,
        ChangeOrderInformation2: ChangeOrderInformation.id,
    },
    });
    // modalRef.componentInstance.internalUserResponse = data;
    modalRef.componentInstance.ChangeOrder = ChangeOrderInformation;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.onSaveComplete.subscribe(() => {
      this.Changeordergrid(1, this.PAGE_ID , this.defaultFilterIdSelected,this.currentPage,this.pageSize);
    });
  }


  toggleExpand() {
    this.expand = !this.expand;
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    let filterId : number = 0;
    this.ChangeOrdersubscription = this.internalUserService.$selectedFilterId.subscribe(v =>{
      if(v){
        filterId = v;
      }
    });
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
   this.Changeordergrid(1, this.PAGE_ID ,filterId,page,this.pageSize);
    this.isLoading = false;
   
    }, 100);
  }

  onPageSizeChange(size: number): void {
    this.isColumnSticky= new Array(this.displayedColumns.length).fill(false);
    this.pageSize = size;
    let filterId : number = 0;
    this.internalUserService.$selectedFilterId.subscribe(v =>{
      if(v){
        filterId = v;
      }
    });
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.Changeordergrid(1, this.PAGE_ID ,filterId,this.currentPage,this.pageSize);
      this.isLoading = false;
   
    }, 100);
  }

 
  reInitReset(){
    this.ngOnInit();
    this.selectedFilter = -1;
  }


  saveEditStandardView(){
    this.ChangeOrdersubscription = this.standardViewService.$createStandardViewRequest.subscribe(formData =>{
      if(formData && formData !== null){
        this.standardViewService.postData(formData)
        .subscribe(
          () => {
            setTimeout(() => {
              this.toastService.success('Standard View Updated Successfully');
              this.standardViewService.getStandardViewFilterByFormPageId(this.PAGE_ID);
              this.selectedFilter = -1;
            }, 10);
          },
          (error) => {
            console.error('Error:', error);
            this.isLoading = false;
          }
          );  
      }
    });
  }
  ngOnDestroy() {
    if (this.ChangeOrdersubscription) {
      this.ChangeOrdersubscription.unsubscribe();
  }
    // this.LookupFilterService.getStandardFilter.next(null); // New Changes By Aamir Ali - 31-May-2024
    // localStorage.removeItem('Selected_Filter_Id'); // New Changes By Aamir Ali - 31-May-2024
    // if (this.variationFilterSubscription) {
    //   this.variationFilterSubscription.unsubscribe();
    // }
    // if (this.variationsubscription) {
    //     this.variationsubscription.unsubscribe();
    // }
    // if (this.VariationstandardViewFilterSubscription) {
    //   this.VariationstandardViewFilterSubscription.unsubscribe();
    // }
    console.clear();
  }
  allSelected: boolean = false;
  columnLockState: { [key: string]: boolean } = {};
  onHeaderCheckboxChange(checked: boolean) {
    this.allSelected = checked;
    this.GetProjectVariationgriddata.forEach(item => item.selected = checked);
  }
  onCheckboxChange() {
    const selectedCount = this.GetProjectVariationgriddata.filter(item => item.selected).length;
    this.allSelected = selectedCount === this.GetProjectVariationgriddata.length;
  }
  getColumnWidth(column: string): number {
    switch (column) {
      case 'CO ID#':
        return 120;
      case 'Title':
        return 250;
      case 'RFIs':
        return 50;
      case 'Comments':
        return 70;
      case 'Owner':
        return 200;
      case 'Created Date':
        return 100;
      case 'Files':
        return 90;
      case 'Builder Cost':
        return 130;
      case 'Owner Price':
        return 130;
      case 'Approval Status':
        return 140;
      case 'Approval Date':
        return 130;
      case 'Related POs':
        return 230;
      case 'Creator':
        return 100;
      case 'Deadline':
        return 110;
      case 'Owner Last Viewed':
        return 150;
      case 'PO Builder Variance':
        return 160;
      case 'PO Cost':
        return 110;
      case 'PO Customer Variance':
        return 160;
      case 'Related Bid Packages':
        return 160;
      case 'Related Invoices':
        return 150;
      case 'Sub/Vendors':
        return 130;
      case 'Total Markup':
        return 120;
      case 'Created By':
        return 150;
      default:
        return 100;
    }
  }
  isColumnLocked(column: string): boolean {
    return this.columnLockState[column];
  }
  toggleColumnLock(column: string) {
    this.columnLockState[column] = !this.columnLockState[column];
  }
  EditChangeOrders(Id: number): void {
    this.isLoading = true
    const modalRef = this.modal.create({
      nzContent: ChangeOrderComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.ChangeOrderResponseId = Id;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.initializationComplete.subscribe((res) => {
      this.isLoading = res
    });
    this.onAfterReponse();
  }
  onAfterReponse(){
    this.ChangeOrdersubscription = this._BehaviorsubjectService.leadActivityTypeResponse$.subscribe(
      (response) => {
        if (response) {
          this.Changeordergrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPage, this.pageSize);
        }
    });
  }
}
