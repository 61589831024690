import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TreeNodeInterface } from 'src/app/shared/component/subs-vendors-notification/subs-vendors-notification.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationTreeService {

  constructor() { }

  getTreeData(): Observable<TreeNodeInterface[]> {
    // You can replace this with an HTTP call to your backend API
    return of([
      {
        key: `2`,
        name: 'All Notifications',
        address: 'Sidney No. 1 Lake Park'
      },
      {
        key: `1`,
        name: 'Sales',
        address: 'New York No. 1 Lake Park',
        children: [

          {
            key: `1-2`,
            name: 'Leads',


            children: [
              {
                key: `1-2-1`,
                name: 'New Lead opportunity from Contact From',

              },
              {
                key: `1-2-2`,
                name: 'Other Employee Contact ',

              },
              {
                key: `1-2-3`,
                name: 'Lead Salesperson changed',

              },
              {
                key: `1-2-4`,
                name: 'Assigned to a lead activity by another user',

              },
              {
                key: `1-2-5`,
                name: 'Lead activity reminder',


              },
              {
                key: `1-2-6`,
                name: 'Lead Click a Link',

              },
              {
                key: `1-2-7`,
                name: 'Lead Proposal viewed',

              },
              {
                key: `1-2-8`,
                name: 'Lead Proposal accepted or diclined',

              },
              {
                key: `1-2-9`,
                name: 'Lead Proposal Past due',

              },
              {
                key: `1-2-10`,
                name: 'Email quota reached',

              }
            ]
          }
        ]
      },


      {
        key: `3`,
        name: 'Project Managment',
        children: [
          {
            key: `1-2-11`,
            name: 'Schedule',
            children: [
              {
                key: `1-3-1`,
                name: 'New Comment on a schedule item',

              },
              {
                key: `1-3-2`,
                name: 'Schedule item needs confirmation',

              },
              {
                key: `1-3-3`,
                name: 'Schedule item Confirmed by assigned user',

              },
              {
                key: `1-3-4`,
                name: 'Schedule item declined by assigned user',

              },


            ]
          },
          {
            key: `1-2-12`,
            name: 'Daily Logs',
            children: [
              {
                key: `1-4-1`,
                name: 'New Daily Log created',

              },
              {
                key: `1-4-2`,
                name: 'New Comments on daily Log',

              }
            ]
          },
          {
            key: `1-2-13`,
            name: 'To-Dos',
            children: [
              {
                key: `1-5-1`,
                name: 'New Comments on To-do',

              },
              {
                key: `1-5-2`,
                name: 'To-Do Marked complete or incomplete',

              }
            ]
          },
          {
            key: `1-2-14`,
            name: 'Change Orders',
            children: [
              {
                key: `1-6-1`,
                name: 'New Change Order created by another user',

              },
              {
                key: `1-6-2`,
                name: 'New Change Order Request from Owner',

              },
              {
                key: `1-6-3`,
                name: 'New Comment on a change order',

              },
              {
                key: `1-6-4`,
                name: 'Change Order approved by another user',

              },
              {
                key: `1-6-5`,
                name: 'Change order approved by owner',

              },
              {
                key: `1-6-6`,
                name: 'Files attached to a change order',

              }
            ]
          },
          {
            key: `1-2-15`,
            name: 'Selections',
            children: [
              {
                key: `1-7-1`,
                name: 'New Selection choice created',

              },
              {
                key: `1-7-2`,
                name: 'New comment on selection',

              },
              {
                key: `1-7-3`,
                name: 'Selection choice approved by another user',

              },
              {
                key: `1-7-4`,
                name: 'Selection choice approved by owner',

              },
              {
                key: `1-7-5`,
                name: 'Owner requested pricing',

              },
              {
                key: `1-7-6`,
                name: 'Sub/vendor submitted pricing',

              }
            ]
          },
          {
            key: `1-2-16`,
            name: 'Warranty',
            children: [
              {
                key: `1-8-1`,
                name: 'Files attached to assigned warranty services',
              },
              {
                key: `1-8-2`,
                name: 'New Warranty request created by another user',
              },
              {
                key: `1-8-3`,
                name: 'New Warranty request approved by owner',
              },
              {
                key: `1-8-4`,
                name: 'New comment on a warranty request',
              },
              {
                key: `1-8-5`,
                name: 'Follow-up date reminder',
              },
              {
                key: `1-8-6`,
                name: 'Service appointment updated by sub/vendor',
              },
              {
                key: `1-8-7`,
                name: 'Service appointment updated by owner',
              },
              {
                key: `1-8-8`,
                name: 'Feedback provided by owner',
              },
              {
                key: `1-8-9`,
                name: 'Service appointment date changed',
              },
              {
                key: `1-8-10`,
                name: 'Service appointment assigned to another user',
              },
            ],
          },
          {
            key: `1-2-17`,
            name: 'Time Clock',
            children: [
              {
                key: `1-9-1`,
                name: 'Time sheet was changed',
              },
              {
                key: `1-9-2`,
                name: 'Overtime has been reached',
              },
              {
                key: `1-9-3`,
                name: 'User clocked in/out outside of geofence',
              },
            ],
          }


        ]
      },
      {
        key: `4`,
        name: 'Files',
        children: [
          {
            key: `1-2-18`,
            name: 'Documents',
            children: [
              {
                key: `1-10-1`,
                name: 'Document uploaded by owner or Sub/vendor',

              },
              {
                key: `1-10-2`,
                name: 'Schedule item needs confirmation',

              },
              {
                key: `1-10-3`,
                name: 'Schedule item Confirmed by assigned user',

              },
              {
                key: `1-10-4`,
                name: 'Schedule item declined by assigned user',

              },


            ]
          },

          {
            key: `1-2-19`,
            name: 'Photos',
            children: [
              {
                key: `1-11-1`,
                name: 'New comment on a photo',
              }
            ],
          },
          {
            key: `1-2-20`,
            name: 'Videos',
            children: [
              {
                key: `1-12-1`,
                name: 'New comment on a video',
              }
            ],
          }


        ]
      },
      {
        key: `5`,
        name: 'Messages',
        children: [
          {
            key: `1-2-19`,
            name: 'Messages',
            children: [
              {
                key: `1-13-1`,
                name: 'New Message received',
              }

            ]
          },

          {
            key: `1-2-20`,
            name: 'RFIs',
            children: [
              {
                key: `1-14-1`,
                name: 'New RFI created by another user',
              },
              {
                key: `1-14-2`,
                name: 'New RFI created by sub/vendor',
              },
              {
                key: `1-14-3`,
                name: 'New Response to an RFI',
              },
              {
                key: `1-14-5`,
                name: 'RFI marked complete or incomplete',
              },
            ],
          },
          {
            key: `1-2-21`,
            name: 'Surveys',
            children: [
              {
                key: `1-12-1`,
                name: 'New survey response from owner',
              }
            ],
          }


        ]
      },
      {
        key: `6`,
        name: 'Financial',
        children: [
          {
            key: `1-2-22`,
            name: 'Bids',
            children: [
              {
                key: `1-10-1-1`,
                name: 'New Bid Request Submitted by Sub/Vendor',
              },
              {
                key: `1-10-1-2`,
                name: 'Bid Request resubmitted by Sub/Vendor',
              },
              {
                key: `1-10-1-3`,
                name: 'New Comment on a Bid Request',
              },
              {
                key: `1-10-1-4`,
                name: 'Bid Request Approved by another User',
              },
              {
                key: `1-10-1-5`,
                name: 'Bid Request Confirmed or Declined Sub/Vendor',
              },
            ],
          },
          {
            key: `1-2-23`,
            name: 'Estimates',
            children: [
              {
                key: `1-10-2-1`,
                name: 'Estimate View by Owner',
              },
              {
                key: `1-10-2-2`,
                name: 'Estimate approved or declined',
              },
              {
                key: `1-10-2-3`,
                name: 'Estimate past due',
              },
            ],
          },
          {
            key: `1-10-3`,
            name: 'Purchase Order',
            children: [
              {
                key: `1-10-3-1`,
                name: "Bill Marked 'Ready for Payment'",
              },
              {
                key: `1-10-3-2`,
                name: 'Sub Requested Bill Payment',
              },
              {
                key: `1-10-3-3`,
                name: 'New Comment On a Purchased Order',
              },
              {
                key: `1-10-3-4`,
                name: 'Purchase Order approved by another User',
              },
              {
                key: `1-10-3-5`,
                name: 'Purchase Order approved by another Sub/Vendor',
              },
              {
                key: `1-10-3-6`,
                name: 'Purchase Order Declined by Sub/Vendor',
              },
              {
                key: `1-10-3-7`,
                name: "Purchase Order Marked 'Ready for Payment'",
              },
              {
                key: `1-10-3-8`,
                name: 'Lien Waiver accepted',
              },
              {
                key: `1-10-3-9`,
                name: 'Lien Waiver declined',
              },
              {
                key: `1-10-3-10`,
                name: 'Purchase Order assigned to another User',
              },
              {
                key: `1-10-3-11`,
                name: 'Purchase Order work completed by another User',
              },
              {
                key: `1-10-3-12`,
                name: 'Purchase Order work completed by Sub/Vendor',
              },
              {
                key: `1-10-3-13`,
                name: 'Files attached to a Purchase Order by a Sub/Vendor',
              },
              {
                key: `1-10-3-14`,
                name: 'Payment Requested on a Purchase Order',
              },
              {
                key: `1-10-3-15`,
                name: 'Payment Made on a Purchase Order',
              },
              {
                key: `1-10-3-16`,
                name: 'Purchase Order Payment Reminder',
              },
              {
                key: `1-10-3-17`,
                name: 'Inspection Reminder',
              },
              {
                key: `1-10-3-18`,
                name: 'New Comment on a Bill',
              }
            ],
          },
          {
            key: `1-10-4`,
            name: 'Owner Invoices',
            children: [
              {
                key: `1-10-4-1`,
                name: 'New Owner Invoice Created',
              },
              {
                key: `1-10-4-2`,
                name: 'Owner Invoice Updated',
              },
              {
                key: `1-10-4-3`,
                name: 'Payment Reminder',
              },
              {
                key: `1-10-4-4`,
                name: 'Payment past due',
              },
              {
                key: `1-10-4-5`,
                name: 'New Comment on a Owner Invoice',
              },
              {
                key: `1-10-4-6`,
                name: 'New Credit Memo',
              },
              {
                key: `1-10-4-7`,
                name: 'New Comment on a Credit Memo',
              },
            ],
          },
          {
            key: `1-10-5`,
            name: 'Cost Inbox',
            children: [
              {
                key: `1-10-5-1`,
                name: 'New Receipt Comment',
              },
              {
                key: `1-10-5-2`,
                name: 'Receipt Uploaded',
              },
            ],
          },
        ],
      },
      {
        key: `7`,
        name: 'Administrative',
        children: [
          {
            key: `1-11-1`,
            name: 'Job Information',
            children: [
              {
                key: `1-11-1-1`,
                name: 'Owner activation complete',
              },
              {
                key: `1-11-1-2`,
                name: 'Job viewing access granted',
              },
              {
                key: `1-11-1-3`,
                name: 'Owner Notification Preferences Changed',
              },
            ],
          },
          {
            key: `1-11-2`,
            name: 'Internal User Information',
            children: [
              {
                key: `1-11-2-1`,
                name: 'Internal User activation complete',
              },
              {
                key: `1-11-2-2`,
                name: 'Internal User Notification Preferences Changed',
              },
            ],
          },
          {
            key: `1-11-3`,
            name: 'Sub/Vendor',
            children: [
              {
                key: `1-11-3-1`,
                name: 'Sub/Vendor uploaded a certificate',
              },
              {
                key: `1-11-3-2`,
                name: 'Sub/Vendor activation complete',
              },
              {
                key: `1-11-3-3`,
                name: 'Action taken on Trade Agreement',
              },
              {
                key: `1-11-3-4`,
                name: 'Insurance reminder',
              },
              {
                key: `1-11-3-5`,
                name: 'Sub/Vendor Notification Preferences Changed',
              },
            ],
          },
        ],
      },


    ]);
  }

  

  getTreeDataSubvendor(): Observable<TreeNodeInterface[]> {

    return of([
      {
        key: `1`,
        name: 'Project management',
        children: [

          {
            key: `1-2`,
            name: 'Schedule',


            children: [
              {
                key: `1-2-1`,
                name: 'Schedule Item due date reminder',

              },
              {
                key: `1-2-2`,
                name: 'Schedule Item needs confirmation ',

              }
            ]
          },
          {
            key: `1-2`,
            name: 'Daily Logs',


            children: [
              {
                key: `1-2-1`,
                name: ' New daily log created',

              },

            ]
          },
          {
            key: `1-2`,
            name: 'To-Dos',


            children: [
              {
                key: `1-2-1`,
                name: ' To-Do marked complete or incomplete',

              },

            ]
          },
          {
            key: `1-2`,
            name: 'Change Order',


            children: [
              {
                key: `1-2-1`,
                name: ' Change Order approved by Owner',

              },

            ]
          },
          {
            key: `1-2`,
            name: 'Selections',


            children: [
              {
                key: `1-2-1`,
                name: 'Selection Choice approved as a vendor ',

              },
              {
                key: `1-2-2`,
                name: 'Selection Choice approved as an installer. ',

              }

            ]
          },
          {
            key: `1-2`,
            name: 'Warranty',


            children: [
              {
                key: `1-2-1`,
                name: 'Service Appointment acceptance requested ',

              },
              {
                key: `1-2-2`,
                name: 'Service Appointment Scheduled ',

              },
              {
                key: `1-2-3`,
                name: 'Service Appointment date changed',

              },

            ]
          },

        ]
      },
      {
        key: `2`,
        name: 'Messaging',
        children: [

          {
            key: `1-2`,
            name: 'Messages',


            children: [
              {
                key: `1-2-1`,
                name: ' New Message Received',

              },

            ]
          }]
      },

      {
        key: `1-2`,
        name: ' RFIs',


        children: [
          {
            key: `1-2-1`,
            name: ' New Response to an RFI',

          },
          {
            key: `1-2-2`,
            name: ' RFI Marked Complete or Incomplete '
          }

        ]
      },
      {
        key: `3`,
        name: 'financial',
        children: [

          {
            key: `1-2`,
            name: 'Bids',


            children: [
              {
                key: `1-2-1`,
                name: ' New Comment On a Bid Request',

              },
              {
                key: `1-2-2`,
                name: ' Bid Request Released by Builder',

              },
              {
                key: `1-2-3`,
                name: ' Bid Request approved by Builder ',

              },
              {
                key: `1-2-4`,
                name: 'Bid Request Declined by Builder',

              },
              {
                key: `1-2-5`,
                name: '  Bid Request Status Reset to in Progress by Builder',

              },
              {
                key: `1-2-6`,
                name: 'Bid Submitted by Builder',

              },
              {
                key: `1-2-7`,
                name: 'Bid Package Reopened',

              },
              {
                key: `1-2-8`,
                name: ' H.        Bid Package Deadline Reminder',

              },
              {
                key: `1-2-9`,
                name: ' Files Attached to a Bid Package',

              },

            ]
          }]
      },
      {
        key: `1-5`,
        name: 'Administrative',
        children: [

          {
            key: `1-2`,
            name: 'Sub/ Vendor Information',


            children: [
              {
                key: `1-2-1`,
                name: ' Insurance Reminder',

              },

            ]
          }
        ]
      },
    ]
    );

  }


}
