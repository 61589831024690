import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'table-data',
  templateUrl: './table-data.component.html',
  styleUrls: ['./table-data.component.css']
})
export class TableDataComponent implements OnInit{

  @Input() tableData : any[] = [];
  @Input() columnDisplayName : string[] = [];
  @Input() total : number;
  @Input() tableIndex : number;
  @Input() tableSize : number;


  constructor(){

  }
  ngOnInit(): void {
    
  }

  getColumns(item: any): string[] {
    return Object.keys(item);
  }

  onPageIndexChange(index: number) {
    console.log('Page index changed to: ', index);
    // You can emit an event or call a function to fetch data based on the new page index here
  }

  onPageSizeChange(size: number) {
    console.log('Page size changed to: ', size);
    // You can emit an event or call a function to fetch data based on the new page size here
  }
}
