import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IconInformation, colorInformation } from 'src/Models/Job-List/Job-Information/color';
import { ResponseModelArray } from 'src/Models/responseMessage.model';

import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class colorService {

  private color: string = environment.apiUrl + 'Color/';
  private Icon: string = environment.apiUrl + 'Icon/';


  constructor(private http: HttpClient) {}

  postData(data: colorInformation): Observable<colorInformation> {
    return this.http.post<any>(`${this.color}`, data);
  }

  getData(): Observable<colorInformation[]> {
    return this.http.get<colorInformation[]>(`${this.color}getAll`);
  }
  getColorData():Observable<ResponseModelArray<colorInformation>>{
    return this.http.get<ResponseModelArray<colorInformation>>(`${this.color}getAll`);
  }

  updateData(data: colorInformation): Observable<colorInformation> {
    return this.http.put<colorInformation>(`${this.color}`, data);
  }

  deleteData(id: number): Observable<colorInformation> {
    return this.http.delete<colorInformation>(
      `${this.color}?globalId=${id}`
    );
  }
  getDataId(id: number): Observable<colorInformation> {
    return this.http.get<colorInformation>(
      `${this.color}getBy-Id?id=${id}`
    );
  }
  getIconData():Observable<ResponseModelArray<IconInformation>>{
    return this.http.get<ResponseModelArray<IconInformation>>(`${this.Icon}getAll`);
  }
}