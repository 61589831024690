import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JobInformationTypeParameterResponse, jobTypeGroupResponse, JobTypeGroupSetup } from 'src/Models/CustomerInfromation/CustomerInformation';
import { JobTypeInformation } from 'src/Models/Job-List/Job-Information/JobType';
import { ResponseModel, ResponseModelArray } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class JobTypeService {

  private baseUrl: string = environment.apiUrl + 'JobType/';
  private Base_Url: string = environment.apiUrl + 'JobSetup/';

  selectedJobId: number = 0;

  constructor(private http: HttpClient) {}

  postData(data: JobTypeInformation): Observable<JobTypeInformation> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }

  getData(): Observable<JobTypeInformation[]> {
    return this.http.get<JobTypeInformation[]>(`${this.baseUrl}getAll`);
  }

  updateData(data: JobTypeInformation): Observable<JobTypeInformation> {
    return this.http.put<JobTypeInformation>(`${this.baseUrl}`, data);
  }

  deleteData(id: number): Observable<JobTypeInformation> {
    return this.http.delete<JobTypeInformation>(
      `${this.baseUrl}?globalId=${id}`
    );
  }
  getDataId(id: number): Observable<JobTypeInformation> {
    return this.http.get<JobTypeInformation>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }

  getAllDataJobTypesSetup(): Observable<ResponseModel<JobTypeGroupSetup[]>> {
    return this.http.get<ResponseModel<JobTypeGroupSetup[]>>(`${this.baseUrl}GetAll`);
  }
  getAllDataJobType(): Observable<ResponseModelArray<jobTypeGroupResponse>> {
    return this.http.get<ResponseModelArray<jobTypeGroupResponse>>(`${this.Base_Url}GetAll-JobType`);
  }

 
}