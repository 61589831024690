import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LeadOpportunitiesDashBoardComponent } from './Components/lead-opportunities-dash-board/lead-opportunities-dash-board.component';


const routes: Routes = [
  { path: '', redirectTo: 'Lead-Opportunities', pathMatch: 'full' },
  { path: 'Lead-Opportunities', component: LeadOpportunitiesDashBoardComponent},
  { path: 'Lead-Activities', component: LeadOpportunitiesDashBoardComponent },
  { path: 'Lead-Activity-Calender', component: LeadOpportunitiesDashBoardComponent },
  { path: 'Lead-Activity Templates', component: LeadOpportunitiesDashBoardComponent },
  { path: 'Lead-Proposals', component: LeadOpportunitiesDashBoardComponent },
  { path: 'Lead-Proposal-Templates', component: LeadOpportunitiesDashBoardComponent },
  { path: 'Lead-Map', component: LeadOpportunitiesDashBoardComponent },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeadOpportunitiesRoutingModule { }
