import { Component } from '@angular/core';

@Component({
  selector: 'comment-empty-data',
  templateUrl: './comment-empty-data.component.html',
  styleUrls: ['./comment-empty-data.component.css']
})
export class CommentEmptyDataComponent {

}
