import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { ColorResponse, IconResponse } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { AddLeadActivityTypeResponse } from 'src/Models/LeadActivity/leadActivityClass';
import { BehaviorsubjectService } from 'src/Service/behaviorsubject/behaviorsubject.service';
import { colorService } from 'src/Service/Job-List/Job-Information/color.service';
import { LeadActivityTypeService } from 'src/Service/Lead-Activity-Type/lead-activity-type.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';

@Component({
  selector: 'app-add-lead-activity-type',
  templateUrl: './add-lead-activity-type.component.html',
  styleUrls: ['./add-lead-activity-type.component.css']
})
export class AddLeadActivityTypeComponent implements OnInit{

  @Output() signatureSaved = new EventEmitter<AddLeadActivityTypeResponse>();
 


  @Output() cancel = new EventEmitter<void>();
  @Output() salesOnEmitFlag : boolean;
  @Output() onSaveComplete  = new EventEmitter();
  @Output() onUpdateComplete  = new EventEmitter();
  @Output() onUpdateCompleteNew  = new EventEmitter();
  @Output() nameSaved = new EventEmitter<string>();
  @Input() addLeadActivityTypeResponse: AddLeadActivityTypeResponse;
  @Output() onDeleteComplete = new EventEmitter<void>();
  
  formFieldValues: any = {};
  colors: ColorResponse[] = [];
  icons: IconResponse[] = [];
  selectedColor: string = '';
  sourceCollectionIcon: string = '';
  newleadActivityVisible=true;
  selectedColorName: string = '';
  selectedName: string = '';
  public mySelection: string[] = [];
  storeActivity: AddLeadActivityTypeResponse;
  ngOnInit(): void {

    console.log('FlAG',this.salesOnEmitFlag);
    
    this.getColor();
    // this.getIcon();
    this.leadActivityFormGroup();
  }
  constructor(
    private leadActivitiesService: LeadActivityTypeService,
    private _BehaviorsubjectService: BehaviorsubjectService,
    private colorService: colorService,
    private modal: NzModalService,
    private toastService: NzMessageService,
    private loadingIndicatorService: LoadingIndicatorService,
    @Inject(NZ_MODAL_DATA) public getname: any,
    @Inject(NZ_MODAL_DATA) public DataPass: any,
  ){
    console.log('DataPassDataPassDataPass',DataPass);
    
  }
  leadActivityFormGroup(): void {
    this.formFieldValues = {
      name: ' ',
      colorId: 0,
      iconId: 0,
  }
  this.formFieldValues['globalId'] = this.addLeadActivityTypeResponse?.globalId;
  this.formFieldValues['name'] = this.addLeadActivityTypeResponse?.name;
  this.formFieldValues['colorId'] = this.addLeadActivityTypeResponse?.colorId;
  this.selectedColor = this.addLeadActivityTypeResponse?.color?.colorCollection;
  this.formFieldValues['iconId'] = this.addLeadActivityTypeResponse?.iconId;
  this.sourceCollectionIcon = this.addLeadActivityTypeResponse?.icon?.sourceCollection;

  }
  newleadActivityCancel10(): void {
    this.cancel.emit();
  }
  // getColor() {
  //   this.colorService.getData().subscribe((res: any) => {
  //     const colorGet = res.result;
  //     this.colors = colorGet;
  //     if(this.addLeadActivityTypeResponse){
  //     } else {
  //         const reminder = colorGet.find((item) => item.name === 'Sky');
  //         if (reminder) {
  //           const reminderId = reminder.id;
  //           this.formFieldValues.colorId = reminderId;
  //           this.updateSelectedColor(reminderId);
  //         } else {
  //           console.log("Reminder 'Ocean' not found.");
  //         }
  //       }
  //   });
  // }
  getColor() {
    this.colorService.getColorData().subscribe((res) => {
      const colorGet = res.result;
      this.colors = colorGet;
      if (this.addLeadActivityTypeResponse?.colorId) {
      } else {
        const color = colorGet.find((item) => item.name === 'Sky');
        const colorId = color.id;
        this.formFieldValues.colorId = colorId;
        this.selectedColor = color.colorCollection;
      }
    });
    this.colorService.getIconData().subscribe((res) => {
      const iconsGet = res.result;
      this.icons = iconsGet;
      if (this.addLeadActivityTypeResponse?.iconId) {
      } else {
        const icons = iconsGet.find((item) => item.name === 'Phone Call');
        const iconId = icons.id;
        this.formFieldValues.iconId = iconId;
        this.sourceCollectionIcon = icons.sourceCollection;
      }
    });
  }
  updateSelectedColor(colorId: number): void {
    const selectedColor = this.colors.find(color => color.id === colorId);
    if (selectedColor) {
      this.selectedColor = selectedColor.colorCollection;
      this.selectedColorName = selectedColor.name;
    } else {
      this.selectedColor = '';
      this.selectedColorName = '';
    }
  }
  updateSelectedColorByName(colorName: string): void {
    const selectedColor = this.colors.find(color => color.name === colorName);
    if (selectedColor) {
      this.formFieldValues['colorId'] = selectedColor.id;
      this.selectedColor = selectedColor.colorCollection;
      this.selectedColorName = selectedColor.name;
    } else {
      this.selectedColor = '';
      this.selectedColorName = '';
    }
  }
  // getIcon() {
  //   this.colorService.getIconData().subscribe((res: any) => {
  //     const IconGet = res.result;
  //     this.icons = IconGet;
  //     if(this.addLeadActivityTypeResponse){
  //     } else {
  //         const iconreminder = IconGet.find((item) => item.name === 'Phone Call');
  //         if (iconreminder) {
  //           const activity = iconreminder.id;
  //           this.formFieldValues.iconId = activity;
  //           this.updateSelectedIcon(activity);
  //         } else {
  //           console.log("Reminder 'Phone Call' not found.");
  //         }
  //       }
  //   });
  // }
  updateSelectedIcon(iconId: number): void {
      const selectedIcon = this.icons.find(color => color.id === iconId);
      if (selectedIcon) {
        this.sourceCollectionIcon = selectedIcon.sourceCollection;
        this.selectedName = selectedIcon.name;
      } else {
        this.sourceCollectionIcon = '';
        this.selectedName = '';
      }
  }
  updateSelectedIconByName(colorName: string): void {
      const selectedIcon = this.icons.find(color => color.name === colorName);
      if (selectedIcon) {
        this.formFieldValues['iconId'] = selectedIcon.id;
        this.sourceCollectionIcon = selectedIcon.sourceCollection;
        this.selectedColorName = selectedIcon.name;
      } else {
        this.sourceCollectionIcon = '';
        this.selectedColorName = '';
      }
  }
  isSave: boolean = false;
  isEdit: boolean = false;

  saveLeadActivityType() {
    if (this.isSave) {
      return;
    }
    this.isSave = true;
    this.leadActivitiesService.postAddLeadActivityType(this.formFieldValues).subscribe(
      (response) => {
        this.storeActivity = response.result;
        this.storeActivity = this.DataPass;
        const getname = this.formFieldValues.name;
        const message = `Added lead ' ${getname} ' activity type`;
        setTimeout(() => {
          this.toastService.success(message);
          this.onSaveComplete.emit(response.result);
          this.cancel.emit();
          this._BehaviorsubjectService.setLeadActivityTypeResponse(response);
          this.isSave = false;
        }, 2000);
      },
      (error) => {
        this.isSave = false;
      }
    );
  }

  //Update

  EditLeadActivityType() {
    if (this.isEdit) {
      return;
    }
    this.isEdit = true;
    this.leadActivitiesService.postAddLeadActivityType(this.formFieldValues).subscribe(
      (response) => {
        const getname = this.formFieldValues.name;
        const message = `Updated Lead ' ${getname} ' activity type`;
        setTimeout(() => {
          this.toastService.success(message);
          this.onUpdateComplete.emit();
          this.cancel.emit();
          this._BehaviorsubjectService.setLeadActivityTypeResponse(response);
          this.isEdit = false;
        }, 2000);
      },
      (error) => {
        this.isEdit = false;
      }
    );
  }
  isDeleteLoading: boolean = false;

  deleteLeadActivityType(globalId: string): void {
    if (this.isDeleteLoading) {
      return;
    }

    const selectedCount = this.mySelection.length;
    this.modal.confirm({
      nzTitle: `Delete lead activity type? ${selectedCount > 1 ? 's' : ''}`,
      nzContent: 'This lead activity type is not currently being used and will be deleted.',
      nzOkText: `Delete  ${selectedCount > 1 ? 's' : ''}`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => this.userDelete(globalId),
      nzCentered: true,
      nzBodyStyle: { 'border-radius': '30px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }

  userDelete(globalId: string) {
    if (this.isDeleteLoading) {
      return;
    }
    this.isDeleteLoading = true;

    this.leadActivitiesService.deleteData(globalId).subscribe(
      response => {

        const getname = this.formFieldValues.name;
        const message = `Deleted ' ${getname} ' lead activity type`;
        setTimeout(() => {
          this.onUpdateCompleteNew.emit();
          this.toastService.success(message);
          this.onDeleteComplete.emit();
          this.newleadActivityCancel10();
          this.isDeleteLoading = false;
        }, 2000);
      },
      error => {
        console.error('Error deleting user:', error);
        this.isDeleteLoading = false;
      }
    );
  }

  isButtonDisableds = false;
  checkCharacterLimits(): void {
    const { name,  } = this.formFieldValues;

    if (name?.length > 50 ) {
      this.isButtonDisableds = true;
    } else {
      this.isButtonDisableds = false;
    }
  }

}


