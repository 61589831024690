<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form [formGroup]="financialbillsfilterfieldForm" (ngSubmit)="onSubmit()">
      <div class="row mt-2">
          <div class="col-10 p-0">
              <div class="input-group">
                  <nz-select formControlName="standardFilter" (ngModelChange)="onChangeStandardFilter($event)"
                      nzShowSearch [lang]="true" nzAutoFocus="true" nzPlaceHolder="Standard Filter">
                      <nz-option *ngFor="let filter of filterResponseData" [nzLabel]="filter.name"
                          [nzValue]="filter.id"></nz-option>
                  </nz-select>
              </div>
          </div>
          <div class="col-1 mt-1">
              <i class="bi bi-three-dots" (click)="openStandardFilterComponent()"></i>
          </div>
      </div>

<!-- <div class="row  d-flex justify-content-between align-items-center">
    <div class="col-10 p-0 ">
      <div class="input-group">
        <nz-select formControlName="standardFilter">
          <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
          <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
        </nz-select>
      </div>
    </div>
    <div class="col-2 text-end p-0">
      <button
      nz-button
      class="border-0"
      >
      <span
        nz-popover
        class="text-primary text-dark fw-bold fs-5"
        nz-icon
        nzType="ellipsis"
        nzTheme="outline"
      ></span>
    </button>
    </div>
  </div> -->

  <div class="row">
    <div class="col mt-1 p-0">
      <label class="sub-text">Title</label>
      <input formControlName="title" class="formField form-control" nz-input placeholder="Basic usage" type="number" />
    </div>
  </div>



  <div class="row">
    <div class="col mt-1 p-0">
      <label class="sub-text"> Bill Status</label>
      <nz-tree-select  formControlName="billStatus"  [nzNodes]="contactTypeNodes" nzShowSearch
      nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
    </div>
  </div>

  <div class="row">
    <div class="col mt-1 p-0">
      <label class="sub-text">Work Status </label>
      <nz-tree-select  formControlName="workStatus"  [nzNodes]="contactTypeNodes" nzShowSearch
      nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
    </div>
  </div>
 
  <div class="row">
    <div class="col mt-1 p-0">
      <label class="sub-text">PO Status</label>
      <nz-tree-select  formControlName="poStatus"  [nzNodes]="contactTypeNodes" nzShowSearch
      nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col mt-1 p-0">
      <label class="sub-text">Work Complete Datee</label>
      <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a person" [(ngModel)]="selectedValue">
        <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
        <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
        <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
      </nz-select>
    </div>
  </div> -->
 
  <div class="row">
    <div class="col mt-1 p-0">
      <label class="sub-text">Pay To</label>
      <nz-select formControlName="payTo" nzShowSearch nzAllowClear nzPlaceHolder="Select a person" >
        <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
        <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
        <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
      </nz-select>
    </div>
  </div>
 
  <div class="row">
    <div class="col mt-1 p-0">
      <label class="sub-text">Invoice Date</label>
      <nz-select nzShowSearch nzAllowClear formControlName="InvoiceDate" >
        <nz-option *ngFor="let option of keywordOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
      </nz-select>
    </div>
  </div>

  <div class="row">
    <div class="col mt-1 p-0">
      <label class="sub-text">Paid Date</label>
      <nz-select formControlName="paidDate" nzShowSearch nzAllowClear nzPlaceHolder="Select a person" >
        <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
        <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
        <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
      </nz-select>
    </div>
  </div>

  <div class="row">
    <div class="col mt-1 p-0">
      <label class="sub-text">Due Date</label>
      <nz-select formControlName="dueDate" nzShowSearch nzAllowClear nzPlaceHolder="Select a person" >
        <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
        <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
        <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
      </nz-select>
    </div>
  </div>
  
  <div class="row">
    <div class="col mt-1 p-0">
      <label class="sub-text">Lien Waivers</label>
      <nz-tree-select  formControlName="lienWaivers"  [nzNodes]="nodes" nzShowSearch
      nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
    </div>
  </div>



  
  <div class="row">
    <div class="col mt-1 p-0">
      <label class="sub-text">Created Date</label>
      <nz-select formControlName="createdDate" nzShowSearch nzAllowClear nzPlaceHolder="Select a person">
        <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
        <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
        <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
      </nz-select>
    </div>
  </div>

  <div class="row">
    <div class="col mt-1 p-0">
      <label class="sub-text">Cost Code </label>
      <nz-tree-select  formControlName="costCode"  [nzNodes]="nodes" nzShowSearch
      nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
    </div>
  </div>

  <div class="row">
    <div class="col mt-1 p-0">
      <label class="sub-text">Variance Code</label>
      <nz-tree-select  formControlName="varianceCode"  [nzNodes]="nodes" nzShowSearch
      nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
    </div>
  </div>

  <div class="row">
    <div class="col mt-1 p-0">
      <label class="sub-text">Sent To Accounting </label>
      <nz-tree-select  formControlName="sentToAccounting"  [nzNodes]="contactTypeNodes" nzShowSearch
      nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
    </div>
  </div>

  <div class="row">
    <div class="col mt-1 p-0">
      <p class="sub-text">Action Items Only  <span><i class="bi bi-info-circle-fill"></i></span></p>
    </div>
  </div>

  <div class="row">
    <div class="col mt-1 p-0">
      <label nz-checkbox formControlName="actionOnly" >Checkbox</label> 
    </div>
  </div>
  

</form>
</div>
