import { CreateAttachmentForDocumentRequest } from "src/Models/Attachment_Files_Class/AttachmentFilesClass";

export function saveAttachments(selectedFilesAttachment, _AttachmentService, _Id, Form_Id): Promise<number> {
  return new Promise((resolve, reject) => {
    const filesToUpload = selectedFilesAttachment.filter(i => i.originFileObj);
    const documentFiles = filesToUpload.map(f => f.originFileObj);

    if (selectedFilesAttachment.length > 0) {
      const attachmentRequest: CreateAttachmentForDocumentRequest = {
        headerId: _Id,
        companyParameterId: 1,
        formNameId: Form_Id,
        documentFiles: documentFiles
      };
      _AttachmentService.CreateAttachmentForDocument(attachmentRequest).subscribe(
        (attachmentResponse) => {
          resolve(attachmentResponse.result?.id)
        },
        (attachmentError) => {
          console.error('Error creating attachment:', attachmentError);
          reject(attachmentError);
        }
      );
    } else {
      console.warn('No files to upload.');
      resolve(0);
    }
  });
}