<nz-table class="ms-2" #rowSelectionTable   nzAutoWidth  [nzData]="tableData" class="mb-3"
[nzPageSize]="5" nzFrontPagination="false" [nzScroll]="{ x: '300px', y: '295px' }" nzSize="small" nzFooter
>

  <thead>
    <th *ngFor="let column of columnDisplayName">{{ column }}</th>
  </thead>
  
  <tbody>
    <tr *ngFor="let item of rowSelectionTable.data">
        <td class="small-font"  *ngFor="let column of getColumns(item)">{{ item[column] }}</td>
      </tr>
    <!-- <tr *ngFor="let data of rowSelectionTable.data">
      <td   class="small-font"  *ngIf="columnDisplayName.includes('Name')" ><a class="text-decoration-none">
        {{ data.firstName }}  {{data.lastName }}</a></td>
      <td  class="small-font"  *ngIf="columnDisplayName.includes('Role')">{{ data.applicationGroup?.name }}</td>
      <td  class="small-font"  *ngIf="columnDisplayName.includes('Admin Access')">{{ data.isAdminAccess }}</td>
      <td  class="small-font"  *ngIf="columnDisplayName.includes('Login')">
        <ng-container *ngIf="data.loginAccess; else pendingInvite">
          <nz-tag class="active" [nzColor]="'#c4fae2'">Active</nz-tag>
        </ng-container>
        <ng-template #pendingInvite>
          <nz-tag class="inactive" [nzColor]="'#fbe9b1'">INVITE PENDING</nz-tag>
        </ng-template>
      </td>
      <td  class="small-font"  *ngIf="columnDisplayName.includes('Auto Access')">{{ data.isAutoAccess }}</td>
      <td  class="small-font"  *ngIf="columnDisplayName.includes('Email')">{{ data.email }}</td>
      <td  class="small-font"  *ngIf="columnDisplayName.includes('Phone')"><a class="text-decoration-none">{{ data.phoneNumber }}</a></td>
    </tr> -->
  </tbody>
</nz-table>
