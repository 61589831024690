import { Component, EventEmitter, Output } from '@angular/core';
import { NewSurveyComponent } from '../new-survey/new-survey.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-select-servey',
  templateUrl: './select-servey.component.html',
  styleUrls: ['./select-servey.component.css']
})
export class SelectServeyComponent {
  constructor(private modal: NzModalService) {}

  @Output() cancel = new EventEmitter<void>();
 
  isVisibleLead=false
  isVisible10=false
  selectedValue = null;
 selectServeyhandle=true;





 selectServeyModelCancel(): void {
  this.cancel.emit();
  }

  handleLeadSelection(value: any): void {
    this.isVisibleLead = false; 
    
    if (value === 'jack' || value === 'lucy' || value === 'tom') {
      this.isVisible10 = true;
      this.openSelectaSurveyForm();
    } else {
      console.log('Invalid selection or other actions based on the selection.');
    }
  }

  

  openSelectaSurveyForm() {
    const modalRef = this.modal.create({
      nzContent: NewSurveyComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }


}
