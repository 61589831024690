import { CustomerStatusCode } from './../../../shared/component/Models/LookUpStandardFilterCodes';
import { CreateCustomerEmailParameterRequest, CustomerEmailParameterResponse, JobInformationResponse, UpdateCustomerRequest } from './../../../../Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { CustomerInformationResponse, LeadOpportunityResponse } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { ResponseModel, TablePageResponseSingle } from 'src/Models/responseMessage.model';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { StatusSetupResponse } from 'src/Models/SubVendor/subvendorFilter/SubVendorFilterResponse.model';
import { AuditDetailsResponse, FilterFooterAudit } from 'src/Models/Footer/footer.model';
import { FooterService } from 'src/Service/Footer/footer.service';
interface DataItem {
  opportunityTitle: string;
  status: string;
  createdDate: string;
  soldDate: string;
  salesperson: string;
}
interface DataItemJob {
  jobName: string;
  streetAddress: string;
  suburb: string;
  state: string;
  postalCode: string;
  projectManager: string;
}

@Component({
  selector: 'app-new-contact',
  templateUrl: './new-contact.component.html',
  styleUrls: ['./new-contact.component.css']
})



export class NewContactComponent implements OnInit, AfterViewInit {
  @ViewChild('firstNameInput') firstNameInput: ElementRef<HTMLInputElement>;
  @Input() CustomerResponseId: number;
  @ViewChild('phoneInput') phoneInputValidnput: NgForm;
  CustomerResponse: CustomerInformationResponse;
  updateStatus: LookupNameSetupResponse;
  StatusCode = CustomerStatusCode;
  // @Input() CustomerResponse: CustomerInformationResponse;
  @Output() selectedCustomer = new EventEmitter<CustomerInformationResponse>();
  @Output() statusCodeDashbord: CustomerStatusCode;
  additionalEmails: FormArray;
  @Input() leadData: LeadOpportunityResponse[];
  jobInformationData: JobInformationResponse[] = [];
  hideRow: boolean = true;
  againrowhide: boolean = true;
  isLoading: boolean = false;

  @Output() cancel = new EventEmitter<void>();
  @Output() onSaveComplete: EventEmitter<ResponseModel<TablePageResponseSingle<CustomerInformationResponse>>> = new EventEmitter();
  CustomerData: CustomerInformationResponse[];
  destroy$: Subject<void> = new Subject<void>();
  showContactInformationCard: boolean = true;
  showAdditionalEmail: boolean = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Australia, CountryISO.Australia];
  countryCode: CountryISO; // Ensure countryCode is of type CountryISO
  dialCode: string;
  separateDialCode = true;
  showInitialRow: boolean = false;
  phoneInputValid: boolean = false;
  modalTitle: string = "Customer Contact";
  showInviteButton: boolean = false; // New property to control the display of the "Send Invite" button
  showDisabledButton: boolean = false;
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });
  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }
  newAssignedData: any;
  isRecordSaved: boolean = false;
  recordSaved: boolean = false;
  // CustomerInformation: FormGroup;
  confirmModal?: NzModalRef;
  value?: string;
  isVisible: boolean = true;
  initials: string = '';
  displayNameInvalid: boolean = false;
  avatarText: string = '';
  unsavedChanges: boolean = false;
  loadingSave: boolean = false;
  initialFormData: any;
  initialEmail: string | null = null;
  initialLabel: string | null = null;
  SavedRecordResponse: any;
  createdDateTime: any = '';
  loadingSaveAndNew: boolean = false;
  loadingSaveAndClose: boolean = false;
  firstName: string = '';
  lastName: string = '';
  isSaveAndNew: boolean = false;
  showFields: boolean = true;
  isDeleteLoading: boolean = false;
  rows: number = 0;
  maxRows: number = 6;
  showAddButton: boolean = true;
  saveAndNewButtonHide: boolean = true;
  someConditionForActive: boolean = false;  // Set to true when you want ACTIVE status
  someConditionForDisabled: boolean = false;
  someConditionForInactive: boolean = false;
  public ActiveCustomer: CustomerInformationResponse | null = null;
  footerResponse: AuditDetailsResponse;

  constructor(
    private modal: NzModalService,
    private customerInformationService: CustomerInformationService,
    private toastService: NzMessageService,
    private cd: ChangeDetectorRef,
    private CustomerInformationSer: CustomerInformationService,
    private sharedService: LeadOpportunitiesService,
    @Inject(NZ_MODAL_DATA) public data: any,
    private FooterService: FooterService,

  ) {
  }

  ngOnInit(): void {
    this.initializeFormFields();
    this.initializeFormAdditionalEmails();

    if (!this.CustomerResponse) {
      this.CustomerResponse = {
        statusSetup: {
          code: CustomerStatusCode.INACTIVE.code,
          description: CustomerStatusCode.INACTIVE.description,
          name: CustomerStatusCode.INACTIVE.name,
        } as LookupNameSetupResponse
      } as CustomerInformationResponse;

      console.log(this.CustomerResponse.statusSetup.description);

    }


    if (this.CustomerResponseId) {
      this.isLoading = true;
      this.CustomerInformationSer.getCustomerById(this.CustomerResponseId).subscribe(
        (res) => {
          this.CustomerResponse = res.result;
          this.customerRes = res.result;
          if (this.CustomerResponse) {
            this.patchCustomer(this.CustomerResponse);
          this.createAndModifiedByData(this.CustomerResponseId);

          }
          this.isLoading = false;
        }
        
      );
      this.storeInitialFormFieldValues();
    }

    this.sharedService.againrowhide$.subscribe(value => {
      this.againrowhide = value;
    });


  }


  // ================== Form Group Methods Start ==============//
  formFieldValues: any = {};
  changedFields: Set<string> = new Set<string>();
  isRowPrimaryEmailVisible: boolean = false;

  initializeFormFields(): void {
    this.formFieldValues = {
      id: '',
      globalId: '00000000-0000-0000-0000-000000000000',
      firstName: '',
      lastName: '',
      displayName: '',
      streetAddress: '',
      suburb: '',
      state: '',
      postalCode: '',
      phone: '',
      countryCode: this.CountryISO.Australia,
      cellPhone: '',
      dialCode: '',
      primaryEmail: '',
      label: '',
      status: true,
      pictureUrl: '',
      receivedTextMessage: '',
      isJob: false,
      isLeadOpportunity: false,
      contactTypeId: 0,
      StatusSetupCode: CustomerStatusCode.INACTIVE.code,
      createCustomerEmailParameterRequests: [],
    };
  }

  setInitialFormFieldValues(): void {
    this.initialFormFieldValues = JSON.parse(JSON.stringify(this.formFieldValues));
  }

  // ================== Form Group Methods End ==============//


  toggleRowPrimaryEmailVisible(): void {
    this.isRowPrimaryEmailVisible = !this.isRowPrimaryEmailVisible;
  }


  hideRowPrimaryEmailVisible(): void {
    this.isRowPrimaryEmailVisible = false;
    this.formFieldValues.primaryEmail = '';
    this.formFieldValues.label = '';
    this.clearFieldErrors(['primaryEmail', 'label']);
  }

  addAdditionalEmail(): void {
    if (this.formFieldValues.createCustomerEmailParameterRequests.length < 5) {
      this.formFieldValues.createCustomerEmailParameterRequests.push({ email: '', label: '' });
    }
  }

  removeAdditionalEmail(index: number): void {
    this.clearFieldErrors([`email${index}`, `label${index}`]);
    this.formFieldValues.createCustomerEmailParameterRequests.splice(index, 1);
    this.checkErrors();
  }




  // ================== Pre Define Error Handle Methods Start ==============//
  private _validationStates: { [key: string]: NzStatus; } = {};
  public get validationStates(): { [key: string]: NzStatus; } {
    return this._validationStates;
  }
  public set validationStates(value: { [key: string]: NzStatus; }) {
    this._validationStates = value;
  }

  validationMessages: { [key: string]: string } = {};

  readonly validationRules = {
    firstName: { maxLength: { limit: 35, message: 'First Name exceeds the limit.' } },
    lastName: { maxLength: { limit: 35, message: 'Last Name exceeds the limit.' } },
    displayName: { required: 'Required', maxLength: { limit: 75, message: 'Display Name exceeds the limit.' } },
    streetAddress: { maxLength: { limit: 50, message: 'Street Address exceeds the limit.' } },
    phone: { maxLength: { limit: 25, message: 'Phone exceeds the limit.' } },
    suburb: { maxLength: { limit: 50, message: 'Suburb exceeds the limit.' } },
    state: { maxLength: { limit: 3, message: 'State exceeds the limit.' } },
    postalCode: { maxLength: { limit: 15, message: 'Postal Code exceeds the limit.' } },
    primaryEmail: {
      required: 'Required',
      maxLength: { limit: 110, message: 'Primary Email exceeds the limit.' },
      pattern: { regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Invalid email format.' }
    },
    label: { maxLength: { limit: 50, message: 'Label exceeds the limit.' } },
    additionalEmail: {
      required: 'Required',
      maxLength: { limit: 110, message: 'Additional Email exceeds the limit.' },
      pattern: { regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Invalid email format.' }
    },
    additionalLabel: { maxLength: { limit: 50, message: 'Label exceeds the limit.' } },
  };

  // Method to get the label for a field
  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      firstName: 'First Name',
      lastName: 'Last Name',
      displayName: 'Display Name',
      streetAddress: 'Street Address',
      suburb: 'Suburb',
      state: 'State',
      postalCode: 'Postal Code',
      phone: 'Phone',
      label: 'Label',
      primaryEmail: 'Primary Email',
    };
    return labels[field] || field;
  }

  // Get error tips for validation rules
  getErrorTip(field: string, index?: number): string {
    let input: string = '';
    let rules: any = {};

    if (index !== undefined) {
      input = this.formFieldValues.createCustomerEmailParameterRequests[index][field] || '';
      rules = {
        email: {
          required: 'Required',
          maxLength: { limit: 110, message: 'Additional Email exceeds the limit.' },
          pattern: { regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Invalid email format.' }
        },
        label: { maxLength: { limit: 50, message: 'Label exceeds the limit.' } },
      }[field];
    } else {
      input = this.formFieldValues[field] || '';
      rules = this.validationRules[field];
    }

    if (rules.required && !input.trim()) {
      return rules.required;
    }
    if (rules.maxLength && input.length > rules.maxLength.limit) {
      const excessLength = input.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }

    if (rules.pattern && !rules.pattern.regex.test(input)) {
      return rules.pattern.message;
    }

    return ''; // No error
  }






  checkErrors(validateAllFields = false) {
    this.validationStates = {};
    this.validationMessages = {};

    const fieldsToValidate = validateAllFields
      ? Object.keys(this.validationRules)
      : Array.from(this.changedFields);

    // Validate standard fields
    for (const field of fieldsToValidate) {
      if ((field === 'primaryEmail' || field === 'label') && !this.isRowPrimaryEmailVisible) {
        continue;
      }
      if (this.validationRules.hasOwnProperty(field) && field !== 'additionalEmail' && field !== 'additionalLabel') {
        const errorTip = this.getErrorTip(field);
        if (errorTip) {
          this.validationStates[field] = 'error';
          this.validationMessages[field] = errorTip;
        } else {
          this.validationStates[field] = null;
          this.validationMessages[field] = '';
        }
      }
    }

    // Validate dynamic fields only if there are items in the array
    if (this.formFieldValues.createCustomerEmailParameterRequests.length > 0) {
      this.formFieldValues.createCustomerEmailParameterRequests.forEach((item, index) => {
        ['email', 'label'].forEach(dynamicField => {
          const dynamicErrorTip = this.getErrorTip(dynamicField, index);
          const targetField = `${dynamicField}${index}`;
          if (dynamicErrorTip) {
            this.validationStates[targetField] = 'error';
            this.validationMessages[targetField] = dynamicErrorTip;
          } else {
            this.validationStates[targetField] = null;
            this.validationMessages[targetField] = '';
          }
        });
      });
    }

    // Additional check for primaryEmail when status is PENDING
    if (this.formFieldValues.StatusSetupCode === CustomerStatusCode.PENDING.code) {
      const primaryEmail = this.formFieldValues.primaryEmail || '';
      if (!primaryEmail.trim()) {
        this.validationStates['primaryEmail'] = 'error';
        this.validationMessages['primaryEmail'] = 'Primary Email is required for invitations.';
      }
    }

    this.cd.detectChanges();

  }





  clearFieldErrors(fields: string[]): void {
    fields.forEach(field => {
      this.changedFields.delete(field);
      delete this.validationStates[field];
      delete this.validationMessages[field];
    });

    // Revalidate the remaining dynamic fields
    this.formFieldValues.createCustomerEmailParameterRequests.forEach((item, index) => {
      ['email', 'label'].forEach(dynamicField => {
        const targetField = `${dynamicField}${index}`;
        if (this.changedFields.has(targetField)) {
          this.changedFields.delete(targetField);
          delete this.validationStates[targetField];
          delete this.validationMessages[targetField];
        }
      });
    });
  }


  // Get an array of fields with errors
  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }


  onFieldChange(field: string, value: string, index?: number): void {
    if (index !== undefined) {
      // For dynamic fields in createCustomerEmailParameterRequests
      const targetField = `${field}${index}`;
      this.formFieldValues.createCustomerEmailParameterRequests[index][field] = value;
      this.changedFields.add(targetField);
    } else {
      // For regular fields
      this.formFieldValues[field] = value;
      this.changedFields.add(field);
    }

    this.checkErrors();

    if (field === 'firstName' || field === 'lastName') {
      this.updateDisplayName();
    }
  }

  get shouldShowInitials(): boolean {
    return this.formFieldValues.createCustomerEmailParameterRequests.length > 0;
  }


  updateDisplayName(): void {
    const firstName = this.formFieldValues?.firstName?.trim();
    const lastName = this.formFieldValues?.lastName?.trim();
    this.formFieldValues.displayName = `${firstName} ${lastName}`.trim();
  }



  getAvatarInitials(): string {
    const firstName = this.formFieldValues?.firstName?.trim();
    const lastName = this.formFieldValues?.lastName?.trim();
    const displayName = this.formFieldValues?.displayName?.trim();
    let initials = '';

    if (firstName) {
      // Use initials from firstName
      const firstNameParts = firstName?.split(' ');
      if (firstNameParts.length > 0) {
        initials += firstNameParts[0].charAt(0).toUpperCase();
      }
      if (firstNameParts.length > 1) {
        initials += firstNameParts[1].charAt(0).toUpperCase();
      }
      if (initials.length < 2 && lastName) {
        initials += lastName.charAt(0).toUpperCase();
      }
    } else if (lastName) {
      const lastNameParts = lastName?.split(' ');
      if (lastNameParts.length > 0) {
        initials += lastNameParts[0].charAt(0).toUpperCase();
      }
      if (lastNameParts.length > 1) {
        initials += lastNameParts[1].charAt(0).toUpperCase();
      }
    } else if (displayName) {
      const displayNameParts = displayName?.split(' ');
      if (displayNameParts.length > 0) {
        initials += displayNameParts[0].charAt(0).toUpperCase();
      }
      if (displayNameParts.length > 1) {
        initials += displayNameParts[1].charAt(0).toUpperCase();
      }
    }

    return initials || '';
  }



  hasInitials(): boolean {
    return this.getAvatarInitials() !== '';
  }



  getAvatarInitialsInfoCard(): string {
    const firstName = this.CustomerResponse?.firstName?.trim();
    const lastName = this.CustomerResponse?.lastName?.trim();
    const displayName = this.CustomerResponse?.displayName?.trim();
    let initials = '';

    if (firstName) {
      // Use initials from firstName
      const firstNameParts = firstName?.split(' ');
      if (firstNameParts.length > 0) {
        initials += firstNameParts[0].charAt(0).toUpperCase();
      }
      if (firstNameParts.length > 1) {
        initials += firstNameParts[1].charAt(0).toUpperCase();
      }
      if (initials.length < 2 && lastName) {
        initials += lastName.charAt(0).toUpperCase();
      }
    } else if (lastName) {
      const lastNameParts = lastName?.split(' ');
      if (lastNameParts.length > 0) {
        initials += lastNameParts[0].charAt(0).toUpperCase();
      }
      if (lastNameParts.length > 1) {
        initials += lastNameParts[1].charAt(0).toUpperCase();
      }
    } else if (displayName) {
      const displayNameParts = displayName?.split(' ');
      if (displayNameParts.length > 0) {
        initials += displayNameParts[0].charAt(0).toUpperCase();
      }
      if (displayNameParts.length > 1) {
        initials += displayNameParts[1].charAt(0).toUpperCase();
      }
    }

    return initials || '';
  }

  hasInitialsInfoCard(): boolean {
    return this.getAvatarInitialsInfoCard() !== '';
  }



  //  ================== Pre Define Error Handle Methods End==============//



  //  ================== First Field Auto Focus Handle Methods Start==============//

  ngAfterViewInit(): void {
    this.focusTitleInput();
  }

  private focusTitleInput(): void {
    setTimeout(() => {
      this.cd.detectChanges();
      if (this.firstNameInput) {
        this.firstNameInput.nativeElement.focus();
      }
    }, 1000);
  }




  //  ================== Lead Opportunity Data Binding Methods End ==============//



  //  ================== Pre Define Saving Method Start==============//


  isSave: boolean = false;
  isSaveAndClose: boolean = false;
  statusMessage = '';
  isSubmitted: boolean = false;
  modalErrorMessages: string[];

  customerRes: CustomerInformationResponse;
  initialFormFieldValues: any = {};

  resetFlags(): void {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
  }

  private extractPhoneNumberDetails(data: any): void {
    if (data.cellPhone && data.cellPhone.number) {
      const phoneNumber = data.cellPhone.number;
      const dialCode = data.cellPhone.dialCode;
      const countryCodeNumber = data.cellPhone.countryCode;
      data.cellPhone = phoneNumber;
      data.dialCode = dialCode;
      data.countryCode = countryCodeNumber;
    }
  }



  DisabledNextActivity: boolean = false;

 
  saveButton(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save'): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
        return reject('Already processing');
      }
  
      // Set appropriate flags based on the actionType
      this.isSave = actionType === 'save';
      this.isSaveAndClose = actionType === 'saveAndClose';
      this.isSaveAndNew = actionType === 'saveAndNew';
      this.isSubmitted = true;
      this.DisabledNextActivity = true; // Disable next activity
  
      this.checkErrors(true);
  
      if (Object.values(this.validationStates).includes('error')) {
        setTimeout(() => {
          const errorElements = document.querySelectorAll('.error-message');
          if (errorElements.length > 0) {
            const firstErrorElement = errorElements[0];
            firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 100);
        this.resetFlags();
        this.DisabledNextActivity = false; // Re-enable next activity
        return reject('Validation errors present');
      }
  
      this.checkErrorsInfo(true);
  
      if (Object.values(this.validationStatesInfo).includes('error')) {
        setTimeout(() => {
          const errorElements = document.querySelectorAll('.error-message');
          if (errorElements.length > 0) {
            const firstErrorElement = errorElements[0];
            firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 100);
        this.resetFlags();
        this.DisabledNextActivity = false; // Re-enable next activity
        return reject('Validation errors present');
      }
  
      let data = this.formFieldValues;
      this.extractPhoneNumberDetails(data);
  
      if (this.formFieldValues['StatusSetupCode'] === CustomerStatusCode.DISABLED.code ||
          this.formFieldValues['StatusSetupCode'] === CustomerStatusCode.ACTIVE.code) {
        this.CustomerInformationSer.updateEmail(this.formFieldValuesEmails).subscribe(response => {
          this.selectedCustomer.emit();
          this.toastService.success('Contact Saved');
          this.onSaveComplete.emit();
          this.DisabledNextActivity = false; // Re-enable next activity
          resolve();
        },
        (error) => {
          console.error('Error updating email:', error);
          this.DisabledNextActivity = false; // Re-enable next activity
          reject(error);
        }).add(() => {
          this.resetFlags();
          this.modalErrorMessages = [];
          this.isSubmitted = false;
          this.DisabledNextActivity = false; // Re-enable next activity
        });
      } else {
        this.CustomerInformationSer.postData(data).subscribe(response => {
          this.customerRes = response.result;
          this.CustomerResponse = response.result;
          this.formFieldValues.globalId = response.result.globalId;
          this.formFieldValues.id = response.result.id;
          this.isSubmitted = false;
  
          this.toastService.success('Contact Saved');
          this.onSaveComplete.emit();
          this.selectedCustomer.emit(response.result);
          this.DisabledNextActivity = false; // Re-enable next activity
          if (response) {
            this.createAndModifiedByData(response.result.id);
          }
          this.storeInitialFormFieldValues();
          resolve();
        },
        (error) => {
          console.error('Error saving data:', error);
          this.toastService.error('An error has occurred. Please try again.');
          this.DisabledNextActivity = false; // Re-enable next activity
          reject(error);
        }).add(() => {
          this.resetFlags();
          this.modalErrorMessages = [];
          this.isSubmitted = false;
          this.DisabledNextActivity = false; // Re-enable next activity
        });
      }
    });
  }
  




  validateForm(): boolean {
    return true;
  }



  saveCustomerAndCloses(): Promise<void> {
    return this.saveButton('saveAndClose').then(() => {
      this.resetForm();
      this.CustomerModal();
    }).catch((error) => {
      console.error('Error during save and close:', error);
    });
  }

  saveCustomerAndNews() {
    this.saveButton('saveAndNew').then(() => {
      this.resetForm();
    }).catch((error) => {
      console.error('Error during save and new:', error);
    }).finally(() => {
    });
  }



  CustomerModal(): void {
    this.cancel.emit();
    this.isVisible = false;

  }
  resetForm(): void {
    this.initializeFormFields();
    this.setInitialFormFieldValues();

    // Reset validation states and messages
    this.validationStates = {};
    this.validationMessages = {};

    setTimeout(() => {
      const errorElements = document.querySelectorAll('.ant-input-status-error');
      errorElements.forEach((element: HTMLElement) => {
        element.classList.remove('ant-input-status-error');
      });
    }, 0);

    // Clear other related states
    this.customerRes = null;
    this.CustomerResponse = null;
    this.modalErrorMessages = [];
    this.isSubmitted = false;
  }






  patchCustomer(CustomerResponse: CustomerInformationResponse) {
    this.CustomerResponse = CustomerResponse;
    this.formFieldValues['id'] = CustomerResponse?.id;
    this.formFieldValues['globalId'] = CustomerResponse?.globalId;
    this.formFieldValues['createdBy'] = CustomerResponse?.createdBy;
    this.formFieldValues['firstName'] = CustomerResponse?.firstName;
    this.formFieldValues['lastName'] = CustomerResponse?.lastName;
    this.formFieldValues['displayName'] = CustomerResponse?.displayName;
    this.formFieldValues['streetAddress'] = CustomerResponse?.streetAddress;
    this.formFieldValues['suburb'] = CustomerResponse?.suburb;
    this.formFieldValues['state'] = CustomerResponse?.state;
    this.formFieldValues['postalCode'] = CustomerResponse?.postalCode;
    this.formFieldValues['phone'] = CustomerResponse?.phone;
    this.formFieldValues['countryCode'] = CustomerResponse?.countryCode;
    this.cd.detectChanges();
    this.formFieldValues['cellPhone'] = CustomerResponse?.cellPhone;
    this.formFieldValues['dialCode'] = CustomerResponse?.dialCode;
    this.formFieldValues['primaryEmail'] = CustomerResponse?.primaryEmail;
    this.formFieldValues['label'] = CustomerResponse?.label;
    this.formFieldValues['status'] = CustomerResponse?.status;
    this.formFieldValues['pictureUrl'] = CustomerResponse?.pictureUrl;
    this.formFieldValues['receivedTextMessage'] = CustomerResponse?.receivedTextMessage;
    this.formFieldValues['isLeadOpportunity'] = CustomerResponse?.isLeadOpportunitie;
    this.formFieldValues['isJob'] = CustomerResponse?.isJob;
    this.formFieldValues['contactTypeId'] = CustomerResponse?.contactTypeId;
    this.formFieldValues['isActive'] = CustomerResponse?.isActive;
    this.formFieldValues['statusSetupId'] = CustomerResponse?.statusSetupId;
    this.cd.detectChanges();
    this.formFieldValues['StatusSetupCode'] = CustomerResponse?.statusSetup?.code;
    this.formFieldValues.createCustomerEmailParameterRequests == CustomerResponse?.customerEmailParameters
    ?.filter(param => param !== null) // Filter out null values
    .map(param => ({
      email: param.email,
      label: param.label
    })) || [];
    this.isRowPrimaryEmailVisible = !!CustomerResponse?.primaryEmail;
    this.cd.detectChanges();


    // for additional Emails and labels in Active and Disable Customer  Start //

    this.formFieldValuesEmails['id'] = CustomerResponse?.id;
    this.formFieldValuesEmails['globalId'] = CustomerResponse?.globalId;
    this.formFieldValuesEmails['statusSetupCode'] = CustomerResponse?.statusSetup?.code;
    this.formFieldValuesEmails.createCustomerEmailParameterRequests = CustomerResponse?.customerEmailParameters
    ?.filter(param => param !== null) // Filter out null values
    .map(param => ({
      email: param.email,
      label: param.label
    })) || [];
  


    // for additional Emails and labels in Active and Disable Customer  End //
    this.setInitialFormFieldValues(); // Initialize form field values

  }


  getStatusClass(statusCode: string): string {
    switch (statusCode) {
      case CustomerStatusCode.ACTIVE.code:
        return 'status-text active';
      case CustomerStatusCode.INACTIVE.code:
        return 'status-text inactive';
      case CustomerStatusCode.DISABLED.code:
        return 'status-text disabled';
      case CustomerStatusCode.PENDING.code:
        return 'status-text pending';
      default:
        return 'status-text inactive'; // Default class
    }
  }



  getStatusDescription(statusCode: string) {
    return this.CustomerResponse?.statusSetup?.description;
  }

  // Rule InActive And Pending

  setStatusToInActive(): void {
    if (this.formFieldValues.StatusSetupCode === CustomerStatusCode.INACTIVE.code) {
      this.formFieldValues.StatusSetupCode = CustomerStatusCode.PENDING.code;
      this.updateStatus = {
        code: CustomerStatusCode.PENDING.code,
      } as LookupNameSetupResponse;
      this.isRowPrimaryEmailVisible = true;
      this.statusMessage = 'Invite will be sent on save.';

    } else if (this.formFieldValues.StatusSetupCode === CustomerStatusCode.PENDING.code) {
      this.formFieldValues.StatusSetupCode = CustomerStatusCode.INACTIVE.code;
      if (this.updateStatus) {
        this.updateStatus.code = CustomerStatusCode.INACTIVE.code;
        this.isRowPrimaryEmailVisible = false;
        this.validationMessages = {};
        this.validationStates = {};
        this.statusMessage = '';
      }

    }

    this.cd.detectChanges();
  }



  // Method to check if status is inactive
  // isStatusInactive(): boolean {
  //   return this.formFieldValues.StatusSetupCode === CustomerStatusCode.INACTIVE.code;
  // }

  // Method to determine button text
  getButtonLabel(): string {
    const statusCode = this.formFieldValues['statusSetup'] || this.formFieldValues.StatusSetupCode;
    if (statusCode === CustomerStatusCode.PENDING.code) {
      return 'Cancel Invite';
    } else if (statusCode === CustomerStatusCode.INACTIVE.code) {
      return 'Send Invite';
    }
    return '';
  }





  //  ================== Pre Define Saving Method End==============//



  CustomerDelete(globalId: string) {
    this.isDeleteLoading = true;
    this.isLoading = true;
    this.customerInformationService.deleteData(globalId).subscribe(
      response => {
        setTimeout(() => {
          this.toastService.success('Customer  deleted successfully!');
          this.onSaveComplete.emit(response);
          this.closeDialog();
          this.isDeleteLoading = false;
          this.isLoading = false;

        }, 500);
      },
      error => {
        console.error('Error deleting Customer :', error);
        this.isDeleteLoading = false;
        this.isLoading = false;

      }
    );
  }



  ConfirmDelete(globalId: string): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete Customer Contact?',
      nzContent: 'Are you sure you want to delete this Customer Contact?',
      nzOkDanger: true,
      nzOkText: 'Delete Customer Contact',
      nzCancelText: 'Cancel',
      nzModalType: 'confirm',
      nzOkLoading: this.isDeleteLoading,
      nzAutofocus: 'ok',
      nzCentered: true,
      nzOnOk: () => this.CustomerDelete(globalId),
    });
  }


  closeDialog() {
    this.cancel.emit();
  }

  handleCancel(): void {
    this.cancel.emit();
    this.isVisible = false;

  }


  // Rules For Customer Status Start



  // Rule for Resend Invite

  reSendInvite(): void {
    if (this.formFieldValues.StatusSetupCode === CustomerStatusCode.INACTIVE.code) {
      this.formFieldValues.StatusSetupCode = CustomerStatusCode.PENDING.code;
      this.statusMessage = 'Invite will be sent on save.';
    }
  }

  // Rule for Disabled
  disabled(): void {
    if (this.formFieldValues.StatusSetupCode === CustomerStatusCode.ACTIVE.code) {
      this.formFieldValues.StatusSetupCode = CustomerStatusCode.INACTIVE.code;
      this.statusMessage = 'Contact will be disabled on save.';
    } else if (this.formFieldValues.StatusSetupCode === CustomerStatusCode.INACTIVE.code) {
      this.formFieldValues.StatusSetupCode = CustomerStatusCode.ACTIVE.code;
      this.statusMessage = '';
    }
  }
  // Rule for Enable
  enable(): void {
    if (this.formFieldValues.StatusSetupCode === CustomerStatusCode.DISABLED.code) {
      this.formFieldValues.StatusSetupCode = CustomerStatusCode.ACTIVE.code;
      this.statusMessage = 'Contact will be enabled on save.';
    } else if (this.formFieldValues.StatusSetupCode === CustomerStatusCode.ACTIVE.code) {
      this.formFieldValues.StatusSetupCode = CustomerStatusCode.DISABLED.code;
      this.statusMessage = '';
    }
  }

  // Rules For Customer Status End

  isHovered = false;
  //  Info Card visibility
  isInfoCardVisible(): boolean {
    const statusCode = this.CustomerResponse?.statusSetup?.code;
    return statusCode === CustomerStatusCode.DISABLED.code ||
      statusCode === CustomerStatusCode.ACTIVE.code;
  }

  isCardVisible(): boolean {
    const statusCode = this.CustomerResponse?.statusSetup?.code;
    return statusCode === CustomerStatusCode.INACTIVE.code ||
      statusCode === CustomerStatusCode.PENDING.code;
  }





  listOfColumn = [
    {
      title: 'Opportunity Title',
      compare: (a: DataItem, b: DataItem) => a.opportunityTitle.localeCompare(b.opportunityTitle),
      priority: false
    },
    {
      title: 'Status',
      compare: (a: DataItem, b: DataItem) => a.status.localeCompare(b.status),
      priority: false
    },
    {
      title: 'Created Date',
      compare: (a: DataItem, b: DataItem) => a.createdDate.localeCompare(b.createdDate),
      priority: false
    },
    {
      title: 'Sold Date',
      compare: (a: DataItem, b: DataItem) => a.soldDate.localeCompare(b.soldDate),
      priority: false
    },
    {
      title: 'Salesperson',
      priority: false
    }
  ];

  listOfData: DataItem[] = [
    {
      opportunityTitle: '  Opportunity Opportunity Opportunity A',
      status: 'Sold',
      createdDate: 'Jul 3, 2021',
      soldDate: 'Jul 3, 2021',
      salesperson: 'Syed Ahsan Kazmi'
    },
    {
      opportunityTitle: '  Opportunity Opportunity Opportunity B',
      status: 'Sold',
      createdDate: 'Jul 3, 2021',
      soldDate: 'Jul 3, 2021',
      salesperson: 'Syed Ahsan Kazmi'
    },
    {
      opportunityTitle: '  Opportunity Opportunity Opportunity C',
      status: 'Sold',
      createdDate: 'Jul 3, 2021',
      soldDate: 'Jul 3, 2021',
      salesperson: 'Syed Ahsan Kazmi'
    },
    {
      opportunityTitle: '  Opportunity Opportunity Opportunity D',
      status: 'Sold',
      createdDate: 'Jul 3, 2021',
      soldDate: 'Jul 3, 2021',
      salesperson: 'Syed Ahsan Kazmi'
    }
  ];


  listOfColumnJob = [
    {
      title: 'Job Name',
      compare: (a: DataItemJob, b: DataItemJob) => a.jobName.localeCompare(b.jobName),
      priority: false
    },
    {
      title: 'Street Address',
      compare: (a: DataItemJob, b: DataItemJob) => a.streetAddress.localeCompare(b.streetAddress),
      priority: false
    },
    {
      title: 'Suburb',
      compare: (a: DataItemJob, b: DataItemJob) => a.suburb.localeCompare(b.suburb),
      priority: false
    },
    {
      title: 'State',
      compare: (a: DataItemJob, b: DataItemJob) => a.state.localeCompare(b.state),
      priority: false
    },
    {
      title: 'Postal Code',
      compare: (a: DataItemJob, b: DataItemJob) => a.postalCode.localeCompare(b.postalCode),
      priority: false
    },
    {
      title: 'Project Manager',
      priority: false
    }
  ];


  listOfDataJob: DataItemJob[] = [
    {
      jobName: 'Job Opportunity A',
      streetAddress: 'Sold',
      suburb: 'Jul 3, 2021',
      state: 'Jul 3, 2021',
      postalCode: 'Syed Ahsan Kazmi',
      projectManager: 'Syed Ahsan Kazmi',
    }
  ];





  formFieldValuesEmails: any = {};

  initializeFormAdditionalEmails(): void {
    this.formFieldValuesEmails = {
      id: '',
      globalId: '00000000-0000-0000-0000-000000000000',
      statusSetupCode: '',
      createCustomerEmailParameterRequests: [],
    };
  }


  addAdditionalEmailInfoCard(): void {
    if (this.formFieldValuesEmails.createCustomerEmailParameterRequests.length < 5) {
      this.formFieldValuesEmails.createCustomerEmailParameterRequests.push({ email: '', label: '' });
    }
  }

  removeAdditionalEmailInfoCard(index: number): void {
    this.clearFieldErrorsInfoCard([`email${index}`, `label${index}`]);
    this.formFieldValuesEmails.createCustomerEmailParameterRequests.splice(index, 1);
    this.checkErrorsInfo();
  }



  clearFieldErrorsInfoCard(fields: string[]): void {
    // Revalidate the remaining dynamic fields
    this.formFieldValuesEmails.createCustomerEmailParameterRequests.forEach((item, index) => {
      ['email', 'label'].forEach(dynamicField => {
        const targetField = `${dynamicField}${index}`;
        if (this.changedFields.has(targetField)) {
          this.changedFields.delete(targetField);
          delete this.validationStates[targetField];
          delete this.validationMessages[targetField];
        }
      });
    });
  }


  private _validationStatesInfo: { [key: string]: NzStatus; } = {};
  public get validationStatesInfo(): { [key: string]: NzStatus; } {
    return this._validationStatesInfo;
  }
  public set validationStatesInfo(value: { [key: string]: NzStatus; }) {
    this._validationStatesInfo = value;
  }

  validationMessagesInfo: { [key: string]: string } = {};

  readonly validationRulesInfo = {
    label: { maxLength: { limit: 50, message: 'Label exceeds the limit.' } },
    email: {
      required: 'Required',
      maxLength: { limit: 110, message: 'Additional Email exceeds the limit.' },
      pattern: { regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Invalid email format.' }
    }
  };


  // Method to get the label for a field
  getFieldLabelInfo(field: string): string {
    const labels: { [key: string]: string } = {
      label: 'Label',
      email: 'Additional Email',
    };
    return labels[field] || field;
  }


  // Get error tips for validation rules
  getErrorTipInfo(field: string, index?: number): string {
    let input: string = '';
    let rules: any = {};

    if (index !== undefined) {
      input = this.formFieldValuesEmails.createCustomerEmailParameterRequests[index][field] || '';
      rules = this.validationRulesInfo[field];
    } else {
      input = this.formFieldValuesEmails[field] || '';
      rules = this.validationRulesInfo[field];
    }

    if (rules.required && !input.trim()) {
      return rules.required;
    }
    if (rules.maxLength && input.length > rules.maxLength.limit) {
      const excessLength = input.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }
    if (rules.pattern && !rules.pattern.regex.test(input)) {
      return rules.pattern.message;
    }

    return ''; // No error
  }




  checkErrorsInfo(validateAllFields = false) {
    this.validationStatesInfo = {};
    this.validationMessagesInfo = {};

    const fieldsToValidate = validateAllFields
      ? Object.keys(this.validationRulesInfo)
      : Array.from(this.changedFields);

    // Validate standard fields
    for (const field of fieldsToValidate) {
      if (this.validationRulesInfo.hasOwnProperty(field) && field !== 'email' && field !== 'label') {
        const errorTip = this.getErrorTipInfo(field);
        if (errorTip) {
          this.validationStatesInfo[field] = 'error';
          this.validationMessagesInfo[field] = errorTip;
        } else {
          this.validationStatesInfo[field] = null;
          this.validationMessagesInfo[field] = '';
        }
      }
    }

    // Validate dynamic fields
    if (this.formFieldValuesEmails.createCustomerEmailParameterRequests.length > 0) {
      this.formFieldValuesEmails.createCustomerEmailParameterRequests.forEach((item, index) => {
        ['email', 'label'].forEach(dynamicField => {
          const dynamicErrorTip = this.getErrorTipInfo(dynamicField, index);
          const additionalField = `${dynamicField}${index}`;

          if (dynamicErrorTip) {
            this.validationStatesInfo[additionalField] = 'error';
            this.validationMessagesInfo[additionalField] = dynamicErrorTip;
          } else {
            this.validationStatesInfo[additionalField] = null;
            this.validationMessagesInfo[additionalField] = '';
          }
        });
      });
    }
  }






  // Get an array of fields with errors
  get errorFieldsInfo(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStatesInfo)
      .filter(field => this.validationStatesInfo[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabelInfo(field),
        message: this.validationMessagesInfo[field]
      }));
  }


  onFieldChangeInfo(field: string, value: string, index?: number): void {
    if (index !== undefined) {
      // For dynamic fields in createCustomerEmailParameterRequests
      const targetField = `${field}${index}`;
      this.formFieldValuesEmails.createCustomerEmailParameterRequests[index][field] = value;
      this.changedFields.add(targetField);
    } else {
      // For regular fields
      this.formFieldValuesEmails[field] = value;
      this.changedFields.add(field);
    }

    this.checkErrorsInfo();

  }


  async storeInitialFormFieldValues(): Promise<void> {
    await new Promise(resolve => setTimeout(() => {
      this.initialFormFieldValues = JSON.parse(JSON.stringify(this.formFieldValues));
      resolve(true);
    }, 0));
  }

  isFormDirty(): boolean {
    return JSON.stringify(this.formFieldValues) !== JSON.stringify(this.initialFormFieldValues);
  }

  showConfirmCardCustomer(): void {
    const dontSaveText = 'Dont Save'; // Constant to store the 'Don't Save' button text

    if (this.isFormDirty()) {
      this.confirmModal = this.modal.confirm({
        nzTitle: '<b>Save Changes?</b>',
        nzContent: '<p>This Customer has unsaved changes. Do you want to save your changes before closing?</p>',
        nzOkText: 'Save',
        nzOkType: 'primary',
        nzCancelText: dontSaveText,
        nzOkLoading: this.isSaveAndClose,
        nzClosable: true, // Modal can be closed with the close button
        nzOnOk: () => {
          this.resetFlags();
          this.isSaveAndClose = true;
          this.confirmModal.destroy();
          return this.saveCustomerAndCloses()
            .then(() => {
              this.isSaveAndClose = false;
              this.saveCustomerAndCloses();
            })
            .catch((error) => {
              this.isSaveAndClose = false;
              console.error('Error saving Customer:', error);
              // Optionally show an error message to the user
            });
        },
        nzOnCancel: () => {
          const clickedButton = (event.target as HTMLElement).textContent?.trim();
          if (clickedButton === dontSaveText) {
            // Close both the confirm card and the modal
            this.confirmModal.destroy();
            this.resetForm();
            this.handleCancel();
          }
          else {
            this.confirmModal.destroy();
          }
        },
     
        nzStyle: { top: '250px' },
        nzClassName: 'custom-modal-content',
      });
    } else {
      this.resetForm();
      this.handleCancel();
    }
  }

  // footer label
  createAndModifiedByData(id: number) {
    const payload: FilterFooterAudit = {
      id: id,
      formCode: 'CI'
    }
    this.FooterService.createdAndModifyUser(payload).subscribe(res => {
      this.footerResponse = res.result;

    })
  }
}


