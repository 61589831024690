<div class="row mt-5 d-flex justify-content-center align-content-center">
  <div class="col" style="text-align: center;">
    <span>
      <img src="assets/Empty-SVGs/Project-management/todos/todos.svg"
        style="font-size: 100px;">
    </span>
    <div class="row">
      <div class="col mt-2">
        <h1>Get more done with to-dos</h1>
      </div>
    </div>
    <div class="col mt-3">
      <p nz-typography>Add and manage tasks within projects. To-do's can help you and your
        team <br> stay on track, and get work done faster.</p>
    </div>
    <div class="col mt-4"
      style="display: flex; justify-content: center; align-items: center;">
      <nz-space>
        <!-- <a *nzSpaceItem nz-dropdown nz-button nzTrigger="click"
          [nzDropdownMenu]="menu">
          <span nz-popover class="text-primary text-dark fw-bold fs-5" nz-icon
            nzType="ellipsis" nzTheme="outline"></span>
        </a> -->
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item><i class="bi bi-copy me-2 mt-2"></i> Import
              Folders</li>
          </ul>
        </nz-dropdown-menu>
        <button class="rounded-2" nzFocus *nzSpaceItem nz-button nzType="default"><i
            class="bi bi-box-arrow-up-right me-2"></i>Learn How</button>
        <button class="rounded-2" nzFocus *nzSpaceItem nz-button nzType="primary">Add a To-Do</button>
      </nz-space>
    </div>
  </div>
</div>