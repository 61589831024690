

<nz-modal
  [(nzVisible)]="selectServeyhandle"
  [nzTitle]="null" 
  [nzContent]="modalContent"
  [nzFooter]="null"
  (nzOnCancel)="selectServeyModelCancel()"
>





    <ng-template #modalContent>
      <h1>Select a Survey</h1>
     

      <div class="row text-center">
        <div class="col mt-5">
          <label class="sub-text">Please Select a Survey</label>
        </div>
      </div>


      <nz-select
      (ngModelChange)="handleLeadSelection($event)"
      nzShowSearch
      [lang]="true"
      nzAutoFocus="true"
      nzPlaceHolder=""
      [ngModel]="selectedValue"
    >
      <nz-option nzLabel="Jack" nzValue="jack" ></nz-option>
      <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
      <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
    </nz-select>
      
  
  </ng-template>
    

  <ng-template #modalFooter>
    
  </ng-template>
</nz-modal>






