import { map } from 'rxjs';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { ChangeOrderInformationResponse } from 'src/Models/ChangeOrder/ChangeOrder';
import { MessagingResponse, ToDoParameterResponse, ToDoResponse } from 'src/Models/Project-Management/projectManagement';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
import { ToDoInformationService } from 'src/Service/Project-Management/to-do-information.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { DatePipe } from '@angular/common';
// import { MessagingEditPermissionComponent } from '../messaging-edit-permission/messaging-edit-permission.component';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';

interface CommentWithDate {
  jobInformationId:number;
  comments: string;
  createdOn: string;
}
@Component({
  selector: 'messaging-common',
  templateUrl: './messaging-common.component.html',
  styleUrls: ['./messaging-common.component.css']
})
export class MessagingCommonComponent implements OnInit{
  @Input() Job_ID: number;
  @Input() Form_Name_ID : number; 
  @Input() Message_header_ID : number; 
  @Input() Message_headerSet_ID : number; 
  @Input() comments: MessagingResponse[];
  @Input() createOn : any;
  combinedNodesMessaging: NzTreeNodeOptions[] = [];
  confirmModal: import("ng-zorro-antd/modal").NzModalRef<unknown, any>;
  messagesFormValue: any = {};
  newTodosFormFiedls: any = {};
  selectedNodes: any[] = [];
  assgindata: NzTreeNodeOptions[] = [];
  customerData: NzTreeNodeOptions[] = [];
  subVendorData: NzTreeNodeOptions[] = [];
  customerInformationData: NzTreeNodeOptions[] = [];
  combinedNodes: NzTreeNodeOptions[] = [];
  printMessage: string []= []; 
  printMessages: any = ""; 
  createdOnDates: Date[] = [];
  showCard: boolean = false;
  flattenedComments: CommentWithDate[] = [];
  @Input() helpline: (ChangeOrderInformationResponse & any[]) | MessagingResponse[];
  @Input() constVarible: MessagingResponse[] | (ChangeOrderInformationResponse & any[]);
  responseToDopara: ToDoResponse;
  response: MessagingResponse[];
  @Input() responseSet: MessagingResponse;
  MessagingId: number;
  newConstId: number[];
  responses: number[];
  res: any;
  responseGlobalid: number [];
  isDeleteLoading: boolean = false;
  @Output() onSaveComplete  = new EventEmitter();
  data: any;



  constructor(
    private customerInformationService: CustomerInformationService,
    private SubVendorService: SubVendorService,
    public localStorage: AccessLocalStorageService,
    private leadOppService: LeadOpportunitiesService,
    public toastsService: NzMessageService,
    private cdr: ChangeDetectorRef,
    private modal: NzModalService,
    private loadingIndicatorService: LoadingIndicatorService,
    @Inject(NZ_MODAL_DATA) public rowToDoData: any,
    @Inject(NZ_MODAL_DATA) public ChangeOrderInformation: any,
    @Inject(NZ_MODAL_DATA) public setresponse: any, 
    @Inject(NZ_MODAL_DATA) public SelectionInformation: any,
    @Inject(NZ_MODAL_DATA) public WarrantyInformation: any,
    
  ){


    let stoee = this.rowToDoData?.rowToDoData2?.toDoParameters.map(vari => vari.messaging?.messagingParameters.map(param => param.comments));
   // this.comments = stoee?.flat() || [];
    //console.log('commentscommentscomments', this.comments);

   

  }

  ngOnInit(){
    console.log("Message_header_ID ", this.Message_header_ID);
    console.log('this.Message_headerSet_ID ::',this.Message_headerSet_ID);
    this.createOn = this.ChangeOrderInformation?.ChangeOrderInformation?.createdOn ||
    this.setresponse?.setresponse?.createdOn ||
    this.SelectionInformation?.SelectionInformation?.createdOn ||
    this.WarrantyInformation?.WarrantyInformation?.createdOn ||
    this.rowToDoData?.rowToDoData2?.createdOn;

    this.getAllCommentsMessages(this.Message_header_ID);
    this.ForCombo();

    this.messagesFormValue = {
      headerId: this.Message_header_ID,
      formNameId: this.Form_Name_ID,
      comments: '',
      // isViewOwner: true,
      // isViewSubVendor: true,
      JobInformationId: this.Job_ID,
      MessageFormTypeId: 10273,
    };

    // this.rowDatas = this.rowToDoData?.rowToDoData2?.toDoParameters.map(print => print.messagingId);
    // console.log(' this.helpline this.helpline', this.rowDatas);
    // this.rowDatas = this.rowToDoData?.rowToDoData2?.toDoParameters
    // console.log(' this.helpline this.helpline', this.rowDatas);

    this.messagesFormValue['comments']= this.flattenedComments

    
  }


  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      const assgindata = res.result;
      this.assgindata = assgindata.map((vendor) => ({
        title: vendor.fullName,
        key: vendor.id.toString(),
        isLeaf: true,
        isExpanded: true, 
        expanded: true, 
        applicationUserId: vendor.applicationUserId
      }));
      this.combineNodes();
    });

    this.SubVendorService.getData().subscribe((res) => {
      const Subvendor = res.result;
      this.subVendorData = Subvendor.map((vendor) => ({
        title: vendor.companyName,
        key: vendor.id.toString(),
        isLeaf: true,
        isExpanded: true, 
        expanded: true,
        subVendorId: vendor.subVendorId
      }));
      this.combineNodes();
    });

    this.customerInformationService.getData().subscribe((res) => {
      const Subvendor = res.result;
      this.customerInformationData = Subvendor.map((customer) => ({
        title: customer.displayName,
        key: customer.id.toString(),
        isLeaf: true,
        isExpanded: true,
        expanded: true, 
        customerInformationId: customer.customerinformationId
      }));
      this.combineNodes();
    });
  }

  combineNodes(): void {
    if (this.assgindata && this.subVendorData && this.customerInformationData) {
      const internalUsersNode = {
        title: 'internalUser',
        value: '0-0',
        key: '0-0',
        children: this.assgindata,
      };

      const subVendorsNode = {
        title: 'Subs/Vendors',
        value: '0-1',
        key: '0-1',
        children: this.subVendorData,
      };
      const customerInformationNode = {
        title: 'Owners',
        value: '0-2',
        key: '0-2',
        children: this.customerInformationData,
      };

      this.combinedNodes = [internalUsersNode, subVendorsNode];
      this.combinedNodesMessaging = [internalUsersNode, subVendorsNode, customerInformationNode];
    }
  }




  onNodeSelectionChange(selectedNodeKeys: string[]): void {
    if (selectedNodeKeys && selectedNodeKeys.length > 0) {
      const selectedNode = this.combinedNodes.find(node => node.key === selectedNodeKeys[0]);
      const combinedNodesMessaging = this.combinedNodesMessaging.find(node => node.key === selectedNodeKeys[0]);

      if (selectedNode && combinedNodesMessaging) {
        this.selectedNodes.push(selectedNode); 
        this.newTodosFormFiedls.assigneeUserId = selectedNode['applicationUserId'] || '';
        this.newTodosFormFiedls.assigneeSubVendorId = selectedNode['subVendorId'] || '';
        this.messagesFormValue.applicationUserId = combinedNodesMessaging['applicationUserId'] || '';
        this.messagesFormValue.customerInformationId = combinedNodesMessaging['customerInformationId'] || '';
        this.messagesFormValue.subVendorId = combinedNodesMessaging['subVendorId'] || '';
      }
    } else {
      this.newTodosFormFiedls.assigneeUserId = '';
      this.newTodosFormFiedls.assigneeSubVendorId = '';
      this.messagesFormValue.applicationUserId = '';
      this.messagesFormValue.customerInformationId = '';
      this.messagesFormValue.subVendorId = '';
    }
  }

  createMessage() {
    
    let data = { ...this.messagesFormValue };
    // console.log('console.log newTodosFormFiedls 3', this.messagesFormValue);
    
    // console.log('this.responseHeaderId ', this.responseHold);
    if (!this.Message_headerSet_ID || this.Form_Name_ID  <= 0) {
      console.error('Main data not saved yet or invalid responseHold.');
      return;
    }
 
   data.headerId = this.Message_headerSet_ID;

  

  this.leadOppService.PostMessage(data).subscribe(
    (response) => {
      this.res = response.result;
      console.log("show data",this.res);
      this.toastsService.success('MessagingId Created successfully!');

   //this.getAllCommentsMessages(this.res);
   this.getAllCommentsMessages(this.Message_header_ID);
      
    },
    (error) => {
      console.error('Error fetching job data', error);
    }
  );
   console.log('datadatadatadatadata', data, data.headerId,data.comments,data.createdOn);

}


getAllCommentsMessages(MessagingId: number): void {
  this.leadOppService.getAllmessageingData(MessagingId).subscribe(
    (response) => {
      this.response = response.result;
    },
    (error) => {
      console.error('Error fetching messages', error);
    }
  );
}


userDelete(globalid: string) {
  console.log("show globalid",globalid);
  
  this.loadingIndicatorService.show();
  this.isDeleteLoading = true;

  this.leadOppService.deleteDatabymessagingCommments(globalid).subscribe(
    response => {
      console.log("show globalid on behave of respose",response.result.globalId);
      
      setTimeout(() => {
        this.toastsService.success('User deleted successfully!');
        this.onSaveComplete.emit(response);
        this.loadingIndicatorService.hide();
        this.isDeleteLoading = false;
      }, 500); 
    },
    error => {
      console.error('Error deleting user:', error);
      this.loadingIndicatorService.hide();
      this.isDeleteLoading = false;
    }
  );
}

commentsDelete(globalId: string): void {

  this.confirmModal = this.modal.confirm({
    nzTitle: 'Delete Comment?',
    nzContent: 'Are you sure you want to permanently delete this Comment?',
    nzOkDanger: true,
    nzOkText: 'Delete Comment',
    nzCancelText: 'Cancel',
    nzCloseIcon: '', 
    nzIconType: '',
    nzCentered: true,
    nzOnOk: () => this.userDelete(globalId),
  });
}





// openmessaingEditPermissionModal(): void {
//   const modalRef = this.modal.create({
//     nzContent: MessagingEditPermissionComponent,
//     nzFooter: null,
//   });
//   modalRef.componentInstance.cancel.subscribe(() => {
//     modalRef.destroy();
//   });
// }




}
