import { Component } from '@angular/core';

@Component({
  selector: 'app-project-management-site-diaries-related-items',
  templateUrl: './project-management-site-diaries-related-items.component.html',
  styleUrls: ['./project-management-site-diaries-related-items.component.css']
})
export class ProjectManagementSiteDiariesRelatedItemsComponent {

}


