
  <nz-card [nzTitle]="Jobs"   nzType="card"  class="mt-3" nzBorderless="true">
    <ng-template #Jobs>
      <div class="row p-0 m-0">
        <h6 class="p-0 m-0">Jobs</h6>
      </div>
    </ng-template>
    <div nz-row>
      <nz-table
        nzSize="small"
        #smallTable
        #sortTable
        [nzData]="customerResponseData?.jobInformations"
        nzTableLayout="fixed"
        nzShowPagination="false"
      >
        <thead>
            <tr>
              <th nzWidth="100px">JobName</th>
              <th nzWidth="150px">StreetAddress</th>
              <th nzWidth="200px">Suburb</th>
              <th nzWidth="200px">State</th>
              <th nzWidth="200px">PostalCode</th>
              <th nzWidth="200px">ProjectManager</th>
              <!-- Add more headers as needed -->
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of sortTable.data">
              <td (click)="JobCreateFromScratchComponentOpen()">
                <a class="hover blue-text">{{ data.name }}</a>
              </td>
                <td>{{ data.streetAddress }}</td>
                <td>{{ data.suburb}}</td>
                <td>{{ data.state}}</td>
                <td>{{ data.postalCode}}</td>
                <td>{{ data.isAllowOwnerToViewProjectManagerPhoneNumber }}</td>
              </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-card>