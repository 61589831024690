
<nz-layout class="p-0 m-0 famly-inn">
  <nz-header class="p-0 m-0 Unstuck" style="position: sticky; top: 0; z-index: 1;">
    <div class="row ms-3 mt-1">
      <h1 class="p-0 m-0 mt-3">RFIs</h1>
    </div>
  </nz-header>  
  <nz-content >
    <div class="row famly-inn gx-3 p-3">
      <div class="col-12">
          <div class="card border-0 mt-0">
            <div class="card-head border-bottom">
              <div class="row mb-0 p-2 d-flex align-items-center">
                <div class="col">
                  <span class="fw-medium fs-5 ms-2">RFIs Setup</span>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="row">
                      <label class="sub-text me-2">Default RFI reminders</label>
                    </div>
                    <div class="row">
                      <div class="col-2">
                        <nz-select
                        nzShowSearch
                        [(ngModel)]="rFIsForm['rfiReminderId']" name="rfiReminderId">
                        <nz-option [nzLabel]="'Non'" [nzValue]="0"></nz-option>
                        <nz-option
                          *ngFor="let option of DefaultRFIreminders"
                          [nzLabel]="option.name"
                          [nzValue]="option.id"
                        ></nz-option>
                      </nz-select>
                        
                      </div>
                    </div>
                  </div>
                  <div class="row"> 
                    <div class="col-6 mt-2">
                      <label class="sub-text me-2">Send Past Due RFI Reminder up to</label>
                      <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="Include"
                        nzPopoverPlacement="top"></i>
                      <ng-template #Include>
                        <div class="rounded-2">
                          <p style="height: 53  px; width: 210px;font-size: 13px;">
                            The RFI assignee will be reminded that the RFI is past deadline for the amount of days selected.
                          </p>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2 mt-1">
                      <nz-select nzShowSearch nzAllowClear
                        nzPlaceHolder="Select Days Before" [(ngModel)]="rFIsForm['sendPastDueRfiReminderUpToId']" name="sendPastDueRfiReminderUpToId">
                        <nz-option [nzLabel]="'Non'" [nzValue]="0"></nz-option>
                        <nz-option *ngFor="let option of SendPastDueRFIReminderupto" [nzLabel]="option.name"
                          [nzValue]="option.id"></nz-option>
                      </nz-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div class="card border-0 mt-3">
          <div class="card-head border-bottom">
            <div class="row mb-0 p-2 d-flex align-items-center">
              <div class="col">
                <span class="fw-medium fs-6 ms-2">RFI Custom Fields</span>
              </div>
              <div class="col text-end">
                <button (click)="CustomFieldInfo()" nz-button nzType="primary" class="rounded-1 me-0">New Survey
                  Questions</button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12 d-flex alighn-item-center justify-content-center">
                    <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41"
                      xmlns="http://www.w3.org/2000/svg">
                      <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                        <ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse>
                        <g class="ant-empty-img-simple-g" fill-rule="nonzero">
                          <path
                            d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z">
                          </path>
                          <path
                            d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                            class="ant-empty-img-simple-path"></path>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 d-flex alighn-item-center justify-content-center">
                    <span class="ant-empty-normal">No Data</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-content>
  <nz-footer class="p-2 Unstuck">
    <button (click)="rFIsSave()" nz-button nzType="primary" class="rounded-1 me-0">Save</button>
  </nz-footer>
</nz-layout>


<nz-modal nzWidth="40%" [(nzVisible)]="customfieldinfo" [nzTitle]="modalTitle1" [nzFooter]="modalFooter1"
  (nzOnCancel)="handleCancel2()"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '395px', 'overflow-y': 'auto',}"
  [nzStyle]="{ top: '15px' }">
  <ng-template #modalTitle1>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0 fw-medium">RFI Custom Field
      </h1>
    </div>
  </ng-template>

  <div class="content" *nzModalContent>
    <div class="famly-inn card border-0">
      <div class="card-head border-bottom">
        <div class="row mb-1 p-2 d-flex align-items-center">
          <div class="col">
            <span class="fw-medium fs-6 ms-2"> Custom Field Info</span>
          </div>

        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <label class="sub-text">
              Label
              <span class="text-danger fw-bolder">*</span>
            </label>
            <input type="text" class="form-control formField">
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label class="sub-text">
              Data Type
              <span class="text-danger fw-bolder">*</span>
            </label>
            <nz-select nzShowSearch >
              <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
              <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
              <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label class="sub-text">
              Tool Tip Text
            </label>
            <textarea nz-input [nzAutosize]="{ minRows: 2, maxRows: 6 }" 
              class="form-control formField" style="height: 54px; resize: none;"></textarea>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <label nz-checkbox><span class="pira-label">Required?</span></label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label nz-checkbox><span class="pira-label">Include In My Filters</span></label>
          </div>
        </div>
        <div class="row mt-3 border-bottom pb-5">
          <div class="col-12">
            <label class="sub-text">Display Order
              <span class="text-danger fw-bolder">*</span>
            </label>
            <nz-input-number [nzMin]="1" [nzMax]="10" [nzStep]="1" type="number"></nz-input-number>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <label nz-checkbox><span class="pira-label">Allow permitted sub/vendor to view??</span></label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #modalFooter1>
    <div class="mt-2 align-items-center">
      <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
    </div>
  </ng-template>
</nz-modal>