import { Component, EventEmitter, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NewAllowanceComponent } from '../new-allowance/new-allowance.component';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { FiltersName } from 'src/app/Core/Enums/filtersName';

@Component({
  selector: 'app-unassinged-selection',
  templateUrl: './unassinged-selection.component.html',
  styleUrls: ['./unassinged-selection.component.css']
})
export class UnassingedSelectionComponent {

  constructor(private modal: NzModalService, private filterService: FilterSideMenuBarService) { }
  openAllowance() {
    const modalRef = this.modal.create({
      nzContent: NewAllowanceComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  }
  panels = [
    {
      active: true,
      disabled: false,
      name: 'All Choices (1)',
      childPannel: [
        {
          active: false,
          disabled: true,
          name: 'This is panel header 1-1'
        }
      ]
    }
  ];
  PROJECT_MANG_SELEC_ALLOWA_UNASSIGN: string = FiltersName.PROJECT_MANG_SELEC_ALLOWA_UNASSIGN;
  PAGE_ID: number = 33;
  openFilter(component: string, pageId: number) {
    this.filterService.toggleFilter(component, pageId);
  }

}