import { AddContactLearnMoreComponent } from './../add-contact-learn-more/add-contact-learn-more.component';
import { AddContactInstallationInstructionComponent } from './../add-contact-installation-instruction/add-contact-installation-instruction.component';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { Product } from 'src/Models/Products.model';
import { ProductService } from 'src/Service/product.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { NzCustomColumn } from 'ng-zorro-antd/table';
import { NgStyle } from '@angular/common';
import { style } from '@angular/animations';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CustomFieldFormComponent } from 'src/app/shared/component/custom-field-form/custom-field-form.component';

// import { Product } from 'src/Model/Products.model';
interface CustomColumn extends NzCustomColumn {
  name: string;
  required?: boolean;
  position?: 'left' | 'right';
}
interface CustomColumns extends NzCustomColumn {
  name: string;
  required?: boolean;
  position?: 'left' | 'right';
}

@Component({
  selector: 'app-lead-opportunities-add-contact',
  templateUrl: './lead-opportunities-add-contact.component.html',
  styleUrls: ['./lead-opportunities-add-contact.component.css']
})
export class LeadOpportunitiesAddContactComponent {
  constructor(private modal: NzModalService, private cdr: ChangeDetectorRef) {} 

  @Output() cancel = new EventEmitter<void>();

  showFields = false;
  // showCustomFields = false;
  // inputFields = false;
  // showDialog4: boolean = false;
  // products!: Product[];
  LeadOpportunityAddcontact = true;
  doneList: string;

  LeadOpportunityAddcontactCancel(): void {
    this.cancel.emit();
  }


  LearnMoreOpen(): void {
    const modalRef = this.modal.create({
      nzContent: AddContactLearnMoreComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });

  }
  installationInstructionOpen(): void {
    const modalRef = this.modal.create({
      nzContent: AddContactInstallationInstructionComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });

  }



  //   constructor(private productService: ProductService){}


  //   toggleFields() {
  //     this.showFields = !this.showFields;
  //   }

  //   toggleCustomFields() {
  //     this.showCustomFields = !this.showCustomFields;
  //   }
  //   toggleinput() {
  //     this.inputFields = !this.inputFields;
  //   }

  //   movies = ['Movie 1', 'Movie 2', 'Movie 3', 'Movie 4','Movie 5','Movie 6','Movie 7',]; // Replace with your movies array


  //   drops(event: Event) {
  //     const cdkEvent = event as unknown as CdkDragDrop<string[]>;
  //     moveItemInArray(this.movies, cdkEvent.previousIndex, cdkEvent.currentIndex);
  //   }
  //   OffPhase: boolean = false;
  //   position: string = 'top';




  //   showPhase(position: string) {
  //     this.position = position;
  //       this.OffPhase = true;
  //   }

  //   showNewField!: boolean;





  //   activeTab: string = 'costItem';


  //   setActiveTab(tabName: string) {
  //     this.activeTab = tabName;
  //   }



  //     ngOnInit() {
  //         this.productService.getProductsMini().then((data) => {
  //             this.products = data;
  //         });
  //     }
  //     addNewItem() {
  //       const newItem = `New Item ${Math.floor(Math.random() * 100)}`;
  //       this.products.forEach(product => {
  //         product.item = newItem;
  //       });
  //     }
  isCheckedButton = true;
  isVisible = false;


  showNewFieldAdd(): void {
    this.isVisible = true;
  }

  //   drop(event: CdkDragDrop<string[]>) {
  //     if (event.previousContainer !== event.container) {
  //       // When an item is dropped into a different container
  //       const movie = this.movies[event.previousIndex];

  //       // Set inputFields to true to show input fields after dropping
  //       this.inputFields = true;

  //       transferArrayItem(
  //         event.previousContainer.data,
  //         event.container.data,
  //         event.previousIndex,
  //         event.currentIndex
  //       );

  //       // Remove the item from the original section after dropping
  //       this.movies = this.movies.filter((_, index) => index !== event.previousIndex);
  //     } else {
  //       // When an item is moved within the same container
  //       moveItemInArray(this.movies, event.previousIndex, event.currentIndex);
  //     }

  // }




  customColumn: CustomColumn[] = [
    {
      name: 'Name',
      value: 'name',
      default: true,
      required: true,
      position: 'left',
      width: 200,
      fixWidth: true,
      
    },
    {
      name: 'Cell',
      value: 'gender',
      default: true,
      width: 200,
      
    
    },
    {
      name: 'Address',
      value: 'address',
      default: true,
      width: 200
    },
    {
      name: 'Subrub',
      value: 'age',
      default: true,
      width: 200
    },
    {
      name: 'City',
      value: 'age',
      default: true,
      width: 200
    },
    {
      name: 'State',
      value: 'age',
      default: true,
      width: 200
    },
    {
      name: 'Zip',
      value: 'age',
      default: true,
      width: 200
    },{
      name: 'Source',
      value: 'age',
      default: true,
      width: 200
    },{
      name: 'Project Type',
      value: 'age',
      default: true,
      width: 200
    },{
      name: 'Project Start Date',
      value: 'age',
      default: true,
      width: 200
    },
    {
      name: 'Action',
      value: 'action',
      default: true,
      required: true,
      position: 'right',
      width: 200
    }
  ];
  customColumns?: CustomColumns[] = [
    {
      name: 'Name',
      value: 'name',
      default: true,
      required: true,
      position: 'left',
      width: 200,
      fixWidth: true,
      
    },
    {
      name: 'Do you have Land',
      value: 'gender',
      default: true,
      width: 200,
      
    
    },
    {
      name: 'Assist client source land',
      value: 'address',
      default: true,
      width: 200
    },
    {
      name: 'Land Title',
      value: 'age',
      default: true,
      width: 200
    },
    {
      name: 'If No,Date of Tilte',
      value: 'age',
      default: true,
      width: 200
    },
    {
      name: 'Land Size (M2)',
      value: 'age',
      default: true,
      width: 200
    },
    {
      name: 'Land Width (M)',
      value: 'age',
      default: true,
      width: 200
    },{
      name: 'Land Lenght (M)',
      value: 'age',
      default: true,
      width: 200
    },{
      name: 'Easement',
      value: 'age',
      default: true,
      width: 200
    },{
      name: 'Corner Lot',
      value: 'age',
      default: true,
      width: 200
    },{
      name: 'POS',
      value: 'age',
      default: true,
      width: 200
    },{
      name: 'Developer Engineering',
      value: 'age',
      default: true,
      width: 200
    },{
      name: 'Design Guidelines',
      value: 'age',
      default: true,
      width: 200
    },{
      name: 'Level 1 compaction report',
      value: 'age',
      default: true,
      width: 200
    },
    {
      name: 'Siting done',
      value: 'age',
      default: true,
      width: 200
    },{
      name: 'Read Engineering plan',
      value: 'age',
      default: true,
      width: 200
    },{
      name: 'Deposit of $3000',
      value: 'age',
      default: true,
      width: 200
    },{
      name: 'Job Conversion',
      value: 'age',
      default: true,
      width: 200
    },
    {
      name: 'email to client',
      value: 'age',
      default: true,
      width: 200
    },
  ];

  title: CustomColumn[] = [];
  footer: CustomColumn[] = [];
  fix?: CustomColumn[] = [];
  notFix: CustomColumn[] = [];
  



  ngOnInit(): void {
    this.title = this.customColumn.filter(item => item.position === 'left' && item.required);
    this.footer = this.customColumn.filter(item => item.position === 'right' && item.required);
    this.fix = this.customColumn.filter(item => item.default && !item.required);
    this.notFix = this.customColumn.filter(item => !item.default && !item.required);
  }

  drop(event: CdkDragDrop<CustomColumn[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.fix = this.fix.map(item => {
      item.default = true;
      return item;
    });
    this.notFix = this.notFix.map(item => {
      item.default = false;
      return item;
    });
    this.cdr.markForCheck();
  }

  deleteCustom(value: CustomColumn, index: number): void {
    value.default = false;
    this.notFix = [...this.notFix, value];
    this.fix.splice(index, 1);
    this.cdr.markForCheck();
  }

  addCustom(value: CustomColumn, index: number): void {
    value.default = true;
    this.fix = [...this.fix, value];
    this.notFix.splice(index, 1);
    this.cdr.markForCheck();
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.customColumn = [ ...this.fix, ...this.notFix];
    this.isVisible = false;
    this.cdr.markForCheck();
  }

  handleCancel(): void {
    this.isVisible = false;
  }


  addCustomFieldsOpen() {
    const modalRef = this.modal.create({
      nzContent: CustomFieldFormComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      
    });
  }



}


