import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd/message';
import { WarrantyService } from 'src/Service/Project-Management/warranty/warranty.service';
import { CreateWarrantySetupRequest, WarrantySetupResponse } from 'src/app/completed-warranty-service/warrantyService';



@Component({
  selector: 'app-warranty',
  templateUrl: './warranty.component.html',
  styleUrls: ['./warranty.component.css']
})
export class WarrantyComponent implements OnInit {
  // Raza Work //
  @Input() newWarrantyId: number;
  // Raza Work //
  @Output() cancel = new EventEmitter<void>();
  text: string | undefined;
  selectedValue = null;
  demoValue = 3;
  customfieldinfo: boolean = false;
  warrantyVisible: boolean = true;
  counter: { label: string; value: number }[] = [];
  warrantyFormFields: WarrantySetupResponse;
  WarrantyInformationResponse: any;
  WarrantyInformationRequest: WarrantySetupResponse;
  CreateWarrantyInformationRequest: null;
  warrantyForms: FormGroup;
  constructor(private warrantyService: WarrantyService,
    private fb: FormBuilder,
    private toastService: NzMessageService) {
  }

  ngOnInit(): void {
    this.getWarrantySetupArray();
    this.initializeForm();
    for (let i = 1; i <= 30; i++) {
      this.counter.push({ label: i.toString(), value: i });
    }
  }
  initializeForm(): void {
    this.warrantyForms = this.fb.group({
      sendOwnerFeedbackReminderUpToDaysNo: '',  // Make sure it's correctly initialized
      allowSubsVendorsAndOwnersToPrintWarrantyClaims: '',  // Default to false if no value is provided
    });
  }
  getWarrantySetup(): void {
    this.warrantyService.companyWarrantyget()
      .subscribe(res => {
        this.WarrantyInformationRequest = res.result[0];
        console.log("DATA = ", this.WarrantyInformationRequest);
      });
  }
  getWarrantySetupArray(): void {
    this.warrantyService.getCompanyWarranty()
      .subscribe(res => {
        const warranty = res.result;
        this.warrantyForms.patchValue({
          sendOwnerFeedbackReminderUpToDaysNo: warranty.sendOwnerFeedbackReminderUpToDaysNo,
          allowSubsVendorsAndOwnersToPrintWarrantyClaims: warranty.allowSubsVendorsAndOwnersToPrintWarrantyClaims // make sure this is the correct value from API
        });
      });
  }

  saveButton(): void {
    if (this.warrantyForms.valid) {
      const warrantyRequest: CreateWarrantySetupRequest = {
        sendOwnerFeedbackReminderUpToDaysNo: this.warrantyForms.value.sendOwnerFeedbackReminderUpToDaysNo,
        allowSubsVendorsAndOwnersToPrintWarrantyClaims: this.warrantyForms.value.allowSubsVendorsAndOwnersToPrintWarrantyClaims
      };
      this.warrantyService.companyWarrantyPost(warrantyRequest).subscribe((response) => {
        this.toastService.success('Warranty Setting Information Updated successfully!');
        this.cancel.emit();
      }, (error) => {
        console.error('Error saving warranty settings', error);
      });
    } else {
      console.error('Form is invalid');
    }
  }
  CustomFieldInfo() {
    this.customfieldinfo = true;
  }
  handleCancel2(): void {
    this.customfieldinfo = false;
  }
  warrantyCancel(): void {
    this.cancel.emit();
  }


}