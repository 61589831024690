import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-messaging-manage-emails',
  templateUrl: './messaging-manage-emails.component.html',
  styleUrls: ['./messaging-manage-emails.component.css']
})
export class MessagingManageEmailsComponent{
  @Output() cancel = new EventEmitter<void>();
  manageEmail: FormGroup;
 
  constructor(private fb: FormBuilder,){}
  emailForm: FormGroup;
  showEmailForm: boolean = true; // Pehle form dikhao
  maxEmails: number = 6;  // Maximum 6 emails
  manageEmailModal = true;
  initializeForm(): void {
    this.manageEmail = this.fb.group({
      email: [null],
      label: [null],
     
  // Grid Baqi Ha 
    });
  }

  manageEmailsclose(): void {
    this.cancel.emit();
    }
  
}
