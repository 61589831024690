<nz-layout>
  <nz-sider
    class="border"
    nzCollapsible
    [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed"
    [nzWidth]="280"
    [nzTrigger]="null"
  >
    <div class="row">
      <app-side-menu-bar
        (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"
      ></app-side-menu-bar>
    </div>
  </nz-sider>

  <nz-layout>
    <nz-content>
      <div class="inner-content">
        <nz-page-header>
          <nz-page-header-title>
            <nz-breadcrumb>
              <nz-breadcrumb-item>{{
                accessLocalStorageService.getJobName()
              }}</nz-breadcrumb-item>
            </nz-breadcrumb>
            <h1>Schedule</h1>
          </nz-page-header-title>
        </nz-page-header>




        <nz-content class="custom-content">
          <div class="container-fuild mb-4 m-3">
            <nz-tabset nzType="card">
              <nz-tab
                *ngFor="let tab of selectiontabsdashbord"
                [nzTitle]="ScheduleTabTitle(tab)"
              >
                <ng-container *ngIf="tab === 1">
                  <!-- inside tabs Start -->

                  <nz-tabset
                    nzType="card"
                    [nzTabBarExtraContent]="extraTemplate"
                  >
                    <nz-tab
                      *ngFor="let tab of selectiontabsdashbord"
                      [nzTitle]="selectionTabTitle(tab)"
                    >
                      <ng-container *ngIf="tab === 1">
                        <app-schedule-list-empty></app-schedule-list-empty>
                      </ng-container>

                      <ng-container *ngIf="tab === 2">
                        <!-- header -->
                        <div *ngIf="scheduleListViewData?.length === 0 || null">
                          <app-schedule-list-empty></app-schedule-list-empty>
                        </div>

                        <div
                          class="ng-container"
                          *ngIf="scheduleListViewData?.length > 0"
                        >
                          <div style="overflow-y: auto">
                            <nz-table
                              class="w-100 mt-4"
                              #rowSelectionTable
                              nzShowSizeChanger
                              [nzData]="scheduleListViewData"
                              [nzScroll]="{ x: '2000px' }"
                              nzFrontPagination="false"
                              #middleTable
                              nzSize="small"
                              #fixedTable
                              [nzScroll]="{ x: '1200px', y: '340px' }"
                              #virtualTable
                              [nzVirtualItemSize]="54"
                            >
                              <!-- (nzCurrentPageDataChange)="
                                        onCurrentPageDataChange($event)
                                      " -->
                              <thead class="sticky2">
                                <tr>
                                  <th
                                    nzLeft
                                    nzWidth="50px"
                                    [(nzChecked)]="checked5"
                                    [nzIndeterminate]="indeterminate"
                                    (nzCheckedChange)="onAllChecked($event)"
                                  ></th>
                                  <th
                                    *ngFor="let column of listOfColumns"
                                    [nzSortOrder]="column.sortOrder"
                                    [nzSortFn]="column.sortFn"
                                    [nzSortDirections]="column.sortDirections"
                                    [nzWidth]="
                                      column.name === 'Accepted' ||
                                      column.name === 'Pending' ||
                                      column.name === 'Declined' ||
                                      column.name === 'Comments' ||
                                      column.name === '' ||
                                      column.name === 'RFIs'
                                        ? '100px'
                                        : column.name === 'Title' ||
                                          column.name === 'Assigness' ||
                                          column.name === 'Phase'
                                        ? '200px'
                                        : '150px'
                                    "
                                  >
                                    {{ column.name }}
                                    <i
                                      *ngIf="column.icon"
                                      nz-icon
                                      [nzType]="column.icon"
                                      class="filter-icon"
                                    ></i>
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let data of rowSelectionTable.data">
                                  <td
                                    nzLeft
                                    Width="50px"
                                    [nzChecked]="setOfCheckedId.has(data.id)"
                                    (nzCheckedChange)="
                                      onItemChecked(data.id, $event)
                                    "
                                  ></td>

                                  <td nzWidth="'200px'">
                                    {{ data?.phaseSetup?.name }}
                                  </td>

                                  <td
                                    class="cursorTab"
                                    nzEllipsis
                                    nzWidth="'250px'"
                                    (click)="editSchedule(data)"
                                  >
                                    <div
                                      class="circle mt-1"
                                      [style.background-color]="
                                        data?.color?.colorCollection
                                      "
                                    ></div>
                                    <span>{{ data.title }}</span>
                                  </td>

                                  <td nzWidth="'150px'">
                                    <div
                                      style="
                                        display: flex;
                                        justify-content: start;
                                      "
                                    >
                                      <div class="round me-3">
                                        <input
                                          type="checkbox"
                                          [id]="'checkbox-' + data.id"
                                          [checked]="
                                            data.progressPercentage.toString() ===
                                            '100'
                                          "
                                        />
                                        <label
                                          [for]="'checkbox-' + data.id"
                                        ></label>
                                      </div>
                                      <span
                                        >{{ data.progressPercentage }}%</span
                                      >
                                    </div>
                                  </td>

                                  <td
                                    nzWidth="'250px'"
                                    class="align-items-center"
                                  >
                                    <span
                                      >{{ data?.workDays }}
                                      {{
                                        data?.workDays === 1 ? "day" : "days"
                                      }}</span
                                    >
                                  </td>

                                  <td nzEllipsis nzWidth="'250px'">
                                    <span>{{
                                      data?.startOn | date : "MMM d, yyyy"
                                    }}</span>
                                  </td>

                                  <td nzEllipsis nzWidth="'250px'">
                                    <span>{{
                                      data?.endOn | date : "MMM d, yyyy"
                                    }}</span>
                                  </td>
                                  <td nzEllipsis nzWidth="'220px'">
                                    <ng-container
                                      *ngFor="
                                        let assignee of data?.scheduleItemAssignees
                                      "
                                    >
                                      <ng-container
                                        *ngIf="
                                          assignee.applicationUser &&
                                          assignee.subVendor
                                        "
                                      >
                                        <span
                                          >{{
                                            assignee.applicationUser.firstName
                                          }}
                                          {{
                                            assignee.applicationUser.middleName
                                          }}
                                          {{
                                            assignee.applicationUser.lastName
                                          }}</span
                                        >
                                        <span> + 1</span>
                                        <span>{{
                                          assignee.subVendor.companyName
                                        }}</span>
                                      </ng-container>
                                      <ng-container
                                        *ngIf="
                                          assignee.applicationUser &&
                                          !assignee.subVendor
                                        "
                                      >
                                        <span
                                          >{{
                                            assignee.applicationUser.firstName
                                          }}
                                          {{
                                            assignee.applicationUser.middleName
                                          }}
                                          {{
                                            assignee.applicationUser.lastName
                                          }}</span
                                        >
                                      </ng-container>
                                      <ng-container
                                        *ngIf="
                                          !assignee.applicationUser &&
                                          assignee.subVendor
                                        "
                                      >
                                        <span>{{
                                          assignee.subVendor.companyName
                                        }}</span>
                                      </ng-container>
                                    </ng-container>
                                  </td>
                                  <td nzWidth="50px">0</td>
                                  <td nzWidth="50px">0</td>
                                  <td nzWidth="50px">0</td>
                                  <td
                                    class="text-primary cursorTab"
                                    (click)="editComments(data)"
                                    nzWidth="50px"
                                  >
                                    0
                                  </td>
                                  <td
                                    class="text-primary cursorTab"
                                    nzWidth="50px"
                                    (click)="editRfi()"
                                  >
                                    0
                                  </td>
                                  <td><span class="bi bi-eye-fill"></span></td>
                                </tr>
                              </tbody>
                            </nz-table>
                          </div>
                        </div>

                        
                      </ng-container>

                      <ng-container *ngIf="tab === 3">
                        <app-schedule-lgantt-empty></app-schedule-lgantt-empty>
                      </ng-container>
                    </nz-tab>
                  </nz-tabset>

                  <!-- inside tabs End -->
                  <ng-template #extraTemplate>
                    <div class="ng-container">
                      <nz-page-header class="p-0">
                        <nz-page-header-title class="p-0">
                          <!-- <nz-button-group class="p-0 m-0">
                  <button nz-button nzType="default">Calander</button>
                  <button nz-button nzType="default">List</button>
                  <button nz-button nzType="default">Gantt</button>
                </nz-button-group> -->
                        </nz-page-header-title>
                        <nz-page-header-extra>
                          <nz-space>
                            <button
                              nz-button
                              nzType="default"
                              nz-popover
                              [nzPopoverContent]="contentTemplate"
                              nzPopoverPlacement="left"
                              class="me-1"
                            >
                              <h5>
                                <i class="bi bi-gear-fill fs-6"></i>
                                <ng-template #contentTemplate>
                                    <p style="font-size: 13px">
                                      Schedule Setting
                                    </p>
                                </ng-template>
                              </h5>
                            </button>
                            <div
                              class="ant-divider ant-divider-vertical button-vertical-divider"
                              role="separator"
                            ></div>
                            <nz-switch [(ngModel)]="switchValue1"></nz-switch>
                            <span class="ms-1 me-2 spanset">Phases</span>
                            <nz-switch [(ngModel)]="switchValue2"></nz-switch>
                            <span class="ms-1 spanset">Schedule Online</span>
                            <button
                              nz-button
                              nzType="default"
                              class="ms-2 rounded-1"
                            >
                              <i class="bi bi-mortarboard-fill"></i
                              >&nbsp;&nbsp;Learn More
                            </button>
                            <button
                              nz-button
                              nz-dropdown
                              [nzTrigger]="'click'"
                              [nzDropdownMenu]="menu4"
                              class="ms-2 rounded-1"
                            >
                              More Action<span nz-icon nzType="down"></span>
                            </button>
                            <nz-dropdown-menu #menu4="nzDropdownMenu">
                              <ul nz-menu>
                                <li nz-menu-item>Import Schedule</li>
                                <li nz-menu-item>Track Conflicts</li>
                                <li nz-menu-item>Notify Assigned Users</li>
                                <li nz-menu-item>Delete All Items</li>
                                <li nz-menu-item>Export to Excel</li>
                                <li nz-menu-item>SomeThingExpot to PDF</li>
                              </ul>
                            </nz-dropdown-menu>
                            <button
                              nz-button
                              nzType="default"
                              (click)="
                                openFilter(PROJECT_MANG_SCHEDULE,PAGE_ID)
                              "
                              class="ms-2 rounded-1"
                            >
                              <i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter
                            </button>
                            <button
                              nz-button
                              (click)="newSchedulemodalOpen('Schedule')"
                              nzType="primary"
                              class="ms-2 rounded-1"
                            >
                              New Schedule Item
                            </button>
                          </nz-space>
                        </nz-page-header-extra>
                      </nz-page-header>
                    </div>
                  </ng-template>
                </ng-container>

                <!-- main tabs    -->

                <ng-container *ngIf="tab === 2">
                  <app-baseline></app-baseline>
                </ng-container>
                <ng-container *ngIf="tab === 3">
                  <workday-execptions></workday-execptions>
                </ng-container>
              </nz-tab>
            </nz-tabset>
          </div>

          <!-- ////////////////////////////// -->
        </nz-content>

        <!-- ///// content ///// -->

        <nz-footer
          class="custom-footer"
          *ngIf="scheduleListViewData?.length > 0"
        >
          <nz-pagination
            class="d-flex justify-content-start me-5"
            [nzPageIndex]="currentPage"
            [nzTotal]="totalRecords"
            nzSimple
          ></nz-pagination>
          <!-- (nzPageIndexChange)="onPageChange($event)"
            (nzPageSizeChange)="onPageSizeChange($event)" -->
        </nz-footer>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
