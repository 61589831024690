import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { AuditDetailsResponse, FilterFooterAudit } from 'src/Models/Footer/footer.model';
import { ResponseModel } from 'src/Models/responseMessage.model';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  private base_Url: string = environment.apiUrl + 'LookupFilter/Get-FooterAuditById';

  constructor(private http: HttpClient) { }
  //  Footer-Label-Service
  createdAndModifyUser(appliedFilter: FilterFooterAudit): Observable<ResponseModel<AuditDetailsResponse>> {
    const apiUrl = `${this.base_Url}`;
    return this.http.post<ResponseModel<AuditDetailsResponse>>(apiUrl, appliedFilter);
  }


}
