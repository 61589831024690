import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';
import { ReportsComponent } from './components/reports/reports.component';
import { SharedModule } from '@progress/kendo-angular-inputs';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NgZorroModule } from 'src/shared/ng-zorro/ng-zorro.module';
import { SalesreportDetailComponent } from './components/reports/salesreport-detail/salesreport-detail.component';
import { ProjectManagmentDetailComponent } from './components/reports/project-managment-detail/project-managment-detail.component';
import { FinancialReportsDetailComponent } from './components/reports/financial-reports-detail/financial-reports-detail.component';


@NgModule({
  declarations: [
    ReportsComponent,
    SalesreportDetailComponent,
    ProjectManagmentDetailComponent,
    FinancialReportsDetailComponent,
  ],
  imports: [
    CommonModule,
    ReportsRoutingModule, 
    SharedModule,
    NgZorroModule,
    ReactiveFormsModule,
    FormsModule,
    NzModalModule,
    GridModule,
    ExcelModule,
  ],
})
export class ReportsModule { }
