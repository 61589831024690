<nz-modal
    nzWidth="95%"
    [(nzVisible)]="closeCatal"
    [nzTitle]="catalog1"
    [nzFooter]="catalog2"
    (nzOnCancel)="closecatal()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '68vh', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
    <ng-template #catalog1> <div class="row p-0 m-0">
        <h1 class="p-0 m-0 fw-medium">Catalog </h1></div>
    </ng-template>
    <div  class="cantent" *nzModalContent>
<div class="row">
  <div class="col-12">
      <div class="shadow-none rounded border-0 mt-0">
          <div class="-body">
              <div class="row">
                  <div class="col">
                      <ul
                          class="nav nav-tabs border-0  rounded-0"
                          style="font-size: 16px; border-radius: none;"
                      >
                          <li class="nav-item border-0  rounded-0">
                              <a
                                  class="nav-link border-0 rounded-0"
                                  [class.active]="activeTabs === 'costItem'"
                                  (click)="ActiveTab('costItem')"
                              >
                              Cost Items
                              </a>
                          </li>
                          <li class="nav-item  rounded-0 border-0">
                              <a
                                  class="nav-link border-0  rounded-0"
                                  [class.active]="activeTabs === 'costGroups'"
                                  (click)="ActiveTab('costGroups')"
                              >
                              Cost Groups
                              </a>
                          </li>
                      </ul>
                      <div *ngIf="activeTabs === 'costItem'" class="ms-0">
                        <app-catalog-cost-item></app-catalog-cost-item>
                      </div>
                      <div *ngIf="activeTabs === 'costGroups'" class="ms-0">
                        <app-catalog-cost-groups></app-catalog-cost-groups>
                      </div>
                      </div>

                  </div>
      </div>
  </div>
</div>
</div>
    </div>
<ng-template #catalog2>
</ng-template>
</nz-modal>
