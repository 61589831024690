<!-- -->
<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form (ngSubmit)="onFilterApply()">
    <div class="row mt-2">
      <!-- <div class="col-10 p-0">
        <div class="input-group">
          <nz-select [(ngModel)]="formFieldValues['standardFilter']" name="standardFilter"  (ngModelChange)="onChangeStandardFilter($event)" nzShowSearch [lang]="true" nzAutoFocus="true"
          nzPlaceHolder="Standard Filter">
          <nz-option *ngFor="let filter of filterResponseData"
              [nzLabel]="filter.name"
              [nzValue]="filter.id">
          </nz-option>
      </nz-select>
        </div>
    </div> -->








 <div class="col-10 p-0">
      <div class="input-group">
       <nz-select
        [(ngModel)]="formFieldValues['standardFilter']"
        name="standardFilter"
        (ngModelChange)="onChangeStandardFilter($event)"
        nzShowSearch
        [nzAutoFocus]="true"
        nzPlaceHolder="Standard Filter"
        >
        <nz-option *ngFor="let filter of filterResponseData"
         [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')"
         [nzValue]="filter.id"
         nzCustomContent>
         <div class="option-content">
          <!-- Customize content with icons and text -->
          <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
          <span>{{ filter.name }}</span>
         <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

         </div>
        </nz-option>
       </nz-select>
      </div>
     </div>

     <!-- [nzLabel]="filter.name + (filter.isSetAsDefault ? ' DEFAULT' : '')"  -->
      <div class="col-1 mt-1">

        <i class="bi bi-three-dots"
        nz-popover
        [nzPopoverContent]="contentTemplate"
        [nzPopoverPlacement]="'top'"
         (click)="openStandardFilterComponent('SAVE_AS')"></i>


      </div>
    </div>
    <div class="row mt-2" *ngIf="selectedFilter === 1">
      <div class="col">
        <p>Your filter has unsaved changes.</p>
      </div>
    </div>
    <div class="row mt-2" *ngIf="selectedFilter === 0">
      <div class="col">
        <p>Your filter has unsaved changes.</p>
      </div>
    </div>

    <div class="col d-flex mt-3">
      <button (click)="openStandardFilterComponent('APPLY_SAVE_AS')" type="button" nz-button nzType="default"
        class="me-2" style="font-size: 12px;" *ngIf="selectedFilter === 1">Save As</button>
      <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;" *ngIf="selectedFilter === 1"
        type="button">Reset</button>
      <button (click)="editSaveFilterData()" nz-button nzType="primary" type="button" class="me-2"
        style="font-size: 12px;" *ngIf="selectedFilter === 0">Save</button>
      <button (click)="openStandardFilterComponent('APPLY_SAVE_AS')" nz-button nzType="default" class="me-2"
        style="font-size: 12px;" *ngIf="selectedFilter === 0" type="button">Save As</button>
      <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;" *ngIf="selectedFilter === 0"
        type="button">Reset</button>
    </div>
    <div class="row famly-inn">
      <div class="col-12">
        <div class="row">
          <div class="col p-0">
            <label class="sub-text">Keyword</label>
            <i
            class="bi bi-info-circle-fill text-secondary ms-2"
            nz-popover
            [nzPopoverContent]="keywordTemplate"
            nzPopoverPlacement="right"
          ></i>
          <ng-template #keywordTemplate>
            <div class="rounded-2">
              <p style="height: auto; width: 180px">
                Keyword Search allows you to search Leads by keyword for any
                of the fields included in the dropdown.
              </p>
            </div>
          </ng-template>
            <input [(ngModel)]="formFieldValues['keyword']" name="keyword" type="text" class="form-control formField">
          </div>
        </div>

        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Activity Status</label>
            <nz-tree-select [(ngModel)]="formFieldValues['activityStatus']" name="activityStatus" style="width: 100%"
              [nzNodes]="activityStatusNodes" nzShowSearch nzAllowClear="false" nzCheckable
              (ngModelChange)="onActivityStatusChange($event)" nzPlaceHolder="Select Activity Status"
              nzDropdownClassName="dropdownStyle">
            </nz-tree-select>

          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Most Recent Click</label>
            <nz-select nzShowSearch [lang]="true" nzAutoFocus="true" [(ngModel)]="formFieldValues['mostRecentClick']"
              name="mostRecentClick">
              <nz-option *ngFor="let filter of Most" [nzLabel]="filter.label" [nzValue]="filter.value"> </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text"># of Links Clicked</label>
            <nz-select nzShowSearch [lang]="true" nzAutoFocus="true" [(ngModel)]="formFieldValues['ofLinksClicked']"
              name="ofLinksClicked">
              <nz-option *ngFor="let filter of OfLinks" [nzLabel]="filter.label" [nzValue]="filter.value"> </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Lead Status</label>

            <nz-tree-select [(ngModel)]="formFieldValues['leadStatus']" name="leadStatus" style="width: 100%"
              [nzNodes]="leadStatusNodes" nzShowSearch nzAllowClear="false" nzCheckable
              (ngModelChange)="onLeadStatusChange($event)" nzPlaceHolder="Select Lead Status"
              nzDropdownClassName="dropdownStyle">
            </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Activity Type</label>


            <nz-tree-select [(ngModel)]="formFieldValues['tagSetupId']" name="tagSetupId" style="width: 100%"
              [nzNodes]="activityStatusNodes" nzShowSearch nzAllowClear="false" nzCheckable
              (ngModelChange)="onActivitytype($event)" nzPlaceHolder="Select Tags" nzDropdownClassName="dropdownStyle">
            </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Assigned User</label>
              <nz-tree-select
             [(ngModel)]="formFieldValues['assignedUser']"
             name="assignedUser"
             style="width: 100%"
             [nzNodes]="assgindata"
             nzShowSearch
             nzAllowClear="false"
             nzCheckable
             (ngModelChange)="onAssigneduserChange($event)"
             nzPlaceHolder="Select Assigned User"
             nzDropdownClassName="dropdownStyle"
            >
            </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Activity Date</label>
            <nz-select nzShowSearch [lang]="true" nzAutoFocus="true" [(ngModel)]="formFieldValues['activityDate']"
              name="activityDate">
               <nz-option *ngFor="let filter of Activity" [nzLabel]="filter.label" [nzValue]="filter.value"> </nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 p-0 text-end">
        <button nz-button nzType="default" type="button" (click)="resetFilter()">Clear All</button>
        <button class="ms-1" nz-button nzType="primary" type="submit" [disabled]="isFormDirty">Apply</button>
      </div>
    </div>

  </form>
</div>

<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>
