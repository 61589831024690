<nz-modal nzWidth="100%" [(nzVisible)]="visibleCatalogModel" [nzTitle]="CatalogModelTitle"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '100vh', 'overflow-y': 'auto' }"
    [nzStyle]="{ top: '37px' }" (nzOnCancel)="CatalogModelhandle()">
    <ng-template #CatalogModelTitle>
     
    </ng-template>
    <div class="content content-set-background" *nzModalContent>
        <div class="ng-container">
            <div class="card-container">
                <div class="row mb-0" style="margin-top: 120px;">

                    <div class="col-lg-8 offset-lg-2">
                        <div class="col text-center p-0 mb-1">
                            <img src="assets/Empty-SVGs/Files/Documents/Documents.svg">
                        </div>
                        <span class="text-center">
                            <h1 class="fw-medium famly-inn mb-3">
                                No Documents </h1>
                        </span>
                        <p class="text-center mb-3" style="font-size: 16px">This folder is empty, or you don’t have
                            permission to view this content. Add files by clicking the "Upload" button, or you can drop
                            files onto the page to begin uploading.</p>
                        <div class="col mt-2 text-center">
                            <nz-space>
                                <button *nzSpaceItem nz-dropdown nz-button nzTrigger="click" [nzDropdownMenu]="menu">
                                    <span nz-popover class="text-primary text-dark fw-bold fs-5" nz-icon
                                        nzType="ellipsis" nzTheme="outline"></span></button>
                                <nz-dropdown-menu #menu="nzDropdownMenu">
                                    <ul nz-menu>
                                        <li nz-menu-item Class="fw-medium famly-inn"><i
                                                class="bi bi-copy me-2 mt-2"></i> Import
                                            Folders</li>
                                    </ul>
                                </nz-dropdown-menu>
                                <button *nzSpaceItem nz-button nzType="default"><i
                                        class="bi bi-box-arrow-up-right me-2"></i>Learn
                                    How</button>
                                <button nz-button nz-dropdown [nzDropdownMenu]="menu4">
                                    Create
                                    <span nz-icon nzType="down"></span>
                                </button>
                                <nz-dropdown-menu #menu4="nzDropdownMenu">
                                    <ul nz-menu>
                                        <li nz-menu-item (click)="createFolderModelOpen(data)">Folder</li>
                                        <li nz-menu-item>Word Documnet</li>
                                        <li nz-menu-item>Excel Documnet</li>
                                        <li nz-menu-item>From GloblaDocx </li>
                                    </ul>
                                </nz-dropdown-menu>
                                <button *nzSpaceItem nz-button nzType="primary" (click)="createFolderModelOpen(data)">Upload </button>
                            </nz-space>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row ms-2">
                <div class="col-6">
                  <div class="card-columns">
                    <div class="card" *ngFor="let attachmentParam of data?.attachment?.attachmentParameters; let i = index">
                      <div class="card-body">
                        <div nz-row class="file-icon">
                          <!-- Set the image based on file type -->
                          <img *ngIf="isPdfFile(attachmentParam.extension)" src="assets/file-type-pdf2.252x256.png" alt="PDF file" class="file-image" />
                          <img *ngIf="isExcelFile(attachmentParam.extension)" src="assets/file-type-excel.256x239.png" alt="Excel file" class="file-image" />
                          <img *ngIf="isWordFile(attachmentParam.extension)" src="assets/file-type-word.256x239.png" alt="Word file" class="file-image" />
                          <img *ngIf="isTxtFile(attachmentParam.extension)" src="assets/file-type-txt-text-textedit.214x256.png" alt="Text file" class="file-image" />
                          <img *ngIf="isImageFile(attachmentParam.extension)" src="assets/image.512x432.png" alt="Image file" class="file-image" />
                          <span class="font-size">{{ attachmentParam.name }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
    </div>
</nz-modal>