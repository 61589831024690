import { Component } from '@angular/core';

@Component({
  selector: 'app-new-schedule-item-schedule-details',
  templateUrl: './new-schedule-item-schedule-details.component.html',
  styleUrls: ['./new-schedule-item-schedule-details.component.css']
})
export class NewScheduleItemScheduleDetailsComponent {
  selectedValue=null;
  date = null;
  demoValue = 0;
  switchValue1 = false;
  showField=false;
  time: Date | null = null;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);
  showContent=false;
  value1!: number;
  disabled = false;
  value: string[] = ['0-0-0'];




  
  onChange(result: Date): void {
    console.log('onChange: ', result);
  }
  timeFeildShow(){
    this.showField= !this.showField;
  }
  toggleContent() {
    this.showContent = !this.showContent;
  }
  iconState: 'up' | 'down' = 'up';
  toggleShowMore() {
    // Toggle the icon state
    this.iconState = this.iconState === 'up' ? 'down' : 'up';

    // Add your logic to show/hide more content here
  }

}
