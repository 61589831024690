<nz-modal nzWidth="55%" [(nzVisible)]="Taghandle" [nzTitle]="NewDaily1" [nzFooter]="NewDaily2"
  (nzOnCancel)="TagClose()"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '72vh', 'overflow-y': 'auto'}"
  [nzStyle]="{ top: '18px' }">
  <ng-template #NewDaily1>
    <div class="row p-0 m-0">
      <h3 class="p-0 m-0">Signature</h3>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <nz-card nzTitle="Approve Change Order">
      <label class="sub-text">Draw your signature <span class="text-danger fw-bolder ms-1">*</span></label>
      <div class="formField">
        <canvas #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)" name="imageSource" width="750" height="200" class="border-3"></canvas>
    </div>
      <div class="row">
        <div class="col text-start mt-1">
          <label nz-checkbox [(ngModel)]="checked"  >Save Signature</label>
        </div>
        <div class="col text-end mt-1">
          <h3 (click)="clearPad()">Clear</h3>
        </div>
      </div>
    </nz-card>
  </div>
</nz-modal>

<ng-template #NewDaily2>
  <button nz-button nzType="primary" class="rounded-1 me-0" (click)="TagClose()">Cancel</button>
  <button nz-button nzType="primary" class="rounded-1 me-0" (click)="CheckApplicationId(true)">Approve</button>
</ng-template>







    <!-- <nz-card nzTitle="Approve Change Order">
      <label class="sub-text">Draw your signature <span class="text-danger fw-bolder ms-1">*</span> </label>
      <div class="formField">
        <canvas #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)" name="imageSource" width="800"
          height="200" class="border-3"></canvas>
      </div>
      <div class="row">
        <div class="col text-start mt-1">
          <label nz-checkbox [(ngModel)]="checked" (click)="savePad()">Save Signature</label>
        </div>
        <div class="col text-end mt-1">
          <h3 (click)="clearPad()">Clear</h3>
        </div>
      </div>
    </nz-card> -->