import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { NzI18nService, zh_CN, en_US } from 'ng-zorro-antd/i18n';



interface ItemData {
  id: string;
  name: string;
  age: string;
  address: string;
}


@Component({
  selector: 'app-add-new-bill',
  templateUrl: './add-new-bill.component.html',
  styleUrls: ['./add-new-bill.component.css']
})
export class AddNewBillComponent implements OnInit  {
  date = null;
  isEnglish = false;
 size: NzButtonSize = 'small';
demoValue:number=0;
addNewBill: FormGroup;
i = 0;
  editId: string | null = null;
  listOfData: ItemData[] = [];
showWeekNumber: boolean = false;




  constructor(private i18n: NzI18nService,private renderer: Renderer2,
    private fb: FormBuilder,
    
    ) {}

  onChange(result: Date): void {
    console.log('onChange: ', result);
  }

  getWeek(result: Date): void {
    console.log('week: ', getISOWeek(result));
  }

  changeLanguage(): void {
    this.i18n.setLocale(this.isEnglish ? zh_CN : en_US);
    this.isEnglish = !this.isEnglish;
  }
bills:boolean=true
footerRender = (): string => 'extra footer';

isPaymentHistoryVisible: boolean = false;
tabs = [1, 2, 3];

togglePaymentHistory() {
  this.isPaymentHistoryVisible = !this.isPaymentHistoryVisible;
}



autoAdjustTextAreaHeight(event: Event): void {
  const textarea = event.target as HTMLTextAreaElement;
  textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
  textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; // Set the height with a maximum of 200px
}
time: Date | null = null;
defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);
showDueDate: boolean = false;
  showScheduleItem1: boolean = false;

  // Add other necessary properties and methods...

field1:boolean=true
field2:boolean=false
field3:boolean=true

show1(){
  this.field1=true
  this.field2=false
  this.field3=true

}
show2(){
  this.field2=true
  this.field3=false
  
}
// Form Group
initializeForm(): void {
  this.addNewBill = this.fb.group({
    bills: [null],
    title: [null],
    datePaid: [null],
    payTo: [null],
    name: [null],
    description: [null],
    invoiceDate: [null],
    due: [null],
    before: [null],
    after: [null],
    scheduleItem: [null],
    dueDate: [null],
    time: [null],
// Grid Baqi Ha 
  });
}


innerCardTabs(tab: number): string {
  switch (tab) {
    case 1:
      return 'Bills';
    case 2:
      return 'Lien Waiver';
    
    default:
      return '';
  }
}



  startEdit(id: string): void {
    this.editId = id;
  }

  stopEdit(): void {
    this.editId = null;
  }

  addRow(): void {
    this.listOfData = [
      ...this.listOfData,
      {
        id: `${this.i}`,
        name: `Edward King ${this.i}`,
        age: '32',
        address: `London, Park Lane no. ${this.i}`
      }
    ];
    this.i++;
  }

  deleteRow(id: string): void {
    this.listOfData = this.listOfData.filter(d => d.id !== id);
  }

  ngOnInit(): void {
    this.addRow();
    this.addRow();
  }


  
  @Output() cancel = new EventEmitter<void>();
  newBillsvisible=true;

  newcBillsCancel(): void {
  this.cancel.emit();
}

}

function getISOWeek(result: Date): any {
  throw new Error('Function not implemented.');
}
