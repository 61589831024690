<div *ngIf="filterResponseData && filterResponseData.length > 0">
   <!-- <form [formGroup]="jobsmapFilterForm" (ngSubmit)="onSubmit()">
    <div class="row mt-2">
      <div class="col-10 p-0">
        <div class="input-group">
          <nz-select formControlName="standardFilter" (ngModelChange)="onChangeStandardFilter($event)" nzShowSearch
            [lang]="true" nzAutoFocus="true" nzPlaceHolder="Standard Filter">
            <nz-option *ngFor="let filter of filterResponseData" [nzLabel]="filter.name"
              [nzValue]="filter.id"></nz-option>
          </nz-select>
        </div>
      </div>
      <div class="col-1 mt-1">
        <i class="bi bi-three-dots" (click)="openStandardFilterComponent()"></i>
      </div>
    </div>

    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Keyword</label>
        <input formControlName="keyWords" type="text" class="form-control formField">
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Project managers</label>
        <nz-tree-select   formControlName="projectManagers"  [nzNodes]="nodes" nzShowSearch
        nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Status</label>
        <nz-tree-select   formControlName="status"  [nzNodes]="nodes" nzShowSearch
        nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Job Type</label>
        <nz-tree-select   formControlName="jobType"  [nzNodes]="nodes" nzShowSearch
        nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Owners</label>
        <nz-tree-select   formControlName="owners"  [nzNodes]="nodes" nzShowSearch
        nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Map status</label>
        <nz-select formControlName="mapStatus">
          <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
          <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Actual Start </label>
        <nz-select  formControlName="actualStart">
          <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
          <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Actual Completion</label>
        <nz-select formControlName="actualCompletion" >
          <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
          <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Groups</label>
        <nz-tree-select   formControlName="groups"  [nzNodes]="nodes" nzShowSearch
        nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Plans logged </label>
        <nz-select formControlName="plansLodged" >
          <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
          <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Test </label>
        <nz-select formControlName="test">
          <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
          <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Plans Approved Issued</label>
        <nz-select formControlName="plansIssued" >
          <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
          <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
        </nz-select>
      </div>
    </div>

    <div class="row">
      <div class="col text-end">
        <button nz-button nzType="primary">Save</button>
        <button nz-button nzType="default" class="ms-1">Close</button>
      </div>
    </div>
  </form> -->
</div>