<nz-layout class="p-0 m-0 famly-inn">
  <nz-header class="p-0 m-0 Unstuck">
    <div class="row ms-3 mt-1">
      <h1 class="p-0 m-0 mt-3">Clock In</h1>
    </div>
  </nz-header>
  <nz-content>
    <div class="content p-3">
      <div class="row famly-inn gx-3">
        <div class="col-12">
          <div class="card border-0">
            <div class="card-head border-bottom">
              <div class="row py-2">
                <h3 nz-typography><span class="ms-3">Current Shift</span></h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12 text-center">
                  <h1> {{ currentTime | date: 'hh:mm' }}</h1>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-3">
                  <label class="sub-text">Job <span class="text-danger fw-bold">*</span></label>
                  <nz-select nzShowSearch nzAutoFocus="true" nzPlaceHolder="Select Lead"
                    [(ngModel)]="timeClockForm['jobInformationId']" name="jobInformationId">
                    <nz-option class="p-0 m-0" *ngFor="let option of jobList" [nzLabel]="option.name"
                      [nzValue]="option.id"></nz-option>
                  </nz-select>
                </div>
              </div>
              <div nz-col nzSpan="24">
                <label class="sub-text mt-3">User <span class="text-danger">*</span></label>
                <nz-tree-select [nzDropdownStyle]="{ 'max-height': '250px', 'overflow-y': 'auto' }"
                  [(ngModel)]="timeClockForm['applicationUserIds']" name="applicationUserIds" [nzNodes]="userNodes"
                  nzShowSearch nzAllowClear="false" nzCheckable nzCheckAll="true"
                  nzPlaceHolder="Select Division"></nz-tree-select>
              </div>


              <ng-template #Dailyfw2>
                <div class="rounded-2" style="width: 350px; max-height: 300px;">
                  <div class="row">
                    <div class="col-2">
                      <nz-avatar class="mt-1"
                        style="width: 64px; height: 64px; line-height: 64px;font-size: 28px; background-color: rgb(255, 185, 168); color: rgb(139, 20, 57);"
                        nzText="MY"></nz-avatar>
                    </div>
                  </div>
                </div>
              </ng-template>
              <!-- Cost Code -->
              <div class="row">
                <label class="sub-text mt-3">Cost Code</label>
                <div class="col-10">
                  <nz-select nzShowSearch nzAutoFocus="true" nzPlaceHolder="Buildertrend Flat Rate"
                    [(ngModel)]="timeClockForm['costCodeId']" name="costCodeId">
                    <nz-option [nzLabel]="'Buixldertrend Flat Rate'" [nzValue]="0"></nz-option>
                    <nz-option class="p-0 m-0" *ngFor="let option of costSubCode" [nzLabel]="option.title"
                      [nzValue]="option.id"></nz-option>
                  </nz-select>
                </div>
              </div>
              <!-- Cost Code -->
              <div class="row mt-2 gx-5">
                <div class="col-1">
                  <button (click)="newcostcode()" nz-button type="button" class="none-buttom"><span>Add</span></button>
                </div>
                <div class="col-1">
                  <button (click)="newcostcode()" nz-button type="button" class="none-buttom"><span>Edit</span></button>
                </div>
              </div>
              <div nz-row>
                <div nz-col nzSpan="18">
                  <label class="sub-text mt-3">Tags</label>
                  <nz-tree-select name="activityStatus"
                    [nzDropdownStyle]="{ 'max-height': '250px', 'overflow-y': 'auto' }"
                    [(ngModel)]="timeClockForm['createShiftTagParameterRequests']"
                    name="createShiftTagParameterRequests" [nzNodes]="tagNodes" nzShowSearch
                    nzCheckable></nz-tree-select>
                </div>
                <div nz-col nzSpan="6" style="margin-top: 30px; padding: 0px">
                  <button class="btn btn-sm text-primary" (click)="showTags()">
                    Add
                  </button>
                  <button
                    [disabled]="!timeClockForm.createShiftTagParameterRequests || timeClockForm.createShiftTagParameterRequests.length !== 1"
                    class="btn btn-sm text-primary border-0 me-1"
                    (click)="showTagEdit(timeClockForm.createShiftTagParameterRequests)">
                    Edit
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-3"><label class="sub-text ">Notes</label></div>
              </div>
              <div class="row">
                <div class="col-12">
                  <textarea style="border-radius: 5px;" rows="2" class="formField" [(ngModel)]="timeClockForm['notes']"
                    name="notes" nz-input></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-content>
  <nz-footer class="p-2 Unstuck">
    <button nz-button nzType="primary" class="rounded-1 me-0" (click)="saveClockIn()">Clock In</button>
  </nz-footer>
</nz-layout>


<!-- Tags Modal Start -->
<nz-modal nzWidth="35%" [(nzVisible)]="isTagVisible" [nzTitle]="divsionTradeTitle" [nzFooter]="divsionTradeFooter"
  [nzBodyStyle]="{ background: '#f1f4fa', padding: '14px' }" [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="showConfirmData()">
  <ng-template #divsionTradeTitle>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0">Add Time Clock Tag</h1>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <form [formGroup]="TagsForm">
      <nz-card [nzBorderless]="false" nzTitle="Add Time Clock Tag">
        <div class="row">
          <div class="col">
            <label class="sub-text">Title<span class="text-danger fw-bolder">*</span></label>
            <input id="inputField" formControlName="name" type="text" class="form-control formField" [ngClass]="{
                error:
                  TagsForm.get('name')?.invalid &&
                  TagsForm.get('name')?.touched
              }" />
            <div *ngIf="
                TagsForm.get('name').hasError('required') &&
                TagsForm.get('name').touched
              " class="text-danger p-error">
              Required
            </div>
          </div>
        </div>
      </nz-card>
    </form>
  </div>
  <ng-template #divsionTradeFooter>
    <div class="mt-2 align-items-center">
      <button *ngIf="isLoadingEdit" nz-button nzType="default" class="rounded-1 me-0" (click)="ConfirmDeleteTags()">
        Delete
      </button>

      <button nz-button nzType="primary" class="rounded-1 me-0" (click)="SaveOrUpdateData()">
        {{ isLoadingEdit ? "Update" : "Save" }}
      </button>



    </div>
  </ng-template>
</nz-modal>
<!-- Tags Modal End -->

<!-- Switch To Shift Model -->
<nz-modal nzWidth="33%" [(nzVisible)]="shiftCardShow" [nzTitle]="null" [nzContent]="conformContentFirstModal"
  [nzFooter]="null" [nzClosable]="false" (nzOnCancel)="shiftCancel()"
  [nzBodyStyle]="{'background': '#ffffff','padding': '35px','max-height': '400px','overflow-y': 'auto'}"
  [nzStyle]="{ top: '280px' }">
  <ng-template #conformContentFirstModal>
    <div class="row mb-3">
      <div class="col-12">
        <h2 class="">Switch Shifts?</h2>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <p style="color: #a9aaac;" class="fw-medium para-lable">
          Clocking into <strong>{{data?.data?.jobInformation?.name}}</strong> will clock you out of your current shift
          at
          <strong>{{currentSelectedJob}}</strong>.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-end">
        <nz-space>
          <button *nzSpaceItem nz-button class="rounded-1" nzType="default" (click)="shiftCancel()">Close</button>
          <button *nzSpaceItem nz-button class="round ed-1 me-2" nzType="primary" (click)="shiftClock()">Switch
            Shifts</button>

        </nz-space>
      </div>
    </div>
  </ng-template>
</nz-modal>
<!-- Switch To Shift End -->