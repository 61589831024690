import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-lead-opportunity-map-6-lead',
  templateUrl: './lead-opportunity-map-6-lead.component.html',
  styleUrls: ['./lead-opportunity-map-6-lead.component.css']
})
export class LeadOpportunityMap6LeadComponent {
  @Output() cancel = new EventEmitter<void>();
  cardMapVisible= true;

  
  CardMapCancel(): void {
    this.cancel.emit();
  }

  

}
