import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { AddLeadActivityTypeComponent } from '../Modal/add-lead-activity-type/add-lead-activity-type.component';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { colorService } from 'src/Service/Job-List/Job-Information/color.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LeadActivityActivityViewPreDefinedCodes, LeadStatusCode } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CustomerInformationResponse } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { AttachmentResponse, CreateAttachmentForDocumentRequest, LeadOpportunityResponse, LookupNameSetupResponse } from 'src/Models/LeadTags';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ApplicationUserForComboResponse } from 'src/Models/InternalUser/InternalUserJob';
import { ColorResponse, IconResponse } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { ProjectManagementSiteDiariesCreateFilesComponent } from 'src/app/project-management/components/Site-Diaries/project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from '../Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { LeadOpportunityAttachmentsUploadFilesComponent } from '../Modal/lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { LeadActivityTypeService } from 'src/Service/Lead-Activity-Type/lead-activity-type.service';
import { Subscription } from 'rxjs';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { GoogleMapComponent } from '../lead-Opportunity-map/google-map/google-map.component';
import { AddLeadActivityTypeResponse, CreateAddLeadActivityTypeRequest } from 'src/Models/LeadActivity/leadActivityClass';
import { LeadActivityResponse, LeadActivityResponses, LeadActivityTemplateResponses } from 'src/Models/LeadActivityTemplate/LeadActivityTemplate';
import { LeadActivityTemplateResponse } from 'src/Models/LeadActivityTemplate/LeadActivityTemplate';
import { environment } from 'src/environments/environment.prod';
import { CustomerInformation } from '../../../../Models/CustomerInfromation/CustomerInformation';
import { Bold, ClassicEditor, Essentials, Font, Heading, Indent, IndentBlock, Italic, Link, List, MediaEmbed, Mention, Paragraph, Table, Undo, FontSize, FontFamily, Style, FontBackgroundColor, FontColor, BlockQuoteCommand, BlockQuote, Image, HorizontalLine, UndoEditing, ImageUpload } from 'ckeditor5';
import { WProofreader } from '@webspellchecker/wproofreader-ckeditor5';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { NzImageService } from 'ng-zorro-antd/image';
import { AttachmentAnnotationComponent } from '../attachment-annotation/attachment-annotation.component';
import { FooterService } from 'src/Service/Footer/footer.service';
import { AuditDetailsResponse, FilterFooterAudit } from 'src/Models/Footer/footer.model';
export class ApplicationUsersId {
  applicationUserId: number = 0
}

interface CustomUploadFile extends NzUploadFile {
  loading?: boolean;
  error?: boolean;
  addedBy: number
  createdOn: Date;
  modifiedOn: Date;
  id: number;
  globalId: string;
}

@Component({
  selector: 'app-lead-activity-form',
  templateUrl: './lead-activity-form.component.html',
  styleUrls: ['./lead-activity-form.component.css']
})
export class LeadActivityFormComponent implements OnInit, OnDestroy {
  @ViewChild('ckeditorInstance') ckeditor: CKEditorComponent;
  public Editor = ClassicEditor;
  alertMessage: string = '';
  attachments: AttachmentResponse[] = [];
  public datas: number[] = []; public isMark: boolean;
  public leadOptionsNew: LeadOpportunityResponse;
  @Input() activityResponseId: number;
  @Input() LeadOpportunityResponse: LeadOpportunityResponse;
  @Input() markComplete: boolean;
  @Input() NewLeadTemplate: LeadActivityTemplateResponse;
  @Input() NewLeadTemplates: LeadActivityTemplateResponses;
  @Input() NewLeadTemplateheadId: LeadActivityTemplateResponse;
  @Output() leadActivityDeleted = new EventEmitter<string>();
  activityResponse: LeadActivityResponse;
  activityResponses: LeadActivityResponses;
  currentSubject: string | null = null;
  @Output() cancel = new EventEmitter<void>();
  @Output() TestNew = new EventEmitter();
  @Output() leadActivityOnSavedCompleted = new EventEmitter<LeadActivityResponse>();
  showContactForm: boolean = false;
  showContactLead: boolean = false;
  tabs = [1, 2];
  confirmModal?: NzModalRef;
  initialFormFieldValues: any = {};
  isButtonDisabled: boolean = true;
  private subscription: Subscription;
  isSaveAndClose: boolean = false;
  isSaveAndNew: boolean = false;
  isSave: boolean = false;
  isAttachmentQueued: boolean = false;
  private isDataEmitted = false; // Flag to check if data has been emitted
  formFieldValues: any = {};
  leadActivtyVisibility = true;
  isMarkVisibility: boolean = false;
  editorValue: string = '';
  showEmailFields: boolean = false;
  @Input() dataSet: LeadOpportunityResponse;
  @Input() addLeadActivityTypeResponse: AddLeadActivityTypeResponse;
  assignedUser: ApplicationUserForComboResponse[] = [];
  attendeesNodes: NzTreeNodeOptions[] = [];
  reminder: LookupNameSetupResponse[] = [];
  selectedDescription: string = '';
  colors: ColorResponse[] = [];
  icons: IconResponse[] = [];
  afterDeleteAddLeadActivityType: CreateAddLeadActivityTypeRequest;
  leadActivityType: AddLeadActivityTypeResponse[] = [];
  leadActivityTypes: AddLeadActivityTypeResponse[];
  colorget: AddLeadActivityTypeResponse;
  private isBuildertrendMessageClicked: boolean = false;
  private isExternalEmailClicked: boolean = false;
  typeStatus: string;
  currentButtonClick: 'attachBuildertrendMessage' | 'attachExternalEmail' | null = null;
  selectedColor: string;
  selectedColorName: string = '';
  nameS: string;
  PAGE_ID: number = 4;
  selectedLeadName: any;
  public customerContactList: CustomerInformationResponse;
  public leadOpportunityRes: LeadOpportunityResponse;
  imageUploaded = false;
  applicationUserId: any[] = [];
  customerInformationId: number = 0;
  savecustomerInformationId: number = 0;
  savedName: string;
  _isCheckedButton: boolean
  attachmentResponse: number;
  isSelected: boolean;
  dataSetForLeadOp: string;
  isLoading: boolean = false;
  LeadStatus: LeadStatusCode;
  leadOpportunity: LeadOpportunityResponse;
  footerResponse: AuditDetailsResponse;
  showEmailContent = false;
  inputValue: string = '';
  primaryEmail: string | undefined;
  userNames: number;
  formattedEmail: string | undefined;
  showActivityResponse = false;
  showAttachBuildertrendMessageButton: boolean = true;
  uniqueIdentifier: string = 'xwizrg_6_ltwifyuvguvjlov27eudcn9zfwww12wzvi_ix_4810cc1e809';
  domain: string = 'leads.buildertrend.net';
  statusCode: { code: string; description: string; color?: string } | null = null;
  isDisabled: boolean = false;
  alertMessageVisible: boolean = false;
  IMG_BASE: string = environment.IMG_BUCKET_URL;
  attachment: AttachmentResponse[] = [];
  testing: AttachmentResponse[] = [];
  ismark: boolean = false;
  @Input() footerData: AuditDetailsResponse | null = null;
  constructor(
    private leadActivitiesService: LeadActivityTypeService,
    private leadOpportunitiesService: LeadOpportunitiesService,
    private modal: NzModalService,
    private colorService: colorService,
    private customerInformationService: CustomerInformationService,
    private LookupFilterService: LookupFilterService,
    public accessLocalStorageService: AccessLocalStorageService,
    public internalUserService: InternalUserService,
    private toastService: NzMessageService,
    private cdn: ChangeDetectorRef,
    private nzImageService: NzImageService,
    private changeDetectorRef: ChangeDetectorRef,
    private loadingIndicatorService: LoadingIndicatorService,
    private FooterService:FooterService,
    @Inject(NZ_MODAL_DATA) public IsTrue: any,
    @Inject(NZ_MODAL_DATA) public leadSelectedData: any,
    @Inject(NZ_MODAL_DATA) public leadData: any,
    @Inject(NZ_MODAL_DATA) public LeadOpportunitiesActivityTemplatesResponse: any,
    @Inject(NZ_MODAL_DATA) public data: any,
    @Inject(NZ_MODAL_DATA) public holdResponse: any,
    @Inject(NZ_MODAL_DATA) public isOther: any,
    @Inject(NZ_MODAL_DATA) public headerIdValue: any,
  ) {
    // Log the leadOptionsNew object directly
    this.leadOptionsNew = data?.leadOptionsNew;
    this.leadData = data?.leadData;
    this.isMark = data?.isMark;
    this.isOther = data?.isOther;
    this.isButtonDisabled = !(this.leadOptionsNew?.attachment?.attachmentParameters?.some(param => param.attachmentId > 0));
    if (this.leadOptionsNew?.customerInformation) {
    } else {
      console.log("Customer information is not available.");
    }
    console.log(' _leadTemplateId', headerIdValue?.headerIdValue);
    console.log(' 2334556566!@#$', this.leadOptionsNew);



  }
  preDefinedDataCodes = {
    activitytype: LeadActivityActivityViewPreDefinedCodes.ActivityType,
    reminderFeild: LeadActivityActivityViewPreDefinedCodes.Reminder,
  };
  test1234: boolean

  customerInformation: any;

  // ngOnInit(): void {
  //   this.customerInformation = this.leadOptionsNew?.customerInformation;
  //   console.log('Customer Information:', {
  //     firstName: this.customerInformation?.firstName,
  //     lastName: this.customerInformation?.lastName,
  //     cellPhone: this.customerInformation?.cellPhone,
  //     primaryEmail: this.customerInformation?.primaryEmail,
  //     streetAddress: this.customerInformation?.streetAddress,
  //     suburb: this.customerInformation?.suburb,
  //     state: this.customerInformation?.state,
  //     postalCode: this.customerInformation?.postalCode
  //   });
  //   this.checkFields();
  //   this.toggleFields();
  //   if (this.activityResponseId) {
  //     this.isLoading = true;
  //     this.leadOpportunitiesService.ActivityGetbyId(this.activityResponseId).subscribe(
  //       (res) => {
  //         this.activityResponses = res.result;
  //         this.primaryEmail = this.activityResponses?.CustomerPrimaryEmail;
  //         this.userNames = this.activityResponse?.attachmentId
  //         if (this.activityResponses?.MarkComplete === true) {
  //           this.test1234 = true;
  //         }
  //         if (this.activityResponses?.Subject || this.activityResponses?.Body) {
  //           this.showActivityResponse = true;
  //         } else {
  //           this.showActivityResponse = false;
  //         }
  //         if (this.activityResponses) {
  //           this.Newpacth(this?.activityResponses);
  //           this.fetchAttachments();
  //         }
  //         if (this.activityResponses && this.activityResponses?.CustomerInformationId) {
  //           this.customerInformationId = this.activityResponses?.CustomerInformationId;
  //         }
  //         if (this.activityResponses && this.activityResponses?.Subject) {
  //           const Subject = this.activityResponses?.Subject;

  //           if (Subject?.length > 0) {
  //             this.currentSubject = Subject;  // Store the subject if needed
  //             this.isButtonDisabled = false;  // Enable the button
  //           } else {
  //             this.currentSubject = null;  // Clear the subject
  //             this.isButtonDisabled = true;  // Disable the button
  //           }
  //         } else {
  //           this.currentSubject = null;  // Clear the subject
  //           this.isButtonDisabled = true;  // Disable the button
  //         }
  //         this.isLoading = false;
  //       }
  //    ) }

  //    if(this.activityResponses?.LeadTitle){
  //     this.dataSetForLeadOp = this.activityResponses?.LeadTitle;
  //   } else if(this.LeadOpportunityResponse?.title){
  //     this.dataSetForLeadOp = this.LeadOpportunityResponse?.title
  //   }
  //   if (this.IsTrue?.isMark) {
  //     this.formFieldValues.markComplete = this.IsTrue?.isMark;
  //     this.isMarkVisibility = this.IsTrue?.isMark;
  //   } else {
  //     this.formFieldValues.markComplete = false;
  //     this.isMarkVisibility = false;
  //   }
  //   this.nameS = this.NewLeadTemplate?.name
  //   this.activityTypeGet();
  //   this.leadActivityFormGroup();
  //   this.initLoad();
  //   this.loadAssignedUsers();
  //   this.ForCombo();
  //   this.afterSaveActivityTypeGet();
  //   this.getColor();
  //   const activityDataSet = this.dataSet?.leadActivities;
  //   this.leadOpportunitiesService.getSelectedFiles().subscribe(files => {
  //     this.selectedFiles = files;
  //   });
  //   this.assignedUserSelecte();
  // }

  ngOnInit(): void {
    this.initializeCustomerInformation();
    this.checkFields();
    this.toggleFields();

    if (this.activityResponseId) {
      this.fetchActivityResponse();
    }

    this.setupLeadOpportunityTitle();
    this.setMarkComplete();

    // Initialize and load form controls, data, and dropdowns
    this.nameS = this.NewLeadTemplates?.Name;
    this.leadActivityFormGroup();
    this.getColor();
    this.activityTypeGet();
    this.initLoad();
    this.loadAssignedUsers();
    this.ForCombo();
    this.afterSaveActivityTypeGet();
    this.loadSelectedFiles();
    this.assignedUserSelecte();
  }

  private initializeCustomerInformation(): void {
    this.customerInformation = this.leadOptionsNew?.customerInformation;
    console.log('Customer Information:', {
      firstName: this.customerInformation?.firstName,
      lastName: this.customerInformation?.lastName,
      cellPhone: this.customerInformation?.cellPhone,
      primaryEmail: this.customerInformation?.primaryEmail,
      streetAddress: this.customerInformation?.streetAddress,
      suburb: this.customerInformation?.suburb,
      state: this.customerInformation?.state,
      postalCode: this.customerInformation?.postalCode
    });
  }

  private fetchActivityResponse(): void {
    this.isLoading = true;
    this.leadOpportunitiesService.ActivityGetbyId(this.activityResponseId).subscribe((res) => {
      this.activityResponses = res.result;
      this.primaryEmail = this.activityResponses?.CustomerPrimaryEmail;
      this.userNames = this.activityResponse?.attachmentId;

      this.test1234 = !!this.activityResponses?.MarkComplete;
      this.showActivityResponse = !!(this.activityResponses?.Subject || this.activityResponses?.Body);

      if (this.activityResponses) {
        this.Newpacth(this.activityResponses);
        this.fetchAttachments();
        this.customerInformationId = this.activityResponses?.CustomerInformationId || null;
      }

      this.updateSubjectState();
      this.isLoading = false;
    });
  }

  private updateSubjectState(): void {
    const subject = this.activityResponses?.Subject;
    if (subject?.length > 0) {
      this.currentSubject = subject;  // Store the subject if needed
      this.isButtonDisabled = false;  // Enable the button
    } else {
      this.currentSubject = null;  // Clear the subject
      this.isButtonDisabled = true;  // Disable the button
    }
  }

  private setupLeadOpportunityTitle(): void {
    if (this.activityResponses?.LeadTitle) {
      this.dataSetForLeadOp = this.activityResponses?.LeadTitle;
    } else if (this.LeadOpportunityResponse?.title) {
      this.dataSetForLeadOp = this.LeadOpportunityResponse?.title;
    }
  }

  private setMarkComplete(): void {
    if (this.IsTrue?.isMark) {
      this.formFieldValues.markComplete = this.IsTrue.isMark;
      this.isMarkVisibility = this.IsTrue.isMark;
    } else {
      this.formFieldValues.markComplete = false;
      this.isMarkVisibility = false;
    }
  }

  private loadSelectedFiles(): void {
    this.leadOpportunitiesService.getSelectedFiles().subscribe(files => {
      this.selectedFiles = files;
     
    });
    this.assignedUserSelecte();
    this.storeInitialFormFieldValues();
    console.log(this.formFieldValues);

  }



  assignedUserSelecte() {
    const userId = this.accessLocalStorageService.getUserLoginId();
    if (userId) {
      this.formFieldValues['assignedUserId'] = userId;
    }
    this.storeInitialFormFieldValues();
  }
    leadActivityFormGroup(): void {

      const currentDateTime = new Date();
      this.formFieldValues.startTime = currentDateTime;
      this.formFieldValues = {
        id: 0,
        globalId: '00000000-0000-0000-0000-000000000000',
        leadOpportunityId: this.LeadOpportunityResponse?.id || this.holdResponse?.leadData?.id || this.activityResponses?.LeadId,
        leadActivityTemplateId: this.headerIdValue?.headerIdValue || 0,
        markComplete: this.formFieldValues?.markComplete,
        followUpDate: null,
        followUpTime: null,
        activityTypeId: 0,
        colorId: 0,
        activityOn: new Date(),
        startTime: new Date(currentDateTime.getTime()),
        endTime: null,
        reminderId: 0,
        assignedUserId: '',
        initiatedBy: 'isSelected',
        salesperson: false,
        lead: false,
        other: true,
        address: '',
        suburb: '',
        state: '',
        postalCode: '',
        description: '',
        subject: '',
        body: '',
        typeStatus: '',
        createLeadActivityUserParameterRequests: []
      };
  }
  
  onStartTimeChange(): void {
    const timeValue = this.formFieldValues['startTime'];
    if (timeValue) {
      const selectedTime = new Date(timeValue);
      console.log('Selected Time:', selectedTime.toLocaleString());
      console.log('Selected Time (UTC):', selectedTime.toUTCString());
      console.log('Timezone Offset (Minutes):', selectedTime.getTimezoneOffset());
      console.log('Selected Time:', this.formFieldValues['startTime']);
    }
  }
  
  
  

  setInitialFormFieldValues(): void {
    this.initialFormFieldValues = JSON.parse(JSON.stringify(this.formFieldValues));
  }


  // onStartTimeChange(time: Date): void {
  //   this.formFieldValues['startTime'] = time;
  // }

  adjustTimeWithTimeDifference(selectedTime: Date, timeDifference: number): Date {
    const adjustedTime = new Date(selectedTime);
    adjustedTime.setHours(adjustedTime.getHours() + timeDifference); // Adjust by 5 hours (can be positive or negative)
    return adjustedTime;
  }

  fetchAttachments() {
    if (this.activityResponses?.AttachmentId) {
      this.leadOpportunitiesService.getLeadByAttachmentId(this.activityResponses.AttachmentId).subscribe(
        (res) => {
          const attachmentParameters = res.result?.attachmentParameters;
          this.selectedFiless = attachmentParameters.map(att => ({
            uid: att.id?.toString() || '',
            name: att.name || '',
            status: 'done',
            url: this.IMG_BASE + att.filePath,
            thumbUrl: this.IMG_BASE + att.filePath,
            type: this.getMimeType(att.extension),
            originFileObj: new File([new Blob()], att.name || '', {
              type: this.getMimeType(att.extension),
              lastModified: new Date(att.modifiedOn).getTime()
            }),
            loading: false,
            error: false,
            addedBy: att.createdBy || 0,
            createdOn: new Date(att.createdOn),
            modifiedOn: new Date(att.modifiedOn),
            id: att.id || 0,
            globalId: att.globalId || ''
          }));
        },
        () => {
        }
      );
    } else {
      this.attachments = [];
    }
  }
  getMimeType(extension: string): string {
    switch (extension) {
      case '.pdf':
        return 'application/pdf';
      case '.doc':
      case '.docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          case '.json':
        return 'application/json';
        case '.txt':
        return 'text/plain';
      case '.xls':
      case '.xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case '.ppt':
      case '.pptx':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'mp4':
      case 'avi':
        return 'video/mp4';
      default:
        return 'application/octet-stream';
    }
  }







  getInitiatedByValue(dataSet: LeadActivityResponse): string {
    if (dataSet?.salesperson) return 'A';
    if (dataSet?.lead) return 'B';
    return 'isSelected';
  }
  getInitiatedByValues(dataSet: LeadActivityResponses): string {
    if (dataSet?.Salesperson) return 'A';
    if (dataSet?.Lead) return 'B';
    return 'isSelected';
  }



  checkFields() {
    const customerInfo = this.LeadOpportunityResponse?.customerInformation;
    this.showContactForm = !!(customerInfo?.streetAddress && customerInfo?.suburb
      && customerInfo?.state && customerInfo?.postalCode);
       this.showContactLead = !!(this.LeadOpportunityResponse?.streetAddress
      && this.LeadOpportunityResponse?.suburb
      && this.LeadOpportunityResponse?.state
      && this.LeadOpportunityResponse?.postalCode);
  }

  patchContact() {
    console.log('patchContact called');
    if (this.LeadOpportunityResponse?.customerInformation) {
      this.formFieldValues.address = this.LeadOpportunityResponse.customerInformation.streetAddress || this.formFieldValues.address;
      this.formFieldValues.suburb = this.LeadOpportunityResponse.customerInformation.suburb || this.formFieldValues.suburb;
      this.formFieldValues.state = this.LeadOpportunityResponse.customerInformation.state || this.formFieldValues.state;
      this.formFieldValues.postalCode = this.LeadOpportunityResponse.customerInformation.postalCode || this.formFieldValues.postalCode;
      console.log('patchContact updated formFieldValues:', this.formFieldValues);
      this.cdn.detectChanges(); // Ensure view update
    } else {
      console.log('No customer information available for patchContact.');
    }
  }

  patchLead() {
    console.log('patchLead called');
    if (this.LeadOpportunityResponse) {
      this.formFieldValues.address = this.LeadOpportunityResponse.streetAddress || this.formFieldValues.address;
      this.formFieldValues.suburb = this.LeadOpportunityResponse.suburb || this.formFieldValues.suburb;
      this.formFieldValues.state = this.LeadOpportunityResponse.state || this.formFieldValues.state;
      this.formFieldValues.postalCode = this.LeadOpportunityResponse.postalCode || this.formFieldValues.postalCode;
      console.log('patchLead updated formFieldValues:', this.formFieldValues);
      this.cdn.detectChanges(); // Ensure view update
    }
  }




  leaAdctivityTabs(tab: number): string {

    switch (tab) {
      case 1:
        return 'General';
      case 2:
        return 'Attach an Email';
      default:
        return '';
    }
  }


  toggleFields(): void {
    if (this.formFieldValues['markComplete']) {
      this.isMarkVisibility = true;
    } else {
      this.isMarkVisibility = false;
    }
  }



  addNewLeadActivityModal() {
    const modalRef = this.modal.create({
      nzContent: AddLeadActivityTypeComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.onSaveComplete.subscribe(() => {
      this.afterSaveActivityTypeGet();
    });
  }



  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe(
      (res) => {
        // -----------single Select ---------------
        let reminderValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.reminderFeild.code
        );
        // -----------single Select --------------
        this.reminder = reminderValues;
      });
  }
  // ForCombo() {
  //   this.customerInformationService.getDataforcombo().subscribe((res) => {
  //     const assignedUser = res.result;
  //     const attendees = res.result;

  //     this.assignedUser = assignedUser;
  //     this.attendeesNodes = attendees.map((Adees) => ({
  //       title: Adees.fullName,
  //       key: Adees.id.toString(),
  //       isLeaf: true,
  //     }));
  //     console.log("show all data12~~ ",this.assignedUser);
  //   });

  // }



  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      const assignedUser = res.result;
      const attendees = res.result;
      this.assignedUser = assignedUser;
      this.attendeesNodes = [
        {
          title: 'Check All',
          value: 'check_all',
          key: 'check_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: attendees.map((user) => ({
            title: user.fullName,
            fullName: user.fullName,
            firstName: this.getFirstName(user.fullName),
            lastName: this.getLastName(user.fullName),
            value: user.id.toString(),
            key: user.id.toString(),
            isLeaf: true,
          }))
        }
      ];

    });
  }
  assignedUserOptions: any[] = [];
  onUserSelect(selectedUserId: string): void {
    // Update the assignedUserId in formFieldValues
    this.formFieldValues['assignedUserId'] = selectedUserId;

    // Select the corresponding user
    this.selectUser();
  }

  loadAssignedUsers(): void {
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      const assignedUsers = res.result;

      // Populate the assignedUser array with data
      this.assignedUser = assignedUsers.map(user => ({
        id: user.id,  // Ensure id is included
        fullName: user.fullName,
        applicationUserId: user.applicationUserId,
        isGrantExsistJobOrTemplate: user.isGrantExsistJobOrTemplate,
        firstName: this.getFirstName(user.fullName),
        lastName: this.getLastName(user.fullName),
        title: user.fullName,
        label: user.fullName,
        value: user.id.toString(),
        CellPhone: user.CellPhone,
        Initials: user.Initials,
        Email: user.Email,

      }));

      // Initialize form field values if needed
      this.formFieldValues['assignedUserId'] = this.formFieldValues['assignedUserId'] || '';
      this.selectUser();
    });
    this.storeInitialFormFieldValues();
  }

  selectUser(): void {
    // Ensure that selectUser is called after assignedUser is populated
    this.selectedUser = this.assignedUser.find(user => user.id === this.formFieldValues['assignedUserId']) || null;
  }



  getFirstName(fullName: string): string {
    const nameParts = fullName.trim().split(' ');
    return nameParts[0] || '';
  }

  getLastName(fullName: string): string {
    const nameParts = fullName.trim().split(' ');
    return nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';
  }

  getInitia(user: any): string {
    if (!user) {
      return '';
    }

    const firstNameInitial = user.firstName ? user.firstName.charAt(0).toUpperCase() : '';
    const lastNameInitial = user.lastName ? user.lastName.charAt(0).toUpperCase() : '';

    return `${firstNameInitial}${lastNameInitial}`;
  }



  selectedUser: ApplicationUserForComboResponse | null = null;



  getInitias(user: ApplicationUserForComboResponse | string): string {
    const fullName = typeof user === 'string' ? user : user?.fullName;

    if (!fullName) {
      return '';
    }

    const nameParts = fullName.split(' ');
    const firstNameInitial = nameParts[0].charAt(0).toUpperCase();
    const lastNameInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1].charAt(0).toUpperCase() : '';

    return `${firstNameInitial}${lastNameInitial}`;
  }

  onUserChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('check_all');
    if (checkAllIndex !== -1) {
      const allValues = this.attendeesNodes[0].children.map(child => child['value']);
      this.formFieldValues['applicationUserId'] = [...allValues];
    } else {
      const allValues = this.attendeesNodes[0].children.map(child => child['value']);
      this.formFieldValues['applicationUserId'] = selectedValues.filter(value => allValues.includes(value));
    }

  }

  onUserChanges(selectedValue: string): void {
    const allValues = this.attendeesNodes[0].children.map(child => child['value']);
    if (selectedValue === 'check_all') {
      this.formFieldValues['applicationUserId'] = [...allValues];
    } else {
      this.formFieldValues['applicationUserId'] = allValues.includes(selectedValue) ? [selectedValue] : [];
    }
    this.selectUser(); // Store the selected user details
  }


    getI(fullName: string): string {
      const names = fullName.split(' ');
      if (names.length === 0) return 'NA';

      const firstInitial = names[0].charAt(0).toUpperCase();
      const lastInitial = names[names.length - 1].charAt(0).toUpperCase();
      console.log("show all firstInitial", firstInitial);
      console.log("show all lastInitial", lastInitial);

      return `${firstInitial}${lastInitial}`;
    }

    getSelectedAttendees(selectedIds: (number | string)[]): string {
      const selectedIdsAsNumbers = selectedIds.map(id => typeof id === 'string' ? Number(id) : id);
      const selectedAttendees = this.assignedUser.filter(user => selectedIdsAsNumbers.includes(user.id));

      return selectedAttendees.map((user, index) => {
        const initials = this.getI(user.fullName || 'Unknown');
        const name = user.fullName || 'Unknown';
        return `${index + 1}. ${initials} (${name})`;
      }).join(', ');
    }

    getSelectedAttendeesList() {
      const selectedIds = this.formFieldValues['createLeadActivityUserParameterRequests'] || [];
      // Get the selected Attendee IDs from activityResponses
      const attendeeIds = this.activityResponses?.AttendeeIds
        ? this.activityResponses.AttendeeIds.split(',').map(id => id.trim())
        : [];

      // Map these IDs to the actual user objects from assignedUser
      return this.assignedUser.filter(user => attendeeIds.includes(user.id.toString()));
    }


    currentAttendee: any = null;

    setCurrentAttendee(user: any) {
      this.currentAttendee = user;
    }



  getInitial(name: string): string {
    if (!name) return '';

    const names = name.split(' ');
    let initials = names[0].charAt(0).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].charAt(0).toUpperCase();
    }

    return initials;
  }

  getColor() {
    this.colorService.getColorData().subscribe((res) => {
      const colorGet = res.result;
      this.colors = colorGet;
      if (this.activityResponse?.colorId) {
      } else {
        this.formFieldValues['colorId'] = this.colors[0].id
        this.selectedColor = this.colors[0].colorCollection;
      }
    });
    this.colorService.getIconData().subscribe((res) => {
      const iconsGet = res.result;
      this.icons = iconsGet;
      if (this.activityResponse?.colorId) {
      } else {
        this.selectedDescription = this.icons[0].sourceCollection;
      }
    });
    this.storeInitialFormFieldValues();
  }
  getAfterDelete() {
    this.leadActivitiesService.getAddLeadActivityType().subscribe((res) => {
      this.formFieldValues = {};
      this.afterDeleteAddLeadActivityType = res.result[0];
      this.selectedDescription = this.afterDeleteAddLeadActivityType?.icon?.sourceCollection;
      this.formFieldValues.activityTypeId = this.afterDeleteAddLeadActivityType?.id;
      this.formFieldValues.colorId = this.afterDeleteAddLeadActivityType?.colorId;
      this.selectedColor = this.afterDeleteAddLeadActivityType?.color?.colorCollection;
      this.leadActivityType = res.result;
      this.cdn.detectChanges();
    });
  }
  activityTypeGet() {
    this.leadActivitiesService.getAddLeadActivityType().subscribe((res) => {
      const LeadActivityGet = res.result;
      this.leadActivityType = LeadActivityGet
      if (this.activityResponse?.activityTypeId) {
      } else {
        this.formFieldValues['activityTypeId'] = this.leadActivityType[16].id;
      }
    });
    this.storeInitialFormFieldValues();
  }
  changeActivityType(SelectedId: number): void {
    let TestingSelected = SelectedId
    const data = this.leadActivityType.find(x => x.id === TestingSelected);
    this.formFieldValues['colorId'] = data.colorId;
    this.selectedColor = data.color?.colorCollection;
    this.selectedDescription = data.icon?.sourceCollection;
    this.storeInitialFormFieldValues();
  }
  afterSaveActivityTypeGet() {
    this.leadActivitiesService.getAddLeadActivityType().subscribe((res) => {
      const LeadActivityGet = res.result;
      this.leadActivityType = LeadActivityGet;
      this.cdn.detectChanges();
    });

    // Fetch the latest colors
    this.colorService.getColorData().subscribe((res) => {
      const colorGet = res.result;
      this.colors = colorGet;
      this.cdn.detectChanges();
    });

    this.subscription = this.leadActivitiesService.leadActivityTypeResponse$.subscribe(
      (response) => {
        if (response && response.result) {
          const updatedActivityType = response.result;
          this.formFieldValues['activityTypeId'] = updatedActivityType.id;
          this.leadActivityType.push({
            name: updatedActivityType.name,
            id: updatedActivityType.id,
          });

          this.selectedDescription = updatedActivityType.icon?.sourceCollection;
          this.selectedColor = updatedActivityType.color?.colorCollection;
          this.formFieldValues['colorId'] = updatedActivityType.colorId;

          this.colors.push({
            colorCollection: updatedActivityType.icon?.sourceCollection,
            name: updatedActivityType.name,
            id: updatedActivityType?.id,
          });
          this.cdn.detectChanges();
        }
      }
    );
    this.storeInitialFormFieldValues();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  updateSelectedColor(colorId: number): void {
    const selectedColor = this.colors.find(color => color.id === colorId);
    if (selectedColor) {
      this.selectedColor = selectedColor.colorCollection;
      this.selectedColorName = selectedColor.name;
    } else {
      this.selectedColor = '';
      this.selectedColorName = '';
    }
    this.storeInitialFormFieldValues();
  }
  updateSelectedColorByName(colorName: string): void {
    const selectedColor = this.colors.find(color => color.name === colorName);
    if (selectedColor) {
      this.formFieldValues['colorId'] = selectedColor.id;
      this.selectedColor = selectedColor.colorCollection;
      this.selectedColorName = selectedColor.name;
    } else {
      this.selectedColor = '';
      this.selectedColorName = '';
    }
  }



  readonly validationRules = {
    title: {
      required: 'Opportunity Title is required',
      maxLength: { limit: 50, message: 'Opportunity Title :' }
    },
    streetAddress: {
      maxLength: { limit: 50, message: 'Street Address :' }
    },
    suburb: {
      maxLength: { limit: 50, message: 'Suburb :' }
    },
    state: {
      maxLength: { limit: 3, message: 'State :' }
    },
    postalCode: {
      maxLength: { limit: 15, message: 'Postal Code :' }
    }
  };

  adjustTimeToUTC(localTime: any): string {
    // Ensure localTime is a Date object
    const time = new Date(localTime);
    if (isNaN(time.getTime())) {
      console.error('Invalid date provided for localTime:', localTime);
      return '';
    }

    // Convert local time to UTC
    const utcTime = new Date(time.getTime() - time.getTimezoneOffset() * 60000).toISOString();
    return utcTime;
  }

 

  saveLeadActivity(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save'): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
        return reject('Already processing');
      }
      if (actionType === 'saveAndNew') {
        this.isSaveAndNew = true;
      } else if (actionType === 'saveAndClose') {
        this.isSaveAndClose = true;
      } else {
        this.isSave = true;
      }
      this.prepareTypeStatusForSave();
      if (this.formFieldValues.typeStatus === null) {
        console.warn('typeStatus is null, no status will be saved');
      }
      const applicationUser = this.formFieldValues?.createLeadActivityUserParameterRequests?.map(
        (id: string) => ({ applicationUserId: id })
      );
      const formDataToSend = {
        ...this.formFieldValues,
        createLeadActivityUserParameterRequests: applicationUser,
        typeStatus: this.formFieldValues.typeStatus,
        startTime: this.adjustTimeToUTC(this.formFieldValues.startTime),// Convert to UTC before sending
      };
      this.leadOpportunitiesService.postLeadActivity(formDataToSend).subscribe(
        (response) => {
          const result = response.result;
          this.formFieldValues.globalId = result?.globalId;
          this.formFieldValues.startTime = new Date(result?.startTime).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
          });
          this.activityResponse = result;
          this.changeDetectorRef.detectChanges();
          if (this.activityResponse?.subject) {
            const subject = this.activityResponse?.subject;
            this.currentSubject = subject;
            this.isButtonDisabled = subject.length === 0;
          } else {
            this.currentSubject = null;
            this.isButtonDisabled = true;
          }
          if (this.selectedFiless?.length > 0) {
            const filesToUpload = this.selectedFiless.filter(i => i.originFileObj);
            const documentFiles = filesToUpload.map(f => f.originFileObj);
            if (filesToUpload.length > 0) {
              const attachmentRequest: CreateAttachmentForDocumentRequest = {
                headerId: result.id,
                companyParameterId: 1,
                formNameId: 4,
                documentFiles: documentFiles,
              };
              this.leadOpportunitiesService.CreateAttachmentForDocument(attachmentRequest).subscribe(
                (attachmentResponse) => {
                  this.fetchAttachments();
                  this.isAttachmentQueued = true;
                  this.saveFileIdToDatabase(attachmentResponse.fileId);
                  this.statusCode = LeadStatusCode.AwaitingMessage;
                  this.typeStatus = this.statusCode.code;
                },
                (attachmentError) => {
                  console.error('Error creating attachment:', attachmentError);
                }
              );
            } else {
              console.warn('No files to upload.');
            }
          }
          this.statusCode = LeadStatusCode.AwaitingMessage;
          this.typeStatus = this.statusCode.code;
          this.toastService.success('New User Saved Successfully!');
          this.leadActivityOnSavedCompleted.emit(this.activityResponse);
          this.leadActivitiesService.setLeadActivityTypeResponse(this.activityResponse);
          // this.Newpacth(this.activityResponses);
          this.storeInitialFormFieldValues();
          this.showContactForm = false;
          this.showContactLead = false;
          if (response) {
            this.createAndModifiedByData(response?.result?.id);
              }
          resolve();
        },
        (error) => {
          console.error('Error saving data:', error);
          reject(error);
        }
      ).add(() => {
        this.resetFlags();
        this.changeDetectorRef.detectChanges();
      });
    });

  }





    // this.leadActivityFromCancel();


    onDateChange(date: Date | null): void {
      if (date) {
        // Create a new Date object for followUpDate
        const selectedDate = new Date(date);
        // Get the current time
        const currentTime = new Date();
        // Set the time of selectedDate to the current time
        selectedDate.setHours(currentTime.getHours(), currentTime.getMinutes(), 0, 0);
        // Update followUpTime
        this.formFieldValues['followUpTime'] = selectedDate;

        // Manually trigger change detection to ensure view updates
        this.cdn.detectChanges();
      }
    }

  prepareTypeStatusForSave(): void {
    this.typeStatus = this.isBuildertrendMessageClicked
      ? LeadStatusCode?.Queued?.code
      : (this.isExternalEmailClicked
        ? LeadStatusCode?.AwaitingMessage?.code
        : (this.formFieldValues.typeStatus || this.activityResponse?.typeStatus || LeadStatusCode?.AwaitingMessage?.code));
  }






  // saveAndCloseActivitys(){
  //     this.saveLeadActivity('saveAndClose')
  //     this.resetForm();
  //     this.leadActivityFromCancel();
  // }
  saveAndCloseActivity(): Promise<void> {
    return this.saveLeadActivity('saveAndClose').then(() => {
      this.resetForm();
      this.leadActivityFromCancel();
    }).catch((error) => {
      console.error('Error during save and close:', error);
    });
  }


  saveAndNewActivity() {
    this.saveLeadActivity('saveAndNew').then(() => {
      this.activityResponse = undefined;
      this.resetForm();
      this.activityTypeGet();
      this.getColor();
      this.leadActivityFormGroup();
      this.ForCombo();
      this.assignedUserSelecte();
    }).catch((error) => {
      console.error('Error during save and new:', error);
    }).finally(() => {
    });
  }


  private emitData(data: LeadActivityResponse): void {
    if (!this.isDataEmitted && data) {
      this.leadActivityOnSavedCompleted.emit(data);
      this.isDataEmitted = true;
    }
  }



  resetFlags() {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
    this.isAttachmentQueued = false;
  }
  resetForm() {
    this.setInitialFormFieldValues();
    this.formFieldValues = {};
  }

  createAndModifiedByData(id: number) {  
    const payload: FilterFooterAudit = {
      id: id,
      formCode: 'LOAV'
    }
    this.FooterService.createdAndModifyUser(payload).subscribe(res => {
      this.footerResponse = res.result;

    })
  }

  saveFileIdToDatabase(fileId: string) {
  }


  selectedFiles: File[] = [];



  UploadFiles(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  CreateNewFile(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  viewAll(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.selectedFilessResponse = this.selectedFiless;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.removeFile.subscribe((file: CustomUploadFile) => {
      this.removeFile(file);
    });
    modalRef.componentInstance.Test.subscribe((data: CustomUploadFile[]) => {
      this.selectedFiless = [...data, ...this.selectedFiless];
      this.onResize();
    });
  }
  // removeFile(file: File): void {
  //   const index = this.selectedFiles.indexOf(file);
  //   if (index !== -1) {
  //     this.selectedFiles.splice(index, 1);
  //   }
  // }


  private showToast(type, title, message) {
    this.toastService.create(type, title, message);
  }


  fileList: NzUploadFile[] = [
    {
      uid: '-1',
      name: 'claimNoPhoto.gif',
      status: 'done',
      url: 'assets/claimNoPhoto.gif',
      download: false,
    },
  ];
  editNewLeadActivityModal() {
    let type = this.formFieldValues['activityTypeId'];
    let data = this.leadActivityType.find(x => x.id === type);
    const modalRef = this.modal.create({
      nzContent: AddLeadActivityTypeComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.addLeadActivityTypeResponse = data;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.onUpdateComplete.subscribe(() => {
      this.afterSaveActivityTypeGet();
    });
    modalRef.componentInstance.onUpdateCompleteNew.subscribe(() => {
      this.getAfterDelete();
      this.resetActivityTypeId();
    });
  }
  public mySelection: string[] = [];
  isDeleteLoading: boolean = false;
  SaveNew: boolean = false;
  deleteLeadConfirm(): void {
    if (this.isDeleteLoading) {
      return;
    }

    this.modal.confirm({
      nzTitle: `Delete Lead Activity?`,
      nzContent: 'Are you sure you want to permanently delete this Lead Activity?',
      nzOkText: `Delete Lead Activity`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteActivity(this.formFieldValues.globalId)
          .then(() => {
            this.LeadActivityNewLeadModelCancel();
          })
          .catch(() => {

          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }
  deleteActivity(globalId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!globalId) {
        this.toastService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }

      this.leadOpportunitiesService.deleteLeadActivityData(globalId).subscribe(
        (res) => {
          this.isDeleteLoading = false;
          this.toastService.success(`Deleted User!`);
          this.leadActivitiesService.setLeadActivityTypeResponse(res);
          this.leadActivityFromCancel();
          resolve();
          reject();
        },
        (error) => {
          this.isDeleteLoading = false;
          this.toastService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      );
    });
  }
  LeadActivityNewLeadModelCancel(): void {
    this.cancel.emit();
    this.leadActivtyVisibility = false;
    this.leadOpportunitiesService.clearSelectedFiles();
  }

  isSaveEnabled(): boolean {
    if (this.formFieldValues.state?.length > 3) {
      return false;
    }
    return true;
  }



  resetActivityTypeId(): void {
    this.formFieldValues['activityTypeId'] = null;
    this.cdn.detectChanges();
  }


  isConfirmationVisible: boolean = false;

  showConfirmationCard() {
    this.modal.error({
      nzTitle: 'Invalid Email',
      nzCentered: true,
      nzContent: 'The lead you have selected does not have a valid email address.',
      nzOkText: 'OK',
    });
  }

  handleCancel() {
    this.isConfirmationVisible = false;
  }

  handleOk() {
    this.isConfirmationVisible = false;
  }
  get isButtonEnabled(): boolean {
    return this.customerInformationId > 0 && this.attachmentResponse > 0;
  }
  getDisplayTitle(): string | null {
    if (this.activityResponse?.leadOpportunity?.title) {
      return 'grid';
    } else if (this.holdResponse?.leadData?.title) {
      return 'Lead Opp';
    } else if (this.LeadOpportunityResponse?.title) {
      return 'Dialog box';
    }
    return null;
  }
  generateMapUrl(query: string) {
    const modalRef = this.modal.create({
      nzContent: GoogleMapComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.searchQuery = query;
  }






  toggleDisplay() {
    this.showEmailContent = !this.showEmailContent;
    if (this.showEmailContent) {
      this.showActivityResponse = false;
      const primaryEmail = this.activityResponse?.leadOpportunity?.customerInformation?.firstName || '';
      this.formattedEmail = primaryEmail ? `${primaryEmail}<${this.uniqueIdentifier}@${this.domain}>` : '';
      this.inputValue = this.formattedEmail;
      this.alertMessageVisible = true;
      if (this.showEmailContent) {
        this.isAttachmentQueued = false;
      }
    } else {
      this.inputValue = '';
      this.statusCode = null;
      console.log('StatusCode reset to null');

    }
  }

  toggleActivityResponse(): void {
    this.showActivityResponse = !this.showActivityResponse;
    this.showEmailContent = false;
    this.alertMessageVisible = false;
  }

  handleAttachBuildertrendMessageClick(): void {
    if (this.leadOptionsNew && this.leadOptionsNew.customerInformationId != null) {
      if ((this.customerInformationId == null || this.customerInformationId <= 0) && this.leadOptionsNew.customerInformationId <= 0) {
        this.showConfirmationCard();
      } else {
        this.currentButtonClick = 'attachBuildertrendMessage';
        this.statusCode = LeadStatusCode.Queued;
        this.typeStatus = this.statusCode.code;
        this.isBuildertrendMessageClicked = true;
        this.isExternalEmailClicked = false;
        this.formFieldValues.typeStatus = this.typeStatus;
        this.prepareTypeStatusForSave();
        this.toggleActivityResponse();
      }
    } else {
      if (this.customerInformationId == null || this.customerInformationId <= 0) {
        this.showConfirmationCard();
      } else {
        this.currentButtonClick = 'attachBuildertrendMessage';
        this.statusCode = LeadStatusCode.Queued;
        this.typeStatus = this.statusCode.code;
        this.isBuildertrendMessageClicked = true;
        this.isExternalEmailClicked = false;
        this.formFieldValues.typeStatus = this.typeStatus;
        this.prepareTypeStatusForSave();
        this.toggleActivityResponse();
      }
    }
  }

  handleAttachExternalEmailClick(): void {
    if (this.leadOptionsNew && this.leadOptionsNew.customerInformationId != null) {
      // Check both IDs if `leadOptionsNew.customerInformationId` exists
      if ((this.customerInformationId == null || this.customerInformationId <= 0) && this.leadOptionsNew.customerInformationId <= 0) {
        this.showConfirmationCard(); // Show confirmation card if both IDs are <= 0 or null
      } else {
        // Proceed with the attachment logic
        console.log('Proceeding with attachExternalEmail logic');
        this.currentButtonClick = 'attachExternalEmail';
        this.statusCode = LeadStatusCode.AwaitingMessage;
        this.typeStatus = this.statusCode.code;
        this.isExternalEmailClicked = true;
        this.isBuildertrendMessageClicked = false;
        this.formFieldValues.typeStatus = this.typeStatus;
        this.prepareTypeStatusForSave();
        this.toggleDisplay();
      }
    } else {
      // Check only `customerInformationId` if `leadOptionsNew` does not exist
      if (this.customerInformationId == null || this.customerInformationId <= 0) {
        this.showConfirmationCard(); // Show confirmation card if `customerInformationId` is <= 0 or null
      } else {
        // Proceed with the attachment logic
        this.currentButtonClick = 'attachExternalEmail';
        this.statusCode = LeadStatusCode.AwaitingMessage;
        this.typeStatus = this.statusCode.code;
        this.isExternalEmailClicked = true;
        this.isBuildertrendMessageClicked = false;
        this.formFieldValues.typeStatus = this.typeStatus;
        this.prepareTypeStatusForSave();
        this.toggleDisplay();
      }
    }
  }



  // cancelEmailFields() {
  //   this.alertMessageVisible = false;  // Hide alert message
  //   this.showActivityResponse = false;
  //   this.showEmailContent = false;
  //   this.showAttachBuildertrendMessageButton = true; // Show "Attach Buildertrend Message" button
  //   this.inputValue = '';
  //   // this.formFieldValues = {}; // Clear form values if necessary
  //   // this.editorValue = ''; // Clear the editor value if necessary
  //   // this.selectedFiles = []; // Clear the selected files if necessary
  //   this.statusCode = null;
  //   this.typeStatus = null; // Also clear the typeStatus if needed
  // }


  cancelEmailFields() {
    // Set typeStatus to null when cancel is clicked
    this.formFieldValues.typeStatus = null;

    // Other cancel actions
    this.statusCode = null;
    this.alertMessageVisible = false;
    this.showEmailContent = false;
    this.showAttachBuildertrendMessageButton = true; // Show "Attach Buildertrend Message" button
    this.showActivityResponse = false;
    this.inputValue = '';
    this.formattedEmail = '';

    console.log('Email fields canceled, typeStatus set to null');
  }




  openMailClient() {
    if (this.inputValue) {
      window.location.href = `mailto:${this.inputValue}`;
    }
  }

  copyToClipboard() {
    if (this.inputValue) {
      navigator.clipboard.writeText(this.inputValue).then(() => {
        this.toastService.success('Copied');
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
  }





  composeNewMessage() {
    if (this.inputValue) {
      window.location.href = `mailto:${this.inputValue}`;
    }
  }


  getAvatarText(fullName: string | undefined): string {
    if (!fullName) return '';

    const names = fullName.split(' ');
    if (names.length < 2) return names[0].charAt(0).toUpperCase(); // Single name case

    const initials = names[0].charAt(0).toUpperCase() + names[names.length - 1].charAt(0).toUpperCase();
    return initials;
  }




  getPakistanTime(): string {
    const now = new Date();
    const pktOffset = 5 * 60; // Pakistan Standard Time is UTC+5
    const localTime = now.getTime() + (now.getTimezoneOffset() * 60000);
    const pakistanTime = new Date(localTime + (pktOffset * 60000));
    return pakistanTime.toLocaleTimeString('en-US', { timeZone: 'Asia/Karachi' });
  }


  getFormattedDate(date: Date): string {
    const today = new Date();
    const formattedDate = date.toLocaleDateString('en-GB', { timeZone: 'Asia/Karachi' }); // Format as DD/MM/YYYY
    const todayDate = today.toLocaleDateString('en-GB', { timeZone: 'Asia/Karachi' });

    if (formattedDate === todayDate) {
      return 'Today';
    } else if (formattedDate === todayDate) {
      return 'Yesterday';
    } else {
      return formattedDate;
    }

  }
  updateCKEditor() {
    if (this.ckeditor) {
      this.ckeditor.ready.subscribe(editor => {
        editor.isReadOnly = !!this.activityResponse?.markComplete;
      });
    }
  }
  public config = {
    height: '400px',

    toolbar: {
      items: [
        'undo', 'redo', '|',
        'heading', '|', 'bold', 'italic', '|',
        'link', 'insertTable', 'mediaEmbed', '|',
        'bulletedList', 'numberedList', 'indent', 'outdent', '|',
        'fontSize', 'fontFamily', 'wproofreader', 'fontBackgroundColor', 'fontColor', 'blockQuote',
        'image', 'horizontalLine', 'undoEditing'
      ],
    },
    plugins: [

      Bold,
      Essentials,
      Heading,
      Indent,
      IndentBlock,
      Italic,
      Link,
      List,
      MediaEmbed,
      Paragraph,
      Table,
      Undo,
      Font,
      FontSize,
      FontFamily,
      WProofreader,
      FontBackgroundColor,
      FontColor,
      BlockQuote,
      Image,
      HorizontalLine,
      UndoEditing,
      ImageUpload,



    ],


    fontSize: {
      options: [
        9, 11, 13, 'default', 17, 19, 21, 25, 28, 32, 36, 40, 44, 48, 52, 56, 60
      ]
    },
    fontFamily: {
      options: [
        'default', 'Arial, Helvetica, sans-serif', 'Courier New, Courier, monospace',
        'Georgia, serif', 'Times New Roman, Times, serif', 'Verdana, Geneva, sans-serif'
      ]
    },


    wproofreader: {
      lang: 'en_US',
      serviceId: 'YRY4vsPN7HIGo9kl',
      srcUrl: 'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js'
    }
  };

  getReminderLabel(reminderId: number | null): string | undefined {
    const reminder = this.reminder.find(item => item.id === reminderId);
    return reminder ? reminder.name : undefined;
  }

  getActivityTypeLabel(activityTypeId: number | null): string | undefined {
    const activityType = this.leadActivityType.find(item => item.id === activityTypeId);
    return activityType ? activityType.name : '--None--';
  }

  getInitials(userId: number | null): string {
    console.log(`Getting initials for userId: ${userId}`);
    const user = this.assignedUser.find(item => item.id === userId);
    if (user && user.fullName) {
      const names = user.fullName.split(' ');
      console.log(`Full name split into names: ${names}`);
      if (names.length < 2) {
        const initial = names[0].charAt(0).toUpperCase(); // Single name case
        console.log(`Single name initials: ${initial}`);
        return initial;
      }
      const initials = names[0].charAt(0).toUpperCase() + names[names.length - 1].charAt(0).toUpperCase();
      console.log(`Initials: ${initials}`);
      return initials;
    }
    console.log('User not found or fullName is not provided');
    return '--';
  }

  getAssignedUserName(userId: number | null): string {
    console.log(`Getting full name for userId: ${userId}`);
    const user = this.assignedUser.find(item => item.id === userId);
    if (user) {
      console.log(`Full name: ${user.fullName}`);
      return user.fullName;
    }
    console.log('User not found');
    return '--None--';
  }

  leadActivityFromCancel(): void {
    this.emitData(this.activityResponse);
    this.cancel.emit();
    this.leadActivtyVisibility = false;
    this.leadOpportunitiesService.clearSelectedFiles();
  }
  // showConfirmCardleadActivityFrom(): void {
  //   const dontSaveText = 'Dont Save';
    
  //   if (this.isFormDirty()) {
  //     this.confirmModal = this.modal.confirm({
  //       nzTitle: '<b>Unsaved changes</b>',
  //       nzContent: 'You have unsaved changes, do you want to save your changes before closing?',
  //       nzOkText: 'Save',
  //       nzOkType: 'primary',
  //       nzIconType: null,
  //       nzCancelText: dontSaveText,
  //       nzOkLoading: this.isSaveAndClose,
  //       nzClosable: true,
  //       nzOnOk: () => {
  //         this.resetFlags();
  //         this.isSaveAndClose = true;
  //         this.confirmModal.destroy();
  //         return this.saveAndCloseActivity()
  //           .then(() => {
  //             this.isSaveAndClose = false;
  //             this.saveAndCloseActivity();
  //           })
  //           .catch((error) => {
  //             this.isSaveAndClose = false;
  //             console.error('Error saving lead:', error);
  //           });
  //       },
  //       nzOnCancel: () => {
  //         const clickedButton = (event.target as HTMLElement).textContent?.trim();
  //         if (clickedButton === dontSaveText) {
  //           this.confirmModal.destroy();
  //           this.resetForm();
  //           this.leadActivityFromCancel();
  //         } else {
  //           this.confirmModal.destroy();
  //         }
  //       },
  //       nzStyle: {
  //         top: '250px',
  //       },
  //       nzClassName: 'ant-modal-confirm-custom', // Custom class
  //     });
  //   } else {
  //     this.resetForm();
  //     this.leadActivityFromCancel();
  //   }
  // }



  async storeInitialFormFieldValues(): Promise<void> {
    await new Promise(resolve => setTimeout(() => {
      this.initialFormFieldValues = JSON.parse(JSON.stringify(this.formFieldValues));
      resolve(true);
    }, 0));
  }

  isFormDirty(): boolean {
    return JSON.stringify(this.formFieldValues) !== JSON.stringify(this.initialFormFieldValues);
  }






  testingHide: CustomUploadFile;
  selectedFiless: CustomUploadFile[] = [];
  uploadFiles(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.UploadFile.subscribe((data: CustomUploadFile[]) => {
      data.filter(file => this.isImageFile(file));
      this.selectedFiless = [...data, ...this.selectedFiless];
      this.onResize();
    });
  }

  onResize() {
    this.updateNavButtonsVisibility();
  }
  isImageFile(file: CustomUploadFile): boolean {
    const fileName = file.name.toLowerCase();
    const fileType = file.type;

    return (
      fileType === 'image/png' ||
      fileType === 'image/jpeg' ||
      fileName.endsWith('.png') ||
      fileName.endsWith('.jpeg')
    );
  }
  @ViewChild('carouselContainer', { static: false }) carouselContainer!: ElementRef<HTMLDivElement>;
  showNavButtons = false;
  currentIndex = 0;

  private updateNavButtonsVisibility() {
    requestAnimationFrame(() => {
      const container = this.carouselContainer.nativeElement;
      if (this.selectedFiless.length > 0) {
        this.showNavButtons = container.scrollWidth > container.clientWidth;
      } else {
        this.showNavButtons = false;
      }
      this.cdn.detectChanges();
    });
  }

  playVideo(file: CustomUploadFile): void {
    const videoElement: HTMLVideoElement = this.carouselContainer.nativeElement.querySelector(`#video-${file.id}`);
    if (videoElement) {
      videoElement.style.display = 'block'; // Show the video player
      videoElement.play();
    }
  }


  removeFile(file: CustomUploadFile): void {
    const fileToRemove = this.selectedFiless.find(item => item.uid === file.uid);
    if (fileToRemove) {
      this.selectedFiless = this.selectedFiless.filter(item => item.uid !== file.uid);
          this.leadOpportunitiesService.deleteLeadByAttachmentId(fileToRemove.globalId).subscribe(
            (res) => {
          console.log("show all delete",res.result);
          this.toastService.success('File deleted successfully!');
        },

 )}
}


  uploadFilesUpdate(fileq: CustomUploadFile): void {
    const modalRef = this.modal.create({
      nzContent: AttachmentAnnotationComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.DrawerImge = fileq;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.imageDataRes.subscribe((data: any) => {
      const newImageUrl = data;
      let test1234 = this.selectedFiless.forEach(activity => {
        activity.thumbUrl = newImageUrl;
      });
      console.log(test1234);
    });
  }

  previous() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.scrollToCurrentIndex();
    }
  }

  next() {
    const container = this.carouselContainer.nativeElement;
    const maxIndex = this.selectedFiless.length - Math.floor(container.clientWidth / 130);
    if (this.currentIndex < maxIndex) {
      this.currentIndex++;
      this.scrollToCurrentIndex();
    }
  }

  scrollToCurrentIndex() {
    const container = this.carouselContainer.nativeElement;
    const scrollAmount = this.currentIndex * 130; // 120px (width) + 10px (margin)
    container.scrollTo({ left: scrollAmount, behavior: 'smooth' });
  }

  getFileIcon(file: CustomUploadFile): string | null {
    const fileType = file.type;
    if (fileType.includes('pdf')) {
      return 'assets/Attachment/documentPDF.svg';
    } else if (fileType.includes('excel') || fileType.includes('spreadsheet')) {
      return 'assets/Attachment/documentXLS.svg';
    } else if (fileType.includes('word')) {
      return 'assets/Attachment/documentDOC.svg';
    } else if (fileType.includes('zip')) {
      return 'assets/Attachment/documentZIP.svg';
    } else if (fileType.includes('text')|| fileType.includes('json')) {
      return 'assets/Attachment/documentGeneric.svg';
    } else if (fileType.includes('ppt') || fileType.includes('presentation')) {
      return 'assets/Attachment/documentPPT.svg';
    } else if (fileType.includes('video')) {
      return null;
    } else if (fileType.includes('image')) {
      return null;
    } else {
      return null;
    }
  }
onClick(): void {
  let filteredImages = this.selectedFiless.filter(f =>
    f.name.includes('png') || f.name.includes('jpeg')
  );
    const images = filteredImages.map(file => ({
      src: file.thumbUrl,
      alt: file.name
    }));
    this.nzImageService.preview(images,  {
      nzZoom: 1.0,
      nzRotate: 0
    });
}
isButtonDisableds = false;
checkCharacterLimits(): void {
  const { address, suburb, state, postalCode, subject } = this.formFieldValues;
  this.isButtonDisableds = false;
  if (address?.length > 50 || suburb?.length > 50 || state?.length > 3 || postalCode?.length > 15 || subject?.length > 50) {
    this.isButtonDisableds = true;
  }
}






Newpacth(activityResponses: LeadActivityResponses) {
  this.dataSetForLeadOp = activityResponses?.LeadTitle;
  let DataSet = activityResponses;
  this.dataSetForLeadOp = DataSet?.LeadTitle;
  this.selectedColor = DataSet?.ActivityColorCollection
  this.selectedDescription = DataSet.ActivityTypeIcon
  this.formFieldValues = {
    ...this.formFieldValues,
    leadActivityId: DataSet?.LeadActivityId,
    globalId: DataSet?.GlobalId,
    followUpDate: DataSet?.FollowUpDate,
    followUpTime: DataSet?.FollowUpTime,
    activityTypeId: DataSet?.ActivityTypeId,
    colorId: DataSet?.ColorId,
    activityOn: DataSet?.ActivityOn,
    startTime: DataSet?.StartTime,
    endTime: DataSet?.EndTime,
    reminderId: DataSet?.ReminderId,
    assignedUserId: DataSet?.AssignedUserId,
    initiatedBy: this.getInitiatedByValues(DataSet),
    salesperson: DataSet?.Salesperson,
    lead: DataSet?.Lead,
    other: DataSet?.Other,
    address: DataSet?.Address,
    suburb: DataSet?.Suburb,
    state: DataSet?.State,
    postalCode: DataSet?.PostalCode,
    description: DataSet?.Description,
    markComplete: DataSet?.MarkComplete,
    markCompleteOn: DataSet?.MarkCompleteOn,
    leadOpportunityId: DataSet?.LeadId,
    subject: DataSet?.Subject,
    body: DataSet?.Body,
    attachmentId: DataSet?.AttachmentId,
    // activityTypeIcon: DataSet?.ActivityTypeIcon,
    // selectedDescription: DataSet?.ActivityTypeIcon,





    typeStatus: DataSet?.TypeStatus,
    statusSetupId: DataSet?.StatusSetupId,
    customerInformationId: DataSet?.CustomerInformationId,
    createLeadActivityUserParameterRequests: DataSet?.AttendeeIds
      ? DataSet.AttendeeIds.split(',').map(Id => Id.trim())
      : [],
    customerPrimaryEmail: DataSet?.CustomerPrimaryEmail,
    customerContactNumber: DataSet?.CustomerContactNumber,
  };
  this.showContactForm = false;
  this.showContactLead = false;
  if (DataSet?.CustomerInformationId) {
    this.formFieldValues.address = DataSet?.CustomerStreetAddress || this.formFieldValues.address;
    this.formFieldValues.suburb = DataSet?.CustomerSuburb || this.formFieldValues.suburb;
    this.formFieldValues.postalCode = DataSet?.CustomerPostalCode || this.formFieldValues.postalCode;
    this.showContactForm = true;
  }
  if (DataSet?.LeadId) {
    this.formFieldValues.address = DataSet?.LeadStreetAddress || this.formFieldValues.address;
    this.formFieldValues.suburb = DataSet?.LeadSuburb || this.formFieldValues.suburb;
    this.formFieldValues.postalCode = DataSet?.LeadPostalCode || this.formFieldValues.postalCode;
    this.showContactLead = true;
  }
  this.cdn.detectChanges();
  this.handleTypeStatus(DataSet);
  this.toggleFields();
  this.cdn.detectChanges();
  this.updateCKEditor();
  this.createAndModifiedByData(this.activityResponseId);
  this.ismark = DataSet.MarkComplete ?? false;
       if (this.ismark) {
         this.alertMessageVisible = false;
         this.isAttachmentQueued = false;
         this.setInitialFormFieldValues();
         const markCompleteDate = this.activityResponses?.MarkCompleteOn;
         this.alertMessage = `Marked Complete on ${new Date(markCompleteDate).toLocaleDateString()}`;
       }
}


handleTypeStatus(DataSet: LeadActivityResponses) {
  if (DataSet?.TypeStatus === 'AwaitingMessage') {
    this.alertMessageVisible = true;
    this.isAttachmentQueued = false;
  } else if (DataSet?.TypeStatus === 'Queued For Send') {
    this.isAttachmentQueued = true;
    this.alertMessageVisible = false;
  } else {
    this.alertMessageVisible = false;
    this.isAttachmentQueued = false;
  }
}



showAdvancedOptions = false;


patchContactafterpacthtime() {
  console.log('patchContactafterpacthtime called');
  if (this.activityResponses?.CustomerInformationId) {
    this.formFieldValues.address = this.activityResponses.CustomerStreetAddress || this.formFieldValues.address;
    this.formFieldValues.suburb = this.activityResponses.CustomerSuburb || this.formFieldValues.suburb;
    this.formFieldValues.state = this.activityResponses.CustomerState || this.formFieldValues.state;
    this.formFieldValues.postalCode = this.activityResponses.CustomerPostalCode || this.formFieldValues.postalCode;
    console.log('patchContact updated formFieldValues:', this.formFieldValues);
    this.cdn.detectChanges(); // Ensure view update
  } else {
    console.log('No customer information available for patchContact.');
  }
}

patchLeadafterpacthtime() {
  console.log('patchLeadafterpacthtime called');
  if (this.activityResponses?.LeadId) {
    this.formFieldValues.address = this.activityResponses.LeadStreetAddress || this.formFieldValues.address;
    this.formFieldValues.suburb = this.activityResponses.LeadSuburb || this.formFieldValues.suburb;
    this.formFieldValues.state = this.activityResponses.LeadState || this.formFieldValues.state;
    this.formFieldValues.postalCode = this.activityResponses.LeadPostalCode || this.formFieldValues.postalCode;
    console.log('patchLead updated formFieldValues:', this.formFieldValues);
    this.cdn.detectChanges(); // Ensure view update
  }
}someOtherCondition = false;

checkConditions() {
  if (this.activityResponses?.CustomerInformationId) {
    this.showContactForm = true;
  }
  if (this.activityResponses?.LeadId) {
    this.showContactLead = true;
  }
  if (this.LeadOpportunityResponse?.customerInformation) {
    this.showContactForm = true;
  }
  if (this.LeadOpportunityResponse) {
    this.showContactLead = true;
  }
}

}
