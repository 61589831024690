<!-- -->
<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form (ngSubmit)="onFilterApply()">
    <div class="row mt-2">
      <!-- <div class="col-10 p-0">
        <div class="input-group">
          <nz-select
            [(ngModel)]="formFieldValues['standardFilter']"
            name="standardFilter"
            (ngModelChange)="onChangeStandardFilter($event)"
            nzShowSearch
            [lang]="true"
            nzAutoFocus="true"
            nzPlaceHolder="Standard Filter"
          >
            <nz-option
              *ngFor="let filter of filterResponseData"
              [nzLabel]="filter.name"
              [nzValue]="filter.id"
            >
            </nz-option>
          </nz-select>
        </div>
      </div> -->
      <div class="col-10 p-0">
            <div class="input-group">
             <nz-select
              [(ngModel)]="formFieldValues['standardFilter']"
              name="standardFilter"
              (ngModelChange)="onChangeStandardFilter($event)"
              nzShowSearch
              [nzAutoFocus]="true"
              nzPlaceHolder="Standard Filter"
              >
              <nz-option *ngFor="let filter of filterResponseData"
               [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')"
               [nzValue]="filter.id"
               nzCustomContent>
               <div class="option-content">
                <!-- Customize content with icons and text -->
                <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
                <span>{{ filter.name }}</span>
               <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

               </div>
              </nz-option>
             </nz-select>
            </div>
           </div>

      <!-- [nzLabel]="filter.name + (filter.isSetAsDefault ? ' DEFAULT' : '')"  -->
      <div class="col-2">
        <button nz-button nzType="text">
          <i
            class="bi bi-three-dots fs-6"

           nz-popover
           [nzPopoverContent]="contentTemplate"
           [nzPopoverPlacement]="'top'"
            (click)="openStandardFilterComponent('SAVE_AS')"
          ></i>
        </button>
      </div>
    </div>
    <div class="row mt-2" *ngIf="selectedFilter === 1">
      <div class="col">
        <p>Your filter has unsaved changes.</p>
      </div>
    </div>
    <div class="row mt-2" *ngIf="selectedFilter === 0">
      <div class="col">
        <p>Your filter has unsaved changes.</p>
      </div>
    </div>

    <div class="col d-flex mt-3">
      <button
        (click)="openStandardFilterComponent('APPLY_SAVE_AS')"
        type="button"
        nz-button
        nzType="default"
        class="me-2"
        style="font-size: 12px"
        *ngIf="selectedFilter === 1"
      >
        Save As
      </button>
      <button
        (click)="reInitReset()"
        nz-button
        nzType="default"
        style="font-size: 12px"
        *ngIf="selectedFilter === 1"
        type="button"
      >
        Reset
      </button>
      <button
        (click)="editSaveFilterData()"
        nz-button
        nzType="primary"
        type="button"
        class="me-2"
        style="font-size: 12px"
        *ngIf="selectedFilter === 0"
      >
        Save
      </button>
      <button
        (click)="openStandardFilterComponent('APPLY_SAVE_AS')"
        nz-button
        nzType="default"
        class="me-2"
        style="font-size: 12px"
        *ngIf="selectedFilter === 0"
        type="button"
      >
        Save As
      </button>
      <button
        (click)="reInitReset()"
        nz-button
        nzType="default"
        style="font-size: 12px"
        *ngIf="selectedFilter === 0"
        type="button"
      >
        Reset
      </button>
    </div>
    <div class="row famly-inn">
      <div class="col-12">
        <div class="row mt-2 mb-2">
          <div class="col-12 p-0 m-0">
            <label class="sub-text">Keyword</label>
            <i
              class="bi bi-info-circle-fill text-secondary ms-2"
              nz-popover
              [nzPopoverContent]="keywordTemplate"
              nzPopoverPlacement="right"
            ></i>
            <ng-template #keywordTemplate>
              <div class="rounded-2">
                <p style="height: auto; width: 180px">
                  Keyword Search allows you to search Leads by keyword for any
                  of the fields included in the dropdown.
                </p>
              </div>
            </ng-template>
            <div class="d-flex align-items-center">
              <div class="row">
                <div class="col-12">
                  <div class="row p-0 m-0">
                    <div class="col-6 p-0 m-0">
                      <input
                        type="text"
                        [(ngModel)]="formFieldValues['keyword']"
                        name="keyword"
                        class="form-control formField"
                        style="
                          border-right: none;
                          border-radius: 4px 0 0 4px;
                          flex: 1;
                        "
                      />
                    </div>
                    <div class="col-6 p-0 m-0">
                      <div style="margin-left: 0px">
                        <nz-select
                          class="ant-select-selector"
                          [(ngModel)]="colName"
                          name="colName"
                          (ngModelChange)="onParentCodeChange($event)"
                          nzShowSearch
                          [lang]="true"
                          nzAutoFocus="false"
                          style="
                            font-size: 12px;
                            height: 32px;
                            width: 100%;
                            border-left: none;
                          "
                        >
                          <nz-option
                            *ngFor="let option of keywordOptions"
                            [nzLabel]="option.name"
                            [nzValue]="option.id"
                          >
                          </nz-option>
                        </nz-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Salesperson</label>
            <nz-tree-select
              [(ngModel)]="formFieldValues['salesperson']"
              name="salesperson"
              style="width: 100%"
              [nzNodes]="salespersonNodes"
              [nzShowSearch]="true"
              nzAllowClear="false"
              nzCheckable
              (ngModelChange)="onSalesPersonChange($event)"
              nzPlaceHolder="Select Sales Person"
              nzDropdownClassName="dropdownStyle"
              [nzAllowClear]="false"
            >
            </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Status</label>
            <nz-tree-select
            [(ngModel)]="formFieldValues['status']"
            name="status"
            style="width: 100%"
            [nzNodes]="statusNodes"
            [nzShowSearch]="true"
            nzAllowClear="false"
            nzCheckable
            (ngModelChange)="onStatusChange($event)"
            nzPlaceHolder="Select Status"
            nzDropdownClassName="dropdownStyle"
            [nzAllowClear]="false"
          >
          </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Age of Lead</label>
            <nz-select
              nzShowSearch
              [lang]="true"
              nzAutoFocus="true"
              [(ngModel)]="formFieldValues['ageofLead']"
              name="ageofLead"
            >
              <nz-option
                *ngFor="let filter of ageofLead"
                [nzLabel]="filter.label"
                [nzValue]="filter.value"
              >
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Tags</label>
            <nz-tree-select
            [(ngModel)]="formFieldValues['tags']"
            name="tags"
            style="width: 100%"
            [nzNodes]="tagsNodes"
            [nzShowSearch]="true"
            nzAllowClear="false"
            nzCheckable
            (ngModelChange)="onTagChange($event)"
            nzPlaceHolder="Select Tags"
            nzDropdownClassName="dropdownStyle"
            [nzAllowClear]="false"
          >
          </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Source</label>
            <nz-tree-select
            [(ngModel)]="formFieldValues['source']"
            name="source"
            style="width: 100%"
            [nzNodes]="sourceNodes"
            [nzShowSearch]="true"
            nzAllowClear="false"
            nzCheckable
            (ngModelChange)="onSourceChange($event)"
            nzPlaceHolder="Select Source"
            nzDropdownClassName="dropdownStyle"
            [nzAllowClear]="false"
          >
          </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Project Type</label>
            <nz-tree-select
            [(ngModel)]="formFieldValues['projectType']"
            name="projectType"
            style="width: 100%"
            [nzNodes]="projectTypeNodes"
            [nzShowSearch]="true"
            nzAllowClear="false"
            nzCheckable
            (ngModelChange)="onProjectTypeChange($event)"
            nzPlaceHolder="Select Project Type"
            nzDropdownClassName="dropdownStyle"
            [nzAllowClear]="false"
          >
          </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Proposal Status</label>
            <nz-tree-select
            [(ngModel)]="formFieldValues['proposalStatus']"
            name="proposalStatus"
            style="width: 100%"
            [nzNodes]="proposalStatusNodes"
            [nzShowSearch]="true"
            nzAllowClear="false"
            nzCheckable
            (ngModelChange)="onProposalStatusChange($event)"
            nzPlaceHolder="Select Proposal Status"
            nzDropdownClassName="dropdownStyle"
            [nzAllowClear]="false"
          >
          </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Sold Date</label>
            <nz-select
              nzShowSearch
              [lang]="true"
              nzAutoFocus="true"
              [(ngModel)]="formFieldValues['soldDate']"
              name="soldDate"
            >
              <nz-option
                *ngFor="let filter of soldDate"
                [nzLabel]="filter.label"
                [nzValue]="filter.value"
              >
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Most Recent Click</label>
            <nz-select
              nzShowSearch
              [lang]="true"
              nzAutoFocus="true"
              [(ngModel)]="formFieldValues['mostRecentClick']"
              name="mostRecentClick"
            >
              <nz-option
                *ngFor="let filter of mostRecentClick"
                [nzLabel]="filter.label"
                [nzValue]="filter.value"
              >
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text"># of Links Clicked</label>
            <nz-select
              nzShowSearch
              [lang]="true"
              nzAutoFocus="true"
              [(ngModel)]="formFieldValues['ofLinksClicked']"
              name="ofLinksClicked"
            >
              <nz-option
                *ngFor="let filter of ofLinksClicked"
                [nzLabel]="filter.label"
                [nzValue]="filter.value"
              >
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row">
          <div class="col mt-1 p-0">
            <label class="sub-text">Do you have Land</label>
            <nz-select
              nzShowSearch
              [lang]="true"
              nzAutoFocus="true"
              [(ngModel)]="formFieldValues['doyouhaveLand']"
              name="doyouhaveLand"
            >
              <nz-option
                *ngFor="let filter of doyouhaveLand"
                [nzLabel]="filter.label"
                [nzValue]="filter.value"
              >
              </nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 p-0 text-end">
        <button
          nz-button
          nzType="default"
          type="button"
          (click)="resetFilter()"
        >
          Clear All
        </button>
        <button
          class="ms-1"
          nz-button
          nzType="primary"
          type="submit"
          [disabled]="isFormDirty"
        >
          Apply
        </button>
      </div>
    </div>
  </form>
</div>
<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>
