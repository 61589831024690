import { CatalogNewCostGroupComponent } from './../catalog-new-cost-group/catalog-new-cost-group.component';
import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

interface ItemData {
  id: number;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'catalog-cost-group-tab-2',
  templateUrl: './catalog-cost-group-tab-2.component.html',
  styleUrls: ['./catalog-cost-group-tab-2.component.css']
})
export class CatalogCostGroupTab2Component {
  
  constructor(private modal: NzModalService, ){}

  listOfData: readonly ItemData[] = [];
  checked = false;
  indeterminate = false;
  listOfCurrentPageData: readonly ItemData[] = [];
  setOfCheckedId = new Set<number>();

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange($event: readonly ItemData[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  ngOnInit(): void {
    this.listOfData = new Array(8 ).fill(0).map((_, index) => ({
      id: index,
      name: `Edward King`,
      age: 32,
      address: `London`
    }));
  }
  newCostGroupOpenMethod() {
    const modalRef = this.modal.create({
      nzContent: CatalogNewCostGroupComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      // this.getNewProposalData = null;
        // this.getInternalUsersData();
    });


}

tableHeaders: string[] = [
  'Cost Group',
  'Cost Items',
  'Description',
  'builder Cost',
  'Markup',
  'Owner Price',
  'Attachments'
];
displayedColumns: string[] = this.tableHeaders.slice();
}
