import { DataSource } from '@angular/cdk/collections';
import { WarrantyInformation } from 'src/Models/Warranty/warranty';
import { Warranty, WarrantyServiceResponse } from './../../Models/Warranty/warranty';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from '../lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { LeadOpportunityAttachmentsUploadFilesComponent } from '../lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { ProjectManagementSiteDiariesCreateFilesComponent } from '../project-management/components/Site-Diaries/project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { WarrantyNewClaimEditWarrantyCategoryComponent } from '../project-management/components/Warranty/warranty-new-claim-edit-warranty-category/warranty-new-claim-edit-warranty-category.component';
import { WarrantyNewClaimWarrantyCategoryComponent } from '../project-management/components/Warranty/warranty-new-claim-warranty-category/warranty-new-claim-warranty-category.component';
import { CreateAttachmentForDocumentRequest, LookupNameSetupResponse, ScheduleItemParameterResponse } from 'src/Models/LeadTags';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { WarrantyService } from 'src/Service/Project-Management/warranty/warranty.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { ProjectManagementWarrantyPreDefinedCodes } from '../shared/component/Models/LookUpStandardFilterCodes';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ApplicationUserResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { ApplicationUserForComboResponse } from 'src/Models/InternalUser/InternalUserJob';

@Component({
  selector: 'app-completed-warranty-service',
  templateUrl: './completed-warranty-service.component.html',
  styleUrls: ['./completed-warranty-service.component.css']
})
export class CompletedWarrantyServiceComponent {
  // Saim Start work || 4/16/2024 || Saim_Raza_Warranty_Form



  @Output() cancel = new EventEmitter<void>();
  @Output() WarrantyResponse = new EventEmitter<WarrantyServiceResponse>();
  @Output() warrantyResponse = new EventEmitter<boolean>();
  @Input() isEditMode: boolean = false;

  public warrantyServiceData: WarrantyServiceResponse | null = null;

  appointmentData: any = {};
  warrantiesData:WarrantyServiceResponse[]; 
  savedResponseData: WarrantyServiceResponse ;
  Taghandle = true;
  isOwnerAccepted: { name: string; };
  isBuilderAccepted: { name: string; };
  responseHeader: number;
  selectedUsers: ApplicationUserResponse[] = [];
  isdeleteEditData: true;
  showEditForm = false;

    showDeleteButton = false;
  deleteButtonEnabled: boolean;

  
  TagClose(): void {
    this.cancel.emit();
  }
  @Output() UploadFile = new EventEmitter<File>();
  scheduleItemOption: ScheduleItemParameterResponse[] = [];
  serviceCoordinator: ApplicationUserForComboResponse[] = [];
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  ownerFeedbackOption: LookupNameSetupResponse[] = [];
  PAGE_ID: number = 10002;
  formName: string = "Completed Warranty Service";

  isData: any = {};
  selectedValue = null;
  date = null;
  isFormSaved = false;
  isFormSaved2 = false;
  isformSaved = false;
  isformSave = true;
  isFormSaved3 = false;
  isfeedbackData: boolean = false;



  isSaveButtonClicked: boolean = false;
  isCheckClicked: boolean = true;
  isCheckClicked2: boolean = true;
  submitted: boolean = false;
  dateFlag: boolean = false;
  isSaveCheckClicked: boolean = true;


  checked = true;
  checked2 = true;
  isopenDelete = this.warrantyEditData

  showCheckIcon: boolean = false;
  uploadfiles = true;

  preDefinedDataCodes = {
    OwnerFeedback: ProjectManagementWarrantyPreDefinedCodes.OwnerFeedback,

  };
  constructor(private leadOppService: LeadOpportunitiesService,
    private modal: NzModalService,
    private warrantyService: WarrantyService,
    private CustomerInformationService: CustomerInformationService,
    private LookupFilter: LookupFilterService,
    public toastsService: NzMessageService,
    @Inject(NZ_MODAL_DATA) public warrantyEditData: any

  ) {

console.log("warrantyEditData" ,warrantyEditData);


  }

  ngOnInit(): void {
       
    this.ForCombo();
    this.loadData();
    this.filedsBindRecord();
    // this.onSave(this.data);
   
    const currentDate = new Date();

    // Initialize the warrantyFieldValues object with default values
    this.appointmentData = {
      globalId: "00000000-0000-0000-0000-000000000000",
      warrantyInformationId: this.warrantyEditData.headerId,
      assignToUserId: 0,
      completionTime: currentDate, // Current date and time in ISO 8601 format
      completionOn: currentDate, // Current date in "YYYY-MM-DD" format
      serviceOn: currentDate, // Current date in "YYYY-MM-DD" format
      assignToSubVendorId: 0,
      startTime: null,
      endTime: null,
      internalNotes: null,
      attachmentId: 0,
      isAppointmentToOwner: true,
      isBuilderOverride: 0,
      ownerFeedbackId: 0,
      appointmentNotes: null,
      isOwnerAccepted: true,
      ownerAcceptedOn: null,
      isBuilderAccepted: false,
      builderAcceptedOn: null
    };




    // Set a timer to show the check icon after 1 second (adjust as needed)
    setTimeout(() => {
      this.showCheckIcon = true;
    }, 4000); // 1000 milliseconds = 1 second
    this.leadOppService.getSelectedFiles().subscribe(files => {
      this.selectedFiles = files;
    });
    this.isSaveButtonClicked = this.warrantyEditData.warrantyEditData;
    this.isfeedbackData = this.warrantyEditData.warrantyEditData;
    this.savedResponseData =  this.warrantyEditData.warrantyEditData;
    this.appointmentData['globalId'] = this.warrantyEditData.warrantyEditData.globalId;
    this.appointmentData['assignToUserId'] = this.warrantyEditData.warrantyEditData.assignToUserId;
    this.appointmentData['attachmentId'] = this.warrantyEditData.warrantyEditData.attachmentId;
    this.appointmentData['ownerFeedbackId']= this.warrantyEditData.warrantyEditData.ownerFeedbackId;
    this.appointmentData['startTime']= this.warrantyEditData.warrantyEditData.startTime;
    this.appointmentData['endTime']= this.warrantyEditData.warrantyEditData.endTime;
    this.appointmentData['internalNotes']= this.warrantyEditData.warrantyEditData.internalNotes;
    this.appointmentData['isAppointmentToOwner']= this.warrantyEditData.warrantyEditData.isAppointmentToOwner;
    this.appointmentData['isBuilderOverride']= this.warrantyEditData.warrantyEditData.isBuilderOverride;
    this.appointmentData['appointmentNotes']= this.warrantyEditData.warrantyEditData.appointmentNotes;
    this.appointmentData['isOwnerAccepted']= this.warrantyEditData.warrantyEditData.isOwnerAccepted;
    this.appointmentData['ownerAcceptedOn']= this.warrantyEditData.warrantyEditData.ownerAcceptedOn;
    this.appointmentData['isBuilderAccepted']= this.warrantyEditData.warrantyEditData.isBuilderAccepted;
    this.appointmentData['builderAcceptedOn']= this.warrantyEditData.warrantyEditData.builderAcceptedOn;
    this.appointmentData['serviceOn']= this.warrantyEditData.warrantyEditData.serviceOn;
    this.appointmentData['completionOn']= this.warrantyEditData.warrantyEditData.completionOn;
    this.appointmentData['completionTime']= this.warrantyEditData.warrantyEditData.completionTime;
    this.appointmentData['warrantyInformationId']= this.warrantyEditData.warrantyEditData.warrantyInformationId;
    this.appointmentData['assignToSubVendorId']= this.warrantyEditData.warrantyEditData.assignToSubVendorId;

  }

  selectedFiles: File[] = [];

  chooseFile(): void {
    const input = document.getElementById('files');
    if (input) {
      input.click();
    }
  }

  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.selectedFiles.push(files.item(i)!);
    }
  }

  uploadFiles(): void {
    console.log(this.selectedFiles);
  }
  removeFile(file: File): void {
    const index = this.selectedFiles.indexOf(file);
    if (index !== -1) {
      this.selectedFiles.splice(index, 1);
    }
  }
  clearAll(): void {
    this.selectedFiles = [];
  }
  uploadFilesTransferLeadOpp() {
    this.leadOppService.setSelectedFiles(this.selectedFiles);
    this.cancel.emit();
  }
  onChange(result: Date): void {
    console.log('onChange: ', result);
  }
  time: Date | null = null;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);

  time2: Date | null = null;
  defaultOpenValue2 = new Date(0, 0, 0, 0, 0, 0);

  Add(): void {
    const modalRef = this.modal.create({
      nzContent: WarrantyNewClaimWarrantyCategoryComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  // selectionChoiceModel
  Edit(): void {
    const modalRef = this.modal.create({
      nzContent: WarrantyNewClaimEditWarrantyCategoryComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  UploadFiles(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  CreateNew(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  viewAll(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }


  responseHeaderId: number = -1;


  saveWarrantyForm() {

    this.isSaveButtonClicked = true;
    this.isFormSaved2 = true;
    this.isformSaved = true;
    this.isFormSaved = true;
    this.isformSave = false;

    this.isFormSaved = true;


    let data = this.appointmentData;
    this.loadOwnerAndBuilderData();

    // let saveData: any = {};
    

    this.warrantyService.postWarrantyServiceData(data).subscribe(
      (response) => {
        this.savedResponseData = response.result; // Assign response data to the variable

        this.toastsService.success('New Warranty Service Saved Successfully!');
        this.isfeedbackData = true;
        if (response) {
          this.warrantyService.updateWarrantyData(response.result); // Update the shared service with response
          this.warrantyResponse.emit(true);
        
          this.responseHeader= response.result.id;
          console.log("RES : , ", response);

          this.responseHeaderId = response.result.id;
          this.formName = "Warranty Service";
     

          this.WarrantyResponse.emit(response.result);
       
           

        }

        if (this.selectedFiles && this.selectedFiles.length > 0) {
          const filesToUpload = this.selectedFiles.filter(file => file && file instanceof File);
          if (filesToUpload.length > 0) {
            const attachmentRequest: CreateAttachmentForDocumentRequest = {
              headerId: response.result.id,
              companyParameterId: 1,
              formNameId: 30005,
              documentFiles: filesToUpload.map(file => file)
            };

            this.leadOppService.CreateAttachmentForDocument(attachmentRequest).subscribe(
              (attachmentResponse) => {
                // Assuming attachmentResponse contains the file ID, you can save it to the database
                this.saveFileIdToDatabase(attachmentResponse.fileId);
              },
              (attachmentError) => {
                console.error('Error creating attachment:', attachmentError);
              }
            );
          } else {
            console.warn('No files to upload.');
          }
        }
      },
      (error) => {
        console.error('Error saving data:', error);
      }
    );
  }


  saveFileIdToDatabase(fileId: string): void {
  }
  ForCombo() {
    this.CustomerInformationService.getDataforcombo().subscribe((res) => {
      const serviceCoordinator = res.result;


      this.serviceCoordinator = serviceCoordinator;
// console.log("serviceCoordinator",serviceCoordinator);

    });
  }


  checkBoxToggle() {
    this.isCheckClicked = this.isCheckClicked;
    // Agar checkbox unchecked hai, to submitted ko false karen
    if (this.isCheckClicked) {
      this.submitted = false;
    }
  }



  filedsBindRecord() {
    this.LookupFilter.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe(
      (res) => {
        this.lookupSetNameResponse = res.result;
        let ownerFeedbackValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.OwnerFeedback.code
        );
        this.ownerFeedbackOption = ownerFeedbackValues;

        // Find the option with name 'Service Completed'
        const serviceCompletedOption = this.ownerFeedbackOption.find(option => option.name === 'Service Completed');
        if (serviceCompletedOption) {
          // Set its ID to appointmentData['ownerFeedbackId']
          this.appointmentData['ownerFeedbackId'] = serviceCompletedOption.id;
        }
      }
    );
  }

  logButtonToggle(): void {
    // Code to handle the logic when the log button is clicked
    this.appointmentData.isBuilderOverride = !this.appointmentData.isBuilderOverride; // Toggle isBuilderOverride
  }
  updateCheckboxState(selectedDate: Date): void {
    this.appointmentData['serviceOn'] = selectedDate; // Update the selected date in your model
    this.isCheckClicked = false;
    this.isCheckClicked2 = false; // Reset the checkbox state
    // Reset the checkbox state
  }

  isDateAfterCurrent(): boolean {
    const selectedDate = new Date(this.appointmentData['serviceOn']);
    const currentDate = new Date();
    return selectedDate > currentDate;
  }
  loadOwnerAndBuilderData(): void {
    // Simulated data for owner and builder (replace with actual API calls)
    this.isOwnerAccepted = { name: 'John Doe' }; // Example owner data
    this.isBuilderAccepted = { name: 'Builder Inc.' }; // Example builder data
  }

  loadData() {
    // Example: Fetch initial data and assign to isData
    this.warrantyService.postWarrantyServiceData(this.warrantyEditData).subscribe(
      (response) => {
        this.isData = response; // Assign received data to isData
      },
      (error) => {
        console.error('Error fetching data:', error);
        // Handle error scenario if needed
      }
    );
  }



  // Saim End work || 4/16/2024 || Saim_Raza_Warranty_Form
}
