import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNode, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { FieldDataType } from 'src/app/Core/Enums/data_types';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { AddNewStandardFilterComponent } from '../../Modal/add-new-standard-filter/add-new-standard-filter.component';
import { ProjectManagementTimeClockPreDefinedCodes } from '../../Models/LookUpStandardFilterCodes';
import { CreateLookupFilterRequest, LookupFilterResponse, LookUpStandardResponse, LookupFilter, LookupNameSetupResponse, FilterSearchParameter } from '../../Models/LookupModels';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { CostCode } from 'src/Models/CostCode/costCode';
import { CostCodeService } from 'src/Service/Internaluser/cost-code.service';
import { TimeClockService } from 'src/Service/TimeClock/time-clock.service';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';

@Component({
  selector: 'app-project-management-time-clock-filter-field',
  templateUrl: './project-management-time-clock-filter-field.component.html',
  styleUrls: ['./project-management-time-clock-filter-field.component.css']
})
export class ProjectManagementTimeClockFilterFieldComponent {

  value: string[] = ['0-0-0'];
  selectedValue = null;
  @Input() pageId: number;
  projectmanagementtimeclockFilterForm: FormGroup;
  createLookupFilterRequests: CreateLookupFilterRequest[] = [];
  filterResponseData: LookupFilterResponse[] = [];
  lookUpStandardResponse: LookUpStandardResponse[] = [];
  defaultStandardFilter: LookupFilterResponse;
  predefinedValuesMultiSelect: string[] = [];
  DateOptions: { label: string, value: number }[] = [];
  ShiftDataOptions: { label: string, value: number }[] = [];
  ApprovalAllDataOption: { label: string, value: number }[] = [];
  LookupNameSetupResponse: LookupNameSetupResponse[] = [];
  roles: { id: string, name: string }[] = [];
  TagsNodes: NzTreeNodeOptions[] = [];
  CostCodeNodes: NzTreeNodeOptions[] = [];
  ApprovalStatusNodes: NzTreeNodeOptions[] = [];
  OutofBoundsStatusNodes: NzTreeNodeOptions[] = [];
  formFieldValues: any = {};
  costSubCode: CostCode[] = [];
  User: NzTreeNodeOptions[] = [];
  isFormDirty: boolean = false;
  filterSearchParameter: FilterSearchParameter;
  editSaveFilterFormGroup: FormGroup;
  selectedFilter: number = -1;
  selectedJobId: number;



  preDefinedDataCodes = {
    standardFilter: ProjectManagementTimeClockPreDefinedCodes.standardFilter,
    user: ProjectManagementTimeClockPreDefinedCodes.User,
    tags: ProjectManagementTimeClockPreDefinedCodes.Tags,
    date: ProjectManagementTimeClockPreDefinedCodes.Date,
    shiftStatusType: ProjectManagementTimeClockPreDefinedCodes.ShiftStatusType,
    costCodes: ProjectManagementTimeClockPreDefinedCodes.CostCodes,
    approvalStatus: ProjectManagementTimeClockPreDefinedCodes.ApprovalStatus,
    approvalDate: ProjectManagementTimeClockPreDefinedCodes.ApprovalDate,
    outofBoundsStatus: ProjectManagementTimeClockPreDefinedCodes.OutofBoundsStatus,

  };
approvalStatus: NzTreeNodeOptions[]|NzTreeNode[];

  constructor(
    private fb: FormBuilder,
    public toastService: NzMessageService,
    private loadingIndicatorService: LoadingIndicatorService,
    private modal: NzModalService,
    private LookupFilterService: LookupFilterService,
    private customerInformationService: CustomerInformationService,
    private lookUpFilterService: LookupFilterService,
    private accessLocalStorageService: AccessLocalStorageService,
    private costCodeServices: CostCodeService,
    private userTimeClockSer: TimeClockService,
    private internalUserService: InternalUserService
  ) { }
  ngOnInit(): void {
    this.selectedJobId = this.accessLocalStorageService.getJobId();
    const lookupFilter: LookupFilter = {
      formNameId: this.pageId,
    };
    this.getStandardFilterByFormPageId(this.pageId);
    this.fetchData(lookupFilter);
    this.initLoad();
    this.initEditSaveFilter()
  }


  private initEditSaveFilter(): void {
    this.editSaveFilterFormGroup = this.fb.group({
      globalId: ['00000000-0000-0000-0000-000000000000'],
      companyParameterId: [0],
      code: ['', Validators.required],
      name: ['', Validators.required],
      description: [''],
      meaning: [''],
      formNameId: [0],
      dataTypeId: [],
      isShareThisFilter: [true],
      isSetAsDefault: [false],
      createLookupFilterRequests: [[]],
    });
  }
  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(res => {
      console.log('res',res);
      
      let TagsData = res.result.filter(x => x.code === this.preDefinedDataCodes.tags.code);
      let Date = res.result.filter(x => x.code === this.preDefinedDataCodes.date.code);
      let ShiftStatusTypedata = res.result.filter(x => x.code === this.preDefinedDataCodes.shiftStatusType.code);
      let ApprovalStatusData = res.result.filter(x => x.code === this.preDefinedDataCodes.approvalStatus.code);
      let ApprovalDate = res.result.filter(x => x.code === this.preDefinedDataCodes.approvalDate.code);
      let OutofBoundsStatus = res.result.filter(x => x.code === this.preDefinedDataCodes.outofBoundsStatus.code);




        this.costCodeServices.getCategoryData().subscribe((res) => {
        this.costSubCode = res.result;

 // costcode//
        this.CostCodeNodes = [
          {
            title: 'Select All',
            value: 'select_all',
            key: 'select_all',
            selectable: false,
            isLeaf: false,
            expanded: true,
            children: this.costSubCode.map((costCode) => ({
              title: costCode.title,
              value: costCode.id.toString(),
              key: costCode.id.toString(),
              isLeaf: true
            }))
          }
        ];
      });
      // tagsnods//
        this.TagsNodes = [
             {
              title: 'Select All',
              value: 'select_all',
              key: 'select_all',
              selectable: false,
              isLeaf: false,
              expanded: true,
              children: TagsData.map((status) => ({
               title: status.name,
               value: status.id.toString(),
               key: status.id.toString(),
               isLeaf: true
              }))
             }
            ];

      this.DateOptions = Date.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));
      this.ShiftDataOptions = ShiftStatusTypedata.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));
      this.costCodeServices.getCategoryData().subscribe((res) => {
        this.costSubCode = res.result;

 // costcode//
        this.CostCodeNodes = [
          {
            title: 'Select All',
            value: 'select_all',
            key: 'select_all',
            selectable: false,
            isLeaf: false,
            expanded: true,
            children: this.costSubCode.map((costCode) => ({
              title: costCode.title,
              value: costCode.id.toString(),
              key: costCode.id.toString(),
              isLeaf: true
            }))
          }
        ];
      });


 // approvalstatus//
      this.ApprovalStatusNodes = [
             {
              title: 'Select All',
              value: 'select_all',
              key: 'select_all',
              selectable: false,
              isLeaf: false,
              expanded: true,
              children: ApprovalStatusData.map((status) => ({
               title: status.name,
               value: status.id.toString(),
               key: status.id.toString(),
               isLeaf: true
              }))
             }
            ];

      this.ApprovalAllDataOption = ApprovalDate.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));


      // outofbonds//
  this.OutofBoundsStatusNodes = [
       {
        title: 'Select All',
        value: 'select_all',
        key: 'select_all',
        selectable: false,
        isLeaf: false,
        expanded: true,
        children: OutofBoundsStatus.map((status) => ({
         title: status.name,
         value: status.id.toString(),
         key: status.id.toString(),
         isLeaf: true
        }))
       }
      ];
    })

// user//
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      this.User = [
        {
          title: 'Select All',
          value: 'select_all',
          key: 'select_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: res.result.map((salesperson) => ({
            title: salesperson?.fullName,
            value: salesperson.id.toString(),
            key: salesperson.id.toString(),
            isLeaf: true
          }))
        }
      ];
    });

  }
  // on tag change//
  onTagChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.TagsNodes[0].children.map(child => child['value']);
      this.formFieldValues['tags'] = [...allValues];
    } else {
      const allValues = this.TagsNodes[0].children.map(child => child['value']);
      this.formFieldValues['tags'] = selectedValues.filter(value => allValues.includes(value));
    }
  }

// costcode//

  onCostChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.CostCodeNodes[0].children.map(child => child['value']);
      this.formFieldValues['costCodes'] = [...allValues];
    } else {
      const allValues = this.CostCodeNodes[0].children.map(child => child['value']);
      this.formFieldValues['costCodes'] = selectedValues.filter(value => allValues.includes(value));
    }
  }

  // on approvals//
  onapprovalStatusChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.ApprovalStatusNodes[0].children.map(child => child['value']);
      this.formFieldValues['approvalStatus'] = [...allValues];
    } else {
      const allValues = this.ApprovalStatusNodes[0].children.map(child => child['value']);
      this.formFieldValues['approvalStatus'] = selectedValues.filter(value => allValues.includes(value));
    }
  }
   // outofbonds//

   onoutofBoundsStatusChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.OutofBoundsStatusNodes[0].children.map(child => child['value']);
      this.formFieldValues['outofBoundsStatus'] = [...allValues];
    } else {
      const allValues = this.OutofBoundsStatusNodes[0].children.map(child => child['value']);
      this.formFieldValues['outofBoundsStatus'] = selectedValues.filter(value => allValues.includes(value));
    }
  }

// user//
  onuserChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
      const allValues = this.User[0].children.map(child => child['value']);
      this.formFieldValues['user'] = [...allValues];
    } else {
      const allValues = this.User[0].children.map(child => child['value']);
      this.formFieldValues['user'] = selectedValues.filter(value => allValues.includes(value));
    }

  }


  onFilterApply(): void {
    let selectedFilter = this.formFieldValues['standardFilter'];
    let isSelectedFilterStandard = this.filterResponseData.find(x => x.id === selectedFilter && x.isNotDeleted === true);
    if (isSelectedFilterStandard && isSelectedFilterStandard != undefined) {
      this.selectedFilter = 1;
    }
    else {
      this.selectedFilter = 0;
    }
    this.createFormFieldsInArray();
    this.createLookupFilterRequests[0].page = 1;
    this.createLookupFilterRequests[0].pageSize = 10;

    this.internalUserService.getAppliedFilterData(this.createLookupFilterRequests);

  }

  fetchData(lookUpStandard: LookupFilter): void {
    this.LookupFilterService.postData(lookUpStandard).subscribe(
      data => {
        this.lookUpStandardResponse = data.result;
      },
      error => {
        console.error('Error fetching data:', error);
      }
    );
  }


  openStandardFilterComponent(type: string) {
    this.createLookupFilterRequests = [];
    this.createFormFieldsInArray();
    const modalRef = this.modal.create({
      nzContent: AddNewStandardFilterComponent,
      nzFooter: null,
      nzData:
      {
        pageId: this.pageId,
        std: this.filterResponseData,
        field: this.createLookupFilterRequests,
        clickFrom: type
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  getStandardFilterByFormPageId(pageId: number) {
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.filterResponseData = result.result;
        this.initializeForm();
        setTimeout(() => {
          let id = this.accessLocalStorageService.getSelectedFilterId();
          this.setDefaultFieldValues(id);
        }, 100);

      }
    });
  }
  resetFilter(): void {
    Object.keys(this.formFieldValues).forEach(field => {
      if (field !== 'standardFilter') {
        if (Array.isArray(this.formFieldValues[field])) {
          this.formFieldValues[field] = [];
        } else {
          this.formFieldValues[field] = '';
        }
      }
    });
    this.selectedFilter = -1;
  }
  createFormFieldsInArray() {
    this.createLookupFilterRequests = [];
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const value = this.formFieldValues[field];
      if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 0)) {
        const newRow = {
          globalId: '00000000-0000-0000-0000-000000000000',
          companyParameterId: 0,
          lookUpStandardId: 0,
          code: code,
          meaning: '',
          valueId: 0,
          formNameId: this.pageId,
          dataTypeId: 0,
          jobInformationId: this.selectedJobId,
          createLookupFilterParameterRequests: [null]
        };

        const correspondingResponse = this.lookUpStandardResponse.find(response => response.code === code);
        if (correspondingResponse) {
          newRow.companyParameterId = correspondingResponse.companyParameterId;
          newRow.lookUpStandardId = correspondingResponse.id;
          newRow.dataTypeId = correspondingResponse.dataTypeId;

          if (
            correspondingResponse.dataType.code === FieldDataType.SingleLineText ||
            correspondingResponse.dataType.name === 'Single-Line Text') {
            newRow.meaning = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.Dropdown ||
            correspondingResponse.dataType.name === 'Dropdown') {
            newRow.valueId = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.MultiSelectDropdown ||
            correspondingResponse.dataType.name === 'Multi-Select Dropdown'
          ) {
            newRow.createLookupFilterParameterRequests = value?.map((item, index) => {
              return { valueId: item };
            });
          }
        }
        this.createLookupFilterRequests.push(newRow);
      }
    });
  }

  getDefaultFilterValue() {
    return this.accessLocalStorageService.getSelectedFilterId();
  }


  setDefaultFieldValues(standardFilterId: number): void {
    this.predefinedValuesMultiSelect = [];
    let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
    let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;

    Object.keys(this.formFieldValues).forEach(field => {
      if (field !== 'standardFilter') {
        if (Array.isArray(this.formFieldValues[field])) {
          this.formFieldValues[field] = [];
        } else {
          this.formFieldValues[field] = '';
        }
      }
    });


    if (lookUpFiltersField.length > 0) {
      Object.keys(this.preDefinedDataCodes).forEach(field => {
        const code = this.preDefinedDataCodes[field].code;
        const fieldData = lookUpFiltersField.find(filter => filter.code === code);
        if (fieldData != undefined) {
          if (
            fieldData.dataType.code === FieldDataType.SingleLineText) {
            this.formFieldValues[field] = fieldData.meaning;
          }
          if (fieldData.dataType.code === FieldDataType.Dropdown) {
            this.formFieldValues[field] = fieldData.valueId.toString();
          }
          if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown) {
            let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
            this.predefinedValuesMultiSelect = multiSelectValues.map(String);
            this.formFieldValues[field] = this.predefinedValuesMultiSelect;
          }
        }
      });
    } else {
      console.log("lookUpFiltersField is null ", lookUpFiltersField);
    }
  }
  onChangeStandardFilter($event: number): void {
    this.selectedFilter = -1;
    const defaultFIlterId = this.getDefaultFilterValue();
    this.accessLocalStorageService.setSelectedFilterId($event);
    // New Changes By Aamir Ali - 22-Apr2024
    let id = this.accessLocalStorageService.getSelectedFilterId();
    this.setDefaultFieldValues(id);
    this.userTimeClockSer.setSelectedFilterId(id);
    this.filterSearchParameter = {
      CompanyParameterId: 1,
      FormNameId: this.pageId,
      LookUpFilterId: $event,
      page: 1,
      pageSize: 10,
      jobInformationId: this.selectedJobId
    };
    if (defaultFIlterId !== $event) {
      this.userTimeClockSer.clockAppliedFilter(true);
    } else {
      this.userTimeClockSer.clockAppliedFilter(false);
    }
    this.userTimeClockSer.getTimeClockShift(this.filterSearchParameter);

  }

  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
    this.isFormDirty = false;
  }
  editSaveFilterData(): void {
    let selected_Filter_Id = this.formFieldValues['standardFilter'];
    let getSelectedFilterValue = this.filterResponseData.find(filter => filter.id === selected_Filter_Id);
    this.createFormFieldsInArray();
    this.editSaveFilterFormGroup.controls['name'].setValue(getSelectedFilterValue.name);

    if (this.editSaveFilterFormGroup.get('name')?.invalid) {
      this.toastService.error('Name field is required');
      return;
    }
    this.editSaveFilterFormGroup.controls['companyParameterId'].setValue(getSelectedFilterValue.companyParameterId);
    this.editSaveFilterFormGroup.controls['code'].setValue(getSelectedFilterValue.code);
    this.editSaveFilterFormGroup.controls['formNameId'].setValue(getSelectedFilterValue.formNameId);
    this.editSaveFilterFormGroup.controls['dataTypeId'].setValue(getSelectedFilterValue.dataTypeId);
    this.editSaveFilterFormGroup.controls['globalId'].setValue(getSelectedFilterValue.globalId);
    this.editSaveFilterFormGroup.controls['createLookupFilterRequests'].setValue(this.createLookupFilterRequests);
    this.editSaveFilterFormGroup.controls['isSetAsDefault'].setValue(getSelectedFilterValue.isSetAsDefault);
    this.lookUpFilterService.saveStandardFilterData(this.editSaveFilterFormGroup.value)
      .subscribe(
        (res: any) => {
          console.log(res.result);
          setTimeout(() => {
            this.loadingIndicatorService.hide();
            this.toastService.success('Filter saved successfully');
            this.getStandardFilterByFormPageId(this.pageId);
            this.selectedFilter = -1;
          }, 20);
        },
        (error) => {
          console.error('Error:', error);
          this.loadingIndicatorService.hide();
          if (error.status === 500) {
            this.toastService.error('Internal server error. Please try again later.');
          } else {
            this.toastService.error('An error occurred. Please try again.');
          }
        }
      );
    this.selectedFilter = -1;
  }

  initializeForm(): void {
    this.formFieldValues = {
      standardFilter: this.getDefaultFilterValue(),
      date: '',
      shiftStatusType: '',
      approvalDate: '',
      user: [] = [],
      tags: [] = [],
      costCodes: [] = [],
      approvalStatus: [] = [],
      outofBoundsStatus: [] = [],

    };


  }
}
