import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CompanyInfo } from 'src/Models/CompanySettings/CompanyInformation';
import { CompanyInformationService } from 'src/Service/CompanyInFormation/company-information.service';
import { ModalService } from 'src/Service/CompanyInFormation/modal.service';


@Component({
  selector: 'app-company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.css']
})
export class CompanyInformationComponent implements OnInit{

  checked = false;
  checked1 = false;
  private subscription: Subscription;
  companyinformation: boolean = false;

    // <------Jobinformation------->
    CompanyformationFormFormGroup:FormGroup;
  
  constructor(private modalService: ModalService,private fb:FormBuilder,private ComIforServices:CompanyInformationService){
    this.subscription = this.modalService.showModal$.subscribe(() => {
      this.CompanyInformation();
    });
  }
  ngOnInit(): void {
this.initForm();
  }


  
  CompanyInformation(){
    this.companyinformation = true;
  }
  closeCompanyInformation(){
    this.companyinformation = false;
  }
  @Output() checkedChange = new EventEmitter<boolean>();

  isChecked: boolean = this.checked;

  toggleCheckbox() {
    this.isChecked = !this.isChecked;
    this.checkedChange.emit(this.isChecked);
  }

  private initForm(): void{
    this.CompanyformationFormFormGroup = this.fb.group({
      companyNameId: ['1'],
      name: [''],
      includeOnPrintOuts: [''],
      includeAllAddressInformationPrintOut: [''],
      streetAddress: [''],
      suburb: [''],
      state: [''],
      postalCode: [''],
      phoneNumber: [''],
      fax: [''],
      field1: [''],
      field2: [''],
      field3: [''],
      webSiteUrl: [''],
    });
  }
SaveComInfor(){
  if (this.CompanyformationFormFormGroup && this.CompanyformationFormFormGroup.valid) {
    const formData: CompanyInfo = this.CompanyformationFormFormGroup.value;
    this.ComIforServices.postData(formData).subscribe(
      (response) => {
        console.log('Data saved successfully:', response);
        alert('Data saved successfully In CompanyInformation');
        this.CompanyformationFormFormGroup.reset();
      },
      (error) => {
        console.error('Error saving data:', error);
        alert('Data not saved');
      }
    );
  } else {
    console.log('Form is invalid or not initialized. Please check.');
  }
}
}
