import { ReminderSetupService } from './../services/reminder-setup.service';
import { SendPastDueRfiIReminderuptoService } from './../services/send-past-due-rfi-ireminderupto.service';
import { RfisService } from './../services/rfis.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { rfiReminder } from 'src/Models/rfisReminder,model';
import { sendPastDueRfiReminderUpToId } from 'src/Models/sendPastDueRFISReminder.model';
import { companySettingRFIsPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-rfis',
  templateUrl: './rfis.component.html',
  styleUrls: ['./rfis.component.css']
})
export class RFIsComponent implements OnInit {
@Output() cancel = new EventEmitter<void>();
PAGE_ID: number = 30019;
selectedValue = null;
rfisFormGroup:FormGroup
selectedValue1: any;
rFIsForm: any = {};
dataset: any;
DefaultRFIreminders : LookupNameSetupResponse[] = [];
SendPastDueRFIReminderupto : LookupNameSetupResponse[] = [];
customfieldinfo:boolean = false;


preDefinedDataCodes = {
  DefaultRFIreminders: companySettingRFIsPreDefinedCodes.RFIDFR,
  SendPastDueRFIReminderupto: companySettingRFIsPreDefinedCodes.RFISPDR,
};
  constructor(
    private rfisService : RfisService,
    private reminderSetupService: ReminderSetupService,
    private LookupFilterService :LookupFilterService,
    private message: NzMessageService,
    private modal: NzModalService,
    private toastssService: NzMessageService,


  ){ }
  ngOnInit() {
   this.initLoad();
   this.fetchCompanyRFIsData();

   this.rFIsForm = {
    globalId: '00000000-0000-0000-0000-000000000000',
    companyParameterId: 1,
    rfiReminderId: '',
    sendPastDueRfiReminderUpToId: ''
   }

  }

  CustomFieldInfo(){
    this.customfieldinfo = true;
  }
  handleCancel2(): void {
    this.customfieldinfo = false;
  }
// <..... Default RFI reminders.......>
initLoad() {
  this.LookupFilterService.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe(
    (res) => {

      // -----------single Select ---------------

      let DefaultRFIsreminders = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.DefaultRFIreminders.code
      );
      let SendPastDueRFIReminderupto = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.SendPastDueRFIReminderupto.code
      );

      // -----------single Select -------------
      this.DefaultRFIreminders = DefaultRFIsreminders;

      this.SendPastDueRFIReminderupto = SendPastDueRFIReminderupto;
    }
  );
}

rFIsSave(){
  this.rfisService.postRFIs(this.rFIsForm).subscribe((response) => {
    this.toastssService.success('New  Successfully!');
    this.rFIsForm = {};
    this.modal.closeAll();
  });
}

fetchCompanyRFIsData(){
  this.rfisService.getAllCompanyRFis().subscribe(
    (response) => {
      console.log('Sami Time Clock Data:', response.result);
      this.dataset = response.result;

      if (this.dataset.length > 0) {
        const rFistItem = this.dataset[0];
        this.rFIsForm = {
          companyParameterId: rFistItem.companyParameterId || 1,
          rfiReminderId: rFistItem.rfiReminderId || '',
          sendPastDueRfiReminderUpToId: rFistItem.sendPastDueRfiReminderUpToId || '',

        };
      }
    },
    (error) => {
      console.error('Error:', error);
    }
  );
}





// getRfisReminder(){
//   this.rfisService.getData().subscribe((data:any)=>{

//   this.RfiReminder= data.result;

//   if (this.RfiReminder && this.RfiReminder.length > 0) {
//     this.selectedValue1 = this.RfiReminder[0].id; // Assuming 'id' is the property you want to set as default
//   }

//     console.log('response RfisReminder',data.result)
//   })

//   }




  SendPastDueRfiReminderupto:sendPastDueRfiReminderUpToId[]=[];

  // getSendPastDueRfiReminderUpToId(){
  //   this.sendPastDueRfiReminderUpToIdService.getData().subscribe((res:any)=>{

  //   this.SendPastDueRfiReminderupto= res.result;

  //   if (this.SendPastDueRfiReminderupto && this.SendPastDueRfiReminderupto.length > 0) {
  //     this.selectedValue1 = this.SendPastDueRfiReminderupto[0].id; // Assuming 'id' is the property you want to set as default
  //   }

  //     console.log('response SendPastDueRfiReminderupto',res.result)
  //   })
  //   }


postData(): void {
  const formData = this.rfisFormGroup.value;

  this.reminderSetupService.postData(formData).subscribe(
    (response: any) => {
      // Handle success
      console.log('Post Data Success1123:', response.result);
      this.createMessage('success', 'Data posted successfully');
      console.log('Post Data :', formData);

    },
    (error: { status: number; }) => {
      // Handle error
      console.error('Post Data Error:', error);

      if (error.status === 0) {
        this.createMessage('error', 'Server is unreachable. Please try again later.');
      } else if (error.status >= 500) {
        this.createMessage('error', 'Internal server error. Please try again later.');
      } else {
        this.createMessage('error', 'An error occurred. Please try again.');
      }
    }
  );
}


createMessage(type: string, content: string): void {
  this.message.create(type, content);
}
}
