import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { JobGroupParameterResponse, JobInformationResponse } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { JobTypeGroupInformation } from 'src/Models/Job-List/Job-Information/JobTypeGroup';
import { ResponseModel, ResponseModelArray } from 'src/Models/responseMessage.model';

import { environment } from 'src/environments/environment.prod';
import { CreateJobSetupRequest, JobSetupResponse, JobSetupResponseGetId } from '../../../Models/Job-List/Job-Information/Job-Comp-setting-Classes';

@Injectable({
  providedIn: 'root'
})
export class JobTypeGroupService {

  private baseUrl: string = environment.apiUrl + 'JobTypeGroup/'; 
  private BASE_Url: string = environment.apiUrl + 'JobGroup/';
  private Base_Url_Get_Data_JobType: string = environment.apiUrl + 'JobType/';
  private JOB_SETUP_COMP_SETTING_URL: string = environment.apiUrl + 'JobSetup/'; 
  private JOB_SETUP_COMP_SETTING_Patch: string = environment.apiUrl + 'JobSetup/'; 

  

  constructor(private http: HttpClient) {}

  postData(data: JobGroupParameterResponse): Observable<ResponseModelArray<JobInformationResponse>> {
    return this.http.post<ResponseModelArray<JobInformationResponse>>(`${this.BASE_Url}`, data);
  }

  getData(): Observable<ResponseModelArray<JobInformationResponse>> {
    return this.http.get<ResponseModelArray<JobInformationResponse>>(`${this.BASE_Url}getAll`);
  }

  updateData(data: JobTypeGroupInformation): Observable<JobTypeGroupInformation> {
    return this.http.put<JobTypeGroupInformation>(`${this.baseUrl}`, data);
  }

  deleteData(id: number): Observable<JobInformationResponse> {
    return this.http.delete<JobInformationResponse>(
      `${this.BASE_Url}?globalId=${id}`
    );
  }
  getDataId(id: number): Observable<JobTypeGroupInformation> {
    return this.http.get<JobTypeGroupInformation>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }

  // getDataforJobType(): Observable<ResponseModelArray<JobSetupResponse>> {
  //   return this.http.get<ResponseModelArray<JobSetupResponse>>(`${this.Base_Url_Get_Data_JobType}GetAll`);
  // }

  jobsetupCompanySettingPostData(jobsetupCompanySetting: CreateJobSetupRequest): Observable<ResponseModel<JobSetupResponse>> {
    const apiUrl = `${this.JOB_SETUP_COMP_SETTING_URL}`;
    return this.http.post<any>(apiUrl, jobsetupCompanySetting).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error); 
      })
    );
  }

  getAllJobSetupPatch(): Observable<ResponseModel<JobSetupResponseGetId>> {
    return this.http.get<ResponseModel<JobSetupResponseGetId>>(`${this.JOB_SETUP_COMP_SETTING_Patch}getData-ByCompany`);
  }


}