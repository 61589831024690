import { Component } from '@angular/core';

@Component({
  selector: 'app-inner-tab-predecessors',
  templateUrl: './inner-tab-predecessors.component.html',
  styleUrls: ['./inner-tab-predecessors.component.css']
})
export class InnerTabPredecessorsComponent {
  rows: any[] = [{ name: '', type: null, days: '' }];
  selectedValue=null;
  demoValue=0;
  removeRow(index: number) {
    this.rows.splice(index, 1); // Remove the row at the specified index
  }
  addRow() {
    if (this.rows.length < 5) { // Limit to 5 rows
      const newRow = {
        name: '',
        type: null,
        days: ''
      };
      this.rows.push(newRow);
    }
  }
}
