<div *ngIf="filterResponseData && filterResponseData.length > 0">
    <form  (ngSubmit)="onFilterApply()">
      <div class="row mt-2">
        <!-- <div class="col-10 p-0">
          <div class="input-group">
            <nz-select [(ngModel)]="formFieldValues['standardFilter']" name="standardFilter" (ngModelChange)="onChangeStandardFilter($event)" nzShowSearch
              [lang]="true" nzAutoFocus="true" nzPlaceHolder="Standard Filter">
              <nz-option *ngFor="let filter of filterResponseData" [nzLabel]="filter.name"
                [nzValue]="filter.id"></nz-option>
            </nz-select>
          </div>
        </div> -->


        <div class="col-10 p-0">
          <div class="input-group">
           <nz-select
            [(ngModel)]="formFieldValues['standardFilter']"
            name="standardFilter"
            (ngModelChange)="onChangeStandardFilter($event)"
            nzShowSearch
            [nzAutoFocus]="true"
            nzPlaceHolder="Standard Filter"
            r>
            <nz-option *ngFor="let filter of filterResponseData"
             [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')"
             [nzValue]="filter.id"
             nzCustomContent>
             <div class="option-content">
              <!-- Customize content with icons and text -->
              <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
              <span>{{ filter.name }}</span>
             <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

             </div>
            </nz-option>
           </nz-select>
          </div>
         </div>
        <div class="col-1 mt-1">
          <i class="bi bi-three-dots"
          nz-popover
          [nzPopoverContent]="contentTemplate"
          [nzPopoverPlacement]="'top'"
           (click)="openStandardFilterComponent('SAVE_AS')"></i>
        </div>
      </div>
      <div class="row mt-2" *ngIf="selectedFilter === 1">
        <div class="col">
          <p>Your filter has unsaved changes.</p>
        </div>
      </div>
      <div class="row mt-2" *ngIf="selectedFilter === 0">
        <div class="col">
          <p>Your filter has unsaved changes.</p>
        </div>
      </div>

      <div class="col d-flex mt-3">
        <button (click)="openStandardFilterComponent('APPLY_SAVE_AS')" type="button" nz-button nzType="default" class="me-2" style="font-size: 12px;"
        *ngIf="selectedFilter === 1">Save As</button>
        <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;" *ngIf="selectedFilter === 1" type="button">Reset</button>
        <button (click)="editSaveFilterData()" nz-button nzType="primary" type="button" class="me-2" style="font-size: 12px;" *ngIf="selectedFilter === 0">Save</button>
        <button (click)="openStandardFilterComponent('APPLY_SAVE_AS')" nz-button nzType="default" class="me-2"
        style="font-size: 12px;" *ngIf="selectedFilter === 0" type="button">Save As</button>
        <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;" *ngIf="selectedFilter === 0" type="button">Reset</button>
      </div>

      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">User</label>

        <nz-tree-select
        [(ngModel)]="formFieldValues['user']"
        name="user"
        style="width: 100%"
        [nzNodes]="User"
        [nzShowSearch]="true"
        nzAllowClear="false"
        nzCheckable
        (ngModelChange)="onuserChange($event)"
        nzPlaceHolder="Select user"
        nzDropdownClassName="dropdownStyle"
        [nzAllowClear]="false"
      >
      </nz-tree-select>

        </div>
      </div>
      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">Tags</label>

        <nz-tree-select
             [(ngModel)]="formFieldValues['tags']"
             name="tags"
             style="width: 100%"
             [nzNodes]="TagsNodes"
             nzShowSearch
             nzAllowClear="false"
             nzCheckable
             (ngModelChange)="onTagChange($event)"
             nzPlaceHolder="Select tags"
             nzDropdownClassName="dropdownStyle"
            >
            </nz-tree-select>

        </div>
      </div>
      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">Date</label>
          <nz-select  name="date" nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['date']">
            <nz-option *ngFor="let option of DateOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>
        </div>
      </div>
      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">Shift Status Type </label>
          <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['shiftStatusType']" name="shiftStatusType">
            <nz-option *ngFor="let option of ShiftDataOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>
        </div>
      </div>
      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">Cost Codes </label>

        <nz-tree-select
        [(ngModel)]="formFieldValues['costCodes']" name="costCodes"
             style="width: 100%"
             [nzNodes]="CostCodeNodes"
             nzShowSearch
             nzAllowClear="false"
             nzCheckable
             (ngModelChange)="onCostChange($event)"
             nzPlaceHolder="Select costCodes"
             nzDropdownClassName="dropdownStyle"
            >
            </nz-tree-select>
        </div>
      </div>
      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">Approval Status</label>

        <nz-tree-select
             [(ngModel)]="formFieldValues['approvalStatus']"
             name="approvalStatus"
             style="width: 100%"
             [nzNodes]="ApprovalStatusNodes"
             nzShowSearch
             nzAllowClear="false"
             nzCheckable
             (ngModelChange)="onapprovalStatusChange($event)"
             nzPlaceHolder="Select approvalStatus"
             nzDropdownClassName="dropdownStyle"
            >
            </nz-tree-select>

        </div>
      </div>
      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">Approval Date</label>
          <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['approvalDate']" name="approvalDate">
            <nz-option *ngFor="let option of ApprovalAllDataOption" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>
        </div>
      </div>
      <div class="row">
        <div class="col mt-1 p-0">
          <label class="sub-text">Out of Bounds Status</label>

        <nz-tree-select
             [(ngModel)]="formFieldValues['outofBoundsStatus']"
             name="outofBoundsStatus"
             style="width: 100%"
             [nzNodes]="OutofBoundsStatusNodes"
             nzShowSearch
             nzAllowClear="false"
             nzCheckable
             (ngModelChange)="onoutofBoundsStatusChange($event)"
             nzPlaceHolder="Select outofBoundsStatus"
             nzDropdownClassName="dropdownStyle"
            >
            </nz-tree-select>

        </div>
      </div>
      <div class="col text-end">
      <button nz-button nzType="default" type="button" (click)="resetFilter()">Clear All</button>
      <button class="ms-1" nz-button nzType="primary" type="submit" [disabled]="isFormDirty" >Apply</button>
      </div>
    </form>
  </div>





<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>
