<nz-modal
  nzWidth="80%"
  [(nzVisible)]="visibleCatalogModel"
  [nzTitle]="CatalogModelTitle"
  [nzFooter]="CatalogModelFooter"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '79vh', 'overflow-y': 'auto' }"
  [nzStyle]="{ top: '37px' }"
  (nzOnCancel)="CatalogModelhandle()">
    <ng-template #CatalogModelTitle>
      <div class="row p-0 m-0">
        <h2 class="p-0 m-0 fw-bold">
         Catalog
        </h2>
      </div>
    </ng-template>

    <div class="content content-set-background" *nzModalContent>
      <div class="ng-container">
        <div class="card-container">
          <nz-tabset nzType="card" >
            <nz-tab *ngFor="let tab of tabs" [nzTitle]="catalogTabTitle(tab)">
              <ng-container class="ant-tabs-content">
                  <!-- Your tab content here -->
                  <div *ngIf="tab === 1">
                    
                    <catalog-catalog-tab-1 [flagForword]="flagForword"></catalog-catalog-tab-1>
                  </div>
                  <div *ngIf="tab === 2">
                    <catalog-cost-group-tab-2></catalog-cost-group-tab-2>
                  </div>
              </ng-container>
            </nz-tab>
          </nz-tabset>
        </div>
      </div>
    </div>
    
    <ng-template #CatalogModelFooter>
      
    </ng-template>
</nz-modal>