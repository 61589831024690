import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css']
})
export class CatalogComponent {
  @Output() cancel = new EventEmitter<void>();
  
  closeCatal= true;
  closecatal(): void {
  this.cancel.emit();
  }

  activeTabs: string = 'costItem';

  ActiveTab(tabName: string) {
    this.activeTabs = tabName;

}

}
