import { Component, OnInit, AfterViewInit, ViewChild, TemplateRef, ViewContainerRef, Input, SimpleChanges } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { CalendarOptions } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { LeadActivitiesService } from 'src/Service/Sales-Lead-Activities/lead-activities.service';
import { LeadActivityResponse } from 'src/Models/LeadActivityTemplate/LeadActivityTemplate';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LeadOpportunitiesNewLeadOpportunitiesComponent } from '../Modal/lead-opportunities-new-lead-opportunities/lead-opportunities-new-lead-opportunities.component';
import { LeadOpportunityResponse } from 'src/Models/LeadTags';
import { LeadOpportunitiesActivityTemplatesHeaderComponent } from '../Lead-opportunities-header/lead-opportunities-activity-templates-header/lead-opportunities-activity-templates-header.component';
@Component({
  selector: 'app-activity-full-calendar',
  templateUrl: './activity-full-calendar.component.html',
  styleUrls: ['./activity-full-calendar.component.css']
})
export class ActivityFullCalendarComponent implements OnInit, AfterViewInit  {
  @Input() Testing: boolean;
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  @ViewChild('eventTemplate', { read: TemplateRef }) eventTemplate!: TemplateRef<any>;
  activities: LeadActivityResponse[] = [];
  hideDetails: boolean = false;

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    timeZone: 'UTC',
    dayMaxEvents: false,
    headerToolbar: {
      left: '',
      center: '',
      right: ''
    },
    events: [], // Start with an empty array
    eventContent: this.renderEventContent.bind(this)
  };
  constructor(
    public leadActivitiesService: LeadActivitiesService,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,)
    { 
    }
    shouldLoadActivities: boolean = true;

    ngOnInit(): void {
      this.loadActivities();
    }
    
  ngOnChanges(changes: SimpleChanges) {
    if (changes['true'] && this.Testing) {
      this.ngAfterViewInit();
    }
  }
// async ngAfterViewInit() {
//     try {
//       setTimeout(() => {
//         if(this.tesing2 === '12'){
//           this.triggerResize();
//         }
//       });
//     } catch (error) {
//       console.error('Error saving lead activity:', error);
//     } finally {
//       this.testing = {};
//       this.activities = [];
//       this.loadActivities();
//       this.triggerResize();
//     }
//   }
  ngAfterViewInit(): void {
    setTimeout(() => {
        this.triggerResize();
    });
  }
  testing: any = {};
  loadActivities(): void {
    this.leadActivitiesService.leadActivityGetData().subscribe(res => {
      this.activities = res?.result;
      this.testing = this.activities.map(activity => ({
        title: activity.leadActivityTypeName,
        leadOpportunityTitle: activity.leadOpportunityTitle,
        date: activity.activityOn,
        id: activity.id,
        backgroundColor: activity.leadActivityColorCollection,
        extendedProps: {
          Icon: activity.leadActivityTypIconSource,
          datestartTime: activity.startTime,
          dateEndTime: activity.endTime,
          title: activity.leadOpportunityTitle,
          name: activity.assignedUserName,
        }
      }));
      this.calendarOptions = {
        ...this.calendarOptions,
        events: this.testing
      };

      if (this.calendarComponent) {
        const calendarApi = this.calendarComponent.getApi();
        calendarApi.removeAllEvents();
        calendarApi.addEventSource(this.testing);
        this.triggerResize();
      }
    });
  }

  updateCalendarOptions(): void {
    if (this.calendarComponent) {
      this.calendarComponent.getApi().setOption('events', this.calendarOptions.events);
    }
  }

  onViewChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedView = selectElement.value;

    this.hideDetails = selectedView !== 'dayGridMonth';

    if (this.calendarComponent) {
      this.calendarComponent.getApi().changeView(selectedView);
      this.updateCalendarOptions();
      this.triggerResize(); // Trigger resize after view change
    }
  }

  renderEventContent(arg: any): any {
    const viewType = this.calendarComponent.getApi().view.type;
    const event = arg.event;

    const view = this.viewContainerRef.createEmbeddedView(this.eventTemplate, { event, hideDetails: this.hideDetails });
    const div = document.createElement('div');
    div.appendChild(view.rootNodes[0]);

    return { domNodes: [div] };
  }

  triggerResize(): void {
    // Trigger resize event to ensure FullCalendar correctly calculates dimensions
    if (this.calendarComponent) {
      this.calendarComponent.getApi().updateSize();
    }
  }
  selectedLead: LeadOpportunityResponse;
  num: any = {};
  Testingfor: string
  leadOpportunityFormEdit(event:LeadActivityResponse): void {
    this.num = {
      leadOpportunity: 28,
      leadAcit: event.id,
    }
    if(this.num?.leadAcit){
      const modalRef = this.modal.create({
        nzContent: LeadOpportunitiesNewLeadOpportunitiesComponent,
        nzFooter: null
      });
      modalRef.componentInstance.TestingforResponse = this.num
      modalRef.componentInstance.JobMenuButton = true;
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
        this.selectedLead = null;
      });
    } else {
      console.error(this.num);
    }
  };
  leadActivityTemplatesFormEdit() {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesActivityTemplatesHeaderComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
} 