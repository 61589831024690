<!-- <nz-modal nzWidth="30%" [(nzVisible)]="selectedLeadVisible" [nzTitle]="LeadTitle" [nzBodyStyle]="{
    background: '#FFFFFF',
    padding: '14px',
    'max-height': '540px',
    'overflow-y': 'auto'
  }" [nzStyle]="{ top: '200px' }" (nzOnCancel)="dialogBoxCancel()" [nzFooter]="null"> -->
 
    <div class="row p-0 m-0">
      <h4 class="p-0 m-0 fw-bold">Select a Lead</h4>
    </div>
    <form (ngSubmit)="onSubmit()">
      <div class="content">
        <div class="row mt-4 mb-4 ms-2 me-2">
          <div class="col-12">
            <label class="cityDropdown fw-bold">Please Select a Lead</label>
            <div class="input-group">
              <div class="input-group">
                <nz-select nzShowSearch nzAutoFocus="true" nzPlaceHolder="Select Lead" [(ngModel)]="selectedLeadId" (ngModelChange)="onLeadSelect($event)">
                  <nz-option *ngFor="let option of leadOptions" [nzLabel]="option.title" [nzValue]="option.id"></nz-option>
              </nz-select>
              </div>
              
            </div>
          </div>
        </div>
      </div>

    </form>

<!-- </nz-modal> -->