import { Component } from '@angular/core';

@Component({
  selector: 'app-tendors-content',
  templateUrl: './tendors-content.component.html',
  styleUrls: ['./tendors-content.component.css']
})
export class TendorsContentComponent {

}
