import {Component} from '@angular/core';
interface Email {
  id: number;
  email: string;
  label: string;
}




@Component({
  selector: 'app-project-management-site-diaries-checklist',
  templateUrl: './project-management-site-diaries-checklist.component.html',
  styleUrls: ['./project-management-site-diaries-checklist.component.css']
})
export class ProjectManagementSiteDiariesChecklistComponent {

  emails: Email[] = [];
  counter: number = 0;
  
  ngOnInit() {
    this.addNewRow(); // Initially, show one row
  }
  
  isAddButtonDisabled: boolean = false;
  addButtonClass: string = '';
  
  addNewRow() {
    this.counter++;
    const newEmail: Email = { id: this.counter, email: '', label: '' };
    this.emails.push(newEmail);
    this.updateButtonState();
  }
  
  deleteRow(id: number) {
    this.emails = this.emails.filter((email) => email.id !== id);
    this.updateButtonState();
  }
  
  updateButtonState() {
    // No limit on the number of rows
    this.isAddButtonDisabled = false;
    this.addButtonClass = ''; // Enable the button, remove the CSS class
  }

  isCheckboxChecked11 = true;

  onCheckboxChange11() {
    this.isCheckboxChecked11 = !this.isCheckboxChecked11;
}
}

