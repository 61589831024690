    <div *ngIf="filterResponseData && filterResponseData.length > 0">
        <form   (ngSubmit)="onFilterApply()">
            <div class="row mt-2">
              <div class="col-10 p-0">
                <div class="input-group">
                 <nz-select
                  [(ngModel)]="formFieldValues['standardFilter']"
                  name="standardFilter"
                  (ngModelChange)="onChangeStandardFilter($event)"
                  nzShowSearch
                  [nzAutoFocus]="true"
                  nzPlaceHolder="Standard Filter"
                  r>
                  <nz-option *ngFor="let filter of filterResponseData"
                   [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')"
                   [nzValue]="filter.id"
                   nzCustomContent>
                   <div class="option-content">
                    <!-- Customize content with icons and text -->
                    <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
                    <span>{{ filter.name }}</span>
                   <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

                   </div>
                  </nz-option>
                 </nz-select>
                </div>
               </div>
                <div class="col-1 mt-1">
                    <i class="bi bi-three-dots"
                    nz-popover
                    [nzPopoverContent]="contentTemplate"
                    [nzPopoverPlacement]="'top'"
                     (click)="openStandardFilterComponent()"></i>
                </div>
            </div>

            <ng-template #customtt>
                <div class="row mb-3 d-flex justify-content-between align-items-center p-0 ">
                    <div class="col p-0">
                        <strong>Filter</strong>
                    </div>
                    <div class="col p-0 text-end">
                        <strong nz-icon nzType="close" nzTheme="outline"></strong>
                    </div>
                </div>
            </ng-template>

                    <div class="row famly-inn">
                        <div class="col-12">
                            <div class="row">
                                <div class="col mt-1 p-0">
                                    <label class="sub-text">Type</label>
                                    <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['type']" name="type">
                                        <nz-option *ngFor="let option of Type" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                                    </nz-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col mt-1 p-0">
                                    <label class="sub-text">Categories</label>
                                    <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['Categories']" name="Categories">
                                        <nz-option *ngFor="let option of Category" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                                    </nz-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 p-0 text-end ">
                            <button nz-button nzType="primary">Clear All</button>
                            <button class="ms-1" nz-button nzType="default">Apply </button>
                        </div>
                    </div>

        </form>
    </div>




<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>
