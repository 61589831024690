import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BillsComponent } from '../bills/bills.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { StandardColumnSetupResponse, StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';
import { ColumnsResizePinProperties } from 'src/Models/InternalUser/internalUserColumnsPinResize';
import { AddNewGridStandardViewComponent } from 'src/app/shared/component/Modal/add-new-grid-standard-view/add-new-grid-standard-view.component';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-purchase-order-dashboard',
  templateUrl: './purchase-order-dashboard.component.html',
  styleUrls: ['./purchase-order-dashboard.component.css']
})
export class PurchaseOrderDashboardComponent implements OnInit{
  private subscriptions: Subscription[] = [];
  private TimeClockstandardViewFilterSubscription : Subscription
  private displayColumnSubscription: Subscription;
  getStandardViewFilterData: StandardViewResponse[] = [];
  selectedColNameSizePin: ColumnsResizePinProperties[] = [];
  columnSizes: { columnName: string, widthSize: number }[] = [];
  getStandardViewColumnFilterData: StandardColumnSetupResponse[] = [];
  fixedSelectedColumnNames: string[] = [];
  PAGE_ID: number = 15;
  Purchase_Order: string = FiltersName.Purchase_Order;
  selectedValueControl: any;
  tabs = [1, 2];
  activeTab: number = 0;

  isCollapsed = false;
  selectedJobId: number = 0;
  totalRecords = 100;
 currentPage = 1;
  pageSize = 10;






  constructor(private modal: NzModalService, private route: ActivatedRoute,
     private router: Router ,
     private internalUserService: InternalUserService,
     private standardViewService: StandardViewForAllService,
     public toastService: NzMessageService,
     private changeDetectorRef: ChangeDetectorRef,


      private filterService: FilterSideMenuBarService) { }



  ngOnInit(): void {
    this.PAGE_ID = 15
    // this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom);
    this.getStandardViewFilterByFormPageId(this.PAGE_ID)

    this.displayedColumns = [];
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    // Add subscriptions to the array
    this.subscriptions.push(
      this.standardViewService.$displaySelectedColApply.subscribe(colName => {
        if (colName.length > 0) {
          this.displayedColumns = colName;
          this.changeDetectorRef.detectChanges();
        }
      })
    );

    this.subscriptions.push(
      this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
        this.selectedFilter = standardType != -1 ? standardType : standardType;
      })
    );
    this.route.url.subscribe(urlSegments => {
      const path = urlSegments[0].path;
      switch (path) {
        case 'Purchase-Orders':
          this.activeTab = 0;
          break;
        case 'Bills':
          this.activeTab = 1;
          break;
      }
    });
  }



  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  innerCardTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'Purchase Orders';
      case 2:
        return 'Bills';
      default:
        return '';
    }
}






openFilter(component: string, pageId: number) {
  this.filterService.toggleFilter(component, pageId);
  console.log('component, pageId',component, pageId);

}

BillsOpen(): void {
  const modalRef = this.modal.create({
    nzContent: BillsComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });

}

PurchaseOpen(): void {
  const modalRef = this.modal.create({
    nzContent: BillsComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });

}
    // Selected Job Id From Left Side Job List
    getSelectedJobId(item: number){
      this.selectedJobId = item;
      console.log("selectedJobId : ", this.selectedJobId);
    }



    // .....

  isLoading: boolean = false;
  defaultFilterIdSelected: number = 0;
  displayedColumns: string[] = [];
  isColumnSticky: boolean[] = new Array(this.displayedColumns.length).fill(false);
  selectedFilter: number = -1;
  selected_View_Columns: string[] = [];
  getColumnWidthSize: number[] = [];




    onPageChange(page: number): void {
      this.currentPage = page;
      let filterId: number = 0;
      this.internalUserService.$selectedFilterId.subscribe(v => {
        if (v) {
          filterId = v;
        }
      });
      this.isLoading = true;
      setTimeout(() => {
        this.defaultFilterIdSelected = filterId;
        // this.RFIsUserGridTableData(1, this.PAGE_ID, filterId, page, this.pageSize);
        this.isLoading = false;

      }, 100);
    }

    onPageSizeChange(size: number): void {
      this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
      this.pageSize = size;
      let filterId: number = 0;
      this.internalUserService.$selectedFilterId.subscribe(v => {
        if (v) {
          filterId = v;
        }
      });
      this.isLoading = true;
      setTimeout(() => {
        this.defaultFilterIdSelected = filterId;
        // this.RFIsUserGridTableData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize);
        this.isLoading = false;

      }, 100);
    }





    onFilterChange(id: number) {
      this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
      this.selectedFilter = -1;
      this.displayedColumns = [];
      this.selected_View_Columns = [];
      let selectedView = this.getStandardViewFilterData.find(view => view.id === id).standardViewParameters;
      selectedView.forEach(viewItem => {
        if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
          this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
          this.displayedColumns = this.selected_View_Columns;

          const columnIndex = this.displayedColumns.indexOf(viewItem.standardColumnSetup.displayColumnName);
          if (columnIndex !== -1) {
            this.isColumnSticky[columnIndex] = viewItem.isPin;
            this.getColumnWidthSize[columnIndex] = viewItem.resizeColumnWidth;
          }
        }
      });
    }


      openStandardViewFilterComponent(isSaveAs?: string) {
    let isInputVisible: boolean = false;
    if (isSaveAs === 'saveAs') {
      isInputVisible = true;
    } else {
      isInputVisible = false;
    }
    this.selectedColNameSizePin = [];
    for (let i = 0; i < this.displayedColumns.length; i++) {
      const item = this.displayedColumns[i];
      const isPinValue = this.isColumnSticky[i];
      let widthSize = 0;
      if (this.columnSizes.length > 0) {
        for (let i = 0; i < this.columnSizes.length; i++) {
          const columnName = this.columnSizes[i].columnName;
          const columnNameSize = this.columnSizes[i].widthSize;
          if (columnName === item) {
            widthSize = columnNameSize;
            break;
          }
        }
      }

      if (widthSize === 0) {
        widthSize = 180;
      }

      this.selectedColNameSizePin.push({
        displayColumnName: item,
        resizeColumnWidth: widthSize, //  "200px", // Set default width here
        isPin: isPinValue == true ? isPinValue : false
      });
    }
    const modalRef = this.modal.create({
      nzContent: AddNewGridStandardViewComponent,
      nzData:
      {
        pageId: this.PAGE_ID,
        column: this.getStandardViewColumnFilterData,
        displayCol: this.displayedColumns,
        viewList: this.getStandardViewFilterData,
        selectedColNameSizePin: this.selectedColNameSizePin,
        selectedStandardView: this.selectedValueControl,
        fixedCol: this.fixedSelectedColumnNames,
        isSaveAs: isInputVisible
      },

    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  }

  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
  }
  getStandardViewFilterByFormPageId(pageId: number) {
    if (this.TimeClockstandardViewFilterSubscription) {
      this.TimeClockstandardViewFilterSubscription.unsubscribe();
    }
    this.displayedColumns = [];
    this.fixedSelectedColumnNames = [];
    this.standardViewService.getStandardViewFilterByFormPageId(pageId);
    this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
    // this.standardViewService.$getStandardViewFilter.subscribe((result) => {
    this.TimeClockstandardViewFilterSubscription = this.standardViewService.$getStandardViewFilter.subscribe(result => {
      if (result) {
        this.displayedColumns = [];
        this.selected_View_Columns = [];
        this.getStandardViewFilterData = result.result;
        let defaultView = this.getStandardViewFilterData.find(
          (view) => view.isSetAsDefault === true
        ).standardViewParameters;
        this.selectedValueControl = this.getStandardViewFilterData.find(
          (view) => view.isSetAsDefault === true
        ).id;
        defaultView.forEach((viewItem) => {
          if (
            viewItem.standardColumnSetup &&
            viewItem.standardColumnSetup.displayColumnName
          ) {
            this.selected_View_Columns.push(
              viewItem.standardColumnSetup.displayColumnName
            );
          }
        });
        this.displayedColumns = this.selected_View_Columns;
      }
    });

    this.standardViewService.$getStandardViewColumn.subscribe((result) => {
      if (result) {
        this.getStandardViewColumnFilterData = result.result;
        let fixedCol = this.getStandardViewColumnFilterData.filter(
          (x) => x.isSelected === true
        );
        fixedCol.forEach((x) => {
          this.fixedSelectedColumnNames.push(x.displayColumnName);
        });
      }
    });
  }


  saveEditStandardView() {
    this.standardViewService.$createStandardViewRequest.subscribe(formData => {
      if (formData && formData !== null) {
        this.standardViewService.postData(formData)
          .subscribe(
            (res: ResponseModelArray<StandardViewResponse>) => {
              setTimeout(() => {
                this.isLoading = true;
                this.toastService.success('Standard View Updated Successfully');
                this.standardViewService.getStandardViewFilterByFormPageId(this.PAGE_ID);
                this.selectedFilter = -1;
              }, 10);
            },
            (error) => {
              console.error('Error:', error);
              this.isLoading = false;
            }
          );
      }
    });
  }
}


