<div class="containers h-100" *ngIf="contentHide">
  <div class="logo mb-5">
    <img
      src="/assets/ImagesPresistant/Persistent Solution Logo png-01.png"
      class="fit-content"
      width="200px"
    />
  </div>

  <div nz-row class="p-2">
    <div nz-col nzSpan="24" class="text-center">
      <button (click)="OpenJobModal()" class="w-100" nz-button nzType="primary">
        New Job
      </button>
    </div>
  </div>

  <!-- <div nz-row class="p-2 mb-2">
      <div nz-col nzSpan="24" class="text-center">
        <button class="w-100" nz-button nzType="primary">New Template</button>
      </div>
    </div> -->

  <div nz-row class="p-2">
    <div class="col-8">
      <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
        <span class="bold">Jobs</span>
        <span nz-icon class="bold" nzType="down"></span>
      </a>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item class="bold">Jobs</li>
          <li nz-menu-item class="bold" nz-popover>Templates</li>
        </ul>
      </nz-dropdown-menu>
    </div>
    <div class="col-2">
      <a>
        <i
          class="bi bi-funnel fs-5"
          nz-popover
          nzPopoverPlacement="rightTop"
          [nzPopoverContent]="FilterTemplate"
          nzPopoverTrigger="click"
        ></i
      ></a>

      <ng-template #FilterTemplate>
        <div class="container" style="padding: 5px; margin: 5px; width: 400px">
          <div nz-row class="row mb-2">
            <div nz-col nzSpan="24">
              <span nz-typography>FILTER YOUR RESULTS</span>
            </div>
          </div>

          <div nz-row class="row mb-3">
            <div nz-col nzSpan="24">
              <label class="sub-text">Saved Filters</label>
              <nz-select></nz-select>
            </div>
          </div>

          <nz-divider></nz-divider>

          <div nz-row class="row mb-3 mt-3">
            <div nz-col nzSpan="24">
              <label class="sub-text">Groups</label>
              <nz-tree-select
                [nzNodes]="JobGroupNodes"
                [nzVirtualHeight]="'250px'"
                nzShowSearch
                nzAllowClear
                nzCheckable
                nzCheckAll="false"
              >
              </nz-tree-select>
            </div>
          </div>
          <div nz-row class="row mb-3 mt-3">
            <div nz-col nzSpan="24">
              <label class="sub-text">Status</label>
              <nz-select nzShowSearch nzAllowClear>
                <nz-option
                  *ngFor="let option of jobListStatus"
                  [nzLabel]="option.name"
                  [nzValue]="option.id"
                ></nz-option>
              </nz-select>
            </div>
          </div>
          <div nz-row class="row mb-3 mt-3">
            <div nz-col nzSpan="24">
              <label class="sub-text">Project Managers</label>
              <nz-tree-select
                [nzNodes]="projectManagerNodes"
                nzShowSearch
                nzAllowClear
                nzCheckable
                nzCheckAll="false"
                [nzVirtualHeight]="'250px'"
              ></nz-tree-select>
            </div>
          </div>

          <div nz-row class="row mb-4 mt-3">
            <div nz-col nzSpan="24">
              <nz-space>
                <button *nzSpaceItem nz-button nzType="primary">
                  Update Results
                </button>
                <button *nzSpaceItem nz-button nzType="default">
                  Resets Filters
                </button>
                <button *nzSpaceItem nz-button nzType="default">
                  Save Filter
                </button>
              </nz-space>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="col-2">
      <a>
        <span
          class="fs-5"
          nz-icon
          nzType="swap"
          nz-popover
          nzPopoverPlacement="rightTop"
          [nzPopoverContent]="ArrowTemplate"
          nzPopoverTrigger="click"
          nzTheme="outline"
        ></span>
      </a>
      <ng-template #ArrowTemplate>
        <div class="container" style="padding: 0%; margin: 0%; width: 200px">
          <nz-list class="text-start" nzSize="small">
            <nz-list-header>Sort Jobs</nz-list-header>

            <nz-list-item class="text-start"
              ><i class="bi fs-6 bi-sort-alpha-down-alt"></i
              >Alphabetically</nz-list-item
            >
            <nz-list-item class="text-start"
              ><i class="bi fs-6 bi-calendar-check"></i>Projected Start
              Date</nz-list-item
            >
            <nz-list-item class="text-start"
              ><i class="bi fs-6 bi-calendar-check"></i>Projected End
              Date</nz-list-item
            >
            <nz-list-item class="text-start"
              ><i class="bi fs-6 bi-house-door-fill"></i>Created
              Date</nz-list-item
            >
          </nz-list>
        </div>
      </ng-template>
    </div>
  </div>

  <!-- side-menu-bar.component.html -->

  <div nz-row class="p-2 mb-2">
    <div nz-col nzSpan="24" class="text-center">
      <nz-input-group [nzSuffix]="suffixIconSearch">
        <input
          type="text"
          nz-input
          nzAllowClear
          [(ngModel)]="searchText"
          (input)="filterJobList()"
          placeholder="Search job list..."
        />
      </nz-input-group>

      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search"></span>
      </ng-template>
    </div>
  </div>

  <div class="selected-job-info p-2" *ngIf="selectedItem">
    <div class="row">
      <div class="job-information-details col-10">
        <div class="row">
          <div class="col-12 mb-2" *ngIf="selectedItem?.name">
            <div class="title">
              <h2>{{ selectedItem.name }}</h2>
            </div>
          </div>
          <div class="col-12">
            <div
              *ngFor="
                let ownerParameter of selectedItem?.jobInformationOwnerParameters
              "
            >
              <!-- Check if customerInformation exists -->
              <p
                class="famly-inn mb-1"
                *ngIf="ownerParameter?.customerInformation?.displayName"
              >
                {{ ownerParameter.customerInformation.displayName }}
              </p>
            </div>

            <p class="famly-inn mb-1" *ngIf="selectedItem?.streetAddress">
              {{ selectedItem?.streetAddress }}
            </p>
            <p
              class="famly-inn mb-1"
              *ngIf="
                selectedItem?.suburb ||
                selectedItem?.state ||
                selectedItem?.postalCode
              "
            >
              <span *ngIf="selectedItem?.suburb"
                >{{ selectedItem?.suburb }},
              </span>
              <span *ngIf="selectedItem?.state"
                >{{ selectedItem?.state }},
              </span>
              <span *ngIf="selectedItem?.postalCode">{{
                selectedItem?.postalCode
              }}</span>
            </p>
            <b class="famly-inn sub-text mb-1">
              Job Running Total Cost: ${{
                selectedItem?.contractPrice ? selectedItem.contractPrice : "00"
              }}
            </b>
          </div>
        </div>
      </div>
      <div class="job-information-icons col-1" *ngIf="selectedItem?.name">
        <div class="row">
          <div class="col-12 mb-1">
            <span
              class="fs-6 bold-Icon"
              nz-popover
              [nzPopoverContent]="jobDetail"
              nzPopoverPlacement="right"
              nz-icon
              nzType="info-circle"
              nzTheme="outline"
              (click)="openJobScratchModal()"
            ></span>
          </div>
          <ng-template #jobDetail>
            <p>View Job Details</p>
          </ng-template>

          <div class="col-12 mb-1">
            <span
              class="fs-6 bold-Icon"
              nz-popover
              [nzPopoverContent]="sendMessage"
              nzPopoverPlacement="right"
              nz-icon
              nzType="mail"
              nzTheme="outline"
              (click)="openMailModelOpen()"
            ></span>
          </div>
          <ng-template #sendMessage>
            <p>Send Message to Owner</p>
          </ng-template>
          <div class="col-12 mb-1">
            <span
              class="fs-6 bold-Icon"
              nz-popover
              [nzPopoverContent]="ownerSite"
              nzPopoverPlacement="right"
              nz-icon
              nzType="home"
              nzTheme="outline"
            ></span>
          </div>
          <ng-template #ownerSite>
            <p>View Owner Site</p>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <!--Job List  -->
  <cdk-virtual-scroll-viewport
    itemSize="50"
    class="demo-infinite-container"
    style="height: 60vh; overflow-x: hidden; border: none"
  >

    <nz-list nzSize="small">

      <div
        *ngIf="
          showNoResultsMessage &&
          filteredJobList &&
          filteredJobList.length === 0
        "
      >
        <div class="no-results">
          Your search returned no results. <br />
          Adjust your search to find what you're looking for.
          <a class="text-primary" (click)="clearSearch()">Clear Search</a>
        </div>
      </div>

      <nz-list-item
        nzNoFlex
        *ngIf="filteredJobList && filteredJobList.length > 0 && isAllJobOption"
        [class.selected]="localStorageService.getJobId() === null"
        (click)="selectItem(filteredJobList)"
      >
        All <b>{{ filteredJobList?.length }}</b> Listed Jobs
      </nz-list-item>
   

      <ng-container *cdkVirtualFor="let item of filteredJobList">
       
        <nz-list-item
          *ngIf="item"
          (click)="selectItem(item)"
          [class.selected]="localStorageService.getJobId() === item.id"
        >
        <span class="d-flex justify-content-center align-items-center text-start">
          <span class="circle" [style.background-color]="
          item?.color?.colorCollection
        "></span>
         <span nz-typography nzEllipsis [innerHTML]="item.name">
            {{ item.name }}
          </span>
        </span>
        </nz-list-item>
      </ng-container>
    </nz-list>
  </cdk-virtual-scroll-viewport>
</div>


<span class="nz-icon-container-tigger">
  <button (click)="handleClick()" nzSize="small" nz-button nzType="default" class="rounded-1">
    <i class="trigger fs-6 d-flex align-items-center" [ngClass]="contentHide ? 'bi bi-chevron-left' : 'bi bi-chevron-right'"></i>
  </button>
</span>
