import { Component, EventEmitter, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-project-management-site-diaries-map',
  templateUrl: './project-management-site-diaries-map.component.html',
  styleUrls: ['./project-management-site-diaries-map.component.css']
})
export class ProjectManagementSiteDiariesMapComponent {
  @Output() cancel = new EventEmitter<void>();

  uploadfiles=true;
  mapclose(): void {
  this.cancel.emit();
  }

  
}
