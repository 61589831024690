import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobsManagementRoutingModule } from './jobs-management-routing.module';
import { SharedModule } from 'src/shared/shared.module';
import { JobsDashboardComponent } from './Job-List/jobs-dashboard/jobs-dashboard.component';
import { JobCreateFromTemplateComponent } from './Job-List/job-create-from-template/job-create-from-template.component';
import { JobCreateFromScratchComponent } from './Job-List/job-create-from-scratch/job-create-from-scratch.component';
import { JobListEmptyComponent } from './Job-List/job-list-empty/job-list-empty.component';
import { ThisWeeksAGENDAComponent } from './Jobs-Summary/this-weeks-agenda/this-weeks-agenda.component';
import { JobSummaryDbComponent } from './Jobs-Summary/job-summary-db/job-summary-db.component';
import { PASTDUEFORYOUComponent } from './Jobs-Summary/past-due-for-you/past-due-for-you.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { JobPriceSummaryComponent } from './Jobs-Job-Price-Summary/job-price-summary/job-price-summary.component';
import { JobsSummaryMapComponent } from './Jobs-Summary/jobs-summary-map/jobs-summary-map.component';
import { RECENTACTIVITYFROMYOURTEAMComponent } from './Jobs-Summary/recent-activity-from-your-team/recent-activity-from-your-team.component';
import { FormScratchTemplateComponent } from './Job-List/form-scratch-template/form-scratch-template.component';
@NgModule({
  declarations: [
    JobsDashboardComponent,
    JobCreateFromTemplateComponent,
    JobCreateFromScratchComponent,
    JobListEmptyComponent,
    JobSummaryDbComponent,
    ThisWeeksAGENDAComponent,
    PASTDUEFORYOUComponent,
    RECENTACTIVITYFROMYOURTEAMComponent,
    JobPriceSummaryComponent,
    JobsSummaryMapComponent,
    FormScratchTemplateComponent,
  ],
  imports: [
    CommonModule,
    JobsManagementRoutingModule,
    SharedModule,
    NzModalModule,
  ]
})
export class JobsManagementModule { }
