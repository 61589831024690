<nz-modal
  nzWidth="60%"
  [(nzVisible)]="scheduleVisible"
  [nzTitle]="JobCreateNewScratchModalTitle"
  [nzFooter]="scheduleModalfooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '75vh',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="CancelSchedule()"
>
  <ng-template #JobCreateNewScratchModalTitle>
    <div class="p-0 m-0">
      <div class="row ms-3 mt-2" *ngIf="leadData?.leadData?.name">
        <h5
          class="ListViewJobname p-0 m-0"
          [(ngModel)]="formFieldValues['jobInformationId']"
          name="jobInformationId"
        >
          {{ leadData?.leadData?.name }}
        </h5>
      </div>
      <div class="row ms-2 mt-1" *ngIf="JobData?.JOB_NAME">
        <h5 class="ListViewJobname p-0 m-0">{{ JobData?.JOB_NAME }}</h5>
      </div>

      <div class="row ms-2 mt-1 d-flex align-items-center">
        <h1 class="p-0 m-0 famly-inn">Schedule Item <button *ngIf="responseHold" nz-popover [nzPopoverTrigger]="'click'"  [nzPopoverContent]="popoverContents" [nzPopoverPlacement]="'bottom'"  class="hover rounded-2 ms-2" nz-button nzType="default"><span  nz-icon nzType="copy"></span></button></h1>
      </div>
    </div>

    <ng-template #popoverContents>
      <p style="height: auto;">
       
        <div class="col-12">
          <nz-input-group [nzAddOnAfter]="Copy" style="cursor: pointer;">
            <input type="text" nz-input [(ngModel)]="inputValue" id="yourInputFieldId" />
          </nz-input-group>
          
        </div>

        <ng-template #Copy>
          <div (click)="copyText()" nz-button nzType="default">
              Copy
          </div>
      </ng-template>
      
    </ng-template>
  </ng-template>
 
  <nz-content *nzModalContent>
    <div class="content">
      <div class="card-container">
        <nz-tabset nzType="card">
          <nz-tab
            *ngFor="let tab of newScheduleItem"
            [nzTitle]="newScheduleItemTabs(tab)"
          >
            <ng-container *ngIf="tab === 1">
              <div class="card border-0 m-0 p-0 rounded-0">
                <div class="card-head border-bottom">
                  <div class="row py-2">
                    <h5 class="ms-3" nz-typography>Schedule Item Details</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-9">
                          <label class="sub-text"
                            >Title
                            <span class="fw-bolder text-danger">*</span>
                          </label>
                          <input
                            [(ngModel)]="formFieldValues['title']"
                            name="title"
                            type="text"
                            class="form-control formField"
                          />
                        </div>
                        <div class="col-3">
                          <label class="sub-text">Display Color</label>
                          <div class="input-group">
                            <nz-select
                              [(ngModel)]="formFieldValues['colorId']"
                              name="colorId"
                              nzShowSearch
                            >
                              <nz-option
                                *ngFor="let option of colors"
                                [nzLabel]="option.name"
                                [nzValue]="option.id"
                              ></nz-option>
                            </nz-select>
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-11">
                          <label class="sub-text">Assignees</label>
                          <nz-tree-select
                            [nzNodes]="combinedNodes"
                            [(ngModel)]="
                              formFieldValues[
                                'createScheduleItemAssigneeRequests'
                              ]
                            "
                            name="createScheduleItemAssigneeRequests"
                            nzAllowClear="false"
                            nzCheckable
                            nzCheckAll="true"
                            (ngModelChange)="onNodeSelectionChange($event)"
                            [nzVirtualHeight]="'250px'"
                          ></nz-tree-select>
                        </div>
                        <div class="col-1">
                          <button (click)="notifyUsersOpen()" nz-popover [nzPopoverContent]="NotifyUsers" nzPopoverPlacement="bottom" class="p-0 mt-4" nz-button nzType="text" nzfocus>
                          <span ><svg width="1.5em" height="1.5em" viewBox="0 0 140 140" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class=""><path d="M132.305 1.44091C135.344 -0.0969311 138.793 2.62548 138.001 5.93871L107.661 133.056C107.026 135.716 103.968 136.969 101.649 135.518L52.8662 105.001L130.023 7.49303L36.191 94.5696L2.87894 73.7303C0.238319 72.0786 0.415076 68.1763 3.19437 66.7701L132.305 1.44091Z" fill="#26292E"></path><path d="M45.7593 138.844C44.4532 140.211 42.1548 139.17 42.3213 137.286L44.5747 111.799C44.7076 110.293 46.3985 109.473 47.6631 110.302L61.2554 119.209C62.3008 119.894 62.4688 121.36 61.605 122.264L45.7593 138.844Z" fill="#26292E"></path></svg></span>
                          </button>
                          <ng-template #NotifyUsers>
                            <p style="height: auto; width: 80px;">
                              Notify Users
                            </p>

                          </ng-template>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <label class="sub-text"
                            >Start Date<span class="fw-bolder text-danger"
                              >*</span
                            ></label
                          >
                        </div>
                        <div class="col-4">
                          <label class="sub-text"
                            >Work Days<span class="fw-bolder text-danger"
                              >*</span
                            ></label
                          >
                        </div>
                        <div class="col-4">
                          <label class="sub-text"
                            >End Date<span class="fw-bolder text-danger"
                              >*</span
                            ></label
                          >
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-4">
                          <nz-date-picker
                            [(ngModel)]="formFieldValues['StartOn']"
                            name="StartOn"
                            [nzAllowClear]="false"
                            [nzFormat]="'MMM d, YYYY'"
                            nzSuffixIcon="false"
                            [nzPlaceHolder]="' '"
                            [nzDisabledDate]="isWeekend"
                            (ngModelChange)="onStartDateChange($event)"
                          >
                          </nz-date-picker>
                        </div>
                        <div class="col-4">
                          <div class="mb-3">
                            <nz-input-group nzAddOnAfter="days">
                              <nz-input-number
                                [nzMin]="1"
                                (ngModelChange)="onWorkDaysChange($event)"
                                nz-input
                                class="formField w-100"
                                [(ngModel)]="formFieldValues['workDays']"
                                name="workDays"
                                [nzStep]="1"
                              ></nz-input-number>
                            </nz-input-group>
                          </div>
                        </div>
                        <div class="col-4">
                          <nz-date-picker
                            [(ngModel)]="formFieldValues['endOn']"
                            name="endOn"
                            [nzAllowClear]="false"
                            [nzFormat]="'MMM d, YYYY'"
                            nzSuffixIcon="false"
                            [nzPlaceHolder]="' '"
                            [nzDisabledDate]="isWeekend"
                            (ngModelChange)="onEndDateChange($event)"
                          >
                          </nz-date-picker>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="d-flex align-items-center">
                            <nz-switch
                              [(ngModel)]="formFieldValues.isHourly"
                              name="isHourly"
                              (click)="toggleFields()"
                            ></nz-switch> 
                            <a  class="ms-2 sub-text">Hourly</a>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="enableFields">
                        <div class="col-6">
                          <label class="sub-text"
                            >Start Time<span class="fw-bolder text-danger"
                              >*</span
                            ></label
                          >
                        </div>
                        <div class="col-6">
                          <label class="sub-text"
                            >End Time</label
                          >
                        </div>
                      </div>
                      <div class="row" *ngIf="enableFields">
                        <div class="col-6">
                          <nz-time-picker
                            [(ngModel)]="formFieldValues['startTime']"
                            name="startTime"
                            nzFormat="HH:mm"
                            nzPlaceHolder=" "
                          ></nz-time-picker>
                          <div *ngIf="enableFields && !formFieldValues['startTime']" class="text-danger">Start Time is required</div>
                        </div>
                        <div class="col-6">
                          <nz-time-picker
                            [(ngModel)]="formFieldValues['endTime']"
                            name="endTime"
                            nzFormat="HH:mm"
                            nzPlaceHolder=" "
                          ></nz-time-picker>
                        </div>
                      </div>
                      
                      <div class="row">
                        <div class="col-10">
                          <label class="sub-text" for="displayName"
                            >Progress</label
                          >
                        </div>
                        <div class="col-2"></div>
                      </div>
                      <div class="row">
                        <div class="col-10">
                          <nz-slider
                            [(ngModel)]="sliderValue"
                            (ngModelChange)="updateNumberFromSlider($event)"
                            name="progressPercentage"
                            [nzTooltipVisible]="'never'"
                          ></nz-slider>
                        </div>
                        <div class="col-2">
                          <div class="input-group mb-3">
                            <nz-input-group nzAddOnAfter="%">
                              <nz-input-number
                                nz-input
                                class="formField w-100"
                                [nzMin]="0"
                                [nzMax]="100"
                                [(ngModel)]="sliderValue"
                                name="sliderValue"
                                [nzStep]="1"
                              ></nz-input-number>
                            </nz-input-group>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <label class="sub-text">Reminder</label>
                          <nz-select
                            [style.width.%]="100"
                            [(ngModel)]="
                              formFieldValues['scheduleItemReminderId']
                            "
                            name="scheduleItemReminderId"
                          >
                            <nz-option
                              *ngFor="let option of scheduleItemReminder"
                              [nzLabel]="option.name"
                              [nzValue]="option.id"
                            ></nz-option>
                          </nz-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="tab === 2">
              <app-new-schedule-item-related-item [responseSchedule]="responseSchedule"></app-new-schedule-item-related-item>
            </ng-container>
          </nz-tab>
        </nz-tabset>

        <nz-tabset bset class="mt-3" nzType="card">
          <ng-container *ngFor="let tab of tabs">
            <nz-tab *ngIf="tab.show" [nzTitle]="tab.title">
              <ng-container *ngIf="tab.id === 1">
                <div class="card border-0 m-0 p-0 rounded-0">
                  <div class="card-head border-bottom">
                    <div class="row py-2">
                      <h5 class="ms-3" nz-typography>Predecessors</h5>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <div class="row gx-2" *ngIf="items?.length > 0">
                          <div class="col-6">
                            <label class="sub-text">Name</label>
                          </div>
                          <div class="col-3">
                            <label class="sub-text">Type</label>
                          </div>
                          <div class="col-2">
                            <label class="sub-text">Lag</label>
                          </div>
                          <div class="col-1"></div>
                        </div>
                        <div
                          class="row gx-2"
                          *ngFor="let item of items; let i = index"
                        >
                          <div class="col-6">
                            <nz-select
                              [disabled]="filteredScheduleData?.length === 0"
                              [(ngModel)]="
                                item.scheduleItemPredecessorParameterId
                              "
                              [nzPlaceHolder]="
                                filteredScheduleData?.length === 0
                                  ? 'There are no items to select'
                                  : ''
                              "
                              name="scheduleItemPredecessorParameterId_{{ i }}"
                            >
                              <nz-option
                                *ngFor="let option of filteredScheduleData"
                                [nzLabel]="option.title"
                                [nzValue]="option.id"
                              ></nz-option>
                            </nz-select>
                          </div>
                          <div class="col-3">
                            <nz-select
                              [style.width.%]="100"
                              [(ngModel)]="
                                item.scheduleItemPredecessorTypeSetupId
                              "
                              name="scheduleItemPredecessorTypeSetupId_{{ i }}"
                            >
                              <nz-option
                                *ngFor="let option of scheduleItemType"
                                [nzLabel]="option.name"
                                [nzValue]="option.id"
                              ></nz-option>
                            </nz-select>
                          </div>
                          <div class="col-2">
                            <div class="input-group mb-3">
                              <nz-input-group nzAddOnAfter="days">
                                <nz-input-number
                                  [(ngModel)]="item.logNumber"
                                  name="logNumber_{{ i }}"
                                  nz-input
                                  class="formField w-100"
                                  [nzMin]="0"
                                  [nzStep]="1"
                                ></nz-input-number>
                              </nz-input-group>
                            </div>
                          </div>
                          <div class="col-1">
                            <a
                              (click)="deleteRow(i)"
                              nz-button
                              [nzSize]="size"
                              nzType="link"
                              ><i class="fa-solid fa-trash-can text-primary"></i
                            ></a>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <button
                              (click)="addNewRow()"
                              nz-button
                              nzType="link"
                              class="text-primary fs-6"
                            >
                              <span
                                class="text-primary me-2 fs-5"
                                nz-icon
                                nzType="plus-circle"
                                nzTheme="fill"
                              ></span>
                              <span class="fs-6">Add Predecessor</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                
                <nz-card *ngIf="editDataResponse" nzBorderless class="card border-0 m-0 p-0 rounded-0"> <!--*ngIf="editDataResponse"!-->
                  <div class="card-head border-bottom">
                    <div class="row py-2">
                      <div class="col-6 mt-1">
                        <h5 nz-typography>Links that Follow this Schedule Item</h5>
                      </div>
                      <div class="col-6 text-end">
                        <button nz-button (click)="showConfirm()" nzType="default" class="rounded-1" [disabled]="setOfCheckedId.size === 0">Break Selected Links</button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <nz-table
                        #colSpanTable
                        [nzFrontPagination]="true"
                        [nzPaginationType]="'small'"
                        #middleTable
                        nzSize="middle"
                        [nzShowPagination]="false"
                        #nzTable
                        [nzData]="listOfData"
                        #rowSelectionTable
                        nzTableLayout="fixed"
                      >
                        <thead>
                          <tr>
                            <th nzWidth="50px"  nzLeft
                              [(nzChecked)]="checked"
                              [nzIndeterminate]="indeterminate"
                              (nzCheckedChange)="onAllChecked($event)"></th>
                            <th>Title</th>
                            <th>Type</th>
                            <th>Lag</th>
                            <th>Start</th>
                            <th>End / Deadline</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let data of nzTable.data | slice: 0: 3">
                            <tr>
                              <td nzWidth="50px"   nzLeft [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
                              <td nzEllipsis>{{ data.title }}</td>
                              <td nzEllipsis>{{ data.type }}</td>
                              <td nzEllipsis>{{ data.lag }}</td>
                              <td nzEllipsis>{{ data.start }}</td>
                              <td nzEllipsis>{{ data.end }}</td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </nz-table>
                    </div>
                  </div>
                </nz-card>
                
               
              </ng-container>
              <ng-container *ngIf="tab.id === 2">
                <nz-card class="card border-0 m-0 p-0 rounded-0">
                  <div class="card-head border-bottom">
                    <div class="row py-2">
                      <h5 class="ms-3" nz-typography>Schedule Item Phase</h5>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <div nz-row>
                          <div nz-col nzSpan="10">
                            <label class="sub-text mb-1" for="displayName"
                              >Phase
                              <span class="text-danger">*</span>
                            </label>
                            <nz-select
                              [(ngModel)]="formFieldValues['PhaseSetupId']"
                              name="PhaseSetupId"
                              style="width: 100%"
                              nzShowSearch
                              nzPlaceHolder="Unassigned"
                            >
                              <nz-option
                                *ngFor="let option of phase"
                                [nzLabel]="option.name"
                                [nzValue]="option.id"
                              ></nz-option>
                            </nz-select>
                          </div>
                          <div
                            nz-col
                            nzSpan="8"
                            style="margin-top: 30px; padding: 0px"
                          >
                            <button
                              nz-button
                              nzType="link"
                              class="rounded-1"
                              (click)="showAddNewPhase()"
                            >
                              Add
                            </button>

                            <button
                              [disabled]="!formFieldValues['PhaseSetupId']"
                              class="btn btn-sm text-primary border-0 me-1"
                              (click)="
                                EditAddNewPhase(
                                  formFieldValues['PhaseSetupId'],
                                  'PhaseSetupId'
                                )
                              "
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-head border-bottom">
                    <div class="row py-2">
                      <h5 class="ms-3" nz-typography>Schedule Item Tags</h5>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <div nz-row>
                          <div nz-col nzSpan="12">
                            <label class="sub-text">Tags</label>
                            <nz-tree-select
                              style="width: 100%"
                              [(ngModel)]="
                                formFieldValues[
                                  'createScheduleItemTagParameterRequests'
                                ]
                              "
                              name="createScheduleItemTagParameterRequests"
                              [nzNodes]="tagNodes"
                              nzShowSearch
                              nzAllowClear="false"
                              nzCheckable
                              nzCheckAll="true"
                              nzPlaceHolder="Select Status"
                              [nzVirtualHeight]="'280px'"
                            ></nz-tree-select>
                          </div>
                          <div
                            nz-col
                            nzSpan="8"
                            style="margin-top: 30px; padding: 0px"
                          >
                            <button
                              class="btn btn-sm text-primary"
                              (click)="addScheduleItemTags()"
                            >
                              Add
                            </button>

                            <button
                              [disabled]="
                                !formFieldValues[
                                  'createScheduleItemTagParameterRequests'
                                ] ||
                                formFieldValues[
                                  'createScheduleItemTagParameterRequests'
                                ].length !== 1
                              "
                              class="btn btn-sm text-primary border-0 me-1"
                              (click)="
                                editScheduleItemTags(
                                  formFieldValues[
                                    'createScheduleItemTagParameterRequests'
                                  ]
                                )
                              "
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </nz-card>
              </ng-container>
              <ng-container *ngIf="tab.id === 3">
                <div class="card border-0 m-0 p-0 rounded-0">
                  <div class="card-head border-bottom">
                    <div class="row py-2">
                      <h5 class="ms-3" nz-typography>Schedule Viewing</h5>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12">
                            <label
                              [(ngModel)]="formFieldValues['isShowOnGantt']"
                              name="isShowOnGantt"
                              nz-checkbox
                              >Show on Gantt</label
                            >
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-12">
                            <label
                              [(ngModel)]="
                                formFieldValues['isShowOwnerPhasesOnly']
                              "
                              name="isShowOwnerPhasesOnly"
                              nz-checkbox
                              >Show Owner (Full Schedule)
                              <i
                                class="bi bi-info-circle-fill text-secondary ms-2"
                                nz-popover
                                [nzPopoverContent]="showOwne"
                                nzPopoverPlacement="top"
                              ></i>
                              <ng-template #showOwne>
                                <div class="rounded-2">
                                  <p style="width: 210px; font-size: 13px">
                                    Items with "Show Owner" unchecked will not
                                    be visible to the owner regardless of owner
                                    schedule settings.
                                  </p>
                                </div>
                              </ng-template>
                            </label>
                          </div>
                        </div>
                        <div class="row gx-0 mt-3">
                          <div class="col-12">
                            <label class="sub-text">Subs/Vendors</label>
                            <nz-tree-select
                              [(ngModel)]="
                                formFieldValues[
                                  'createScheduleItemSubVendorParameterRequests'
                                ]
                              "
                              name="createScheduleItemSubVendorParameterRequests"
                              style="width: 100%"
                              [nzNodes]="subVendorData"
                              nzShowSearch
                              nzAllowClear="false"
                              nzCheckable
                              nzCheckAll="true"
                              [nzVirtualHeight]="'250px'"
                            ></nz-tree-select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div class="row gx-0 mt-3">
                              <div class="ListViewJobname">
                                <!-- All Assigned Subs/Vendors have been granted
                                  viewing access and will receive notifications on
                                  this schedule item. -->
                                All Assigned Subs/Vendors have been granted
                                viewing access and will receive notifications on
                                this schedule item.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="tab.id === 4">
                <div class="card border-0 m-0 p-0 rounded-0">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <nz-tabset nzType="card" class="border">
                          <nz-tab
                            class="colorAssign border"
                            *ngFor="let tab of viewtabs"
                            [nzTitle]="innerCardTabs(tab)"
                            style="background-color: #f1f4fa !important;"
                          >
                            <ng-container *ngIf="tab === 1">
                              <div class="row p-2">
                                <div class="col-12">
                                  <textarea
                                    [(ngModel)]="formFieldValues['allNotes']"
                                    name="allNotes"
                                    style="border-radius: 5px"
                                    class="formField"
                                    nz-input
                                  ></textarea>
                                </div>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="tab === 2">
                              <div class="row p-2">
                                <div class="col-12">
                                  <textarea
                                    [(ngModel)]="
                                      formFieldValues['internalNotes']
                                    "
                                    name="internalNotes"
                                    style="border-radius: 5px"
                                    class="formField"
                                    nz-input
                                  ></textarea>
                                </div>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="tab === 3">
                              <div class="row p-2">
                                <div class="col-12">
                                  <textarea
                                    [(ngModel)]="formFieldValues['subNotes']"
                                    name="subNotes"
                                    style="border-radius: 5px"
                                    class="formField"
                                    nz-input
                                  ></textarea>
                                </div>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="tab === 4">
                              <div class="row p-2">
                                <div class="col-12">
                                  <textarea
                                    [(ngModel)]="formFieldValues['ownerNotes']"
                                    name="ownerNotes"
                                    style="border-radius: 5px"
                                    class="formField"
                                    nz-input
                                  ></textarea>
                                </div>
                              </div>
                            </ng-container>
                          </nz-tab>
                        </nz-tabset>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="tab.id === 5">
                <div class="card border-0 m-0 p-0 rounded-0">
                  <div class="card-head border-bottom">
                    <div class="row py-2">
                      <h5 class="ms-3" nz-typography>Predecessors</h5>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <button
                          (click)="UploadFiles()"
                          nz-button
                          nzType="default"
                          class="rounded-1 me-2"
                        >
                          Add
                        </button>
                        <button
                          (click)="CreateNewFile()"
                          nz-button
                          nzType="default"
                          class="rounded-1 me-2"
                        >
                          Create New File
                        </button>
                        <button
                          nz-button
                          (click)="viewAll()"
                          *ngIf="selectedFiles.length > 0"
                          nz-button
                          nzType="default"
                          class="rounded-1 me-2"
                        >
                          View All ({{ selectedFiles.length }})
                        </button>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div
                        class="col-1 filUpload me-3"
                        *ngFor="let file of selectedFiles"
                      >
                        <div class="row gx-0 mt-2 p-0 m-0">
                          <div class="col-12 mt-2">
                            <span
                              class="m-0 p-0d d-flex align-items-center justify-content-center"
                              style="border-radius: 6px; object-fit: cover"
                              nz-icon
                              nzType="file-text"
                              nzTheme="outline"
                            ></span>
                          </div>
                        </div>
                        <div class="row p-0 m-0">
                          <div class="col-12">
                            <label class="ListViewJobname">{{
                              file.name
                            }}</label>
                          </div>
                        </div>
                        <div class="row p-0 m-0">
                          <div class="col-12">
                            <i
                              nzTrigger="click"
                              nzType="down"
                              nz-dropdown
                              [nzDropdownMenu]="menu4"
                              class="fa-solid fa-chevron-down fw-bolder"
                            ></i>
                            <nz-dropdown-menu #menu4="nzDropdownMenu">
                              <ul nz-menu>
                                <li nz-menu-item>
                                  <i class="fa-solid fa-pen me-2"></i>Edit
                                  Online
                                </li>
                                <li (click)="removeFile(file)" nz-menu-item>
                                  <i class="fa-regular fa-trash-can me-2"></i
                                  >Delete
                                </li>
                              </ul>
                            </nz-dropdown-menu>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="tab.id === 6">
                <nz-card class="card border-0 m-0 p-0 rounded-0">
                  <div class="card-head border-bottom">
                    <div class="row py-2">
                      <h5 class="ms-3" nz-typography>Baseline</h5>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="activity-table-container">
                      <nz-table
                        [nzFrontPagination]="true"
                        [nzPaginationType]="'small'"
                        #smallTable
                        nzSize="small"
                        [nzShowPagination]="true"
                        #nzTable
                        [nzData]=""
                        nzTableLayout="fixed"
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Duration</th>
                            <th>Total Variance</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container
                            *ngFor="let data of nzTable.data | slice : 0 : 3"
                          >
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </nz-table>
                    </div>
                  </div>

                  <div class="card-body">
                    <nz-radio-group>
                      <label nz-radio-button>All</label>
                      <label nz-radio-button>Direct</label>
                      <label nz-radio-button>Indirect</label>
                    </nz-radio-group>

                    <div class="activity-table-container">
                      <nz-table
                        [nzFrontPagination]="true"
                        [nzPaginationType]="'small'"
                        #smallTable
                        nzSize="small"
                        [nzShowPagination]="true"
                        #nzTable
                        [nzData]=""
                        nzTableLayout="fixed"
                      >
                        <thead>
                          <tr>
                            <th>User</th>
                            <th>(Base) Start Date</th>
                            <th>(Base) End Date</th>
                            <th>Slip (Direct)</th>
                            <th>Source</th>
                            <th>Reason</th>
                            <th>Notes</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container
                            *ngFor="let data of nzTable.data | slice : 0 : 3"
                          >
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </nz-table>
                    </div>
                  </div>
                </nz-card>
              </ng-container>
              <ng-container *ngIf="tab.id === 7">

                  <!-- sufyan work -->
                  <ng-container *ngIf="responseHold !== -1">
                    <messaging-common
                    [Job_ID]="jobID"
                    [Form_Name_ID]="formNameID"
                    [Message_header_ID]="messagingId"
                    [Message_headerSet_ID]="messagingIdRow"
                    >
                    </messaging-common>
                  </ng-container>
                  <!-- sufyan work -->
                <!-- sufyan work -->

                <div class="card border-0 mt-0">
                  <div class="card-head border-bottom">
                    <div class="row py-2">
                      <div class="col-6">
                        <h5 nz-typography><span class="ms-3">RFIs</span></h5>
                      </div>
                      <div class="col-6 text-end">
                        <button
                          (click)="rfisOpen(responseHold,8)"
                          nz-button
                          nzType="default"
                          class="rounded-1 me-2"
                        >
                          Create RFI
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body"></div>
                </div>
              </ng-container>

              
              <ng-container *ngIf="tab.id === 8">
                <div
                  class="card border-0 m-0 p-0 rounded-0"
                  nzBorderless="true"
                >
                  <div class="card-head border-bottom">
                    <div class="row py-2">
                      <h5 class="ms-3" nz-typography>Confirmation</h5>
                    </div>
                  </div>
                  <div class="card-body" nz-borderless="true">
                    <div class="row">
                      <div class="col-2">
                        <h4>
                          <span class="badge rounded-pill text-dark rounded-10"
                            >0</span
                          >
                          Confirmed
                        </h4>
                      </div>
                      <div class="col-2">
                        <h4>
                          <span class="badge rounded-pill text-dark rounded-10">
                            0
                          </span>
                          Declined
                        </h4>
                      </div>
                      <div class="col-3">
                        <h4>
                          <span class="badge rounded-pill text-dark rounded-10"
                            >0
                          </span>
                          Pending Requests
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </nz-tab>
          </ng-container>
        </nz-tabset>
      </div>
    </div>
  </nz-content>
  <ng-template #scheduleModalfooter>
    <div>
      <button nz-button nzType="default" class="rounded-1 me-2">Delete</button>
      <button nz-button nzType="primary" class="rounded-1 me-2">
        Save & New
      </button>
      <button
      nz-button
      nzType="primary"
      class="rounded-1 me-2"
      (click)="saveButton()"
      [disabled]="
        !formFieldValues.title ||
        !formFieldValues.StartOn ||
        !formFieldValues.workDays ||
        !formFieldValues.endOn ||
        (enableFields && !formFieldValues.startTime) 
      "
    >
      Save
    </button>
      <button  *ngIf="responseHold > 0" nz-button nzType="primary" class="rounded-1"  (click)="createMessage()">Update</button>
    </div>
  </ng-template>
</nz-modal>

<nz-modal
  nzWidth="38%"
  [(nzVisible)]="PhaseDetails"
  [nzTitle]="PhaseDetailsTitle"
  [nzFooter]="PhaseDetailsFooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '75vh',
    'overflow-x': 'hidden'
  }"
  [nzStyle]="{ top: '20px' }"
  (nzOnCancel)="AddNewPhasecancel()"
>
  <ng-template #PhaseDetailsTitle>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0">Add New Phase</h1>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <div class="card border-0 mt-0">
      <div class="card-head border-bottom">
        <div class="row">
          <h5 class="ms-3" nz-typography>Phase Details</h5>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <label class="sub-text">Phase Name</label>
            <!-- <input type="text" formControlName="name" name="name" class="form-control formField"/> -->
            <input
              [(ngModel)]="PhaseDetailsFieldValues['name']"
              name="name"
              type="text"
              class="form-control formField"
            />
            <!-- <div *ngIf="TagsForm.get('name').hasError('required') && TagsForm.get('name').touched" class="text-danger p-error">Required</div> -->
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-6">
            <label class="sub-text">Display Order</label>
            <nz-input-number
              [(ngModel)]="PhaseDetailsFieldValues['displayOrder']"
              name="displayOrder"
            ></nz-input-number>
          </div>
        </div> -->
        <div class="row">
          <div class="col-6">
            <label class="sub-text"
              >Phase Color
              <i
                class="bi bi-info-circle-fill text-secondary ms-2"
                nz-popover
                [nzPopoverContent]="phaseColor"
                nzPopoverPlacement="left"
              ></i>
              <ng-template #phaseColor>
                <div class="rounded-2">
                  <p style="width: 210px; font-size: 13px">
                    Selecting a default color for a particular phase determines
                    how that phase will display on the schedule
                  </p>
                </div>
              </ng-template>
            </label>
            <nz-select
              [(ngModel)]="PhaseDetailsFieldValues['colorId']"
              name="colorId"
              nzShowSearch
              [lang]="true"
              nzAutoFocus="true"
              nzPlaceHolder="--None--"
            >
              <nz-option
                *ngFor="let option of colors"
                [nzLabel]="option.name"
                [nzValue]="option.id"
              ></nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #PhaseDetailsFooter>
    <button
      nz-button
      nzType="default"
      class="rounded-1 me-0"
      (click)="phaseDataDelete()"
      *ngIf="isPhaseDetailsLoadingEdit"
    >
      Delete
    </button>
    <button nz-button nzType="primary" class="rounded-1 me-0">
      Save & New
    </button>
    <button
      nz-button
      nzType="primary"
      class="rounded-1 me-0"
      (click)="savePhaseDetails()"
    >
      {{ isPhaseDetailsLoadingEdit ? "Update" : "Save" }}
    </button>
  </ng-template>
</nz-modal>
<!-- Tag Model  -->

<!-- <nz-modal
  nzWidth="35%"
  [(nzVisible)]="jobGroupVisible"
  [nzTitle]="warrantyModalTitle"
  [nzFooter]="warrantyModalTitle1"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '75vh',
    'overflow-x': 'hidden'
  }"
  [nzStyle]="{ top: '20px' }"
  (nzOnCancel)="secondModelcancel()"
>
  <ng-template #warrantyModalTitle>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0">Add Schedule Item Tag</h1>
    </div>
  </ng-template>
  <form [formGroup]="TagsForm">
    <div class="content" *nzModalContent>
      <div class="card border-0 mt-0">
        <div class="card-head border-bottom">
          <div class="row py-2">
            <h5 class="ms-3" nz-typography>Add Schedule Item Tag</h5>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <label class="sub-text"
                >Title<span class="fw-bolder text-danger">*</span>
              </label>
              <input
                id="inputField"
                formControlName="name"
                type="text"
                class="form-control formField"
                [ngClass]="{
                  error:
                    TagsForm.get('name')?.invalid &&
                    TagsForm.get('name')?.touched
                }"
              />
              <div
                *ngIf="
                  TagsForm.get('name').hasError('required') &&
                  TagsForm.get('name').touched
                "
                class="text-danger p-error"
              >
                Required
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #warrantyModalTitle1>
      <button
        *ngIf="isLoadingEdit"
        nz-button
        nzType="default"
        class="rounded-1 me-0"
        (click)="ConfirmDeleteTags()"
      >
        Delete
      </button>

      <button
        nz-button
        nzType="primary"
        class="rounded-1 me-0"
        (click)="SaveOrUpdateData()"
      >
        {{ isLoadingEdit ? "Update" : "Save" }}
      </button>
    </ng-template>
  </form>
</nz-modal> -->
