import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import { CustomerInformationResponse } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { LeadOpportunityResponse } from 'src/Models/LeadTags';
import { LeadOpportunitiesNewLeadOpportunitiesComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunities-new-lead-opportunities/lead-opportunities-new-lead-opportunities.component';




@Component({
  selector: 'customer-lead-opportunities',
  templateUrl: './customer-lead-opportunities.component.html',
  styleUrls: ['./customer-lead-opportunities.component.css']
})
export class CustomerLeadOpportunitiesComponent implements OnInit {
  @Input() customerResponseData: any;
  @Input() lead: LeadOpportunityResponse;

  constructor(private modal: NzModalService,
    private msg: NzMessageService,
  ) { }
  ngOnInit(): void {
  }




  leadOpportunityNewLeadModalOpen(data: CustomerInformationResponse): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesNewLeadOpportunitiesComponent,
      nzFooter: null
    });
    modalRef.componentInstance.leadOpportunityResponse = data; // Pass the data directly to the component
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();

    });






  }





}
