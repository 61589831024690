<!-- Saim Start work || 4/16/2024 || Saim_Raza_Warranty_Form-->

<nz-modal nzWidth="70%" [(nzVisible)]="Taghandle" [nzTitle]="NewDaily1" [nzFooter]="CWarranty" (nzOnCancel)="TagClose()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '68vh', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
    <ng-template #NewDaily1>
        <div class="row p-0 m-0">
            <h3 class="p-0 m-0" nz-typography>{{ formName }}</h3>

        </div>
    </ng-template>
    <div class="content" *nzModalContent>
        <nz-card nzTitle="Appointment Scheduling">
            <div nz-row>
                <div nz-col nzSpan="12">
                    <label class="sub-text">Assigned to <span class="text-danger">*</span></label>
                    <nz-select [(ngModel)]="appointmentData['assignToUserId']" name="assignToUserId" style="width: 100%"
                        nzShowSearch>
                        <nz-option *ngFor="let option of serviceCoordinator" [nzLabel]="option.fullName"
                            [nzValue]="option.id"></nz-option></nz-select>
                </div>


            </div>
            <div class="row">
                <label class="sub-text">Service on <span class="text-danger">*</span></label>
                <div nz-col nzSpan="12 mb-2">
                    <nz-date-picker nzPlaceHolder=" " [(ngModel)]="appointmentData['serviceOn']" name="serviceOn"
                        nzSuffixIcon="false" (ngModelChange)="updateCheckboxState($event)"></nz-date-picker>
                </div>
            </div>
            <div class="row mb-2">
                <label class="sub-text">Start Time </label>
                <div nz-col nzSpan="12">
                    <nz-time-picker nzFormat="HH:mm" [(ngModel)]="appointmentData['startTime']" name="startTime"
                        nzPlaceHolder=" "></nz-time-picker>
                </div>
            </div>

            <div class="row">
                <label class="sub-text">End Time <span class="text-danger">*</span></label>
                <div nz-col nzSpan="12 mb-2">
                    <nz-time-picker nzFormat="HH:mm" [(ngModel)]="appointmentData['endTime']" name="endTime"
                        nzPlaceHolder=" "></nz-time-picker>
                </div>
            </div>
        </nz-card>

        <nz-card nzTitle="">
            <div class="row">
                <div class="col-12"><label class="sub-text">Internal Appointment Notes</label></div>

            </div>
            <div class="row">
                <div class="col-12">
                    <textarea style="border-radius: 5px;" rows="2" class="formField"
                        [(ngModel)]="appointmentData['internalNotes']" name="internalNotes" nz-input></textarea>
                </div>
            </div>
        </nz-card>
        <nz-card nzBorderless="true" class="mt-2 rounded-1" [(ngModel)]="appointmentData['attachmentId']"
            name="attachmentId" [nzTitle]="'Attachments'" [nzExtra]="extraTemplate">
            <ng-template #Attachments>
                <h4 class="fw-medium famly-inn">Attachments</h4>
            </ng-template>
            <div class="d-flex align-items-center mt-2">
            
            </div>
            <div class="row">
                <div class="col-5">
                    <button (click)="UploadFiles()" nz-button nzType="default" class="rounded-1 me-2">Add</button>
                    <button (click)="CreateNew()" nz-button nzType="default" class="rounded-1 me-2">Create New
                        File</button>
                    <button (click)="viewAll()" *ngIf="selectedFiles.length > 0" nz-button nzType="default"
                        class="rounded-1 me-2">View All ({{ selectedFiles.length }})</button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-1 filUpload me-3" *ngFor="let file of selectedFiles">
                    <div class="row gx-0 mt-2 p-0 m-0">
                        <div class="col-12 mt-2">
                            <span class="m-0 p-0d d-flex align-items-center justify-content-center"
                                style="border-radius: 6px; object-fit: cover;" nz-icon nzType="file-text"
                                nzTheme="outline"></span>
                        </div>
                    </div>
                    <div class="row p-0 m-0">
                        <div class="col-12">
                            <label class="ListViewJobname">{{file.name}}</label>
                        </div>
                    </div>
                    <div class="row p-0 m-0">
                        <div class="col-12">
                            <i nzTrigger="click" nzType="down" nz-dropdown [nzDropdownMenu]="menu4"
                                class="fa-solid fa-chevron-down fw-bolder"></i>
                            <nz-dropdown-menu #menu4="nzDropdownMenu">
                                <ul nz-menu>
                                    <li nz-menu-item><i class="fa-solid fa-pen me-2"></i>Edit Online</li>
                                    <li (click)="removeFile(file)" nz-menu-item><i
                                            class="fa-regular fa-trash-can me-2"></i>Delete</li>
                                </ul>
                            </nz-dropdown-menu>
                        </div>
                    </div>
                </div>
            </div>
        </nz-card>

        <nz-card nzTitle="Appointment Status" class="mt-3" *ngIf="warrantyResponse || responseHeaderId > 0 ||!isformSave " >
            <!-- <div class="row" *ngIf="!isformSaved" > -->
                <div class="row" *ngIf="savedResponseData">
                    <div class="col-2" *ngIf="WarrantyResponse" >
                        <p class="sub-text">Owner</p>
                    </div>
                    <div class="col-2">
                        <td>
                            <i class="bi bi-house-door"></i>
                            <span class="fw-bold text-secondary"> Never Accepted </span>
                            <p class="fw-bold">
                             
                            </td>
                    </div>
                </div>
           
                <div class="row" *ngIf="savedResponseData">
                    <div class="col-2">
                        <p class="sub-text">Builder</p>
                    </div>
                    <div class="col-5">
                        <p>
                            <i class="bi bi-people"></i> 
                            <span class="fw-bold" style="color: #52A300" [(ngModel)]="appointmentData['isBuilderAccepted']"
                            name="isBuilderAccepted"> Internally Accepted</span> -
                            {{ savedResponseData?.serviceOn | date: 'MMM d, yyyy'   }} ,{{ savedResponseData?.serviceOn | date: 'h:mm a' }}

                            <!-- Example of using a custom function to format time -->
                            

                          </p>
                    </div>
                </div>



            <!-- </div> -->
            <div class="row" *ngIf="!savedResponseData">
                <div class="col">
                    <label nz-checkbox [(ngModel)]="checked2" *ngIf=" !isformSaved ">Submit this appointment to OWNER for
                        acceptance? <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover
                            [nzPopoverContent]="Daiyly" nzPopoverPlacement="top"></i>
                        <ng-template #Daiyly>
                            <div class="rounded-2">
                                <p style="width: 210px; font-size: 13px;">

                                    Checking this box will notify the Owner of this appointment time. They will be
                                    prompted to accept or decline it in Buildertrend.
                                </p>
                            </div>
                        </ng-template>
                    </label>
                </div>
            </div>

        </nz-card>
        <ng-template #extraTemplate>

        </ng-template>

        <nz-card nzTitle="Final Work Approval" class="mt-3">
            <div class="row">
                <div class="col-12">
                    <label nz-checkbox [(ngModel)]="isCheckClicked" (click)="checkBoxToggle()" (click)="isDateAfterCurrent()" [nzDisabled]="!isSaveButtonClicked"
                       *ngIf="responseHeaderId > 0 " name="isBuilderOverride"
                       >Builder Override</label>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label class="sub-text">Owner Feedback <i class="bi bi-info-circle-fill text-secondary" nz-popover
                            [nzPopoverContent]="contentTemplate" nzPopoverPlacement="right"></i>
                        <ng-template #contentTemplate>
                            <div class="rounded-2">
                                <p style="height: auto; width: 180px;">
                                    Feedback will be available after the appointment date
                                </p>
                            </div>
                        </ng-template>
                    </label>

                    <nz-select [nzDisabled]="!isSaveButtonClicked|| isFormSaved3 || !isCheckClicked"
                        [(ngModel)]="appointmentData['ownerFeedbackId']" name="ownerFeedbackId">
                        <nz-option *ngFor="let option of ownerFeedbackOption" [nzLabel]="option.name"
                            [nzValue]="option.id"></nz-option>
                    </nz-select>

                </div>
            </div>
 

            <div nz-row class="mt-3"  *ngIf="!dateFlag">
                <div nz-col nzSpan="3" class="pe-2">
                    <label class="sub-text">Completion Date </label>
                    <nz-date-picker [nzDisabled]="isFormSaved3 || isCheckClicked"
                        [(ngModel)]="appointmentData['completionOn']" name="completionOn" nzPlaceHolder=" "
                        nzSuffixIcon="false"></nz-date-picker>
                </div>
                <div nz-col nzSpan="3" class="ps-2">
                    <label class="sub-text">Time </label>
                    <nz-time-picker [nzDisabled]="isFormSaved3 || isCheckClicked"
                        [(ngModel)]="appointmentData['completionTime']" name="completionTime" nzFormat="HH:mm"
                        nzPlaceHolder=" "></nz-time-picker>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="sub-text">Appointment Notes</label>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <textarea style="border-radius: 5px;" [disabled]="isFormSaved3 || !isCheckClicked" rows="2"
                        class="formField" nz-input [(ngModel)]="appointmentData['appointmentNotes']"
                        name="appointmentNotes"></textarea>
                </div>
            </div>
            <div nz-row>
                <div nz-col nzSpan="12">
                    <label class="sub-text">Feedback Left on</label>
                </div>
            </div>
            <div nz-row>
                <div nz-col nzSpan="12">
                    <!-- Display "N/A" if isfeedbackData is false or savedResponseData is not available -->
                    <span *ngIf="!isfeedbackData || !savedResponseData">N/A</span>
                    
                    <!-- Display formatted date and time if isfeedbackData is true and savedResponseData is available -->
                    <span *ngIf="isfeedbackData && savedResponseData">
                      {{ savedResponseData.serviceOn | date: 'MMM d, yyyy' }}, {{ savedResponseData.serviceOn | date: 'h:mm a' }}
                    </span>
                  </div>
                  
            </div>
        </nz-card>



    </div>
    <ng-template #CWarranty>
        <button nz-button nzType="primary" class="rounded-1 me-0"  
          >Delete</button>
        <button nz-button nzType="primary" class="rounded-1 me-0" [disabled]="!appointmentData['assignToUserId']"
            (click)="saveWarrantyForm()">Save</button> <button nz-button nzType="primary" class="rounded-1 me-0">Save &
            Close</button>
    </ng-template>
</nz-modal>

<!-- Saim End work || 4/16/2024 || Saim_Raza_Warranty_Form-->