<nz-layout>
  <nz-sider class="border" nzCollapsible [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed" [nzWidth]="280"
    [nzTrigger]="null">
    <div class="row">
      <app-side-menu-bar [isAllJobOption]="true"
        (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"></app-side-menu-bar>
    </div>
  </nz-sider>

  <nz-layout>
    <nz-page-header >
      <nz-page-header-title>
        <nz-breadcrumb>
          <nz-breadcrumb-item>{{localStorage.getJobName()}}</nz-breadcrumb-item>
        </nz-breadcrumb>
        <h1 nz-typography>
          Site Diaries
        </h1>
      </nz-page-header-title>

      <nz-page-header-extra>
        <nz-space>
          <button *nzSpaceItem nz-popover
            [nzPopoverContent]="'Daily Log Settings'"
            nzPopoverPlacement="left"
            (click)="DailyLogSetting()"
            nz-button
            nzType="default">
            <span nz-icon nzType="setting" nzTheme="outline"></span>
          </button>

          <!-- Vertical Divider -->
          <nz-divider nzType="vertical" *nzSpaceItem class="custom-divider"></nz-divider>

          <button nz-button nzType="default" *nzSpaceItem>
            <i class="fas fa-graduation-cap"></i>&nbsp;&nbsp;
            Learn More
          </button>

          <button nz-button nzType="default" *nzSpaceItem>
            Print
          </button>

          <button *nzSpaceItem
            nz-button
            nzType="default"
            (click)="openFilter(PROJECT_MANG_SITE_DIAR_DAILY_L, PAGE_ID)">
            <i class="bi bi-funnel"></i>&nbsp;&nbsp;
            Filter
          </button>

          <button *nzSpaceItem
            nz-button
            nzType="primary"
            (click)="newDailyLogModelOpen('SiteDiaries')">
            New Daily Log
          </button>
        </nz-space>
      </nz-page-header-extra>
    </nz-page-header>

    <!-- <div class="container-fuild mb-4 m-3">
    <div class="ant-row div-set" style="row-gap: 0px;" *ngIf="selectionResponse && selectionResponse.length">
        <div class="ant-col ant-col-xs-24 ant-col-md-18" *ngFor="let data of selectionResponse">

            <h2 nz-typography class="text-sites">{{ data?.date }}</h2>
            <h2 nz-typography class="text-sites-name">{{ data?.title }}</h2>
             <div class="ListViewJobname mt-2">{{ data?.notes }}</div>
        </div>
        <div class="ant-col text-right ant-col-xs-24 ant-col-md-6 text-end">
             <div class="ListViewJobname mt-2  text-end"><img nz-popover [nzPopoverContent]="Summary" nzPopoverPlacement="top" class="me-2" src="assets/ProjectManagerSitesDiaries/map-marker-default.svg" alt="Open in Maps" style="height: 18px; width: 16px;">Created by Muhammad Yousuf</div>
             <ng-template #Summary>
              <div class="rounded-2">
                <p style="font-size: 13px;">
                  Click to view details
                </p>
              </div>
            </ng-template>
             <div class="ViewableBy">
                <span class="padding-right-xs"><span style="font-size: 12px; color: rgb(78, 85, 95);">Viewable by</span></span>
                <img class="ViewableByIcon" src="assets/ProjectManagerSitesDiaries/ownerIconDisabled.png" alt="ownerIconDisabled">
                <img class="ViewableByIcon" src="assets/ProjectManagerSitesDiaries/subIconDisabled.png" alt="subIconDisabled">
                <img class="ViewableByIcon" src="assets/ProjectManagerSitesDiaries/userIcon.png" alt="userIcon">
            </div>
        </div>
        <div class="ant-col ant-col-xs-24 ant-col-md-18 mt-2">
            <button (click)="CommentModelOpen()" nz-button nzType="link" class="text-primary fs-6"><i
                class="bi bi-chat-dots-fill text-primary me-2 fs-5"></i><span class="fs-6">Add Comment</span></button>
                <button [nzTrigger]="'click'" nz-button class="text-primary border-0 me-2 ms-3 mt-2" nz-dropdown [nzDropdownMenu]="menu4">
                    <span class="text-primary">1 Related To-Do</span>
                    <span nz-icon nzType="down"></span>
              </button>
                  <nz-dropdown-menu #menu4="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item>menu4 1st menu item</li>
                      <li nz-menu-item>menu4 2nd menu item</li>
                      <li nz-menu-item>menu4 3rd menu item</li>
                    </ul>
                  </nz-dropdown-menu>
        </div>
        <div class="ant-col text-right ant-col-xs-24 ant-col-md-6">
        </div>
    </div>




    </div> -->

    <nz-content class="custom-content">

      <ng-container *ngIf="selectionResponse?.length > 0">
        <div class="container">
        <nz-card nzBordered class="border">


        </nz-card>
        </div>

      </ng-container>

      <div class="full-screen mt-4" *ngIf="selectionResponse?.length === 0">
        <div class="content-center text-center ">
          <i class="size" nz-icon nzType="file-done" nzTheme="outline"></i>
          <div class="mt-2">
            <h1 nz-typography>Track project progress with daily </h1>
          </div>
          <div><h1 nz-typography>
              logs
            </h1> </div>
          <div class="mt-2">
            <p>Document and share project updates with your team, subs, and homeowners.</p>
          </div>
          <div class="mt-2">
            <button nz-button nzType="default" class="ms-2">
              <i class="fas fa-graduation-cap"></i>&nbsp;&nbsp;Learn
              More</button>&nbsp;
            <button (click)="newDailyLogModelOpen('SiteDiaries')" nz-button
              nzType="primary" class="ms-2">New
              Add A Daily Log</button>

          </div>
        </div>
      </div>

    </nz-content>
  </nz-layout>

</nz-layout>
