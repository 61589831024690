import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { ApplicationGroupResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { ModalServiceService } from 'src/Service/Internaluser/modal-service.service';
import { RoleTypeService } from 'src/Service/Internaluser/role-type.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';

@Component({
  selector: 'app-invite-internal-user',
  templateUrl: './invite-internal-user.component.html',
  styleUrls: ['./invite-internal-user.component.css'],
})
export class InviteInternalUserComponent implements OnInit {
  roles: { id: number, name: string }[] = [];
  isVisible = false;
  private subscription: Subscription;
  constructor(
    private loadingIndicatoreService: LoadingIndicatorService,
    public toastService: NzMessageService,
    private modalService: ModalServiceService,
    private RoleService: RoleTypeService,
  ) {
    this.subscription = this.modalService.showModal$.subscribe(() => {
      console.log('showModal$ triggered');
      this.showModal();
    });
    
  }
  ngOnInit(): void {
   
  }
  
  showModal(): void {
    this.loadingIndicatoreService.show();
    setTimeout(() => {
      this.loadingIndicatoreService.hide();
      this.isVisible = true;
    }, 1000); // Adjust the timeout as needed
  }

  handleCancel(): void {
    this.loadingIndicatoreService.show();
    setTimeout(() => {
      this.loadingIndicatoreService.hide();
      this.isVisible = false;
    }, 1000); // Adjust the timeout as needed
  }

  
RoleGetData() {
  this.RoleService.getData().subscribe(
    (res: ResponseModelArray<ApplicationGroupResponse>) => {
      this.roles = res.result || [];
      this.roles = res.result.map((role: any) => ({ id: role.id, name: role.name }));
    },
    (error) => {
      console.error('Error fetching roles:', error);
    }
  );
}


formItems: any[] = [{}]; // Initial row

  addRow() {
    this.formItems.push({});
  }

  removeRow(index: number) {
    this.formItems.splice(index, 1);
  }

}
