import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, throwError } from 'rxjs';
import { CompanyParameter } from 'src/Models/All-Filters/financialBillsFilterField';
import { ChangeOrderInformationResponse, CreateMessagingRequest } from 'src/Models/LeadTags';
import { CreateWarrantyInformationRequest, CreateWarrantyServiceRequest, Warranty, WarrantyClaim, WarrantyInformation, WarrantyInformationFordashbord, WarrantyInformationResponse, WarrantyInformations, WarrantyServiceResponse } from 'src/Models/Warranty/warranty';
import { ResponseModel, ResponseModelArray, TablePageResponse } from 'src/Models/responseMessage.model';
import { CreateWarrantySetupRequest, WarrantySetupResponse } from 'src/app/completed-warranty-service/warrantyService';
import { FilterSearchParameter, CreateLookupFilterRequest } from 'src/app/shared/component/Models/LookupModels';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class WarrantyService {



  private Warrantysresponses = new Subject<any>();
  Warrantysresponses$ = this.Warrantysresponses.asObservable();





  patchValue(arg0: { id: any; globalId: any; createdBy: any; firstName: any; lastName: any; displayName: any; streetAddress: any; suburb: any; state: any; postalCode: any; phone: any; countryCodeId: any; cellPhone: any; primaryEmail: any; label: any; status: any; pictureUrl: any; receivedTextMessage: any; isJob: any; isLeadOpportunity: any; contactTypeId: any; isActive: any; }) {
    throw new Error('Method not implemented.');
  }
  // Saim End work || 4/15/2024 || Saim_Raza_Warranty_Form
  private baseUrl: string = environment.apiUrl + 'WarrantyInformation/';


  constructor(private http: HttpClient) { }

  postWarrantyData(LeadOpportunity: WarrantyInformation): Observable<ResponseModel<WarrantyInformationResponse>> {
    const apiUrl = `${this.baseUrl}`;
    return this.http.post<any>(apiUrl, LeadOpportunity).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  // Saim End work || 4/15/2024 || Saim_Raza_Warranty_Form

  // Saim Start work || 4/16/2024 || Saim_Raza_Warranty_Form

  private MessageUrl: string = environment.apiUrl + 'Messaging/create-Messaging/';


  PostMessage(createLead: CreateMessagingRequest): Observable<ResponseModel<ChangeOrderInformationResponse>> {
    const apiUrl = `${this.MessageUrl}`;
    return this.http.post<ResponseModel<ChangeOrderInformationResponse>>(apiUrl, createLead).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  // Saim End work || 4/16/2024 || Saim_Raza_Warranty_Form

  private baseU: string = environment.apiUrl + 'WarrantyService/';
  private warrantyDataSubject = new BehaviorSubject<any>(null);


  updateWarrantyData(data: any) {
    this.warrantyDataSubject.next(data);
  }

  // Function to retrieve the warranty data as an Observable
  getWarrantyDatas() {
    return this.warrantyDataSubject.asObservable();
  }


  postWarrantyServiceData(LeadOpportunity: Warranty): Observable<ResponseModel<WarrantyServiceResponse>> {
    const apiUrl = `${this.baseU}`;
    return this.http.post<any>(apiUrl, LeadOpportunity).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  private WarrantySetup: string = environment.apiUrl + 'WarrantySetup/getData-ByCompany';
  companyWarrantyget(): Observable<ResponseModel<WarrantySetupResponse>> {
    return this.http.get<ResponseModel<WarrantySetupResponse>>(this.WarrantySetup);
  }

  getCompanyWarranty(): Observable<ResponseModel<WarrantySetupResponse>> {
    return this.http.get<ResponseModel<WarrantySetupResponse>>(this.WarrantySetup);
  }


  private _baseUrl: string = environment.apiUrl + 'WarrantySetup';

  companyWarrantyPost(warrantyFormData: CreateWarrantySetupRequest): Observable<ResponseModelArray<WarrantySetupResponse>> {
    const apiUrl = `${this._baseUrl}`;
    return this.http.post<any>(this._baseUrl, warrantyFormData);
  }



  private Url_LookUpFilter: string = environment.apiUrl + 'LookupFilter/';

  getWarrantyData = new BehaviorSubject<ResponseModel<TablePageResponse<WarrantyInformations>>>(null);
  $geProjecttWarrantyData = this.getWarrantyData.asObservable();




  getAllWarrantyByFilter(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.Url_LookUpFilter}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getWarrantyData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }


  getAppliedFilterData(appliedFilter: CreateLookupFilterRequest[]) {
    const apiUrl = `${this.Url_LookUpFilter}GetAll-ByLookupFilter`;
    return this.http.post<any>(apiUrl, appliedFilter).subscribe(
      (data) => {
        this.getWarrantyData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }


  getProjectWarranty(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.Url_LookUpFilter}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getWarrantyData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }


  selectedFilterId = new BehaviorSubject<number>(0);
  $selectedFilterId = this.selectedFilterId.asObservable();


  private WarrantyDataresponse = new Subject<any>();
  WarrantyDataresponses$ = this.WarrantyDataresponse.asObservable();


WarrantyGetbyId(id: number): Observable<ResponseModel<WarrantyClaim>> {
    return this.http.get<ResponseModel<WarrantyClaim>>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }
WarrantyWarrantyService(id: number): Observable<ResponseModel<WarrantyServiceResponse>> {
    return this.http.get<ResponseModel<WarrantyServiceResponse>>(
      `${this.baseU}getAllBy-Id?WarrantyInformationId=${id}`
    );
  }


WarrantyWarrantyServicegetById(id: number): Observable<ResponseModel<WarrantyInformationFordashbord>> {
    return this.http.get<ResponseModel<WarrantyInformationFordashbord>>(
      `${this.baseU}getBy-Id?id=${id}`
    );
  }


  isUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $isUserAppliedFilter = this.isUserAppliedFilter.asObservable();



  setSelectedFilterId(filterId: number) {
    this.selectedFilterId.next(filterId);
  }

  userAppliedFilter(filter: boolean) {
    this.isUserAppliedFilter.next(filter);
  }
  WarrantygetData(): Observable<ResponseModelArray<WarrantyInformationResponse>> {
    return this.http.get<ResponseModelArray<WarrantyInformationResponse>>(`${this.baseUrl}GetAll`);
  }

  deleteWarrantyData(globalId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}?globalId=${globalId}`);
  }
}
