import { NewRFIsComponent } from 'src/app/messaging/Components/RFIs/new-rfis/new-rfis.component';
import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { ProjectManagementSiteDiariesCreateFilesComponent } from '../../Site-Diaries/project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { WarrantyNewClaimWarrantyCategoryComponent } from '../warranty-new-claim-warranty-category/warranty-new-claim-warranty-category.component';
import { WarrantyNewClaimEditWarrantyCategoryComponent } from '../warranty-new-claim-edit-warranty-category/warranty-new-claim-edit-warranty-category.component';
import { ProjectManagementWarrantyPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { AttachmentResponse, CreateAttachmentForDocumentRequest, CreateMessagingRequest, LeadOpportunityResponse, LookupNameSetupResponse, ScheduleItemParameterResponse, } from 'src/Models/LeadTags';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { LeadOpportunityAttachmentsUploadFilesComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { CompletedWarrantyServiceComponent } from 'src/app/completed-warranty-service/completed-warranty-service.component';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { WarrantyService } from 'src/Service/Project-Management/warranty/warranty.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { NzSizeDSType } from 'ng-zorro-antd/core/types';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { RfisService } from 'src/app/company-settings/services/rfis.service';
import { CreateRfiInformationAnswerRequest } from 'src/Models/ChangeOrder/ChangeOrder';
import { WarrantyInformationResponse, WarrantyServiceResponse } from 'src/Models/Warranty/warranty';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-warranty-new-claim',
  templateUrl: './warranty-new-claim.component.html',
  styleUrls: ['./warranty-new-claim.component.css']
})
export class WarrantyNewClaimComponent implements OnInit {
  @Output() onSaveComplete = new EventEmitter();

  // sufyan working
  jobID: number;
  formNameID: number = 10002;
  messagingId:number ;
  messagingIdRow: any;
  @Input() comments: any;
  // sufyan working

  selectedValue = null;
  warrantyFieldValues: any = {};
  categoryOption: { label: string; value: string }[] = [];
  priorityOption: { label: string; value: string }[] = [];
  serviceCoordinator: { label: string; value: string }[] = [];
  classificationOption: LookupNameSetupResponse[] = [];
  scheduleItemOption: ScheduleItemParameterResponse[] = [];
  selectedFiles: File[] = [];
  fileList: NzUploadFile[] = [];
  attachments: AttachmentResponse[] = [];
  isFormSaved = false;
  isformSaved = true;
  compareDate:string = 'Date';
  rfiInformationId: string; // Declare rfiInformationId property
  isSaved: boolean = false;
  public responseHeaderId: number = -1;
  public responseHeaderIdRes: WarrantyInformationResponse; // add sufyan
  public HeaderId: number;
   responseData: WarrantyServiceResponse  []=[] ;
 
  mainSaveResponseId: number | undefined;
  fromScheduleButton: boolean = false;


  PAGE_ID: number = 30005;
  PAGEID: number = 10002;
  WarrantyNewClaimComponent = true;


  TagsForm: FormGroup;
  isLoadingEdit: boolean = false;
  public isTagVisible = false;
  isLoadingOne: boolean = false;

  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  preDefinedDataCodes = {
    Category: ProjectManagementWarrantyPreDefinedCodes.Category,
    Priority: ProjectManagementWarrantyPreDefinedCodes.Priority,
    Coordinator: ProjectManagementWarrantyPreDefinedCodes.Coordinator,
    Classifications: ProjectManagementWarrantyPreDefinedCodes.Classifications
  };

  @Output() cancel = new EventEmitter<void>();
  // @Output() warrantydata = new EventEmitter<WarrantyServiceResponse>();


  public dataWarrantyService: WarrantyServiceResponse | null = null;


  Taghandle = true;
  coordinator: { title: string; key: string; isLeaf: boolean; }[];
  attendeesNodes: NzTreeNodeOptions[] = [];
  confirmModal: import("ng-zorro-antd/modal").NzModalRef<unknown, any>;
  isDeleteTagsLoading: boolean;
  leadOpportunitiesService: any = {};
  small: NzSizeDSType;
  showMessageAfterSave: boolean;
  isEditCardVisible: boolean = false;

  warrantyServiceData: WarrantyServiceResponse[] = [];
  private dataSubscription: Subscription;
  warrantydata: WarrantyServiceResponse [] =[];
  classificationResponse: LookupNameSetupResponse[] = []; // add sufyan


  constructor(private modal: NzModalService,
    private LookupFilter: LookupFilterService,
    private CustomerInformationService: CustomerInformationService,

    public toastsService: NzMessageService,
    private fb: FormBuilder,
    private loadingIndicatoreService: LoadingIndicatorService,
    private warrantyService: WarrantyService,
    private leadOppService: LeadOpportunitiesService,
    public localStorage: AccessLocalStorageService,
    private localStorageService: AccessLocalStorageService,
    private LeadOpportunitiesService: LeadOpportunitiesService,



    @Inject(NZ_MODAL_DATA) public JobData: any,
    @Inject(NZ_MODAL_DATA) public DataRfis: any,
    @Inject(NZ_MODAL_DATA) public JOBDATA: any,
    @Inject(NZ_MODAL_DATA) public leadData: any,
    @Inject(NZ_MODAL_DATA) public WarrantyInformation: any, // add sufyan

  ) {

   

  }


  ngOnInit(): void {

    this.messagingId = this.WarrantyInformation?.WarrantyInformation?.messagingId;
    this.messagingIdRow = this.WarrantyInformation?.WarrantyInformation?.id;
    console.log("MESSAGEING ID ROW:", this.messagingIdRow);
    console.log("MESSAGEING ID :", this.messagingId);

    const  storeComments = this.WarrantyInformation?.WarrantyInformation?.messaging?.messagingParameters.map(param => param.comments)
    this.comments = storeComments?.flat() || [];
    console.log('sufyan console.log',this.comments);

    this.dataSubscription = this.warrantyService.getWarrantyDatas().subscribe(data => {
      this.responseData = data;
    });

    this.jobID = this.localStorage.getJobId();


    this.ForCombo();
    this.iniliazeForm();
    this.filedsBindRecord();
    this.getScheduleItem();
    this.localStorage.getJobId();
    this.localStorage.getJobName();



    //Saim Start Work || 4/15/2024 || Saim_Raza_Warranty_Form 

    this.leadOppService.getSelectedFiles().subscribe(files => {
      this.selectedFiles = files;
    });

    this.warrantyFieldValues = {
      globalId: "00000000-0000-0000-0000-000000000000",
      warrantySetupId: 0,
      jobInformationId: this.jobID,
      claimAutoNumber: "",
      claimNumber: "",
      clainFullNumber: "",
      title: "",
      warrantyCategorySetupId: 0,
      prioritySetupId: 0,
      problemDescription: "",
      internalComments: "",
      isShowOwner: true,
      coordinatorUserId: 0,
      classificationSetupId: 0,
      addedCost: 0,
      followUpOn: null,
      rFIInformationId: 0,
      createWarrantyRequests: [
        {
          AssignToUserId: 456,
          AssignToSubVendorId: 789,
          ServiceOn: "2024-04-15T09:00:00",
          StartTime: "2024-04-15T10:00:00",
          EndTime: "2024-04-15T12:00:00",
          InternalNotes: "Sample internal notes",
          AttachmentId: 456,
          IsAppointmentToOwner: true,
          IsBuilderOverride: 1,
          OwnerFeedbackId: 123,
          CompletionOn: "2024-04-15T12:00:00",
          CompletionTime: "2024-04-15T13:00:00",
          AppointmentNotes: "Sample appointment notes",
          IsOwnerAccepted: true,
          OwnerAcceptedOn: "2024-04-14T09:00:00",
          IsBuilderAccepted: true,
          BuilderAcceptedOn: "2024-04-14T10:00:00"
        },

      ]
    }

    this.warrantyFieldValues = {
      headerId: 1,
      formNameId: 1002,
      comments: "",
      isViewOwner: true,
      isViewSubVendor: false,
      createMessagingUserNotifyParameterRequests: [
        { applicationUserId: 1 },
        { applicationUserId: 2 }
      ]
    };

        // add sufyan
        this.warrantyFieldValues['title'] = this.WarrantyInformation.WarrantyInformation.title;
        // add sufyan

  }


  // <!-- Saim starting Work || 4/15/2024 || Saim_Raza_Warranty_Form -->



  filedsBindRecord() {
    this.LookupFilter.getLookUpValuesByFormNameId(this.PAGEID).subscribe(
      (res) => {

        this.lookupSetNameResponse = res.result;

        let categoryValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.Category.code
        );
        let priorityValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.Priority.code
        );

        let classificationOption = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.Classifications.code
        );

        this.categoryOption = categoryValues.map((category) => ({
          label: category.name,
          value: category.id.toString(),
        }));
        this.priorityOption = priorityValues.map((Priority) => ({
          label: Priority.name,
          value: Priority.id.toString(),
        }));

        // sufyan add

        this.classificationOption = classificationOption;
        const claasificat = classificationOption.find(
          (item) => item.name === 'Holding Until'
        );

        if (claasificat) {
          const claasificatId = claasificat.id;
          this.warrantyFieldValues.classificationSetupId = claasificatId
        } else {
          console.log("Reminder '3 Days Before' not found.");
        }

        // sufyan add


      })
    console.log("classificationOption", this.classificationOption);

  }

  // Saim end Work || 4/15/2024 || Saim_Raza_Warranty_Form 

  //  <!-- Saim starting Work || 4/15/2024 || Saim_Raza_Warranty_Form -->

  ForCombo() {
    this.CustomerInformationService.getDataforcombo().subscribe((res) => {
      const serviceCoordinator = res.result;

      const attendees = res.result;


      this.serviceCoordinator = serviceCoordinator.map((AUser) => ({
        label: AUser.fullName,
        value: AUser.id.toString(),
      }));
      this.attendeesNodes = attendees.map((Adees) => ({
        title: Adees.fullName,
        key: Adees.id.toString(),
        isLeaf: true,
      }));
    });
  }


  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }


  // selectionChoiceModel
  Add(): void {
    const modalRef = this.modal.create({
      nzContent: WarrantyNewClaimWarrantyCategoryComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  // selectionChoiceModel
  Edit(): void {
    const modalRef = this.modal.create({
      nzContent: WarrantyNewClaimEditWarrantyCategoryComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  UploadFiles(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  CreateNew(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  viewAll(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  removeFile(file: File): void {
    const index = this.selectedFiles.indexOf(file);
    if (index !== -1) {
      this.selectedFiles.splice(index, 1);
    }
  }
  // Saim end Work || 4/15/2024 || Saim_Raza_Warranty_Form 

  // <!-- Saim starting Work || 4/15/2024 || Saim_Raza_Warranty_Form -->

  ///Open Category //
  iniliazeForm() {
    this.TagsForm = this.fb.group({
      id: [''],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      name: ['', Validators.required],
      companyParameterId: [1],
      formNameId: this.PAGE_ID,
      lookUpStandardId: [89],
      parentCode: ['PMWCG'],
      code: ['PMWCG'],
      description: [''],
      isFieldValue: [true],
    });
  }


  SaveOrUpdateData() {
    if (this.isLoadingEdit) {
      this.editData();
    } else {
      this.SaveData();
    }
  }

  SaveData() {
    if (this.TagsForm.valid) {
      this.loadingIndicatoreService.show();
      this.isLoadingOne = true;
      setTimeout(() => {
        this.leadOppService.getLookUpNameSetup(this.TagsForm.value).subscribe(
          response => {
            this.loadingIndicatoreService.hide();
            const tagName = this.TagsForm.get('name')?.value;
            this.isTagVisible = false;
            this.iniliazeForm();
            this.filedsBindRecord();

            this.isLoadingOne = false;
            this.toastsService.success(`${tagName} saved successfully`);
          },
          (error) => {
            this.loadingIndicatoreService.hide();
            if (error.status === 500) {
              this.toastsService.error(
                'Internal Server Error. Please try again later.'
              );
              this.isLoadingOne = false;
            } else if (error.status === 400) {
              this.isLoadingOne = false;
              this.toastsService.error('Bad Request. Please check your input.');
            } else {
              this.toastsService.error(
                'Failed to save division trade. Please try again.'
              );
            }
          }
        );
      }, 1000);
    } else {
      this.TagsForm.markAllAsTouched();
    }
  }


  editData() {
    if (this.TagsForm.valid) {
      this.loadingIndicatoreService.show();
      const tagName = this.TagsForm.get('name')?.value;
      setTimeout(() => {
        this.leadOppService.getLookUpNameSetup(this.TagsForm.value).subscribe(
          (response: any) => {

            this.loadingIndicatoreService.hide();
            this.isTagVisible = false;
            this.iniliazeForm();
            this.filedsBindRecord();
            this.isLoadingEdit = false;
            this.toastsService.success(`${tagName} Update successfully`);
          },
          (error) => {
            this.loadingIndicatoreService.hide();
            if (error.status === 500) {
              this.toastsService.error(
                'Internal Server Error. Please try again later.'
              );
            } else if (error.status === 400) {
              this.toastsService.error('Bad Request. Please check your input.');
            } else {
              this.toastsService.error(
                'Failed to save division trade. Please try again.'
              );
            }
          }
        );
      }, 1000); // 2000 milliseconds (2 seconds)
    } else {
    }
  }

  ConfirmDeleteTags(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete Tags?',
      nzContent:
        'This Tags is not applied to any subs/vendors and will be deleted.',
      nzOkDanger: true,
      nzOkText: 'Delete',
      nzCancelText: 'Cancel',
      nzModalType: 'confirm',
      nzOkLoading: this.isDeleteTagsLoading,
      nzAutofocus: 'ok',
      nzCentered: true,
      nzOnOk: () => this.DeleteData(),
    });
  }

  DeleteData(): Promise<boolean> {
    const vendorId = this.TagsForm.get('globalId')?.value;
    const tagName = this.TagsForm.get('name')?.value;
    this.isDeleteTagsLoading = true;
    this.loadingIndicatoreService.show();

    return new Promise<boolean>((resolve, reject) => {
      this.leadOppService.deleteDataLookupNameSetup(vendorId).subscribe(
        (res: any) => {
          this.isDeleteTagsLoading = false;
          setTimeout(() => {
            this.toastsService.success(`${tagName} Deleted successfully`);
            this.isTagVisible = false;
            this.loadingIndicatoreService.hide();
            this.confirmModal.destroy();
            resolve(true);
          }, 1000);
          this.isLoadingEdit = false;
          this.isTagVisible = false;
          this.iniliazeForm();
          this.filedsBindRecord();
          this.warrantyFieldValues.warrantyCategorySetupId = '';
        },
        (error) => {
          this.isDeleteTagsLoading = false;
          this.isTagVisible = false;
          reject(false);
        }
      );
    });
  }


  showConfirmData(): void {
    if (this.TagsForm.dirty) {
      // The form has unsaved changes, show confirmation modal
      this.confirmModal = this.modal.confirm({
        nzTitle: '<b>Save Changes?</b>',
        nzContent:
          '<p>This Lead Tags has unsaved changes. Do you want to save your changes before closing??</p> ',
        nzOkText: 'Save',
        nzCancelText: "Don't Save",
        nzOnOk: () => {
          // User confirmed to discard changes, save and close modal
          this.SaveData();
        },
        nzOnCancel: () => {
          // User canceled, reset form and close modal
          this.TagsForm.reset();
          this.isTagVisible = false;
        },
        nzCloseIcon: 'false',
        nzStyle: { top: '250px' },
        nzClassName: 'custom-modal-content',
      });
    } else {
      // No unsaved changes, close the modal directly
      this.TagsForm.reset();
      this.isTagVisible = false;
    }
  }

  showTagEdit(selectedValue: LookupNameSetupResponse): void {
    if (selectedValue) {
      const selectedId: number = Number(selectedValue); // Convert the selected ID to number
      // console.log('selectedId', selectedId);
      this.isTagVisible = true;
      this.isLoadingEdit = true;
      const selectedObjectFromLookup = this.lookupSetNameResponse.find(item => item.id === selectedId);
      if (selectedObjectFromLookup) {
        const selectedData = selectedObjectFromLookup;
        if (selectedData) {
          this.TagsForm.controls['name'].setValue(selectedData.name);
          this.TagsForm.controls['globalId'].setValue(selectedData.globalId);
          this.TagsForm.controls['isFieldValue'].setValue(selectedData.isFieldValue);
          this.TagsForm.controls['companyParameterId'].setValue(selectedData.companyParameterId);
          this.TagsForm.controls['formNameId'].setValue(selectedData.formNameId);
          this.TagsForm.controls['lookUpStandardId'].setValue(selectedData.lookUpStandardId);
          this.TagsForm.controls['code'].setValue(selectedData.code);
          this.TagsForm.controls['description'].setValue(selectedData.description);
          this.TagsForm.controls['parentCode'].setValue(selectedData.parentCode);
        }
      }
    }

  }

  showTags(): void {
    this.loadingIndicatoreService.show();

    setTimeout(() => {
      this.loadingIndicatoreService.hide();
      this.isTagVisible = true;
      this.isLoadingEdit = false;
    }, 1000);




  }

  OpenLogCompletedService(flag: boolean): void {
    const modalRef = this.modal.create({
      nzContent: CompletedWarrantyServiceComponent,
      nzFooter: null,
      nzData: {
        headerId: this.responseHeaderId

      }
    });
    modalRef.componentInstance.isSaveButtonClicked = flag;
    modalRef.componentInstance.responseHeaderId = this.responseHeaderId;
    modalRef.componentInstance.isCheckClicked = true;
    modalRef.componentInstance.dateFlag = false;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.WarrantyResponse.subscribe((data) => {
      this.dataWarrantyService = data;

      console.log("RESPONSE DATA : ", this.dataWarrantyService);
      this.warrantyServiceData.push(this.dataWarrantyService);
      
    });
   
  }


  TagClose(): void {
    this.cancel.emit();
  }
  // <!-- Saim end Work || 4/15/2024 || Saim_Raza_Warranty_Form -->

  // Saim Start Work || 4/17/2024 || Saim_Raza_Warranty_Form 
  getScheduleItem() {
    this.leadOppService.getAllDataScheduleItems().subscribe(res => {
      // console.log('logggggggg', res.result);
      this.scheduleItemOption = res.result;

    });
  }
  // Saim end Work || 4/17/2024 || Saim_Raza_Warranty_Form 

  // Saim start work || 4/17/2024 || Saim_raza_warranty_Form_Work

  createMessage() {
    console.log("this.responseHeaderId ", this.responseHeaderId);

    if (this.responseHeaderId <= 0) {
      console.error('Main data not saved yet.');
      return;
    }

    const selectedUsers = this.warrantyFieldValues['createMessagingUserNotifyParameterRequests'];

    const createMessagingRequest: CreateMessagingRequest = {
      headerId: this.responseHeaderId,
      formNameId: 10002,
      comments: "New Message",
      isViewOwner: true,
      isViewSubVendor: false,
      createMessagingUserNotifyParameterRequests: selectedUsers.map(userId => ({ applicationUserId: userId }))
    };

    this.LeadOpportunitiesService.PostMessage(createMessagingRequest).subscribe(
      (messageResponse) => {
        console.log('Message created successfully:', messageResponse);
        this.toastsService.success('Update successfully!');
      },
      (messageError) => {
        console.error('Error creating message:', messageError);
      }
    );;
  }






// _____________________________________________________Saim_Start_Work_On_Save________________________________________________________________
saveWarrantyForm( ) {
    this.isFormSaved = true;
    this.isformSaved = true;



    let data = this.warrantyFieldValues;

    this.warrantyService.postWarrantyData(data).subscribe(

      (response) => {
        this.toastsService.success('New Warranty  Saved Successfully!');
        if (response) {
          console.log("RES : ", response);
          this.responseHeaderId = response.result.id;
          this.responseHeaderIdRes = response.result; // add sufyan

          this.messagingId =this.responseHeaderId ? this.responseHeaderId : this.WarrantyInformation.id; // sufyan work
          this.messagingIdRow = this.responseHeaderId ? this.responseHeaderId : this.WarrantyInformation.id; // sufyan work
          console.log(" toDoParameterId ? toDoParameterId : this.rowToDoData;",this.messagingIdRow);

          this.HeaderId = response.result.id;
          // this.handleSelectedCustomerChange();

        }
        console.log("responseHeaderId :", this.HeaderId);


        if (this.selectedFiles && this.selectedFiles.length > 0) {
          const filesToUpload = this.selectedFiles.filter(file => file && file instanceof File);
          if (filesToUpload.length > 0) {
            const attachmentRequest: CreateAttachmentForDocumentRequest = {
              headerId: response.result.id,
              companyParameterId: 1,
              formNameId: 10002,
              documentFiles: filesToUpload.map(file => file)
            };
            this.leadOppService.CreateAttachmentForDocument(attachmentRequest).subscribe(
              (attachmentResponse) => {
                // Assuming attachmentResponse contains the file ID, you can save it to the database
                this.saveFileIdToDatabase(attachmentResponse.fileId);
              },
              (attachmentError) => {
                console.error('Error creating attachment:', attachmentError);
              }
            );
          } else {
            console.warn('No files to upload.');
          }
        }
      },
      (error) => {
        console.error('Error saving data:', error);
      }
    );
  }

 
  


  saveFileIdToDatabase(fileId: string): void {
  }
// _____________________________________________________Saim_End_Work_On_Save________________________________________________________________

// _____________________________________________________Saim_Start_Work_On_Rfi's________________________________________________________________

  openrFISForm(responseHeaderId: number, formNameId: number) {
    const modalRef = this.modal.create({
      nzContent: NewRFIsComponent,
      nzFooter: null,
      nzData: {
        headerId: responseHeaderId,
        formNameId: formNameId,
      }
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
 
  }
  // _____________________________________________________Saim_End_Work_On_Rfi's_________________________________________________________________


  // Saim end work || 4/17/2024 || Saim_raza_warranty_Form_Work


  //   // _____________________________________________________Saim_Start_Work_Open_Model_For_Edit_Mode________________________________________________________________  


openEditMode(warrantyEdit: WarrantyServiceResponse[]) {
  // Open modal and pass data
  const modalRef = this.modal.create({
    nzContent: CompletedWarrantyServiceComponent,
    nzFooter: null,
    nzData: {
      headerId: this.responseHeaderId,
      warrantyEditData: warrantyEdit,

    }
  });

  // Assign warrantiesData in modal component
  modalRef.componentInstance.warrantiesData = warrantyEdit;
console.log("warrantyEditData",warrantyEdit);

  // Subscribe to modal's warrantyResponse event
  modalRef.componentInstance.warrantyResponse.subscribe((data: WarrantyServiceResponse) => {
    // Push the emitted data into warrantyServiceData array
    this.warrantyServiceData.push(data);
    console.log("Updated warrantyServiceData:", this.warrantyServiceData);
  });

  // Subscribe to modal's cancel event
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy(); // Destroy the modal on cancel
  });
}
 editModeActivated = false;

  // _____________________________________________________Saim_End_Work_Open_Model_For_Edit_Mode________________________________________________________________  

}



