import { Component } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';

@Component({
  selector: 'app-bid-dashboard',
  templateUrl: './bid-dashboard.component.html',
  styleUrls: ['./bid-dashboard.component.css']
})
export class BidDashboardComponent {

  isCollapsed = false;
  selectedJobId: number = 0;

  PAGE_ID: number = 16;
  Financial_Tenders: string = FiltersName.Financial_Tenders;

  constructor(private modal: NzModalService, 
    private msg: NzMessageService,
    private filterService: FilterSideMenuBarService) { }

  openFilter(component: string, pageId: number) {
    this.filterService.toggleFilter(component, pageId);
  }

  
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
      // Selected Job Id From Left Side Job List
getSelectedJobId(item: number){
  this.selectedJobId = item;
  console.log("selectedJobId : ", this.selectedJobId);
}
}
