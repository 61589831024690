import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzSelectSizeType } from 'ng-zorro-antd/select';
import { Subscription } from 'rxjs';
import { Selectionsetupcode } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { CustomField } from 'src/Models/CompanySettings/Selections/CustomFieldId';
import { SelectionSetup } from 'src/Models/CompanySettings/Selections/SelectionSetup';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { CustomFieldService } from 'src/Service/CompanyInFormation/Selections/custom-field.service';
import { DataTypeService } from 'src/Service/CompanyInFormation/Selections/data-type.service';
import { FileNameSetupService } from 'src/Service/CompanyInFormation/Selections/file-name-setup.service';
import { RequireSignatureOnSelectionSetupService } from 'src/Service/CompanyInFormation/Selections/require-signature-on-selection-setup.service';
import { SelectionBuilderViewService } from 'src/Service/CompanyInFormation/Selections/selection-builder-view.service';
import { SelectionOwnerViewService } from 'src/Service/CompanyInFormation/Selections/selection-owner-view.service';
import { SelectionsSScheduleSetupModalService } from 'src/Service/CompanyInFormation/Selections/selections-sschedule-setup-modal.service';
import { SelectionsService } from 'src/Service/CompanyInFormation/Selections/selections.service';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { ApplicationUserService } from 'src/Service/Job-List/Job-Information/application-user.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';

@Component({
  selector: 'app-selectiondetup',
  templateUrl: './selectiondetup.component.html',
  styleUrls: ['./selectiondetup.component.css']
})
export class SelectiondetupComponent {
  @Output() cancel = new EventEmitter<void>();
  DefaultBuilderview: LookupNameSetupResponse[] = [];
  DefaultOwnerview: LookupNameSetupResponse[] = [];
  RequireSignatureonSelections: LookupNameSetupResponse[] = [];
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  isInputVisible: boolean = false;
  text: string = '';
  dataset: any;
  selectionSetupForm: FormGroup;
  CustomFieldSetupForm: FormGroup;
  CustomField: CustomField[] = [];
  form: FormGroup;
  PAGE_ID: number = 50024;
  data: any;  
  isSaving = false;   
  listOfOption: Array<{ label: string; value: string }> = [];
  date = null;
  checked = false;
  checked1 = false;
  checked2 = false;
  checked3 = false;
  checked4 = false;
  checked5 = false;
  checked6 = false;
  checked7 = false;
  checked8 = false;
  checked9 = false;
  checked10 = false;
  checked11 = false;
  checked12 = false;
  checked13 = false;
  checked14 = false;
  checked15 = false;
  checked16 = false;
  checked17 = false;
  demoValue = 0;

  preDefinedDataCodes = {
  DefaultBuilderview: Selectionsetupcode.CSDBV,
  DefaultOwnerview: Selectionsetupcode.CSDBV,
  RequireSignatureonSelections: Selectionsetupcode.CSRSS,
  };


  constructor(private fb:FormBuilder,
    private Selectionsservice : SelectionsService,
    private LookupFilterServicee: LookupFilterService,
    private toastssService: NzMessageService,
    private modal: NzModalService,
    private _loadingIndicatorService: LoadingIndicatorService,
      ){}

  ngOnInit(): void {
    this.selectionSetupForm = this.fb.group({
    weekStartId: [''],
    hourlyRateTrackingId: [''],
    useFormattingOTimeClockTotals: [false],
    whenAJobIsDeletedPromptToDeleteCorrespondingTimeCardItems: [false],
    sendOneTimeNotificationToInternalUsersThatHaveBeenClockedInForOverHours: [false],
    automaticallyEndBreaksAfterMinutesNo: [''],
    showGeneralJob: [false],
    showOvertime: [false],
    employeesAccrueDailyOvertime: [false],
    employeesAccrueWeeklyOvertime: [false],
    showDoubleOvertime: [false],
    radiusId: [''],
    radiusUnitId: [''],
    defaultTimeClockGeofencingToOnForAllNewJobs: [false],
  });
    this.fetchCompanySelection();
    this.initLoad()
    this.initForm();
    // this.initFormCustomFieldSetupForm();
    const children: Array<{ label: string; value: string }> = [];
    for (let i = 10; i < 36; i++) {
      children.push({ label: i.toString(36) + i, value: i.toString(36) + i });
    }
    this.listOfOption = children;
  }

  private initForm(): void {
    this.form = this.fb.group({
      selectedDataType: [null]
    });
  
    this.selectionSetupForm = this.fb.group({
      selectionBuilderViewId: [''],
      selectionOwnerViewId: [''],
      defaultToExpandCategoryLocationView: [''],
      alertUserNumber: [''],
      allowancesOnNewJob: [''],
      showAllowancesToSubsVendors: [''],
      showVendorContactInfoToOwner: [''],
      showLineItemsToOwner: [''],
      defaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs: [''],
      onNewJobsUpdateJobRunningTotalWhenASelectionIsApproved: [''],
      requireSignatureOnSelectionSetupId: [''],
      selectionsApprovalDisclaimer: ['string'],
      approvedChoicesOnly: [''],
      pricing: [''],
      subsVendors: [''],
      addedBy: [''],
      installers: [''],
      choiceDescription: [''],
      selectionDescription: [''],
      lineItems: [''],
      discussions: [''],
      customFields: [''],
      weekStartId: [''],  
      hourlyRateTrackingId: [''],
      useFormattingOTimeClockTotals: [false],
      whenAJobIsDeletedPromptToDeleteCorrespondingTimeCardItems: [false],
      sendOneTimeNotificationToInternalUsersThatHaveBeenClockedInForOverHours: [false],
      automaticallyEndBreaksAfterMinutesNo: [''],
      showGeneralJob: [false],
      showOvertime: [false],
      employeesAccrueDailyOvertime: [false],
      employeesAccrueWeeklyOvertime: [false],
      showDoubleOvertime: [false],
      radiusId: [''],
      radiusUnitId: [''],
      defaultTimeClockGeofencingToOnForAllNewJobs: [false],
    });
  }


  initLoad() {
    this.LookupFilterServicee.getLookUpValuesByFormNameId(
      this.PAGE_ID
    ).subscribe((res) => {
      // -----------single Select ---------------

      let defaultBuilderview = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.DefaultBuilderview.code
      );
      let DefaultOwnerview = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.DefaultOwnerview.code
      );
      let requireSignatureonSelections = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.RequireSignatureonSelections.code
      );


      // -----------single Select -------------
      this.DefaultBuilderview = defaultBuilderview;

      this.DefaultOwnerview = DefaultOwnerview;

      this.RequireSignatureonSelections = requireSignatureonSelections;

    }
  )}

    toggleVisibility() {
      this.isInputVisible = !this.isInputVisible;
    }
  
    saveSelection() {
      this._loadingIndicatorService.show(); 
      if (this.selectionSetupForm.valid) {
        this.isSaving = true; 
        this.Selectionsservice.postCompanySelection(this.selectionSetupForm.value)
          .subscribe(
            (response) => {
              this.toastssService.success('Selection saved successfully!');
              this.selectionSetupForm.reset();
              this.modal.closeAll();
              this.isSaving = false; 
              this._loadingIndicatorService.hide(); 
            },
            (error) => {
              console.error('Error while saving selection:', error);
              this.toastssService.error('Failed to save selection.');
              this.isSaving = false; 
              this._loadingIndicatorService.hide(); 
            }
          );
      } else {
        this.toastssService.error('Please fill all required fields.');
        this._loadingIndicatorService.hide(); 
      }
    }
    
    fetchCompanySelection() {
      this._loadingIndicatorService.show();
      this.Selectionsservice.getAllselection().subscribe(
        (response) => {
          this._loadingIndicatorService.hide();
          this.dataset = response.result;
          console.log("this.dataset", this.dataset);
          if (this.dataset.length > 0) {
            const firstItem = this.dataset[0];
            this.selectionSetupForm.patchValue({
              selectionBuilderViewId: firstItem.SelectionBuilderViewId || '',
              selectionOwnerViewId: firstItem.SelectionOwnerViewId || '',
              defaultToExpandCategoryLocationView: firstItem.DefaultToExpandCategoryLocationView || false,
              alertUserNumber: firstItem.AlertUserNumber || '',
              allowancesOnNewJob: firstItem.AllowancesOnNewJob || false,
              showAllowancesToSubsVendors: firstItem.ShowAllowancesToSubsVendors || false,
              showVendorContactInfoToOwner: firstItem.ShowVendorContactInfoToOwner || false,
              showLineItemsToOwner: firstItem.ShowLineItemsToOwner || false,
              defaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs: firstItem.DefaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs || false,
              onNewJobsUpdateJobRunningTotalWhenASelectionIsApproved: firstItem.OnNewJobsUpdateJobRunningTotalWhenASelectionIsApproved || false,
              requireSignatureOnSelectionSetupId: firstItem.RequireSignatureOnSelectionSetupId || '',
              selectionsApprovalDisclaimer: firstItem.SelectionsApprovalDisclaimer || '',
              approvedChoicesOnly: firstItem.ApprovedChoicesOnly || false,
              pricing: firstItem.Pricing || false,
              subsVendors: firstItem.SubsVendors || false,
              addedBy: firstItem.AddedBy || false,
              installers: firstItem.Installers || false,
              choiceDescription: firstItem.ChoiceDescription || false,
              selectionDescription: firstItem.SelectionDescription || false,
              lineItems: firstItem.LineItems || false,
              discussions: firstItem.Discussions || false,
              customFields: firstItem.CustomFields || false,
            });
          }
        },
      );
    }
}  
