<nz-modal
    nzWidth="70%"
    [(nzVisible)]="Taghandle"
    [nzTitle]="companylogo1"
    [nzFooter]="companylogo2"
    (nzOnCancel)="TagClose()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '70vh', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
  <ng-template #companylogo1>
    <div class="row p-0 m-0">
      <h1 nz-typography>Import Data From Template</h1>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <div class="row famly-inn">
      <div class="col-12">
          <div class="card border-0 mt-0">
              <div class="card-head border-bottom">
                  <div class="row py-3">
                      <h5 nz-typography><span class="ms-3">Import Options</span></h5>
                  </div>
              </div>
              <div class="card-body">
                <div class="row">
                    <label class="sub-text fs-6">Schedule Items</label>
                </div>
                <div class="row">
                  <label class="sub-text">Source Template <span class="fw-bolder text-danger">*</span></label>
                </div>
                <div class="row">
                    <div class="col-6">
                      <nz-select nzShowSearch nzPlaceHolder="Select a person">
                        <nz-option nzLabel="Jack" nzValue="Jack"></nz-option>
                        <nz-option nzLabel="www" nzValue="www"></nz-option>
                        <nz-option nzLabel="Jacfwwek" nzValue="Jacfwwek"></nz-option>
                    </nz-select>
                    
                    </div>
                </div>
                <div class="row border-bottom pb-5">
                  <div class="col-12">
                <div class="row">
                  <label class="sub-text fs-6">Schedule Items</label>
                </div>
                <div class="row">
                  <label class="sub-text">Project Management</label>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                      <label (change)="onCheckboxChange11()" nz-checkbox><span class="pira-label">Schedule</span></label>
                  </div>
                </div>
                <div class="row mt-1 gap-baj" *ngIf="isCheckboxChecked11">
                  <div class="col-12">
                    <div class="row">
                      <label class="sub-text">New Start Date<span class="fw-bolder text-danger ms-1">*</span></label>
                    </div>
                    <nz-date-picker [nzPlaceHolder]="' '" nzSuffixIcon="false"></nz-date-picker>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                      <label nz-checkbox><span class="pira-label">To-Do's</span></label>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                      <label  (change)="onCheckboxChange12()" nz-checkbox><span class="pira-label">Selections</span></label>
                  </div>
                </div>
                <div class="row mt-2 gap-baj" *ngIf="isCheckboxChecked12">
                  <div class="col-12">
                    <div class="row">
                      <label class="sub-text">New Deadline</label>
                    </div>
                    <nz-date-picker [nzPlaceHolder]="' '" nzSuffixIcon="false"></nz-date-picker>
                    <div class="row mt-3">
                      <div class="col-12">
                          <label nz-checkbox><span class="pira-label">Reset approved choices to pending</span></label>
                      </div>
                    </div>
                    <div class="row mt-3 ms-3">
                      <div class="col-12">
                          <label nz-checkbox><span class="pira-label">Apply any pending line item updates</span></label>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                      <label nz-checkbox><span class="pira-label">Selections</span></label>
                  </div>
                </div>
                  </div>
                </div>
                <div class="row border-bottom pb-5  mt-3">
                  <div class="col-12">
                <div class="row">
                  <label class="sub-text">Files</label>
                </div>

                <div class="row mt-2">
                  <div class="col-12">
                      <label (change)="onCheckboxChange13()" nz-checkbox><span class="pira-label">Document Folders</span></label>
                  </div>
                </div>
                <div class="row mt-3 gap-baj" *ngIf="isCheckboxChecked13">
                  <div class="col-12">
                      <label nz-checkbox><span class="pira-label">Include files</span></label>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                      <label nz-checkbox><span class="pira-label">Photo Folders</span></label>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                      <label (change)="onCheckboxChange14()"nz-checkbox><span class="pira-label">Video Folders</span></label>
                  </div>
                </div>
                <div class="row mt-3 gap-baj" *ngIf="isCheckboxChecked14">
                  <div class="col-12">
                      <label nz-checkbox><span class="pira-label">Include files</span></label>
                  </div>
                </div>
                  </div>
                </div>
                <div class="row border-bottom pb-5  mt-3">
                  <div class="col-12">
                <div class="row">
                  <label class="sub-text">Messaging</label>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                      <label (change)="onCheckboxChange15()" nz-checkbox><span class="pira-label">Surveys</span></label>
                  </div>
                </div>
                <div class="row mt-1 gap-baj" *ngIf="isCheckboxChecked15">
                  <div class="col-12">
                    <div class="row">
                      <label class="sub-text">New Release Date<span class="fw-bolder text-danger ms-1">*</span></label>
                    </div>
                    <nz-date-picker [nzPlaceHolder]="' '" nzSuffixIcon="false"></nz-date-picker>
                  </div>
                </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                <div class="row">
                  <label class="sub-text">Financial</label>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                      <label (change)="onCheckboxChange16()" nz-checkbox><span class="pira-label">Owner Invoices</span></label>
                  </div>
                </div>
                <div class="row mt-1 gap-baj" *ngIf="isCheckboxChecked16">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-2">
                      <label class="sub-text">Date</label>
                      </div>
                      <div class="col-2">
                      <label class="sub-text">Time</label>
                      </div>
                    </div>
                    <nz-date-picker [nzPlaceHolder]="' '" nzSuffixIcon="false"></nz-date-picker>
                    <nz-time-picker [nzPlaceHolder]="' '"></nz-time-picker> `
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                      <label (change)="onCheckboxChange17()" nz-checkbox><span class="pira-label">Owner Invoices</span></label>
                  </div>
                </div>
                <div class="row mt-1 gap-baj" *ngIf="isCheckboxChecked17">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-2">
                      <label class="sub-text">Date</label>
                      </div>
                      <div class="col-2">
                      <label class="sub-text">Time</label>
                      </div>
                    </div>
                    <nz-date-picker [nzPlaceHolder]="' '" nzSuffixIcon="false"></nz-date-picker>
                    <nz-time-picker [nzPlaceHolder]="' '"></nz-time-picker> `
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                      <label (change)="onCheckboxChange18()" nz-checkbox><span class="pira-label">Estimates</span></label>
                  </div>
                </div>
                <div class="row mt-2 gap-baj" *ngIf="isCheckboxChecked18">
                  <div class="col-12">
                    <div class="row mt-3">
                      <div class="col-12">
                          <label nz-checkbox><span class="pira-label">Remove existing estimate items<br>
                            Any existing items tied to Bids, Selections, or Allowances will not be removed.</span></label>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-12">
                          <label nz-checkbox><span class="pira-label">Keep Introductory and Closing Text in Job Proposal Details. Text from this template will be added to the existing text.</span></label>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                      <label (change)="onCheckboxChange19()" nz-checkbox><span class="pira-label">Purchase Orders</span></label>
                  </div>
                </div>
                <div class="row mt-3 gap-baj" *ngIf="isCheckboxChecked19">
                  <div class="col-12">
                      <label nz-checkbox><span class="pira-label">Linked Bills</span></label>
                  </div>
                  <div class="row mt-2">
                    <label class="sub-text">Estimated Completion for Purchase Orders</label>
                  </div>
                  <div class="col-2">
                  <nz-date-picker [nzPlaceHolder]="' '" nzSuffixIcon="false"></nz-date-picker>
                </div>
                  <div class="col-12 mt-2">
                    <label nz-checkbox><span class="pira-label">Include PO attachments</span></label>
                </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                      <label nz-checkbox><span class="pira-label">Bills</span></label>
                  </div>
                </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
    </div>
</div>
<ng-template #companylogo2>
  <button nz-button nzType="primary" class="rounded-1 me-0">Import</button>
</ng-template>
</nz-modal>