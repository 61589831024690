<nz-modal
  nzWidth="40%"
  [(nzVisible)]="copyToDos"
  [nzTitle]="companylogo1"
  [nzFooter]="companylogo2"
  (nzOnCancel)="closeCopToDo()"
  [nzCentered]="true"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '70vh',
    'overflow-y': 'auto'
  }"
>
  <ng-template #companylogo1>
    <div class="p-0 m-0">
      <h1 class="p-0 m-0">Copy To-Do's</h1>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>

    <div class="card-container">
        <nz-card 
          [nzTitle]="CopyToDo"
          class=" border-0 m-0 p-0 rounded-2 "
        >
          <ng-template #CopyToDo>
            <div class="row m-0 p-0">
              <h5 class="m-0 p-0" nz-typography>Copy To-Do's</h5>
            </div>
          </ng-template>
          <div class="card-body m-0 p-0">
            <div class="row mb-3">
              <div class="col-12">
                <label class="sub-text mb-1">Destination Job/Template <span class="text-danger">*</span></label>
                <nz-select class="rounded-2" nzShowSearch nzAutoFocus="true"  [(ngModel)]="formFieldValues['jobInformationId']" name="jobInformationId" >
                  <nz-option  *ngFor="let option of jobList" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                </nz-select>  
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <label class="sub-text mb-1">New Deadline </label>
              </div>
              <div class="col-12">
                <nz-date-picker class="rounded-2" [(ngModel)]="formFieldValues['newDeadLineDate']" name="newDeadLineDate" [nzFormat]="'MM d,YYYY'" [nzPlaceHolder]="' '" nzSuffixIcon="false"></nz-date-picker>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <label class="para">(Leave blank for no deadline) </label>
              </div>
            </div>
          
          </div>
        </nz-card>


      
      </div>
  </div>
  <ng-template #companylogo2>
    <button nz-button nzType="default" class="rounded-1 me-0" (click)="closeCopToDo()">Cancel</button>
    <button nz-button nzType="primary" class="rounded-1 me-0" [nzLoading]="isLoadingOne" (click)="savecopyToDos()">Copy Selected To-Do`s</button>
  </ng-template>
</nz-modal>
