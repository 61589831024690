




import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';1
import { FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { delay, finalize } from 'rxjs';
import { ColumnsResizePinProperties } from 'src/Models/InternalUser/internalUserColumnsPinResize';
import { StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';
import { ApiResponseModel } from 'src/Models/apiResponse.model';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { DataUpdateServiceService } from 'src/Service/SubVendorsServices/data-update-service.service';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { LeadActivityTypeService } from 'src/Service/Lead-Activity-Type/lead-activity-type.service';
@Component({
  selector: 'app-add-new-grid-standard-view',
  templateUrl: './add-new-grid-standard-view.component.html',
  styleUrls: ['./add-new-grid-standard-view.component.css']
})
export class AddNewGridStandardViewComponent {
  @Output() cancel = new EventEmitter<void>();
  StandardViewResponse: StandardViewResponse[] = [];

  @Input() StandardViewResponseData: any[];
  @Output() selectedApplyView: EventEmitter<string[]> = new EventEmitter<string[]>();
  isLoading: boolean = false; // Initially set to true
  isVisible: boolean = true;
  confirmModal?: NzModalRef;
  columnNameOption: NzTreeNodeOptions[] = [];
  columnNameDisplay: NzTreeNodeOptions[] = [];
  isDuplicateSaveButtonVisible: boolean = false;
  isEditFormVisible: boolean = false;
  isInputVisible: boolean = false;
  selectedColumnName: string[] = [];
  selectedName: string[] = [];
  standardViewResponse: StandardViewResponse[];
  standardViewList: StandardViewResponse[] = [];
  selectedCompareProperties: ColumnsResizePinProperties[] = [];
  selectedAddColumnNames: NzTreeNodeOptions[] = [];
  standardFormFieldValues: any = {};
  selectedStandardView: number;
  fixedColumns : string[]= [];
  buttonDisabled: boolean = true;
  constructor(
    private loadingIndicatorService: LoadingIndicatorService,
    public toastService: NzMessageService,
    private modal: NzModalService,
    private dataUpdateService: DataUpdateServiceService,
    private standardViewService: StandardViewForAllService,
    private changeDetectorRef: ChangeDetectorRef,
    private leadAceeetivitiesService: LeadActivityTypeService,





    @Inject(NZ_MODAL_DATA) public columnData: any) {
      console.log(' console.log(columnData)',columnData);
  }

  ngOnInit(): void {
    this.selectedStandardView = this.columnData.selectedStandardView
    console.log('this.selectedStandardView',this.selectedStandardView);
    this.columnNameOption = this.columnData.column;
    this.standardViewList = this.columnData.viewList;
    this.columnNameDisplay = this.columnData.column;
    this.selectedColumnName = this.columnData.displayCol;
    this.selectedCompareProperties = this.columnData.selectedColNameSizePin;
    this.fixedColumns = this.columnData.fixedCol;

    console.log("selectedColNameSizePin : ", this.selectedCompareProperties);


    if(this.columnData.isSaveAs){
      this.initStandardForm();
      console.log("this.columnData.  : ", this.columnData);
      this.isInputVisible = this.columnData.isSaveAs;

      let selectedView = this.standardViewList.find(x => x.id === this.selectedStandardView);
      this.standardFormFieldValues['name'] = "Copy Of "+ selectedView.name;
      this.standardFormFieldValues['isMarkAsPrivate'] = selectedView.isMarkAsPrivate;
      this.standardFormFieldValues['isSetAsDefault'] = selectedView.isSetAsDefault;

      let selectedNames = this.columnData.column
      .filter(x => this.selectedColumnName.includes(x.displayColumnName))
      .map(x => x.id.toString());
      this.standardFormFieldValues['createStandardViewParameterRequests'] = selectedNames;

    }

    this.columnNameOption = this.columnData.column.map(item => ({
      title: item.displayColumnName,
      value: item.id.toString(),
      key: item.id.toString(),
      isLeaf: true,
      disabled: this.fixedColumns.includes(item.displayColumnName),
      checked: this.fixedColumns.includes(item.displayColumnName),
      selectable: true,
      selected: this.fixedColumns.includes(item.displayColumnName),
    }));



    this.columnNameDisplay = this.columnData.column.map(item => ({
      title: item.displayColumnName,
      key: item.displayColumnName,
      isLeaf: true,
      disabled: this.fixedColumns.includes(item.displayColumnName),
      checked: this.columnData.displayCol.includes(item.displayColumnName),
      selectable: true,
      selected: this.columnData.displayCol.includes(item.displayColumnName),
    }));
  }

  onCancel(): void {
    this.cancel.emit();
  }


  private initStandardForm(): void {
    this.standardFormFieldValues  = {
      globalId: '00000000-0000-0000-0000-000000000000',
      id: '',
      name: '',
      isMarkAsPrivate: true,
      isSetAsDefault: false,
      formNameId: this.columnData.pageId,
      companyParameterId: 1,
      isNotDeleted: false,
      createStandardViewParameterRequests: [[]]
    };
   }

  saveStandardView(): void {
    if (!this.standardFormFieldValues.name) {
      return;
    }
    // this.isLoading = true;
    const formData = { ...this.standardFormFieldValues };
         let newArray = [];
         for (let node of this.columnNameOption) {
          if (formData.createStandardViewParameterRequests.includes(node.key)) {
              let matchingSelected = this.selectedCompareProperties.find(selectedFrom => selectedFrom.displayColumnName === node.title);

              if (matchingSelected) {
                  newArray.push({
                      resizeColumnWidth: matchingSelected.resizeColumnWidth,
                      isPin: matchingSelected.isPin,
                      standardColumnSetupId: node.key
                  });
              } else {
                  newArray.push({
                      resizeColumnWidth: node['resizeColumnWidth'] === "defaultSize" ? 0 :  node['resizeColumnWidth'],
                      isPin: false,
                      standardColumnSetupId: node.key
                  });
              }
          }
      }

    formData.createStandardViewParameterRequests = newArray;
    this.standardViewService.postData(formData)
      .subscribe(
        (res: ResponseModelArray<StandardViewResponse>) => {
          this.standardViewResponse = res.result;
          setTimeout(() => {
            // this.isLoading = false;
            this.toastService.success('Filter saved successfully');
            this.isInputVisible = false;
            this.isDuplicateSaveButtonVisible = false;
            this.isEditFormVisible = false;
            this.standardViewService.getStandardViewFilterByFormPageId(this.columnData.pageId);
            this.standardViewService.$getStandardViewFilter.subscribe(result =>{
              if(result){
                   this.standardViewList  = [];
                this.standardViewList = result.result;
              }
            });

          }, 2000);
        },
        (error) => {
          console.error('Error:', error);
          this.isLoading = false;
        }
      );

  }

  // setAsDefaultView(id: number): void {
  //   //this.loadingIndicatorService.show();
  //   this.isLoading = true;
  //   this.standardViewService.getDataId(id).subscribe(
  //     (res: ApiResponseModel) => {
  //         this.standardFormFieldValues.id = res.result.id;
  //         this.standardFormFieldValues.globalId = res.result.globalId;
  //         this.standardFormFieldValues.name = res.result.name;
  //         this.standardFormFieldValues.isMarkAsPrivate = res.result.isMarkAsPrivate;
  //         this.standardFormFieldValues.isSetAsDefault = res.result.isSetAsDefault;
  //         this.standardFormFieldValues.formNameId = res.result.formNameId;
  //       if (!this.standardFormFieldValues['isSetAsDefault']) {
  //         const nameValue = this.standardFormFieldValues['name'];
  //         this.standardFormFieldValues.isSetAsDefault = true;
  //         this.standardViewService.updateDataView(this.standardFormFieldValues).subscribe(
  //           (updateRes: ApiResponseModel) => {
  //             if (updateRes.result.id === id) {
  //               res.result.isSetAsDefault = true;
  //             } else {
  //               res.result.isSetAsDefault = false;
  //             }


  //             console.log('Update successful:', updateRes.result);
  //             this.standardViewService.getStandardViewFilterByFormPageId(this.columnData.pageId);

  //             this.standardViewService.$getStandardViewFilter.subscribe(result =>{
  //               if(result){
  //                    this.standardViewList  = [];
  //                 this.standardViewList = result.result;
  //               }
  //             });
  //             setTimeout(() => {
  //               //this.loadingIndicatorService.hide();
  //               this.isLoading = false;
  //               this.toastService.success(`${nameValue} Set as Default View`);
  //             }, 2000);
  //           },
  //           (updateError) => {
  //             console.error('Update Error:', updateError);
  //             if (updateError.status === 500) {
  //               this.toastService.error('Internal server error. Please try again later.');
  //             } else {
  //               this.toastService.error('An error occurred during update. Please try again.');
  //             }
  //            // this.loadingIndicatorService.hide();
  //            this.isLoading = false;
  //           }
  //         );
  //       } else {
  //        // this.loadingIndicatorService.hide();
  //         this.isLoading = false;
  //       }
  //     },
  //     (error) => {
  //       console.error('Error:', error);
  //       if (error.status === 500) {
  //         this.toastService.error('Internal server error. Please try again later.');
  //       } else {
  //         this.toastService.error('An error occurred. Please try again.');
  //       }
  //       //this.loadingIndicatorService.hide();
  //       this.isLoading = false;
  //     }
  //   );
  // }


  setAsDefaultView1(filter: StandardViewResponse): void {
    // Find the already default view and set its isSetAsDefault to false
    const alreadyDefaultView = this.standardViewList.find(view => view.isSetAsDefault);
    if (alreadyDefaultView) {
      alreadyDefaultView.isSetAsDefault = false;
    }

    // Set the clicked filter as the default view
    filter.isSetAsDefault = true;

    // Update the UI by refreshing
    this.refreshUI();

    // Update the default view in the backend
    const setAsDefaultPayload = {
      globalId: filter.globalId,
      companyParameterId: filter.companyParameterId,
      isSetAsDefault: true,
      formNameId: filter.formNameId
    };

    // this.isLoading = true;
    this.standardViewService.updateDataView(setAsDefaultPayload).subscribe(
      () => {
        // this.isLoading = false;
        // Fetch updated data if necessary
        this.standardViewService.getStandardViewFilterByFormPageId(this.columnData.pageId);
      },
      (updateError) => {
        console.error('Update Error:', updateError);
        // this.isLoading = false;
        // Add error handling logic here, if needed
      }
    );
  }

  refreshUI(): void {
    // Trigger change detection to update the UI
    this.changeDetectorRef.detectChanges();
  }





  showEditFilterConfirm(filter: StandardViewResponse , type: string): void {
    if(type === 'DUPLICATE'){
      this.isEditFormVisible = true;
      this.confirmEdit(filter, type);
    }
    if(type === 'EDIT'){
      this.confirmModal = this.modal.confirm({
        nzTitle: 'Edit Saved Filters?',
        nzOkText: 'Edit',
        nzCancelText: 'Cancel',
        nzContent: 'Editing this filter will change it for it for everyone in your account',
        nzIconType: 'edit',
        nzCentered: true,

        nzCloseIcon: 'false',
        nzOnOk: () => {
          this.isEditFormVisible = true;
          this.confirmEdit(filter,type);
        },
      });
    }
  }

  confirmEdit(filter: StandardViewResponse, type: string) {
    this.isInputVisible = true;
    this.standardFormFieldValues['createStandardViewParameterRequests'] = [];

    if(type === 'EDIT'){
      this.standardFormFieldValues["globalId"] = filter.globalId;
      this.standardFormFieldValues["name"] = filter.name;
    }
    if(type === 'DUPLICATE'){
      this.standardFormFieldValues["globalId"] = '00000000-0000-0000-0000-000000000000';
      this.standardFormFieldValues["name"] = 'Copy Of '+ filter.name;
    }

    this.standardFormFieldValues["id"] = filter.id;
    this.standardFormFieldValues['isMarkAsPrivate'] = filter.isMarkAsPrivate;
    this.standardFormFieldValues['isSetAsDefault'] = filter.isSetAsDefault;
    this.standardFormFieldValues["formNameId"] = filter.formNameId;
    this.standardFormFieldValues["companyParameterId"] = 1;
    this.standardFormFieldValues["isNotDeleted"] = false;

        for(let item of filter.standardViewParameters){
          this.standardFormFieldValues['createStandardViewParameterRequests'].push(item.standardColumnSetupId.toString());
        }
}

  updateFilter(): void {
    const formData = this.standardFormFieldValues;
    let newArray = [];
    for (let node of this.columnNameOption) {
        if (formData.createStandardViewParameterRequests.includes(node.key)) {
            let matchingSelected = this.selectedCompareProperties.find(selectedFrom => selectedFrom.displayColumnName === node.title);

            if (matchingSelected) {
                newArray.push({
                    resizeColumnWidth: matchingSelected.resizeColumnWidth,
                    isPin: matchingSelected.isPin,
                    standardColumnSetupId: node.key
                });
            } else {
              newArray.push({
                resizeColumnWidth: "defaultSize",
                isPin: false,
                standardColumnSetupId: node.key
              });
            }
        }
    }
formData.createStandardViewParameterRequests = newArray;
//this.loadingIndicatorService.show();
this.isLoading = true;
this.standardViewService.postData(formData)
  .subscribe(
    (res: ResponseModelArray<StandardViewResponse>) => {
      this.standardViewResponse = res.result;
      setTimeout(() => {
        //this.loadingIndicatorService.hide();
        this.isLoading = false;
        this.toastService.success('Filter updated successfully');
        this.isInputVisible = false;
        this.isDuplicateSaveButtonVisible = false;
        this.isEditFormVisible = false;
        this.standardViewService.getStandardViewFilterByFormPageId(this.columnData.pageId);
        this.standardViewService.$getStandardViewFilter.subscribe(result =>{
          if(result){
               this.standardViewList  = [];
            this.standardViewList = result.result;
          }
        });

      }, 2000);
    },
    (error) => {
      console.error('Error:', error);
    //  this.loadingIndicatorService.hide();
      this.isLoading = false;
    }
  );
  }

  openEditForm(): void {
    this.isInputVisible = true;

  }

  closeEditForm(): void {
    this.isInputVisible = false;
    this.isEditFormVisible = true;
    this.isDuplicateSaveButtonVisible = true;

  }

  deleteFilter(globalId: string) {
    this.loadingIndicatorService.show();
    this.isLoading = true;
    this.standardViewService.deleteData(globalId)
      .pipe(
        delay(2000),
        finalize(() => this.loadingIndicatorService.hide())
      )
      .subscribe(
        (res: ApiResponseModel) => {
          console.log(res.result);
          this.toastService.success('Filter Delete successfully');
          this.dataUpdateService.triggerDropdownUpdate();
          this.isDuplicateSaveButtonVisible = false;
          this.isEditFormVisible = false;
          const index = this.standardViewList.findIndex(x => x.globalId === globalId);
          if (index !== -1) {
            this.standardViewList.splice(index, 1);
          }
          this.standardViewService.getStandardViewFilterByFormPageId(this.columnData.pageId);
        },
        (error) => {
          console.error('Error:', error);
          if (error.status === 500) {
            this.toastService.error('Internal server error. Please try again later.');
          } else {
            this.toastService.error('An error occurred. Please try again.');
          }
        }
      );
  }

  showDeleteFilterConfirm(globalId: string): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete all schedule item on this Job?',
      nzOkDanger: true,
      nzOkText: 'Delete',
      nzCancelText: 'Cancel',
      nzContent: 'Deleting this filter will remove it for everyone in your account',
      nzIconType: 'delete',
      nzCloseIcon: 'false',
      nzCentered: true,
      nzOnOk: () => {
        this.deleteFilter(globalId);
      },
    });
  }


  toggleVisibility() {
    this.isInputVisible = true;
    this.initStandardForm();
    let selectedNames = this.columnData.column
    .filter(x => this.fixedColumns.includes(x.displayColumnName))
    .map(x => x.id.toString());
      this.standardFormFieldValues['createStandardViewParameterRequests'] = selectedNames;
  }

  closeManageSavedViews() {
    this.isInputVisible = false;
    this.isEditFormVisible = false;
  }

  handleManageSavedViews() {
    this.cancel.emit();
  }


  applyView(){ 
    let formData = this.standardFormFieldValues;
    let selectedFilterNo : number = -1;
    let selectedFilter = this.selectedStandardView;
    let userSelectedStandardView = this.standardViewList.find(x => x.id === selectedFilter);
    let isSelectedFilterStandard = this.standardViewList.find(x => x.id === selectedFilter && x.isNotDeleted === true);
    if(isSelectedFilterStandard && isSelectedFilterStandard != undefined ){
      selectedFilterNo = 1;
      formData = null;
    }
     else{
     // formData = null;
      selectedFilterNo = 0;

      this.standardFormFieldValues["globalId"] = userSelectedStandardView.globalId;
      this.standardFormFieldValues["name"] = userSelectedStandardView.name;

      this.standardFormFieldValues["id"] = userSelectedStandardView.id;
      this.standardFormFieldValues['isMarkAsPrivate'] = userSelectedStandardView.isMarkAsPrivate;
      this.standardFormFieldValues['isSetAsDefault'] = userSelectedStandardView.isSetAsDefault;
      this.standardFormFieldValues["formNameId"] = userSelectedStandardView.formNameId;
      this.standardFormFieldValues["companyParameterId"] = 1;
      this.standardFormFieldValues["isNotDeleted"] = false;
      console.log("VIew Id",userSelectedStandardView.id);



      let newArray = [];
        for (let node of this.columnNameOption) { 
            if (this.selectedColumnName.includes(node.title)) {
                let matchingSelected = this.selectedCompareProperties.find(selectedFrom => selectedFrom.displayColumnName === node.title);

                if (matchingSelected) {
                    newArray.push({
                        resizeColumnWidth: matchingSelected.resizeColumnWidth,
                        isPin: matchingSelected.isPin,
                        standardColumnSetupId: node.key
                    });
                } else {
                  newArray.push({
                    resizeColumnWidth: 180,
                    isPin: false,
                    standardColumnSetupId: node.key
                  });
                }
            }
        }
        formData.createStandardViewParameterRequests = newArray;
           
        console.log('After Clicking view',this.standardFormFieldValues);
        
    }

    
      this.standardViewService.sendDisplaySelectedColApply(this.selectedColumnName , selectedFilterNo, formData);

      setTimeout(() => {
        this.onCancel();
      }, 10);

  }

  onDropdownChange() {
    // Enable button when a new value is selected
    this.buttonDisabled = false;
  }

}
