import { Component } from '@angular/core';
interface DataItem {
  name: string;
  chinese: number;
  math: number;
  english: number;
  colSpan?: number;
}
@Component({
  selector: 'job-access',
  templateUrl: './job-access.component.html',
  styleUrls: ['./job-access.component.css']
})
export class JobAccessComponent {
  listOfData: DataItem[] = [
    {
      name: 'ZH-212 Lot 5819 Ohagan Way Truganina VIC 3029', 
      chinese: 98,
      math: 60,
      english: 70
    },
    {
      name: 'ZH-211 Lot 1909 Cosgrove Street, Tarneit VIC 3029',
      chinese: 98,
      math: 66,
      english: 89
    },
    {
      name: 'Joe Black Joe Black Joe Black Joe Black Joe Black Joe Black Joe Black',
      chinese: 98,
      math: 90,
      english: 70
    },
    {
      name: 'ZH-209 Lot 1928 Arnison Road Tarneit 3029 VIC',
      chinese: 88,
      math: 99,
      english: 89
    }
  ];

  listOfColumn = [
    {
      title: 'Job Name',
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
      priority: false
    },
    {
      title: 'Job Status',
      compare: (a: DataItem, b: DataItem) => a.chinese - b.chinese,
      priority: 3
    },
    {
      title: 'Group',
      compare: (a: DataItem, b: DataItem) => a.math - b.math,
      priority: 2
    },
    {
      title: 'Date Opened',
      compare: (a: DataItem, b: DataItem) => a.english - b.english,
      priority: 1
    }
  ];


  


  
}
