import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldDataType } from 'src/app/Core/Enums/data_types';
import { AddNewStandardFilterComponent } from '../../Modal/add-new-standard-filter/add-new-standard-filter.component';
import { CreateLookupFilterRequest, FilterSearchParameter, LookUpStandardResponse, LookupFilter, LookupFilterResponse, LookupNameSetupResponse } from '../../Models/LookupModels';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { ProjectManagementWarrantyPreDefinedCodes } from '../../Models/LookUpStandardFilterCodes';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
import { WarrantyService } from 'src/Service/Project-Management/warranty/warranty.service';

@Component({
  selector: 'app-project-management-warranty-filter-field',
  templateUrl: './project-management-warranty-filter-field.component.html',
  styleUrls: ['./project-management-warranty-filter-field.component.css']
})
export class ProjectManagementWarrantyFilterFieldComponent {
  nodes: NzTreeNodeOptions[] = [
    {
      title: 'Check All',
      value: '0-all',
      key: '0-all',
      isLeaf: true,
      checked: false
    },
    {
      title: 'Node1',
      value: '0-0',
      key: '0-0',
      isLeaf: true
    },
    {
      title: 'Node2',
      value: '0-1',
      key: '0-1',
      isLeaf: true
    },
    {
      title: 'Node3',
      value: '0-2',
      key: '0-2',
      isLeaf: true
    },
    {
      title: 'Node4',
      value: '0-3',
      key: '0-3',
      isLeaf: true
    },
    {
      title: 'Node5',
      value: '0-4',
      key: '0-4',
      isLeaf: true
    },
    {
      title: 'Node6',
      value: '0-5',
      key: '0-5',
      isLeaf: true
    },
  ];
  value: string[] = ['0-0-0'];
  selectedValue = null;
  @Input() pageId: number;
  projectmanagementTimeclockFilterForm: FormGroup;
  createLookupFilterRequests: CreateLookupFilterRequest[] = [];
  AssignedTo: NzTreeNodeOptions[] = [];
  SubvendorAssginTo: NzTreeNodeOptions[] = [];

  filterResponseData: LookupFilterResponse[] = [];
  lookUpStandardResponse: LookUpStandardResponse[] = [];
  defaultStandardFilter: LookupFilterResponse;
  predefinedValuesMultiSelect: string[] = [];
  roles: { id: string, name: string }[] = [];
  StatusNodes: NzTreeNodeOptions[] = [];
  classificationNodes: NzTreeNodeOptions[] = [];
  AssignDatesNodes: NzTreeNodeOptions[] = [];
  categoryOptions: { label: string, value: number }[] = [];
  priorityOptions: { label: string, value: number }[] = [];
  coordinatorOptions: { label: string, value: number }[] = [];
  ScheduledDateOptions: { label: string, value: number }[] = [];
  AddedDateOptions: { label: string, value: number }[] = [];
  AddedByOptions: { label: string, value: number }[] = [];
  LookupNameSetupResponse: LookupNameSetupResponse[] = [];
  formFieldValues: any = {};
  selectedFilter: number = -1;
  selectedJobId: number = 0;
  preDefinedDataCodes = {
    standardFilter: ProjectManagementWarrantyPreDefinedCodes.standardFilter,
    Search: ProjectManagementWarrantyPreDefinedCodes.Search,
    Status: ProjectManagementWarrantyPreDefinedCodes.Status,
    Classifications: ProjectManagementWarrantyPreDefinedCodes.Classifications,
    Category: ProjectManagementWarrantyPreDefinedCodes.Category,
    Priority: ProjectManagementWarrantyPreDefinedCodes.Priority,
    Coordinator: ProjectManagementWarrantyPreDefinedCodes.Coordinator,
    AssignedTo: ProjectManagementWarrantyPreDefinedCodes.AssignedTo,
    ScheduledDate: ProjectManagementWarrantyPreDefinedCodes.ScheduledDate,
    AddedDate: ProjectManagementWarrantyPreDefinedCodes.AddedDate,
    AddedBy: ProjectManagementWarrantyPreDefinedCodes.AddedBy,
  };

  constructor(
    public toastService: NzMessageService,
    private modal: NzModalService,
    private LookupFilterService: LookupFilterService,
    private customerInformationService: CustomerInformationService,
    private internalUserService: InternalUserService,
    public leadOpportunities: LeadOpportunitiesService,
    private accessLocalStorageService : AccessLocalStorageService,
    private fb: FormBuilder,
    private lookUpFilterService: LookupFilterService,
    private loadingIndicatorService: LoadingIndicatorService,
    private SubVendorService: SubVendorService,
    private WarrantyService: WarrantyService,




  ) { }
  ngOnInit(): void {
    this.initLoad();
    this.ForCombo();
    this.ForUbvendor();
    this.initEditSaveFilter();
    this.selectedJobId = this.accessLocalStorageService.getJobId();

  }

  initializeForm(): void {
    this.formFieldValues = {
      standardFilter: this.getDefaultFilterValue(),
      Search: [],
      status: [],
      classifications: [] = [],
      category: [],
      priority: [],
      coordinator: [],
      assignedTo: [] = [],
      originalVendor: [] = [],
      scheduledDate: [],
      addedDate: [],
      addedBy: [],
    };
  }

  onFilterApply(): void {
    let selectedFilter = this.formFieldValues['standardFilter'];
    let isSelectedFilterStandard = this.filterResponseData.find(x => x.id === selectedFilter && x.isNotDeleted === true);
    if (isSelectedFilterStandard && isSelectedFilterStandard != undefined) {
      this.selectedFilter = 1;
    }
    else {
      this.selectedFilter = 0;
    }
    this.createFormFieldsInArray();
    this.createLookupFilterRequests[0].page = 1;
    this.createLookupFilterRequests[0].pageSize = 10;

    this.WarrantyService.getAppliedFilterData(this.createLookupFilterRequests);


  }



  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(res => {
      let StatusData = res.result.filter(x => x.code === this.preDefinedDataCodes.Status.code);
      let ClassificationsData = res.result.filter(x => x.code === this.preDefinedDataCodes.Classifications.code);
      let AssignedToData = res.result.filter(x => x.code === this.preDefinedDataCodes.AssignedTo.code);
      let Categorydata = res.result.filter(x => x.code === this.preDefinedDataCodes.Category.code);
      let PriorityData = res.result.filter(x => x.code === this.preDefinedDataCodes.Priority.code);
      let CoordinatorData = res.result.filter(x => x.code === this.preDefinedDataCodes.Coordinator.code);
      let ScheduledDatedata = res.result.filter(x => x.code === this.preDefinedDataCodes.ScheduledDate.code);
      let AddedDateData = res.result.filter(x => x.code === this.preDefinedDataCodes.AddedDate.code);
      let AddedBydata = res.result.filter(x => x.code === this.preDefinedDataCodes.AddedBy.code);
      console.log("all data", res.result);


        // status//
      this.StatusNodes = [
             {
              title: 'Select all',
              value: 'select_all',
              key: 'select_all',
              selectable: false,
              isLeaf: false,
              expanded: true,
              children: StatusData.map((status) => ({
               title: status.name,
               value: status.id.toString(),
               key: status.id.toString(),
               isLeaf: true
              }))
             }
            ];

      // classification//
      this.classificationNodes = [
             {
              title: 'Select all',
              value: 'select_all',
              key: 'select_all',
              selectable: false,
              isLeaf: false,
              expanded: true,
              children: ClassificationsData.map((status) => ({
               title: status.name,
               value: status.id.toString(),
               key: status.id.toString(),
               isLeaf: true
              }))
             }
            ];

       //Assignto //
       this.AssignDatesNodes = [
        {
         title: 'Select all',
         value: 'select_all',
         key: 'select_all',
         selectable: false,
         isLeaf: false,
         expanded: true,
         children: AssignedToData.map((status) => ({
          title: status.name,
          value: status.id.toString(),
          key: status.id.toString(),
          isLeaf: true
         }))
        }
       ];

      this.categoryOptions = Categorydata.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));
      this.priorityOptions = PriorityData.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));
      this.coordinatorOptions = CoordinatorData.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));
      this.ScheduledDateOptions = ScheduledDatedata.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));
      this.AddedDateOptions = AddedDateData.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));
      this.AddedByOptions = AddedBydata.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));
    })

    const lookupFilter: LookupFilter = {
      formNameId: this.pageId,
    };
    this.getStandardFilterByFormPageId(this.pageId);
    this.fetchData(lookupFilter);
  }


  fetchData(lookUpStandard: LookupFilter): void {
    this.LookupFilterService.postData(lookUpStandard).subscribe(
      data => {
        this.lookUpStandardResponse = data.result;
      },
      error => {
        console.error('Error fetching data:', error);
      }
    );
  }
  // status//
  onstatusChange(selectedValues: string[]): void {
      const checkAllIndex = selectedValues.indexOf('select_all');
      if (checkAllIndex !== -1) {
       const allValues = this.StatusNodes[0].children.map(child => child['value']);
       this.formFieldValues['status'] = [...allValues];
      } else {
       const allValues = this.StatusNodes[0].children.map(child => child['value']);
       this.formFieldValues['status'] = selectedValues.filter(value => allValues.includes(value));
      }
     }
       // classifications//
  onclassificationsChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
     const allValues = this.classificationNodes[0].children.map(child => child['value']);
     this.formFieldValues['classifications'] = [...allValues];
    } else {
     const allValues = this.classificationNodes[0].children.map(child => child['value']);
     this.formFieldValues['classifications'] = selectedValues.filter(value => allValues.includes(value));
    }
   }
         //onAssignedTo //
         onAssignedTosChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
     const allValues = this.AssignDatesNodes[0].children.map(child => child['value']);
     this.formFieldValues['assignedTo'] = [...allValues];
    } else {
     const allValues = this.AssignDatesNodes[0].children.map(child => child['value']);
     this.formFieldValues['assignedTo'] = selectedValues.filter(value => allValues.includes(value));
    }
   }
  openStandardFilterComponent(type: string) {
    this.createLookupFilterRequests = [];
    this.createFormFieldsInArray();
    const modalRef = this.modal.create({
      nzContent: AddNewStandardFilterComponent,
      nzFooter: null,
      nzData:
      {
        pageId: this.pageId,
        std: this.filterResponseData,
        field: this.createLookupFilterRequests,
        clickFrom: type
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }



  getStandardFilterByFormPageId(pageId: number) {
    this.lookUpFilterService.getStandardFilterByFormPageId(pageId);
    this.lookUpFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.filterResponseData = result.result;
        this.initializeForm();
        setTimeout(() => {
          let id = this.accessLocalStorageService.getSelectedFilterId();
          this?.setDefaultFieldValues(id);
        }, 100);

      }

    });
  }


  isFormDirty: boolean = false;


  resetFilter(): void {
    Object.keys(this.formFieldValues).forEach(field => {
      if (field !== 'standardFilter') {
        if (Array.isArray(this.formFieldValues[field])) {
          this.formFieldValues[field] = [];
        } else {
          this.formFieldValues[field] = '';
        }
      }
    });
    this.selectedFilter = -1;
  }

  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
    this.isFormDirty = false;
  }


  createFormFieldsInArray() {
    this.createLookupFilterRequests = [];
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const value = this.formFieldValues[field];
      if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 0)) {
        const newRow = {
          globalId: '00000000-0000-0000-0000-000000000000',
          companyParameterId: 0,
          lookUpStandardId: 0,
          code: code,
          meaning: '',
          valueId: 0,
          formNameId: this.pageId,
          dataTypeId: 0,
          createLookupFilterParameterRequests: [null]
        };

        const correspondingResponse = this.lookUpStandardResponse.find(response => response.code === code);
        if (correspondingResponse) {
          newRow.companyParameterId = correspondingResponse.companyParameterId;
          newRow.lookUpStandardId = correspondingResponse.id;
          newRow.dataTypeId = correspondingResponse.dataTypeId;

          if (
            correspondingResponse.dataType.code === FieldDataType.SingleLineText ||
            correspondingResponse.dataType.name === 'Single-Line Text') {
            newRow.meaning = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.Dropdown ||
            correspondingResponse.dataType.name === 'Dropdown') {
            newRow.valueId = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.MultiSelectDropdown ||
            correspondingResponse.dataType.name === 'Multi-Select Dropdown'
          ) {
            newRow.createLookupFilterParameterRequests = value?.map((item, index) => {
              return { valueId: item };
            });
          }
        }
        this.createLookupFilterRequests.push(newRow);
      }
    });
  }


  getDefaultFilterValue() {
    // this.defaultStandardFilter = this.filterResponseData.find(filter => filter.isSetAsDefault === true);
    // return this.defaultStandardFilter ? this.defaultStandardFilter.id : null;
     return this.accessLocalStorageService.getSelectedFilterId();
   }



  // setDefaultFieldValues(standardFilterId: number): void {
  //   this.predefinedValuesMultiSelect = [];
  //   let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
  //   let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;
  //   // Get all form control keys except the first one
  //   const formControlKeys = Object.keys(this.projectmanagementTimeclockFilterForm.controls).slice(1);
  //   // Reset all form controls except the first one
  //   formControlKeys.forEach(field => {
  //     this.projectmanagementTimeclockFilterForm.get(field).reset(); // Reset the form control
  //   });
  //   Object.keys(this.preDefinedDataCodes).forEach(field => {
  //     const code = this.preDefinedDataCodes[field].code;
  //     const fieldData = lookUpFiltersField.find(filter => filter.code === code);
  //     if (fieldData != undefined) {
  //       if (
  //         fieldData.dataType.code === FieldDataType.SingleLineText) {
  //         this.projectmanagementTimeclockFilterForm.get(field).setValue(fieldData.meaning);
  //       }
  //       if (fieldData.dataType.code === FieldDataType.Dropdown) {
  //         this.projectmanagementTimeclockFilterForm.get(field).setValue(fieldData.id);
  //       }
  //       if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown) {
  //         let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
  //         this.predefinedValuesMultiSelect = multiSelectValues.map(String);
  //         this.projectmanagementTimeclockFilterForm.get(field).setValue(this.predefinedValuesMultiSelect);
  //       }
  //     }
  //   });
  // }

  setDefaultFieldValues(standardFilterId: number): void {
    this.predefinedValuesMultiSelect = [];
    let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
    let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;

    Object.keys(this.formFieldValues).forEach(field => {
      if (field !== 'standardFilter') {
        if (Array.isArray(this.formFieldValues[field])) {
          this.formFieldValues[field] = [];
        } else {
          this.formFieldValues[field] = '';
        }
      }
    });


    if (lookUpFiltersField.length > 0) {
      Object.keys(this.preDefinedDataCodes).forEach(field => {
        const code = this.preDefinedDataCodes[field].code;
        const fieldData = lookUpFiltersField.find(filter => filter.code === code);
        if (fieldData != undefined) {
          if (
            fieldData.dataType.code === FieldDataType.SingleLineText) {
            this.formFieldValues[field] = fieldData.meaning;
          }
          if (fieldData.dataType.code === FieldDataType.Dropdown) {
            this.formFieldValues[field] = fieldData.id;
          }
          if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown) {
            let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
            this.predefinedValuesMultiSelect = multiSelectValues.map(String);
            this.formFieldValues[field] = this.predefinedValuesMultiSelect;
          }
        }
      });
    } else {
      console.log("lookUpFiltersField is null ", lookUpFiltersField);
    }
  }


  filterSearchParameter: FilterSearchParameter;


  onChangeStandardFilter($event: number): void {
    this.selectedFilter = -1;
    const defaultFIlterId = this.getDefaultFilterValue();
    this.accessLocalStorageService.setSelectedFilterId($event);
    // New Changes By Aamir Ali - 22-Apr2024
    let id = this.accessLocalStorageService.getSelectedFilterId();
    this.setDefaultFieldValues(id);
    this.internalUserService.setSelectedFilterId(id);
    this.filterSearchParameter = {
      CompanyParameterId: 1,
      FormNameId: this.pageId,
      LookUpFilterId: $event,
      page: 1,
      pageSize: 10
    };
    if (defaultFIlterId !== $event) {
      this.WarrantyService.userAppliedFilter(true);
    } else {
      this.WarrantyService.userAppliedFilter(false);
    }
    this.WarrantyService.getAllWarrantyByFilter(this.filterSearchParameter);

  }

  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe(res => {
      const AssignedToData = res.result;
      console.log("dataforcombo", AssignedToData);

      this.AssignedTo = AssignedToData.map(LOP => ({
        title: LOP.fullName,
        key: LOP.id.toString(),
        isLeaf: true,
      }));
    });

  }
  ForUbvendor() {
    this.SubVendorService.getData().subscribe(res => {
      const SubvendorAssgin = res.result;
      console.log("dataforcombo", SubvendorAssgin);

      this.SubvendorAssginTo = [
        {
         title: 'Select all',
         value: 'select_all',
         key: 'select_all',
         selectable: false,
         isLeaf: false,
         expanded: true,
         children: SubvendorAssgin.map((LOP) => ({
          title: LOP.companyName,
          value: LOP.id.toString(),
          key: LOP.id.toString(),
          isLeaf: true
         }))
        }
       ];
    });

  }

  onSubsVendorChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
     const allValues = this.SubvendorAssginTo[0].children.map(child => child['value']);
     this.formFieldValues['originalVendor'] = [...allValues];
    } else {
     const allValues = this.SubvendorAssginTo[0].children.map(child => child['value']);
     this.formFieldValues['originalVendor'] = selectedValues.filter(value => allValues.includes(value));
    }
   }






  editSaveFilterFormGroup: FormGroup;


  private initEditSaveFilter(): void {
    this.editSaveFilterFormGroup = this.fb.group({
      globalId: ['00000000-0000-0000-0000-000000000000'],
      companyParameterId: [0],
      code: ['', Validators.required],
      name: ['', Validators.required],
      description: [''],
      meaning: [''],
      formNameId: [0],
      dataTypeId: [],
      isShareThisFilter: [true],
      isSetAsDefault: [false],
      createLookupFilterRequests: [[]],
    });
  }

  editSaveFilterData(): void {
    let selected_Filter_Id = this.formFieldValues['standardFilter'];
    let getSelectedFilterValue = this.filterResponseData.find(filter => filter.id === selected_Filter_Id);
    this.createFormFieldsInArray();
    this.editSaveFilterFormGroup.controls['name'].setValue(getSelectedFilterValue.name);

    if (this.editSaveFilterFormGroup.get('name')?.invalid) {
      this.toastService.error('Name field is required');
      return;
    }
    this.editSaveFilterFormGroup.controls['companyParameterId'].setValue(getSelectedFilterValue.companyParameterId);
    this.editSaveFilterFormGroup.controls['code'].setValue(getSelectedFilterValue.code);
    this.editSaveFilterFormGroup.controls['formNameId'].setValue(getSelectedFilterValue.formNameId);
    this.editSaveFilterFormGroup.controls['dataTypeId'].setValue(getSelectedFilterValue.dataTypeId);
    this.editSaveFilterFormGroup.controls['globalId'].setValue(getSelectedFilterValue.globalId);
    this.editSaveFilterFormGroup.controls['createLookupFilterRequests'].setValue(this.createLookupFilterRequests);
    this.editSaveFilterFormGroup.controls['isSetAsDefault'].setValue(getSelectedFilterValue.isSetAsDefault);
    this.lookUpFilterService.saveStandardFilterData(this.editSaveFilterFormGroup.value)
      .subscribe(
        (res: any) => {
          console.log(res.result);
          setTimeout(() => {
            this.loadingIndicatorService.hide();
            this.toastService.success('Filter saved successfully');
            this.getStandardFilterByFormPageId(this.pageId);
            this.selectedFilter = -1;
          }, 20);
        },
        (error) => {
          console.error('Error:', error);
          this.loadingIndicatorService.hide();
          if (error.status === 500) {
            this.toastService.error('Internal server error. Please try again later.');
          } else {
            this.toastService.error('An error occurred. Please try again.');
          }
        }
      );
    this.selectedFilter = -1;
  }


}

