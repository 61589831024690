<nz-layout>
  <nz-sider
    class="border"
    nzCollapsible
    [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed"
    [nzWidth]="280"
    [nzTrigger]="null"
  >
    <div class="row">
      <app-side-menu-bar
      [isAllJobOption]="true"
      (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"
      ></app-side-menu-bar>
    </div>
  </nz-sider>
 
 <nz-layout>
    <nz-page-header>
      <nz-page-header-title>
        <nz-breadcrumb class="text-start" nz-page-header-breadcrumb>
          <nz-breadcrumb-item>Yousuf Test 1 </nz-breadcrumb-item>
        </nz-breadcrumb>
        <h1>
          Notification History
        </h1>
      </nz-page-header-title>
      <nz-page-header-extra>
        <nz-space>
          <button nz-button nzType="default" class="rounded-1  me-2  ">Export</button>
          <button nz-button nzType="default" (click)="openFilter(Messaging_Notification_History ,PAGE_ID)" class="rounded-1"><i  
            class="bi bi-funnel-fill fs-6 me-1"></i>Filter</button>
        </nz-space>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-content class="custom-content">
      <app-notification-history-content></app-notification-history-content>
    </nz-content>
  </nz-layout>
  </nz-layout>