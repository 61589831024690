<nz-modal nzWidth="95%" [(nzVisible)]="newRFIsvisible" nzClosable="true" (nzOnCancel)="closebiils()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '600px', 'overflow-y': 'auto' }"
    [nzStyle]="{ top: '15px' }" [nzFooter]="modalFooter" [nzTitle]="rfis" (nzOnCancel)="newcRFIsCancel()"
    (nzOnOk)="handleOk()">
    <ng-template #rfis>
        <nz-page-header class="mb-0 p-0">
            <nz-breadcrumb nz-page-header-breadcrumb>
                <nz-breadcrumb-item>{{ DataRfis?.DataRfis }}</nz-breadcrumb-item>
                <nz-breadcrumb-item>{{ localStorage.getJobName()}}</nz-breadcrumb-item>
            </nz-breadcrumb>

            <nz-breadcrumb nz-page-header-breadcrumb>
                <nz-breadcrumb-item>{{ nzData?.nzData }}</nz-breadcrumb-item>
            </nz-breadcrumb>

            <nz-breadcrumb nz-page-header-breadcrumb>
                <nz-breadcrumb-item>{{ nzData?.nzData }}</nz-breadcrumb-item>
            </nz-breadcrumb>

            <div *ngIf="localStorage.getJobName()" class="ListViewJobname p-0 m-0">{{localStorage.getJobName()}} </div>


        </nz-page-header>
        <h2>RFI</h2>
    </ng-template>
    <form>

        <ng-container *nzModalContent>
            <div class="row">
                <div class="col-12">
                    <nz-card nzTitle="RFI Information">
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-12">
                                        <label class="sub-text">Title</label>
                                        <input [(ngModel)]="formFieldValues.title" name="title" pInputText type="text"
                                            class="radius-work p-inputtext-sm form-control  shadow-none"
                                            style="height: 30px; border-top-left-radius: 0; border-bottom-left-radius: 0;">

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <label class="sub-text">ID#</label>
                                        <nz-input-group nzAddOnBefore="ZH-208LIS">
                                            <input formControlName="iD" type="text" nz-input
                                                placeholder="(Auto Assign #)" />
                                        </nz-input-group>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <label class="sub-text">Assigned</label>
                                        <!-- <nz-select nzShowSearch [lang]="true"
                                            [(ngModel)]="formFieldValues['assigneeUserId']" name="assigneeUserId"
                                            nzAutoFocus="true" nzPlaceHolder="--None--">
                                            <nz-option *ngFor="let option of assignedUser" [nzLabel]="option.label"
                                                [nzValue]="option.value"></nz-option>
                                        </nz-select> -->
                                        <div class="row">
                                            <div class="col-12">
                                                <!-- <nz-select nzShowSearch [lang]="true"
                                                    [(ngModel)]="formFieldValues['assigneeUserId']"
                                                    name="assigneeUserId" nzAutoFocus="true" nzPlaceHolder="--None--"
                                                    [(ngModel)]="formFieldValues['assigneeSubVendorId']"
                                                    name="assigneeSubVendorId" nzAutoFocus="true" nzPlaceHolder="--None--"
                                                    (ngModelChange)="onUserGroupChange($event)">
                                                    <optgroup label="Option Group 1">
                                                        <nz-option *ngFor="let option of assignedUserGroup1"
                                                            [nzLabel]="option.label"
                                                            [nzValue]="option.value"></nz-option>
                                                    </optgroup>
                                                    <optgroup label="Option Group 2">
                                                        <nz-option *ngFor="let option of assignedUserGroup2"
                                                            [nzLabel]="option.label"
                                                            [nzValue]="option.value"></nz-option>
                                                    </optgroup>
                                                </nz-select> -->
                                                <nz-select nzShowSearch [lang]="true" [(ngModel)]="selectedAssignee"
                                                    name="assignee" nzAutoFocus="true" nzPlaceHolder="--None--"
                                                    (ngModelChange)="onAssigneeChange($event)">
                                                    <optgroup label="Option Group 1">
                                                        <nz-option *ngFor="let option of assignedUserGroup1"
                                                            [nzLabel]="option.label"
                                                            [nzValue]="{ assigneeUserId: option.value, assigneeSubVendorId: null }"></nz-option>
                                                    </optgroup>
                                                    <optgroup label="Option Group 2">
                                                        <nz-option *ngFor="let option of assignedUserGroup2"
                                                            [nzLabel]="option.label"
                                                            [nzValue]="{ assigneeUserId: null, assigneeSubVendorId: option.value }"></nz-option>
                                                    </optgroup>
                                                </nz-select>


                                            </div>
                                        </div>



                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <label class="sub-text">Viewers</label>
                                        <!-- <nz-tree-select style="width: 100%"
                                            [(ngModel)]="formFieldValues['assigneeSubVendorId']"
                                            name="assigneeSubVendorId" [nzNodes]="subVendorData" nzShowSearch
                                            nzAllowClear="false" nzCheckable nzCheckAll="true"
                                            nzPlaceHolder="Select Status">
                                        </nz-tree-select> -->
                                        <nz-tree-select [nzNodes]="subVendorsNods"
                                            [(ngModel)]="formFieldValues.createRFIViewerParameterRequests"
                                            name="createRFIViewerParameterRequests" nzAllowClear="false" nzCheckable
                                            nzCheckAll="true" (ngModelChange)="onNodeSelectionChange($event)">
                                        </nz-tree-select>
                                    </div>
                                    
                                </div>

                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-12">
                                        <label class="sub-text">Due Date</label><br>
                                        <nz-button-group>
                                            <button [(ngModel)]="formFieldValues['isChooseDate']" name="isChooseDate"
                                                nz-button nzType="default" (click)="toggleScheduleItemFieldss()">Choose
                                                Date</button>
                                            <button [(ngModel)]="formFieldValues['isLinkToScheduleItem']"
                                                name="isLinkToScheduleItem" nz-button nzType="default"
                                                (click)="toggleScheduleItemFields()"> Link to Schedule Items</button>
                                        </nz-button-group>
                                    </div>
                                </div>

                                <!-- form -->
                                <div class="row mt-4" *ngIf="showScheduleItemFields">
                                    <div class="col-9">
                                        <div class="input-group">
                                            <div class="input-group">
                                                <nz-select [(ngModel)]="formFieldValues['scheduleItemParameterId']"
                                                    name="scheduleItemParameterId" style="width: 100%" nzShowSearch
                                                    [lang]="true" nzAutoFocus="true" nzPlaceHolder="Unassigned"
                                                    [(ngModel)]="selectedValue">
                                                    <nz-option *ngFor="let option of scheduleItemOption"
                                                        [nzLabel]="option.title" [nzValue]="option.id"></nz-option>
                                                </nz-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3" style="text-align: left;">
                                        <nz-space>
                                            <button aria-disabled="false" type="button"
                                                class="ant-btn ant-btn-link BTButton isolated AutoSizing "
                                                style="height: 29px;">
                                                <span class="ms-2">Add</span>
                                            </button>
                                            <button aria-disabled="false" type="button"
                                                class="ant-btn ant-btn-link BTButton isolated AutoSizing p-0">
                                                <span style="opacity: 60%;">Edit</span>
                                            </button>

                                        </nz-space>
                                    </div>
                                </div>



                                <div class="row  mt-3" *ngIf="showScheduleItemFields">
                                    <div class="col-2">
                                        <label class=" fw-bold">Due</label>
                                        <input [(ngModel)]="formFieldValues['dueNumberDays']" name="dueNumberDays"
                                            type="text" class="form-control formField" placeholder="0">
                                    </div>
                                    <div class="col-5" style="margin-top: 27px;">
                                        <label class=" fw-medium me-3" style="margin-left: -10px;">Days</label>
                                        <nz-radio-group [(ngModel)]="radioValue" nzName="radiogroup">
                                            <label [(ngModel)]="formFieldValues['before']" name="before" nz-radio
                                                nzValue="A">Before</label>
                                            <label [(ngModel)]="formFieldValues['after']" name="after" nz-radio
                                                nzValue="B" style="margin-left: -5px;">After</label>
                                        </nz-radio-group>
                                    </div>
                                </div>


                                <!-- form -->
                                <div class="row">
                                    <div class="col-4">
                                        <label class="sub-text fs-6">Due </label>
                                        <span class="text-danger fw-bold">*</span><br class="fs-6">
                                        <nz-date-picker [(ngModel)]="formFieldValues.dueOn" name="dueOn"
                                            [nzPlaceHolder]="' '" nzSuffixIcon="false"></nz-date-picker>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <label class="sub-text">Reminder</label>
                                        <nz-select nzShowSearch [(ngModel)]="formFieldValues['reminderSetupId']"
                                            name="reminderSetupId">
                                            <nz-option [nzLabel]="'Non'" [nzValue]="0"></nz-option>
                                            <nz-option *ngFor="let option of reminder" [nzLabel]="option.name"
                                                [nzValue]="option.id"></nz-option>
                                        </nz-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <label class="sub-text mt-4">Related</label><br>

                                        <span style="color: blue;">
                                            <i class="bi bi-plus-circle-fill" (click)="Add()"></i> <button nz-button
                                                nzType="text" style="color: blue;" (click)="Add()">Add a related
                                                item</button>
                                        </span>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </nz-card>
                </div>
            </div>

            <nz-card *ngIf="!isEditCardVisible" nzTitle="Question" class="mt-3">
                <div class="row">
                    <div class="col">
                        <label class="sub-text">Question</label>
                        <span class="text-danger fw-bold">*</span>
                        <textarea [(ngModel)]="formFieldValues['question']" name="question"
                            class="formField form-control" rows="4" nz-input [(ngModel)]="inputValue"></textarea>
                    </div>
                </div>

                <div class="row">
                    <label class="sub-text">Attechments</label>
                </div>
                <!-- <div class="row">
                <div class="col-3">
                    <button nz-button nzType="default" style="margin-right: 4px; white-space: nowrap;">Add</button>
                    <button nz-button nzType="default" style="white-space: nowrap;">Create new doc</button>
                </div>
            </div> -->

                <ng-template #extraTemplate>
                </ng-template>
                <nz-card nzBorderless="true" class="mt-2 rounded-1" [nzTitle]="'Attachments'" [nzExtra]="extraTemplate">
                    <ng-template #Attachments>
                        <h4 class="fw-medium famly-inn">Attachments</h4>
                    </ng-template>
                    <div class="d-flex align-items-center mt-2">
                        <!-- <button nz-button nzType="default" class="m-2" style="font-size: 12px;" (click)="openAddsModal()">Add</button>
                <button nz-button nzType="default" class="m-2" style="font-size: 12px;">Create New Doc</button> -->
                        <!-- <div class="clearfix">
                  <nz-upload
                    nzListType="picture-card"
                    [(nzFileList)]="fileList"
                    [nzShowButton]="fileList.length < 2 && !imageUploaded"
                    [nzMultiple]="true" 
                    showPreviewIcon="false"
                    showDownloadIcon="false"
                  >
                    <div *ngIf="!imageUploaded" >
                      <span nz-icon nzType="plus"></span>
                      <div style="margin-top: 8px">Upload</div>
                    </div>
                  </nz-upload>
                </div> -->
                    </div>
                    <div class="row">
                        <div class="col-5">
                            <button (click)="UploadFiles()" nz-button nzType="default"
                                class="rounded-1 me-2">Add</button>
                            <button (click)="CreateNewFile()" nz-button nzType="default" class="rounded-1 me-2">Create
                                New
                                File</button>
                            <button (click)="viewAll()" *ngIf="selectedFiles.length > 0" nz-button nzType="default"
                                class="rounded-1 me-2">View All ({{ selectedFiles.length }})</button>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-1 filUpload me-3" *ngFor="let file of selectedFiles">
                            <div class="row gx-0 mt-2 p-0 m-0">
                                <div class="col-12 mt-2">
                                    <span class="m-0 p-0d d-flex align-items-center justify-content-center"
                                        style="border-radius: 6px; object-fit: cover;" nz-icon nzType="file-text"
                                        nzTheme="outline"></span>
                                </div>
                            </div>
                            <div class="row p-0 m-0">
                                <div class="col-12">
                                    <label class="ListViewJobname">{{file.name}}</label>
                                </div>
                            </div>
                            <div class="row p-0 m-0">
                                <div class="col-12">
                                    <i nzTrigger="click" nzType="down" nz-dropdown [nzDropdownMenu]="menu4"
                                        class="fa-solid fa-chevron-down fw-bolder"></i>
                                    <nz-dropdown-menu #menu4="nzDropdownMenu">
                                        <ul nz-menu>
                                            <li nz-menu-item><i class="fa-solid fa-pen me-2"></i>Edit Online</li>
                                            <li (click)="removeFile(file)" nz-menu-item><i
                                                    class="fa-regular fa-trash-can me-2"></i>Delete</li>
                                        </ul>
                                    </nz-dropdown-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-card>
            </nz-card>


            <!-- After Edit Question -->
            <nz-card *ngIf="isSaved && !isEditCardVisible" nzTitle="Question" class="mt-3">
                <div class="row align-items-center">
                    <div class="col-auto">
                        <nz-avatar nzText="U"></nz-avatar>
                    </div>
                    <div class="col">
                        <!-- Display user name -->
                        <h3 class="mb-0">{{ formFieldValues?.assigneeUser?.fullName }}</h3>
                    </div>
                    <div class="col-auto">
                        <svg width="1em" height="1em" viewBox="0 0 1024 1024" fill="currentColor"
                            (click)="toggleEditCardVisibility()">
                            <path
                                d="M880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32zm-622.3-84c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 000-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 009.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9z">
                            </path>
                        </svg>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <!-- Display question -->
                        <h2>{{ formFieldValues.question }}</h2>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <!-- Display date and time -->
                        <p>{{ formFieldValues.createdOn | date: 'MMM d, yyyy, h:mm a' }}</p>
                    </div>
                </div>
            </nz-card>


            <!-- New Answer -->

            <nz-card *ngIf="isSaved" nzTitle="New Answer" class="mt-3">
                <div class="row">
                    <div class="col">
                        <label class="sub-text" for="">Reassign RFI to</label>
                        <div>
                            <nz-select nzShowSearch [lang]="true" [(ngModel)]="selectedAssignee" name="assignee"
                                nzAutoFocus="true" nzPlaceHolder="--None--" (ngModelChange)="onAssigneeChange($event)">
                                <optgroup label="Option Group 1">
                                    <nz-option *ngFor="let option of assignedUserGroup1" [nzLabel]="option.label"
                                        [nzValue]="{ assigneeUserId: option.value, assigneeSubVendorId: null }"></nz-option>
                                </optgroup>
                                <optgroup label="Option Group 2">
                                    <nz-option *ngFor="let option of assignedUserGroup2" [nzLabel]="option.label"
                                        [nzValue]="{ assigneeUserId: null, assigneeSubVendorId: option.value }"></nz-option>
                                </optgroup>
                            </nz-select>


                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="sub-text">Response</label>
                        <span class="text-danger fw-bold">*</span>
                        <textarea [(ngModel)]="formFieldValues['question']" name="question"
                            class="formField form-control" rows="4" nz-input [(ngModel)]="inputValue"></textarea>
                    </div>
                </div>

                <div class="row">
                    <label class="sub-text">Attechments</label>
                </div>


                <ng-template #extraTemplate>
                </ng-template>
                <nz-card nzBorderless="true" class="mt-2 rounded-1" [nzTitle]="'Attachments'" [nzExtra]="extraTemplate">
                    <ng-template #Attachments>
                        <h4 class="fw-medium famly-inn">Attachments</h4>
                    </ng-template>
                    <div class="d-flex align-items-center mt-2">
                    </div>
                    <div class="row">
                        <div class="col-5">
                            <button (click)="UploadFiles()" nz-button nzType="default"
                                class="rounded-1 me-2">Add</button>
                            <button (click)="CreateNewFile()" nz-button nzType="default" class="rounded-1 me-2">Create
                                New
                                File</button>
                            <button (click)="viewAll()" *ngIf="selectedFiles.length > 0" nz-button nzType="default"
                                class="rounded-1 me-2">View All ({{ selectedFiles.length }})</button>
                        </div>
                    </div>
                    <div class="row mt-5 text-end">
                        <div class="col">
                            <button (click)="saveAnswer(rfiInformationId)" nz-button nzType="default"
                                class="rounded-1 me-2">Save Answer</button>


                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-1 filUpload me-3" *ngFor="let file of selectedFiles">
                            <div class="row gx-0 mt-2 p-0 m-0">
                                <div class="col-12 mt-2">
                                    <span class="m-0 p-0d d-flex align-items-center justify-content-center"
                                        style="border-radius: 6px; object-fit: cover;" nz-icon nzType="file-text"
                                        nzTheme="outline"></span>
                                </div>
                            </div>
                            <div class="row p-0 m-0">
                                <div class="col-12">
                                    <label class="ListViewJobname">{{file.name}}</label>
                                </div>
                            </div>
                            <div class="row p-0 m-0">
                                <div class="col-12">
                                    <i nzTrigger="click" nzType="down" nz-dropdown [nzDropdownMenu]="menu4"
                                        class="fa-solid fa-chevron-down fw-bolder"></i>
                                    <nz-dropdown-menu #menu4="nzDropdownMenu">
                                        <ul nz-menu>
                                            <li nz-menu-item><i class="fa-solid fa-pen me-2"></i>Edit Online</li>
                                            <li (click)="removeFile(file)" nz-menu-item><i
                                                    class="fa-regular fa-trash-can me-2"></i>Delete</li>
                                        </ul>
                                    </nz-dropdown-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-card>
            </nz-card>


            <!-- table -->
            <nz-card *ngIf="isSaved" class="mt-3">
                <div class="card-body">
                    <!-- History Tag -->
                    <div class="row mt-3">
                        <div class="col">
                            <h2 (click)="toggleHistoryVisibility()">
                                <span nz-icon nzType="caret-right" class="ant-collapse-arrow"
                                    [nzRotate]="isHistoryVisible ? 90 : 0"></span>
                                History
                            </h2>
                        </div>
                    </div>
                    <!-- History Section -->
                    <div *ngIf="isHistoryVisible" class="row shadow-sm p-1 bg-body rounded">
                        <div class="col-12 p-0 m-0">
                            <nz-table [nzBordered]="false" nzSize="small" #editRowTable nzBordered
                                nzShowPagination="false" [nzScroll]="{ x: '1625px'}">
                                <thead style="background-color: #f1f4fa;border-bottom: #ccc;">
                                    <tr>
                                        <th [nzSortFn]="true" nzWidth="25%" class="sub-text1">Status</th>
                                        <th [nzSortFn]="true" nzWidth="17%" class="sub-text1 text-start">Date</th>
                                        <th [nzSortFn]="true" nzWidth="22%" class="sub-text1 text-start">By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="ms-2 bard" style="border: solid 2px #ccc">
                                        <td style="width: 17%;">
                                            <label class="pira-label">Created & Released</label>
                                        </td>
                                        <td style="width: 17%;">
                                            <label class="pira-label">Mon, Apr 15, 2024, 11:27 PM </label>
                                        </td>
                                        <td style="width: 17%;">
                                            <label class="pira-label">Muhammad Yousuf
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                    </div>
                </div>
            </nz-card>



        </ng-container>
    </form>
</nz-modal>
<ng-template #modalFooter>
    <button nz-button nzType="primary" id="manageViewsBtn">
        Close
    </button>
    <button nz-button [nzType]="'primary'" (click)="SaveRfis()">Save</button>
</ng-template>
<!-- RFI Add -->

<nz-modal nzWidth="40%" [(nzVisible)]="isVisibles" nzCancelText="Cancel" nzClosable="true" (nzOnCancel)="closebiilss()"
    [nzBodyStyle]="{'max-height': '200px'}" nzTitle="Related Item" [nzOkLoading]="isOkLoading" [nzFooter]="addFooter">

    <ng-container *nzModalContent>
        <!-- Type Select -->
        <label class="sub-text">Type</label>
        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a person" [(ngModel)]="selectedValue">
            <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
            <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
            <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
        </nz-select>

        <!-- Title Select -->
        <label class="sub-text">Title</label>
        <nz-select [nzDisabled]="!selectedValue" nzPlaceHolder="Select a person" [(ngModel)]="selectedValue">
            <!-- <nz-select [nzDisabled]="!selectedValue" nzBorderless [(ngModel)]="titleValue"   > -->
            <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
            <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
            <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
            <!-- Add other nz-option elements as needed -->
        </nz-select>
    </ng-container>
</nz-modal>
<ng-template #addFooter>
</ng-template>
<!-- RFI Add -->