<div class="row famly-inn gx-3">
    <div class="col-12">
        <div class="card border-0 mt-0">
            <div class="card-body">
                <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col">
                        <div class="d-flex">
                            <div class="me-4">
                                <img src="assets/Upgrade.png" alt="">
                            </div>
                            <div>
                                <span class="demo">
                                    You are connected to Xero.
                                </span>
                                <a class="TextWrap" href="/">
                                    Xero Help Center
                                    <span role="img" class="ms-0">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class="">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4996 2.0005L9.75007 2L9.69176 2.00336C9.44308 2.03221 9.25 2.24352 9.25 2.49993L9.25336 2.55824C9.28221 2.80692 9.49352 2.99997 9.74993 3H12.2925L8.64466 6.64823L8.60306 6.69534C8.45055 6.89148 8.46442 7.1751 8.64466 7.35534C8.83992 7.5506 9.1565 7.5506 9.35177 7.35534L12.9995 3.707L13 6.25007L13.0034 6.30838C13.0323 6.55704 13.2436 6.75 13.5001 6.75C13.7762 6.75 14 6.52608 14 6.24993L13.9995 2.50043L13.9961 2.44213C13.9672 2.19351 13.7559 2.00053 13.4996 2.0005ZM7.5 4.5C7.5 4.22386 7.27614 4 7 4H3L2.92537 4.00274C2.40794 4.04092 2 4.47282 2 5V13L2.00274 13.0746C2.04092 13.5921 2.47282 14 3 14H11L11.0746 13.9973C11.5921 13.9591 12 13.5272 12 13V9L11.9966 8.94169C11.9678 8.69302 11.7564 8.5 11.5 8.5C11.2239 8.5 11 8.72386 11 9V13H3V5H7L7.05831 4.99664C7.30698 4.96775 7.5 4.75642 7.5 4.5Z"></path>
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                </div>
            </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-body">
                <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <span  class="d-flex align-content-center justify-content-center demo mt-2 mb-2">
                            No Access
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <span class="d-flex align-content-center justify-content-center">
                            You do not have access to update Accounting Settings. Contact an
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <span class="d-flex align-content-center justify-content-center">
                            administrator of this account for details.
                        </span>
                    </div>
                </div>
            </div>
                </div>
            </div>
        </div>

</div>
</div>


    