import { Component } from '@angular/core';

@Component({
  selector: 'app-format-no-data',
  templateUrl: './format-no-data.component.html',
  styleUrls: ['./format-no-data.component.css']
})
export class FormatNoDataComponent {

}
