<nz-modal
nzWidth="25%"
[(nzVisible)]="uploadfiles"
[nzTitle]="UploadFiles1"
[nzFooter]="UploadFiles2"
(nzOnCancel)="UploadFiles()"
[nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '68vh', 'overflow-y': 'auto'}"
[nzStyle]="{ top: '130px', }">
<ng-template #UploadFiles1>
<div class="row p-0 m-0">
  <h2 class="p-0 m-0" nz-typography>Create New  File</h2>
</div>
</ng-template>
<div class="content" *nzModalContent>
<div class="row famly-inn gx-3">
  <div class="col-12">
      <div class="card border-0 mt-0">
        <div class="card-head border-bottom">
          <div class="row py-2">
              <h5 nz-typography><span class="ms-3"></span>New Document Title</h5>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
                <div class="col-12">
                  <input placeholder="Untitled Document" type="text" class="formField">
                </div>
          </div>
        </div>
      </div>
      <div class="card border-0 mt-3">
        <div class="card-head border-bottom">
          <div class="row py-2">
              <h5 nz-typography><span class="ms-3"></span>Viewing Permissions</h5>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
                <div class="col-12">
                  <div class="row mt-1">
                <div class="col-12">
                      <label nz-checkbox>Owner</label> 
                </div>
                  </div>
                  <div class="row mt-1">
                <div class="col-12">
                      <label nz-checkbox>Subs</label> 
                </div>
                  </div>
                </div>
          </div>
        </div>
      </div>
  </div>
</div>
</div>
<ng-template #UploadFiles2>
  <div class="row">
    <div class="col-12">
      <button style="padding: 0px 120px 0px 130px;" nz-button nzType="primary" class="rounded-1 me-0">Create</button>
    </div>
  </div>
</ng-template>
</nz-modal>



