import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { RoleTypeService } from 'src/Service/Internaluser/role-type.service';
import { FieldDataType } from 'src/app/Core/Enums/data_types';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { AddNewStandardFilterComponent } from '../../Modal/add-new-standard-filter/add-new-standard-filter.component';
import { ProjectManagementAllowanceUnassignedSelectionsPreDefinedCodes } from '../../Models/LookUpStandardFilterCodes';
import { CreateLookupFilterRequest, LookupFilterResponse, LookUpStandardResponse, LookupFilter, LookupNameSetupResponse } from '../../Models/LookupModels';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
@Component({
  selector: 'app-project-management-allowance-unassinged-filter-field',
  templateUrl: './project-management-allowance-unassinged-filter-field.component.html',
  styleUrls: ['./project-management-allowance-unassinged-filter-field.component.css']
})
export class ProjectManagementAllowanceUnassingedFilterFieldComponent {
  @Input() pageId : number;
  projectmanagementselectionallowanceunassingedFilterForm: FormGroup;
  createLookupFilterRequests: CreateLookupFilterRequest[] = [];
  filterResponseData: LookupFilterResponse[] = [];
  lookUpStandardResponse: LookUpStandardResponse[] = [];
  defaultStandardFilter : LookupFilterResponse;
  predefinedValuesMultiSelect: string[] = [];
  formFieldValues: any = {};
  deadlineOptions: { label: string, value: number }[] = [];
  LookupNameSetupResponse: LookupNameSetupResponse[] = [];
  SelectionStatusNodes : NzTreeNodeOptions[]=[];
  LocationsNodes : NzTreeNodeOptions[]=[];
  CategoriesNodes : NzTreeNodeOptions[]=[];
  roles: { id: string, name: string }[] = [];
  selectedFilter: number = -1;

  preDefinedDataCodes = {
    Title: ProjectManagementAllowanceUnassignedSelectionsPreDefinedCodes.Title,
    SelectionStatus: ProjectManagementAllowanceUnassignedSelectionsPreDefinedCodes.SelectionStatus,
    Locations: ProjectManagementAllowanceUnassignedSelectionsPreDefinedCodes.Locations,
    Categories: ProjectManagementAllowanceUnassignedSelectionsPreDefinedCodes.Categories,
    Deadline: ProjectManagementAllowanceUnassignedSelectionsPreDefinedCodes.Deadline,
    standardFilter: ProjectManagementAllowanceUnassignedSelectionsPreDefinedCodes.standardFilter,
  };
  constructor(
    private roleService: RoleTypeService,
    private fb: FormBuilder,
    public toastService: NzMessageService,
    private vendor:SubVendorService,
    private cdr: ChangeDetectorRef,
    private loadingIndicatoreService: LoadingIndicatorService,
    private modal: NzModalService,
    private LookupFilterService: LookupFilterService,
    private internalUserService: InternalUserService,
    )
    {}
    ngOnInit(): void {
      this.initLoad();
      this.ForUbvendor()
     }
    initLoad(){
      this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(res =>{
        let SelectionStatusData = res.result.filter(x => x.code === this.preDefinedDataCodes.SelectionStatus.code);
        let LocationsData = res.result.filter(x => x.code === this.preDefinedDataCodes.Locations.code);
        let CategoriesData = res.result.filter(x => x.code === this.preDefinedDataCodes.Categories.code);
        let DeadlineData = res.result.filter(x => x.code === this.preDefinedDataCodes.Deadline.code);
        console.log("all data",res.result);


        // selecation status//
          this.SelectionStatusNodes = [
               {
                title: 'Select all',
                value: 'select_all',
                key: 'select_all',
                selectable: false,
                isLeaf: false,
                expanded: true,
                children: SelectionStatusData.map((status) => ({
                 title: status.name,
                 value: status.id.toString(),
                 key: status.id.toString(),
                 isLeaf: true
                }))
               }
              ];

        // location //
  this.LocationsNodes = [
       {
        title: 'Select all',
        value: 'select_all',
        key: 'select_all',
        selectable: false,
        isLeaf: false,
        expanded: true,
        children: LocationsData.map((status) => ({
         title: status.name,
         value: status.id.toString(),
         key: status.id.toString(),
         isLeaf: true
        }))
       }
      ];

        // categories//
          this.CategoriesNodes = [
               {
                title: 'Select all',
                value: 'select_all',
                key: 'select_all',
                selectable: false,
                isLeaf: false,
                expanded: true,
                children: CategoriesData.map((status) => ({
                 title: status.name,
                 value: status.id.toString(),
                 key: status.id.toString(),
                 isLeaf: true
                }))
               }
              ];

        this.deadlineOptions = DeadlineData.map(res => ({
          label: res.description,
          value: res.id.toString(),
        }));
      })
      const lookupFilter: LookupFilter = {
        formNameId: this.pageId,
      };
      this.getStandardFilterByFormPageId(this.pageId);
      this.fetchData(lookupFilter);
    }
    onFilterApply(): void {
      let selectedFilter = this.formFieldValues['standardFilter'];
      let isSelectedFilterStandard = this.filterResponseData.find(x => x.id === selectedFilter && x.isNotDeleted === true);
      if(isSelectedFilterStandard && isSelectedFilterStandard != undefined ){
        this.selectedFilter = 1;
      }
       else{
        this.selectedFilter = 0;
      }
      this.createFormFieldsInArray();

    }
    fetchData(lookUpStandard: LookupFilter): void {
      this.LookupFilterService.postData(lookUpStandard).subscribe(
        data => {
          this.lookUpStandardResponse = data.result;
        },
        error => {
          console.error('Error fetching data:', error);
        }
      );
    }
// selecation status//
    onselectionStatusChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
     const allValues = this.SelectionStatusNodes[0].children.map(child => child['value']);
     this.formFieldValues['selectionStatus'] = [...allValues];
    } else {
     const allValues = this.SelectionStatusNodes[0].children.map(child => child['value']);
     this.formFieldValues['selectionStatus'] = selectedValues.filter(value => allValues.includes(value));
    }
   }

 // location //
   onlocationsChange(selectedValues: string[]): void {
      const checkAllIndex = selectedValues.indexOf('select_all');
      if (checkAllIndex !== -1) {
       const allValues = this.LocationsNodes[0].children.map(child => child['value']);
       this.formFieldValues['locations'] = [...allValues];
      } else {
       const allValues = this.LocationsNodes[0].children.map(child => child['value']);
       this.formFieldValues['locations'] = selectedValues.filter(value => allValues.includes(value));
      }
     }
// categories//
     oncategoriesChange(selectedValues: string[]): void {
        const checkAllIndex = selectedValues.indexOf('select_all');
        if (checkAllIndex !== -1) {
         const allValues = this.CategoriesNodes[0].children.map(child => child['value']);
         this.formFieldValues['categories'] = [...allValues];
        } else {
         const allValues = this.CategoriesNodes[0].children.map(child => child['value']);
         this.formFieldValues['categories'] = selectedValues.filter(value => allValues.includes(value));
        }
       }



       OnVendorChange(selectedValues: string[]): void {
        const checkAllIndex = selectedValues.indexOf('select_all');
        if (checkAllIndex !== -1) {
          const allValues = this.SubvendorNode[0].children.map(child => child['value']);
          this.formFieldValues['subsVendors'] = [...allValues];
        } else {
          const allValues = this.SubvendorNode[0].children.map(child => child['value']);
          this.formFieldValues['subsVendors'] = selectedValues.filter(value => allValues.includes(value));
        }
      }
      SubvendorNode: NzTreeNodeOptions[] = [];

      ForUbvendor() {
        this.vendor.getData().subscribe(res => {
          const SubvendorAssgin = res.result;
          this.SubvendorNode = [
            {
             title: 'Select all',
             value: 'select_all',
             key: 'select_all',
             selectable: false,
             isLeaf: false,
             expanded: true,
             children: SubvendorAssgin.map((LOP) => ({
              title: LOP.companyName,
              value: LOP.id.toString(),
              key: LOP.id.toString(),
              isLeaf: true
             }))
            }
           ];
        });

      }

    openStandardFilterComponent() {
      this.createFormFieldsInArray();
      const modalRef = this.modal.create({
        nzContent: AddNewStandardFilterComponent,
        nzFooter: null,
        nzData:
      {
        pageId: this.pageId,
        std : this.filterResponseData ,
        field: this.createLookupFilterRequests
      },
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
    }
    getStandardFilterByFormPageId(pageId:number){
      this.LookupFilterService.getStandardFilterByFormPageId(pageId);
      this.LookupFilterService.$getStandardFilter.subscribe(result =>{
        if(result){
          this.filterResponseData = result.result;
          console.log("RESULT STANDARD FILTER : ", this.filterResponseData);
          this.initializeForm();
          this.setDefaultFieldValues(this.defaultStandardFilter.id);
        }
      });
    }
    nodes: NzTreeNodeOptions[] = [
      {
        title: 'Check All',
        value: '0-all',
        key: '0-all',
        isLeaf: true,
        checked: false
      },
      {
        title: 'Node1',
        value: '0-0',
        key: '0-0',
        isLeaf: true
      },
      {
        title: 'Node2',
        value: '0-1',
        key: '0-1',
        isLeaf: true
      },
      {
        title: 'Node3',
        value: '0-2',
        key: '0-2',
        isLeaf: true
      },
      {
        title: 'Node4',
        value: '0-3',
        key: '0-3',
        isLeaf: true
      },
      {
        title: 'Node5',
        value: '0-4',
        key: '0-4',
        isLeaf: true
      },
      {
        title: 'Node6',
        value: '0-5',
        key: '0-5',
        isLeaf: true
      },
    ];

  initializeForm(): void {
    this.formFieldValues = {
      standardFilter: this.getDefaultFilterValue(),
      title: '',
      selectionStatus: [] = [],
      subsVendors: [] = [] ,
      locations: [] = [],
      categories: [] = [],
      deadline: [],
      showUnavailableChoices: [],
    };
  }

  resetFilter(): void {
     const formControlKeys = Object.keys(this.projectmanagementselectionallowanceunassingedFilterForm.controls).slice(1);
     formControlKeys.forEach(field => {
       this.projectmanagementselectionallowanceunassingedFilterForm.get(field).reset();
     });
  }
  createFormFieldsInArray(){
    this.createLookupFilterRequests = [];
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const value = this.projectmanagementselectionallowanceunassingedFilterForm.get(field).value;
      if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 1 && value[0] === null)) {
        const newRow = {
          companyParameterId: 0,
          lookUpStandardId: 0,
          code: code,
          meaning: '',
          valueId: 0,
          formNameId: this.pageId,
          dataTypeId: 0,
          createLookupFilterParameterRequests: [null]
        };
           const correspondingResponse = this.lookUpStandardResponse.find(response => response.code === code);
           if (correspondingResponse) {
               newRow.companyParameterId = correspondingResponse.companyParameterId;
               newRow.lookUpStandardId = correspondingResponse.id;
               newRow.dataTypeId = correspondingResponse.dataTypeId;
               if(
                correspondingResponse.dataType.code === FieldDataType.SingleLineText ||
                correspondingResponse.dataType.name === 'Single-Line Text'){
                newRow.meaning = value;
               }
               if(
                correspondingResponse.dataType.code === FieldDataType.Dropdown ||
                correspondingResponse.dataType.name === 'Dropdown'){
                newRow.valueId = value;
               }
              if (
                correspondingResponse.dataType.code === FieldDataType.MultiSelectDropdown ||
                correspondingResponse.dataType.name === 'Multi-Select Dropdown'
            ) {
                newRow.createLookupFilterParameterRequests = value.map((item, index) => {
                    return { valueId: item };
                });
            }
               // Assign other properties similarly based on your data structure
           }
        this.createLookupFilterRequests.push(newRow);
      }
    });
  }
  getDefaultFilterValue() {
    this.defaultStandardFilter = this.filterResponseData.find(filter => filter.isSetAsDefault === true);
    return this.defaultStandardFilter ? this.defaultStandardFilter.id : null;
  }
  setDefaultFieldValues(standardFilterId: number): void {
    this.predefinedValuesMultiSelect = [];
    let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
    let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;
    // Get all form control keys except the first one
    const formControlKeys = Object.keys(this.projectmanagementselectionallowanceunassingedFilterForm.controls).slice(1);
    // Reset all form controls except the first one
    formControlKeys.forEach(field => {
      this.projectmanagementselectionallowanceunassingedFilterForm.get(field).reset(); // Reset the form control
    });
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const fieldData = lookUpFiltersField.find(filter => filter.code === code);
      if (fieldData != undefined) {
        if(
          fieldData.dataType.code === FieldDataType.SingleLineText ){
          this.projectmanagementselectionallowanceunassingedFilterForm.get(field).setValue(fieldData.meaning);
         }
         if(fieldData.dataType.code === FieldDataType.Dropdown){
          this.projectmanagementselectionallowanceunassingedFilterForm.get(field).setValue(fieldData.id);
         }
         if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown ) {
          let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
          this.predefinedValuesMultiSelect = multiSelectValues.map(String);
          this.projectmanagementselectionallowanceunassingedFilterForm.get(field).setValue(this.predefinedValuesMultiSelect);
      }
      }
    });
  }
  onChangeStandardFilter($event: number): void {
    this.setDefaultFieldValues($event);
  }




  }


















