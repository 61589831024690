import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { StandardColumnSetupResponse, StandardView, StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { LookupFilter, LookUpStandardResponse } from 'src/app/shared/component/Models/LookupModels';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class StandardviewColumnSetupService {

  private baseUrl: string = environment.apiUrl;
  getStandardView = new BehaviorSubject<ResponseModelArray<StandardViewResponse>>(null);
  getStandarcolumndView = new BehaviorSubject<ResponseModelArray<StandardColumnSetupResponse>>(null);
  $getStandardView = this.getStandardView.asObservable();
  StandardViewResponseData: StandardViewResponse[];

  constructor(private http: HttpClient) { }

  postData(lookUpStandard: StandardView): Observable<ResponseModelArray<StandardViewResponse>> {
    const apiUrl = `${this.baseUrl}StandardView/getByDefaultStandardViewByForm?FormNameId=${lookUpStandard.formNameId}`;
    return this.http.post<any>(apiUrl, lookUpStandard).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  getStandardViewByFormPageId(formNameId: number) {
    if (formNameId !== undefined) {
      const apiUrl = `${this.baseUrl}StandardView/getByDefaultStandardViewByForm?FormNameId=${formNameId}`;
      this.http.post<ResponseModelArray<StandardViewResponse>>(apiUrl, formNameId).subscribe(
        (data) => {
          this.getStandardView.next(data); },);
    } else {
      console.error('formNameId is undefined');
    }
  }



  getData(formNameId: number): Observable<ResponseModelArray<StandardColumnSetupResponse>> {
    const apiUrl = `${this.baseUrl}StandardColumnSetup/getAll-StandardColumnByFormNameId?FormNameId=${formNameId}`;
    return this.http.get<ResponseModelArray<StandardColumnSetupResponse>>(apiUrl);
  }


}
