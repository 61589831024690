<nz-modal
  nzWidth="65%"
  [nzVisible]="NewDailyhandle"
  [nzTitle]="NewDaily1"
  [nzFooter]="NewDaily2"
  (nzOnCancel)="NewDailyCancel()"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '78vh',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '18px' }"
>
  <ng-template #NewDaily1>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0">Contact List</h1>
    </div>
  </ng-template>
  <div class="content famly-inn" *nzModalContent>
    <div class="card border-0 mt-0 p-3">
      <div class="card-head border-bottom pb-2">
        <div class="row py-2">
          <div class="col-12">
            <label class="sub-text ms-2">Search by name/email</label>
          </div>
        </div>
        <div class="row p-2">
          <div class="col-6">
            <div class="input-group">
              <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                <input type="text" class="formField" nz-input [(ngModel)]="searchTerm"  />
              </nz-input-group>
              <ng-template #suffixIconButton>
                <button nz-button nzType="primary" nzSearch (click)="onSearch()">
                  <span nz-icon nzType="search"></span>
                </button>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row shadow-sm p-1 bg-body rounded">
          <div class="col-12 p-0 m-0">
            <nz-table
            #sortTable
            [nzData]="displayData"
            [nzBordered]="false"
            nzSize="small"
            [nzScroll]="{ x: '1625px' }"
            [nzPageSize]="pageSize"
            [nzPageSizeOptions]="[20, 50, 75, 100, 200, 250]"
            [nzLoading]="isLoading"
            nzPaginationType="default"
            [nzFrontPagination]="true"
            [nzPaginationPosition]="'bottom'"
            (nzPageSizeChange)="onPageSizeChange($event)"
            (nzPageIndexChange)="onPageIndexChange($event)"
          >
            <thead style="background-color: #f1f4fa; border-bottom: #ccc">
              <tr>
                <th nzLeft style="border-radius: 8px 0px 0px 0px" nzWidth="110px" class="sub-text1"></th>
                <th
                  class="sub-text1"
                  nzWidth="10%"
                  *ngFor="let column of listOfColumns"
                  [nzSortFn]="column.compare"
                  [nzSortPriority]="column.priority"
                >
                  {{ column.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="ms-2 bard" style="border: solid 2px #ccc" *ngFor="let item of sortTable.data">
                <td nzLeft style="width: 15%">
                  <button (click)="onSelectCustomer(item)" nz-button nzType="text" class="same-but ms-2">
                    <span>Select</span>
                  </button>
                </td>
                <td style="width: 17%">
                  <label class="pira-label" nz-popover [nzPopoverContent]="displayName" nzPopoverPlacement="top">
                    {{ item.displayName }}
                  </label>
                  <ng-template #displayName>
                    <p style="width: auto; font-size: 14px; padding: 12px">{{ item.displayName }}</p>
                  </ng-template>
                </td>
                <td style="width: 17%">
                  <nz-tag
                    nz-popover
                    [nzPopoverContent]="Content"
                    class="d-flex align-items-center mt-1"
                    [ngClass]="getStatusClass(item?.statusSetup?.code)"
                    style="
                      line-height: 1;
                      border-radius: 10px;
                      border: none;
                      padding: inherit;
                      height: 20px;
                      width: fit-content;
                      font-weight: 700;
                      cursor: pointer;
                    "
                    (mouseenter)="setHovered(item, true)"
                    (mouseleave)="setHovered(item, false)"
                  >
                    {{ item?.statusSetup?.description }}
                    <span class="ms-2" nz-icon nzType="info-circle" [nzTheme]="isHovered(item) ? 'fill' : 'outline'"></span>
                  </nz-tag>
                  <ng-template #Content>
                    <p style="width: 240px; height: auto; padding: 12px">
                      <span>
                        {{ item?.statusSetup?.name }}
                        <ng-container *ngIf="item?.statusSetup?.code === 'CISTSA' || 'CISTSD'">
                          {{ item?.createdOn | date : "MMM dd,YYYY" }}
                        </ng-container>
                      </span>
                    </p>
                  </ng-template>
                </td>
                <td style="width: 17%">
                  <label class="pira-label" nz-popover [nzPopoverContent]="primaryEmail" nzPopoverPlacement="top">
                    {{ item.primaryEmail }}
                  </label>
                </td>
                <ng-template #primaryEmail>
                  <p style="width: auto; font-size: 14px; padding: 12px">{{ item.primaryEmail }}</p>
                </ng-template>
                <td style="width: 17%">
                  <label class="pira-label">{{ item.phone }}</label>
                </td>
                <td style="width: 17%">
                  <label class="pira-label">{{ item.cellPhone }}</label>
                </td>
                <td style="width: 17%">
                  <label class="pira-label">{{ item.streetAddress }}</label>
                </td>
                <td style="width: 17%">
                  <label class="pira-label">{{ item.countryCode }}</label>
                </td>
                <td style="width: 17%">
                  <label class="pira-label">{{ item.state }}</label>
                </td>
                <td style="width: 17%">
                  <label class="pira-label">{{ item.state }}</label>
                </td>
                <td style="width: 17%">
                  <label class="pira-label">{{ item.suburb }}</label>
                </td>
              </tr>
            </tbody>
          </nz-table>
          
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #NewDaily2> </ng-template>
</nz-modal>
