import { Component } from '@angular/core';

@Component({
  selector: 'app-owner-invoices-empty',
  templateUrl: './owner-invoices-empty.component.html',
  styleUrls: ['./owner-invoices-empty.component.css']
})
export class OwnerInvoicesEmptyComponent {

}
