import { ProjectManagementSiteDiariesToDoInformationComponent } from './components/Site-Diaries/project-management-site-diaries-to-do-information/project-management-site-diaries-to-do-information.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectManagementRoutingModule } from './project-management-routing.module';
import { SelectionDashbordComponent as SelectionDashboardComponent } from './components/selection-dashbord/selection-dashbord.component';
import { HierarchyComponent } from './components/hierarchy/hierarchy.component';
import { ListsComponent } from './components/lists/lists.component';
// import { SelectionComponent } from '../company-settings/selection/selection.component';
import { AddchoiceSelectionChoiceComponent } from './components/Selection-Common/addchoice-selection-choice/addchoice-selection-choice.component';
import { ImportSelectionComponent } from './components/Selection-Common/import-selection/import-selection.component';
import { AllowanceComponent } from './components/allowance/allowance.component';
import { AssingedselectionComponent } from './components/allowance/assingedselection/assingedselection.component';
import { NewAllowanceComponent } from './components/allowance/new-allowance/new-allowance.component';
import { UnassingedSelectionComponent as UnassignedSelectionComponent } from './components/allowance/unassinged-selection/unassinged-selection.component';
import { HierarchyNewSelectionComponent } from './components/hierarchy-new-selection/hierarchy-new-selection.component';
import { NewSelectionComponent } from './components/hierarchy/Add Chooice List/new-selection/new-selection.component';
import { NgZorroModule } from 'src/shared/ng-zorro/ng-zorro.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectionComponent } from './components/Selection-Common/selection/selection.component';
import { BaselineComponent } from './components/schedules-Components/baseline/baseline.component';
import { WorkdayExecptionsComponent as WorkdayExceptionsComponent } from './components/schedules-Components/workday-execptions/workday-execptions.component';
import { NewscheduleItemComponent } from './components/schedules-Components/schedule-common/newschedule-item/newschedule-item.component';
import { NewScheduleItemScheduleDetailsComponent } from './components/schedules-Components/schedule-common/new-schedule-item-schedule-details/new-schedule-item-schedule-details.component';
import { ScheduleDashboradComponent as ScheduleDashboardComponent } from './components/schedules-Components/schedule-dashborad/schedule-dashborad.component';
import { NewScheduleItemRelatedItemComponent } from './components/schedules-Components/schedule-common/new-schedule-item-related-item/new-schedule-item-related-item.component';
import { InnerTabPredecessorsComponent } from './components/schedules-Components/schedule-common/Inner-Related-Items/inner-tab-predecessors/inner-tab-predecessors.component';
import { InnerTabPhasesTagsComponent } from './components/schedules-Components/schedule-common/Inner-Related-Items/inner-tab-phases-tags/inner-tab-phases-tags.component';
import { InnerTabViewingComponent } from './components/schedules-Components/schedule-common/Inner-Related-Items/inner-tab-viewing/inner-tab-viewing.component';
import { InnerTabNotesComponent } from './components/schedules-Components/schedule-common/Inner-Related-Items/inner-tab-notes/inner-tab-notes.component';
import { InnerTabsFilesComponent } from './components/schedules-Components/schedule-common/Inner-Related-Items/inner-tabs-files/inner-tabs-files.component';
import { ProjectManagementSiteDiariesComponent } from './components/Site-Diaries/project-management-site-diaries/project-management-site-diaries.component';
import { SharedModule } from 'src/shared/shared.module';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { ProjectManagementSiteDiariesAddTagComponent } from './components/Site-Diaries/project-management-site-diaries-add-tag/project-management-site-diaries-add-tag.component';
import { ProjectManagementSiteDiariesChecklistComponent } from './components/Site-Diaries/project-management-site-diaries-checklist/project-management-site-diaries-checklist.component';
import { ProjectManagementSiteDiariesCommentComponent } from './components/Site-Diaries/project-management-site-diaries-comment/project-management-site-diaries-comment.component';
import { ProjectManagementSiteDiariesNewDailyLogComponent } from './components/Site-Diaries/project-management-site-diaries-new-daily-log/project-management-site-diaries-new-daily-log.component';
import { ProjectManagementSiteDiariesRelatedItemsComponent } from './components/Site-Diaries/project-management-site-diaries-related-items/project-management-site-diaries-related-items.component';
import { ProjectManagementSiteDiariesRelatedToDoStComponent } from './components/Site-Diaries/project-management-site-diaries-related-to-do-st/project-management-site-diaries-related-to-do-st.component';
import { ProjectManagementSiteDiariesSettingComponent } from './components/Site-Diaries/project-management-site-diaries-setting/project-management-site-diaries-setting.component';
import { ProjectManagementSiteDiariesUploadFilesComponent } from './components/Site-Diaries/project-management-site-diaries-upload-files/project-management-site-diaries-upload-files.component';
import { ProjectManagementSiteDiariesCreateFilesComponent } from './components/Site-Diaries/project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { ProjectManagementSiteDiariesDocumentXLSComponent } from './components/Site-Diaries/project-management-site-diaries-document-xls/project-management-site-diaries-document-xls.component';
import { ProjectManagementSiteDiariesDocumentDOCComponent } from './components/Site-Diaries/project-management-site-diaries-document-doc/project-management-site-diaries-document-doc.component';
import { ProjectManagementSiteDiariesMapComponent } from './components/Site-Diaries/project-management-site-diaries-map/project-management-site-diaries-map.component';
import { ProjectManagementToDosDbComponent } from './components/To-Do\'s/project-management-to-dos-db/project-management-to-dos-db.component';
import { ProjectManagementVariationsDbComponent } from './components/Variations/project-management-variations-db/project-management-variations-db.component';
import { ProjectManagementTimeClockDbComponent } from './components/TimeClock/project-management-time-clock-db/project-management-time-clock-db.component';
import { ProjectManagementWarrantyDbComponent } from './components/Warranty/project-management-warranty-db/project-management-warranty-db.component';
import { ToDosImportDataFromTemplateComponent } from './components/To-Do\'s/to-dos-import-data-from-template/to-dos-import-data-from-template.component';
import { ChangeOrderComponent } from './components/Variations/change-order/change-order.component';
import { WarrantyNewClaimComponent } from './components/Warranty/warranty-new-claim/warranty-new-claim.component';
import { WarrantyNewClaimWarrantyCategoryComponent } from './components/Warranty/warranty-new-claim-warranty-category/warranty-new-claim-warranty-category.component';
import { WarrantyNewClaimEditWarrantyCategoryComponent } from './components/Warranty/warranty-new-claim-edit-warranty-category/warranty-new-claim-edit-warranty-category.component';
import { TimeClockClockInComponent } from './components/TimeClock/time-clock-clock-in/time-clock-clock-in.component';
import { TimeClockAddTimeClockTagComponent } from './components/TimeClock/time-clock-add-time-clock-tag/time-clock-add-time-clock-tag.component';
import { TimeClockEditTimeClockTagComponent } from './components/TimeClock/time-clock-edit-time-clock-tag/time-clock-edit-time-clock-tag.component';
import { TimeClockNewCostCodeComponent } from './components/TimeClock/time-clock-new-cost-code/time-clock-new-cost-code.component';
import { TimeClockCostCodeEditorComponent } from './components/TimeClock/time-clock-cost-code-editor/time-clock-cost-code-editor.component';
import { TimeClockClockInMultipleComponent } from './components/TimeClock/time-clock-clock-in-multiple/time-clock-clock-in-multiple.component';
import { TimeClockShiftComponent } from './components/TimeClock/time-clock-shift/time-clock-shift.component';
import { TodoEmptyContentComponent } from './components/To-Do\'s/todos-empty-content/todos-empty-content.component';
import { ScheduleListEmptyComponent } from './components/schedules-Components/schedule-list-empty/schedule-list-empty.component';
import { ScheduleLGanttEmptyComponent } from './components/schedules-Components/schedule-lgantt-empty/schedule-lgantt-empty.component';
import { ProjectManagementSelectDialogBoxComponent } from './components/project-management-select-dialog-box/project-management-select-dialog-box.component';
import { NotifyUsersComponent } from './components/schedules-Components/notify-users/notify-users.component';
import { CompletedWarrantyServiceComponent } from '../completed-warranty-service/completed-warranty-service.component';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { GridModule, ExcelModule, ToolbarComponent } from "@progress/kendo-angular-grid";
import { CopyToDosComponent } from './components/To-Do\'s/copy-to-dos/copy-to-dos.component';

@NgModule({
  declarations:[
    SelectionDashboardComponent,
    HierarchyComponent,
    ListsComponent,
    AllowanceComponent,
    UnassignedSelectionComponent,
    NewSelectionComponent,
    HierarchyNewSelectionComponent,
    SelectionComponent,
    AddchoiceSelectionChoiceComponent,
    ImportSelectionComponent,
    AssingedselectionComponent,
    NewAllowanceComponent,
    ScheduleDashboardComponent,
    BaselineComponent,
    WorkdayExceptionsComponent,
    NewscheduleItemComponent,
    NewScheduleItemScheduleDetailsComponent,
    NewScheduleItemRelatedItemComponent,
    InnerTabPredecessorsComponent,
    InnerTabPhasesTagsComponent,
    InnerTabViewingComponent,
    InnerTabNotesComponent,
    InnerTabsFilesComponent,  
    ProjectManagementSiteDiariesComponent,
    ProjectManagementSiteDiariesAddTagComponent,
    ProjectManagementSiteDiariesChecklistComponent,
    ProjectManagementSiteDiariesCommentComponent,
    ProjectManagementSiteDiariesNewDailyLogComponent,
    ProjectManagementSiteDiariesRelatedItemsComponent,
    ProjectManagementSiteDiariesRelatedToDoStComponent,
    ProjectManagementSiteDiariesSettingComponent,
    ProjectManagementSiteDiariesToDoInformationComponent,
    ProjectManagementSiteDiariesUploadFilesComponent,
    ProjectManagementSiteDiariesCreateFilesComponent,
    ProjectManagementSiteDiariesDocumentXLSComponent,
    ProjectManagementSiteDiariesDocumentDOCComponent,
    ProjectManagementSiteDiariesMapComponent,
    ProjectManagementToDosDbComponent,
    ProjectManagementVariationsDbComponent,
    ProjectManagementTimeClockDbComponent,
    ProjectManagementWarrantyDbComponent,
    ProjectManagementToDosDbComponent,
    ToDosImportDataFromTemplateComponent,
    ChangeOrderComponent,
    WarrantyNewClaimComponent,
    WarrantyNewClaimWarrantyCategoryComponent,
    WarrantyNewClaimEditWarrantyCategoryComponent,
    TimeClockClockInComponent,
    TimeClockAddTimeClockTagComponent,
    TimeClockEditTimeClockTagComponent,
    TimeClockNewCostCodeComponent,
    TimeClockCostCodeEditorComponent,
    TimeClockClockInMultipleComponent,
    TimeClockShiftComponent,
    TodoEmptyContentComponent,
    ScheduleListEmptyComponent,
    ScheduleLGanttEmptyComponent,
    ProjectManagementSelectDialogBoxComponent,
    CompletedWarrantyServiceComponent,
    NotifyUsersComponent,
    CopyToDosComponent,
    // SignaturePadComponent

  ],
  imports: [
    CommonModule,
    ProjectManagementRoutingModule,
    SharedModule,
    NgZorroModule,
    ReactiveFormsModule,
    FormsModule,
    NzModalModule,
    GridModule,
    ExcelModule,
  ]


})
export class ProjectManagementModule { }
