<nz-modal
  nzWidth="40%"
  [(nzVisible)]="isVisible10"
  [nzTitle]="LeadproposalTitle"
  (nzOnCancel)="handleCancel()"
  [nzFooter]="LeadproposalFooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '550px',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '15px' }"
>
  <ng-template #LeadproposalTitle>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0">Upload Files</h2>
    </div>
  </ng-template>

  <div class="content" *nzModalContent>
    <nz-card>
      <nz-upload
        nzType="drag"
        [nzMultiple]="true"
        nzAction="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        nzListType="picture"
        
      >
        <p class="ant-upload-drag-icon mb-5">
          <span nz-icon nzType="file" nzTheme="outline"></span>
        </p>
        <p class="ant-upload-text bold mb-5">
          Drop files here to upload, or browse for files.
        </p>
  

        <div class="ant-upload-hint">
          <div class="col text-center">
            
              <button nz-button nzType="primary" class="me-2">
                Browse Computer
              </button>
              <button nz-button nzType="default">
                Browse Persistent solution
              </button>
            
        </div>
    </div>
</nz-upload>
    </nz-card>
  </div>

  <ng-template #LeadproposalFooter>
    <div class="container-fluid p-0 PSCustomFooter mt-2">
      <div class="row">
        <div class="col text-end">
          <nz-space>
            <button *nzSpaceItem nz-button nzType="default">Discard</button>
            <button *nzSpaceItem nz-button nzType="primary">Save</button>
          </nz-space>
        </div>
      </div>
    </div>
  </ng-template>
</nz-modal>
