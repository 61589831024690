import { ChangeOrderSubVendor } from './../../../../../Models/ChangeOrder/ChangeOrder';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { ProjectManagementSiteDiariesCreateFilesComponent } from '../../Site-Diaries/project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { NzSelectSizeType } from 'ng-zorro-antd/select';
import { CatalogComponent } from 'src/app/company-settings/catalog/catalog.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { LeadProposalsPreDefinedCodes, SubsVendors } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { LeadProposalsService } from 'src/Service/LeadProp/lead-proposals.service';
import { CostItemParameterResponse, CreateCostItemRequest, CreateCostTypeItemParameterRequest } from 'src/Models/LeadProposal/LeadProposalModels';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { AttachmentResponse, CreateAttachmentForDocumentRequest, LeadOpportunityResponse } from 'src/Models/LeadTags';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { LeadOpportunityAttachmentsUploadFilesComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { ChangeOrderInformationResponse, CreateMessagingRequest } from 'src/Models/ChangeOrder/ChangeOrder';
import { NewRFIsComponent } from 'src/app/messaging/Components/RFIs/new-rfis/new-rfis.component';
import { SignaturePadComponent } from 'src/app/project-management/signature-pad/signature-pad.component';
import { LeadOpportunitiesWorkSheetAddformCatalogComponent } from 'src/app/lead-opportunities/Components/Catalog-Modal/lead-opportunities-work-sheet-addform-catalog/lead-opportunities-work-sheet-addform-catalog.component';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { CostCodeCategoryResponse, CostCodeInfo, CostCodeResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { BlockQuote, Bold,  ClassicEditor,  EditorConfig,  Essentials, Font, FontBackgroundColor, FontColor, FontFamily, FontSize, Heading, HorizontalLine, Image, ImageUpload, Indent, Italic, Link, List, MediaEmbed, Paragraph, Table, Undo, UndoEditing } from 'ckeditor5';
interface ItemData {
  id: string;
  name: string;
  age: string;
  address: string;
}
@Component({
  selector: 'app-change-order',
  templateUrl: './change-order.component.html',
  styleUrls: ['./change-order.component.css']
})
export class ChangeOrderComponent {
  @Output() onSaveComplete = new EventEmitter();
  @Input() jobInformationId: number;
  // sufyan working
  jobID: number;
  formNameID: number = 7;
  messagingId:number ; //soofee work
  messagingIdRow: any; //soofee work
  jobIDForm: number;
 @Input() comments: any;  //hold
 @Input() createOn: string;
  // sufyan working
  showValidationMessage: boolean = false;
  items: any[] = [];
  formFieldValues: any = {};
  index: number = 0;
  tableFormFieldValues: any[] = [];
  selectedFiles: File[] = [];
  tabs = [1, 2, 3,];
  showMessagingSection: boolean = false;
  mainSaveResponseId: number = -1;
  listOfOption: Array<{ label: string; value: string }> = [];
  size: NzSelectSizeType = 'default';
  singleValue = 'a10';
  multipleValue = ['a10', 'c12'];
  tagValue = ['a10', 'c12', 'tag'];

  proposalStatusNodes: NzTreeNodeOptions[] = [];
  salespersonMulti: NzTreeNodeOptions[] = [];
  leadOptions: CostCodeCategoryResponse[] = [];
  attendeesNodes: NzTreeNodeOptions[] = [];
  // leadData: LeadOpportunityResponse;
  @Input() leadData: LeadOpportunityResponse;
  @Input() ChangeOrder: ChangeOrderInformationResponse;
  setIdVariable : any ;


  preDefinedDataCodes = {
    LOLPCT: LeadProposalsPreDefinedCodes
  };


  constructor(private modal: NzModalService,
    // @Inject(NZ_MODAL_DATA) public leadData: any,
    @Inject(NZ_MODAL_DATA) public data: any,
    private LookupFilterService: LookupFilterService,
    private leadproposalsserv: LeadProposalsService,
    private LeadOpportunitiesService: LeadOpportunitiesService,
    private toastService: NzMessageService,
    private customerInformationService: CustomerInformationService,
    public localStorage: AccessLocalStorageService,
    @Inject(NZ_MODAL_DATA) public JOBDATA: any,
    @Inject(NZ_MODAL_DATA) public ChangeOrderInformation: any,
    @Inject(NZ_MODAL_DATA) public ChangeOrderInformation2: any,

  ) {


  }
  @Output() cancel = new EventEmitter<void>();

  ngOnInit(): void {

    this.messagingId = this.ChangeOrderInformation?.ChangeOrderInformation?.messagingId;
    this.messagingIdRow = this.ChangeOrderInformation?.ChangeOrderInformation2;
    console.log("MESSAGEING ID ROW:", this.messagingIdRow);
    console.log("MESSAGEING ID :", this.messagingId);

    const storeComments = this.ChangeOrderInformation?.ChangeOrderInformation?.messaging?.messagingParameters.map(param => param.comments)
    this.comments = storeComments?.flat() || [];
    console.log('sufyan console.log',this.comments);


    this.jobID = this.localStorage.getJobId();
    this.jobInformationId = this.localStorage.getJobId();
    // console.log(' this.jobID', this.jobID);
    this.formFieldValues['jobInformationId'] = this.jobInformationId;

    this.New();
    this.ForCombo();
    this.initLoad();
    this.getLeadData();
    this.addRow();
    this.leadData = this.data?.leadData;
    this.LeadOpportunitiesService.getSelectedFiles().subscribe(files => {
      this.selectedFiles = files;
    });
    this.LeadOpportunitiesService.GetCompanyChangeOrder().subscribe(
      (response) => {
        console.log("Received company change order data:", response.result);
        // Handle the response data here
      },
      (error) => {
        console.error('Error:', error);
      }
    );
    console.log('ChangeOrderInformationResponse', this.ChangeOrder);

    this.formFieldValues['title'] = this.ChangeOrder?.title;
    this.formFieldValues['approvalDeadlineOn'] = this.ChangeOrder?.approvalDeadlineOn;
    this.formFieldValues['changeOrderDescription'] = this.ChangeOrder?.changeOrderDescription;
    this.formFieldValues['builderCost'] = this.ChangeOrder?.builderCost;
    this.formFieldValues['ownerPrice'] = this.ChangeOrder?.ownerPrice;
    this.formFieldValues['internalNotes'] = this.ChangeOrder?.internalNotes;
    this.formFieldValues['subVendorNotes'] = this.ChangeOrder?.subVendorNotes;
    this.formFieldValues['ownerNotes'] = this.ChangeOrder?.ownerNotes;
    this.formFieldValues.createChangeOrderSubVendorRequests =this.ChangeOrder?.changeOrderSubVendors.map(param => param.subVendorId.toString());

    // console.log('this.ChangeOrderInformation?.changeOrderSubVendors?.changeOrderInformationId;',this.ChangeOrderInformation)


    // this.formFieldValues.createDivisionTradeParameterRequests = this.GridDataResponse.divisionTradeParameters.map(param => param.divisionTradeId.toString());
    // this.formFieldValues['createChangeOrderSubVendorRequests'] = this.ChangeOrder.changeOrderSubVendors.map(param => param.changeOrderInformationId.toString());



    // if (this.ChangeOrder && this.ChangeOrder.attachment && this.ChangeOrder.attachment.attachmentParameters) {
    //   this.attachments = this.ChangeOrder.attachment.attachmentParameters.map(attachment => ({
    //     attachmentId: attachment.attachmentId,
    //     name: attachment.name,
    //     extension: attachment.extension,
    //     size: attachment.size,
    //     filePath: attachment.filePath + '\\D:\\CmsFrontEnd\\BackupConstructaion\\Files\\Uploads\\Documents',
    //     createdBy: attachment.createdBy,
    //     createdOn: attachment.createdOn,
    //     modifiedBy: null,
    //     modifiedOn: null,
    //     isActive: true,
    //     id: null,
    //     globalId: null
    //   }));

    //   console.log('Attachments:', this.attachments);
    // } else {
    //   this.attachments = [];
    // }




    const children: Array<{ label: string; value: string }> = [];
    for (let i = 10; i < 36; i++) {
      children.push({ label: i.toString(36) + i, value: i.toString(36) + i });
    }
    this.listOfOption = children;


    this.mainSaveResponseId = this.ChangeOrder?.id;

    this.setIdVariable = this.ChangeOrder.id ;

  }


  leadProFormGroup(): void {

    this.formFieldValues = {
      globalId: '00000000-0000-0000-0000-000000000000',
      jobSetupId: 0,
      changeOrderSetupId: 0,
      jobInformationId: this.jobInformationId,
      title: '',
      rfiNumber: '',
      autoAssignNumber: '',
      rfiFullNumber: '',
      approvalDeadlineOn: new Date(),
      isShowLineItemsToOwner: true,
      isFlatFree: true,
      isLineItems: true,
      builderCost: '',
      ownerPrice: '',
      internalNotes: '',
      subVendorNotes: '',
      ownerNotes: '',
      approvalSignature: '',
      ownerComments: '',
      changeOrderDescription: '',
      comments: '',
      // costItemId: '',
      attachmentId: '',
      messagingId: '',
      rfiInformationId: '',
      statusId: '',
      approved: true,
      decline: true,
      signatureSetupId: '',
      scheduleItemParameterId: '',
      ownerReason: '',
      isConfirmOwner: true,
      ownerSignatureSetupId: '',
      ownerApproved: true,
      ownerDecline: true,
      createChangeOrderSubVendorRequests: []
    };


    console.log("jobInformationId",this.jobInformationId);

  }

  // selectionModel
  Taghandle = true;
  TagClose(): void {
    this.cancel.emit();
  }


  isRowVisible = true;
  isRowVisible1 = false;

  showRow1() {
    this.isRowVisible = false;
    this.isRowVisible1 = true;
  }

  showRow() {
    this.isRowVisible1 = false;
    this.isRowVisible = true;
  }

  confirmSwitchToFlatFee(): void {
    this.modal.confirm({
      nzTitle: 'Switch to Flat Fee?',
      nzContent: 'Upon save, all line items will be removed permanently and any invoice previously created from this change order will be unlinked.',
      nzOkText: 'Use Flat Fee',
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.showRow1();
      },
    });
  }

  isSectionVisible = false;

  toggleSection() {
    this.isSectionVisible = !this.isSectionVisible;
  }
  i = 0;
  editId: string | null = null;
  listOfData: ItemData[] = [];

  startEdit(id: string): void {
    this.editId = id;
  }

  stopEdit(): void {
    this.editId = null;
  }

  addRow(): void {
    this.listOfData = [
      ...this.listOfData,
      {
        id: `${this.i}`,
        name: `Edward King ${this.i}`,
        age: '32',
        address: `London, Park Lane no. ${this.i}`
      }
    ];
    this.i++;
  }



  tbodyList: any[] = [];
  attachments: AttachmentResponse[] = [];
  addTbody() {
    // Add a new tbody to the list
    this.tbodyList.push({});
  }

  deleteTbody() {
    // Remove the last tbody from the list
    if (this.tbodyList.length > 0) {
      this.tbodyList.pop();
    }
  }

  innerCardTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'Internal Notes';
      case 2:
        return 'Sub/Vendor Notes';
      case 3:
        return 'Owner Notes';
      default:
        return '';
    }
  }


  Catalog(flag: string): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesWorkSheetAddformCatalogComponent,
      nzFooter: null,
      nzData: {
        CompanySetting: flag
      },

    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    this.isRowVisible = true;
    console.log(flag);

  }

  addNewRow(): void {
    const incompleteRows = this.items.some(item => !item.title || !item.costCodeId);
    if (incompleteRows) {
      this.showValidationMessage = true;
      return;
    }
    this.showValidationMessage = false;

    const newRow = {
      title: "",
      description: "",
      quantity: "",
      unit: "",
      unitCost: "",
      builderCost: 0,
      markupValue: 9942,
      markupId: 0,
      ownerPrice: 0,
      margin: 0,
      profit: 0,
      internalNotes: "IN",
      includeQuantity: true,
      includeOwnerPrice: true,
      createCostTypeItemParameterRequests: [] // <-- This should be createCostItemParameterRequests
    };

    this.items.push(newRow);
    this.index = this.items.length - 1; // Update the index
    this.formFieldValues.createCostItemParameterRequests = this.items; // Update this line
}



deleteRow(index: number) {
  this.items.splice(index, 1);
  this.formFieldValues.createCostItemParameterRequests = this.items; // Update this line
}







  calculateBuilderCost() {
    const quantity = this.tableFormFieldValues['quantity'];
    const unitCost = this.tableFormFieldValues['unitCost'];

    if (quantity && unitCost) {
      const product = quantity * unitCost;
      const formattedProduct = product.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      this.tableFormFieldValues['builderCost'] = formattedProduct;
    } else {
      this.tableFormFieldValues['builderCost'] = '';
    }
  }
  calculateMarginAndProfit(): void {
    const quantity = parseFloat(this.tableFormFieldValues['quantity']);
    const unitCost = parseFloat(this.tableFormFieldValues['unitCost']);
    const markup = parseFloat(this.tableFormFieldValues['markup']);

    if (!isNaN(quantity) && !isNaN(unitCost) && !isNaN(markup)) {
      let margin: number;
      let profit: number;

      if (markup >= unitCost) {
        margin = ((markup - unitCost) / unitCost) * 100;
        profit = (markup - unitCost) * quantity;
      } else {
        margin = 0;
        profit = 0;
      }

      // Update the tableFormFieldValues with the calculated values
      this.tableFormFieldValues['margin'] = margin.toFixed(2); // Convert to 2 decimal places
      this.tableFormFieldValues['profit'] = profit.toFixed(2); // Convert to 2 decimal places
    } else {
      // Set default values or handle error
      this.tableFormFieldValues['margin'] = '0.00';
      this.tableFormFieldValues['profit'] = '0.00';
    }
  }




  getLeadData() {
    console.log('Lead Pro Data:', this.leadOptions);
    this.leadproposalsserv.getAllLeadData().subscribe(data => {
      this.leadOptions = data.result;


    });
  }

  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(37).subscribe(res => {
      let proposalStatus = res.result.filter(x => x.code === this.preDefinedDataCodes.LOLPCT);
      console.log('costtype', proposalStatus);


      this.proposalStatusNodes = proposalStatus.map(proposalStatus => ({
        title: proposalStatus.name,
        value: proposalStatus.id.toString(),
        key: proposalStatus.id.toString(),
        isLeaf: true
      }));
    });
  }

  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      console.log("API Response:", res); // Log API response for debugging
      const salesper = res.result;
      console.log("Salesperson Data:", salesper); // Log salesperson data for debugging

      this.salespersonMulti = salesper.map((LOP) => ({
        title: LOP.fullName,
        key: LOP.id.toString(),
        isLeaf: true,
      }));

      console.log("Salesperson Multi:", this.salespersonMulti); // Log mapped data for debugging
    });
  }


  save(): void {
    const data = { ...this.formFieldValues }; // Make a copy of formFieldValues
    console.log('formFieldValues',data);


    const salesUsers = data.createChangeOrderSubVendorRequests?.map((id: string) => ({ subVendorId: id }));
    data.createChangeOrderSubVendorRequests = salesUsers;


    console.log("subvenderId salesUsers",salesUsers);


    this.LeadOpportunitiesService.PostChangeOrder(data).subscribe(
      (response) => {
        console.log('Data saved successfully:', response);
        this.toastService.success('New Copy Saved Successfully!');
        this.mainSaveResponseId = response.result.id;
        console.log('this.mainSaveResponseId',this.mainSaveResponseId);

         this.messagingId = this.mainSaveResponseId ? this.mainSaveResponseId : this.ChangeOrderInformation2;
         this.messagingIdRow =  this.mainSaveResponseId ? this.mainSaveResponseId : this.ChangeOrderInformation2;
         console.log(" toDoParameterId ? toDoParameterId : this.rowToDoData;",this.messagingIdRow);


        if (this.mainSaveResponseId) {
          const selectedUsers = this.formFieldValues['createCostItemParameterRequests'] || [];
          const costItemRequest: CreateCostItemRequest = {
              headerId: response.result.id,
              companyParameterId: 1,
              formNameId: 7,
              createCostItemParameterRequests: selectedUsers.map(userId => {
                  const mappedCostTypeItemParameterRequests = userId.createCostTypeItemParameterRequests.map((costType: CreateCostTypeItemParameterRequest) => ({
                      costTypeId: costType.costTypeId
                  }));
                  return {
                      costItemId: userId.costItemId,
                      categoryId: userId.categoryId,
                      costCodeId: userId.costCodeId,
                      title: userId.title,
                      description: userId.description,
                      quantity: userId.quantity,
                      unit: userId.unit,
                      unitCost: userId.unitCost,
                      builderCost: userId.builderCost,
                      markupValue: userId.markupValue,
                      markupId: userId.markupId,
                      ownerPrice: userId.ownerPrice,
                      margin: userId.margin,
                      profit: userId.profit,
                      internalNotes: userId.internalNotes,
                      includeQuantity: userId.includeQuantity,
                      includeOwnerPrice: userId.includeOwnerPrice,
                      createCostTypeItemParameterRequests: mappedCostTypeItemParameterRequests
                  };
              })
          };

          // Call the service method with the request object
          this.leadproposalsserv.postCostItem(costItemRequest).subscribe(
              (costItemResponse) => {
                  console.log('Cost item data saved successfully:', costItemResponse);
                  this.toastService.success('New Cost Item Saved Successfully!');
              },
              (costItemError) => {
                  console.error('Error saving cost item data:', costItemError);
                  this.toastService.error('Error saving cost item data.');
              }
          );
      }



         else {
          console.error('mainSaveResponseId is not available.');
        }

        // Check if selectedFiles is available and has files to upload
        if (this.selectedFiles && this.selectedFiles.length > 0) {
          const filesToUpload = this.selectedFiles.filter(file => file instanceof File);
          if (filesToUpload.length > 0) {
            const attachmentRequest: CreateAttachmentForDocumentRequest = {
              headerId: response.result.id,
              companyParameterId: 1,
              formNameId: 7,
              documentFiles: filesToUpload
            };
            console.log('selectedFiles:', this.selectedFiles);
            this.LeadOpportunitiesService.CreateAttachmentForDocument(attachmentRequest).subscribe(
              (attachmentResponse) => {
                console.log('Attachment created successfully:', attachmentResponse);
                this.saveFileIdToDatabase(attachmentResponse.fileId);
                this.showMessagingSection = true;
              },
              (attachmentError) => {
                console.error('Error creating attachment:', attachmentError);
              }
            );
          } else {
            console.warn('No files to upload.');
          }
        }
      },
      (error) => {
        console.error('Error saving data:', error);
      }
    );
  }


  saveFileIdToDatabase(fileId: string): void {
  }

  Rfis(responseId: number,formNameId: number) {
    const modalRef = this.modal.create({
      nzContent: NewRFIsComponent,
      nzFooter: null,
      nzData: {
        headerId: responseId,
        formNameId: formNameId

      }
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  createMessage() {
    if (!this.mainSaveResponseId) {
      console.error('Main data not saved yet.');
      return;
    }
    const selectedUsers = this.formFieldValues['createMessagingUserNotifyParameterRequests'];

    const createMessagingRequest: CreateMessagingRequest = {
      headerId: this.mainSaveResponseId,
      formNameId: 7,
      comments: "New Message",
      isViewOwner: true,
      isViewSubVendor: false,
      createMessagingUserNotifyParameterRequests: selectedUsers.map(userId => ({ applicationUserId: userId }))
    };
    this.LeadOpportunitiesService.PostMessage(createMessagingRequest).subscribe(
      (messageResponse) => {
        console.log('Message created successfully:', messageResponse);
        this.toastService.success('Message created successfully!');
      },
      (messageError) => {
        console.error('Error creating message:', messageError);
      }
    );
  }

  UploadFiles(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  CreateNewFile(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  viewAll(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  removeFile(file: File): void {
    const index = this.selectedFiles.indexOf(file);
    if (index !== -1) {
      this.selectedFiles.splice(index, 1);
    }
  }

  New() {
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      const attendees = res.result;
      this.attendeesNodes = attendees.map((Adees) => ({
        title: Adees.fullName,
        key: Adees.id.toString(),
        isLeaf: true,
      }));

      // Once attendeesNodes are populated, call the leadProFormGroup() method
    });
  }


  signatureData: string | null = null;

  Signature(): void {
    const modalRef = this.modal.create({
      nzContent: SignaturePadComponent,
      nzFooter: null,
    });

    modalRef.componentInstance.signatureSaved.subscribe((data: string) => {
      this.signatureData = data;
      modalRef.close();
    });
  }

  public Editor = ClassicEditor;
  public config: EditorConfig = {
    toolbar: {
      items: [
        'undo', 'redo', '|',
        'heading', '|', 'bold', 'italic', '|',
        'link', 'insertTable', 'mediaEmbed', '|',
        'bulletedList', 'numberedList', 'indent', 'outdent', '|',
        'fontSize', 'fontFamily', 'fontBackgroundColor', 'fontColor', 'blockQuote',
        'image', 'horizontalLine', 'undoEditing'
      ]
    },
    plugins: [
      Bold,
      Essentials,
      Heading,
      Indent,
      Italic,
      Link,
      List,
      MediaEmbed,
      Paragraph,
      Table,
      Undo,
      Font,
      FontSize,
      FontFamily,
      FontBackgroundColor,
      FontColor,
      BlockQuote,
      Image,
      HorizontalLine,
      UndoEditing,
      ImageUpload
    ],
    fontSize: {
      options: [
        9, 11, 13, 'default', 17, 19, 21, 25, 28, 32, 36, 40, 44, 48, 52, 56, 60
      ]
    },
    fontFamily: {
      options: [
        'default', 'Arial, Helvetica, sans-serif', 'Courier New, Courier, monospace',
        'Georgia, serif', 'Times New Roman, Times, serif', 'Verdana, Geneva, sans-serif'
      ]
    }
  };
}


