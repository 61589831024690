<div class="row mt-5" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <div class="col" style="text-align: center;">

        <img src="assets/Empty-SVGs/Financial/Owner-invoice/payment.svg" style="font-size: 70px;">   

        <div class="row">
            <div class="col mt-2">
        <h4 class="size">No Invoice Payment <br> available</h4>
            </div>
        </div>
     
        <div class="col mt-4" style="display: flex; justify-content: center; align-items: center;">
            <button nz-button nzType="primary" style="margin-right: 10px;" >Add an Invoice</button>
        </div>
    </div>
  
  </div>