<div class="row mt-1">
    <div class="col-12">
        <div   style="margin-left: 5px; margin-right: 5px;"  class="card shadow-none  rounded border-0">
            <div class="card-body ">
                <div class="row">
                    <div class="col">
                        <div class="styling" >
                        <svg width="277" height="65" viewBox="0 0 277 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M49.8974 39.0457C49.7781 34.2694 48.2908 29.6277 45.612 25.6715C42.9333 21.7153 39.1755 18.6108 34.785 16.7267L31.7204 29.0174C33.4932 30.1509 34.954 31.7103 35.9696 33.5532C36.9852 35.3962 37.5232 37.4641 37.5345 39.5683C37.5273 42.7314 36.3311 45.7761 34.1833 48.0982C32.0354 50.4203 29.093 51.8499 25.9401 52.1033L22.877 64.3955L24.8611 64.5H26.3581C32.6755 64.1389 38.6202 61.3923 42.9899 56.8157C47.3597 52.239 49.8286 46.1738 49.8974 39.8465V39.0457Z" fill="#00AEEF"/>
                            <path d="M18.2109 50.2516C16.4209 49.1184 14.9463 47.5512 13.9242 45.6955C12.902 43.8399 12.3654 41.756 12.3643 39.6374C12.3636 36.4629 13.5607 33.4051 15.7164 31.0747C17.8721 28.7444 20.8277 27.3132 23.9926 27.0671L27.0543 14.7778L25.8767 14.7086H12.2907V0.5H0V36.2947V39.6374C0.00835343 44.5189 1.44755 49.2907 4.13956 53.3628C6.83157 57.4348 10.6583 60.6283 15.1463 62.5482L18.2109 50.2516Z" fill="#00567B"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M80 22.5C79.4477 22.5 79 22.9477 79 23.5V31.5H71C70.4477 31.5 70 31.9477 70 32.5C70 33.0523 70.4477 33.5 71 33.5H79V41.5C79 42.0523 79.4477 42.5 80 42.5C80.5523 42.5 81 42.0523 81 41.5V33.5H89C89.5523 33.5 90 33.0523 90 32.5C90 31.9477 89.5523 31.5 89 31.5H81V23.5C81 22.9477 80.5523 22.5 80 22.5Z" fill="#B1B4B5"/>
                            <path d="M257.449 10.8304C253.782 10.8301 250.197 11.9174 247.148 13.9546C244.099 15.9919 241.722 18.8877 240.319 22.2758C238.915 25.6639 238.548 29.3921 239.264 32.9888C239.979 36.5856 241.745 39.8893 244.339 42.4822C246.932 45.0751 250.236 46.8407 253.833 47.5557C257.43 48.2707 261.158 47.9029 264.546 46.499C267.934 45.095 270.829 42.7178 272.866 39.6682C274.903 36.6186 275.989 33.0334 275.988 29.3662C275.982 24.4516 274.027 19.7402 270.551 16.2653C267.076 12.7905 262.364 10.836 257.449 10.8304ZM257.449 39.1164C255.522 39.1164 253.637 38.5448 252.035 37.4739C250.432 36.4031 249.182 34.881 248.445 33.1001C247.707 31.3193 247.514 29.3596 247.889 27.469C248.265 25.5783 249.193 23.8416 250.556 22.4783C251.919 21.115 253.655 20.1865 255.546 19.81C257.436 19.4336 259.396 19.6261 261.177 20.3634C262.958 21.1006 264.481 22.3494 265.552 23.9519C266.624 25.5543 267.196 27.4385 267.196 29.3662C267.194 31.9509 266.167 34.4292 264.339 36.2571C262.512 38.0851 260.034 39.1134 257.449 39.1164Z" fill="#F45D48"/>
                            <path d="M202.861 25.6192L199.622 24.0006C198.219 23.3093 197.339 22.7644 196.985 22.3659C196.646 22.0127 196.456 21.5439 196.451 21.055C196.451 20.43 196.73 19.9059 197.292 19.4844C197.855 19.063 198.63 18.853 199.622 18.853C201.439 18.853 203.457 19.954 205.677 22.156L211.148 16.6847C209.813 14.8681 208.067 13.3932 206.053 12.3804C204.038 11.3677 201.813 10.8459 199.558 10.8576C196.212 10.8576 193.466 11.8448 191.319 13.8192C189.173 15.7936 188.099 18.173 188.098 20.9572C188.098 25.2746 190.947 28.8688 196.645 31.7396L199.622 33.2284C202.187 34.5233 203.469 35.8508 203.469 37.2108C203.469 37.9448 203.113 38.5923 202.401 39.1532C201.69 39.7141 200.751 39.9946 199.586 39.9946C198.505 39.9946 197.27 39.6206 195.879 38.8727C194.493 38.1306 193.246 37.1547 192.193 35.9881L186.803 41.8664C189.866 45.8804 193.967 47.8874 199.105 47.8874C202.968 47.8874 206.049 46.8409 208.349 44.7479C210.648 42.6549 211.797 40.0432 211.796 36.9128C211.796 34.5623 211.159 32.512 209.886 30.762C208.612 29.0119 206.271 27.2977 202.861 25.6192Z" fill="#F45D48"/>
                            <path d="M147.829 11.5644H139.036L139.065 14.7264C136.217 11.8417 132.709 10.8304 129.1 10.8304C124.182 10.8304 119.467 12.7838 115.989 16.2608C112.512 19.7379 110.559 24.4537 110.559 29.371C110.559 34.2882 112.512 39.0041 115.989 42.4811C119.467 45.9581 124.182 47.9115 129.1 47.9115C133.367 47.9115 137.02 46.0204 138.901 43.6149C138.901 43.7608 138.888 45.5092 138.884 45.6983C138.848 46.8938 138.675 48.809 137.549 50.7657C136.888 51.8818 136.011 52.8544 134.969 53.626C133.926 54.3976 132.74 54.9526 131.48 55.2584C130.22 55.5642 128.911 55.6144 127.631 55.4063C126.351 55.1981 125.126 54.7357 124.027 54.0463L119.654 61.6586C122.527 63.4013 125.823 64.3236 129.183 64.3254C134.048 64.3254 140.116 62.4022 144.419 56.439C147.998 51.4837 147.829 46.8025 147.829 42.2703V11.5644ZM129.1 39.1164C127.172 39.1164 125.288 38.5448 123.685 37.474C122.082 36.4031 120.833 34.881 120.095 33.1001C119.357 31.3193 119.164 29.3596 119.54 27.469C119.915 25.5783 120.843 23.8416 122.206 22.4783C123.569 21.115 125.306 20.1865 127.196 19.81C129.087 19.4336 131.046 19.6261 132.827 20.3634C134.609 21.1006 136.131 22.3494 137.202 23.9519C138.274 25.5543 138.846 27.4385 138.847 29.3662C138.845 31.951 137.818 34.4296 135.99 36.2576C134.163 38.0857 131.684 39.1139 129.1 39.1164Z" fill="#F45D48"/>
                            <path d="M182.886 11.5643H174.048C174.048 11.5643 174.048 28.9254 174.048 29.193C174.066 31.6898 174.063 36.0153 172.221 37.8695C171.338 38.7573 170.377 39.5522 168.336 39.5522C166.296 39.5522 165.327 38.7509 164.445 37.8695C162.604 36.0153 162.609 31.6898 162.626 29.193C162.626 28.9302 162.626 11.5643 162.626 11.5643H153.794V29.1305C153.77 32.7476 153.725 39.4833 158.16 43.9466C160.763 46.5652 163.862 47.8938 168.343 47.8938C172.824 47.8938 175.925 46.5652 178.527 43.9466C182.962 39.4833 182.917 32.7476 182.893 29.1305L182.886 11.5643Z" fill="#F45D48"/>
                            <path d="M228.601 39.0106C227.593 38.8744 226.601 38.1548 226.037 36.8407C225.651 35.94 225.511 34.7573 225.511 33.3005V19.1255H235.898V11.5372H225.511V1.68115H216.697V32.5858C216.697 35.6163 216.94 39.2895 218.94 42.6822C220.647 45.5669 223.654 47.8987 228.588 47.8987C234.571 47.8987 238.875 43.5716 238.875 43.5716L233.587 37.0683C233.587 37.0683 231.264 39.3712 228.601 39.0106Z" fill="#F45D48"/>
                            </svg>
                        </div>
                        <span class="styling demo mt-5">
                            Payroll made simple
                        </span>
                        <div class="stylo mt-3">
                            We’ve partnered with some of the experts in payroll and HR services to make
                            <div class="stylo-description">
                                managing payroll a breeze by sending time clock shifts to Gusto.
                            </div>
                        </div>
                        <div class="styling demo mt-4">
                                    <button
                                        type="button"
                                        class="ant-btn ant-btn-success"
                                    >
                                        <span>Create a Gusto Account</span>
                                    </button>
                        </div>
                        <div class="stylos  mt-4">
                            Already have a Gusto account?<a class="BTLink ms-2" target="_blank" rel="noopener noreferrer">Connect to Gusto</a>
                        </div>
                        <div class="styloss">
                           <a class="BTLink ms-2" target="_blank" rel="noopener noreferrer">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
