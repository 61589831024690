import { Component } from '@angular/core';

@Component({
  selector: 'app-sub-vendor-accountin',
  templateUrl: './sub-vendor-accountin.component.html',
  styleUrls: ['./sub-vendor-accountin.component.css']
})
export class SubVendorAccountinComponent {

}
