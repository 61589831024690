<div class="card-head pt-2">
    <h5  class="fw-medium">Schedule Item Phase</h5>
  </div>
  <hr>
  <div class="row mt-3">
    <div class="col-5">
      <label class="cityDropdown "><b>Phase :</b></label>
      <div class="input-group">
        <nz-select nzShowSearch  [lang]="true" nzAutoFocus="true"    nzPlaceHolder="Select a person" [(ngModel)]="selectedValue">
          <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
          <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
          <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
        </nz-select>
    </div>
    </div>
    <div class="col-3 mt-4">            
      <button  (click)="scheduleInnercardModal2()" aria-disabled="false" type="button" class="ant-btn ant-btn-link BTButton isolated AutoSizing"
     style="height: 29px; margin-top: -12px;">
     <span>Add</span>
   </button>&nbsp;&nbsp;
   <button aria-disabled="false" type="button" class="ant-btn ant-btn-link BTButton isolated AutoSizing ">
     <span style="opacity: 60%; margin-bottom: 40px;">
       Edit
     </span>
   </button>
 </div>
  </div>
  <div class="card-head mt-3 pt-2">
    <h5  class="fw-medium">Schedule Item Phase</h5>
  </div>
  <hr>
  <div class="row mt-3">
    <div class="col-5">
      <label class="cityDropdown "><b>Phase :</b></label>
      <div class="input-group">
       <nz-tree-select style="width: 100%" [(ngModel)]="value" [nzNodes]="nodes" 
nzShowSearch nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"
formControlName="createDivisionTradeParameterRequests"></nz-tree-select>
    </div>
    </div>
    <div class="col-3 mt-4">            
      <button  (click)="scheduleInnercardModal1()" aria-disabled="false" type="button" class="ant-btn ant-btn-link BTButton isolated AutoSizing"
     style="height: 29px; margin-top: -12px;">
     <span>Add</span>
   </button>&nbsp;&nbsp;
   <button aria-disabled="false" type="button" class="ant-btn ant-btn-link BTButton isolated AutoSizing ">
     <span style="opacity: 60%; margin-bottom: 40px;">
       Edit
     </span>
   </button>
 </div>
  </div>