import { CompanyParameter, FormName } from "../All-Filters/financialBillsFilterField";

export class Exchange {
  id: number;
  globalId: string;

  constructor(id: number, globalId: string) {
    this.id = id;
    this.globalId = globalId;
  }
}

export abstract class BaseResponse extends Exchange {
  createdBy: number;
  createdOn: Date;
  modifiedBy: number | null;
  modifiedOn: Date | null;
  isActive: boolean;
}

export class ApplicationUserResponse extends BaseResponse {
    firstName: string;
    fullName: string;
    middleName: string;
    lastName: string;
    password: string;
    salt: string;
    email: string;
    label: string;
    phoneNumber: string;
    companyParameterId?: number;
    companyParameter?: CompanyParameterResponse;
    countryCode?: string;
     dailCode?: string;
    cellPhone: string;
    receiveTextMessages: string;
    applicationGroupId?: number;
    applicationGroup?: ApplicationGroupResponse;
    loginAccess?: boolean;
    archiveUser?: boolean;
    userStatusId?: number;
    userStatus?: UserStatusResponse;
    userTypeId?: number;
    userType?: UserTypeResponse;
    isGrantExsistJobOrTemplate: boolean;
    isGrantNewJobOrTemplate: boolean;
    isSuperAdmin?: boolean;
    activationCode: string;
    dialCode: string;
    activationCodeExpiration?: Date;
    isActivationCodeUsed?: boolean;
    isSetOutOfOfficeStatus?: boolean;
    messageSignature: string;
    messageSignatureImageUrl: string;
    isScheduleNotifyMe?: boolean;
    scheduleNotifyMeWhen?: number;
    isTimeClockRequiredLocation?: boolean;
    costCodeId?: number;
    costCode?: CostCodeResponse;
    defaultLaborCost?: number;
    contactInformation?: string;
    isAdminAccess?: boolean;
    isAutoAccess?: boolean;
    isDeleted?: boolean;
    applicationUserEmailParameters?: ApplicationUserEmailParameterResponse[];
    applicationUserNotificationParameters?: ApplicationUserNotificationParameterResponse[];
    applicationUserJobAcessParameters?: ApplicationUserJobAcessParameterResponse[];
  }
  
  export class ApplicationUserEmailParameterResponse {
    public email?: string | null;
    public label?: string | null;
  }
  
  export class ApplicationUserNotificationParameterResponse {
    public applicationModuleId?: number | null;
    public isAllUserActivity?: boolean | null;
    public isEmail?: number | null;
    public isText?: boolean | null;
    public isPush?: boolean | null;
    public toolTip?: string | null;
  }
  
  export class ApplicationUserJobAcessParameterResponse {
    public jobInformationId?: number | null;
    public isAccess?: boolean | null;
    public isNotification?: boolean | null;
  }
  
  export class ApplicationUserForInternalResponse {
    firstName: string;
    middleName: string;
    lastName: string;
  }

export class CompanyParameterResponse extends BaseResponse{
    companyNameId?: number;
    companyName?: CompanyNameResponse;
  }
  
  export class CompanyNameResponse extends BaseResponse{
    name: string;
  }
  
  export class CountryCodeResponse extends BaseResponse {
    name: string;
    iconUrl: string;
  }
  
  export class ApplicationGroupResponse extends BaseResponse {
    name: string;
    description: string;
    multiFactorAuthenticationStatus?: boolean;
    view?: boolean;
    add?: boolean;
    edit?: boolean;
    delete?: boolean;
    toolTip: string;
    applicationGroupParameters?: ApplicationGroupParameterResponse[];
  }
  
  export class ApplicationGroupParameterResponse extends BaseResponse{
    applicationGroupId?: number;
    applicationGroup?: ApplicationGroupResponse;
    applicationModuleId?: number;
    applicationModule?: ApplicationModuleResponse;
    isAllUserActivity?: boolean;
    isEmailNotification?: boolean;
    isTextNotification?: boolean;
    isPushNotification?: boolean;
    isView?: boolean;
    isAdd?: boolean;
    isEdit?: boolean;
    isDelete?: boolean;
    toolTip: string;
  }
  
  export class ApplicationModuleResponse extends BaseResponse{
    name: string;
    applicationModuleId: number | null;
    // applicationModule?: ApplicationModuleResponse; // You can comment this out in TypeScript too if needed
    path: string;
    isMenu?: boolean | null;
    isPermission?: boolean | null;
    isNotification?: boolean | null;
    isView?: boolean | null;
    isAdd?: boolean | null;
    isEdit?: boolean | null;
    isDelete?: boolean | null;
    isEnabled?: boolean | null;
    isAllUserActivity?: boolean | null;
    isEmailNotification?: boolean | null;
    isTextNotification?: boolean | null;
    isPushNotification?: boolean | null;
    toolTip: string;
    applicationModules: ApplicationModuleResponse[] | null;
  }
  
  export class UserStatusResponse extends BaseResponse{
    name: string;
  }
  
  export class UserTypeResponse extends BaseResponse{
    name: string;
  }
  
  export class CostCodeResponse extends BaseResponse{
    companyParameterId?: number;
    companyParameter?: CompanyParameterResponse;
    title: string;
    isParent?: boolean;
    costCodeTemplateId?: number;
    costCodeTemplate?: CostCodeTemplateResponse;
    costCodeCategoryId?: number;
    subCostCodeId: number;
    costCodeCategory?: CostCodeCategoryResponse;
    timeClockLaborCode?: boolean;
    detail: string;
    defaultLaborCost: string;
    status?: boolean;
  }
  
  export class CostCodeTemplateResponse extends BaseResponse{
    showInactiveCodes?: boolean;
  }
  
  export interface CostCodeCategoryResponse {
    globalId?: string;
    id?: number;
    companyParameterId: number;
    title: string;
    defaultLaborCost: string;
    timeClockLaborCode: boolean;
    costCodeId: number;
    subCostCodeId: number;
    detail: string;
    isStatus: boolean;
    isCategory: boolean;
    isSubCodeOf: boolean;
  }
  export interface CostCodeInfo {
    id?: number;
    companyParameterId: number;
    title: string;
    costCodeId: number;
    subCostCodeId: number;
    timeClockLaborCode: boolean;
    defaultLaborCost: string;
    detail: string;
    isStatus: boolean;
    isCategory: boolean;
    isSubCodeOf: boolean;
  }
  







  











  