import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BidsSetupResponse, CreateBidsSetupRequest } from 'src/Models/Bids/bids';
import { ResponseModel, ResponseModelArray } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class BidsService {

  constructor(private http: HttpClient) { }

  private _BaseUrl: string = environment.apiUrl + 'BidsSetup/getData-ByCompany';
  companyBidsget(): Observable<ResponseModel<CreateBidsSetupRequest>> {
    return this.http.get<ResponseModel<CreateBidsSetupRequest>>(this._BaseUrl);
  }

  getCompanyBids(): Observable<ResponseModelArray<BidsSetupResponse>> {
    return this.http.get<ResponseModelArray<BidsSetupResponse>>(this._BaseUrl);
  }


  private BaseUrl: string = environment.apiUrl + 'BidsSetup';
  companyBidsPost(bidsFormData: BidsSetupResponse): Observable<ResponseModelArray<BidsSetupResponse>> {
    const apiUrl = `${this.BaseUrl}`;
    return this.http.post<any>(this.BaseUrl, bidsFormData);
  }

}
