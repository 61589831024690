<nz-layout>
  <nz-sider
    class="border"
    nzCollapsible
    [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed"
    [nzWidth]="280"
    [nzTrigger]="null"
  >
    <div class="row">
      <app-side-menu-bar
        [isAllJobOption]="false"
        (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"
      ></app-side-menu-bar>
    </div>
  </nz-sider>
  <nz-layout>
    <nz-page-header>
      <nz-page-header-title>
        <nz-breadcrumb nz-page-header-breadcrumb>
          <nz-breadcrumb-item>{{AccessLocalStorageService.getJobName()}} - {{selectedJobId}}</nz-breadcrumb-item>
        </nz-breadcrumb>
        <h1>RFIs</h1>
      </nz-page-header-title>
    </nz-page-header>

    <nz-content class="custom-content">
      <div class="container-fuild mb-4 m-3">
              <div nz-row>
                <div nz-col nzSpan="7">
                </div>
                <div nz-col nzSpan="17" class="text-end">
                  <button
                    nz-button
                    nzType="default"
                    class="ms-2 rounded-1"
                    (click)="toggleCollapse()"
                  >
                    {{
                      isCollapsed
                        ? "Expand All Choices "
                        : "Collapse All Choices"
                    }}
                  </button>
                  <button
                    nz-button
                    nzType="default"
                    class="ms-2 rounded-1"
                    (click)="toggleCollapse()"
                  >
                    {{
                      isCollapsed ? "Expand Everything" : "Collapse Everything"
                    }}
                  </button>
                  <button
                    nz-button
                    nzType="default"
                    class="ms-2 rounded-1"
                    (click)="importSelectionModelOpen()"
                  >
                    Import Selections
                  </button>
                  <button
                  nz-button
                  nzType="default"
                  (click)="openFilter(Messaging_RFIs, PAGE_ID)"
                  class="rounded-1 ms-2"
                >
                  <i class="bi bi-funnel fs-8 me-2"></i>Filter
                </button>
                <button
                  nz-button
                  nzType="primary"
                  class="rounded-1 ms-2"
                  (click)="newRFIsOpen()"
                >
                  New RFI
                </button>
                </div>
              </div>
              <kendo-grid
              (columnResize)="saveColumnSizes($event)"
              [data]="getrfiUserData"
              [pageSize]="5"
              [sortable]="true"
              [resizable]="true"
              [reorderable]="true"
            >
            <kendo-grid-checkbox-column
            [width]="45"
            [headerClass]="{ 'text-center': true }"
            [class]="{ 'text-center': true }"
            [resizable]="false"
            [columnMenu]="false"
            [showSelectAll]="true"
          ></kendo-grid-checkbox-column>

              <kendo-grid-column *ngFor="let column of displayedColumns; let i = index"
                [title]="column"
                [field]="column"
                [minResizableWidth]="150"
                [maxResizableWidth]="800"
                [width]="getColumnWidthSize[i]"
                [sticky]="isColumnSticky[i]"
                [headerClass]="'pinned-header'"
                >



              <ng-template kendoGridHeaderTemplate let-columnIndex="columnIndex">
               <div class="d-flex align-items-center" >
                 <span>{{ column }}</span>
                 <button (click)="toggleColumnSticky(columnIndex)" class="btn btn-link">
                   <i class="bi" [ngClass]="{
                     'bi-pin-fill': isColumnSticky[columnIndex],
                     'bi-pin': !isColumnSticky[columnIndex]
                   }"></i>
                 </button>
               </div>
               </ng-template>

              <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
            <ng-container *ngIf="column === 'RFI Is New'">
              <div class="ellipsis">{{dataItem?.CreatedOn}}</div>
            </ng-container>
            <ng-container *ngIf="column === 'RFI #'">
              <div class="ellipsis"><a class="text-primary">{{dataItem?.rfiFullNumber}}</a></div>
            </ng-container>
            <ng-container *ngIf="column === 'Job'">
              <div class="ellipsis"><a class="text-primary"  >{{dataItem?.jobInformationId}}</a></div>
            </ng-container>
            <ng-container *ngIf="column === 'Title'">
              <div class="ellipsis">{{dataItem?.title}}</div>
            </ng-container>
            <ng-container *ngIf="column === 'Due'">
              <div class="ellipsis"><a class="text-primary">{{dataItem?.dueNumberDays}}</a></div>
            </ng-container>
            <ng-container *ngIf="column === 'Created By'">
              <div class="ellipsis">{{dataItem?.createdBy}}</div>
            </ng-container>
            <ng-container *ngIf="column === 'Assigned To'">
              <div class="ellipsis">{{dataItem?.assigneeUser?.firstName}}{{dataItem?.assigneeUser?.middleName}}{{dataItem?.assigneeUser?.lastName}}</div>
            </ng-container>
            <ng-container *ngIf="column === 'Related Items'">
              <div class="ellipsis">{{dataItem?.relatedTypeId}}</div>
            </ng-container>


              </ng-template>

              </kendo-grid-column>
              </kendo-grid>

              <nz-footer class="custom-footer">
                <div class="container-fluid ">
                  <div class="row d-flex justify-content-between align-items-center  ">
                    <div class="row mb-0 mt-2">
                      <div class="col">
                        <nz-pagination style="float: right;" [nzPageIndex]="currentPage" [nzTotal]="totalRecords"
                        [nzPageSize]="pageSize" nzShowSizeChanger (nzPageIndexChange)="onPageChange($event)"
                        (nzPageSizeChange)="onPageSizeChange($event)" [nzPageSizeOptions]="[10, 20, 50, 75, 100]"></nz-pagination>

                        <ng-container  *ngIf="PAGE_ID">
                          <div class="row d-flex justify-content-start mb-1 ">
                            <div class="col-md-3">
                              <nz-select (ngModelChange)="onFilterChange($event)" [(ngModel)]="selectedValueControl" nzShowSearch nzAutoFocus="true">
                                <nz-select
                                [(ngModel)]="selectedValueControl"
                                 name="standardFilter"
                                 (ngModelChange)="onFilterChange($event)"
                                 nzShowSearch
                                 >
                                 <nz-option  *ngFor="let standard of getStandardViewFilterData"
                                   [nzLabel]="standard.name + (standard.isSetAsDefault ? ' ' : '')"
                                   [nzValue]="standard.id"
                                   nzCustomContent>
                                   <div class="option-content">
                                     <!-- Customize content with icons and text -->
                                     <span *ngIf="standard.icon" nz-icon [nzType]="standard.icon"></span>
                                     <span>{{ standard.name }}</span>
                                   <span  *ngIf="standard.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

                                   </div>
                                 </nz-option>
                               </nz-select>
                              </nz-select>

                            </div>
                            <div class="col-md-1">
                              <button
                              nz-button
                              nz-popover
                              nzPopoverTitle="Manage Saved View"
                              nzPopoverPlacement="top"
                              nz-button class="border-0" (click)="openStandardViewFilterComponent()">
                                <span nz-popover class="text-primary text-dark fw-bold fs-5" nz-icon nzType="ellipsis"
                                  nzTheme="outline"></span>
                              </button>
                            </div>
                            <div class="col-md-2">
                              <div class="mt-2" *ngIf="selectedFilter === 1">
                                <p>Your filter has unsaved changes.</p>
                              </div>
                              <div class="mt-2" *ngIf="selectedFilter === 0">
                                <p>Your filter has unsaved changes.</p>
                              </div>
                            </div>

                            <div class="col-md-3">
                              <button (click)="openStandardViewFilterComponent('saveAs')" type="button" nz-button nzType="default" class="me-2" style="font-size: 12px;"
                              *ngIf="selectedFilter === 1">Save As</button>

                              <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;"
                              *ngIf="selectedFilter === 1" type="button">Reset</button>

                              <button (click)="saveEditStandardView()"  nz-button nzType="primary" type="button" class="me-2" style="font-size: 12px;"
                              *ngIf="selectedFilter === 0">Save</button>

                              <button (click)="openStandardViewFilterComponent('saveAs')" nz-button nzType="default" class="me-2" style="font-size: 12px;"
                              *ngIf="selectedFilter === 0" type="button">Save As</button>

                              <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;"
                              *ngIf="selectedFilter === 0" type="button">Reset</button>

                            </div>
                          </div>
                        </ng-container>

                        <div class="col d-flex mt-3">


                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </nz-footer>
      </div>
    </nz-content>

    <!-- ///// content ///// -->

  </nz-layout>
</nz-layout>
