import { Component, EventEmitter, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CopyFormAnotherRoleComponent } from '../copy-form-another-role/copy-form-another-role.component';
import { FormBuilder, FormGroup } from '@angular/forms';


interface Person {
  key: string;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'app-role-management-custom-field',
  templateUrl: './role-management-custom-field.component.html',
  styleUrls: ['./role-management-custom-field.component.css']
})
export class RoleManagementCustomFieldComponent {
  roleManagmentForm: FormGroup;

  
  constructor(private modal: NzModalService,private fb: FormBuilder,
    ){}
  

  @Output() cancel = new EventEmitter<void>();

  ManageCustomeFileds: boolean = true; // Set to false initially
  modalTitle: string = "Role Management Custom Field";
  checked = true;

  closeDialog() {
    this.cancel.emit();
  }


  tabs = ['Permissions',];


  listOfData: Person[] = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park'
    },

   
  ];

  initializeForm(): void {
    this.roleManagmentForm = this.fb.group({
      role: [null],
      roleDescription: [null],
      view: [null],
      new: [null],
      edit: [null],
      delete: [null],
    });
  }



  showCopyfromanotherRole() {
    const modalRef = this.modal.create({
      nzContent: CopyFormAnotherRoleComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
   
  }


}
