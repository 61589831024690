
  <nz-modal nzWidth="70%" [(nzVisible)]="unvisible" [nzTitle]="UploadFiles1" [nzFooter]="UploadFiles2" (nzOnCancel)="close()" [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '70vh', 'overflow-y': 'auto'}" [nzStyle]="{ top: '18px' }">
    <ng-template #UploadFiles1>
        <div class="row">
            <h2 class="p-0 m-0 react">Photo Draw</h2>
        </div>
    </ng-template>
    <div class="content" *nzModalContent>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom"></div>
            <div class="card-body">
      
            </div>
        </div>
    </div>
    <ng-template #UploadFiles2></ng-template>
</nz-modal>
