<div *ngIf="!isDeleting" class="content">
  <div class="row">
    <h1 nz-typography>
      Delete {{ selectedGlobalIds?.length }}
      {{ selectedGlobalIds?.length === 1 ? "Activity" : "Activities" }}?
    </h1>
  </div>

  <div nz-row class="justify-content-end mt-3">
    <div nz-col>
      <nz-space>
        <button (click)="Cancel()" *nzSpaceItem nz-button nzType="default">
          Cancel
        </button>
        <button (click)="deleteLeadActivities()" *nzSpaceItem nz-button nzType="primary" nzDanger>
          Delete {{ selectedGlobalIds?.length }}
          {{ selectedGlobalIds?.length === 1 ? "Activity" : "Activities" }}
        </button>
      </nz-space>
    </div>
  </div>
</div>

<div *ngIf="isDeleting" class="result">
  <nz-result
    nzStatus="success"
    nzTitle="Deleted {{ selectedGlobalIds?.length === 1 ? 'Activity..' : 'Activities...' }}"
  >
  </nz-result>
</div>
