<nz-card nzBorderless="true" [nzTitle]="tradeTitle">
  <ng-template #tradeTitle>
   <h3 >Trade Agreement Contract</h3>
  </ng-template>
  <div class="row">
   <div class="col-12">
   <h3 >Current Trade Agreements <i
     class="bi bi-info-circle-fill ms-1 text-secondary"
     nz-popover [nzPopoverTrigger]="'click'"
     [nzPopoverContent]="contentTemplate"
     nzPopoverPlacement="top"></i>
     <ng-template #contentTemplate>
       <div class="rounded-2">
         <p style="height: auto; width: 180px; ">
           Trade partners will be presented with the trade agreement upon activation and first sign in
         </p>
       </div>
     </ng-template> 
   </h3>
  </div>
 </div>

 <div *ngIf="!uploadButtonClicked">
   <div class="row mt-3"><div class="col-12 text-center">
     <h2 >No active trade agreement</h2>
    </div></div>
    <div class="row mt-3"><div class="col-12 text-center">
     <span >This sub doesn't have a trade agreement, upload a document to get the approval process started.</span>
   </div></div>
   <div class="row mt-3">
     <div class="col-5"></div>
     <div class="col-5 ms-4">
       <nz-upload
           nzAction="https://www.mocky.io/v2/5cc8019d300000980a055e76"
           [nzHeaders]="{ authorization: 'authorization-text' }"
           (nzChange)="handleChange($event)"
         >
         <div class="row">
           <button nz-button>
             <span nz-icon nzType="upload"></span>
             Choose File
           </button>
         </div>
         </nz-upload>
     </div>      
   </div>
 </div>

<div *ngIf="uploadButtonClicked">
 <div class="row">
   <div class="col-12">
     <h4>Trade Agreement</h4>
   </div>
 </div>

 <div class="row mt-3">
   <div class="col-12" id="fileNameField">
     <span>{{fileName}}</span>
   </div>
 </div>
 <div class="row mt-1">
   <div class="col-12">
     <label><h4>Description</h4></label>
     <textarea
      class="form-control formField"
        nz-input
        [nzAutosize]="{ minRows: 2, maxRows: 6 }"
      ></textarea>
   </div>
 </div>
 <div class="row mt-1">
   <div class="col-12">
     <button nz-button class="ant-btn-primary">Save</button>
   </div>
 </div>
</div>

 </nz-card>