import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerInformationResponse } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { CustomerStatusCode } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';





@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.css']
})
export class ContactListComponent implements OnInit {
  @Output() selectedCustomer = new EventEmitter<CustomerInformationResponse>();
  @Output() selectedCustomerInJob = new EventEmitter<CustomerInformationResponse[]>();
  @Output() cancel = new EventEmitter<void>();
  NewDailyhandle = true;

  gridData: CustomerInformationResponse[] = [];
  displayData: CustomerInformationResponse[] = [];
  currentPage = 1;
  totalRecords = 100;
  pageSize = 10;
  isLoading: boolean;
  searchTerm = '';
  constructor(private customerInformation: CustomerInformationService) { }

  ngOnInit(): void {
    this.loadData();

  }

  NewDailyCancel(): void {
    this.cancel.emit();
    this.NewDailyhandle = false;
  }



  hoverStates: { [key: string]: boolean } = {};

  setHovered(item: CustomerInformationResponse, isHovered: boolean): void {
    if (item && item.id) {
      this.hoverStates[item.id] = isHovered;
    }
  }

  isHovered(item: CustomerInformationResponse): boolean {
    return item.id ? !!this.hoverStates[item.id] : false;
  }

  getStatusClass(statusCode: string): string {
    switch (statusCode) {
      case CustomerStatusCode.ACTIVE.code:
        return 'status-text active';
      case CustomerStatusCode.INACTIVE.code:
        return 'status-text inactive';
      case CustomerStatusCode.DISABLED.code:
        return 'status-text disabled';
      case CustomerStatusCode.PENDING.code:
        return 'status-text pending';
      default:
        return 'status-text inactive'; // Default class
    }
  }

  listOfColumns: any[] = [
    {
      name: 'Name',
      sortOrder: null,
      compare: (a: CustomerInformationResponse, b: CustomerInformationResponse) => a.displayName.localeCompare(b.displayName),
      priority: false
    },
    {
      name: 'Status',
      sortOrder: null,
      compare: (a: CustomerInformationResponse, b: CustomerInformationResponse) => a.statusSetup.code.localeCompare(b.statusSetup.code),
      priority: false
      
    },
    {
      name: 'Email',
      sortOrder: null,
      compare: (a: CustomerInformationResponse, b: CustomerInformationResponse) => a.primaryEmail.localeCompare(b.primaryEmail),
      priority: false
    },
    {
      name: 'Phone',
      sortOrder: null,
      compare: (a: CustomerInformationResponse, b: CustomerInformationResponse) => a.phone.localeCompare(b.phone),
      priority: false
    },
    {
      name: 'Cell',
      sortOrder: null,
      compare: (a: CustomerInformationResponse, b: CustomerInformationResponse) => a.cellPhone.localeCompare(b.cellPhone),
      priority: false
    },
    {
      name: 'Address',
      sortOrder: null,
      compare: (a: CustomerInformationResponse, b: CustomerInformationResponse) => a.streetAddress.localeCompare(b.streetAddress),
      priority: false
    },
    {
      name: 'City',
      sortOrder: null,
      compare: (a: CustomerInformationResponse, b: CustomerInformationResponse) => a.countryCode.localeCompare(b.countryCode),
      priority: false
    },
    {
      name: 'State',
      sortOrder: null,
      compare: (a: CustomerInformationResponse, b: CustomerInformationResponse) => a.state.localeCompare(b.state),
      priority: false
    },
    {
      name: 'Zip',
      sortOrder: null,
      compare: (a: CustomerInformationResponse, b: CustomerInformationResponse) => a.state.localeCompare(b.state), // Fix the sort function if needed
      priority: false
    },
    {
      name: 'Suburb',
      sortOrder: null,
      compare: (a: CustomerInformationResponse, b: CustomerInformationResponse) => a.suburb.localeCompare(b.suburb),
      priority: false
    }
  ];


  loadData(): void {
    this.isLoading = true;
    this.customerInformation.getData().subscribe(result => {
      if (result) {
        this.gridData = result.result;
        this.filterData();
      }
      this.isLoading = false;
    });
  }

  onSearch(): void {
    this.filterData();

  }

  filterData(): void {
    this.isLoading = true;

    if (this.searchTerm) {
      this.displayData = this.gridData.filter(item =>
        item.displayName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        item.primaryEmail.toLowerCase().includes(this.searchTerm.toLowerCase())
     
      );
    } else {
      this.displayData = [...this.gridData];
      this.isLoading = false;

    }
    this.updatePagination();
    this.isLoading = false;

  }


  updatePagination(): void {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.displayData = this.displayData.slice(startIndex, endIndex);
  }

  onPageSizeChange(newSize: number): void {
    this.pageSize = newSize;
    this.updatePagination();
  }

  onPageIndexChange(newPage: number): void {
    this.currentPage = newPage;
    this.updatePagination();
  }




  onSelectCustomer(dataItem: CustomerInformationResponse) {
    this.selectedCustomer.emit(dataItem);
    let data: CustomerInformationResponse[] = [dataItem];
    this.selectedCustomerInJob.emit(data);
    this.cancel.emit();
  }

}
