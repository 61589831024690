<nz-card nzBorderless="true" nzTitle="Schedule Viewing" >
    <div class="row">
      <div class="col-12">
        <label nz-checkbox >Show on Gantt</label>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <label nz-checkbox >Show Onwer (Full Schedule)
          <i
          class="bi bi-info-circle-fill text-secondary"
          nz-popover
          [nzPopoverContent]="contentTemplate"
          nzPopoverPlacement="top"></i>
          <ng-template #contentTemplate>
            <div class="rounded-2">
              <p style="height: auto; width: 180px; ">
                To receive text messages
                please review your
              </p>
            </div>
          </ng-template>  
        </label>
      </div>
    </div>
    <div class="row- mt-4">
      <div class="col-12">
        <label class="cityDropdown fw-bold">Subs/Vendors</label>
        <div class="input-group">
          <nz-tree-select style="width: 100%" [(ngModel)]="value" [nzNodes]="nodes" 
          nzShowSearch nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"
          formControlName="createDivisionTradeParameterRequests"></nz-tree-select>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <p>All Assigned Subs/Vendors have been granted viewing Access and will recive notifications on this schedule item.</p>
      </div>
    </div>


  </nz-card>
  <!-- crad working -->