import { Component, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import { JobInformationResponse } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { Job } from 'src/Models/InternalUser/InternalUser';
import { LeadOpportunityResponse } from 'src/Models/LeadTags';
import { JobCreateFromScratchComponent } from 'src/app/jobs-management/Job-List/job-create-from-scratch/job-create-from-scratch.component';


@Component({
  selector: 'customer-jobs',
  templateUrl: './customer-jobs.component.html',
  styleUrls: ['./customer-jobs.component.css']
})
export class CustomerJobsComponent {
  @Input() customerResponseData: any;
  @Input() lead: LeadOpportunityResponse;

  constructor(private modal: NzModalService, 
    private msg: NzMessageService,
   ) { }

 

  JobCreateFromScratchComponentOpen(): void {
    const modalRef = this.modal.create({
      nzContent: JobCreateFromScratchComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }


}
