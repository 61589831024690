import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CreateApplicationBulkUserRequest } from 'src/Models/InternalUser/InternalUser';
import { ApplicationGroupResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { RoleTypeService } from 'src/Service/Internaluser/role-type.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';

@Component({
  selector: 'app-add-bulk-internal-user',
  templateUrl: './add-bulk-internal-user.component.html',
  styleUrls: ['./add-bulk-internal-user.component.css']
})
export class AddBulkInternalUserComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Output() onBulkSaveComplete = new EventEmitter<void>();
  userForm: FormGroup = new FormGroup({});
  roles: { id: number, name: string }[] = [];
  rolesData: ApplicationGroupResponse[] = [];
  isVisible: boolean = true;
  internalUserFormData: CreateApplicationBulkUserRequest[] = [];

  constructor(
    private fb: FormBuilder,
    private toastService: NzMessageService,
    private roleService: RoleTypeService,
    private internalUserService: InternalUserService,
    private loadingIndicatorService: LoadingIndicatorService,
  ) { }

  ngOnInit(): void {
    this.initLoad();
    this.initForm();
  }

  initLoad() {
    this.roleGetData();
  }

  initForm() {
    this.userForm = this.fb.group({
      users: this.fb.array([this.createUser()])
    });
  }

  get users() {
    return (this.userForm.get('users') as FormArray);
  }


  createUser(): FormGroup {
    return this.fb.group({
      companyParameterId: new FormControl(1),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      applicationGroupId: new FormControl('', Validators.required),
      userStatusId: new FormControl(1),
      userTypeId: new FormControl(1)
    });
  }


  public addUser() {
    this.users.push(this.createUser());
  }

  public removeUser(index: number) {
    this.users.removeAt(index);
  }


  saveData() {

    if (this.userForm.valid) {
      let val = this.userForm.value;
      this.internalUserFormData = val.users;
      this.loadingIndicatorService.show();
  
      this.internalUserService.saveBulkInterUserData(this.internalUserFormData).subscribe(
        res => {
          setTimeout(() => {
          this.toastService.success('User Saved successfully!');
          this.handleCancel();
          this.loadingIndicatorService.hide();
          this.onBulkSaveComplete.emit();
      }, 2000);

        },
        error => {
          console.error('Error saving user data:', error);
          this.toastService.error('Failed to save user data. Please try again.');
          this.loadingIndicatorService.hide();
        }
      );
    } else {
      // Handle form validation errors or show a message
      this.toastService.warning('Please fill in all required fields correctly.');
    }
  }
  

  getFormControl(controlName: string, index: number): any {
    return (this.users.at(index).get(controlName) as FormControl);
  }
  roleGetData() {
    this.roleService.getData().subscribe(
      (res: ResponseModelArray<ApplicationGroupResponse>) => {
        this.roles = res.result || [];
        this.rolesData = res.result;
        this.roles = res.result.map((role: ApplicationGroupResponse) => ({ id: role.id, name: role.name }));
      },
      (error) => {
        console.error('Error fetching roles:', error);
      }
    );
  }

  handleCancel() {
    this.cancel.emit();
  }

}