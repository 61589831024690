<nz-modal nzWidth="90%" [(nzVisible)]="ManageRole" [nzTitle]="modalTitle"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', }" [nzStyle]="{ top: '15px' }"
    (nzOnCancel)="closeDialog()">
    <div class="content" *nzModalContent>
       
            <nz-card class="mt-1 mb-2" style="margin: -5px;" nzTitle="Standard Roles">

             <nz-card class="mt-2 custom-card" style="width:67%; ;">
                <div nz-row>
                    <div nz-col nzSpan="18">
                        <div class="row">
                            <div class="col RoleLink">
                                <h5>Full Admin</h5>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <p>Access to perform all actions on every item in Buildertrend.</p>
                            </div>
                        </div>
                    </div>
                    <div nz-col nzSpan="6">
                        <div class="row">
                            <div class="col text-end text-primary mb-2">
                                <span nz-icon nzType="arrow-right" nzTheme="outline"
                                    style="font-size: 20px; color: #004fd6;"></span>
                            </div>
                        </div>
                    </div>
                </div>
             </nz-card>

             <nz-card class="mt-1 custom-card" style="width:67%;  ">
                <div nz-row>
                    <div nz-col nzSpan="18">
                        <div class="row">
                            <div class="col RoleLink">
                                <h5>Full Admin</h5>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <p>Access to perform all actions on every item in Buildertrend.</p>
                            </div>
                        </div>
                    </div>
                    <div nz-col nzSpan="6">
                        <div class="row">
                            <div class="col text-end text-primary mb-2">
                                <span nz-icon nzType="arrow-right" nzTheme="outline"
                                    style="font-size: 20px; color: #004fd6;"></span>
                            </div>
                        </div>
                    </div>
                </div>
             </nz-card>


             <nz-card class="mt-1 custom-card" style="width:67%;  ">
                <div nz-row>
                    <div nz-col nzSpan="18">
                        <div class="row">
                            <div class="col RoleLink">
                                <h5>Full Admin</h5>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <p>Access to perform all actions on every item in Buildertrend.</p>
                            </div>
                        </div>
                    </div>
                    <div nz-col nzSpan="6">
                        <div class="row">
                            <div class="col text-end text-primary mb-2">
                                <span nz-icon nzType="arrow-right" nzTheme="outline"
                                    style="font-size: 20px; color: #004fd6;"></span>
                            </div>
                        </div>
                    </div>
                </div>
             </nz-card>
             </nz-card>

              <nz-card  class="mt-1 mb-2" style=" margin: -5px;" nzTitle="Custom Roles" [nzExtra]="extraTemplate">

            <nz-card class="mt-2 custom-card" style="width:67%;  ">
                <div nz-row>
                    <div nz-col nzSpan="18">
                        <div class="row">
                            <div class="col RoleLink">
                                <h5>Full Admin</h5>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <p>Access to perform all actions on every item in Buildertrend.</p>
                            </div>
                        </div>
                    </div>
                    <div nz-col nzSpan="6">
                        <div class="row">
                            <div class="col text-end text-primary mb-2">
                                <span nz-icon nzType="arrow-right" nzTheme="outline"
                                    style="font-size: 20px; color: #004fd6;"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-card>


            <nz-card class="mt-1 custom-card" style="width:67%;">
                <div nz-row>
                    <div nz-col nzSpan="18">
                        <div class="row">
                            <div class="col RoleLink">
                                <h5>Full Admin12</h5>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <p>Access to perform all actions on every item in Buildertrend.</p>
                            </div>
                        </div>
                    </div>
                    <div nz-col nzSpan="6">
                        <div class="row">
                            <div class="col text-end text-primary mb-2">
                                <span nz-icon nzType="arrow-right" nzTheme="outline"
                                    style="font-size: 20px; color: #004fd6;"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-card>


            <nz-card class="mt-1 custom-card" style="width:67%; ">
                <div nz-row>
                    <div nz-col nzSpan="18">
                        <div class="row">
                            <div class="col RoleLink">
                                <h5>Full Admin</h5>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <p>Access to perform all actions on every item in Buildertrend.</p>
                            </div>
                        </div>
                    </div>
                    <div nz-col nzSpan="6">
                        <div class="row">
                            <div class="col text-end text-primary mb-2">
                                <span nz-icon nzType="arrow-right" nzTheme="outline"
                                    style="font-size: 20px; color: #004fd6;"></span>
                            </div>
                        </div>

                    </div>
                </div>
            </nz-card>

              </nz-card>


              <nz-card class="mt-1" style="margin: -5px;" nzTitle="Internal Users Custom Fields" [nzExtra]="extraTemplate">
            <nz-table #basicTable [nzData]="listOfData" [nzShowPagination]="false" class="custom-table">
                <thead>
                    <tr>
                        <th>Label</th>
                        <th>Data Type </th>
                        <th>Display Order </th>
                        <th>Required </th>
                        <th>Include In Filters
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of basicTable.data">
                        <td>{{ data.name }}</td>
                        <td>{{ data.age }}</td>
                        <td>{{ data.address }}</td>
                        <td>
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </td>
                        <td>
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </td>

                    </tr>
                </tbody>
            </nz-table>
              </nz-card>


               <ng-template #extraTemplate>
               <button nz-button nzType="primary" (click)="showrolemanagememtCustomField()">Create New Role</button>
               </ng-template>
    </div>
</nz-modal>