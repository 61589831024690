<nz-modal nzWidth="60%" [(nzVisible)]="newCostItem" [nzTitle]="Cost12" (nzOnCancel)="newCostItemCancel()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '73vh', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '37px' }" [nzFooter]="modalFooter"> <ng-template #Cost12>
        <div class="row p-0 m-0">
            <h1 class="p-0 m-0 fw-medium">Cost Item</h1>
        </div>
    </ng-template>
    <div class="cantent" *nzModalContent>
<!-- Saim Work Start on Cost Item work || Saim-New-Work || 5/14/2024  -->
        <div class="row">
            <div class="col-12">
                <nz-card [nzTitle]="Catalog">
                    <ng-template #Catalog>
                        <strong class="mt-1">Catalog</strong>
                    </ng-template>
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-12"> <label class="sub-text"> Cost Code
                                                <span class="text-danger">*</span> </label>
                                            <nz-select  [(ngModel)]="SubFormField['costCodeId']" name="costCodeId">

                                                <nz-option *ngFor="let option of costSubCodes"
                                                    [nzLabel]="option.title" [nzValue]="option.id"></nz-option>
                                            </nz-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row ">
                                        <div class="col-12">
                                            <label class="sub-text"> Cost Type
                                                <span class="text-danger">*</span>
                                            </label>

                                            <nz-tree-select [(ngModel)]="SubFormField.createCostTypeItemParameterRequests" name="createCostTypeItemParameterRequests"
                                                [nzNodes]="codeType" nzShowSearch nzAllowClear="false" nzCheckable
                                                nzCheckAll="true" nzPlaceHolder="Select Status">
                                            </nz-tree-select>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-6 mt-2">
                                    <label class="sub-text"> Title
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input [(ngModel)]="SubFormField['title']"  name="title" type="text"
                                        class="form-control formField">
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-6 mt-2"> <label for="text" class="sub-text">Description</label>
                                    <textarea [(ngModel)]="SubFormField['description']" name="description"
                                        class="formField" rows="4" nz-input style="height: 50px;"></textarea>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-6 mt-2"> <label for="text" class="sub-text">Internal Notes</label>
                                    <textarea [(ngModel)]="SubFormField['internalNotes']" name="internalNotes"
                                        class="formField" rows="4" nz-input style="height: 50px;"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-card>
                <!-- Sencond Card -->
                <nz-card [nzTitle]="CostInformation" class="mt-2">
                    <ng-template #CostInformation>
                        <strong class="mt-1">Cost Information</strong>
                    </ng-template>
                    <div class="row">
                        <div class="col">
                            <div class="row">
                                <div class="col-12">
                                    <label nz-checkbox [(ngModel)]="isCheckClicked"  (nzCheckedChange)="onItemChecked()" [(ngModel)]="SubFormField['quantity']" name="quantity">Include Quantity</label>

                                    <i class="bi bi-info-circle-fill text-secondary" nz-popover
                                        [nzPopoverContent]="contentTemplate" nzPopoverPlacement="right"></i>
                                    <ng-template #contentTemplate>
                                        <div class="rounded-2">
                                            <p style="height: auto; width: 180px;"> This Service Coordinator box
                                                allows
                                                you to limit communications on this warranty request to one employee
                                            </p>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 mt-3"> 
                                    <label nz-checkbox [(ngModel)]="isCheckClicked2" (nzCheckedChange)="onItemChecked()" [(ngModel)]="SubFormField['ownerPrice']" name="ownerPrice">
                                        Include Owner Price</label>


                                        
                                        
                                    <i class="bi bi-info-circle-fill text-secondary" nz-popover
                                        [nzPopoverContent]="contentTemplate" nzPopoverPlacement="right"></i>
                                    <ng-template #contentTemplate>
                                        <div class="rounded-2">
                                            <p style="height: auto; width: 180px;"> This Service Coordinator box
                                                allows
                                                you to limit communications on this warranty request to one employee
                                            </p>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="row gx-4">
                                <div class="col-2 mt-2 ms-2" >
                                    <label class="sub-text">Unit Cost</label>
                                    <br>
                                    <input [(ngModel)]="SubFormField['unitCost']"  name="unitCost" type="number" class="form-control formField" />
                                  </div>
                                <div class="col-3 mt-2">
                                    <label class="sub-text">Unit</label> <input [(ngModel)]="SubFormField['unit']"
                                        name="unit" type="text" class="form-control formField" />
                                </div>
                                <div class="col-2 mt-2" *ngIf="IncludeQuantityField">
                                    <label class="sub-text">Quantity</label>
                                    <input [(ngModel)]="SubFormField['quantity']" name="quantity" type="number" class="form-control formField" />
                                  </div>
                                <div class="col-2 mt-2 ms-2" >
                                    <label class="sub-text">Builder Cost</label>
                                    <br>
                                    <p class="mt-1 ms-4" [(ngModel)]="SubFormField['builderCost']" name="builderCost">
                                        ${{ totalBuilderCost.toFixed(2) }}</p>
                                </div>
                            </div>
                            

                            <div class="row gx-0" *ngIf="IncludeOwnerField">
                                <div class="col-2" style="margin-top: 7px;">
                                  <label class="sub-text">Markup</label>
                                  <div class="input-group">
                                    <span class="input-group-text" style="height: 32px;">
                                      <ng-container *ngIf="SubFormField['markupValue'] === '1'">
                                        $ 
                                      </ng-container>
                                      <ng-container *ngIf="SubFormField['markupValue'] === '2'">
                                        % 
                                      </ng-container>
                                      <ng-container *ngIf="SubFormField['markupValue'] === '3'">
                                        $ 
                                      </ng-container>
                                    </span>
                                    <input [(ngModel)]="SubFormField['markupId']" name="margin" type="number"
                                           class="form-control formField" aria-label="Amount (to the nearest dollar)"
                                           [disabled]="SubFormField['markupValue'] === '4'">
                                  </div>
                                </div>
                              
                                <div class="col-1" style="margin-top: 36px;">
                                    <nz-select [(ngModel)]="SubFormField['markupValue']" name="markupValue" style="width: 100%"
                                               (ngModelChange)="handleOptionSelect($event)">
                                      <nz-option [nzValue]="'1'" nzLabel="$"></nz-option> 
                                      <nz-option [nzValue]="'2'" nzLabel="%"></nz-option>  
                                      <nz-option [nzValue]="'3'" nzLabel="$unit"></nz-option> 
                                      <nz-option [nzValue]="'4'" nzLabel="----"></nz-option> 
                                    </nz-select>
                                  </div>
                              
                                <div class="col-3 mt-2 ms-4">
                                  <label class="sub-text">Margin</label>
                                  <nz-input-group nzAddOnAfter="%">
                                    <input [(ngModel)]="SubFormField['margin']" name="margin" type="number"
                                           class="form-control formField" nz-input  />
                                  </nz-input-group>
                                </div>
                              
                                <div class="col-3 mt-2 ms-4">
                                    <label class="sub-text">Owner Price</label>
                                    <br>
                                    <p class="mt-1 ms-4" nzLabel="----" *ngIf="!isOption4Selected">
                                      $0.00
                                    </p>
                                  <nz-input-group nzAddOnBefore="$" *ngIf="isOption4Selected">
                                    <input [(ngModel)]="SubFormField['ownerPrice']" name="ownerPrice" type="number"
                                           class="form-control formField" nz-input  />
                                  </nz-input-group>
                                </div>
                              </div>
                              
                        </div>
                    </div>
                </nz-card>
            </div>
        </div>

    </div>


    <ng-template #modalFooter>
        <button nz-button nzType="primary" class="rounded-1 me-0">Save & New</button>
        <button nz-button  nzType="primary" class="rounded-1 me-0" [disabled]="!SubFormField['costCodeId'] || !SubFormField['title']"  (click)=" saveCatalog()" >Save</button>
    </ng-template>
    <!-- Saim End Work on Cost Item work || Saim-New-Work || 5/14/2024  -->

</nz-modal>