import { Component, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { ProjectManegmentScheduleItemDetailsPreDefinedCodes, ProjectManegmentSchedulePreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { ApplicationUserForComboResponse } from 'src/Models/InternalUser/InternalUserJob';
import { ColorResponse } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { ReminderLimitSetupResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { PhaseService } from 'src/Service/CompanyInFormation/ScheDule/Phase/phase.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { colorService } from 'src/Service/Job-List/Job-Information/color.service';
import { SubVendersService } from 'src/Service/SubVendorsServices/sub-venders.service';
import { PhaseSetup } from 'src/Models/CompanySettings/Schedule/PhaseSetup/PhaseSetup';
import { InnerTabPhasesPhaseSetupComponent } from '../Inner-Related-Items/inner-tab-phases-phase-setup/inner-tab-phases-phase-setup.component';
import { AuditDetailsResponse, FilterFooterAudit } from 'src/Models/Footer/footer.model';
import { InnerTabPhasesTagsComponent } from '../Inner-Related-Items/inner-tab-phases-tags/inner-tab-phases-tags.component';
import { ScheduleSetupPhaseParameterResponse } from 'src/Models/CompanySettings/Schedule/PhaseSetup/Company-Settings-Schedule';
import { Subject } from 'rxjs';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { ScheduleService } from 'src/Service/Project-Management/schedule.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { scheduleItemByJob } from 'src/Models/Project-Management/projectManagement';
import { FooterService } from 'src/Service/Footer/footer.service';
import { saveAttachments } from 'src/app/helpers/Attachment_Save';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ResponseScheduleItemResult } from 'src/Models/Project-Management/schedule';
import { ScheduleSetupService } from 'src/Service/CompanyInFormation/ScheDule/schedule-setup.service';
import { AttachmentResponse, CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';

export function noDuplicatePredecessors(predecessors: FormArray): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const hasDuplicates = new Set(predecessors.value.map((p: any) => p.scheduleItemPredecessorParameterId)).size !== predecessors.length;
    return hasDuplicates ? { duplicatePredecessors: true } : null;
  };
}

export function endTimeAfterStartTimeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const startTime = control.get('startTime')?.value;
    const endTime = control.get('endTime')?.value;

    if (startTime && endTime && startTime >= endTime) {
      return { endTimeInvalid: true }; // Return an error if the condition is not met
    }
    return null; // No error
  };
}


function parseTime(time: string): [number, number] {
  const [timePart, modifier] = time.split(' ');
  let [hours, minutes] = timePart.split(':').map(Number);

  if (modifier === 'pm' && hours < 12) {
    hours += 12;
  } else if (modifier === 'am' && hours === 12) {
    hours = 0;
  }

  return [hours, minutes];
}

@Component({
  selector: 'app-newschedule-item',
  templateUrl: './newschedule-item.component.html',
  styleUrls: ['./newschedule-item.component.css'],
})


export class NewscheduleItemComponent implements OnInit {
  sliderValue: number = 0;
  inputValue: number = 0;
  scheduleForm: FormGroup;
  @Output() cancel = new EventEmitter<void>();
  @ViewChild('carouselContainer', { static: false }) carouselContainer!: ElementRef<HTMLDivElement>;
  scheduleVisible = true;
  newScheduleItem = [1, 2];
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  pageId: number = 8;
  colors: ColorResponse[] = [];
  JobInfoId: number;
  ScheduleItemId: number;
  Messaging_Id: number;
  formNameId: number = 8;
  // Footer Starts
  footerResponse: AuditDetailsResponse;
  // Footer End
  selectedFiless: CustomUploadFile[] = [];
  showNavButtons = false;
  currentIndex = 0;
  // sliderValue: number = 0;
  timeOptions: string[] = [
    '08:00 am',
    '08:30 am',
    '09:00 am',
    '09:30 am',
    '10:00 am',
    '10:30 am',
    '11:00 am',
    '11:30 am',
    '12:00 am',
    '12:30 am',
    '08:00 pm',
    '08:30 pm',
    '09:00 pm',
    '09:30 pm',
    '10:00 pm',
    '10:30 pm',
    '11:00 pm',
    '11:30 pm',
    '12:00 pm',
    '12:30 pm'
  ];
  reminderList: ReminderLimitSetupResponse[] = [];
  typeList: LookupNameSetupResponse[] = [];
  date: any;
  combinedNodes: NzTreeNodeOptions[] = [];
  prefixTemplateClock: string | TemplateRef<void>;
  phaseList: PhaseSetup[] = [];
  tagResponse: any;
  scheduleItemType: LookupNameSetupResponse[] = [];
  tagNodes: NzTreeNodeOptions[] = [];
  private phasesLoaded = new Subject<void>();
  private tagLoaded = new Subject<void>();

  maxPredecessorRows = 5;
  hasAttemptedSubmit = false;
  preDefinedDataCodes = {
    Reminder: ProjectManegmentScheduleItemDetailsPreDefinedCodes.Reminder,
    Type: ProjectManegmentScheduleItemDetailsPreDefinedCodes.Type,
    Tags: ProjectManegmentSchedulePreDefinedCodes.Tags,
  };
  errorMessages: string[] = [];
  scheduleFormErrorMessage: string = '';
  titleErrorMessage: string = '';
  allNotesErrorMessage: string = '';
  internalNotesErrorMessage: string = '';
  subNotesErrorMessage: string = '';
  ownerNotesErrorMessage: string = '';
  isSingleTagSelected: boolean = false;
  private isFormUpdateInProgress = false;  // General flag for form value updates

  // Current Job Id and Name and color getting dashboard Start

  scheduleId: number;
  selectedJobId: number;
  selectedJobName: string;
  selectedColorId: number;
  predecessorList: scheduleItemByJob[];
  // response after save
  scheduleResponse: ResponseScheduleItemResult;

  // Current Job Id and Name and color getting dashboard End


  confirmModal?: NzModalRef;
  constructor(private fb: FormBuilder,
    private modal: NzModalService,
    private LookupFilterService: LookupFilterService,
    private colorService: colorService,
    private phaseService: PhaseService,
    private customerInformationService: CustomerInformationService,
    private SubVendorService: SubVendersService,
    private _AttachmentService: AttachmentService,
    private _scheduleService: ScheduleService,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _footerService: FooterService,
    public _toastService: NzMessageService,
    public _scheduleSetupService: ScheduleSetupService,
  ) { }

  // this._scheduleService.getPredecessorLinkedData(this.selectedJobId).subscribe(res => {

  // })

  ngOnInit(): void {
    this._loadingIndicatorService.show(); // Show the loading indicator at the start
    // Wrap the initialization logic in a Promise
    this.initializeApp().finally(() => {
      this._loadingIndicatorService.hide(); // Hide the loading indicator once all tasks are complete
    });

  }

  private async initializeApp(): Promise<void> {
    this.initializeForm();
    try {
      await this.initLoad();
      await this.ForCombo();
      await this.getPhases();
      await this.getPredecessorsByJob();
      if (this.scheduleId) {
        await this._scheduleService.scheduleGetById(this.scheduleId).toPromise().then(res => {
          this.scheduleResponse = res.result;
          this.patchFormValues(res.result);
          this.createAndModifiedByData(res.result.Id);
          console.log(res.result);

        });
      } else {
        await this._scheduleSetupService.getScheduleDataByCompany().toPromise().then(res => {
          this.scheduleForm.patchValue({
            scheduleItemReminderId: res.result.scheduleItemReminderId,
          });
        });
      }

      // Handle colors
      if (!this.scheduleId && this.colors && this.colors.length > 0) {
        this.updateSelectedColor(this.selectedColorId);
        this.scheduleForm.patchValue({
          colorId: this.selectedColorId
        });
      } else if (!this.scheduleId) {
        console.warn("Colors Not Available");
      }



      this.setupFormValueChangeHandler();

      // Subscribe to isComplete checkbox changes
      this.scheduleForm.get('isComplete')?.valueChanges.subscribe((isChecked) => {
        this.scheduleForm.get('progressPercentage')?.setValue(isChecked ? 100 : 0); // Set progress based on checkbox
      });

      this.scheduleForm.get('progressPercentage')?.valueChanges.subscribe((value) => {
        this.scheduleForm.get('progressPercentage')?.setValue(value, { emitEvent: false });
      });

      this.scheduleForm.statusChanges.subscribe(() => {
        if (this.hasAttemptedSubmit) {
          this.errorMessages = this.getFormValidationErrors();
        }
      });

      this.scheduleForm.get('isHourly')?.valueChanges.subscribe((isHourly) => {
        const startOnControl = this.scheduleForm.get('startTime');
        if (isHourly) {
          startOnControl?.setValidators([Validators.required]); // Add required validator
        } else {
          startOnControl?.clearValidators(); // Remove required validator
        }
        startOnControl?.updateValueAndValidity(); // Re-run validation on `StartOn`
      });

    } catch (error) {
      console.error("Error during initialization:", error);
    }
  }


  initializeForm() {
    const today = new Date();
    const nextWorkingDay = this.getNextWorkingDay(today);

    this.scheduleForm = this.fb.group({
      id: [],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      jobSetupId: [0],
      jobInformationId: [this.selectedJobId],
      title: ['', [Validators.required, Validators.maxLength(50)]],
      colorId: [null],
      startOn: [nextWorkingDay],
      workDays: [1, Validators.required],
      endOn: [nextWorkingDay],
      isHourly: [false],
      startTime: [null],
      endTime: [null],
      progressPercentage: [0],
      scheduleItemReminderId: [null],
      isShowOnGantt: [true],
      isShowOwnerPhasesOnly: [true],
      isComplete: [false],
      phaseSetupId: [0],
      allNotes: ['', Validators.maxLength(2000)],
      internalNotes: ['', Validators.maxLength(2000)],
      subNotes: ['', Validators.maxLength(2000)],
      ownerNotes: ['', Validators.maxLength(2000)],
      createScheduleItemAssigneeRequests: [null],
      createScheduleItemPredecessorParameterRequests: this.fb.array([]),
      createScheduleItemTagParameterRequests: [null],
      createScheduleItemSubVendorParameterRequests: this.fb.array([]),
    }, { validators: endTimeAfterStartTimeValidator() });

    this.setupFormSubscriptions();
    this.addPredecessorRow();

    // Watchers for date changes to recalculate lag numbers
    this.scheduleForm.get('startOn')?.valueChanges.subscribe(() => {
      this.updateLagNumbersOnDateChange();
    });

    this.scheduleForm.get('endOn')?.valueChanges.subscribe(() => {
      this.updateLagNumbersOnDateChange();
    });



    this.scheduleForm.get('startOn')?.valueChanges.subscribe((startDate) => {
      this.onChangeWorkDays();
    });

    this.scheduleForm.get('workDays')?.valueChanges.subscribe(() => {
      this.onChangeWorkDays();
    });
  }



  patchFormValues(scheduleItem: ResponseScheduleItemResult): void {
    this.selectedJobName = scheduleItem.JobName;
    if (this.colors && this.colors.length > 0) {
      this.updateSelectedColor(scheduleItem.ColorId);
      this.scheduleForm.patchValue({
        colorId: scheduleItem.ColorId
      });
    }
    // Handle AssigneeIds and map them as an array of strings
    if (this.combinedNodes && scheduleItem.AssigneeIds) {
      // console.log('scheduleItem.AssigneeIds', scheduleItem.AssigneeIds);
      const assigneeIdsArray = scheduleItem.AssigneeIds.split(',').map(id => id.trim()); // Keep them as strings
      this.scheduleForm.patchValue({
        createScheduleItemAssigneeRequests: assigneeIdsArray
      });
    }

    if (this.tagNodes && scheduleItem.TagIds) {
      // console.log('scheduleItem.TagIds', scheduleItem.TagIds);
      const tagIdsArray = scheduleItem.TagIds.split(',').map(id => id.trim()); // Keep them as strings
      this.scheduleForm.patchValue({
        createScheduleItemTagParameterRequests: tagIdsArray
      });
    }



    this.Messaging_Id = scheduleItem.MessagingId;
    this.JobInfoId = scheduleItem?.JobInformationId;
    this.ScheduleItemId = scheduleItem?.Id;
    this.formNameId = 8;

    if (scheduleItem.Predecessors) {
      // Clear existing predecessor rows to avoid duplicates
      this.predecessorRows.clear();

      // Split and map the Predecessors string
      const predecessorsArray = scheduleItem.Predecessors.split(',').map(predecessor => {
        const [paramId, typeSetupId, lag] = predecessor.split('-').map(id => id.trim());

        return this.fb.group({
          scheduleItemPredecessorParameterId: [Number(paramId)], // Convert to number
          scheduleItemPredecessorTypeSetupId: [Number(typeSetupId)],
          lagNumber: [Number(lag)],
        });
      });

      // Push each group as a new FormGroup into the predecessorRows FormArray
      predecessorsArray.forEach(predecessor => this.predecessorRows.push(predecessor));
    }




    if (scheduleItem?.AttachmentId) {
      this.fetchAttachments(scheduleItem?.AttachmentId);
    }
    this.tabVisibility(scheduleItem);




    this.scheduleForm.patchValue({
      id: scheduleItem.Id,
      globalId: scheduleItem.GlobalId,
      jobSetupId: scheduleItem.JobSetupId,
      jobInformationId: scheduleItem.JobInformationId,
      jobName: scheduleItem.JobName,
      title: scheduleItem.Title,
      startOn: scheduleItem.StartOn,
      workDays: scheduleItem.WorkDays,
      endOn: scheduleItem.EndOn,
      isHourly: scheduleItem.IsHourly,
      startTime: scheduleItem.StartTime,
      endTime: scheduleItem.EndTime,
      progressPercentage: scheduleItem.ProgressPercentage,
      scheduleItemReminderId: scheduleItem.ScheduleItemReminderId,
      isShowOnGantt: scheduleItem.IsShowOnGantt ?? true,
      isShowOwnerPhasesOnly: scheduleItem.IsShowOwnerPhasesOnly ?? true,
      isComplete: scheduleItem.ProgressPercentage === 100 ? true : false,
      phaseSetupId: scheduleItem.PhaseSetupId,
      allNotes: scheduleItem.AllNotes,
      internalNotes: scheduleItem.InternalNotes,
      subNotes: scheduleItem.SubNotes,
      ownerNotes: scheduleItem.OwnerNotes,
    });

  }



  setupFormValueChangeHandler() {
    const noteFields = ['allNotes', 'internalNotes', 'subNotes', 'ownerNotes'];
    noteFields.forEach(field => {
      this.scheduleForm.get(field)?.valueChanges.subscribe(value => {
        if (this.hasAttemptedSubmit) {
          this.setFieldErrorMessage(field, value, 2000);
        }
      });
    });

    // Track changes for the title field
    this.scheduleForm.get('title')?.valueChanges.subscribe(value => {
      if (this.hasAttemptedSubmit) {
        this.setFieldErrorMessage('title', value, 50);
      }
    });

    // Track changes for the StartOn and EndOn dates
    this.scheduleForm.get('startOn')?.valueChanges.subscribe(() => {
      if (this.hasAttemptedSubmit) {
        this.errorMessages = this.getFormValidationErrors();
      }
    });
    this.scheduleForm.get('endOn')?.valueChanges.subscribe(() => {
      if (this.hasAttemptedSubmit) {
        this.errorMessages = this.getFormValidationErrors();
      }
    });
  }

  setFieldErrorMessage(fieldName: string, value: string, maxLength: number): void {
    const currentLength = value ? value.length : 0;
    const overCount = currentLength - maxLength;
    const message = overCount > 0 ? `${overCount} character${overCount > 1 ? 's' : ''} over` : '';

    // Set specific error messages for each field
    switch (fieldName) {
      case 'title':
        this.titleErrorMessage = message;
        break;
      case 'allNotes':
        this.allNotesErrorMessage = message;
        break;
      case 'internalNotes':
        this.internalNotesErrorMessage = message;
        break;
      case 'subNotes':
        this.subNotesErrorMessage = message;
        break;
      case 'ownerNotes':
        this.ownerNotesErrorMessage = message;
        break;
    }
  }


  getFormValidationErrors(): string[] {
    const errorMessages: string[] = [];

    Object.keys(this.scheduleForm.controls).forEach(key => {
      const control = this.scheduleForm.get(key);
      const controlErrors = control?.errors;
      const fieldLabel = this.getFieldLabel(key);

      if (controlErrors) {
        if (controlErrors['required']) {
          errorMessages.push(`${fieldLabel}: Required`);
        }
        if (controlErrors['maxlength']) {
          const maxLength = controlErrors['maxlength'].requiredLength;
          const actualLength = controlErrors['maxlength'].actualLength;
          const overCount = actualLength - maxLength;
          errorMessages.push(`${fieldLabel}: ${overCount} character${overCount > 1 ? 's' : ''} over `);
        }
      }
    });

    // Check for form-level errors (e.g., endTimeInvalid)
    if (this.scheduleForm.errors?.['endTimeInvalid']) {
      errorMessages.push(`End Time: End Time must be after Start Time.`);
    }

    // Check for duplicate predecessors
    const predecessorErrors = this.checkForDuplicatePredecessors();
    if (predecessorErrors.length > 0) {
      errorMessages.push(...predecessorErrors);
    }

    return errorMessages;
  }

  // Method to check for duplicate predecessors and show messages for each row separately
  checkForDuplicatePredecessors(): string[] {
    const duplicateMessages: string[] = [];
    const predecessorValues = this.predecessorRows.controls.map((row, index) => ({
      id: row.value.scheduleItemPredecessorParameterId,
      position: index + 1
    }));

    const seenPredecessors = new Map<number, number[]>(); // Map to track predecessor IDs and their positions

    predecessorValues.forEach(predecessor => {
      if (predecessor.id) {
        if (!seenPredecessors.has(predecessor.id)) {
          seenPredecessors.set(predecessor.id, [predecessor.position]); // Add position of first occurrence
        } else {
          seenPredecessors.get(predecessor.id)?.push(predecessor.position); // Track all duplicate positions
        }
      }
    });

    // Loop through the Map to create error messages for duplicate predecessors
    seenPredecessors.forEach((positions, id) => {
      if (positions.length > 1) { // Only create error messages for duplicates
        positions.forEach(pos => {
          duplicateMessages.push(`Predecessor ${pos}: Cannot add predecessor more than once`);
        });
      }
    });

    return duplicateMessages;
  }


  // Helper method to map form control names to user-friendly labels
  getFieldLabel(fieldName: string): string {
    const fieldLabels: { [key: string]: string } = {
      title: 'Title',
      allNotes: 'All Notes',
      internalNotes: 'Internal Notes',
      subNotes: 'Sub Notes',
      ownerNotes: 'Owner Notes',
      startOn: 'Start Date',
      workDays: 'Work Days',
      endOn: 'End Date',
      startTime: 'Start Time',
      endTime: 'End Time'
    };
    return fieldLabels[fieldName] || fieldName;
  }




  // Method to toggle `isHourly` value
  toggleIsHourly(): void {
    const currentValue = this.scheduleForm.get('isHourly')?.value;
    this.scheduleForm.patchValue({ isHourly: !currentValue });
  }

  // Track value changes for fields to dynamically update errors after submission


  createAndModifiedByData(id: number) {
    const payload: FilterFooterAudit = {
      id: id,
      formCode: 'PMSS'
    }
    this._footerService.createdAndModifyUser(payload).subscribe(res => {
      this.footerResponse = res.result;
    })
  }


  // Method to scroll to the top of the form
  scrollToTop() {
    const formElement = document.getElementById('scheduleForm'); // Replace with your form's ID
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }




  getPredecessorsByJob(): void {
    this._scheduleService.getAllPredecessorsDataScheduleItem(this.selectedJobId).subscribe(res => {
      const selectedEditModeScheduleId = this.scheduleId;
      const allPredecessors = res.result || [];
      this.predecessorList = allPredecessors.filter(predecessor => predecessor.Id !== selectedEditModeScheduleId);
    });
  }





  private previousLagNumbers: number[] = [];
  private isUpdatingLagFromPredecessor: boolean = false;

  onPredecessorChange(selectedId: any, index: number): void {
    const selectedPredecessor = this.predecessorList.find(item => item.Id === selectedId);
    if (selectedPredecessor) {
      const predecessorEndOn = new Date(selectedPredecessor.EndOn);
      const currentStartOn = new Date(this.scheduleForm.get('startOn')?.value);
      const normalizedPredecessorEndOn = new Date(predecessorEndOn.getFullYear(), predecessorEndOn.getMonth(), predecessorEndOn.getDate());
      const normalizedCurrentStartOn = new Date(currentStartOn.getFullYear(), currentStartOn.getMonth(), currentStartOn.getDate());
      let lagDays: number = 0;

      // Calculate lag days based on predecessor and current start date
      if (normalizedCurrentStartOn < normalizedPredecessorEndOn) {
        lagDays = Math.floor((normalizedCurrentStartOn.getTime() - normalizedPredecessorEndOn.getTime()) / (1000 * 60 * 60 * 24)) - 1;
      } else if (normalizedCurrentStartOn.getTime() === normalizedPredecessorEndOn.getTime()) {
        lagDays = -1;
      } else {
        lagDays = 0;
      }

      // Update the lag days
      this.isUpdatingLagFromPredecessor = true;
      this.predecessorRows.at(index).get('lagNumber')?.setValue(lagDays);
      this.previousLagNumbers[index] = lagDays; // Update previous lag numbers
      this.isUpdatingLagFromPredecessor = false;
    }

    // Check for duplicates in scheduleItemPredecessorParameterId
    const duplicate = this.predecessorRows.controls.some((control, idx) =>
      idx !== index && control.get('scheduleItemPredecessorParameterId')?.value === selectedId
    );

    // Set error if duplicate found on the current control
    if (duplicate) {
      this.predecessorRows.at(index).get('scheduleItemPredecessorParameterId')?.setErrors({ duplicate: true });
    } else {
      // Clear errors if not a duplicate on the current control
      this.predecessorRows.at(index).get('scheduleItemPredecessorParameterId')?.setErrors(null);
    }

    // Iterate through all controls to ensure duplicate errors are dynamically updated
    this.predecessorRows.controls.forEach((control, idx) => {
      if (idx !== index) {
        const controlId = control.get('scheduleItemPredecessorParameterId')?.value;

        // Check if the current control is a duplicate of any other control (excluding the changed one)
        const isDuplicateElsewhere = this.predecessorRows.controls.some((innerControl, innerIdx) =>
          innerIdx !== idx && innerControl.get('scheduleItemPredecessorParameterId')?.value === controlId
        );

        if (isDuplicateElsewhere) {
          control.get('scheduleItemPredecessorParameterId')?.setErrors({ duplicate: true });
        } else {
          // Clear duplicate errors if no longer a duplicate
          control.get('scheduleItemPredecessorParameterId')?.setErrors(null);
        }
      }
    });
  }

  // this._toastService.error('You cannot choose the same predecessor twice');


  onChangeLagNumber(index: number, newLagNumber: number): void {
    const predecessorRow = this.getCurrentPredecessorRow(index);
    const currentStartOn = this.scheduleForm.get('startOn')?.value;
    const currentEndOn = this.scheduleForm.get('endOn')?.value;

    if (currentStartOn && currentEndOn) { // Ensure both dates are present
      const startDate = new Date(currentStartOn);
      const endDate = new Date(currentEndOn);
      const previousLagNumber = this.previousLagNumbers[index] || 0;

      // Only update dates if lagNumber has changed
      if (newLagNumber !== previousLagNumber) {
        const lagDifference = newLagNumber - previousLagNumber;

        // Update the start date and end date only if there is a lag change and not from predecessor change
        if (!this.isUpdatingLagFromPredecessor) {
          const predecessorId = predecessorRow.get('scheduleItemPredecessorParameterId')?.value;

          // Prevent changes to dates if the predecessor ID is null
          if (predecessorId !== null && this.isIndexValid(index)) {
            const newStartOn = this.addBusinessDays(startDate, lagDifference);
            const newEndOn = this.addBusinessDays(endDate, lagDifference);

            // Update the schedule form with new dates only if the index is valid
            this.scheduleForm.patchValue({
              startOn: newStartOn,
              endOn: newEndOn
            });
          }
        }

        // Update previousLagNumbers to reflect the new lag value
        this.previousLagNumbers[index] = newLagNumber;
      }
    }
  }

  // Method to check if the index is valid for changing dates
  private isIndexValid(index: number): boolean {
    const predecessorRequests = this.predecessorRows.controls
      .map(row => row.value)
      .filter(predecessor => predecessor.scheduleItemPredecessorParameterId !== null);

    // Check if the current index is part of valid predecessor rows
    return predecessorRequests.length > 0 && predecessorRequests[index] !== undefined && predecessorRequests[index].scheduleItemPredecessorParameterId !== null;
  }

  // Helper function to add business days to a date (skips weekends)
  addBusinessDays(date: Date, days: number): Date {
    let result = new Date(date);
    let daysAdded = 0;

    console.log(`Adding ${days} business days to ${result}`);

    while (daysAdded < Math.abs(days)) {
      result.setDate(result.getDate() + (days > 0 ? 1 : -1));

      // Check if the resulting day is a weekday
      const day = result.getDay();
      if (day !== 0 && day !== 6) { // Not Sunday (0) and not Saturday (6)
        daysAdded++;
      }
    }

    console.log(`New date after adding business days: ${result}`);
    return result;
  }


  private updateLagNumbersOnDateChange() {
    this.predecessorRows.controls.forEach((row, index) => {
      const selectedPredecessorId = row.get('scheduleItemPredecessorParameterId')?.value;
      if (selectedPredecessorId) {
        this.onPredecessorChange(selectedPredecessorId, index); // Recalculate lag based on predecessor
      }
    });
  }



  // Modify setupFormSubscriptions to use `onChangeLagNumber` only when lagNumber changes
  setupFormSubscriptions() {
    this.scheduleForm.get('startOn')?.valueChanges.subscribe(startDate => {
      if (this.isFormUpdateInProgress) return;

      this.isFormUpdateInProgress = true;
      const endDateControl = this.scheduleForm.get('endOn');
      const endDate = new Date(endDateControl?.value);
      const start = new Date(startDate);

      if (startDate && start > endDate) {
        endDateControl?.setValue(start, { emitEvent: false });
      }

      this.updateDate();
      this.isFormUpdateInProgress = false;
    });

    this.scheduleForm.get('endOn')?.valueChanges.subscribe(endDate => {
      if (this.isFormUpdateInProgress) return;

      this.isFormUpdateInProgress = true;
      const startDateControl = this.scheduleForm.get('startOn');
      const startDate = new Date(startDateControl?.value);
      const end = new Date(endDate);

      if (endDate && end < startDate) {
        startDateControl?.setValue(end, { emitEvent: false });
      }

      this.updateDate();
      this.isFormUpdateInProgress = false;
    });

    // Keep workDays subscription as is
  }

  // Method to update work days based on current startOn and endOn values
  updateDate() {
    const startOn = this.scheduleForm.get('startOn')?.value ? new Date(this.scheduleForm.get('startOn')?.value) : null;
    const endOn = this.scheduleForm.get('endOn')?.value ? new Date(this.scheduleForm.get('endOn')?.value) : null;

    if (!startOn || !endOn) {
      return; // Do nothing if either date is null
    } else {
      const workDays = this.calculateBusinessDays(startOn, endOn);
      this.isFormUpdateInProgress = true;
      this.scheduleForm.get('workDays')?.setValue(workDays, { emitEvent: false }); // Prevent triggering the workDays subscription
      this.isFormUpdateInProgress = false;
    }
  }

  // Calculate the number of business days between two dates
  calculateBusinessDays(startOn: Date, endOn: Date): number {
    let totalDays = 0;
    for (let d = new Date(startOn); d <= endOn; d.setDate(d.getDate() + 1)) {
      const day = d.getDay();
      // Count only weekdays (Monday to Friday)
      if (day !== 0 && day !== 6) {
        totalDays++;
      }
    }

    return totalDays; // Return total business days
  }

  onChangeWorkDays() {
    const startOn = this.scheduleForm.get('startOn')?.value;
    const workDays = this.scheduleForm.get('workDays')?.value || 1; // Default to 1 if undefined
    if (startOn) {
      const newEndDate = new Date(startOn);
      if (workDays === 1) {
        newEndDate.setDate(newEndDate.getDate()); // No change, end date is same as start date
      } else if (workDays > 1) {
        newEndDate.setDate(newEndDate.getDate() + (workDays - 1));
      }
      this.scheduleForm.patchValue({ endOn: newEndDate }, { emitEvent: false });
    }
  }


  disableWeekend(date: Date): boolean {
    const day = date.getDay();
    const isWeekend = day === 0 || day === 6;
    console.log(`Day ${date}: Weekend?`, isWeekend);
    return isWeekend;
  }



  getNextWorkingDay(date: Date): Date {
    const nextWorkingDate = new Date(date);
    const day = nextWorkingDate.getDay();

    // If Saturday (6) or Sunday (0), move to Monday
    if (day === 6) { // Saturday
      nextWorkingDate.setDate(nextWorkingDate.getDate() + 2);
    } else if (day === 0) { // Sunday
      nextWorkingDate.setDate(nextWorkingDate.getDate() + 1);
    }

    return nextWorkingDate;
  }

  // Method to handle form submission and display error messages

  isSaving: boolean = false;
  isSaveAndNew: boolean = false;
  isSaveAndClose: boolean = false;
  DisabledNextActivity: boolean = false;

  saveButton(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save'): Promise<void> {
    return new Promise((resolve, reject) => {
      // Check if already saving
      if (this.isSaving || this.isSaveAndClose || this.isSaveAndNew) {
        return reject('Already processing');
      }

      this.hasAttemptedSubmit = true;
      this.errorMessages = [];

      // Validate the form
      if (this.scheduleForm.invalid) {
        Object.values(this.scheduleForm.controls).forEach(control => {
          control.markAsDirty();
          control.updateValueAndValidity();
        });

        this.errorMessages = this.getFormValidationErrors(); // Get error messages
        this.scrollToTop();
        return reject('Form is invalid'); // Reject here
      }

      this.DisabledNextActivity = true; // Disable next activity

      // Set appropriate flags based on the actionType
      if (actionType === 'saveAndNew') {
        this.isSaveAndNew = true;
      } else if (actionType === 'saveAndClose') {
        this.isSaveAndClose = true;
      } else {
        this.isSaving = true;
      }

      // Construct the tag and assignee parameter requests
      const selectedTags = this.scheduleForm.get('createScheduleItemTagParameterRequests')?.value || [];
      const tagRequests = Array.isArray(selectedTags) ? selectedTags.map(tagId => ({ scheduleItemTagSetupId: tagId })) : [];

      const selectedAssignees = this.scheduleForm.get('createScheduleItemAssigneeRequests')?.value || [];
      const assigneeRequests = Array.isArray(selectedAssignees) ? selectedAssignees.map(userId => ({ applicationUserId: userId })) : [];

      const predecessorRequests = this.predecessorRows.controls
        .map(row => row.value)
        .filter(predecessor => predecessor.scheduleItemPredecessorParameterId !== null);

      // Construct the schedule data payload
      const scheduleData = {
        ...this.scheduleForm.value,
        createScheduleItemTagParameterRequests: tagRequests,
        createScheduleItemAssigneeRequests: assigneeRequests,
        createScheduleItemPredecessorParameterRequests: predecessorRequests
      };

      this._scheduleService.postSchedule(scheduleData).subscribe({
        next: (response) => {
          this.isSaving = false;
          this.DisabledNextActivity = false;
          this.scheduleResponse = response.result;
          this.Messaging_Id = response.result?.MessagingId;
          this.JobInfoId = response.result?.JobInformationId;
          this.ScheduleItemId = response.result?.Id;
          this.scheduleForm.patchValue({ globalId: response.result.GlobalId });

          if (this.selectedFilesAttachment?.length > 0) {
            saveAttachments(this.selectedFilesAttachment, this._AttachmentService, response.result.Id, this.pageId);
          }

          if (response) {
            this.createAndModifiedByData(response.result.Id);
          }

          // Show the "Messaging" tab after save
          const messagingTab = this.tabs.find(tab => tab.title === 'Messaging');
          if (messagingTab) {
            messagingTab.show = true; // Set the "Messaging" tab to be shown
          }

          this._toastService.success('Saved Successfully');
          this.resetFlags(); // Reset flags after successful save
          resolve(); // Resolve promise on success
        },
        error: (error) => {
          this.isSaving = false;
          this.DisabledNextActivity = false; // Re-enable next activity on error

          if (error?.status === 500) {
            this._toastService.error('Server error: Unable to save. Please try again later.');
          } else {
            this._toastService.error('Error saving schedule. Please check your input.');
          }

          console.error('Error saving schedule:', error);
          this.resetFlags(); // Reset flags after failure
          reject('Error saving schedule'); // Reject promise on error
        }
      });
    });
  }


  resetFlags() {
    this.isSaving = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
    this.DisabledNextActivity = false;
  }

  saveAndNewSchedule() {
    this.saveButton('saveAndNew')
      .then(() => {
        this.resetForm();
        this.scheduleResponse = null;
        this.TestAttachment = null;
        this.selectedFilesAttachment = null;
      })
      .catch((error) => {
        console.error('Error during save and new:', error);
      });
  }

  saveAndCloseSchedule() {
    return this.saveButton('saveAndClose')
      .then(() => {
        this.resetForm();
        this.scheduleResponse = null;
        this.onCancel();
      })
      .catch((error) => {
        console.error('Error during save and close:', error);
      });
  }


  resetForm(): void {
    // Store the current values of jobInformationId and colorId
    const currentJobInformationId = this.scheduleForm.get('jobInformationId')?.value;
    const currentColorId = this.scheduleForm.get('colorId')?.value;

    // Reset the form
    this.scheduleForm.reset();

    // Restore jobInformationId and colorId
    this.scheduleForm.patchValue({
      jobInformationId: currentJobInformationId,
      colorId: currentColorId
    });

    // Set default values for any other fields as needed
    const today = new Date();
    const nextWorkingDay = this.getNextWorkingDay(today);
    this.scheduleForm.patchValue({
      globalId: '00000000-0000-0000-0000-000000000000',
      jobSetupId: 0,
      startOn: nextWorkingDay,
      workDays: 1,
      endOn: nextWorkingDay,
      isHourly: false,
      startTime: null,
      endTime: null,
      progressPercentage: 0,
      scheduleItemReminderId: 0,
      isShowOnGantt: false,
      isShowOwnerPhasesOnly: false,
      phaseSetupId: 0,
      allNotes: '',
      internalNotes: '',
      subNotes: '',
      ownerNotes: '',
      createScheduleItemAssigneeRequests: null,
      createScheduleItemPredecessorParameterRequests: [],
      createScheduleItemTagParameterRequests: null,
      createScheduleItemSubVendorParameterRequests: []
    });

    // Reinitialize form arrays
    this.predecessorRows.clear();
    this.addPredecessorRow();

    // Refresh form validation state
    this.refreshErrorStates();

    // Reset the attachments
    this.resetAttachments();

    // Reset tab visibility to initial state
    this.resetTabVisibility();




  }

  // New method to reset tab visibility
  private resetTabVisibility(): void {
    this.tabs.forEach(tab => {
      // Set the default visibility based on the initial tab configuration
      if (tab.id === 1 || tab.id === 2 || tab.id === 3 || tab.id === 4 || tab.id === 5) {
        tab.show = true;  // Default visible tabs
      } else {
        tab.show = false; // Default hidden tabs
      }
    });
  }


  resetAttachments(): void {
    this.selectedFilesAttachment = [];
    this.TestAttachment = null;
  }


  refreshErrorStates(): void {
    // Clear error messages for specific fields
    this.titleErrorMessage = '';
    this.allNotesErrorMessage = '';
    this.internalNotesErrorMessage = '';
    this.subNotesErrorMessage = '';
    this.ownerNotesErrorMessage = '';
    this.hasAttemptedSubmit = false;

    // Clear global error messages
    this.errorMessages = [];

    // Manually trigger validation for each control
    Object.values(this.scheduleForm.controls).forEach(control => {
      control.markAsPristine(); // Set control to pristine state
      control.markAsUntouched(); // Set control to untouched state
      control.updateValueAndValidity(); // Trigger re-validation
    });
  }


  tabVisibility(scheduleResponse: ResponseScheduleItemResult) {
    if (this.scheduleResponse) {
      const messagingTab = this.tabs.find(tab => tab.id === 7); // Find the Messaging tab
      if (messagingTab) {
        messagingTab.show = true; // Set the show property to true
      }
    }
  }

  // calculateEndDate(startOn: Date, workDays: number): Date {
  //   let result = new Date(startOn);
  //   result.setDate(result.getDate() + workDays - 1);
  //   return result;
  // }


  getCurrentPredecessorRow(index: number): FormGroup | null {
    const control = this.scheduleForm.get('createScheduleItemPredecessorParameterRequests');
    return (control as FormArray).at(index) as FormGroup; // Assert as FormArray
  }


  // Helper method to create a new predecessor row
  createPredecessorRow(): FormGroup {
    return this.fb.group({
      scheduleItemPredecessorParameterId: [null],
      scheduleItemPredecessorTypeSetupId: [10028],
      lagNumber: [0]
    });
  }

  // Get the form array for the predecessor rows
  get predecessorRows(): FormArray {
    return this.scheduleForm.get('createScheduleItemPredecessorParameterRequests') as FormArray;
  }

  // Add a new predecessor row
  addPredecessorRow(): void {
    if (this.predecessorRows.length < this.maxPredecessorRows) {
      const newRow = this.createPredecessorRow();
      this.predecessorRows.push(newRow);
    }
  }

  // Delete a predecessor row by index
  deletePredecessorRow(index: number): void {
    if (this.predecessorRows.length > 0) {
      this.predecessorRows.removeAt(index);
    }
  }

  //  End Here Predecessors Methods


  initLoad(): Promise<void> {
    return new Promise((resolve) => {
      // Fetch lookup values
      this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe((res) => {
        this.lookupSetNameResponse = res.result;

        // -----------single Select ---------------
        const scheduleItemReminderValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.Reminder.code
        );

        const scheduleItemTypeValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.Type.code
        );

        this.tagResponse = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.Tags.code
        );

        this.scheduleItemType = scheduleItemTypeValues;
        this.reminderList = scheduleItemReminderValues;
        this.typeList = scheduleItemTypeValues;

        this.tagNodes = [
          {
            title: 'Check All',
            value: 'check_all',
            key: 'check_all',
            selectable: false,
            isLeaf: false,
            expanded: true,
            children: this.tagResponse.map((tag) => ({
              title: tag.name,
              value: tag.id.toString(),
              key: tag.id.toString(),
              isLeaf: true,
            })),
          },
        ];

        // After lookup values are loaded, fetch colors
        this.colorService.getData().subscribe((res: any) => {
          const colorGet = res.result;
          this.colors = colorGet;

          // Resolve the promise after both lookup values and colors are loaded
          resolve();
        });
      });
    });
  }




  // Existing method to fetch phases
  getPhases() {
    this.phaseService.getData().subscribe((res: any) => {
      this.phaseList = res.result;
      this.phasesLoaded.next(); // Emit an event when phases are loaded
    });
  }


  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe(
      (response: ResponseModelArray<ApplicationUserForComboResponse>) => {
        this.combinedNodes = [
          {
            title: 'Check All',
            value: 'check_all',
            key: 'check_all',
            selectable: false,
            isLeaf: false,
            expanded: true,
            children: response.result.map((status) => ({
              title: status.fullName,
              value: status.id.toString(),
              key: status.id.toString(),
              isLeaf: true
            }))
          }
        ];
      },
      (error) => {
        console.error('Error fetching sub-vendor data:', error);
      }
    );
    (error) => {
      console.error('Error fetching customer data:', error);
    }
  }


  onTagChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('check_all');

    if (checkAllIndex !== -1) {
      const allValues = this.tagNodes[0].children.map(child => child['value']);
      this.scheduleForm.get('createScheduleItemTagParameterRequests')?.setValue(allValues);
      this.isSingleTagSelected = false; // Reset the single tag selection flag
    } else {
      const allValues = this.tagNodes[0].children.map(child => child['value']);
      const filteredValues = selectedValues.filter(value => allValues.includes(value));
      this.scheduleForm.get('createScheduleItemTagParameterRequests')?.setValue(filteredValues);
      this.isSingleTagSelected = filteredValues.length === 1; // Set to true if only one tag is selected
    }
    this.scheduleForm.get('createScheduleItemTagParameterRequests')?.markAsTouched();
    this.scheduleForm.get('createScheduleItemTagParameterRequests')?.updateValueAndValidity();
  }



  onAssigneesChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('check_all');

    if (checkAllIndex !== -1) {
      // Select all child values when "Check All" is selected
      const allValues = this.combinedNodes[0].children.map((child) => child['value']);
      this.scheduleForm.get('createScheduleItemAssigneeRequests')?.setValue(allValues);
      this.isSingleTagSelected = false; // Reset the single tag selection flag
    } else {
      // Filter out deselected values
      const allValues = this.combinedNodes[0].children.map((child) => child['value']);
      const filteredValues = selectedValues.filter((value) => allValues.includes(value));
      this.scheduleForm.get('createScheduleItemAssigneeRequests')?.setValue(filteredValues);
      this.isSingleTagSelected = filteredValues.length === 1;
    }

    // Update form control state
    const assigneeControl = this.scheduleForm.get('createScheduleItemAssigneeRequests');
    if (assigneeControl) {
      assigneeControl.markAsTouched();
      assigneeControl.updateValueAndValidity();
    }
  }




  newScheduleItemTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'Schedule Item Details';
      case 2:
        return 'Related Item(0)';
      default:
        return '';
    }
  }

  // Display Color Field Method
  selectedColor: string;
  selectedColorName: string;

  updateSelectedColor(colorId: number): void {
    if (!this.colors || this.colors.length === 0) {
      // Colors not yet available; skip or handle accordingly
      this.selectedColor = '';
      this.selectedColorName = '';
      return;
    }

    const selectedColor = this.colors.find(color => color.id === colorId);
    if (selectedColor) {
      this.selectedColor = selectedColor.colorCollection;
      this.selectedColorName = selectedColor.name;
    } else {
      this.selectedColor = '';
      this.selectedColorName = '';
    }
  }





  // AISHA ALL NOTES TABS

  notesTabs = [
    { id: 9, title: 'All Notes', show: true },
    { id: 10, title: 'Internal Notes', show: true },
    { id: 11, title: 'Sub Notes', show: true },
    { id: 12, title: 'Owner Notes', show: true },

  ];


  //  Switcher Method Azam

  isHourly = false;

  onChangeSwitcher() {
    this.isHourly = !this.isHourly;
  }

  // Method to open the "Add New Phase" modal

  newSchedulePhaseOpen(): void {
    const modalRef = this.modal.create({
      nzContent: InnerTabPhasesPhaseSetupComponent,
      nzFooter: null,
      nzTitle: '<h1 nz-typography>Add New Phase</h1>',
      nzWidth: '30%',
      nzBodyStyle: {
        'background-color': '#f1f4fa',
        'padding': '20px',
      },
      nzStyle: { top: '10px' },
      nzClosable: true,
      nzMaskClosable: true,
      nzOnCancel: () => {
        modalRef.componentInstance.onCancel();
      }
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.phaseSaved.subscribe(() => {
      this.getPhases();
      modalRef.destroy();
    });

    modalRef.componentInstance.phaseSavedAndNew.subscribe((data: ScheduleSetupPhaseParameterResponse) => {
      if (data) {
        this.getPhases();
        this.phasesLoaded.subscribe(() => {
          this.scheduleForm.patchValue({ phaseSetupId: data.id });
        });
      }
    });
    modalRef.componentInstance.phaseSavedSelected.subscribe((data: ScheduleSetupPhaseParameterResponse) => {
      if (data) {
        this.getPhases();
        this.phasesLoaded.subscribe(() => {
          this.scheduleForm.patchValue({ phaseSetupId: data.id });
        });
      }
    });
  }





  newSchedulePhaseEdit(): void {
    const selectedPhaseId = this.scheduleForm.get('phaseSetupId')?.value;
    const selectedPhase = this.phaseList.find(res => res.Id === selectedPhaseId);

    if (selectedPhaseId > 0 && selectedPhase) {
      const modalRef = this.modal.create({
        nzContent: InnerTabPhasesPhaseSetupComponent,
        nzFooter: null,
        nzTitle: '<h1 nz-typography>Edit Phase</h1>',
        nzWidth: '30%',
        nzBodyStyle: {
          'background-color': '#f1f4fa',
          'padding': '20px',
        },
        nzStyle: { top: '10px' },
        nzClosable: true,
        nzMaskClosable: true,
        nzOnCancel: () => {
          modalRef.componentInstance.onCancel();
        }
      });

      // Pass the selected phase to the child component
      modalRef.componentInstance.editPhase = selectedPhase;
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });

      modalRef.componentInstance.phaseSaved.subscribe(() => {
        this.getPhases(); // Fetch the updated phases
        this.phasesLoaded.subscribe(() => {
          this.scheduleForm.patchValue({ phaseSetupId: 0 });
          modalRef.destroy();
        })

      });

      modalRef.componentInstance.phaseSavedAndNew.subscribe((data: ScheduleSetupPhaseParameterResponse) => {
        if (data) {
          this.getPhases(); // Fetch the updated phases
          this.phasesLoaded.subscribe(() => {
            // Update the phaseSetupId only after phases have been loaded
            this.scheduleForm.patchValue({ phaseSetupId: data.id });
          });
        }
      });

      modalRef.componentInstance.phaseSavedSelected.subscribe((data: ScheduleSetupPhaseParameterResponse) => {
        if (data) {
          this.getPhases(); // Fetch the updated phases
          this.phasesLoaded.subscribe(() => {
            // Update the phaseSetupId only after phases have been loaded
            this.scheduleForm.patchValue({ phaseSetupId: data.id });
          });
        }
      });
    }
  }



  newScheduleTagOpen(): void {
    const modalRef = this.modal.create({
      nzContent: InnerTabPhasesTagsComponent,
      nzFooter: null,
      nzTitle: '<h1 nz-typography>Add Schedule Item Tag</h1>',
      nzWidth: '35%',
      nzBodyStyle: {
        'background-color': '#f1f4fa',
        padding: '20px',
      },
      nzStyle: { top: '10px' },
    });

    modalRef.componentInstance.cancelTag.subscribe(() => {
      modalRef.close();
    });

    modalRef.componentInstance.tagSavedSelected.subscribe((newTag: LookupNameSetupResponse) => {
      // Step 1: Call initLoad() and wait for it to complete
      this.initLoad().then(() => {
        // Step 2: Create the new tag node based on the saved tag
        const newTagNode = {
          title: newTag.name,
          key: newTag.id.toString(),
          value: newTag.id.toString(),
          isLeaf: true,
        };

        // Step 3: Add the new tag node to the tagNodes array
        if (this.tagNodes.length > 0 && this.tagNodes[0].children) {
          this.tagNodes[0].children.push(newTagNode);
        }

        // Step 4: Update the form control value
        const updatedValues = [newTag.id.toString()];
        this.scheduleForm.get('createScheduleItemTagParameterRequests')?.patchValue(updatedValues);
        this.scheduleForm.get('createScheduleItemTagParameterRequests')?.markAsTouched();
        this.scheduleForm.get('createScheduleItemTagParameterRequests')?.updateValueAndValidity();

        // Step 5: Close the modal only after initLoad is completed
        modalRef.destroy();
      }).catch((err) => {
        console.error('Error during initLoad:', err);
        // Handle the error, if necessary
      });
    });
  }



  // Edit Mode Tags

  newScheduleTagEdit(): void {
    const selectedTagIds = this.scheduleForm.get('createScheduleItemTagParameterRequests')?.value;
    if (selectedTagIds && Array.isArray(selectedTagIds) && selectedTagIds.length === 1) {
      const selectedTagId = selectedTagIds[0].toString();
      const selectedTag = this.tagResponse.find((res) => res.id.toString() === selectedTagId);
      if (selectedTag) {
        // Create a modal to edit the selected tag
        const modalRef = this.modal.create({
          nzContent: InnerTabPhasesTagsComponent,
          nzFooter: null,
          nzTitle: '<h1 nz-typography>Edit Schedule Item Tag</h1>',
          nzWidth: '35%',
          nzBodyStyle: {
            'background-color': '#f1f4fa',
            padding: '20px',
          },
          nzStyle: { top: '10px' },
        });

        // Pass the selected tag to the modal component
        modalRef.componentInstance.selectedTag = selectedTag;

        modalRef.componentInstance.cancelTag.subscribe(() => {
          modalRef.destroy();

        });

        modalRef.componentInstance.tagSavedSelected.subscribe((newTag: LookupNameSetupResponse) => {
          // Step 1: Call initLoad() and wait for it to complete
          this.initLoad().then(() => {
            // Step 2: Create the new tag node based on the saved tag
            const newTagNode = {
              title: newTag.name,
              key: newTag.id.toString(),
              value: newTag.id.toString(),
              isLeaf: true,
            };

            // Step 3: Add the new tag node to the tagNodes array
            if (this.tagNodes.length > 0 && this.tagNodes[0].children) {
              this.tagNodes[0].children.push(newTagNode);
            }

            // Step 4: Update the form control value
            const updatedValues = [newTag.id.toString()];
            this.scheduleForm.get('createScheduleItemTagParameterRequests')?.patchValue(updatedValues);
            this.scheduleForm.get('createScheduleItemTagParameterRequests')?.markAsTouched();
            this.scheduleForm.get('createScheduleItemTagParameterRequests')?.updateValueAndValidity();

            // Step 5: Close the modal only after initLoad is completed
            modalRef.destroy();
          }).catch((err) => {
            console.error('Error during initLoad:', err);
            // Handle the error, if necessary
          });
        });



        // Subscribe to the tagSaved event when a tag is deleted
        modalRef.componentInstance.tagSaved.subscribe(() => {
          this.initLoad().then(() => {
            this.scheduleForm.patchValue({ createScheduleItemTagParameterRequests: null });
            modalRef.destroy();
          }).catch((err) => {
            console.error('Error during initLoad:', err);
          });
        });

      } else {
        console.error('Tag not found! Ensure the selected ID exists in tagResponse.');
      }
    } else {
      console.error('No valid tag selected for editing! Ensure only one tag is selected.');
    }
  }


  isSingleItemSelected(): boolean {
    const value = this.scheduleForm.get('createScheduleItemTagParameterRequests')?.value;
    return value && Array.isArray(value) && value.length === 1; // Adjusted to check if it's an array with one element
  }

  getTagParameterRequestsCount(): number {
    const tagRequests = this.scheduleForm.get('createScheduleItemTagParameterRequests')?.value; // Access the value
    return tagRequests ? Object.keys(tagRequests).length : 0; // If it's an object, count keys, otherwise return 0
  }

  getPredecessorParameterRequestsCount(): number {
    const predecessorRequests = this.scheduleForm.get('createScheduleItemPredecessorParameterRequests') as FormArray;

    // Return count of only the valid indices
    return predecessorRequests?.controls.filter((_, index) => this.isIndexValid(index)).length || 0;
  }


  // Bottom Tabs
  viewTabs = [1, 2, 3, 4, 5, 6, 7, 8];
  tabs = [
    { id: 1, title: 'Predecessors & Links', show: true },
    { id: 2, title: 'Phases & Tags', show: true },
    { id: 3, title: 'Viewing', show: true },
    { id: 4, title: 'Notes', show: true },
    { id: 5, title: 'Files', show: true },
    { id: 6, title: 'Shifts', show: false },
    { id: 7, title: 'Messaging', show: false },
    { id: 8, title: 'Confirmations', show: false }
  ];

  TestAttachment: AttachmentResponse;
  selectedFilesAttachment: CustomUploadFile[] = [];


  fetchAttachments(AttachmentId: number) {
    if (AttachmentId) {
      this._AttachmentService.getLeadByAttachmentId(AttachmentId).subscribe(
        (res) => {
          this.TestAttachment = res.result
        },
      );
    } else {
      this.TestAttachment = undefined;
    }
  }


  onFilesUploaded(files: CustomUploadFile[]): void {
    this.selectedFilesAttachment = files
  }





  isDeleteLoading: boolean = false; // Step 1: Add loading flag

  deleteSchedule() {
    const globalId = this.scheduleForm.get('globalId').value;
    this.isDeleteLoading = true; // Start loading

    this._scheduleService.deleteSchedule(globalId).subscribe(
      res => {
        this._toastService.success('Deleted Successfully');
        this.isDeleteLoading = false; // End loading
        // You may want to perform additional actions after deletion, e.g., refresh the list
      },
      err => {
        this._toastService.error('Deletion failed'); // Handle error appropriately
        this.isDeleteLoading = false; // End loading
      }
    );
  }

  showConfirm(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete Schedule Item?',
      nzContent: 'Are you sure you want to permanently delete this Schedule Item?',
      nzOkDanger: true,
      nzOkText: 'Delete',
      nzOkLoading: this.isDeleteLoading, // Step 2: Set loading flag here
      nzCentered: true,
      nzAutofocus: 'cancel',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          this.isDeleteLoading = true; // Show loading spinner
          this.deleteSchedule(); // Call deleteSchedule here

          setTimeout(() => {
            this.isDeleteLoading = false; // Hide loading spinner after delay
            this.onCancel();
            resolve(); // Resolve the promise to close the modal
          }, 1000); // Delay in milliseconds (1 second)
        }),
      nzOnCancel: () => console.log('Cancel'),
    });
  }






  nzOkLoading = false;

  showConfirmChanges(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Unsaved Changes',
      nzContent: 'You have unsaved changes, do you want to save your changes before closing?',
      nzOnOk: () => this.saveAndCloseSchedule(),
      nzOnCancel: () => this.onCancel(),
      nzOkText: 'Save',
      nzCancelText: "Don't Save",
    });
  }




  onCancel(): void {
    this.cancel.emit();
  }



}

