import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, PipeTransform } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { JobInfoComponentInformation } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { StandardColumnSetupResponse, StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';
import { FilterSearchParameter, LookupFilterResponse } from 'src/app/shared/component/Models/LookupModels';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { AddNewGridStandardViewComponent } from 'src/app/shared/component/Modal/add-new-grid-standard-view/add-new-grid-standard-view.component';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ColumnsResizePinProperties } from 'src/Models/InternalUser/internalUserColumnsPinResize';
import { Subscription } from 'rxjs';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { CostCodeService } from 'src/Service/Internaluser/cost-code.service';
import { MessagingsCommentsService } from 'src/Service/Messagings-Comments-Services/messagings-comments.service';
import { MessagingResponse } from 'src/Models/ChangeOrder/ChangeOrder';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
@Component({
  selector: 'app-comments-dashboard',
  templateUrl: './comments-dashboard.component.html',
  styleUrls: ['./comments-dashboard.component.css'],
})

export class CommentsDashboardComponent  implements OnInit , OnDestroy {
  @Output() onSaveComplete  = new EventEmitter();
  // //New Changes By Aamir Ali - 22-May-2024
  private subscriptions: Subscription[] = [];
  private subscription: Subscription;
  private commentFilterSubscription: Subscription;
  private displayColumnSubscription: Subscription;
  private standardViewFilterSubscription : Subscription
  showCollapsePanel: boolean = true;
  confirmModal?: NzModalRef;
  jobInfoOptions: JobInfoComponentInformation[] = [];
  selectionResponse: MessagingResponse[] = [];
  processedData: any[] = [];
  PAGE_ID: number = -1;
  selectiontabsdashbord = [1,2];
  demoValue = 0;
  isCollapsed = false;
  jobId: number;
  formFieldValues: any = {};
  selectedFilter: number = -1;
  lookupFilterResponse: LookupFilterResponse[] = [];
  selectionFrom: string = 'DB';
  companyNameInvalid: boolean = false;
  showErrorCards: boolean = false;
  isFilterApplied: boolean = false;
  public mySelection: string[] = [];
  groupedData: { [key: string]: any[] } = {};
  selectedJobId: number = 0;
  displayedColumns: string[] = [];
  columnSizes: { columnName: string, widthSize: number }[] = [];
  isColumnSticky: boolean[] = new Array(this.displayedColumns.length).fill(false);
  getColumnWidthSize: number[] = [];
  // selectedFilter: number = -1;
  selected_View_Columns: string[] = [];
  getStandardViewFilterData: StandardViewResponse[] = [];
  getMessageCommentsData: MessagingResponse[] = [];
  filterSearchParameter: FilterSearchParameter;
  totalRecords = 100;
  currentPage = 1;
  pageSize = 100;
  selectedValueControl: any;
  isLoading: boolean = false;
  defaultFilterIdSelected: number = 0;
  selectedColNameSizePin: ColumnsResizePinProperties[] = [];
  getStandardViewColumnFilterData: StandardColumnSetupResponse[] = [];
  fixedSelectedColumnNames: string[] = [];
  CoseCode: { id: number, title: string, detail: string }[] = [];
  groupedMessages: any[] = [];
  userId : number = -1
  constructor(private modal: NzModalService, private filterService: FilterSideMenuBarService,
    private jobInfoService: JobInformationService,
    public localStorage: AccessLocalStorageService,
    private internalUserService: InternalUserService,
    private loadingIndicatorService: LoadingIndicatorService,
    private messagingCommentService: MessagingsCommentsService,
    private standardViewService: StandardViewForAllService,
    public toastService: NzMessageService,
    private changeDetectorRef: ChangeDetectorRef,
    private LookupFilterService: LookupFilterService,
    private leadOppService: LeadOpportunitiesService,
  ) { 
    if (this.commentFilterSubscription) {
      this.commentFilterSubscription.unsubscribe();
    }
    if (this.subscription) {
        this.subscription.unsubscribe();
    }
    if (this.standardViewFilterSubscription) {
      this.standardViewFilterSubscription.unsubscribe();
    }
    console.clear();
  }
  ngOnInit(): void {
    this.internalUserService.setSelectedFilterId(0);
    if (this.commentFilterSubscription) {
      this.commentFilterSubscription.unsubscribe();
    }
    if (this.subscription) {
        this.subscription.unsubscribe();
    }
    if (this.standardViewFilterSubscription) {
      this.standardViewFilterSubscription.unsubscribe();
    }
    this.PAGE_ID = 24;
    this.extractLastComments();
    this.selectedJobId = this.localStorage.getJobId();
    this.userId = this.localStorage.getUserLoginId();
    
    this.displayedColumns = [];
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    // Add subscriptions to the array
    this.subscriptions.push(
      this.standardViewService.$displaySelectedColApply.subscribe(colName => {
        if (colName.length > 0) {
          this.displayedColumns = colName;
          this.changeDetectorRef.detectChanges();
        }
      })
    );

    this.subscriptions.push(
      this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
        this.selectedFilter = standardType != -1 ? standardType : standardType;
      })
    );
 
    this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom); // New Changes By Aamir Ali - 22-Apr2024
    this.getStandardViewFilterByFormPageId(this.PAGE_ID);

    this.showErrorCards = false;
    this.companyNameInvalid = false;

    this.internalUserService.$isUserAppliedFilter.subscribe(filter => {
      if (filter) {
        this.isFilterApplied = filter;
      } else {
        this.isFilterApplied = filter;
      }
    });
  
    
  }
  
  getInstallerNameOrSelect(parameter: any, index: number): string {
    return parameter?.subVendor?.companyName ? parameter.subVendor.companyName : `Select(${index})`;
  }
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  selectionTabTitle(tab: number): string {
    switch (tab) {
      case 1:
        return 'Card View';
      case 2:
        return 'List';
      default:
        return '';
    }
  }
  getLeadData() {
    this.jobInfoService.getAllLeadData().subscribe(Data => {
      this.jobInfoOptions = Data.result;
    });
  }
  COMMENT_V_BY_CONVERSATION: string = FiltersName.COMMENT_V_BY_CONVERSATION;
  openFilter(component: string, pageId: number) {
    this.selectionFrom = ''; // New Changes By Aamir Ali - 22-Apr2024
    this.isColumnSticky= new Array(this.displayedColumns.length).fill(false);
    this.filterService.toggleFilter(component, pageId);
    this.internalUserService.userAppliedFilter(false);
  }
  // _________________________Abdul_Rehman Start___________________

  // _________________________Abdul_Rehman End_____________________
 
  toggleColumnSticky(index: number) {
    this.isColumnSticky[index] = !this.isColumnSticky[index];
    if (this.isColumnSticky[index]) {
      for (let i = 0; i < index; i++) {
        this.isColumnSticky[i] = true;
      }
    }
  }
  saveColumnSizes(event: any) {
    event.forEach(col => {
      const columnName = col.column.field;
      const widthSize = col.newWidth;
      const existingIndex = this.columnSizes.findIndex(item => item.columnName === columnName);

      if (existingIndex !== -1) {
        this.columnSizes[existingIndex].widthSize = widthSize;
      } else {
        this.columnSizes.push({ columnName, widthSize });
      }
    });

  }
  onFilterChange(id: number) {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);

    this.selectedFilter = -1;
    this.displayedColumns = [];
    this.selected_View_Columns = [];
    let selectedView = this.getStandardViewFilterData.find(view => view.id === id).standardViewParameters;
    selectedView.forEach(viewItem => {
      if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
        this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
        this.displayedColumns = this.selected_View_Columns;

        const columnIndex = this.displayedColumns.indexOf(viewItem.standardColumnSetup.displayColumnName);
        if (columnIndex !== -1) {
          this.isColumnSticky[columnIndex] = viewItem.isPin;
          this.getColumnWidthSize[columnIndex] = viewItem.resizeColumnWidth;
        }
      }
    });
  }
  internalUserGridTableData(companyId: number, formNameId: number , lookUpFilterId: number, page: number, pageSize: number ){
    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId:lookUpFilterId,
      page: page,
      pageSize: pageSize,
      jobInformationId: this.selectedJobId
    };
    
    this.messagingCommentService.getCOM(this.filterSearchParameter);
    this.subscription = this.messagingCommentService.$getComments.subscribe(result => {
      if (result) {
        this.totalRecords = result?.result?.rowCount;
        this.getMessageCommentsData = result?.result?.rows;
       this.groupMessages();        
      }
    });
  }

  openStandardViewFilterComponent(isSaveAs?: string) {
    let isInputVisible: boolean = false;
    if (isSaveAs === 'saveAs') {
      isInputVisible = true;
    } else {
      isInputVisible = false;
    }
    this.selectedColNameSizePin = [];
    for (let i = 0; i < this.displayedColumns.length; i++) {
      const item = this.displayedColumns[i];
      const isPinValue = this.isColumnSticky[i];
      let widthSize = 0;
      if (this.columnSizes.length > 0) {
        for (let i = 0; i < this.columnSizes.length; i++) {
          const columnName = this.columnSizes[i].columnName;
          const columnNameSize = this.columnSizes[i].widthSize;
          if (columnName === item) {
            widthSize = columnNameSize;
            break;
          }
        }
      }

      if (widthSize === 0) {
        widthSize = 180;
      }

      this.selectedColNameSizePin.push({
        displayColumnName: item,
        resizeColumnWidth: widthSize, //  "200px", // Set default width here
        isPin: isPinValue == true ? isPinValue : false
      });
    }
    const modalRef = this.modal.create({
      nzContent: AddNewGridStandardViewComponent,
      nzData:
      {
        pageId: this.PAGE_ID,
        column: this.getStandardViewColumnFilterData,
        displayCol: this.displayedColumns,
        viewList: this.getStandardViewFilterData,
        selectedColNameSizePin: this.selectedColNameSizePin,
        selectedStandardView: this.selectedValueControl,
        fixedCol: this.fixedSelectedColumnNames,
        isSaveAs: isInputVisible
      },

    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
  }
  saveEditStandardView() {
    this.standardViewService.$createStandardViewRequest.subscribe(formData => {
      if (formData && formData !== null) {
        this.standardViewService.postData(formData)
          .subscribe(
            (res: ResponseModelArray<StandardViewResponse>) => {
              setTimeout(() => {
                this.isLoading = true;
                this.toastService.success('Standard View Updated Successfully');
                this.standardViewService.getStandardViewFilterByFormPageId(this.PAGE_ID);
                this.selectedFilter = -1;
              }, 10);
            },
            (error) => {
              console.error('Error:', error);
              this.isLoading = false;
            }
          );
      }
    });
  }
  onPageSizeChange(size: number): void {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
    this.pageSize = size;
    let filterId: number = 0;
    this.internalUserService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.internalUserGridTableData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize);
      this.isLoading = false;

    }, 100);
  }
  onPageChange(page: number): void {
    this.currentPage = page;
    let filterId: number = 0;
    this.internalUserService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.internalUserGridTableData(1, this.PAGE_ID, filterId, page, this.pageSize);
      this.isLoading = false;

    }, 100);
  }
  getStandardFilterByFormPageId(pageId: number, type: string) {// New Changes By Aamir Ali - 22-Apr2024
    // Unsubscribe previous subscriptions if they exist
        if (this.commentFilterSubscription) {
          this.commentFilterSubscription.unsubscribe();
      }
      if (this.subscription) {
          this.subscription.unsubscribe();
      }
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.commentFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.lookupFilterResponse = result.result;
        let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
        this.internalUserService.setSelectedFilterId(defaultFilterId);
        let id = -1;
        if (type === this.selectionFrom) {
          this.defaultFilterIdSelected = defaultFilterId;
          this.localStorage.setSelectedFilterId(defaultFilterId);

          // New Changes By Aamir Ali - 22-Apr2024
          id = this.localStorage.getSelectedFilterId();
        } else {
          id = this.localStorage.getSelectedFilterId();
        }

        if (id !== -1) {
          this.internalUserGridTableData(1, this.PAGE_ID, id, this.currentPage, this.pageSize);
        }

      }
    });
  }
  getStandardViewFilterByFormPageId(pageId: number) {
    if (this.standardViewFilterSubscription) {
      this.standardViewFilterSubscription.unsubscribe();
    }
    this.displayedColumns = [];
    this.fixedSelectedColumnNames = [];
    this.standardViewService.getStandardViewFilterByFormPageId(pageId);
    this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
    this.standardViewFilterSubscription = this.standardViewService.$getStandardViewFilter.subscribe(result => {
      if (result) {
        this.displayedColumns = [];
        this.selected_View_Columns = [];
        this.getStandardViewFilterData = result.result;
        let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true)?.standardViewParameters;
        this.selectedValueControl = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true)?.id;
        defaultView?.forEach(viewItem => {
          if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
            this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
          }
        });
        this.displayedColumns = this.selected_View_Columns;
      }
    });

    this.standardViewService.$getStandardViewColumn.subscribe(result => {
      if (result) {
        this.getStandardViewColumnFilterData = result.result;
        let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);
        fixedCol.forEach(x => {
          this.fixedSelectedColumnNames.push(x.displayColumnName);
        });

      }
    });
  }
  // CostCodeGetData() {
  //   this.CostCodeService.getData().subscribe(
  //     (res: any) => {
  //       this.CoseCode = res.result.map((costCode: any) => ({
  //         id: costCode.id,
  //         title: costCode.title,
  //         detail: costCode.detail
  //       }));
  //     },
  //     (error) => {
  //       console.error('Error fetching cost codes:', error);
  //     }
  //   );
  // }
  getSelectedJobId(item: number) {
    this.selectedJobId = item;
    this.selectionResponse = null;
    this.getMessageCommentsData = null;
    this.getStandardFilterByFormPageId(this.PAGE_ID,this.selectionFrom);
    console.log("selectedJobId : ", this.selectedJobId);
  }
  extractLastComments() {
    const groupedByDate: { [key: string]: MessagingResponse } = {};

    this.selectionResponse.forEach(comment => {
      const date = new Date(comment.createdOn).toDateString();

      if (!groupedByDate[date] || new Date(groupedByDate[date].createdOn) < new Date(comment.createdOn)) {
        groupedByDate[date] = comment;
      }
    });

    // Convert the object back to array
    this.processedData = Object.values(groupedByDate);
  }
  groupCommentsByDate(comments) {
    let groupedComments: { [key: string]: any[] } = {}; // Define the type of groupedComments
    comments.forEach(comment => {
      const date = comment.date;
      if (!groupedComments[date]) {
        groupedComments[date] = [];
      }
      groupedComments[date].push(comment);
    });
    return Object.values(groupedComments); // Convert object values to an array
  }


  // Group BY Date
  groupMessages() {
    const grouped = {};
  
    // Iterate over each row in the main array
    this.getMessageCommentsData.forEach(row => {
      // Iterate over each messaging parameter
      row.messagingParameters.forEach(message => {
        const createdOn = new Date(message?.createdOn).toDateString(); // Convert createdOn to date string
  
        // If group for this date doesn't exist, create it
        if (!grouped[createdOn]) {
          grouped[createdOn] = {
            date: createdOn, // Include the date in the group
            headerData: row, // Include the header data in the group
            messages: [] // Initialize messages array
          };
        }
  
        grouped[createdOn].messages.push(message);
      }); 
    });
    this.groupedMessages = Object.keys(grouped).map(key => grouped[key]);
  }
  //New Changes By Aamir Ali - 22-May-2024
ngOnDestroy() {
  this.LookupFilterService.getStandardFilter.next(null); // New Changes By Aamir Ali - 31-May-2024
  localStorage.removeItem('Selected_Filter_Id'); 
  if (this.commentFilterSubscription) {
    this.commentFilterSubscription.unsubscribe();
  }
  if (this.subscription) {
      this.subscription.unsubscribe();
  }
  if (this.standardViewFilterSubscription) {
    this.standardViewFilterSubscription.unsubscribe();
  }
  console.clear();
}
isDeleteLoading: boolean = false;
  ConfirmDelete(globalId: string): void {
    const selectedCount = this.mySelection.length;
    this.modal.confirm({
      nzTitle: `Delete Comment  ${selectedCount > 1 ? 's' : ''}`,
      nzContent: 'Are you sure you want to delete this Comment?',
      nzOkText: `Delete Comment ${selectedCount > 1 ? 's' : ''}`,
      nzOkDanger: true,
      // nzOnOk: () => this.(),
      nzCancelText: 'Cancel',
      nzOnOk: () => this.userDelete(globalId),
      nzCentered: true,
      // nzWidth: '18%', 
      nzBodyStyle: {'border-radius': '30px','height': 'auto','padding-top':'15',},
      nzCloseIcon: '', 
      nzIconType: '',
      
    });
  }

// soofee
  userDelete(globalid: string) {
    console.log("show globalid",globalid);
    
    this.loadingIndicatorService.show();
    this.isDeleteLoading = true;
  
    this.leadOppService.deleteDatabymessagingCommments(globalid).subscribe(
      response => {
        console.log("show globalid on behave of respose",response.result.globalId);
        
        setTimeout(() => {
          this.toastService.success('User deleted successfully!');
          this.onSaveComplete.emit(response);
          this.loadingIndicatorService.hide();
          this.isDeleteLoading = false;
        }, 500); 
      },
      error => {
        console.error('Error deleting user:', error);
        this.loadingIndicatorService.hide();
        this.isDeleteLoading = false;
      }
    );
  }

//soofee

}