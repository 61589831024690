<nz-modal nzWidth="70%"
 [nzFooter]="null"
 [(nzVisible)]="learnMoreAddForm" 
 [nzTitle]="surveys1" 
 (nzOnCancel)="learnMoreclose()"
 [nzStyle]="{ top: '37px', 'overflow-y': 'hidden','height': '75vh','max-height': '75vh', }"
 >


    <ng-template #surveys1>
        <div class="row p-0 m-0">
            <h2 class="p-0 m-0 fw-medium">Learn More About Lead Contact Form</h2>
        </div>
    </ng-template>


   <div class="row">
    <div class="col mt-0">


        <div class="cantent" *nzModalContent>
                <div class="row">
                    <div class="col mt-0">
                        <iframe width="100%" height="450" src="https://www.youtube.com/embed/Z2MyXuHstIs?si=3MwMOCASY_YJPV_i" 
                        frameborder="0"
                        allowfullscreen></iframe>
                    </div>
                </div>     
        </div>

    </div>
   </div>
    
</nz-modal>


