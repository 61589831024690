<nz-modal nzWidth="95%" [(nzVisible)]="commentsmessages" [nzTitle]="commentsmessages1" [nzFooter]="commentsmessages2"
    (nzOnCancel)="closeCommentsMessages()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '68vh', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
    <ng-template #commentsmessages1>
        <div class="row p-0 m-0">
            <h1 class="p-0 m-0 fw-medium">Comments & Messages </h1>
        </div>
    </ng-template>
    <div class="cantent" *nzModalContent>
        <div class="row famly-inn gx-3">
            <div class="col-12">
                <div class="card border-0 mt-0">
                    <div class="card-head border-bottom">
                        <div class="row mb-0 p-2 d-flex align-items-center">
                            <div class="col">
                                <span class="fw-medium fs-6 ms-2">Comments</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row mt-3 mb-3">
                            <div class=" col-9 d-flex align-items-center">
                                <label nz-checkbox
                                    [(ngModel)]="commentMessageFields['allowOwnerToDiscussAndCommentWithSubsVendors']"
                                    name="allowOwnerToDiscussAndCommentWithSubsVendors"><span class="pira-label">
                                        Allow Owner to discuss and comment with Subs/Vendors</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-0 mt-3">
                    <div class="card-head border-bottom">
                        <div class="row mb-0 p-2 d-flex align-items-center">
                            <div class="col">
                                <span class="fw-medium fs-6 ms-2">Messages</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row mt-3 mb-3">
                            <div class=" col-9 d-flex align-items-center">
                                <label nz-checkbox
                                    [(ngModel)]="commentMessageFields['allowOwnersToComposeMessagesWithMoreThanOneRecipient']"
                                    name="allowOwnersToComposeMessagesWithMoreThanOneRecipient"><span
                                        class="pira-label">
                                        Allow owners to compose messages with more than one recipient</span></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #commentsmessages2>
        <button nz-button nzType="primary" class="rounded-1 me-0" (click)="saveButton()"><span>Save</span></button>
    </ng-template>
</nz-modal>