import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-import-selection',
  templateUrl: './import-selection.component.html',
  styleUrls: ['./import-selection.component.css']
})
export class ImportSelectionComponent {
  @Output() cancel = new EventEmitter<void>();

  selectedValue = null;
  importDataShowDate=true;
  importShowSelectFeild=false;
  importShowDocumentFeild=false;
  importShowVideoFeild=false;
  importShowEstimateFeild=false;
  importShowPurchaseFeild=false;
  selectionModelhandle=true; //cancel property import-selection
  date = null;

  dateShow(){
    this.importDataShowDate =!this.importDataShowDate
  }
  onChange(result: Date): void {
    console.log('onChange: ', result);
  }
  showSelectFeild(){
    this.importShowSelectFeild= !this.importShowSelectFeild;
  }
  showDocumentFeild(){
    this.importShowDocumentFeild = !this.importShowDocumentFeild;
  }
  showVideoFeild(){
    this.importShowVideoFeild =!this.importShowVideoFeild;
  }
  showEstimateFeild(){
    this.importShowEstimateFeild =!this.importShowEstimateFeild;
  }
  showPurchaseFeild(){
    this.importShowPurchaseFeild =!this.importShowPurchaseFeild;
  }

  importSelectionCancelEvent(): void {
  this.cancel.emit();
  }
}
