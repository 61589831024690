

  




<form [formGroup]="internalUserStandardViewForm">
<div class="row d-flex justify-content-start mb-1 ">
  <div class="col-md-2 "> <!-- Adjust the column width as needed -->
    <select (change)="onFilterChange($event)">
      <option value="all">Show All Columns</option>
      <option value="firstFilter">Show First Filter Columns</option>
    </select>
  </div>
  <div class="col-md-3 gx-5"> <!-- Adjust the column width as needed -->
    <nz-select formControlName="name" nzShowSearch nzAutoFocus="true">
      <nz-option *ngFor="let filter of StandardViewResponseData" [nzLabel]="filter.name + (filter.isSetAsDefault ? ' DEFAULT' : '')" [nzValue]="filter.formNameId"> 
      </nz-option>
    </nz-select>
  </div>



  <div class="col-md-2"> <!-- Adjust the column width as needed -->
    <button nz-button class="border-0" (click)="showNewInternaStandardView()">
      <span nz-popover class="text-primary text-dark fw-bold fs-5" nz-icon nzType="ellipsis" nzTheme="outline" style="color: blue;"></span>
    </button>
</div>
</div>
</form>






  




  