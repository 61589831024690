<nz-modal nzWidth="40%" nzheight="25vh" [nzTitle]="Map6Lead" [(nzVisible)]="cardMapVisible" nzClosable="true"
  (nzOnCancel)="CardMapCancel()" [nzFooter]="mapFooter">


  <ng-template #Map6Lead>
    <h3 style="font-weight: bold;">Map 6 Lead</h3>
  </ng-template>


  <ng-container *nzModalContent>
    <div class="row">
      <p style="text-align: center;font-weight: bold;">Please Note:</p>
    </div>

    <div class="row ">
      <h6 style="text-align: center;font-size: 12px;">
        Only Leads With a valid street address, city, state and zip code will
      </h6>
    </div>

    <div class="row">
      <h6 style="text-align: center;font-size: 12px;">
        be found
      </h6>
    </div>
    <div class="row">
      <p style="text-align: center;font-weight: bold;">
        Approximate mapping time is 6 seconds
      </p>
    </div>

    <div class="row mt-2">
      <div class="col-12 text-end">
        <button class="rounded-2" nz-button nzType="default">close</button>&nbsp;
        <button class="rounded-2" nz-button nzType="primary">Save</button>
      </div>
    </div>


  </ng-container>


  <ng-template #mapFooter>


  </ng-template>

  

</nz-modal>