import { MessagingManageEmailsComponent } from './../messaging-manage-emails/messaging-manage-emails.component';
import { Component, EventEmitter, Output } from '@angular/core';
import { Position } from '@progress/kendo-angular-popup/models/position.interface';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzSelectSizeType } from 'ng-zorro-antd/select';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { ClassicEditor, EditorConfig, Bold, Essentials, Heading, Indent, Italic, Link, List, ListProperties, MediaEmbed, Paragraph, Autoformat, Table, Undo, Font, FontSize, FontFamily, FontBackgroundColor, FontColor, BlockQuote, HorizontalLine, UndoEditing, ImageUpload, Image } from 'ckeditor5';

@Component({
  selector: 'app-messaging-compose-new-message',
  templateUrl: './messaging-compose-new-message.component.html',
  styleUrls: ['./messaging-compose-new-message.component.css']
})
export class MessagingComposeNewMessageComponent {

  constructor(private modal: NzModalService,
    public AccessLocalStorageService: AccessLocalStorageService,
  ) {}
  @Output() cancel = new EventEmitter<void>();
  listOfOption: string[] = [];
  size: NzSelectSizeType = 'default';
  singleValue = 'a10';
  position  : Position | undefined;
  text= false;
  editorValue: string = '';
  AddEmail: boolean = false;
  composeNewMessaging=true;


  ngOnInit(): void {
    const children: string[] = [];
    for (let i = 10; i < 36; i++) {
      children.push(`${i.toString(36)}${i}`);
    }
    this.listOfOption = children;

    this.AccessLocalStorageService.getJobName();

  }

  composeNewMessageModelCancel(): void {
  this.cancel.emit();
  }

  // add: boolean = false;
  // doc: boolean = false;
  
  addemail(position: Position){
    this.AddEmail = true;
    this.position = position;
  }

  composeNewMessagingCancel(){
    this.composeNewMessaging=false;
  }



  manageEmailsModelOpen(): void {
    const modalRef = this.modal.create({
      nzContent: MessagingManageEmailsComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });

  }

  public Editor = ClassicEditor;
  public config: EditorConfig = {
    toolbar: {
      items: [
        'undo', 'redo', '|',
        'heading', '|', 'bold', 'italic', '|',
        'link', 'insertTable', 'mediaEmbed', '|',
        'bulletedList', 'numberedList', 'indent', 'outdent', '|',
        'fontSize', 'fontFamily', 'fontBackgroundColor', 'fontColor', 'blockQuote',
        'image', 'horizontalLine', 'undoEditing'
      ]
    },
    plugins: [
      Bold,
      Essentials,
      Heading,
      Indent,
      Italic,
      Link,
      List,
      MediaEmbed,
      Paragraph,
      Table,
      Undo,
      Font,
      FontSize,
      FontFamily,
      FontBackgroundColor,
      FontColor,
      BlockQuote,
      // Image,
      HorizontalLine,
      UndoEditing,
      ImageUpload
    ],
    fontSize: {
      options: [
        9, 11, 13, 'default', 17, 19, 21, 25, 28, 32, 36, 40, 44, 48, 52, 56, 60
      ]
    },
    fontFamily: {
      options: [
        'default', 'Arial, Helvetica, sans-serif', 'Courier New, Courier, monospace',
        'Georgia, serif', 'Times New Roman, Times, serif', 'Verdana, Geneva, sans-serif'
      ]
    }
  };
 

}
