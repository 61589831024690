<nz-modal
  nzWidth="55%"
  [(nzVisible)]="Jobvillsvisible"
  [nzTitle]="JobinformationModalTitle"
  [nzFooter]="jobInformationModalfooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '75vh',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '34px' }"
  (nzOnCancel)="JobCancel()"
>
  <ng-template #JobinformationModalTitle>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0 fs-3">New Job From Template</h2>
    </div>
  </ng-template>

  <div class="content content-set-background" *nzModalContent>
    <div class="ng-container">
      <nz-card nzBorderless="true" nzTitle="New Job Information">
        <div class="row">
          <div class="col-6">
            <label class="sub-text fw-bold"
              >New Job Name <span class="text-danger">*</span></label
            >
            <input
              type="text"
              [(ngModel)]="newJobTemplateForm.name"
              name="name"
              class="form-control formField"
            />
          </div>
          <div class="col-6 mb-2 p-0 ps-2">
            <div nz-row>
              <div nz-col nzSpan="18">
                <label class="sub-text">Job Group</label>
                <nz-tree-select
                  style="width: 100%"
                  nzShowSearch
                  nzAllowClear="false"
                  nzCheckable
                  nzCheckAll="true"
                  [nzNodes]="JobGroupNodes"
                  [(ngModel)]="newJobTemplateForm['createJobGroupParameterRequests']"
                  name="createJobGroupParameterRequests"
                  [nzVirtualHeight]="'250px'"
                >
                </nz-tree-select>
              </div>
              <div nz-col nzSpan="6" style="margin-top: 30px; padding: 0px">
                <button
                  class="btn btn-sm text-primary"
                  (click)="secondShowWarrranty()"
                >
                  Add
                </button>
                <button class="btn btn-sm text-primary border-0 me-1" >
                  Edit
                </button>
                <!-- [disabled]="!newJobTemplateForm['createJobGroupParameterRequests'] || newJobTemplateForm['createJobGroupParameterRequests'].length !== 1"
                  (click)="showTagEdit(newJobTemplateForm['createJobGroupParameterRequests'])" -->
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <label class="sub-text"
              >Job Type <span class="text-danger">*</span></label
            >
            <nz-select
              nzShowSearch
              [(ngModel)]="newJobTemplateForm.jobInformationTypeParameterId"
              name="jobInformationTypeParameterId"
              [nzDisabled]="leadToJobFormData"
            >
              <nz-option
                *ngFor="let jobType of jobTypeList"
                [nzLabel]="jobType.label"
                [nzValue]="jobType.value"
              ></nz-option>
            </nz-select>
          </div>
          <div class="col-3">
            <label class="sub-text"> Contract Type</label>
            <nz-select
              nzShowSearch
              nzAllowClear
              [(ngModel)]="newJobTemplateForm.contractTypeID"
              name="contractTypeID"
            >
            <nz-option *ngFor="let contractType of contactTypeOption" [nzLabel]="contractType.name" [nzValue]="contractType.id"></nz-option>
            </nz-select>
          </div>
          <div class="col-2">
            <label class="sub-text"
              >New Start Date<span class="text-danger">*</span></label
            >
            <nz-date-picker
              [(ngModel)]="newJobTemplateForm.newStartDate"
              nzSuffixIcon="false"
              [nzFormat]="'MMM d, YYYY'"
              nzPlaceHolder=" "
            ></nz-date-picker>
          </div>
          <div class="col-3 mt-4">
            <label [(ngModel)]="newJobTemplateForm.isMakeScheduleOnline" name="isMakeScheduleOnline" class="sub-text" nz-checkbox>Make Schedule Online</label>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3" *ngIf="!leadToJobFormData">
            <label class="sub-text">
              Contact Option
              <i
                class="bi bi-info-circle-fill text-secondary"
                nz-popover
                [nzPopoverContent]="contentTemplate"
                nzPopoverPlacement="right"
              ></i>
              <ng-template #contentTemplate>
                <div class="rounded-2">
                  <p style="height: auto; width: 180px">
                    Assignments to the owner on the template will be assigned to
                    the contact selected.
                  </p>
                </div>
              </ng-template>
            </label>
            <nz-select
            
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Select a person"
            [(ngModel)]="newJobTemplateForm['customerInformationId']"
            name="customerInformationId"
            (ngModelChange)="showContactListComp($event)"
          
          >
            <nz-option nzLabel="Create New Contact" nzValue="newCostumer" ></nz-option>
            <nz-option nzLabel="None" nzValue="none"></nz-option>
            <nz-option nzLabel="Search Existing Contact" nzValue="searchContact"></nz-option>
            <nz-option *ngIf="selectedCustomer" [nzLabel]="selectedCustomer.firstName" [nzValue]="selectedCustomer.id"  ></nz-option>
          </nz-select>
          </div>

          <div class="col-3" *ngIf="showAllFields">
            <label class="sub-text fw-bold">First Name</label>
            <input [(ngModel)]="newJobTemplateForm.firstName" (ngModelChange)="patchDisplayName()" name="firstName" type="text" class="form-control formField" />
          </div>
                    
          <div class="col-3" *ngIf="showAllFields">
            <label class="sub-text fw-bold">Last Name</label>
            <input [(ngModel)]="newJobTemplateForm.lastName" (ngModelChange)="patchDisplayName()" name="lastName" type="text" class="form-control formField" />
          </div>
          
          <div class="col-3" *ngIf="showAllFields">
            <label class="sub-text fw-bold">Display Name <span class="text-danger">*</span></label>
            <input [(ngModel)]="newJobTemplateForm.displayName" name="displayName" type="text" class="form-control formField" />
          </div>

          <div class="col-3" *ngIf="showExistingFields">
            <label class="sub-text fw-bold">First Name</label>
            <input  [(ngModel)]="firstName" name="firstName" type="text" class="form-control formField" />
          </div>
                    
          <div class="col-3" *ngIf="showExistingFields">
            <label class="sub-text fw-bold">Last Name</label>
            <input  [(ngModel)]="lastName" name="lastName" type="text" class="form-control formField" />
          </div>

          
<!-- 
        <div *ngIf="showFieldsFlag">
          <div class="col-3">
            <label class="sub-text fw-bold">First Name </label>
            <input [(ngModel)]="newJobTemplateForm.firstName" name="firstName" type="text" class="form-control formField" />
          </div>
        
          <div class="col-3">
            <label class="sub-text fw-bold">Last Name </label>
            <input [(ngModel)]="newJobTemplateForm.lastName" name="lastName" type="text" class="form-control formField" />
          </div>
        </div> -->
        
          
          

        </div>
      </nz-card>

      <nz-card class="mt-3" nzBorderless="true" nzTitle="Template Information">
        <div class="col-4">
          <label class="sub-text"
            >Source Template <span class="text-danger">*</span></label
          >
          <nz-select
            nzShowSearch
            nzPlaceHolder="--Choose a Template--"
            [(ngModel)]="newJobTemplateForm.jobTemplateId" name="jobTemplateId"
            (ngModelChange)="onOptionClicked($event)"
          >
           <nz-option *ngFor="let option of sourceTemplateOptions" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
          </nz-select>
        </div>

        <div class="row ms-2">
          <div class="col-12 mt-2">
            <p class="fs-8">
              This job will inherit Subs/Vendors Viewing Permissions from the
              template.
            </p>
          </div>
        </div>

        <div class="row ms-2" *ngIf="selectedOptionClicked">
          <div class="col-12">
            <label class="sub-text">Works Days</label>
            <nz-tree-select
              nzShowSearch
              nzCheckable
              nzCheckAll="true"
              [nzNodes]="workDaysNodes"
              [(ngModel)]="newJobTemplateForm.createWorkDayParameterRequests" name="createWorkDayParameterRequests"

            ></nz-tree-select>
          </div>
        </div>
      </nz-card>

      <nz-card class="mt-3" nzBorderless="true" nzTitle="Link to Xero" *ngIf="!leadToJobFormData">
        <nz-radio-group>
          <label nz-radio nzValue="A">Create new Contact in Xero</label>
          <label nz-radio nzValue="B">Choose Xero Contact</label>
          <label nz-radio nzValue="C" >Do not create a new Contact in Xero</label
          >
        </nz-radio-group>
      </nz-card>


    </div>
  </div>
  <ng-template #jobInformationModalfooter>
    <div class="mt-2 align-items-center">
      <!-- <button nz-button nzType="primary" class="rounded-1 me-0">
        Save & New
      </button> -->
      <button nz-button nzType="primary" [disabled]="!(newJobTemplateForm.name && newJobTemplateForm.jobInformationTypeParameterId && newJobTemplateForm.newStartDate && newJobTemplateForm.jobTemplateId)" class="rounded-1 me-0" (click)="saveTemplateJob()">Save</button>
    </div>
  </ng-template>
</nz-modal>


