import { Component, Input, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RFIsComponent } from 'src/app/company-settings/rfis/rfis.component';
import { NewRFIsComponent } from 'src/app/messaging/Components/RFIs/new-rfis/new-rfis.component';
import { RFIInformationGetById, RfiInformationPostById } from 'src/Models/_RFIs/RFIs_Infor';
import { ChangeOrderGetById } from 'src/Models/ChangeOrder/ChangeOrder';
import { RFIsInforServiceService } from 'src/Service/RFIs_InforService/rfis-infor-service.service';

@Component({
  selector: 'app-create-rfis-common',
  templateUrl: './create-rfis-common.component.html',
  styleUrls: ['./create-rfis-common.component.css']
})
export class CreateRFIsCommonComponent implements OnInit {
  @Input() Job_ID: number;
  @Input() header_ID : number;
  @Input() Change_Order_Info: ChangeOrderGetById;
  RFIsGetById_Response: RfiInformationPostById[] = [];
  
  constructor(private modal: NzModalService,
  private _RFIsInforService: RFIsInforServiceService,
  ){}

  ngOnInit(): void {
    if (this.header_ID > 0) {
      this.RFIsGetById(this.header_ID);
    }
    debugger
    if (this.Change_Order_Info?.RFIId > 0) {
      this.RFIsGetById(this.Change_Order_Info?.RFIId);
    }
  }
  createRFI(): void {
    const modalRef = this.modal.create({
      nzWidth: '0%',
      nzBodyStyle: { 'background': '#f1f4fa', 'padding': '0', 'margin': '0', 'overflow-x': 'hidden' },
      nzStyle: { top: '0px' },
      nzContent: NewRFIsComponent,
      nzFooter: null
    });
    modalRef.componentInstance.Form_Name_Id = 7;
    modalRef.componentInstance.Job_ID = this.Job_ID;
    modalRef.componentInstance.header_ID = this.header_ID;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.RFIs_Response_Is_Complete.subscribe(res => {
      this.RFIsGetById(res?.rfiId);
    })
  }
  RFIsGetById(_Id: number){
    this._RFIsInforService.RFIsgetRFIInformation(_Id).subscribe(
      (res) => {
        if (res?.result) {
          this.RFIsGetById_Response = res?.result;
        }
      },
    )
  }
}
