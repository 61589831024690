import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomField } from 'src/Models/CompanySettings/Selections/CustomFieldId';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldService {
  private baseUrl: string = environment.apiUrl + 'CustomField/';


  constructor(private http: HttpClient) {}

  postData(data: CustomField): Observable<CustomField> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }

  getData(): Observable<CustomField[]> {
    return this.http.get<CustomField[]>(`${this.baseUrl}getAll`);
  }
  updateData(data: CustomField): Observable<CustomField> {
    return this.http.put<CustomField>(`${this.baseUrl}`, data);
  }

  deleteData(id: number): Observable<CustomField> {
    return this.http.delete<CustomField>(
      `${this.baseUrl}?globalId=${id}`
    );
  }
  getDataId(id: number): Observable<CustomField> {
    return this.http.get<CustomField>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }
}