import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { Subscription, finalize, delay } from 'rxjs';
import { ApiResponseModel } from 'src/Models/apiResponse.model';
import { DataUpdateServiceService } from 'src/Service/SubVendorsServices/data-update-service.service';
import { SubVendersService } from 'src/Service/SubVendorsServices/sub-venders.service';
import { SUbvendorStandardViewModalService } from 'src/Service/SubVendorsServices/subvendor-standard-view-modal.service';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';

@Component({
  selector: 'app-internal-standard-view',
  templateUrl: './internal-standard-view.component.html',
  styleUrls: ['./internal-standard-view.component.css']
})
export class InternalStandardViewComponent {
  confirmModal?: NzModalRef;
  selectedColumnNames: string[] = [];
  colunNameoptionView: NzTreeNodeOptions[] = [];
  colunNameoption: NzTreeNodeOptions[] = [];
  listOfOption: Array<{ label: string; value: string }> = [];
  isDuplicateSaveButtonVisible: boolean = false;
  isEditFormVisible: boolean = false;
  multipleValue = [];
  filters: any[] = [];
  isInputVisible: boolean = false;
  managesavedviews: boolean = false;
  GetDataColumns: any[] = [];
  columns: any[] = [];
  selectedColumnName: string[] = [];
  private subscription: Subscription;
  isVisible: boolean;
  StandardformGroup: FormGroup;
  constructor(
    private loadingIndicatoreService: LoadingIndicatorService,
    public toastService: NzMessageService,
    private fb: FormBuilder,
    private modal: NzModalService,
    private modalService: SUbvendorStandardViewModalService,
    private SubvendorService: SubVendersService,
    private dataUpdateService: DataUpdateServiceService,
    private standardViewService: StandardViewForAllService) {
    this.subscription = this.modalService.showStandardView$.subscribe(() => {
      console.log('showStandardView$ triggered');
      this.showStandardView();
    });

  }


  ngOnInit(): void {
    this.initStandardForm();
 //   this.StandardViewGetData();
    // this.SubvendorService.getDataColumns().subscribe(
    //   (response: any) => {
    //     this.columns = response.result;
        
    //     this.StandardViewGetData();
    //     // this.updateTreeNodes({ standardViewParameters: this.columns });
    //     // Modify defaultColumnsStandard as needed
    //     const defaultColumnsStandards = ['Company Name'];
    //     this.selectedColumnName = defaultColumnsStandards;

    //     this.colunNameoption = this.columns.map(item => ({
    //       title: item.displayColumnName,
    //       key: item.displayColumnName,
    //       isLeaf: true,
    //       disabled: item.displayColumnName === 'Company Name',
    //     }));

    //   },
    //   (error: any) => {
    //     console.error('Error fetching columns', error);
    //     // Handle error scenario
    //   }
    // );
  }

  private initStandardForm(): void {
    this.StandardformGroup = this.fb.group({
      id: [''],
      globalId: [''],
      name: ['', [Validators.required, Validators.maxLength(25)]], // Place validators inside an array
      isMarkAsPrivate: [true],
      isSetAsDefault: [false],
      formNameId: [1],
      updateStandardViewParameterRequests: this.fb.array([]),
      createStandardViewParameterRequests: this.fb.group({
        columnName: [''],
        columnDisplayName: [''],
        globalId: ['']
      })
    });
  }



  saveStandardView(): void {
    if (this.StandardformGroup.invalid) {
      return;
    }

    this.loadingIndicatoreService.show();
    const filterFormData = { ...this.StandardformGroup.value };
    console.log(filterFormData);

    const columnDisplayName = this.StandardformGroup.get('createStandardViewParameterRequests.columnDisplayName').value;

    const createStandardViewParameterRequests = [];
    for (let i = 0; i < columnDisplayName.length; i++) {
      const columnName = this.columns.find(column => column.displayColumnName === columnDisplayName[i])?.columnName;
      if (columnName) {
        createStandardViewParameterRequests.push({
          columnName,
          columnDisplayName: columnDisplayName[i]
        });
      }
    }


    filterFormData.createStandardViewParameterRequests = createStandardViewParameterRequests;
    this.standardViewService.postData(filterFormData)
      .subscribe(
        (res: ApiResponseModel) => {
          console.log(res.result);
          setTimeout(() => {
            this.loadingIndicatoreService.hide();
            this.toastService.success('Filter saved successfully');
            this.isInputVisible = false;

            this.isDuplicateSaveButtonVisible = false;
            this.isEditFormVisible = false;
            // Reset the form after saving
            this.StandardformGroup.reset();
            this.StandardViewGetData();
            this.dataUpdateService.triggerDropdownUpdate();
          }, 2000);
        },
        (error) => {
          console.error('Error:', error);
          this.loadingIndicatoreService.hide();

          if (error.status === 409) {
            // Handle the specific error condition
            this.StandardformGroup.get('name').setErrors({ uniqueName: true });
          } else if (error.status === 500) {
            this.toastService.error('Internal server error. Please try again later.');
          } else {
            this.toastService.error('An error occurred. Please try again.');
          }
        }
      );
  }



  selectedValue: any = null;
  StandardViewGetData() {
    this.standardViewService.getData().subscribe((res: any) => {
      // Filter data based on formNameId
      const formDataWithFormNameId3 = res.result.filter(item => item.formNameId === 1);
      this.filters = formDataWithFormNameId3;

      const formDataWithFormNameId3AndSetAsDefault = res.result.filter(item => item.formNameId === 1 && item.isSetAsDefault);
      // Iterate through standardViewParameters and log columnDisplayName
      formDataWithFormNameId3AndSetAsDefault.forEach(item => {
        const standardViewParameters = item.standardViewParameters;
        if (standardViewParameters && standardViewParameters.length > 0) {
          standardViewParameters.forEach(param => {
          });
        }

        this.updatetreeNodesInitial(formDataWithFormNameId3AndSetAsDefault);
        this.dataUpdateService.triggerDropdownUpdate();

      });
    });
  }


  updatetreeNodesInitial(data: any): void {
    // Find the first item with isSetAsDefault = true
    const firstDefaultItem = data.find(item => item.isSetAsDefault);

    if (!firstDefaultItem) {
      console.error('No item with isSetAsDefault = true found.');
      return;
    }

    // Extract standardViewParameters from the first default item
    const apiColumns = (firstDefaultItem && firstDefaultItem.standardViewParameters) ? firstDefaultItem.standardViewParameters : [];
    // Update tree nodes with the new columns
    this.colunNameoptionView = this.columns.map(column => ({
      title: column.displayColumnName, // Use displayColumnName for the title
      key: column.displayColumnName,
      isLeaf: true,
      disabled: column.displayColumnName === 'Company Name',
    }));
    // Set the default selected columns based on the matched columns from the API response
    this.selectedColumnNames = this.columns
      .filter(column => apiColumns.some(param => param.columnDisplayName === column.displayColumnName))
      .map(column => column.displayColumnName);

    // Filter columns from this.columns that are not present in the API columns
    const additionalColumns = this.columns.filter(column => !apiColumns.some(param => param.columnDisplayName === column.displayColumnName));

    // Add the additional columns to the tree nodes
    this.colunNameoptionView = this.colunNameoptionView.concat(additionalColumns.map(column => ({
      title: column.displayColumnName,
      key: column.displayColumnName,
      isLeaf: true,
      disabled: column.displayColumnName === 'Company Name',
    })));
  }

  setAsDefaultView(id: any): void {
    this.loadingIndicatoreService.show();

    this.standardViewService.getDataId(id).subscribe(
      (res: ApiResponseModel) => {
        console.log('response get by id:', res.result);
        this.patchFilterForm(res.result);

        if (!this.StandardformGroup.value.isSetAsDefault) {
          const nameValue = this.StandardformGroup.value.name;
          this.StandardformGroup.patchValue({
            isSetAsDefault: true,
          });

          this.standardViewService.updateData(this.StandardformGroup.value).subscribe(
            (updateRes: ApiResponseModel) => {
              console.log('Update successful:', updateRes.result);

              // this.updateTreeNodes(updateRes.result);
              this.StandardViewGetData();
            this.dataUpdateService.triggerDropdownUpdate();
              // Show the indicator for 2 seconds
              setTimeout(() => {
                this.loadingIndicatoreService.hide();

                // Show success message
                this.toastService.success(`${nameValue} Set as Default View`);
              }, 2000);
            },
            (updateError) => {
              console.error('Update Error:', updateError);

              // Handle update errors
              if (updateError.status === 500) {
                this.toastService.error('Internal server error. Please try again later.');
              } else {
                this.toastService.error('An error occurred during update. Please try again.');
              }

              // Hide the indicator immediately in case of an error
              this.loadingIndicatoreService.hide();
            }
          );
        } else {
          this.StandardViewGetData();

          // Hide the indicator immediately if isSetAsDefault is already true
          this.loadingIndicatoreService.hide();
        }
      },
      (error) => {
        console.error('Error:', error);

        // Handle errors
        if (error.status === 500) {
          this.toastService.error('Internal server error. Please try again later.');
        } else {
          this.toastService.error('An error occurred. Please try again.');
        }

        // Hide the indicator immediately in case of an error
        this.loadingIndicatoreService.hide();
      }
    );
  }



  EditFilter(id: any, isDuplicate: boolean = false): void {
    this.loadingIndicatoreService.show();

    this.standardViewService.getDataId(id)
      .pipe(
        finalize(() => this.loadingIndicatoreService.hide())
      )
      .subscribe(
        (res: ApiResponseModel) => {
          console.error('response get by id:', res.result);

          this.patchFilterForm(res.result);
          this.isDuplicateSaveButtonVisible = !isDuplicate;
          this.isEditFormVisible = isDuplicate;
          this.openEditForm();
          this.dataUpdateService.triggerDropdownUpdate();

        },
        (error) => {
          console.error('Error:', error);
        }
      );
  }

  showEditFilterConfirm(id: any): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Edit Saved Filters?',
      nzOkText: 'Edit',
      nzCancelText: 'Cancel',
      nzContent: 'Editing this filter will change it for it for everyone in your account',
      nzIconType: 'edit',
      nzCentered: true,

      nzCloseIcon: 'false',
      nzOnOk: () => {
        // Call the deleteFilter method when the user confirms
        this.EditFilter(id, true);
      },
    });
    

  }

  patchFilterForm(data: any): void {
    // Patch the form values with the fetched data
    this.StandardformGroup.patchValue({
      id: data.id,
      globalId: data.globalId,
      name: data.name,
      isMarkAsPrivate: data.isMarkAsPrivate,
      isSetAsDefault: data.isSetAsDefault,
      formNameId: data.formNameId,
      // Other form controls...
    });

    // Clear existing values in updateStandardViewParameterRequests
    const updateStandardViewParameterRequests = this.StandardformGroup.get('updateStandardViewParameterRequests') as FormArray;
    while (updateStandardViewParameterRequests.length !== 0) {
      updateStandardViewParameterRequests.removeAt(0);
    }

    // Check if data.standardViewParameters is not null or undefined
    if (data.standardViewParameters) {
      // Add parameters from the fetched data
      data.standardViewParameters.forEach(parameter => {
        const parameterFormGroup = this.fb.group({
          columnName: parameter.columnName,
          columnDisplayName: parameter.columnDisplayName,
          globalId: parameter.globalId
        });

        updateStandardViewParameterRequests.push(parameterFormGroup);
      });
    }

    // Update colunNameoption based on the updated data
    this.colunNameoption = this.columns.map(item => ({
      title: item.columnName,
      key: item.columnName,
      isLeaf: true,
      disabled: item.columnName === 'CompanyName',
    }));

    // Update selectedColumnName based on the updated data
    this.selectedColumnName = updateStandardViewParameterRequests.value.map(parameter => parameter.columnName);

    // Log the updated form value
    console.log(this.StandardformGroup.value);
  }


  updateFilter(): void {
    // Extract the values from the form
    const formData = this.StandardformGroup.value;

    // Show loading indicator
    this.loadingIndicatoreService.show();

    // Assuming you have a method in your service to update the filter
    this.standardViewService.updateData(formData)
      .pipe(
        finalize(() => this.loadingIndicatoreService.hide())
      )
      .subscribe(
        (res: ApiResponseModel) => {
          console.log('Filter Update successfully', res.result);
          this.toastService.success('Filter Update successfully');
          // Close the edit form
          this.closeEditForm();
          // Refresh the filter data
          this.StandardViewGetData();
          this.dataUpdateService.triggerDropdownUpdate();

          this.isDuplicateSaveButtonVisible = false;
          this.isEditFormVisible = false;
        },
        (error) => {
          console.error('Error:', error);
          // Handle errors
          if (error.status === 500) {
            this.toastService.error('Internal server error. Please try again later.');
          } else {
            this.toastService.error('An error occurred. Please try again.');
          }
        }
      );
  }

  openEditForm(): void {
    this.isInputVisible = true;

  }


  closeEditForm(): void {
    // Reset any additional flags or variables if needed
    this.isInputVisible = false;
    this.isEditFormVisible = true;
    this.isDuplicateSaveButtonVisible = true;

  }


  deleteFilter(globalId: any) {
    this.loadingIndicatoreService.show();

    this.standardViewService.deleteData(globalId)
      .pipe(
        delay(2000), // Add a delay of 2000 milliseconds (2 seconds)
        finalize(() => this.loadingIndicatoreService.hide()) // Hide the loading indicator in all cases
      )
      .subscribe(
        (res: ApiResponseModel) => {
          console.log(res.result);
          this.toastService.success('Filter Delete successfully');
          this.StandardViewGetData();
          this.dataUpdateService.triggerDropdownUpdate();
          this.isDuplicateSaveButtonVisible = false;
          this.isEditFormVisible = false;

        },
        (error) => {
          console.error('Error:', error);
          // Check for server-side errors
          if (error.status === 500) {
            this.toastService.error('Internal server error. Please try again later.');
          } else {
            this.toastService.error('An error occurred. Please try again.');
          }
        }
      );
  }

  showDeleteFilterConfirm(globalId: any): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete all schedule item on this Job?',
      nzOkDanger: true,
      nzOkText: 'Delete',
      nzCancelText: 'Cancel',
      nzContent: 'Deleting this filter will remove it for everyone in your account',
      nzIconType: 'delete',
      nzCloseIcon: 'false',
      nzCentered: true,
      nzOnOk: () => {
        // Call the deleteFilter method when the user confirms
        this.deleteFilter(globalId);
      },
    });
  }



  showStandardView(): void {
    this.loadingIndicatoreService.show();
    setTimeout(() => {
      this.loadingIndicatoreService.hide();
      this.managesavedviews = true;
    }, 1000); // Adjust the timeout as needed
  }


  toggleVisibility() {
    this.isInputVisible = true;
    this.initStandardForm();

    // Manually set the default column as pre-selected
    const defaultColumnsStandard = ['Company Name'];
    this.StandardformGroup.get('createStandardViewParameterRequests.columnDisplayName').setValue(defaultColumnsStandard);

    // Update the ngModel binding with the default value
    this.selectedColumnName = defaultColumnsStandard;
  }

  closeManageSavedViews() {
    this.isInputVisible = false;
  }

  handleManageSavedViews() {
    this.managesavedviews = false;
  }
  addNewColumn(columnName: string): void {
    // Add the new column to selectedColumnName
    this.selectedColumnName.push(columnName);

    // Update form control 'columnDisplayName'
    const columnDisplayNameControl = this.StandardformGroup.get('createStandardViewParameterRequests.columnDisplayName') as FormControl;
    columnDisplayNameControl.setValue(this.selectedColumnName);

    // Create a new parameterFormGroup for the new column
    const newParameterFormGroup = this.fb.group({
      columnName: columnName,
      columnDisplayName: columnName,
      globalId: '00000000-0000-0000-0000-000000000000'
    });

    // Add the new parameter to the FormArray
    const updateStandardViewParameterRequests = this.StandardformGroup.get('updateStandardViewParameterRequests') as FormArray;
    updateStandardViewParameterRequests.push(newParameterFormGroup);

    // Optionally, update colunNameoption based on the updated data
    this.colunNameoption = [...this.colunNameoption, { title: columnName, key: columnName, isLeaf: true }];

    // Check the first value in the FormArray
    const firstValue = updateStandardViewParameterRequests.at(0).value;
    console.log('First value in updateStandardViewParameterRequests:', firstValue);

    // Optionally, take action based on the first value
    if (firstValue.columnName === 'someValue') {
      // Do something
    }
  }
}
