import { Component } from '@angular/core';

@Component({
  selector: 'app-notification-history-content',
  templateUrl: './notification-history-content.component.html',
  styleUrls: ['./notification-history-content.component.css']
})
export class NotificationHistoryContentComponent {

}
