<nz-modal
  [nzWidth]="isLargeScreen ? '80%' : '80%'"
  [(nzVisible)]="schedule"
  [nzTitle]="schedule1"
  [nzFooter]="schedule2"
  (nzOnCancel)="closeSchedule()"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': isLargeScreen ? '77vh' : '60vh',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '18px' }"
>
  <ng-template #schedule1>
    <div class="row">
      <h1 class="p-0 m-0">Schedule</h1>
    </div>
  </ng-template>
  <div class="cantent" *nzModalContent>
    <div class="row famly-inn gx-3">
      <div class="col-12">
        <div class="card border-0 mt-0">
          <div class="card-head border-bottom">
            <div class="row mb-0 p-2 d-flex align-items-center">
              <div class="col">
                <span class="fw-medium fs-6 ms-2">Default Viewing Options</span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="row">
                    <label class="sub-text me-2">Default Builder view</label>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <nz-select nzShowSearch nz-input
                      [(ngModel)]="scheduleFormsValues['builderViewId']"
                       name="builderViewId"
                      >
                        <nz-option
                          *ngFor="let role of builder"
                          [nzLabel]="role.name"
                          [nzValue]="role.id"
                        ></nz-option>
                      </nz-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="row">
                    <label class="sub-text me-2">Default Owner view</label>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <nz-select nzShowSearch nz-input
                      [(ngModel)]="scheduleFormsValues['ownerViewId']"
                       name="ownerViewId"
                      >
                        <nz-option
                          *ngFor="let role of owner"
                          [nzLabel]="role.name"
                          [nzValue]="role.id"
                        ></nz-option>
                      </nz-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label class="sub-text me-2">Default Owner view</label>
                  </div>
                </div>
                <nz-radio-group >
                    <label nz-radio 
                    [(ngModel)]="scheduleFormsValues['isPhases']"
                    name="isPhases"
                    nzValue="Phases"
                    >Phases   
                     <i
                      class="bi bi-info-circle-fill text-secondary ms-1"
                      nz-popover
                      [nzPopoverContent]="Include"
                      nzPopoverPlacement="top"
                    ></i>
                    </label>
                    <label nz-radio 
                    [(ngModel)]="scheduleFormsValues['isScheduleItem']"
                    name="isScheduleItem"
                    nzValue="Schedule Items"

                    >Schedule Items</label>
                  
                  </nz-radio-group>
                  <ng-template #Include>
                    <div class="rounded-2">
                      <p style="height: auto; width: 240px">
                        With Phases Only selected, Owners will not be able to
                        see any schedule item information including attached
                        files, comments, or notes. Only phases with at least
                        one schedule item viewable to Owner will show to the
                        Owner.
                      </p>
                    </div>
                  </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-0 mt-3">
          <div class="card-head border-bottom">
            <div class="row mb-0 p-2 d-flex align-items-center">
              <div class="col">
                <span class="fw-medium fs-6 ms-2"
                  >Default Notification Options</span
                >
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="row">
                    <label class="sub-text me-2"
                      >Default schedule item reminders</label
                    >
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <nz-select nzShowSearch nz-input
                      [(ngModel)]="scheduleFormsValues['scheduleItemReminderId']"
                       name="scheduleItemReminderId"
                      >
                        <nz-option
                          *ngFor="let role of scheduleItemReminder"
                          [nzLabel]="role.name"
                          [nzValue]="role.id"
                        ></nz-option>
                      </nz-select>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-9 d-flex align-items-center">
                    <label nz-checkbox
                      [(ngModel)]="scheduleFormsValues['sendConfirmationRequestWithScheduleItemReminders']"
                       name="sendConfirmationRequestWithScheduleItemReminders"
                      >Send confirmation request with schedule item
                      reminders</label
                    >
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-9 d-flex align-items-center">
                    <label nz-checkbox
                      [(ngModel)]="scheduleFormsValues['defaultNotifyLinkedSubsVendor']"
                       name="defaultNotifyLinkedSubsVendor"
                      >Default notify linked subs/vendors</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-0 mt-3">
          <div class="card-head border-bottom">
            <div class="row mb-0 p-2 d-flex align-items-center">
              <div class="col">
                <span class="fw-medium fs-6 ms-2">Other Settings</span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="row mt-2">
                  <div class="col-9 d-flex align-items-center">
                    <label nz-checkbox

                     [(ngModel)]="scheduleFormsValues['showTimesForHourlyItemsOnCalendarMonthView']"
                       name="showTimesForHourlyItemsOnCalendarMonthView"


                      >Show times for hourly items on Calendar - Month
                      view</label
                    >
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-9 d-flex align-items-center">
                    <label nz-checkbox

                     [(ngModel)]="scheduleFormsValues['showJobNameOnCalendarMonthWeekDayViewsForAllListedJobs']"
                       name="showJobNameOnCalendarMonthWeekDayViewsForAllListedJobs"

                      >Show job name on Calendar - Month/Week/Day views for All Listed Jobs</label
                    >
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-9 d-flex align-items-center">
                    <label nz-checkbox
                    
                     [(ngModel)]="scheduleFormsValues['automaticallyMarkItemsComplete']"
                       name="automaticallyMarkItemsComplete"
                    >Automatically mark items complete</label>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-9 d-flex align-items-center">
                    <label nz-checkbox

                     [(ngModel)]="scheduleFormsValues['newScheduleItemsDefaultToShowSubsVendors']"
                       name="newScheduleItemsDefaultToShowSubsVendors"

                      >New Schedule Items - Default to show subs/vendors</label
                    >
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-9 d-flex align-items-center">
                    <label nz-checkbox

                     [(ngModel)]="scheduleFormsValues['includeHeaderOnSchedulePdfExports']"
                       name="includeHeaderOnSchedulePdfExports"

                      >Include header on schedule PDF exports</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-0 mt-3">
          <div class="card-head border-bottom">
            <div class="row mb-0 p-2 d-flex align-items-center">
              <div class="col">
                <span class="fw-medium fs-6 ms-2">Phases</span>
              </div>
              <div class="col text-end">
                <button
                  (click)="showAddNewPhase(headerId)"
                  data-testid="saveDraft"
                  aria-disabled="false"
                  type="button"
                  class="me-2 ant-btn13 ant-btn-default"
                >
                  <span>Add Phase</span>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body" style="padding: 16px">
            <nz-table 
            [nzData]="PhaseSet" nzFrontPagination="false"
            >
              <thead>
                <tr style="background-color: #f1f4fa">
                  <th nzWidth="600px">Lable</th>
                  <th>Color</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of PhaseSet">
                  <td  class="cursorTab text-primary" (click)="EditAddNewPhase(item)">{{ item?.name }}</td>
                    <td
                  >
                    <div
                      class="circle mt-1"
                      [style.background-color]="
                        item?.color?.colorCollection"
                    ></div>
                    <span>{{ item?.color?.name}}</span>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #schedule2>
    <button nz-button nzType="primary" class="rounded-1 me-0" (click)="saveScheduleCompanySetting()">Save</button>
  </ng-template>
</nz-modal>


<nz-modal
  nzWidth="28%"
  [(nzVisible)]="PhaseDetails"
  [nzTitle]="PhaseDetailsTitle"
  [nzFooter]="PhaseDetailsFooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '75vh',
    'overflow-x': 'hidden'
  }"
  [nzStyle]="{ top: '20px' }"
  (nzOnCancel)="AddNewPhasecancel()"
>
  <ng-template #PhaseDetailsTitle>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0">Add New Phase</h1>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <div class="card border-0 mt-0">
      <div class="card-head border-bottom">
        <div class="row p-2">
          <h5 class="ms-3" nz-typography>Phase Details</h5>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <label class="sub-text">Phase Name</label>
            <input
              [(ngModel)]="PhaseDetailsFieldValues['name']"
              name="name"
              type="text"
              nz-input
              class="formField m-0"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label class="sub-text"
              >Phase Color
              <i
                class="bi bi-info-circle-fill text-secondary ms-2"
                nz-popover
                [nzPopoverContent]="phaseColor"
                nzPopoverPlacement="left"
              ></i>
              <ng-template #phaseColor>
                <div class="rounded-2">
                  <p style="height: auto; width: 210px; font-size: 13px">
                    Selecting a default color for a particular phase determines
                    how that phase will display on the schedule
                  </p>
                </div>
              </ng-template>
            </label>
            <nz-select
              [(ngModel)]="PhaseDetailsFieldValues['colorId']"
              name="colorId"
              nzShowSearch
              [lang]="true"
              nzAutoFocus="true"
              nzPlaceHolder="--None--"
            >
              <nz-option
                *ngFor="let option of colors"
                [nzLabel]="option.name"
                [nzValue]="option.id"
              ></nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #PhaseDetailsFooter>
    <button
      nz-button
      nzType="default"
      class="rounded-1 me-0"
      (click)="phaseDataDelete()"
      *ngIf="isPhaseDetailsLoadingEdit"
    >
      Delete
    </button>
    <button nz-button nzType="primary" class="rounded-1 me-0">
      Save & New
    </button>
    <button
      nz-button
      nzType="primary"
      class="rounded-1 me-0"
      (click)="savePhaseDetails()"
    >
      {{ isPhaseDetailsLoadingEdit ? "Update" : "Save" }}
    </button>
  </ng-template>
</nz-modal>