    <nz-modal
    nzWidth="45%"
    [(nzVisible)]="uploadfiles"
    [nzTitle]="UploadFiles1"
    [nzFooter]="UploadFiles2"
    (nzOnCancel)="UploadFiles()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '68vh', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '130px', }">
  <ng-template #UploadFiles1>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0" nz-typography>Create New File</h2>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <div class="row famly-inn gx-3">
      <div class="col-12">
          <div class="card border-0 mt-0">
            <div class="card-head border-bottom">
              <div class="row py-2">
                  <h5 nz-typography><span class="ms-3"></span>Blank Documents</h5>
              </div>
          </div>
              <div class="card-body">
                <div class="row">
                  <div (click)="documentXLS()" class="col-6">
                    <img src="assets/ProjectManagerSitesDiaries/documentXLS.svg" width="40">
                    Blank Excel Spreadsheet
                </div>
                <div (click)="documentDOC()" class="col-6">
                  <img src="assets/ProjectManagerSitesDiaries/documentDOC.svg" width="40">
                  Blank Excel Spreadsheet
              </div>
                </div>
              </div>
          </div>
      </div>
    </div>
</div>
  <ng-template #UploadFiles2>
  </ng-template>
</nz-modal>


