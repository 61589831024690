<div class="Conatiner" *ngIf="filterResponseData && filterResponseData.length > 0">
  <form (ngSubmit)="onFilterApply()">
    <div class="row mt-2 mb-2">
      <div class="col-12">
        <div class="row gx-3">
          <!-- <div class="col-11">
            <nz-select [(ngModel)]="formFieldValues['standardFilter']" name="standardFilter" (ngModelChange)="onChangeStandardFilter($event)" nzShowSearch [lang]="true" nzAutoFocus="true" nzPlaceHolder="Standard Filter">
              <nz-option *ngFor="let filter of filterResponseData" [nzLabel]="filter.name + (filter.isSetAsDefault ? ' DEFAULT' : '')" [nzValue]="filter.id"></nz-option>
            </nz-select>
          </div>   -->
          <div class="col-10 p-0">
                <div class="input-group">
                 <nz-select 
                  [(ngModel)]="formFieldValues['standardFilter']" 
                  name="standardFilter"
                  (ngModelChange)="onChangeStandardFilter($event)" 
                  nzShowSearch 
                  [nzAutoFocus]="true"
                  nzPlaceHolder="Standard Filter"
                  nzAllowClear>
                  <nz-option *ngFor="let filter of filterResponseData"
                   [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')" 
                   [nzValue]="filter.id"
                   nzCustomContent>
                   <div class="option-content">
                    <!-- Customize content with icons and text -->
                    <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
                    <span>{{ filter.name }}</span>
                   <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>
            
                   </div>
                  </nz-option>
                 </nz-select>
                </div>
               </div>
            
          <div class="col-1 p-0 m-0">
            <span (click)="openStandardFilterComponent()" nz-popover [nzPopoverContent]="contentTemp" nzPlacement="'bottomRight'" class="text-primary text-dark fw-bold fs-5" nz-icon nzType="ellipsis" nzTheme="outline"></span>
            <ng-template #contentTemp><p style="height: 20px">Manage Saved Filters</p></ng-template>
          </div>  
        </div> 
      </div>
    </div>
    <nz-divider class="p-0 m-0"></nz-divider>


  <div class="content">

  <div class="row mt-2" *ngIf="selectedFilter === 1">
    <div class="col">
      <p>Your filter has unsaved changes.</p>
    </div>
  </div>
  <div class="row mt-2" *ngIf="selectedFilter === 0">
    <div class="col">
      <p>Your filter has unsaved changes.</p>
    </div>
  </div>
    <div class="col d-flex mt-3">
      <button (click)="openStandardFilterComponent()" type="button" nz-button nzType="default" class="me-2"
        style="font-size: 12px;" *ngIf="selectedFilter === 1">Save As</button>
      <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;" *ngIf="selectedFilter === 1"
        type="button">Reset</button>
      <button (click)="editSaveFilterData()" nz-button nzType="primary" type="button" class="me-2"
        style="font-size: 12px;" *ngIf="selectedFilter === 0">Save</button>
      <button (click)="openStandardFilterComponent()" nz-button nzType="default" class="me-2" style="font-size: 12px;"
        *ngIf="selectedFilter === 0" type="button">Save As</button>
      <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;" *ngIf="selectedFilter === 0"
        type="button">Reset</button>
    </div>
  </div>

<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12">
          <label class="sub-text" for="companyName">Keyword
            <i class="bi bi-info-circle-fill text-secondary" nz-popover [nzPopoverContent]="receiveextMessages" nzPopoverPlacement="bottom"></i>
            <ng-template #receiveextMessages>
              <div class="rounded-5" style="width: 250px; font-size: 13px;">
                <div class="row"><div class="col-12"><p>The<strong>Keyword Search</strong>allows you to search for Subs/Vendors by <strong>Company, Primary Contact, Email Address, Phone / Cell / Fax, Street Address, Suburb, and Postal</strong></p></div></div>
              </div>
            </ng-template>
          </label>
      </div> 
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row p-0 m-0">
          <div class="col-8 p-0 m-0">
            <input type="text" [(ngModel)]="formFieldValues['keyword']" name="keyword" class="form-control formField" style="border-right: none; border-radius: 4px 0 0 4px; flex: 1;"/>
          </div>  
          <div class="col-4 p-0 m-0">
            <div style="margin-left: 0px">
              <nz-select class="ant-select-selector" [(ngModel)]="colName" name="colName" (ngModelChange)="onParentCodeChange($event)" nzShowSearch [lang]="true" nzAutoFocus="false" nzShowSearch nzAllowClear style="font-size: 12px; height: 32px; width: 100%; border-left: none;">
                <nz-option *ngFor="let option of keywordOptions" [nzLabel]="option.label" [nzValue]="option.value">
                </nz-option>
              </nz-select>
            </div>
          </div>  
        </div> 
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="sub-text" for="companyName">Division</label>
        <nz-tree-select style="width: 100%" [nzNodes]="divisionTypeNodes" [(ngModel)]="formFieldValues['division']" name="division" nzShowSearch nzVirtualHeight="200px" nzMultiple nzStatus nzAllowClear="false" nzDropdownMatchSelectWidth="true" nzCheckable nzSelectable nzPlaceHolder="Select Division" nzVirtualHeight="230px"></nz-tree-select>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="sub-text" for="companyName">Status</label>
        <nz-select name="status" nzAllowClear nzShowSearch [(ngModel)]="formFieldValues['status']">
          <nz-option *ngFor="let option of StatusOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
      </div>
    </div>
  </div>
</div>
<div class="row mt-2">
  <div class="col-12 p-0 text-end">
    <button nz-button nzType="default" type="button" (click)="resetFilter()">Clear All</button>
    <button class="ms-1" nz-button nzType="primary" type="submit" [disabled]="isFormDirty" >Apply</button>
  </div>
</div>
</form>
</div>