import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LeadProposalResponse } from 'src/Models/LeadProposal/LeadProposalModels';
import { LeadProposalsMetaDataResponse } from 'src/Models/LeadTags';
import { ResponseModel, TablePageResponse } from 'src/Models/responseMessage.model';
import { FilterSearchParameter, CreateLookupFilterRequest } from 'src/app/shared/component/Models/LookupModels';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class LeadProposalsFService {
  private base_Url: string = environment.apiUrl + 'LookupFilter/';
  private baseUrl_Id: string = environment.apiUrl + 'LeadProposal/';

  constructor(private http: HttpClient) {}

  getRLeadUserData = new BehaviorSubject<ResponseModel<TablePageResponse<
  LeadProposalsMetaDataResponse
  // LeadProposalResponse
  >>>(null);
  $getActivityUsers = this.getRLeadUserData.asObservable();

  LeadProposalSelectedFilterId = new BehaviorSubject<number>(0);
  $ProposalSelectedFilterId = this.LeadProposalSelectedFilterId.asObservable();

  LeadProposalIsUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $ProposalIsUserAppliedFilter = this.LeadProposalIsUserAppliedFilter.asObservable();
  
  getLeadProposal(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getRLeadUserData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  getAppliedFilterDataLeadProposal(appliedFilter: CreateLookupFilterRequest[]) {
    const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
    return this.http.post<any>(apiUrl, appliedFilter).subscribe(
      (data) => {
        this.getRLeadUserData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
LeadProposalSetSelectedFilterId(filterId: number) {
    this.LeadProposalSelectedFilterId.next(filterId);
  }
  LeadProposalUserAppliedFilter(filter: boolean) {
    this.LeadProposalIsUserAppliedFilter.next(filter);
  }
  getleadProposalsId(id: number): Observable<ResponseModel<LeadProposalResponse>> {
    return this.http.get<ResponseModel<LeadProposalResponse>>(
      `${this.baseUrl_Id}getBy-Id?id=${id}`
    );
  }
}