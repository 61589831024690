<nz-modal nzWidth="67%" [(nzVisible)]="installationInstuctionAddForm" [nzTitle]="installation" nzClosable="true" (nzOnCancel)="installationInstuctionclose()"
[nzBodyStyle]="{'max-height': '75vh', 'height': '70vh','overflow-y': 'auto'}" [nzStyle]="{ top: '37px' }" [nzFooter]="modalFooter" 
>
  <ng-template #installation>
    <h2>Installation Instructions</h2>
  </ng-template>

  <ng-container *nzModalContent>
    <div class="row p-0">
      <div class="col-12 p-0">
        <ol>
          <li><span class="fw-bold">Copy the code:</span><span class="">Copy the generated code snippet provided below</span></li>
          <nz-card class="custom-card rounded-2">
           <!-- Buildertrend Contact Form -->
	script type="text/javascript" src="https://buildertrend.net/leads/contactforms/js/btClientContactForm.js">/script> <br>
	iframe src="https://buildertrend.net/leads/contactforms/ContactFormFrame.aspx?builderID=39895" scrolling="no" id="btIframe" style="background:transparent;border:0px;margin:0 auto;width:100%;">/iframe>
<!-- /Buildertrend Contact Form -->
          </nz-card>
          <li class="mt-3">
            <span class="fw-bold">
              Access your website's code:
            </span>
            <span class="">
              Log in to the administration panel or
              back-end of your website. This is typically accessed through a content
              management system (CMS) like Wix, Squarespace, WordPress, GoDaddy, or
              Weebly.
            </span>
          </li>
          <li class="mt-1">
            <span class="fw-bold">
              Insert the code:
            </span>
            <span class="">
              Locate the appropriate section in your website's
              code where you want to display the contact form. This could be on a
              specific page or in a template that appears on multiple pages. Paste the
              copied code snippet in the desired location.
            </span><br>
            <span class="fw-bold">Site specific editors:</span>
              <ul >
                <li><a href="">Wix</a></li>
                <li><a href="">Squarespace</a></li>
                <li><a href="">WordPress Lot Zip Sourc</a></li>
                <li><a href="">GoDaddy</a></li>
                <li><a href="">Weebly</a></li>
              </ul>
          </li>
          <li class="mt-1">
            <span class="fw-bold">
              Save and publish:
            </span>
            <span class="">
              After inserting the code, save the changes to your
              website's code and publish the updated pages. This will make the contact
              form visible to your website visitors.
            </span>
          </li>
          <li class="mt-1">
            <span class="fw-bold">
              Start collecting leads:
            </span>
            <span class="">
              When prospective customers submit the form a
              lead opportunity will automatically be created in buildertrend. 
            </span>
          </li>
        </ol>
      </div>
    </div>
  </ng-container>

<ng-template #modalFooter>
    <div class="row text-start p-2 ">
        <span ><a href="">Contact Us</a> <span class="ms-2">If you need help or have any Question</span></span> 
    </div>
   
</ng-template>

</nz-modal>


