import { Component } from '@angular/core';

@Component({
  selector: 'app-financial-reports-detail',
  templateUrl: './financial-reports-detail.component.html',
  styleUrls: ['./financial-reports-detail.component.css']
})
export class FinancialReportsDetailComponent {

}
