import { CreateLeadProposalRequest, CreateTemplateByLeadProposalRequest, LeadProposalResponse } from './../Models/LeadProposal/LeadProposalModels';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { CreateActivityFromTemplateRequest, CreateLeadActivityRequest, CreateLeadActivityTemplateRequest, CreateSendEmailByLeadActivityRequest, CreateTemplateByLeadActivityRequest, LeadActivityResponse, LeadActivityResponses, LeadActivityTemplateResponse, LeadActivityTemplateResponses } from 'src/Models/LeadActivityTemplate/LeadActivityTemplate';
import { LeadOpportunity, LeadOpportunityResponse, LookupNameSetup, LookupNameSetupResponse,  ScheduleItemParameterResponse,  UpdateLeadOpportunityDocumentRequest } from 'src/Models/LeadTags';
import { ApiResponseModel } from 'src/Models/apiResponse.model';
import { updateLeadOpportunity } from 'src/Models/leadOpputtunities';
import {  ResponseModel, ResponseModelArray, TablePageResponse } from 'src/Models/responseMessage.model';
import { FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';
import { environment } from 'src/environments/environment.prod';
import { ChangeOrderInformationResponse, CreateMessagingRequest } from 'src/Models/ChangeOrder/ChangeOrder';
import { CreateSignatureSetupRequest, SignatureSetupResponse } from 'src/Models/SignaturePad/SignaturePad';
import { ChangeOrderSetupResponse, CreateChangeOrderSetupRequest } from 'src/Models/CompanySettings/CompanyInformation';
import { WarrantyInformationResponse } from 'src/Models/Warranty/warranty';
import { CreateSaleSetupRequest,  SaleSetupResponse, SalesSettings } from 'src/Models/LeasSalesPeople';
import { MessagingResponse } from 'src/Models/Project-Management/projectManagement';

@Injectable({
  providedIn: 'root'
})


export class LeadOpportunitiesService {



  private baseUrl: string = environment.apiUrl + 'LeadOpportunity/';
  private Activity_baseUrl: string = environment.apiUrl + 'LeadActivity/';
  private baseURLGet: string = environment.apiUrl + 'LeadOpportunity/';

  private base_Url: string = environment.apiUrl + 'LookupNameSetup/';
  private LeadActivityTemplate: string = environment.apiUrl + 'LeadActivityTemplate/';
  private Url_LookUpFilter: string = environment.apiUrl + 'LookupFilter/';
  private sales_setup: string = environment.apiUrl + 'SaleSetup/getDataBy-Company';


  getLeadListViewData = new BehaviorSubject<ResponseModel<TablePageResponse<LeadOpportunityResponse>>>(null);
  getLeadActivityViewData = new BehaviorSubject<ResponseModel<TablePageResponse<LeadActivityResponse>>>(null);
  $getInternalUsers = this.getLeadListViewData.asObservable();
  $getLeadActivity = this.getLeadActivityViewData.asObservable();

  selectedFilterId = new BehaviorSubject<number>(0);
  $selectedFilterId = this.selectedFilterId.asObservable();

  isUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $isUserAppliedFilter = this.isUserAppliedFilter.asObservable();





  private baseurls: string = environment.apiUrl + 'LeadActivity/';

  constructor(private http: HttpClient) {}

  postData(formData: any): Observable<any> {
    return this.http.post<any>(this.baseUrl, formData);
  }




  getAllLeadData(): Observable<ResponseModelArray<LeadOpportunityResponse>> {
    return this.http.get<ResponseModelArray<LeadOpportunityResponse>>(`${this.baseURLGet}getAll`);
  }

  updateData(data: updateLeadOpportunity): Observable<updateLeadOpportunity> {
    return this.http.put<updateLeadOpportunity>(`${this.baseUrl}`, data);
  }

  deleteDataLookupNameSetup(globalId: string): Observable<any> {
    return this.http.delete<any>(`${this.base_Url}?globalId=${globalId}`);
  }
  deleteLeadActivityData(globalId: string): Observable<any> {
    return this.http.delete<any>(`${this.Activity_baseUrl}?globalId=${globalId}`);
  }
  getDataId(id: string): Observable<ApiResponseModel> {
    return this.http.get<ApiResponseModel>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`
      );
    }
    // Return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }



  getLookUpNameSetup(lookupNameSetup: LookupNameSetup): Observable<ResponseModel<LookupNameSetupResponse>> {
    const apiUrl = this.base_Url;
    return this.http.post<ResponseModel<LookupNameSetupResponse>>(apiUrl, lookupNameSetup).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  postLeadData(LeadOpportunity: LeadOpportunity): Observable<ResponseModel<LeadOpportunityResponse>> {
    const apiUrl = `${this.baseUrl}`;
    return this.http.post<any>(apiUrl, LeadOpportunity).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }


  getAllDataLookUpNameSetup(): Observable<ResponseModelArray<LookupNameSetupResponse>> {
    return this.http.get<ResponseModelArray<LookupNameSetupResponse>>(`${this.base_Url}getAll`);
  }

  LookUpNameSetupGetById(id: string): Observable<ResponseModelArray<LookupNameSetupResponse>> {
    return this.http.get<ResponseModelArray<LookupNameSetupResponse>>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }

  getAllLeadListViewyFilter(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.Url_LookUpFilter}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getLeadListViewData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }


  private baseUrls: string = environment.apiUrl + 'LeadOpportunity/';

  update(UpdateLeadOpportunityDocumentRequest: UpdateLeadOpportunityDocumentRequest): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('globalid', UpdateLeadOpportunityDocumentRequest.globalid);
    UpdateLeadOpportunityDocumentRequest.documentFiles.forEach((file) => {
      formData.append(`documentFiles`, file, file.name);
    });
    const headers = new HttpHeaders();
    const apiUrl = `${this.baseUrls}update-document`;
    return this.http.put<any>(apiUrl, formData, { headers });
  }


  postLeadActivity(customerInformationFormData: CreateLeadActivityRequest): Observable<ResponseModel<LeadActivityResponses>> {
    const apiUrl = `${this.baseurls}`;
    return this.http.post<any>(apiUrl, customerInformationFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }



  postsendEmailLeadActivity(customerInformationFormData: CreateSendEmailByLeadActivityRequest): Observable<ResponseModel<LeadActivityResponse>> {
    const apiUrl = `${this.baseurls}Create-SendLeadActivityByActivity`;
    return this.http.post<any>(apiUrl, customerInformationFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }


  private apiUrl = 'https://api.languagetool.org/v2/check';
  checkText(text: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const body = `text=${encodeURIComponent(text)}&language=en-US`;

    return this.http.post<any>(this.apiUrl, body, { headers });
  }


  private againrowhideSubject = new BehaviorSubject<boolean>(true);
  againrowhide$ = this.againrowhideSubject.asObservable();

  setAgainRowHide(value: boolean) {
    this.againrowhideSubject.next(value);
  }





  // CreateAttachmentForDocument(createAttachmentForDocumentRequest: CreateAttachmentForDocumentRequest): Observable<any> {
  //   const formData: FormData = new FormData();
  //   formData.append('headerId', createAttachmentForDocumentRequest.headerId.toString());
  //   if (createAttachmentForDocumentRequest.companyParameterId) {
  //     formData.append('companyParameterId', createAttachmentForDocumentRequest.companyParameterId.toString());
  //   }
  //   if (createAttachmentForDocumentRequest.formNameId) {
  //     formData.append('formNameId', createAttachmentForDocumentRequest.formNameId.toString());
  //   }
  //   if (createAttachmentForDocumentRequest.documentFiles) {
  //     createAttachmentForDocumentRequest.documentFiles.forEach((file) => {
  //       if (file) { // Check if file is defined
  //         formData.append('documentFiles', file, file.name);
  //       }
  //     });
  //   }
  //   const headers = new HttpHeaders();
  //   const apiUrl = `${this.baseUrls1}create-document`;
  //   return this.http.post<any>(apiUrl, formData, { headers });
  // }



  
  // ________________Abdul rehman_________________
// BehaviorSubject ka istemal karke selected files ko store karne ke liye
private selectedFilesSubject: BehaviorSubject<File[]> = new BehaviorSubject<File[]>([]);

// Function jo files ki array ko update karega
setSelectedFiles(files: File[]): void {
    const currentFiles = this.selectedFilesSubject.value; // Current files ko retrieve karen
    const updatedFiles = [...files, ...currentFiles]; // Naye files ko current files ke saath combine karen
    this.selectedFilesSubject.next(updatedFiles); // Update files ko BehaviorSubject ke subscribers ko provide karen
}

// Function jo selected files ko retrieve karega
getSelectedFiles(): Observable<File[]> {
    return this.selectedFilesSubject.asObservable(); // Selected files ko observable ke form mein return karen
}
clearSelectedFiles() {
  this.selectedFilesSubject.next([]);
}
  // ________________Abdul rehman_________________


  private LeadTemplteUrl: string = environment.apiUrl + 'LeadActivityTemplate/';


  PostLeadTemplate(CreateLead: CreateLeadActivityTemplateRequest): Observable<ResponseModel<LeadActivityTemplateResponses>> {
    const apiUrl = `${this.LeadTemplteUrl}`;
    return this.http.post<any>(apiUrl, CreateLead).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }



// ................................................................................................
                            //  NoorLeadActivityTemplteImport
// ................................................................................................

  private LeadTempltimporteUrl: string = environment.apiUrl + 'LeadActivity/';


  PostimportTemplate(CreateLead: CreateActivityFromTemplateRequest): Observable<ResponseModel<LeadActivityResponse>> {
    const apiUrl = `${this.LeadTempltimporteUrl}Create-ActivityByTemplate`;
    return this.http.post<any>(apiUrl, CreateLead).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  private leadCopyTemplateImportUrl: string = environment.apiUrl + 'LeadActivity/';

  PostImportCopyTemplate(createLead: CreateTemplateByLeadActivityRequest): Observable<ResponseModel<LeadActivityResponse>> {
    const apiUrl = `${this.leadCopyTemplateImportUrl}Create-TemplateByActivity`;
    return this.http.post<ResponseModel<LeadActivityResponse>>(apiUrl, createLead).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }



  getDataLeadTemplte(): Observable<ResponseModelArray<LeadActivityTemplateResponse>> {
    return this.http.get<ResponseModelArray<LeadActivityTemplateResponse>>(`${this.LeadTemplteUrl}GetAll`);
  }

  private LeadProposalUrl: string = environment.apiUrl + 'LeadProposal/';
  GetLeadProposal(): Observable<ResponseModelArray<LeadProposalResponse>> {
    return this.http.get<ResponseModelArray<LeadProposalResponse>>(`${this.LeadProposalUrl}GetAll`);
  }

  PostILeadProposal(createLead: CreateLeadProposalRequest): Observable<ResponseModel<LeadProposalResponse>> {
    const apiUrl = `${this.LeadProposalUrl}Create-LeadProposalByTemplate`;
    return this.http.post<ResponseModel<LeadProposalResponse>>(apiUrl, createLead).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  PostCopyToProposalTemplate(createLead: CreateTemplateByLeadProposalRequest): Observable<ResponseModel<LeadProposalResponse>> {
    const apiUrl = `${this.LeadProposalUrl}Create-TemplateByLeadProposal`;
    return this.http.post<ResponseModel<LeadProposalResponse>>(apiUrl, createLead).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  setSelectedJobId(id : number){
    window.localStorage.setItem("JOB_ID", id.toString());

  }
  getSelectedJobId(): string{
   return window.localStorage.getItem("JOB_ID");
  }
// Saim start work
  private URL_SDUL_ITEM_BASE: string = environment.apiUrl + 'ScheduleItemParameter/getAll-ForName';

  getAllDataScheduleItem(): Observable<ResponseModelArray<ScheduleItemParameterResponse>> {
    return this.http.get<ResponseModelArray<ScheduleItemParameterResponse>>(`${this.URL_SDUL_ITEM_BASE}`);
  }
// Saim End Work
  // ................................................................................................
                                //  NoorLeadActivityTemplteImport
  // ...............................................................................................




 // ................................................................................................
                                //  NoorChangeOrder///
  // ...............................................................................................

  private MessageUrl: string = environment.apiUrl + 'Messaging/';
  private RFISURL: string = environment.apiUrl + 'RFI/';
  private RFISAnswerURL: string = environment.apiUrl + 'RfiInformationAnswer/';
  private SignatureUrl: string = environment.apiUrl + 'SignatureSetup/';







  PostMessage(createLead: CreateMessagingRequest): Observable<ResponseModel<ChangeOrderInformationResponse>> {
    const apiUrl = `${this.MessageUrl}`;
    return this.http.post<ResponseModel<any>>(apiUrl, createLead).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  // soofee

  private URL_get_All_Comm_Msg: string = environment.apiUrl + 'Messaging/';

  private msg_del_URL: string = environment.apiUrl + 'MessagingParameter';
  private baseUrl_Messaging_Delete: string = environment.apiUrl + 'Messaging/Delete-Messsaging-Parameter';

  getAllmessageingData(MessagingId: number): Observable<ResponseModelArray<MessagingResponse[]>> {
    return this.http.get<ResponseModelArray<MessagingResponse[]>>(`${this.URL_get_All_Comm_Msg}getMessegsBy-MessagingId?MessagingId=${MessagingId}`);
  }

  deleteDatabymessagingCommments(globalId: string | number): Observable<ResponseModel<MessagingResponse>> {
    const apiUrl = `${this.baseUrl_Messaging_Delete}?globalId=${globalId}`;
    return this.http.delete<ResponseModel<MessagingResponse>>(apiUrl).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
  PostSignaturePad(CreatSignature: CreateSignatureSetupRequest): Observable<ResponseModel<SignatureSetupResponse>> {
    const apiUrl = `${this.SignatureUrl}`;
    return this.http.post<ResponseModel<SignatureSetupResponse>>(apiUrl, CreatSignature).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  UpdateSignaturePad(CreatSignature: CreateSignatureSetupRequest): Observable<ResponseModel<SignatureSetupResponse>> {
    const apiUrl = `${this.SignatureUrl}`;
    return this.http.post<ResponseModel<SignatureSetupResponse>>(apiUrl, CreatSignature).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

//   PostSignaturePad(CreatSignature: CreateSignatureSetupRequest): Observable<SignatureSetupResponse> {
//     const apiUrl = `${this.SignatureUrl}`;
//     return this.http.post<SignatureSetupResponse>(apiUrl, CreatSignature).pipe(
//       catchError((error: any) => {
//         console.error('Error:', error);
//         return throwError(error);
//       })
//     );
// }

  getSignatureSetupByUserId(applicationUserId: number): Observable<ResponseModel<SignatureSetupResponse>> {
    const apiUrl = `${this.SignatureUrl}GetData-ByUserId?applicationUserId=${applicationUserId}`;
    return this.http.post<ResponseModel<SignatureSetupResponse>>(apiUrl, null).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }




  private URL_SDUL_ITEM_BASEe: string = environment.apiUrl + 'ScheduleItemParameter/GetAll';


  getAllDataScheduleItems(): Observable<ResponseModelArray<ScheduleItemParameterResponse>> {
    return this.http.get<ResponseModelArray<ScheduleItemParameterResponse>>(`${this.URL_SDUL_ITEM_BASEe}`);
  }


  getChangeData = new BehaviorSubject<ResponseModel<TablePageResponse<ChangeOrderInformationResponse>>>(null);
  $getProjectVariation = this.getChangeData.asObservable();


  getWarrantyData = new BehaviorSubject<ResponseModel<TablePageResponse<WarrantyInformationResponse>>>(null);
  $geProjecttWarrantyData = this.getWarrantyData.asObservable();


  getChangeOrder(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.Url_LookUpFilter}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getChangeData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }


  private CompanyChangeOrderUrl: string = environment.apiUrl + 'ChangeOrderSetup/';
  PostCompanyChangeOrder(createCompanyChangeorder: CreateChangeOrderSetupRequest): Observable<ResponseModel<ChangeOrderSetupResponse>> {
    const apiUrl = `${this.CompanyChangeOrderUrl}`;
    return this.http.post<ResponseModel<ChangeOrderSetupResponse>>(apiUrl, createCompanyChangeorder).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }



  GetCompanyChangeOrder(): Observable<ResponseModelArray<ChangeOrderSetupResponse>> {
    return this.http.get<ResponseModelArray<ChangeOrderSetupResponse>>(`${this.CompanyChangeOrderUrl}getData-ByCompany`);
  }


  // getWarrantyAppliedFilterData(appliedFilter: CreateLookupFilterRequest[]) {

    // const apiUrl = `${this.Url_LookUpFilter}GetAll-ByLookupFilter`;
    // return this.http.post<any>(apiUrl, appliedFilter).subscribe(
    //   (data) => {
    //     this.getWarrantyData.next(data);
    //   },
    //   (error) => {
    //     console.error('Error fetching internal users:', error);
    //   }
    // );
  // }




  setSelectedFilterId(filterId: number) {
    this.selectedFilterId.next(filterId);
  }

  userAppliedFilter(filter: boolean) {
    this.isUserAppliedFilter.next(filter);
  }



  ///////////////////////////////Azam post company setting sales//////////


  private baseUrl3: string = environment.apiUrl + 'SaleSetup/';


  Postsale(salesData:CreateSaleSetupRequest ): Observable<ResponseModel<SaleSetupResponse>> {
    const apiUrl = `${this.baseUrl3}`;
    return this.http.post<ResponseModel<SaleSetupResponse>>(apiUrl, salesData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }




  // Create Method Get By ID Lead Opportunity
  getLeadById(id: number): Observable<ResponseModel<LeadOpportunityResponse>> {
    return this.http.get<ResponseModel<LeadOpportunityResponse>>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }

  // Noor Working//
  ActivityGetbyId(id: number): Observable<ResponseModel<LeadActivityResponses>> {
    return this.http.get<ResponseModel<LeadActivityResponses>>(
      `${this.Activity_baseUrl}getBy-Id?id=${id}`
    );
  }
  ActivityPut(data: LeadActivityResponse): Observable<LeadActivityResponse> {
    return this.http.put<LeadActivityResponse>(`${this.Activity_baseUrl}`, data);
  }

  deleteLeadOpportunity(globalId: string): Observable<ResponseModel<any>>{
    return this.http.delete<ResponseModel<any>>(`${this.baseUrl}?globalId=${globalId}`);
  }

  private baseUrlAttachmentdelete: string = environment.apiUrl + 'AttachmentParameter/';



  datagetbysalessetup(): Observable<ResponseModelArray<SaleSetupResponse>> {
    return this.http.get<ResponseModelArray<SaleSetupResponse>>(`${this.sales_setup}`);
  }


// Noor Working 09-14-2024//



getDataByCompany(): Observable<ResponseModel<SalesSettings>> {
  return this.http.get<ResponseModel<SalesSettings>>(`${this.sales_setup}`);
}



  updateDataSale(data: SaleSetupResponse): Observable<SaleSetupResponse> {
    return this.http.put<SaleSetupResponse>(`${this.baseUrl}`, data);
  }



  deleteLeadByAttachmentId(globalId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrlAttachmentdelete}?globalId=${globalId}`);
  }


}
