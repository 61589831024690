<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form (ngSubmit)="onFilterApply()">
    <div class="row mt-2">
      <div class="col-10 p-0">
        <div class="input-group">
         <nz-select
          [(ngModel)]="formFieldValues['standardFilter']"
          name="standardFilter"
          (ngModelChange)="onChangeStandardFilter($event)"
          nzShowSearch
          [nzAutoFocus]="true"
          nzPlaceHolder="Standard Filter"
          >
          <nz-option *ngFor="let filter of filterResponseData"
           [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')"
           [nzValue]="filter.id"
           nzCustomContent>
           <div class="option-content">
            <!-- Customize content with icons and text -->
            <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
            <span>{{ filter.name }}</span>
           <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

           </div>
          </nz-option>
         </nz-select>
        </div>
       </div>
     <div class="col-1 mt-1">
      <i
      nz-popover
      [nzPopoverContent]="contentTemplate"
      [nzPopoverPlacement]="'top'"
      class="bi bi-three-dots" (click)="openStandardFilterComponent()"></i>
    </div>
    </div>




    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Title</label>
        <input
        [(ngModel)]="formFieldValues['title']"
        name="title" class="formField form-control" nz-input placeholder="Basic usage" type="number" />
      </div>
    </div>


    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Work Status </label>
        <!-- <nz-tree-select  formControlName="workStatus"  [nzNodes]="workStatusNodes" nzShowSearch
                nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select> -->

               <nz-tree-select [(ngModel)]="formFieldValues['workStatus']" name="workStatus" style="width: 100%"
               [nzNodes]="workStatusNodes" nzShowSearch nzAllowClear="false" nzCheckable
               (ngModelChange)="onWorkStatusChange($event)" nzPlaceHolder="Select workStatus"
               nzDropdownClassName="dropdownStyle">
             </nz-tree-select>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Bid Status</label>
        <nz-tree-select  formControlName="bidStatus "  [nzNodes]="nodes" nzShowSearch
        nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select>
      </div>
    </div> -->




    <div class="row">
      <div [ngClass]="{'col-6': selectedDateType === 'CustomDate', 'col': selectedDateType !== 'CustomDate'}" class="mt-1 p-0">
        <label class="sub-text">Work Complete Date</label>
        <nz-select
        [(ngModel)]="formFieldValues['workComplete']"
        name="workComplete" nzShowSearch  formControlName="WorkCompleteDate" >
          <nz-option *ngFor="let option of WorkCompleteDate" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
      </div>
    </div>


    <div class="row" *ngIf="selectedDateType === 'CustomDate'">
      <div class="col p-0">
        <nz-range-picker style="width: 100%;" [nzFormat]="dateFormat"></nz-range-picker>
      </div>
    </div>


    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Payment Status</label>
        <!-- <nz-tree-select  formControlName="paymentStatus"  [nzNodes]="PaymentStatusNodes" nzShowSearch
        nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select> -->
        <nz-tree-select
        [(ngModel)]="formFieldValues['paymentStatus']"
        name="paymentStatus"
        style="width: 100%"
        [nzNodes]="PaymentStatusNodes"
        nzShowSearch
        nzAllowClear="false"
        nzCheckable
        (ngModelChange)="onpaymentStatusChange($event)"
        nzPlaceHolder="Select payment Status"
        nzDropdownClassName="dropdownStyle"
       >
       </nz-tree-select>
      </div>
    </div>



    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">PO Status</label>
        <!-- <nz-tree-select  formControlName="pOStatus"  [nzNodes]="POStatusNodes" nzShowSearch
        nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select> -->
        <nz-tree-select
        [(ngModel)]="formFieldValues['pOStatus']"
        name="pOStatus"
        style="width: 100%"
        [nzNodes]="POStatusNodes"
        nzShowSearch
        nzAllowClear="false"
        nzCheckable
        (ngModelChange)="onpOStatusChange($event)"
        nzPlaceHolder="Select pO Status"
        nzDropdownClassName="dropdownStyle"
       >
       </nz-tree-select>
      </div>
    </div>

    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Performed By</label>

        <nz-tree-select
        [(ngModel)]="formFieldValues['performedBy']"
        name="performedBy"
        style="width: 100%"
        [nzNodes]="PerformbyNodes"
        nzShowSearch
        nzAllowClear="false"
        nzCheckable
        nzPlaceHolder="Select performedBy"
        nzDropdownClassName="dropdownStyle"
       >
       </nz-tree-select>


      </div>
    </div>

    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">PO Type</label>
        <nz-select [(ngModel)]="formFieldValues['pOType']"
        name="pOType" >
          <nz-option *ngFor="let option of POType" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
      </div>
    </div>

    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Materials/Labor</label>
        <nz-select [(ngModel)]="formFieldValues['materialsLabor']"
        name="materialsLabor" >
          <nz-option *ngFor="let option of MaterialsLabor" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
      </div>
    </div>

    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Cost Code </label>
        <!-- <nz-tree-select [(ngModel)]="formFieldValues['costCode']"
        name="costCode"
        nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select> -->
        <nz-tree-select
        [(ngModel)]="formFieldValues['costCode']"
        name="costCode"
        style="width: 100%"
        [nzNodes]="CostCodeNodes"
        nzShowSearch
        nzAllowClear="false"
        nzCheckable
        (ngModelChange)="oncostCodeChange($event)"
        nzPlaceHolder="Select costCode"
        nzDropdownClassName="dropdownStyle"
       >
       </nz-tree-select>


      </div>
    </div>

    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Variance Code</label>
        <!-- <nz-tree-select  [(ngModel)]="formFieldValues['varianceCode']"
        name="varianceCode"
        nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select> -->
        <nz-tree-select
        [(ngModel)]="formFieldValues['varianceCode']"
        name="varianceCode"
        style="width: 100%"
        [nzNodes]="VarianceNodes"
        nzShowSearch
        nzAllowClear="false"
        nzCheckable
        (ngModelChange)="onvarianceCodeChange($event)"
        nzPlaceHolder="Select varianceCode"
        nzDropdownClassName="dropdownStyle"
       >
       </nz-tree-select>

      </div>
    </div>

    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Created Date</label>
        <nz-select  [(ngModel)]="formFieldValues['createdDate']"
        name="createdDate" >
          <nz-option *ngFor="let option of CreatedDate" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
      </div>
    </div>

    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Est. Completion Date</label>
        <nz-select [(ngModel)]="formFieldValues['estCompletion']"
        name="createdDate"  >
          <nz-option *ngFor="let option of EstCompletionDate" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
      </div>
    </div>


  </form>
</div>

<ng-template #contentTemplate>
  <div>
  <p>Manage Saved Views</p>
  </div>
  </ng-template>
