import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzSelectSizeType } from 'ng-zorro-antd/select';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { ScheduleItemParameterResponse } from 'src/Models/Project-Management/projectManagement';
import { ToDoParameterResponse } from 'src/Models/Warranty/warranty';
import { SubVendersService } from 'src/Service/SubVendorsServices/sub-venders.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';

@Component({
  selector: 'app-project-management-site-diaries-comment',
  templateUrl: './project-management-site-diaries-comment.component.html',
  styleUrls: ['./project-management-site-diaries-comment.component.css']
})
export class ProjectManagementSiteDiariesCommentComponent implements OnInit {
  @Output() editDataResponse: ScheduleItemParameterResponse;
  @Output() editDataResponseByToDos: ToDoParameterResponse;
  @Output() cancel = new EventEmitter<void>();
  icon: string | TemplateRef<void>;
  selectionModelhandle = true;
  isSectionVisible = false;
  size: NzSelectSizeType = 'default';
  messagesFormValue: any = {};
  combinedNodes: NzTreeNodeOptions[] = [];
  combinedNodesMessaging: NzTreeNodeOptions[] = [];
  assgindata: NzTreeNodeOptions[] = [];
  subVendorData: NzTreeNodeOptions[] = [];
  customerInformationData: NzTreeNodeOptions[] = [];
  internalUserNodes: NzTreeNodeOptions[] = [];
  subVendorsNodes: NzTreeNodeOptions[] = [];
  selectedNodes: NzTreeNodeOptions[] = [];

  constructor(private modal: NzModalService, 
    private customerInformationService: CustomerInformationService,
    private SubVendorService: SubVendersService,
    private localStorageService: AccessLocalStorageService,
  ) { }

  ngOnInit(): void {
    console.log('Row Data :', this.editDataResponse);

    const jobId = this.localStorageService.getJobId();
    this.messagesFormValue = {
      headerId: 0,
      formNameId: 8,
      comments: "",
      isViewOwner: true,
      isViewSubVendor: true,
      JobInformationId: jobId,
      MessageFormTypeId: 10271,
      createMessagingUserNotifyParameterRequests: []
    };
  }

  selectionModelCancel(): void {
    this.cancel.emit();
  }


  toggleSection() {
    this.isSectionVisible = !this.isSectionVisible;
  }

  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      const assgindata = res.result;
      this.assgindata = assgindata.map((vendor) => ({
        title: vendor.fullName,
        key: vendor.id.toString(),
        isLeaf: true,
        isExpanded: true, // Set isExpanded to true for each node
        expanded: true, // Set expanded to true for each node if required by your tree component
        applicationUserId: vendor.applicationUserId
      }));
      this.combineNodes();
    });

    this.SubVendorService.getData().subscribe((res) => {
      const Subvendor = res.result;
      this.subVendorData = Subvendor.map((vendor) => ({
        title: vendor.companyName,
        key: vendor.id.toString(),
        isLeaf: true,
        isExpanded: true, // Set isExpanded to true for each node
        expanded: true, // Set expanded to true for each node if required by your tree component
        subVendorId: vendor.subVendorId
      }));
      this.combineNodes();
    });

    this.customerInformationService.getData().subscribe((res) => {
      const Subvendor = res.result;
      this.customerInformationData = Subvendor.map((customer) => ({
        title: customer.displayName,
        key: customer.id.toString(),
        isLeaf: true,
        isExpanded: true,
        expanded: true, 
        customerInformationId: customer.customerinformationId
      }));
      this.combineNodes();
    });
  }

  combineNodes(): void {
    if (this.assgindata && this.subVendorData && this.customerInformationData) {
      const internalUsersNode = {
        title: 'internalUser',
        value: '0-0',
        key: '0-0',
        children: this.assgindata,
      };

      const subVendorsNode = {
        title: 'Subs/Vendors',
        value: '0-1',
        key: '0-1',
        children: this.subVendorData,
      };
      const customerInformationNode = {
        title: 'Owners',
        value: '0-2',
        key: '0-2',
        children: this.customerInformationData,
      };

      this.combinedNodes = [internalUsersNode, subVendorsNode];
      this.combinedNodesMessaging = [internalUsersNode, subVendorsNode, customerInformationNode];
    }
  }



  onNodeSelectionChange(selectedNodeKeys: string[]): void {
    if (selectedNodeKeys && selectedNodeKeys.length > 0) {
      const selectedNode = this.combinedNodes.find(node => node.key === selectedNodeKeys[0]);
      const combinedNodesMessaging = this.combinedNodesMessaging.find(node => node.key === selectedNodeKeys[0]);

      if (selectedNode && combinedNodesMessaging) {
        this.selectedNodes.push(selectedNode); // Add selected node data to selectedNodes array
        // this.formFieldValues.assigneeUserId = selectedNode['applicationUserId'] || '';
        // this.formFieldValues.assigneeSubVendorId = selectedNode['subVendorId'] || '';
        this.messagesFormValue.applicationUserId = combinedNodesMessaging['applicationUserId'] || '';
        this.messagesFormValue.customerInformationId = combinedNodesMessaging['customerInformationId'] || '';
        this.messagesFormValue.subVendorId = combinedNodesMessaging['subVendorId'] || '';
      }
    } else {
      // Clear the values if no node is selected
      // this.formFieldValues.assigneeUserId = '';
      // this.formFieldValues.assigneeSubVendorId = '';
      this.messagesFormValue.applicationUserId = '';
      this.messagesFormValue.customerInformationId = '';
      this.messagesFormValue.subVendorId = '';
    }
  }





}
