import { NewRFIsComponent } from 'src/app/messaging/Components/RFIs/new-rfis/new-rfis.component';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { WarrantyNewClaimWarrantyCategoryComponent } from '../warranty-new-claim-warranty-category/warranty-new-claim-warranty-category.component';
import { WarrantyNewClaimEditWarrantyCategoryComponent } from '../warranty-new-claim-edit-warranty-category/warranty-new-claim-edit-warranty-category.component';
import { ProjectManagementWarrantyPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { CreateMessagingRequest, LookupNameSetupResponse, ScheduleItemParameterResponse, } from 'src/Models/LeadTags';
import { LeadOpportunityAttachmentsUploadFilesComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { CompletedWarrantyServiceComponent } from 'src/app/completed-warranty-service/completed-warranty-service.component';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { WarrantyService } from 'src/Service/Project-Management/warranty/warranty.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { NzSizeDSType, NzStatus } from 'ng-zorro-antd/core/types';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { AppointmentResposne, WarrantyClaim, WarrantyInformationFordashbord, WarrantyInformationResponse, WarrantyInformations, WarrantyServiceResponse } from 'src/Models/Warranty/warranty';
import { Subscription } from 'rxjs';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { NzImageService } from 'ng-zorro-antd/image';
import { saveAttachments } from 'src/app/helpers/Attachment_Save';
import { AuditDetailsResponse, FilterFooterAudit } from 'src/Models/Footer/footer.model';
import { FooterService } from 'src/Service/Footer/footer.service';
import { AttachmentResponse, CreateAttachmentForDocumentRequest, CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';

@Component({
  selector: 'app-warranty-new-claim',
  templateUrl: './warranty-new-claim.component.html',
  styleUrls: ['./warranty-new-claim.component.css']
})
export class WarrantyNewClaimComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  public dataWarrantyService: WarrantyServiceResponse | null = null;
  editModeActivated = false;
  @ViewChild('carouselContainer', { static: false }) carouselContainer!: ElementRef<HTMLDivElement>;
  showNavButtons = false;
  currentIndex = 0;
  Messaging_Id: number;
  WarrantyIds: number;
  formNameId: number = 10002;
  Form_Id: 10002;
  TestAttachment: AttachmentResponse;
  testingHide: CustomUploadFile;
  selectedFiless: CustomUploadFile[] = [];
  @Input() warrrantyId: number;
  @Output() onSaveComplete = new EventEmitter();
  @Output() onSaveCompletewarranty = new EventEmitter<WarrantyInformationResponse>();
  WarrantyInformations: WarrantyInformationResponse;
  isSaveAndClose: boolean = false;
  isSaveAndNew: boolean = false;
  isSave: boolean = false;
  JobInfoId: number;
  jobID: number;
  formNameID: number = 10002;
  messagingId: number;
  messagingIdRow: any;
  @Input() comments: any;
  selectedValue = null;
  warrantyFieldValues: any = {};
  resetProjectType: boolean = false;
  resetSource: boolean = false;
  resetTags: boolean = false;
  validationStates: { [key: string]: NzStatus } = {};
  validationMessages: { [key: string]: string } = {};
  changedFields: Set<string> = new Set<string>();
  isSubmitted: boolean = false;
  selectedFilesAttachment: CustomUploadFile[] = [];
   public newResponseHeaderId: number;
  categoryOption: LookupNameSetupResponse[] = [];
  priorityOption: { label: string; value: string }[] = [];
  serviceCoordinator: { label: string; value: string }[] = [];
  classificationOption: LookupNameSetupResponse[] = [];
  scheduleItemOption: ScheduleItemParameterResponse[] = [];
  selectedFiles: File[] = [];
  fileList: NzUploadFile[] = [];
  attachments: AttachmentResponse[] = [];
  isFormSaved = false;
  isformSaved = true;
  compareDate: string = 'Date';
  rfiInformationId: string; // Declare rfiInformationId property
  isSaved: boolean = false;
  public responseHeaderId: number = -1;
  public isFieldTouched: boolean = false;
  public responseHeaderIdRes: WarrantyInformationResponse; // add sufyan
  public HeaderId: number;
  responseData: WarrantyServiceResponse[] = [];
  mainSaveResponseId: number | undefined;
  fromScheduleButton: boolean = false;
  PAGE_ID: number = 30005;
  PAGEID: number = 10002;
  WarrantyNewClaimComponent = true;
  TagsForm: FormGroup;
  isLoadingEdit: boolean = false;
  public isTagVisible = false;
  Taghandle = true;
  isLoadingOne: boolean = false;
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  preDefinedDataCodes = {
    Category: ProjectManagementWarrantyPreDefinedCodes.Category,
    Priority: ProjectManagementWarrantyPreDefinedCodes.Priority,
    Coordinator: ProjectManagementWarrantyPreDefinedCodes.Coordinator,
    Classifications: ProjectManagementWarrantyPreDefinedCodes.Classifications
  };
  coordinator: { title: string; key: string; isLeaf: boolean; }[];
  attendeesNodes: NzTreeNodeOptions[] = [];
  confirmModal: import("ng-zorro-antd/modal").NzModalRef<unknown, any>;
  isDeleteTagsLoading: boolean;
  warrantyResponses: WarrantyClaim;
  footerResponse: AuditDetailsResponse;
  warrantydashbordResponses: WarrantyInformationFordashbord;
  small: NzSizeDSType;
  showMessageAfterSave: boolean;
  isEditCardVisible: boolean = false;
  warrantyServiceData: WarrantyServiceResponse[] = [];
  Appointment: AppointmentResposne[] = [];

  private dataSubscription: Subscription;
  warrantydata: WarrantyServiceResponse[] = [];
  classificationResponse: LookupNameSetupResponse[] = []; // add sufyan
  newWarrantyId: any;
  formFieldValues: any = {};
  isFormSubmitted = false; // Track if the form is submitted or interacted with
selectedOption: string;



  constructor(private modal: NzModalService,
    private FooterService: FooterService,
    private nzImageService: NzImageService,
    private LookupFilter: LookupFilterService,
    private toastService: NzMessageService,
    private CustomerInformationService: CustomerInformationService,
    private cdn: ChangeDetectorRef,
    public toastsService: NzMessageService,
    private fb: FormBuilder,
    private loadingIndicatoreService: LoadingIndicatorService,
    private warrantyService: WarrantyService,
    private leadOppService: LeadOpportunitiesService,
    private _AttachmentService: AttachmentService,
    public localStorage: AccessLocalStorageService,
    private localStorageService: AccessLocalStorageService,
    private LeadOpportunitiesService: LeadOpportunitiesService,
    @Inject(NZ_MODAL_DATA) public JobData: any,
    @Inject(NZ_MODAL_DATA) public DataRfis: any,
    @Inject(NZ_MODAL_DATA) public JOBDATA: any,
    @Inject(NZ_MODAL_DATA) public leadData: any,
    @Inject(NZ_MODAL_DATA) public WarrantyInformation: any, // add sufyan

  ) {
  }
  ngOnInit(): void {
    this.warrantyService.getWarrantyDatas().subscribe(data => {
      console.log('Received warranty data:', data);
      this.responseData = data;
    });
    this.fetchActivityResponse();
    this.fetch();
    this.NewPatch(this.warrantyResponses);
    const jobId = this.localStorage.getJobId();
    this.jobID = this.localStorage.getJobId();
    console.log('show all job id id ',jobId);
    console.log(' job id id ',this.jobID );

    this.messagingId = this.WarrantyInformation?.WarrantyInformation?.messagingId;
    this.messagingIdRow = this.WarrantyInformation?.WarrantyInformation?.id;
    const storeComments = this.WarrantyInformation?.WarrantyInformation?.messaging?.messagingParameters.map(param => param.comments)
    this.comments = storeComments?.flat() || [];
    this.ForCombo();
    this.iniliazeForm();
    this.filedsBindRecord();
    this.getScheduleItem();
    this.localStorage.getJobId();
    this.localStorage.getJobName();
    this.leadOppService.getSelectedFiles().subscribe(files => {
      this.selectedFiles = files;
    });
    this.warrantyFieldValues = {
      globalId: '00000000-0000-0000-0000-000000000000',
      warrantySetupId: 1,
      jobInformationId: jobId,
      assignToUserId: '',
      autoAssignNumber: '',
      completionDate: '',
      completionTime: '',
      idNumber: '',
      clainFullNumber: '',
      title: '',
      warrantyCategorySetupId: '',
      prioritySetupId: '',
      problemDescription: '',
      internalComments: '',
      isShowOwner: true,
      coordinatorUserId: '',
      classificationSetupId: '',
      scheduleItemParameterId: '',
      addedCost: '',
      followUpOn: '2024-10-04T10:30:00Z',
    };
    this.warrantyFieldValues['title'] = this.WarrantyInformation?.WarrantyInformation?.title || '';
  }

  filedsBindRecord() {
    this.LookupFilter.getLookUpValuesByFormNameId(this.PAGEID).subscribe(
      (res) => {
        this.lookupSetNameResponse = res.result;

        let categoryValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.Category.code
        );
        this.categoryOption = categoryValues;

        let priorityValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.Priority.code
        );
        this.priorityOption = priorityValues.map((Priority) => ({
          label: Priority.name,
          value: Priority.id.toString(),
        }));

        let classificationOption = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.Classifications.code
        );
        this.classificationOption = classificationOption;

        // Automatically select "Meets Warranty Standards (closed)" if it exists
        const classification = classificationOption.find(
          (item) => item.name === 'Meets Warranty Standards(closed)'
        );

        if (classification) {
          const classificationId = classification.id;
          this.warrantyFieldValues.classificationSetupId = classificationId;
        } else {
          console.log("'Meets Warranty Standards (closed)' classification not found.");
        }
      });
  }



  showAdditionalFields: boolean = false;
  onFieldChanges(field: string, value: any) {
    if (field === 'classificationSetupId') {
      const selectedOption = this.classificationOption.find(option => option.id === value);

      console.log('Selected Classification:', selectedOption?.name);  // Debugging the selected option

      // Check for both options: "Meets Warranty Standards (closed)" or "Service Completed (closed)"
      if (selectedOption &&
        (selectedOption.name === 'Meets Warranty Standards(closed)' || selectedOption.name === 'Service Completed (closed)')) {
        this.showAdditionalFields = true;
        console.log('Additional fields shown');
      } else {
        this.showAdditionalFields = false;
        console.log('Additional fields hidden');
      }
    }
  }


  ForCombo() {
    this.CustomerInformationService.getDataforcombo().subscribe((res) => {
      const serviceCoordinator = res.result;
      const attendees = res.result;
      this.serviceCoordinator = serviceCoordinator.map((AUser) => ({
        label: AUser.fullName,
        value: AUser.id.toString(),
      }));
      this.attendeesNodes = attendees.map((Adees) => ({
        title: Adees.fullName,
        key: Adees.id.toString(),
        isLeaf: true,
      }));
    });
  }
  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }
  Add(): void {
    const modalRef = this.modal.create({
      nzContent: WarrantyNewClaimWarrantyCategoryComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  // selectionChoiceModel
  Edit(): void {
    const modalRef = this.modal.create({
      nzContent: WarrantyNewClaimEditWarrantyCategoryComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  UploadFiles(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }


  iniliazeForm() {
    this.TagsForm = this.fb.group({
      id: [''],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      name: ['', [Validators.required, Validators.maxLength(50)]],
      companyParameterId: [1],
      formNameId: this.PAGEID,
      lookUpStandardId: [],
      parentCode: [''],
      code: [''],
      description: [''],
      isFieldValue: [true],
    });
  }


  SaveOrUpdateData() {
    if (this.isLoadingEdit) {
      this.editData();
    } else {
      this.SaveData();
    }
  }
  SaveData() {
    if (this.TagsForm.valid) {
      this.loadingIndicatoreService.show();
      this.isLoadingOne = true;
      setTimeout(() => {
        this.leadOppService.getLookUpNameSetup(this.TagsForm.value).subscribe(
          response => {
            this.loadingIndicatoreService.hide();
            const tagName = this.TagsForm.get('name')?.value;
            this.isTagVisible = false;
            this.iniliazeForm();
            this.filedsBindRecord();

            this.isLoadingOne = false;
            this.toastsService.success(`${tagName} saved successfully`);
          },
          (error) => {
            this.loadingIndicatoreService.hide();
            if (error.status === 500) {
              this.toastsService.error(
                'Internal Server Error. Please try again later.'
              );
              this.isLoadingOne = false;
            } else if (error.status === 400) {
              this.isLoadingOne = false;
              this.toastsService.error('Bad Request. Please check your input.');
            } else {
              this.toastsService.error(
                'Failed to save division trade. Please try again.'
              );
            }
          }
        );
      }, 1000);
    } else {
      this.TagsForm.markAllAsTouched();
    }
  }


  editData() {
    if (this.TagsForm.valid) {
      this.loadingIndicatoreService.show();
      const tagName = this.TagsForm.get('name')?.value;
      setTimeout(() => {
        this.leadOppService.getLookUpNameSetup(this.TagsForm.value).subscribe(
          (response: any) => {

            this.loadingIndicatoreService.hide();
            this.isTagVisible = false;
            this.iniliazeForm();
            this.filedsBindRecord();
            this.isLoadingEdit = false;
            this.toastsService.success(`${tagName} Update successfully`);
          },
          (error) => {
            this.loadingIndicatoreService.hide();
            if (error.status === 500) {
              this.toastsService.error(
                'Internal Server Error. Please try again later.'
              );
            } else if (error.status === 400) {
              this.toastsService.error('Bad Request. Please check your input.');
            } else {
              this.toastsService.error(
                'Failed to save division trade. Please try again.'
              );
            }
          }
        );
      }, 1000); // 2000 milliseconds (2 seconds)
    } else {
    }
  }

  ConfirmDeleteTags(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete Tags?',
      nzContent:
        'This Tags is not applied to any subs/vendors and will be deleted.',
      nzOkDanger: true,
      nzOkText: 'Delete',
      nzCancelText: 'Cancel',
      nzModalType: 'confirm',
      nzOkLoading: this.isDeleteTagsLoading,
      nzAutofocus: 'ok',
      nzCentered: true,
      nzOnOk: () => this.DeleteData(),
    });
  }

  DeleteData(): Promise<boolean> {
    const vendorId = this.TagsForm.get('globalId')?.value;
    const tagName = this.TagsForm.get('name')?.value;
    this.isDeleteTagsLoading = true;
    this.loadingIndicatoreService.show();

    return new Promise<boolean>((resolve, reject) => {
      this.leadOppService.deleteDataLookupNameSetup(vendorId).subscribe(
        (res: any) => {
          this.isDeleteTagsLoading = false;
          setTimeout(() => {
            this.toastsService.success(`${tagName} Deleted successfully`);
            this.isTagVisible = false;
            this.loadingIndicatoreService.hide();
            this.confirmModal.destroy();
            resolve(true);
          }, 1000);
          this.isLoadingEdit = false;
          this.isTagVisible = false;
          this.iniliazeForm();
          this.filedsBindRecord();
          this.warrantyFieldValues.warrantyCategorySetupId = '';
        },
        (error) => {
          this.isDeleteTagsLoading = false;
          this.isTagVisible = false;
          reject(false);
        }
      );
    });
  }


  showConfirmData(): void {
    if (this.TagsForm.dirty) {
      // The form has unsaved changes, show confirmation modal
      this.confirmModal = this.modal.confirm({
        nzTitle: '<b>Save Changes?</b>',
        nzContent:
          '<p>This Lead Tags has unsaved changes. Do you want to save your changes before closing??</p> ',
        nzOkText: 'Save',
        nzCancelText: "Don't Save",
        nzOnOk: () => {
          // User confirmed to discard changes, save and close modal
          this.SaveData();
        },
        nzOnCancel: () => {
          // User canceled, reset form and close modal
          this.TagsForm.reset();
          this.isTagVisible = false;
        },
        nzCloseIcon: 'false',
        nzStyle: { top: '250px' },
        nzClassName: 'custom-modal-content',
      });
    } else {
      // No unsaved changes, close the modal directly
      this.TagsForm.reset();
      this.isTagVisible = false;
    }
  }


  // OpenLogCompletedService(flag: boolean): void {
  //   const modalRef = this.modal.create({
  //     nzContent: CompletedWarrantyServiceComponent,
  //     nzFooter: null,
  //     nzData: {
  //       headerId: this.responseHeaderId
  //     }
  //   });
  //   modalRef.componentInstance.isSaveButtonClicked = flag;
  //   modalRef.componentInstance.responseHeaderId = this.responseHeaderId;
  //   modalRef.componentInstance.isCheckClicked = true;
  //   modalRef.componentInstance.dateFlag = false;
  //   modalRef.componentInstance.cancel.subscribe(() => {
  //     modalRef.destroy();
  //   });
  //   modalRef.componentInstance.WarrantyResponse.subscribe((data) => {
  //     this.dataWarrantyService = data;
  //     console.log("RESPONSE DATA : ", this.dataWarrantyService);
  //     this.warrantyServiceData.push(this.dataWarrantyService);

  //   });

  // }

  OpenLogCompletedService(flag: boolean): void {
    // Check if both options "Meets Warranty Standards (closed)" and "Service Completed (closed)" are selected
    const selectedClassification = this.classificationOption.find(option =>
      option.id === this.warrantyFieldValues.classificationSetupId
    );

    if (
      selectedClassification &&
      (
        selectedClassification.name === 'Meets Warranty Standards(closed)' ||
        selectedClassification.name === 'Service Completed (closed)' ||
        selectedClassification.name === 'Non-Warranty(closed)'
      )
    ) {
      // Show an error message and prevent the modal from opening
      this.toastService.error('You cannot create warranty services for this warranty');
      return; // Exit the function if these options are selected
    }

    // If the user hasn't selected either of the closed options, proceed to open the modal
    const modalRef = this.modal.create({
      nzContent: CompletedWarrantyServiceComponent,
      nzFooter: null,
      nzData: {
        headerId: this.responseHeaderId // Pass necessary data
      }
    });

    // Set modal instance properties
    modalRef.componentInstance.isSaveButtonClicked = flag;
    modalRef.componentInstance.responseHeaderId = this.responseHeaderId;
    modalRef.componentInstance.isCheckClicked = true;
    modalRef.componentInstance.dateFlag = false;

    // Handle modal cancellation
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    // Handle modal response after saving warranty data
    modalRef.componentInstance.WarrantyResponse.subscribe((data) => {
      this.dataWarrantyService = data;
      console.log("RESPONSE DATA:", this.dataWarrantyService);
      this.warrantyServiceData.push(this.dataWarrantyService);  // Add the response data to your service data array
    });
  }




  public ModalTitle: ModalTitle;
  showTags(fieldType: string, pCode: string, code: string, stndId: number): void {
    this.filedsBindRecord();
    this.loadingIndicatoreService.show();
    setTimeout(() => {
      this.loadingIndicatoreService.hide();
      this.isTagVisible = true;
      this.isLoadingEdit = false;
    }, 1000);

    // Update the dynamic title based on the field type
    switch (fieldType) {
      case 'Category':
        this.ModalTitle = { title: 'Add Warranty Category', deleteTitle: 'Add Warranty Category' };
        this.TagsForm.controls['code'].setValue(code);
        this.TagsForm.controls['parentCode'].setValue(pCode);
        this.TagsForm.controls['lookUpStandardId'].setValue(stndId);

        break;
      default:
        this.ModalTitle = { title: 'Add/Edit Field', deleteTitle: 'Add/Edit Field' };

        break;
    }

  }





  showTagEdit(selectedValue: LookupNameSetupResponse, fieldType: string): void {
    if (selectedValue) {
      const selectedId: number = Number(selectedValue); // Convert the selected ID to number
      this.isTagVisible = true;
      this.isLoadingEdit = true;
      const selectedObjectFromLookup = this.lookupSetNameResponse.find(item => item.id === selectedId);
      if (selectedObjectFromLookup) {
        const selectedData = selectedObjectFromLookup;
        if (selectedData) {
          this.TagsForm.controls['name'].setValue(selectedData.name);
          this.TagsForm.controls['globalId'].setValue(selectedData.globalId);
          this.TagsForm.controls['isFieldValue'].setValue(selectedData.isFieldValue);
          this.TagsForm.controls['companyParameterId'].setValue(selectedData.companyParameterId);
          this.TagsForm.controls['formNameId'].setValue(selectedData.formNameId);
          this.TagsForm.controls['lookUpStandardId'].setValue(selectedData.lookUpStandardId);
          this.TagsForm.controls['code'].setValue(selectedData.code);
          this.TagsForm.controls['description'].setValue(selectedData.description);
          this.TagsForm.controls['parentCode'].setValue(selectedData.parentCode);
        }
      }
    }

    switch (fieldType) {
      case 'Category':
        this.ModalTitle = { title: 'Edit Warranty Category', deleteTitle: 'Edit Warranty Category' };
        this.resetSource = true;
        this.resetTags = false;
        this.resetProjectType = false;
        break;

      default:
        this.ModalTitle = { title: 'Add/Edit Field', deleteTitle: 'Add/Edit Field' };
        break;
    }
  }


  TagClose(): void {
    this.cancel.emit();
  }
  getScheduleItem() {
    this.leadOppService.getAllDataScheduleItems().subscribe(res => {
      this.scheduleItemOption = res.result;
    });
  }
  createMessage() {
    console.log("this.responseHeaderId ", this.responseHeaderId);

    if (this.responseHeaderId <= 0) {
      console.error('Main data not saved yet.');
      return;
    }

    const selectedUsers = this.warrantyFieldValues['createMessagingUserNotifyParameterRequests'];
    const createMessagingRequest: CreateMessagingRequest = {
      headerId: this.responseHeaderId,
      formNameId: 10002,
      comments: "New Message",
      isViewOwner: true,
      isViewSubVendor: false,
      createMessagingUserNotifyParameterRequests: selectedUsers.map(userId => ({ applicationUserId: userId }))
    };
    this.LeadOpportunitiesService.PostMessage(createMessagingRequest).subscribe(
      (messageResponse) => {
        console.log('Message created successfully:', messageResponse);
        this.toastsService.success('Update successfully!');
      },
      (messageError) => {
        console.error('Error creating message:', messageError);
      }
    );;
  }
  saveFileIdToDatabase(fileId: string): void {
  }
  generateAutoAssignNumber(): string {
    return 'AUTO-123456';  // You can replace this with actual logic
  }
// Save Form Method ....//
  saveWarrantyForm(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save'): Promise<void> {
    this.isFormSaved = true;

    return new Promise((resolve, reject) => {
      if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
        return reject('Already processing');
      }

      // Set the appropriate action flag
      if (actionType === 'saveAndNew') {
        this.isSaveAndNew = true;
      } else if (actionType === 'saveAndClose') {
        this.isSaveAndClose = true;
      } else {
        this.isSave = true;
      }

      this.isSubmitted = true;
      this.checkErrors(true);

      // Create the formData object including warrantyFieldValues and footerResponse
      const formDataToSend = {
        ...this.warrantyFieldValues,
        footerResponse: this.footerResponse || {}  // Add footerResponse if it exists
      };

      this.warrantyService.postWarrantyData(formDataToSend).subscribe(
        (response) => {
          this.toastsService.success('Warranty saved successfully!');
          if (response && response.result) {
            autoAssignNumber: response.result.autoAssignNumber || this.generateAutoAssignNumber(),

            this.WarrantyInformations = response.result;
            this.responseHeaderId = response.result.id;
            this.JobInfoId = response.result?.jobInformationId;
            console.log("JobInfoIdJobInfoId", this.JobInfoId);
            // Assign the responseHeaderId to the new variable
            this.newResponseHeaderId = this.responseHeaderId;
            console.log("newResponseHeaderIdnewResponseHeaderId", this.newResponseHeaderId);
            this.cdn.detectChanges();





            this.cdn.detectChanges();
            this.onSaveCompletewarranty.emit(this.WarrantyInformations);

            if (this.selectedFilesAttachment?.length > 0) {
              saveAttachments(this.selectedFilesAttachment, this._AttachmentService, response.result.id, 10002);
            }
            if (actionType === 'saveAndClose') {
              this.closeForm();
            } else if (actionType === 'saveAndNew') {
              this.resetForm();
            }
            if (response) {
              this.createAndModifiedByData(response?.result?.id);
            }
            resolve();
          } else {
            reject('No response from server');
          }
        },
        (error) => {
          console.error('Error saving warranty data:', error);
          this.toastsService.error('Failed to save warranty. Please try again.');
          reject(error);
        }
      ).add(() => {
        this.resetFlags();
      });
    });
  }


  saveAndClosewwarranty(): Promise<void> {
    return this.saveWarrantyForm('saveAndClose').then(() => {
      this.resetForm();
      this.WarrantyFromCancel();
    }).catch((error) => {
      console.error('Error during save and close:', error);
    });
  }

  saveAndNewwarranty() {
    this.saveWarrantyForm('saveAndNew').then(() => {
      this.WarrantyInformations = undefined;
      this.resetForm();
      this.warrantyFieldValues = {}; // Reset the form values
      this.ForCombo();
    }).catch((error) => {
      console.error('Error during save and new:', error);
    });
  }

  onFilesUploaded(files: CustomUploadFile[]): void {
    this.selectedFilesAttachment = files
  }





  closeForm() {
    this.isSaveAndClose = false;
  }

  resetFlags() {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
  }


  resetForm() {
    this.warrantyFieldValues = {};
  }


  deleteWarantyConfirm(): void {
    if (this.isDeleteTagsLoading) {
      return;
    }
    this.modal.confirm({
      nzTitle: `Delete Warranty?`,
      nzContent: 'Are you sure you want to permanently delete this Warranty?',
      nzOkText: `Delete`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteTagsLoading = true;
        return this.deleteActivity(this.warrantyFieldValues.globalId)
          .then(() => {
            this.WarrantyFromCancel();
          })
          .catch(() => {

          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteTagsLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }

  Warrantyemit: WarrantyInformationResponse;
  deleteActivity(globalId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!globalId) {
        this.toastsService.error('No record selected for deletion.');
        this.isDeleteTagsLoading = false;
        reject();
        return;
      }

      this.warrantyService.deleteWarrantyData(globalId).subscribe(
        (res) => {
          this.isDeleteTagsLoading = false;
          this.toastsService.success(`Deleted User!`);
          this.onSaveCompletewarranty.emit(this.Warrantyemit);
          this.WarrantyFromCancel();
          resolve();
          reject();
        },
        (error) => {
          this.isDeleteTagsLoading = false;
          this.toastsService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      );
    });
  }


  WarrantyFromCancel(): void {
    this.cancel.emit();
    this.Taghandle = false;
  }
  // _________________________________Rfi's______________________
  openrFISForm(responseHeaderId: number, formNameId: number) {
    const modalRef = this.modal.create({
      nzContent: NewRFIsComponent,
      nzFooter: null,
      nzData: {
        headerId: responseHeaderId,
        formNameId: formNameId,
      }
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  }

  openEditMode(warrantyEdit: WarrantyServiceResponse[]) {
    // Open modal and pass data
    const modalRef = this.modal.create({
      nzContent: CompletedWarrantyServiceComponent,
      nzFooter: null,
      nzData: {
        headerId: this.responseHeaderId,
        warrantyEditData: warrantyEdit,

      }
    });

    modalRef.componentInstance.warrantiesData = warrantyEdit;
    console.log("warrantyEditData", warrantyEdit);
    modalRef.componentInstance.warrantyResponse.subscribe((data: WarrantyServiceResponse) => {
      this.warrantyServiceData.push(data);
      console.log("Updated warrantyServiceData:", this.warrantyServiceData);
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  WarrantyServices:WarrantyClaim

  NewPatch(WarrantyService: WarrantyClaim) {
    if (!WarrantyService) {
      console.error('No warranty service data available for patching.');
      return;
    }
    this.Messaging_Id = WarrantyService?.messagingId;
    this.JobInfoId = WarrantyService?.jobInformationId;
    this.WarrantyIds = WarrantyService?.id;
    const DataSet = WarrantyService;
    this.warrantyFieldValues = {
      ...this.warrantyFieldValues,
      globalId: DataSet?.globalId || '00000000-0000-0000-0000-000000000000',
      warrantySetupId: DataSet?.warrantySetupId,
      jobInformationId: DataSet?.jobInformationId,
      autoAssignNumber: DataSet?.autoAssignNumber,
      idNumber: DataSet?.idNumber,
      clainFullNumber: DataSet?.clainFullNumber,
      title: DataSet?.title || this.warrantyFieldValues.title,
      warrantyCategorySetupId: DataSet?.warrantyCategorySetupId,
      prioritySetupId: DataSet?.prioritySetupId.toString(),
      problemDescription: DataSet?.problemDescription,
      internalComments: DataSet?.internalComments,
      isShowOwner: DataSet?.isShowOwner ?? this.warrantyFieldValues.isShowOwner,
      coordinatorUserId: DataSet?.coordinatorUserId.toString(),
      classificationSetupId: DataSet?.classificationSetupId,
      // scheduleItemParameterId: DataSet?.s,
      addedCost: DataSet?.addedCost,
      followUpOn: DataSet?.followUpOn,
      attachmentId: DataSet?.attachmentId,
      Messaging_Id: DataSet.messagingId,
      rFIInformationId: DataSet?.rfiId,
      createdBy: DataSet?.createdBy,
      createdOn: DataSet?.createdOn,
      modifiedBy: DataSet?.modifiedBy,
      modifiedOn: DataSet?.modifiedOn,
      isActive: DataSet?.isActive ?? this.warrantyFieldValues.isActive,
      id: DataSet?.id || this.warrantyFieldValues.id
    };
    console.log("show all patch data",DataSet);
    console.log("show all patch data  warrantyCategorySetupId",DataSet.warrantyCategorySetupId);
    console.log("show all patch data  classificationSetupId",DataSet.classificationSetupId);
    this.fetchAttachments(DataSet?.attachmentId);

    this.createAndModifiedByData(this.warrrantyId);
  }



  fetchAttachments(AttachmentId: number) {
    if (AttachmentId) {
      this._AttachmentService.getLeadByAttachmentId(AttachmentId).subscribe(
        (res) => {
          this.TestAttachment = res.result
        },
      );
    } else {
      this.TestAttachment = undefined;
    }
  }
  private fetchActivityResponse(): void {
    this.warrantyService.WarrantyGetbyId(this.warrrantyId)?.subscribe((res) => {
      if (res && res.result) {
        console.log("API response:", res);
        this.warrantyResponses = res.result as WarrantyClaim; // Use type assertion if needed
        this.NewPatch(this.warrantyResponses);

        this.warrantyFieldValues.patchValue(this.warrantyFieldValues.value);
      } else {
        console.error('Invalid response from API:', res);
      }
    });
  }
  private fetch(): void {
    this.warrantyService.WarrantyWarrantyServicegetById(this.warrrantyId)?.subscribe((res) => {
      if (res && res.result) {
        console.log("API response:", res);
        this.warrantydashbordResponses = res.result as WarrantyInformationFordashbord; // Use type assertion if needed
        this.NewPatch(this.warrantyResponses);

        this.warrantyFieldValues.patchValue(this.warrantyFieldValues.value);
      } else {
        console.error('Invalid response from API:', res);
      }
    });
  }



  readonly validationRules = {
    title: {
      required: 'Required',
      maxLength: { limit: 50, message: 'Opportunity Title exceeds the limit.' }
    },
    classificationSetupId: {
    },
    suburb: {
      maxLength: { limit: 50, message: 'Suburb exceeds the limit.' }
    },
    state: {
      maxLength: { limit: 3, message: 'State exceeds the limit.' }
    },
    postalCode: {
      maxLength: { limit: 15, message: 'Postal Code exceeds the limit.' }
    }
  };




  getErrorTip(field: string): string {
    const rules = this.validationRules[field];
    const input = this.warrantyFieldValues[field] || '';
    if (rules.required && !input.trim()) {
      return `Required.`;
    }
    if (rules.maxLength && input.length > rules.maxLength.limit) {
      const excessLength = input.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }
    return ''; // No error
  }

  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }

  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      title: 'title',
      classificationSetupId: 'Required',
    };
    return labels[field] || field;
  }
  checkErrors(validateAllFields = false) {
    this.validationStates = {};
    this.validationMessages = {};
    const fieldsToValidate = validateAllFields ? Object.keys(this.validationRules) : Array.from(this.changedFields);
    for (const field of fieldsToValidate) {
      if (this.validationRules.hasOwnProperty(field)) {
        const errorTip = this.getErrorTip(field);
        if (errorTip) {
          this.validationStates[field] = 'error';
          this.validationMessages[field] = errorTip;
        } else {
          this.validationStates[field] = null;
          this.validationMessages[field] = '';
        }
      }
    }
  }

  onFieldChange(field: string, value: string) {
    this.warrantyFieldValues[field] = value;
    this.changedFields.add(field);
    this.checkErrors(); // Revalidate fields when input changes

    // Additional logic for specific field validations if needed
    if (field === 'title') {
      this.checkTitleField(); // Check title field specifically
    }

    if (field === 'classificationSetupId') {
      this.checkClassificationField(); // Check classification field specifically
    }

  }



  validateAllFields() {
    this.checkErrors(true);
  }

  validateChangedFields(field: string) {
    this.changedFields.add(field); // Add field to the changed fields set
    this.checkErrors(); // Validate changed fields
  }



  isButtonDisableds = false;
  checkCharacterLimits(): void {
    // const { address, suburb, state, postalCode, subject } = this.formFieldValues;
    const { title, internalComments, problemDescription } = this.formFieldValues;
    this.isButtonDisableds = false;
    // if (address?.length > 50 || suburb?.length > 50 || state?.length > 3 || postalCode?.length > 15 || subject?.length > 50) {
    if (title?.length > 50 || internalComments?.lenght > 4000 || problemDescription?.lenght > 4000) {
      this.isButtonDisableds = true;
    }
  }


  isFieldEmpty: any = {
    title: false,
    classificationSetupId: false,
  };

  checkTitleField() {
    // Check if title is empty when the input loses focus
    if (!this.warrantyFieldValues['title']?.trim()) {
      this.isFieldEmpty['title'] = true; // Set the flag to true for validation
      this.validationStates['title'] = 'error'; // Set validation state to error
    } else {
      this.isFieldEmpty['title'] = false; // Reset the flag
      this.validationStates['title'] = null; // Clear validation state
    }
    this.checkErrors(); // Run overall validation check
  }


  checkClassificationField() {
    // Check if title is empty when the input loses focus
    if (!this.warrantyFieldValues['classificationSetupId']?.trim()) {
      this.isFieldEmpty['classificationSetupId'] = true; // Set the flag to true for validation
      this.validationStates['classificationSetupId'] = 'error'; // Set validation state to error
    } else {
      this.isFieldEmpty['classificationSetupId'] = false; // Reset the flag
      this.validationStates['classificationSetupId'] = null; // Clear validation state
    }
    this.checkErrors(); // Run overall validation check
  }

  clearTitleError() {
    // Clear the title error flag when the input is focused
    this.isFieldEmpty['title'] = false;
    this.validationStates['title'] = null; // Reset validation state
  }

  clearClassificationError() {
    // Clear the classification error flag when the input is focused
    this.isFieldEmpty['classificationSetupId'] = false;
    this.validationStates['classificationSetupId'] = null; // Reset validation state
  }


  createAndModifiedByData(id: number) {
    const payload: FilterFooterAudit = {
      id: id,
      formCode: 'PMW'
    }
    this.FooterService.createdAndModifyUser(payload).subscribe(res => {
      this.footerResponse = res.result;

    })
  }


  onDateChange(date: Date | null): void {
    if (date) {
      // Create a new Date object for followUpDate
      const selectedDate = new Date(date);
      // Get the current time
      const currentTime = new Date();
      // Set the time of selectedDate to the current time
      selectedDate.setHours(currentTime.getHours(), currentTime.getMinutes(), 0, 0);
      // Update followUpTime
      this.warrantyFieldValues['completionTime'] = selectedDate;

      // Manually trigger change detection to ensure view updates
      this.cdn.detectChanges();
    }
  }


}

interface ModalTitle {
  title?: string;
  deleteTitle?: string; // Optional property for cases where deleteTitle is not needed
}
