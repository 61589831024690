


<div class="row mt-5" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <div class="col" style="text-align: center;">
       <span>
<img src="assets/header/icons/balance.svg" >

       </span>        
        <div class="row">
            <div class="col mt-2">
        <h2>Manage job costs with a holistic view</h2>
            </div>
        </div>
        <div class="col mt-3 text-secondary">
        <p>Track your job costs in a glance and always know where you stand. <br>Budgets integrate information from Estimates, Bills and POs, and <br>more.</p>
        </div>
        <div class="col mt-4" style="display: flex; justify-content: center; align-items: center;">
            <button nz-button nzType="primary" style="margin-right: 10px;" routerLink="/Financial/Estimate" >Go to Estimate</button>

            <button nz-button nz-dropdown [nzDropdownMenu]="menu4">
                Import From 
                <span nz-icon nzType="down"></span>
              </button>
              <nz-dropdown-menu #menu4="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item>menu1 1st menu item</li>
                  <li nz-menu-item>menu1 2nd menu item</li>
                  <li nz-menu-item>menu1 3rd menu item</li>
                </ul>
              </nz-dropdown-menu>
        </div>
        <button class="mt-4" nz-button nzType="link" style="margin-right: 10px;"><i class="bi bi-box-arrow-up-right me-2"></i> Learn How</button>


    </div>
  
  </div>