import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectionOwnerView } from 'src/Models/CompanySettings/Selections/SelectionOwnerView';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SelectionOwnerViewService {

  private baseUrl: string = environment.apiUrl + 'SelectionOwnerView/';


  constructor(private http: HttpClient) {}

  postData(data: SelectionOwnerView): Observable<SelectionOwnerView> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }

  getData(): Observable<SelectionOwnerView[]> {
    return this.http.get<SelectionOwnerView[]>(`${this.baseUrl}getAll`);
  }

  updateData(data: SelectionOwnerView): Observable<SelectionOwnerView> {
    return this.http.put<SelectionOwnerView>(`${this.baseUrl}`, data);
  }

  deleteData(id: number): Observable<SelectionOwnerView> {
    return this.http.delete<SelectionOwnerView>(
      `${this.baseUrl}?globalId=${id}`
    );
  }
  getDataId(id: number): Observable<SelectionOwnerView> {
    return this.http.get<SelectionOwnerView>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }
}