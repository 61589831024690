import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { CustomerInformationResponse } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { JobInformationTypeParameterResponse, jobTypeGroupResponse } from 'src/Models/CustomerInfromation/CustomerInformation';
import { ApplicationUserResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { ColorResponse } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { JobInformationByIdResponse, JobInformationResponse } from 'src/Models/Job-Scratch/JobFromScratch';
import { LeadOpportunityResponse, LookupNameSetupResponse } from 'src/Models/LeadTags';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { colorService } from 'src/Service/Job-List/Job-Information/color.service';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { JobTypeService } from 'src/Service/Job-List/Job-Information/job-type.service';
import { LeadActivityTypeService } from 'src/Service/Lead-Activity-Type/lead-activity-type.service';
import { SubVendersService } from 'src/Service/SubVendorsServices/sub-venders.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { NewContactComponent } from 'src/app/customer-infromation/Components/new-contact/new-contact.component';
import { AddNewInternalUserComponent } from 'src/app/internal-user/component/add-new-internal-user/add-new-internal-user.component';
import { ContactListComponent } from 'src/app/lead-opportunities/Components/contact-list/contact-list.component';
import { AddEditJobGroupComponent } from 'src/app/shared/component/Modal/add-edit-job-group/add-edit-job-group.component';
import {
  CustomerStatusCode,
  JobScratchPreDefinedCodes,
  LeadOpportunityListViewPreDefinedCodes,
} from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { JobsComponent } from 'src/app/shared/component/jobs/jobs.component';
import { map } from 'rxjs';
import { JobSetupResponse } from 'src/Models/Job-List/Job-Information/Job-Comp-setting-Classes';
import { JobTypeGroupService } from 'src/Service/Job-List/Job-Information/job-type-group.service';
interface ItemData {
  id: number;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'app-job-create-from-scratch',
  templateUrl: './job-create-from-scratch.component.html',
  styleUrls: ['./job-create-from-scratch.component.css']
})
// @Component({
//   selector: 'app-job-create-from-scratch',
//   templateUrl: './job-create-from-scratch.component.html',
//   styleUrls: './job-create-from-scratch.component.css',
// })
export class JobCreateFromScratchComponent implements OnInit, AfterViewInit {
  @Output() cancel = new EventEmitter();
  @Input() Test: boolean;
  isDollarFormatter = true;
  @Input() JobResponseId: number;
  @Input() leadToJobFormData: any;
  @Input() leadOpportunityResponse: LeadOpportunityResponse;
  isLoading: boolean = false;
  isVisibleInJob: boolean = true;
  touchedFields: { [key: string]: boolean } = {};
  StatusCode = CustomerStatusCode;
  isHovered = false;
  isHoveredMap: { [key: number]: boolean } = {};
  public selectedCustomers: CustomerInformationResponse[] = [];
  projectManagerNodes: NzTreeNodeOptions[] = [];
  internalUserNodes: NzTreeNodeOptions[] = [];
  subVendorsNodes: NzTreeNodeOptions[] = [];
  jobTypValues: { label: string; value: string }[] = [];
  formFieldValues: any = {};
  PAGE_ID: number = 30002;
  tabJob = [1, 2, 3, 4, 5, 6];
  ContactType: LookupNameSetupResponse[] = [];
  ChangeJobStatus: LookupNameSetupResponse[] = [];
  jobTypeList:jobTypeGroupResponse[] = [];
  JobGroupNodes: NzTreeNodeOptions[] = [];
  TimeFrameValuesType: LookupNameSetupResponse[] = [];
  WorkDaysNodes: NzTreeNodeOptions[] = [];
  precision = 2;
  JobCreateNewScratchModal = true;
  userList: ApplicationUserResponse[] = [];
  projectManagerList: ApplicationUserResponse[] = [];
  vendorList: SubVendorResponse[] = [];
  selectedUserIds: string[] = [];
  selectedUsers: ApplicationUserResponse[] = [];
  selectedVenodorIds: string[] = [];
  selectedVenodors: SubVendorResponse[] = [];
  IncludeTimeClock: boolean = false;
  checked = false;
  indeterminate = false;
  listOfCurrentPageData: readonly SubVendorResponse[] = [];
  listOfData: SubVendorResponse[] = [];
  setOfCheckedId = new Set<number>();
  disableField: boolean = false;
  UserdisableField: boolean = false;
  onSTSChange: string;
  onEditBtn: boolean = false
  PercentageType: LookupNameSetupResponse[] = [];

  preDefinedDataCodes = {
    contactType: JobScratchPreDefinedCodes.ContactType,
    JobGroup: JobScratchPreDefinedCodes.JobGroup,
    WorkDays: JobScratchPreDefinedCodes.WorkDays,
    TimeFrame: JobScratchPreDefinedCodes.TimeFrame,
    PercentageType: JobScratchPreDefinedCodes.PercentageType,
    tags: LeadOpportunityListViewPreDefinedCodes.Tags,
    changeJobStatus: JobScratchPreDefinedCodes.ChangeJobStatus,
  };
  constructor(
    private LookupFilter: LookupFilterService,
    private colorsService: colorService,
    private jobiformation: JobInformationService,
    private leadActivitiesService: LeadActivityTypeService,
    public toastsService: NzMessageService,
    private jobTypeService: JobTypeService,
    private modal: NzModalService,
    private leadOppService: LeadOpportunitiesService,
    private internalUserService: InternalUserService,
    private subVendorsService: SubVendersService,
    private jobSetupService: JobTypeGroupService,
    private cd: ChangeDetectorRef,
  ) { }
  ngOnInit(): void {
    this.isLoading = true
    this.createIJobForm();
    this.initLoad();
    this.getColor();
    this.onGetJobGroup();
    this.internalUserGetAll();
    this.subVendorsGetAll();
    this.FiltersubVendorsGetAll();
    this.FilterinternalUserGetAll();
    this.jobGetById();

    this.internalUserService.getData().subscribe(res => {
      this.userList = res.result;
      this.projectManagerNodes = [
        {
          title: 'Check All',
          value: 'check_all',
          key: 'check_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: this.userList.map((data) => ({
            title: data?.firstName + data?.lastName,
            key: data?.id.toString(),
            isLeaf: true,
          }))
        }
      ];
      console.log('this.projectManagerNodes',this.projectManagerNodes);
    })
    this.onGetJobType();
    this.contractTyperadioButtons();
    this.onAllowOwnertoseeradioButtons();
  }
  onGetJobType(){
    this.jobTypeService.getAllDataJobType().subscribe((res) => {
      this.jobTypeList = res.result
    });
    this.fetchCompanyJobsetup();
  }
  fetchCompanyJobsetup() {
    this.jobSetupService.getAllJobSetupPatch().subscribe(
      (response) => {
        let onSetRadioBut = response.result;
          if(onSetRadioBut.IsFixedPrice ===  true){
            this.contractTyperadioValue = 'A'
          } else {
            this.contractTyperadioValue = 'B'
          }
          this.isLoading = false
        }
      ,
      (error) => {
        console.error('Error:', error);
      }
    );
  }

  initLoad() {
    this.LookupFilter.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe(
      (res) => {
        // -----------single Select ---------------
        let contactTypeValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.contactType.code
        );
        let WorkDaysValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.WorkDays.code
        );
        let TimeFrameValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.TimeFrame.code
        );
        let PercentageType = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.PercentageType.code
        );
        let ChangeJobStatus = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.changeJobStatus.code
        );
        // -----------single Select ---------------
        this.PercentageType = PercentageType;
        this.JobFormFieldsValue.percentageTypeId = this.PercentageType[0].id
        this.ContactType = contactTypeValues;
        this.ChangeJobStatus = ChangeJobStatus;
        this.JobFormFieldsValue['statusSetupId'] = this.ChangeJobStatus[0].id
        this.TimeFrameValuesType = TimeFrameValues;
        this.JobFormFieldsValue.timeFrameSetupId = this.TimeFrameValuesType[7].id
        this.WorkDaysNodes = [
          {
            title: 'Check All',
            value: 'check_all',
            key: 'check_all',
            selectable: false,
            isLeaf: false,
            expanded: true,
            children: WorkDaysValues.map((WorkDays) => ({
              title: WorkDays.name,
              key: WorkDays.id.toString(),
              isLeaf: true,
            }))
          }
        ];
      }
    );
  }
  getTabJob(tab: number): string {
    switch (tab) {
      case 1:
        return 'Job';
      case 2:
        return 'Owners';
      case 3:
        return 'Internal Users';
      case 4:
        return 'Subs/Vendors';
      case 5:
        return 'Options';
      case 6:
        return 'Accounting';
      default:
        return '';
    }
  }
  Budget() {
    this.IncludeTimeClock = !this.IncludeTimeClock;
  }
  customPrecisionFn(value: string | number, precision?: number): number {
    return +Number(value).toFixed(precision! + 1);
  }
  CancelJobScartch() {
    this.cancel.emit();
  }
  onGetJobGroup(){
    this.LookupFilter.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe(
      (res) => {
        let JobGroupValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.JobGroup.code
        );
        this.JobGroupNodes = [
          {
            title: 'Check All',
            value: 'check_all',
            key: 'check_all',
            selectable: false,
            isLeaf: false,
            expanded: true,
            children: JobGroupValues.map((JobGroup) => ({
              title: JobGroup.name,
              key: JobGroup.id.toString(),
              value: JobGroup.id.toString(),
              isLeaf: true,
            }))
          }
        ];
      });
  }
  jobGroupSetupOpen(selectedValue: LookupNameSetupResponse) {
    const selectedId: number = Number(selectedValue); 
    const modalRef = this.modal.create({
      nzContent: AddEditJobGroupComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.selectedValue = selectedId;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.jobGroupOnCompleted.subscribe((data: LookupNameSetupResponse) =>{
      this.onGetJobGroup();
      if(data.id === 0){
        this.JobFormFieldsValue.createJobGroupParameterRequests = null
      } else {
        this.JobFormFieldsValue.createJobGroupParameterRequests = data.id.toString();
        this.onEditBtn = true
      }
    });
  }
  jobsOpen() {
    const modalRef = this.modal.create({
      nzContent: JobsComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  showContactlModal() {
    this.leadOppService.setAgainRowHide(true);
    const modalRef = this.modal.create({
      nzContent: NewContactComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.selectedCustomer.subscribe((data) => {
      this.selectedCustomers.push(data);
    });
  }
  selectionModelOpen() {
    const modalRef = this.modal.create({
      nzContent: ContactListComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.selectedCustomer.subscribe((data) => {

      if (!this.selectedCustomers.some(customer => customer.id === data.id)) {
        this.selectedCustomers.push(data);
        this.formFieldValues.createJobInformationOwnerParameterRequests = this.selectedCustomers.map(customer => ({
          customerInformationId: customer.id
        }));
      }
    });
  }
  getStatusColor(status: boolean): string {
    if (status === true) {
      return '#c4fae2';
    } else if (status === false) {
      return '#c7d0d9';
    } else {
      return ''; 
    }
  }
  removeCustomerFromJob(selectedCustomer: CustomerInformationResponse): void {
    this.modal.confirm({
      nzTitle: '<b>Remove from Job?</b>',
      nzContent: '<p>This contact will be removed from the job and will lose access to any shared files or to-dos assigned to them.</p>',
      nzOkText: 'Remove',
      nzOkDanger: true,
      nzCentered: true,
      nzCloseIcon: 'false',
      nzIconType: 'false',
      nzOnOk: () => {
        const index = this.selectedCustomers.indexOf(selectedCustomer);
        if (index !== -1) {
          this.selectedCustomers.splice(index, 1);
        }
      }
    });
  }
  onFirstCustomerSelectionChange(id: number): void {
    if (this.selectedCustomers.length > 0) {
      const selectedCustomer = this.selectedCustomers.find(customer => customer.id === id);
      if (selectedCustomer) {
        this.selectedCustomers[0].displayName;
      }
    }
  }
  selectedCustomerOpenEditMode() {
    const modalRef = this.modal.create({
      nzContent: NewContactComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  internalUserGetAll() {
    this.internalUserService.getData().subscribe(res => {
      this.userList = res.result;

      this.internalUserNodes = this.userList.map((data) => ({
        title: data?.firstName + ' ' + data?.lastName,
        value: data?.id.toString(),
        key: data?.id.toString(),
        isLeaf: true,
      }));
    });
  }
  FilterinternalUserGetAll() {
    this.internalUserService.getData().subscribe(result => {
      this.selectedUsers = result?.result.filter(data => data?.isGrantExsistJobOrTemplate === true)
    });
  }
  subVendorsGetAll() {
    this.subVendorsService.getData().subscribe(res => {
      this.vendorList = res.result;
      this.subVendorsNodes = this.vendorList.map((data) => ({
        title: data.companyName,
        value: data.id.toString(),
        key: data.id.toString(),
        isLeaf: true,
      }));
    })
  }
  FiltersubVendorsGetAll() {
    this.subVendorsService.getData().subscribe(result => {
      this.selectedVenodors = result?.result.filter(data => data?.automaticallyPermitAccessToNewJobs === true)
    });
  }
  addSelectedUsers() {
    const selectedUsersToAdd = this.userList.filter(user => this.selectedUserIds.includes(user.id.toString()));
    const uniqueSelectedUsersToAdd = selectedUsersToAdd.filter(userToAdd => !this.selectedUsers.some(user => user.id === userToAdd.id));

    const userViewAccessParameters = uniqueSelectedUsersToAdd.map(user => ({
      applicationUserId: user.id,
      isNotification: true,
      isStatus: true
    }));

    this.formFieldValues.createJobInternalUserParameterRequests.push(...userViewAccessParameters);

    this.selectedUsers.push(...uniqueSelectedUsersToAdd);
    this.internalUserNodes = this.internalUserNodes.filter(node => !this.selectedUserIds.includes(node['value']));
    this.selectedUserIds = this.selectedUserIds.filter(id => !uniqueSelectedUsersToAdd.map(user => user.id.toString()).includes(id));

    if (this.internalUserNodes.length === 0 && this.selectedUserIds.length === 0) {
      this.selectedUserIds.push('All users have been added to this job.');
      this.UserdisableField = true;
    }
  }
  removeUserRows() {
    const removedRows = this.selectedUsers.filter(data => this.setOfCheckedId.has(data.id));
    this.selectedUsers = this.selectedUsers.filter(data => !this.setOfCheckedId.has(data.id));
    const removedNodes = removedRows.map(row => ({
      title: row.firstName + row.lastName,
      key: row.id.toString(),
      value: row.id.toString(),
      isLeaf: true,
    }));
    this.internalUserNodes = this.internalUserNodes.concat(removedNodes);

    this.UserdisableField = this.internalUserNodes.length === 0 && this.selectedUserIds.length === 0;
    if (this.UserdisableField) {
      this.selectedUserIds.push(' ');
    }
    this.setOfCheckedId.clear();
    this.refreshCheckedStatus();
  }
  newInternalUserModalOpen() {
    const modalRef = this.modal.create({
      nzContent: AddNewInternalUserComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  generateInitials(companyName: string): string {
    if (!companyName) return '';
    return companyName[0].toUpperCase();
  }
  internalUserAvatarText(firstName: string, lastName: string): string {
    const initials = (firstName?.charAt(0) || '') + (lastName?.charAt(0) || '');
    return initials.toUpperCase();
  }
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }
  onCurrentPageDataChange($event: readonly SubVendorResponse[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }
  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }
  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }
  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.length > 0 && this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }
  removeVendorRows() {
    const removedRows = this.selectedVenodors.filter(data => this.setOfCheckedId.has(data.id));
    this.selectedVenodors = this.selectedVenodors.filter(data => !this.setOfCheckedId.has(data.id));
    const removedNodes = removedRows.map(row => ({
      title: row.companyName,
      key: row.id.toString(),
      value: row.id.toString(),
      isLeaf: true,
    }));
    this.subVendorsNodes = this.subVendorsNodes.concat(removedNodes);
    this.disableField = this.subVendorsNodes.length === 0 && this.selectedVenodorIds.length === 0; // Toggle disableField based on subVendorsNodes length and selectedVenodorIds length
    if (this.disableField) {
      this.selectedVenodorIds.push('');
    }
    this.setOfCheckedId.clear();
    this.refreshCheckedStatus();
  }
  addSelectedVendors() {
    const selectedUsersToAdd = this.vendorList.filter(user => this.selectedVenodorIds.includes(user.id.toString()));
    const uniqueSelectedVendorsToAdd = selectedUsersToAdd.filter(userToAdd => !this.selectedVenodors.some(user => user.id === userToAdd.id));
    const selectedVendorsAccessParameters = uniqueSelectedVendorsToAdd.map(vendor => ({
      subVendorId: vendor.id,
      isStatus: true
    }));

    this.formFieldValues.createJobSubVendorUserParameterRequests.push(...selectedVendorsAccessParameters);
    this.selectedVenodors.push(...uniqueSelectedVendorsToAdd);
    this.subVendorsNodes = this.subVendorsNodes.filter(node => !this.selectedVenodorIds.includes(node['value']));
    this.selectedVenodorIds = this.selectedVenodorIds.filter(id => !uniqueSelectedVendorsToAdd.map(user => user.id.toString()).includes(id));
    this.disableField = this.subVendorsNodes.length === 0 && this.selectedVenodorIds.length === 0; // Toggle disableField based on subVendorsNodes length and selectedVenodorIds length

    if (this.subVendorsNodes.length === 0 && this.selectedVenodorIds.length === 0) {
      this.selectedVenodorIds.push('All Sub/Vendors have been added to this job.');
    }
  }
  onPercentageTypeChange(value: string) {
    this.isDollarFormatter = value === 'markup';
  }
// ================Abdul Rehman Job============
 @Output() onSaveJobComplete = new EventEmitter();
 colors: ColorResponse[] = [];
 @ViewChild('titleInput') titleInput: ElementRef<HTMLInputElement>;
 isSaveAndClose: boolean = false;
 isSaveAndNew: boolean = false;
 isSubmitted: boolean = false;
 isSave = false;
 conformCardShow = false;
 userInvited: boolean = false;
 validationStates: { [key: string]: NzStatus } = {};
 validationMessages: { [key: string]: string } = {};
 validationStatestest123: { [key: string]: NzStatus } = {};
 changedFields: Set<string> = new Set<string>();
 tagNodes: NzTreeNodeOptions[] = [];
 radioValue = 'A';
 contractTyperadioValue = 'B';
 AllowOwnertoseeradioButtons = 'A';
 showtheBudgetradioButtons = 'B';
 JobFormFieldsValue: any = {};
 jobInformationResponse: JobInformationByIdResponse;
 selectedColor: string;
 selectedColorName: string = '';
 initials: string[] = [];
 chooseExistingContactCustomer: any[] = [];
 projectedCompletionError: boolean = false;
 WorkDaysError: boolean = false;
 actualCompletionError: boolean = false;
 isDeleteLoading: boolean = false;
 items: any[] = []
 DisabledNextActivity: boolean = false;
 onShowtheBudget: any;
  isMarkVisibility: boolean = false;
  isonAllowOwnertoaddWarrantyVisibility: boolean = false;

 ngAfterViewInit(): void {
   this.focusTitleInput();
 }
 private focusTitleInput(): void {
   setTimeout(() => {
     if (this.titleInput) {
       this.titleInput.nativeElement.focus();
     }
   }, 1000);
 }
 saveJob(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save', setAsNewLead: boolean = false): Promise<void> {
  return new Promise((resolve, reject) => {
    if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
      return reject('Already processing');
    }

    if (actionType === 'saveAndNew') {
      this.isSaveAndNew = true;
    } else if (actionType === 'saveAndClose') {
      this.isSaveAndClose = true;
    } else {
      this.isSave = true;
    }

    this.DisabledNextActivity = true;

    if (setAsNewLead) {
      this.formFieldValues.asNewLead = true;
    }
    
    this.isSubmitted = true;
    this.checkErrors();

    if (this.actualCompletionError || this.projectedCompletionError || Object.values(this.validationStates).includes('error')) {
      setTimeout(() => {
        const errorElements = document.querySelectorAll('.error-message');
        if (errorElements.length > 0) {
          const lastErrorElement = errorElements[errorElements.length - 1];
          lastErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100);
    
      this.resetFlags();
      this.DisabledNextActivity = false;
      return reject('Validation errors present');
    }

    this.JobFormFieldsValue.createJobInformationOwnerParameterRequests =  this.chooseExistingContactCustomer.map(ix => ix.id);
    let jobGroup = this.JobFormFieldsValue.createJobGroupParameterRequests?.map(
      (id: string) => ({ jobGroupId: id })
    );
    let workDay = this.JobFormFieldsValue.createWorkDayParameterRequests?.map(
      (id: string) => ({ workDayId: id })
    );
    let applicationUser = this.JobFormFieldsValue.createJobInformationUserParameterRequests?.map(
      (id: string) => ({ applicationUserId: id })
    );
    let customerInformation = this.JobFormFieldsValue.createJobInformationOwnerParameterRequests?.map(
      (id: string) => ({ customerInformationId: id })
    );
    
    let formDataToSend = {
      ...this.JobFormFieldsValue,
      createJobGroupParameterRequests: jobGroup,
      createWorkDayParameterRequests: workDay,
      createJobInformationUserParameterRequests: applicationUser,
      createJobInformationOwnerParameterRequests: customerInformation,
    };
    this.jobiformation.PostJobScratch(formDataToSend).subscribe(
      (response) => {
        this.jobInformationResponse = response.result;
        this.JobFormFieldsValue.globalId = response.result.globalId;
        this.isSubmitted = false;

        this.toastsService.success('Saved Successfully');
        this.DisabledNextActivity = false;
     
        this.onAfterResponseUse();
        resolve();
      },
      (error) => {
        console.error('Error saving data:', error);
        this.toastsService.error('An error has occurred. Please try again.');
        this.DisabledNextActivity = false;
        reject(error);
      }
    ).add(() => {
      this.resetFlags();
      this.conformCardShow = false;
      this.isSubmitted = false;
    });
  });
 }
 saveJobAndNew() {
   this.saveJob('saveAndNew').then(() => {
     this.resetForm();
   }).catch((error) => {
     console.error('Error during save and new:', error);
   }).finally(() => {
   });
 }
 saveJobAndClose(): Promise<void> {
   return this.saveJob('saveAndClose').then(() => {
     this.resetForm();
     this.CancelJobScartch();
   }).catch((error) => {
     console.error('Error during save and close:', error);
   })
 }
 onAfterResponseUse() {
  this.leadActivitiesService.setLeadActivityTypeResponse(this.jobInformationResponse);
 }
 resetForm(): void {
   this.JobFormFieldsValue = {},
   this.jobInformationResponse = null;
   this.validationStates[this.JobFormFieldsValue] = null;
   this.validationMessages[this.JobFormFieldsValue.name] = '';
   this.cd.detectChanges();
   this.validationStates[this.JobFormFieldsValue] = null;
   this.validationMessages[this.JobFormFieldsValue] = '';
 }
 resetFlags() {
   this.isSave = false; 
   this.isSaveAndClose = false;
   this.isSaveAndNew = false;
   this.cd.detectChanges();
 }
 onFieldReadioChange() {
   this.radioButtons();
   this.checkErrors();
 }
 readonly validationRules = {
   name: {
     required: 'Required',
     maxLength: { limit: 50, message: 'Job Name.' }
   },
   jobPrefix: {
     maxLength: { limit: 11, message: 'Job Prefix' }
    },
    DonotcreateanewContactinXero: {
      message: 'An owner is required to connect a job to accounting',
    },
    jobInformationTypeParameterId: {
      required: 'Required',
      maxLength: {message: 'Job Type' }
    },
    createWorkDayParameterRequests: {
      maxLength: {message: 'Work Days' }
    },
    projectedCompletion: {
      maxLength: { message: 'Projected Start Date' }
    },
    actualCompletion: {
      maxLength: { message: 'Actual Completion' }
    },
   streetAddress: {
     maxLength: { limit: 50, message: 'Street' }
   },
   suburb: {
     maxLength: { limit: 50, message: 'Suburb' }
   },
   state: {
     maxLength: { limit: 3, message: 'State' }
   },
   postalCode: {
     maxLength: { limit: 15, message: 'Postal Code' }
   },
   permitNo: {
     maxLength: { limit: 25, message: 'Permit #' }
   },
   lotInformation: {
     maxLength: { limit: 35, message: 'Lot Info' }
   },
   notesForInternalUsers: {
     maxLength: { limit: 2500, message: 'Notes for Internal Users' }
   },
   notesForSubsVendors: {
     maxLength: { limit: 2500, message: 'Notes for Subs/Vendors' }
   },
 };
 getFieldLabel(field: string): string {
   const labels: { [key: string]: string } = {
     name: 'Job Name',
     jobPrefix: 'Job Prefix',
     DonotcreateanewContactinXero: 'Accounting Connection',
     streetAddress: 'Street',
     suburb: 'Suburb',
     state: 'State',
     postalCode: 'Postal Code',
     jobInformationTypeParameterId: 'Job Type',
     projectedCompletion: 'Projected Start Date',
     createWorkDayParameterRequests: 'Work Days',
     actualCompletion: 'Actual Completion',
     permitNo: 'Permit #',
     lotInformation: 'Lot Info',
     notesForInternalUsers: 'Notes for Internal Users',
     notesForSubsVendors: 'Notes for Subs/Vendors',
   };
   return labels[field] || field;
 }
 
 getErrorTip(field: string): string {
  const rules = this.validationRules[field];
  const input = this.JobFormFieldsValue[field];

  const inputValue = typeof input === 'string' ? input.trim() : '';


  if (rules.required && !input) {
    return 'Required.';
  }

    if (rules.maxLength && inputValue.length > rules.maxLength.limit) {
    const excessLength = inputValue.length - rules.maxLength.limit;
    const unit = excessLength === 1 ? 'character' : 'characters';
    return `${excessLength} ${unit} over.`;
  }
    if (field === 'DonotcreateanewContactinXero') {
    return rules?.message;
  }

  return '';
 }
//  getErrorTip(field: string): string {
//   const rules = this.validationRules[field];
//   const input = this.JobFormFieldsValue[field];

//   const inputValue = typeof input === 'string' ? input.trim() : '';

//   if (rules.required && inputValue.length === 0) {
//     return 'Required';
//   }

//   if (rules.maxLength && inputValue.length > rules.maxLength.limit) {
//     const excessLength = inputValue.length - rules.maxLength.limit;
//     const unit = excessLength === 1 ? 'character' : 'characters';
//     return `${excessLength} ${unit} over.`;
//   }

//   if (field === 'DonotcreateanewContactinXero') {
//     return rules?.message;
//   }

//   return '';
//  }
 get errorFields(): { field: string; label: string; message: string }[] {
   return Object.keys(this.validationStates)
     .filter(field => this.validationStates[field] === 'error')
     .map(field => ({
       field,
       label: this.getFieldLabel(field),
       message: this.validationMessages[field]
     }));
 }

 checkErrors() {
  this.validationStates = {};
  this.validationMessages = {};

  for (const field in this.validationRules) {
    if (this.validationRules.hasOwnProperty(field)) {
      if (this.touchedFields[field] || this.isSubmitted) {
        const errorTip = this.getErrorTip(field);
        if (field === 'DonotcreateanewContactinXero' && this.radioValue === 'C') {
          this.validationStates[field] = null;
          this.validationMessages[field] = '';
          continue;
        }
        if (errorTip) {
          this.validationStates[field] = 'error';
          this.validationMessages[field] = errorTip;
        } else {
          this.validationStates[field] = null;
          this.validationMessages[field] = '';
        }
      }
    }
  }

  if (!this.JobFormFieldsValue['createWorkDayParameterRequests'] || this.JobFormFieldsValue['createWorkDayParameterRequests'].length === 0) {
    this.validationStates['createWorkDayParameterRequests'] = 'error';
    this.validationMessages['createWorkDayParameterRequests'] = 'Must select at least one workday';
  }

  this.updateErrors();
  if (this.projectedCompletionError) {
    this.validationStates['projectedCompletion'] = 'error';
    this.validationMessages['projectedCompletion'] = 'Completion date must be on or later than start date';
  }
  if (this.actualCompletionError) {
    this.validationStates['actualCompletion'] = 'error';
    this.validationMessages['actualCompletion'] = 'Completion date must be on or later than start date';
  }
  if (this.actualCompletionError) {
    this.validationStates['actualCompletion'] = 'error';
    this.validationMessages['actualCompletion'] = 'Completion date must be on or later than start date';
  }
  if (this.radioValue === 'C') {
    delete this.validationStates['accountingConnection'];
    delete this.validationMessages['accountingConnection'];
  }
 }
 onFieldChange(field: string, value: string) {
   this.JobFormFieldsValue[field] = value.trim();
   this.touchedFields[field] = true;
   this.checkErrors(); 
 }
 createIJobForm() {
   this.JobFormFieldsValue = {
     globalId: '00000000-0000-0000-0000-000000000000',
     name: '',
     jobPrefix: '',
     contractTypeId: 0,
     statusSetupId: 0,
     jobInformationTypeParameterId: 0,
     isFixedPrice: false,
     isOpenBook: false,
     streetAddress: '',
     suburb: '',
     state: '',
     postalCode: '',
     projectedStart: null,
     projectedCompletion: null,
     actualStart: null,
     actualCompletion: null,
     updateActualDatesBasedOnSchedule: false,
     colorId: 0,
     notesForInternalUsers: '',
     notesForSubsVendors: '',
     customerInformationId: 0,
     customerInformationStatus: false,
     contractPrice: '',
     squareFeet: null,
     permitNo: '',
     lotInformation: '',
     timeFrameSetupId: 0,
     isScheduleItems: true,
     isPhases: false,
     isShowJobPriceSummary: true,
     isShowOwnerInvoices: true,
     isAllowOwnerToPreviewLockedSelections: false,
     isAllowOwnerToViewProjectManagerPhoneNumber: true,
     isAllowOwnerToSubmitChangeOrderRequests: true,
     isAllowOwnerToAddWarrantyClaims: false,
     isEnableGeofencingOnTimeClockShifts: true,
     isAllowCreationOfAllowances: true,
     isUpdateJobRunningTotalWhenaSelectionIsApproved: true,
     individualBillPoLimit: 0,
     totalJobBillPoLimit: 0,
     includeTimeClockLaborInTheBudget: false,
     includeTimeClockLaborInTotalCostsAndPaidColumnsonBudget: false,
     isMakeThisJobAWorkingTemplate: false,
     isMakeScheduleOnline: false,
     templateName: '',
     leadOpportunityId: 0,
     percentageTypeId: 0,
     percentageValue: 0,
     createJobGroupParameterRequests: [],
     createWorkDayParameterRequests: [
       '1193',
       '1194',
       '1195',
       '1196',
       '1197'
     ],
     createJobInternalUserParameterRequests: [],
     createJobSubVendorUserParameterRequests: [],
     createJobInformationAdvancedNotificationsRequests: [],
     createJobInformationUserParameterRequests: [],
     createJobInformationOwnerParameterRequests: [],
   };
 }
 radioButtons(){
   switch (this.radioValue) {
     case 'A':
       this.JobFormFieldsValue.ChooseXeroContact = false
       this.JobFormFieldsValue.DonotcreateanewContactinXero = false
       this.JobFormFieldsValue.createnewContactinXero = true;
       break;
     case 'B':
       this.JobFormFieldsValue.createnewContactinXero = false
       this.JobFormFieldsValue.DonotcreateanewContactinXero = false
       this.JobFormFieldsValue.ChooseXeroContact = true;
       break;
     case 'C':
       this.JobFormFieldsValue.createnewContactinXero = false
       this.JobFormFieldsValue.ChooseXeroContact = false
       this.JobFormFieldsValue.DonotcreateanewContactinXero = true;
       break;
   }
 }
 contractTyperadioButtons(){
   switch (this.contractTyperadioValue) {
     case 'A':
       this.JobFormFieldsValue.isOpenBook = false
       this.JobFormFieldsValue.isFixedPrice = true;
      break;
      case 'B':
        this.JobFormFieldsValue.isFixedPrice = false
        this.JobFormFieldsValue.isOpenBook = true;
      break;
    }
  }
  onAllowOwnertoseeradioButtons(){
    switch (this.AllowOwnertoseeradioButtons) {
      case 'A':
        this.JobFormFieldsValue.isPhases = false
        this.JobFormFieldsValue.isScheduleItems = true;
        break;
        case 'B':
          this.JobFormFieldsValue.isScheduleItems = false;
        this.JobFormFieldsValue.isPhases = true
        break;
    }
  }
  onShowtheBudgetradioButtons(){
    // switch (this.AllowOwnertoseeradioButtons) {
    //   case 'A':
    //     this.JobFormFieldsValue.isPhases = false
    //     this.JobFormFieldsValue.isScheduleItems = true;
    //     break;
    //     case 'B':
    //       this.JobFormFieldsValue.isScheduleItems = false;
    //     this.JobFormFieldsValue.isPhases = true
    //     break;
    // }
  }
 onTesting(selectedValues: string[]){
  if (selectedValues.length === 1) {
    this.onEditBtn = true
  } else {
    this.onEditBtn = false
  }
  this.onTagChange(selectedValues);
 }
 onTagChange(selectedValues: string[]): void {
   const checkAllValue = 'check_all';
   const allValues = this.JobGroupNodes[0].children.map(child => child['value']);
   
   if (selectedValues.includes(checkAllValue)) {
     this.JobFormFieldsValue['createJobGroupParameterRequests'] = allValues;
     this.JobGroupNodes[0].children.forEach(child => child['checked'] = true);
   } else {
     if (this.JobFormFieldsValue['createJobGroupParameterRequests'].includes(checkAllValue)) {
       this.JobFormFieldsValue['createJobGroupParameterRequests'] = selectedValues.filter(value => value !== checkAllValue);
     }
     this.JobFormFieldsValue['createJobGroupParameterRequests'] = selectedValues.filter(value => allValues.includes(value));
     this.JobGroupNodes[0].children.forEach(child => {
       child['checked'] = this.JobFormFieldsValue['createJobGroupParameterRequests'].includes(child['value']);
     });
   }
 }
 onWorkDaysChange(selectedValues: string[]): void {
   const checkAllIndex = selectedValues.indexOf('check_all');
   if (checkAllIndex !== -1) {
     const allValues = this.JobGroupNodes[0].children.map(child => child['value']);
     this.JobFormFieldsValue['workDayId'] = [...allValues];
   } else {
     const allValues = this.JobGroupNodes[0].children.map(child => child['value']);
     this.JobFormFieldsValue['workDayId'] = selectedValues.filter(value => allValues.includes(value));
   }
   this.WorkDaysError = this.JobFormFieldsValue['createWorkDayParameterRequests'].length === 0;
   this.checkErrors(); 
 }
 notifyShow: boolean = false
 onProjectManagersChange(selectedValues: string[]): void {
  if(selectedValues.length > 0){
    this.notifyShow = true
  } else {
    this.notifyShow = false
  }
   const checkAllIndex = selectedValues.indexOf('check_all');
   if (checkAllIndex !== -1) {
     const allValues = this.JobGroupNodes[0].children.map(child => child['value']);
     this.JobFormFieldsValue['applicationUserId'] = [...allValues];
   } else {
     const allValues = this.JobGroupNodes[0].children.map(child => child['value']);
     this.JobFormFieldsValue['applicationUserId'] = selectedValues.filter(value => allValues.includes(value));
   }
 }
 getColor() {
   this.colorsService.getColorData().subscribe((res) => {
     this.colors = res.result;
     if (this.jobInformationResponse?.ColorCollection) {
     } else {
       this.JobFormFieldsValue['colorId'] = this.colors[28].id
       this.selectedColor = this.colors[28].colorCollection;
     }
   });
 }
 updateSelectedColor(colorId: number): void {
   const selectedColor = this.colors.find(color => color.id === colorId);
   if (selectedColor) {
     this.selectedColor = selectedColor.colorCollection;
     this.selectedColorName = selectedColor.name;
   } else {
     this.selectedColor = '';
     this.selectedColorName = '';
   }
 }
 updateErrors() {
   this.projectedCompletionError = !this.JobFormFieldsValue.updateActualDatesBasedOnSchedule &&
     this.JobFormFieldsValue.projectedCompletion &&
     this.JobFormFieldsValue.projectedStart &&
     this.JobFormFieldsValue.projectedCompletion < this.JobFormFieldsValue.projectedStart;
 
   this.actualCompletionError = !this.JobFormFieldsValue.updateActualDatesBasedOnSchedule &&
     this.JobFormFieldsValue.actualCompletion &&
     this.JobFormFieldsValue.actualStart &&
     this.JobFormFieldsValue.actualCompletion < this.JobFormFieldsValue.actualStart;
 }
 onDateChange(type: string) {
   this.updateErrors();
   this.checkErrors();
 }
 onCheckboxChange(isChecked: boolean) {
   this.JobFormFieldsValue.updateActualDatesBasedOnSchedule = isChecked;
 
   if (isChecked) {
     this.JobFormFieldsValue.actualStart = null;
     this.JobFormFieldsValue.actualCompletion = null;
   }
 
   this.updateErrors();
 }
 jobGetById(){
   if (this.JobResponseId) {
     this.jobiformation.jobgetDataId(this.JobResponseId).subscribe(
       (res) => {
         if (res.result) {
          this.jobInformationResponse = res.result
           this.onOwnerGetById(this.JobResponseId);
         }
       },
     )
   }
 }
 onContactCustomer: string[];
 onOwnerGetById(id: number){
  this.jobiformation.JobOwnersgetDataId(id).subscribe(
    (res) => {
      this.chooseExistingContactCustomer = res.result;
      if (this.chooseExistingContactCustomer) {
          this.onContactCustomer = this.chooseExistingContactCustomer.map(sn => sn.ShortName);
          this.initials = [...this.initials, ...new Set(this.onContactCustomer)]; 
        this.patchFormValues(this.jobInformationResponse);
      }
    },  
  )
 }
 testingNewArray: string[];
 patchFormValues(jobInfoResponse: JobInformationByIdResponse) {
  // const ShortName = "SK,MK,UB".split(',');
  // const statusSetup = "This user has been Active since,This user has not been invited to Buildertrend. Once they Accept an invite they will become Active.,This user is still Pending because the user has not accepted your invite. Invite sent on".split(',');
  // const Name = "Sami Khan,Muhammad Rehman Khan,Uzair123 Bhai".split(',');
  // const Email = "SamiIbrahim786@gmail.com,shabbir.sufyan99@gmail.com,Uzair@gmail.com".split(',');
  // const PhoneNumber = "03132334173,03131234893,0313233413".split(',');

  // const users = ShortName.map((shortName, index) => ({
  //   ShortName: shortName,
  //   statusSetup: statusSetup[index],
  //   Name: Name[index],
  //   Email: Email[index],
  //   PhoneNumber: PhoneNumber[index]
  // }));

  // console.log(users);
  this.JobFormFieldsValue.statusSetupId = jobInfoResponse.StatusSetupId
  this.testingNewArray = jobInfoResponse?.WorkDayNames.split(',').map(id => id.trim());
  this.onSTSChange = jobInfoResponse.StatusSetupName || '',
  console.log('this.onSTSChange',this.onSTSChange);
   this.selectedColor =  jobInfoResponse.ColorCollection || '',
   this.JobFormFieldsValue = {
     globalId: jobInfoResponse.GlobalId || '00000000-0000-0000-0000-000000000000',
     name: jobInfoResponse?.Name || '',
     jobPrefix: jobInfoResponse.JobPrefix || '',
     contractTypeId: jobInfoResponse.ContractTypeId || 0,
     jobInformationTypeParameterId: jobInfoResponse.JobInformationTypeParameterId || 0,
     IsFixedPrice: jobInfoResponse?.IsFixedPrice || false,
     IsOpenBook: jobInfoResponse?.IsOpenBook || false,
     streetAddress: jobInfoResponse.StreetAddress || '',
     suburb: jobInfoResponse.Suburb || '',
     state: jobInfoResponse.State || '',
     postalCode: jobInfoResponse.PostalCode || '',
     projectedStart: jobInfoResponse.ProjectedStart || null,
     projectedCompletion: jobInfoResponse.ProjectedCompletion || null,
     actualStart: jobInfoResponse.ActualStart || null,
     actualCompletion: jobInfoResponse.ActualCompletion || null,
     updateActualDatesBasedOnSchedule: jobInfoResponse.UpdateActualDatesBasedOnSchedule ?? false,
     colorId: jobInfoResponse.ColorId || 0,
     selectedColor: jobInfoResponse.ColorCollection || '',
     notesForInternalUsers: jobInfoResponse.NotesForInternalUsers || '',
     notesForSubsVendors: jobInfoResponse.NotesForSubsVendors || '',
     customerInformationId: jobInfoResponse.CustomerInformationId || 0,
     customerInformationStatus: jobInfoResponse.CustomerInformationStatus ||  false,
     statusSetupId: jobInfoResponse.StatusSetupId || 0,
     contractPrice: jobInfoResponse.ContractPrice,
     squareFeet: jobInfoResponse.SquareFeet || null,
     permitNo: jobInfoResponse.PermitNo || '',
     lotInformation: jobInfoResponse.LotInformation || '',
     timeFrameSetupId: jobInfoResponse.TimeFrameSetupId || 0,
     isScheduleItems: jobInfoResponse.IsScheduleItems ?? true,
     isPhases: jobInfoResponse.IsPhases ?? false,
     isShowJobPriceSummary: jobInfoResponse.IsShowJobPriceSummary ?? true,
     isShowOwnerInvoices: jobInfoResponse.IsShowOwnerInvoices ?? true,
     isAllowOwnerToPreviewLockedSelections: jobInfoResponse.IsAllowOwnerToPreviewLockedSelections ?? false,
     isAllowOwnerToViewProjectManagerPhoneNumber: jobInfoResponse.IsAllowOwnerToViewProjectManagerPhoneNumber ?? true,
     isAllowOwnerToSubmitChangeOrderRequests: jobInfoResponse.IsAllowOwnerToSubmitChangeOrderRequests ?? false,
     isAllowOwnerToAddWarrantyClaims: jobInfoResponse.IsAllowOwnerToAddWarrantyClaims ?? false,
     isEnableGeofencingOnTimeClockShifts: jobInfoResponse.IsEnableGeofencingOnTimeClockShifts ?? false,
     isAllowCreationOfAllowances: jobInfoResponse.IsAllowCreationOfAllowances ?? false,
     isUpdateJobRunningTotalWhenaSelectionIsApproved: jobInfoResponse.IsUpdateJobRunningTotalWhenaSelectionIsApproved ?? false,
     individualBillPoLimit: jobInfoResponse.IndividualBillPoLimit ?? 0,
     totalJobBillPoLimit: jobInfoResponse.TotalJobBillPoLimit ?? 0,
     includeTimeClockLaborInTheBudget: jobInfoResponse.IncludeTimeClockLaborInTheBudget ?? false,
     includeTimeClockLaborInTotalCostsAndPaidColumnsonBudget: jobInfoResponse.IncludeTimeClockLaborInTotalCostsAndPaidColumnsonBudget ?? false,
     isMakeThisJobAWorkingTemplate: jobInfoResponse.IsMakeThisJobAWorkingTemplate ?? false,
     isMakeScheduleOnline: jobInfoResponse.IsMakeScheduleOnline ?? false,
     templateName: jobInfoResponse.TemplateName || '',
     leadOpportunityId: jobInfoResponse.LeadOpportunityId || 0,
     percentageTypeId: jobInfoResponse.PercentageTypeId || 0,
     percentageValue: jobInfoResponse.PercentageValue || 0,
     createJobGroupParameterRequests : jobInfoResponse?.JobGroupIds? jobInfoResponse.JobGroupIds.split(',').map(id => id.trim()): [],
     createWorkDayParameterRequests: jobInfoResponse?.WorkDayIds? jobInfoResponse.WorkDayIds.split(',').map(id => id.trim()): [],   
    //  createJobInformationOwnerParameterRequests: jobInfoResponse?.workDayIds? jobInfoResponse.workDayIds.split(',').map(id => id.trim()): [],   
     createJobInformationUserParameterRequests: jobInfoResponse?.ProjectManagerIds? jobInfoResponse.ProjectManagerIds.split(',').map(id => id.trim()): [], 
   };
   
   if(jobInfoResponse.IsFixedPrice ===  true){
     this.contractTyperadioValue = 'A'
   } else {
     this.contractTyperadioValue = 'B'
   }
   if(jobInfoResponse.IsScheduleItems ===  true){
     this.AllowOwnertoseeradioButtons = 'A'
   } else {
     this.AllowOwnertoseeradioButtons = 'B'
   }
   this.radioValue = 'C'
   this.radioButtons();
   this.isLoading = false
 }
 deleteJobConfirm(): void {
   if (this.isDeleteLoading) {
     return;
   }
 
   this.modal.confirm({
     nzTitle: `Delete Job <strong>${this.jobInformationResponse.Name}</strong>?`,
     nzContent: `Are you sure you want to permanently delete the job <strong>${this.jobInformationResponse.Name}</strong> and all items on it?`,
     nzOkText: `Delete Job`,
     nzOkDanger: true,
     nzCancelText: 'Cancel',
     nzOnOk: () => {
       this.isDeleteLoading = true;
       return this.deleteSubVendor(this.JobFormFieldsValue.globalId)
         .then(() => {
           this.SubVendorNewLeadModelCancel();
         })
         .catch(() => {
 
         });
     },
     nzCentered: true,
     nzOkLoading: this.isDeleteLoading,
     nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
     nzCloseIcon: '',
     nzIconType: ''
   });
 }
 deleteSubVendor(id: number): Promise<void> {
   return new Promise((resolve, reject) => {
     if (!id) {
       this.toastsService.error('No record selected for deletion.');
       this.isDeleteLoading = false;
       reject();
       return;
     }
 
     this.jobiformation.deleteData(id).subscribe(
       (res) => {
         // this.isDeleteLoading = false;
         this.leadActivitiesService.setLeadActivityTypeResponse(this.jobInformationResponse);
         this.onAfterResponseUse();
         this.CancelJobScartch();
         this.isDeleteLoading = false;
         resolve();
       },
       (error) => {
         this.isDeleteLoading = false;
         this.toastsService.error('An error occurred while deleting the record. Please try again.');
         reject();
       }
     );
   });
 }
 SubVendorNewLeadModelCancel(): void {
   this.cancel.emit();
   this.isVisibleInJob = false;
   this.leadOppService.clearSelectedFiles();
 }
 onViewOwnerSite: boolean = false;
 chooseExistingContactCustomerOpen() {
  const modalRef = this.modal.create({
    nzContent: ContactListComponent,
    nzFooter: null,
  });

  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });

  modalRef.componentInstance.selectedCustomerInJob.subscribe((data: CustomerInformationResponse[]) => {
    const existingCustomerIds = new Set(this.chooseExistingContactCustomer.map(customer => customer.id));
    const newCustomers = data.filter(customer => !existingCustomerIds.has(customer.id));
    this.chooseExistingContactCustomer = [...this.chooseExistingContactCustomer, ...newCustomers];
    const newInitials = newCustomers.map(customer => this.getInitials(customer.displayName));
    this.initials = [...this.initials, ...new Set(newInitials)];
    this.onViewOwnerSite = true
  });
}
 getInitials(fullName: string | undefined): string {
   if (!fullName) return '';
   const nameParts = fullName.split(' ');
   const firstInitial = nameParts[0]?.charAt(0) || '';
   const lastInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1]?.charAt(0) || '' : '';
   return (firstInitial + lastInitial).toUpperCase();
 }
 CloseSpecificeID(data: CustomerInformationResponse) {
   this.chooseExistingContactCustomer = this.chooseExistingContactCustomer.filter(customer => customer.id !== data.id);
   //  this.initials = [...this.initials, ...new Set(this.chooseExistingContactCustomer)];
  //  this.initials = this.chooseExistingContactCustomer.map(customer => this.getInitials(customer.displayName));
 }
 showNewCustomerStandard() {  
   const modalRef = this.modal.create({
     nzContent: NewContactComponent,
   });
   modalRef.componentInstance.cancel.subscribe(() => {
     modalRef.destroy();
   });
 }
 deleteJobOwnerCutomerInfomationConfirm(data: CustomerInformationResponse): void {
  // if(this.onContactCustomer){
  //   this.CloseSpecificeID(this.onContactCustomer)
  // }
  // this.data: CustomerInformationResponse || 
   this.modal.confirm({
     nzTitle: `Remove from Job`,
     nzContent: `This contact will be removed from the job and will lose access to any shared files or to-dos assigned to them.`,
     nzOkText: `Remove`,
     nzOkDanger: true,
     nzCancelText: 'Cancel',
     nzOnOk: () => {this.CloseSpecificeID(data)},
     nzCentered: true,
     nzOkLoading: this.isDeleteLoading,
     nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
     nzCloseIcon: '',
     nzIconType: ''
   });
 }
 getStatusClass(statusCode: string): string {
   switch (statusCode) {
     case CustomerStatusCode.ACTIVE.code:
       return 'status-text success';
     case CustomerStatusCode.INACTIVE.code:
       return 'status-text inactive';
     case CustomerStatusCode.DISABLED.code:
       return 'status-text disabled';
     case CustomerStatusCode.PENDING.code:
       return 'status-text pending';
     default:
       return 'status-text inactive';
   }
 }
 getStatusDescription(statusCode: string) {
   return this.chooseExistingContactCustomer
     ?.find(xx => xx.statusSetup?.code === statusCode)?.statusSetup?.description || '';
 }
 onTagMouseEnter(index: number) {
   this.isHoveredMap[index] = true;
 }
 onTagMouseLeave(index: number) {
   this.isHoveredMap[index] = false;
 }
 jobsComponentsOpen() {
  const modalRef = this.modal.create({
    nzContent: JobsComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
  modalRef.componentInstance.jobsOnCompleted.subscribe((data: JobSetupResponse) =>{
    this.onGetJobType();
    if(data.isFixedPrice ===  true){
      this.contractTyperadioValue = 'A'
    } else {
      this.contractTyperadioValue = 'B'
    }
 });
}
onSquareFeetChange(value: any) {
  if (value?.toString().length === 7) {
    this.JobFormFieldsValue.squareFeet = 1000000;
  } else {
    this.JobFormFieldsValue.squareFeet = value;
  }
}
toggleFields(): void {
  if (this.onShowtheBudget) {
    this.isMarkVisibility = true;
  } else {
    this.isMarkVisibility = false;
  }
}
onAllowOwnertoaddWarrantyclaimstoggleFields(): void {
  if (this.JobFormFieldsValue.isAllowOwnerToAddWarrantyClaims) {
    this.isonAllowOwnertoaddWarrantyVisibility = true;
  } else {
    this.isonAllowOwnertoaddWarrantyVisibility = false;
  }
}
  // ================Abdul Rehman Job============
}