

<nz-modal nzWidth="95%" [(nzVisible)]="isVisibleProposalTemplateModal"
  [nzTitle]="LeadProposalTemplateTitle"
  (nzOnCancel)="handleCancelLeadProposalTemplate()"
  [nzFooter]="LeadProposalTemplateFooter" [nzBodyStyle]="{
  background: '#f1f4fa',
  padding: '14px',
  'max-height': '75vh',
  'overflow-y': 'auto'
 }" [nzStyle]="{ top: '38px' }">
  <ng-template #LeadProposalTemplateTitle>
    <div class="row">
      <h1 class="p-0 m-0 famly-inn">Lead Proposal Template</h1>
    </div>
  </ng-template>

  <div class="content top-content" *nzModalContent>

    <div class="spinner-wrapper" *ngIf="isLoading">
      <nz-spin nzSimple [nzSpinning]="true" [nzSize]="'large'"></nz-spin>
    </div>

    <!-- Error Card Start -->
    <nz-alert class="rounded-3 mb-3" *ngIf="validationErrors.length > 0"
      nzType="error" nzShowIcon [nzMessage]="message"
      [nzDescription]="errorDescriptionTemplate"></nz-alert>

    <ng-template #message>
      <p class="mt-1">Please correct the following fields:</p>
    </ng-template>

    <ng-template #errorDescriptionTemplate>
      <ul>
        <li class="sub-text" *ngFor="let error of validationErrors">
          {{ error }}
        </li>
      </ul>
    </ng-template>
    <!-- Error Card End -->
    <ng-template #extraTemplate>
      <div class="row p-0">
        <div class="bg-body-secondary p-3">
          <strong>Un Release</strong>
        </div>
      </div>
    </ng-template>
    <ng-template #newCardTitle>
      <h2>Lead Proposal Details</h2>
    </ng-template>

    <nz-card [nzTitle]="newCardTitle" nzBorderless class="rounded-2 mb-3">

      <div class="row">
        <div class="col-12">
          <div class="row align-items-center ">
            <div class="col-8">
              <label class="sub-text mb-1" for="title">
                Title
                <span class="text-danger p-error fw-bolder">*</span>
              </label>
              <nz-form-item>
                <nz-form-control
                  [nzErrorTip]="isSubmitted ? getErrorTip('title') : null"
                  [nzValidateStatus]="isSubmitted && getErrorTip('title') ? 'error' : null">
                  <input #titleInput nz-input
                    [(ngModel)]="formFieldValues.title" name="title"
                    class="form-control formField" required
                    (ngModelChange)="onFieldChange('title', $event)" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-3">
              <label class="sub-text mb-1" for="salesPersonId">
                Salesperson
                <span nz-popover [nzPopoverContent]="contentTemplate"
                  class="bi bi-info-circle-fill"></span>
              </label>
              <ng-template #contentTemplate>
                <p style="height: 90px; width: 250px;">Only the selected
                  salesperson will have access to this template.
                  Select <strong>Unassigned</strong> to make it available to
                  all.</p>
              </ng-template>
              <nz-form-item>
                <nz-form-control
                  [nzErrorTip]="isSubmitted ? getErrorTip('salesPersonId') : null"
                  [nzValidateStatus]="isSubmitted && getErrorTip('salesPersonId') ? 'error' : null">
                  <nz-select [(ngModel)]="formFieldValues['salesPersonId']"
                    name="salesPersonId" nzShowSearch
                    (ngModelChange)="onFieldChange('salesPersonId', $event)">
                    <nz-option nzLabel="--Unassigned--" nzValue="0"
                      nzSelected></nz-option>
                    <nz-option *ngFor="let option of assignedUser"
                      [nzLabel]="option.label"
                      [nzValue]="option.value"></nz-option>
                  </nz-select>
                </nz-form-control>

              </nz-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <nz-collapse>
                <nz-collapse-panel #p [ngStyle]="{'background': '#f1f4fa',
             'margin-bottom': '24px',
             }" [nzExpandedIcon]="expandedIcon"
                  [nzHeader]="tileetwe">
                  <div class="row mt-0" #p>
                    <div class="col-12">
                      <div class="row mt-2">
                        <div class="col-10">
                          <label class="sub-text">Template Description</label>
                          <textarea
                            [(ngModel)]="formFieldValues.TempeleteDescription"
                            name="TempeleteDescription" nz-input
                            [nzAutosize]="{ minRows: 2, maxRows: 10 }"
                            class="formField"></textarea>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-10">
                          <label class="sub-text">Introductory Text</label>
                          <div class="row">
                            <nz-form-item>
                              <nz-form-control>
                                <ckeditor
                                  [(ngModel)]="formFieldValues['introductoryText']"
                                  name="introductoryText"
                                  [editor]="Editor" [config]="config"
                                  class="size" id="editor">
                                </ckeditor>
                              </nz-form-control>
                            </nz-form-item>

                            <div class="row mt-2">
                              <div class="col-10" style="width: 1300px;">
                                <label class="sub-text">Closing Text</label>
                                <div class="row">
                                  <nz-form-item>
                                    <nz-form-control>
                                      <ckeditor
                                        [(ngModel)]="formFieldValues['closingText']"
                                        name="closingText"
                                        [editor]="Editor" [config]="config"
                                        class="size" id="editor">
                                      </ckeditor>
                                    </nz-form-control>
                                  </nz-form-item>

                                  <!-- Document Card For Lead Proposal Template Start  Date 08/29/2024-->
                                  <div class="card border-0 mt-3">
                                    <div class="card-head border-bottom">
                                      <h3 class="p-0 m-0 pt-2 pb-2 ms-3 react"
                                        nz-typography>Attachments</h3>
                                    </div>
                                    <div class="card-body bt-file-viewer-card">
                                      <div class="row">
                                        <div class="col-12">
                                          <button nz-button
                                            class="button-default react"
                                            (click)="uploadFiles()"><span>Add</span></button>
                                          <button nz-button
                                            class="button-default react ms-2"
                                            (click)="CreateNewFile()"><span>Create
                                              New File</span></button>
                                          <button
                                            *ngIf="selectedFiless?.length > 0"
                                            nz-button
                                            class="button-default react ms-2"
                                            (click)="viewAll()"><span>View All
                                              ({{
                                              selectedFiless?.length
                                              }})</span></button>
                                        </div>
                                      </div>
                                      <i *ngIf="showNavButtons"
                                        class="bx bx-chevron-left carousel-nav prev"
                                        (click)="previous()"></i>
                                      <i *ngIf="showNavButtons"
                                        class="bx bx-chevron-right carousel-nav next"
                                        (click)="next()"></i>
                                      <div class="carousel-container"
                                        #carouselContainer>
                                        <div class="carousel-view"
                                          *ngFor="let fileq of selectedFiless">
                                          <div class="bt-file-viewer">
                                            <img class="img-1"
                                              *ngIf="getFileIcon(fileq)"
                                              [src]="getFileIcon(fileq)" />
                                            <img (click)="onClick()"
                                              class="img-2"
                                              *ngIf="!getFileIcon(fileq) && !fileq.type.includes('video')"
                                              [src]="fileq.thumbUrl" />
                                            <ng-container
                                              *ngIf="fileq.type.includes('video')">
                                              <div class="video-container">
                                                <i nz-icon nzType="play-circle"
                                                  nzTheme="outline"
                                                  class="video-play-icon"
                                                  (click)="playVideo(fileq)"></i>
                                                <video #videoPlayer
                                                  id="video-{{ fileq.id }}"
                                                  [src]="fileq.thumbUrl"
                                                  (click)="playVideo(fileq)"></video>
                                              </div>
                                            </ng-container>
                                            <div nz-row class="action-row">
                                              <div nz-col nzSpan="24">
                                                <label
                                                  class="BTFileUpload-SecondaryText p-0 m-0 ms-2">{{
                                                  fileq.name
                                                  }}</label>
                                              </div>
                                              <div nz-col nzSpan="5">
                                                <i
                                                  style="margin: 2px 0px 0px 6px;cursor: pointer;font-size: 21px;color: #626262;"
                                                  nzTrigger="click" nz-dropdown
                                                  [nzDropdownMenu]="menu4"
                                                  class="bx bxs-chevron-down"></i>
                                                <nz-dropdown-menu
                                                  #menu4="nzDropdownMenu">
                                                  <ul nz-menu
                                                    style="width: 170px;">
                                                    <li nz-menu-item
                                                      (click)="removeFile(fileq)">
                                                      <div
                                                        class="dropdown-container">
                                                        <i
                                                          style="cursor: pointer;font-size: 20px;color: #626262;"
                                                          class='bx bx-trash'></i>
                                                        <span
                                                          class="react">Delete</span>
                                                      </div>
                                                    </li>
                                                    <li nz-menu-item
                                                      (click)="uploadFilesUpdate(fileq)"
                                                      *ngIf="isImageFile(fileq)">
                                                      <div
                                                        class="dropdown-container">
                                                        <i
                                                          style="cursor: pointer;font-size: 20px;color: #626262;"
                                                          class='bx bxs-pencil'></i>
                                                        <span class="react">Edit
                                                          Online</span>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </nz-dropdown-menu>
                                              </div>
                                              <div nz-col nzSpan="4"
                                                *ngIf="isImageFile(fileq)">
                                                <i
                                                  (click)="uploadFilesUpdate(fileq)"
                                                  style="cursor: pointer;font-size: 26px;color: #626262;"
                                                  class='bx bxs-pencil'></i>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- Document Card For Lead Proposal Template End -->

                                </div>
                              </div>
                              <ng-template #tileetwe>
                                <strong class="ms-3 fS-5">More Details</strong>
                              </ng-template>
                              <ng-template #expandedIcon let-active>
                                {{ active }}
                                <span nz-icon nzType="caret-right"
                                  class="ant-collapse-arrow"
                                  [nzRotate]="p.nzActive ? 90 : -0"></span>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </nz-collapse-panel>
              </nz-collapse>
            </div>
          </div>
        </div>
      </div>

    </nz-card>

    <!-- Second Card Start -->
<!-- tabs -->
<div class="card-container">
  <nz-tabset nzType="card">
    <nz-tab *ngFor="let tab of tabs" [nzTitle]="leadproposalTabs(tab)">
      <ng-container *ngIf="tab === 1">
        <nz-card
          nzBorderless="true"
          nzTitle="Proposal Worksheet"
          [nzExtra]="leadProposalTabsTemplate"
        >
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-2">
                <small class="ListViewJobname">Total Owner Price</small>
              </div>
              <div class="col-2">
                <small class="ListViewJobname">Total Builder Cost</small>
              </div>
              <div class="col-2">
                <small class="ListViewJobname">Estimated Profit</small>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <strong class="" style="letter-spacing: 1px">
                  {{ totalOwnerPrice !== null && totalOwnerPrice !== undefined && totalOwnerPrice !== 0 ? (totalOwnerPrice | currency:'USD') : '--' }}
                </strong>
              </div>
              <div class="col-2">
                <strong class="" style="letter-spacing: 1px">
                  {{ totalBuilderCost !== null && totalBuilderCost !== undefined && totalBuilderCost !== 0 ? (totalBuilderCost | currency:'USD') : '--' }}
                </strong>
              </div>
              <div class="col-2">
                <strong class="" style="letter-spacing: 1px">
                  {{ estimatedProfit !== null && estimatedProfit !== undefined && estimatedProfit !== 0 ? (estimatedProfit | currency:'USD') : '--' }}
                </strong>
              </div>
            </div>
          </div>
        </div>
        
          <div class="row" style="background-color: rgb(241, 244, 250)">
            <div class="col-12">
              <div class="row pt-2 pb-2 gx-0">
                <div class="col-2">
                  <button
                    class=""
                    nzTrigger="click"
                    nz-button
                    nz-dropdown
                    [disabled]="!isAnyRowChecked()"
                  >
                    Checked Option
                    <span nz-icon nzType="down"></span>
                  </button>
                </div>
                <div class="col-2">
                  <button
                   
                    class="ms-2"
                    nzTrigger="click"
                    nz-button
                    nz-dropdown
                    [nzDropdownMenu]="groupMenu"
                    [disabled]="DisabledNextActivity"
                    [class.disabled]="DisabledNextActivity"
                  >
                  Group
                  <span nz-icon nzType="down"></span>
                  </button>
                  <nz-dropdown-menu #groupMenu="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item>None</li>
                      <li nz-menu-item>Cost Category</li>
                      <li nz-menu-item>Custom</li>
                    </ul>
                  </nz-dropdown-menu>
                </div>
                <div class="col-6"></div>
                <div class="col-2 p-0 text-end">
                  <a
                    nz-popover
                    [nzPopoverContent]="Summary"
                    nzPopoverPlacement="top"
                    class="fs-5 me-3"
                    nz-icon
                    nzType="download"
                    nzTheme="outline"
                  ></a>
                  <ng-template #Summary>
                    <div class="">
                      <p style="font-size: 13px">Export to Excel</p>
                    </div>
                  </ng-template>
                  <a
                    (click)="editColumns()"
                    nz-popover
                    [nzPopoverContent]="Summary1"
                    nzPopoverPlacement="top"
                    nz-icon
                    nzType="setting"
                    nzTheme="fill"
                    class="fs-5 me-2"
                  ></a>
                  <ng-template #Summary1>
                    <div class="">
                      <p style="font-size: 13px">Edit Columns</p>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row border"
           
          >
            <div
              class="col-12 p-0 m-0"
            >
            <nz-table
            [nzFooter]="customFooter"
            nzFooter="Footer"
            [nzBordered]="false"
            nzSize="small"
            #editRowTable
            nzShowPagination="false"
            [nzScroll]="{ x: '1625px' }"
            [nzData]="listOfData"
          >
            <thead
              style="
                background-color: #f1f4fa;
                border-bottom: 1px solid #ccc;
              "
            >
              <tr>
                <th nzLeft nzWidth="9%">
                  <span style="align-items: center; position: sticky">
                    <label
                    nz-checkbox
                    [(ngModel)]="allChecked"
                    (ngModelChange)="onAllChecked($event)"
                    [nzIndeterminate]="indeterminate"
                    [nzDisabled]="!isRowCreated"
                  ></label>
                  </span>
                </th>
                <th nzLeft nzWidth="55%" class="sub-text1">Items</th>
                <th nzWidth="55%" class="sub-text1">Description</th>
                <th nzWidth="20%" class="sub-text1 text-end">Quantity</th>
                <th nzWidth="17%" class="sub-text1 text-start">Unit</th>
                <th nzWidth="25%" class="sub-text1 text-end">Unit Cost</th>
                <th nzWidth="30%" class="sub-text1 text-start">Cost Type</th>
                <th nzWidth="23%" class="sub-text1 text-end">Builder Cost</th>
                <th nzWidth="26%" class="sub-text1">Markup</th>
                <th nzWidth="25%" class="sub-text1 text-end">Owner Price</th>
                <th nzWidth="25%" class="sub-text1 text-end">Margin</th>
                <th nzWidth="17%" class="sub-text1 text-end">Profit</th>
                <th nzRight nzWidth="18%" class="sub-text1"></th>
              </tr>
            </thead>
            <tbody>
              <tr class="custom-table-row">
                <td  class="border" [colSpan]="13">
                  <a (click)="addNewRow()">
                    <span class="me-2 fs-5" nz-icon nzType="plus-circle" nzTheme="fill"></span>
                    <span class="fs-6">Item</span>
                  </a>
                </td>
              </tr>
              
              
              <ng-container
               *ngFor="let item of items; let i = index trackBy: trackById">
                <tr 
                  [ngClass]="{
                    'default-color': !item.isEditable && !isMissingRequiredFields(item),
                    'clicked-color': item.isEditable,
                    'error-background': isMissingRequiredFields(item)
                  }"
                  class="editable-row"
                  (click)="highlightRow(i)"
                  [attr.data-id]="item.id"
                >
                <td nzLeft nzWidth="9%" class="table-cell-td table-cell-td-color">
                  <label nz-checkbox [(ngModel)]="item.checked" (ngModelChange)="onItemChecked($event, index)"></label>
                </td>
              
              <td nzLeft nzWidth="55%" class="table-cell-td table-cell-td-color editable-row">
                <div  class=""  [hidden]="item.isEditable">
                  <div style="color: #004bcc;" (click)="leadProposalItemEdit(item)">{{ item?.title || "--"}} <br> {{item.costCodeId || "Sami" }}</div>
                  <!-- <div (click)="leadProposalItemEdit(item)">{{ item?.costCode?.title || "--" }}</div> -->
                </div>
                <label  [hidden]="!item.isEditable" nzWidth="55%" class="sub-textInnerLable mt-4">Title</label>
                <div [hidden]="!item.isEditable">
                  <input 
                  type="text" 
                  nz-input 
                  [(ngModel)]="item.title"
                  name="title_{{ i }}" 
                  (blur)="stopEdit(item.id)" />
                </div>
              </td>
                    <td nzWidth="55%" class="table-cell-td table-cell-td-color">
                       <div
                          class="editable-cell"
                          [hidden]="item.isEditable"
                          (click)="startEdit(item.id)"
                        >
                          {{ item?.description || "--"}}
                        </div>
                        <label  [hidden]="!item.isEditable" nzWidth="55%" class="sub-textInnerLable mt-4">Description</label>
                        <textarea
                        [hidden]="!item.isEditable"
                        (blur)="stopEdit(item.id)"
                        type="text"
                        nz-input
                        [(ngModel)]="item.description"
                        name="description_{{ i }}"
                        ></textarea>
                   </td>
                  <!-- Quantity -->
                  <td nzWidth="20%" class="table-cell-td table-cell-td-color">
                    <div
                      class="text-end editable-cell"
                      [hidden]="item.isEditable"
                      (click)="startEdit(item.id)"
                    >
                      {{ item?.quantity || "--" }}
                    </div>
                    <label [hidden]="!item.isEditable" class="sub-textInnerLable text-end">Quantity</label>
                    <nz-input-number 
                    [hidden]="!item.isEditable"
                      (blur)="stopEdit(item.id)"
                      [(ngModel)]="item.quantity"
                      (ngModelChange)="updateBuilderCost(item)"
                      name="quantity_{{ i }}"
                      [nzMin]="1"
                      [nzStep]="1"
                    ></nz-input-number>
                  </td>
                  <td class="table-cell-td table-cell-td-color">
                    <div
                      class="text-end editable-cell"
                      [hidden]="item.isEditable"
                      (click)="startEdit(item.id)"
                    >
                      {{ item?.unit || "--" }}
                    </div>
                    <label [hidden]="!item.isEditable" for="" class="sub-text">Unit</label>
                    <input
                    [hidden]="!item.isEditable"
                      (blur)="stopEdit(item.id)"
                      [(ngModel)]="item.unit"
                      name="unit_{{ i }}"
                      type="text"
                      nz-input
                      class="formField"
                    />
                  </td>
                  <td  class="table-cell-td table-cell-td-color">
                    <div
                      class="editable-cell text-end"
                      [hidden]="item.isEditable"
                      (click)="startEdit(item.id)"
                    >
                      {{ item?.unitCost || "--"}}
                    </div>
                <label [hidden]="!item.isEditable" nzWidth="25%" class="sub-textInnerLable text-end">Unit Cost</label>

                    <input
                      [(ngModel)]="item.unitCost"
                    name="unitCost_{{ i }}"
                    *ngIf="showDollarFormatter"
                    class="formField p-1"
                    [hidden]="!item.isEditable"
                    (blur)="stopEdit(item.id)"
                    (ngModelChange)="updateBuilderCost(item)"
                    nz-input
                     />
       
                <!-- Input field with $ symbol -->
                    <nz-input-group nzAddOnBefore="$">
                    <input
                     [(ngModel)]="item.unitCost"
                    name="unitCost_{{ i }}"
                        class="formField"
                        [hidden]="!item.isEditable"
                        (blur)="stopEdit(item.id)"
                     (ngModelChange)="updateBuilderCost(item)"
                        appDollarFormatter
                        nz-input
                    />
                </nz-input-group>
                  </td>
                  <td class="table-cell-td table-cell-td-color">
                    <!-- Display the selected label -->
                    <div
                      class="text-start editable-cell"
                      [hidden]="item.isEditable"
                      (click)="startEdit(item.id)"
                    >
                      {{ getSelectedOptionName(item.costTypeId) || "--" }}
                    </div>
                  
                    <!-- Label and Select for editing -->
                    <label
                      [hidden]="!item.isEditable"
                      nzWidth="30%"
                      class="sub-textInnerLable text-start"
                    >
                      Cost Type
                    </label>
                    <nz-select
                      [hidden]="!item.isEditable"
                      style="width: 100%"
                      [(ngModel)]="item.costTypeId"
                      name="costTypeId_{{ i }}"
                      nzPlaceHolder="None"
                      (nzOpenChange)="onSelectOpenChange($event)"
                    >
                      <nz-option
                        *ngFor="let option of proposalOption"
                        [nzLabel]="option.name"
                        [nzValue]="option.id"
                      ></nz-option>
                    </nz-select>
                  </td>
                  <td class="table-cell-td table-cell-td-color">
                    <div
                      class="text-start editable-cell"
                      [hidden]="item.isEditable"
                      (click)="startEdit(item.id)"
                    >
                    {{ item.builderCost.toFixed(2) || "--"}}
                    </div>
                    <label [hidden]="!item.isEditable" nzWidth="23%" class="sub-textInnerLable text-end">Builder Cost</label>
                    <strong
                    [hidden]="!item.isEditable"
                      (blur)="stopEdit(item.id)"
                      [(ngModel)]="item.builderCost"
                      name="builderCost_{{ i }}"
                    >{{item.builderCost | currency}}</strong>
                  </td>
                  <td class="table-cell-td table-cell-td-color">
                    <!-- Editable Area -->
                    <div
                    class="text-start editable-cell"
                    [hidden]="item.isEditable"
                    (click)="startEdit(item.id)"
                  >
               {{ formatMarkupValue(formFieldValues.markupValue, item.markupId) || '--' }}
                  </div>
                    
                    <!-- Non-Editable Label -->
                    <label [hidden]="!item.isEditable" nzWidth="30%" class="sub-textInnerLable text-start">
                      Markup
                    </label>
                    <div [hidden]="!item.isEditable">
                     <div class="row gx-0">
                       <div class="col-8">
                         <nz-input-group nzAddOnBefore="$" *ngIf="item.markupId === 40418 || item.markupId === 40419 ">
                           <input
                             nz-input
                             class="formField"
                             appDollarFormatter
                             [(ngModel)]="item.markupValue"
                             name="markupValue"
                             (ngModelChange)="updateBuilderCost(item)"
                           />
                         </nz-input-group>
                         <nz-input-group  *ngIf="item.markupId === 40417">
                           <input
                             nz-input
                             class="p-1"
                             appPercentage
                             [(ngModel)]="item.markupValue"
                             name="markupValue"
                             (ngModelChange)="updateBuilderCost(item)"
                           />
                         </nz-input-group>
                         <nz-input-group nzAddOnBefore="--" *ngIf="item.markupId === 40420">
                           <input
                             nz-input
                             class="formField"
                             [(ngModel)]="item.markupValue"
                             name="markupValue"
                             [disabled]="true"
                           />
                         </nz-input-group>
                       </div>
                       <div class="col-4">
                         <nz-select
                           style="width: 100%;"
                           [(ngModel)]="item.markupId"
                           name="markupId"
                           (ngModelChange)="onMarkupChange($event, item)"
                         >
                           <nz-option *ngFor="let item of markupOption" [nzLabel]="item.description" [nzValue]="item.id"></nz-option>
                         </nz-select>
                       </div>
                     </div>
                    </div>
                  </td>
                  <td  class="table-cell-td table-cell-td-color">
                    <div
                      class="fw-bold text-end"
                      [hidden]="item.isEditable"
                      (click)="startEdit(item.id)"
                    >
                    ${{ item.ownerPrice || "--"}}
                    </div>

                    
                <label [hidden]="!item.isEditable" nzWidth="25%" class="sub-textInnerLable text-end">Owner price</label>
                    <input
                    [(ngModel)]="item.ownerPrice"
                    name="ownerPrice_{{ i }}"
                    *ngIf="showDollarFormatter"
                    class="formField p-1"
                    disabled
                    [hidden]="!item.isEditable"
                    (blur)="stopEdit(item.id)"
                    nz-input
                     />
                <!-- Input field with $ symbol -->
                <nz-input-group  nzPrefix="$">
                  <input
                  [(ngModel)]="item.ownerPrice"
                  name="ownerPrice_{{ i }}"
                      disabled
                      [hidden]="!item.isEditable"
                      (blur)="stopEdit(item.id)"
                      appDollarFormatter
                      nz-input
                  />
                </nz-input-group>
                  </td>        
                  <td class="table-cell-td table-cell-td-color">
                    <div
                      class="editable-cell text-end"
                      [hidden]="item.isEditable"
                      (click)="startEdit(item.id)"
                    >
                    {{ item.margin.toFixed(2) || "--"}}%
                    </div>
                    <label [hidden]="!item.isEditable" nzWidth="35%" class="sub-textInnerLable text-end">Margin</label>
                    <nz-input-group nzAddOnBefore="%"  >
                     <input
                     nz-input
                     class="p-1"
                     appPercentage
                     [hidden]="!item.isEditable"
                     (blur)="stopEdit(item.id)"
                     [(ngModel)]="item.margin"
                     name="margin_{{i}}"
                   />
                </nz-input-group>

                  </td>



                  <td>
                    <div class="row">
                      <label class="sub-text text-end">Profit</label>
                    </div>
                    <div class="row">
                      <span
                        class="fw-bold mb-2 text-end"
                        [(ngModel)]="item.profit"
                        name="profit{{ i }}"
                        >${{ item.profit.toFixed(2) || "--"}}</span
                      >
                    </div>
                  </td>
                  <td nzRight>
                    <span
                      (click)="ConfirmDelete(i)"
                      nz-icon
                      nzType="delete"
                      class="text-primary text-center fs-4 k-cursor-pointer"
                      nzTheme="fill"
                    ></span>
                  </td>
                </tr>
                <tr
                *ngIf="activeRow === i || isMissingRequiredFields(item)"
                [ngClass]="{
                  'default-color': !item.isEditable && !isMissingRequiredFields(item),
                  'clicked-color': item.isEditable, 'error-background': isMissingRequiredFields(item)
                }"
                class="expanded-row"
                [attr.data-id]="item.id"
                (click)="highlightRow(i)"
              >
              
                      <td nzLeft style="width: 40%"></td>
                      <!-- <td nzLeft style="width: 40%"> -->
                        <td nzLeft class="table-cell-td table-cell-td-color">
                          <!-- Display the selected label -->
                          <div
                            class="text-start editable-cell"
                            *ngIf="!item.isEditable"
                            (click)="startEdit(item.id)"
                          >
                            {{ item.costCodeId || "--" }}
                          </div>
                        
                          <!-- Label and Select for editing -->
                          <label [hidden]="!item.isEditable" class="sub-text">
                            Cost Code
                            <span class="fw-bolder text-danger">*</span>
                          </label>
                          <nz-select
                          [hidden]="!item.isEditable"
                          nzShowSearch
                          [nzStatus]="item.showValidationMessage && !item.costCodeId ? 'error' : ''"
                          nzAutoFocus="true"
                          nzPlaceHolder="Select Lead"
                          [(ngModel)]="item.costCodeId"
                          name="costCodeId_{{ i }}"
                          (nzOpenChange)="onSelectOpenChange($event)"
                        >
                          <nz-option
                            *ngFor="let option of leadOptions"
                            [nzLabel]="option.title"
                            [nzValue]="option.id"
                          ></nz-option>
                        </nz-select>
                        
                        <!-- Validation message -->
                        <div *ngIf="item.showValidationMessage && !item.costCodeId" class="text-danger">
                          Required
                        </div>
                        </td>
                        
                      <td style="width: 17%">
                        <label for="" class="sub-text">Mark As</label>
                        <nz-select
                          style="width: 100%"
                          nzPlaceHolder="None"
                          [(ngModel)]="item.markAsId"
                          name="markAsId_{{ i }}"
                        >
                          <nz-option
                            *ngFor="let option of markAsOption"
                            [nzLabel]="option.name"
                            [nzValue]="option.id"
                          ></nz-option>
                        </nz-select>
                      </td>
                      <td style="width: 17%"></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style="width: 17%"></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td nzRight></td>
                    </tr>
                  </ng-container>
     
                  <ng-container *ngIf="leadOpportunitiesCost != null">
                    <ng-container *ngFor="let data of leadOpportunitiesCost; let i = index">
                      <tr [ngClass]="{'default-color': activeRow !== i, 'clicked-color': activeRow === i}" class="editable-row editable-cell" (click)="highlightRow(i)">
                    <td 
                    nzLeft 
                    nzWidth="9%" 
                    class="table-cell-td table-cell-td-color" 
                    [ngClass]="{'row-highlight': activeRow === i}">
                    <span style="align-items: center; position: sticky">
                      <label nz-checkbox></label>
                    </span>
                  </td>
                  <td
                    nzLeft
                    nzWidth="55%"
                    class="table-cell-td table-cell-td-color editable-row"
                    [ngClass]="{'row-highlight': activeRow === i}"
                    (click)="leadProposalItemEdit(data)"
                  >
                    <a>
                      <div class="flex editable-row">{{ data?.title }}</div>
                      <div class="ant-space-item editable-row">{{ data?.costCode?.title }}</div>
                    </a>
                  </td>
                      <td nzWidth="55%" class="table-cell-td table-cell-td-color">
                        <div
                          class="editable-cell"
                          [hidden]="editId === data.id"
                          (click)="startEdit(data.id)"
                        >
                          {{ data?.description }}
                        </div>
                        <label [hidden]="editId !== data.id" nzWidth="55%" class="sub-textInnerLable mt-4">Description</label>
                        <textarea
                        [hidden]="editId !== data.id"
                        (blur)="stopEdit(data.id)"
                        type="text"
                        nz-input
                        [(ngModel)]="data.description"
                        name="description_{{ i }}" 
                        ></textarea>
                      </td>
                      <td nzWidth="20%" class="table-cell-td table-cell-td-color">
                        <div
                          class="text-end editable-cell"
                          [hidden]="editId === data.id"
                          (click)="startEdit(data.id)"
                        >
                          {{ data?.quantity }}
                        </div>
                        <label [hidden]="editId !== data.id" nzWidth="20%" class="sub-textInnerLable text-end">Quantity</label>
                        <nz-input-number 
                        [hidden]="editId !== data.id"
                          (blur)="stopEdit(data.id)"
                          type="number"
                          nz-input
                          [(ngModel)]="data.quantity"
                          (ngModelChange)="updateBuilderCost(item)"
                          name="quantity_{{ i }}" 
                        >
                        </nz-input-number>
                      </td>
                      <td nzWidth="17%" class="table-cell-td table-cell-td-color">
                        <div
                          class="text-start editable-cell"
                          [hidden]="editId === data.id"
                          (click)="startEdit(data.id)"
                        >
                          {{ data?.unit }}
                        </div>
                        <label [hidden]="editId !== data.id" nzWidth="17%" class="sub-textInnerLable text-start">Unit</label>
                        <input
                          [hidden]="editId !== data.id"
                          (blur)="stopEdit(data.id)"
                          (ngModelChange)="updateBuilderCost(item)"
                          type="text"
                          nz-input
                          [(ngModel)]="data.unit"
                          name="unit_{{ i }}" 
                        />
                      </td>
                      <td nzWidth="25%" class="table-cell-td table-cell-td-color">
                        <div
                          class="text-end editable-cell"
                          [hidden]="editId === data.id"
                          (click)="startEdit(data.id)"
                        >
                          {{ data?.unitCost }}
                        </div>
                        <label [hidden]="editId !== data.id" nzWidth="25%" class="sub-textInnerLable text-end">Unit Cost</label>
                        <input
                        *ngIf="!showDollarFormatter"
                        class="formField"
                        [hidden]="editId !== data.id"
                        (blur)="stopEdit(data.id)"
                        (ngModelChange)="updateBuilderCost(item)"
                        nz-input
                        [(ngModel)]="data.unitCost"
                         />
                    <!-- Input field with $ symbol -->
                        <input
                            class="formField"
                            [hidden]="editId !== data.id"
                            (blur)="stopEdit(data.id)"
                            appDollarFormatter
                            nz-input
                            [(ngModel)]="data.unitCost"
                            name="unitCost_{{ i }}" 
                            />
                      </td>
                      <td nzWidth="25%" class="table-cell-td table-cell-td-color">
                        <div
                          class="text-start editable-cell"
                          [hidden]="editId === data.id"
                          (click)="startEdit(data.id)"
                        >
                          {{ data?.costCode?.title }}
                        </div>
                        <label [hidden]="editId !== data.id" nzWidth="30%" class="sub-textInnerLable text-start">Cost Type</label>
                        <nz-select
                        [hidden]="editId !== data.id"
                        (blur)="stopEdit(data.id)"
                        style="width: 100%"
                        [(ngModel)]="data.costTypeId"
                        name="costTypeId_{{ i }}"
                        nzPlaceHolder="None"
                      >
                        <nz-option
                          *ngFor="let option of proposalOption"
                          [nzLabel]="option.name"
                          [nzValue]="option.id"
                        ></nz-option>
                      </nz-select>
                      </td>
                      <td nzWidth="23%" class="table-cell-td table-cell-td-color">
                        <div
                          class="text-start editable-cell"
                          [hidden]="editId === data.id"
                          (click)="startEdit(data.id)"
                        >
                          {{ data?.builderCost }}
                        </div>
                        <label [hidden]="editId !== data.id" nzWidth="23%" class="sub-textInnerLable text-end">Builder Cost</label>
                        <strong
                        [hidden]="!item.isEditable"
                          (blur)="startEdit(data.id)"
                          [(ngModel)]="data.builderCost"
                          name="builderCost_{{ i }}"
                        >{{data.builderCost | currency}}</strong>
                      </td>
                      <td nzWidth="26%" class="table-cell-td table-cell-td-color">
                        <div
                          class="text-end editable-cell"
                          [hidden]="editId === data.id"
                          (click)="startEdit(data.id)"
                        >
                          {{ data?.markupValue }}
                        </div>
                        <label [hidden]="editId !== data.id" nzWidth="26%" class="sub-textInnerLable">Markup</label>
                        <input
                          [hidden]="editId !== data.id"
                          (blur)="stopEdit(data.id)"
                          nz-input
                          appDollarFormatter
                          [(ngModel)]="data.markupValue"
                          name="markupValue_{{ i }}"
                        />
                      </td>
                      <td nzWidth="25%" class="table-cell-td table-cell-td-color">
                        <div
                          class="editable-cell text-end"
                          [hidden]="editId === data.id"
                          (click)="startEdit(data.id)"
                        >
                          {{ data?.ownerPrice }}
                        </div>
                        <label [hidden]="editId !== data.id" nzWidth="25%" class="sub-textInnerLable text-end">Owner Price</label>
                         <nz-input-group  nzAddOnBefore="$">
                        <input
                            class="formField"
                            [hidden]="editId !== data.id"
                            (blur)="stopEdit(data.id)"
                            appDollarFormatter
                            nz-input
                             [(ngModel)]="data.ownerPrice"
                             name="ownerPrice_{{ i }}"
                        />
                    </nz-input-group>
                      </td>
                      <td nzWidth="17%" class="table-cell-td table-cell-td-color">
                        <div
                          class="editable-cell"
                          [hidden]="editId === data.id"
                          (click)="startEdit(data.id)"
                        >
                          {{ data?.margin }}
                        </div>
                        <label [hidden]="editId !== data.id" nzWidth="17%" class="sub-textInnerLable text-end">Margin</label>
                        <input
                          [hidden]="editId !== data.id"
                          (blur)="stopEdit(data.id)"
                          appDollarFormatter
                          nz-input
                          [(ngModel)]="data.margin"
                         name="margin_{{ i }}"
                        />
                      </td>
                      <td nzWidth="17%" class="table-cell-td table-cell-td-color">
                        <label [hidden]="editId !== data.id" nzWidth="17%" class="sub-textInnerLable text-end">Profit</label>
                        <div class="text-end">{{ data?.profit }}</div>
                      </td>
                      <td nzRight>
                        <span
                          (click)="deleteRow(i)"
                          nz-icon
                          nzType="delete"
                          class="text-primary text-center fs-4 k-cursor-pointer"
                          nzTheme="fill"
                        ></span>
                      </td>
                    </tr>
                    <tr
                    [ngClass]="{
                        'default-color': activeRow !== i,
                        'clicked-color': activeRow === i
                      }"
                    *ngIf="activeRow === i"
                    >
                      <td nzLeft></td>
                      <td nzLeft></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td nzWidth="25%" class="table-cell-td table-cell-td-color">
                       
                      <label [hidden]="editId !== data.id" nzWidth="55%" class="sub-textInnerLable">Mark As</label>
                      <nz-select
                      [hidden]="editId !== data.id"
                      (blur)="stopEdit(data.id)"
                      style="width: 100%"
                      [lang]="true"
                      nzPlaceHolder="None"
                      [(ngModel)]="data.markAsId"
                      name="markAsId_{{ i }}"
                    >
                      <nz-option
                        *ngFor="let option of markAsOption"
                        [nzLabel]="option.name"
                        [nzValue]="option.id"
                      ></nz-option>
                    </nz-select>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td nzRight></td>
                    </tr>
                  </ng-container>
                  </ng-container>
     
     
            
                  
                  
                </tbody>
                <ng-template #customFooter>
                  <tfoot>
                    <tr
                      class="ms-2 custom-table-row-tr"
                      style="border: solid 2px #ccc"
                    >
                      <td nzLeft style="width: 9%">
                        <span style="align-items: center; position: sticky"
                          ><label nz-checkbox></label
                        ></span>
                      </td>
                      <td nzLeft style="width: 13%" class="sub-text1"></td>
                      <td style="width: 18%" class="sub-text1"></td>
                      <td style="width: 6%" class="sub-text1 text-end"></td>
                      <td
                        style="width: 6%"
                        class="sub-text1 text-start"
                      ></td>
                      <td style="width: 8%" class="sub-text1 text-end"></td>
                      <td
                        style="width: 5%"
                        class="sub-text1 text-start"
                      ></td>
                      <td style="width: 6%" class="sub-text1 text-end">
                        {{ totalBuilderCost | currency }}
                      </td>
                      <td style="width: 7%" class="sub-text1"></td>
                      <td style="width: 7%" class="sub-text1 text-end">
                        {{ totalOwnerPrice | currency }}
                      </td>
                      <td style="width: 7%" class="sub-text1 text-end"></td>
                      <td style="width: 7%" class="sub-text1 text-end">
                        {{ estimatedProfit | currency }}
                      </td>
                      <td nzRight style="width: 15%" class="sub-text1"></td>
                    </tr>
                  </tfoot>
                </ng-template>
              </nz-table>

              <ng-template #customFooter>
                <tfoot>
                  <tr
                    class="ms-2 custom-table-row-tr"
                    style="border: solid 2px #ccc"
                  >
                    <td nzLeft style="width: 9%">
                      <span style="align-items: center; position: sticky"
                        ><label nz-checkbox></label
                      ></span>
                    </td>
                    <td nzLeft style="width: 13%" class="sub-text1"></td>
                    <td style="width: 18%" class="sub-text1"></td>
                    <td style="width: 6%" class="sub-text1 text-end"></td>
                    <td style="width: 6%" class="sub-text1 text-start"></td>
                    <td style="width: 8%" class="sub-text1 text-end"></td>
                    <td style="width: 5%" class="sub-text1 text-start"></td>
                    <td style="width: 6%" class="sub-text1 text-end">
                      {{ totalBuilderCost | currency }}
                    </td>
                    <td style="width: 7%" class="sub-text1"></td>
                    <td style="width: 7%" class="sub-text1 text-end">
                      {{ totalOwnerPrice | currency }}
                    </td>
                    <td style="width: 7%" class="sub-text1 text-end"></td>
                    <td style="width: 7%" class="sub-text1 text-end">
                      {{ estimatedProfit | currency }}
                    </td>
                    <td nzRight style="width: 15%" class="sub-text1"></td>
                  </tr>
                </tfoot>
              </ng-template>
            </div>
          </div>
          <div class="row mt-3 mb-3">
            <div class="col-12">
              <div class="row">
                <div class="col-12 text-end">
                  <h4 nz-typography>Owner Price: $0.00</h4>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 text-end">
                  <label
                    class="k-text-underline text-primary"
                    nzPopoverTrigger="click"
                    nz-popover
                    [nzPopoverContent]="Daily21"
                    nzPopoverPlacement="left"
                    >See full price breakdown</label
                  >
                </div>
              </div>
              <ng-template #Daily21>
                <div class="">
                  <div
                    class="row p-2"
                    style="width: 210px; font-size: 13px; line-height: 25px"
                  >
                    <div class="col-12">
                      <div class="row hoverJob p-1">
                        <div class="col-9">
                          <div class="ListViewJobname text-center">
                            Total Builder Cost
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="ListViewJobname">$0.00</div>
                        </div>
                      </div>
                      <div class="row hoverJob p-1">
                        <div class="col-9">
                          <div class="ListViewJobname text-center">
                            Total Markup
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="ListViewJobname">$0.00</div>
                        </div>
                      </div>
                      <div class="row hoverJob">
                        <div class="col-9">
                          <div class="sub-text text-center">
                            Owner Price
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="sub-text">$0.00</div>
                        </div>
                      </div>
                      <div class="row hoverJob p-1">
                        <div class="col-9">
                          <div class="ListViewJobname text-center">
                            Total Margin %
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="ListViewJobname">0.00%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </nz-card>
        <ng-template #leadProposalTabsTemplate>
          <nz-space>
            <button
              *nzSpaceItem
              nz-button
              nz-dropdown
              [nzDropdownMenu]="ImportForm"
              class=""
              nzTrigger="click"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
              Import
              <span nz-icon nzType="down"></span>
            </button>
            <nz-dropdown-menu #ImportForm="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item>SomeThing</li>
                <li nz-menu-item>SomeThing</li>
                <li nz-menu-item>SomeThing</li>
              </ul>
            </nz-dropdown-menu>
            <button
              *nzSpaceItem
              nz-button
              nz-dropdown
              [nzDropdownMenu]="AddForm"
              class=""
              nzTrigger="click"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
              Add Form
              <span nz-icon nzType="down"></span>
            </button>
            <nz-dropdown-menu #AddForm="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item (click)="showCatalogModel('NEW_PROPOSAL')">
                  Catalog
                </li>
                <li nz-menu-item (click)="showProposalCostCode()">
                  Cost Codes
                </li>
              </ul>
            </nz-dropdown-menu>
            <button
              *nzSpaceItem
              nz-button
              nzType="primary"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
              (click)="showEstimatedCost()"
              >
              Add New Cost
            </button>
          </nz-space>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="tab === 2">
        <nz-card nzBorderless="true" nzTitle="Format Proposal">
          <div class="row mb-2">
            <div class="col-9">
              <div class="form-group">
                <div
                  class="row d-flex justify-content-center align-items-center"
                >
                  <label class="sub-text align-items-center">
                    What to Display to Lead
                    <span
                      class="text-secondary p-0 m-0 bi bi-info-circle-fill"
                      nz-popover
                      nzPopoverTitle=""
                      [nzPopoverContent]="contentTemplate"
                    ></span>
                  </label>
                </div>

                <ng-template #contentTemplate>
                  <div>
                    <p style="width: 230px">
                      Including more than 6 display columns on this proposal
                      may cause text to overflow and Item Description to
                      display within the Items column. Please preview the
                      proposal before sending.
                    </p>
                  </div>
                </ng-template>

                <nz-tree-select
                  [nzMultiple]="true"
                  [nzCheckStrictly]="true"
                  nzShowSearch="true"
                  [nzNodes]=""
                  nzShowSearch
                  nzMultiple
                  nzCheckStrictly
                  nzStatus
                  nzAllowClear="false"
                  nzDropdownMatchSelectWidth="true"
                  nzCheckable
                  nzCheckAll="true"
                  nzPlaceHolder="Select Division"
                  nzHideUnMatched="true"
                ></nz-tree-select>
              </div>
            </div>
          </div>

          <div class="row p-2">
            <div class="col text-end">
              <h4>
                <strong> Total Price: $381,360.00 </strong>
              </h4>
            </div>
          </div>
        </nz-card>

        <app-format-no-data></app-format-no-data>
      </ng-container>

      <ng-container *ngIf="tab === 3">
        <app-lead-proposal-preview></app-lead-proposal-preview>
      </ng-container>
    </nz-tab>
  </nz-tabset>
</div>
<!-- tabs -->

      <!-- Second Card End -->

    </div>
    <ng-template #LeadProposalTemplateFooter>
      
      <div class="container-fluid p-0 PSCustomFooter mt-2">
        <div class="row">
          <div class="col text-start  justify-content-start align-items-start" *ngIf="getLeadProposalisTemplateData">
            <span *ngIf="footerResponse">
              <app-footer-label [footerData]="footerResponse"></app-footer-label>
            </span>
        </div>
          <div class="col text-end">
            <nz-space>
              <ng-container *nzSpaceItem>
                <button class="rounded-2" nz-button
                  *ngIf="isVisibleProposalTemplateModal && getLeadProposalisTemplateData"
                  nzType="default" [nzLoading]="isDeleteLoading"
                  (click)="deleteLeadConfirm()">
                  Delete
                </button>

              </ng-container>
              <button class="rounded-2" *nzSpaceItem nz-button nzType="primary"
                [nzLoading]="isSaveAndNew"
                (click)="saveAndNewProposalTemplate()">
                Save & New
              </button>
              <button class="rounded-2" *nzSpaceItem nz-button nzType="primary"
                [nzLoading]="isSave"
                (click)="saveLeadProposalTemplate()">
                Save
              </button>
              <button class="rounded-2" *nzSpaceItem
                (click)="saveAndCloseProposalTemplate()" nz-button
                nzType="primary"
                [nzLoading]="isSaveAndClose">
                Save & Close
              </button>
            </nz-space>
          </div>
          <!-- <div class="col-md-4 text-start">
      <span>Updated by <nz-avatar nzSize="small" nzText="MM" style="color:#f56a00; background-color:#fde3cf;"></nz-avatar> <span class="ms-1"><strong>Muhammad Ahmed</strong></span> on Oct 31, 2023, 7:27 AM</span>
    </div> -->
        </div>
      </div>
    </ng-template>
  </nz-modal>
