<nz-layout>
  <nz-sider
    class="border"
    nzCollapsible
    [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed"
    [nzWidth]="280"
    [nzTrigger]="null"
  >
    <div class="row">
      <app-side-menu-bar
      [isAllJobOption]="true"
      (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"
      ></app-side-menu-bar>
    </div>
  </nz-sider>
  <nz-layout>
    <nz-page-header>
      <nz-page-header-title>
        <h1>Cost Inbox</h1>
      </nz-page-header-title>
      <nz-page-header-extra>
        <nz-space>
          <button
            nz-button
            *nzSpaceItem
            nzType="default"
            (click)="forwordToEmailModelOpen()"
          >
            Email Receipts into Buildertrend
          </button>
          <button
            nz-button
            *nzSpaceItem
            nzType="default"
            (click)="openFilter(Cost_Inbox, PAGE_ID)"
          >
            <i class="bi bi-funnel-fill fs-6 me-2"></i>Filter
          </button>
          <button
            nz-button
            *nzSpaceItem
            nzType="primary"
            (click)="receiptUploadModelOpen()"
          >
            Upload Receipts
          </button>
        </nz-space>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-content class="custom-content p-4">
      <app-cost-inbox-empty></app-cost-inbox-empty>
    </nz-content>
  </nz-layout>
</nz-layout>
