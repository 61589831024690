import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DailyLogInformation, DailyLogInformationResponse, DailyLogSetup, DailyLogSetupResponse } from 'src/Models/Project-Management/projectManagement';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { ResponseModel, TablePageResponse } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment.prod';
import { CreateLookupFilterRequest, FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';

@Injectable({
  providedIn: 'root'
})
export class SiteDiariesService {

  private PROJECT_MANAGMENT_SITE_DIARIES_URL: string = environment.apiUrl + 'DailyLogInformation/';
  private PROJECT_MANAGMENT_SITE_DIARIES_DailyLog_URL: string = environment.apiUrl + 'DailyLogSetup/';


  constructor(private http: HttpClient) {}

  postLeadData(siteDiaiesFormData: DailyLogInformation): Observable<ResponseModel<DailyLogInformationResponse>> {
    const apiUrl = `${this.PROJECT_MANAGMENT_SITE_DIARIES_URL}`;
    return this.http.post<any>(apiUrl, siteDiaiesFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  postDailyLogData(DailyLogFormData: DailyLogSetup): Observable<ResponseModel<DailyLogSetupResponse>> {
    const apiUrl = `${this.PROJECT_MANAGMENT_SITE_DIARIES_DailyLog_URL}`;
    return this.http.post<any>(apiUrl, DailyLogFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  // getLookUpNameSetup(lookupNameSetup: LookupNameSetup): Observable<ResponseModelArray<LookupNameSetupResponse>> {
  //   const apiUrl = this.base_Url; 
  //   return this.http.post<ResponseModelArray<LookupNameSetupResponse>>(apiUrl, lookupNameSetup).pipe(
  //     catchError((error: any) => {
  //       console.error('Error:', error);
  //       return throwError(error);
  //     })
  //   );
  // }
  private base_Url: string = environment.apiUrl + 'LookupFilter/';


 

 




  setSelectedFilterId(filterId: number) {
    this.selectedFilterId.next(filterId);
  }

  userAppliedFilter(filter: boolean) {
    this.isUserAppliedFilter.next(filter);
  }


  selectedFilterId = new BehaviorSubject<number>(0);
  $selectedFilterId = this.selectedFilterId.asObservable();

  isUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $isUserAppliedFilter = this.isUserAppliedFilter.asObservable();



  getSiteDiariesData = new BehaviorSubject<ResponseModel<TablePageResponse<DailyLogInformationResponse>>>(null);
  $getSitedata = this.getSiteDiariesData.asObservable();

  // getDailydataAppliedFilterData(appliedFilter: CreateLookupFilterRequest[]) {
    getDailydataAppliedFilterData(appliedFilter: CreateLookupFilterRequest[]) {
      const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
      return this.http.post<any>(apiUrl, appliedFilter).subscribe(
        (data) => {
          this.getSiteDiariesData.next(data);
        },
        (error) => {
          console.error('Error fetching internal users:', error);
        }
      );
    }


    
    // const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
    // return this.http.post<any>(apiUrl, appliedFilter).subscribe(
    //   (data) => {
    //     this.getSiteDiariesData.next(data);
    //   },
    //   (error) => {
    //     console.error('Error fetching internal users:', error);
    //   }
    // );

  getAllSiteDiariesByFilter(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getSiteDiariesData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }


  // getAllWarrantyByFilter(filterSearchParameter: FilterSearchParameter) {
  //   const apiUrl = `${this.Url_LookUpFilter}GetAll-ByFilter`;
  //   return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
  //     (data) => {
  //       this.getWarrantyData.next(data);
  //     },
  //     (error) => {
  //       console.error('Error fetching internal users:', error);
  //     }
  //   );
  // }

}
