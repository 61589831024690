<div class="card border-0 p-0">
    <div class="card-head ms-4 me-4 pt-3">
        <div class="row">
            <div class="col-7" style="margin-top: 6px;">
              <span class="sub-text mt-3" style="letter-spacing: 2px;">THIS WEEK'S AGENDA</span>
            </div>
            <div class="col-5 text-end">
              <button class="text-end" nz-button nzType="link" (click)="templateModalOpen()"><span>View Schedule</span></button>
            </div>
         </div>
    </div>
    <div class="card-body p-0 m-0">
      <div class="row ms-3 me-4">
        <div class="col-12">
          <ul class="list-group list-group-flush">
      <li class="list-group-item"></li>
        <div class="row gx-5 pt-3 pb-3 p-2">
          <div class="col-2 mt-2">
            <nz-avatar style="background-color:  #0763fb;" nzText="17"></nz-avatar>
          </div>
          <div class="col-10">
            <div class="row">
            <div class="col-12 pira-label">
              <h3 nz-typography>Friday</h3>
            </div>
          </div>
            <div class="row">
            <div class="col-12 pira-label">
              <span>Feb 2024</span>
            </div>
          </div>
          </div>
        </div>
      <li class="list-group-item" style="background-color: #f1f4fa">
        <div class="row gx-0 pt-4 pb-3 p-2">
          <div class="col-2 mt-2">
            <div class="fw-bolder fs-4">18</div>
          </div>
          <div class="col-10">
            <div class="row">
            <div class="col-12 pira-label">
              <h3 nz-typography>Friday</h3>
            </div>
          </div>
            <div class="row">
            <div class="col-12 pira-label">
              <span>Feb 2024</span>
            </div>
          </div>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-12">
            <span class="pira-label ms-3">
              Non-workday
            </span>
          </div>
        </div>
      </li>
      <li class="list-group-item" style="background-color: #f1f4fa">
        <div class="row gx-0 pt-4 pb-3 p-2">
          <div class="col-2 mt-2">
            <div class="fw-bolder fs-4">18</div>
          </div>
          <div class="col-10">
            <div class="row">
            <div class="col-12 pira-label">
              <h3 nz-typography>Friday</h3>
            </div>
          </div>
            <div class="row">
            <div class="col-12 pira-label">
              <span>Feb 2024</span>
            </div>
          </div>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-12">
            <span class="pira-label ms-3">
              Non-workday
            </span>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row gx-0 pt-3 pb-3 p-2">
          <div class="col-2 mt-2">
            <div class="fw-bolder fs-4">18</div>
          </div>
          <div class="col-10">
            <div class="row">
            <div class="col-12 pira-label">
              <h3 nz-typography>Friday</h3>
            </div>
          </div>
            <div class="row">
            <div class="col-12 pira-label">
              <span>Feb 2024</span>
            </div>
          </div>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row gx-0 pt-3 pb-3 p-2">
          <div class="col-2 mt-2">
            <div class="fw-bolder fs-4">18</div>
          </div>
          <div class="col-10">
            <div class="row">
            <div class="col-12 pira-label">
              <h3 nz-typography>Friday</h3>
            </div>
          </div>
            <div class="row">
            <div class="col-12 pira-label">
              <span>Feb 2024</span>
            </div>
          </div>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row gx-0 pt-3 pb-3 p-2">
          <div class="col-2 mt-2">
            <div class="fw-bolder fs-4">18</div>
          </div>
          <div class="col-10">
            <div class="row">
            <div class="col-12 pira-label">
              <h3 nz-typography>Friday</h3>
            </div>
          </div>
            <div class="row">
            <div class="col-12 pira-label">
              <span>Feb 2024</span>
            </div>
          </div>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row gx-0 pt-3 pb-3 p-2">
          <div class="col-2 mt-2">
            <div class="fw-bolder fs-4">18</div>
          </div>
          <div class="col-10">
            <div class="row">
            <div class="col-12 pira-label">
              <h3 nz-typography>Friday</h3>
            </div>
          </div>
            <div class="row">
            <div class="col-12 pira-label">
              <span>Feb 2024</span>
            </div>
          </div>
          </div>
        </div>
      </li>
      <li class="list-group-item"></li>
        </ul>
        </div>
    </div>
  </div>
</div>