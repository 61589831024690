import { Component, EventEmitter, Output } from '@angular/core';
import { AddLeadActivityTypeComponent } from '../add-lead-activity-type/add-lead-activity-type.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-schedule-new-activityvity',
  templateUrl: './schedule-new-activityvity.component.html',
  styleUrls: ['./schedule-new-activityvity.component.css']
})
export class ScheduleNewActivityvityComponent {
  constructor(private modal: NzModalService) { }
  @Output() cancel = new EventEmitter<void>();
  isSelectLead = true;
  handleCancel10(): void {
    this.cancel.emit();
  }
  tabs = [1, 2];



  openSelectLead(): void {
    this.isSelectLead = true;
  }

  closeSelectLead(): void {
    this.isSelectLead = false;
  }

  isVisible7 = false;
  leadActivity() {
    this.isVisible7 = true;
  }
  handleCancel8(): void {
    this.isVisible7 = false;
  }


  leaAdctivityTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'General';
      case 2:
        return 'Attach an Email';
      default:
        return '';
    }
  }
  checked18 = false;
  time = new Date();


  toggleFields(): void {
    this.checked18 = !this.checked18;
  }

  showEmailFields = false;

  toggleEmailFields() {
    this.showEmailFields = !this.showEmailFields;
  }
  cancelEmailFields() {
    this.showEmailFields = false;
  }

  addNewLeadActivityModal() {
    const modalRef = this.modal.create({
      nzContent: AddLeadActivityTypeComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();

    });
  }

}
