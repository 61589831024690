<nz-modal nzWidth="95%" [(nzVisible)]="subsvendors" [nzTitle]="subsvendors1" [nzFooter]="subsvendors2"
(nzOnCancel)="closeSubsVendors()"
[nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '68vh', 'overflow-y': 'auto'}"
[nzStyle]="{ top: '18px' }">
<ng-template #subsvendors1>
    <div class="row p-0 m-0">
        <h1 class="p-0 m-0 fw-medium">Subs/Vendors</h1>
    </div>
</ng-template>
<div class="cantent" *nzModalContent>
    
  <div class="row famly-inn">
    <div class="col-12">
        <div class="card border-0 mt-0">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2 me-1">Additional Certificates</span>
                        <i
                        class="bi bi-info-circle-fill text-secondary"
                        nz-popover
                        [nzPopoverContent]="JobTypes"
                        nzPopoverPlacement="bottom"></i>
                        <ng-template #JobTypes>
                          <div class="rounded-2">
                            <p style="height: 80px; width: 210px;font-size: 13px;">
                                To add additional certificates include a name in the fields below. These additional certificates will also be visible to your Subs/Vendors in their Setup page.
                            </p>
                          </div>
                        </ng-template>
                    </div>
                    <div class="col text-end">
                        <button (click)="CustomFieldInfo()" nz-button nzType="primary" class="rounded-1 me-0">Add New Field</button>
                  </div> 
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                <div class="col">
                    <label class="sub-text">Additional Certificate Name 1</label>
                </div>
                    </div>
                    <div class="row">
                <div class="col-3">
                    <input  [(ngModel)]="formfieldsvalue['additionalCertificateName1']"
                    name="additionalCertificateName1" type="text" class="form-control formField">
                </div>
                    </div>
                    <div class="row">
                <div class="col">
                    <label class="sub-text">Additional Certificate Name 2</label>
                </div>
                    </div>
                    <div class="row">
                <div class="col-3">
                    <input [(ngModel)]="formfieldsvalue['additionalCertificateName2']"
                    name="additionalCertificateName2" type="text" class="form-control formField">
                </div>
                    </div>
                  </div>
                </div>
                </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Sub/Vendor Invitation Email</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="row mt-0">
                        <div class="col-md-12">
                          <kendo-editor [style]="{'flex-direction': 'column-reverse'}"
                           [value]="editorValue" [(ngModel)]="formfieldsvalue['subVendorInvitationEmail']"
                          name="subVendorInvitationEmail"
                          ></kendo-editor>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-2">
        <button nz-button nzType="default" class="rounded-1 me-0">Email Me a Simple</button>
                        </div>
                    </div>
                  </div>
                </div>
                </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Subs/Vendors Custom Fields</span>
                    </div>
                    <div class="col text-end">
                        <button (click)="CustomFieldInfo()" nz-button nzType="primary" class="rounded-1 me-0">Add New Field</button>
                  </div> 
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12 d-flex alighn-item-center justify-content-center">
                        <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.or  g/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g class="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" class="ant-empty-img-simple-path"></path></g></g></svg>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 d-flex alighn-item-center justify-content-center">
                    <span class="ant-empty-normal">No Data</span>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
        </div>

</div>
</div>

</div>
<ng-template #subsvendors2>
  <button nz-button nzType="primary" (click)="saveMethod()">Save</button>
</ng-template>
</nz-modal>









    
<!-- <------stat-Modals-------->
<nz-modal
nzWidth="40%"
[(nzVisible)]="customfieldinfo"
[nzTitle]="modalTitle1"
[nzFooter]="modalFooter1"
(nzOnCancel)="handleCancel2()"
[nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '365px', 'overflow-y': 'auto',}"
[nzStyle]="{ top: '15px' }">
  <ng-template #modalTitle1>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0 fw-medium">Subs/Vendors Custom Field
      </h1>
    </div>
  </ng-template>

  <div class="content" *nzModalContent>
      <div class="card border-0 famly-inn">
          <div class="card-head border-bottom">
              <div class="row mb-1 p-2 d-flex align-items-center">
                <div class="col">
                  <span class="fw-medium fs-6 ms-2"> Custom Field Info</span>
                </div>
      
              </div>
            </div>
                    <div class="card-body">
                      <div class="row">
                          <div class="col">
                          <label class="sub-text">
                            Label
                              <span class="text-danger fw-bolder">*</span>
                          </label>
                          <input
                          formControlName="name"
                          type="text"
                          class="form-control formField">
                      </div>
                      </div>
                      <div class="row">
                          <div class="col">
                          <label class="sub-text">
                              Data Type
                              <span class="text-danger fw-bolder">*</span>
                          </label>
                          <nz-select nzShowSearch formControlName="jobInformationTypeParameterId">
                              <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                              <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                              <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                            </nz-select>
                            </div>
                      </div>
                      <div class="row">
                          <div class="col">
                          <label class="sub-text">
                              Tool Tip Text
                          </label>
                          <textarea
                          nz-input [nzAutosize]="{ minRows: 2, maxRows: 6 }"
                          formControlName="lotInformation"
                          class="form-control formField"
                          style="height: 54px; resize: none;"
                        ></textarea>
                              </div>
                      </div>
                      <div class="row mt-1">
                        <div class=" col-9 d-flex align-items-center">
                            <label nz-checkbox [(ngModel)]="checked7"><span class="pira-label">Required?</span></label>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class=" col-9 d-flex align-items-center">
                            <label nz-checkbox [(ngModel)]="checked8"><span class="pira-label">Include In My Filters</span></label>
                        </div>
                    </div>
                      <div class="row mt-3 border-bottom pb-5">
                          <div class="col-12">
                              <label class="sub-text">Display Order 
                                  <span class="text-danger fw-bolder">*</span>
                              </label>
                              <nz-input-number
                              [nzMin]="1"
                              [nzMax]="10"
                              [nzStep]="1"
                              type="number" 
                            ></nz-input-number>
                          </div>
                      </div>
                      <div class="row mt-3">
                        <div class=" col-9 d-flex align-items-center">
                            <label nz-checkbox [(ngModel)]="checked8"><span class="pira-label">
                                Allow permitted sub/vendor to view?</span></label>
                        </div>
                    </div>
                    </div>
          </div>
</div>
  <ng-template #modalFooter1>
      <div class="mt-2 align-items-center">
        <button nz-button nzType="primary" class="rounded-1 me-0" (click)="saveMethod()" >Save</button>
      </div>

    </ng-template>
</nz-modal>