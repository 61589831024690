import { Component, OnInit, Input } from '@angular/core';
import { AddNewStandardFilterComponent } from '../../Modal/add-new-standard-filter/add-new-standard-filter.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { CreateLookupFilterRequest, FilterSearchParameter, LookUpStandardResponse, LookupFilter, LookupFilterResponse } from '../../Models/LookupModels';
import { FieldDataType } from 'src/app/Core/Enums/data_types';
import { ProjectManagementSelectionCardViewwPreDefinedCodes } from '../../Models/LookUpStandardFilterCodes';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { ApplicationGroupResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { RoleTypeService } from 'src/Service/Internaluser/role-type.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { SelectionService } from 'src/Service/Selection/selection.service';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';

@Component({
  selector: 'app-project-management-selection-card-view-filter-field',
  templateUrl: './project-management-selection-card-view-filter-field.component.html',
  styleUrls: ['./project-management-selection-card-view-filter-field.component.css']
})
export class ProjectManagementSelectionCardViewFilterFieldComponent  implements OnInit {
  predefinedValuesMultiSelect: string[] = [];
  @Input() pageId: number;
  selectedJobId: number = -1;
  /* In between Start and End these code are different from other filters   */
  /* _______________________________________________________________ START DIFFERENCE __________________________________________________________________ */
  status: NzTreeNodeOptions[] = [];
  roleNodes: NzTreeNodeOptions[] = [];
  preDefinedDataCodes = {
    standardFilter: ProjectManagementSelectionCardViewwPreDefinedCodes.standardFilter,
    title: ProjectManagementSelectionCardViewwPreDefinedCodes.Title,
    selectionStatus: ProjectManagementSelectionCardViewwPreDefinedCodes.SelectionStatus,
    locations: ProjectManagementSelectionCardViewwPreDefinedCodes.Locations,
    categories: ProjectManagementSelectionCardViewwPreDefinedCodes.Categories,
    deadline: ProjectManagementSelectionCardViewwPreDefinedCodes.deadline,
    subvendor: ProjectManagementSelectionCardViewwPreDefinedCodes.subvendor,
  };
  /*__________________________________________________________________ END DIFFERENCE __________________________________________________________________ */

  filters: any[] = [];
  lookUpStandardResponse: LookUpStandardResponse[] = [];
  createLookupFilterRequests: CreateLookupFilterRequest[] = [];
  filterResponseData: LookupFilterResponse[] = [];
  defaultStandardFilter: LookupFilterResponse;
  isSetAsDefault: boolean;
  filterSearchParameter: FilterSearchParameter;
  selectedFilter: number = -1;
  editSaveFilterFormGroup: FormGroup;
  isFormDirty: boolean = false;
  formFieldValues: any = {};
  SelectionStatusNodes : NzTreeNodeOptions[]=[];
  LocationsNodes : NzTreeNodeOptions[]=[];
  CategoriesNodes : NzTreeNodeOptions[]=[];
  deadlineOptions: { label: string, value: string }[] = [];
  vendersNodes: NzTreeNodeOptions[] = [];
  constructor(
    private fb: FormBuilder,
    public toastService: NzMessageService,
    private loadingIndicatorService: LoadingIndicatorService,
    private modal: NzModalService,
    private lookUpFilterService: LookupFilterService,
    private subVendorsService: SubVendorService,
    private accessLocalStorageService : AccessLocalStorageService,
    private selectionService: SelectionService

  ) {}
  ngOnInit(): void {
    this.initLoad();
    this.ForUbvendor()
   }
  initLoad() {
    this.lookUpFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(res => {
      // let statusValues = res.result.filter(x => x.code === this.preDefinedDataCodes.deadline.code);
      let SelectionStatusData = res.result.filter(x => x.code === this.preDefinedDataCodes.selectionStatus.code);
      let LocationsData = res.result.filter(x => x.code === this.preDefinedDataCodes.locations.code);
      let CategoriesData = res.result.filter(x => x.code === this.preDefinedDataCodes.categories.code);
      let deadline = res.result.filter(x => x.code === this.preDefinedDataCodes.deadline.code);

      // selecionstatus//
        this.SelectionStatusNodes = [
     {
      title: 'Select All',
      value: 'select_all',
      key: 'select_all',
      selectable: false,
      isLeaf: false,
      expanded: true,
      children: SelectionStatusData.map((status) => ({
       title: status.name,
       value: status.id.toString(),
       key: status.id.toString(),
       isLeaf: true
      }))
     }
    ];
// locationstatus//
      this.LocationsNodes = [
        {
         title: 'Select All',
         value: 'select_all',
         key: 'select_all',
         selectable: false,
         isLeaf: false,
         expanded: true,
         children: LocationsData.map((Location) => ({
          title: Location.name,
          value: Location.id.toString(),
          key: Location.id.toString(),
          isLeaf: true
         }))
        }
       ];


      // Categories //

      this.CategoriesNodes = [
             {
              title: 'Select All',
              value: 'select_all',
              key: 'select_all',
              selectable: false,
              isLeaf: false,
              expanded: true,
              children: CategoriesData.map((status) => ({
               title: status.name,
               value: status.id.toString(),
               key: status.id.toString(),
               isLeaf: true
              }))
             }
            ];




      this.deadlineOptions = deadline.map(res => ({
        label: res.description,
        value: res.id.toString(),
      }));
      });
      this.subVendorsService.getData().subscribe(
        (res: ResponseModelArray<SubVendorResponse>) => {
          this.vendersNodes = res.result.map(vendor => ({
            title: vendor.companyName,
            value: vendor.id.toString(),
            key: vendor.id.toString(),
            isLeaf: true
          }));
        },
        (error) => {
          console.error('Error fetching roles:', error);
        }
      );


      // this.deadlineOptions = deadline;
      // })
      const lookupFilter: LookupFilter = {
        formNameId: this.pageId,
      };
      this.getStandardFilterByFormPageId(this.pageId);
      this.fetchData(lookupFilter);
    };



    // subsvondors//
    OnVendorChange(selectedValues: string[]): void {
      const checkAllIndex = selectedValues.indexOf('select_all');
      if (checkAllIndex !== -1) {
        const allValues = this.vendersNodes[0].children.map(child => child['value']);
        this.formFieldValues['subsVendors'] = [...allValues];
      } else {
        const allValues = this.vendersNodes[0].children.map(child => child['value']);
        this.formFieldValues['subsVendors'] = selectedValues.filter(value => allValues.includes(value));
      }
    }

 // subsvondors//
    ForUbvendor() {
      this.subVendorsService.getData().subscribe(res => {
        const SubvendorAssgin = res.result;
        this.vendersNodes = [
          {
           title: 'Select All',
           value: 'select_all',
           key: 'select_all',
           selectable: false,
           isLeaf: false,
           expanded: true,
           children: SubvendorAssgin.map((LOP) => ({
            title: LOP.companyName,
            value: LOP.id.toString(),
            key: LOP.id.toString(),
            isLeaf: true
           }))
          }
         ];
      });

    }
  fetchData(lookUpStandard: LookupFilter): void {
    this.lookUpFilterService.postData(lookUpStandard).subscribe(
      data => {
        this.lookUpStandardResponse = data.result;
      },
      error => {
        console.error('Error fetching data:', error);
      }
    );
  }

  openStandardFilterComponent(type: string) {
    this.createLookupFilterRequests = [];
    this.createFormFieldsInArray();
    const modalRef = this.modal.create({
      nzContent: AddNewStandardFilterComponent,
      nzFooter: null,
      nzData:
      {
        pageId: this.pageId,
        std: this.filterResponseData,
        field: this.createLookupFilterRequests,
        clickFrom: type
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  // Categories //

 onCategoriesChange(selectedValues: string[]): void {
  const checkAllIndex = selectedValues.indexOf('select_all');
  if (checkAllIndex !== -1) {
   const allValues = this.CategoriesNodes[0].children.map(child => child['value']);
   this.formFieldValues['categories'] = [...allValues];
  } else {
   const allValues = this.CategoriesNodes[0].children.map(child => child['value']);
   this.formFieldValues['categories'] = selectedValues.filter(value => allValues.includes(value));
  }
 }

// locationstatus//
onLocationsChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
     const allValues = this.LocationsNodes[0].children.map(child => child['value']);
     this.formFieldValues['locations'] = [...allValues];
    } else {
     const allValues = this.LocationsNodes[0].children.map(child => child['value']);
     this.formFieldValues['locations'] = selectedValues.filter(value => allValues.includes(value));
    }
   }
// selecionstatus//
   onselectionStatusChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');
    if (checkAllIndex !== -1) {
     const allValues = this.SelectionStatusNodes[0].children.map(child => child['value']);
     this.formFieldValues['selectionStatus'] = [...allValues];
    } else {
     const allValues = this.SelectionStatusNodes[0].children.map(child => child['value']);
     this.formFieldValues['selectionStatus'] = selectedValues.filter(value => allValues.includes(value));
    }
   }
   //
   //
   //
   //




  getStandardFilterByFormPageId(pageId: number) {
    this.lookUpFilterService.getStandardFilterByFormPageId(pageId);
    this.lookUpFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.filterResponseData = result.result;
        this.initializeForm();
        setTimeout(() => {
          let id = this.accessLocalStorageService.getSelectedFilterId();
          this?.setDefaultFieldValues(id);
        }, 100);

      }

    });
  }

  onFilterApply(): void {
    let selectedFilter = this.formFieldValues['standardFilter'];
    let isSelectedFilterStandard = this.filterResponseData.find(x => x.id === selectedFilter && x.isNotDeleted === true);
    if (isSelectedFilterStandard && isSelectedFilterStandard != undefined) {
      this.selectedFilter = 1;
    }
    else {
      this.selectedFilter = 0;
    }
    this.createFormFieldsInArray();
    this.createLookupFilterRequests[0].page = 1;
    this.createLookupFilterRequests[0].pageSize = 10;

    this.selectionService.getAppliedFilterData(this.createLookupFilterRequests);


  }

  resetFilter(): void {
    Object.keys(this.formFieldValues).forEach(field => {
      if (field !== 'standardFilter') {
        if (Array.isArray(this.formFieldValues[field])) {
          this.formFieldValues[field] = [];
        } else {
          this.formFieldValues[field] = '';
        }
      }
    });
    this.selectedFilter = -1;
  }

  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
    this.isFormDirty = false;
  }

  createFormFieldsInArray() {
    this.createLookupFilterRequests = [];
    Object.keys(this.preDefinedDataCodes)?.forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const value = this.formFieldValues[field];
      if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 0)) {
        const newRow = {
          globalId: '00000000-0000-0000-0000-000000000000',
          companyParameterId: 0,
          lookUpStandardId: 0,
          code: code,
          meaning: '',
          valueId: 0,
          formNameId: this.pageId,
          dataTypeId: 0,
          jobInformationId: this.selectedJobId,
          createLookupFilterParameterRequests: [null]
        };

        const correspondingResponse = this.lookUpStandardResponse.find(response => response.code === code);
        if (correspondingResponse) {
          newRow.companyParameterId = correspondingResponse.companyParameterId;
          newRow.lookUpStandardId = correspondingResponse.id;
          newRow.dataTypeId = correspondingResponse.dataTypeId;

          if (
            correspondingResponse.dataType.code === FieldDataType.SingleLineText ||
            correspondingResponse.dataType.name === 'Single-Line Text') {
            newRow.meaning = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.Dropdown ||
            correspondingResponse.dataType.name === 'Dropdown') {
            newRow.valueId = value;
          }
          if (
            correspondingResponse.dataType.code === FieldDataType.MultiSelectDropdown ||
            correspondingResponse.dataType.name === 'Multi-Select Dropdown'
          ) {
            newRow.createLookupFilterParameterRequests = value?.map((item, index) => {
              return { valueId: item };
            });
          }
        }
        this.createLookupFilterRequests.push(newRow);
      }
    });
  }

  getDefaultFilterValue() {
   // this.defaultStandardFilter = this.filterResponseData.find(filter => filter.isSetAsDefault === true);
   // return this.defaultStandardFilter ? this.defaultStandardFilter.id : null;
    return this.accessLocalStorageService.getSelectedFilterId();
  }

  setDefaultFieldValues(standardFilterId: number): void {
    this.predefinedValuesMultiSelect = [];
    let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
    let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;

    Object.keys(this.formFieldValues).forEach(field => {
      if (field !== 'standardFilter') {
        if (Array.isArray(this.formFieldValues[field])) {
          this.formFieldValues[field] = [];
        } else {
          this.formFieldValues[field] = '';
        }
      }
    });


    if (lookUpFiltersField.length > 0) {
      Object.keys(this.preDefinedDataCodes).forEach(field => {
        const code = this.preDefinedDataCodes[field].code;
        const fieldData = lookUpFiltersField.find(filter => filter.code === code);
        if (fieldData != undefined) {
          if (
            fieldData.dataType.code === FieldDataType.SingleLineText) {
            this.formFieldValues[field] = fieldData.meaning;
          }
          if (fieldData.dataType.code === FieldDataType.Dropdown) {
            this.formFieldValues[field] = fieldData.valueId.toString();
          }
          if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown) {
            let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
            this.predefinedValuesMultiSelect = multiSelectValues.map(String);
            this.formFieldValues[field] = this.predefinedValuesMultiSelect;
          }
        }
      });
    } else {
      console.log("lookUpFiltersField is null ", lookUpFiltersField);
    }
  }
  onChangeStandardFilter($event: number): void {
    this.selectedFilter = -1;
    const defaultFIlterId = this.getDefaultFilterValue();
    this.accessLocalStorageService.setSelectedFilterId($event);
    // New Changes By Aamir Ali - 22-Apr2024
    let id = this.accessLocalStorageService.getSelectedFilterId();
    this.setDefaultFieldValues(id);
    this.selectionService.CradViewedsetSelectedFilterId(id);
    this.filterSearchParameter = {
      CompanyParameterId: 1,
      FormNameId: this.pageId,
      LookUpFilterId: $event,
      page: 1,
      pageSize: 10,
      jobInformationId: this.selectedJobId,
    };
    if (defaultFIlterId !== $event) {
      this.selectionService.CradVieweduserAppliedFilter(true);
    } else {
      this.selectionService.CradVieweduserAppliedFilter(false);
    }
    this.selectionService.getAllselectUsersByFilter(this.filterSearchParameter);

  }

  private initEditSaveFilter(): void {
    this.editSaveFilterFormGroup = this.fb.group({
      globalId: ['00000000-0000-0000-0000-000000000000'],
      companyParameterId: [0],
      code: ['', Validators.required],
      name: ['', Validators.required],
      description: [''],
      meaning: [''],
      formNameId: [0],
      dataTypeId: [],
      isShareThisFilter: [true],
      isSetAsDefault: [false],
      createLookupFilterRequests: [[]],
    });
  }

  editSaveFilterData(): void {
    let selected_Filter_Id = this.formFieldValues['standardFilter'];
    let getSelectedFilterValue = this.filterResponseData.find(filter => filter.id === selected_Filter_Id);
    this.createFormFieldsInArray();
    this.editSaveFilterFormGroup.controls['name'].setValue(getSelectedFilterValue.name);

    if (this.editSaveFilterFormGroup.get('name')?.invalid) {
      this.toastService.error('Name field is required');
      return;
    }
    this.editSaveFilterFormGroup.controls['companyParameterId'].setValue(getSelectedFilterValue.companyParameterId);
    this.editSaveFilterFormGroup.controls['code'].setValue(getSelectedFilterValue.code);
    this.editSaveFilterFormGroup.controls['formNameId'].setValue(getSelectedFilterValue.formNameId);
    this.editSaveFilterFormGroup.controls['dataTypeId'].setValue(getSelectedFilterValue.dataTypeId);
    this.editSaveFilterFormGroup.controls['globalId'].setValue(getSelectedFilterValue.globalId);
    this.editSaveFilterFormGroup.controls['createLookupFilterRequests'].setValue(this.createLookupFilterRequests);
    this.editSaveFilterFormGroup.controls['isSetAsDefault'].setValue(getSelectedFilterValue.isSetAsDefault);
    this.lookUpFilterService.saveStandardFilterData(this.editSaveFilterFormGroup.value)
      .subscribe(
        (res: any) => {
          console.log(res.result);
          setTimeout(() => {
            this.loadingIndicatorService.hide();
            this.toastService.success('Filter saved successfully');
            this.getStandardFilterByFormPageId(this.pageId);
          }, 2000);
        },
        (error) => {
          console.error('Error:', error);
          this.loadingIndicatorService.hide();
          if (error.status === 500) {
            this.toastService.error('Internal server error. Please try again later.');
          } else {
            this.toastService.error('An error occurred. Please try again.');
          }
        }
      );
  }
  /* _____________________________________ START DIFFERENCE _________________________________________________ */
  initializeForm(): void {
    this.formFieldValues = {
      standardFilter: this.getDefaultFilterValue(),
      title: '',
      selectionStatus: [] = [],
      locations: [] = [],
      categories: [] = [],
      subvendor: [] = [],
      deadline: '',
    };
  }
  /* ____________________________________ END DIFFERENCE _____________________________________________________ */

}
