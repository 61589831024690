
<nz-modal nzWidth="60%" [(nzVisible)]="selectionModelhandle" [nzTitle]="Cost12" [nzFooter]="surveys2"
  (nzOnCancel)="selectionModelCancel()"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '75vh', 'overflow-y': 'auto'}"
  [nzStyle]="{ top: '18px' }">

  <ng-template #Cost12>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0 fw-small">Add Form Other Selection</h2>
    </div>
  </ng-template>
  <div class="cantent" *nzModalContent>


<nz-card nzTitle="Copy Selection Choices To Feature Tiles">
    <div class="row">
        <span><b>Step 1:</b>Choose which job or template you would like to copy selection Choices from.</span>
    </div>

    <div class="row">
        <div class="col">
            <label class="cityDropdown">Job/Template</label>
            <span class="text-danger fw-bold">*</span>
            <nz-select [(ngModel)]="singleValue" [nzSize]="size">
                <nz-option *ngFor="let option of listOfOption" [nzLabel]="option.label"
                    [nzValue]="option.value"></nz-option>
            </nz-select>
        </div>
    </div>
    <div class="row">
        <div class="col mt-4">
            <nz-divider></nz-divider>
        </div>
    </div>

    <div class="row">
        <span><b>Step 2:</b>Choose which job or template you would like to copy Choices from.</span>
    </div>

    <div class="row">
        <div class="col">
            <label class="cityDropdown">Selection</label>
            <span class="text-danger fw-bold">*</span>
            <nz-select [(ngModel)]="singleValue" [nzSize]="size" nz-placeholder="Double Storey Dwelling-2023">
                <nz-option *ngFor="let option of listOfOption" [nzLabel]="option.label"
                    [nzValue]="option.value"></nz-option>
            </nz-select>
        </div>
    </div>

    <div class="row">
        <div class="col mt-4">
            <nz-divider></nz-divider>
        </div>
    </div>


    <div class="row mt-2">
        <span><b>Step 3:</b>Choose which job or template you would like to copy Features Tiles.</span>
    </div>

    <div class="row">
        <div class="col">
            <label class="cityDropdown">Choices</label>
        </div>
    </div>

    <div class="row ms-4 mt-3">
        <label nz-checkbox></label>
    </div>


    <div class="row mt-3 ">
        <div class="col">
            <nz-card>
                <div class="row">
                    <div class="col-1 mt-4 ">
                        <label nz-checkbox></label>
                    </div>

                    <div class="col-4">
                        <img src="https://i.ibb.co/F8NmNPd/Capture.png" alt="" style="height: 65px; width: auto;">
                    </div>

                    <div class="col-3"></div>

                    <div class="col-4 mt-4">
                        <span>$0.00</span>
                    </div>
                </div>

            </nz-card>
        </div>
    </div>

</nz-card>

</div>
<ng-template #surveys2>
    </ng-template>