


  <nz-table class="table-hover" #expandTable [nzData]="listOfMapData" nzTableLayout="fixed" nzFrontPagination="false">
    <thead>
        <tr>
          <th [colSpan]="6" class="text-start"></th>
          <th class="text-start d-flex align-items-center">
            <span class="bi bi-envelope-check me-1"></span>
            <span > Email</span>
          </th>
         
           <th  class="text-start align-items-center ">
            <span  class="bi bi-chat-dots"></span>
            <span > Text</span>
            </th> 
            
          <th  class="text-start align-items-center">
            <span  class="bi bi-tablet"></span>
             Push
             <span  class="bi bi-info-circle-fill text-secondary" ></span>
            </th> 
        </tr> 
  
</thead>
<tbody>
    
    <ng-container *ngFor="let data of expandTable.data">
      <ng-container *ngFor="let item of mapOfExpandedData[data.key]">
        <tr *ngIf="(item.parent && item.parent.expand) || !item.parent">
          <td class="text-start"
              [colSpan]="6"
              [nzIndentSize]="item.level! * 20"
              [nzShowExpand]="!!item.children"
              [(nzExpand)]="item.expand"
              (nzExpandChange)="collapse(mapOfExpandedData[data.key], item, $event)"
              [nzCellControl]="'true'"
          >
            {{ item.name }}
          </td>
          <td class="text-center"><label nz-checkbox ></label></td>
          <td class="text-center"><label nz-checkbox ></label></td>
          <td class="text-center"><label nz-checkbox ></label></td>
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
  </nz-table>



  