import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { ProjectManegmentScheduleItemDetailsPreDefinedCodes, ProjectManegmentToDosPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { Customerres } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { ApplicationUserForComboResponse } from 'src/Models/InternalUser/InternalUserJob';
import { LookupNameSetupResponse, ScheduleItemParameterResponse } from 'src/Models/LeadTags';
import { scheduleItemByJob } from 'src/Models/Project-Management/projectManagement';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { ScheduleService } from 'src/Service/Project-Management/schedule.service';
import { AuditDetailsResponse } from 'src/Models/Footer/footer.model';
import { NzTSType } from 'ng-zorro-antd/core/types/template';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { forkJoin, map } from 'rxjs';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { AttachmentResponse, CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-project-management-site-diaries-related-to-do-st',
  templateUrl: './project-management-site-diaries-related-to-do-st.component.html',
  styleUrls: ['./project-management-site-diaries-related-to-do-st.component.css']
})
export class ProjectManagementSiteDiariesRelatedToDoStComponent implements OnInit {
onCheckboxChange() {
throw new Error('Method not implemented.');
}
  @Input() visible: boolean = false; // Receive visibility status from parent
  @Output() closeDrawer = new EventEmitter<void>(); // Emit event when drawer is closed
  @Output() cancel = new EventEmitter<void>();
  combinedNodes: NzTreeNodeOptions[] = [];
  tagsNodes: NzTreeNodeOptions[] = [];
  priorityOption: LookupNameSetupResponse[] = [];
  reminderOption: LookupNameSetupResponse[] = [];
  scheduleItemOption: scheduleItemByJob[] = [];
  scheduleItemOptions: ScheduleItemParameterResponse[] = [];
  checklistItems: Array<any> = [];
  // internalRes: ApplicationUserResponse[] = [];
  subVenRes: SubVendorResponse[] = [];
  todoForm: FormGroup;
  checklistForm: FormGroup;
  PAGE_id: number = 6;
  test: any;
  selectedUsers: any[] = [];
  initials: string = '';
  showPriorityField: boolean = false;
  showReminderField: boolean = false;
  showTagsField: boolean = false;
  isLinkToAdd: boolean = true;
  timeOptions: string[] = [
    '08:00 am',
    '08:30 am',
    '09:00 am',
    '09:30 am',
    '10:00 am',
    '10:30 am',
    '11:00 am',
    '11:30 am',
    '12:00 am',
    '12:30 am',
    '08:00 pm',
    '08:30 pm',
    '09:00 pm',
    '09:30 pm',
    '10:00 pm',
    '10:30 pm',
    '11:00 pm',
    '11:30 pm',
    '12:00 pm',
    '12:30 pm'
  ];
  isComplete: any;
  contentTemplate: NzTSType;
  column: any;
  showAttachment: boolean = false;
  selectedFilesAttachment: CustomUploadFile[] = [];
  TestAttachment: AttachmentResponse;
  localStorageService: any;
  internalRes: any;
  InterUserId: any;
  SubVenId: any;


  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public localStorage: AccessLocalStorageService,
    private modal: NzModalService,
    private customerInformationService: CustomerInformationService,
    private SubVendorService: SubVendorService,
    private LookupFilterService: LookupFilterService,
    private leadOppService: LeadOpportunitiesService,
    private scheduleService: ScheduleService,
    private fb: FormBuilder,
    private internalUserService: InternalUserService,
    private _AttachmentService: AttachmentService,
    private subVendorService: SubVendorService,

  ) { }

  preDefinedDataCodes = {
    Tags: ProjectManegmentToDosPreDefinedCodes.Tags,
    Priority: ProjectManegmentToDosPreDefinedCodes.Priority,
    Reminder: ProjectManegmentScheduleItemDetailsPreDefinedCodes.Reminder
  }


  ngOnInit(): void {
    this.leadOppService.getAllDataScheduleItems().subscribe(res => {
      this.scheduleItemOptions = res.result;
      console.log('show all data',res.result);
      
    });
    this.getScheduleItem();
    this.ForCombo();
    this.inItLoad();
    this.onAssigneeChange(this.test);
    this.onGetAllAssigneeUser();
  }

  onAssigneeChange(selectedValue: any) {
    if (!selectedValue) {
      console.error('Selected value is undefined or null');
      return;
    }
    // When selected value is 'me'
    if (selectedValue === 'me') {
      const userId = this.localStorageService.getUserLoginId();
      this.test = userId;
      const user = this.internalRes?.find(u => u.id === userId);

      if (user) {
        this.InterUserId = user.id;
        this.onShortName(user.id);
      } else {
        console.log('User not found in internal users.');
      }
    } else {
      // Ensure selectedValue.id exists before accessing it
      const id = selectedValue.id || selectedValue;
      if (!id) {
        console.error('Selected value does not have a valid ID:', selectedValue);
        return;
      }
      this.onShortName(id);
    }

    // Validate selectedValue.groupName and selectedValue.id
    if (selectedValue?.groupName === 'Internal Users' && selectedValue?.id) {
      this.InterUserId = selectedValue.id;
    } else if (selectedValue?.groupName === 'Sub Vendors' && selectedValue?.id) {
      this.SubVenId = selectedValue.id;
    } else {
      console.error('Selected value is missing groupName or id:', selectedValue);
    }
  }

  onGetAllAssigneeUser() {
    const internalUser$ = this.internalUserService.getData().pipe(map(res => res.result));
    const subVendor$ = this.subVendorService.getData().pipe(map(res => res.result));

    forkJoin([internalUser$, subVendor$]).subscribe(
      ([internalRes, subVenRes]) => {
        this.internalRes = internalRes.filter(ii => ii?.isActive === true);
        this.subVenRes = subVenRes.filter(ii => ii?.isActive === true);
        this.selectedUsers = [
          {
            groupName: 'Internal Users',
            users: this.internalRes.map(user => ({
              id: user?.id,
              name: user?.fullName,
            }))
          },
          {
            groupName: 'Sub Vendors',
            users: this.subVenRes.map(vendor => ({
              id: vendor?.id,
              name: vendor?.companyName,
            }))
          }
        ];
      },
      (error) => {
        console.error('Error fetching users or vendors:', error);
      }
    );
  }

  onShortName(selectedValue: any) {
    if (selectedValue) {
      const userIdAndNames = this.selectedUsers?.flatMap(group =>
        group.users.map(user => ({
          id: user.id,
          name: user.name
        }))
      );

      const data = userIdAndNames?.find(user => user.id === selectedValue);
      if (data) {
        this.initials = this.getInitials(data.name);
      }
    }
  }

  getInitials(fullName: string | undefined): string {
    if (!fullName) return '';
    const nameParts = fullName.split(' ');
    const firstInitial = nameParts[0]?.charAt(0) || '';
    const lastInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1]?.charAt(0) || '' : '';
    return (firstInitial + lastInitial).toUpperCase();
  }

  initializeForm() {
    this.todoForm = this.fb.group({
      // formNameId: [0],
      // jobInformationId: [0],
      // companyParameterId: [1],
      // createToDoParameterRequest: this.fb.group({
      globalId: ['00000000-0000-0000-0000-000000000000'],
      jobInformationId: [0],
      isCompleted: [false],
      completedBy: [1],
      title: ['', Validators.required, Validators.maxLength(75)],
      notes: ['', Validators.maxLength(2000)],
      isChooseDate: [false],
      isLinkedScheduleItem: [false],
      scheduleItemParameterId: '',
      dueNumberDays: [null],
      before: [false],
      after: [false],
      dueDate: [null],
      time: [null],
      prioritySetupId: [0],
      reminderSetupId: [0],
      attachmentId: [null],
      createToDoParameterAssignOwnersRequests: this.fb.array([]),
      createToDoInformationTagParameterRequests: this.fb.array([])
    })
    // });
  }

  inItLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.PAGE_id).subscribe(res => {
      let tagOptions = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.Tags.code
      );
      let PriorityOptionValues = res.result.filter((x) => x.code === this.preDefinedDataCodes.Priority.code);
      this.priorityOption = PriorityOptionValues;


      this.scheduleService.getAllPredecessorsDataScheduleItem(789012).subscribe(res => {
        this.scheduleItemOption = res.result
      })
      this.tagsNodes = tagOptions.map((data) => ({
        title: data.name,
        value: data.id.toString(),
        key: data.id.toString(),
        isLeaf: true,
      }));
    })

    this.LookupFilterService.getLookUpValuesByFormNameId(8).subscribe(res => {
      let reminderOptionValues = res.result.filter((x) => x.code === this.preDefinedDataCodes.Reminder.code);
      this.reminderOption = reminderOptionValues;
    })
  }



  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe(
      (response: ResponseModelArray<ApplicationUserForComboResponse>) => {

        this.SubVendorService.getData().subscribe(
          (res: ResponseModelArray<SubVendorResponse>) => {

            this.customerInformationService.customercombo().subscribe(
              (responses: ResponseModelArray<Customerres>) => {

                this.combinedNodes = [
                  {
                    title: 'Select All',
                    value: 'select_all',
                    key: 'select_all',
                    selectable: false,
                    isLeaf: false,
                    expanded: true,
                    children: [
                      {
                        title: 'Internal Users',
                        value: 'internal_users',
                        key: 'internal_users',
                        selectable: false,
                        isLeaf: false,
                        expanded: true,
                        children: response.result.map((status) => ({
                          title: status.fullName,
                          value: status.id.toString(),
                          key: status.id.toString(),
                          isLeaf: true
                        }))
                      },
                      {
                        title: 'Sub Vendors',
                        value: 'sub_vendors',
                        key: 'sub_vendors',
                        selectable: false,
                        isLeaf: false,
                        expanded: true,
                        children: res.result.map((status) => ({
                          title: status.companyName,
                          value: status.id.toString(),
                          key: status.id.toString(),
                          isLeaf: true
                        }))
                      },
                      {
                        title: 'Customer Info',
                        value: 'Customer_info',
                        key: 'Customer_info',
                        selectable: false,
                        isLeaf: false,
                        expanded: true,
                        children: responses.result.map((status) => ({
                          title: status.displayName,
                          value: status.id.toString(),
                          key: status.id.toString(),
                          isLeaf: true
                        }))
                      }
                    ]
                  }
                ];
              },
              (error) => {
                console.error('Error fetching sub-vendor data:', error);
              }
            );
          },
          (error) => {
            console.error('Error fetching customer data:', error);
          }
        );
      })
  }
  viewTabs = [1, 2, 3,];
  tabs = [
    { id: 1, title: 'To-Do Information', show: true },
    { id: 2, title: 'Checklist', show: true },
    { id: 3, title: 'Related Items', show: true },

  ];

  footerResponse: AuditDetailsResponse;
  toggleDateFields() {
    this.isLinkToAdd = true; // Set to false when choosing date
  }
  // toggleScheduleItemFields() {

  // }
  toggleScheduleItemFields() {
    this.isLinkToAdd = false; // Set to true when linking to add

  }


  togglePriorityField() {
    this.showPriorityField = !this.showPriorityField;
  }

  toggleTagsField() {
    this.showTagsField = !this.showTagsField;
  }

  toggleReminderField() {
    this.showReminderField = !this.showReminderField;
  }

  toggleAttachment() {
    this.showAttachment = !this.showAttachment;
  }




  isCompleteAllClicked: boolean = false;
  completeAll() {
    console.log('Completing all checkboxes');
    // Hide the "Complete All" button
    this.isCompleteAllClicked = true;

    // Force change detection if necessary
    // this.changeDetectorRef.detectChanges();
  }




  onFilesUploaded(files: CustomUploadFile[]): void {
    this.selectedFilesAttachment = files
    if (files.length > 0) {
      this.isAttachmentAdded = true; // Set to true when files are uploaded
    } else {
      this.isAttachmentAdded = false; // Set to false if no files are uploaded
    }
  }


  fetchAttachments(ChangeOrderInfoResponse: any) {
    if (ChangeOrderInfoResponse?.AttachmentId) {
      this._AttachmentService.getLeadByAttachmentId(ChangeOrderInfoResponse?.AttachmentId).subscribe(
        (res) => {
          this.TestAttachment = res.result
        },
      );
    } else {
      this.TestAttachment = undefined;
    }
  }

  // add assigner and attachment and delete method start//////

  isItemClicked = false;
  isAssigneeAdded: boolean = false;
  isAttachmentAdded: boolean = false;

  addChecklistItem() {
    const newItem = {
      text: '', // Default text for input field
      assignee: null, // Assignee selection
      isChecked: false, // For checkbox state
      isAssigneeAdded: false, // Control assignee field display
    };
    this.checklistItems.push(newItem); // Add new item to the array


    console.log('Check List Row --',this.checklistItems);
    
  }
  toggleCheckbox(item: any) {
    item.isChecked = !item.isChecked; // Toggle checkbox state
  }


  onItemClick() {
    this.isAssigneeAdded = !this.isAssigneeAdded;
  }

  onAttachClick() {
    this.isAttachmentAdded = !this.isAttachmentAdded;
  }

  removeAttachments(): void {
    this.isAttachmentAdded = false;
  }

  
  dueDays: number = 0;     // Default days value
  dueOption: string = 'before';  // Default option for radio group






  close(): void {
    this.visible = false;
    this.closeDrawer.emit(); 
  }


  isDeleteLoading: boolean = false; // Step 1: Add loading flag

  deleteTodo() {
    // const globalId = this.scheduleForm.get('globalId').value;
    // this.isDeleteLoading = true; // Start loading

    // this._scheduleService.deleteSchedule(globalId).subscribe(
    //   res => {
    //     this._toastService.success('Deleted Successfully');
    //     this.isDeleteLoading = false; // End loading
    //     // You may want to perform additional actions after deletion, e.g., refresh the list
    //   },
    //   err => {
    //     this._toastService.error('Deletion failed'); // Handle error appropriately
    //     this.isDeleteLoading = false; // End loading
    //   }
    // );
  }

  showConfirm(): void {
    // this.confirmModal = this.modal.confirm({
    //   nzTitle: 'Delete Schedule Item?',
    //   nzContent: 'Are you sure you want to permanently delete this Schedule Item?',
    //   nzOkDanger: true,
    //   nzOkText: 'Delete',
    //   nzOkLoading: this.isDeleteLoading, // Step 2: Set loading flag here
    //   nzCentered: true,
    //   nzAutofocus: 'cancel',
    //   nzOnOk: () =>
    //     new Promise((resolve, reject) => {
    //       this.isDeleteLoading = true; // Show loading spinner
    //       this.deleteSchedule(); // Call deleteSchedule here

    //       setTimeout(() => {
    //         this.isDeleteLoading = false; // Hide loading spinner after delay
    //         this.onCancel();
    //         resolve(); // Resolve the promise to close the modal
    //       }, 1000); // Delay in milliseconds (1 second)
    //     }),
    //   nzOnCancel: () => console.log('Cancel'),
    // });
  }

  
  // checklistItems = [];
  isChecklistAdded : boolean =  false;

  addChecklist() {
    this.isChecklistAdded = true;
    this.checklistItems.push({
      checkbox: false,
      inputValue: '',
      assignee: null,
      isAssigneeAdded: false,
      isAttachmentAdded: false,
      attachment: []
    });
  }

  // Method to handle file uploads for a specific item
  // onFilesUploaded(event, item) {
  //   item.attachment = event;
  // }
  
  confirmModal?: NzModalRef;

  RemoveCheckListConformModal(): void {
    this.confirmModal = this.modal.confirm({
        nzTitle: '<b>Remove CheckList?</b>',
        nzContent: '<p>All checklist items will be removed. The to-do must also be saved in order for this change to be made permanent.</p>',  
        nzOkText: 'Remove',
        nzOkDanger: true,
        nzCancelText: 'Cancel',
        nzCloseIcon:null,
        nzIconType: null, 
        nzOnOk: () => {
            this.checklistItems = [];
            this.confirmModal.destroy();
        },
        nzOnCancel: () => {
            this.confirmModal.destroy();
        },
        nzStyle: { top: '250px' },
        nzClassName: 'custom-modal-content',
         // Add the custom class here
    });
}


getScheduleItem() {
  this.leadOppService.getAllDataScheduleItems().subscribe(res => {
    this.scheduleItemOptions = res.result;
    console.log('show all data',res.result);
    
  });
}
  



}