<nz-modal nzWidth="70%" [(nzVisible)]="SubvendorModal" [nzTitle]="JobCreateNewScratchModalTitle"
  [nzFooter]="jobCreateNewScratchModalfooter" [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '75vh',
    'overflow-y': 'auto'
  }" [nzStyle]="{ top: '15px' }" (nzOnCancel)="CancelJobScartch()">
  <ng-template #JobCreateNewScratchModalTitle>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0 fs-3">Sub Vendor</h2>
    </div>
  </ng-template>
  <div class="content p-3" *nzModalContent>
    <div class="card mb-3 rounded-2 p-3 border-0">
      <div class="row p-1">
        <div class="col-md-12 d-flex align-items-center justify-content-start">
          <div class="me-2">
            <h2 nz-typography>Activation Status:</h2>
          </div>
          <div class="me-0">
            <button button nz-button nzType="text" class="same-but" (click)="InviteSubsVendorOpen()"><span>Invite
                Sub/Vendor</span></button>
          </div>
          <div class="span">
            <button button nz-button nzType="text" class="same-but ms-2"
              (click)="showDisableVendor()"><span>Disable</span></button>
          </div>
        </div>
      </div>
    </div>
    <div class="card border-0 mt-0">
      <div class="card-head border-bottom">
        <div class="row py-0">
          <h2 class="ms-3" nz-typography>Contact Information</h2>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <!-- <div class="col-12">
                <label class="sub-text" for="companyName">Company Name<span
                    class="text-danger fw-bolder">*</span></label>
                <input type="text" [(ngModel)]="formFieldValues['companyName']" name="companyName"
                  class="form-control formField" />
              </div> -->




              <div class="col-12">
                <label class="sub-text" for="companyName">Company Name</label>
                <input
                  type="text"
                  [(ngModel)]="formFieldValues['companyName']"
                  name="state"
                  #stateInput="ngModel"
                  class="form-control formField"
                  [ngClass]="{'border-danger': formFieldValues['companyName']?.length > 50}"
                  required
                  pattern="^.{50}$"
                />
              
                <div *ngIf="formFieldValues['companyName']?.length > 50" class="text-danger">
                  {{ formFieldValues['companyName'].length - 50 }} characters over.
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-12">
                <label class="sub-text" for="primaryContactFirstLastName">Primary Contact (First & Last Name)</label>
                <input type="text" [(ngModel)]="formFieldValues['primaryContactFirstLastName']"
                  name="primaryContactFirstLastName" class="form-control formField" [ngClass]="{'border-danger': formFieldValues['primaryContactFirstLastName']?.length > 30}"
                  required
                  pattern="^.{30}$" />
              </div>
            
              <div *ngIf="formFieldValues['primaryContactFirstLastName']?.length > 30" class="text-danger">
                {{ formFieldValues['primaryContactFirstLastName'].length - 30 }} characters over.
              </div>
            </div>



         


            <div class="row">
              <div class="col-12">
                <label class="sub-text" for="streetAddress">Street Address</label>
                <input type="text" [(ngModel)]="formFieldValues['streetAddress']" name="streetAddress"
                  class="form-control formField" 
                  [ngClass]="{'border-danger': formFieldValues['streetAddress']?.length > 50}"
                  required
                  pattern="^.{50}$"
                />
           
                <div *ngIf="formFieldValues['streetAddress']?.length > 50" class="text-danger">
                  {{ formFieldValues['streetAddress'].length - 50 }} characters over.
                </div> 
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label class="sub-text" for="suburb">Suburb</label>
                <input type="text" [(ngModel)]="formFieldValues['suburb']" name="suburb"
                  class="form-control formField"
                  [ngClass]="{'border-danger': formFieldValues['suburb']?.length > 50}"
                  required
                  pattern="^.{50}$"
                />
              
                <div *ngIf="formFieldValues['suburb']?.length > 50" class="text-danger">
                  {{ formFieldValues['suburb'].length - 50 }} characters over.
                </div> 
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label class="sub-text" for="state">State</label>
                <input
                  type="text"
                  [(ngModel)]="formFieldValues['state']"
                  name="state"
                  #stateInput="ngModel"
                  class="form-control formField"
                  [ngClass]="{'border-danger': formFieldValues['state']?.length > 3}"
                  required
                  pattern="^.{3}$"
                />
            
                <div *ngIf="formFieldValues['state']?.length > 3" class="text-danger">
                  {{ formFieldValues['state'].length - 3 }} characters over.
                </div>
              </div>
              
              
              
              
              <div class="col-6">
                <label class="sub-text" for="postalCode"> Postal Code</label>
                <input type="text" [(ngModel)]="formFieldValues['postalCode']" name="postalCode"
                  class="form-control formField"
                  
                  [ngClass]="{'border-danger': formFieldValues['postalCode']?.length > 15}"
                  required
                  pattern="^.{15}$"
                />
              
                <div *ngIf="formFieldValues['postalCode']?.length > 15" class="text-danger">
                  {{ formFieldValues['postalCode'].length - 15 }} characters over.
                </div> 
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-10">
                <label class="sub-text">Division/Trade</label>
              </div>
              <div class="col-1"></div>
              <div class="col-1"></div>
            </div>
            <div class="row gx-1">
              <div class="col-10">
                <nz-tree-select 
                  style="width: 100%" 
                  [(ngModel)]="formFieldValues.createDivisionTradeParameterRequests"
                  name="createDivisionTradeParameterRequests" 
                  [nzVirtualHeight]="'250px'" 
                  [nzNodes]="tagNodes"
                  nzShowSearch 
                  nzAllowClear="false" 
                  nzCheckable 
                  nzCheckAll="true"
                  nzPlaceHolder="Select Status">
                </nz-tree-select>
              </div>
              
              
              <div class="col-1">
                <a nz-button nzType="link" class="p-0 m-0" (click)="showTags()">Add</a>
              </div>
              <div class="col-1">
                <a [disabled]="!formFieldValues['createDivisionTradeParameterRequests'] || formFieldValues['createDivisionTradeParameterRequests'].length !== 1"
                  nz-button nzType="link" class="p-0 m-0"
                  (click)="showTagEdit(formFieldValues['createDivisionTradeParameterRequests'],'createDivisionTradeParameterRequests')">Edit</a>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6">
                <label class="sub-text" for="businessPhone">Business Phone</label>
                <input type="text" [(ngModel)]="formFieldValues['businessPhone']" name="businessPhone"
                  class="form-control formField" 
                  [ngClass]="{'border-danger': formFieldValues['businessPhone']?.length > 25}"
                  required
                  pattern="^.{25}$"
                />
             
                <div *ngIf="formFieldValues['businessPhone']?.length > 25" class="text-danger">
                  {{ formFieldValues['businessPhone'].length - 25 }} characters over.
                </div>
 
              </div>
              <div class="col-6">
                <label class="sub-text" for="fax">Fax</label>
                <input type="text" [(ngModel)]="formFieldValues['fax']" name="fax" class="form-control formField" 
                [ngClass]="{'border-danger': formFieldValues['fax']?.length > 15}"
                required
                pattern="^.{15}$"
              />
             
              <div *ngIf="formFieldValues['fax']?.length > 15" class="text-danger">
                {{ formFieldValues['fax'].length - 15 }} characters over.
              </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label class="sub-text" for="companyName">Cell Phone </label>
                <div class="wrapper">
                  <div class="wrapper">
                    <ngx-intl-tel-input [cssClass]="'custom'"
                    name="cellPhone"
                    [(ngModel)]="formFieldValues.cellPhone"
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="true"
                    [selectedCountryISO]="formFieldValues.countryCode"
                    [phoneValidation]="true"
                    [separateDialCode]="separateDialCode"
                    [numberFormat]="PhoneNumberFormat.National"
                    #phoneInput="ngModel"
                    (ngModelChange)="onPhoneInputChange()">
                  </ngx-intl-tel-input>
                  
                  

                    <div *ngIf="phoneInput.invalid && (phoneInput.dirty || phoneInput.touched)" class="text-danger">
                      Invalid phone number</div>

                  </div>

                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label class="sub-text" for="companyName">Receive Text Messages
                  <i class="bi bi-info-circle-fill text-secondary" nz-popover [nzPopoverContent]="receiveextMessages"
                    nzPopoverPlacement="right"></i>
                  <ng-template #receiveextMessages>
                    <div class="rounded-5" style="width: 150px; font-size: 13px;">
                      <div class="row">
                        <div class="col-12">
                          <p>
                            To receive text messages please review your
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <a nz-button nzType="link" class="p-0 m-0 text-primary"
                            style="text-decoration: underline;">notification preferences.</a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button button nz-button nzType="text" class="same-but"><span>Verify Phone Number</span></button>
              </div>
            </div>
            <div class="row mt-2" *ngIf="validItems.length === 0">
              <div class="col-4">
                <a class="fs-5" (click)="showmanageEmails()" nz-icon nzType="plus-circle" nzTheme="fill"></a>
                <a class="ms-2" (click)="showmanageEmails()" style="bottom: 100;">Add Email</a>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <span *ngFor="let item of validItems" class="ant-tag">
                  {{ item.label && item.label.trim() !== '' ? item.label : item.email }}
                </span>
                
              </div>
            </div>
            <div class="row mt-2" *ngIf="validItems.length > 0">
              <div class="col-6">
                <a class="fs-5" (click)="showmanageEmails()" nz-icon nzType="setting" nzTheme="fill"></a>
                <a class="ms-2" (click)="showmanageEmails()" style="bottom: 100;">Manage Email</a>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="card-container mt-3">
        <nz-tabset nzType="card">
          <nz-tab *ngFor="let tab of newSubVendor" [nzTitle]="newSubVendorTabs(tab)">
            <ng-container *ngIf="tab === 1">
              <div class="card border-0 mt-0">
                <div class="card-head border-bottom">
                  <div class="row py-2">
                    <h2 class="ms-3" nz-typography>Preferences</h2>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <label [(ngModel)]="formFieldValues['viewOwnerInformation']" name="viewOwnerInformation"
                        class="pira-label" nz-checkbox>View owner information</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label [(ngModel)]="formFieldValues['automaticallyPermitAccessToNewJobs']"
                        name="automaticallyPermitAccessToNewJobs" class="pira-label" nz-checkbox>Automatically permit
                        access to new jobs</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label [(ngModel)]="formFieldValues['shareCommentsAndDocumentsWithOwner']"
                        name="shareCommentsAndDocumentsWithOwner" class="pira-label" nz-checkbox>Share comments and
                        documents with owner</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label [(ngModel)]="formFieldValues['assignRFIsToOtherSubsVendors']"
                        name="assignRFIsToOtherSubsVendors" class="pira-label" nz-checkbox>Assign RFIs to other
                        subs/vendors </label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label [(ngModel)]="formFieldValues['notifyMeWhen']" name="notifyMeWhen" class="pira-label"
                        nz-checkbox>Notify me when or more schedule items conflict (minimum 2).</label><nz-input-number
                        [disabled]="!formFieldValues.notifyMeWhen" class="me-1"
                        [(ngModel)]="formFieldValues['notifyMeWhenOrMoreScheduleItemsConflict']"
                        name="notifyMeWhenOrMoreScheduleItemsConflict" class="form-control"></nz-input-number>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label [(ngModel)]="formFieldValues['holdPaymentsToTheSubvVendor']"
                        name="holdPaymentsToTheSubvVendor" class="pira-label" nz-checkbox>Hold payments to the
                        sub/vendor</label>
                    </div>
                  </div>
                  <div class="row ms-3">
                    <div class="col-12">
                      <label class="sub-text" for="companyName">Hold Payments Notes</label>
                      <input type="text" [(ngModel)]="formFieldValues['holdPaymentsNotes']" name="holdPaymentsNotes"
                        [disabled]="!formFieldValues['holdPaymentsToTheSubvVendor']" class="form-control formField" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label class="sub-text" for="companyName">Default payment email address</label>
                      <input type="text" [(ngModel)]="formFieldValues.defaultPaymentEmailAddress"
                        name="defaultPaymentEmailAddress" class="form-control formField" #emailInput="ngModel" required
                        email />
                      <div *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)" class="text-danger">
                        <div *ngIf="emailInput.errors?.['required'] && emailInput.touched">
                          Email is required.
                        </div>
                        <div *ngIf="emailInput.errors?.['email']">
                          Please enter a valid email address.
                        </div>
                      </div>
                    </div>
                  </div>




                </div>
              </div>
              <div class="card border-0 mt-0">
                <div class="card-head border-bottom">
                  <div class="row py-2">
                    <h2 class="ms-3" nz-typography>Certificates</h2>
                  </div>
                </div>
                <!-- <div class="card-body" *ngFor="let certificate of subVendorCertificateSetup; let i=index">
                  <div class="row">
                    <div class="col-12">
                      <h2 nz-typography [(ngModel)]="newvalue.value" name="id_{{i}}">{{ certificate.name }}</h2>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5">
                      <label class="sub-text" for="companyName">File (pdf)</label>
                    </div>  
                    <div class="col-2">
                      <label class="sub-text" for="companyName">Expiration Date</label>
                    </div>
                    <div class="col-3" style="padding-right: 0; margin-right: 0;">
                      <label class="sub-text" for="companyName">Weekly Email Reminder (days)</label>
                    </div>
                    <div class="col-2" style="padding-right: 0; margin-right: 0;">
                      <label class="sub-text" for="companyName">Reminder Limit
                        <i class="bi bi-info-circle-fill text-secondary" nz-popover [nzPopoverContent]="reminderLimit" nzPopoverPlacement="top"></i>
                        <ng-template #reminderLimit>
                          <div class="rounded-2">
                            <p style="width: 210px; font-size: 13px;">
                              The max number of emails that will be sent. Emails will stop after the max has been reached or 1 week after expiration (whichever comes first). Manage certificate reminders in the notifications tab.
                            </p>
                          </div>
                        </ng-template>
                      </label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5">
                      <div class="row">
                        <div class="col-3">
                          <nz-upload   [(ngModel)]="SubVendorCertificate.pdfFileUrl" name="pdfFileUrl" [nzFileList]="fileList" (nzChange)="onFileSelected($event)">
                            <button nz-button nzType="text">
                              <span class="fs-5" nz-icon nzType="upload"></span>
                              <span>Choose File</span>
                            </button>
                          </nz-upload>
                        </div>
                        
                        
                      </div>
                    </div>  
                    <div class="col-2">
                      <nz-date-picker [(ngModel)]="certificate.expirationDate" name="expirationDate_{{i}}" [nzFormat]="'MMM d,YYYY'" nzSuffixIcon="false" [nzPlaceHolder]="' '"></nz-date-picker>
                    </div>
                    <div class="col-3" style="padding-right: 0; margin-right: 0;">
                      <div class="row" style="padding-right: 0; margin-right: 0;">
                        <div class="col-4" style="padding-right: 0; margin-right: 0;">
                          <nz-input-number style="width: 70px" [(ngModel)]="certificate.weeklyEmailReminderDays" name="weeklyEmailReminderDays_{{i}}" [disabled]="!certificate.expirationDate"></nz-input-number>
                        </div>
                        <div class="col-7">
                          <nz-select [style.width.%]="100" [(ngModel)]="certificate.weeklyEmailReminderDaySetupId" name="weeklyEmailReminderDaySetupId_{{i}}" [disabled]="!certificate.expirationDate">
                            <nz-option *ngFor="let option of weeklyEmailReminderOptions" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                          </nz-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-2" style="padding-right: 0; margin-right: 0;">
                      <nz-select [style.width.%]="100" [(ngModel)]="certificate.reminderLimitSetupId" name="reminderLimitSetupId_{{i}}" [disabled]="!certificate.expirationDate">
                        <nz-option *ngFor="let option of reminderLimitSetup" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                      </nz-select>
                    </div>
                  </div>
                </div> -->

                <div class="card-body" *ngFor="let certificate of subVendorCertificateSetup; let i=index">
                  <div class="row">
                    <div class="col-12">
                      <h2 nz-typography>{{ certificate.name }}</h2>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5">
                      <label class="sub-text" for="pdfFile">File (pdf)</label>
                    </div>
                    <div class="col-2">
                      <label class="sub-text" for="expirationDate">Expiration Date</label>
                    </div>
                    <div class="col-3">
                      <label class="sub-text" for="weeklyEmailReminderDays">Weekly Email Reminder (days)</label>
                    </div>
                    <div class="col-2">
                      <label class="sub-text" for="reminderLimit">Reminder Limit
                        <i class="bi bi-info-circle-fill text-secondary" nz-popover [nzPopoverContent]="reminderLimit"
                          nzPopoverPlacement="top"></i>
                        <ng-template #reminderLimit>
                          <div class="rounded-2">
                            <p style="width: 210px; font-size: 13px;">
                              The max number of emails that will be sent. Emails will stop after the max has been
                              reached or 1 week after expiration (whichever comes first). Manage certificate reminders
                              in the notifications tab.
                            </p>
                          </div>
                        </ng-template>
                      </label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5">
                      <div class="row">
                        <div class="col-3">

                          <nz-upload name="pdfFileUrl" [nzFileList]="fileList"
                            (nzChange)="onFileSelected($event, certificate)">
                            <button nz-button nzType="text">
                              <span class="fs-5" nz-icon nzType="upload"></span>
                              <span>Choose File</span>
                            </button>
                          </nz-upload>


                        </div>
                      </div>
                    </div>
                    <div class="col-2">
                      <nz-date-picker [(ngModel)]="certificate.expirationDate" name="expirationDate_{{i}}"
                        [nzFormat]="'MMM d,YYYY'" nzSuffixIcon="false" [nzPlaceHolder]="' '"></nz-date-picker>
                    </div>
                    <div class="col-3">
                      <div class="row">
                        <div class="col-4">
                          <nz-input-number style="width: 70px" [(ngModel)]="certificate.weeklyEmailReminderDays"
                            name="weeklyEmailReminderDays_{{i}}"
                            [disabled]="!certificate.expirationDate"></nz-input-number>
                        </div>
                        <div class="col-7">
                          <nz-select [style.width.%]="100" [(ngModel)]="certificate.weeklyEmailReminderDaySetupId"
                            name="weeklyEmailReminderDaySetupId_{{i}}" [disabled]="!certificate.expirationDate">
                            <nz-option *ngFor="let option of weeklyEmailReminderOptions" [nzLabel]="option.name"
                              [nzValue]="option.id"></nz-option>
                          </nz-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-2">
                      <nz-select [style.width.%]="100" [(ngModel)]="certificate.reminderLimitSetupId"
                        name="reminderLimitSetupId_{{i}}" [disabled]="!certificate.expirationDate">
                        <nz-option *ngFor="let option of reminderLimitSetup" [nzLabel]="option.name"
                          [nzValue]="option.id"></nz-option>
                      </nz-select>
                    </div>
                  </div>
                </div>


              </div>
              <div class="card border-0 mt-0">
                <div class="card-head border-bottom">
                  <div class="row py-2">
                    <h2 class="ms-3" nz-typography>Notes</h2>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <textarea rows="6" [(ngModel)]="formFieldValues['notes']" name="notes" style="border-radius: 5px;"
                        class="formField" nz-input></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="tab === 2">
              <div class="card border-0 m-0 p-0 rounded-0">
                <div class="card-head border-bottom">
                  <div class="row py-2">
                    <h2 class="ms-3" nz-typography>Notifications</h2>
                  </div>
                </div>
                <div class="card-body">
                  <notification></notification>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="tab === 3">
              <div class="card border-0 m-0 p-0 rounded-0">
                <div class="card-head border-bottom">
                  <div class="row py-2">
                    <h2 class="ms-3" nz-typography>Job Access</h2>
                  </div>
                </div>
                <div class="card-body">
                  <job-access></job-access>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="tab === 4">
              <div class="card border-0 m-0 p-0 rounded-0">
                <div class="card-head border-bottom">
                  <div class="row py-2">
                    <h2 class="ms-3" nz-typography>Accounting</h2>
                  </div>
                </div>
                <div class="card-body">
                  <app-sub-vendor-accounting></app-sub-vendor-accounting>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="tab === 5">
              <div class="card border-0 m-0 p-0 rounded-0">
                <div class="card-head border-bottom">
                  <div class="row py-2">
                    <h2 class="ms-3" nz-typography>Trade Agreement Contract</h2>
                  </div>
                </div>
                <div class="card-body">
                  <app-sub-vendor-trade-agreement></app-sub-vendor-trade-agreement>
                </div>
              </div>
            </ng-container>

          </nz-tab>
        </nz-tabset>
      </div>
    </div>
  </div>
    <ng-template #jobCreateNewScratchModalfooter>
      <div class="mt-2 align-items-center">
        <button
          nz-button
          nzType="default"
          class="rounded-1 me-0"
          *ngIf="subVendorResponse !== undefined"
          [nzLoading]="isDeleteLoading"
          (click)="deleteSubConfirm()"
        >
          Delete
        </button>
        <button
        nz-button
        nzType="primary"
        class="rounded-1 align-items-center"
        [disabled]="!formFieldValues.companyName"
        [nzLoading]="isSaveAndNew"
        (click)="saveSubVendorAndNew()"
      >
        Save & New
      </button>
      <button
        nz-button
        nzType="primary"
        class="rounded-1 align-items-center"
        [nzLoading]="isSave"
        [disabled]="!formFieldValues.companyName"
        (click)="saveSubVendor()"
      >
        Save
      </button>
      <button
        nz-button
        nzType="primary"
        class="rounded-1 align-items-center"
        [nzLoading]="isSaveAndClose"
        [disabled]="!formFieldValues.companyName"
        (click)="saveSubVendorAndClose()"
      >
        Save & Close
      </button>
  
      </div>
      <!-- <nz-space>

        <button nz-button nzType="default" class="rounded-1 me-0" *ngIf="subVendorResponse !== undefined"
          (click)="ConfirmDelete(subVendorResponse.globalId)">
          Delete
        </button>
        <button  nz-button nzType="primary" class="rounded-1 me-0" (click)="SaveEndNew()"><span>Save & New</span></button>
        <button *ngIf="subVendorResponse === undefined" nz-button nzType="primary" class="rounded-1 me-0"
          [disabled]="!isSaveEnabled()" (click)="saveInternalUser()">Save</button>
        <button *ngIf="subVendorResponse !== undefined" nz-button nzType="primary" class="rounded-1 me-0"
          (click)="EditSubVendors()">Save</button>
        <button *ngIf="!subVButton"  nz-button nzType="primary" class="rounded-1 me-0"
          (click)="SaveEndClose()"><span>Save & Close</span></button>
      </nz-space> -->
    </ng-template>



  <app-loading-indicator></app-loading-indicator>
</nz-modal>


<nz-modal nzWidth="30%" [(nzVisible)]="manageEmailsVisible" [nzTitle]="manageEmailsTitle"
  [nzFooter]="manageEmailsFooter"
  [nzBodyStyle]="{background: '#f1f4fa',padding: '14px','max-height': '75vh','overflow-x': 'hidden'}"
  [nzStyle]="{ top: '20px' }" (nzOnCancel)="manageEmailsModelcancel()">
  <ng-template #manageEmailsTitle>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0">Manage Emails</h1>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <div class="card border-0 mt-0">
      <div class="card-head border-bottom">
        <div class="row">
          <h2 class="ms-3" nz-typography>Manage Emails</h2>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="row" *ngIf="items.length === 0">
              <div class="col-12">
                <div class="row justify-content-center">
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="p"></nz-empty>
                    <ng-template #p>
                      No Email
                    </ng-template>
                  </div>
                </div>
                <div class="row justify-content-center mt-3">
                  <div class="col-12 text-center">
                    <button nz-button nzType="text" class="button-Daily ms-2" (click)="addNewRow()"><span>Add Email</span></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngFor="let item of items; let i = index">
              <div class="col-12">
                <div class="row gx-2">
                  <div class="col-6">
                    <label class="sub-text">Email</label>
                  </div>
                  <div class="col-6">
                    <label class="sub-text">Label</label>
                  </div>
                  <div class="col-1"></div>
                </div>
                <div class="row gx-2 mt-2">
                  <div class="col-6">
                    <input type="text" [(ngModel)]="item.email" name="email_{{i}}" class="form-control formField" #emailInput="ngModel" required email />
                    <div *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)" class="text-danger">
                      <div *ngIf="emailInput.errors?.['required'] && emailInput.touched">
                        Email is required.
                      </div>
                      <div *ngIf="emailInput.errors?.['email']">
                        Please enter a valid email address.
                      </div>
                    </div>
                  </div>
                  <div class="col-5">
                    <input type="text" [(ngModel)]="item.label" name="label_{{i}}" class="form-control formField" />
                  </div>
                  <div class="col-1">
                    <a (click)="deleteRow(i)" nz-button [nzSize]="size" nzType="link"><i class="fa-solid fa-trash-can"></i></a>
                  </div>
                </div>
                <nz-divider class="mt-4 mb-4"></nz-divider>
              </div>
            </div>
            <div class="row mt-0" *ngIf="items.length > 0">
              <div class="col-4" [class.disabled]="items.length >= maxItems"
                [style.color]="items.length >= maxItems ? 'rgba(0, 0, 0, 0.4)' : 'inherit'">
                <a (click)="addNewRow()" class="fs-5" nz-icon nzType="plus-circle" nzTheme="fill"></a>
                <a (click)="addNewRow()" class="ms-2" style="bottom: 100;">Add Email</a>
              </div>
              <div class="col-8">
                <span *ngIf="items.length >= maxItems">Only 6 emails can be added</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #manageEmailsFooter>
    <button nz-button nzType="text" class="same-but" (click)="manageEmailsModelcancel()"><span>Cancel</span></button>
    <button nz-button nzType="text" class="button-Daily ms-2" [disabled]="!isAnyEmailEntered()" (click)="doneEmail()"><span>Done</span></button>
  </ng-template>
</nz-modal>

<nz-modal nzWidth="35%" [(nzVisible)]="jobGroupVisible" [nzTitle]="warrantyModalTitle" [nzFooter]="warrantyModalTitle1"
  [nzBodyStyle]="{background: '#f1f4fa',padding: '14px','max-height': '75vh','overflow-x': 'hidden'}"
  [nzStyle]="{ top: '20px' }" (nzOnCancel)="secondModelcancel()">
  <ng-template #warrantyModalTitle>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0">Add Daily Log Tag</h1>
    </div>
  </ng-template>
  <form [formGroup]="TagsForm">
    <div class="content" *nzModalContent>
      <div class="card border-0 mt-0">
        <div class="card-head border-bottom">
          <div class="row">
            <h2 class="ms-3" nz-typography>Add Daily Log Tag</h2>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <label class="sub-text">Title<span class="fw-bolder text-danger">*</span> </label>
              <input id="inputField" formControlName="name" type="text" class="form-control formField" [ngClass]="{
              error:
                TagsForm.get('name')?.invalid &&
                TagsForm.get('name')?.touched
            }" />
              <div *ngIf="
              TagsForm.get('name').hasError('required') &&
              TagsForm.get('name').touched
            " class="text-danger p-error">
                Required
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #warrantyModalTitle1>
      <button *ngIf="isLoadingEdit" nz-button nzType="default" class="rounded-1 me-0" (click)="ConfirmDeleteTags()">
        Delete
      </button>

      <button nz-button nzType="primary" class="rounded-1 me-0" (click)="SaveOrUpdateData()">
        {{ isLoadingEdit ? "Update" : "Save" }}
      </button>
    </ng-template>
  </form>
</nz-modal>