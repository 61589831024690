<nz-modal nzWidth="68%"
 [nzFooter]="null" 
 [(nzVisible)]="ForwardingEmail" 
 [nzTitle]="forwordEmail"
 [nzContent]="modalContent" 
 (nzOnCancel)="forwordToEmailModelCancel()"
 nzCloseIcon="false"
 [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '400px', 'overflow-y': 'auto'}"
 [nzStyle]="{ top: '100px' }">

  <ng-template #forwordEmail>

  <div class="row" style="padding: 10px;background-color: #fff;">
    <div class="col-8"></div>
    <div class="col-4 text-end fs-5"><span nz-icon nzType="close" nzTheme="outline"></span></div>
    </div>
  </ng-template>
 

  <ng-template #modalContent>

    <nz-card class="mb-3"  [nzTitle]="forwordEmail">
      <ng-template #forwordEmail>
          <h4 class="fw-medium famly-inn">Forwarding Email into your Buildertrend job is as 1, 2, 3 </h4>
      </ng-template>

      <nz-steps [nzCurrent]="current">
          <nz-step [nzTitle]="CopyAddress">
            <ng-template #CopyAddress>
              <h4 class="fw-medium famly-inn settext">Copy Address</h4>
            </ng-template>
          </nz-step>
          <nz-step [nzTitle]="PasteintoEmail">
            <ng-template #PasteintoEmail>
              <h4 class="fw-medium famly-inn settext">Paste into Email</h4>
            </ng-template>
          </nz-step>
          <nz-step [nzTitle]="SendEmail">
            <ng-template #SendEmail>
              <h4 class="fw-medium famly-inn settext">Send Email</h4>
            </ng-template>
          </nz-step>
      </nz-steps>

      <div class="row">
        <div class="col mt-4">
          <nz-input-group nzAddOnAfter="Copy" (click)="copyText()" style="cursor: pointer;">
            <input type="text" nz-input [(ngModel)]="inputValue" id="yourInputFieldId" />
          </nz-input-group>
        </div>
      </div>
    </nz-card>
  </ng-template>
</nz-modal>