import { SelectionInformationResponse } from 'src/Models/Selection/selectionClasses';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTreeNode, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { CreateAttachmentForDocumentRequest, LookupNameSetupResponse, ScheduleItemParameterResponse } from 'src/Models/LeadTags';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { SelectionService } from 'src/Service/Selection/selection.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { ProjectManagementSelectionCardViewwPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { ProjectManagementSiteDiariesUploadFilesComponent } from '../../Site-Diaries/project-management-site-diaries-upload-files/project-management-site-diaries-upload-files.component';
import { ProjectManagementSiteDiariesCreateFilesComponent } from '../../Site-Diaries/project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { SubVendersService } from 'src/Service/SubVendorsServices/sub-venders.service';
import { NzButtonType } from 'ng-zorro-antd/button';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { AllowanceInformationResponse } from 'src/Models/Selection/AllowanceClasses';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { ApplicationUserForComboResponse } from 'src/Models/InternalUser/InternalUserJob';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { ClassicEditor, EditorConfig, Bold, Essentials, Heading, Indent, Italic, Link, List, ListProperties, MediaEmbed, Paragraph, Autoformat, Table, Undo, Font, FontSize, FontFamily, FontBackgroundColor, FontColor, BlockQuote, HorizontalLine, UndoEditing, ImageUpload, Image } from 'ckeditor5';


interface Person {
  key: string;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'app-selection',
  templateUrl:'./selection.component.html',
  styleUrls: ['./selection.component.css']
})
export class SelectionComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Output() onSaveComplete  = new EventEmitter();

  // sufyan working add
  jobID: number;
  formNameID: number = 30;
  messagingId:number ; //soofe work
  messagingIdRow: any; //soofe work
  selectionReponse: number = -1;
  @Input() comments: any;
  // sufyan working add

  fileName: string;
  selectedValue = null;
  addedCost: number = 0;
  visible=false
  radioValue = 'A';
  demoValue = 0.00;
  date = null;
  time: Date | null = null;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);
  value: string[] = ['0-0-0'];
  showDateFields: boolean = true;
  showScheduleItemFields: boolean = false;
  selectionModelhandle=true;
  selectioTabs = [ 1,2,3,4,5];
  listOfOption: Array<{ label: string; value: string }> = [];
  categoryOption: LookupNameSetupResponse [] = [];
  locationOption: LookupNameSetupResponse [] = [];
  scheduleItemOption: ScheduleItemParameterResponse [] = [];
  shareAllowanceOption: AllowanceInformationResponse [] = [];
  listOfTagOptions = [];
  customNotFoundContent = 'Adding choice will be available after saving';
  showSelector =true
  ShowSelector2 =false;
  newSelectionFormFiedls: any = {};
  pageId : number = 30;
  selectionResponce: SelectionInformationResponse;
  inputValue?: string;
  editorValue: string = '';
  radioValuess = 'A';
  radioValues = 'A';
  vendersNodes: NzTreeNodeOptions[] = [];
  vendorList: SubVendorResponse[] = [];
  selectedUsers: ApplicationUserForComboResponse[] = [];
  nzNodes: NzTreeNodeOptions[] | NzTreeNode[];
  subVendorsNodes: NzTreeNodeOptions[] = [];
  installarNodes: NzTreeNodeOptions[] = [];
  titleTouched: boolean = false;
  showTitleError: boolean = false;
  showSharedAllowanceError: boolean = false;
  sharedAllowanceTouched: boolean = false;
  sharedAllowanceId: string | null = null;
  selectedFiles: File[] = [];
  SelectInfoResponse: SelectionInformationResponse;

  /////
  CategoryField: FormGroup;
  isDeleteTagsLoading: boolean;
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  isLoadingOne: boolean = false;
  public isTagVisible = false;
  isLoadingEdit: boolean = false;
  PAGE_ID: 30;
  confirmModal: import("ng-zorro-antd/modal").NzModalRef<unknown, any>;
  isButtonEnabled: boolean = false;

//////


  preDefinedDataCodes = {
    Categories: ProjectManagementSelectionCardViewwPreDefinedCodes.Categories,
    Locations: ProjectManagementSelectionCardViewwPreDefinedCodes.Locations,
  };




  constructor(
    private LookupFilterService: LookupFilterService,
    private modal:NzModalService,
    private selectionService: SelectionService,
    private leadOpportunitiesService:LeadOpportunitiesService,
    public toastsService: NzMessageService,
    private subVendorsService: SubVendersService,
    private internalUserService: InternalUserService,
    private loadingIndicatoreService: LoadingIndicatorService,
    private fb: FormBuilder,
    @Inject(NZ_MODAL_DATA) public JobData: any,
    @Inject(NZ_MODAL_DATA) public leadData: any,public localStorage: AccessLocalStorageService,
    @Inject(NZ_MODAL_DATA) public SelectionInformation: any,
    @Inject(NZ_MODAL_DATA) public SelectionInformation2: any,
  ){


  }

  ngOnInit(): void {


    this.messagingId = this.SelectionInformation?.SelectionInformation?.messagingId;
    this.messagingIdRow = this.SelectionInformation2?.SelectionInformation2;
    console.log("MESSAGEING ID ROW:", this.messagingIdRow);
    console.log("MESSAGEING ID :", this.messagingId);

    const  storeComments = this.SelectionInformation?.SelectionInformation?.messaging?.messagingParameters.map(param => param.comments)

    this.comments = storeComments?.flat() || [];
    const jobId = this.localStorage.getJobId();

    this.getDropDownMethod();
    this.getScheduleItem();
    this.getAllowanceInformation();
    this.subVendorsGetAll();
    this.getForINstallar();
    this.iniliazeForm();

    this.jobID = this.localStorage.getJobId();

    this.leadOpportunitiesService.getSelectedFiles().subscribe(files => {
      this.selectedFiles = files;
    });


    const children: Array<{ label: string; value: string }> = [];
      for (let i = 10; i < 36; i++) {
        children.push({ label: i.toString(36) + i, value: i.toString(36) + i });
      }
    this.listOfOption = children;


    this.newSelectionFormFiedls = {
      globalId: '00000000-0000-0000-0000-000000000000',
      selectionSetupId: 0,
      jobInformationId:  jobId,
      title: '',
      isSingle: true,
      isShared: true,
      sharedAllowanceId: 0,
      isRequireOwnerToMakeSelection: false,
      catagorySetupId: 0,
      locationSetupId: 0,
      singleAllowanceNumber: '',
      isChooseDate: true,
      isLinkedToScheduleItem: 0,
      scheduleItemParameterId: 0,
      dueNumberDays: 0,
      isBefore: true,
      isAfter: false,
      dedlineOn: null,
      time: null,
      attachmentId: 0,
      messagingId: 0,
      isApproveChoicesOnly: true,
      isAddEditAndApproveChoices: false,
      publicInstructions: "",
      internalNotes: '',
      isAllowMultipleSelectedChoices: true,
      isAuto: true,
      isManual: true,
      isApprove: true,
      isDecline: true,
      createSelectionSubVenderParameterRequests: [],
      createSelectionInstallerParameterRequests: [],
    };


    this.newSelectionFormFiedls['title'] = this.SelectionInformation?.SelectionInformation?.title;

    this.selectionReponse =  this.SelectionInformation2?.SelectionInformation?.id

  }


  saveSelectionForm(){
    let SubsVendenrs = this.newSelectionFormFiedls.createSelectionSubVenderParameterRequests.map(
      (id: string) =>
      ({ workDayId: id })
    );
    let Installars = this.newSelectionFormFiedls.createSelectionSubVenderParameterRequests.map(
      (id: string) =>
      ({ workDayId: id })
    );


    this.newSelectionFormFiedls.createSelectionSubVenderParameterRequests = SubsVendenrs;
    this.newSelectionFormFiedls.createSelectionInstallerParameterRequests = Installars;
      this.selectionService.PostDataSelectionForm(this.newSelectionFormFiedls).subscribe(
        (response) => {
          this.selectionReponse = response.result.id; //sufyan add
          this.messagingId = this.selectionReponse ? this.selectionReponse : this.SelectionInformation2;
          this.messagingIdRow =  this.selectionReponse ? this.selectionReponse : this.SelectionInformation2;
          console.log(" toDoParameterId ? toDoParameterId : this.rowToDoData;",this.messagingIdRow);



          // this.toastsService.success('New Lead Saved Successfully!');
          if (this.selectedFiles && this.selectedFiles.length > 0) {
            const filesToUpload = this.selectedFiles.filter(file => file && file instanceof File);
            if (filesToUpload.length > 0) {
              const attachmentRequest: CreateAttachmentForDocumentRequest = {
                headerId: response.result.id,
                companyParameterId: 1,
                formNameId: 30,
                documentFiles: filesToUpload.map(file => file)
              };
              console.log('selectedFiles:', this.selectedFiles);
              this.leadOpportunitiesService.CreateAttachmentForDocument(attachmentRequest).subscribe(
                (attachmentResponse) => {
                  console.log('Attachment created successfully:', attachmentResponse);
                  // Assuming attachmentResponse contains the file ID, you can save it to the database
                },
                (attachmentError) => {
                  console.error('Error creating attachment:', attachmentError);
                }


              );

              // this.toastsService.success('Selection Form Successfully!');
              // this.selectionModelhandle = false;
              // this.onSaveComplete.emit();

            } else {
              console.warn('No files to upload.');
            }
          }
          setTimeout(() => {
            this.toastsService.success('New Selection Saved Successfully!');
            this.onSaveComplete.emit();
            // this.cancel.emit();
          }, 2000);
        },
        (error) => {
          console.error('Error saving data:', error);

        }
      );
  }

  getDropDownMethod(){

    this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(res =>{

      this.lookupSetNameResponse = res.result;
      let categoryOptionValues = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.Categories.code
      );
      this.categoryOption = categoryOptionValues;

      console.log('dropdown',this.categoryOption);

      let locationOptionValues = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.Locations.code
      );
      this.locationOption = locationOptionValues;

    })

  }

  getScheduleItem() {
    this.leadOpportunitiesService.getAllDataScheduleItem().subscribe(res => {

    this.scheduleItemOption= res.result
      // // Check if there are options available and set the default value
      // if (this.scheduleItemOption.length > 0) {
      //   // Assuming you want to select the first option by default
      //   this.newSelectionFormFiedls['scheduleItemParameterId'] = this.scheduleItemOption[0].id;
      // }
    });
  }

  getAllowanceInformation(){
      this.selectionService.getAllDataAllowanceInformation().subscribe(res =>{

        this.shareAllowanceOption = res.result;
      })
  }



  fileList: NzUploadFile[] = [
    {
      uid: '-1',
      name: 'claimNoPhoto.gif',
      status: 'done',
      url: 'assets/claimNoPhoto.gif',
      download: false,
    },
  ];

  getButtonType(): NzButtonType {
    // Check if `newSelectionFormFiedls.title` is empty or if `isSharedSelected` is true and other mandatory fields are empty
    if (!this.newSelectionFormFiedls.title || (this.ShowSelector2 && (!this.newSelectionFormFiedls.sharedAllowanceId)) || (this.showScheduleItemFields && (!this.newSelectionFormFiedls.scheduleItemParameterId))) {
        // Return null if either title is empty or mandatory fields are missing when `isSharedSelected` is true
        return null;
    } else {
        // Return 'primary' if all conditions are met and fields are valid
        return 'primary';
    }
}

  subVendorsGetAll() {
      this.subVendorsService.getData().subscribe(res => {
        this.vendorList = res.result.filter(data => data.automaticallyPermitAccessToNewJobs === true);
        this.vendersNodes = this.vendorList.map((data) => ({
          title: data.companyName,
          value: data.id.toString(),
          key: data.id.toString(),
          isLeaf: true,
        }));
      })
    }
    getForINstallar() {
      // this.internalUserService.getData().subscribe(result => {
      //   this.selectedUsers = result?.result.filter(data => data.isGrantExsistJobOrTemplate === true)
      //   this.installarNodes = this.selectedUsers.map((data) => ({
      //     title: data.fullName,
      //     value: data.id.toString(),
      //     key: data.id.toString(),
      //     isLeaf: true,
      //   }));
      // });
    }

  selectionCancel(){
    this.visible=false;
  }
  showSelection(){
    this.visible=true;
  }


  convertToRupees(value: number): string {
    // Your conversion logic here
    // For example, if 1 dollar = 75 rupees
    const conversionRate = 75;
    const result = (value * conversionRate).toFixed(2); // Convert to string with two decimal places
    return result;
  }

  updateConvertedValue(newValue: string): void {
    // Update the addedCost property as the user types, with a debounce of 300 milliseconds
    const inputParts = newValue.split('.');
    const integerValue = inputParts[1];
    const decimalValue = inputParts.length > 1 ? inputParts[1] : '';

    // Check if the decimal part has been manually modified by the user
    const isDecimalModifiedByUser = newValue.includes('.') && decimalValue.length > 0;

    // If the decimal part is not modified by the user, update the addedCost
    if (!isDecimalModifiedByUser) {
      this.addedCost = integerValue !== '' ? parseFloat(integerValue) / 75 : 0; // Parse the input for the integer part
    }

}

  toggleDateFields() {
    this.showDateFields = !this.showDateFields;
    this.showScheduleItemFields = false; // Ensure the other set is hidden
  }

  toggleScheduleItemFields() {
    this.showScheduleItemFields = !this.showScheduleItemFields;
    this.showDateFields = false; // Ensure the other set is hidden
  }


  selectionModelCancel(): void {
  this.cancel.emit();
  }

  open(): void {
    this.visible = true;
  }

  selectioninnercardTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'Chooice';
      case 2:
        return 'Description';
      case 3:
        return 'Lock/Unlock';
      case 4:
        return 'Files & Discussions';
      case 5:
        return 'Participation';

      default:
        return '';
    }
  }
  autoAdjustTextAreaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; // Set the height with a maximum of 200px
  }

  Single(){
   this.showSelector=true
   this.ShowSelector2=false
  }

  Shared(){
    this.showSelector=false
    this.ShowSelector2=true
  }


  isRowVisible: boolean = false;



  uploadFiles(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesUploadFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    this.isRowVisible = true;
  }
  createNew(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    this.isRowVisible = true;
  }



  onTitleFocus() {
    this.titleTouched = true; // User focused on title field
}

  // Event handler for title field blur
  onTitleBlur() {
    // Check if title field is empty and title has been touched
    if (!this.newSelectionFormFiedls.title  && this.titleTouched) {
        // Display error message or handle it according to your requirement
        this.showTitleError = true;
    } else {
        // Hide error message if title is not empty or has not been touched
        this.showTitleError = false;
    }
}

 onSharedAllowanceBlur(): void {


  // Check if the shared allowance ID is empty or not selected
  if (!this.sharedAllowanceId) {
      // Set the error flag to true to show the error message
      this.showSharedAllowanceError = true;
      console.log('showSharedAllowanceError set to true');
  } else {
      // If the value is selected, set error flag to false
      this.showSharedAllowanceError = false;
      console.log('showSharedAllowanceError set to false');
  }
}

// Event handler for focus event on select field
onSharedAllowanceFocus(): void {
  console.log('onSharedAllowanceFocus called');
  // Reset the error flag when the field is focused
  this.showSharedAllowanceError = false;
  console.log('showSharedAllowanceError reset to false');
}


viewAll(): void {
  const modalRef = this.modal.create({
    nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
}
removeFile(file: File): void {
  const index = this.selectedFiles.indexOf(file);
  if (index !== -1) {
    this.selectedFiles.splice(index, 1);
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

SaveData() {
  if (this.CategoryField.valid) {
    this.loadingIndicatoreService.show();
    this.isLoadingOne = true;
    setTimeout(() => {
      this.leadOpportunitiesService.getLookUpNameSetup(this.CategoryField.value).subscribe(
        (response) => {
          this.loadingIndicatoreService.hide();
          const category = this.CategoryField.get('name')?.value;
          this.isTagVisible = false;
          this.iniliazeForm();
          this.iniliazeForm2();
          this.getDropDownMethod();

          this.isLoadingOne = false;
          this.toastsService.success(`${category} saved successfully`);
        },
        (error) => {
          this.loadingIndicatoreService.hide();
          if (error.status === 500) {
            this.toastsService.error(
              'Internal Server Error. Please try again later.'
            );
            this.isLoadingOne = false;
          } else if (error.status === 400) {
            this.isLoadingOne = false;
            this.toastsService.error('Bad Request. Please check your input.');
          } else {
            this.toastsService.error(
              'Failed to save division trade. Please try again.'
            );
          }
        }
      );
    }, 1000);
  } else {
    this.CategoryField.markAllAsTouched();
  }
}

editData() {
  if (this.CategoryField.valid) {
    this.loadingIndicatoreService.show();
    const tagName = this.CategoryField.get('name')?.value;
    setTimeout(() => {
      this.leadOpportunitiesService.getLookUpNameSetup(this.CategoryField.value).subscribe(
        (response: any) => {
          this.loadingIndicatoreService.hide();
          this.isTagVisible = false;
          this.iniliazeForm();
          this.iniliazeForm2();
          this.getDropDownMethod();
          this.isLoadingEdit = false;
          this.toastsService.success(`${tagName} Update successfully`);
        },
        (error) => {
          this.loadingIndicatoreService.hide();
          if (error.status === 500) {
            this.toastsService.error(
              'Internal Server Error. Please try again later.'
            );
          } else if (error.status === 400) {
            this.toastsService.error('Bad Request. Please check your input.');
          } else {
            this.toastsService.error(
              'Failed to save division trade. Please try again.'
            );
          }
        }
      );
    }, 1000); // 2000 milliseconds (2 seconds)
  } else {
  }
}

ConfirmDeleteTags(): void {
  this.confirmModal = this.modal.confirm({
    nzTitle: 'Delete Tags?',
    nzContent:
      'This Tags is not applied to any subs/vendors and will be deleted.',
    nzOkDanger: true,
    nzOkText: 'Delete',
    nzCancelText: 'Cancel',
    nzModalType: 'confirm',
    nzOkLoading: this.isDeleteTagsLoading,
    nzAutofocus: 'ok',
    nzCentered: true,
    nzOnOk: () => this.DeleteData(),
  });
}
SaveOrUpdateData() {
  if (this.isLoadingEdit) {
    this.editData();
  } else {
    this.SaveData();
  }
}

DeleteData(): Promise<boolean> {
  const vendorId = this.CategoryField.get('globalId')?.value;
  const tagName = this.CategoryField.get('name')?.value;
  this.isDeleteTagsLoading = true;
  this.loadingIndicatoreService.show();

  return new Promise<boolean>((resolve, reject) => {
    this.leadOpportunitiesService.deleteDataLookupNameSetup(vendorId).subscribe(
      (res: any) => {
        this.isDeleteTagsLoading = false;
        setTimeout(() => {
          this.toastsService.success(`${tagName} Deleted successfully`);
          this.isTagVisible = false;
          this.loadingIndicatoreService.hide();
          this.confirmModal.destroy();
          resolve(true);
        }, 1000);
        this.isLoadingEdit = false;
        this.isTagVisible = false;
        this.iniliazeForm();
        this.iniliazeForm2();
        this.getDropDownMethod();
        this.newSelectionFormFiedls['catagorySetupId'] = '';
        this.newSelectionFormFiedls['locationSetupId'] = '';
      },
      (error) => {
        this.isDeleteTagsLoading = false;
        this.isTagVisible = false;
        reject(false);
      }
    );
  });
}

showConfirmData(): void {
  if (this.CategoryField.dirty) {
    // The form has unsaved changes, show confirmation modal
    this.confirmModal = this.modal.confirm({
      nzTitle: '<b>Save Changes?</b>',
      nzContent:
        '<p>This Lead Tags has unsaved changes. Do you want to save your changes before closing??</p> ',
      nzOkText: 'Save',
      nzCancelText: "Don't Save",
      nzOnOk: () => {
        // User confirmed to discard changes, save and close modal
        this.SaveData();
      },
      nzOnCancel: () => {
        // User canceled, reset form and close modal
        this.CategoryField.reset();
        this.isTagVisible = false;
      },
      nzCloseIcon: 'false',
      nzStyle: { top: '250px' },
      nzClassName: 'custom-modal-content',
    });
  } else {
    // No unsaved changes, close the modal directly
    this.CategoryField.reset();
    this.isTagVisible = false;
  }
}

showTagEdit(selectedValue: LookupNameSetupResponse): void {
  if (selectedValue) {
    const selectedId: number = Number(selectedValue); // Convert the selected ID to number
    // console.log('selectedId', selectedId);
    this.isTagVisible = true;
    this.isLoadingEdit = true;
    const selectedObjectFromLookup = this.lookupSetNameResponse.find(
      (item) => item.id === selectedId

    );
    if (selectedObjectFromLookup) {
      const selectedData = selectedObjectFromLookup;
      if (selectedData) {
        this.CategoryField.controls['name'].setValue(selectedData.name);
        this.CategoryField.controls['globalId'].setValue(selectedData.globalId);
        this.CategoryField.controls['isFieldValue'].setValue(
          selectedData.isFieldValue
        );
        this.CategoryField.controls['companyParameterId'].setValue(
          selectedData.companyParameterId
        );
        this.CategoryField.controls['formNameId'].setValue(
          selectedData.formNameId
        );
        this.CategoryField.controls['lookUpStandardId'].setValue(
          selectedData.lookUpStandardId
        );
        this.CategoryField.controls['code'].setValue(selectedData.code);
        this.CategoryField.controls['description'].setValue(
          selectedData.description
        );
        this.CategoryField.controls['parentCode'].setValue(
          selectedData.parentCode
        );
      }
    }
  }
}

showTags(): void {
  // this.isTagVisible = true ;
  this.loadingIndicatoreService.show();

  setTimeout(() => {
    this.loadingIndicatoreService.hide();
    this.isTagVisible = true;
    this.isLoadingEdit = false;
  }, 1000);
}


iniliazeForm() {
  this.CategoryField = this.fb.group({
    id: [''],
    globalId: ['00000000-0000-0000-0000-000000000000'],
    name: ['', Validators.required],
    companyParameterId: [1],
    formNameId: 30,
    lookUpStandardId: [74],
    parentCode: ['PMSCVC'],
    code: ['PMSCVC'],
    description: [''],
    isFieldValue: [true],
  });
}
iniliazeForm2() {
  this.CategoryField = this.fb.group({
    id: [''],
    globalId: ['00000000-0000-0000-0000-000000000000'],
    name: ['', Validators.required],
    companyParameterId: [1],
    formNameId: 30,
    lookUpStandardId: [73],
    parentCode: ['PMSCVL'],
    code: ['PMSCVL'],
    description: [''],
    isFieldValue: [true],
  });
}



public Editor = ClassicEditor;
public config: EditorConfig = {
  toolbar: {
    items: [
      'undo', 'redo', '|',
      'heading', '|', 'bold', 'italic', '|',
      'link', 'insertTable', 'mediaEmbed', '|',
      'bulletedList', 'numberedList', 'indent', 'outdent', '|',
      'fontSize', 'fontFamily', 'fontBackgroundColor', 'fontColor', 'blockQuote',
      'image', 'horizontalLine', 'undoEditing'
    ]
  },
  plugins: [
    Bold,
    Essentials,
    Heading,
    Indent,
    Italic,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    Table,
    Undo,
    Font,
    FontSize,
    FontFamily,
    FontBackgroundColor,
    FontColor,
    BlockQuote,
    Image,
    HorizontalLine,
    UndoEditing,
    ImageUpload
  ],
  fontSize: {
    options: [
      9, 11, 13, 'default', 17, 19, 21, 25, 28, 32, 36, 40, 44, 48, 52, 56, 60
    ]
  },
  fontFamily: {
    options: [
      'default', 'Arial, Helvetica, sans-serif', 'Courier New, Courier, monospace',
      'Georgia, serif', 'Times New Roman, Times, serif', 'Verdana, Geneva, sans-serif'
    ]
  }
};


}



