import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CompanySettingSubVendorService } from 'src/Service/CompanyInFormation/company-setting-sub-vendor.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ModalService } from 'src/Service/CompanyInFormation/modal.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SubVendorSetupResponse } from 'src/Models/SubVendor/SubvendorResponse.model';

@Component({
  selector: 'app-company-settings-subs-vendors',
  templateUrl: './company-settings-subs-vendors.component.html',
  styleUrls: ['./company-settings-subs-vendors.component.css']
})
export class CompanySettingsSubsVendorsComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  formfieldsvalue :  any = {}
  selectedValue = null;
  checked7 = false;
  checked8 = false;
  newsurveys: boolean = false;
  selectedOption: string = 'None';
  selectedOption3: string = 'None';
  selectedOption5: string = '1';
  isOpen: boolean = false;
  isOpen3: boolean = false;
  isOpen5: boolean = false;
  options5: string[] = ['1', '2', '3', '4', '5', '6' , '7'];
  options: string[] = ['None', '1 Day', '2 Day', '3 Day', '7 Day', '14 Day', '21 Day' , '30 Day'];
  options3: string[] = ['None', '1 Day', '2 Day', '3 Day', '4 Day', '5 Day', '6 Day' , '7 Day'];
  editorValue: string = '';
  subsvendors= true;
  dataset: any;


  constructor(private SubVendorService:CompanySettingSubVendorService, 
      private toastser: NzMessageService,
     private modal: NzModalService
  ){}


  ngOnInit(): void {
    this.fetchCompanySubVendorData();
    this.formfieldsvalue = {
      companyParameterId: 1,
      additionalCertificateName1: ' ',
      additionalCertificateName2: '',
      subVendorInvitationEmail: '',
    }
   
  }

  autoAdjustTextAreaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; // Set the height with a maximum of 200px
  }


  selectOption5(option: string) {
    this.selectedOption = option;
    this.isOpen5 = false;
  }
  toggleDropdown5() {
    this.isOpen5 = !this.isOpen5;
  }

  newsurvey() {
    this.newsurveys = true;
}
customfieldinfo:boolean = false;
CustomFieldInfo(){
  this.customfieldinfo = true;
}
handleCancel2(): void {
  this.customfieldinfo = false;
}
closeSubsVendors() {
  this.cancel.emit();
}

saveMethod(){
  console.log('saif data', this.formfieldsvalue);
  this.SubVendorService.postsubvendorSetup(this.formfieldsvalue).subscribe((response) =>{
    this.toastser.success('Subvendor Save Success')
    this.formfieldsvalue = {},
    this.modal.closeAll()
  })
}

fetchCompanySubVendorData() {
  this.SubVendorService.getAllSubVendorData().subscribe(
    (response) => {
      this.dataset = response.result;
      let subData = this.dataset
        this.formfieldsvalue.additionalCertificateName1 = subData.additionalCertificateName1 || '',
        this.formfieldsvalue.additionalCertificateName2 = subData.additionalCertificateName2 || '',
        this.formfieldsvalue.subVendorInvitationEmail = subData.subVendorInvitationEmail || ''
    },
    (error) => {
      console.error('Error:', error);
    }
  );
}

}