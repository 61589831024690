import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EstimateDashboardComponent } from './Estimate/estimate-dashboard/estimate-dashboard.component';
import { BidDashboardComponent } from './Tenders/bid-dashboard/bid-dashboard.component';
import { BudgetDashboardComponent } from './Budget/budget-dashboard/budget-dashboard.component';
import { PurchaseOrderDashboardComponent } from './purchaseOreder/purchase-order-dashboard/purchase-order-dashboard.component';
import { CostInboxDashboardComponent } from './Cost-Inbox/cost-inbox-dashboard/cost-inbox-dashboard.component';
import { OwnerInvoiceDashboardComponent } from './Owner-Invoice/owner-invoice-dashboard/owner-invoice-dashboard.component';

const routes: Routes = [
  { path: '', redirectTo: 'Estimate', pathMatch: 'full' },
  { path: 'Estimate', component: EstimateDashboardComponent},
  { path: 'Tenders', component: BidDashboardComponent},
  { path: 'Budget', component:BudgetDashboardComponent},
  { path: 'Purchase-Orders', component: PurchaseOrderDashboardComponent},
  { path: 'Bills', component: PurchaseOrderDashboardComponent},
  { path: 'Cost-Inbox', component: CostInboxDashboardComponent},
  { path: 'Owner-Invoices', component: OwnerInvoiceDashboardComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FinancialRoutingModule { }
