<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form [formGroup]="financialtendersFilterForm" (ngSubmit)="onSubmit()">
    <div class="row mt-2">



      <div class="col-10 p-0">
        <div class="input-group">
         <nz-select
         formControlName="standardFilter"
          name="standardFilter"
          (ngModelChange)="onChangeStandardFilter($event)"
          nzShowSearch
          [nzAutoFocus]="true"
          nzPlaceHolder="Standard Filter"
          r>
          <nz-option *ngFor="let filter of filterResponseData"
           [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')"
           [nzValue]="filter.id"
           nzCustomContent>
           <div class="option-content">
            <!-- Customize content with icons and text -->
            <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
            <span>{{ filter.name }}</span>
           <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

           </div>
          </nz-option>
         </nz-select>
        </div>
       </div>
      <div class="col-1 mt-1">
        <i
        nz-popover
        [nzPopoverContent]="contentTemplate"
        [nzPopoverPlacement]="'top'"
        class="bi bi-three-dots" (click)="openStandardFilterComponent()"></i>
      </div>
    </div>

    <div class="row  d-flex justify-content-between align-items-center">
      <div class="col-10 p-0 ">
        <!-- <div class="input-group">
          <nz-select>
            <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
            <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
          </nz-select>
        </div> -->
      </div>
      <!-- <div class="col-2 text-end p-0">
        <button nz-button class="border-0">
          <span nz-popover class="text-primary text-dark fw-bold fs-5" nz-icon nzType="ellipsis"
            nzTheme="outline"></span>
        </button>
      </div> -->
    </div>


    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Search</label>
        <input  formControlName="search" class="formField form-control" nz-input placeholder="Basic usage" type="number" />
      </div>
    </div>




    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Bid Package Status</label>
        <nz-tree-select
        [(ngModel)]="financialtendersFilterForm['bidPackageole']"
        name="bidPackageole"
        style="width: 100%"
        [nzNodes]="BidPackageStatusNodes"
        nzShowSearch
        nzAllowClear="false"
        nzCheckable
        (ngModelChange)="OnbidStatusChange($event)"
        nzPlaceHolder="Select Bids Package Status"
        nzDropdownClassName="dropdownStyle"
      >
      </nz-tree-select>

      </div>
    </div>

    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Bid Status</label>
        <nz-tree-select
        [(ngModel)]="financialtendersFilterForm['bidStatus']"
        name="bidStatus"
        style="width: 100%"
        [nzNodes]="BidStatusNodes"
        nzShowSearch
        nzAllowClear="false"
        nzCheckable
        (ngModelChange)="OnbidStatusNotesChange($event)"
        nzPlaceHolder="Select Bids Status"
        nzDropdownClassName="dropdownStyle"
      >
      </nz-tree-select>

      </div>
    </div>

    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Subs/vendors</label>
        <nz-tree-select
        [(ngModel)]="financialtendersFilterForm['subsvendors']"
        name="subsvendors"
        style="width: 100%"
        [nzNodes]="SubvendorAssginTo"
        nzShowSearch
        nzAllowClear="false"
        nzCheckable
        (ngModelChange)="OnVendorChange($event)"
        nzPlaceHolder="Select Subs-vendors"
        nzDropdownClassName="dropdownStyle"
      >
      </nz-tree-select>

      </div>
    </div>

    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text"> Deadline Date</label>
        <nz-select nzShowSearch nzAllowClear formControlName="DeadlineDate" >
          <nz-option *ngFor="let option of keywordOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
      </div>
    </div>

    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Budgeted Status</label>
        <nz-tree-select
        [(ngModel)]="financialtendersFilterForm['budgetedStatus']"
        name="budgetedStatus"
        style="width: 100%"
        [nzNodes]="BudgetedStatusNodes"
        nzShowSearch
        nzAllowClear="false"
        nzCheckable
        (ngModelChange)="OnbudgeStatusChange($event)"
        nzPlaceHolder="Select Budgeted Status"
        nzDropdownClassName="dropdownStyle"
      >
      </nz-tree-select>

      </div>
    </div>

    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Has Related PO</label>
        <nz-tree-select
        [(ngModel)]="financialtendersFilterForm['hasRelated']"
        name="hasRelated"
        style="width: 100%"
        [nzNodes]="HasRelatedPONodes"
        nzShowSearch
        nzAllowClear="false"
        nzCheckable
        (ngModelChange)="OnRelatedPoChange($event)"
        nzPlaceHolder="Select Related PO"
        nzDropdownClassName="dropdownStyle"
      >
      </nz-tree-select>

      </div>
    </div>

  </form>
</div>


<ng-template #contentTemplate>
  <div>
  <p>Manage Saved Views</p>
  </div>
  </ng-template>
