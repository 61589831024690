import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { ApplicationUserResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { colorService } from 'src/Service/Job-List/Job-Information/color.service';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { SubVendersService } from 'src/Service/SubVendorsServices/sub-venders.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';


interface ItemData {
  id: number;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'app-form-scratch-template',
  templateUrl: './form-scratch-template.component.html',
  styleUrls: ['./form-scratch-template.component.css']
})
export class FormScratchTemplateComponent implements OnInit {

  @Output() cancel = new EventEmitter<void>();
  tabJob = [1, 2, 3];
  scratchFormModal = true;
  inputValue?: string;
  projectManagersNodes: NzTreeNodeOptions[] = [];
  workDaysNodes: NzTreeNodeOptions[] = [];
  colors: { label: string; value: string }[] = [];


  internalUserNodes: NzTreeNodeOptions[] = [];
  subVendorsNodes: NzTreeNodeOptions[] = [];
  JobCreateNewScratchModal = true;
  userList: ApplicationUserResponse[] = [];
  projectManagerList: ApplicationUserResponse[] = [];
  vendorList: SubVendorResponse[] = [];

  selectedUserIds: string[] = [];
  selectedUsers: ApplicationUserResponse[] = [];
  selectedVenodorIds: string[] = [];
  selectedVenodors: SubVendorResponse[] = [];
  formFieldValues: any = {};

  projectManagerNodes: NzTreeNodeOptions[] = [];
  
  constructor(
    private colorService: colorService,
    private customerInformationService: CustomerInformationService,
    private jobInformationService: JobInformationService,
    private internalUserService: InternalUserService,
    private toastsService: NzMessageService,
    private subVendorsService: SubVendersService,) { }




  ngOnInit(): void {

    this.internalUserGetAll();
    this.subVendorsGetAll();

    this.internalUserService.getData().subscribe(res => {
      this.userList = res.result;
      this.projectManagerNodes = this.projectManagerList.map((data) => ({
        title: data.firstName + data.lastName,
        value: data.id.toString(),
        key: data.id.toString(),
        isLeaf: true,
      }));
    })


    this.colorService.getData().subscribe((res: any) => {
      const colorGet = res.result;

      this.colors = colorGet.map((COL) => ({
        label: COL.name,
        value: COL.id.toString(),
      }))
    })

    this.customerInformationService.getDataforcombo().subscribe((res) => {
      const assignedUser = res.result;

      this.projectManagersNodes = assignedUser.map((PMs) => ({
        title: PMs.fullName,
        key: PMs.id.toString(),
        isLeaf: true,
      }));

    });

    this.jobInformationService.getDataWorkDay().subscribe((res) => {
      const workDays = res.result;



      this.workDaysNodes = workDays.map((WDs) => ({
        title: WDs.name,
        key: WDs.id.toString(),
        isLeaf: true,
      }));

    });

    this.formFieldValues = {
      globalId: "00000000-0000-0000-0000-000000000000",
      name: "",
      colorId: '',
      isIncludeAllowances: true,
      internalNotes: "",
      subVendorNotes: "",
      createTemplateProjectManagerUserParameterRequests: [],  
      createJobTemplateWorkDayParameterRequests: [
        '1',
        '2',
        '3',
        '4',
        '5',
      ],
      createTemplateInternalUserParameterRequests: [],  
      createTemplateSubVendorParameterRequests: [],


    };

  }


  saveScratchJob() {
    let WorkDays = this.formFieldValues.createJobTemplateWorkDayParameterRequests.map(
      (id: string) => 
      ({ workDayId: id })
    );

    // let WorkDays = this.formFieldValues.createWorkDayParameterRequests.map(
    //   (id: string) => 
    //   ({ workDayId: id })
    // );

    let projectManagers = this.formFieldValues.createTemplateProjectManagerUserParameterRequests.map(
      (id: string) => ({ applicationUserId: id })
    );

    this.formFieldValues.createJobTemplateWorkDayParameterRequests = WorkDays;
    this.formFieldValues.createTemplateProjectManagerUserParameterRequests = projectManagers;

    

    console.log(this.formFieldValues);
    this.jobInformationService.PostJobTemplate(this.formFieldValues).subscribe((response) => {

      this.formFieldValues = response.result;
      
      this.toastsService.success('Job Template Saved Successfully!');
      this.scratchFormModal = false;
       this.cancel.emit();
    });
  }


  jobScratchTabMethod(tab: number): string {
    switch (tab) {
      case 1:
        return 'Template';
      case 2:
        return 'Internal Users';
      case 3:
        return 'Subs/Vendors';
      default:
        return '';
    }
  }





  internalUserGetAll() {
    this.internalUserService.getData().subscribe(res => {
      this.userList = res.result;


      this.internalUserNodes = this.userList.map((data) => ({
        title: data.firstName + data.lastName,
        value: data.id.toString(),
        key: data.id.toString(),
        isLeaf: true,
      }));
    })
  }

  subVendorsGetAll() {
    this.subVendorsService.getData().subscribe(res => {
      this.vendorList = res.result;


      this.subVendorsNodes = this.vendorList.map((data) => ({
        title: data.companyName,
        value: data.id.toString(),
        key: data.id.toString(),
        isLeaf: true,
      }));
    })
  }



  addSelectedUsers() {
    const selectedUsersToAdd = this.userList.filter(user => this.selectedUserIds.includes(user.id.toString()));
    const uniqueSelectedUsersToAdd = selectedUsersToAdd.filter(userToAdd => !this.selectedUsers.some(user => user.id === userToAdd.id));

    // Push unique selected users to createJobSetupInternalUserViewAccessParameterRequests
    const userViewAccessParameters = uniqueSelectedUsersToAdd.map(user => ({
      applicationUserId: user.id,
      isNotification: true,
      isStatus: true
    }));

    this.formFieldValues.createTemplateInternalUserParameterRequests.push(...userViewAccessParameters);

    this.selectedUsers.push(...uniqueSelectedUsersToAdd);
    this.internalUserNodes = this.internalUserNodes.filter(node => !this.selectedUserIds.includes(node['value']));
    this.selectedUserIds = this.selectedUserIds.filter(id => !uniqueSelectedUsersToAdd.map(user => user.id.toString()).includes(id));

    // Check if there are no more options to add
    if (this.internalUserNodes.length === 0 && this.selectedUserIds.length === 0) {
      // All users have been added to the job
      this.selectedUserIds.push('All users have been added to this job.');
    }
  }





  removeUser(userId: string) {
    this.selectedUsers = this.selectedUsers.filter(user => user.id.toString() !== userId);
  }


  addSelectedVendors() {
    const selectedUsersToAdd = this.vendorList.filter(user => this.selectedVenodorIds.includes(user.id.toString()));
    const uniqueSelectedVendorsToAdd = selectedUsersToAdd.filter(userToAdd => !this.selectedVenodors.some(user => user.id === userToAdd.id));

    const selectedVendorsAccessParameters = uniqueSelectedVendorsToAdd.map(vendor => ({
      subVendorId: vendor.id,
      isStatus: true
    }));

    this.formFieldValues.createTemplateSubVendorParameterRequests.push(...selectedVendorsAccessParameters);

    this.selectedVenodors.push(...uniqueSelectedVendorsToAdd);
    this.subVendorsNodes = this.subVendorsNodes.filter(node => !this.selectedVenodorIds.includes(node['value']));
    this.selectedVenodorIds = this.selectedVenodorIds.filter(id => !uniqueSelectedVendorsToAdd.map(user => user.id.toString()).includes(id));
    if (this.subVendorsNodes.length === 0 && this.selectedVenodorIds.length === 0) {
      this.selectedVenodorIds.push('All Sub/Vendors have been added to this job.');
    }
  }

  removeVendors(userId: string) {
    this.selectedUsers = this.selectedUsers.filter(user => user.id.toString() !== userId);
  }
  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach((item) =>
      this.updateCheckedSet(item.id, value)
    );
    this.refreshCheckedStatus();
  }
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every((item) =>
      this.setOfCheckedId.has(item.id)
    );
    this.indeterminate =
      this.listOfCurrentPageData.some((item) =>
        this.setOfCheckedId.has(item.id)
      ) && !this.checked;
  }

  checked = false;
  indeterminate = false;
  listOfCurrentPageData: readonly ItemData[] = [];
  listOfData: ApplicationUserResponse[] = [];
  setOfCheckedId = new Set<number>();


  generateInitials(companyName: string): string {
    if (!companyName) return '';

    return companyName[0].toUpperCase();
  }

  internalUserAvatarText(firstName: string, lastName: string): string {
    // Extract first letter of first name and last name
    const initials = (firstName?.charAt(0) || '') + (lastName?.charAt(0) || '');
    return initials.toUpperCase();
  }


  cancelEventScratchFormModal() {
    this.cancel.emit()
  }

}
