<nz-modal
  [(nzVisible)]="isVisibles"
  nzWidth="46%"
  [nzFooter]="modalFooter"
  [nzTitle]="nzModalTitle"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '12px',
    'max-height': '64vh',
    'overflow-y': 'auto'
  }"
  (nzOnCancel)="handleCancels()"
  (nzOnOk)="handleOk()"
>
  <ng-template #nzModalTitle>
    <h1 style="font-weight: bolder;">Lead Email Wizard</h1>
    <div class="modal-header">
      <nz-steps [nzCurrent]="currentStep" nzSize="small" class="custom-steps">
        <nz-step nzTitle="Options"></nz-step>
        <nz-step nzTitle="Compose"></nz-step>
      </nz-steps>
    </div>
  </ng-template>

  <ng-container *nzModalContent>
    <nz-alert
      class="mb-3 error-message"
      *ngIf="isSubmitted && errorFields.length > 0"
      nzType="error"
      nzShowIcon
      [nzMessage]="message"
      [nzDescription]="errorDescriptionTemplate"
    ></nz-alert>

    <ng-template #message>
      <p class="mt-1">Please correct the following fields:</p>
    </ng-template>

    <ng-template #errorDescriptionTemplate>
      <ul>
        <li class="sub-text" *ngFor="let error of errorFields">
          <span class="d-flex align-items-center">
            <p>{{ error.label }}:</p>
            <p>{{ error.message }}</p>
          </span>
        </li>
      </ul>
    </ng-template>

    <ng-container *ngIf="showInitialCard">
      <!-- Initial Content -->

      <nz-card class="mt-0" style="height: 34vh">
        <div class="form-group">
          <div class="row">
            <div class="col">
              <label class="form-label">Send Message</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <!-- Radio Button for 'Now' -->
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  id="flexRadioDefault1"
                  name="flexRadioDefault"
                  [(ngModel)]="selectedOption"
                  [value]="'now'"
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  Now
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-2">
              <!-- Radio Button for 'Later' -->
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  id="flexRadioDefault2"
                  name="flexRadioDefault"
                  [(ngModel)]="selectedOption"
                  [value]="'later'"
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  Later
                </label>
              </div>
            </div>

            <div class="col-3 me-2">
              <!-- Ng Zorro Date Picker, enabled or disabled based on radio selection -->
              <nz-date-picker
                [(ngModel)]="sendDate"
                name="sendDate"
                class="formField"
                [disabled]="selectedOption !== 'later'"
              ></nz-date-picker>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <label class="form-label">Activity Type*</label>
            </div>
          </div>
          <div class="row d-flex align-items-center">
            <div class="col-10 mr-0 pr-0">
              <nz-select
                [(ngModel)]="formFieldValues['activityTypeId']"
                name="activityTypeId"
                [style.width.%]="100"
                (ngModelChange)="changeActivityType($event)"
              >
                <nz-option
                  *ngFor="let option of leadActivityType"
                  [nzLabel]="option.name"
                  [nzValue]="option.id"
                ></nz-option>
              </nz-select>
            </div>
            <div class="col-2 d-flex">
              <button
                nz-button
                nzType="link"
                (click)="addNewLeadActivityModal()"
              >
                <a>Add</a>
              </button>
              <button
                nz-button
                nzType="link"
                (click)="editNewLeadActivityModal()"
              >
                <a>Edit</a>
              </button>
            </div>
          </div>
        </div>
      </nz-card>
    </ng-container>

    <ng-container *ngIf="showNewDataCard">
      <!-- New Data Content -->
      <nz-card>
        <div class="row mt-1">
          <div class="col-3">
            <h6 class="famly-inn fw-medium">Compose Message</h6>
          </div>
          <div class="col-7"></div>
          <div class="col-2 p-0">
            <span class="mail-merge-info">
              Mail Merge
              <i
                class="bi bi-info-circle-fill text-secondary"
                nz-popover
                [nzPopoverContent]="contentTemplate"
                nzPopoverPlacement="left"
              ></i>
              <ng-template #contentTemplate>
                <div class="popover-content">
                  <h4>Mail Merge Template</h4>
                  <p>
                    Update a lead email with placeholder text. Use the fields
                    below as placeholders, and we will populate them before the
                    email is sent:
                  </p>
                  <ul>
                    <li>
                      <strong>[[FirstName]] [[LastName]]</strong>: The Customer
                      Contact's First and Last Name for that specific lead is
                      populated.
                    </li>
                    <li>
                      <strong>[[DisplayName]]</strong>: The Customer Contact's
                      Display Name for that specific lead is populated.
                    </li>
                    <li>
                      <strong>[[Signature]]</strong>: The signature text and/or
                      image is populated for the Lead's assigned Salesperson.
                      The signature text and image can be set up for each
                      Salesperson in User Preferences.
                    </li>
                  </ul>
                  <p>
                    <strong>Example:</strong> You want to set up an email drip
                    campaign that can be used for any of your leads. To avoid
                    personalizing each lead email greeting or signature, set up
                    a lead activity template with placeholder fields. For
                    instance, "Dear [[DisplayName]]" would populate the Lead's
                    name into the email message before sending, and show as
                    "Dear John Smith".
                  </p>
                </div>
              </ng-template>
            </span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label class="sub-text">Subject</label>
            <input
              [(ngModel)]="formFieldValues['subject']"
              name="subject"
              type="text"
              (ngModelChange)="onFieldChange('subject', $event)"
              class="formField form-control"
              #subjectInput="ngModel"
              [ngClass]="{
                'border-danger': validationStates['subject'] === 'error'
              }"
              required
            />
            <div
              *ngIf="subjectInput.touched || isSubmitted"
              class="text-danger"
            >
              <div *ngIf="validationStates['subject'] === 'error'">
                {{ validationMessages["subject"] }}
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12">
            <label class="sub-text">Send To </label>
            <h5>{{ primaryEmail }}</h5>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12">
            <label class="sub-text">Body</label>
            <div
              class="editor-container"
              [ngClass]="{
                'border-danger': validationStates['body'] === 'error'
              }"
            >
              <!-- <kendo-editor
                [(ngModel)]="formFieldValues['body']"
                name="body"
                [style]="{ 'flex-direction': 'column-reverse' }"
                (ngModelChange)="onFieldChange('body', $event)"
              ></kendo-editor> -->

              <ckeditor
              [(ngModel)]="formFieldValues['body']"
              name="notes"
              [editor]="Editor"
              [config]="config"
              class="size"
              id="editor"
            ></ckeditor>

            </div>
            <div
              *ngIf="validationStates['body'] === 'error'"
              class="text-danger"
            >
              {{ validationMessages["body"] }}
            </div>
          </div>
        </div>




        <div class="card border-0 mt-3">
          <div class="card-head border-bottom">
            <h3 class="p-0 m-0 pt-2 pb-2 ms-3 react" nz-typography>
              Attachments
            </h3>
          </div>
          <div class="card-body bt-file-viewer-card">
            <div class="row">
              <div class="col-12">
                <button
                  nz-button
                  class="button-default react"
                  (click)="uploadFiles()"
                >
                  <span>Add</span>
                </button>
                <button
                  nz-button
                  class="button-default react ms-2"
                  (click)="CreateNewFile()"
                >
                  <span>Create New File</span>
                </button>
                <button
                  *ngIf="selectedFiless?.length > 0"
                  nz-button
                  class="button-default react ms-2"
                  (click)="viewAll()"
                >
                  <span>View All ({{ selectedFiless?.length }})</span>
                </button>
              </div>
            </div>
            <i
              *ngIf="showNavButtons"
              class="bx bx-chevron-left carousel-nav prev"
              (click)="previous()"
            ></i>
            <i
              *ngIf="showNavButtons"
              class="bx bx-chevron-right carousel-nav next"
              (click)="next()"
            ></i>
            <div class="carousel-container" #carouselContainer>
              <div
                class="carousel-view"
                *ngFor="let fileq of selectedFiless"
              >
                <div class="bt-file-viewer">
                  <img
                    class="img-1"
                    *ngIf="getFileIcon(fileq)"
                    [src]="getFileIcon(fileq)"
                  />
                  <img
                    (click)="onClick()"
                    class="img-2"
                    *ngIf="
                      !getFileIcon(fileq) &&
                      !fileq.type.includes('video')
                    "
                    [src]="fileq.thumbUrl"
                  />
                  <ng-container *ngIf="fileq.type.includes('video')">
                    <div class="video-container">
                      <i
                        nz-icon
                        nzType="play-circle"
                        nzTheme="outline"
                        class="video-play-icon"
                        (click)="playVideo(fileq)"
                      ></i>
                      <video
                        #videoPlayer
                        id="video-{{ fileq.id }}"
                        [src]="fileq.thumbUrl"
                        (click)="playVideo(fileq)"
                      ></video>
                    </div>
                  </ng-container>
                  <div nz-row class="action-row">
                    <div nz-col nzSpan="24">
                      <label
                        class="BTFileUpload-SecondaryText p-0 m-0 ms-2"
                        >{{ fileq.name }}</label
                      >
                    </div>
                    <div nz-col nzSpan="5">
                      <i
                        style="
                          margin: 2px 0px 0px 6px;
                          cursor: pointer;
                          font-size: 21px;
                          color: #626262;
                        "
                        nzTrigger="click"
                        nz-dropdown
                        [nzDropdownMenu]="menu4"
                        class="bx bxs-chevron-down"
                      ></i>
                      <nz-dropdown-menu #menu4="nzDropdownMenu">
                        <ul nz-menu style="width: 170px">
                          <li nz-menu-item (click)="removeFile(fileq)">
                            <div class="dropdown-container">
                              <i
                                style="
                                  cursor: pointer;
                                  font-size: 20px;
                                  color: #626262;
                                "
                                class="bx bx-trash"
                              ></i>
                              <span class="react">Delete</span>
                            </div>
                          </li>
                          <li
                            nz-menu-item
                            (click)="uploadFilesUpdate(fileq)"
                            *ngIf="isImageFile(fileq)"
                          >
                            <div class="dropdown-container">
                              <i
                                style="
                                  cursor: pointer;
                                  font-size: 20px;
                                  color: #626262;
                                "
                                class="bx bxs-pencil"
                              ></i>
                              <span class="react">Edit Online</span>
                            </div>
                          </li>
                        </ul>
                      </nz-dropdown-menu>
                    </div>
                    <div nz-col nzSpan="4" *ngIf="isImageFile(fileq)">
                      <i
                        (click)="uploadFilesUpdate(fileq)"
                        style="
                          cursor: pointer;
                          font-size: 26px;
                          color: #626262;
                        "
                        class="bx bxs-pencil"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </nz-card>
    </ng-container>

    <ng-container *ngIf="Showemail">
      <nz-card class="" style="height: 25vh">
        <div class="row mt-1">
          <div class="col-3">
            <div class="row mt-3">
              <div class="col-12 mt-2">
                <label class="sub-text">Send To </label>
                <h5 style="color: #202227; overflow-wrap: break-word">
                  {{ primaryEmail }}
                </h5>
              </div>
            </div>

          </div>
        </div>
        <div class="row mt-1">
          <div class="col mt-3">
            <button
              nz-button
              nzType="default"
              (click)="composeNewMessage()"
              class="large-button "
            >
              Continue with your own email client
            </button>
          </div>
        </div>
      </nz-card>
    </ng-container>
  </ng-container>

  <ng-template #modalFooter>
    <div class="modal-footer">
      <!-- Left side buttons (Back button) -->
      <div class="left-buttons">
        <button
          *ngIf="showBackButton"
          class="text-start"
          nz-button
          nzType="default"
          (click)="showInitialCardView()"
        >
          Back
        </button>
      </div>

      <!-- Right side buttons (Send button and Dropdown button) -->
      <div class="right-buttons">
        <!-- Send button visibility controlled by sendButtonVisible flag -->
        <button
          *ngIf="sendButtonVisible"
          nz-button
          nzType="primary"
          (click)="sendEmaillead()"
        >
          Send
        </button>

        <!-- Dropdown button visibility controlled by showInitialCard flag -->
        <button
          *ngIf="showInitialCard"
          nz-button
          nzType="primary"
          [nzTrigger]="'click'"
          nz-dropdown
          [nzDropdownMenu]="menu"
        >
          Compose
          <span nz-icon nzType="down"></span>
        </button>
      </div>
    </div>

    <!-- Dropdown menu -->
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item *ngIf="showInitialCard" (click)="showOnlineCard()">
          <a>Online</a>
        </li>
        <li nz-menu-item *ngIf="showInitialCard" (click)="clickshowwmial()">
          <a>With My Email</a>
        </li>
      </ul>
    </nz-dropdown-menu>
  </ng-template>
</nz-modal>
