<div class="card border-0 mt-0 p-4">
  <div class="card-head">
      <div class="row">
          <div class="col-6">
            <span class="sub-text" style="letter-spacing: 2px;">RECENT ACTIVITY FROM YOUR TEAM</span>
          </div>
          <div class="col-6 text-end">
            <button nz-button nzType="default"  (click)="openFilter(Job_Summary,PAGE_ID)" class="rounded-1" ><i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter</button>
          </div>
       </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
          <div class="row">
              <div class="col-12">
                <h3 nz-typography>Feb 1, 2024</h3>
              </div>
            </div>
          <div class="ant-divider ant-divider-horizontal" role="separator"></div>
          <div class="row">
            <div class="col-12" style="padding: 0px 40px 20px 40px;">
             <div class="row gx-0  border-bottom border-2 pb-1">
            <div class="col-1">
              <nz-avatar style="background-color:  rgb(139, 139, 139);;" nzText="A"></nz-avatar>
            </div>
            <div class="col-11">
              <div class="row">
                <div class="col-12 ListViewJobname">ZH-075 Lot 1909 Cosgrove street Tarneit VIC 3029 <span class="ms-1" >Feb 1, 2024, 6:52 PM</span></div>
              </div>
              <div class="row">
                <div class="col-12"><span class="sub-text">Accounts Assistant approved a Purchase Order</span></div>
              </div>
              <div class="row mt-2 p-2">
                <div class="col-12"><h3 nz-typography>ZH-75COS-5006 - Client Refund</h3></div>
              </div>
            </div>
             </div>
             <div class="row gx-0 mt-3 mb-3  border-bottom border-2 pb-1">
            <div class="col-1">
              <nz-avatar style="background-color:  rgb(139, 139, 139);;" nzText="A"></nz-avatar>
            </div>
            <div class="col-11">
              <div class="row">
                <div class="col-12 ListViewJobname">ZH-075 Lot 1909 Cosgrove street Tarneit VIC 3029 <span class="ms-1" >Feb 1, 2024, 6:52 PM</span></div>
              </div>
              <div class="row">
                <div class="col-12"><span class="sub-text">Accounts Assistant approved a Purchase Order</span></div>
              </div>
              <div class="row mt-2 p-2">
                <div class="col-12"><h3 nz-typography>ZH-75COS-5006 - Client Refund</h3></div>
              </div>
              <div class="row mt-2 p-2">
               <textarea type="text " class="form-control formField">
                </textarea>
              </div>
            </div>
             </div>
            </div>
          </div>
        <div class="ant-divider ant-divider-horizontal" role="separator"></div>
        <div class=" FeedFooter padding-bottom-sm mt-5">No Recent Activity</div>
    </div>
  </div>
</div>