import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { SubVendersService } from 'src/Service/SubVendorsServices/sub-venders.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';

@Component({
  selector: 'app-notify-users',
  templateUrl: './notify-users.component.html',
  styleUrls: ['./notify-users.component.css']
})
export class NotifyUsersComponent implements OnInit {
 
  scheduleVisible : boolean = true;

  @Output() cancel = new EventEmitter<void>();
  assgindata: NzTreeNodeOptions[] = [];
  subVendorData: NzTreeNodeOptions[] = [];
  customerInformationData: NzTreeNodeOptions[] = [];
  internalUserNodes: NzTreeNodeOptions[] = [];
  combinedNodes: NzTreeNodeOptions[] = [];
  combinedNodesMessaging: NzTreeNodeOptions[] = [];
  selectedNodes: NzTreeNodeOptions[] = [];

constructor(
  private customerInformationService: CustomerInformationService,
  private SubVendorService: SubVendersService,


){}

  ngOnInit(): void {


  }


  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      const assgindata = res.result;
      this.assgindata = assgindata.map((vendor) => ({
        title: vendor.fullName,
        key: vendor.id.toString(),
        isLeaf: true,
        isExpanded: true, // Set isExpanded to true for each node
        expanded: true, // Set expanded to true for each node if required by your tree component
        applicationUserId: vendor.applicationUserId
      }));
      this.combineNodes();
    });

    this.SubVendorService.getData().subscribe((res) => {
      const Subvendor = res.result;
      this.subVendorData = Subvendor.map((vendor) => ({
        title: vendor.companyName,
        key: vendor.id.toString(),
        isLeaf: true,
        isExpanded: true, // Set isExpanded to true for each node
        expanded: true, // Set expanded to true for each node if required by your tree component
        subVendorId: vendor.subVendorId
      }));
      this.combineNodes();
    });

    this.customerInformationService.getData().subscribe((res) => {
      const Subvendor = res.result;
      this.customerInformationData = Subvendor.map((customer) => ({
        title: customer.displayName,
        key: customer.id.toString(),
        isLeaf: true,
        isExpanded: true, // Set isExpanded to true for each node
        expanded: true, // Set expanded to true for each node if required by your tree component
        customerInformationId: customer.customerinformationId
      }));
      this.combineNodes();
    });
  }

  combineNodes(): void {
    if (this.assgindata && this.subVendorData && this.customerInformationData) {
      const internalUsersNode = {
        title: 'internalUser',
        value: '0-0',
        key: '0-0',
        children: this.assgindata,
      };

      const subVendorsNode = {
        title: 'Subs/Vendors',
        value: '0-1',
        key: '0-1',
        children: this.subVendorData,
      };
      const customerInformationNode = {
        title: 'Owners',
        value: '0-2',
        key: '0-2',
        children: this.customerInformationData,
      };

      this.combinedNodes = [internalUsersNode, subVendorsNode];
      this.combinedNodesMessaging = [internalUsersNode, subVendorsNode, customerInformationNode];
    }
  }


  onNodeSelectionChange(selectedNodeKeys: string[]): void {
    if (selectedNodeKeys && selectedNodeKeys.length > 0) {
      const selectedNode = this.combinedNodes.find(node => node.key === selectedNodeKeys[0]);
      const combinedNodesMessaging = this.combinedNodesMessaging.find(node => node.key === selectedNodeKeys[0]);

      if (selectedNode && combinedNodesMessaging) {
        this.selectedNodes.push(selectedNode); // Add selected node data to selectedNodes array
        // this.formFieldValues.assigneeUserId = selectedNode['applicationUserId'] || '';
        // this.formFieldValues.assigneeSubVendorId = selectedNode['subVendorId'] || '';
        // this.messagesFormValue.applicationUserId = combinedNodesMessaging['applicationUserId'] || '';
        // this.messagesFormValue.customerInformationId = combinedNodesMessaging['customerInformationId'] || '';
        // this.messagesFormValue.subVendorId = combinedNodesMessaging['subVendorId'] || '';
      }
    } else {
      // Clear the values if no node is selected
      // this.formFieldValues.assigneeUserId = '';
      // this.formFieldValues.assigneeSubVendorId = '';
      // this.messagesFormValue.applicationUserId = '';
      // this.messagesFormValue.customerInformationId = '';
      // this.messagesFormValue.subVendorId = '';
    }
  }

  CancelSchedule() {
    this.cancel.emit();
  }

}
