import { catchError, filter } from 'rxjs/operators';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzSelectSizeType } from 'ng-zorro-antd/select';
import { ProjectManagementSiteDiariesAddTagComponent } from '../project-management-site-diaries-add-tag/project-management-site-diaries-add-tag.component';
import { ProjectManagementSiteDiariesRelatedToDoStComponent } from '../project-management-site-diaries-related-to-do-st/project-management-site-diaries-related-to-do-st.component';
import { ProjectManagementSiteDiariesUploadFilesComponent } from '../project-management-site-diaries-upload-files/project-management-site-diaries-upload-files.component';
import { ProjectManagementSiteDiariesCreateFilesComponent } from '../project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { ProjectManagementSiteDiariesMapComponent } from '../project-management-site-diaries-map/project-management-site-diaries-map.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/Service/CompanyInFormation/modal.service';
import { colorService } from 'src/Service/Job-List/Job-Information/color.service';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { AttachmentResponse, CreateAttachmentForDocumentRequest, CreateMessagingRequest, LeadOpportunityResponse, LookupNameSetupResponse } from 'src/Models/LeadTags';
import { ProjectManegmentSchedulePreDefinedCodes, ProjectManegmentSiteDiriesPreDefinedCodes, ProjectManegmentToDosPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { ApplicationUserResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { SubVendersService } from 'src/Service/SubVendorsServices/sub-venders.service';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { SiteDiariesService } from 'src/Service/Project-Management/site-diaries.service';
import { ToastService } from 'src/shared/Shared-Services/toast.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { LeadOpportunityAttachmentsUploadFilesComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { JobInformationResponse } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { focusableSelector } from '@progress/kendo-angular-common';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { DailyLogInformationResponse } from 'src/Models/Project-Management/projectManagement';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { AttachmentAnnotationComponent } from 'src/app/lead-opportunities/Components/attachment-annotation/attachment-annotation.component';
import { NzImageService } from 'ng-zorro-antd/image';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';


// A**
interface CustomUploadFile extends NzUploadFile {
  loading?: boolean;
  error?: boolean;
  addedBy: number
  createdOn: Date;
  modifiedOn: Date;
  id: number;
  globalId: string;
}

@Component({
  selector: 'app-project-management-site-diaries-new-daily-log',
  templateUrl: './project-management-site-diaries-new-daily-log.component.html',
  styleUrls: ['./project-management-site-diaries-new-daily-log.component.css']
})
export class ProjectManagementSiteDiariesNewDailyLogComponent implements OnInit{
  @Input()  dailyLogResponse: DailyLogInformationResponse;

  @ViewChild('carouselContainer', { static: false }) carouselContainer!: ElementRef<HTMLDivElement>;


  jobID: number;
  formNameID: number = 5;
  mesageingHeadId: number;
  // sufyan working

  dailyLog:FormGroup;
  formFieldValues: any = {};
  // selectedItem: JobInformationResponse;
  selectedItem: JobInformationResponse[] = [];
  tagNodes: NzTreeNodeOptions[] = [];
  combinedNodes: NzTreeNodeOptions[] = [];
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  internalUserNodes: NzTreeNodeOptions[] = [];
  subVendorsNodes: NzTreeNodeOptions[] = [];
  userList: ApplicationUserResponse[] = [];
  vendorList: SubVendorResponse[] = [];
  selectedFiles: File[] = [];
  pageId: number = 5;
  selectedLeadId: any;
  jobGroupVisible: boolean = false;
  dailyLogsVisible = true;
  isLoadingEdit: boolean = false;
  TagsForm: FormGroup;
  isLoadingOne: boolean = false;
  isDeleteTagsLoading: boolean = false;
  selectedLead: LeadOpportunityResponse;
  confirmModal?: NzModalRef;
  public ModalTitle: string = '';
  jobList: JobInformationResponse[] = [];
  assgindata: NzTreeNodeOptions[] = [];
  subVendorData: NzTreeNodeOptions[] = [];
  OwnerData: NzTreeNodeOptions[] = [];
  attachments: AttachmentResponse[] = [];
  mainSaveResponseId: number = -1;  // change sufyan
  internal: boolean = true;
  owner: boolean = false;
  subsVendors: boolean = false;
  NewDailyhandle=true;
  isCheckboxChecked1 = false;
  isCheckboxChecked2 = false;
  isRowVisible: boolean = false;
  selectedValue = null;
  date = null;


  selectedFiless: CustomUploadFile[] = [];
  showNavButtons = false;
  currentIndex = 0;

  city: string = '';  // Declare city as a class property


  selectedNodes: any;
  privateChecked: boolean = this.owner && this.internal && this.subsVendors;
  previousSelections: { owner: boolean, internal: boolean, subsVendors: boolean } = { owner: false, internal: false, subsVendors: false };
  @Output() cancel = new EventEmitter<void>();
  checked = true;
  preDefinedDataCodes = {
    tags: ProjectManegmentSiteDiriesPreDefinedCodes.Tags,
  };


 


constructor(private modal: NzModalService,
  private http : HttpClient,

  private modalService: ModalService,
  private internalUserService: InternalUserService,
  private subVendorsService: SubVendersService,
  private LookupFilterService: LookupFilterService,
  private PMSSiteService: SiteDiariesService,
  public toastsService: NzMessageService,
  private loadingIndicatoreService: LoadingIndicatorService,
  private leadOppService: LeadOpportunitiesService,
  public toastService: NzMessageService,
  private fb: FormBuilder,
  private jobsService: JobInformationService,
  private customerInformationService: CustomerInformationService,
  private SubVendorService: SubVendersService,
  private LeadOpportunitiesService: LeadOpportunitiesService,
  private cd: ChangeDetectorRef,
  private nzImageService: NzImageService,


  @Inject(NZ_MODAL_DATA) public JobData: any,
  @Inject(NZ_MODAL_DATA) public leadData: any,public localStorage: AccessLocalStorageService,)
   {}

ngOnInit(): void {


  this.leadProFormGroup();

  console.log('dailyLogResponse TIME',this.dailyLogResponse);

  this.formFieldValues['globalId'] = this.dailyLogResponse?.globalId;
  // this.formFieldValues['jobInformationId'] = this.dailyLogResponse?.jobInformationId.toString();
  this.formFieldValues['jobInformationId'] = this.dailyLogResponse?.jobInformationId?.toString();
  // this.formFieldValues['reminderId'] = this.leadData.activityData.reminderId;
  this.formFieldValues['title'] = this.dailyLogResponse?.title;
  this.formFieldValues['date'] = this.dailyLogResponse?.date;
  this.formFieldValues.createDailyLogTagParameterRequests = this.dailyLogResponse?.dailyLogTagParameters.map(param => param.dailyLogTagSetupId.toString());
  this.formFieldValues['isPermissionInternalUser'] = this.dailyLogResponse?.isPermissionInternalUser;
  this.formFieldValues['isPermissionSubVendor'] = this.dailyLogResponse?.isPermissionSubVendor;
  this.formFieldValues['isPermissionOwner'] = this.dailyLogResponse?.isPermissionOwner;
  this.formFieldValues['isPermissionPrivate'] = this.dailyLogResponse?.isPermissionPrivate;
  // this.formFieldValues.createDailyLogNotifyUserRequest = this.dailyLogResponse?.dailyLogTagParameters.map(param => param.dailyLogTagSetupId.toString());
  this.formFieldValues['notes'] = this.dailyLogResponse?.notes;
  this.formFieldValues['isIncludeWeatherConditions'] = this.dailyLogResponse?.isIncludeWeatherConditions;
  this.formFieldValues['isIncludeWeatherNotes'] = this.dailyLogResponse?.isIncludeWeatherNotes;
  this.formFieldValues['weatherNotes'] = this.dailyLogResponse?.weatherNotes;
  this.formFieldValues['attachment'] = this.dailyLogResponse?.attachment;
  if (this.dailyLogResponse && this.dailyLogResponse.attachment && this.dailyLogResponse.attachment.attachmentParameters) {
    this.attachments = this.dailyLogResponse.attachment.attachmentParameters.map(attachment => ({
      attachmentId: attachment.attachmentId,
      name: attachment.name,
      extension: attachment.extension,
      size: attachment.size,
      filePath: attachment.filePath + '\\D:\\CmsFrontEnd\\BackupConstructaion\\Files\\Uploads\\Documents',
      createdBy: attachment.createdBy,
      createdOn: attachment.createdOn,
      modifiedBy: null,
      modifiedOn: null,
      isActive: true,
      id: null,
      globalId: null
    }));
  } else {
    this.attachments = [];
  }

  this.jobsService.getAllLeadData().subscribe((res: any) => {
    this.jobList =res.result;
  })
  this.leadOppService.getSelectedFiles().subscribe(files => {
    this.selectedFiles = files;
  });
  this.ForCombo();
  this.iniliazeForm();
  this.initLoad();
  this.jobID = this.localStorage.getJobId(); // sufyan add working !!
  const jobId = this.localStorage.getJobId();
  const dropDownJobId = this.leadData?.leadData?.id;

  if (dropDownJobId) {
      this.formFieldValues.jobInformationId = dropDownJobId | 0;
  } else if (dropDownJobId) {
    this.formFieldValues.jobInformationId = jobId | 0;
  } else {
      this.formFieldValues.jobInformationId = 0;
  }



  this.localStorage.getJobName();
}



  weatherData: any; // To store the full response
  wind: number | undefined;
  precipitation: number | undefined;  humidity: number = 0; // To store humidity
  conditionText: string = '';


// A**


getWeather(): void {
  const city = 'Karachi'; // Example city, you can replace it with dynamic input
  const apiKey = '47c32bf7d8msh56a6e680e754763p155991jsn80a529846e81';
  const apiUrl = `https://weatherapi-com.p.rapidapi.com/current.json?q=${city}`;

  const headers = new HttpHeaders({
    'x-rapidapi-key': apiKey,
    'x-rapidapi-host': 'weatherapi-com.p.rapidapi.com'
  });

  this.http.get(apiUrl, { headers }).subscribe(
    (data) => {
      this.weatherData = data;
      console.log('Weather Data:', this.weatherData);
      this.wind = this.weatherData?.current?.wind_kph;
      this.precipitation = this.weatherData?.current?.precip_mm;      this.humidity = this.weatherData.current.humidity; // Humidity
      this.conditionText = this.weatherData.current.condition.text; // Condi
    },
    (error) => {
      console.error('Error fetching weather data', error);
    }
  );
}


// A**










ClosedailyLogInformation(): void {
  this.cancel.emit();
}
ForCombo() {
  this.customerInformationService.getDataforcombo().subscribe((res) => {
  const assgindata = res.result;
  this.assgindata = assgindata.map((vendor) => ({
    title: vendor.fullName,
    key: vendor.id.toString(),
    isLeaf: false,
    applicationUserId: vendor.applicationUserId
  }));
  this.combineNodes();
  this.SubVendorService.getData().subscribe((res) => {
    const Subvendor = res.result;
    this.subVendorData = Subvendor.map((vendor) => ({
      title: vendor.companyName,
      key: vendor.id.toString(),
      isLeaf: false,
      subVendorId: vendor.subVendorId
    }));
  this.combineNodes();
  });
  this.customerInformationService.getData().subscribe((res) => {
    const ownerdata = res.result;
    this.OwnerData =  ownerdata.map((vendor) => ({
      title: vendor.firstName,
      key: vendor.id.toString(),
      isLeaf: false,
      customerinformationId: vendor.customerinformationId
      }));
    this.combineNodes();
  });
})
}
combineNodes(): void {
  if (this.assgindata && this.subVendorData && this.OwnerData) {
    const OwnerNode = {
        title: 'Owner111',
        value: '0-2',
        key: '0-2',
        children: this.OwnerData
    };
    let OwnersNode: NzTreeNodeOptions | null = null;
    let internalUsersNode: NzTreeNodeOptions | null = null;
    let subsVendorsNode: NzTreeNodeOptions | null = null;
    if (this.internal) {
        internalUsersNode = {
            title: 'internalUser321',
            value: '0-0',
            key: '0-0',
            children: this.assgindata
        };
    }
    if (this.subsVendors) {
      subsVendorsNode = {
          title: 'Subs/Vendors123',
          value: '0-1',
          key: '0-1',
          children: this.subVendorData
        };
    }
    if (this.owner) {
      OwnersNode = {
          title: 'Owner111',
          value: '0-2',
          key: '0-2',
          children: this.OwnerData
        };
    }
    this.combinedNodes = [OwnersNode, internalUsersNode, subsVendorsNode].filter(node => node);
    console.log('Combined Nodes:', this.combinedNodes);
  }
}
onSubsVendors() {
  this.subsVendors = !this.subsVendors;
  this.combineNodes();
  this.updatePreviousSelections();
}
onOwner() {
  this.owner = !this.owner;
  this.combineNodes();
  this.updatePreviousSelections();
}
onInternal() {
  this.internal = !this.internal;
   this.combineNodes();
  if (!this.internal) {
      this.privateChecked = true;
  }
  this.combineNodes();
  this.updatePreviousSelections();
}
onPrivate() {
  this.privateChecked = !this.privateChecked;
    this.owner = false;
  this.internal = false;
  this.subsVendors = false;
  if (!this.privateChecked) {
      this.internal = true;
  }
  this.combineNodes();
  this.updatePreviousSelections();
}
updatePreviousSelections() {
  this.previousSelections.owner = this.owner;
  this.previousSelections.internal = this.internal;
  this.previousSelections.subsVendors = this.subsVendors;
  this.privateChecked = !this.internal;
}
onNodeSelectionChange(selectedNodeKeys: string[]): void {
  if (selectedNodeKeys && selectedNodeKeys.length > 0) {
      const selectedNode = this.combinedNodes.find(node => node.key === selectedNodeKeys[0]);
      if (selectedNode) {
          this.selectedNodes.push(selectedNode);
          this.formFieldValues.assigneeUserId = selectedNode['applicationUserId'] || '';
          this.formFieldValues.assigneeSubVendorId = selectedNode['subVendorId'] || '';
          this.formFieldValues.assigneeSustomerInformationId = selectedNode['customerInformationId'] || '';
      }
  } else {
      this.formFieldValues.assigneeUserId = '';
      this.formFieldValues.assigneeSubVendorId = '';
      this.formFieldValues.assigneeSustomerInformationId = '';
  }
}
NewDailyCancel(): void {
    this.cancel.emit();
}
leadProFormGroup(): void {
  const jobId = this.localStorage.getJobId();
  this.formFieldValues = {
    globalId: '00000000-0000-0000-0000-000000000000',
    jobSetupId: 1,
    jobInformationId: jobId || 0,
    date : new Date(),
    title  : '',
    notes : '',
    isIncludeWeatherConditions: false,
    isIncludeWeatherNotes: false,
    weatherNotes: '',
    isPermissionInternalUser: false,
    isPermissionSubVendor: false,
    isPermissionOwner: false,
    isPermissionPrivate: false,
    toDoId: 0,
    attachmentId: 0,
    createDailyLogTagParameterRequests: [],
    createDailyLogNotifyUserRequest: [],
  };
}
onLeadSelect(selectedLeadId: any): void {
      const selectedLead = this.jobList.find(lead => lead.id === selectedLeadId);
      this.leadData = { leadData: selectedLead };
      this.JobData = { JobData: selectedLead };
}
initLoad() {
  this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(
    (res) => {
      this.lookupSetNameResponse = res.result;
      // -----------Multi Select --------------
      let tagValues = res.result.filter(
        (x: { code: string; }) => x.code === this.preDefinedDataCodes.tags.code
      );
      // -----------single Select --------------
      this.tagNodes = tagValues.map((status: { name: any; id: { toString: () => any; }; }) => ({
        title: status.name,
        value: status.id.toString(),
        key: status.id.toString(),
        isLeaf: true,
      }));
    }
  );
}

// A**

onResize() {
  this.updateNavButtonsVisibility();
}



playVideo(file: CustomUploadFile): void {
  const videoElement: HTMLVideoElement = this.carouselContainer.nativeElement.querySelector(`#video-${file.id}`);
  if (videoElement) {
    videoElement.style.display = 'block'; // Show the video player
    videoElement.play();
  }
}



private updateNavButtonsVisibility() {
  requestAnimationFrame(() => {
    const container = this.carouselContainer.nativeElement;
    if (this.selectedFiless.length > 0) {
      this.showNavButtons = container.scrollWidth > container.clientWidth;
    } else {
      this.showNavButtons = false;
    }
    this.cd.detectChanges();
  });
}

uploadFiles(): void {
  const modalRef = this.modal.create({
    nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
  modalRef.componentInstance.UploadFile.subscribe((data: CustomUploadFile[]) => {
    data.filter(file => this.isImageFile(file));
    this.selectedFiless = [...data, ...this.selectedFiless];
    this.onResize();
  });
}

isImageFile(file: CustomUploadFile): boolean {
  const fileName = file.name.toLowerCase();
  const fileType = file.type;

  return (
    fileType === 'image/png' ||
    fileType === 'image/jpeg' ||
    fileName.endsWith('.png') ||
    fileName.endsWith('.jpeg')
  );
}

scrollToCurrentIndex() {
  const container = this.carouselContainer.nativeElement;
  const scrollAmount = this.currentIndex * 130; // 120px (width) + 10px (margin)
  container.scrollTo({ left: scrollAmount, behavior: 'smooth' });
}

previous() {
  if (this.currentIndex > 0) {
    this.currentIndex--;
    this.scrollToCurrentIndex();
  }
}


next() {
  const container = this.carouselContainer.nativeElement;
  const maxIndex = this.selectedFiless.length - Math.floor(container.clientWidth / 130);
  if (this.currentIndex < maxIndex) {
    this.currentIndex++;
    this.scrollToCurrentIndex();
  }
}


onClick(): void {
  let filteredImages = this.selectedFiless.filter(f =>
    f.name.includes('png') || f.name.includes('jpeg')
  );
    const images = filteredImages.map(file => ({
      src: file.thumbUrl,
      alt: file.name
    }));
    this.nzImageService.preview(images,  {
      nzZoom: 1.0,
      nzRotate: 0
    });
}


getFileIcon(file: CustomUploadFile): string | null {
  const fileType = file.type;
  if (fileType.includes('pdf')) {
    return 'assets/Attachment/documentPDF.svg';
  } else if (fileType.includes('excel') || fileType.includes('spreadsheet')) {
    return 'assets/Attachment/documentXLS.svg';
  } else if (fileType.includes('word')) {
    return 'assets/Attachment/documentDOC.svg';
  } else if (fileType.includes('zip')) {
    return 'assets/Attachment/documentZIP.svg';
  } else if (fileType.includes('text')|| fileType.includes('json')) {
    return 'assets/Attachment/documentGeneric.svg';
  } else if (fileType.includes('ppt') || fileType.includes('presentation')) {
    return 'assets/Attachment/documentPPT.svg';
  } else if (fileType.includes('video')) {
    return null;
  } else if (fileType.includes('image')) {
    return null;
  } else {
    return null;
  }
}


CreateNewFile(): void {
  const modalRef = this.modal.create({
    nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
}
viewAll(): void {
  const modalRef = this.modal.create({
    nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.selectedFilessResponse = this.selectedFiless;
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
  modalRef.componentInstance.removeFile.subscribe((file: CustomUploadFile) => {
    this.removeFile(file);
  });
  modalRef.componentInstance.Test.subscribe((data: CustomUploadFile[]) => {
    this.selectedFiless = [...data, ...this.selectedFiless];
    this.onResize();
  });
}

removeFile(file: CustomUploadFile): void {
  this.selectedFiless = this.selectedFiless.filter(item => item.uid !== file.uid);
}

imageData: string;

uploadFilesUpdate(fileq: CustomUploadFile): void {
  const modalRef = this.modal.create({
    nzContent: AttachmentAnnotationComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.DrawerImge = fileq;
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
  modalRef.componentInstance.imageDataRes.subscribe((data: any) => {
    const newImageUrl = data;
    let test1234 = this.selectedFiless.forEach(activity => {
      activity.thumbUrl = newImageUrl;
    });
    console.log(test1234);
  });
}



// A** End






saveButton() {
  let data = this.formFieldValues;
  let dailyLog = this.formFieldValues.createDailyLogTagParameterRequests.map(
    (id: string)  =>
    ({ dailyLogTagSetupId: id})
  );
  this.formFieldValues.createDailyLogTagParameterRequests = dailyLog;
  console.log('Save Data', this.formFieldValues)
  let viewerParams = this.formFieldValues.createDailyLogNotifyUserRequest;
  let transformedParams = [];
  for (let i = 0; i < viewerParams.length; i += 2) {
    const applicationUserId = viewerParams[i];
    const subVendorId = viewerParams[i + 1];
    const customerInformationId = viewerParams[i + 1];
    if (applicationUserId && subVendorId & customerInformationId) {
      transformedParams.push({
        applicationUserId,
        subVendorId,
        customerInformationId,
    });
    }
  }
  data.createDailyLogNotifyUserRequest = transformedParams;
  this.PMSSiteService.postLeadData(data).subscribe(
    (response) => {
      this.toastService.success('New Lead Saved Successfully!');
      this.mainSaveResponseId = response.result.id;
      this.mesageingHeadId = this.mainSaveResponseId;
      this.RelatedModelOpen()
      if (this.selectedFiles && this.selectedFiles.length > 0) {
        const filesToUpload = this.selectedFiles.filter(file => file && file instanceof File);
        if (filesToUpload.length > 0) {
          const attachmentRequest: CreateAttachmentForDocumentRequest = {
            headerId: response.result.id,
            companyParameterId: 1,
            formNameId: 5,
            documentFiles: filesToUpload.map(file => file)
          };
          console.log('selectedFiles:', this.selectedFiles);
          this.leadOppService.CreateAttachmentForDocument(attachmentRequest).subscribe(
            (attachmentResponse) => {
              console.log('Attachment created successfully:', attachmentResponse);
              // Assuming attachmentResponse contains the file ID, you can save it to the database
              this.saveFileIdToDatabase(attachmentResponse.fileId);
            },
            (attachmentError) => {
              console.error('Error creating attachment:', attachmentError);
            }
          );
        } else {
          console.warn('No files to upload.');
        }
      }
    },
    (error) => {
      console.error('Error saving data:', error);
      alert('Error saving data. Please try again later.');
    }
  );
}
createMessage() {
  if (!this.mainSaveResponseId) {
    console.error('Main data not saved yet.');
    return;
  }
  console.log('mainSaveResponseId successfully:', this.mainSaveResponseId);

  const selectedUsers = this.formFieldValues['createMessagingUserNotifyParameterRequests'];

  const createMessagingRequest: CreateMessagingRequest = {
    headerId: this.mainSaveResponseId,
    formNameId: 5,
    comments: 'New Message',
    isViewOwner: true,
    isViewSubVendor: true,
    createMessagingUserNotifyParameterRequests: selectedUsers?.map(userId => ({ applicationUserId: userId }))
  };
  this.LeadOpportunitiesService.PostMessage(createMessagingRequest).subscribe(
    (messageResponse) => {
      console.log('Message created successfully:', messageResponse);
      this.toastService.success('Message created successfully!');
    },
    (messageError) => {
      console.error('Error creating message:', messageError);
    }
  );
}
saveFileIdToDatabase(fileId: string) {
}
showTags() {
  this.jobGroupVisible = true;
}
secondModelcancel() {
  this.jobGroupVisible = false;
}
SaveOrUpdateData() {
  if (this.isLoadingEdit) {
    this.editData();
  } else {
    this.SaveData();
  }
}
SaveData() {
  if (this.TagsForm.valid) {
    this.loadingIndicatoreService.show();
    this.isLoadingOne = true;
    setTimeout(() => {
      this.leadOppService.getLookUpNameSetup(this.TagsForm.value).subscribe(
        response => {
          this.loadingIndicatoreService.hide();
          this.jobGroupVisible = false;
          const tagName = this.TagsForm.get('name')?.value;
          this.initLoad();
          this.iniliazeForm();
          this.isLoadingOne = false;
          this.toastService.success(`${tagName} saved successfully`);
        },
        (error) => {
          this.loadingIndicatoreService.hide();
          if (error.status === 500) {
            this.toastService.error(
              'Internal Server Error. Please try again later.'
            );
            this.isLoadingOne = false;
          } else if (error.status === 400) {
            this.isLoadingOne = false;
            this.toastService.error('Bad Request. Please check your input.');
          } else {
            this.toastService.error(
              'Failed to save division trade. Please try again.'
            );
          }
        }
      );
    }, 1000);
  } else {
    this.TagsForm.markAllAsTouched();
  }
}
editData() {
  if (this.TagsForm.valid) {
    this.loadingIndicatoreService.show();
    const tagName = this.TagsForm.get('name')?.value;
    setTimeout(() => {
      this.leadOppService.getLookUpNameSetup(this.TagsForm.value).subscribe(
        (response: any) => {
          this.loadingIndicatoreService.hide();
          this.jobGroupVisible = false;
          this.initLoad();
          this.isLoadingEdit = false;
          this.toastService.success(`${tagName} Update successfully`);
        },
        (error) => {
          this.loadingIndicatoreService.hide();
          if (error.status === 500) {
            this.toastService.error(
              'Internal Server Error. Please try again later.'
            );
          } else if (error.status === 400) {
            this.toastService.error('Bad Request. Please check your input.');
          } else {
            this.toastService.error(
              'Failed to save division trade. Please try again.'
            );
          }
        }
      );
    }, 1000); // 2000 milliseconds (2 seconds)
  } else {
  }
}
iniliazeForm() {
  this.TagsForm = this.fb.group({
    id: [''],
    globalId: ['00000000-0000-0000-0000-000000000000'],
    name: ['', Validators.required],
    companyParameterId: [1],
    formNameId: this.pageId,
    lookUpStandardId: [],
    parentCode: ['PMSDDLT'],
    code: ['PMSDDLT'],
    description: [''],
    isFieldValue: [true],
  });
}
ConfirmDeleteTags(): void {
  this.confirmModal = this.modal.confirm({
    nzTitle: 'Delete Tags?',
    nzContent:
      'This Tags is not applied to any subs/vendors and will be deleted.',
    nzOkDanger: true,
    nzOkText: 'Delete',
    nzCancelText: 'Cancel',
    nzModalType: 'confirm',
    nzOkLoading: this.isDeleteTagsLoading,
    nzAutofocus: 'ok',
    nzCentered: true,
    nzOnOk: () => this.DeleteData(),
  });
}
DeleteData(): Promise<boolean> {
  const vendorId = this.TagsForm.get('globalId')?.value;
  const tagName = this.TagsForm.get('name')?.value;
  this.isDeleteTagsLoading = true;
  this.loadingIndicatoreService.show();
  return new Promise<boolean>((resolve, reject) => {
    this.leadOppService.deleteDataLookupNameSetup(vendorId).subscribe(
      (res: any) => {
        this.isDeleteTagsLoading = false;
        setTimeout(() => {
          this.toastService.success(`${tagName} Deleted successfully`);
          this.jobGroupVisible = false;
          this.loadingIndicatoreService.hide();
          this.confirmModal.destroy();
          resolve(true);
        }, 1000);
        this.isLoadingEdit = false;
        this.TagsForm.reset();
        this.jobGroupVisible = false;
        this.initLoad();
      },
      (error) => {
        this.isDeleteTagsLoading = false;
        this.jobGroupVisible = false;
        reject(false);
      }
    );
  });
}
showTagEdit(selectedValue: LookupNameSetupResponse, fieldType: string): void {
  if (selectedValue) {
    const selectedId: number = Number(selectedValue);
    this.jobGroupVisible = true;
    this.isLoadingEdit = true;
    const selectedObjectFromLookup = this.lookupSetNameResponse.find(item => item.id === selectedId);
    if (selectedObjectFromLookup) {
      const selectedData = selectedObjectFromLookup;
      if (selectedData) {
        this.TagsForm.controls['name'].setValue(selectedData.name);
        this.TagsForm.controls['globalId'].setValue(selectedData.globalId);
        this.TagsForm.controls['isFieldValue'].setValue(selectedData.isFieldValue);
        this.TagsForm.controls['companyParameterId'].setValue(selectedData.companyParameterId);
        this.TagsForm.controls['formNameId'].setValue(selectedData.formNameId);
        this.TagsForm.controls['lookUpStandardId'].setValue(selectedData.lookUpStandardId);
        this.TagsForm.controls['code'].setValue(selectedData.code);
        this.TagsForm.controls['description'].setValue(selectedData.description);
        this.TagsForm.controls['parentCode'].setValue(selectedData.parentCode);
      }
    }
  }
  switch (fieldType) {
    case 'Sources':
      this.ModalTitle = 'Add Lead Source';
      break;
    case 'ProjectType':
      this.ModalTitle = 'Add Project Type';
      break;
    case 'Tags':
      this.ModalTitle = 'Add Lead Tag';
      break;
    default:
      this.ModalTitle = 'Add/Edit Field';
      break;
  }
}
UploadFiles(): void {
  const modalRef = this.modal.create({
    nzContent: ProjectManagementSiteDiariesUploadFilesComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
}
// CreateNewFile(): void {
//   const modalRef = this.modal.create({
//     nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
//     nzFooter: null,
//   });
//   modalRef.componentInstance.cancel.subscribe(() => {
//     modalRef.destroy();
//   });
// }
// viewAll(): void {
//   const modalRef = this.modal.create({
//     nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
//     nzFooter: null,
//   });
//   modalRef.componentInstance.cancel.subscribe(() => {
//     modalRef.destroy();
//   });
// }
// removeFile(file: File): void {
//   const index = this.selectedFiles.indexOf(file);
//   if (index !== -1) {
//     this.selectedFiles.splice(index, 1);
//   }
// }
onChange(result: Date): void {
  console.log('onChange: ', result);
}
TagModelOpen() {
  this.modalService.triggerShowModal();
}
RelatedModelOpen(): void {
  const jobId = this.localStorage.getJobId();
  const modalRef = this.modal.create({
    nzContent: ProjectManagementSiteDiariesRelatedToDoStComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
  this.isRowVisible = true;

}
map(): void {
  const modalRef = this.modal.create({
    nzContent: ProjectManagementSiteDiariesMapComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
  this.isRowVisible = true;
}
checkGetWeather() {
  // this.isCheckboxChecked1 = !this.isCheckboxChecked1;
  if(this.formFieldValues['isIncludeWeatherConditions'] === true){
    this.getWeather();
  }
}
onCheckboxChange2() {
  this.isCheckboxChecked2 = !this.isCheckboxChecked2;
}
}
