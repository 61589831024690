import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-cost-code-link',
  templateUrl: './edit-cost-code-link.component.html',
  styleUrls: ['./edit-cost-code-link.component.css']
})
export class EditCostCodeLinkComponent {
  selectedOption5: string = '--Not Linked in Xero--';
  isOpen5: boolean = false;
  options5: string[] = ['--Not Linked in Xero--', '1 Day'];




  selectOption5(option: string) {
    this.selectedOption5 = option;
    this.isOpen5 = false;
  }
  toggleDropdown5() {
    this.isOpen5 = !this.isOpen5;
  }

}