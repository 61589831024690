import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ReceiptUploadComponent } from '../receipt-upload/receipt-upload.component';

@Component({
  selector: 'app-cost-inbox-empty',
  templateUrl: './cost-inbox-empty.component.html',
  styleUrls: ['./cost-inbox-empty.component.css']
})
export class CostInboxEmptyComponent {

  constructor(private modal: NzModalService) {}
 
  receiptUploadModelOpen(): void {
    const modalRef = this.modal.create({
      nzContent: ReceiptUploadComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }

}
