

<nz-modal class="shadow" nzWidth="60%"
    [(nzVisible)]="scheduleVisible" [nzTitle]="modalTitle"
    [nzFooter]="scheduleFooter"
    [nzBodyStyle]="{background: '#f1f4fa',padding: '13px','max-height': '74vh','overflow-x': 'hidden'}"
    (nzOnCancel)="onCancel()"
    [nzStyle]="{ top: '15px' }">
    <ng-template #modalTitle>
        <p nz-typography>{{selectedJobName}}</p>
        <h2 nz-typography>Schedule Item</h2>
    </ng-template>
    <form nz-form [formGroup]="scheduleForm">
        <div id="scheduleForm" *nzModalContent>

            <div nz-row nzAlign="middle"
                *ngIf="errorMessages && errorMessages?.length > 0">
                <div nz-col [nzSpan]="24">
                    <nz-alert
                        nzType="error"
                        nzShowIcon
                        [nzMessage]="message"
                        [nzDescription]="errorDescriptionTemplate">
                    </nz-alert>
                </div>
            </div>

            <ng-template #errorDescriptionTemplate>
                <ul>
                    <li class="sub-text p-0 m-0"
                        *ngFor="let error of errorMessages">
                        {{ error }}
                    </li>
                </ul>
            </ng-template>

            <ng-template #message>
                <strong class="mt-2">Please correct the following
                    fields:</strong>
            </ng-template>

            <div class="card-container">
                <nz-tabset nzType="card" class="shadow-lg" >
                    <nz-tab *ngFor="let tab of newScheduleItem"
                        [nzTitle]="newScheduleItemTabs(tab)">

                        <ng-container *ngIf="tab === 1">
                            <nz-card nzTitle="Schedule Item Details"
                                nzBorderless>

                                <div nz-row [nzGutter]="16" class="mb-2" *ngIf="scheduleResponse">
                                    <div nz-col nzSpan="24">
                                        <nz-form-item> 
                                            <nz-form-control>
                                                <label nz-checkbox
                                                    class="custom-checkbox d-flex align-items-center"
                                                    formControlName="isComplete">Complete</label>
                                            </nz-form-control>
                                        </nz-form-item>
                                        
                                    </div>
                                </div>

                                <div nz-row [nzGutter]="16">
                                    <div nz-col [nzSpan]="18">
                                        <div class>
                                            <label class="sub-text"
                                                for="title">Title <span
                                                    class="text-danger">*</span></label>
                                            <nz-form-item>
                                                <nz-form-control
                                                    [nzErrorTip]="titleErrorMessage  || 'Required'">
                                                    <input nz-input
                                                        formControlName="title"
                                                        type="text"
                                                        id="title" />
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                    <div nz-col [nzSpan]="6">
                                        <div class>
                                            <label for="color"
                                                class="sub-text">Display
                                                Color</label>
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <nz-select nzShowSearch
                                                        [nzCustomTemplate]="defaultTemplate"
                                                        id="color"
                                                        formControlName="colorId"
                                                        (ngModelChange)="updateSelectedColor($event)"
                                                        [style.width.%]="100"
                                                        [disabled]="false">
                                                        <nz-option
                                                            nzCustomContent
                                                            *ngFor="let option of colors"
                                                            [nzLabel]="option.name"
                                                            [nzValue]="option.id">
                                                            <span
                                                                class="color-circle-drop"
                                                                [style.backgroundColor]="option.colorCollection"></span>
                                                            <ng-container>
                                                                <span
                                                                    class="color-name">{{
                                                                    option.name
                                                                    }}</span>
                                                            </ng-container>
                                                        </nz-option>
                                                    </nz-select>
                                                    <ng-template
                                                        #defaultTemplate
                                                        let-selected>
                                                        <div
                                                            class="color-item d-flex align-items-center">
                                                            <span
                                                                class="color-circle-drop"
                                                                [style.backgroundColor]="selectedColor"></span>
                                                            <span
                                                                class="color-name">{{
                                                                selected.nzLabel
                                                                }}</span>
                                                        </div>
                                                    </ng-template>
                                                </nz-form-control>
                                            </nz-form-item>

                                        </div>
                                    </div>
                                </div>
                                <div nz-row [nzGutter]="16">
                                    <div nz-col [nzSpan]="23">
                                        <div class>
                                            <label class="sub-text">Assignees
                                            </label>
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <nz-tree-select
                                                        formControlName="createScheduleItemAssigneeRequests"
                                                        style="width: 100%"
                                                        [nzNodes]="combinedNodes"
                                                        [nzShowSearch]="true"
                                                        nzCheckable
                                                        (ngModelChange)="onAssigneesChange($event)"
                                                        nzDropdownClassName="dropdownStyle"
                                                        [nzAllowClear]="false"></nz-tree-select>
                                                </nz-form-control>
                                            </nz-form-item>

                                        </div>
                                    </div>
                                    <div nz-col [nzSpan]="1" disabled>
                                        <label
                                            class="p-0 mb-0 d-flex align-items-center mb-1">ㅤ</label>
                                        <button nz-popover disabled
                                            class="w-100"
                                            nzPopoverContent="No Users can receive notifications"
                                            nzPopoverPlacement="bottom"
                                            class="mt-0 p-0"
                                            nz-button nzType="link">
                                            <span>
                                                <img src="/assets/svg/arrow.svg"
                                                    disabled
                                                    width="24" height="24"
                                                    alt="icon">
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div nz-row [nzGutter]="16" class="mb-2">
                                    <div nz-col [nzSpan]="8">
                                        <div class>
                                            <label class="sub-text">Start Date
                                                <span
                                                    class="text-danger">*</span></label>
                                            <nz-form-item>
                                                <nz-form-control
                                                    nzErrorTip="Required">
                                                    <nz-date-picker
                                                        formControlName="startOn"
                                                        [nzFormat]="'MMM d, YYYY'"
                                                        nzSuffixIcon="false"
                                                        required
                                                        nzAllowClear
                                                        [nzPlaceHolder]="' '"
                                                        [nzDisabledDate]="disableWeekend"></nz-date-picker>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                    <div nz-col [nzSpan]="8">
                                        <div class>
                                            <label class="sub-text">Work Days
                                                <span
                                                    class="text-danger">*</span>
                                            </label>
                                            <nz-form-item>
                                                <nz-form-control
                                                    nzErrorTip="Required">
                                                    <nz-input-group [nzAddOnAfter]="scheduleForm.get('workDays')?.value > 1 ? 'days' : 'day'">
                                                        <nz-input-number
                                                            formControlName="workDays"
                                                            [nzMin]="1"
                                                            required
                                                            [nzStep]="1"></nz-input-number>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                    <div nz-col [nzSpan]="8">
                                        <div class>
                                            <label class="sub-text">End Date
                                                <span
                                                    class="text-danger">*</span>
                                            </label>
                                            <nz-form-item>
                                                <nz-form-control
                                                    nzErrorTip="Required">
                                                    <nz-date-picker
                                                        formControlName="endOn"
                                                        [nzFormat]="'MMM d, YYYY'"
                                                        nzSuffixIcon="false"
                                                        required
                                                        nzAllowClear
                                                        [nzPlaceHolder]="' '"
                                                        [nzDisabledDate]="disableWeekend">
                                                    </nz-date-picker>
                                                </nz-form-control>
                                            </nz-form-item>

                                        </div>
                                    </div>

                                </div>

                                <div nz-row [nzGutter]="16">
                                    <div nz-col [nzSpan]="24">
                                        <div class="inner-box">
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <nz-switch
                                                        formControlName="isHourly"></nz-switch>
                                                    <span class="hourly-label"
                                                        (click)="toggleIsHourly()">Hourly</span>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                </div>

                                <!-- Show this row only if isHourly is true in the form's control -->
                                <div nz-row
                                    *ngIf="scheduleForm.get('isHourly')?.value"
                                    [nzGutter]="16" class="mb-2">
                                    <div nz-col [nzSpan]="12">
                                        <div class="inner-box">
                                            <label class="sub-text">Start Time
                                                <span
                                                    class="text-danger">*</span></label>
                                            <nz-form-item>
                                                <nz-form-control
                                                    [nzErrorTip]="'Required'">
                                                    <nz-select nzShowSearch
                                                        formControlName="startTime">
                                                        <nz-option
                                                            *ngFor="let time of timeOptions"
                                                            [nzLabel]="time"
                                                            [nzValue]="time">
                                                        </nz-option>
                                                    </nz-select>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                    <div nz-col [nzSpan]="12">
                                        <div class="inner-box" *ngIf="scheduleForm.get('startOn')?.value && scheduleForm.get('endOn')?.value">
                                            <label class="sub-text">End
                                                Time</label>
                                            <nz-form-item>
                                                <nz-form-control
                                                    [nzErrorTip]="scheduleForm.errors?.['endTimeInvalid'] ? 'End time must be after start time' : null"
                                                    [nzValidateStatus]="scheduleForm.errors?.['endTimeInvalid'] ? 'error' : null">

                                                    <nz-select nzShowSearch
                                                        formControlName="endTime">
                                                        <nz-option
                                                            *ngFor="let time of timeOptions"
                                                            [nzLabel]="time"
                                                            [nzValue]="time">
                                                        </nz-option>
                                                    </nz-select>

                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                </div>

                                <div nz-row [nzGutter]="21">
                                    <div nz-col [nzSpan]="20">
                                        <div>
                                            <label
                                                class="sub-text">Progress</label>
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <!-- Use formControlName for Reactive Forms -->
                                                    <nz-slider
                                                        formControlName="progressPercentage"
                                                        [nzMin]="0"
                                                        [nzMax]="100"
                                                        [nzStep]="1"></nz-slider>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                    <div nz-col [nzSpan]="4">
                                        <div>
                                            <label class="sub-text">ㅤㅤㅤㅤ</label>
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <!-- Use formControlName here as well -->
                                                    <nz-input-group
                                                        nzAddOnAfter="%">
                                                        <nz-input-number
                                                            formControlName="progressPercentage"
                                                            [nzMin]="0"
                                                            [nzMax]="100"
                                                            [nzStep]="1"></nz-input-number>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                </div>

                                <div nz-row [nzGutter]="16">
                                    <div nz-col [nzSpan]="24">
                                        <div class>
                                            <label class="sub-text">Reminder
                                            </label>
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <nz-select
                                                        formControlName="scheduleItemReminderId"
                                                        [style.width.%]="100">
                                                        <nz-option
                                                            *ngFor="let option of reminderList"
                                                            [nzLabel]="option.name"
                                                            [nzValue]="option.id"></nz-option>
                                                    </nz-select>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                </div>

                            </nz-card>
                        </ng-container>
                        <ng-container *ngIf="tab === 2">
                            <nz-card nzTitle="Related Items" nzBorderless>
                                <div class="content" *ngIf="!scheduleResponse">
                                    <h3 nz-typography class="mb-3">Related To-Do's</h3>
                                <hr>
                                <h3 nz-typography class="mb-3">To-Do's available after save</h3>
                                <button nz-button nzType="default">
                                    Save and create To-Do
                                </button>
                                </div>

                                <nz-card nzTitle="Related To-Do's" [nzExtra]="RelatedToDoExtra" nzBorderless *ngIf="scheduleResponse">
                                    
                                    <ng-template #RelatedToDoExtra>
                                       <button nz-button nzType="default">New To-Do</button>
                                    </ng-template>
                                    
                                    <div class="card-center">
                                      <div class="content-block">
                                        <!-- Icon Section -->
                                        <i nz-icon nzType="file-done" nzTheme="outline" class="icon"></i>
                                        <!-- Header Section -->
                                        <h3 nz-typography class="card-title">No Related To-Do's</h3>
                                  
                                        <!-- Paragraph Section -->
                                        <p   nz-typography class="card-description">To-do's can help you and your team stay on track, and get work done faster.</p>
                                      </div>
                                    </div>
                                  </nz-card>
                                  
                            </nz-card>
                          
                        </ng-container>

                    </nz-tab>
                </nz-tabset>
            </div>

            <div class="content">
                <div class="card-container">

                    <nz-tabset tabset class="mt-3" nzType="card">
                        <ng-container *ngFor="let tab of tabs">
                            <nz-tab *ngIf="tab.show"  [nzTitle]="tab.id === 2 ? (tab.title + (getTagParameterRequestsCount() > 0 ? ' (' + getTagParameterRequestsCount() + ' new)' : '')) : 
                                      (tab.id === 1 ? (tab.title + (getPredecessorParameterRequestsCount() > 0 ? ' (' + getPredecessorParameterRequestsCount() + ' new)' : '')) : tab.title)">
                                <ng-container *ngIf="tab.id === 1">
                                    <nz-card nzBorderless
                                        nzTitle="Predecessors">

                                        <!-- Header Row for Labels -->
                                        <div nz-row [nzGutter]="16" class="m-0"
                                            *ngIf="predecessorRows.length > 0 && predecessorRows.length <= maxPredecessorRows">
                                            <div nz-col [nzSpan]="12">
                                                <label
                                                    class="sub-text">Name</label>
                                            </div>
                                            <div nz-col [nzSpan]="6">
                                                <label
                                                    class="sub-text">Type</label>
                                            </div>
                                            <div nz-col [nzSpan]="4">
                                                <label
                                                    class="sub-text">Lag</label>
                                            </div>
                                            <div nz-col [nzSpan]="2">
                                                <label
                                                    class="sub-text"></label>
                                            </div>
                                        </div>

                                        <div
                                            formArrayName="createScheduleItemPredecessorParameterRequests">
                                            <div
                                                *ngFor="let row of predecessorRows.controls; let i = index"
                                                [formGroupName]="i" nz-row
                                                [nzGutter]="16" class="m-0">
                                                <div nz-col [nzSpan]="12">
                                                    <nz-form-item>
                                                        <nz-form-control [nzErrorTip]="'Cannot add predecessor more than once'">
                                                            <nz-select
                                                                nzShowSearch
                                                                formControlName="scheduleItemPredecessorParameterId"
                                                                [style.width.%]="100"
                                                                [nzDisabled]="predecessorList?.length === 0"
                                                                [nzPlaceHolder]="predecessorList?.length === 0 ? 'There are no items to select' : ' '"
                                                                (ngModelChange)="onPredecessorChange($event, i)">

                                                                <nz-option
                                                                    *ngFor="let option of predecessorList"
                                                                    [nzValue]="option.Id"
                                                                    [nzLabel]="option.FormattedTitle">
                                                                </nz-option>

                                                            </nz-select>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>

                                                <div nz-col [nzSpan]="6">
                                                    <nz-form-item>
                                                        <nz-form-control>
                                                            <nz-select
                                                                nzShowSearch
                                                                formControlName="scheduleItemPredecessorTypeSetupId"
                                                                [style.width.%]="100">
                                                                <nz-option
                                                                    *ngFor="let option of scheduleItemType"
                                                                    [nzLabel]="option.name"
                                                                    [nzValue]="option.id"></nz-option>
                                                            </nz-select>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>

                                                <div nz-col [nzSpan]="4">
                                                    <nz-form-item>
                                                      <nz-form-control>
                                                        <nz-input-group 
                                                          [nzAddOnAfter]="(predecessorRows.at(i).get('lagNumber')?.value === 1 || predecessorRows.at(i).get('lagNumber')?.value === -1) ? 'day' : 'days'">
                                                          <nz-input-number
                                                            formControlName="lagNumber"
                                                            [nzStep]="1"
                                                            (ngModelChange)="onChangeLagNumber(i, $event)">
                                                          </nz-input-number>
                                                        </nz-input-group>
                                                      </nz-form-control>
                                                    </nz-form-item>
                                                  </div>
                                                  
                                                  
                                                  
                                                  

                                                <div nz-col [nzSpan]="2">
                                                    <div class>
                                                        <button nz-button
                                                            nzType="link"
                                                            (click)="deletePredecessorRow(i)">
                                                            <a nz-icon
                                                                nzType="delete"
                                                                class="fs-4"
                                                                nzTheme="fill"></a>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- "Add Predecessor" Button -->
                                        <div nz-row class="p-2"
                                            *ngIf="predecessorRows.length < maxPredecessorRows">
                                            <div nz-col>
                                                <nz-form-control>
                                                    <nz-input-group>
                                                        <button nz-button
                                                            nzType="text"
                                                            (click)="addPredecessorRow()">
                                                            <a class="fs-5"
                                                                nz-icon
                                                                nzType="plus-circle"
                                                                nzTheme="fill"></a>
                                                            <a class="ms-3">Add
                                                                Predecessor</a>
                                                        </button>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </div>
                                        </div>



                                        <!-- <nz-card nzTitle="Links that Follow this Schedule Item" nzBorderless [nzExtra]="extraLink">
                                            <ng-template #extraLink>
                                                <button nz-button nzType="default">
                                                    Break Selected Link 
                                                </button>
                                            </ng-template>

                                                <nz-table
                                                  #smallTable
                                                  nzSize="small"
                                                  [nzFrontPagination]="false"
                                                  [nzPaginationType]="'small'"
                                                  [nzShowPagination]="false"
                                                  [nzData]=""
                                                  nzTableLayout="fixed"
                                                >
                                                  <thead>
                                                    <tr>
                                                      <th
                                                        nzWidth="60px"
                                                        nzShowCheckbox
                                                      ></th>
                                                      <th>Title</th>
                                                      <th>Type</th>
                                                      <th>Lag</th>
                                                      <th>Start</th>
                                                      <th>End / Deadline</th>

                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    
                                                      <tr>
                                                        <td
                                                          nzWidth="60px"
                                                          nzShowCheckbox
                                                        ></td>
                                                        <td >
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td >
                                                        </td>
                                                      </tr>
                                                     
                                                  </tbody>
                                                </nz-table>



                                        </nz-card> -->
                                    </nz-card>

                                </ng-container>
                                <ng-container *ngIf="tab.id === 2">
                                    <nz-card nzBorderless
                                        nzTitle="Schedule Item Phase">
                                        <div nz-row [nzAlign]="'middle'">
                                            <!-- Form Field Section -->
                                            <div nz-col [nzSpan]="10"
                                                class="">
                                                <div>
                                                    <label
                                                        class="sub-text">Phase</label>
                                                    <nz-form-item>
                                                        <nz-form-control>
                                                            <nz-select
                                                                [style.width.%]="100"
                                                                formControlName="phaseSetupId">
                                                                <nz-option
                                                                    [nzLabel]="'Unassigned'"
                                                                    [nzValue]="0"></nz-option>
                                                                <nz-option
                                                                    *ngFor="let option of phaseList"
                                                                    [nzLabel]="option.Name"
                                                                    [nzValue]="option.Id"></nz-option>
                                                            </nz-select>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                            </div>

                                            <!-- Button Section with Flex Alignment -->
                                            <div nz-col [nzSpan]="4"
                                                class="mt-2">
                                                <nz-space class="d-flex align-items-center mt-2"
                                                    nzAlign="center">
                                                    <button nz-button
                                                        nzType="link"
                                                        class="add-button"
                                                        (click)="newSchedulePhaseOpen()">Add</button>
                                                    <button nz-button
                                                        nzType="link"
                                                        class="edit-button"
                                                        (click)="newSchedulePhaseEdit()"
                                                        [disabled]="scheduleForm.get('phaseSetupId').value <= 0">Edit</button>

                                                </nz-space>
                                            </div>
                                        </div>

                                    </nz-card>
                                    <nz-card nzBorderless
                                        nzTitle="Schedule Item Tags">

                                        <div nz-row [nzAlign]="'middle'">
                                            <!-- Form Field Section -->
                                            <div nz-col [nzSpan]="10"
                                                >
                                                <div>
                                                    <label
                                                        class="sub-text">Tags</label>
                                                    <nz-form-item>
                                                        <nz-form-control>
                                                            <nz-tree-select
                                                                style="width: 100%"
                                                                formControlName="createScheduleItemTagParameterRequests"
                                                                [nzNodes]="tagNodes"
                                                                nzShowSearch
                                                                nzAllowClear="false"
                                                                nzCheckable
                                                                (ngModelChange)="onTagChange($event)"
                                                                nzDropdownClassName="dropdownStyle">
                                                            </nz-tree-select>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                            </div>

                                            <!-- Button Section with Flex Alignment -->
                                            <div nz-col [nzSpan]="4"
                                                class="mt-2">
                                                <nz-space class="d-flex align-items-center mt-2"
                                                    nzAlign="center">
                                                    <button nz-button
                                                        nzType="link"
                                                        class="add-button"
                                                        (click)="newScheduleTagOpen()">Add</button>
                                                    <button
                                                        nz-button
                                                        nzType="link"
                                                        class="edit-button"
                                                        (click)="newScheduleTagEdit()"
                                                        [disabled]="!isSingleItemSelected()">
                                                        Edit
                                                    </button>
                                                </nz-space>
                                            </div>
                                        </div>

                                    </nz-card>

                                </ng-container>
                                <ng-container *ngIf="tab.id === 3">
                                    <nz-card nzBorderless
                                        nzTitle="Schedule Viewing">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div
                                                            class="col-12">
                                                            <label
                                                                formControlName="isShowOnGantt"
                                                                name="isShowOnGantt"
                                                                nz-checkbox>Show
                                                                on
                                                                Gantt</label>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="row mt-3">
                                                        <div
                                                            class="col-12">
                                                            <label
                                                                formControlName="isShowOwnerPhasesOnly"
                                                                nz-checkbox>Show
                                                                Owner
                                                                (Full
                                                                Schedule)
                                                                <i
                                                                    class="bi bi-info-circle-fill text-secondary ms-2"
                                                                    nz-popover
                                                                    [nzPopoverContent]="showOwne"
                                                                    nzPopoverPlacement="top"></i>
                                                                <ng-template
                                                                    #showOwne>
                                                                    <div
                                                                        class="rounded-2">
                                                                        <p
                                                                            style="width: 210px; font-size: 13px">
                                                                            Items
                                                                            with
                                                                            "Show Owner"
                                                                            unchecked
                                                                            will
                                                                            not
                                                                            be
                                                                            visible
                                                                            to
                                                                            the
                                                                            owner
                                                                            regardless
                                                                            of
                                                                            owner
                                                                            schedule
                                                                            settings.
                                                                        </p>
                                                                    </div>
                                                                </ng-template>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="row gx-0 mt-3">
                                                        <div
                                                            class="col-12">
                                                            <label
                                                                class="sub-text">Subs/Vendors</label>
                                                                <nz-tree-select style="width: 100%" [nzNodes]="combinedNodes"
                                                                [nzShowSearch]="true" nzAllowClear="false" nzCheckable
                                                                nzDropdownClassName="dropdownStyle">
                                                            </nz-tree-select>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div
                                                            class="col-12">
                                                            <div
                                                                class="row gx-0 mt-3">
                                                                <div
                                                                    class="ListViewJobname">
                                                                    <!-- All Assigned Subs/Vendors have been granted
                                              viewing access and will receive notifications on
                                              this schedule item. -->
                                                                    x
                                                                    All
                                                                    Assigned
                                                                    Subs/Vendors
                                                                    have
                                                                    been
                                                                    granted
                                                                    viewing
                                                                    access
                                                                    and
                                                                    will
                                                                    receive
                                                                    notifications
                                                                    on
                                                                    this
                                                                    schedule
                                                                    item.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </nz-card>

                                </ng-container>
                                <ng-container *ngIf="tab.id === 4">
                                    <div class="content">

                                        <div class="card-container p-3">
                                            <nz-card nzBorderless
                                                style="background-color: #f1f4fa;">
                                                <nz-tabset
                                                    nzType="card">
                                                    <nz-tab
                                                        *ngFor="let tab of notesTabs"
                                                        [nzTitle]="tab.title">

                                                        <ng-container
                                                            *ngIf="tab.id === 9">
                                                            <div
                                                                class="row p-2">
                                                                <div
                                                                    class="col-12">
                                                                    <nz-form-item>
                                                                        <nz-form-control
                                                                            [nzErrorTip]="allNotesErrorMessage">
                                                                            <textarea
                                                                                formControlName="allNotes"
                                                                                [nzAutosize]="{ minRows: 3, maxRows: 10 }"
                                                                                nz-input></textarea>
                                                                        </nz-form-control>
                                                                    </nz-form-item>
                                                                </div>
                                                            </div>

                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="tab.id === 10">
                                                            <div
                                                                class="row p-2">
                                                                <div
                                                                    class="col-12">
                                                                    <nz-form-item>
                                                                        <nz-form-control
                                                                            [nzErrorTip]="internalNotesErrorMessage">
                                                                            <textarea
                                                                                formControlName="internalNotes"
                                                                                [nzAutosize]="{ minRows: 3, maxRows: 10 }"
                                                                                nz-input></textarea>
                                                                        </nz-form-control>
                                                                    </nz-form-item>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="tab.id === 11">
                                                            <div
                                                                class="row p-2">
                                                                <div
                                                                    class="col-12">
                                                                    <nz-form-item>
                                                                        <nz-form-control
                                                                            [nzErrorTip]="subNotesErrorMessage">
                                                                            <textarea
                                                                                formControlName="subNotes"
                                                                                [nzAutosize]="{ minRows: 3, maxRows: 10 }"
                                                                                nz-input></textarea>
                                                                        </nz-form-control>
                                                                    </nz-form-item>
                                                                </div>
                                                            </div>

                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="tab.id === 12">
                                                            <div
                                                                class="row p-2">
                                                                <div
                                                                    class="col-12">
                                                                    <nz-form-item>
                                                                        <nz-form-control
                                                                            [nzErrorTip]="ownerNotesErrorMessage">
                                                                            <textarea
                                                                                formControlName="ownerNotes"
                                                                                [nzAutosize]="{ minRows: 3, maxRows: 10 }"
                                                                                nz-input></textarea>
                                                                        </nz-form-control>
                                                                    </nz-form-item>
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                    </nz-tab>
                                                </nz-tabset>
                                            </nz-card>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="tab.id === 5">
                                    <nz-card nzBorderless nzTitle="Attachments">
                                        <div class="row p-2">
                                            <div class="col-12">
                                                <app-create-attachments-common
                                                    [TestAttachment__]="TestAttachment"
                                                    (filesUploaded)="onFilesUploaded($event)"
                                                    (attachmentsReset)="resetForm()"></app-create-attachments-common>
                                            </div>

                                        </div>
                                    </nz-card>

                                </ng-container>
                                <ng-container *ngIf="tab.id === 6">
                                    <nz-card nzBorderless
                                        nzTitle="Baseline"></nz-card>

                                </ng-container>
                                <ng-container *ngIf="tab.id === 7">
                                    <nz-card nzBorderless
                                        nzTitle="Messaging">
                                        <messaging-common 
                                        [Job_ID]="JobInfoId"
                                        [Messaging_ID]="Messaging_Id"
                                        [header_ID]="ScheduleItemId"
                                        [Form_Name_ID]="formNameId"
                                        >
                                        </messaging-common>
                                    </nz-card>
                                  
                                </ng-container>
                                <ng-container *ngIf="tab.id === 8">
                                    <nz-card nzBorderless
                                        nzTitle="Confirmations"></nz-card>

                                </ng-container>
                            </nz-tab>
                        </ng-container>
                    </nz-tabset>

                </div>
            </div>

        </div>
    </form>

</nz-modal>
<ng-template #scheduleFooter>

    <div nz-row class="p-1 justify-content-end">
        <div *ngIf="scheduleResponse"
            nz-col
            nzSpan="12"
            class="footerContent">
            <span *ngIf="footerResponse">
                <app-footer-label
                    [footerData]="footerResponse"></app-footer-label>
            </span>
        </div>
        <div nz-col nzSpan="12" class="text-end">
            <nz-space>

                <ng-container *nzSpaceItem>
                    <button nz-button *ngIf="scheduleResponse"
                        nzType="default" (click)="showConfirm()"
                      [disabled]="DisabledNextActivity"
                      >Delete</button>
                </ng-container>
                <ng-container *nzSpaceItem>
                    <button nz-button nzType="primary"
                        (click)="saveAndNewSchedule()"
                        [disabled]="DisabledNextActivity"

                        [nzLoading]="isSaveAndNew">
                        Save & New
                    </button>
                </ng-container>

                <ng-container *nzSpaceItem>
                    <button nz-button nzType="primary" (click)="saveButton()"
                      [disabled]="DisabledNextActivity"
                        [nzLoading]="isSaving">
                        Save
                    </button>
                </ng-container>

                <ng-container *nzSpaceItem>
                    <button nz-button nzType="primary"
                      [disabled]="DisabledNextActivity"
                        (click)="saveAndCloseSchedule()"
                        [nzLoading]="isSaveAndClose">
                        Save & Close
                    </button>
                </ng-container>
            </nz-space>
        </div>
    </div>
</ng-template>
