import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectionSetup } from 'src/Models/CompanySettings/Selections/SelectionSetup';
import { ApiResponseModel } from 'src/Models/apiResponse.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SelectionsService {

  private baseUrl: string = environment.apiUrl + 'SelectionSetup/';


  constructor(private http: HttpClient) {}

  postData(data: SelectionSetup): Observable<SelectionSetup> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }

  getData(): Observable<SelectionSetup[]> {
    return this.http.get<SelectionSetup[]>(`${this.baseUrl}getAll`);
  }

  updateData(data: SelectionSetup): Observable<SelectionSetup> {
    return this.http.put<SelectionSetup>(`${this.baseUrl}`, data);
  }

  deleteData(id: number): Observable<SelectionSetup> {
    return this.http.delete<SelectionSetup>(
      `${this.baseUrl}?globalId=${id}`
    );
  }
  getDataId(id: number): Observable<ApiResponseModel> {
    return this.http.get<ApiResponseModel>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }
}