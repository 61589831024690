import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataType } from 'src/Models/CompanySettings/Selections/DataType';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DataTypeService {
  private baseUrl: string = environment.apiUrl + 'DataType/';


  constructor(private http: HttpClient) {}

  postData(data: DataType): Observable<DataType> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }

  getData(): Observable<DataType[]> {
    return this.http.get<DataType[]>(`${this.baseUrl}getAll`);
  }
  updateData(data: DataType): Observable<DataType> {
    return this.http.put<DataType>(`${this.baseUrl}`, data);
  }

  deleteData(id: number): Observable<DataType> {
    return this.http.delete<DataType>(
      `${this.baseUrl}?globalId=${id}`
    );
  }
  getDataId(id: number): Observable<DataType> {
    return this.http.get<DataType>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }
}