import { Component, EventEmitter, Output } from '@angular/core';
import { NzSelectSizeType } from 'ng-zorro-antd/select';
@Component({
  selector: 'app-new-selection',
  templateUrl: './new-selection.component.html',
  styleUrls: ['./new-selection.component.css']
})
export class NewSelectionComponent {
  @Output() cancel = new EventEmitter<void>();
  
  listOfOption: Array<{ label: string; value: string }> = [];
  size: NzSelectSizeType = 'default';
  singleValue = 'a10';

  selectionModelhandle=true;
  selectionModelCancel(): void {
  this.cancel.emit();
  }

}