import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, ElementRef, Output, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, NgForm } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { CreateApplicationUserRequest, UpdateApplicationUserImageRequest } from 'src/Models/InternalUser/InternalUser';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { RoleTypeService } from 'src/Service/Internaluser/role-type.service';
import { ApplicationGroupResponse, ApplicationUserResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { ManageRoleComponent } from '../manage-role/manage-role.component';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Loading3QuartersOutline } from '@ant-design/icons-angular/icons';


@Component({
  selector: 'app-add-new-internal-user',
  templateUrl: './add-new-internal-user.component.html',
  styleUrls: ['./add-new-internal-user.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddNewInternalUserComponent implements OnInit {
  IMG_BASE: string = environment.IMG_BUCKET_URL;
  destroy$: Subject<void> = new Subject<void>();
  @Output() cancel = new EventEmitter<void>();
  @Output() internalUserOnCompleted = new EventEmitter();
  @Input() internalUserResponseId: number;
  internalUserResponse: ApplicationUserResponse;
  internalUserResponseinImg: ApplicationUserResponse;
  @ViewChild('phoneInput') phoneInput!: ElementRef;
  previewImage: string | undefined = '';
  previewVisible = false;
  selectedValue = null;
  selectedFile: File | null = null;
  userData: CreateApplicationUserRequest = new CreateApplicationUserRequest();
  userImageUpload: UpdateApplicationUserImageRequest = new UpdateApplicationUserImageRequest();
  roleId: number = 0;
  isVisible: boolean = true;
  checked = true;
  internalHeader: string;
  showErrorCards: boolean = false;
  companyNameInvalid: boolean = false;
  demoValue = 2;
  isDisabled = true;
  tabs = [1, 2, 3, 4, 5, 6, 7];
  userInvited: boolean = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Australia, CountryISO.Australia];
  separateDialCode = true;
  dialCode: string;
  countryCode: CountryISO;
  selectedFiles: File[] = [];
  roles: ApplicationGroupResponse[] = [];
  rolesData: ApplicationGroupResponse[] = [];
  selectedRole: ApplicationGroupResponse;
  mainCheckbox: boolean = true;
  confirmModal?: NzModalRef;
  CoseCode: { id: number, title: string, detail: string }[] = [];
  mainCheckboxIndeterminate: boolean = false;
  rolRes: any;
  yourForm: any;
  searchKeyword: string = '';
  editorValue: string = '';
  editorTools: any[] = [];
  PAGE_ID: number = 1;
  selectedRoleId: number;
  additionalEmails: FormArray;
  fileList: NzUploadFile[] = [];
  imageUploaded: boolean = false;
  uploadedImageUrl: SafeUrl = '';
  Filter: boolean = false;
  internalUserFormFieldsValue: any = {};
  ApplicationUserNotificationParameter: any = {};
  ApplicationUserJobAcessParameter: any = {};
  maxItems: number = 6;
  items: any[] = [];
  size: NzButtonSize;
  validationErrors: string[] = [];
  isSaveAndClose: boolean = false;
  isSaveAndNew: boolean = false;
  isSubmitted: boolean = false;
  isSave = false;
  conformCardShow = false;
  proposalconformCardShow = false;
  modalErrorMessages: string[];
  isDeleteLoading: boolean = false;
  validItems: any[] = [];
  isLoading: boolean = false;
  initialFormFieldValues: any = {};
  validationStates: { [key: string]: NzStatus } = {};
  validationMessages: { [key: string]: string } = {};
  validationStatestest123: { [key: string]: NzStatus } = {};
  @ViewChild('phoneInput') phoneInput123: NgForm;
  phoneInputValid: boolean = false;

  constructor(private fb: FormBuilder,
    private toastService: NzMessageService,
    private roleService: RoleTypeService,
    private internalUserService: InternalUserService,
    private cd: ChangeDetectorRef,
    private modal: NzModalService,
    public accessLocalStorageService: AccessLocalStorageService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.fetchLeadData();
    this.roleGetData();
    this.createInternalUserForm();
    this.assignedUserSelecte();
    // this.internalUserForm = this.createInternalUserForm();
    // this.phoneInput?.nativeElement.setAttribute('autocomplete', 'off');
    // this.internalUserForm.get('isScheduleNotifyMe')?.valueChanges.pipe(
    //   takeUntil(this.destroy$)
    // ).subscribe(isNotifyMeChecked => {
    //   if (isNotifyMeChecked) {
    //     this.internalUserForm.get('scheduleNotifyMeWhen')?.enable();
    //   } else {
    //     this.internalUserForm.get('scheduleNotifyMeWhen')?.disable();
    //   }
    // });
  }
  assignedUserSelecte() {
    let _userId = this.accessLocalStorageService.getUserLoginId();
    this.internalUserService.getData().subscribe((res) => {
      let datset = res?.result?.find(x => x?.id === _userId)
      this.internalUserFormFieldsValue.email = datset?.email;
    });
  }
  async fetchLeadData(): Promise<void> {
    if (this.internalUserResponseId) {
      this.isLoading = true;
      try {
        const leadResponse = await this.internalUserService.getDataId(this.internalUserResponseId).toPromise();
        this.internalUserResponse = leadResponse.result;
        if (this.internalUserResponse) {
          this.patchFormValues(this.internalUserResponse);
        }
      } catch (error) {
        console.error('Error fetching InternalUser:', error);
      } finally {
        this.isLoading = false;
        this.cd.detectChanges();
      }
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  // ngAfterViewInit() {
  //   this.initLoad();
  //   this.cd.detectChanges();
  // }
  private initForm(): void {
    this.internalUserFormFieldsValue;
    this.fileList = [];
    this.selectedFiles = [];
    this.imageUploaded = false;

    this.internalUserFormFieldsValue.applicationGroupId.valueChanges.subscribe(value => {
      this.onRoleChange(value);
    });
    this.additionalEmails = this.internalUserFormFieldsValue.createApplicationUserEmailParameterRequests as FormArray;
  }
  createInternalUserForm() {
    this.internalUserFormFieldsValue = {
      companyParameterId: 0,
      globalId: '00000000-0000-0000-0000-000000000000',
      middleName: '',
      companyNameId: 1,
      firstName: '',
      lastName: '',
      email: '',
      label: '',
      applicationGroupId: '',
      phoneNumber: "",
      countryCode: this.CountryISO.Australia,
      cellPhone: "",
      dialCode: '',
      receiveTextMessages: '',
      loginAccess: true,
      isSetOutOfOfficeStatus: false,
      messageSignature: '',
      messageSignatureImageUrl: '',
      isScheduleNotifyMe: false,
      // scheduleNotifyMeWhen: [{ value: this.demoValue, disabled: this.isDisabled }],
      scheduleNotifyMeWhen: 0,
      isTimeClockRequiredLocation: null,
      costCodeId: 0,
      defaultLaborCost: 0,
      contactInformation: '',
      userStatusId: 0,
      userTypeId: 0,
      createApplicationUserNotificationParameterRequests: [this.ApplicationUserNotificationParameter],
      createApplicationUserJobAcessParameterRequests: [this.ApplicationUserJobAcessParameter],
      createApplicationUserEmailParameterRequests: [],
    }
    this.ApplicationUserNotificationParameter = {
      applicationUserId: 1,
      applicationModuleId: 1,
      isAllUserActivity: false,
      isEmail: 1,
      isText: false,
      isPush: false,
      toolTip: '',
    }
    this.ApplicationUserJobAcessParameter = {
      jobInformationId: 1,
      applicationUserId: 1,
      isAccess: false,
      isNotification: false,
    }
    // createApplicationUserJobAcessParameterRequests: this.fb.array([
    //   this.fb.group({
    //     applicationUserId: [1],
    //     jobInformationId: [1],
    //     isAccess: [true],
    //     isNotification: [true]
    //   })
    // ]),
    // createApplicationUserEmailParameterRequests: this.fb.array([]),
    // private createInternalUserForm(): FormGroup {
    //   return this.fb.group({
    //   });
    this.fileList = [];
  }
  initLoad() {
    this.roleGetData();
    this.initForm();
  }
  patchFormValues(internalUserResponse: ApplicationUserResponse) {
    this.internalUserFormFieldsValue.cellPhone = internalUserResponse?.cellPhone
    this.cd.detectChanges();
    this.internalHeader = internalUserResponse?.fullName;
    const notificationParameters = this.internalUserResponse?.applicationUserNotificationParameters || [];
    const jobAccessParameters = this.internalUserResponse?.applicationUserJobAcessParameters || [];
    this.internalUserFormFieldsValue['globalId'] = internalUserResponse?.globalId;
    this.internalUserFormFieldsValue['middleName'] = internalUserResponse?.middleName;
    this.internalUserFormFieldsValue['firstName'] = internalUserResponse?.firstName;
    this.internalUserFormFieldsValue['lastName'] = internalUserResponse?.lastName;
    this.internalUserFormFieldsValue['email'] = internalUserResponse?.email;
    this.internalUserFormFieldsValue['label'] = internalUserResponse?.label;
    this.cd.detectChanges();
    this.internalUserFormFieldsValue['applicationGroupId'] = internalUserResponse?.applicationGroupId;
    this.internalUserFormFieldsValue['phoneNumber'] = internalUserResponse?.phoneNumber;
    this.internalUserFormFieldsValue['countryCode'] = internalUserResponse?.countryCode;
    this.internalUserFormFieldsValue['dialCode'] = internalUserResponse?.dialCode;
    this.internalUserFormFieldsValue['receiveTextMessages'] = internalUserResponse?.receiveTextMessages;
    this.internalUserFormFieldsValue['loginAccess'] = internalUserResponse?.loginAccess;
    this.internalUserFormFieldsValue['isSetOutOfOfficeStatus'] = internalUserResponse?.isSetOutOfOfficeStatus;
    this.internalUserFormFieldsValue['messageSignature'] = internalUserResponse?.messageSignature;
    this.internalUserFormFieldsValue['messageSignatureImageUrl'] = internalUserResponse?.messageSignatureImageUrl;
    this.internalUserFormFieldsValue['isScheduleNotifyMe'] = internalUserResponse?.isScheduleNotifyMe;
    this.internalUserFormFieldsValue['scheduleNotifyMeWhen'] = internalUserResponse?.scheduleNotifyMeWhen;
    this.internalUserFormFieldsValue['isTimeClockRequiredLocation'] = internalUserResponse?.isTimeClockRequiredLocation;
    this.internalUserFormFieldsValue['costCodeId'] = internalUserResponse?.costCodeId;
    this.internalUserFormFieldsValue['defaultLaborCost'] = internalUserResponse?.defaultLaborCost;
    this.internalUserFormFieldsValue['contactInformation'] = internalUserResponse?.contactInformation;
    this.internalUserFormFieldsValue['userStatusId'] = internalUserResponse?.userStatusId;
    this.internalUserFormFieldsValue['userTypeId'] = internalUserResponse?.userTypeId;
    this.cd.detectChanges();
    this.roleId = this.internalUserResponse?.applicationGroupId;
    this.selectedRole = this.rolesData.find(role => role.id === this.roleId);
    if (this.internalUserResponse?.messageSignatureImageUrl) {
      const fullImageUrl = this.IMG_BASE + this.internalUserResponse.messageSignatureImageUrl;
      this.fileList = [{
        uid: this.internalUserResponse.globalId,
        name: fullImageUrl,
        status: 'done',
        url: fullImageUrl,
      }];
      this.uploadedImageUrl = fullImageUrl
      this.cd.detectChanges();
    } else {
      this.fileList = [];
      this.uploadedImageUrl = '';
      console.error('Image URL is missing or invalid');
    }
    const notificationFormArray = this.internalUserFormFieldsValue?.createApplicationUserNotificationParameterRequests as FormArray; notificationFormArray?.clear();

    for (const notificationParam of notificationParameters) {
      notificationFormArray.push(this.createNotificationFormGroup(
        notificationParam.applicationModuleId || 1,
        notificationParam.applicationModuleId || 1
      ));
    }
    const jobAccessFormArray = this.internalUserFormFieldsValue?.createApplicationUserJobAcessParameterRequests as FormArray;
    jobAccessFormArray.clear();

    for (const jobAccessParam of jobAccessParameters) {
      jobAccessFormArray.push(this.fb.group({
        applicationUserId: [jobAccessParam.jobInformationId || 1],
        jobInformationId: [jobAccessParam.jobInformationId || 1],
        isAccess: [jobAccessParam.isAccess || true],
        isNotification: [jobAccessParam.isNotification || true]
      }));
    }

    // const emailFormArray = this.internalUserFormFieldsValue?.createApplicationUserEmailParameterRequests as FormArray;
    // if (this.internalUserResponse?.applicationUserEmailParameters) {
    //   this.internalUserResponse.applicationUserEmailParameters.forEach(email => {
    //   });
      //   emailFormArray.push(this.createEmailFormGroup(email.email, email.label));
      // });
    }
  createEmailFormGroup(email: string, label: string): FormGroup {
    return this.fb.group({
      email: [email, [Validators.required, Validators.email]],
      label: [label, Validators.maxLength(50)],
    });
  }
  roleGetData() {
    this.isLoading = true;
    this.roleService.getData().subscribe((res) => {
    this.roles = res.result;
    this.isLoading = false;
    this.cd.detectChanges();
    });
  }
  createNotificationFormGroup(userId: number, moduleId: number) {
    return this.fb.group({
      applicationUserId: [userId],
      applicationModuleId: [moduleId],
      isAllUserActivity: [false],
      isEmail: [1],
      isText: [false],
      isPush: [false],
      toolTip: [''],
    });
  }
  toggleInviteStatus() {
    this.userInvited = !this.userInvited;

    this.validationStates['email'] = null;
    this.validationMessages['email'] = '';
  }
  getTabTitle(tab: number): string {
    switch (tab) {
      case 1:
        return 'Overview';
      case 2:
        return 'Permissions';
      case 3:
        return 'Notifications';
      case 4:
        return 'Messaging';
      case 5:
        return 'Job Access (0)';
      case 6:
        return 'Security & Login';
      case 7:
        return 'Other';
      default:
        return '';
    }
  }
  addAdditionalEmail(): void {
    if (this.items.length < this.maxItems) {
      this.items.push({ email: '', label: '' });
    }
  }
  deleteRow(index: number): void {
    this.items.splice(index, 1);
    this.internalUserFormFieldsValue.createApplicationUserEmailParameterRequests = this.items;
  }
  downloadImage(): void {
    if (this.fileList.length > 0) {
      const file = this.fileList[0];
      const url = URL.createObjectURL(file.originFileObj);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name || 'image';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  }
  handleChange(info: { file: NzUploadFile; fileList: NzUploadFile[] }): void {
    if (info.fileList.length > 1) {
      info.fileList = info.fileList.slice(-1);
    }
    this.fileList = info.fileList;

    const dataImagehold = this.fileList[0];
    this.userData.imageFile = dataImagehold?.originFileObj || null;
    this.selectedFiles = [dataImagehold?.originFileObj || null].filter(file => file !== null) as File[];

    this.imageUploaded = !!this.selectedFiles.length;

    if (this.imageUploaded && this.selectedFiles[0]) {
      this.uploadedImageUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.selectedFiles[0]));
    }
  }
  handleRemove = (file: NzUploadFile): Observable<boolean> => {
    const globalId = file.uid;
    return new Observable<boolean>(observer => {
      if (this.internalUserResponse?.globalId) {
        this.internalUserService.deleteSingal(globalId).subscribe(
          () => {
            this.toastService.success('Image deleted successfully');
            observer.next(true);
            observer.complete();
          },
          error => {
            console.error('Error deleting image', error);
            this.toastService.error('Error deleting image');
            observer.next(false);
            observer.complete();
          }
        );
      } else {
        this.toastService.success('Image deleted successfully');
        observer.next(true);
        observer.complete();
      }
    });
  };
  beforeUpload = (file: NzUploadFile): boolean => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      this.toastService.error('You can only upload image files!');
      return false;
    }
    return true;
  };
  deleteImage(): void {
    this.fileList = [];
    this.imageUploaded = false;
    this.uploadedImageUrl = '';
    this.toastService.success('Image deleted successfully');
  }
  handlePreview(file: NzUploadFile): void {
    this.previewImage = file.url || file.thumbUrl;
  }
  updateSignatureImage(userImageUpload: UpdateApplicationUserImageRequest) {
    if (userImageUpload.GlobalId && userImageUpload.ImageFile) {
      this.internalUserService.updateInternalUserSignature(userImageUpload).subscribe(() => {
      }, () => {
        this.toastService.error('Image upload failed!');
      });
    } else {
      console.error('GlobalId or ImageFile is missing for upload');
    }
  }
  deleteOpportunity(globalId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!globalId) {
        this.toastService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }

      this.internalUserService.deleteData(globalId).subscribe(
        (res) => {
          this.isDeleteLoading = false;
          this.toastService.success(`Deleted User!`);
          this.internalUserOnCompleted.emit(res);
          resolve();
          reject();
        },
        (error) => {
          this.isDeleteLoading = false;
          this.toastService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      );
    });
  }
  deleteInternalUser(): void {
    if (this.isDeleteLoading) {
      return;
    }

    this.modal.confirm({
      nzTitle: `Delete Lead Opportunity?`,
      nzContent: 'Are you sure you want to permanently delete this lead and all items on it?',
      nzOkText: `Delete Lead Opportunity`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteOpportunity(this.internalUserFormFieldsValue.globalId)
          .then(() => {
            this.LeadOpportunityNewLeadModelCancel();
          })
          .catch(() => {

          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }
  LeadOpportunityNewLeadModelCancel(): void {
    this.cancel.emit();
    this.isVisible = false;
  }
  closeDialog() {
    this.isVisible = false;
    this.cancel.emit();
  }
  onRoleChange(id: number) {
    this.roleId = id;
    this.selectedRole = this.rolesData.find(role => role.id === id);
    if (this.selectedRole) {
      this.cd.detectChanges();
    } else {
      console.error('Role not found for id:');
    }
  }
  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    this.userData.imageFile = this.selectedFile;
  }
  openFilter() {
    this.Filter = true
  }
  closeFilter() {
    this.Filter = false
  }
  showManagerole() {
    const modalRef = this.modal.create({
      nzContent: ManageRoleComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.ManageRole = true;
  }
  // handleRemove = (file: NzUploadFile): Observable<boolean> => {
  //   const globalId = file.uid;
  //   return new Observable<boolean>(observer => {
  //     if (this.internalUserResponse?.globalId) {
  //       this.internalUserService.deleteSingal(globalId).subscribe(
  //         () => {
  //           this.toastService.success('Image deleted successfully');
  //           observer.next(true);
  //           observer.complete();
  //         },
  //         error => {
  //           console.error('Error deleting image', error);
  //           this.toastService.error('Error deleting image');
  //           observer.next(false);
  //           observer.complete();
  //         }
  //       );
  //     } else {
  //       this.toastService.success('Image deleted successfully');
  //       observer.next(true);
  //       observer.complete();
  //     }
  //   });
  // };
  saveInternalUser(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save'): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
        return reject('Already processing');
      }

      if (actionType === 'saveAndNew') {
        this.isSaveAndNew = true;
      } else if (actionType === 'saveAndClose') {
        this.isSaveAndClose = true;
      } else {
        this.isSave = true;
      }
      this.isSubmitted = true;
      this.checkErrors();

      if (Object.values(this.validationStates).includes('error')) {
        setTimeout(() => {
          const errorElements = document.querySelectorAll('.error-message');
          if (errorElements.length > 0) {
            const lastErrorElement = errorElements[errorElements.length - 1];
            lastErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 100);
        this.resetFlags();
        return reject('Validation errors present');
      }
      if (this.internalUserFormFieldsValue.cellPhone && this.internalUserFormFieldsValue.cellPhone.number) {
        const phoneNumber = this.internalUserFormFieldsValue.cellPhone.number;
        const dialCode = this.internalUserFormFieldsValue.cellPhone.dialCode;
        const countryCodeNumber = this.internalUserFormFieldsValue.cellPhone.countryCode;
        this.internalUserFormFieldsValue.cellPhone = phoneNumber;
        this.internalUserFormFieldsValue.dialCode = dialCode;
        this.internalUserFormFieldsValue.countryCode = countryCodeNumber;
      }
      // this.validItems = this.items.filter(item => item.email.trim() !== '' || (item.label && item.label.trim() !== ''));
      // if (this.validItems.length > 0) {
      //   this.internalUserFormFieldsValue.createApplicationUserEmailParameterRequests = this.validItems;
      // } else {
      //   this.internalUserFormFieldsValue.createApplicationUserEmailParameterRequests = [];
      // }
      this.internalUserFormFieldsValue.createApplicationUserEmailParameterRequests = this.items;
      this.internalUserFormFieldsValue.phoneNumber = String(this.internalUserFormFieldsValue.phoneNumber);
      this.internalUserFormFieldsValue.isTimeClockRequiredLocation = this.internalUserFormFieldsValue.isTimeClockRequiredLocation
        ? Boolean(this.internalUserFormFieldsValue.isTimeClockRequiredLocation)
        : null;

      this.internalUserService.postData(this.internalUserFormFieldsValue).subscribe(
        (response) => {
          this.internalUserResponse = response?.result;
          this.internalUserFormFieldsValue.globalId = response?.result?.globalId;
          this.internalUserFormFieldsValue.id = response.result?.id;
          this.isSubmitted = false;

          if (this.fileList && this.selectedFiles?.length > 0) {
            const filesToUpload = this.selectedFiles.filter(file => file && file instanceof File);
            if (filesToUpload?.length > 0) {
              const attachmentRequest: UpdateApplicationUserImageRequest = {
                GlobalId: response.result?.globalId,
                ImageFile: filesToUpload,
                messageSignatureImageUrl: ''
              };
              this.internalUserService.updateInternalUserSignature(attachmentRequest).subscribe((res) => {
                this.internalUserResponse = res?.result;
              });
            }
          }

          this.internalUserOnCompleted.emit(response?.result);
          this.toastService.success('New Lead Saved Successfully!');
          this.storeInitialFormFieldValues();

          resolve();
        },
        (error) => {
          console.error('Error saving data:', error);
          reject(error);
        }
      ).add(() => {
        this.resetFlags();
        this.cd.detectChanges();
        this.conformCardShow = false;
        this.proposalconformCardShow = false;
        this.modalErrorMessages = []; // Reset modal error messages specifically
        this.isSubmitted = false;
      });
    });
  }
  updateModalErrorMessages() {
    this.modalErrorMessages = this.validationErrors.filter(
      (error) => error.includes('Opportunity Title')
    );
  }
  async storeInitialFormFieldValues(): Promise<void> {
    this.isLoading = true;
    await new Promise(resolve => setTimeout(() => {
      let data = this.internalUserFormFieldsValue;
      this.initialFormFieldValues = JSON.parse(JSON.stringify(data));
      resolve(true);
    }, 0));
    this.isLoading = false;
  };
  resetFlags() {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
    this.cd.detectChanges();
  }
  saveInternalUserAndNew() {
    this.saveInternalUser('saveAndNew').then(() => {
      this.resetForm();
    }).catch((error) => {
      console.error('Error during save and new:', error);
    }).finally(() => {
      this.cd.detectChanges();
    });
  }
  // saveInternalUserAndClose() {
  //   this.saveInternalUser('saveAndClose').then(() => {
  //     this.resetForm();
  //   }).catch((error) => {
  //     console.error('Error during save and new:', error);
  //   }).finally(() => {
  //     this.closeDialog();
  //   });
  // }
  saveInternalUserAndClose(): Promise<void> {
    return this.saveInternalUser('saveAndClose').then(() => {
      this.resetForm();
      this.cancel.emit();
    }).catch((error) => {
      console.error('Error during save and close:', error);
    })
  }
  // saveInternalUserAndClose(): Promise<void> {
  //   return this.saveInternalUser('saveAndClose').then(() => {
  //     this.resetForm();
  //     this.closeDialog();
  //   }).catch((error) => {
  //     console.error('Error during save and close:', error);
  //   });
  // }
  resetForm(): void {
    this.internalUserFormFieldsValue = {};
    this.internalUserResponse = null;
    this.internalUserResponse = undefined;
    this.cd.detectChanges();
    this.fileList = [];
    this.imageUploaded = false;
    this.selectedFiles = [];
    this.uploadedImageUrl = null;
    this.validationStates[this.internalUserFormFieldsValue.lastName] = null;
    this.validationMessages[this.internalUserFormFieldsValue.lastName] = '';
    this.validationErrors = [];
    this.validationErrors = [];
    this.modalErrorMessages = [];
    this.cd.detectChanges();
    this.internalUserFormFieldsValue.applicationGroupId.valueChanges.subscribe(value => {
      // this.onRoleChange(value);
    });
    this.additionalEmails = this.internalUserFormFieldsValue.createApplicationUserEmailParameterRequests as FormArray;
    // this.isSubmitted = false;
  }
  // onFieldChange(field: string, value: string) {
  //   this.internalUserFormFieldsValue[field] = value;
  //   this.checkErrors();
  // }
  onPhoneInputChange() {
    this.phoneInputValid = !this.phoneInput123.invalid;
    const cellPhone = this.internalUserFormFieldsValue.cellPhone;
    if (typeof cellPhone === 'object' && cellPhone?.number) {
      this.internalUserFormFieldsValue.cellPhone = cellPhone?.number;
      this.internalUserFormFieldsValue.dialCode = cellPhone.dialCode;
      this.internalUserFormFieldsValue.countryCode = cellPhone.countryCode;
    }
  }

  // getFieldLabel(field: string): string {
  //   const labels: { [key: string]: string } = {
  //     firstName: 'First Name',
  //     lastName: 'Last Name',
  //     email: 'Primary Email',
  //     label: 'Label',
  //     applicationGroupId: 'Role',
  //     phoneNumber: 'Phone Number',
  //   };
  //   return labels[field] || field;
  // }
  // get errorFields(): { field: string; label: string; message: string }[] {
  //   return Object.keys(this.validationStates)
  //     .filter(field => this.validationStates[field] === 'error')
  //     .map(field => ({
  //       field,
  //       label: this.getFieldLabel(field),
  //       message: this.validationMessages[field]
  //     }));
  // }
  // getErrorTip(field: string): string {
  //   const rules = this.validationRules[field];
  //   const input = this.internalUserFormFieldsValue[field] || '';

  //   if (rules?.required && typeof input === 'string' && !input.trim()) {
  //     return 'Required';
  //   }
  //   if (rules?.maxLength && input?.length > rules?.maxLength.limit) {
  //     const excessLength = input?.length - rules?.maxLength.limit;
  //     const unit = excessLength === 1 ? 'character' : 'characters';
  //     return `${excessLength} ${unit} over.`;
  //   }
  //   // if (rules?.maxLength && typeof input === 'string' && input.length > rules.maxLength.limit) {
  //   //   const excessLength = input.length - rules.maxLength.limit;
  //   //   return `${excessLength} character${excessLength > 1 ? 's' : ''} over`;
  //   // }
  //   if (field === 'email') {
  //     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //     if (!emailPattern.test(input)) {
  //       return 'Required';
  //     }
  //   }
  //   if (field.startsWith('email_')) {
  //     if (input.trim() === '') {
  //       return 'Required';
  //     }
  //     if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input)) {
  //       return 'Required';
  //     }
  //   } else if (field.startsWith('label_')) {
  //     if (input.trim() === '') {
  //       return 'Required';
  //     }
  //   }

  //   return '';
  // }
  // checkErrors() {
  //   this.validationStates = {};
  //   this.validationMessages = {};
  //   this.validationStatestest123 = {};

  //   const setValidationState = (field: string, errorTip: string) => {
  //     if (errorTip) {
  //       this.validationStates[field] = 'error';
  //       this.validationMessages[field] = errorTip;
  //     } else {
  //       this.validationStates[field] = null;
  //       this.validationMessages[field] = '';
  //     }
  //   };

  //   for (const field in this.validationRules) {
  //     if (this.validationRules.hasOwnProperty(field)) {
  //       const errorTip = this.getErrorTip(field);
  //       if (field === 'email' && this.userInvited) {
  //         this.validationStates[field] = null;
  //         this.validationMessages[field] = '';
  //       } else {
  //         setValidationState(field, errorTip);
  //       }
  //     }
  //   }

  //   this.items.forEach((item, index) => {
  //     const emailField = `email_${index}`;
  //     const labelField = `label_${index}`;
  //     const emailErrorTip = this.getErrorTip(emailField);
  //     const labelErrorTip = this.getErrorTip(labelField);
  //     const validationMessagesAdd = `Additional Email ${index + 1}`;

  //     setValidationState(validationMessagesAdd, emailErrorTip);
  //     this.validationStatestest123[emailField] = emailErrorTip ? 'error' : null;

  //     if (labelErrorTip) {
  //       this.validationStates[labelField] = 'error';
  //       this.validationMessages[labelField] = labelErrorTip;
  //     } else {
  //       this.validationStates[labelField] = null;
  //       this.validationMessages[labelField] = '';
  //     }
  //   });
  // }
  // readonly validationRules = {
  //   firstName: {
  //     required: 'Required',
  //     maxLength: { limit: 24, message: 'First Name.' }
  //   },
  //   lastName: {
  //     required: 'Required',
  //     maxLength: { limit: 24, message: 'Last Name.' }
  //   },
  //   email: {
  //     required: 'Required',
  //     maxLength: { limit: 50, message: 'Primary Email the limit.' }
  //   },
  //   applicationGroupId: {
  //     required: 'Required',
  //     maxLength: { message: 'Role the limit.' },
  //    },
  //    label: {
  //     maxLength: { limit: 50, message: 'Primary Email the limit.' }
  //   },
  //   phoneNumber: {
  //     maxLength: { limit: 25, message: 'Phone Number' }
  //   },
  // };
  isEmailValid(): boolean {
    return !this.getErrorTip('email');
  }
  readonly validationRules = {
    firstName: {
      required: 'Required',
      maxLength: { limit: 24, message: 'First Name.' }
    },
    lastName: {
      required: 'Required',
      maxLength: { limit: 24, message: 'Last Name.' }
    },
    email: {
      required: 'Required',
      maxLength: { limit: 110, message: 'Primary Email' },
      emailFormat: 'Primary Email must be a valid email'
    },
    label: {
      maxLength: { limit: 50, message: 'Primary Email the limit.' }
    },
    applicationGroupId: {
      required: 'Required',
      maxLength: { message: 'Role the limit.' },
    },
    phoneNumber: {
      maxLength: { limit: 25, message: 'Phone Number' }
    },
  };

  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Primary Email',
      label: 'Label',
      applicationGroupId: 'Role',
      phoneNumber: 'Phone Number',
    };
    return labels[field] || field;
  }
  
  getErrorTip(field: string): string {
    const rules = this.validationRules[field];
    const input = this.internalUserFormFieldsValue[field];
    const inputValue = typeof input === 'string' ? input.trim() : '';
  
    if (rules.required && !input) {
      return 'Required';
    }
  
    if (rules.maxLength && inputValue.length > rules.maxLength.limit) {
      const excessLength = inputValue.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }
  
    if (field === 'email' && input && !this.isValidEmail(inputValue)) {
      return rules.emailFormat;
    }
  
    return '';
  }
  
  isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }
 
  checkErrors() {
   this.validationStates = {};
   this.validationMessages = {};
 
   for (const field in this.validationRules) {
     if (this.validationRules.hasOwnProperty(field)) {
       if (this.touchedFields[field] || this.isSubmitted) {
         const errorTip = this.getErrorTip(field);
         if (errorTip) {
           this.validationStates[field] = 'error';
           this.validationMessages[field] = errorTip;
         } else {
           this.validationStates[field] = null;
           this.validationMessages[field] = '';
         }
       }
     }
   }
  }
  touchedFields: { [key: string]: boolean } = {};
  onFieldChange(field: string, value: any) {
    if (typeof value === 'string') {
      this.internalUserFormFieldsValue[field] = value.trim();
    } else {
      this.internalUserFormFieldsValue[field] = value;
    }
    this.touchedFields[field] = true;
    this.checkErrors();
  }
}