<div class="row mt-5" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <div class="col" style="text-align: center;">
       <span>
          <!-- <img src="assets/Empty-SVGs/Messaging/Comments/comments.svg" style="font-size: 70px;"> -->
          <i class="bi bi-journal-album" style="font-size: 70px;"></i>
  
      </span>        
        <div class="row">
            <div class="col mt-2">
        <h1>No Jobs</h1>
            </div>
        </div>
        <div class="col mt-3">
        <p>Setup Jobs </p>
  
  
        </div>
    </div>
    <div class="col mt-4" style="display: flex; justify-content: center; align-items: center;">
        <nz-space>
        <button *nzSpaceItem nz-button nzType="default" ><i class="bi bi-box-arrow-up-right me-2"></i>Learn How</button>
        <button
        class="rounded-1"
        *nzSpaceItem
        nz-button
        nzType="primary"
        (click)="OpenChooseHowYouWouldLiketoStart()"
      >
        New Job
      </button>
    </nz-space>
    </div>
  </div> 
  

  <nz-modal
  nzWidth="38%"
  [nzFooter]="null"
  nzCentered
  [(nzVisible)]="ChooseHowYouWouldLike"
  [nzTitle]="surveys1"
  (nzOnCancel)="CloseChooseHowYouWouldLiketoStart()"
  [nzStyle]="{ top: '78px', 'overflow-y': 'hidden','height': '75vh','max-height': '75vh', }"
>
  <ng-template #surveys1>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0 fw-medium">Choose How You Would Like to Start</h2>
    </div>
  </ng-template>

  <div
    class="cantent d-flex justify-content-center align-items-center"
    *nzModalContent
  >
    <div nz-row nzJustify="space-around" >
      <nz-space>
        <div nz-col *nzSpaceItem nzSpan="12">
          <button 
          (click)="JobCreateScratch()"
            class="NewJobProp d-flex flex-column align-items-center justify-content-center"
            nz-button 
            nzType="default"
          >
            <div
              nz-icon
              nzType="plus-circle"
              class="fs-3 mb-2"
              nzTheme="outline"
            ></div>
            <span>Create from Scratch</span>
          </button>
        </div>
        <div nz-col *nzSpaceItem nzSpan="12">
          <button (click)="OpenJobTEmplate()"
            class="NewJobProp d-flex flex-column align-items-center justify-content-center"
            nz-button
            nzType="default"
          >
            <div class="bi bi-copy fs-3 mb-2"></div>
            <span>Create from Template</span>
          </button>
        </div>
      </nz-space>
    </div>
  </div>
</nz-modal>
