export enum FiltersName {
  INTERNAL_USER = 'Internal User',
  LEAD_OPPR_ACT_TEMPL = 'Lead Opportunity Action Template',
  LEAD_OPPR_ACT_VIEW = 'Lead Opportunity Action View',
  LEAD_OPPR_LEAD_PROP = 'Lead Opportunity Lead Property',
  LEAD_OPPR_LIST_VIEW = 'Lead Opportunity List View',
  LEAD_OPPR_MAP = 'Lead Opportunity Map',
  LEAD_OPPR_PROP_TEMP = 'Lead Opportunity Property Template',
  PROJECT_MANG_SELEC_CARD_V = 'Project Management Select Card View',
  PROJECT_MANG_SELEC_LIST = 'Project Management Select List',
  PROJECT_MANG_SITE_DIAR_DAILY_L = 'Project Management Site Diary Daily Log',
  PROJECT_MANG_VARIATION = 'Project Management Variation',
  PROJECT_MANG_SCHED_BASELINE = 'Project Management Schedule Baseline',
  PROJECT_MANG_SCHEDULE = 'Project Management Schedule',
  SUB_VENDOR = 'Sub Vendor',
  CUSTOMER_CONTACT = 'Customer Contact',
  COMMENT_V_BY_CONVERSATION = 'Comment View by Conversation',
  JOBS_JOBS_LIST = 'Job list',
  Surveys_Individual = 'Surveys_Individual',
  Messaging_RFIs = 'Messaging_RFIs',
  Messaging_Notification_History = 'Messageing NOtification History',
  Purchase_Order = 'Purchase Order',
  Financial_Tenders = 'Financial Tender',
  Owner_Invoice = 'Owner Invoice',
  Cost_Inbox = 'Cost Inbox',
  MESSAGES = "MESSAGES",
  Financial_Bills = "Financial_Bills",
  PROJECT_MANG_SELEC_HIERARCHY = 'Project Management Select Hierarchy',
  Job_Summary = "Job Summary",
  LEAD_OPPR_ACT_CAL = "Lead Opportunity Activity Calendar",
  PROJECT_MANG_SELEC_ALLOWA_ASSIGN = "PROJECT_MANG_SELEC_ALLOWA_ASSIGN",
  PROJECT_MANG_WARRANTY = "PROJECT_MANG_WARRANTY",
  PROJECT_MANG_TIMECLOCK = "PROJECT_MANG_TIMECLOCK",
  PROJECT_MANG_SELEC_ALLOWA_UNASSIGN ='Project Management Selection Allowance UnAssinged',
  // Messaging_Servey = "MESSAGING SERVEY",
  PROJECT_MANG_Todos = "todos",
  Project_Man_WorkDay_Excep = "WORKDAYEXCEPTON",
  Messaging_NOTIFICATION_HISTORY = "MessagingNOTIFICATIONHISTORY"
}
