import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { CompanychangeOrderPredifinedcodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';

@Component({
  selector: 'app-change-orders',
  templateUrl: './change-orders.component.html',
  styleUrls: ['./change-orders.component.css']
})
export class ChangeOrdersComponent implements OnInit {
  text: string | undefined;
  formFieldValues: any = {};
  demoValue = 3;
  customfieldinfo: boolean = false;
  ChangeOrder: boolean = true;
  editorValue: string = '';
  showInitialField: boolean = true;
  showFirstField = false;
  @Output() cancel = new EventEmitter<void>();

  preDefinedDataCodes = {
   
    ChangeorderCode: CompanychangeOrderPredifinedcodes.COSRSCO,
  
  };
  constructor(
    private  LeadOpportunitiesService: LeadOpportunitiesService,
    private lookUpFilterService: LookupFilterService,

  ) {}

  ngOnInit(): void {
    this.leadProFormGroup();
    this.intialload();
  }

  // showSecondField = false;

  showFirstFieldy() {
    this.showInitialField = false;
    this.showFirstField = true;
  }

  // Function to show the second field and hide the first field
  showSecondFieldy() {
    this.showFirstField = false;
    // this.showSecondField = true;
  }



  CustomFieldInfo() {
    this.customfieldinfo = true;
  }
  handleCancel2(): void {
    this.ChangeOrder = false;
  }




  leadProFormGroup(): void {
    this.formFieldValues = {
      globalId: '00000000-0000-0000-0000-000000000000',
      companyNameId: '',
      changeOrderPrefix: 0,
      noOfDays: 0,
      maxItem: 0,
      allowOwnerChangeOrderRequestsByDefault: false,
      showOwnerinternallyDeclinedChangeOrders: false,
      showLineItemsToOwner: false,
      requireSignatureOnChangeOrderSetupId: 0,
      changeOrderApprovalDisclaimer: '',
      defaultChangeOrderDescription: '',
      price: false,
      discussions: false,
      lineItems: false,
      performingSubsVendors: false,
      signatureLines: false,
      customFields: false,
    }
  }


  save() {
    console.log("Forfields data", this.formFieldValues);
    this.LeadOpportunitiesService.PostCompanyChangeOrder(this.formFieldValues).subscribe(
      (response) => {
        console.log("Show company data", response.result);
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }
  @Input() pageId: number;
  keywordOptions: { label: string, value: string }[] = [];

intialload(){
  this.lookUpFilterService.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe(res => {
    let statusValues = res.result.filter(x => x.code === this.preDefinedDataCodes.ChangeorderCode.code);
   
    this.keywordOptions = statusValues.map(res => ({
      label: res.description,
      value: res.code.toString(),
    }));
    
    
  });
  
}
PAGE_ID: number = 30014;


// initLoad() {
//   this.lookUpFilterService.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe(
//     (res) => {

//       // -----------single Select ---------------

//       let activityTypeValues = res.result.filter(
//         (x) => x.code === this.preDefinedDataCodes.ChangeorderCode.code
//       );
     


//       // -----------single Select --------------

//       // this.activityType = activityTypeValues;
//       //   label: LOP.description,
//       //   value: LOP.id.toString(),
//       // }));

     
//       this.activityType = activityTypeValues;
//       const reminder = activityTypeValues.find(
//         (item) => item.description === 'Phone Call'
//       );

//       if (reminder) {
//         const activity = reminder.id;
//         this.formFieldValues.activityTypeId = activity
//       } else {
//         console.log("Reminder '3 Days Before' not found.");
//       }

    

//     }
//   );
// }
 




}
