import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SalesLeadOpportunityCustomFieldComponent } from '../sales-lead-opportunity-custom-field/sales-lead-opportunity-custom-field.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ToDoInformationService } from 'src/Service/Project-Management/to-do-information.service';
import { ToDoSetupResponse } from 'src/Models/Project-Management/projectManagement';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-to-dos',
  templateUrl: './to-dos.component.html',
  styleUrls: ['./to-dos.component.css']
})
export class ToDosComponent implements OnInit{
  @Output() cancel = new EventEmitter<void>();
  selectedValue = null;
  customfieldinfo:boolean = false;
  todos: boolean =true;
  newToDoSetupFormValues: any = {};
  toDoCompSetRes: ToDoSetupResponse;

  constructor(
    private modal: NzModalService,
    private todoService: ToDoInformationService,
    public toastsService: NzMessageService,
  ) {}
  
  ngOnInit(){

    this.newToDoSetupFormValues = {
      companyParameterId: 1,
      sendPastDueToDoReminderUpToDay: '',
      requireAllChecklistItemsToBeCompleteInOrderToCompleteAtoDo: true,
      moveCompletedChecklistItemsToTheBottom: false
    }

  }

  saveMethodToDoSetup(){

    this.todoService.postDataToDoToDoSetupCompSet(this.newToDoSetupFormValues).subscribe(response =>{
      this.toDoCompSetRes = response.result;
      
      console.log('postDataToDoToDoSetupCompSet',this.toDoCompSetRes);
      this.toastsService.success('ToDoSetup Saved Successfully!');
      this.todos = false
      this.cancel.emit();
    })
    

  }


  closeToDos(): void {
  this.cancel.emit();
  }

  CustomFieldInfo(){
    this.customfieldinfo = true;
  }
  handleCancel2(): void {
    this.customfieldinfo = false;
  }

  TagModelOpen(): void {
      const modalRef = this.modal.create({
        nzContent: SalesLeadOpportunityCustomFieldComponent,
        nzFooter: null, 
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy(); 
      });
  }
}
