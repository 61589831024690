<nz-modal
  [(nzVisible)]="selectionChoicevisible"
  [nzTitle]="selectionChoiceModelTitle"
  [nzFooter]="selectionChoiceModelFooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    maxHeight: '69vh',
    'overflow-y': 'auto',
    'overflow-x': 'hidden',
  }" [nzStyle]="{ top: '25px'}" [nzWidth]="'96%'"
  (nzOnCancel)="selectionChoiceModelCancel()"
  >
    <ng-template #selectionChoiceModelTitle >
      <div class="row">
        <h1 class="p-0 m-0 react">Selection Choice</h1>
      </div>
    </ng-template>

    <div class="content content-set-background " *nzModalContent >
      <div class="top-of-modal mb-2"></div>
      <nz-alert class="rounded-3 mb-3 mt-2 error-message" *ngIf="isSubmitted && errorFieldsItem.length > 0 || errorFields.length > 0" nzType="error" nzShowIcon [nzMessage]="message" [nzDescription]="errorDescriptionTemplate"></nz-alert>
    <ng-template #message>
      <p class="mt-1">Please correct the following fields:</p>
    </ng-template>
    <ng-template #errorDescriptionTemplate>
      <ul *ngFor="let error of errorFields" class="m-0">
        <li class="famly-inn p-0 m-0 ">
          <span class="ms-0">{{ error.label }}:</span>
          <span class="ms-1">{{ error.message }}</span>
        </li>
      </ul>
      <ul class="m-0" *ngIf="isSubmitted && errorFieldsItem.length > 0">
        <li class="famly-inn p-0 m-0">
          <span class="ms-0">Line Items :</span>
        </li>
        <ul class="m-0" *ngFor="let error of errorFieldsItem">
          <li class="famly-inn p-0 m-0">
            <span class="ms-0">{{ error.label }}</span>
            <span class="ms-1">{{ error.message }}</span>
          </li>
        </ul>
      </ul>
    </ng-template>
    <div class="spinner-wrapper" *ngIf="isLoading">
      <nz-spin nzSimple [nzSpinning]="true" [nzSize]="'large'"></nz-spin>
    </div>
      <div class="ng-container ">
      <nz-card [nzSize]="'small'" nzBorderless="true" [nzTitle]="Choice" [nzExtra]="status">
        <ng-template #Choice>
          <h5 class="Card-Title-Set">Choice Details</h5>
        </ng-template>
        <div nz-row [nzGutter]="24">
          <div nz-col nzSpan="10">
            <div class="row div-set">
              <!-- Previous Button -->
            <!-- Conditional Rendering -->
                <div *ngIf="selectedFilesAttachment?.length === 0; else carouselTemplate">
                  <ng-container>
                    <div class="padding-horizontal-sm">
                      <div class="Thumbnail flex align-items-center justify-content-center">
                        <div class="Thumbnail--fallback" [ngStyle]="{ width: '100%', height: '500px' }">
                          <div class="Thumbnail--fallback Thumbnail--fallback-default" [ngStyle]="{ width: '100%', height: '397px' }">
                            <img 
                              alt="Buildertrend Logo" 
                              src="data:image/svg+xml,%3c?xml%20version='1.0'%20encoding='UTF-8'?%3e%3csvg%20id='Layer_1'%20data-name='Layer%201'%20xmlns='http://www.w3.org/2000/svg'%20version='1.1'%20viewBox='0%200%201000%201232'%3e%3cdefs%3e%3cstyle%3e%20.cls-1%20{%20fill:%20%23acb8c3;%20}%20.cls-1,%20.cls-2%20{%20stroke-width:%200px;%20}%20.cls-2%20{%20fill:%20%230e0f10;%20}%20%3c/style%3e%3c/defs%3e%3cpath%20class='cls-2'%20d='M411.001,230.943h-209.645V.334H0v637.164c0,.006.001.01.001.017.009,224.526,182.025,406.538,406.554,406.538s406.552-182.021,406.552-406.554c0-223.042-179.631-404.159-402.106-406.555ZM406.555,842.087c-112.992,0-204.588-91.598-204.588-204.589s91.595-204.588,204.588-204.588,204.588,91.597,204.588,204.588-91.598,204.589-204.588,204.589Z'/%3e%3cpath%20class='cls-1'%20d='M696.61,120.656l-40.89,70.823c156.484,87.466,262.307,254.732,262.307,446.74,0,282.479-228.995,511.474-511.474,511.474-95.891,0-185.603-26.413-262.302-72.324l-40.882,70.809c88.79,52.989,192.485,83.488,303.184,83.488,327.228,0,593.447-266.219,593.447-593.447,0-221.94-122.486-415.78-303.39-517.564Z'/%3e%3c/svg%3e" 
                              width="144" 
                              class="Thumbnail--BTLogo-light"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>

                <ng-template #carouselTemplate>
                  <ng-container *ngIf="selectedFilesAttachment?.length > 0">
                    <img class="d-block w-100 rounded-img" 
                       [src]=" FileSetName"
                       alt="Slide" 
                       height="280px">
                  </ng-container>
                </ng-template>

 <!-- Previous Button -->
 <!-- <div class="col-1 d-flex align-items-center justify-content-center carousel slide" *ngIf="TestAttachments?.length > 1">
  <span role="button" aria-label="left" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev"
    class="anticon anticon-left dark slick-arrow slick-prev" style="display: block;">
    <span class="carousel-control-prev-icon" aria-hidden="true" style="filter: invert(100%); height: 20px;"></span>
    <span class="visually-hidden">Previous</span>
  </span>
</div> -->

<!-- Carousel -->
<!-- <div [ngClass]="{'col-12': TestAttachments?.length === 1, 'col-10': TestAttachments?.length > 1}" class="CardPicSet">
  <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators" *ngIf="TestAttachments?.length > 1">
      <button *ngFor="let fileq of TestAttachments; let i = index"
              type="button" 
              [attr.data-bs-target]="'#carouselExampleIndicators'" 
              [attr.data-bs-slide-to]="i" 
              [class.active]="i === 0"
              [attr.aria-current]="i === 0 ? 'true' : 'false'"
              [attr.aria-label]="'Slide ' + (i + 1)">
      </button>
    </div>

    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let File of TestAttachments let i = index" [class.active]="i === 0">
        <ng-container *ngIf="File.filePath && File.filePath.trim().length > 0">
          <img class="d-block w-100 rounded-img" 
             [src]="File.filePath"
             alt="Slide {{i + 1}}" 
             height="280px">
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="col-1 d-flex align-items-center justify-content-center carousel slide" *ngIf="TestAttachments?.length > 1">
  <span role="button" aria-label="right" data-bs-target="#carouselExampleIndicators" data-bs-slide="next"
    class="anticon anticon-left dark slick-arrow slick-next" style="display: block;">
    <span class="carousel-control-next-icon" aria-hidden="true" style="filter: invert(100%); height: 20px;"></span>
    <span class="visually-hidden">Next</span>
  </span>
</div>



                </ng-template> -->
          <!-- Carousel Template -->
         

      </div>
      <div class="row mt-1 ms-1">
        <div class="col-11">
          <label class="para-lable">Attachments</label>
          <app-create-attachments-common [TestAttachment__]="TestAttachment" (filesUploaded)="onFilesUploaded($event)"></app-create-attachments-common>
       </div>
      </div>
    </div>
    <div nz-col nzSpan="14">
      <div class="row">
        <div class="col-6 mt-2">
          <label class="para-lable">Selectin Title :</label>
          <span class="p-1">{{this.SelectTitleSendChoice || this.titlebySelection || this.choiceGetbyIdSelectionTitle}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label class="para-lable">Category :</label>
          <span class="p-1">{{this.CategorySendChoice || this.categoryBySelection || this.choiceGetbyIdSelectionCategory}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-6 ">
          <label class="para-lable">Location :</label>
          <span class="p-1">{{this.LocationSendChoice || this.locationBySelection || this.choiceGetbyIdSelectionLocation}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label class="para-lable">Choice Title <span class="text-danger">*</span></label>
          <nz-form-control class="p-0">
            <input
              #titleInput
              nz-input
              [(ngModel)]="choiceSelectionFormFieldValues.title"
              name="title"
              required
              (ngModelChange)="onFieldChange('name', $event)"
              [nzStatus]="validationStates['name']"
              autocomplete="off"
              class="formField"
            />
          </nz-form-control>
          <div *ngIf="validationStates['name'] === 'error'" class="text-danger">
            {{ validationMessages["name"] }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label class="para-lable">
            Product Link
            <i class="bi bi-info-circle-fill text-secondary" style="font-size: 14px;" nz-popover [nzPopoverContent]="contentTemplate"
              nzPopoverPlacement="top"></i>

            <ng-template #contentTemplate>
              <div class="rounded-2">
                <p style="height: auto; width: 200px">
                  The <b>Product Link</b> allows you to cut and paste
                  a link to more product information on the web for
                  this option. This is a great way to let the buyer
                  browse information quickly and easily.
                </p>
              </div>
            </ng-template>
          </label>
          <div class="input-group mb-1">
            <nz-input-group nzCompact>
              <input 
              type="text" 
              class="formField" 
              [(ngModel)]="choiceSelectionFormFieldValues.productLink"
              name="productLink"
              style="width: 80%; height: 30px;"  
              aria-label="Website URL"
              aria-describedby="basic-addon1"
              placeholder="">
              <button nz-button nzType="default" style=" cursor: pointer;width:20%;height: 30px;border-radius: 0px 4px 4px 0px;" (click)="openWebsite()">View <i class="bi bi-box-arrow-in-down-right ms-1"></i></button>
            </nz-input-group>
        </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 mt-1">
          <div class="card rounded-0 border-0">
            <div class="row">
              <div class="col-6 text-center mt-4" style="border-right: solid 1px #ccc;">
                <span class="fw-bold fs-7">Builder Cost</span>
                <div class="chooice">
                  ${{ builderCostFeild || '0.00' }} 
                </div>
              </div>
              <div class="col-6 text-center mt-4">
                <span class="fw-bold fs-7">Owner Price</span>
                <div class="chooice">
                  ${{ ownerPriceFeild || '0.00' }} 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 mt-3">
          <label 
           nz-checkbox 
           class="para-lable1"
           [(ngModel)]="choiceSelectionFormFieldValues.isInCludeInBudget"
           name="isInCludeInBudget"
           >Include in Budget</label>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <nz-divider></nz-divider>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label class="fw-bold">Vendor</label>
          <!-- <p 
          [(ngModel)]="choiceSelectionFormFieldValues.subVendorId"
          name="subVendorId"
          class="para-lable1 mt-1">None Assigned to Parent Selection</p> -->
          <!-- <nz-tree-select 
          style="width: 100%" 
          [(ngModel)]="choiceSelectionFormFieldValues.subVendorId" 
          name="subVendorId"
          [nzNodes]="vendersNodes" 
          nzShowSearch 
          nzAllowClear="false" 
          nzCheckable 
          nzCheckAll="true"
          nzPlaceHolder="Select Status"
          >
        </nz-tree-select> -->
        <nz-select
        nzShowSearch
        [lang]="true"
        [(ngModel)]="choiceSelectionFormFieldValues.subVendorId" 
        name="subVendorId"
        nzAutoFocus="true"
        nzPlaceHolder="--None--"
      >
        <nz-option
          *ngFor="let option of vendorList"
          [nzLabel]="option.title"
          [nzValue]="option.id" 
        ></nz-option>
      </nz-select>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-6">
          <label class="para-lable">Installer </label>
          <div class="input-group">
            <nz-select 
             style="width: 100%" 
             nzShowSearch 
             [lang]="true" 
             nzAutoFocus="true"
             nzPlaceHolder="All Installers On Selection" 
             [(ngModel)]="choiceSelectionFormFieldValues.installerId"
             name="installerId"
             [(ngModel)]="selectedValue">
              <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</nz-card>
<ng-template #status>
  <span class="status-text status-tag " *ngIf="choiceSelectionFormFieldValues.statusId">
    <div
        *ngIf="choiceSelectionFormFieldValues.statusId === 381"
        class="status-text status-tag"
        [ngStyle]="statussetupColorMap[SelectionStatusCode.UnReleased.code]">
        Unreleased
    </div>
    <div
        *ngIf="choiceSelectionFormFieldValues.statusId === 384"
        class="status-text status-tag"
        [ngStyle]="statussetupColorMap[SelectionStatusCode.PriceTBD.code]">
        Price TBD
    </div>
    <div
        *ngIf="choiceSelectionFormFieldValues.statusId === 386"
        class="status-text status-tag"
        [ngStyle]="statussetupColorMap[SelectionStatusCode.SubVendorPrice.code]">
        Sub/Vendor Price Needed
    </div>
  </span>
</ng-template>


<nz-card [nzSize]="'small'" nzBorderless="true" class="mt-3" [nzTitle]="PriceDetails">
  <ng-template #PriceDetails>
    <h4 class="Card-Title-Set">Price Details</h4>
  </ng-template>
  <nz-radio-group [(ngModel)]="priceInformationradioValue" (ngModelChange)="priceInformationradioButtons()">
    <ng-container *ngIf="choiceResponses?.IsRequestFromSubVendor === true; else nextRadio">
      <!-- Flat Fee -->
      <label nz-radio-button nzValue="A" [nzDisabled]="choiceResponses?.IsRequestFromSubVendor === true">
        <input type="radio" [(ngModel)]="priceInformationradioValue" name="radioGroup" (click)="showDeleteConfirm()" />
        Flat Fee
      </label>
  
      <!-- Line Item -->
      <label nz-radio-button nzValue="B" [nzDisabled]="choiceResponses?.IsRequestFromSubVendor === true">
        <input type="radio" [(ngModel)]="priceInformationradioValue" name="radioGroup" />
        Line Item
      </label>
    </ng-container>
  
    <ng-template #nextRadio>
      <!-- Flat Fee -->
      <label nz-radio-button nzValue="A">
        <input type="radio" [(ngModel)]="priceInformationradioValue" name="radioGroup" (click)="showDeleteConfirm()" />
        Flat Fee
      </label>
  
      <!-- Line Item -->
      <label nz-radio-button nzValue="B">
        <input type="radio" [(ngModel)]="priceInformationradioValue" name="radioGroup" />
        Line Item
      </label>
    </ng-template>
    
    <!-- Request from Sub/Vendor -->
    <label nz-radio-button nzValue="C" (click)="handleVendorAction()">
      <input type="radio" [(ngModel)]="choiceSelectionFormFieldValues.isRequestFromSubVendor" name="IsRequestFromSubVendor" />
      Request From Sub/Vendor
    </label>
  </nz-radio-group>
  
  <!-- Info Icon with Popover -->
  <i class="bi bi-info-circle-fill mt-1 ms-1 text-secondary" nz-popover [nzPopoverContent]="contentTemplate" nzPopoverPlacement="right"></i>
  <ng-template #contentTemplate>
    <div class="rounded-2">
      <p style="height: auto; width: 180px">
        Claim numbers are auto-assigned based on your previous numbers used per job.
      </p>
    </div>
  </ng-template>
  
  <!-- Reset Row -->
  <div class="row mt-2" *ngIf="choiceSelectionFormFieldValues.statusId === 386 && choiceSelectionFormFieldValues.isRequestFromSubVendor">
    <div class="col-12">
      <span>
        <svg viewBox="64 64 896 896" focusable="false" data-icon="unlock" width="1em" height="1em" fill="currentColor" aria-hidden="true">
          <path d="M832 464H332V240c0-30.9 25.1-56 56-56h248c30.9 0 56 25.1 56 56v68c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-68c0-70.7-57.3-128-128-128H388c-70.7 0-128 57.3-128 128v224h-68c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V496c0-17.7-14.3-32-32-32zm-40 376H232V536h560v304zM484 701v53c0 4.4 3.6 8 8 8h40c4.4 0 8-3.6 8-8v-53a48.01 48.01 0 10-56 0z"></path>
        </svg>
      </span>
      Requested {{ vendorHoldDateByChoice | date: 'EEE, MMM d - h:mm a' }}
      <button nz-button nzType="link" (click)="resetVendorSelectedModal()">Reset</button>
    </div>
  </div>
  
  <!-- <i class="bi bi-info-circle-fill mt-1 ms-1 text-secondary" nz-popover [nzPopoverContent]="contentTemplate" nzPopoverPlacement="right"></i>
<ng-template #contentTemplate>
  <div class="rounded-2">
    <p style="height: auto; width: 180px">
      Claim numbers are auto-assigned based on your previous numbers used per job.
    </p>
  </div>
</ng-template>
  <div class="row mt-2" *ngIf="choiceResponses?.IsRequestFromSubVendor === true">
    <div class="col-12">
      <span>
        <svg viewBox="64 64 896 896" focusable="false" data-icon="unlock" width="1em" height="1em" fill="currentColor" aria-hidden="true">
          <path d="M832 464H332V240c0-30.9 25.1-56 56-56h248c30.9 0 56 25.1 56 56v68c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-68c0-70.7-57.3-128-128-128H388c-70.7 0-128 57.3-128 128v224h-68c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V496c0-17.7-14.3-32-32-32zm-40 376H232V536h560v304zM484 701v53c0 4.4 3.6 8 8 8h40c4.4 0 8-3.6 8-8v-53a48.01 48.01 0 10-56 0z"></path>
        </svg>
      </span>
      Requested {{ vendorHoldDateByChoice | date: 'EEE, MMM d - h:mm a' }}
      <button nz-button nzType="link" (click)="resetVendorSelectedModal()">Reset</button>
    </div>
  </div> -->
  
  <div *ngIf="priceInformationradioValue === 'A' || priceInformationradioValue === 'C'" class="row gx-3 mt-2">
    <div class="col-3">
      <label class="para-lable">Builder Cost</label>
      <nz-input-group nzAddOnBefore="$">
        <input
          nz-input
          appDollarFormatter
          [(ngModel)]="builderCostFeild"
          name="builderCostFeild"
          [disabled]="this.choiceSelectionFormFieldValues.isRequestFromSubVendor"
        />
      </nz-input-group>
    </div>
  
    <div class="col-2">
      <label class="para-lable">Owner Price</label>
      <nz-input-group nzAddOnBefore="$">
        <input
          nz-input
          appDollarFormatter
          [(ngModel)]="ownerPriceFeild"
          name="ownerPriceFeild"
          [disabled]="isSetPriceLater"
        />
      </nz-input-group>
    </div>
  
    <div class="col-3 mt-3" style="padding-top: 20px;">
      <label 
        nz-checkbox 
        [(ngModel)]="isSetPriceLater"
        name="isSetPriceLater" 
        class="para-lable1"
      >
      Set Price Later
     </label>
      <i class="bi bi-info-circle-fill mt-1  text-secondary"
        nz-popover [nzPopoverContent]="entered"
        nzPopoverPlacement="right"></i>
      <ng-template #entered>
        <div class="rounded-2">
          <p style="height: auto; width: 200px">
            NOTE: Choice cannot be selected until price is entered.
          </p>
        </div>
      </ng-template>
    </div>
  </div>
  

    <div *ngIf="priceInformationradioValue === 'B' " class="row mt-2">
     <!-- grid choice selection -->
     <div nz-row class="pt-3">
      <div nz-col nzSpan="24">
        <label nz-checkbox [(ngModel)]="choiceSelectionFormFieldValues.isShowLineItemToOwner">
          Show Line Items To Owner
        </label>
        <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="onLineItemsToOwnerooltipContent" nzPopoverPlacement="right"></i>
        <ng-template #onLineItemsToOwnerooltipContent>
        <div style="border-radius: 10px; width: 210px; font-size: 13px; line-height: 25px;">
          <div nz-row>
            <div nz-col nzSpan="24">
              <div class="famly-inn">
                Owner will not be permitted to see builder cost or markup. Description, Quantity, Unit Price and Price will be shown for each line item.
              </div>
            </div>
          </div>
        </div>
        </ng-template>
      </div>
    </div>
    <div nz-row class="pt-3">
      <div nz-col nzSpan="24">
        <nz-table [nzFooter]="customFooter" nzFooter="Footer" [nzBordered]="false" nzSize="small" #editRowTable nzShowPagination="false" [nzScroll]="{ x: '1625px' }" [nzData]="listOfData">
          <thead>
            <tr class="custom-table-row-th">
              <th nzLeft nzWidth="9%">
              </th>
              <th nzLeft nzWidth="60%" class="sub-text1">Items</th>
              <th nzWidth="35%" class="sub-text1">Cost Type</th>
              <th nzWidth="25%" class="sub-text1 text-end">Unit Cost</th>
              <th nzWidth="24%" class="sub-text1 text-end">Quantity</th>
              <th nzWidth="20%" class="sub-text1 text-start">Unit</th>
              <th nzWidth="23%" class="sub-text1 text-end">Builder Cost</th>
              <th nzWidth="30%" class="sub-text1">Markup</th>
              <th nzWidth="25%" class="sub-text1 text-end">Owner Price</th>
              <th nzWidth="25%" class="sub-text1 text-end">Margin</th>
              <th nzRight nzWidth="18%" class="sub-text1"></th>
            </tr>
          </thead>
          <tbody>
              <ng-container *ngFor="let item of items; let i = index">
                  <tr class="actionRow first-tr">
                    <td nzLeft nzWidth="9%">
                    </td>
                    <td nzLeft nzWidth="60%">
                      <div class="row">
                        <div class="col-12">
                          <label class="sub-text">Title</label>
                          <nz-form-item>
                            <nz-form-control>
                              <input  #titleInput nz-input 
                                [(ngModel)]="items[i].title"
                                name="title_{{ i }}"
                                required 
                                (ngModelChange)="onFieldChangeItem('title', $event, i)"
                                [nzStatus]="validationStatesItem['title_' + i]" class="formField" />
                            </nz-form-control>
                            <div *ngIf="validationStatesItem['title_' + i] === 'error'" class="text-danger">
                              {{ validationMessagesItem['title_' + i] }}
                            </div>
                          </nz-form-item>
                        </div>
                      </div>
                    </td>
                    <td nzWidth="35%">
                        <div class="row">
                            <div class="col-12">
                              <label class="sub-text">Cost Type</label>
                              <nz-tree-select
                              [(ngModel)]="item.costTypeItemParameters" name="costTypeItemParameters_{{ i }}"
                              style="width: 100%"
                              [nzNodes]="JobGroupNodes"
                              [nzShowSearch]="true"
                              nzAllowClear="false"
                              nzCheckable
                              nzDropdownClassName="dropdownStyle"
                              [nzAllowClear]="false">
                              </nz-tree-select>
                            </div>
                        </div>
                    </td>
                    <td nzWidth="29%">
                        <div class="row">
                            <div class="col-12">
                                <label class="sub-text">Unit Cost</label>
                                <div class="input-group">
                                  <nz-input-group nzAddOnBefore="$">
                                    <input
                                    nz-input
                                    [(ngModel)]="item.unitCost" name="unitCost_{{ i }}"
                                    appDollarFormatter
                                    #stateInput="ngModel"
                                    class="form-control formField" 
                                  />
                                  </nz-input-group>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td nzWidth="24%">
                        <div class="row">
                            <div class="col-12">
                                <label class="sub-text">Quantity</label>
                                <nz-input-number [(ngModel)]="item.quantity" name="quantity_{{ i }}"></nz-input-number>
                            </div>
                        </div>
                    </td>
                    <td nzWidth="20%">
                        <div class="row">
                            <div class="col-12">
                                <label class="sub-text">Unit</label>
                                <input [(ngModel)]="item.unit" name="unit_{{ i }}" type="text"nz-input class="formField"/>
                            </div>
                        </div>
                    </td>
                    <td nzWidth="23%">
                        <div class="row">
                            <div class="col-12">
                                <label class="sub-text">Builder Cost</label>
                                <label class="sub-text pt-2" [(ngModel)]="item.builderCost" name="builderCost_{{ i }}">$0.00</label>
                            </div>
                        </div>
                    </td>
                    <td nzWidth="30%">
                        <div class="row gx-0">
                            <div class="col-10">
                                <label class="sub-text">Markup</label>
                            </div>
                            <div class="col-2">
                            </div>
                        </div>
                        <div class="row gx-0">
                            <div class="col-7">
                              <input
                              nz-input
                              class="formField"
                              appDollarFormatter
                              [(ngModel)]="item.markupValue"
                              name="markupValue"
                              (ngModelChange)="updateBuilderCost(item)"
                            />
                            </div>
                            <div class="col-5">
                              <nz-select nzShowSearch nzAutoFocus="true" [(ngModel)]="item.markupId" name="markupId_{{ i }}">
                                <nz-option *ngFor="let option of markupOption" [nzLabel]="option.description" [nzValue]="option.id"></nz-option>
                              </nz-select>
                            </div>
                        </div>
                    </td>
                    <td nzWidth="25%">
                        <div class="row">
                            <div class="col-12">
                                <label class="sub-text">Owner Price</label>
                            </div>
                        </div>
                    </td>
                    <td nzWidth="25%">
                        <div class="row">
                            <div class="col-12">
                                <label class="sub-text">Margin</label>
                            </div>
                        </div>
                    </td>
                    <td nzRight nzWidth="18%">
                      <span
                      (click)="deleteRow(i)"
                      nz-icon
                      nzType="delete"
                      class="text-primary text-center fs-4 k-cursor-pointer"
                      nzTheme="fill"
                    ></span>
                    </td>
                  </tr>
                  <tr class="actionRow">
                    <td nzLeft nzWidth="9%">
                    </td>
                    <td nzLeft nzWidth="60%">
                        <div class="row">
                            <div class="col-12">
                              <label class="sub-text">Cost Code <span class="ms-1 fw-bold text-danger">*</span></label>
                              <nz-form-item>
                                <nz-form-control [nzErrorTip]="validationMessagesItem['costCodeId_' + i]" [nzValidateStatus]="validationStatesItem['costCodeId_' + i]">
                                  <nz-select
                                  (ngModelChange)="onFieldChangeItem('costCodeId', $event, i)"
                                    nzShowSearch
                                    [(ngModel)]="items[i].costCodeId"
                                    name="costCodeId">
                                    <nz-option *ngFor="let option of leadOptions" [nzLabel]="option.title" [nzValue]="option.id"></nz-option>
                                  </nz-select>
                                </nz-form-control>
                              </nz-form-item>




                              <!-- <nz-form-item>
                                <nz-form-control [nzErrorTip]="validationMessages['costTypeId']" [nzValidateStatus]="validationStates['costTypeId']">
                                  <nz-select
                                  (ngModelChange)="onFieldChangeItem('costTypeId', $event)"
                                    nzShowSearch
                                    [(ngModel)]="item.costTypeId"
                                    id="costTypeId"
                                    name="costTypeId"
                                  >
                                  <nz-option *ngFor="let option of leadOptions" [nzLabel]="option.title" [nzValue]="option.id"></nz-option>
                                  </nz-select>
                                </nz-form-control>
                              </nz-form-item> -->
<!-- 



                              <nz-form-item>
                                <nz-form-control [nzErrorTip]="validationMessages['costTypeId' + i]" [nzValidateStatus]="validationStates['costTypeId' + i]">
                                    <nz-select
                                        required (ngModelChange)="onFieldChangeItem('costTypeId', $event)"
                                        nzShowSearch
                                        [(ngModel)]="item.costTypeId"
                                        name="costTypeId"
                                    >
                                        <nz-option *ngFor="let option of leadOptions" [nzLabel]="option.title" [nzValue]="option.id"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item> -->
                            </div>
                        </div>
                    </td>
                    <td nzWidth="55%"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td nzRight></td>
                  </tr>
                  <tr class="actionRow">
                    <td nzLeft nzWidth="9%"></td>
                    <td nzLeft nzWidth="60%">
                        <div nz-row style="margin-top: -14px;margin-left: -13px;">
                            <div nz-col nzSpan="3">
                                <a nz-button nzType="link">Add</a>
                            </div>
                            <div nz-col nzSpan="3">
                                <a nz-button nzType="link">Edit</a>
                            </div>
                        </div>
                    </td>
                    <td nzWidth="55%"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td nzRight></td>
                  </tr>
                  <tr class="actionRow">
                    <td [colSpan]="1">
                    </td>
                    <td [colSpan]="5">
                        <label class="sub-text">Description</label>
                        <textarea [(ngModel)]="item.description" name="description_{{ i }}" nz-input [nzAutosize]="{ minRows: 2, maxRows: 10 }" class="formField" style="resize: none;border: none;"></textarea>
                    </td>
                    <td [colSpan]="5">
                        <label class="sub-text">Internal Notes</label>
                        <textarea [(ngModel)]="item.internalnoter_" name="internalnoter_{{ i }}" nz-input [nzAutosize]="{ minRows: 2, maxRows: 10 }" class="formField" style="resize: none;border: none;"></textarea>
                    </td>
                  </tr>
              </ng-container>
              <tr class="custom-table-row">
                <td  class="border" [colSpan]="13">
                  <a (click)="addNewRow()">
                    <span class="me-2 fs-5" nz-icon nzType="plus-circle" nzTheme="fill"></span>
                    <span class="fs-6">Item</span>
                  </a>
                </td>
              </tr>
          </tbody>
            <ng-template #customFooter>
              <tfoot>
              </tfoot>
            </ng-template>
        </nz-table>
        <!-- <app-item-grid></app-item-grid> -->
      </div>
    </div>
     <!-- grid choice selection -->
    </div>

</nz-card>

<nz-card  [nzSize]="'small'" nzBorderless="true" class="mt-3" [nzTitle]="ProductDescription">
  <ng-template #ProductDescription>
    <h4 class="Card-Title-Set">Product Description</h4>
  </ng-template>
 <div class="row">
  <div class="col-12">
    <label class="para-lable ">Description</label>
    <ckeditor
    [(ngModel)]="choiceSelectionFormFieldValues.description"
    name="description"
    [editor]="Editor"
    [config]="config"
    class="size"
    id="editor"
  ></ckeditor>
  </div>
 </div>
</nz-card>

</div>
</div>
<ng-template #selectionChoiceModelFooter>
 
    <div nz-row  class="mt-2 justify-content-end">
   
    <div nz-col nzSpan="12">
      <nz-space>
          <ng-container *ngIf="choiceSelectionRes || newSeletChoiceId > 0">
            <button
            nz-button
            nzType="default"
            *nzSpaceItem
            (click)="ConfirmDelete()"
          >
            Delete
          </button>
          <button
            nz-button
            nzType="default"
            *nzSpaceItem
          >
            Copy
          </button>
          <button
            nz-button
            *nzSpaceItem
            nzType="primary"
          >
            Approve
          </button>
          </ng-container>
          <button 
           nz-button 
           *nzSpaceItem
           nzType="primary"
           [nzLoading]="isSaveAndNew"
           (click)="saveAndNewAddChoice()"
           >
           Save & New
          </button>
          <button
            nz-button
            *nzSpaceItem
            nzType="primary"
            [nzLoading]="isSave"
            (click)="addChoiceSaveMechanism()"
          >
            Save
          </button>
          <button 
          nz-button 
          *nzSpaceItem
          nzType="primary"
          [nzLoading]="isSaveAndClose"
          (click)="saveAndCloseAddChoice()"
          >
          Save & Close
        </button>
      </nz-space>
    </div>
  </div>

</ng-template>
</nz-modal>