

<nz-modal nzWidth="95%" [(nzVisible)]="estimates" [nzTitle]=""[nzFooter]="estimates2"
    (nzOnCancel)="closeEstimates()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '68vh', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '18px' }">
    <ng-template #estimates1>
        <div class="row p-0 m-0">
            <h1 class="p-0 m-0 fw-medium">Estimates </h1>
        </div>
    </ng-template>
    <div class="cantent" *nzModalContent>

<div class="row famly-inn gx-3">
    <div class="col-12">
        <div class="card border-0 mt-0">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2" >Estimate Settings</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="row border-bottom pb-5">
                            <div class="row">
                                <label class="sub-text me-2">Default Grouping</label>
                            </div>
                            <div class="row">
                                <div class="col-2">
                                    <nz-select [(ngModel)]="formfieldvalue['estimateGroupId']"
                                    name="estimateGroupId" nzShowSearch nzAllowClear nzPlaceHolder="Select a person" [(ngModel)]="selectedValue">
                                    <nz-option *ngFor="let option of defaultGroupSetting" [nzLabel]="option.name"
                                    [nzValue]="option.id"></nz-option>
                                    </nz-select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label class="sub-text me-2">Default Columns</label>
                            </div>
                        </div>
                        <div class="row" cdkDropList  >
                            <div class="col-12" *ngFor="let data of listOfData" >
                                <div class="row mt-3" cdkDrag >
                            <div class="col-12">
                                    <span class="me-3"><img  src="assets/drag.png" class="me-5" alt>
                            <label nz-checkbox ><span class="pira-label">Quantity</span></label>
                                    </span>
                            </div>
                                </div>
                                <div class="row mt-3" cdkDrag>
                            <div class="col-12">
                                    <span  class="me-3"><img  src="assets/drag.png"  class="me-5" alt>
                            <label nz-checkbox ><span class="pira-label">Unit</span></label>
                                    </span>
                            </div>
                                </div>
                                <div class="row mt-3" cdkDrag>
                            <div class="col-12">
                                    <span  class="me-3"><img  src="assets/drag.png" class="me-5" alt>
                            <label nz-checkbox ><span class="pira-label">Description</span></label>
                                    </span>
                            </div>
                                </div>
                                <div class="row mt-3" cdkDrag>
                            <div class="col-12">
                                    <span  class="me-3"><img  src="assets/drag.png"  class="me-5" alt>
                            <label nz-checkbox ><span class="pira-label">Unit Cost</span></label>
                                    </span>
                            </div>
                                </div>
                                <div class="row mt-3" cdkDrag>
                            <div class="col-12">
                                    <span  class="me-3"><img  src="assets/drag.png"  class="me-5" alt>
                            <label nz-checkbox ><span class="pira-label">Cost Type</span></label>
                                    </span>
                            </div>
                                </div>
                                <div class="row mt-3" cdkDrag>
                            <div class="col-12">
                                    <span  class="me-3"><img  src="assets/drag.png"  class="me-5" alt>
                            <label nz-checkbox ><span class="pira-label">%  Profit</span></label>
                                    </span>
                            </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Job Proposal Format Settings</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="row border-bottom pb-4">
                            <div class="row">
                                <label class="sub-text me-2">Header</label>
                            </div>
                            <div class="row">
                                <div class="col-2">
                                    <nz-select [(ngModel)]="formfieldvalue['headerSetupId']"
                                    name="headerSetupId"
                                        nzShowSearch
                                        nzAllowClear
                                        nzPlaceHolder="Select a person"

                                    >
                                    <nz-option *ngFor="let option of header" [nzLabel]="option.name"
                                    [nzValue]="option.id"></nz-option>
                                    </nz-select>
                                </div>
                            </div>
                            <div class="row">
                                <label class="sub-text me-2">Content</label>
                            </div>
                            <div class="row">
                                <div class="col-2">
                                    <nz-select [(ngModel)]="formfieldvalue['contentSetupId']"
                                    name="contentSetupId"
                                        nzShowSearch
                                        nzAllowClear
                                        nzPlaceHolder="Select a person"

                                    >
                                    <nz-option *ngFor="let option of content" [nzLabel]="option.name"
                                    [nzValue]="option.id"></nz-option>
                                    </nz-select>
                                </div>
                            </div>
                            <div class="row">
                                <label class="sub-text me-2">Show Contact Name & Phone</label>
                            </div>
                            <div class="row">
                                <div class="col-2">
                                    <nz-select [(ngModel)]="formfieldvalue['showContactNamePhoneSetupId']"
                                    name="showContactNamePhoneSetupId"
                                        nzShowSearch
                                        nzAllowClear
                                        nzPlaceHolder="Select a person"

                                    >
                                    <nz-option *ngFor="let option of showContact" [nzLabel]="option.name"
                                    [nzValue]="option.id"></nz-option>
                                    </nz-select>
                                </div>
                            </div>
                            <div class="row">
                                <label class="sub-text me-2">
                                    Show Address
                                    <i
                                        class="bi bi-info-circle-fill text-secondary"
                                        nz-popover
                                        [nzPopoverContent]="Budget"
                                        nzPopoverPlacement="right"
                                    ></i>
                                    <ng-template #Budget>
                                        <div class="rounded-2">
                                            <p style="height:80px; width: 210px;font-size: 13px;">
                                                Both the Customer Contact and Opportunity's address will display if the addresses are different from one another.
                                            </p>
                                        </div>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="row">
                                <div class="col-2">
                                    <nz-select  [(ngModel)]="formfieldvalue['showAddressSetupId']"
                                    name="showAddressSetupId"
                                        nzShowSearch
                                        nzAllowClear
                                        nzPlaceHolder="Select a person"

                                    >
                                    <nz-option *ngFor="let option of showContact" [nzLabel]="option.name"
                                    [nzValue]="option.id"></nz-option>
                                    </nz-select>
                                </div>
                            </div>
                            <div class="row">
                                <label class="sub-text me-2">Show Company Information</label>
                            </div>
                            <div class="row">
                                <div class="col-2">
                                    <nz-select  [(ngModel)]="formfieldvalue['showCompanyInformationSetupId']"
                                    name="showCompanyInformationSetupId"
                                        nzShowSearch
                                        nzAllowClear
                                        nzPlaceHolder="Select a person"

                                    >
                                    <nz-option *ngFor="let option of showContact" [nzLabel]="option.name"
                                    [nzValue]="option.id"></nz-option>
                                    </nz-select>
                                </div>
                            </div>
                        </div>
                        <div class="row border-bottom pb-5">
                            <div class="col-12">
                                <div class="row mt-3 ">
                                    <label class="sub-text me-2">Default "What to Display"</label>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <nz-select
                                            [(ngModel)]="multipleValue"
                                            [nzSize]="size"
                                            nzMode="multiple"
                                            nzPlaceHolder="Please select"
                                        >
                                        <nz-option *ngFor="let option of showContact" [nzLabel]="option.name"
                                        [nzValue]="option.id"></nz-option>
                                        </nz-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row  border-bottom pb-5">
                            <div class="col-md-12">
                                <label class="sub-text me-2">Default Introductory Text</label>
                                <textarea [(ngModel)]="formfieldvalue['defaultIntroductoryText']"
                                name="defaultIntroductoryText"
                                    class="form-control formField"
                                    style="height: 120px; resize: none;"
                                    (input)="autoAdjustTextAreaHeight($event)"
                                ></textarea>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-4">
                                <label class="sub-text">Default Title</label>
                                <input [(ngModel)]="formfieldvalue['defaultTitle']"
                                name="defaultTitle"
                                    type="text"
                                    class="form-control formField"
                                >
                            </div>
                        </div>
                        <div class="row  border-bottom pb-5">
                            <div class="col-md-12">
                                <label class="sub-text me-2">Default Closing Text</label>
                                <textarea [(ngModel)]="formfieldvalue['defaultClosingText']"
                                name="defaultClosingText"
                                    class="form-control formField"
                                    style="height: 120px; resize: none;"
                                    (input)="autoAdjustTextAreaHeight($event)"
                                ></textarea>
                            </div>
                        </div>
                        <div class="row mt-3 border-bottom pb-5">
                            <div class="col-md-12">
                                <label class="sub-text me-2">Estimate Disclaimer</label>
                                <textarea [(ngModel)]="formfieldvalue['estimateDisclaimer']"
                                name="estimateDisclaimer"
                                    class="form-control formField"
                                    style="height: 120px; resize: none;"
                                    (input)="autoAdjustTextAreaHeight($event)"
                                ></textarea>
                            </div>
                        </div>
                        <div class="row-mt-3">
                            <span class="sub-text">Estimate Disclaimer</span>
                        </div>
                        <div  *ngIf="!isInputVisible" class="row-mt-1">
                            <span class="pira-label mt-1">
                                I confirm that my action here represents my electronic signature and is binding.
                            </span>
                        </div>
                        <div class="row mt-2">
                            <span class="ms-0">
                                <button  *ngIf="!isInputVisible"
                        (click)="toggleVisibility()"
                                    nz-button
                                    type="button"
                                    class="me-0 ant-btn1 ant-btn-default"
                                >
                                    <span>Change</span>
                                </button>
                            </span>
                        </div>
                        <div  *ngIf="isInputVisible" class="row mt-1 border-bottom pb-5">
                            <div class="col-md-12">
                                <textarea
                                    class="form-control formField"
                                    style="height: 120px; resize: none;"
                                    (input)="autoAdjustTextAreaHeight($event)"
                                >
                                    I confirm that my action here represents my electronic signature and is binding.
                            </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</div>

<ng-template #estimates2>
  <button nz-button nzType="primary" class="rounded-1 me-0" (click)="saveData()">Save</button>
</ng-template>

</nz-modal>
