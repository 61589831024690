import { Component, EventEmitter, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';



@Component({
  selector: 'app-lead-opportunities-learn-more',
  templateUrl: './lead-opportunities-learn-more.component.html',
  styleUrls: ['./lead-opportunities-learn-more.component.css']
})
export class LeadOpportunitiesLearnMoreComponent {

  constructor(private modal: NzModalService){}
  @Output() cancel = new EventEmitter<void>();
 

  LeadOpportunityLearnMoremodal=true;
  LeadOpportunityLearnMoreModelCancel(): void {
  this.cancel.emit();
  }


}



