import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/Service/CompanyInFormation/modal.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';

@Component({
  selector: 'app-project-management-site-diaries-add-tag',
  templateUrl: './project-management-site-diaries-add-tag.component.html',
  styleUrls: ['./project-management-site-diaries-add-tag.component.css']
})
export class ProjectManagementSiteDiariesAddTagComponent  implements OnInit {
  isModalOpen: boolean = false;
  private subscription: Subscription;
  
  constructor(private modalService: ModalService,) {
    this.subscription = this.modalService.showModal$.subscribe(() => {
      this.CompanyInformation();
    });
  }

  ngOnInit() {
  }
  CompanyInformation(){
    this.isModalOpen = true;
  }
  closeModal(){
    this.isModalOpen = false;
  }
}