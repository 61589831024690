

<nz-modal
  nzWidth="40%"
  [(nzVisible)]="receiptUpload"
  [nzTitle]="printModalTitles"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '72vh', 'overflow-y': 'auto' }"
  [nzStyle]="{ top: '15px' }"
  [nzFooter]="modal"
  (nzOnCancel)="receiptUploadclose()"

  
  
  >
    <ng-template #printModalTitles>
      <div class="row p-0 m-0">
        <h2 class="p-0 m-0 ">
          Receipt Upload
        </h2>
      </div>
    </ng-template>

    <div class="content content-set-background " *nzModalContent>
        <nz-upload
nzType="drag"
[nzMultiple]="true"
nzAction="https://www.mocky.io/v2/5cc8019d300000980a055e76"
(nzChange)="handleChange($event)"
>
<p class="ant-upload-drag-icon">
  <span nz-icon nzType="inbox"></span>
</p>
<p class="ant-upload-text"> <a href="#">Browse</a> or drag file(s) here to upload</p>
<p class="ant-upload-hint">
  Each image will be uploaded as an individual receipt. <br> Preferred file types - .gif, .png, .jpg, .jpeg, .pdf, .heic

</p>
</nz-upload>
    </div>
</nz-modal>
<ng-template #modal>
    <button nz-button nzType="default" (click)="receiptUploadclose()" >Cancel</button>
  <button nz-button nzType="primary">Save Receipts</button>

</ng-template>








