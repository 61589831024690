import { Component, OnInit } from '@angular/core';
import { NzSelectSizeType } from 'ng-zorro-antd/select';
interface DataItem {
  name: string;
  chinese: number;
  math: number;
  english: number;
}
@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.css']
})
export class SurveysComponent  implements OnInit  {

  checked = false;
  checked1 = false;
  checked2 = false;
  selectedValue = null;
  text: string | undefined;
  selectedOS = null;


  autoAdjustTextAreaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; // Set the height with a maximum of 200px
  }
  newsurveysuestions:boolean = false;
  NewSurveyQuestions(){
    this.newsurveysuestions = true;
  }
  closeNewSurveyQuestions(): void {
    this.newsurveysuestions = false;
  }

  customfieldinfo:boolean = false;
  CustomFieldInfo(){
    this.customfieldinfo = true;
  }
  handleCancel2(): void {
    this.customfieldinfo = false;
  }
  panels = [
    {
      active: false,
      disabled: false,
      name: ' Custom Questions',
      childPannel: [
        {
          active: false,
          disabled: true,
          name: 'This is panel header 1-1'
        }
      ]
    },
    {
      active: false,
      disabled: true,
      name: 'Buildertrend Questions'
    }
  ];
visible1: boolean = false;

  showDialog1() {
    this.visible1 = true;
  }
  handleCancel1(): void {
    this.visible1 = false;
    }
    listOfOption: Array<{ label: string; value: string }> = [];
    size: NzSelectSizeType = 'default';
    singleValue = 'a10';
    multipleValue = ['a10', 'c12'];
    tagValue = ['a10', 'c12', 'tag'];
  
    ngOnInit(): void {
      const children: Array<{ label: string; value: string }> = [];
      for (let i = 10; i < 36; i++) {
        children.push({ label: i.toString(36) + i, value: i.toString(36) + i });
      }
      this.listOfOption = children;
    }
    listOfColumn = [
      {
        title: 'Name',
        compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
        priority: false
      },
      {
        title: 'Chinese Score',
        compare: (a: DataItem, b: DataItem) => a.chinese - b.chinese,
        priority: 3
      },
      {
        title: 'Math Score',
        compare: (a: DataItem, b: DataItem) => a.math - b.math,
        priority: 2
      },
      {
        title: 'English Score',
        compare: (a: DataItem, b: DataItem) => a.english - b.english,
        priority: 1
      }
    ];
    listOfData: DataItem[] = [
      {
        name: 'John Brown',
        chinese: 98,
        math: 60,
        english: 70
      },
    ];
  }