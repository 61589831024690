import { MessagingComposeNewMessageComponent } from './../messaging-compose-new-message/messaging-compose-new-message.component';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTableSortOrder, NzTableSortFn } from 'ng-zorro-antd/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MessagingForwordToEmailComponent } from 'src/shared/Components/messaging-forword-to-email/messaging-forword-to-email.component';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';


interface DataItem {
  id: string;
  name: string;
  address: string;
  formValue: string; // Add this property
  subjectValue: string; // Add this property
  dateReceivedValue: string; // Add this property
}
interface CustomColumn {
  value: string; // or any other type that suits your needs
  default: any; // or any other type that suits your needs
  width: string; // or any other type that suits your needs
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<ItemData> | null;
  sortDirections: NzTableSortOrder[];
  colspan?: string; // Added colspan property
}

interface ItemData {
  id: number;
  name: string;
  address: string;
  number: number;
  formValue?: string;
  subjectValue?: string;
  dateReceivedValue?: string;
}

@Component({
  selector: 'app-messaging-dashboard',
  templateUrl: './messaging-dashboard.component.html',
  styleUrls: ['./messaging-dashboard.component.css']
})
export class MessagingDashboardComponent implements OnInit {
doneList: string;
isCollapsed = false;
selectedJobId: number = 0;


    constructor(private modal: NzModalService, 
    public AccessLocalStorageService: AccessLocalStorageService,
      
      private cdr: ChangeDetectorRef,private filterService: FilterSideMenuBarService,) {}
  indeterminate = false;
  checked5 = false;
  setOfCheckedId = new Set<number>();
  // listOfData: readonly ItemData[] = [];
  listOfData: ItemData[];
  multipleValue = ['a10', 'c12'];
  tagValue = ['a10', 'c12', 'tag'];
  listOfCurrentPageData: readonly ItemData[] = [];
  checked = true;
  PAGE_ID: number = 3;
  MESSAGES: string = FiltersName.MESSAGES;

  ngOnInit(): void {
    // schedule
    this.listOfData = new Array(2).fill(0).map((_, index) => ({
      id: index + 1,
      name: `Muhmmad Yousuf ${index}`,
      age: 32,
      address: `(no-subject)-copy ${index}`,
      number: index + 1,
      formValue: '',  // Add these properties and assign default values if needed
      subjectValue: '',
      dateReceivedValue: '',
    }));
    this.AccessLocalStorageService.getJobName();
  }
  
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  composeNewMessageModelOpen(): void {
    const modalRef = this.modal.create({
      nzContent: MessagingComposeNewMessageComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }

  forwordToEmailModelOpen(): void {
    const modalRef = this.modal.create({
      nzContent: MessagingForwordToEmailComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }
  
  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }
  
  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }
  
  onCurrentPageDataChange($event: readonly ItemData[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }
  
  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  listOfColumns: CustomColumn[] = [
    {
      name: 'Form',
      value: 'formValue',
      default: 'defaultValue',
      width: '100px',
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: ItemData, b: ItemData) => a.formValue.localeCompare(b.formValue),
    },
    {
      name: 'Subject',
      value: 'subjectValue',
      default: 'defaultValue',
      width: '150px',
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: ItemData, b: ItemData) => a.subjectValue.localeCompare(b.subjectValue),
    },
    {
      name: 'Date Received',
      value: 'dateReceivedValue',
      default: 'defaultValue',
      width: '120px',
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: ItemData, b: ItemData) => a.dateReceivedValue.localeCompare(b.dateReceivedValue),
    },
  ];
  
  title: CustomColumn[] = [];
  footer: CustomColumn[] = [];
  fix?: CustomColumn[] = [];
  notFix: CustomColumn[] = [];

    // drop(event: CdkDragDrop<CustomColumn[]>): void {
    //   if (event.previousContainer === event.container) {
    //     moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    //   } else {
    //     transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    //   }
    //   this.fix = this.fix.map(item => {
    //     item.default = true;
    //     return item;
    //   });
    //   this.notFix = this.notFix.map(item => {
    //     item.default = false;
    //     return item;
    //   });
    //   this.cdr.markForCheck();
    // }
   
  drop(event: CdkDragDrop<string[]>): void {
    const mutableList = [...this.listOfData];
    moveItemInArray(mutableList, event.previousIndex, event.currentIndex);
    this.listOfData = mutableList;
  }

    deleteCustom(value: CustomColumn, index: number): void {
      value.default = false;
      this.notFix = [...this.notFix, value];
      this.fix.splice(index, 1);
      this.cdr.markForCheck();
    }
    handleOk(): void {
      this.listOfColumns = [ ...this.fix, ...this.notFix];
      this.cdr.markForCheck();
    }
  
    addCustom(value: CustomColumn, index: number): void {
      value.default = true;
      this.fix = [...this.fix, value];
      this.notFix.splice(index, 1);
      this.cdr.markForCheck();
    }
  
    onSortOrderChange(column: CustomColumn, sortOrder: NzTableSortOrder): void {
      // Set the sort order for the column
      column.sortOrder = sortOrder;
    
      // Perform sorting logic here (if needed)
    
      // Refresh the checked status
      this.refreshCheckedStatus();
    }

    cancelSelection(): void {
      this.setOfCheckedId.clear();
      this.checked5 = false;
    }

    showAddressColumn = true;
    toggleAddressColumn(): void {
      this.showAddressColumn = !this.showAddressColumn;
    }

   openFilter(component: string, pageId: number) {
        this.filterService.toggleFilter(component, pageId);
      }
          // Selected Job Id From Left Side Job List
getSelectedJobId(item: number){
  this.selectedJobId = item;
  console.log("selectedJobId : ", this.selectedJobId);
}
}
