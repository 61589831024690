import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InternalUserRoutingModule } from './internal-user-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InterUserDashboardComponent } from './inter-user-dashboard/inter-user-dashboard.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { SharedModule } from 'src/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { InviteInternalUserComponent } from './invite-internal-user/invite-internal-user.component';
import { ModalServiceService } from 'src/Service/Internaluser/modal-service.service';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { InternalStandardViewComponent } from './internal-standard-view/internal-standard-view.component';
import { AddNewInternalUserComponent } from './component/add-new-internal-user/add-new-internal-user.component';
import { AddBulkInternalUserComponent } from './component/add-bulk-internal-user/add-bulk-internal-user.component';
import { ManageRoleComponent } from './component/manage-role/manage-role.component';
import { RoleManagementCustomFieldComponent } from './component/role-management-custom-field/role-management-custom-field.component';
import { CopyFormAnotherRoleComponent } from './component/copy-form-another-role/copy-form-another-role.component';
import { SubsVendorsInformationModule } from '../subs-vendors-information/subs-vendors-information.module';
@NgModule({
  declarations: [
    InterUserDashboardComponent,
    InviteInternalUserComponent,
    InternalStandardViewComponent,
    AddNewInternalUserComponent,
    AddBulkInternalUserComponent,
    ManageRoleComponent,
    RoleManagementCustomFieldComponent,
    CopyFormAnotherRoleComponent,
  ],
  imports: [
    CommonModule,
    InternalUserRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NzIconModule,

    // BrowserAnimationsModule,
    NzPopoverModule,
    NzSelectModule,
    NzButtonModule,
    NzPageHeaderModule,
    NzSpaceModule,
    NzDescriptionsModule,
    NzLayoutModule,
    NzIconModule,
    NzTableModule,
    NzTagModule,
    NzModalModule,
    NzTabsModule,
    NzFormModule,
    NzDatePickerModule,
    NzInputModule,
    NzMessageModule,
    NzCardModule,
    NzGridModule,
    NzCheckboxModule,
    NgxIntlTelInputModule,
    SharedModule,
    NzUploadModule,
    NzSwitchModule,
    NzInputNumberModule,
    NzDividerModule,
    NzDrawerModule,
    NzTreeSelectModule,
    SubsVendorsInformationModule

  ],
  exports:[
    InterUserDashboardComponent
  ],
  providers: [ModalServiceService],
})
export class InternalUserModule { }
