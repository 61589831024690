import { JobSummaryFilterFieldComponent } from 'src/app/shared/component/filter/job-summary-filter-field/job-summary-filter-field.component';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { HeaderComponent } from './Components/header/header.component';
import { EditorModule } from "@progress/kendo-angular-editor";
import { FilterSideMenuBarComponent } from "src/app/shared/component/filter-side-menu-bar/filter-side-menu-bar.component";
import { CommentsViewbyConversationFilterFieldComponent } from "src/app/shared/component/filter/comments-viewby-conversation-filter-field/comments-viewby-conversation-filter-field.component";
import { CustomerContactFilterFieldComponent } from "src/app/shared/component/filter/customer-contact-filter-field/customer-contact-filter-field.component";
import { InternalUserFilterFieldComponent } from "src/app/shared/component/filter/internal-user-filter-field/internal-user-filter-field.component";
import { JobsListFilterFieldComponent } from "src/app/shared/component/filter/jobs-list-filter-field/jobs-list-filter-field.component";
import { JobsMapFilterFieldComponent } from "src/app/shared/component/filter/jobs-map-filter-field/jobs-map-filter-field.component";
import { LeadOpportunitiesActivityTemplatesFilterFieldComponent } from "src/app/shared/component/filter/lead-opportunities-activity-templates-filter-field/lead-opportunities-activity-templates-filter-field.component";
import { LeadOpportunitiesActivityViewFilterFieldComponent } from "src/app/shared/component/filter/lead-opportunities-activity-view-filter-field/lead-opportunities-activity-view-filter-field.component";
import { LeadOpportunitiesLeadProposalsFilterFieldComponent } from "src/app/shared/component/filter/lead-opportunities-lead-proposals-filter-field/lead-opportunities-lead-proposals-filter-field.component";
import { LeadOpportunitiesListViewFilterFieldComponent } from "src/app/shared/component/filter/lead-opportunities-list-view-filter-field/lead-opportunities-list-view-filter-field.component";
import { LeadOpportunitiesMapFilterFieldComponent } from "src/app/shared/component/filter/lead-opportunities-map-filter-field/lead-opportunities-map-filter-field.component";
import { LeadOpportunitiesProposalTemplatesFilterFieldComponent } from "src/app/shared/component/filter/lead-opportunities-proposal-templates-filter-field/lead-opportunities-proposal-templates-filter-field.component";
import { MessagingMessagesFilterFieldComponent } from "src/app/shared/component/filter/messaging-messages-filter-field/messaging-messages-filter-field.component";
import { MessagingRFIsFilterFieldComponent } from "src/app/shared/component/filter/messaging-rfis-filter-field/messaging-rfis-filter-field.component";
import { ProjectManagementSelectionCardViewFilterFieldComponent } from "src/app/shared/component/filter/project-management-selection-card-view-filter-field/project-management-selection-card-view-filter-field.component";
import { ProjectManagementSelectionListFilterFieldComponent } from "src/app/shared/component/filter/project-management-selection-list-filter-field/project-management-selection-list-filter-field.component";
import { ProjectManagementSiteDiariesDailyLogFilterFieldComponent } from "src/app/shared/component/filter/project-management-site-diaries-daily-log-filter-field/project-management-site-diaries-daily-log-filter-field.component";
import { ProjectManagementVariationFilterFieldComponent } from "src/app/shared/component/filter/project-management-variation-filter-field/project-management-variation-filter-field.component";
import { ProjectMangamentScheduleBaselineFilterFieldComponent } from "src/app/shared/component/filter/project-mangament-schedule-baseline-filter-field/project-mangament-schedule-baseline-filter-field.component";
import { ProjectMangamentScheduleFilterFieldComponent } from "src/app/shared/component/filter/project-mangament-schedule-filter-field/project-mangament-schedule-filter-field.component";
import { ProjectMangamentScheduleWorkdayExceptionFilterFieldComponent } from "src/app/shared/component/filter/project-mangament-schedule-workday-exception-filter-field/project-mangament-schedule-workday-exception-filter-field.component";
import { SubVenderFilterFieldComponent } from "src/app/shared/component/filter/sub-vender-filter-field/sub-vender-filter-field.component";
import { PermissionComponent } from "src/app/shared/component/permission/permission.component";
import { LoadingIndicatorComponent } from "./Components/loading-indicator/loading-indicator.component";
import { PageNotFound404Component } from "./Components/page-not-found404/page-not-found404.component";
import { JobAccessComponent } from "src/app/shared/component/job-access/job-access.component";
import { SubsVendorsNotificationComponent } from "src/app/shared/component/subs-vendors-notification/subs-vendors-notification.component";
import { CurrencyDollarFieldComponent } from "src/app/shared/component/currency-dollar-field/currency-dollar-field.component";
import { DashboardPagesFooterComponent } from "src/app/shared/dashboard-footer/dashboard-pages-footer/dashboard-pages-footer.component";
import { TableDataComponent } from "src/app/custom-component/table-data/table-data.component";
import { MessagingForwordToEmailComponent } from "src/shared/Components/messaging-forword-to-email/messaging-forword-to-email.component";
import { DocumentCreateFolderComponent } from "src/app/shared/component/document-create-folder/document-create-folder.component";
import { NgxCurrencyDirective, NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from "ngx-currency";
import { SalesComponent } from "src/app/shared/component/sales/sales.component";
import { JobsComponent } from "src/app/shared/component/jobs/jobs.component";
import { MessagingNotificationHistoryFilterFiledComponent } from "src/app/shared/component/filter/messaging-notification-history-filter-filed/messaging-notification-history-filter-filed.component";
import { MessagingSurveysIndividualFilterFieldComponent } from "src/app/shared/component/filter/messaging-surveys-individual-filter-field/messaging-surveys-individual-filter-field.component";
import { AddNewGridStandardViewComponent } from "src/app/shared/component/Modal/add-new-grid-standard-view/add-new-grid-standard-view.component";
import { AddNewStandardFilterComponent } from "src/app/shared/component/Modal/add-new-standard-filter/add-new-standard-filter.component";
import { FinancialOwnerInvoiceFilterFieldComponent } from "src/app/shared/component/filter/financial-owner-invoice-filter-field/financial-owner-invoice-filter-field.component";
import { FinancialOwnerInvoicePaymentCreditMemosFilterFieldComponent } from "src/app/shared/component/filter/financial-owner-invoice-payment-credit-memos-filter-field/financial-owner-invoice-payment-credit-memos-filter-field.component";
import { FinancialOwnerInvoicePaymentFilterFieldComponent } from "src/app/shared/component/filter/financial-owner-invoice-payment-filter-field/financial-owner-invoice-payment-filter-field.component";
import { FinancialCostInboxFilterFieldComponent } from "src/app/shared/component/filter/financial-cost-inbox-filter-field/financial-cost-inbox-filter-field.component";
import { FinancialEstimateFilterFieldComponent } from "src/app/shared/component/filter/financial-estimate-filter-field/financial-estimate-filter-field.component";
import { FinancialBugetFilterFieldComponent } from "src/app/shared/component/filter/financial-buget-filter-field/financial-buget-filter-field.component";
import { FinancialBillsFilterFieldComponent } from "src/app/shared/component/filter/financial-bills-filter-field/financial-bills-filter-field.component";
import { FinancialPurchaseOrderFilterFieldComponent } from "src/app/shared/component/filter/financial-purchase-order-filter-field/financial-purchase-order-filter-field.component";
import { FinancialTendersFilterFieldComponent } from "src/app/shared/component/filter/financial-tenders-filter-field/financial-tenders-filter-field.component";
import { MessagingQuestionsFilterFieldComponent } from "src/app/shared/component/filter/messaging-questions-filter-field/messaging-questions-filter-field.component";
import { MessagingGroupedFilterFieldComponent } from "src/app/shared/component/filter/messaging-grouped-filter-field/messaging-grouped-filter-field.component";
import { MessagingSurveys0GroupedFilterFieldComponent } from "src/app/shared/component/filter/messaging-surveys0-grouped-filter-field/messaging-surveys0-grouped-filter-field.component";
import { AddButtonComponent } from "src/app/shared/component/add-button/add-button.component";
import { LeadOpportunityActivityCalanderFilterFieldComponent } from "src/app/shared/component/filter/lead-opportunity-activity-calander-filter-field/lead-opportunity-activity-calander-filter-field.component";
import { LoaderIconComponent } from "src/app/shared/component/loader-icon/loader-icon.component";
import { ProjectManegmentToDosFilterFieldComponent } from "src/app/shared/component/filter/project-manegment-to-dos-filter-field/project-manegment-to-dos-filter-field.component";
import { ProjectManagementTimeClockFilterFieldComponent } from "src/app/shared/component/filter/project-management-time-clock-filter-field/project-management-time-clock-filter-field.component";
import { ProjectManagementWarrantyFilterFieldComponent } from "src/app/shared/component/filter/project-management-warranty-filter-field/project-management-warranty-filter-field.component";
import { ProjectManagementSelectionHirechyFilterFieldComponent } from "src/app/shared/component/filter/project-management-selection-hirechy-filter-field/project-management-selection-hirechy-filter-field.component";
import { ProjectManagementAllowanceAssingedFilterFieldComponent } from "src/app/shared/component/filter/project-management-allowance-assinged-filter-field/project-management-allowance-assinged-filter-field.component";
import { ProjectManagementAllowanceUnassingedFilterFieldComponent } from "src/app/shared/component/filter/project-management-allowance-unassinged-filter-field/project-management-allowance-unassinged-filter-field.component";
import { GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { LeadOpportunitySelectionDialog } from 'src/app/lead-opportunities/Components/dialog-box/dialog-box.component';
import { SideMenuBarComponent } from 'src/app/shared/component/Modal/side-menu-bar/side-menu-bar.component';
import { SignaturePadComponent } from 'src/app/project-management/signature-pad/signature-pad.component';
import { MessagingCommonComponent } from 'src/app/shared/component/messaging-common/messaging-common.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { AppComponent } from 'src/app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { DollarFormatterDirective } from 'src/app/Core/Directives/dollar-formatter.directive';

import { NzInputModule } from 'ng-zorro-antd/input';
import { NgZorroModule } from './ng-zorro/ng-zorro.module';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PercentageDirective } from 'src/app/Core/Directives/percentage.directive';
import { FooterLabelComponent } from './Components/footer-label/footer-label.component';

bootstrapApplication(AppComponent, {
  providers: [
    provideEnvironmentNgxCurrency({
      align: "left",
      allowNegative: true,
      allowZero: true,
      decimal: ",",
      precision: 2,
      prefix: "",
      suffix: "",
      thousands: ".",
      nullable: true,
      min: null,
      max: null,
      inputMode: NgxCurrencyInputMode.Financial,
    }),
  ],
}).catch((err) => console.error(err));

@NgModule({

  declarations: [
    PageNotFound404Component,
    HeaderComponent,
    LoadingIndicatorComponent,
    FilterSideMenuBarComponent,
    InternalUserFilterFieldComponent,
    SubVenderFilterFieldComponent,
    CustomerContactFilterFieldComponent,
    PermissionComponent,
    MessagingRFIsFilterFieldComponent,
    LeadOpportunitiesActivityTemplatesFilterFieldComponent,
    LeadOpportunitiesListViewFilterFieldComponent,
    LeadOpportunitiesLeadProposalsFilterFieldComponent,
    LeadOpportunitiesProposalTemplatesFilterFieldComponent,
    JobsListFilterFieldComponent,
    ProjectMangamentScheduleFilterFieldComponent,
    ProjectMangamentScheduleWorkdayExceptionFilterFieldComponent,
    ProjectMangamentScheduleBaselineFilterFieldComponent,
    ProjectManagementVariationFilterFieldComponent,
    CommentsViewbyConversationFilterFieldComponent,
    JobsMapFilterFieldComponent,
    ProjectManagementSiteDiariesDailyLogFilterFieldComponent,
    LeadOpportunitiesMapFilterFieldComponent,
    LeadOpportunitiesActivityViewFilterFieldComponent,
    ProjectManagementSelectionCardViewFilterFieldComponent,
    ProjectManagementSelectionListFilterFieldComponent,
    CurrencyDollarFieldComponent,
    JobAccessComponent,
    SubsVendorsNotificationComponent,
    DashboardPagesFooterComponent,
    TableDataComponent,
    MessagingForwordToEmailComponent,
    DocumentCreateFolderComponent,
    JobsComponent,
    SalesComponent,
    MessagingNotificationHistoryFilterFiledComponent,
    MessagingSurveysIndividualFilterFieldComponent,
    MessagingSurveys0GroupedFilterFieldComponent,
    MessagingGroupedFilterFieldComponent,
    MessagingQuestionsFilterFieldComponent,
    FinancialTendersFilterFieldComponent,
    FinancialPurchaseOrderFilterFieldComponent,
    FinancialBillsFilterFieldComponent,
    FinancialBugetFilterFieldComponent,
    FinancialEstimateFilterFieldComponent,
    FinancialCostInboxFilterFieldComponent,
    FinancialOwnerInvoicePaymentFilterFieldComponent,
    FinancialOwnerInvoicePaymentCreditMemosFilterFieldComponent,
    FinancialOwnerInvoiceFilterFieldComponent,
    AddNewStandardFilterComponent,
    AddNewGridStandardViewComponent,
    MessagingMessagesFilterFieldComponent,
    LeadOpportunityActivityCalanderFilterFieldComponent,
    ProjectManagementWarrantyFilterFieldComponent,
    ProjectManagementTimeClockFilterFieldComponent,
    ProjectManagementSelectionHirechyFilterFieldComponent,
    ProjectManagementAllowanceAssingedFilterFieldComponent,
    ProjectManagementAllowanceUnassingedFilterFieldComponent,
    AddButtonComponent,
    ProjectManegmentToDosFilterFieldComponent,
    LoaderIconComponent,
    JobSummaryFilterFieldComponent,
    LeadOpportunitySelectionDialog,
    SideMenuBarComponent,
    SignaturePadComponent,
    MessagingCommonComponent,
    DollarFormatterDirective,
    PercentageDirective,
    FooterLabelComponent



  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgZorroModule,
    EditorModule,
    GridModule,
    PDFModule,
    NzPopoverModule,
    NgxCurrencyDirective,
    CKEditorModule,





  ],
  exports:
   [
    GridModule,
    PDFModule,
    HeaderComponent,
    LoadingIndicatorComponent,
    PageNotFound404Component,
    NgZorroModule,
    JobsComponent,
    PermissionComponent,
    FilterSideMenuBarComponent,
    InternalUserFilterFieldComponent,
    SubVenderFilterFieldComponent,
    CustomerContactFilterFieldComponent,
    MessagingRFIsFilterFieldComponent,
    LeadOpportunitiesActivityTemplatesFilterFieldComponent,
    LeadOpportunitiesListViewFilterFieldComponent,
    LeadOpportunitiesLeadProposalsFilterFieldComponent,
    LeadOpportunitiesProposalTemplatesFilterFieldComponent,
    JobsListFilterFieldComponent,
    ProjectMangamentScheduleFilterFieldComponent,
    ProjectMangamentScheduleWorkdayExceptionFilterFieldComponent,
    ProjectMangamentScheduleBaselineFilterFieldComponent,
    ProjectManagementVariationFilterFieldComponent,
    CommentsViewbyConversationFilterFieldComponent,
    JobsMapFilterFieldComponent,
    ProjectManagementSiteDiariesDailyLogFilterFieldComponent,
    LeadOpportunitiesMapFilterFieldComponent,
    LeadOpportunitiesActivityViewFilterFieldComponent,
    ProjectManagementSelectionCardViewFilterFieldComponent,
    ProjectManagementSelectionListFilterFieldComponent,
    NgxCurrencyDirective,
    JobAccessComponent,
    SubsVendorsNotificationComponent,
    EditorModule,
    CurrencyDollarFieldComponent,
    DashboardPagesFooterComponent,
    TableDataComponent,
    MessagingForwordToEmailComponent,
    DocumentCreateFolderComponent,
    SalesComponent,
    MessagingNotificationHistoryFilterFiledComponent,
    MessagingSurveysIndividualFilterFieldComponent,
    MessagingSurveys0GroupedFilterFieldComponent,
    MessagingGroupedFilterFieldComponent,
    MessagingQuestionsFilterFieldComponent,
    FinancialTendersFilterFieldComponent,
    FinancialPurchaseOrderFilterFieldComponent,
    FinancialBillsFilterFieldComponent,
    FinancialBugetFilterFieldComponent,
    FinancialEstimateFilterFieldComponent,
    FinancialCostInboxFilterFieldComponent,
    FinancialOwnerInvoicePaymentFilterFieldComponent,
    FinancialOwnerInvoicePaymentCreditMemosFilterFieldComponent,
    FinancialOwnerInvoiceFilterFieldComponent,
    AddNewStandardFilterComponent,
    AddNewGridStandardViewComponent,
    MessagingMessagesFilterFieldComponent,
    AddButtonComponent,
    LeadOpportunityActivityCalanderFilterFieldComponent,
    LoaderIconComponent,
    ProjectManegmentToDosFilterFieldComponent,
    JobSummaryFilterFieldComponent,
    LeadOpportunitySelectionDialog,
    // TendorsContentComponent
    SideMenuBarComponent,
    SignaturePadComponent,
    MessagingCommonComponent,
    DollarFormatterDirective,
    NzInputModule,
    NzNotificationModule,
    CKEditorModule,
    PercentageDirective,
    FooterLabelComponent

  ]
})


export class SharedModule { }
