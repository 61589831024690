import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-attachmentfile-upload',
  templateUrl: './attachmentfile-upload.component.html',
  styleUrls: ['./attachmentfile-upload.component.css']
})
export class AttachmentfileUploadComponent {
  @Output() cancel = new EventEmitter<void>();

  constructor(@Inject(NZ_MODAL_DATA) public data: { videoUrl?: string, imageUrl?: string }) {
    console.log("show media", data);
  }

  getExtension(videoUrl: string): string {
    return videoUrl.substring(videoUrl.lastIndexOf('.') + 1);
  }
  getExtensions(imageUrl: string): string {
    return imageUrl.substring(imageUrl.lastIndexOf('.') + 1);
  }
}




  

