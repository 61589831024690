import { Component, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'app-percentage-markup',
  templateUrl: './percentage-markup.component.html',
  styleUrls: ['./percentage-markup.component.css']
})
export class PercentageMarkupComponent {
  @Output() cancel = new EventEmitter<void>();
  @Output() apply = new EventEmitter<number>();
  [x: string]: any;
  isVisible = true;
  inputValue = '';

  handleCancel(): void {
    this.isVisible = false;
  }
  // handleApply(): void {
  //   this.apply.emit(this.inputValue); // Emit the percentage value when Apply is clicked

  //   this.isVisible = false;
  // }


  handleOk() {
    this.isVisible = false; // Hide the modal after applying
  }


  handleApply(): void {
    const percentage = parseFloat(this.inputValue); // Convert string to number
  
    if (!isNaN(percentage)) {
      this.apply.emit(percentage); // Emit the number value
    } else {
      console.error('Invalid percentage value'); // Handle invalid input
    }
  
    this.isVisible = false; // Hide the modal after applying
  }

  logInputValue(): void { 
    console.log('Input changed:', this.inputValue); // Logs every input change
  }

}
