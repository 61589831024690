import { Component } from '@angular/core';
import { SurveysEmptyContentComponent } from '../surveys-empty-content/surveys-empty-content.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SelectServeyComponent } from '../select-servey/select-servey.component';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { FiltersName } from 'src/app/Core/Enums/filtersName';

@Component({
  selector: 'app-surveys-dashboard',
  templateUrl: './surveys-dashboard.component.html',
  styleUrls: ['./surveys-dashboard.component.css']
})
export class SurveysDashboardComponent {
  isCollapsed = false;
  selectedJobId: number = 0;
  tabs = [1, 2, 3];
  constructor(private modal: NzModalService , private filterService: FilterSideMenuBarService) {}

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  innerCardTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'Individual';
      case 2:
        return 'Grouped';
      case 3:
        return ' Questions';
      default:
        return '';
    }
  }
  showSurveyForm = false;
  newSurveyName: string = ''; // For ngModel binding in the form

  openSelectaSurveyForm() {
    const modalRef = this.modal.create({
      nzContent: SelectServeyComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }
  submitSurvey() {
    // Logic to handle survey submission
    console.log('Survey submitted:', this.newSurveyName);

    // After submitting, you might want to close the form
    this.showSurveyForm = false;
  }

  PAGE_ID: number = 28;
  Surveys_Individual: string = FiltersName.Surveys_Individual;

openFilter(component: string, pageId: number) {
  this.filterService.toggleFilter(component, pageId);
  console.log('component, pageId',component, pageId);
  
}

    // Selected Job Id From Left Side Job List
    getSelectedJobId(item: number){
      this.selectedJobId = item;
      console.log("selectedJobId : ", this.selectedJobId);
    }


}
