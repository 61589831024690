import { DropdowncostcodesComponent } from './dropdowncostcodes/dropdowncostcodes.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanySettingsComponent } from './company-settings.component';
import { CompanyLogoComponent } from './company-logo/company-logo.component';
import { CompanyInformationComponent } from './company-information/company-information.component';
import { RouterModule } from '@angular/router';
import { ToDosComponent } from './to-dos/to-dos.component';
import { ChangeOrdersComponent } from './change-orders/change-orders.component';
// import { SelectionComponent } from './selection/selection.component';
import { WarrantyComponent } from './warranty/warranty.component';
import { TimeClockComponent } from './time-clock/time-clock.component';
import { FilesComponent } from './files/files.component';
import { CommentMessagesComponent } from './comment-messages/comment-messages.component';
import { SurveysComponent } from './surveys/surveys.component';
import { RFIsComponent } from './rfis/rfis.component';
import { CostCodeComponent } from './cost-code/cost-code.component';
import { EditCostCodeLinkComponent } from './edit-cost-code-link/edit-cost-code-link.component';
import { ImportRecommendedCostCodesComponent } from './import-recommended-cost-codes/import-recommended-cost-codes.component';
import { ManageCostCodesComponent } from './manage-cost-codes/manage-cost-codes.component';
import { GustoComponent } from './gusto/gusto.component';
import { SalesforceComponent } from './salesforce/salesforce.component';
import { BuildertrendMarketplaceComponent } from './buildertrend-marketplace/buildertrend-marketplace.component';

import { ScheduleComponent } from './schedule/schedule.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DailyLogsComponent } from './daily-logs/daily-logs.component';

import { CatalogComponent } from './catalog/catalog.component';
import { BudgetComponent } from './Bills/POs/budget/budget.component';
import { OwnerInvoicesComponent } from './owner-invoices/owner-invoices.component';
import { EstimatesComponent } from './estimates/estimates.component';
import { BidsComponent } from './bids/bids.component';
import { CompanySettingsRoutingModule } from './company-settings-routing.module';
import { RolemanagementComponent } from './rolemanagement/rolemanagement.component';
import { NgZorroModule } from 'src/shared/ng-zorro/ng-zorro.module';
import { SharedModule } from 'src/shared/shared.module';
import { CatalogCostItemComponent } from './catalog-cost-item/catalog-cost-item.component';
import { CatalogCostGroupsComponent } from './catalog-cost-groups/catalog-cost-groups.component';
import { CatalogUploadFilesComponent } from './catalog-upload-files/catalog-upload-files.component';
import { CostCodesHeaderComponent } from './cost-codes-header/cost-codes-header.component';
import { BillsPOsBudgetComponent } from './bills-pos-budget/bills-pos-budget.component';
import { CompanySettingsCustomerContactsComponent } from './company-settings-customer-contacts/company-settings-customer-contacts.component';
import { CompanySettingsSubsVendorsComponent } from './company-settings-subs-vendors/company-settings-subs-vendors.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SalesAddLeadActivityTypeComponent } from './sales-add-lead-activity-type/sales-add-lead-activity-type.component';
import { SalesLeadOpportunityCustomFieldComponent } from './sales-lead-opportunity-custom-field/sales-lead-opportunity-custom-field.component';
import { HubSpotComponent } from './import-recommended-cost-codes/hub-spot/hub-spot.component';
import { CostCodeCostCategoryComponent } from './cost-code-cost-category/cost-code-cost-category.component';
import { CosCodeModelComponent } from './cos-code-model/cos-code-model.component';
import { VarianceCategoryComponent } from './VarianceCategory/variance-category/variance-category.component';
import { VarianceCodeComponent } from './variance-code/variance-code/variance-code.component';
import { AccountingComponent } from 'Publish/accounting/accounting.component';
import { SelectiondetupComponent } from './selectiondetup/selectiondetup.component';

@NgModule({
  declarations: [
    SelectiondetupComponent,
    CompanySettingsComponent,
    CompanyLogoComponent,
    CompanyInformationComponent,
    ToDosComponent,
    ChangeOrdersComponent,
    WarrantyComponent,
    TimeClockComponent,
    FilesComponent,
    CommentMessagesComponent,
    SurveysComponent,
    RFIsComponent,
    CostCodeComponent,
    EditCostCodeLinkComponent,
    ImportRecommendedCostCodesComponent,
    ManageCostCodesComponent,
    DropdowncostcodesComponent,
    AccountingComponent,
    GustoComponent,
    HubSpotComponent,
    SalesforceComponent,
    BuildertrendMarketplaceComponent,
    ScheduleComponent,
    DailyLogsComponent,
    BidsComponent,
    CatalogComponent,
    BudgetComponent,
    OwnerInvoicesComponent,
    EstimatesComponent,
    RolemanagementComponent,
    CatalogCostItemComponent,
    CatalogCostGroupsComponent,
    CatalogUploadFilesComponent,
    CostCodesHeaderComponent,
    BillsPOsBudgetComponent,
    CompanySettingsCustomerContactsComponent,
    CompanySettingsSubsVendorsComponent,
    SalesAddLeadActivityTypeComponent,
    SalesLeadOpportunityCustomFieldComponent,
    CostCodeCostCategoryComponent,
    CosCodeModelComponent,
    VarianceCategoryComponent,
    VarianceCodeComponent
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    CompanySettingsRoutingModule,
    CommonModule,
    SharedModule,
    DragDropModule

  ],
  bootstrap: [CompanySettingsComponent],
  exports: [CompanySettingsComponent, BidsComponent],
})
export class CompanySettingsModule { }
