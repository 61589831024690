<nz-modal
    nzWidth="82%"
    [(nzVisible)]="todos"
    [nzTitle]="todos1"
    [nzFooter]="todos2"
    (nzOnCancel)="closeToDos()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '72vh', 'overflow-y': 'auto'}"
    [nzStyle]="{ top: '20px' }">
    <ng-template #todos1> 
        <div class="row p-0 m-0">
          <h2 class="p-0 m-0" nz-typography>To-Do's</h2>
       </div>
    </ng-template>
    <div  class="cantent" *nzModalContent>
      <div class="row famly-inn gx-3">
        <div class="col-12">
            <div class="card border-0 mt-0">
                <div class="card-head border-bottom">
                  <div class="row py-2">
                    <h3 nz-typography><span class="ms-3">To-Do's Setup</span></h3>
                  </div>
              </div>
              <form>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-12">
                          <label>Send Past Due To-Do Reminder up to
                            <nz-select style="width: 100px;" [(ngModel)]="newToDoSetupFormValues['sendPastDueToDoReminderUpToDay']" name="sendPastDueToDoReminderUpToDay">
                              <nz-option nzLabel="none" nzValue="1"></nz-option>
                              <nz-option nzLabel="1" nzValue="2"></nz-option>
                              <nz-option nzLabel="2" nzValue="3"></nz-option>
                              <nz-option nzLabel="3" nzValue="4"></nz-option>
                              <nz-option nzLabel="4" nzValue="5"></nz-option>
                              <nz-option nzLabel="5" nzValue="6"></nz-option>
                              <nz-option nzLabel="6" nzValue="7"></nz-option>
                              <nz-option nzLabel="7" nzValue="8"></nz-option>
                          </nz-select>
                          days.
                          <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="nzShowSerch" nzPopoverPlacement="right"></i>
                          <ng-template #nzShowSerch>
                            <div class="rounded-2">
                              <p style="width: 210px;font-size: 13px;">
                                The to-do assignees will be reminded that the to-do is past deadline for the amount of days selected.
                              </p>
                            </div>
                          </ng-template>
                          </label>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12">
                            <label nz-checkbox [(ngModel)]="newToDoSetupFormValues['requireAllChecklistItemsToBeCompleteInOrderToCompleteAtoDo']" name="requireAllChecklistItemsToBeCompleteInOrderToCompleteAtoDo">
                              Require all checklist items to be complete in order to complete a To-Do</label> 
                            <i class="bi bi-info-circle-fill text-secondary me-2" nz-popover [nzPopoverContent]="Clock" nzPopoverPlacement="right"></i>
                            <ng-template #Clock>
                              <div class="rounded-2">
                                <p style="width: 210px; font-size: 13px;">
                                  Disable To-Do completion until all checklist items are marked complete.
                                </p>
                              </div>
                            </ng-template>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-12">
                            <label nz-checkbox [(ngModel)]="newToDoSetupFormValues['moveCompletedChecklistItemsToTheBottom']" name="moveCompletedChecklistItemsToTheBottom">
                              Move completed checklist items to the bottom</label> 
                        </div>
                      </div>
                      </div>
                  </div>
                  </div>
              </form>
          </div>
            <div class="card border-0 mt-3">
              <div class="card-head border-bottom">
                <div class="row py-2">
                    <div class="col-6">
                  <h3 nz-typography><span class="ms-3">To-Do's Custom Fields</span></h3>
                    </div>
                    <div class="col-6 text-end">
                      <button  (click)="TagModelOpen()" nz-button  class="button-Daily2 me-2">Add New Field</button>
                    </div>
                </div>
            </div>
              <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12 d-flex alighn-item-center justify-content-center">
                      <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g class="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" class="ant-empty-img-simple-path"></path></g></g></svg>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 d-flex alighn-item-center justify-content-center">
                  <span class="ant-empty-normal">No Data</span>
                    </div>
                  </div>
                </div>
              </div>
              </div>
          </div>
        </div>
    </div>
    </div>
    <ng-template #todos2>
        <button nz-button  class="button-Daily2 rounded-1 me-0" (click)="saveMethodToDoSetup()">Save</button>
    </ng-template>
</nz-modal>





