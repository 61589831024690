import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BidsSetup, BidsSetupResponse } from 'src/Models/Bids/bids';

import { BidsService } from 'src/Service/Bids/bids.service';

@Component({
  selector: 'app-bids',
  templateUrl: './bids.component.html',
  styleUrls: ['./bids.component.css']
})
export class BidsComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();

  demoValue = null;
  bidsVisible: boolean = true;

  bidsFormFields: any;
  bidsSetupResponse: any;
  CreateBidsSetupRequest: BidsSetupResponse;
  bids: any;
  


  constructor(private bidsService: BidsService, private toastService: NzMessageService) { }


  ngOnInit(): void {

    this.bidsFormFields = {
      companyParameterId: 1,
      alertSubsVendorsDayNo: 0,
      defaultBidRequestNotificationText: null
    }
    this.getBidsSetupArray();


  }


  getBidsSetup(): void {
    this.bidsService.companyBidsget()
      .subscribe(res => {
        this.CreateBidsSetupRequest = this.bidsSetupResponse(res.result);
      });
  }


  getBidsSetupArray(): void {
    this.bidsService.getCompanyBids().subscribe(res => {
      if (res.result) {
        this.bids = res.result; // Assuming you want the first item
        console.log(this.bids);

        this.bidsFormFields.alertSubsVendorsDayNo = this.bids.alertSubsVendorsDayNo || 0;
        this.bidsFormFields.defaultBidRequestNotificationText = this.bids.defaultBidRequestNotificationText || '';
      }
    });
  }


  saveButton(): void {
    this.bidsService.companyBidsPost(this.bidsFormFields).subscribe((response) => {
      this.toastService.success('Bids Setting Information Updated successfully!');
      this.cancel.emit();
    });
  }

  autoAdjustTextAreaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
    textarea.style.height = `${Math.min(textarea.scrollHeight, 190)}px`; // Set the height with a maximum of 200px
  }

  bidsCancel(): void {
    this.cancel.emit();
  }

}
