import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';

@Component({
  selector: 'app-baseline',
  templateUrl: './baseline.component.html',
  styleUrls: ['./baseline.component.css']
})
export class BaselineComponent {
  switchValue1 = false;

  constructor(private modal: NzModalService,private filterService: FilterSideMenuBarService) {}

  openFilter(component: string, pageId: number) {
    this.filterService.toggleFilter(component, pageId);
  }
  PAGE_ID: number = 9;

  PROJECT_MANG_SCHED_BASELINE: string = FiltersName.PROJECT_MANG_SCHED_BASELINE;

}
