import { Component } from '@angular/core';

@Component({
  selector: 'app-import-recommended-cost-codes',
  templateUrl: './import-recommended-cost-codes.component.html',
  styleUrls: ['./import-recommended-cost-codes.component.css']
})
export class ImportRecommendedCostCodesComponent {
  CostCodes: boolean = false;



  closeDialog() {
    this.CostCodes = false; // Set your dialog property to false to close the dialog
}


}
