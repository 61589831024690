<nz-modal
[(nzVisible)]="selectionModelhandle"
[nzTitle]="SelectionModelTitle"
[nzFooter]="SelectionModelFooter"
[nzBodyStyle]="{
  background: '#f1f4fa',
  padding: '14px',
  maxHeight: '69vh',
  'overflow-y': 'auto',
  'overflow-x': 'hidden',
}" [nzStyle]="{ top: '25px'}" [nzWidth]="'96%'"
(nzOnCancel)="selectionModelCancel()">
  <ng-template #SelectionModelTitle>

    <h5 class="ListViewJobname p-0 m-0">{{ dataSetForselection}}</h5>
    <div class="row" *ngIf="leadData?.leadData?.name">
      <h5 class="ListViewJobname p-0 m-0">{{ leadData?.leadData?.name }}</h5>
  </div>
  <div  class="row" *ngIf="JobData?.JOB_NAME">
    <h5 class="ListViewJobname p-0 m-0" >{{ JobData?.JOB_NAME }}</h5>
  </div>
  <div class="d-flex align-items-center" style="margin-left: -13px;" >
    <h1 class="p-0 m-0 react" id="textToCopy">Selection</h1>
    <!-- <i class="bi bi-copy ml-2 copy-icon" (click)="copyText()"></i> -->
    <!-- <span (click)="copyText()" style="font-size: 20px; margin-left: 10px;">
      <svg width="1em" height="1em" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class=""><path fill-rule="evenodd" clip-rule="evenodd" d="M27 3.99911C27.5128 3.99911 27.9355 4.38516 27.9933 4.88249L28 4.99911V20.9991C28 21.512 27.614 21.9346 27.1166 21.9924L27 21.9991H21.9999V26.9993C21.9999 27.5121 21.6138 27.9348 21.1165 27.9925L20.9999 27.9993H4.99988C4.48704 27.9993 4.06437 27.6132 4.00661 27.1159L3.99988 26.9993V10.9993C3.99988 10.4864 4.38592 10.0638 4.88326 10.006L4.99988 9.99926L9.99988 9.99911L10 4.99911C10 4.48628 10.386 4.06361 10.8834 4.00584L11 3.99911H27ZM19.999 25.999V11.999H5.999V25.999H19.999ZM11.999 5.99899H25.999V19.999L21.9999 19.9991V10.9993L21.9932 10.8826C21.9354 10.3853 21.5127 9.99926 20.9999 9.99926L11.9999 9.99911L11.999 5.99899Z"></path></svg>
    </span> -->
  </div>

  <!-- <div class="d-flex align-items-center mt-3" *ngIf="isCopied">
    <input type="text" class="form-control" [value]="copiedText" placeholder="Copied text will appear here" readonly>
    <i class="bi bi-copy ml-2 copy-icon" (click)="copyInputText()"></i>
  </div> -->

</ng-template>

  <div class="content content-set-background " *nzModalContent>
    <div class="top-of-modal mb-2"></div>
    <nz-alert
      class="mb-3 error-message"
      *ngIf="isSubmitted && errorFields.length > 0"
      nzType="error"
      nzShowIcon
      [nzMessage]="message"
      [nzDescription]="errorDescriptionTemplate"
    ></nz-alert>
    <ng-template #message>
      <p class="mt-1">Please correct the following fields:</p>
    </ng-template>
    <ng-template #errorDescriptionTemplate>
      <ul>
        <li class="sub-text" *ngFor="let error of errorFields">
          <span class="d-flex align-items-center">
            <p>{{ error.label }}:</p>
            <p>{{ error.message }}</p>
          </span>
        </li>
      </ul>
    </ng-template>
      <!-- <div class="spinner-wrapper" *ngIf="isLoading">
        <nz-spin nzSimple [nzSpinning]="true" [nzSize]="'large'"></nz-spin>
      </div> -->

    <div class="ng-container ">
<nz-card [nzSize]="'small'" nzBorderless="true" [nzTitle]="Choice"  [nzExtra]="extraTemplate" >
  <ng-template #Choice>
    <h5 class="Card-Title-Set">Selection Details</h5>
  </ng-template>
  <div class="row">
    <div class="col-6 mb-3">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <label class="sub-text">Title <span class="text-danger">*</span></label>





          </div>
          <div class="col-11">
            <input
              [(ngModel)]="newSelectionFormFiedls['title']"
              name="title"
              type="text"
              #stateInput="ngModel"
              class="formField col-11"
              (ngModelChange)="onFieldChange('title', $event)"
              [ngClass]="{
                'border-danger': newSelectionFormFiedls['title']?.length > 50
              }"
              required
              pattern="^.{50}$"
            />
            <div
              *ngIf="
                stateInput.invalid &&
                (stateInput.dirty || stateInput.touched)
              "
              class="text-danger"
            >

            </div>
            <div
              *ngIf="newSelectionFormFiedls['title']?.length > 50"
              class="text-danger"
            >
              {{ newSelectionFormFiedls['title'].length - 50 }} characters over.
            </div>
          </div>
        </div>

          <div class="row">
            <div class="col-12 mt-3">
              <label class="selectionCheckBoxSelection" [(ngModel)]="newSelectionFormFiedls['isRequireOwnerToMakeSelection']" name="isRequireOwnerToMakeSelection" nz-checkbox>Requare owner to make a selection</label>
            </div>
          </div>
        <div nz-row class="mt-2">
          <div nz-col nzSpan="24">
              <label class="para-lable-selection">Category</label>
          </div>
        </div>
        <div nz-row >
          <div nz-col nzSpan="16">
            <nz-select style="width: 100%" [(ngModel)]="newSelectionFormFiedls['catagorySetupId']" name="catagorySetupId" nzShowSearch [lang]="true" nzAutoFocus="true" nzPlaceHolder="Unassigned"
              >
              <nz-option *ngFor="let option of categoryOption" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
            </nz-select>
          </div>
          <div nz-col nzSpan="2" style="display: flex;justify-content: center;align-items: center;">
            <a class="BT-Link famly-inn"    (click)="showTags('Sources', 'PMSCVC', 'PMSCVC', 74)">Add</a>
          </div>
          <div nz-col nzSpan="2" style="display: flex;justify-content: center;align-items: center;">
            <a class="BT-Link ms-1 famly-inn"  (click)="showTagEdit(newSelectionFormFiedls['catagorySetupId'],'Sources')">Edit</a>
          </div>
        </div>


        <div nz-row class="mt-2">
          <div nz-col nzSpan="24">
              <label class="para-lable-selection">Location</label>
          </div>
        </div>
        <div nz-row >
          <div nz-col nzSpan="16">
            <nz-select style="width: 100%" [(ngModel)]="newSelectionFormFiedls['locationSetupId']" name="locationSetupId" nzShowSearch [lang]="true" nzAutoFocus="true" nzPlaceHolder="Unassigned"
            >
            <nz-option *ngFor="let option of locationOption" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
          </nz-select>
          </div>
          <div nz-col nzSpan="2" style="display: flex;justify-content: center;align-items: center;">
            <a class="BT-Link famly-inn"  (click)="showTags('ProjectType', 'PMSCVL', 'PMSCVL', 73)">Add</a>
          </div>
          <div nz-col nzSpan="2" style="display: flex;justify-content: center;align-items: center;">
            <a class="BT-Link ms-1 famly-inn"  (click)="showTagEdit(newSelectionFormFiedls['locationSetupId'],'ProjectType')">Edit</a>
          </div>
        </div>


      </div>
    </div>
    <div class="col-6 mb-3">
      <div class="row">
        <div class="col-12 ">
          <nz-radio-group [(ngModel)]="shareSingleradioValue" (ngModelChange)="shareSingleradioButtons()">
            <label  nz-radio-button nzValue="A">Single</label>
            <!-- [(ngModel)]="newSelectionFormFiedls['isSingle']" name="isSingle"  -->
            <label  nz-radio-button nzValue="B">Shared</label>
            <!-- [(ngModel)]="newSelectionFormFiedls['isShared']" name="isShared"  -->
          </nz-radio-group>
        </div>
      </div>
      <div class="row" *ngIf="showSelector">
        <div class="col-10 mt-3">
          <label class="para-lable-selection">Single Allowance</label>
          <nz-input-group nzAddOnBefore="$">
            <input
              [(ngModel)]="newSelectionFormFiedls['singleAllowanceNumber']"
              name="singleAllowanceNumber"
              nz-input
              placeholder="0.00"
              type="number"
              [ngModel]="convertToRupees(addedCost)"
              (ngModelChange)="updateConvertedValue($event)"
              (keypress)="validateNumberLength($event)"
              max="99999999999999"
              [maxLength]="14"
            />
          </nz-input-group>
        </div>
      </div>
      <div nz-row class="mt-3"  *ngIf="ShowSelector2">
        <div nz-col nzSpan="24">
            <label class="para-lable-selection">Shared Allowance<span class="text-danger fw-bolder ms-1">*</span></label>
        </div>
      </div>
      <div nz-row   *ngIf="ShowSelector2">
        <div nz-col nzSpan="18">
          <nz-select [(ngModel)]="newSelectionFormFiedls.sharedAllowanceId"
                     (blur)="onSharedAllowanceBlur()"
                     (focus)="onSharedAllowanceFocus()"
                     [ngClass]="{'is-invalid': showSharedAllowanceError}"
                     style="width: 100%;">
              <nz-option *ngFor="let option of shareAllowanceOption" [nzLabel]="option.title" [nzValue]="option.id"></nz-option>
              <div *ngIf="showSharedAllowanceError" class="text-danger">
                Required
            </div>
            </nz-select>
        </div>
        <div nz-col nzSpan="2" style="display: flex;justify-content: center;align-items: center;">
          <a class="BT-Link famly-inn" (click)="Editaloownce()">Add</a>
        </div>
        <div nz-col nzSpan="2" style="display: flex;justify-content: center;align-items: center;">
          <a class="BT-Link ms-1 famly-inn">Edit</a>
        </div>
      </div>
      <div class="row">
        <div class="col-12 ">
          <label class="para-lable-selection">Deadline </label><br>
          <nz-radio-group [(ngModel)]="chooseAddLinkradioValue" (ngModelChange)="chooseAddLinkradioButtons()">
            <label  nz-radio-button nzValue="A">Choose Date</label>
            <!--  [(ngModel)]="newSelectionFormFiedls['isChooseDate']" name="isChooseDate" -->
            <label  nz-radio-button nzValue="B">Link to add</label>
            <!--  [(ngModel)]="newSelectionFormFiedls['isLinkedToScheduleItem']" name="isLinkedToScheduleItem"  -->
          </nz-radio-group>
        </div>
      </div>
      <div class="row mt-1" *ngIf="showDateFields">
        <div class="col-3">
          <label class="para-lable-selection">Deadline</label>
          <nz-date-picker nzPlaceHolder=" " nzSuffixIcon="false" [(ngModel)]="newSelectionFormFiedls['dedlineOn']"  name="dedlineOn"></nz-date-picker>
        </div>

        <div class="col-3">
          <label class="para-lable-selection">Time</label><br>
          <nz-time-picker [(ngModel)]="newSelectionFormFiedls['time']" name="time"   nzFormat="HH:mm" nzPlaceHolder=" "></nz-time-picker>
        </div>
      </div>
      <div nz-row class="mt-2" *ngIf="showScheduleItemFields">
        <div nz-col nzSpan="24">
            <label class="para-lable-selection">Schedule Item<span class="text-danger fw-bolder ms-1">*</span></label>
        </div>
      </div>
      <div nz-row  *ngIf="showScheduleItemFields">
        <div nz-col nzSpan="16">
          <nz-select [(ngModel)]="newSelectionFormFiedls['scheduleItemParameterId']" name="scheduleItemParameterId" style="width: 100%" nzShowSearch [lang]="true" nzAutoFocus="true" nzPlaceHolder="Unassigned"
          [(ngModel)]="selectedValue">
          <nz-option *ngFor="let option of scheduleItemOption" [nzLabel]="option.title" [nzValue]="option.id"></nz-option>
        </nz-select>
        </div>
        <div nz-col nzSpan="2" style="display: flex;justify-content: center;align-items: center;">
          <a class="BT-Link famly-inn">Add</a>
        </div>
        <div nz-col nzSpan="2" style="display: flex;justify-content: center;align-items: center;">
          <a class="BT-Link ms-1 famly-inn">Edit</a>
        </div>
      </div>

      <!-- sufi  -->
      <div class="mt-2" nz-row *ngIf="showScheduleItemFields">
        <div nz-col nzSpan="24">
          <label class="para-lable-selection">Due</label>
        </div>
      </div>
      <div nz-row [nzGutter]="3" *ngIf="showScheduleItemFields">
        <div nz-col nzSpan="4">
          <nz-input-number [(ngModel)]="newSelectionFormFiedls['dueNumberDays']"
          name="dueNumberDays"
          type="text"
          class="form-control formField"
          placeholder="0"
          maxlength="3"
          (input)="limitToThreeDigits()"></nz-input-number>
        </div>
        <div nz-col nzSpan="2">
          <label class="famly-inn">Days</label>
        </div>
        <div nz-col nzSpan="8">
          <nz-radio-group [(ngModel)]="radioValue" nzName="radiogroup">
            <div nz-row>
              <div nz-col nzSpan="12">
                <label [(ngModel)]="newSelectionFormFiedls['isBefore']" name="isBefore" nz-radio nzValue="A"><span class="famly-inn">Before</span></label>
              </div>
              <div nz-col nzSpan="12">
                <label [(ngModel)]="newSelectionFormFiedls['isAfter']" name="isAfter"  nz-radio nzValue="B"><span class="famly-inn">After</span></label>
              </div>
            </div>
          </nz-radio-group>
        </div>
      </div>
      <!-- sufi  -->
      <div class="row mb-4 mt-1" *ngIf="showScheduleItemFields">
        <div class="col-2">
          <label class="para-lable-selection mb-1">DeadLine</label>
          <p class="fw-bold">
            {{ newSelectionFormFiedls['dedlineOn'] ? (newSelectionFormFiedls['dedlineOn'] | date: 'MMM dd, yyyy') : '--' }}
          </p>
        </div>
        <div class="col-3">
          <label class="para-lable-selection">Time</label><br>
          <nz-time-picker [(ngModel)]="newSelectionFormFiedls['time']" name="time"   nzFormat="HH:mm" nzPlaceHolder=" "></nz-time-picker>
        </div>
      </div>

    </div>
  </div>

</nz-card>



<ng-template #extraTemplate>
  <div class="status-text status-tag ">{{ selectionResponses?.StatusName }}</div>
</ng-template>







<div class="card-container mt-2">
  <nz-tabset nzType="card">
    <nz-tab *ngFor="let tab of selectioTabs" [nzTitle]="selectioninnercardTabs(tab)">

      <ng-container *ngIf="tab === 1">
        <!-- <app-selection-choice></app-selection-choice> -->
        <div class="row mb-5 mt-3" *ngIf="!selectionReponse && !newSelectionId">
          <div class="col-12">
            <div class="row">
              <div class="col text-center mt-2">
                <nz-empty [nzNotFoundContent]="CustomContent"></nz-empty>
                <ng-template #CustomContent>
                  <div class="row">
                    <div class="col text-center">
                      <span class="pira-label">Adding choice will be available after saving</span>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <nz-card *ngIf="selectionReponse || newSelectionId > 0" [nzSize]="'small'" nzBorderless="true" [nzTitle]="Choices" [nzExtra]="ChooiceTabTemplate">
          <ng-template #Choices>
            <h5 class="famly-inn Card-Title-Set ms-3">Selection Choices</h5>
          </ng-template>
          <div class="row ps-3">
            <div class="col-12">
              <label class="selectionCheckBoxSelection" nz-checkbox>Allow Multiple Selected Choices</label>
            </div>
          </div>
          <nz-card class="p-3" [nzSize]="'small'" nzBorderless="true" [nzTitle]="ChoiceOrdering">
            <ng-template #ChoiceOrdering>
              <h5 class="para-lable-selection">Choice Ordering</h5>
              <nz-button-group class="me-2" [nzSize]="size">
                <button (click)="showDeleteConfirm()" nz-button nzType="default">
                  Auto
                </button>
                <button nz-button nzType="default">
                 Manual
                </button>
              </nz-button-group>
            </ng-template>
            <div class="row">
              <div class="col-12 p-0 text-lg-end">
                <button nz-button nz-dropdown [nzType]="'default'" [nzTrigger]="'click'"  [nzDropdownMenu]="menucheckedoptions">
                  Checked Options
                   <span nz-icon nzType="down"></span>
                 </button>
                 <nz-dropdown-menu #menucheckedoptions="nzDropdownMenu">
                   <ul nz-menu>
                     <li nz-menu-item>Copy Checked</li>
                     <li nz-menu-item (click)="ConfirmDelete()">Delete Checked</li>
                   </ul>
                 </nz-dropdown-menu>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <label class="pira-label" nz-checkbox>Select All</label>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-lg-6">
                <div>
                  <div class="card" (click)="openSelectionChoice($event)">
                    <div class="card-body">
                      <div class="text-center text-success">
                        <span role="img" aria-label="plus-circle" data-icon-name="BTIcon" type="plus-circle" class="anticon anticon-plus-circle" style="font-size: 24px;">
                          <svg viewBox="64 64 896 896" focusable="false" data-icon="plus-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"></path>
                          </svg>
                        </span>
                      </div>
                      <h2 class="ant-typography text-center ant-typography-bold">
                        <span class="text-success">Create New Choice</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <div nz-row class="pt-2">
              <div nz-col nzSpan="24">
                <ng-container *ngIf="CNChoicesradioValue === 'A'; else Test">
                  <div class="row mb-3">
                    <div class="col-lg-6">
                      <div *ngIf="!selectionResponses?.StatusName?.includes('Selected')" class="card" (click)="openSelectionChoice($event)">
                        <div class="card-body">
                          <div class="text-center text-success">
                            <span role="img" aria-label="plus-circle" data-icon-name="BTIcon" type="plus-circle" class="anticon anticon-plus-circle" style="font-size: 24px;">
                              <svg viewBox="64 64 896 896" focusable="false" data-icon="plus-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"></path>
                              </svg>
                            </span>
                          </div>
                          <h2 class="ant-typography text-center ant-typography-bold">
                            <span class="text-success">Create New Choice</span>
                          </h2>
                        </div>
                      </div>
                  </div>

                    <!-- <div class="col-lg-6" *ngFor="let card of cards">
                      <nz-card   [nzSize]="'small'"  [nzTitle]="CreateNewChoice" [nzExtra]="extraTemplate">
                        <ng-template #CreateNewChoice>
                          <label class="pira-label ms-2" nz-checkbox></label>
                        </ng-template>
                        <div class="row mt-3 ms-2">
                          <div class="col-lg-4">
                            <div class="row">
                              <div class="col" style="background-color: #f1f4fa;">
                                <ng-container>
                                  <div class="Thumbnail--fallback" style="width: 100px; height: 157px; margin-left: 45px;">
                                    <div class="Thumbnail--fallback Thumbnail--fallback-default" style="width: 100px; height: 150px;">
                                      <img
                                        alt="Buildertrend Logo"
                                        [src]="holdPath ? holdPath : 'data:image/svg+xml,%3c?xml%20version=%271.0%27%20encoding=%27UTF-8%27?%3e%3csvg%20id=%27Layer_1%27%20data-name=%27Layer%201%27%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20viewBox=%270%200%201000%201232%27%3e%3cdefs%3e%3cstyle%3e%20.cls-1%20{%20fill:%20%23acb8c3;}%20.cls-1,%20.cls-2%20{%20stroke-width:%200px;}%20.cls-2%20{%20fill:%20%230e0f10;}%20%3c/style%3e%3c/defs%3e%3cpath%20class=%27cls-2%27%20d=%27M411.001,230.943h-209.645V.334H0v637.164c0,.006.001.01.001.017.009,224.526,182.025,406.538,406.554,406.538s406.552-182.021,406.552-406.554c0-223.042-179.631-404.159-402.106-406.555ZM406.555,842.087c-112.992,0-204.588-91.598-204.588-204.589s91.595-204.588,204.588-204.588,204.588,91.597,204.588,204.588-91.598,204.589-204.588,204.589Z%27/%3e%3cpath%20class=%27cls-1%27%20d=%27M696.61,120.656l-40.89,70.823c156.484,87.466,262.307,254.732,262.307,446.74,0,282.479-228.995,511.474-511.474,511.474-95.891,0-185.603-26.413-262.302-72.324l-40.882,70.809c88.79,52.989,192.485,83.488,303.184,83.488,327.228,0,593.447-266.219,593.447-593.447,0-221.94-122.486-415.78-303.39-517.564Z%27/%3e%3c/svg%3e'"
                                        width="100px"
                                        height="150px"
                                        class="Thumbnail--BTLogo-light"
                                      />
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                            <div class="row mb-4">
                              <div class="col">
                                <button
                                [nzTrigger]="'click'"
                                nz-button
                                class="text-primary border-0"
                                nz-dropdown
                                [nzDropdownMenu]="menu4"
                              >
                                <i
                                  class="fa-solid fa-link text-primary me-"
                                ></i
                                ><span class="text-primary">
                                  <svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path></svg>
                                <span class="text-primary">1</span>
                                </span>
                                <span nz-icon nzType="down"></span>
                              </button>
                              <nz-dropdown-menu #menu4="nzDropdownMenu">
                                <ul nz-menu>
                                  <li nz-menu-item>
                                    {{holdName}}
                                  </li>
                                </ul>
                              </nz-dropdown-menu>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-8">
                            <div class="row mb-1">
                              <div class="col-3">
                                <nz-tag class="badge-set">UNRELEASED</nz-tag>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <h3 class="set-add-heading-choice" (click)="FormEdit(card)">{{card?.title || 'Choice Title'}}</h3>
                              </div>
                            </div>
                            <div class="row mb-1">
                              <div class="col">
                                <span class="set-value-dollar-choicecard">{{card?.ownerPrice || '5.00'}}</span>
                              </div>
                            </div>
                            <div class="row mb-1">
                              <div class="col">
                                <p class="">{{card?.description || 'Dell Op'}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </nz-card>
                      <ng-template #extraTemplate>
                        <button nz-button nzType="default" class="me-2"  (click)="OpenAproveStatus()">
                          <span  style="color: rgb(6, 162, 96);margin-right: 5px;font-size: 15px;"><svg viewBox="64 64 896 896" focusable="false" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span>
                          Approve12</button>
                      </ng-template>
                    </div> -->

                    <!-- <div class="col-lg-6">
                      <div class="card-container">
                        <div *ngFor="let data of selectionDatas" class="card">
                          <h3>{{ data?.Title }}</h3>
                          <p>{{ data?.Description }}</p>
                          <p><strong>ID:</strong> {{ data?.Id }}</p>
                        </div>
                      </div>
                    </div> -->

                    <div class="col-lg-6" *ngFor="let item of selectionData">
                      <nz-card   [nzSize]="'small'"  [nzTitle]="CreateNewChoice" [nzExtra]="extraTemplate">
                        <ng-template #CreateNewChoice>
                          <label class="pira-label ms-2" nz-checkbox></label>
                        </ng-template>
                        <div class="row mt-3 ms-2">
                          <div class="col-lg-4">
                            <div class="row">
                              <div class="col" style="background-color: #f1f4fa;">
                                <ng-container >
                                  <div class="Thumbnail--fallback" style="width: 100px; height: 157px; margin-left: 45px;">
                                    <div class="Thumbnail--fallback Thumbnail--fallback-default" style="width: 100px; height: 150px;">
                                      <img
                                        alt="Buildertrend Logo"
                                        [src]="firstFilePath  ? firstFilePath : 'data:image/svg+xml,%3c?xml%20version=%271.0%27%20encoding=%27UTF-8%27?%3e%3csvg%20id=%27Layer_1%27%20data-name=%27Layer%201%27%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20viewBox=%270%200%201000%201232%27%3e%3cdefs%3e%3cstyle%3e%20.cls-1%20{%20fill:%20%23acb8c3;}%20.cls-1,%20.cls-2%20{%20stroke-width:%200px;}%20.cls-2%20{%20fill:%20%230e0f10;}%20%3c/style%3e%3c/defs%3e%3cpath%20class=%27cls-2%27%20d=%27M411.001,230.943h-209.645V.334H0v637.164c0,.006.001.01.001.017.009,224.526,182.025,406.538,406.554,406.538s406.552-182.021,406.552-406.554c0-223.042-179.631-404.159-402.106-406.555ZM406.555,842.087c-112.992,0-204.588-91.598-204.588-204.589s91.595-204.588,204.588-204.588,204.588,91.597,204.588,204.588-91.598,204.589-204.588,204.589Z%27/%3e%3cpath%20class=%27cls-1%27%20d=%27M696.61,120.656l-40.89,70.823c156.484,87.466,262.307,254.732,262.307,446.74,0,282.479-228.995,511.474-511.474,511.474-95.891,0-185.603-26.413-262.302-72.324l-40.882,70.809c88.79,52.989,192.485,83.488,303.184,83.488,327.228,0,593.447-266.219,593.447-593.447,0-221.94-122.486-415.78-303.39-517.564Z%27/%3e%3c/svg%3e'"
                                        width="100px"
                                        height="150px"
                                        class="Thumbnail--BTLogo-light"
                                      />
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                            <div class="row mb-4">
                              <div class="col">
                                <button
                                [nzTrigger]="'click'"
                                nz-button
                                class="text-primary border-0"
                                nz-dropdown
                                [nzDropdownMenu]="menu4"
                              >
                                <i
                                  class="fa-solid fa-link text-primary me-"
                                ></i
                                ><span class="text-primary">
                                  <svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path></svg>
                                <span class="text-primary">1</span>
                                </span>
                                <span nz-icon nzType="down"></span>
                              </button>
                              <nz-dropdown-menu #menu4="nzDropdownMenu">
                                <ul nz-menu>
                                  <li nz-menu-item *ngFor="let detail of fileDetails">
                                    {{detail?.footImage}}
                                  </li>
                                </ul>
                              </nz-dropdown-menu>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-8">
                            <div class="row mb-1">
                              <!-- <div class="col-3">
                                <nz-tag class="badge-set">{{ selectionResponses.StatusName }} || {{selectionChoiceParameterStatus}} </nz-tag>
                              </div> -->
                              <div class="ant-row BTRow-xs" style="row-gap: 0px;"><div class="ant-col statusMessage"><span class="status-text badge success">{{ selectionResponses.StatusName }} </span></div><div class="ant-col statusMessage"><small>{{selectionChoiceParameterStatus}}</small></div></div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <h3 class="set-add-heading-choice" (click)="FormEdit(item?.Id)">
                                  {{item.Title || 'Choice Title'}}</h3>
                              </div>
                            </div>
                            <div class="row mb-1">
                              <div class="col">
                                <span class="set-value-dollar-choicecard">{{item?.OwnerPrice || '5.00'}}</span>
                              </div>
                            </div>
                            <div class="row mb-1">
                              <div class="col">
                                <p class="">{{item?.Description || 'Dell Op'}}</p>
                              </div>
                            </div>
                            <div class="ant-col text-end me-4">
                              <span
                                nz-tooltip
                                [nzTooltipTitle]="Approvalcomments"
                                [nzTooltipPlacement]="['topLeft', 'leftTop']"
                                [nzTooltipColor]="'black'"
                                role="img"
                                aria-label="message"
                                data-icon-name="BTIcon"
                                type="message"
                                class="anticon anticon-message BTPopover PopoverSpacing"
                                style="color: rgb(0, 79, 214);">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="message" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                  <path d="M464 512a48 48 0 1096 0 48 48 0 10-96 0zm200 0a48 48 0 1096 0 48 48 0 10-96 0zm-400 0a48 48 0 1096 0 48 48 0 10-96 0zm661.2-173.6c-22.6-53.7-55-101.9-96.3-143.3a444.35 444.35 0 00-143.3-96.3C630.6 75.7 572.2 64 512 64h-2c-60.6.3-119.3 12.3-174.5 35.9a445.35 445.35 0 00-142 96.5c-40.9 41.3-73 89.3-95.2 142.8-23 55.4-34.6 114.3-34.3 174.9A449.4 449.4 0 00112 714v152a46 46 0 0046 46h152.1A449.4 449.4 0 00510 960h2.1c59.9 0 118-11.6 172.7-34.3a444.48 444.48 0 00142.8-95.2c41.3-40.9 73.8-88.7 96.5-142 23.6-55.2 35.6-113.9 35.9-174.5.3-60.9-11.5-120-34.8-175.6zm-151.1 438C704 845.8 611 884 512 884h-1.7c-60.3-.3-120.2-15.3-173.1-43.5l-8.4-4.5H188V695.2l-4.5-8.4C155.3 633.9 140.3 574 140 513.7c-.4-99.7 37.7-193.3 107.6-263.8 69.8-70.5 163.1-109.5 262.8-109.9h1.7c50 0 98.5 9.7 144.2 28.9 44.6 18.7 84.6 45.6 119 80 34.3 34.3 61.3 74.4 80 119 19.4 46.2 29.1 95.2 28.9 145.8-.6 99.6-39.7 192.9-110.1 262.7z">
                                  </path>
                                </svg>
                              </span>
                            </div>





                          </div>
                        </div>
                      </nz-card>

                      <ng-template #extraTemplate>
                        <button nz-button nzType="default" class="me-2" (click)="OpenAproveStatus()" *ngIf="!selectionResponses?.StatusName?.includes('Selected')">
                          <span style="color: rgb(6, 162, 96);margin-right: 5px;font-size: 15px;"><svg viewBox="64 64 896 896" focusable="false" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span>
                          Approve!</button>
                      </ng-template>
                    </div>
                  </div>
                </ng-container>

                <ng-template #Test>

                    <div class="row">
                      <nz-table #basicTable>
                        <thead class="back-color-set">
                          <tr>
                            <th nzWidth="20px"><label nz-checkbox></label></th>
                            <th nzWidth="250px"></th>
                            <th>Choice</th>
                            <th>Details</th>
                            <th>Status / Alerts</th>
                            <th>Files</th>
                            <th>Price</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                            <!-- <tr  *ngFor="let card of cards">
                              <td nzWidth="20px"><label nz-checkbox></label></td>
                              <td nzWidth="250px">
                                <ng-container >
                                  <div class="Thumbnail--fallback" style="width: 100px; height: 100px;">
                                    <div class="Thumbnail--fallback Thumbnail--fallback-default" style="width: 100px; height: 100px;">
                                      <img alt="Buildertrend Logo"
                                      [src]="holdPath ? holdPath : 'data:image/svg+xml,%3c?xml%20version=%271.0%27%20encoding=%27UTF-8%27?%3e%3csvg%20id=%27Layer_1%27%20data-name=%27Layer%201%27%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20viewBox=%270%200%201000%201232%27%3e%3cdefs%3e%3cstyle%3e%20.cls-1%20{%20fill:%20%23acb8c3;}%20.cls-1,%20.cls-2%20{%20stroke-width:%200px;}%20.cls-2%20{%20fill:%20%230e0f10;}%20%3c/style%3e%3c/defs%3e%3cpath%20class=%27cls-2%27%20d=%27M411.001,230.943h-209.645V.334H0v637.164c0,.006.001.01.001.017.009,224.526,182.025,406.538,406.554,406.538s406.552-182.021,406.552-406.554c0-223.042-179.631-404.159-402.106-406.555ZM406.555,842.087c-112.992,0-204.588-91.598-204.588-204.589s91.595-204.588,204.588-204.588,204.588,91.597,204.588,204.588-91.598,204.589-204.588,204.589Z%27/%3e%3cpath%20class=%27cls-1%27%20d=%27M696.61,120.656l-40.89,70.823c156.484,87.466,262.307,254.732,262.307,446.74,0,282.479-228.995,511.474-511.474,511.474-95.891,0-185.603-26.413-262.302-72.324l-40.882,70.809c88.79,52.989,192.485,83.488,303.184,83.488,327.228,0,593.447-266.219,593.447-593.447,0-221.94-122.486-415.78-303.39-517.564Z%27/%3e%3c/svg%3e'" width="120px" height="90px" class="Thumbnail--BTLogo-light"
                                      >

                                    </div>
                                  </div>
                               </ng-container>
                              </td>
                              <td><h3 class="set-add-heading-choice">{{card?.title}}</h3></td>
                              <td><p class="">{{card?.description}}</p></td>
                              <td><nz-tag class="badge-set">UNRELEASED</nz-tag></td>
                              <td>
                                <button
                                [nzTrigger]="'click'"
                                nz-button
                                class="text-primary border-0"
                                nz-dropdown
                                [nzDropdownMenu]="menu4"
                              >
                                <i
                                  class="fa-solid fa-link text-primary me-"
                                ></i
                                ><span class="text-primary">
                                  <svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path></svg>
                                <span class="text-primary">1</span>
                                </span>
                                <span nz-icon nzType="down"></span>
                              </button>
                              <nz-dropdown-menu #menu4="nzDropdownMenu">
                                <ul nz-menu>
                                  <li nz-menu-item>
                                    {{holdName}}
                                  </li>
                                </ul>
                              </nz-dropdown-menu>
                              </td>
                              <td>
                                <span class="set-value-dollar-choicecard">{{card?.ownerPrice}}</span>
                              </td>
                              <td>
                                <button nz-button nzType="default" class="me-2" (click)="ApproveStatus()">
                                  <span style="color: rgb(6, 162, 96);margin-right: 5px;font-size: 15px;"><svg viewBox="64 64 896 896" focusable="false" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span>
                                  Approve12
                                </button>
                              </td>
                            </tr> -->
                            <tr *ngFor="let item of selectionDatas">
                              <td nzWidth="20px"><label nz-checkbox></label></td>
                              <td nzWidth="250px">
                                <ng-container >
                                  <div class="Thumbnail--fallback" style="width: 100px; height: 100px;">
                                    <div class="Thumbnail--fallback Thumbnail--fallback-default" style="width: 100px; height: 100px;">
                                      <img alt="Persistent Logo"
                                      [src]="firstFilePath  ? firstFilePath :  'data:image/svg+xml,%3c?xml%20version=%271.0%27%20encoding=%27UTF-8%27?%3e%3csvg%20id=%27Layer_1%27%20data-name=%27Layer%201%27%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20viewBox=%270%200%201000%201232%27%3e%3cdefs%3e%3cstyle%3e%20.cls-1%20{%20fill:%20%23acb8c3;}%20.cls-1,%20.cls-2%20{%20stroke-width:%200px;}%20.cls-2%20{%20fill:%20%230e0f10;}%20%3c/style%3e%3c/defs%3e%3cpath%20class=%27cls-2%27%20d=%27M411.001,230.943h-209.645V.334H0v637.164c0,.006.001.01.001.017.009,224.526,182.025,406.538,406.554,406.538s406.552-182.021,406.552-406.554c0-223.042-179.631-404.159-402.106-406.555ZM406.555,842.087c-112.992,0-204.588-91.598-204.588-204.589s91.595-204.588,204.588-204.588,204.588,91.597,204.588,204.588-91.598,204.589-204.588,204.589Z%27/%3e%3cpath%20class=%27cls-1%27%20d=%27M696.61,120.656l-40.89,70.823c156.484,87.466,262.307,254.732,262.307,446.74,0,282.479-228.995,511.474-511.474,511.474-95.891,0-185.603-26.413-262.302-72.324l-40.882,70.809c88.79,52.989,192.485,83.488,303.184,83.488,327.228,0,593.447-266.219,593.447-593.447,0-221.94-122.486-415.78-303.39-517.564Z%27/%3e%3c/svg%3e'" width="120px" height="90px" class="Thumbnail--BTLogo-light"
                                      >
                                    </div>
                                  </div>
                               </ng-container>
                              </td>
                              <td><h3 class="set-add-heading-choice">{{item?.Title}}</h3></td>
                              <td><p class="">{{item?.Description}}</p></td>
                              <td><nz-tag class="badge-set">UNRELEASED</nz-tag></td>
                              <td>
                                <button
                                [nzTrigger]="'click'"
                                nz-button
                                class="text-primary border-0"
                                nz-dropdown
                                [nzDropdownMenu]="menu4"
                              >
                                <i
                                  class="fa-solid fa-link text-primary me-"
                                ></i
                                ><span class="text-primary">
                                  <svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path></svg>
                                <span class="text-primary">1</span>
                                </span>
                                <span nz-icon nzType="down"></span>
                              </button>
                              <nz-dropdown-menu #menu4="nzDropdownMenu">
                                <ul nz-menu>
                                  <li nz-menu-item *ngFor="let detail of fileDetails">
                                    {{detail?.footImage}}
                                  </li>
                                </ul>
                              </nz-dropdown-menu>
                              </td>
                              <td>
                                <span class="set-value-dollar-choicecard">{{item?.OwnerPrice}}</span>
                              </td>
                              <td>
                                <button nz-button nzType="default" class="me-2" (click)="OpenAproveStatus()">
                                  <span style="color: rgb(6, 162, 96);margin-right: 5px;font-size: 15px;"><svg viewBox="64 64 896 896" focusable="false" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span>
                                  Approve
                                </button>

                                <!-- settt  -->
                              </td>
                            </tr>
                        </tbody>
                      </nz-table>
                    </div>
                    <ng-template #extraTemplate>
                      <button nz-button nzType="default">Approve</button>
                    </ng-template>

                  <div class="col-lg-12">
                    <div class="card" (click)="openSelectionChoice($event)" style="height: 140px;">
                      <div class="card-body" style="padding-top: 30px;">
                        <div class="text-center text-success">
                          <span role="img" aria-label="plus-circle" class="anticon anticon-plus-circle" style="font-size: 24px;">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="plus-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"></path>
                            </svg>
                          </span>
                        </div>
                        <h2 class="ant-typography text-center ant-typography-bold">
                          <span class="text-success">Create New Choice</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </nz-card>
        </nz-card>
        <ng-template #ChooiceTabTemplate>

          <nz-button-group class="me-2" [nzSize]="size">
            <button (click)="CNChoicesradioButtons('A')" nz-button nzType="default">
              <span><span ><svg viewBox="64 64 896 896" focusable="false" data-icon="appstore" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M864 144H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm0 400H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zM464 144H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm0 400H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16z"></path></svg></span></span>
            </button>
            <button (click)="CNChoicesradioButtons('B')" nz-button nzType="default">
              <span><span ><svg viewBox="64 64 896 896" focusable="false" data-icon="unordered-list" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 192H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM104 228a56 56 0 10112 0 56 56 0 10-112 0zm0 284a56 56 0 10112 0 56 56 0 10-112 0zm0 284a56 56 0 10112 0 56 56 0 10-112 0z"></path></svg></span></span>
            </button>
          </nz-button-group>
          <button class="me-2" nz-button nz-dropdown [nzType]="'primary'" [nzTrigger]="'click'"  [nzDropdownMenu]="menu4" *ngIf="!selectionResponses?.StatusName?.includes('Selected')">
            Add Choices
            <span nz-icon nzType="down"></span>
          </button>
          <nz-dropdown-menu #menu4="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="openSelectionChoice($event)">New</li>
              <li nz-menu-item>From Other Selection</li>
            </ul>
          </nz-dropdown-menu>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="tab === 2">
        <!-- <app-selection-description ></app-selection-description> -->
        <div class="row">
          <div class="col-12">
            <label class="para-lable">Public Instructions</label>
            <div class="editor-container">
              <!-- <kendo-editor [value]="editorValue" [(ngModel)]="newSelectionFormFiedls['publicInstructions']" name="publicInstructions"
                [style]="{'flex-direction': 'column-reverse'}"></kendo-editor> -->
                <ckeditor
                [(ngModel)]="newSelectionFormFiedls.publicInstructions"
                name="publicInstructions"
                [editor]="Editor"
                [config]="config"
                class="size"
                id="changeOrdereditor">
              </ckeditor>
            </div>





          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="para-lable">Internal Notes</label>
            <!-- <kendo-editor [value]="editorValue" [(ngModel)]="newSelectionFormFiedls['internalNotes']" name="internalNotes"
                [style]="{'flex-direction': 'column-reverse'}"></kendo-editor> -->
                <ckeditor
                [(ngModel)]="newSelectionFormFiedls.internalNotes"
                name="internalNotes"
                [editor]="Editor"
                [config]="config"
                class="size"
                id="changeOrdereditor">
              </ckeditor>
          </div>
        </div>

      </ng-container>
      <ng-container *ngIf="tab === 3">
      <!-- <app-selection-lock-and-unlock></app-selection-lock-and-unlock> -->
      <nz-card [nzTitle]="locktitlemanage"  nzBordered="false">
        <ng-template #locktitlemanage>
            <h5>Lock or Unlock This Selection</h5>
        </ng-template>
        <div class="row">
          <div class="col">
         <span class="pira-label"> Approving any of the following chooice will unlock this Selection for approval</span>
        </div>
        </div>
        <div class="row">
          <div class="col mt-3 ">
           <button nz-button class="ant-btn-primary rounded-2" >Add/Edit Chooice</button>
          </div>
        </div>
        </nz-card>
      </ng-container>
      <ng-container *ngIf="tab === 4">
        <!-- <app-selection-files-discussion></app-selection-files-discussion> -->

        <!-- <nz-card nzBorderless="true" [nzTitle]="filestitle">
          <ng-template #filestitle>
            <h5  class="pira-label">Attachments</h5>
          </ng-template>
            <div class="row ">
              <div class="col-12 p-0 ">
                <button class="me-2 ms-2" nz-button (click)="uploadFiles()">Add</button>
                <button nz-button (click)="createNew()">Create New Doc</button>
                <button (click)="viewAll()" *ngIf="selectedFiles.length > 0" nz-button nzType="default"
                  class="rounded-1 me-2">View All ({{ selectedFiles.length }})</button>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-1 filUpload me-3" *ngFor="let file of selectedFiles">
                <div class="row gx-0 mt-2 p-0 m-0">
                  <div class="col-12 mt-2">
                    <span class="m-0 p-0d d-flex align-items-center justify-content-center"
                      style="border-radius: 6px; object-fit: cover;" nz-icon nzType="file-text"
                      nzTheme="outline"></span>
                  </div>
                </div>
                <div class="row p-0 m-0">
                  <div class="col-12">
                    <label class="ListViewJobname">{{file.name}}</label>
                  </div>
                </div>
                <div class="row p-0 m-0">
                  <div class="col-12">
                    <i nzTrigger="click" nzType="down" nz-dropdown [nzDropdownMenu]="menu4"
                      class="fa-solid fa-chevron-down fw-bolder"></i>
                    <nz-dropdown-menu #menu4="nzDropdownMenu">
                      <ul nz-menu>
                        <li nz-menu-item><i class="fa-solid fa-pen me-2"></i>Edit Online</li>
                        <li (click)="removeFile(file)" nz-menu-item><i
                            class="fa-regular fa-trash-can me-2"></i>Delete</li>
                      </ul>
                    </nz-dropdown-menu>
                  </div>
                </div>
              </div>
            </div>
          </nz-card> -->


<!-- Attachment Work -->

<nz-card class="" [nzBordered]="false">
  <div class="card-body bt-file-viewer-card">
    <div class="row">
      <div class="col-12">
        <app-create-attachments-common [TestAttachment__]="TestAttachment" (filesUploaded)="onFilesUploaded($event)"></app-create-attachments-common>
      </div>
    </div>
  </div>
</nz-card>

<!-- Attachment Work -->


          <nz-card nzBorderless="true" [nzTitle]="messagingtitle"  *ngIf="selectionReponse === -1">
            <ng-template #messagingtitle>
              <h5 class="pira-label">Messaging</h5>
            </ng-template>
            <div class="row">
              <div class="col">
                <h5 class="pira-label">Message available after save</h5>
              </div>
            </div>
          </nz-card>


           <ng-container *ngIf="selectionReponse">
            <messaging-common
            [Job_ID]="JobInfoId"
            [header_ID]="ChangeOrderInfoId"
            [Form_Name_ID]="formNameId"
            >
            </messaging-common>
          </ng-container>


      </ng-container>
      <ng-container *ngIf="tab === 5">
        <div class="row">
          <div class="col-12">
            <nz-card nzBorderless="true" [nzTitle]=" Ownertitle">
              <ng-template #Ownertitle>
                <h5>Owner</h5>
              </ng-template>
              <div class="row">
                <div class="col">
                  <span>Miteshkumar Manubhai Sathavara and Priyanka Miteshkumar Sathavara</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-2">
                  <label class="sub-text">Allowed to</label>
                </div>
                <nz-radio-group [(ngModel)]="radioValues">
                  <label [(ngModel)]="newSelectionFormFiedls['isApproveChoicesOnly']" name="isApproveChoicesOnly"  nz-radio nzValue="A" class="mt-2 ms-3">Approve Choices Only</label><br>
                  <label [(ngModel)]="newSelectionFormFiedls['isAddEditAndApproveChoices']" name="isAddEditAndApproveChoices" nz-radio nzValue="B" class="mt-2 ms-3">Add/Edit & Approve Choices</label>
                </nz-radio-group>
              </div>
            </nz-card>
            <nz-card [nzTitle]="Venderstitle" nzBordered="false">
              <ng-template #Venderstitle>
                <h5>Venders</h5>
              </ng-template>
              <div class="row">
                <div class="col-5 ">
                  <label class="sub-text">
                    Venders <i class="bi bi-info-circle-fill text-secondary  ms-1"></i>
                  </label>
                  <nz-tree-select  style="width: 100%" [(ngModel)]="newSelectionFormFiedls['createSelectionSubVenderParameterRequests']" name="createSelectionSubVenderParameterRequests"
                  [nzNodes]="vendersNodes" nzShowSearch nzAllowClear="false" nzCheckable nzCheckAll="true"
                  nzPlaceHolder="Select Status">
                </nz-tree-select>
                </div>
              </div>
              <div class="row ">
                <div class="col-12 mt-2">
                  <label class="sub-text">Allowed to</label>
                </div>
                <nz-radio-group [(ngModel)]="radioValuess">
                  <label [(ngModel)]="newSelectionFormFiedls['isApproveChoicesOnly']" name="isApproveChoicesOnly"  nz-radio nzValue="A" class="mt-2 ms-3">Approve Choices Only</label><br>
                  <label [(ngModel)]="newSelectionFormFiedls['isAddEditAndApproveChoices']" name="isAddEditAndApproveChoices" nz-radio nzValue="B" class="mt-2 ms-3">Add/Edit & Approve Choices</label>
                </nz-radio-group>
              </div>
              <!-- <div class="row mt-1">
                <label nz-radio nzValue="A">View Pricing /Request Only</label>
              </div>
              <div class="row mt-1">
                <label nz-radio nzValue="B">Add/Edit Choices</label>
              </div> -->
            </nz-card>
            <nz-card [nzTitle]="Installerstitle" nzBordered="false">
              <ng-template #Installerstitle>
                <h5>Installers</h5>
              </ng-template>
              <div class="row">
                <div class="col">
                  <label class="sub-text">
                    Installers <i class="bi bi-info-circle-fill text-secondary  ms-1"></i>
                  </label>

                  <div class="row">
                    <div class="col-5 mt-2">
                      <nz-tree-select  style="width: 100%" [(ngModel)]="newSelectionFormFiedls['createSelectionInstallerParameterRequests']" name="createSelectionInstallerParameterRequests"
                        [nzNodes]="installarNodes" nzShowSearch nzAllowClear="false" nzCheckable nzCheckAll="true"
                        nzPlaceHolder="Select Status">
                      </nz-tree-select>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col mt-1">
                <label class="sub-text">Allowed to</label>
              </div>
              <div class="col mt-2">
                <label>View Only</label>
              </div>
            </nz-card>

          </div>
        </div>
      </ng-container>
    </nz-tab>
  </nz-tabset>
</div>
</div>
</div>


<ng-template #SelectionModelFooter>
  <div class="row">
    <div class="col-4">
     <span *ngIf="selectionReponse > 0">
      <span *ngIf="footerResponse">
        <app-footer-label
        [footerData]="footerResponse"
        ></app-footer-label>
      </span>
     </span>
    </div>
    <div class="col-8">
      <nz-space>
        <ng-container *ngIf="selectionResponses !== undefined">
          <button
          nz-button
          nzType="default"
          *nzSpaceItem
          class=""
          (click)="deleteLeadConfirm()"
          [nzLoading]="isDeleteLoading"
        >
          Delete
        </button>
        </ng-container>
      <ng-container  *ngIf="selectionResponses !== undefined">
        <button
        nz-button
        nzType="default"
        *nzSpaceItem
      >
        Print
      </button>
      </ng-container>
      <button
        nz-button
        nzType="primary"
        *ngIf="!isReleased"
        (click)="saveAndRelease()"
      >
        Save & Release
      </button>
      <button
        nz-button
        nzType="primary"
        *nzSpaceItem
        [nzLoading]="isSaveAndNew"

        (click)="saveAndNewSelection()"
      >
        Save & New
      </button>
      <button
        nz-button
        nzType="primary"
        [disabled]="!newSelectionFormFiedls.title"
        *nzSpaceItem
        [nzLoading]="isSave"
        (click)="saveSelection()"
        [disabled]="isButtonDisableds"
      >
        Save
      </button>
      <button
        nz-button
        [nzType]="getButtonType()"
        [disabled]="!newSelectionFormFiedls.title"
        nzType="primary"
        *nzSpaceItem
        [nzLoading]="isSaveAndClose"
        (click)="saveAndCloseSelection()"
      >
        Save & Close
      </button>
      <button
        *ngIf="isReleased"
        nz-button
        [nzType]="getButtonType()"
        nzType="primary"

        (click)="onUnrelease()"
      >
        Unrelease
       </button>
      </nz-space>

    </div>
  </div>
</ng-template>



</nz-modal>

<nz-modal [(nzVisible)]="isConfirmUnreleaseVisible" nzTitle="Unrelease Selection?" (nzOnCancel)="onCancelUnrelease()" (nzOnOk)="confirmUnrelease()">
  <p>Are you sure you want to reset this selection to unreleased?</p>
  <div nz-modal-footer>
    <button nz-button nzType="default" (click)="onCancelUnrelease()">Cancel</button>
    <button nz-button nzType="primary" (click)="confirmUnrelease()">Unrelease</button>
  </div>
</nz-modal>



<!-- new modal open for Approve Form  -->

<nz-modal nzWidth="35%" [(nzVisible)]="Approve" [nzTitle]="ApprovalComments" [nzFooter]="divsionTradeFooter"
  [nzBodyStyle]="{ background: '#f1f4fa', padding: '14px' }" [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="OpenAproveStatuscancel()">
  <ng-template #ApprovalComments>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0">Approval Comments</h1>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <form [formGroup]="ApproveForm">
      <nz-card [nzBorderless]="false" nzTitle="Approval Comments">
        <div class="row">
          <div class="col">
            <div>
              <textarea
              formControlName="ApprovalComments"
              id="ApprovalComments"
              class="ant-input"
              style="height: 54px; min-height: 54px; max-height: 274px; overflow-y: hidden; resize: none;"
              spellcheck="false">
            </textarea>
            </div>
          </div>
        </div>
      </nz-card>
    </form>
  </div>
  <ng-template #divsionTradeFooter>
    <div class="mt-2 align-items-center">


      <button nz-button nzType="primary" class="rounded-1 me-0" (click)="ApproveStatus()" >
        Confirm Approval
      </button>
      <button nz-button nzType="default" class="rounded-1 me-0" (click)="OpenAproveStatuscancel()" >
        cancel
      </button>
    </div>
  </ng-template>
</nz-modal>

<!-- new modal open for Approve Form  -->


<nz-modal
  nzWidth="35%"
  [(nzVisible)]="isTagVisible"
  [nzTitle]="divsionTradeTitle"
  [nzFooter]="divsionTradeFooter"
  [nzBodyStyle]="{ background: '#f1f4fa', padding: '14px' }"
  [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="showConfirmData()"
>
  <ng-template #divsionTradeTitle>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0">{{ ModalTitle?.title }}</h1>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <form nz-form [formGroup]="CategoryField">
      <nz-card [nzBorderless]="false" nzTitle="{{ ModalTitle?.title }}">
        <div class="row">
          <div class="col">
            <label class="sub-text">
              Title<span class="text-danger fw-bolder">*</span>
            </label>
            <nz-form-item>
              <nz-form-control
                [nzValidateStatus]="CategoryField.get('name')"
                [nzErrorTip]="nameErrorTemplate"
              >
                <input
                  type="text"
                  nz-input
                  class="form-control "
                  formControlName="name"
                />
              </nz-form-control>
            </nz-form-item>
            <ng-template #nameErrorTemplate let-control>
              <ng-container
                *ngIf="control.hasError('required')"
                class="text-danger"
                >Required</ng-container
              >
              <ng-container
                *ngIf="control.hasError('maxlength')"
                class="text-danger"
              >
                {{ exceededCharacters }}
                {{ exceededCharacters === 1 ? "character" : "characters" }}
              </ng-container>
            </ng-template>
          </div>
        </div>
      </nz-card>
    </form>
  </div>

  <ng-template #divsionTradeFooter>
    <div class="mt-2 align-items-center">
      <button
        *ngIf="isLoadingEdit"
        nz-button
        nzType="default"
        class="me-0"
        (click)="ConfirmDeleteTags(ModalTitle?.deleteTitle)"
        [nzLoading]="isDeleteTagsLoading"
      >
        Delete
      </button>

      <button
        nz-button
        nzType="primary"
        class="me-0"
        (click)="SaveOrUpdateData()"
        [nzLoading]="isLoadingOne"
      >
        {{ isLoadingEdit ? "Update" : "Save" }}
      </button>
    </div>
  </ng-template>
</nz-modal>




<!-- new modal open -->
