<nz-layout>
  <nz-sider
    class="border"
    nzCollapsible
    [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed"
    [nzWidth]="280"
    [nzTrigger]="null"
  >
    <div class="row">
      <app-side-menu-bar
      [isAllJobOption]="true"
      (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"
      ></app-side-menu-bar>
    </div>
  </nz-sider>
<nz-layout>
  <nz-page-header>
      <nz-page-header-title>
      <nz-breadcrumb class="text-start" nz-page-header-breadcrumb>
        <nz-breadcrumb-item>ZH-211 Lot 1909 Cosgrove Street Tarneit VIC 3029</nz-breadcrumb-item>
      </nz-breadcrumb>
        <h1>
          Owner Invoice
        </h1>
      </nz-page-header-title>

      <nz-page-header-extra>
        <nz-space>
          <button *nzSpaceItem nz-button nzType="default" class="rounded-1">
            Import Invoices
          </button>
          <button
            *nzSpaceItem
            nz-button
            nzType="default" (click)="openFilter(Owner_Invoice,PAGE_ID)"
            class="d-flex align-items-center rounded-1"
          >
            <i class="bi bi-funnel fs-6 me-1"></i>Filter
          </button>
          <button
            class="d-flex align-items-center rounded-1"
            [nzTrigger]="'click'"
            nz-dropdown
            [nzDropdownMenu]="NewInvoices"
            *nzSpaceItem
            nz-button
            nzType="primary"
          >
            New
            <span nz-icon nzType="down"></span>
          </button>
        </nz-space>
      </nz-page-header-extra>
      <nz-dropdown-menu #NewInvoices="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item>Owner Invoice</li>
          <li nz-menu-item>Credit Memo</li>
        </ul>
      </nz-dropdown-menu>
      <nz-page-header-content>
        <nz-row nzType="flex">
          <nz-statistic
            [nzTitle]="Jobrunningtotal"
            nzValue="0.00"
            nzPrefix="$"
          ></nz-statistic>
          <ng-template #Jobrunningtotal>
            <h5>Job running total</h5>
          </ng-template>
          <nz-statistic
            [nzTitle]="Appliedcreditmemos"
            nzValue="0.00"
            nzPrefix="-  $"
            style="margin: 0 32px"
          ></nz-statistic>
          <ng-template #Appliedcreditmemos>
            <h5>Applied credit memos</h5>
          </ng-template>
          <nz-statistic
            [nzTitle]="Amountpaid"
            nzValue="0.00"
            nzPrefix="-  $"
          ></nz-statistic>
          <ng-template #Amountpaid>
            <h5>Amount paid</h5>
          </ng-template>
          <nz-statistic
            [nzTitle]="Remainingbalance"
            nzValue="0.00"
            nzPrefix="=  $"
            style="margin: 0 32px"
          ></nz-statistic>
          <ng-template #Remainingbalance>
            <h5>Remaining balance</h5>
          </ng-template>
        </nz-row>
      </nz-page-header-content>
    </nz-page-header>
    
  <nz-content class="custom-content">
      <div class="container-fuild mb-4 m-3">
      <nz-tabset nzType="card">
        <nz-tab
          *ngFor="let tab of tabs"
          [nzTitle]="innerCardTabs(tab)"
          style="background-color: #f1f4fa"
        >
          <ng-container *ngIf="tab === 1">
            <app-owner-invoices-empty></app-owner-invoices-empty>
          </ng-container>
          <ng-container *ngIf="tab === 2">
            <owner-invoice-payment-empty></owner-invoice-payment-empty>
            
          </ng-container>
          <ng-container *ngIf="tab === 3">
            <owner-invoice-credit-memos-empty></owner-invoice-credit-memos-empty>
          </ng-container>
        </nz-tab>
      </nz-tabset>
    </div>
    </nz-content>
  </nz-layout>
  </nz-layout>
