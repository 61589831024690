<nz-modal class="shadow" nzWidth="90%" [(nzVisible)]="LeadOpportunityNewLeadmodal" [nzTitle]="modalTitle" [nzFooter]="LeadOpportunitySettingmodalFooter"
[nzBodyStyle]="{background: '#f1f4fa',padding: '13px','max-height': '78vh','overflow-x': 'hidden',}" [nzStyle]="{ top: '15px' }" (nzOnCancel)="showConfirmCardLead()">
  <ng-template #modalTitle>
    <div class="row">
      <h2 class="p-0 m-0 react">Lead Opportunity</h2>
    </div>
  </ng-template>
  <form nz-form>
    <div class="content" *nzModalContent>
      <div class="top-of-modal mb-2"></div>
      <div class="spinner-wrapper" *ngIf="isLoading">
        <nz-spin nzSimple [nzSpinning]="true" [nzSize]="'large'"></nz-spin>
      </div>

      <nz-alert
        class="mb-3 error-message"
        *ngIf="isSubmitted && errorFields.length > 0"
        nzType="error"
        nzShowIcon
        [nzMessage]="message"
        [nzDescription]="errorDescriptionTemplate"
      ></nz-alert>

      <ng-template #message>
        <p class="mt-1">Please correct the following fields:</p>
      </ng-template>

      <ng-template #errorDescriptionTemplate>
        <ul>
          <li class="sub-text" *ngFor="let error of errorFields">
            <span class="d-flex align-items-center">
              <p>{{ error.label }}:</p>
              <p>{{ error.message }}</p>
            </span>
          </li>
        </ul>
      </ng-template>
      <nz-card
        class="mb-3"
        *ngIf="selectedCustomer; else noSelectedCustomer"
        [nzTitle]="ContactInformations"
        [nzExtra]="extraTemplate"
      >
        <ng-template #ContactInformations>
          <h3 class="p-0 m-0 react" nz-typography>Contact Information</h3>
        </ng-template>
        <div nz-row [nzGutter]="85">
          <div nz-col nzSpan="1">
            <nz-avatar [nzSize]="64" nzIcon="user"></nz-avatar>
          </div>
          <div nz-col nzSpan="20">
            <div class="row">
              <div class="col-12">
                <p
                  class="famly-inn mb-1"
                  *ngIf="
                    selectedCustomer?.firstName || selectedCustomer?.lastName
                  "
                >
                  {{ selectedCustomer?.firstName }}
                  {{ selectedCustomer?.lastName }}
                </p>

                <p class="famly-inn mb-1" *ngIf="selectedCustomer?.displayName">
                  {{ selectedCustomer?.displayName }}
                </p>
                <p
                  class="famly-inn mb-1"
                  *ngIf="selectedCustomer?.streetAddress"
                >
                  {{ selectedCustomer?.streetAddress }}
                </p>
                <p class="famly-inn mb-1" *ngIf="selectedCustomer?.suburb">
                  {{ selectedCustomer?.suburb }}
                </p>
                <p
                  class="famly-inn mb-1"
                  *ngIf="
                    selectedCustomer?.state || selectedCustomer?.postalCode
                  "
                >
                  {{ selectedCustomer?.state }}
                  {{ selectedCustomer?.postalCode }}
                </p>
                <p class="famly-inn mb-1" *ngIf="selectedCustomer?.phone">
                  Phone:
                  <a class="text-primary"> {{ selectedCustomer?.phone }}</a>
                </p>
                <p class="famly-inn mb-1" *ngIf="selectedCustomer?.cellPhone">
                  Cell:
                  <a class="text-primary"> {{ selectedCustomer?.cellPhone }}</a>
                </p>
                <p
                  class="famly-inn mb-1"
                  *ngIf="selectedCustomer?.primaryEmail"
                >
                  Primary Email:
                  <i
                    class="bi bi-info-circle-fill me-1 text-secondary"
                    nz-popover
                    [nzPopoverContent]="contentTemplate"
                    nzPopoverPlacement="top"
                  ></i>
                  <ng-container
                    *ngIf="selectedCustomer?.label; else showPrimaryEmail"
                  >
                    {{ selectedCustomer?.label }}
                  </ng-container>
                  <ng-template #showPrimaryEmail>
                    <a
                      class="text-primary"
                      *ngIf="selectedCustomer?.primaryEmail"
                    >
                      {{ selectedCustomer?.primaryEmail }}
                    </a>
                  </ng-template>
                  <ng-template #contentTemplate>
                    <div class="">
                      <p style="height: auto; width: 180px">
                        This email activates and maintains the account
                      </p>
                    </div>
                  </ng-template>
                </p>

                <p
                  class="famly-inn mb-1"
                  *ngIf="
                    selectedCustomer?.customerEmailParameters &&
                    selectedCustomer.customerEmailParameters.length > 0
                  "
                >
                  Additional Email:
                  <span
                    *ngFor="
                      let emailObj of selectedCustomer.customerEmailParameters;
                      let last = last
                    "
                  >
                    <a *ngIf="emailObj.label" class="text-primary">{{
                      emailObj.label
                    }}</a>
                    <a *ngIf="!emailObj.label" class="text-primary">{{
                      emailObj.email
                    }}</a>
                    <ng-container *ngIf="!last">, </ng-container>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Saim Start work// Saim-new-proposal-data-binding-work -->
        <ng-template #extraTemplate>
          <nz-space>
            <button
              *nzSpaceItem
              (click)="leadOpportunityEditCustomer(selectedCustomer.id, false)"
              nz-button
              nzType="default"
              class=" "
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
              Edit Contact
            </button>
            <button
              *nzSpaceItem
              nz-button
              nzType="default"
              (click)="onRemoveClick()"
              class=" "
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
              Remove
            </button>
          </nz-space>
          <!-- Saim End work// Saim-new-proposal-data-binding-work -->
        </ng-template>
      </nz-card>
      <!-- Saim start work // 3/14/2024 // Saim-Contact-list-new-works// -->

      <!-- //////// Saim Start  here // 13-3-2024 // Saim/new-implements/works //////// -->

      <!-- //////// Saim Start Work here // 13-3-2024 // Saim/new-implements/works //////// -->

      <ng-template #noSelectedCustomer>
        <nz-card [nzTitle]="ContactInformation" class="">
          <ng-template #ContactInformation>
            <h3 class="p-0 m-0 react" nz-typography>Contact Information</h3>
          </ng-template>
          <div nz-row >
            <div nz-col nzSpan="24">
              <h3 style="font-size: 24px;" class="p-0 m-0 mt-2 react text-center" nz-typography>Connect your clients to their projects</h3>
            </div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="24">
                <p class="react pt-2 mb-4 text-center" style="font-size: 16px">
                  Create a client contact and assign them to jobs and lead opportunities effortlessly.
                </p>
              </div>
            </div>
            <div class="row">
            <div class="d-flex align-content-center justify-content-center">
              <div nz-row>
                <div nz-col nzSpan="8">
                  <button (click)="showContactlModal(false)"[disabled]="DisabledNextActivity" [class.disabled]="DisabledNextActivity" nz-button class="button-primary react m-0 p-0"><span>New Contact</span></button>
                </div>
                <div nz-col nzSpan="12">
                  <button (click)="chooseExistingContactCustomerOpen()" [disabled]="DisabledNextActivity" [class.disabled]="DisabledNextActivity" nz-button class="button-default react ms-2"><span>Choose from Existing Contacts</span></button>
                </div>
              </div>
            </div>
            </div>
                <nz-modal
                  nzWidth="59%"
                  [(nzVisible)]="isChooseVisible"
                  [nzFooter]="contactListmodalFooter"
                  [nzTitle]="modalTitle"
                  [nzBodyStyle]="{
                    background: '#f1f4fa',
                    padding: '13px',
                    'max-height': '440px',
                    'overflow-y': 'auto'
                  }"
                  [nzStyle]="{ top: '15px' }"
                  (nzOnCancel)="cancelchooseExistingModal()"
                >
                  <ng-template #modalTitle>
                    <div class="row p-0 m-0">
                      <h2 class="p-0 m-0 fw-bold famly-inn">Contact List</h2>
                    </div>
                  </ng-template>

                  <div class="content" *nzModalContent>
                    <!-- <app-choose-form-existing-contacts></app-choose-form-existing-contacts> -->
                    <nz-card [nzTitle]="Searchbynameemail">
                      <ng-template #Searchbynameemail>
                        <h6 class="famly-inn fw-medium mb-2">
                          Search by name email
                        </h6>
                        <div class="row">
                          <div class="col-6">
                            <nz-input-group
                              nzSearch
                              [nzAddOnAfter]="suffixIconButton"
                            >
                              <input
                                class="RoundSearchInput"
                                type="text"
                                nz-input
                                placeholder="Input search text"
                                [(ngModel)]="searchText"
                              />
                            </nz-input-group>
                            <ng-template #suffixIconButton>
                              <button
                                style="border-radius: 0px 5px 5px 0px"
                                nz-button
                                nzType="default"
                                nzSearch
                              >
                                <span nz-icon nzType="search"></span>
                              </button>
                            </ng-template>
                          </div>
                        </div>
                      </ng-template>
                      <nz-card class="mb-3">
                        <nz-table #sortTable [nzData]="" nzTableLayout="fixed">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Action</th>
                              <th>
                                <!-- {{ column.title }} -->
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let data of sortTable.data">
                              <td>
                                <button nz-button nzType="default">
                                  Select
                                </button>
                                <!-- Added button -->
                              </td>
                              <td>
                                <nz-tag class="cyan mb-1">Active</nz-tag>
                                <nz-tag class="purple">INACTIVE</nz-tag>
                                <!-- Added nz-tag -->
                              </td>
                              <!-- <td>{{ data.name }}</td>
                          <td>{{ data.chinese }}</td>
                          <td>{{ data.math }}</td>
                          <td>{{ data.english }}</td> -->
                            </tr>
                          </tbody>
                        </nz-table>
                        <nz-pagination
                          [nzPageIndex]="3"
                          nzShowSizeChanger
                          [nzPageSize]="10"
                        ></nz-pagination>
                      </nz-card>
                    </nz-card>
                  </div>
                  <ng-template #contactListmodalFooter> </ng-template>
                </nz-modal>
        </nz-card>
      </ng-template>
      <!-- Tab  -->
      <form>
        <div class="card-container2">
          <nz-tabset class="mt-2" nzType="card" [nzSelectedIndex]="mainTabs.indexOf(activeTab)">
            <nz-tab *ngFor="let tab of mainTabs" [nzTitle]="getTab(tab)">
              <!-- Start Of Tab General -->
              <ng-container *ngIf="tab === 9">
                <nz-card class="border-0">
                  <div nz-row>
                    <div nz-col nzSpan="12">
                      <label class="sub-text">Opportunity Title <span class="text-danger fw-bolder ms-1x">*</span></label>
                    </div>
                    <div nz-col nzSpan="12" class="text-end">
                      <small *ngIf="leadOpportunityResponse" class="col-6 p-0 react">Last contacted by {{ leadOpportunityResponse?.createdByUser?.fullName }} on {{ leadOpportunityResponse?.modifiedOn | date : "MMM d, YYYY" }}</small>
                    </div>
                  </div>
                  <div nz-row>
                    <div nz-col nzSpan="24">
                      <nz-form-control>
                        <input
                        #titleInput
                        nz-input
                        [(ngModel)]="formFieldValues.title"
                        name="title"
                        required
                        (ngModelChange)="onFieldChange('title', $event)"
                        [nzStatus]="validationStates['title']"
                        autocomplete="off"
                      />
                      </nz-form-control>
                      <div
                      *ngIf="validationStates['title'] === 'error'"
                      class="text-danger"
                    >
                      {{ validationMessages["title"] }}
                    </div>
                    </div>
                  </div>
                  <div nz-row class="pt-2 pb-2">
                    <div nz-col nzSpan="12">
                      <h3 style="font-weight: 700;" class="p-0 m-0 react" nz-typography>Address</h3>
                    </div>
                    <div nz-col nzSpan="12" class="text-end">
                      <a *ngIf="selectedCustomer" class=" react" (click)="copyFromContact()">Copy Form Contact</a>
                    </div>
                  </div>
                  <div nz-row>
                    <div nz-col nzSpan="24">
                      <label class="sub-text">Street Address</label>
                    </div>
                    <div [nzSpan]="leadOpportunityResponse ? 23 : 24" nz-col>
                      <nz-form-control>
                        <input
                          nz-input
                          [(ngModel)]="formFieldValues.streetAddress"
                          name="streetAddress"
                          (ngModelChange)="
                            onFieldChange('streetAddress', $event)
                          "
                          [nzStatus]="validationStates['streetAddress']"
                        />
                      </nz-form-control>
                      <div
                        *ngIf="validationStates['streetAddress'] === 'error'"
                        class="text-danger"
                      >
                        {{ validationMessages["streetAddress"] }}
                      </div>
                    </div>
                    <div
                    *ngIf="leadOpportunityResponse"
                    nz-col
                    nzSpan="1"
                    style="padding-left: 10px"
                    class="text-center d-flex justify-content-center align-items-center"
                    (click)="
                      generateMapUrl(formFieldValues.streetAddress?.trim())
                    "
                  >
                    <button
                      nz-button
                      nzType="text"
                      class="text-center d-flex justify-content-center align-items-center"
                    >
                      <img
                        src="assets/location-svg/location.svg"
                        alt=""
                        class="img-fluid"
                      />
                    </button>
                  </div>
                  </div>
                  <div class="row gx-2">
                    <div class="col-4">
                      <label class="sub-text">Suburb</label>
                      <nz-form-control>
                        <input
                          nz-input
                          [(ngModel)]="formFieldValues.suburb"
                          name="suburb"
                          #suburbInput="ngModel"
                          (ngModelChange)="onFieldChange('suburb', $event)"
                          [nzStatus]="validationStates['suburb']"
                        />
                      </nz-form-control>
                      <div
                        *ngIf="validationStates['suburb'] === 'error'"
                        class="text-danger"
                      >
                        {{ validationMessages["suburb"] }}
                      </div>
                    </div>
                    <div class="col-4">
                      <label class="sub-text">State</label>
                      <nz-form-control>
                        <input
                          nz-input
                          [(ngModel)]="formFieldValues.state"
                          name="state"
                          #stateInput="ngModel"
                          (ngModelChange)="onFieldChange('state', $event)"
                          [nzStatus]="validationStates['state']"
                        />
                      </nz-form-control>
                      <div
                        *ngIf="validationStates['state'] === 'error'"
                        class="text-danger"
                      >
                        {{ validationMessages["state"] }}
                      </div>
                    </div>
                    <div class="col-4">
                      <label class="sub-text">Postal Code</label>
                      <nz-form-control>
                        <input
                          nz-input
                          [(ngModel)]="formFieldValues.postalCode"
                          name="postalCode"
                          #postalCodeInput="ngModel"
                          (ngModelChange)="onFieldChange('postalCode', $event)"
                          [nzStatus]="validationStates['postalCode']"
                        />
                      </nz-form-control>
                      <div
                        *ngIf="validationStates['postalCode'] === 'error'"
                        class="text-danger"
                      >
                        {{ validationMessages["postalCode"] }}
                      </div>
                    </div>
                  </div>
                  <div nz-row>
                    <div nz-col nzSpan="12">
                      <div class="row">
                        <div class="col-8 p-0 ps-3">
                          <label class="sub-text">Confidence</label>
                          <nz-slider
                            [(ngModel)]="formFieldValues['confidence']"
                            (ngModelChange)="updateSliderValue($event)"
                            name="confidence"
                            [nzTooltipVisible]="'never'"
                          ></nz-slider>
                        </div>
                        <div
                          class="col-1 p-0 text-center"
                          style="margin-top: 33px"
                        >
                          <span
                            *ngIf="formFieldValues['confidence'] !== undefined"
                            dir="ltr"
                            >{{ formFieldValues["confidence"] }} %</span
                          >
                        </div>

                        <div class="col-3 p-0 pe-3">
                          <label class="sub-text" for="displayName"
                            >Projected Sales Date</label
                          >
                          <nz-date-picker
                            nzSuffixIcon="false"
                            [nzFormat]="'MMM d,YYYY'"
                            [nzPlaceHolder]="' '"
                            [(ngModel)]="formFieldValues['projectedSalesDate']"
                            name="projectedSalesDate"
                          ></nz-date-picker>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <label class="sub-text">Salespeople</label>
                    </div>
                    <div class="col-6">
                      <div nz-row>
                        <div nz-col nzSpan="20">
                          <label class="sub-text">Tags</label>
                        </div>
                        <div nz-col nzSpan="2">
                        </div>
                        <div nz-col nzSpan="2">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                        <ng-template #avatarTemplate let-node>
                          <ng-container *ngIf="node.origin.value !== 'check_all'">
                            <nz-avatar
                              nzSize="small"
                              [nzIcon]="
                                !node.origin.firstName && !node.origin.lastName
                                  ? 'user'
                                  : ''
                              "
                              [nzText]="
                                node.origin.firstName || node.origin.lastName
                                  ? getInitials(node.origin)
                                  : ''
                              "
                            >
                            </nz-avatar>
                          </ng-container>
                          <span class="ms-1">{{ node?.title }}</span>
                        </ng-template>
                        <nz-tree-select
                          style="width: 100%"
                          [(ngModel)]="formFieldValues['applicationUserId']"
                          name="applicationUserId"
                          [nzNodes]="salespersonMulti"
                          nzCheckable
                          nzCheckAll="true"
                          nzPlaceHolder="Select Sales people"
                          [nzAllowClear]="false"
                          [nzShowSearch]="true"
                          (ngModelChange)="onUserChange($event)"
                          nzDropdownClassName="dropdownStyle"
                          [nzTreeTemplate]="avatarTemplate"
                        >
                        </nz-tree-select>
                    </div>
                    <div class="col-6">
                      <div nz-row>
                        <div nz-col nzSpan="20">
                          <nz-tree-select
                          [(ngModel)]="formFieldValues['tagSetupId']"
                          name="tagSetupId"
                          style="width: 100%"
                          [nzNodes]="tagNodes"
                          [nzShowSearch]="true"
                          nzAllowClear="false"
                          nzCheckable
                          (ngModelChange)="onTagChange($event)"
                          nzPlaceHolder="Select Tags"
                          nzDropdownClassName="dropdownStyle"
                          [nzAllowClear]="false">
                          </nz-tree-select>
                        </div>
                        <div nz-col nzSpan="2" class="pt-1">
                          <a class="text-end react ms-2" (click)="showTags('Tags', 'LOLVT', 'LOLVT', 40005)">Add</a>
                        </div>
                        <div nz-col nzSpan="2">
                          <button
                          [disabled]="
                            !formFieldValues['tagSetupId'] ||
                            formFieldValues['tagSetupId'].length !== 1
                          "
                          class="btn btn-sm text-primary border-0 me-1"
                          (click)="
                            showTagEdit(formFieldValues['tagSetupId'], 'Tags')
                          "
                        >
                          Edit
                        </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <label class="sub-text">Estimated Revenue</label>
                    </div>
                    <div class="col-6">
                      <div nz-row>
                        <div nz-col nzSpan="20">
                          <label class="sub-text">Sources</label>
                        </div>
                        <div nz-col nzSpan="2">
                        </div>
                        <div nz-col nzSpan="2">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div nz-row>
                        <div nz-col nzSpan="24">
                          <div nz-row>
                            <div nz-col nzSpan="11">
                              <div class="input-group">
                                <nz-input-group nzAddOnBefore="$">
                                  <input
                                    nz-input
                                    appDollarFormatter
                                    [(ngModel)]="
                                      formFieldValues['estimatedRevenueFrom']
                                    "
                                    name="estimatedRevenueFrom

                                "
                                  />
                                </nz-input-group>
                              </div>
                            </div>
                            <div nz-col nzSpan="2">
                              <p class="react text-center" style="font-size: 16px">
                                to
                              </p>
                            </div>
                            <div nz-col nzSpan="11">
                              <nz-input-group nzAddOnBefore="$">
                                <input
                                  nz-input
                                  class=""
                                  appDollarFormatter
                                  [(ngModel)]="
                                    formFieldValues['estimatedRevenueTo']
                                  "
                                  name="estimatedRevenueTo

                              "
                                />
                              </nz-input-group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div nz-row>
                        <div nz-col nzSpan="20">
                          <nz-tree-select
                          [(ngModel)]="formFieldValues['sourceSetupId']"
                          name="sourceSetupId"
                          style="width: 100%"
                          [nzNodes]="sourceNodes"
                          nzCheckAll="true"
                          nzPlaceHolder="Select Sources"
                          nzCheckable
                          [nzAllowClear]="false"
                          [nzShowSearch]="true"
                          (ngModelChange)="onSourceChange($event)"
                          nzDropdownClassName="dropdownStyle"
                        >
                        </nz-tree-select>
                        </div>
                        <div nz-col nzSpan="2" class="pt-1">
                          <a class="text-end react ms-2" (click)="showTags('Sources', 'LOLVS', 'LOLVS', 40006)">Add</a>
                        </div>
                        <div nz-col nzSpan="2">
                          <button
                          [disabled]="
                            !formFieldValues['sourceSetupId'] ||
                            formFieldValues['sourceSetupId'].length !== 1
                          "
                          class="btn btn-sm text-primary border-0 me-1"
                          (click)="
                            showTagEdit(
                              formFieldValues['sourceSetupId'],
                              'Sources'
                            )
                          "
                        >
                          Edit
                        </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div [ngClass]="{'col-6': !isSold && !soldDateReadAble,'col-2': isSold && !soldDateReadAble,'col-5': soldDateReadAble}">
                      <label class="sub-text">Status</label>
                      <nz-select
                        [style.width.%]="100"
                        [(ngModel)]="formFieldValues['statusSetupId']"
                        (ngModelChange)="onStatusChange($event)"
                        [nzDisabled]="isSold && showReclassify"
                        name="statusSetupId"
                      >
                        <nz-option *ngFor="let option of status" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                      </nz-select>
                    </div>
                    <div *ngIf="isSold" [ngClass]="{ 'col-4': !showReclassify, 'col-3': showReclassify }" style="left: -14px">
                      <label class="sub-text">Sold Date</label>
                      <nz-date-picker
                        [style.width.%]="100"
                        [(ngModel)]="formFieldValues['soldOn']"
                        name="soldOn"
                        [nzFormat]="'MMM d, YYYY'"></nz-date-picker>
                    </div>
                    <div *ngIf="showReclassify" class="col-1" style="left: -28px">
                      <label class="sub-text">Reclassify</label>
                      <button
                        nz-button
                        nzType="default"
                        class="button-default"
                        nz-dropdown
                        [nzDropdownMenu]="Reclassify"
                        [nzTrigger]="'click'"
                      >
                        Reclassify
                        <span nz-icon nzType="down"></span>
                      </button>
                      <nz-dropdown-menu #Reclassify="nzDropdownMenu">
                        <ul nz-menu>
                          <li nz-menu-item (click)="classifyAsLostOptionWorking()">As Lost</li>
                          <li nz-menu-item (click)="reOpenAsNewLead()">Re-Open As New Lead</li>
                          <li nz-menu-item (click)="reOpenAndUndoSale()">Re-Open and Undo Sale</li>
                        </ul>
                      </nz-dropdown-menu>
                    </div>
                    <div *ngIf="soldDateReadAble" class="col-1 readabled-date">
                      <label class="sub-text d-flex align-items-center justify-content-center">Sold Date</label>
                      <p class="text-center ms-0" style="font-size: 13px; padding-top: 5px"> {{ leadOpportunityResponse?.soldOn | date : 'MMM d, YYYY' }}</p>
                    </div>
                    <div class="col-6">
                      <div nz-row>
                        <div nz-col nzSpan="20">
                          <label class="sub-text">Project Type</label>
                          <nz-select
                            [style.width.%]="100"
                            [(ngModel)]="formFieldValues['projectTypeSetupId']"
                            nzShowSearch
                            name="projectTypeSetupId"
                            [nzDropdownClassName]="'custom-select-dropdown'"
                          >
                            <nz-option
                              [nzLabel]="'-- None --'"
                              [nzValue]="0"
                            ></nz-option>
                            <nz-option
                              *ngFor="let option of projectType"
                              [nzLabel]="option?.label"
                              [nzValue]="option.value"
                            ></nz-option>
                          </nz-select>
                        </div>
                        <div nz-col nzSpan="2" style="margin-top: 33px; padding: 0px">
                          <a class="text-end react ms-2" (click)="showTags('ProjectType', 'LOLVPT', 'LOLVPT', 40007)">Add</a>
                        </div>
                        <div nz-col nzSpan="2" style="margin-top: 30px; padding: 0px">
                          <button [disabled]=" !formFieldValues['projectTypeSetupId'] || formFieldValues['projectTypeSetupId'] === 0" class="btn btn-sm text-primary border-0 me-1" (click)="showTagEdit(formFieldValues['projectTypeSetupId'],'ProjectType')">Edit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </nz-card>
                <div class="card border-0 mt-3">
                  <div class="card-head border-bottom">
                    <h3 class="p-0 m-0 pt-2 pb-2 ms-3 react" nz-typography>Notes</h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="editor-container">
                        <ckeditor
                          [(ngModel)]="formFieldValues['notes']"
                          name="notes"
                          [editor]="Editor"
                          [config]="config"
                          class="size"
                          id="editor"
                        >
                        </ckeditor>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card border-0 mt-3">
                  <div class="card-head border-bottom">
                    <h3 class="p-0 m-0 pt-2 pb-2 ms-3 react" nz-typography>Attachments</h3>
                  </div>
                  <div class="card-body bt-file-viewer-card">
                    <div class="row">
                      <div class="col-12">
                        <button nz-button class="button-default react" (click)="uploadFiles()"><span>Add</span></button>
                        <button nz-button class="button-default react ms-2" (click)="CreateNewFile()"><span>Create New File</span></button>
                        <button *ngIf="selectedFiless?.length > 0" nz-button class="button-default react ms-2" (click)="viewAll()"><span>View All ({{ selectedFiless?.length }})</span></button>
                      </div>
                    </div>
                    <i *ngIf="showNavButtons" class="bx bx-chevron-left carousel-nav prev" (click)="previous()"></i>
                    <i *ngIf="showNavButtons" class="bx bx-chevron-right carousel-nav next" (click)="next()"></i>
                    <div class="carousel-container" #carouselContainer>
                      <div class="carousel-view" *ngFor="let fileq of selectedFiless">
                        <div class="bt-file-viewer">
                          <img class="img-1" *ngIf="getFileIcon(fileq)" [src]="getFileIcon(fileq)" />
                          <img (click)="onClick()" class="img-2" *ngIf="!getFileIcon(fileq) && !fileq.type.includes('video')" [src]="fileq.thumbUrl" />
                          <ng-container *ngIf="fileq.type.includes('video')">
                            <div class="video-container">
                              <i nz-icon nzType="play-circle" nzTheme="outline" class="video-play-icon" (click)="playVideo(fileq)"></i>
                              <video #videoPlayer id="video-{{ fileq.id }}" [src]="fileq.thumbUrl" (click)="playVideo(fileq)"></video>
                            </div>
                          </ng-container>
                          <div nz-row class="action-row">
                            <div nz-col nzSpan="24">
                              <label class="BTFileUpload-SecondaryText p-0 m-0 ms-2">{{ fileq.name }}</label>
                            </div>
                            <div nz-col nzSpan="5">
                              <i style="margin: 2px 0px 0px 6px;cursor: pointer;font-size: 21px;color: #626262;" nzTrigger="click" nz-dropdown [nzDropdownMenu]="menu4" class="bx bxs-chevron-down"></i>
                              <nz-dropdown-menu #menu4="nzDropdownMenu">
                                <ul nz-menu style="width: 170px;">
                                  <li nz-menu-item (click)="removeFile(fileq)">
                                    <div class="dropdown-container">
                                      <i style="cursor: pointer;font-size: 20px;color: #626262;" class='bx bx-trash'></i>
                                      <span class="react">Delete</span>
                                    </div>
                                  </li>
                                  <li nz-menu-item (click)="uploadFilesUpdate(fileq)" *ngIf="isImageFile(fileq)" >
                                    <div class="dropdown-container">
                                      <i style="cursor: pointer;font-size: 20px;color: #626262;" class='bx bxs-pencil'></i>
                                      <span class="react">Edit Online</span>
                                    </div>
                                  </li>
                                </ul>
                              </nz-dropdown-menu>
                            </div>
                            <div nz-col nzSpan="4" *ngIf="isImageFile(fileq)" >
                              <i (click)="uploadFilesUpdate(fileq)" style="cursor: pointer;font-size: 26px;color: #626262;" class='bx bxs-pencil'></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

                <!-- <ng-template #extraTemplate> </ng-template> -->
                <!-- //////// custom Fields start-->
                <!-- <nz-collapse [nzBordered]="false">
                <nz-collapse-panel #p [ngStyle]="{'background': '#ffffff','border-radius': '4px',
                                                          'margin-bottom': '10px','margin-top': '15px',
                                                          border: '0px'}" nzTitle="Custom Column"
                  [nzExpandedIcon]="expandedIcon" [nzHeader]="tileetwe">
                  <hr>
                  <div class="row mt-0" #p>

                    <div class="row ">
                      <div class="col-4">
                        <label class="sub-text mb-1" for="displayName">Do you have Land</label>
                        <nz-select style="width: 100%" nzShowSearch [lang]="true" nzAutoFocus="true"
                          nzPlaceHolder="Unassigned">
                          <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                          <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                          <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                        </nz-select>
                      </div>
                      <div class="col-2 " style="margin-top: 35px;">
                        <a class="ms-2 " (click)="easementCustomFieldFormModalOpen()">Setting</a>
                      </div>
                    </div>

                    <div class="row ">
                      <div class="col-4">
                        <label class="sub-text mb-1" for="displayName">Assist client souce
                          land</label>
                        <nz-select style="width: 100%" nzShowSearch [lang]="true" nzAutoFocus="true"
                          nzPlaceHolder="Unassigned">
                          <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                          <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                          <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                        </nz-select>
                      </div>
                      <div class="col-2 " style="margin-top: 35px;">
                        <a class="ms-2 " (click)="easementCustomFieldFormModalOpen()">Setting</a>
                      </div>
                    </div>
                    <div class="row ">
                      <div class="col-4">
                        <label class="sub-text mb-1" for="displayName">Land Title</label>
                        <nz-tree-select style="width: 100%" nzShowSearch nzAllowClear="false" nzCheckable
                          nzCheckAll="true" nzPlaceHolder="Select ">
                        </nz-tree-select>
                      </div>
                      <div class="col-2 " style="margin-top: 35px;">
                        <a class="ms-2 " (click)="easementCustomFieldFormModalOpen()">Setting</a>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-2">
                        <label for="example" class="sub-text mb-2 demoo">
                          If No, Date of Title
                        </label>
                        <nz-date-picker nzSuffixIcon="false" [nzPlaceHolder]="' '"></nz-date-picker>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-6">
                        <div class="form-group">
                          <label for="example" class="sub-text mb-2 demoo">
                            Land Size (M2)
                          </label>
                          <div class="d-flex align-items-center gap-3 shadow-none ">
                            <input pInputText type="text"
                              class="form-control formField p-inputtext-sm shadow-none  p-0"
                              style="width: 505px; height: 35px" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-6">
                        <div class="form-group">
                          <label for="example" class="sub-text mb-2 demoo">
                            Land Width (M)
                          </label>
                          <div class="d-flex align-items-center gap-3 shadow-none ">
                            <input pInputText type="text"
                              class="form-control formField p-inputtext-sm shadow-none  p-0"
                              style="width: 505px; height: 35px" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-6">
                        <div class="form-group">
                          <label for="example" class="sub-text mb-2 demoo">
                            Land Length (M)
                          </label>
                          <div class="d-flex align-items-center gap-3 shadow-none ">
                            <input pInputText type="text"
                              class="form-control formField p-inputtext-sm shadow-none  p-0"
                              style="width: 505px; height: 35px" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-4">
                        <label class="sub-text mb-1" for="displayName">Easement</label>
                        <nz-select style="width: 100%" nzShowSearch [lang]="true" nzAutoFocus="true"
                          nzPlaceHolder="Unassigned">
                          <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                          <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                          <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                        </nz-select>
                      </div>
                      <div class="col-2 " style="margin-top: 35px;">
                        <a class="ms-2 " (click)="easementCustomFieldFormModalOpen()">Setting</a>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-4">
                        <label class="sub-text mb-1" for="displayName">Corner Lot</label>
                        <nz-select style="width: 100%" nzShowSearch [lang]="true" nzAutoFocus="true"
                          nzPlaceHolder="Unassigned">
                          <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                          <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                          <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                        </nz-select>
                      </div>
                      <div class="col-2 " style="margin-top: 35px;">
                        <a class="ms-2 " (click)="cornerLotCustomFieldFormModalOpen()">Setting</a>
                      </div>
                    </div>


                    <div class="row mt-3">
                      <div class="col-12">
                        <label class="pira-label" nz-checkbox>POS</label>
                        <i class="bi bi-info-circle-fill text-secondary" nz-popover [nzPopoverContent]="contentTemplate"
                          nzPopoverPlacement="right"></i>
                        <ng-template #contentTemplate>
                          <div class="">
                            <p style="height: auto; width: 180px; ">
                              Selecting a default color for a particular phase
                              determines
                              how that phase will display on the schedule.
                            </p>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <label class="pira-label" nz-checkbox>MCP</label>
                        <i class="bi bi-info-circle-fill text-secondary" nz-popover [nzPopoverContent]="contentTemplate"
                          nzPopoverPlacement="right"></i>
                        <ng-template #contentTemplate>
                          <div class="">
                            <p style="height: auto; width: 180px; ">
                              Selecting a default color for a particular phase
                              determines how that phase will display on the schedule.
                            </p>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <label class="pira-label" nz-checkbox>Developer Engineering</label>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <label class="pira-label" nz-checkbox>Design Guidelines</label>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <label class="pira-label" nz-checkbox>Level 1 compaction report</label>
                        <i class="bi bi-info-circle-fill text-secondary" nz-popover [nzPopoverContent]="contentTemplate"
                          nzPopoverPlacement="right"></i>
                        <ng-template #contentTemplate>
                          <div class="">
                            <p style="height: auto; width: 180px; ">
                              Selecting a default color for a particular phase
                              determines how that phase will display on the schedule.
                            </p>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <label class="pira-label" nz-checkbox>Siting done</label>
                        <i class="bi bi-info-circle-fill text-secondary" nz-popover [nzPopoverContent]="contentTemplate"
                          nzPopoverPlacement="right"></i>
                        <ng-template #contentTemplate>
                          <div class="">
                            <p style="height: auto; width: 180px; ">
                              Selecting a default color for a particular phase
                              determines how that phase will display on the schedule.
                            </p>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <label class="pira-label" nz-checkbox>Read Engineering plan</label>
                        <i class="bi bi-info-circle-fill text-secondary" nz-popover [nzPopoverContent]="contentTemplate"
                          nzPopoverPlacement="right"></i>
                        <ng-template #contentTemplate>
                          <div class="">
                            <p style="height: auto; width: 180px; ">
                              Selecting a default color for a particular phase
                              determines how that phase will display on the schedule.
                            </p>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <label class="pira-label" nz-checkbox>Deposit of $3000</label>
                        <i class="bi bi-info-circle-fill text-secondary" nz-popover [nzPopoverContent]="contentTemplate"
                          nzPopoverPlacement="right"></i>
                        <ng-template #contentTemplate>
                          <div class="">
                            <p style="height: auto; width: 180px; ">
                              Selecting a default color for a particular phase
                              determines how that phase will display on the schedule.
                            </p>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <label class="pira-label" nz-checkbox>Job conversion</label>
                        <i class="bi bi-info-circle-fill text-secondary" nz-popover [nzPopoverContent]="contentTemplate"
                          nzPopoverPlacement="right"></i>
                        <ng-template #contentTemplate>
                          <div class="">
                            <p style="height: auto; width: 180px; ">
                              Selecting a default color for a particular phase
                              determines how that phase will display on the schedule.
                            </p>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <label class="pira-label" nz-checkbox>email to client</label>
                        <i class="bi bi-info-circle-fill text-secondary" nz-popover [nzPopoverContent]="contentTemplate"
                          nzPopoverPlacement="right"></i>
                        <ng-template #contentTemplate>
                          <div class="">
                            <p style="height: auto; width: 180px; ">
                              Selecting a default color for a particular phase
                              determines how that phase will display on the schedule.
                            </p>
                          </div>
                        </ng-template>
                      </div>
                    </div>


                  </div>

                  <ng-template #tileetwe>
                    <span class="LColaPP mt-1 " style="background-color: #ffffff;">
                      <h4 class="fw-medium famly-inn">Custom Fields (19)</h4>
                    </span>
                  </ng-template>
                  <ng-template #expandedIcon let-active>
                    {{ active }}
                    <span nz-icon nzType="caret-right" class="ant-collapse-arrow"
                      [nzRotate]="p.nzActive ? 90 : -0"></span>
                  </ng-template>
                </nz-collapse-panel>
              </nz-collapse> -->
                <!-- ///////// custom Fields end-->
              <!-- End Of Tab General -->

              <!-- ----- Saim --  Grid Data Bind Start  --- Grid_Data_bind_Proposal_and_activity  -->

              <!-- Start Of Tab Activities -->
              <ng-container *ngIf="tab === 10">
                <ng-container>
                  <!-- Grid Working -->
                  <ng-container
                    *ngIf="
                      leadActivitiesResponse &&
                        leadActivitiesResponse &&
                        leadActivitiesResponse.length > 0;
                      else showFirstCard
                    "
                  >
                    <nz-card
                      class="border-0 custom-card"
                      [nzTitle]="LeadActivityCard"
                      [nzExtra]="leadActivityExtraCard"
                    >
                      <div class="activity-table-container">
                        <nz-table
                          #smallTable
                          nzSize="small"
                          [nzFrontPagination]="false"
                          [nzPaginationType]="'small'"
                          [nzShowPagination]="false"
                          [nzData]="leadActivitiesResponse"
                          (nzCurrentPageDataChange)="
                            onCurrentPageDataChange($event)
                          "
                          nzTableLayout="fixed"
                        >
                          <thead>
                            <tr>
                              <th nzWidth="60px"></th>
                              <!-- target point -->
                              <th
                                nzWidth="60px"
                                nzShowCheckbox
                                [(nzChecked)]="checked"
                                [nzIndeterminate]="indeterminate"
                                (nzCheckedChange)="onAllChecked($event,leadActivitiesResponse)"
                              ></th>
                              <!-- target point -->
                              <th></th>
                              <th>Type</th>
                              <th nzWidth="40%">Communication</th>
                              <th>Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container
                              *ngFor="let data of leadActivitiesResponse"
                            >
                              <tr>
                                <ng-container
                                  *ngIf="data?.subject; else noSubject"
                                >
                                  <td
                                    nzWidth="60px"
                                    [nzExpand]="expandSet.has(data.id)"
                                    (nzExpandChange)="
                                      onExpandChange(data.id, $event)
                                    "
                                  ></td>
                                </ng-container>
                                <ng-template #noSubject>
                                  <td nzWidth="60px"></td>
                                </ng-template>
                                <!-- target Point -->
                                <td
                                  nzWidth="60px"
                                  nzShowCheckbox
                                  [nzChecked]="setOfCheckedId.has(data.id)"
                                  (nzCheckedChange)="
                                    onItemChecked(data.id, $event, data)
                                  "
                                ></td>
                                <!-- target Point -->
                                <td nzWidth="20%">
                                  <div class="row">
                                    <div class="col-md-1 mt-1">
                                      <span
                                        *ngIf="
                                          data?.leadActivityTypeColorCollection
                                        "
                                        class="color-circle"
                                        [style.background-color]="
                                          data?.leadActivityTypeColorCollection
                                        "
                                      ></span>
                                    </div>
                                  </div>
                                  <div class="row iconset">
                                    <div class="col-12">
                                      <i
                                        *ngIf="data?.leadActivityTypIconSource"
                                        style="
                                          display: flex;
                                          align-items: center;
                                          justify-content: center;
                                          font-size: 12px;
                                          padding-top: 5px;
                                          padding-left: 5px;
                                          left: 21px;
                                        "
                                        [class]="
                                          data?.leadActivityTypIconSource
                                        "
                                      ></i>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <a
                                    class="text-primary"
                                    (click)="OnClickActivity(data.id)"
                                    >{{ data?.leadActivityTypeName }}</a
                                  >
                                </td>
                                <td>
                                  <span class="text-primary">
                                    <svg
                                      width="16"
                                      height="16"
                                      class="me-3"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M15.3134 2.94568L11.8118 3.36756C11.6958 3.38162 11.6466 3.52399 11.7292 3.60661L12.7733 4.64899L8.00615 9.41793C6.59118 10.8557 4.76587 12.8679 3.09647 14.4406C3.07029 14.4671 3.05561 14.5028 3.05561 14.5399C3.05561 14.5771 3.07029 14.6128 3.09647 14.6393L3.891 15.4303C3.91743 15.4564 3.95312 15.4711 3.99031 15.4711C4.02751 15.4711 4.0632 15.4564 4.08963 15.4303L9.74803 9.7754C10.8851 8.65635 12.3921 6.91992 13.7665 5.64215L14.8089 6.68454C14.8276 6.70312 14.8511 6.7161 14.8768 6.72198C14.9025 6.72786 14.9293 6.72642 14.9542 6.71781C14.9791 6.7092 15.0011 6.69378 15.0177 6.67329C15.0342 6.6528 15.0447 6.62807 15.048 6.60192L15.4698 3.10037C15.4821 3.01072 15.4048 2.93338 15.3134 2.94568Z"
                                        fill="#26292E"
                                      ></path>
                                    </svg>
                                    <span
                                      style="
                                        font-weight: medium;
                                        color: #202227;
                                      "
                                      >{{ data?.assignedUser?.fullName }}</span
                                    >
                                    <span
                                      style="
                                        color: #202227;
                                        margin-right: 3px;
                                        margin-left: 3px;
                                      "
                                      >to</span
                                    >
                                    <span
                                      style="
                                        font-weight: medium;
                                        color: #004fd6;
                                        text-transform: capitalize;
                                      "
                                    >
                                      <a
                                        *ngIf="
                                          leadOpportunityResponse
                                            .customerInformation?.displayName
                                        "
                                        class="text-primary"
                                        (click)="
                                          IsCustomer(
                                            leadOpportunityResponse
                                              .customerInformation?.id,
                                            false
                                          )
                                        "
                                      >
                                        {{
                                          leadOpportunityResponse
                                            .customerInformation.displayName
                                        }}
                                      </a>
                                      <span
                                        *ngIf="
                                          !leadOpportunityResponse
                                            .customerInformation?.displayName &&
                                          leadOpportunityResponse?.title
                                        "
                                      >
                                        {{ leadOpportunityResponse.title }}
                                      </span>
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  <a class="text-primary">
                                    <ng-container
                                      *ngIf="
                                        isToday(data.activityOn);
                                        else otherDate
                                      "
                                    >
                                      Today -
                                      {{ data.activityOn | date : "h:mm a" }}
                                    </ng-container>
                                    <ng-template #otherDate>
                                      {{
                                        data.activityOn | date : "MMM d, yyyy"
                                      }}
                                    </ng-template>
                                  </a>
                                </td>
                                <td class="">
                                  <nz-tag
                                    [nzColor]="'#c4fae2'"
                                    style="
                                      font-weight: 700;
                                      line-height: 1;
                                      border-radius: 12px;
                                      line-height: 16px;
                                      color: #005c35;
                                      padding: 0.2em 0.6em;
                                    "
                                  >
                                    Completed
                                  </nz-tag>
                                </td>
                              </tr>
                              <tr
                                *ngIf="expandSet.has(data.id) && data?.subject"
                              >
                                <td [attr.colspan]="7">{{ data.subject }}</td>
                              </tr>
                            </ng-container>
                          </tbody>
                        </nz-table>
                      </div>
                    </nz-card>
                  </ng-container>
                  <!-- Grid Working -->
                </ng-container>

                <!-- grid  -->
                <ng-template #LeadActivityCard>
                  <h3 nz-typography>Lead Activities</h3>
                </ng-template>

                <ng-template #showFirstCard>
                  <nz-card
                    class="border-0"
                    [nzTitle]="LeadActivityCard"
                    [nzExtra]="leadActivityExtraCard"
                  >
                    <div class="row mb-0 mt-1">
                      <div class="col-lg-8 offset-lg-2">
                        <div class="col text-center mb-2">
                          <span
                            nz-icon
                            nzType="phone"
                            nzTheme="outline"
                            [nzRotate]="90"
                            class="large-icon"
                          ></span>
                        </div>
                        <span class="text-center">
                          <h1 class="fw-medium famly-inn mb-4">
                            Engage your leads with activities
                          </h1>
                        </span>
                        <p class="text-center mb-4" style="font-size: 16px">
                          Track calls, emails, and meeting notes for leads in
                          one place.
                        </p>
                        <div class="col mt-2 text-center">
                          <button
                            nz-button
                            nzType="default"
                            class="me-2 align-items-center"
                          >
                            <i class="bi bi-box-arrow-up-right fs-6 me-2"></i
                            >Learn How
                          </button>
                          <button
                            nz-button
                            class=" "
                            nzType="primary"
                            (click)="
                              openConfirmDialogActivity(true, false, 'ACTIVITY')
                            "
                          >
                            Add a Lead Activity
                          </button>
                        </div>
                      </div>
                    </div>
                  </nz-card>
                </ng-template>
                <!-- grid Sufyan Work -->
                <ng-template #leadActivityExtraCard>
                  <nz-space *ngIf="!isCheckboxSelected">
                    <button
                      [nzTrigger]="'click'"
                      class="ms-3"
                      nz-button
                      nz-dropdown
                      [nzDropdownMenu]="JobMenu"
                    >
                      Template Actions
                      <span nz-icon nzType="down"></span>
                    </button>

                    <nz-dropdown-menu #JobMenu="nzDropdownMenu">
                      <ul nz-menu>
                        <li nz-menu-item (click)="ImportLeadTemplate()">
                          Import Activity
                        </li>
                        <li
                          nz-menu-item
                          (click)="handleCopyToLeadTemplate()"
                          [nzDisabled]="
                            !leadActivitiesResponse ||
                            leadActivitiesResponse?.length === 0
                          "
                        >
                          Copy To Activity Template
                        </li>
                      </ul>
                    </nz-dropdown-menu>

                    <button
                      nz-button
                      nzType="default"
                      class="ms-2"
                      (click)="
                        openConfirmDialogActivity(true, false, 'ACTIVITY')
                      "
                    >
                      Log Completed Activity
                    </button>
                    <button
                      nz-button
                      nzType="default"
                      class="ms-2 d-flex align-items-center justify-content-center"
                    >
                      <i class="bi bi-funnel fs-5"></i>Filter
                    </button>
                    <button
                      nz-button
                      nzType="primary"
                      class="ms-2"
                      (click)="
                        openConfirmDialogActivity(false, false, 'ACTIVITY')
                      "
                    >
                      Schedule New Activity
                    </button>
                  </nz-space>
                  <nz-space *ngIf="isCheckboxSelected">
                    <nz-space>
                      <span class="me-4 mt-1 fs-6 fw-bold">
                        <p>{{ setOfCheckedId.size }} selected</p>
                      </span>
                      <button
                        *nzSpaceItem
                        nz-button
                        nzType="default"
                        class="pt-1 fs-6 text-primary border-0"
                        (click)="cancelSelection()"
                      >
                        Cancel
                      </button>
                      <button (click)="leadActivityDelete()" *nzSpaceItem  nz-button nzType="default">
                        Delete
                      </button>
                      <button (click)="leadActivityAssignUser()" *nzSpaceItem  nz-button nzType="default">
                        Reassign
                      </button>
                      <button (click)="assignDisplayColor()" *nzSpaceItem  nz-button nzType="default">
                        Assign Display Color
                      </button>
                    </nz-space>
                  </nz-space>
                </ng-template>
              </ng-container>

              <!-- modal -->
              <!-- ///////////////Sami  working Branch: Name: Sami/Features-Activity-Work ///////////// -->

              <nz-modal
                nzWidth="27%"
                [(nzVisible)]="conformCardShow"
                [nzTitle]="null"
                [nzContent]="conformContentFirstModal"
                [nzFooter]="null"
                [nzClosable]="false"
                (nzOnCancel)="conformhandleCancel()"
                [nzBodyStyle]="{
                  background: '#ffffff',
                  padding: '35px',
                  'max-height': '430px',
                  'overflow-y': 'auto'
                }"
                [nzStyle]="{ top: '280px' }"
              >
                <ng-template #conformContentFirstModal>
                  <h3 class="mb-2 famly-inn" style="margin-top: -20px">
                    Create New Activity?
                  </h3>
                  <div class="row mb-3">
                    <div class="col-12">
                      <p class="fw-medium famly-inn">
                        You must save the Lead before creating an Activity.
                        Would you like to save the Lead now and create the
                        Activity?
                      </p>
                    </div>
                  </div>
                  <div class="row" style="margin-bottom: -10px">
                    <div class="col-12 text-end">
                      <nz-space>
                        <button
                          *nzSpaceItem
                          nz-button
                          class=""
                          nzType="default"
                          (click)="conformhandleCancel()"
                        >
                          Close
                        </button>
                        <button
                          *nzSpaceItem
                          nz-button
                          class="me-2"
                          nzType="primary"
                          (click)="NewLeadActivity(false)"
                        >
                          Save & Create
                        </button>
                      </nz-space>
                    </div>
                  </div>
                </ng-template>
              </nz-modal>

              <nz-modal
                nzWidth="27%"
                [(nzVisible)]="proposalConformCardShow"
                [nzTitle]="null"
                [nzContent]="conformContentSecondModal"
                [nzFooter]="null"
                [nzClosable]="false"
                (nzOnCancel)="conformhandleCancel()"
                [nzBodyStyle]="{
                  background: '#ffffff',
                  padding: '35px',
                  'max-height': '430px',
                  'overflow-y': 'auto'
                }"
                [nzStyle]="{ top: '280px' }"
              >
                <ng-template #conformContentSecondModal>
                  <h3
                    nz-typography
                    class="mb-2 famly-inn"
                    style="margin-top: -20px"
                  >
                    Create New Proposal?
                  </h3>
                  <div class="row mb-3">
                    <div class="col-12">
                      <p class="fw-medium famly-inn">
                        You must save the Lead before creating a Proposal. Would
                        you like to save the Lead now and create the Proposal?
                      </p>
                    </div>
                  </div>
                  <div class="row" style="margin-bottom: -10px">
                    <div class="col-12 text-end">
                      <nz-space>
                        <button
                          *nzSpaceItem
                          nz-button
                          class=""
                          nzType="default"
                          (click)="conformhandleCancel()"
                        >
                          Close
                        </button>
                        <button
                          *nzSpaceItem
                          nz-button
                          class="me-2"
                          nzType="primary"
                          (click)="NewProposalCard(false)"
                        >
                          Save & Create
                        </button>
                      </nz-space>
                    </div>
                  </div>
                </ng-template>
              </nz-modal>

              <!-- modal -->
              <!-- End Of Tab Activities -->
              <!-- ----- Saim --  Grid Data Bind End  --- Grid_Data_bind_Proposal_and_activity --- -->
              <!-- Start Of Tab Proposals -->
              <!------- Raza --  Start Work  --- Grid_Data_bind_Proposal_and_activity ----->

              <ng-container *ngIf="tab === 11">
                <ng-container
                  *ngIf="
                    leadProposalsResponse && leadProposalsResponse.length > 0;
                    else showFirstCards
                  "
                >
                  <nz-card
                    class="border-0 p-2"
                    [nzTitle]="LeadActivityCard"
                    [nzExtra]="leadActivityExtraCard"
                  >
                    <nz-table
                      class="custom-nz-table"
                      #smallTable
                      nzSize="small"
                      [nzFrontPagination]="false"
                      [nzPaginationType]="'small'"
                      [nzShowPagination]="true"
                      #nzTable
                      [nzData]="leadProposalsResponse"
                      nzTableLayout="fixed"
                    >
                      <thead>
                        <tr>
                          <th nzWidth="20%">Title</th>
                          <th>Status</th>
                          <th>Updated</th>
                          <th>Email Address</th>
                          <th>Owner Price</th>
                          <th>Files</th>
                          <th nzWidth="20%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let data of nzTable.data">
                          <tr>
                            <td>
                              <a
                                class="text-primary"
                                (click)="OnClickProposal(data.id)"
                              >
                                {{ data?.title }}
                              </a>
                            </td>
                            <td class="ant-table-cell">
                              <ng-container *ngIf="data?.statusSetup">
                                <nz-tag
                                  [ngClass]="
                                    getStatusClass(
                                      data?.statusSetup?.description
                                    )
                                  "
                                  style="
                                    font-weight: 700;
                                    line-height: 1;
                                    border-radius: 12px;
                                    line-height: 16px;
                                    padding: 0.2em 0.6em;
                                  "
                                >
                                  {{ data?.statusSetup?.description }}
                                </nz-tag>
                              </ng-container>
                            </td>
                            <td>{{ data.createdOn | date : "MMM d, yyyy" }}</td>
                            <td>
                              {{
                                leadOpportunityResponse.customerInformation
                                  ?.primaryEmail
                              }}
                            </td>
                            <td>
                              <ng-container *ngIf="data.totalOwnerAmount">
                                <span>{{
                                  data?.totalOwnerAmount
                                    | currency : "USD" : "symbol"
                                }}</span>
                              </ng-container>
                              <ng-container
                                *ngFor="
                                  let parameter of data.costItem
                                    ?.costItemParameters
                                "
                              >
                                <span>{{
                                  parameter?.ownerPrice
                                    | currency : "USD" : "symbol"
                                }}</span>
                              </ng-container>
                            </td>

                            <td>
                              <!-- <ng-container
                            *ngFor="let attachmentParameter of leadOpportunityResponse.attachment?.attachmentParameters"
                          >
                            {{ attachmentParameter.name }}
                          </ng-container> -->
                              <ng-container
                                *ngIf="
                                  data?.attachmentCount &&
                                  data?.attachmentCount > 0
                                "
                              >
                                <a
                                  class="d-flex align-items-center"
                                  nz-button
                                  nzType="text"
                                  nz-dropdown
                                  [nzDropdownMenu]="menu"
                                  nzTrigger="click"
                                >
                                  <span
                                    nz-icon
                                    nzType="paper-clip"
                                    nzTheme="outline"
                                  ></span>
                                  {{ data?.attachmentCount }}
                                  <span nz-icon nzType="down"></span>
                                </a>
                                <nz-dropdown-menu #menu="nzDropdownMenu">
                                  <ul nz-menu>
                                    <li nz-menu-item>1st menu item</li>
                                    <li nz-menu-item>2nd menu item</li>
                                    <li nz-submenu nzTitle="sub menu">
                                      <ul>
                                        <li nz-menu-item>3rd menu item</li>
                                        <li nz-menu-item>4th menu item</li>
                                      </ul>
                                    </li>
                                  </ul>
                                </nz-dropdown-menu>
                              </ng-container>
                            </td>
                            <td>
                              <button
                                nz-button
                                nzType="default"
                                class=""
                                (click)="IsCopyProposalsTemplate(data.id)"
                              >
                                Copy to Proposal Template
                              </button>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </nz-table>
                  </nz-card>
                </ng-container>

                <ng-template #showFirstCards>
                  <nz-card
                    class="border-0"
                    [nzTitle]="LeadActivityCard"
                    [nzExtra]="leadActivityExtraCard"
                  >
                    <div class="row mb-0 mt-1">
                      <div class="col-lg-8 offset-lg-2">
                        <div class="col text-center mb-3">
                          <span
                            nz-icon
                            nzType="file-text"
                            nzTheme="outline"
                            class="large-icon"
                          ></span>
                        </div>
                        <span class="text-center">
                          <h1 class="fw-medium famly-inn mb-4">
                            Create a Lead Proposal
                          </h1>
                        </span>
                        <p class="text-center mb-4" style="font-size: 16px">
                          Create, send, track, and sign lead proposals,
                          contracts, and agreements in one place.
                        </p>
                        <div
                          class="col mt-2 text-center d-flex align-items-center justify-content-center text-center"
                        >
                          <button
                            nz-button
                            nzType="default"
                            class="me-2 align-items-center"
                          >
                            <i class="bi bi-box-arrow-up-right fs-6 me-2"></i
                            >Learn How
                          </button>
                          <button
                            nz-button
                            nzType="primary"
                            class=" "
                            (click)="
                              openConfirmDialogActivity(false, true, 'PROPOSAL')
                            "
                          >
                            Create a Lead Proposal
                          </button>
                        </div>
                      </div>
                    </div>
                  </nz-card>
                </ng-template>

                <ng-template #leadActivityExtraCard>
                  <button
                    nz-button
                    nzType="default"
                    class="ms-2"
                    (click)="ImportProposals()"
                  >
                    Import
                  </button>
                  <button
                    nz-button
                    nzType="primary"
                    class="ms-2"
                    (click)="openConfirmDialogActivity(false, true, 'PROPOSAL')"
                  >
                    New Proposal
                  </button>
                </ng-template>

                <ng-template #LeadActivityCard>
                  <h3 nz-typography>Lead Proposals</h3>
                </ng-template>
              </ng-container>

              <!-- End Of Tab Proposals -->
              <!------- Raza --  Start Work  --- Grid_Data_bind_Proposal_and_activity ----->
            </nz-tab>
          </nz-tabset>
        </div>
      </form>
    </div>
  </form>
</nz-modal>
<ng-template #LeadOpportunitySettingmodalFooter>
  <div nz-row class="p-1 justify-content-end">
    <div
      nz-col
      nzSpan="12"
      class="footerContent"
      *ngIf="leadOpportunityResponse"
    >
    <span *ngIf="footerResponse">
      <app-footer-label [footerData]="footerResponse"></app-footer-label>
    </span>
    
      
    </div>

    <div nz-col nzSpan="12">
      <nz-space>
        <ng-container *nzSpaceItem>
          <button
            *ngIf="leadOpportunityResponse"
            [nzTrigger]="'click'"
            nz-button
            nz-dropdown
            nzType="default"
            [nzDropdownMenu]="otherOptionsMenu"
            [disabled]="DisabledNextActivity"
            [class.disabled]="DisabledNextActivity"
          >
            Other Options
            <span nz-icon nzType="down"></span>
          </button>
        </ng-container>

        <ng-container *nzSpaceItem>
          <button
            *ngIf="leadOpportunityResponse"
            [nzTrigger]="'click'"
            nz-button
            nzType="default"
            nz-dropdown
            [nzDropdownMenu]="JobMenu"
            [disabled]="DisabledNextActivity"
            [class.disabled]="DisabledNextActivity"
          >
            Convert To Job
            <span nz-icon nzType="down"></span>
          </button>
        </ng-container>

        <ng-container *nzSpaceItem>
          <button
            nz-button
            nzType="primary"
            class="align-items-center"
            [nzLoading]="isSaveAndNew"
            (click)="saveAndNewLead()"
            [disabled]="DisabledNextActivity"
            [class.disabled]="DisabledNextActivity"
          >
            Save & New
          </button>
        </ng-container>

        <ng-container *nzSpaceItem>
          <button
            nz-button
            nzType="primary"
            class="align-items-center"
            [nzLoading]="isSave"
            (click)="saveButton()"
            [disabled]="DisabledNextActivity"
            [class.disabled]="DisabledNextActivity"
          >
            Save
          </button>
        </ng-container>

        <ng-container *nzSpaceItem>
          <button
            nz-button
            nzType="primary"
            class="align-items-center"
            [nzLoading]="isSaveAndClose"
            (click)="saveAndCloseLead()"
            [disabled]="DisabledNextActivity"
            [class.disabled]="DisabledNextActivity"
          >
            Save & Close
          </button>
        </ng-container>
      </nz-space>
    </div>
  </div>

  <nz-dropdown-menu #JobMenu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item (click)="leadToJob('SCRATCH')">New Job From Scratch</li>
      <li nz-menu-item (click)="leadToJob('TEMPLATE')">
        New Job From Template
      </li>
      <li nz-menu-item>Choose Existing Job</li>
    </ul>
  </nz-dropdown-menu>

  <nz-dropdown-menu #otherOptionsMenu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item (click)="deleteLeadConfirm()">Delete</li>
      <li nz-menu-item>Print</li>
    </ul>
  </nz-dropdown-menu>
</ng-template>

<nz-modal
  [(nzVisible)]="isVisibleAddModal"
  nzTitle="Upload Files"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '13px',
    'max-height': '430px',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="handleCancelAddModal()"
  (nzOnOk)="handleOkAddModal()"
>
  <ng-container *nzModalContent class="upload-modal-content">
    <nz-card nzTitle="Contact Information">
      <div class="d-flex align-items-center justify-content-center h-100">
        <div>
          <i class="bi bi-cloud-arrow-up-fill" style="font-size: 3rem"></i>
          <div class="row">
            <div class="col">
              <div class="styling demo">
                <b>Drop files here to upload, or browse for files.</b>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="mt-2">
                <button
                  nz-button
                  nzType="primary"
                  class="m-2"
                  style="font-size: 12px"
                >
                  Browser Buildertrend
                </button>
                <nz-upload
                  nzAction="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  nzDirectory
                >
                  <button nz-button>
                    <span nz-icon nzType="upload"></span>
                    Upload Directory
                  </button>
                </nz-upload>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-card>
  </ng-container>
</nz-modal>

<!-- Tag Model  -->

<nz-modal
  nzWidth="35%"
  [(nzVisible)]="isTagVisible"
  [nzTitle]="divsionTradeTitle"
  [nzFooter]="divsionTradeFooter"
  [nzBodyStyle]="{ background: '#f1f4fa', padding: '14px' }"
  [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="showConfirmData()"
>
  <ng-template #divsionTradeTitle>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0">{{ ModalTitle?.title }}</h1>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <form nz-form [formGroup]="TagsForm">
      <nz-card [nzBorderless]="false" nzTitle="{{ ModalTitle?.title }}">
        <div class="row">
          <div class="col">
            <label class="sub-text">
              Title<span class="text-danger fw-bolder">*</span>
            </label>
            <nz-form-item>
              <nz-form-control
                [nzValidateStatus]="TagsForm.get('name')"
                [nzErrorTip]="nameErrorTemplate"
              >
                <input
                  type="text"
                  nz-input
                  class="form-control "
                  formControlName="name"
                />
              </nz-form-control>
            </nz-form-item>
            <ng-template #nameErrorTemplate let-control>
              <ng-container
                *ngIf="control.hasError('required')"
                class="text-danger"
                >Required</ng-container
              >
              <ng-container
                *ngIf="control.hasError('maxlength')"
                class="text-danger"
              >
                {{ exceededCharacters }}
                {{ exceededCharacters === 1 ? "character" : "characters" }}
              </ng-container>
            </ng-template>
          </div>
        </div>
      </nz-card>
    </form>
  </div>

  <ng-template #divsionTradeFooter>
    <div class="mt-2 align-items-center">
      <button
        *ngIf="isLoadingEdit"
        nz-button
        nzType="default"
        class="me-0"
        (click)="ConfirmDeleteTags(ModalTitle?.deleteTitle)"
        [nzLoading]="isDeleteTagsLoading"
      >
        Delete
      </button>

      <button
        nz-button
        nzType="primary"
        class="me-0"
        (click)="SaveOrUpdateData()"
        [nzLoading]="isLoadingOne"
      >
        {{ isLoadingEdit ? "Update" : "Save" }}
      </button>
    </div>
  </ng-template>
</nz-modal>
