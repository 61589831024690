import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { JobListPreDefinedCodes, JobSummaryPreDefinedCodes } from '../../Models/LookUpStandardFilterCodes';
import { CreateLookupFilterRequest, LookupFilter, LookupFilterResponse, LookupNameSetupResponse, LookUpStandardResponse } from '../../Models/LookupModels';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { RoleTypeService } from 'src/Service/Internaluser/role-type.service';
import { FieldDataType } from 'src/app/Core/Enums/data_types';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { AddNewStandardFilterComponent } from '../../Modal/add-new-standard-filter/add-new-standard-filter.component';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';


@Component({
  selector: 'app-job-summary-filter-field',
  templateUrl: './job-summary-filter-field.component.html',
  styleUrls: ['./job-summary-filter-field.component.css']
})
export class JobSummaryFilterFieldComponent {

  @Input() pageId : number;
  jobsSummaryForm: FormGroup;
  createLookupFilterRequests: CreateLookupFilterRequest[] = [];
  filterResponseData: LookupFilterResponse[] = [];
  lookUpStandardResponse: LookUpStandardResponse[] = [];
  defaultStandardFilter : LookupFilterResponse;
  predefinedValuesMultiSelect: string[] = [];
  ItemTypeNodes : NzTreeNodeOptions[]=[];
  roles: { id: string, name: string }[] = [];
  preDefinedDataCodes = {
    standardFilter:JobSummaryPreDefinedCodes.standardFilter,
    ItemType:JobSummaryPreDefinedCodes.ItemType
  
}
nodes: NzTreeNodeOptions[] = [
  {
    title: 'Check All',
    value: '0-all',
    key: '0-all',
    isLeaf: true,
    checked: false
  },
  {
    title: 'Node1',
    value: '0-0',
    key: '0-0',
    isLeaf: true
  },
  {
    title: 'Node2',
    value: '0-1',
    key: '0-1',
    isLeaf: true
  },
  {
    title: 'Node3',
    value: '0-2',
    key: '0-2',
    isLeaf: true
  },
  {
    title: 'Node4',
    value: '0-3',
    key: '0-3',
    isLeaf: true
  },
  {
    title: 'Node5',
    value: '0-4',
    key: '0-4',
    isLeaf: true
  },
  {
    title: 'Node6',
    value: '0-5',
    key: '0-5',
    isLeaf: true
  },];


constructor(
  private roleService: RoleTypeService,
  private fb: FormBuilder,
  public toastService: NzMessageService,
  private cdr: ChangeDetectorRef,
  private loadingIndicatoreService: LoadingIndicatorService,
  private modal: NzModalService,
  private LookupFilterService: LookupFilterService,
  private internalUserService: InternalUserService,
  private customerInformationService: CustomerInformationService,



) { }
ngOnInit(): void {
  this.initLoad();
  this.ForCombo(); 
  // this. showContactTypes();

  
}



// showContactTypes() {
//   const formNameId = 34;
//   this.internalUserService.showCode(formNameId).subscribe(
//     (res: ResponseModelArray<LookupNameSetupResponse>) => {
//       // Filter out only the contact type data
//       const ItemType = res.result.filter(item => item.code === JobSummaryPreDefinedCodes.ItemType.code);

//       this.ItemTypeNodes = ItemType.map(ItemType => ({
//         title: ItemType.description, 
//         key: ItemType.code,
//         isLeaf: true 
//       }));
      
//     },
//     (error) => {
//       console.error('Error fetching item types:', error);
//     }
//   );
// }
initLoad() {
  this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(res =>{
    let ItemTypeData = res.result.filter(x => x.code === this.preDefinedDataCodes.ItemType.code);
   
    console.log("all data",res.result);
    
    this.ItemTypeNodes = ItemTypeData.map(ItemType => ({
      title: ItemType.name,
      value: ItemType.id.toString(),
      key: ItemType.id.toString(),
      isLeaf: true
    }));


});

  const lookupFilter: LookupFilter = {
    formNameId: this.pageId,
  };
  this.getStandardFilterByFormPageId(this.pageId);
  this.fetchData(lookupFilter);
}
fetchData(lookUpStandard: LookupFilter): void {
  this.LookupFilterService.postData(lookUpStandard).subscribe(
    data => {
      this.lookUpStandardResponse = data.result;
    },
    error => {
      console.error('Error fetching data:', error);
    }
  );
}

openStandardFilterComponent() {
  this.createFormFieldsInArray();
  const modalRef = this.modal.create({
    nzContent: AddNewStandardFilterComponent,
    nzData: 
    { 
      pageId: this.pageId,
      std : this.filterResponseData ,
      field: this.createLookupFilterRequests
    },
  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
}
getStandardFilterByFormPageId(pageId: number) {
  this.LookupFilterService.getStandardFilterByFormPageId(pageId);
  this.LookupFilterService.$getStandardFilter.subscribe(result =>{
    if(result){
      this.filterResponseData = result.result;
      console.log("RESULT STANDARD FILTER : ", this.filterResponseData);
      this.initializeForm();
      this.setDefaultFieldValues(this.defaultStandardFilter.id);
    }
  });
}
formFieldValues: any = {};

initializeForm(): void {
  this.formFieldValues = {
    standardFilter: this.getDefaultFilterValue(),
    performingUser: [] = [],
    itemType: [] = [],
  };
}
onSubmit(): void {
  this.createFormFieldsInArray();
}
resetFilter(): void {
  const formControlKeys = Object.keys(this.jobsSummaryForm.controls).slice(1);
  formControlKeys.forEach(field => {
    this.jobsSummaryForm.get(field).reset();
  });
}
createFormFieldsInArray() {
  this.createLookupFilterRequests = [];
  Object.keys(this.preDefinedDataCodes).forEach(field => {
    const code = this.preDefinedDataCodes[field].code;
    const value = this.jobsSummaryForm.get(field).value;
    if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 1 && value[0] === null)) {
      const newRow = {
        companyParameterId: 0,
        lookUpStandardId: 0,
        code: code,
        meaning: '',
        valueId: 0,
        formNameId: this.pageId,
        dataTypeId: 0,
        createLookupFilterParameterRequests: [null]
      };
      const correspondingResponse = this.lookUpStandardResponse.find(response => response.code === code);
      if (correspondingResponse) {
        newRow.companyParameterId = correspondingResponse.companyParameterId;
        newRow.lookUpStandardId = correspondingResponse.id;
        newRow.dataTypeId = correspondingResponse.dataTypeId;
        if (
          correspondingResponse.dataType.code === FieldDataType.SingleLineText ||
          correspondingResponse.dataType.name === 'Single-Line Text') {
          newRow.meaning = value;
        }
        if (
          correspondingResponse.dataType.code === FieldDataType.Dropdown ||
          correspondingResponse.dataType.name === 'Dropdown') {
          newRow.valueId = value;
        }
        if (
          correspondingResponse.dataType.code === FieldDataType.MultiSelectDropdown ||
          correspondingResponse.dataType.name === 'Multi-Select Dropdown'
        ) {
          newRow.createLookupFilterParameterRequests = value.map((item, index) => {
            return { valueId: item };
          });
        }
        // Assign other properties similarly based on your data structure
      }
      this.createLookupFilterRequests.push(newRow);
    }
  });
}
getDefaultFilterValue() {
  this.defaultStandardFilter = this.filterResponseData.find(filter => filter.isSetAsDefault === true);
  return this.defaultStandardFilter ? this.defaultStandardFilter.id : null;
}
setDefaultFieldValues(standardFilterId: number): void {
  this.predefinedValuesMultiSelect = [];
  let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
  let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;
  // Get all form control keys except the first one
  const formControlKeys = Object.keys(this.jobsSummaryForm.controls).slice(1);
  // Reset all form controls except the first one
  formControlKeys.forEach(field => {
    this.jobsSummaryForm.get(field).reset(); // Reset the form control
  });
  Object.keys(this.preDefinedDataCodes).forEach(field => {
    const code = this.preDefinedDataCodes[field].code;
    const fieldData = lookUpFiltersField.find(filter => filter.code === code);
    if (fieldData != undefined) {
      if (
        fieldData.dataType.code === FieldDataType.SingleLineText) {
        this.jobsSummaryForm.get(field).setValue(fieldData.meaning);
      }
      if (fieldData.dataType.code === FieldDataType.Dropdown) {
        this.jobsSummaryForm.get(field).setValue(fieldData.id);
      }
      if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown) {
        let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
        this.predefinedValuesMultiSelect = multiSelectValues.map(String);
        this.jobsSummaryForm.get(field).setValue(this.predefinedValuesMultiSelect);
      }
    }
  });
}
onChangeStandardFilter($event: number): void {
  this.setDefaultFieldValues($event);
}
performinguser: NzTreeNodeOptions[]=[];

ForCombo() {
  this.customerInformationService.getDataforcombo().subscribe(res => {
    const performinguser = res.result; 

    // console.log("dataforcombo",projectmanager);
    // console.log("dataforcombo",owners);

    
    this.performinguser = performinguser.map(JJM => ({
      title: JJM.fullName,
      key: JJM.id.toString(),
      isLeaf: true,
    }));

 
   });

}


selectedFilter: number = -1;

 onFilterApply(): void {
    let selectedFilter = this.formFieldValues['standardFilter'];
    let isSelectedFilterStandard = this.filterResponseData.find(x => x.id === selectedFilter && x.isNotDeleted === true);
    if(isSelectedFilterStandard && isSelectedFilterStandard != undefined ){
      this.selectedFilter = 1;
    }
     else{
      this.selectedFilter = 0;
    }
    this.createFormFieldsInArray();

  }
}


