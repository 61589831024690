import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RoleTypeService } from 'src/Service/Internaluser/role-type.service';
import { FieldDataType } from 'src/app/Core/Enums/data_types';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { AddNewStandardFilterComponent } from '../../Modal/add-new-standard-filter/add-new-standard-filter.component';
import { CreateLookupFilterRequest, LookupFilterResponse, LookUpStandardResponse, LookupFilter, LookupNameSetupResponse } from '../../Models/LookupModels';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { ProjectManegmentScheduleBaseLinePreDefinedCodes, ProjectManegmentSchedulePreDefinedCodes } from '../../Models/LookUpStandardFilterCodes';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';

@Component({
  selector: 'app-project-mangament-schedule-baseline-filter-field',
  templateUrl: './project-mangament-schedule-baseline-filter-field.component.html',
  styleUrls: ['./project-mangament-schedule-baseline-filter-field.component.css']
})
export class ProjectMangamentScheduleBaselineFilterFieldComponent implements OnInit {
  
Filter:boolean=false


  @Input() pageId : number;
  projectmangamentschedulebaselineFilterForm: FormGroup;
  createLookupFilterRequests: CreateLookupFilterRequest[] = [];
  filterResponseData: LookupFilterResponse[] = [];
  lookUpStandardResponse: LookUpStandardResponse[] = [];
  defaultStandardFilter : LookupFilterResponse;
  predefinedValuesMultiSelect: string[] = [];
  keywordOptions: { label: string, value: number }[] = [];
  keywordOption2: { label: string, value: number }[] = [];
  PhasesNodes : NzTreeNodeOptions[]=[];
  formFieldValues: any = {};
  LookupNameSetupResponse: LookupNameSetupResponse[] = [];
  selectedFilter: number = -1;
  preDefinedDataCodes = {
    standardFilter: ProjectManegmentScheduleBaseLinePreDefinedCodes .standardFilter,
    Title: ProjectManegmentScheduleBaseLinePreDefinedCodes .Title,
    Status: ProjectManegmentScheduleBaseLinePreDefinedCodes .Status,
    Phases: ProjectManegmentScheduleBaseLinePreDefinedCodes .Phases,
    Date: ProjectManegmentScheduleBaseLinePreDefinedCodes .Date,
  };

  constructor(
    private roleService: RoleTypeService,
    private fb: FormBuilder,
    public toastService: NzMessageService,
    private cdr: ChangeDetectorRef,
    private loadingIndicatoreService: LoadingIndicatorService,
    private modal: NzModalService,
    private LookupFilterService: LookupFilterService,
    private internalUserService: InternalUserService,
    private customerInformationService: CustomerInformationService,
    )
    {}
    ngOnInit(): void {
      this.initLoad();
      this.ForCombo();
    
     }
     initLoad() {
      this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(res =>{
        let Phases = res.result.filter(x => x.code === this.preDefinedDataCodes.Phases.code);
        let StatusData = res.result.filter(x => x.code === this.preDefinedDataCodes.Status.code);
        let DateData = res.result.filter(x => x.code === this.preDefinedDataCodes.Date.code);
        console.log("all data",res.result);
        
        this.PhasesNodes = Phases.map(Phases => ({
          title: Phases.name,
          value: Phases.id.toString(),
          key: Phases.id.toString(),
          isLeaf: true
        }));

        this.keywordOptions = StatusData.map(res => ({
          label: res.description,
          value: res.id.toString(),
        }));

        this.keywordOption2 = DateData.map(res1 => ({
          label: res1.description,
          value: res1.id.toString(),
        }));
    });
    
      const lookupFilter: LookupFilter = {
        formNameId: this.pageId,
      };
      this.getStandardFilterByFormPageId(this.pageId);
      this.fetchData(lookupFilter);
    }
    onFilterApply(): void {
      let selectedFilter = this.formFieldValues['standardFilter'];
      let isSelectedFilterStandard = this.filterResponseData.find(x => x.id === selectedFilter && x.isNotDeleted === true);
      if(isSelectedFilterStandard && isSelectedFilterStandard != undefined ){
        this.selectedFilter = 1;
      }
       else{
        this.selectedFilter = 0;
      }
      this.createFormFieldsInArray();
  
    }
    fetchData(lookUpStandard: LookupFilter): void {
      this.LookupFilterService.postData(lookUpStandard).subscribe(
        data => {
          this.lookUpStandardResponse = data.result;
        },
        error => {
          console.error('Error fetching data:', error);
        }
      );
    }
    openStandardFilterComponent() {
      this.createFormFieldsInArray();
      const modalRef = this.modal.create({
        nzContent: AddNewStandardFilterComponent,
        nzData: 
        { 
          pageId: this.pageId,
          std : this.filterResponseData ,
          field: this.createLookupFilterRequests
        },
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
    }
    getStandardFilterByFormPageId(pageId:number){
      this.LookupFilterService.getStandardFilterByFormPageId(pageId);
      this.LookupFilterService.$getStandardFilter.subscribe(result =>{
        if(result){
          this.filterResponseData = result.result;
          console.log("RESULT STANDARD FILTER : ", this.filterResponseData);
          this.initializeForm();
          this.setDefaultFieldValues(this.defaultStandardFilter.id);
        }
      });
    }
  resetFilter(): void {
     const formControlKeys = Object.keys(this.projectmangamentschedulebaselineFilterForm.controls).slice(1);
     formControlKeys.forEach(field => {
       this.projectmangamentschedulebaselineFilterForm.get(field).reset();
     });
  }
  createFormFieldsInArray(){
    this.createLookupFilterRequests = [];
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const value = this.projectmangamentschedulebaselineFilterForm.get(field).value;
      if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 1 && value[0] === null)) {
        const newRow = {
          companyParameterId: 0,
          lookUpStandardId: 0,
          code: code,
          meaning: '',
          valueId: 0,
          formNameId: this.pageId,
          dataTypeId: 0,
          createLookupFilterParameterRequests: [null]
        };
           const correspondingResponse = this.lookUpStandardResponse.find(response => response.code === code);
           if (correspondingResponse) {
               newRow.companyParameterId = correspondingResponse.companyParameterId;
               newRow.lookUpStandardId = correspondingResponse.id;
               newRow.dataTypeId = correspondingResponse.dataTypeId;
               if(
                correspondingResponse.dataType.code === FieldDataType.SingleLineText ||
                correspondingResponse.dataType.name === 'Single-Line Text'){
                newRow.meaning = value;
               }
               if(
                correspondingResponse.dataType.code === FieldDataType.Dropdown ||
                correspondingResponse.dataType.name === 'Dropdown'){
                newRow.valueId = value;
               }
              if (
                correspondingResponse.dataType.code === FieldDataType.MultiSelectDropdown ||
                correspondingResponse.dataType.name === 'Multi-Select Dropdown'
            ) {
                newRow.createLookupFilterParameterRequests = value.map((item, index) => {
                    return { valueId: item };
                });
            }
               // Assign other properties similarly based on your data structure
           }
        this.createLookupFilterRequests.push(newRow);
      }
    });
  }
  getDefaultFilterValue() {
    this.defaultStandardFilter = this.filterResponseData.find(filter => filter.isSetAsDefault === true);
    return this.defaultStandardFilter ? this.defaultStandardFilter.id : null;
  }
  setDefaultFieldValues(standardFilterId: number): void {
    this.predefinedValuesMultiSelect = [];
    let selectedStdFilter = this.filterResponseData.find(filter => filter.id === standardFilterId);
    let lookUpFiltersField = selectedStdFilter.lookupFilters.find(filter => filter.lookupSetupId === standardFilterId).lookupFilters;
    // Get all form control keys except the first one
    const formControlKeys = Object.keys(this.projectmangamentschedulebaselineFilterForm.controls).slice(1);
    // Reset all form controls except the first one
    formControlKeys.forEach(field => {
      this.projectmangamentschedulebaselineFilterForm.get(field).reset(); // Reset the form control
    });
    Object.keys(this.preDefinedDataCodes).forEach(field => {
      const code = this.preDefinedDataCodes[field].code;
      const fieldData = lookUpFiltersField.find(filter => filter.code === code);
      if (fieldData != undefined) {
        if(
          fieldData.dataType.code === FieldDataType.SingleLineText ){
          this.projectmangamentschedulebaselineFilterForm.get(field).setValue(fieldData.meaning);
         }
         if(fieldData.dataType.code === FieldDataType.Dropdown){
          this.projectmangamentschedulebaselineFilterForm.get(field).setValue(fieldData.id);
         }
         if (fieldData.dataType.code === FieldDataType.MultiSelectDropdown ) {
          let multiSelectValues = fieldData.lookupFilterParameters.map(param => param.valueId);
          this.predefinedValuesMultiSelect = multiSelectValues.map(String);
          this.projectmangamentschedulebaselineFilterForm.get(field).setValue(this.predefinedValuesMultiSelect);
      }
      }
    });
  }
  onChangeStandardFilter($event: number): void {
    this.setDefaultFieldValues($event);
  }
  
closeFilter(){
  this.Filter =false
}

// ....................initializeForm.......................////


initializeForm(): void {
  this.formFieldValues = {
    standardFilter: this.getDefaultFilterValue(),
    Title: '',
    assignedTo: [] = [],
    Status: [] ,
    tags: [] = [],
    Phases: [] = [],
    Date: [],
  };
}
// ....................initializeForm.......................////
Assigned: NzTreeNodeOptions[]=[];
  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe(res => {
      const AssignedData = res.result; 
      console.log("dataforcombo",AssignedData);
      
      this.Assigned = AssignedData.map(LOP => ({
        title: LOP.fullName,
        key: LOP.id.toString(),
        isLeaf: true,
      }));
    });

  }
}



















