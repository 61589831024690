import { Component } from '@angular/core';

@Component({
  selector: 'app-page-not-found404',
  templateUrl: './page-not-found404.component.html',
  styleUrls: ['./page-not-found404.component.css']
})
export class PageNotFound404Component {

}
