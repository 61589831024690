export enum FieldDataType {
    SingleLineText = 'ST',
    MultiLineTextExpandable = 'MT',
    Checkbox = 'CB', WholeNumber = 'WN', 
    ListOfUsersSingleSelect = 'LUS',
    ListOfSubsVendorsSingleSelect = 'LSVS',
    Date = 'DT', Currency = 'CU', File = 'FI', 
    Dropdown = 'DR', MultiSelectDropdown = 'MSD', 
    Link = 'LNK',
    ListOfUsersMultiSelect = 'LUMS', 
    ListOfSubsVendorsMultiSelect = 'LSVMS',
}