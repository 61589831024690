import { ImportSelectionComponent } from './../Selection-Common/import-selection/import-selection.component';
import { NewSelectionComponent } from './Add Chooice List/new-selection/new-selection.component';
import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { HierarchyNewSelectionComponent } from '../hierarchy-new-selection/hierarchy-new-selection.component';
import { SelectionComponent } from '../Selection-Common/selection/selection.component';
import { AddchoiceSelectionChoiceComponent } from '../Selection-Common/addchoice-selection-choice/addchoice-selection-choice.component';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';

@Component({
  selector: 'app-hierarchy',
  templateUrl:'./hierarchy.component.html',
  styleUrls: ['./hierarchy.component.css']
})
export class HierarchyComponent {
  constructor(private modal: NzModalService,private filterService: FilterSideMenuBarService){}

  isRowVisible = true;
  

  toggleRowVisibility() {
    this.isRowVisible = !this.isRowVisible;
  }

  //New Selection modal
  openchooice(){
    const modalRef = this.modal.create({
      nzContent:NewSelectionComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });

  }
 //import Selection Component modal
  importSelectionModelOpen(): void {
    const modalRef = this.modal.create({
      nzContent: ImportSelectionComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });

  }

  selectionModelOpen(): void {
    const modalRef = this.modal.create({
      nzContent: SelectionComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });

  }
  // Add choice Selection ChoiceComponent modal
  selectionChoiceModelOpen(){
    const modalRef = this.modal.create({
       nzContent: AddchoiceSelectionChoiceComponent,
       nzFooter: null, 
     });
     modalRef.componentInstance.cancel.subscribe(() => {
       modalRef.destroy(); 
     });
 
   }
   PROJECT_MANG_SELEC_HIERARCHY: string = FiltersName.PROJECT_MANG_SELEC_HIERARCHY;
   PAGE_ID: number = 31;
   openFilter(component: string, pageId: number) {
     this.filterService.toggleFilter(component, pageId);
   }   
 

}
