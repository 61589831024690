import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ScheduleNewActivityvityComponent } from '../../Modal/schedule-new-activityvity/schedule-new-activityvity.component';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { FiltersName } from 'src/app/Core/Enums/filtersName';

@Component({
  selector: 'lead-opportunities-activity-calendar-header',
  templateUrl: './lead-opportunities-activity-calendar-header.component.html',
  styleUrls: ['./lead-opportunities-activity-calendar-header.component.css']
})
export class LeadOpportunitiesActivityCalendarHeaderComponent {
  constructor(private modal: NzModalService,private filterService: FilterSideMenuBarService){}

  PAGE_ID: number = 20002;
  LEAD_OPPR_ACT_CAL: string = FiltersName.LEAD_OPPR_ACT_CAL;
  
  scheduleNewActivityComponentOpen() {
    const modalRef = this.modal.create({
      nzContent: ScheduleNewActivityvityComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      
    });
  }
  openFilter(component: string, pageId: number) {
    this.filterService.toggleFilter(component, pageId);
  }

}
