import { Component, EventEmitter, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LeadOpportunitiesWorkSheetAddformCatalogComponent } from '../lead-opportunities-work-sheet-addform-catalog/lead-opportunities-work-sheet-addform-catalog.component';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-catalog-new-cost-group',
  templateUrl: './catalog-new-cost-group.component.html',
  styleUrls: ['./catalog-new-cost-group.component.css']
})
export class CatalogNewCostGroupComponent {
  constructor(private modal: NzModalService,  private fb: FormBuilder, ){}
  @Output() cancel = new EventEmitter<void>();

  inputValue?: string;
  Coster:boolean=false
  NewCostGroup: FormGroup;


    newCostGroup=true;
    newCostGroupCancel(): void {
      this.cancel.emit();
    }
    
    addButtonshowModel() {
      const modalRef = this.modal.create({
        nzContent: LeadOpportunitiesWorkSheetAddformCatalogComponent,
        nzFooter: null,
        nzData: 1 // Pass the default active tab as a property
        
      }
      );
    
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
    }


    initializeForm(): void {
      this.NewCostGroup = this.fb.group({
        title: [null],
        Description: [null],

    // Grid Baqi Ha 
      });
    }

}
