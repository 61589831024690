
<nz-modal class="shadow" nzWidth="97%"
  [(nzVisible)]="dailyLogVisible" [nzTitle]="modalTitle"
  [nzFooter]="dailyLogFooter"
  [nzBodyStyle]="{background: '#f1f4fa',padding: '13px','max-height': '72vh','overflow-x': 'hidden',}"
  (nzOnCancel)="onCancel()"
  [nzStyle]="{ top: '15px' }">
  <ng-template #modalTitle>
    <p nz-typography>{{selectedJobName}}</p>
    <h2 nz-typography>Daily Log</h2>
  </ng-template>

  <form nz-form [formGroup]="DailyLogForm">
    <div id="dailyLogForm" *nzModalContent>

      <div nz-row nzAlign="middle"
        *ngIf="errorMessages && errorMessages?.length > 0">
        <div nz-col [nzSpan]="24">
          <nz-alert
            nzType="error"
            nzShowIcon
            [nzMessage]="message"
            [nzDescription]="errorDescriptionTemplate">
          </nz-alert>
        </div>
      </div>

      <ng-template #errorDescriptionTemplate>
        <ul>
          <li class="sub-text p-0 m-0"
            *ngFor="let error of errorMessages">
            {{ error }}
          </li>
        </ul>
      </ng-template>

      <ng-template #message>
        <strong class="mt-2">Please correct the following
          fields:</strong>
      </ng-template>

      <div nz-row [nzGutter]="16">

        <!-- left side -->
        <div nz-col class="gutter-row" [nzSpan]="8"><div
            class="inner-box">

            <nz-card nzBorderless class="mb-2"
              nzTitle="Daily Log Information">

              <div nz-row>
                <div nz-col [nzSpan]="24">
                  <label class="sub-text" for="Job">Job <span
                      class="text-danger"></span></label>
                  <nz-form-item>
                    <nz-form-control>
                      <nz-select
                        formControlName="jobInformationId"
                        nzShowSearch>
                        <nz-option class="p-0 m-0"
                          *ngFor="let option of jobList"
                          [nzLabel]="option.name"
                          [nzValue]="option.id"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="24">
                  <label class="sub-text" for="Date">Date
                    <span
                      class="text-danger">*</span></label>
                  <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Required'">
                      <nz-date-picker
                        formControlName="date"
                        [nzFormat]="'MMM d, YYYY'"
                        nzSuffixIcon="false"
                        nzAllowClear
                        [nzPlaceHolder]="' '">
                      </nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="24">
                  <label class="sub-text"
                    for="title">Title<span
                      class="text-danger"></span></label>
                  <nz-form-item>
                    <nz-form-control [nzErrorTip]="titleErrorMessage">
                      <input nz-input
                        formControlName="title"
                        type="text"
                        id="title" />
                    </nz-form-control>
                  </nz-form-item>
                </div>

              </div>
              <div nz-row [nzAlign]="'middle'">
                <div nz-col [nzSpan]="16"
                  class>
                  <div>
                    <label
                      class="sub-text">Tags</label>
                    <nz-form-item>
                      <nz-form-control>
                        <nz-tree-select
                          formControlName="createDailyLogTagParameterRequests"
                          style="width: 100%"
                          [nzNodes]="tagNodes"
                          (ngModelChange)="onTagChange($event)"
                          nzShowSearch
                          nzAllowClear="false"
                          nzCheckable
                          nzDropdownClassName="dropdownStyle">
                        </nz-tree-select>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>

                <div nz-col [nzSpan]="4"
                  class="mt-2">
                  <nz-space
                    nzAlign="center">
                    <button nz-button
                      (click)="newDailyLogTagOpen()"
                      nzType="link">Add</button>
                    <button
                      nz-button
                      nzType="link"
                      (click)="newDailyLogTagEdit()"
                      [disabled]="!isSingleItemSelected()">
                      Edit
                    </button>

                  </nz-space>
                </div>
              </div>

            </nz-card>

            <nz-card nzBorderless class="mb-2" *ngIf="!dailyLogResponse"
              nzTitle="Related To-Do's">
              <div nz-row>
                <div nz-col [nzSpan]="24">
                  <label class="sub-text"><h2>To-Do's
                      available
                      after
                      save</h2></label>
                </div>
                <button nz-button nzType="default" (click)="createDailyLogAndOpenTodo()">Save and
                  Create
                  To-Do</button>
              </div>
            </nz-card>

            <nz-card *ngIf="dailyLogResponse"
              nzTitle="Related To-Do's"
              [nzExtra]="todoExtra">
              <div class="text-center">
                <span class="mb-2">
                  <img
                    src="assets/Empty-SVGs/Project-management/todos/todos.svg"
                    style="width: 70px; height: 60px">
                </span>
                <h5 class="mb-2" nz-typography>
                  No Related To-Do's
                </h5>
                <small class="mb-2">
                  To-do's can help you and your team stay on track, and get work
                  done faster.
                </small>
              </div>

              <ng-template #todoExtra>
                <button nz-button nzType="default">New To-Do</button>
              </ng-template>
            </nz-card>
          </div>
        </div>
        <div nz-col class="gutter-row" [nzSpan]="16">
          <div class="inner-box">
            <nz-card nzBorderless class="mb-3" nzTitle="Attachment">
              <div class="mb-2 p-0 m-0">
                <app-create-attachments-common
                  [TestAttachment__]="TestAttachment"
                  (filesUploaded)="onFilesUploaded($event)"></app-create-attachments-common>
              </div>
            </nz-card>

            <nz-card nzBorderless class="mb-2" nzTitle="Notes">
              <div nz-row>
                <div nz-col [nzSpan]="24">
                  <nz-form-item>
                    <nz-form-control [nzErrorTip]="allNotesErrorMessage">
                      <nz-textarea-count [nzMaxCharacterCount]="4000">
                      <textarea formControlName="notes"
                        [nzAutosize]="{ minRows: 5, maxRows: 10 }"
                        nz-input></textarea>
                        </nz-textarea-count>
                    </nz-form-control>

                  </nz-form-item>

                </div>
              </div>
            </nz-card>

            <nz-card nzTitle="Weather" class="mt-3 mb-3" >
              <div class="card-body">
                <div class="row">
                  <div class="col-9 d-flex align-items-center">
                    <label nz-checkbox formControlName="isIncludeWeatherConditions" (ngModelChange)="checkGetWeather()">
                      Include Weather Conditions
                    </label>
                  </div>
                </div>

                <div class="example" *ngIf="isLoadingWeather">
                  <nz-spin nzSimple [nzSpinning]="isLoadingWeather"></nz-spin>
                </div>

                <div  *ngIf="weatherData && !isLoadingWeather">
                  <div class="p-0 m-0" nzBorderless *ngIf="DailyLogForm.get('isIncludeWeatherConditions').value">
                    <div class="col-12">
                      <div nz-row>
                        <div nz-col nzSpan="10" class="mt-2">
                          Weather in {{ weatherData?.current?.condition?.text }}
                        </div>
                        <div nz-col nzSpan="12" class="text-end" formControlName="weatherOn">
                          {{ formatDate(weatherData?.current?.last_updated) }}
                        </div>
                      </div>
                      <div nz-row>
                        <div nz-col nzSpan="10">
                          <div nz-row>
                            <div nz-col>
                              <img
                                class="WeatherImage"
                                [src]="weatherData?.current?.condition?.icon ? 'https:' + weatherData?.current?.condition?.icon : 'assets/ProjectManagerSitesDiaries/rainyx50.png'"
                                alt="{{ weatherData?.current?.condition?.text }}"
                                style="height: 50px; margin-right: 16px; width: auto;" />
                            </div>
                            <div nz-col>
                              <div nz-row style="row-gap: 0px;">
                                <div nz-col>
                                  <strong style="font-size: 1.5em; font-weight: 700;">
                                    {{ weatherData?.current?.temp_c }} °C
                                  </strong>
                                </div>
                              </div>
                              <div nz-row style="row-gap: 0px;">
                                <div nz-col>
                                  <strong style="font-size: 1.5em; font-weight: 700;">
                                    {{ weatherData?.current?.feelslike_c }} °C
                                  </strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div nz-col nzSpan="12">
                          <div nz-row>
                            <div nz-col>Wind Speed: {{ weatherData?.current?.wind_kph }} km/h</div>
                          </div>
                          <div nz-row>
                            <div nz-col>Humidity: {{ weatherData?.current?.humidity }}%</div>
                          </div>
                          <div nz-row>
                            <div nz-col>Total Precipitation: {{ weatherData?.current?.precip_mm }} mm</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-9 d-flex align-items-center">
                    <nz-form-item>
                      <nz-form-control>
                        <label nz-checkbox formControlName="isIncludeWeatherNotes" (ngModelChange)="onCheckboxChange2()">
                          <span>Include Weather Notes</span>
                        </label>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>

                <div class="row mt-2" *ngIf="DailyLogForm.get('isIncludeWeatherNotes').value">
                  <div class="col-12">
                    <label class="sub-text"> Weather Notes</label>

                    <nz-form-item>
                      <nz-form-control [nzErrorTip]="weatherNotesErrorMessage">
                        <nz-textarea-count [nzMaxCharacterCount]="512">
                          <textarea formControlName="weatherNotes"
                                    [nzAutosize]="{ minRows: 2, maxRows: 10 }"
                                    nz-input></textarea>
                        </nz-textarea-count>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
            </nz-card>


            <nz-card nzBorderless class="mb-2"
              nzTitle="Messaging">
              <div class="row">
                <div class="col">
                  <h3 nz-typography *ngIf="!dailyLogResponse">Message available
                    after
                    save</h3>

                  <ng-container *ngIf="dailyLogResponse">

                    <messaging-common
                      [Job_ID]="JobInfoId"
                      [Messaging_ID]="Messaging_Id"
                      [header_ID]="dailyLogId"
                      [Form_Name_ID]="formNameId">
                    </messaging-common>
                  </ng-container>
                </div>
              </div>

            </nz-card>
          </div>
        </div>

      </div>
    </div>
  </form>
</nz-modal>

<ng-template #dailyLogFooter>

  <div nz-row class="p-1 justify-content-end">
    <div *ngIf="dailyLogResponse"
      nz-col
      nzSpan="12"
      class="footerContent">
      <span *ngIf="footerResponse">
        <app-footer-label
          [footerData]="footerResponse"></app-footer-label>
      </span>
    </div>
    <div nz-col nzSpan="12" class="text-end">
      <nz-space>

        <ng-container *nzSpaceItem>
          <button nz-button *ngIf="dailyLogResponse"
            nzType="default" (click)="showConfirm()"
            [disabled]="DisabledNextActivity">Delete</button>
        </ng-container>
        <ng-container *nzSpaceItem>
          <button nz-button nzType="primary"
            (click)="saveAndNewDailyLog()"
            [disabled]="DisabledNextActivity"
            [nzLoading]="isSaveAndNew">
            Save & New
          </button>
        </ng-container>

        <ng-container *nzSpaceItem>
          <button nz-button nzType="primary" (click)="saveButton()"
            [disabled]="DisabledNextActivity"
            [nzLoading]="isSaving">
            Save
          </button>
        </ng-container>

        <ng-container *nzSpaceItem>
          <button nz-button nzType="primary"
            [disabled]="DisabledNextActivity"
            (click)="saveAndCloseDailyLog()"
            [nzLoading]="isSaveAndClose">
            Save & Close
          </button>
        </ng-container>
      </nz-space>
    </div>
  </div>
</ng-template>
