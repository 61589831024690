<nz-modal nzWidth="55%" [(nzVisible)]="scratchFormModal" [nzTitle]="JobCreateNewScratchModalTitle"
    [nzFooter]="jobCreateNewScratchModalfooter"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '75vh', 'overflow-y': 'auto' }"
    [nzStyle]="{ top: '15px' }" (nzOnCancel)="cancelEventScratchFormModal()">
    <ng-template #JobCreateNewScratchModalTitle>
        <div class="row p-0 m-0">
            <h1 class="p-0 m-0 famly-inn">
               Template
            </h1>
        </div>
    </ng-template>

    <div class="content content-set-background famly-inn " *nzModalContent>
        <div class="card-container">
            <nz-tabset nzType="card">
                <nz-tab *ngFor="let tab of tabJob" [nzTitle]="jobScratchTabMethod(tab)">
                  
                    <ng-container class=" ant-tabs-content " *ngIf="tab === 1">

                        <nz-card  nzBorderless="true" [nzTitle]="TemplateInformation">
                           <ng-template #TemplateInformation>
                            Template Information
                        </ng-template>

                        <div class="row">
                            <div class="col-3">
                                    <label class="sub-text mb-1" for="displayName">Job Name <span class="text-danger">*</span></label>
                                    <input  type="text" [(ngModel)]="formFieldValues['name']" name="name"  class="form-control formField">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label class="sub-text mb-1" for="displayName">Job Color <span class="text-danger">*</span></label>
                                <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['colorId']" name="colorId">
                                    <nz-option *ngFor="let option of colors" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                                </nz-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <label class="sub-text mb-1" for="displayName">Project Managers</label>
                                <nz-tree-select [nzNodes]="projectManagersNodes"
                                    [nzVirtualHeight]="'250px'" nzShowSearch nzAllowClear
                                    nzCheckable nzCheckAll="false"
                                    [(ngModel)]="formFieldValues['createTemplateProjectManagerUserParameterRequests']" name="createTemplateProjectManagerUserParameterRequests"
                                    >
                                </nz-tree-select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-7">
                                <label class="sub-text mb-1" for="displayName">Work Days<span class="text-danger">*</span></label>
                                <nz-tree-select
                                [nzNodes]="workDaysNodes"
                                [(ngModel)]="
                                  formFieldValues['createJobTemplateWorkDayParameterRequests']
                                "
                                name="createJobTemplateWorkDayParameterRequests"
                                nzShowSearch
                                nzAllowClear
                                nzCheckable
                                nzCheckAll="false"
                                nzPlaceHolder="Select contact type"
                              ></nz-tree-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <label  [(ngModel)]="formFieldValues['isIncludeAllowances']" name="isIncludeAllowances" nz-checkbox  class="famly-inn">Include Allowances</label> 
                            </div>
                        </div>

                        </nz-card>
                        <nz-card  nzBorderless="true" [nzTitle]="Notes">
                            <ng-template #Notes>
                                Notes
                            </ng-template>
                            <div class="row mb-2">
                                <div class="col-12">
                                    <label class="famly-inn fw-medium">Internal Notes</label>
                                    <textarea
                                    class="formField"
                                    rows="2"
                                    nz-input
                                    [(ngModel)]="formFieldValues['internalNotes']" name="internalNotes"
                                    ></textarea>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label class="famly-inn fw-medium">Sub/Vendor Notes</label>
                                    <textarea
                                    class="formField"
                                    rows="2"
                                    nz-input
                                    [(ngModel)]="formFieldValues['subVendorNotes']" name="subVendorNotes"
                                    ></textarea>
                                </div>
                            </div>
                        </nz-card> 
                      
                    </ng-container>
                    <ng-container class=" ant-tabs-content " *ngIf="tab === 2">

                        <nz-card  nzBorderless="true" [nzTitle]="InternalUserPermissions" [nzExtra]="extraTemplate">
                            <ng-template #InternalUserPermissions>
                                Internal User Permissions
                            </ng-template>

                            <div nz-row [nzGutter]="5" class="mb-3">
                                <div nz-col nzSpan="22">
                                  <nz-tree-select
                                    [nzNodes]="internalUserNodes"
                                    nzAllowClear="false"
                                    nzCheckable
                                    nzCheckAll="true"
                                    [(ngModel)]="selectedUserIds"
                                    [nzDisabled]="selectedUserIds.includes('All users have been added to this job.')"
                                    [nzPlaceHolder]="selectedUserIds.includes('All users have been added to this job.') ? 'All users have been added to this job.' : null"
                                  ></nz-tree-select>
                                </div>
                                <div nz-col nzSpan="2">
                                  <button nz-button nzType="primary" [disabled]="selectedUserIds.length === 0 || selectedUserIds.includes('All users have been added to this job.')" (click)="addSelectedUsers()">Add</button>
                                </div>
                              </div>

                              <div class="row">
                                <nz-table
                                  nzSize="small"
                                  #small
                                  [nzFrontPagination]="false"
                                  nzShowSizeChanger
                                  [nzData]="selectedUsers"
                                >
                                  <thead>
                                    <tr>
                                      <th class="col-1"
                                        [(nzChecked)]="checked"
                                        [nzIndeterminate]="indeterminate"
                                        (nzCheckedChange)="onAllChecked($event)"
                                      ></th>
                                      <th class="col-7">Name</th>
                                      <th class="col-1"
                                        [(nzChecked)]="checked"
                                        [nzIndeterminate]="indeterminate"
                                        (nzCheckedChange)="onAllChecked($event)"
                                      ></th>
                                      <th class="col-4">Notifications</th>
                                    </tr>
                                  </thead>
                
                                  <tbody>
                                    <tr *ngFor="let data of selectedUsers">
                                      <td class="col-1"
                                        [nzChecked]="setOfCheckedId.has(data.id)"
                                        (nzCheckedChange)="onItemChecked(data.id, $event)"
                                      ></td>
                                      <td class="col-7"
                                     
                                    >
                                      <nz-avatar
                                        class="avatar-style adjust" 
                                        [nzText]="internalUserAvatarText(data?.firstName, data?.lastName)"
                                        style="color: #f56a00; background-color: #fde3cf"
                                        
                                      ></nz-avatar>
                                    
                                        <span  class="ant-popover1 company-name"
                                      nzSize="large"
                                      nz-popover
                                      [nzPopoverContent]="contentTemplate"> {{ data?.firstName }}  {{ data?.lastName }}</span>
                                    </td>
                                    
                                      <td class="col-1"
                                        [nzChecked]="setOfCheckedId.has(data.id)"
                                        (nzCheckedChange)="onItemChecked(data.id, $event)"
                                      ></td>
                                      <td class="col-4">
                                      </td>
                                      <ng-template #contentTemplate>
                                        <div nz-row style="width: 330px; height: auto">
                                          <div nz-col nzSpan="24" class="ms-2">
                                            <div nz-row [nzGutter]="60">
                                              <div nz-col nzSpan="4">
                                                <nz-avatar
                                                  class="ant-avatar1 avatar-style"
                                                  [nzText]="generateInitials(data?.firstName)"
                
                                                ></nz-avatar>
                                              </div>
                                              <div nz-col nzSpan="15" class="ms-2 mt-2">
                                                <span class="fw-bold fs-7 company-name" *ngIf="data?.firstName || data?.lastName">
                                                 {{data?.firstName}}   {{data?.lastName}}
                                                </span>
                                                <div class="p-0 m-0" *ngIf="data?.email">
                                                  <i class="bi bi-envelope smf p-0"></i
                                                  ><span class="text-primary ms-2 p-0 mail"
                                                    >{{data?.email}}</span
                                                  >
                                                </div>
                                              </div>
                                            </div>
                                            <div nz-row>
                                              <div nz-col nzSpan="24" class="ms-0">
                                                <nz-space>
                                                  <button
                                                    *nzSpaceItem
                                                    nz-button
                                                    nz-popover
                                                    [nzPopoverContent]="Content1"
                                                    nzType="text"
                                                    nzSize="small"
                                                  >
                                                    <span
                                                      class="fs-4"
                                                      nz-icon
                                                      nzSize="large"
                                                      nzType="message"
                                                      nzTheme="outline"
                                                    ></span>
                                                  </button>
                                                  <ng-template #Content1>
                                                    Send a Messages
                                                  </ng-template>
                                                  <button
                                                    *nzSpaceItem
                                                    nz-button
                                                    nz-popover
                                                    [nzPopoverContent]="Content3"
                                                    nzType="text"
                                                    nzSize="small"
                                                  >
                                                    <span
                                                      class="fs-4"
                                                      nzSize="large"
                                                      nz-icon
                                                      nzType="user"
                                                      nzTheme="outline"
                                                    ></span>
                                                  </button>
                                                  
                                                  <ng-template #Content3>
                                                  <p *ngIf="data?.cellPhone">{{data?.cellPhone}}</p> 
                                                 </ng-template>
                                                  <button
                                                    *nzSpaceItem
                                                    nz-button
                                                    nz-popover
                                                    [nzPopoverContent]="Content2"
                                                    nzType="text"
                                                    nzSize="small"
                                                  >
                                                    <span
                                                      class="fs-4"
                                                      nzSize="large"
                                                      nz-icon
                                                      nzType="phone"
                                                      nzTheme="outline"
                                                    ></span>
                                                  </button>
                                                  <ng-template #Content2>
                                                    {{data?.phoneNumber}}
                                                  </ng-template>
                                                </nz-space>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </ng-template>
                                    </tr>
                                  </tbody>
                                </nz-table>
                              </div>
                       </nz-card>
                       <ng-template #extraTemplate>
                        <button nz-button nzType="default">Remove</button>
                      </ng-template>
                      
                    </ng-container>
                    <ng-container class=" ant-tabs-content " *ngIf="tab === 3">

                        <nz-card  nzBorderless="true" [nzTitle]="SubVendorPermissions" [nzExtra]="extraTemplateRemove">
                            <ng-template #SubVendorPermissions>
                                Sub/Vendor Permissions
                            </ng-template>
                            <div nz-row [nzGutter]="5" class="mb-3">
                                <div nz-col nzSpan="22">
                                  <nz-tree-select
                                   [nzNodes]="subVendorsNodes"
                                    nzAllowClear="false"
                                    nzCheckable
                                    nzCheckAll="true"
                                    [(ngModel)]="selectedVenodorIds"
                                    [nzDisabled]="selectedVenodorIds.includes('All Sub/Vendors have been added to this job.')"
                                    [nzPlaceHolder]="selectedVenodorIds.includes('All Sub/Vendors have been added to this job.') ? 'All Sub/Vendors have been added to this job.' : null"
                                 
                                  ></nz-tree-select>
                                </div>
                                <div nz-col nzSpan="2">
                                  <button nz-button nzType="primary" [disabled]="selectedVenodorIds.length === 0 || selectedVenodorIds.includes('All Sub/Vendors have been added to this job.')"  (click)="addSelectedVendors()">Add</button>
                                </div>
                              </div>
                
                              <div class="row">
                                <nz-table
                                  nzSize="small"
                                  #small
                                  #colSpanTable
                                  [nzFrontPagination]="false"
                                  #rowSelectionTable
                                  nzShowSizeChanger
                                  [nzData]="selectedVenodors"
                                  >
                                  <thead class="text-start">
                                    <tr>
                                      <th  class="col-1"
                                        [(nzChecked)]="checked"
                                        [nzIndeterminate]="indeterminate"
                                        (nzCheckedChange)="onAllChecked($event)"
                                      ></th>
                                      <th class="col-11" >Name</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let data of selectedVenodors">
                                      <td class="col-1"
                                        [nzChecked]="setOfCheckedId.has(data.id)"
                                        (nzCheckedChange)="onItemChecked(data.id, $event)"
                                      ></td>
                                      <td class="col-11">
                                        <nz-avatar
                                          [nzText]="generateInitials(data.companyName)"
                                          class="avatar-style"
                                        ></nz-avatar>
                                        <span nz-popover [nzPopoverContent]="SubVendorContent" class="company-name">{{ data?.companyName }}</span>
                                      </td>
                                      
                                      <ng-template #SubVendorContent>
                                        <div nz-row style="width: 330px; height: auto" >
                                          <div nz-col nzSpan="24" class="ms-2">
                                            <div nz-row [nzGutter]="50">
                                              <div nz-col nzSpan="5">
                                            
                                               <nz-avatar
                                               class="ant-avatar1"
                                               [nzText]="generateInitials(data.companyName)"
                                             ></nz-avatar>
                                              </div>
                                              <div nz-col nzSpan="15" class="ms-2 mt-2">
                                                <span class="fw-bold fs-7" *ngIf="data?.companyName"> {{data?.companyName}} </span>
                                                <div class="p-0 m-0" *ngIf="data?.subVenderManageEmails?.[0]?.email">
                                                  <i class="bi bi-envelope smf p-0"></i
                                                  ><span class="text-primary ms-2 p-0 mail"
                                                    >{{ data?.subVenderManageEmails?.[0]?.email }}</span
                                                  >
                                                </div>
                                                <div class="p-0 m-0" *ngIf="data?.primaryContactFirstLastName">
                                                  <i class="bi bi-person smf p-0"></i>
                                                  <span class="text-primary ms-2 p-0 mail">
                                                    {{data?.primaryContactFirstLastName}} 
                                                  </span>
                                                </div>
                                                
                                              </div>
                                            </div>
                                            <div nz-row>
                                              <div nz-col nzSpan="24" class="ms-0">
                                                <nz-space>
                                                  <button
                                                    *nzSpaceItem
                                                    nz-button
                                                    nz-popover
                                                    [nzPopoverContent]="SubsVendor1"
                                                    nzType="text"
                                                    nzSize="small"
                                                  >
                                                    <span
                                                      class="fs-4"
                                                      nz-icon
                                                      nzSize="large"
                                                      nzType="message"
                                                      nzTheme="outline"
                                                    ></span>
                                                  </button>
                                                  <ng-template  #SubsVendor1>
                                                    Send a Messages
                                                  </ng-template>
                                                  <button
                                                    *nzSpaceItem
                                                    nz-button
                                                    nz-popover
                                                    [nzPopoverContent]="SubsVendor2"
                                                    nzType="text"
                                                    nzSize="small"
                                                  >
                                                    <span
                                                      class="fs-4"
                                                      nzSize="large"
                                                      nz-icon
                                                      nzType="user"
                                                      nzTheme="outline"
                                                    ></span>
                                                  </button>
                                                  <ng-template #SubsVendor2 >
                                                  <p *ngIf="data?.cellPhone">{{data?.cellPhone}}</p>
                                                  </ng-template>
                                                  <button
                                                    *nzSpaceItem
                                                    nz-button
                                                    nz-popover
                                                    [nzPopoverContent]="SubsVendor3"
                                                    nzType="text"
                                                    nzSize="small"
                                                  >
                                                    <span
                                                      class="fs-4"
                                                      nzSize="large"
                                                      nz-icon
                                                      nzType="phone"
                                                      nzTheme="outline"
                                                    ></span>
                                                  </button>
                                                  <ng-template #SubsVendor3>
                                                    <p *ngIf="data?.businessPhone">{{data?.businessPhone}}</p> 
                                                  </ng-template>
                                                </nz-space>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </ng-template>
                                    </tr>
                                  </tbody>
                                  
                                </nz-table>
                             
                              </div>
                       </nz-card>
                       <ng-template #extraTemplateRemove>
                        <button nz-button nzType="default">Remove</button>
                      </ng-template>

                    </ng-container>

                </nz-tab>
            </nz-tabset>
        </div>

    </div>
    <ng-template #jobCreateNewScratchModalfooter>
        <nz-space>
            <button nzType="primary" *nzSpaceItem nz-button [disabled]="!formFieldValues.name || !formFieldValues.colorId || !formFieldValues.createJobTemplateWorkDayParameterRequests.length" (click)="saveScratchJob()">
                Save & Close
            </button>
            
            <!-- <button  nz-dropdown nzType="primary" *nzSpaceItem nz-button nzTrigger="click" [nzPlacement]="'bottomRight'" [nzDropdownMenu]="menu">
                Save & Close
                <span nz-icon nzType="down"></span>
            </button> -->
            <!-- <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                    <li nz-menu-item Class="fw-medium famly-inn" [nzDisabled]="formFieldValues['name'] && formFieldValues['colorId'] && formFieldValues['createJobTemplateWorkDayParameterRequests']" (click)="saveScratchJob()">Save</li>
                    <li nz-menu-item Class="fw-medium famly-inn">Save & Select Template</li>
                    <li nz-menu-item Class="fw-medium famly-inn">Save & Create Job</li>
                </ul>
            </nz-dropdown-menu> -->
        </nz-space>
    </ng-template>
</nz-modal>