import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { SelectionSetup } from 'src/Models/CompanySettings/Selections/SelectionSetup';
import { ApiResponseModel } from 'src/Models/apiResponse.model';
import { ResponseModel, ResponseModelArray } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SelectionsService {

  private baseUrl: string = environment.apiUrl + 'SelectionSetup/';


  constructor(private http: HttpClient) {}

  postCompanySelection(saleSetupData: SelectionSetup): Observable<ResponseModel<SelectionSetup>> {
    const apiUrl = `${this.baseUrl}`;
    return this.http.post<ResponseModel<SelectionSetup>>(apiUrl, saleSetupData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  getAllselection(): Observable<ResponseModelArray<SelectionSetup>> {
    return this.http.get<ResponseModelArray<SelectionSetup>>(`${this.baseUrl}getCompany-SelectionSetup`);
  }

  getData(): Observable<SelectionSetup[]> {
    return this.http.get<SelectionSetup[]>(`${this.baseUrl}getAll`);
  }

  updateData(data: SelectionSetup): Observable<SelectionSetup> {
    return this.http.put<SelectionSetup>(`${this.baseUrl}`, data);
  }

  deleteData(id: number): Observable<SelectionSetup> {
    return this.http.delete<SelectionSetup>(
      `${this.baseUrl}?globalId=${id}`
    );
  }
  getDataId(id: number): Observable<ApiResponseModel> {
    return this.http.get<ApiResponseModel>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }
}