<!-- <button class="ms-2" nz-button nzType="primary" (click)="showComment()">Comment Button</button> -->


<nz-modal nzWidth="80%" [(nzVisible)]="commentConversationvisible" [nzTitle]="ComentModalTitle" [nzFooter]="commentModalfooter"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '75vh', 'overflow-y': 'auto' }"
  [nzStyle]="{ top: '15px' }" (nzOnCancel)="commentConversationModelCancel()">
  <ng-template #ComentModalTitle>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0 ">
        Conversation
      </h2>
    </div>
  </ng-template>
  <form [formGroup]="commentConversation">

  <div class="content content-set-background " *nzModalContent>
    <div class="ng-container ">
      <nz-card nzBorderless="true" nzTitle="Conversation Information">
        <div class="row">
          <div class="col">
            <label class="sub-text mt-0 fs-7">Job</label><br>
            <p class="mt-1">ZH-047 Lot 186 SINCLAIRS RD DEANSIDE-DISPLAY</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label class="sub-text fs-7">Type</label><br>
            <p class="mt-1">PO</p>
          </div>
        </div>
        <div class="row">
          <div class="cp">
            <label class="sub-text fs-7">Title</label><br>
            <p class="mt-1">High Preasure Jet</p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <label class="sub-text fs-7">Comments</label>

            <i class="bi bi-eye-fill ms-2" nz-popover [nzPopoverContent]="Daily" nzPopoverPlacement="right"></i>
            <ng-template #Daily>
              <div class="rounded-2 p-0 m-0" style="width: 200px; max-height: 80vh; font-size: 13px;">
                <div class="card border-0 mt-0 m-0 p-0">
                  <div class="card-head border-bottom m-0 p-0">
                    <div class="row py-2">
                      <h5 nz-typography><span class="ms-1 mb-0">Who Can View?</span></h5>
                    </div>
                  </div>
                  <div class="card-body">
                    <p class="mb-0">
                      14 users can currently view all or some of this conversation (details on the comment).
                    </p>
                  </div>
                </div>
                <div class="card border-0 mt-0">
                  <div class="card-head m-0 p-0">
                    <div class="row py-2 ms-0">
                      <h5 nz-typography><span class="ms-0 mb-0">Sub/Vendor</span></h5>
                    </div>
                  </div>
                  <div class="card-body">
                    <p class="mb-0">
                      Multiflow Pty Ltd
                    </p>
                  </div>
                </div>
                <div class="card border-0 ">
                  <div class="card-head">
                    <div class="row mb-0">
                      <h5 nz-typography><span class="ms-0 ">Internal Users</span></h5>
                    </div>
                  </div>
                  <div class="card-body">
                    <p>
                      Abbas Hussain, Accounts Assistant, Construction Admin, Imran Ahmed, Lucas King, Mesum Hussain,
                      Muhammad Yousuf, Pre-Construction Admin, Sajad Hassani, sds dsd, Shabab Haider, Syed Ahsan Kazmi,
                      Testingoor testingNoor1
                    </p>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="row mt-4 ck-toolbar-container row-with-border">
          <div>

          </div>
          <textarea rows="4" nz-input formControlName="comment" [(ngModel)]="inputValue"></textarea> 
        </div>
        <!--  -->
        <div class="border-bottom pb-2 mt-3">
          <nz-collapse [nzBordered]="false">
            <nz-collapse-panel #p [ngStyle]="{'background': '#fafafa','border-radius': '4px',
                       'margin-bottom': '24px',
                       border: '0px'}" [nzExpandedIcon]="expandedIcon" [nzHeader]="tileetwe">
              <div class="row mt-0" #p>
                <div class="col-12">
                  <h5 nz-typography>Show</h5>
                  <div class="row mt-1">
                    <div class="col-9">
                      <label nz-checkbox formControlName="subsVendors"><span class="pira-label">Subs/Vendors</span></label>
                    </div>
                  </div>
                  <div class="col- gx-1">
                    <label class="sub-text">Notify
                      <i class="bi bi-info-circle-fill text-secondary ms-2" nz-popover [nzPopoverContent]="Daily1"
                        nzPopoverPlacement="right"></i>
                      <ng-template #Daily1>
                        <div class="rounded-2 p-0 m-0" style="width: 210px; font-size: 13px;">
                          <div class="row">
                            <span>
                              Owners will appear in this list if they have access to the entity this comment is
                              associated to.
                            </span>
                          </div>
                          <div class="row mt-3">
                            <span>
                              A Sub/Vendor will appear on this list if they have access to the entity this comment is
                              associated to and if they have viewing access to the job.
                            </span>
                          </div>
                          <div class="row mt-3">
                            <span>
                              An Internal User will appear in the list if they have access to the entity the comment is
                              associated with, if they have access to the job, and if they have their notifications
                              turned on for new comments on the entity.
                            </span>
                          </div>
                        </div>
                      </ng-template>
                    </label>
                    <div class="input-group mt-1">
                      <nz-select formControlName="notify" style="width: 100%" nzShowSearch nzMode="tags" [lang]="true" nzAutoFocus="true"
                        nzPlaceHolder="Unassigned" [(ngModel)]="selectedValue">
                        <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                        <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                        <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                      </nz-select>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template #tileetwe>
                <span class="LColaPP mt-1">Viewer & Notification Settings</span>
              </ng-template>
              <ng-template #expandedIcon let-active>
                {{ active }}
                <span nz-icon nzType="caret-right" class="ant-collapse-arrow" [nzRotate]="p.nzActive ? 90 : -0"></span>
              </ng-template>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
        <nz-card nzBorderless="true">
            <div class="row">
                <div class="col-1 p-0">
                    <nz-avatar nzText="USER"></nz-avatar>
                </div>
                <div class="col-11 p-0  ">
                    <div class="card" style="width: 90%; height: 100%;">
                        <div class="card-body" style="background-color: #f1f4fa;">
                          <div class="row">
                            <div class="col-2 text-start">
                                <h6 class="fs-7">Multiflow Pty Ltd</h6>
                            </div>
                            <div class="col-3 text-start gx-0">
                                <p style="font-size: small;">Aug 8,2022,10:21 PM</p>
                            </div>
                            <div class="col-7 text-end text-primary fs-4">
                                <i id="eyeIcon" class="bi bi-eye-fill"></i>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                                <p class="fs-7">Please re book in once rubbish over Inspection Shaft has been removed. A text message and Vedio Sent to Abbas</p>
                            </div>
                          </div>

                        </div>
                      </div>
                </div>
            </div>
          </nz-card>


        <!--  -->
      </nz-card>
    </div>
  </div>
  <ng-template #commentModalfooter>
    <div class="mt-2 align-items-center">
      <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
    </div>
  </ng-template>
</form>
</nz-modal>
