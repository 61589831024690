import { Component, EventEmitter, Inject, Input, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { TimeClockAddTimeClockTagComponent } from '../time-clock-add-time-clock-tag/time-clock-add-time-clock-tag.component';
import { TimeClockCostCodeEditorComponent } from '../time-clock-cost-code-editor/time-clock-cost-code-editor.component';
import { TimeClockEditTimeClockTagComponent } from '../time-clock-edit-time-clock-tag/time-clock-edit-time-clock-tag.component';
import { TimeClockNewCostCodeComponent } from '../time-clock-new-cost-code/time-clock-new-cost-code.component';
import { ApplicationUserResponse, CostCodeCategoryResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import {
  LeadOpportunityResponse,
  LookupNameSetupResponse,
} from 'src/Models/LeadTags';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { CostCodeService } from 'src/Service/Internaluser/cost-code.service';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { CostCode } from 'src/Models/CostCode/costCode';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { ShiftInformationResponse } from 'src/Models/Time-Clock/clock-in';
import { TimeClockService } from 'src/Service/TimeClock/time-clock.service';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { CosCodeModelComponent } from 'src/app/company-settings/cos-code-model/cos-code-model.component';

@Component({
  selector: 'app-time-clock-clock-in-multiple',
  templateUrl: './time-clock-clock-in-multiple.component.html',
  styleUrls: ['./time-clock-clock-in-multiple.component.css'],
})
export class TimeClockClockInMultipleComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Input() costCodeResponse: CostCodeCategoryResponse;
  @Input() pageId: number;
  PAGE_ID: number = 11;
  TagForm: FormGroup;
  isLoadingEdit: boolean = false;
  public isTagVisiblee = false;
  isLoadingOne: boolean = false;
  isDeleteTagsLoading: boolean;
  confirmModal: import('ng-zorro-antd/modal').NzModalRef<unknown, any>;
  timeClockForm: any = {};
  jobList: LeadOpportunityResponse[] = [];
  tagNodes: NzTreeNodeOptions[] = [];
  costSubCode: CostCode[] = [];
  userNodes: NzTreeNodeOptions[] = [];
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  clockInResponse: ShiftInformationResponse;
  userList: ApplicationUserResponse[] = [];
  Taghandle = true;
  currentTime: Date;
  tagValue = [];
  preDefinedDataCodes = {
    tags: { code: 'PMTCT' },
  };

  constructor(
    private modal: NzModalService,
    private fb: FormBuilder,
    private jobsService: JobInformationService,
    public localStorageService: AccessLocalStorageService,
    private costCodeServices: CostCodeService,
    private LookupFilterServices: LookupFilterService,
    private internalUserservice: InternalUserService,
    private timeClockIn: TimeClockService,
    private toastssService: NzMessageService,
    private loadingIndicatoreService: LoadingIndicatorService,
    private toastsService: NzMessageService,
    private leadOppService: LeadOpportunitiesService,
    @Inject(NZ_MODAL_DATA) public holdResponse: any
  ) { }

  ngOnInit(): void {
    this.iniliazeForm();
    this.initLoad();
    this.internalUserGetAll();
    this.updateTime();
    setInterval(() => {
      this.updateTime();
    }, 1000);




    this.timeClockForm = {
      globalId: '00000000-0000-0000-0000-000000000000',
      timeClockSetupId: 0,
      jobSetupId: 0,
      jobInformationId: 0,
      startOn: null,
      endOn: new Date(Date.now() - new Date().getTimezoneOffset() * 60000),
      startTime: null,
      endTime: new Date(Date.now() - new Date().getTimezoneOffset() * 60000),
      costCodeId: 0,
      notes: '',
      isClockIn: false,
      timeClockTagSetupId: null,
      applicationUserIds: [],
      createShiftTagParameterRequests: [],
    };

    this.costCodeServices
      .getCategoryData()
      .subscribe((res: ResponseModelArray<CostCode>) => {
        this.costSubCode = res.result.filter(
          (cost) => cost.timeClockLaborCode === true
        );
      });

    if (this.holdResponse) {
      this.timeClockForm.globalId = this.holdResponse.Data.globalId;
      this.timeClockForm.startTime = this.holdResponse.Data.startTime;
      this.timeClockForm.startOn = this.holdResponse.Data.startOn;
      this.timeClockForm.jobInformationId = this.holdResponse.Data.jobInformationId;
      this.timeClockForm.costCodeId = this.holdResponse.Data.costCodeId;
      this.timeClockForm.applicationUserIds.push(this.holdResponse.Data.applicationUserId.toString()); // Push into the array
      this.timeClockForm.createShiftTagParameterRequests = this.holdResponse.Data.shiftTagParameters.map(param => param.shiftTagSetupId.toString());
      this.timeClockForm.notes = this.holdResponse?.Data?.notes;
    }

    // JOB Data get /////////////////
    this.jobsService.getData().subscribe((res) => {
      this.jobList = res.result;
    });
  }

  calculateHoursWorked(startTime: Date, currentTime: Date): string {
      if (startTime && currentTime) {
        const start = new Date(startTime);
        const end = new Date(currentTime);
        const diff = Math.abs(end.getTime() - start.getTime());
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        return hours.toString() + ' : ' + minutes.toString() + '  ';
      }
      return '00 : 00';
    }

    saveClockOut() {
      let tag = this.timeClockForm.createShiftTagParameterRequests.map(
        (id: string) => ({
          shiftInformationId: null,
          shiftTagSetupId: id
        })
      );
      this.timeClockForm.applicationUserIds = this.timeClockForm.applicationUserIds.map(id => id.toString());
      this.timeClockForm.endTime = new Date(); // Assuming endTime is a Date field
      this.timeClockForm.createShiftTagParameterRequests = tag;
      this.timeClockIn.PostTimeClock(this.timeClockForm).subscribe((response) => {
        this.toastssService.success('Clock Out Successfully!');
        this.timeClockForm = {};
        this.modal.closeAll();
        this.cancel.emit();
      });
    }

  internalUserGetAll() {
    this.internalUserservice.getData().subscribe((res) => {
      this.userList = res.result;

      this.userNodes = this.userList.map((data) => ({
        title: data.firstName + data.lastName,
        value: data.id.toString(),
        key: data.id.toString(),
        isLeaf: true,
      }));
    });
  }

 
  updateTime() {
    this.currentTime = new Date();
    
  }
  // selectionChoiceModel


  initLoad() {
    this.LookupFilterServices.getLookUpValuesByFormNameId(
      this.PAGE_ID
    ).subscribe((res) => {
      let tags = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.tags.code
      );
      this.lookupSetNameResponse = res.result;
      this.tagNodes = tags.map((tags) => ({
        title: tags.name,
        value: tags.id.toString(),
        key: tags.id.toString(),
        isLeaf: true,
      }));
    });
  }

  // selectionChoiceModel
  newcostcode(): void {
    const modalRef = this.modal.create({
      nzContent: CosCodeModelComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  // ////////////////////////////////////// Tags  Data /////////////////////////////
  iniliazeForm() {
    this.TagForm = this.fb.group({
      id: [''],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      name: ['', Validators.required],
      companyParameterId: [1],
      formNameId: this.PAGE_ID,
      lookUpStandardId: [94],
      parentCode: ['PMTCT'],
      code: ['PMTCT'],
      description: [''],
      isFieldValue: [true],
    });
  }

  SaveOrUpdateData() {
    if (this.isLoadingEdit) {
      this.editData();
    } else {
      this.SaveData();
    }
  }

  SaveData() {
    if (this.TagForm.valid) {
      this.loadingIndicatoreService.show();
      this.isLoadingOne = true;
      setTimeout(() => {
        this.leadOppService.getLookUpNameSetup(this.TagForm.value).subscribe(
          (response) => {
            this.loadingIndicatoreService.hide();
            const tagName = this.TagForm.get('name')?.value;
            this.isTagVisiblee = false;
            this.iniliazeForm();
            this.initLoad();

            this.isLoadingOne = false;
            this.toastsService.success(`${tagName} saved successfully`);
          },
          (error) => {
            this.loadingIndicatoreService.hide();
            if (error.status === 500) {
              this.toastsService.error(
                'Internal Server Error. Please try again later.'
              );
              this.isLoadingOne = false;
            } else if (error.status === 400) {
              this.isLoadingOne = false;
              this.toastsService.error('Bad Request. Please check your input.');
            } else {
              this.toastsService.error(
                'Failed to save division trade. Please try again.'
              );
            }
          }
        );
      }, 1000);
    } else {
      this.TagForm.markAllAsTouched();
    }
  }

  editData() {
    if (this.TagForm.valid) {
      this.loadingIndicatoreService.show();
      const tagName = this.TagForm.get('name')?.value;
      setTimeout(() => {
        this.leadOppService.getLookUpNameSetup(this.TagForm.value).subscribe(
          (response: any) => {
            this.loadingIndicatoreService.hide();
            this.isTagVisiblee = false;
            this.iniliazeForm();
            this.initLoad();
            this.isLoadingEdit = false;
            this.toastsService.success(`${tagName} Update successfully`);
          },
          (error) => {
            this.loadingIndicatoreService.hide();
            if (error.status === 500) {
              this.toastsService.error(
                'Internal Server Error. Please try again later.'
              );
            } else if (error.status === 400) {
              this.toastsService.error('Bad Request. Please check your input.');
            } else {
              this.toastsService.error(
                'Failed to save division trade. Please try again.'
              );
            }
          }
        );
      }, 1000); // 2000 milliseconds (2 seconds)
    } else {
    }
  }

  ConfirmDeleteTags(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete Tags?',
      nzContent:
        'This Tags is not applied to any subs/vendors and will be deleted.',
      nzOkDanger: true,
      nzOkText: 'Delete',
      nzCancelText: 'Cancel',
      nzModalType: 'confirm',
      nzOkLoading: this.isDeleteTagsLoading,
      nzAutofocus: 'ok',
      nzCentered: true,
      nzOnOk: () => this.DeleteData(),
    });
  }

  DeleteData(): Promise<boolean> {
    const vendorId = this.TagForm.get('globalId')?.value;
    const tagName = this.TagForm.get('name')?.value;
    this.isDeleteTagsLoading = true;
    this.loadingIndicatoreService.show();

    return new Promise<boolean>((resolve, reject) => {
      this.leadOppService.deleteDataLookupNameSetup(vendorId).subscribe(
        (res: any) => {
          this.isDeleteTagsLoading = false;
          setTimeout(() => {
            this.toastsService.success(`${tagName} Deleted successfully`);
            this.isTagVisiblee = false;
            this.loadingIndicatoreService.hide();
            this.confirmModal.destroy();
            resolve(true);
          }, 1000);
          this.isLoadingEdit = false;
          this.isTagVisiblee = false;
          this.iniliazeForm();
          this.initLoad();
        },
        (error) => {
          this.isDeleteTagsLoading = false;
          this.isTagVisiblee = false;
          reject(false);
        }
      );
    });
  }


  showConfirmCancel(): void {
    if (this.TagForm.dirty) {
      // The form has unsaved changes, show confirmation modal
      this.confirmModal = this.modal.confirm({
        nzTitle: '<b>Save Changes?</b>',
        nzContent:
          '<p>This Lead Tags has unsaved changes. Do you want to save your changes before closing??</p> ',
        nzOkText: 'Save',
        nzCancelText: "Don't Save",
        nzOnOk: () => {
          // User confirmed to discard changes, save and close modals
          this.SaveData();
        },
        nzOnCancel: () => {
          // User canceled, reset form and close modal
          this.TagForm.reset();
          this.isTagVisiblee = false;
        },
        nzCloseIcon: 'false',
        nzStyle: { top: '250px' },
        nzClassName: 'custom-modal-content',
      });
    } else {
      // No unsaved changes, close the modal directly
      this.TagForm.reset();
      this.isTagVisiblee = false;
    }
  }

  showTagEdit(selectedValue: LookupNameSetupResponse): void {
    if (selectedValue) {
      const selectedId: number = Number(selectedValue); // Convert the selected ID to number
      // console.log('selectedId', selectedId);
      this.isTagVisiblee = true;
      this.isLoadingEdit = true;
      const selectedObjectFromLookup = this.lookupSetNameResponse.find(
        (item) => item.id === selectedId
      );
      if (selectedObjectFromLookup) {
        const selectedData = selectedObjectFromLookup;
        if (selectedData) {
          this.TagForm.controls['name'].setValue(selectedData.name);
          this.TagForm.controls['globalId'].setValue(selectedData.globalId);
          this.TagForm.controls['isFieldValue'].setValue(
            selectedData.isFieldValue
          );
          this.TagForm.controls['companyParameterId'].setValue(
            selectedData.companyParameterId
          );
          this.TagForm.controls['formNameId'].setValue(
            selectedData.formNameId
          );
          this.TagForm.controls['lookUpStandardId'].setValue(
            selectedData.lookUpStandardId
          );
          this.TagForm.controls['code'].setValue(selectedData.code);
          this.TagForm.controls['description'].setValue(
            selectedData.description
          );
          this.TagForm.controls['parentCode'].setValue(
            selectedData.parentCode
          );
        }
      }
    }
  }

  showTags(): void {
    this.loadingIndicatoreService.show();

    setTimeout(() => {
      this.loadingIndicatoreService.hide();
      this.isTagVisiblee = true;
      this.isLoadingEdit = false;
    }, 1000);
  }
}