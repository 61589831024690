

                      <!-- message -->

                      <div class="row">
                        <div class="col-12">
                          <div class="card border-0 mt-3">
                            <div class="card-head border-bottom">
                              <div class="row py-2">
                                <h5 nz-typography><span class="ms-3">Messaging</span></h5>

                              
                              </div>
                            </div>
                            <div class="card-body">
                              <div class="row mb-3">
                                <div class="col-12">
                                  <h2>
                                    Comments
                                    <i class="bi bi-eye-fill text-secondary ms-1" nz-popover
                                      [nzPopoverContent]="Da1iyly" nzPopoverPlacement="right"></i>
                                    <ng-template #Da1iyly>
                                      <div class="rounded-2">
                                        <p style="width: 210px; font-size: 13px">
                                          Who Can View?
                                          <nz-divider></nz-divider>
                                          18 users can currently view all or some of this conversation (details on the
                                          comment).
                                          <br />
                                          <br />
                                          <span class="fw-bold">Internal Users</span>
                                          <br />
                                          <br />
                                          Abbas Hussain, Accounts Assistant, Construction Admin,
                                          Imran Ahmed, John BT Test, Lucas King, mehmood test test,
                                          Mesum Hussain, Muhammad Umer, Muhammad Yousuf,
                                          Pre-Construction Admin, Sajad Hassani, Shabab Haider, sufyan shabbir2,
                                          Sultan Abdul Hameed , Syed Ahsan Kazmi, Waseem Ali, Wasi Shah
                                        </p>
                                      </div>
                                    </ng-template>
                                  </h2>

                                  
                                </div>
                              </div>
                              <!-- <div class="row">
                                <div class="col-11">
                                  <textarea [(ngModel)]="messagesFormValue['comments']" name="comments" rows="2"
                                    style="border-radius: 5px" class="formField" nz-input></textarea>
                                </div>
                                <div class="col-1 mt-3">
                                </div>
                              </div> -->
                              <div nz-row>
                                <div nz-col nzSpan="23">
                                    <textarea [(ngModel)]="messagesFormValue['comments']" name="comments" rows="2"
                                    style="border-radius: 5px" class="formField" nz-input></textarea>
                                </div>
                                <div nz-col nzSpan="1" class="mt-4">
                                    <button nz-button class="border-0"  nzType="default" (click)=" createMessage()"><span nz-icon nzType="send" nzTheme="outline"></span></button>

                                </div>
                              </div>
                             
                              <div class="row mt-3">
                                <div class="col-12">
                                  <nz-collapse [nzBordered]="false" class="conection border-0 rounded-2">
                                    <nz-collapse-panel class="border-0 rounded-2" #p [nzExpandedIcon]="expandedIcon"
                                      [nzHeader]="tileetwe">
                                      <div class="row m-0 p-1" #p>
                                        <div class="col">
                                          <label class="sub-text h5">Show</label>
                                        </div>
                                        <div class="col-md-12">
                                          <label
                                            nz-checkbox name="isCopyActivityAddresses">Owner</label>
                                        </div>

                                        <div class="col-md-12 mb-3">
                                          <label 
                                           nz-checkbox
                                            name="isCopyActivityAddresses">Subs/Vendors</label>
                                        </div>
                                        <div class="col align-items-center">
                                          <label class="sub-text h5">Notify
                                            <span nz-popover [nzPopoverContent]="CollapseMessage"
                                              nzPopoverPlacement="right" nz-icon
                                              nzType="info-circle-fill"></span></label>
                                        </div>
                                        <div class="col-md-12 mb-1">
                                          <div class="input-group">
                                            <nz-tree-select
                                              [(ngModel)]="messagesFormValue['createMessagingUserNotifyParameterRequests']  "
                                              name="createMessagingUserNotifyParameterRequests"
                                               style="width: 100%"
                                              nzShowSearch nzAllowClear="false" nzCheckable nzCheckAll="true"
                                              [nzVirtualHeight]="'80px'" [nzNodes]="combinedNodesMessaging"
                                              (ngModelChange)="onNodeSelectionChange($event)">
                                            </nz-tree-select>
                                          </div>
                                        </div>
                                      </div>
                                      <ng-template #CollapseMessage>
                                        <div class="rounded-2">
                                          <p style="width: 230px; font-size: 13px">
                                            Owners will appear in this list if they have
                                            access to the entity this comment is
                                            associated to.
                                            <br>
                                            <br>
                                            <span>
                                              A Sub/Vendor will appear on this list if they
                                              have access to the entity this comment is
                                              associated to and if they have viewing access
                                              to the job.
                                            </span>
                                            <br>
                                            <br>
                                            <span>
                                              An Internal User will appear in the list if
                                              they have access to the entity the comment is
                                              associated with, if they have access to the
                                              job, and if they have their notifications
                                              turned on for new comments on the entity.
                                            </span>
                                          </p>
                                        </div>
                                      </ng-template>

                                      <ng-template #tileetwe>
                                        <h5 nz-typography class="p-0 w-100 h-100 m-0 mt-0 mb-0 border-0">
                                          <span class="LColaPP ms-3">Viewer & Notification Settings</span>
                                        </h5>
                                      </ng-template>
                                      <ng-template #expandedIcon let-active>
                                        {{ active }}
                                        <span nz-icon nzType="caret-right" class="ant-collapse-arrow"
                                          [nzRotate]="p.nzActive ? 90 : -0"></span>
                                      </ng-template>
                                    </nz-collapse-panel>
                                  </nz-collapse>
                                  <nz-divider></nz-divider>

                                </div>
                              </div>

                              <!-- card Print -->


                            <div class="row">
                              <div class="col-3"></div>
                              <div class="col-9">
                                <nz-card [(ngModel)]="messagesFormValue['comments']" name="comments" *ngFor="let comment of flattenedComments" id="hoverRow" style="background-color: #e6f6ff; margin-top: 5px;border-radius: 5px;">
                                  <div class="row" style="margin-top: -5px;">
                                    <div class="col-10">
                                      
                                      <div class="ListViewJobname ">{{comment?.jobInformationId}} &nbsp;{{ comment.createdOn | date: 'MMM d, yyyy'}},{{ comment.createdOn | date: 'h:mm a' }}</div>
                                      <div class="row p-2">
                                        <div class="col-12">
                                        <div class="ms-3 mt-2"><h2>{{ comment.comments }}</h2></div>
                                      </div>
                                      </div>
                                    </div>
                                    <div class="col-2  text-end" id="hoverIcons" >
                                      <i   nz-popover [nzPopoverContent]="Summary" nzPopoverPlacement="top"  class="fs-6 text-primary fa-solid fa-trash me-3"></i>
                                      <ng-template #Summary>
                                        <div class="rounded-2">
                                          <p style="font-size: 13px;">
                                            Delete comment
                                          </p>
                                        </div>
                                      </ng-template>
                                      <i  nz-popover [nzPopoverContent]="Summary1" nzPopoverPlacement="top"  class="fs-6 text-primary fa-solid fa-eye me-2"></i>
                                      <ng-template #Summary1>
                                        <div class="rounded-2">
                                          <p style="font-size: 13px;">
                                            Edit viewing permissions
                                          </p>
                                        </div>
                                      </ng-template>
                                    </div>
                                  </div>
                                  
                                </nz-card>
                              </div>
                            </div>

                            <!-- First card -->
    <!-- <div  *ngIf="comments.length > 0 && !response" >
      <nz-card style="background-color: #e6f6ff; margin-top: 5px;border-radius: 5px;"  id="hoverRow" *ngFor="let comment of comments">



        <div class="row" style="margin-top: -5px;">
          <div class="col-10">
            
            <div class="ListViewJobname">{{ createOn | date: 'MMM d, yyyy'  }} {{ createOn | date: 'h:mm a' }} </div>
            <div class="row p-2">
              <div class="col-12">
              <div class="ms-3 mt-2"><h2>{{ comment }}</h2></div>
            </div>
            </div>
          </div>
          <div class="col-2  text-end" id="hoverIcons" >
            <i (click)="commentsDelete(responseSet?.globalId)"  nz-popover [nzPopoverContent]="Summary" nzPopoverPlacement="top"  class="fs-6 text-primary fa-solid fa-trash me-3"></i>
            <ng-template #Summary>
              <div class="rounded-2">
                <p style="font-size: 13px;">
                  Delete comment
                </p>
              </div>
            </ng-template>
            <i (click)="openmessaingEditPermissionModal()"  nz-popover [nzPopoverContent]="Summary1" nzPopoverPlacement="top"  class="fs-6 text-primary fa-solid fa-eye me-2"></i>
            <ng-template #Summary1>
              <div class="rounded-2">
                <p style="font-size: 13px;">
                  Edit viewing permissions
                </p>
              </div>
            </ng-template>
          </div>
        </div>
      </nz-card>
    </div> -->

    <!-- Second card -->
    <div *ngIf="response && response.length > 0" >
      <nz-card style="background-color: #e6f6ff; margin-top: 5px;border-radius: 5px;" id="hoverRow" *ngFor="let comment of response">
        
        <div class="row" style="margin-top: -5px;">
          <div class="col-10">
            
            <div class="ListViewJobname">{{ comment.createdOn | date: 'MMM d, yyyy'}}</div>
            <div class="row p-2">
              <div class="col-12">
              <div class="ms-3 mt-2"> <h2 >{{ comment.comments }}</h2></div>
            </div>
            </div>
          </div>
          <div class="col-2  text-end" id="hoverIcons" >
            <i (click)="commentsDelete(comment?.globalId)"  nz-popover [nzPopoverContent]="Summary" nzPopoverPlacement="top"  class="fs-6 text-primary fa-solid fa-trash me-3"></i>
            <ng-template #Summary>
              <div class="rounded-2">
                <p style="font-size: 13px;">
                  Delete comment
                </p>
              </div>
            </ng-template>
            <i  nz-popover [nzPopoverContent]="Summary1" nzPopoverPlacement="top"  class="fs-6 text-primary fa-solid fa-eye me-2"></i>
            <ng-template #Summary1>
              <div class="rounded-2">
                <p style="font-size: 13px;">
                  Edit viewing permissions
                </p>
              </div>
            </ng-template>
          </div>
        </div>
      </nz-card>
    </div>
                              
                              <!-- card Print -->


                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- message -->