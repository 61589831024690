import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalServiceService {
  private showModalSource = new Subject<void>();
  showModal$ = this.showModalSource.asObservable();

  // Expose a public method to trigger the next on the subject
  triggerShowModal() {
    this.showModalSource.next();
  }
}
