import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerInfromationRoutingModule } from './customer-infromation-routing.module';
import { CustomerContactsDashboardComponent } from './Components/customer-contacts-dashboard/customer-contacts-dashboard.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgZorroModule } from 'src/shared/ng-zorro/ng-zorro.module';
import { SharedModule } from 'src/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NewContactComponent } from './Components/new-contact/new-contact.component';
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { CustomerStandardViewComponent } from './Components/customer-standard-view/customer-standard-view.component';
import { CustomerLeadOpportunitiesComponent } from './Components/customer-lead-opportunities/customer-lead-opportunities.component';
import { CustomerJobsComponent } from './Components/customer-jobs/customer-jobs.component';
import { NewContactCustomerComponent } from './Components/new-contact-customer/new-contact-customer.component';




@NgModule({
    declarations: [
        CustomerContactsDashboardComponent,
        NewContactComponent,
        CustomerStandardViewComponent,
        CustomerLeadOpportunitiesComponent,
        CustomerJobsComponent,
        NewContactCustomerComponent
    ],
    imports: [
        CustomerInfromationRoutingModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
        SharedModule,
        NgZorroModule,
        NzTreeSelectModule,
        BrowserModule,
        NgxIntlTelInputModule
    ]
})
export class CustomerInfromationModule { }
