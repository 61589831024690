import { Component, Input, OnInit } from '@angular/core';
import { ScheduleItemParameterResponse } from 'src/Models/Project-Management/projectManagement';


@Component({
  selector: 'app-new-schedule-item-related-item',
  templateUrl: './new-schedule-item-related-item.component.html',
  styleUrls: ['./new-schedule-item-related-item.component.css']
})
export class NewScheduleItemRelatedItemComponent implements OnInit{

  @Input() responseSchedule: ScheduleItemParameterResponse;
  iconState: 'up' | 'down' = 'up';

  ngOnInit(): void {
    console.log(this.responseSchedule);
    
  }



  get showCard(): boolean {
    return !!this.responseSchedule; // Show the card if responseSchedule has data
  }

}
