import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DailyLogSetupResponse } from 'src/Models/Project-Management/projectManagement';
import { SiteDiariesService } from 'src/Service/Project-Management/site-diaries.service';

@Component({
  selector: 'app-daily-logs',
  templateUrl: './daily-logs.component.html',
  styleUrls: ['./daily-logs.component.css']
})
export class DailyLogsComponent implements OnInit  {

  constructor(private DailyLOgService:SiteDiariesService){}
  @Output() cancel = new EventEmitter<void>();
  formFieldValues: any = {};
  dailyLogSetup:DailyLogSetupResponse;
  checked = false;
  checked1 = false;
  checked2 = false;
  checked3 = false;
  checked4 = false;
  checked5 = false;
  checked6 = false;
  checked7 = false;
  closeToDos(): void {
    this.cancel.emit();
    }
  ngOnInit(): void {
   
    this.formFieldValues = {

      companyParameterId: 1,
      isStampLocation: false,
      notes: "",
      isIncludeWeatherConditionsDefault: false,
      isIncludeWeatherConditionNotesDefault: false,
      createDefaultDailyLogShareParameterRequests: [
        {
          userTypeId: 1,
          isShare: false,
          isNotify: false
        }
  ]

    }

  }

  saveDialyLog(){
  let  data = {...this.formFieldValues}
  console.log(this.formFieldValues);
    this.DailyLOgService.postDailyLogData(data).subscribe(response =>{
      this.dailyLogSetup = response.result;
      console.log('saveDialyLog',this.dailyLogSetup);


      
    })
  }


  autoAdjustTextAreaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; // Set the height with a maximum of 200px
  }

  customfieldinfo:boolean = false;
  CustomFieldInfo(){
    this.customfieldinfo = true;
  }
  handleCancel2(): void {
    this.customfieldinfo = false;
  }

  dailylog: boolean =true;


  onIsNotifyChange(event: any, row: any) {
    if (event.target.checked) {
        // If isNotify checkbox is checked, also check the isShare checkbox
        row.isShare = true;
    } else {
        // If isNotify checkbox is unchecked, leave isShare checkbox as is
        // You can add additional logic here if needed
    }
}
  
updateShareCheckbox(isNotify: boolean) {
  if (isNotify) {
      // If Notify checkbox is checked, automatically check Share checkbox
      this.formFieldValues.createDefaultDailyLogShareParameterRequests['isShare'] = true;
  }
}

onNotifyCheckboxChange(isNotify: boolean) {
  if (isNotify) {
      
      this.formFieldValues.createDefaultDailyLogShareParameterRequests['isNotify'] = false;
      
  }
}

}
