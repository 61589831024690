<nz-modal nzWidth="70%" [(nzVisible)]="isVisible" [nzTitle]="modalTitle" [nzFooter]="modalFooter"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '540px', 'overflow-y': 'auto' }"
  [nzStyle]="{ top: '15px' }" (nzOnCancel)="handleCancel()">
  <ng-template #modalTitle>
    <div class="row">
      <h2 class="p-0 m-0 famly-inn">Invite Internal Users</h2>
    </div>
    <!-- <div class="row p-0 m-0">
      <h1 class="p-0 m-0 fw-medium">
        Invite Internal Users
      </h1>
    </div> -->
  </ng-template>
  <div class="content" *nzModalContent> 
    <div class="card border-0 mt-0">
      <div class="card-head border-bottom">
        <h3 class="ms-3 famly-inn pt-2 pb-2">Invite Internal Users to Zircon Homes T/A Xircon Homes</h3>
      </div>
      <div class="card-body" style="padding: 0; margin: 0; padding-left: 15px;padding-right: 15px;padding-top: 10px;">
        <label class="sub-text fs-6 famly-inn">Job Access</label>
        <div nz-row class="pt-3">
          <div nz-col nzSpan="12">
            <label nzChecked="true" nz-checkbox>
              <span class="famly-inn">
                Grant all users access to all existing jobs and templates<i class=" ms-2 bi bi-info-circle-fill text-secondary" nz-popover nzPopoverContent="Includes access to all closed jobs"></i>
              </span>
            </label>
          </div>
          <div nz-col nzSpan="12">
            <label nzChecked="true" nz-checkbox>
              <span class="famly-inn">
                Grant all users automatic access to new jobs and templates
              </span>
            </label>
          </div>
        </div>
        <div nz-row class="pt-4">
          <div nz-col nzSpan="1" style="padding-left: 4px; padding-right: 4px;">
            <label class="sub-text famly-inn">#</label>
          </div>
          <div nz-col nzSpan="5" style="padding-left: 4px; padding-right: 4px;">
            <label class="sub-text famly-inn">First Name<span class="text-danger text-bolder ms-2">*</span></label>
          </div>
          <div nz-col nzSpan="5" style="padding-left: 4px; padding-right: 4px;">
            <label class="sub-text famly-inn">Last  Name<span class="text-danger text-bolder ms-2">*</span></label>
          </div>
          <div nz-col nzSpan="7" style="padding-left: 4px; padding-right: 4px;">
            <label class="sub-text famly-inn">Email<span class="text-danger text-bolder ms-2">*</span></label>
          </div>
          <div nz-col nzSpan="6" style="padding-left: 4px; padding-right: 4px;">
            <label class="sub-text famly-inn">Role<span class="text-danger text-bolder ms-2">*</span></label>
          </div>
          <div nz-col nzSpan="1" style="padding-left: 4px; padding-right: 4px;"></div>
        </div>
        <div nz-row *ngFor="let user of users.controls; let i = index">
          <div nz-col nzSpan="1" style="padding-left: 4px; padding-right: 4px;">
            <label class="sub-text famly-inn">{{ i + 1 }}.</label>
          </div>
          <div nz-col nzSpan="5" style="padding-left: 4px; padding-right: 4px;">
            <input nz-input class="formField"/>
          </div>
          <div nz-col nzSpan="5" style="padding-left: 4px; padding-right: 4px;">
            <input nz-input class="formField"/>
          </div>
          <div nz-col nzSpan="7" style="padding-left: 4px; padding-right: 4px;">
            <input nz-input class="formField"/>
          </div>
          <div nz-col nzSpan="5" style="padding-left: 4px; padding-right: 4px;">
            <input nz-input class="formField"/>
          </div>
          <div nz-col nzSpan="1" style="padding-left: 4px; padding-right: 4px;">
            <div class="">
              <div class="trash-icon ms-3" *ngIf="i > 0">
                 <span nz-icon nzType="delete" nzTheme="fill" class="mb-2" (click)="removeUser(i)"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <button nz-button class="button-primary react m-0 p-0 ms-2" (click)="addUser()"><span>Add Another</span></button>
          </div>
        </div>
      </div>
    </div>
    <!-- <nz-card nzTitle="Invite Internal Users to Zircon Homes T/A Xircon Homes">
      <div nz-row>
        <div nz-col  nzSpan="1">
          <div class="col-1 g-1">
            <label class="sub-text">#</label>
          </div>
        </div>
        <div nz-col class="ms-2" nzSpan="5">
          <div class="">
          <label class="form-label">First Name <span class="text-danger">*</span></label>
        </div>
        </div>
        <div nz-col class="ms-2" nzSpan="5">
          <div class=" ">
            <label class="form-label">Last Name<span class="text-danger">*</span></label>
          </div>
        </div>
        <div nz-col class="ms-2" nzSpan="6">
          <div class="">
            <label class="form-label"> Email <span class="text-danger">*</span></label>
          </div>
        </div>
        <div nz-col class="ms-2" nzSpan="5">
          <div class="">
            <label class="form-label"> Role <span class="text-danger">*</span></label>
          </div>
        </div>
        <div nz-col nzSpan="1"></div>
      </div>
      
      <ng-container class="container mt-0" style="margin-top: -200px;">
        <form [formGroup]="userForm" (ngSubmit)="saveData()">
          <div  nz-row formArrayName="users" *ngFor="let user of users.controls; let i = index">
            
            <div nz-col class=" mb-4" nzSpan="1">
              <div class=" ">
                <p class="me-0 sub-text">{{ i + 1 }}.</p>
              </div>
            </div>
            <div nz-col class="ms-2 mb-4 " nzSpan="5">
              <div class="">
                <input nz-input type="text" class="formField" [formControl]="getFormControl('firstName', i)" id="firstName{{i}}"
                  [ngClass]="{'border-danger': getFormControl('firstName', i).hasError('required') && getFormControl('firstName', i).touched}" />
                <div *ngIf="getFormControl('firstName', i).hasError('required') && getFormControl('firstName', i).touched"
                  class="text-danger">
                  First Name is required.
                </div>
              </div>
            </div>
            <div nz-col class="ms-2 mb-4" nzSpan="5">
              <div class="">
                <input nz-input type="text" class="formField" [formControl]="getFormControl('lastName', i)" id="lastName{{i}}"
                  [ngClass]="{'border-danger': getFormControl('lastName', i).hasError('required') && getFormControl('lastName', i).touched}" />
                <div *ngIf="getFormControl('lastName', i).hasError('required') && getFormControl('lastName', i).touched"
                  class="text-danger">
                  Last Name is required.
                </div>
              </div>
            </div>
            <div nz-col class="ms-2 mb-4" nzSpan="6">
              <div class="">
                <input nz-input type="text" class="formField" [formControl]="getFormControl('email', i)" id="email{{i}}"
                [ngClass]="{
                  'border-danger': getFormControl('email', i).touched && getFormControl('email', i).invalid
                }" />
              
                 <div *ngIf="getFormControl('email', i).hasError('email') && getFormControl('email', i).touched" class="text-danger">
                  Please enter a valid email address.
                </div>
                <div *ngIf="getFormControl('email', i).hasError('required') && getFormControl('email', i).touched"
                class="text-danger">
                  Email is required.
              </div>
              </div>
            </div>
            <div nz-col class="ms-2 mb-4" nzSpan="5"> <div class=" ">
              <nz-select nzShowSearch nzAllowClear  nz-input [formControl]="getFormControl('applicationGroupId', i)" id="applicationGroupId{{i}}"
              [ngClass]="{
                'border-danger': getFormControl('applicationGroupId', i).touched && getFormControl('applicationGroupId', i).invalid
              }">
                <nz-option *ngFor="let role of roles" [nzLabel]="role.name" [nzValue]="role.id"></nz-option>
              </nz-select>
              <div *ngIf="getFormControl('applicationGroupId', i).hasError('required') && getFormControl('applicationGroupId', i).touched"
              class="text-danger">
              Role is required.
            </div>
            </div>
          </div>
            <div nz-col nzSpan="1" class=" mb-4">
              <div class="">
                <div class="trash-icon ms-3" *ngIf="i > 0">
                  <i class="fa fa-trash animated"  id="trash-icon"></i> -->
                  <!-- <span nz-icon nzType="delete" nzTheme="fill" class="mb-2" (click)="removeUser(i)"></span>
                </div>
              </div>
            </div>
          </div>
          
        </form>
        <div class="row mt-3">
          <div class="col">
            <button nz-button nzType="primary" (click)="addUser()">Add Another</button>
          </div>
        </div>
      </ng-container>
    </nz-card> -->
  </div>
  <ng-template #modalFooter>
    <div class="row ">
      <div class="col d-flex justify-content-end">
        <div>
          <button nz-button nzType="default" class="rounded-1 me-2" (click)="handleCancel()">Cancel</button>
        </div>
        <div>
          <button type="submit" nz-button nzType="primary" (click)="saveData()" [disabled]="!userForm.valid">Send Invites</button>

        </div>
      </div>
    </div>
  </ng-template>
</nz-modal>

<app-loading-indicator></app-loading-indicator>