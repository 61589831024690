import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-todos-empty-content',
  templateUrl: './todos-empty-content.component.html',
  styleUrls: ['./todos-empty-content.component.css']
})
export class TodoEmptyContentComponent {

  constructor(private modalService: NzModalService) {
  }
}

