<div class="row famly-inn gx-3">
    <div class="col-12">
        <div class="card border-0 mt-0">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Docs</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row mt-3 mb-3">
                    <div class=" col-9 d-flex align-items-center">
                        <label nz-checkbox [(ngModel)]="checked"><span class="pira-label">Allow Subs/Vendors and owners to upload docs into docs tab</span></label>
                        <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="enable" nzPopoverPlacement="bottom"></i>
                        <ng-template #enable>
                          <div class="rounded-2">
                            <p style="height: 150px; width: 210px; font-size: 13px;">
                                This creates a specific folder for each sub/vendor and owner that they can upload documents into. Only Internal Users with permission to view that folder, and the specific sub/vendor or owner can see this folder. You can move these files into any other folders.
                            </p>
                          </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Picture Sharing</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row  mt-3">
                    <div class="col-12">
                        <label class="sub-text">Houzz Website Identifier</label>
                        <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="enable" nzPopoverPlacement="top"></i>
                        <ng-template #enable>
                          <div class="rounded-2">
                            <p style="height: 150px; width: 210px; font-size: 13px;">
                                For new jobs, from scratch or from a template, if there are no existing albums with the owner allowed to upload photos, auto-create an "Owner Added Photos" album for them with the permissions set to allow them to view & upload photos.
                            </p>
                          </div>
                        </ng-template>
                        <div class="row">
                    <div class="col-2">
                        <input
                        formControlName="name"
                        type="text"
                        class="form-control formField">
                    </div>
                        </div>
                     
                    </div>
                </div>
                <div class="row mt-3 mb-3">
                    <div class=" col-9 d-flex align-items-center">
                        <label nz-checkbox [(ngModel)]="checked1"><span class="pira-label">Auto Create Album for Owners to Upload Photos</span></label>
                        <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="enable" nzPopoverPlacement="top"></i>
                        <ng-template #enable>
                          <div class="rounded-2">
                            <p style="height: 150px; width: 210px; font-size: 13px;">
                                For new jobs, from scratch or from a template, if there are no existing albums with the owner allowed to upload photos, auto-create an "Owner Added Photos" album for them with the permissions set to allow them to view & upload photos.
                            </p>
                          </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Signature Request</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row  mb-3">
                    <div class="col-md-12">
                        <label class="sub-text me-2">Default Release Text</label>
                        <textarea
                            class="form-control formField"
                            style="height: 54px; resize: none;"
                            (input)="autoAdjustTextAreaHeight($event)"
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
</div>
</div>