import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { RoleManagementCustomFieldComponent } from '../role-management-custom-field/role-management-custom-field.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ApplicationGroupResponse, ApplicationGroupParameterResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { FormGroup } from '@angular/forms';

interface Person {
  key: string;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'app-manage-role',
  templateUrl: './manage-role.component.html',
  styleUrls: ['./manage-role.component.css']
})
export class ManageRoleComponent {



  constructor(private modal: NzModalService,){

  }
  @Output() cancel = new EventEmitter<void>();
  ManageRole: boolean = false; // Set to false initially
  modalTitle: string = "Standard Roles";

  closeDialog() {
    this.cancel.emit();
  }




  listOfData: Person[] = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park'
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park'
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park'
    }
  ];











  showrolemanagememtCustomField() {
    const modalRef = this.modal.create({
      nzContent: RoleManagementCustomFieldComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
   
  }








  @Input() roleId: number;
  @Input() selectedRole: ApplicationGroupResponse; 

  applicationGroupParameters: ApplicationGroupParameterResponse[] = [];
  sortedApplicationGroupParameters : ApplicationGroupParameterResponse[] = [];

  
  ngOnChanges(changes: SimpleChanges) {
    if ((changes as any).roleId || (changes as any).selectedRole) {
      this.applicationGroupParameters = (changes as any).selectedRole.currentValue.applicationGroupParameters;

      this.sortedApplicationGroupParameters = [...this.applicationGroupParameters].sort((a, b) => {
        return a.applicationGroupId - b.applicationGroupId;
      });
      
      console.log("sortedArray:", this.applicationGroupParameters);

    }
  }
  loadRoleById() {
  }

}
