import { Component } from '@angular/core';

@Component({
  selector: 'app-past-due-for-you',
  templateUrl: './past-due-for-you.component.html',
  styleUrls: ['./past-due-for-you.component.css']
})
export class PASTDUEFORYOUComponent {
  show = true;
}
