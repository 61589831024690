<nz-layout class="p-0 m-0 ">
  <nz-header class="p-0 m-0 Unstuck">
    <div class="row ms-3 mt-1">
      <h1 class="p-0 m-0">Shift</h1>
    </div>
  </nz-header>
  <nz-content>
    <div class="content p-3">
      <div class="row famly-inn gx-3">
        <div class="col-12">
          <div class="card border-0 mt-0">
            <div class="card-head border-bottom">
              <div class="row py-2">
                <div class="col-12">
                  <h3 class="ms-3" nz-typography>Shift Details</h3>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <label class="sub-text">Job <span class="text-danger fw-bolder">*</span></label>
                  <nz-select nzShowSearch nzAutoFocus="true" nzPlaceHolder="Select Lead"
                    [(ngModel)]="timeClockForm['jobInformationId']" name="jobInformationId">
                    <nz-option class="p-0 m-0" *ngFor="let option of jobList" [nzLabel]="option.name"
                      [nzValue]="option.id"></nz-option>
                  </nz-select>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <label class="sub-text">Users<span class="text-danger fw-bolder">*</span></label>
                  <nz-tree-select [nzDropdownStyle]="{ 'max-height': '250px', 'overflow-y': 'auto' }"
                  [(ngModel)]="timeClockForm['applicationUserIds']" name="applicationUserIds" [nzNodes]="userNodes"
                  nzShowSearch nzAllowClear="false" nzCheckable nzCheckAll="true"
                  nzPlaceHolder="Select Division"></nz-tree-select>
                </div>
              </div>
              <div class="row ">
                <div class="col-2">
                  <label class="sub-text">Start Time</label><br>
                  <nz-time-picker [(ngModel)]="timeClockForm['startTime']" name="startTime"
                    [nzPlaceHolder]="' '"></nz-time-picker>
                </div>
                <div class="col-2">
                  <label class="sub-text">Start Date</label><br>
                  <nz-date-picker [(ngModel)]="timeClockForm['startOn']" name="startOn" [nzPlaceHolder]="' '"
                    nzSuffixIcon="false"></nz-date-picker>
                </div>
              </div>
              <div class="row">
                <div class="col-2">
                  <label class="sub-text">End Time</label><br>
                  <nz-time-picker [(ngModel)]="timeClockForm['endTime']" name="endTime"
                    [nzPlaceHolder]="' '"></nz-time-picker>
                </div>
                <div class="col-lg-2  ">
                  <label class="sub-text">End Date</label><br>
                  <nz-date-picker [(ngModel)]="timeClockForm['endOn']" name="endOn" [nzPlaceHolder]="' '"
                    nzSuffixIcon="false"></nz-date-picker>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <label class="pira-label fw-bolder fs-6">Shift Total</label><label class=" ms-2 pira-label">{{
                    shiftTotal }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="card border-0 mt-3">
            <div class="card-body">
              <nz-tabset nzType="card">
                <nz-tab *ngFor="let tab of tabs" [nzTitle]="innerCardTabs(tab)" style="background-color: #f1f4fa;">
                  <ng-container *ngIf="tab === 1">
                    <div class="row p-2">
                      <div class="col-12">
                        <div class="row mt-1">
                          <div class="col-10">
                            <label class="sub-text">Tags</label>
                          </div>
                          <div class="col-1">
                          </div>
                          <div class="col-1">
                          </div>
                        </div>
                        <div class="row gx-1">
                          <div class="col-6">
                            <nz-tree-select name="activityStatus"
                              [(ngModel)]="timeClockForm['createShiftTagParameterRequests']"
                              name="createShiftTagParameterRequests" [nzNodes]="tagNodes" nzShowSearch
                              nzAllowClear="false" nzCheckable nzCheckAll="true"
                              nzPlaceHolder="Select Division"></nz-tree-select>
                          </div>
                          <div class="col-1">
                            <button nz-button type="button" class="none-buttom">
                              <span>Add</span>
                            </button>
                          </div>
                          <div class="col-1">
                            <button (click)="edittime()" nz-button type="button" class="none-buttom">
                              <span>Edit</span>
                            </button>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <label class="sub-text">Notes</label>
                            <textarea [(ngModel)]="timeClockForm['notes']" name="notes" style="border-radius: 5px;"
                              class="formField" nz-input></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="tab === 2">
                    <div class="row p-2">
                      <div class="col-12">
                        <div class="row gx-0">
                          <div class="col-2">
                            <label class="sub-text">Shift Type</label>
                          </div>
                          <div class="col-2">
                            <label class="sub-text">Hours</label>
                          </div>
                          <div class="col-2">
                            <label class="sub-text">Minutes</label>
                          </div>
                        </div>
                        <div class="row gx-2">
                          <div class="col-2">
                            <nz-select nzShowSearch nzAutoFocus="true" nzPlaceHolder="Select Lead"
                              [(ngModel)]="timeClockForm['shiftTypeSetupId']" name="shiftTypeSetupId">
                              <nz-option class="p-0 m-0" *ngFor="let option of ShiftType" [nzLabel]="option.name"
                                [nzValue]="option.id"></nz-option>
                            </nz-select>
                          </div>
                          <div class="col-2">
                            <nz-input-number [(ngModel)]="timeClockForm['shiftHours']" name="shiftHours" [nzMin]="1"
                              [nzMax]="10" [nzStep]="1"></nz-input-number>
                            <label class="pira-label ms-2">:</label>
                          </div>
                          <div class="col-2">
                            <nz-input-number [(ngModel)]="timeClockForm['shiftMinutes']" name="shiftMinutes" [nzMin]="1"
                              [nzMax]="10" [nzStep]="1"></nz-input-number>
                          </div>
                        </div>
                        <div class="row gx-2 mt-2 border-bottom pb-5">
                          <div class="col-2">
                            <label class="sub-text">Overtime</label>
                          </div>
                          <div class="col-2">
                            <nz-input-number [(ngModel)]="timeClockForm['overTimeHours']" name="overTimeHours"
                              [nzMin]="1" [nzMax]="10" [nzStep]="1"></nz-input-number>
                            <label class="pira-label ms-2">:</label>
                          </div>
                          <div class="col-2">
                            <nz-input-number [(ngModel)]="timeClockForm['overTimeMinutes']" name="overTimeMinutes"
                              [nzMin]="1" [nzMax]="10" [nzStep]="1"></nz-input-number>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-5">
                            <label class="pira-label fw-bolder fs-6">Total</label>
                          </div>
                          <div class="col-6">
                            <label class=" ms-2 pira-label">0 hours 0 minutes</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="tab === 3">
                    <!-- Only display the row containing labels when breaks are added -->
                    <div *ngIf="showParameters">
                      <div class="row border-bottom pb-3">
                        <div class="col-4 text-start"><label class="sub-text">Break Start</label></div>
                        <div class="col-4 text-start"><label class="sub-text">Break End</label></div>
                        <div class="col-4 text-end"><label class="sub-text">Break Time</label></div>
                      </div>
                    </div>
                    <!-- Loop through breaks and display break fields -->
                    <div *ngIf="showParameters">
                      <div
                        *ngFor="let param of timeClockForm.createShiftInformationBreakParameterRequests; let i = index">
                        <div class="row mt-3">
                          <div class="col-4">
                            <div class="row gx-2">
                              <div class="col-6">
                                <nz-time-picker [(ngModel)]="param.breakStartTime" name="paramBreakStart"
                                  nzPlaceHolder=" "></nz-time-picker>
                              </div>
                              <div class="col-6   ">
                                <nz-date-picker [(ngModel)]="param.breakStartOn" name="paramBreakStart"
                                  nzPlaceHolder=" " nzSuffixIcon="false"></nz-date-picker>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="row gx-2">
                              <div class="col-6">
                                <nz-time-picker [(ngModel)]="param.breakEndTime" name="paramBreakEnd"
                                  nzPlaceHolder=" "></nz-time-picker>
                              </div>
                              <div class="col-6">
                                <nz-date-picker [(ngModel)]="param.breakEndOn" name="paramBreakEnd" nzPlaceHolder=" "
                                  nzSuffixIcon="false"></nz-date-picker>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="row gx-2">
                              <div class="col-6">
                              </div>
                              <div class="col-6">
                                <i class="bi bi-trash mt-1" (click)="removeModuleParameter(i)"
                                  style="font-size: 22px; cursor: pointer;"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Button to add breaks -->
                    <div class="row p-2">
                      <div class="col-12">
                        <button nz-button nzType="primary" class="rounded-1 me-0" (click)="addModuleParameter()">Add
                          Break</button>
                      </div>
                    </div>
                  </ng-container>


                  <ng-container *ngIf="tab === 4">
                    <div class="row">
                      <label class="sub-text">Cost Code</label>
                      <div class="col-10">
                        <nz-select nzShowSearch nzAutoFocus="true" nzPlaceHolder="Buildertrend Flat Rate"
                          [(ngModel)]="timeClockForm['costCodeId']" name="costCodeId">
                          <nz-option [nzLabel]="'Buixldertrend Flat Rate'" [nzValue]="0"></nz-option>
                          <nz-option class="p-0 m-0" *ngFor="let option of costSubCode" [nzLabel]="option.title"
                            [nzValue]="option.id"></nz-option>
                        </nz-select>
                      </div>
                    </div>
                  </ng-container>
                </nz-tab>
              </nz-tabset>
            </div>
          </div>

        </div>
      </div>
    </div>
  </nz-content>
  <nz-footer class="p-2 Unstuck">
    <button nz-button nzType="primary" class="rounded-1 me-0" (click)="saveShift()">Save</button>
  </nz-footer>
</nz-layout>


<!-- Tag start -->



<!-- Tag End -->