import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LeadActivitiesDeleteService } from 'src/Service/Sales-Lead-Activities/lead-activity-feature-services/lead-activities-deltete.service';

@Component({
  selector: 'app-lead-activity-delete',
  templateUrl: './lead-activity-delete.component.html',
  styleUrls: ['./lead-activity-delete.component.css'],
})
export class LeadActivityDeleteComponent implements OnInit {

  @Input() selectedGlobalIds: string[];
  @Output() cancel = new EventEmitter<void>();
  @Output() leadActivityDeleted = new EventEmitter<string>();
  isDeleting: boolean;

  constructor(
    private leadActivityDeleteService:LeadActivitiesDeleteService ,
    private toastService:NzMessageService ,
  ) {
    
  }

  ngOnInit(): void {
    console.log('globalIds :',this.selectedGlobalIds);
  }

  deleteLeadActivities() {
    this.isDeleting = true;  // Set to true when deleting starts
    this.leadActivityDeleteService.leadActivitiesDelete(this.selectedGlobalIds).subscribe(res => {
      this.toastService.success('Activities Deleted');
      this.isDeleting = false;  // Set to false when deletion completes
      this.selectedGlobalIds = [];  // Reset selectedGlobalIds
      this.cancel.emit();
      this.leadActivityDeleted.emit();
    });
  }

  Cancel(): void {
    this.cancel.emit();

  }
}
