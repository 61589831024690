<form nz-form [formGroup]="TagsForm" >
  <nz-card nzTitle="Add Daily Log Tag" nzBorderless>
    <div class="content">
      <!-- Title Field -->
      <nz-form-item>
        <label  class="sub-text"  >Title</label><span class="text-danger">*</span>
        <nz-form-control [nzSm]="24" [nzXs]="24" class="ant-form-item-explain-error"   
             [nzErrorTip]="
        nameControl.errors?.['required']
          ? 'Required'
          : nameControl.errors?.['maxlength']
          ?  + getOverCharacters() + (getOverCharacters() > 1 ? ' characters over' : ' character over')
          : null">
          <input nz-input formControlName="name" name="name" id="name" />
        </nz-form-control>
      </nz-form-item>
    </div>
  </nz-card>

  <!-- Form Footer with Buttons -->
  <div class="form-footer" nz-row nzJustify="end">
    <div nz-col>
      <nz-space>
        <button *ngIf="selectedTag" nz-button nzType="default" type="button" (click)="showConfirmDelete()">Delete</button>
        <button nz-button nzType="primary" type="submit" [nzLoading]="isLoadingOne" [disabled]="isLoadingOne" (click)="SaveTagData()" >
          Save
        </button>
      </nz-space>
    </div>
  </div>
</form>