<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form (ngSubmit)="onFilterApply()">
    <div class="row mt-2">
      <div class="col-10 p-0">
        <div class="input-group">
          <nz-select [(ngModel)]="formFieldValues['standardFilter']" name="standardFilter"
            (ngModelChange)="onChangeStandardFilter($event)" nzShowSearch [nzAutoFocus]="true"
            nzPlaceHolder="Standard Filter" >
            <nz-option *ngFor="let filter of filterResponseData"
              [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')" [nzValue]="filter.id" nzCustomContent>
              <div class="option-content">
                <!-- Customize content with icons and text -->
                <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
                <span>{{ filter.name }}</span>
                <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

              </div>
            </nz-option>
          </nz-select>
        </div>
      </div>

      <div class="col-1 p-0 m-0">
        <span (click)="openStandardFilterComponent()" nz-popover [nzPopoverContent]="contentTemp"
          nzPlacement="'bottomRight'" class="text-primary text-dark fw-bold fs-5" nz-icon nzType="ellipsis"
          nzTheme="outline"></span>
        <ng-template #contentTemp>
          <p style="height: 20px">Manage Saved Filters</p>
        </ng-template>
      </div>
      <nz-divider class="p-0 m-0"></nz-divider>
    </div>


    <!-- <div class="row d-flex justify-content-between align-items-center">
      <div class="col-10 p-0">
        <div class="input-group">
          <nz-select>
            <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
            <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
          </nz-select>
        </div>
      </div>
      <div class="col-2 text-end p-0">
        <button nz-button class="border-0">
          <span nz-popover class="text-primary text-dark fw-bold fs-5" nz-icon nzType="ellipsis"
            nzTheme="outline"></span>
        </button>
      </div>
    </div> -->

    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Sent To</label>
        <input [(ngModel)]="formFieldValues['sentTo']" name="sentTo" formControlName="sentTo" type="text" class="form-control formField" />
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Date Sent</label>
        <nz-select [(ngModel)]="formFieldValues['dateSent']" name="dateSent"  nzShowSearch  nzPlaceHolder="Select a person" >
          <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
          <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
          <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">None</label>
        <nz-date-picker ></nz-date-picker>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 p-0">
        <label class="sub-text">Type</label>
        <!-- <nz-tree-select [(ngModel)]="formFieldValues['type']" name="type"   [nzNodes]="nodes" nzShowSearch
        nzAllowClear="false" nzCheckable nzCheckAll="true" nzPlaceHolder="Select Division"></nz-tree-select> -->

      </div>
    </div>

    <div class="col text-end">
      <button nz-button nzType="primary">Clear All</button>
      <button nz-button nzType="default" class="ms-1">apply</button>
    </div>
  </form>
</div>
