<div class="row famly-inn gx-3">
    <div class="col-12">
        <div class="card border-0 mt-0">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Surveys</span>
                    </div>
                    <div class="col text-end">
                    <button  (click)="CustomFieldInfo()" nz-button nzType="primary" class="rounded-1 me-0">New Survey</button>
                    </div>
                </div>
            </div>
            <div class="card-body" style="padding: 16px;">
                  <nz-table nzShowPagination="false" #sortTable [nzData]="listOfData" nzTableLayout="fixed" >
                    <thead>
                      <tr class="custom-header-style" >
                        <th *ngFor="let column of listOfColumn" [nzSortFn]="column.compare" [nzSortPriority]="column.priority">
                          {{ column.title }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of sortTable.data">
                        <td>{{ data.name }}</td>
                        <td>{{ data.chinese }}</td>
                        <td>{{ data.math }}</td>
                        <td>{{ data.english }}</td>
                      </tr>
                    </tbody>
                  </nz-table>
            </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Default Survey Notification</span>
                    </div>
                </div>
            </div>
            <div class="card-body" style="padding: 16px;">
                <div class="row mt-1">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                        <textarea
                        class="form-control formField"
                        style="height: 200px; resize: none;"
                        (input)="autoAdjustTextAreaHeight($event)"
                    ></textarea>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-0 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Survey Questions</span>
                    </div>
                    <div class="col text-end">
                    <button  (click)="NewSurveyQuestions()" nz-button nzType="primary" class="rounded-1 me-0">New Survey Questions</button>
                    </div>
                </div>
            </div>
            <div class="card-body" >
                <div class="row mt-3">
                    <div class=" col-9 d-flex align-items-center">
                        <label nz-checkbox [(ngModel)]="checked"><span class="pira-label">
                            Default all questions to require an answer</span></label>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class=" col-9 d-flex align-items-center">
                        <label nz-checkbox [(ngModel)]="checked1"><span class="pira-label">
                            Default all questions to have N/A option</span></label>
                    </div>
                </div>
                <nz-collapse [nzBordered]="false" class="mt-3">
                    <nz-collapse-panel  [nzHeader]="panels[0].name" [nzActive]="panels[0].active">
                      <nz-table nzShowPagination="false" #sortTable [nzData]="listOfData" nzTableLayout="fixed" #smallTable nzSize="small">
                        <thead>
                          <tr class="custom-header-style" >
                            <th *ngFor="let column of listOfColumn" [nzSortFn]="column.compare" [nzSortPriority]="column.priority">
                              {{ column.title }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let data of sortTable.data">
                            <td>{{ data.name }}</td>
                            <td>{{ data.chinese }}</td>
                            <td>{{ data.math }}</td>
                            <td>{{ data.english }}</td>
                          </tr>
                        </tbody>
                      </nz-table>
                    </nz-collapse-panel>
                    <!-- Collapse Panel Two -->
                    <nz-collapse-panel [nzHeader]="panels[1].name" [nzActive]="panels[1].active">
                      <nz-table nzShowPagination="false" #sortTable [nzData]="listOfData" nzTableLayout="fixed" #smallTable nzSize="small">
                        <thead>
                          <tr class="custom-header-style" >
                            <th *ngFor="let column of listOfColumn" [nzSortFn]="column.compare" [nzSortPriority]="column.priority">
                              {{ column.title }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let data of sortTable.data">
                            <td>{{ data.name }}</td>
                            <td>{{ data.chinese }}</td>
                            <td>{{ data.math }}</td>
                            <td>{{ data.english }}</td>
                          </tr>
                        </tbody>
                      </nz-table>
                    </nz-collapse-panel>
                  </nz-collapse>
            </div>
        </div>
    </div>
</div>




<nz-modal
    nzWidth="50%"
    [(nzVisible)]="newsurveysuestions"
    [nzTitle]="newsurveysuestions1"
    [nzFooter]="newsurveysuestions2"
    (nzOnCancel)="closeNewSurveyQuestions()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '390px', 'overflow-y': 'auto',}"
    [nzStyle]="{ top: '15px' }"
>
    <ng-template #newsurveysuestions1>
        <div class="row p-0 m-0">
            <h1 class="p-0 m-0 fw-medium">Survey Question</h1>
        </div>
    </ng-template>
    <div class="content famly-inn" *nzModalContent>
        <div class="card border-0">
            <div class="card-head border-bottom">
                <div class="row mb-1 p-2 d-flex align-items-center">
                    <div class="col">
                        <span class="fw-medium fs-6 ms-2">Question Details</span>
                    </div>
                </div>
            </div>
            <div class="card-body  famly-inn">
                <div class="row">
                    <div class="col">
                        <label class="sub-text">
                            Question
                            <span class="text-danger fw-bolder">*</span>
                        </label>
                        <input formControlName="name" type="text" class="form-control formField">
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="sub-text">
                            Report Label
                            <i
                            class="bi bi-info-circle-fill text-secondary"
                            nz-popover
                            [nzPopoverContent]="JobTypes"
                            nzPopoverPlacement="top"></i>
                            <ng-template #JobTypes>
                              <div class="rounded-2">
                                <p style="height: 60px; width: 210px;font-size: 13px;">
                                    Reporting labels are a shortened version of the question used for quick reporting and reviews on your website.
                                </p>
                              </div>
                            </ng-template>
                        </label>
                        <input formControlName="name" type="text" class="form-control formField">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <label class="sub-text me-2">Answer Format</label>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <nz-select
                                    nzShowSearch
                                    nzAllowClear
                                    [(ngModel)]="selectedValue"
                                >
                                    <nz-option
                                        nzLabel="Jack"
                                        nzValue="jack"
                                    ></nz-option>
                                    <nz-option
                                        nzLabel="Lucy"
                                        nzValue="lucy"
                                    ></nz-option>
                                    <nz-option
                                        nzLabel="Tom"
                                        nzValue="tom"
                                    ></nz-option>
                                </nz-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row gx-0 border-bottom pb-5">
                    <div class="col-10">
                        <label class="sub-text">Tags</label>
                        <nz-select  formControlName="createJobGroupParameterRequests"  [nzSize]="size" nzMode="multiple">
                            <nz-option *ngFor="let option  of listOfOption" ></nz-option>
                        </nz-select>
                    </div>
                    <div class="col-1">
                          <a style="margin-top: 28px;" (click)="showDialog1()"  nz-button nzType="link" nzBlock>Add</a>
                    </div>
                    <div class="col-1">
                          <a style="margin-top: 28px;" (click)="showDialog1()"  nz-button nzType="link" nzBlock>Edit</a>
                    </div>
                     <!-- nz-zorror -->
                     <nz-modal
                     nzWidth="40vw"
                     [(nzVisible)]="visible1"
                     [nzTitle]="Dialog1"
                     [nzFooter]="modalFooter2"
                     (nzOnCancel)="handleCancel1()"
                     [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '25vw', 'overflow-y': 'auto', }"
                     [nzStyle]="{ top: '15px' }">
                       <ng-template #Dialog1>
                         <div class="row p-0 m-0">
                           <h1 class="p-0 m-0 fw-medium">
                            Add Survey Question Tag
                           </h1>
                         </div>
                       </ng-template>
                   
                       <div class="content" *nzModalContent>
                               <div class="card border-0 famly-inn">
                                   <div class="card-head border-bottom">
                                       <div class="row mb-0 p-2 d-flex align-items-center">
                                         <div class="col">
                                           <span class="fw-medium fs-6 ms-2">Add Survey Question Tag</span>
                                         </div>
                                       </div>
                                     </div>
                                   <div class="card-body">
                                       <div class="row">
                                           <div class="col">
                                               <label class="sub-text">
                                                   Title
                                                   <span class="text-danger fw-bolder">*</span>
                                               </label>
                                               <input
                                                   type="text"
                                                   class=" form-control formField">
                                           </div>
                                       </div>
                                   </div>
                               </div>
                        </div>
                       <ng-template #modalFooter2>
                         <div class="align-items-center">
                            <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
                         </div>
                       </ng-template>
                    </nz-modal>
                   
                </div> 
                <div class="row mt-3 mb-3">
                    <div class=" col-9 d-flex align-items-center">
                        <label nz-checkbox [(ngModel)]="checked2"><span class="pira-label">Active</span></label>
                        <i
                        class="bi bi-info-circle-fill text-secondary"
                        nz-popover
                        [nzPopoverContent]="Active"
                        nzPopoverPlacement="bottomRight"></i>
                        <ng-template #Active>
                          <div class="rounded-2">
                            <p style="height: 60px; width: 210px;font-size: 13px;">
                                Marking a question inactive will take it off the list of possible questions on the survey creation page.
                            </p>
                          </div>
                        </ng-template>
                    </div>
                </div>
            </div>
         
        </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-1 p-2 d-flex align-items-center">
                  <div class="col">
                    <span class="fw-medium fs-6 ms-2">Owner Preview</span>
                  </div>
        
                </div>
              </div>
              <div class="card-body mt-0">
                <div class="row">
                    <label class="sub-text">Response</label>
                </div>
                  <div class="row mt-0 border-bottom pb-5">
                      <div class="col">
                      <nz-rate [ngModel]="0"></nz-rate>
                  </div>
                  </div>
              </div>
      </div>
        <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
                <div class="row mb-1 p-2 d-flex align-items-center">
                  <div class="col">
                    <span class="fw-medium fs-6 ms-2">Review Preview</span>
                  </div>
        
                </div>
              </div>
              <div class="card-body mt-0">
                <div class="row">
                    <label class="sub-text">Response</label>
                </div>
                  <div class="row mt-0 border-bottom pb-5">
                      <div class="col">
                      <nz-rate [ngModel]="0"></nz-rate>
                  </div>
                  </div>
              </div>
      </div>
    </div>
    <ng-template #newsurveysuestions2>
        <div class="mt-2 align-items-center">
            <button nz-button nzType="primary" class="rounded-1 me-0">Save & New</button>
                <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
        </div>
      </ng-template>
</nz-modal>


    
<nz-modal
nzWidth="50%"
[(nzVisible)]="customfieldinfo"
[nzTitle]="modalTitle1"
[nzFooter]="modalFooter10"
(nzOnCancel)="handleCancel2()"
[nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '400px', 'overflow-y': 'auto',}"
[nzStyle]="{ top: '15px' }">
  <ng-template #modalTitle1>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0 fw-medium">Lead Opportunity Custom Field
      </h1>
    </div>
  </ng-template>

  <div class="content" *nzModalContent>
      <div class="card border-0">
          <div class="card-head border-bottom">
              <div class="row mb-1 p-2 d-flex align-items-center">
                <div class="col">
                  <span class="fw-medium fs-6 ms-2"> Custom Field Info</span>
                </div>
      
              </div>
            </div>
                    <div class="card-body">
                      <div class="row">
                          <div class="col">
                          <label class="sub-text">
                            Label
                              <span class="text-danger fw-bolder">*</span>
                          </label>
                          <input
                          formControlName="name"
                          type="text"
                          class="form-control formField">
                      </div>
                      </div>
                      <div class="row">
                          <div class="col">
                          <label class="sub-text">
                              Data Type
                              <span class="text-danger fw-bolder">*</span>
                          </label>
                          <nz-select nzShowSearch formControlName="jobInformationTypeParameterId">
                              <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                              <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                              <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                            </nz-select>
                            </div>
                      </div>
                      <div class="row">
                          <div class="col">
                          <label class="sub-text">
                              Tool Tip Text
                          </label>
                          <textarea
                          nz-input [nzAutosize]="{ minRows: 2, maxRows: 6 }"
                          formControlName="lotInformation"
                          class="form-control formField"
                          style="height: 54px; resize: none;"
                        ></textarea>
                              </div>
                      </div>
                      <div class="row mt-2">
                          <div class=" col-9 d-flex align-items-center">
                      <label class="checkbox bounce">
                          <input type="checkbox" id="exampleCheck8">
                      <svg viewBox="0 0 21 21" fill="#fff">
                          <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                      </svg>
                          </label>
                      <label for="exampleCheck8" class="pira-label ms-2 me-2">Required?
                      </label>
                          </div>
                      </div>
                      <div class="row mt-2">
                          <div class=" col-9 d-flex align-items-center">
                      <label class="checkbox bounce">
                          <input type="checkbox" id="exampleCheck9">
                      <svg viewBox="0 0 21 21" fill="#fff">
                          <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                      </svg>
                          </label>
                      <label for="exampleCheck9" class="pira-label ms-2 me-2">
                          Include In My Filters
                      </label>
                          </div>
                      </div>
                      <div class="row mt-3" style="margin-bottom: 30px;">
                          <div class="col-12">
                              <label class="sub-text">Display Order 
                                  <span class="text-danger fw-bolder">*</span>
                              </label>
                              <nz-input-number
                              [nzMin]="1"
                              [nzMax]="10"
                              [nzStep]="1"
                              type="number" 
                            ></nz-input-number>
                          </div>
                      </div>
                    </div>
    </div>
      <div class="card border-0 mt-3">
          <div class="card-head border-bottom">
              <div class="row mb-1 p-2 d-flex align-items-center">
                <div class="col">
                  <span class="fw-medium fs-6 ms-2">Owner Preview</span>
                </div>
      
              </div>
            </div>
            <div class="card-body">
                <div class="row mt-3 border-bottom pb-5">
                    <div class="col">
                    <label class="sub-text">
                        Response
                        <span class="text-danger fw-bolder">*</span>
                    </label>
                    <nz-rate [ngModel]="0"></nz-rate>
                </div>
                </div>

            </div>
    </div>
      <div class="card border-0 mt-3">
          <div class="card-head border-bottom">
              <div class="row mb-1 p-2 d-flex align-items-center">
                <div class="col">
                  <span class="fw-medium fs-6 ms-2">Review Preview</span>
                </div>
      
              </div>
            </div>
            <div class="card-body">
                <div class="row mt-3">
                    <div class="col">
                    <label class="sub-text">
                        Response
                        <span class="text-danger fw-bolder">*</span>
                    </label>
                    <nz-rate [ngModel]="0"></nz-rate>
                </div>
                </div>

            </div>
    </div>
</div>
  <ng-template #modalFooter10>
      <div class="mt-2 align-items-center">
        <ng-template #modalFooter2>
            <div class="mt-2 align-items-center">
                <button nz-button nzType="primary" class="rounded-1 me-0">Save & New</button>
                <button nz-button nzType="primary" class="rounded-1 me-0">Save</button>
            </div>
        </ng-template>
      </div>

    </ng-template>
</nz-modal>