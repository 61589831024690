<div class="row mt-3">
    <div class="col-12">
        <div class="card shadow">
            <div class="demo card-header py-3 ">Edit Cost Code Link</div>
            <div class="card-body ">
                <div class="row mt-3">
                    <div class="col">
                        <span class="demo">BuilderTrend Cost Code</span>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col">
                        <span class="mt-3">01.00 - Architectural Plans</span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <div class="d-flex align-items-center">
                            <span class="demo">
                                Xero Products/Services
                            </span>
                            <div style="width: 240px; height: 38px;" class="dropdown ml-2">
                                <input
                                    type="text"
                                    [value]="selectedOption5"
                                    (click)="toggleDropdown5()"
                                >
                                <i
                                    class="dropdown-icon fa fa-chevron-down"
                                    [ngClass]="{ 'open': isOpen5 }"
                                    (click)="toggleDropdown5()"
                                ></i>
                                <div class="option" *ngIf="isOpen5" [@dropdownAnimation]="isOpen5 ? 'open' : 'closed'">
                                    <div
                                        *ngFor="let option of options5"
                                        (click)="selectOption5(option)"
                                        [ngClass]="{
                                            'special-hover': option === selectedOption5,
                                            'normal-hover': true
                                        }"
                                    >
                                        {{ option }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
