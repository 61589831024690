   <!-- modal form modal  -->
<nz-modal
nzWidth="70%"
[(nzVisible)]="isVisible"
[nzTitle]="modalTitle"
[nzFooter]="modalFooter"
[nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '540px', 'overflow-y': 'auto' }"
[nzStyle]="{ top: '15px' }"
(nzOnCancel)="handleCancel()">
  <ng-template #modalTitle>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0 fw-medium">
        Add Internal User
      </h1>
    </div>
  </ng-template>

  <div class="content" *nzModalContent>
             <nz-card
                nzTitle="Invite Internal Users to Zircon Homes T/A Xircon Homes">
                <p class="fw-medium">Job
                  Access</p>

                <div nz-row>
                  <div nz-col nzSpan="12">
                    <div nz-col><label nzChecked="true" nz-checkbox nzValue="D">Grant all users
                        access to all existing jobs and templates <i
                          class="bi bi-info-circle-fill text-secondary"
                          nz-popover
                          nzPopoverContent="Includes access to all closed jobs"></i>
                      </label></div>
                  </div>
                  <div nz-col>
                    <div nz-col><label nz-checkbox nzChecked="true" nzValue="E">Grant all users
                        automatic access to new jobs and templates</label></div>
                  </div>
                </div>
                <nz-divider></nz-divider>

                <div nz-row [nzGutter]="24">
                  <div nz-col class="gutter-row" [nzSpan]="1">
                    <nz-form-item>
                      <label class="cityDropdown" ># </label>
                        <nz-form-control>
                        </nz-form-control>
                    </nz-form-item>
                    <div class="inner-box">
                    
                    </div>
                  </div>
                  <div nz-col class="gutter-row" [nzSpan]="5">
                    <nz-form-item>
                      <label class="cityDropdown" >First Name <span class="text-danger">*</span></label>
                        <!-- <nz-form-control>
                          <div class="form-group mb-2">
                           
                            <input type="text" autocomplete="off"
                              class="form-control formField"
                              autocomplete="off" id="state"
                              name="state"
                              
                            >
                          </div>
                        </nz-form-control> -->
                    </nz-form-item>
                    <div class="inner-box">
                    
                    </div>
                  </div>
                  <div nz-col class="gutter-row" [nzSpan]="5">
                    <nz-form-item>
                      <label class="cityDropdown">Last Name <span class="text-danger">*</span></label>
                      <!-- <nz-form-control>
                        <div class="form-group mb-2">
                         
                          <input type="text" autocomplete="off"
                            class="form-control formField"
                            autocomplete="off" id="state"
                            name="state"
                            
                          >
                        </div>
                      </nz-form-control> -->
                    </nz-form-item>
                    <div class="inner-box">
                      <!-- Content for the third column -->
                    </div>
                  </div>
                  <div nz-col class="gutter-row" [nzSpan]="5">
                    <nz-form-item>
                      <label class="cityDropdown">Email <span class="text-danger">*</span></label>
                      <!-- <nz-form-control>
                        <div class="form-group mb-2">
                         
                          <input type="text" autocomplete="off"
                            class="form-control formField"
                            autocomplete="off" id="state"
                            name="state"
                            
                          >
                        </div>
                      </nz-form-control> -->
                    </nz-form-item>
                    <div class="inner-box">
                      <!-- Content for the fourth column -->
                    </div>
                  </div>
                  <div nz-col class="gutter-row" [nzSpan]="5">
                    <label class="cityDropdown">Role <span class="text-danger">*</span></label>
                    <nz-form-item>
                      <!-- <nz-form-control>
                        <div class="form-group">
                         
                          <nz-select nzPlaceHolder="--Choose Role--"
                          nzShowSearch nz-input
                          >
                          <nz-option *ngFor="let role of roles" [nzLabel]="role.name" [nzValue]="role.id"></nz-option>
                        </nz-select>
                        </div>
                      </nz-form-control> -->
                    </nz-form-item>
                    <div class="inner-box">
                      <!-- Content for the fifth column -->
                    </div>
                  </div>
                </div>


                <div nz-row [nzGutter]="32" *ngFor="let item of formItems; let i = index">
                  <div nz-col class="gutter-row" [nzSpan]="1">
                    <label class="cityDropdown" >{{ i + 1 }}</label>
                    <nz-form-item>
                        <nz-form-control>
                        </nz-form-control>
                    </nz-form-item>
                    <div class="inner-box">
                    
                    </div>
                  </div>
                  <div nz-col class="gutter-row" [nzSpan]="5">
                    <nz-form-item>
                        <nz-form-control>
                          <div class="form-group mb-2">
                           
                            <input type="text" autocomplete="off"
                              class="form-control formField"
                              autocomplete="off" id="state"
                              name="state"
                              
                            >
                          </div>
                        </nz-form-control>
                    </nz-form-item>
                    <div class="inner-box">
                    
                    </div>
                  </div>
                  <div nz-col class="gutter-row" [nzSpan]="5">
                    <nz-form-item>
                      <nz-form-control>
                        <div class="form-group mb-2">
                         
                          <input type="text" autocomplete="off"
                            class="form-control formField"
                            autocomplete="off" id="state"
                            name="state"
                            
                          >
                        </div>
                      </nz-form-control>
                    </nz-form-item>
                    <div class="inner-box">
                      <!-- Content for the third column -->
                    </div>
                  </div>
                  <div nz-col class="gutter-row" [nzSpan]="5">
                    <nz-form-item>
                      <nz-form-control>
                        <div class="form-group mb-2">
                         
                          <input type="text" autocomplete="off"
                            class="form-control formField"
                            autocomplete="off" id="state"
                            name="state"
                            
                          >
                        </div>
                      </nz-form-control>
                    </nz-form-item>
                    <div class="inner-box">
                      <!-- Content for the fourth column -->
                    </div>
                  </div>
                  <div nz-col class="gutter-row" [nzSpan]="5">
                    <nz-form-item>
                      <nz-form-control>
                        <div class="form-group">
                         
                          <nz-select 
                          nzShowSearch nz-input
                          >
                          <nz-option *ngFor="let role of roles" [nzLabel]="role.name" [nzValue]="role.id"></nz-option>
                        </nz-select>
                        </div>
                      </nz-form-control>
                    </nz-form-item>
                    <div class="inner-box">
                      <!-- Content for the fifth column -->
                    </div>
                  </div>
                  <div nz-col class="gutter-row" [nzSpan]="1">
                    <nz-form-item>
                      <nz-form-control>
                        <div class="form-group">
                          <div class="trash-icon"  *ngIf="i > 0">
                            <i class="fa fa-trash  animated" (click)="removeRow(i)" id="trash-icon"></i>
                          </div>
                        </div>
                      </nz-form-control>
                    </nz-form-item>
                    <div class="inner-box">
                      <!-- Content for the fifth column -->
                    </div>
                  </div>
                </div>

                 <button  nz-button class="rounded-1 me-0" nzType="primary" (click)="addRow()">Add Another</button>

               
                




              </nz-card>

            </div>

            <ng-template #modalFooter>
              <!-- <div class="row d-flex justify-content-center align-items-center">
            <div class="col-6 text-start">
              
            </div> -->
              <div class="mt-2 align-items-center">
                <button nz-button nzType="default" class="rounded-1 me-0"
                 >Delete</button>
                <button nz-button nzType="primary" class="rounded-1 me-0"
                >Save & New</button>
                <button nz-button nzType="primary" class="rounded-1 me-0"
                >Save</button>
                <button nz-button nzType="primary" class="rounded-1 me-0"
                >Save &
                  Close</button>
        
              </div>
              <!-- </div> -->
        
            </ng-template>
        
        </nz-modal>

        <app-loading-indicator></app-loading-indicator>
