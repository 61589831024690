
<nz-modal nzWidth="95%" [(nzVisible)]="allowancevisible" [nzTitle]="Cost12" [nzFooter]="surveys2" (nzOnCancel)="close()"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '80vh', 'overflow-y': 'auto'}"
  [nzStyle]="{ top: '18px' }" [nzFooter]="allowance">

  <ng-template #Cost12>
    <span class="text-secondary small">ZH-206 Lot 1607 (71) Kingdom Blivd Weir Views 3338</span>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0 fw-small">Allowance</h2>
    </div>
  </ng-template>
  <div class="cantent" *nzModalContent>
    <nz-card nzTitle="Allownce Information" class="mb-3">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <!-- First Field -->
            <div class="col-4">
              <label class="cityDropdown">Title</label>
              <span class="text-danger fw-bold">*</span>
              <input [(ngModel)]="newAllowanceInfomationForm['title']" name="title" type="text" class="form-control formField">
            </div>
            <!-- Second Field -->
            <div class="col-4">
              <label class="cityDropdown" >Selection</label>
              <div class="input-group">
                <nz-tree-select 
                [(ngModel)]="newAllowanceInfomationForm['selectionInformationId']" name="selectionInformationId"
                style="width: 100%"
                nzShowSearch
                nzAllowClear="false"
                nzCheckable 
                nzCheckAll="true"
                [nzNodes]="selectionNodes"
                nzPlaceHolder="">
                </nz-tree-select>
              </div>
            </div>
          </div>
      <div class="row">
          <div class="col-6 mt-2">
            <label class="cityDropdown">Notes</label>
            <textarea
            [(ngModel)]="newAllowanceInfomationForm['notes']" name="notes"
            class="form-control formField"
        nz-input
        [nzAutosize]="{ minRows: 2, maxRows: 8 }"
      ></textarea>
          </div>
        </div>
      </div>
    </div>
<!-- Sencond Card -->

    </nz-card>
    <nz-card nzTitle="Allowance Amount">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <nz-button-group>
                <button [(ngModel)]="newAllowanceInfomationForm['isFlatFee']" name="isFlatFee" nz-button nzType="default" (click)="confirmSwitchToFlatFeeAllowance()">Flat Fee</button>
                <button [(ngModel)]="newAllowanceInfomationForm['isLineItems']" name="isLineItems" nz-button nzType="default" (click)="showRow()">Line Item</button>
              </nz-button-group>
              <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="Daily2"
                      nzPopoverPlacement="right"></i>
                    <ng-template #Daily2>
                      <div class="rounded-2">
                        <div class="famly-inn" style="width: 210px; font-size: 13px; line-height: 25px;">
                          Selecting <strong> Flat Fee</strong>will allow you to enter one amount for the entire allowance.
                          <div class="row mt-2">
                            <div class="col-12">
                              Selecting <strong>Line Items</strong> will allow you to enter multiple line items broken out
                              by cost codes.
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
            </div>
          </div>
          <!-- Flate Fee Work -->
          <div class="row" *ngIf="isRowVisible1">
            <div class="col-4 mt-3">
              <label class="cityDropdown">Builder Cost</label>
              <nz-input-group nzAddOnBefore="$">
                <input type="text" nz-input placeholder="0.00" class=" form-control formField" />
              </nz-input-group>
            </div>
            <div class="col-4 mt-3">
              <label class="cityDropdown">Allowance</label>
              <nz-input-group nzAddOnBefore="$">
                <input type="text" nz-input placeholder="0.00" class=" form-control formField" />
              </nz-input-group>
            </div>
          </div>
          <!--Line Item Work -->

          <div class="row" *ngIf="isRowVisible">
            <div class="col-12 mt-3">
              <!-- <nz-table #middleTable nzSize="middle" [nzData]="data">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Address</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of middleTable.data">
                    <td>{{ data.name }}</td>
                    <td>{{ data.age }}</td>
                    <td>{{ data.address }}</td>
                  </tr>
                </tbody>
              </nz-table> -->
              <div>
                <nz-table [nzFooter]="customfoter" nzFooter="Footer" [nzBordered]="false" nzSize="small" #editRowTable
                nzBordered nzShowPagination="false" [nzScroll]="{ x: '1625px'}">
                <thead style="background-color: #f1f4fa;border-bottom: #ccc;">
                  <tr>
                    <!-- <th nzLeft nzWidth="6%" class="sub-text1"><label nz-checkbox></label></th> -->
                    <th nzLeft nzWidth="7%" class="sub-text1"><span
                        style="align-items: center; position: sticky;"><label nz-checkbox></label></span></th>
                    <th nzLeft nzWidth="55%" class="sub-text1">Items</th>
                    <th nzWidth="55%" class="sub-text1">Description</th>
                    <th nzWidth="20%" class="sub-text1 text-end">Quantity</th>
                    <th nzWidth="17%" class="sub-text1 text-start">Unit</th>
                    <th nzWidth="25%" class="sub-text1 text-end">Unit Cost</th>
                    <th nzWidth="30%" class="sub-text1 text-start">Cost Type</th>
                    <th nzWidth="23%" class="sub-text1 text-end">Builder Cost</th>
                    <th nzWidth="26%" class="sub-text1">Markup</th>
                    <th nzWidth="25%" class="sub-text1  text-end">Owner Price</th>
                    <th nzWidth="17%" class="sub-text1  text-end">Margin</th>
                    <th nzWidth="17%" class="sub-text1  text-end">Profit</th>
                    <th nzRight nzWidth="18%" class="sub-text1"></th>
                  </tr>
                </thead>
                <tr class="border-top hoverable">
                  <td class="border" [colSpan]="9">
                    <button (click)="addNewRow()" nz-button nzType="link" class="text-primary fs-6">
                      <span class="text-primary me-2 fs-5" nz-icon nzType="plus-circle" nzTheme="fill"></span>
                      <span class="fs-6">Item</span>
                    </button>
                    <!-- Validation message for adding new row -->
                  </td>
                </tr>
                <tbody *ngFor="let item of items; let i=index">
                  <tr class="ms-2 bard" style="background-color: #e6f6ff;border: solid 2px #ccc">
                    <td nzLeft style="background-color: #e6f6ff;">
                      <label nz-checkbox></label>
                    </td>
                    <td nzLeft style="width: 40%; background-color: #e6f6ff;">
                      <label class="sub-text">Title</label>
                      <input [(ngModel)]="item.title" name="title_{{i}}" type="text" class="formField" required>

                    </td>
                    <td style="width: 17%;">
                      <label for="" class="sub-text">Description</label>
                      <textarea [(ngModel)]="item.description" name="description_{{i}}" style="border-radius: 5px;"
                        rows="2" class="ant-input1 formField" nz-input></textarea>
                    </td>
                    <td>
                      <label for="" class="sub-text">Quantity</label>
                      <nz-input-number [(ngModel)]="item.quantity" name="quantity_{{i}}"
                        style="width: 100%;border-radius: 4px;"></nz-input-number>
                    </td>
                    <td>
                      <label for="" class="sub-text">Unit</label>
                      <input [(ngModel)]="item.unit" name="unit_{{i}}" type="text" nz-input class=" formField" />
                    </td>
                    <td>
                      <label for="" class="sub-text">Unit Cost</label>
                      <nz-input-group nzAddOnBefore="$" style="width: 100%;">
                        <input [(ngModel)]="item.unitCost" name="unitCost_{{i}}" type="text" nz-input
                          class=" formField" />

                      </nz-input-group>
                    </td>
                    <td style="width: 17%;">
                      <label for="" class="sub-text">Cost Type</label>
                      <nz-tree-select [(ngModel)]="item.createCostTypeItemParameterRequests" name="costTypeId_{{i}}" style="width: 100%"
                          [nzNodes]="proposalStatusNodes" nzShowSearch nzAllowClear="false" nzCheckable
                          nzCheckAll="true" nzPlaceHolder="Select Division">
                      </nz-tree-select>
                  </td>
                  
                    <td>
                      <div class="row">
                        <label class="sub-text text-end">Builder Cost</label>
                      </div>
                      <div class="row">
                        <span class="fw-bold mb-2 text-end">{{ item.builderCost }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="row">
                        <label class="sub-text">Markup</label>
                      </div>
                      <div class="row gx-0">
                        <div class="col-8">
                            <nz-input-group *ngIf="item.markupId === '$'" >
                              <input
                                nz-input
                                class="formField"
                                appDollarFormatter
                                [(ngModel)]="item.markupValue" name="markupValue_{{i}}"
                                    
                           />
                            </nz-input-group>
                            <nz-input-group *ngIf="item.markupId === '%'"  >
                              <input
                                nz-input
                                class="formField"
                                appPercentage
                                [(ngModel)]="item.markupValue" name="markupValue_{{i}}"
                                    
                           />
                            </nz-input-group>
                            <nz-input-group *ngIf="item.markupId === '$/Unit'"  >
                              <input
                                nz-input
                                class="formField"
                                appDollarFormatter
                                [(ngModel)]="item.markupValue" name="markupValue_{{i}}"
                                    
                           />
                            </nz-input-group>
                        </div>
                        <div class="col-4">
                          <nz-select 
                          [(ngModel)]="item.markupId" 
                          name="markupId_{{i}}" 
                          class="custom-select" 
                          nzShowSearch
                          (ngModelChange)="onMarkupIdChange($event)"
                        >
                          <nz-option nzLabel="%" nzValue="%"></nz-option>
                          <nz-option nzLabel="$" nzValue="$"></nz-option>
                          <nz-option nzLabel="$/Unit" nzValue="$/Unit"></nz-option>
                          <nz-option nzLabel="--" nzValue="--"></nz-option>
                        </nz-select>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="row">
                        <label class="sub-text">Owner Price</label>
                      </div>
                      <div class="row">
                        <!-- <span class="fw-bold mb-2 text-end">{{ tableFormFieldValues['ownerPrice'] }}</span> -->
                        <span class="ant-input-wrapper ant-input-group"><span
                            class="ant-input-group-addon">$</span><input class="ant-input ant-input-disabled"
                            disabled="" data-testid="lineItems[0].ownerPrice" id="lineItems[0].ownerPrice" type="text"
                            value="0.00"></span>
                        <!--                       
                                <nz-input-group nzDisabled nzAddOnBefore="$" style="width: 100%;">
                                  <input [(ngModel)]="tableFormFieldValues['ownerPrice']" name="ownerPrice" type="text" nz-input class="formField" (ngModelChange)="calculateBuilderCost()" />
                              </nz-input-group> -->
                      </div>
                    </td>
                    <td>
                      <div class="row">
                        <label class="sub-text text-end">Margin</label>
                      </div>
                      <div class="row">
                        <span class="fw-bold mb-2 text-end" [(ngModel)]="item.margin" name="margin_{{i}}">{{
                          item.margin}}%</span>
                      </div>
                    </td>
                    <td>
                      <div class="row">
                        <label class="sub-text text-end">Profit</label>
                      </div>
                      <div class="row">
                        <!-- <span class="fw-bold mb-2 text-end" [(ngModel)]="items[i]['profit']" name="profit">{{ items[i]['profit'] }}</span> -->
                        <span class="fw-bold mb-2 text-end" [(ngModel)]="item.profit" name="profit{{i}}">{{
                          item.profit}}</span>

                      </div>
                    </td>
                    <td nzRight style="background-color: #e6f6ff;">
                      <span (click)="deleteRow(i)" nz-icon nzType="delete"
                        class="text-primary text-center fs-4 k-cursor-pointer" nzTheme="fill"></span>
                    </td>
                  </tr>
                  <tr class="ms-2 bard" style="background-color: #e6f6ff;border: solid 2px #ccc">
                    <td nzLeft style="width: 40%;background-color: #e6f6ff;">

                    </td>
                    <td nzLeft style="width: 40%; background-color: #e6f6ff;">
                      <label class="sub-text">Cost Code <span class="fw-bolder text-danger">*</span> </label>
                      <nz-select nzShowSearch nzAutoFocus="true" nzPlaceHolder="Select Lead"
                        [(ngModel)]="item.costCodeId" name="costCodeId_{{i}}">
                        <nz-option *ngFor="let option of leadOptions" [nzLabel]="option.title"
                          [nzValue]="option.id"></nz-option>
                      </nz-select>
                      <div *ngIf="showValidationMessage && !items[i]?.title" class="text-danger">Required</div>
                    </td>

                    
                    <td style="width: 17%;">
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                    <td style="width: 17%;">
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                    <td nzRight style="background-color: #e6f6ff;">
                    </td>
                  </tr>
                </tbody>
                <ng-template #customfoter>
                  <tfoot>
                    <tr>
                      <td class="sub-text fs-6">Total</td>
                    </tr>
                  </tfoot>
                </ng-template>
              </nz-table>
                <div nz-row class="border" style="background-color: #f1f4fa;">
                  <div nz-col nzSpan="8" class=" p-2">
                    <span class="fw-bold ms-5">Total</span>
                  </div>
                  <div nzFlex nz-col nzSpan="8" class=" p-2 text-end" style="background-color: #f1f4fa;">
                    <span class="fw-bold">$0.00</span>
                  </div>
                  <div nzFlex nz-col nzSpan="8" class=" p-2 text-center" style="background-color: #f1f4fa;">
                    <span class="fw-bold">$0.00</span>
                  </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>

    </nz-card>
  </div>


  <ng-template #surveys2>
  </ng-template>
</nz-modal>

<ng-template #allowance>

    <button nz-button disabled nzType="primary">Save&New</button> 
    <button nz-button nzType="primary" [disabled]="!(newAllowanceInfomationForm.title )" (click)="saveMethodAllowance()">Save</button> 
    <button nz-button disabled nzType="primary">Save&Close</button>
</ng-template>