
<!-- standard view -->

<nz-modal 
  nzWidth="60%"
  [(nzVisible)]="managesavedviews"
  [nzTitle]="printModalTitles"
  [nzFooter]="printmodalFooters"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '540px', 'overflow-y': 'auto' }"
  [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="handleManageSavedViews()">
    <ng-template #printModalTitles>
      <div class="row p-0 m-0">
        <h3 class="p-0 m-0 ">
          Manage Saved Views
        </h3>
      </div>WWWW
    </ng-template>

    <div class="content content-set-background " *nzModalContent>
     
      <div class="row famly-inn gx-3">
        <div class="col-12">
            <div class="card border-0 mt-0">
                <div class="card-head border-bottom">
                    <div class="row mb-0 p-2 d-flex align-items-center">
                        <div class="col">
                            <span class="fw-bold fs-6 ms-2">Manage Saved Views</span>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <p class="">Manage your grid views and select your default view for the current page.</p>
                  </div>
                  <div class="card border-0" style="padding: 10px; background-color: #f1f4fa;">
                    <div class="row">
                      <div class="col-12">
                    <div class="row">
                      <span class="fw-bold fs-6">Update Current View</span>
                    </div>
                    <div nz-row class="mt-2">
                      <p class="">Change the current columns displayed on the page. This will not affect your currently selected saved view settings.</p>
                    </div>
                  <div class="row">
                   <span class="fw-bold">Columns</span>
                  </div>
                  <div class="row mb-2 p-2 d-flex justify-content-center align-items-center align-content-center">
                    <div class="col-md-10 col-sm-12 ">
                      <nz-tree-select
                      [nzMultiple]="true"
                      [nzCheckStrictly]="true"
                      nzShowSearch="true"
                      nzCheckable="true"
                      [(ngModel)]="selectedColumnNames"
                      [nzNodes]="colunNameoptionView"
                      nzVirtualHeight="250px"
                      nzAllowClear="false"
                      nzPlaceHolder="Select Columns"
                    ></nz-tree-select>
                    </div>
                    <div class="col-md-2 col-sm-12">
                        <button
                        nz-button
                        nzType="primary"
                        class="rounded-1 w-100"
                      >
                        Apply View
                      </button>
                                 
             
                    </div>
                  </div>
                  
                      </div>
                    </div>
                  </div>
                  <form [formGroup]="StandardformGroup">
                  <nz-card  nzTitle="Your Saved Views" nzBorderless="true" style="margin-left: -20px;" [nzExtra]="Addfilteremplate">       
                    <!-- card -->
                        <nz-page-header *ngIf="!isInputVisible && filters.length === 1" style="border: 0.1px solid #dad7d7; margin-right: -22px; margin-left: -3px;">            
                          <nz-page-header-content>
                            <div nz-row>
                                <div nz-col nzSpan="3" style="margin-top: -10px;margin-left: -15px;"><svg fill="#000000" width="100px" height="100px" viewBox="-1.6 -1.6 35.20 35.20" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#000000" stroke-width="0.064" transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.576"> <path d="M17.5 0h-11c-1.104 0-2 0.895-2 2v28c0 1.105 0.896 2 2 2h19c1.105 0 2-0.895 2-2v-20zM25.5 10.829v0.171h-9v-9h0.172zM6.5 30v-28h8v11h11v17h-19z"></path> </g><g id="SVGRepo_iconCarrier"> <path d="M17.5 0h-11c-1.104 0-2 0.895-2 2v28c0 1.105 0.896 2 2 2h19c1.105 0 2-0.895 2-2v-20zM25.5 10.829v0.171h-9v-9h0.172zM6.5 30v-28h8v11h11v17h-19z"></path> </g></svg></div>
                                <div nz-col nzSpan="21">
                                    <span><h4 class="fw-bold mb-0">You have no saved views</h4></span> <br>
                                    <span><p>Saved views let you configure what information is shown on this page.<a class="text-primary fw-bold" (click)="toggleVisibility()">Add New Saved View</a></p></span>
                                </div>
                              </div>
                          </nz-page-header-content>
                        </nz-page-header>
                    <!-- card -->
                  </nz-card>
        
                <!-- ADD FORM  -->
                  <ng-template #Addfilteremplate>
                    <button *ngIf="!isInputVisible"  nz-button nzType="default" class="text-primary border-0"  (click)="toggleVisibility()"><i class="bi bi-plus-circle-fill" style="font-size: 18px;"></i>&nbsp;&nbsp;Add New Saved filter</button>
                  </ng-template>



                  <div class="row mt-1"  *ngIf="isInputVisible" >
                    <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                          <nz-page-header class="headset">
                            <!--title-->
                                <nz-page-header-title>Add New Saved View</nz-page-header-title>
                                <!--extra-->
                                <nz-page-header-extra>
                                  <nz-space  (click)="closeManageSavedViews()">
                                    <a class="bi bi-x-lg"></a>
                                  </nz-space>
                                  
                                  </nz-page-header-extra>
                                  <nz-page-header-content>
                                    <div nz-row>
                                      <div nz-col nzSpan="24">
                                        <label  class="mb-1 fw-bold sub-text">Label</label>
                                        <input formControlName="name" type="text" class="form-control formField"
                                        name="name"
                                       
                                        [ngClass]="{'error': StandardformGroup.get('name')?.invalid &&  StandardformGroup.get('name')?.touched  }">
                                        <div
                                              *ngIf="StandardformGroup.get('name')?.hasError('required') && StandardformGroup.get('name')?.touched"
                                           class="text-danger p-error">Required</div>
                                            <div
                                            *ngIf="StandardformGroup.get('name')?.value.length > 25 && StandardformGroup.get('name')?.touched"
                                               class="text-danger p-error">
                                              {{ StandardformGroup.get('name')?.value.length -
                                          25 }} characters over.
                                           </div>
                                           <div *ngIf="StandardformGroup.get('name')?.hasError('uniqueName')" class="text-danger p-error">
                                            View name must be unique
                                          </div>
                                          
                                      </div>
                                      <div nz-col nzSpan="24">
                                        <label class="mb-1 fw-bold sub-text">Columns</label>
                                      <div formArrayName="createStandardViewParameterRequests">
                                            <nz-tree-select
                                              [nzMultiple]="true"
                                              [nzCheckStrictly]="true"
                                              nzShowSearch="true"
                                              nzCheckable="true"
                                              [(ngModel)]="selectedColumnName" 
                                              [nzNodes]="colunNameoption"
                                              nzVirtualHeight="200px"
                                              nzAllowClear="false"
                                              nzPlaceHolder="Select Columns"
                                              formControlName="columnDisplayName"
                                            ></nz-tree-select>
                                      </div>

                                      <!-- <pre>{{this.StandardformGroup.value | json}}</pre> -->
                                      
                                      </div>
                                      </div><div nz-row class="mt-2">
                                        <div nz-col nzSpan="24">
                                       <label formControlName="isMarkAsPrivate" nz-checkbox >Share this view</label>
                                         
                                        </div>
                                      </div>
                                      <div nz-row>
                                        <div nz-col nzSpan="24" class="mt-2 mb-2">
                                       <label formControlName="isSetAsDefault" nz-checkbox>Set as default</label>
                                        </div>
                                      </div>
                                      <div nz-row class="text-end">
                                        <div nz-col nzSpan="24" class="mt-2 mb-1">
                                           <button nz-button nzType="primary" *ngIf="!isEditFormVisible" (click)="saveStandardView()">Add</button>
                                            <button nz-button nzType="primary" *ngIf="isEditFormVisible" (click)="updateFilter()">Update</button>
                                        </div>
                                      </div>
        
                                  </nz-page-header-content>
                                </nz-page-header>
                        </div>
                    </div>
        
        
                    </div>
                     </div>


                     <nz-page-header class="headset-border mt-2 p-0" nzBorderless="false" *ngFor="let filter of filters.slice(1); let i = index">
                      <!--title-->
                      <nz-page-header-title>
                          <div class="ms-3" nz-row>
                              <div nz-col nzSpan="18">
                                <ng-container *ngIf="filter.isSetAsDefault">
                                  <nz-tag nzColor="#99daf2" class="rounded-5">
                                    <span style="color: #353b99; font-size: 10px;" class="fw-bold">DEFAULT FILTER</span>
                                  </nz-tag>
                                </ng-container>
                                  <div nz-row class=" mb-0" style="margin-top: -7px;">
                                      <span style="font-size: 13px;">{{ filter.name }}</span>
                                  </div>
                                  <div nz-row class=" mb-0">
                                      <span style="opacity: 70%; font-weight: normal; font-size: 13px; margin-top: -10px;">
                                          {{ filter.isMarkAsPrivate ? 'Shared' : 'Private' }}
                                      </span>
                                  </div>
                              </div>
                          </div>
                      </nz-page-header-title>
                  
                  
                                <!--extra-->
                                <nz-page-header-extra class="justify-content-center  align-content-center">
                                 <div nz-row>
                                    <nz-space class="p-3">
                                   <button nz-button class="text-primary border-0 me-2 align-items-center" (click)="showEidtFilterConfirm(filter.id)">    
                                    <span nz-icon nzType="edit" style="font-size: 22px;"  nzTheme="fill" class="text-primary" nz-popover
                                    [nzPopoverContent]="editTemplate"
                                    nzPopoverPlacement="top">
                                    <ng-template #editTemplate>
                                        <div class="rounded-2">
                                          <p style="height: auto; width: auto; ">
                                            Edit
                                          </p>
                                        </div>
                                      </ng-template>    
                                    </span>
                                 </button>
                                        <button  nz-button  class="text-primary  border-0  me-2 align-items-center" (click)="showDeleteFilterConfirm(filter.globalId)" >
                                            <span nz-icon nzType="delete" style="font-size: 22px;" nzTheme="fill" class="text-primary ms-2"  nz-popover
                                        [nzPopoverContent]="deleteTemplate"
                                         nzPopoverPlacement="top">
                                         <ng-template #deleteTemplate>
                                            <div class="rounded-2">
                                              <p style="height: auto; width: auto; ">
                                                Delete
                                              </p>
                                            </div>
                                          </ng-template>
                                        </span>
                                        </button>
                                           <button nz-button nz-dropdown class="text-primary border-0 me-2  align-items-center"  [nzPlacement]="'bottomRight'" [nzTrigger]="'click'" [nzDropdownMenu]="menu2">
                                            <span nz-icon nzType="ellipsis" style="font-size: 22px;"s nzTheme="outline"  class="text-primary ms-2 "  nz-popover
                                            [nzPopoverContent]="moreTemplate"
                                             nzPopoverPlacement="top">
                                            <ng-template #moreTemplate>
                                           <div class="rounded-2">
                                            <p style="height: auto; width: auto; ">
                                               More Action
                                             </p>
                                            </div>
                                            </ng-template>
                                            </span>           
                                          </button>
                                          <nz-dropdown-menu #menu2="nzDropdownMenu">
                                            <ul nz-menu>
                                              <li nz-menu-item [nzDisabled]="filter.isSetAsDefault" (click)="setAsDefaultView(filter.id)">Set as Default View</li>
                                               <li nz-menu-item  *ngIf="!isDuplicateSaveButtonVisible" (click)="EditFilter(filter.id)">Duplicate Save View</li>
                                            </ul>
                                          </nz-dropdown-menu>                      
                                    </nz-space>
                                 </div>
                                </nz-page-header-extra>  
        
        
                         </nz-page-header>

                  <div class="row mt-3">
                        <span class="fw-bold fs-6">Shared Saved Views</span>
                  </div> 
                  <div class="row mt-2">
                    <p class="">These views have been created by other internal users on your account</p>
                    </div>
    
                    <ng-container *ngFor="let filter of filters; let i = index">
                      <ng-container *ngIf="i === 0">
                        <nz-page-header class="headset-border mt-2 p-0" nzBorderless="false">
                          <!--title-->
                          <nz-page-header-title>
                            <div class="ms-3" nz-row>
                              <div nz-col nzSpan="18">
                                <ng-container *ngIf="filter.isSetAsDefault">
                                  <nz-tag nzColor="#99daf2" class="rounded-5">
                                    <span style="color: #353b99; font-size: 10px;" class="fw-bold">DEFAULT FILTER</span>
                                  </nz-tag>
                                </ng-container>
                                <div nz-row class="mb-0" style="margin-top: -7px;">
                                  <span style="font-size: 13px;">{{ filter.name }}</span>
                                  <!-- Replace 'name' with the actual property name in your data -->
                                </div>
                              </div>
                            </div>
                          </nz-page-header-title>
                    
                          <!--extra-->
                          <nz-page-header-extra>
                            <nz-space class="p-3">
                              <button nz-button nz-dropdown class="text-primary border-0" [nzPlacement]="'bottomRight'" [nzTrigger]="'click'" [nzDropdownMenu]="menu3">
                                <span nz-icon nzType="ellipsis" style="font-size: 22px;" nzTheme="outline" class="text-primary ms-2 " nz-popover
                                  [nzPopoverContent]="moreTemplate" nzPopoverPlacement="top">
                                </span>
                              </button>
                              <ng-template #moreTemplate>
                                <div class="rounded-2">
                                 <p style="height: auto; width: auto; ">
                                 More Action
                                  </p>
                                 </div>
                                 </ng-template>
                              <nz-dropdown-menu #menu3="nzDropdownMenu">
                                <ul nz-menu>
                                  <li nz-menu-item [nzDisabled]="filter.isSetAsDefault" (click)="setAsDefaultView(filter.id)">Set as Default View</li>
                                  <li nz-menu-item (click)="EditFilter(filter.id)">Duplicate Save View</li>
                                </ul>
                              </nz-dropdown-menu>
                            </nz-space>
                          </nz-page-header-extra>
                        </nz-page-header>
                      </ng-container>
                    </ng-container>
                    
    
                 

        
                   </form>

                </div>
            </div>
    </div>
    </div>
    
    




    </div>
    <ng-template #printmodalFooters>
    </ng-template>

   

</nz-modal>
<!-- standard view  -->




