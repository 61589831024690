<nz-modal [(nzVisible)]="isVisibleInJobGroup" [nzTitle]="modalTitle" [nzFooter]="modalFooter" [nzBodyStyle]="{
  background: '#f1f4fa',
  padding: '14px',
  maxHeight: '75vh',
  'overflow-y': 'auto',
  'overflow-x': 'hidden',
  }" [nzStyle]="{ top: '15px'}" [nzWidth]="'42%'" (nzOnCancel)="CancelJobJobGroup()">
  <ng-template #modalTitle>
    <div class="row">
      <ng-container *ngIf="onJobGroupEdit === true; else onHeaderGroup">
        <h2 class="p-0 m-0 famly-inn">Edit Job Group</h2>
      </ng-container>
      <ng-template #onHeaderGroup> 
        <h2 class="p-0 m-0 famly-inn">Add Job Group</h2>
      </ng-template>
    </div>
  </ng-template>
  <form [formGroup]="jobGroupForm">
    <div class="content" *nzModalContent>
      <div class="card border-0 mt-0">
        <div class="card-head border-bottom">
          <div class="row pt-2 pb-2">
            <ng-container *ngIf="onJobGroupEdit === true; else onHeaderGroup">
              <h3 class="ms-3 famly-inn">Edit Job Group</h3>
            </ng-container>
            <ng-template #onHeaderGroup> 
              <h3 class="ms-3 famly-inn">Add Job Group</h3>
            </ng-template>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <label class="sub-text">Title<span class="ms-1 text-danger fw-bolder">*</span></label>
              <input 
                #titleInput
                id="inputField" 
                formControlName="name" 
                type="text" 
                class="form-control formField" 
                [ngClass]="{error: jobGroupForm.get('name')?.invalid && jobGroupForm.get('name')?.touched}" 
                (ngModelChange)="onInputChange($event)"
              />
              <div *ngIf="jobGroupForm.get('name').hasError('required') && jobGroupForm.get('name').touched" class="text-danger p-error">
                Required
              </div>
              <div *ngIf="jobGroupForm.get('name').hasError('maxlength') && jobGroupForm.get('name').touched" class="text-danger p-error">
                {{ jobGroupForm.get('name').value.length - 25 }} 
                {{ (jobGroupForm.get('name').value.length - 25) > 1 ? 'characters' : 'character' }} over
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #modalFooter>
    <div nz-row class="p-1 justify-content-end">
      <div nz-col nzSpan="24">
        <nz-space>
          <ng-container *nzSpaceItem>
            <button
              nz-button
              nzType="default"
              class="align-items-center"
              *ngIf="onJobGroupEdit === true"
              [nzLoading]="isDeleteLoading"
               (click)="deleteJobConfirm()"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
            Delete
            </button>
          </ng-container>
          <ng-container *nzSpaceItem>
            <button
              nz-button
              nzType="primary"
              class="align-items-center"
              [nzLoading]="isSave"
              (click)="saveJob()"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
              Save
            </button>
          </ng-container>
          <!-- This Job Group exists. -->
        </nz-space>
      </div>
    </div>
    </ng-template>
  </form>
</nz-modal>














