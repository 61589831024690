import { Component, EventEmitter, Output } from '@angular/core';
import { SelectServeyComponent } from '../select-servey/select-servey.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-new-survey',
  templateUrl: './new-survey.component.html',
  styleUrls: ['./new-survey.component.css']
})
export class NewSurveyComponent {
  constructor(private modal: NzModalService,private fb: FormBuilder,) {}
  @Output() cancel = new EventEmitter<void>();
  newSurvey:FormGroup
  newSurveyshandle=true;
 newSurveysModelCancel(): void {
  this.cancel.emit();
  }
  initializeForm(): void {
    this.newSurvey = this.fb.group({
      emailBody: [null],
      RreleaseDate: [null],
      time: [null], 
  // Grid Baqi Ha 
    });
  }
  inputValue?: string;
  date = null;
  onChange(result: Date): void {
    console.log('onChange: ', result);
  }
  time = new Date();
}
