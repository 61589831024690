import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { AttachmentResponse } from 'src/Models/LeadTags';
import { FileDecumentService } from 'src/Service/FileDocumnet/file-decument.service';
import { ProjectManagementSiteDiariesUploadFilesComponent } from 'src/app/project-management/components/Site-Diaries/project-management-site-diaries-upload-files/project-management-site-diaries-upload-files.component';
import { DocumentCreateFolderComponent } from 'src/app/shared/component/document-create-folder/document-create-folder.component';

@Component({
  selector: 'videos-content',
  templateUrl: './videos-content.component.html',
  styleUrls: ['./videos-content.component.css']
})
export class VideosContentComponent {
  @Output() cancel = new EventEmitter<void>();
  savedId: string;
  data: any;
  visibleCatalogModel = true;

  constructor(private modal:NzModalService,
    private FileDecumentService : FileDecumentService,
    @Inject(NZ_MODAL_DATA) public responseData: any,
  
  ){
    console.log("responseData Noor",responseData);
    
  }




  ngOnInit(): void {
    this.FileDecumentService.currentSavedId.subscribe(savedId => {
      this.savedId = savedId
      console.log('Saved ID in DocumentsDashboardComponent123:', savedId);
    });
  }

  createFolderModelOpen(data: AttachmentResponse): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesUploadFilesComponent,
      nzFooter: null, 
      nzData:{
        responseData: this.savedId
      }
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
  }
  CatalogModelhandle() {
    this.cancel.emit();
  }



  isPdfFile(extension: string): boolean {
    return extension.toLowerCase() === '.pdf';
  }
  
  isExcelFile(extension: string): boolean {
    return extension.toLowerCase() === '.xls' || extension.toLowerCase() === '.xlsx';
  }
  
  isWordFile(extension: string): boolean {
    return extension.toLowerCase() === '.doc' || extension.toLowerCase() === '.docx';
  }
  
  isImageFile(extension: string): boolean {
    return extension.toLowerCase() === '.png' || extension.toLowerCase() === '.jpg' || extension.toLowerCase() === '.jpeg' || extension.toLowerCase() === '.gif';
  }
  isTxtFile(extension: string): boolean {
    return extension.toLowerCase() === '.txt';
  }
  
  getIconType(extension: string): string {
    if (this.isPdfFile(extension)) {
      return 'fa-file-pdf';
    } else if (this.isExcelFile(extension)) {
      return 'fa-file-excel';
    } else if (this.isWordFile(extension)) {
      return 'fa-file-word';
    } else if (this.isImageFile(extension)) {
      return 'fa-file-image';
    } else if (this.isTxtFile(extension)) {
      return 'fa-file-alt'; // Assuming this icon for text files
    } else {
      return 'fa-file';
    }
  }
  

}
