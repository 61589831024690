import { Component } from '@angular/core';

@Component({
  selector: 'app-inner-tab-notes',
  templateUrl: './inner-tab-notes.component.html',
  styleUrls: ['./inner-tab-notes.component.css']
})
export class InnerTabNotesComponent {
  inputValue: string='';
  viewtabs = [1, 2, 3,4];


  ViewingTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'All Notes';
      case 2:
        return 'Internal Notes';
      case 3:
        return 'Sub Notes';
      case 4:
        return 'Owner Notes';
      default:
        return '';
    }
  }


}
