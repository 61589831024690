import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-import-proposals',
  templateUrl: './import-proposals.component.html',
  styleUrls: ['./import-proposals.component.css']
})
export class ImportProposalsComponent implements OnInit{
 
  @Output() cancel = new EventEmitter<void>();
  ImportProposalsVisible = true;
  formFieldValues: any = {};



  ngOnInit(): void {
    
  }



  openCopycActivityTemplateModal(): void {
    this.ImportProposalsVisible = true;
  }
  ActivityTemCancel
    (): void {
    this.cancel.emit();
  }

}
