<div class="row">
  <div class="col-12">
    <div nz-row>
      <div nz-col [nzSpan]="12" [nzPush]="12" class="text-end">
        <button nz-button nz-dropdown [nzDropdownMenu]="menu4" class="me-2 rounded-1" >
          Import
          <span nz-icon nzType="down"></span>
        </button>
        <nz-dropdown-menu #menu4="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item (click)="importSelectionModelOpen()">Import From Selection</li>
          </ul>
        </nz-dropdown-menu>
        <button   (click)="openFilter(PROJECT_MANG_SELEC_HIERARCHY,PAGE_ID)"  nz-button nzType="default" class="me-2" ><i class="me-2 mt-1 bi bi-funnel"></i> Filter (1)</button>
        <button nz-button nzType="primary" class="me-2"  (click)="selectionModelOpen()" >New
          Selection</button>
      </div>
      <div nz-col [nzSpan]="12" [nzPull]="12">
        <div class="row">

          <div class="me-2 col-1 fw-bold mt-2">
            <p>Sort</p>
          </div>
          <div class="col-7" style="margin-left: -25px;">
            <nz-input-group nzCompact>
              <nz-select [ngModel]="'Title'" style="width: 50%;">
                <nz-option [nzLabel]="'Title'" [nzValue]="'Title'"></nz-option>
                <nz-option [nzLabel]="'Company'" [nzValue]="'Company'"></nz-option>
              </nz-select>
              <button nz-button nzType="default"><i class="bi bi-arrow-down-up text-primary"></i></button>
            </nz-input-group>
          </div>
        </div>
      </div>
    </div>
    <nz-card style="width:99%;" class="mt-3">
      <div class="row">
        <div class="col text-start">
          <i class="me-2 bi bi-play-fill" (click)="toggleRowVisibility()" [ngClass]="{'rotate-icon': !isRowVisible}"
            style="border: 6px; border-radius: 20px; background-color: #f1f4fa; padding: 6px; font-size: 9px;"></i><span
            class="text-primary">
            <b>Feature Tile</b> <small class="ms-1 text-secondary">10-interior
              FInishes | Master Bath</small>
          </span>
        </div>
        <div class="col text-end">
          <button nz-button nz-dropdown [nzDropdownMenu]="menu1">
            Add Choice
            <span nz-icon nzType="down"></span>
          </button>
          <nz-dropdown-menu #menu1="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="selectionChoiceModelOpen()">
                <a>New</a>
              </li>
              <li (click)="openchooice()" nz-menu-item>
                <a>New Form Selection</a>
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </div>
      <div class="row" [ngClass]="{'d-none': !isRowVisible}">
        <div class="col-12">
          <div class="border bottom mt-2"></div>
          <div class="row">
            <div class="col mt-1">
              <a nz-button nzType="link">Feature Tiles</a>
              <nz-tag [nzColor]="'cyan'" class="rounded-5 fw-bolder">Select</nz-tag>
              <span class="ms-2">$0.00</span>
              <a nz-dropdown [nzDropdownMenu]="menu" class="ms-3">
                Add
                <span nz-icon nzType="down"></span>
              </a>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu nzSelectable>
                  <li nz-menu-item>None</li>
                  <li nz-menu-item>None</li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </div>
        </div>
      </div>
    </nz-card>
  </div>
</div>

<!-- Add Chooice List  -->

  


<!-- 
         2nd row -->
          <!-- <nz-card style="width:99%;" class="mt-3">
            <div class="row">
              <div class="col text-start">
                <i class="me-2 fs-4 bi bi-play-circle" (click)="toggleRowVisibility()"></i> <span class="text-primary">
                  <b>Feature Tile</b> <small class="ms-1 text-secondary">10-interior FInishes  | Master Bath</small>
               </span>
              </div>
              <div class="col text-end">
                <button nz-button nz-dropdown >
                Add Choice
                  <span  pan nz-icon nzType="down"></span>
                </button>
              </div> 
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="border bottom mt-2"></div>
                      <div class="row" [ngClass]="{'d-none': !isRowVisible}">
                          <div class="col mt-1">
                              <a nz-button nzType="link">Feature Tiles</a>
                              <nz-tag [nzColor]="'cyan'">Select</nz-tag>
                              <span class="ms-2">$0.00</span>
                              <a nz-dropdown [nzDropdownMenu]="menu" class="ms-3">
                              Add
                                  <span nz-icon nzType="down"></span>
                                </a>
                                <nz-dropdown-menu #menu="nzDropdownMenu">
                                  <ul nz-menu nzSelectable>
                                    <li nz-menu-item>1st menu item</li>
                                    <li nz-menu-item>2nd menu item</li>
                                    <li nz-menu-item>3rd menu item</li>
                                    <li nz-menu-item nzDanger>4th danger item</li>
                                  </ul>
                                </nz-dropdown-menu>
                          </div>
                        </div>
                </div>
              </div>
          
          </nz-card> -->
