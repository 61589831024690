<nz-card nzTitle="Preview Lead Proposal" nzBorderless="true">
  <div class="container-fluid">
    <div class="row mb-3">
      <div class="col-12">
        <p>
          Below is a preview of the proposal that will be sent as a link to the
          email addresses on this customer contact.
        </p>
        <span
          >The proposal can be accessed through the link while it is
          Released.</span
        >
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-4">
        <h6 class="fw-bold">Layout Options</h6>
        <label class="sub-text">Header</label><br />
        <nz-radio-group>
          <label class="mt-1" nz-radio nzValue="A">Centered</label><br />
          <label class="mt-1" nz-radio nzValue="B">Split</label>
        </nz-radio-group>
      </div>
      <div class="col-4">
        <h6 class="fw-bold">Customer Information</h6>
        <label class="mt-1" nz-checkbox
          >Show Customer Contact Name and Phone</label
        ><br />
        <label class="mt-3" nz-checkbox>Show Address</label>
      </div>
      <div class="col-4">
        <h6 class="fw-bold">
          Company Information
          <span
            ><i
              class="bi bi-info-circle-fill text-secondary"
              nz-popover
              [nzPopoverContent]="contentTemplate"
              nzPopoverPlacement="right"
            ></i>

            <ng-template #contentTemplate>
              <div class="rounded-2">
                <p style="height: auto; width: 180px">
                  This will line or stack the company information. Text can be
                  modified in fields 1-3 under company information.
                </p>
              </div>
            </ng-template></span
          >
        </h6>
        <label class="mt-3" nz-checkbox>Show Printout Information</label>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-4">
        <label class="sub-text fw-bold">Body</label><br />
        <nz-radio-group>
          <label class="mt-1" nz-radio nzValue="A">Column</label><br />
          <label class="mt-1" nz-radio nzValue="B">Multi-Line</label>
        </nz-radio-group>
      </div>
    </div>

    <div class="container d-flex justify-content-center align-items-center">
      <div class="FormattedProposal shadow">
        <div nz-row [nzJustify]="'end'" class="mb-3">
          <div nz-col nzSpan="12">
            <div class="ImageContent">
              <img
                src="assets/printLogo.jpg"
                width="384"
                height="94"
                alt="printLogo"
              />
            </div>
          </div>
          <div nz-col nzSpan="12" class="text-end">
            <div class="ClientDetailContent">
              <strong>Zircon Homes T/A Xircon Homes</strong>
              <div
                nz-row
                class="AddressDisplay sentry-block justify-content-end"
              >
                <div nz-col nzXs="4" nzSm="6" nzMd="12" nzLg="24" nzXl="24">
                  20/47-51 Little Boundary Road
                </div>
                <div nz-col nzXs="4" nzSm="6" nzMd="12" nzLg="24" nzXl="24">
                  Laverton North
                </div>
                <div nz-col nzXs="4" nzSm="6" nzMd="12" nzLg="24" nzXl="24">
                  VIC 3026
                </div>
                <div nz-col nzXs="4" nzSm="6" nzMd="12" nzLg="24" nzXl="24">
                  Phone 18870 341 866
                </div>
              </div>
            </div>
          </div>
        </div>

        <div nz-row>
          <div nz-col nzXs="4" nzSm="6" nzMd="12" nzLg="24" nzXl="24">
            Khokhar family Pty Ltd ATF khokhar family trust
          </div>
        </div>
        <div nz-row class="mb-3">
          <div nz-col nzXs="4" nzSm="6" nzMd="12" nzLg="24" nzXl="24">
            Cell: 0430 454 071
          </div>
        </div>

        <div nz-row class="mb-3">
          <div nz-col nzSpan="8">
            <div nz-col nzXs="4" nzSm="6" nzMd="12" nzLg="24" nzXl="24">
              8 Juneberry Drive
            </div>
            <div nz-col nzXs="4" nzSm="6" nzMd="12" nzLg="24" nzXl="24">
              Keysborough
            </div>
            <div nz-col nzXs="4" nzSm="6" nzMd="12" nzLg="24" nzXl="24">
              VIC 3173
            </div>
          </div>
          <div nz-col nzSpan="8" nzOffset="8" class="text-end">
            <div nz-col nzXs="4" nzSm="6" nzMd="12" nzLg="24" nzXl="24">
              Job Address:
            </div>
            <div nz-col nzXs="4" nzSm="6" nzMd="12" nzLg="24" nzXl="24">
              Lot 1718 Riversdale Boulevard
            </div>
            <div nz-col nzXs="4" nzSm="6" nzMd="12" nzLg="24" nzXl="24">
              Weir views
            </div>
            <div nz-col nzXs="4" nzSm="6" nzMd="12" nzLg="24" nzXl="24">
              VIC 3338
            </div>
          </div>
        </div>

        <div nz-row class="mb-3">
          <div nz-col nzSm="6" nzMd="12" nzLg="24" class="text-end">
            <p><Strong>Date:</Strong>26-01-2024</p>
          </div>
        </div>
        <div nz-row class="mb-3">
          <div nz-col nzSm="12" nzMd="24" nzLg="24">
            <div class="proposalHeader">
              <h3>
                Proposal for Lot 1718 Riversdale Boulevard Weir views VIC 3338
                (23)
              </h3>
            </div>
          </div>
        </div>

        <div class="IntroductionText">
          <div nz-row class="mb-3">
            <div nz-col nzSm="12" nzMd="24" nzLg="24">
              <p>Hi</p>
              <p>
                Thank you for providing Xircon homes an opportunity to quote
                your home.
              </p>
            </div>
          </div>
        </div>
        <div nz-row class="mb-2">
          <div nz-col nzSm="12" nzMd="24" nzLg="24">
            <span style="color: teal">Muhammad Mehmood</span>
          </div>
        </div>
        <div nz-row class="mb-2">
          <div nz-col nzSm="12" nzMd="24" nzLg="24">
            <span>Director Sales Operations</span>
          </div>
        </div>
        <div nz-row class="mb-3">
          <div nz-col nzSm="12" nzMd="24" nzLg="24">
            <div class="ImageContent">
              <img
                src="assets/PrintCompanyDetail.png"
                width="545"
                height="89"
                alt="printLogo"
              />
            </div>
          </div>
        </div>
        <div nz-row class="mb-3">
          <div nz-col nzSm="12" nzMd="24" nzLg="24">
            <div class="ImageContent">
              <img
                src="assets/spaceWinner.png"
                width="476"
                height="150"
                alt="printLogo"
              />
            </div>
          </div>
        </div>
        <div nz-row class="mb-3">
          <div nz-col nzSm="12" nzMd="24" nzLg="24">
            <div class="CompanyContentText">
              <span>
                Xircon Homes accepts no liability for the content of this email,
                or for the consequences of any actions taken on the basis of the
                information provided, unless that information is subsequently
                confirmed in writing. This e-mail and/or attachments are
                confidential and may also be privileged. If you are not the
                named recipient, please notify the sender immediately and do not
                disclose the contents to other persons or use it for any purpose
                or store or copy the information in any medium. Any views
                expressed in this message are those of the individual sender,
                except where the sender specifically states them to be the views
                of Xircon Homes.
              </span>
            </div>
          </div>
        </div>

        <br />

        <nz-divider></nz-divider>
        <nz-divider></nz-divider>

        <div class="FooterText">
          <div nz-row class="mb-2">
            <div nz-col nzSm="12" nzMd="24" nzLg="24">
              <div class="CompanyFooterContentText">
                <span> Terms And Conditions: </span>
              </div>
            </div>
          </div>

          <ol class="ms-2">
            <li *ngFor="let condition of termsAndConditionsList">{{ condition }}</li>
          </ol>
          <ol type="a" class="ms-2 mt-0">
            <li *ngFor="let condition of termsAndConditionssequenceList">{{ condition }}</li>
          </ol>

        
          
          <div nz-row class="mb-2">
            <div nz-col nzSm="12" nzMd="24" nzLg="24">
              <div class="CompanyFooterContentText">
                <span>  QUOTATION ACCEPTANCE </span><br>
                <span> I/We, the undersigned, understand the following upon signing this quotation: </span>
              </div>
            </div>
          </div>

          <ul class="ms-2 mt-0">
            <li *ngFor="let condition of termsAndConditionsundersignedList">{{ condition }}</li>
          </ul>
        <div
          nz-row
          class="AddressDisplay ms-2 mb-3">
          <div *ngFor="let condition of CompanyInfoList" nz-col nzXs="4" nzSm="6" nzMd="12" nzLg="24" nzXl="24">
             <div style="color: #181818;" class="PSInfoContect text-decoration-underline fw-medium ">
                    {{condition}}
             </div>
          </div>
        </div>
        <div nz-row class="mb-2">
            <div nz-col nzXs="4" nzSm="6" nzMd="12" nzLg="24" nzXl="24">
                <div class="PSDisclaimerText ">
                    I confirm that my action here represents my electronic signature and is binding.
                 </div>
              </div>
          </div>

          <div class="PSSignatureArea mt-5">
            <div nz-row>
                <div nz-col nzSpan="4">
                   <strong>
                    Signature:
                   </strong>
                </div>
                <div nz-col nzSpan="20">
                    <div class="SignatureLine">
                    </div>
                </div>
              </div>
          </div>
          <div class="PSSignatureArea mt-5">
            <div nz-row>
                <div nz-col nzSpan="4">
                   <strong>
                    Date:
                   </strong>
                </div>
                <div nz-col nzSpan="20">
                    <div class="SignatureLine">
                    </div>
                </div>
              </div>
          </div>
          <div class="PSSignatureArea mt-5">
            <div nz-row>
                <div nz-col nzSpan="4">
                   <strong>
                    Print Name:
                   </strong>
                </div>
                <div nz-col nzSpan="20">
                    <div class="SignatureLine">
                    </div>
                </div>
              </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</nz-card>






