
<!-- <div class="card border-0 mt-0">
    <div class="card-head border-bottom">
        <div class="row py-0">
            <h5 nz-typography><label class="sub-text mb-1" for="displayName">Subject</label></h5>
        </div>
    </div>
    <div class="card-body">
      <div class="row">
        <label class="sub-text ms-3">From</label>
      </div>
      <div class="row">
        <div class="ListViewJobname ms-3">Muhammad YOUSUF</div>
      </div>
      <div class="row">
        <div class="col-12">
            <div class="col-3">
                <button nz-button nzType="link" >Show More</button>
            </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="ListViewJobname sub-text">To</div>
      </div>

      <div class="row mt-4">
        <label class="sub-text">Muhammad YOUSUF</label>
      </div>

      <div class="row mt-3">
        <div class="ListViewJobname sub-text">cc</div>
      </div>

      <div class="row mt-3">
        <div class="ListViewJobname sub-text">Sent</div>
      </div>

      <div class="row mt-3">
        <div class="ListViewJobname">Feb 1,2024, 4:23PM </div>
      </div>

      <div class="row mt-3">
        <div class="ListViewJobname">test </div>
      </div>
    </div>
  </div> -->
  <nz-card nzBorderless="true"  [nzTitle]="Subject">
    <ng-template #Subject>
        <h4 class="fw-medium famly-inn" for="displayName">Subject:</h4>
    </ng-template>

            <div>
                <div class="row  mt-2">
                    <div class="col-12">
                        <label class="fw-bold fs-7 ">From</label>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-12">
                        <label class="">Muhmmad Yousuf</label>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <span class="text-primary">Show more</span>
                    </div>
                </div>
                <div class="row  mt-4">
                    <div class="col-12">
                        <label class="fw-bold fs-7 ">To</label>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-12">
                        <label class="">Muhmmad Yousuf</label>
                    </div>
                </div>
                <div class="row  mt-4">
                    <div class="col-12">
                        <label class="fw-bold fs-7 ">CC</label>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-12">
                        <span class="">adc&#64;gmail.com</span>
                    </div>
                </div>
                <div class="row  mt-4">
                    <div class="col-12">
                        <label class="fw-bold fs-7 ">BCC</label>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-12">
                        <span class="">muhammadmehmood&#64;gmail.com</span>
                    </div>
                </div>
                <div class="row  mt-4">
                    <div class="col-12">
                        <label class="fw-bold fs-7 ">Sent</label>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-12">
                        <span class="">Feb 10,2024, 2:30 AM</span>
                    </div>
                </div>
                <div class="row  mt-4">
                    <div class="col-12">
                        <span class="">test</span>
                    </div>
                </div>
            </div>
      

  </nz-card>

  