<div *ngIf="filterResponseData && filterResponseData.length > 0">
  <form (ngSubmit)="onFilterApply()">
    <div class="row mt-2">
      <!-- <div class="col-10 p-0">
        <div class="input-group">
          <nz-select
            [(ngModel)]="formFieldValues['standardFilter']"
            name="standardFilter"
            (ngModelChange)="onChangeStandardFilter($event)"
            nzShowSearch
            [lang]="true"
            nzAutoFocus="true"
            nzPlaceHolder="Standard Filter"
          >
            <nz-option
              *ngFor="let filter of filterResponseData"
              [nzLabel]="
                filter.name + (filter.isSetAsDefault ? ' DEFAULT' : '')
              "
              [nzValue]="filter.id"
            ></nz-option>
          </nz-select>
        </div>
      </div> -->

      







 <div class="col-10 p-0">
      <div class="input-group">
       <nz-select 
        [(ngModel)]="formFieldValues['standardFilter']" 
        name="standardFilter"
        (ngModelChange)="onChangeStandardFilter($event)" 
        nzShowSearch 
        [nzAutoFocus]="true"
        nzPlaceHolder="Standard Filter"
        nzAllowClear>
        <nz-option *ngFor="let filter of filterResponseData"
         [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')" 
         [nzValue]="filter.id"
         nzCustomContent>
         <div class="option-content">
          <!-- Customize content with icons and text -->
          <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
          <span>{{ filter.name }}</span>
         <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>
  
         </div>
        </nz-option>
       </nz-select>
      </div>
     </div>
  
      <div class="col-1 mt-1">
        <i class="bi bi-three-dots" (click)="openStandardFilterComponent()"></i>
      </div>
    </div>
    <div class="row mt-2" *ngIf="selectedFilter === 1">
      <div class="col">
        <p>Your filter has unsaved changes.</p>
      </div>
    </div>
    <div class="row mt-2" *ngIf="selectedFilter === 0">
      <div class="col">
        <p>Your filter has unsaved changes.</p>
      </div>
    </div>

    <div class="col d-flex mt-3">
      <button
        (click)="openStandardFilterComponent()"
        type="button"
        nz-button
        nzType="default"
        class="me-2"
        style="font-size: 12px"
        *ngIf="selectedFilter === 1"
      >
        Save As
      </button>
      <button
        (click)="reInitReset()"
        nz-button
        nzType="default"
        style="font-size: 12px"
        *ngIf="selectedFilter === 1"
        type="button"
      >
        Reset
      </button>
      <button
        (click)="editSaveFilterData()"
        nz-button
        nzType="primary"
        type="button"
        class="me-2"
        style="font-size: 12px"
        *ngIf="selectedFilter === 0"
      >
        Save
      </button>
      <button
        (click)="openStandardFilterComponent()"
        nz-button
        nzType="default"
        class="me-2"
        style="font-size: 12px"
        *ngIf="selectedFilter === 0"
        type="button"
      >
        Save As
      </button>
      <button
        (click)="reInitReset()"
        nz-button
        nzType="default"
        style="font-size: 12px"
        *ngIf="selectedFilter === 0"
        type="button"
      >
        Reset
      </button>
    </div>

    <div class="row famly-inn">
      <div class="col-12">
        <div class="row mt-2 mb-2">
          <div class="col-12 p-0 m-0">
            <label class="sub-text">Keyword</label>
            <i
              class="bi bi-info-circle-fill text-secondary ms-2"
              nz-popover
              [nzPopoverContent]="keywordTemplate"
              nzPopoverPlacement="right"
            ></i>
            <ng-template #keywordTemplate>
              <div class="rounded-2">
                <p style="height: auto; width: 180px">
                  The Keyword Search allows you to search for Customer Contacts
                  by Name, Email Address, Phone/Cell, Street Address, City, and
                  Zip Code.
                </p>
              </div>
            </ng-template>
            <div class="d-flex align-items-center">
              <div class="row">
                <div class="col-12">
                  <div class="row p-0 m-0">
                    <div class="col-6 p-0 m-0">
                      <input
                        type="text"
                        [(ngModel)]="formFieldValues['keyword']"
                        name="keyword"
                        class="form-control formField"
                        style="
                          border-right: none;
                          border-radius: 4px 0 0 4px;
                          flex: 1;
                        "
                      />
                    </div>
                    <div class="col-6 p-0 m-0">
                      <div style="margin-left: 0px">
                        <nz-select
                          [(ngModel)]="colName"
                          name="colName"
                          (ngModelChange)="onParentCodeChange($event)"
                          nzShowSearch
                          [lang]="true"
                          nzAutoFocus="true"
                          nzPlaceHolder="colName"
                          nzPlaceHolder="Select contact type"
                          style="
                            font-size: 12px;
                            height: 32px;
                            width: 100%;
                            border-left: none;
                          "
                        >
                          <nz-option
                            *ngFor="let option of keywordOptions"
                            [nzLabel]="option.label"
                            [nzValue]="option.value"
                          >
                          </nz-option>
                        </nz-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 p-0 mt-1">
            <label class="sub-text">contact Type</label>
            <div class="input-group">
              <nz-tree-select
              [(ngModel)]="formFieldValues['contactType']"
              name="contactType"
              style="width: 100%"
              [nzNodes]="contactTypeNodes"
              [nzShowSearch]="true"
              nzAllowClear="false"
              nzCheckable
              (ngModelChange)="onContactTypeChange($event)"
              nzPlaceHolder="Select contact Type"
              nzDropdownClassName="dropdownStyle"
              [nzAllowClear]="false"
            >
            </nz-tree-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border-top text-end pt-4" style="margin-top: 220px">
      <div nz-col nzSpan="24">
        <button
          class=""
          nz-button
          nzType="default"
          type="button"
          (click)="resetFilter()"
        >
          Clear All
        </button>
        <button
          class="ms-1"
          nz-button
          nzType="primary"
          type="submit"
          [disabled]="isFormDirty"
        >
          Apply
        </button>
      </div>
    </div>
  </form>
</div>
