<div class="row mt-0 famly-inn">
    <div class="col-12">
        <div class="card shadow-none  rounded border-0">
            <div class="card-body">
                <div class="row">
                    <div class="col d-flex align-items-center justify-content-center">
                            <svg width="80px" height="100px" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class=""><path fill-rule="evenodd" clip-rule="evenodd" d="M6.99995 3L19.0634 3.00198L19.1571 3.01236L19.2037 3.02078L19.3244 3.05382L19.4402 3.10186L19.5121 3.14087L19.5932 3.19484L19.6254 3.21969L19.7075 3.29291L26.6995 10.2844C26.8594 10.4405 26.9671 10.6496 26.9943 10.8834L27.001 11L27 27C27 28.0543 26.1842 28.9182 25.1493 28.9945L25 29H6.99995C5.9456 29 5.08183 28.1841 5.00549 27.1493L5 27V5C5 3.94566 5.81584 3.08184 6.85069 3.00549L6.99995 3ZM18 4.999L7 5V27H25L25 12H19C18.4872 12 18.0645 11.614 18.0067 11.1166L18 11V4.999ZM20 6.414L23.585 10H20V6.414Z"></path></svg>
                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex align-items-center justify-content-center">
                <span class="fw-medium fs-6 ms-2">Drop files here to upload, or browse for files.</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 text-end">
                        <nz-upload
                        nzAction="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        [nzHeaders]="{ authorization: 'authorization-text' }"
                        (nzChange)="handleChange($event)"
                        >
                        <button  nz-button nzType="primary" class="rounded-1 me-0">Browser Computer</button>
                        </nz-upload>
                    </div>
                    <div class="col-6  text-start d-flex align-items-center justify-content-center">
                        <button nz-button nzType="default" class="rounded-1 ms-0">Browser Buildertrend</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

