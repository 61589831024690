<nz-layout>
  <nz-sider
    class="border"
    nzCollapsible
    [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed"
    [nzWidth]="280"
    [nzTrigger]="null"
  >
    <div class="row">
      <app-side-menu-bar
      [isAllJobOption]="true"
      (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"
      ></app-side-menu-bar>
    </div> 
    </nz-sider>
   
<nz-layout>
  <nz-page-header>
    <nz-page-header-title>
      <nz-breadcrumb class="text-start" nz-page-header-breadcrumb>
        <nz-breadcrumb-item>{{this.AccessLocalStorageService.getJobName()}}</nz-breadcrumb-item>
      </nz-breadcrumb>
      <h1>Message</h1>
    </nz-page-header-title>
    <nz-page-header-extra>
      <nz-space *ngIf="!checked5" class="mt-2">
        <label class="text-secondary mt-2 me-2"
          >Last Updated at 10:24 AM</label
        >
        <button nz-button nzType="default" class="rounded-1 fs-6 me-2">
          <i
            nz-icon
            nzType="reload"
            nzTheme="outline"
            class="fw-bold mb-2 me-1"
          ></i
          >Refresh
        </button>
        <button
          nz-button
          nzType="default"
          class="rounded-1 me-2"
          (click)="forwordToEmailModelOpen()"
        >
          Forword To Email Buildertrend
        </button>
        <button
          nz-button
          nzType="default"
          class="rounded-1 me-2"
          (click)="openFilter(MESSAGES, PAGE_ID)"
        >
          <i class="bi bi-funnel fs-6 me-1"></i>Filter
        </button>
        <button
          nzType="primary"
          *nzSpaceItem
          nz-button
          class="rounded-1"
          (click)="composeNewMessageModelOpen()"
        >
          Compose New Message
        </button>
      </nz-space>
      <nz-space *ngIf="checked5" class="mt-2">
        <span class="me-4 mt-2 fs-6 fw-bold">
          <p>{{ setOfCheckedId.size }} selected</p>
        </span>
        <button
          *nzSpaceItem
          nz-button
          nzType="default"
          class="pt-1 fs-6 text-primary border-0"
          (click)="cancelSelection()"
        >
          Cancel
        </button>
        <button *nzSpaceItem nz-button nzType="default" class="rounded-1">
          Move
        </button>
        <button *nzSpaceItem nz-button nzType="default" class="rounded-1">
          Delete
        </button>
        <button *nzSpaceItem nz-button nzType="default" class="rounded-1">
          Mark As Read
        </button>
        <button *nzSpaceItem nz-button nzType="default" class="rounded-1">
          Mark As Unread
        </button>
      </nz-space>
    </nz-page-header-extra>
  </nz-page-header>

  <nz-content class="custom-content">
    <div class="container-fuild mb-4 m-3">
      <div nz-row>
        <div nz-col nzSpan="4">
          <div class="row">
            <div class="col text-start">
              <ul class="list-group list-group-flush">
                <li class="list-group-item setli">
                  <i class="bi bi-envelope-open fw-bold"></i>
                  <span class="seter">Inbox</span>
                </li>
                <li class="list-group-item setli">
                  <i
                    nz-icon
                    nzType="file"
                    nzTheme="fill"
                    class="list-icon-set"
                  ></i
                  ><span class="seter fw-bold">Drafts (1)</span>
                </li>
                <li class="list-group-item setli">
                  <i class="bi bi-send-fill list-icon-set"></i
                  ><span class="seter">Sent</span>
                </li>
                <li class="list-group-item setli">
                  <i
                    nz-icon
                    nzType="delete"
                    nzTheme="fill"
                    class="list-icon-set"
                  ></i
                  ><span class="seter">Trash</span>
                </li>

                <li class="list-group-item setli pt-3 pb-3">
                  <i class="bi bi-envelope-paper list-icon-set"></i
                  ><span class="seter">Company Message</span>
                </li>
                <li class="list-group-item setli text-primary">
                  <i class="bi bi-gear-fill text-primary list-icon-set"></i
                  ><span class="seter text-primary">Manage Folder</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div nz-col nzSpan="19" class="ms-5" *ngIf="showAddressColumn">
          <!-- <div class="row">
                             
          <div class="col-lg-8 offset-lg-2"  style="margin-top:150px;">
             <div class="col text-center ">
              <i class="bi bi-envelope" style="font-size: 60px;"></i>
             </div>
              <span class="text-center"><h2 class="fw-bold famly-inn mb-3">Stay In Touch With Message</h2></span>
              <p class="text-center mb-1" style="font-size: 14px">Monitor communication for each job and store emails.</p>
              <p class="text-center mb-3" style="font-size: 14px">in one place</p>

          <div class="col mt-2 text-center" >
              <button nz-button nzType="default" class="me-2 " ><i class="bi bi-box-arrow-up-right me-2"></i>Learn How</button>
              <button nz-button nzType="primary" (click)="composeNewMessageModelOpen()">Send a Message</button>
          </div>
      </div>
      </div> -->

          <nz-table
            class="w-100 mt-4"
            #rowSelectionTable
            nzShowSizeChanger
            [nzData]="listOfData"
            (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
            nzFrontPagination="false"
            #middleTable
            nzSize="small"
          >
            <thead>
              <tr>
                <th nzWidth="5px"></th>
                <th
                  nzWidth="10px"
                  [(nzChecked)]="checked5"
                  [nzIndeterminate]="indeterminate"
                  (nzCheckedChange)="onAllChecked($event)"
                ></th>
                <th
                  *ngFor="let column of listOfColumns"
                  [nzSortOrder]="column.sortOrder"
                  [nzSortFn]="column.sortFn"
                  [nzSortDirections]="column.sortDirections"
                >
                  {{ column.name }}
                </th>
              </tr>
            </thead>

            <tbody cdkDropList (cdkDropListDropped)="drop($event)">
              <tr
                *ngFor="let data of listOfData"
                cdkDrag
                class="example-list icon-container rounded-2"
                style="cursor: pointer"
                cdkDropList
                #todoList="cdkDropList"
                [cdkDropListData]="fix"
                [cdkDropListConnectedTo]="[doneList]"
              >
                <td
                  style="cursor: grab"
                  class="icon-container text-center"
                  nzWidth="5px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#000000"
                    width="30px"
                    height="30px"
                    viewBox="-64 -64 384.00 384.00"
                    id="Flat"
                    stroke="#000000"
                    stroke-width="25.6"
                    transform="rotate(0)"
                  >
                    <g
                      id="SVGRepo_bgCarrier"
                      stroke-width="0"
                      transform="translate(56.31999999999999,56.31999999999999), scale(0.56)"
                    />
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke="#CCCCCC"
                      stroke-width="0.512"
                    />
                    <g class="hidden-icon" id="SVGRepo_iconCarrier">
                      <path
                        d="M100,60.0001a8,8,0,1,1-8-8A8.00008,8.00008,0,0,1,100,60.0001Zm64,8a8,8,0,1,0-8-8A8.00008,8.00008,0,0,0,164,68.0001Zm-72,52a8,8,0,1,0,8,8A7.99977,7.99977,0,0,0,92,120.0001Zm72,0a8,8,0,1,0,8,8A7.99977,7.99977,0,0,0,164,120.0001Zm-72,68a8,8,0,1,0,8,8A7.99977,7.99977,0,0,0,92,188.0001Zm72,0a8,8,0,1,0,8,8A7.99977,7.99977,0,0,0,164,188.0001Z"
                      />
                    </g>
                  </svg>
                </td>
                <td
                  nzWidth="10px"
                  [nzChecked]="setOfCheckedId.has(data.id)"
                  (nzCheckedChange)="onItemChecked(data.id, $event)"
                ></td>
                <td Width="60px" (click)="toggleAddressColumn()">
                  {{ data.name }}
                </td>
                <td Width="200px">{{ data.address }}</td>
                <td Width="60px">{{ data.name }}</td>
              </tr>
            </tbody>
          </nz-table>
        </div>

        <div nz-col nzSpan="19" class="ms-5" *ngIf="!showAddressColumn">
          <nz-page-header [nzGhost]="true">
            <nz-page-header-title
              (click)="toggleAddressColumn()"
              class="trash-icon"
            >
              <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
              <span nz-button nzType="link" class="fs-6 ms-2"
                >Back to trash</span
              >
            </nz-page-header-title>
            <nz-page-header-extra style="margin-left: 20px">
              <nz-space>
                <button *nzSpaceItem nz-button class="rounded-1">
                  New To-Do
                </button>
                <button *nzSpaceItem nz-button class="rounded-1">
                  Print
                </button>
                <button *nzSpaceItem nz-button class="rounded-1">
                  Delete
                </button>
                <button *nzSpaceItem nz-button class="rounded-1">
                  Mark as unread
                </button>
                <button *nzSpaceItem nz-button class="rounded-1">
                  Move Message
                </button>
                <button *nzSpaceItem nz-button class="rounded-1">
                  Forword
                </button>
                <button *nzSpaceItem nz-button class="rounded-1">
                  Reply All
                </button>
                <button
                  *nzSpaceItem
                  nz-button
                  nzType="primary"
                  class="rounded-1"
                >
                  Reply
                </button>
              </nz-space>
            </nz-page-header-extra>
          </nz-page-header>
          <inbox-details></inbox-details>
        </div>

        <!-- second Header  -->
        <!-- <nz-page-header [nzGhost]="false">
          <nz-page-header-title>
            <h6 class="text-primary fw-normal ms-3"><i class="bi bi-arrow-left text-primary me-2"></i> Back to Trash
            </h6>
          </nz-page-header-title>
          <nz-page-header-extra>
            <nz-space>
              <button *nzSpaceItem nz-button class="rounded-1">New To-Do</button>
              <button *nzSpaceItem nz-button class="rounded-1">Print</button>
              <button *nzSpaceItem nz-button class="rounded-1">Delete</button>
              <button *nzSpaceItem nz-button class="rounded-1">
                Mark as unread
              </button>
              <button *nzSpaceItem nz-button class="rounded-1">Move Message</button>
              <button *nzSpaceItem nz-button class="rounded-1">Forword</button>
              <button *nzSpaceItem nz-button class="rounded-1">Reply All</button>
              <button *nzSpaceItem nz-button nzType="primary" class="rounded-1"> Reply</button>
            </nz-space>
          </nz-page-header-extra>
        </nz-page-header> -->
        <!-- second row -->
        <!-- <div class="row mt-5">
          <div class="col-12 text-center">
            <i class="bi bi-envelope" style="font-size: 90px;"></i>
            <div class="row ">
              <h5>Stay In Touch With Message</h5>
            </div>
            <div class="row text-center" style="font-size: 12px;">
              <p>Monitor communication for each job and store emails </p>
              <p>in one place</p>
            </div>
            <div class="row text-center">
              <div class="col">
                <button nz-button nzType="default"><i class="bi bi-box-arrow-in-up-right me-2 "></i>Learn How
                </button>
                <button nz-button nzType="primary" class="ms-2" (click)="showProduct()">Send A Messages</button>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </nz-content>
</nz-layout>
</nz-layout>


