import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CostCode } from 'src/Models/CostCode/costCode';
import { CostCodeCategoryResponse, CostCodeResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { BehaviorsubjectService } from 'src/Service/behaviorsubject/behaviorsubject.service';
import { CostCodeService } from 'src/Service/Internaluser/cost-code.service';
import { LeadActivityTypeService } from 'src/Service/Lead-Activity-Type/lead-activity-type.service';

@Component({
  selector: 'app-cos-code-model',
  templateUrl: './cos-code-model.component.html',
  styleUrls: ['./cos-code-model.component.css'],
})
export class CosCodeModelComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Input() addLeadActivityTypeResponse: CostCodeCategoryResponse;
  @Output() costCodeResponse: CostCodeCategoryResponse;
  @Output() costCodeSaved = new EventEmitter<CostCodeCategoryResponse>(); // Event emitter for cost code saved response
  @Output() costCodeonSaveComplete  = new EventEmitter();
  formFieldValues: any = {};
  selectedValue = null;
  costCategory: CostCode[] = [];
  costSubCode: CostCode[] = [];
  costCode = true;
  newnostcode = true;
  checked: boolean = false;
  TitleValid: boolean = false;
  listOfOptions: CostCode[];
  CostInformation: boolean = true;

  constructor(
    private costCodeService: CostCodeService,
    private toastssServices: NzMessageService,
    private leadActivitiesService: LeadActivityTypeService,
    private _BehaviorsubjectService: BehaviorsubjectService,

  ) { }

  ngOnInit(): void {
    this.loadCostCodes();
    this.initializeFormFields();
    let dataSet = this.addLeadActivityTypeResponse
    console.log("dataSet",dataSet);
    
    this.formFieldValues['globalId'] = dataSet?.globalId;
    this.formFieldValues['title'] = dataSet?.title;
    this.formFieldValues['costCodeId'] = dataSet?.costCodeId;
    this.formFieldValues['isSubCodeOf'] = dataSet?.isSubCodeOf;
    this.formFieldValues['timeClockLaborCode'] = dataSet?.timeClockLaborCode;
    this.formFieldValues['defaultLaborCost'] = dataSet?.defaultLaborCost;
    this.formFieldValues['costCodeCategoryId'] = dataSet?.costCodeId;
    this.formFieldValues['detail'] = dataSet?.detail;

  }


  initializeFormFields(): void {
    this.formFieldValues = {
      globalI: '00000000-0000-0000-0000-000000000000',
      companyParameterId: 1,
      title: '',
      defaultLaborCost: '',
      timeClockLaborCode: false,
      costCodeCategoryId: 0,
      subCostCodeId: 0,
      detail: '',
      isStatus: false,
      isCategory: false,
      isSubCodeOf: false,
    };
  }

  loadCostCodes(): void {
    this.costCodeService.getCategoryData().subscribe((res: ResponseModelArray<CostCode>) => {
        this.listOfOptions = res.result;
        if (res) {
          this.costCategory = res.result.filter(
            (cost) => cost.isCategory === true
          );
          this.costSubCode = res.result.filter(
            (cost) => cost.isSubCodeOf === true
          );
        }
      });
  }

  subCodeChanged(): void {
    const selectedSubCodeId = this.formFieldValues['subCostCodeId'];
    const matchingSubCode = this.costSubCode.find(cost => cost.id === selectedSubCodeId);
    if (matchingSubCode && matchingSubCode.costCodeId) {
      const matchingCategory = this.costCategory.find(cost => cost.id === matchingSubCode.costCodeId);
      if (matchingCategory) {
        this.formFieldValues['costCodeId'] = matchingCategory.id;
      }
    }
  }


  open(): void {
    this.CostInformation = true
  }
  close(): void {
    this.cancel.emit();
  }
  toggleFields(): void {
    this.checked = !this.checked;
  }

  saveButton(): void {
    console.log("this.formFieldValues",this.formFieldValues);
    let subCostCodeId = this.formFieldValues.subCostCodeId;
    if (subCostCodeId > 0) {
      this.formFieldValues.costCodeId = subCostCodeId;
    }
    this.costCodeService.postCostCategory(this.formFieldValues)
      .subscribe((response) => {
        console.log("response",response);
        this.costCodeResponse = response.result;
        this.costCodeonSaveComplete.emit();
        this._BehaviorsubjectService.setLeadActivityTypeResponse(response);
        this.costCodeSaved.emit(response.result);
        this.toastssServices.success('New  Successfully!');
      });
  }
  saveAndCloseButton(): void {
    this.saveButton(); 
    this.costCodeResponse = null; 
    this.close();
  }

  saveAndNew(): void {
    this.saveButton(); 
    this.costCodeResponse = null;
    this.toggleFields();
    this.initializeFormFields(); 
  }


}
